import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowRevocationsBaseVariables, FirstWorkflowRevocationsBaseVariables, CountWorkflowRevocationsBaseVariables, FindWorkflowRevocationsBaseVariables, SearchWorkflowRevocationsBaseVariables, ExportSchemaWorkflowRevocationsBaseVariables, ExportDataWorkflowRevocationsBaseVariables, CustomQueryWorkflowRevocationsBaseVariables, CustomQueryIdWorkflowRevocationsBaseVariables, UsedWorkflowRevocationsBaseVariables, ExistWorkflowRevocationsBaseVariables, InsertWorkflowRevocationsBaseVariables, UpdateWorkflowRevocationsBaseVariables, ImportDataWorkflowRevocationsBaseVariables, UpdateManyWorkflowRevocationsBaseVariables, DeleteWorkflowRevocationsBaseVariables } from '../gqls'
import { GetWorkflowRevocationsDocument, FirstWorkflowRevocationsDocument, CountWorkflowRevocationsDocument, FindWorkflowRevocationsDocument, SearchWorkflowRevocationsDocument, ExportSchemaWorkflowRevocationsDocument, ExportDataWorkflowRevocationsDocument, CustomQueryWorkflowRevocationsDocument, CustomQueryIdWorkflowRevocationsDocument, UsedWorkflowRevocationsDocument, ExistWorkflowRevocationsDocument, InsertWorkflowRevocationsDocument, UpdateWorkflowRevocationsDocument, ImportDataWorkflowRevocationsDocument, UpdateManyWorkflowRevocationsDocument, DeleteWorkflowRevocationsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowRevocation, QueryContextOfWorkflowRevocation, FilterOfWorkflowRevocation, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowRevocation, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowRevocationInput, FieldUpdateOperatorOfWorkflowRevocation } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowRevocationBaseService {
    
public modelName = 'workflowRevocation';
public modelPluralName = 'workflowRevocations';

	private getWorkflowRevocationsQuery: GetWorkflowRevocationsDocument;
	private firstWorkflowRevocationsQuery: FirstWorkflowRevocationsDocument;
	private countWorkflowRevocationsQuery: CountWorkflowRevocationsDocument;
	private findWorkflowRevocationsQuery: FindWorkflowRevocationsDocument;
	private searchWorkflowRevocationsQuery: SearchWorkflowRevocationsDocument;
	private exportSchemaWorkflowRevocationsQuery: ExportSchemaWorkflowRevocationsDocument;
	private exportDataWorkflowRevocationsQuery: ExportDataWorkflowRevocationsDocument;
	private customQueryWorkflowRevocationsQuery: CustomQueryWorkflowRevocationsDocument;
	private customQueryIdWorkflowRevocationsQuery: CustomQueryIdWorkflowRevocationsDocument;
	private usedWorkflowRevocationsQuery: UsedWorkflowRevocationsDocument;
	private existWorkflowRevocationsQuery: ExistWorkflowRevocationsDocument;
	private insertWorkflowRevocationsMutation: InsertWorkflowRevocationsDocument;
	private updateWorkflowRevocationsMutation: UpdateWorkflowRevocationsDocument;
	private importDataWorkflowRevocationsMutation: ImportDataWorkflowRevocationsDocument;
	private updateManyWorkflowRevocationsMutation: UpdateManyWorkflowRevocationsDocument;
	private deleteWorkflowRevocationsMutation: DeleteWorkflowRevocationsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowRevocationsQuery = this.injector.get(GetWorkflowRevocationsDocument);
		this.firstWorkflowRevocationsQuery = this.injector.get(FirstWorkflowRevocationsDocument);
		this.countWorkflowRevocationsQuery = this.injector.get(CountWorkflowRevocationsDocument);
		this.findWorkflowRevocationsQuery = this.injector.get(FindWorkflowRevocationsDocument);
		this.searchWorkflowRevocationsQuery = this.injector.get(SearchWorkflowRevocationsDocument);
		this.exportSchemaWorkflowRevocationsQuery = this.injector.get(ExportSchemaWorkflowRevocationsDocument);
		this.exportDataWorkflowRevocationsQuery = this.injector.get(ExportDataWorkflowRevocationsDocument);
		this.customQueryWorkflowRevocationsQuery = this.injector.get(CustomQueryWorkflowRevocationsDocument);
		this.customQueryIdWorkflowRevocationsQuery = this.injector.get(CustomQueryIdWorkflowRevocationsDocument);
		this.usedWorkflowRevocationsQuery = this.injector.get(UsedWorkflowRevocationsDocument);
		this.existWorkflowRevocationsQuery = this.injector.get(ExistWorkflowRevocationsDocument);
		this.insertWorkflowRevocationsMutation = this.injector.get(InsertWorkflowRevocationsDocument);
		this.updateWorkflowRevocationsMutation = this.injector.get(UpdateWorkflowRevocationsDocument);
		this.importDataWorkflowRevocationsMutation = this.injector.get(ImportDataWorkflowRevocationsDocument);
		this.updateManyWorkflowRevocationsMutation = this.injector.get(UpdateManyWorkflowRevocationsDocument);
		this.deleteWorkflowRevocationsMutation = this.injector.get(DeleteWorkflowRevocationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocation> {
    
    		let variables: GetWorkflowRevocationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowRevocation={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowRevocation,
		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocation> {
    
    		let variables: FirstWorkflowRevocationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowRevocationsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowRevocation,
		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocation> {
    
    		let variables: FindWorkflowRevocationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowRevocation,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocation> {
    
    		let variables: SearchWorkflowRevocationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowRevocationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowRevocationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowRevocation,
		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocation> {
    
    		let variables: CustomQueryWorkflowRevocationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowRevocation,
		@Args('filter?') filter?: FilterOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocation> {
    
    		let variables: CustomQueryIdWorkflowRevocationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowRevocationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowRevocationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowRevocationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowRevocationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocation> {
    
    		let variables: InsertWorkflowRevocationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowRevocationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocation> {
    
    		let variables: UpdateWorkflowRevocationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowRevocationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowRevocationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowRevocationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowRevocation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowRevocationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowRevocationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowRevocationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowRevocationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocations.delete));
    	}
    
}