import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStockTransferReasonsBaseVariables, FirstStockTransferReasonsBaseVariables, CountStockTransferReasonsBaseVariables, FindStockTransferReasonsBaseVariables, SearchStockTransferReasonsBaseVariables, ExportSchemaStockTransferReasonsBaseVariables, ExportDataStockTransferReasonsBaseVariables, CustomQueryStockTransferReasonsBaseVariables, CustomQueryIdStockTransferReasonsBaseVariables, UsedStockTransferReasonsBaseVariables, ExistStockTransferReasonsBaseVariables, FindUnassociatedStockTransfersStockTransferReasonsBaseVariables, InsertStockTransferReasonsBaseVariables, UpdateStockTransferReasonsBaseVariables, ImportDataStockTransferReasonsBaseVariables, UpdateManyStockTransferReasonsBaseVariables, DeleteStockTransferReasonsBaseVariables, AddStockTransfersStockTransferReasonsBaseVariables, RemoveStockTransfersStockTransferReasonsBaseVariables } from '../gqls'
import { GetStockTransferReasonsDocument, FirstStockTransferReasonsDocument, CountStockTransferReasonsDocument, FindStockTransferReasonsDocument, SearchStockTransferReasonsDocument, ExportSchemaStockTransferReasonsDocument, ExportDataStockTransferReasonsDocument, CustomQueryStockTransferReasonsDocument, CustomQueryIdStockTransferReasonsDocument, UsedStockTransferReasonsDocument, ExistStockTransferReasonsDocument, FindUnassociatedStockTransfersStockTransferReasonsDocument, InsertStockTransferReasonsDocument, UpdateStockTransferReasonsDocument, ImportDataStockTransferReasonsDocument, UpdateManyStockTransferReasonsDocument, DeleteStockTransferReasonsDocument, AddStockTransfersStockTransferReasonsDocument, RemoveStockTransfersStockTransferReasonsDocument } from '../gqls'
import { ServiceSingleResultOfStockTransferReason, QueryContextOfStockTransferReason, FilterOfStockTransferReason, ServiceSingleResultOfInt64, ServiceListResultOfStockTransferReason, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfStockTransfer, FilterOfStockTransfer, ServiceListResultOfStockTransfer, StockTransferReasonInput, FieldUpdateOperatorOfStockTransferReason } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StockTransferReasonBaseService {
    
public modelName = 'stockTransferReason';
public modelPluralName = 'stockTransferReasons';

	private getStockTransferReasonsQuery: GetStockTransferReasonsDocument;
	private firstStockTransferReasonsQuery: FirstStockTransferReasonsDocument;
	private countStockTransferReasonsQuery: CountStockTransferReasonsDocument;
	private findStockTransferReasonsQuery: FindStockTransferReasonsDocument;
	private searchStockTransferReasonsQuery: SearchStockTransferReasonsDocument;
	private exportSchemaStockTransferReasonsQuery: ExportSchemaStockTransferReasonsDocument;
	private exportDataStockTransferReasonsQuery: ExportDataStockTransferReasonsDocument;
	private customQueryStockTransferReasonsQuery: CustomQueryStockTransferReasonsDocument;
	private customQueryIdStockTransferReasonsQuery: CustomQueryIdStockTransferReasonsDocument;
	private usedStockTransferReasonsQuery: UsedStockTransferReasonsDocument;
	private existStockTransferReasonsQuery: ExistStockTransferReasonsDocument;
	private findUnassociatedStockTransfersStockTransferReasonsQuery: FindUnassociatedStockTransfersStockTransferReasonsDocument;
	private insertStockTransferReasonsMutation: InsertStockTransferReasonsDocument;
	private updateStockTransferReasonsMutation: UpdateStockTransferReasonsDocument;
	private importDataStockTransferReasonsMutation: ImportDataStockTransferReasonsDocument;
	private updateManyStockTransferReasonsMutation: UpdateManyStockTransferReasonsDocument;
	private deleteStockTransferReasonsMutation: DeleteStockTransferReasonsDocument;
	private addStockTransfersStockTransferReasonsMutation: AddStockTransfersStockTransferReasonsDocument;
	private removeStockTransfersStockTransferReasonsMutation: RemoveStockTransfersStockTransferReasonsDocument;

	constructor(private injector: Injector) {
		this.getStockTransferReasonsQuery = this.injector.get(GetStockTransferReasonsDocument);
		this.firstStockTransferReasonsQuery = this.injector.get(FirstStockTransferReasonsDocument);
		this.countStockTransferReasonsQuery = this.injector.get(CountStockTransferReasonsDocument);
		this.findStockTransferReasonsQuery = this.injector.get(FindStockTransferReasonsDocument);
		this.searchStockTransferReasonsQuery = this.injector.get(SearchStockTransferReasonsDocument);
		this.exportSchemaStockTransferReasonsQuery = this.injector.get(ExportSchemaStockTransferReasonsDocument);
		this.exportDataStockTransferReasonsQuery = this.injector.get(ExportDataStockTransferReasonsDocument);
		this.customQueryStockTransferReasonsQuery = this.injector.get(CustomQueryStockTransferReasonsDocument);
		this.customQueryIdStockTransferReasonsQuery = this.injector.get(CustomQueryIdStockTransferReasonsDocument);
		this.usedStockTransferReasonsQuery = this.injector.get(UsedStockTransferReasonsDocument);
		this.existStockTransferReasonsQuery = this.injector.get(ExistStockTransferReasonsDocument);
		this.findUnassociatedStockTransfersStockTransferReasonsQuery = this.injector.get(FindUnassociatedStockTransfersStockTransferReasonsDocument);
		this.insertStockTransferReasonsMutation = this.injector.get(InsertStockTransferReasonsDocument);
		this.updateStockTransferReasonsMutation = this.injector.get(UpdateStockTransferReasonsDocument);
		this.importDataStockTransferReasonsMutation = this.injector.get(ImportDataStockTransferReasonsDocument);
		this.updateManyStockTransferReasonsMutation = this.injector.get(UpdateManyStockTransferReasonsDocument);
		this.deleteStockTransferReasonsMutation = this.injector.get(DeleteStockTransferReasonsDocument);
		this.addStockTransfersStockTransferReasonsMutation = this.injector.get(AddStockTransfersStockTransferReasonsDocument);
		this.removeStockTransfersStockTransferReasonsMutation = this.injector.get(RemoveStockTransfersStockTransferReasonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransferReason> {
    
    		let variables: GetStockTransferReasonsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.get));
            }
            else{
                let result:ServiceSingleResultOfStockTransferReason={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockTransferReason,
		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransferReason> {
    
    		let variables: FirstStockTransferReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStockTransferReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockTransferReason,
		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransferReason> {
    
    		let variables: FindStockTransferReasonsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStockTransferReason,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransferReason> {
    
    		let variables: SearchStockTransferReasonsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStockTransferReasonsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStockTransferReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStockTransferReason,
		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransferReason> {
    
    		let variables: CustomQueryStockTransferReasonsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStockTransferReason,
		@Args('filter?') filter?: FilterOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransferReason> {
    
    		let variables: CustomQueryIdStockTransferReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStockTransferReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.usedStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStockTransferReasonsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.exist));
    	}

	@InjectArgs
	public findAssociatedStockTransfers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfStockTransfer,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfStockTransfer> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('stockTransfers', fields, null, [
			GqlSubFieldArg.create('filterOfStockTransfers', 'filter'),
			GqlSubFieldArg.create('optionsOfStockTransfers', 'options'),
		]),
		])
		extendedVariables['filterOfStockTransfers'] = filter;
		extendedVariables['optionsOfStockTransfers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.stockTransfers));
            }
            else{
                let result: ServiceListResultOfStockTransfer = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedStockTransfers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransfer> {
    
    		let variables: FindUnassociatedStockTransfersStockTransferReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedStockTransfersStockTransferReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.findUnassociatedStockTransfers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StockTransferReasonInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransferReason> {
    
    		let variables: InsertStockTransferReasonsBaseVariables = {
    			entity: entity
    		}
    				return this.insertStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransferReason> {
    
    		let variables: UpdateStockTransferReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStockTransferReasonsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockTransferReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStockTransferReasonsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStockTransferReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addStockTransfers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('stockTransferIds') stockTransferIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddStockTransfersStockTransferReasonsBaseVariables = {
    			id: id,
			stockTransferIds: stockTransferIds
    		}
    				return this.addStockTransfersStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.addStockTransfers));
    	}

    	/**  */
    	@InjectArgs
    	public removeStockTransfers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('stockTransferIds') stockTransferIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveStockTransfersStockTransferReasonsBaseVariables = {
    			stockTransferIds: stockTransferIds
    		}
    				return this.removeStockTransfersStockTransferReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransferReasons.removeStockTransfers));
    	}
    
}