import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { GqlField, GqlSubField } from '../../helpers';
import { Layout } from '../../types';
import { UserCoreService } from '../user.service';
import { BaseStorageService } from './base-storage-service';
import { map } from 'rxjs/operators';

/** Classe sur les donnée de layout par defaut */
export class LayoutStorageService extends BaseStorageService {
  _layout: Layout[];
  _defaultLayout: Layout[];

  constructor(userService: UserCoreService, userId: string) {
    super(userService, userId);
  }

  reset() {
    this._layout = undefined;
    this._defaultLayout = undefined;
  }

  /** Refresh */
  refresh(): Observable<Layout[]> {
    return this._userService
      .get(
        [
          GqlSubField.create('data', [
            GqlSubField.create('settings', [
              GqlSubField.create('preferences', [
                GqlSubField.create('layouts', [
                  GqlField.create('id'),
                  GqlField.create('key'),
                  GqlField.create('name'),
                  GqlField.create('value'),
                  GqlField.create('filterName'),
                  GqlField.create('filterValue'),
                  GqlField.create('rawFilterValue'),
                ]),
                GqlSubField.create('defaultLayouts', [
                  GqlField.create('id'),
                  GqlField.create('key'),
                ]),
              ]),
            ]),
          ]),
        ],
        this._userId,
      )
      .pipe(
        map((s) => {
          this._layout = s?.data?.settings?.preferences?.layouts;
          this._defaultLayout = s?.data?.settings?.preferences?.defaultLayouts;

          if (this._layout == undefined) {
            this._layout = [];
          }

          if (this._defaultLayout == undefined) {
            this._defaultLayout = [];
          }

          this._layout = this._layout.filter(
            (s) => s.name != undefined && s.key != undefined,
          );

          return this._layout.sort((a, b) => a.name.localeCompare(b.name));
        }),
      );
  }

  /** Par defaut */
  getDefault(key: string): Observable<Layout> {
    if (this._defaultLayout == undefined && this._layout == undefined) {
      return this.find(key).pipe(
        map((s) => {
          return this.defaultLayout(key);
        }),
      );
    } else {
      return of(this.defaultLayout(key));
    }
  }

  private defaultLayout(key: string) {
    if (this._defaultLayout != undefined && this._layout != undefined) {
      let defaultLayout = this._defaultLayout.filter((s) => s.key == key);
      if (defaultLayout != undefined && defaultLayout.length > 0) {
        let layout = this._layout.filter((s) => s.id == defaultLayout[0].id);
        if (defaultLayout != undefined && defaultLayout.length > 0) {
          return layout[0];
        }
      }
    }
  }

  /** Obtient l'ensemble des layout en fonction de la key */
  find(key: string): Observable<Layout[]> {
    if (this._layout == undefined) {
      return this.refresh().pipe(
        map((s) => {
          return this._layout.filter((s) => s.key == key);
        }),
      );
    } else {
      let data = this._layout.filter((s) => s.key == key);
      return of(data);
    }
  }

  /** Obtient l'ensemble des layout en fonction de la key */
  async findById(id: string): Promise<Layout> {
    if (this._layout == undefined) {
      await this.refresh().toPromise();
    }
    let data = this._layout.filter((s) => s.id == id);
    if (data != undefined && data.length > 0) {
      return data[0];
    }
    return undefined;
  }
}
