import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStockOutReasonsBaseVariables, FirstStockOutReasonsBaseVariables, CountStockOutReasonsBaseVariables, FindStockOutReasonsBaseVariables, SearchStockOutReasonsBaseVariables, ExportSchemaStockOutReasonsBaseVariables, ExportDataStockOutReasonsBaseVariables, CustomQueryStockOutReasonsBaseVariables, CustomQueryIdStockOutReasonsBaseVariables, UsedStockOutReasonsBaseVariables, ExistStockOutReasonsBaseVariables, FindUnassociatedConsumableMovementsStockOutReasonsBaseVariables, FindUnassociatedOutStockMovementsStockOutReasonsBaseVariables, InsertStockOutReasonsBaseVariables, UpdateStockOutReasonsBaseVariables, ImportDataStockOutReasonsBaseVariables, UpdateManyStockOutReasonsBaseVariables, DeleteStockOutReasonsBaseVariables, AddConsumableMovementsStockOutReasonsBaseVariables, RemoveConsumableMovementsStockOutReasonsBaseVariables, AddOutStockMovementsStockOutReasonsBaseVariables, RemoveOutStockMovementsStockOutReasonsBaseVariables } from '../gqls'
import { GetStockOutReasonsDocument, FirstStockOutReasonsDocument, CountStockOutReasonsDocument, FindStockOutReasonsDocument, SearchStockOutReasonsDocument, ExportSchemaStockOutReasonsDocument, ExportDataStockOutReasonsDocument, CustomQueryStockOutReasonsDocument, CustomQueryIdStockOutReasonsDocument, UsedStockOutReasonsDocument, ExistStockOutReasonsDocument, FindUnassociatedConsumableMovementsStockOutReasonsDocument, FindUnassociatedOutStockMovementsStockOutReasonsDocument, InsertStockOutReasonsDocument, UpdateStockOutReasonsDocument, ImportDataStockOutReasonsDocument, UpdateManyStockOutReasonsDocument, DeleteStockOutReasonsDocument, AddConsumableMovementsStockOutReasonsDocument, RemoveConsumableMovementsStockOutReasonsDocument, AddOutStockMovementsStockOutReasonsDocument, RemoveOutStockMovementsStockOutReasonsDocument } from '../gqls'
import { ServiceSingleResultOfStockOutReason, QueryContextOfStockOutReason, FilterOfStockOutReason, ServiceSingleResultOfInt64, ServiceListResultOfStockOutReason, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfConsumableMovement, FilterOfConsumableMovement, ServiceListResultOfConsumableMovement, QueryContextOfOutStockMovement, FilterOfOutStockMovement, ServiceListResultOfOutStockMovement, StockOutReasonInput, FieldUpdateOperatorOfStockOutReason } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StockOutReasonBaseService {
    
public modelName = 'stockOutReason';
public modelPluralName = 'stockOutReasons';

	private getStockOutReasonsQuery: GetStockOutReasonsDocument;
	private firstStockOutReasonsQuery: FirstStockOutReasonsDocument;
	private countStockOutReasonsQuery: CountStockOutReasonsDocument;
	private findStockOutReasonsQuery: FindStockOutReasonsDocument;
	private searchStockOutReasonsQuery: SearchStockOutReasonsDocument;
	private exportSchemaStockOutReasonsQuery: ExportSchemaStockOutReasonsDocument;
	private exportDataStockOutReasonsQuery: ExportDataStockOutReasonsDocument;
	private customQueryStockOutReasonsQuery: CustomQueryStockOutReasonsDocument;
	private customQueryIdStockOutReasonsQuery: CustomQueryIdStockOutReasonsDocument;
	private usedStockOutReasonsQuery: UsedStockOutReasonsDocument;
	private existStockOutReasonsQuery: ExistStockOutReasonsDocument;
	private findUnassociatedConsumableMovementsStockOutReasonsQuery: FindUnassociatedConsumableMovementsStockOutReasonsDocument;
	private findUnassociatedOutStockMovementsStockOutReasonsQuery: FindUnassociatedOutStockMovementsStockOutReasonsDocument;
	private insertStockOutReasonsMutation: InsertStockOutReasonsDocument;
	private updateStockOutReasonsMutation: UpdateStockOutReasonsDocument;
	private importDataStockOutReasonsMutation: ImportDataStockOutReasonsDocument;
	private updateManyStockOutReasonsMutation: UpdateManyStockOutReasonsDocument;
	private deleteStockOutReasonsMutation: DeleteStockOutReasonsDocument;
	private addConsumableMovementsStockOutReasonsMutation: AddConsumableMovementsStockOutReasonsDocument;
	private removeConsumableMovementsStockOutReasonsMutation: RemoveConsumableMovementsStockOutReasonsDocument;
	private addOutStockMovementsStockOutReasonsMutation: AddOutStockMovementsStockOutReasonsDocument;
	private removeOutStockMovementsStockOutReasonsMutation: RemoveOutStockMovementsStockOutReasonsDocument;

	constructor(private injector: Injector) {
		this.getStockOutReasonsQuery = this.injector.get(GetStockOutReasonsDocument);
		this.firstStockOutReasonsQuery = this.injector.get(FirstStockOutReasonsDocument);
		this.countStockOutReasonsQuery = this.injector.get(CountStockOutReasonsDocument);
		this.findStockOutReasonsQuery = this.injector.get(FindStockOutReasonsDocument);
		this.searchStockOutReasonsQuery = this.injector.get(SearchStockOutReasonsDocument);
		this.exportSchemaStockOutReasonsQuery = this.injector.get(ExportSchemaStockOutReasonsDocument);
		this.exportDataStockOutReasonsQuery = this.injector.get(ExportDataStockOutReasonsDocument);
		this.customQueryStockOutReasonsQuery = this.injector.get(CustomQueryStockOutReasonsDocument);
		this.customQueryIdStockOutReasonsQuery = this.injector.get(CustomQueryIdStockOutReasonsDocument);
		this.usedStockOutReasonsQuery = this.injector.get(UsedStockOutReasonsDocument);
		this.existStockOutReasonsQuery = this.injector.get(ExistStockOutReasonsDocument);
		this.findUnassociatedConsumableMovementsStockOutReasonsQuery = this.injector.get(FindUnassociatedConsumableMovementsStockOutReasonsDocument);
		this.findUnassociatedOutStockMovementsStockOutReasonsQuery = this.injector.get(FindUnassociatedOutStockMovementsStockOutReasonsDocument);
		this.insertStockOutReasonsMutation = this.injector.get(InsertStockOutReasonsDocument);
		this.updateStockOutReasonsMutation = this.injector.get(UpdateStockOutReasonsDocument);
		this.importDataStockOutReasonsMutation = this.injector.get(ImportDataStockOutReasonsDocument);
		this.updateManyStockOutReasonsMutation = this.injector.get(UpdateManyStockOutReasonsDocument);
		this.deleteStockOutReasonsMutation = this.injector.get(DeleteStockOutReasonsDocument);
		this.addConsumableMovementsStockOutReasonsMutation = this.injector.get(AddConsumableMovementsStockOutReasonsDocument);
		this.removeConsumableMovementsStockOutReasonsMutation = this.injector.get(RemoveConsumableMovementsStockOutReasonsDocument);
		this.addOutStockMovementsStockOutReasonsMutation = this.injector.get(AddOutStockMovementsStockOutReasonsDocument);
		this.removeOutStockMovementsStockOutReasonsMutation = this.injector.get(RemoveOutStockMovementsStockOutReasonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockOutReason> {
    
    		let variables: GetStockOutReasonsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.get));
            }
            else{
                let result:ServiceSingleResultOfStockOutReason={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockOutReason,
		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockOutReason> {
    
    		let variables: FirstStockOutReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStockOutReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockOutReason,
		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockOutReason> {
    
    		let variables: FindStockOutReasonsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStockOutReason,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockOutReason> {
    
    		let variables: SearchStockOutReasonsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStockOutReasonsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStockOutReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStockOutReason,
		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockOutReason> {
    
    		let variables: CustomQueryStockOutReasonsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStockOutReason,
		@Args('filter?') filter?: FilterOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockOutReason> {
    
    		let variables: CustomQueryIdStockOutReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStockOutReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.usedStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStockOutReasonsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.exist));
    	}

	@InjectArgs
	public findAssociatedConsumableMovements(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfConsumableMovement,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfConsumableMovement> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('consumableMovements', fields, null, [
			GqlSubFieldArg.create('filterOfConsumableMovements', 'filter'),
			GqlSubFieldArg.create('optionsOfConsumableMovements', 'options'),
		]),
		])
		extendedVariables['filterOfConsumableMovements'] = filter;
		extendedVariables['optionsOfConsumableMovements'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.consumableMovements));
            }
            else{
                let result: ServiceListResultOfConsumableMovement = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedConsumableMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: FindUnassociatedConsumableMovementsStockOutReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedConsumableMovementsStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.findUnassociatedConsumableMovements));
    	}

	@InjectArgs
	public findAssociatedOutStockMovements(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfOutStockMovement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfOutStockMovement,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfOutStockMovement> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('outStockMovements', fields, null, [
			GqlSubFieldArg.create('filterOfOutStockMovements', 'filter'),
			GqlSubFieldArg.create('optionsOfOutStockMovements', 'options'),
		]),
		])
		extendedVariables['filterOfOutStockMovements'] = filter;
		extendedVariables['optionsOfOutStockMovements'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.outStockMovements));
            }
            else{
                let result: ServiceListResultOfOutStockMovement = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedOutStockMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOutStockMovement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfOutStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOutStockMovement> {
    
    		let variables: FindUnassociatedOutStockMovementsStockOutReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedOutStockMovementsStockOutReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.findUnassociatedOutStockMovements));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StockOutReasonInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockOutReason> {
    
    		let variables: InsertStockOutReasonsBaseVariables = {
    			entity: entity
    		}
    				return this.insertStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockOutReason> {
    
    		let variables: UpdateStockOutReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStockOutReasonsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockOutReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStockOutReasonsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStockOutReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addConsumableMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('consumableMovementIds') consumableMovementIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddConsumableMovementsStockOutReasonsBaseVariables = {
    			id: id,
			consumableMovementIds: consumableMovementIds
    		}
    				return this.addConsumableMovementsStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.addConsumableMovements));
    	}

    	/**  */
    	@InjectArgs
    	public removeConsumableMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('consumableMovementIds') consumableMovementIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveConsumableMovementsStockOutReasonsBaseVariables = {
    			consumableMovementIds: consumableMovementIds
    		}
    				return this.removeConsumableMovementsStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.removeConsumableMovements));
    	}

    	/**  */
    	@InjectArgs
    	public addOutStockMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('outStockMovementIds') outStockMovementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOutStockMovementsStockOutReasonsBaseVariables = {
    			id: id,
			outStockMovementIds: outStockMovementIds
    		}
    				return this.addOutStockMovementsStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.addOutStockMovements));
    	}

    	/**  */
    	@InjectArgs
    	public removeOutStockMovements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('outStockMovementIds') outStockMovementIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOutStockMovementsStockOutReasonsBaseVariables = {
    			outStockMovementIds: outStockMovementIds
    		}
    				return this.removeOutStockMovementsStockOutReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockOutReasons.removeOutStockMovements));
    	}
    
}