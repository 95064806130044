import { Maybe } from 'graphql/jsutils/Maybe'

export type FieldUpdateOperator = {
    inc?: any,
    pullAll?: any,
    push?: any,
    set?: any
}

export type ServiceResultError = {
	messageError: string;
	property: string;
	validationCategory: ValidationErrorCategory;
}

export type ServiceListResult<TType> = {
    data: Array<TType>;
	errors?: ServiceResultError[];
    totalCount: number;
}

export type ServiceSingleResult<TType> = {
    data: TType;
	errors?: ServiceResultError[];
    totalCount: number;
}

/**  */
export enum ValidationErrorCategory {
	Error = 'ERROR', /**  */
	Warning = 'WARNING', /**  */
	Simplewarning = 'SIMPLEWARNING', /**  */
}

/**  */
export enum AlarmCategoryEnum {
	ParkManagement = 'PARK_MANAGEMENT', /**  */
	ServiceDesk = 'SERVICE_DESK', /**  */
}

/**  */
export enum AlarmTriggerTypeEnum {
	Update = 'UPDATE', /**  */
	Insert = 'INSERT', /**  */
	Delete = 'DELETE', /**  */
	All = 'ALL', /**  */
	PeriodicalHours = 'PERIODICAL_HOURS', /**  */
	PeriodicalDays = 'PERIODICAL_DAYS', /**  */
	Tasks = 'TASKS', /**  */
}

/**  */
export enum ManagedAlarmEnum {
	NotManaged = 'NOT_MANAGED', /**  */
	SoftwarepackageAssetSoftNotPresent = 'SOFTWAREPACKAGE_ASSET_SOFT_NOT_PRESENT', /**  */
	SoftwareAssetForbiddenPresent = 'SOFTWARE_ASSET_FORBIDDEN_PRESENT', /**  */
	SoftwareAssetObligatoryAbsent = 'SOFTWARE_ASSET_OBLIGATORY_ABSENT', /**  */
	OwnerAssetChange = 'OWNER_ASSET_CHANGE', /**  */
	AuditInError = 'AUDIT_IN_ERROR', /**  */
	ChangeMonitor = 'CHANGE_MONITOR', /**  */
	BudgetOut = 'BUDGET_OUT', /**  */
	ChangeLogonsession = 'CHANGE_LOGONSESSION', /**  */
	ChangeSoftware = 'CHANGE_SOFTWARE', /**  */
	SoftwarepackageExpire = 'SOFTWAREPACKAGE_EXPIRE', /**  */
	ContractExpire = 'CONTRACT_EXPIRE', /**  */
	AssetNoAudit = 'ASSET_NO_AUDIT', /**  */
	SoftwarepackageExpireIn = 'SOFTWAREPACKAGE_EXPIRE_IN', /**  */
	ContractExpireIn = 'CONTRACT_EXPIRE_IN', /**  */
	StockMini = 'STOCK_MINI', /**  */
	LoanExpire = 'LOAN_EXPIRE', /**  */
	LoanInbookExpire = 'LOAN_INBOOK_EXPIRE', /**  */
	AvailabilityAgent = 'AVAILABILITY_AGENT', /**  */
	AvailabilityLocalAgent = 'AVAILABILITY_LOCAL_AGENT', /**  */
	AssetRenewExpired = 'ASSET_RENEW_EXPIRED', /**  */
	AssetRenewExpireIn = 'ASSET_RENEW_EXPIRE_IN', /**  */
	LogicaldiskFreespace = 'LOGICALDISK_FREESPACE', /**  */
	MessageToOperator = 'MESSAGE_TO_OPERATOR', /**  */
	MessageToOperatorTeam = 'MESSAGE_TO_OPERATOR_TEAM', /**  */
	BudgetRemaining = 'BUDGET_REMAINING', /**  */
	ActualLowLevel = 'ACTUAL_LOW_LEVEL', /**  */
	ReminderMyIncident = 'REMINDER_MY_INCIDENT', /**  */
	ReminderMyTeamIncident = 'REMINDER_MY_TEAM_INCIDENT', /**  */
	ReminderMyRequest = 'REMINDER_MY_REQUEST', /**  */
	ReminderMyTeamRequest = 'REMINDER_MY_TEAM_REQUEST', /**  */
	StockMiniByLoc = 'STOCK_MINI_BY_LOC', /**  */
}

/**  */
export enum Sort {
	Asc = 'ASC', /**  */
	Desc = 'DESC', /**  */
}

/**  */
export enum SecurityGroupCategory {
	Manager = 'MANAGER', /**  */
	OperatorTeam = 'OPERATOR_TEAM', /**  */
	Population = 'POPULATION', /**  */
}

/**  */
export enum EntityState {
	Detached = 'DETACHED', /**  */
	Unchanged = 'UNCHANGED', /**  */
	Added = 'ADDED', /**  */
	Deleted = 'DELETED', /**  */
	Modified = 'MODIFIED', /**  */
	Associated = 'ASSOCIATED', /**  */
	Dissociated = 'DISSOCIATED', /**  */
}

/**  */
export enum PermissionScopeRole {
	None = 'NONE', /**  */
	Manager = 'MANAGER', /**  */
	HelpDeskOperator = 'HELP_DESK_OPERATOR', /**  */
	EndUser = 'END_USER', /**  */
	HelpDeskUser = 'HELP_DESK_USER', /**  */
}

/**  */
export enum PermissionAccess {
	NotSet = 'NOT_SET', /**  */
	Allow = 'ALLOW', /**  */
	Deny = 'DENY', /**  */
	Custom = 'CUSTOM', /**  */
}

/**  */
export enum RankState {
	None = 'NONE', /**  */
	First = 'FIRST', /**  */
	Last = 'LAST', /**  */
}

/**  */
export enum WarningMessageCategory {
	HelpMe = 'HELP_ME', /**  */
	Operator = 'OPERATOR', /**  */
}

/**  */
export enum UserCategory {
	User = 'USER', /**  */
	Supplier = 'SUPPLIER', /**  */
	Contact = 'CONTACT', /**  */
}

/**  */
export enum LoanBorrowerType {
	Internal = 'INTERNAL', /**  */
	External = 'EXTERNAL', /**  */
}

/**  */
export enum PhoneEnum {
	None = 'NONE', /**  */
	Office = 'OFFICE', /**  */
	Home = 'HOME', /**  */
	Mobile = 'MOBILE', /**  */
	Pager = 'PAGER', /**  */
	Fax = 'FAX', /**  */
	Ip = 'IP', /**  */
}

/**  */
export enum MemberAzureType {
	User = 'USER', /**  */
	Group = 'GROUP', /**  */
}

/**  */
export enum ByDomainEnum {
	None = 'NONE', /**  */
	BySameDomain = 'BY_SAME_DOMAIN', /**  */
	ByDifferentDomain = 'BY_DIFFERENT_DOMAIN', /**  */
}

/**  */
export enum InheritLocationCharterCategory {
	None = 'NONE', /**  */
	UserInheritAsset = 'USER_INHERIT_ASSET', /**  */
	AssetInheritUser = 'ASSET_INHERIT_USER', /**  */
}

/**  */
export enum ScanExcludeOperator {
	Equals = 'EQUALS', /**  */
	NotEquals = 'NOT_EQUALS', /**  */
}

/**  */
export enum NotAuditedType {
	None = 'NONE', /**  */
	NotAudited = 'NOT_AUDITED', /**  */
	SinceDay = 'SINCE_DAY', /**  */
}

/**  */
export enum ActionHistoryState {
	InQueue = 'IN_QUEUE', /**  */
	InProgress = 'IN_PROGRESS', /**  */
	Completed = 'COMPLETED', /**  */
	Cancel = 'CANCEL', /**  */
	None = 'NONE', /**  */
	Error = 'ERROR', /**  */
	Canceling = 'CANCELING', /**  */
}

/**  */
export enum LdapAuthType {
	Anonymous = 'ANONYMOUS', /**  */
	Basic = 'BASIC', /**  */
	Negotiate = 'NEGOTIATE', /**  */
	Ntlm = 'NTLM', /**  */
	Digest = 'DIGEST', /**  */
	Sicily = 'SICILY', /**  */
	Dpa = 'DPA', /**  */
	Msn = 'MSN', /**  */
	External = 'EXTERNAL', /**  */
	Kerberos = 'KERBEROS', /**  */
}

/**  */
export enum ImportOrganizationalUnit {
	None = 'NONE', /**  */
	ToOrganizationalUnit = 'TO_ORGANIZATIONAL_UNIT', /**  */
	ToLocation = 'TO_LOCATION', /**  */
	All = 'ALL', /**  */
}

/**  */
export enum AuditType {
	Wmi = 'WMI', /**  */
	Snmp = 'SNMP', /**  */
	Ssh = 'SSH', /**  */
	Esx = 'ESX', /**  */
	Unknow = 'UNKNOW', /**  */
	None = 'NONE', /**  */
	Xlsx = 'XLSX', /**  */
}

/**  */
export enum DomainRole {
	StandaloneWorkstation = 'STANDALONE_WORKSTATION', /**  */
	MemberWorkstation = 'MEMBER_WORKSTATION', /**  */
	StandaloneServer = 'STANDALONE_SERVER', /**  */
	MemberServer = 'MEMBER_SERVER', /**  */
	BackupDomainController = 'BACKUP_DOMAIN_CONTROLLER', /**  */
	PrimaryDomainController = 'PRIMARY_DOMAIN_CONTROLLER', /**  */
}

/**  */
export enum PcSystemType {
	Unspecified = 'UNSPECIFIED', /**  */
	Desktop = 'DESKTOP', /**  */
	Mobile = 'MOBILE', /**  */
	Workstation = 'WORKSTATION', /**  */
	EnterpriseServer = 'ENTERPRISE_SERVER', /**  */
	SmallOfficeandHomeOfficeServer = 'SMALL_OFFICEAND_HOME_OFFICE_SERVER', /**  */
	AppliancePc = 'APPLIANCE_PC', /**  */
	Maximum = 'MAXIMUM', /**  */
}

/**  */
export enum DriveType {
	Unknown = 'UNKNOWN', /**  */
	NoRootDirectory = 'NO_ROOT_DIRECTORY', /**  */
	RemovableDisk = 'REMOVABLE_DISK', /**  */
	LocalDisk = 'LOCAL_DISK', /**  */
	NetworkDrive = 'NETWORK_DRIVE', /**  */
	CompactDisc = 'COMPACT_DISC', /**  */
	RamDisk = 'RAM_DISK', /**  */
}

/**  */
export enum VmPowerState {
	Unknow = 'UNKNOW', /**  */
	PowerOn = 'POWER_ON', /**  */
	Paused = 'PAUSED', /**  */
	PowerOff = 'POWER_OFF', /**  */
	Restart = 'RESTART', /**  */
}

/**  */
export enum VmHealthStatus {
	Unknow = 'UNKNOW', /**  */
	Normal = 'NORMAL', /**  */
	Error = 'ERROR', /**  */
}

/**  */
export enum EventType {
	Error = 'ERROR', /**  */
	Warning = 'WARNING', /**  */
	Information = 'INFORMATION', /**  */
	SecurityAuditSuccess = 'SECURITY_AUDIT_SUCCESS', /**  */
	SecurityAuditFailure = 'SECURITY_AUDIT_FAILURE', /**  */
}

/**  */
export enum FormFactor {
	Unknown = 'UNKNOWN', /**  */
	Other = 'OTHER', /**  */
	Sip = 'SIP', /**  */
	Dip = 'DIP', /**  */
	Zip = 'ZIP', /**  */
	Soj = 'SOJ', /**  */
	Proprietary = 'PROPRIETARY', /**  */
	Simm = 'SIMM', /**  */
	Dimm = 'DIMM', /**  */
	Tsop = 'TSOP', /**  */
	Pga = 'PGA', /**  */
	Rimm = 'RIMM', /**  */
	Sodimm = 'SODIMM', /**  */
	Srimm = 'SRIMM', /**  */
	Smd = 'SMD', /**  */
	Ssmp = 'SSMP', /**  */
	Qfp = 'QFP', /**  */
	Tqfp = 'TQFP', /**  */
	Soic = 'SOIC', /**  */
	Lcc = 'LCC', /**  */
	Plcc = 'PLCC', /**  */
	Bga = 'BGA', /**  */
	Fpbga = 'FPBGA', /**  */
	Lga = 'LGA', /**  */
}

/**  */
export enum MemoryType {
	Unknown = 'UNKNOWN', /**  */
	Other = 'OTHER', /**  */
	Dram = 'DRAM', /**  */
	SynchronousDram = 'SYNCHRONOUS_DRAM', /**  */
	CacheDram = 'CACHE_DRAM', /**  */
	Edo = 'EDO', /**  */
	Edram = 'EDRAM', /**  */
	Vram = 'VRAM', /**  */
	Sram = 'SRAM', /**  */
	Ram = 'RAM', /**  */
	Rom = 'ROM', /**  */
	Flash = 'FLASH', /**  */
	Eeprom = 'EEPROM', /**  */
	Feprom = 'FEPROM', /**  */
	Eprom = 'EPROM', /**  */
	Cdram = 'CDRAM', /**  */
	D_3Ram = 'D_3_RAM', /**  */
	Sdram = 'SDRAM', /**  */
	Sgram = 'SGRAM', /**  */
	Rdram = 'RDRAM', /**  */
	Ddr = 'DDR', /**  */
	Ddr_2 = 'DDR_2', /**  */
	Ddr_2Fbdimm = 'DDR_2_FBDIMM', /**  */
	Ddr_3 = 'DDR_3', /**  */
	Fbd_2 = 'FBD_2', /**  */
	Ddr_4 = 'DDR_4', /**  */
}

/**  */
export enum Architecture {
	X_86 = 'X_86', /**  */
	Mips = 'MIPS', /**  */
	Alpha = 'ALPHA', /**  */
	PowerPc = 'POWER_PC', /**  */
	Arm = 'ARM', /**  */
	Ia_64 = 'IA_64', /**  */
	X_64 = 'X_64', /**  */
}

/**  */
export enum Availability {
	Unknow = 'UNKNOW', /**  */
	Other = 'OTHER', /**  */
	Unknown = 'UNKNOWN', /**  */
	RunningOrFullPower = 'RUNNING_OR_FULL_POWER', /**  */
	Warning = 'WARNING', /**  */
	InTest = 'IN_TEST', /**  */
	NotApplicable = 'NOT_APPLICABLE', /**  */
	PowerOff = 'POWER_OFF', /**  */
	OffLine = 'OFF_LINE', /**  */
	OffDuty = 'OFF_DUTY', /**  */
	Degraded = 'DEGRADED', /**  */
	NotInstalled = 'NOT_INSTALLED', /**  */
	InstallError = 'INSTALL_ERROR', /**  */
	PowerSaveUnknown = 'POWER_SAVE_UNKNOWN', /**  */
	PowerSaveLowPowerMode = 'POWER_SAVE_LOW_POWER_MODE', /**  */
	PowerSaveStandby = 'POWER_SAVE_STANDBY', /**  */
	PowerCycle = 'POWER_CYCLE', /**  */
	PowerSaveWarning = 'POWER_SAVE_WARNING', /**  */
}

/**  */
export enum StartMode {
	Boot = 'BOOT', /**  */
	System = 'SYSTEM', /**  */
	Auto = 'AUTO', /**  */
	Manual = 'MANUAL', /**  */
	Disabled = 'DISABLED', /**  */
	Unknown = 'UNKNOWN', /**  */
}

/**  */
export enum ServiceState {
	Unknown = 'UNKNOWN', /**  */
	Stopped = 'STOPPED', /**  */
	StartPending = 'START_PENDING', /**  */
	StopPending = 'STOP_PENDING', /**  */
	Running = 'RUNNING', /**  */
	Continue = 'CONTINUE', /**  */
	PausePending = 'PAUSE_PENDING', /**  */
	Paused = 'PAUSED', /**  */
}

/**  */
export enum ShareCategory {
	DiskDrive = 'DISK_DRIVE', /**  */
	PrintQueue = 'PRINT_QUEUE', /**  */
	Device = 'DEVICE', /**  */
	Ipc = 'IPC', /**  */
	DiskDriveAdmin = 'DISK_DRIVE_ADMIN', /**  */
	PrintQueueAdmin = 'PRINT_QUEUE_ADMIN', /**  */
	DeviceAdmin = 'DEVICE_ADMIN', /**  */
	IpcAdmin = 'IPC_ADMIN', /**  */
}

/**  */
export enum SqlServiceCategory {
	SqlServer = 'SQL_SERVER', /**  */
	SqlServerAgent = 'SQL_SERVER_AGENT', /**  */
	FteSql = 'FTE_SQL', /**  */
	DtsServer = 'DTS_SERVER', /**  */
	ServerOlapService = 'SERVER_OLAP_SERVICE', /**  */
	ReportServer = 'REPORT_SERVER', /**  */
	SqlBrowser = 'SQL_BROWSER', /**  */
	NsService = 'NS_SERVICE', /**  */
	MsSqlFdLauncher = 'MS_SQL_FD_LAUNCHER', /**  */
	SqlPbEngine = 'SQL_PB_ENGINE', /**  */
	SqlPbDms = 'SQL_PB_DMS', /**  */
	MsSqlLaunchpad = 'MS_SQL_LAUNCHPAD', /**  */
}

/**  */
export enum ChassisType {
	Other = 'OTHER', /**  */
	Unknown = 'UNKNOWN', /**  */
	Desktop = 'DESKTOP', /**  */
	LowProfileDesktop = 'LOW_PROFILE_DESKTOP', /**  */
	PizzaBox = 'PIZZA_BOX', /**  */
	MiniTower = 'MINI_TOWER', /**  */
	Tower = 'TOWER', /**  */
	Portable = 'PORTABLE', /**  */
	Laptop = 'LAPTOP', /**  */
	Notebook = 'NOTEBOOK', /**  */
	HandHeld = 'HAND_HELD', /**  */
	DockingStation = 'DOCKING_STATION', /**  */
	AllinOne = 'ALLIN_ONE', /**  */
	SubNotebook = 'SUB_NOTEBOOK', /**  */
	SpaceSaving = 'SPACE_SAVING', /**  */
	LunchBox = 'LUNCH_BOX', /**  */
	MainSystemChassis = 'MAIN_SYSTEM_CHASSIS', /**  */
	ExpansionChassis = 'EXPANSION_CHASSIS', /**  */
	SubChassis = 'SUB_CHASSIS', /**  */
	BusExpansionChassis = 'BUS_EXPANSION_CHASSIS', /**  */
	PeripheralChassis = 'PERIPHERAL_CHASSIS', /**  */
	StorageChassis = 'STORAGE_CHASSIS', /**  */
	RackMountChassis = 'RACK_MOUNT_CHASSIS', /**  */
	SealedCasePc = 'SEALED_CASE_PC', /**  */
	MultiSystemChassis = 'MULTI_SYSTEM_CHASSIS', /**  */
	CompactPci = 'COMPACT_PCI', /**  */
	AdvancedTca = 'ADVANCED_TCA', /**  */
	Blade = 'BLADE', /**  */
	BladeEnclosure = 'BLADE_ENCLOSURE', /**  */
	Tablet = 'TABLET', /**  */
	Convertible = 'CONVERTIBLE', /**  */
	Detachable = 'DETACHABLE', /**  */
	IotGateway = 'IOT_GATEWAY', /**  */
	EmbeddedPc = 'EMBEDDED_PC', /**  */
	MiniPc = 'MINI_PC', /**  */
	StickPc = 'STICK_PC', /**  */
}

/**  */
export enum DockingMode {
	None = 'NONE', /**  */
	Undocked = 'UNDOCKED', /**  */
	Docked = 'DOCKED', /**  */
	Unknow = 'UNKNOW', /**  */
}

/**  */
export enum ConsentPromptBehaviorAdmin {
	NoPrompt = 'NO_PROMPT', /**  */
	PromptAdminPassword = 'PROMPT_ADMIN_PASSWORD', /**  */
	ApproveSecureDesktop = 'APPROVE_SECURE_DESKTOP', /**  */
	PromptUserPassword = 'PROMPT_USER_PASSWORD', /**  */
	PromptOnly = 'PROMPT_ONLY', /**  */
	PromptNonWindowsBinaries = 'PROMPT_NON_WINDOWS_BINARIES', /**  */
}

/**  */
export enum ConsentPromptBehaviorUser {
	RequiredElevated = 'REQUIRED_ELEVATED', /**  */
	PromptAdminPassword = 'PROMPT_ADMIN_PASSWORD', /**  */
	PromptUserPassword = 'PROMPT_USER_PASSWORD', /**  */
}

/**  */
export enum LogonType {
	SystemAccount = 'SYSTEM_ACCOUNT', /**  */
	Interactive = 'INTERACTIVE', /**  */
	Network = 'NETWORK', /**  */
	Batch = 'BATCH', /**  */
	Service = 'SERVICE', /**  */
	Proxy = 'PROXY', /**  */
	Unlock = 'UNLOCK', /**  */
	NetworkCleartext = 'NETWORK_CLEARTEXT', /**  */
	NewCredentials = 'NEW_CREDENTIALS', /**  */
	RemoteInteractive = 'REMOTE_INTERACTIVE', /**  */
	CachedInteractive = 'CACHED_INTERACTIVE', /**  */
	CachedRemoteInteractive = 'CACHED_REMOTE_INTERACTIVE', /**  */
	CachedUnlock = 'CACHED_UNLOCK', /**  */
}

/**  */
export enum SerialNumberMode {
	Microsoft = 'MICROSOFT', /**  */
	Adobe = 'ADOBE', /**  */
	Other = 'OTHER', /**  */
	AutoSearch = 'AUTO_SEARCH', /**  */
	Microsoft_2 = 'MICROSOFT_2', /**  */
}

/**  */
export enum UptimeCalendarMode {
	Startup = 'STARTUP', /**  */
	Shutdown = 'SHUTDOWN', /**  */
}

/**  */
export enum BatteryStatus {
	Unknow = 'UNKNOW', /**  */
	Discharging = 'DISCHARGING', /**  */
	Plug = 'PLUG', /**  */
	FullyCharged = 'FULLY_CHARGED', /**  */
	Low = 'LOW', /**  */
	Critical = 'CRITICAL', /**  */
	Charging = 'CHARGING', /**  */
	ChargingAndHigh = 'CHARGING_AND_HIGH', /**  */
	ChargingAndLow = 'CHARGING_AND_LOW', /**  */
	ChargingAndCritical = 'CHARGING_AND_CRITICAL', /**  */
	Undefined = 'UNDEFINED', /**  */
	PartiallyCharged = 'PARTIALLY_CHARGED', /**  */
}

/**  */
export enum Chemistry {
	Unknow = 'UNKNOW', /**  */
	Other = 'OTHER', /**  */
	Unknown = 'UNKNOWN', /**  */
	LeadAcid = 'LEAD_ACID', /**  */
	NickelCadmium = 'NICKEL_CADMIUM', /**  */
	NickelMetalHydride = 'NICKEL_METAL_HYDRIDE', /**  */
	LithiumIon = 'LITHIUM_ION', /**  */
	ZincAir = 'ZINC_AIR', /**  */
	LithiumPolymer = 'LITHIUM_POLYMER', /**  */
}

/**  */
export enum LogonHistoryMode {
	Startup = 'STARTUP', /**  */
	Shutdown = 'SHUTDOWN', /**  */
}

/**  */
export enum ConversionStatus {
	FullyDecrypted = 'FULLY_DECRYPTED', /**  */
	FullyEncrypted = 'FULLY_ENCRYPTED', /**  */
	EncryptionInProgress = 'ENCRYPTION_IN_PROGRESS', /**  */
	DecryptionInProgress = 'DECRYPTION_IN_PROGRESS', /**  */
	EncryptionPaused = 'ENCRYPTION_PAUSED', /**  */
	DecryptionPaused = 'DECRYPTION_PAUSED', /**  */
}

/**  */
export enum EncryptionMethod {
	None = 'NONE', /**  */
	Aes_128WithDiffuser = 'AES_128_WITH_DIFFUSER', /**  */
	Aes_256WithDiffuser = 'AES_256_WITH_DIFFUSER', /**  */
	Aes_128 = 'AES_128', /**  */
	Aes_256 = 'AES_256', /**  */
	HardwareEncryption = 'HARDWARE_ENCRYPTION', /**  */
	XtsAes_128 = 'XTS_AES_128', /**  */
	XtsAes_256 = 'XTS_AES_256', /**  */
}

/**  */
export enum ProtectionStatus {
	Off = 'OFF', /**  */
	On = 'ON', /**  */
	Unknown = 'UNKNOWN', /**  */
}

/**  */
export enum SnmpType {
	Unknow = 'UNKNOW', /**  */
	Switch = 'SWITCH', /**  */
	Printer = 'PRINTER', /**  */
	ThinClient = 'THIN_CLIENT', /**  */
	AccessPoint = 'ACCESS_POINT', /**  */
}

/**  */
export enum UpdaterState {
	None = 'NONE', /**  */
	Download = 'DOWNLOAD', /**  */
	Install = 'INSTALL', /**  */
	Updated = 'UPDATED', /**  */
	Error = 'ERROR', /**  */
}

/**  */
export enum ScheduleTaskStatus {
	WaitForExec = 'WAIT_FOR_EXEC', /**  */
	Exec = 'EXEC', /**  */
	Terminated = 'TERMINATED', /**  */
	WaitingEndInitialization = 'WAITING_END_INITIALIZATION', /**  */
}

/**  */
export enum CronJobHourlyType {
	Default = 'DEFAULT', /**  */
	DailyTicketStatistic = 'DAILY_TICKET_STATISTIC', /**  */
}

/**  */
export enum TicketStatisticModifier {
	Status = 'STATUS', /**  */
	AffectedOperator = 'AFFECTED_OPERATOR', /**  */
	AffectedOperatorTeam = 'AFFECTED_OPERATOR_TEAM', /**  */
	Creation = 'CREATION', /**  */
	NotManaged = 'NOT_MANAGED', /**  */
	Priority = 'PRIORITY', /**  */
}

/**  */
export enum WorkflowTrigger {
	Save = 'SAVE', /**  */
	Delete = 'DELETE', /**  */
	Autonomous = 'AUTONOMOUS', /**  */
	Form = 'FORM', /**  */
}

/**  */
export enum ApiHttpMethod {
	Get = 'GET', /**  */
	Post = 'POST', /**  */
	Put = 'PUT', /**  */
	Patch = 'PATCH', /**  */
	Delete = 'DELETE', /**  */
}

/**  */
export enum HttpOutputSendAttachment {
	None = 'NONE', /**  */
	AllInOne = 'ALL_IN_ONE', /**  */
	New = 'NEW', /**  */
}

/**  */
export enum EmailPrototypeTriggerTypeEnum {
	Insert = 'INSERT', /**  */
	PeriodicalHours = 'PERIODICAL_HOURS', /**  */
	PeriodicalDays = 'PERIODICAL_DAYS', /**  */
}

/**  */
export enum UserOrAsset {
	User = 'USER', /**  */
	Asset = 'ASSET', /**  */
}

/**  */
export enum HKey {
	None = 'NONE', /**  */
	HkeyClassesRoot = 'HKEY_CLASSES_ROOT', /**  */
	HkeyCurrentUser = 'HKEY_CURRENT_USER', /**  */
	HkeyLocalMachine = 'HKEY_LOCAL_MACHINE', /**  */
	HkeyUsers = 'HKEY_USERS', /**  */
	HkeyCurrentConfig = 'HKEY_CURRENT_CONFIG', /**  */
}

/**  */
export enum SoftwareAllowedType {
	None = 'NONE', /**  */
	NotAllowed = 'NOT_ALLOWED', /**  */
	Obligatory = 'OBLIGATORY', /**  */
}

/**  */
export enum SoftwareCategory {
	OperatingSystem = 'OPERATING_SYSTEM', /**  */
	Program = 'PROGRAM', /**  */
	Update = 'UPDATE', /**  */
	WorkApplication = 'WORK_APPLICATION', /**  */
}

/**  */
export enum FrequencyDeadline {
	Month = 'MONTH', /**  */
	Trimester = 'TRIMESTER', /**  */
	Semester = 'SEMESTER', /**  */
	Year = 'YEAR', /**  */
}

/**  */
export enum AssetModelCategory {
	Asset = 'ASSET', /**  */
	Consumable = 'CONSUMABLE', /**  */
}

/**  */
export enum LifetimeStartDateSelection {
	Bill = 'BILL', /**  */
	Service = 'SERVICE', /**  */
	Warranty = 'WARRANTY', /**  */
	EndWarranty = 'END_WARRANTY', /**  */
	Delivery = 'DELIVERY', /**  */
}

/**  */
export enum AssetModelMinimumStockManagement {
	General = 'GENERAL', /**  */
	ByStorageUnit = 'BY_STORAGE_UNIT', /**  */
}

/**  */
export enum AssetModelStockEntryStatus {
	InStock = 'IN_STOCK', /**  */
	InService = 'IN_SERVICE', /**  */
}

/**  */
export enum ObjectOidMap {
	Model = 'MODEL', /**  */
	Serial = 'SERIAL', /**  */
	MacAddress = 'MAC_ADDRESS', /**  */
	ConsumableName = 'CONSUMABLE_NAME', /**  */
	ConsumableActualLevel = 'CONSUMABLE_ACTUAL_LEVEL', /**  */
	ConsumableMaxLevel = 'CONSUMABLE_MAX_LEVEL', /**  */
	ConsumableCode = 'CONSUMABLE_CODE', /**  */
	SubnetMask = 'SUBNET_MASK', /**  */
	Processor = 'PROCESSOR', /**  */
	Comment = 'COMMENT', /**  */
	MemorySize = 'MEMORY_SIZE', /**  */
	Gateway = 'GATEWAY', /**  */
	PrinterPageTotalCount = 'PRINTER_PAGE_TOTAL_COUNT', /**  */
	PrinterPageColorCount = 'PRINTER_PAGE_COLOR_COUNT', /**  */
	PrinterPageBlackCount = 'PRINTER_PAGE_BLACK_COUNT', /**  */
	PrinterPageTwoColorCount = 'PRINTER_PAGE_TWO_COLOR_COUNT', /**  */
	PrinterFaxReceiveCount = 'PRINTER_FAX_RECEIVE_COUNT', /**  */
	PrinterCopyTotalCount = 'PRINTER_COPY_TOTAL_COUNT', /**  */
	PrinterCopyColorCount = 'PRINTER_COPY_COLOR_COUNT', /**  */
	PrinterCopyBlackCount = 'PRINTER_COPY_BLACK_COUNT', /**  */
	PrinterCopyTwoColorCount = 'PRINTER_COPY_TWO_COLOR_COUNT', /**  */
	SwitchEntryTable = 'SWITCH_ENTRY_TABLE', /**  */
	SwitchConnectedTable = 'SWITCH_CONNECTED_TABLE', /**  */
	ElementName = 'ELEMENT_NAME', /**  */
	NetBios = 'NET_BIOS', /**  */
	PrinterFaxSendCount = 'PRINTER_FAX_SEND_COUNT', /**  */
}

/**  */
export enum SnmpVersion {
	Ver_1 = 'VER_1', /**  */
	Ver_2 = 'VER_2', /**  */
	Ver_3 = 'VER_3', /**  */
}

/**  */
export enum AuthentificationSnmpV3 {
	Md_5 = 'MD_5', /**  */
	Sha = 'SHA', /**  */
}

/**  */
export enum EncryptionSnmpV3 {
	Des = 'DES', /**  */
	Aes = 'AES', /**  */
}

/**  */
export enum ScanExcludeCategory {
	Computer = 'COMPUTER', /**  */
	User = 'USER', /**  */
}

/**  */
export enum ScanDataFileObject {
	Extension = 'EXTENSION', /**  */
	File = 'FILE', /**  */
}

/**  */
export enum CompareToType {
	LessOrEqual = 'LESS_OR_EQUAL', /**  */
	Less = 'LESS', /**  */
	Equal = 'EQUAL', /**  */
	NotEqual = 'NOT_EQUAL', /**  */
	Greater = 'GREATER', /**  */
	GreaterOrEqual = 'GREATER_OR_EQUAL', /**  */
}

/**  */
export enum SerialNumberSearch {
	Equal = 'EQUAL', /**  */
	Contains = 'CONTAINS', /**  */
}

/**  */
export enum ImportUserMode {
	Always = 'ALWAYS', /**  */
	OnlyEmpty = 'ONLY_EMPTY', /**  */
	Never = 'NEVER', /**  */
}

/**  */
export enum MainUserMode {
	Always = 'ALWAYS', /**  */
	OnlyEmpty = 'ONLY_EMPTY', /**  */
	Never = 'NEVER', /**  */
}

/**  */
export enum HistoryState {
	InQueue = 'IN_QUEUE', /**  */
	InProgress = 'IN_PROGRESS', /**  */
	Completed = 'COMPLETED', /**  */
	InError = 'IN_ERROR', /**  */
	Success = 'SUCCESS', /**  */
	AllByScanConfiguration = 'ALL_BY_SCAN_CONFIGURATION', /**  */
	Warning = 'WARNING', /**  */
	AllFinishedByLastScan = 'ALL_FINISHED_BY_LAST_SCAN', /**  */
	Exclude = 'EXCLUDE', /**  */
	AllByLastScan = 'ALL_BY_LAST_SCAN', /**  */
	AllInError = 'ALL_IN_ERROR', /**  */
	Cancel = 'CANCEL', /**  */
	All = 'ALL', /**  */
}

/**  */
export enum CampaignStatusEnum {
	Created = 'CREATED', /**  */
	Published = 'PUBLISHED', /**  */
	Finished = 'FINISHED', /**  */
	Failed = 'FAILED', /**  */
}

/**  */
export enum TicketEmailAction {
	Send = 'SEND', /**  */
	Receive = 'RECEIVE', /**  */
}

/**  */
export enum TicketBacklogType {
	Backlog = 'BACKLOG', /**  */
}

/**  */
export enum LdapObjectClassType {
	User = 'USER', /**  */
	Organization = 'ORGANIZATION', /**  */
	Role = 'ROLE', /**  */
	Asset = 'ASSET', /**  */
	Container = 'CONTAINER', /**  */
	Root = 'ROOT', /**  */
	All = 'ALL', /**  */
	AzureUser = 'AZURE_USER', /**  */
}

/**  */
export enum UpdateLdapValue {
	Never = 'NEVER', /**  */
	Always = 'ALWAYS', /**  */
	OnlyNotNull = 'ONLY_NOT_NULL', /**  */
}

/**  */
export enum SftpAction {
	Test = 'TEST', /**  */
	Upload = 'UPLOAD', /**  */
	Download = 'DOWNLOAD', /**  */
}

/**  */
export enum EmailConnectorTypeEnum {
	Smtp = 'SMTP', /**  */
	Pop_3 = 'POP_3', /**  */
	Imap = 'IMAP', /**  */
	One = 'ONE', /**  */
	Office_365 = 'OFFICE_365', /**  */
}

/**  */
export enum ApplyOption {
	IfEmpty = 'IF_EMPTY', /**  */
	Always = 'ALWAYS', /**  */
}

/**  */
export enum LocationCharterCategory {
	Group = 'GROUP', /**  */
	OrganizationalUnit = 'ORGANIZATIONAL_UNIT', /**  */
	UserProperties = 'USER_PROPERTIES', /**  */
	AssetProperties = 'ASSET_PROPERTIES', /**  */
	Ldap = 'LDAP', /**  */
}

/**  */
export enum ExchangeConnectorTypeEnum {
	Exchange = 'EXCHANGE', /**  */
	Office_365 = 'OFFICE_365', /**  */
}

/**  */
export enum FormDesignerTo {
	HelpMe = 'HELP_ME', /**  */
	Operator = 'OPERATOR', /**  */
}

/**  */
export enum LdapAnalyseFindType {
	None = 'NONE', /**  */
	DistinguishedName = 'DISTINGUISHED_NAME', /**  */
	Netbios = 'NETBIOS', /**  */
	Name = 'NAME', /**  */
}

/**  */
export enum NotificationKey {
	MessageIncident = 'MESSAGE_INCIDENT', /**  */
	MessageRequest = 'MESSAGE_REQUEST', /**  */
	MessageRequestTeam = 'MESSAGE_REQUEST_TEAM', /**  */
	MessageIncidentTeam = 'MESSAGE_INCIDENT_TEAM', /**  */
	EmailIncident = 'EMAIL_INCIDENT', /**  */
	EmailRequest = 'EMAIL_REQUEST', /**  */
	EmailProblem = 'EMAIL_PROBLEM', /**  */
	EmailIncidentTeam = 'EMAIL_INCIDENT_TEAM', /**  */
	EmailRequestTeam = 'EMAIL_REQUEST_TEAM', /**  */
	EmailProblemTeam = 'EMAIL_PROBLEM_TEAM', /**  */
}

/**  */
export enum CommandCategory {
	RemoteControl = 'REMOTE_CONTROL', /**  */
	Manage = 'MANAGE', /**  */
	ItRemote = 'IT_REMOTE', /**  */
}

/**  */
export enum CommandProtocole {
	Http = 'HTTP', /**  */
	Https = 'HTTPS', /**  */
	Telnet = 'TELNET', /**  */
	Ssh = 'SSH', /**  */
}

/**  */
export enum ListDesignerType {
	HelpDeskOperator = 'HELP_DESK_OPERATOR', /**  */
	HelpDeskUser = 'HELP_DESK_USER', /**  */
}

/**  */
export enum NamingConventionCategory {
	BarCode = 'BAR_CODE', /**  */
	Name = 'NAME', /**  */
	InventoryNumber = 'INVENTORY_NUMBER', /**  */
	NetBios = 'NET_BIOS', /**  */
	AssetNumber = 'ASSET_NUMBER', /**  */
}

/**  */
export enum ImportFileFormat {
	Csv = 'CSV', /**  */
	Json = 'JSON', /**  */
	Xml = 'XML', /**  */
	Xlsx = 'XLSX', /**  */
}

/**  */
export enum DynamicPropertyFieldEnum {
	String = 'STRING', /**  */
	Numeric = 'NUMERIC', /**  */
	Html = 'HTML', /**  */
	Bool = 'BOOL', /**  */
	DateTime = 'DATE_TIME', /**  */
	Email = 'EMAIL', /**  */
	Link = 'LINK', /**  */
	Image = 'IMAGE', /**  */
	SelectList = 'SELECT_LIST', /**  */
}

/**  */
export enum DynamicPropertyNumericFormat {
	Decimal = 'DECIMAL', /**  */
	Int = 'INT', /**  */
}

/**  */
export enum DynamicPropertyDateFormat {
	ShortDate = 'SHORT_DATE', /**  */
	LongDate = 'LONG_DATE', /**  */
	Time = 'TIME', /**  */
}

/**  */
export enum DynamicPropertyLocationEnum {
	Full = 'FULL', /**  */
	Before = 'BEFORE', /**  */
	Center = 'CENTER', /**  */
	After = 'AFTER', /**  */
}

/**  */
export enum AgentTokenAction {
	Import = 'IMPORT', /**  */
	Download = 'DOWNLOAD', /**  */
}

/**  */
export enum Period {
	All = 'ALL', /**  */
	NextMonth = 'NEXT_MONTH', /**  */
	CurrentYear = 'CURRENT_YEAR', /**  */
	NextYear = 'NEXT_YEAR', /**  */
}

/**  */
export enum BadgeType {
	String = 'STRING', /**  */
	Boolean = 'BOOLEAN', /**  */
	Int = 'INT', /**  */
}

/**  */
export enum BarcodeFormat {
	Code_128 = 'CODE_128', /**  */
	Code_39 = 'CODE_39', /**  */
	Ean_13 = 'EAN_13', /**  */
	Ean_8 = 'EAN_8', /**  */
	Ean_5 = 'EAN_5', /**  */
	Ean_2 = 'EAN_2', /**  */
	Upc = 'UPC', /**  */
	Itf_14 = 'ITF_14', /**  */
	Msi = 'MSI', /**  */
	Msi_10 = 'MSI_10', /**  */
	Msi_11 = 'MSI_11', /**  */
	Msi_1010 = 'MSI_1010', /**  */
	Msi_1110 = 'MSI_1110', /**  */
}

/**  */
export enum LayoutModelCategory {
	Form = 'FORM', /**  */
	List = 'LIST', /**  */
}

/**  */
export enum OneEventLogAction {
	Unknow = 'UNKNOW', /**  */
	Update = 'UPDATE', /**  */
	Insert = 'INSERT', /**  */
	Delete = 'DELETE', /**  */
}

/**  */
export enum LicenseTypeEnum {
	Trial = 'TRIAL', /**  */
	Standard = 'STANDARD', /**  */
}

/**  */
export enum Civility {
	Mr = 'MR', /**  */
	Mrs = 'MRS', /**  */
}

/**  */
export enum OrganizationStatus {
	WaintingApproval = 'WAINTING_APPROVAL', /**  */
	Active = 'ACTIVE', /**  */
	WaitingDelete = 'WAITING_DELETE', /**  */
}

/**  */
export enum NamingConventionAction {
	BarCode = 'BAR_CODE', /**  */
	Name = 'NAME', /**  */
	InventoryNumber = 'INVENTORY_NUMBER', /**  */
	NetBios = 'NET_BIOS', /**  */
	AssetNumber = 'ASSET_NUMBER', /**  */
	AccountingNumber = 'ACCOUNTING_NUMBER', /**  */
	CostAccountingNumber = 'COST_ACCOUNTING_NUMBER', /**  */
	MacAddress = 'MAC_ADDRESS', /**  */
	IpAddress = 'IP_ADDRESS', /**  */
	DateTime = 'DATE_TIME', /**  */
	Increment = 'INCREMENT', /**  */
	Fqdn = 'FQDN', /**  */
	SerialNumber = 'SERIAL_NUMBER', /**  */
	Manufacturer = 'MANUFACTURER', /**  */
	Model = 'MODEL', /**  */
	Date = 'DATE', /**  */
}

/**  */
export enum ApplyPolicy {
	BeforeResolver = 'BEFORE_RESOLVER', /**  */
	AfterResolver = 'AFTER_RESOLVER', /**  */
}

/**  */
export type IFilterType = {
	type?: Maybe<string> /**  */
} 

/**  */
export type Permission = {
	inheritType?: Array<Maybe<string>> /**  */
	inheritFrom?: Maybe<string> /**  */
	order: number /**  */
	value?: Maybe<string> /**  */
	access: PermissionAccess /**  */
	inheritFromIds: Array<string> /**  */
} 

/**  */
export type IAccount = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
} 

/**  */
export type ITemplate = {
	templateId?: Maybe<string> /**  */
} 

/**  */
export type IPopulationSecurityGroupObject = {
	id?: string /**  */
	isManual: boolean /**  */
} 

/**  */
export type GroupUser = {
	id?: string /**  */
	userCaption?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	userDomain?: Maybe<string> /**  */
	userSid?: Maybe<string> /**  */
} 

/**  */
export type ISecurityPopulationBase = {
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups?: Array<Maybe<string>> /**  */
	memberAzureGroupObjectIds?: Array<Maybe<string>> /**  */
} 

/**  */
export type IAllowedTicketCategory = {
	ticketCategoryVisible: boolean /**  */
	ticketCategoryAllowed: boolean /**  */
	allowedTicketsCategories?: Array<Maybe<AllowedEntity>> /**  */
} 

/**  */
export type IUserPropertyPopulation = {
	isManager: boolean /**  */
} 

/**  */
export type IAllowedLocation = {
	locationVisible: boolean /**  */
	locationAllowed: boolean /**  */
	allowedLocations?: Array<Maybe<AllowedEntity>> /**  */
} 

/**  */
export type IAllowedOrganizationalUnit = {
	organizationalUnitVisible: boolean /**  */
	organizationalUnitAllowed: boolean /**  */
	allowedOrganizationalUnits?: Array<Maybe<AllowedEntity>> /**  */
} 

/**  */
export type IPopulation = {
	populationIds: Array<string> /**  */
} 

/**  */
export type FileMetaData = {
	id?: Maybe<string> /**  */
	encoding?: Maybe<string> /**  */
	size: number /**  */
	type?: Maybe<string> /**  */
	indexChunk: number /**  */
	temp: boolean /**  */
	commentary?: Maybe<string> /**  */
	linkeds?: Array<Maybe<LinkedInfo>> /**  */
	tenantId?: Maybe<string> /**  */
	uploadedBy?: Maybe<string> /**  */
	visibleByHelpDeskUser: boolean /**  */
	outgoingEmails: boolean /**  */
} 

/**  */
export type LinkedInfo = {
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	entityProperty?: Maybe<string> /**  */
	classType?: Maybe<string> /**  */
} 

/**  */
export type WorkTimeConfigTime = {
	start?: Maybe<Date> /**  */
	startTick: number /**  */
	end?: Maybe<Date> /**  */
	endTick: number /**  */
} 

/**  */
export type WorkTimeConfigDay = {
	enabled: boolean /**  */
	times?: Array<Maybe<WorkTimeConfigTime>> /**  */
} 

/**  */
export type DayOffConfig = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type WorkTimeConfig = {
	monday?: Maybe<WorkTimeConfigDay> /**  */
	tuesday?: Maybe<WorkTimeConfigDay> /**  */
	wednesday?: Maybe<WorkTimeConfigDay> /**  */
	thursday?: Maybe<WorkTimeConfigDay> /**  */
	friday?: Maybe<WorkTimeConfigDay> /**  */
	saturday?: Maybe<WorkTimeConfigDay> /**  */
	sunday?: Maybe<WorkTimeConfigDay> /**  */
} 

/**  */
export type FileModel = {
	attachmentTicket?: Maybe<ServiceListResultOfTicket> /**  */
	countAttachmentTicket: number /**  */
	fileId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	uploaded?: Maybe<Date> /**  */
	size: number /**  */
	type?: Maybe<string> /**  */
	objectId?: Maybe<string> /**  */
	id?: string /**  */
	commentary?: Maybe<string> /**  */
	metaData?: Maybe<FileMetaData> /**  */
} 

/**  */
export type IAgentHistory = {
	id?: string /**  */
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	host?: Maybe<string> /**  */
	clarilogException?: Maybe<ClarilogException> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRule> /**  */
	hidden: boolean /**  */
} 

/**  */
export type AsyncTaskParam = {
	default?: Maybe<string> /**  */
} 

/**  */
export type ScheduleTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
} & IScheduleTask

/**  */
export type HttpOutput = {
	method: ApiHttpMethod /**  */
	body?: Maybe<string> /**  */
	headers?: Array<Maybe<HttpRequestParams>> /**  */
	url?: Maybe<string> /**  */
	queryParams?: Array<Maybe<HttpRequestParams>> /**  */
	attachmentSetting: HttpOutputSendAttachment /**  */
	attachmentArrayProperty?: Maybe<string> /**  */
	attachmentFileName?: Maybe<string> /**  */
	attachmentSize?: Maybe<string> /**  */
	attachmentType?: Maybe<string> /**  */
	attachmentFileValue?: Maybe<string> /**  */
	attachmentLinkedFileIds?: Maybe<string> /**  */
	linkedObjectIdPath?: Maybe<string> /**  */
	bodyParams?: Array<Maybe<HttpRequestParams>> /**  */
} 

/**  */
export type HttpRequestParams = {
	key?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	enable: boolean /**  */
} 

/**  */
export type IEmailPrototypeBase = {
	enabled: boolean /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	from?: Maybe<string> /**  */
	to?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	attachedResult: boolean /**  */
	triggerType: EmailPrototypeTriggerTypeEnum /**  */
	time?: Maybe<Date> /**  */
} 

/**  */
export type WorkflowPropertyValue = {
	editType?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	changeValue: boolean /**  */
	replaceOnlyEmpty: boolean /**  */
} 

/**  */
export type WorkflowPropertyState = {
	changeState: boolean /**  */
	isReadOnly: boolean /**  */
	isRequired: boolean /**  */
	isVisible: boolean /**  */
} 

/**  */
export type OptionSave = {
	insert: boolean /**  */
	update: boolean /**  */
} 

/**  */
export type OptionDelete = {
	delete: boolean /**  */
	trash: boolean /**  */
} 

/**  */
export type Software = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	softwareGroup?: Maybe<ServiceSingleResultOfSoftwareGroup> /**  */
	softwareConnectionMode?: Maybe<ServiceSingleResultOfSoftwareConnectionMode> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	softwarePackages?: Maybe<ServiceListResultOfSoftwarePackage> /**  */
	softwareProperties?: Maybe<ServiceListResultOfSoftwareProperty> /**  */
	softwareHostedLinks?: Maybe<ServiceListResultOfSoftwareHostedLink> /**  */
	softwareReferentLinks?: Maybe<ServiceListResultOfSoftwareReferentLink> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	internalName?: Maybe<string> /**  */
	internalCode?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hidden: boolean /**  */
	notAllowed: boolean /**  */
	obligatory: boolean /**  */
	softwareAllowedType: SoftwareAllowedType /**  */
	approved: boolean /**  */
	recorded: boolean /**  */
	publisher?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	softwareCategory: SoftwareCategory /**  */
	manualData: boolean /**  */
	softwareGroupId?: Maybe<string> /**  */
	softwarePackageIds: Array<string> /**  */
	installCount: number /**  */
	contractIds: Array<string> /**  */
	auditType: AuditType /**  */
	shortcutName?: Maybe<string> /**  */
	softwareAttributes?: Array<Maybe<SoftwareAttribute>> /**  */
	ldapGroupDn?: Array<Maybe<string>> /**  */
	softwareConnectionModeId?: Maybe<string> /**  */
} 

/**  */
export type SoftwareGroup = {
	countSoftware: number /**  */
	softInstalled: number /**  */
	id?: string /**  */
	software?: Maybe<ServiceListResultOfSoftware> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	softwarePackages?: Maybe<ServiceListResultOfSoftwarePackage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	obligatory: boolean /**  */
	softwarePackageIds: Array<string> /**  */
	expression?: Maybe<any> /**  */
	softwareGroupForbidden: boolean /**  */
	softwareGroupHidden: boolean /**  */
	contractIds: Array<string> /**  */
} 

/**  */
export type SoftwarePackage = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	softwares?: Maybe<ServiceListResultOfSoftware> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	softwareGroups?: Maybe<ServiceListResultOfSoftwareGroup> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	name?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	create?: Maybe<Date> /**  */
	validate?: Maybe<Date> /**  */
	userIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	softwareIds: Array<string> /**  */
	softwareGroupIds: Array<string> /**  */
} 

/**  */
export type StockFinancialInformation = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	budget?: Maybe<ServiceSingleResultOfBudget> /**  */
	budgetId?: Maybe<string> /**  */
	modeId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
} 

/**  */
export type InCart = {
	id?: string /**  */
	unitCost: number /**  */
	totalCost: number /**  */
	budgetId?: Maybe<string> /**  */
	receivedById?: Maybe<string> /**  */
	storageUnitId: string /**  */
	inStockName?: Maybe<string> /**  */
	entryId: string /**  */
	cartId?: Maybe<string> /**  */
	amount: number /**  */
	category: AssetModelCategory /**  */
	oldCarts: number /**  */
} 

/**  */
export type NamingConventionSummary = {
	value?: Maybe<string> /**  */
	namingConventionMask?: Maybe<string> /**  */
	manualEdit: boolean /**  */
	lock: boolean /**  */
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type AssetModel = {
	entriesAmount?: Maybe<ServiceSingleResultOfInt64> /**  */
	totalMinimumStock: number /**  */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	suppliers?: Maybe<ServiceListResultOfSupplier> /**  */
	assetCategory?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	manufacturer?: Maybe<ServiceSingleResultOfModelManufacturer> /**  */
	model?: Maybe<ServiceSingleResultOfModelManufacturer> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	assetModelStockEntries?: Maybe<ServiceListResultOfAssetModelStockEntry> /**  */
	name?: Maybe<string> /**  */
	assetCategoryId?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	assetStockEntryIds: Array<string> /**  */
	category: AssetModelCategory /**  */
	minimumStorageAmount: number /**  */
	minimumStockManagement: AssetModelMinimumStockManagement /**  */
	minimumStockByStorageUnits?: Array<Maybe<MinimumStockByStorageUnit>> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<Location> /**  */
	totalStorageAmout: number /**  */
	supplierIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	compatibleIds: Array<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	restockingThreshold?: Maybe<number> /**  */
	economicQuantity?: Maybe<number> /**  */
	technicalQuantity?: Maybe<number> /**  */
	restockingQuantity?: Maybe<number> /**  */
} 

/**  */
export type MinimumStockByStorageUnit = {
	storageUnitId: string /**  */
	minimum: number /**  */
} 

/**  */
export type ISnmpMapInformation = {
	sysOid?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	type: SnmpType /**  */
	snmpOidObjects?: Array<Maybe<SnmpOidObject>> /**  */
	assetCategoryId?: Maybe<string> /**  */
} 

/**  */
export type SnmpOidObject = {
	objectOidMap: ObjectOidMap /**  */
	oid?: Maybe<string> /**  */
	knowledgeBase: boolean /**  */
} 

/**  */
export type AssetLifetime = {
	yearDuration: number /**  */
	monthDuration: number /**  */
	dayDuration: number /**  */
	lifeTimeOption: LifetimeStartDateSelection /**  */
} 

/**  */
export type ModelManufacturer = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	manufacturer?: Maybe<ServiceSingleResultOfModelManufacturer> /**  */
	manufacturers?: Maybe<ServiceListResultOfModelManufacturer> /**  */
	assetModels?: Maybe<ServiceListResultOfAsset> /**  */
	assetManufacturers?: Maybe<ServiceListResultOfAsset> /**  */
	assetModelManufacturers?: Maybe<ServiceListResultOfAssetModel> /**  */
	assetModelModels?: Maybe<ServiceListResultOfAssetModel> /**  */
	name?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
} 

/**  */
export type IAgent = {
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	enabled: boolean /**  */
	version?: Maybe<string> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	updater?: Maybe<Updater> /**  */
	clarilogAgentSettings?: Maybe<ClarilogAgentSettings> /**  */
} 

/**  */
export type ScanDataFile = {
	id?: string /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	dataFiles?: Maybe<ServiceListResultOfDataFile> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	driveType: Array<DriveType> /**  */
	scanDataFileObject: ScanDataFileObject /**  */
	enabled: boolean /**  */
	file?: Maybe<string> /**  */
	extensions?: Array<Maybe<string>> /**  */
	version?: Maybe<string> /**  */
	withSize: boolean /**  */
	size: number /**  */
	operator: CompareToType /**  */
	includeFolders?: Array<Maybe<IncludeFolder>> /**  */
	excludeFolders?: Array<Maybe<ExcludeFolder>> /**  */
	scanConfigurationIds: Array<string> /**  */
	softwareId?: Maybe<string> /**  */
} 

/**  */
export type ScanRegistryKey = {
	id?: string /**  */
	scanRegistries?: Maybe<ServiceListResultOfScanRegistry> /**  */
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hKey: HKey /**  */
	allValue: boolean /**  */
	keyPath?: Maybe<string> /**  */
	value?: Array<Maybe<string>> /**  */
	scanConfigurationIds: Array<string> /**  */
} 

/**  */
export type LdapTrack = {
	total: number /**  */
	added: number /**  */
	updated: number /**  */
} 

/**  */
export type Campaign = {
	countTotal: number /**  */
	countSuccess: number /**  */
	countError: number /**  */
	countInProgress: number /**  */
	countInWainting: number /**  */
	lastDeploy: Date /**  */
	id?: string /**  */
	packages?: Maybe<ServiceListResultOfPackage> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	campaignHistories?: Maybe<ServiceListResultOfCampaignHistory> /**  */
	name?: Maybe<string> /**  */
	status: CampaignStatusEnum /**  */
	packageIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	lastDeployId?: Maybe<string> /**  */
} 

/**  */
export type Package = {
	id?: string /**  */
	campaigns?: Maybe<ServiceListResultOfCampaign> /**  */
	campaignHistories?: Maybe<ServiceListResultOfCampaignHistory> /**  */
	name?: Maybe<string> /**  */
	packageName?: Maybe<string> /**  */
	packageVersion?: Maybe<string> /**  */
	packagePathSource?: Maybe<string> /**  */
	campaignIds: Array<string> /**  */
	campaignHistoryId?: Maybe<string> /**  */
} 

/**  */
export type ScanHistoryOption = {
	keepSuccessAudit: number /**  */
	delayBeforePurge: number /**  */
	keepLastDayForEventLog: number /**  */
	keepLastDayForLogon: number /**  */
	keepLastDayForUptimeCalendar: number /**  */
} 

/**  */
export type ClarilogServerLog = {
	id?: string /**  */
	eventCode: number /**  */
	eventType: EventType /**  */
	logfile?: Maybe<string> /**  */
	message?: Maybe<string> /**  */
	sourceName?: Maybe<string> /**  */
	timeGenerated?: Maybe<Date> /**  */
} 

/**  */
export type Updater = {
	lastDownload?: Maybe<Date> /**  */
	lastUpdate?: Maybe<Date> /**  */
	lastInstall?: Maybe<Date> /**  */
	updateState: UpdaterState /**  */
	lastError?: Maybe<string> /**  */
	countAttempt: number /**  */
	version?: Maybe<string> /**  */
} 

/**  */
export type ClarilogAgentSettings = {
	lastVersion?: Maybe<string> /**  */
	pendingDelay: number /**  */
	pingDelay: number /**  */
	stopService: boolean /**  */
	restartService: boolean /**  */
	disableService: boolean /**  */
	forceToken?: Maybe<string> /**  */
	canBeUpdate: boolean /**  */
	blockIfNotLastVersion: boolean /**  */
	disabledApiQuery: boolean /**  */
	disabledApiMutation: boolean /**  */
	apiServer?: Maybe<string> /**  */
} 

/**  */
export type ITicketLink = {
	ticketId?: Maybe<string> /**  */
} 

/**  */
export type TicketTask = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	taskSequence?: Maybe<ServiceSingleResultOfTaskSequence> /**  */
	parent?: Maybe<ServiceSingleResultOfTicketTask> /**  */
	children?: Maybe<ServiceListResultOfTicketTask> /**  */
	operator?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	activity?: Maybe<ServiceSingleResultOfActivity> /**  */
	status?: Maybe<ServiceSingleResultOfTaskStatus> /**  */
	predecessor?: Maybe<ServiceSingleResultOfTicketTask> /**  */
	successors?: Maybe<ServiceListResultOfTicketTask> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	name?: Maybe<TranslatedField> /**  */
	title?: Maybe<TranslatedField> /**  */
	childrenIds: Array<string> /**  */
	createdBy?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	interventionIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	scheduleAt?: Maybe<Date> /**  */
	endAt?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	duration?: Maybe<string> /**  */
	durationMinutes: number /**  */
	taskModel?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	order: number /**  */
	statusId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskStatusWorkflowId?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	activityId?: Maybe<string> /**  */
	taskNumber: number /**  */
	isPredecessor: boolean /**  */
	predecessorId?: Maybe<string> /**  */
	predecessorStatusId?: Maybe<string> /**  */
	successorsIds: Array<string> /**  */
	isGreyList: boolean /**  */
	createdByTicketModel: boolean /**  */
} & ITicketLink

/**  */
export type TicketBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} & ITicketBacklog

/**  */
export type Message = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	author?: Maybe<ServiceSingleResultOfUser> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	date?: Maybe<Date> /**  */
	content?: Maybe<string> /**  */
	authorId: string /**  */
	ticketId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	isOperator: boolean /**  */
	isSenderUserConcerned: boolean /**  */
	authorName?: Maybe<string> /**  */
	alertedUsersIds: Array<string> /**  */
	fileIds: Array<string> /**  */
} & ITicketLink

/**  */
export type EmailPrototypeSend = {
	emailConnector?: Maybe<ServiceSingleResultOfEmailConnector> /**  */
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	enabled: boolean /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	from?: Maybe<string> /**  */
	to?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	attachedResult: boolean /**  */
	alarmIds: Array<string> /**  */
	triggerType: EmailPrototypeTriggerTypeEnum /**  */
	time?: Maybe<Date> /**  */
} & IEmailPrototypeBase

/**  */
export type Intervention = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	operator?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	task?: Maybe<ServiceSingleResultOfTicketTask> /**  */
	activity?: Maybe<ServiceSingleResultOfActivity> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	fileIds: Array<string> /**  */
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	interventionModel?: Maybe<string> /**  */
	elaspedTime: string /**  */
	elaspedTimeHour: number /**  */
	cost: number /**  */
	autoCost: boolean /**  */
	ticketId?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
} & ITicketLink

/**  */
export type MinimumStockByStorageUnitAlertResult = {
	minimum: number /**  */
	currentAmount: number /**  */
} 

/**  */
export type SoftwareAttribute = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	softwareAttributeTypeId?: Maybe<string> /**  */
} 

/**  */
export type IncludeFolder = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	includeSubFolder: boolean /**  */
	enabled: boolean /**  */
} 

/**  */
export type ExcludeFolder = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	enabled: boolean /**  */
} 

/**  */
export type VirtualMachineGuest = {
	operatingSystem?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	hostName?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	memorySize: number /**  */
	hardDiskSize: number /**  */
	cpuCount: number /**  */
	vmPowerState: VmPowerState /**  */
	vmHealthStatus: VmHealthStatus /**  */
	macAddress?: Maybe<string> /**  */
} 

/**  */
export type ShareSecurity = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	accessMaskValue: number /**  */
	shareAccess?: Maybe<ShareAccess> /**  */
	sidString?: Maybe<string> /**  */
	sidLength: number /**  */
	aceFlags: number /**  */
	aceType: number /**  */
} 

/**  */
export type ShareAccess = {
	read: boolean /**  */
	write: boolean /**  */
	fullAccess: boolean /**  */
	deny: boolean /**  */
} 

/**  */
export type SqlService = {
	acceptPause: boolean /**  */
	acceptStop: boolean /**  */
	binaryPath?: Maybe<string> /**  */
	dependencies?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	errorControl: number /**  */
	exitCode: number /**  */
	hostName?: Maybe<string> /**  */
	processId: number /**  */
	serviceName?: Maybe<string> /**  */
	sqlServiceType: SqlServiceCategory /**  */
	startMode: StartMode /**  */
	startName?: Maybe<string> /**  */
	state: ServiceState /**  */
} 

/**  */
export type DataBaseInformation = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	dataFilesSizeKb?: Maybe<string> /**  */
	logFilesSizeKb?: Maybe<string> /**  */
	logFilesUsedSizeKb?: Maybe<string> /**  */
} 

/**  */
export type FirewallPolicy = {
	disableNotifications: boolean /**  */
	doNotAllowExceptions: boolean /**  */
	enableFirewall: boolean /**  */
} 

/**  */
export type WindowsUpdateResult = {
	lastError: number /**  */
	lastSuccessTime?: Maybe<string> /**  */
} 

/**  */
export type IisSite = {
	id?: string /**  */
	siteId: number /**  */
	adsiPath?: Maybe<string> /**  */
	logFileDirectory?: Maybe<string> /**  */
	appPoolId?: Maybe<string> /**  */
	dontLog: boolean /**  */
	defaultDoc?: Maybe<string> /**  */
	authFlags: number /**  */
	anonymousUserName?: Maybe<string> /**  */
	ntAuthenticationProviders?: Maybe<string> /**  */
	serverState: number /**  */
	name?: Maybe<string> /**  */
	serverBindings?: Maybe<string> /**  */
	iisApplications?: Array<Maybe<IisApplication>> /**  */
} 

/**  */
export type IisApplication = {
	applicationPool?: Maybe<string> /**  */
	defaultDoc?: Maybe<string> /**  */
	adsiPath?: Maybe<string> /**  */
	authFlags: number /**  */
	anonymousUserName?: Maybe<string> /**  */
	ntAuthenticationProviders?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	appFriendlyName?: Maybe<string> /**  */
	appIsolated: number /**  */
	appRoot?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type IisApplicationPool = {
	id?: string /**  */
	managedRuntimeVersion?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	adsiPath?: Maybe<string> /**  */
	state: number /**  */
} 

/**  */
export type IisComponent = {
	id?: string /**  */
	w3Svc: number /**  */
	commonHttpFeaturesIis?: Maybe<CommonHttpFeaturesIis> /**  */
	applicationDevelopmentFeaturesIis?: Maybe<ApplicationDevelopmentFeaturesIis> /**  */
	healthandDiagnosticsIis?: Maybe<HealthandDiagnosticsIis> /**  */
	securityIis?: Maybe<SecurityIis> /**  */
	performanceFeaturesIis?: Maybe<PerformanceFeaturesIis> /**  */
	managementToolsIis?: Maybe<ManagementToolsIis> /**  */
	managementServiceIis?: Maybe<ManagementServiceIis> /**  */
	fTpPublishingServiceIis?: Maybe<FtpPublishingServiceIis> /**  */
	windowsProcessActivationService?: Maybe<WindowsProcessActivationService> /**  */
} 

/**  */
export type CommonHttpFeaturesIis = {
	staticContent: number /**  */
	defaultDocument: number /**  */
	directoryBrowse: number /**  */
	httpErrors: number /**  */
	httpRedirect: number /**  */
} 

/**  */
export type ApplicationDevelopmentFeaturesIis = {
	aspNet: number /**  */
	netFxExtensibility: number /**  */
	asp: number /**  */
	cgi: number /**  */
	isapiExtensions: number /**  */
	isapiFilter: number /**  */
	serverSideInclude: number /**  */
} 

/**  */
export type HealthandDiagnosticsIis = {
	httpLogging: number /**  */
	loggingLibraries: number /**  */
	requestMonitor: number /**  */
	httpTracing: number /**  */
	customLogging: number /**  */
	odbcLogging: number /**  */
} 

/**  */
export type SecurityIis = {
	basicAuthentication: number /**  */
	windowsAuthentication: number /**  */
	digestAuthentication: number /**  */
	clientCertificateMappingAuthentication: number /**  */
	iisClientCertificateMappingAuthentication: number /**  */
	authorization: number /**  */
	requestFiltering: number /**  */
	ipSecurity: number /**  */
} 

/**  */
export type PerformanceFeaturesIis = {
	httpCompressionStatic: number /**  */
	httpCompressionDynamic: number /**  */
} 

/**  */
export type ManagementToolsIis = {
	managementConsole: number /**  */
	managementScriptingTools: number /**  */
	adminService: number /**  */
} 

/**  */
export type ManagementServiceIis = {
	metabase: number /**  */
	wmiCompatibility: number /**  */
	legacyScripts: number /**  */
	legacySnapin: number /**  */
} 

/**  */
export type FtpPublishingServiceIis = {
	ftpServer: number /**  */
} 

/**  */
export type WindowsProcessActivationService = {
	processModel: number /**  */
	netFxEnvironment: number /**  */
	wasConfigurationApi: number /**  */
} 

/**  */
export type ScanImportOptionIpRange = {
	ipAddressStart?: Maybe<string> /**  */
	ipAddressEnd?: Maybe<string> /**  */
} 

/**  */
export type ScanImportOptionIpAddress = {
	value?: Maybe<string> /**  */
	scanExcludeOperator: ScanExcludeOperator /**  */
} 

/**  */
export type ClarilogException = {
	message?: Maybe<string> /**  */
	stackTrace?: Maybe<string> /**  */
	source?: Maybe<string> /**  */
	helpLink?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
} 

/**  */
export type LdapHistory = {
	countExcludeFilterUsers: number /**  */
	id?: string /**  */
	scanConfiguration?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	progressValue: number /**  */
	progressDisplay?: Maybe<string> /**  */
	ldapUser?: Maybe<LdapTrack> /**  */
	ldapGroup?: Maybe<LdapTrack> /**  */
	ldapOrganizationalUnit?: Maybe<LdapTrack> /**  */
	ldapComputer?: Maybe<LdapTrack> /**  */
	forceSearchFilter: boolean /**  */
	ldapOrganizationalUnitFilter: number /**  */
	distinguishedName?: Maybe<string> /**  */
	exceptions?: Array<Maybe<ClarilogException>> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	excludeFilterUsers?: Array<Maybe<ExcludeFilterUser>> /**  */
} 

/**  */
export type LdapObjectClassMapping = {
	id?: string /**  */
	type: LdapObjectClassType /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type LdapFieldMapping = {
	id?: string /**  */
	ldapField?: Maybe<string> /**  */
	entityField?: Maybe<string> /**  */
	phoneType: PhoneEnum /**  */
	updateLdapValue: UpdateLdapValue /**  */
	type: LdapObjectClassType /**  */
} 

/**  */
export type NetworkCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
} & INetworkCredential

/**  */
export type INetworkCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
} 

/**  */
export type IScanConfiguration = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} 

/**  */
export type IAppointment = {
	allDay: boolean /**  */
	disabled: boolean /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
} 

/**  */
export type AuditOption = {
	notAuditedType: NotAuditedType /**  */
	sinceDay: number /**  */
} 

/**  */
export type PowerControl = {
	enabled: boolean /**  */
	hour: number /**  */
	autoPurge: boolean /**  */
	since: number /**  */
} 

/**  */
export type Wol = {
	enabled: boolean /**  */
	timeOut: number /**  */
} 

/**  */
export type ShutDown = {
	enabled: boolean /**  */
	onlyNotError: boolean /**  */
} 

/**  */
export type IBLockByEnvironnement = {
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
} 

/**  */
export type MailSlurpInbox = {
	email?: Maybe<string> /**  */
	inboxId: string /**  */
	webhookId: string /**  */
	endpoint?: Maybe<string> /**  */
	enabled: boolean /**  */
	useFiltering: boolean /**  */
	useEmailChecker: boolean /**  */
	emailCheckerLevel: number /**  */
} 

/**  */
export type DisabledReason = {
	disabled: boolean /**  */
	date?: Maybe<Date> /**  */
	reason?: Maybe<string> /**  */
} 

/**  */
export type Problem = {
	isFavorite: boolean /** Permet de savoir si le ticket est un favoris. */
	isReminderOpen: boolean /** Permet de savoir si le reminder est disponible. */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	concernedProject?: Maybe<ServiceSingleResultOfConcernedProject> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	channel?: Maybe<ServiceSingleResultOfChannel> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	ticketStatusReasons?: Maybe<ServiceSingleResultOfTicketStatusReason> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	sla?: Maybe<ServiceSingleResultOfServiceLevelAgreement> /**  */
	mainImpactedAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	userAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	userMakeRequest?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	parent?: Maybe<ServiceSingleResultOfTicket> /**  */
	children?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	privateResolutionArticle?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<Reminder> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfaction> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	origin?: Maybe<ServiceSingleResultOfOrigin> /**  */
	linkedTickets?: Maybe<ServiceListResultOfTicket> /**  */
	originId?: Maybe<string> /**  */
	cause?: Maybe<string> /**  */
	workaround?: Maybe<string> /**  */
	solution?: Maybe<string> /**  */
} & ITicket

/**  */
export type Ticket = {
	isFavorite: boolean /** Permet de savoir si le ticket est un favoris. */
	isReminderOpen: boolean /** Permet de savoir si le reminder est disponible. */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	concernedProject?: Maybe<ServiceSingleResultOfConcernedProject> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	channel?: Maybe<ServiceSingleResultOfChannel> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	ticketStatusReasons?: Maybe<ServiceSingleResultOfTicketStatusReason> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	sla?: Maybe<ServiceSingleResultOfServiceLevelAgreement> /**  */
	mainImpactedAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	userAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	userMakeRequest?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	parent?: Maybe<ServiceSingleResultOfTicket> /**  */
	children?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	privateResolutionArticle?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<Reminder> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfaction> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
} & ITicket

/**  */
export type AppointmentConnector = {
	appointmentId?: Maybe<string> /**  */
	calendarConnectorId: string /**  */
} 

/**  */
export type Reminder = {
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	userId?: Maybe<string> /**  */
	lastReminder?: Maybe<Date> /**  */
	increment: number /**  */
	commentary?: Maybe<string> /**  */
} 

/**  */
export type TicketSatisfaction = {
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	created?: Maybe<Date> /**  */
	rating?: Maybe<number> /**  */
	commentary?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
} 

/**  */
export type MatrixPriorityConfig = {
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
} 

/**  */
export type StatusEdge = {
	title?: Maybe<string> /**  */
	fromId?: Maybe<string> /**  */
	toId?: Maybe<string> /**  */
	objId?: Maybe<string> /**  */
	fromPointIndex: number /**  */
	toPointIndex: number /**  */
	points?: Array<Maybe<StatusPoint>> /**  */
} 

/**  */
export type StatusPoint = {
	x: number /**  */
	y: number /**  */
} 

/**  */
export type StatusNode = {
	isEntry: boolean /**  */
	isEnding: boolean /**  */
	isTakingCharge: boolean /**  */
	isEndTreatment: boolean /**  */
	isCommentaryRequired: boolean /**  */
	canAddSatisfaction: boolean /**  */
	isForbiddenPredecessor: boolean /**  */
	objId?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	statusId: string /**  */
	name?: Maybe<string> /**  */
	x: number /**  */
	y: number /**  */
	rightToMove?: Maybe<StatusMoveRight> /**  */
} 

/**  */
export type StatusMoveRight = {
	allOperator: boolean /**  */
	referentTeam: boolean /**  */
	affectedTeam: boolean /**  */
	allConcerned: boolean /**  */
	makeRequest: boolean /**  */
	affected: boolean /**  */
	operatorAffected: boolean /**  */
	operatorReferent: boolean /**  */
	manager: boolean /**  */
	allTaskFinished: boolean /**  */
	customUserIds: Array<string> /**  */
} 

/**  */
export type AllowedEntity = {
	entityId: string /**  */
	visible: boolean /**  */
	allowed: boolean /**  */
} 

/**  */
export type TicketCategory = {
	numberOfChildren: number /**  */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	file?: Maybe<ServiceSingleResultOfFileModel> /**  */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	articlesKnowledges?: Maybe<ServiceListResultOfArticleKnowledge> /**  */
	emailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	error: boolean /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	path?: Maybe<TranslatedField> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	disabled: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	keywords?: Array<Maybe<string>> /**  */
	allowSelection: boolean /**  */
	fileId?: Maybe<string> /**  */
} & ITicketQualification & IAllowedEntity

/**  */
export type LocationCharterLdap = {
	location: boolean /**  */
	organizationalUnit: boolean /**  */
} 

/**  */
export type ILocationCharterRule = {
	allRulesMustBeValidAsset: boolean /**  */
	allRulesMustBeValidUser: boolean /**  */
} 

/**  */
export type IAllowedEntity = {
	allowSelection: boolean /**  */
} 

/**  */
export type CorrectionCart = {
	description?: Maybe<string> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<Location> /**  */
	entryId: string /**  */
	cartId?: Maybe<string> /**  */
	amount: number /**  */
	category: AssetModelCategory /**  */
	oldCarts: number /**  */
} 

/**  */
export type DistinguishedNameModel = {
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	id?: Maybe<string> /**  */
} 

/**  */
export type ScanImportOptionLogonSession = {
	value?: Maybe<string> /**  */
	scanExcludeOperator: ScanExcludeOperator /**  */
} 

/**  */
export type ICalendarConnector = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} 

/**  */
export type LdapAnalyseProcess = {
	start?: Maybe<Date> /**  */
	total: number /**  */
	analyse: number /**  */
	progressValue: number /**  */
	end?: Maybe<Date> /**  */
	cancel: boolean /**  */
} 

/**  */
export type ModelRuleOption = {
	allowEdit: boolean /**  */
	allowRequire: boolean /**  */
	allowStatus: boolean /**  */
} 

/**  */
export type StatusConfig = {
	edges?: Array<Maybe<StatusEdge>> /**  */
	nodes?: Array<Maybe<StatusNode>> /**  */
} 

/**  */
export type Feature = {
	name?: Maybe<string> /**  */
	rank: number /**  */
} 

/**  */
export type AuthentificationType = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	userPasswords?: Array<Maybe<UserPassword>> /**  */
} 

/**  */
export type Habilitation = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	userHabilitations?: Array<Maybe<UserHabilitation>> /**  */
} 

/**  */
export type IAffectedRule = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} 

/**  */
export type LocationCharterSummary = {
	value?: Maybe<string> /**  */
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type IPermissible = {
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
} 

/**  */
export type ILdapAttribute = {
	objectSid?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
} 

/**  */
export type IUserAzureAD = {
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	officeName?: Maybe<string> /**  */
	address?: Maybe<Address> /**  */
	principalMobile?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	phones?: Array<Maybe<Phone>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAd>> /**  */
	userAccountDisabled: boolean /**  */
} 

/**  */
export type OperatorTeam = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ticketAffectedRuleOperatorTeamReferents?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketAffectedRuleOperatorTeamAffecteds?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketReferents?: Maybe<ServiceListResultOfTicket> /**  */
	ticketAffecteds?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModelReferents?: Maybe<ServiceListResultOfIncidentModel> /**  */
	incidentModelAffecteds?: Maybe<ServiceListResultOfIncidentModel> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	userMainTeams?: Maybe<ServiceListResultOfUser> /**  */
	operatorTeamAffectedEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	operatorTeamReferentEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	interventionModels?: Maybe<ServiceListResultOfInterventionModel> /**  */
	ticketBacklogs?: Maybe<ServiceListResultOfTicketBacklog> /**  */
	taskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	emails?: Array<Maybe<string>> /**  */
	userIds: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	default: boolean /**  */
	allowedTicketsCategories?: Array<Maybe<AllowedEntity>> /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible: boolean /**  */
	ticketCategoryAllowed: boolean /**  */
	allowedOperatorTeamIds?: Array<Maybe<AllowedEntity>> /**  */
	allowSelection: boolean /**  */
} & IAllowedTicketCategory & IAllowedEntity

/**  */
export type ServiceListResultOfDistinguishedNameModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DistinguishedNameModel>> /**  */
} 

/**  */
export type Settings = {
	features?: Array<Maybe<Feature>> /**  */
	preferences?: Maybe<Preferences> /**  */
} 

/**  */
export type UserPassword = {
	id?: string /**  */
	description?: Maybe<string> /**  */
	logIn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	authentificationTypeId?: Maybe<string> /**  */
	authentificationType?: Maybe<AuthentificationType> /**  */
} 

/**  */
export type Loan = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	nextAssetLoans?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	loanStatus?: Maybe<ServiceSingleResultOfLoanStatus> /**  */
	manager?: Maybe<ServiceSingleResultOfUser> /**  */
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	locations?: Maybe<ServiceListResultOfLocation> /**  */
	nextLocations?: Maybe<ServiceListResultOfLocation> /**  */
	number: number /**  */
	slipNumber?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	provision?: Maybe<Date> /**  */
	returnDate?: Maybe<Date> /**  */
	realReturnDate?: Maybe<Date> /**  */
	duration: number /**  */
	information?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	accountIds?: Array<Maybe<UserAccount>> /**  */
	recordDate?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	contactId?: Maybe<string> /**  */
	loanStatusId?: Maybe<string> /**  */
	loanBorrowerType: LoanBorrowerType /**  */
	fileIds: Array<string> /**  */
} 

/**  */
export type UserHabilitation = {
	id?: string /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	habilitationId?: Maybe<string> /**  */
	habilitation?: Maybe<Habilitation> /**  */
	permanent: boolean /**  */
} 

/**  */
export type Phone = {
	id?: string /**  */
	number?: Maybe<string> /**  */
	phoneType: PhoneEnum /**  */
} 

/**  */
export type Invitation = {
	sendDate?: Maybe<Date> /**  */
	acceptDate?: Maybe<Date> /**  */
	revokeDate?: Maybe<Date> /**  */
	token?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
} 

/**  */
export type TrackingChange = {
	newValue?: Maybe<any> /**  */
	oldValue?: Maybe<any> /**  */
	id?: string /**  */
	name?: Maybe<string> /**  */
	isTranslatable: boolean /**  */
} 

/**  */
export type TrackingValue = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
} 

/**  */
export type Activity = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	taskSequencies?: Maybe<ServiceListResultOfTaskSequence> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	interventionModels?: Maybe<ServiceListResultOfInterventionModel> /**  */
	taskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
} & ISecurityGroup & IRank & ITicketQualification

/**  */
export type SftpConnector = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	queryBuilders?: Maybe<ServiceListResultOfQueryBuilder> /**  */
	sftpConnectorHistories?: Maybe<ServiceListResultOfSftpConnectorHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	port: number /**  */
	userName?: Maybe<string> /**  */
	remoteFolder?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
	queryBuilderIds: Array<string> /**  */
} & IBLockByEnvironnement

/**  */
export type IEnablable = {
	enabled: boolean /**  */
} 

/**  */
export type BusinessTime = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	dayOffs?: Maybe<ServiceListResultOfDayOff> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	config?: Maybe<WorkTimeConfig> /**  */
	isDefault: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	pauseStatusIds: Array<string> /**  */
	timeZoneId?: Maybe<string> /**  */
} & ISystem

/**  */
export type ScanConfiguration = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & IScanConfiguration & ISystem

/**  */
export type StatusWorkflow = {
	id?: string /**  */
	lastUpdateBy?: Maybe<ServiceSingleResultOfUser> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	enable: boolean /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	copyFromId?: Maybe<string> /**  */
	config?: Maybe<StatusConfig> /**  */
} & ISystem & ITicketQualification

/**  */
export type WorkflowAction = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
} & IWorkflowAction & ISystem

/**  */
export type Workflow = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	lastUpdateBy?: Maybe<ServiceSingleResultOfUser> /**  */
	workflowActions?: Maybe<ServiceListResultOfWorkflowAction> /**  */
	name?: Maybe<TranslatedField> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	expression?: Maybe<any> /**  */
	enabled: boolean /**  */
	description?: Maybe<TranslatedField> /**  */
	entityType?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
	delayConfig?: Maybe<WorkflowDelay> /**  */
	intervalConfig?: Maybe<WorkflowDelay> /**  */
	enableDelay: boolean /**  */
	enableInterval: boolean /**  */
	priority: number /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	managedChooseForApply: UserOrAsset /**  */
	copyFromId?: Maybe<string> /**  */
} & ISystem & IEnablable

/**  */
export type IRank = {
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
} 

/**  */
export type ISystem = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} 

/**  */
export type ScanImportOption = {
	id?: string /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	importAuditTasks?: Maybe<ServiceListResultOfImportAuditTask> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	autoApproved: boolean /**  */
	createScreen: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	serialNumberSearch: SerialNumberSearch /**  */
	inheritLocation: boolean /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	scanImportOptionLogonSessions?: Array<Maybe<ScanImportOptionLogonSession>> /**  */
	scanImportOptionIpRanges?: Array<Maybe<ScanImportOptionIpRange>> /**  */
	scanImportOptionIpAddresses?: Array<Maybe<ScanImportOptionIpAddress>> /**  */
	searchWarranty: boolean /**  */
	importSoftwareLinux: boolean /**  */
	importUser: boolean /**  */
	importUserMode: ImportUserMode /**  */
	mainUserMode: MainUserMode /**  */
	createUser: boolean /**  */
	updateManualData: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	name?: Maybe<TranslatedField> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enabled: boolean /**  */
	description?: Maybe<string> /**  */
	dateUp: boolean /**  */
	assetStateDateUp: boolean /**  */
	forceMonitorName: boolean /**  */
	scanConfigurationIds: Array<string> /**  */
	changeStatusActivated?: Maybe<boolean> /**  */
	concernedStatusIds: Array<string> /**  */
	destinationStatusId?: Maybe<string> /**  */
} & IRank & ISystem

/**  */
export type ITicketQualification = {
	qualification?: Array<Maybe<string>> /**  */
} 

/**  */
export type Alarm = {
	expression?: Maybe<any> /**  */
	countNotViewedAlert: number /**  */
	countNotViewedAlertHidden: number /**  */
	countAlert: number /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	emailPrototypes?: Maybe<ServiceListResultOfEmailPrototype> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	type?: Maybe<string> /**  */
	category: AlarmCategoryEnum /**  */
	triggerType: AlarmTriggerTypeEnum /**  */
	enabled: boolean /**  */
	trigger?: Maybe<any> /**  */
	managedName: ManagedAlarmEnum /**  */
	interval: number /**  */
	time?: Maybe<Date> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	allRecipient: boolean /**  */
	forceShowAlert: boolean /**  */
	recipientproperties?: Array<Maybe<string>> /**  */
} & ISystem & ISecurityGroup & IFilterType & IEnablable

/**  */
export type ArticleKnowledge = {
	articleKnowledgePopulation?: Array<Maybe<TranslatedField>> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	lastUpdateBy?: Maybe<ServiceSingleResultOfUser> /**  */
	articleKnowledgeCategory?: Maybe<ServiceSingleResultOfArticleKnowledgeCategory> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	articleKnowledgeNotes?: Maybe<ServiceListResultOfArticleKnowledgeNote> /**  */
	title?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	descriptionText?: Maybe<TranslatedField> /**  */
	solution?: Maybe<TranslatedField> /**  */
	solutionText?: Maybe<TranslatedField> /**  */
	keyWords?: Array<Maybe<string>> /**  */
	links?: Array<Maybe<string>> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	lastViewByOperator?: Maybe<Date> /**  */
	countViewOperator: number /**  */
	lastViewByUser?: Maybe<Date> /**  */
	countViewUser: number /**  */
	averageRating: number /**  */
	countRating: number /**  */
	knowledgeNumber: number /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	resolvedTickets: number /**  */
	lastResolvedTicketDate?: Maybe<Date> /**  */
	articleKnowledgeCategoryId?: Maybe<string> /**  */
} & ISecurityGroup

/**  */
export type AssetCategory = {
	countAssets: number /**  */
	namingConvention?: Maybe<ServiceSingleResultOfAssetNamingConvention> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	parent?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	children?: Maybe<ServiceListResultOfAssetCategory> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	assetModels?: Maybe<ServiceListResultOfAssetModel> /**  */
	assetCategoryRules?: Maybe<ServiceListResultOfAssetCategoryRule> /**  */
	snmpMapInformations?: Maybe<ServiceListResultOfSnmpMapInformation> /**  */
	snmpMapInformationKbs?: Maybe<ServiceListResultOfSnmpMapInformationKb> /**  */
	lifetime?: Maybe<AssetLifetime> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	disabled: boolean /**  */
	defaultPower: number /**  */
	stockageDurationMax: number /**  */
	lifeDuration: number /**  */
	snmpType: SnmpType /**  */
	decayRate: number /**  */
	parentId?: Maybe<string> /**  */
	namingPolicyRuleId?: Maybe<string> /**  */
	remoteControlId?: Maybe<string> /**  */
	path?: Maybe<TranslatedField> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	defaultCommandId?: Maybe<string> /**  */
	autoLaunchCommand: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	error: boolean /**  */
} & ISystem & ISecurityGroup

/**  */
export type Budget = {
	accountRemaining: number /**  */
	amountPurchase: number /**  */
	parentPath?: Maybe<ServiceSingleResultOfString> /** Permet de lister tous les parents associés à un budget. */
	costAsset: number /** Permet de calculer le coup des biens d'un budget. */
	costContract: number /** Permet de calculer le coup des contrats d'un budget. */
	costConsumables: number /** Permet de calculer le coup des livraisons d'un budget. */
	costInStockMovement: number /** Permet de calculer le coup des livraisons d'un budget. */
	costBudgetChildren: number /** Permet de calculer le coup des budgets enfant. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	consummables?: Array<Maybe<InCart>> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	manager?: Maybe<ServiceSingleResultOfUser> /**  */
	parent?: Maybe<ServiceSingleResultOfBudget> /**  */
	budgets?: Maybe<ServiceListResultOfBudget> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	consumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	inStockMovements?: Maybe<ServiceListResultOfInStockMovement> /**  */
	hidden: boolean /**  */
	name?: Maybe<string> /**  */
	code?: Maybe<string> /**  */
	opened?: Maybe<Date> /**  */
	closed?: Maybe<Date> /**  */
	amount: number /**  */
	budgetCategoryId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	contractIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type CalendarConnector = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ICalendarConnector & ISecurityGroup

/**  */
export type Channel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISecurityGroup & IRank & ISystem & ITicketQualification

/**  */
export type ConcernedProject = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup & ITicketQualification

/**  */
export type EmailConnector = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	account?: Maybe<ServiceSingleResultOfEmailCredential> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	emailPrototypes?: Maybe<ServiceListResultOfEmailPrototype> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	name?: Maybe<TranslatedField> /**  */
	type: EmailConnectorTypeEnum /**  */
	server?: Maybe<string> /**  */
	port: number /**  */
	ssl: boolean /**  */
	email?: Maybe<string> /**  */
	accountId?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	logOn?: Maybe<string> /**  */
	mailSlurpInbox?: Maybe<MailSlurpInbox> /**  */
	allowUnknowFrom: boolean /**  */
	allowCreateTicketByEmail: boolean /**  */
	allowCommunicationByMail: boolean /**  */
	allowAddInterventionByMail: boolean /**  */
	interventionByMailTag?: Maybe<string> /**  */
	interventionByMailActivityId?: Maybe<string> /**  */
	onCreateTicketAddAllRecipients: boolean /**  */
	onCommunicationTicketAddAllRecipients: boolean /**  */
	onCommunicationTicketAddFrom: boolean /**  */
	statusIds: Array<string> /**  */
	inboxName?: Maybe<string> /**  */
	send: boolean /**  */
	receive: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	countMailSend: number /**  */
	countMailReceive: number /**  */
	qualification?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	default: boolean /**  */
	disabledSend?: Maybe<DisabledReason> /**  */
	disabledReceive?: Maybe<DisabledReason> /**  */
	allowEmbeddedImage: boolean /**  */
	smtpEncodingConstraint: number /**  */
	authAzurePassword: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISystem & IBLockByEnvironnement & ISecurityGroup

/**  */
export type EmailPrototype = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	emailConnector?: Maybe<ServiceSingleResultOfEmailConnector> /**  */
	alarms?: Maybe<ServiceListResultOfAlarm> /**  */
	queryBuilders?: Maybe<ServiceListResultOfQueryBuilder> /**  */
	workflowMails?: Maybe<ServiceListResultOfWorkflowMail> /**  */
	subject?: Maybe<TranslatedField> /**  */
	copyFrom?: Maybe<string> /**  */
	body?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	from?: Maybe<string> /**  */
	to?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	attachedResult: boolean /**  */
	alarmIds: Array<string> /**  */
	queryBuilderIds: Array<string> /**  */
	triggerType: EmailPrototypeTriggerTypeEnum /**  */
	time?: Maybe<Date> /**  */
	ticketCategoryIds: Array<string> /**  */
	disableFilterCategories: boolean /**  */
	sendInvitation: boolean /**  */
	attachFiles: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & IEmailPrototypeBase & ISecurityGroup

/**  */
export type Impact = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & IRank & ISecurityGroup & ITicketQualification

/**  */
export type IncidentModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	populations?: Maybe<ServiceListResultOfPopulation> /** Récupère les populations de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	statusWorkflow?: Maybe<ServiceSingleResultOfStatusWorkflow> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	name?: Maybe<TranslatedField> /**  */
	modelName?: Maybe<TranslatedField> /**  */
	title?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	copyFrom?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	assetIds: Array<string> /**  */
	privateResolutionComment?: Maybe<TranslatedField> /**  */
	resolutionComment?: Maybe<TranslatedField> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	populationIds: Array<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	levelSupport: number /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
} & ISecurityGroup & IPopulation & ITicketQualification

/**  */
export type Origin = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	problems?: Maybe<ServiceListResultOfProblem> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ITicketQualification & ISecurityGroup

/**  */
export type Priority = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	color?: Maybe<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
} & IRank & ISecurityGroup & ITicketQualification

/**  */
export type ResolutionCategory = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	resolutionModels?: Maybe<ServiceListResultOfResolutionModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	qualification?: Array<Maybe<string>> /**  */
	resolutionModelIds: Array<string> /**  */
} & IRank & ISecurityGroup & ITicketQualification

/**  */
export type ServiceLevelAgreement = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	populations?: Maybe<ServiceListResultOfPopulation> /**  */
	dayOffs?: Maybe<ServiceListResultOfDayOff> /**  */
	workTime?: Maybe<ServiceSingleResultOfWorkTime> /**  */
	priorities?: Maybe<ServiceListResultOfPriority> /**  */
	ticketCategories?: Maybe<ServiceListResultOfTicketCategory> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	supportTimes: number /**  */
	resolutionTimes: number /**  */
	ticketCategoryIds: Array<string> /**  */
	priorityIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	workTimeId?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	populationsIds: Array<string> /**  */
} & ISecurityGroup & IRank

/**  */
export type Supplier = {
	contacts?: Maybe<ServiceListResultOfUser> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assetModels?: Maybe<ServiceListResultOfAssetModel> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	consumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	inStockMovements?: Maybe<ServiceListResultOfInStockMovement> /**  */
	serviceOffers?: Maybe<ServiceListResultOfServiceOffer> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	naf?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	intraCommunity?: Maybe<string> /**  */
	hidden: boolean /**  */
	webSite?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	contactIds: Array<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	address?: Maybe<Address> /**  */
	serviceOfferIds: Array<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	assetModelIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type TaskSequence = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	activity?: Maybe<ServiceSingleResultOfActivity> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	taskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskIds: Array<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	activityId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup & ITicketLink

/**  */
export type TicketStatusReason = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	statusId: string /**  */
	ticketsIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type Urgency = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & IRank & ISecurityGroup & ITicketQualification

/**  */
export type ILocalizable = {
	locationId?: Maybe<string> /**  */
} 

/**  */
export type Contract = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	softwares?: Maybe<ServiceListResultOfSoftware> /**  */
	softwareGroups?: Maybe<ServiceListResultOfSoftwareGroup> /**  */
	contractCategory?: Maybe<ServiceSingleResultOfContractCategory> /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	supplier?: Maybe<ServiceSingleResultOfSupplier> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	budget?: Maybe<ServiceSingleResultOfBudget> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	hidden: boolean /**  */
	name?: Maybe<string> /**  */
	contractNumber?: Maybe<string> /**  */
	customerNumber?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	denouncement?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	unlimited: boolean /**  */
	contractCategoryId?: Maybe<string> /**  */
	costMonthly: number /**  */
	costSubscription: number /**  */
	periodicityId?: Maybe<string> /**  */
	firstRent?: Maybe<Date> /**  */
	costFirstRent: number /**  */
	costLastRent: number /**  */
	costNextRent: number /**  */
	costOther: number /**  */
	residualPurchase: number /**  */
	reconduction?: Maybe<Date> /**  */
	taciteReconduction: boolean /**  */
	renewal: boolean /**  */
	illimited: boolean /**  */
	fileNumber?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	cost: number /**  */
	totalCost: number /**  */
	authorizeNumber?: Maybe<string> /**  */
	orderDate?: Maybe<Date> /**  */
	numberOrder?: Maybe<string> /**  */
	numberSupplierOrder?: Maybe<string> /**  */
	billDate?: Maybe<Date> /**  */
	billNumber?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
	immobilizationNumber?: Maybe<string> /**  */
	isActiv: boolean /**  */
	fixIllimited: boolean /**  */
	serviceData: boolean /**  */
	fidelityNumber: number /**  */
	fidelityDate?: Maybe<Date> /**  */
	phoneNumber?: Maybe<string> /**  */
	capacityData: number /**  */
	nextDeadline?: Maybe<Date> /**  */
	firstDeadline?: Maybe<Date> /**  */
	assetIds: Array<string> /**  */
	userIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	histories?: Array<Maybe<Contract>> /**  */
	fileIds: Array<string> /**  */
	detectWarranty?: Maybe<DetectWarranty> /**  */
	groupIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	softwareGroupsIds: Array<string> /**  */
	softwaresIds: Array<string> /**  */
	renewalId?: Maybe<string> /**  */
	frequencyDeadline?: Maybe<FrequencyDeadline> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
} & ISecurityGroup & ILocalizable & IOrganizable

/**  */
export type Contact = {
	subordinatesLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	roleNames?: Array<Maybe<string>> /**  */
	roleTypes?: Array<Maybe<string>> /**  */
	listOperatorTeams?: Array<Maybe<OperatorTeam>> /**  */
	memberOfLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	memberOfAzure?: Maybe<ServiceListResultOfGroupAzureAd> /**  */
	findCollaboratorsLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	isOperator: boolean /**  */
	isApproved: boolean /**  */
	displayOperatorFilter?: Maybe<string> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /**  */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
	budgets?: Maybe<ServiceListResultOfBudget> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	ticketAffectedRuleUserReferents?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketAffectedRuleUserAffecteds?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketUserAffecteds?: Maybe<ServiceListResultOfTicket> /**  */
	ticketUserSatisfactions?: Maybe<ServiceListResultOfTicket> /**  */
	ticketReferents?: Maybe<ServiceListResultOfTicket> /**  */
	ticketAffecteds?: Maybe<ServiceListResultOfTicket> /**  */
	ticketUserMakeRequests?: Maybe<ServiceListResultOfTicket> /**  */
	reminders?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModelReferents?: Maybe<ServiceListResultOfIncidentModel> /**  */
	incidentModelAffecteds?: Maybe<ServiceListResultOfIncidentModel> /**  */
	lastUpdateStatusWorkflows?: Maybe<ServiceListResultOfStatusWorkflow> /**  */
	lastUpdateArticleKnowledges?: Maybe<ServiceListResultOfArticleKnowledge> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	inheritLocationCharters?: Maybe<ServiceListResultOfInheritLocationCharter> /**  */
	operatorTeams?: Maybe<ServiceListResultOfOperatorTeam> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	privilege?: Maybe<ServiceSingleResultOfPrivilege> /**  */
	mainOperatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	userManager?: Maybe<ServiceSingleResultOfUser> /**  */
	collaborators?: Maybe<ServiceListResultOfUser> /**  */
	supplier?: Maybe<ServiceSingleResultOfSupplier> /**  */
	scanByLdap?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	managerRef?: Maybe<ServiceSingleResultOfUser> /**  */
	subordinates?: Maybe<ServiceListResultOfUser> /**  */
	inviteBy?: Maybe<ServiceSingleResultOfUser> /**  */
	invitations?: Maybe<ServiceListResultOfUser> /**  */
	revokeBy?: Maybe<ServiceSingleResultOfUser> /**  */
	revocations?: Maybe<ServiceListResultOfUser> /**  */
	mainAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	calendar?: Maybe<ServiceSingleResultOfCalendarConnector> /**  */
	userPrivilege?: Maybe<ServiceSingleResultOfUserPrivilege> /**  */
	businessTime?: Maybe<ServiceSingleResultOfBusinessTime> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	ownerAssets?: Maybe<ServiceListResultOfAsset> /**  */
	operatorAffectedEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	operatorReferentEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	softwarePackages?: Maybe<ServiceListResultOfSoftwarePackage> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	articleKnowledgeNotes?: Maybe<ServiceListResultOfArticleKnowledgeNote> /**  */
	outByConsumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	recipientConsumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	correctionStockMovements?: Maybe<ServiceListResultOfCorrectionStockMovement> /**  */
	dashboards?: Maybe<ServiceListResultOfDashboard> /**  */
	formDesigners?: Maybe<ServiceListResultOfFormDesigner> /**  */
	interventionModels?: Maybe<ServiceListResultOfInterventionModel> /**  */
	ldapAnalyses?: Maybe<ServiceListResultOfLdapAnalyse> /**  */
	loanManagers?: Maybe<ServiceListResultOfLoan> /**  */
	loanUsers?: Maybe<ServiceListResultOfLoan> /**  */
	locationCharters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	modelRulesDesigners?: Maybe<ServiceListResultOfModelRulesDesigner> /**  */
	lastUpdateNews?: Maybe<ServiceListResultOfNews> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	notifications?: Maybe<ServiceListResultOfNotification> /**  */
	outStockMovements?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	outStockMovementsRecipients?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	softwareReferentLinks?: Maybe<ServiceListResultOfSoftwareReferentLink> /**  */
	stockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	lastUpdateTaskStatusWorkflows?: Maybe<ServiceListResultOfTaskStatusWorkflow> /**  */
	ticketBacklogs?: Maybe<ServiceListResultOfTicketBacklog> /**  */
	taskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	lastUpdateWorkflows?: Maybe<ServiceListResultOfWorkflow> /**  */
	subId?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	userAccountControl: number /**  */
	userAccountDisabled: boolean /**  */
	userAccountExpires?: Maybe<Date> /**  */
	initial?: Maybe<string> /**  */
	registrationNumber?: Maybe<string> /**  */
	vip: boolean /**  */
	helpDeskActivated: boolean /**  */
	function?: Maybe<string> /**  */
	instantMessaging?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	secretary?: Maybe<string> /**  */
	hiringDate?: Maybe<Date> /**  */
	exitDate?: Maybe<Date> /**  */
	lastLogOn?: Maybe<Date> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
	lastLogOff?: Maybe<Date> /**  */
	lastConnected?: Maybe<Date> /**  */
	accessFailedCount: number /**  */
	homeDrive?: Maybe<string> /**  */
	homeDirectory?: Maybe<string> /**  */
	logonCount: number /**  */
	profilePath?: Maybe<string> /**  */
	scriptPath?: Maybe<string> /**  */
	logOnTo?: Maybe<string> /**  */
	allowDialIn: boolean /**  */
	officeName?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	settings?: Maybe<Settings> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userPasswords?: Array<Maybe<UserPassword>> /**  */
	operatorTeamIds: Array<string> /**  */
	mainOperatorTeamId?: Maybe<string> /**  */
	loans?: Array<Maybe<Loan>> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations?: Array<Maybe<UserHabilitation>> /**  */
	phones?: Array<Maybe<Phone>> /**  */
	address?: Maybe<Address> /**  */
	webSites?: Array<Maybe<string>> /**  */
	locations?: Array<Maybe<Location>> /**  */
	stockManagers?: Array<Maybe<Location>> /**  */
	locationId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	manager?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	containerDistinguishedName?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	lastImportAzureAd?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	principalPhone?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	principalHomePhone?: Maybe<string> /**  */
	principalIpPhone?: Maybe<string> /**  */
	principalMobile?: Maybe<string> /**  */
	principalPager?: Maybe<string> /**  */
	memberOf?: Array<Maybe<string>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAd>> /**  */
	assetIds: Array<string> /**  */
	invitation?: Maybe<Invitation> /**  */
	category: UserCategory /**  */
	userAffectedRule?: Maybe<UserAffectedRule> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	forceLdapImport: boolean /**  */
	domain?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	memberOfSecurityGroupIds?: Array<Maybe<SecurityGroupObject>> /**  */
	memberOfPopulationIds?: Array<Maybe<SecurityGroupObject>> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inviteById?: Maybe<string> /**  */
	revokeById?: Maybe<string> /**  */
	costHours: number /**  */
	supportLevelOperator: number /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	mainAssetId?: Maybe<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	inheritLocationCharterLocation?: Maybe<InheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<InheritLocationCharter> /**  */
	entityLocationCharter?: Maybe<EntityLocationCharter> /**  */
	privilegeId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	locationCharterAlreadyApply: boolean /**  */
	securityGroupAlreadyApply: boolean /**  */
	populationAlreadyApply: boolean /**  */
	calendarId?: Maybe<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	operatorIncidentRuleId?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	operatorRequestRuleId?: Maybe<string> /**  */
	operatorProblemRuleId?: Maybe<string> /**  */
	serviceDeskActivated: boolean /**  */
	dashboardIds: Array<string> /**  */
	allowEditOrganization: boolean /**  */
	recipentSystemNotification: boolean /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	userContractType?: Maybe<string> /**  */
	operatorIncidentListDesignerId?: Maybe<string> /**  */
	operatorRequestListDesignerId?: Maybe<string> /**  */
	operatorProblemListDesignerId?: Maybe<string> /**  */
} & IAccount & IPermissible & ILdapAttribute & IDistinguishedName & ILocalizable & IOrganizable & ISecurityGroup & IUserAzureAD

/**  */
export type IOrganizable = {
	organizationalUnitId?: Maybe<string> /**  */
} 

/**  */
export type IAssetReference = {
	assetCategoryId?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
} 

/**  */
export type IDistinguishedName = {
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
} 

/**  */
export type ISecurityGroup = {
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} 

/**  */
export type IAssetCategory = {
	assetCategoryId?: Maybe<string> /**  */
} 

/**  */
export type LifeCycle = {
	startLife?: Maybe<StartLife> /**  */
	renewal?: Maybe<Renewal> /**  */
	endLife?: Maybe<EndLife> /**  */
} 

/**  */
export type StartLife = {
	description?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
} 

/**  */
export type Renewal = {
	description?: Maybe<string> /**  */
	estimatedDate?: Maybe<Date> /**  */
	age: number /**  */
	usingDuration: number /**  */
} 

/**  */
export type EndLife = {
	reason?: Maybe<string> /**  */
	destination?: Maybe<string> /**  */
	removal?: Maybe<string> /**  */
	contributor?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	outDate?: Maybe<Date> /**  */
	reasonId?: Maybe<string> /**  */
	destinationId?: Maybe<string> /**  */
	recipient?: Maybe<string> /**  */
	removalDate?: Maybe<Date> /**  */
	removalId?: Maybe<string> /**  */
	bunddleNumber?: Maybe<string> /**  */
	disposalPrice: number /**  */
	recycleCost: number /**  */
	contributorId?: Maybe<string> /**  */
	restockingPrograms: boolean /**  */
	restockingLicenses: boolean /**  */
	dissociateAssets: boolean /**  */
} 

/**  */
export type FeatureMobility = {
	firstSimSlot?: Maybe<SimSlot> /**  */
	secondSimSlot?: Maybe<SimSlot> /**  */
} 

/**  */
export type SimSlot = {
	imei?: Maybe<string> /**  */
	phoneNumber?: Maybe<string> /**  */
	primaryPinCode?: Maybe<string> /**  */
	secondaryPinCode?: Maybe<string> /**  */
	primaryPukCode?: Maybe<string> /**  */
	secondaryPukCode?: Maybe<string> /**  */
} 

/**  */
export type FeatureSimCard = {
	id?: string /**  */
	phone?: Maybe<string> /**  */
	pin?: Maybe<string> /**  */
	pin2?: Maybe<string> /**  */
	puk?: Maybe<string> /**  */
	puk2?: Maybe<string> /**  */
	simMemoryId?: Maybe<string> /**  */
	simCardMemory?: Maybe<SimCardMemory> /**  */
} 

/**  */
export type SimCardMemory = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	capacity?: Maybe<string> /**  */
} 

/**  */
export type FeatureUps = {
	autonomyId?: Maybe<string> /**  */
	powerId?: Maybe<string> /**  */
	upsTypeId?: Maybe<string> /**  */
	chargerTime: number /**  */
	outlet: number /**  */
} 

/**  */
export type FeatureScanner = {
	id?: string /**  */
	charger: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategory>> /**  */
	paperFormats?: Array<Maybe<PaperFormat>> /**  */
	scanSpeedId?: Maybe<string> /**  */
	dpiResolutionId?: Maybe<string> /**  */
} 

/**  */
export type FeatureWebcam = {
	color: boolean /**  */
	microphone: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategory>> /**  */
} 

/**  */
export type FeatureModem = {
	id?: string /**  */
	wifi: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategory>> /**  */
	speedConnectionId?: Maybe<string> /**  */
} 

/**  */
export type FeatureTerminal = {
	barcodeReader: boolean /**  */
	rfidReader: boolean /**  */
	wifi: boolean /**  */
	bluetooth: boolean /**  */
	infrared: boolean /**  */
	miniUsb: boolean /**  */
	gPs: boolean /**  */
	camera: boolean /**  */
	memoryExtension?: Maybe<string> /**  */
	resolutionId?: Maybe<string> /**  */
} 

/**  */
export type FeatureSwitch = {
	id?: string /**  */
	desktop: boolean /**  */
	stackable: boolean /**  */
	rackable: boolean /**  */
	poe: boolean /**  */
	modem: boolean /**  */
	firewall: boolean /**  */
	consolePort: boolean /**  */
	proxy: boolean /**  */
	vpn: boolean /**  */
	utm: boolean /**  */
} 

/**  */
export type FeatureNetwork = {
	id?: string /**  */
	wifi: boolean /**  */
	sSid?: Maybe<string> /**  */
	securityKey?: Maybe<string> /**  */
	webAdministration: boolean /**  */
	snmp: boolean /**  */
} 

/**  */
export type FeaturePrinter = {
	id?: string /**  */
	color: boolean /**  */
	share: boolean /**  */
	shareName?: Maybe<string> /**  */
	speedBlack: number /**  */
	speedColor: number /**  */
	rectoVerso: boolean /**  */
	scanner: boolean /**  */
	fax: boolean /**  */
	dpiResolutionId?: Maybe<string> /**  */
	paperFormats?: Array<Maybe<PaperFormat>> /**  */
	connectionCategories?: Array<Maybe<ConnectionCategory>> /**  */
	printerTechnologyId?: Maybe<string> /**  */
	orientations?: Array<Maybe<Orientation>> /**  */
} 

/**  */
export type PaperFormat = {
	id?: string /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type Orientation = {
	id?: string /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type FeatureVideoProjector = {
	speaker: boolean /**  */
	remoteControl: boolean /**  */
	resolutions?: Array<Maybe<Resolution>> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	powerLampId?: Maybe<string> /**  */
	brightnessId?: Maybe<string> /**  */
	videoProjectorTypeId?: Maybe<string> /**  */
	noiseLevelId?: Maybe<string> /**  */
	videoProjectorFormatId?: Maybe<string> /**  */
	connectionCategories?: Array<Maybe<ConnectionCategory>> /**  */
} 

/**  */
export type Resolution = {
	id?: string /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type ConnectionCategory = {
	id?: string /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type FeatureMobile = {
	id?: string /**  */
	imei?: Maybe<string> /**  */
	firstSimId?: Maybe<string> /**  */
	secondSimId?: Maybe<string> /**  */
} 

/**  */
export type FeatureTelephony = {
	phoneNumber?: Maybe<string> /**  */
} 

/**  */
export type DetectWarranty = {
	lastAttempt?: Maybe<Date> /**  */
	lastSuccess?: Maybe<Date> /**  */
	message?: Maybe<string> /**  */
	success: boolean /**  */
} 

/**  */
export type AssetFinancial = {
	budget?: Maybe<ServiceSingleResultOfBudget> /**  */
	budgetId?: Maybe<string> /**  */
	acquisition?: Maybe<AssetAcquisition> /**  */
	internalInvoice?: Maybe<AssetInternalInvoice> /**  */
	accounting?: Maybe<AssetAccounting> /**  */
} 

/**  */
export type AssetAcquisition = {
	acquisitionMode?: Maybe<ServiceSingleResultOfAssetAcquisitionMode> /**  */
	acquisitionState?: Maybe<ServiceSingleResultOfAssetAcquisitionState> /**  */
	supplier?: Maybe<ServiceSingleResultOfSupplier> /**  */
	typeOfStaffing?: Maybe<ServiceSingleResultOfAssetTypeStaffing> /**  */
	modeId?: Maybe<string> /**  */
	stateId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	batchDate?: Maybe<Date> /**  */
	batchNumber?: Maybe<string> /**  */
	invoiceDate?: Maybe<Date> /**  */
	invoiceNumber?: Maybe<string> /**  */
	price: number /**  */
	supplierId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
} 

/**  */
export type AssetInternalInvoice = {
	date?: Maybe<Date> /**  */
	number?: Maybe<string> /**  */
	amount: number /**  */
	organizationalUnitId?: Maybe<string> /**  */
} 

/**  */
export type AssetAccounting = {
	assetCostCenter?: Maybe<ServiceSingleResultOfAssetCostCenter> /**  */
	assetTypeAccounting?: Maybe<ServiceSingleResultOfAssetTypeAccounting> /**  */
	typeId?: Maybe<string> /**  */
	startDepreciation?: Maybe<Date> /**  */
	endDepreciation?: Maybe<Date> /**  */
	duration: number /**  */
	numberImmobilization?: Maybe<string> /**  */
	recouping?: Maybe<string> /**  */
	analyticalImputation?: Maybe<string> /**  */
	costCenterId?: Maybe<string> /**  */
} 

/**  */
export type ICostCenter = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
} 

/**  */
export type AssetNamingConvention = {
	onBarCode?: Maybe<NamingConventionSummary> /**  */
	onInventoryNumber?: Maybe<NamingConventionSummary> /**  */
	onName?: Maybe<NamingConventionSummary> /**  */
	onNetBios?: Maybe<NamingConventionSummary> /**  */
	onAssetNumber?: Maybe<NamingConventionSummary> /**  */
	alreadyApply: boolean /**  */
} 

/**  */
export type EntityLocationCharter = {
	onLocationId?: Maybe<LocationCharterSummary> /**  */
	onOrganizationalUnitId?: Maybe<LocationCharterSummary> /**  */
} 

/**  */
export type AuditResult = {
	antiSpywareProducts?: Array<Maybe<AntiSpywareProduct>> /**  */
	antiVirusProducts?: Array<Maybe<AntiVirusProduct>> /**  */
	autoRuns?: Array<Maybe<AutoRun>> /**  */
	baseBoards?: Array<Maybe<BaseBoard>> /**  */
	bios?: Array<Maybe<Bios>> /**  */
	cdRomDrives?: Array<Maybe<CDRomDrive>> /**  */
	computerSystems?: Array<Maybe<ComputerSystem>> /**  */
	computerSystemProducts?: Array<Maybe<ComputerSystemProduct>> /**  */
	dataFiles?: Array<Maybe<DataFile>> /**  */
	diskDrives?: Array<Maybe<DiskDrive>> /**  */
	logicalDisks?: Array<Maybe<LogicalDisk>> /**  */
	environmentVariables?: Array<Maybe<EnvironmentVariable>> /**  */
	firewallProducts?: Array<Maybe<FirewallProduct>> /**  */
	fontInfoActions?: Array<Maybe<FontInfoAction>> /**  */
	groups?: Array<Maybe<Group>> /**  */
	keyboards?: Array<Maybe<Keyboard>> /**  */
	monitors?: Array<Maybe<Monitor>> /**  */
	virtualMachines?: Array<Maybe<VirtualMachine>> /**  */
	networkAdapters?: Array<Maybe<NetworkAdapter>> /**  */
	networkDrives?: Array<Maybe<NetworkDrive>> /**  */
	ntLogEvents?: Array<Maybe<NtLogEvent>> /**  */
	operatingSystems?: Array<Maybe<OperatingSystem>> /**  */
	parallelPorts?: Array<Maybe<ParallelPort>> /**  */
	physicalMemoryArrays?: Array<Maybe<PhysicalMemoryArray>> /**  */
	physicalMemories?: Array<Maybe<PhysicalMemory>> /**  */
	pnpEntities?: Array<Maybe<PnpEntity>> /**  */
	pointingDevices?: Array<Maybe<PointingDevice>> /**  */
	portConnectors?: Array<Maybe<PortConnector>> /**  */
	potsModems?: Array<Maybe<PotsModem>> /**  */
	printers?: Array<Maybe<Printer>> /**  */
	processes?: Array<Maybe<Process>> /**  */
	processors?: Array<Maybe<Processor>> /**  */
	programs?: Array<Maybe<Program>> /**  */
	quickFixEngineerings?: Array<Maybe<QuickFixEngineering>> /**  */
	workApplications?: Array<Maybe<WorkApplication>> /**  */
	serialPorts?: Array<Maybe<SerialPort>> /**  */
	windowsServices?: Array<Maybe<WindowsService>> /**  */
	shares?: Array<Maybe<Share>> /**  */
	sqlServers?: Array<Maybe<SqlServer>> /**  */
	soundDevices?: Array<Maybe<SoundDevice>> /**  */
	systemEnclosures?: Array<Maybe<SystemEnclosure>> /**  */
	systemSlots?: Array<Maybe<SystemSlot>> /**  */
	tapeDrives?: Array<Maybe<TapeDrive>> /**  */
	userAccountControls?: Array<Maybe<UserAccountControl>> /**  */
	userAccounts?: Array<Maybe<UserAccount>> /**  */
	userProfiles?: Array<Maybe<UserProfile>> /**  */
	videoControllers?: Array<Maybe<VideoController>> /**  */
	windowsFirewalls?: Array<Maybe<WindowsFirewall>> /**  */
	windowsUpdates?: Array<Maybe<WindowsUpdate>> /**  */
	scanRegistries?: Array<Maybe<ScanRegistry>> /**  */
	printerConsumables?: Array<Maybe<PrinterConsumable>> /**  */
	pageCounts?: Array<Maybe<PageCount>> /**  */
	copyCounts?: Array<Maybe<CopyCount>> /**  */
	scannerCounts?: Array<Maybe<ScannerCount>> /**  */
	faxCounts?: Array<Maybe<FaxCount>> /**  */
	entryTables?: Array<Maybe<EntryTable>> /**  */
	vlans?: Array<Maybe<Vlan>> /**  */
	accessPoints?: Array<Maybe<AccessPoint>> /**  */
	portOpens?: Array<Maybe<PortOpen>> /**  */
	odbcDrivers?: Array<Maybe<OdbcDriver>> /**  */
	systemAccounts?: Array<Maybe<SystemAccount>> /**  */
	logonSessions?: Array<Maybe<LogonSession>> /**  */
	pnpSignedDrivers?: Array<Maybe<PnpSignedDriver>> /**  */
	serialNumbers?: Array<Maybe<SerialNumber>> /**  */
	uptimeCalendars?: Array<Maybe<UptimeCalendar>> /**  */
	bootConfigurations?: Array<Maybe<BootConfiguration>> /**  */
	pageFiles?: Array<Maybe<PageFile>> /**  */
	codecFiles?: Array<Maybe<CodecFile>> /**  */
	osRecoveryConfigurations?: Array<Maybe<OsRecoveryConfiguration>> /**  */
	scsiControllers?: Array<Maybe<ScsiController>> /**  */
	pcmciaControllers?: Array<Maybe<PcmciaController>> /**  */
	infraredDevices?: Array<Maybe<InfraredDevice>> /**  */
	batteries?: Array<Maybe<Battery>> /**  */
	portableBatteries?: Array<Maybe<PortableBattery>> /**  */
	logonHistories?: Array<Maybe<LogonHistory>> /**  */
	desktops?: Array<Maybe<Desktop>> /**  */
	optionalFeatures?: Array<Maybe<OptionalFeature>> /**  */
	iis?: Array<Maybe<Iis>> /**  */
	ldapProperties?: Array<Maybe<LdapProperty>> /**  */
	logicalDiskHistories?: Array<Maybe<LogicalDiskHistory>> /**  */
	internetExplorers?: Array<Maybe<InternetExplorer>> /**  */
	encryptableVolumes?: Array<Maybe<EncryptableVolume>> /**  */
	snmpSummary?: Array<Maybe<SnmpSummary>> /**  */
} 

/**  */
export type AssetDelivery = {
	deliveryId?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	orderDate?: Maybe<Date> /**  */
	deliveryOrder?: Maybe<string> /**  */
	receivedById?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
} 

/**  */
export type ClarilogLocalAgent = {
	isUpToDate: boolean /**  */
	isConnected: boolean /**  */
	scanByLocalAgent?: Maybe<ServiceSingleResultOfScanByLocalAgent> /**  */
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	firstCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	forceAudit: boolean /**  */
	mustBeAudited: boolean /**  */
	clientApplicationtName?: Maybe<string> /**  */
	updater?: Maybe<Updater> /**  */
	clarilogAgentSettings?: Maybe<ClarilogAgentSettings> /**  */
	campaignIds: Array<string> /**  */
	packageToDeploy?: Array<Maybe<Package>> /**  */
} & IAgent

/**  */
export type LogicalDisk = {
	percent: number /**  */
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	providerName?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	compressed: boolean /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driveType: DriveType /**  */
	fileSystem?: Maybe<string> /**  */
	freeSpace: number /**  */
	name?: Maybe<string> /**  */
	volumeName?: Maybe<string> /**  */
	size: number /**  */
	volumeSerialNumber?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type IAssetProperty = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
} 

/**  */
export type Registry = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
} & IAssetProperty & IRegistry

/**  */
export type SoftwareProperty = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
} & IAssetProperty & ISoftwareProperty

/**  */
export type SecurityGroupObject = {
	id?: string /**  */
	isManual: boolean /**  */
} & IPopulationSecurityGroupObject

/**  */
export type ServiceSingleResultOfAccessPoint = {
	data?: Maybe<AccessPoint> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AccessPoint = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	macAddress?: Maybe<string> /**  */
	bSSid?: Maybe<string> /**  */
	ssid?: Maybe<string> /**  */
	state?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfAccount = {
	data?: Maybe<Account> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type PermissionScope = {
	value?: Maybe<string> /**  */
	type: PermissionScopeRole /**  */
	permissions?: Array<Maybe<Permission>> /**  */
} 

/**  */
export type DynamicPropertyNumeric = {
	dynamicPropertyNumericFormat: DynamicPropertyNumericFormat /**  */
	minimun: number /**  */
	maximun: number /**  */
} 

/**  */
export type DynamicPropertyDateTime = {
	dynamicPropertyDateFormat: DynamicPropertyDateFormat /**  */
} 

/**  */
export type DynamicPropertySelectList = {
	dynamicPropertyListId?: Maybe<string> /**  */
	multiple: boolean /**  */
} 

/**  */
export type DynamicPropertyColumnOption = {
	sortable: boolean /**  */
	filterable: boolean /**  */
	showInColumnChooser: boolean /**  */
	visible: boolean /**  */
	template?: Maybe<string> /**  */
} 

/**  */
export type DynamicPropertyFormOption = {
	required: boolean /**  */
	visible: boolean /**  */
} 

/**  */
export type ServiceSingleResultOfAffectedRule = {
	data?: Maybe<AffectedRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AffectedRule = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & IAffectedRule & IRank & ISystem

/**  */
export type ServiceListResultOfAffectedRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AffectedRule>> /**  */
} 

/**  */
export type AgentConfiguration = {
	id?: string /**  */
	configuration?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfAgentConfiguration = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AgentConfiguration>> /**  */
} 

/**  */
export type ServiceSingleResultOfAgentHistory = {
	data?: Maybe<AgentHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AgentHistory = {
	id?: string /**  */
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	host?: Maybe<string> /**  */
	clarilogException?: Maybe<ClarilogException> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRule> /**  */
	hidden: boolean /**  */
} & IAgentHistory

/**  */
export type ServiceListResultOfAgentHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AgentHistory>> /**  */
} 

/**  */
export type AgentToken = {
	id?: string /**  */
	date?: Maybe<Date> /**  */
	agentTokenAction: AgentTokenAction /**  */
	clientApplicationtName?: Maybe<string> /**  */
	agentId: string /**  */
	query?: Maybe<string> /**  */
	queryName?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfAgentToken = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AgentToken>> /**  */
} 

/**  */
export type AlarmMailTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	emailPrototypeId: string /**  */
	queryContext?: Maybe<string> /**  */
	force: boolean /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfAlarmMailTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AlarmMailTask>> /**  */
} 

/**  */
export type Alert = {
	isNew: boolean /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwarePackage?: Maybe<ServiceSingleResultOfSoftwarePackage> /**  */
	scanConfiguration?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	budget?: Maybe<ServiceSingleResultOfBudget> /**  */
	contract?: Maybe<ServiceSingleResultOfContract> /**  */
	loan?: Maybe<ServiceSingleResultOfLoan> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	assetModel?: Maybe<ServiceSingleResultOfAssetModel> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	logicalDisk?: Maybe<ServiceSingleResultOfLogicalDisk> /**  */
	printerConsumable?: Maybe<ServiceSingleResultOfPrinterConsumable> /**  */
	message?: Maybe<ServiceSingleResultOfMessage> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	incident?: Maybe<ServiceSingleResultOfIncident> /**  */
	request?: Maybe<ServiceSingleResultOfRequest> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	ticketTask?: Maybe<ServiceSingleResultOfTicketTask> /**  */
	intervention?: Maybe<ServiceSingleResultOfIntervention> /**  */
	alarm?: Maybe<ServiceSingleResultOfAlarm> /**  */
	softwareGroup?: Maybe<ServiceSingleResultOfSoftwareGroup> /**  */
	name?: Maybe<string> /**  */
	alarmId: string /**  */
	dateOfCreation?: Maybe<Date> /**  */
	acknowledgedUsersIds: Array<string> /**  */
	affectedUsersIds: Array<string> /**  */
	hiddenUsersIds: Array<string> /**  */
	hidden: boolean /**  */
	assetId?: Maybe<string> /**  */
	softwareId?: Maybe<string> /**  */
	softwarePackageId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	contractId?: Maybe<string> /**  */
	loanId?: Maybe<string> /**  */
	assetModelId?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	logicalDiskId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	printerConsumableId?: Maybe<string> /**  */
	messageId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	softwareGroupId?: Maybe<string> /**  */
	problemId?: Maybe<string> /**  */
	incidentId?: Maybe<string> /**  */
	requestId?: Maybe<string> /**  */
	ticketTaskId?: Maybe<string> /**  */
	interventionId?: Maybe<string> /**  */
	minimumStockByStorageUnitAlertResult?: Maybe<MinimumStockByStorageUnitAlertResult> /**  */
	sended: boolean /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
} 

/**  */
export type AlertTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	alarmTriggerType: AlarmTriggerTypeEnum /**  */
	oldEntity?: Maybe<any> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfAlertTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AlertTask>> /**  */
} 

/**  */
export type AnalyseScanByAzureAdTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfAnalyseScanByAzureAdTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AnalyseScanByAzureAdTask>> /**  */
} 

/**  */
export type ServiceSingleResultOfAntiSpywareProduct = {
	data?: Maybe<AntiSpywareProduct> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AntiSpywareProduct = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	displayName?: Maybe<string> /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToSignedProductExe?: Maybe<string> /**  */
	pathToSignedReportingExe?: Maybe<string> /**  */
	productState: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfAntiVirusProduct = {
	data?: Maybe<AntiVirusProduct> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AntiVirusProduct = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	displayName?: Maybe<string> /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToSignedProductExe?: Maybe<string> /**  */
	pathToSignedReportingExe?: Maybe<string> /**  */
	productState: number /**  */
} & IAssetProperty

/**  */
export type ApplicationAzureAd = {
	id?: string /**  */
	scanByAzureAds?: Maybe<ServiceListResultOfScanByAzureAd> /**  */
	name?: Maybe<string> /**  */
	tenantAzureId?: Maybe<string> /**  */
	clientId?: Maybe<string> /**  */
	clientSecret?: Maybe<string> /**  */
	expireClientSecretDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
} & IBLockByEnvironnement

/**  */
export type ServiceListResultOfApplicationAzureAd = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ApplicationAzureAd>> /**  */
} 

/**  */
export type AppLog = {
	id?: string /**  */
	date?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	data?: Maybe<any> /**  */
} 

/**  */
export type ServiceListResultOfAppLog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AppLog>> /**  */
} 

/**  */
export type Appointment = {
	id?: string /**  */
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
} & IAppointment

/**  */
export type ArticleKnowledgeCategory = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	articles?: Maybe<ServiceListResultOfArticleKnowledge> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfArticleKnowledgeCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ArticleKnowledgeCategory>> /**  */
} 

/**  */
export type ArticleKnowledgeNote = {
	id?: string /**  */
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	articleKnowledge?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	userId: string /**  */
	rating: number /**  */
	articleKnowledgeId: string /**  */
} 

/**  */
export type ServiceListResultOfArticleKnowledgeNote = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ArticleKnowledgeNote>> /**  */
} 

/**  */
export type ServiceSingleResultOfDouble = {
	data: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type AssetAccount = {
	id?: string /**  */
	assetId: string /**  */
	accountId: string /**  */
} 

/**  */
export type ServiceListResultOfAssetAccount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetAccount>> /**  */
} 

/**  */
export type AssetAcquisitionMode = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfAssetAcquisitionMode = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetAcquisitionMode>> /**  */
} 

/**  */
export type AssetAcquisitionState = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfAssetAcquisitionState = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetAcquisitionState>> /**  */
} 

/**  */
export type AssetAffectedRule = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	byNetbios: boolean /**  */
	byMacAddress: boolean /**  */
	byIpAddress: boolean /**  */
	bySerialNumber: boolean /**  */
	byUuid: boolean /**  */
	byDistinguishedName: boolean /**  */
	byFqdn: boolean /**  */
	byName: boolean /**  */
	auditTypes: Array<AuditType> /**  */
} & IAffectedRule & IRank & ISystem

/**  */
export type ServiceListResultOfAssetAffectedRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetAffectedRule>> /**  */
} 

/**  */
export type AssetCategoryRule = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assetCategory?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	assetCategoryId?: Maybe<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	expression?: Maybe<any> /**  */
	description?: Maybe<string> /**  */
} & IRank

/**  */
export type ServiceListResultOfAssetCategoryRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetCategoryRule>> /**  */
} 

/**  */
export type AssetContract = {
	id?: string /**  */
	assetId: string /**  */
	contractId: string /**  */
} 

/**  */
export type ServiceListResultOfAssetContract = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetContract>> /**  */
} 

/**  */
export type AssetCostCenter = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
} & ICostCenter

/**  */
export type ServiceListResultOfAssetCostCenter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetCostCenter>> /**  */
} 

/**  */
export type AssetLoan = {
	id?: string /**  */
	assetId: string /**  */
	loanId: string /**  */
} 

/**  */
export type ServiceListResultOfAssetLoan = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetLoan>> /**  */
} 

/**  */
export type AssetModelStockStorageUnit = {
	storageUnitId: string /**  */
	storageUnit?: Maybe<Location> /**  */
	amount: number /**  */
	dynamicProperties: Array<KeyValuePairOfStringAndDynamicPropertyValue> /**  */
	isApproved: boolean /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	additionalProperties: Array<KeyValuePairOfStringAndObject> /**  */
	id?: string /**  */
	externalObjectId?: Maybe<string> /**  */
} 

/**  */
export type Location = {
	countAssets: number /**  */
	countUsers: number /**  */
	charters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	assetModelStockEntries?: Maybe<ServiceListResultOfAssetModelStockEntry> /**  */
	storageUnitConsumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	consumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	correctionStockMovements?: Maybe<ServiceListResultOfCorrectionStockMovement> /**  */
	loans?: Maybe<ServiceListResultOfLoan> /**  */
	loan?: Maybe<ServiceSingleResultOfLoan> /**  */
	locationCharters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	outStockMovements?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	outStockMovementsLocations?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	fromStockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	toStockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	wallSockets?: Maybe<ServiceListResultOfWallSocket> /**  */
	error: boolean /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	allowSelection: boolean /**  */
	stock: boolean /**  */
	maxCapacity: boolean /**  */
	stockManagerId?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedField> /**  */
	responsableId?: Maybe<string> /**  */
	locationCategoryId: string /**  */
	primaryContactId?: Maybe<string> /**  */
	templateId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	allRulesMustBeValidAsset: boolean /**  */
	allRulesMustBeValidUser: boolean /**  */
	address?: Maybe<Address> /**  */
	phone?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	loanId?: Maybe<string> /**  */
	loanIds: Array<string> /**  */
	roomNumber?: Maybe<string> /**  */
	loanable: boolean /**  */
	contactId?: Maybe<string> /**  */
	buildingTypeId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	assetModelStockEntryIds: Array<string> /**  */
} & ITemplate & ILocationCharterRule & ISecurityGroup & ILdapAttribute & IDistinguishedName & IAllowedEntity

/**  */
export type KeyValuePairOfStringAndDynamicPropertyValue = {
	key: string /**  */
	value: DynamicPropertyValue /**  */
} 

/**  */
export type DynamicPropertyValue = {

} 

/**  */
export type KeyValuePairOfStringAndObject = {
	key: string /**  */
} 

/**  */
export type ServiceListResultOfAssetModelStockStorageUnit = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetModelStockStorageUnit>> /**  */
} 

/**  */
export type AssetModelStockEntry = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	storageUnit?: Maybe<ServiceSingleResultOfLocation> /**  */
	assetModel?: Maybe<ServiceSingleResultOfAssetModel> /**  */
	storageUnitId?: Maybe<string> /**  */
	inStockId: string /**  */
	inStockCartId: string /**  */
	outStockCartId: string /**  */
	outStockId: string /**  */
	correctionStockCartId: string /**  */
	correctionStockId: string /**  */
	createdAssetId: string /**  */
	status: AssetModelStockEntryStatus /**  */
	assetModelId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
} 

/**  */
export type AssetProperty = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfAssetProperty = {
	data?: Maybe<AssetProperty> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfAssetProperty = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetProperty>> /**  */
} 

/**  */
export type ServiceListResultOfAntiSpywareProduct = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AntiSpywareProduct>> /**  */
} 

/**  */
export type ServiceListResultOfAntiVirusProduct = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AntiVirusProduct>> /**  */
} 

/**  */
export type OperatingSystem = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	mainOperatingSystemOnAssets?: Maybe<ServiceListResultOfAsset> /**  */
	bootDevice?: Maybe<string> /**  */
	buildNumber?: Maybe<string> /**  */
	buildType?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	codeSet?: Maybe<string> /**  */
	countryCode?: Maybe<string> /**  */
	csdVersion?: Maybe<string> /**  */
	currentTimeZone: number /**  */
	debug: boolean /**  */
	description?: Maybe<string> /**  */
	distributed: boolean /**  */
	encryptionLevel: number /**  */
	foregroundApplicationBoost: number /**  */
	freePhysicalMemory: number /**  */
	freeSpaceInPagingFiles: number /**  */
	freeVirtualMemory: number /**  */
	largeSystemCache: number /**  */
	lastBootUpTime?: Maybe<Date> /**  */
	localDateTime?: Maybe<Date> /**  */
	locale?: Maybe<string> /**  */
	maxNumberOfProcesses: number /**  */
	maxProcessMemorySize: number /**  */
	muiLanguages?: Array<Maybe<string>> /**  */
	name?: Maybe<string> /**  */
	numberOfLicensedUsers: number /**  */
	numberOfProcesses: number /**  */
	numberOfUsers: number /**  */
	operatingSystemSku: number /**  */
	organization?: Maybe<string> /**  */
	osArchitecture?: Maybe<string> /**  */
	osLanguage: number /**  */
	osProductSuite: number /**  */
	osType: number /**  */
	otherTypeDescription?: Maybe<string> /**  */
	paeEnabled: boolean /**  */
	plusProductId?: Maybe<string> /**  */
	plusVersionNumber?: Maybe<string> /**  */
	primary: boolean /**  */
	productType: number /**  */
	registeredUser?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	servicePackMajorVersion: number /**  */
	servicePackMinorVersion: number /**  */
	sizeStoredInPagingFiles: number /**  */
	status?: Maybe<string> /**  */
	suiteMask: number /**  */
	systemDevice?: Maybe<string> /**  */
	systemDirectory?: Maybe<string> /**  */
	systemDrive?: Maybe<string> /**  */
	totalSwapSpaceSize: number /**  */
	totalVirtualMemorySize: number /**  */
	totalVisibleMemorySize: number /**  */
	version?: Maybe<string> /**  */
	windowsDirectory?: Maybe<string> /**  */
	digitalProductId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
} & IAssetProperty & ISoftwareProperty

/**  */
export type ServiceListResultOfAccessPoint = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AccessPoint>> /**  */
} 

/**  */
export type PrinterConsumable = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	name?: Maybe<string> /**  */
	maxLevel: number /**  */
	actualLevel: number /**  */
	colorCode?: Maybe<string> /**  */
	percent: number /**  */
	date?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type SnmpSummary = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	sysDescription?: Maybe<string> /**  */
	sysOid?: Maybe<string> /**  */
	sysUpTime?: Maybe<string> /**  */
	sysContact?: Maybe<string> /**  */
	sysName?: Maybe<string> /**  */
	sysLocation?: Maybe<string> /**  */
	sysService?: Maybe<string> /**  */
	snmpType: SnmpType /**  */
	vendorId?: Maybe<number> /**  */
} & IAssetProperty

/**  */
export type EntityPropertyTracking = {
	id?: string /**  */
	propertyName?: Maybe<string> /**  */
	enabled: boolean /**  */
	parentProperty?: Maybe<string> /**  */
	propertyFullName?: Maybe<string> /**  */
} 

/**  */
export type AssetPropertyTracking = {
	id?: string /**  */
	entityName?: Maybe<string> /**  */
	canUpdated: boolean /**  */
	canDeleted: boolean /**  */
	canAdded: boolean /**  */
	entityPropertyTracking?: Array<Maybe<EntityPropertyTracking>> /**  */
} 

/**  */
export type ServiceListResultOfAssetPropertyTracking = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetPropertyTracking>> /**  */
} 

/**  */
export type AssetProvisionalCommissioning = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	assetId: string /**  */
	ownerId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
} & ILocalizable

/**  */
export type ServiceListResultOfAssetProvisionalCommissioning = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetProvisionalCommissioning>> /**  */
} 

/**  */
export type AuditTime = {
	id?: string /**  */
	methodName?: Maybe<string> /**  */
	time: number /**  */
	isAudited: boolean /**  */
	count?: Maybe<number> /**  */
	clarilogException?: Maybe<ClarilogException> /**  */
} 

/**  */
export type ServiceListResultOfLifeCycleRecipient = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LifeCycleRecipient>> /**  */
} 

/**  */
export type LifeCycleRecipient = {
	name?: Maybe<string> /**  */
	id?: string /**  */
	externalObjectId?: Maybe<string> /**  */
} 

/**  */
export type FeatureMonitor = {
	size?: Maybe<string> /**  */
	resolutions?: Array<Maybe<Resolution>> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	monitorTechnology?: Maybe<MonitorTechnology> /**  */
	resolutionMax?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfMonitorTechnology = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MonitorTechnology>> /**  */
} 

/**  */
export type MonitorTechnology = {
	id?: string /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfFeatureMonitor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FeatureMonitor>> /**  */
} 

/**  */
export type AssetStatus = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfAssetStatus = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetStatus>> /**  */
} 

/**  */
export type AssetTypeAccounting = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfAssetTypeAccounting = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetTypeAccounting>> /**  */
} 

/**  */
export type AssetTypeStaffing = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfAssetTypeStaffing = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetTypeStaffing>> /**  */
} 

/**  */
export type AsyncTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	progression: number /**  */
	methodName?: Maybe<string> /**  */
	paramMap?: Maybe<AsyncTaskParam> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfAsyncTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AsyncTask>> /**  */
} 

/**  */
export type AutoRun = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
} & IAssetProperty & IRegistry

/**  */
export type ServiceSingleResultOfAutoRun = {
	data?: Maybe<AutoRun> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfAutoRun = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AutoRun>> /**  */
} 

/**  */
export type AxelData = {
	id?: string /**  */
	model?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfAxelData = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AxelData>> /**  */
} 

/**  */
export type BaseBoard = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	product?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfBaseBoard = {
	data?: Maybe<BaseBoard> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfBaseBoard = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<BaseBoard>> /**  */
} 

/**  */
export type Battery = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	batteryRechargeTime: number /**  */
	batteryStatus: BatteryStatus /**  */
	caption?: Maybe<string> /**  */
	chemistry: Chemistry /**  */
	description?: Maybe<string> /**  */
	designCapacity: number /**  */
	designVoltage: number /**  */
	deviceId?: Maybe<string> /**  */
	estimatedChargeRemaining: number /**  */
	estimatedRunTime: number /**  */
	expectedBatteryLife: number /**  */
	expectedLife: number /**  */
	fullChargeCapacity: number /**  */
	maxRechargeTime: number /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	timeOnBattery: number /**  */
	timeToFullCharge: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfBattery = {
	data?: Maybe<Battery> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfBattery = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Battery>> /**  */
} 

/**  */
export type Bios = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	buildNumber?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	releaseDate?: Maybe<Date> /**  */
	serialNumber?: Maybe<string> /**  */
	smBiosVersion?: Maybe<string> /**  */
	smBiosMajorVersion: number /**  */
	smBiosMinorVersion: number /**  */
	smBiosPresent: boolean /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfBios = {
	data?: Maybe<Bios> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfBios = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Bios>> /**  */
} 

/**  */
export type BootConfiguration = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	bootDirectory?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	configurationPath?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	lastDrive?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	scratchDirectory?: Maybe<string> /**  */
	settingId?: Maybe<string> /**  */
	tempDirectory?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfBootConfiguration = {
	data?: Maybe<BootConfiguration> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfBootConfiguration = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<BootConfiguration>> /**  */
} 

/**  */
export type BudgetCategory = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfBudgetCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<BudgetCategory>> /**  */
} 

/**  */
export type ServiceListResultOfTimeZone = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TimeZone>> /**  */
} 

/**  */
export type TimeZone = {
	id?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
} 

/**  */
export type ServiceSingleResultOfCalendarConnector = {
	data?: Maybe<CalendarConnector> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type CampaignHistory = {
	id?: string /**  */
	campaign?: Maybe<ServiceSingleResultOfCampaign> /**  */
	package?: Maybe<ServiceSingleResultOfPackage> /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	date?: Maybe<Date> /**  */
	state: HistoryState /**  */
	packageId: string /**  */
	assetId: string /**  */
	campaignId: string /**  */
	error?: Maybe<string> /**  */
	deployId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfCampaignHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CampaignHistory>> /**  */
} 

/**  */
export type CDRomDrive = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	mediaType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfCDRomDrive = {
	data?: Maybe<CDRomDrive> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfCDRomDrive = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CDRomDrive>> /**  */
} 

/**  */
export type CheckFileTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfCheckFileTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CheckFileTask>> /**  */
} 

/**  */
export type CheckSizeFileTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfCheckSizeFileTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CheckSizeFileTask>> /**  */
} 

/**  */
export type ClarilogServer = {
	isUpToDate: boolean /**  */
	isConnected: boolean /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	simultaneousScan: number /**  */
	scanHistoryOption?: Maybe<ScanHistoryOption> /**  */
	locationId?: Maybe<string> /**  */
	clarilogServerLogs?: Array<Maybe<ClarilogServerLog>> /**  */
	localId: string /**  */
	version?: Maybe<string> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	updater?: Maybe<Updater> /**  */
	clarilogAgentSettings?: Maybe<ClarilogAgentSettings> /**  */
} & IAgent

/**  */
export type ServiceListResultOfClarilogServer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ClarilogServer>> /**  */
} 

/**  */
export type CodecFile = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	group?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfCodecFile = {
	data?: Maybe<CodecFile> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfCodecFile = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CodecFile>> /**  */
} 

/**  */
export type Command = {
	commandAssetCategories?: Array<Maybe<CommandAssetCategory>> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	exceptionAssets?: Maybe<ServiceListResultOfAsset> /**  */
	category: CommandCategory /**  */
	name?: Maybe<TranslatedField> /**  */
	pathToExecutable?: Maybe<string> /**  */
	pathToParamterFile?: Maybe<string> /**  */
	port: number /**  */
	commandLine?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	protocole: CommandProtocole /**  */
	userIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	exceptionAssetIds: Array<string> /**  */
	dataCommand?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type CommandAssetCategory = {
	id?: string /**  */
	parentId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	active: boolean /**  */
	default: boolean /**  */
	automatique: boolean /**  */
} 

/**  */
export type ServiceListResultOfCommandAssetCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CommandAssetCategory>> /**  */
} 

/**  */
export type CommentaryModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	type?: Array<Maybe<string>> /**  */
	commentarySupportLevelIds: Array<number> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	qualification?: Array<Maybe<string>> /**  */
	usageType?: Array<Maybe<string>> /**  */
} & ISecurityGroup

/**  */
export type ComputerSystemProduct = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	identifyingNumber?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	skuNumber?: Maybe<string> /**  */
	uuid?: Maybe<string> /**  */
	vendor?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfComputerSystemProduct = {
	data?: Maybe<ComputerSystemProduct> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfComputerSystemProduct = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ComputerSystemProduct>> /**  */
} 

/**  */
export type ComputerSystem = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	adminPasswordStatus: number /**  */
	automaticManagedPagefile: boolean /**  */
	automaticResetBootOption: boolean /**  */
	automaticResetCapability: boolean /**  */
	bootOptionOnLimit: number /**  */
	bootOptionOnWatchDog: number /**  */
	bootRomSupported: boolean /**  */
	bootupState?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	chassisBootupState: number /**  */
	currentTimeZone: number /**  */
	daylightInEffect: boolean /**  */
	description?: Maybe<string> /**  */
	dnsHostName?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	domainRole: DomainRole /**  */
	enableDaylightSavingsTime: boolean /**  */
	frontPanelResetStatus: number /**  */
	infraredSupported: boolean /**  */
	initialLoadInfo?: Maybe<string> /**  */
	keyboardPasswordStatus: number /**  */
	lastLoadInfo?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	nameFormat?: Maybe<string> /**  */
	networkServerModeEnabled: boolean /**  */
	numberOfLogicalProcessors: number /**  */
	numberOfProcessors: number /**  */
	oem?: Array<Maybe<string>> /**  */
	partOfDomain: boolean /**  */
	pauseAfterReset: number /**  */
	pcSystemType: PcSystemType /**  */
	powerManagementSupported: boolean /**  */
	powerOnPasswordStatus: number /**  */
	powerState: number /**  */
	powerSupplyState: number /**  */
	primaryOwnerContact?: Maybe<string> /**  */
	primaryOwnerName?: Maybe<string> /**  */
	resetCapability: number /**  */
	resetCount: number /**  */
	resetLimit: number /**  */
	roles?: Array<Maybe<string>> /**  */
	status?: Maybe<string> /**  */
	supportContactDescription?: Array<Maybe<string>> /**  */
	systemStartupDelay: number /**  */
	systemStartupOptions?: Array<Maybe<string>> /**  */
	systemType?: Maybe<string> /**  */
	thermalState: number /**  */
	totalPhysicalMemory: number /**  */
	userName?: Maybe<string> /**  */
	wakeUpType: number /**  */
	workGroup?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfComputerSystem = {
	data?: Maybe<ComputerSystem> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfComputerSystem = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ComputerSystem>> /**  */
} 

/**  */
export type ConsumableMovement = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	movementId: string /**  */
	movementType?: Maybe<TranslatedField> /**  */
	name?: Maybe<string> /**  */
	movementDate?: Maybe<Date> /**  */
	quantity: number /**  */
	unitPrice?: Maybe<number> /**  */
	amount?: Maybe<number> /**  */
	reference?: Maybe<string> /**  */
	referenceName?: Maybe<TranslatedField> /**  */
	referencePath?: Maybe<TranslatedField> /**  */
	inStockMovement?: Maybe<InStockMovement> /**  */
	assetAcquisitionMode?: Maybe<TranslatedField> /**  */
	typeOfStaffing?: Maybe<string> /**  */
	supplier?: Maybe<string> /**  */
	outStockMovement?: Maybe<OutStockMovement> /**  */
	assetRecipient?: Maybe<string> /**  */
	storageUnit?: Maybe<TranslatedField> /**  */
	reason?: Maybe<string> /**  */
} 

/**  */
export type ContractCategory = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	children?: Maybe<ServiceListResultOfContract> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
} & ISystem

/**  */
export type ServiceListResultOfContractCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ContractCategory>> /**  */
} 

/**  */
export type CopyCount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	color: number /**  */
	black: number /**  */
	twoColor: number /**  */
	monochrome: number /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfCopyCount = {
	data?: Maybe<CopyCount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfCopyCount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CopyCount>> /**  */
} 

/**  */
export type CorrectionStockMovement = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	storageUnit?: Maybe<ServiceSingleResultOfLocation> /**  */
	correctionBy?: Maybe<ServiceSingleResultOfUser> /**  */
	name?: Maybe<string> /**  */
	carts?: Array<Maybe<CorrectionCart>> /**  */
	date?: Maybe<Date> /**  */
	correctionById?: Maybe<string> /**  */
	storageUnitId: string /**  */
	storageUnitIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
} 

/**  */
export type CostCenter = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
} & ICostCenter

/**  */
export type ServiceListResultOfCostCenter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CostCenter>> /**  */
} 

/**  */
export type CronJobHourlyTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	endExecution?: Maybe<Date> /**  */
	cronJobHourlyType: CronJobHourlyType /**  */
	cronExpression?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfCronJobHourlyTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CronJobHourlyTask>> /**  */
} 

/**  */
export type CustomField = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	testField?: Maybe<TranslatedField> /**  */
	defaultName?: Maybe<TranslatedField> /**  */
	fieldName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfCustomField = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CustomField>> /**  */
} 

/**  */
export type Dashboard = {
	id?: string /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	title?: Maybe<TranslatedField> /**  */
	fileId?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	deleted: boolean /**  */
	updated: boolean /**  */
	serviceDesk: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	timerAutoRefresh: number /**  */
	autoRefresh: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	description?: Maybe<string> /**  */
	userIds: Array<string> /**  */
} & ISystem & IRank

/**  */
export type DataFile = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	scanDataFile?: Maybe<ServiceSingleResultOfScanDataFile> /**  */
	accessMask: number /**  */
	caption?: Maybe<string> /**  */
	compressed: boolean /**  */
	compressionMethod?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	eightDotThreeFileName?: Maybe<string> /**  */
	encrypted: boolean /**  */
	encryptionMethod?: Maybe<string> /**  */
	extension?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	fsName?: Maybe<string> /**  */
	hidden: boolean /**  */
	inUseCount: number /**  */
	lastAccessed?: Maybe<Date> /**  */
	lastModified?: Maybe<Date> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	readable: boolean /**  */
	status?: Maybe<string> /**  */
	system: boolean /**  */
	version?: Maybe<string> /**  */
	writeable: boolean /**  */
	scanDataFileId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfDataFile = {
	data?: Maybe<DataFile> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type DayOff = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	businessTimes?: Maybe<ServiceListResultOfBusinessTime> /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	description?: Maybe<TranslatedField> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	businessTimeIds: Array<string> /**  */
	daysConfig?: Array<Maybe<DayOffConfig>> /**  */
	key?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfBusinessTime = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<BusinessTime>> /**  */
} 

/**  */
export type DeleteFileTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	fileIds: Array<string> /**  */
	ticketType?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfDeleteFileTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DeleteFileTask>> /**  */
} 

/**  */
export type Desktop = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	borderWidth: number /**  */
	caption?: Maybe<string> /**  */
	coolSwitch: boolean /**  */
	cursorBlinkRate: number /**  */
	description?: Maybe<string> /**  */
	dragFullWindows: boolean /**  */
	gridGranularity: number /**  */
	iconSpacing: number /**  */
	iconTitleFaceName?: Maybe<string> /**  */
	iconTitleSize: number /**  */
	iconTitleWrap: boolean /**  */
	name?: Maybe<string> /**  */
	pattern?: Maybe<string> /**  */
	screenSaverActive: boolean /**  */
	screenSaverExecutable?: Maybe<string> /**  */
	screenSaverSecure: boolean /**  */
	screenSaverTimeout: number /**  */
	settingId?: Maybe<string> /**  */
	wallpaper?: Maybe<string> /**  */
	wallpaperStretched: boolean /**  */
	wallpaperTiled: boolean /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfDesktop = {
	data?: Maybe<Desktop> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfDesktop = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Desktop>> /**  */
} 

/**  */
export type DiskDrive = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	compressionMethod?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	firmwareRevision?: Maybe<string> /**  */
	index: number /**  */
	interfaceType?: Maybe<string> /**  */
	maxBlockSize: number /**  */
	mediaType?: Maybe<string> /**  */
	minBlockSize: number /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partitions: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
	scsiBus: number /**  */
	scsiLogicalUnit: number /**  */
	scsiPort: number /**  */
	scsiTargetId: number /**  */
	sectorsPerTrack: number /**  */
	serialNumber?: Maybe<string> /**  */
	size: number /**  */
	bytesPerSector: number /**  */
	totalCylinders: number /**  */
	totalHeads: number /**  */
	totalSectors: number /**  */
	totalTracks: number /**  */
	tracksPerCylinder: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfDiskDrive = {
	data?: Maybe<DiskDrive> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfDiskDrive = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DiskDrive>> /**  */
} 

/**  */
export type DiskRamInformation = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	index?: Maybe<string> /**  */
	oid?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	allocationUnit: number /**  */
	size: number /**  */
	used: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfDiskRamInformation = {
	data?: Maybe<DiskRamInformation> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfDiskRamInformation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DiskRamInformation>> /**  */
} 

/**  */
export type Account = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
} & IAccount & IPermissible

/**  */
export type OrganizationalUnit = {
	countAssets: number /**  */
	countUsers: number /**  */
	charters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	consumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	locationCharters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	outStockMovements?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	name?: Maybe<TranslatedField> /**  */
	error: boolean /**  */
	objectSid?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	address?: Maybe<Address> /**  */
	description?: Maybe<TranslatedField> /**  */
	manager?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedField> /**  */
	templateId?: Maybe<string> /**  */
	allRulesMustBeValidAsset: boolean /**  */
	allRulesMustBeValidUser: boolean /**  */
	scanByLdapId?: Maybe<string> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	allowSelection: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ITemplate & ILdapAttribute & IDistinguishedName & ILocationCharterRule & ISecurityGroup & IAllowedEntity

/**  */
export type Asset = {
	memoriesString?: Maybe<string> /**  */
	snmpSummary?: Maybe<ServiceSingleResultOfSnmpSummary> /**  */
	virtualMachineHost?: Maybe<ServiceSingleResultOfAsset> /**  */
	logicalDisks?: Maybe<ServiceListResultOfLogicalDisk> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	lifeCycleEndReason?: Maybe<ServiceSingleResultOfString> /**  */
	lifeCycleEndDestination?: Maybe<ServiceSingleResultOfString> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	warranties?: Maybe<ServiceListResultOfContract> /**  */
	mainImpactedTickets?: Maybe<ServiceListResultOfTicket> /**  */
	mainUsers?: Maybe<ServiceListResultOfUser> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	loans?: Maybe<ServiceListResultOfLoan> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	inheritLocationCharters?: Maybe<ServiceListResultOfInheritLocationCharter> /**  */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	model?: Maybe<ServiceSingleResultOfModelManufacturer> /**  */
	manufacturer?: Maybe<ServiceSingleResultOfModelManufacturer> /**  */
	assetStatus?: Maybe<ServiceSingleResultOfAssetStatus> /**  */
	assetCategory?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	owner?: Maybe<ServiceSingleResultOfUser> /**  */
	scanConfiguration?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	createdModel?: Maybe<ServiceSingleResultOfAssetModel> /**  */
	loan?: Maybe<ServiceSingleResultOfLoan> /**  */
	mainOperatingSystem?: Maybe<ServiceSingleResultOfOperatingSystem> /**  */
	assetProperties?: Maybe<ServiceListResultOfAssetProperty> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	softwarePackages?: Maybe<ServiceListResultOfSoftwarePackage> /**  */
	campaigns?: Maybe<ServiceListResultOfCampaign> /**  */
	campaignHistories?: Maybe<ServiceListResultOfCampaignHistory> /**  */
	commands?: Maybe<ServiceListResultOfCommand> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapAnalyses?: Maybe<ServiceListResultOfLdapAnalyse> /**  */
	locationCharters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	outStocks?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	softwareHostedLinks?: Maybe<ServiceListResultOfSoftwareHostedLink> /**  */
	stockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	virtualMachines?: Maybe<ServiceListResultOfVirtualMachine> /**  */
	isAudited: boolean /**  */
	assetCategoryId?: Maybe<string> /**  */
	assetStatusId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	fqdn?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	power: number /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	skuNumber?: Maybe<string> /**  */
	uuid?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	macAddresses?: Array<Maybe<string>> /**  */
	inventoryNumber?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	netBios?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	firstAudit?: Maybe<Date> /**  */
	lastAudit?: Maybe<Date> /**  */
	lastSuccessAudit?: Maybe<Date> /**  */
	auditState?: Maybe<ActionHistoryState> /**  */
	domainId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	phoneAsset?: Maybe<string> /**  */
	ownerId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<AssetCategoryRule> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	inventoryHistory?: Maybe<InventoryHistory> /**  */
	lifeCycle?: Maybe<LifeCycle> /**  */
	mainOf?: Array<Maybe<Account>> /**  */
	remoteControlId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	lendable: boolean /**  */
	featureComment?: Maybe<string> /**  */
	featureMobility?: Maybe<FeatureMobility> /**  */
	featureSimCard?: Maybe<FeatureSimCard> /**  */
	featureMonitor?: Maybe<FeatureMonitor> /**  */
	featureUps?: Maybe<FeatureUps> /**  */
	featureScanner?: Maybe<FeatureScanner> /**  */
	featureWebcam?: Maybe<FeatureWebcam> /**  */
	featureModem?: Maybe<FeatureModem> /**  */
	featureTerminal?: Maybe<FeatureTerminal> /**  */
	featureSwitch?: Maybe<FeatureSwitch> /**  */
	featureNetwork?: Maybe<FeatureNetwork> /**  */
	featurePrinter?: Maybe<FeaturePrinter> /**  */
	featureVideoProjector?: Maybe<FeatureVideoProjector> /**  */
	featureMobile?: Maybe<FeatureMobile> /**  */
	firstMobileDevices?: Array<Maybe<FeatureMobile>> /**  */
	secondMobileDevices?: Array<Maybe<FeatureMobile>> /**  */
	featureTelephony?: Maybe<FeatureTelephony> /**  */
	createdModelId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	outStockIds: Array<string> /**  */
	stockTransfersIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	detectWarranty?: Maybe<DetectWarranty> /**  */
	financial?: Maybe<AssetFinancial> /**  */
	loanId?: Maybe<string> /**  */
	assetNamingConvention?: Maybe<AssetNamingConvention> /**  */
	entityLocationCharter?: Maybe<EntityLocationCharter> /**  */
	fileIds: Array<string> /**  */
	lastCommandId?: Maybe<string> /**  */
	auditResult?: Maybe<AuditResult> /**  */
	operatingSystem?: Maybe<string> /**  */
	mainOperatingSystemId?: Maybe<string> /**  */
	memories?: Maybe<number> /**  */
	processor?: Maybe<string> /**  */
	warrantyStart?: Maybe<Date> /**  */
	warrantyEnd?: Maybe<Date> /**  */
	loanable: boolean /**  */
	groupIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	commandIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	deliveryAsset?: Maybe<AssetDelivery> /**  */
	lastCalcRenewDate?: Maybe<Date> /**  */
	campaignIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	clarilogLocalAgent?: Maybe<ClarilogLocalAgent> /**  */
	ticketIds: Array<string> /**  */
	mainImpactedTicketIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	inheritLocationCharterLocation?: Maybe<InheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<InheritLocationCharter> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
} & ILocalizable & IOrganizable & IAssetReference & IDistinguishedName & ISecurityGroup & IAssetCategory

/**  */
export type Domain = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	netbiosName?: Maybe<string> /**  */
	accounts?: Array<Maybe<Account>> /**  */
	organisations?: Array<Maybe<OrganizationalUnit>> /**  */
	assets?: Array<Maybe<Asset>> /**  */
} 

/**  */
export type ServiceListResultOfDomain = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Domain>> /**  */
} 

/**  */
export type DynamicPropertyField = {
	id?: string /**  */
	dynamicPropertyGroup?: Maybe<ServiceSingleResultOfDynamicPropertyGroup> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	position: number /**  */
	dynamicPropertyGroupId: string /**  */
	propertyName?: Maybe<string> /**  */
	entityTypes?: Array<Maybe<string>> /**  */
	dynamicPropertyFieldType: DynamicPropertyFieldEnum /**  */
	dynamicPropertyNumericFormat?: Maybe<DynamicPropertyNumeric> /**  */
	dynamicPropertyDateTime?: Maybe<DynamicPropertyDateTime> /**  */
	dynamicPropertySelectList?: Maybe<DynamicPropertySelectList> /**  */
	dynamicPropertyColumnOption?: Maybe<DynamicPropertyColumnOption> /**  */
	dynamicPropertyFormOption?: Maybe<DynamicPropertyFormOption> /**  */
	expression?: Maybe<any> /**  */
} 

/**  */
export type DynamicPropertyGroup = {
	id?: string /**  */
	dynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField> /**  */
	name?: Maybe<TranslatedField> /**  */
	position: number /**  */
	collapse: boolean /**  */
	description?: Maybe<TranslatedField> /**  */
	useFreeSpace: boolean /**  */
	dynamicPropertyLocation: DynamicPropertyLocationEnum /**  */
	entityTypes?: Array<Maybe<string>> /**  */
} 

/**  */
export type ServiceListResultOfDynamicPropertyGroup = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DynamicPropertyGroup>> /**  */
} 

/**  */
export type DynamicPropertyList = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	values?: Array<Maybe<DynamicPropertyListValue>> /**  */
	entityTypes?: Array<Maybe<string>> /**  */
} 

/**  */
export type ServiceListResultOfDynamicPropertyListValue = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DynamicPropertyListValue>> /**  */
} 

/**  */
export type DynamicPropertyListValue = {
	id?: string /**  */
	fr?: Maybe<string> /**  */
	nl?: Maybe<string> /**  */
	en?: Maybe<string> /**  */
	it?: Maybe<string> /**  */
	de?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfDynamicPropertyList = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DynamicPropertyList>> /**  */
} 

/**  */
export type EmailLink = {
	id?: string /**  */
	linkId: string /**  */
	name?: Maybe<TranslatedField> /**  */
	emailType?: Maybe<string> /**  */
	qualification?: Maybe<string> /**  */
	subject?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfEmailLink = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailLink>> /**  */
} 

/**  */
export type EmailCredential = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	emailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfEmailCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailCredential>> /**  */
} 

/**  */
export type EmailPrototypeLog = {
	id?: string /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	alarm?: Maybe<ServiceSingleResultOfAlarm> /**  */
	email?: Maybe<EmailPrototypeSend> /**  */
	type?: Maybe<string> /**  */
	sent?: Maybe<Date> /**  */
	success: boolean /**  */
	error?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	alarmId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfEmailSubstituteField = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailSubstituteField>> /**  */
} 

/**  */
export type EmailSubstituteField = {
	key?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	method?: Maybe<string> /**  */
} 

/**  */
export type EncryptableVolume = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	conversionStatus: ConversionStatus /**  */
	deviceId?: Maybe<string> /**  */
	driveLetter?: Maybe<string> /**  */
	encryptionMethod: EncryptionMethod /**  */
	isVolumeInitializedForProtection: boolean /**  */
	persistentVolumeId?: Maybe<string> /**  */
	protectionStatus: ProtectionStatus /**  */
	volumeType: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfEncryptableVolume = {
	data?: Maybe<EncryptableVolume> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfEncryptableVolume = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EncryptableVolume>> /**  */
} 

/**  */
export type EntryTable = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	ifIndex?: Maybe<string> /**  */
	ifDescr?: Maybe<string> /**  */
	ifType?: Maybe<string> /**  */
	ifMtu?: Maybe<string> /**  */
	ifSpeed?: Maybe<string> /**  */
	ifPhysAddress?: Maybe<string> /**  */
	ifAdminStatus?: Maybe<string> /**  */
	ifOperStatus?: Maybe<string> /**  */
	ifLastChange?: Maybe<string> /**  */
	ifInOctets?: Maybe<string> /**  */
	ifInUcastPkts?: Maybe<string> /**  */
	ifInNUcastPkts?: Maybe<string> /**  */
	ifInDiscards?: Maybe<string> /**  */
	ifInErrors?: Maybe<string> /**  */
	ifInUnknownProtos?: Maybe<string> /**  */
	ifOutOctets?: Maybe<string> /**  */
	ifOutUcastPkts?: Maybe<string> /**  */
	ifOutNUcastPkts?: Maybe<string> /**  */
	ifOutDiscards?: Maybe<string> /**  */
	ifOutErrors?: Maybe<string> /**  */
	ifOutQLen?: Maybe<string> /**  */
	ifSpecific?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfEntryTable = {
	data?: Maybe<EntryTable> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfEntryTable = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EntryTable>> /**  */
} 

/**  */
export type EnvironmentVariable = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
} & IAssetProperty & IRegistry

/**  */
export type ServiceSingleResultOfEnvironmentVariable = {
	data?: Maybe<EnvironmentVariable> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfEnvironmentVariable = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EnvironmentVariable>> /**  */
} 

/**  */
export type EsxCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	webService?: Maybe<string> /**  */
} & INetworkCredential

/**  */
export type ServiceListResultOfEsxCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EsxCredential>> /**  */
} 

/**  */
export type ExchangeConnector = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	exchangeCalendar?: Maybe<ExchangeCalendar> /**  */
	domain?: Maybe<string> /**  */
	logon?: Maybe<string> /**  */
	mailBox?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	useAutodiscoverUrl: boolean /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	expireAccesToken?: Maybe<Date> /**  */
	azureToken?: Maybe<string> /**  */
	authAzurePassword: boolean /**  */
	type: ExchangeConnectorTypeEnum /**  */
} & ICalendarConnector & ISecurityGroup

/**  */
export type ServiceListResultOfExchangeCalendar = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ExchangeCalendar>> /**  */
} 

/**  */
export type ExchangeCalendar = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	calendarId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Appointment>> /**  */
} 

/**  */
export type FaxCount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	color: number /**  */
	black: number /**  */
	send: number /**  */
	receive: number /**  */
	date?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfFaxCount = {
	data?: Maybe<FaxCount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfFaxCount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FaxCount>> /**  */
} 

/**  */
export type Feedback = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	send?: Maybe<Date> /**  */
} 

/**  */
export type ServiceListResultOfFeedback = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Feedback>> /**  */
} 

/**  */
export type FieldMapping = {
	id?: string /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	ldapFieldMappings?: Array<Maybe<LdapFieldMapping>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfFieldMapping = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FieldMapping>> /**  */
} 

/**  */
export type FirewallProduct = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	companyName?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	enabled: boolean /**  */
	enableUImd5Hash: boolean /**  */
	enableUiParameters: boolean /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToEnableUi?: Maybe<string> /**  */
	versionNumber?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfFirewallProduct = {
	data?: Maybe<FirewallProduct> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfFirewallProduct = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FirewallProduct>> /**  */
} 

/**  */
export type FontInfoAction = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	actionId?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	direction: number /**  */
	file?: Maybe<string> /**  */
	fontTitle?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	softwareElementId?: Maybe<string> /**  */
	softwareElementState: number /**  */
	targetOperatingSystem: number /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfFontInfoAction = {
	data?: Maybe<FontInfoAction> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfFontInfoAction = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FontInfoAction>> /**  */
} 

/**  */
export type FormDesigner = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<TranslatedField> /**  */
	isDefault: boolean /**  */
	qualification?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	json?: Maybe<string> /**  */
	formDesignerTo: FormDesignerTo /**  */
	userIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type GroupAzureAd = {
	id?: string /**  */
	objectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	memberOf?: Array<Maybe<string>> /**  */
	parentId?: Maybe<string> /**  */
	applicationAzureId?: Maybe<string> /**  */
} 

/**  */
export type Group = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	localAccount: boolean /**  */
	name?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	sidType: number /**  */
	status?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	groupUsers?: Array<Maybe<GroupUser>> /**  */
} & IAssetProperty & IDistinguishedName

/**  */
export type ServiceSingleResultOfGroup = {
	data?: Maybe<Group> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfGroup = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Group>> /**  */
} 

/**  */
export type IamtCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	tls: boolean /**  */
} & INetworkCredential

/**  */
export type ServiceListResultOfIamtCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IamtCredential>> /**  */
} 

/**  */
export type Iis = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	iisProgramGroup?: Maybe<string> /**  */
	installPath?: Maybe<string> /**  */
	pathWwwRoot?: Maybe<string> /**  */
	productString?: Maybe<string> /**  */
	setupString?: Maybe<string> /**  */
	versionString?: Maybe<string> /**  */
	majorVersion: number /**  */
	metabaseSetMajorVersion: number /**  */
	metabaseSetMinorVersion: number /**  */
	minorVersion: number /**  */
	iisSites?: Array<Maybe<IisSite>> /**  */
	iisApplicationPools?: Array<Maybe<IisApplicationPool>> /**  */
	iisComponents?: Array<Maybe<IisComponent>> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfIis = {
	data?: Maybe<Iis> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfIis = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Iis>> /**  */
} 

/**  */
export type ImportAuditTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	scanImportOption?: Maybe<ServiceSingleResultOfScanImportOption> /**  */
	asset?: Maybe<Asset> /**  */
	auditResult?: Maybe<AuditResult> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	scanImportOptionId: string /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfImportAuditTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ImportAuditTask>> /**  */
} 

/**  */
export type ServiceListResultOfTag = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Tag>> /**  */
} 

/**  */
export type Tag = {
	name?: Maybe<string> /**  */
} 

/**  */
export type Incident = {
	isFavorite: boolean /** Permet de savoir si le ticket est un favoris. */
	isReminderOpen: boolean /** Permet de savoir si le reminder est disponible. */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	concernedProject?: Maybe<ServiceSingleResultOfConcernedProject> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	channel?: Maybe<ServiceSingleResultOfChannel> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	ticketStatusReasons?: Maybe<ServiceSingleResultOfTicketStatusReason> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	sla?: Maybe<ServiceSingleResultOfServiceLevelAgreement> /**  */
	mainImpactedAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	userAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	userMakeRequest?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	parent?: Maybe<ServiceSingleResultOfTicket> /**  */
	children?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	privateResolutionArticle?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<Reminder> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfaction> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
} & ITicket

/**  */
export type Badge = {
	key?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	hasValue: boolean /**  */
	type: BadgeType /**  */
} 

/**  */
export type TicketStatus = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	ticketStatusReasons?: Maybe<ServiceListResultOfTicketStatusReason> /**  */
	ticketBacklogs?: Maybe<ServiceListResultOfTicketBacklog> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	qualification?: Array<Maybe<string>> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ITicketQualification & ISystem

/**  */
export type OperatorLevel = {
	value: number /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type TicketLogEvents = {
	title?: Maybe<TranslatedField> /**  */
	entityType?: Maybe<string> /**  */
	entityTypeName?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	activity?: Maybe<TranslatedField> /**  */
	createdBy?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	emailRecipient?: Array<Maybe<string>> /**  */
	id?: string /**  */
	externalObjectId?: Maybe<string> /**  */
} 

/**  */
export type InfraredDevice = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfInfraredDevice = {
	data?: Maybe<InfraredDevice> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfInfraredDevice = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InfraredDevice>> /**  */
} 

/**  */
export type InheritLocationCharter = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	excludeUsers?: Maybe<ServiceListResultOfUser> /**  */
	excludeAssets?: Maybe<ServiceListResultOfAsset> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	organizationalUnit: InheritLocationCharterCategory /**  */
	location: InheritLocationCharterCategory /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	optionLogonSessions?: Array<Maybe<ScanImportOptionLogonSession>> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
} & ISystem & IRank

/**  */
export type InStockMovement = {
	realTotal?: Maybe<ServiceSingleResultOfDouble> /**  */
	costTotal: number /** Permet de calculer le coup total d'une Livraison. */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	receivedById: string /**  */
	deliveryOrder?: Maybe<string> /**  */
	deliveryNumber: number /**  */
	defaultStorageUnitId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	order?: Maybe<Order> /**  */
	bill?: Maybe<OrderBill> /**  */
	financial?: Maybe<StockFinancialInformation> /**  */
	carts?: Array<Maybe<InCart>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type InternetExplorer = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	proxyServer?: Maybe<string> /**  */
	autoConfigUrl?: Maybe<string> /**  */
	autoDetect: boolean /**  */
	proxyEnable: boolean /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfInternetExplorer = {
	data?: Maybe<InternetExplorer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfInternetExplorer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InternetExplorer>> /**  */
} 

/**  */
export type InterventionModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	operator?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	activity?: Maybe<ServiceSingleResultOfActivity> /**  */
	name?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	elaspedTime: string /**  */
	activityId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	cost: number /**  */
} & ISecurityGroup

/**  */
export type InventoryHistory = {
	id?: string /**  */
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	host?: Maybe<string> /**  */
	clarilogException?: Maybe<ClarilogException> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRule> /**  */
	hidden: boolean /**  */
	scanConfiguration?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	networkCredential?: Maybe<ServiceSingleResultOfNetworkCredential> /**  */
	scanImportOption?: Maybe<ServiceSingleResultOfScanImportOption> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	clientApplicationtVersion?: Maybe<string> /**  */
	auditTimes?: Array<Maybe<AuditTime>> /**  */
	networkCredentialId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	auditType: AuditType /**  */
	new: boolean /**  */
	macAddress?: Maybe<string> /**  */
	resolveName?: Maybe<string> /**  */
	scanImportOptionId?: Maybe<string> /**  */
	attempts: number /**  */
} & IAgentHistory

/**  */
export type Keyboard = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	isLocked: boolean /**  */
	lastErrorCode: number /**  */
	layout?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	numberOfFunctionKeys: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfKeyboard = {
	data?: Maybe<Keyboard> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfKeyboard = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Keyboard>> /**  */
} 

/**  */
export type LabelTemplate = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	file?: Maybe<ServiceSingleResultOfFileModel> /**  */
	barcodeFormat: BarcodeFormat /**  */
	availableFormats?: Array<Maybe<string>> /**  */
	paperType?: Maybe<string> /**  */
	availablePaperTypes?: Array<Maybe<string>> /**  */
	includeLogo: boolean /**  */
	includeBarcode: boolean /**  */
	barcodeType?: Maybe<string> /**  */
	visibleFields?: Array<Maybe<string>> /**  */
	height: number /**  */
	width: number /**  */
	name?: Maybe<TranslatedField> /**  */
	fileId?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfLabelTemplate = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LabelTemplate>> /**  */
} 

/**  */
export type EntityReference = {
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type LayoutModel = {
	id?: string /**  */
	file?: Maybe<ServiceSingleResultOfFileModel> /**  */
	fileId?: Maybe<string> /**  */
	roleIds: Array<string> /**  */
	userIds: Array<string> /**  */
	type: LayoutModelCategory /**  */
	entityReference?: Maybe<EntityReference> /**  */
	version: string /**  */
} 

/**  */
export type ServiceListResultOfLayoutModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LayoutModel>> /**  */
} 

/**  */
export type LdapAnalyse = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	ldapObjectClassType: LdapObjectClassType /**  */
	assetId?: Maybe<string> /**  */
	ldapObject?: Maybe<LdapOrganizationalUnit> /**  */
	userId?: Maybe<string> /**  */
	find: boolean /**  */
	ldapAnalyseFindType: LdapAnalyseFindType /**  */
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	disabled: boolean /**  */
	ldapAnalyseProcess?: Maybe<LdapAnalyseProcess> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	azureObject?: Maybe<UserAzureAD> /**  */
} 

/**  */
export type ServiceListResultOfExcludeFilterUser = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ExcludeFilterUser>> /**  */
} 

/**  */
export type ExcludeFilterUser = {
	name?: Maybe<string> /**  */
	filterExpression?: Maybe<string> /**  */
} 

/**  */
export type LdapOrganizationalUnit = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	ldapObjectClassType: LdapObjectClassType /**  */
	lastUpdate?: Maybe<Date> /**  */
	scanByLdapId: string /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	userAccountControl: number /**  */
	disabled: boolean /**  */
} 

/**  */
export type ServiceListResultOfLdapOrganizationalUnit = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LdapOrganizationalUnit>> /**  */
} 

/**  */
export type LdapProperty = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	objectClass?: Maybe<string> /**  */
	cN?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	objectGuid: string /**  */
	userAccountControl: number /**  */
	badPwdCount: number /**  */
	codePage: number /**  */
	countryCode: number /**  */
	badPasswordTime?: Maybe<Date> /**  */
	lastLogoff?: Maybe<Date> /**  */
	lastLogon?: Maybe<Date> /**  */
	localPolicyFlags: number /**  */
	pwdLastSet?: Maybe<Date> /**  */
	primaryGroupId: number /**  */
	objectSid?: Maybe<string> /**  */
	accountExpires?: Maybe<Date> /**  */
	logonCount: number /**  */
	sAmAccountName?: Maybe<string> /**  */
	operatingSystem?: Maybe<string> /**  */
	operatingSystemVersion?: Maybe<string> /**  */
	dNsHostName?: Maybe<string> /**  */
	objectCategory?: Maybe<string> /**  */
	isCriticalSystemObject: boolean /**  */
	path?: Maybe<string> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	nTSecurityDescriptor?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfLdapProperty = {
	data?: Maybe<LdapProperty> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfLdapProperty = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LdapProperty>> /**  */
} 

/**  */
export type LifeCycleDestination = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfLifeCycleDestination = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LifeCycleDestination>> /**  */
} 

/**  */
export type LifeCycleReason = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfLifeCycleReason = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LifeCycleReason>> /**  */
} 

/**  */
export type LifeCycleTicketConfig = {
	fromStatusId: string /**  */
	toStatusId: Array<string> /**  */
} 

/**  */
export type LifeCycleTicket = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<string> /**  */
	qualification?: Maybe<string> /**  */
	config?: Array<Maybe<LifeCycleTicketConfig>> /**  */
} 

/**  */
export type ServiceListResultOfLifeCycleTicket = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LifeCycleTicket>> /**  */
} 

/**  */
export type LinkAsset = {
	id?: string /**  */
	sourceId: string /**  */
	destinationId: string /**  */
	type?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	isSystem: boolean /**  */
} 

/**  */
export type ServiceListResultOfLinkAsset = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LinkAsset>> /**  */
} 

/**  */
export type ListDesigner = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	isDefault: boolean /**  */
	qualification?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	columns?: Maybe<string> /**  */
	listType: ListDesignerType /**  */
} & ISystem

/**  */
export type ServiceListResultOfListDesigner = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ListDesigner>> /**  */
} 

/**  */
export type LoanAppointment = {
	id?: string /**  */
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	loanId: string /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
} & IAppointment

/**  */
export type ServiceListResultOfLoanAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LoanAppointment>> /**  */
} 

/**  */
export type LoanStatus = {
	id?: string /**  */
	loans?: Maybe<ServiceListResultOfLoan> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfLoanStatus = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LoanStatus>> /**  */
} 

/**  */
export type LocationCategory = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	icon?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
} & ISystem

/**  */
export type ServiceListResultOfLocationCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LocationCategory>> /**  */
} 

/**  */
export type LocationCharter = {
	caption?: Maybe<string> /**  */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	excludeAssets?: Maybe<ServiceListResultOfAsset> /**  */
	excludeUsers?: Maybe<ServiceListResultOfUser> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	locationCharterCategory: LocationCharterCategory /**  */
	locationCharterCategoryForImport?: Maybe<string> /**  */
	titles?: Array<Maybe<string>> /**  */
	companies?: Array<Maybe<string>> /**  */
	cities?: Array<Maybe<string>> /**  */
	departments?: Array<Maybe<string>> /**  */
	offices?: Array<Maybe<string>> /**  */
	ipAddressStart?: Maybe<string> /**  */
	ipAddressEnd?: Maybe<string> /**  */
	netbiosOrIpAddresses?: Array<Maybe<string>> /**  */
	macAddresses?: Array<Maybe<string>> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	user: boolean /**  */
	asset: boolean /**  */
	ldapDistinguishedName?: Array<Maybe<string>> /**  */
	azureGroupObjectIds?: Array<Maybe<string>> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	locationCharterLdapAsset?: Maybe<LocationCharterLdap> /**  */
	locationCharterLdapUser?: Maybe<LocationCharterLdap> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfAssetModelStockEntry = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetModelStockEntry>> /**  */
} 

/**  */
export type LogicalDiskHistory = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	diskDriveCaption?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driveType: DriveType /**  */
	fileSystem?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	mediaType: number /**  */
	freeSpace: number /**  */
	size: number /**  */
	volumeName?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	useSpace: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfLogicalDiskHistory = {
	data?: Maybe<LogicalDiskHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfLogicalDiskHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LogicalDiskHistory>> /**  */
} 

/**  */
export type ServiceSingleResultOfLogicalDisk = {
	data?: Maybe<LogicalDisk> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfLogicalDisk = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LogicalDisk>> /**  */
} 

/**  */
export type LogonHistory = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	date?: Maybe<Date> /**  */
	logonHistoryMode: LogonHistoryMode /**  */
	userCaption?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	userDomain?: Maybe<string> /**  */
	userSid?: Maybe<string> /**  */
	recordNumber: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfLogonHistory = {
	data?: Maybe<LogonHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfLogonHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LogonHistory>> /**  */
} 

/**  */
export type LogonSession = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	authenticationPackage?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logonId?: Maybe<string> /**  */
	logonType: LogonType /**  */
	name?: Maybe<string> /**  */
	startTime?: Maybe<Date> /**  */
	status?: Maybe<string> /**  */
	logonName?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	fullName?: Maybe<string> /**  */
	azureObjectId?: Maybe<string> /**  */
	azureAccountId?: Maybe<string> /**  */
	azureDisplayName?: Maybe<string> /**  */
	azureIdentityName?: Maybe<string> /**  */
	azureTenantId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfLogonSession = {
	data?: Maybe<LogonSession> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfLogonSession = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LogonSession>> /**  */
} 

/**  */
export type MailSlurpBlackList = {
	id?: string /**  */
	email?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfMailSlurpBlackList = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MailSlurpBlackList>> /**  */
} 

/**  */
export type MailSlurpWebhookTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	inboxId: string /**  */
	emailId: string /**  */
	messageId?: Maybe<string> /**  */
	webhookId?: Maybe<string> /**  */
	eventName?: Maybe<string> /**  */
	createdAt?: Maybe<Date> /**  */
	to?: Array<Maybe<string>> /**  */
	from?: Maybe<string> /**  */
	cc?: Array<Maybe<string>> /**  */
	bcc?: Array<Maybe<string>> /**  */
	subject?: Maybe<string> /**  */
	attachmentMetaDatas?: Array<Maybe<MailSlurpAttachmentMetaData>> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfMailSlurpWebhookTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MailSlurpWebhookTask>> /**  */
} 

/**  */
export type MatrixPriority = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	config?: Array<Maybe<MatrixPriorityConfig>> /**  */
} & ITicketQualification

/**  */
export type ServiceListResultOfMatrixPriority = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MatrixPriority>> /**  */
} 

/**  */
export type MessageModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	name?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	fileIds: Array<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ITicketQualification & ISecurityGroup

/**  */
export type ServiceListResultOfModelManufacturer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ModelManufacturer>> /**  */
} 

/**  */
export type ModelRulesDesigner = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	operators?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<TranslatedField> /**  */
	isDefault: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	rules?: Array<Maybe<ModelRule>> /**  */
	editRules?: Array<Maybe<ModelRule>> /**  */
	taskRules?: Array<Maybe<ModelRule>> /**  */
	taskEditRules?: Array<Maybe<ModelRule>> /**  */
	interventionRules?: Array<Maybe<ModelRule>> /**  */
	interventionEditRules?: Array<Maybe<ModelRule>> /**  */
	escalationRules?: Array<Maybe<ModelRule>> /**  */
	escalationEditRules?: Array<Maybe<ModelRule>> /**  */
	dynamicFieldRules?: Array<Maybe<ModelRule>> /**  */
	dynamicFieldEditRules?: Array<Maybe<ModelRule>> /**  */
	operatorIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfModelRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ModelRule>> /**  */
} 

/**  */
export type ModelRule = {
	title?: Maybe<string> /**  */
	translatedTitle?: Maybe<TranslatedField> /**  */
	fieldName?: Maybe<string> /**  */
	editable: boolean /**  */
	visible: boolean /**  */
	required: boolean /**  */
	option?: Maybe<ModelRuleOption> /**  */
	statusIds: Array<string> /**  */
} 

/**  */
export type Monitor = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	size: number /**  */
	manufactureDate?: Maybe<Date> /**  */
	manufactureDateYear: number /**  */
	manufactureDateWeek: number /**  */
	pnpId?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	serialNumberOriginal?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfMonitor = {
	data?: Maybe<Monitor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfMonitor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Monitor>> /**  */
} 

/**  */
export type NamingConventionHistory = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	created?: Maybe<Date> /**  */
	value?: Maybe<string> /**  */
	namingPolicyId?: Maybe<string> /**  */
	namingPolicy?: Maybe<NamingConvention> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfNamingConventionHistory = {
	data?: Maybe<NamingConventionHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfNamingConventionHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NamingConventionHistory>> /**  */
} 

/**  */
export type NamingConvention = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	assetCategoryIds: Array<string> /**  */
	excludeAssetCategoryIds: Array<string> /**  */
	namingConventionCategory: NamingConventionCategory /**  */
	locked: boolean /**  */
	empty: boolean /**  */
	inheritCategory?: Maybe<string> /**  */
	dateFormat?: Maybe<string> /**  */
	dateFormatWithoutHour?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	incrementNextValue?: Maybe<number> /**  */
	incrementLength?: Maybe<number> /**  */
} 

/**  */
export type ServiceListResultOfNamingConvention = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NamingConvention>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetNamingConvention = {
	data?: Maybe<AssetNamingConvention> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type NetworkAdapter = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	adapterType?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	guid?: Maybe<string> /**  */
	index: number /**  */
	interfaceIndex: number /**  */
	macAddress?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	maxSpeed: number /**  */
	name?: Maybe<string> /**  */
	netConnectionId?: Maybe<string> /**  */
	netEnabled: boolean /**  */
	pnpDeviceId?: Maybe<string> /**  */
	powerManagementSupported: boolean /**  */
	productName?: Maybe<string> /**  */
	serviceName?: Maybe<string> /**  */
	speed: number /**  */
	networkAdapterConfiguration?: Maybe<NetworkAdapterConfiguration> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfNetworkAdapter = {
	data?: Maybe<NetworkAdapter> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfNetworkAdapter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NetworkAdapter>> /**  */
} 

/**  */
export type NetworkDrive = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	remotePath?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	letter?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfNetworkDrive = {
	data?: Maybe<NetworkDrive> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfNetworkDrive = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NetworkDrive>> /**  */
} 

/**  */
export type News = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	populations?: Maybe<ServiceListResultOfPopulation> /** Récupère les populations de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	lastUpdateBy?: Maybe<ServiceSingleResultOfUser> /**  */
	title?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	populationIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
} & IPopulation & ISecurityGroup & IRank

/**  */
export type Note = {
	contextNote?: Maybe<ServiceSingleResultOfString> /**  */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	operator?: Maybe<ServiceSingleResultOfUser> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	externalAppName?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	context?: Maybe<string> /**  */
	oldValue?: Maybe<any> /**  */
	newValue?: Maybe<any> /**  */
} & ITicketLink

/**  */
export type Notification = {
	id?: string /**  */
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	title?: Maybe<TranslatedField> /**  */
	text?: Maybe<TranslatedField> /**  */
	createdDate?: Maybe<Date> /**  */
	userId: string /**  */
	isRead: boolean /**  */
	urlKey: NotificationKey /**  */
	urlObjectId: string /**  */
} 

/**  */
export type ServiceListResultOfNotification = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Notification>> /**  */
} 

/**  */
export type NtLogEvent = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	category: number /**  */
	categorystring?: Maybe<string> /**  */
	computerName?: Maybe<string> /**  */
	data: Array<number> /**  */
	eventCode: number /**  */
	eventIdentifier: number /**  */
	eventType: EventType /**  */
	insertionStrings?: Array<Maybe<string>> /**  */
	logfile?: Maybe<string> /**  */
	message?: Maybe<string> /**  */
	recordNumber: number /**  */
	sourceName?: Maybe<string> /**  */
	timeGenerated?: Maybe<Date> /**  */
	timeWritten?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	user?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfNtLogEvent = {
	data?: Maybe<NtLogEvent> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfNtLogEvent = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NtLogEvent>> /**  */
} 

/**  */
export type ObjectClassMapping = {
	id?: string /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	ldapObjectClassMappings?: Array<Maybe<LdapObjectClassMapping>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfObjectClassMapping = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ObjectClassMapping>> /**  */
} 

/**  */
export type OdbcDriver = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	driver?: Maybe<string> /**  */
	apiLevel?: Maybe<string> /**  */
	connectFunctions?: Maybe<string> /**  */
	driverOdbcVer?: Maybe<string> /**  */
	fileExtns?: Maybe<string> /**  */
	fileUsage?: Maybe<string> /**  */
	setup?: Maybe<string> /**  */
	sqlLevel?: Maybe<string> /**  */
	usageCount: number /**  */
	regeditPath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfOdbcDriver = {
	data?: Maybe<OdbcDriver> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfOdbcDriver = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OdbcDriver>> /**  */
} 

/**  */
export type OneEventLogUser = {
	subId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
} 

/**  */
export type OneEventLog = {
	id?: string /**  */
	date?: Maybe<Date> /**  */
	type: OneEventLogAction /**  */
	data?: Maybe<any> /**  */
	oneEventLogUser?: Maybe<OneEventLogUser> /**  */
	entityId: string /**  */
	entityDefaultName?: Maybe<TranslatedField> /**  */
	entityType?: Maybe<string> /**  */
	isSystem: boolean /**  */
	isMigrating: boolean /**  */
	entityParentId?: Maybe<string> /**  */
	entityParentReference?: Maybe<any> /**  */
	applicationPodName?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
} 

/**  */
export type MailSlurpServiceSetting = {
	enableVerificationStatus: boolean /**  */
	blockService: boolean /**  */
	temporayBlockService?: Maybe<Date> /**  */
	temporayDelayBlockService: number /**  */
	preventDailyLimit: number /**  */
	preventTotalLimit: number /**  */
	rescheduleTime: number /**  */
	emailSetting?: Maybe<OneAlertEmailSetting> /**  */
} 

/**  */
export type OneAlertEmailSetting = {
	sendAlertEmail: boolean /**  */
	alertEmails?: Array<Maybe<string>> /**  */
	lastSend?: Maybe<Date> /**  */
} 

/**  */
export type ScheduleTaskSetting = {
	enable: boolean /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type OneSetting = {
	id?: string /**  */
	mailSlurpServiceSetting?: Maybe<MailSlurpServiceSetting> /**  */
	scheduleTaskSettings?: Array<Maybe<ScheduleTaskSetting>> /**  */
	grantAccess?: Array<Maybe<string>> /**  */
} 

/**  */
export type ServiceListResultOfOneSetting = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OneSetting>> /**  */
} 

/**  */
export type ServiceSingleResultOfOperatingSystem = {
	data?: Maybe<OperatingSystem> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfOperatingSystem = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OperatingSystem>> /**  */
} 

/**  */
export type OptionalFeature = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	installState: number /**  */
	description?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfOptionalFeature = {
	data?: Maybe<OptionalFeature> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfOptionalFeature = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OptionalFeature>> /**  */
} 

/**  */
export type OrderBill = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	billDate?: Maybe<Date> /**  */
	total: number /**  */
	fileId?: Maybe<string> /**  */
	billNumber?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfOrderBill = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OrderBill>> /**  */
} 

/**  */
export type Order = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	orderDate?: Maybe<Date> /**  */
	supplierId?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfOrder = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Order>> /**  */
} 

/**  */
export type OrganizationalUnitCategory = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfOrganizationalUnitCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OrganizationalUnitCategory>> /**  */
} 

/**  */
export type LicenseModel = {
	featureList?: Array<Maybe<ProductFeature>> /**  */
	managedAssetNumber: number /**  */
	remainingAssetNumber: number /**  */
	type: LicenseTypeEnum /**  */
	features?: Maybe<FeaturesModel> /**  */
	expireAt?: Maybe<Date> /**  */
	customer?: Maybe<CustomerModel> /**  */
	clientId: string /**  */
	id?: string /**  */
} 

/**  */
export type FeaturesModel = {
	assetUnlimited?: Maybe<boolean> /**  */
	assetNumber?: Maybe<number> /**  */
	assetAuditedUnlimited?: Maybe<boolean> /**  */
	assetAuditedNumber?: Maybe<number> /**  */
	managerNumber: number /**  */
	operatorNumber: number /**  */
	endUserNumber: number /**  */
	mailBoxNumber: number /**  */
	featureServiceDesk?: Maybe<boolean> /**  */
	featureServiceDeskExpireAt?: Maybe<Date> /**  */
	featureAssets?: Maybe<boolean> /**  */
	featureAssetsExpireAt?: Maybe<Date> /**  */
	featureContracts?: Maybe<boolean> /**  */
	featureContractsExpireAt?: Maybe<Date> /**  */
	featureFinancials?: Maybe<boolean> /**  */
	featureFinancialsExpireAt?: Maybe<Date> /**  */
	featureStocks?: Maybe<boolean> /**  */
	featureStocksExpireAt?: Maybe<Date> /**  */
	featureSuppliers?: Maybe<boolean> /**  */
	featureSuppliersExpireAt?: Maybe<Date> /**  */
	featureInventories?: Maybe<boolean> /**  */
	featureInventoriesExpireAt?: Maybe<Date> /**  */
	featureLoans?: Maybe<boolean> /**  */
	featureLoansExpireAt?: Maybe<Date> /**  */
	featureSoftware?: Maybe<boolean> /**  */
	featureSoftwareExpireAt?: Maybe<Date> /**  */
} 

/**  */
export type CustomerModel = {
	company?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type Address = {
	street?: Maybe<string> /**  */
	additional?: Maybe<string> /**  */
	city?: Maybe<string> /**  */
	province?: Maybe<string> /**  */
	country?: Maybe<string> /**  */
	zipCode?: Maybe<string> /**  */
	postalCode?: Maybe<string> /**  */
} 

/**  */
export type CommercialInformation = {
	users: number /**  */
	computers: number /**  */
} 

/**  */
export type OrganizationContact = {
	lastName?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	civility: Civility /**  */
} 

/**  */
export type LicenseAgreement = {
	accept: boolean /**  */
	acceptedDate?: Maybe<Date> /**  */
	acceptedSubId?: Maybe<string> /**  */
	acceptedUserName?: Maybe<string> /**  */
} 

/**  */
export type ThemeColor = {
	littleText?: Maybe<TranslatedField> /**  */
	boldTexte?: Maybe<TranslatedField> /**  */
	satisfactionLabel?: Maybe<TranslatedField> /**  */
	accessTicketsObserverLabel?: Maybe<TranslatedField> /**  */
	primaryColor?: Maybe<string> /**  */
	secondaryColor?: Maybe<string> /**  */
	headBandColor?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	timerRefresh: number /**  */
	timerRefreshEnable: boolean /**  */
	timerReminder: number /**  */
} 

/**  */
export type AreaListCustomization = {
	messageNumberEnable: boolean /**  */
	messageNumber: number /**  */
	articleKbNumberEnable: boolean /**  */
	articleKbNumber: number /**  */
	newsNumberEnable: boolean /**  */
	newsNumber: number /**  */
} 

/**  */
export type ServiceSingleResultOfContentCustomization = {
	data?: Maybe<ContentCustomization> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ContentCustomization = {
	helpMe?: Maybe<ThemeColor> /**  */
	helpMeAreaList?: Maybe<AreaListCustomization> /**  */
} 

/**  */
export type ValidationEntryClassification = {
	name?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfOneEventLog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OneEventLog>> /**  */
} 

/**  */
export type VerifaliaEmailCheck = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	expireUtc?: Maybe<Date> /**  */
	classification?: Maybe<ValidationEntryClassification> /**  */
	status: number /**  */
} 

/**  */
export type OsRecoveryConfiguration = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	autoReboot: boolean /**  */
	caption?: Maybe<string> /**  */
	debugFilePath?: Maybe<string> /**  */
	debugInfoType: number /**  */
	description?: Maybe<string> /**  */
	expandedDebugFilePath?: Maybe<string> /**  */
	expandedMiniDumpDirectory?: Maybe<string> /**  */
	kernelDumpOnly: boolean /**  */
	miniDumpDirectory?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	overwriteExistingDebugFile: boolean /**  */
	sendAdminAlert: boolean /**  */
	settingId?: Maybe<string> /**  */
	writeDebugInfo: boolean /**  */
	writeToSystemLog: boolean /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfOsRecoveryConfiguration = {
	data?: Maybe<OsRecoveryConfiguration> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfOsRecoveryConfiguration = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OsRecoveryConfiguration>> /**  */
} 

/**  */
export type OutStockMovement = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	storageUnit?: Maybe<ServiceSingleResultOfLocation> /**  */
	outBy?: Maybe<ServiceSingleResultOfUser> /**  */
	reason?: Maybe<ServiceSingleResultOfStockOutReason> /**  */
	recipient?: Maybe<ServiceSingleResultOfUser> /**  */
	deliveryLocation?: Maybe<ServiceSingleResultOfLocation> /**  */
	deliveryOrganizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	name?: Maybe<string> /**  */
	deliveryLocationId?: Maybe<string> /**  */
	destinationOrganizationalUnitId?: Maybe<string> /**  */
	recipientId?: Maybe<string> /**  */
	carts?: Array<Maybe<OutCart>> /**  */
	date?: Maybe<Date> /**  */
	outById?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	storageUnitId: string /**  */
	storageUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	assetIds: Array<string> /**  */
	hasConsumable: boolean /**  */
	hasAsset: boolean /**  */
} 

/**  */
export type OutStock = {
	deliveryId?: Maybe<string> /**  */
	outStockMovementId?: Maybe<string> /**  */
	outStockMovementName?: Maybe<string> /**  */
	deliveryName?: Maybe<string> /**  */
	recipientId?: Maybe<string> /**  */
	carts?: Array<Maybe<OutCart>> /**  */
	reasonId?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
	date: Date /**  */
	outById?: Maybe<string> /**  */
	outBy?: Maybe<User> /**  */
	storageUnitId: string /**  */
	name?: Maybe<string> /**  */
	id?: string /**  */
	externalObjectId?: Maybe<string> /**  */
} 

/**  */
export type OutCart = {
	id?: string /**  */
	reasonId?: Maybe<string> /**  */
	entryName?: Maybe<string> /**  */
	assetCategory?: Maybe<AssetCategory> /**  */
	assetManufacturer?: Maybe<ModelManufacturer> /**  */
	assetModel?: Maybe<ModelManufacturer> /**  */
	availableAmount: number /**  */
	oldStock?: Maybe<number> /**  */
	correctionStock?: Maybe<number> /**  */
	movedAmount?: Maybe<number> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<Location> /**  */
	storageUnitEndId?: Maybe<string> /**  */
	storageUnitEnd?: Maybe<Location> /**  */
	entryId: string /**  */
	cartId?: Maybe<string> /**  */
	amount: number /**  */
	category: AssetModelCategory /**  */
	oldCarts: number /**  */
} 

/**  */
export type User = {
	subordinatesLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	roleNames?: Array<Maybe<string>> /**  */
	roleTypes?: Array<Maybe<string>> /**  */
	listOperatorTeams?: Array<Maybe<OperatorTeam>> /**  */
	memberOfLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	memberOfAzure?: Maybe<ServiceListResultOfGroupAzureAd> /**  */
	findCollaboratorsLdap?: Maybe<ServiceListResultOfDistinguishedNameModel> /**  */
	isOperator: boolean /**  */
	isApproved: boolean /**  */
	displayOperatorFilter?: Maybe<string> /**  */
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /**  */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
	budgets?: Maybe<ServiceListResultOfBudget> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	ticketAffectedRuleUserReferents?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketAffectedRuleUserAffecteds?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	ticketUserAffecteds?: Maybe<ServiceListResultOfTicket> /**  */
	ticketUserSatisfactions?: Maybe<ServiceListResultOfTicket> /**  */
	ticketReferents?: Maybe<ServiceListResultOfTicket> /**  */
	ticketAffecteds?: Maybe<ServiceListResultOfTicket> /**  */
	ticketUserMakeRequests?: Maybe<ServiceListResultOfTicket> /**  */
	reminders?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModelReferents?: Maybe<ServiceListResultOfIncidentModel> /**  */
	incidentModelAffecteds?: Maybe<ServiceListResultOfIncidentModel> /**  */
	lastUpdateStatusWorkflows?: Maybe<ServiceListResultOfStatusWorkflow> /**  */
	lastUpdateArticleKnowledges?: Maybe<ServiceListResultOfArticleKnowledge> /**  */
	tickets?: Maybe<ServiceListResultOfTicket> /**  */
	inheritLocationCharters?: Maybe<ServiceListResultOfInheritLocationCharter> /**  */
	operatorTeams?: Maybe<ServiceListResultOfOperatorTeam> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	privilege?: Maybe<ServiceSingleResultOfPrivilege> /**  */
	mainOperatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	userManager?: Maybe<ServiceSingleResultOfUser> /**  */
	collaborators?: Maybe<ServiceListResultOfUser> /**  */
	supplier?: Maybe<ServiceSingleResultOfSupplier> /**  */
	scanByLdap?: Maybe<ServiceSingleResultOfScanConfiguration> /**  */
	managerRef?: Maybe<ServiceSingleResultOfUser> /**  */
	subordinates?: Maybe<ServiceListResultOfUser> /**  */
	inviteBy?: Maybe<ServiceSingleResultOfUser> /**  */
	invitations?: Maybe<ServiceListResultOfUser> /**  */
	revokeBy?: Maybe<ServiceSingleResultOfUser> /**  */
	revocations?: Maybe<ServiceListResultOfUser> /**  */
	mainAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	calendar?: Maybe<ServiceSingleResultOfCalendarConnector> /**  */
	userPrivilege?: Maybe<ServiceSingleResultOfUserPrivilege> /**  */
	businessTime?: Maybe<ServiceSingleResultOfBusinessTime> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	ownerAssets?: Maybe<ServiceListResultOfAsset> /**  */
	operatorAffectedEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	operatorReferentEmailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	softwarePackages?: Maybe<ServiceListResultOfSoftwarePackage> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	articleKnowledgeNotes?: Maybe<ServiceListResultOfArticleKnowledgeNote> /**  */
	outByConsumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	recipientConsumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	correctionStockMovements?: Maybe<ServiceListResultOfCorrectionStockMovement> /**  */
	dashboards?: Maybe<ServiceListResultOfDashboard> /**  */
	formDesigners?: Maybe<ServiceListResultOfFormDesigner> /**  */
	interventionModels?: Maybe<ServiceListResultOfInterventionModel> /**  */
	ldapAnalyses?: Maybe<ServiceListResultOfLdapAnalyse> /**  */
	loanManagers?: Maybe<ServiceListResultOfLoan> /**  */
	loanUsers?: Maybe<ServiceListResultOfLoan> /**  */
	locationCharters?: Maybe<ServiceListResultOfLocationCharter> /**  */
	modelRulesDesigners?: Maybe<ServiceListResultOfModelRulesDesigner> /**  */
	lastUpdateNews?: Maybe<ServiceListResultOfNews> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	notifications?: Maybe<ServiceListResultOfNotification> /**  */
	outStockMovements?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	outStockMovementsRecipients?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	softwareReferentLinks?: Maybe<ServiceListResultOfSoftwareReferentLink> /**  */
	stockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	lastUpdateTaskStatusWorkflows?: Maybe<ServiceListResultOfTaskStatusWorkflow> /**  */
	ticketBacklogs?: Maybe<ServiceListResultOfTicketBacklog> /**  */
	taskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	lastUpdateWorkflows?: Maybe<ServiceListResultOfWorkflow> /**  */
	subId?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	userAccountControl: number /**  */
	userAccountDisabled: boolean /**  */
	userAccountExpires?: Maybe<Date> /**  */
	initial?: Maybe<string> /**  */
	registrationNumber?: Maybe<string> /**  */
	vip: boolean /**  */
	helpDeskActivated: boolean /**  */
	function?: Maybe<string> /**  */
	instantMessaging?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	secretary?: Maybe<string> /**  */
	hiringDate?: Maybe<Date> /**  */
	exitDate?: Maybe<Date> /**  */
	lastLogOn?: Maybe<Date> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
	lastLogOff?: Maybe<Date> /**  */
	lastConnected?: Maybe<Date> /**  */
	accessFailedCount: number /**  */
	homeDrive?: Maybe<string> /**  */
	homeDirectory?: Maybe<string> /**  */
	logonCount: number /**  */
	profilePath?: Maybe<string> /**  */
	scriptPath?: Maybe<string> /**  */
	logOnTo?: Maybe<string> /**  */
	allowDialIn: boolean /**  */
	officeName?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	settings?: Maybe<Settings> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userPasswords?: Array<Maybe<UserPassword>> /**  */
	operatorTeamIds: Array<string> /**  */
	mainOperatorTeamId?: Maybe<string> /**  */
	loans?: Array<Maybe<Loan>> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations?: Array<Maybe<UserHabilitation>> /**  */
	phones?: Array<Maybe<Phone>> /**  */
	address?: Maybe<Address> /**  */
	webSites?: Array<Maybe<string>> /**  */
	locations?: Array<Maybe<Location>> /**  */
	stockManagers?: Array<Maybe<Location>> /**  */
	locationId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	manager?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	containerDistinguishedName?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	lastImportAzureAd?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	principalPhone?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	principalHomePhone?: Maybe<string> /**  */
	principalIpPhone?: Maybe<string> /**  */
	principalMobile?: Maybe<string> /**  */
	principalPager?: Maybe<string> /**  */
	memberOf?: Array<Maybe<string>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAd>> /**  */
	assetIds: Array<string> /**  */
	invitation?: Maybe<Invitation> /**  */
	category: UserCategory /**  */
	userAffectedRule?: Maybe<UserAffectedRule> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	forceLdapImport: boolean /**  */
	domain?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	memberOfSecurityGroupIds?: Array<Maybe<SecurityGroupObject>> /**  */
	memberOfPopulationIds?: Array<Maybe<SecurityGroupObject>> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inviteById?: Maybe<string> /**  */
	revokeById?: Maybe<string> /**  */
	costHours: number /**  */
	supportLevelOperator: number /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	mainAssetId?: Maybe<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	inheritLocationCharterLocation?: Maybe<InheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<InheritLocationCharter> /**  */
	entityLocationCharter?: Maybe<EntityLocationCharter> /**  */
	privilegeId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	locationCharterAlreadyApply: boolean /**  */
	securityGroupAlreadyApply: boolean /**  */
	populationAlreadyApply: boolean /**  */
	calendarId?: Maybe<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	operatorIncidentRuleId?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	operatorRequestRuleId?: Maybe<string> /**  */
	operatorProblemRuleId?: Maybe<string> /**  */
	serviceDeskActivated: boolean /**  */
	dashboardIds: Array<string> /**  */
	allowEditOrganization: boolean /**  */
	recipentSystemNotification: boolean /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	userContractType?: Maybe<string> /**  */
	operatorIncidentListDesignerId?: Maybe<string> /**  */
	operatorRequestListDesignerId?: Maybe<string> /**  */
	operatorProblemListDesignerId?: Maybe<string> /**  */
} & IAccount & IPermissible & ILdapAttribute & IDistinguishedName & ILocalizable & IOrganizable & ISecurityGroup & IUserAzureAD

/**  */
export type ServiceListResultOfOutStock = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OutStock>> /**  */
} 

/**  */
export type ServiceListResultOfPackage = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Package>> /**  */
} 

/**  */
export type ServiceListResultOfCampaign = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Campaign>> /**  */
} 

/**  */
export type PageCount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	color: number /**  */
	black: number /**  */
	twoColor: number /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPageCount = {
	data?: Maybe<PageCount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPageCount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PageCount>> /**  */
} 

/**  */
export type PageFile = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	freeSpace: number /**  */
	initialSize: number /**  */
	inUseCount: number /**  */
	manufacturer?: Maybe<string> /**  */
	maximumSize: number /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPageFile = {
	data?: Maybe<PageFile> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPageFile = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PageFile>> /**  */
} 

/**  */
export type ParallelPort = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfParallelPort = {
	data?: Maybe<ParallelPort> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfParallelPort = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ParallelPort>> /**  */
} 

/**  */
export type Pat = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	token?: Maybe<string> /**  */
	tokenCache?: Maybe<string> /**  */
	expirationDate?: Maybe<Date> /**  */
	activationDate?: Maybe<Date> /**  */
	permissions?: Array<Maybe<string>> /**  */
	clientIds?: Array<Maybe<string>> /**  */
	fullAccess: boolean /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	mustBeCache: boolean /**  */
	lastTokenUpdated?: Maybe<Date> /**  */
	lastTokenUpdatedBy?: Maybe<string> /**  */
	lastTokenExpiration?: Maybe<Date> /**  */
	tokenCount: number /**  */
} & IPermissible & ISystem

/**  */
export type ServiceListResultOfPat = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Pat>> /**  */
} 

/**  */
export type ServiceListResultOfPermissionScope = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PermissionScope>> /**  */
} 

/**  */
export type PcmciaController = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPcmciaController = {
	data?: Maybe<PcmciaController> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPcmciaController = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PcmciaController>> /**  */
} 

/**  */
export type PhysicalMemoryArray = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	location: number /**  */
	maxCapacity: number /**  */
	memoryDevices: number /**  */
	memoryErrorCorrection: number /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	use: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPhysicalMemoryArray = {
	data?: Maybe<PhysicalMemoryArray> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPhysicalMemoryArray = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PhysicalMemoryArray>> /**  */
} 

/**  */
export type PhysicalMemory = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	bankLabel?: Maybe<string> /**  */
	capacity: number /**  */
	caption?: Maybe<string> /**  */
	dataWidth: number /**  */
	description?: Maybe<string> /**  */
	deviceLocator?: Maybe<string> /**  */
	formFactor: FormFactor /**  */
	hotSwappable: boolean /**  */
	interleaveDataDepth: number /**  */
	interleavePosition: number /**  */
	memoryType: MemoryType /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partNumber?: Maybe<string> /**  */
	positionInRow: number /**  */
	poweredOn: boolean /**  */
	removable: boolean /**  */
	replaceable: boolean /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	speed: number /**  */
	tag?: Maybe<string> /**  */
	totalWidth: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPhysicalMemory = {
	data?: Maybe<PhysicalMemory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPhysicalMemory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PhysicalMemory>> /**  */
} 

/**  */
export type PnpEntityBase = {
	id?: string /**  */
	className?: Maybe<string> /**  */
	classGuid?: Maybe<string> /**  */
	base64Ico?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfPnpEntityBase = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PnpEntityBase>> /**  */
} 

/**  */
export type PnpEntity = {
	ico?: Maybe<string> /**  */
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	classGuid?: Maybe<string> /**  */
	pnpEntityString?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	hardwareId?: Array<Maybe<string>> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	service?: Maybe<string> /**  */
	configManagerErrorCode: number /**  */
	configManagerErrorCodeString?: Maybe<string> /**  */
	errorDescription?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPnpEntity = {
	data?: Maybe<PnpEntity> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPnpEntity = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PnpEntity>> /**  */
} 

/**  */
export type PnpSignedDriver = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	classGuid?: Maybe<string> /**  */
	compatId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceClass?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	deviceName?: Maybe<string> /**  */
	devLoader?: Maybe<string> /**  */
	driverDate?: Maybe<Date> /**  */
	driverName?: Maybe<string> /**  */
	driverVersion?: Maybe<string> /**  */
	friendlyName?: Maybe<string> /**  */
	hardWareId?: Maybe<string> /**  */
	infName?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
	isSigned: boolean /**  */
	location?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pdo?: Maybe<string> /**  */
	driverProviderName?: Maybe<string> /**  */
	signer?: Maybe<string> /**  */
	started: boolean /**  */
	startMode?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPnpSignedDriver = {
	data?: Maybe<PnpSignedDriver> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPnpSignedDriver = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PnpSignedDriver>> /**  */
} 

/**  */
export type PnpVendor = {
	id?: string /**  */
	manufacturer?: Maybe<string> /**  */
	pnpId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfPnpVendor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PnpVendor>> /**  */
} 

/**  */
export type PointingDevice = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	hardwareType?: Maybe<string> /**  */
	infFileName?: Maybe<string> /**  */
	infSection?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	numberOfButtons: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPointingDevice = {
	data?: Maybe<PointingDevice> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPointingDevice = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PointingDevice>> /**  */
} 

/**  */
export type Population = {
	totalMembers: number /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	news?: Maybe<ServiceListResultOfNews> /**  */
	warningMessages?: Maybe<ServiceListResultOfWarningMessage> /**  */
	id?: string /**  */
	ticketAffectedRules?: Maybe<ServiceListResultOfTicketAffectedRule> /**  */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	isManager: boolean /**  */
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	ticketAffectedRuleIds: Array<string> /**  */
	memberDistinguishedNameGroups?: Array<Maybe<string>> /**  */
	memberAzureGroupObjectIds?: Array<Maybe<string>> /**  */
	allowedTicketsCategories?: Array<Maybe<AllowedEntity>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	isManual: boolean /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible: boolean /**  */
	ticketCategoryAllowed: boolean /**  */
	locationVisible: boolean /**  */
	locationAllowed: boolean /**  */
	allowedLocations?: Array<Maybe<AllowedEntity>> /**  */
	organizationalUnitVisible: boolean /**  */
	organizationalUnitAllowed: boolean /**  */
	allowedOrganizationalUnits?: Array<Maybe<AllowedEntity>> /**  */
} & ISystem & ISecurityPopulationBase & IAllowedTicketCategory & IUserPropertyPopulation & IAllowedLocation & IAllowedOrganizationalUnit

/**  */
export type PortableBattery = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	batteryRechargeTime: number /**  */
	batteryStatus: BatteryStatus /**  */
	capacityMultiplier: number /**  */
	caption?: Maybe<string> /**  */
	chemistry: Chemistry /**  */
	description?: Maybe<string> /**  */
	designCapacity: number /**  */
	designVoltage: number /**  */
	deviceId?: Maybe<string> /**  */
	estimatedChargeRemaining: number /**  */
	estimatedRunTime: number /**  */
	expectedBatteryLife: number /**  */
	expectedLife: number /**  */
	fullChargeCapacity: number /**  */
	location?: Maybe<string> /**  */
	manufactureDate?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	maxBatteryError: number /**  */
	maxRechargeTime: number /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	smartBatteryVersion?: Maybe<string> /**  */
	timeOnBattery: number /**  */
	timeToFullCharge: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPortableBattery = {
	data?: Maybe<PortableBattery> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPortableBattery = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PortableBattery>> /**  */
} 

/**  */
export type PortConnector = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	externalReferenceDesignator?: Maybe<string> /**  */
	internalReferenceDesignator?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	portType: number /**  */
	tag?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPortConnector = {
	data?: Maybe<PortConnector> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPortConnector = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PortConnector>> /**  */
} 

/**  */
export type PortOpen = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	portName?: Maybe<string> /**  */
	portNumber: number /**  */
	open: boolean /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPortOpen = {
	data?: Maybe<PortOpen> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPortOpen = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PortOpen>> /**  */
} 

/**  */
export type PotsModem = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPotsModem = {
	data?: Maybe<PotsModem> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPotsModem = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PotsModem>> /**  */
} 

/**  */
export type ServiceSingleResultOfPrinterConsumable = {
	data?: Maybe<PrinterConsumable> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPrinterConsumable = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PrinterConsumable>> /**  */
} 

/**  */
export type Printer = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	action: number /**  */
	attributes: number /**  */
	dataType?: Maybe<string> /**  */
	objectGuid?: Maybe<string> /**  */
	printProcessor?: Maybe<string> /**  */
	printerDriver?: Maybe<string> /**  */
	priority: number /**  */
	separatorFile?: Maybe<string> /**  */
	shareName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	status: number /**  */
	regeditPath?: Maybe<string> /**  */
	isNetWork: boolean /**  */
	isDefault: boolean /**  */
	ipAddress?: Maybe<string> /**  */
	deviceInstanceId?: Maybe<string> /**  */
	hardwareId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	printerName?: Maybe<string> /**  */
	printShareName?: Maybe<string> /**  */
	serverName?: Maybe<string> /**  */
	shortServerName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	driverName?: Maybe<string> /**  */
	portName?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfPrinter = {
	data?: Maybe<Printer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfPrinter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Printer>> /**  */
} 

/**  */
export type Privilege = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	operators?: Maybe<ServiceListResultOfUser> /**  */
	key?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	isDefault: boolean /**  */
	defaultName?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	ticketEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	taskEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	actionEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	authEditEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	notificationEntries?: Array<Maybe<PrivilegeEntry>> /**  */
} & ISystem

/**  */
export type PrivilegeEntry = {
	title?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	enable: boolean /**  */
} 

/**  */
export type ServiceListResultOfPrivilege = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Privilege>> /**  */
} 

/**  */
export type ServiceListResultOfIncident = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Incident>> /**  */
} 

/**  */
export type ServiceListResultOfProblem = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Problem>> /**  */
} 

/**  */
export type Processor = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	addressWidth: number /**  */
	architecture: Architecture /**  */
	availability: Availability /**  */
	caption?: Maybe<string> /**  */
	configManagerErrorCode: number /**  */
	configManagerUserConfig: boolean /**  */
	cpuStatus: number /**  */
	currentClockSpeed: number /**  */
	currentVoltage: number /**  */
	dataWidth: number /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	errorCleared: boolean /**  */
	errorDescription?: Maybe<string> /**  */
	extClock: number /**  */
	family: number /**  */
	l2CacheSize: number /**  */
	l2CacheSpeed: number /**  */
	l3CacheSize: number /**  */
	l3CacheSpeed: number /**  */
	lastErrorCode: number /**  */
	level: number /**  */
	loadPercentage: number /**  */
	manufacturer?: Maybe<string> /**  */
	maxClockSpeed: number /**  */
	name?: Maybe<string> /**  */
	numberOfCores: number /**  */
	numberOfLogicalProcessors: number /**  */
	otherFamilyDescription?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	powerManagementSupported: boolean /**  */
	processorId?: Maybe<string> /**  */
	processorType: number /**  */
	revision: number /**  */
	role?: Maybe<string> /**  */
	socketDesignation?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	statusInfo: number /**  */
	stepping?: Maybe<string> /**  */
	uniqueId?: Maybe<string> /**  */
	upgradeMethod: number /**  */
	version?: Maybe<string> /**  */
	voltageCaps: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfProcessor = {
	data?: Maybe<Processor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfProcessor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Processor>> /**  */
} 

/**  */
export type Process = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	handle?: Maybe<string> /**  */
	processId: number /**  */
	caption?: Maybe<string> /**  */
	commandLine?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	executablePath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	osCreationClassName?: Maybe<string> /**  */
	osName?: Maybe<string> /**  */
	virtualSize: number /**  */
	workingSetSize: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfProcess = {
	data?: Maybe<Process> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfProcess = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Process>> /**  */
} 

/**  */
export type Program = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	parentDisplayName?: Maybe<string> /**  */
	contact?: Maybe<string> /**  */
	estimatedSize: number /**  */
	installLocation?: Maybe<string> /**  */
	installSource?: Maybe<string> /**  */
	helpLink?: Maybe<string> /**  */
	language: number /**  */
	productId?: Maybe<string> /**  */
	uninstallString?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	publisher?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
} & IAssetProperty & ISoftwareProperty

/**  */
export type ServiceSingleResultOfProgram = {
	data?: Maybe<Program> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfProgram = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Program>> /**  */
} 

/**  */
export type QueryBuilderAppointment = {
	id?: string /**  */
	allDay: boolean /**  */
	disabled: boolean /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	queryBuilderIds: Array<string> /**  */
	queryBuilderAppointmentTaskId?: Maybe<string> /**  */
} & IAppointment

/**  */
export type QueryBuilderAppointmentTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	queryBuilderIds: Array<string> /**  */
	timeZone?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfQueryBuilderAppointmentTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<QueryBuilderAppointmentTask>> /**  */
} 

/**  */
export type QueryBuilder = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	emailPrototypes?: Maybe<ServiceListResultOfEmailPrototype> /**  */
	sftpConnectors?: Maybe<ServiceListResultOfSftpConnector> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	expression?: Maybe<any> /**  */
	columns?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	displayRequestToJob: boolean /**  */
	typeTranslate?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	sftpConnectorIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup & IFilterType

/**  */
export type ServiceListResultOfQueryBuilderAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<QueryBuilderAppointment>> /**  */
} 

/**  */
export type QuickFixEngineering = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	fixComments?: Maybe<string> /**  */
	hotFixId?: Maybe<string> /**  */
	installedBy?: Maybe<string> /**  */
	installedOn?: Maybe<string> /**  */
	installedDate?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	servicePackInEffect?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
} & IAssetProperty & ISoftwareProperty

/**  */
export type ServiceSingleResultOfQuickFixEngineering = {
	data?: Maybe<QuickFixEngineering> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfQuickFixEngineering = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<QuickFixEngineering>> /**  */
} 

/**  */
export type ServiceSingleResultOfRegistry = {
	data?: Maybe<IRegistry> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type IRegistry = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfRegistry = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IRegistry>> /**  */
} 

/**  */
export type ReportCache = {
	id?: string /**  */
	cacheId?: Maybe<string> /**  */
	number: number /**  */
	pageType: number /**  */
	content: Array<number> /**  */
} 

/**  */
export type ServiceListResultOfReportCache = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ReportCache>> /**  */
} 

/**  */
export type Report = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	file?: Maybe<ServiceSingleResultOfFileModel> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	fileId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfReport = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Report>> /**  */
} 

/**  */
export type Request = {
	isFavorite: boolean /** Permet de savoir si le ticket est un favoris. */
	isReminderOpen: boolean /** Permet de savoir si le reminder est disponible. */
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	concernedProject?: Maybe<ServiceSingleResultOfConcernedProject> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	channel?: Maybe<ServiceSingleResultOfChannel> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	ticketStatusReasons?: Maybe<ServiceSingleResultOfTicketStatusReason> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	sla?: Maybe<ServiceSingleResultOfServiceLevelAgreement> /**  */
	mainImpactedAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	userAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	userMakeRequest?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	parent?: Maybe<ServiceSingleResultOfTicket> /**  */
	children?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	privateResolutionArticle?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<Reminder> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfaction> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	privateComment?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
} & ITicket

/**  */
export type ServiceListResultOfOperatorLevel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OperatorLevel>> /**  */
} 

/**  */
export type ServiceListResultOfTicketLogEvents = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketLogEvents>> /**  */
} 

/**  */
export type ServiceListResultOfRequest = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Request>> /**  */
} 

/**  */
export type ResolutionModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	resolutionCategories?: Maybe<ServiceListResultOfResolutionCategory> /**  */
	name?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	type?: Array<Maybe<string>> /**  */
	resolutionCategoryIds: Array<string> /**  */
	ticketCategoriesIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
} & ISecurityGroup

/**  */
export type UserAzureAD = {
	userAccountDisabled: boolean /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	officeName?: Maybe<string> /**  */
	address?: Maybe<Address> /**  */
	principalMobile?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	phones?: Array<Maybe<Phone>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAd>> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
} & IUserAzureAD

/**  */
export type ResultScanAzureAd = {
	id?: string /**  */
	scanByAzureAdId: string /**  */
	userAzureAd?: Maybe<UserAzureAD> /**  */
} 

/**  */
export type ServiceListResultOfResultScanAzureAd = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ResultScanAzureAd>> /**  */
} 

/**  */
export type RoleAccount = {
	id?: string /**  */
	roleId: string /**  */
	accountId: string /**  */
} 

/**  */
export type ServiceListResultOfRoleAccount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<RoleAccount>> /**  */
} 

/**  */
export type Role = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScope>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	hidden: boolean /**  */
	isSystem: boolean /**  */
	type?: Array<Maybe<string>> /**  */
} & IAccount & IPermissible & ISystem

/**  */
export type ServiceListResultOfAccount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Account>> /**  */
} 

/**  */
export type ServiceListResultOfRole = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Role>> /**  */
} 

/**  */
export type SatisfactionSetting = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	rating: number /**  */
	commentaryRequired: boolean /**  */
} 

/**  */
export type ServiceListResultOfSatisfactionSetting = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SatisfactionSetting>> /**  */
} 

/**  */
export type ScanByAddressIPRange = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	addressIpStart?: Maybe<string> /**  */
	addressIpEnd?: Maybe<string> /**  */
} & IScanConfiguration & ISystem

/**  */
export type ServiceListResultOfScanByAddressIPRange = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByAddressIPRange>> /**  */
} 

/**  */
export type ScanByAzureAdAppointment = {
	id?: string /**  */
	allDay: boolean /**  */
	disabled: boolean /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	scanConfigurationIds: Array<string> /**  */
	scanByAzureAdAppointmentTaskId?: Maybe<string> /**  */
} & IAppointment

/**  */
export type ScanByAzureAdAppointmentTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	scanConfigurationIds: Array<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfScanByAzureAdAppointmentTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByAzureAdAppointmentTask>> /**  */
} 

/**  */
export type ScanByAzureAd = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	applicationAzureAd?: Maybe<ServiceSingleResultOfApplicationAzureAd> /**  */
	applicationAzureAdId: string /**  */
	importDisabledUser: boolean /**  */
	importMember: boolean /**  */
	importGuest: boolean /**  */
	importNoType: boolean /**  */
	importGroup: boolean /**  */
	groupObjectIds?: Array<Maybe<string>> /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	recursiveGroup: boolean /**  */
} & IScanConfiguration & ISystem

/**  */
export type ServiceListResultOfGroupAzureAd = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<GroupAzureAd>> /**  */
} 

/**  */
export type ServiceListResultOfScanByAzureAdAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByAzureAdAppointment>> /**  */
} 

/**  */
export type ServiceListResultOfScanByAzureAd = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByAzureAd>> /**  */
} 

/**  */
export type ScanByAzureAdTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	scanByAzureAdAppointmentId?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfScanByAzureAdTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByAzureAdTask>> /**  */
} 

/**  */
export type ScanByHost = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	hostName?: Array<Maybe<string>> /**  */
} & IScanConfiguration & ISystem

/**  */
export type ServiceListResultOfScanByHost = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByHost>> /**  */
} 

/**  */
export type ScanByLdap = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	lastHistoryLdap?: Maybe<ServiceSingleResultOfLdapHistory> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	ldapCredential?: Maybe<ServiceSingleResultOfNetworkCredential> /**  */
	objectClassMapping?: Maybe<ServiceSingleResultOfObjectClassMapping> /**  */
	fieldMapping?: Maybe<ServiceSingleResultOfFieldMapping> /**  */
	domain?: Maybe<string> /**  */
	credentialId?: Maybe<string> /**  */
	useSsl: boolean /**  */
	usePort: number /**  */
	protocolVersion: number /**  */
	connectionTimeoutIn: number /**  */
	ldapAuthType: LdapAuthType /**  */
	distinguishedName?: Maybe<string> /**  */
	importGroup: boolean /**  */
	importUser: boolean /**  */
	importDisabledUser: boolean /**  */
	importRecursiveGroup: boolean /**  */
	importComputer: boolean /**  */
	importLdapStruct: boolean /**  */
	disableUsers: boolean /**  */
	importOrganizationalUnit: ImportOrganizationalUnit /**  */
	forceSearchFilter: boolean /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	ldapDistinguishedNames?: Array<Maybe<string>> /**  */
} & IScanConfiguration & ISystem

/**  */
export type ScanByLocalAgent = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	countError: number /**  */
	countSuccess: number /**  */
	countAgent: number /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	clarilogLocalAgents?: Maybe<ServiceListResultOfAsset> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
} & IScanConfiguration & ISystem

/**  */
export type ServiceListResultOfScanByLocalAgent = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByLocalAgent>> /**  */
} 

/**  */
export type ScanByWorkgroup = {
	scanMethods?: Maybe<ServiceListResultOfScanMethod> /**  */
	appointments?: Maybe<ServiceListResultOfScanConfigurationAppointment> /**  */
	nextTask?: Maybe<IAppointment> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	networkCredentials?: Maybe<ServiceListResultOfNetworkCredential> /**  */
	scanExcludes?: Maybe<ServiceListResultOfScanExclude> /**  */
	scanDataFiles?: Maybe<ServiceListResultOfScanDataFile> /**  */
	scanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey> /**  */
	scanImportOptions?: Maybe<ServiceListResultOfScanImportOption> /**  */
	scanMethodProfil?: Maybe<ServiceSingleResultOfScanMethodProfil> /**  */
	clarilogServer?: Maybe<ServiceSingleResultOfClarilogServer> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	ldapHistories?: Maybe<ServiceListResultOfLdapHistory> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOption> /**  */
	powerControl?: Maybe<PowerControl> /**  */
	wol?: Maybe<Wol> /**  */
	shutDown?: Maybe<ShutDown> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workgroupName?: Maybe<string> /**  */
} & IScanConfiguration & ISystem

/**  */
export type ServiceListResultOfScanByWorkgroup = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByWorkgroup>> /**  */
} 

/**  */
export type ScanConfigurationAppointment = {
	id?: string /**  */
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
} & IAppointment

/**  */
export type ServiceListResultOfScanConfigurationAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanConfigurationAppointment>> /**  */
} 

/**  */
export type ServiceListResultOfNetworkCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<INetworkCredential>> /**  */
} 

/**  */
export type ServiceListResultOfLdapHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LdapHistory>> /**  */
} 

/**  */
export type ServiceListResultOfDataFile = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DataFile>> /**  */
} 

/**  */
export type ScanExclude = {
	id?: string /**  */
	scanExcludeOperator: ScanExcludeOperator /**  */
	scanExcludeCategory: ScanExcludeCategory /**  */
	name?: Maybe<string> /**  */
	value?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	scanConfigurationIds: Array<string> /**  */
} 

/**  */
export type ServiceListResultOfScanExclude = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanExclude>> /**  */
} 

/**  */
export type ServiceListResultOfScanImportOption = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanImportOption>> /**  */
} 

/**  */
export type ScanMethodProfil = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	name?: Maybe<TranslatedField> /**  */
	scanMethodIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfScanMethodProfil = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanMethodProfil>> /**  */
} 

/**  */
export type ScanMethod = {
	id?: string /**  */
	methodName?: Maybe<string> /**  */
	fast: boolean /**  */
	normal: boolean /**  */
	enabled: boolean /**  */
} 

/**  */
export type ServiceListResultOfScanMethod = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanMethod>> /**  */
} 

/**  */
export type ScannerCount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfScannerCount = {
	data?: Maybe<ScannerCount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfScannerCount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScannerCount>> /**  */
} 

/**  */
export type ServiceListResultOfScanRegistryKey = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanRegistryKey>> /**  */
} 

/**  */
export type ScanRegistry = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	scanRegistryKey?: Maybe<ServiceSingleResultOfScanRegistryKey> /**  */
	scanRegistryKeyId?: Maybe<string> /**  */
} & IAssetProperty & IRegistry

/**  */
export type ServiceSingleResultOfScanRegistry = {
	data?: Maybe<ScanRegistry> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfScanRegistry = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanRegistry>> /**  */
} 

/**  */
export type ServiceSingleResultOfScheduleTask = {
	data?: Maybe<IScheduleTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type IScheduleTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
} 

/**  */
export type ServiceListResultOfScheduleTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IScheduleTask>> /**  */
} 

/**  */
export type ScsiController = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driverName?: Maybe<string> /**  */
	index: number /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfScsiController = {
	data?: Maybe<ScsiController> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfScsiController = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScsiController>> /**  */
} 

/**  */
export type SecurityGroup = {
	totalUser: number /**  */
	totalUserWithRole: number /**  */
	readOnlyTicketCategory: boolean /**  */
	alarms?: Maybe<ServiceListResultOfAlarm> /**  */
	articleKnowledges?: Maybe<ServiceListResultOfArticleKnowledge> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	assetCategories?: Maybe<ServiceListResultOfAssetCategory> /**  */
	commands?: Maybe<ServiceListResultOfCommand> /**  */
	concernedProjects?: Maybe<ServiceListResultOfConcernedProject> /**  */
	calendarConnectors?: Maybe<ServiceListResultOfCalendarConnector> /**  */
	exchangeConnectors?: Maybe<ServiceListResultOfExchangeConnector> /**  */
	contracts?: Maybe<ServiceListResultOfContract> /**  */
	emailConnectors?: Maybe<ServiceListResultOfEmailConnector> /**  */
	emailPrototypes?: Maybe<ServiceListResultOfEmailPrototype> /**  */
	budgets?: Maybe<ServiceListResultOfBudget> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	locations?: Maybe<ServiceListResultOfLocation> /**  */
	organizationalUnits?: Maybe<ServiceListResultOfOrganizationalUnit> /**  */
	queryBuilders?: Maybe<ServiceListResultOfQueryBuilder> /**  */
	incidentModels?: Maybe<ServiceListResultOfIncidentModel> /**  */
	interventionModels?: Maybe<ServiceListResultOfInterventionModel> /**  */
	messageModels?: Maybe<ServiceListResultOfMessageModel> /**  */
	news?: Maybe<ServiceListResultOfNews> /**  */
	activities?: Maybe<ServiceListResultOfActivity> /**  */
	channels?: Maybe<ServiceListResultOfChannel> /**  */
	commentaryModels?: Maybe<ServiceListResultOfCommentaryModel> /**  */
	impacts?: Maybe<ServiceListResultOfImpact> /**  */
	origins?: Maybe<ServiceListResultOfOrigin> /**  */
	priorities?: Maybe<ServiceListResultOfPriority> /**  */
	resolutionCategories?: Maybe<ServiceListResultOfResolutionCategory> /**  */
	resolutionModels?: Maybe<ServiceListResultOfResolutionModel> /**  */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	taskSequences?: Maybe<ServiceListResultOfTaskSequence> /**  */
	urgencies?: Maybe<ServiceListResultOfUrgency> /**  */
	ticketTaskModels?: Maybe<ServiceListResultOfTicketTaskModel> /**  */
	ticketStatusReasons?: Maybe<ServiceListResultOfTicketStatusReason> /**  */
	warningMessages?: Maybe<ServiceListResultOfWarningMessage> /**  */
	inStockMovements?: Maybe<ServiceListResultOfInStockMovement> /**  */
	suppliers?: Maybe<ServiceListResultOfSupplier> /**  */
	id?: string /**  */
	securityGroupRules?: Maybe<ServiceListResultOfSecurityGroupRule> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	memberLocationIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups?: Array<Maybe<string>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	securityGroupRuleIds: Array<string> /**  */
	isManual: boolean /**  */
	category: SecurityGroupCategory /**  */
} & ISystem

/**  */
export type ServiceListResultOfAlarm = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Alarm>> /**  */
} 

/**  */
export type ServiceListResultOfAssetCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetCategory>> /**  */
} 

/**  */
export type ServiceListResultOfCommand = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Command>> /**  */
} 

/**  */
export type ServiceListResultOfConcernedProject = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ConcernedProject>> /**  */
} 

/**  */
export type ServiceListResultOfCalendarConnector = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CalendarConnector>> /**  */
} 

/**  */
export type ServiceListResultOfExchangeConnector = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ExchangeConnector>> /**  */
} 

/**  */
export type ServiceListResultOfEmailPrototype = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailPrototype>> /**  */
} 

/**  */
export type ServiceListResultOfMessageModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MessageModel>> /**  */
} 

/**  */
export type ServiceListResultOfActivity = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Activity>> /**  */
} 

/**  */
export type ServiceListResultOfChannel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Channel>> /**  */
} 

/**  */
export type ServiceListResultOfCommentaryModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CommentaryModel>> /**  */
} 

/**  */
export type ServiceListResultOfOrigin = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Origin>> /**  */
} 

/**  */
export type ServiceListResultOfResolutionCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ResolutionCategory>> /**  */
} 

/**  */
export type SecurityGroupRule = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	securityGroupIds: Array<string> /**  */
	disableFilterLocation: boolean /**  */
	disableFilterOrganizationalUnit: boolean /**  */
	disableFilterAssetCategory: boolean /**  */
} & ISystem

/**  */
export type ServiceListResultOfSecurityGroupRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SecurityGroupRule>> /**  */
} 

/**  */
export type SerialNumber = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	productName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	name2?: Maybe<string> /**  */
	value2?: Maybe<string> /**  */
	guid?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	serialNumberMode: SerialNumberMode /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSerialNumber = {
	data?: Maybe<SerialNumber> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSerialNumber = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SerialNumber>> /**  */
} 

/**  */
export type SerialPort = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSerialPort = {
	data?: Maybe<SerialPort> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSerialPort = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SerialPort>> /**  */
} 

/**  */
export type ServiceListResultOfDayOff = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DayOff>> /**  */
} 

/**  */
export type ServiceOffer = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	suppliers?: Maybe<ServiceListResultOfSupplier> /**  */
	error: boolean /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedField> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	supplierIds: Array<string> /**  */
} & ISystem

/**  */
export type SftpConnectorHistory = {
	id?: string /**  */
	sftpConnector?: Maybe<ServiceSingleResultOfSftpConnector> /**  */
	sftpConnectorId: string /**  */
	date?: Maybe<Date> /**  */
	sftpAction: SftpAction /**  */
	hasError: boolean /**  */
	description?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfSftpConnectorHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SftpConnectorHistory>> /**  */
} 

/**  */
export type ServiceListResultOfSftpConnector = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SftpConnector>> /**  */
} 

/**  */
export type ServiceListResultOfQueryBuilder = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<QueryBuilder>> /**  */
} 

/**  */
export type Share = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	accessMask: number /**  */
	allowMaximum: boolean /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	maximumAllowed: number /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	type: ShareCategory /**  */
	shareSecurities?: Array<Maybe<ShareSecurity>> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfShare = {
	data?: Maybe<Share> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfShare = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Share>> /**  */
} 

/**  */
export type SnmpCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	encryptionKey?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	community?: Maybe<string> /**  */
	snmpVersion: SnmpVersion /**  */
	authentificationSnmpV3: AuthentificationSnmpV3 /**  */
	encryptionSnmpV3: EncryptionSnmpV3 /**  */
	port: number /**  */
} & INetworkCredential

/**  */
export type ServiceListResultOfSnmpCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SnmpCredential>> /**  */
} 

/**  */
export type SnmpMapInformationKb = {
	id?: string /**  */
	assetCategory?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	sysOid?: Maybe<string> /**  */
	type: SnmpType /**  */
	name?: Maybe<string> /**  */
	assetCategoryKey?: Maybe<string> /**  */
	snmpOidObjects?: Array<Maybe<SnmpOidObject>> /**  */
	assetCategoryId?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISnmpMapInformation & ISystem

/**  */
export type ServiceListResultOfSnmpMapInformationKb = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SnmpMapInformationKb>> /**  */
} 

/**  */
export type SnmpMapInformation = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	assetCategory?: Maybe<ServiceSingleResultOfAssetCategory> /**  */
	sysOid?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	type: SnmpType /**  */
	snmpOidObjects?: Array<Maybe<SnmpOidObject>> /**  */
	assetCategoryId?: Maybe<string> /**  */
} & ISnmpMapInformation

/**  */
export type ServiceListResultOfSnmpMapInformation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SnmpMapInformation>> /**  */
} 

/**  */
export type ServiceSingleResultOfSnmpSummary = {
	data?: Maybe<SnmpSummary> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSnmpSummary = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SnmpSummary>> /**  */
} 

/**  */
export type SnmpVendor = {
	id?: string /**  */
	value: number /**  */
	manufacturer?: Maybe<string> /**  */
} 

/**  */
export type SoftwareAttributeType = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareAttributeType = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareAttributeType>> /**  */
} 

/**  */
export type SoftwareConnectionMode = {
	id?: string /**  */
	software?: Maybe<ServiceListResultOfSoftware> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareConnectionMode = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareConnectionMode>> /**  */
} 

/**  */
export type SoftwareHostedComponent = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareHostedComponent = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareHostedComponent>> /**  */
} 

/**  */
export type SoftwareHostedLink = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId: string /**  */
	assetId: string /**  */
	softwareHostedComponentId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareHostedLink = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareHostedLink>> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareGroup = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareGroup>> /**  */
} 

/**  */
export type ISoftwareProperty = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareProperty = {
	data?: Maybe<ISoftwareProperty> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type SoftwareReferentLink = {
	id?: string /**  */
	user?: Maybe<ServiceSingleResultOfUser> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId: string /**  */
	userId: string /**  */
	softwareReferentTypeId?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareReferentLink = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareReferentLink>> /**  */
} 

/**  */
export type SoftwareReferentType = {
	id?: string /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareReferentType = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwareReferentType>> /**  */
} 

/**  */
export type ServiceListResultOfSoftware = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Software>> /**  */
} 

/**  */
export type ServiceListResultOfScanDataFile = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanDataFile>> /**  */
} 

/**  */
export type ServiceListResultOfSoftwareProperty = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ISoftwareProperty>> /**  */
} 

/**  */
export type SoundDevice = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	productName?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSoundDevice = {
	data?: Maybe<SoundDevice> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSoundDevice = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoundDevice>> /**  */
} 

/**  */
export type SqlServer = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	instanceName?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	edition?: Maybe<string> /**  */
	licenceName?: Maybe<string> /**  */
	fileVersion?: Maybe<string> /**  */
	servicePack?: Maybe<string> /**  */
	wow64: boolean /**  */
	language?: Maybe<string> /**  */
	installPath?: Maybe<string> /**  */
	dataPath?: Maybe<string> /**  */
	sqlService?: Maybe<SqlService> /**  */
	dataBaseInformations?: Array<Maybe<DataBaseInformation>> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSqlServer = {
	data?: Maybe<SqlServer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSqlServer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SqlServer>> /**  */
} 

/**  */
export type SshCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	port: number /**  */
} & INetworkCredential

/**  */
export type ServiceListResultOfSshCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SshCredential>> /**  */
} 

/**  */
export type StatisticTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	ticketId: string /**  */
	modifier: TicketStatisticModifier /**  */
	savedTicket?: Maybe<ITicket> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfStatisticTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StatisticTask>> /**  */
} 

/**  */
export type StockInReason = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfStockInReason = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StockInReason>> /**  */
} 

/**  */
export type StockOutReason = {
	id?: string /**  */
	consumableMovements?: Maybe<ServiceListResultOfConsumableMovement> /**  */
	outStockMovements?: Maybe<ServiceListResultOfOutStockMovement> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfStockOutReason = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StockOutReason>> /**  */
} 

/**  */
export type StockTransferReason = {
	id?: string /**  */
	stockTransfers?: Maybe<ServiceListResultOfStockTransfer> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
} 

/**  */
export type ServiceListResultOfStockTransferReason = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StockTransferReason>> /**  */
} 

/**  */
export type StockTransfer = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	motifStockTransfer?: Maybe<ServiceSingleResultOfStockTransferReason> /**  */
	fromStorageUnit?: Maybe<ServiceSingleResultOfLocation> /**  */
	toStorageUnit?: Maybe<ServiceSingleResultOfLocation> /**  */
	transferBy?: Maybe<ServiceSingleResultOfUser> /**  */
	name?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	transferById: string /**  */
	fromStorageUnitId: string /**  */
	toStorageUnitId: string /**  */
	description?: Maybe<string> /**  */
	motifStockTransferId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	carts?: Array<Maybe<OutCart>> /**  */
	assetIds: Array<string> /**  */
} 

/**  */
export type ServiceListResultOfStockTransfer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StockTransfer>> /**  */
} 

/**  */
export type ServiceListResultOfOutCart = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OutCart>> /**  */
} 

/**  */
export type Substitute = {
	id?: string /**  */
	value?: Maybe<string> /**  */
	replace?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfSubstitute = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Substitute>> /**  */
} 

/**  */
export type ServiceListResultOfSupplier = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Supplier>> /**  */
} 

/**  */
export type ServiceListResultOfAssetModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AssetModel>> /**  */
} 

/**  */
export type ServiceListResultOfInStockMovement = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InStockMovement>> /**  */
} 

/**  */
export type ServiceListResultOfServiceOffer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ServiceOffer>> /**  */
} 

/**  */
export type SystemAccount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	localAccount: boolean /**  */
	name?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	sidType: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSystemAccount = {
	data?: Maybe<SystemAccount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSystemAccount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SystemAccount>> /**  */
} 

/**  */
export type SystemEnclosure = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	audibleAlarm: boolean /**  */
	caption?: Maybe<string> /**  */
	chassisTypes: ChassisType /**  */
	description?: Maybe<string> /**  */
	lockPresent: boolean /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partNumber?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	smbiosAssetTag?: Maybe<string> /**  */
	tag?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	computerDescription?: Maybe<string> /**  */
	dockingMode: DockingMode /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSystemEnclosure = {
	data?: Maybe<SystemEnclosure> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSystemEnclosure = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SystemEnclosure>> /**  */
} 

/**  */
export type SystemSlot = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	slotDesignation?: Maybe<string> /**  */
	tag?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfSystemSlot = {
	data?: Maybe<SystemSlot> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfSystemSlot = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SystemSlot>> /**  */
} 

/**  */
export type TapeDrive = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	ecc: number /**  */
	manufacturer?: Maybe<string> /**  */
	mediaType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	needsCleaning: boolean /**  */
	numberOfMediaSupported: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	statusInfo: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfTapeDrive = {
	data?: Maybe<TapeDrive> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfTapeDrive = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TapeDrive>> /**  */
} 

/**  */
export type ServiceListResultOfTaskSequence = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TaskSequence>> /**  */
} 

/**  */
export type TaskStatus = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ticketTasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	predecessorTicketTasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	name?: Maybe<TranslatedField> /**  */
	description?: Maybe<TranslatedField> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type TaskStatusWorkflow = {
	id?: string /**  */
	lastUpdateBy?: Maybe<ServiceSingleResultOfUser> /**  */
	name?: Maybe<TranslatedField> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enable: boolean /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	config?: Maybe<StatusConfig> /**  */
} & ISystem

/**  */
export type Template = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
} & ISystem

/**  */
export type ServiceListResultOfTemplate = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Template>> /**  */
} 

/**  */
export type TermsContract = {
	id?: string /**  */
	title?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	entityType?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfTermsContract = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TermsContract>> /**  */
} 

/**  */
export type TicketAffectedRule = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	referentOperator?: Maybe<ServiceSingleResultOfUser> /**  */
	affectedOperator?: Maybe<ServiceSingleResultOfUser> /**  */
	referentOperatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	affectedOperatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	populations?: Maybe<ServiceListResultOfPopulation> /**  */
	categoryIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	applyOption: ApplyOption /**  */
	description?: Maybe<TranslatedField> /**  */
	name?: Maybe<TranslatedField> /**  */
	referentOperatorId?: Maybe<string> /**  */
	referentOperatorTeamId?: Maybe<string> /**  */
	affectedOperatorId?: Maybe<string> /**  */
	affectedOperatorTeamId?: Maybe<string> /**  */
	supportLevel: number /**  */
	qualification?: Array<Maybe<string>> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
} & ITicketQualification & IRank

/**  */
export type ServiceListResultOfSnmpVendor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SnmpVendor>> /**  */
} 

/**  */
export type TicketAppointment = {
	id?: string /**  */
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	operatorId: string /**  */
	operatorTeamId: string /**  */
	ticketStatusId: string /**  */
	taskStatusId: string /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	ticketId: string /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	color?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
} & IAppointment

/**  */
export type ITicketBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} 

/**  */
export type ServiceSingleResultOfTicketBacklog = {
	data?: Maybe<ITicketBacklog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type TicketDailyBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} & ITicketBacklog

/**  */
export type ServiceListResultOfTicketDailyBacklog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketDailyBacklog>> /**  */
} 

/**  */
export type TicketDurationTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	startDate?: Maybe<Date> /**  */
	endDate?: Maybe<Date> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfTicketDurationTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketDurationTask>> /**  */
} 

/**  */
export type TicketEmail = {
	id?: string /**  */
	files?: Maybe<ServiceListResultOfFileModel> /**  */
	attachmentFiles?: Maybe<ServiceListResultOfFileModel> /**  */
	ticket?: Maybe<ServiceSingleResultOfTicket> /**  */
	emailConnector?: Maybe<ServiceSingleResultOfEmailConnector> /**  */
	ticketId?: Maybe<string> /**  */
	ticketEmailAction: TicketEmailAction /**  */
	emailId?: Maybe<string> /**  */
	inboxId?: Maybe<string> /**  */
	emailConnectorId: string /**  */
	to?: Array<Maybe<string>> /**  */
	from?: Maybe<string> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	bcc?: Array<Maybe<string>> /**  */
	attachments?: Array<Maybe<MailSlurpAttachmentMetaData>> /**  */
	hasAttachment: boolean /**  */
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	isHtml: boolean /**  */
	createdAt?: Maybe<Date> /**  */
	errorMessage?: Maybe<string> /**  */
	hasError: boolean /**  */
	retryAt?: Maybe<Date> /**  */
	retryBy?: Maybe<string> /**  */
	retryComment?: Maybe<string> /**  */
	createdDate?: Maybe<Date> /**  */
	createdFromTicket: boolean /**  */
	fileIds: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
} & ITicketLink

/**  */
export type ServiceListResultOfMailSlurpAttachmentMetaData = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<MailSlurpAttachmentMetaData>> /**  */
} 

/**  */
export type MailSlurpAttachmentMetaData = {
	name?: Maybe<string> /**  */
	contentType?: Maybe<string> /**  */
	contentLength: number /**  */
	id?: Maybe<string> /**  */
	fileId?: Maybe<string> /**  */
	commentary?: Maybe<string> /**  */
} 

/**  */
export type TicketMonthlyBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} & ITicketBacklog

/**  */
export type ServiceListResultOfTicketMonthlyBacklog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketMonthlyBacklog>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicket = {
	data?: Maybe<ITicket> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfGuid = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data: Array<string> /**  */
} 

/**  */
export type ServiceListResultOfResolutionModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ResolutionModel>> /**  */
} 

/**  */
export type ServiceListResultOfTicketCategory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketCategory>> /**  */
} 

/**  */
export type ServiceListResultOfOrganizationalUnit = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OrganizationalUnit>> /**  */
} 

/**  */
export type ServiceListResultOfImpact = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Impact>> /**  */
} 

/**  */
export type ServiceListResultOfPriority = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Priority>> /**  */
} 

/**  */
export type ServiceListResultOfMessage = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Message>> /**  */
} 

/**  */
export type ServiceListResultOfEmailPrototypeLog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailPrototypeLog>> /**  */
} 

/**  */
export type ServiceListResultOfTicketEmail = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketEmail>> /**  */
} 

/**  */
export type ITicket = {
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	impact?: Maybe<ServiceSingleResultOfImpact> /**  */
	urgency?: Maybe<ServiceSingleResultOfUrgency> /**  */
	priority?: Maybe<ServiceSingleResultOfPriority> /**  */
	resolutionCategory?: Maybe<ServiceSingleResultOfResolutionCategory> /**  */
	concernedProject?: Maybe<ServiceSingleResultOfConcernedProject> /**  */
	ticketCategory?: Maybe<ServiceSingleResultOfTicketCategory> /**  */
	channel?: Maybe<ServiceSingleResultOfChannel> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	ticketStatusReasons?: Maybe<ServiceSingleResultOfTicketStatusReason> /**  */
	organizationalUnit?: Maybe<ServiceSingleResultOfOrganizationalUnit> /**  */
	sla?: Maybe<ServiceSingleResultOfServiceLevelAgreement> /**  */
	mainImpactedAsset?: Maybe<ServiceSingleResultOfAsset> /**  */
	userAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorReferent?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	userMakeRequest?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeamReferent?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	parent?: Maybe<ServiceSingleResultOfTicket> /**  */
	children?: Maybe<ServiceListResultOfTicket> /**  */
	incidentModel?: Maybe<ServiceSingleResultOfIncidentModel> /**  */
	privateResolutionArticle?: Maybe<ServiceSingleResultOfArticleKnowledge> /**  */
	problem?: Maybe<ServiceSingleResultOfProblem> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	assets?: Maybe<ServiceListResultOfAsset> /**  */
	messages?: Maybe<ServiceListResultOfMessage> /**  */
	alerts?: Maybe<ServiceListResultOfAlert> /**  */
	tasks?: Maybe<ServiceListResultOfTicketTask> /**  */
	interventions?: Maybe<ServiceListResultOfIntervention> /**  */
	emailPrototypeLogs?: Maybe<ServiceListResultOfEmailPrototypeLog> /**  */
	notes?: Maybe<ServiceListResultOfNote> /**  */
	ticketEmails?: Maybe<ServiceListResultOfTicketEmail> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnector> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<Reminder> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfaction> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
} 

/**  */
export type TicketStatistic = {
	ticketBeforeState?: Maybe<ITicket> /**  */
	savedTicket?: Maybe<ITicket> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ticketId: string /**  */
	elapsedSinceLastEvent: number /**  */
	ticketEventDate?: Maybe<Date> /**  */
	modifier?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfTicketStatistic = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketStatistic>> /**  */
} 

/**  */
export type ServiceListResultOfTicketStatusReason = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketStatusReason>> /**  */
} 

/**  */
export type ServiceListResultOfTicketStatus = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketStatus>> /**  */
} 

/**  */
export type TicketTag = {
	id?: string /**  */
	entityType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfTicketTag = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketTag>> /**  */
} 

/**  */
export type TicketTagTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	tags?: Array<Maybe<string>> /**  */
	oldName?: Maybe<string> /**  */
	newName?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfTicketTagTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketTagTask>> /**  */
} 

/**  */
export type TicketTaskModel = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	operator?: Maybe<ServiceSingleResultOfUser> /**  */
	operatorTeam?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	activity?: Maybe<ServiceSingleResultOfActivity> /**  */
	taskSequence?: Maybe<ServiceSingleResultOfTaskSequence> /**  */
	title?: Maybe<TranslatedField> /**  */
	createdBy?: Maybe<string> /**  */
	name?: Maybe<TranslatedField> /**  */
	qualification?: Array<Maybe<string>> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	duration?: Maybe<string> /**  */
	description?: Maybe<TranslatedField> /**  */
	activityId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
} & ISecurityGroup

/**  */
export type ServiceListResultOfTaskStatus = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TaskStatus>> /**  */
} 

/**  */
export type ServiceListResultOfTicketAppointment = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketAppointment>> /**  */
} 

/**  */
export type ServiceListResultOfBadge = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Badge>> /**  */
} 

/**  */
export type ServiceListResultOfAlert = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Alert>> /**  */
} 

/**  */
export type TicketWeeklyBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} & ITicketBacklog

/**  */
export type ServiceListResultOfTicketWeeklyBacklog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketWeeklyBacklog>> /**  */
} 

/**  */
export type TicketYearlyBacklog = {
	id?: string /**  */
	operatorTeamAffected?: Maybe<ServiceSingleResultOfOperatorTeam> /**  */
	operatorAffected?: Maybe<ServiceSingleResultOfUser> /**  */
	status?: Maybe<ServiceSingleResultOfTicketStatus> /**  */
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
} & ITicketBacklog

/**  */
export type ServiceListResultOfTicketYearlyBacklog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketYearlyBacklog>> /**  */
} 

/**  */
export type TrackingEntity = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	ids: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	isTranslatable: boolean /**  */
} 

/**  */
export type ServiceListResultOfTrackingEntity = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TrackingEntity>> /**  */
} 

/**  */
export type Tracking = {
	id?: string /**  */
	author?: Maybe<ServiceSingleResultOfUser> /**  */
	inventoryHistoryId?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	entityId?: Maybe<string> /**  */
	oldValue?: Maybe<any> /**  */
	newValue?: Maybe<any> /**  */
	value?: Maybe<any> /**  */
	entityState: EntityState /**  */
	entityPropertyName?: Maybe<string> /**  */
	entityName?: Maybe<string> /**  */
	entitySourceId: string /**  */
	entitySourceName?: Maybe<string> /**  */
	entitySource?: Maybe<any> /**  */
	system: boolean /**  */
	authorId?: Maybe<string> /**  */
	propertyKeyValue?: Maybe<string> /**  */
	changes?: Array<Maybe<TrackingChange>> /**  */
	values?: Array<Maybe<TrackingValue>> /**  */
} 

/**  */
export type UptimeCalendar = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	date?: Maybe<Date> /**  */
	uptimeCalendarMode: UptimeCalendarMode /**  */
	shutDownReason?: Maybe<string> /**  */
	shutDownType?: Maybe<string> /**  */
	shutDownProcess?: Maybe<string> /**  */
	shutDownCode?: Maybe<string> /**  */
	recordNumber: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfUptimeCalendar = {
	data?: Maybe<UptimeCalendar> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfUptimeCalendar = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UptimeCalendar>> /**  */
} 

/**  */
export type ServiceListResultOfUrgency = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Urgency>> /**  */
} 

/**  */
export type UserAccountControl = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	consentPromptBehaviorAdmin: ConsentPromptBehaviorAdmin /**  */
	consentPromptBehaviorUser: ConsentPromptBehaviorUser /**  */
	enableInstallerDetection: boolean /**  */
	enableLua: boolean /**  */
	promptOnSecureDesktop: boolean /**  */
	filterAdministratorToken: boolean /**  */
	enableUiaDesktopToggle: boolean /**  */
	enableSecureUiaPaths: boolean /**  */
	validateAdminCodeSignatures: boolean /**  */
	enableVirtualization: boolean /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfUserAccountControl = {
	data?: Maybe<UserAccountControl> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfUserAccountControl = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserAccountControl>> /**  */
} 

/**  */
export type UserAccount = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	accountType: number /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	disabled: boolean /**  */
	domain?: Maybe<string> /**  */
	fullName?: Maybe<string> /**  */
	localAccount: boolean /**  */
	lockout: boolean /**  */
	name?: Maybe<string> /**  */
	passwordChangeable: boolean /**  */
	passwordExpires: boolean /**  */
	passwordRequired: boolean /**  */
	sid?: Maybe<string> /**  */
	sidType: number /**  */
	status?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfUserAccount = {
	data?: Maybe<UserAccount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfUserAccount = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserAccount>> /**  */
} 

/**  */
export type UserAffectedRule = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	byDistinguishedName: boolean /**  */
	bySid: boolean /**  */
	byEmail: boolean /**  */
	byDisplayName: boolean /**  */
	byUserPrincipalName: boolean /**  */
	byLastNameAndFirstName: boolean /**  */
	byDomain: boolean /**  */
	byDifferentDomain: boolean /**  */
	byAzureObjectId: boolean /**  */
	byDomainEnum: ByDomainEnum /**  */
} & IAffectedRule & IRank & ISystem

/**  */
export type ServiceListResultOfUserAffectedRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserAffectedRule>> /**  */
} 

/**  */
export type UserPrivilege = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	users?: Maybe<ServiceListResultOfUser> /**  */
	name?: Maybe<TranslatedField> /**  */
	isDefault: boolean /**  */
	defaultName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	affectedUserEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	otherAffectedUserEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	responsableUserEntries?: Array<Maybe<PrivilegeEntry>> /**  */
	accessTicketsObserverEntries?: Array<Maybe<PrivilegeEntry>> /**  */
} & ISystem

/**  */
export type ServiceListResultOfPrivilegeEntry = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PrivilegeEntry>> /**  */
} 

/**  */
export type ServiceListResultOfUserPrivilege = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserPrivilege>> /**  */
} 

/**  */
export type UserProfile = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	lastDownloadTime?: Maybe<Date> /**  */
	lastUploadTime?: Maybe<Date> /**  */
	lastUseTime?: Maybe<Date> /**  */
	loaded: boolean /**  */
	localPath?: Maybe<string> /**  */
	refCount: number /**  */
	roamingConfigured: boolean /**  */
	roamingPath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	roamingPreference: boolean /**  */
	sid?: Maybe<string> /**  */
	special: boolean /**  */
	status: number /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfUserProfile = {
	data?: Maybe<UserProfile> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfUserProfile = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserProfile>> /**  */
} 

/**  */
export type EntityAttributes = {
	created?: Maybe<EntityAttribute> /**  */
	deleted?: Maybe<EntityAttribute> /**  */
	updated?: Maybe<EntityAttribute> /**  */
	locked?: Maybe<EntityAttribute> /**  */
} 

/**  */
export type UserRole = {
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	userId: string /**  */
	roleId: string /**  */
} 

/**  */
export type ServiceListResultOfUserRole = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<UserRole>> /**  */
} 

/**  */
export type ServiceListResultOfLocation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Location>> /**  */
} 

/**  */
export type ServiceListResultOfLayout = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Layout>> /**  */
} 

/**  */
export type ServiceSingleResultOfLayout = {
	data?: Maybe<Layout> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type Layout = {
	id?: string /**  */
	key?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	filterName?: Maybe<string> /**  */
	filterValue?: Maybe<string> /**  */
} 

/**  */
export type ServiceSingleResultOfInt32 = {
	data: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPreferences = {
	data?: Maybe<Preferences> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type Preferences = {
	lang?: Maybe<string> /**  */
	theme?: Maybe<string> /**  */
	dashboardId?: Maybe<string> /**  */
	paginate: number /**  */
	layouts?: Array<Maybe<Layout>> /**  */
	defaultLayouts?: Array<Maybe<Layout>> /**  */
	pinStateMenu: boolean /**  */
} 

/**  */
export type ServiceListResultOfBudget = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Budget>> /**  */
} 

/**  */
export type ServiceListResultOfContract = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Contract>> /**  */
} 

/**  */
export type ServiceListResultOfTicketAffectedRule = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketAffectedRule>> /**  */
} 

/**  */
export type ServiceListResultOfIncidentModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IncidentModel>> /**  */
} 

/**  */
export type ServiceListResultOfStatusWorkflow = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<StatusWorkflow>> /**  */
} 

/**  */
export type ServiceListResultOfArticleKnowledge = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ArticleKnowledge>> /**  */
} 

/**  */
export type ServiceListResultOfInheritLocationCharter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InheritLocationCharter>> /**  */
} 

/**  */
export type ServiceListResultOfEmailConnector = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<EmailConnector>> /**  */
} 

/**  */
export type ServiceListResultOfSoftwarePackage = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SoftwarePackage>> /**  */
} 

/**  */
export type ServiceListResultOfTicketTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketTask>> /**  */
} 

/**  */
export type ServiceListResultOfIntervention = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Intervention>> /**  */
} 

/**  */
export type ServiceListResultOfConsumableMovement = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ConsumableMovement>> /**  */
} 

/**  */
export type ServiceListResultOfCorrectionStockMovement = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<CorrectionStockMovement>> /**  */
} 

/**  */
export type ServiceListResultOfDashboard = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Dashboard>> /**  */
} 

/**  */
export type ServiceListResultOfFormDesigner = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FormDesigner>> /**  */
} 

/**  */
export type ServiceListResultOfInterventionModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InterventionModel>> /**  */
} 

/**  */
export type ServiceListResultOfLdapAnalyse = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LdapAnalyse>> /**  */
} 

/**  */
export type ServiceListResultOfLoan = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Loan>> /**  */
} 

/**  */
export type ServiceListResultOfLocationCharter = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<LocationCharter>> /**  */
} 

/**  */
export type ServiceListResultOfModelRulesDesigner = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ModelRulesDesigner>> /**  */
} 

/**  */
export type ServiceListResultOfNews = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<News>> /**  */
} 

/**  */
export type ServiceListResultOfNote = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Note>> /**  */
} 

/**  */
export type ServiceListResultOfOutStockMovement = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OutStockMovement>> /**  */
} 

/**  */
export type ServiceListResultOfTaskStatusWorkflow = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TaskStatusWorkflow>> /**  */
} 

/**  */
export type ServiceListResultOfTicketBacklog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ITicketBacklog>> /**  */
} 

/**  */
export type ServiceListResultOfTicketTaskModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<TicketTaskModel>> /**  */
} 

/**  */
export type ServiceListResultOfTracking = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Tracking>> /**  */
} 

/**  */
export type VerifaliaTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	jobId: string /**  */
	emailsToCheck?: Array<Maybe<string>> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfVerifaliaTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<VerifaliaTask>> /**  */
} 

/**  */
export type VideoController = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	adapterCompatibility?: Maybe<string> /**  */
	adapterDacType?: Maybe<string> /**  */
	adapterRam: number /**  */
	caption?: Maybe<string> /**  */
	currentBitsPerPixel: number /**  */
	currentHorizontalResolution: number /**  */
	currentNumberOfColors: number /**  */
	currentNumberOfColumns: number /**  */
	currentNumberOfRows: number /**  */
	currentRefreshRate: number /**  */
	currentScanMode: number /**  */
	currentVerticalResolution: number /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driverDate?: Maybe<Date> /**  */
	driverVersion?: Maybe<string> /**  */
	monochrome: boolean /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	videoArchitecture: number /**  */
	videoMemoryType: number /**  */
	videoMode: number /**  */
	videoModeDescription?: Maybe<string> /**  */
	videoProcessor?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfVideoController = {
	data?: Maybe<VideoController> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfVideoController = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<VideoController>> /**  */
} 

/**  */
export type VirtualMachine = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	assetLink?: Maybe<ServiceSingleResultOfAsset> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	elementName?: Maybe<string> /**  */
	statusDescriptions?: Array<Maybe<string>> /**  */
	status?: Maybe<string> /**  */
	healthState: number /**  */
	enabledState: number /**  */
	otherEnabledState?: Maybe<string> /**  */
	requestedState: number /**  */
	enabledDefault: number /**  */
	timeOfLastStateChange?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	primaryOwnerName?: Maybe<string> /**  */
	primaryOwnerContact?: Maybe<string> /**  */
	nameFormat?: Maybe<string> /**  */
	otherIdentifyingInfo?: Array<Maybe<string>> /**  */
	identifyingDescriptions?: Array<Maybe<string>> /**  */
	otherDedicatedDescriptions?: Array<Maybe<string>> /**  */
	resetCapability: number /**  */
	onTimeInMilliseconds: number /**  */
	timeOfLastConfigurationChange?: Maybe<Date> /**  */
	processId: number /**  */
	virtualMachineGuest?: Maybe<VirtualMachineGuest> /**  */
	assetLinkId?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfVirtualMachine = {
	data?: Maybe<VirtualMachine> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfVirtualMachine = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<VirtualMachine>> /**  */
} 

/**  */
export type Vlan = {
	assetLink?: Maybe<Asset> /**  */
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	port: number /**  */
	macAddress?: Maybe<string> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfVlan = {
	data?: Maybe<Vlan> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfVlan = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Vlan>> /**  */
} 

/**  */
export type WallSocket = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	location?: Maybe<ServiceSingleResultOfLocation> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	brokenDown: boolean /**  */
	locationId?: Maybe<string> /**  */
} & ILocalizable

/**  */
export type ServiceListResultOfWallSocket = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WallSocket>> /**  */
} 

/**  */
export type EntityAttribute = {
	username?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	state: boolean /**  */
} 

/**  */
export type WarningCustomer = {
	id?: string /**  */
	created?: Maybe<EntityAttribute> /**  */
	updated?: Maybe<EntityAttribute> /**  */
	title?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	availableForHelpMe: boolean /**  */
} 

/**  */
export type ServiceListResultOfWarningCustomer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WarningCustomer>> /**  */
} 

/**  */
export type WarningMessage = {
	id?: string /**  */
	securityGroups?: Maybe<ServiceListResultOfSecurityGroup> /** Récupère les profils de l'entité. */
	populations?: Maybe<ServiceListResultOfPopulation> /** Récupère les populations de l'entité. */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	title?: Maybe<TranslatedField> /**  */
	content?: Maybe<TranslatedField> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	populationIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObject> /**  */
	warningMessageType: WarningMessageCategory /**  */
	warningMessageOperatorUsersIds: Array<string> /**  */
} & IPopulation & ISecurityGroup

/**  */
export type ServiceListResultOfOperatorTeam = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<OperatorTeam>> /**  */
} 

/**  */
export type ServiceListResultOfPopulation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Population>> /**  */
} 

/**  */
export type ServiceListResultOfWarningMessage = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WarningMessage>> /**  */
} 

/**  */
export type WarningViewCustomer = {
	id?: string /**  */
	warningCustomerId: string /**  */
	userId: string /**  */
	lastDisplay?: Maybe<Date> /**  */
} 

/**  */
export type ServiceListResultOfWarningViewCustomer = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WarningViewCustomer>> /**  */
} 

/**  */
export type WarrantyLinkVendor = {
	id?: string /**  */
	vendor?: Maybe<string> /**  */
	manufacturers?: Array<Maybe<string>> /**  */
	displayLink?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfWarrantyLinkVendor = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WarrantyLinkVendor>> /**  */
} 

/**  */
export type WindowsFirewall = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	domainProfile?: Maybe<FirewallPolicy> /**  */
	publicProfile?: Maybe<FirewallPolicy> /**  */
	standardProfile?: Maybe<FirewallPolicy> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfWindowsFirewall = {
	data?: Maybe<WindowsFirewall> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfWindowsFirewall = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WindowsFirewall>> /**  */
} 

/**  */
export type WindowsService = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	acceptPause: boolean /**  */
	acceptStop: boolean /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pathName?: Maybe<string> /**  */
	started: boolean /**  */
	startMode: StartMode /**  */
	startName?: Maybe<string> /**  */
	state: ServiceState /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfWindowsService = {
	data?: Maybe<WindowsService> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfWindowsService = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WindowsService>> /**  */
} 

/**  */
export type WindowsUpdate = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	name?: Maybe<string> /**  */
	auOptions: number /**  */
	nextDetectionTime?: Maybe<string> /**  */
	scheduledInstallDate?: Maybe<string> /**  */
	scheduledInstallDay: number /**  */
	scheduledInstallTime: number /**  */
	detect?: Maybe<WindowsUpdateResult> /**  */
	download?: Maybe<WindowsUpdateResult> /**  */
	install?: Maybe<WindowsUpdateResult> /**  */
} & IAssetProperty

/**  */
export type ServiceSingleResultOfWindowsUpdate = {
	data?: Maybe<WindowsUpdate> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfWindowsUpdate = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WindowsUpdate>> /**  */
} 

/**  */
export type WmiCredential = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanConfigurations?: Maybe<ServiceListResultOfScanConfiguration> /**  */
	inventoryHistories?: Maybe<ServiceListResultOfInventoryHistory> /**  */
	scanByLdaps?: Maybe<ServiceListResultOfScanByLdap> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	domain?: Maybe<string> /**  */
} & INetworkCredential

/**  */
export type ServiceListResultOfWmiCredential = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WmiCredential>> /**  */
} 

/**  */
export type ServiceListResultOfScanByLdap = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ScanByLdap>> /**  */
} 

/**  */
export type WorkApplication = {
	id?: string /**  */
	asset?: Maybe<ServiceSingleResultOfAsset> /**  */
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObject>> /**  */
	software?: Maybe<ServiceSingleResultOfSoftware> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	publisher?: Maybe<string> /**  */
} & IAssetProperty & ISoftwareProperty

/**  */
export type ServiceSingleResultOfWorkApplication = {
	data?: Maybe<WorkApplication> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfWorkApplication = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkApplication>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowAction = {
	data?: Maybe<IWorkflowAction> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type WorkflowDelete = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowDelete = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowDelete>> /**  */
} 

/**  */
export type WorkflowHttpRequest = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	httpOutputConfig?: Maybe<HttpOutput> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowHttpRequest = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowHttpRequest>> /**  */
} 

/**  */
export type WorkflowIntervalTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowId: string /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfWorkflowIntervalTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowIntervalTask>> /**  */
} 

/**  */
export type WorkflowInvitation = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	roleIds: Array<string> /**  */
	modelType?: Maybe<string> /**  */
	user?: Maybe<User> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowInvitation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowInvitation>> /**  */
} 

/**  */
export type WorkflowInvitationTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowInvitation?: Maybe<WorkflowInvitation> /**  */
	authorId?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfWorkflowInvitationTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowInvitationTask>> /**  */
} 

/**  */
export type TranslatedField = {
	fr?: Maybe<string> /**  */
	nl?: Maybe<string> /**  */
	en?: Maybe<string> /**  */
	it?: Maybe<string> /**  */
	de?: Maybe<string> /**  */
} 

/**  */
export type IWorkflowAction = {
	id?: string /**  */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
} 

/**  */
export type WorkflowLog = {
	description?: Maybe<string> /**  */
	id?: string /**  */
	created?: Maybe<Date> /**  */
	name?: Maybe<TranslatedField> /**  */
	type?: Maybe<string> /**  */
	propertyName?: Maybe<string> /**  */
	workflowActions?: Array<Maybe<IWorkflowAction>> /**  */
	state: boolean /**  */
	error?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	workflowType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	entityDisplay?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	ticketType?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfWorkflowLog = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowLog>> /**  */
} 

/**  */
export type WorkflowMail = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	emailPrototypeLink?: Maybe<ServiceSingleResultOfEmailPrototype> /**  */
	useProprotype: boolean /**  */
	sendInvitation: boolean /**  */
	emailPrototypeId?: Maybe<string> /**  */
	emailPrototype?: Maybe<EmailPrototype> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowMail = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowMail>> /**  */
} 

/**  */
export type WorkflowMailTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowMail?: Maybe<WorkflowMail> /**  */
	entity?: Maybe<any> /**  */
	callerId?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfWorkflowMailTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowMailTask>> /**  */
} 

/**  */
export type WorkflowManaged = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	managedKey?: Maybe<string> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowManaged = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowManaged>> /**  */
} 

/**  */
export type WorkflowNotificationTeams = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	recipient?: Maybe<string> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowNotificationTeams = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowNotificationTeams>> /**  */
} 

/**  */
export type WorkflowProperty = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	workflowPropertyValue?: Maybe<WorkflowPropertyValue> /**  */
	workflowPropertyState?: Maybe<WorkflowPropertyState> /**  */
	propertyName?: Maybe<string> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowProperty = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowProperty>> /**  */
} 

/**  */
export type WorkflowRevocation = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	user?: Maybe<User> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowRevocation = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowRevocation>> /**  */
} 

/**  */
export type WorkflowRevocationWithoutEmail = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	workflow?: Maybe<ServiceSingleResultOfWorkflow> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSave> /**  */
	optionDelete?: Maybe<OptionDelete> /**  */
	user?: Maybe<User> /**  */
} & IWorkflowAction & ISystem

/**  */
export type ServiceListResultOfWorkflowRevocationWithoutEmail = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowRevocationWithoutEmail>> /**  */
} 

/**  */
export type ServiceListResultOfWorkflowDelay = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowDelay>> /**  */
} 

/**  */
export type WorkflowDelay = {
	key?: Maybe<string> /**  */
	displayName?: Maybe<TranslatedField> /**  */
	property?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfWorkflow = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Workflow>> /**  */
} 

/**  */
export type ServiceListResultOfWorkflowAction = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IWorkflowAction>> /**  */
} 

/**  */
export type WorkflowScheduleTask = {
	id?: string /**  */
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowLinkedObjectId: string /**  */
	workflowLinkedObjectType?: Maybe<string> /**  */
} & IScheduleTask

/**  */
export type ServiceListResultOfWorkflowScheduleTask = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkflowScheduleTask>> /**  */
} 

/**  */
export type WorkTime = {
	id?: string /**  */
	trackings?: Maybe<ServiceListResultOfTracking> /** Récupère les historique de changement de l'entité. */
	serviceLevelAgreements?: Maybe<ServiceListResultOfServiceLevelAgreement> /**  */
	name?: Maybe<TranslatedField> /**  */
	enabled: boolean /**  */
	config?: Maybe<WorkTimeConfig> /**  */
} 

/**  */
export type ServiceListResultOfWorkTime = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<WorkTime>> /**  */
} 

/**  */
export type ServiceListResultOfServiceLevelAgreement = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ServiceLevelAgreement>> /**  */
} 

/**  */
export type ServiceListResultOfOrganization = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Organization>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrganizationResult = {
	data?: Maybe<OrganizationResult> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type OrganizationResult = {
	tenantId: string /**  */
	userId: string /**  */
	userIsReponsible: boolean /**  */
	privilegeId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	operatorTeamIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	roles?: Array<Maybe<string>> /**  */
	permissions?: Array<Maybe<string>> /**  */
	securityGroupIds: Array<string> /**  */
	securityGroupIdsByKey: Array<KeyValuePairOfStringAndICollectionOfGuid> /**  */
	roleTypes?: Array<Maybe<string>> /**  */
	organization?: Maybe<Organization> /**  */
	userLanguage?: Maybe<string> /**  */
	isOperator: boolean /**  */
} 

/**  */
export type KeyValuePairOfStringAndICollectionOfGuid = {
	key: string /**  */
	value: Array<string> /**  */
} 

/**  */
export type Organization = {
	licenseModel?: Maybe<ServiceSingleResultOfLicenseModel> /**  */
	findDynamicProperties?: Maybe<ServiceListResultOfDynamicPropertyField> /**  */
	id?: string /**  */
	attributes?: Maybe<EntityAttributes> /**  */
	dynamicProperties?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	file?: Maybe<ServiceSingleResultOfFileModel> /**  */
	name?: Maybe<string> /**  */
	address?: Maybe<Address> /**  */
	commercialInformation?: Maybe<CommercialInformation> /**  */
	contact?: Maybe<OrganizationContact> /**  */
	url?: Maybe<string> /**  */
	blocked: boolean /**  */
	status: OrganizationStatus /**  */
	expireAt?: Maybe<Date> /**  */
	licenseOrganizationType: LicenseTypeEnum /**  */
	approvedDate?: Maybe<Date> /**  */
	approvedUserId?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	lastConnected?: Maybe<Date> /**  */
	lastUserConnected?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	licenseAgreement?: Maybe<LicenseAgreement> /**  */
	license: Array<number> /**  */
	incrementTicket?: Maybe<string> /**  */
	incrementTask?: Maybe<string> /**  */
	incrementKnowledge?: Maybe<string> /**  */
	countMailSend: number /**  */
	countMailReceive: number /**  */
	contentCustomization?: Maybe<ContentCustomization> /**  */
	fileId?: Maybe<string> /**  */
	lang?: Maybe<string> /**  */
	idleTimer: number /**  */
	lengthFiles: number /**  */
	maxFileSize: number /**  */
	allowedFileWithoutExtension: boolean /**  */
	allowedFileExtension?: Array<Maybe<string>> /**  */
	forbiddenFileExtension?: Array<Maybe<string>> /**  */
	fileIncidentId?: Maybe<string> /**  */
	urlImgIncident?: Maybe<string> /**  */
	urlImgRequest?: Maybe<string> /**  */
	fileRequestId?: Maybe<string> /**  */
	allowAnonymousViewFile: boolean /**  */
	marketPlaceToken?: Maybe<string> /**  */
	marketPlaceSubscriptionId?: Maybe<string> /**  */
	userIds?: Array<Maybe<string>> /**  */
	verifaliaEmails?: Array<Maybe<string>> /**  */
	dayOfEndWeek: number /**  */
	lastTicketBacklog?: Maybe<Date> /**  */
	cronTicketBacklog?: Maybe<Date> /**  */
} 

/**  */
export type ServiceSingleResultOfNetworkCredential = {
	data?: Maybe<INetworkCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfScanConfiguration = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<IScanConfiguration>> /**  */
} 

/**  */
export type ServiceListResultOfNetworkAdapterConfiguration = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<NetworkAdapterConfiguration>> /**  */
} 

/**  */
export type NetworkAdapterConfiguration = {
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	dhcpEnabled: boolean /**  */
	dhcpServer?: Maybe<string> /**  */
	dnsDomain?: Maybe<string> /**  */
	dnsDomainSuffixSearchOrder?: Array<Maybe<string>> /**  */
	dnsEnabledForWinsResolution: boolean /**  */
	dnsHostName?: Maybe<string> /**  */
	dnsServerSearchOrder?: Array<Maybe<string>> /**  */
	domainDnsRegistrationEnabled: boolean /**  */
	index: number /**  */
	interfaceIndex: number /**  */
	ipEnabled: boolean /**  */
	macAddress?: Maybe<string> /**  */
	winsEnableLmHostsLookup: boolean /**  */
	winsHostLookupFile?: Maybe<string> /**  */
	winsPrimaryServer?: Maybe<string> /**  */
	winsSecondaryServer?: Maybe<string> /**  */
	ipAddress?: Array<Maybe<string>> /**  */
	ipSubnet?: Array<Maybe<string>> /**  */
	defaultIpGateway?: Array<Maybe<string>> /**  */
} 

/**  */
export type PropertyField = {
	name?: Maybe<string> /**  */
	serviceName?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	isEnum: boolean /**  */
	isList: boolean /**  */
	isClarilogModel: boolean /**  */
	isNumeric: boolean /**  */
	displayExpression?: Maybe<string> /**  */
	valueExpression?: Maybe<string> /**  */
	id?: string /**  */
	parentId?: Maybe<string> /**  */
	hasChildren: boolean /**  */
	hasQualification: boolean /**  */
	canFilter: boolean /**  */
	treeField?: Maybe<TreeField> /**  */
	isTranslatable: boolean /**  */
	completeName?: Maybe<string> /**  */
	keyName?: Maybe<string> /**  */
	keyPathName?: Maybe<string> /**  */
	parentType?: Maybe<string> /**  */
	subIrefProperty: boolean /**  */
	irefProperty: boolean /**  */
	translated?: Maybe<string> /**  */
} 

/**  */
export type TreeField = {
	nameExp?: Maybe<string> /**  */
	pathExp?: Maybe<string> /**  */
	parentIdExp?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfPropertyField = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<PropertyField>> /**  */
} 

/**  */
export type ServiceListResultOfProductFeature = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ProductFeature>> /**  */
} 

/**  */
export type ProductFeature = {
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
} 

/**  */
export type ServiceListResultOfSecurityGroup = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<SecurityGroup>> /**  */
} 

/**  */
export type ServiceSingleResultOfEntityAttribute = {
	data?: Maybe<EntityAttribute> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfUser = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<User>> /**  */
} 

/**  */
export type ServiceSingleResultOfInt64 = {
	data: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfDynamicPropertyField = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<DynamicPropertyField>> /**  */
} 

/**  */
export type ServiceListResultOfAsset = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<Asset>> /**  */
} 

/**  */
export type ServiceListResultOfInventoryHistory = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<InventoryHistory>> /**  */
} 

/**  */
export type ServiceListResultOfAuditTime = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<AuditTime>> /**  */
} 

/**  */
export type ServiceSingleResultOfFileModel = {
	data?: Maybe<FileModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfFileModel = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<FileModel>> /**  */
} 

/**  */
export type ServiceListResultOfTicket = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<ITicket>> /**  */
} 

/**  */
export type ServiceSingleResultOfActivity = {
	data?: Maybe<Activity> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAgentConfiguration = {
	data?: Maybe<AgentConfiguration> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAgentToken = {
	data?: Maybe<AgentToken> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAlarmMailTask = {
	data?: Maybe<AlarmMailTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAlarm = {
	data?: Maybe<Alarm> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAlert = {
	data?: Maybe<Alert> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAlertTask = {
	data?: Maybe<AlertTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAnalyseScanByAzureAdTask = {
	data?: Maybe<AnalyseScanByAzureAdTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfApplicationAzureAd = {
	data?: Maybe<ApplicationAzureAd> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAppLog = {
	data?: Maybe<AppLog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAppointment = {
	data?: Maybe<Appointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfArticleKnowledgeCategory = {
	data?: Maybe<ArticleKnowledgeCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfArticleKnowledgeNote = {
	data?: Maybe<ArticleKnowledgeNote> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfArticleKnowledge = {
	data?: Maybe<ArticleKnowledge> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetAccount = {
	data?: Maybe<AssetAccount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetAcquisitionMode = {
	data?: Maybe<AssetAcquisitionMode> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetAcquisitionState = {
	data?: Maybe<AssetAcquisitionState> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetAffectedRule = {
	data?: Maybe<AssetAffectedRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetCategory = {
	data?: Maybe<AssetCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetCategoryRule = {
	data?: Maybe<AssetCategoryRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetContract = {
	data?: Maybe<AssetContract> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetCostCenter = {
	data?: Maybe<AssetCostCenter> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetLoan = {
	data?: Maybe<AssetLoan> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetModel = {
	data?: Maybe<AssetModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetModelStockEntry = {
	data?: Maybe<AssetModelStockEntry> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetPropertyTracking = {
	data?: Maybe<AssetPropertyTracking> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetProvisionalCommissioning = {
	data?: Maybe<AssetProvisionalCommissioning> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetStatus = {
	data?: Maybe<AssetStatus> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetTypeAccounting = {
	data?: Maybe<AssetTypeAccounting> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAssetTypeStaffing = {
	data?: Maybe<AssetTypeStaffing> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAsyncTask = {
	data?: Maybe<AsyncTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAxelData = {
	data?: Maybe<AxelData> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfBudgetCategory = {
	data?: Maybe<BudgetCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfBudget = {
	data?: Maybe<Budget> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfBusinessTime = {
	data?: Maybe<BusinessTime> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCampaignHistory = {
	data?: Maybe<CampaignHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCampaign = {
	data?: Maybe<Campaign> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfChannel = {
	data?: Maybe<Channel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCheckFileTask = {
	data?: Maybe<CheckFileTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCheckSizeFileTask = {
	data?: Maybe<CheckSizeFileTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCommand = {
	data?: Maybe<Command> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCommentaryModel = {
	data?: Maybe<CommentaryModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfConcernedProject = {
	data?: Maybe<ConcernedProject> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfConsumableMovement = {
	data?: Maybe<ConsumableMovement> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfContractCategory = {
	data?: Maybe<ContractCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfContract = {
	data?: Maybe<Contract> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCorrectionStockMovement = {
	data?: Maybe<CorrectionStockMovement> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCostCenter = {
	data?: Maybe<CostCenter> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCronJobHourlyTask = {
	data?: Maybe<CronJobHourlyTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfCustomField = {
	data?: Maybe<CustomField> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDashboard = {
	data?: Maybe<Dashboard> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDayOff = {
	data?: Maybe<DayOff> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDeleteFileTask = {
	data?: Maybe<DeleteFileTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDomain = {
	data?: Maybe<Domain> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDynamicPropertyField = {
	data?: Maybe<DynamicPropertyField> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDynamicPropertyGroup = {
	data?: Maybe<DynamicPropertyGroup> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfDynamicPropertyList = {
	data?: Maybe<DynamicPropertyList> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfEmailConnector = {
	data?: Maybe<EmailConnector> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfEmailCredential = {
	data?: Maybe<EmailCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfEmailPrototypeLog = {
	data?: Maybe<EmailPrototypeLog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfEmailPrototype = {
	data?: Maybe<EmailPrototype> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfEsxCredential = {
	data?: Maybe<EsxCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfExchangeConnector = {
	data?: Maybe<ExchangeConnector> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfFeedback = {
	data?: Maybe<Feedback> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfFieldMapping = {
	data?: Maybe<FieldMapping> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfFormDesigner = {
	data?: Maybe<FormDesigner> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfGroupAzureAd = {
	data?: Maybe<GroupAzureAd> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfIamtCredential = {
	data?: Maybe<IamtCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfImpact = {
	data?: Maybe<Impact> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfImportAuditTask = {
	data?: Maybe<ImportAuditTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type FileDescriptor = {
	name?: Maybe<string> /**  */
	data?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
} 

/**  */
export type ValidationError = {
	messageError?: Maybe<string> /**  */
	property?: Maybe<string> /**  */
	validationCategory: ValidationErrorCategory /**  */
} 

/**  */
export type ServiceSingleResultOfIncidentModel = {
	data?: Maybe<IncidentModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfIncident = {
	data?: Maybe<Incident> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfInheritLocationCharter = {
	data?: Maybe<InheritLocationCharter> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfInStockMovement = {
	data?: Maybe<InStockMovement> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfInterventionModel = {
	data?: Maybe<InterventionModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfIntervention = {
	data?: Maybe<Intervention> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfInventoryHistory = {
	data?: Maybe<InventoryHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLabelTemplate = {
	data?: Maybe<LabelTemplate> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLayoutModel = {
	data?: Maybe<LayoutModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLdapAnalyse = {
	data?: Maybe<LdapAnalyse> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLdapHistory = {
	data?: Maybe<LdapHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLdapOrganizationalUnit = {
	data?: Maybe<LdapOrganizationalUnit> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLifeCycleDestination = {
	data?: Maybe<LifeCycleDestination> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLifeCycleReason = {
	data?: Maybe<LifeCycleReason> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLifeCycleTicket = {
	data?: Maybe<LifeCycleTicket> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLinkAsset = {
	data?: Maybe<LinkAsset> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfListDesigner = {
	data?: Maybe<ListDesigner> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLoanAppointment = {
	data?: Maybe<LoanAppointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLoan = {
	data?: Maybe<Loan> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLoanStatus = {
	data?: Maybe<LoanStatus> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLocationCategory = {
	data?: Maybe<LocationCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLocationCharter = {
	data?: Maybe<LocationCharter> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLocation = {
	data?: Maybe<Location> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfMailSlurpBlackList = {
	data?: Maybe<MailSlurpBlackList> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfMailSlurpWebhookTask = {
	data?: Maybe<MailSlurpWebhookTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfMatrixPriority = {
	data?: Maybe<MatrixPriority> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfMessageModel = {
	data?: Maybe<MessageModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfMessage = {
	data?: Maybe<Message> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfModelManufacturer = {
	data?: Maybe<ModelManufacturer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfModelRulesDesigner = {
	data?: Maybe<ModelRulesDesigner> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfNamingConvention = {
	data?: Maybe<NamingConvention> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfNews = {
	data?: Maybe<News> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfNote = {
	data?: Maybe<Note> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfNotification = {
	data?: Maybe<Notification> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfObjectClassMapping = {
	data?: Maybe<ObjectClassMapping> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOneEventLog = {
	data?: Maybe<OneEventLog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOneSetting = {
	data?: Maybe<OneSetting> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOperatorTeam = {
	data?: Maybe<OperatorTeam> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrderBill = {
	data?: Maybe<OrderBill> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrder = {
	data?: Maybe<Order> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrganizationalUnitCategory = {
	data?: Maybe<OrganizationalUnitCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrganizationalUnit = {
	data?: Maybe<OrganizationalUnit> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfVerifaliaEmailCheck = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<VerifaliaEmailCheck>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrigin = {
	data?: Maybe<Origin> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOutStockMovement = {
	data?: Maybe<OutStockMovement> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPackage = {
	data?: Maybe<Package> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPat = {
	data?: Maybe<Pat> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPnpEntityBase = {
	data?: Maybe<PnpEntityBase> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPnpVendor = {
	data?: Maybe<PnpVendor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPopulation = {
	data?: Maybe<Population> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPriority = {
	data?: Maybe<Priority> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfPrivilege = {
	data?: Maybe<Privilege> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfProblem = {
	data?: Maybe<Problem> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfQueryBuilderAppointment = {
	data?: Maybe<QueryBuilderAppointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfQueryBuilderAppointmentTask = {
	data?: Maybe<QueryBuilderAppointmentTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfQueryBuilder = {
	data?: Maybe<QueryBuilder> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfReportCache = {
	data?: Maybe<ReportCache> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfReport = {
	data?: Maybe<Report> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfRequest = {
	data?: Maybe<Request> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfResolutionCategory = {
	data?: Maybe<ResolutionCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfResolutionModel = {
	data?: Maybe<ResolutionModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfResultScanAzureAd = {
	data?: Maybe<ResultScanAzureAd> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfRoleAccount = {
	data?: Maybe<RoleAccount> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfRole = {
	data?: Maybe<Role> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSatisfactionSetting = {
	data?: Maybe<SatisfactionSetting> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByAddressIPRange = {
	data?: Maybe<ScanByAddressIPRange> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByAzureAdAppointment = {
	data?: Maybe<ScanByAzureAdAppointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByAzureAdAppointmentTask = {
	data?: Maybe<ScanByAzureAdAppointmentTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByAzureAd = {
	data?: Maybe<ScanByAzureAd> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByAzureAdTask = {
	data?: Maybe<ScanByAzureAdTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByHost = {
	data?: Maybe<ScanByHost> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByLdap = {
	data?: Maybe<ScanByLdap> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByLocalAgent = {
	data?: Maybe<ScanByLocalAgent> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanByWorkgroup = {
	data?: Maybe<ScanByWorkgroup> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanConfigurationAppointment = {
	data?: Maybe<ScanConfigurationAppointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanConfiguration = {
	data?: Maybe<IScanConfiguration> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanDataFile = {
	data?: Maybe<ScanDataFile> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanExclude = {
	data?: Maybe<ScanExclude> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanImportOption = {
	data?: Maybe<ScanImportOption> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanMethodProfil = {
	data?: Maybe<ScanMethodProfil> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanMethod = {
	data?: Maybe<ScanMethod> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfScanRegistryKey = {
	data?: Maybe<ScanRegistryKey> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSecurityGroup = {
	data?: Maybe<SecurityGroup> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSecurityGroupRule = {
	data?: Maybe<SecurityGroupRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfServiceLevelAgreement = {
	data?: Maybe<ServiceLevelAgreement> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfServiceOffer = {
	data?: Maybe<ServiceOffer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSftpConnectorHistory = {
	data?: Maybe<SftpConnectorHistory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSftpConnector = {
	data?: Maybe<SftpConnector> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSnmpCredential = {
	data?: Maybe<SnmpCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSnmpMapInformationKb = {
	data?: Maybe<SnmpMapInformationKb> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSnmpMapInformation = {
	data?: Maybe<SnmpMapInformation> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSnmpVendor = {
	data?: Maybe<SnmpVendor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareAttributeType = {
	data?: Maybe<SoftwareAttributeType> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareConnectionMode = {
	data?: Maybe<SoftwareConnectionMode> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareGroup = {
	data?: Maybe<SoftwareGroup> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareHostedComponent = {
	data?: Maybe<SoftwareHostedComponent> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareHostedLink = {
	data?: Maybe<SoftwareHostedLink> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwarePackage = {
	data?: Maybe<SoftwarePackage> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareReferentLink = {
	data?: Maybe<SoftwareReferentLink> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftwareReferentType = {
	data?: Maybe<SoftwareReferentType> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSoftware = {
	data?: Maybe<Software> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSshCredential = {
	data?: Maybe<SshCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStatisticTask = {
	data?: Maybe<StatisticTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStatusWorkflow = {
	data?: Maybe<StatusWorkflow> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStockInReason = {
	data?: Maybe<StockInReason> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStockOutReason = {
	data?: Maybe<StockOutReason> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStockTransferReason = {
	data?: Maybe<StockTransferReason> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfStockTransfer = {
	data?: Maybe<StockTransfer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSubstitute = {
	data?: Maybe<Substitute> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfSupplier = {
	data?: Maybe<Supplier> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTaskSequence = {
	data?: Maybe<TaskSequence> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTaskStatus = {
	data?: Maybe<TaskStatus> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTaskStatusWorkflow = {
	data?: Maybe<TaskStatusWorkflow> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTemplate = {
	data?: Maybe<Template> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTermsContract = {
	data?: Maybe<TermsContract> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketAffectedRule = {
	data?: Maybe<TicketAffectedRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketAppointment = {
	data?: Maybe<TicketAppointment> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketCategory = {
	data?: Maybe<TicketCategory> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketDailyBacklog = {
	data?: Maybe<TicketDailyBacklog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketDurationTask = {
	data?: Maybe<TicketDurationTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketEmail = {
	data?: Maybe<TicketEmail> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketMonthlyBacklog = {
	data?: Maybe<TicketMonthlyBacklog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceListResultOfString = {
	totalCount: number /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
	data?: Array<Maybe<string>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketStatistic = {
	data?: Maybe<TicketStatistic> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketStatusReason = {
	data?: Maybe<TicketStatusReason> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketStatus = {
	data?: Maybe<TicketStatus> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketTag = {
	data?: Maybe<TicketTag> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketTagTask = {
	data?: Maybe<TicketTagTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketTaskModel = {
	data?: Maybe<TicketTaskModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketTask = {
	data?: Maybe<TicketTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketWeeklyBacklog = {
	data?: Maybe<TicketWeeklyBacklog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTicketYearlyBacklog = {
	data?: Maybe<TicketYearlyBacklog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTrackingEntity = {
	data?: Maybe<TrackingEntity> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfTracking = {
	data?: Maybe<Tracking> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfUrgency = {
	data?: Maybe<Urgency> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfUserAffectedRule = {
	data?: Maybe<UserAffectedRule> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfUserPrivilege = {
	data?: Maybe<UserPrivilege> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfUserRole = {
	data?: Maybe<UserRole> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfVerifaliaEmailCheck = {
	data?: Maybe<VerifaliaEmailCheck> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfVerifaliaTask = {
	data?: Maybe<VerifaliaTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWallSocket = {
	data?: Maybe<WallSocket> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWarningCustomer = {
	data?: Maybe<WarningCustomer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWarningMessage = {
	data?: Maybe<WarningMessage> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWarningViewCustomer = {
	data?: Maybe<WarningViewCustomer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWarrantyLinkVendor = {
	data?: Maybe<WarrantyLinkVendor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWmiCredential = {
	data?: Maybe<WmiCredential> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowDelete = {
	data?: Maybe<WorkflowDelete> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowHttpRequest = {
	data?: Maybe<WorkflowHttpRequest> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowIntervalTask = {
	data?: Maybe<WorkflowIntervalTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowInvitation = {
	data?: Maybe<WorkflowInvitation> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowInvitationTask = {
	data?: Maybe<WorkflowInvitationTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowLog = {
	data?: Maybe<WorkflowLog> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowMail = {
	data?: Maybe<WorkflowMail> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowMailTask = {
	data?: Maybe<WorkflowMailTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowManaged = {
	data?: Maybe<WorkflowManaged> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowNotificationTeams = {
	data?: Maybe<WorkflowNotificationTeams> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowProperty = {
	data?: Maybe<WorkflowProperty> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowRevocation = {
	data?: Maybe<WorkflowRevocation> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowRevocationWithoutEmail = {
	data?: Maybe<WorkflowRevocationWithoutEmail> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflow = {
	data?: Maybe<Workflow> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkflowScheduleTask = {
	data?: Maybe<WorkflowScheduleTask> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfWorkTime = {
	data?: Maybe<WorkTime> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfOrganization = {
	data?: Maybe<Organization> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfClarilogServer = {
	data?: Maybe<ClarilogServer> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfAsset = {
	data?: Maybe<Asset> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfFileDescriptor = {
	data?: Maybe<FileDescriptor> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfLicenseModel = {
	data?: Maybe<LicenseModel> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfString = {
	data?: Maybe<string> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfUser = {
	data?: Maybe<User> /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfGuid = {
	data: string /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 

/**  */
export type ServiceSingleResultOfBoolean = {
	data: boolean /**  */
	errors?: Array<Maybe<ValidationError>> /**  */
} 


/**  */
export type FilterOfAlarm = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterAlarmCategoryEnumOperator> /**  */
	triggerType?: Maybe<FilterAlarmTriggerTypeEnumOperator> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	managedName?: Maybe<FilterManagedAlarmEnumOperator> /**  */
	interval?: Maybe<FilterInt32Operator> /**  */
	time?: Maybe<FilterDateTimeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	emailPrototypeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	allRecipient?: Maybe<FilterBooleanOperator> /**  */
	forceShowAlert?: Maybe<FilterBooleanOperator> /**  */
	recipientproperties?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAlarm>> /**  */
	or?: Array<Maybe<FilterOfAlarm>> /**  */
	nor?: Array<Maybe<FilterOfAlarm>> /**  */
	not?: Array<Maybe<FilterOfAlarm>> /**  */
}


/**  */
export type FilterOfTranslatedField = {
	fr?: Maybe<FilterStringOperator> /**  */
	nl?: Maybe<FilterStringOperator> /**  */
	en?: Maybe<FilterStringOperator> /**  */
	it?: Maybe<FilterStringOperator> /**  */
	de?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTranslatedField>> /**  */
	or?: Array<Maybe<FilterOfTranslatedField>> /**  */
	nor?: Array<Maybe<FilterOfTranslatedField>> /**  */
	not?: Array<Maybe<FilterOfTranslatedField>> /**  */
}


/**  */
export type FilterStringOperator = {
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	startsWith?: Maybe<string> /**  */
	endsWith?: Maybe<string> /**  */
	regex?: Maybe<RegexOperator> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	in?: Array<Maybe<string>> /**  */
	nin?: Array<Maybe<string>> /**  */
}


/**  */
export type RegexOperator = {
	pattern?: Maybe<string> /**  */
	options?: Maybe<string> /**  */
}


/**  */
export type FilterAlarmCategoryEnumOperator = {
	eq?: Maybe<AlarmCategoryEnum> /**  */
	ne?: Maybe<AlarmCategoryEnum> /**  */
	in?: Array<Maybe<AlarmCategoryEnum>> /**  */
	nin?: Array<Maybe<AlarmCategoryEnum>> /**  */
}


/**  */
export type FilterAlarmTriggerTypeEnumOperator = {
	eq?: Maybe<AlarmTriggerTypeEnum> /**  */
	ne?: Maybe<AlarmTriggerTypeEnum> /**  */
	in?: Array<Maybe<AlarmTriggerTypeEnum>> /**  */
	nin?: Array<Maybe<AlarmTriggerTypeEnum>> /**  */
}


/**  */
export type ArrayQueryFilterOperator = {
	elemMatch?: Maybe<FilterOfQueryFilter> /**  */
}


/**  */
export type FilterOfQueryFilter = {
	keyName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	operation?: Maybe<FilterStringOperator> /**  */
	groupOperation?: Maybe<FilterStringOperator> /**  */
	groups?: Maybe<ArrayQueryFilterOperator> /**  */
	and?: Array<Maybe<FilterOfQueryFilter>> /**  */
	or?: Array<Maybe<FilterOfQueryFilter>> /**  */
	nor?: Array<Maybe<FilterOfQueryFilter>> /**  */
	not?: Array<Maybe<FilterOfQueryFilter>> /**  */
}


/**  */
export type FilterBooleanOperator = {
	eq?: Maybe<boolean> /**  */
	ne?: Maybe<boolean> /**  */
}


/**  */
export type FilterManagedAlarmEnumOperator = {
	eq?: Maybe<ManagedAlarmEnum> /**  */
	ne?: Maybe<ManagedAlarmEnum> /**  */
	in?: Array<Maybe<ManagedAlarmEnum>> /**  */
	nin?: Array<Maybe<ManagedAlarmEnum>> /**  */
}


/**  */
export type FilterInt32Operator = {
	eq?: Maybe<number> /**  */
	ne?: Maybe<number> /**  */
	in?: Array<Maybe<number>> /**  */
	nin?: Array<Maybe<number>> /**  */
	gt?: Maybe<number> /**  */
	gte?: Maybe<number> /**  */
	lt?: Maybe<number> /**  */
	lte?: Maybe<number> /**  */
	mod?: Maybe<ModOperator> /**  */
	regex?: Maybe<RegexOperator> /**  */
}


/**  */
export type ModOperator = {
	divisor: number /**  */
	remainder?: Maybe<number> /**  */
}


/**  */
export type FilterDateTimeOperator = {
	eq?: Maybe<Date> /**  */
	ne?: Maybe<Date> /**  */
	in?: Array<Maybe<Date>> /**  */
	nin?: Array<Maybe<Date>> /**  */
	gt?: Maybe<Date> /**  */
	gte?: Maybe<Date> /**  */
	lt?: Maybe<Date> /**  */
	lte?: Maybe<Date> /**  */
}


/**  */
export type ArrayUuidTypeOperator = {
	all?: Array<Maybe<string>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterGuidOperator = {
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	in?: Array<Maybe<string>> /**  */
	nin?: Array<Maybe<string>> /**  */
}


/**  */
export type ArraySecurityGroupObjectOperator = {
	elemMatch?: Maybe<FilterOfSecurityGroupObject> /**  */
}


/**  */
export type FilterOfSecurityGroupObject = {
	id?: Maybe<FilterGuidOperator> /**  */
	isManual?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfSecurityGroupObject>> /**  */
	or?: Array<Maybe<FilterOfSecurityGroupObject>> /**  */
	nor?: Array<Maybe<FilterOfSecurityGroupObject>> /**  */
	not?: Array<Maybe<FilterOfSecurityGroupObject>> /**  */
}


/**  */
export type ArrayStringTypeOperator = {
	all?: Array<Maybe<string>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfAlarm = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAlarm>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Alarm */
export type QuerySortTypeAlarm = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	type?: Maybe<Sort> /** Représente le type de trie. */
	category?: Maybe<Sort> /** Représente le type de trie. */
	triggerType?: Maybe<Sort> /** Représente le type de trie. */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	managedName?: Maybe<Sort> /** Représente le type de trie. */
	interval?: Maybe<Sort> /** Représente le type de trie. */
	time?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	emailPrototypeIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	allRecipient?: Maybe<Sort> /** Représente le type de trie. */
	forceShowAlert?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TranslatedField */
export type QuerySortTypeTranslatedField = {
	fr?: Maybe<Sort> /** Représente le type de trie. */
	nl?: Maybe<Sort> /** Représente le type de trie. */
	en?: Maybe<Sort> /** Représente le type de trie. */
	it?: Maybe<Sort> /** Représente le type de trie. */
	de?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SecurityGroupObject */
export type QuerySortTypeSecurityGroupObject = {
	isManual?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSecurityGroup = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	memberLocationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	memberOrganizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	memberDistinguishedNameGroups?: Maybe<ArrayStringTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	securityGroupRuleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	isManual?: Maybe<FilterBooleanOperator> /**  */
	category?: Maybe<FilterSecurityGroupCategoryOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSecurityGroup>> /**  */
	or?: Array<Maybe<FilterOfSecurityGroup>> /**  */
	nor?: Array<Maybe<FilterOfSecurityGroup>> /**  */
	not?: Array<Maybe<FilterOfSecurityGroup>> /**  */
}


/**  */
export type FilterSecurityGroupCategoryOperator = {
	eq?: Maybe<SecurityGroupCategory> /**  */
	ne?: Maybe<SecurityGroupCategory> /**  */
	in?: Array<Maybe<SecurityGroupCategory>> /**  */
	nin?: Array<Maybe<SecurityGroupCategory>> /**  */
}


/**  */
export type QueryContextOfSecurityGroup = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSecurityGroup>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SecurityGroup */
export type QuerySortTypeSecurityGroup = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	memberLocationIds?: Maybe<Sort> /** Représente le type de trie. */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	memberOrganizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	securityGroupRuleIds?: Maybe<Sort> /** Représente le type de trie. */
	isManual?: Maybe<Sort> /** Représente le type de trie. */
	category?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTracking = {
	inventoryHistoryId?: Maybe<FilterGuidOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	entityState?: Maybe<FilterEntityStateOperator> /**  */
	entityPropertyName?: Maybe<FilterStringOperator> /**  */
	entityName?: Maybe<FilterStringOperator> /**  */
	entitySourceId?: Maybe<FilterGuidOperator> /**  */
	entitySourceName?: Maybe<FilterStringOperator> /**  */
	system?: Maybe<FilterBooleanOperator> /**  */
	authorId?: Maybe<FilterGuidOperator> /**  */
	author?: Maybe<FilterDenormalizeOfUserByAuthorInTracking> /**  */
	propertyKeyValue?: Maybe<FilterStringOperator> /**  */
	changes?: Maybe<ArrayTrackingChangeOperator> /**  */
	values?: Maybe<ArrayTrackingValueOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTracking>> /**  */
	or?: Array<Maybe<FilterOfTracking>> /**  */
	nor?: Array<Maybe<FilterOfTracking>> /**  */
	not?: Array<Maybe<FilterOfTracking>> /**  */
}


/**  */
export type FilterEntityStateOperator = {
	eq?: Maybe<EntityState> /**  */
	ne?: Maybe<EntityState> /**  */
	in?: Array<Maybe<EntityState>> /**  */
	nin?: Array<Maybe<EntityState>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByAuthorInTracking = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type ArrayTrackingChangeOperator = {
	elemMatch?: Maybe<FilterOfTrackingChange> /**  */
}


/**  */
export type FilterOfTrackingChange = {
	name?: Maybe<FilterStringOperator> /**  */
	oldTrackingEntity?: Maybe<FilterOfTrackingEntity> /**  */
	newTrackingEntity?: Maybe<FilterOfTrackingEntity> /**  */
	isTranslatable?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTrackingChange>> /**  */
	or?: Array<Maybe<FilterOfTrackingChange>> /**  */
	nor?: Array<Maybe<FilterOfTrackingChange>> /**  */
	not?: Array<Maybe<FilterOfTrackingChange>> /**  */
}


/**  */
export type FilterOfTrackingEntity = {
	name?: Maybe<FilterStringOperator> /**  */
	ids?: Maybe<ArrayUuidTypeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	isTranslatable?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTrackingEntity>> /**  */
	or?: Array<Maybe<FilterOfTrackingEntity>> /**  */
	nor?: Array<Maybe<FilterOfTrackingEntity>> /**  */
	not?: Array<Maybe<FilterOfTrackingEntity>> /**  */
}


/**  */
export type ArrayTrackingValueOperator = {
	elemMatch?: Maybe<FilterOfTrackingValue> /**  */
}


/**  */
export type FilterOfTrackingValue = {
	id?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTrackingValue>> /**  */
	or?: Array<Maybe<FilterOfTrackingValue>> /**  */
	nor?: Array<Maybe<FilterOfTrackingValue>> /**  */
	not?: Array<Maybe<FilterOfTrackingValue>> /**  */
}


/**  */
export type QueryContextOfTracking = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTracking>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Tracking */
export type QuerySortTypeTracking = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	entityState?: Maybe<Sort> /** Représente le type de trie. */
	entityPropertyName?: Maybe<Sort> /** Représente le type de trie. */
	entityName?: Maybe<Sort> /** Représente le type de trie. */
	entitySourceName?: Maybe<Sort> /** Représente le type de trie. */
	system?: Maybe<Sort> /** Représente le type de trie. */
	author?: Array<Maybe<QuerySortTypeUser>> /**  */
	propertyKeyValue?: Maybe<Sort> /** Représente le type de trie. */
	changes?: Maybe<Sort> /** Représente le type de trie. */
	values?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité User */
export type QuerySortTypeUser = {
	firstName?: Maybe<Sort> /** Représente le type de trie. */
	lastName?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControl?: Maybe<Sort> /** Représente le type de trie. */
	userAccountDisabled?: Maybe<Sort> /** Représente le type de trie. */
	userAccountExpires?: Maybe<Sort> /** Représente le type de trie. */
	initial?: Maybe<Sort> /** Représente le type de trie. */
	registrationNumber?: Maybe<Sort> /** Représente le type de trie. */
	vip?: Maybe<Sort> /** Représente le type de trie. */
	helpDeskActivated?: Maybe<Sort> /** Représente le type de trie. */
	function?: Maybe<Sort> /** Représente le type de trie. */
	instantMessaging?: Maybe<Sort> /** Représente le type de trie. */
	userPrincipalName?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	secretary?: Maybe<Sort> /** Représente le type de trie. */
	hiringDate?: Maybe<Sort> /** Représente le type de trie. */
	exitDate?: Maybe<Sort> /** Représente le type de trie. */
	lastLogOn?: Maybe<Sort> /** Représente le type de trie. */
	lastLogonTimestamp?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnInteractive?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnNotInteractive?: Maybe<Sort> /** Représente le type de trie. */
	lastLogOff?: Maybe<Sort> /** Représente le type de trie. */
	lastConnected?: Maybe<Sort> /** Représente le type de trie. */
	accessFailedCount?: Maybe<Sort> /** Représente le type de trie. */
	homeDrive?: Maybe<Sort> /** Représente le type de trie. */
	homeDirectory?: Maybe<Sort> /** Représente le type de trie. */
	logonCount?: Maybe<Sort> /** Représente le type de trie. */
	profilePath?: Maybe<Sort> /** Représente le type de trie. */
	scriptPath?: Maybe<Sort> /** Représente le type de trie. */
	logOnTo?: Maybe<Sort> /** Représente le type de trie. */
	allowDialIn?: Maybe<Sort> /** Représente le type de trie. */
	officeName?: Maybe<Sort> /** Représente le type de trie. */
	principalWebSite?: Maybe<Sort> /** Représente le type de trie. */
	settings?: Array<Maybe<QuerySortTypeSettings>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userPasswords?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamIds?: Maybe<Sort> /** Représente le type de trie. */
	mainOperatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	isOperator?: Maybe<Sort> /** Représente le type de trie. */
	loans?: Maybe<Sort> /** Représente le type de trie. */
	ticketsIds?: Maybe<Sort> /** Représente le type de trie. */
	userHabilitations?: Maybe<Sort> /** Représente le type de trie. */
	phones?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	locations?: Maybe<Sort> /** Représente le type de trie. */
	stockManagers?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	department?: Maybe<Sort> /** Représente le type de trie. */
	company?: Maybe<Sort> /** Représente le type de trie. */
	manager?: Maybe<Sort> /** Représente le type de trie. */
	managerRef?: Array<Maybe<QuerySortTypeUser>> /**  */
	userManager?: Array<Maybe<QuerySortTypeUser>> /**  */
	supplier?: Array<Maybe<QuerySortTypeSupplier>> /**  */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	containerDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	firstImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportAzureAd?: Maybe<Sort> /** Représente le type de trie. */
	scanByLdap?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	principalPhone?: Maybe<Sort> /** Représente le type de trie. */
	principalFax?: Maybe<Sort> /** Représente le type de trie. */
	principalHomePhone?: Maybe<Sort> /** Représente le type de trie. */
	principalIpPhone?: Maybe<Sort> /** Représente le type de trie. */
	principalMobile?: Maybe<Sort> /** Représente le type de trie. */
	principalPager?: Maybe<Sort> /** Représente le type de trie. */
	memberOfAzureGroup?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	invitation?: Array<Maybe<QuerySortTypeInvitation>> /**  */
	category?: Maybe<Sort> /** Représente le type de trie. */
	userAffectedRule?: Array<Maybe<QuerySortTypeUserAffectedRule>> /**  */
	populationsIds?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	forceLdapImport?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	memberOfSecurityGroupIds?: Maybe<Sort> /** Représente le type de trie. */
	memberOfPopulationIds?: Maybe<Sort> /** Représente le type de trie. */
	softwarePackageIds?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	inviteBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	revokeBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	costHours?: Maybe<Sort> /** Représente le type de trie. */
	supportLevelOperator?: Maybe<Sort> /** Représente le type de trie. */
	azureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	managerAzureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	mainAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	inheritLocationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	inheritLocationCharterLocation?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	inheritLocationCharterOrganizationalUnit?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	entityLocationCharter?: Array<Maybe<QuerySortTypeEntityLocationCharter>> /**  */
	privilege?: Array<Maybe<QuerySortTypePrivilege>> /**  */
	userPrivilege?: Array<Maybe<QuerySortTypeUserPrivilege>> /**  */
	locationCharterAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	securityGroupAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	populationAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	calendar?: Array<Maybe<QuerySortTypeCalendarConnector>> /**  */
	modelRulesDesignerIds?: Maybe<Sort> /** Représente le type de trie. */
	formDesignerIds?: Maybe<Sort> /** Représente le type de trie. */
	serviceDeskActivated?: Maybe<Sort> /** Représente le type de trie. */
	dashboardIds?: Maybe<Sort> /** Représente le type de trie. */
	allowEditOrganization?: Maybe<Sort> /** Représente le type de trie. */
	recipentSystemNotification?: Maybe<Sort> /** Représente le type de trie. */
	businessTime?: Array<Maybe<QuerySortTypeBusinessTime>> /**  */
	userContractType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	memberOfIds?: Maybe<Sort> /** Représente le type de trie. */
	permissionScopes?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Settings */
export type QuerySortTypeSettings = {
	features?: Maybe<Sort> /** Représente le type de trie. */
	preferences?: Array<Maybe<QuerySortTypePreferences>> /**  */
}


/** Représente les propriétés triables de l'entité Preferences */
export type QuerySortTypePreferences = {
	lang?: Maybe<Sort> /** Représente le type de trie. */
	theme?: Maybe<Sort> /** Représente le type de trie. */
	dashboardId?: Maybe<Sort> /** Représente le type de trie. */
	paginate?: Maybe<Sort> /** Représente le type de trie. */
	layouts?: Maybe<Sort> /** Représente le type de trie. */
	defaultLayouts?: Maybe<Sort> /** Représente le type de trie. */
	pinStateMenu?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OrganizationalUnit */
export type QuerySortTypeOrganizationalUnit = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	error?: Maybe<Sort> /** Représente le type de trie. */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	manager?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	path?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	allRulesMustBeValidAsset?: Maybe<Sort> /** Représente le type de trie. */
	allRulesMustBeValidUser?: Maybe<Sort> /** Représente le type de trie. */
	firstImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	budgetIds?: Maybe<Sort> /** Représente le type de trie. */
	allowSelection?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Address */
export type QuerySortTypeAddress = {
	street?: Maybe<Sort> /** Représente le type de trie. */
	additional?: Maybe<Sort> /** Représente le type de trie. */
	city?: Maybe<Sort> /** Représente le type de trie. */
	province?: Maybe<Sort> /** Représente le type de trie. */
	country?: Maybe<Sort> /** Représente le type de trie. */
	zipCode?: Maybe<Sort> /** Représente le type de trie. */
	postalCode?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EntityAttributes */
export type QuerySortTypeEntityAttributes = {
	created?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	updated?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	deleted?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	locked?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	archived?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
}


/** Représente les propriétés triables de l'entité EntityAttribute */
export type QuerySortTypeEntityAttribute = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	username?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OperatorTeam */
export type QuerySortTypeOperatorTeam = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketStatusReasonIds?: Maybe<Sort> /** Représente le type de trie. */
	default?: Maybe<Sort> /** Représente le type de trie. */
	allowedTicketsCategories?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryVisible?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryAllowed?: Maybe<Sort> /** Représente le type de trie. */
	allowedOperatorTeamIds?: Maybe<Sort> /** Représente le type de trie. */
	allowSelection?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Location */
export type QuerySortTypeLocation = {
	error?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	allowSelection?: Maybe<Sort> /** Représente le type de trie. */
	stock?: Maybe<Sort> /** Représente le type de trie. */
	maxCapacity?: Maybe<Sort> /** Représente le type de trie. */
	rfid?: Maybe<Sort> /** Représente le type de trie. */
	barCode?: Maybe<Sort> /** Représente le type de trie. */
	path?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	firstImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	allRulesMustBeValidAsset?: Maybe<Sort> /** Représente le type de trie. */
	allRulesMustBeValidUser?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	phone?: Maybe<Sort> /** Représente le type de trie. */
	principalWebSite?: Maybe<Sort> /** Représente le type de trie. */
	budgetIds?: Maybe<Sort> /** Représente le type de trie. */
	loanIds?: Maybe<Sort> /** Représente le type de trie. */
	roomNumber?: Maybe<Sort> /** Représente le type de trie. */
	loanable?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	loan?: Array<Maybe<QuerySortTypeLoan>> /**  */
	assetModelStockEntryIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Loan */
export type QuerySortTypeLoan = {
	number?: Maybe<Sort> /** Représente le type de trie. */
	slipNumber?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	provision?: Maybe<Sort> /** Représente le type de trie. */
	returnDate?: Maybe<Sort> /** Représente le type de trie. */
	realReturnDate?: Maybe<Sort> /** Représente le type de trie. */
	duration?: Maybe<Sort> /** Représente le type de trie. */
	information?: Maybe<Sort> /** Représente le type de trie. */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	accountIds?: Maybe<Sort> /** Représente le type de trie. */
	recordDate?: Maybe<Sort> /** Représente le type de trie. */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	manager?: Array<Maybe<QuerySortTypeUser>> /**  */
	loanStatus?: Array<Maybe<QuerySortTypeLoanStatus>> /**  */
	loanBorrowerType?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LoanStatus */
export type QuerySortTypeLoanStatus = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Supplier */
export type QuerySortTypeSupplier = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	naf?: Maybe<Sort> /** Représente le type de trie. */
	siret?: Maybe<Sort> /** Représente le type de trie. */
	intraCommunity?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	webSite?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	tva?: Maybe<Sort> /** Représente le type de trie. */
	contactIds?: Maybe<Sort> /** Représente le type de trie. */
	phone?: Maybe<Sort> /** Représente le type de trie. */
	fax?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	serviceOfferIds?: Maybe<Sort> /** Représente le type de trie. */
	assetModelIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanConfiguration */
export type QuerySortTypeScanConfiguration = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	applicationAzureAd?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /**  */
	importDisabledUser?: Maybe<Sort> /** Représente le type de trie. */
	importMember?: Maybe<Sort> /** Représente le type de trie. */
	importGuest?: Maybe<Sort> /** Représente le type de trie. */
	importNoType?: Maybe<Sort> /** Représente le type de trie. */
	importGroup?: Maybe<Sort> /** Représente le type de trie. */
	recursiveGroup?: Maybe<Sort> /** Représente le type de trie. */
	addressIpStart?: Maybe<Sort> /** Représente le type de trie. */
	addressIpEnd?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	ldapCredential?: Array<Maybe<QuerySortTypeNetworkCredential>> /**  */
	useSsl?: Maybe<Sort> /** Représente le type de trie. */
	usePort?: Maybe<Sort> /** Représente le type de trie. */
	protocolVersion?: Maybe<Sort> /** Représente le type de trie. */
	connectionTimeoutIn?: Maybe<Sort> /** Représente le type de trie. */
	ldapAuthType?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	importUser?: Maybe<Sort> /** Représente le type de trie. */
	importRecursiveGroup?: Maybe<Sort> /** Représente le type de trie. */
	importComputer?: Maybe<Sort> /** Représente le type de trie. */
	importLdapStruct?: Maybe<Sort> /** Représente le type de trie. */
	disableUsers?: Maybe<Sort> /** Représente le type de trie. */
	importOrganizationalUnit?: Maybe<Sort> /** Représente le type de trie. */
	forceSearchFilter?: Maybe<Sort> /** Représente le type de trie. */
	fieldMapping?: Array<Maybe<QuerySortTypeFieldMapping>> /**  */
	objectClassMapping?: Array<Maybe<QuerySortTypeObjectClassMapping>> /**  */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	workgroupName?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AuditOption */
export type QuerySortTypeAuditOption = {
	notAuditedType?: Maybe<Sort> /** Représente le type de trie. */
	sinceDay?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité PowerControl */
export type QuerySortTypePowerControl = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	hour?: Maybe<Sort> /** Représente le type de trie. */
	autoPurge?: Maybe<Sort> /** Représente le type de trie. */
	since?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Wol */
export type QuerySortTypeWol = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	timeOut?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ShutDown */
export type QuerySortTypeShutDown = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	onlyNotError?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanMethodProfil */
export type QuerySortTypeScanMethodProfil = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	scanMethodIds?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ClarilogServer */
export type QuerySortTypeClarilogServer = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	lastStart?: Maybe<Sort> /** Représente le type de trie. */
	lastCheck?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	osVersion?: Maybe<Sort> /** Représente le type de trie. */
	simultaneousScan?: Maybe<Sort> /** Représente le type de trie. */
	scanHistoryOption?: Array<Maybe<QuerySortTypeScanHistoryOption>> /**  */
	clarilogServerLogs?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtName?: Maybe<Sort> /** Représente le type de trie. */
	updater?: Array<Maybe<QuerySortTypeUpdater>> /**  */
	clarilogAgentSettings?: Array<Maybe<QuerySortTypeClarilogAgentSettings>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanHistoryOption */
export type QuerySortTypeScanHistoryOption = {
	keepSuccessAudit?: Maybe<Sort> /** Représente le type de trie. */
	delayBeforePurge?: Maybe<Sort> /** Représente le type de trie. */
	keepLastDayForEventLog?: Maybe<Sort> /** Représente le type de trie. */
	keepLastDayForLogon?: Maybe<Sort> /** Représente le type de trie. */
	keepLastDayForUptimeCalendar?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Updater */
export type QuerySortTypeUpdater = {
	lastDownload?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdate?: Maybe<Sort> /** Représente le type de trie. */
	lastInstall?: Maybe<Sort> /** Représente le type de trie. */
	updateState?: Maybe<Sort> /** Représente le type de trie. */
	lastError?: Maybe<Sort> /** Représente le type de trie. */
	countAttempt?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ClarilogAgentSettings */
export type QuerySortTypeClarilogAgentSettings = {
	lastVersion?: Maybe<Sort> /** Représente le type de trie. */
	pendingDelay?: Maybe<Sort> /** Représente le type de trie. */
	pingDelay?: Maybe<Sort> /** Représente le type de trie. */
	stopService?: Maybe<Sort> /** Représente le type de trie. */
	restartService?: Maybe<Sort> /** Représente le type de trie. */
	disableService?: Maybe<Sort> /** Représente le type de trie. */
	forceToken?: Maybe<Sort> /** Représente le type de trie. */
	canBeUpdate?: Maybe<Sort> /** Représente le type de trie. */
	blockIfNotLastVersion?: Maybe<Sort> /** Représente le type de trie. */
	disabledApiQuery?: Maybe<Sort> /** Représente le type de trie. */
	disabledApiMutation?: Maybe<Sort> /** Représente le type de trie. */
	apiServer?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ApplicationAzureAd */
export type QuerySortTypeApplicationAzureAd = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	tenantAzureId?: Maybe<Sort> /** Représente le type de trie. */
	clientId?: Maybe<Sort> /** Représente le type de trie. */
	clientSecret?: Maybe<Sort> /** Représente le type de trie. */
	expireClientSecretDate?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	environnement?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité NetworkCredential */
export type QuerySortTypeNetworkCredential = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	webService?: Maybe<Sort> /** Représente le type de trie. */
	tls?: Maybe<Sort> /** Représente le type de trie. */
	community?: Maybe<Sort> /** Représente le type de trie. */
	snmpVersion?: Maybe<Sort> /** Représente le type de trie. */
	authentificationSnmpV3?: Maybe<Sort> /** Représente le type de trie. */
	encryptionSnmpV3?: Maybe<Sort> /** Représente le type de trie. */
	encryptionKey?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FieldMapping */
export type QuerySortTypeFieldMapping = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	ldapFieldMappings?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ObjectClassMapping */
export type QuerySortTypeObjectClassMapping = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	ldapObjectClassMappings?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Invitation */
export type QuerySortTypeInvitation = {
	sendDate?: Maybe<Sort> /** Représente le type de trie. */
	acceptDate?: Maybe<Sort> /** Représente le type de trie. */
	revokeDate?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité UserAffectedRule */
export type QuerySortTypeUserAffectedRule = {
	byDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	bySid?: Maybe<Sort> /** Représente le type de trie. */
	byEmail?: Maybe<Sort> /** Représente le type de trie. */
	byDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	byUserPrincipalName?: Maybe<Sort> /** Représente le type de trie. */
	byLastNameAndFirstName?: Maybe<Sort> /** Représente le type de trie. */
	byDomain?: Maybe<Sort> /** Représente le type de trie. */
	byDifferentDomain?: Maybe<Sort> /** Représente le type de trie. */
	byAzureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	byDomainEnum?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	searchInDeleted?: Maybe<Sort> /** Représente le type de trie. */
	searchInArchived?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Asset */
export type QuerySortTypeAsset = {
	isAudited?: Maybe<Sort> /** Représente le type de trie. */
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	assetStatus?: Array<Maybe<QuerySortTypeAssetStatus>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	fqdn?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	power?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	model?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	skuNumber?: Maybe<Sort> /** Représente le type de trie. */
	uuid?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	inventoryNumber?: Maybe<Sort> /** Représente le type de trie. */
	barCode?: Maybe<Sort> /** Représente le type de trie. */
	rfid?: Maybe<Sort> /** Représente le type de trie. */
	netBios?: Maybe<Sort> /** Représente le type de trie. */
	comment?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	firstAudit?: Maybe<Sort> /** Représente le type de trie. */
	lastAudit?: Maybe<Sort> /** Représente le type de trie. */
	lastSuccessAudit?: Maybe<Sort> /** Représente le type de trie. */
	auditState?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	phoneAsset?: Maybe<Sort> /** Représente le type de trie. */
	owner?: Array<Maybe<QuerySortTypeUser>> /**  */
	assetAffectedRule?: Array<Maybe<QuerySortTypeAssetAffectedRule>> /**  */
	assetCategoryRule?: Array<Maybe<QuerySortTypeAssetCategoryRule>> /**  */
	scanConfiguration?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	inventoryHistory?: Array<Maybe<QuerySortTypeInventoryHistory>> /**  */
	lifeCycle?: Array<Maybe<QuerySortTypeLifeCycle>> /**  */
	mainOf?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	lendable?: Maybe<Sort> /** Représente le type de trie. */
	featureComment?: Maybe<Sort> /** Représente le type de trie. */
	featureMobility?: Array<Maybe<QuerySortTypeFeatureMobility>> /**  */
	featureSimCard?: Array<Maybe<QuerySortTypeFeatureSimCard>> /**  */
	featureMonitor?: Array<Maybe<QuerySortTypeFeatureMonitor>> /**  */
	featureUps?: Array<Maybe<QuerySortTypeFeatureUps>> /**  */
	featureScanner?: Array<Maybe<QuerySortTypeFeatureScanner>> /**  */
	featureWebcam?: Array<Maybe<QuerySortTypeFeatureWebcam>> /**  */
	featureModem?: Array<Maybe<QuerySortTypeFeatureModem>> /**  */
	featureTerminal?: Array<Maybe<QuerySortTypeFeatureTerminal>> /**  */
	featureSwitch?: Array<Maybe<QuerySortTypeFeatureSwitch>> /**  */
	featureNetwork?: Array<Maybe<QuerySortTypeFeatureNetwork>> /**  */
	featurePrinter?: Array<Maybe<QuerySortTypeFeaturePrinter>> /**  */
	featureVideoProjector?: Array<Maybe<QuerySortTypeFeatureVideoProjector>> /**  */
	featureMobile?: Array<Maybe<QuerySortTypeFeatureMobile>> /**  */
	firstMobileDevices?: Maybe<Sort> /** Représente le type de trie. */
	secondMobileDevices?: Maybe<Sort> /** Représente le type de trie. */
	featureTelephony?: Array<Maybe<QuerySortTypeFeatureTelephony>> /**  */
	createdModel?: Array<Maybe<QuerySortTypeAssetModel>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	outStockIds?: Maybe<Sort> /** Représente le type de trie. */
	stockTransfersIds?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	detectWarranty?: Array<Maybe<QuerySortTypeDetectWarranty>> /**  */
	financial?: Array<Maybe<QuerySortTypeAssetFinancial>> /**  */
	loan?: Array<Maybe<QuerySortTypeLoan>> /**  */
	assetNamingConvention?: Array<Maybe<QuerySortTypeAssetNamingConvention>> /**  */
	entityLocationCharter?: Array<Maybe<QuerySortTypeEntityLocationCharter>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	auditResult?: Array<Maybe<QuerySortTypeAuditResult>> /**  */
	operatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	mainOperatingSystem?: Array<Maybe<QuerySortTypeOperatingSystem>> /**  */
	memories?: Maybe<Sort> /** Représente le type de trie. */
	processor?: Maybe<Sort> /** Représente le type de trie. */
	warrantyStart?: Maybe<Sort> /** Représente le type de trie. */
	warrantyEnd?: Maybe<Sort> /** Représente le type de trie. */
	loanable?: Maybe<Sort> /** Représente le type de trie. */
	groupIds?: Maybe<Sort> /** Représente le type de trie. */
	loanIds?: Maybe<Sort> /** Représente le type de trie. */
	inheritLocationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	softwarePackageIds?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	commandIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	deliveryAsset?: Array<Maybe<QuerySortTypeAssetDelivery>> /**  */
	lastCalcRenewDate?: Maybe<Sort> /** Représente le type de trie. */
	campaignIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	clarilogLocalAgent?: Array<Maybe<QuerySortTypeClarilogLocalAgent>> /**  */
	ticketIds?: Maybe<Sort> /** Représente le type de trie. */
	mainImpactedTicketIds?: Maybe<Sort> /** Représente le type de trie. */
	assetModelIds?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	inheritLocationCharterLocation?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	inheritLocationCharterOrganizationalUnit?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetCategory */
export type QuerySortTypeAssetCategory = {
	lifetime?: Array<Maybe<QuerySortTypeAssetLifetime>> /**  */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	defaultPower?: Maybe<Sort> /** Représente le type de trie. */
	stockageDurationMax?: Maybe<Sort> /** Représente le type de trie. */
	lifeDuration?: Maybe<Sort> /** Représente le type de trie. */
	snmpType?: Maybe<Sort> /** Représente le type de trie. */
	decayRate?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	path?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	autoLaunchCommand?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	error?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetLifetime */
export type QuerySortTypeAssetLifetime = {
	yearDuration?: Maybe<Sort> /** Représente le type de trie. */
	monthDuration?: Maybe<Sort> /** Représente le type de trie. */
	dayDuration?: Maybe<Sort> /** Représente le type de trie. */
	lifeTimeOption?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetStatus */
export type QuerySortTypeAssetStatus = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ModelManufacturer */
export type QuerySortTypeModelManufacturer = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetAffectedRule */
export type QuerySortTypeAssetAffectedRule = {
	byNetbios?: Maybe<Sort> /** Représente le type de trie. */
	byMacAddress?: Maybe<Sort> /** Représente le type de trie. */
	byIpAddress?: Maybe<Sort> /** Représente le type de trie. */
	bySerialNumber?: Maybe<Sort> /** Représente le type de trie. */
	byUuid?: Maybe<Sort> /** Représente le type de trie. */
	byDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	byFqdn?: Maybe<Sort> /** Représente le type de trie. */
	byName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	searchInDeleted?: Maybe<Sort> /** Représente le type de trie. */
	searchInArchived?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetCategoryRule */
export type QuerySortTypeAssetCategoryRule = {
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité InventoryHistory */
export type QuerySortTypeInventoryHistory = {
	clientApplicationtName?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtVersion?: Maybe<Sort> /** Représente le type de trie. */
	auditTimes?: Maybe<Sort> /** Représente le type de trie. */
	networkCredential?: Array<Maybe<QuerySortTypeNetworkCredential>> /**  */
	scanConfiguration?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	auditType?: Maybe<Sort> /** Représente le type de trie. */
	new?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	resolveName?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOption?: Array<Maybe<QuerySortTypeScanImportOption>> /**  */
	attempts?: Maybe<Sort> /** Représente le type de trie. */
	start?: Maybe<Sort> /** Représente le type de trie. */
	lastChanged?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	actionHistoryState?: Maybe<Sort> /** Représente le type de trie. */
	host?: Maybe<Sort> /** Représente le type de trie. */
	clarilogException?: Array<Maybe<QuerySortTypeClarilogException>> /**  */
	progressValue?: Maybe<Sort> /** Représente le type de trie. */
	progressDisplay?: Maybe<Sort> /** Représente le type de trie. */
	log?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	assetAffectedRule?: Array<Maybe<QuerySortTypeAssetAffectedRule>> /**  */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanImportOption */
export type QuerySortTypeScanImportOption = {
	autoApproved?: Maybe<Sort> /** Représente le type de trie. */
	createScreen?: Maybe<Sort> /** Représente le type de trie. */
	searchInDeleted?: Maybe<Sort> /** Représente le type de trie. */
	searchInArchived?: Maybe<Sort> /** Représente le type de trie. */
	serialNumberSearch?: Maybe<Sort> /** Représente le type de trie. */
	inheritLocation?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	excludeAssetIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionLogonSessions?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIpRanges?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIpAddresses?: Maybe<Sort> /** Représente le type de trie. */
	searchWarranty?: Maybe<Sort> /** Représente le type de trie. */
	importSoftwareLinux?: Maybe<Sort> /** Représente le type de trie. */
	importUser?: Maybe<Sort> /** Représente le type de trie. */
	importUserMode?: Maybe<Sort> /** Représente le type de trie. */
	mainUserMode?: Maybe<Sort> /** Représente le type de trie. */
	createUser?: Maybe<Sort> /** Représente le type de trie. */
	updateManualData?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dateUp?: Maybe<Sort> /** Représente le type de trie. */
	assetStateDateUp?: Maybe<Sort> /** Représente le type de trie. */
	forceMonitorName?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	changeStatusActivated?: Maybe<Sort> /** Représente le type de trie. */
	concernedStatusIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ClarilogException */
export type QuerySortTypeClarilogException = {
	message?: Maybe<Sort> /** Représente le type de trie. */
	stackTrace?: Maybe<Sort> /** Représente le type de trie. */
	source?: Maybe<Sort> /** Représente le type de trie. */
	helpLink?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LifeCycle */
export type QuerySortTypeLifeCycle = {
	startLife?: Array<Maybe<QuerySortTypeStartLife>> /**  */
	renewal?: Array<Maybe<QuerySortTypeRenewal>> /**  */
	endLife?: Array<Maybe<QuerySortTypeEndLife>> /**  */
}


/** Représente les propriétés triables de l'entité StartLife */
export type QuerySortTypeStartLife = {
	description?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Renewal */
export type QuerySortTypeRenewal = {
	description?: Maybe<Sort> /** Représente le type de trie. */
	estimatedDate?: Maybe<Sort> /** Représente le type de trie. */
	age?: Maybe<Sort> /** Représente le type de trie. */
	usingDuration?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EndLife */
export type QuerySortTypeEndLife = {
	description?: Maybe<Sort> /** Représente le type de trie. */
	outDate?: Maybe<Sort> /** Représente le type de trie. */
	reason?: Maybe<Sort> /** Représente le type de trie. */
	destination?: Maybe<Sort> /** Représente le type de trie. */
	recipient?: Maybe<Sort> /** Représente le type de trie. */
	removalDate?: Maybe<Sort> /** Représente le type de trie. */
	bunddleNumber?: Maybe<Sort> /** Représente le type de trie. */
	disposalPrice?: Maybe<Sort> /** Représente le type de trie. */
	recycleCost?: Maybe<Sort> /** Représente le type de trie. */
	restockingPrograms?: Maybe<Sort> /** Représente le type de trie. */
	restockingLicenses?: Maybe<Sort> /** Représente le type de trie. */
	dissociateAssets?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureMobility */
export type QuerySortTypeFeatureMobility = {
	firstSimSlot?: Array<Maybe<QuerySortTypeSimSlot>> /**  */
	secondSimSlot?: Array<Maybe<QuerySortTypeSimSlot>> /**  */
}


/** Représente les propriétés triables de l'entité SimSlot */
export type QuerySortTypeSimSlot = {
	imei?: Maybe<Sort> /** Représente le type de trie. */
	phoneNumber?: Maybe<Sort> /** Représente le type de trie. */
	primaryPinCode?: Maybe<Sort> /** Représente le type de trie. */
	secondaryPinCode?: Maybe<Sort> /** Représente le type de trie. */
	primaryPukCode?: Maybe<Sort> /** Représente le type de trie. */
	secondaryPukCode?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureSimCard */
export type QuerySortTypeFeatureSimCard = {
	phone?: Maybe<Sort> /** Représente le type de trie. */
	pin?: Maybe<Sort> /** Représente le type de trie. */
	pin2?: Maybe<Sort> /** Représente le type de trie. */
	puk?: Maybe<Sort> /** Représente le type de trie. */
	puk2?: Maybe<Sort> /** Représente le type de trie. */
	simCardMemory?: Array<Maybe<QuerySortTypeSimCardMemory>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SimCardMemory */
export type QuerySortTypeSimCardMemory = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	capacity?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureMonitor */
export type QuerySortTypeFeatureMonitor = {
	size?: Maybe<Sort> /** Représente le type de trie. */
	resolutions?: Maybe<Sort> /** Représente le type de trie. */
	monitorTechnology?: Array<Maybe<QuerySortTypeMonitorTechnology>> /**  */
	resolutionMax?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité MonitorTechnology */
export type QuerySortTypeMonitorTechnology = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureUps */
export type QuerySortTypeFeatureUps = {
	chargerTime?: Maybe<Sort> /** Représente le type de trie. */
	outlet?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureScanner */
export type QuerySortTypeFeatureScanner = {
	charger?: Maybe<Sort> /** Représente le type de trie. */
	connectionCategories?: Maybe<Sort> /** Représente le type de trie. */
	paperFormats?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureWebcam */
export type QuerySortTypeFeatureWebcam = {
	color?: Maybe<Sort> /** Représente le type de trie. */
	microphone?: Maybe<Sort> /** Représente le type de trie. */
	connectionCategories?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureModem */
export type QuerySortTypeFeatureModem = {
	wifi?: Maybe<Sort> /** Représente le type de trie. */
	connectionCategories?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureTerminal */
export type QuerySortTypeFeatureTerminal = {
	barcodeReader?: Maybe<Sort> /** Représente le type de trie. */
	rfidReader?: Maybe<Sort> /** Représente le type de trie. */
	wifi?: Maybe<Sort> /** Représente le type de trie. */
	bluetooth?: Maybe<Sort> /** Représente le type de trie. */
	infrared?: Maybe<Sort> /** Représente le type de trie. */
	miniUsb?: Maybe<Sort> /** Représente le type de trie. */
	gPs?: Maybe<Sort> /** Représente le type de trie. */
	camera?: Maybe<Sort> /** Représente le type de trie. */
	memoryExtension?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureSwitch */
export type QuerySortTypeFeatureSwitch = {
	desktop?: Maybe<Sort> /** Représente le type de trie. */
	stackable?: Maybe<Sort> /** Représente le type de trie. */
	rackable?: Maybe<Sort> /** Représente le type de trie. */
	poe?: Maybe<Sort> /** Représente le type de trie. */
	modem?: Maybe<Sort> /** Représente le type de trie. */
	firewall?: Maybe<Sort> /** Représente le type de trie. */
	consolePort?: Maybe<Sort> /** Représente le type de trie. */
	proxy?: Maybe<Sort> /** Représente le type de trie. */
	vpn?: Maybe<Sort> /** Représente le type de trie. */
	utm?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureNetwork */
export type QuerySortTypeFeatureNetwork = {
	wifi?: Maybe<Sort> /** Représente le type de trie. */
	sSid?: Maybe<Sort> /** Représente le type de trie. */
	securityKey?: Maybe<Sort> /** Représente le type de trie. */
	webAdministration?: Maybe<Sort> /** Représente le type de trie. */
	snmp?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeaturePrinter */
export type QuerySortTypeFeaturePrinter = {
	color?: Maybe<Sort> /** Représente le type de trie. */
	share?: Maybe<Sort> /** Représente le type de trie. */
	shareName?: Maybe<Sort> /** Représente le type de trie. */
	speedBlack?: Maybe<Sort> /** Représente le type de trie. */
	speedColor?: Maybe<Sort> /** Représente le type de trie. */
	rectoVerso?: Maybe<Sort> /** Représente le type de trie. */
	scanner?: Maybe<Sort> /** Représente le type de trie. */
	fax?: Maybe<Sort> /** Représente le type de trie. */
	paperFormats?: Maybe<Sort> /** Représente le type de trie. */
	connectionCategories?: Maybe<Sort> /** Représente le type de trie. */
	orientations?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureVideoProjector */
export type QuerySortTypeFeatureVideoProjector = {
	speaker?: Maybe<Sort> /** Représente le type de trie. */
	remoteControl?: Maybe<Sort> /** Représente le type de trie. */
	resolutions?: Maybe<Sort> /** Représente le type de trie. */
	connectionCategories?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureMobile */
export type QuerySortTypeFeatureMobile = {
	imei?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FeatureTelephony */
export type QuerySortTypeFeatureTelephony = {
	phoneNumber?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetModel */
export type QuerySortTypeAssetModel = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	manufacturer?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	assetStockEntryIds?: Maybe<Sort> /** Représente le type de trie. */
	model?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	category?: Maybe<Sort> /** Représente le type de trie. */
	minimumStorageAmount?: Maybe<Sort> /** Représente le type de trie. */
	minimumStockManagement?: Maybe<Sort> /** Représente le type de trie. */
	minimumStockByStorageUnits?: Maybe<Sort> /** Représente le type de trie. */
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	totalStorageAmout?: Maybe<Sort> /** Représente le type de trie. */
	supplierIds?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	compatibleIds?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	restockingThreshold?: Maybe<Sort> /** Représente le type de trie. */
	economicQuantity?: Maybe<Sort> /** Représente le type de trie. */
	technicalQuantity?: Maybe<Sort> /** Représente le type de trie. */
	restockingQuantity?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DetectWarranty */
export type QuerySortTypeDetectWarranty = {
	lastAttempt?: Maybe<Sort> /** Représente le type de trie. */
	lastSuccess?: Maybe<Sort> /** Représente le type de trie. */
	message?: Maybe<Sort> /** Représente le type de trie. */
	success?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetFinancial */
export type QuerySortTypeAssetFinancial = {
	budget?: Array<Maybe<QuerySortTypeBudget>> /**  */
	acquisition?: Array<Maybe<QuerySortTypeAssetAcquisition>> /**  */
	internalInvoice?: Array<Maybe<QuerySortTypeAssetInternalInvoice>> /**  */
	accounting?: Array<Maybe<QuerySortTypeAssetAccounting>> /**  */
}


/** Représente les propriétés triables de l'entité Budget */
export type QuerySortTypeBudget = {
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	code?: Maybe<Sort> /** Représente le type de trie. */
	opened?: Maybe<Sort> /** Représente le type de trie. */
	closed?: Maybe<Sort> /** Représente le type de trie. */
	amount?: Maybe<Sort> /** Représente le type de trie. */
	budgetCategory?: Array<Maybe<QuerySortTypeBudgetCategory>> /**  */
	manager?: Array<Maybe<QuerySortTypeUser>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeBudget>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	groupIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité BudgetCategory */
export type QuerySortTypeBudgetCategory = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetAcquisition */
export type QuerySortTypeAssetAcquisition = {
	acquisitionMode?: Array<Maybe<QuerySortTypeAssetAcquisitionMode>> /**  */
	acquisitionState?: Array<Maybe<QuerySortTypeAssetAcquisitionState>> /**  */
	typeOfStaffing?: Array<Maybe<QuerySortTypeAssetTypeStaffing>> /**  */
	supplier?: Array<Maybe<QuerySortTypeSupplier>> /**  */
	batchDate?: Maybe<Sort> /** Représente le type de trie. */
	batchNumber?: Maybe<Sort> /** Représente le type de trie. */
	invoiceDate?: Maybe<Sort> /** Représente le type de trie. */
	invoiceNumber?: Maybe<Sort> /** Représente le type de trie. */
	price?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetAcquisitionMode */
export type QuerySortTypeAssetAcquisitionMode = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetAcquisitionState */
export type QuerySortTypeAssetAcquisitionState = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetTypeStaffing */
export type QuerySortTypeAssetTypeStaffing = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetInternalInvoice */
export type QuerySortTypeAssetInternalInvoice = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	number?: Maybe<Sort> /** Représente le type de trie. */
	amount?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetAccounting */
export type QuerySortTypeAssetAccounting = {
	startDepreciation?: Maybe<Sort> /** Représente le type de trie. */
	endDepreciation?: Maybe<Sort> /** Représente le type de trie. */
	duration?: Maybe<Sort> /** Représente le type de trie. */
	numberImmobilization?: Maybe<Sort> /** Représente le type de trie. */
	recouping?: Maybe<Sort> /** Représente le type de trie. */
	analyticalImputation?: Maybe<Sort> /** Représente le type de trie. */
	assetCostCenter?: Array<Maybe<QuerySortTypeAssetCostCenter>> /**  */
	assetTypeAccounting?: Array<Maybe<QuerySortTypeAssetTypeAccounting>> /**  */
}


/** Représente les propriétés triables de l'entité AssetCostCenter */
export type QuerySortTypeAssetCostCenter = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetTypeAccounting */
export type QuerySortTypeAssetTypeAccounting = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetNamingConvention */
export type QuerySortTypeAssetNamingConvention = {
	onBarCode?: Array<Maybe<QuerySortTypeNamingConventionSummary>> /**  */
	onInventoryNumber?: Array<Maybe<QuerySortTypeNamingConventionSummary>> /**  */
	onName?: Array<Maybe<QuerySortTypeNamingConventionSummary>> /**  */
	onNetBios?: Array<Maybe<QuerySortTypeNamingConventionSummary>> /**  */
	onAssetNumber?: Array<Maybe<QuerySortTypeNamingConventionSummary>> /**  */
	alreadyApply?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité NamingConventionSummary */
export type QuerySortTypeNamingConventionSummary = {
	value?: Maybe<Sort> /** Représente le type de trie. */
	namingConventionMask?: Maybe<Sort> /** Représente le type de trie. */
	manualEdit?: Maybe<Sort> /** Représente le type de trie. */
	lock?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EntityLocationCharter */
export type QuerySortTypeEntityLocationCharter = {
	onLocationId?: Array<Maybe<QuerySortTypeLocationCharterSummary>> /**  */
	onOrganizationalUnitId?: Array<Maybe<QuerySortTypeLocationCharterSummary>> /**  */
}


/** Représente les propriétés triables de l'entité LocationCharterSummary */
export type QuerySortTypeLocationCharterSummary = {
	name?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AuditResult */
export type QuerySortTypeAuditResult = {
	antiSpywareProducts?: Maybe<Sort> /** Représente le type de trie. */
	antiVirusProducts?: Maybe<Sort> /** Représente le type de trie. */
	autoRuns?: Maybe<Sort> /** Représente le type de trie. */
	baseBoards?: Maybe<Sort> /** Représente le type de trie. */
	bios?: Maybe<Sort> /** Représente le type de trie. */
	cdRomDrives?: Maybe<Sort> /** Représente le type de trie. */
	computerSystems?: Maybe<Sort> /** Représente le type de trie. */
	computerSystemProducts?: Maybe<Sort> /** Représente le type de trie. */
	dataFiles?: Maybe<Sort> /** Représente le type de trie. */
	diskDrives?: Maybe<Sort> /** Représente le type de trie. */
	logicalDisks?: Maybe<Sort> /** Représente le type de trie. */
	environmentVariables?: Maybe<Sort> /** Représente le type de trie. */
	firewallProducts?: Maybe<Sort> /** Représente le type de trie. */
	fontInfoActions?: Maybe<Sort> /** Représente le type de trie. */
	groups?: Maybe<Sort> /** Représente le type de trie. */
	keyboards?: Maybe<Sort> /** Représente le type de trie. */
	monitors?: Maybe<Sort> /** Représente le type de trie. */
	virtualMachines?: Maybe<Sort> /** Représente le type de trie. */
	networkAdapters?: Maybe<Sort> /** Représente le type de trie. */
	networkDrives?: Maybe<Sort> /** Représente le type de trie. */
	ntLogEvents?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystems?: Maybe<Sort> /** Représente le type de trie. */
	parallelPorts?: Maybe<Sort> /** Représente le type de trie. */
	physicalMemoryArrays?: Maybe<Sort> /** Représente le type de trie. */
	physicalMemories?: Maybe<Sort> /** Représente le type de trie. */
	pnpEntities?: Maybe<Sort> /** Représente le type de trie. */
	pointingDevices?: Maybe<Sort> /** Représente le type de trie. */
	portConnectors?: Maybe<Sort> /** Représente le type de trie. */
	potsModems?: Maybe<Sort> /** Représente le type de trie. */
	printers?: Maybe<Sort> /** Représente le type de trie. */
	processes?: Maybe<Sort> /** Représente le type de trie. */
	processors?: Maybe<Sort> /** Représente le type de trie. */
	programs?: Maybe<Sort> /** Représente le type de trie. */
	quickFixEngineerings?: Maybe<Sort> /** Représente le type de trie. */
	workApplications?: Maybe<Sort> /** Représente le type de trie. */
	serialPorts?: Maybe<Sort> /** Représente le type de trie. */
	windowsServices?: Maybe<Sort> /** Représente le type de trie. */
	shares?: Maybe<Sort> /** Représente le type de trie. */
	sqlServers?: Maybe<Sort> /** Représente le type de trie. */
	soundDevices?: Maybe<Sort> /** Représente le type de trie. */
	systemEnclosures?: Maybe<Sort> /** Représente le type de trie. */
	systemSlots?: Maybe<Sort> /** Représente le type de trie. */
	tapeDrives?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControls?: Maybe<Sort> /** Représente le type de trie. */
	userAccounts?: Maybe<Sort> /** Représente le type de trie. */
	userProfiles?: Maybe<Sort> /** Représente le type de trie. */
	videoControllers?: Maybe<Sort> /** Représente le type de trie. */
	windowsFirewalls?: Maybe<Sort> /** Représente le type de trie. */
	windowsUpdates?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistries?: Maybe<Sort> /** Représente le type de trie. */
	printerConsumables?: Maybe<Sort> /** Représente le type de trie. */
	pageCounts?: Maybe<Sort> /** Représente le type de trie. */
	copyCounts?: Maybe<Sort> /** Représente le type de trie. */
	scannerCounts?: Maybe<Sort> /** Représente le type de trie. */
	faxCounts?: Maybe<Sort> /** Représente le type de trie. */
	entryTables?: Maybe<Sort> /** Représente le type de trie. */
	vlans?: Maybe<Sort> /** Représente le type de trie. */
	accessPoints?: Maybe<Sort> /** Représente le type de trie. */
	portOpens?: Maybe<Sort> /** Représente le type de trie. */
	odbcDrivers?: Maybe<Sort> /** Représente le type de trie. */
	systemAccounts?: Maybe<Sort> /** Représente le type de trie. */
	logonSessions?: Maybe<Sort> /** Représente le type de trie. */
	pnpSignedDrivers?: Maybe<Sort> /** Représente le type de trie. */
	serialNumbers?: Maybe<Sort> /** Représente le type de trie. */
	uptimeCalendars?: Maybe<Sort> /** Représente le type de trie. */
	bootConfigurations?: Maybe<Sort> /** Représente le type de trie. */
	pageFiles?: Maybe<Sort> /** Représente le type de trie. */
	codecFiles?: Maybe<Sort> /** Représente le type de trie. */
	osRecoveryConfigurations?: Maybe<Sort> /** Représente le type de trie. */
	scsiControllers?: Maybe<Sort> /** Représente le type de trie. */
	pcmciaControllers?: Maybe<Sort> /** Représente le type de trie. */
	infraredDevices?: Maybe<Sort> /** Représente le type de trie. */
	batteries?: Maybe<Sort> /** Représente le type de trie. */
	portableBatteries?: Maybe<Sort> /** Représente le type de trie. */
	logonHistories?: Maybe<Sort> /** Représente le type de trie. */
	desktops?: Maybe<Sort> /** Représente le type de trie. */
	optionalFeatures?: Maybe<Sort> /** Représente le type de trie. */
	iis?: Maybe<Sort> /** Représente le type de trie. */
	ldapProperties?: Maybe<Sort> /** Représente le type de trie. */
	logicalDiskHistories?: Maybe<Sort> /** Représente le type de trie. */
	internetExplorers?: Maybe<Sort> /** Représente le type de trie. */
	encryptableVolumes?: Maybe<Sort> /** Représente le type de trie. */
	snmpSummary?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OperatingSystem */
export type QuerySortTypeOperatingSystem = {
	bootDevice?: Maybe<Sort> /** Représente le type de trie. */
	buildNumber?: Maybe<Sort> /** Représente le type de trie. */
	buildType?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	codeSet?: Maybe<Sort> /** Représente le type de trie. */
	countryCode?: Maybe<Sort> /** Représente le type de trie. */
	csdVersion?: Maybe<Sort> /** Représente le type de trie. */
	currentTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	debug?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	distributed?: Maybe<Sort> /** Représente le type de trie. */
	encryptionLevel?: Maybe<Sort> /** Représente le type de trie. */
	foregroundApplicationBoost?: Maybe<Sort> /** Représente le type de trie. */
	freePhysicalMemory?: Maybe<Sort> /** Représente le type de trie. */
	freeSpaceInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	freeVirtualMemory?: Maybe<Sort> /** Représente le type de trie. */
	largeSystemCache?: Maybe<Sort> /** Représente le type de trie. */
	lastBootUpTime?: Maybe<Sort> /** Représente le type de trie. */
	localDateTime?: Maybe<Sort> /** Représente le type de trie. */
	locale?: Maybe<Sort> /** Représente le type de trie. */
	maxNumberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	maxProcessMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLicensedUsers?: Maybe<Sort> /** Représente le type de trie. */
	numberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	numberOfUsers?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystemSku?: Maybe<Sort> /** Représente le type de trie. */
	organization?: Maybe<Sort> /** Représente le type de trie. */
	osArchitecture?: Maybe<Sort> /** Représente le type de trie. */
	osLanguage?: Maybe<Sort> /** Représente le type de trie. */
	osProductSuite?: Maybe<Sort> /** Représente le type de trie. */
	osType?: Maybe<Sort> /** Représente le type de trie. */
	otherTypeDescription?: Maybe<Sort> /** Représente le type de trie. */
	paeEnabled?: Maybe<Sort> /** Représente le type de trie. */
	plusProductId?: Maybe<Sort> /** Représente le type de trie. */
	plusVersionNumber?: Maybe<Sort> /** Représente le type de trie. */
	primary?: Maybe<Sort> /** Représente le type de trie. */
	productType?: Maybe<Sort> /** Représente le type de trie. */
	registeredUser?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	sizeStoredInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	suiteMask?: Maybe<Sort> /** Représente le type de trie. */
	systemDevice?: Maybe<Sort> /** Représente le type de trie. */
	systemDirectory?: Maybe<Sort> /** Représente le type de trie. */
	systemDrive?: Maybe<Sort> /** Représente le type de trie. */
	totalSwapSpaceSize?: Maybe<Sort> /** Représente le type de trie. */
	totalVirtualMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	totalVisibleMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	windowsDirectory?: Maybe<Sort> /** Représente le type de trie. */
	digitalProductId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	installDate?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Software */
export type QuerySortTypeSoftware = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	internalName?: Maybe<Sort> /** Représente le type de trie. */
	internalCode?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	notAllowed?: Maybe<Sort> /** Représente le type de trie. */
	obligatory?: Maybe<Sort> /** Représente le type de trie. */
	softwareAllowedType?: Maybe<Sort> /** Représente le type de trie. */
	approved?: Maybe<Sort> /** Représente le type de trie. */
	recorded?: Maybe<Sort> /** Représente le type de trie. */
	publisher?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	softwareCategory?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	softwareGroup?: Array<Maybe<QuerySortTypeSoftwareGroup>> /**  */
	softwarePackageIds?: Maybe<Sort> /** Représente le type de trie. */
	installCount?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	auditType?: Maybe<Sort> /** Représente le type de trie. */
	shortcutName?: Maybe<Sort> /** Représente le type de trie. */
	softwareAttributes?: Maybe<Sort> /** Représente le type de trie. */
	softwareConnectionMode?: Array<Maybe<QuerySortTypeSoftwareConnectionMode>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SoftwareGroup */
export type QuerySortTypeSoftwareGroup = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	obligatory?: Maybe<Sort> /** Représente le type de trie. */
	softwarePackageIds?: Maybe<Sort> /** Représente le type de trie. */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	softwareGroupForbidden?: Maybe<Sort> /** Représente le type de trie. */
	softwareGroupHidden?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SoftwareConnectionMode */
export type QuerySortTypeSoftwareConnectionMode = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AssetDelivery */
export type QuerySortTypeAssetDelivery = {
	deliveryDate?: Maybe<Sort> /** Représente le type de trie. */
	orderDate?: Maybe<Sort> /** Représente le type de trie. */
	deliveryOrder?: Maybe<Sort> /** Représente le type de trie. */
	supplierReferenceNumber?: Maybe<Sort> /** Représente le type de trie. */
	internalReferenceNumber?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ClarilogLocalAgent */
export type QuerySortTypeClarilogLocalAgent = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	lastStart?: Maybe<Sort> /** Représente le type de trie. */
	lastCheck?: Maybe<Sort> /** Représente le type de trie. */
	firstCheck?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	osVersion?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	scanByLocalAgent?: Array<Maybe<QuerySortTypeScanByLocalAgent>> /**  */
	forceAudit?: Maybe<Sort> /** Représente le type de trie. */
	mustBeAudited?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtName?: Maybe<Sort> /** Représente le type de trie. */
	updater?: Array<Maybe<QuerySortTypeUpdater>> /**  */
	clarilogAgentSettings?: Array<Maybe<QuerySortTypeClarilogAgentSettings>> /**  */
	campaignIds?: Maybe<Sort> /** Représente le type de trie. */
	packageToDeploy?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanByLocalAgent */
export type QuerySortTypeScanByLocalAgent = {
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité InheritLocationCharter */
export type QuerySortTypeInheritLocationCharter = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnit?: Maybe<Sort> /** Représente le type de trie. */
	location?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	optionLogonSessions?: Maybe<Sort> /** Représente le type de trie. */
	excludeAssetIds?: Maybe<Sort> /** Représente le type de trie. */
	excludeUserIds?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Privilege */
export type QuerySortTypePrivilege = {
	key?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketEntries?: Maybe<Sort> /** Représente le type de trie. */
	taskEntries?: Maybe<Sort> /** Représente le type de trie. */
	actionEntries?: Maybe<Sort> /** Représente le type de trie. */
	authEditEntries?: Maybe<Sort> /** Représente le type de trie. */
	notificationEntries?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité UserPrivilege */
export type QuerySortTypeUserPrivilege = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	affectedUserEntries?: Maybe<Sort> /** Représente le type de trie. */
	otherAffectedUserEntries?: Maybe<Sort> /** Représente le type de trie. */
	responsableUserEntries?: Maybe<Sort> /** Représente le type de trie. */
	accessTicketsObserverEntries?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité CalendarConnector */
export type QuerySortTypeCalendarConnector = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	exchangeCalendar?: Array<Maybe<QuerySortTypeExchangeCalendar>> /**  */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	logon?: Maybe<Sort> /** Représente le type de trie. */
	mailBox?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	server?: Maybe<Sort> /** Représente le type de trie. */
	useAutodiscoverUrl?: Maybe<Sort> /** Représente le type de trie. */
	accessToken?: Maybe<Sort> /** Représente le type de trie. */
	expireAccesToken?: Maybe<Sort> /** Représente le type de trie. */
	applicationAzureAd?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /**  */
	azureToken?: Maybe<Sort> /** Représente le type de trie. */
	authAzurePassword?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ExchangeCalendar */
export type QuerySortTypeExchangeCalendar = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	calendarId?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité BusinessTime */
export type QuerySortTypeBusinessTime = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	config?: Array<Maybe<QuerySortTypeWorkTimeConfig>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dayOffIds?: Maybe<Sort> /** Représente le type de trie. */
	pauseStatusIds?: Maybe<Sort> /** Représente le type de trie. */
	timeZoneId?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkTimeConfig */
export type QuerySortTypeWorkTimeConfig = {
	monday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	tuesday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	wednesday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	thursday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	friday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	saturday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
	sunday?: Array<Maybe<QuerySortTypeWorkTimeConfigDay>> /**  */
}


/** Représente les propriétés triables de l'entité WorkTimeConfigDay */
export type QuerySortTypeWorkTimeConfigDay = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	times?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfPopulation = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	isManager?: Maybe<FilterBooleanOperator> /**  */
	memberLocationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	memberOrganizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketAffectedRuleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	memberDistinguishedNameGroups?: Maybe<ArrayStringTypeOperator> /**  */
	memberAzureGroupObjectIds?: Maybe<ArrayStringTypeOperator> /**  */
	allowedTicketsCategories?: Maybe<ArrayAllowedEntityOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	isManual?: Maybe<FilterBooleanOperator> /**  */
	securityGroupId?: Maybe<FilterGuidOperator> /**  */
	ticketCategoryVisible?: Maybe<FilterBooleanOperator> /**  */
	ticketCategoryAllowed?: Maybe<FilterBooleanOperator> /**  */
	locationVisible?: Maybe<FilterBooleanOperator> /**  */
	locationAllowed?: Maybe<FilterBooleanOperator> /**  */
	allowedLocations?: Maybe<ArrayAllowedEntityOperator> /**  */
	organizationalUnitVisible?: Maybe<FilterBooleanOperator> /**  */
	organizationalUnitAllowed?: Maybe<FilterBooleanOperator> /**  */
	allowedOrganizationalUnits?: Maybe<ArrayAllowedEntityOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPopulation>> /**  */
	or?: Array<Maybe<FilterOfPopulation>> /**  */
	nor?: Array<Maybe<FilterOfPopulation>> /**  */
	not?: Array<Maybe<FilterOfPopulation>> /**  */
}


/**  */
export type ArrayAllowedEntityOperator = {
	elemMatch?: Maybe<FilterOfAllowedEntity> /**  */
}


/**  */
export type FilterOfAllowedEntity = {
	entityId?: Maybe<FilterGuidOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	allowed?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfAllowedEntity>> /**  */
	or?: Array<Maybe<FilterOfAllowedEntity>> /**  */
	nor?: Array<Maybe<FilterOfAllowedEntity>> /**  */
	not?: Array<Maybe<FilterOfAllowedEntity>> /**  */
}


/**  */
export type QueryContextOfPopulation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePopulation>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Population */
export type QuerySortTypePopulation = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	isManager?: Maybe<Sort> /** Représente le type de trie. */
	memberLocationIds?: Maybe<Sort> /** Représente le type de trie. */
	memberOrganizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketAffectedRuleIds?: Maybe<Sort> /** Représente le type de trie. */
	allowedTicketsCategories?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	isManual?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryVisible?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryAllowed?: Maybe<Sort> /** Représente le type de trie. */
	locationVisible?: Maybe<Sort> /** Représente le type de trie. */
	locationAllowed?: Maybe<Sort> /** Représente le type de trie. */
	allowedLocations?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitVisible?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitAllowed?: Maybe<Sort> /** Représente le type de trie. */
	allowedOrganizationalUnits?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicket = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	tenantIdString?: Maybe<FilterStringOperator> /**  */
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	counterIncident?: Maybe<FilterInt32Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInTicket> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInTicket> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInTicket> /**  */
	resolutionCategoryId?: Maybe<FilterGuidOperator> /**  */
	resolutionCategory?: Maybe<FilterDenormalizeOfResolutionCategoryByResolutionCategoryInTicket> /**  */
	statusReasonId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusReasons?: Maybe<FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInTicket> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInTicket> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	channel?: Maybe<FilterDenormalizeOfChannelByChannelInTicket> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInTicket> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInTicket> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicket> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicket> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	concernedProject?: Maybe<FilterDenormalizeOfConcernedProjectByConcernedProjectInTicket> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInTicket> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInTicket> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByUserAffectedInTicket> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByUserMakeRequestInTicket> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicket> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	respondBefore?: Maybe<FilterDateTimeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	slaId?: Maybe<FilterGuidOperator> /**  */
	sla?: Maybe<FilterDenormalizeOfServiceLevelAgreementBySlaInTicket> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	appointmentConnector?: Maybe<FilterOfAppointmentConnector> /**  */
	estimate?: Maybe<FilterTimeSpanOperator> /**  */
	estimateMinutes?: Maybe<FilterDoubleOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainImpactedAssetId?: Maybe<FilterGuidOperator> /**  */
	mainImpactedAsset?: Maybe<FilterDenormalizeOfAssetByMainImpactedAssetInTicket> /**  */
	favoriteUserTicketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	statusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	privateResolutionArticleId?: Maybe<FilterGuidOperator> /**  */
	privateResolutionArticle?: Maybe<FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInTicket> /**  */
	privateResolutionComment?: Maybe<FilterStringOperator> /**  */
	resolutionComment?: Maybe<FilterStringOperator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	incidentModel?: Maybe<FilterDenormalizeOfIncidentModelByIncidentModelInTicket> /**  */
	callbackNumber?: Maybe<FilterStringOperator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
	takeChargeDate?: Maybe<FilterDateTimeOperator> /**  */
	endTreatmentDate?: Maybe<FilterDateTimeOperator> /**  */
	closedTicketDate?: Maybe<FilterDateTimeOperator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	emailConnectorId?: Maybe<FilterGuidOperator> /**  */
	satisfaction?: Maybe<FilterOfTicketSatisfaction> /**  */
	takeChargeHours?: Maybe<FilterDoubleOperator> /**  */
	endTreatmentHours?: Maybe<FilterDoubleOperator> /**  */
	closedHours?: Maybe<FilterDoubleOperator> /**  */
	isParent?: Maybe<FilterBooleanOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketByParentInTicket> /**  */
	childrenIds?: Maybe<ArrayUuidTypeOperator> /**  */
	problemId?: Maybe<FilterGuidOperator> /**  */
	problem?: Maybe<FilterDenormalizeOfProblemByProblemInTicket> /**  */
	externalAlreadySendFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	originId?: Maybe<FilterGuidOperator> /**  */
	origin?: Maybe<FilterDenormalizeOfOriginByOriginInTicket> /**  */
	cause?: Maybe<FilterStringOperator> /**  */
	workaround?: Maybe<FilterStringOperator> /**  */
	solution?: Maybe<FilterStringOperator> /**  */
	privateComment?: Maybe<FilterStringOperator> /**  */
	comment?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicket>> /**  */
	or?: Array<Maybe<FilterOfTicket>> /**  */
	nor?: Array<Maybe<FilterOfTicket>> /**  */
	not?: Array<Maybe<FilterOfTicket>> /**  */
}


/**  */
export type FilterDenormalizeOfImpactByImpactInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUrgencyByUrgencyInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfPriorityByPriorityInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfResolutionCategoryByResolutionCategoryInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfChannelByChannelInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInTicket = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicket = {
	email?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterUserCategoryOperator = {
	eq?: Maybe<UserCategory> /**  */
	ne?: Maybe<UserCategory> /**  */
	in?: Array<Maybe<UserCategory>> /**  */
	nin?: Array<Maybe<UserCategory>> /**  */
}


/**  */
export type FilterDenormalizeOfConcernedProjectByConcernedProjectInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserAffectedInTicket = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserMakeRequestInTicket = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfServiceLevelAgreementBySlaInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfAppointmentConnector = {
	appointmentId?: Maybe<FilterStringOperator> /**  */
	calendarConnectorId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfAppointmentConnector>> /**  */
	or?: Array<Maybe<FilterOfAppointmentConnector>> /**  */
	nor?: Array<Maybe<FilterOfAppointmentConnector>> /**  */
	not?: Array<Maybe<FilterOfAppointmentConnector>> /**  */
}


/**  */
export type FilterTimeSpanOperator = {
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	in?: Array<Maybe<string>> /**  */
	nin?: Array<Maybe<string>> /**  */
	gt?: Maybe<string> /**  */
	gte?: Maybe<string> /**  */
	lt?: Maybe<string> /**  */
	lte?: Maybe<string> /**  */
}


/**  */
export type FilterDoubleOperator = {
	eq?: Maybe<number> /**  */
	ne?: Maybe<number> /**  */
	in?: Array<Maybe<number>> /**  */
	nin?: Array<Maybe<number>> /**  */
	gt?: Maybe<number> /**  */
	gte?: Maybe<number> /**  */
	lt?: Maybe<number> /**  */
	lte?: Maybe<number> /**  */
	mod?: Maybe<ModOperator> /**  */
	regex?: Maybe<RegexOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainImpactedAssetInTicket = {
	name?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfModelManufacturerByManufacturerInAsset = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInTicket = {
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentModelByIncidentModelInTicket = {
	modelName?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfReminder = {
	userId?: Maybe<FilterGuidOperator> /**  */
	lastReminder?: Maybe<FilterDateTimeOperator> /**  */
	increment?: Maybe<FilterInt32Operator> /**  */
	commentary?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfReminder>> /**  */
	or?: Array<Maybe<FilterOfReminder>> /**  */
	nor?: Array<Maybe<FilterOfReminder>> /**  */
	not?: Array<Maybe<FilterOfReminder>> /**  */
}


/**  */
export type FilterOfTicketSatisfaction = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	rating?: Maybe<FilterDoubleOperator> /**  */
	commentary?: Maybe<FilterStringOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfTicketSatisfaction>> /**  */
	or?: Array<Maybe<FilterOfTicketSatisfaction>> /**  */
	nor?: Array<Maybe<FilterOfTicketSatisfaction>> /**  */
	not?: Array<Maybe<FilterOfTicketSatisfaction>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByParentInTicket = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfProblemByProblemInTicket = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterOfEntityAttributes = {
	created?: Maybe<FilterOfEntityAttribute> /**  */
	updated?: Maybe<FilterOfEntityAttribute> /**  */
	deleted?: Maybe<FilterOfEntityAttribute> /**  */
	locked?: Maybe<FilterOfEntityAttribute> /**  */
	archived?: Maybe<FilterOfEntityAttribute> /**  */
	and?: Array<Maybe<FilterOfEntityAttributes>> /**  */
	or?: Array<Maybe<FilterOfEntityAttributes>> /**  */
	nor?: Array<Maybe<FilterOfEntityAttributes>> /**  */
	not?: Array<Maybe<FilterOfEntityAttributes>> /**  */
}


/**  */
export type FilterOfEntityAttribute = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	username?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfEntityAttribute>> /**  */
	or?: Array<Maybe<FilterOfEntityAttribute>> /**  */
	nor?: Array<Maybe<FilterOfEntityAttribute>> /**  */
	not?: Array<Maybe<FilterOfEntityAttribute>> /**  */
}


/**  */
export type FilterDenormalizeOfOriginByOriginInTicket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicket = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicket>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Ticket */
export type QuerySortTypeTicket = {
	tenantIdString?: Maybe<Sort> /** Représente le type de trie. */
	created?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	counterIncident?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	impact?: Array<Maybe<QuerySortTypeImpact>> /**  */
	urgency?: Array<Maybe<QuerySortTypeUrgency>> /**  */
	priority?: Array<Maybe<QuerySortTypePriority>> /**  */
	resolutionCategory?: Array<Maybe<QuerySortTypeResolutionCategory>> /**  */
	ticketStatusReasons?: Array<Maybe<QuerySortTypeTicketStatusReason>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	channel?: Array<Maybe<QuerySortTypeChannel>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	concernedProject?: Array<Maybe<QuerySortTypeConcernedProject>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	userMakeRequest?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	respondBefore?: Maybe<Sort> /** Représente le type de trie. */
	resolveBefore?: Maybe<Sort> /** Représente le type de trie. */
	sla?: Array<Maybe<QuerySortTypeServiceLevelAgreement>> /**  */
	planned?: Maybe<Sort> /** Représente le type de trie. */
	appointmentConnector?: Array<Maybe<QuerySortTypeAppointmentConnector>> /**  */
	estimate?: Maybe<Sort> /** Représente le type de trie. */
	estimateMinutes?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	mainImpactedAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	favoriteUserTicketIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketNumber?: Maybe<Sort> /** Représente le type de trie. */
	privateResolutionArticle?: Array<Maybe<QuerySortTypeArticleKnowledge>> /**  */
	privateResolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	resolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	incidentModel?: Array<Maybe<QuerySortTypeIncidentModel>> /**  */
	callbackNumber?: Maybe<Sort> /** Représente le type de trie. */
	customField1?: Maybe<Sort> /** Représente le type de trie. */
	customField2?: Maybe<Sort> /** Représente le type de trie. */
	customField3?: Maybe<Sort> /** Représente le type de trie. */
	customField4?: Maybe<Sort> /** Représente le type de trie. */
	customField5?: Maybe<Sort> /** Représente le type de trie. */
	takeChargeDate?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentDate?: Maybe<Sort> /** Représente le type de trie. */
	closedTicketDate?: Maybe<Sort> /** Représente le type de trie. */
	reminder?: Array<Maybe<QuerySortTypeReminder>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	elaspedTimeHour?: Maybe<Sort> /** Représente le type de trie. */
	satisfaction?: Array<Maybe<QuerySortTypeTicketSatisfaction>> /**  */
	takeChargeHours?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentHours?: Maybe<Sort> /** Représente le type de trie. */
	closedHours?: Maybe<Sort> /** Représente le type de trie. */
	isParent?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeTicket>> /**  */
	childrenIds?: Maybe<Sort> /** Représente le type de trie. */
	problem?: Array<Maybe<QuerySortTypeProblem>> /**  */
	externalAlreadySendFileIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	origin?: Array<Maybe<QuerySortTypeOrigin>> /**  */
	cause?: Maybe<Sort> /** Représente le type de trie. */
	workaround?: Maybe<Sort> /** Représente le type de trie. */
	solution?: Maybe<Sort> /** Représente le type de trie. */
	privateComment?: Maybe<Sort> /** Représente le type de trie. */
	comment?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Impact */
export type QuerySortTypeImpact = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Urgency */
export type QuerySortTypeUrgency = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Priority */
export type QuerySortTypePriority = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	color?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ResolutionCategory */
export type QuerySortTypeResolutionCategory = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	resolutionModelIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TicketStatusReason */
export type QuerySortTypeTicketStatusReason = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	ticketsIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TicketStatus */
export type QuerySortTypeTicketStatus = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	ticketStatusReasonIds?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TicketCategory */
export type QuerySortTypeTicketCategory = {
	error?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	path?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	allowSelection?: Maybe<Sort> /** Représente le type de trie. */
	file?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FileModel */
export type QuerySortTypeFileModel = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	uploaded?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	commentary?: Maybe<Sort> /** Représente le type de trie. */
	metaData?: Array<Maybe<QuerySortTypeFileMetaData>> /**  */
	fileObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FileMetaData */
export type QuerySortTypeFileMetaData = {
	encoding?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	indexChunk?: Maybe<Sort> /** Représente le type de trie. */
	temp?: Maybe<Sort> /** Représente le type de trie. */
	commentary?: Maybe<Sort> /** Représente le type de trie. */
	linkeds?: Maybe<Sort> /** Représente le type de trie. */
	visibleByHelpDeskUser?: Maybe<Sort> /** Représente le type de trie. */
	outgoingEmails?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Channel */
export type QuerySortTypeChannel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ConcernedProject */
export type QuerySortTypeConcernedProject = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ServiceLevelAgreement */
export type QuerySortTypeServiceLevelAgreement = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	supportTimes?: Maybe<Sort> /** Représente le type de trie. */
	resolutionTimes?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	priorityIds?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	workTime?: Array<Maybe<QuerySortTypeWorkTime>> /**  */
	dayOffIds?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	populationsIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkTime */
export type QuerySortTypeWorkTime = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	config?: Array<Maybe<QuerySortTypeWorkTimeConfig>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AppointmentConnector */
export type QuerySortTypeAppointmentConnector = {
	appointmentId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ArticleKnowledge */
export type QuerySortTypeArticleKnowledge = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	descriptionText?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	solution?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	solutionText?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	populationsIds?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdateBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastUpdated?: Maybe<Sort> /** Représente le type de trie. */
	lastViewByOperator?: Maybe<Sort> /** Représente le type de trie. */
	countViewOperator?: Maybe<Sort> /** Représente le type de trie. */
	lastViewByUser?: Maybe<Sort> /** Représente le type de trie. */
	countViewUser?: Maybe<Sort> /** Représente le type de trie. */
	averageRating?: Maybe<Sort> /** Représente le type de trie. */
	countRating?: Maybe<Sort> /** Représente le type de trie. */
	knowledgeNumber?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	resolvedTickets?: Maybe<Sort> /** Représente le type de trie. */
	lastResolvedTicketDate?: Maybe<Sort> /** Représente le type de trie. */
	articleKnowledgeCategory?: Array<Maybe<QuerySortTypeArticleKnowledgeCategory>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ArticleKnowledgeCategory */
export type QuerySortTypeArticleKnowledgeCategory = {
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité IncidentModel */
export type QuerySortTypeIncidentModel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	modelName?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	impact?: Array<Maybe<QuerySortTypeImpact>> /**  */
	urgency?: Array<Maybe<QuerySortTypeUrgency>> /**  */
	priority?: Array<Maybe<QuerySortTypePriority>> /**  */
	resolutionCategory?: Array<Maybe<QuerySortTypeResolutionCategory>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	privateResolutionComment?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	resolutionComment?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	populationIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	customField1?: Maybe<Sort> /** Représente le type de trie. */
	customField2?: Maybe<Sort> /** Représente le type de trie. */
	customField3?: Maybe<Sort> /** Représente le type de trie. */
	customField4?: Maybe<Sort> /** Représente le type de trie. */
	customField5?: Maybe<Sort> /** Représente le type de trie. */
	statusWorkflow?: Array<Maybe<QuerySortTypeStatusWorkflow>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité StatusWorkflow */
export type QuerySortTypeStatusWorkflow = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	enable?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdateBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastUpdated?: Maybe<Sort> /** Représente le type de trie. */
	config?: Array<Maybe<QuerySortTypeStatusConfig>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité StatusConfig */
export type QuerySortTypeStatusConfig = {
	edges?: Maybe<Sort> /** Représente le type de trie. */
	nodes?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Reminder */
export type QuerySortTypeReminder = {
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastReminder?: Maybe<Sort> /** Représente le type de trie. */
	increment?: Maybe<Sort> /** Représente le type de trie. */
	commentary?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TicketSatisfaction */
export type QuerySortTypeTicketSatisfaction = {
	created?: Maybe<Sort> /** Représente le type de trie. */
	rating?: Maybe<Sort> /** Représente le type de trie. */
	commentary?: Maybe<Sort> /** Représente le type de trie. */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
}


/** Représente les propriétés triables de l'entité Problem */
export type QuerySortTypeProblem = {
	origin?: Array<Maybe<QuerySortTypeOrigin>> /**  */
	cause?: Maybe<Sort> /** Représente le type de trie. */
	workaround?: Maybe<Sort> /** Représente le type de trie. */
	solution?: Maybe<Sort> /** Représente le type de trie. */
	tenantIdString?: Maybe<Sort> /** Représente le type de trie. */
	created?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	counterIncident?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	impact?: Array<Maybe<QuerySortTypeImpact>> /**  */
	urgency?: Array<Maybe<QuerySortTypeUrgency>> /**  */
	priority?: Array<Maybe<QuerySortTypePriority>> /**  */
	resolutionCategory?: Array<Maybe<QuerySortTypeResolutionCategory>> /**  */
	ticketStatusReasons?: Array<Maybe<QuerySortTypeTicketStatusReason>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	channel?: Array<Maybe<QuerySortTypeChannel>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	concernedProject?: Array<Maybe<QuerySortTypeConcernedProject>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	userMakeRequest?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	respondBefore?: Maybe<Sort> /** Représente le type de trie. */
	resolveBefore?: Maybe<Sort> /** Représente le type de trie. */
	sla?: Array<Maybe<QuerySortTypeServiceLevelAgreement>> /**  */
	planned?: Maybe<Sort> /** Représente le type de trie. */
	appointmentConnector?: Array<Maybe<QuerySortTypeAppointmentConnector>> /**  */
	estimate?: Maybe<Sort> /** Représente le type de trie. */
	estimateMinutes?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	mainImpactedAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	favoriteUserTicketIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketNumber?: Maybe<Sort> /** Représente le type de trie. */
	privateResolutionArticle?: Array<Maybe<QuerySortTypeArticleKnowledge>> /**  */
	privateResolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	resolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	incidentModel?: Array<Maybe<QuerySortTypeIncidentModel>> /**  */
	callbackNumber?: Maybe<Sort> /** Représente le type de trie. */
	customField1?: Maybe<Sort> /** Représente le type de trie. */
	customField2?: Maybe<Sort> /** Représente le type de trie. */
	customField3?: Maybe<Sort> /** Représente le type de trie. */
	customField4?: Maybe<Sort> /** Représente le type de trie. */
	customField5?: Maybe<Sort> /** Représente le type de trie. */
	takeChargeDate?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentDate?: Maybe<Sort> /** Représente le type de trie. */
	closedTicketDate?: Maybe<Sort> /** Représente le type de trie. */
	reminder?: Array<Maybe<QuerySortTypeReminder>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	elaspedTimeHour?: Maybe<Sort> /** Représente le type de trie. */
	satisfaction?: Array<Maybe<QuerySortTypeTicketSatisfaction>> /**  */
	takeChargeHours?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentHours?: Maybe<Sort> /** Représente le type de trie. */
	closedHours?: Maybe<Sort> /** Représente le type de trie. */
	isParent?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeTicket>> /**  */
	childrenIds?: Maybe<Sort> /** Représente le type de trie. */
	problem?: Array<Maybe<QuerySortTypeProblem>> /**  */
	externalAlreadySendFileIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Origin */
export type QuerySortTypeOrigin = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	modelName?: Maybe<FilterOfTranslatedField> /**  */
	title?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInIncidentModel> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	copyFrom?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInIncidentModel> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInIncidentModel> /**  */
	resolutionCategoryId?: Maybe<FilterGuidOperator> /**  */
	resolutionCategory?: Maybe<FilterDenormalizeOfResolutionCategoryByResolutionCategoryInIncidentModel> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInIncidentModel> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncidentModel> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncidentModel> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInIncidentModel> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInIncidentModel> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	privateResolutionComment?: Maybe<FilterOfTranslatedField> /**  */
	resolutionComment?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	populationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
	statusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	statusWorkflow?: Maybe<FilterDenormalizeOfStatusWorkflowByStatusWorkflowInIncidentModel> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIncidentModel>> /**  */
	or?: Array<Maybe<FilterOfIncidentModel>> /**  */
	nor?: Array<Maybe<FilterOfIncidentModel>> /**  */
	not?: Array<Maybe<FilterOfIncidentModel>> /**  */
}


/**  */
export type FilterDenormalizeOfImpactByImpactInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUrgencyByUrgencyInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfPriorityByPriorityInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfResolutionCategoryByResolutionCategoryInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInIncidentModel = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInIncidentModel = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfStatusWorkflowByStatusWorkflowInIncidentModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type QueryContextOfIncidentModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeIncidentModel>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfServiceLevelAgreement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	supportTimes?: Maybe<FilterDoubleOperator> /**  */
	resolutionTimes?: Maybe<FilterDoubleOperator> /**  */
	ticketCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	priorityIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	workTimeId?: Maybe<FilterGuidOperator> /**  */
	workTime?: Maybe<FilterDenormalizeOfWorkTimeByWorkTimeInServiceLevelAgreement> /**  */
	dayOffIds?: Maybe<ArrayUuidTypeOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	populationsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfServiceLevelAgreement>> /**  */
	or?: Array<Maybe<FilterOfServiceLevelAgreement>> /**  */
	nor?: Array<Maybe<FilterOfServiceLevelAgreement>> /**  */
	not?: Array<Maybe<FilterOfServiceLevelAgreement>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkTimeByWorkTimeInServiceLevelAgreement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterInt64Operator = {
	eq?: Maybe<number> /**  */
	ne?: Maybe<number> /**  */
	in?: Array<Maybe<number>> /**  */
	nin?: Array<Maybe<number>> /**  */
	gt?: Maybe<number> /**  */
	gte?: Maybe<number> /**  */
	lt?: Maybe<number> /**  */
	lte?: Maybe<number> /**  */
	mod?: Maybe<ModOperator> /**  */
	regex?: Maybe<RegexOperator> /**  */
}


/**  */
export type FilterRankStateOperator = {
	eq?: Maybe<RankState> /**  */
	ne?: Maybe<RankState> /**  */
	in?: Array<Maybe<RankState>> /**  */
	nin?: Array<Maybe<RankState>> /**  */
}


/**  */
export type QueryContextOfServiceLevelAgreement = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeServiceLevelAgreement>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfDayOff = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	businessTimeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	daysConfig?: Maybe<ArrayDayOffConfigOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDayOff>> /**  */
	or?: Array<Maybe<FilterOfDayOff>> /**  */
	nor?: Array<Maybe<FilterOfDayOff>> /**  */
	not?: Array<Maybe<FilterOfDayOff>> /**  */
}


/**  */
export type ArrayDayOffConfigOperator = {
	elemMatch?: Maybe<FilterOfDayOffConfig> /**  */
}


/**  */
export type FilterOfDayOffConfig = {
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	and?: Array<Maybe<FilterOfDayOffConfig>> /**  */
	or?: Array<Maybe<FilterOfDayOffConfig>> /**  */
	nor?: Array<Maybe<FilterOfDayOffConfig>> /**  */
	not?: Array<Maybe<FilterOfDayOffConfig>> /**  */
}


/**  */
export type QueryContextOfDayOff = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDayOff>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DayOff */
export type QuerySortTypeDayOff = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	serviceLevelAgreementIds?: Maybe<Sort> /** Représente le type de trie. */
	businessTimeIds?: Maybe<Sort> /** Représente le type de trie. */
	daysConfig?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfUser = {
	subId?: Maybe<FilterGuidOperator> /**  */
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	userAccountControl?: Maybe<FilterInt32Operator> /**  */
	userAccountDisabled?: Maybe<FilterBooleanOperator> /**  */
	userAccountExpires?: Maybe<FilterDateTimeOperator> /**  */
	initial?: Maybe<FilterStringOperator> /**  */
	registrationNumber?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	helpDeskActivated?: Maybe<FilterBooleanOperator> /**  */
	function?: Maybe<FilterStringOperator> /**  */
	instantMessaging?: Maybe<FilterStringOperator> /**  */
	userPrincipalName?: Maybe<FilterStringOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	secretary?: Maybe<FilterStringOperator> /**  */
	hiringDate?: Maybe<FilterDateTimeOperator> /**  */
	exitDate?: Maybe<FilterDateTimeOperator> /**  */
	lastLogOn?: Maybe<FilterDateTimeOperator> /**  */
	lastLogonTimestamp?: Maybe<FilterDateTimeOperator> /**  */
	azureLastLogOnInteractive?: Maybe<FilterDateTimeOperator> /**  */
	azureLastLogOnNotInteractive?: Maybe<FilterDateTimeOperator> /**  */
	lastLogOff?: Maybe<FilterDateTimeOperator> /**  */
	lastConnected?: Maybe<FilterDateTimeOperator> /**  */
	accessFailedCount?: Maybe<FilterInt32Operator> /**  */
	homeDrive?: Maybe<FilterStringOperator> /**  */
	homeDirectory?: Maybe<FilterStringOperator> /**  */
	logonCount?: Maybe<FilterInt32Operator> /**  */
	profilePath?: Maybe<FilterStringOperator> /**  */
	scriptPath?: Maybe<FilterStringOperator> /**  */
	logOnTo?: Maybe<FilterStringOperator> /**  */
	allowDialIn?: Maybe<FilterBooleanOperator> /**  */
	officeName?: Maybe<FilterStringOperator> /**  */
	principalWebSite?: Maybe<FilterStringOperator> /**  */
	settings?: Maybe<FilterOfSettings> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInUser> /**  */
	userPasswords?: Maybe<ArrayUserPasswordOperator> /**  */
	operatorTeamIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainOperatorTeamId?: Maybe<FilterGuidOperator> /**  */
	mainOperatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByMainOperatorTeamInUser> /**  */
	isOperator?: Maybe<FilterBooleanOperator> /**  */
	loans?: Maybe<ArrayLoanOperator> /**  */
	ticketsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	userHabilitations?: Maybe<ArrayUserHabilitationOperator> /**  */
	phones?: Maybe<ArrayPhoneOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	webSites?: Maybe<ArrayStringTypeOperator> /**  */
	locations?: Maybe<ArrayLocationOperator> /**  */
	stockManagers?: Maybe<ArrayLocationOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInUser> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	department?: Maybe<FilterStringOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	manager?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	managerRef?: Maybe<FilterDenormalizeOfUserByManagerRefInUser> /**  */
	userManager?: Maybe<FilterDenormalizeOfUserByUserManagerInUser> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	supplier?: Maybe<FilterDenormalizeOfSupplierBySupplierInUser> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	containerDistinguishedName?: Maybe<FilterStringOperator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	firstImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportAzureAd?: Maybe<FilterDateTimeOperator> /**  */
	scanByLdapId?: Maybe<FilterGuidOperator> /**  */
	scanByLdap?: Maybe<FilterDenormalizeOfScanConfigurationByScanByLdapInUser> /**  */
	principalPhone?: Maybe<FilterStringOperator> /**  */
	principalFax?: Maybe<FilterStringOperator> /**  */
	principalHomePhone?: Maybe<FilterStringOperator> /**  */
	principalIpPhone?: Maybe<FilterStringOperator> /**  */
	principalMobile?: Maybe<FilterStringOperator> /**  */
	principalPager?: Maybe<FilterStringOperator> /**  */
	memberOf?: Maybe<ArrayStringTypeOperator> /**  */
	memberOfAzureGroup?: Maybe<ArrayGroupAzureAdOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	invitation?: Maybe<FilterOfInvitation> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	userAffectedRule?: Maybe<FilterOfUserAffectedRule> /**  */
	populationsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	forceLdapImport?: Maybe<FilterBooleanOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	memberOfSecurityGroupIds?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	memberOfPopulationIds?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	softwarePackageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inviteById?: Maybe<FilterGuidOperator> /**  */
	inviteBy?: Maybe<FilterDenormalizeOfUserByInviteByInUser> /**  */
	revokeById?: Maybe<FilterGuidOperator> /**  */
	revokeBy?: Maybe<FilterDenormalizeOfUserByRevokeByInUser> /**  */
	costHours?: Maybe<FilterDoubleOperator> /**  */
	supportLevelOperator?: Maybe<FilterInt32Operator> /**  */
	azureObjectId?: Maybe<FilterStringOperator> /**  */
	managerAzureObjectId?: Maybe<FilterStringOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	mainAsset?: Maybe<FilterDenormalizeOfAssetByMainAssetInUser> /**  */
	inheritLocationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inheritLocationCharterLocation?: Maybe<FilterOfInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<FilterOfInheritLocationCharter> /**  */
	entityLocationCharter?: Maybe<FilterOfEntityLocationCharter> /**  */
	privilegeId?: Maybe<FilterGuidOperator> /**  */
	privilege?: Maybe<FilterDenormalizeOfPrivilegeByPrivilegeInUser> /**  */
	userPrivilegeId?: Maybe<FilterGuidOperator> /**  */
	userPrivilege?: Maybe<FilterDenormalizeOfUserPrivilegeByUserPrivilegeInUser> /**  */
	locationCharterAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	securityGroupAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	populationAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	calendarId?: Maybe<FilterGuidOperator> /**  */
	calendar?: Maybe<FilterDenormalizeOfCalendarConnectorByCalendarInUser> /**  */
	modelRulesDesignerIds?: Maybe<ArrayUuidTypeOperator> /**  */
	formDesignerIds?: Maybe<ArrayUuidTypeOperator> /**  */
	operatorIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	operatorRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	operatorProblemRuleId?: Maybe<FilterGuidOperator> /**  */
	serviceDeskActivated?: Maybe<FilterBooleanOperator> /**  */
	dashboardIds?: Maybe<ArrayUuidTypeOperator> /**  */
	allowEditOrganization?: Maybe<FilterBooleanOperator> /**  */
	recipentSystemNotification?: Maybe<FilterBooleanOperator> /**  */
	businessTimeId?: Maybe<FilterGuidOperator> /**  */
	businessTime?: Maybe<FilterDenormalizeOfBusinessTimeByBusinessTimeInUser> /**  */
	userRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userContractType?: Maybe<FilterStringOperator> /**  */
	operatorIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	operatorRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	operatorProblemListDesignerId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	memberOfIds?: Maybe<ArrayUuidTypeOperator> /**  */
	permissionScopes?: Maybe<ArrayPermissionScopeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUser>> /**  */
	or?: Array<Maybe<FilterOfUser>> /**  */
	nor?: Array<Maybe<FilterOfUser>> /**  */
	not?: Array<Maybe<FilterOfUser>> /**  */
}


/**  */
export type FilterOfSettings = {
	features?: Maybe<ArrayFeatureOperator> /**  */
	preferences?: Maybe<FilterOfPreferences> /**  */
	and?: Array<Maybe<FilterOfSettings>> /**  */
	or?: Array<Maybe<FilterOfSettings>> /**  */
	nor?: Array<Maybe<FilterOfSettings>> /**  */
	not?: Array<Maybe<FilterOfSettings>> /**  */
}


/**  */
export type ArrayFeatureOperator = {
	elemMatch?: Maybe<FilterOfFeature> /**  */
}


/**  */
export type FilterOfFeature = {
	name?: Maybe<FilterStringOperator> /**  */
	rank?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfFeature>> /**  */
	or?: Array<Maybe<FilterOfFeature>> /**  */
	nor?: Array<Maybe<FilterOfFeature>> /**  */
	not?: Array<Maybe<FilterOfFeature>> /**  */
}


/**  */
export type FilterOfPreferences = {
	lang?: Maybe<FilterStringOperator> /**  */
	theme?: Maybe<FilterStringOperator> /**  */
	dashboardId?: Maybe<FilterStringOperator> /**  */
	paginate?: Maybe<FilterInt32Operator> /**  */
	layouts?: Maybe<ArrayLayoutOperator> /**  */
	defaultLayouts?: Maybe<ArrayLayoutOperator> /**  */
	pinStateMenu?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfPreferences>> /**  */
	or?: Array<Maybe<FilterOfPreferences>> /**  */
	nor?: Array<Maybe<FilterOfPreferences>> /**  */
	not?: Array<Maybe<FilterOfPreferences>> /**  */
}


/**  */
export type ArrayLayoutOperator = {
	elemMatch?: Maybe<FilterOfLayout> /**  */
}


/**  */
export type FilterOfLayout = {
	key?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	filterName?: Maybe<FilterStringOperator> /**  */
	filterValue?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLayout>> /**  */
	or?: Array<Maybe<FilterOfLayout>> /**  */
	nor?: Array<Maybe<FilterOfLayout>> /**  */
	not?: Array<Maybe<FilterOfLayout>> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type ArrayUserPasswordOperator = {
	elemMatch?: Maybe<FilterOfUserPassword> /**  */
}


/**  */
export type FilterOfUserPassword = {
	description?: Maybe<FilterStringOperator> /**  */
	logIn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	authentificationTypeId?: Maybe<FilterGuidOperator> /**  */
	authentificationType?: Maybe<FilterOfAuthentificationType> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserPassword>> /**  */
	or?: Array<Maybe<FilterOfUserPassword>> /**  */
	nor?: Array<Maybe<FilterOfUserPassword>> /**  */
	not?: Array<Maybe<FilterOfUserPassword>> /**  */
}


/**  */
export type FilterOfAuthentificationType = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	userPasswords?: Maybe<ArrayUserPasswordOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAuthentificationType>> /**  */
	or?: Array<Maybe<FilterOfAuthentificationType>> /**  */
	nor?: Array<Maybe<FilterOfAuthentificationType>> /**  */
	not?: Array<Maybe<FilterOfAuthentificationType>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByMainOperatorTeamInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type ArrayLoanOperator = {
	elemMatch?: Maybe<FilterOfLoan> /**  */
}


/**  */
export type FilterOfLoan = {
	number?: Maybe<FilterInt32Operator> /**  */
	slipNumber?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	provision?: Maybe<FilterDateTimeOperator> /**  */
	returnDate?: Maybe<FilterDateTimeOperator> /**  */
	realReturnDate?: Maybe<FilterDateTimeOperator> /**  */
	duration?: Maybe<FilterDoubleOperator> /**  */
	information?: Maybe<FilterStringOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	accountIds?: Maybe<ArrayUserAccountOperator> /**  */
	recordDate?: Maybe<FilterDateTimeOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	user?: Maybe<FilterDenormalizeOfUserByUserInLoan> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	manager?: Maybe<FilterDenormalizeOfUserByManagerInLoan> /**  */
	contactId?: Maybe<FilterGuidOperator> /**  */
	loanStatusId?: Maybe<FilterGuidOperator> /**  */
	loanStatus?: Maybe<FilterDenormalizeOfLoanStatusByLoanStatusInLoan> /**  */
	loanBorrowerType?: Maybe<FilterLoanBorrowerTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLoan>> /**  */
	or?: Array<Maybe<FilterOfLoan>> /**  */
	nor?: Array<Maybe<FilterOfLoan>> /**  */
	not?: Array<Maybe<FilterOfLoan>> /**  */
}


/**  */
export type ArrayUserAccountOperator = {
	elemMatch?: Maybe<FilterOfUserAccount> /**  */
}


/**  */
export type FilterOfUserAccount = {
	accountType?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	fullName?: Maybe<FilterStringOperator> /**  */
	localAccount?: Maybe<FilterBooleanOperator> /**  */
	lockout?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	passwordChangeable?: Maybe<FilterBooleanOperator> /**  */
	passwordExpires?: Maybe<FilterBooleanOperator> /**  */
	passwordRequired?: Maybe<FilterBooleanOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	sidType?: Maybe<FilterInt32Operator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInUserAccount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserAccount>> /**  */
	or?: Array<Maybe<FilterOfUserAccount>> /**  */
	nor?: Array<Maybe<FilterOfUserAccount>> /**  */
	not?: Array<Maybe<FilterOfUserAccount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInUserAccount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOwnerInAsset = {
	vip?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserInLoan = {
	vip?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByManagerInLoan = {
	vip?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLoanStatusByLoanStatusInLoan = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterLoanBorrowerTypeOperator = {
	eq?: Maybe<LoanBorrowerType> /**  */
	ne?: Maybe<LoanBorrowerType> /**  */
	in?: Array<Maybe<LoanBorrowerType>> /**  */
	nin?: Array<Maybe<LoanBorrowerType>> /**  */
}


/**  */
export type ArrayUserHabilitationOperator = {
	elemMatch?: Maybe<FilterOfUserHabilitation> /**  */
}


/**  */
export type FilterOfUserHabilitation = {
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	habilitationId?: Maybe<FilterGuidOperator> /**  */
	habilitation?: Maybe<FilterOfHabilitation> /**  */
	permanent?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserHabilitation>> /**  */
	or?: Array<Maybe<FilterOfUserHabilitation>> /**  */
	nor?: Array<Maybe<FilterOfUserHabilitation>> /**  */
	not?: Array<Maybe<FilterOfUserHabilitation>> /**  */
}


/**  */
export type FilterOfHabilitation = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	userHabilitations?: Maybe<ArrayUserHabilitationOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfHabilitation>> /**  */
	or?: Array<Maybe<FilterOfHabilitation>> /**  */
	nor?: Array<Maybe<FilterOfHabilitation>> /**  */
	not?: Array<Maybe<FilterOfHabilitation>> /**  */
}


/**  */
export type ArrayPhoneOperator = {
	elemMatch?: Maybe<FilterOfPhone> /**  */
}


/**  */
export type FilterOfPhone = {
	number?: Maybe<FilterStringOperator> /**  */
	phoneType?: Maybe<FilterPhoneEnumOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPhone>> /**  */
	or?: Array<Maybe<FilterOfPhone>> /**  */
	nor?: Array<Maybe<FilterOfPhone>> /**  */
	not?: Array<Maybe<FilterOfPhone>> /**  */
}


/**  */
export type FilterPhoneEnumOperator = {
	eq?: Maybe<PhoneEnum> /**  */
	ne?: Maybe<PhoneEnum> /**  */
	in?: Array<Maybe<PhoneEnum>> /**  */
	nin?: Array<Maybe<PhoneEnum>> /**  */
}


/**  */
export type FilterOfAddress = {
	street?: Maybe<FilterStringOperator> /**  */
	additional?: Maybe<FilterStringOperator> /**  */
	city?: Maybe<FilterStringOperator> /**  */
	province?: Maybe<FilterStringOperator> /**  */
	country?: Maybe<FilterStringOperator> /**  */
	zipCode?: Maybe<FilterStringOperator> /**  */
	postalCode?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAddress>> /**  */
	or?: Array<Maybe<FilterOfAddress>> /**  */
	nor?: Array<Maybe<FilterOfAddress>> /**  */
	not?: Array<Maybe<FilterOfAddress>> /**  */
}


/**  */
export type ArrayLocationOperator = {
	elemMatch?: Maybe<FilterOfLocation> /**  */
}


/**  */
export type FilterOfLocation = {
	error?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	allowSelection?: Maybe<FilterBooleanOperator> /**  */
	stock?: Maybe<FilterBooleanOperator> /**  */
	maxCapacity?: Maybe<FilterBooleanOperator> /**  */
	stockManagerId?: Maybe<FilterGuidOperator> /**  */
	rfid?: Maybe<FilterStringOperator> /**  */
	barCode?: Maybe<FilterStringOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	responsableId?: Maybe<FilterGuidOperator> /**  */
	locationCategoryId?: Maybe<FilterGuidOperator> /**  */
	primaryContactId?: Maybe<FilterGuidOperator> /**  */
	templateId?: Maybe<FilterGuidOperator> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	firstImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	scanByLdapId?: Maybe<FilterGuidOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	allRulesMustBeValidAsset?: Maybe<FilterBooleanOperator> /**  */
	allRulesMustBeValidUser?: Maybe<FilterBooleanOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	phone?: Maybe<FilterStringOperator> /**  */
	principalWebSite?: Maybe<FilterStringOperator> /**  */
	parentNodes?: Maybe<ArrayStringTypeOperator> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
	budgetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	loanIds?: Maybe<ArrayUuidTypeOperator> /**  */
	roomNumber?: Maybe<FilterStringOperator> /**  */
	loanable?: Maybe<FilterBooleanOperator> /**  */
	contactId?: Maybe<FilterGuidOperator> /**  */
	buildingTypeId?: Maybe<FilterGuidOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	loan?: Maybe<FilterDenormalizeOfLoanByLoanInLocation> /**  */
	assetModelStockEntryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLocation>> /**  */
	or?: Array<Maybe<FilterOfLocation>> /**  */
	nor?: Array<Maybe<FilterOfLocation>> /**  */
	not?: Array<Maybe<FilterOfLocation>> /**  */
}


/**  */
export type FilterDenormalizeOfLoanByLoanInLocation = {
	provision?: Maybe<FilterDateTimeOperator> /**  */
	returnDate?: Maybe<FilterDateTimeOperator> /**  */
	realReturnDate?: Maybe<FilterDateTimeOperator> /**  */
	loanStatusId?: Maybe<FilterGuidOperator> /**  */
	loanStatus?: Maybe<FilterDenormalizeOfLoanStatusByLoanStatusInLoan> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByManagerRefInUser = {
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	hiringDate?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserManagerInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfSupplierBySupplierInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanByLdapInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type ArrayGroupAzureAdOperator = {
	elemMatch?: Maybe<FilterOfGroupAzureAd> /**  */
}


/**  */
export type FilterOfGroupAzureAd = {
	objectId?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	memberOf?: Maybe<ArrayStringTypeOperator> /**  */
	parentId?: Maybe<FilterStringOperator> /**  */
	applicationAzureId?: Maybe<FilterGuidOperator> /**  */
	members?: Maybe<ArrayMembersAzureAdOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfGroupAzureAd>> /**  */
	or?: Array<Maybe<FilterOfGroupAzureAd>> /**  */
	nor?: Array<Maybe<FilterOfGroupAzureAd>> /**  */
	not?: Array<Maybe<FilterOfGroupAzureAd>> /**  */
}


/**  */
export type ArrayMembersAzureAdOperator = {
	elemMatch?: Maybe<FilterOfMembersAzureAd> /**  */
}


/**  */
export type FilterOfMembersAzureAd = {
	objectId?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterMemberAzureTypeOperator> /**  */
	and?: Array<Maybe<FilterOfMembersAzureAd>> /**  */
	or?: Array<Maybe<FilterOfMembersAzureAd>> /**  */
	nor?: Array<Maybe<FilterOfMembersAzureAd>> /**  */
	not?: Array<Maybe<FilterOfMembersAzureAd>> /**  */
}


/**  */
export type FilterMemberAzureTypeOperator = {
	eq?: Maybe<MemberAzureType> /**  */
	ne?: Maybe<MemberAzureType> /**  */
	in?: Array<Maybe<MemberAzureType>> /**  */
	nin?: Array<Maybe<MemberAzureType>> /**  */
}


/**  */
export type FilterOfInvitation = {
	sendDate?: Maybe<FilterDateTimeOperator> /**  */
	acceptDate?: Maybe<FilterDateTimeOperator> /**  */
	revokeDate?: Maybe<FilterDateTimeOperator> /**  */
	token?: Maybe<FilterGuidOperator> /**  */
	accessToken?: Maybe<FilterGuidOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInvitation>> /**  */
	or?: Array<Maybe<FilterOfInvitation>> /**  */
	nor?: Array<Maybe<FilterOfInvitation>> /**  */
	not?: Array<Maybe<FilterOfInvitation>> /**  */
}


/**  */
export type FilterOfUserAffectedRule = {
	byDistinguishedName?: Maybe<FilterBooleanOperator> /**  */
	bySid?: Maybe<FilterBooleanOperator> /**  */
	byEmail?: Maybe<FilterBooleanOperator> /**  */
	byDisplayName?: Maybe<FilterBooleanOperator> /**  */
	byUserPrincipalName?: Maybe<FilterBooleanOperator> /**  */
	byLastNameAndFirstName?: Maybe<FilterBooleanOperator> /**  */
	byDomain?: Maybe<FilterBooleanOperator> /**  */
	byDifferentDomain?: Maybe<FilterBooleanOperator> /**  */
	byAzureObjectId?: Maybe<FilterBooleanOperator> /**  */
	byDomainEnum?: Maybe<FilterByDomainEnumOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	searchInDeleted?: Maybe<FilterBooleanOperator> /**  */
	searchInArchived?: Maybe<FilterBooleanOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserAffectedRule>> /**  */
	or?: Array<Maybe<FilterOfUserAffectedRule>> /**  */
	nor?: Array<Maybe<FilterOfUserAffectedRule>> /**  */
	not?: Array<Maybe<FilterOfUserAffectedRule>> /**  */
}


/**  */
export type FilterByDomainEnumOperator = {
	eq?: Maybe<ByDomainEnum> /**  */
	ne?: Maybe<ByDomainEnum> /**  */
	in?: Array<Maybe<ByDomainEnum>> /**  */
	nin?: Array<Maybe<ByDomainEnum>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByInviteByInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByRevokeByInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainAssetInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfInheritLocationCharter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	organizationalUnit?: Maybe<FilterInheritLocationCharterCategoryOperator> /**  */
	location?: Maybe<FilterInheritLocationCharterCategoryOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	assetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	optionLogonSessions?: Maybe<ArrayScanImportOptionLogonSessionOperator> /**  */
	excludeAssetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	excludeUserIds?: Maybe<ArrayUuidTypeOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInheritLocationCharter>> /**  */
	or?: Array<Maybe<FilterOfInheritLocationCharter>> /**  */
	nor?: Array<Maybe<FilterOfInheritLocationCharter>> /**  */
	not?: Array<Maybe<FilterOfInheritLocationCharter>> /**  */
}


/**  */
export type FilterInheritLocationCharterCategoryOperator = {
	eq?: Maybe<InheritLocationCharterCategory> /**  */
	ne?: Maybe<InheritLocationCharterCategory> /**  */
	in?: Array<Maybe<InheritLocationCharterCategory>> /**  */
	nin?: Array<Maybe<InheritLocationCharterCategory>> /**  */
}


/**  */
export type ArrayScanImportOptionLogonSessionOperator = {
	elemMatch?: Maybe<FilterOfScanImportOptionLogonSession> /**  */
}


/**  */
export type FilterOfScanImportOptionLogonSession = {
	value?: Maybe<FilterStringOperator> /**  */
	scanExcludeOperator?: Maybe<FilterScanExcludeOperatorOperator> /**  */
	and?: Array<Maybe<FilterOfScanImportOptionLogonSession>> /**  */
	or?: Array<Maybe<FilterOfScanImportOptionLogonSession>> /**  */
	nor?: Array<Maybe<FilterOfScanImportOptionLogonSession>> /**  */
	not?: Array<Maybe<FilterOfScanImportOptionLogonSession>> /**  */
}


/**  */
export type FilterScanExcludeOperatorOperator = {
	eq?: Maybe<ScanExcludeOperator> /**  */
	ne?: Maybe<ScanExcludeOperator> /**  */
	in?: Array<Maybe<ScanExcludeOperator>> /**  */
	nin?: Array<Maybe<ScanExcludeOperator>> /**  */
}


/**  */
export type FilterOfEntityLocationCharter = {
	onLocationId?: Maybe<FilterOfLocationCharterSummary> /**  */
	onOrganizationalUnitId?: Maybe<FilterOfLocationCharterSummary> /**  */
	and?: Array<Maybe<FilterOfEntityLocationCharter>> /**  */
	or?: Array<Maybe<FilterOfEntityLocationCharter>> /**  */
	nor?: Array<Maybe<FilterOfEntityLocationCharter>> /**  */
	not?: Array<Maybe<FilterOfEntityLocationCharter>> /**  */
}


/**  */
export type FilterOfLocationCharterSummary = {
	value?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLocationCharterSummary>> /**  */
	or?: Array<Maybe<FilterOfLocationCharterSummary>> /**  */
	nor?: Array<Maybe<FilterOfLocationCharterSummary>> /**  */
	not?: Array<Maybe<FilterOfLocationCharterSummary>> /**  */
}


/**  */
export type FilterDenormalizeOfPrivilegeByPrivilegeInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserPrivilegeByUserPrivilegeInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfCalendarConnectorByCalendarInUser = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfBusinessTimeByBusinessTimeInUser = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type ArrayPermissionScopeOperator = {
	elemMatch?: Maybe<FilterOfPermissionScope> /**  */
}


/**  */
export type FilterOfPermissionScope = {
	value?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterPermissionScopeRoleOperator> /**  */
	permissions?: Maybe<ArrayPermissionOperator> /**  */
	and?: Array<Maybe<FilterOfPermissionScope>> /**  */
	or?: Array<Maybe<FilterOfPermissionScope>> /**  */
	nor?: Array<Maybe<FilterOfPermissionScope>> /**  */
	not?: Array<Maybe<FilterOfPermissionScope>> /**  */
}


/**  */
export type FilterPermissionScopeRoleOperator = {
	eq?: Maybe<PermissionScopeRole> /**  */
	ne?: Maybe<PermissionScopeRole> /**  */
	in?: Array<Maybe<PermissionScopeRole>> /**  */
	nin?: Array<Maybe<PermissionScopeRole>> /**  */
}


/**  */
export type ArrayPermissionOperator = {
	elemMatch?: Maybe<FilterOfPermission> /**  */
}


/**  */
export type FilterOfPermission = {
	order?: Maybe<FilterInt32Operator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	access?: Maybe<FilterPermissionAccessOperator> /**  */
	inheritFromIds?: Maybe<ArrayUuidTypeOperator> /**  */
	and?: Array<Maybe<FilterOfPermission>> /**  */
	or?: Array<Maybe<FilterOfPermission>> /**  */
	nor?: Array<Maybe<FilterOfPermission>> /**  */
	not?: Array<Maybe<FilterOfPermission>> /**  */
}


/**  */
export type FilterPermissionAccessOperator = {
	eq?: Maybe<PermissionAccess> /**  */
	ne?: Maybe<PermissionAccess> /**  */
	in?: Array<Maybe<PermissionAccess>> /**  */
	nin?: Array<Maybe<PermissionAccess>> /**  */
}


/**  */
export type QueryContextOfUser = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUser>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfBusinessTime = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	config?: Maybe<FilterOfWorkTimeConfig> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dayOffIds?: Maybe<ArrayUuidTypeOperator> /**  */
	pauseStatusIds?: Maybe<ArrayUuidTypeOperator> /**  */
	timeZoneId?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBusinessTime>> /**  */
	or?: Array<Maybe<FilterOfBusinessTime>> /**  */
	nor?: Array<Maybe<FilterOfBusinessTime>> /**  */
	not?: Array<Maybe<FilterOfBusinessTime>> /**  */
}


/**  */
export type FilterOfWorkTimeConfig = {
	monday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	tuesday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	wednesday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	thursday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	friday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	saturday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	sunday?: Maybe<FilterOfWorkTimeConfigDay> /**  */
	and?: Array<Maybe<FilterOfWorkTimeConfig>> /**  */
	or?: Array<Maybe<FilterOfWorkTimeConfig>> /**  */
	nor?: Array<Maybe<FilterOfWorkTimeConfig>> /**  */
	not?: Array<Maybe<FilterOfWorkTimeConfig>> /**  */
}


/**  */
export type FilterOfWorkTimeConfigDay = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	times?: Maybe<ArrayWorkTimeConfigTimeOperator> /**  */
	and?: Array<Maybe<FilterOfWorkTimeConfigDay>> /**  */
	or?: Array<Maybe<FilterOfWorkTimeConfigDay>> /**  */
	nor?: Array<Maybe<FilterOfWorkTimeConfigDay>> /**  */
	not?: Array<Maybe<FilterOfWorkTimeConfigDay>> /**  */
}


/**  */
export type ArrayWorkTimeConfigTimeOperator = {
	elemMatch?: Maybe<FilterOfWorkTimeConfigTime> /**  */
}


/**  */
export type FilterOfWorkTimeConfigTime = {
	start?: Maybe<FilterDateTimeOperator> /**  */
	startTick?: Maybe<FilterInt64Operator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	endTick?: Maybe<FilterInt64Operator> /**  */
	and?: Array<Maybe<FilterOfWorkTimeConfigTime>> /**  */
	or?: Array<Maybe<FilterOfWorkTimeConfigTime>> /**  */
	nor?: Array<Maybe<FilterOfWorkTimeConfigTime>> /**  */
	not?: Array<Maybe<FilterOfWorkTimeConfigTime>> /**  */
}


/**  */
export type QueryContextOfBusinessTime = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBusinessTime>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfPriority = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPriority>> /**  */
	or?: Array<Maybe<FilterOfPriority>> /**  */
	nor?: Array<Maybe<FilterOfPriority>> /**  */
	not?: Array<Maybe<FilterOfPriority>> /**  */
}


/**  */
export type QueryContextOfPriority = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePriority>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfArticleKnowledge = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	descriptionText?: Maybe<FilterOfTranslatedField> /**  */
	solution?: Maybe<FilterOfTranslatedField> /**  */
	solutionText?: Maybe<FilterOfTranslatedField> /**  */
	keyWords?: Maybe<ArrayStringTypeOperator> /**  */
	links?: Maybe<ArrayStringTypeOperator> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInArticleKnowledge> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	populationsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastUpdateById?: Maybe<FilterGuidOperator> /**  */
	lastUpdateBy?: Maybe<FilterDenormalizeOfUserByLastUpdateByInArticleKnowledge> /**  */
	lastUpdated?: Maybe<FilterDateTimeOperator> /**  */
	lastViewByOperator?: Maybe<FilterDateTimeOperator> /**  */
	countViewOperator?: Maybe<FilterInt32Operator> /**  */
	lastViewByUser?: Maybe<FilterDateTimeOperator> /**  */
	countViewUser?: Maybe<FilterInt32Operator> /**  */
	averageRating?: Maybe<FilterDoubleOperator> /**  */
	countRating?: Maybe<FilterDoubleOperator> /**  */
	knowledgeNumber?: Maybe<FilterInt32Operator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	resolvedTickets?: Maybe<FilterInt32Operator> /**  */
	lastResolvedTicketDate?: Maybe<FilterDateTimeOperator> /**  */
	articleKnowledgeCategoryId?: Maybe<FilterGuidOperator> /**  */
	articleKnowledgeCategory?: Maybe<FilterDenormalizeOfArticleKnowledgeCategoryByArticleKnowledgeCategoryInArticleKnowledge> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfArticleKnowledge>> /**  */
	or?: Array<Maybe<FilterOfArticleKnowledge>> /**  */
	nor?: Array<Maybe<FilterOfArticleKnowledge>> /**  */
	not?: Array<Maybe<FilterOfArticleKnowledge>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInArticleKnowledge = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByLastUpdateByInArticleKnowledge = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfArticleKnowledgeCategoryByArticleKnowledgeCategoryInArticleKnowledge = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfArticleKnowledge = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeArticleKnowledge>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfScanConfiguration = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanConfiguration> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanConfiguration> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	applicationAzureAdId?: Maybe<FilterGuidOperator> /**  */
	importDisabledUser?: Maybe<FilterBooleanOperator> /**  */
	importMember?: Maybe<FilterBooleanOperator> /**  */
	importGuest?: Maybe<FilterBooleanOperator> /**  */
	importNoType?: Maybe<FilterBooleanOperator> /**  */
	importGroup?: Maybe<FilterBooleanOperator> /**  */
	groupObjectIds?: Maybe<ArrayStringTypeOperator> /**  */
	fieldMappingId?: Maybe<FilterGuidOperator> /**  */
	objectClassMappingId?: Maybe<FilterGuidOperator> /**  */
	recursiveGroup?: Maybe<FilterBooleanOperator> /**  */
	addressIpStart?: Maybe<FilterStringOperator> /**  */
	addressIpEnd?: Maybe<FilterStringOperator> /**  */
	hostName?: Maybe<ArrayStringTypeOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	credentialId?: Maybe<FilterGuidOperator> /**  */
	ldapCredential?: Maybe<FilterDenormalizeOfNetworkCredentialByLdapCredentialInScanConfiguration> /**  */
	useSsl?: Maybe<FilterBooleanOperator> /**  */
	usePort?: Maybe<FilterInt32Operator> /**  */
	protocolVersion?: Maybe<FilterInt32Operator> /**  */
	connectionTimeoutIn?: Maybe<FilterInt32Operator> /**  */
	ldapAuthType?: Maybe<FilterLdapAuthTypeOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	importUser?: Maybe<FilterBooleanOperator> /**  */
	importRecursiveGroup?: Maybe<FilterBooleanOperator> /**  */
	importComputer?: Maybe<FilterBooleanOperator> /**  */
	importLdapStruct?: Maybe<FilterBooleanOperator> /**  */
	disableUsers?: Maybe<FilterBooleanOperator> /**  */
	importOrganizationalUnit?: Maybe<FilterImportOrganizationalUnitOperator> /**  */
	forceSearchFilter?: Maybe<FilterBooleanOperator> /**  */
	ldapDistinguishedNames?: Maybe<ArrayStringTypeOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	workgroupName?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanConfiguration>> /**  */
	or?: Array<Maybe<FilterOfScanConfiguration>> /**  */
	nor?: Array<Maybe<FilterOfScanConfiguration>> /**  */
	not?: Array<Maybe<FilterOfScanConfiguration>> /**  */
}


/**  */
export type FilterOfAuditOption = {
	notAuditedType?: Maybe<FilterNotAuditedTypeOperator> /**  */
	sinceDay?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfAuditOption>> /**  */
	or?: Array<Maybe<FilterOfAuditOption>> /**  */
	nor?: Array<Maybe<FilterOfAuditOption>> /**  */
	not?: Array<Maybe<FilterOfAuditOption>> /**  */
}


/**  */
export type FilterNotAuditedTypeOperator = {
	eq?: Maybe<NotAuditedType> /**  */
	ne?: Maybe<NotAuditedType> /**  */
	in?: Array<Maybe<NotAuditedType>> /**  */
	nin?: Array<Maybe<NotAuditedType>> /**  */
}


/**  */
export type FilterOfPowerControl = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	hour?: Maybe<FilterInt32Operator> /**  */
	autoPurge?: Maybe<FilterBooleanOperator> /**  */
	since?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfPowerControl>> /**  */
	or?: Array<Maybe<FilterOfPowerControl>> /**  */
	nor?: Array<Maybe<FilterOfPowerControl>> /**  */
	not?: Array<Maybe<FilterOfPowerControl>> /**  */
}


/**  */
export type FilterOfWol = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	timeOut?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfWol>> /**  */
	or?: Array<Maybe<FilterOfWol>> /**  */
	nor?: Array<Maybe<FilterOfWol>> /**  */
	not?: Array<Maybe<FilterOfWol>> /**  */
}


/**  */
export type FilterOfShutDown = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	onlyNotError?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfShutDown>> /**  */
	or?: Array<Maybe<FilterOfShutDown>> /**  */
	nor?: Array<Maybe<FilterOfShutDown>> /**  */
	not?: Array<Maybe<FilterOfShutDown>> /**  */
}


/**  */
export type FilterActionHistoryStateOperator = {
	eq?: Maybe<ActionHistoryState> /**  */
	ne?: Maybe<ActionHistoryState> /**  */
	in?: Array<Maybe<ActionHistoryState>> /**  */
	nin?: Array<Maybe<ActionHistoryState>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanConfiguration = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanConfiguration = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type FilterOfIAppointment = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	allDay?: Maybe<FilterBooleanOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanByAzureAdAppointmentTaskId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	queryBuilderAppointmentTaskId?: Maybe<FilterGuidOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusId?: Maybe<FilterGuidOperator> /**  */
	taskStatusId?: Maybe<FilterGuidOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	color?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	taskId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfIAppointment>> /**  */
	or?: Array<Maybe<FilterOfIAppointment>> /**  */
	nor?: Array<Maybe<FilterOfIAppointment>> /**  */
	not?: Array<Maybe<FilterOfIAppointment>> /**  */
}


/**  */
export type FilterDenormalizeOfNetworkCredentialByLdapCredentialInScanConfiguration = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterLdapAuthTypeOperator = {
	eq?: Maybe<LdapAuthType> /**  */
	ne?: Maybe<LdapAuthType> /**  */
	in?: Array<Maybe<LdapAuthType>> /**  */
	nin?: Array<Maybe<LdapAuthType>> /**  */
}


/**  */
export type FilterImportOrganizationalUnitOperator = {
	eq?: Maybe<ImportOrganizationalUnit> /**  */
	ne?: Maybe<ImportOrganizationalUnit> /**  */
	in?: Array<Maybe<ImportOrganizationalUnit>> /**  */
	nin?: Array<Maybe<ImportOrganizationalUnit>> /**  */
}


/**  */
export type QueryContextOfScanConfiguration = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanConfiguration>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAsset = {
	isAudited?: Maybe<FilterBooleanOperator> /**  */
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	assetStatusId?: Maybe<FilterGuidOperator> /**  */
	assetStatus?: Maybe<FilterDenormalizeOfAssetStatusByAssetStatusInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	fqdn?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	power?: Maybe<FilterInt32Operator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByModelInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	skuNumber?: Maybe<FilterStringOperator> /**  */
	uuid?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	macAddresses?: Maybe<ArrayStringTypeOperator> /**  */
	inventoryNumber?: Maybe<FilterStringOperator> /**  */
	barCode?: Maybe<FilterStringOperator> /**  */
	rfid?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	comment?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	firstAudit?: Maybe<FilterDateTimeOperator> /**  */
	lastAudit?: Maybe<FilterDateTimeOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	auditState?: Maybe<FilterActionHistoryStateOperator> /**  */
	domainId?: Maybe<FilterGuidOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	phoneAsset?: Maybe<FilterStringOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	assetAffectedRule?: Maybe<FilterOfAssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<FilterOfAssetCategoryRule> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	scanConfiguration?: Maybe<FilterDenormalizeOfScanConfigurationByScanConfigurationInAsset> /**  */
	inventoryHistory?: Maybe<FilterOfInventoryHistory> /**  */
	lifeCycle?: Maybe<FilterOfLifeCycle> /**  */
	mainOf?: Maybe<ArrayAccountOperator> /**  */
	remoteControlId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInAsset> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInAsset> /**  */
	lendable?: Maybe<FilterBooleanOperator> /**  */
	featureComment?: Maybe<FilterStringOperator> /**  */
	featureMobility?: Maybe<FilterOfFeatureMobility> /**  */
	featureSimCard?: Maybe<FilterOfFeatureSimCard> /**  */
	featureMonitor?: Maybe<FilterOfFeatureMonitor> /**  */
	featureUps?: Maybe<FilterOfFeatureUps> /**  */
	featureScanner?: Maybe<FilterOfFeatureScanner> /**  */
	featureWebcam?: Maybe<FilterOfFeatureWebcam> /**  */
	featureModem?: Maybe<FilterOfFeatureModem> /**  */
	featureTerminal?: Maybe<FilterOfFeatureTerminal> /**  */
	featureSwitch?: Maybe<FilterOfFeatureSwitch> /**  */
	featureNetwork?: Maybe<FilterOfFeatureNetwork> /**  */
	featurePrinter?: Maybe<FilterOfFeaturePrinter> /**  */
	featureVideoProjector?: Maybe<FilterOfFeatureVideoProjector> /**  */
	featureMobile?: Maybe<FilterOfFeatureMobile> /**  */
	firstMobileDevices?: Maybe<ArrayFeatureMobileOperator> /**  */
	secondMobileDevices?: Maybe<ArrayFeatureMobileOperator> /**  */
	featureTelephony?: Maybe<FilterOfFeatureTelephony> /**  */
	createdModelId?: Maybe<FilterGuidOperator> /**  */
	createdModel?: Maybe<FilterDenormalizeOfAssetModelByCreatedModelInAsset> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	outStockIds?: Maybe<ArrayUuidTypeOperator> /**  */
	stockTransfersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	detectWarranty?: Maybe<FilterOfDetectWarranty> /**  */
	financial?: Maybe<FilterOfAssetFinancial> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	loan?: Maybe<FilterDenormalizeOfLoanByLoanInAsset> /**  */
	assetNamingConvention?: Maybe<FilterOfAssetNamingConvention> /**  */
	entityLocationCharter?: Maybe<FilterOfEntityLocationCharter> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastCommandId?: Maybe<FilterGuidOperator> /**  */
	auditResult?: Maybe<FilterOfAuditResult> /**  */
	operatingSystem?: Maybe<FilterStringOperator> /**  */
	mainOperatingSystemId?: Maybe<FilterGuidOperator> /**  */
	mainOperatingSystem?: Maybe<FilterDenormalizeOfOperatingSystemByMainOperatingSystemInAsset> /**  */
	memories?: Maybe<FilterDoubleOperator> /**  */
	processor?: Maybe<FilterStringOperator> /**  */
	warrantyStart?: Maybe<FilterDateTimeOperator> /**  */
	warrantyEnd?: Maybe<FilterDateTimeOperator> /**  */
	loanable?: Maybe<FilterBooleanOperator> /**  */
	groupIds?: Maybe<ArrayUuidTypeOperator> /**  */
	loanIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inheritLocationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	softwarePackageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	commandIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	deliveryAsset?: Maybe<FilterOfAssetDelivery> /**  */
	lastCalcRenewDate?: Maybe<FilterDateTimeOperator> /**  */
	campaignIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	clarilogLocalAgent?: Maybe<FilterOfClarilogLocalAgent> /**  */
	ticketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainImpactedTicketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetModelIds?: Maybe<ArrayUuidTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	inheritLocationCharterLocation?: Maybe<FilterOfInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<FilterOfInheritLocationCharter> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAsset>> /**  */
	or?: Array<Maybe<FilterOfAsset>> /**  */
	nor?: Array<Maybe<FilterOfAsset>> /**  */
	not?: Array<Maybe<FilterOfAsset>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetStatusByAssetStatusInAsset = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfModelManufacturerByModelInAsset = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfAssetAffectedRule = {
	byNetbios?: Maybe<FilterBooleanOperator> /**  */
	byMacAddress?: Maybe<FilterBooleanOperator> /**  */
	byIpAddress?: Maybe<FilterBooleanOperator> /**  */
	bySerialNumber?: Maybe<FilterBooleanOperator> /**  */
	byUuid?: Maybe<FilterBooleanOperator> /**  */
	byDistinguishedName?: Maybe<FilterBooleanOperator> /**  */
	byFqdn?: Maybe<FilterBooleanOperator> /**  */
	byName?: Maybe<FilterBooleanOperator> /**  */
	auditTypes?: Maybe<ArrayAuditTypeTypeOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	searchInDeleted?: Maybe<FilterBooleanOperator> /**  */
	searchInArchived?: Maybe<FilterBooleanOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAffectedRule>> /**  */
	or?: Array<Maybe<FilterOfAssetAffectedRule>> /**  */
	nor?: Array<Maybe<FilterOfAssetAffectedRule>> /**  */
	not?: Array<Maybe<FilterOfAssetAffectedRule>> /**  */
}


/**  */
export type ArrayAuditTypeTypeOperator = {
	all?: Array<Maybe<AuditType>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterAuditTypeOperator> /**  */
}


/**  */
export type FilterAuditTypeOperator = {
	eq?: Maybe<AuditType> /**  */
	ne?: Maybe<AuditType> /**  */
	in?: Array<Maybe<AuditType>> /**  */
	nin?: Array<Maybe<AuditType>> /**  */
}


/**  */
export type FilterOfAssetCategoryRule = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAssetCategoryRule> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetCategoryRule>> /**  */
	or?: Array<Maybe<FilterOfAssetCategoryRule>> /**  */
	nor?: Array<Maybe<FilterOfAssetCategoryRule>> /**  */
	not?: Array<Maybe<FilterOfAssetCategoryRule>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByAssetCategoryInAssetCategoryRule = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanConfigurationInAsset = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfInventoryHistory = {
	clientApplicationtName?: Maybe<FilterStringOperator> /**  */
	clientApplicationtVersion?: Maybe<FilterStringOperator> /**  */
	auditTimes?: Maybe<ArrayAuditTimeOperator> /**  */
	networkCredentialId?: Maybe<FilterGuidOperator> /**  */
	networkCredential?: Maybe<FilterDenormalizeOfNetworkCredentialByNetworkCredentialInInventoryHistory> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	scanConfiguration?: Maybe<FilterDenormalizeOfScanConfigurationByScanConfigurationInInventoryHistory> /**  */
	auditType?: Maybe<FilterAuditTypeOperator> /**  */
	new?: Maybe<FilterBooleanOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	resolveName?: Maybe<FilterStringOperator> /**  */
	scanImportOptionId?: Maybe<FilterGuidOperator> /**  */
	scanImportOption?: Maybe<FilterDenormalizeOfScanImportOptionByScanImportOptionInInventoryHistory> /**  */
	attempts?: Maybe<FilterInt32Operator> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	lastChanged?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	actionHistoryState?: Maybe<FilterActionHistoryStateOperator> /**  */
	host?: Maybe<FilterStringOperator> /**  */
	clarilogException?: Maybe<FilterOfClarilogException> /**  */
	progressValue?: Maybe<FilterInt32Operator> /**  */
	progressDisplay?: Maybe<FilterStringOperator> /**  */
	log?: Maybe<FilterStringOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInInventoryHistory> /**  */
	assetAffectedRule?: Maybe<FilterOfAssetAffectedRule> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInventoryHistory>> /**  */
	or?: Array<Maybe<FilterOfInventoryHistory>> /**  */
	nor?: Array<Maybe<FilterOfInventoryHistory>> /**  */
	not?: Array<Maybe<FilterOfInventoryHistory>> /**  */
}


/**  */
export type ArrayAuditTimeOperator = {
	elemMatch?: Maybe<FilterOfAuditTime> /**  */
}


/**  */
export type FilterOfAuditTime = {
	methodName?: Maybe<FilterStringOperator> /**  */
	time?: Maybe<FilterDoubleOperator> /**  */
	isAudited?: Maybe<FilterBooleanOperator> /**  */
	count?: Maybe<FilterInt32Operator> /**  */
	clarilogException?: Maybe<FilterOfClarilogException> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAuditTime>> /**  */
	or?: Array<Maybe<FilterOfAuditTime>> /**  */
	nor?: Array<Maybe<FilterOfAuditTime>> /**  */
	not?: Array<Maybe<FilterOfAuditTime>> /**  */
}


/**  */
export type FilterOfClarilogException = {
	message?: Maybe<FilterStringOperator> /**  */
	stackTrace?: Maybe<FilterStringOperator> /**  */
	source?: Maybe<FilterStringOperator> /**  */
	helpLink?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfClarilogException>> /**  */
	or?: Array<Maybe<FilterOfClarilogException>> /**  */
	nor?: Array<Maybe<FilterOfClarilogException>> /**  */
	not?: Array<Maybe<FilterOfClarilogException>> /**  */
}


/**  */
export type FilterDenormalizeOfNetworkCredentialByNetworkCredentialInInventoryHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanConfigurationInInventoryHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanImportOptionByScanImportOptionInInventoryHistory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInInventoryHistory = {
	name?: Maybe<FilterStringOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterOfLifeCycle = {
	startLife?: Maybe<FilterOfStartLife> /**  */
	renewal?: Maybe<FilterOfRenewal> /**  */
	endLife?: Maybe<FilterOfEndLife> /**  */
	and?: Array<Maybe<FilterOfLifeCycle>> /**  */
	or?: Array<Maybe<FilterOfLifeCycle>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycle>> /**  */
	not?: Array<Maybe<FilterOfLifeCycle>> /**  */
}


/**  */
export type FilterOfStartLife = {
	description?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfStartLife>> /**  */
	or?: Array<Maybe<FilterOfStartLife>> /**  */
	nor?: Array<Maybe<FilterOfStartLife>> /**  */
	not?: Array<Maybe<FilterOfStartLife>> /**  */
}


/**  */
export type FilterOfRenewal = {
	description?: Maybe<FilterStringOperator> /**  */
	estimatedDate?: Maybe<FilterDateTimeOperator> /**  */
	age?: Maybe<FilterDoubleOperator> /**  */
	usingDuration?: Maybe<FilterDoubleOperator> /**  */
	and?: Array<Maybe<FilterOfRenewal>> /**  */
	or?: Array<Maybe<FilterOfRenewal>> /**  */
	nor?: Array<Maybe<FilterOfRenewal>> /**  */
	not?: Array<Maybe<FilterOfRenewal>> /**  */
}


/**  */
export type FilterOfEndLife = {
	description?: Maybe<FilterStringOperator> /**  */
	outDate?: Maybe<FilterDateTimeOperator> /**  */
	reasonId?: Maybe<FilterGuidOperator> /**  */
	reason?: Maybe<FilterStringOperator> /**  */
	destinationId?: Maybe<FilterGuidOperator> /**  */
	destination?: Maybe<FilterStringOperator> /**  */
	recipient?: Maybe<FilterStringOperator> /**  */
	removalDate?: Maybe<FilterDateTimeOperator> /**  */
	removalId?: Maybe<FilterGuidOperator> /**  */
	bunddleNumber?: Maybe<FilterStringOperator> /**  */
	disposalPrice?: Maybe<FilterDoubleOperator> /**  */
	recycleCost?: Maybe<FilterDoubleOperator> /**  */
	contributorId?: Maybe<FilterGuidOperator> /**  */
	restockingPrograms?: Maybe<FilterBooleanOperator> /**  */
	restockingLicenses?: Maybe<FilterBooleanOperator> /**  */
	dissociateAssets?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfEndLife>> /**  */
	or?: Array<Maybe<FilterOfEndLife>> /**  */
	nor?: Array<Maybe<FilterOfEndLife>> /**  */
	not?: Array<Maybe<FilterOfEndLife>> /**  */
}


/**  */
export type ArrayAccountOperator = {
	elemMatch?: Maybe<FilterOfAccount> /**  */
}


/**  */
export type FilterOfAccount = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	memberOfIds?: Maybe<ArrayUuidTypeOperator> /**  */
	permissionScopes?: Maybe<ArrayPermissionScopeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	isSystem?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<ArrayStringTypeOperator> /**  */
	subId?: Maybe<FilterGuidOperator> /**  */
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	userAccountControl?: Maybe<FilterInt32Operator> /**  */
	userAccountDisabled?: Maybe<FilterBooleanOperator> /**  */
	userAccountExpires?: Maybe<FilterDateTimeOperator> /**  */
	initial?: Maybe<FilterStringOperator> /**  */
	registrationNumber?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	helpDeskActivated?: Maybe<FilterBooleanOperator> /**  */
	function?: Maybe<FilterStringOperator> /**  */
	instantMessaging?: Maybe<FilterStringOperator> /**  */
	userPrincipalName?: Maybe<FilterStringOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	secretary?: Maybe<FilterStringOperator> /**  */
	hiringDate?: Maybe<FilterDateTimeOperator> /**  */
	exitDate?: Maybe<FilterDateTimeOperator> /**  */
	lastLogOn?: Maybe<FilterDateTimeOperator> /**  */
	lastLogonTimestamp?: Maybe<FilterDateTimeOperator> /**  */
	azureLastLogOnInteractive?: Maybe<FilterDateTimeOperator> /**  */
	azureLastLogOnNotInteractive?: Maybe<FilterDateTimeOperator> /**  */
	lastLogOff?: Maybe<FilterDateTimeOperator> /**  */
	lastConnected?: Maybe<FilterDateTimeOperator> /**  */
	accessFailedCount?: Maybe<FilterInt32Operator> /**  */
	homeDrive?: Maybe<FilterStringOperator> /**  */
	homeDirectory?: Maybe<FilterStringOperator> /**  */
	logonCount?: Maybe<FilterInt32Operator> /**  */
	profilePath?: Maybe<FilterStringOperator> /**  */
	scriptPath?: Maybe<FilterStringOperator> /**  */
	logOnTo?: Maybe<FilterStringOperator> /**  */
	allowDialIn?: Maybe<FilterBooleanOperator> /**  */
	officeName?: Maybe<FilterStringOperator> /**  */
	principalWebSite?: Maybe<FilterStringOperator> /**  */
	settings?: Maybe<FilterOfSettings> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInAccount> /**  */
	userPasswords?: Maybe<ArrayUserPasswordOperator> /**  */
	operatorTeamIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainOperatorTeamId?: Maybe<FilterGuidOperator> /**  */
	mainOperatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByMainOperatorTeamInAccount> /**  */
	isOperator?: Maybe<FilterBooleanOperator> /**  */
	loans?: Maybe<ArrayLoanOperator> /**  */
	ticketsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	userHabilitations?: Maybe<ArrayUserHabilitationOperator> /**  */
	phones?: Maybe<ArrayPhoneOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	webSites?: Maybe<ArrayStringTypeOperator> /**  */
	locations?: Maybe<ArrayLocationOperator> /**  */
	stockManagers?: Maybe<ArrayLocationOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInAccount> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	department?: Maybe<FilterStringOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	manager?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	managerRef?: Maybe<FilterDenormalizeOfUserByManagerRefInAccount> /**  */
	userManager?: Maybe<FilterDenormalizeOfUserByUserManagerInAccount> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	supplier?: Maybe<FilterDenormalizeOfSupplierBySupplierInAccount> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	containerDistinguishedName?: Maybe<FilterStringOperator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	firstImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportAzureAd?: Maybe<FilterDateTimeOperator> /**  */
	scanByLdapId?: Maybe<FilterGuidOperator> /**  */
	scanByLdap?: Maybe<FilterDenormalizeOfScanConfigurationByScanByLdapInAccount> /**  */
	principalPhone?: Maybe<FilterStringOperator> /**  */
	principalFax?: Maybe<FilterStringOperator> /**  */
	principalHomePhone?: Maybe<FilterStringOperator> /**  */
	principalIpPhone?: Maybe<FilterStringOperator> /**  */
	principalMobile?: Maybe<FilterStringOperator> /**  */
	principalPager?: Maybe<FilterStringOperator> /**  */
	memberOf?: Maybe<ArrayStringTypeOperator> /**  */
	memberOfAzureGroup?: Maybe<ArrayGroupAzureAdOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	invitation?: Maybe<FilterOfInvitation> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	userAffectedRule?: Maybe<FilterOfUserAffectedRule> /**  */
	populationsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	forceLdapImport?: Maybe<FilterBooleanOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	memberOfSecurityGroupIds?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	memberOfPopulationIds?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	softwarePackageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inviteById?: Maybe<FilterGuidOperator> /**  */
	inviteBy?: Maybe<FilterDenormalizeOfUserByInviteByInAccount> /**  */
	revokeById?: Maybe<FilterGuidOperator> /**  */
	revokeBy?: Maybe<FilterDenormalizeOfUserByRevokeByInAccount> /**  */
	costHours?: Maybe<FilterDoubleOperator> /**  */
	supportLevelOperator?: Maybe<FilterInt32Operator> /**  */
	azureObjectId?: Maybe<FilterStringOperator> /**  */
	managerAzureObjectId?: Maybe<FilterStringOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	mainAsset?: Maybe<FilterDenormalizeOfAssetByMainAssetInAccount> /**  */
	inheritLocationCharterIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inheritLocationCharterLocation?: Maybe<FilterOfInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<FilterOfInheritLocationCharter> /**  */
	entityLocationCharter?: Maybe<FilterOfEntityLocationCharter> /**  */
	privilegeId?: Maybe<FilterGuidOperator> /**  */
	privilege?: Maybe<FilterDenormalizeOfPrivilegeByPrivilegeInAccount> /**  */
	userPrivilegeId?: Maybe<FilterGuidOperator> /**  */
	userPrivilege?: Maybe<FilterDenormalizeOfUserPrivilegeByUserPrivilegeInAccount> /**  */
	locationCharterAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	securityGroupAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	populationAlreadyApply?: Maybe<FilterBooleanOperator> /**  */
	calendarId?: Maybe<FilterGuidOperator> /**  */
	calendar?: Maybe<FilterDenormalizeOfCalendarConnectorByCalendarInAccount> /**  */
	modelRulesDesignerIds?: Maybe<ArrayUuidTypeOperator> /**  */
	formDesignerIds?: Maybe<ArrayUuidTypeOperator> /**  */
	operatorIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	operatorRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	operatorProblemRuleId?: Maybe<FilterGuidOperator> /**  */
	serviceDeskActivated?: Maybe<FilterBooleanOperator> /**  */
	dashboardIds?: Maybe<ArrayUuidTypeOperator> /**  */
	allowEditOrganization?: Maybe<FilterBooleanOperator> /**  */
	recipentSystemNotification?: Maybe<FilterBooleanOperator> /**  */
	businessTimeId?: Maybe<FilterGuidOperator> /**  */
	businessTime?: Maybe<FilterDenormalizeOfBusinessTimeByBusinessTimeInAccount> /**  */
	userRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userContractType?: Maybe<FilterStringOperator> /**  */
	operatorIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	operatorRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	operatorProblemListDesignerId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfAccount>> /**  */
	or?: Array<Maybe<FilterOfAccount>> /**  */
	nor?: Array<Maybe<FilterOfAccount>> /**  */
	not?: Array<Maybe<FilterOfAccount>> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByMainOperatorTeamInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByManagerRefInAccount = {
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	hiringDate?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserManagerInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfSupplierBySupplierInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanByLdapInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByInviteByInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByRevokeByInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainAssetInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfPrivilegeByPrivilegeInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserPrivilegeByUserPrivilegeInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfCalendarConnectorByCalendarInAccount = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfBusinessTimeByBusinessTimeInAccount = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInAsset = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInAsset = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
}


/**  */
export type FilterOfFeatureMobility = {
	firstSimSlot?: Maybe<FilterOfSimSlot> /**  */
	secondSimSlot?: Maybe<FilterOfSimSlot> /**  */
	and?: Array<Maybe<FilterOfFeatureMobility>> /**  */
	or?: Array<Maybe<FilterOfFeatureMobility>> /**  */
	nor?: Array<Maybe<FilterOfFeatureMobility>> /**  */
	not?: Array<Maybe<FilterOfFeatureMobility>> /**  */
}


/**  */
export type FilterOfSimSlot = {
	imei?: Maybe<FilterStringOperator> /**  */
	phoneNumber?: Maybe<FilterStringOperator> /**  */
	primaryPinCode?: Maybe<FilterStringOperator> /**  */
	secondaryPinCode?: Maybe<FilterStringOperator> /**  */
	primaryPukCode?: Maybe<FilterStringOperator> /**  */
	secondaryPukCode?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSimSlot>> /**  */
	or?: Array<Maybe<FilterOfSimSlot>> /**  */
	nor?: Array<Maybe<FilterOfSimSlot>> /**  */
	not?: Array<Maybe<FilterOfSimSlot>> /**  */
}


/**  */
export type FilterOfFeatureSimCard = {
	phone?: Maybe<FilterStringOperator> /**  */
	pin?: Maybe<FilterStringOperator> /**  */
	pin2?: Maybe<FilterStringOperator> /**  */
	puk?: Maybe<FilterStringOperator> /**  */
	puk2?: Maybe<FilterStringOperator> /**  */
	simMemoryId?: Maybe<FilterGuidOperator> /**  */
	simCardMemory?: Maybe<FilterOfSimCardMemory> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureSimCard>> /**  */
	or?: Array<Maybe<FilterOfFeatureSimCard>> /**  */
	nor?: Array<Maybe<FilterOfFeatureSimCard>> /**  */
	not?: Array<Maybe<FilterOfFeatureSimCard>> /**  */
}


/**  */
export type FilterOfSimCardMemory = {
	name?: Maybe<FilterStringOperator> /**  */
	capacity?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSimCardMemory>> /**  */
	or?: Array<Maybe<FilterOfSimCardMemory>> /**  */
	nor?: Array<Maybe<FilterOfSimCardMemory>> /**  */
	not?: Array<Maybe<FilterOfSimCardMemory>> /**  */
}


/**  */
export type FilterOfFeatureMonitor = {
	size?: Maybe<FilterStringOperator> /**  */
	resolutions?: Maybe<ArrayResolutionOperator> /**  */
	monitorTechnologyId?: Maybe<FilterGuidOperator> /**  */
	monitorTechnology?: Maybe<FilterOfMonitorTechnology> /**  */
	resolutionMax?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureMonitor>> /**  */
	or?: Array<Maybe<FilterOfFeatureMonitor>> /**  */
	nor?: Array<Maybe<FilterOfFeatureMonitor>> /**  */
	not?: Array<Maybe<FilterOfFeatureMonitor>> /**  */
}


/**  */
export type ArrayResolutionOperator = {
	elemMatch?: Maybe<FilterOfResolution> /**  */
}


/**  */
export type FilterOfResolution = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfResolution>> /**  */
	or?: Array<Maybe<FilterOfResolution>> /**  */
	nor?: Array<Maybe<FilterOfResolution>> /**  */
	not?: Array<Maybe<FilterOfResolution>> /**  */
}


/**  */
export type FilterOfMonitorTechnology = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMonitorTechnology>> /**  */
	or?: Array<Maybe<FilterOfMonitorTechnology>> /**  */
	nor?: Array<Maybe<FilterOfMonitorTechnology>> /**  */
	not?: Array<Maybe<FilterOfMonitorTechnology>> /**  */
}


/**  */
export type FilterOfFeatureUps = {
	autonomyId?: Maybe<FilterGuidOperator> /**  */
	powerId?: Maybe<FilterGuidOperator> /**  */
	upsTypeId?: Maybe<FilterGuidOperator> /**  */
	chargerTime?: Maybe<FilterInt32Operator> /**  */
	outlet?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfFeatureUps>> /**  */
	or?: Array<Maybe<FilterOfFeatureUps>> /**  */
	nor?: Array<Maybe<FilterOfFeatureUps>> /**  */
	not?: Array<Maybe<FilterOfFeatureUps>> /**  */
}


/**  */
export type FilterOfFeatureScanner = {
	charger?: Maybe<FilterBooleanOperator> /**  */
	connectionCategories?: Maybe<ArrayConnectionCategoryOperator> /**  */
	paperFormats?: Maybe<ArrayPaperFormatOperator> /**  */
	scanSpeedId?: Maybe<FilterGuidOperator> /**  */
	dpiResolutionId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureScanner>> /**  */
	or?: Array<Maybe<FilterOfFeatureScanner>> /**  */
	nor?: Array<Maybe<FilterOfFeatureScanner>> /**  */
	not?: Array<Maybe<FilterOfFeatureScanner>> /**  */
}


/**  */
export type ArrayConnectionCategoryOperator = {
	elemMatch?: Maybe<FilterOfConnectionCategory> /**  */
}


/**  */
export type FilterOfConnectionCategory = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfConnectionCategory>> /**  */
	or?: Array<Maybe<FilterOfConnectionCategory>> /**  */
	nor?: Array<Maybe<FilterOfConnectionCategory>> /**  */
	not?: Array<Maybe<FilterOfConnectionCategory>> /**  */
}


/**  */
export type ArrayPaperFormatOperator = {
	elemMatch?: Maybe<FilterOfPaperFormat> /**  */
}


/**  */
export type FilterOfPaperFormat = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPaperFormat>> /**  */
	or?: Array<Maybe<FilterOfPaperFormat>> /**  */
	nor?: Array<Maybe<FilterOfPaperFormat>> /**  */
	not?: Array<Maybe<FilterOfPaperFormat>> /**  */
}


/**  */
export type FilterOfFeatureWebcam = {
	color?: Maybe<FilterBooleanOperator> /**  */
	microphone?: Maybe<FilterBooleanOperator> /**  */
	connectionCategories?: Maybe<ArrayConnectionCategoryOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureWebcam>> /**  */
	or?: Array<Maybe<FilterOfFeatureWebcam>> /**  */
	nor?: Array<Maybe<FilterOfFeatureWebcam>> /**  */
	not?: Array<Maybe<FilterOfFeatureWebcam>> /**  */
}


/**  */
export type FilterOfFeatureModem = {
	wifi?: Maybe<FilterBooleanOperator> /**  */
	connectionCategories?: Maybe<ArrayConnectionCategoryOperator> /**  */
	speedConnectionId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureModem>> /**  */
	or?: Array<Maybe<FilterOfFeatureModem>> /**  */
	nor?: Array<Maybe<FilterOfFeatureModem>> /**  */
	not?: Array<Maybe<FilterOfFeatureModem>> /**  */
}


/**  */
export type FilterOfFeatureTerminal = {
	barcodeReader?: Maybe<FilterBooleanOperator> /**  */
	rfidReader?: Maybe<FilterBooleanOperator> /**  */
	wifi?: Maybe<FilterBooleanOperator> /**  */
	bluetooth?: Maybe<FilterBooleanOperator> /**  */
	infrared?: Maybe<FilterBooleanOperator> /**  */
	miniUsb?: Maybe<FilterBooleanOperator> /**  */
	gPs?: Maybe<FilterBooleanOperator> /**  */
	camera?: Maybe<FilterBooleanOperator> /**  */
	memoryExtension?: Maybe<FilterStringOperator> /**  */
	resolutionId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureTerminal>> /**  */
	or?: Array<Maybe<FilterOfFeatureTerminal>> /**  */
	nor?: Array<Maybe<FilterOfFeatureTerminal>> /**  */
	not?: Array<Maybe<FilterOfFeatureTerminal>> /**  */
}


/**  */
export type FilterOfFeatureSwitch = {
	desktop?: Maybe<FilterBooleanOperator> /**  */
	stackable?: Maybe<FilterBooleanOperator> /**  */
	rackable?: Maybe<FilterBooleanOperator> /**  */
	poe?: Maybe<FilterBooleanOperator> /**  */
	modem?: Maybe<FilterBooleanOperator> /**  */
	firewall?: Maybe<FilterBooleanOperator> /**  */
	consolePort?: Maybe<FilterBooleanOperator> /**  */
	proxy?: Maybe<FilterBooleanOperator> /**  */
	vpn?: Maybe<FilterBooleanOperator> /**  */
	utm?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureSwitch>> /**  */
	or?: Array<Maybe<FilterOfFeatureSwitch>> /**  */
	nor?: Array<Maybe<FilterOfFeatureSwitch>> /**  */
	not?: Array<Maybe<FilterOfFeatureSwitch>> /**  */
}


/**  */
export type FilterOfFeatureNetwork = {
	wifi?: Maybe<FilterBooleanOperator> /**  */
	sSid?: Maybe<FilterStringOperator> /**  */
	securityKey?: Maybe<FilterStringOperator> /**  */
	webAdministration?: Maybe<FilterBooleanOperator> /**  */
	snmp?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureNetwork>> /**  */
	or?: Array<Maybe<FilterOfFeatureNetwork>> /**  */
	nor?: Array<Maybe<FilterOfFeatureNetwork>> /**  */
	not?: Array<Maybe<FilterOfFeatureNetwork>> /**  */
}


/**  */
export type FilterOfFeaturePrinter = {
	color?: Maybe<FilterBooleanOperator> /**  */
	share?: Maybe<FilterBooleanOperator> /**  */
	shareName?: Maybe<FilterStringOperator> /**  */
	speedBlack?: Maybe<FilterInt32Operator> /**  */
	speedColor?: Maybe<FilterInt32Operator> /**  */
	rectoVerso?: Maybe<FilterBooleanOperator> /**  */
	scanner?: Maybe<FilterBooleanOperator> /**  */
	fax?: Maybe<FilterBooleanOperator> /**  */
	dpiResolutionId?: Maybe<FilterGuidOperator> /**  */
	paperFormats?: Maybe<ArrayPaperFormatOperator> /**  */
	connectionCategories?: Maybe<ArrayConnectionCategoryOperator> /**  */
	printerTechnologyId?: Maybe<FilterGuidOperator> /**  */
	orientations?: Maybe<ArrayOrientationOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeaturePrinter>> /**  */
	or?: Array<Maybe<FilterOfFeaturePrinter>> /**  */
	nor?: Array<Maybe<FilterOfFeaturePrinter>> /**  */
	not?: Array<Maybe<FilterOfFeaturePrinter>> /**  */
}


/**  */
export type ArrayOrientationOperator = {
	elemMatch?: Maybe<FilterOfOrientation> /**  */
}


/**  */
export type FilterOfOrientation = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrientation>> /**  */
	or?: Array<Maybe<FilterOfOrientation>> /**  */
	nor?: Array<Maybe<FilterOfOrientation>> /**  */
	not?: Array<Maybe<FilterOfOrientation>> /**  */
}


/**  */
export type FilterOfFeatureVideoProjector = {
	speaker?: Maybe<FilterBooleanOperator> /**  */
	remoteControl?: Maybe<FilterBooleanOperator> /**  */
	resolutions?: Maybe<ArrayResolutionOperator> /**  */
	monitorTechnologyId?: Maybe<FilterGuidOperator> /**  */
	powerLampId?: Maybe<FilterGuidOperator> /**  */
	brightnessId?: Maybe<FilterGuidOperator> /**  */
	videoProjectorTypeId?: Maybe<FilterGuidOperator> /**  */
	noiseLevelId?: Maybe<FilterGuidOperator> /**  */
	videoProjectorFormatId?: Maybe<FilterGuidOperator> /**  */
	connectionCategories?: Maybe<ArrayConnectionCategoryOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureVideoProjector>> /**  */
	or?: Array<Maybe<FilterOfFeatureVideoProjector>> /**  */
	nor?: Array<Maybe<FilterOfFeatureVideoProjector>> /**  */
	not?: Array<Maybe<FilterOfFeatureVideoProjector>> /**  */
}


/**  */
export type FilterOfFeatureMobile = {
	imei?: Maybe<FilterStringOperator> /**  */
	firstSimId?: Maybe<FilterGuidOperator> /**  */
	secondSimId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureMobile>> /**  */
	or?: Array<Maybe<FilterOfFeatureMobile>> /**  */
	nor?: Array<Maybe<FilterOfFeatureMobile>> /**  */
	not?: Array<Maybe<FilterOfFeatureMobile>> /**  */
}


/**  */
export type ArrayFeatureMobileOperator = {
	elemMatch?: Maybe<FilterOfFeatureMobile> /**  */
}


/**  */
export type FilterOfFeatureTelephony = {
	phoneNumber?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeatureTelephony>> /**  */
	or?: Array<Maybe<FilterOfFeatureTelephony>> /**  */
	nor?: Array<Maybe<FilterOfFeatureTelephony>> /**  */
	not?: Array<Maybe<FilterOfFeatureTelephony>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetModelByCreatedModelInAsset = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfDetectWarranty = {
	lastAttempt?: Maybe<FilterDateTimeOperator> /**  */
	lastSuccess?: Maybe<FilterDateTimeOperator> /**  */
	message?: Maybe<FilterStringOperator> /**  */
	success?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfDetectWarranty>> /**  */
	or?: Array<Maybe<FilterOfDetectWarranty>> /**  */
	nor?: Array<Maybe<FilterOfDetectWarranty>> /**  */
	not?: Array<Maybe<FilterOfDetectWarranty>> /**  */
}


/**  */
export type FilterOfAssetFinancial = {
	budgetId?: Maybe<FilterGuidOperator> /**  */
	acquisition?: Maybe<FilterOfAssetAcquisition> /**  */
	internalInvoice?: Maybe<FilterOfAssetInternalInvoice> /**  */
	accounting?: Maybe<FilterOfAssetAccounting> /**  */
	and?: Array<Maybe<FilterOfAssetFinancial>> /**  */
	or?: Array<Maybe<FilterOfAssetFinancial>> /**  */
	nor?: Array<Maybe<FilterOfAssetFinancial>> /**  */
	not?: Array<Maybe<FilterOfAssetFinancial>> /**  */
}


/**  */
export type FilterOfAssetAcquisition = {
	modeId?: Maybe<FilterGuidOperator> /**  */
	stateId?: Maybe<FilterGuidOperator> /**  */
	typeOfStaffingId?: Maybe<FilterGuidOperator> /**  */
	batchDate?: Maybe<FilterDateTimeOperator> /**  */
	batchNumber?: Maybe<FilterStringOperator> /**  */
	invoiceDate?: Maybe<FilterDateTimeOperator> /**  */
	invoiceNumber?: Maybe<FilterStringOperator> /**  */
	price?: Maybe<FilterDoubleOperator> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAcquisition>> /**  */
	or?: Array<Maybe<FilterOfAssetAcquisition>> /**  */
	nor?: Array<Maybe<FilterOfAssetAcquisition>> /**  */
	not?: Array<Maybe<FilterOfAssetAcquisition>> /**  */
}


/**  */
export type FilterOfAssetInternalInvoice = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	number?: Maybe<FilterStringOperator> /**  */
	amount?: Maybe<FilterDoubleOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfAssetInternalInvoice>> /**  */
	or?: Array<Maybe<FilterOfAssetInternalInvoice>> /**  */
	nor?: Array<Maybe<FilterOfAssetInternalInvoice>> /**  */
	not?: Array<Maybe<FilterOfAssetInternalInvoice>> /**  */
}


/**  */
export type FilterOfAssetAccounting = {
	typeId?: Maybe<FilterGuidOperator> /**  */
	startDepreciation?: Maybe<FilterDateTimeOperator> /**  */
	endDepreciation?: Maybe<FilterDateTimeOperator> /**  */
	duration?: Maybe<FilterInt32Operator> /**  */
	numberImmobilization?: Maybe<FilterStringOperator> /**  */
	recouping?: Maybe<FilterStringOperator> /**  */
	analyticalImputation?: Maybe<FilterStringOperator> /**  */
	costCenterId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAccounting>> /**  */
	or?: Array<Maybe<FilterOfAssetAccounting>> /**  */
	nor?: Array<Maybe<FilterOfAssetAccounting>> /**  */
	not?: Array<Maybe<FilterOfAssetAccounting>> /**  */
}


/**  */
export type FilterDenormalizeOfLoanByLoanInAsset = {
	provision?: Maybe<FilterDateTimeOperator> /**  */
	returnDate?: Maybe<FilterDateTimeOperator> /**  */
	realReturnDate?: Maybe<FilterDateTimeOperator> /**  */
	loanStatusId?: Maybe<FilterGuidOperator> /**  */
	loanStatus?: Maybe<FilterDenormalizeOfLoanStatusByLoanStatusInLoan> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterOfAssetNamingConvention = {
	onBarCode?: Maybe<FilterOfNamingConventionSummary> /**  */
	onInventoryNumber?: Maybe<FilterOfNamingConventionSummary> /**  */
	onName?: Maybe<FilterOfNamingConventionSummary> /**  */
	onNetBios?: Maybe<FilterOfNamingConventionSummary> /**  */
	onAssetNumber?: Maybe<FilterOfNamingConventionSummary> /**  */
	alreadyApply?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfAssetNamingConvention>> /**  */
	or?: Array<Maybe<FilterOfAssetNamingConvention>> /**  */
	nor?: Array<Maybe<FilterOfAssetNamingConvention>> /**  */
	not?: Array<Maybe<FilterOfAssetNamingConvention>> /**  */
}


/**  */
export type FilterOfNamingConventionSummary = {
	value?: Maybe<FilterStringOperator> /**  */
	namingConventionMask?: Maybe<FilterStringOperator> /**  */
	manualEdit?: Maybe<FilterBooleanOperator> /**  */
	lock?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNamingConventionSummary>> /**  */
	or?: Array<Maybe<FilterOfNamingConventionSummary>> /**  */
	nor?: Array<Maybe<FilterOfNamingConventionSummary>> /**  */
	not?: Array<Maybe<FilterOfNamingConventionSummary>> /**  */
}


/**  */
export type FilterOfAuditResult = {
	antiSpywareProducts?: Maybe<ArrayAntiSpywareProductOperator> /**  */
	antiVirusProducts?: Maybe<ArrayAntiVirusProductOperator> /**  */
	autoRuns?: Maybe<ArrayAutoRunOperator> /**  */
	baseBoards?: Maybe<ArrayBaseBoardOperator> /**  */
	bios?: Maybe<ArrayBiosOperator> /**  */
	cdRomDrives?: Maybe<ArrayCDRomDriveOperator> /**  */
	computerSystems?: Maybe<ArrayComputerSystemOperator> /**  */
	computerSystemProducts?: Maybe<ArrayComputerSystemProductOperator> /**  */
	dataFiles?: Maybe<ArrayDataFileOperator> /**  */
	diskDrives?: Maybe<ArrayDiskDriveOperator> /**  */
	logicalDisks?: Maybe<ArrayLogicalDiskOperator> /**  */
	environmentVariables?: Maybe<ArrayEnvironmentVariableOperator> /**  */
	firewallProducts?: Maybe<ArrayFirewallProductOperator> /**  */
	fontInfoActions?: Maybe<ArrayFontInfoActionOperator> /**  */
	groups?: Maybe<ArrayGroupOperator> /**  */
	keyboards?: Maybe<ArrayKeyboardOperator> /**  */
	monitors?: Maybe<ArrayMonitorOperator> /**  */
	virtualMachines?: Maybe<ArrayVirtualMachineOperator> /**  */
	networkAdapters?: Maybe<ArrayNetworkAdapterOperator> /**  */
	networkDrives?: Maybe<ArrayNetworkDriveOperator> /**  */
	ntLogEvents?: Maybe<ArrayNtLogEventOperator> /**  */
	operatingSystems?: Maybe<ArrayOperatingSystemOperator> /**  */
	parallelPorts?: Maybe<ArrayParallelPortOperator> /**  */
	physicalMemoryArrays?: Maybe<ArrayPhysicalMemoryArrayOperator> /**  */
	physicalMemories?: Maybe<ArrayPhysicalMemoryOperator> /**  */
	pnpEntities?: Maybe<ArrayPnpEntityOperator> /**  */
	pointingDevices?: Maybe<ArrayPointingDeviceOperator> /**  */
	portConnectors?: Maybe<ArrayPortConnectorOperator> /**  */
	potsModems?: Maybe<ArrayPotsModemOperator> /**  */
	printers?: Maybe<ArrayPrinterOperator> /**  */
	processes?: Maybe<ArrayProcessOperator> /**  */
	processors?: Maybe<ArrayProcessorOperator> /**  */
	programs?: Maybe<ArrayProgramOperator> /**  */
	quickFixEngineerings?: Maybe<ArrayQuickFixEngineeringOperator> /**  */
	workApplications?: Maybe<ArrayWorkApplicationOperator> /**  */
	serialPorts?: Maybe<ArraySerialPortOperator> /**  */
	windowsServices?: Maybe<ArrayWindowsServiceOperator> /**  */
	shares?: Maybe<ArrayShareOperator> /**  */
	sqlServers?: Maybe<ArraySqlServerOperator> /**  */
	soundDevices?: Maybe<ArraySoundDeviceOperator> /**  */
	systemEnclosures?: Maybe<ArraySystemEnclosureOperator> /**  */
	systemSlots?: Maybe<ArraySystemSlotOperator> /**  */
	tapeDrives?: Maybe<ArrayTapeDriveOperator> /**  */
	userAccountControls?: Maybe<ArrayUserAccountControlOperator> /**  */
	userAccounts?: Maybe<ArrayUserAccountOperator> /**  */
	userProfiles?: Maybe<ArrayUserProfileOperator> /**  */
	videoControllers?: Maybe<ArrayVideoControllerOperator> /**  */
	windowsFirewalls?: Maybe<ArrayWindowsFirewallOperator> /**  */
	windowsUpdates?: Maybe<ArrayWindowsUpdateOperator> /**  */
	scanRegistries?: Maybe<ArrayScanRegistryOperator> /**  */
	printerConsumables?: Maybe<ArrayPrinterConsumableOperator> /**  */
	pageCounts?: Maybe<ArrayPageCountOperator> /**  */
	copyCounts?: Maybe<ArrayCopyCountOperator> /**  */
	scannerCounts?: Maybe<ArrayScannerCountOperator> /**  */
	faxCounts?: Maybe<ArrayFaxCountOperator> /**  */
	entryTables?: Maybe<ArrayEntryTableOperator> /**  */
	vlans?: Maybe<ArrayVlanOperator> /**  */
	accessPoints?: Maybe<ArrayAccessPointOperator> /**  */
	portOpens?: Maybe<ArrayPortOpenOperator> /**  */
	odbcDrivers?: Maybe<ArrayOdbcDriverOperator> /**  */
	systemAccounts?: Maybe<ArraySystemAccountOperator> /**  */
	logonSessions?: Maybe<ArrayLogonSessionOperator> /**  */
	pnpSignedDrivers?: Maybe<ArrayPnpSignedDriverOperator> /**  */
	serialNumbers?: Maybe<ArraySerialNumberOperator> /**  */
	uptimeCalendars?: Maybe<ArrayUptimeCalendarOperator> /**  */
	bootConfigurations?: Maybe<ArrayBootConfigurationOperator> /**  */
	pageFiles?: Maybe<ArrayPageFileOperator> /**  */
	codecFiles?: Maybe<ArrayCodecFileOperator> /**  */
	osRecoveryConfigurations?: Maybe<ArrayOsRecoveryConfigurationOperator> /**  */
	scsiControllers?: Maybe<ArrayScsiControllerOperator> /**  */
	pcmciaControllers?: Maybe<ArrayPcmciaControllerOperator> /**  */
	infraredDevices?: Maybe<ArrayInfraredDeviceOperator> /**  */
	batteries?: Maybe<ArrayBatteryOperator> /**  */
	portableBatteries?: Maybe<ArrayPortableBatteryOperator> /**  */
	logonHistories?: Maybe<ArrayLogonHistoryOperator> /**  */
	desktops?: Maybe<ArrayDesktopOperator> /**  */
	optionalFeatures?: Maybe<ArrayOptionalFeatureOperator> /**  */
	iis?: Maybe<ArrayIisOperator> /**  */
	ldapProperties?: Maybe<ArrayLdapPropertyOperator> /**  */
	logicalDiskHistories?: Maybe<ArrayLogicalDiskHistoryOperator> /**  */
	internetExplorers?: Maybe<ArrayInternetExplorerOperator> /**  */
	encryptableVolumes?: Maybe<ArrayEncryptableVolumeOperator> /**  */
	snmpSummary?: Maybe<ArraySnmpSummaryOperator> /**  */
	and?: Array<Maybe<FilterOfAuditResult>> /**  */
	or?: Array<Maybe<FilterOfAuditResult>> /**  */
	nor?: Array<Maybe<FilterOfAuditResult>> /**  */
	not?: Array<Maybe<FilterOfAuditResult>> /**  */
}


/**  */
export type ArrayAntiSpywareProductOperator = {
	elemMatch?: Maybe<FilterOfAntiSpywareProduct> /**  */
}


/**  */
export type FilterOfAntiSpywareProduct = {
	displayName?: Maybe<FilterStringOperator> /**  */
	instanceGuid?: Maybe<FilterStringOperator> /**  */
	pathToSignedProductExe?: Maybe<FilterStringOperator> /**  */
	pathToSignedReportingExe?: Maybe<FilterStringOperator> /**  */
	productState?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAntiSpywareProduct> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAntiSpywareProduct>> /**  */
	or?: Array<Maybe<FilterOfAntiSpywareProduct>> /**  */
	nor?: Array<Maybe<FilterOfAntiSpywareProduct>> /**  */
	not?: Array<Maybe<FilterOfAntiSpywareProduct>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAntiSpywareProduct = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayAntiVirusProductOperator = {
	elemMatch?: Maybe<FilterOfAntiVirusProduct> /**  */
}


/**  */
export type FilterOfAntiVirusProduct = {
	displayName?: Maybe<FilterStringOperator> /**  */
	instanceGuid?: Maybe<FilterStringOperator> /**  */
	pathToSignedProductExe?: Maybe<FilterStringOperator> /**  */
	pathToSignedReportingExe?: Maybe<FilterStringOperator> /**  */
	productState?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAntiVirusProduct> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAntiVirusProduct>> /**  */
	or?: Array<Maybe<FilterOfAntiVirusProduct>> /**  */
	nor?: Array<Maybe<FilterOfAntiVirusProduct>> /**  */
	not?: Array<Maybe<FilterOfAntiVirusProduct>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAntiVirusProduct = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayAutoRunOperator = {
	elemMatch?: Maybe<FilterOfAutoRun> /**  */
}


/**  */
export type FilterOfAutoRun = {
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAutoRun> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAutoRun>> /**  */
	or?: Array<Maybe<FilterOfAutoRun>> /**  */
	nor?: Array<Maybe<FilterOfAutoRun>> /**  */
	not?: Array<Maybe<FilterOfAutoRun>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAutoRun = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayBaseBoardOperator = {
	elemMatch?: Maybe<FilterOfBaseBoard> /**  */
}


/**  */
export type FilterOfBaseBoard = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	product?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	sku?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInBaseBoard> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBaseBoard>> /**  */
	or?: Array<Maybe<FilterOfBaseBoard>> /**  */
	nor?: Array<Maybe<FilterOfBaseBoard>> /**  */
	not?: Array<Maybe<FilterOfBaseBoard>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInBaseBoard = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayBiosOperator = {
	elemMatch?: Maybe<FilterOfBios> /**  */
}


/**  */
export type FilterOfBios = {
	buildNumber?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	releaseDate?: Maybe<FilterDateTimeOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	smBiosVersion?: Maybe<FilterStringOperator> /**  */
	smBiosMajorVersion?: Maybe<FilterInt64Operator> /**  */
	smBiosMinorVersion?: Maybe<FilterInt64Operator> /**  */
	smBiosPresent?: Maybe<FilterBooleanOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInBios> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBios>> /**  */
	or?: Array<Maybe<FilterOfBios>> /**  */
	nor?: Array<Maybe<FilterOfBios>> /**  */
	not?: Array<Maybe<FilterOfBios>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInBios = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayCDRomDriveOperator = {
	elemMatch?: Maybe<FilterOfCDRomDrive> /**  */
}


/**  */
export type FilterOfCDRomDrive = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	drive?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	mediaType?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInCDRomDrive> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCDRomDrive>> /**  */
	or?: Array<Maybe<FilterOfCDRomDrive>> /**  */
	nor?: Array<Maybe<FilterOfCDRomDrive>> /**  */
	not?: Array<Maybe<FilterOfCDRomDrive>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInCDRomDrive = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayComputerSystemOperator = {
	elemMatch?: Maybe<FilterOfComputerSystem> /**  */
}


/**  */
export type FilterOfComputerSystem = {
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	adminPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	automaticManagedPagefile?: Maybe<FilterBooleanOperator> /**  */
	automaticResetBootOption?: Maybe<FilterBooleanOperator> /**  */
	automaticResetCapability?: Maybe<FilterBooleanOperator> /**  */
	bootOptionOnLimit?: Maybe<FilterInt64Operator> /**  */
	bootOptionOnWatchDog?: Maybe<FilterInt64Operator> /**  */
	bootRomSupported?: Maybe<FilterBooleanOperator> /**  */
	bootupState?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	chassisBootupState?: Maybe<FilterInt64Operator> /**  */
	currentTimeZone?: Maybe<FilterInt32Operator> /**  */
	daylightInEffect?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dnsHostName?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	domainRole?: Maybe<FilterDomainRoleOperator> /**  */
	enableDaylightSavingsTime?: Maybe<FilterBooleanOperator> /**  */
	frontPanelResetStatus?: Maybe<FilterInt64Operator> /**  */
	infraredSupported?: Maybe<FilterBooleanOperator> /**  */
	initialLoadInfo?: Maybe<FilterStringOperator> /**  */
	keyboardPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	lastLoadInfo?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	nameFormat?: Maybe<FilterStringOperator> /**  */
	networkServerModeEnabled?: Maybe<FilterBooleanOperator> /**  */
	numberOfLogicalProcessors?: Maybe<FilterInt64Operator> /**  */
	numberOfProcessors?: Maybe<FilterInt64Operator> /**  */
	oem?: Maybe<ArrayStringTypeOperator> /**  */
	partOfDomain?: Maybe<FilterBooleanOperator> /**  */
	pauseAfterReset?: Maybe<FilterInt64Operator> /**  */
	pcSystemType?: Maybe<FilterPcSystemTypeOperator> /**  */
	powerManagementSupported?: Maybe<FilterBooleanOperator> /**  */
	powerOnPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	powerState?: Maybe<FilterInt64Operator> /**  */
	powerSupplyState?: Maybe<FilterInt64Operator> /**  */
	primaryOwnerContact?: Maybe<FilterStringOperator> /**  */
	primaryOwnerName?: Maybe<FilterStringOperator> /**  */
	resetCapability?: Maybe<FilterInt64Operator> /**  */
	resetCount?: Maybe<FilterInt32Operator> /**  */
	resetLimit?: Maybe<FilterInt32Operator> /**  */
	roles?: Maybe<ArrayStringTypeOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	supportContactDescription?: Maybe<ArrayStringTypeOperator> /**  */
	systemStartupDelay?: Maybe<FilterInt64Operator> /**  */
	systemStartupOptions?: Maybe<ArrayStringTypeOperator> /**  */
	systemType?: Maybe<FilterStringOperator> /**  */
	thermalState?: Maybe<FilterInt64Operator> /**  */
	totalPhysicalMemory?: Maybe<FilterInt64Operator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	wakeUpType?: Maybe<FilterInt64Operator> /**  */
	workGroup?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInComputerSystem> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfComputerSystem>> /**  */
	or?: Array<Maybe<FilterOfComputerSystem>> /**  */
	nor?: Array<Maybe<FilterOfComputerSystem>> /**  */
	not?: Array<Maybe<FilterOfComputerSystem>> /**  */
}


/**  */
export type FilterDomainRoleOperator = {
	eq?: Maybe<DomainRole> /**  */
	ne?: Maybe<DomainRole> /**  */
	in?: Array<Maybe<DomainRole>> /**  */
	nin?: Array<Maybe<DomainRole>> /**  */
}


/**  */
export type FilterPcSystemTypeOperator = {
	eq?: Maybe<PcSystemType> /**  */
	ne?: Maybe<PcSystemType> /**  */
	in?: Array<Maybe<PcSystemType>> /**  */
	nin?: Array<Maybe<PcSystemType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInComputerSystem = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayComputerSystemProductOperator = {
	elemMatch?: Maybe<FilterOfComputerSystemProduct> /**  */
}


/**  */
export type FilterOfComputerSystemProduct = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	identifyingNumber?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	skuNumber?: Maybe<FilterStringOperator> /**  */
	uuid?: Maybe<FilterStringOperator> /**  */
	vendor?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInComputerSystemProduct> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfComputerSystemProduct>> /**  */
	or?: Array<Maybe<FilterOfComputerSystemProduct>> /**  */
	nor?: Array<Maybe<FilterOfComputerSystemProduct>> /**  */
	not?: Array<Maybe<FilterOfComputerSystemProduct>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInComputerSystemProduct = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayDataFileOperator = {
	elemMatch?: Maybe<FilterOfDataFile> /**  */
}


/**  */
export type FilterOfDataFile = {
	accessMask?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	compressed?: Maybe<FilterBooleanOperator> /**  */
	compressionMethod?: Maybe<FilterStringOperator> /**  */
	creationDate?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	drive?: Maybe<FilterStringOperator> /**  */
	eightDotThreeFileName?: Maybe<FilterStringOperator> /**  */
	encrypted?: Maybe<FilterBooleanOperator> /**  */
	encryptionMethod?: Maybe<FilterStringOperator> /**  */
	extension?: Maybe<FilterStringOperator> /**  */
	fileName?: Maybe<FilterStringOperator> /**  */
	fileSize?: Maybe<FilterInt64Operator> /**  */
	fileType?: Maybe<FilterStringOperator> /**  */
	fsName?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	inUseCount?: Maybe<FilterInt64Operator> /**  */
	lastAccessed?: Maybe<FilterDateTimeOperator> /**  */
	lastModified?: Maybe<FilterDateTimeOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	readable?: Maybe<FilterBooleanOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	system?: Maybe<FilterBooleanOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	writeable?: Maybe<FilterBooleanOperator> /**  */
	scanDataFileId?: Maybe<FilterGuidOperator> /**  */
	scanDataFile?: Maybe<FilterDenormalizeOfScanDataFileByScanDataFileInDataFile> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInDataFile> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDataFile>> /**  */
	or?: Array<Maybe<FilterOfDataFile>> /**  */
	nor?: Array<Maybe<FilterOfDataFile>> /**  */
	not?: Array<Maybe<FilterOfDataFile>> /**  */
}


/**  */
export type FilterDenormalizeOfScanDataFileByScanDataFileInDataFile = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInDataFile = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayDiskDriveOperator = {
	elemMatch?: Maybe<FilterOfDiskDrive> /**  */
}


/**  */
export type FilterOfDiskDrive = {
	caption?: Maybe<FilterStringOperator> /**  */
	compressionMethod?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	firmwareRevision?: Maybe<FilterStringOperator> /**  */
	index?: Maybe<FilterInt64Operator> /**  */
	interfaceType?: Maybe<FilterStringOperator> /**  */
	maxBlockSize?: Maybe<FilterInt64Operator> /**  */
	mediaType?: Maybe<FilterStringOperator> /**  */
	minBlockSize?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	partitions?: Maybe<FilterInt64Operator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	scsiBus?: Maybe<FilterInt64Operator> /**  */
	scsiLogicalUnit?: Maybe<FilterInt64Operator> /**  */
	scsiPort?: Maybe<FilterInt64Operator> /**  */
	scsiTargetId?: Maybe<FilterInt64Operator> /**  */
	sectorsPerTrack?: Maybe<FilterInt64Operator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	bytesPerSector?: Maybe<FilterInt64Operator> /**  */
	totalCylinders?: Maybe<FilterInt64Operator> /**  */
	totalHeads?: Maybe<FilterInt64Operator> /**  */
	totalSectors?: Maybe<FilterInt64Operator> /**  */
	totalTracks?: Maybe<FilterInt64Operator> /**  */
	tracksPerCylinder?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInDiskDrive> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDiskDrive>> /**  */
	or?: Array<Maybe<FilterOfDiskDrive>> /**  */
	nor?: Array<Maybe<FilterOfDiskDrive>> /**  */
	not?: Array<Maybe<FilterOfDiskDrive>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInDiskDrive = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayLogicalDiskOperator = {
	elemMatch?: Maybe<FilterOfLogicalDisk> /**  */
}


/**  */
export type FilterOfLogicalDisk = {
	providerName?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	compressed?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	driveType?: Maybe<FilterDriveTypeOperator> /**  */
	fileSystem?: Maybe<FilterStringOperator> /**  */
	freeSpace?: Maybe<FilterDoubleOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	volumeName?: Maybe<FilterStringOperator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	volumeSerialNumber?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLogicalDisk> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLogicalDisk>> /**  */
	or?: Array<Maybe<FilterOfLogicalDisk>> /**  */
	nor?: Array<Maybe<FilterOfLogicalDisk>> /**  */
	not?: Array<Maybe<FilterOfLogicalDisk>> /**  */
}


/**  */
export type FilterDriveTypeOperator = {
	eq?: Maybe<DriveType> /**  */
	ne?: Maybe<DriveType> /**  */
	in?: Array<Maybe<DriveType>> /**  */
	nin?: Array<Maybe<DriveType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLogicalDisk = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayEnvironmentVariableOperator = {
	elemMatch?: Maybe<FilterOfEnvironmentVariable> /**  */
}


/**  */
export type FilterOfEnvironmentVariable = {
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInEnvironmentVariable> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEnvironmentVariable>> /**  */
	or?: Array<Maybe<FilterOfEnvironmentVariable>> /**  */
	nor?: Array<Maybe<FilterOfEnvironmentVariable>> /**  */
	not?: Array<Maybe<FilterOfEnvironmentVariable>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInEnvironmentVariable = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayFirewallProductOperator = {
	elemMatch?: Maybe<FilterOfFirewallProduct> /**  */
}


/**  */
export type FilterOfFirewallProduct = {
	companyName?: Maybe<FilterStringOperator> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	enableUImd5Hash?: Maybe<FilterBooleanOperator> /**  */
	enableUiParameters?: Maybe<FilterBooleanOperator> /**  */
	instanceGuid?: Maybe<FilterStringOperator> /**  */
	pathToEnableUi?: Maybe<FilterStringOperator> /**  */
	versionNumber?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInFirewallProduct> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFirewallProduct>> /**  */
	or?: Array<Maybe<FilterOfFirewallProduct>> /**  */
	nor?: Array<Maybe<FilterOfFirewallProduct>> /**  */
	not?: Array<Maybe<FilterOfFirewallProduct>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInFirewallProduct = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayFontInfoActionOperator = {
	elemMatch?: Maybe<FilterOfFontInfoAction> /**  */
}


/**  */
export type FilterOfFontInfoAction = {
	actionId?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	direction?: Maybe<FilterInt64Operator> /**  */
	file?: Maybe<FilterStringOperator> /**  */
	fontTitle?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	softwareElementId?: Maybe<FilterStringOperator> /**  */
	softwareElementState?: Maybe<FilterInt64Operator> /**  */
	targetOperatingSystem?: Maybe<FilterInt64Operator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInFontInfoAction> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFontInfoAction>> /**  */
	or?: Array<Maybe<FilterOfFontInfoAction>> /**  */
	nor?: Array<Maybe<FilterOfFontInfoAction>> /**  */
	not?: Array<Maybe<FilterOfFontInfoAction>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInFontInfoAction = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayGroupOperator = {
	elemMatch?: Maybe<FilterOfGroup> /**  */
}


/**  */
export type FilterOfGroup = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	localAccount?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	sidType?: Maybe<FilterInt32Operator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	groupUsers?: Maybe<ArrayGroupUserOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInGroup> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfGroup>> /**  */
	or?: Array<Maybe<FilterOfGroup>> /**  */
	nor?: Array<Maybe<FilterOfGroup>> /**  */
	not?: Array<Maybe<FilterOfGroup>> /**  */
}


/**  */
export type ArrayGroupUserOperator = {
	elemMatch?: Maybe<FilterOfGroupUser> /**  */
}


/**  */
export type FilterOfGroupUser = {
	userCaption?: Maybe<FilterStringOperator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	userDomain?: Maybe<FilterStringOperator> /**  */
	userSid?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfGroupUser>> /**  */
	or?: Array<Maybe<FilterOfGroupUser>> /**  */
	nor?: Array<Maybe<FilterOfGroupUser>> /**  */
	not?: Array<Maybe<FilterOfGroupUser>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInGroup = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayKeyboardOperator = {
	elemMatch?: Maybe<FilterOfKeyboard> /**  */
}


/**  */
export type FilterOfKeyboard = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	isLocked?: Maybe<FilterBooleanOperator> /**  */
	lastErrorCode?: Maybe<FilterInt64Operator> /**  */
	layout?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	numberOfFunctionKeys?: Maybe<FilterInt64Operator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInKeyboard> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfKeyboard>> /**  */
	or?: Array<Maybe<FilterOfKeyboard>> /**  */
	nor?: Array<Maybe<FilterOfKeyboard>> /**  */
	not?: Array<Maybe<FilterOfKeyboard>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInKeyboard = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayMonitorOperator = {
	elemMatch?: Maybe<FilterOfMonitor> /**  */
}


/**  */
export type FilterOfMonitor = {
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	manufactureDate?: Maybe<FilterDateTimeOperator> /**  */
	manufactureDateYear?: Maybe<FilterInt32Operator> /**  */
	manufactureDateWeek?: Maybe<FilterInt32Operator> /**  */
	pnpId?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	serialNumberOriginal?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInMonitor> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMonitor>> /**  */
	or?: Array<Maybe<FilterOfMonitor>> /**  */
	nor?: Array<Maybe<FilterOfMonitor>> /**  */
	not?: Array<Maybe<FilterOfMonitor>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInMonitor = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayVirtualMachineOperator = {
	elemMatch?: Maybe<FilterOfVirtualMachine> /**  */
}


/**  */
export type FilterOfVirtualMachine = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	elementName?: Maybe<FilterStringOperator> /**  */
	statusDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	healthState?: Maybe<FilterInt64Operator> /**  */
	enabledState?: Maybe<FilterInt64Operator> /**  */
	otherEnabledState?: Maybe<FilterStringOperator> /**  */
	requestedState?: Maybe<FilterInt64Operator> /**  */
	enabledDefault?: Maybe<FilterInt64Operator> /**  */
	timeOfLastStateChange?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	primaryOwnerName?: Maybe<FilterStringOperator> /**  */
	primaryOwnerContact?: Maybe<FilterStringOperator> /**  */
	nameFormat?: Maybe<FilterStringOperator> /**  */
	otherIdentifyingInfo?: Maybe<ArrayStringTypeOperator> /**  */
	identifyingDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	otherDedicatedDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	resetCapability?: Maybe<FilterInt64Operator> /**  */
	onTimeInMilliseconds?: Maybe<FilterInt64Operator> /**  */
	timeOfLastConfigurationChange?: Maybe<FilterDateTimeOperator> /**  */
	processId?: Maybe<FilterInt64Operator> /**  */
	virtualMachineGuest?: Maybe<FilterOfVirtualMachineGuest> /**  */
	assetLinkId?: Maybe<FilterGuidOperator> /**  */
	assetLink?: Maybe<FilterDenormalizeOfAssetByAssetLinkInVirtualMachine> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInVirtualMachine> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVirtualMachine>> /**  */
	or?: Array<Maybe<FilterOfVirtualMachine>> /**  */
	nor?: Array<Maybe<FilterOfVirtualMachine>> /**  */
	not?: Array<Maybe<FilterOfVirtualMachine>> /**  */
}


/**  */
export type FilterOfVirtualMachineGuest = {
	operatingSystem?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	hostName?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	memorySize?: Maybe<FilterInt64Operator> /**  */
	hardDiskSize?: Maybe<FilterInt64Operator> /**  */
	cpuCount?: Maybe<FilterInt64Operator> /**  */
	vmPowerState?: Maybe<FilterVmPowerStateOperator> /**  */
	vmHealthStatus?: Maybe<FilterVmHealthStatusOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVirtualMachineGuest>> /**  */
	or?: Array<Maybe<FilterOfVirtualMachineGuest>> /**  */
	nor?: Array<Maybe<FilterOfVirtualMachineGuest>> /**  */
	not?: Array<Maybe<FilterOfVirtualMachineGuest>> /**  */
}


/**  */
export type FilterVmPowerStateOperator = {
	eq?: Maybe<VmPowerState> /**  */
	ne?: Maybe<VmPowerState> /**  */
	in?: Array<Maybe<VmPowerState>> /**  */
	nin?: Array<Maybe<VmPowerState>> /**  */
}


/**  */
export type FilterVmHealthStatusOperator = {
	eq?: Maybe<VmHealthStatus> /**  */
	ne?: Maybe<VmHealthStatus> /**  */
	in?: Array<Maybe<VmHealthStatus>> /**  */
	nin?: Array<Maybe<VmHealthStatus>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetLinkInVirtualMachine = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInVirtualMachine = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayNetworkAdapterOperator = {
	elemMatch?: Maybe<FilterOfNetworkAdapter> /**  */
}


/**  */
export type FilterOfNetworkAdapter = {
	adapterType?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	guid?: Maybe<FilterStringOperator> /**  */
	index?: Maybe<FilterInt64Operator> /**  */
	interfaceIndex?: Maybe<FilterInt64Operator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	maxSpeed?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	netConnectionId?: Maybe<FilterStringOperator> /**  */
	netEnabled?: Maybe<FilterBooleanOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	powerManagementSupported?: Maybe<FilterBooleanOperator> /**  */
	productName?: Maybe<FilterStringOperator> /**  */
	serviceName?: Maybe<FilterStringOperator> /**  */
	speed?: Maybe<FilterInt64Operator> /**  */
	networkAdapterConfiguration?: Maybe<FilterOfNetworkAdapterConfiguration> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInNetworkAdapter> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNetworkAdapter>> /**  */
	or?: Array<Maybe<FilterOfNetworkAdapter>> /**  */
	nor?: Array<Maybe<FilterOfNetworkAdapter>> /**  */
	not?: Array<Maybe<FilterOfNetworkAdapter>> /**  */
}


/**  */
export type FilterOfNetworkAdapterConfiguration = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dhcpEnabled?: Maybe<FilterBooleanOperator> /**  */
	dhcpServer?: Maybe<FilterStringOperator> /**  */
	dnsDomain?: Maybe<FilterStringOperator> /**  */
	dnsDomainSuffixSearchOrder?: Maybe<ArrayStringTypeOperator> /**  */
	dnsEnabledForWinsResolution?: Maybe<FilterBooleanOperator> /**  */
	dnsHostName?: Maybe<FilterStringOperator> /**  */
	dnsServerSearchOrder?: Maybe<ArrayStringTypeOperator> /**  */
	domainDnsRegistrationEnabled?: Maybe<FilterBooleanOperator> /**  */
	index?: Maybe<FilterInt64Operator> /**  */
	interfaceIndex?: Maybe<FilterInt64Operator> /**  */
	ipEnabled?: Maybe<FilterBooleanOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	winsEnableLmHostsLookup?: Maybe<FilterBooleanOperator> /**  */
	winsHostLookupFile?: Maybe<FilterStringOperator> /**  */
	winsPrimaryServer?: Maybe<FilterStringOperator> /**  */
	winsSecondaryServer?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<ArrayStringTypeOperator> /**  */
	ipSubnet?: Maybe<ArrayStringTypeOperator> /**  */
	defaultIpGateway?: Maybe<ArrayStringTypeOperator> /**  */
	and?: Array<Maybe<FilterOfNetworkAdapterConfiguration>> /**  */
	or?: Array<Maybe<FilterOfNetworkAdapterConfiguration>> /**  */
	nor?: Array<Maybe<FilterOfNetworkAdapterConfiguration>> /**  */
	not?: Array<Maybe<FilterOfNetworkAdapterConfiguration>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInNetworkAdapter = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayNetworkDriveOperator = {
	elemMatch?: Maybe<FilterOfNetworkDrive> /**  */
}


/**  */
export type FilterOfNetworkDrive = {
	remotePath?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	letter?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInNetworkDrive> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNetworkDrive>> /**  */
	or?: Array<Maybe<FilterOfNetworkDrive>> /**  */
	nor?: Array<Maybe<FilterOfNetworkDrive>> /**  */
	not?: Array<Maybe<FilterOfNetworkDrive>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInNetworkDrive = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayNtLogEventOperator = {
	elemMatch?: Maybe<FilterOfNtLogEvent> /**  */
}


/**  */
export type FilterOfNtLogEvent = {
	category?: Maybe<FilterInt64Operator> /**  */
	categorystring?: Maybe<FilterStringOperator> /**  */
	computerName?: Maybe<FilterStringOperator> /**  */
	data?: Maybe<ArrayIntTypeOperator> /**  */
	eventCode?: Maybe<FilterInt64Operator> /**  */
	eventIdentifier?: Maybe<FilterInt64Operator> /**  */
	eventType?: Maybe<FilterEventTypeOperator> /**  */
	insertionStrings?: Maybe<ArrayStringTypeOperator> /**  */
	logfile?: Maybe<FilterStringOperator> /**  */
	message?: Maybe<FilterStringOperator> /**  */
	recordNumber?: Maybe<FilterInt64Operator> /**  */
	sourceName?: Maybe<FilterStringOperator> /**  */
	timeGenerated?: Maybe<FilterDateTimeOperator> /**  */
	timeWritten?: Maybe<FilterDateTimeOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	user?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInNtLogEvent> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNtLogEvent>> /**  */
	or?: Array<Maybe<FilterOfNtLogEvent>> /**  */
	nor?: Array<Maybe<FilterOfNtLogEvent>> /**  */
	not?: Array<Maybe<FilterOfNtLogEvent>> /**  */
}


/**  */
export type ArrayIntTypeOperator = {
	all?: Array<Maybe<number>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterEventTypeOperator = {
	eq?: Maybe<EventType> /**  */
	ne?: Maybe<EventType> /**  */
	in?: Array<Maybe<EventType>> /**  */
	nin?: Array<Maybe<EventType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInNtLogEvent = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayOperatingSystemOperator = {
	elemMatch?: Maybe<FilterOfOperatingSystem> /**  */
}


/**  */
export type FilterOfOperatingSystem = {
	bootDevice?: Maybe<FilterStringOperator> /**  */
	buildNumber?: Maybe<FilterStringOperator> /**  */
	buildType?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	codeSet?: Maybe<FilterStringOperator> /**  */
	countryCode?: Maybe<FilterStringOperator> /**  */
	csdVersion?: Maybe<FilterStringOperator> /**  */
	currentTimeZone?: Maybe<FilterInt32Operator> /**  */
	debug?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	distributed?: Maybe<FilterBooleanOperator> /**  */
	encryptionLevel?: Maybe<FilterInt64Operator> /**  */
	foregroundApplicationBoost?: Maybe<FilterInt32Operator> /**  */
	freePhysicalMemory?: Maybe<FilterInt64Operator> /**  */
	freeSpaceInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	freeVirtualMemory?: Maybe<FilterInt64Operator> /**  */
	largeSystemCache?: Maybe<FilterInt64Operator> /**  */
	lastBootUpTime?: Maybe<FilterDateTimeOperator> /**  */
	localDateTime?: Maybe<FilterDateTimeOperator> /**  */
	locale?: Maybe<FilterStringOperator> /**  */
	maxNumberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	maxProcessMemorySize?: Maybe<FilterInt64Operator> /**  */
	muiLanguages?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	numberOfLicensedUsers?: Maybe<FilterInt64Operator> /**  */
	numberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	numberOfUsers?: Maybe<FilterInt64Operator> /**  */
	operatingSystemSku?: Maybe<FilterInt64Operator> /**  */
	organization?: Maybe<FilterStringOperator> /**  */
	osArchitecture?: Maybe<FilterStringOperator> /**  */
	osLanguage?: Maybe<FilterInt64Operator> /**  */
	osProductSuite?: Maybe<FilterInt64Operator> /**  */
	osType?: Maybe<FilterInt64Operator> /**  */
	otherTypeDescription?: Maybe<FilterStringOperator> /**  */
	paeEnabled?: Maybe<FilterBooleanOperator> /**  */
	plusProductId?: Maybe<FilterStringOperator> /**  */
	plusVersionNumber?: Maybe<FilterStringOperator> /**  */
	primary?: Maybe<FilterBooleanOperator> /**  */
	productType?: Maybe<FilterInt64Operator> /**  */
	registeredUser?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	servicePackMajorVersion?: Maybe<FilterInt64Operator> /**  */
	servicePackMinorVersion?: Maybe<FilterInt64Operator> /**  */
	sizeStoredInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	suiteMask?: Maybe<FilterInt64Operator> /**  */
	systemDevice?: Maybe<FilterStringOperator> /**  */
	systemDirectory?: Maybe<FilterStringOperator> /**  */
	systemDrive?: Maybe<FilterStringOperator> /**  */
	totalSwapSpaceSize?: Maybe<FilterInt64Operator> /**  */
	totalVirtualMemorySize?: Maybe<FilterInt64Operator> /**  */
	totalVisibleMemorySize?: Maybe<FilterInt64Operator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	windowsDirectory?: Maybe<FilterStringOperator> /**  */
	digitalProductId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	installDate?: Maybe<FilterDateTimeOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInOperatingSystem> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInOperatingSystem> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOperatingSystem>> /**  */
	or?: Array<Maybe<FilterOfOperatingSystem>> /**  */
	nor?: Array<Maybe<FilterOfOperatingSystem>> /**  */
	not?: Array<Maybe<FilterOfOperatingSystem>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInOperatingSystem = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInOperatingSystem = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayParallelPortOperator = {
	elemMatch?: Maybe<FilterOfParallelPort> /**  */
}


/**  */
export type FilterOfParallelPort = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInParallelPort> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfParallelPort>> /**  */
	or?: Array<Maybe<FilterOfParallelPort>> /**  */
	nor?: Array<Maybe<FilterOfParallelPort>> /**  */
	not?: Array<Maybe<FilterOfParallelPort>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInParallelPort = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPhysicalMemoryArrayOperator = {
	elemMatch?: Maybe<FilterOfPhysicalMemoryArray> /**  */
}


/**  */
export type FilterOfPhysicalMemoryArray = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	location?: Maybe<FilterInt64Operator> /**  */
	maxCapacity?: Maybe<FilterInt64Operator> /**  */
	memoryDevices?: Maybe<FilterInt64Operator> /**  */
	memoryErrorCorrection?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	use?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPhysicalMemoryArray> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPhysicalMemoryArray>> /**  */
	or?: Array<Maybe<FilterOfPhysicalMemoryArray>> /**  */
	nor?: Array<Maybe<FilterOfPhysicalMemoryArray>> /**  */
	not?: Array<Maybe<FilterOfPhysicalMemoryArray>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPhysicalMemoryArray = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPhysicalMemoryOperator = {
	elemMatch?: Maybe<FilterOfPhysicalMemory> /**  */
}


/**  */
export type FilterOfPhysicalMemory = {
	bankLabel?: Maybe<FilterStringOperator> /**  */
	capacity?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	dataWidth?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceLocator?: Maybe<FilterStringOperator> /**  */
	formFactor?: Maybe<FilterFormFactorOperator> /**  */
	hotSwappable?: Maybe<FilterBooleanOperator> /**  */
	interleaveDataDepth?: Maybe<FilterInt64Operator> /**  */
	interleavePosition?: Maybe<FilterInt64Operator> /**  */
	memoryType?: Maybe<FilterMemoryTypeOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	partNumber?: Maybe<FilterStringOperator> /**  */
	positionInRow?: Maybe<FilterInt64Operator> /**  */
	poweredOn?: Maybe<FilterBooleanOperator> /**  */
	removable?: Maybe<FilterBooleanOperator> /**  */
	replaceable?: Maybe<FilterBooleanOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	sku?: Maybe<FilterStringOperator> /**  */
	speed?: Maybe<FilterInt64Operator> /**  */
	tag?: Maybe<FilterStringOperator> /**  */
	totalWidth?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPhysicalMemory> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPhysicalMemory>> /**  */
	or?: Array<Maybe<FilterOfPhysicalMemory>> /**  */
	nor?: Array<Maybe<FilterOfPhysicalMemory>> /**  */
	not?: Array<Maybe<FilterOfPhysicalMemory>> /**  */
}


/**  */
export type FilterFormFactorOperator = {
	eq?: Maybe<FormFactor> /**  */
	ne?: Maybe<FormFactor> /**  */
	in?: Array<Maybe<FormFactor>> /**  */
	nin?: Array<Maybe<FormFactor>> /**  */
}


/**  */
export type FilterMemoryTypeOperator = {
	eq?: Maybe<MemoryType> /**  */
	ne?: Maybe<MemoryType> /**  */
	in?: Array<Maybe<MemoryType>> /**  */
	nin?: Array<Maybe<MemoryType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPhysicalMemory = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPnpEntityOperator = {
	elemMatch?: Maybe<FilterOfPnpEntity> /**  */
}


/**  */
export type FilterOfPnpEntity = {
	caption?: Maybe<FilterStringOperator> /**  */
	classGuid?: Maybe<FilterStringOperator> /**  */
	pnpEntityString?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	hardwareId?: Maybe<ArrayStringTypeOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	service?: Maybe<FilterStringOperator> /**  */
	configManagerErrorCode?: Maybe<FilterInt32Operator> /**  */
	configManagerErrorCodeString?: Maybe<FilterStringOperator> /**  */
	errorDescription?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPnpEntity> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPnpEntity>> /**  */
	or?: Array<Maybe<FilterOfPnpEntity>> /**  */
	nor?: Array<Maybe<FilterOfPnpEntity>> /**  */
	not?: Array<Maybe<FilterOfPnpEntity>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPnpEntity = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPointingDeviceOperator = {
	elemMatch?: Maybe<FilterOfPointingDevice> /**  */
}


/**  */
export type FilterOfPointingDevice = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	hardwareType?: Maybe<FilterStringOperator> /**  */
	infFileName?: Maybe<FilterStringOperator> /**  */
	infSection?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	numberOfButtons?: Maybe<FilterInt32Operator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPointingDevice> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPointingDevice>> /**  */
	or?: Array<Maybe<FilterOfPointingDevice>> /**  */
	nor?: Array<Maybe<FilterOfPointingDevice>> /**  */
	not?: Array<Maybe<FilterOfPointingDevice>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPointingDevice = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPortConnectorOperator = {
	elemMatch?: Maybe<FilterOfPortConnector> /**  */
}


/**  */
export type FilterOfPortConnector = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	externalReferenceDesignator?: Maybe<FilterStringOperator> /**  */
	internalReferenceDesignator?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	portType?: Maybe<FilterInt64Operator> /**  */
	tag?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPortConnector> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPortConnector>> /**  */
	or?: Array<Maybe<FilterOfPortConnector>> /**  */
	nor?: Array<Maybe<FilterOfPortConnector>> /**  */
	not?: Array<Maybe<FilterOfPortConnector>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPortConnector = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPotsModemOperator = {
	elemMatch?: Maybe<FilterOfPotsModem> /**  */
}


/**  */
export type FilterOfPotsModem = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPotsModem> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPotsModem>> /**  */
	or?: Array<Maybe<FilterOfPotsModem>> /**  */
	nor?: Array<Maybe<FilterOfPotsModem>> /**  */
	not?: Array<Maybe<FilterOfPotsModem>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPotsModem = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPrinterOperator = {
	elemMatch?: Maybe<FilterOfPrinter> /**  */
}


/**  */
export type FilterOfPrinter = {
	action?: Maybe<FilterInt64Operator> /**  */
	attributes?: Maybe<FilterInt64Operator> /**  */
	dataType?: Maybe<FilterStringOperator> /**  */
	objectGuid?: Maybe<FilterStringOperator> /**  */
	printProcessor?: Maybe<FilterStringOperator> /**  */
	printerDriver?: Maybe<FilterStringOperator> /**  */
	priority?: Maybe<FilterInt64Operator> /**  */
	separatorFile?: Maybe<FilterStringOperator> /**  */
	shareName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterInt64Operator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	isNetWork?: Maybe<FilterBooleanOperator> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	deviceInstanceId?: Maybe<FilterStringOperator> /**  */
	hardwareId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	printerName?: Maybe<FilterStringOperator> /**  */
	printShareName?: Maybe<FilterStringOperator> /**  */
	serverName?: Maybe<FilterStringOperator> /**  */
	shortServerName?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	driverName?: Maybe<FilterStringOperator> /**  */
	portName?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPrinter> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPrinter>> /**  */
	or?: Array<Maybe<FilterOfPrinter>> /**  */
	nor?: Array<Maybe<FilterOfPrinter>> /**  */
	not?: Array<Maybe<FilterOfPrinter>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPrinter = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayProcessOperator = {
	elemMatch?: Maybe<FilterOfProcess> /**  */
}


/**  */
export type FilterOfProcess = {
	handle?: Maybe<FilterStringOperator> /**  */
	processId?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	commandLine?: Maybe<FilterStringOperator> /**  */
	creationDate?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	executablePath?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	osCreationClassName?: Maybe<FilterStringOperator> /**  */
	osName?: Maybe<FilterStringOperator> /**  */
	virtualSize?: Maybe<FilterInt64Operator> /**  */
	workingSetSize?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInProcess> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfProcess>> /**  */
	or?: Array<Maybe<FilterOfProcess>> /**  */
	nor?: Array<Maybe<FilterOfProcess>> /**  */
	not?: Array<Maybe<FilterOfProcess>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInProcess = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayProcessorOperator = {
	elemMatch?: Maybe<FilterOfProcessor> /**  */
}


/**  */
export type FilterOfProcessor = {
	addressWidth?: Maybe<FilterInt64Operator> /**  */
	architecture?: Maybe<FilterArchitectureOperator> /**  */
	availability?: Maybe<FilterAvailabilityOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	configManagerErrorCode?: Maybe<FilterInt64Operator> /**  */
	configManagerUserConfig?: Maybe<FilterBooleanOperator> /**  */
	cpuStatus?: Maybe<FilterInt64Operator> /**  */
	currentClockSpeed?: Maybe<FilterInt64Operator> /**  */
	currentVoltage?: Maybe<FilterInt64Operator> /**  */
	dataWidth?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	errorCleared?: Maybe<FilterBooleanOperator> /**  */
	errorDescription?: Maybe<FilterStringOperator> /**  */
	extClock?: Maybe<FilterInt64Operator> /**  */
	family?: Maybe<FilterInt64Operator> /**  */
	l2CacheSize?: Maybe<FilterInt64Operator> /**  */
	l2CacheSpeed?: Maybe<FilterInt64Operator> /**  */
	l3CacheSize?: Maybe<FilterInt64Operator> /**  */
	l3CacheSpeed?: Maybe<FilterInt64Operator> /**  */
	lastErrorCode?: Maybe<FilterInt64Operator> /**  */
	level?: Maybe<FilterInt64Operator> /**  */
	loadPercentage?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	maxClockSpeed?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	numberOfCores?: Maybe<FilterInt64Operator> /**  */
	numberOfLogicalProcessors?: Maybe<FilterInt64Operator> /**  */
	otherFamilyDescription?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	powerManagementSupported?: Maybe<FilterBooleanOperator> /**  */
	processorId?: Maybe<FilterStringOperator> /**  */
	processorType?: Maybe<FilterInt64Operator> /**  */
	revision?: Maybe<FilterInt64Operator> /**  */
	role?: Maybe<FilterStringOperator> /**  */
	socketDesignation?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	statusInfo?: Maybe<FilterInt64Operator> /**  */
	stepping?: Maybe<FilterStringOperator> /**  */
	uniqueId?: Maybe<FilterStringOperator> /**  */
	upgradeMethod?: Maybe<FilterInt64Operator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	voltageCaps?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInProcessor> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfProcessor>> /**  */
	or?: Array<Maybe<FilterOfProcessor>> /**  */
	nor?: Array<Maybe<FilterOfProcessor>> /**  */
	not?: Array<Maybe<FilterOfProcessor>> /**  */
}


/**  */
export type FilterArchitectureOperator = {
	eq?: Maybe<Architecture> /**  */
	ne?: Maybe<Architecture> /**  */
	in?: Array<Maybe<Architecture>> /**  */
	nin?: Array<Maybe<Architecture>> /**  */
}


/**  */
export type FilterAvailabilityOperator = {
	eq?: Maybe<Availability> /**  */
	ne?: Maybe<Availability> /**  */
	in?: Array<Maybe<Availability>> /**  */
	nin?: Array<Maybe<Availability>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInProcessor = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayProgramOperator = {
	elemMatch?: Maybe<FilterOfProgram> /**  */
}


/**  */
export type FilterOfProgram = {
	parentDisplayName?: Maybe<FilterStringOperator> /**  */
	contact?: Maybe<FilterStringOperator> /**  */
	estimatedSize?: Maybe<FilterInt64Operator> /**  */
	installLocation?: Maybe<FilterStringOperator> /**  */
	installSource?: Maybe<FilterStringOperator> /**  */
	helpLink?: Maybe<FilterStringOperator> /**  */
	language?: Maybe<FilterInt64Operator> /**  */
	productId?: Maybe<FilterStringOperator> /**  */
	uninstallString?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
	installDate?: Maybe<FilterDateTimeOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInProgram> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInProgram> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfProgram>> /**  */
	or?: Array<Maybe<FilterOfProgram>> /**  */
	nor?: Array<Maybe<FilterOfProgram>> /**  */
	not?: Array<Maybe<FilterOfProgram>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInProgram = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInProgram = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayQuickFixEngineeringOperator = {
	elemMatch?: Maybe<FilterOfQuickFixEngineering> /**  */
}


/**  */
export type FilterOfQuickFixEngineering = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	fixComments?: Maybe<FilterStringOperator> /**  */
	hotFixId?: Maybe<FilterStringOperator> /**  */
	installedBy?: Maybe<FilterStringOperator> /**  */
	installedOn?: Maybe<FilterStringOperator> /**  */
	installedDate?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	servicePackInEffect?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInQuickFixEngineering> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInQuickFixEngineering> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfQuickFixEngineering>> /**  */
	or?: Array<Maybe<FilterOfQuickFixEngineering>> /**  */
	nor?: Array<Maybe<FilterOfQuickFixEngineering>> /**  */
	not?: Array<Maybe<FilterOfQuickFixEngineering>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInQuickFixEngineering = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInQuickFixEngineering = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayWorkApplicationOperator = {
	elemMatch?: Maybe<FilterOfWorkApplication> /**  */
}


/**  */
export type FilterOfWorkApplication = {
	publisher?: Maybe<FilterStringOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInWorkApplication> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInWorkApplication> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkApplication>> /**  */
	or?: Array<Maybe<FilterOfWorkApplication>> /**  */
	nor?: Array<Maybe<FilterOfWorkApplication>> /**  */
	not?: Array<Maybe<FilterOfWorkApplication>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInWorkApplication = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInWorkApplication = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySerialPortOperator = {
	elemMatch?: Maybe<FilterOfSerialPort> /**  */
}


/**  */
export type FilterOfSerialPort = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSerialPort> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSerialPort>> /**  */
	or?: Array<Maybe<FilterOfSerialPort>> /**  */
	nor?: Array<Maybe<FilterOfSerialPort>> /**  */
	not?: Array<Maybe<FilterOfSerialPort>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSerialPort = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayWindowsServiceOperator = {
	elemMatch?: Maybe<FilterOfWindowsService> /**  */
}


/**  */
export type FilterOfWindowsService = {
	acceptPause?: Maybe<FilterBooleanOperator> /**  */
	acceptStop?: Maybe<FilterBooleanOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pathName?: Maybe<FilterStringOperator> /**  */
	started?: Maybe<FilterBooleanOperator> /**  */
	startMode?: Maybe<FilterStartModeOperator> /**  */
	startName?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterServiceStateOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInWindowsService> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWindowsService>> /**  */
	or?: Array<Maybe<FilterOfWindowsService>> /**  */
	nor?: Array<Maybe<FilterOfWindowsService>> /**  */
	not?: Array<Maybe<FilterOfWindowsService>> /**  */
}


/**  */
export type FilterStartModeOperator = {
	eq?: Maybe<StartMode> /**  */
	ne?: Maybe<StartMode> /**  */
	in?: Array<Maybe<StartMode>> /**  */
	nin?: Array<Maybe<StartMode>> /**  */
}


/**  */
export type FilterServiceStateOperator = {
	eq?: Maybe<ServiceState> /**  */
	ne?: Maybe<ServiceState> /**  */
	in?: Array<Maybe<ServiceState>> /**  */
	nin?: Array<Maybe<ServiceState>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInWindowsService = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayShareOperator = {
	elemMatch?: Maybe<FilterOfShare> /**  */
}


/**  */
export type FilterOfShare = {
	accessMask?: Maybe<FilterInt64Operator> /**  */
	allowMaximum?: Maybe<FilterBooleanOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	maximumAllowed?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterShareCategoryOperator> /**  */
	shareSecurities?: Maybe<ArrayShareSecurityOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInShare> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfShare>> /**  */
	or?: Array<Maybe<FilterOfShare>> /**  */
	nor?: Array<Maybe<FilterOfShare>> /**  */
	not?: Array<Maybe<FilterOfShare>> /**  */
}


/**  */
export type FilterShareCategoryOperator = {
	eq?: Maybe<ShareCategory> /**  */
	ne?: Maybe<ShareCategory> /**  */
	in?: Array<Maybe<ShareCategory>> /**  */
	nin?: Array<Maybe<ShareCategory>> /**  */
}


/**  */
export type ArrayShareSecurityOperator = {
	elemMatch?: Maybe<FilterOfShareSecurity> /**  */
}


/**  */
export type FilterOfShareSecurity = {
	name?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	accessMaskValue?: Maybe<FilterInt64Operator> /**  */
	shareAccess?: Maybe<FilterOfShareAccess> /**  */
	sidString?: Maybe<FilterStringOperator> /**  */
	sidLength?: Maybe<FilterInt64Operator> /**  */
	aceFlags?: Maybe<FilterInt64Operator> /**  */
	aceType?: Maybe<FilterInt64Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfShareSecurity>> /**  */
	or?: Array<Maybe<FilterOfShareSecurity>> /**  */
	nor?: Array<Maybe<FilterOfShareSecurity>> /**  */
	not?: Array<Maybe<FilterOfShareSecurity>> /**  */
}


/**  */
export type FilterOfShareAccess = {
	read?: Maybe<FilterBooleanOperator> /**  */
	write?: Maybe<FilterBooleanOperator> /**  */
	fullAccess?: Maybe<FilterBooleanOperator> /**  */
	deny?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfShareAccess>> /**  */
	or?: Array<Maybe<FilterOfShareAccess>> /**  */
	nor?: Array<Maybe<FilterOfShareAccess>> /**  */
	not?: Array<Maybe<FilterOfShareAccess>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInShare = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySqlServerOperator = {
	elemMatch?: Maybe<FilterOfSqlServer> /**  */
}


/**  */
export type FilterOfSqlServer = {
	instanceName?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	edition?: Maybe<FilterStringOperator> /**  */
	licenceName?: Maybe<FilterStringOperator> /**  */
	fileVersion?: Maybe<FilterStringOperator> /**  */
	servicePack?: Maybe<FilterStringOperator> /**  */
	wow64?: Maybe<FilterBooleanOperator> /**  */
	language?: Maybe<FilterStringOperator> /**  */
	installPath?: Maybe<FilterStringOperator> /**  */
	dataPath?: Maybe<FilterStringOperator> /**  */
	sqlService?: Maybe<FilterOfSqlService> /**  */
	dataBaseInformations?: Maybe<ArrayDataBaseInformationOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSqlServer> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSqlServer>> /**  */
	or?: Array<Maybe<FilterOfSqlServer>> /**  */
	nor?: Array<Maybe<FilterOfSqlServer>> /**  */
	not?: Array<Maybe<FilterOfSqlServer>> /**  */
}


/**  */
export type FilterOfSqlService = {
	acceptPause?: Maybe<FilterBooleanOperator> /**  */
	acceptStop?: Maybe<FilterBooleanOperator> /**  */
	binaryPath?: Maybe<FilterStringOperator> /**  */
	dependencies?: Maybe<ArrayStringTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	errorControl?: Maybe<FilterInt64Operator> /**  */
	exitCode?: Maybe<FilterInt64Operator> /**  */
	hostName?: Maybe<FilterStringOperator> /**  */
	processId?: Maybe<FilterInt64Operator> /**  */
	serviceName?: Maybe<FilterStringOperator> /**  */
	sqlServiceType?: Maybe<FilterSqlServiceCategoryOperator> /**  */
	startMode?: Maybe<FilterStartModeOperator> /**  */
	startName?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterServiceStateOperator> /**  */
	and?: Array<Maybe<FilterOfSqlService>> /**  */
	or?: Array<Maybe<FilterOfSqlService>> /**  */
	nor?: Array<Maybe<FilterOfSqlService>> /**  */
	not?: Array<Maybe<FilterOfSqlService>> /**  */
}


/**  */
export type FilterSqlServiceCategoryOperator = {
	eq?: Maybe<SqlServiceCategory> /**  */
	ne?: Maybe<SqlServiceCategory> /**  */
	in?: Array<Maybe<SqlServiceCategory>> /**  */
	nin?: Array<Maybe<SqlServiceCategory>> /**  */
}


/**  */
export type ArrayDataBaseInformationOperator = {
	elemMatch?: Maybe<FilterOfDataBaseInformation> /**  */
}


/**  */
export type FilterOfDataBaseInformation = {
	name?: Maybe<FilterStringOperator> /**  */
	dataFilesSizeKb?: Maybe<FilterStringOperator> /**  */
	logFilesSizeKb?: Maybe<FilterStringOperator> /**  */
	logFilesUsedSizeKb?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDataBaseInformation>> /**  */
	or?: Array<Maybe<FilterOfDataBaseInformation>> /**  */
	nor?: Array<Maybe<FilterOfDataBaseInformation>> /**  */
	not?: Array<Maybe<FilterOfDataBaseInformation>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSqlServer = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySoundDeviceOperator = {
	elemMatch?: Maybe<FilterOfSoundDevice> /**  */
}


/**  */
export type FilterOfSoundDevice = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	productName?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSoundDevice> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoundDevice>> /**  */
	or?: Array<Maybe<FilterOfSoundDevice>> /**  */
	nor?: Array<Maybe<FilterOfSoundDevice>> /**  */
	not?: Array<Maybe<FilterOfSoundDevice>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSoundDevice = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySystemEnclosureOperator = {
	elemMatch?: Maybe<FilterOfSystemEnclosure> /**  */
}


/**  */
export type FilterOfSystemEnclosure = {
	audibleAlarm?: Maybe<FilterBooleanOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	chassisTypes?: Maybe<FilterChassisTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	lockPresent?: Maybe<FilterBooleanOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	partNumber?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	sku?: Maybe<FilterStringOperator> /**  */
	smbiosAssetTag?: Maybe<FilterStringOperator> /**  */
	tag?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	computerDescription?: Maybe<FilterStringOperator> /**  */
	dockingMode?: Maybe<FilterDockingModeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSystemEnclosure> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSystemEnclosure>> /**  */
	or?: Array<Maybe<FilterOfSystemEnclosure>> /**  */
	nor?: Array<Maybe<FilterOfSystemEnclosure>> /**  */
	not?: Array<Maybe<FilterOfSystemEnclosure>> /**  */
}


/**  */
export type FilterChassisTypeOperator = {
	eq?: Maybe<ChassisType> /**  */
	ne?: Maybe<ChassisType> /**  */
	in?: Array<Maybe<ChassisType>> /**  */
	nin?: Array<Maybe<ChassisType>> /**  */
}


/**  */
export type FilterDockingModeOperator = {
	eq?: Maybe<DockingMode> /**  */
	ne?: Maybe<DockingMode> /**  */
	in?: Array<Maybe<DockingMode>> /**  */
	nin?: Array<Maybe<DockingMode>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSystemEnclosure = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySystemSlotOperator = {
	elemMatch?: Maybe<FilterOfSystemSlot> /**  */
}


/**  */
export type FilterOfSystemSlot = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	slotDesignation?: Maybe<FilterStringOperator> /**  */
	tag?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSystemSlot> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSystemSlot>> /**  */
	or?: Array<Maybe<FilterOfSystemSlot>> /**  */
	nor?: Array<Maybe<FilterOfSystemSlot>> /**  */
	not?: Array<Maybe<FilterOfSystemSlot>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSystemSlot = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayTapeDriveOperator = {
	elemMatch?: Maybe<FilterOfTapeDrive> /**  */
}


/**  */
export type FilterOfTapeDrive = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	ecc?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	mediaType?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	needsCleaning?: Maybe<FilterBooleanOperator> /**  */
	numberOfMediaSupported?: Maybe<FilterInt64Operator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	statusInfo?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInTapeDrive> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTapeDrive>> /**  */
	or?: Array<Maybe<FilterOfTapeDrive>> /**  */
	nor?: Array<Maybe<FilterOfTapeDrive>> /**  */
	not?: Array<Maybe<FilterOfTapeDrive>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInTapeDrive = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayUserAccountControlOperator = {
	elemMatch?: Maybe<FilterOfUserAccountControl> /**  */
}


/**  */
export type FilterOfUserAccountControl = {
	name?: Maybe<FilterStringOperator> /**  */
	consentPromptBehaviorAdmin?: Maybe<FilterConsentPromptBehaviorAdminOperator> /**  */
	consentPromptBehaviorUser?: Maybe<FilterConsentPromptBehaviorUserOperator> /**  */
	enableInstallerDetection?: Maybe<FilterBooleanOperator> /**  */
	enableLua?: Maybe<FilterBooleanOperator> /**  */
	promptOnSecureDesktop?: Maybe<FilterBooleanOperator> /**  */
	filterAdministratorToken?: Maybe<FilterBooleanOperator> /**  */
	enableUiaDesktopToggle?: Maybe<FilterBooleanOperator> /**  */
	enableSecureUiaPaths?: Maybe<FilterBooleanOperator> /**  */
	validateAdminCodeSignatures?: Maybe<FilterBooleanOperator> /**  */
	enableVirtualization?: Maybe<FilterBooleanOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInUserAccountControl> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserAccountControl>> /**  */
	or?: Array<Maybe<FilterOfUserAccountControl>> /**  */
	nor?: Array<Maybe<FilterOfUserAccountControl>> /**  */
	not?: Array<Maybe<FilterOfUserAccountControl>> /**  */
}


/**  */
export type FilterConsentPromptBehaviorAdminOperator = {
	eq?: Maybe<ConsentPromptBehaviorAdmin> /**  */
	ne?: Maybe<ConsentPromptBehaviorAdmin> /**  */
	in?: Array<Maybe<ConsentPromptBehaviorAdmin>> /**  */
	nin?: Array<Maybe<ConsentPromptBehaviorAdmin>> /**  */
}


/**  */
export type FilterConsentPromptBehaviorUserOperator = {
	eq?: Maybe<ConsentPromptBehaviorUser> /**  */
	ne?: Maybe<ConsentPromptBehaviorUser> /**  */
	in?: Array<Maybe<ConsentPromptBehaviorUser>> /**  */
	nin?: Array<Maybe<ConsentPromptBehaviorUser>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInUserAccountControl = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayUserProfileOperator = {
	elemMatch?: Maybe<FilterOfUserProfile> /**  */
}


/**  */
export type FilterOfUserProfile = {
	lastDownloadTime?: Maybe<FilterDateTimeOperator> /**  */
	lastUploadTime?: Maybe<FilterDateTimeOperator> /**  */
	lastUseTime?: Maybe<FilterDateTimeOperator> /**  */
	loaded?: Maybe<FilterBooleanOperator> /**  */
	localPath?: Maybe<FilterStringOperator> /**  */
	refCount?: Maybe<FilterInt64Operator> /**  */
	roamingConfigured?: Maybe<FilterBooleanOperator> /**  */
	roamingPath?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	roamingPreference?: Maybe<FilterBooleanOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	special?: Maybe<FilterBooleanOperator> /**  */
	status?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInUserProfile> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserProfile>> /**  */
	or?: Array<Maybe<FilterOfUserProfile>> /**  */
	nor?: Array<Maybe<FilterOfUserProfile>> /**  */
	not?: Array<Maybe<FilterOfUserProfile>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInUserProfile = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayVideoControllerOperator = {
	elemMatch?: Maybe<FilterOfVideoController> /**  */
}


/**  */
export type FilterOfVideoController = {
	adapterCompatibility?: Maybe<FilterStringOperator> /**  */
	adapterDacType?: Maybe<FilterStringOperator> /**  */
	adapterRam?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	currentBitsPerPixel?: Maybe<FilterInt64Operator> /**  */
	currentHorizontalResolution?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfColors?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfColumns?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfRows?: Maybe<FilterInt64Operator> /**  */
	currentRefreshRate?: Maybe<FilterInt64Operator> /**  */
	currentScanMode?: Maybe<FilterInt64Operator> /**  */
	currentVerticalResolution?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	driverDate?: Maybe<FilterDateTimeOperator> /**  */
	driverVersion?: Maybe<FilterStringOperator> /**  */
	monochrome?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	videoArchitecture?: Maybe<FilterInt64Operator> /**  */
	videoMemoryType?: Maybe<FilterInt64Operator> /**  */
	videoMode?: Maybe<FilterInt64Operator> /**  */
	videoModeDescription?: Maybe<FilterStringOperator> /**  */
	videoProcessor?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInVideoController> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVideoController>> /**  */
	or?: Array<Maybe<FilterOfVideoController>> /**  */
	nor?: Array<Maybe<FilterOfVideoController>> /**  */
	not?: Array<Maybe<FilterOfVideoController>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInVideoController = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayWindowsFirewallOperator = {
	elemMatch?: Maybe<FilterOfWindowsFirewall> /**  */
}


/**  */
export type FilterOfWindowsFirewall = {
	name?: Maybe<FilterStringOperator> /**  */
	domainProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	publicProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	standardProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInWindowsFirewall> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWindowsFirewall>> /**  */
	or?: Array<Maybe<FilterOfWindowsFirewall>> /**  */
	nor?: Array<Maybe<FilterOfWindowsFirewall>> /**  */
	not?: Array<Maybe<FilterOfWindowsFirewall>> /**  */
}


/**  */
export type FilterOfFirewallPolicy = {
	disableNotifications?: Maybe<FilterBooleanOperator> /**  */
	doNotAllowExceptions?: Maybe<FilterBooleanOperator> /**  */
	enableFirewall?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfFirewallPolicy>> /**  */
	or?: Array<Maybe<FilterOfFirewallPolicy>> /**  */
	nor?: Array<Maybe<FilterOfFirewallPolicy>> /**  */
	not?: Array<Maybe<FilterOfFirewallPolicy>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInWindowsFirewall = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayWindowsUpdateOperator = {
	elemMatch?: Maybe<FilterOfWindowsUpdate> /**  */
}


/**  */
export type FilterOfWindowsUpdate = {
	name?: Maybe<FilterStringOperator> /**  */
	auOptions?: Maybe<FilterInt64Operator> /**  */
	nextDetectionTime?: Maybe<FilterStringOperator> /**  */
	scheduledInstallDate?: Maybe<FilterStringOperator> /**  */
	scheduledInstallDay?: Maybe<FilterInt64Operator> /**  */
	scheduledInstallTime?: Maybe<FilterInt64Operator> /**  */
	detect?: Maybe<FilterOfWindowsUpdateResult> /**  */
	download?: Maybe<FilterOfWindowsUpdateResult> /**  */
	install?: Maybe<FilterOfWindowsUpdateResult> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInWindowsUpdate> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWindowsUpdate>> /**  */
	or?: Array<Maybe<FilterOfWindowsUpdate>> /**  */
	nor?: Array<Maybe<FilterOfWindowsUpdate>> /**  */
	not?: Array<Maybe<FilterOfWindowsUpdate>> /**  */
}


/**  */
export type FilterOfWindowsUpdateResult = {
	lastError?: Maybe<FilterInt64Operator> /**  */
	lastSuccessTime?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWindowsUpdateResult>> /**  */
	or?: Array<Maybe<FilterOfWindowsUpdateResult>> /**  */
	nor?: Array<Maybe<FilterOfWindowsUpdateResult>> /**  */
	not?: Array<Maybe<FilterOfWindowsUpdateResult>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInWindowsUpdate = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayScanRegistryOperator = {
	elemMatch?: Maybe<FilterOfScanRegistry> /**  */
}


/**  */
export type FilterOfScanRegistry = {
	scanRegistryKeyId?: Maybe<FilterGuidOperator> /**  */
	scanRegistryKey?: Maybe<FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInScanRegistry> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInScanRegistry> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanRegistry>> /**  */
	or?: Array<Maybe<FilterOfScanRegistry>> /**  */
	nor?: Array<Maybe<FilterOfScanRegistry>> /**  */
	not?: Array<Maybe<FilterOfScanRegistry>> /**  */
}


/**  */
export type FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInScanRegistry = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInScanRegistry = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPrinterConsumableOperator = {
	elemMatch?: Maybe<FilterOfPrinterConsumable> /**  */
}


/**  */
export type FilterOfPrinterConsumable = {
	name?: Maybe<FilterStringOperator> /**  */
	maxLevel?: Maybe<FilterInt32Operator> /**  */
	actualLevel?: Maybe<FilterInt32Operator> /**  */
	colorCode?: Maybe<FilterStringOperator> /**  */
	percent?: Maybe<FilterDoubleOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPrinterConsumable> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPrinterConsumable>> /**  */
	or?: Array<Maybe<FilterOfPrinterConsumable>> /**  */
	nor?: Array<Maybe<FilterOfPrinterConsumable>> /**  */
	not?: Array<Maybe<FilterOfPrinterConsumable>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPrinterConsumable = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPageCountOperator = {
	elemMatch?: Maybe<FilterOfPageCount> /**  */
}


/**  */
export type FilterOfPageCount = {
	color?: Maybe<FilterInt32Operator> /**  */
	black?: Maybe<FilterInt32Operator> /**  */
	twoColor?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt32Operator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPageCount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPageCount>> /**  */
	or?: Array<Maybe<FilterOfPageCount>> /**  */
	nor?: Array<Maybe<FilterOfPageCount>> /**  */
	not?: Array<Maybe<FilterOfPageCount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPageCount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayCopyCountOperator = {
	elemMatch?: Maybe<FilterOfCopyCount> /**  */
}


/**  */
export type FilterOfCopyCount = {
	color?: Maybe<FilterInt32Operator> /**  */
	black?: Maybe<FilterInt32Operator> /**  */
	twoColor?: Maybe<FilterInt32Operator> /**  */
	monochrome?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt32Operator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInCopyCount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCopyCount>> /**  */
	or?: Array<Maybe<FilterOfCopyCount>> /**  */
	nor?: Array<Maybe<FilterOfCopyCount>> /**  */
	not?: Array<Maybe<FilterOfCopyCount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInCopyCount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayScannerCountOperator = {
	elemMatch?: Maybe<FilterOfScannerCount> /**  */
}


/**  */
export type FilterOfScannerCount = {
	total?: Maybe<FilterInt32Operator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInScannerCount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScannerCount>> /**  */
	or?: Array<Maybe<FilterOfScannerCount>> /**  */
	nor?: Array<Maybe<FilterOfScannerCount>> /**  */
	not?: Array<Maybe<FilterOfScannerCount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInScannerCount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayFaxCountOperator = {
	elemMatch?: Maybe<FilterOfFaxCount> /**  */
}


/**  */
export type FilterOfFaxCount = {
	color?: Maybe<FilterInt32Operator> /**  */
	black?: Maybe<FilterInt32Operator> /**  */
	send?: Maybe<FilterInt32Operator> /**  */
	receive?: Maybe<FilterInt32Operator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInFaxCount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFaxCount>> /**  */
	or?: Array<Maybe<FilterOfFaxCount>> /**  */
	nor?: Array<Maybe<FilterOfFaxCount>> /**  */
	not?: Array<Maybe<FilterOfFaxCount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInFaxCount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayEntryTableOperator = {
	elemMatch?: Maybe<FilterOfEntryTable> /**  */
}


/**  */
export type FilterOfEntryTable = {
	ifIndex?: Maybe<FilterStringOperator> /**  */
	ifDescr?: Maybe<FilterStringOperator> /**  */
	ifType?: Maybe<FilterStringOperator> /**  */
	ifMtu?: Maybe<FilterStringOperator> /**  */
	ifSpeed?: Maybe<FilterStringOperator> /**  */
	ifPhysAddress?: Maybe<FilterStringOperator> /**  */
	ifAdminStatus?: Maybe<FilterStringOperator> /**  */
	ifOperStatus?: Maybe<FilterStringOperator> /**  */
	ifLastChange?: Maybe<FilterStringOperator> /**  */
	ifInOctets?: Maybe<FilterStringOperator> /**  */
	ifInUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifInNUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifInDiscards?: Maybe<FilterStringOperator> /**  */
	ifInErrors?: Maybe<FilterStringOperator> /**  */
	ifInUnknownProtos?: Maybe<FilterStringOperator> /**  */
	ifOutOctets?: Maybe<FilterStringOperator> /**  */
	ifOutUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifOutNUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifOutDiscards?: Maybe<FilterStringOperator> /**  */
	ifOutErrors?: Maybe<FilterStringOperator> /**  */
	ifOutQLen?: Maybe<FilterStringOperator> /**  */
	ifSpecific?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInEntryTable> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEntryTable>> /**  */
	or?: Array<Maybe<FilterOfEntryTable>> /**  */
	nor?: Array<Maybe<FilterOfEntryTable>> /**  */
	not?: Array<Maybe<FilterOfEntryTable>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInEntryTable = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayVlanOperator = {
	elemMatch?: Maybe<FilterOfVlan> /**  */
}


/**  */
export type FilterOfVlan = {
	port?: Maybe<FilterInt32Operator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInVlan> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVlan>> /**  */
	or?: Array<Maybe<FilterOfVlan>> /**  */
	nor?: Array<Maybe<FilterOfVlan>> /**  */
	not?: Array<Maybe<FilterOfVlan>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInVlan = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayAccessPointOperator = {
	elemMatch?: Maybe<FilterOfAccessPoint> /**  */
}


/**  */
export type FilterOfAccessPoint = {
	macAddress?: Maybe<FilterStringOperator> /**  */
	bSSid?: Maybe<FilterStringOperator> /**  */
	ssid?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAccessPoint> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAccessPoint>> /**  */
	or?: Array<Maybe<FilterOfAccessPoint>> /**  */
	nor?: Array<Maybe<FilterOfAccessPoint>> /**  */
	not?: Array<Maybe<FilterOfAccessPoint>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAccessPoint = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPortOpenOperator = {
	elemMatch?: Maybe<FilterOfPortOpen> /**  */
}


/**  */
export type FilterOfPortOpen = {
	portName?: Maybe<FilterStringOperator> /**  */
	portNumber?: Maybe<FilterInt32Operator> /**  */
	open?: Maybe<FilterBooleanOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPortOpen> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPortOpen>> /**  */
	or?: Array<Maybe<FilterOfPortOpen>> /**  */
	nor?: Array<Maybe<FilterOfPortOpen>> /**  */
	not?: Array<Maybe<FilterOfPortOpen>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPortOpen = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayOdbcDriverOperator = {
	elemMatch?: Maybe<FilterOfOdbcDriver> /**  */
}


/**  */
export type FilterOfOdbcDriver = {
	driver?: Maybe<FilterStringOperator> /**  */
	apiLevel?: Maybe<FilterStringOperator> /**  */
	connectFunctions?: Maybe<FilterStringOperator> /**  */
	driverOdbcVer?: Maybe<FilterStringOperator> /**  */
	fileExtns?: Maybe<FilterStringOperator> /**  */
	fileUsage?: Maybe<FilterStringOperator> /**  */
	setup?: Maybe<FilterStringOperator> /**  */
	sqlLevel?: Maybe<FilterStringOperator> /**  */
	usageCount?: Maybe<FilterInt32Operator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInOdbcDriver> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOdbcDriver>> /**  */
	or?: Array<Maybe<FilterOfOdbcDriver>> /**  */
	nor?: Array<Maybe<FilterOfOdbcDriver>> /**  */
	not?: Array<Maybe<FilterOfOdbcDriver>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInOdbcDriver = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySystemAccountOperator = {
	elemMatch?: Maybe<FilterOfSystemAccount> /**  */
}


/**  */
export type FilterOfSystemAccount = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	localAccount?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	sidType?: Maybe<FilterInt32Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSystemAccount> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSystemAccount>> /**  */
	or?: Array<Maybe<FilterOfSystemAccount>> /**  */
	nor?: Array<Maybe<FilterOfSystemAccount>> /**  */
	not?: Array<Maybe<FilterOfSystemAccount>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSystemAccount = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayLogonSessionOperator = {
	elemMatch?: Maybe<FilterOfLogonSession> /**  */
}


/**  */
export type FilterOfLogonSession = {
	authenticationPackage?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logonId?: Maybe<FilterStringOperator> /**  */
	logonType?: Maybe<FilterLogonTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	startTime?: Maybe<FilterDateTimeOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	logonName?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	fullName?: Maybe<FilterStringOperator> /**  */
	azureObjectId?: Maybe<FilterStringOperator> /**  */
	azureAccountId?: Maybe<FilterStringOperator> /**  */
	azureDisplayName?: Maybe<FilterStringOperator> /**  */
	azureIdentityName?: Maybe<FilterStringOperator> /**  */
	azureTenantId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLogonSession> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLogonSession>> /**  */
	or?: Array<Maybe<FilterOfLogonSession>> /**  */
	nor?: Array<Maybe<FilterOfLogonSession>> /**  */
	not?: Array<Maybe<FilterOfLogonSession>> /**  */
}


/**  */
export type FilterLogonTypeOperator = {
	eq?: Maybe<LogonType> /**  */
	ne?: Maybe<LogonType> /**  */
	in?: Array<Maybe<LogonType>> /**  */
	nin?: Array<Maybe<LogonType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLogonSession = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPnpSignedDriverOperator = {
	elemMatch?: Maybe<FilterOfPnpSignedDriver> /**  */
}


/**  */
export type FilterOfPnpSignedDriver = {
	classGuid?: Maybe<FilterStringOperator> /**  */
	compatId?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceClass?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	deviceName?: Maybe<FilterStringOperator> /**  */
	devLoader?: Maybe<FilterStringOperator> /**  */
	driverDate?: Maybe<FilterDateTimeOperator> /**  */
	driverName?: Maybe<FilterStringOperator> /**  */
	driverVersion?: Maybe<FilterStringOperator> /**  */
	friendlyName?: Maybe<FilterStringOperator> /**  */
	hardWareId?: Maybe<FilterStringOperator> /**  */
	infName?: Maybe<FilterStringOperator> /**  */
	installDate?: Maybe<FilterDateTimeOperator> /**  */
	isSigned?: Maybe<FilterBooleanOperator> /**  */
	location?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pdo?: Maybe<FilterStringOperator> /**  */
	driverProviderName?: Maybe<FilterStringOperator> /**  */
	signer?: Maybe<FilterStringOperator> /**  */
	started?: Maybe<FilterBooleanOperator> /**  */
	startMode?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPnpSignedDriver> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPnpSignedDriver>> /**  */
	or?: Array<Maybe<FilterOfPnpSignedDriver>> /**  */
	nor?: Array<Maybe<FilterOfPnpSignedDriver>> /**  */
	not?: Array<Maybe<FilterOfPnpSignedDriver>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPnpSignedDriver = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySerialNumberOperator = {
	elemMatch?: Maybe<FilterOfSerialNumber> /**  */
}


/**  */
export type FilterOfSerialNumber = {
	productName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	name2?: Maybe<FilterStringOperator> /**  */
	value2?: Maybe<FilterStringOperator> /**  */
	guid?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	serialNumberMode?: Maybe<FilterSerialNumberModeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSerialNumber> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSerialNumber>> /**  */
	or?: Array<Maybe<FilterOfSerialNumber>> /**  */
	nor?: Array<Maybe<FilterOfSerialNumber>> /**  */
	not?: Array<Maybe<FilterOfSerialNumber>> /**  */
}


/**  */
export type FilterSerialNumberModeOperator = {
	eq?: Maybe<SerialNumberMode> /**  */
	ne?: Maybe<SerialNumberMode> /**  */
	in?: Array<Maybe<SerialNumberMode>> /**  */
	nin?: Array<Maybe<SerialNumberMode>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSerialNumber = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayUptimeCalendarOperator = {
	elemMatch?: Maybe<FilterOfUptimeCalendar> /**  */
}


/**  */
export type FilterOfUptimeCalendar = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	uptimeCalendarMode?: Maybe<FilterUptimeCalendarModeOperator> /**  */
	shutDownReason?: Maybe<FilterStringOperator> /**  */
	shutDownType?: Maybe<FilterStringOperator> /**  */
	shutDownProcess?: Maybe<FilterStringOperator> /**  */
	shutDownCode?: Maybe<FilterStringOperator> /**  */
	recordNumber?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInUptimeCalendar> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUptimeCalendar>> /**  */
	or?: Array<Maybe<FilterOfUptimeCalendar>> /**  */
	nor?: Array<Maybe<FilterOfUptimeCalendar>> /**  */
	not?: Array<Maybe<FilterOfUptimeCalendar>> /**  */
}


/**  */
export type FilterUptimeCalendarModeOperator = {
	eq?: Maybe<UptimeCalendarMode> /**  */
	ne?: Maybe<UptimeCalendarMode> /**  */
	in?: Array<Maybe<UptimeCalendarMode>> /**  */
	nin?: Array<Maybe<UptimeCalendarMode>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInUptimeCalendar = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayBootConfigurationOperator = {
	elemMatch?: Maybe<FilterOfBootConfiguration> /**  */
}


/**  */
export type FilterOfBootConfiguration = {
	bootDirectory?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	configurationPath?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	lastDrive?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	scratchDirectory?: Maybe<FilterStringOperator> /**  */
	settingId?: Maybe<FilterStringOperator> /**  */
	tempDirectory?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInBootConfiguration> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBootConfiguration>> /**  */
	or?: Array<Maybe<FilterOfBootConfiguration>> /**  */
	nor?: Array<Maybe<FilterOfBootConfiguration>> /**  */
	not?: Array<Maybe<FilterOfBootConfiguration>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInBootConfiguration = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPageFileOperator = {
	elemMatch?: Maybe<FilterOfPageFile> /**  */
}


/**  */
export type FilterOfPageFile = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	drive?: Maybe<FilterStringOperator> /**  */
	fileName?: Maybe<FilterStringOperator> /**  */
	fileSize?: Maybe<FilterInt64Operator> /**  */
	fileType?: Maybe<FilterStringOperator> /**  */
	freeSpace?: Maybe<FilterDoubleOperator> /**  */
	initialSize?: Maybe<FilterInt64Operator> /**  */
	inUseCount?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	maximumSize?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPageFile> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPageFile>> /**  */
	or?: Array<Maybe<FilterOfPageFile>> /**  */
	nor?: Array<Maybe<FilterOfPageFile>> /**  */
	not?: Array<Maybe<FilterOfPageFile>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPageFile = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayCodecFileOperator = {
	elemMatch?: Maybe<FilterOfCodecFile> /**  */
}


/**  */
export type FilterOfCodecFile = {
	caption?: Maybe<FilterStringOperator> /**  */
	creationDate?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	drive?: Maybe<FilterStringOperator> /**  */
	fileName?: Maybe<FilterStringOperator> /**  */
	fileSize?: Maybe<FilterInt64Operator> /**  */
	fileType?: Maybe<FilterStringOperator> /**  */
	group?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInCodecFile> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCodecFile>> /**  */
	or?: Array<Maybe<FilterOfCodecFile>> /**  */
	nor?: Array<Maybe<FilterOfCodecFile>> /**  */
	not?: Array<Maybe<FilterOfCodecFile>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInCodecFile = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayOsRecoveryConfigurationOperator = {
	elemMatch?: Maybe<FilterOfOsRecoveryConfiguration> /**  */
}


/**  */
export type FilterOfOsRecoveryConfiguration = {
	autoReboot?: Maybe<FilterBooleanOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	debugFilePath?: Maybe<FilterStringOperator> /**  */
	debugInfoType?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	expandedDebugFilePath?: Maybe<FilterStringOperator> /**  */
	expandedMiniDumpDirectory?: Maybe<FilterStringOperator> /**  */
	kernelDumpOnly?: Maybe<FilterBooleanOperator> /**  */
	miniDumpDirectory?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	overwriteExistingDebugFile?: Maybe<FilterBooleanOperator> /**  */
	sendAdminAlert?: Maybe<FilterBooleanOperator> /**  */
	settingId?: Maybe<FilterStringOperator> /**  */
	writeDebugInfo?: Maybe<FilterBooleanOperator> /**  */
	writeToSystemLog?: Maybe<FilterBooleanOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInOsRecoveryConfiguration> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOsRecoveryConfiguration>> /**  */
	or?: Array<Maybe<FilterOfOsRecoveryConfiguration>> /**  */
	nor?: Array<Maybe<FilterOfOsRecoveryConfiguration>> /**  */
	not?: Array<Maybe<FilterOfOsRecoveryConfiguration>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInOsRecoveryConfiguration = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayScsiControllerOperator = {
	elemMatch?: Maybe<FilterOfScsiController> /**  */
}


/**  */
export type FilterOfScsiController = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	driverName?: Maybe<FilterStringOperator> /**  */
	index?: Maybe<FilterInt64Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInScsiController> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScsiController>> /**  */
	or?: Array<Maybe<FilterOfScsiController>> /**  */
	nor?: Array<Maybe<FilterOfScsiController>> /**  */
	not?: Array<Maybe<FilterOfScsiController>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInScsiController = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPcmciaControllerOperator = {
	elemMatch?: Maybe<FilterOfPcmciaController> /**  */
}


/**  */
export type FilterOfPcmciaController = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPcmciaController> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPcmciaController>> /**  */
	or?: Array<Maybe<FilterOfPcmciaController>> /**  */
	nor?: Array<Maybe<FilterOfPcmciaController>> /**  */
	not?: Array<Maybe<FilterOfPcmciaController>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPcmciaController = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayInfraredDeviceOperator = {
	elemMatch?: Maybe<FilterOfInfraredDevice> /**  */
}


/**  */
export type FilterOfInfraredDevice = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInInfraredDevice> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInfraredDevice>> /**  */
	or?: Array<Maybe<FilterOfInfraredDevice>> /**  */
	nor?: Array<Maybe<FilterOfInfraredDevice>> /**  */
	not?: Array<Maybe<FilterOfInfraredDevice>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInInfraredDevice = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayBatteryOperator = {
	elemMatch?: Maybe<FilterOfBattery> /**  */
}


/**  */
export type FilterOfBattery = {
	batteryRechargeTime?: Maybe<FilterInt64Operator> /**  */
	batteryStatus?: Maybe<FilterBatteryStatusOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	chemistry?: Maybe<FilterChemistryOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	designCapacity?: Maybe<FilterInt64Operator> /**  */
	designVoltage?: Maybe<FilterInt64Operator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	estimatedChargeRemaining?: Maybe<FilterInt64Operator> /**  */
	estimatedRunTime?: Maybe<FilterInt64Operator> /**  */
	expectedBatteryLife?: Maybe<FilterInt64Operator> /**  */
	expectedLife?: Maybe<FilterInt64Operator> /**  */
	fullChargeCapacity?: Maybe<FilterInt64Operator> /**  */
	maxRechargeTime?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	timeOnBattery?: Maybe<FilterInt64Operator> /**  */
	timeToFullCharge?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInBattery> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBattery>> /**  */
	or?: Array<Maybe<FilterOfBattery>> /**  */
	nor?: Array<Maybe<FilterOfBattery>> /**  */
	not?: Array<Maybe<FilterOfBattery>> /**  */
}


/**  */
export type FilterBatteryStatusOperator = {
	eq?: Maybe<BatteryStatus> /**  */
	ne?: Maybe<BatteryStatus> /**  */
	in?: Array<Maybe<BatteryStatus>> /**  */
	nin?: Array<Maybe<BatteryStatus>> /**  */
}


/**  */
export type FilterChemistryOperator = {
	eq?: Maybe<Chemistry> /**  */
	ne?: Maybe<Chemistry> /**  */
	in?: Array<Maybe<Chemistry>> /**  */
	nin?: Array<Maybe<Chemistry>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInBattery = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayPortableBatteryOperator = {
	elemMatch?: Maybe<FilterOfPortableBattery> /**  */
}


/**  */
export type FilterOfPortableBattery = {
	batteryRechargeTime?: Maybe<FilterInt64Operator> /**  */
	batteryStatus?: Maybe<FilterBatteryStatusOperator> /**  */
	capacityMultiplier?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	chemistry?: Maybe<FilterChemistryOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	designCapacity?: Maybe<FilterInt64Operator> /**  */
	designVoltage?: Maybe<FilterInt64Operator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	estimatedChargeRemaining?: Maybe<FilterInt64Operator> /**  */
	estimatedRunTime?: Maybe<FilterInt64Operator> /**  */
	expectedBatteryLife?: Maybe<FilterInt64Operator> /**  */
	expectedLife?: Maybe<FilterInt64Operator> /**  */
	fullChargeCapacity?: Maybe<FilterInt64Operator> /**  */
	location?: Maybe<FilterStringOperator> /**  */
	manufactureDate?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	maxBatteryError?: Maybe<FilterInt64Operator> /**  */
	maxRechargeTime?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	smartBatteryVersion?: Maybe<FilterStringOperator> /**  */
	timeOnBattery?: Maybe<FilterInt64Operator> /**  */
	timeToFullCharge?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPortableBattery> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPortableBattery>> /**  */
	or?: Array<Maybe<FilterOfPortableBattery>> /**  */
	nor?: Array<Maybe<FilterOfPortableBattery>> /**  */
	not?: Array<Maybe<FilterOfPortableBattery>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInPortableBattery = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayLogonHistoryOperator = {
	elemMatch?: Maybe<FilterOfLogonHistory> /**  */
}


/**  */
export type FilterOfLogonHistory = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	logonHistoryMode?: Maybe<FilterLogonHistoryModeOperator> /**  */
	userCaption?: Maybe<FilterStringOperator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	userDomain?: Maybe<FilterStringOperator> /**  */
	userSid?: Maybe<FilterStringOperator> /**  */
	recordNumber?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLogonHistory> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLogonHistory>> /**  */
	or?: Array<Maybe<FilterOfLogonHistory>> /**  */
	nor?: Array<Maybe<FilterOfLogonHistory>> /**  */
	not?: Array<Maybe<FilterOfLogonHistory>> /**  */
}


/**  */
export type FilterLogonHistoryModeOperator = {
	eq?: Maybe<LogonHistoryMode> /**  */
	ne?: Maybe<LogonHistoryMode> /**  */
	in?: Array<Maybe<LogonHistoryMode>> /**  */
	nin?: Array<Maybe<LogonHistoryMode>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLogonHistory = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayDesktopOperator = {
	elemMatch?: Maybe<FilterOfDesktop> /**  */
}


/**  */
export type FilterOfDesktop = {
	borderWidth?: Maybe<FilterInt64Operator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	coolSwitch?: Maybe<FilterBooleanOperator> /**  */
	cursorBlinkRate?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dragFullWindows?: Maybe<FilterBooleanOperator> /**  */
	gridGranularity?: Maybe<FilterInt64Operator> /**  */
	iconSpacing?: Maybe<FilterInt64Operator> /**  */
	iconTitleFaceName?: Maybe<FilterStringOperator> /**  */
	iconTitleSize?: Maybe<FilterInt64Operator> /**  */
	iconTitleWrap?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	pattern?: Maybe<FilterStringOperator> /**  */
	screenSaverActive?: Maybe<FilterBooleanOperator> /**  */
	screenSaverExecutable?: Maybe<FilterStringOperator> /**  */
	screenSaverSecure?: Maybe<FilterBooleanOperator> /**  */
	screenSaverTimeout?: Maybe<FilterInt64Operator> /**  */
	settingId?: Maybe<FilterStringOperator> /**  */
	wallpaper?: Maybe<FilterStringOperator> /**  */
	wallpaperStretched?: Maybe<FilterBooleanOperator> /**  */
	wallpaperTiled?: Maybe<FilterBooleanOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInDesktop> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDesktop>> /**  */
	or?: Array<Maybe<FilterOfDesktop>> /**  */
	nor?: Array<Maybe<FilterOfDesktop>> /**  */
	not?: Array<Maybe<FilterOfDesktop>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInDesktop = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayOptionalFeatureOperator = {
	elemMatch?: Maybe<FilterOfOptionalFeature> /**  */
}


/**  */
export type FilterOfOptionalFeature = {
	name?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	installState?: Maybe<FilterInt64Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInOptionalFeature> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOptionalFeature>> /**  */
	or?: Array<Maybe<FilterOfOptionalFeature>> /**  */
	nor?: Array<Maybe<FilterOfOptionalFeature>> /**  */
	not?: Array<Maybe<FilterOfOptionalFeature>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInOptionalFeature = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayIisOperator = {
	elemMatch?: Maybe<FilterOfIis> /**  */
}


/**  */
export type FilterOfIis = {
	iisProgramGroup?: Maybe<FilterStringOperator> /**  */
	installPath?: Maybe<FilterStringOperator> /**  */
	pathWwwRoot?: Maybe<FilterStringOperator> /**  */
	productString?: Maybe<FilterStringOperator> /**  */
	setupString?: Maybe<FilterStringOperator> /**  */
	versionString?: Maybe<FilterStringOperator> /**  */
	majorVersion?: Maybe<FilterInt32Operator> /**  */
	metabaseSetMajorVersion?: Maybe<FilterInt32Operator> /**  */
	metabaseSetMinorVersion?: Maybe<FilterInt32Operator> /**  */
	minorVersion?: Maybe<FilterInt32Operator> /**  */
	iisSites?: Maybe<ArrayIisSiteOperator> /**  */
	iisApplicationPools?: Maybe<ArrayIisApplicationPoolOperator> /**  */
	iisComponents?: Maybe<ArrayIisComponentOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInIis> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIis>> /**  */
	or?: Array<Maybe<FilterOfIis>> /**  */
	nor?: Array<Maybe<FilterOfIis>> /**  */
	not?: Array<Maybe<FilterOfIis>> /**  */
}


/**  */
export type ArrayIisSiteOperator = {
	elemMatch?: Maybe<FilterOfIisSite> /**  */
}


/**  */
export type FilterOfIisSite = {
	siteId?: Maybe<FilterInt32Operator> /**  */
	adsiPath?: Maybe<FilterStringOperator> /**  */
	logFileDirectory?: Maybe<FilterStringOperator> /**  */
	appPoolId?: Maybe<FilterStringOperator> /**  */
	dontLog?: Maybe<FilterBooleanOperator> /**  */
	defaultDoc?: Maybe<FilterStringOperator> /**  */
	authFlags?: Maybe<FilterInt32Operator> /**  */
	anonymousUserName?: Maybe<FilterStringOperator> /**  */
	ntAuthenticationProviders?: Maybe<FilterStringOperator> /**  */
	serverState?: Maybe<FilterInt32Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	serverBindings?: Maybe<FilterStringOperator> /**  */
	iisApplications?: Maybe<ArrayIisApplicationOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIisSite>> /**  */
	or?: Array<Maybe<FilterOfIisSite>> /**  */
	nor?: Array<Maybe<FilterOfIisSite>> /**  */
	not?: Array<Maybe<FilterOfIisSite>> /**  */
}


/**  */
export type ArrayIisApplicationOperator = {
	elemMatch?: Maybe<FilterOfIisApplication> /**  */
}


/**  */
export type FilterOfIisApplication = {
	applicationPool?: Maybe<FilterStringOperator> /**  */
	defaultDoc?: Maybe<FilterStringOperator> /**  */
	adsiPath?: Maybe<FilterStringOperator> /**  */
	authFlags?: Maybe<FilterInt32Operator> /**  */
	anonymousUserName?: Maybe<FilterStringOperator> /**  */
	ntAuthenticationProviders?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	appFriendlyName?: Maybe<FilterStringOperator> /**  */
	appIsolated?: Maybe<FilterInt32Operator> /**  */
	appRoot?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIisApplication>> /**  */
	or?: Array<Maybe<FilterOfIisApplication>> /**  */
	nor?: Array<Maybe<FilterOfIisApplication>> /**  */
	not?: Array<Maybe<FilterOfIisApplication>> /**  */
}


/**  */
export type ArrayIisApplicationPoolOperator = {
	elemMatch?: Maybe<FilterOfIisApplicationPool> /**  */
}


/**  */
export type FilterOfIisApplicationPool = {
	managedRuntimeVersion?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	adsiPath?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIisApplicationPool>> /**  */
	or?: Array<Maybe<FilterOfIisApplicationPool>> /**  */
	nor?: Array<Maybe<FilterOfIisApplicationPool>> /**  */
	not?: Array<Maybe<FilterOfIisApplicationPool>> /**  */
}


/**  */
export type ArrayIisComponentOperator = {
	elemMatch?: Maybe<FilterOfIisComponent> /**  */
}


/**  */
export type FilterOfIisComponent = {
	w3Svc?: Maybe<FilterInt32Operator> /**  */
	commonHttpFeaturesIis?: Maybe<FilterOfCommonHttpFeaturesIis> /**  */
	applicationDevelopmentFeaturesIis?: Maybe<FilterOfApplicationDevelopmentFeaturesIis> /**  */
	healthandDiagnosticsIis?: Maybe<FilterOfHealthandDiagnosticsIis> /**  */
	securityIis?: Maybe<FilterOfSecurityIis> /**  */
	performanceFeaturesIis?: Maybe<FilterOfPerformanceFeaturesIis> /**  */
	managementToolsIis?: Maybe<FilterOfManagementToolsIis> /**  */
	managementServiceIis?: Maybe<FilterOfManagementServiceIis> /**  */
	fTpPublishingServiceIis?: Maybe<FilterOfFtpPublishingServiceIis> /**  */
	windowsProcessActivationService?: Maybe<FilterOfWindowsProcessActivationService> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIisComponent>> /**  */
	or?: Array<Maybe<FilterOfIisComponent>> /**  */
	nor?: Array<Maybe<FilterOfIisComponent>> /**  */
	not?: Array<Maybe<FilterOfIisComponent>> /**  */
}


/**  */
export type FilterOfCommonHttpFeaturesIis = {
	staticContent?: Maybe<FilterInt32Operator> /**  */
	defaultDocument?: Maybe<FilterInt32Operator> /**  */
	directoryBrowse?: Maybe<FilterInt32Operator> /**  */
	httpErrors?: Maybe<FilterInt32Operator> /**  */
	httpRedirect?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfCommonHttpFeaturesIis>> /**  */
	or?: Array<Maybe<FilterOfCommonHttpFeaturesIis>> /**  */
	nor?: Array<Maybe<FilterOfCommonHttpFeaturesIis>> /**  */
	not?: Array<Maybe<FilterOfCommonHttpFeaturesIis>> /**  */
}


/**  */
export type FilterOfApplicationDevelopmentFeaturesIis = {
	aspNet?: Maybe<FilterInt32Operator> /**  */
	netFxExtensibility?: Maybe<FilterInt32Operator> /**  */
	asp?: Maybe<FilterInt32Operator> /**  */
	cgi?: Maybe<FilterInt32Operator> /**  */
	isapiExtensions?: Maybe<FilterInt32Operator> /**  */
	isapiFilter?: Maybe<FilterInt32Operator> /**  */
	serverSideInclude?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfApplicationDevelopmentFeaturesIis>> /**  */
	or?: Array<Maybe<FilterOfApplicationDevelopmentFeaturesIis>> /**  */
	nor?: Array<Maybe<FilterOfApplicationDevelopmentFeaturesIis>> /**  */
	not?: Array<Maybe<FilterOfApplicationDevelopmentFeaturesIis>> /**  */
}


/**  */
export type FilterOfHealthandDiagnosticsIis = {
	httpLogging?: Maybe<FilterInt32Operator> /**  */
	loggingLibraries?: Maybe<FilterInt32Operator> /**  */
	requestMonitor?: Maybe<FilterInt32Operator> /**  */
	httpTracing?: Maybe<FilterInt32Operator> /**  */
	customLogging?: Maybe<FilterInt32Operator> /**  */
	odbcLogging?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfHealthandDiagnosticsIis>> /**  */
	or?: Array<Maybe<FilterOfHealthandDiagnosticsIis>> /**  */
	nor?: Array<Maybe<FilterOfHealthandDiagnosticsIis>> /**  */
	not?: Array<Maybe<FilterOfHealthandDiagnosticsIis>> /**  */
}


/**  */
export type FilterOfSecurityIis = {
	basicAuthentication?: Maybe<FilterInt32Operator> /**  */
	windowsAuthentication?: Maybe<FilterInt32Operator> /**  */
	digestAuthentication?: Maybe<FilterInt32Operator> /**  */
	clientCertificateMappingAuthentication?: Maybe<FilterInt32Operator> /**  */
	iisClientCertificateMappingAuthentication?: Maybe<FilterInt32Operator> /**  */
	authorization?: Maybe<FilterInt32Operator> /**  */
	requestFiltering?: Maybe<FilterInt32Operator> /**  */
	ipSecurity?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfSecurityIis>> /**  */
	or?: Array<Maybe<FilterOfSecurityIis>> /**  */
	nor?: Array<Maybe<FilterOfSecurityIis>> /**  */
	not?: Array<Maybe<FilterOfSecurityIis>> /**  */
}


/**  */
export type FilterOfPerformanceFeaturesIis = {
	httpCompressionStatic?: Maybe<FilterInt32Operator> /**  */
	httpCompressionDynamic?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfPerformanceFeaturesIis>> /**  */
	or?: Array<Maybe<FilterOfPerformanceFeaturesIis>> /**  */
	nor?: Array<Maybe<FilterOfPerformanceFeaturesIis>> /**  */
	not?: Array<Maybe<FilterOfPerformanceFeaturesIis>> /**  */
}


/**  */
export type FilterOfManagementToolsIis = {
	managementConsole?: Maybe<FilterInt32Operator> /**  */
	managementScriptingTools?: Maybe<FilterInt32Operator> /**  */
	adminService?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfManagementToolsIis>> /**  */
	or?: Array<Maybe<FilterOfManagementToolsIis>> /**  */
	nor?: Array<Maybe<FilterOfManagementToolsIis>> /**  */
	not?: Array<Maybe<FilterOfManagementToolsIis>> /**  */
}


/**  */
export type FilterOfManagementServiceIis = {
	metabase?: Maybe<FilterInt32Operator> /**  */
	wmiCompatibility?: Maybe<FilterInt32Operator> /**  */
	legacyScripts?: Maybe<FilterInt32Operator> /**  */
	legacySnapin?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfManagementServiceIis>> /**  */
	or?: Array<Maybe<FilterOfManagementServiceIis>> /**  */
	nor?: Array<Maybe<FilterOfManagementServiceIis>> /**  */
	not?: Array<Maybe<FilterOfManagementServiceIis>> /**  */
}


/**  */
export type FilterOfFtpPublishingServiceIis = {
	ftpServer?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfFtpPublishingServiceIis>> /**  */
	or?: Array<Maybe<FilterOfFtpPublishingServiceIis>> /**  */
	nor?: Array<Maybe<FilterOfFtpPublishingServiceIis>> /**  */
	not?: Array<Maybe<FilterOfFtpPublishingServiceIis>> /**  */
}


/**  */
export type FilterOfWindowsProcessActivationService = {
	processModel?: Maybe<FilterInt32Operator> /**  */
	netFxEnvironment?: Maybe<FilterInt32Operator> /**  */
	wasConfigurationApi?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfWindowsProcessActivationService>> /**  */
	or?: Array<Maybe<FilterOfWindowsProcessActivationService>> /**  */
	nor?: Array<Maybe<FilterOfWindowsProcessActivationService>> /**  */
	not?: Array<Maybe<FilterOfWindowsProcessActivationService>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInIis = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayLdapPropertyOperator = {
	elemMatch?: Maybe<FilterOfLdapProperty> /**  */
}


/**  */
export type FilterOfLdapProperty = {
	objectClass?: Maybe<FilterStringOperator> /**  */
	cN?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	objectGuid?: Maybe<FilterGuidOperator> /**  */
	userAccountControl?: Maybe<FilterInt32Operator> /**  */
	badPwdCount?: Maybe<FilterInt32Operator> /**  */
	codePage?: Maybe<FilterInt32Operator> /**  */
	countryCode?: Maybe<FilterInt32Operator> /**  */
	badPasswordTime?: Maybe<FilterDateTimeOperator> /**  */
	lastLogoff?: Maybe<FilterDateTimeOperator> /**  */
	lastLogon?: Maybe<FilterDateTimeOperator> /**  */
	localPolicyFlags?: Maybe<FilterInt32Operator> /**  */
	pwdLastSet?: Maybe<FilterDateTimeOperator> /**  */
	primaryGroupId?: Maybe<FilterInt32Operator> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	accountExpires?: Maybe<FilterDateTimeOperator> /**  */
	logonCount?: Maybe<FilterInt32Operator> /**  */
	sAmAccountName?: Maybe<FilterStringOperator> /**  */
	operatingSystem?: Maybe<FilterStringOperator> /**  */
	operatingSystemVersion?: Maybe<FilterStringOperator> /**  */
	dNsHostName?: Maybe<FilterStringOperator> /**  */
	objectCategory?: Maybe<FilterStringOperator> /**  */
	isCriticalSystemObject?: Maybe<FilterBooleanOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	lastLogonTimestamp?: Maybe<FilterDateTimeOperator> /**  */
	nTSecurityDescriptor?: Maybe<FilterDateTimeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLdapProperty> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapProperty>> /**  */
	or?: Array<Maybe<FilterOfLdapProperty>> /**  */
	nor?: Array<Maybe<FilterOfLdapProperty>> /**  */
	not?: Array<Maybe<FilterOfLdapProperty>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLdapProperty = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayLogicalDiskHistoryOperator = {
	elemMatch?: Maybe<FilterOfLogicalDiskHistory> /**  */
}


/**  */
export type FilterOfLogicalDiskHistory = {
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	diskDriveCaption?: Maybe<FilterStringOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	driveType?: Maybe<FilterDriveTypeOperator> /**  */
	fileSystem?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	mediaType?: Maybe<FilterInt64Operator> /**  */
	freeSpace?: Maybe<FilterDoubleOperator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	volumeName?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	useSpace?: Maybe<FilterDoubleOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLogicalDiskHistory> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLogicalDiskHistory>> /**  */
	or?: Array<Maybe<FilterOfLogicalDiskHistory>> /**  */
	nor?: Array<Maybe<FilterOfLogicalDiskHistory>> /**  */
	not?: Array<Maybe<FilterOfLogicalDiskHistory>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLogicalDiskHistory = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayInternetExplorerOperator = {
	elemMatch?: Maybe<FilterOfInternetExplorer> /**  */
}


/**  */
export type FilterOfInternetExplorer = {
	proxyServer?: Maybe<FilterStringOperator> /**  */
	autoConfigUrl?: Maybe<FilterStringOperator> /**  */
	autoDetect?: Maybe<FilterBooleanOperator> /**  */
	proxyEnable?: Maybe<FilterBooleanOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInInternetExplorer> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInternetExplorer>> /**  */
	or?: Array<Maybe<FilterOfInternetExplorer>> /**  */
	nor?: Array<Maybe<FilterOfInternetExplorer>> /**  */
	not?: Array<Maybe<FilterOfInternetExplorer>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInInternetExplorer = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArrayEncryptableVolumeOperator = {
	elemMatch?: Maybe<FilterOfEncryptableVolume> /**  */
}


/**  */
export type FilterOfEncryptableVolume = {
	conversionStatus?: Maybe<FilterConversionStatusOperator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	driveLetter?: Maybe<FilterStringOperator> /**  */
	encryptionMethod?: Maybe<FilterEncryptionMethodOperator> /**  */
	isVolumeInitializedForProtection?: Maybe<FilterBooleanOperator> /**  */
	persistentVolumeId?: Maybe<FilterStringOperator> /**  */
	protectionStatus?: Maybe<FilterProtectionStatusOperator> /**  */
	volumeType?: Maybe<FilterInt32Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInEncryptableVolume> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEncryptableVolume>> /**  */
	or?: Array<Maybe<FilterOfEncryptableVolume>> /**  */
	nor?: Array<Maybe<FilterOfEncryptableVolume>> /**  */
	not?: Array<Maybe<FilterOfEncryptableVolume>> /**  */
}


/**  */
export type FilterConversionStatusOperator = {
	eq?: Maybe<ConversionStatus> /**  */
	ne?: Maybe<ConversionStatus> /**  */
	in?: Array<Maybe<ConversionStatus>> /**  */
	nin?: Array<Maybe<ConversionStatus>> /**  */
}


/**  */
export type FilterEncryptionMethodOperator = {
	eq?: Maybe<EncryptionMethod> /**  */
	ne?: Maybe<EncryptionMethod> /**  */
	in?: Array<Maybe<EncryptionMethod>> /**  */
	nin?: Array<Maybe<EncryptionMethod>> /**  */
}


/**  */
export type FilterProtectionStatusOperator = {
	eq?: Maybe<ProtectionStatus> /**  */
	ne?: Maybe<ProtectionStatus> /**  */
	in?: Array<Maybe<ProtectionStatus>> /**  */
	nin?: Array<Maybe<ProtectionStatus>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInEncryptableVolume = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type ArraySnmpSummaryOperator = {
	elemMatch?: Maybe<FilterOfSnmpSummary> /**  */
}


/**  */
export type FilterOfSnmpSummary = {
	sysDescription?: Maybe<FilterStringOperator> /**  */
	sysOid?: Maybe<FilterStringOperator> /**  */
	sysUpTime?: Maybe<FilterStringOperator> /**  */
	sysContact?: Maybe<FilterStringOperator> /**  */
	sysName?: Maybe<FilterStringOperator> /**  */
	sysLocation?: Maybe<FilterStringOperator> /**  */
	sysService?: Maybe<FilterStringOperator> /**  */
	snmpType?: Maybe<FilterSnmpTypeOperator> /**  */
	vendorId?: Maybe<FilterInt32Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSnmpSummary> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpSummary>> /**  */
	or?: Array<Maybe<FilterOfSnmpSummary>> /**  */
	nor?: Array<Maybe<FilterOfSnmpSummary>> /**  */
	not?: Array<Maybe<FilterOfSnmpSummary>> /**  */
}


/**  */
export type FilterSnmpTypeOperator = {
	eq?: Maybe<SnmpType> /**  */
	ne?: Maybe<SnmpType> /**  */
	in?: Array<Maybe<SnmpType>> /**  */
	nin?: Array<Maybe<SnmpType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSnmpSummary = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterDenormalizeOfOperatingSystemByMainOperatingSystemInAsset = {
	caption?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfAssetDelivery = {
	deliveryId?: Maybe<FilterGuidOperator> /**  */
	deliveryDate?: Maybe<FilterDateTimeOperator> /**  */
	orderDate?: Maybe<FilterDateTimeOperator> /**  */
	deliveryOrder?: Maybe<FilterStringOperator> /**  */
	receivedById?: Maybe<FilterGuidOperator> /**  */
	supplierReferenceNumber?: Maybe<FilterStringOperator> /**  */
	internalReferenceNumber?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetDelivery>> /**  */
	or?: Array<Maybe<FilterOfAssetDelivery>> /**  */
	nor?: Array<Maybe<FilterOfAssetDelivery>> /**  */
	not?: Array<Maybe<FilterOfAssetDelivery>> /**  */
}


/**  */
export type FilterOfClarilogLocalAgent = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	lastStart?: Maybe<FilterDateTimeOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
	firstCheck?: Maybe<FilterDateTimeOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	osVersion?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	forceAudit?: Maybe<FilterBooleanOperator> /**  */
	mustBeAudited?: Maybe<FilterBooleanOperator> /**  */
	clientApplicationtName?: Maybe<FilterStringOperator> /**  */
	updater?: Maybe<FilterOfUpdater> /**  */
	clarilogAgentSettings?: Maybe<FilterOfClarilogAgentSettings> /**  */
	campaignIds?: Maybe<ArrayUuidTypeOperator> /**  */
	packageToDeploy?: Maybe<ArrayPackageOperator> /**  */
	and?: Array<Maybe<FilterOfClarilogLocalAgent>> /**  */
	or?: Array<Maybe<FilterOfClarilogLocalAgent>> /**  */
	nor?: Array<Maybe<FilterOfClarilogLocalAgent>> /**  */
	not?: Array<Maybe<FilterOfClarilogLocalAgent>> /**  */
}


/**  */
export type FilterOfUpdater = {
	lastDownload?: Maybe<FilterDateTimeOperator> /**  */
	lastUpdate?: Maybe<FilterDateTimeOperator> /**  */
	lastInstall?: Maybe<FilterDateTimeOperator> /**  */
	updateState?: Maybe<FilterUpdaterStateOperator> /**  */
	lastError?: Maybe<FilterStringOperator> /**  */
	countAttempt?: Maybe<FilterInt32Operator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUpdater>> /**  */
	or?: Array<Maybe<FilterOfUpdater>> /**  */
	nor?: Array<Maybe<FilterOfUpdater>> /**  */
	not?: Array<Maybe<FilterOfUpdater>> /**  */
}


/**  */
export type FilterUpdaterStateOperator = {
	eq?: Maybe<UpdaterState> /**  */
	ne?: Maybe<UpdaterState> /**  */
	in?: Array<Maybe<UpdaterState>> /**  */
	nin?: Array<Maybe<UpdaterState>> /**  */
}


/**  */
export type FilterOfClarilogAgentSettings = {
	lastVersion?: Maybe<FilterStringOperator> /**  */
	pendingDelay?: Maybe<FilterInt32Operator> /**  */
	pingDelay?: Maybe<FilterInt32Operator> /**  */
	stopService?: Maybe<FilterBooleanOperator> /**  */
	restartService?: Maybe<FilterBooleanOperator> /**  */
	disableService?: Maybe<FilterBooleanOperator> /**  */
	forceToken?: Maybe<FilterStringOperator> /**  */
	canBeUpdate?: Maybe<FilterBooleanOperator> /**  */
	blockIfNotLastVersion?: Maybe<FilterBooleanOperator> /**  */
	disabledApiQuery?: Maybe<FilterBooleanOperator> /**  */
	disabledApiMutation?: Maybe<FilterBooleanOperator> /**  */
	apiServer?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfClarilogAgentSettings>> /**  */
	or?: Array<Maybe<FilterOfClarilogAgentSettings>> /**  */
	nor?: Array<Maybe<FilterOfClarilogAgentSettings>> /**  */
	not?: Array<Maybe<FilterOfClarilogAgentSettings>> /**  */
}


/**  */
export type ArrayPackageOperator = {
	elemMatch?: Maybe<FilterOfPackage> /**  */
}


/**  */
export type FilterOfPackage = {
	name?: Maybe<FilterStringOperator> /**  */
	packageName?: Maybe<FilterStringOperator> /**  */
	packageVersion?: Maybe<FilterStringOperator> /**  */
	packagePathSource?: Maybe<FilterStringOperator> /**  */
	campaignIds?: Maybe<ArrayUuidTypeOperator> /**  */
	campaignHistoryId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPackage>> /**  */
	or?: Array<Maybe<FilterOfPackage>> /**  */
	nor?: Array<Maybe<FilterOfPackage>> /**  */
	not?: Array<Maybe<FilterOfPackage>> /**  */
}


/**  */
export type QueryContextOfAsset = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAsset>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfWorkflowAction = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowAction> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	httpOutputConfig?: Maybe<FilterOfHttpOutput> /**  */
	roleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	modelType?: Maybe<FilterStringOperator> /**  */
	user?: Maybe<FilterOfUser> /**  */
	userIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	userPrivilegeId?: Maybe<FilterGuidOperator> /**  */
	businessTimeId?: Maybe<FilterGuidOperator> /**  */
	userRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	useProprotype?: Maybe<FilterBooleanOperator> /**  */
	sendInvitation?: Maybe<FilterBooleanOperator> /**  */
	emailPrototypeId?: Maybe<FilterGuidOperator> /**  */
	emailPrototypeLink?: Maybe<FilterDenormalizeOfEmailPrototypeByEmailPrototypeLinkInWorkflowAction> /**  */
	emailPrototype?: Maybe<FilterOfEmailPrototype> /**  */
	managedKey?: Maybe<FilterStringOperator> /**  */
	recipient?: Maybe<FilterStringOperator> /**  */
	workflowPropertyValue?: Maybe<FilterOfWorkflowPropertyValue> /**  */
	workflowPropertyState?: Maybe<FilterOfWorkflowPropertyState> /**  */
	propertyName?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowAction>> /**  */
	or?: Array<Maybe<FilterOfWorkflowAction>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowAction>> /**  */
	not?: Array<Maybe<FilterOfWorkflowAction>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowAction = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterWorkflowTriggerOperator = {
	eq?: Maybe<WorkflowTrigger> /**  */
	ne?: Maybe<WorkflowTrigger> /**  */
	in?: Array<Maybe<WorkflowTrigger>> /**  */
	nin?: Array<Maybe<WorkflowTrigger>> /**  */
}


/**  */
export type FilterOfOptionSave = {
	insert?: Maybe<FilterBooleanOperator> /**  */
	update?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfOptionSave>> /**  */
	or?: Array<Maybe<FilterOfOptionSave>> /**  */
	nor?: Array<Maybe<FilterOfOptionSave>> /**  */
	not?: Array<Maybe<FilterOfOptionSave>> /**  */
}


/**  */
export type FilterOfOptionDelete = {
	delete?: Maybe<FilterBooleanOperator> /**  */
	trash?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfOptionDelete>> /**  */
	or?: Array<Maybe<FilterOfOptionDelete>> /**  */
	nor?: Array<Maybe<FilterOfOptionDelete>> /**  */
	not?: Array<Maybe<FilterOfOptionDelete>> /**  */
}


/**  */
export type FilterOfHttpOutput = {
	method?: Maybe<FilterApiHttpMethodOperator> /**  */
	body?: Maybe<FilterStringOperator> /**  */
	headers?: Maybe<ArrayHttpRequestParamsOperator> /**  */
	url?: Maybe<FilterStringOperator> /**  */
	queryParams?: Maybe<ArrayHttpRequestParamsOperator> /**  */
	attachmentSetting?: Maybe<FilterHttpOutputSendAttachmentOperator> /**  */
	attachmentArrayProperty?: Maybe<FilterStringOperator> /**  */
	attachmentFileName?: Maybe<FilterStringOperator> /**  */
	attachmentSize?: Maybe<FilterStringOperator> /**  */
	attachmentType?: Maybe<FilterStringOperator> /**  */
	attachmentFileValue?: Maybe<FilterStringOperator> /**  */
	attachmentLinkedFileIds?: Maybe<FilterStringOperator> /**  */
	linkedObjectIdPath?: Maybe<FilterStringOperator> /**  */
	bodyParams?: Maybe<ArrayHttpRequestParamsOperator> /**  */
	and?: Array<Maybe<FilterOfHttpOutput>> /**  */
	or?: Array<Maybe<FilterOfHttpOutput>> /**  */
	nor?: Array<Maybe<FilterOfHttpOutput>> /**  */
	not?: Array<Maybe<FilterOfHttpOutput>> /**  */
}


/**  */
export type FilterApiHttpMethodOperator = {
	eq?: Maybe<ApiHttpMethod> /**  */
	ne?: Maybe<ApiHttpMethod> /**  */
	in?: Array<Maybe<ApiHttpMethod>> /**  */
	nin?: Array<Maybe<ApiHttpMethod>> /**  */
}


/**  */
export type ArrayHttpRequestParamsOperator = {
	elemMatch?: Maybe<FilterOfHttpRequestParams> /**  */
}


/**  */
export type FilterOfHttpRequestParams = {
	key?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfHttpRequestParams>> /**  */
	or?: Array<Maybe<FilterOfHttpRequestParams>> /**  */
	nor?: Array<Maybe<FilterOfHttpRequestParams>> /**  */
	not?: Array<Maybe<FilterOfHttpRequestParams>> /**  */
}


/**  */
export type FilterHttpOutputSendAttachmentOperator = {
	eq?: Maybe<HttpOutputSendAttachment> /**  */
	ne?: Maybe<HttpOutputSendAttachment> /**  */
	in?: Array<Maybe<HttpOutputSendAttachment>> /**  */
	nin?: Array<Maybe<HttpOutputSendAttachment>> /**  */
}


/**  */
export type FilterDenormalizeOfEmailPrototypeByEmailPrototypeLinkInWorkflowAction = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfEmailPrototype = {
	subject?: Maybe<FilterOfTranslatedField> /**  */
	copyFrom?: Maybe<FilterGuidOperator> /**  */
	body?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	connectorId?: Maybe<FilterGuidOperator> /**  */
	emailConnector?: Maybe<FilterDenormalizeOfEmailConnectorByEmailConnectorInEmailPrototype> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	from?: Maybe<FilterStringOperator> /**  */
	to?: Maybe<ArrayStringTypeOperator> /**  */
	cc?: Maybe<ArrayStringTypeOperator> /**  */
	cci?: Maybe<ArrayStringTypeOperator> /**  */
	replyTo?: Maybe<ArrayStringTypeOperator> /**  */
	attachedResult?: Maybe<FilterBooleanOperator> /**  */
	alarmIds?: Maybe<ArrayUuidTypeOperator> /**  */
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	triggerType?: Maybe<FilterEmailPrototypeTriggerTypeEnumOperator> /**  */
	time?: Maybe<FilterDateTimeOperator> /**  */
	ticketCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	disableFilterCategories?: Maybe<FilterBooleanOperator> /**  */
	sendInvitation?: Maybe<FilterBooleanOperator> /**  */
	eventDate?: Maybe<FilterDateTimeOperator> /**  */
	attachFiles?: Maybe<FilterBooleanOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEmailPrototype>> /**  */
	or?: Array<Maybe<FilterOfEmailPrototype>> /**  */
	nor?: Array<Maybe<FilterOfEmailPrototype>> /**  */
	not?: Array<Maybe<FilterOfEmailPrototype>> /**  */
}


/**  */
export type FilterDenormalizeOfEmailConnectorByEmailConnectorInEmailPrototype = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterEmailPrototypeTriggerTypeEnumOperator = {
	eq?: Maybe<EmailPrototypeTriggerTypeEnum> /**  */
	ne?: Maybe<EmailPrototypeTriggerTypeEnum> /**  */
	in?: Array<Maybe<EmailPrototypeTriggerTypeEnum>> /**  */
	nin?: Array<Maybe<EmailPrototypeTriggerTypeEnum>> /**  */
}


/**  */
export type FilterOfWorkflowPropertyValue = {
	editType?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	changeValue?: Maybe<FilterBooleanOperator> /**  */
	replaceOnlyEmpty?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowPropertyValue>> /**  */
	or?: Array<Maybe<FilterOfWorkflowPropertyValue>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowPropertyValue>> /**  */
	not?: Array<Maybe<FilterOfWorkflowPropertyValue>> /**  */
}


/**  */
export type FilterOfWorkflowPropertyState = {
	changeState?: Maybe<FilterBooleanOperator> /**  */
	isReadOnly?: Maybe<FilterBooleanOperator> /**  */
	isRequired?: Maybe<FilterBooleanOperator> /**  */
	isVisible?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowPropertyState>> /**  */
	or?: Array<Maybe<FilterOfWorkflowPropertyState>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowPropertyState>> /**  */
	not?: Array<Maybe<FilterOfWorkflowPropertyState>> /**  */
}


/**  */
export type QueryContextOfWorkflowAction = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowAction>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowAction */
export type QuerySortTypeWorkflowAction = {
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	httpOutputConfig?: Array<Maybe<QuerySortTypeHttpOutput>> /**  */
	roleIds?: Maybe<Sort> /** Représente le type de trie. */
	modelType?: Maybe<Sort> /** Représente le type de trie. */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	useProprotype?: Maybe<Sort> /** Représente le type de trie. */
	sendInvitation?: Maybe<Sort> /** Représente le type de trie. */
	emailPrototypeLink?: Array<Maybe<QuerySortTypeEmailPrototype>> /**  */
	emailPrototype?: Array<Maybe<QuerySortTypeEmailPrototype>> /**  */
	managedKey?: Maybe<Sort> /** Représente le type de trie. */
	recipient?: Maybe<Sort> /** Représente le type de trie. */
	workflowPropertyValue?: Array<Maybe<QuerySortTypeWorkflowPropertyValue>> /**  */
	workflowPropertyState?: Array<Maybe<QuerySortTypeWorkflowPropertyState>> /**  */
	propertyName?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Workflow */
export type QuerySortTypeWorkflow = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	delay?: Maybe<Sort> /** Représente le type de trie. */
	delayConfig?: Array<Maybe<QuerySortTypeWorkflowDelay>> /**  */
	intervalConfig?: Array<Maybe<QuerySortTypeWorkflowDelay>> /**  */
	enableDelay?: Maybe<Sort> /** Représente le type de trie. */
	enableInterval?: Maybe<Sort> /** Représente le type de trie. */
	priority?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdateBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastUpdated?: Maybe<Sort> /** Représente le type de trie. */
	managedChooseForApply?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkflowDelay */
export type QuerySortTypeWorkflowDelay = {
	key?: Maybe<Sort> /** Représente le type de trie. */
	displayName?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	property?: Maybe<Sort> /** Représente le type de trie. */
	delay?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OptionSave */
export type QuerySortTypeOptionSave = {
	insert?: Maybe<Sort> /** Représente le type de trie. */
	update?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OptionDelete */
export type QuerySortTypeOptionDelete = {
	delete?: Maybe<Sort> /** Représente le type de trie. */
	trash?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité HttpOutput */
export type QuerySortTypeHttpOutput = {
	method?: Maybe<Sort> /** Représente le type de trie. */
	body?: Maybe<Sort> /** Représente le type de trie. */
	headers?: Maybe<Sort> /** Représente le type de trie. */
	url?: Maybe<Sort> /** Représente le type de trie. */
	queryParams?: Maybe<Sort> /** Représente le type de trie. */
	attachmentSetting?: Maybe<Sort> /** Représente le type de trie. */
	attachmentArrayProperty?: Maybe<Sort> /** Représente le type de trie. */
	attachmentFileName?: Maybe<Sort> /** Représente le type de trie. */
	attachmentSize?: Maybe<Sort> /** Représente le type de trie. */
	attachmentType?: Maybe<Sort> /** Représente le type de trie. */
	attachmentFileValue?: Maybe<Sort> /** Représente le type de trie. */
	attachmentLinkedFileIds?: Maybe<Sort> /** Représente le type de trie. */
	linkedObjectIdPath?: Maybe<Sort> /** Représente le type de trie. */
	bodyParams?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EmailPrototype */
export type QuerySortTypeEmailPrototype = {
	subject?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	body?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	emailConnector?: Array<Maybe<QuerySortTypeEmailConnector>> /**  */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	from?: Maybe<Sort> /** Représente le type de trie. */
	attachedResult?: Maybe<Sort> /** Représente le type de trie. */
	alarmIds?: Maybe<Sort> /** Représente le type de trie. */
	queryBuilderIds?: Maybe<Sort> /** Représente le type de trie. */
	triggerType?: Maybe<Sort> /** Représente le type de trie. */
	time?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	disableFilterCategories?: Maybe<Sort> /** Représente le type de trie. */
	sendInvitation?: Maybe<Sort> /** Représente le type de trie. */
	eventDate?: Maybe<Sort> /** Représente le type de trie. */
	attachFiles?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EmailConnector */
export type QuerySortTypeEmailConnector = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	type?: Maybe<Sort> /** Représente le type de trie. */
	server?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	ssl?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	account?: Array<Maybe<QuerySortTypeEmailCredential>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	mailSlurpInbox?: Array<Maybe<QuerySortTypeMailSlurpInbox>> /**  */
	allowUnknowFrom?: Maybe<Sort> /** Représente le type de trie. */
	allowCreateTicketByEmail?: Maybe<Sort> /** Représente le type de trie. */
	allowCommunicationByMail?: Maybe<Sort> /** Représente le type de trie. */
	allowAddInterventionByMail?: Maybe<Sort> /** Représente le type de trie. */
	interventionByMailTag?: Maybe<Sort> /** Représente le type de trie. */
	onCreateTicketAddAllRecipients?: Maybe<Sort> /** Représente le type de trie. */
	onCommunicationTicketAddAllRecipients?: Maybe<Sort> /** Représente le type de trie. */
	onCommunicationTicketAddFrom?: Maybe<Sort> /** Représente le type de trie. */
	statusIds?: Maybe<Sort> /** Représente le type de trie. */
	inboxName?: Maybe<Sort> /** Représente le type de trie. */
	send?: Maybe<Sort> /** Représente le type de trie. */
	receive?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	countMailSend?: Maybe<Sort> /** Représente le type de trie. */
	countMailReceive?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	environnement?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	accessToken?: Maybe<Sort> /** Représente le type de trie. */
	expireAccesToken?: Maybe<Sort> /** Représente le type de trie. */
	default?: Maybe<Sort> /** Représente le type de trie. */
	disabledSend?: Array<Maybe<QuerySortTypeDisabledReason>> /**  */
	disabledReceive?: Array<Maybe<QuerySortTypeDisabledReason>> /**  */
	allowEmbeddedImage?: Maybe<Sort> /** Représente le type de trie. */
	smtpEncodingConstraint?: Maybe<Sort> /** Représente le type de trie. */
	authAzurePassword?: Maybe<Sort> /** Représente le type de trie. */
	applicationAzureAd?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EmailCredential */
export type QuerySortTypeEmailCredential = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité MailSlurpInbox */
export type QuerySortTypeMailSlurpInbox = {
	email?: Maybe<Sort> /** Représente le type de trie. */
	endpoint?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	useFiltering?: Maybe<Sort> /** Représente le type de trie. */
	useEmailChecker?: Maybe<Sort> /** Représente le type de trie. */
	emailCheckerLevel?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DisabledReason */
export type QuerySortTypeDisabledReason = {
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	reason?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkflowPropertyValue */
export type QuerySortTypeWorkflowPropertyValue = {
	editType?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	changeValue?: Maybe<Sort> /** Représente le type de trie. */
	replaceOnlyEmpty?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkflowPropertyState */
export type QuerySortTypeWorkflowPropertyState = {
	changeState?: Maybe<Sort> /** Représente le type de trie. */
	isReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	isRequired?: Maybe<Sort> /** Représente le type de trie. */
	isVisible?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfQueryBuilder = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	columns?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	displayRequestToJob?: Maybe<FilterBooleanOperator> /**  */
	typeTranslate?: Maybe<FilterStringOperator> /**  */
	emailPrototypeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	sftpConnectorIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfQueryBuilder>> /**  */
	or?: Array<Maybe<FilterOfQueryBuilder>> /**  */
	nor?: Array<Maybe<FilterOfQueryBuilder>> /**  */
	not?: Array<Maybe<FilterOfQueryBuilder>> /**  */
}


/**  */
export type QueryContextOfQueryBuilder = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeQueryBuilder>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité QueryBuilder */
export type QuerySortTypeQueryBuilder = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	columns?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	displayRequestToJob?: Maybe<Sort> /** Représente le type de trie. */
	typeTranslate?: Maybe<Sort> /** Représente le type de trie. */
	emailPrototypeIds?: Maybe<Sort> /** Représente le type de trie. */
	sftpConnectorIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowMail = {
	useProprotype?: Maybe<FilterBooleanOperator> /**  */
	sendInvitation?: Maybe<FilterBooleanOperator> /**  */
	emailPrototypeId?: Maybe<FilterGuidOperator> /**  */
	emailPrototypeLink?: Maybe<FilterDenormalizeOfEmailPrototypeByEmailPrototypeLinkInWorkflowMail> /**  */
	emailPrototype?: Maybe<FilterOfEmailPrototype> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowMail> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowMail>> /**  */
	or?: Array<Maybe<FilterOfWorkflowMail>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowMail>> /**  */
	not?: Array<Maybe<FilterOfWorkflowMail>> /**  */
}


/**  */
export type FilterDenormalizeOfEmailPrototypeByEmailPrototypeLinkInWorkflowMail = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowMail = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowMail = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowMail>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowMail */
export type QuerySortTypeWorkflowMail = {
	useProprotype?: Maybe<Sort> /** Représente le type de trie. */
	sendInvitation?: Maybe<Sort> /** Représente le type de trie. */
	emailPrototypeLink?: Array<Maybe<QuerySortTypeEmailPrototype>> /**  */
	emailPrototype?: Array<Maybe<QuerySortTypeEmailPrototype>> /**  */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfScanRegistry = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanRegistry>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanRegistry */
export type QuerySortTypeScanRegistry = {
	scanRegistryKey?: Array<Maybe<QuerySortTypeScanRegistryKey>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ScanRegistryKey */
export type QuerySortTypeScanRegistryKey = {
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	hKey?: Maybe<Sort> /** Représente le type de trie. */
	allValue?: Maybe<Sort> /** Représente le type de trie. */
	keyPath?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftware = {
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	internalName?: Maybe<FilterStringOperator> /**  */
	internalCode?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	softwareAllowedType?: Maybe<FilterSoftwareAllowedTypeOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	softwareCategory?: Maybe<FilterSoftwareCategoryOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	softwareGroupId?: Maybe<FilterGuidOperator> /**  */
	softwareGroup?: Maybe<FilterDenormalizeOfSoftwareGroupBySoftwareGroupInSoftware> /**  */
	softwarePackageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	installCount?: Maybe<FilterInt32Operator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	auditType?: Maybe<FilterAuditTypeOperator> /**  */
	shortcutName?: Maybe<FilterStringOperator> /**  */
	softwareAttributes?: Maybe<ArraySoftwareAttributeOperator> /**  */
	ldapGroupDn?: Maybe<ArrayStringTypeOperator> /**  */
	softwareConnectionModeId?: Maybe<FilterGuidOperator> /**  */
	softwareConnectionMode?: Maybe<FilterDenormalizeOfSoftwareConnectionModeBySoftwareConnectionModeInSoftware> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftware>> /**  */
	or?: Array<Maybe<FilterOfSoftware>> /**  */
	nor?: Array<Maybe<FilterOfSoftware>> /**  */
	not?: Array<Maybe<FilterOfSoftware>> /**  */
}


/**  */
export type FilterSoftwareAllowedTypeOperator = {
	eq?: Maybe<SoftwareAllowedType> /**  */
	ne?: Maybe<SoftwareAllowedType> /**  */
	in?: Array<Maybe<SoftwareAllowedType>> /**  */
	nin?: Array<Maybe<SoftwareAllowedType>> /**  */
}


/**  */
export type FilterSoftwareCategoryOperator = {
	eq?: Maybe<SoftwareCategory> /**  */
	ne?: Maybe<SoftwareCategory> /**  */
	in?: Array<Maybe<SoftwareCategory>> /**  */
	nin?: Array<Maybe<SoftwareCategory>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareGroupBySoftwareGroupInSoftware = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type ArraySoftwareAttributeOperator = {
	elemMatch?: Maybe<FilterOfSoftwareAttribute> /**  */
}


/**  */
export type FilterOfSoftwareAttribute = {
	name?: Maybe<FilterStringOperator> /**  */
	softwareAttributeTypeId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareAttribute>> /**  */
	or?: Array<Maybe<FilterOfSoftwareAttribute>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareAttribute>> /**  */
	not?: Array<Maybe<FilterOfSoftwareAttribute>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareConnectionModeBySoftwareConnectionModeInSoftware = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfSoftware = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftware>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfContract = {
	hidden?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	contractNumber?: Maybe<FilterStringOperator> /**  */
	customerNumber?: Maybe<FilterStringOperator> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	supplier?: Maybe<FilterDenormalizeOfSupplierBySupplierInContract> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	denouncement?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	budgetId?: Maybe<FilterGuidOperator> /**  */
	budget?: Maybe<FilterDenormalizeOfBudgetByBudgetInContract> /**  */
	unlimited?: Maybe<FilterBooleanOperator> /**  */
	contractCategoryId?: Maybe<FilterGuidOperator> /**  */
	contractCategory?: Maybe<FilterDenormalizeOfContractCategoryByContractCategoryInContract> /**  */
	costMonthly?: Maybe<FilterInt32Operator> /**  */
	costSubscription?: Maybe<FilterInt32Operator> /**  */
	periodicityId?: Maybe<FilterGuidOperator> /**  */
	firstRent?: Maybe<FilterDateTimeOperator> /**  */
	costFirstRent?: Maybe<FilterDoubleOperator> /**  */
	costLastRent?: Maybe<FilterDoubleOperator> /**  */
	costNextRent?: Maybe<FilterDoubleOperator> /**  */
	costOther?: Maybe<FilterDoubleOperator> /**  */
	residualPurchase?: Maybe<FilterInt32Operator> /**  */
	reconduction?: Maybe<FilterDateTimeOperator> /**  */
	taciteReconduction?: Maybe<FilterBooleanOperator> /**  */
	renewal?: Maybe<FilterBooleanOperator> /**  */
	illimited?: Maybe<FilterBooleanOperator> /**  */
	fileNumber?: Maybe<FilterStringOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInContract> /**  */
	cost?: Maybe<FilterDoubleOperator> /**  */
	totalCost?: Maybe<FilterDoubleOperator> /**  */
	authorizeNumber?: Maybe<FilterStringOperator> /**  */
	orderDate?: Maybe<FilterDateTimeOperator> /**  */
	numberOrder?: Maybe<FilterStringOperator> /**  */
	numberSupplierOrder?: Maybe<FilterStringOperator> /**  */
	billDate?: Maybe<FilterDateTimeOperator> /**  */
	billNumber?: Maybe<FilterStringOperator> /**  */
	lotDate?: Maybe<FilterDateTimeOperator> /**  */
	lotNumber?: Maybe<FilterStringOperator> /**  */
	immobilizationNumber?: Maybe<FilterStringOperator> /**  */
	isActiv?: Maybe<FilterBooleanOperator> /**  */
	fixIllimited?: Maybe<FilterBooleanOperator> /**  */
	serviceData?: Maybe<FilterBooleanOperator> /**  */
	fidelityNumber?: Maybe<FilterInt32Operator> /**  */
	fidelityDate?: Maybe<FilterDateTimeOperator> /**  */
	phoneNumber?: Maybe<FilterStringOperator> /**  */
	capacityData?: Maybe<FilterInt32Operator> /**  */
	nextDeadline?: Maybe<FilterDateTimeOperator> /**  */
	firstDeadline?: Maybe<FilterDateTimeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	budgetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	histories?: Maybe<ArrayContractOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	detectWarranty?: Maybe<FilterOfDetectWarranty> /**  */
	groupIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	softwareGroupsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	softwaresIds?: Maybe<ArrayUuidTypeOperator> /**  */
	renewalId?: Maybe<FilterGuidOperator> /**  */
	frequencyDeadline?: Maybe<FilterFrequencyDeadlineOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInContract> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInContract> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfContract>> /**  */
	or?: Array<Maybe<FilterOfContract>> /**  */
	nor?: Array<Maybe<FilterOfContract>> /**  */
	not?: Array<Maybe<FilterOfContract>> /**  */
}


/**  */
export type FilterDenormalizeOfSupplierBySupplierInContract = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfBudgetByBudgetInContract = {
	name?: Maybe<FilterStringOperator> /**  */
	code?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfContractCategoryByContractCategoryInContract = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInContract = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type ArrayContractOperator = {
	elemMatch?: Maybe<FilterOfContract> /**  */
}


/**  */
export type FilterFrequencyDeadlineOperator = {
	eq?: Maybe<FrequencyDeadline> /**  */
	ne?: Maybe<FrequencyDeadline> /**  */
	in?: Array<Maybe<FrequencyDeadline>> /**  */
	nin?: Array<Maybe<FrequencyDeadline>> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInContract = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInContract = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfContract = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeContract>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Contract */
export type QuerySortTypeContract = {
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	contractNumber?: Maybe<Sort> /** Représente le type de trie. */
	customerNumber?: Maybe<Sort> /** Représente le type de trie. */
	supplier?: Array<Maybe<QuerySortTypeSupplier>> /**  */
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	denouncement?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	budget?: Array<Maybe<QuerySortTypeBudget>> /**  */
	unlimited?: Maybe<Sort> /** Représente le type de trie. */
	contractCategory?: Array<Maybe<QuerySortTypeContractCategory>> /**  */
	costMonthly?: Maybe<Sort> /** Représente le type de trie. */
	costSubscription?: Maybe<Sort> /** Représente le type de trie. */
	firstRent?: Maybe<Sort> /** Représente le type de trie. */
	costFirstRent?: Maybe<Sort> /** Représente le type de trie. */
	costLastRent?: Maybe<Sort> /** Représente le type de trie. */
	costNextRent?: Maybe<Sort> /** Représente le type de trie. */
	costOther?: Maybe<Sort> /** Représente le type de trie. */
	residualPurchase?: Maybe<Sort> /** Représente le type de trie. */
	reconduction?: Maybe<Sort> /** Représente le type de trie. */
	taciteReconduction?: Maybe<Sort> /** Représente le type de trie. */
	renewal?: Maybe<Sort> /** Représente le type de trie. */
	illimited?: Maybe<Sort> /** Représente le type de trie. */
	fileNumber?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	cost?: Maybe<Sort> /** Représente le type de trie. */
	totalCost?: Maybe<Sort> /** Représente le type de trie. */
	authorizeNumber?: Maybe<Sort> /** Représente le type de trie. */
	orderDate?: Maybe<Sort> /** Représente le type de trie. */
	numberOrder?: Maybe<Sort> /** Représente le type de trie. */
	numberSupplierOrder?: Maybe<Sort> /** Représente le type de trie. */
	billDate?: Maybe<Sort> /** Représente le type de trie. */
	billNumber?: Maybe<Sort> /** Représente le type de trie. */
	lotDate?: Maybe<Sort> /** Représente le type de trie. */
	lotNumber?: Maybe<Sort> /** Représente le type de trie. */
	immobilizationNumber?: Maybe<Sort> /** Représente le type de trie. */
	isActiv?: Maybe<Sort> /** Représente le type de trie. */
	fixIllimited?: Maybe<Sort> /** Représente le type de trie. */
	serviceData?: Maybe<Sort> /** Représente le type de trie. */
	fidelityNumber?: Maybe<Sort> /** Représente le type de trie. */
	fidelityDate?: Maybe<Sort> /** Représente le type de trie. */
	phoneNumber?: Maybe<Sort> /** Représente le type de trie. */
	capacityData?: Maybe<Sort> /** Représente le type de trie. */
	nextDeadline?: Maybe<Sort> /** Représente le type de trie. */
	firstDeadline?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	budgetIds?: Maybe<Sort> /** Représente le type de trie. */
	histories?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	detectWarranty?: Array<Maybe<QuerySortTypeDetectWarranty>> /**  */
	groupIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	softwareGroupsIds?: Maybe<Sort> /** Représente le type de trie. */
	softwaresIds?: Maybe<Sort> /** Représente le type de trie. */
	frequencyDeadline?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ContractCategory */
export type QuerySortTypeContractCategory = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftwareGroup = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	softwarePackageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	softwareGroupForbidden?: Maybe<FilterBooleanOperator> /**  */
	softwareGroupHidden?: Maybe<FilterBooleanOperator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareGroup>> /**  */
	or?: Array<Maybe<FilterOfSoftwareGroup>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareGroup>> /**  */
	not?: Array<Maybe<FilterOfSoftwareGroup>> /**  */
}


/**  */
export type QueryContextOfSoftwareGroup = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareGroup>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfInCart = {
	amount?: Maybe<FilterInt64Operator> /**  */
	entryId?: Maybe<FilterGuidOperator> /**  */
	category?: Maybe<FilterAssetModelCategoryOperator> /**  */
	cartId?: Maybe<FilterGuidOperator> /**  */
	unitCost?: Maybe<FilterDoubleOperator> /**  */
	totalCost?: Maybe<FilterDoubleOperator> /**  */
	budgetId?: Maybe<FilterGuidOperator> /**  */
	receivedById?: Maybe<FilterGuidOperator> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	inStockName?: Maybe<FilterStringOperator> /**  */
	oldCarts?: Maybe<FilterInt64Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInCart>> /**  */
	or?: Array<Maybe<FilterOfInCart>> /**  */
	nor?: Array<Maybe<FilterOfInCart>> /**  */
	not?: Array<Maybe<FilterOfInCart>> /**  */
}


/**  */
export type FilterAssetModelCategoryOperator = {
	eq?: Maybe<AssetModelCategory> /**  */
	ne?: Maybe<AssetModelCategory> /**  */
	in?: Array<Maybe<AssetModelCategory>> /**  */
	nin?: Array<Maybe<AssetModelCategory>> /**  */
}


/**  */
export type QueryContextOfInCart = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInCart>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité InCart */
export type QuerySortTypeInCart = {
	amount?: Maybe<Sort> /** Représente le type de trie. */
	category?: Maybe<Sort> /** Représente le type de trie. */
	unitCost?: Maybe<Sort> /** Représente le type de trie. */
	totalCost?: Maybe<Sort> /** Représente le type de trie. */
	inStockName?: Maybe<Sort> /** Représente le type de trie. */
	oldCarts?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfBudget = {
	hidden?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	code?: Maybe<FilterStringOperator> /**  */
	opened?: Maybe<FilterDateTimeOperator> /**  */
	closed?: Maybe<FilterDateTimeOperator> /**  */
	amount?: Maybe<FilterDoubleOperator> /**  */
	budgetCategoryId?: Maybe<FilterGuidOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	manager?: Maybe<FilterDenormalizeOfUserByManagerInBudget> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	contractIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfBudgetByParentInBudget> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	groupIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBudget>> /**  */
	or?: Array<Maybe<FilterOfBudget>> /**  */
	nor?: Array<Maybe<FilterOfBudget>> /**  */
	not?: Array<Maybe<FilterOfBudget>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByManagerInBudget = {
	vip?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfBudgetByParentInBudget = {
	name?: Maybe<FilterStringOperator> /**  */
	code?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfBudget = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBudget>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	alarmId?: Maybe<FilterGuidOperator> /**  */
	alarm?: Maybe<FilterDenormalizeOfAlarmByAlarmInAlert> /**  */
	dateOfCreation?: Maybe<FilterDateTimeOperator> /**  */
	acknowledgedUsersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	affectedUsersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	hiddenUsersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAlert> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInAlert> /**  */
	softwarePackageId?: Maybe<FilterGuidOperator> /**  */
	softwarePackage?: Maybe<FilterDenormalizeOfSoftwarePackageBySoftwarePackageInAlert> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	scanConfiguration?: Maybe<FilterDenormalizeOfScanConfigurationByScanConfigurationInAlert> /**  */
	budgetId?: Maybe<FilterGuidOperator> /**  */
	budget?: Maybe<FilterDenormalizeOfBudgetByBudgetInAlert> /**  */
	contractId?: Maybe<FilterGuidOperator> /**  */
	contract?: Maybe<FilterDenormalizeOfContractByContractInAlert> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	loan?: Maybe<FilterDenormalizeOfLoanByLoanInAlert> /**  */
	assetModelId?: Maybe<FilterGuidOperator> /**  */
	assetModel?: Maybe<FilterDenormalizeOfAssetModelByAssetModelInAlert> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInAlert> /**  */
	logicalDiskId?: Maybe<FilterGuidOperator> /**  */
	logicalDisk?: Maybe<FilterDenormalizeOfLogicalDiskByLogicalDiskInAlert> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInAlert> /**  */
	printerConsumableId?: Maybe<FilterGuidOperator> /**  */
	printerConsumable?: Maybe<FilterDenormalizeOfPrinterConsumableByPrinterConsumableInAlert> /**  */
	messageId?: Maybe<FilterGuidOperator> /**  */
	message?: Maybe<FilterDenormalizeOfMessageByMessageInAlert> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInAlert> /**  */
	softwareGroupId?: Maybe<FilterGuidOperator> /**  */
	softwareGroup?: Maybe<FilterDenormalizeOfSoftwareGroupBySoftwareGroupInAlert> /**  */
	problemId?: Maybe<FilterGuidOperator> /**  */
	problem?: Maybe<FilterDenormalizeOfProblemByProblemInAlert> /**  */
	incidentId?: Maybe<FilterGuidOperator> /**  */
	incident?: Maybe<FilterDenormalizeOfIncidentByIncidentInAlert> /**  */
	requestId?: Maybe<FilterGuidOperator> /**  */
	request?: Maybe<FilterDenormalizeOfRequestByRequestInAlert> /**  */
	ticketTaskId?: Maybe<FilterGuidOperator> /**  */
	ticketTask?: Maybe<FilterDenormalizeOfTicketTaskByTicketTaskInAlert> /**  */
	interventionId?: Maybe<FilterGuidOperator> /**  */
	intervention?: Maybe<FilterDenormalizeOfInterventionByInterventionInAlert> /**  */
	minimumStockByStorageUnitAlertResult?: Maybe<FilterOfMinimumStockByStorageUnitAlertResult> /**  */
	sended?: Maybe<FilterBooleanOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAlert>> /**  */
	or?: Array<Maybe<FilterOfAlert>> /**  */
	nor?: Array<Maybe<FilterOfAlert>> /**  */
	not?: Array<Maybe<FilterOfAlert>> /**  */
}


/**  */
export type FilterDenormalizeOfAlarmByAlarmInAlert = {
	type?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterAlarmCategoryEnumOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAlert = {
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	assetStatus?: Maybe<FilterDenormalizeOfAssetStatusByAssetStatusInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastAudit?: Maybe<FilterDateTimeOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	lifeCycle?: Maybe<FilterOfLifeCycle> /**  */
	operatingSystem?: Maybe<FilterStringOperator> /**  */
	memories?: Maybe<FilterDoubleOperator> /**  */
	warrantyEnd?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	softwareGroup?: Maybe<FilterDenormalizeOfSoftwareGroupBySoftwareGroupInSoftware> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwarePackageBySoftwarePackageInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	validate?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanConfigurationInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanConfiguration> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterDenormalizeOfBudgetByBudgetInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	code?: Maybe<FilterStringOperator> /**  */
	opened?: Maybe<FilterDateTimeOperator> /**  */
	closed?: Maybe<FilterDateTimeOperator> /**  */
	amount?: Maybe<FilterDoubleOperator> /**  */
}


/**  */
export type FilterDenormalizeOfContractByContractInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	contractCategory?: Maybe<FilterDenormalizeOfContractCategoryByContractCategoryInContract> /**  */
}


/**  */
export type FilterDenormalizeOfLoanByLoanInAlert = {
	number?: Maybe<FilterInt32Operator> /**  */
	provision?: Maybe<FilterDateTimeOperator> /**  */
	returnDate?: Maybe<FilterDateTimeOperator> /**  */
	recordDate?: Maybe<FilterDateTimeOperator> /**  */
	loanStatus?: Maybe<FilterDenormalizeOfLoanStatusByLoanStatusInLoan> /**  */
}


/**  */
export type FilterDenormalizeOfAssetModelByAssetModelInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLogicalDiskByLogicalDiskInAlert = {
	driveType?: Maybe<FilterDriveTypeOperator> /**  */
	freeSpace?: Maybe<FilterDoubleOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInAlert = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	stock?: Maybe<FilterBooleanOperator> /**  */
}


/**  */
export type FilterDenormalizeOfPrinterConsumableByPrinterConsumableInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
	maxLevel?: Maybe<FilterInt32Operator> /**  */
	actualLevel?: Maybe<FilterInt32Operator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInPrinterConsumable> /**  */
}


/**  */
export type FilterDenormalizeOfMessageByMessageInAlert = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	content?: Maybe<FilterStringOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInMessage> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInMessage = {
	title?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInAlert = {
	title?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareGroupBySoftwareGroupInAlert = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfProblemByProblemInAlert = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInProblem> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInProblem> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInProblem> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInProblem> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInProblem> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInProblem> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInProblem> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorReferentInProblem> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInProblem> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInProblem> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInProblem> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterDenormalizeOfImpactByImpactInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUrgencyByUrgencyInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfPriorityByPriorityInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInProblem = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentByIncidentInAlert = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInIncident> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInIncident> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInIncident> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInIncident> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncident> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncident> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncident> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncident> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInIncident> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInIncident> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncident> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncident> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInIncident> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
}


/**  */
export type FilterDenormalizeOfImpactByImpactInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUrgencyByUrgencyInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfPriorityByPriorityInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInIncident = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfRequestByRequestInAlert = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInRequest> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInRequest> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInRequest> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInRequest> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInRequest> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInRequest> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInRequest> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorReferentInRequest> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInRequest> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInRequest> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByOperatorReferentInRequest> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByOperatorReferentInRequest> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInRequest> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
}


/**  */
export type FilterDenormalizeOfImpactByImpactInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUrgencyByUrgencyInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfPriorityByPriorityInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	color?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInRequest = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketTaskByTicketTaskInAlert = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketTaskByParentInTicketTask> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInTicketTask> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTask> /**  */
	scheduleAt?: Maybe<FilterDateTimeOperator> /**  */
	endAt?: Maybe<FilterDateTimeOperator> /**  */
	duration?: Maybe<FilterTimeSpanOperator> /**  */
	durationMinutes?: Maybe<FilterDoubleOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTaskStatusByStatusInTicketTask> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInTicketTask> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInTicketTask> /**  */
}


/**  */
export type FilterDenormalizeOfTicketTaskByParentInTicketTask = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorInTicketTask = {
	email?: Maybe<FilterStringOperator> /**  */
	serviceDeskActivated?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTaskStatusByStatusInTicketTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInTicketTask = {
	title?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	statusReasonId?: Maybe<FilterGuidOperator> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	mainImpactedAssetId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfActivityByActivityInTicketTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
}


/**  */
export type FilterDenormalizeOfInterventionByInterventionInAlert = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInIntervention> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInIntervention> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	cost?: Maybe<FilterDoubleOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInIntervention> /**  */
	taskId?: Maybe<FilterGuidOperator> /**  */
	task?: Maybe<FilterDenormalizeOfTicketTaskByTaskInIntervention> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInIntervention> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorInIntervention = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamInIntervention = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInIntervention = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInTicket> /**  */
	concernedProject?: Maybe<FilterDenormalizeOfConcernedProjectByConcernedProjectInTicket> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInTicket> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInTicket> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketTaskByTaskInIntervention = {
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfActivityByActivityInIntervention = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
}


/**  */
export type FilterOfMinimumStockByStorageUnitAlertResult = {
	minimum?: Maybe<FilterInt32Operator> /**  */
	currentAmount?: Maybe<FilterDoubleOperator> /**  */
	and?: Array<Maybe<FilterOfMinimumStockByStorageUnitAlertResult>> /**  */
	or?: Array<Maybe<FilterOfMinimumStockByStorageUnitAlertResult>> /**  */
	nor?: Array<Maybe<FilterOfMinimumStockByStorageUnitAlertResult>> /**  */
	not?: Array<Maybe<FilterOfMinimumStockByStorageUnitAlertResult>> /**  */
}


/**  */
export type QueryContextOfAlert = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAlert>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Alert */
export type QuerySortTypeAlert = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	alarm?: Array<Maybe<QuerySortTypeAlarm>> /**  */
	dateOfCreation?: Maybe<Sort> /** Représente le type de trie. */
	acknowledgedUsersIds?: Maybe<Sort> /** Représente le type de trie. */
	affectedUsersIds?: Maybe<Sort> /** Représente le type de trie. */
	hiddenUsersIds?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	softwarePackage?: Array<Maybe<QuerySortTypeSoftwarePackage>> /**  */
	scanConfiguration?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	budget?: Array<Maybe<QuerySortTypeBudget>> /**  */
	contract?: Array<Maybe<QuerySortTypeContract>> /**  */
	loan?: Array<Maybe<QuerySortTypeLoan>> /**  */
	assetModel?: Array<Maybe<QuerySortTypeAssetModel>> /**  */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	logicalDisk?: Array<Maybe<QuerySortTypeLogicalDisk>> /**  */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	printerConsumable?: Array<Maybe<QuerySortTypePrinterConsumable>> /**  */
	message?: Array<Maybe<QuerySortTypeMessage>> /**  */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	softwareGroup?: Array<Maybe<QuerySortTypeSoftwareGroup>> /**  */
	problem?: Array<Maybe<QuerySortTypeProblem>> /**  */
	incident?: Array<Maybe<QuerySortTypeIncident>> /**  */
	request?: Array<Maybe<QuerySortTypeRequest>> /**  */
	ticketTask?: Array<Maybe<QuerySortTypeTicketTask>> /**  */
	intervention?: Array<Maybe<QuerySortTypeIntervention>> /**  */
	minimumStockByStorageUnitAlertResult?: Array<Maybe<QuerySortTypeMinimumStockByStorageUnitAlertResult>> /**  */
	sended?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SoftwarePackage */
export type QuerySortTypeSoftwarePackage = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	create?: Maybe<Sort> /** Représente le type de trie. */
	validate?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	softwareIds?: Maybe<Sort> /** Représente le type de trie. */
	softwareGroupIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LogicalDisk */
export type QuerySortTypeLogicalDisk = {
	providerName?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	compressed?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	driveType?: Maybe<Sort> /** Représente le type de trie. */
	fileSystem?: Maybe<Sort> /** Représente le type de trie. */
	freeSpace?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	volumeName?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	volumeSerialNumber?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité PrinterConsumable */
export type QuerySortTypePrinterConsumable = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	maxLevel?: Maybe<Sort> /** Représente le type de trie. */
	actualLevel?: Maybe<Sort> /** Représente le type de trie. */
	colorCode?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Message */
export type QuerySortTypeMessage = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	content?: Maybe<Sort> /** Représente le type de trie. */
	author?: Array<Maybe<QuerySortTypeUser>> /**  */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	isOperator?: Maybe<Sort> /** Représente le type de trie. */
	isSenderUserConcerned?: Maybe<Sort> /** Représente le type de trie. */
	authorName?: Maybe<Sort> /** Représente le type de trie. */
	alertedUsersIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Incident */
export type QuerySortTypeIncident = {
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	tenantIdString?: Maybe<Sort> /** Représente le type de trie. */
	created?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	counterIncident?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	impact?: Array<Maybe<QuerySortTypeImpact>> /**  */
	urgency?: Array<Maybe<QuerySortTypeUrgency>> /**  */
	priority?: Array<Maybe<QuerySortTypePriority>> /**  */
	resolutionCategory?: Array<Maybe<QuerySortTypeResolutionCategory>> /**  */
	ticketStatusReasons?: Array<Maybe<QuerySortTypeTicketStatusReason>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	channel?: Array<Maybe<QuerySortTypeChannel>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	concernedProject?: Array<Maybe<QuerySortTypeConcernedProject>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	userMakeRequest?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	respondBefore?: Maybe<Sort> /** Représente le type de trie. */
	resolveBefore?: Maybe<Sort> /** Représente le type de trie. */
	sla?: Array<Maybe<QuerySortTypeServiceLevelAgreement>> /**  */
	planned?: Maybe<Sort> /** Représente le type de trie. */
	appointmentConnector?: Array<Maybe<QuerySortTypeAppointmentConnector>> /**  */
	estimate?: Maybe<Sort> /** Représente le type de trie. */
	estimateMinutes?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	mainImpactedAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	favoriteUserTicketIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketNumber?: Maybe<Sort> /** Représente le type de trie. */
	privateResolutionArticle?: Array<Maybe<QuerySortTypeArticleKnowledge>> /**  */
	privateResolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	resolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	incidentModel?: Array<Maybe<QuerySortTypeIncidentModel>> /**  */
	callbackNumber?: Maybe<Sort> /** Représente le type de trie. */
	customField1?: Maybe<Sort> /** Représente le type de trie. */
	customField2?: Maybe<Sort> /** Représente le type de trie. */
	customField3?: Maybe<Sort> /** Représente le type de trie. */
	customField4?: Maybe<Sort> /** Représente le type de trie. */
	customField5?: Maybe<Sort> /** Représente le type de trie. */
	takeChargeDate?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentDate?: Maybe<Sort> /** Représente le type de trie. */
	closedTicketDate?: Maybe<Sort> /** Représente le type de trie. */
	reminder?: Array<Maybe<QuerySortTypeReminder>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	elaspedTimeHour?: Maybe<Sort> /** Représente le type de trie. */
	satisfaction?: Array<Maybe<QuerySortTypeTicketSatisfaction>> /**  */
	takeChargeHours?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentHours?: Maybe<Sort> /** Représente le type de trie. */
	closedHours?: Maybe<Sort> /** Représente le type de trie. */
	isParent?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeTicket>> /**  */
	childrenIds?: Maybe<Sort> /** Représente le type de trie. */
	problem?: Array<Maybe<QuerySortTypeProblem>> /**  */
	externalAlreadySendFileIds?: Maybe<Sort> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Request */
export type QuerySortTypeRequest = {
	privateComment?: Maybe<Sort> /** Représente le type de trie. */
	comment?: Maybe<Sort> /** Représente le type de trie. */
	tenantIdString?: Maybe<Sort> /** Représente le type de trie. */
	created?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	counterIncident?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	impact?: Array<Maybe<QuerySortTypeImpact>> /**  */
	urgency?: Array<Maybe<QuerySortTypeUrgency>> /**  */
	priority?: Array<Maybe<QuerySortTypePriority>> /**  */
	resolutionCategory?: Array<Maybe<QuerySortTypeResolutionCategory>> /**  */
	ticketStatusReasons?: Array<Maybe<QuerySortTypeTicketStatusReason>> /**  */
	ticketCategory?: Array<Maybe<QuerySortTypeTicketCategory>> /**  */
	channel?: Array<Maybe<QuerySortTypeChannel>> /**  */
	operatorTeamReferent?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorReferent?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	concernedProject?: Array<Maybe<QuerySortTypeConcernedProject>> /**  */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	userMakeRequest?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	respondBefore?: Maybe<Sort> /** Représente le type de trie. */
	resolveBefore?: Maybe<Sort> /** Représente le type de trie. */
	sla?: Array<Maybe<QuerySortTypeServiceLevelAgreement>> /**  */
	planned?: Maybe<Sort> /** Représente le type de trie. */
	appointmentConnector?: Array<Maybe<QuerySortTypeAppointmentConnector>> /**  */
	estimate?: Maybe<Sort> /** Représente le type de trie. */
	estimateMinutes?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	mainImpactedAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	favoriteUserTicketIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketNumber?: Maybe<Sort> /** Représente le type de trie. */
	privateResolutionArticle?: Array<Maybe<QuerySortTypeArticleKnowledge>> /**  */
	privateResolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	resolutionComment?: Maybe<Sort> /** Représente le type de trie. */
	incidentModel?: Array<Maybe<QuerySortTypeIncidentModel>> /**  */
	callbackNumber?: Maybe<Sort> /** Représente le type de trie. */
	customField1?: Maybe<Sort> /** Représente le type de trie. */
	customField2?: Maybe<Sort> /** Représente le type de trie. */
	customField3?: Maybe<Sort> /** Représente le type de trie. */
	customField4?: Maybe<Sort> /** Représente le type de trie. */
	customField5?: Maybe<Sort> /** Représente le type de trie. */
	takeChargeDate?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentDate?: Maybe<Sort> /** Représente le type de trie. */
	closedTicketDate?: Maybe<Sort> /** Représente le type de trie. */
	reminder?: Array<Maybe<QuerySortTypeReminder>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	elaspedTimeHour?: Maybe<Sort> /** Représente le type de trie. */
	satisfaction?: Array<Maybe<QuerySortTypeTicketSatisfaction>> /**  */
	takeChargeHours?: Maybe<Sort> /** Représente le type de trie. */
	endTreatmentHours?: Maybe<Sort> /** Représente le type de trie. */
	closedHours?: Maybe<Sort> /** Représente le type de trie. */
	isParent?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeTicket>> /**  */
	childrenIds?: Maybe<Sort> /** Représente le type de trie. */
	problem?: Array<Maybe<QuerySortTypeProblem>> /**  */
	externalAlreadySendFileIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TicketTask */
export type QuerySortTypeTicketTask = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	childrenIds?: Maybe<Sort> /** Représente le type de trie. */
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	parent?: Array<Maybe<QuerySortTypeTicketTask>> /**  */
	visibleForUser?: Maybe<Sort> /** Représente le type de trie. */
	interventionIds?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	scheduleAt?: Maybe<Sort> /** Représente le type de trie. */
	endAt?: Maybe<Sort> /** Représente le type de trie. */
	appointmentConnector?: Array<Maybe<QuerySortTypeAppointmentConnector>> /**  */
	duration?: Maybe<Sort> /** Représente le type de trie. */
	durationMinutes?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	order?: Maybe<Sort> /** Représente le type de trie. */
	status?: Array<Maybe<QuerySortTypeTaskStatus>> /**  */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	incidentModel?: Array<Maybe<QuerySortTypeIncidentModel>> /**  */
	taskSequence?: Array<Maybe<QuerySortTypeTaskSequence>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	activity?: Array<Maybe<QuerySortTypeActivity>> /**  */
	taskNumber?: Maybe<Sort> /** Représente le type de trie. */
	isPredecessor?: Maybe<Sort> /** Représente le type de trie. */
	predecessor?: Array<Maybe<QuerySortTypeTicketTask>> /**  */
	predecessorStatus?: Array<Maybe<QuerySortTypeTaskStatus>> /**  */
	successorsIds?: Maybe<Sort> /** Représente le type de trie. */
	isGreyList?: Maybe<Sort> /** Représente le type de trie. */
	createdByTicketModel?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TaskStatus */
export type QuerySortTypeTaskStatus = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité TaskSequence */
export type QuerySortTypeTaskSequence = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	taskIds?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	activity?: Array<Maybe<QuerySortTypeActivity>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Activity */
export type QuerySortTypeActivity = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Intervention */
export type QuerySortTypeIntervention = {
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	elaspedTimeHour?: Maybe<Sort> /** Représente le type de trie. */
	cost?: Maybe<Sort> /** Représente le type de trie. */
	autoCost?: Maybe<Sort> /** Représente le type de trie. */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	task?: Array<Maybe<QuerySortTypeTicketTask>> /**  */
	activity?: Array<Maybe<QuerySortTypeActivity>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité MinimumStockByStorageUnitAlertResult */
export type QuerySortTypeMinimumStockByStorageUnitAlertResult = {
	minimum?: Maybe<Sort> /** Représente le type de trie. */
	currentAmount?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfConsumableMovement = {
	movementId?: Maybe<FilterGuidOperator> /**  */
	movementType?: Maybe<FilterOfTranslatedField> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	movementDate?: Maybe<FilterDateTimeOperator> /**  */
	quantity?: Maybe<FilterInt64Operator> /**  */
	unitPrice?: Maybe<FilterDoubleOperator> /**  */
	amount?: Maybe<FilterDoubleOperator> /**  */
	reference?: Maybe<FilterStringOperator> /**  */
	referenceName?: Maybe<FilterOfTranslatedField> /**  */
	referencePath?: Maybe<FilterOfTranslatedField> /**  */
	inStockMovement?: Maybe<FilterOfInStockMovement> /**  */
	assetAcquisitionMode?: Maybe<FilterOfTranslatedField> /**  */
	typeOfStaffing?: Maybe<FilterStringOperator> /**  */
	supplier?: Maybe<FilterStringOperator> /**  */
	outStockMovement?: Maybe<FilterOfOutStockMovement> /**  */
	assetRecipient?: Maybe<FilterStringOperator> /**  */
	storageUnit?: Maybe<FilterOfTranslatedField> /**  */
	reason?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfConsumableMovement>> /**  */
	or?: Array<Maybe<FilterOfConsumableMovement>> /**  */
	nor?: Array<Maybe<FilterOfConsumableMovement>> /**  */
	not?: Array<Maybe<FilterOfConsumableMovement>> /**  */
}


/**  */
export type FilterOfInStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
	deliveryDate?: Maybe<FilterDateTimeOperator> /**  */
	receivedById?: Maybe<FilterGuidOperator> /**  */
	deliveryOrder?: Maybe<FilterStringOperator> /**  */
	deliveryNumber?: Maybe<FilterInt32Operator> /**  */
	defaultStorageUnitId?: Maybe<FilterGuidOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	order?: Maybe<FilterOfOrder> /**  */
	bill?: Maybe<FilterOfOrderBill> /**  */
	financial?: Maybe<FilterOfStockFinancialInformation> /**  */
	carts?: Maybe<ArrayInCartOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInStockMovement>> /**  */
	or?: Array<Maybe<FilterOfInStockMovement>> /**  */
	nor?: Array<Maybe<FilterOfInStockMovement>> /**  */
	not?: Array<Maybe<FilterOfInStockMovement>> /**  */
}


/**  */
export type FilterOfOrder = {
	orderDate?: Maybe<FilterDateTimeOperator> /**  */
	supplierId?: Maybe<FilterGuidOperator> /**  */
	supplierReferenceNumber?: Maybe<FilterStringOperator> /**  */
	internalReferenceNumber?: Maybe<FilterStringOperator> /**  */
	reason?: Maybe<FilterGuidOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrder>> /**  */
	or?: Array<Maybe<FilterOfOrder>> /**  */
	nor?: Array<Maybe<FilterOfOrder>> /**  */
	not?: Array<Maybe<FilterOfOrder>> /**  */
}


/**  */
export type FilterOfOrderBill = {
	billDate?: Maybe<FilterDateTimeOperator> /**  */
	total?: Maybe<FilterDoubleOperator> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	billNumber?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrderBill>> /**  */
	or?: Array<Maybe<FilterOfOrderBill>> /**  */
	nor?: Array<Maybe<FilterOfOrderBill>> /**  */
	not?: Array<Maybe<FilterOfOrderBill>> /**  */
}


/**  */
export type FilterOfStockFinancialInformation = {
	budgetId?: Maybe<FilterGuidOperator> /**  */
	modeId?: Maybe<FilterGuidOperator> /**  */
	typeOfStaffingId?: Maybe<FilterGuidOperator> /**  */
	lotDate?: Maybe<FilterDateTimeOperator> /**  */
	lotNumber?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStockFinancialInformation>> /**  */
	or?: Array<Maybe<FilterOfStockFinancialInformation>> /**  */
	nor?: Array<Maybe<FilterOfStockFinancialInformation>> /**  */
	not?: Array<Maybe<FilterOfStockFinancialInformation>> /**  */
}


/**  */
export type ArrayInCartOperator = {
	elemMatch?: Maybe<FilterOfInCart> /**  */
}


/**  */
export type FilterOfOutStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
	deliveryLocationId?: Maybe<FilterGuidOperator> /**  */
	destinationOrganizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	recipientId?: Maybe<FilterGuidOperator> /**  */
	carts?: Maybe<ArrayOutCartOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	outById?: Maybe<FilterGuidOperator> /**  */
	outBy?: Maybe<FilterDenormalizeOfUserByOutByInOutStockMovement> /**  */
	recipient?: Maybe<FilterDenormalizeOfUserByRecipientInOutStockMovement> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterDenormalizeOfLocationByStorageUnitInOutStockMovement> /**  */
	storageUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	deliveryLocation?: Maybe<FilterDenormalizeOfLocationByDeliveryLocationInOutStockMovement> /**  */
	deliveryOrganizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByDeliveryOrganizationalUnitInOutStockMovement> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	reason?: Maybe<FilterDenormalizeOfStockOutReasonByReasonInOutStockMovement> /**  */
	reasonId?: Maybe<FilterGuidOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	hasConsumable?: Maybe<FilterBooleanOperator> /**  */
	hasAsset?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOutStockMovement>> /**  */
	or?: Array<Maybe<FilterOfOutStockMovement>> /**  */
	nor?: Array<Maybe<FilterOfOutStockMovement>> /**  */
	not?: Array<Maybe<FilterOfOutStockMovement>> /**  */
}


/**  */
export type ArrayOutCartOperator = {
	elemMatch?: Maybe<FilterOfOutCart> /**  */
}


/**  */
export type FilterOfOutCart = {
	amount?: Maybe<FilterInt64Operator> /**  */
	entryId?: Maybe<FilterGuidOperator> /**  */
	cartId?: Maybe<FilterGuidOperator> /**  */
	reasonId?: Maybe<FilterGuidOperator> /**  */
	entryName?: Maybe<FilterStringOperator> /**  */
	assetCategory?: Maybe<FilterOfAssetCategory> /**  */
	assetManufacturer?: Maybe<FilterOfModelManufacturer> /**  */
	assetModel?: Maybe<FilterOfModelManufacturer> /**  */
	availableAmount?: Maybe<FilterInt64Operator> /**  */
	oldStock?: Maybe<FilterInt64Operator> /**  */
	correctionStock?: Maybe<FilterInt64Operator> /**  */
	movedAmount?: Maybe<FilterInt64Operator> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterOfLocation> /**  */
	storageUnitEndId?: Maybe<FilterGuidOperator> /**  */
	storageUnitEnd?: Maybe<FilterOfLocation> /**  */
	category?: Maybe<FilterAssetModelCategoryOperator> /**  */
	oldCarts?: Maybe<FilterInt64Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOutCart>> /**  */
	or?: Array<Maybe<FilterOfOutCart>> /**  */
	nor?: Array<Maybe<FilterOfOutCart>> /**  */
	not?: Array<Maybe<FilterOfOutCart>> /**  */
}


/**  */
export type FilterOfAssetCategory = {
	lifetime?: Maybe<FilterOfAssetLifetime> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	defaultPower?: Maybe<FilterInt32Operator> /**  */
	stockageDurationMax?: Maybe<FilterInt32Operator> /**  */
	lifeDuration?: Maybe<FilterInt32Operator> /**  */
	snmpType?: Maybe<FilterSnmpTypeOperator> /**  */
	decayRate?: Maybe<FilterDoubleOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfAssetCategoryByParentInAssetCategory> /**  */
	namingPolicyRuleId?: Maybe<FilterGuidOperator> /**  */
	remoteControlId?: Maybe<FilterGuidOperator> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentNodes?: Maybe<ArrayStringTypeOperator> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
	defaultCommandId?: Maybe<FilterGuidOperator> /**  */
	autoLaunchCommand?: Maybe<FilterBooleanOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	error?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetCategory>> /**  */
	or?: Array<Maybe<FilterOfAssetCategory>> /**  */
	nor?: Array<Maybe<FilterOfAssetCategory>> /**  */
	not?: Array<Maybe<FilterOfAssetCategory>> /**  */
}


/**  */
export type FilterOfAssetLifetime = {
	yearDuration?: Maybe<FilterInt32Operator> /**  */
	monthDuration?: Maybe<FilterInt32Operator> /**  */
	dayDuration?: Maybe<FilterInt32Operator> /**  */
	lifeTimeOption?: Maybe<FilterLifetimeStartDateSelectionOperator> /**  */
	and?: Array<Maybe<FilterOfAssetLifetime>> /**  */
	or?: Array<Maybe<FilterOfAssetLifetime>> /**  */
	nor?: Array<Maybe<FilterOfAssetLifetime>> /**  */
	not?: Array<Maybe<FilterOfAssetLifetime>> /**  */
}


/**  */
export type FilterLifetimeStartDateSelectionOperator = {
	eq?: Maybe<LifetimeStartDateSelection> /**  */
	ne?: Maybe<LifetimeStartDateSelection> /**  */
	in?: Array<Maybe<LifetimeStartDateSelection>> /**  */
	nin?: Array<Maybe<LifetimeStartDateSelection>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByParentInAssetCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfModelManufacturer = {
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInModelManufacturer> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfModelManufacturer>> /**  */
	or?: Array<Maybe<FilterOfModelManufacturer>> /**  */
	nor?: Array<Maybe<FilterOfModelManufacturer>> /**  */
	not?: Array<Maybe<FilterOfModelManufacturer>> /**  */
}


/**  */
export type FilterDenormalizeOfModelManufacturerByManufacturerInModelManufacturer = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOutByInOutStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByRecipientInOutStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByStorageUnitInOutStockMovement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByDeliveryLocationInOutStockMovement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByDeliveryOrganizationalUnitInOutStockMovement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfStockOutReasonByReasonInOutStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfConsumableMovement = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeConsumableMovement>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ConsumableMovement */
export type QuerySortTypeConsumableMovement = {
	movementType?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	movementDate?: Maybe<Sort> /** Représente le type de trie. */
	quantity?: Maybe<Sort> /** Représente le type de trie. */
	unitPrice?: Maybe<Sort> /** Représente le type de trie. */
	amount?: Maybe<Sort> /** Représente le type de trie. */
	reference?: Maybe<Sort> /** Représente le type de trie. */
	referenceName?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	referencePath?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	inStockMovement?: Array<Maybe<QuerySortTypeInStockMovement>> /**  */
	assetAcquisitionMode?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	typeOfStaffing?: Maybe<Sort> /** Représente le type de trie. */
	supplier?: Maybe<Sort> /** Représente le type de trie. */
	outStockMovement?: Array<Maybe<QuerySortTypeOutStockMovement>> /**  */
	assetRecipient?: Maybe<Sort> /** Représente le type de trie. */
	storageUnit?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	reason?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité InStockMovement */
export type QuerySortTypeInStockMovement = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	deliveryDate?: Maybe<Sort> /** Représente le type de trie. */
	deliveryOrder?: Maybe<Sort> /** Représente le type de trie. */
	deliveryNumber?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	order?: Array<Maybe<QuerySortTypeOrder>> /**  */
	bill?: Array<Maybe<QuerySortTypeOrderBill>> /**  */
	financial?: Array<Maybe<QuerySortTypeStockFinancialInformation>> /**  */
	carts?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Order */
export type QuerySortTypeOrder = {
	orderDate?: Maybe<Sort> /** Représente le type de trie. */
	supplier?: Array<Maybe<QuerySortTypeSupplier>> /**  */
	supplierReferenceNumber?: Maybe<Sort> /** Représente le type de trie. */
	internalReferenceNumber?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OrderBill */
export type QuerySortTypeOrderBill = {
	billDate?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	billNumber?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité StockFinancialInformation */
export type QuerySortTypeStockFinancialInformation = {
	budget?: Array<Maybe<QuerySortTypeBudget>> /**  */
	lotDate?: Maybe<Sort> /** Représente le type de trie. */
	lotNumber?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OutStockMovement */
export type QuerySortTypeOutStockMovement = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	carts?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	outBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	recipient?: Array<Maybe<QuerySortTypeUser>> /**  */
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	storageUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	deliveryLocation?: Array<Maybe<QuerySortTypeLocation>> /**  */
	deliveryOrganizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	reason?: Array<Maybe<QuerySortTypeStockOutReason>> /**  */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	hasConsumable?: Maybe<Sort> /** Représente le type de trie. */
	hasAsset?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité StockOutReason */
export type QuerySortTypeStockOutReason = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfOutStockMovement = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOutStockMovement>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfAssetCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetCategory>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetModel = {
	name?: Maybe<FilterStringOperator> /**  */
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAssetModel> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAssetModel> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	assetStockEntryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByModelInAssetModel> /**  */
	category?: Maybe<FilterAssetModelCategoryOperator> /**  */
	minimumStorageAmount?: Maybe<FilterInt64Operator> /**  */
	minimumStockManagement?: Maybe<FilterAssetModelMinimumStockManagementOperator> /**  */
	minimumStockByStorageUnits?: Maybe<ArrayMinimumStockByStorageUnitOperator> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterOfLocation> /**  */
	totalStorageAmout?: Maybe<FilterInt64Operator> /**  */
	supplierIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	compatibleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	restockingThreshold?: Maybe<FilterInt64Operator> /**  */
	economicQuantity?: Maybe<FilterInt64Operator> /**  */
	technicalQuantity?: Maybe<FilterInt64Operator> /**  */
	restockingQuantity?: Maybe<FilterInt64Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetModel>> /**  */
	or?: Array<Maybe<FilterOfAssetModel>> /**  */
	nor?: Array<Maybe<FilterOfAssetModel>> /**  */
	not?: Array<Maybe<FilterOfAssetModel>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByAssetCategoryInAssetModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
}


/**  */
export type FilterDenormalizeOfModelManufacturerByManufacturerInAssetModel = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfModelManufacturerByModelInAssetModel = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterAssetModelMinimumStockManagementOperator = {
	eq?: Maybe<AssetModelMinimumStockManagement> /**  */
	ne?: Maybe<AssetModelMinimumStockManagement> /**  */
	in?: Array<Maybe<AssetModelMinimumStockManagement>> /**  */
	nin?: Array<Maybe<AssetModelMinimumStockManagement>> /**  */
}


/**  */
export type ArrayMinimumStockByStorageUnitOperator = {
	elemMatch?: Maybe<FilterOfMinimumStockByStorageUnit> /**  */
}


/**  */
export type FilterOfMinimumStockByStorageUnit = {
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	minimum?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfMinimumStockByStorageUnit>> /**  */
	or?: Array<Maybe<FilterOfMinimumStockByStorageUnit>> /**  */
	nor?: Array<Maybe<FilterOfMinimumStockByStorageUnit>> /**  */
	not?: Array<Maybe<FilterOfMinimumStockByStorageUnit>> /**  */
}


/**  */
export type QueryContextOfAssetModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetModel>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfInStockMovement = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInStockMovement>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSupplier = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	naf?: Maybe<FilterStringOperator> /**  */
	siret?: Maybe<FilterStringOperator> /**  */
	intraCommunity?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	webSite?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	tva?: Maybe<FilterStringOperator> /**  */
	contactIds?: Maybe<ArrayUuidTypeOperator> /**  */
	phone?: Maybe<FilterStringOperator> /**  */
	fax?: Maybe<FilterStringOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	serviceOfferIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	assetModelIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSupplier>> /**  */
	or?: Array<Maybe<FilterOfSupplier>> /**  */
	nor?: Array<Maybe<FilterOfSupplier>> /**  */
	not?: Array<Maybe<FilterOfSupplier>> /**  */
}


/**  */
export type QueryContextOfSupplier = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSupplier>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfServiceOffer = {
	error?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentNodes?: Maybe<ArrayStringTypeOperator> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	supplierIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfServiceOffer>> /**  */
	or?: Array<Maybe<FilterOfServiceOffer>> /**  */
	nor?: Array<Maybe<FilterOfServiceOffer>> /**  */
	not?: Array<Maybe<FilterOfServiceOffer>> /**  */
}


/**  */
export type QueryContextOfServiceOffer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeServiceOffer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ServiceOffer */
export type QuerySortTypeServiceOffer = {
	error?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	path?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	supplierIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfModelManufacturer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeModelManufacturer>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetModelStockEntry = {
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterDenormalizeOfLocationByStorageUnitInAssetModelStockEntry> /**  */
	inStockId?: Maybe<FilterGuidOperator> /**  */
	inStockCartId?: Maybe<FilterGuidOperator> /**  */
	outStockCartId?: Maybe<FilterGuidOperator> /**  */
	outStockId?: Maybe<FilterGuidOperator> /**  */
	correctionStockCartId?: Maybe<FilterGuidOperator> /**  */
	correctionStockId?: Maybe<FilterGuidOperator> /**  */
	createdAssetId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterAssetModelStockEntryStatusOperator> /**  */
	assetModelId?: Maybe<FilterGuidOperator> /**  */
	assetModel?: Maybe<FilterDenormalizeOfAssetModelByAssetModelInAssetModelStockEntry> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetModelStockEntry>> /**  */
	or?: Array<Maybe<FilterOfAssetModelStockEntry>> /**  */
	nor?: Array<Maybe<FilterOfAssetModelStockEntry>> /**  */
	not?: Array<Maybe<FilterOfAssetModelStockEntry>> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByStorageUnitInAssetModelStockEntry = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterAssetModelStockEntryStatusOperator = {
	eq?: Maybe<AssetModelStockEntryStatus> /**  */
	ne?: Maybe<AssetModelStockEntryStatus> /**  */
	in?: Array<Maybe<AssetModelStockEntryStatus>> /**  */
	nin?: Array<Maybe<AssetModelStockEntryStatus>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetModelByAssetModelInAssetModelStockEntry = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfAssetModelStockEntry = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetModelStockEntry>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetModelStockEntry */
export type QuerySortTypeAssetModelStockEntry = {
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	status?: Maybe<Sort> /** Représente le type de trie. */
	assetModel?: Array<Maybe<QuerySortTypeAssetModel>> /**  */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfAssetCategoryRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetCategoryRule>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSnmpMapInformation = {
	sysOid?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterSnmpTypeOperator> /**  */
	snmpOidObjects?: Maybe<ArraySnmpOidObjectOperator> /**  */
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInSnmpMapInformation> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpMapInformation>> /**  */
	or?: Array<Maybe<FilterOfSnmpMapInformation>> /**  */
	nor?: Array<Maybe<FilterOfSnmpMapInformation>> /**  */
	not?: Array<Maybe<FilterOfSnmpMapInformation>> /**  */
}


/**  */
export type ArraySnmpOidObjectOperator = {
	elemMatch?: Maybe<FilterOfSnmpOidObject> /**  */
}


/**  */
export type FilterOfSnmpOidObject = {
	objectOidMap?: Maybe<FilterObjectOidMapOperator> /**  */
	oid?: Maybe<FilterStringOperator> /**  */
	knowledgeBase?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpOidObject>> /**  */
	or?: Array<Maybe<FilterOfSnmpOidObject>> /**  */
	nor?: Array<Maybe<FilterOfSnmpOidObject>> /**  */
	not?: Array<Maybe<FilterOfSnmpOidObject>> /**  */
}


/**  */
export type FilterObjectOidMapOperator = {
	eq?: Maybe<ObjectOidMap> /**  */
	ne?: Maybe<ObjectOidMap> /**  */
	in?: Array<Maybe<ObjectOidMap>> /**  */
	nin?: Array<Maybe<ObjectOidMap>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByAssetCategoryInSnmpMapInformation = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfSnmpMapInformation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSnmpMapInformation>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SnmpMapInformation */
export type QuerySortTypeSnmpMapInformation = {
	sysOid?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	snmpOidObjects?: Maybe<Sort> /** Représente le type de trie. */
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSnmpMapInformationKb = {
	sysOid?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterSnmpTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	assetCategoryKey?: Maybe<FilterStringOperator> /**  */
	snmpOidObjects?: Maybe<ArraySnmpOidObjectOperator> /**  */
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInSnmpMapInformationKb> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpMapInformationKb>> /**  */
	or?: Array<Maybe<FilterOfSnmpMapInformationKb>> /**  */
	nor?: Array<Maybe<FilterOfSnmpMapInformationKb>> /**  */
	not?: Array<Maybe<FilterOfSnmpMapInformationKb>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetCategoryByAssetCategoryInSnmpMapInformationKb = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfSnmpMapInformationKb = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSnmpMapInformationKb>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SnmpMapInformationKb */
export type QuerySortTypeSnmpMapInformationKb = {
	sysOid?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryKey?: Maybe<Sort> /** Représente le type de trie. */
	snmpOidObjects?: Maybe<Sort> /** Représente le type de trie. */
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLoan = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLoan>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfLocation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLocation>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfNetworkCredential = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	webService?: Maybe<FilterStringOperator> /**  */
	tls?: Maybe<FilterBooleanOperator> /**  */
	community?: Maybe<FilterStringOperator> /**  */
	snmpVersion?: Maybe<FilterSnmpVersionOperator> /**  */
	authentificationSnmpV3?: Maybe<FilterAuthentificationSnmpV3Operator> /**  */
	encryptionSnmpV3?: Maybe<FilterEncryptionSnmpV3Operator> /**  */
	encryptionKey?: Maybe<FilterStringOperator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNetworkCredential>> /**  */
	or?: Array<Maybe<FilterOfNetworkCredential>> /**  */
	nor?: Array<Maybe<FilterOfNetworkCredential>> /**  */
	not?: Array<Maybe<FilterOfNetworkCredential>> /**  */
}


/**  */
export type FilterSnmpVersionOperator = {
	eq?: Maybe<SnmpVersion> /**  */
	ne?: Maybe<SnmpVersion> /**  */
	in?: Array<Maybe<SnmpVersion>> /**  */
	nin?: Array<Maybe<SnmpVersion>> /**  */
}


/**  */
export type FilterAuthentificationSnmpV3Operator = {
	eq?: Maybe<AuthentificationSnmpV3> /**  */
	ne?: Maybe<AuthentificationSnmpV3> /**  */
	in?: Array<Maybe<AuthentificationSnmpV3>> /**  */
	nin?: Array<Maybe<AuthentificationSnmpV3>> /**  */
}


/**  */
export type FilterEncryptionSnmpV3Operator = {
	eq?: Maybe<EncryptionSnmpV3> /**  */
	ne?: Maybe<EncryptionSnmpV3> /**  */
	in?: Array<Maybe<EncryptionSnmpV3>> /**  */
	nin?: Array<Maybe<EncryptionSnmpV3>> /**  */
}


/**  */
export type QueryContextOfNetworkCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNetworkCredential>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfScanExclude = {
	scanExcludeOperator?: Maybe<FilterScanExcludeOperatorOperator> /**  */
	scanExcludeCategory?: Maybe<FilterScanExcludeCategoryOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<ArrayStringTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanExclude>> /**  */
	or?: Array<Maybe<FilterOfScanExclude>> /**  */
	nor?: Array<Maybe<FilterOfScanExclude>> /**  */
	not?: Array<Maybe<FilterOfScanExclude>> /**  */
}


/**  */
export type FilterScanExcludeCategoryOperator = {
	eq?: Maybe<ScanExcludeCategory> /**  */
	ne?: Maybe<ScanExcludeCategory> /**  */
	in?: Array<Maybe<ScanExcludeCategory>> /**  */
	nin?: Array<Maybe<ScanExcludeCategory>> /**  */
}


/**  */
export type QueryContextOfScanExclude = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanExclude>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanExclude */
export type QuerySortTypeScanExclude = {
	scanExcludeOperator?: Maybe<Sort> /** Représente le type de trie. */
	scanExcludeCategory?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanDataFile = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	driveType?: Maybe<ArrayDriveTypeTypeOperator> /**  */
	scanDataFileObject?: Maybe<FilterScanDataFileObjectOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	file?: Maybe<FilterStringOperator> /**  */
	extensions?: Maybe<ArrayStringTypeOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	withSize?: Maybe<FilterBooleanOperator> /**  */
	size?: Maybe<FilterInt32Operator> /**  */
	operator?: Maybe<FilterCompareToTypeOperator> /**  */
	includeFolders?: Maybe<ArrayIncludeFolderOperator> /**  */
	excludeFolders?: Maybe<ArrayExcludeFolderOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInScanDataFile> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanDataFile>> /**  */
	or?: Array<Maybe<FilterOfScanDataFile>> /**  */
	nor?: Array<Maybe<FilterOfScanDataFile>> /**  */
	not?: Array<Maybe<FilterOfScanDataFile>> /**  */
}


/**  */
export type ArrayDriveTypeTypeOperator = {
	all?: Array<Maybe<DriveType>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterDriveTypeOperator> /**  */
}


/**  */
export type FilterScanDataFileObjectOperator = {
	eq?: Maybe<ScanDataFileObject> /**  */
	ne?: Maybe<ScanDataFileObject> /**  */
	in?: Array<Maybe<ScanDataFileObject>> /**  */
	nin?: Array<Maybe<ScanDataFileObject>> /**  */
}


/**  */
export type FilterCompareToTypeOperator = {
	eq?: Maybe<CompareToType> /**  */
	ne?: Maybe<CompareToType> /**  */
	in?: Array<Maybe<CompareToType>> /**  */
	nin?: Array<Maybe<CompareToType>> /**  */
}


/**  */
export type ArrayIncludeFolderOperator = {
	elemMatch?: Maybe<FilterOfIncludeFolder> /**  */
}


/**  */
export type FilterOfIncludeFolder = {
	name?: Maybe<FilterStringOperator> /**  */
	includeSubFolder?: Maybe<FilterBooleanOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIncludeFolder>> /**  */
	or?: Array<Maybe<FilterOfIncludeFolder>> /**  */
	nor?: Array<Maybe<FilterOfIncludeFolder>> /**  */
	not?: Array<Maybe<FilterOfIncludeFolder>> /**  */
}


/**  */
export type ArrayExcludeFolderOperator = {
	elemMatch?: Maybe<FilterOfExcludeFolder> /**  */
}


/**  */
export type FilterOfExcludeFolder = {
	name?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfExcludeFolder>> /**  */
	or?: Array<Maybe<FilterOfExcludeFolder>> /**  */
	nor?: Array<Maybe<FilterOfExcludeFolder>> /**  */
	not?: Array<Maybe<FilterOfExcludeFolder>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInScanDataFile = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfScanDataFile = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanDataFile>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanDataFile */
export type QuerySortTypeScanDataFile = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileObject?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	file?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	withSize?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Maybe<Sort> /** Représente le type de trie. */
	includeFolders?: Maybe<Sort> /** Représente le type de trie. */
	excludeFolders?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanRegistryKey = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	hKey?: Maybe<FilterHKeyOperator> /**  */
	allValue?: Maybe<FilterBooleanOperator> /**  */
	keyPath?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<ArrayStringTypeOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanRegistryKey>> /**  */
	or?: Array<Maybe<FilterOfScanRegistryKey>> /**  */
	nor?: Array<Maybe<FilterOfScanRegistryKey>> /**  */
	not?: Array<Maybe<FilterOfScanRegistryKey>> /**  */
}


/**  */
export type FilterHKeyOperator = {
	eq?: Maybe<HKey> /**  */
	ne?: Maybe<HKey> /**  */
	in?: Array<Maybe<HKey>> /**  */
	nin?: Array<Maybe<HKey>> /**  */
}


/**  */
export type QueryContextOfScanRegistryKey = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanRegistryKey>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfScanImportOption = {
	autoApproved?: Maybe<FilterBooleanOperator> /**  */
	createScreen?: Maybe<FilterBooleanOperator> /**  */
	searchInDeleted?: Maybe<FilterBooleanOperator> /**  */
	searchInArchived?: Maybe<FilterBooleanOperator> /**  */
	serialNumberSearch?: Maybe<FilterSerialNumberSearchOperator> /**  */
	inheritLocation?: Maybe<FilterBooleanOperator> /**  */
	assetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	excludeAssetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionLogonSessions?: Maybe<ArrayScanImportOptionLogonSessionOperator> /**  */
	scanImportOptionIpRanges?: Maybe<ArrayScanImportOptionIpRangeOperator> /**  */
	scanImportOptionIpAddresses?: Maybe<ArrayScanImportOptionIpAddressOperator> /**  */
	searchWarranty?: Maybe<FilterBooleanOperator> /**  */
	importSoftwareLinux?: Maybe<FilterBooleanOperator> /**  */
	importUser?: Maybe<FilterBooleanOperator> /**  */
	importUserMode?: Maybe<FilterImportUserModeOperator> /**  */
	mainUserMode?: Maybe<FilterMainUserModeOperator> /**  */
	createUser?: Maybe<FilterBooleanOperator> /**  */
	updateManualData?: Maybe<FilterBooleanOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dateUp?: Maybe<FilterBooleanOperator> /**  */
	assetStateDateUp?: Maybe<FilterBooleanOperator> /**  */
	forceMonitorName?: Maybe<FilterBooleanOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	changeStatusActivated?: Maybe<FilterBooleanOperator> /**  */
	concernedStatusIds?: Maybe<ArrayUuidTypeOperator> /**  */
	destinationStatusId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanImportOption>> /**  */
	or?: Array<Maybe<FilterOfScanImportOption>> /**  */
	nor?: Array<Maybe<FilterOfScanImportOption>> /**  */
	not?: Array<Maybe<FilterOfScanImportOption>> /**  */
}


/**  */
export type FilterSerialNumberSearchOperator = {
	eq?: Maybe<SerialNumberSearch> /**  */
	ne?: Maybe<SerialNumberSearch> /**  */
	in?: Array<Maybe<SerialNumberSearch>> /**  */
	nin?: Array<Maybe<SerialNumberSearch>> /**  */
}


/**  */
export type ArrayScanImportOptionIpRangeOperator = {
	elemMatch?: Maybe<FilterOfScanImportOptionIpRange> /**  */
}


/**  */
export type FilterOfScanImportOptionIpRange = {
	ipAddressStart?: Maybe<FilterStringOperator> /**  */
	ipAddressEnd?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanImportOptionIpRange>> /**  */
	or?: Array<Maybe<FilterOfScanImportOptionIpRange>> /**  */
	nor?: Array<Maybe<FilterOfScanImportOptionIpRange>> /**  */
	not?: Array<Maybe<FilterOfScanImportOptionIpRange>> /**  */
}


/**  */
export type ArrayScanImportOptionIpAddressOperator = {
	elemMatch?: Maybe<FilterOfScanImportOptionIpAddress> /**  */
}


/**  */
export type FilterOfScanImportOptionIpAddress = {
	value?: Maybe<FilterStringOperator> /**  */
	scanExcludeOperator?: Maybe<FilterScanExcludeOperatorOperator> /**  */
	and?: Array<Maybe<FilterOfScanImportOptionIpAddress>> /**  */
	or?: Array<Maybe<FilterOfScanImportOptionIpAddress>> /**  */
	nor?: Array<Maybe<FilterOfScanImportOptionIpAddress>> /**  */
	not?: Array<Maybe<FilterOfScanImportOptionIpAddress>> /**  */
}


/**  */
export type FilterImportUserModeOperator = {
	eq?: Maybe<ImportUserMode> /**  */
	ne?: Maybe<ImportUserMode> /**  */
	in?: Array<Maybe<ImportUserMode>> /**  */
	nin?: Array<Maybe<ImportUserMode>> /**  */
}


/**  */
export type FilterMainUserModeOperator = {
	eq?: Maybe<MainUserMode> /**  */
	ne?: Maybe<MainUserMode> /**  */
	in?: Array<Maybe<MainUserMode>> /**  */
	nin?: Array<Maybe<MainUserMode>> /**  */
}


/**  */
export type QueryContextOfScanImportOption = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanImportOption>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfInventoryHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInventoryHistory>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfLdapHistory = {
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	lastChanged?: Maybe<FilterDateTimeOperator> /**  */
	actionHistoryState?: Maybe<FilterActionHistoryStateOperator> /**  */
	progressValue?: Maybe<FilterInt32Operator> /**  */
	progressDisplay?: Maybe<FilterStringOperator> /**  */
	ldapUser?: Maybe<FilterOfLdapTrack> /**  */
	ldapGroup?: Maybe<FilterOfLdapTrack> /**  */
	ldapOrganizationalUnit?: Maybe<FilterOfLdapTrack> /**  */
	ldapComputer?: Maybe<FilterOfLdapTrack> /**  */
	forceSearchFilter?: Maybe<FilterBooleanOperator> /**  */
	ldapOrganizationalUnitFilter?: Maybe<FilterInt32Operator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	exceptions?: Maybe<ArrayClarilogExceptionOperator> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	scanConfiguration?: Maybe<FilterDenormalizeOfScanConfigurationByScanConfigurationInLdapHistory> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	excludeFilterUsers?: Maybe<ArrayExcludeFilterUserOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapHistory>> /**  */
	or?: Array<Maybe<FilterOfLdapHistory>> /**  */
	nor?: Array<Maybe<FilterOfLdapHistory>> /**  */
	not?: Array<Maybe<FilterOfLdapHistory>> /**  */
}


/**  */
export type FilterOfLdapTrack = {
	total?: Maybe<FilterInt32Operator> /**  */
	added?: Maybe<FilterInt32Operator> /**  */
	updated?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfLdapTrack>> /**  */
	or?: Array<Maybe<FilterOfLdapTrack>> /**  */
	nor?: Array<Maybe<FilterOfLdapTrack>> /**  */
	not?: Array<Maybe<FilterOfLdapTrack>> /**  */
}


/**  */
export type ArrayClarilogExceptionOperator = {
	elemMatch?: Maybe<FilterOfClarilogException> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanConfigurationInLdapHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type ArrayExcludeFilterUserOperator = {
	elemMatch?: Maybe<FilterOfExcludeFilterUser> /**  */
}


/**  */
export type FilterOfExcludeFilterUser = {
	name?: Maybe<FilterStringOperator> /**  */
	filterExpression?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfExcludeFilterUser>> /**  */
	or?: Array<Maybe<FilterOfExcludeFilterUser>> /**  */
	nor?: Array<Maybe<FilterOfExcludeFilterUser>> /**  */
	not?: Array<Maybe<FilterOfExcludeFilterUser>> /**  */
}


/**  */
export type QueryContextOfLdapHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLdapHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LdapHistory */
export type QuerySortTypeLdapHistory = {
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	lastChanged?: Maybe<Sort> /** Représente le type de trie. */
	actionHistoryState?: Maybe<Sort> /** Représente le type de trie. */
	progressValue?: Maybe<Sort> /** Représente le type de trie. */
	progressDisplay?: Maybe<Sort> /** Représente le type de trie. */
	ldapUser?: Array<Maybe<QuerySortTypeLdapTrack>> /**  */
	ldapGroup?: Array<Maybe<QuerySortTypeLdapTrack>> /**  */
	ldapOrganizationalUnit?: Array<Maybe<QuerySortTypeLdapTrack>> /**  */
	ldapComputer?: Array<Maybe<QuerySortTypeLdapTrack>> /**  */
	forceSearchFilter?: Maybe<Sort> /** Représente le type de trie. */
	ldapOrganizationalUnitFilter?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	exceptions?: Maybe<Sort> /** Représente le type de trie. */
	scanConfiguration?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	excludeFilterUsers?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LdapTrack */
export type QuerySortTypeLdapTrack = {
	total?: Maybe<Sort> /** Représente le type de trie. */
	added?: Maybe<Sort> /** Représente le type de trie. */
	updated?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPackage = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePackage>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Package */
export type QuerySortTypePackage = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	packageName?: Maybe<Sort> /** Représente le type de trie. */
	packageVersion?: Maybe<Sort> /** Représente le type de trie. */
	packagePathSource?: Maybe<Sort> /** Représente le type de trie. */
	campaignIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfCampaignHistory = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	state?: Maybe<FilterHistoryStateOperator> /**  */
	packageId?: Maybe<FilterGuidOperator> /**  */
	package?: Maybe<FilterDenormalizeOfPackageByPackageInCampaignHistory> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInCampaignHistory> /**  */
	campaignId?: Maybe<FilterGuidOperator> /**  */
	campaign?: Maybe<FilterDenormalizeOfCampaignByCampaignInCampaignHistory> /**  */
	error?: Maybe<FilterStringOperator> /**  */
	deployId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCampaignHistory>> /**  */
	or?: Array<Maybe<FilterOfCampaignHistory>> /**  */
	nor?: Array<Maybe<FilterOfCampaignHistory>> /**  */
	not?: Array<Maybe<FilterOfCampaignHistory>> /**  */
}


/**  */
export type FilterHistoryStateOperator = {
	eq?: Maybe<HistoryState> /**  */
	ne?: Maybe<HistoryState> /**  */
	in?: Array<Maybe<HistoryState>> /**  */
	nin?: Array<Maybe<HistoryState>> /**  */
}


/**  */
export type FilterDenormalizeOfPackageByPackageInCampaignHistory = {
	name?: Maybe<FilterStringOperator> /**  */
	packageName?: Maybe<FilterStringOperator> /**  */
	packageVersion?: Maybe<FilterStringOperator> /**  */
	packagePathSource?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInCampaignHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfCampaignByCampaignInCampaignHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfCampaignHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCampaignHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CampaignHistory */
export type QuerySortTypeCampaignHistory = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	package?: Array<Maybe<QuerySortTypePackage>> /**  */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	campaign?: Array<Maybe<QuerySortTypeCampaign>> /**  */
	error?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité Campaign */
export type QuerySortTypeCampaign = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	packageIds?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfCampaign = {
	name?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterCampaignStatusEnumOperator> /**  */
	packageIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastDeployId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCampaign>> /**  */
	or?: Array<Maybe<FilterOfCampaign>> /**  */
	nor?: Array<Maybe<FilterOfCampaign>> /**  */
	not?: Array<Maybe<FilterOfCampaign>> /**  */
}


/**  */
export type FilterCampaignStatusEnumOperator = {
	eq?: Maybe<CampaignStatusEnum> /**  */
	ne?: Maybe<CampaignStatusEnum> /**  */
	in?: Array<Maybe<CampaignStatusEnum>> /**  */
	nin?: Array<Maybe<CampaignStatusEnum>> /**  */
}


/**  */
export type QueryContextOfCampaign = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCampaign>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTicketTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	title?: Maybe<FilterOfTranslatedField> /**  */
	childrenIds?: Maybe<ArrayUuidTypeOperator> /**  */
	createdBy?: Maybe<FilterGuidOperator> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketTaskByParentInTicketTask> /**  */
	visibleForUser?: Maybe<FilterBooleanOperator> /**  */
	interventionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInTicketTask> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTask> /**  */
	scheduleAt?: Maybe<FilterDateTimeOperator> /**  */
	endAt?: Maybe<FilterDateTimeOperator> /**  */
	appointmentConnector?: Maybe<FilterOfAppointmentConnector> /**  */
	duration?: Maybe<FilterTimeSpanOperator> /**  */
	durationMinutes?: Maybe<FilterDoubleOperator> /**  */
	taskModel?: Maybe<FilterGuidOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	order?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTaskStatusByStatusInTicketTask> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInTicketTask> /**  */
	taskStatusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	incidentModel?: Maybe<FilterDenormalizeOfIncidentModelByIncidentModelInTicketTask> /**  */
	taskSequenceId?: Maybe<FilterGuidOperator> /**  */
	taskSequence?: Maybe<FilterDenormalizeOfTaskSequenceByTaskSequenceInTicketTask> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInTicketTask> /**  */
	taskNumber?: Maybe<FilterInt32Operator> /**  */
	isPredecessor?: Maybe<FilterBooleanOperator> /**  */
	predecessorId?: Maybe<FilterGuidOperator> /**  */
	predecessor?: Maybe<FilterDenormalizeOfTicketTaskByPredecessorInTicketTask> /**  */
	predecessorStatusId?: Maybe<FilterGuidOperator> /**  */
	successorsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	isGreyList?: Maybe<FilterBooleanOperator> /**  */
	createdByTicketModel?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketTask>> /**  */
	or?: Array<Maybe<FilterOfTicketTask>> /**  */
	nor?: Array<Maybe<FilterOfTicketTask>> /**  */
	not?: Array<Maybe<FilterOfTicketTask>> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentModelByIncidentModelInTicketTask = {
	modelName?: Maybe<FilterOfTranslatedField> /**  */
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTaskSequenceByTaskSequenceInTicketTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketTaskByPredecessorInTicketTask = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInTicketTask> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTask> /**  */
	scheduleAt?: Maybe<FilterDateTimeOperator> /**  */
	endAt?: Maybe<FilterDateTimeOperator> /**  */
	duration?: Maybe<FilterTimeSpanOperator> /**  */
	durationMinutes?: Maybe<FilterDoubleOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTaskStatusByStatusInTicketTask> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	taskNumber?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type QueryContextOfTicketTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketTask>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTicketTaskModel = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	createdBy?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	visibleForUser?: Maybe<FilterBooleanOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInTicketTaskModel> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTaskModel> /**  */
	duration?: Maybe<FilterTimeSpanOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInTicketTaskModel> /**  */
	taskSequenceId?: Maybe<FilterGuidOperator> /**  */
	taskSequence?: Maybe<FilterDenormalizeOfTaskSequenceByTaskSequenceInTicketTaskModel> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketTaskModel>> /**  */
	or?: Array<Maybe<FilterOfTicketTaskModel>> /**  */
	nor?: Array<Maybe<FilterOfTicketTaskModel>> /**  */
	not?: Array<Maybe<FilterOfTicketTaskModel>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorInTicketTaskModel = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamInTicketTaskModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfActivityByActivityInTicketTaskModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
}


/**  */
export type FilterDenormalizeOfTaskSequenceByTaskSequenceInTicketTaskModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketTaskModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketTaskModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketTaskModel */
export type QuerySortTypeTicketTaskModel = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	visibleForUser?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	duration?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	activity?: Array<Maybe<QuerySortTypeActivity>> /**  */
	taskSequence?: Array<Maybe<QuerySortTypeTaskSequence>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfIntervention = {
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInIntervention> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInIntervention> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	interventionModel?: Maybe<FilterGuidOperator> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	cost?: Maybe<FilterDoubleOperator> /**  */
	autoCost?: Maybe<FilterBooleanOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInIntervention> /**  */
	taskId?: Maybe<FilterGuidOperator> /**  */
	task?: Maybe<FilterDenormalizeOfTicketTaskByTaskInIntervention> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInIntervention> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIntervention>> /**  */
	or?: Array<Maybe<FilterOfIntervention>> /**  */
	nor?: Array<Maybe<FilterOfIntervention>> /**  */
	not?: Array<Maybe<FilterOfIntervention>> /**  */
}


/**  */
export type QueryContextOfIntervention = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeIntervention>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfResolutionCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	resolutionModelIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfResolutionCategory>> /**  */
	or?: Array<Maybe<FilterOfResolutionCategory>> /**  */
	nor?: Array<Maybe<FilterOfResolutionCategory>> /**  */
	not?: Array<Maybe<FilterOfResolutionCategory>> /**  */
}


/**  */
export type QueryContextOfResolutionCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeResolutionCategory>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfResolutionModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	type?: Maybe<ArrayStringTypeOperator> /**  */
	resolutionCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketCategoriesIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfResolutionModel>> /**  */
	or?: Array<Maybe<FilterOfResolutionModel>> /**  */
	nor?: Array<Maybe<FilterOfResolutionModel>> /**  */
	not?: Array<Maybe<FilterOfResolutionModel>> /**  */
}


/**  */
export type QueryContextOfResolutionModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeResolutionModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ResolutionModel */
export type QuerySortTypeResolutionModel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	resolutionCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketCategoriesIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketStatusReason = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketStatusReason> /**  */
	ticketsIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketStatusReason>> /**  */
	or?: Array<Maybe<FilterOfTicketStatusReason>> /**  */
	nor?: Array<Maybe<FilterOfTicketStatusReason>> /**  */
	not?: Array<Maybe<FilterOfTicketStatusReason>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketStatusReason = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketStatusReason = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketStatusReason>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTicketBacklog = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	createDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketBacklog> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicketBacklog> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketBacklog> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt64Operator> /**  */
	ticketBacklogType?: Maybe<FilterTicketBacklogTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketBacklog>> /**  */
	or?: Array<Maybe<FilterOfTicketBacklog>> /**  */
	nor?: Array<Maybe<FilterOfTicketBacklog>> /**  */
	not?: Array<Maybe<FilterOfTicketBacklog>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicketBacklog = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterTicketBacklogTypeOperator = {
	eq?: Maybe<TicketBacklogType> /**  */
	ne?: Maybe<TicketBacklogType> /**  */
	in?: Array<Maybe<TicketBacklogType>> /**  */
	nin?: Array<Maybe<TicketBacklogType>> /**  */
}


/**  */
export type QueryContextOfTicketBacklog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketBacklog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketBacklog */
export type QuerySortTypeTicketBacklog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	createDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	ticketBacklogType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfMessage = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	content?: Maybe<FilterStringOperator> /**  */
	authorId?: Maybe<FilterGuidOperator> /**  */
	author?: Maybe<FilterDenormalizeOfUserByAuthorInMessage> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInMessage> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	isOperator?: Maybe<FilterBooleanOperator> /**  */
	isSenderUserConcerned?: Maybe<FilterBooleanOperator> /**  */
	authorName?: Maybe<FilterStringOperator> /**  */
	alertedUsersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMessage>> /**  */
	or?: Array<Maybe<FilterOfMessage>> /**  */
	nor?: Array<Maybe<FilterOfMessage>> /**  */
	not?: Array<Maybe<FilterOfMessage>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByAuthorInMessage = {
	vip?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfMessage = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMessage>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfEmailPrototypeLog = {
	email?: Maybe<FilterOfEmailPrototypeSend> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	sent?: Maybe<FilterDateTimeOperator> /**  */
	success?: Maybe<FilterBooleanOperator> /**  */
	error?: Maybe<FilterStringOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInEmailPrototypeLog> /**  */
	alarmId?: Maybe<FilterGuidOperator> /**  */
	alarm?: Maybe<FilterDenormalizeOfAlarmByAlarmInEmailPrototypeLog> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEmailPrototypeLog>> /**  */
	or?: Array<Maybe<FilterOfEmailPrototypeLog>> /**  */
	nor?: Array<Maybe<FilterOfEmailPrototypeLog>> /**  */
	not?: Array<Maybe<FilterOfEmailPrototypeLog>> /**  */
}


/**  */
export type FilterOfEmailPrototypeSend = {
	subject?: Maybe<FilterStringOperator> /**  */
	body?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	connectorId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	from?: Maybe<FilterStringOperator> /**  */
	to?: Maybe<ArrayStringTypeOperator> /**  */
	cc?: Maybe<ArrayStringTypeOperator> /**  */
	cci?: Maybe<ArrayStringTypeOperator> /**  */
	replyTo?: Maybe<ArrayStringTypeOperator> /**  */
	attachedResult?: Maybe<FilterBooleanOperator> /**  */
	alarmIds?: Maybe<ArrayUuidTypeOperator> /**  */
	triggerType?: Maybe<FilterEmailPrototypeTriggerTypeEnumOperator> /**  */
	time?: Maybe<FilterDateTimeOperator> /**  */
	and?: Array<Maybe<FilterOfEmailPrototypeSend>> /**  */
	or?: Array<Maybe<FilterOfEmailPrototypeSend>> /**  */
	nor?: Array<Maybe<FilterOfEmailPrototypeSend>> /**  */
	not?: Array<Maybe<FilterOfEmailPrototypeSend>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInEmailPrototypeLog = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterDenormalizeOfAlarmByAlarmInEmailPrototypeLog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfEmailPrototypeLog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEmailPrototypeLog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EmailPrototypeLog */
export type QuerySortTypeEmailPrototypeLog = {
	email?: Array<Maybe<QuerySortTypeEmailPrototypeSend>> /**  */
	type?: Maybe<Sort> /** Représente le type de trie. */
	sent?: Maybe<Sort> /** Représente le type de trie. */
	success?: Maybe<Sort> /** Représente le type de trie. */
	error?: Maybe<Sort> /** Représente le type de trie. */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	alarm?: Array<Maybe<QuerySortTypeAlarm>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EmailPrototypeSend */
export type QuerySortTypeEmailPrototypeSend = {
	subject?: Maybe<Sort> /** Représente le type de trie. */
	body?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	emailConnector?: Array<Maybe<QuerySortTypeEmailConnector>> /**  */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	from?: Maybe<Sort> /** Représente le type de trie. */
	attachedResult?: Maybe<Sort> /** Représente le type de trie. */
	alarmIds?: Maybe<Sort> /** Représente le type de trie. */
	triggerType?: Maybe<Sort> /** Représente le type de trie. */
	time?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfNote = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInNote> /**  */
	externalAppName?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInNote> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	context?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNote>> /**  */
	or?: Array<Maybe<FilterOfNote>> /**  */
	nor?: Array<Maybe<FilterOfNote>> /**  */
	not?: Array<Maybe<FilterOfNote>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorInNote = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInNote = {
	title?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfNote = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNote>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Note */
export type QuerySortTypeNote = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Array<Maybe<QuerySortTypeUser>> /**  */
	externalAppName?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	context?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketEmail = {
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticket?: Maybe<FilterDenormalizeOfTicketByTicketInTicketEmail> /**  */
	ticketEmailAction?: Maybe<FilterTicketEmailActionOperator> /**  */
	emailId?: Maybe<FilterGuidOperator> /**  */
	inboxId?: Maybe<FilterGuidOperator> /**  */
	emailConnectorId?: Maybe<FilterGuidOperator> /**  */
	emailConnector?: Maybe<FilterDenormalizeOfEmailConnectorByEmailConnectorInTicketEmail> /**  */
	to?: Maybe<ArrayStringTypeOperator> /**  */
	from?: Maybe<FilterStringOperator> /**  */
	replyTo?: Maybe<ArrayStringTypeOperator> /**  */
	cc?: Maybe<ArrayStringTypeOperator> /**  */
	cci?: Maybe<ArrayStringTypeOperator> /**  */
	bcc?: Maybe<ArrayStringTypeOperator> /**  */
	attachments?: Maybe<ArrayMailSlurpAttachmentMetaDataOperator> /**  */
	hasAttachment?: Maybe<FilterBooleanOperator> /**  */
	subject?: Maybe<FilterStringOperator> /**  */
	body?: Maybe<FilterStringOperator> /**  */
	isHtml?: Maybe<FilterBooleanOperator> /**  */
	createdAt?: Maybe<FilterDateTimeOperator> /**  */
	errorMessage?: Maybe<FilterStringOperator> /**  */
	hasError?: Maybe<FilterBooleanOperator> /**  */
	retryAt?: Maybe<FilterDateTimeOperator> /**  */
	retryBy?: Maybe<FilterGuidOperator> /**  */
	retryComment?: Maybe<FilterStringOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	createdFromTicket?: Maybe<FilterBooleanOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketEmail>> /**  */
	or?: Array<Maybe<FilterOfTicketEmail>> /**  */
	nor?: Array<Maybe<FilterOfTicketEmail>> /**  */
	not?: Array<Maybe<FilterOfTicketEmail>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByTicketInTicketEmail = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type FilterTicketEmailActionOperator = {
	eq?: Maybe<TicketEmailAction> /**  */
	ne?: Maybe<TicketEmailAction> /**  */
	in?: Array<Maybe<TicketEmailAction>> /**  */
	nin?: Array<Maybe<TicketEmailAction>> /**  */
}


/**  */
export type FilterDenormalizeOfEmailConnectorByEmailConnectorInTicketEmail = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type ArrayMailSlurpAttachmentMetaDataOperator = {
	elemMatch?: Maybe<FilterOfMailSlurpAttachmentMetaData> /**  */
}


/**  */
export type FilterOfMailSlurpAttachmentMetaData = {
	name?: Maybe<FilterStringOperator> /**  */
	contentType?: Maybe<FilterStringOperator> /**  */
	contentLength?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterStringOperator> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	commentary?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMailSlurpAttachmentMetaData>> /**  */
	or?: Array<Maybe<FilterOfMailSlurpAttachmentMetaData>> /**  */
	nor?: Array<Maybe<FilterOfMailSlurpAttachmentMetaData>> /**  */
	not?: Array<Maybe<FilterOfMailSlurpAttachmentMetaData>> /**  */
}


/**  */
export type QueryContextOfTicketEmail = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketEmail>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketEmail */
export type QuerySortTypeTicketEmail = {
	ticket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	ticketEmailAction?: Maybe<Sort> /** Représente le type de trie. */
	emailConnector?: Array<Maybe<QuerySortTypeEmailConnector>> /**  */
	from?: Maybe<Sort> /** Représente le type de trie. */
	attachments?: Maybe<Sort> /** Représente le type de trie. */
	hasAttachment?: Maybe<Sort> /** Représente le type de trie. */
	subject?: Maybe<Sort> /** Représente le type de trie. */
	body?: Maybe<Sort> /** Représente le type de trie. */
	isHtml?: Maybe<Sort> /** Représente le type de trie. */
	createdAt?: Maybe<Sort> /** Représente le type de trie. */
	errorMessage?: Maybe<Sort> /** Représente le type de trie. */
	hasError?: Maybe<Sort> /** Représente le type de trie. */
	retryAt?: Maybe<Sort> /** Représente le type de trie. */
	retryComment?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	createdFromTicket?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfProblem = {
	originId?: Maybe<FilterGuidOperator> /**  */
	origin?: Maybe<FilterDenormalizeOfOriginByOriginInProblem> /**  */
	cause?: Maybe<FilterStringOperator> /**  */
	workaround?: Maybe<FilterStringOperator> /**  */
	solution?: Maybe<FilterStringOperator> /**  */
	tenantIdString?: Maybe<FilterStringOperator> /**  */
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	counterIncident?: Maybe<FilterInt32Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInProblem> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInProblem> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInProblem> /**  */
	resolutionCategoryId?: Maybe<FilterGuidOperator> /**  */
	resolutionCategory?: Maybe<FilterDenormalizeOfResolutionCategoryByResolutionCategoryInProblem> /**  */
	statusReasonId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusReasons?: Maybe<FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInProblem> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInProblem> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	channel?: Maybe<FilterDenormalizeOfChannelByChannelInProblem> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInProblem> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInProblem> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInProblem> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInProblem> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	concernedProject?: Maybe<FilterDenormalizeOfConcernedProjectByConcernedProjectInProblem> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInProblem> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInProblem> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByUserAffectedInProblem> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByUserMakeRequestInProblem> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInProblem> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	respondBefore?: Maybe<FilterDateTimeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	slaId?: Maybe<FilterGuidOperator> /**  */
	sla?: Maybe<FilterDenormalizeOfServiceLevelAgreementBySlaInProblem> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	appointmentConnector?: Maybe<FilterOfAppointmentConnector> /**  */
	estimate?: Maybe<FilterTimeSpanOperator> /**  */
	estimateMinutes?: Maybe<FilterDoubleOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainImpactedAssetId?: Maybe<FilterGuidOperator> /**  */
	mainImpactedAsset?: Maybe<FilterDenormalizeOfAssetByMainImpactedAssetInProblem> /**  */
	favoriteUserTicketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	statusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	privateResolutionArticleId?: Maybe<FilterGuidOperator> /**  */
	privateResolutionArticle?: Maybe<FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInProblem> /**  */
	privateResolutionComment?: Maybe<FilterStringOperator> /**  */
	resolutionComment?: Maybe<FilterStringOperator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	incidentModel?: Maybe<FilterDenormalizeOfIncidentModelByIncidentModelInProblem> /**  */
	callbackNumber?: Maybe<FilterStringOperator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
	takeChargeDate?: Maybe<FilterDateTimeOperator> /**  */
	endTreatmentDate?: Maybe<FilterDateTimeOperator> /**  */
	closedTicketDate?: Maybe<FilterDateTimeOperator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	emailConnectorId?: Maybe<FilterGuidOperator> /**  */
	satisfaction?: Maybe<FilterOfTicketSatisfaction> /**  */
	takeChargeHours?: Maybe<FilterDoubleOperator> /**  */
	endTreatmentHours?: Maybe<FilterDoubleOperator> /**  */
	closedHours?: Maybe<FilterDoubleOperator> /**  */
	isParent?: Maybe<FilterBooleanOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketByParentInProblem> /**  */
	childrenIds?: Maybe<ArrayUuidTypeOperator> /**  */
	problemId?: Maybe<FilterGuidOperator> /**  */
	problem?: Maybe<FilterDenormalizeOfProblemByProblemInProblem> /**  */
	externalAlreadySendFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfProblem>> /**  */
	or?: Array<Maybe<FilterOfProblem>> /**  */
	nor?: Array<Maybe<FilterOfProblem>> /**  */
	not?: Array<Maybe<FilterOfProblem>> /**  */
}


/**  */
export type FilterDenormalizeOfOriginByOriginInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfResolutionCategoryByResolutionCategoryInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfChannelByChannelInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInProblem = {
	email?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfConcernedProjectByConcernedProjectInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserAffectedInProblem = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserMakeRequestInProblem = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfServiceLevelAgreementBySlaInProblem = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainImpactedAssetInProblem = {
	name?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInProblem = {
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentModelByIncidentModelInProblem = {
	modelName?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByParentInProblem = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfProblemByProblemInProblem = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type QueryContextOfProblem = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeProblem>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSoftwarePackage = {
	name?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	create?: Maybe<FilterDateTimeOperator> /**  */
	validate?: Maybe<FilterDateTimeOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	softwareIds?: Maybe<ArrayUuidTypeOperator> /**  */
	softwareGroupIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwarePackage>> /**  */
	or?: Array<Maybe<FilterOfSoftwarePackage>> /**  */
	nor?: Array<Maybe<FilterOfSoftwarePackage>> /**  */
	not?: Array<Maybe<FilterOfSoftwarePackage>> /**  */
}


/**  */
export type QueryContextOfSoftwarePackage = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwarePackage>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSoftwareProperty = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInSoftwareProperty> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSoftwareProperty> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	bootDevice?: Maybe<FilterStringOperator> /**  */
	buildNumber?: Maybe<FilterStringOperator> /**  */
	buildType?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	codeSet?: Maybe<FilterStringOperator> /**  */
	countryCode?: Maybe<FilterStringOperator> /**  */
	csdVersion?: Maybe<FilterStringOperator> /**  */
	currentTimeZone?: Maybe<FilterInt32Operator> /**  */
	debug?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	distributed?: Maybe<FilterBooleanOperator> /**  */
	encryptionLevel?: Maybe<FilterInt64Operator> /**  */
	foregroundApplicationBoost?: Maybe<FilterInt32Operator> /**  */
	freePhysicalMemory?: Maybe<FilterInt64Operator> /**  */
	freeSpaceInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	freeVirtualMemory?: Maybe<FilterInt64Operator> /**  */
	largeSystemCache?: Maybe<FilterInt64Operator> /**  */
	lastBootUpTime?: Maybe<FilterDateTimeOperator> /**  */
	localDateTime?: Maybe<FilterDateTimeOperator> /**  */
	locale?: Maybe<FilterStringOperator> /**  */
	maxNumberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	maxProcessMemorySize?: Maybe<FilterInt64Operator> /**  */
	muiLanguages?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	numberOfLicensedUsers?: Maybe<FilterInt64Operator> /**  */
	numberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	numberOfUsers?: Maybe<FilterInt64Operator> /**  */
	operatingSystemSku?: Maybe<FilterInt64Operator> /**  */
	organization?: Maybe<FilterStringOperator> /**  */
	osArchitecture?: Maybe<FilterStringOperator> /**  */
	osLanguage?: Maybe<FilterInt64Operator> /**  */
	osProductSuite?: Maybe<FilterInt64Operator> /**  */
	osType?: Maybe<FilterInt64Operator> /**  */
	otherTypeDescription?: Maybe<FilterStringOperator> /**  */
	paeEnabled?: Maybe<FilterBooleanOperator> /**  */
	plusProductId?: Maybe<FilterStringOperator> /**  */
	plusVersionNumber?: Maybe<FilterStringOperator> /**  */
	primary?: Maybe<FilterBooleanOperator> /**  */
	productType?: Maybe<FilterInt64Operator> /**  */
	registeredUser?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	servicePackMajorVersion?: Maybe<FilterInt64Operator> /**  */
	servicePackMinorVersion?: Maybe<FilterInt64Operator> /**  */
	sizeStoredInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	suiteMask?: Maybe<FilterInt64Operator> /**  */
	systemDevice?: Maybe<FilterStringOperator> /**  */
	systemDirectory?: Maybe<FilterStringOperator> /**  */
	systemDrive?: Maybe<FilterStringOperator> /**  */
	totalSwapSpaceSize?: Maybe<FilterInt64Operator> /**  */
	totalVirtualMemorySize?: Maybe<FilterInt64Operator> /**  */
	totalVisibleMemorySize?: Maybe<FilterInt64Operator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	windowsDirectory?: Maybe<FilterStringOperator> /**  */
	digitalProductId?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	installDate?: Maybe<FilterDateTimeOperator> /**  */
	parentDisplayName?: Maybe<FilterStringOperator> /**  */
	contact?: Maybe<FilterStringOperator> /**  */
	estimatedSize?: Maybe<FilterInt64Operator> /**  */
	installLocation?: Maybe<FilterStringOperator> /**  */
	installSource?: Maybe<FilterStringOperator> /**  */
	helpLink?: Maybe<FilterStringOperator> /**  */
	language?: Maybe<FilterInt64Operator> /**  */
	productId?: Maybe<FilterStringOperator> /**  */
	uninstallString?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
	fixComments?: Maybe<FilterStringOperator> /**  */
	hotFixId?: Maybe<FilterStringOperator> /**  */
	installedBy?: Maybe<FilterStringOperator> /**  */
	installedOn?: Maybe<FilterStringOperator> /**  */
	installedDate?: Maybe<FilterDateTimeOperator> /**  */
	servicePackInEffect?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareProperty>> /**  */
	or?: Array<Maybe<FilterOfSoftwareProperty>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareProperty>> /**  */
	not?: Array<Maybe<FilterOfSoftwareProperty>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInSoftwareProperty = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSoftwareProperty = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type QueryContextOfSoftwareProperty = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareProperty>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SoftwareProperty */
export type QuerySortTypeSoftwareProperty = {
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	bootDevice?: Maybe<Sort> /** Représente le type de trie. */
	buildNumber?: Maybe<Sort> /** Représente le type de trie. */
	buildType?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	codeSet?: Maybe<Sort> /** Représente le type de trie. */
	countryCode?: Maybe<Sort> /** Représente le type de trie. */
	csdVersion?: Maybe<Sort> /** Représente le type de trie. */
	currentTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	debug?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	distributed?: Maybe<Sort> /** Représente le type de trie. */
	encryptionLevel?: Maybe<Sort> /** Représente le type de trie. */
	foregroundApplicationBoost?: Maybe<Sort> /** Représente le type de trie. */
	freePhysicalMemory?: Maybe<Sort> /** Représente le type de trie. */
	freeSpaceInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	freeVirtualMemory?: Maybe<Sort> /** Représente le type de trie. */
	largeSystemCache?: Maybe<Sort> /** Représente le type de trie. */
	lastBootUpTime?: Maybe<Sort> /** Représente le type de trie. */
	localDateTime?: Maybe<Sort> /** Représente le type de trie. */
	locale?: Maybe<Sort> /** Représente le type de trie. */
	maxNumberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	maxProcessMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLicensedUsers?: Maybe<Sort> /** Représente le type de trie. */
	numberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	numberOfUsers?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystemSku?: Maybe<Sort> /** Représente le type de trie. */
	organization?: Maybe<Sort> /** Représente le type de trie. */
	osArchitecture?: Maybe<Sort> /** Représente le type de trie. */
	osLanguage?: Maybe<Sort> /** Représente le type de trie. */
	osProductSuite?: Maybe<Sort> /** Représente le type de trie. */
	osType?: Maybe<Sort> /** Représente le type de trie. */
	otherTypeDescription?: Maybe<Sort> /** Représente le type de trie. */
	paeEnabled?: Maybe<Sort> /** Représente le type de trie. */
	plusProductId?: Maybe<Sort> /** Représente le type de trie. */
	plusVersionNumber?: Maybe<Sort> /** Représente le type de trie. */
	primary?: Maybe<Sort> /** Représente le type de trie. */
	productType?: Maybe<Sort> /** Représente le type de trie. */
	registeredUser?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	sizeStoredInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	suiteMask?: Maybe<Sort> /** Représente le type de trie. */
	systemDevice?: Maybe<Sort> /** Représente le type de trie. */
	systemDirectory?: Maybe<Sort> /** Représente le type de trie. */
	systemDrive?: Maybe<Sort> /** Représente le type de trie. */
	totalSwapSpaceSize?: Maybe<Sort> /** Représente le type de trie. */
	totalVirtualMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	totalVisibleMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	windowsDirectory?: Maybe<Sort> /** Représente le type de trie. */
	digitalProductId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	installDate?: Maybe<Sort> /** Représente le type de trie. */
	parentDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	contact?: Maybe<Sort> /** Représente le type de trie. */
	estimatedSize?: Maybe<Sort> /** Représente le type de trie. */
	installLocation?: Maybe<Sort> /** Représente le type de trie. */
	installSource?: Maybe<Sort> /** Représente le type de trie. */
	helpLink?: Maybe<Sort> /** Représente le type de trie. */
	language?: Maybe<Sort> /** Représente le type de trie. */
	productId?: Maybe<Sort> /** Représente le type de trie. */
	uninstallString?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	publisher?: Maybe<Sort> /** Représente le type de trie. */
	fixComments?: Maybe<Sort> /** Représente le type de trie. */
	hotFixId?: Maybe<Sort> /** Représente le type de trie. */
	installedBy?: Maybe<Sort> /** Représente le type de trie. */
	installedOn?: Maybe<Sort> /** Représente le type de trie. */
	installedDate?: Maybe<Sort> /** Représente le type de trie. */
	servicePackInEffect?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftwareHostedLink = {
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInSoftwareHostedLink> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInSoftwareHostedLink> /**  */
	softwareHostedComponentId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareHostedLink>> /**  */
	or?: Array<Maybe<FilterOfSoftwareHostedLink>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareHostedLink>> /**  */
	not?: Array<Maybe<FilterOfSoftwareHostedLink>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInSoftwareHostedLink = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInSoftwareHostedLink = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfSoftwareHostedLink = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareHostedLink>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SoftwareHostedLink */
export type QuerySortTypeSoftwareHostedLink = {
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	softwareHostedComponent?: Array<Maybe<QuerySortTypeSoftwareHostedComponent>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SoftwareHostedComponent */
export type QuerySortTypeSoftwareHostedComponent = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftwareReferentLink = {
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInSoftwareReferentLink> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	user?: Maybe<FilterDenormalizeOfUserByUserInSoftwareReferentLink> /**  */
	softwareReferentTypeId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareReferentLink>> /**  */
	or?: Array<Maybe<FilterOfSoftwareReferentLink>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareReferentLink>> /**  */
	not?: Array<Maybe<FilterOfSoftwareReferentLink>> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInSoftwareReferentLink = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserInSoftwareReferentLink = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfSoftwareReferentLink = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareReferentLink>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SoftwareReferentLink */
export type QuerySortTypeSoftwareReferentLink = {
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	softwareReferentType?: Array<Maybe<QuerySortTypeSoftwareReferentType>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SoftwareReferentType */
export type QuerySortTypeSoftwareReferentType = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfDataFile = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDataFile>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DataFile */
export type QuerySortTypeDataFile = {
	accessMask?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	compressed?: Maybe<Sort> /** Représente le type de trie. */
	compressionMethod?: Maybe<Sort> /** Représente le type de trie. */
	creationDate?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	drive?: Maybe<Sort> /** Représente le type de trie. */
	eightDotThreeFileName?: Maybe<Sort> /** Représente le type de trie. */
	encrypted?: Maybe<Sort> /** Représente le type de trie. */
	encryptionMethod?: Maybe<Sort> /** Représente le type de trie. */
	extension?: Maybe<Sort> /** Représente le type de trie. */
	fileName?: Maybe<Sort> /** Représente le type de trie. */
	fileSize?: Maybe<Sort> /** Représente le type de trie. */
	fileType?: Maybe<Sort> /** Représente le type de trie. */
	fsName?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	inUseCount?: Maybe<Sort> /** Représente le type de trie. */
	lastAccessed?: Maybe<Sort> /** Représente le type de trie. */
	lastModified?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	readable?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	system?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	writeable?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFile?: Array<Maybe<QuerySortTypeScanDataFile>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfImportAuditTask = {
	asset?: Maybe<FilterOfAsset> /**  */
	auditResult?: Maybe<FilterOfAuditResult> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	scanImportOptionId?: Maybe<FilterGuidOperator> /**  */
	scanImportOption?: Maybe<FilterDenormalizeOfScanImportOptionByScanImportOptionInImportAuditTask> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfImportAuditTask>> /**  */
	or?: Array<Maybe<FilterOfImportAuditTask>> /**  */
	nor?: Array<Maybe<FilterOfImportAuditTask>> /**  */
	not?: Array<Maybe<FilterOfImportAuditTask>> /**  */
}


/**  */
export type FilterDenormalizeOfScanImportOptionByScanImportOptionInImportAuditTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterScheduleTaskStatusOperator = {
	eq?: Maybe<ScheduleTaskStatus> /**  */
	ne?: Maybe<ScheduleTaskStatus> /**  */
	in?: Array<Maybe<ScheduleTaskStatus>> /**  */
	nin?: Array<Maybe<ScheduleTaskStatus>> /**  */
}


/**  */
export type QueryContextOfImportAuditTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeImportAuditTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ImportAuditTask */
export type QuerySortTypeImportAuditTask = {
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	auditResult?: Array<Maybe<QuerySortTypeAuditResult>> /**  */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOption?: Array<Maybe<QuerySortTypeScanImportOption>> /**  */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanByLdap = {
	domain?: Maybe<FilterStringOperator> /**  */
	credentialId?: Maybe<FilterGuidOperator> /**  */
	ldapCredential?: Maybe<FilterDenormalizeOfNetworkCredentialByLdapCredentialInScanByLdap> /**  */
	useSsl?: Maybe<FilterBooleanOperator> /**  */
	usePort?: Maybe<FilterInt32Operator> /**  */
	protocolVersion?: Maybe<FilterInt32Operator> /**  */
	connectionTimeoutIn?: Maybe<FilterInt32Operator> /**  */
	ldapAuthType?: Maybe<FilterLdapAuthTypeOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	importGroup?: Maybe<FilterBooleanOperator> /**  */
	importUser?: Maybe<FilterBooleanOperator> /**  */
	importDisabledUser?: Maybe<FilterBooleanOperator> /**  */
	importRecursiveGroup?: Maybe<FilterBooleanOperator> /**  */
	importComputer?: Maybe<FilterBooleanOperator> /**  */
	importLdapStruct?: Maybe<FilterBooleanOperator> /**  */
	disableUsers?: Maybe<FilterBooleanOperator> /**  */
	importOrganizationalUnit?: Maybe<FilterImportOrganizationalUnitOperator> /**  */
	forceSearchFilter?: Maybe<FilterBooleanOperator> /**  */
	fieldMappingId?: Maybe<FilterGuidOperator> /**  */
	objectClassMappingId?: Maybe<FilterGuidOperator> /**  */
	ldapDistinguishedNames?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByLdap> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByLdap> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByLdap>> /**  */
	or?: Array<Maybe<FilterOfScanByLdap>> /**  */
	nor?: Array<Maybe<FilterOfScanByLdap>> /**  */
	not?: Array<Maybe<FilterOfScanByLdap>> /**  */
}


/**  */
export type FilterDenormalizeOfNetworkCredentialByLdapCredentialInScanByLdap = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByLdap = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByLdap = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByLdap = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByLdap>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByLdap */
export type QuerySortTypeScanByLdap = {
	domain?: Maybe<Sort> /** Représente le type de trie. */
	ldapCredential?: Array<Maybe<QuerySortTypeNetworkCredential>> /**  */
	useSsl?: Maybe<Sort> /** Représente le type de trie. */
	usePort?: Maybe<Sort> /** Représente le type de trie. */
	protocolVersion?: Maybe<Sort> /** Représente le type de trie. */
	connectionTimeoutIn?: Maybe<Sort> /** Représente le type de trie. */
	ldapAuthType?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	importGroup?: Maybe<Sort> /** Représente le type de trie. */
	importUser?: Maybe<Sort> /** Représente le type de trie. */
	importDisabledUser?: Maybe<Sort> /** Représente le type de trie. */
	importRecursiveGroup?: Maybe<Sort> /** Représente le type de trie. */
	importComputer?: Maybe<Sort> /** Représente le type de trie. */
	importLdapStruct?: Maybe<Sort> /** Représente le type de trie. */
	disableUsers?: Maybe<Sort> /** Représente le type de trie. */
	importOrganizationalUnit?: Maybe<Sort> /** Représente le type de trie. */
	forceSearchFilter?: Maybe<Sort> /** Représente le type de trie. */
	fieldMapping?: Array<Maybe<QuerySortTypeFieldMapping>> /**  */
	objectClassMapping?: Array<Maybe<QuerySortTypeObjectClassMapping>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanByAzureAd = {
	applicationAzureAdId?: Maybe<FilterGuidOperator> /**  */
	importDisabledUser?: Maybe<FilterBooleanOperator> /**  */
	importMember?: Maybe<FilterBooleanOperator> /**  */
	importGuest?: Maybe<FilterBooleanOperator> /**  */
	importNoType?: Maybe<FilterBooleanOperator> /**  */
	importGroup?: Maybe<FilterBooleanOperator> /**  */
	groupObjectIds?: Maybe<ArrayStringTypeOperator> /**  */
	fieldMappingId?: Maybe<FilterGuidOperator> /**  */
	objectClassMappingId?: Maybe<FilterGuidOperator> /**  */
	recursiveGroup?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByAzureAd> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByAzureAd> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByAzureAd>> /**  */
	or?: Array<Maybe<FilterOfScanByAzureAd>> /**  */
	nor?: Array<Maybe<FilterOfScanByAzureAd>> /**  */
	not?: Array<Maybe<FilterOfScanByAzureAd>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByAzureAd = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByAzureAd = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByAzureAd = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByAzureAd>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByAzureAd */
export type QuerySortTypeScanByAzureAd = {
	applicationAzureAd?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /**  */
	importDisabledUser?: Maybe<Sort> /** Représente le type de trie. */
	importMember?: Maybe<Sort> /** Représente le type de trie. */
	importGuest?: Maybe<Sort> /** Représente le type de trie. */
	importNoType?: Maybe<Sort> /** Représente le type de trie. */
	importGroup?: Maybe<Sort> /** Représente le type de trie. */
	recursiveGroup?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSftpConnectorHistory = {
	sftpConnectorId?: Maybe<FilterGuidOperator> /**  */
	sftpConnector?: Maybe<FilterDenormalizeOfSftpConnectorBySftpConnectorInSftpConnectorHistory> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	sftpAction?: Maybe<FilterSftpActionOperator> /**  */
	hasError?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSftpConnectorHistory>> /**  */
	or?: Array<Maybe<FilterOfSftpConnectorHistory>> /**  */
	nor?: Array<Maybe<FilterOfSftpConnectorHistory>> /**  */
	not?: Array<Maybe<FilterOfSftpConnectorHistory>> /**  */
}


/**  */
export type FilterDenormalizeOfSftpConnectorBySftpConnectorInSftpConnectorHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterSftpActionOperator = {
	eq?: Maybe<SftpAction> /**  */
	ne?: Maybe<SftpAction> /**  */
	in?: Array<Maybe<SftpAction>> /**  */
	nin?: Array<Maybe<SftpAction>> /**  */
}


/**  */
export type QueryContextOfSftpConnectorHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSftpConnectorHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SftpConnectorHistory */
export type QuerySortTypeSftpConnectorHistory = {
	sftpConnector?: Array<Maybe<QuerySortTypeSftpConnector>> /**  */
	date?: Maybe<Sort> /** Représente le type de trie. */
	sftpAction?: Maybe<Sort> /** Représente le type de trie. */
	hasError?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SftpConnector */
export type QuerySortTypeSftpConnector = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	server?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	remoteFolder?: Maybe<Sort> /** Représente le type de trie. */
	environnement?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	queryBuilderIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfEmailConnector = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	type?: Maybe<FilterEmailConnectorTypeEnumOperator> /**  */
	server?: Maybe<FilterStringOperator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	ssl?: Maybe<FilterBooleanOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	accountId?: Maybe<FilterGuidOperator> /**  */
	account?: Maybe<FilterDenormalizeOfEmailCredentialByAccountInEmailConnector> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	mailSlurpInbox?: Maybe<FilterOfMailSlurpInbox> /**  */
	allowUnknowFrom?: Maybe<FilterBooleanOperator> /**  */
	allowCreateTicketByEmail?: Maybe<FilterBooleanOperator> /**  */
	allowCommunicationByMail?: Maybe<FilterBooleanOperator> /**  */
	allowAddInterventionByMail?: Maybe<FilterBooleanOperator> /**  */
	interventionByMailTag?: Maybe<FilterStringOperator> /**  */
	interventionByMailActivityId?: Maybe<FilterGuidOperator> /**  */
	onCreateTicketAddAllRecipients?: Maybe<FilterBooleanOperator> /**  */
	onCommunicationTicketAddAllRecipients?: Maybe<FilterBooleanOperator> /**  */
	onCommunicationTicketAddFrom?: Maybe<FilterBooleanOperator> /**  */
	statusIds?: Maybe<ArrayUuidTypeOperator> /**  */
	inboxName?: Maybe<FilterStringOperator> /**  */
	send?: Maybe<FilterBooleanOperator> /**  */
	receive?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	countMailSend?: Maybe<FilterInt32Operator> /**  */
	countMailReceive?: Maybe<FilterInt32Operator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInEmailConnector> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInEmailConnector> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInEmailConnector> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInEmailConnector> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInEmailConnector> /**  */
	environnement?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	applicationAzureAdId?: Maybe<FilterGuidOperator> /**  */
	accessToken?: Maybe<FilterStringOperator> /**  */
	expireAccesToken?: Maybe<FilterDateTimeOperator> /**  */
	default?: Maybe<FilterBooleanOperator> /**  */
	disabledSend?: Maybe<FilterOfDisabledReason> /**  */
	disabledReceive?: Maybe<FilterOfDisabledReason> /**  */
	allowEmbeddedImage?: Maybe<FilterBooleanOperator> /**  */
	smtpEncodingConstraint?: Maybe<FilterInt32Operator> /**  */
	authAzurePassword?: Maybe<FilterBooleanOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEmailConnector>> /**  */
	or?: Array<Maybe<FilterOfEmailConnector>> /**  */
	nor?: Array<Maybe<FilterOfEmailConnector>> /**  */
	not?: Array<Maybe<FilterOfEmailConnector>> /**  */
}


/**  */
export type FilterEmailConnectorTypeEnumOperator = {
	eq?: Maybe<EmailConnectorTypeEnum> /**  */
	ne?: Maybe<EmailConnectorTypeEnum> /**  */
	in?: Array<Maybe<EmailConnectorTypeEnum>> /**  */
	nin?: Array<Maybe<EmailConnectorTypeEnum>> /**  */
}


/**  */
export type FilterDenormalizeOfEmailCredentialByAccountInEmailConnector = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfMailSlurpInbox = {
	email?: Maybe<FilterStringOperator> /**  */
	inboxId?: Maybe<FilterGuidOperator> /**  */
	webhookId?: Maybe<FilterGuidOperator> /**  */
	endpoint?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	useFiltering?: Maybe<FilterBooleanOperator> /**  */
	useEmailChecker?: Maybe<FilterBooleanOperator> /**  */
	emailCheckerLevel?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfMailSlurpInbox>> /**  */
	or?: Array<Maybe<FilterOfMailSlurpInbox>> /**  */
	nor?: Array<Maybe<FilterOfMailSlurpInbox>> /**  */
	not?: Array<Maybe<FilterOfMailSlurpInbox>> /**  */
}


/**  */
export type FilterDenormalizeOfTicketCategoryByTicketCategoryInEmailConnector = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorReferentInEmailConnector = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInEmailConnector = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInEmailConnector = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInEmailConnector = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfDisabledReason = {
	disabled?: Maybe<FilterBooleanOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	reason?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDisabledReason>> /**  */
	or?: Array<Maybe<FilterOfDisabledReason>> /**  */
	nor?: Array<Maybe<FilterOfDisabledReason>> /**  */
	not?: Array<Maybe<FilterOfDisabledReason>> /**  */
}


/**  */
export type QueryContextOfEmailConnector = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEmailConnector>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfEmailPrototype = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEmailPrototype>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTicketCategory = {
	error?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	parentNodes?: Maybe<ArrayStringTypeOperator> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	serviceLevelAgreementIds?: Maybe<ArrayUuidTypeOperator> /**  */
	keywords?: Maybe<ArrayStringTypeOperator> /**  */
	allowSelection?: Maybe<FilterBooleanOperator> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketCategory>> /**  */
	or?: Array<Maybe<FilterOfTicketCategory>> /**  */
	nor?: Array<Maybe<FilterOfTicketCategory>> /**  */
	not?: Array<Maybe<FilterOfTicketCategory>> /**  */
}


/**  */
export type QueryContextOfTicketCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketCategory>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfNews = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	populationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastUpdateById?: Maybe<FilterGuidOperator> /**  */
	lastUpdateBy?: Maybe<FilterDenormalizeOfUserByLastUpdateByInNews> /**  */
	lastUpdated?: Maybe<FilterDateTimeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNews>> /**  */
	or?: Array<Maybe<FilterOfNews>> /**  */
	nor?: Array<Maybe<FilterOfNews>> /**  */
	not?: Array<Maybe<FilterOfNews>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByLastUpdateByInNews = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfNews = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNews>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité News */
export type QuerySortTypeNews = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	enable?: Maybe<Sort> /** Représente le type de trie. */
	populationIds?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdateBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastUpdated?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWarningMessage = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	populationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	warningMessageType?: Maybe<FilterWarningMessageCategoryOperator> /**  */
	warningMessageOperatorUsersIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWarningMessage>> /**  */
	or?: Array<Maybe<FilterOfWarningMessage>> /**  */
	nor?: Array<Maybe<FilterOfWarningMessage>> /**  */
	not?: Array<Maybe<FilterOfWarningMessage>> /**  */
}


/**  */
export type FilterWarningMessageCategoryOperator = {
	eq?: Maybe<WarningMessageCategory> /**  */
	ne?: Maybe<WarningMessageCategory> /**  */
	in?: Array<Maybe<WarningMessageCategory>> /**  */
	nin?: Array<Maybe<WarningMessageCategory>> /**  */
}


/**  */
export type QueryContextOfWarningMessage = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWarningMessage>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WarningMessage */
export type QuerySortTypeWarningMessage = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	enable?: Maybe<Sort> /** Représente le type de trie. */
	populationIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	warningMessageType?: Maybe<Sort> /** Représente le type de trie. */
	warningMessageOperatorUsersIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketAffectedRule = {
	categoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	populationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	applyOption?: Maybe<FilterApplyOptionOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	referentOperatorId?: Maybe<FilterGuidOperator> /**  */
	referentOperator?: Maybe<FilterDenormalizeOfUserByReferentOperatorInTicketAffectedRule> /**  */
	referentOperatorTeamId?: Maybe<FilterGuidOperator> /**  */
	referentOperatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByReferentOperatorTeamInTicketAffectedRule> /**  */
	affectedOperatorId?: Maybe<FilterGuidOperator> /**  */
	affectedOperator?: Maybe<FilterDenormalizeOfUserByAffectedOperatorInTicketAffectedRule> /**  */
	affectedOperatorTeamId?: Maybe<FilterGuidOperator> /**  */
	affectedOperatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByAffectedOperatorTeamInTicketAffectedRule> /**  */
	supportLevel?: Maybe<FilterInt32Operator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketAffectedRule>> /**  */
	or?: Array<Maybe<FilterOfTicketAffectedRule>> /**  */
	nor?: Array<Maybe<FilterOfTicketAffectedRule>> /**  */
	not?: Array<Maybe<FilterOfTicketAffectedRule>> /**  */
}


/**  */
export type FilterApplyOptionOperator = {
	eq?: Maybe<ApplyOption> /**  */
	ne?: Maybe<ApplyOption> /**  */
	in?: Array<Maybe<ApplyOption>> /**  */
	nin?: Array<Maybe<ApplyOption>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByReferentOperatorInTicketAffectedRule = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByReferentOperatorTeamInTicketAffectedRule = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByAffectedOperatorInTicketAffectedRule = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByAffectedOperatorTeamInTicketAffectedRule = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketAffectedRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketAffectedRule>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketAffectedRule */
export type QuerySortTypeTicketAffectedRule = {
	categoryIds?: Maybe<Sort> /** Représente le type de trie. */
	populationIds?: Maybe<Sort> /** Représente le type de trie. */
	applyOption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	referentOperator?: Array<Maybe<QuerySortTypeUser>> /**  */
	referentOperatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	affectedOperator?: Array<Maybe<QuerySortTypeUser>> /**  */
	affectedOperatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	supportLevel?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfInterventionModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operator?: Maybe<FilterDenormalizeOfUserByOperatorInInterventionModel> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	operatorTeam?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamInInterventionModel> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInInterventionModel> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	cost?: Maybe<FilterDoubleOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfInterventionModel>> /**  */
	or?: Array<Maybe<FilterOfInterventionModel>> /**  */
	nor?: Array<Maybe<FilterOfInterventionModel>> /**  */
	not?: Array<Maybe<FilterOfInterventionModel>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorInInterventionModel = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamInInterventionModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfActivityByActivityInInterventionModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
}


/**  */
export type QueryContextOfInterventionModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInterventionModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité InterventionModel */
export type QuerySortTypeInterventionModel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	operator?: Array<Maybe<QuerySortTypeUser>> /**  */
	operatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	elaspedTime?: Maybe<Sort> /** Représente le type de trie. */
	activity?: Array<Maybe<QuerySortTypeActivity>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	cost?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLocationCharter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLocationCharter>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LocationCharter */
export type QuerySortTypeLocationCharter = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterCategory?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterCategoryForImport?: Maybe<Sort> /** Représente le type de trie. */
	ipAddressStart?: Maybe<Sort> /** Représente le type de trie. */
	ipAddressEnd?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	user?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Maybe<Sort> /** Représente le type de trie. */
	excludeAssetIds?: Maybe<Sort> /** Représente le type de trie. */
	excludeUserIds?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterLdapAsset?: Array<Maybe<QuerySortTypeLocationCharterLdap>> /**  */
	locationCharterLdapUser?: Array<Maybe<QuerySortTypeLocationCharterLdap>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LocationCharterLdap */
export type QuerySortTypeLocationCharterLdap = {
	location?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnit?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLocationCharter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	locationCharterCategory?: Maybe<FilterLocationCharterCategoryOperator> /**  */
	locationCharterCategoryForImport?: Maybe<FilterStringOperator> /**  */
	titles?: Maybe<ArrayStringTypeOperator> /**  */
	companies?: Maybe<ArrayStringTypeOperator> /**  */
	cities?: Maybe<ArrayStringTypeOperator> /**  */
	departments?: Maybe<ArrayStringTypeOperator> /**  */
	offices?: Maybe<ArrayStringTypeOperator> /**  */
	ipAddressStart?: Maybe<FilterStringOperator> /**  */
	ipAddressEnd?: Maybe<FilterStringOperator> /**  */
	netbiosOrIpAddresses?: Maybe<ArrayStringTypeOperator> /**  */
	macAddresses?: Maybe<ArrayStringTypeOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInLocationCharter> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInLocationCharter> /**  */
	user?: Maybe<FilterBooleanOperator> /**  */
	asset?: Maybe<FilterBooleanOperator> /**  */
	ldapDistinguishedName?: Maybe<ArrayStringTypeOperator> /**  */
	azureGroupObjectIds?: Maybe<ArrayStringTypeOperator> /**  */
	excludeAssetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	excludeUserIds?: Maybe<ArrayUuidTypeOperator> /**  */
	locationCharterLdapAsset?: Maybe<FilterOfLocationCharterLdap> /**  */
	locationCharterLdapUser?: Maybe<FilterOfLocationCharterLdap> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLocationCharter>> /**  */
	or?: Array<Maybe<FilterOfLocationCharter>> /**  */
	nor?: Array<Maybe<FilterOfLocationCharter>> /**  */
	not?: Array<Maybe<FilterOfLocationCharter>> /**  */
}


/**  */
export type FilterLocationCharterCategoryOperator = {
	eq?: Maybe<LocationCharterCategory> /**  */
	ne?: Maybe<LocationCharterCategory> /**  */
	in?: Array<Maybe<LocationCharterCategory>> /**  */
	nin?: Array<Maybe<LocationCharterCategory>> /**  */
}


/**  */
export type FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInLocationCharter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInLocationCharter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterOfLocationCharterLdap = {
	location?: Maybe<FilterBooleanOperator> /**  */
	organizationalUnit?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfLocationCharterLdap>> /**  */
	or?: Array<Maybe<FilterOfLocationCharterLdap>> /**  */
	nor?: Array<Maybe<FilterOfLocationCharterLdap>> /**  */
	not?: Array<Maybe<FilterOfLocationCharterLdap>> /**  */
}


/**  */
export type FilterOfCorrectionStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
	carts?: Maybe<ArrayCorrectionCartOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	correctionById?: Maybe<FilterGuidOperator> /**  */
	correctionBy?: Maybe<FilterDenormalizeOfUserByCorrectionByInCorrectionStockMovement> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterDenormalizeOfLocationByStorageUnitInCorrectionStockMovement> /**  */
	storageUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	reasonId?: Maybe<FilterGuidOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCorrectionStockMovement>> /**  */
	or?: Array<Maybe<FilterOfCorrectionStockMovement>> /**  */
	nor?: Array<Maybe<FilterOfCorrectionStockMovement>> /**  */
	not?: Array<Maybe<FilterOfCorrectionStockMovement>> /**  */
}


/**  */
export type ArrayCorrectionCartOperator = {
	elemMatch?: Maybe<FilterOfCorrectionCart> /**  */
}


/**  */
export type FilterOfCorrectionCart = {
	amount?: Maybe<FilterInt64Operator> /**  */
	entryId?: Maybe<FilterGuidOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterOfLocation> /**  */
	cartId?: Maybe<FilterGuidOperator> /**  */
	category?: Maybe<FilterAssetModelCategoryOperator> /**  */
	oldCarts?: Maybe<FilterInt64Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCorrectionCart>> /**  */
	or?: Array<Maybe<FilterOfCorrectionCart>> /**  */
	nor?: Array<Maybe<FilterOfCorrectionCart>> /**  */
	not?: Array<Maybe<FilterOfCorrectionCart>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByCorrectionByInCorrectionStockMovement = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByStorageUnitInCorrectionStockMovement = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
}


/**  */
export type QueryContextOfCorrectionStockMovement = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCorrectionStockMovement>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CorrectionStockMovement */
export type QuerySortTypeCorrectionStockMovement = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	carts?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	correctionBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	storageUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfStockTransfer = {
	name?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	transferById?: Maybe<FilterGuidOperator> /**  */
	transferBy?: Maybe<FilterDenormalizeOfUserByTransferByInStockTransfer> /**  */
	fromStorageUnitId?: Maybe<FilterGuidOperator> /**  */
	fromStorageUnit?: Maybe<FilterDenormalizeOfLocationByFromStorageUnitInStockTransfer> /**  */
	toStorageUnitId?: Maybe<FilterGuidOperator> /**  */
	toStorageUnit?: Maybe<FilterDenormalizeOfLocationByToStorageUnitInStockTransfer> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	motifStockTransferId?: Maybe<FilterGuidOperator> /**  */
	motifStockTransfer?: Maybe<FilterDenormalizeOfStockTransferReasonByMotifStockTransferInStockTransfer> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	carts?: Maybe<ArrayOutCartOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStockTransfer>> /**  */
	or?: Array<Maybe<FilterOfStockTransfer>> /**  */
	nor?: Array<Maybe<FilterOfStockTransfer>> /**  */
	not?: Array<Maybe<FilterOfStockTransfer>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByTransferByInStockTransfer = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByFromStorageUnitInStockTransfer = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByToStorageUnitInStockTransfer = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfStockTransferReasonByMotifStockTransferInStockTransfer = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfStockTransfer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStockTransfer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité StockTransfer */
export type QuerySortTypeStockTransfer = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	transferBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	fromStorageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	toStorageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	motifStockTransfer?: Array<Maybe<QuerySortTypeStockTransferReason>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	carts?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité StockTransferReason */
export type QuerySortTypeStockTransferReason = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWallSocket = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	brokenDown?: Maybe<FilterBooleanOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInWallSocket> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWallSocket>> /**  */
	or?: Array<Maybe<FilterOfWallSocket>> /**  */
	nor?: Array<Maybe<FilterOfWallSocket>> /**  */
	not?: Array<Maybe<FilterOfWallSocket>> /**  */
}


/**  */
export type FilterDenormalizeOfLocationByLocationInWallSocket = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfWallSocket = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWallSocket>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WallSocket */
export type QuerySortTypeWallSocket = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	brokenDown?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfDistinguishedNameModel = {
	name?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDistinguishedNameModel>> /**  */
	or?: Array<Maybe<FilterOfDistinguishedNameModel>> /**  */
	nor?: Array<Maybe<FilterOfDistinguishedNameModel>> /**  */
	not?: Array<Maybe<FilterOfDistinguishedNameModel>> /**  */
}


/**  */
export type QueryContextOfDistinguishedNameModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDistinguishedNameModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DistinguishedNameModel */
export type QuerySortTypeDistinguishedNameModel = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	id?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfGroupAzureAd = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeGroupAzureAd>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité GroupAzureAd */
export type QuerySortTypeGroupAzureAd = {
	objectId?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	memberOf?: Maybe<Sort> /** Représente le type de trie. */
	parentId?: Maybe<Sort> /** Représente le type de trie. */
	members?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfStatusWorkflow = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	lastUpdateById?: Maybe<FilterGuidOperator> /**  */
	lastUpdateBy?: Maybe<FilterDenormalizeOfUserByLastUpdateByInStatusWorkflow> /**  */
	lastUpdated?: Maybe<FilterDateTimeOperator> /**  */
	copyFromId?: Maybe<FilterGuidOperator> /**  */
	config?: Maybe<FilterOfStatusConfig> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStatusWorkflow>> /**  */
	or?: Array<Maybe<FilterOfStatusWorkflow>> /**  */
	nor?: Array<Maybe<FilterOfStatusWorkflow>> /**  */
	not?: Array<Maybe<FilterOfStatusWorkflow>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByLastUpdateByInStatusWorkflow = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfStatusConfig = {
	edges?: Maybe<ArrayStatusEdgeOperator> /**  */
	nodes?: Maybe<ArrayStatusNodeOperator> /**  */
	and?: Array<Maybe<FilterOfStatusConfig>> /**  */
	or?: Array<Maybe<FilterOfStatusConfig>> /**  */
	nor?: Array<Maybe<FilterOfStatusConfig>> /**  */
	not?: Array<Maybe<FilterOfStatusConfig>> /**  */
}


/**  */
export type ArrayStatusEdgeOperator = {
	elemMatch?: Maybe<FilterOfStatusEdge> /**  */
}


/**  */
export type FilterOfStatusEdge = {
	title?: Maybe<FilterStringOperator> /**  */
	fromId?: Maybe<FilterStringOperator> /**  */
	toId?: Maybe<FilterStringOperator> /**  */
	objId?: Maybe<FilterStringOperator> /**  */
	fromPointIndex?: Maybe<FilterInt32Operator> /**  */
	toPointIndex?: Maybe<FilterInt32Operator> /**  */
	points?: Maybe<ArrayStatusPointOperator> /**  */
	and?: Array<Maybe<FilterOfStatusEdge>> /**  */
	or?: Array<Maybe<FilterOfStatusEdge>> /**  */
	nor?: Array<Maybe<FilterOfStatusEdge>> /**  */
	not?: Array<Maybe<FilterOfStatusEdge>> /**  */
}


/**  */
export type ArrayStatusPointOperator = {
	elemMatch?: Maybe<FilterOfStatusPoint> /**  */
}


/**  */
export type FilterOfStatusPoint = {
	x?: Maybe<FilterInt32Operator> /**  */
	y?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfStatusPoint>> /**  */
	or?: Array<Maybe<FilterOfStatusPoint>> /**  */
	nor?: Array<Maybe<FilterOfStatusPoint>> /**  */
	not?: Array<Maybe<FilterOfStatusPoint>> /**  */
}


/**  */
export type ArrayStatusNodeOperator = {
	elemMatch?: Maybe<FilterOfStatusNode> /**  */
}


/**  */
export type FilterOfStatusNode = {
	isEntry?: Maybe<FilterBooleanOperator> /**  */
	isEnding?: Maybe<FilterBooleanOperator> /**  */
	isTakingCharge?: Maybe<FilterBooleanOperator> /**  */
	isEndTreatment?: Maybe<FilterBooleanOperator> /**  */
	isCommentaryRequired?: Maybe<FilterBooleanOperator> /**  */
	canAddSatisfaction?: Maybe<FilterBooleanOperator> /**  */
	isForbiddenPredecessor?: Maybe<FilterBooleanOperator> /**  */
	objId?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	x?: Maybe<FilterInt32Operator> /**  */
	y?: Maybe<FilterInt32Operator> /**  */
	rightToMove?: Maybe<FilterOfStatusMoveRight> /**  */
	and?: Array<Maybe<FilterOfStatusNode>> /**  */
	or?: Array<Maybe<FilterOfStatusNode>> /**  */
	nor?: Array<Maybe<FilterOfStatusNode>> /**  */
	not?: Array<Maybe<FilterOfStatusNode>> /**  */
}


/**  */
export type FilterOfStatusMoveRight = {
	allOperator?: Maybe<FilterBooleanOperator> /**  */
	referentTeam?: Maybe<FilterBooleanOperator> /**  */
	affectedTeam?: Maybe<FilterBooleanOperator> /**  */
	allConcerned?: Maybe<FilterBooleanOperator> /**  */
	makeRequest?: Maybe<FilterBooleanOperator> /**  */
	affected?: Maybe<FilterBooleanOperator> /**  */
	operatorAffected?: Maybe<FilterBooleanOperator> /**  */
	operatorReferent?: Maybe<FilterBooleanOperator> /**  */
	manager?: Maybe<FilterBooleanOperator> /**  */
	allTaskFinished?: Maybe<FilterBooleanOperator> /**  */
	customUserIds?: Maybe<ArrayUuidTypeOperator> /**  */
	and?: Array<Maybe<FilterOfStatusMoveRight>> /**  */
	or?: Array<Maybe<FilterOfStatusMoveRight>> /**  */
	nor?: Array<Maybe<FilterOfStatusMoveRight>> /**  */
	not?: Array<Maybe<FilterOfStatusMoveRight>> /**  */
}


/**  */
export type QueryContextOfStatusWorkflow = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStatusWorkflow>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfInheritLocationCharter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfOperatorTeam = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketStatusReasonIds?: Maybe<ArrayUuidTypeOperator> /**  */
	default?: Maybe<FilterBooleanOperator> /**  */
	allowedTicketsCategories?: Maybe<ArrayAllowedEntityOperator> /**  */
	securityGroupId?: Maybe<FilterGuidOperator> /**  */
	ticketCategoryVisible?: Maybe<FilterBooleanOperator> /**  */
	ticketCategoryAllowed?: Maybe<FilterBooleanOperator> /**  */
	allowedOperatorTeamIds?: Maybe<ArrayAllowedEntityOperator> /**  */
	allowSelection?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOperatorTeam>> /**  */
	or?: Array<Maybe<FilterOfOperatorTeam>> /**  */
	nor?: Array<Maybe<FilterOfOperatorTeam>> /**  */
	not?: Array<Maybe<FilterOfOperatorTeam>> /**  */
}


/**  */
export type QueryContextOfOperatorTeam = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOperatorTeam>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfArticleKnowledgeNote = {
	userId?: Maybe<FilterGuidOperator> /**  */
	rating?: Maybe<FilterDoubleOperator> /**  */
	articleKnowledgeId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfArticleKnowledgeNote>> /**  */
	or?: Array<Maybe<FilterOfArticleKnowledgeNote>> /**  */
	nor?: Array<Maybe<FilterOfArticleKnowledgeNote>> /**  */
	not?: Array<Maybe<FilterOfArticleKnowledgeNote>> /**  */
}


/**  */
export type QueryContextOfArticleKnowledgeNote = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeArticleKnowledgeNote>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ArticleKnowledgeNote */
export type QuerySortTypeArticleKnowledgeNote = {
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	rating?: Maybe<Sort> /** Représente le type de trie. */
	articleKnowledge?: Array<Maybe<QuerySortTypeArticleKnowledge>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfDashboard = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	fileName?: Maybe<FilterStringOperator> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	updated?: Maybe<FilterBooleanOperator> /**  */
	serviceDesk?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	timerAutoRefresh?: Maybe<FilterInt32Operator> /**  */
	autoRefresh?: Maybe<FilterBooleanOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDashboard>> /**  */
	or?: Array<Maybe<FilterOfDashboard>> /**  */
	nor?: Array<Maybe<FilterOfDashboard>> /**  */
	not?: Array<Maybe<FilterOfDashboard>> /**  */
}


/**  */
export type QueryContextOfDashboard = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDashboard>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Dashboard */
export type QuerySortTypeDashboard = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	version?: Maybe<Sort> /** Représente le type de trie. */
	fileName?: Maybe<Sort> /** Représente le type de trie. */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	updated?: Maybe<Sort> /** Représente le type de trie. */
	serviceDesk?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	timerAutoRefresh?: Maybe<Sort> /** Représente le type de trie. */
	autoRefresh?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfFormDesigner = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	json?: Maybe<FilterStringOperator> /**  */
	formDesignerTo?: Maybe<FilterFormDesignerToOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFormDesigner>> /**  */
	or?: Array<Maybe<FilterOfFormDesigner>> /**  */
	nor?: Array<Maybe<FilterOfFormDesigner>> /**  */
	not?: Array<Maybe<FilterOfFormDesigner>> /**  */
}


/**  */
export type FilterFormDesignerToOperator = {
	eq?: Maybe<FormDesignerTo> /**  */
	ne?: Maybe<FormDesignerTo> /**  */
	in?: Array<Maybe<FormDesignerTo>> /**  */
	nin?: Array<Maybe<FormDesignerTo>> /**  */
}


/**  */
export type QueryContextOfFormDesigner = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFormDesigner>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité FormDesigner */
export type QuerySortTypeFormDesigner = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	json?: Maybe<Sort> /** Représente le type de trie. */
	formDesignerTo?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLdapAnalyse = {
	ldapObjectClassType?: Maybe<FilterLdapObjectClassTypeOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInLdapAnalyse> /**  */
	ldapObject?: Maybe<FilterOfLdapOrganizationalUnit> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	user?: Maybe<FilterDenormalizeOfUserByUserInLdapAnalyse> /**  */
	find?: Maybe<FilterBooleanOperator> /**  */
	ldapAnalyseFindType?: Maybe<FilterLdapAnalyseFindTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	ldapAnalyseProcess?: Maybe<FilterOfLdapAnalyseProcess> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	azureObject?: Maybe<FilterOfUserAzureAD> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapAnalyse>> /**  */
	or?: Array<Maybe<FilterOfLdapAnalyse>> /**  */
	nor?: Array<Maybe<FilterOfLdapAnalyse>> /**  */
	not?: Array<Maybe<FilterOfLdapAnalyse>> /**  */
}


/**  */
export type FilterLdapObjectClassTypeOperator = {
	eq?: Maybe<LdapObjectClassType> /**  */
	ne?: Maybe<LdapObjectClassType> /**  */
	in?: Array<Maybe<LdapObjectClassType>> /**  */
	nin?: Array<Maybe<LdapObjectClassType>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInLdapAnalyse = {
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	assetStatus?: Maybe<FilterDenormalizeOfAssetStatusByAssetStatusInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	fqdn?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastAudit?: Maybe<FilterDateTimeOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInAsset> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInAsset> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterOfLdapOrganizationalUnit = {
	name?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	ldapObjectClassType?: Maybe<FilterLdapObjectClassTypeOperator> /**  */
	lastUpdate?: Maybe<FilterDateTimeOperator> /**  */
	scanByLdapId?: Maybe<FilterGuidOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	userAccountControl?: Maybe<FilterInt32Operator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapOrganizationalUnit>> /**  */
	or?: Array<Maybe<FilterOfLdapOrganizationalUnit>> /**  */
	nor?: Array<Maybe<FilterOfLdapOrganizationalUnit>> /**  */
	not?: Array<Maybe<FilterOfLdapOrganizationalUnit>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserInLdapAnalyse = {
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	registrationNumber?: Maybe<FilterStringOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInUser> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInUser> /**  */
	principalPhone?: Maybe<FilterStringOperator> /**  */
	principalMobile?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterLdapAnalyseFindTypeOperator = {
	eq?: Maybe<LdapAnalyseFindType> /**  */
	ne?: Maybe<LdapAnalyseFindType> /**  */
	in?: Array<Maybe<LdapAnalyseFindType>> /**  */
	nin?: Array<Maybe<LdapAnalyseFindType>> /**  */
}


/**  */
export type FilterOfLdapAnalyseProcess = {
	start?: Maybe<FilterDateTimeOperator> /**  */
	total?: Maybe<FilterInt32Operator> /**  */
	analyse?: Maybe<FilterInt32Operator> /**  */
	progressValue?: Maybe<FilterInt32Operator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	cancel?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfLdapAnalyseProcess>> /**  */
	or?: Array<Maybe<FilterOfLdapAnalyseProcess>> /**  */
	nor?: Array<Maybe<FilterOfLdapAnalyseProcess>> /**  */
	not?: Array<Maybe<FilterOfLdapAnalyseProcess>> /**  */
}


/**  */
export type FilterOfUserAzureAD = {
	userAccountDisabled?: Maybe<FilterBooleanOperator> /**  */
	azureObjectId?: Maybe<FilterStringOperator> /**  */
	managerAzureObjectId?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	firstName?: Maybe<FilterStringOperator> /**  */
	lastName?: Maybe<FilterStringOperator> /**  */
	userPrincipalName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	department?: Maybe<FilterStringOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	officeName?: Maybe<FilterStringOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	principalMobile?: Maybe<FilterStringOperator> /**  */
	principalFax?: Maybe<FilterStringOperator> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	phones?: Maybe<ArrayPhoneOperator> /**  */
	memberOfAzureGroup?: Maybe<ArrayGroupAzureAdOperator> /**  */
	azureLastLogOnInteractive?: Maybe<FilterDateTimeOperator> /**  */
	azureLastLogOnNotInteractive?: Maybe<FilterDateTimeOperator> /**  */
	and?: Array<Maybe<FilterOfUserAzureAD>> /**  */
	or?: Array<Maybe<FilterOfUserAzureAD>> /**  */
	nor?: Array<Maybe<FilterOfUserAzureAD>> /**  */
	not?: Array<Maybe<FilterOfUserAzureAD>> /**  */
}


/**  */
export type QueryContextOfLdapAnalyse = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLdapAnalyse>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LdapAnalyse */
export type QuerySortTypeLdapAnalyse = {
	ldapObjectClassType?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	ldapObject?: Array<Maybe<QuerySortTypeLdapOrganizationalUnit>> /**  */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	find?: Maybe<Sort> /** Représente le type de trie. */
	ldapAnalyseFindType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	ldapAnalyseProcess?: Array<Maybe<QuerySortTypeLdapAnalyseProcess>> /**  */
	azureObject?: Array<Maybe<QuerySortTypeUserAzureAD>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LdapOrganizationalUnit */
export type QuerySortTypeLdapOrganizationalUnit = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	ldapObjectClassType?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdate?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControl?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LdapAnalyseProcess */
export type QuerySortTypeLdapAnalyseProcess = {
	start?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	analyse?: Maybe<Sort> /** Représente le type de trie. */
	progressValue?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	cancel?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité UserAzureAD */
export type QuerySortTypeUserAzureAD = {
	userAccountDisabled?: Maybe<Sort> /** Représente le type de trie. */
	azureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	managerAzureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	firstName?: Maybe<Sort> /** Représente le type de trie. */
	lastName?: Maybe<Sort> /** Représente le type de trie. */
	userPrincipalName?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	department?: Maybe<Sort> /** Représente le type de trie. */
	company?: Maybe<Sort> /** Représente le type de trie. */
	officeName?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	principalMobile?: Maybe<Sort> /** Représente le type de trie. */
	principalFax?: Maybe<Sort> /** Représente le type de trie. */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	phones?: Maybe<Sort> /** Représente le type de trie. */
	memberOfAzureGroup?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnInteractive?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnNotInteractive?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfModelRulesDesigner = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	rules?: Maybe<ArrayModelRuleOperator> /**  */
	editRules?: Maybe<ArrayModelRuleOperator> /**  */
	taskRules?: Maybe<ArrayModelRuleOperator> /**  */
	taskEditRules?: Maybe<ArrayModelRuleOperator> /**  */
	interventionRules?: Maybe<ArrayModelRuleOperator> /**  */
	interventionEditRules?: Maybe<ArrayModelRuleOperator> /**  */
	escalationRules?: Maybe<ArrayModelRuleOperator> /**  */
	escalationEditRules?: Maybe<ArrayModelRuleOperator> /**  */
	dynamicFieldRules?: Maybe<ArrayModelRuleOperator> /**  */
	dynamicFieldEditRules?: Maybe<ArrayModelRuleOperator> /**  */
	operatorIds?: Maybe<ArrayUuidTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfModelRulesDesigner>> /**  */
	or?: Array<Maybe<FilterOfModelRulesDesigner>> /**  */
	nor?: Array<Maybe<FilterOfModelRulesDesigner>> /**  */
	not?: Array<Maybe<FilterOfModelRulesDesigner>> /**  */
}


/**  */
export type ArrayModelRuleOperator = {
	elemMatch?: Maybe<FilterOfModelRule> /**  */
}


/**  */
export type FilterOfModelRule = {
	title?: Maybe<FilterStringOperator> /**  */
	translatedTitle?: Maybe<FilterOfTranslatedField> /**  */
	fieldName?: Maybe<FilterStringOperator> /**  */
	editable?: Maybe<FilterBooleanOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	required?: Maybe<FilterBooleanOperator> /**  */
	option?: Maybe<FilterOfModelRuleOption> /**  */
	statusIds?: Maybe<ArrayUuidTypeOperator> /**  */
	and?: Array<Maybe<FilterOfModelRule>> /**  */
	or?: Array<Maybe<FilterOfModelRule>> /**  */
	nor?: Array<Maybe<FilterOfModelRule>> /**  */
	not?: Array<Maybe<FilterOfModelRule>> /**  */
}


/**  */
export type FilterOfModelRuleOption = {
	allowEdit?: Maybe<FilterBooleanOperator> /**  */
	allowRequire?: Maybe<FilterBooleanOperator> /**  */
	allowStatus?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfModelRuleOption>> /**  */
	or?: Array<Maybe<FilterOfModelRuleOption>> /**  */
	nor?: Array<Maybe<FilterOfModelRuleOption>> /**  */
	not?: Array<Maybe<FilterOfModelRuleOption>> /**  */
}


/**  */
export type QueryContextOfModelRulesDesigner = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeModelRulesDesigner>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ModelRulesDesigner */
export type QuerySortTypeModelRulesDesigner = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	rules?: Maybe<Sort> /** Représente le type de trie. */
	editRules?: Maybe<Sort> /** Représente le type de trie. */
	taskRules?: Maybe<Sort> /** Représente le type de trie. */
	taskEditRules?: Maybe<Sort> /** Représente le type de trie. */
	interventionRules?: Maybe<Sort> /** Représente le type de trie. */
	interventionEditRules?: Maybe<Sort> /** Représente le type de trie. */
	escalationRules?: Maybe<Sort> /** Représente le type de trie. */
	escalationEditRules?: Maybe<Sort> /** Représente le type de trie. */
	dynamicFieldRules?: Maybe<Sort> /** Représente le type de trie. */
	dynamicFieldEditRules?: Maybe<Sort> /** Représente le type de trie. */
	operatorIds?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfNotification = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	text?: Maybe<FilterOfTranslatedField> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	user?: Maybe<FilterDenormalizeOfUserByUserInNotification> /**  */
	isRead?: Maybe<FilterBooleanOperator> /**  */
	urlKey?: Maybe<FilterNotificationKeyOperator> /**  */
	urlObjectId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNotification>> /**  */
	or?: Array<Maybe<FilterOfNotification>> /**  */
	nor?: Array<Maybe<FilterOfNotification>> /**  */
	not?: Array<Maybe<FilterOfNotification>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserInNotification = {
	email?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterNotificationKeyOperator = {
	eq?: Maybe<NotificationKey> /**  */
	ne?: Maybe<NotificationKey> /**  */
	in?: Array<Maybe<NotificationKey>> /**  */
	nin?: Array<Maybe<NotificationKey>> /**  */
}


/**  */
export type QueryContextOfNotification = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNotification>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Notification */
export type QuerySortTypeNotification = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	text?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	isRead?: Maybe<Sort> /** Représente le type de trie. */
	urlKey?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTaskStatusWorkflow = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	lastUpdateById?: Maybe<FilterGuidOperator> /**  */
	lastUpdateBy?: Maybe<FilterDenormalizeOfUserByLastUpdateByInTaskStatusWorkflow> /**  */
	lastUpdated?: Maybe<FilterDateTimeOperator> /**  */
	config?: Maybe<FilterOfStatusConfig> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTaskStatusWorkflow>> /**  */
	or?: Array<Maybe<FilterOfTaskStatusWorkflow>> /**  */
	nor?: Array<Maybe<FilterOfTaskStatusWorkflow>> /**  */
	not?: Array<Maybe<FilterOfTaskStatusWorkflow>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByLastUpdateByInTaskStatusWorkflow = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfTaskStatusWorkflow = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTaskStatusWorkflow>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TaskStatusWorkflow */
export type QuerySortTypeTaskStatusWorkflow = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	enable?: Maybe<Sort> /** Représente le type de trie. */
	lastUpdateBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	lastUpdated?: Maybe<Sort> /** Représente le type de trie. */
	config?: Array<Maybe<QuerySortTypeStatusConfig>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflow = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	delay?: Maybe<FilterTimeSpanOperator> /**  */
	delayConfig?: Maybe<FilterOfWorkflowDelay> /**  */
	intervalConfig?: Maybe<FilterOfWorkflowDelay> /**  */
	enableDelay?: Maybe<FilterBooleanOperator> /**  */
	enableInterval?: Maybe<FilterBooleanOperator> /**  */
	priority?: Maybe<FilterInt32Operator> /**  */
	lastUpdateById?: Maybe<FilterGuidOperator> /**  */
	lastUpdateBy?: Maybe<FilterDenormalizeOfUserByLastUpdateByInWorkflow> /**  */
	lastUpdated?: Maybe<FilterDateTimeOperator> /**  */
	managedChooseForApply?: Maybe<FilterUserOrAssetOperator> /**  */
	copyFromId?: Maybe<FilterGuidOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflow>> /**  */
	or?: Array<Maybe<FilterOfWorkflow>> /**  */
	nor?: Array<Maybe<FilterOfWorkflow>> /**  */
	not?: Array<Maybe<FilterOfWorkflow>> /**  */
}


/**  */
export type FilterOfWorkflowDelay = {
	key?: Maybe<FilterStringOperator> /**  */
	displayName?: Maybe<FilterOfTranslatedField> /**  */
	property?: Maybe<FilterStringOperator> /**  */
	delay?: Maybe<FilterTimeSpanOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowDelay>> /**  */
	or?: Array<Maybe<FilterOfWorkflowDelay>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowDelay>> /**  */
	not?: Array<Maybe<FilterOfWorkflowDelay>> /**  */
}


/**  */
export type FilterDenormalizeOfUserByLastUpdateByInWorkflow = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterUserOrAssetOperator = {
	eq?: Maybe<UserOrAsset> /**  */
	ne?: Maybe<UserOrAsset> /**  */
	in?: Array<Maybe<UserOrAsset>> /**  */
	nin?: Array<Maybe<UserOrAsset>> /**  */
}


/**  */
export type QueryContextOfWorkflow = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflow>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfCommand = {
	category?: Maybe<FilterCommandCategoryOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	pathToExecutable?: Maybe<FilterStringOperator> /**  */
	pathToParamterFile?: Maybe<FilterStringOperator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	commandLine?: Maybe<FilterStringOperator> /**  */
	url?: Maybe<FilterStringOperator> /**  */
	protocole?: Maybe<FilterCommandProtocoleOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	exceptionAssetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dataCommand?: Maybe<FilterStringOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	commandAssetCategories?: Maybe<ArrayCommandAssetCategoryOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCommand>> /**  */
	or?: Array<Maybe<FilterOfCommand>> /**  */
	nor?: Array<Maybe<FilterOfCommand>> /**  */
	not?: Array<Maybe<FilterOfCommand>> /**  */
}


/**  */
export type FilterCommandCategoryOperator = {
	eq?: Maybe<CommandCategory> /**  */
	ne?: Maybe<CommandCategory> /**  */
	in?: Array<Maybe<CommandCategory>> /**  */
	nin?: Array<Maybe<CommandCategory>> /**  */
}


/**  */
export type FilterCommandProtocoleOperator = {
	eq?: Maybe<CommandProtocole> /**  */
	ne?: Maybe<CommandProtocole> /**  */
	in?: Array<Maybe<CommandProtocole>> /**  */
	nin?: Array<Maybe<CommandProtocole>> /**  */
}


/**  */
export type ArrayCommandAssetCategoryOperator = {
	elemMatch?: Maybe<FilterOfCommandAssetCategory> /**  */
}


/**  */
export type FilterOfCommandAssetCategory = {
	id?: Maybe<FilterGuidOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	active?: Maybe<FilterBooleanOperator> /**  */
	default?: Maybe<FilterBooleanOperator> /**  */
	automatique?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfCommandAssetCategory>> /**  */
	or?: Array<Maybe<FilterOfCommandAssetCategory>> /**  */
	nor?: Array<Maybe<FilterOfCommandAssetCategory>> /**  */
	not?: Array<Maybe<FilterOfCommandAssetCategory>> /**  */
}


/**  */
export type QueryContextOfCommand = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCommand>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Command */
export type QuerySortTypeCommand = {
	category?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	pathToExecutable?: Maybe<Sort> /** Représente le type de trie. */
	pathToParamterFile?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	commandLine?: Maybe<Sort> /** Représente le type de trie. */
	url?: Maybe<Sort> /** Représente le type de trie. */
	protocole?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	exceptionAssetIds?: Maybe<Sort> /** Représente le type de trie. */
	dataCommand?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	commandAssetCategories?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfConcernedProject = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfConcernedProject>> /**  */
	or?: Array<Maybe<FilterOfConcernedProject>> /**  */
	nor?: Array<Maybe<FilterOfConcernedProject>> /**  */
	not?: Array<Maybe<FilterOfConcernedProject>> /**  */
}


/**  */
export type QueryContextOfConcernedProject = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeConcernedProject>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfCalendarConnector = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	exchangeCalendar?: Maybe<FilterOfExchangeCalendar> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	logon?: Maybe<FilterStringOperator> /**  */
	mailBox?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	server?: Maybe<FilterStringOperator> /**  */
	useAutodiscoverUrl?: Maybe<FilterBooleanOperator> /**  */
	applicationAzureAdId?: Maybe<FilterGuidOperator> /**  */
	accessToken?: Maybe<FilterStringOperator> /**  */
	expireAccesToken?: Maybe<FilterDateTimeOperator> /**  */
	azureToken?: Maybe<FilterStringOperator> /**  */
	authAzurePassword?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<FilterExchangeConnectorTypeEnumOperator> /**  */
	and?: Array<Maybe<FilterOfCalendarConnector>> /**  */
	or?: Array<Maybe<FilterOfCalendarConnector>> /**  */
	nor?: Array<Maybe<FilterOfCalendarConnector>> /**  */
	not?: Array<Maybe<FilterOfCalendarConnector>> /**  */
}


/**  */
export type FilterOfExchangeCalendar = {
	name?: Maybe<FilterStringOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	calendarId?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfExchangeCalendar>> /**  */
	or?: Array<Maybe<FilterOfExchangeCalendar>> /**  */
	nor?: Array<Maybe<FilterOfExchangeCalendar>> /**  */
	not?: Array<Maybe<FilterOfExchangeCalendar>> /**  */
}


/**  */
export type FilterExchangeConnectorTypeEnumOperator = {
	eq?: Maybe<ExchangeConnectorTypeEnum> /**  */
	ne?: Maybe<ExchangeConnectorTypeEnum> /**  */
	in?: Array<Maybe<ExchangeConnectorTypeEnum>> /**  */
	nin?: Array<Maybe<ExchangeConnectorTypeEnum>> /**  */
}


/**  */
export type QueryContextOfCalendarConnector = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCalendarConnector>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfExchangeConnector = {
	exchangeCalendar?: Maybe<FilterOfExchangeCalendar> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	logon?: Maybe<FilterStringOperator> /**  */
	mailBox?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	server?: Maybe<FilterStringOperator> /**  */
	useAutodiscoverUrl?: Maybe<FilterBooleanOperator> /**  */
	applicationAzureAdId?: Maybe<FilterGuidOperator> /**  */
	accessToken?: Maybe<FilterStringOperator> /**  */
	expireAccesToken?: Maybe<FilterDateTimeOperator> /**  */
	azureToken?: Maybe<FilterStringOperator> /**  */
	authAzurePassword?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<FilterExchangeConnectorTypeEnumOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfExchangeConnector>> /**  */
	or?: Array<Maybe<FilterOfExchangeConnector>> /**  */
	nor?: Array<Maybe<FilterOfExchangeConnector>> /**  */
	not?: Array<Maybe<FilterOfExchangeConnector>> /**  */
}


/**  */
export type QueryContextOfExchangeConnector = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeExchangeConnector>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ExchangeConnector */
export type QuerySortTypeExchangeConnector = {
	exchangeCalendar?: Array<Maybe<QuerySortTypeExchangeCalendar>> /**  */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	logon?: Maybe<Sort> /** Représente le type de trie. */
	mailBox?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	server?: Maybe<Sort> /** Représente le type de trie. */
	useAutodiscoverUrl?: Maybe<Sort> /** Représente le type de trie. */
	accessToken?: Maybe<Sort> /** Représente le type de trie. */
	expireAccesToken?: Maybe<Sort> /** Représente le type de trie. */
	applicationAzureAd?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /**  */
	azureToken?: Maybe<Sort> /** Représente le type de trie. */
	authAzurePassword?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfOrganizationalUnit = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	error?: Maybe<FilterBooleanOperator> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	manager?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	path?: Maybe<FilterOfTranslatedField> /**  */
	templateId?: Maybe<FilterGuidOperator> /**  */
	allRulesMustBeValidAsset?: Maybe<FilterBooleanOperator> /**  */
	allRulesMustBeValidUser?: Maybe<FilterBooleanOperator> /**  */
	scanByLdapId?: Maybe<FilterGuidOperator> /**  */
	firstImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	lastImportLdap?: Maybe<FilterDateTimeOperator> /**  */
	parentNodes?: Maybe<ArrayStringTypeOperator> /**  */
	parentIds?: Maybe<ArrayUuidTypeOperator> /**  */
	budgetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	allowSelection?: Maybe<FilterBooleanOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrganizationalUnit>> /**  */
	or?: Array<Maybe<FilterOfOrganizationalUnit>> /**  */
	nor?: Array<Maybe<FilterOfOrganizationalUnit>> /**  */
	not?: Array<Maybe<FilterOfOrganizationalUnit>> /**  */
}


/**  */
export type QueryContextOfOrganizationalUnit = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfMessageModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMessageModel>> /**  */
	or?: Array<Maybe<FilterOfMessageModel>> /**  */
	nor?: Array<Maybe<FilterOfMessageModel>> /**  */
	not?: Array<Maybe<FilterOfMessageModel>> /**  */
}


/**  */
export type QueryContextOfMessageModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMessageModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité MessageModel */
export type QuerySortTypeMessageModel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfActivity = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfActivity>> /**  */
	or?: Array<Maybe<FilterOfActivity>> /**  */
	nor?: Array<Maybe<FilterOfActivity>> /**  */
	not?: Array<Maybe<FilterOfActivity>> /**  */
}


/**  */
export type QueryContextOfActivity = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeActivity>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfChannel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfChannel>> /**  */
	or?: Array<Maybe<FilterOfChannel>> /**  */
	nor?: Array<Maybe<FilterOfChannel>> /**  */
	not?: Array<Maybe<FilterOfChannel>> /**  */
}


/**  */
export type QueryContextOfChannel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeChannel>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfCommentaryModel = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	type?: Maybe<ArrayStringTypeOperator> /**  */
	commentarySupportLevelIds?: Maybe<ArrayIntTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	usageType?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCommentaryModel>> /**  */
	or?: Array<Maybe<FilterOfCommentaryModel>> /**  */
	nor?: Array<Maybe<FilterOfCommentaryModel>> /**  */
	not?: Array<Maybe<FilterOfCommentaryModel>> /**  */
}


/**  */
export type QueryContextOfCommentaryModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCommentaryModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CommentaryModel */
export type QuerySortTypeCommentaryModel = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfImpact = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfImpact>> /**  */
	or?: Array<Maybe<FilterOfImpact>> /**  */
	nor?: Array<Maybe<FilterOfImpact>> /**  */
	not?: Array<Maybe<FilterOfImpact>> /**  */
}


/**  */
export type QueryContextOfImpact = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeImpact>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfOrigin = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrigin>> /**  */
	or?: Array<Maybe<FilterOfOrigin>> /**  */
	nor?: Array<Maybe<FilterOfOrigin>> /**  */
	not?: Array<Maybe<FilterOfOrigin>> /**  */
}


/**  */
export type QueryContextOfOrigin = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrigin>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTaskSequence = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	taskIds?: Maybe<ArrayUuidTypeOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	activityId?: Maybe<FilterGuidOperator> /**  */
	activity?: Maybe<FilterDenormalizeOfActivityByActivityInTaskSequence> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTaskSequence>> /**  */
	or?: Array<Maybe<FilterOfTaskSequence>> /**  */
	nor?: Array<Maybe<FilterOfTaskSequence>> /**  */
	not?: Array<Maybe<FilterOfTaskSequence>> /**  */
}


/**  */
export type FilterDenormalizeOfActivityByActivityInTaskSequence = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
}


/**  */
export type QueryContextOfTaskSequence = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTaskSequence>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfUrgency = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUrgency>> /**  */
	or?: Array<Maybe<FilterOfUrgency>> /**  */
	nor?: Array<Maybe<FilterOfUrgency>> /**  */
	not?: Array<Maybe<FilterOfUrgency>> /**  */
}


/**  */
export type QueryContextOfUrgency = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUrgency>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSecurityGroupRule = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	assetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	securityGroupIds?: Maybe<ArrayUuidTypeOperator> /**  */
	disableFilterLocation?: Maybe<FilterBooleanOperator> /**  */
	disableFilterOrganizationalUnit?: Maybe<FilterBooleanOperator> /**  */
	disableFilterAssetCategory?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSecurityGroupRule>> /**  */
	or?: Array<Maybe<FilterOfSecurityGroupRule>> /**  */
	nor?: Array<Maybe<FilterOfSecurityGroupRule>> /**  */
	not?: Array<Maybe<FilterOfSecurityGroupRule>> /**  */
}


/**  */
export type QueryContextOfSecurityGroupRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSecurityGroupRule>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SecurityGroupRule */
export type QuerySortTypeSecurityGroupRule = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	locationIds?: Maybe<Sort> /** Représente le type de trie. */
	organizationalUnitIds?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroupIds?: Maybe<Sort> /** Représente le type de trie. */
	disableFilterLocation?: Maybe<Sort> /** Représente le type de trie. */
	disableFilterOrganizationalUnit?: Maybe<Sort> /** Représente le type de trie. */
	disableFilterAssetCategory?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSftpConnector = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	server?: Maybe<FilterStringOperator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	remoteFolder?: Maybe<FilterStringOperator> /**  */
	environnement?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSftpConnector>> /**  */
	or?: Array<Maybe<FilterOfSftpConnector>> /**  */
	nor?: Array<Maybe<FilterOfSftpConnector>> /**  */
	not?: Array<Maybe<FilterOfSftpConnector>> /**  */
}


/**  */
export type QueryContextOfSftpConnector = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSftpConnector>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetProperty = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAssetProperty> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	displayName?: Maybe<FilterStringOperator> /**  */
	instanceGuid?: Maybe<FilterStringOperator> /**  */
	pathToSignedProductExe?: Maybe<FilterStringOperator> /**  */
	pathToSignedReportingExe?: Maybe<FilterStringOperator> /**  */
	productState?: Maybe<FilterInt64Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	caption?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	product?: Maybe<FilterStringOperator> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	sku?: Maybe<FilterStringOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	batteryRechargeTime?: Maybe<FilterInt64Operator> /**  */
	batteryStatus?: Maybe<FilterBatteryStatusOperator> /**  */
	chemistry?: Maybe<FilterChemistryOperator> /**  */
	designCapacity?: Maybe<FilterInt64Operator> /**  */
	designVoltage?: Maybe<FilterInt64Operator> /**  */
	deviceId?: Maybe<FilterStringOperator> /**  */
	estimatedChargeRemaining?: Maybe<FilterInt64Operator> /**  */
	estimatedRunTime?: Maybe<FilterInt64Operator> /**  */
	expectedBatteryLife?: Maybe<FilterInt64Operator> /**  */
	expectedLife?: Maybe<FilterInt64Operator> /**  */
	fullChargeCapacity?: Maybe<FilterInt64Operator> /**  */
	maxRechargeTime?: Maybe<FilterInt64Operator> /**  */
	pnpDeviceId?: Maybe<FilterStringOperator> /**  */
	timeOnBattery?: Maybe<FilterInt64Operator> /**  */
	timeToFullCharge?: Maybe<FilterInt64Operator> /**  */
	buildNumber?: Maybe<FilterStringOperator> /**  */
	releaseDate?: Maybe<FilterDateTimeOperator> /**  */
	smBiosVersion?: Maybe<FilterStringOperator> /**  */
	smBiosMajorVersion?: Maybe<FilterInt64Operator> /**  */
	smBiosMinorVersion?: Maybe<FilterInt64Operator> /**  */
	smBiosPresent?: Maybe<FilterBooleanOperator> /**  */
	bootDirectory?: Maybe<FilterStringOperator> /**  */
	configurationPath?: Maybe<FilterStringOperator> /**  */
	lastDrive?: Maybe<FilterStringOperator> /**  */
	scratchDirectory?: Maybe<FilterStringOperator> /**  */
	settingId?: Maybe<FilterStringOperator> /**  */
	tempDirectory?: Maybe<FilterStringOperator> /**  */
	drive?: Maybe<FilterStringOperator> /**  */
	mediaType?: Maybe<FilterStringOperator> /**  */
	creationDate?: Maybe<FilterDateTimeOperator> /**  */
	fileName?: Maybe<FilterStringOperator> /**  */
	fileSize?: Maybe<FilterInt64Operator> /**  */
	fileType?: Maybe<FilterStringOperator> /**  */
	group?: Maybe<FilterStringOperator> /**  */
	path?: Maybe<FilterStringOperator> /**  */
	adminPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	automaticManagedPagefile?: Maybe<FilterBooleanOperator> /**  */
	automaticResetBootOption?: Maybe<FilterBooleanOperator> /**  */
	automaticResetCapability?: Maybe<FilterBooleanOperator> /**  */
	bootOptionOnLimit?: Maybe<FilterInt64Operator> /**  */
	bootOptionOnWatchDog?: Maybe<FilterInt64Operator> /**  */
	bootRomSupported?: Maybe<FilterBooleanOperator> /**  */
	bootupState?: Maybe<FilterStringOperator> /**  */
	chassisBootupState?: Maybe<FilterInt64Operator> /**  */
	currentTimeZone?: Maybe<FilterInt32Operator> /**  */
	daylightInEffect?: Maybe<FilterBooleanOperator> /**  */
	dnsHostName?: Maybe<FilterStringOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	domainRole?: Maybe<FilterDomainRoleOperator> /**  */
	enableDaylightSavingsTime?: Maybe<FilterBooleanOperator> /**  */
	frontPanelResetStatus?: Maybe<FilterInt64Operator> /**  */
	infraredSupported?: Maybe<FilterBooleanOperator> /**  */
	initialLoadInfo?: Maybe<FilterStringOperator> /**  */
	keyboardPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	lastLoadInfo?: Maybe<FilterStringOperator> /**  */
	nameFormat?: Maybe<FilterStringOperator> /**  */
	networkServerModeEnabled?: Maybe<FilterBooleanOperator> /**  */
	numberOfLogicalProcessors?: Maybe<FilterInt64Operator> /**  */
	numberOfProcessors?: Maybe<FilterInt64Operator> /**  */
	oem?: Maybe<ArrayStringTypeOperator> /**  */
	partOfDomain?: Maybe<FilterBooleanOperator> /**  */
	pauseAfterReset?: Maybe<FilterInt64Operator> /**  */
	pcSystemType?: Maybe<FilterPcSystemTypeOperator> /**  */
	powerManagementSupported?: Maybe<FilterBooleanOperator> /**  */
	powerOnPasswordStatus?: Maybe<FilterInt64Operator> /**  */
	powerState?: Maybe<FilterInt64Operator> /**  */
	powerSupplyState?: Maybe<FilterInt64Operator> /**  */
	primaryOwnerContact?: Maybe<FilterStringOperator> /**  */
	primaryOwnerName?: Maybe<FilterStringOperator> /**  */
	resetCapability?: Maybe<FilterInt64Operator> /**  */
	resetCount?: Maybe<FilterInt32Operator> /**  */
	resetLimit?: Maybe<FilterInt32Operator> /**  */
	roles?: Maybe<ArrayStringTypeOperator> /**  */
	status?: Maybe<FilterStringOperator> /**  */
	supportContactDescription?: Maybe<ArrayStringTypeOperator> /**  */
	systemStartupDelay?: Maybe<FilterInt64Operator> /**  */
	systemStartupOptions?: Maybe<ArrayStringTypeOperator> /**  */
	systemType?: Maybe<FilterStringOperator> /**  */
	thermalState?: Maybe<FilterInt64Operator> /**  */
	totalPhysicalMemory?: Maybe<FilterInt64Operator> /**  */
	userName?: Maybe<FilterStringOperator> /**  */
	wakeUpType?: Maybe<FilterInt64Operator> /**  */
	workGroup?: Maybe<FilterStringOperator> /**  */
	identifyingNumber?: Maybe<FilterStringOperator> /**  */
	skuNumber?: Maybe<FilterStringOperator> /**  */
	uuid?: Maybe<FilterStringOperator> /**  */
	vendor?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyId?: Maybe<FilterGuidOperator> /**  */
	scanRegistryKey?: Maybe<FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInAssetProperty> /**  */
	accessMask?: Maybe<FilterInt64Operator> /**  */
	compressed?: Maybe<FilterBooleanOperator> /**  */
	compressionMethod?: Maybe<FilterStringOperator> /**  */
	eightDotThreeFileName?: Maybe<FilterStringOperator> /**  */
	encrypted?: Maybe<FilterBooleanOperator> /**  */
	encryptionMethod?: Maybe<FilterStringOperator> /**  */
	extension?: Maybe<FilterStringOperator> /**  */
	fsName?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	inUseCount?: Maybe<FilterInt64Operator> /**  */
	lastAccessed?: Maybe<FilterDateTimeOperator> /**  */
	lastModified?: Maybe<FilterDateTimeOperator> /**  */
	readable?: Maybe<FilterBooleanOperator> /**  */
	system?: Maybe<FilterBooleanOperator> /**  */
	writeable?: Maybe<FilterBooleanOperator> /**  */
	scanDataFileId?: Maybe<FilterGuidOperator> /**  */
	scanDataFile?: Maybe<FilterDenormalizeOfScanDataFileByScanDataFileInAssetProperty> /**  */
	borderWidth?: Maybe<FilterInt64Operator> /**  */
	coolSwitch?: Maybe<FilterBooleanOperator> /**  */
	cursorBlinkRate?: Maybe<FilterInt64Operator> /**  */
	dragFullWindows?: Maybe<FilterBooleanOperator> /**  */
	gridGranularity?: Maybe<FilterInt64Operator> /**  */
	iconSpacing?: Maybe<FilterInt64Operator> /**  */
	iconTitleFaceName?: Maybe<FilterStringOperator> /**  */
	iconTitleSize?: Maybe<FilterInt64Operator> /**  */
	iconTitleWrap?: Maybe<FilterBooleanOperator> /**  */
	pattern?: Maybe<FilterStringOperator> /**  */
	screenSaverActive?: Maybe<FilterBooleanOperator> /**  */
	screenSaverExecutable?: Maybe<FilterStringOperator> /**  */
	screenSaverSecure?: Maybe<FilterBooleanOperator> /**  */
	screenSaverTimeout?: Maybe<FilterInt64Operator> /**  */
	wallpaper?: Maybe<FilterStringOperator> /**  */
	wallpaperStretched?: Maybe<FilterBooleanOperator> /**  */
	wallpaperTiled?: Maybe<FilterBooleanOperator> /**  */
	firmwareRevision?: Maybe<FilterStringOperator> /**  */
	index?: Maybe<FilterInt64Operator> /**  */
	interfaceType?: Maybe<FilterStringOperator> /**  */
	maxBlockSize?: Maybe<FilterInt64Operator> /**  */
	minBlockSize?: Maybe<FilterInt64Operator> /**  */
	partitions?: Maybe<FilterInt64Operator> /**  */
	scsiBus?: Maybe<FilterInt64Operator> /**  */
	scsiLogicalUnit?: Maybe<FilterInt64Operator> /**  */
	scsiPort?: Maybe<FilterInt64Operator> /**  */
	scsiTargetId?: Maybe<FilterInt64Operator> /**  */
	sectorsPerTrack?: Maybe<FilterInt64Operator> /**  */
	size?: Maybe<FilterDoubleOperator> /**  */
	bytesPerSector?: Maybe<FilterInt64Operator> /**  */
	totalCylinders?: Maybe<FilterInt64Operator> /**  */
	totalHeads?: Maybe<FilterInt64Operator> /**  */
	totalSectors?: Maybe<FilterInt64Operator> /**  */
	totalTracks?: Maybe<FilterInt64Operator> /**  */
	tracksPerCylinder?: Maybe<FilterInt64Operator> /**  */
	providerName?: Maybe<FilterStringOperator> /**  */
	driveType?: Maybe<FilterDriveTypeOperator> /**  */
	fileSystem?: Maybe<FilterStringOperator> /**  */
	freeSpace?: Maybe<FilterDoubleOperator> /**  */
	volumeName?: Maybe<FilterStringOperator> /**  */
	volumeSerialNumber?: Maybe<FilterStringOperator> /**  */
	diskDriveCaption?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	useSpace?: Maybe<FilterDoubleOperator> /**  */
	conversionStatus?: Maybe<FilterConversionStatusOperator> /**  */
	driveLetter?: Maybe<FilterStringOperator> /**  */
	isVolumeInitializedForProtection?: Maybe<FilterBooleanOperator> /**  */
	persistentVolumeId?: Maybe<FilterStringOperator> /**  */
	protectionStatus?: Maybe<FilterProtectionStatusOperator> /**  */
	volumeType?: Maybe<FilterInt32Operator> /**  */
	companyName?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	enableUImd5Hash?: Maybe<FilterBooleanOperator> /**  */
	enableUiParameters?: Maybe<FilterBooleanOperator> /**  */
	pathToEnableUi?: Maybe<FilterStringOperator> /**  */
	versionNumber?: Maybe<FilterStringOperator> /**  */
	actionId?: Maybe<FilterStringOperator> /**  */
	direction?: Maybe<FilterInt64Operator> /**  */
	file?: Maybe<FilterStringOperator> /**  */
	fontTitle?: Maybe<FilterStringOperator> /**  */
	softwareElementId?: Maybe<FilterStringOperator> /**  */
	softwareElementState?: Maybe<FilterInt64Operator> /**  */
	targetOperatingSystem?: Maybe<FilterInt64Operator> /**  */
	localAccount?: Maybe<FilterBooleanOperator> /**  */
	sid?: Maybe<FilterStringOperator> /**  */
	sidType?: Maybe<FilterInt32Operator> /**  */
	distinguishedName?: Maybe<FilterStringOperator> /**  */
	parentDistinguishedName?: Maybe<FilterStringOperator> /**  */
	groupUsers?: Maybe<ArrayGroupUserOperator> /**  */
	iisProgramGroup?: Maybe<FilterStringOperator> /**  */
	installPath?: Maybe<FilterStringOperator> /**  */
	pathWwwRoot?: Maybe<FilterStringOperator> /**  */
	productString?: Maybe<FilterStringOperator> /**  */
	setupString?: Maybe<FilterStringOperator> /**  */
	versionString?: Maybe<FilterStringOperator> /**  */
	majorVersion?: Maybe<FilterInt32Operator> /**  */
	metabaseSetMajorVersion?: Maybe<FilterInt32Operator> /**  */
	metabaseSetMinorVersion?: Maybe<FilterInt32Operator> /**  */
	minorVersion?: Maybe<FilterInt32Operator> /**  */
	iisSites?: Maybe<ArrayIisSiteOperator> /**  */
	iisApplicationPools?: Maybe<ArrayIisApplicationPoolOperator> /**  */
	iisComponents?: Maybe<ArrayIisComponentOperator> /**  */
	proxyServer?: Maybe<FilterStringOperator> /**  */
	autoConfigUrl?: Maybe<FilterStringOperator> /**  */
	autoDetect?: Maybe<FilterBooleanOperator> /**  */
	proxyEnable?: Maybe<FilterBooleanOperator> /**  */
	isLocked?: Maybe<FilterBooleanOperator> /**  */
	lastErrorCode?: Maybe<FilterInt64Operator> /**  */
	layout?: Maybe<FilterStringOperator> /**  */
	numberOfFunctionKeys?: Maybe<FilterInt64Operator> /**  */
	objectClass?: Maybe<FilterStringOperator> /**  */
	cN?: Maybe<FilterStringOperator> /**  */
	whenCreated?: Maybe<FilterDateTimeOperator> /**  */
	whenChanged?: Maybe<FilterDateTimeOperator> /**  */
	objectGuid?: Maybe<FilterGuidOperator> /**  */
	userAccountControl?: Maybe<FilterInt32Operator> /**  */
	badPwdCount?: Maybe<FilterInt32Operator> /**  */
	codePage?: Maybe<FilterInt32Operator> /**  */
	countryCode?: Maybe<FilterInt32Operator> /**  */
	badPasswordTime?: Maybe<FilterDateTimeOperator> /**  */
	lastLogoff?: Maybe<FilterDateTimeOperator> /**  */
	lastLogon?: Maybe<FilterDateTimeOperator> /**  */
	localPolicyFlags?: Maybe<FilterInt32Operator> /**  */
	pwdLastSet?: Maybe<FilterDateTimeOperator> /**  */
	primaryGroupId?: Maybe<FilterInt32Operator> /**  */
	objectSid?: Maybe<FilterStringOperator> /**  */
	accountExpires?: Maybe<FilterDateTimeOperator> /**  */
	logonCount?: Maybe<FilterInt32Operator> /**  */
	sAmAccountName?: Maybe<FilterStringOperator> /**  */
	operatingSystem?: Maybe<FilterStringOperator> /**  */
	operatingSystemVersion?: Maybe<FilterStringOperator> /**  */
	dNsHostName?: Maybe<FilterStringOperator> /**  */
	objectCategory?: Maybe<FilterStringOperator> /**  */
	isCriticalSystemObject?: Maybe<FilterBooleanOperator> /**  */
	lastLogonTimestamp?: Maybe<FilterDateTimeOperator> /**  */
	nTSecurityDescriptor?: Maybe<FilterDateTimeOperator> /**  */
	logonHistoryMode?: Maybe<FilterLogonHistoryModeOperator> /**  */
	userCaption?: Maybe<FilterStringOperator> /**  */
	userDomain?: Maybe<FilterStringOperator> /**  */
	userSid?: Maybe<FilterStringOperator> /**  */
	recordNumber?: Maybe<FilterInt64Operator> /**  */
	authenticationPackage?: Maybe<FilterStringOperator> /**  */
	logonId?: Maybe<FilterStringOperator> /**  */
	logonType?: Maybe<FilterLogonTypeOperator> /**  */
	startTime?: Maybe<FilterDateTimeOperator> /**  */
	logonName?: Maybe<FilterStringOperator> /**  */
	fullName?: Maybe<FilterStringOperator> /**  */
	azureObjectId?: Maybe<FilterStringOperator> /**  */
	azureAccountId?: Maybe<FilterStringOperator> /**  */
	azureDisplayName?: Maybe<FilterStringOperator> /**  */
	azureIdentityName?: Maybe<FilterStringOperator> /**  */
	azureTenantId?: Maybe<FilterStringOperator> /**  */
	manufactureDate?: Maybe<FilterDateTimeOperator> /**  */
	manufactureDateYear?: Maybe<FilterInt32Operator> /**  */
	manufactureDateWeek?: Maybe<FilterInt32Operator> /**  */
	pnpId?: Maybe<FilterStringOperator> /**  */
	serialNumberOriginal?: Maybe<FilterStringOperator> /**  */
	adapterType?: Maybe<FilterStringOperator> /**  */
	guid?: Maybe<FilterStringOperator> /**  */
	interfaceIndex?: Maybe<FilterInt64Operator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	maxSpeed?: Maybe<FilterInt64Operator> /**  */
	netConnectionId?: Maybe<FilterStringOperator> /**  */
	netEnabled?: Maybe<FilterBooleanOperator> /**  */
	productName?: Maybe<FilterStringOperator> /**  */
	serviceName?: Maybe<FilterStringOperator> /**  */
	speed?: Maybe<FilterInt64Operator> /**  */
	networkAdapterConfiguration?: Maybe<FilterOfNetworkAdapterConfiguration> /**  */
	remotePath?: Maybe<FilterStringOperator> /**  */
	letter?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterInt64Operator> /**  */
	categorystring?: Maybe<FilterStringOperator> /**  */
	computerName?: Maybe<FilterStringOperator> /**  */
	data?: Maybe<ArrayIntTypeOperator> /**  */
	eventCode?: Maybe<FilterInt64Operator> /**  */
	eventIdentifier?: Maybe<FilterInt64Operator> /**  */
	eventType?: Maybe<FilterEventTypeOperator> /**  */
	insertionStrings?: Maybe<ArrayStringTypeOperator> /**  */
	logfile?: Maybe<FilterStringOperator> /**  */
	message?: Maybe<FilterStringOperator> /**  */
	sourceName?: Maybe<FilterStringOperator> /**  */
	timeGenerated?: Maybe<FilterDateTimeOperator> /**  */
	timeWritten?: Maybe<FilterDateTimeOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	user?: Maybe<FilterStringOperator> /**  */
	driver?: Maybe<FilterStringOperator> /**  */
	apiLevel?: Maybe<FilterStringOperator> /**  */
	connectFunctions?: Maybe<FilterStringOperator> /**  */
	driverOdbcVer?: Maybe<FilterStringOperator> /**  */
	fileExtns?: Maybe<FilterStringOperator> /**  */
	fileUsage?: Maybe<FilterStringOperator> /**  */
	setup?: Maybe<FilterStringOperator> /**  */
	sqlLevel?: Maybe<FilterStringOperator> /**  */
	usageCount?: Maybe<FilterInt32Operator> /**  */
	bootDevice?: Maybe<FilterStringOperator> /**  */
	buildType?: Maybe<FilterStringOperator> /**  */
	codeSet?: Maybe<FilterStringOperator> /**  */
	csdVersion?: Maybe<FilterStringOperator> /**  */
	debug?: Maybe<FilterBooleanOperator> /**  */
	distributed?: Maybe<FilterBooleanOperator> /**  */
	encryptionLevel?: Maybe<FilterInt64Operator> /**  */
	foregroundApplicationBoost?: Maybe<FilterInt32Operator> /**  */
	freePhysicalMemory?: Maybe<FilterInt64Operator> /**  */
	freeSpaceInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	freeVirtualMemory?: Maybe<FilterInt64Operator> /**  */
	largeSystemCache?: Maybe<FilterInt64Operator> /**  */
	lastBootUpTime?: Maybe<FilterDateTimeOperator> /**  */
	localDateTime?: Maybe<FilterDateTimeOperator> /**  */
	locale?: Maybe<FilterStringOperator> /**  */
	maxNumberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	maxProcessMemorySize?: Maybe<FilterInt64Operator> /**  */
	muiLanguages?: Maybe<ArrayStringTypeOperator> /**  */
	numberOfLicensedUsers?: Maybe<FilterInt64Operator> /**  */
	numberOfProcesses?: Maybe<FilterInt64Operator> /**  */
	numberOfUsers?: Maybe<FilterInt64Operator> /**  */
	operatingSystemSku?: Maybe<FilterInt64Operator> /**  */
	organization?: Maybe<FilterStringOperator> /**  */
	osArchitecture?: Maybe<FilterStringOperator> /**  */
	osLanguage?: Maybe<FilterInt64Operator> /**  */
	osProductSuite?: Maybe<FilterInt64Operator> /**  */
	osType?: Maybe<FilterInt64Operator> /**  */
	otherTypeDescription?: Maybe<FilterStringOperator> /**  */
	paeEnabled?: Maybe<FilterBooleanOperator> /**  */
	plusProductId?: Maybe<FilterStringOperator> /**  */
	plusVersionNumber?: Maybe<FilterStringOperator> /**  */
	primary?: Maybe<FilterBooleanOperator> /**  */
	productType?: Maybe<FilterInt64Operator> /**  */
	registeredUser?: Maybe<FilterStringOperator> /**  */
	servicePackMajorVersion?: Maybe<FilterInt64Operator> /**  */
	servicePackMinorVersion?: Maybe<FilterInt64Operator> /**  */
	sizeStoredInPagingFiles?: Maybe<FilterInt64Operator> /**  */
	suiteMask?: Maybe<FilterInt64Operator> /**  */
	systemDevice?: Maybe<FilterStringOperator> /**  */
	systemDirectory?: Maybe<FilterStringOperator> /**  */
	systemDrive?: Maybe<FilterStringOperator> /**  */
	totalSwapSpaceSize?: Maybe<FilterInt64Operator> /**  */
	totalVirtualMemorySize?: Maybe<FilterInt64Operator> /**  */
	totalVisibleMemorySize?: Maybe<FilterInt64Operator> /**  */
	windowsDirectory?: Maybe<FilterStringOperator> /**  */
	digitalProductId?: Maybe<FilterStringOperator> /**  */
	installDate?: Maybe<FilterDateTimeOperator> /**  */
	softwareId?: Maybe<FilterGuidOperator> /**  */
	software?: Maybe<FilterDenormalizeOfSoftwareBySoftwareInAssetProperty> /**  */
	displayVersion?: Maybe<FilterStringOperator> /**  */
	installState?: Maybe<FilterInt64Operator> /**  */
	autoReboot?: Maybe<FilterBooleanOperator> /**  */
	debugFilePath?: Maybe<FilterStringOperator> /**  */
	debugInfoType?: Maybe<FilterInt64Operator> /**  */
	expandedDebugFilePath?: Maybe<FilterStringOperator> /**  */
	expandedMiniDumpDirectory?: Maybe<FilterStringOperator> /**  */
	kernelDumpOnly?: Maybe<FilterBooleanOperator> /**  */
	miniDumpDirectory?: Maybe<FilterStringOperator> /**  */
	overwriteExistingDebugFile?: Maybe<FilterBooleanOperator> /**  */
	sendAdminAlert?: Maybe<FilterBooleanOperator> /**  */
	writeDebugInfo?: Maybe<FilterBooleanOperator> /**  */
	writeToSystemLog?: Maybe<FilterBooleanOperator> /**  */
	initialSize?: Maybe<FilterInt64Operator> /**  */
	maximumSize?: Maybe<FilterInt64Operator> /**  */
	bankLabel?: Maybe<FilterStringOperator> /**  */
	capacity?: Maybe<FilterInt64Operator> /**  */
	dataWidth?: Maybe<FilterInt64Operator> /**  */
	deviceLocator?: Maybe<FilterStringOperator> /**  */
	formFactor?: Maybe<FilterFormFactorOperator> /**  */
	hotSwappable?: Maybe<FilterBooleanOperator> /**  */
	interleaveDataDepth?: Maybe<FilterInt64Operator> /**  */
	interleavePosition?: Maybe<FilterInt64Operator> /**  */
	memoryType?: Maybe<FilterMemoryTypeOperator> /**  */
	partNumber?: Maybe<FilterStringOperator> /**  */
	positionInRow?: Maybe<FilterInt64Operator> /**  */
	poweredOn?: Maybe<FilterBooleanOperator> /**  */
	removable?: Maybe<FilterBooleanOperator> /**  */
	replaceable?: Maybe<FilterBooleanOperator> /**  */
	tag?: Maybe<FilterStringOperator> /**  */
	totalWidth?: Maybe<FilterInt64Operator> /**  */
	location?: Maybe<FilterInt64Operator> /**  */
	maxCapacity?: Maybe<FilterInt64Operator> /**  */
	memoryDevices?: Maybe<FilterInt64Operator> /**  */
	memoryErrorCorrection?: Maybe<FilterInt64Operator> /**  */
	use?: Maybe<FilterInt64Operator> /**  */
	classGuid?: Maybe<FilterStringOperator> /**  */
	pnpEntityString?: Maybe<FilterStringOperator> /**  */
	hardwareId?: Maybe<ArrayStringTypeOperator> /**  */
	service?: Maybe<FilterStringOperator> /**  */
	configManagerErrorCode?: Maybe<FilterInt32Operator> /**  */
	configManagerErrorCodeString?: Maybe<FilterStringOperator> /**  */
	errorDescription?: Maybe<FilterStringOperator> /**  */
	compatId?: Maybe<FilterStringOperator> /**  */
	deviceClass?: Maybe<FilterStringOperator> /**  */
	deviceName?: Maybe<FilterStringOperator> /**  */
	devLoader?: Maybe<FilterStringOperator> /**  */
	driverDate?: Maybe<FilterDateTimeOperator> /**  */
	driverName?: Maybe<FilterStringOperator> /**  */
	driverVersion?: Maybe<FilterStringOperator> /**  */
	friendlyName?: Maybe<FilterStringOperator> /**  */
	hardWareId?: Maybe<FilterStringOperator> /**  */
	infName?: Maybe<FilterStringOperator> /**  */
	isSigned?: Maybe<FilterBooleanOperator> /**  */
	pdo?: Maybe<FilterStringOperator> /**  */
	driverProviderName?: Maybe<FilterStringOperator> /**  */
	signer?: Maybe<FilterStringOperator> /**  */
	started?: Maybe<FilterBooleanOperator> /**  */
	startMode?: Maybe<FilterStringOperator> /**  */
	hardwareType?: Maybe<FilterStringOperator> /**  */
	infFileName?: Maybe<FilterStringOperator> /**  */
	infSection?: Maybe<FilterStringOperator> /**  */
	numberOfButtons?: Maybe<FilterInt32Operator> /**  */
	capacityMultiplier?: Maybe<FilterInt64Operator> /**  */
	maxBatteryError?: Maybe<FilterInt64Operator> /**  */
	smartBatteryVersion?: Maybe<FilterStringOperator> /**  */
	externalReferenceDesignator?: Maybe<FilterStringOperator> /**  */
	internalReferenceDesignator?: Maybe<FilterStringOperator> /**  */
	portType?: Maybe<FilterInt64Operator> /**  */
	portName?: Maybe<FilterStringOperator> /**  */
	portNumber?: Maybe<FilterInt32Operator> /**  */
	open?: Maybe<FilterBooleanOperator> /**  */
	action?: Maybe<FilterInt64Operator> /**  */
	attributes?: Maybe<FilterInt64Operator> /**  */
	dataType?: Maybe<FilterStringOperator> /**  */
	printProcessor?: Maybe<FilterStringOperator> /**  */
	printerDriver?: Maybe<FilterStringOperator> /**  */
	priority?: Maybe<FilterInt64Operator> /**  */
	separatorFile?: Maybe<FilterStringOperator> /**  */
	shareName?: Maybe<FilterStringOperator> /**  */
	isNetWork?: Maybe<FilterBooleanOperator> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	deviceInstanceId?: Maybe<FilterStringOperator> /**  */
	printerName?: Maybe<FilterStringOperator> /**  */
	printShareName?: Maybe<FilterStringOperator> /**  */
	serverName?: Maybe<FilterStringOperator> /**  */
	shortServerName?: Maybe<FilterStringOperator> /**  */
	handle?: Maybe<FilterStringOperator> /**  */
	processId?: Maybe<FilterInt64Operator> /**  */
	commandLine?: Maybe<FilterStringOperator> /**  */
	executablePath?: Maybe<FilterStringOperator> /**  */
	osCreationClassName?: Maybe<FilterStringOperator> /**  */
	osName?: Maybe<FilterStringOperator> /**  */
	virtualSize?: Maybe<FilterInt64Operator> /**  */
	workingSetSize?: Maybe<FilterInt64Operator> /**  */
	addressWidth?: Maybe<FilterInt64Operator> /**  */
	architecture?: Maybe<FilterArchitectureOperator> /**  */
	availability?: Maybe<FilterAvailabilityOperator> /**  */
	configManagerUserConfig?: Maybe<FilterBooleanOperator> /**  */
	cpuStatus?: Maybe<FilterInt64Operator> /**  */
	currentClockSpeed?: Maybe<FilterInt64Operator> /**  */
	currentVoltage?: Maybe<FilterInt64Operator> /**  */
	errorCleared?: Maybe<FilterBooleanOperator> /**  */
	extClock?: Maybe<FilterInt64Operator> /**  */
	family?: Maybe<FilterInt64Operator> /**  */
	l2CacheSize?: Maybe<FilterInt64Operator> /**  */
	l2CacheSpeed?: Maybe<FilterInt64Operator> /**  */
	l3CacheSize?: Maybe<FilterInt64Operator> /**  */
	l3CacheSpeed?: Maybe<FilterInt64Operator> /**  */
	level?: Maybe<FilterInt64Operator> /**  */
	loadPercentage?: Maybe<FilterInt64Operator> /**  */
	maxClockSpeed?: Maybe<FilterInt64Operator> /**  */
	numberOfCores?: Maybe<FilterInt64Operator> /**  */
	otherFamilyDescription?: Maybe<FilterStringOperator> /**  */
	processorId?: Maybe<FilterStringOperator> /**  */
	processorType?: Maybe<FilterInt64Operator> /**  */
	revision?: Maybe<FilterInt64Operator> /**  */
	role?: Maybe<FilterStringOperator> /**  */
	socketDesignation?: Maybe<FilterStringOperator> /**  */
	statusInfo?: Maybe<FilterInt64Operator> /**  */
	stepping?: Maybe<FilterStringOperator> /**  */
	uniqueId?: Maybe<FilterStringOperator> /**  */
	upgradeMethod?: Maybe<FilterInt64Operator> /**  */
	voltageCaps?: Maybe<FilterInt64Operator> /**  */
	parentDisplayName?: Maybe<FilterStringOperator> /**  */
	contact?: Maybe<FilterStringOperator> /**  */
	estimatedSize?: Maybe<FilterInt64Operator> /**  */
	installLocation?: Maybe<FilterStringOperator> /**  */
	installSource?: Maybe<FilterStringOperator> /**  */
	helpLink?: Maybe<FilterStringOperator> /**  */
	language?: Maybe<FilterInt64Operator> /**  */
	productId?: Maybe<FilterStringOperator> /**  */
	uninstallString?: Maybe<FilterStringOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
	fixComments?: Maybe<FilterStringOperator> /**  */
	hotFixId?: Maybe<FilterStringOperator> /**  */
	installedBy?: Maybe<FilterStringOperator> /**  */
	installedOn?: Maybe<FilterStringOperator> /**  */
	installedDate?: Maybe<FilterDateTimeOperator> /**  */
	servicePackInEffect?: Maybe<FilterStringOperator> /**  */
	name2?: Maybe<FilterStringOperator> /**  */
	value2?: Maybe<FilterStringOperator> /**  */
	serialNumberMode?: Maybe<FilterSerialNumberModeOperator> /**  */
	allowMaximum?: Maybe<FilterBooleanOperator> /**  */
	maximumAllowed?: Maybe<FilterInt64Operator> /**  */
	shareSecurities?: Maybe<ArrayShareSecurityOperator> /**  */
	bSSid?: Maybe<FilterStringOperator> /**  */
	ssid?: Maybe<FilterStringOperator> /**  */
	state?: Maybe<FilterStringOperator> /**  */
	maxLevel?: Maybe<FilterInt32Operator> /**  */
	actualLevel?: Maybe<FilterInt32Operator> /**  */
	colorCode?: Maybe<FilterStringOperator> /**  */
	percent?: Maybe<FilterDoubleOperator> /**  */
	color?: Maybe<FilterInt32Operator> /**  */
	black?: Maybe<FilterInt32Operator> /**  */
	twoColor?: Maybe<FilterInt32Operator> /**  */
	monochrome?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt32Operator> /**  */
	oid?: Maybe<FilterStringOperator> /**  */
	allocationUnit?: Maybe<FilterInt32Operator> /**  */
	used?: Maybe<FilterInt64Operator> /**  */
	ifIndex?: Maybe<FilterStringOperator> /**  */
	ifDescr?: Maybe<FilterStringOperator> /**  */
	ifType?: Maybe<FilterStringOperator> /**  */
	ifMtu?: Maybe<FilterStringOperator> /**  */
	ifSpeed?: Maybe<FilterStringOperator> /**  */
	ifPhysAddress?: Maybe<FilterStringOperator> /**  */
	ifAdminStatus?: Maybe<FilterStringOperator> /**  */
	ifOperStatus?: Maybe<FilterStringOperator> /**  */
	ifLastChange?: Maybe<FilterStringOperator> /**  */
	ifInOctets?: Maybe<FilterStringOperator> /**  */
	ifInUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifInNUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifInDiscards?: Maybe<FilterStringOperator> /**  */
	ifInErrors?: Maybe<FilterStringOperator> /**  */
	ifInUnknownProtos?: Maybe<FilterStringOperator> /**  */
	ifOutOctets?: Maybe<FilterStringOperator> /**  */
	ifOutUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifOutNUcastPkts?: Maybe<FilterStringOperator> /**  */
	ifOutDiscards?: Maybe<FilterStringOperator> /**  */
	ifOutErrors?: Maybe<FilterStringOperator> /**  */
	ifOutQLen?: Maybe<FilterStringOperator> /**  */
	ifSpecific?: Maybe<FilterStringOperator> /**  */
	send?: Maybe<FilterInt32Operator> /**  */
	receive?: Maybe<FilterInt32Operator> /**  */
	sysDescription?: Maybe<FilterStringOperator> /**  */
	sysOid?: Maybe<FilterStringOperator> /**  */
	sysUpTime?: Maybe<FilterStringOperator> /**  */
	sysContact?: Maybe<FilterStringOperator> /**  */
	sysName?: Maybe<FilterStringOperator> /**  */
	sysLocation?: Maybe<FilterStringOperator> /**  */
	sysService?: Maybe<FilterStringOperator> /**  */
	snmpType?: Maybe<FilterSnmpTypeOperator> /**  */
	vendorId?: Maybe<FilterInt32Operator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	instanceName?: Maybe<FilterStringOperator> /**  */
	edition?: Maybe<FilterStringOperator> /**  */
	licenceName?: Maybe<FilterStringOperator> /**  */
	fileVersion?: Maybe<FilterStringOperator> /**  */
	servicePack?: Maybe<FilterStringOperator> /**  */
	wow64?: Maybe<FilterBooleanOperator> /**  */
	dataPath?: Maybe<FilterStringOperator> /**  */
	sqlService?: Maybe<FilterOfSqlService> /**  */
	dataBaseInformations?: Maybe<ArrayDataBaseInformationOperator> /**  */
	audibleAlarm?: Maybe<FilterBooleanOperator> /**  */
	chassisTypes?: Maybe<FilterChassisTypeOperator> /**  */
	lockPresent?: Maybe<FilterBooleanOperator> /**  */
	smbiosAssetTag?: Maybe<FilterStringOperator> /**  */
	computerDescription?: Maybe<FilterStringOperator> /**  */
	dockingMode?: Maybe<FilterDockingModeOperator> /**  */
	slotDesignation?: Maybe<FilterStringOperator> /**  */
	ecc?: Maybe<FilterInt64Operator> /**  */
	needsCleaning?: Maybe<FilterBooleanOperator> /**  */
	numberOfMediaSupported?: Maybe<FilterInt64Operator> /**  */
	uptimeCalendarMode?: Maybe<FilterUptimeCalendarModeOperator> /**  */
	shutDownReason?: Maybe<FilterStringOperator> /**  */
	shutDownType?: Maybe<FilterStringOperator> /**  */
	shutDownProcess?: Maybe<FilterStringOperator> /**  */
	shutDownCode?: Maybe<FilterStringOperator> /**  */
	accountType?: Maybe<FilterInt64Operator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	lockout?: Maybe<FilterBooleanOperator> /**  */
	passwordChangeable?: Maybe<FilterBooleanOperator> /**  */
	passwordExpires?: Maybe<FilterBooleanOperator> /**  */
	passwordRequired?: Maybe<FilterBooleanOperator> /**  */
	consentPromptBehaviorAdmin?: Maybe<FilterConsentPromptBehaviorAdminOperator> /**  */
	consentPromptBehaviorUser?: Maybe<FilterConsentPromptBehaviorUserOperator> /**  */
	enableInstallerDetection?: Maybe<FilterBooleanOperator> /**  */
	enableLua?: Maybe<FilterBooleanOperator> /**  */
	promptOnSecureDesktop?: Maybe<FilterBooleanOperator> /**  */
	filterAdministratorToken?: Maybe<FilterBooleanOperator> /**  */
	enableUiaDesktopToggle?: Maybe<FilterBooleanOperator> /**  */
	enableSecureUiaPaths?: Maybe<FilterBooleanOperator> /**  */
	validateAdminCodeSignatures?: Maybe<FilterBooleanOperator> /**  */
	enableVirtualization?: Maybe<FilterBooleanOperator> /**  */
	lastDownloadTime?: Maybe<FilterDateTimeOperator> /**  */
	lastUploadTime?: Maybe<FilterDateTimeOperator> /**  */
	lastUseTime?: Maybe<FilterDateTimeOperator> /**  */
	loaded?: Maybe<FilterBooleanOperator> /**  */
	localPath?: Maybe<FilterStringOperator> /**  */
	refCount?: Maybe<FilterInt64Operator> /**  */
	roamingConfigured?: Maybe<FilterBooleanOperator> /**  */
	roamingPath?: Maybe<FilterStringOperator> /**  */
	roamingPreference?: Maybe<FilterBooleanOperator> /**  */
	special?: Maybe<FilterBooleanOperator> /**  */
	adapterCompatibility?: Maybe<FilterStringOperator> /**  */
	adapterDacType?: Maybe<FilterStringOperator> /**  */
	adapterRam?: Maybe<FilterInt64Operator> /**  */
	currentBitsPerPixel?: Maybe<FilterInt64Operator> /**  */
	currentHorizontalResolution?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfColors?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfColumns?: Maybe<FilterInt64Operator> /**  */
	currentNumberOfRows?: Maybe<FilterInt64Operator> /**  */
	currentRefreshRate?: Maybe<FilterInt64Operator> /**  */
	currentScanMode?: Maybe<FilterInt64Operator> /**  */
	currentVerticalResolution?: Maybe<FilterInt64Operator> /**  */
	videoArchitecture?: Maybe<FilterInt64Operator> /**  */
	videoMemoryType?: Maybe<FilterInt64Operator> /**  */
	videoMode?: Maybe<FilterInt64Operator> /**  */
	videoModeDescription?: Maybe<FilterStringOperator> /**  */
	videoProcessor?: Maybe<FilterStringOperator> /**  */
	elementName?: Maybe<FilterStringOperator> /**  */
	statusDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	healthState?: Maybe<FilterInt64Operator> /**  */
	enabledState?: Maybe<FilterInt64Operator> /**  */
	otherEnabledState?: Maybe<FilterStringOperator> /**  */
	requestedState?: Maybe<FilterInt64Operator> /**  */
	enabledDefault?: Maybe<FilterInt64Operator> /**  */
	timeOfLastStateChange?: Maybe<FilterDateTimeOperator> /**  */
	otherIdentifyingInfo?: Maybe<ArrayStringTypeOperator> /**  */
	identifyingDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	otherDedicatedDescriptions?: Maybe<ArrayStringTypeOperator> /**  */
	onTimeInMilliseconds?: Maybe<FilterInt64Operator> /**  */
	timeOfLastConfigurationChange?: Maybe<FilterDateTimeOperator> /**  */
	virtualMachineGuest?: Maybe<FilterOfVirtualMachineGuest> /**  */
	assetLinkId?: Maybe<FilterGuidOperator> /**  */
	assetLink?: Maybe<FilterDenormalizeOfAssetByAssetLinkInAssetProperty> /**  */
	domainProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	publicProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	standardProfile?: Maybe<FilterOfFirewallPolicy> /**  */
	acceptPause?: Maybe<FilterBooleanOperator> /**  */
	acceptStop?: Maybe<FilterBooleanOperator> /**  */
	pathName?: Maybe<FilterStringOperator> /**  */
	startName?: Maybe<FilterStringOperator> /**  */
	auOptions?: Maybe<FilterInt64Operator> /**  */
	nextDetectionTime?: Maybe<FilterStringOperator> /**  */
	scheduledInstallDate?: Maybe<FilterStringOperator> /**  */
	scheduledInstallDay?: Maybe<FilterInt64Operator> /**  */
	scheduledInstallTime?: Maybe<FilterInt64Operator> /**  */
	detect?: Maybe<FilterOfWindowsUpdateResult> /**  */
	download?: Maybe<FilterOfWindowsUpdateResult> /**  */
	install?: Maybe<FilterOfWindowsUpdateResult> /**  */
	created?: Maybe<FilterDateTimeOperator> /**  */
	namingPolicyId?: Maybe<FilterGuidOperator> /**  */
	namingPolicy?: Maybe<FilterOfNamingConvention> /**  */
	and?: Array<Maybe<FilterOfAssetProperty>> /**  */
	or?: Array<Maybe<FilterOfAssetProperty>> /**  */
	nor?: Array<Maybe<FilterOfAssetProperty>> /**  */
	not?: Array<Maybe<FilterOfAssetProperty>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAssetProperty = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInAssetProperty = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanDataFileByScanDataFileInAssetProperty = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfSoftwareBySoftwareInAssetProperty = {
	name?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	notAllowed?: Maybe<FilterBooleanOperator> /**  */
	obligatory?: Maybe<FilterBooleanOperator> /**  */
	approved?: Maybe<FilterBooleanOperator> /**  */
	recorded?: Maybe<FilterBooleanOperator> /**  */
	publisher?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetLinkInAssetProperty = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterOfNamingConvention = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	assetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	excludeAssetCategoryIds?: Maybe<ArrayUuidTypeOperator> /**  */
	namingConventionCategory?: Maybe<FilterNamingConventionCategoryOperator> /**  */
	locked?: Maybe<FilterBooleanOperator> /**  */
	empty?: Maybe<FilterBooleanOperator> /**  */
	inheritCategory?: Maybe<FilterStringOperator> /**  */
	dateFormat?: Maybe<FilterStringOperator> /**  */
	dateFormatWithoutHour?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	incrementNextValue?: Maybe<FilterInt32Operator> /**  */
	incrementLength?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNamingConvention>> /**  */
	or?: Array<Maybe<FilterOfNamingConvention>> /**  */
	nor?: Array<Maybe<FilterOfNamingConvention>> /**  */
	not?: Array<Maybe<FilterOfNamingConvention>> /**  */
}


/**  */
export type FilterNamingConventionCategoryOperator = {
	eq?: Maybe<NamingConventionCategory> /**  */
	ne?: Maybe<NamingConventionCategory> /**  */
	in?: Array<Maybe<NamingConventionCategory>> /**  */
	nin?: Array<Maybe<NamingConventionCategory>> /**  */
}


/**  */
export type QueryContextOfAssetProperty = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetProperty>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetProperty */
export type QuerySortTypeAssetProperty = {
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	instanceGuid?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedProductExe?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedReportingExe?: Maybe<Sort> /** Représente le type de trie. */
	productState?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	product?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	sku?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	batteryRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	batteryStatus?: Maybe<Sort> /** Représente le type de trie. */
	chemistry?: Maybe<Sort> /** Représente le type de trie. */
	designCapacity?: Maybe<Sort> /** Représente le type de trie. */
	designVoltage?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	estimatedChargeRemaining?: Maybe<Sort> /** Représente le type de trie. */
	estimatedRunTime?: Maybe<Sort> /** Représente le type de trie. */
	expectedBatteryLife?: Maybe<Sort> /** Représente le type de trie. */
	expectedLife?: Maybe<Sort> /** Représente le type de trie. */
	fullChargeCapacity?: Maybe<Sort> /** Représente le type de trie. */
	maxRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	timeOnBattery?: Maybe<Sort> /** Représente le type de trie. */
	timeToFullCharge?: Maybe<Sort> /** Représente le type de trie. */
	buildNumber?: Maybe<Sort> /** Représente le type de trie. */
	releaseDate?: Maybe<Sort> /** Représente le type de trie. */
	smBiosVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosPresent?: Maybe<Sort> /** Représente le type de trie. */
	bootDirectory?: Maybe<Sort> /** Représente le type de trie. */
	configurationPath?: Maybe<Sort> /** Représente le type de trie. */
	lastDrive?: Maybe<Sort> /** Représente le type de trie. */
	scratchDirectory?: Maybe<Sort> /** Représente le type de trie. */
	settingId?: Maybe<Sort> /** Représente le type de trie. */
	tempDirectory?: Maybe<Sort> /** Représente le type de trie. */
	drive?: Maybe<Sort> /** Représente le type de trie. */
	mediaType?: Maybe<Sort> /** Représente le type de trie. */
	creationDate?: Maybe<Sort> /** Représente le type de trie. */
	fileName?: Maybe<Sort> /** Représente le type de trie. */
	fileSize?: Maybe<Sort> /** Représente le type de trie. */
	fileType?: Maybe<Sort> /** Représente le type de trie. */
	group?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	adminPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	automaticManagedPagefile?: Maybe<Sort> /** Représente le type de trie. */
	automaticResetBootOption?: Maybe<Sort> /** Représente le type de trie. */
	automaticResetCapability?: Maybe<Sort> /** Représente le type de trie. */
	bootOptionOnLimit?: Maybe<Sort> /** Représente le type de trie. */
	bootOptionOnWatchDog?: Maybe<Sort> /** Représente le type de trie. */
	bootRomSupported?: Maybe<Sort> /** Représente le type de trie. */
	bootupState?: Maybe<Sort> /** Représente le type de trie. */
	chassisBootupState?: Maybe<Sort> /** Représente le type de trie. */
	currentTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	daylightInEffect?: Maybe<Sort> /** Représente le type de trie. */
	dnsHostName?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	domainRole?: Maybe<Sort> /** Représente le type de trie. */
	enableDaylightSavingsTime?: Maybe<Sort> /** Représente le type de trie. */
	frontPanelResetStatus?: Maybe<Sort> /** Représente le type de trie. */
	infraredSupported?: Maybe<Sort> /** Représente le type de trie. */
	initialLoadInfo?: Maybe<Sort> /** Représente le type de trie. */
	keyboardPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	lastLoadInfo?: Maybe<Sort> /** Représente le type de trie. */
	nameFormat?: Maybe<Sort> /** Représente le type de trie. */
	networkServerModeEnabled?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLogicalProcessors?: Maybe<Sort> /** Représente le type de trie. */
	numberOfProcessors?: Maybe<Sort> /** Représente le type de trie. */
	partOfDomain?: Maybe<Sort> /** Représente le type de trie. */
	pauseAfterReset?: Maybe<Sort> /** Représente le type de trie. */
	pcSystemType?: Maybe<Sort> /** Représente le type de trie. */
	powerManagementSupported?: Maybe<Sort> /** Représente le type de trie. */
	powerOnPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	powerState?: Maybe<Sort> /** Représente le type de trie. */
	powerSupplyState?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerContact?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerName?: Maybe<Sort> /** Représente le type de trie. */
	resetCapability?: Maybe<Sort> /** Représente le type de trie. */
	resetCount?: Maybe<Sort> /** Représente le type de trie. */
	resetLimit?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	systemStartupDelay?: Maybe<Sort> /** Représente le type de trie. */
	systemType?: Maybe<Sort> /** Représente le type de trie. */
	thermalState?: Maybe<Sort> /** Représente le type de trie. */
	totalPhysicalMemory?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	wakeUpType?: Maybe<Sort> /** Représente le type de trie. */
	workGroup?: Maybe<Sort> /** Représente le type de trie. */
	identifyingNumber?: Maybe<Sort> /** Représente le type de trie. */
	skuNumber?: Maybe<Sort> /** Représente le type de trie. */
	uuid?: Maybe<Sort> /** Représente le type de trie. */
	vendor?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKey?: Array<Maybe<QuerySortTypeScanRegistryKey>> /**  */
	accessMask?: Maybe<Sort> /** Représente le type de trie. */
	compressed?: Maybe<Sort> /** Représente le type de trie. */
	compressionMethod?: Maybe<Sort> /** Représente le type de trie. */
	eightDotThreeFileName?: Maybe<Sort> /** Représente le type de trie. */
	encrypted?: Maybe<Sort> /** Représente le type de trie. */
	encryptionMethod?: Maybe<Sort> /** Représente le type de trie. */
	extension?: Maybe<Sort> /** Représente le type de trie. */
	fsName?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	inUseCount?: Maybe<Sort> /** Représente le type de trie. */
	lastAccessed?: Maybe<Sort> /** Représente le type de trie. */
	lastModified?: Maybe<Sort> /** Représente le type de trie. */
	readable?: Maybe<Sort> /** Représente le type de trie. */
	system?: Maybe<Sort> /** Représente le type de trie. */
	writeable?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFile?: Array<Maybe<QuerySortTypeScanDataFile>> /**  */
	borderWidth?: Maybe<Sort> /** Représente le type de trie. */
	coolSwitch?: Maybe<Sort> /** Représente le type de trie. */
	cursorBlinkRate?: Maybe<Sort> /** Représente le type de trie. */
	dragFullWindows?: Maybe<Sort> /** Représente le type de trie. */
	gridGranularity?: Maybe<Sort> /** Représente le type de trie. */
	iconSpacing?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleFaceName?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleSize?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleWrap?: Maybe<Sort> /** Représente le type de trie. */
	pattern?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverActive?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverExecutable?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverSecure?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverTimeout?: Maybe<Sort> /** Représente le type de trie. */
	wallpaper?: Maybe<Sort> /** Représente le type de trie. */
	wallpaperStretched?: Maybe<Sort> /** Représente le type de trie. */
	wallpaperTiled?: Maybe<Sort> /** Représente le type de trie. */
	firmwareRevision?: Maybe<Sort> /** Représente le type de trie. */
	index?: Maybe<Sort> /** Représente le type de trie. */
	interfaceType?: Maybe<Sort> /** Représente le type de trie. */
	maxBlockSize?: Maybe<Sort> /** Représente le type de trie. */
	minBlockSize?: Maybe<Sort> /** Représente le type de trie. */
	partitions?: Maybe<Sort> /** Représente le type de trie. */
	scsiBus?: Maybe<Sort> /** Représente le type de trie. */
	scsiLogicalUnit?: Maybe<Sort> /** Représente le type de trie. */
	scsiPort?: Maybe<Sort> /** Représente le type de trie. */
	scsiTargetId?: Maybe<Sort> /** Représente le type de trie. */
	sectorsPerTrack?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	bytesPerSector?: Maybe<Sort> /** Représente le type de trie. */
	totalCylinders?: Maybe<Sort> /** Représente le type de trie. */
	totalHeads?: Maybe<Sort> /** Représente le type de trie. */
	totalSectors?: Maybe<Sort> /** Représente le type de trie. */
	totalTracks?: Maybe<Sort> /** Représente le type de trie. */
	tracksPerCylinder?: Maybe<Sort> /** Représente le type de trie. */
	providerName?: Maybe<Sort> /** Représente le type de trie. */
	driveType?: Maybe<Sort> /** Représente le type de trie. */
	fileSystem?: Maybe<Sort> /** Représente le type de trie. */
	freeSpace?: Maybe<Sort> /** Représente le type de trie. */
	volumeName?: Maybe<Sort> /** Représente le type de trie. */
	volumeSerialNumber?: Maybe<Sort> /** Représente le type de trie. */
	diskDriveCaption?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	useSpace?: Maybe<Sort> /** Représente le type de trie. */
	conversionStatus?: Maybe<Sort> /** Représente le type de trie. */
	driveLetter?: Maybe<Sort> /** Représente le type de trie. */
	isVolumeInitializedForProtection?: Maybe<Sort> /** Représente le type de trie. */
	persistentVolumeId?: Maybe<Sort> /** Représente le type de trie. */
	protectionStatus?: Maybe<Sort> /** Représente le type de trie. */
	volumeType?: Maybe<Sort> /** Représente le type de trie. */
	companyName?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	enableUImd5Hash?: Maybe<Sort> /** Représente le type de trie. */
	enableUiParameters?: Maybe<Sort> /** Représente le type de trie. */
	pathToEnableUi?: Maybe<Sort> /** Représente le type de trie. */
	versionNumber?: Maybe<Sort> /** Représente le type de trie. */
	actionId?: Maybe<Sort> /** Représente le type de trie. */
	direction?: Maybe<Sort> /** Représente le type de trie. */
	file?: Maybe<Sort> /** Représente le type de trie. */
	fontTitle?: Maybe<Sort> /** Représente le type de trie. */
	softwareElementId?: Maybe<Sort> /** Représente le type de trie. */
	softwareElementState?: Maybe<Sort> /** Représente le type de trie. */
	targetOperatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	localAccount?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	sidType?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	groupUsers?: Maybe<Sort> /** Représente le type de trie. */
	iisProgramGroup?: Maybe<Sort> /** Représente le type de trie. */
	installPath?: Maybe<Sort> /** Représente le type de trie. */
	pathWwwRoot?: Maybe<Sort> /** Représente le type de trie. */
	productString?: Maybe<Sort> /** Représente le type de trie. */
	setupString?: Maybe<Sort> /** Représente le type de trie. */
	versionString?: Maybe<Sort> /** Représente le type de trie. */
	majorVersion?: Maybe<Sort> /** Représente le type de trie. */
	metabaseSetMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	metabaseSetMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	minorVersion?: Maybe<Sort> /** Représente le type de trie. */
	iisSites?: Maybe<Sort> /** Représente le type de trie. */
	iisApplicationPools?: Maybe<Sort> /** Représente le type de trie. */
	iisComponents?: Maybe<Sort> /** Représente le type de trie. */
	proxyServer?: Maybe<Sort> /** Représente le type de trie. */
	autoConfigUrl?: Maybe<Sort> /** Représente le type de trie. */
	autoDetect?: Maybe<Sort> /** Représente le type de trie. */
	proxyEnable?: Maybe<Sort> /** Représente le type de trie. */
	isLocked?: Maybe<Sort> /** Représente le type de trie. */
	lastErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	layout?: Maybe<Sort> /** Représente le type de trie. */
	numberOfFunctionKeys?: Maybe<Sort> /** Représente le type de trie. */
	objectClass?: Maybe<Sort> /** Représente le type de trie. */
	cN?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControl?: Maybe<Sort> /** Représente le type de trie. */
	badPwdCount?: Maybe<Sort> /** Représente le type de trie. */
	codePage?: Maybe<Sort> /** Représente le type de trie. */
	countryCode?: Maybe<Sort> /** Représente le type de trie. */
	badPasswordTime?: Maybe<Sort> /** Représente le type de trie. */
	lastLogoff?: Maybe<Sort> /** Représente le type de trie. */
	lastLogon?: Maybe<Sort> /** Représente le type de trie. */
	localPolicyFlags?: Maybe<Sort> /** Représente le type de trie. */
	pwdLastSet?: Maybe<Sort> /** Représente le type de trie. */
	primaryGroupId?: Maybe<Sort> /** Représente le type de trie. */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	accountExpires?: Maybe<Sort> /** Représente le type de trie. */
	logonCount?: Maybe<Sort> /** Représente le type de trie. */
	sAmAccountName?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystemVersion?: Maybe<Sort> /** Représente le type de trie. */
	dNsHostName?: Maybe<Sort> /** Représente le type de trie. */
	objectCategory?: Maybe<Sort> /** Représente le type de trie. */
	isCriticalSystemObject?: Maybe<Sort> /** Représente le type de trie. */
	lastLogonTimestamp?: Maybe<Sort> /** Représente le type de trie. */
	nTSecurityDescriptor?: Maybe<Sort> /** Représente le type de trie. */
	logonHistoryMode?: Maybe<Sort> /** Représente le type de trie. */
	userCaption?: Maybe<Sort> /** Représente le type de trie. */
	userDomain?: Maybe<Sort> /** Représente le type de trie. */
	userSid?: Maybe<Sort> /** Représente le type de trie. */
	recordNumber?: Maybe<Sort> /** Représente le type de trie. */
	authenticationPackage?: Maybe<Sort> /** Représente le type de trie. */
	logonId?: Maybe<Sort> /** Représente le type de trie. */
	logonType?: Maybe<Sort> /** Représente le type de trie. */
	startTime?: Maybe<Sort> /** Représente le type de trie. */
	logonName?: Maybe<Sort> /** Représente le type de trie. */
	fullName?: Maybe<Sort> /** Représente le type de trie. */
	azureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	azureAccountId?: Maybe<Sort> /** Représente le type de trie. */
	azureDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	azureIdentityName?: Maybe<Sort> /** Représente le type de trie. */
	azureTenantId?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDate?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDateYear?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDateWeek?: Maybe<Sort> /** Représente le type de trie. */
	pnpId?: Maybe<Sort> /** Représente le type de trie. */
	serialNumberOriginal?: Maybe<Sort> /** Représente le type de trie. */
	adapterType?: Maybe<Sort> /** Représente le type de trie. */
	guid?: Maybe<Sort> /** Représente le type de trie. */
	interfaceIndex?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	maxSpeed?: Maybe<Sort> /** Représente le type de trie. */
	netConnectionId?: Maybe<Sort> /** Représente le type de trie. */
	netEnabled?: Maybe<Sort> /** Représente le type de trie. */
	productName?: Maybe<Sort> /** Représente le type de trie. */
	serviceName?: Maybe<Sort> /** Représente le type de trie. */
	speed?: Maybe<Sort> /** Représente le type de trie. */
	networkAdapterConfiguration?: Array<Maybe<QuerySortTypeNetworkAdapterConfiguration>> /**  */
	remotePath?: Maybe<Sort> /** Représente le type de trie. */
	letter?: Maybe<Sort> /** Représente le type de trie. */
	category?: Maybe<Sort> /** Représente le type de trie. */
	categorystring?: Maybe<Sort> /** Représente le type de trie. */
	computerName?: Maybe<Sort> /** Représente le type de trie. */
	eventCode?: Maybe<Sort> /** Représente le type de trie. */
	eventIdentifier?: Maybe<Sort> /** Représente le type de trie. */
	eventType?: Maybe<Sort> /** Représente le type de trie. */
	logfile?: Maybe<Sort> /** Représente le type de trie. */
	message?: Maybe<Sort> /** Représente le type de trie. */
	sourceName?: Maybe<Sort> /** Représente le type de trie. */
	timeGenerated?: Maybe<Sort> /** Représente le type de trie. */
	timeWritten?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	user?: Maybe<Sort> /** Représente le type de trie. */
	driver?: Maybe<Sort> /** Représente le type de trie. */
	apiLevel?: Maybe<Sort> /** Représente le type de trie. */
	connectFunctions?: Maybe<Sort> /** Représente le type de trie. */
	driverOdbcVer?: Maybe<Sort> /** Représente le type de trie. */
	fileExtns?: Maybe<Sort> /** Représente le type de trie. */
	fileUsage?: Maybe<Sort> /** Représente le type de trie. */
	setup?: Maybe<Sort> /** Représente le type de trie. */
	sqlLevel?: Maybe<Sort> /** Représente le type de trie. */
	usageCount?: Maybe<Sort> /** Représente le type de trie. */
	bootDevice?: Maybe<Sort> /** Représente le type de trie. */
	buildType?: Maybe<Sort> /** Représente le type de trie. */
	codeSet?: Maybe<Sort> /** Représente le type de trie. */
	csdVersion?: Maybe<Sort> /** Représente le type de trie. */
	debug?: Maybe<Sort> /** Représente le type de trie. */
	distributed?: Maybe<Sort> /** Représente le type de trie. */
	encryptionLevel?: Maybe<Sort> /** Représente le type de trie. */
	foregroundApplicationBoost?: Maybe<Sort> /** Représente le type de trie. */
	freePhysicalMemory?: Maybe<Sort> /** Représente le type de trie. */
	freeSpaceInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	freeVirtualMemory?: Maybe<Sort> /** Représente le type de trie. */
	largeSystemCache?: Maybe<Sort> /** Représente le type de trie. */
	lastBootUpTime?: Maybe<Sort> /** Représente le type de trie. */
	localDateTime?: Maybe<Sort> /** Représente le type de trie. */
	locale?: Maybe<Sort> /** Représente le type de trie. */
	maxNumberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	maxProcessMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLicensedUsers?: Maybe<Sort> /** Représente le type de trie. */
	numberOfProcesses?: Maybe<Sort> /** Représente le type de trie. */
	numberOfUsers?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystemSku?: Maybe<Sort> /** Représente le type de trie. */
	organization?: Maybe<Sort> /** Représente le type de trie. */
	osArchitecture?: Maybe<Sort> /** Représente le type de trie. */
	osLanguage?: Maybe<Sort> /** Représente le type de trie. */
	osProductSuite?: Maybe<Sort> /** Représente le type de trie. */
	osType?: Maybe<Sort> /** Représente le type de trie. */
	otherTypeDescription?: Maybe<Sort> /** Représente le type de trie. */
	paeEnabled?: Maybe<Sort> /** Représente le type de trie. */
	plusProductId?: Maybe<Sort> /** Représente le type de trie. */
	plusVersionNumber?: Maybe<Sort> /** Représente le type de trie. */
	primary?: Maybe<Sort> /** Représente le type de trie. */
	productType?: Maybe<Sort> /** Représente le type de trie. */
	registeredUser?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	servicePackMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	sizeStoredInPagingFiles?: Maybe<Sort> /** Représente le type de trie. */
	suiteMask?: Maybe<Sort> /** Représente le type de trie. */
	systemDevice?: Maybe<Sort> /** Représente le type de trie. */
	systemDirectory?: Maybe<Sort> /** Représente le type de trie. */
	systemDrive?: Maybe<Sort> /** Représente le type de trie. */
	totalSwapSpaceSize?: Maybe<Sort> /** Représente le type de trie. */
	totalVirtualMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	totalVisibleMemorySize?: Maybe<Sort> /** Représente le type de trie. */
	windowsDirectory?: Maybe<Sort> /** Représente le type de trie. */
	digitalProductId?: Maybe<Sort> /** Représente le type de trie. */
	installDate?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	installState?: Maybe<Sort> /** Représente le type de trie. */
	autoReboot?: Maybe<Sort> /** Représente le type de trie. */
	debugFilePath?: Maybe<Sort> /** Représente le type de trie. */
	debugInfoType?: Maybe<Sort> /** Représente le type de trie. */
	expandedDebugFilePath?: Maybe<Sort> /** Représente le type de trie. */
	expandedMiniDumpDirectory?: Maybe<Sort> /** Représente le type de trie. */
	kernelDumpOnly?: Maybe<Sort> /** Représente le type de trie. */
	miniDumpDirectory?: Maybe<Sort> /** Représente le type de trie. */
	overwriteExistingDebugFile?: Maybe<Sort> /** Représente le type de trie. */
	sendAdminAlert?: Maybe<Sort> /** Représente le type de trie. */
	writeDebugInfo?: Maybe<Sort> /** Représente le type de trie. */
	writeToSystemLog?: Maybe<Sort> /** Représente le type de trie. */
	initialSize?: Maybe<Sort> /** Représente le type de trie. */
	maximumSize?: Maybe<Sort> /** Représente le type de trie. */
	bankLabel?: Maybe<Sort> /** Représente le type de trie. */
	capacity?: Maybe<Sort> /** Représente le type de trie. */
	dataWidth?: Maybe<Sort> /** Représente le type de trie. */
	deviceLocator?: Maybe<Sort> /** Représente le type de trie. */
	formFactor?: Maybe<Sort> /** Représente le type de trie. */
	hotSwappable?: Maybe<Sort> /** Représente le type de trie. */
	interleaveDataDepth?: Maybe<Sort> /** Représente le type de trie. */
	interleavePosition?: Maybe<Sort> /** Représente le type de trie. */
	memoryType?: Maybe<Sort> /** Représente le type de trie. */
	partNumber?: Maybe<Sort> /** Représente le type de trie. */
	positionInRow?: Maybe<Sort> /** Représente le type de trie. */
	poweredOn?: Maybe<Sort> /** Représente le type de trie. */
	removable?: Maybe<Sort> /** Représente le type de trie. */
	replaceable?: Maybe<Sort> /** Représente le type de trie. */
	tag?: Maybe<Sort> /** Représente le type de trie. */
	totalWidth?: Maybe<Sort> /** Représente le type de trie. */
	location?: Maybe<Sort> /** Représente le type de trie. */
	maxCapacity?: Maybe<Sort> /** Représente le type de trie. */
	memoryDevices?: Maybe<Sort> /** Représente le type de trie. */
	memoryErrorCorrection?: Maybe<Sort> /** Représente le type de trie. */
	use?: Maybe<Sort> /** Représente le type de trie. */
	classGuid?: Maybe<Sort> /** Représente le type de trie. */
	pnpEntityString?: Maybe<Sort> /** Représente le type de trie. */
	service?: Maybe<Sort> /** Représente le type de trie. */
	configManagerErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	configManagerErrorCodeString?: Maybe<Sort> /** Représente le type de trie. */
	errorDescription?: Maybe<Sort> /** Représente le type de trie. */
	compatId?: Maybe<Sort> /** Représente le type de trie. */
	deviceClass?: Maybe<Sort> /** Représente le type de trie. */
	deviceName?: Maybe<Sort> /** Représente le type de trie. */
	devLoader?: Maybe<Sort> /** Représente le type de trie. */
	driverDate?: Maybe<Sort> /** Représente le type de trie. */
	driverName?: Maybe<Sort> /** Représente le type de trie. */
	driverVersion?: Maybe<Sort> /** Représente le type de trie. */
	friendlyName?: Maybe<Sort> /** Représente le type de trie. */
	hardWareId?: Maybe<Sort> /** Représente le type de trie. */
	infName?: Maybe<Sort> /** Représente le type de trie. */
	isSigned?: Maybe<Sort> /** Représente le type de trie. */
	pdo?: Maybe<Sort> /** Représente le type de trie. */
	driverProviderName?: Maybe<Sort> /** Représente le type de trie. */
	signer?: Maybe<Sort> /** Représente le type de trie. */
	started?: Maybe<Sort> /** Représente le type de trie. */
	startMode?: Maybe<Sort> /** Représente le type de trie. */
	hardwareType?: Maybe<Sort> /** Représente le type de trie. */
	infFileName?: Maybe<Sort> /** Représente le type de trie. */
	infSection?: Maybe<Sort> /** Représente le type de trie. */
	numberOfButtons?: Maybe<Sort> /** Représente le type de trie. */
	capacityMultiplier?: Maybe<Sort> /** Représente le type de trie. */
	maxBatteryError?: Maybe<Sort> /** Représente le type de trie. */
	smartBatteryVersion?: Maybe<Sort> /** Représente le type de trie. */
	externalReferenceDesignator?: Maybe<Sort> /** Représente le type de trie. */
	internalReferenceDesignator?: Maybe<Sort> /** Représente le type de trie. */
	portType?: Maybe<Sort> /** Représente le type de trie. */
	portName?: Maybe<Sort> /** Représente le type de trie. */
	portNumber?: Maybe<Sort> /** Représente le type de trie. */
	open?: Maybe<Sort> /** Représente le type de trie. */
	action?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Maybe<Sort> /** Représente le type de trie. */
	dataType?: Maybe<Sort> /** Représente le type de trie. */
	printProcessor?: Maybe<Sort> /** Représente le type de trie. */
	printerDriver?: Maybe<Sort> /** Représente le type de trie. */
	priority?: Maybe<Sort> /** Représente le type de trie. */
	separatorFile?: Maybe<Sort> /** Représente le type de trie. */
	shareName?: Maybe<Sort> /** Représente le type de trie. */
	isNetWork?: Maybe<Sort> /** Représente le type de trie. */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	deviceInstanceId?: Maybe<Sort> /** Représente le type de trie. */
	printerName?: Maybe<Sort> /** Représente le type de trie. */
	printShareName?: Maybe<Sort> /** Représente le type de trie. */
	serverName?: Maybe<Sort> /** Représente le type de trie. */
	shortServerName?: Maybe<Sort> /** Représente le type de trie. */
	handle?: Maybe<Sort> /** Représente le type de trie. */
	processId?: Maybe<Sort> /** Représente le type de trie. */
	commandLine?: Maybe<Sort> /** Représente le type de trie. */
	executablePath?: Maybe<Sort> /** Représente le type de trie. */
	osCreationClassName?: Maybe<Sort> /** Représente le type de trie. */
	osName?: Maybe<Sort> /** Représente le type de trie. */
	virtualSize?: Maybe<Sort> /** Représente le type de trie. */
	workingSetSize?: Maybe<Sort> /** Représente le type de trie. */
	addressWidth?: Maybe<Sort> /** Représente le type de trie. */
	architecture?: Maybe<Sort> /** Représente le type de trie. */
	availability?: Maybe<Sort> /** Représente le type de trie. */
	configManagerUserConfig?: Maybe<Sort> /** Représente le type de trie. */
	cpuStatus?: Maybe<Sort> /** Représente le type de trie. */
	currentClockSpeed?: Maybe<Sort> /** Représente le type de trie. */
	currentVoltage?: Maybe<Sort> /** Représente le type de trie. */
	errorCleared?: Maybe<Sort> /** Représente le type de trie. */
	extClock?: Maybe<Sort> /** Représente le type de trie. */
	family?: Maybe<Sort> /** Représente le type de trie. */
	l2CacheSize?: Maybe<Sort> /** Représente le type de trie. */
	l2CacheSpeed?: Maybe<Sort> /** Représente le type de trie. */
	l3CacheSize?: Maybe<Sort> /** Représente le type de trie. */
	l3CacheSpeed?: Maybe<Sort> /** Représente le type de trie. */
	level?: Maybe<Sort> /** Représente le type de trie. */
	loadPercentage?: Maybe<Sort> /** Représente le type de trie. */
	maxClockSpeed?: Maybe<Sort> /** Représente le type de trie. */
	numberOfCores?: Maybe<Sort> /** Représente le type de trie. */
	otherFamilyDescription?: Maybe<Sort> /** Représente le type de trie. */
	processorId?: Maybe<Sort> /** Représente le type de trie. */
	processorType?: Maybe<Sort> /** Représente le type de trie. */
	revision?: Maybe<Sort> /** Représente le type de trie. */
	role?: Maybe<Sort> /** Représente le type de trie. */
	socketDesignation?: Maybe<Sort> /** Représente le type de trie. */
	statusInfo?: Maybe<Sort> /** Représente le type de trie. */
	stepping?: Maybe<Sort> /** Représente le type de trie. */
	uniqueId?: Maybe<Sort> /** Représente le type de trie. */
	upgradeMethod?: Maybe<Sort> /** Représente le type de trie. */
	voltageCaps?: Maybe<Sort> /** Représente le type de trie. */
	parentDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	contact?: Maybe<Sort> /** Représente le type de trie. */
	estimatedSize?: Maybe<Sort> /** Représente le type de trie. */
	installLocation?: Maybe<Sort> /** Représente le type de trie. */
	installSource?: Maybe<Sort> /** Représente le type de trie. */
	helpLink?: Maybe<Sort> /** Représente le type de trie. */
	language?: Maybe<Sort> /** Représente le type de trie. */
	productId?: Maybe<Sort> /** Représente le type de trie. */
	uninstallString?: Maybe<Sort> /** Représente le type de trie. */
	publisher?: Maybe<Sort> /** Représente le type de trie. */
	fixComments?: Maybe<Sort> /** Représente le type de trie. */
	hotFixId?: Maybe<Sort> /** Représente le type de trie. */
	installedBy?: Maybe<Sort> /** Représente le type de trie. */
	installedOn?: Maybe<Sort> /** Représente le type de trie. */
	installedDate?: Maybe<Sort> /** Représente le type de trie. */
	servicePackInEffect?: Maybe<Sort> /** Représente le type de trie. */
	name2?: Maybe<Sort> /** Représente le type de trie. */
	value2?: Maybe<Sort> /** Représente le type de trie. */
	serialNumberMode?: Maybe<Sort> /** Représente le type de trie. */
	allowMaximum?: Maybe<Sort> /** Représente le type de trie. */
	maximumAllowed?: Maybe<Sort> /** Représente le type de trie. */
	shareSecurities?: Maybe<Sort> /** Représente le type de trie. */
	bSSid?: Maybe<Sort> /** Représente le type de trie. */
	ssid?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	maxLevel?: Maybe<Sort> /** Représente le type de trie. */
	actualLevel?: Maybe<Sort> /** Représente le type de trie. */
	colorCode?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	color?: Maybe<Sort> /** Représente le type de trie. */
	black?: Maybe<Sort> /** Représente le type de trie. */
	twoColor?: Maybe<Sort> /** Représente le type de trie. */
	monochrome?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	oid?: Maybe<Sort> /** Représente le type de trie. */
	allocationUnit?: Maybe<Sort> /** Représente le type de trie. */
	used?: Maybe<Sort> /** Représente le type de trie. */
	ifIndex?: Maybe<Sort> /** Représente le type de trie. */
	ifDescr?: Maybe<Sort> /** Représente le type de trie. */
	ifType?: Maybe<Sort> /** Représente le type de trie. */
	ifMtu?: Maybe<Sort> /** Représente le type de trie. */
	ifSpeed?: Maybe<Sort> /** Représente le type de trie. */
	ifPhysAddress?: Maybe<Sort> /** Représente le type de trie. */
	ifAdminStatus?: Maybe<Sort> /** Représente le type de trie. */
	ifOperStatus?: Maybe<Sort> /** Représente le type de trie. */
	ifLastChange?: Maybe<Sort> /** Représente le type de trie. */
	ifInOctets?: Maybe<Sort> /** Représente le type de trie. */
	ifInUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifInNUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifInDiscards?: Maybe<Sort> /** Représente le type de trie. */
	ifInErrors?: Maybe<Sort> /** Représente le type de trie. */
	ifInUnknownProtos?: Maybe<Sort> /** Représente le type de trie. */
	ifOutOctets?: Maybe<Sort> /** Représente le type de trie. */
	ifOutUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifOutNUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifOutDiscards?: Maybe<Sort> /** Représente le type de trie. */
	ifOutErrors?: Maybe<Sort> /** Représente le type de trie. */
	ifOutQLen?: Maybe<Sort> /** Représente le type de trie. */
	ifSpecific?: Maybe<Sort> /** Représente le type de trie. */
	send?: Maybe<Sort> /** Représente le type de trie. */
	receive?: Maybe<Sort> /** Représente le type de trie. */
	sysDescription?: Maybe<Sort> /** Représente le type de trie. */
	sysOid?: Maybe<Sort> /** Représente le type de trie. */
	sysUpTime?: Maybe<Sort> /** Représente le type de trie. */
	sysContact?: Maybe<Sort> /** Représente le type de trie. */
	sysName?: Maybe<Sort> /** Représente le type de trie. */
	sysLocation?: Maybe<Sort> /** Représente le type de trie. */
	sysService?: Maybe<Sort> /** Représente le type de trie. */
	snmpType?: Maybe<Sort> /** Représente le type de trie. */
	vendorId?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	instanceName?: Maybe<Sort> /** Représente le type de trie. */
	edition?: Maybe<Sort> /** Représente le type de trie. */
	licenceName?: Maybe<Sort> /** Représente le type de trie. */
	fileVersion?: Maybe<Sort> /** Représente le type de trie. */
	servicePack?: Maybe<Sort> /** Représente le type de trie. */
	wow64?: Maybe<Sort> /** Représente le type de trie. */
	dataPath?: Maybe<Sort> /** Représente le type de trie. */
	sqlService?: Array<Maybe<QuerySortTypeSqlService>> /**  */
	dataBaseInformations?: Maybe<Sort> /** Représente le type de trie. */
	audibleAlarm?: Maybe<Sort> /** Représente le type de trie. */
	chassisTypes?: Maybe<Sort> /** Représente le type de trie. */
	lockPresent?: Maybe<Sort> /** Représente le type de trie. */
	smbiosAssetTag?: Maybe<Sort> /** Représente le type de trie. */
	computerDescription?: Maybe<Sort> /** Représente le type de trie. */
	dockingMode?: Maybe<Sort> /** Représente le type de trie. */
	slotDesignation?: Maybe<Sort> /** Représente le type de trie. */
	ecc?: Maybe<Sort> /** Représente le type de trie. */
	needsCleaning?: Maybe<Sort> /** Représente le type de trie. */
	numberOfMediaSupported?: Maybe<Sort> /** Représente le type de trie. */
	uptimeCalendarMode?: Maybe<Sort> /** Représente le type de trie. */
	shutDownReason?: Maybe<Sort> /** Représente le type de trie. */
	shutDownType?: Maybe<Sort> /** Représente le type de trie. */
	shutDownProcess?: Maybe<Sort> /** Représente le type de trie. */
	shutDownCode?: Maybe<Sort> /** Représente le type de trie. */
	accountType?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	lockout?: Maybe<Sort> /** Représente le type de trie. */
	passwordChangeable?: Maybe<Sort> /** Représente le type de trie. */
	passwordExpires?: Maybe<Sort> /** Représente le type de trie. */
	passwordRequired?: Maybe<Sort> /** Représente le type de trie. */
	consentPromptBehaviorAdmin?: Maybe<Sort> /** Représente le type de trie. */
	consentPromptBehaviorUser?: Maybe<Sort> /** Représente le type de trie. */
	enableInstallerDetection?: Maybe<Sort> /** Représente le type de trie. */
	enableLua?: Maybe<Sort> /** Représente le type de trie. */
	promptOnSecureDesktop?: Maybe<Sort> /** Représente le type de trie. */
	filterAdministratorToken?: Maybe<Sort> /** Représente le type de trie. */
	enableUiaDesktopToggle?: Maybe<Sort> /** Représente le type de trie. */
	enableSecureUiaPaths?: Maybe<Sort> /** Représente le type de trie. */
	validateAdminCodeSignatures?: Maybe<Sort> /** Représente le type de trie. */
	enableVirtualization?: Maybe<Sort> /** Représente le type de trie. */
	lastDownloadTime?: Maybe<Sort> /** Représente le type de trie. */
	lastUploadTime?: Maybe<Sort> /** Représente le type de trie. */
	lastUseTime?: Maybe<Sort> /** Représente le type de trie. */
	loaded?: Maybe<Sort> /** Représente le type de trie. */
	localPath?: Maybe<Sort> /** Représente le type de trie. */
	refCount?: Maybe<Sort> /** Représente le type de trie. */
	roamingConfigured?: Maybe<Sort> /** Représente le type de trie. */
	roamingPath?: Maybe<Sort> /** Représente le type de trie. */
	roamingPreference?: Maybe<Sort> /** Représente le type de trie. */
	special?: Maybe<Sort> /** Représente le type de trie. */
	adapterCompatibility?: Maybe<Sort> /** Représente le type de trie. */
	adapterDacType?: Maybe<Sort> /** Représente le type de trie. */
	adapterRam?: Maybe<Sort> /** Représente le type de trie. */
	currentBitsPerPixel?: Maybe<Sort> /** Représente le type de trie. */
	currentHorizontalResolution?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfColors?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfColumns?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfRows?: Maybe<Sort> /** Représente le type de trie. */
	currentRefreshRate?: Maybe<Sort> /** Représente le type de trie. */
	currentScanMode?: Maybe<Sort> /** Représente le type de trie. */
	currentVerticalResolution?: Maybe<Sort> /** Représente le type de trie. */
	videoArchitecture?: Maybe<Sort> /** Représente le type de trie. */
	videoMemoryType?: Maybe<Sort> /** Représente le type de trie. */
	videoMode?: Maybe<Sort> /** Représente le type de trie. */
	videoModeDescription?: Maybe<Sort> /** Représente le type de trie. */
	videoProcessor?: Maybe<Sort> /** Représente le type de trie. */
	elementName?: Maybe<Sort> /** Représente le type de trie. */
	healthState?: Maybe<Sort> /** Représente le type de trie. */
	enabledState?: Maybe<Sort> /** Représente le type de trie. */
	otherEnabledState?: Maybe<Sort> /** Représente le type de trie. */
	requestedState?: Maybe<Sort> /** Représente le type de trie. */
	enabledDefault?: Maybe<Sort> /** Représente le type de trie. */
	timeOfLastStateChange?: Maybe<Sort> /** Représente le type de trie. */
	onTimeInMilliseconds?: Maybe<Sort> /** Représente le type de trie. */
	timeOfLastConfigurationChange?: Maybe<Sort> /** Représente le type de trie. */
	virtualMachineGuest?: Array<Maybe<QuerySortTypeVirtualMachineGuest>> /**  */
	assetLink?: Array<Maybe<QuerySortTypeAsset>> /**  */
	domainProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	publicProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	standardProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	acceptPause?: Maybe<Sort> /** Représente le type de trie. */
	acceptStop?: Maybe<Sort> /** Représente le type de trie. */
	pathName?: Maybe<Sort> /** Représente le type de trie. */
	startName?: Maybe<Sort> /** Représente le type de trie. */
	auOptions?: Maybe<Sort> /** Représente le type de trie. */
	nextDetectionTime?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallDate?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallDay?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallTime?: Maybe<Sort> /** Représente le type de trie. */
	detect?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	download?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	install?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	created?: Maybe<Sort> /** Représente le type de trie. */
	namingPolicy?: Array<Maybe<QuerySortTypeNamingConvention>> /**  */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité NetworkAdapterConfiguration */
export type QuerySortTypeNetworkAdapterConfiguration = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dhcpEnabled?: Maybe<Sort> /** Représente le type de trie. */
	dhcpServer?: Maybe<Sort> /** Représente le type de trie. */
	dnsDomain?: Maybe<Sort> /** Représente le type de trie. */
	dnsEnabledForWinsResolution?: Maybe<Sort> /** Représente le type de trie. */
	dnsHostName?: Maybe<Sort> /** Représente le type de trie. */
	domainDnsRegistrationEnabled?: Maybe<Sort> /** Représente le type de trie. */
	index?: Maybe<Sort> /** Représente le type de trie. */
	interfaceIndex?: Maybe<Sort> /** Représente le type de trie. */
	ipEnabled?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	winsEnableLmHostsLookup?: Maybe<Sort> /** Représente le type de trie. */
	winsHostLookupFile?: Maybe<Sort> /** Représente le type de trie. */
	winsPrimaryServer?: Maybe<Sort> /** Représente le type de trie. */
	winsSecondaryServer?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité SqlService */
export type QuerySortTypeSqlService = {
	acceptPause?: Maybe<Sort> /** Représente le type de trie. */
	acceptStop?: Maybe<Sort> /** Représente le type de trie. */
	binaryPath?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	errorControl?: Maybe<Sort> /** Représente le type de trie. */
	exitCode?: Maybe<Sort> /** Représente le type de trie. */
	hostName?: Maybe<Sort> /** Représente le type de trie. */
	processId?: Maybe<Sort> /** Représente le type de trie. */
	serviceName?: Maybe<Sort> /** Représente le type de trie. */
	sqlServiceType?: Maybe<Sort> /** Représente le type de trie. */
	startMode?: Maybe<Sort> /** Représente le type de trie. */
	startName?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité VirtualMachineGuest */
export type QuerySortTypeVirtualMachineGuest = {
	operatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	hostName?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	memorySize?: Maybe<Sort> /** Représente le type de trie. */
	hardDiskSize?: Maybe<Sort> /** Représente le type de trie. */
	cpuCount?: Maybe<Sort> /** Représente le type de trie. */
	vmPowerState?: Maybe<Sort> /** Représente le type de trie. */
	vmHealthStatus?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité FirewallPolicy */
export type QuerySortTypeFirewallPolicy = {
	disableNotifications?: Maybe<Sort> /** Représente le type de trie. */
	doNotAllowExceptions?: Maybe<Sort> /** Représente le type de trie. */
	enableFirewall?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WindowsUpdateResult */
export type QuerySortTypeWindowsUpdateResult = {
	lastError?: Maybe<Sort> /** Représente le type de trie. */
	lastSuccessTime?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité NamingConvention */
export type QuerySortTypeNamingConvention = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	assetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	excludeAssetCategoryIds?: Maybe<Sort> /** Représente le type de trie. */
	namingConventionCategory?: Maybe<Sort> /** Représente le type de trie. */
	locked?: Maybe<Sort> /** Représente le type de trie. */
	empty?: Maybe<Sort> /** Représente le type de trie. */
	inheritCategory?: Maybe<Sort> /** Représente le type de trie. */
	dateFormat?: Maybe<Sort> /** Représente le type de trie. */
	dateFormatWithoutHour?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	incrementNextValue?: Maybe<Sort> /** Représente le type de trie. */
	incrementLength?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfVirtualMachine = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeVirtualMachine>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité VirtualMachine */
export type QuerySortTypeVirtualMachine = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	elementName?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	healthState?: Maybe<Sort> /** Représente le type de trie. */
	enabledState?: Maybe<Sort> /** Représente le type de trie. */
	otherEnabledState?: Maybe<Sort> /** Représente le type de trie. */
	requestedState?: Maybe<Sort> /** Représente le type de trie. */
	enabledDefault?: Maybe<Sort> /** Représente le type de trie. */
	timeOfLastStateChange?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerName?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerContact?: Maybe<Sort> /** Représente le type de trie. */
	nameFormat?: Maybe<Sort> /** Représente le type de trie. */
	resetCapability?: Maybe<Sort> /** Représente le type de trie. */
	onTimeInMilliseconds?: Maybe<Sort> /** Représente le type de trie. */
	timeOfLastConfigurationChange?: Maybe<Sort> /** Représente le type de trie. */
	processId?: Maybe<Sort> /** Représente le type de trie. */
	virtualMachineGuest?: Array<Maybe<QuerySortTypeVirtualMachineGuest>> /**  */
	assetLink?: Array<Maybe<QuerySortTypeAsset>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfAccessPoint = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAccessPoint>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AccessPoint */
export type QuerySortTypeAccessPoint = {
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	bSSid?: Maybe<Sort> /** Représente le type de trie. */
	ssid?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryBuilderInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	expression?: Maybe<string> /**  */
	columns?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	displayRequestToJob: boolean /**  */
	typeTranslate?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	sftpConnectorIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type SecurityGroupObjectInput = {
	id?: string /**  */
	isManual: boolean /**  */
}


/**  */
export type QueryContextOfAccount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAccount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Account */
export type QuerySortTypeAccount = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	memberOfIds?: Maybe<Sort> /** Représente le type de trie. */
	permissionScopes?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	isSystem?: Maybe<Sort> /** Représente le type de trie. */
	firstName?: Maybe<Sort> /** Représente le type de trie. */
	lastName?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControl?: Maybe<Sort> /** Représente le type de trie. */
	userAccountDisabled?: Maybe<Sort> /** Représente le type de trie. */
	userAccountExpires?: Maybe<Sort> /** Représente le type de trie. */
	initial?: Maybe<Sort> /** Représente le type de trie. */
	registrationNumber?: Maybe<Sort> /** Représente le type de trie. */
	vip?: Maybe<Sort> /** Représente le type de trie. */
	helpDeskActivated?: Maybe<Sort> /** Représente le type de trie. */
	function?: Maybe<Sort> /** Représente le type de trie. */
	instantMessaging?: Maybe<Sort> /** Représente le type de trie. */
	userPrincipalName?: Maybe<Sort> /** Représente le type de trie. */
	title?: Maybe<Sort> /** Représente le type de trie. */
	secretary?: Maybe<Sort> /** Représente le type de trie. */
	hiringDate?: Maybe<Sort> /** Représente le type de trie. */
	exitDate?: Maybe<Sort> /** Représente le type de trie. */
	lastLogOn?: Maybe<Sort> /** Représente le type de trie. */
	lastLogonTimestamp?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnInteractive?: Maybe<Sort> /** Représente le type de trie. */
	azureLastLogOnNotInteractive?: Maybe<Sort> /** Représente le type de trie. */
	lastLogOff?: Maybe<Sort> /** Représente le type de trie. */
	lastConnected?: Maybe<Sort> /** Représente le type de trie. */
	accessFailedCount?: Maybe<Sort> /** Représente le type de trie. */
	homeDrive?: Maybe<Sort> /** Représente le type de trie. */
	homeDirectory?: Maybe<Sort> /** Représente le type de trie. */
	logonCount?: Maybe<Sort> /** Représente le type de trie. */
	profilePath?: Maybe<Sort> /** Représente le type de trie. */
	scriptPath?: Maybe<Sort> /** Représente le type de trie. */
	logOnTo?: Maybe<Sort> /** Représente le type de trie. */
	allowDialIn?: Maybe<Sort> /** Représente le type de trie. */
	officeName?: Maybe<Sort> /** Représente le type de trie. */
	principalWebSite?: Maybe<Sort> /** Représente le type de trie. */
	settings?: Array<Maybe<QuerySortTypeSettings>> /**  */
	organizationalUnit?: Array<Maybe<QuerySortTypeOrganizationalUnit>> /**  */
	userPasswords?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamIds?: Maybe<Sort> /** Représente le type de trie. */
	mainOperatorTeam?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	isOperator?: Maybe<Sort> /** Représente le type de trie. */
	loans?: Maybe<Sort> /** Représente le type de trie. */
	ticketsIds?: Maybe<Sort> /** Représente le type de trie. */
	userHabilitations?: Maybe<Sort> /** Représente le type de trie. */
	phones?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	locations?: Maybe<Sort> /** Représente le type de trie. */
	stockManagers?: Maybe<Sort> /** Représente le type de trie. */
	location?: Array<Maybe<QuerySortTypeLocation>> /**  */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	department?: Maybe<Sort> /** Représente le type de trie. */
	company?: Maybe<Sort> /** Représente le type de trie. */
	manager?: Maybe<Sort> /** Représente le type de trie. */
	managerRef?: Array<Maybe<QuerySortTypeUser>> /**  */
	userManager?: Array<Maybe<QuerySortTypeUser>> /**  */
	supplier?: Array<Maybe<QuerySortTypeSupplier>> /**  */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	containerDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	firstImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportLdap?: Maybe<Sort> /** Représente le type de trie. */
	lastImportAzureAd?: Maybe<Sort> /** Représente le type de trie. */
	scanByLdap?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	principalPhone?: Maybe<Sort> /** Représente le type de trie. */
	principalFax?: Maybe<Sort> /** Représente le type de trie. */
	principalHomePhone?: Maybe<Sort> /** Représente le type de trie. */
	principalIpPhone?: Maybe<Sort> /** Représente le type de trie. */
	principalMobile?: Maybe<Sort> /** Représente le type de trie. */
	principalPager?: Maybe<Sort> /** Représente le type de trie. */
	memberOfAzureGroup?: Maybe<Sort> /** Représente le type de trie. */
	assetIds?: Maybe<Sort> /** Représente le type de trie. */
	invitation?: Array<Maybe<QuerySortTypeInvitation>> /**  */
	category?: Maybe<Sort> /** Représente le type de trie. */
	userAffectedRule?: Array<Maybe<QuerySortTypeUserAffectedRule>> /**  */
	populationsIds?: Maybe<Sort> /** Représente le type de trie. */
	contractIds?: Maybe<Sort> /** Représente le type de trie. */
	forceLdapImport?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	securityGroups?: Maybe<Sort> /** Représente le type de trie. */
	securityGroup?: Array<Maybe<QuerySortTypeSecurityGroupObject>> /**  */
	memberOfSecurityGroupIds?: Maybe<Sort> /** Représente le type de trie. */
	memberOfPopulationIds?: Maybe<Sort> /** Représente le type de trie. */
	softwarePackageIds?: Maybe<Sort> /** Représente le type de trie. */
	locationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	inviteBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	revokeBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	costHours?: Maybe<Sort> /** Représente le type de trie. */
	supportLevelOperator?: Maybe<Sort> /** Représente le type de trie. */
	azureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	managerAzureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	mainAsset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	inheritLocationCharterIds?: Maybe<Sort> /** Représente le type de trie. */
	inheritLocationCharterLocation?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	inheritLocationCharterOrganizationalUnit?: Array<Maybe<QuerySortTypeInheritLocationCharter>> /**  */
	entityLocationCharter?: Array<Maybe<QuerySortTypeEntityLocationCharter>> /**  */
	privilege?: Array<Maybe<QuerySortTypePrivilege>> /**  */
	userPrivilege?: Array<Maybe<QuerySortTypeUserPrivilege>> /**  */
	locationCharterAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	securityGroupAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	populationAlreadyApply?: Maybe<Sort> /** Représente le type de trie. */
	calendar?: Array<Maybe<QuerySortTypeCalendarConnector>> /**  */
	modelRulesDesignerIds?: Maybe<Sort> /** Représente le type de trie. */
	formDesignerIds?: Maybe<Sort> /** Représente le type de trie. */
	serviceDeskActivated?: Maybe<Sort> /** Représente le type de trie. */
	dashboardIds?: Maybe<Sort> /** Représente le type de trie. */
	allowEditOrganization?: Maybe<Sort> /** Représente le type de trie. */
	recipentSystemNotification?: Maybe<Sort> /** Représente le type de trie. */
	businessTime?: Array<Maybe<QuerySortTypeBusinessTime>> /**  */
	userContractType?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfRole = {
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	isSystem?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	memberOfIds?: Maybe<ArrayUuidTypeOperator> /**  */
	permissionScopes?: Maybe<ArrayPermissionScopeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfRole>> /**  */
	or?: Array<Maybe<FilterOfRole>> /**  */
	nor?: Array<Maybe<FilterOfRole>> /**  */
	not?: Array<Maybe<FilterOfRole>> /**  */
}


/**  */
export type QueryContextOfRole = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeRole>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Role */
export type QuerySortTypeRole = {
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	isSystem?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	memberOfIds?: Maybe<Sort> /** Représente le type de trie. */
	permissionScopes?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Account */
export type FieldUpdateOperatorOfAccount = {
	set?: Maybe<SetOperatorInputOfAccount> /**  */
	push?: Maybe<PushUpdateOperatorInputAccount> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAccount> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Account */
export type SetOperatorInputOfAccount = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type PermissionScopeInput = {
	value?: Maybe<string> /**  */
	type: PermissionScopeRole /**  */
	permissions?: Array<Maybe<PermissionInput>> /**  */
}


/**  */
export type PermissionInput = {
	order: number /**  */
	value?: Maybe<string> /**  */
	access: PermissionAccess /**  */
	inheritFromIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Account */
export type PushUpdateOperatorInputAccount = {
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Account */
export type PullUpdateOperatorInputAccount = {
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfDynamicPropertyField = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	position?: Maybe<FilterInt32Operator> /**  */
	dynamicPropertyGroupId?: Maybe<FilterGuidOperator> /**  */
	dynamicPropertyGroup?: Maybe<FilterDenormalizeOfDynamicPropertyGroupByDynamicPropertyGroupInDynamicPropertyField> /**  */
	propertyName?: Maybe<FilterStringOperator> /**  */
	entityTypes?: Maybe<ArrayStringTypeOperator> /**  */
	dynamicPropertyFieldType?: Maybe<FilterDynamicPropertyFieldEnumOperator> /**  */
	dynamicPropertyNumericFormat?: Maybe<FilterOfDynamicPropertyNumeric> /**  */
	dynamicPropertyDateTime?: Maybe<FilterOfDynamicPropertyDateTime> /**  */
	dynamicPropertySelectList?: Maybe<FilterOfDynamicPropertySelectList> /**  */
	dynamicPropertyColumnOption?: Maybe<FilterOfDynamicPropertyColumnOption> /**  */
	dynamicPropertyFormOption?: Maybe<FilterOfDynamicPropertyFormOption> /**  */
	expression?: Maybe<ArrayQueryFilterOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyField>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyField>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyField>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyField>> /**  */
}


/**  */
export type FilterDenormalizeOfDynamicPropertyGroupByDynamicPropertyGroupInDynamicPropertyField = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDynamicPropertyFieldEnumOperator = {
	eq?: Maybe<DynamicPropertyFieldEnum> /**  */
	ne?: Maybe<DynamicPropertyFieldEnum> /**  */
	in?: Array<Maybe<DynamicPropertyFieldEnum>> /**  */
	nin?: Array<Maybe<DynamicPropertyFieldEnum>> /**  */
}


/**  */
export type FilterOfDynamicPropertyNumeric = {
	dynamicPropertyNumericFormat?: Maybe<FilterDynamicPropertyNumericFormatOperator> /**  */
	minimun?: Maybe<FilterInt32Operator> /**  */
	maximun?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyNumeric>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyNumeric>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyNumeric>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyNumeric>> /**  */
}


/**  */
export type FilterDynamicPropertyNumericFormatOperator = {
	eq?: Maybe<DynamicPropertyNumericFormat> /**  */
	ne?: Maybe<DynamicPropertyNumericFormat> /**  */
	in?: Array<Maybe<DynamicPropertyNumericFormat>> /**  */
	nin?: Array<Maybe<DynamicPropertyNumericFormat>> /**  */
}


/**  */
export type FilterOfDynamicPropertyDateTime = {
	dynamicPropertyDateFormat?: Maybe<FilterDynamicPropertyDateFormatOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyDateTime>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyDateTime>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyDateTime>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyDateTime>> /**  */
}


/**  */
export type FilterDynamicPropertyDateFormatOperator = {
	eq?: Maybe<DynamicPropertyDateFormat> /**  */
	ne?: Maybe<DynamicPropertyDateFormat> /**  */
	in?: Array<Maybe<DynamicPropertyDateFormat>> /**  */
	nin?: Array<Maybe<DynamicPropertyDateFormat>> /**  */
}


/**  */
export type FilterOfDynamicPropertySelectList = {
	dynamicPropertyListId?: Maybe<FilterGuidOperator> /**  */
	multiple?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertySelectList>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertySelectList>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertySelectList>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertySelectList>> /**  */
}


/**  */
export type FilterOfDynamicPropertyColumnOption = {
	sortable?: Maybe<FilterBooleanOperator> /**  */
	filterable?: Maybe<FilterBooleanOperator> /**  */
	showInColumnChooser?: Maybe<FilterBooleanOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyColumnOption>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyColumnOption>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyColumnOption>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyColumnOption>> /**  */
}


/**  */
export type FilterOfDynamicPropertyFormOption = {
	required?: Maybe<FilterBooleanOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyFormOption>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyFormOption>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyFormOption>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyFormOption>> /**  */
}


/**  */
export type QueryContextOfDynamicPropertyField = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDynamicPropertyField>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyField */
export type QuerySortTypeDynamicPropertyField = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	position?: Maybe<Sort> /** Représente le type de trie. */
	dynamicPropertyGroup?: Array<Maybe<QuerySortTypeDynamicPropertyGroup>> /**  */
	propertyName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicPropertyFieldType?: Maybe<Sort> /** Représente le type de trie. */
	dynamicPropertyNumericFormat?: Array<Maybe<QuerySortTypeDynamicPropertyNumeric>> /**  */
	dynamicPropertyDateTime?: Array<Maybe<QuerySortTypeDynamicPropertyDateTime>> /**  */
	dynamicPropertySelectList?: Array<Maybe<QuerySortTypeDynamicPropertySelectList>> /**  */
	dynamicPropertyColumnOption?: Array<Maybe<QuerySortTypeDynamicPropertyColumnOption>> /**  */
	dynamicPropertyFormOption?: Array<Maybe<QuerySortTypeDynamicPropertyFormOption>> /**  */
	expression?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyGroup */
export type QuerySortTypeDynamicPropertyGroup = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	position?: Maybe<Sort> /** Représente le type de trie. */
	collapse?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	useFreeSpace?: Maybe<Sort> /** Représente le type de trie. */
	dynamicPropertyLocation?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyNumeric */
export type QuerySortTypeDynamicPropertyNumeric = {
	dynamicPropertyNumericFormat?: Maybe<Sort> /** Représente le type de trie. */
	minimun?: Maybe<Sort> /** Représente le type de trie. */
	maximun?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyDateTime */
export type QuerySortTypeDynamicPropertyDateTime = {
	dynamicPropertyDateFormat?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertySelectList */
export type QuerySortTypeDynamicPropertySelectList = {
	dynamicPropertyList?: Array<Maybe<QuerySortTypeDynamicPropertyList>> /**  */
	multiple?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyList */
export type QuerySortTypeDynamicPropertyList = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	values?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyColumnOption */
export type QuerySortTypeDynamicPropertyColumnOption = {
	sortable?: Maybe<Sort> /** Représente le type de trie. */
	filterable?: Maybe<Sort> /** Représente le type de trie. */
	showInColumnChooser?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyFormOption */
export type QuerySortTypeDynamicPropertyFormOption = {
	required?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Activity */
export type FieldUpdateOperatorOfActivity = {
	set?: Maybe<SetOperatorInputOfActivity> /**  */
	inc?: Maybe<IncOperatorInputActivity> /**  */
	push?: Maybe<PushUpdateOperatorInputActivity> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputActivity> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Activity */
export type SetOperatorInputOfActivity = {
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TranslatedField */
export type SetOperatorInputOfTranslatedField = {
	fr?: Maybe<string> /**  */
	nl?: Maybe<string> /**  */
	en?: Maybe<string> /**  */
	it?: Maybe<string> /**  */
	de?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Activity */
export type IncOperatorInputActivity = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Activity */
export type PushUpdateOperatorInputActivity = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Activity */
export type PullUpdateOperatorInputActivity = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfAffectedRule = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	searchInDeleted?: Maybe<FilterBooleanOperator> /**  */
	searchInArchived?: Maybe<FilterBooleanOperator> /**  */
	rankOrder?: Maybe<FilterInt64Operator> /**  */
	rankState?: Maybe<FilterRankStateOperator> /**  */
	canDrag?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	byNetbios?: Maybe<FilterBooleanOperator> /**  */
	byMacAddress?: Maybe<FilterBooleanOperator> /**  */
	byIpAddress?: Maybe<FilterBooleanOperator> /**  */
	bySerialNumber?: Maybe<FilterBooleanOperator> /**  */
	byUuid?: Maybe<FilterBooleanOperator> /**  */
	byDistinguishedName?: Maybe<FilterBooleanOperator> /**  */
	byFqdn?: Maybe<FilterBooleanOperator> /**  */
	byName?: Maybe<FilterBooleanOperator> /**  */
	auditTypes?: Maybe<ArrayAuditTypeTypeOperator> /**  */
	bySid?: Maybe<FilterBooleanOperator> /**  */
	byEmail?: Maybe<FilterBooleanOperator> /**  */
	byDisplayName?: Maybe<FilterBooleanOperator> /**  */
	byUserPrincipalName?: Maybe<FilterBooleanOperator> /**  */
	byLastNameAndFirstName?: Maybe<FilterBooleanOperator> /**  */
	byDomain?: Maybe<FilterBooleanOperator> /**  */
	byDifferentDomain?: Maybe<FilterBooleanOperator> /**  */
	byAzureObjectId?: Maybe<FilterBooleanOperator> /**  */
	byDomainEnum?: Maybe<FilterByDomainEnumOperator> /**  */
	and?: Array<Maybe<FilterOfAffectedRule>> /**  */
	or?: Array<Maybe<FilterOfAffectedRule>> /**  */
	nor?: Array<Maybe<FilterOfAffectedRule>> /**  */
	not?: Array<Maybe<FilterOfAffectedRule>> /**  */
}


/**  */
export type QueryContextOfAffectedRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAffectedRule>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AffectedRule */
export type QuerySortTypeAffectedRule = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	searchInDeleted?: Maybe<Sort> /** Représente le type de trie. */
	searchInArchived?: Maybe<Sort> /** Représente le type de trie. */
	rankOrder?: Maybe<Sort> /** Représente le type de trie. */
	rankState?: Maybe<Sort> /** Représente le type de trie. */
	canDrag?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	byNetbios?: Maybe<Sort> /** Représente le type de trie. */
	byMacAddress?: Maybe<Sort> /** Représente le type de trie. */
	byIpAddress?: Maybe<Sort> /** Représente le type de trie. */
	bySerialNumber?: Maybe<Sort> /** Représente le type de trie. */
	byUuid?: Maybe<Sort> /** Représente le type de trie. */
	byDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	byFqdn?: Maybe<Sort> /** Représente le type de trie. */
	byName?: Maybe<Sort> /** Représente le type de trie. */
	bySid?: Maybe<Sort> /** Représente le type de trie. */
	byEmail?: Maybe<Sort> /** Représente le type de trie. */
	byDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	byUserPrincipalName?: Maybe<Sort> /** Représente le type de trie. */
	byLastNameAndFirstName?: Maybe<Sort> /** Représente le type de trie. */
	byDomain?: Maybe<Sort> /** Représente le type de trie. */
	byDifferentDomain?: Maybe<Sort> /** Représente le type de trie. */
	byAzureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	byDomainEnum?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAgentConfiguration = {
	configuration?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAgentConfiguration>> /**  */
	or?: Array<Maybe<FilterOfAgentConfiguration>> /**  */
	nor?: Array<Maybe<FilterOfAgentConfiguration>> /**  */
	not?: Array<Maybe<FilterOfAgentConfiguration>> /**  */
}


/**  */
export type QueryContextOfAgentConfiguration = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAgentConfiguration>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AgentConfiguration */
export type QuerySortTypeAgentConfiguration = {
	configuration?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAgentHistory = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	lastChanged?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	actionHistoryState?: Maybe<FilterActionHistoryStateOperator> /**  */
	host?: Maybe<FilterStringOperator> /**  */
	clarilogException?: Maybe<FilterOfClarilogException> /**  */
	progressValue?: Maybe<FilterInt32Operator> /**  */
	progressDisplay?: Maybe<FilterStringOperator> /**  */
	log?: Maybe<FilterStringOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInAgentHistory> /**  */
	assetAffectedRule?: Maybe<FilterOfAssetAffectedRule> /**  */
	hidden?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	clientApplicationtName?: Maybe<FilterStringOperator> /**  */
	clientApplicationtVersion?: Maybe<FilterStringOperator> /**  */
	auditTimes?: Maybe<ArrayAuditTimeOperator> /**  */
	networkCredentialId?: Maybe<FilterGuidOperator> /**  */
	networkCredential?: Maybe<FilterDenormalizeOfNetworkCredentialByNetworkCredentialInAgentHistory> /**  */
	scanConfigurationId?: Maybe<FilterGuidOperator> /**  */
	scanConfiguration?: Maybe<FilterDenormalizeOfScanConfigurationByScanConfigurationInAgentHistory> /**  */
	auditType?: Maybe<FilterAuditTypeOperator> /**  */
	new?: Maybe<FilterBooleanOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	resolveName?: Maybe<FilterStringOperator> /**  */
	scanImportOptionId?: Maybe<FilterGuidOperator> /**  */
	scanImportOption?: Maybe<FilterDenormalizeOfScanImportOptionByScanImportOptionInAgentHistory> /**  */
	attempts?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfAgentHistory>> /**  */
	or?: Array<Maybe<FilterOfAgentHistory>> /**  */
	nor?: Array<Maybe<FilterOfAgentHistory>> /**  */
	not?: Array<Maybe<FilterOfAgentHistory>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInAgentHistory = {
	name?: Maybe<FilterStringOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterDenormalizeOfNetworkCredentialByNetworkCredentialInAgentHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanConfigurationByScanConfigurationInAgentHistory = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfScanImportOptionByScanImportOptionInAgentHistory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfAgentHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAgentHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AgentHistory */
export type QuerySortTypeAgentHistory = {
	start?: Maybe<Sort> /** Représente le type de trie. */
	lastChanged?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	actionHistoryState?: Maybe<Sort> /** Représente le type de trie. */
	host?: Maybe<Sort> /** Représente le type de trie. */
	clarilogException?: Array<Maybe<QuerySortTypeClarilogException>> /**  */
	progressValue?: Maybe<Sort> /** Représente le type de trie. */
	progressDisplay?: Maybe<Sort> /** Représente le type de trie. */
	log?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	assetAffectedRule?: Array<Maybe<QuerySortTypeAssetAffectedRule>> /**  */
	hidden?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtName?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtVersion?: Maybe<Sort> /** Représente le type de trie. */
	auditTimes?: Maybe<Sort> /** Représente le type de trie. */
	networkCredential?: Array<Maybe<QuerySortTypeNetworkCredential>> /**  */
	scanConfiguration?: Array<Maybe<QuerySortTypeScanConfiguration>> /**  */
	auditType?: Maybe<Sort> /** Représente le type de trie. */
	new?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	resolveName?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOption?: Array<Maybe<QuerySortTypeScanImportOption>> /**  */
	attempts?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAgentToken = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	agentTokenAction?: Maybe<FilterAgentTokenActionOperator> /**  */
	clientApplicationtName?: Maybe<FilterStringOperator> /**  */
	agentId?: Maybe<FilterGuidOperator> /**  */
	query?: Maybe<FilterStringOperator> /**  */
	queryName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAgentToken>> /**  */
	or?: Array<Maybe<FilterOfAgentToken>> /**  */
	nor?: Array<Maybe<FilterOfAgentToken>> /**  */
	not?: Array<Maybe<FilterOfAgentToken>> /**  */
}


/**  */
export type FilterAgentTokenActionOperator = {
	eq?: Maybe<AgentTokenAction> /**  */
	ne?: Maybe<AgentTokenAction> /**  */
	in?: Array<Maybe<AgentTokenAction>> /**  */
	nin?: Array<Maybe<AgentTokenAction>> /**  */
}


/**  */
export type QueryContextOfAgentToken = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAgentToken>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AgentToken */
export type QuerySortTypeAgentToken = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	agentTokenAction?: Maybe<Sort> /** Représente le type de trie. */
	clientApplicationtName?: Maybe<Sort> /** Représente le type de trie. */
	query?: Maybe<Sort> /** Représente le type de trie. */
	queryName?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type AgentTokenInput = {
	agentTokenAction: AgentTokenAction /**  */
	clientApplicationtName?: Maybe<string> /**  */
	agentId: string /**  */
	query?: Maybe<string> /**  */
	queryName?: Maybe<string> /**  */
}


/**  */
export type FilterOfAlarmMailTask = {
	emailPrototypeId?: Maybe<FilterGuidOperator> /**  */
	queryContext?: Maybe<FilterStringOperator> /**  */
	force?: Maybe<FilterBooleanOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAlarmMailTask>> /**  */
	or?: Array<Maybe<FilterOfAlarmMailTask>> /**  */
	nor?: Array<Maybe<FilterOfAlarmMailTask>> /**  */
	not?: Array<Maybe<FilterOfAlarmMailTask>> /**  */
}


/**  */
export type QueryContextOfAlarmMailTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAlarmMailTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AlarmMailTask */
export type QuerySortTypeAlarmMailTask = {
	queryContext?: Maybe<Sort> /** Représente le type de trie. */
	force?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Alert */
export type FieldUpdateOperatorOfAlert = {
	set?: Maybe<SetOperatorInputOfAlert> /**  */
	inc?: Maybe<IncOperatorInputAlert> /**  */
	push?: Maybe<PushUpdateOperatorInputAlert> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAlert> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Alert */
export type SetOperatorInputOfAlert = {
	name?: Maybe<string> /**  */
	alarmId?: Maybe<string> /**  */
	dateOfCreation?: Maybe<Date> /**  */
	acknowledgedUsersIds: Array<string> /**  */
	affectedUsersIds: Array<string> /**  */
	hiddenUsersIds: Array<string> /**  */
	hidden?: Maybe<boolean> /**  */
	assetId?: Maybe<string> /**  */
	softwareId?: Maybe<string> /**  */
	softwarePackageId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	contractId?: Maybe<string> /**  */
	loanId?: Maybe<string> /**  */
	assetModelId?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	logicalDiskId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	printerConsumableId?: Maybe<string> /**  */
	messageId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	softwareGroupId?: Maybe<string> /**  */
	problemId?: Maybe<string> /**  */
	incidentId?: Maybe<string> /**  */
	requestId?: Maybe<string> /**  */
	ticketTaskId?: Maybe<string> /**  */
	interventionId?: Maybe<string> /**  */
	sended?: Maybe<boolean> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	minimumStockByStorageUnitAlertResult?: Maybe<SetOperatorInputOfMinimumStockByStorageUnitAlertResult> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MinimumStockByStorageUnitAlertResult */
export type SetOperatorInputOfMinimumStockByStorageUnitAlertResult = {
	minimum?: Maybe<number> /**  */
	currentAmount?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité Alert */
export type IncOperatorInputAlert = {
	minimumStockByStorageUnitAlertResult?: Maybe<IncOperatorInputMinimumStockByStorageUnitAlertResult> /**  */
}


/** Représente les propriétés de type numérique de l'entité MinimumStockByStorageUnitAlertResult */
export type IncOperatorInputMinimumStockByStorageUnitAlertResult = {
	minimum?: Maybe<number> /**  */
	currentAmount?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Alert */
export type PushUpdateOperatorInputAlert = {
	acknowledgedUsersIds: Array<string> /**  */
	affectedUsersIds: Array<string> /**  */
	hiddenUsersIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Alert */
export type PullUpdateOperatorInputAlert = {
	acknowledgedUsersIds: Array<string> /**  */
	affectedUsersIds: Array<string> /**  */
	hiddenUsersIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfAlertTask = {
	alarmTriggerType?: Maybe<FilterAlarmTriggerTypeEnumOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAlertTask>> /**  */
	or?: Array<Maybe<FilterOfAlertTask>> /**  */
	nor?: Array<Maybe<FilterOfAlertTask>> /**  */
	not?: Array<Maybe<FilterOfAlertTask>> /**  */
}


/**  */
export type QueryContextOfAlertTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAlertTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AlertTask */
export type QuerySortTypeAlertTask = {
	alarmTriggerType?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAnalyseScanByAzureAdTask = {
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAnalyseScanByAzureAdTask>> /**  */
	or?: Array<Maybe<FilterOfAnalyseScanByAzureAdTask>> /**  */
	nor?: Array<Maybe<FilterOfAnalyseScanByAzureAdTask>> /**  */
	not?: Array<Maybe<FilterOfAnalyseScanByAzureAdTask>> /**  */
}


/**  */
export type QueryContextOfAnalyseScanByAzureAdTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAnalyseScanByAzureAdTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AnalyseScanByAzureAdTask */
export type QuerySortTypeAnalyseScanByAzureAdTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfAntiSpywareProduct = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAntiSpywareProduct>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AntiSpywareProduct */
export type QuerySortTypeAntiSpywareProduct = {
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	instanceGuid?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedProductExe?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedReportingExe?: Maybe<Sort> /** Représente le type de trie. */
	productState?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfAntiVirusProduct = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAntiVirusProduct>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AntiVirusProduct */
export type QuerySortTypeAntiVirusProduct = {
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	instanceGuid?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedProductExe?: Maybe<Sort> /** Représente le type de trie. */
	pathToSignedReportingExe?: Maybe<Sort> /** Représente le type de trie. */
	productState?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfApplicationAzureAd = {
	name?: Maybe<FilterStringOperator> /**  */
	tenantAzureId?: Maybe<FilterStringOperator> /**  */
	clientId?: Maybe<FilterStringOperator> /**  */
	clientSecret?: Maybe<FilterStringOperator> /**  */
	expireClientSecretDate?: Maybe<FilterDateTimeOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	environnement?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfApplicationAzureAd>> /**  */
	or?: Array<Maybe<FilterOfApplicationAzureAd>> /**  */
	nor?: Array<Maybe<FilterOfApplicationAzureAd>> /**  */
	not?: Array<Maybe<FilterOfApplicationAzureAd>> /**  */
}


/**  */
export type QueryContextOfApplicationAzureAd = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeApplicationAzureAd>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAppLog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAppLog>> /**  */
	or?: Array<Maybe<FilterOfAppLog>> /**  */
	nor?: Array<Maybe<FilterOfAppLog>> /**  */
	not?: Array<Maybe<FilterOfAppLog>> /**  */
}


/**  */
export type QueryContextOfAppLog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAppLog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AppLog */
export type QuerySortTypeAppLog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAppointment>> /**  */
	or?: Array<Maybe<FilterOfAppointment>> /**  */
	nor?: Array<Maybe<FilterOfAppointment>> /**  */
	not?: Array<Maybe<FilterOfAppointment>> /**  */
}


/**  */
export type QueryContextOfAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Appointment */
export type QuerySortTypeAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfArticleKnowledgeCategory = {
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfArticleKnowledgeCategory>> /**  */
	or?: Array<Maybe<FilterOfArticleKnowledgeCategory>> /**  */
	nor?: Array<Maybe<FilterOfArticleKnowledgeCategory>> /**  */
	not?: Array<Maybe<FilterOfArticleKnowledgeCategory>> /**  */
}


/**  */
export type QueryContextOfArticleKnowledgeCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeArticleKnowledgeCategory>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type ArticleKnowledgeCategory */
export type FieldUpdateOperatorOfArticleKnowledgeCategory = {
	set?: Maybe<SetOperatorInputOfArticleKnowledgeCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputArticleKnowledgeCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputArticleKnowledgeCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ArticleKnowledgeCategory */
export type SetOperatorInputOfArticleKnowledgeCategory = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ArticleKnowledgeCategory */
export type PushUpdateOperatorInputArticleKnowledgeCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ArticleKnowledgeCategory */
export type PullUpdateOperatorInputArticleKnowledgeCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfFileModel = {
	fileId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	uploaded?: Maybe<FilterDateTimeOperator> /**  */
	size?: Maybe<FilterInt32Operator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	objectId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	commentary?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFileModel>> /**  */
	or?: Array<Maybe<FilterOfFileModel>> /**  */
	nor?: Array<Maybe<FilterOfFileModel>> /**  */
	not?: Array<Maybe<FilterOfFileModel>> /**  */
}


/**  */
export type QueryContextOfFileModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFileModel>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type ArticleKnowledge */
export type FieldUpdateOperatorOfArticleKnowledge = {
	set?: Maybe<SetOperatorInputOfArticleKnowledge> /**  */
	inc?: Maybe<IncOperatorInputArticleKnowledge> /**  */
	push?: Maybe<PushUpdateOperatorInputArticleKnowledge> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputArticleKnowledge> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ArticleKnowledge */
export type SetOperatorInputOfArticleKnowledge = {
	keyWords: Array<string> /**  */
	links: Array<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	lastViewByOperator?: Maybe<Date> /**  */
	countViewOperator?: Maybe<number> /**  */
	lastViewByUser?: Maybe<Date> /**  */
	countViewUser?: Maybe<number> /**  */
	averageRating?: Maybe<number> /**  */
	countRating?: Maybe<number> /**  */
	knowledgeNumber?: Maybe<number> /**  */
	resolvedTickets?: Maybe<number> /**  */
	lastResolvedTicketDate?: Maybe<Date> /**  */
	articleKnowledgeCategoryId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	descriptionText?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	solution?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	solutionText?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité ArticleKnowledge */
export type IncOperatorInputArticleKnowledge = {
	countViewOperator?: Maybe<number> /**  */
	countViewUser?: Maybe<number> /**  */
	averageRating?: Maybe<number> /**  */
	countRating?: Maybe<number> /**  */
	knowledgeNumber?: Maybe<number> /**  */
	resolvedTickets?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ArticleKnowledge */
export type PushUpdateOperatorInputArticleKnowledge = {
	keyWords: Array<string> /**  */
	links: Array<string> /**  */
	fileIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ArticleKnowledge */
export type PullUpdateOperatorInputArticleKnowledge = {
	keyWords: Array<string> /**  */
	links: Array<string> /**  */
	fileIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfAssetAccount = {
	assetId?: Maybe<FilterGuidOperator> /**  */
	accountId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAccount>> /**  */
	or?: Array<Maybe<FilterOfAssetAccount>> /**  */
	nor?: Array<Maybe<FilterOfAssetAccount>> /**  */
	not?: Array<Maybe<FilterOfAssetAccount>> /**  */
}


/**  */
export type QueryContextOfAssetAccount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetAccount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetAccount */
export type QuerySortTypeAssetAccount = {
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAssetAcquisitionMode = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAcquisitionMode>> /**  */
	or?: Array<Maybe<FilterOfAssetAcquisitionMode>> /**  */
	nor?: Array<Maybe<FilterOfAssetAcquisitionMode>> /**  */
	not?: Array<Maybe<FilterOfAssetAcquisitionMode>> /**  */
}


/**  */
export type QueryContextOfAssetAcquisitionMode = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetAcquisitionMode>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetAcquisitionState = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetAcquisitionState>> /**  */
	or?: Array<Maybe<FilterOfAssetAcquisitionState>> /**  */
	nor?: Array<Maybe<FilterOfAssetAcquisitionState>> /**  */
	not?: Array<Maybe<FilterOfAssetAcquisitionState>> /**  */
}


/**  */
export type QueryContextOfAssetAcquisitionState = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetAcquisitionState>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfAssetAffectedRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetAffectedRule>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfNamingConvention = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNamingConvention>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetContract = {
	assetId?: Maybe<FilterGuidOperator> /**  */
	contractId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetContract>> /**  */
	or?: Array<Maybe<FilterOfAssetContract>> /**  */
	nor?: Array<Maybe<FilterOfAssetContract>> /**  */
	not?: Array<Maybe<FilterOfAssetContract>> /**  */
}


/**  */
export type QueryContextOfAssetContract = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetContract>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetContract */
export type QuerySortTypeAssetContract = {
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAssetCostCenter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetCostCenter>> /**  */
	or?: Array<Maybe<FilterOfAssetCostCenter>> /**  */
	nor?: Array<Maybe<FilterOfAssetCostCenter>> /**  */
	not?: Array<Maybe<FilterOfAssetCostCenter>> /**  */
}


/**  */
export type QueryContextOfAssetCostCenter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetCostCenter>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type AssetCostCenter */
export type FieldUpdateOperatorOfAssetCostCenter = {
	set?: Maybe<SetOperatorInputOfAssetCostCenter> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetCostCenter> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetCostCenter> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetCostCenter */
export type SetOperatorInputOfAssetCostCenter = {
	notTrackingFields: Array<string> /**  */
	description?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCostCenter */
export type PushUpdateOperatorInputAssetCostCenter = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCostCenter */
export type PullUpdateOperatorInputAssetCostCenter = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfAssetLoan = {
	assetId?: Maybe<FilterGuidOperator> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetLoan>> /**  */
	or?: Array<Maybe<FilterOfAssetLoan>> /**  */
	nor?: Array<Maybe<FilterOfAssetLoan>> /**  */
	not?: Array<Maybe<FilterOfAssetLoan>> /**  */
}


/**  */
export type QueryContextOfAssetLoan = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetLoan>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetLoan */
export type QuerySortTypeAssetLoan = {
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAssetModelStockStorageUnit = {
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	storageUnit?: Maybe<FilterOfLocation> /**  */
	amount?: Maybe<FilterInt64Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetModelStockStorageUnit>> /**  */
	or?: Array<Maybe<FilterOfAssetModelStockStorageUnit>> /**  */
	nor?: Array<Maybe<FilterOfAssetModelStockStorageUnit>> /**  */
	not?: Array<Maybe<FilterOfAssetModelStockStorageUnit>> /**  */
}


/**  */
export type QueryContextOfAssetModelStockStorageUnit = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetModelStockStorageUnit>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetModelStockStorageUnit */
export type QuerySortTypeAssetModelStockStorageUnit = {
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	amount?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type AssetModel */
export type FieldUpdateOperatorOfAssetModel = {
	set?: Maybe<SetOperatorInputOfAssetModel> /**  */
	inc?: Maybe<IncOperatorInputAssetModel> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetModel */
export type SetOperatorInputOfAssetModel = {
	name?: Maybe<string> /**  */
	assetCategoryId?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	assetStockEntryIds: Array<string> /**  */
	category?: Maybe<AssetModelCategory> /**  */
	minimumStorageAmount?: Maybe<number> /**  */
	minimumStockManagement?: Maybe<AssetModelMinimumStockManagement> /**  */
	minimumStockByStorageUnits: Array<MinimumStockByStorageUnitInput> /**  */
	storageUnitId?: Maybe<string> /**  */
	totalStorageAmout?: Maybe<number> /**  */
	supplierIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	compatibleIds: Array<string> /**  */
	restockingThreshold?: Maybe<number> /**  */
	economicQuantity?: Maybe<number> /**  */
	technicalQuantity?: Maybe<number> /**  */
	restockingQuantity?: Maybe<number> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	storageUnit?: Maybe<SetOperatorInputOfLocation> /**  */
}


/**  */
export type MinimumStockByStorageUnitInput = {
	storageUnitId: string /**  */
	minimum: number /**  */
}


/** Représente les propriétés de mise à jour de l'entité Location */
export type SetOperatorInputOfLocation = {
	error?: Maybe<boolean> /**  */
	allowSelection?: Maybe<boolean> /**  */
	stock?: Maybe<boolean> /**  */
	maxCapacity?: Maybe<boolean> /**  */
	stockManagerId?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	responsableId?: Maybe<string> /**  */
	locationCategoryId?: Maybe<string> /**  */
	primaryContactId?: Maybe<string> /**  */
	templateId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	allRulesMustBeValidAsset?: Maybe<boolean> /**  */
	allRulesMustBeValidUser?: Maybe<boolean> /**  */
	phone?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	loanId?: Maybe<string> /**  */
	loanIds: Array<string> /**  */
	roomNumber?: Maybe<string> /**  */
	loanable?: Maybe<boolean> /**  */
	contactId?: Maybe<string> /**  */
	buildingTypeId?: Maybe<string> /**  */
	assetModelStockEntryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	path?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Address */
export type SetOperatorInputOfAddress = {
	street?: Maybe<string> /**  */
	additional?: Maybe<string> /**  */
	city?: Maybe<string> /**  */
	province?: Maybe<string> /**  */
	country?: Maybe<string> /**  */
	zipCode?: Maybe<string> /**  */
	postalCode?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetModel */
export type IncOperatorInputAssetModel = {
	minimumStorageAmount?: Maybe<number> /**  */
	totalStorageAmout?: Maybe<number> /**  */
	restockingThreshold?: Maybe<number> /**  */
	economicQuantity?: Maybe<number> /**  */
	technicalQuantity?: Maybe<number> /**  */
	restockingQuantity?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetModel */
export type PushUpdateOperatorInputAssetModel = {
	assetStockEntryIds: Array<string> /**  */
	minimumStockByStorageUnits: Array<MinimumStockByStorageUnitInput> /**  */
	supplierIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	compatibleIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	storageUnit?: Maybe<PushUpdateOperatorInputLocation> /**  */
}


/** Représente les propriétés de type collection de l'entité Location */
export type PushUpdateOperatorInputLocation = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	assetModelStockEntryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetModel */
export type PullUpdateOperatorInputAssetModel = {
	assetStockEntryIds: Array<string> /**  */
	minimumStockByStorageUnits: Array<MinimumStockByStorageUnitInput> /**  */
	supplierIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	compatibleIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	storageUnit?: Maybe<PullUpdateOperatorInputLocation> /**  */
}


/** Représente les propriétés de type collection de l'entité Location */
export type PullUpdateOperatorInputLocation = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	assetModelStockEntryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type AssetModelStockEntry */
export type FieldUpdateOperatorOfAssetModelStockEntry = {
	set?: Maybe<SetOperatorInputOfAssetModelStockEntry> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetModelStockEntry> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetModelStockEntry> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetModelStockEntry */
export type SetOperatorInputOfAssetModelStockEntry = {
	storageUnitId?: Maybe<string> /**  */
	inStockId?: Maybe<string> /**  */
	inStockCartId?: Maybe<string> /**  */
	outStockCartId?: Maybe<string> /**  */
	outStockId?: Maybe<string> /**  */
	correctionStockCartId?: Maybe<string> /**  */
	correctionStockId?: Maybe<string> /**  */
	createdAssetId?: Maybe<string> /**  */
	status?: Maybe<AssetModelStockEntryStatus> /**  */
	assetModelId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetModelStockEntry */
export type PushUpdateOperatorInputAssetModelStockEntry = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetModelStockEntry */
export type PullUpdateOperatorInputAssetModelStockEntry = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfAutoRun = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAutoRun>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AutoRun */
export type QuerySortTypeAutoRun = {
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfBaseBoard = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBaseBoard>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité BaseBoard */
export type QuerySortTypeBaseBoard = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	product?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	sku?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfBattery = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBattery>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Battery */
export type QuerySortTypeBattery = {
	batteryRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	batteryStatus?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	chemistry?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	designCapacity?: Maybe<Sort> /** Représente le type de trie. */
	designVoltage?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	estimatedChargeRemaining?: Maybe<Sort> /** Représente le type de trie. */
	estimatedRunTime?: Maybe<Sort> /** Représente le type de trie. */
	expectedBatteryLife?: Maybe<Sort> /** Représente le type de trie. */
	expectedLife?: Maybe<Sort> /** Représente le type de trie. */
	fullChargeCapacity?: Maybe<Sort> /** Représente le type de trie. */
	maxRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	timeOnBattery?: Maybe<Sort> /** Représente le type de trie. */
	timeToFullCharge?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfBios = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBios>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Bios */
export type QuerySortTypeBios = {
	buildNumber?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	releaseDate?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	smBiosVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	smBiosPresent?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfBootConfiguration = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBootConfiguration>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité BootConfiguration */
export type QuerySortTypeBootConfiguration = {
	bootDirectory?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	configurationPath?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	lastDrive?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	scratchDirectory?: Maybe<Sort> /** Représente le type de trie. */
	settingId?: Maybe<Sort> /** Représente le type de trie. */
	tempDirectory?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfCDRomDrive = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCDRomDrive>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CDRomDrive */
export type QuerySortTypeCDRomDrive = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	drive?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	mediaType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfCodecFile = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCodecFile>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CodecFile */
export type QuerySortTypeCodecFile = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	creationDate?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	drive?: Maybe<Sort> /** Représente le type de trie. */
	fileName?: Maybe<Sort> /** Représente le type de trie. */
	fileSize?: Maybe<Sort> /** Représente le type de trie. */
	fileType?: Maybe<Sort> /** Représente le type de trie. */
	group?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfComputerSystem = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeComputerSystem>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ComputerSystem */
export type QuerySortTypeComputerSystem = {
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	adminPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	automaticManagedPagefile?: Maybe<Sort> /** Représente le type de trie. */
	automaticResetBootOption?: Maybe<Sort> /** Représente le type de trie. */
	automaticResetCapability?: Maybe<Sort> /** Représente le type de trie. */
	bootOptionOnLimit?: Maybe<Sort> /** Représente le type de trie. */
	bootOptionOnWatchDog?: Maybe<Sort> /** Représente le type de trie. */
	bootRomSupported?: Maybe<Sort> /** Représente le type de trie. */
	bootupState?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	chassisBootupState?: Maybe<Sort> /** Représente le type de trie. */
	currentTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	daylightInEffect?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dnsHostName?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	domainRole?: Maybe<Sort> /** Représente le type de trie. */
	enableDaylightSavingsTime?: Maybe<Sort> /** Représente le type de trie. */
	frontPanelResetStatus?: Maybe<Sort> /** Représente le type de trie. */
	infraredSupported?: Maybe<Sort> /** Représente le type de trie. */
	initialLoadInfo?: Maybe<Sort> /** Représente le type de trie. */
	keyboardPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	lastLoadInfo?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	nameFormat?: Maybe<Sort> /** Représente le type de trie. */
	networkServerModeEnabled?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLogicalProcessors?: Maybe<Sort> /** Représente le type de trie. */
	numberOfProcessors?: Maybe<Sort> /** Représente le type de trie. */
	partOfDomain?: Maybe<Sort> /** Représente le type de trie. */
	pauseAfterReset?: Maybe<Sort> /** Représente le type de trie. */
	pcSystemType?: Maybe<Sort> /** Représente le type de trie. */
	powerManagementSupported?: Maybe<Sort> /** Représente le type de trie. */
	powerOnPasswordStatus?: Maybe<Sort> /** Représente le type de trie. */
	powerState?: Maybe<Sort> /** Représente le type de trie. */
	powerSupplyState?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerContact?: Maybe<Sort> /** Représente le type de trie. */
	primaryOwnerName?: Maybe<Sort> /** Représente le type de trie. */
	resetCapability?: Maybe<Sort> /** Représente le type de trie. */
	resetCount?: Maybe<Sort> /** Représente le type de trie. */
	resetLimit?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	systemStartupDelay?: Maybe<Sort> /** Représente le type de trie. */
	systemType?: Maybe<Sort> /** Représente le type de trie. */
	thermalState?: Maybe<Sort> /** Représente le type de trie. */
	totalPhysicalMemory?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	wakeUpType?: Maybe<Sort> /** Représente le type de trie. */
	workGroup?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfComputerSystemProduct = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeComputerSystemProduct>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ComputerSystemProduct */
export type QuerySortTypeComputerSystemProduct = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	identifyingNumber?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	skuNumber?: Maybe<Sort> /** Représente le type de trie. */
	uuid?: Maybe<Sort> /** Représente le type de trie. */
	vendor?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfDesktop = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDesktop>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Desktop */
export type QuerySortTypeDesktop = {
	borderWidth?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	coolSwitch?: Maybe<Sort> /** Représente le type de trie. */
	cursorBlinkRate?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dragFullWindows?: Maybe<Sort> /** Représente le type de trie. */
	gridGranularity?: Maybe<Sort> /** Représente le type de trie. */
	iconSpacing?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleFaceName?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleSize?: Maybe<Sort> /** Représente le type de trie. */
	iconTitleWrap?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pattern?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverActive?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverExecutable?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverSecure?: Maybe<Sort> /** Représente le type de trie. */
	screenSaverTimeout?: Maybe<Sort> /** Représente le type de trie. */
	settingId?: Maybe<Sort> /** Représente le type de trie. */
	wallpaper?: Maybe<Sort> /** Représente le type de trie. */
	wallpaperStretched?: Maybe<Sort> /** Représente le type de trie. */
	wallpaperTiled?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfDiskDrive = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDiskDrive>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DiskDrive */
export type QuerySortTypeDiskDrive = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	compressionMethod?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	firmwareRevision?: Maybe<Sort> /** Représente le type de trie. */
	index?: Maybe<Sort> /** Représente le type de trie. */
	interfaceType?: Maybe<Sort> /** Représente le type de trie. */
	maxBlockSize?: Maybe<Sort> /** Représente le type de trie. */
	mediaType?: Maybe<Sort> /** Représente le type de trie. */
	minBlockSize?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	partitions?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	scsiBus?: Maybe<Sort> /** Représente le type de trie. */
	scsiLogicalUnit?: Maybe<Sort> /** Représente le type de trie. */
	scsiPort?: Maybe<Sort> /** Représente le type de trie. */
	scsiTargetId?: Maybe<Sort> /** Représente le type de trie. */
	sectorsPerTrack?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	bytesPerSector?: Maybe<Sort> /** Représente le type de trie. */
	totalCylinders?: Maybe<Sort> /** Représente le type de trie. */
	totalHeads?: Maybe<Sort> /** Représente le type de trie. */
	totalSectors?: Maybe<Sort> /** Représente le type de trie. */
	totalTracks?: Maybe<Sort> /** Représente le type de trie. */
	tracksPerCylinder?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLogicalDisk = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLogicalDisk>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfLogicalDiskHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLogicalDiskHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LogicalDiskHistory */
export type QuerySortTypeLogicalDiskHistory = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	diskDriveCaption?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	driveType?: Maybe<Sort> /** Représente le type de trie. */
	fileSystem?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	mediaType?: Maybe<Sort> /** Représente le type de trie. */
	freeSpace?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	volumeName?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	useSpace?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfEncryptableVolume = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEncryptableVolume>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EncryptableVolume */
export type QuerySortTypeEncryptableVolume = {
	conversionStatus?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	driveLetter?: Maybe<Sort> /** Représente le type de trie. */
	encryptionMethod?: Maybe<Sort> /** Représente le type de trie. */
	isVolumeInitializedForProtection?: Maybe<Sort> /** Représente le type de trie. */
	persistentVolumeId?: Maybe<Sort> /** Représente le type de trie. */
	protectionStatus?: Maybe<Sort> /** Représente le type de trie. */
	volumeType?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfEnvironmentVariable = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEnvironmentVariable>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EnvironmentVariable */
export type QuerySortTypeEnvironmentVariable = {
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfFirewallProduct = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFirewallProduct>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité FirewallProduct */
export type QuerySortTypeFirewallProduct = {
	companyName?: Maybe<Sort> /** Représente le type de trie. */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	enableUImd5Hash?: Maybe<Sort> /** Représente le type de trie. */
	enableUiParameters?: Maybe<Sort> /** Représente le type de trie. */
	instanceGuid?: Maybe<Sort> /** Représente le type de trie. */
	pathToEnableUi?: Maybe<Sort> /** Représente le type de trie. */
	versionNumber?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfFontInfoAction = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFontInfoAction>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité FontInfoAction */
export type QuerySortTypeFontInfoAction = {
	actionId?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	direction?: Maybe<Sort> /** Représente le type de trie. */
	file?: Maybe<Sort> /** Représente le type de trie. */
	fontTitle?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	softwareElementId?: Maybe<Sort> /** Représente le type de trie. */
	softwareElementState?: Maybe<Sort> /** Représente le type de trie. */
	targetOperatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfGroup = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeGroup>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Group */
export type QuerySortTypeGroup = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	localAccount?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	sidType?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	parentDistinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	groupUsers?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfIis = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeIis>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Iis */
export type QuerySortTypeIis = {
	iisProgramGroup?: Maybe<Sort> /** Représente le type de trie. */
	installPath?: Maybe<Sort> /** Représente le type de trie. */
	pathWwwRoot?: Maybe<Sort> /** Représente le type de trie. */
	productString?: Maybe<Sort> /** Représente le type de trie. */
	setupString?: Maybe<Sort> /** Représente le type de trie. */
	versionString?: Maybe<Sort> /** Représente le type de trie. */
	majorVersion?: Maybe<Sort> /** Représente le type de trie. */
	metabaseSetMajorVersion?: Maybe<Sort> /** Représente le type de trie. */
	metabaseSetMinorVersion?: Maybe<Sort> /** Représente le type de trie. */
	minorVersion?: Maybe<Sort> /** Représente le type de trie. */
	iisSites?: Maybe<Sort> /** Représente le type de trie. */
	iisApplicationPools?: Maybe<Sort> /** Représente le type de trie. */
	iisComponents?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfInfraredDevice = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInfraredDevice>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité InfraredDevice */
export type QuerySortTypeInfraredDevice = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfInternetExplorer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeInternetExplorer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité InternetExplorer */
export type QuerySortTypeInternetExplorer = {
	proxyServer?: Maybe<Sort> /** Représente le type de trie. */
	autoConfigUrl?: Maybe<Sort> /** Représente le type de trie. */
	autoDetect?: Maybe<Sort> /** Représente le type de trie. */
	proxyEnable?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfKeyboard = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeKeyboard>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Keyboard */
export type QuerySortTypeKeyboard = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	isLocked?: Maybe<Sort> /** Représente le type de trie. */
	lastErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	layout?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	numberOfFunctionKeys?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLdapProperty = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLdapProperty>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LdapProperty */
export type QuerySortTypeLdapProperty = {
	objectClass?: Maybe<Sort> /** Représente le type de trie. */
	cN?: Maybe<Sort> /** Représente le type de trie. */
	distinguishedName?: Maybe<Sort> /** Représente le type de trie. */
	whenCreated?: Maybe<Sort> /** Représente le type de trie. */
	whenChanged?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	userAccountControl?: Maybe<Sort> /** Représente le type de trie. */
	badPwdCount?: Maybe<Sort> /** Représente le type de trie. */
	codePage?: Maybe<Sort> /** Représente le type de trie. */
	countryCode?: Maybe<Sort> /** Représente le type de trie. */
	badPasswordTime?: Maybe<Sort> /** Représente le type de trie. */
	lastLogoff?: Maybe<Sort> /** Représente le type de trie. */
	lastLogon?: Maybe<Sort> /** Représente le type de trie. */
	localPolicyFlags?: Maybe<Sort> /** Représente le type de trie. */
	pwdLastSet?: Maybe<Sort> /** Représente le type de trie. */
	primaryGroupId?: Maybe<Sort> /** Représente le type de trie. */
	objectSid?: Maybe<Sort> /** Représente le type de trie. */
	accountExpires?: Maybe<Sort> /** Représente le type de trie. */
	logonCount?: Maybe<Sort> /** Représente le type de trie. */
	sAmAccountName?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystem?: Maybe<Sort> /** Représente le type de trie. */
	operatingSystemVersion?: Maybe<Sort> /** Représente le type de trie. */
	dNsHostName?: Maybe<Sort> /** Représente le type de trie. */
	objectCategory?: Maybe<Sort> /** Représente le type de trie. */
	isCriticalSystemObject?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	lastLogonTimestamp?: Maybe<Sort> /** Représente le type de trie. */
	nTSecurityDescriptor?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLogonHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLogonHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LogonHistory */
export type QuerySortTypeLogonHistory = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	logonHistoryMode?: Maybe<Sort> /** Représente le type de trie. */
	userCaption?: Maybe<Sort> /** Représente le type de trie. */
	userName?: Maybe<Sort> /** Représente le type de trie. */
	userDomain?: Maybe<Sort> /** Représente le type de trie. */
	userSid?: Maybe<Sort> /** Représente le type de trie. */
	recordNumber?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLogonSession = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLogonSession>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LogonSession */
export type QuerySortTypeLogonSession = {
	authenticationPackage?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logonId?: Maybe<Sort> /** Représente le type de trie. */
	logonType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	startTime?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	logonName?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	fullName?: Maybe<Sort> /** Représente le type de trie. */
	azureObjectId?: Maybe<Sort> /** Représente le type de trie. */
	azureAccountId?: Maybe<Sort> /** Représente le type de trie. */
	azureDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	azureIdentityName?: Maybe<Sort> /** Représente le type de trie. */
	azureTenantId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfMonitor = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMonitor>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Monitor */
export type QuerySortTypeMonitor = {
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDate?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDateYear?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDateWeek?: Maybe<Sort> /** Représente le type de trie. */
	pnpId?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	serialNumberOriginal?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfNetworkAdapter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNetworkAdapter>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité NetworkAdapter */
export type QuerySortTypeNetworkAdapter = {
	adapterType?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	guid?: Maybe<Sort> /** Représente le type de trie. */
	index?: Maybe<Sort> /** Représente le type de trie. */
	interfaceIndex?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	maxSpeed?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	netConnectionId?: Maybe<Sort> /** Représente le type de trie. */
	netEnabled?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	powerManagementSupported?: Maybe<Sort> /** Représente le type de trie. */
	productName?: Maybe<Sort> /** Représente le type de trie. */
	serviceName?: Maybe<Sort> /** Représente le type de trie. */
	speed?: Maybe<Sort> /** Représente le type de trie. */
	networkAdapterConfiguration?: Array<Maybe<QuerySortTypeNetworkAdapterConfiguration>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfNetworkDrive = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNetworkDrive>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité NetworkDrive */
export type QuerySortTypeNetworkDrive = {
	remotePath?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	letter?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfNtLogEvent = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNtLogEvent>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité NtLogEvent */
export type QuerySortTypeNtLogEvent = {
	category?: Maybe<Sort> /** Représente le type de trie. */
	categorystring?: Maybe<Sort> /** Représente le type de trie. */
	computerName?: Maybe<Sort> /** Représente le type de trie. */
	eventCode?: Maybe<Sort> /** Représente le type de trie. */
	eventIdentifier?: Maybe<Sort> /** Représente le type de trie. */
	eventType?: Maybe<Sort> /** Représente le type de trie. */
	logfile?: Maybe<Sort> /** Représente le type de trie. */
	message?: Maybe<Sort> /** Représente le type de trie. */
	recordNumber?: Maybe<Sort> /** Représente le type de trie. */
	sourceName?: Maybe<Sort> /** Représente le type de trie. */
	timeGenerated?: Maybe<Sort> /** Représente le type de trie. */
	timeWritten?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	user?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfOdbcDriver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOdbcDriver>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OdbcDriver */
export type QuerySortTypeOdbcDriver = {
	driver?: Maybe<Sort> /** Représente le type de trie. */
	apiLevel?: Maybe<Sort> /** Représente le type de trie. */
	connectFunctions?: Maybe<Sort> /** Représente le type de trie. */
	driverOdbcVer?: Maybe<Sort> /** Représente le type de trie. */
	fileExtns?: Maybe<Sort> /** Représente le type de trie. */
	fileUsage?: Maybe<Sort> /** Représente le type de trie. */
	setup?: Maybe<Sort> /** Représente le type de trie. */
	sqlLevel?: Maybe<Sort> /** Représente le type de trie. */
	usageCount?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfOperatingSystem = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOperatingSystem>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfOptionalFeature = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOptionalFeature>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OptionalFeature */
export type QuerySortTypeOptionalFeature = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	installState?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfOsRecoveryConfiguration = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOsRecoveryConfiguration>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OsRecoveryConfiguration */
export type QuerySortTypeOsRecoveryConfiguration = {
	autoReboot?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	debugFilePath?: Maybe<Sort> /** Représente le type de trie. */
	debugInfoType?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	expandedDebugFilePath?: Maybe<Sort> /** Représente le type de trie. */
	expandedMiniDumpDirectory?: Maybe<Sort> /** Représente le type de trie. */
	kernelDumpOnly?: Maybe<Sort> /** Représente le type de trie. */
	miniDumpDirectory?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	overwriteExistingDebugFile?: Maybe<Sort> /** Représente le type de trie. */
	sendAdminAlert?: Maybe<Sort> /** Représente le type de trie. */
	settingId?: Maybe<Sort> /** Représente le type de trie. */
	writeDebugInfo?: Maybe<Sort> /** Représente le type de trie. */
	writeToSystemLog?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPageFile = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePageFile>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PageFile */
export type QuerySortTypePageFile = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	drive?: Maybe<Sort> /** Représente le type de trie. */
	fileName?: Maybe<Sort> /** Représente le type de trie. */
	fileSize?: Maybe<Sort> /** Représente le type de trie. */
	fileType?: Maybe<Sort> /** Représente le type de trie. */
	freeSpace?: Maybe<Sort> /** Représente le type de trie. */
	initialSize?: Maybe<Sort> /** Représente le type de trie. */
	inUseCount?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	maximumSize?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfParallelPort = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeParallelPort>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ParallelPort */
export type QuerySortTypeParallelPort = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPcmciaController = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePcmciaController>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PcmciaController */
export type QuerySortTypePcmciaController = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPhysicalMemory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePhysicalMemory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PhysicalMemory */
export type QuerySortTypePhysicalMemory = {
	bankLabel?: Maybe<Sort> /** Représente le type de trie. */
	capacity?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	dataWidth?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceLocator?: Maybe<Sort> /** Représente le type de trie. */
	formFactor?: Maybe<Sort> /** Représente le type de trie. */
	hotSwappable?: Maybe<Sort> /** Représente le type de trie. */
	interleaveDataDepth?: Maybe<Sort> /** Représente le type de trie. */
	interleavePosition?: Maybe<Sort> /** Représente le type de trie. */
	memoryType?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	partNumber?: Maybe<Sort> /** Représente le type de trie. */
	positionInRow?: Maybe<Sort> /** Représente le type de trie. */
	poweredOn?: Maybe<Sort> /** Représente le type de trie. */
	removable?: Maybe<Sort> /** Représente le type de trie. */
	replaceable?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	sku?: Maybe<Sort> /** Représente le type de trie. */
	speed?: Maybe<Sort> /** Représente le type de trie. */
	tag?: Maybe<Sort> /** Représente le type de trie. */
	totalWidth?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPhysicalMemoryArray = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePhysicalMemoryArray>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PhysicalMemoryArray */
export type QuerySortTypePhysicalMemoryArray = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	location?: Maybe<Sort> /** Représente le type de trie. */
	maxCapacity?: Maybe<Sort> /** Représente le type de trie. */
	memoryDevices?: Maybe<Sort> /** Représente le type de trie. */
	memoryErrorCorrection?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	use?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPnpEntity = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePnpEntity>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PnpEntity */
export type QuerySortTypePnpEntity = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	classGuid?: Maybe<Sort> /** Représente le type de trie. */
	pnpEntityString?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	service?: Maybe<Sort> /** Représente le type de trie. */
	configManagerErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	configManagerErrorCodeString?: Maybe<Sort> /** Représente le type de trie. */
	errorDescription?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPnpSignedDriver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePnpSignedDriver>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PnpSignedDriver */
export type QuerySortTypePnpSignedDriver = {
	classGuid?: Maybe<Sort> /** Représente le type de trie. */
	compatId?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceClass?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	deviceName?: Maybe<Sort> /** Représente le type de trie. */
	devLoader?: Maybe<Sort> /** Représente le type de trie. */
	driverDate?: Maybe<Sort> /** Représente le type de trie. */
	driverName?: Maybe<Sort> /** Représente le type de trie. */
	driverVersion?: Maybe<Sort> /** Représente le type de trie. */
	friendlyName?: Maybe<Sort> /** Représente le type de trie. */
	hardWareId?: Maybe<Sort> /** Représente le type de trie. */
	infName?: Maybe<Sort> /** Représente le type de trie. */
	installDate?: Maybe<Sort> /** Représente le type de trie. */
	isSigned?: Maybe<Sort> /** Représente le type de trie. */
	location?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pdo?: Maybe<Sort> /** Représente le type de trie. */
	driverProviderName?: Maybe<Sort> /** Représente le type de trie. */
	signer?: Maybe<Sort> /** Représente le type de trie. */
	started?: Maybe<Sort> /** Représente le type de trie. */
	startMode?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPointingDevice = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePointingDevice>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PointingDevice */
export type QuerySortTypePointingDevice = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	hardwareType?: Maybe<Sort> /** Représente le type de trie. */
	infFileName?: Maybe<Sort> /** Représente le type de trie. */
	infSection?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	numberOfButtons?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPortableBattery = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePortableBattery>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PortableBattery */
export type QuerySortTypePortableBattery = {
	batteryRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	batteryStatus?: Maybe<Sort> /** Représente le type de trie. */
	capacityMultiplier?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	chemistry?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	designCapacity?: Maybe<Sort> /** Représente le type de trie. */
	designVoltage?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	estimatedChargeRemaining?: Maybe<Sort> /** Représente le type de trie. */
	estimatedRunTime?: Maybe<Sort> /** Représente le type de trie. */
	expectedBatteryLife?: Maybe<Sort> /** Représente le type de trie. */
	expectedLife?: Maybe<Sort> /** Représente le type de trie. */
	fullChargeCapacity?: Maybe<Sort> /** Représente le type de trie. */
	location?: Maybe<Sort> /** Représente le type de trie. */
	manufactureDate?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	maxBatteryError?: Maybe<Sort> /** Représente le type de trie. */
	maxRechargeTime?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	smartBatteryVersion?: Maybe<Sort> /** Représente le type de trie. */
	timeOnBattery?: Maybe<Sort> /** Représente le type de trie. */
	timeToFullCharge?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPortConnector = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePortConnector>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PortConnector */
export type QuerySortTypePortConnector = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalReferenceDesignator?: Maybe<Sort> /** Représente le type de trie. */
	internalReferenceDesignator?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	portType?: Maybe<Sort> /** Représente le type de trie. */
	tag?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPortOpen = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePortOpen>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PortOpen */
export type QuerySortTypePortOpen = {
	portName?: Maybe<Sort> /** Représente le type de trie. */
	portNumber?: Maybe<Sort> /** Représente le type de trie. */
	open?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPotsModem = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePotsModem>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PotsModem */
export type QuerySortTypePotsModem = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPrinter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePrinter>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Printer */
export type QuerySortTypePrinter = {
	action?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Maybe<Sort> /** Représente le type de trie. */
	dataType?: Maybe<Sort> /** Représente le type de trie. */
	objectGuid?: Maybe<Sort> /** Représente le type de trie. */
	printProcessor?: Maybe<Sort> /** Représente le type de trie. */
	printerDriver?: Maybe<Sort> /** Représente le type de trie. */
	priority?: Maybe<Sort> /** Représente le type de trie. */
	separatorFile?: Maybe<Sort> /** Représente le type de trie. */
	shareName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	isNetWork?: Maybe<Sort> /** Représente le type de trie. */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	ipAddress?: Maybe<Sort> /** Représente le type de trie. */
	deviceInstanceId?: Maybe<Sort> /** Représente le type de trie. */
	hardwareId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	printerName?: Maybe<Sort> /** Représente le type de trie. */
	printShareName?: Maybe<Sort> /** Représente le type de trie. */
	serverName?: Maybe<Sort> /** Représente le type de trie. */
	shortServerName?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	driverName?: Maybe<Sort> /** Représente le type de trie. */
	portName?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfProcess = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeProcess>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Process */
export type QuerySortTypeProcess = {
	handle?: Maybe<Sort> /** Représente le type de trie. */
	processId?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	commandLine?: Maybe<Sort> /** Représente le type de trie. */
	creationDate?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	executablePath?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	osCreationClassName?: Maybe<Sort> /** Représente le type de trie. */
	osName?: Maybe<Sort> /** Représente le type de trie. */
	virtualSize?: Maybe<Sort> /** Représente le type de trie. */
	workingSetSize?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfProcessor = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeProcessor>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Processor */
export type QuerySortTypeProcessor = {
	addressWidth?: Maybe<Sort> /** Représente le type de trie. */
	architecture?: Maybe<Sort> /** Représente le type de trie. */
	availability?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	configManagerErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	configManagerUserConfig?: Maybe<Sort> /** Représente le type de trie. */
	cpuStatus?: Maybe<Sort> /** Représente le type de trie. */
	currentClockSpeed?: Maybe<Sort> /** Représente le type de trie. */
	currentVoltage?: Maybe<Sort> /** Représente le type de trie. */
	dataWidth?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	errorCleared?: Maybe<Sort> /** Représente le type de trie. */
	errorDescription?: Maybe<Sort> /** Représente le type de trie. */
	extClock?: Maybe<Sort> /** Représente le type de trie. */
	family?: Maybe<Sort> /** Représente le type de trie. */
	l2CacheSize?: Maybe<Sort> /** Représente le type de trie. */
	l2CacheSpeed?: Maybe<Sort> /** Représente le type de trie. */
	l3CacheSize?: Maybe<Sort> /** Représente le type de trie. */
	l3CacheSpeed?: Maybe<Sort> /** Représente le type de trie. */
	lastErrorCode?: Maybe<Sort> /** Représente le type de trie. */
	level?: Maybe<Sort> /** Représente le type de trie. */
	loadPercentage?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	maxClockSpeed?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	numberOfCores?: Maybe<Sort> /** Représente le type de trie. */
	numberOfLogicalProcessors?: Maybe<Sort> /** Représente le type de trie. */
	otherFamilyDescription?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	powerManagementSupported?: Maybe<Sort> /** Représente le type de trie. */
	processorId?: Maybe<Sort> /** Représente le type de trie. */
	processorType?: Maybe<Sort> /** Représente le type de trie. */
	revision?: Maybe<Sort> /** Représente le type de trie. */
	role?: Maybe<Sort> /** Représente le type de trie. */
	socketDesignation?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	statusInfo?: Maybe<Sort> /** Représente le type de trie. */
	stepping?: Maybe<Sort> /** Représente le type de trie. */
	uniqueId?: Maybe<Sort> /** Représente le type de trie. */
	upgradeMethod?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	voltageCaps?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfProgram = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeProgram>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Program */
export type QuerySortTypeProgram = {
	parentDisplayName?: Maybe<Sort> /** Représente le type de trie. */
	contact?: Maybe<Sort> /** Représente le type de trie. */
	estimatedSize?: Maybe<Sort> /** Représente le type de trie. */
	installLocation?: Maybe<Sort> /** Représente le type de trie. */
	installSource?: Maybe<Sort> /** Représente le type de trie. */
	helpLink?: Maybe<Sort> /** Représente le type de trie. */
	language?: Maybe<Sort> /** Représente le type de trie. */
	productId?: Maybe<Sort> /** Représente le type de trie. */
	uninstallString?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	publisher?: Maybe<Sort> /** Représente le type de trie. */
	installDate?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfQuickFixEngineering = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeQuickFixEngineering>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité QuickFixEngineering */
export type QuerySortTypeQuickFixEngineering = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	fixComments?: Maybe<Sort> /** Représente le type de trie. */
	hotFixId?: Maybe<Sort> /** Représente le type de trie. */
	installedBy?: Maybe<Sort> /** Représente le type de trie. */
	installedOn?: Maybe<Sort> /** Représente le type de trie. */
	installedDate?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	servicePackInEffect?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfScsiController = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScsiController>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScsiController */
export type QuerySortTypeScsiController = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	driverName?: Maybe<Sort> /** Représente le type de trie. */
	index?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSerialNumber = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSerialNumber>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SerialNumber */
export type QuerySortTypeSerialNumber = {
	productName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	name2?: Maybe<Sort> /** Représente le type de trie. */
	value2?: Maybe<Sort> /** Représente le type de trie. */
	guid?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	serialNumberMode?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSerialPort = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSerialPort>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SerialPort */
export type QuerySortTypeSerialPort = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfShare = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeShare>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Share */
export type QuerySortTypeShare = {
	accessMask?: Maybe<Sort> /** Représente le type de trie. */
	allowMaximum?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	maximumAllowed?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	path?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	shareSecurities?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPrinterConsumable = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePrinterConsumable>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfCopyCount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCopyCount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CopyCount */
export type QuerySortTypeCopyCount = {
	color?: Maybe<Sort> /** Représente le type de trie. */
	black?: Maybe<Sort> /** Représente le type de trie. */
	twoColor?: Maybe<Sort> /** Représente le type de trie. */
	monochrome?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfDiskRamInformation = {
	index?: Maybe<FilterStringOperator> /**  */
	oid?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	allocationUnit?: Maybe<FilterInt32Operator> /**  */
	size?: Maybe<FilterInt64Operator> /**  */
	used?: Maybe<FilterInt64Operator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInDiskRamInformation> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDiskRamInformation>> /**  */
	or?: Array<Maybe<FilterOfDiskRamInformation>> /**  */
	nor?: Array<Maybe<FilterOfDiskRamInformation>> /**  */
	not?: Array<Maybe<FilterOfDiskRamInformation>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInDiskRamInformation = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type QueryContextOfDiskRamInformation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDiskRamInformation>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DiskRamInformation */
export type QuerySortTypeDiskRamInformation = {
	index?: Maybe<Sort> /** Représente le type de trie. */
	oid?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	allocationUnit?: Maybe<Sort> /** Représente le type de trie. */
	size?: Maybe<Sort> /** Représente le type de trie. */
	used?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfEntryTable = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEntryTable>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EntryTable */
export type QuerySortTypeEntryTable = {
	ifIndex?: Maybe<Sort> /** Représente le type de trie. */
	ifDescr?: Maybe<Sort> /** Représente le type de trie. */
	ifType?: Maybe<Sort> /** Représente le type de trie. */
	ifMtu?: Maybe<Sort> /** Représente le type de trie. */
	ifSpeed?: Maybe<Sort> /** Représente le type de trie. */
	ifPhysAddress?: Maybe<Sort> /** Représente le type de trie. */
	ifAdminStatus?: Maybe<Sort> /** Représente le type de trie. */
	ifOperStatus?: Maybe<Sort> /** Représente le type de trie. */
	ifLastChange?: Maybe<Sort> /** Représente le type de trie. */
	ifInOctets?: Maybe<Sort> /** Représente le type de trie. */
	ifInUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifInNUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifInDiscards?: Maybe<Sort> /** Représente le type de trie. */
	ifInErrors?: Maybe<Sort> /** Représente le type de trie. */
	ifInUnknownProtos?: Maybe<Sort> /** Représente le type de trie. */
	ifOutOctets?: Maybe<Sort> /** Représente le type de trie. */
	ifOutUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifOutNUcastPkts?: Maybe<Sort> /** Représente le type de trie. */
	ifOutDiscards?: Maybe<Sort> /** Représente le type de trie. */
	ifOutErrors?: Maybe<Sort> /** Représente le type de trie. */
	ifOutQLen?: Maybe<Sort> /** Représente le type de trie. */
	ifSpecific?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfFaxCount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFaxCount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité FaxCount */
export type QuerySortTypeFaxCount = {
	color?: Maybe<Sort> /** Représente le type de trie. */
	black?: Maybe<Sort> /** Représente le type de trie. */
	send?: Maybe<Sort> /** Représente le type de trie. */
	receive?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfPageCount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePageCount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PageCount */
export type QuerySortTypePageCount = {
	color?: Maybe<Sort> /** Représente le type de trie. */
	black?: Maybe<Sort> /** Représente le type de trie. */
	twoColor?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfScannerCount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScannerCount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScannerCount */
export type QuerySortTypeScannerCount = {
	total?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSnmpSummary = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSnmpSummary>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SnmpSummary */
export type QuerySortTypeSnmpSummary = {
	sysDescription?: Maybe<Sort> /** Représente le type de trie. */
	sysOid?: Maybe<Sort> /** Représente le type de trie. */
	sysUpTime?: Maybe<Sort> /** Représente le type de trie. */
	sysContact?: Maybe<Sort> /** Représente le type de trie. */
	sysName?: Maybe<Sort> /** Représente le type de trie. */
	sysLocation?: Maybe<Sort> /** Représente le type de trie. */
	sysService?: Maybe<Sort> /** Représente le type de trie. */
	snmpType?: Maybe<Sort> /** Représente le type de trie. */
	vendorId?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfVlan = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeVlan>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Vlan */
export type QuerySortTypeVlan = {
	port?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSoundDevice = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoundDevice>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SoundDevice */
export type QuerySortTypeSoundDevice = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	productName?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSqlServer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSqlServer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SqlServer */
export type QuerySortTypeSqlServer = {
	instanceName?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	edition?: Maybe<Sort> /** Représente le type de trie. */
	licenceName?: Maybe<Sort> /** Représente le type de trie. */
	fileVersion?: Maybe<Sort> /** Représente le type de trie. */
	servicePack?: Maybe<Sort> /** Représente le type de trie. */
	wow64?: Maybe<Sort> /** Représente le type de trie. */
	language?: Maybe<Sort> /** Représente le type de trie. */
	installPath?: Maybe<Sort> /** Représente le type de trie. */
	dataPath?: Maybe<Sort> /** Représente le type de trie. */
	sqlService?: Array<Maybe<QuerySortTypeSqlService>> /**  */
	dataBaseInformations?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSystemAccount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSystemAccount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SystemAccount */
export type QuerySortTypeSystemAccount = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	localAccount?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	sidType?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSystemEnclosure = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSystemEnclosure>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SystemEnclosure */
export type QuerySortTypeSystemEnclosure = {
	audibleAlarm?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	chassisTypes?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	lockPresent?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	partNumber?: Maybe<Sort> /** Représente le type de trie. */
	serialNumber?: Maybe<Sort> /** Représente le type de trie. */
	sku?: Maybe<Sort> /** Représente le type de trie. */
	smbiosAssetTag?: Maybe<Sort> /** Représente le type de trie. */
	tag?: Maybe<Sort> /** Représente le type de trie. */
	version?: Maybe<Sort> /** Représente le type de trie. */
	computerDescription?: Maybe<Sort> /** Représente le type de trie. */
	dockingMode?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfSystemSlot = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSystemSlot>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SystemSlot */
export type QuerySortTypeSystemSlot = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	slotDesignation?: Maybe<Sort> /** Représente le type de trie. */
	tag?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfTapeDrive = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTapeDrive>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TapeDrive */
export type QuerySortTypeTapeDrive = {
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	ecc?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	mediaType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	needsCleaning?: Maybe<Sort> /** Représente le type de trie. */
	numberOfMediaSupported?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	statusInfo?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfUptimeCalendar = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUptimeCalendar>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité UptimeCalendar */
export type QuerySortTypeUptimeCalendar = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	uptimeCalendarMode?: Maybe<Sort> /** Représente le type de trie. */
	shutDownReason?: Maybe<Sort> /** Représente le type de trie. */
	shutDownType?: Maybe<Sort> /** Représente le type de trie. */
	shutDownProcess?: Maybe<Sort> /** Représente le type de trie. */
	shutDownCode?: Maybe<Sort> /** Représente le type de trie. */
	recordNumber?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfUserAccount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserAccount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité UserAccount */
export type QuerySortTypeUserAccount = {
	accountType?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	domain?: Maybe<Sort> /** Représente le type de trie. */
	fullName?: Maybe<Sort> /** Représente le type de trie. */
	localAccount?: Maybe<Sort> /** Représente le type de trie. */
	lockout?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	passwordChangeable?: Maybe<Sort> /** Représente le type de trie. */
	passwordExpires?: Maybe<Sort> /** Représente le type de trie. */
	passwordRequired?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	sidType?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfUserAccountControl = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserAccountControl>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité UserAccountControl */
export type QuerySortTypeUserAccountControl = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	consentPromptBehaviorAdmin?: Maybe<Sort> /** Représente le type de trie. */
	consentPromptBehaviorUser?: Maybe<Sort> /** Représente le type de trie. */
	enableInstallerDetection?: Maybe<Sort> /** Représente le type de trie. */
	enableLua?: Maybe<Sort> /** Représente le type de trie. */
	promptOnSecureDesktop?: Maybe<Sort> /** Représente le type de trie. */
	filterAdministratorToken?: Maybe<Sort> /** Représente le type de trie. */
	enableUiaDesktopToggle?: Maybe<Sort> /** Représente le type de trie. */
	enableSecureUiaPaths?: Maybe<Sort> /** Représente le type de trie. */
	validateAdminCodeSignatures?: Maybe<Sort> /** Représente le type de trie. */
	enableVirtualization?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfUserProfile = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserProfile>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité UserProfile */
export type QuerySortTypeUserProfile = {
	lastDownloadTime?: Maybe<Sort> /** Représente le type de trie. */
	lastUploadTime?: Maybe<Sort> /** Représente le type de trie. */
	lastUseTime?: Maybe<Sort> /** Représente le type de trie. */
	loaded?: Maybe<Sort> /** Représente le type de trie. */
	localPath?: Maybe<Sort> /** Représente le type de trie. */
	refCount?: Maybe<Sort> /** Représente le type de trie. */
	roamingConfigured?: Maybe<Sort> /** Représente le type de trie. */
	roamingPath?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	roamingPreference?: Maybe<Sort> /** Représente le type de trie. */
	sid?: Maybe<Sort> /** Représente le type de trie. */
	special?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfVideoController = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeVideoController>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité VideoController */
export type QuerySortTypeVideoController = {
	adapterCompatibility?: Maybe<Sort> /** Représente le type de trie. */
	adapterDacType?: Maybe<Sort> /** Représente le type de trie. */
	adapterRam?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	currentBitsPerPixel?: Maybe<Sort> /** Représente le type de trie. */
	currentHorizontalResolution?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfColors?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfColumns?: Maybe<Sort> /** Représente le type de trie. */
	currentNumberOfRows?: Maybe<Sort> /** Représente le type de trie. */
	currentRefreshRate?: Maybe<Sort> /** Représente le type de trie. */
	currentScanMode?: Maybe<Sort> /** Représente le type de trie. */
	currentVerticalResolution?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	deviceId?: Maybe<Sort> /** Représente le type de trie. */
	driverDate?: Maybe<Sort> /** Représente le type de trie. */
	driverVersion?: Maybe<Sort> /** Représente le type de trie. */
	monochrome?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pnpDeviceId?: Maybe<Sort> /** Représente le type de trie. */
	videoArchitecture?: Maybe<Sort> /** Représente le type de trie. */
	videoMemoryType?: Maybe<Sort> /** Représente le type de trie. */
	videoMode?: Maybe<Sort> /** Représente le type de trie. */
	videoModeDescription?: Maybe<Sort> /** Représente le type de trie. */
	videoProcessor?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfWindowsFirewall = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWindowsFirewall>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WindowsFirewall */
export type QuerySortTypeWindowsFirewall = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	domainProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	publicProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	standardProfile?: Array<Maybe<QuerySortTypeFirewallPolicy>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfWindowsService = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWindowsService>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WindowsService */
export type QuerySortTypeWindowsService = {
	acceptPause?: Maybe<Sort> /** Représente le type de trie. */
	acceptStop?: Maybe<Sort> /** Représente le type de trie. */
	caption?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	pathName?: Maybe<Sort> /** Représente le type de trie. */
	started?: Maybe<Sort> /** Représente le type de trie. */
	startMode?: Maybe<Sort> /** Représente le type de trie. */
	startName?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfWindowsUpdate = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWindowsUpdate>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WindowsUpdate */
export type QuerySortTypeWindowsUpdate = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	auOptions?: Maybe<Sort> /** Représente le type de trie. */
	nextDetectionTime?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallDate?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallDay?: Maybe<Sort> /** Représente le type de trie. */
	scheduledInstallTime?: Maybe<Sort> /** Représente le type de trie. */
	detect?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	download?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	install?: Array<Maybe<QuerySortTypeWindowsUpdateResult>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfWorkApplication = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkApplication>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkApplication */
export type QuerySortTypeWorkApplication = {
	publisher?: Maybe<Sort> /** Représente le type de trie. */
	software?: Array<Maybe<QuerySortTypeSoftware>> /**  */
	displayName?: Maybe<Sort> /** Représente le type de trie. */
	displayVersion?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfNamingConventionHistory = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	namingPolicyId?: Maybe<FilterGuidOperator> /**  */
	namingPolicy?: Maybe<FilterOfNamingConvention> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInNamingConventionHistory> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfNamingConventionHistory>> /**  */
	or?: Array<Maybe<FilterOfNamingConventionHistory>> /**  */
	nor?: Array<Maybe<FilterOfNamingConventionHistory>> /**  */
	not?: Array<Maybe<FilterOfNamingConventionHistory>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInNamingConventionHistory = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type QueryContextOfNamingConventionHistory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeNamingConventionHistory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité NamingConventionHistory */
export type QuerySortTypeNamingConventionHistory = {
	created?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	namingPolicy?: Array<Maybe<QuerySortTypeNamingConvention>> /**  */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAssetPropertyTracking = {
	entityName?: Maybe<FilterStringOperator> /**  */
	canUpdated?: Maybe<FilterBooleanOperator> /**  */
	canDeleted?: Maybe<FilterBooleanOperator> /**  */
	canAdded?: Maybe<FilterBooleanOperator> /**  */
	entityPropertyTracking?: Maybe<ArrayEntityPropertyTrackingOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetPropertyTracking>> /**  */
	or?: Array<Maybe<FilterOfAssetPropertyTracking>> /**  */
	nor?: Array<Maybe<FilterOfAssetPropertyTracking>> /**  */
	not?: Array<Maybe<FilterOfAssetPropertyTracking>> /**  */
}


/**  */
export type ArrayEntityPropertyTrackingOperator = {
	elemMatch?: Maybe<FilterOfEntityPropertyTracking> /**  */
}


/**  */
export type FilterOfEntityPropertyTracking = {
	propertyName?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	parentProperty?: Maybe<FilterStringOperator> /**  */
	propertyFullName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEntityPropertyTracking>> /**  */
	or?: Array<Maybe<FilterOfEntityPropertyTracking>> /**  */
	nor?: Array<Maybe<FilterOfEntityPropertyTracking>> /**  */
	not?: Array<Maybe<FilterOfEntityPropertyTracking>> /**  */
}


/**  */
export type QueryContextOfAssetPropertyTracking = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetPropertyTracking>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetPropertyTracking */
export type QuerySortTypeAssetPropertyTracking = {
	entityName?: Maybe<Sort> /** Représente le type de trie. */
	canUpdated?: Maybe<Sort> /** Représente le type de trie. */
	canDeleted?: Maybe<Sort> /** Représente le type de trie. */
	canAdded?: Maybe<Sort> /** Représente le type de trie. */
	entityPropertyTracking?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAssetProvisionalCommissioning = {
	name?: Maybe<FilterStringOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetProvisionalCommissioning>> /**  */
	or?: Array<Maybe<FilterOfAssetProvisionalCommissioning>> /**  */
	nor?: Array<Maybe<FilterOfAssetProvisionalCommissioning>> /**  */
	not?: Array<Maybe<FilterOfAssetProvisionalCommissioning>> /**  */
}


/**  */
export type QueryContextOfAssetProvisionalCommissioning = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetProvisionalCommissioning>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AssetProvisionalCommissioning */
export type QuerySortTypeAssetProvisionalCommissioning = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type AssetProvisionalCommissioning */
export type FieldUpdateOperatorOfAssetProvisionalCommissioning = {
	set?: Maybe<SetOperatorInputOfAssetProvisionalCommissioning> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetProvisionalCommissioning> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetProvisionalCommissioning> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetProvisionalCommissioning */
export type SetOperatorInputOfAssetProvisionalCommissioning = {
	name?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	ownerId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetProvisionalCommissioning */
export type PushUpdateOperatorInputAssetProvisionalCommissioning = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetProvisionalCommissioning */
export type PullUpdateOperatorInputAssetProvisionalCommissioning = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfLifeCycleRecipient = {
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLifeCycleRecipient>> /**  */
	or?: Array<Maybe<FilterOfLifeCycleRecipient>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycleRecipient>> /**  */
	not?: Array<Maybe<FilterOfLifeCycleRecipient>> /**  */
}


/**  */
export type QueryContextOfLifeCycleRecipient = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLifeCycleRecipient>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LifeCycleRecipient */
export type QuerySortTypeLifeCycleRecipient = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type AssetInput = {
	dynamicProperties?: Maybe<string> /**  */
	isAudited: boolean /**  */
	assetCategoryId?: Maybe<string> /**  */
	assetStatusId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	fqdn?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	power: number /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	skuNumber?: Maybe<string> /**  */
	uuid?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	macAddresses?: Array<Maybe<string>> /**  */
	inventoryNumber?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	netBios?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	firstAudit?: Maybe<Date> /**  */
	lastAudit?: Maybe<Date> /**  */
	lastSuccessAudit?: Maybe<Date> /**  */
	auditState?: Maybe<ActionHistoryState> /**  */
	domainId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	phoneAsset?: Maybe<string> /**  */
	ownerId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRuleInput> /**  */
	assetCategoryRule?: Maybe<AssetCategoryRuleInput> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	inventoryHistory?: Maybe<InventoryHistoryInput> /**  */
	lifeCycle?: Maybe<LifeCycleInput> /**  */
	remoteControlId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	lendable: boolean /**  */
	featureComment?: Maybe<string> /**  */
	featureMobility?: Maybe<FeatureMobilityInput> /**  */
	featureSimCard?: Maybe<FeatureSimCardInput> /**  */
	featureMonitor?: Maybe<FeatureMonitorInput> /**  */
	featureUps?: Maybe<FeatureUpsInput> /**  */
	featureScanner?: Maybe<FeatureScannerInput> /**  */
	featureWebcam?: Maybe<FeatureWebcamInput> /**  */
	featureModem?: Maybe<FeatureModemInput> /**  */
	featureTerminal?: Maybe<FeatureTerminalInput> /**  */
	featureSwitch?: Maybe<FeatureSwitchInput> /**  */
	featureNetwork?: Maybe<FeatureNetworkInput> /**  */
	featurePrinter?: Maybe<FeaturePrinterInput> /**  */
	featureVideoProjector?: Maybe<FeatureVideoProjectorInput> /**  */
	featureMobile?: Maybe<FeatureMobileInput> /**  */
	firstMobileDevices?: Array<Maybe<FeatureMobileInput>> /**  */
	secondMobileDevices?: Array<Maybe<FeatureMobileInput>> /**  */
	featureTelephony?: Maybe<FeatureTelephonyInput> /**  */
	createdModelId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	outStockIds: Array<string> /**  */
	stockTransfersIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	detectWarranty?: Maybe<DetectWarrantyInput> /**  */
	financial?: Maybe<AssetFinancialInput> /**  */
	loanId?: Maybe<string> /**  */
	assetNamingConvention?: Maybe<AssetNamingConventionInput> /**  */
	entityLocationCharter?: Maybe<EntityLocationCharterInput> /**  */
	fileIds: Array<string> /**  */
	lastCommandId?: Maybe<string> /**  */
	auditResult?: Maybe<AuditResultInput> /**  */
	operatingSystem?: Maybe<string> /**  */
	mainOperatingSystemId?: Maybe<string> /**  */
	memories?: Maybe<number> /**  */
	processor?: Maybe<string> /**  */
	warrantyStart?: Maybe<Date> /**  */
	warrantyEnd?: Maybe<Date> /**  */
	loanable: boolean /**  */
	groupIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	commandIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	deliveryAsset?: Maybe<AssetDeliveryInput> /**  */
	lastCalcRenewDate?: Maybe<Date> /**  */
	campaignIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	clarilogLocalAgent?: Maybe<ClarilogLocalAgentInput> /**  */
	ticketIds: Array<string> /**  */
	mainImpactedTicketIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	inheritLocationCharterLocation?: Maybe<InheritLocationCharterInput> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<InheritLocationCharterInput> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
}


/**  */
export type AssetAffectedRuleInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	byNetbios: boolean /**  */
	byMacAddress: boolean /**  */
	byIpAddress: boolean /**  */
	bySerialNumber: boolean /**  */
	byUuid: boolean /**  */
	byDistinguishedName: boolean /**  */
	byFqdn: boolean /**  */
	byName: boolean /**  */
	auditTypes: Array<AuditType> /**  */
}


/**  */
export type TranslatedFieldInput = {
	fr?: Maybe<string> /**  */
	nl?: Maybe<string> /**  */
	en?: Maybe<string> /**  */
	it?: Maybe<string> /**  */
	de?: Maybe<string> /**  */
}


/**  */
export type AssetCategoryRuleInput = {
	assetCategoryId?: Maybe<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	expression?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/**  */
export type InventoryHistoryInput = {
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	host?: Maybe<string> /**  */
	clarilogException?: Maybe<ClarilogExceptionInput> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<AssetAffectedRuleInput> /**  */
	hidden: boolean /**  */
	clientApplicationtName?: Maybe<string> /**  */
	clientApplicationtVersion?: Maybe<string> /**  */
	auditTimes?: Array<Maybe<AuditTimeInput>> /**  */
	networkCredentialId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	auditType: AuditType /**  */
	new: boolean /**  */
	macAddress?: Maybe<string> /**  */
	resolveName?: Maybe<string> /**  */
	scanImportOptionId?: Maybe<string> /**  */
	attempts: number /**  */
}


/**  */
export type ClarilogExceptionInput = {
	message?: Maybe<string> /**  */
	stackTrace?: Maybe<string> /**  */
	source?: Maybe<string> /**  */
	helpLink?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
}


/**  */
export type AuditTimeInput = {
	methodName?: Maybe<string> /**  */
	time: number /**  */
	isAudited: boolean /**  */
	count?: Maybe<number> /**  */
	clarilogException?: Maybe<ClarilogExceptionInput> /**  */
}


/**  */
export type LifeCycleInput = {
	startLife?: Maybe<StartLifeInput> /**  */
	renewal?: Maybe<RenewalInput> /**  */
	endLife?: Maybe<EndLifeInput> /**  */
}


/**  */
export type StartLifeInput = {
	description?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
}


/**  */
export type RenewalInput = {
	description?: Maybe<string> /**  */
	estimatedDate?: Maybe<Date> /**  */
	age: number /**  */
	usingDuration: number /**  */
}


/**  */
export type EndLifeInput = {
	description?: Maybe<string> /**  */
	outDate?: Maybe<Date> /**  */
	reasonId?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
	destinationId?: Maybe<string> /**  */
	destination?: Maybe<string> /**  */
	recipient?: Maybe<string> /**  */
	removalDate?: Maybe<Date> /**  */
	removalId?: Maybe<string> /**  */
	bunddleNumber?: Maybe<string> /**  */
	disposalPrice: number /**  */
	recycleCost: number /**  */
	contributorId?: Maybe<string> /**  */
	restockingPrograms: boolean /**  */
	restockingLicenses: boolean /**  */
	dissociateAssets: boolean /**  */
}


/**  */
export type FeatureMobilityInput = {
	firstSimSlot?: Maybe<SimSlotInput> /**  */
	secondSimSlot?: Maybe<SimSlotInput> /**  */
}


/**  */
export type SimSlotInput = {
	imei?: Maybe<string> /**  */
	phoneNumber?: Maybe<string> /**  */
	primaryPinCode?: Maybe<string> /**  */
	secondaryPinCode?: Maybe<string> /**  */
	primaryPukCode?: Maybe<string> /**  */
	secondaryPukCode?: Maybe<string> /**  */
}


/**  */
export type FeatureSimCardInput = {
	phone?: Maybe<string> /**  */
	pin?: Maybe<string> /**  */
	pin2?: Maybe<string> /**  */
	puk?: Maybe<string> /**  */
	puk2?: Maybe<string> /**  */
	simMemoryId?: Maybe<string> /**  */
	simCardMemory?: Maybe<SimCardMemoryInput> /**  */
}


/**  */
export type SimCardMemoryInput = {
	name?: Maybe<string> /**  */
	capacity?: Maybe<string> /**  */
}


/**  */
export type FeatureMonitorInput = {
	size?: Maybe<string> /**  */
	resolutions?: Array<Maybe<ResolutionInput>> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	monitorTechnology?: Maybe<MonitorTechnologyInput> /**  */
	resolutionMax?: Maybe<string> /**  */
}


/**  */
export type ResolutionInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type MonitorTechnologyInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type FeatureUpsInput = {
	autonomyId?: Maybe<string> /**  */
	powerId?: Maybe<string> /**  */
	upsTypeId?: Maybe<string> /**  */
	chargerTime: number /**  */
	outlet: number /**  */
}


/**  */
export type FeatureScannerInput = {
	charger: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategoryInput>> /**  */
	paperFormats?: Array<Maybe<PaperFormatInput>> /**  */
	scanSpeedId?: Maybe<string> /**  */
	dpiResolutionId?: Maybe<string> /**  */
}


/**  */
export type ConnectionCategoryInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type PaperFormatInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type FeatureWebcamInput = {
	color: boolean /**  */
	microphone: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategoryInput>> /**  */
}


/**  */
export type FeatureModemInput = {
	wifi: boolean /**  */
	connectionCategories?: Array<Maybe<ConnectionCategoryInput>> /**  */
	speedConnectionId?: Maybe<string> /**  */
}


/**  */
export type FeatureTerminalInput = {
	barcodeReader: boolean /**  */
	rfidReader: boolean /**  */
	wifi: boolean /**  */
	bluetooth: boolean /**  */
	infrared: boolean /**  */
	miniUsb: boolean /**  */
	gPs: boolean /**  */
	camera: boolean /**  */
	memoryExtension?: Maybe<string> /**  */
	resolutionId?: Maybe<string> /**  */
}


/**  */
export type FeatureSwitchInput = {
	desktop: boolean /**  */
	stackable: boolean /**  */
	rackable: boolean /**  */
	poe: boolean /**  */
	modem: boolean /**  */
	firewall: boolean /**  */
	consolePort: boolean /**  */
	proxy: boolean /**  */
	vpn: boolean /**  */
	utm: boolean /**  */
}


/**  */
export type FeatureNetworkInput = {
	wifi: boolean /**  */
	sSid?: Maybe<string> /**  */
	securityKey?: Maybe<string> /**  */
	webAdministration: boolean /**  */
	snmp: boolean /**  */
}


/**  */
export type FeaturePrinterInput = {
	color: boolean /**  */
	share: boolean /**  */
	shareName?: Maybe<string> /**  */
	speedBlack: number /**  */
	speedColor: number /**  */
	rectoVerso: boolean /**  */
	scanner: boolean /**  */
	fax: boolean /**  */
	dpiResolutionId?: Maybe<string> /**  */
	paperFormats?: Array<Maybe<PaperFormatInput>> /**  */
	connectionCategories?: Array<Maybe<ConnectionCategoryInput>> /**  */
	printerTechnologyId?: Maybe<string> /**  */
	orientations?: Array<Maybe<OrientationInput>> /**  */
}


/**  */
export type OrientationInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type FeatureVideoProjectorInput = {
	speaker: boolean /**  */
	remoteControl: boolean /**  */
	resolutions?: Array<Maybe<ResolutionInput>> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	powerLampId?: Maybe<string> /**  */
	brightnessId?: Maybe<string> /**  */
	videoProjectorTypeId?: Maybe<string> /**  */
	noiseLevelId?: Maybe<string> /**  */
	videoProjectorFormatId?: Maybe<string> /**  */
	connectionCategories?: Array<Maybe<ConnectionCategoryInput>> /**  */
}


/**  */
export type FeatureMobileInput = {
	imei?: Maybe<string> /**  */
	firstSimId?: Maybe<string> /**  */
	secondSimId?: Maybe<string> /**  */
}


/**  */
export type FeatureTelephonyInput = {
	phoneNumber?: Maybe<string> /**  */
}


/**  */
export type DetectWarrantyInput = {
	lastAttempt?: Maybe<Date> /**  */
	lastSuccess?: Maybe<Date> /**  */
	message?: Maybe<string> /**  */
	success: boolean /**  */
}


/**  */
export type AssetFinancialInput = {
	budgetId?: Maybe<string> /**  */
	acquisition?: Maybe<AssetAcquisitionInput> /**  */
	internalInvoice?: Maybe<AssetInternalInvoiceInput> /**  */
	accounting?: Maybe<AssetAccountingInput> /**  */
}


/**  */
export type AssetAcquisitionInput = {
	modeId?: Maybe<string> /**  */
	stateId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	batchDate?: Maybe<Date> /**  */
	batchNumber?: Maybe<string> /**  */
	invoiceDate?: Maybe<Date> /**  */
	invoiceNumber?: Maybe<string> /**  */
	price: number /**  */
	supplierId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/**  */
export type AssetInternalInvoiceInput = {
	date?: Maybe<Date> /**  */
	number?: Maybe<string> /**  */
	amount: number /**  */
	organizationalUnitId?: Maybe<string> /**  */
}


/**  */
export type AssetAccountingInput = {
	typeId?: Maybe<string> /**  */
	startDepreciation?: Maybe<Date> /**  */
	endDepreciation?: Maybe<Date> /**  */
	duration: number /**  */
	numberImmobilization?: Maybe<string> /**  */
	recouping?: Maybe<string> /**  */
	analyticalImputation?: Maybe<string> /**  */
	costCenterId?: Maybe<string> /**  */
}


/**  */
export type AssetNamingConventionInput = {
	onBarCode?: Maybe<NamingConventionSummaryInput> /**  */
	onInventoryNumber?: Maybe<NamingConventionSummaryInput> /**  */
	onName?: Maybe<NamingConventionSummaryInput> /**  */
	onNetBios?: Maybe<NamingConventionSummaryInput> /**  */
	onAssetNumber?: Maybe<NamingConventionSummaryInput> /**  */
	alreadyApply: boolean /**  */
}


/**  */
export type NamingConventionSummaryInput = {
	value?: Maybe<string> /**  */
	namingConventionMask?: Maybe<string> /**  */
	manualEdit: boolean /**  */
	lock: boolean /**  */
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/**  */
export type EntityLocationCharterInput = {
	onLocationId?: Maybe<LocationCharterSummaryInput> /**  */
	onOrganizationalUnitId?: Maybe<LocationCharterSummaryInput> /**  */
}


/**  */
export type LocationCharterSummaryInput = {
	value?: Maybe<string> /**  */
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/**  */
export type AuditResultInput = {
	antiSpywareProducts?: Array<Maybe<AntiSpywareProductInput>> /**  */
	antiVirusProducts?: Array<Maybe<AntiVirusProductInput>> /**  */
	autoRuns?: Array<Maybe<AutoRunInput>> /**  */
	baseBoards?: Array<Maybe<BaseBoardInput>> /**  */
	bios?: Array<Maybe<BiosInput>> /**  */
	cdRomDrives?: Array<Maybe<CDRomDriveInput>> /**  */
	computerSystems?: Array<Maybe<ComputerSystemInput>> /**  */
	computerSystemProducts?: Array<Maybe<ComputerSystemProductInput>> /**  */
	dataFiles?: Array<Maybe<DataFileInput>> /**  */
	diskDrives?: Array<Maybe<DiskDriveInput>> /**  */
	logicalDisks?: Array<Maybe<LogicalDiskInput>> /**  */
	environmentVariables?: Array<Maybe<EnvironmentVariableInput>> /**  */
	firewallProducts?: Array<Maybe<FirewallProductInput>> /**  */
	fontInfoActions?: Array<Maybe<FontInfoActionInput>> /**  */
	groups?: Array<Maybe<GroupInput>> /**  */
	keyboards?: Array<Maybe<KeyboardInput>> /**  */
	monitors?: Array<Maybe<MonitorInput>> /**  */
	virtualMachines?: Array<Maybe<VirtualMachineInput>> /**  */
	networkAdapters?: Array<Maybe<NetworkAdapterInput>> /**  */
	networkDrives?: Array<Maybe<NetworkDriveInput>> /**  */
	ntLogEvents?: Array<Maybe<NtLogEventInput>> /**  */
	operatingSystems?: Array<Maybe<OperatingSystemInput>> /**  */
	parallelPorts?: Array<Maybe<ParallelPortInput>> /**  */
	physicalMemoryArrays?: Array<Maybe<PhysicalMemoryArrayInput>> /**  */
	physicalMemories?: Array<Maybe<PhysicalMemoryInput>> /**  */
	pnpEntities?: Array<Maybe<PnpEntityInput>> /**  */
	pointingDevices?: Array<Maybe<PointingDeviceInput>> /**  */
	portConnectors?: Array<Maybe<PortConnectorInput>> /**  */
	potsModems?: Array<Maybe<PotsModemInput>> /**  */
	printers?: Array<Maybe<PrinterInput>> /**  */
	processes?: Array<Maybe<ProcessInput>> /**  */
	processors?: Array<Maybe<ProcessorInput>> /**  */
	programs?: Array<Maybe<ProgramInput>> /**  */
	quickFixEngineerings?: Array<Maybe<QuickFixEngineeringInput>> /**  */
	workApplications?: Array<Maybe<WorkApplicationInput>> /**  */
	serialPorts?: Array<Maybe<SerialPortInput>> /**  */
	windowsServices?: Array<Maybe<WindowsServiceInput>> /**  */
	shares?: Array<Maybe<ShareInput>> /**  */
	sqlServers?: Array<Maybe<SqlServerInput>> /**  */
	soundDevices?: Array<Maybe<SoundDeviceInput>> /**  */
	systemEnclosures?: Array<Maybe<SystemEnclosureInput>> /**  */
	systemSlots?: Array<Maybe<SystemSlotInput>> /**  */
	tapeDrives?: Array<Maybe<TapeDriveInput>> /**  */
	userAccountControls?: Array<Maybe<UserAccountControlInput>> /**  */
	userAccounts?: Array<Maybe<UserAccountInput>> /**  */
	userProfiles?: Array<Maybe<UserProfileInput>> /**  */
	videoControllers?: Array<Maybe<VideoControllerInput>> /**  */
	windowsFirewalls?: Array<Maybe<WindowsFirewallInput>> /**  */
	windowsUpdates?: Array<Maybe<WindowsUpdateInput>> /**  */
	scanRegistries?: Array<Maybe<ScanRegistryInput>> /**  */
	printerConsumables?: Array<Maybe<PrinterConsumableInput>> /**  */
	pageCounts?: Array<Maybe<PageCountInput>> /**  */
	copyCounts?: Array<Maybe<CopyCountInput>> /**  */
	scannerCounts?: Array<Maybe<ScannerCountInput>> /**  */
	faxCounts?: Array<Maybe<FaxCountInput>> /**  */
	entryTables?: Array<Maybe<EntryTableInput>> /**  */
	vlans?: Array<Maybe<VlanInput>> /**  */
	accessPoints?: Array<Maybe<AccessPointInput>> /**  */
	portOpens?: Array<Maybe<PortOpenInput>> /**  */
	odbcDrivers?: Array<Maybe<OdbcDriverInput>> /**  */
	systemAccounts?: Array<Maybe<SystemAccountInput>> /**  */
	logonSessions?: Array<Maybe<LogonSessionInput>> /**  */
	pnpSignedDrivers?: Array<Maybe<PnpSignedDriverInput>> /**  */
	serialNumbers?: Array<Maybe<SerialNumberInput>> /**  */
	uptimeCalendars?: Array<Maybe<UptimeCalendarInput>> /**  */
	bootConfigurations?: Array<Maybe<BootConfigurationInput>> /**  */
	pageFiles?: Array<Maybe<PageFileInput>> /**  */
	codecFiles?: Array<Maybe<CodecFileInput>> /**  */
	osRecoveryConfigurations?: Array<Maybe<OsRecoveryConfigurationInput>> /**  */
	scsiControllers?: Array<Maybe<ScsiControllerInput>> /**  */
	pcmciaControllers?: Array<Maybe<PcmciaControllerInput>> /**  */
	infraredDevices?: Array<Maybe<InfraredDeviceInput>> /**  */
	batteries?: Array<Maybe<BatteryInput>> /**  */
	portableBatteries?: Array<Maybe<PortableBatteryInput>> /**  */
	logonHistories?: Array<Maybe<LogonHistoryInput>> /**  */
	desktops?: Array<Maybe<DesktopInput>> /**  */
	optionalFeatures?: Array<Maybe<OptionalFeatureInput>> /**  */
	iis?: Array<Maybe<IisInput>> /**  */
	ldapProperties?: Array<Maybe<LdapPropertyInput>> /**  */
	logicalDiskHistories?: Array<Maybe<LogicalDiskHistoryInput>> /**  */
	internetExplorers?: Array<Maybe<InternetExplorerInput>> /**  */
	encryptableVolumes?: Array<Maybe<EncryptableVolumeInput>> /**  */
	snmpSummary?: Array<Maybe<SnmpSummaryInput>> /**  */
}


/**  */
export type AntiSpywareProductInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	displayName?: Maybe<string> /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToSignedProductExe?: Maybe<string> /**  */
	pathToSignedReportingExe?: Maybe<string> /**  */
	productState: number /**  */
}


/**  */
export type AntiVirusProductInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	displayName?: Maybe<string> /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToSignedProductExe?: Maybe<string> /**  */
	pathToSignedReportingExe?: Maybe<string> /**  */
	productState: number /**  */
}


/**  */
export type AutoRunInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
}


/**  */
export type BaseBoardInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	product?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type BiosInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	buildNumber?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	releaseDate?: Maybe<Date> /**  */
	serialNumber?: Maybe<string> /**  */
	smBiosVersion?: Maybe<string> /**  */
	smBiosMajorVersion: number /**  */
	smBiosMinorVersion: number /**  */
	smBiosPresent: boolean /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type CDRomDriveInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	mediaType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
}


/**  */
export type ComputerSystemInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	adminPasswordStatus: number /**  */
	automaticManagedPagefile: boolean /**  */
	automaticResetBootOption: boolean /**  */
	automaticResetCapability: boolean /**  */
	bootOptionOnLimit: number /**  */
	bootOptionOnWatchDog: number /**  */
	bootRomSupported: boolean /**  */
	bootupState?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	chassisBootupState: number /**  */
	currentTimeZone: number /**  */
	daylightInEffect: boolean /**  */
	description?: Maybe<string> /**  */
	dnsHostName?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	domainRole: DomainRole /**  */
	enableDaylightSavingsTime: boolean /**  */
	frontPanelResetStatus: number /**  */
	infraredSupported: boolean /**  */
	initialLoadInfo?: Maybe<string> /**  */
	keyboardPasswordStatus: number /**  */
	lastLoadInfo?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	nameFormat?: Maybe<string> /**  */
	networkServerModeEnabled: boolean /**  */
	numberOfLogicalProcessors: number /**  */
	numberOfProcessors: number /**  */
	oem?: Array<Maybe<string>> /**  */
	partOfDomain: boolean /**  */
	pauseAfterReset: number /**  */
	pcSystemType: PcSystemType /**  */
	powerManagementSupported: boolean /**  */
	powerOnPasswordStatus: number /**  */
	powerState: number /**  */
	powerSupplyState: number /**  */
	primaryOwnerContact?: Maybe<string> /**  */
	primaryOwnerName?: Maybe<string> /**  */
	resetCapability: number /**  */
	resetCount: number /**  */
	resetLimit: number /**  */
	roles?: Array<Maybe<string>> /**  */
	status?: Maybe<string> /**  */
	supportContactDescription?: Array<Maybe<string>> /**  */
	systemStartupDelay: number /**  */
	systemStartupOptions?: Array<Maybe<string>> /**  */
	systemType?: Maybe<string> /**  */
	thermalState: number /**  */
	totalPhysicalMemory: number /**  */
	userName?: Maybe<string> /**  */
	wakeUpType: number /**  */
	workGroup?: Maybe<string> /**  */
}


/**  */
export type ComputerSystemProductInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	identifyingNumber?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	skuNumber?: Maybe<string> /**  */
	uuid?: Maybe<string> /**  */
	vendor?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type DataFileInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	accessMask: number /**  */
	caption?: Maybe<string> /**  */
	compressed: boolean /**  */
	compressionMethod?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	eightDotThreeFileName?: Maybe<string> /**  */
	encrypted: boolean /**  */
	encryptionMethod?: Maybe<string> /**  */
	extension?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	fsName?: Maybe<string> /**  */
	hidden: boolean /**  */
	inUseCount: number /**  */
	lastAccessed?: Maybe<Date> /**  */
	lastModified?: Maybe<Date> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	readable: boolean /**  */
	status?: Maybe<string> /**  */
	system: boolean /**  */
	version?: Maybe<string> /**  */
	writeable: boolean /**  */
	scanDataFileId?: Maybe<string> /**  */
}


/**  */
export type DiskDriveInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	compressionMethod?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	firmwareRevision?: Maybe<string> /**  */
	index: number /**  */
	interfaceType?: Maybe<string> /**  */
	maxBlockSize: number /**  */
	mediaType?: Maybe<string> /**  */
	minBlockSize: number /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partitions: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
	scsiBus: number /**  */
	scsiLogicalUnit: number /**  */
	scsiPort: number /**  */
	scsiTargetId: number /**  */
	sectorsPerTrack: number /**  */
	serialNumber?: Maybe<string> /**  */
	size: number /**  */
	bytesPerSector: number /**  */
	totalCylinders: number /**  */
	totalHeads: number /**  */
	totalSectors: number /**  */
	totalTracks: number /**  */
	tracksPerCylinder: number /**  */
}


/**  */
export type LogicalDiskInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	providerName?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	compressed: boolean /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driveType: DriveType /**  */
	fileSystem?: Maybe<string> /**  */
	freeSpace: number /**  */
	name?: Maybe<string> /**  */
	volumeName?: Maybe<string> /**  */
	size: number /**  */
	volumeSerialNumber?: Maybe<string> /**  */
}


/**  */
export type EnvironmentVariableInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
}


/**  */
export type FirewallProductInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	companyName?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	enabled: boolean /**  */
	enableUImd5Hash: boolean /**  */
	enableUiParameters: boolean /**  */
	instanceGuid?: Maybe<string> /**  */
	pathToEnableUi?: Maybe<string> /**  */
	versionNumber?: Maybe<string> /**  */
}


/**  */
export type FontInfoActionInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	actionId?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	direction: number /**  */
	file?: Maybe<string> /**  */
	fontTitle?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	softwareElementId?: Maybe<string> /**  */
	softwareElementState: number /**  */
	targetOperatingSystem: number /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type GroupInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	localAccount: boolean /**  */
	name?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	sidType: number /**  */
	status?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	groupUsers?: Array<Maybe<GroupUserInput>> /**  */
}


/**  */
export type GroupUserInput = {
	userCaption?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	userDomain?: Maybe<string> /**  */
	userSid?: Maybe<string> /**  */
}


/**  */
export type KeyboardInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	isLocked: boolean /**  */
	lastErrorCode: number /**  */
	layout?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	numberOfFunctionKeys: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type MonitorInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	size: number /**  */
	manufactureDate?: Maybe<Date> /**  */
	manufactureDateYear: number /**  */
	manufactureDateWeek: number /**  */
	pnpId?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	serialNumberOriginal?: Maybe<string> /**  */
}


/**  */
export type VirtualMachineInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	elementName?: Maybe<string> /**  */
	statusDescriptions?: Array<Maybe<string>> /**  */
	status?: Maybe<string> /**  */
	healthState: number /**  */
	enabledState: number /**  */
	otherEnabledState?: Maybe<string> /**  */
	requestedState: number /**  */
	enabledDefault: number /**  */
	timeOfLastStateChange?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	primaryOwnerName?: Maybe<string> /**  */
	primaryOwnerContact?: Maybe<string> /**  */
	nameFormat?: Maybe<string> /**  */
	otherIdentifyingInfo?: Array<Maybe<string>> /**  */
	identifyingDescriptions?: Array<Maybe<string>> /**  */
	otherDedicatedDescriptions?: Array<Maybe<string>> /**  */
	resetCapability: number /**  */
	onTimeInMilliseconds: number /**  */
	timeOfLastConfigurationChange?: Maybe<Date> /**  */
	processId: number /**  */
	virtualMachineGuest?: Maybe<VirtualMachineGuestInput> /**  */
	assetLinkId?: Maybe<string> /**  */
}


/**  */
export type VirtualMachineGuestInput = {
	operatingSystem?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	hostName?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	memorySize: number /**  */
	hardDiskSize: number /**  */
	cpuCount: number /**  */
	vmPowerState: VmPowerState /**  */
	vmHealthStatus: VmHealthStatus /**  */
	macAddress?: Maybe<string> /**  */
}


/**  */
export type NetworkAdapterInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	adapterType?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	guid?: Maybe<string> /**  */
	index: number /**  */
	interfaceIndex: number /**  */
	macAddress?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	maxSpeed: number /**  */
	name?: Maybe<string> /**  */
	netConnectionId?: Maybe<string> /**  */
	netEnabled: boolean /**  */
	pnpDeviceId?: Maybe<string> /**  */
	powerManagementSupported: boolean /**  */
	productName?: Maybe<string> /**  */
	serviceName?: Maybe<string> /**  */
	speed: number /**  */
	networkAdapterConfiguration?: Maybe<NetworkAdapterConfigurationInput> /**  */
}


/**  */
export type NetworkAdapterConfigurationInput = {
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	dhcpEnabled: boolean /**  */
	dhcpServer?: Maybe<string> /**  */
	dnsDomain?: Maybe<string> /**  */
	dnsDomainSuffixSearchOrder?: Array<Maybe<string>> /**  */
	dnsEnabledForWinsResolution: boolean /**  */
	dnsHostName?: Maybe<string> /**  */
	dnsServerSearchOrder?: Array<Maybe<string>> /**  */
	domainDnsRegistrationEnabled: boolean /**  */
	index: number /**  */
	interfaceIndex: number /**  */
	ipEnabled: boolean /**  */
	macAddress?: Maybe<string> /**  */
	winsEnableLmHostsLookup: boolean /**  */
	winsHostLookupFile?: Maybe<string> /**  */
	winsPrimaryServer?: Maybe<string> /**  */
	winsSecondaryServer?: Maybe<string> /**  */
	ipAddress?: Array<Maybe<string>> /**  */
	ipSubnet?: Array<Maybe<string>> /**  */
	defaultIpGateway?: Array<Maybe<string>> /**  */
}


/**  */
export type NetworkDriveInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	remotePath?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	letter?: Maybe<string> /**  */
}


/**  */
export type NtLogEventInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	category: number /**  */
	categorystring?: Maybe<string> /**  */
	computerName?: Maybe<string> /**  */
	data: Array<number> /**  */
	eventCode: number /**  */
	eventIdentifier: number /**  */
	eventType: EventType /**  */
	insertionStrings?: Array<Maybe<string>> /**  */
	logfile?: Maybe<string> /**  */
	message?: Maybe<string> /**  */
	recordNumber: number /**  */
	sourceName?: Maybe<string> /**  */
	timeGenerated?: Maybe<Date> /**  */
	timeWritten?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	user?: Maybe<string> /**  */
}


/**  */
export type OperatingSystemInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	bootDevice?: Maybe<string> /**  */
	buildNumber?: Maybe<string> /**  */
	buildType?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	codeSet?: Maybe<string> /**  */
	countryCode?: Maybe<string> /**  */
	csdVersion?: Maybe<string> /**  */
	currentTimeZone: number /**  */
	debug: boolean /**  */
	description?: Maybe<string> /**  */
	distributed: boolean /**  */
	encryptionLevel: number /**  */
	foregroundApplicationBoost: number /**  */
	freePhysicalMemory: number /**  */
	freeSpaceInPagingFiles: number /**  */
	freeVirtualMemory: number /**  */
	largeSystemCache: number /**  */
	lastBootUpTime?: Maybe<Date> /**  */
	localDateTime?: Maybe<Date> /**  */
	locale?: Maybe<string> /**  */
	maxNumberOfProcesses: number /**  */
	maxProcessMemorySize: number /**  */
	muiLanguages?: Array<Maybe<string>> /**  */
	name?: Maybe<string> /**  */
	numberOfLicensedUsers: number /**  */
	numberOfProcesses: number /**  */
	numberOfUsers: number /**  */
	operatingSystemSku: number /**  */
	organization?: Maybe<string> /**  */
	osArchitecture?: Maybe<string> /**  */
	osLanguage: number /**  */
	osProductSuite: number /**  */
	osType: number /**  */
	otherTypeDescription?: Maybe<string> /**  */
	paeEnabled: boolean /**  */
	plusProductId?: Maybe<string> /**  */
	plusVersionNumber?: Maybe<string> /**  */
	primary: boolean /**  */
	productType: number /**  */
	registeredUser?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	servicePackMajorVersion: number /**  */
	servicePackMinorVersion: number /**  */
	sizeStoredInPagingFiles: number /**  */
	status?: Maybe<string> /**  */
	suiteMask: number /**  */
	systemDevice?: Maybe<string> /**  */
	systemDirectory?: Maybe<string> /**  */
	systemDrive?: Maybe<string> /**  */
	totalSwapSpaceSize: number /**  */
	totalVirtualMemorySize: number /**  */
	totalVisibleMemorySize: number /**  */
	version?: Maybe<string> /**  */
	windowsDirectory?: Maybe<string> /**  */
	digitalProductId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
}


/**  */
export type ParallelPortInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type PhysicalMemoryArrayInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	location: number /**  */
	maxCapacity: number /**  */
	memoryDevices: number /**  */
	memoryErrorCorrection: number /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	use: number /**  */
}


/**  */
export type PhysicalMemoryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	bankLabel?: Maybe<string> /**  */
	capacity: number /**  */
	caption?: Maybe<string> /**  */
	dataWidth: number /**  */
	description?: Maybe<string> /**  */
	deviceLocator?: Maybe<string> /**  */
	formFactor: FormFactor /**  */
	hotSwappable: boolean /**  */
	interleaveDataDepth: number /**  */
	interleavePosition: number /**  */
	memoryType: MemoryType /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partNumber?: Maybe<string> /**  */
	positionInRow: number /**  */
	poweredOn: boolean /**  */
	removable: boolean /**  */
	replaceable: boolean /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	speed: number /**  */
	tag?: Maybe<string> /**  */
	totalWidth: number /**  */
}


/**  */
export type PnpEntityInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	classGuid?: Maybe<string> /**  */
	pnpEntityString?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	hardwareId?: Array<Maybe<string>> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	service?: Maybe<string> /**  */
	configManagerErrorCode: number /**  */
	configManagerErrorCodeString?: Maybe<string> /**  */
	errorDescription?: Maybe<string> /**  */
}


/**  */
export type PointingDeviceInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	hardwareType?: Maybe<string> /**  */
	infFileName?: Maybe<string> /**  */
	infSection?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	numberOfButtons: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type PortConnectorInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	externalReferenceDesignator?: Maybe<string> /**  */
	internalReferenceDesignator?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	portType: number /**  */
	tag?: Maybe<string> /**  */
}


/**  */
export type PotsModemInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type PrinterInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	action: number /**  */
	attributes: number /**  */
	dataType?: Maybe<string> /**  */
	objectGuid?: Maybe<string> /**  */
	printProcessor?: Maybe<string> /**  */
	printerDriver?: Maybe<string> /**  */
	priority: number /**  */
	separatorFile?: Maybe<string> /**  */
	shareName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	status: number /**  */
	regeditPath?: Maybe<string> /**  */
	isNetWork: boolean /**  */
	isDefault: boolean /**  */
	ipAddress?: Maybe<string> /**  */
	deviceInstanceId?: Maybe<string> /**  */
	hardwareId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	printerName?: Maybe<string> /**  */
	printShareName?: Maybe<string> /**  */
	serverName?: Maybe<string> /**  */
	shortServerName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	driverName?: Maybe<string> /**  */
	portName?: Maybe<string> /**  */
}


/**  */
export type ProcessInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	handle?: Maybe<string> /**  */
	processId: number /**  */
	caption?: Maybe<string> /**  */
	commandLine?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	executablePath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	osCreationClassName?: Maybe<string> /**  */
	osName?: Maybe<string> /**  */
	virtualSize: number /**  */
	workingSetSize: number /**  */
}


/**  */
export type ProcessorInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	addressWidth: number /**  */
	architecture: Architecture /**  */
	availability: Availability /**  */
	caption?: Maybe<string> /**  */
	configManagerErrorCode: number /**  */
	configManagerUserConfig: boolean /**  */
	cpuStatus: number /**  */
	currentClockSpeed: number /**  */
	currentVoltage: number /**  */
	dataWidth: number /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	errorCleared: boolean /**  */
	errorDescription?: Maybe<string> /**  */
	extClock: number /**  */
	family: number /**  */
	l2CacheSize: number /**  */
	l2CacheSpeed: number /**  */
	l3CacheSize: number /**  */
	l3CacheSpeed: number /**  */
	lastErrorCode: number /**  */
	level: number /**  */
	loadPercentage: number /**  */
	manufacturer?: Maybe<string> /**  */
	maxClockSpeed: number /**  */
	name?: Maybe<string> /**  */
	numberOfCores: number /**  */
	numberOfLogicalProcessors: number /**  */
	otherFamilyDescription?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	powerManagementSupported: boolean /**  */
	processorId?: Maybe<string> /**  */
	processorType: number /**  */
	revision: number /**  */
	role?: Maybe<string> /**  */
	socketDesignation?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	statusInfo: number /**  */
	stepping?: Maybe<string> /**  */
	uniqueId?: Maybe<string> /**  */
	upgradeMethod: number /**  */
	version?: Maybe<string> /**  */
	voltageCaps: number /**  */
}


/**  */
export type ProgramInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	parentDisplayName?: Maybe<string> /**  */
	contact?: Maybe<string> /**  */
	estimatedSize: number /**  */
	installLocation?: Maybe<string> /**  */
	installSource?: Maybe<string> /**  */
	helpLink?: Maybe<string> /**  */
	language: number /**  */
	productId?: Maybe<string> /**  */
	uninstallString?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	publisher?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
}


/**  */
export type QuickFixEngineeringInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	fixComments?: Maybe<string> /**  */
	hotFixId?: Maybe<string> /**  */
	installedBy?: Maybe<string> /**  */
	installedOn?: Maybe<string> /**  */
	installedDate?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	servicePackInEffect?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
}


/**  */
export type WorkApplicationInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwareId?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	displayVersion?: Maybe<string> /**  */
	publisher?: Maybe<string> /**  */
}


/**  */
export type SerialPortInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type WindowsServiceInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	acceptPause: boolean /**  */
	acceptStop: boolean /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pathName?: Maybe<string> /**  */
	started: boolean /**  */
	startMode: StartMode /**  */
	startName?: Maybe<string> /**  */
	state: ServiceState /**  */
}


/**  */
export type ShareInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	accessMask: number /**  */
	allowMaximum: boolean /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	maximumAllowed: number /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	type: ShareCategory /**  */
	shareSecurities?: Array<Maybe<ShareSecurityInput>> /**  */
}


/**  */
export type ShareSecurityInput = {
	name?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	accessMaskValue: number /**  */
	shareAccess?: Maybe<ShareAccessInput> /**  */
	sidString?: Maybe<string> /**  */
	sidLength: number /**  */
	aceFlags: number /**  */
	aceType: number /**  */
}


/**  */
export type ShareAccessInput = {
	read: boolean /**  */
	write: boolean /**  */
	fullAccess: boolean /**  */
	deny: boolean /**  */
}


/**  */
export type SqlServerInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	instanceName?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	edition?: Maybe<string> /**  */
	licenceName?: Maybe<string> /**  */
	fileVersion?: Maybe<string> /**  */
	servicePack?: Maybe<string> /**  */
	wow64: boolean /**  */
	language?: Maybe<string> /**  */
	installPath?: Maybe<string> /**  */
	dataPath?: Maybe<string> /**  */
	sqlService?: Maybe<SqlServiceInput> /**  */
	dataBaseInformations?: Array<Maybe<DataBaseInformationInput>> /**  */
}


/**  */
export type SqlServiceInput = {
	acceptPause: boolean /**  */
	acceptStop: boolean /**  */
	binaryPath?: Maybe<string> /**  */
	dependencies?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	displayName?: Maybe<string> /**  */
	errorControl: number /**  */
	exitCode: number /**  */
	hostName?: Maybe<string> /**  */
	processId: number /**  */
	serviceName?: Maybe<string> /**  */
	sqlServiceType: SqlServiceCategory /**  */
	startMode: StartMode /**  */
	startName?: Maybe<string> /**  */
	state: ServiceState /**  */
}


/**  */
export type DataBaseInformationInput = {
	name?: Maybe<string> /**  */
	dataFilesSizeKb?: Maybe<string> /**  */
	logFilesSizeKb?: Maybe<string> /**  */
	logFilesUsedSizeKb?: Maybe<string> /**  */
}


/**  */
export type SoundDeviceInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	productName?: Maybe<string> /**  */
}


/**  */
export type SystemEnclosureInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	audibleAlarm: boolean /**  */
	caption?: Maybe<string> /**  */
	chassisTypes: ChassisType /**  */
	description?: Maybe<string> /**  */
	lockPresent: boolean /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	partNumber?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	sku?: Maybe<string> /**  */
	smbiosAssetTag?: Maybe<string> /**  */
	tag?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	computerDescription?: Maybe<string> /**  */
	dockingMode: DockingMode /**  */
}


/**  */
export type SystemSlotInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	slotDesignation?: Maybe<string> /**  */
	tag?: Maybe<string> /**  */
}


/**  */
export type TapeDriveInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	ecc: number /**  */
	manufacturer?: Maybe<string> /**  */
	mediaType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	needsCleaning: boolean /**  */
	numberOfMediaSupported: number /**  */
	pnpDeviceId?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	statusInfo: number /**  */
}


/**  */
export type UserAccountControlInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	consentPromptBehaviorAdmin: ConsentPromptBehaviorAdmin /**  */
	consentPromptBehaviorUser: ConsentPromptBehaviorUser /**  */
	enableInstallerDetection: boolean /**  */
	enableLua: boolean /**  */
	promptOnSecureDesktop: boolean /**  */
	filterAdministratorToken: boolean /**  */
	enableUiaDesktopToggle: boolean /**  */
	enableSecureUiaPaths: boolean /**  */
	validateAdminCodeSignatures: boolean /**  */
	enableVirtualization: boolean /**  */
}


/**  */
export type UserAccountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	accountType: number /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	disabled: boolean /**  */
	domain?: Maybe<string> /**  */
	fullName?: Maybe<string> /**  */
	localAccount: boolean /**  */
	lockout: boolean /**  */
	name?: Maybe<string> /**  */
	passwordChangeable: boolean /**  */
	passwordExpires: boolean /**  */
	passwordRequired: boolean /**  */
	sid?: Maybe<string> /**  */
	sidType: number /**  */
	status?: Maybe<string> /**  */
}


/**  */
export type UserProfileInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	lastDownloadTime?: Maybe<Date> /**  */
	lastUploadTime?: Maybe<Date> /**  */
	lastUseTime?: Maybe<Date> /**  */
	loaded: boolean /**  */
	localPath?: Maybe<string> /**  */
	refCount: number /**  */
	roamingConfigured: boolean /**  */
	roamingPath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	roamingPreference: boolean /**  */
	sid?: Maybe<string> /**  */
	special: boolean /**  */
	status: number /**  */
}


/**  */
export type VideoControllerInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	adapterCompatibility?: Maybe<string> /**  */
	adapterDacType?: Maybe<string> /**  */
	adapterRam: number /**  */
	caption?: Maybe<string> /**  */
	currentBitsPerPixel: number /**  */
	currentHorizontalResolution: number /**  */
	currentNumberOfColors: number /**  */
	currentNumberOfColumns: number /**  */
	currentNumberOfRows: number /**  */
	currentRefreshRate: number /**  */
	currentScanMode: number /**  */
	currentVerticalResolution: number /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driverDate?: Maybe<Date> /**  */
	driverVersion?: Maybe<string> /**  */
	monochrome: boolean /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	videoArchitecture: number /**  */
	videoMemoryType: number /**  */
	videoMode: number /**  */
	videoModeDescription?: Maybe<string> /**  */
	videoProcessor?: Maybe<string> /**  */
}


/**  */
export type WindowsFirewallInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	domainProfile?: Maybe<FirewallPolicyInput> /**  */
	publicProfile?: Maybe<FirewallPolicyInput> /**  */
	standardProfile?: Maybe<FirewallPolicyInput> /**  */
}


/**  */
export type FirewallPolicyInput = {
	disableNotifications: boolean /**  */
	doNotAllowExceptions: boolean /**  */
	enableFirewall: boolean /**  */
}


/**  */
export type WindowsUpdateInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	auOptions: number /**  */
	nextDetectionTime?: Maybe<string> /**  */
	scheduledInstallDate?: Maybe<string> /**  */
	scheduledInstallDay: number /**  */
	scheduledInstallTime: number /**  */
	detect?: Maybe<WindowsUpdateResultInput> /**  */
	download?: Maybe<WindowsUpdateResultInput> /**  */
	install?: Maybe<WindowsUpdateResultInput> /**  */
}


/**  */
export type WindowsUpdateResultInput = {
	lastError: number /**  */
	lastSuccessTime?: Maybe<string> /**  */
}


/**  */
export type ScanRegistryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	scanRegistryKeyId?: Maybe<string> /**  */
}


/**  */
export type PrinterConsumableInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	maxLevel: number /**  */
	actualLevel: number /**  */
	colorCode?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type PageCountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	color: number /**  */
	black: number /**  */
	twoColor: number /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type CopyCountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	color: number /**  */
	black: number /**  */
	twoColor: number /**  */
	monochrome: number /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type ScannerCountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	total: number /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type FaxCountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	color: number /**  */
	black: number /**  */
	send: number /**  */
	receive: number /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type EntryTableInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	ifIndex?: Maybe<string> /**  */
	ifDescr?: Maybe<string> /**  */
	ifType?: Maybe<string> /**  */
	ifMtu?: Maybe<string> /**  */
	ifSpeed?: Maybe<string> /**  */
	ifPhysAddress?: Maybe<string> /**  */
	ifAdminStatus?: Maybe<string> /**  */
	ifOperStatus?: Maybe<string> /**  */
	ifLastChange?: Maybe<string> /**  */
	ifInOctets?: Maybe<string> /**  */
	ifInUcastPkts?: Maybe<string> /**  */
	ifInNUcastPkts?: Maybe<string> /**  */
	ifInDiscards?: Maybe<string> /**  */
	ifInErrors?: Maybe<string> /**  */
	ifInUnknownProtos?: Maybe<string> /**  */
	ifOutOctets?: Maybe<string> /**  */
	ifOutUcastPkts?: Maybe<string> /**  */
	ifOutNUcastPkts?: Maybe<string> /**  */
	ifOutDiscards?: Maybe<string> /**  */
	ifOutErrors?: Maybe<string> /**  */
	ifOutQLen?: Maybe<string> /**  */
	ifSpecific?: Maybe<string> /**  */
}


/**  */
export type VlanInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	port: number /**  */
	macAddress?: Maybe<string> /**  */
}


/**  */
export type AccessPointInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	macAddress?: Maybe<string> /**  */
	bSSid?: Maybe<string> /**  */
	ssid?: Maybe<string> /**  */
	state?: Maybe<string> /**  */
}


/**  */
export type PortOpenInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	portName?: Maybe<string> /**  */
	portNumber: number /**  */
	open: boolean /**  */
}


/**  */
export type OdbcDriverInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	driver?: Maybe<string> /**  */
	apiLevel?: Maybe<string> /**  */
	connectFunctions?: Maybe<string> /**  */
	driverOdbcVer?: Maybe<string> /**  */
	fileExtns?: Maybe<string> /**  */
	fileUsage?: Maybe<string> /**  */
	setup?: Maybe<string> /**  */
	sqlLevel?: Maybe<string> /**  */
	usageCount: number /**  */
	regeditPath?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/**  */
export type SystemAccountInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	localAccount: boolean /**  */
	name?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
	sidType: number /**  */
}


/**  */
export type LogonSessionInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	authenticationPackage?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logonId?: Maybe<string> /**  */
	logonType: LogonType /**  */
	name?: Maybe<string> /**  */
	startTime?: Maybe<Date> /**  */
	status?: Maybe<string> /**  */
	logonName?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	sid?: Maybe<string> /**  */
	fullName?: Maybe<string> /**  */
	azureObjectId?: Maybe<string> /**  */
	azureAccountId?: Maybe<string> /**  */
	azureDisplayName?: Maybe<string> /**  */
	azureIdentityName?: Maybe<string> /**  */
	azureTenantId?: Maybe<string> /**  */
}


/**  */
export type PnpSignedDriverInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	classGuid?: Maybe<string> /**  */
	compatId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceClass?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	deviceName?: Maybe<string> /**  */
	devLoader?: Maybe<string> /**  */
	driverDate?: Maybe<Date> /**  */
	driverName?: Maybe<string> /**  */
	driverVersion?: Maybe<string> /**  */
	friendlyName?: Maybe<string> /**  */
	hardWareId?: Maybe<string> /**  */
	infName?: Maybe<string> /**  */
	installDate?: Maybe<Date> /**  */
	isSigned: boolean /**  */
	location?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pdo?: Maybe<string> /**  */
	driverProviderName?: Maybe<string> /**  */
	signer?: Maybe<string> /**  */
	started: boolean /**  */
	startMode?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
}


/**  */
export type SerialNumberInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	productName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	name2?: Maybe<string> /**  */
	value2?: Maybe<string> /**  */
	guid?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
	serialNumberMode: SerialNumberMode /**  */
}


/**  */
export type UptimeCalendarInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	date?: Maybe<Date> /**  */
	uptimeCalendarMode: UptimeCalendarMode /**  */
	shutDownReason?: Maybe<string> /**  */
	shutDownType?: Maybe<string> /**  */
	shutDownProcess?: Maybe<string> /**  */
	shutDownCode?: Maybe<string> /**  */
	recordNumber: number /**  */
}


/**  */
export type BootConfigurationInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	bootDirectory?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	configurationPath?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	lastDrive?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	scratchDirectory?: Maybe<string> /**  */
	settingId?: Maybe<string> /**  */
	tempDirectory?: Maybe<string> /**  */
}


/**  */
export type PageFileInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	freeSpace: number /**  */
	initialSize: number /**  */
	inUseCount: number /**  */
	manufacturer?: Maybe<string> /**  */
	maximumSize: number /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type CodecFileInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	creationDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	drive?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	fileSize: number /**  */
	fileType?: Maybe<string> /**  */
	group?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type OsRecoveryConfigurationInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	autoReboot: boolean /**  */
	caption?: Maybe<string> /**  */
	debugFilePath?: Maybe<string> /**  */
	debugInfoType: number /**  */
	description?: Maybe<string> /**  */
	expandedDebugFilePath?: Maybe<string> /**  */
	expandedMiniDumpDirectory?: Maybe<string> /**  */
	kernelDumpOnly: boolean /**  */
	miniDumpDirectory?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	overwriteExistingDebugFile: boolean /**  */
	sendAdminAlert: boolean /**  */
	settingId?: Maybe<string> /**  */
	writeDebugInfo: boolean /**  */
	writeToSystemLog: boolean /**  */
}


/**  */
export type ScsiControllerInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driverName?: Maybe<string> /**  */
	index: number /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type PcmciaControllerInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type InfraredDeviceInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
}


/**  */
export type BatteryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	batteryRechargeTime: number /**  */
	batteryStatus: BatteryStatus /**  */
	caption?: Maybe<string> /**  */
	chemistry: Chemistry /**  */
	description?: Maybe<string> /**  */
	designCapacity: number /**  */
	designVoltage: number /**  */
	deviceId?: Maybe<string> /**  */
	estimatedChargeRemaining: number /**  */
	estimatedRunTime: number /**  */
	expectedBatteryLife: number /**  */
	expectedLife: number /**  */
	fullChargeCapacity: number /**  */
	maxRechargeTime: number /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	timeOnBattery: number /**  */
	timeToFullCharge: number /**  */
}


/**  */
export type PortableBatteryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	batteryRechargeTime: number /**  */
	batteryStatus: BatteryStatus /**  */
	capacityMultiplier: number /**  */
	caption?: Maybe<string> /**  */
	chemistry: Chemistry /**  */
	description?: Maybe<string> /**  */
	designCapacity: number /**  */
	designVoltage: number /**  */
	deviceId?: Maybe<string> /**  */
	estimatedChargeRemaining: number /**  */
	estimatedRunTime: number /**  */
	expectedBatteryLife: number /**  */
	expectedLife: number /**  */
	fullChargeCapacity: number /**  */
	location?: Maybe<string> /**  */
	manufactureDate?: Maybe<string> /**  */
	manufacturer?: Maybe<string> /**  */
	maxBatteryError: number /**  */
	maxRechargeTime: number /**  */
	name?: Maybe<string> /**  */
	pnpDeviceId?: Maybe<string> /**  */
	smartBatteryVersion?: Maybe<string> /**  */
	timeOnBattery: number /**  */
	timeToFullCharge: number /**  */
}


/**  */
export type LogonHistoryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	date?: Maybe<Date> /**  */
	logonHistoryMode: LogonHistoryMode /**  */
	userCaption?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	userDomain?: Maybe<string> /**  */
	userSid?: Maybe<string> /**  */
	recordNumber: number /**  */
}


/**  */
export type DesktopInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	borderWidth: number /**  */
	caption?: Maybe<string> /**  */
	coolSwitch: boolean /**  */
	cursorBlinkRate: number /**  */
	description?: Maybe<string> /**  */
	dragFullWindows: boolean /**  */
	gridGranularity: number /**  */
	iconSpacing: number /**  */
	iconTitleFaceName?: Maybe<string> /**  */
	iconTitleSize: number /**  */
	iconTitleWrap: boolean /**  */
	name?: Maybe<string> /**  */
	pattern?: Maybe<string> /**  */
	screenSaverActive: boolean /**  */
	screenSaverExecutable?: Maybe<string> /**  */
	screenSaverSecure: boolean /**  */
	screenSaverTimeout: number /**  */
	settingId?: Maybe<string> /**  */
	wallpaper?: Maybe<string> /**  */
	wallpaperStretched: boolean /**  */
	wallpaperTiled: boolean /**  */
}


/**  */
export type OptionalFeatureInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	name?: Maybe<string> /**  */
	caption?: Maybe<string> /**  */
	installState: number /**  */
	description?: Maybe<string> /**  */
	status?: Maybe<string> /**  */
}


/**  */
export type IisInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	iisProgramGroup?: Maybe<string> /**  */
	installPath?: Maybe<string> /**  */
	pathWwwRoot?: Maybe<string> /**  */
	productString?: Maybe<string> /**  */
	setupString?: Maybe<string> /**  */
	versionString?: Maybe<string> /**  */
	majorVersion: number /**  */
	metabaseSetMajorVersion: number /**  */
	metabaseSetMinorVersion: number /**  */
	minorVersion: number /**  */
	iisSites?: Array<Maybe<IisSiteInput>> /**  */
	iisApplicationPools?: Array<Maybe<IisApplicationPoolInput>> /**  */
	iisComponents?: Array<Maybe<IisComponentInput>> /**  */
}


/**  */
export type IisSiteInput = {
	siteId: number /**  */
	adsiPath?: Maybe<string> /**  */
	logFileDirectory?: Maybe<string> /**  */
	appPoolId?: Maybe<string> /**  */
	dontLog: boolean /**  */
	defaultDoc?: Maybe<string> /**  */
	authFlags: number /**  */
	anonymousUserName?: Maybe<string> /**  */
	ntAuthenticationProviders?: Maybe<string> /**  */
	serverState: number /**  */
	name?: Maybe<string> /**  */
	serverBindings?: Maybe<string> /**  */
	iisApplications?: Array<Maybe<IisApplicationInput>> /**  */
}


/**  */
export type IisApplicationInput = {
	applicationPool?: Maybe<string> /**  */
	defaultDoc?: Maybe<string> /**  */
	adsiPath?: Maybe<string> /**  */
	authFlags: number /**  */
	anonymousUserName?: Maybe<string> /**  */
	ntAuthenticationProviders?: Maybe<string> /**  */
	path?: Maybe<string> /**  */
	appFriendlyName?: Maybe<string> /**  */
	appIsolated: number /**  */
	appRoot?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/**  */
export type IisApplicationPoolInput = {
	managedRuntimeVersion?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	adsiPath?: Maybe<string> /**  */
	state: number /**  */
}


/**  */
export type IisComponentInput = {
	w3Svc: number /**  */
	commonHttpFeaturesIis?: Maybe<CommonHttpFeaturesIisInput> /**  */
	applicationDevelopmentFeaturesIis?: Maybe<ApplicationDevelopmentFeaturesIisInput> /**  */
	healthandDiagnosticsIis?: Maybe<HealthandDiagnosticsIisInput> /**  */
	securityIis?: Maybe<SecurityIisInput> /**  */
	performanceFeaturesIis?: Maybe<PerformanceFeaturesIisInput> /**  */
	managementToolsIis?: Maybe<ManagementToolsIisInput> /**  */
	managementServiceIis?: Maybe<ManagementServiceIisInput> /**  */
	fTpPublishingServiceIis?: Maybe<FtpPublishingServiceIisInput> /**  */
	windowsProcessActivationService?: Maybe<WindowsProcessActivationServiceInput> /**  */
}


/**  */
export type CommonHttpFeaturesIisInput = {
	staticContent: number /**  */
	defaultDocument: number /**  */
	directoryBrowse: number /**  */
	httpErrors: number /**  */
	httpRedirect: number /**  */
}


/**  */
export type ApplicationDevelopmentFeaturesIisInput = {
	aspNet: number /**  */
	netFxExtensibility: number /**  */
	asp: number /**  */
	cgi: number /**  */
	isapiExtensions: number /**  */
	isapiFilter: number /**  */
	serverSideInclude: number /**  */
}


/**  */
export type HealthandDiagnosticsIisInput = {
	httpLogging: number /**  */
	loggingLibraries: number /**  */
	requestMonitor: number /**  */
	httpTracing: number /**  */
	customLogging: number /**  */
	odbcLogging: number /**  */
}


/**  */
export type SecurityIisInput = {
	basicAuthentication: number /**  */
	windowsAuthentication: number /**  */
	digestAuthentication: number /**  */
	clientCertificateMappingAuthentication: number /**  */
	iisClientCertificateMappingAuthentication: number /**  */
	authorization: number /**  */
	requestFiltering: number /**  */
	ipSecurity: number /**  */
}


/**  */
export type PerformanceFeaturesIisInput = {
	httpCompressionStatic: number /**  */
	httpCompressionDynamic: number /**  */
}


/**  */
export type ManagementToolsIisInput = {
	managementConsole: number /**  */
	managementScriptingTools: number /**  */
	adminService: number /**  */
}


/**  */
export type ManagementServiceIisInput = {
	metabase: number /**  */
	wmiCompatibility: number /**  */
	legacyScripts: number /**  */
	legacySnapin: number /**  */
}


/**  */
export type FtpPublishingServiceIisInput = {
	ftpServer: number /**  */
}


/**  */
export type WindowsProcessActivationServiceInput = {
	processModel: number /**  */
	netFxEnvironment: number /**  */
	wasConfigurationApi: number /**  */
}


/**  */
export type LdapPropertyInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	objectClass?: Maybe<string> /**  */
	cN?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	objectGuid: string /**  */
	userAccountControl: number /**  */
	badPwdCount: number /**  */
	codePage: number /**  */
	countryCode: number /**  */
	badPasswordTime?: Maybe<Date> /**  */
	lastLogoff?: Maybe<Date> /**  */
	lastLogon?: Maybe<Date> /**  */
	localPolicyFlags: number /**  */
	pwdLastSet?: Maybe<Date> /**  */
	primaryGroupId: number /**  */
	objectSid?: Maybe<string> /**  */
	accountExpires?: Maybe<Date> /**  */
	logonCount: number /**  */
	sAmAccountName?: Maybe<string> /**  */
	operatingSystem?: Maybe<string> /**  */
	operatingSystemVersion?: Maybe<string> /**  */
	dNsHostName?: Maybe<string> /**  */
	objectCategory?: Maybe<string> /**  */
	isCriticalSystemObject: boolean /**  */
	path?: Maybe<string> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	nTSecurityDescriptor?: Maybe<Date> /**  */
}


/**  */
export type LogicalDiskHistoryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	caption?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	diskDriveCaption?: Maybe<string> /**  */
	deviceId?: Maybe<string> /**  */
	driveType: DriveType /**  */
	fileSystem?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	mediaType: number /**  */
	freeSpace: number /**  */
	size: number /**  */
	volumeName?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
}


/**  */
export type InternetExplorerInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	proxyServer?: Maybe<string> /**  */
	autoConfigUrl?: Maybe<string> /**  */
	autoDetect: boolean /**  */
	proxyEnable: boolean /**  */
	path?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	regeditPath?: Maybe<string> /**  */
}


/**  */
export type EncryptableVolumeInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	conversionStatus: ConversionStatus /**  */
	deviceId?: Maybe<string> /**  */
	driveLetter?: Maybe<string> /**  */
	encryptionMethod: EncryptionMethod /**  */
	isVolumeInitializedForProtection: boolean /**  */
	persistentVolumeId?: Maybe<string> /**  */
	protectionStatus: ProtectionStatus /**  */
	volumeType: number /**  */
}


/**  */
export type SnmpSummaryInput = {
	manualData: boolean /**  */
	key?: Maybe<string> /**  */
	history: boolean /**  */
	assetId: string /**  */
	deleted: boolean /**  */
	displayKey?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	sysDescription?: Maybe<string> /**  */
	sysOid?: Maybe<string> /**  */
	sysUpTime?: Maybe<string> /**  */
	sysContact?: Maybe<string> /**  */
	sysName?: Maybe<string> /**  */
	sysLocation?: Maybe<string> /**  */
	sysService?: Maybe<string> /**  */
	snmpType: SnmpType /**  */
}


/**  */
export type AssetDeliveryInput = {
	deliveryId?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	orderDate?: Maybe<Date> /**  */
	deliveryOrder?: Maybe<string> /**  */
	receivedById?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
}


/**  */
export type ClarilogLocalAgentInput = {
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	firstCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	forceAudit: boolean /**  */
	mustBeAudited: boolean /**  */
	clientApplicationtName?: Maybe<string> /**  */
	updater?: Maybe<UpdaterInput> /**  */
	clarilogAgentSettings?: Maybe<ClarilogAgentSettingsInput> /**  */
	campaignIds: Array<string> /**  */
	packageToDeploy?: Array<Maybe<PackageInput>> /**  */
}


/**  */
export type UpdaterInput = {
	lastDownload?: Maybe<Date> /**  */
	lastUpdate?: Maybe<Date> /**  */
	lastInstall?: Maybe<Date> /**  */
	updateState: UpdaterState /**  */
	lastError?: Maybe<string> /**  */
	countAttempt: number /**  */
	version?: Maybe<string> /**  */
}


/**  */
export type ClarilogAgentSettingsInput = {
	lastVersion?: Maybe<string> /**  */
	pendingDelay: number /**  */
	pingDelay: number /**  */
	stopService: boolean /**  */
	restartService: boolean /**  */
	disableService: boolean /**  */
	forceToken?: Maybe<string> /**  */
	canBeUpdate: boolean /**  */
	blockIfNotLastVersion: boolean /**  */
	disabledApiQuery: boolean /**  */
	disabledApiMutation: boolean /**  */
	apiServer?: Maybe<string> /**  */
}


/**  */
export type PackageInput = {
	name?: Maybe<string> /**  */
	packageName?: Maybe<string> /**  */
	packageVersion?: Maybe<string> /**  */
	packagePathSource?: Maybe<string> /**  */
	campaignIds: Array<string> /**  */
	campaignHistoryId?: Maybe<string> /**  */
}


/**  */
export type InheritLocationCharterInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	organizationalUnit: InheritLocationCharterCategory /**  */
	location: InheritLocationCharterCategory /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	optionLogonSessions?: Array<Maybe<ScanImportOptionLogonSessionInput>> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
}


/**  */
export type ScanImportOptionLogonSessionInput = {
	value?: Maybe<string> /**  */
	scanExcludeOperator: ScanExcludeOperator /**  */
}


/** Représente les méthodes disponible pour le type Asset */
export type FieldUpdateOperatorOfAsset = {
	set?: Maybe<SetOperatorInputOfAsset> /**  */
	inc?: Maybe<IncOperatorInputAsset> /**  */
	push?: Maybe<PushUpdateOperatorInputAsset> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAsset> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Asset */
export type SetOperatorInputOfAsset = {
	isAudited?: Maybe<boolean> /**  */
	assetCategoryId?: Maybe<string> /**  */
	assetStatusId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	fqdn?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	power?: Maybe<number> /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	serialNumber?: Maybe<string> /**  */
	skuNumber?: Maybe<string> /**  */
	uuid?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	macAddresses: Array<string> /**  */
	inventoryNumber?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	netBios?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	firstAudit?: Maybe<Date> /**  */
	lastAudit?: Maybe<Date> /**  */
	lastSuccessAudit?: Maybe<Date> /**  */
	auditState?: Maybe<ActionHistoryState> /**  */
	domainId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	phoneAsset?: Maybe<string> /**  */
	ownerId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	remoteControlId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	lendable?: Maybe<boolean> /**  */
	featureComment?: Maybe<string> /**  */
	firstMobileDevices: Array<FeatureMobileInput> /**  */
	secondMobileDevices: Array<FeatureMobileInput> /**  */
	createdModelId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	outStockIds: Array<string> /**  */
	stockTransfersIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	loanId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	lastCommandId?: Maybe<string> /**  */
	operatingSystem?: Maybe<string> /**  */
	mainOperatingSystemId?: Maybe<string> /**  */
	memories?: Maybe<number> /**  */
	processor?: Maybe<string> /**  */
	warrantyStart?: Maybe<Date> /**  */
	warrantyEnd?: Maybe<Date> /**  */
	loanable?: Maybe<boolean> /**  */
	groupIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	commandIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	lastCalcRenewDate?: Maybe<Date> /**  */
	campaignIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	ticketIds: Array<string> /**  */
	mainImpactedTicketIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	assetAffectedRule?: Maybe<SetOperatorInputOfAssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<SetOperatorInputOfAssetCategoryRule> /**  */
	inventoryHistory?: Maybe<SetOperatorInputOfInventoryHistory> /**  */
	lifeCycle?: Maybe<SetOperatorInputOfLifeCycle> /**  */
	featureMobility?: Maybe<SetOperatorInputOfFeatureMobility> /**  */
	featureSimCard?: Maybe<SetOperatorInputOfFeatureSimCard> /**  */
	featureMonitor?: Maybe<SetOperatorInputOfFeatureMonitor> /**  */
	featureUps?: Maybe<SetOperatorInputOfFeatureUps> /**  */
	featureScanner?: Maybe<SetOperatorInputOfFeatureScanner> /**  */
	featureWebcam?: Maybe<SetOperatorInputOfFeatureWebcam> /**  */
	featureModem?: Maybe<SetOperatorInputOfFeatureModem> /**  */
	featureTerminal?: Maybe<SetOperatorInputOfFeatureTerminal> /**  */
	featureSwitch?: Maybe<SetOperatorInputOfFeatureSwitch> /**  */
	featureNetwork?: Maybe<SetOperatorInputOfFeatureNetwork> /**  */
	featurePrinter?: Maybe<SetOperatorInputOfFeaturePrinter> /**  */
	featureVideoProjector?: Maybe<SetOperatorInputOfFeatureVideoProjector> /**  */
	featureMobile?: Maybe<SetOperatorInputOfFeatureMobile> /**  */
	featureTelephony?: Maybe<SetOperatorInputOfFeatureTelephony> /**  */
	detectWarranty?: Maybe<SetOperatorInputOfDetectWarranty> /**  */
	financial?: Maybe<SetOperatorInputOfAssetFinancial> /**  */
	assetNamingConvention?: Maybe<SetOperatorInputOfAssetNamingConvention> /**  */
	entityLocationCharter?: Maybe<SetOperatorInputOfEntityLocationCharter> /**  */
	auditResult?: Maybe<SetOperatorInputOfAuditResult> /**  */
	deliveryAsset?: Maybe<SetOperatorInputOfAssetDelivery> /**  */
	clarilogLocalAgent?: Maybe<SetOperatorInputOfClarilogLocalAgent> /**  */
	inheritLocationCharterLocation?: Maybe<SetOperatorInputOfInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<SetOperatorInputOfInheritLocationCharter> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAffectedRule */
export type SetOperatorInputOfAssetAffectedRule = {
	byNetbios?: Maybe<boolean> /**  */
	byMacAddress?: Maybe<boolean> /**  */
	byIpAddress?: Maybe<boolean> /**  */
	bySerialNumber?: Maybe<boolean> /**  */
	byUuid?: Maybe<boolean> /**  */
	byDistinguishedName?: Maybe<boolean> /**  */
	byFqdn?: Maybe<boolean> /**  */
	byName?: Maybe<boolean> /**  */
	auditTypes: Array<AuditType> /**  */
	notTrackingFields: Array<string> /**  */
	enabled?: Maybe<boolean> /**  */
	searchInDeleted?: Maybe<boolean> /**  */
	searchInArchived?: Maybe<boolean> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetCategoryRule */
export type SetOperatorInputOfAssetCategoryRule = {
	assetCategoryId?: Maybe<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	enabled?: Maybe<boolean> /**  */
	expression?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité InventoryHistory */
export type SetOperatorInputOfInventoryHistory = {
	clientApplicationtName?: Maybe<string> /**  */
	clientApplicationtVersion?: Maybe<string> /**  */
	auditTimes: Array<AuditTimeInput> /**  */
	networkCredentialId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	auditType?: Maybe<AuditType> /**  */
	new?: Maybe<boolean> /**  */
	macAddress?: Maybe<string> /**  */
	resolveName?: Maybe<string> /**  */
	scanImportOptionId?: Maybe<string> /**  */
	attempts?: Maybe<number> /**  */
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState?: Maybe<ActionHistoryState> /**  */
	host?: Maybe<string> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	hidden?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	clarilogException?: Maybe<SetOperatorInputOfClarilogException> /**  */
	assetAffectedRule?: Maybe<SetOperatorInputOfAssetAffectedRule> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ClarilogException */
export type SetOperatorInputOfClarilogException = {
	message?: Maybe<string> /**  */
	stackTrace?: Maybe<string> /**  */
	source?: Maybe<string> /**  */
	helpLink?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LifeCycle */
export type SetOperatorInputOfLifeCycle = {
	startLife?: Maybe<SetOperatorInputOfStartLife> /**  */
	renewal?: Maybe<SetOperatorInputOfRenewal> /**  */
	endLife?: Maybe<SetOperatorInputOfEndLife> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StartLife */
export type SetOperatorInputOfStartLife = {
	description?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Renewal */
export type SetOperatorInputOfRenewal = {
	description?: Maybe<string> /**  */
	estimatedDate?: Maybe<Date> /**  */
	age?: Maybe<number> /**  */
	usingDuration?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EndLife */
export type SetOperatorInputOfEndLife = {
	description?: Maybe<string> /**  */
	outDate?: Maybe<Date> /**  */
	reasonId?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
	destinationId?: Maybe<string> /**  */
	destination?: Maybe<string> /**  */
	recipient?: Maybe<string> /**  */
	removalDate?: Maybe<Date> /**  */
	removalId?: Maybe<string> /**  */
	bunddleNumber?: Maybe<string> /**  */
	disposalPrice?: Maybe<number> /**  */
	recycleCost?: Maybe<number> /**  */
	contributorId?: Maybe<string> /**  */
	restockingPrograms?: Maybe<boolean> /**  */
	restockingLicenses?: Maybe<boolean> /**  */
	dissociateAssets?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureMobility */
export type SetOperatorInputOfFeatureMobility = {
	firstSimSlot?: Maybe<SetOperatorInputOfSimSlot> /**  */
	secondSimSlot?: Maybe<SetOperatorInputOfSimSlot> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SimSlot */
export type SetOperatorInputOfSimSlot = {
	imei?: Maybe<string> /**  */
	phoneNumber?: Maybe<string> /**  */
	primaryPinCode?: Maybe<string> /**  */
	secondaryPinCode?: Maybe<string> /**  */
	primaryPukCode?: Maybe<string> /**  */
	secondaryPukCode?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureSimCard */
export type SetOperatorInputOfFeatureSimCard = {
	phone?: Maybe<string> /**  */
	pin?: Maybe<string> /**  */
	pin2?: Maybe<string> /**  */
	puk?: Maybe<string> /**  */
	puk2?: Maybe<string> /**  */
	simMemoryId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	simCardMemory?: Maybe<SetOperatorInputOfSimCardMemory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SimCardMemory */
export type SetOperatorInputOfSimCardMemory = {
	name?: Maybe<string> /**  */
	capacity?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureMonitor */
export type SetOperatorInputOfFeatureMonitor = {
	size?: Maybe<string> /**  */
	resolutions: Array<ResolutionInput> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	resolutionMax?: Maybe<string> /**  */
	monitorTechnology?: Maybe<SetOperatorInputOfMonitorTechnology> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MonitorTechnology */
export type SetOperatorInputOfMonitorTechnology = {
	name?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureUps */
export type SetOperatorInputOfFeatureUps = {
	autonomyId?: Maybe<string> /**  */
	powerId?: Maybe<string> /**  */
	upsTypeId?: Maybe<string> /**  */
	chargerTime?: Maybe<number> /**  */
	outlet?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureScanner */
export type SetOperatorInputOfFeatureScanner = {
	charger?: Maybe<boolean> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	paperFormats: Array<PaperFormatInput> /**  */
	scanSpeedId?: Maybe<string> /**  */
	dpiResolutionId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureWebcam */
export type SetOperatorInputOfFeatureWebcam = {
	color?: Maybe<boolean> /**  */
	microphone?: Maybe<boolean> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureModem */
export type SetOperatorInputOfFeatureModem = {
	wifi?: Maybe<boolean> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	speedConnectionId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureTerminal */
export type SetOperatorInputOfFeatureTerminal = {
	barcodeReader?: Maybe<boolean> /**  */
	rfidReader?: Maybe<boolean> /**  */
	wifi?: Maybe<boolean> /**  */
	bluetooth?: Maybe<boolean> /**  */
	infrared?: Maybe<boolean> /**  */
	miniUsb?: Maybe<boolean> /**  */
	gPs?: Maybe<boolean> /**  */
	camera?: Maybe<boolean> /**  */
	memoryExtension?: Maybe<string> /**  */
	resolutionId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureSwitch */
export type SetOperatorInputOfFeatureSwitch = {
	desktop?: Maybe<boolean> /**  */
	stackable?: Maybe<boolean> /**  */
	rackable?: Maybe<boolean> /**  */
	poe?: Maybe<boolean> /**  */
	modem?: Maybe<boolean> /**  */
	firewall?: Maybe<boolean> /**  */
	consolePort?: Maybe<boolean> /**  */
	proxy?: Maybe<boolean> /**  */
	vpn?: Maybe<boolean> /**  */
	utm?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureNetwork */
export type SetOperatorInputOfFeatureNetwork = {
	wifi?: Maybe<boolean> /**  */
	sSid?: Maybe<string> /**  */
	securityKey?: Maybe<string> /**  */
	webAdministration?: Maybe<boolean> /**  */
	snmp?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeaturePrinter */
export type SetOperatorInputOfFeaturePrinter = {
	color?: Maybe<boolean> /**  */
	share?: Maybe<boolean> /**  */
	shareName?: Maybe<string> /**  */
	speedBlack?: Maybe<number> /**  */
	speedColor?: Maybe<number> /**  */
	rectoVerso?: Maybe<boolean> /**  */
	scanner?: Maybe<boolean> /**  */
	fax?: Maybe<boolean> /**  */
	dpiResolutionId?: Maybe<string> /**  */
	paperFormats: Array<PaperFormatInput> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	printerTechnologyId?: Maybe<string> /**  */
	orientations: Array<OrientationInput> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureVideoProjector */
export type SetOperatorInputOfFeatureVideoProjector = {
	speaker?: Maybe<boolean> /**  */
	remoteControl?: Maybe<boolean> /**  */
	resolutions: Array<ResolutionInput> /**  */
	monitorTechnologyId?: Maybe<string> /**  */
	powerLampId?: Maybe<string> /**  */
	brightnessId?: Maybe<string> /**  */
	videoProjectorTypeId?: Maybe<string> /**  */
	noiseLevelId?: Maybe<string> /**  */
	videoProjectorFormatId?: Maybe<string> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureMobile */
export type SetOperatorInputOfFeatureMobile = {
	imei?: Maybe<string> /**  */
	firstSimId?: Maybe<string> /**  */
	secondSimId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeatureTelephony */
export type SetOperatorInputOfFeatureTelephony = {
	phoneNumber?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DetectWarranty */
export type SetOperatorInputOfDetectWarranty = {
	lastAttempt?: Maybe<Date> /**  */
	lastSuccess?: Maybe<Date> /**  */
	message?: Maybe<string> /**  */
	success?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetFinancial */
export type SetOperatorInputOfAssetFinancial = {
	budgetId?: Maybe<string> /**  */
	acquisition?: Maybe<SetOperatorInputOfAssetAcquisition> /**  */
	internalInvoice?: Maybe<SetOperatorInputOfAssetInternalInvoice> /**  */
	accounting?: Maybe<SetOperatorInputOfAssetAccounting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAcquisition */
export type SetOperatorInputOfAssetAcquisition = {
	modeId?: Maybe<string> /**  */
	stateId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	batchDate?: Maybe<Date> /**  */
	batchNumber?: Maybe<string> /**  */
	invoiceDate?: Maybe<Date> /**  */
	invoiceNumber?: Maybe<string> /**  */
	price?: Maybe<number> /**  */
	supplierId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetInternalInvoice */
export type SetOperatorInputOfAssetInternalInvoice = {
	date?: Maybe<Date> /**  */
	number?: Maybe<string> /**  */
	amount?: Maybe<number> /**  */
	organizationalUnitId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAccounting */
export type SetOperatorInputOfAssetAccounting = {
	typeId?: Maybe<string> /**  */
	startDepreciation?: Maybe<Date> /**  */
	endDepreciation?: Maybe<Date> /**  */
	duration?: Maybe<number> /**  */
	numberImmobilization?: Maybe<string> /**  */
	recouping?: Maybe<string> /**  */
	analyticalImputation?: Maybe<string> /**  */
	costCenterId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetNamingConvention */
export type SetOperatorInputOfAssetNamingConvention = {
	alreadyApply?: Maybe<boolean> /**  */
	onBarCode?: Maybe<SetOperatorInputOfNamingConventionSummary> /**  */
	onInventoryNumber?: Maybe<SetOperatorInputOfNamingConventionSummary> /**  */
	onName?: Maybe<SetOperatorInputOfNamingConventionSummary> /**  */
	onNetBios?: Maybe<SetOperatorInputOfNamingConventionSummary> /**  */
	onAssetNumber?: Maybe<SetOperatorInputOfNamingConventionSummary> /**  */
}


/** Représente les propriétés de mise à jour de l'entité NamingConventionSummary */
export type SetOperatorInputOfNamingConventionSummary = {
	value?: Maybe<string> /**  */
	namingConventionMask?: Maybe<string> /**  */
	manualEdit?: Maybe<boolean> /**  */
	lock?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EntityLocationCharter */
export type SetOperatorInputOfEntityLocationCharter = {
	onLocationId?: Maybe<SetOperatorInputOfLocationCharterSummary> /**  */
	onOrganizationalUnitId?: Maybe<SetOperatorInputOfLocationCharterSummary> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LocationCharterSummary */
export type SetOperatorInputOfLocationCharterSummary = {
	value?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AuditResult */
export type SetOperatorInputOfAuditResult = {
	antiSpywareProducts: Array<AntiSpywareProductInput> /**  */
	antiVirusProducts: Array<AntiVirusProductInput> /**  */
	autoRuns: Array<AutoRunInput> /**  */
	baseBoards: Array<BaseBoardInput> /**  */
	bios: Array<BiosInput> /**  */
	cdRomDrives: Array<CDRomDriveInput> /**  */
	computerSystems: Array<ComputerSystemInput> /**  */
	computerSystemProducts: Array<ComputerSystemProductInput> /**  */
	dataFiles: Array<DataFileInput> /**  */
	diskDrives: Array<DiskDriveInput> /**  */
	logicalDisks: Array<LogicalDiskInput> /**  */
	environmentVariables: Array<EnvironmentVariableInput> /**  */
	firewallProducts: Array<FirewallProductInput> /**  */
	fontInfoActions: Array<FontInfoActionInput> /**  */
	groups: Array<GroupInput> /**  */
	keyboards: Array<KeyboardInput> /**  */
	monitors: Array<MonitorInput> /**  */
	virtualMachines: Array<VirtualMachineInput> /**  */
	networkAdapters: Array<NetworkAdapterInput> /**  */
	networkDrives: Array<NetworkDriveInput> /**  */
	ntLogEvents: Array<NtLogEventInput> /**  */
	operatingSystems: Array<OperatingSystemInput> /**  */
	parallelPorts: Array<ParallelPortInput> /**  */
	physicalMemoryArrays: Array<PhysicalMemoryArrayInput> /**  */
	physicalMemories: Array<PhysicalMemoryInput> /**  */
	pnpEntities: Array<PnpEntityInput> /**  */
	pointingDevices: Array<PointingDeviceInput> /**  */
	portConnectors: Array<PortConnectorInput> /**  */
	potsModems: Array<PotsModemInput> /**  */
	printers: Array<PrinterInput> /**  */
	processes: Array<ProcessInput> /**  */
	processors: Array<ProcessorInput> /**  */
	programs: Array<ProgramInput> /**  */
	quickFixEngineerings: Array<QuickFixEngineeringInput> /**  */
	workApplications: Array<WorkApplicationInput> /**  */
	serialPorts: Array<SerialPortInput> /**  */
	windowsServices: Array<WindowsServiceInput> /**  */
	shares: Array<ShareInput> /**  */
	sqlServers: Array<SqlServerInput> /**  */
	soundDevices: Array<SoundDeviceInput> /**  */
	systemEnclosures: Array<SystemEnclosureInput> /**  */
	systemSlots: Array<SystemSlotInput> /**  */
	tapeDrives: Array<TapeDriveInput> /**  */
	userAccountControls: Array<UserAccountControlInput> /**  */
	userAccounts: Array<UserAccountInput> /**  */
	userProfiles: Array<UserProfileInput> /**  */
	videoControllers: Array<VideoControllerInput> /**  */
	windowsFirewalls: Array<WindowsFirewallInput> /**  */
	windowsUpdates: Array<WindowsUpdateInput> /**  */
	scanRegistries: Array<ScanRegistryInput> /**  */
	printerConsumables: Array<PrinterConsumableInput> /**  */
	pageCounts: Array<PageCountInput> /**  */
	copyCounts: Array<CopyCountInput> /**  */
	scannerCounts: Array<ScannerCountInput> /**  */
	faxCounts: Array<FaxCountInput> /**  */
	entryTables: Array<EntryTableInput> /**  */
	vlans: Array<VlanInput> /**  */
	accessPoints: Array<AccessPointInput> /**  */
	portOpens: Array<PortOpenInput> /**  */
	odbcDrivers: Array<OdbcDriverInput> /**  */
	systemAccounts: Array<SystemAccountInput> /**  */
	logonSessions: Array<LogonSessionInput> /**  */
	pnpSignedDrivers: Array<PnpSignedDriverInput> /**  */
	serialNumbers: Array<SerialNumberInput> /**  */
	uptimeCalendars: Array<UptimeCalendarInput> /**  */
	bootConfigurations: Array<BootConfigurationInput> /**  */
	pageFiles: Array<PageFileInput> /**  */
	codecFiles: Array<CodecFileInput> /**  */
	osRecoveryConfigurations: Array<OsRecoveryConfigurationInput> /**  */
	scsiControllers: Array<ScsiControllerInput> /**  */
	pcmciaControllers: Array<PcmciaControllerInput> /**  */
	infraredDevices: Array<InfraredDeviceInput> /**  */
	batteries: Array<BatteryInput> /**  */
	portableBatteries: Array<PortableBatteryInput> /**  */
	logonHistories: Array<LogonHistoryInput> /**  */
	desktops: Array<DesktopInput> /**  */
	optionalFeatures: Array<OptionalFeatureInput> /**  */
	iis: Array<IisInput> /**  */
	ldapProperties: Array<LdapPropertyInput> /**  */
	logicalDiskHistories: Array<LogicalDiskHistoryInput> /**  */
	internetExplorers: Array<InternetExplorerInput> /**  */
	encryptableVolumes: Array<EncryptableVolumeInput> /**  */
	snmpSummary: Array<SnmpSummaryInput> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetDelivery */
export type SetOperatorInputOfAssetDelivery = {
	deliveryId?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	orderDate?: Maybe<Date> /**  */
	deliveryOrder?: Maybe<string> /**  */
	receivedById?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ClarilogLocalAgent */
export type SetOperatorInputOfClarilogLocalAgent = {
	enabled?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	firstCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	forceAudit?: Maybe<boolean> /**  */
	mustBeAudited?: Maybe<boolean> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	campaignIds: Array<string> /**  */
	packageToDeploy: Array<PackageInput> /**  */
	updater?: Maybe<SetOperatorInputOfUpdater> /**  */
	clarilogAgentSettings?: Maybe<SetOperatorInputOfClarilogAgentSettings> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Updater */
export type SetOperatorInputOfUpdater = {
	lastDownload?: Maybe<Date> /**  */
	lastUpdate?: Maybe<Date> /**  */
	lastInstall?: Maybe<Date> /**  */
	updateState?: Maybe<UpdaterState> /**  */
	lastError?: Maybe<string> /**  */
	countAttempt?: Maybe<number> /**  */
	version?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ClarilogAgentSettings */
export type SetOperatorInputOfClarilogAgentSettings = {
	lastVersion?: Maybe<string> /**  */
	pendingDelay?: Maybe<number> /**  */
	pingDelay?: Maybe<number> /**  */
	stopService?: Maybe<boolean> /**  */
	restartService?: Maybe<boolean> /**  */
	disableService?: Maybe<boolean> /**  */
	forceToken?: Maybe<string> /**  */
	canBeUpdate?: Maybe<boolean> /**  */
	blockIfNotLastVersion?: Maybe<boolean> /**  */
	disabledApiQuery?: Maybe<boolean> /**  */
	disabledApiMutation?: Maybe<boolean> /**  */
	apiServer?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité InheritLocationCharter */
export type SetOperatorInputOfInheritLocationCharter = {
	description?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	organizationalUnit?: Maybe<InheritLocationCharterCategory> /**  */
	location?: Maybe<InheritLocationCharterCategory> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	optionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Asset */
export type IncOperatorInputAsset = {
	power?: Maybe<number> /**  */
	memories?: Maybe<number> /**  */
	assetAffectedRule?: Maybe<IncOperatorInputAssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<IncOperatorInputAssetCategoryRule> /**  */
	inventoryHistory?: Maybe<IncOperatorInputInventoryHistory> /**  */
	lifeCycle?: Maybe<IncOperatorInputLifeCycle> /**  */
	featureUps?: Maybe<IncOperatorInputFeatureUps> /**  */
	featurePrinter?: Maybe<IncOperatorInputFeaturePrinter> /**  */
	financial?: Maybe<IncOperatorInputAssetFinancial> /**  */
	clarilogLocalAgent?: Maybe<IncOperatorInputClarilogLocalAgent> /**  */
	inheritLocationCharterLocation?: Maybe<IncOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<IncOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetAffectedRule */
export type IncOperatorInputAssetAffectedRule = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetCategoryRule */
export type IncOperatorInputAssetCategoryRule = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité InventoryHistory */
export type IncOperatorInputInventoryHistory = {
	attempts?: Maybe<number> /**  */
	progressValue?: Maybe<number> /**  */
	assetAffectedRule?: Maybe<IncOperatorInputAssetAffectedRule> /**  */
}


/** Représente les propriétés de type numérique de l'entité LifeCycle */
export type IncOperatorInputLifeCycle = {
	renewal?: Maybe<IncOperatorInputRenewal> /**  */
	endLife?: Maybe<IncOperatorInputEndLife> /**  */
}


/** Représente les propriétés de type numérique de l'entité Renewal */
export type IncOperatorInputRenewal = {
	age?: Maybe<number> /**  */
	usingDuration?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité EndLife */
export type IncOperatorInputEndLife = {
	disposalPrice?: Maybe<number> /**  */
	recycleCost?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité FeatureUps */
export type IncOperatorInputFeatureUps = {
	chargerTime?: Maybe<number> /**  */
	outlet?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité FeaturePrinter */
export type IncOperatorInputFeaturePrinter = {
	speedBlack?: Maybe<number> /**  */
	speedColor?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetFinancial */
export type IncOperatorInputAssetFinancial = {
	acquisition?: Maybe<IncOperatorInputAssetAcquisition> /**  */
	internalInvoice?: Maybe<IncOperatorInputAssetInternalInvoice> /**  */
	accounting?: Maybe<IncOperatorInputAssetAccounting> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetAcquisition */
export type IncOperatorInputAssetAcquisition = {
	price?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetInternalInvoice */
export type IncOperatorInputAssetInternalInvoice = {
	amount?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetAccounting */
export type IncOperatorInputAssetAccounting = {
	duration?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité ClarilogLocalAgent */
export type IncOperatorInputClarilogLocalAgent = {
	updater?: Maybe<IncOperatorInputUpdater> /**  */
	clarilogAgentSettings?: Maybe<IncOperatorInputClarilogAgentSettings> /**  */
}


/** Représente les propriétés de type numérique de l'entité Updater */
export type IncOperatorInputUpdater = {
	countAttempt?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité ClarilogAgentSettings */
export type IncOperatorInputClarilogAgentSettings = {
	pendingDelay?: Maybe<number> /**  */
	pingDelay?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité InheritLocationCharter */
export type IncOperatorInputInheritLocationCharter = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Asset */
export type PushUpdateOperatorInputAsset = {
	macAddresses: Array<string> /**  */
	firstMobileDevices: Array<FeatureMobileInput> /**  */
	secondMobileDevices: Array<FeatureMobileInput> /**  */
	userIds: Array<string> /**  */
	outStockIds: Array<string> /**  */
	stockTransfersIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	commandIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	campaignIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	ticketIds: Array<string> /**  */
	mainImpactedTicketIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	assetAffectedRule?: Maybe<PushUpdateOperatorInputAssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<PushUpdateOperatorInputAssetCategoryRule> /**  */
	inventoryHistory?: Maybe<PushUpdateOperatorInputInventoryHistory> /**  */
	featureMonitor?: Maybe<PushUpdateOperatorInputFeatureMonitor> /**  */
	featureScanner?: Maybe<PushUpdateOperatorInputFeatureScanner> /**  */
	featureWebcam?: Maybe<PushUpdateOperatorInputFeatureWebcam> /**  */
	featureModem?: Maybe<PushUpdateOperatorInputFeatureModem> /**  */
	featurePrinter?: Maybe<PushUpdateOperatorInputFeaturePrinter> /**  */
	featureVideoProjector?: Maybe<PushUpdateOperatorInputFeatureVideoProjector> /**  */
	auditResult?: Maybe<PushUpdateOperatorInputAuditResult> /**  */
	clarilogLocalAgent?: Maybe<PushUpdateOperatorInputClarilogLocalAgent> /**  */
	inheritLocationCharterLocation?: Maybe<PushUpdateOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<PushUpdateOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAffectedRule */
export type PushUpdateOperatorInputAssetAffectedRule = {
	auditTypes: Array<AuditType> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCategoryRule */
export type PushUpdateOperatorInputAssetCategoryRule = {
	expression?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité InventoryHistory */
export type PushUpdateOperatorInputInventoryHistory = {
	auditTimes: Array<AuditTimeInput> /**  */
	assetAffectedRule?: Maybe<PushUpdateOperatorInputAssetAffectedRule> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureMonitor */
export type PushUpdateOperatorInputFeatureMonitor = {
	resolutions: Array<ResolutionInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureScanner */
export type PushUpdateOperatorInputFeatureScanner = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	paperFormats: Array<PaperFormatInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureWebcam */
export type PushUpdateOperatorInputFeatureWebcam = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureModem */
export type PushUpdateOperatorInputFeatureModem = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeaturePrinter */
export type PushUpdateOperatorInputFeaturePrinter = {
	paperFormats: Array<PaperFormatInput> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	orientations: Array<OrientationInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureVideoProjector */
export type PushUpdateOperatorInputFeatureVideoProjector = {
	resolutions: Array<ResolutionInput> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité AuditResult */
export type PushUpdateOperatorInputAuditResult = {
	antiSpywareProducts: Array<AntiSpywareProductInput> /**  */
	antiVirusProducts: Array<AntiVirusProductInput> /**  */
	autoRuns: Array<AutoRunInput> /**  */
	baseBoards: Array<BaseBoardInput> /**  */
	bios: Array<BiosInput> /**  */
	cdRomDrives: Array<CDRomDriveInput> /**  */
	computerSystems: Array<ComputerSystemInput> /**  */
	computerSystemProducts: Array<ComputerSystemProductInput> /**  */
	dataFiles: Array<DataFileInput> /**  */
	diskDrives: Array<DiskDriveInput> /**  */
	logicalDisks: Array<LogicalDiskInput> /**  */
	environmentVariables: Array<EnvironmentVariableInput> /**  */
	firewallProducts: Array<FirewallProductInput> /**  */
	fontInfoActions: Array<FontInfoActionInput> /**  */
	groups: Array<GroupInput> /**  */
	keyboards: Array<KeyboardInput> /**  */
	monitors: Array<MonitorInput> /**  */
	virtualMachines: Array<VirtualMachineInput> /**  */
	networkAdapters: Array<NetworkAdapterInput> /**  */
	networkDrives: Array<NetworkDriveInput> /**  */
	ntLogEvents: Array<NtLogEventInput> /**  */
	operatingSystems: Array<OperatingSystemInput> /**  */
	parallelPorts: Array<ParallelPortInput> /**  */
	physicalMemoryArrays: Array<PhysicalMemoryArrayInput> /**  */
	physicalMemories: Array<PhysicalMemoryInput> /**  */
	pnpEntities: Array<PnpEntityInput> /**  */
	pointingDevices: Array<PointingDeviceInput> /**  */
	portConnectors: Array<PortConnectorInput> /**  */
	potsModems: Array<PotsModemInput> /**  */
	printers: Array<PrinterInput> /**  */
	processes: Array<ProcessInput> /**  */
	processors: Array<ProcessorInput> /**  */
	programs: Array<ProgramInput> /**  */
	quickFixEngineerings: Array<QuickFixEngineeringInput> /**  */
	workApplications: Array<WorkApplicationInput> /**  */
	serialPorts: Array<SerialPortInput> /**  */
	windowsServices: Array<WindowsServiceInput> /**  */
	shares: Array<ShareInput> /**  */
	sqlServers: Array<SqlServerInput> /**  */
	soundDevices: Array<SoundDeviceInput> /**  */
	systemEnclosures: Array<SystemEnclosureInput> /**  */
	systemSlots: Array<SystemSlotInput> /**  */
	tapeDrives: Array<TapeDriveInput> /**  */
	userAccountControls: Array<UserAccountControlInput> /**  */
	userAccounts: Array<UserAccountInput> /**  */
	userProfiles: Array<UserProfileInput> /**  */
	videoControllers: Array<VideoControllerInput> /**  */
	windowsFirewalls: Array<WindowsFirewallInput> /**  */
	windowsUpdates: Array<WindowsUpdateInput> /**  */
	scanRegistries: Array<ScanRegistryInput> /**  */
	printerConsumables: Array<PrinterConsumableInput> /**  */
	pageCounts: Array<PageCountInput> /**  */
	copyCounts: Array<CopyCountInput> /**  */
	scannerCounts: Array<ScannerCountInput> /**  */
	faxCounts: Array<FaxCountInput> /**  */
	entryTables: Array<EntryTableInput> /**  */
	vlans: Array<VlanInput> /**  */
	accessPoints: Array<AccessPointInput> /**  */
	portOpens: Array<PortOpenInput> /**  */
	odbcDrivers: Array<OdbcDriverInput> /**  */
	systemAccounts: Array<SystemAccountInput> /**  */
	logonSessions: Array<LogonSessionInput> /**  */
	pnpSignedDrivers: Array<PnpSignedDriverInput> /**  */
	serialNumbers: Array<SerialNumberInput> /**  */
	uptimeCalendars: Array<UptimeCalendarInput> /**  */
	bootConfigurations: Array<BootConfigurationInput> /**  */
	pageFiles: Array<PageFileInput> /**  */
	codecFiles: Array<CodecFileInput> /**  */
	osRecoveryConfigurations: Array<OsRecoveryConfigurationInput> /**  */
	scsiControllers: Array<ScsiControllerInput> /**  */
	pcmciaControllers: Array<PcmciaControllerInput> /**  */
	infraredDevices: Array<InfraredDeviceInput> /**  */
	batteries: Array<BatteryInput> /**  */
	portableBatteries: Array<PortableBatteryInput> /**  */
	logonHistories: Array<LogonHistoryInput> /**  */
	desktops: Array<DesktopInput> /**  */
	optionalFeatures: Array<OptionalFeatureInput> /**  */
	iis: Array<IisInput> /**  */
	ldapProperties: Array<LdapPropertyInput> /**  */
	logicalDiskHistories: Array<LogicalDiskHistoryInput> /**  */
	internetExplorers: Array<InternetExplorerInput> /**  */
	encryptableVolumes: Array<EncryptableVolumeInput> /**  */
	snmpSummary: Array<SnmpSummaryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité ClarilogLocalAgent */
export type PushUpdateOperatorInputClarilogLocalAgent = {
	campaignIds: Array<string> /**  */
	packageToDeploy: Array<PackageInput> /**  */
}


/** Représente les propriétés de type collection de l'entité InheritLocationCharter */
export type PushUpdateOperatorInputInheritLocationCharter = {
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	optionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Asset */
export type PullUpdateOperatorInputAsset = {
	macAddresses: Array<string> /**  */
	firstMobileDevices: Array<FeatureMobileInput> /**  */
	secondMobileDevices: Array<FeatureMobileInput> /**  */
	userIds: Array<string> /**  */
	outStockIds: Array<string> /**  */
	stockTransfersIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	loanIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	commandIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	campaignIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	ticketIds: Array<string> /**  */
	mainImpactedTicketIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	assetAffectedRule?: Maybe<PullUpdateOperatorInputAssetAffectedRule> /**  */
	assetCategoryRule?: Maybe<PullUpdateOperatorInputAssetCategoryRule> /**  */
	inventoryHistory?: Maybe<PullUpdateOperatorInputInventoryHistory> /**  */
	featureMonitor?: Maybe<PullUpdateOperatorInputFeatureMonitor> /**  */
	featureScanner?: Maybe<PullUpdateOperatorInputFeatureScanner> /**  */
	featureWebcam?: Maybe<PullUpdateOperatorInputFeatureWebcam> /**  */
	featureModem?: Maybe<PullUpdateOperatorInputFeatureModem> /**  */
	featurePrinter?: Maybe<PullUpdateOperatorInputFeaturePrinter> /**  */
	featureVideoProjector?: Maybe<PullUpdateOperatorInputFeatureVideoProjector> /**  */
	auditResult?: Maybe<PullUpdateOperatorInputAuditResult> /**  */
	clarilogLocalAgent?: Maybe<PullUpdateOperatorInputClarilogLocalAgent> /**  */
	inheritLocationCharterLocation?: Maybe<PullUpdateOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<PullUpdateOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAffectedRule */
export type PullUpdateOperatorInputAssetAffectedRule = {
	auditTypes: Array<AuditType> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCategoryRule */
export type PullUpdateOperatorInputAssetCategoryRule = {
	expression?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité InventoryHistory */
export type PullUpdateOperatorInputInventoryHistory = {
	auditTimes: Array<AuditTimeInput> /**  */
	assetAffectedRule?: Maybe<PullUpdateOperatorInputAssetAffectedRule> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureMonitor */
export type PullUpdateOperatorInputFeatureMonitor = {
	resolutions: Array<ResolutionInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureScanner */
export type PullUpdateOperatorInputFeatureScanner = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	paperFormats: Array<PaperFormatInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureWebcam */
export type PullUpdateOperatorInputFeatureWebcam = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureModem */
export type PullUpdateOperatorInputFeatureModem = {
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeaturePrinter */
export type PullUpdateOperatorInputFeaturePrinter = {
	paperFormats: Array<PaperFormatInput> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
	orientations: Array<OrientationInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FeatureVideoProjector */
export type PullUpdateOperatorInputFeatureVideoProjector = {
	resolutions: Array<ResolutionInput> /**  */
	connectionCategories: Array<ConnectionCategoryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité AuditResult */
export type PullUpdateOperatorInputAuditResult = {
	antiSpywareProducts: Array<AntiSpywareProductInput> /**  */
	antiVirusProducts: Array<AntiVirusProductInput> /**  */
	autoRuns: Array<AutoRunInput> /**  */
	baseBoards: Array<BaseBoardInput> /**  */
	bios: Array<BiosInput> /**  */
	cdRomDrives: Array<CDRomDriveInput> /**  */
	computerSystems: Array<ComputerSystemInput> /**  */
	computerSystemProducts: Array<ComputerSystemProductInput> /**  */
	dataFiles: Array<DataFileInput> /**  */
	diskDrives: Array<DiskDriveInput> /**  */
	logicalDisks: Array<LogicalDiskInput> /**  */
	environmentVariables: Array<EnvironmentVariableInput> /**  */
	firewallProducts: Array<FirewallProductInput> /**  */
	fontInfoActions: Array<FontInfoActionInput> /**  */
	groups: Array<GroupInput> /**  */
	keyboards: Array<KeyboardInput> /**  */
	monitors: Array<MonitorInput> /**  */
	virtualMachines: Array<VirtualMachineInput> /**  */
	networkAdapters: Array<NetworkAdapterInput> /**  */
	networkDrives: Array<NetworkDriveInput> /**  */
	ntLogEvents: Array<NtLogEventInput> /**  */
	operatingSystems: Array<OperatingSystemInput> /**  */
	parallelPorts: Array<ParallelPortInput> /**  */
	physicalMemoryArrays: Array<PhysicalMemoryArrayInput> /**  */
	physicalMemories: Array<PhysicalMemoryInput> /**  */
	pnpEntities: Array<PnpEntityInput> /**  */
	pointingDevices: Array<PointingDeviceInput> /**  */
	portConnectors: Array<PortConnectorInput> /**  */
	potsModems: Array<PotsModemInput> /**  */
	printers: Array<PrinterInput> /**  */
	processes: Array<ProcessInput> /**  */
	processors: Array<ProcessorInput> /**  */
	programs: Array<ProgramInput> /**  */
	quickFixEngineerings: Array<QuickFixEngineeringInput> /**  */
	workApplications: Array<WorkApplicationInput> /**  */
	serialPorts: Array<SerialPortInput> /**  */
	windowsServices: Array<WindowsServiceInput> /**  */
	shares: Array<ShareInput> /**  */
	sqlServers: Array<SqlServerInput> /**  */
	soundDevices: Array<SoundDeviceInput> /**  */
	systemEnclosures: Array<SystemEnclosureInput> /**  */
	systemSlots: Array<SystemSlotInput> /**  */
	tapeDrives: Array<TapeDriveInput> /**  */
	userAccountControls: Array<UserAccountControlInput> /**  */
	userAccounts: Array<UserAccountInput> /**  */
	userProfiles: Array<UserProfileInput> /**  */
	videoControllers: Array<VideoControllerInput> /**  */
	windowsFirewalls: Array<WindowsFirewallInput> /**  */
	windowsUpdates: Array<WindowsUpdateInput> /**  */
	scanRegistries: Array<ScanRegistryInput> /**  */
	printerConsumables: Array<PrinterConsumableInput> /**  */
	pageCounts: Array<PageCountInput> /**  */
	copyCounts: Array<CopyCountInput> /**  */
	scannerCounts: Array<ScannerCountInput> /**  */
	faxCounts: Array<FaxCountInput> /**  */
	entryTables: Array<EntryTableInput> /**  */
	vlans: Array<VlanInput> /**  */
	accessPoints: Array<AccessPointInput> /**  */
	portOpens: Array<PortOpenInput> /**  */
	odbcDrivers: Array<OdbcDriverInput> /**  */
	systemAccounts: Array<SystemAccountInput> /**  */
	logonSessions: Array<LogonSessionInput> /**  */
	pnpSignedDrivers: Array<PnpSignedDriverInput> /**  */
	serialNumbers: Array<SerialNumberInput> /**  */
	uptimeCalendars: Array<UptimeCalendarInput> /**  */
	bootConfigurations: Array<BootConfigurationInput> /**  */
	pageFiles: Array<PageFileInput> /**  */
	codecFiles: Array<CodecFileInput> /**  */
	osRecoveryConfigurations: Array<OsRecoveryConfigurationInput> /**  */
	scsiControllers: Array<ScsiControllerInput> /**  */
	pcmciaControllers: Array<PcmciaControllerInput> /**  */
	infraredDevices: Array<InfraredDeviceInput> /**  */
	batteries: Array<BatteryInput> /**  */
	portableBatteries: Array<PortableBatteryInput> /**  */
	logonHistories: Array<LogonHistoryInput> /**  */
	desktops: Array<DesktopInput> /**  */
	optionalFeatures: Array<OptionalFeatureInput> /**  */
	iis: Array<IisInput> /**  */
	ldapProperties: Array<LdapPropertyInput> /**  */
	logicalDiskHistories: Array<LogicalDiskHistoryInput> /**  */
	internetExplorers: Array<InternetExplorerInput> /**  */
	encryptableVolumes: Array<EncryptableVolumeInput> /**  */
	snmpSummary: Array<SnmpSummaryInput> /**  */
}


/** Représente les propriétés de type collection de l'entité ClarilogLocalAgent */
export type PullUpdateOperatorInputClarilogLocalAgent = {
	campaignIds: Array<string> /**  */
	packageToDeploy: Array<PackageInput> /**  */
}


/** Représente les propriétés de type collection de l'entité InheritLocationCharter */
export type PullUpdateOperatorInputInheritLocationCharter = {
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	optionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
}


/**  */
export type FilterOfAssetStatus = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetStatus>> /**  */
	or?: Array<Maybe<FilterOfAssetStatus>> /**  */
	nor?: Array<Maybe<FilterOfAssetStatus>> /**  */
	not?: Array<Maybe<FilterOfAssetStatus>> /**  */
}


/**  */
export type QueryContextOfAssetStatus = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetStatus>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetTypeAccounting = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetTypeAccounting>> /**  */
	or?: Array<Maybe<FilterOfAssetTypeAccounting>> /**  */
	nor?: Array<Maybe<FilterOfAssetTypeAccounting>> /**  */
	not?: Array<Maybe<FilterOfAssetTypeAccounting>> /**  */
}


/**  */
export type QueryContextOfAssetTypeAccounting = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetTypeAccounting>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAssetTypeStaffing = {
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAssetTypeStaffing>> /**  */
	or?: Array<Maybe<FilterOfAssetTypeStaffing>> /**  */
	nor?: Array<Maybe<FilterOfAssetTypeStaffing>> /**  */
	not?: Array<Maybe<FilterOfAssetTypeStaffing>> /**  */
}


/**  */
export type QueryContextOfAssetTypeStaffing = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAssetTypeStaffing>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfAsyncTask = {
	progression?: Maybe<FilterDoubleOperator> /**  */
	methodName?: Maybe<FilterStringOperator> /**  */
	paramMap?: Maybe<FilterOfAsyncTaskParam> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAsyncTask>> /**  */
	or?: Array<Maybe<FilterOfAsyncTask>> /**  */
	nor?: Array<Maybe<FilterOfAsyncTask>> /**  */
	not?: Array<Maybe<FilterOfAsyncTask>> /**  */
}


/**  */
export type FilterOfAsyncTaskParam = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	default?: Maybe<FilterStringOperator> /**  */
	rawFile?: Maybe<FilterStringOperator> /**  */
	isAsync?: Maybe<FilterBooleanOperator> /**  */
	type?: Maybe<FilterImportFileFormatOperator> /**  */
	and?: Array<Maybe<FilterOfAsyncTaskParam>> /**  */
	or?: Array<Maybe<FilterOfAsyncTaskParam>> /**  */
	nor?: Array<Maybe<FilterOfAsyncTaskParam>> /**  */
	not?: Array<Maybe<FilterOfAsyncTaskParam>> /**  */
}


/**  */
export type FilterImportFileFormatOperator = {
	eq?: Maybe<ImportFileFormat> /**  */
	ne?: Maybe<ImportFileFormat> /**  */
	in?: Array<Maybe<ImportFileFormat>> /**  */
	nin?: Array<Maybe<ImportFileFormat>> /**  */
}


/**  */
export type QueryContextOfAsyncTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAsyncTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AsyncTask */
export type QuerySortTypeAsyncTask = {
	progression?: Maybe<Sort> /** Représente le type de trie. */
	methodName?: Maybe<Sort> /** Représente le type de trie. */
	paramMap?: Array<Maybe<QuerySortTypeAsyncTaskParam>> /**  */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AsyncTaskParam */
export type QuerySortTypeAsyncTaskParam = {
	default?: Maybe<Sort> /** Représente le type de trie. */
	rawFile?: Maybe<Sort> /** Représente le type de trie. */
	isAsync?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfAxelData = {
	model?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfAxelData>> /**  */
	or?: Array<Maybe<FilterOfAxelData>> /**  */
	nor?: Array<Maybe<FilterOfAxelData>> /**  */
	not?: Array<Maybe<FilterOfAxelData>> /**  */
}


/**  */
export type QueryContextOfAxelData = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeAxelData>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité AxelData */
export type QuerySortTypeAxelData = {
	model?: Maybe<Sort> /** Représente le type de trie. */
	macAddress?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfBudgetCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfBudgetCategory>> /**  */
	or?: Array<Maybe<FilterOfBudgetCategory>> /**  */
	nor?: Array<Maybe<FilterOfBudgetCategory>> /**  */
	not?: Array<Maybe<FilterOfBudgetCategory>> /**  */
}


/**  */
export type QueryContextOfBudgetCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeBudgetCategory>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type BudgetCategory */
export type FieldUpdateOperatorOfBudgetCategory = {
	set?: Maybe<SetOperatorInputOfBudgetCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputBudgetCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputBudgetCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité BudgetCategory */
export type SetOperatorInputOfBudgetCategory = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité BudgetCategory */
export type PushUpdateOperatorInputBudgetCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité BudgetCategory */
export type PullUpdateOperatorInputBudgetCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Budget */
export type FieldUpdateOperatorOfBudget = {
	set?: Maybe<SetOperatorInputOfBudget> /**  */
	inc?: Maybe<IncOperatorInputBudget> /**  */
	push?: Maybe<PushUpdateOperatorInputBudget> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputBudget> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Budget */
export type SetOperatorInputOfBudget = {
	hidden?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	code?: Maybe<string> /**  */
	opened?: Maybe<Date> /**  */
	closed?: Maybe<Date> /**  */
	amount?: Maybe<number> /**  */
	budgetCategoryId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	contractIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Budget */
export type IncOperatorInputBudget = {
	amount?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Budget */
export type PushUpdateOperatorInputBudget = {
	contractIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Budget */
export type PullUpdateOperatorInputBudget = {
	contractIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type CalendarConnector */
export type FieldUpdateOperatorOfCalendarConnector = {
	set?: Maybe<SetOperatorInputOfCalendarConnector> /**  */
	push?: Maybe<PushUpdateOperatorInputCalendarConnector> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCalendarConnector> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CalendarConnector */
export type SetOperatorInputOfCalendarConnector = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité CalendarConnector */
export type PushUpdateOperatorInputCalendarConnector = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité CalendarConnector */
export type PullUpdateOperatorInputCalendarConnector = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Channel */
export type FieldUpdateOperatorOfChannel = {
	set?: Maybe<SetOperatorInputOfChannel> /**  */
	inc?: Maybe<IncOperatorInputChannel> /**  */
	push?: Maybe<PushUpdateOperatorInputChannel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputChannel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Channel */
export type SetOperatorInputOfChannel = {
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Channel */
export type IncOperatorInputChannel = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Channel */
export type PushUpdateOperatorInputChannel = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Channel */
export type PullUpdateOperatorInputChannel = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfCheckFileTask = {
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCheckFileTask>> /**  */
	or?: Array<Maybe<FilterOfCheckFileTask>> /**  */
	nor?: Array<Maybe<FilterOfCheckFileTask>> /**  */
	not?: Array<Maybe<FilterOfCheckFileTask>> /**  */
}


/**  */
export type QueryContextOfCheckFileTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCheckFileTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CheckFileTask */
export type QuerySortTypeCheckFileTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfCheckSizeFileTask = {
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCheckSizeFileTask>> /**  */
	or?: Array<Maybe<FilterOfCheckSizeFileTask>> /**  */
	nor?: Array<Maybe<FilterOfCheckSizeFileTask>> /**  */
	not?: Array<Maybe<FilterOfCheckSizeFileTask>> /**  */
}


/**  */
export type QueryContextOfCheckSizeFileTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCheckSizeFileTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CheckSizeFileTask */
export type QuerySortTypeCheckSizeFileTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfClarilogServer = {
	enabled?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	lastStart?: Maybe<FilterDateTimeOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
	domain?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	osVersion?: Maybe<FilterStringOperator> /**  */
	simultaneousScan?: Maybe<FilterInt32Operator> /**  */
	scanHistoryOption?: Maybe<FilterOfScanHistoryOption> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	clarilogServerLogs?: Maybe<ArrayClarilogServerLogOperator> /**  */
	localId?: Maybe<FilterGuidOperator> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	clientApplicationtName?: Maybe<FilterStringOperator> /**  */
	updater?: Maybe<FilterOfUpdater> /**  */
	clarilogAgentSettings?: Maybe<FilterOfClarilogAgentSettings> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfClarilogServer>> /**  */
	or?: Array<Maybe<FilterOfClarilogServer>> /**  */
	nor?: Array<Maybe<FilterOfClarilogServer>> /**  */
	not?: Array<Maybe<FilterOfClarilogServer>> /**  */
}


/**  */
export type FilterOfScanHistoryOption = {
	keepSuccessAudit?: Maybe<FilterInt32Operator> /**  */
	delayBeforePurge?: Maybe<FilterInt32Operator> /**  */
	keepLastDayForEventLog?: Maybe<FilterInt32Operator> /**  */
	keepLastDayForLogon?: Maybe<FilterInt32Operator> /**  */
	keepLastDayForUptimeCalendar?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfScanHistoryOption>> /**  */
	or?: Array<Maybe<FilterOfScanHistoryOption>> /**  */
	nor?: Array<Maybe<FilterOfScanHistoryOption>> /**  */
	not?: Array<Maybe<FilterOfScanHistoryOption>> /**  */
}


/**  */
export type ArrayClarilogServerLogOperator = {
	elemMatch?: Maybe<FilterOfClarilogServerLog> /**  */
}


/**  */
export type FilterOfClarilogServerLog = {
	eventCode?: Maybe<FilterInt64Operator> /**  */
	eventType?: Maybe<FilterEventTypeOperator> /**  */
	logfile?: Maybe<FilterStringOperator> /**  */
	message?: Maybe<FilterStringOperator> /**  */
	sourceName?: Maybe<FilterStringOperator> /**  */
	timeGenerated?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfClarilogServerLog>> /**  */
	or?: Array<Maybe<FilterOfClarilogServerLog>> /**  */
	nor?: Array<Maybe<FilterOfClarilogServerLog>> /**  */
	not?: Array<Maybe<FilterOfClarilogServerLog>> /**  */
}


/**  */
export type QueryContextOfClarilogServer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeClarilogServer>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type ClarilogServer */
export type FieldUpdateOperatorOfClarilogServer = {
	set?: Maybe<SetOperatorInputOfClarilogServer> /**  */
	inc?: Maybe<IncOperatorInputClarilogServer> /**  */
	push?: Maybe<PushUpdateOperatorInputClarilogServer> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputClarilogServer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ClarilogServer */
export type SetOperatorInputOfClarilogServer = {
	enabled?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	simultaneousScan?: Maybe<number> /**  */
	locationId?: Maybe<string> /**  */
	clarilogServerLogs: Array<ClarilogServerLogInput> /**  */
	localId?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	scanHistoryOption?: Maybe<SetOperatorInputOfScanHistoryOption> /**  */
	updater?: Maybe<SetOperatorInputOfUpdater> /**  */
	clarilogAgentSettings?: Maybe<SetOperatorInputOfClarilogAgentSettings> /**  */
}


/**  */
export type ClarilogServerLogInput = {
	eventCode: number /**  */
	eventType: EventType /**  */
	logfile?: Maybe<string> /**  */
	message?: Maybe<string> /**  */
	sourceName?: Maybe<string> /**  */
	timeGenerated?: Maybe<Date> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanHistoryOption */
export type SetOperatorInputOfScanHistoryOption = {
	keepSuccessAudit?: Maybe<number> /**  */
	delayBeforePurge?: Maybe<number> /**  */
	keepLastDayForEventLog?: Maybe<number> /**  */
	keepLastDayForLogon?: Maybe<number> /**  */
	keepLastDayForUptimeCalendar?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité ClarilogServer */
export type IncOperatorInputClarilogServer = {
	simultaneousScan?: Maybe<number> /**  */
	scanHistoryOption?: Maybe<IncOperatorInputScanHistoryOption> /**  */
	updater?: Maybe<IncOperatorInputUpdater> /**  */
	clarilogAgentSettings?: Maybe<IncOperatorInputClarilogAgentSettings> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanHistoryOption */
export type IncOperatorInputScanHistoryOption = {
	keepSuccessAudit?: Maybe<number> /**  */
	delayBeforePurge?: Maybe<number> /**  */
	keepLastDayForEventLog?: Maybe<number> /**  */
	keepLastDayForLogon?: Maybe<number> /**  */
	keepLastDayForUptimeCalendar?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ClarilogServer */
export type PushUpdateOperatorInputClarilogServer = {
	clarilogServerLogs: Array<ClarilogServerLogInput> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ClarilogServer */
export type PullUpdateOperatorInputClarilogServer = {
	clarilogServerLogs: Array<ClarilogServerLogInput> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfCommandAssetCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCommandAssetCategory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CommandAssetCategory */
export type QuerySortTypeCommandAssetCategory = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	active?: Maybe<Sort> /** Représente le type de trie. */
	default?: Maybe<Sort> /** Représente le type de trie. */
	automatique?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Command */
export type FieldUpdateOperatorOfCommand = {
	set?: Maybe<SetOperatorInputOfCommand> /**  */
	inc?: Maybe<IncOperatorInputCommand> /**  */
	push?: Maybe<PushUpdateOperatorInputCommand> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCommand> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Command */
export type SetOperatorInputOfCommand = {
	category?: Maybe<CommandCategory> /**  */
	pathToExecutable?: Maybe<string> /**  */
	pathToParamterFile?: Maybe<string> /**  */
	port?: Maybe<number> /**  */
	commandLine?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	protocole?: Maybe<CommandProtocole> /**  */
	userIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	exceptionAssetIds: Array<string> /**  */
	dataCommand?: Maybe<string> /**  */
	commandAssetCategories: Array<CommandAssetCategoryInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type CommandAssetCategoryInput = {
	id?: string /**  */
	parentId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	active: boolean /**  */
	default: boolean /**  */
	automatique: boolean /**  */
}


/** Représente les propriétés de type numérique de l'entité Command */
export type IncOperatorInputCommand = {
	port?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Command */
export type PushUpdateOperatorInputCommand = {
	userIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	exceptionAssetIds: Array<string> /**  */
	commandAssetCategories: Array<CommandAssetCategoryInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Command */
export type PullUpdateOperatorInputCommand = {
	userIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	exceptionAssetIds: Array<string> /**  */
	commandAssetCategories: Array<CommandAssetCategoryInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type ConsumableMovement */
export type FieldUpdateOperatorOfConsumableMovement = {
	set?: Maybe<SetOperatorInputOfConsumableMovement> /**  */
	inc?: Maybe<IncOperatorInputConsumableMovement> /**  */
	push?: Maybe<PushUpdateOperatorInputConsumableMovement> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputConsumableMovement> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ConsumableMovement */
export type SetOperatorInputOfConsumableMovement = {
	movementId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	movementDate?: Maybe<Date> /**  */
	quantity?: Maybe<number> /**  */
	unitPrice?: Maybe<number> /**  */
	amount?: Maybe<number> /**  */
	reference?: Maybe<string> /**  */
	typeOfStaffing?: Maybe<string> /**  */
	supplier?: Maybe<string> /**  */
	assetRecipient?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	movementType?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	referenceName?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	referencePath?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	inStockMovement?: Maybe<SetOperatorInputOfInStockMovement> /**  */
	assetAcquisitionMode?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	outStockMovement?: Maybe<SetOperatorInputOfOutStockMovement> /**  */
	storageUnit?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité InStockMovement */
export type SetOperatorInputOfInStockMovement = {
	name?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	receivedById?: Maybe<string> /**  */
	deliveryOrder?: Maybe<string> /**  */
	deliveryNumber?: Maybe<number> /**  */
	defaultStorageUnitId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	carts: Array<InCartInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	order?: Maybe<SetOperatorInputOfOrder> /**  */
	bill?: Maybe<SetOperatorInputOfOrderBill> /**  */
	financial?: Maybe<SetOperatorInputOfStockFinancialInformation> /**  */
}


/**  */
export type InCartInput = {
	amount: number /**  */
	entryId: string /**  */
	category: AssetModelCategory /**  */
	cartId?: Maybe<string> /**  */
	unitCost: number /**  */
	totalCost: number /**  */
	budgetId?: Maybe<string> /**  */
	receivedById?: Maybe<string> /**  */
	storageUnitId: string /**  */
	inStockName?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Order */
export type SetOperatorInputOfOrder = {
	orderDate?: Maybe<Date> /**  */
	supplierId?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OrderBill */
export type SetOperatorInputOfOrderBill = {
	billDate?: Maybe<Date> /**  */
	total?: Maybe<number> /**  */
	fileId?: Maybe<string> /**  */
	billNumber?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StockFinancialInformation */
export type SetOperatorInputOfStockFinancialInformation = {
	budgetId?: Maybe<string> /**  */
	modeId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OutStockMovement */
export type SetOperatorInputOfOutStockMovement = {
	name?: Maybe<string> /**  */
	deliveryLocationId?: Maybe<string> /**  */
	destinationOrganizationalUnitId?: Maybe<string> /**  */
	recipientId?: Maybe<string> /**  */
	carts: Array<OutCartInput> /**  */
	date?: Maybe<Date> /**  */
	outById?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	assetIds: Array<string> /**  */
	hasConsumable?: Maybe<boolean> /**  */
	hasAsset?: Maybe<boolean> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type OutCartInput = {
	amount: number /**  */
	entryId: string /**  */
	cartId?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	entryName?: Maybe<string> /**  */
	assetCategory?: Maybe<AssetCategoryInput> /**  */
	assetManufacturer?: Maybe<ModelManufacturerInput> /**  */
	assetModel?: Maybe<ModelManufacturerInput> /**  */
	availableAmount: number /**  */
	oldStock?: Maybe<number> /**  */
	correctionStock?: Maybe<number> /**  */
	movedAmount?: Maybe<number> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<LocationInput> /**  */
	storageUnitEndId?: Maybe<string> /**  */
	storageUnitEnd?: Maybe<LocationInput> /**  */
}


/**  */
export type AssetCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	lifetime?: Maybe<AssetLifetimeInput> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	disabled: boolean /**  */
	defaultPower: number /**  */
	stockageDurationMax: number /**  */
	lifeDuration: number /**  */
	snmpType: SnmpType /**  */
	decayRate: number /**  */
	parentId?: Maybe<string> /**  */
	namingPolicyRuleId?: Maybe<string> /**  */
	remoteControlId?: Maybe<string> /**  */
	path?: Maybe<TranslatedFieldInput> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	defaultCommandId?: Maybe<string> /**  */
	autoLaunchCommand: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	error: boolean /**  */
}


/**  */
export type AssetLifetimeInput = {
	yearDuration: number /**  */
	monthDuration: number /**  */
	dayDuration: number /**  */
	lifeTimeOption: LifetimeStartDateSelection /**  */
}


/**  */
export type ModelManufacturerInput = {
	name?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
}


/**  */
export type LocationInput = {
	dynamicProperties?: Maybe<string> /**  */
	error: boolean /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	allowSelection: boolean /**  */
	stock: boolean /**  */
	maxCapacity: boolean /**  */
	stockManagerId?: Maybe<string> /**  */
	rfid?: Maybe<string> /**  */
	barCode?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedFieldInput> /**  */
	responsableId?: Maybe<string> /**  */
	locationCategoryId: string /**  */
	primaryContactId?: Maybe<string> /**  */
	templateId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	allRulesMustBeValidAsset: boolean /**  */
	allRulesMustBeValidUser: boolean /**  */
	address?: Maybe<AddressInput> /**  */
	phone?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	loanId?: Maybe<string> /**  */
	loanIds: Array<string> /**  */
	roomNumber?: Maybe<string> /**  */
	loanable: boolean /**  */
	contactId?: Maybe<string> /**  */
	buildingTypeId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	assetModelStockEntryIds: Array<string> /**  */
}


/**  */
export type AddressInput = {
	street?: Maybe<string> /**  */
	additional?: Maybe<string> /**  */
	city?: Maybe<string> /**  */
	province?: Maybe<string> /**  */
	country?: Maybe<string> /**  */
	zipCode?: Maybe<string> /**  */
	postalCode?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ConsumableMovement */
export type IncOperatorInputConsumableMovement = {
	quantity?: Maybe<number> /**  */
	unitPrice?: Maybe<number> /**  */
	amount?: Maybe<number> /**  */
	inStockMovement?: Maybe<IncOperatorInputInStockMovement> /**  */
}


/** Représente les propriétés de type numérique de l'entité InStockMovement */
export type IncOperatorInputInStockMovement = {
	deliveryNumber?: Maybe<number> /**  */
	bill?: Maybe<IncOperatorInputOrderBill> /**  */
}


/** Représente les propriétés de type numérique de l'entité OrderBill */
export type IncOperatorInputOrderBill = {
	total?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ConsumableMovement */
export type PushUpdateOperatorInputConsumableMovement = {
	dynamicProperties?: Maybe<any> /**  */
	inStockMovement?: Maybe<PushUpdateOperatorInputInStockMovement> /**  */
	outStockMovement?: Maybe<PushUpdateOperatorInputOutStockMovement> /**  */
}


/** Représente les propriétés de type collection de l'entité InStockMovement */
export type PushUpdateOperatorInputInStockMovement = {
	fileIds: Array<string> /**  */
	carts: Array<InCartInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	order?: Maybe<PushUpdateOperatorInputOrder> /**  */
	bill?: Maybe<PushUpdateOperatorInputOrderBill> /**  */
	financial?: Maybe<PushUpdateOperatorInputStockFinancialInformation> /**  */
}


/** Représente les propriétés de type collection de l'entité Order */
export type PushUpdateOperatorInputOrder = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OrderBill */
export type PushUpdateOperatorInputOrderBill = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité StockFinancialInformation */
export type PushUpdateOperatorInputStockFinancialInformation = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OutStockMovement */
export type PushUpdateOperatorInputOutStockMovement = {
	carts: Array<OutCartInput> /**  */
	storageUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ConsumableMovement */
export type PullUpdateOperatorInputConsumableMovement = {
	dynamicProperties?: Maybe<any> /**  */
	inStockMovement?: Maybe<PullUpdateOperatorInputInStockMovement> /**  */
	outStockMovement?: Maybe<PullUpdateOperatorInputOutStockMovement> /**  */
}


/** Représente les propriétés de type collection de l'entité InStockMovement */
export type PullUpdateOperatorInputInStockMovement = {
	fileIds: Array<string> /**  */
	carts: Array<InCartInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	order?: Maybe<PullUpdateOperatorInputOrder> /**  */
	bill?: Maybe<PullUpdateOperatorInputOrderBill> /**  */
	financial?: Maybe<PullUpdateOperatorInputStockFinancialInformation> /**  */
}


/** Représente les propriétés de type collection de l'entité Order */
export type PullUpdateOperatorInputOrder = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OrderBill */
export type PullUpdateOperatorInputOrderBill = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité StockFinancialInformation */
export type PullUpdateOperatorInputStockFinancialInformation = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OutStockMovement */
export type PullUpdateOperatorInputOutStockMovement = {
	carts: Array<OutCartInput> /**  */
	storageUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfContractCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	contentModel?: Maybe<ArrayByteTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfContractCategory>> /**  */
	or?: Array<Maybe<FilterOfContractCategory>> /**  */
	nor?: Array<Maybe<FilterOfContractCategory>> /**  */
	not?: Array<Maybe<FilterOfContractCategory>> /**  */
}


/**  */
export type ArrayByteTypeOperator = {
	all?: Array<Maybe<number>> /**  */
	size?: Maybe<number> /**  */
	eq?: Maybe<string> /**  */
	ne?: Maybe<string> /**  */
	contains?: Maybe<string> /**  */
	notcontains?: Maybe<string> /**  */
	elemMatch?: Maybe<FilterByteOperator> /**  */
}


/**  */
export type FilterByteOperator = {
	eq?: Maybe<number> /**  */
	ne?: Maybe<number> /**  */
	in?: Array<Maybe<number>> /**  */
	nin?: Array<Maybe<number>> /**  */
	gt?: Maybe<number> /**  */
	gte?: Maybe<number> /**  */
	lt?: Maybe<number> /**  */
	lte?: Maybe<number> /**  */
	mod?: Maybe<ModOperator> /**  */
	regex?: Maybe<RegexOperator> /**  */
}


/**  */
export type QueryContextOfContractCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeContractCategory>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type ContractCategory */
export type FieldUpdateOperatorOfContractCategory = {
	set?: Maybe<SetOperatorInputOfContractCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputContractCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputContractCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ContractCategory */
export type SetOperatorInputOfContractCategory = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ContractCategory */
export type PushUpdateOperatorInputContractCategory = {
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ContractCategory */
export type PullUpdateOperatorInputContractCategory = {
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Contract */
export type FieldUpdateOperatorOfContract = {
	set?: Maybe<SetOperatorInputOfContract> /**  */
	inc?: Maybe<IncOperatorInputContract> /**  */
	push?: Maybe<PushUpdateOperatorInputContract> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputContract> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Contract */
export type SetOperatorInputOfContract = {
	hidden?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	contractNumber?: Maybe<string> /**  */
	customerNumber?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	denouncement?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	unlimited?: Maybe<boolean> /**  */
	contractCategoryId?: Maybe<string> /**  */
	costMonthly?: Maybe<number> /**  */
	costSubscription?: Maybe<number> /**  */
	periodicityId?: Maybe<string> /**  */
	firstRent?: Maybe<Date> /**  */
	costFirstRent?: Maybe<number> /**  */
	costLastRent?: Maybe<number> /**  */
	costNextRent?: Maybe<number> /**  */
	costOther?: Maybe<number> /**  */
	residualPurchase?: Maybe<number> /**  */
	reconduction?: Maybe<Date> /**  */
	taciteReconduction?: Maybe<boolean> /**  */
	renewal?: Maybe<boolean> /**  */
	illimited?: Maybe<boolean> /**  */
	fileNumber?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	cost?: Maybe<number> /**  */
	totalCost?: Maybe<number> /**  */
	authorizeNumber?: Maybe<string> /**  */
	orderDate?: Maybe<Date> /**  */
	numberOrder?: Maybe<string> /**  */
	numberSupplierOrder?: Maybe<string> /**  */
	billDate?: Maybe<Date> /**  */
	billNumber?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
	immobilizationNumber?: Maybe<string> /**  */
	isActiv?: Maybe<boolean> /**  */
	fixIllimited?: Maybe<boolean> /**  */
	serviceData?: Maybe<boolean> /**  */
	fidelityNumber?: Maybe<number> /**  */
	fidelityDate?: Maybe<Date> /**  */
	phoneNumber?: Maybe<string> /**  */
	capacityData?: Maybe<number> /**  */
	nextDeadline?: Maybe<Date> /**  */
	firstDeadline?: Maybe<Date> /**  */
	assetIds: Array<string> /**  */
	userIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	histories: Array<ContractInput> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	softwareGroupsIds: Array<string> /**  */
	softwaresIds: Array<string> /**  */
	renewalId?: Maybe<string> /**  */
	frequencyDeadline?: Maybe<FrequencyDeadline> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	detectWarranty?: Maybe<SetOperatorInputOfDetectWarranty> /**  */
}


/**  */
export type ContractInput = {
	dynamicProperties?: Maybe<string> /**  */
	hidden: boolean /**  */
	name?: Maybe<string> /**  */
	contractNumber?: Maybe<string> /**  */
	customerNumber?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	denouncement?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	unlimited: boolean /**  */
	contractCategoryId?: Maybe<string> /**  */
	costMonthly: number /**  */
	costSubscription: number /**  */
	periodicityId?: Maybe<string> /**  */
	firstRent?: Maybe<Date> /**  */
	costFirstRent: number /**  */
	costLastRent: number /**  */
	costNextRent: number /**  */
	costOther: number /**  */
	residualPurchase: number /**  */
	reconduction?: Maybe<Date> /**  */
	taciteReconduction: boolean /**  */
	renewal: boolean /**  */
	illimited: boolean /**  */
	fileNumber?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	cost: number /**  */
	totalCost: number /**  */
	authorizeNumber?: Maybe<string> /**  */
	orderDate?: Maybe<Date> /**  */
	numberOrder?: Maybe<string> /**  */
	numberSupplierOrder?: Maybe<string> /**  */
	billDate?: Maybe<Date> /**  */
	billNumber?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
	immobilizationNumber?: Maybe<string> /**  */
	isActiv: boolean /**  */
	fixIllimited: boolean /**  */
	serviceData: boolean /**  */
	fidelityNumber: number /**  */
	fidelityDate?: Maybe<Date> /**  */
	phoneNumber?: Maybe<string> /**  */
	capacityData: number /**  */
	nextDeadline?: Maybe<Date> /**  */
	firstDeadline?: Maybe<Date> /**  */
	assetIds: Array<string> /**  */
	userIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	histories?: Array<Maybe<ContractInput>> /**  */
	fileIds: Array<string> /**  */
	detectWarranty?: Maybe<DetectWarrantyInput> /**  */
	groupIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwareGroupsIds: Array<string> /**  */
	softwaresIds: Array<string> /**  */
	renewalId?: Maybe<string> /**  */
	frequencyDeadline?: Maybe<FrequencyDeadline> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Contract */
export type IncOperatorInputContract = {
	costMonthly?: Maybe<number> /**  */
	costSubscription?: Maybe<number> /**  */
	costFirstRent?: Maybe<number> /**  */
	costLastRent?: Maybe<number> /**  */
	costNextRent?: Maybe<number> /**  */
	costOther?: Maybe<number> /**  */
	residualPurchase?: Maybe<number> /**  */
	cost?: Maybe<number> /**  */
	totalCost?: Maybe<number> /**  */
	fidelityNumber?: Maybe<number> /**  */
	capacityData?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Contract */
export type PushUpdateOperatorInputContract = {
	assetIds: Array<string> /**  */
	userIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	histories: Array<ContractInput> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	softwareGroupsIds: Array<string> /**  */
	softwaresIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Contract */
export type PullUpdateOperatorInputContract = {
	assetIds: Array<string> /**  */
	userIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	histories: Array<ContractInput> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	softwareGroupsIds: Array<string> /**  */
	softwaresIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfOutCart = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOutCart>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OutCart */
export type QuerySortTypeOutCart = {
	amount?: Maybe<Sort> /** Représente le type de trie. */
	entryName?: Maybe<Sort> /** Représente le type de trie. */
	assetCategory?: Array<Maybe<QuerySortTypeAssetCategory>> /**  */
	assetManufacturer?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	assetModel?: Array<Maybe<QuerySortTypeModelManufacturer>> /**  */
	availableAmount?: Maybe<Sort> /** Représente le type de trie. */
	oldStock?: Maybe<Sort> /** Représente le type de trie. */
	correctionStock?: Maybe<Sort> /** Représente le type de trie. */
	movedAmount?: Maybe<Sort> /** Représente le type de trie. */
	storageUnit?: Array<Maybe<QuerySortTypeLocation>> /**  */
	storageUnitEnd?: Array<Maybe<QuerySortTypeLocation>> /**  */
	category?: Maybe<Sort> /** Représente le type de trie. */
	oldCarts?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type CorrectionStockMovement */
export type FieldUpdateOperatorOfCorrectionStockMovement = {
	set?: Maybe<SetOperatorInputOfCorrectionStockMovement> /**  */
	push?: Maybe<PushUpdateOperatorInputCorrectionStockMovement> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCorrectionStockMovement> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CorrectionStockMovement */
export type SetOperatorInputOfCorrectionStockMovement = {
	name?: Maybe<string> /**  */
	carts: Array<CorrectionCartInput> /**  */
	date?: Maybe<Date> /**  */
	correctionById?: Maybe<string> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnitIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type CorrectionCartInput = {
	amount: number /**  */
	entryId: string /**  */
	description?: Maybe<string> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<LocationInput> /**  */
}


/** Représente les propriétés de type collection de l'entité CorrectionStockMovement */
export type PushUpdateOperatorInputCorrectionStockMovement = {
	carts: Array<CorrectionCartInput> /**  */
	storageUnitIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité CorrectionStockMovement */
export type PullUpdateOperatorInputCorrectionStockMovement = {
	carts: Array<CorrectionCartInput> /**  */
	storageUnitIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfCostCenter = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCostCenter>> /**  */
	or?: Array<Maybe<FilterOfCostCenter>> /**  */
	nor?: Array<Maybe<FilterOfCostCenter>> /**  */
	not?: Array<Maybe<FilterOfCostCenter>> /**  */
}


/**  */
export type QueryContextOfCostCenter = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCostCenter>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CostCenter */
export type QuerySortTypeCostCenter = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type CostCenter */
export type FieldUpdateOperatorOfCostCenter = {
	set?: Maybe<SetOperatorInputOfCostCenter> /**  */
	push?: Maybe<PushUpdateOperatorInputCostCenter> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCostCenter> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CostCenter */
export type SetOperatorInputOfCostCenter = {
	description?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité CostCenter */
export type PushUpdateOperatorInputCostCenter = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité CostCenter */
export type PullUpdateOperatorInputCostCenter = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfCronJobHourlyTask = {
	endExecution?: Maybe<FilterDateTimeOperator> /**  */
	cronJobHourlyType?: Maybe<FilterCronJobHourlyTypeOperator> /**  */
	cronExpression?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCronJobHourlyTask>> /**  */
	or?: Array<Maybe<FilterOfCronJobHourlyTask>> /**  */
	nor?: Array<Maybe<FilterOfCronJobHourlyTask>> /**  */
	not?: Array<Maybe<FilterOfCronJobHourlyTask>> /**  */
}


/**  */
export type FilterCronJobHourlyTypeOperator = {
	eq?: Maybe<CronJobHourlyType> /**  */
	ne?: Maybe<CronJobHourlyType> /**  */
	in?: Array<Maybe<CronJobHourlyType>> /**  */
	nin?: Array<Maybe<CronJobHourlyType>> /**  */
}


/**  */
export type QueryContextOfCronJobHourlyTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCronJobHourlyTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CronJobHourlyTask */
export type QuerySortTypeCronJobHourlyTask = {
	endExecution?: Maybe<Sort> /** Représente le type de trie. */
	cronJobHourlyType?: Maybe<Sort> /** Représente le type de trie. */
	cronExpression?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfCustomField = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	testField?: Maybe<FilterOfTranslatedField> /**  */
	defaultName?: Maybe<FilterOfTranslatedField> /**  */
	fieldName?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfCustomField>> /**  */
	or?: Array<Maybe<FilterOfCustomField>> /**  */
	nor?: Array<Maybe<FilterOfCustomField>> /**  */
	not?: Array<Maybe<FilterOfCustomField>> /**  */
}


/**  */
export type QueryContextOfCustomField = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeCustomField>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité CustomField */
export type QuerySortTypeCustomField = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	testField?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	defaultName?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	fieldName?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type CustomField */
export type FieldUpdateOperatorOfCustomField = {
	set?: Maybe<SetOperatorInputOfCustomField> /**  */
	push?: Maybe<PushUpdateOperatorInputCustomField> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCustomField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CustomField */
export type SetOperatorInputOfCustomField = {
	fieldName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	testField?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	defaultName?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité CustomField */
export type PushUpdateOperatorInputCustomField = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité CustomField */
export type PullUpdateOperatorInputCustomField = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfDeleteFileTask = {
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketType?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDeleteFileTask>> /**  */
	or?: Array<Maybe<FilterOfDeleteFileTask>> /**  */
	nor?: Array<Maybe<FilterOfDeleteFileTask>> /**  */
	not?: Array<Maybe<FilterOfDeleteFileTask>> /**  */
}


/**  */
export type QueryContextOfDeleteFileTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDeleteFileTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DeleteFileTask */
export type QuerySortTypeDeleteFileTask = {
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketType?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfDomain = {
	name?: Maybe<FilterStringOperator> /**  */
	netbiosName?: Maybe<FilterStringOperator> /**  */
	accounts?: Maybe<ArrayAccountOperator> /**  */
	organisations?: Maybe<ArrayOrganizationalUnitOperator> /**  */
	assets?: Maybe<ArrayAssetOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDomain>> /**  */
	or?: Array<Maybe<FilterOfDomain>> /**  */
	nor?: Array<Maybe<FilterOfDomain>> /**  */
	not?: Array<Maybe<FilterOfDomain>> /**  */
}


/**  */
export type ArrayOrganizationalUnitOperator = {
	elemMatch?: Maybe<FilterOfOrganizationalUnit> /**  */
}


/**  */
export type ArrayAssetOperator = {
	elemMatch?: Maybe<FilterOfAsset> /**  */
}


/**  */
export type QueryContextOfDomain = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDomain>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Domain */
export type QuerySortTypeDomain = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	netbiosName?: Maybe<Sort> /** Représente le type de trie. */
	accounts?: Maybe<Sort> /** Représente le type de trie. */
	organisations?: Maybe<Sort> /** Représente le type de trie. */
	assets?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfDynamicPropertyGroup = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	position?: Maybe<FilterInt32Operator> /**  */
	collapse?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	useFreeSpace?: Maybe<FilterBooleanOperator> /**  */
	dynamicPropertyLocation?: Maybe<FilterDynamicPropertyLocationEnumOperator> /**  */
	entityTypes?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyGroup>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyGroup>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyGroup>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyGroup>> /**  */
}


/**  */
export type FilterDynamicPropertyLocationEnumOperator = {
	eq?: Maybe<DynamicPropertyLocationEnum> /**  */
	ne?: Maybe<DynamicPropertyLocationEnum> /**  */
	in?: Array<Maybe<DynamicPropertyLocationEnum>> /**  */
	nin?: Array<Maybe<DynamicPropertyLocationEnum>> /**  */
}


/**  */
export type QueryContextOfDynamicPropertyGroup = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDynamicPropertyGroup>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfDynamicPropertyList = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	values?: Maybe<ArrayDynamicPropertyListValueOperator> /**  */
	entityTypes?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyList>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyList>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyList>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyList>> /**  */
}


/**  */
export type ArrayDynamicPropertyListValueOperator = {
	elemMatch?: Maybe<FilterOfDynamicPropertyListValue> /**  */
}


/**  */
export type FilterOfDynamicPropertyListValue = {
	id?: Maybe<FilterGuidOperator> /**  */
	fr?: Maybe<FilterStringOperator> /**  */
	nl?: Maybe<FilterStringOperator> /**  */
	en?: Maybe<FilterStringOperator> /**  */
	it?: Maybe<FilterStringOperator> /**  */
	de?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfDynamicPropertyListValue>> /**  */
	or?: Array<Maybe<FilterOfDynamicPropertyListValue>> /**  */
	nor?: Array<Maybe<FilterOfDynamicPropertyListValue>> /**  */
	not?: Array<Maybe<FilterOfDynamicPropertyListValue>> /**  */
}


/**  */
export type QueryContextOfDynamicPropertyList = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDynamicPropertyList>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfDynamicPropertyListValue = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeDynamicPropertyListValue>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité DynamicPropertyListValue */
export type QuerySortTypeDynamicPropertyListValue = {
	fr?: Maybe<Sort> /** Représente le type de trie. */
	nl?: Maybe<Sort> /** Représente le type de trie. */
	en?: Maybe<Sort> /** Représente le type de trie. */
	it?: Maybe<Sort> /** Représente le type de trie. */
	de?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfEmailLink = {
	id?: Maybe<FilterGuidOperator> /**  */
	linkId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	emailType?: Maybe<FilterStringOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	subject?: Maybe<FilterOfTranslatedField> /**  */
	and?: Array<Maybe<FilterOfEmailLink>> /**  */
	or?: Array<Maybe<FilterOfEmailLink>> /**  */
	nor?: Array<Maybe<FilterOfEmailLink>> /**  */
	not?: Array<Maybe<FilterOfEmailLink>> /**  */
}


/**  */
export type QueryContextOfEmailLink = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEmailLink>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EmailLink */
export type QuerySortTypeEmailLink = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	emailType?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	subject?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
}


/** Représente les méthodes disponible pour le type EmailConnector */
export type FieldUpdateOperatorOfEmailConnector = {
	set?: Maybe<SetOperatorInputOfEmailConnector> /**  */
	inc?: Maybe<IncOperatorInputEmailConnector> /**  */
	push?: Maybe<PushUpdateOperatorInputEmailConnector> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputEmailConnector> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EmailConnector */
export type SetOperatorInputOfEmailConnector = {
	type?: Maybe<EmailConnectorTypeEnum> /**  */
	server?: Maybe<string> /**  */
	port?: Maybe<number> /**  */
	ssl?: Maybe<boolean> /**  */
	email?: Maybe<string> /**  */
	accountId?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	allowUnknowFrom?: Maybe<boolean> /**  */
	allowCreateTicketByEmail?: Maybe<boolean> /**  */
	allowCommunicationByMail?: Maybe<boolean> /**  */
	allowAddInterventionByMail?: Maybe<boolean> /**  */
	interventionByMailTag?: Maybe<string> /**  */
	interventionByMailActivityId?: Maybe<string> /**  */
	onCreateTicketAddAllRecipients?: Maybe<boolean> /**  */
	onCommunicationTicketAddAllRecipients?: Maybe<boolean> /**  */
	onCommunicationTicketAddFrom?: Maybe<boolean> /**  */
	statusIds: Array<string> /**  */
	inboxName?: Maybe<string> /**  */
	send?: Maybe<boolean> /**  */
	receive?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	countMailSend?: Maybe<number> /**  */
	countMailReceive?: Maybe<number> /**  */
	qualification?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	expireAccesToken?: Maybe<Date> /**  */
	default?: Maybe<boolean> /**  */
	allowEmbeddedImage?: Maybe<boolean> /**  */
	smtpEncodingConstraint?: Maybe<number> /**  */
	authAzurePassword?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	mailSlurpInbox?: Maybe<SetOperatorInputOfMailSlurpInbox> /**  */
	disabledSend?: Maybe<SetOperatorInputOfDisabledReason> /**  */
	disabledReceive?: Maybe<SetOperatorInputOfDisabledReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MailSlurpInbox */
export type SetOperatorInputOfMailSlurpInbox = {
	email?: Maybe<string> /**  */
	inboxId?: Maybe<string> /**  */
	webhookId?: Maybe<string> /**  */
	endpoint?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	useFiltering?: Maybe<boolean> /**  */
	useEmailChecker?: Maybe<boolean> /**  */
	emailCheckerLevel?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DisabledReason */
export type SetOperatorInputOfDisabledReason = {
	disabled?: Maybe<boolean> /**  */
	date?: Maybe<Date> /**  */
	reason?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité EmailConnector */
export type IncOperatorInputEmailConnector = {
	port?: Maybe<number> /**  */
	countMailSend?: Maybe<number> /**  */
	countMailReceive?: Maybe<number> /**  */
	smtpEncodingConstraint?: Maybe<number> /**  */
	mailSlurpInbox?: Maybe<IncOperatorInputMailSlurpInbox> /**  */
}


/** Représente les propriétés de type numérique de l'entité MailSlurpInbox */
export type IncOperatorInputMailSlurpInbox = {
	emailCheckerLevel?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailConnector */
export type PushUpdateOperatorInputEmailConnector = {
	statusIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailConnector */
export type PullUpdateOperatorInputEmailConnector = {
	statusIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfEmailCredential = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEmailCredential>> /**  */
	or?: Array<Maybe<FilterOfEmailCredential>> /**  */
	nor?: Array<Maybe<FilterOfEmailCredential>> /**  */
	not?: Array<Maybe<FilterOfEmailCredential>> /**  */
}


/**  */
export type QueryContextOfEmailCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEmailCredential>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type EmailCredential */
export type FieldUpdateOperatorOfEmailCredential = {
	set?: Maybe<SetOperatorInputOfEmailCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputEmailCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputEmailCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EmailCredential */
export type SetOperatorInputOfEmailCredential = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailCredential */
export type PushUpdateOperatorInputEmailCredential = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailCredential */
export type PullUpdateOperatorInputEmailCredential = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfEmailPrototypeLogQueriesResolver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
}


/** Représente les méthodes disponible pour le type EmailPrototype */
export type FieldUpdateOperatorOfEmailPrototype = {
	set?: Maybe<SetOperatorInputOfEmailPrototype> /**  */
	push?: Maybe<PushUpdateOperatorInputEmailPrototype> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputEmailPrototype> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EmailPrototype */
export type SetOperatorInputOfEmailPrototype = {
	copyFrom?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	from?: Maybe<string> /**  */
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	attachedResult?: Maybe<boolean> /**  */
	alarmIds: Array<string> /**  */
	queryBuilderIds: Array<string> /**  */
	triggerType?: Maybe<EmailPrototypeTriggerTypeEnum> /**  */
	time?: Maybe<Date> /**  */
	ticketCategoryIds: Array<string> /**  */
	disableFilterCategories?: Maybe<boolean> /**  */
	sendInvitation?: Maybe<boolean> /**  */
	eventDate?: Maybe<Date> /**  */
	attachFiles?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	subject?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	body?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototype */
export type PushUpdateOperatorInputEmailPrototype = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	alarmIds: Array<string> /**  */
	queryBuilderIds: Array<string> /**  */
	ticketCategoryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototype */
export type PullUpdateOperatorInputEmailPrototype = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	alarmIds: Array<string> /**  */
	queryBuilderIds: Array<string> /**  */
	ticketCategoryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfEsxCredential = {
	webService?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEsxCredential>> /**  */
	or?: Array<Maybe<FilterOfEsxCredential>> /**  */
	nor?: Array<Maybe<FilterOfEsxCredential>> /**  */
	not?: Array<Maybe<FilterOfEsxCredential>> /**  */
}


/**  */
export type QueryContextOfEsxCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeEsxCredential>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité EsxCredential */
export type QuerySortTypeEsxCredential = {
	webService?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type EsxCredential */
export type FieldUpdateOperatorOfEsxCredential = {
	set?: Maybe<SetOperatorInputOfEsxCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputEsxCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputEsxCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EsxCredential */
export type SetOperatorInputOfEsxCredential = {
	webService?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité EsxCredential */
export type PushUpdateOperatorInputEsxCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité EsxCredential */
export type PullUpdateOperatorInputEsxCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type ExchangeConnectorInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	exchangeCalendar?: Maybe<ExchangeCalendarInput> /**  */
	domain?: Maybe<string> /**  */
	logon?: Maybe<string> /**  */
	mailBox?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	useAutodiscoverUrl: boolean /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	expireAccesToken?: Maybe<Date> /**  */
	azureToken?: Maybe<string> /**  */
	authAzurePassword: boolean /**  */
	type: ExchangeConnectorTypeEnum /**  */
}


/**  */
export type ExchangeCalendarInput = {
	name?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	calendarId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type ExchangeConnector */
export type FieldUpdateOperatorOfExchangeConnector = {
	set?: Maybe<SetOperatorInputOfExchangeConnector> /**  */
	push?: Maybe<PushUpdateOperatorInputExchangeConnector> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputExchangeConnector> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ExchangeConnector */
export type SetOperatorInputOfExchangeConnector = {
	domain?: Maybe<string> /**  */
	logon?: Maybe<string> /**  */
	mailBox?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	useAutodiscoverUrl?: Maybe<boolean> /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	expireAccesToken?: Maybe<Date> /**  */
	azureToken?: Maybe<string> /**  */
	authAzurePassword?: Maybe<boolean> /**  */
	type?: Maybe<ExchangeConnectorTypeEnum> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	exchangeCalendar?: Maybe<SetOperatorInputOfExchangeCalendar> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ExchangeCalendar */
export type SetOperatorInputOfExchangeCalendar = {
	name?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	calendarId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ExchangeConnector */
export type PushUpdateOperatorInputExchangeConnector = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ExchangeConnector */
export type PullUpdateOperatorInputExchangeConnector = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfFeedback = {
	name?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	send?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFeedback>> /**  */
	or?: Array<Maybe<FilterOfFeedback>> /**  */
	nor?: Array<Maybe<FilterOfFeedback>> /**  */
	not?: Array<Maybe<FilterOfFeedback>> /**  */
}


/**  */
export type QueryContextOfFeedback = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFeedback>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Feedback */
export type QuerySortTypeFeedback = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	send?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfFieldMapping = {
	name?: Maybe<FilterStringOperator> /**  */
	ldapFieldMappings?: Maybe<ArrayLdapFieldMappingOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfFieldMapping>> /**  */
	or?: Array<Maybe<FilterOfFieldMapping>> /**  */
	nor?: Array<Maybe<FilterOfFieldMapping>> /**  */
	not?: Array<Maybe<FilterOfFieldMapping>> /**  */
}


/**  */
export type ArrayLdapFieldMappingOperator = {
	elemMatch?: Maybe<FilterOfLdapFieldMapping> /**  */
}


/**  */
export type FilterOfLdapFieldMapping = {
	ldapField?: Maybe<FilterStringOperator> /**  */
	entityField?: Maybe<FilterStringOperator> /**  */
	phoneType?: Maybe<FilterPhoneEnumOperator> /**  */
	updateLdapValue?: Maybe<FilterUpdateLdapValueOperator> /**  */
	type?: Maybe<FilterLdapObjectClassTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapFieldMapping>> /**  */
	or?: Array<Maybe<FilterOfLdapFieldMapping>> /**  */
	nor?: Array<Maybe<FilterOfLdapFieldMapping>> /**  */
	not?: Array<Maybe<FilterOfLdapFieldMapping>> /**  */
}


/**  */
export type FilterUpdateLdapValueOperator = {
	eq?: Maybe<UpdateLdapValue> /**  */
	ne?: Maybe<UpdateLdapValue> /**  */
	in?: Array<Maybe<UpdateLdapValue>> /**  */
	nin?: Array<Maybe<UpdateLdapValue>> /**  */
}


/**  */
export type QueryContextOfFieldMapping = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeFieldMapping>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type FormDesigner */
export type FieldUpdateOperatorOfFormDesigner = {
	set?: Maybe<SetOperatorInputOfFormDesigner> /**  */
	push?: Maybe<PushUpdateOperatorInputFormDesigner> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputFormDesigner> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FormDesigner */
export type SetOperatorInputOfFormDesigner = {
	isDefault?: Maybe<boolean> /**  */
	qualification?: Maybe<string> /**  */
	json?: Maybe<string> /**  */
	formDesignerTo?: Maybe<FormDesignerTo> /**  */
	userIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité FormDesigner */
export type PushUpdateOperatorInputFormDesigner = {
	userIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité FormDesigner */
export type PullUpdateOperatorInputFormDesigner = {
	userIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfIamtCredential = {
	tls?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIamtCredential>> /**  */
	or?: Array<Maybe<FilterOfIamtCredential>> /**  */
	nor?: Array<Maybe<FilterOfIamtCredential>> /**  */
	not?: Array<Maybe<FilterOfIamtCredential>> /**  */
}


/**  */
export type QueryContextOfIamtCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeIamtCredential>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité IamtCredential */
export type QuerySortTypeIamtCredential = {
	tls?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type IamtCredential */
export type FieldUpdateOperatorOfIamtCredential = {
	set?: Maybe<SetOperatorInputOfIamtCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputIamtCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputIamtCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité IamtCredential */
export type SetOperatorInputOfIamtCredential = {
	tls?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité IamtCredential */
export type PushUpdateOperatorInputIamtCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité IamtCredential */
export type PullUpdateOperatorInputIamtCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Impact */
export type FieldUpdateOperatorOfImpact = {
	set?: Maybe<SetOperatorInputOfImpact> /**  */
	inc?: Maybe<IncOperatorInputImpact> /**  */
	push?: Maybe<PushUpdateOperatorInputImpact> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputImpact> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Impact */
export type SetOperatorInputOfImpact = {
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Impact */
export type IncOperatorInputImpact = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Impact */
export type PushUpdateOperatorInputImpact = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Impact */
export type PullUpdateOperatorInputImpact = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTag = {
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTag>> /**  */
	or?: Array<Maybe<FilterOfTag>> /**  */
	nor?: Array<Maybe<FilterOfTag>> /**  */
	not?: Array<Maybe<FilterOfTag>> /**  */
}


/**  */
export type QueryContextOfTag = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTag>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Tag */
export type QuerySortTypeTag = {
	name?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type IncidentModel */
export type FieldUpdateOperatorOfIncidentModel = {
	set?: Maybe<SetOperatorInputOfIncidentModel> /**  */
	inc?: Maybe<IncOperatorInputIncidentModel> /**  */
	push?: Maybe<PushUpdateOperatorInputIncidentModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputIncidentModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité IncidentModel */
export type SetOperatorInputOfIncidentModel = {
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	copyFrom?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	populationIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	levelSupport?: Maybe<number> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	modelName?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	privateResolutionComment?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	resolutionComment?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité IncidentModel */
export type IncOperatorInputIncidentModel = {
	levelSupport?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité IncidentModel */
export type PushUpdateOperatorInputIncidentModel = {
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	populationIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité IncidentModel */
export type PullUpdateOperatorInputIncidentModel = {
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	populationIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfIncident = {
	dynamicProperties?: Maybe<any> /**  */
	tenantIdString?: Maybe<FilterStringOperator> /**  */
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	counterIncident?: Maybe<FilterInt32Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInIncident> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInIncident> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInIncident> /**  */
	resolutionCategoryId?: Maybe<FilterGuidOperator> /**  */
	resolutionCategory?: Maybe<FilterDenormalizeOfResolutionCategoryByResolutionCategoryInIncident> /**  */
	statusReasonId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusReasons?: Maybe<FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInIncident> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInIncident> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	channel?: Maybe<FilterDenormalizeOfChannelByChannelInIncident> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInIncident> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInIncident> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInIncident> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInIncident> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	concernedProject?: Maybe<FilterDenormalizeOfConcernedProjectByConcernedProjectInIncident> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInIncident> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInIncident> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByUserAffectedInIncident> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByUserMakeRequestInIncident> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInIncident> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	respondBefore?: Maybe<FilterDateTimeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	slaId?: Maybe<FilterGuidOperator> /**  */
	sla?: Maybe<FilterDenormalizeOfServiceLevelAgreementBySlaInIncident> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	appointmentConnector?: Maybe<FilterOfAppointmentConnector> /**  */
	estimate?: Maybe<FilterTimeSpanOperator> /**  */
	estimateMinutes?: Maybe<FilterDoubleOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainImpactedAssetId?: Maybe<FilterGuidOperator> /**  */
	mainImpactedAsset?: Maybe<FilterDenormalizeOfAssetByMainImpactedAssetInIncident> /**  */
	favoriteUserTicketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	statusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	privateResolutionArticleId?: Maybe<FilterGuidOperator> /**  */
	privateResolutionArticle?: Maybe<FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInIncident> /**  */
	privateResolutionComment?: Maybe<FilterStringOperator> /**  */
	resolutionComment?: Maybe<FilterStringOperator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	incidentModel?: Maybe<FilterDenormalizeOfIncidentModelByIncidentModelInIncident> /**  */
	callbackNumber?: Maybe<FilterStringOperator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
	takeChargeDate?: Maybe<FilterDateTimeOperator> /**  */
	endTreatmentDate?: Maybe<FilterDateTimeOperator> /**  */
	closedTicketDate?: Maybe<FilterDateTimeOperator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	emailConnectorId?: Maybe<FilterGuidOperator> /**  */
	satisfaction?: Maybe<FilterOfTicketSatisfaction> /**  */
	takeChargeHours?: Maybe<FilterDoubleOperator> /**  */
	endTreatmentHours?: Maybe<FilterDoubleOperator> /**  */
	closedHours?: Maybe<FilterDoubleOperator> /**  */
	isParent?: Maybe<FilterBooleanOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketByParentInIncident> /**  */
	childrenIds?: Maybe<ArrayUuidTypeOperator> /**  */
	problemId?: Maybe<FilterGuidOperator> /**  */
	problem?: Maybe<FilterDenormalizeOfProblemByProblemInIncident> /**  */
	externalAlreadySendFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfIncident>> /**  */
	or?: Array<Maybe<FilterOfIncident>> /**  */
	nor?: Array<Maybe<FilterOfIncident>> /**  */
	not?: Array<Maybe<FilterOfIncident>> /**  */
}


/**  */
export type FilterDenormalizeOfResolutionCategoryByResolutionCategoryInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfChannelByChannelInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInIncident = {
	email?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfConcernedProjectByConcernedProjectInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserAffectedInIncident = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserMakeRequestInIncident = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfServiceLevelAgreementBySlaInIncident = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainImpactedAssetInIncident = {
	name?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInIncident = {
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentModelByIncidentModelInIncident = {
	modelName?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByParentInIncident = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfProblemByProblemInIncident = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type QueryContextOfIncident = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeIncident>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfIncidentQueriesResolver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
}


/**  */
export type FilterOfTicketAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	operatorId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusId?: Maybe<FilterGuidOperator> /**  */
	taskStatusId?: Maybe<FilterGuidOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	color?: Maybe<FilterStringOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	taskId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketAppointment>> /**  */
	or?: Array<Maybe<FilterOfTicketAppointment>> /**  */
	nor?: Array<Maybe<FilterOfTicketAppointment>> /**  */
	not?: Array<Maybe<FilterOfTicketAppointment>> /**  */
}


/**  */
export type QueryContextOfTicketAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketAppointment */
export type QuerySortTypeTicketAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	color?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketStatus = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	ticketStatusReasonIds?: Maybe<ArrayUuidTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketStatus>> /**  */
	or?: Array<Maybe<FilterOfTicketStatus>> /**  */
	nor?: Array<Maybe<FilterOfTicketStatus>> /**  */
	not?: Array<Maybe<FilterOfTicketStatus>> /**  */
}


/**  */
export type QueryContextOfTicketStatus = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketStatus>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfTicketLogEvents = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityTypeName?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	activity?: Maybe<FilterOfTranslatedField> /**  */
	createdBy?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	emailRecipient?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketLogEvents>> /**  */
	or?: Array<Maybe<FilterOfTicketLogEvents>> /**  */
	nor?: Array<Maybe<FilterOfTicketLogEvents>> /**  */
	not?: Array<Maybe<FilterOfTicketLogEvents>> /**  */
}


/**  */
export type QueryContextOfTicketLogEvents = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketLogEvents>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketLogEvents */
export type QuerySortTypeTicketLogEvents = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	entityTypeName?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	activity?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	createdBy?: Maybe<Sort> /** Représente le type de trie. */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Incident */
export type FieldUpdateOperatorOfIncident = {
	set?: Maybe<SetOperatorInputOfIncident> /**  */
	inc?: Maybe<IncOperatorInputIncident> /**  */
	push?: Maybe<PushUpdateOperatorInputIncident> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputIncident> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Incident */
export type SetOperatorInputOfIncident = {
	dynamicProperties?: Maybe<any> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident?: Maybe<number> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags: Array<string> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes?: Maybe<number> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber?: Maybe<number> /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	emailConnectorId?: Maybe<string> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	isParent?: Maybe<boolean> /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	appointmentConnector?: Maybe<SetOperatorInputOfAppointmentConnector> /**  */
	reminder?: Maybe<SetOperatorInputOfReminder> /**  */
	satisfaction?: Maybe<SetOperatorInputOfTicketSatisfaction> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AppointmentConnector */
export type SetOperatorInputOfAppointmentConnector = {
	appointmentId?: Maybe<string> /**  */
	calendarConnectorId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Reminder */
export type SetOperatorInputOfReminder = {
	userId?: Maybe<string> /**  */
	lastReminder?: Maybe<Date> /**  */
	increment?: Maybe<number> /**  */
	commentary?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketSatisfaction */
export type SetOperatorInputOfTicketSatisfaction = {
	created?: Maybe<Date> /**  */
	rating?: Maybe<number> /**  */
	commentary?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Incident */
export type IncOperatorInputIncident = {
	counterIncident?: Maybe<number> /**  */
	levelSupport?: Maybe<number> /**  */
	estimateMinutes?: Maybe<number> /**  */
	ticketNumber?: Maybe<number> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	reminder?: Maybe<IncOperatorInputReminder> /**  */
	satisfaction?: Maybe<IncOperatorInputTicketSatisfaction> /**  */
}


/** Représente les propriétés de type numérique de l'entité Reminder */
export type IncOperatorInputReminder = {
	increment?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketSatisfaction */
export type IncOperatorInputTicketSatisfaction = {
	rating?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Incident */
export type PushUpdateOperatorInputIncident = {
	dynamicProperties?: Maybe<any> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Incident */
export type PullUpdateOperatorInputIncident = {
	dynamicProperties?: Maybe<any> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type InStockMovement */
export type FieldUpdateOperatorOfInStockMovement = {
	set?: Maybe<SetOperatorInputOfInStockMovement> /**  */
	inc?: Maybe<IncOperatorInputInStockMovement> /**  */
	push?: Maybe<PushUpdateOperatorInputInStockMovement> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputInStockMovement> /**  */
}


/** Représente les méthodes disponible pour le type Intervention */
export type FieldUpdateOperatorOfIntervention = {
	set?: Maybe<SetOperatorInputOfIntervention> /**  */
	inc?: Maybe<IncOperatorInputIntervention> /**  */
	push?: Maybe<PushUpdateOperatorInputIntervention> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputIntervention> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Intervention */
export type SetOperatorInputOfIntervention = {
	fileIds: Array<string> /**  */
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	interventionModel?: Maybe<string> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	cost?: Maybe<number> /**  */
	autoCost?: Maybe<boolean> /**  */
	ticketId?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Intervention */
export type IncOperatorInputIntervention = {
	elaspedTimeHour?: Maybe<number> /**  */
	cost?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Intervention */
export type PushUpdateOperatorInputIntervention = {
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Intervention */
export type PullUpdateOperatorInputIntervention = {
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfLabelTemplate = {
	barcodeFormat?: Maybe<FilterBarcodeFormatOperator> /**  */
	availableFormats?: Maybe<ArrayStringTypeOperator> /**  */
	paperType?: Maybe<FilterStringOperator> /**  */
	availablePaperTypes?: Maybe<ArrayStringTypeOperator> /**  */
	includeLogo?: Maybe<FilterBooleanOperator> /**  */
	includeBarcode?: Maybe<FilterBooleanOperator> /**  */
	barcodeType?: Maybe<FilterStringOperator> /**  */
	visibleFields?: Maybe<ArrayStringTypeOperator> /**  */
	height?: Maybe<FilterDoubleOperator> /**  */
	width?: Maybe<FilterDoubleOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLabelTemplate>> /**  */
	or?: Array<Maybe<FilterOfLabelTemplate>> /**  */
	nor?: Array<Maybe<FilterOfLabelTemplate>> /**  */
	not?: Array<Maybe<FilterOfLabelTemplate>> /**  */
}


/**  */
export type FilterBarcodeFormatOperator = {
	eq?: Maybe<BarcodeFormat> /**  */
	ne?: Maybe<BarcodeFormat> /**  */
	in?: Array<Maybe<BarcodeFormat>> /**  */
	nin?: Array<Maybe<BarcodeFormat>> /**  */
}


/**  */
export type QueryContextOfLabelTemplate = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLabelTemplate>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LabelTemplate */
export type QuerySortTypeLabelTemplate = {
	barcodeFormat?: Maybe<Sort> /** Représente le type de trie. */
	availableFormats?: Maybe<Sort> /** Représente le type de trie. */
	paperType?: Maybe<Sort> /** Représente le type de trie. */
	availablePaperTypes?: Maybe<Sort> /** Représente le type de trie. */
	includeLogo?: Maybe<Sort> /** Représente le type de trie. */
	includeBarcode?: Maybe<Sort> /** Représente le type de trie. */
	barcodeType?: Maybe<Sort> /** Représente le type de trie. */
	visibleFields?: Maybe<Sort> /** Représente le type de trie. */
	height?: Maybe<Sort> /** Représente le type de trie. */
	width?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	file?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type LabelTemplate */
export type FieldUpdateOperatorOfLabelTemplate = {
	set?: Maybe<SetOperatorInputOfLabelTemplate> /**  */
	inc?: Maybe<IncOperatorInputLabelTemplate> /**  */
	push?: Maybe<PushUpdateOperatorInputLabelTemplate> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLabelTemplate> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LabelTemplate */
export type SetOperatorInputOfLabelTemplate = {
	barcodeFormat?: Maybe<BarcodeFormat> /**  */
	availableFormats: Array<string> /**  */
	paperType?: Maybe<string> /**  */
	availablePaperTypes: Array<string> /**  */
	includeLogo?: Maybe<boolean> /**  */
	includeBarcode?: Maybe<boolean> /**  */
	barcodeType?: Maybe<string> /**  */
	visibleFields: Array<string> /**  */
	height?: Maybe<number> /**  */
	width?: Maybe<number> /**  */
	fileId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité LabelTemplate */
export type IncOperatorInputLabelTemplate = {
	height?: Maybe<number> /**  */
	width?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité LabelTemplate */
export type PushUpdateOperatorInputLabelTemplate = {
	availableFormats: Array<string> /**  */
	availablePaperTypes: Array<string> /**  */
	visibleFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité LabelTemplate */
export type PullUpdateOperatorInputLabelTemplate = {
	availableFormats: Array<string> /**  */
	availablePaperTypes: Array<string> /**  */
	visibleFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfLayoutModel = {
	fileId?: Maybe<FilterGuidOperator> /**  */
	roleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	type?: Maybe<FilterLayoutModelCategoryOperator> /**  */
	entityReference?: Maybe<FilterOfEntityReference> /**  */
	version?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLayoutModel>> /**  */
	or?: Array<Maybe<FilterOfLayoutModel>> /**  */
	nor?: Array<Maybe<FilterOfLayoutModel>> /**  */
	not?: Array<Maybe<FilterOfLayoutModel>> /**  */
}


/**  */
export type FilterLayoutModelCategoryOperator = {
	eq?: Maybe<LayoutModelCategory> /**  */
	ne?: Maybe<LayoutModelCategory> /**  */
	in?: Array<Maybe<LayoutModelCategory>> /**  */
	nin?: Array<Maybe<LayoutModelCategory>> /**  */
}


/**  */
export type FilterOfEntityReference = {
	id?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfEntityReference>> /**  */
	or?: Array<Maybe<FilterOfEntityReference>> /**  */
	nor?: Array<Maybe<FilterOfEntityReference>> /**  */
	not?: Array<Maybe<FilterOfEntityReference>> /**  */
}


/**  */
export type QueryContextOfLayoutModel = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLayoutModel>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LayoutModel */
export type QuerySortTypeLayoutModel = {
	roleIds?: Maybe<Sort> /** Représente le type de trie. */
	userIds?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	entityReference?: Array<Maybe<QuerySortTypeEntityReference>> /**  */
	version?: Maybe<Sort> /** Représente le type de trie. */
	file?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité EntityReference */
export type QuerySortTypeEntityReference = {
	name?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfExcludeFilterUser = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeExcludeFilterUser>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ExcludeFilterUser */
export type QuerySortTypeExcludeFilterUser = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	filterExpression?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfLdapOrganizationalUnit = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLdapOrganizationalUnit>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfLifeCycleDestination = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLifeCycleDestination>> /**  */
	or?: Array<Maybe<FilterOfLifeCycleDestination>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycleDestination>> /**  */
	not?: Array<Maybe<FilterOfLifeCycleDestination>> /**  */
}


/**  */
export type QueryContextOfLifeCycleDestination = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLifeCycleDestination>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LifeCycleDestination */
export type QuerySortTypeLifeCycleDestination = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLifeCycleReason = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLifeCycleReason>> /**  */
	or?: Array<Maybe<FilterOfLifeCycleReason>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycleReason>> /**  */
	not?: Array<Maybe<FilterOfLifeCycleReason>> /**  */
}


/**  */
export type QueryContextOfLifeCycleReason = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLifeCycleReason>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LifeCycleReason */
export type QuerySortTypeLifeCycleReason = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLifeCycleTicket = {
	name?: Maybe<FilterStringOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	config?: Maybe<ArrayLifeCycleTicketConfigOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLifeCycleTicket>> /**  */
	or?: Array<Maybe<FilterOfLifeCycleTicket>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycleTicket>> /**  */
	not?: Array<Maybe<FilterOfLifeCycleTicket>> /**  */
}


/**  */
export type ArrayLifeCycleTicketConfigOperator = {
	elemMatch?: Maybe<FilterOfLifeCycleTicketConfig> /**  */
}


/**  */
export type FilterOfLifeCycleTicketConfig = {
	fromStatusId?: Maybe<FilterGuidOperator> /**  */
	toStatusId?: Maybe<ArrayUuidTypeOperator> /**  */
	and?: Array<Maybe<FilterOfLifeCycleTicketConfig>> /**  */
	or?: Array<Maybe<FilterOfLifeCycleTicketConfig>> /**  */
	nor?: Array<Maybe<FilterOfLifeCycleTicketConfig>> /**  */
	not?: Array<Maybe<FilterOfLifeCycleTicketConfig>> /**  */
}


/**  */
export type QueryContextOfLifeCycleTicket = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLifeCycleTicket>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LifeCycleTicket */
export type QuerySortTypeLifeCycleTicket = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	config?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLinkAsset = {
	sourceId?: Maybe<FilterGuidOperator> /**  */
	destinationId?: Maybe<FilterGuidOperator> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	isSystem?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLinkAsset>> /**  */
	or?: Array<Maybe<FilterOfLinkAsset>> /**  */
	nor?: Array<Maybe<FilterOfLinkAsset>> /**  */
	not?: Array<Maybe<FilterOfLinkAsset>> /**  */
}


/**  */
export type QueryContextOfLinkAsset = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLinkAsset>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LinkAsset */
export type QuerySortTypeLinkAsset = {
	type?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	isSystem?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfListDesigner = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	columns?: Maybe<FilterStringOperator> /**  */
	listType?: Maybe<FilterListDesignerTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfListDesigner>> /**  */
	or?: Array<Maybe<FilterOfListDesigner>> /**  */
	nor?: Array<Maybe<FilterOfListDesigner>> /**  */
	not?: Array<Maybe<FilterOfListDesigner>> /**  */
}


/**  */
export type FilterListDesignerTypeOperator = {
	eq?: Maybe<ListDesignerType> /**  */
	ne?: Maybe<ListDesignerType> /**  */
	in?: Array<Maybe<ListDesignerType>> /**  */
	nin?: Array<Maybe<ListDesignerType>> /**  */
}


/**  */
export type QueryContextOfListDesigner = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeListDesigner>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ListDesigner */
export type QuerySortTypeListDesigner = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	isDefault?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	columns?: Maybe<Sort> /** Représente le type de trie. */
	listType?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ListDesigner */
export type FieldUpdateOperatorOfListDesigner = {
	set?: Maybe<SetOperatorInputOfListDesigner> /**  */
	push?: Maybe<PushUpdateOperatorInputListDesigner> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputListDesigner> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ListDesigner */
export type SetOperatorInputOfListDesigner = {
	isDefault?: Maybe<boolean> /**  */
	qualification?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	columns?: Maybe<string> /**  */
	listType?: Maybe<ListDesignerType> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ListDesigner */
export type PushUpdateOperatorInputListDesigner = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ListDesigner */
export type PullUpdateOperatorInputListDesigner = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfLoanAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	loanId?: Maybe<FilterGuidOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLoanAppointment>> /**  */
	or?: Array<Maybe<FilterOfLoanAppointment>> /**  */
	nor?: Array<Maybe<FilterOfLoanAppointment>> /**  */
	not?: Array<Maybe<FilterOfLoanAppointment>> /**  */
}


/**  */
export type QueryContextOfLoanAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLoanAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LoanAppointment */
export type QuerySortTypeLoanAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfLoanStatus = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLoanStatus>> /**  */
	or?: Array<Maybe<FilterOfLoanStatus>> /**  */
	nor?: Array<Maybe<FilterOfLoanStatus>> /**  */
	not?: Array<Maybe<FilterOfLoanStatus>> /**  */
}


/**  */
export type QueryContextOfLoanStatus = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLoanStatus>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfLocationCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	icon?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	contentModel?: Maybe<ArrayByteTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLocationCategory>> /**  */
	or?: Array<Maybe<FilterOfLocationCategory>> /**  */
	nor?: Array<Maybe<FilterOfLocationCategory>> /**  */
	not?: Array<Maybe<FilterOfLocationCategory>> /**  */
}


/**  */
export type QueryContextOfLocationCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLocationCategory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité LocationCategory */
export type QuerySortTypeLocationCategory = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	icon?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type LocationCategory */
export type FieldUpdateOperatorOfLocationCategory = {
	set?: Maybe<SetOperatorInputOfLocationCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputLocationCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLocationCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LocationCategory */
export type SetOperatorInputOfLocationCategory = {
	description?: Maybe<string> /**  */
	icon?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité LocationCategory */
export type PushUpdateOperatorInputLocationCategory = {
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité LocationCategory */
export type PullUpdateOperatorInputLocationCategory = {
	contentModel: Array<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type LocationCharter */
export type FieldUpdateOperatorOfLocationCharter = {
	set?: Maybe<SetOperatorInputOfLocationCharter> /**  */
	push?: Maybe<PushUpdateOperatorInputLocationCharter> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLocationCharter> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LocationCharter */
export type SetOperatorInputOfLocationCharter = {
	description?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	locationCharterCategory?: Maybe<LocationCharterCategory> /**  */
	locationCharterCategoryForImport?: Maybe<string> /**  */
	titles: Array<string> /**  */
	companies: Array<string> /**  */
	cities: Array<string> /**  */
	departments: Array<string> /**  */
	offices: Array<string> /**  */
	ipAddressStart?: Maybe<string> /**  */
	ipAddressEnd?: Maybe<string> /**  */
	netbiosOrIpAddresses: Array<string> /**  */
	macAddresses: Array<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	user?: Maybe<boolean> /**  */
	asset?: Maybe<boolean> /**  */
	ldapDistinguishedName: Array<string> /**  */
	azureGroupObjectIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	locationCharterLdapAsset?: Maybe<SetOperatorInputOfLocationCharterLdap> /**  */
	locationCharterLdapUser?: Maybe<SetOperatorInputOfLocationCharterLdap> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LocationCharterLdap */
export type SetOperatorInputOfLocationCharterLdap = {
	location?: Maybe<boolean> /**  */
	organizationalUnit?: Maybe<boolean> /**  */
}


/** Représente les propriétés de type collection de l'entité LocationCharter */
export type PushUpdateOperatorInputLocationCharter = {
	titles: Array<string> /**  */
	companies: Array<string> /**  */
	cities: Array<string> /**  */
	departments: Array<string> /**  */
	offices: Array<string> /**  */
	netbiosOrIpAddresses: Array<string> /**  */
	macAddresses: Array<string> /**  */
	ldapDistinguishedName: Array<string> /**  */
	azureGroupObjectIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité LocationCharter */
export type PullUpdateOperatorInputLocationCharter = {
	titles: Array<string> /**  */
	companies: Array<string> /**  */
	cities: Array<string> /**  */
	departments: Array<string> /**  */
	offices: Array<string> /**  */
	netbiosOrIpAddresses: Array<string> /**  */
	macAddresses: Array<string> /**  */
	ldapDistinguishedName: Array<string> /**  */
	azureGroupObjectIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfMailSlurpBlackList = {
	email?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMailSlurpBlackList>> /**  */
	or?: Array<Maybe<FilterOfMailSlurpBlackList>> /**  */
	nor?: Array<Maybe<FilterOfMailSlurpBlackList>> /**  */
	not?: Array<Maybe<FilterOfMailSlurpBlackList>> /**  */
}


/**  */
export type QueryContextOfMailSlurpBlackList = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMailSlurpBlackList>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité MailSlurpBlackList */
export type QuerySortTypeMailSlurpBlackList = {
	email?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfMailSlurpWebhookTask = {
	inboxId?: Maybe<FilterGuidOperator> /**  */
	emailId?: Maybe<FilterGuidOperator> /**  */
	messageId?: Maybe<FilterStringOperator> /**  */
	webhookId?: Maybe<FilterStringOperator> /**  */
	eventName?: Maybe<FilterStringOperator> /**  */
	createdAt?: Maybe<FilterDateTimeOperator> /**  */
	to?: Maybe<ArrayStringTypeOperator> /**  */
	from?: Maybe<FilterStringOperator> /**  */
	cc?: Maybe<ArrayStringTypeOperator> /**  */
	bcc?: Maybe<ArrayStringTypeOperator> /**  */
	subject?: Maybe<FilterStringOperator> /**  */
	attachmentMetaDatas?: Maybe<ArrayMailSlurpAttachmentMetaDataOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMailSlurpWebhookTask>> /**  */
	or?: Array<Maybe<FilterOfMailSlurpWebhookTask>> /**  */
	nor?: Array<Maybe<FilterOfMailSlurpWebhookTask>> /**  */
	not?: Array<Maybe<FilterOfMailSlurpWebhookTask>> /**  */
}


/**  */
export type QueryContextOfMailSlurpWebhookTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMailSlurpWebhookTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité MailSlurpWebhookTask */
export type QuerySortTypeMailSlurpWebhookTask = {
	messageId?: Maybe<Sort> /** Représente le type de trie. */
	webhookId?: Maybe<Sort> /** Représente le type de trie. */
	eventName?: Maybe<Sort> /** Représente le type de trie. */
	createdAt?: Maybe<Sort> /** Représente le type de trie. */
	from?: Maybe<Sort> /** Représente le type de trie. */
	subject?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfMatrixPriority = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	qualification?: Maybe<ArrayStringTypeOperator> /**  */
	config?: Maybe<ArrayMatrixPriorityConfigOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfMatrixPriority>> /**  */
	or?: Array<Maybe<FilterOfMatrixPriority>> /**  */
	nor?: Array<Maybe<FilterOfMatrixPriority>> /**  */
	not?: Array<Maybe<FilterOfMatrixPriority>> /**  */
}


/**  */
export type ArrayMatrixPriorityConfigOperator = {
	elemMatch?: Maybe<FilterOfMatrixPriorityConfig> /**  */
}


/**  */
export type FilterOfMatrixPriorityConfig = {
	impactId?: Maybe<FilterGuidOperator> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	and?: Array<Maybe<FilterOfMatrixPriorityConfig>> /**  */
	or?: Array<Maybe<FilterOfMatrixPriorityConfig>> /**  */
	nor?: Array<Maybe<FilterOfMatrixPriorityConfig>> /**  */
	not?: Array<Maybe<FilterOfMatrixPriorityConfig>> /**  */
}


/**  */
export type QueryContextOfMatrixPriority = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMatrixPriority>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité MatrixPriority */
export type QuerySortTypeMatrixPriority = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	config?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type MatrixPriority */
export type FieldUpdateOperatorOfMatrixPriority = {
	set?: Maybe<SetOperatorInputOfMatrixPriority> /**  */
	push?: Maybe<PushUpdateOperatorInputMatrixPriority> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputMatrixPriority> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MatrixPriority */
export type SetOperatorInputOfMatrixPriority = {
	description?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	config: Array<MatrixPriorityConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type MatrixPriorityConfigInput = {
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité MatrixPriority */
export type PushUpdateOperatorInputMatrixPriority = {
	qualification: Array<string> /**  */
	config: Array<MatrixPriorityConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité MatrixPriority */
export type PullUpdateOperatorInputMatrixPriority = {
	qualification: Array<string> /**  */
	config: Array<MatrixPriorityConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type ModelRulesDesigner */
export type FieldUpdateOperatorOfModelRulesDesigner = {
	set?: Maybe<SetOperatorInputOfModelRulesDesigner> /**  */
	push?: Maybe<PushUpdateOperatorInputModelRulesDesigner> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputModelRulesDesigner> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ModelRulesDesigner */
export type SetOperatorInputOfModelRulesDesigner = {
	isDefault?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	description?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	rules: Array<ModelRuleInput> /**  */
	editRules: Array<ModelRuleInput> /**  */
	taskRules: Array<ModelRuleInput> /**  */
	taskEditRules: Array<ModelRuleInput> /**  */
	interventionRules: Array<ModelRuleInput> /**  */
	interventionEditRules: Array<ModelRuleInput> /**  */
	escalationRules: Array<ModelRuleInput> /**  */
	escalationEditRules: Array<ModelRuleInput> /**  */
	dynamicFieldRules: Array<ModelRuleInput> /**  */
	dynamicFieldEditRules: Array<ModelRuleInput> /**  */
	operatorIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type ModelRuleInput = {
	title?: Maybe<string> /**  */
	translatedTitle?: Maybe<TranslatedFieldInput> /**  */
	fieldName?: Maybe<string> /**  */
	editable: boolean /**  */
	visible: boolean /**  */
	required: boolean /**  */
	option?: Maybe<ModelRuleOptionInput> /**  */
	statusIds: Array<string> /**  */
}


/**  */
export type ModelRuleOptionInput = {
	allowEdit: boolean /**  */
	allowRequire: boolean /**  */
	allowStatus: boolean /**  */
}


/** Représente les propriétés de type collection de l'entité ModelRulesDesigner */
export type PushUpdateOperatorInputModelRulesDesigner = {
	qualification: Array<string> /**  */
	rules: Array<ModelRuleInput> /**  */
	editRules: Array<ModelRuleInput> /**  */
	taskRules: Array<ModelRuleInput> /**  */
	taskEditRules: Array<ModelRuleInput> /**  */
	interventionRules: Array<ModelRuleInput> /**  */
	interventionEditRules: Array<ModelRuleInput> /**  */
	escalationRules: Array<ModelRuleInput> /**  */
	escalationEditRules: Array<ModelRuleInput> /**  */
	dynamicFieldRules: Array<ModelRuleInput> /**  */
	dynamicFieldEditRules: Array<ModelRuleInput> /**  */
	operatorIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ModelRulesDesigner */
export type PullUpdateOperatorInputModelRulesDesigner = {
	qualification: Array<string> /**  */
	rules: Array<ModelRuleInput> /**  */
	editRules: Array<ModelRuleInput> /**  */
	taskRules: Array<ModelRuleInput> /**  */
	taskEditRules: Array<ModelRuleInput> /**  */
	interventionRules: Array<ModelRuleInput> /**  */
	interventionEditRules: Array<ModelRuleInput> /**  */
	escalationRules: Array<ModelRuleInput> /**  */
	escalationEditRules: Array<ModelRuleInput> /**  */
	dynamicFieldRules: Array<ModelRuleInput> /**  */
	dynamicFieldEditRules: Array<ModelRuleInput> /**  */
	operatorIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type NetworkCredential */
export type FieldUpdateOperatorOfNetworkCredential = {
	set?: Maybe<SetOperatorInputOfNetworkCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputNetworkCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputNetworkCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité NetworkCredential */
export type SetOperatorInputOfNetworkCredential = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité NetworkCredential */
export type PushUpdateOperatorInputNetworkCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité NetworkCredential */
export type PullUpdateOperatorInputNetworkCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Note */
export type FieldUpdateOperatorOfNote = {
	set?: Maybe<SetOperatorInputOfNote> /**  */
	push?: Maybe<PushUpdateOperatorInputNote> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputNote> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Note */
export type SetOperatorInputOfNote = {
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	externalAppName?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	context?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Note */
export type PushUpdateOperatorInputNote = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Note */
export type PullUpdateOperatorInputNote = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfObjectClassMapping = {
	name?: Maybe<FilterStringOperator> /**  */
	ldapObjectClassMappings?: Maybe<ArrayLdapObjectClassMappingOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfObjectClassMapping>> /**  */
	or?: Array<Maybe<FilterOfObjectClassMapping>> /**  */
	nor?: Array<Maybe<FilterOfObjectClassMapping>> /**  */
	not?: Array<Maybe<FilterOfObjectClassMapping>> /**  */
}


/**  */
export type ArrayLdapObjectClassMappingOperator = {
	elemMatch?: Maybe<FilterOfLdapObjectClassMapping> /**  */
}


/**  */
export type FilterOfLdapObjectClassMapping = {
	type?: Maybe<FilterLdapObjectClassTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLdapObjectClassMapping>> /**  */
	or?: Array<Maybe<FilterOfLdapObjectClassMapping>> /**  */
	nor?: Array<Maybe<FilterOfLdapObjectClassMapping>> /**  */
	not?: Array<Maybe<FilterOfLdapObjectClassMapping>> /**  */
}


/**  */
export type QueryContextOfObjectClassMapping = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeObjectClassMapping>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfOneEventLog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	type?: Maybe<FilterOneEventLogActionOperator> /**  */
	oneEventLogUser?: Maybe<FilterOfOneEventLogUser> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	entityDefaultName?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	isSystem?: Maybe<FilterBooleanOperator> /**  */
	isMigrating?: Maybe<FilterBooleanOperator> /**  */
	entityParentId?: Maybe<FilterGuidOperator> /**  */
	applicationPodName?: Maybe<FilterStringOperator> /**  */
	origin?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOneEventLog>> /**  */
	or?: Array<Maybe<FilterOfOneEventLog>> /**  */
	nor?: Array<Maybe<FilterOfOneEventLog>> /**  */
	not?: Array<Maybe<FilterOfOneEventLog>> /**  */
}


/**  */
export type FilterOneEventLogActionOperator = {
	eq?: Maybe<OneEventLogAction> /**  */
	ne?: Maybe<OneEventLogAction> /**  */
	in?: Array<Maybe<OneEventLogAction>> /**  */
	nin?: Array<Maybe<OneEventLogAction>> /**  */
}


/**  */
export type FilterOfOneEventLogUser = {
	subId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOneEventLogUser>> /**  */
	or?: Array<Maybe<FilterOfOneEventLogUser>> /**  */
	nor?: Array<Maybe<FilterOfOneEventLogUser>> /**  */
	not?: Array<Maybe<FilterOfOneEventLogUser>> /**  */
}


/**  */
export type QueryContextOfOneEventLog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOneEventLog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OneEventLog */
export type QuerySortTypeOneEventLog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	type?: Maybe<Sort> /** Représente le type de trie. */
	oneEventLogUser?: Array<Maybe<QuerySortTypeOneEventLogUser>> /**  */
	entityDefaultName?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	isSystem?: Maybe<Sort> /** Représente le type de trie. */
	isMigrating?: Maybe<Sort> /** Représente le type de trie. */
	applicationPodName?: Maybe<Sort> /** Représente le type de trie. */
	origin?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OneEventLogUser */
export type QuerySortTypeOneEventLogUser = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfOneSetting = {
	mailSlurpServiceSetting?: Maybe<FilterOfMailSlurpServiceSetting> /**  */
	scheduleTaskSettings?: Maybe<ArrayScheduleTaskSettingOperator> /**  */
	grantAccess?: Maybe<ArrayStringTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOneSetting>> /**  */
	or?: Array<Maybe<FilterOfOneSetting>> /**  */
	nor?: Array<Maybe<FilterOfOneSetting>> /**  */
	not?: Array<Maybe<FilterOfOneSetting>> /**  */
}


/**  */
export type FilterOfMailSlurpServiceSetting = {
	enableVerificationStatus?: Maybe<FilterBooleanOperator> /**  */
	blockService?: Maybe<FilterBooleanOperator> /**  */
	temporayBlockService?: Maybe<FilterDateTimeOperator> /**  */
	temporayDelayBlockService?: Maybe<FilterInt32Operator> /**  */
	preventDailyLimit?: Maybe<FilterInt32Operator> /**  */
	preventTotalLimit?: Maybe<FilterInt32Operator> /**  */
	rescheduleTime?: Maybe<FilterInt32Operator> /**  */
	emailSetting?: Maybe<FilterOfOneAlertEmailSetting> /**  */
	and?: Array<Maybe<FilterOfMailSlurpServiceSetting>> /**  */
	or?: Array<Maybe<FilterOfMailSlurpServiceSetting>> /**  */
	nor?: Array<Maybe<FilterOfMailSlurpServiceSetting>> /**  */
	not?: Array<Maybe<FilterOfMailSlurpServiceSetting>> /**  */
}


/**  */
export type FilterOfOneAlertEmailSetting = {
	sendAlertEmail?: Maybe<FilterBooleanOperator> /**  */
	alertEmails?: Maybe<ArrayStringTypeOperator> /**  */
	lastSend?: Maybe<FilterDateTimeOperator> /**  */
	and?: Array<Maybe<FilterOfOneAlertEmailSetting>> /**  */
	or?: Array<Maybe<FilterOfOneAlertEmailSetting>> /**  */
	nor?: Array<Maybe<FilterOfOneAlertEmailSetting>> /**  */
	not?: Array<Maybe<FilterOfOneAlertEmailSetting>> /**  */
}


/**  */
export type ArrayScheduleTaskSettingOperator = {
	elemMatch?: Maybe<FilterOfScheduleTaskSetting> /**  */
}


/**  */
export type FilterOfScheduleTaskSetting = {
	enable?: Maybe<FilterBooleanOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScheduleTaskSetting>> /**  */
	or?: Array<Maybe<FilterOfScheduleTaskSetting>> /**  */
	nor?: Array<Maybe<FilterOfScheduleTaskSetting>> /**  */
	not?: Array<Maybe<FilterOfScheduleTaskSetting>> /**  */
}


/**  */
export type QueryContextOfOneSetting = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOneSetting>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OneSetting */
export type QuerySortTypeOneSetting = {
	mailSlurpServiceSetting?: Array<Maybe<QuerySortTypeMailSlurpServiceSetting>> /**  */
	scheduleTaskSettings?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité MailSlurpServiceSetting */
export type QuerySortTypeMailSlurpServiceSetting = {
	enableVerificationStatus?: Maybe<Sort> /** Représente le type de trie. */
	blockService?: Maybe<Sort> /** Représente le type de trie. */
	temporayBlockService?: Maybe<Sort> /** Représente le type de trie. */
	temporayDelayBlockService?: Maybe<Sort> /** Représente le type de trie. */
	preventDailyLimit?: Maybe<Sort> /** Représente le type de trie. */
	preventTotalLimit?: Maybe<Sort> /** Représente le type de trie. */
	rescheduleTime?: Maybe<Sort> /** Représente le type de trie. */
	emailSetting?: Array<Maybe<QuerySortTypeOneAlertEmailSetting>> /**  */
}


/** Représente les propriétés triables de l'entité OneAlertEmailSetting */
export type QuerySortTypeOneAlertEmailSetting = {
	sendAlertEmail?: Maybe<Sort> /** Représente le type de trie. */
	lastSend?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type OperatorTeam */
export type FieldUpdateOperatorOfOperatorTeam = {
	set?: Maybe<SetOperatorInputOfOperatorTeam> /**  */
	push?: Maybe<PushUpdateOperatorInputOperatorTeam> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOperatorTeam> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OperatorTeam */
export type SetOperatorInputOfOperatorTeam = {
	emails: Array<string> /**  */
	userIds: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	default?: Maybe<boolean> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible?: Maybe<boolean> /**  */
	ticketCategoryAllowed?: Maybe<boolean> /**  */
	allowedOperatorTeamIds: Array<AllowedEntityInput> /**  */
	allowSelection?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type AllowedEntityInput = {
	entityId: string /**  */
	visible: boolean /**  */
	allowed: boolean /**  */
}


/** Représente les propriétés de type collection de l'entité OperatorTeam */
export type PushUpdateOperatorInputOperatorTeam = {
	emails: Array<string> /**  */
	userIds: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	allowedOperatorTeamIds: Array<AllowedEntityInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OperatorTeam */
export type PullUpdateOperatorInputOperatorTeam = {
	emails: Array<string> /**  */
	userIds: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	allowedOperatorTeamIds: Array<AllowedEntityInput> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfOrderBill = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrderBill>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type OrderBill */
export type FieldUpdateOperatorOfOrderBill = {
	set?: Maybe<SetOperatorInputOfOrderBill> /**  */
	inc?: Maybe<IncOperatorInputOrderBill> /**  */
	push?: Maybe<PushUpdateOperatorInputOrderBill> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrderBill> /**  */
}


/**  */
export type QueryContextOfOrder = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrder>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type Order */
export type FieldUpdateOperatorOfOrder = {
	set?: Maybe<SetOperatorInputOfOrder> /**  */
	push?: Maybe<PushUpdateOperatorInputOrder> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrder> /**  */
}


/**  */
export type FilterOfOrganizationalUnitCategory = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrganizationalUnitCategory>> /**  */
	or?: Array<Maybe<FilterOfOrganizationalUnitCategory>> /**  */
	nor?: Array<Maybe<FilterOfOrganizationalUnitCategory>> /**  */
	not?: Array<Maybe<FilterOfOrganizationalUnitCategory>> /**  */
}


/**  */
export type QueryContextOfOrganizationalUnitCategory = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrganizationalUnitCategory>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OrganizationalUnitCategory */
export type QuerySortTypeOrganizationalUnitCategory = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type OrganizationalUnitCategory */
export type FieldUpdateOperatorOfOrganizationalUnitCategory = {
	set?: Maybe<SetOperatorInputOfOrganizationalUnitCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputOrganizationalUnitCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrganizationalUnitCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OrganizationalUnitCategory */
export type SetOperatorInputOfOrganizationalUnitCategory = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité OrganizationalUnitCategory */
export type PushUpdateOperatorInputOrganizationalUnitCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OrganizationalUnitCategory */
export type PullUpdateOperatorInputOrganizationalUnitCategory = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfOrganization = {
	name?: Maybe<FilterStringOperator> /**  */
	address?: Maybe<FilterOfAddress> /**  */
	commercialInformation?: Maybe<FilterOfCommercialInformation> /**  */
	contact?: Maybe<FilterOfOrganizationContact> /**  */
	url?: Maybe<FilterStringOperator> /**  */
	blocked?: Maybe<FilterBooleanOperator> /**  */
	status?: Maybe<FilterOrganizationStatusOperator> /**  */
	expireAt?: Maybe<FilterDateTimeOperator> /**  */
	licenseOrganizationType?: Maybe<FilterLicenseTypeOperator> /**  */
	approvedDate?: Maybe<FilterDateTimeOperator> /**  */
	approvedUserId?: Maybe<FilterGuidOperator> /**  */
	tva?: Maybe<FilterStringOperator> /**  */
	lastConnected?: Maybe<FilterDateTimeOperator> /**  */
	lastUserConnected?: Maybe<FilterGuidOperator> /**  */
	siret?: Maybe<FilterStringOperator> /**  */
	licenseAgreement?: Maybe<FilterOfLicenseAgreement> /**  */
	license?: Maybe<ArrayByteTypeOperator> /**  */
	incrementTicket?: Maybe<FilterStringOperator> /**  */
	incrementTask?: Maybe<FilterStringOperator> /**  */
	incrementKnowledge?: Maybe<FilterStringOperator> /**  */
	countMailSend?: Maybe<FilterInt32Operator> /**  */
	countMailReceive?: Maybe<FilterInt32Operator> /**  */
	contentCustomization?: Maybe<FilterOfContentCustomization> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	lang?: Maybe<FilterStringOperator> /**  */
	idleTimer?: Maybe<FilterInt32Operator> /**  */
	lengthFiles?: Maybe<FilterDoubleOperator> /**  */
	maxFileSize?: Maybe<FilterDoubleOperator> /**  */
	allowedFileWithoutExtension?: Maybe<FilterBooleanOperator> /**  */
	allowedFileExtension?: Maybe<ArrayStringTypeOperator> /**  */
	forbiddenFileExtension?: Maybe<ArrayStringTypeOperator> /**  */
	fileIncidentId?: Maybe<FilterGuidOperator> /**  */
	urlImgIncident?: Maybe<FilterStringOperator> /**  */
	urlImgRequest?: Maybe<FilterStringOperator> /**  */
	fileRequestId?: Maybe<FilterGuidOperator> /**  */
	allowAnonymousViewFile?: Maybe<FilterBooleanOperator> /**  */
	marketPlaceToken?: Maybe<FilterStringOperator> /**  */
	marketPlaceSubscriptionId?: Maybe<FilterStringOperator> /**  */
	userIds?: Maybe<ArrayStringTypeOperator> /**  */
	verifaliaEmails?: Maybe<ArrayStringTypeOperator> /**  */
	dayOfEndWeek?: Maybe<FilterInt32Operator> /**  */
	lastTicketBacklog?: Maybe<FilterDateTimeOperator> /**  */
	cronTicketBacklog?: Maybe<FilterDateTimeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOrganization>> /**  */
	or?: Array<Maybe<FilterOfOrganization>> /**  */
	nor?: Array<Maybe<FilterOfOrganization>> /**  */
	not?: Array<Maybe<FilterOfOrganization>> /**  */
}


/**  */
export type FilterOfCommercialInformation = {
	users?: Maybe<FilterInt32Operator> /**  */
	computers?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfCommercialInformation>> /**  */
	or?: Array<Maybe<FilterOfCommercialInformation>> /**  */
	nor?: Array<Maybe<FilterOfCommercialInformation>> /**  */
	not?: Array<Maybe<FilterOfCommercialInformation>> /**  */
}


/**  */
export type FilterOfOrganizationContact = {
	lastName?: Maybe<FilterStringOperator> /**  */
	firstName?: Maybe<FilterStringOperator> /**  */
	email?: Maybe<FilterStringOperator> /**  */
	phone?: Maybe<FilterStringOperator> /**  */
	fax?: Maybe<FilterStringOperator> /**  */
	civility?: Maybe<FilterCivilityOperator> /**  */
	and?: Array<Maybe<FilterOfOrganizationContact>> /**  */
	or?: Array<Maybe<FilterOfOrganizationContact>> /**  */
	nor?: Array<Maybe<FilterOfOrganizationContact>> /**  */
	not?: Array<Maybe<FilterOfOrganizationContact>> /**  */
}


/**  */
export type FilterCivilityOperator = {
	eq?: Maybe<Civility> /**  */
	ne?: Maybe<Civility> /**  */
	in?: Array<Maybe<Civility>> /**  */
	nin?: Array<Maybe<Civility>> /**  */
}


/**  */
export type FilterOrganizationStatusOperator = {
	eq?: Maybe<OrganizationStatus> /**  */
	ne?: Maybe<OrganizationStatus> /**  */
	in?: Array<Maybe<OrganizationStatus>> /**  */
	nin?: Array<Maybe<OrganizationStatus>> /**  */
}


/**  */
export type FilterLicenseTypeOperator = {
	eq?: Maybe<LicenseTypeEnum> /**  */
	ne?: Maybe<LicenseTypeEnum> /**  */
	in?: Array<Maybe<LicenseTypeEnum>> /**  */
	nin?: Array<Maybe<LicenseTypeEnum>> /**  */
}


/**  */
export type FilterOfLicenseAgreement = {
	accept?: Maybe<FilterBooleanOperator> /**  */
	acceptedDate?: Maybe<FilterDateTimeOperator> /**  */
	acceptedSubId?: Maybe<FilterGuidOperator> /**  */
	acceptedUserName?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfLicenseAgreement>> /**  */
	or?: Array<Maybe<FilterOfLicenseAgreement>> /**  */
	nor?: Array<Maybe<FilterOfLicenseAgreement>> /**  */
	not?: Array<Maybe<FilterOfLicenseAgreement>> /**  */
}


/**  */
export type FilterOfContentCustomization = {
	helpMe?: Maybe<FilterOfThemeColor> /**  */
	helpMeAreaList?: Maybe<FilterOfAreaListCustomization> /**  */
	and?: Array<Maybe<FilterOfContentCustomization>> /**  */
	or?: Array<Maybe<FilterOfContentCustomization>> /**  */
	nor?: Array<Maybe<FilterOfContentCustomization>> /**  */
	not?: Array<Maybe<FilterOfContentCustomization>> /**  */
}


/**  */
export type FilterOfThemeColor = {
	littleText?: Maybe<FilterOfTranslatedField> /**  */
	boldTexte?: Maybe<FilterOfTranslatedField> /**  */
	satisfactionLabel?: Maybe<FilterOfTranslatedField> /**  */
	accessTicketsObserverLabel?: Maybe<FilterOfTranslatedField> /**  */
	primaryColor?: Maybe<FilterStringOperator> /**  */
	secondaryColor?: Maybe<FilterStringOperator> /**  */
	headBandColor?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	timerRefresh?: Maybe<FilterInt32Operator> /**  */
	timerRefreshEnable?: Maybe<FilterBooleanOperator> /**  */
	timerReminder?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfThemeColor>> /**  */
	or?: Array<Maybe<FilterOfThemeColor>> /**  */
	nor?: Array<Maybe<FilterOfThemeColor>> /**  */
	not?: Array<Maybe<FilterOfThemeColor>> /**  */
}


/**  */
export type FilterOfAreaListCustomization = {
	messageNumberEnable?: Maybe<FilterBooleanOperator> /**  */
	messageNumber?: Maybe<FilterInt32Operator> /**  */
	articleKbNumberEnable?: Maybe<FilterBooleanOperator> /**  */
	articleKbNumber?: Maybe<FilterInt32Operator> /**  */
	newsNumberEnable?: Maybe<FilterBooleanOperator> /**  */
	newsNumber?: Maybe<FilterInt32Operator> /**  */
	and?: Array<Maybe<FilterOfAreaListCustomization>> /**  */
	or?: Array<Maybe<FilterOfAreaListCustomization>> /**  */
	nor?: Array<Maybe<FilterOfAreaListCustomization>> /**  */
	not?: Array<Maybe<FilterOfAreaListCustomization>> /**  */
}


/**  */
export type QueryContextOfOrganization = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrganization>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Organization */
export type QuerySortTypeOrganization = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	address?: Array<Maybe<QuerySortTypeAddress>> /**  */
	commercialInformation?: Array<Maybe<QuerySortTypeCommercialInformation>> /**  */
	contact?: Array<Maybe<QuerySortTypeOrganizationContact>> /**  */
	url?: Maybe<Sort> /** Représente le type de trie. */
	blocked?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	expireAt?: Maybe<Sort> /** Représente le type de trie. */
	licenseOrganizationType?: Maybe<Sort> /** Représente le type de trie. */
	approvedDate?: Maybe<Sort> /** Représente le type de trie. */
	tva?: Maybe<Sort> /** Représente le type de trie. */
	lastConnected?: Maybe<Sort> /** Représente le type de trie. */
	siret?: Maybe<Sort> /** Représente le type de trie. */
	licenseAgreement?: Array<Maybe<QuerySortTypeLicenseAgreement>> /**  */
	incrementTicket?: Maybe<Sort> /** Représente le type de trie. */
	incrementTask?: Maybe<Sort> /** Représente le type de trie. */
	incrementKnowledge?: Maybe<Sort> /** Représente le type de trie. */
	countMailSend?: Maybe<Sort> /** Représente le type de trie. */
	countMailReceive?: Maybe<Sort> /** Représente le type de trie. */
	contentCustomization?: Array<Maybe<QuerySortTypeContentCustomization>> /**  */
	file?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	lang?: Maybe<Sort> /** Représente le type de trie. */
	idleTimer?: Maybe<Sort> /** Représente le type de trie. */
	lengthFiles?: Maybe<Sort> /** Représente le type de trie. */
	maxFileSize?: Maybe<Sort> /** Représente le type de trie. */
	allowedFileWithoutExtension?: Maybe<Sort> /** Représente le type de trie. */
	fileIncident?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	urlImgIncident?: Maybe<Sort> /** Représente le type de trie. */
	fileRequest?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	urlImgRequest?: Maybe<Sort> /** Représente le type de trie. */
	allowAnonymousViewFile?: Maybe<Sort> /** Représente le type de trie. */
	marketPlaceToken?: Maybe<Sort> /** Représente le type de trie. */
	marketPlaceSubscriptionId?: Maybe<Sort> /** Représente le type de trie. */
	dayOfEndWeek?: Maybe<Sort> /** Représente le type de trie. */
	lastTicketBacklog?: Maybe<Sort> /** Représente le type de trie. */
	cronTicketBacklog?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité CommercialInformation */
export type QuerySortTypeCommercialInformation = {
	users?: Maybe<Sort> /** Représente le type de trie. */
	computers?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité OrganizationContact */
export type QuerySortTypeOrganizationContact = {
	lastName?: Maybe<Sort> /** Représente le type de trie. */
	firstName?: Maybe<Sort> /** Représente le type de trie. */
	email?: Maybe<Sort> /** Représente le type de trie. */
	phone?: Maybe<Sort> /** Représente le type de trie. */
	fax?: Maybe<Sort> /** Représente le type de trie. */
	civility?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité LicenseAgreement */
export type QuerySortTypeLicenseAgreement = {
	accept?: Maybe<Sort> /** Représente le type de trie. */
	acceptedDate?: Maybe<Sort> /** Représente le type de trie. */
	acceptedUserName?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité ContentCustomization */
export type QuerySortTypeContentCustomization = {
	helpMe?: Array<Maybe<QuerySortTypeThemeColor>> /**  */
	helpMeAreaList?: Array<Maybe<QuerySortTypeAreaListCustomization>> /**  */
}


/** Représente les propriétés triables de l'entité ThemeColor */
export type QuerySortTypeThemeColor = {
	littleText?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	boldTexte?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	satisfactionLabel?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	accessTicketsObserverLabel?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	primaryColor?: Maybe<Sort> /** Représente le type de trie. */
	secondaryColor?: Maybe<Sort> /** Représente le type de trie. */
	headBandColor?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	timerRefresh?: Maybe<Sort> /** Représente le type de trie. */
	timerRefreshEnable?: Maybe<Sort> /** Représente le type de trie. */
	timerReminder?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité AreaListCustomization */
export type QuerySortTypeAreaListCustomization = {
	messageNumberEnable?: Maybe<Sort> /** Représente le type de trie. */
	messageNumber?: Maybe<Sort> /** Représente le type de trie. */
	articleKbNumberEnable?: Maybe<Sort> /** Représente le type de trie. */
	articleKbNumber?: Maybe<Sort> /** Représente le type de trie. */
	newsNumberEnable?: Maybe<Sort> /** Représente le type de trie. */
	newsNumber?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfContentCustomization = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeContentCustomization>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfVerifaliaEmailCheck = {
	email?: Maybe<FilterStringOperator> /**  */
	expireUtc?: Maybe<FilterDateTimeOperator> /**  */
	classification?: Maybe<FilterOfValidationEntryClassification> /**  */
	status?: Maybe<FilterInt32Operator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVerifaliaEmailCheck>> /**  */
	or?: Array<Maybe<FilterOfVerifaliaEmailCheck>> /**  */
	nor?: Array<Maybe<FilterOfVerifaliaEmailCheck>> /**  */
	not?: Array<Maybe<FilterOfVerifaliaEmailCheck>> /**  */
}


/**  */
export type FilterOfValidationEntryClassification = {
	name?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfValidationEntryClassification>> /**  */
	or?: Array<Maybe<FilterOfValidationEntryClassification>> /**  */
	nor?: Array<Maybe<FilterOfValidationEntryClassification>> /**  */
	not?: Array<Maybe<FilterOfValidationEntryClassification>> /**  */
}


/**  */
export type QueryContextOfVerifaliaEmailCheck = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeVerifaliaEmailCheck>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité VerifaliaEmailCheck */
export type QuerySortTypeVerifaliaEmailCheck = {
	email?: Maybe<Sort> /** Représente le type de trie. */
	expireUtc?: Maybe<Sort> /** Représente le type de trie. */
	classification?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextInput = {
	skip?: Maybe<number> /**  */
	limit?: Maybe<number> /**  */
	sort?: Array<Maybe<QuerySortInput>> /**  */
	hasTotalCount: boolean /**  */
	selector?: Maybe<string> /**  */
	select?: Array<Maybe<string>> /**  */
	unactivedFilters?: Array<Maybe<string>> /**  */
	hasSelect: boolean /**  */
	searchValue?: Maybe<string> /**  */
	searchPath?: Array<Maybe<string>> /**  */
	useAtlasSearch: boolean /**  */
	hintMongo?: Maybe<string> /**  */
	useCollationCount: boolean /**  */
}


/**  */
export type QuerySortInput = {
	selector?: Maybe<string> /**  */
	desc: boolean /**  */
	isExpanded: boolean /**  */
}


/** Représente les méthodes disponible pour le type Organization */
export type FieldUpdateOperatorOfOrganization = {
	set?: Maybe<SetOperatorInputOfOrganization> /**  */
	inc?: Maybe<IncOperatorInputOrganization> /**  */
	push?: Maybe<PushUpdateOperatorInputOrganization> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrganization> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Organization */
export type SetOperatorInputOfOrganization = {
	name?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	blocked?: Maybe<boolean> /**  */
	status?: Maybe<OrganizationStatus> /**  */
	expireAt?: Maybe<Date> /**  */
	licenseOrganizationType?: Maybe<LicenseTypeEnum> /**  */
	approvedDate?: Maybe<Date> /**  */
	approvedUserId?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	lastConnected?: Maybe<Date> /**  */
	lastUserConnected?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	license: Array<number> /**  */
	incrementTicket?: Maybe<string> /**  */
	incrementTask?: Maybe<string> /**  */
	incrementKnowledge?: Maybe<string> /**  */
	countMailSend?: Maybe<number> /**  */
	countMailReceive?: Maybe<number> /**  */
	fileId?: Maybe<string> /**  */
	lang?: Maybe<string> /**  */
	idleTimer?: Maybe<number> /**  */
	lengthFiles?: Maybe<number> /**  */
	maxFileSize?: Maybe<number> /**  */
	allowedFileWithoutExtension?: Maybe<boolean> /**  */
	allowedFileExtension: Array<string> /**  */
	forbiddenFileExtension: Array<string> /**  */
	fileIncidentId?: Maybe<string> /**  */
	urlImgIncident?: Maybe<string> /**  */
	urlImgRequest?: Maybe<string> /**  */
	fileRequestId?: Maybe<string> /**  */
	allowAnonymousViewFile?: Maybe<boolean> /**  */
	marketPlaceToken?: Maybe<string> /**  */
	marketPlaceSubscriptionId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	verifaliaEmails: Array<string> /**  */
	dayOfEndWeek?: Maybe<number> /**  */
	lastTicketBacklog?: Maybe<Date> /**  */
	cronTicketBacklog?: Maybe<Date> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
	commercialInformation?: Maybe<SetOperatorInputOfCommercialInformation> /**  */
	contact?: Maybe<SetOperatorInputOfOrganizationContact> /**  */
	licenseAgreement?: Maybe<SetOperatorInputOfLicenseAgreement> /**  */
	contentCustomization?: Maybe<SetOperatorInputOfContentCustomization> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CommercialInformation */
export type SetOperatorInputOfCommercialInformation = {
	users?: Maybe<number> /**  */
	computers?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OrganizationContact */
export type SetOperatorInputOfOrganizationContact = {
	lastName?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	civility?: Maybe<Civility> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LicenseAgreement */
export type SetOperatorInputOfLicenseAgreement = {
	accept?: Maybe<boolean> /**  */
	acceptedDate?: Maybe<Date> /**  */
	acceptedSubId?: Maybe<string> /**  */
	acceptedUserName?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ContentCustomization */
export type SetOperatorInputOfContentCustomization = {
	helpMe?: Maybe<SetOperatorInputOfThemeColor> /**  */
	helpMeAreaList?: Maybe<SetOperatorInputOfAreaListCustomization> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ThemeColor */
export type SetOperatorInputOfThemeColor = {
	primaryColor?: Maybe<string> /**  */
	secondaryColor?: Maybe<string> /**  */
	headBandColor?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	timerRefresh?: Maybe<number> /**  */
	timerRefreshEnable?: Maybe<boolean> /**  */
	timerReminder?: Maybe<number> /**  */
	littleText?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	boldTexte?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	satisfactionLabel?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	accessTicketsObserverLabel?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AreaListCustomization */
export type SetOperatorInputOfAreaListCustomization = {
	messageNumberEnable?: Maybe<boolean> /**  */
	messageNumber?: Maybe<number> /**  */
	articleKbNumberEnable?: Maybe<boolean> /**  */
	articleKbNumber?: Maybe<number> /**  */
	newsNumberEnable?: Maybe<boolean> /**  */
	newsNumber?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité Organization */
export type IncOperatorInputOrganization = {
	countMailSend?: Maybe<number> /**  */
	countMailReceive?: Maybe<number> /**  */
	idleTimer?: Maybe<number> /**  */
	lengthFiles?: Maybe<number> /**  */
	maxFileSize?: Maybe<number> /**  */
	dayOfEndWeek?: Maybe<number> /**  */
	commercialInformation?: Maybe<IncOperatorInputCommercialInformation> /**  */
	contentCustomization?: Maybe<IncOperatorInputContentCustomization> /**  */
}


/** Représente les propriétés de type numérique de l'entité CommercialInformation */
export type IncOperatorInputCommercialInformation = {
	users?: Maybe<number> /**  */
	computers?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité ContentCustomization */
export type IncOperatorInputContentCustomization = {
	helpMe?: Maybe<IncOperatorInputThemeColor> /**  */
	helpMeAreaList?: Maybe<IncOperatorInputAreaListCustomization> /**  */
}


/** Représente les propriétés de type numérique de l'entité ThemeColor */
export type IncOperatorInputThemeColor = {
	timerRefresh?: Maybe<number> /**  */
	timerReminder?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité AreaListCustomization */
export type IncOperatorInputAreaListCustomization = {
	messageNumber?: Maybe<number> /**  */
	articleKbNumber?: Maybe<number> /**  */
	newsNumber?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Organization */
export type PushUpdateOperatorInputOrganization = {
	license: Array<number> /**  */
	allowedFileExtension: Array<string> /**  */
	forbiddenFileExtension: Array<string> /**  */
	userIds: Array<string> /**  */
	verifaliaEmails: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Organization */
export type PullUpdateOperatorInputOrganization = {
	license: Array<number> /**  */
	allowedFileExtension: Array<string> /**  */
	forbiddenFileExtension: Array<string> /**  */
	userIds: Array<string> /**  */
	verifaliaEmails: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Origin */
export type FieldUpdateOperatorOfOrigin = {
	set?: Maybe<SetOperatorInputOfOrigin> /**  */
	push?: Maybe<PushUpdateOperatorInputOrigin> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrigin> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Origin */
export type SetOperatorInputOfOrigin = {
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité Origin */
export type PushUpdateOperatorInputOrigin = {
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Origin */
export type PullUpdateOperatorInputOrigin = {
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfOutStock = {
	deliveryId?: Maybe<FilterGuidOperator> /**  */
	outStockMovementId?: Maybe<FilterGuidOperator> /**  */
	outStockMovementName?: Maybe<FilterStringOperator> /**  */
	deliveryName?: Maybe<FilterStringOperator> /**  */
	recipientId?: Maybe<FilterGuidOperator> /**  */
	carts?: Maybe<ArrayOutCartOperator> /**  */
	reasonId?: Maybe<FilterGuidOperator> /**  */
	reason?: Maybe<FilterStringOperator> /**  */
	date?: Maybe<FilterDateTimeOperator> /**  */
	outById?: Maybe<FilterGuidOperator> /**  */
	outBy?: Maybe<FilterOfUser> /**  */
	storageUnitId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfOutStock>> /**  */
	or?: Array<Maybe<FilterOfOutStock>> /**  */
	nor?: Array<Maybe<FilterOfOutStock>> /**  */
	not?: Array<Maybe<FilterOfOutStock>> /**  */
}


/**  */
export type QueryContextOfOutStock = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOutStock>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OutStock */
export type QuerySortTypeOutStock = {
	outStockMovementName?: Maybe<Sort> /** Représente le type de trie. */
	deliveryName?: Maybe<Sort> /** Représente le type de trie. */
	carts?: Maybe<Sort> /** Représente le type de trie. */
	reason?: Maybe<Sort> /** Représente le type de trie. */
	date?: Maybe<Sort> /** Représente le type de trie. */
	outBy?: Array<Maybe<QuerySortTypeUser>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type OutStockMovement */
export type FieldUpdateOperatorOfOutStockMovement = {
	set?: Maybe<SetOperatorInputOfOutStockMovement> /**  */
	push?: Maybe<PushUpdateOperatorInputOutStockMovement> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOutStockMovement> /**  */
}


/**  */
export type FilterOfPat = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	token?: Maybe<FilterStringOperator> /**  */
	tokenCache?: Maybe<FilterStringOperator> /**  */
	expirationDate?: Maybe<FilterDateTimeOperator> /**  */
	activationDate?: Maybe<FilterDateTimeOperator> /**  */
	permissions?: Maybe<ArrayStringTypeOperator> /**  */
	clientIds?: Maybe<ArrayStringTypeOperator> /**  */
	fullAccess?: Maybe<FilterBooleanOperator> /**  */
	permissionScopes?: Maybe<ArrayPermissionScopeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	mustBeCache?: Maybe<FilterBooleanOperator> /**  */
	lastTokenUpdated?: Maybe<FilterDateTimeOperator> /**  */
	lastTokenUpdatedBy?: Maybe<FilterStringOperator> /**  */
	lastTokenExpiration?: Maybe<FilterDateTimeOperator> /**  */
	tokenCount?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPat>> /**  */
	or?: Array<Maybe<FilterOfPat>> /**  */
	nor?: Array<Maybe<FilterOfPat>> /**  */
	not?: Array<Maybe<FilterOfPat>> /**  */
}


/**  */
export type QueryContextOfPat = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePat>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Pat */
export type QuerySortTypePat = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	token?: Maybe<Sort> /** Représente le type de trie. */
	tokenCache?: Maybe<Sort> /** Représente le type de trie. */
	expirationDate?: Maybe<Sort> /** Représente le type de trie. */
	activationDate?: Maybe<Sort> /** Représente le type de trie. */
	fullAccess?: Maybe<Sort> /** Représente le type de trie. */
	permissionScopes?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	mustBeCache?: Maybe<Sort> /** Représente le type de trie. */
	lastTokenUpdated?: Maybe<Sort> /** Représente le type de trie. */
	lastTokenUpdatedBy?: Maybe<Sort> /** Représente le type de trie. */
	lastTokenExpiration?: Maybe<Sort> /** Représente le type de trie. */
	tokenCount?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfPnpEntityBase = {
	className?: Maybe<FilterStringOperator> /**  */
	classGuid?: Maybe<FilterStringOperator> /**  */
	base64Ico?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPnpEntityBase>> /**  */
	or?: Array<Maybe<FilterOfPnpEntityBase>> /**  */
	nor?: Array<Maybe<FilterOfPnpEntityBase>> /**  */
	not?: Array<Maybe<FilterOfPnpEntityBase>> /**  */
}


/**  */
export type QueryContextOfPnpEntityBase = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePnpEntityBase>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PnpEntityBase */
export type QuerySortTypePnpEntityBase = {
	className?: Maybe<Sort> /** Représente le type de trie. */
	classGuid?: Maybe<Sort> /** Représente le type de trie. */
	base64Ico?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfPnpVendor = {
	manufacturer?: Maybe<FilterStringOperator> /**  */
	pnpId?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPnpVendor>> /**  */
	or?: Array<Maybe<FilterOfPnpVendor>> /**  */
	nor?: Array<Maybe<FilterOfPnpVendor>> /**  */
	not?: Array<Maybe<FilterOfPnpVendor>> /**  */
}


/**  */
export type QueryContextOfPnpVendor = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePnpVendor>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité PnpVendor */
export type QuerySortTypePnpVendor = {
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	pnpId?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Priority */
export type FieldUpdateOperatorOfPriority = {
	set?: Maybe<SetOperatorInputOfPriority> /**  */
	inc?: Maybe<IncOperatorInputPriority> /**  */
	push?: Maybe<PushUpdateOperatorInputPriority> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputPriority> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Priority */
export type SetOperatorInputOfPriority = {
	color?: Maybe<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Priority */
export type IncOperatorInputPriority = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Priority */
export type PushUpdateOperatorInputPriority = {
	qualification: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Priority */
export type PullUpdateOperatorInputPriority = {
	qualification: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfPrivilege = {
	key?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	taskEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	actionEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	authEditEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	notificationEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfPrivilege>> /**  */
	or?: Array<Maybe<FilterOfPrivilege>> /**  */
	nor?: Array<Maybe<FilterOfPrivilege>> /**  */
	not?: Array<Maybe<FilterOfPrivilege>> /**  */
}


/**  */
export type ArrayPrivilegeEntryOperator = {
	elemMatch?: Maybe<FilterOfPrivilegeEntry> /**  */
}


/**  */
export type FilterOfPrivilegeEntry = {
	title?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	and?: Array<Maybe<FilterOfPrivilegeEntry>> /**  */
	or?: Array<Maybe<FilterOfPrivilegeEntry>> /**  */
	nor?: Array<Maybe<FilterOfPrivilegeEntry>> /**  */
	not?: Array<Maybe<FilterOfPrivilegeEntry>> /**  */
}


/**  */
export type QueryContextOfPrivilege = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypePrivilege>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type Privilege */
export type FieldUpdateOperatorOfPrivilege = {
	set?: Maybe<SetOperatorInputOfPrivilege> /**  */
	push?: Maybe<PushUpdateOperatorInputPrivilege> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputPrivilege> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Privilege */
export type SetOperatorInputOfPrivilege = {
	key?: Maybe<string> /**  */
	isDefault?: Maybe<boolean> /**  */
	defaultName?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	ticketEntries: Array<PrivilegeEntryInput> /**  */
	taskEntries: Array<PrivilegeEntryInput> /**  */
	actionEntries: Array<PrivilegeEntryInput> /**  */
	authEditEntries: Array<PrivilegeEntryInput> /**  */
	notificationEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type PrivilegeEntryInput = {
	title?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	enable: boolean /**  */
}


/** Représente les propriétés de type collection de l'entité Privilege */
export type PushUpdateOperatorInputPrivilege = {
	userIds: Array<string> /**  */
	ticketEntries: Array<PrivilegeEntryInput> /**  */
	taskEntries: Array<PrivilegeEntryInput> /**  */
	actionEntries: Array<PrivilegeEntryInput> /**  */
	authEditEntries: Array<PrivilegeEntryInput> /**  */
	notificationEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Privilege */
export type PullUpdateOperatorInputPrivilege = {
	userIds: Array<string> /**  */
	ticketEntries: Array<PrivilegeEntryInput> /**  */
	taskEntries: Array<PrivilegeEntryInput> /**  */
	actionEntries: Array<PrivilegeEntryInput> /**  */
	authEditEntries: Array<PrivilegeEntryInput> /**  */
	notificationEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type Problem */
export type FieldUpdateOperatorOfProblem = {
	set?: Maybe<SetOperatorInputOfProblem> /**  */
	inc?: Maybe<IncOperatorInputProblem> /**  */
	push?: Maybe<PushUpdateOperatorInputProblem> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputProblem> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Problem */
export type SetOperatorInputOfProblem = {
	originId?: Maybe<string> /**  */
	cause?: Maybe<string> /**  */
	workaround?: Maybe<string> /**  */
	solution?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident?: Maybe<number> /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags: Array<string> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes?: Maybe<number> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber?: Maybe<number> /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	emailConnectorId?: Maybe<string> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	isParent?: Maybe<boolean> /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	appointmentConnector?: Maybe<SetOperatorInputOfAppointmentConnector> /**  */
	reminder?: Maybe<SetOperatorInputOfReminder> /**  */
	satisfaction?: Maybe<SetOperatorInputOfTicketSatisfaction> /**  */
}


/** Représente les propriétés de type numérique de l'entité Problem */
export type IncOperatorInputProblem = {
	counterIncident?: Maybe<number> /**  */
	levelSupport?: Maybe<number> /**  */
	estimateMinutes?: Maybe<number> /**  */
	ticketNumber?: Maybe<number> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	reminder?: Maybe<IncOperatorInputReminder> /**  */
	satisfaction?: Maybe<IncOperatorInputTicketSatisfaction> /**  */
}


/** Représente les propriétés de type collection de l'entité Problem */
export type PushUpdateOperatorInputProblem = {
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Problem */
export type PullUpdateOperatorInputProblem = {
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfQueryBuilderAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	queryBuilderAppointmentTaskId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfQueryBuilderAppointment>> /**  */
	or?: Array<Maybe<FilterOfQueryBuilderAppointment>> /**  */
	nor?: Array<Maybe<FilterOfQueryBuilderAppointment>> /**  */
	not?: Array<Maybe<FilterOfQueryBuilderAppointment>> /**  */
}


/**  */
export type QueryContextOfQueryBuilderAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeQueryBuilderAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité QueryBuilderAppointment */
export type QuerySortTypeQueryBuilderAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	queryBuilderIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfQueryBuilderAppointmentTask = {
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	timeZone?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfQueryBuilderAppointmentTask>> /**  */
	or?: Array<Maybe<FilterOfQueryBuilderAppointmentTask>> /**  */
	nor?: Array<Maybe<FilterOfQueryBuilderAppointmentTask>> /**  */
	not?: Array<Maybe<FilterOfQueryBuilderAppointmentTask>> /**  */
}


/**  */
export type QueryContextOfQueryBuilderAppointmentTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeQueryBuilderAppointmentTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité QueryBuilderAppointmentTask */
export type QuerySortTypeQueryBuilderAppointmentTask = {
	queryBuilderIds?: Maybe<Sort> /** Représente le type de trie. */
	timeZone?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfQueryBuilderQueriesResolver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
}


/**  */
export type FilterOfRegistry = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	value?: Maybe<FilterStringOperator> /**  */
	regeditPath?: Maybe<FilterStringOperator> /**  */
	manualData?: Maybe<FilterBooleanOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	history?: Maybe<FilterBooleanOperator> /**  */
	assetId?: Maybe<FilterGuidOperator> /**  */
	asset?: Maybe<FilterDenormalizeOfAssetByAssetInRegistry> /**  */
	deleted?: Maybe<FilterBooleanOperator> /**  */
	displayKey?: Maybe<FilterStringOperator> /**  */
	inheritSecurityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyId?: Maybe<FilterGuidOperator> /**  */
	scanRegistryKey?: Maybe<FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInRegistry> /**  */
	and?: Array<Maybe<FilterOfRegistry>> /**  */
	or?: Array<Maybe<FilterOfRegistry>> /**  */
	nor?: Array<Maybe<FilterOfRegistry>> /**  */
	not?: Array<Maybe<FilterOfRegistry>> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByAssetInRegistry = {
	assetCategoryId?: Maybe<FilterGuidOperator> /**  */
	assetCategory?: Maybe<FilterDenormalizeOfAssetCategoryByAssetCategoryInAsset> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	manufacturerId?: Maybe<FilterGuidOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	modelId?: Maybe<FilterGuidOperator> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
	macAddress?: Maybe<FilterStringOperator> /**  */
	netBios?: Maybe<FilterStringOperator> /**  */
	ipAddress?: Maybe<FilterStringOperator> /**  */
	lastSuccessAudit?: Maybe<FilterDateTimeOperator> /**  */
	ownerId?: Maybe<FilterGuidOperator> /**  */
	owner?: Maybe<FilterDenormalizeOfUserByOwnerInAsset> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
}


/**  */
export type FilterDenormalizeOfScanRegistryKeyByScanRegistryKeyInRegistry = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfRegistry = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeRegistry>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Registry */
export type QuerySortTypeRegistry = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	regeditPath?: Maybe<Sort> /** Représente le type de trie. */
	manualData?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	history?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	deleted?: Maybe<Sort> /** Représente le type de trie. */
	displayKey?: Maybe<Sort> /** Représente le type de trie. */
	inheritSecurityGroups?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKey?: Array<Maybe<QuerySortTypeScanRegistryKey>> /**  */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfReportCache = {
	cacheId?: Maybe<FilterStringOperator> /**  */
	number?: Maybe<FilterInt32Operator> /**  */
	pageType?: Maybe<FilterInt32Operator> /**  */
	content?: Maybe<ArrayByteTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfReportCache>> /**  */
	or?: Array<Maybe<FilterOfReportCache>> /**  */
	nor?: Array<Maybe<FilterOfReportCache>> /**  */
	not?: Array<Maybe<FilterOfReportCache>> /**  */
}


/**  */
export type QueryContextOfReportCache = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeReportCache>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ReportCache */
export type QuerySortTypeReportCache = {
	cacheId?: Maybe<Sort> /** Représente le type de trie. */
	number?: Maybe<Sort> /** Représente le type de trie. */
	pageType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfReport = {
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	url?: Maybe<FilterStringOperator> /**  */
	fileId?: Maybe<FilterGuidOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfReport>> /**  */
	or?: Array<Maybe<FilterOfReport>> /**  */
	nor?: Array<Maybe<FilterOfReport>> /**  */
	not?: Array<Maybe<FilterOfReport>> /**  */
}


/**  */
export type QueryContextOfReport = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeReport>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Report */
export type QuerySortTypeReport = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	url?: Maybe<Sort> /** Représente le type de trie. */
	file?: Array<Maybe<QuerySortTypeFileModel>> /**  */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Report */
export type FieldUpdateOperatorOfReport = {
	set?: Maybe<SetOperatorInputOfReport> /**  */
	push?: Maybe<PushUpdateOperatorInputReport> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputReport> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Report */
export type SetOperatorInputOfReport = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	fileId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Report */
export type PushUpdateOperatorInputReport = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Report */
export type PullUpdateOperatorInputReport = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfRequest = {
	privateComment?: Maybe<FilterStringOperator> /**  */
	comment?: Maybe<FilterStringOperator> /**  */
	tenantIdString?: Maybe<FilterStringOperator> /**  */
	created?: Maybe<FilterDateTimeOperator> /**  */
	title?: Maybe<FilterStringOperator> /**  */
	counterIncident?: Maybe<FilterInt32Operator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	impactId?: Maybe<FilterGuidOperator> /**  */
	impact?: Maybe<FilterDenormalizeOfImpactByImpactInRequest> /**  */
	urgencyId?: Maybe<FilterGuidOperator> /**  */
	urgency?: Maybe<FilterDenormalizeOfUrgencyByUrgencyInRequest> /**  */
	priorityId?: Maybe<FilterGuidOperator> /**  */
	priority?: Maybe<FilterDenormalizeOfPriorityByPriorityInRequest> /**  */
	resolutionCategoryId?: Maybe<FilterGuidOperator> /**  */
	resolutionCategory?: Maybe<FilterDenormalizeOfResolutionCategoryByResolutionCategoryInRequest> /**  */
	statusReasonId?: Maybe<FilterGuidOperator> /**  */
	ticketStatusReasons?: Maybe<FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInRequest> /**  */
	ticketCategoryId?: Maybe<FilterGuidOperator> /**  */
	ticketCategory?: Maybe<FilterDenormalizeOfTicketCategoryByTicketCategoryInRequest> /**  */
	channelId?: Maybe<FilterGuidOperator> /**  */
	channel?: Maybe<FilterDenormalizeOfChannelByChannelInRequest> /**  */
	operatorTeamReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamReferent?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamReferentInRequest> /**  */
	operatorReferentId?: Maybe<FilterGuidOperator> /**  */
	operatorReferent?: Maybe<FilterDenormalizeOfUserByOperatorReferentInRequest> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInRequest> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInRequest> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	concernedProject?: Maybe<FilterDenormalizeOfConcernedProjectByConcernedProjectInRequest> /**  */
	concernedProjectId?: Maybe<FilterGuidOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	location?: Maybe<FilterDenormalizeOfLocationByLocationInRequest> /**  */
	organizationalUnit?: Maybe<FilterDenormalizeOfOrganizationalUnitByOrganizationalUnitInRequest> /**  */
	locationId?: Maybe<FilterGuidOperator> /**  */
	organizationalUnitId?: Maybe<FilterGuidOperator> /**  */
	userAffected?: Maybe<FilterDenormalizeOfUserByUserAffectedInRequest> /**  */
	userAffectedId?: Maybe<FilterGuidOperator> /**  */
	userMakeRequest?: Maybe<FilterDenormalizeOfUserByUserMakeRequestInRequest> /**  */
	userMakeRequestId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInRequest> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	respondBefore?: Maybe<FilterDateTimeOperator> /**  */
	resolveBefore?: Maybe<FilterDateTimeOperator> /**  */
	slaId?: Maybe<FilterGuidOperator> /**  */
	sla?: Maybe<FilterDenormalizeOfServiceLevelAgreementBySlaInRequest> /**  */
	planned?: Maybe<FilterDateTimeOperator> /**  */
	appointmentConnector?: Maybe<FilterOfAppointmentConnector> /**  */
	estimate?: Maybe<FilterTimeSpanOperator> /**  */
	estimateMinutes?: Maybe<FilterDoubleOperator> /**  */
	assetIds?: Maybe<ArrayUuidTypeOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	mainImpactedAssetId?: Maybe<FilterGuidOperator> /**  */
	mainImpactedAsset?: Maybe<FilterDenormalizeOfAssetByMainImpactedAssetInRequest> /**  */
	favoriteUserTicketIds?: Maybe<ArrayUuidTypeOperator> /**  */
	statusWorkflowId?: Maybe<FilterGuidOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	privateResolutionArticleId?: Maybe<FilterGuidOperator> /**  */
	privateResolutionArticle?: Maybe<FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInRequest> /**  */
	privateResolutionComment?: Maybe<FilterStringOperator> /**  */
	resolutionComment?: Maybe<FilterStringOperator> /**  */
	incidentModelId?: Maybe<FilterGuidOperator> /**  */
	incidentModel?: Maybe<FilterDenormalizeOfIncidentModelByIncidentModelInRequest> /**  */
	callbackNumber?: Maybe<FilterStringOperator> /**  */
	customField1?: Maybe<FilterStringOperator> /**  */
	customField2?: Maybe<FilterStringOperator> /**  */
	customField3?: Maybe<FilterStringOperator> /**  */
	customField4?: Maybe<FilterStringOperator> /**  */
	customField5?: Maybe<FilterStringOperator> /**  */
	takeChargeDate?: Maybe<FilterDateTimeOperator> /**  */
	endTreatmentDate?: Maybe<FilterDateTimeOperator> /**  */
	closedTicketDate?: Maybe<FilterDateTimeOperator> /**  */
	reminder?: Maybe<FilterOfReminder> /**  */
	elaspedTime?: Maybe<FilterTimeSpanOperator> /**  */
	elaspedTimeHour?: Maybe<FilterDoubleOperator> /**  */
	emailConnectorId?: Maybe<FilterGuidOperator> /**  */
	satisfaction?: Maybe<FilterOfTicketSatisfaction> /**  */
	takeChargeHours?: Maybe<FilterDoubleOperator> /**  */
	endTreatmentHours?: Maybe<FilterDoubleOperator> /**  */
	closedHours?: Maybe<FilterDoubleOperator> /**  */
	isParent?: Maybe<FilterBooleanOperator> /**  */
	parentId?: Maybe<FilterGuidOperator> /**  */
	parent?: Maybe<FilterDenormalizeOfTicketByParentInRequest> /**  */
	childrenIds?: Maybe<ArrayUuidTypeOperator> /**  */
	problemId?: Maybe<FilterGuidOperator> /**  */
	problem?: Maybe<FilterDenormalizeOfProblemByProblemInRequest> /**  */
	externalAlreadySendFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfRequest>> /**  */
	or?: Array<Maybe<FilterOfRequest>> /**  */
	nor?: Array<Maybe<FilterOfRequest>> /**  */
	not?: Array<Maybe<FilterOfRequest>> /**  */
}


/**  */
export type FilterDenormalizeOfResolutionCategoryByResolutionCategoryInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusReasonByTicketStatusReasonsInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfChannelByChannelInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	emails?: Maybe<ArrayStringTypeOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInRequest = {
	email?: Maybe<FilterStringOperator> /**  */
	category?: Maybe<FilterUserCategoryOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfConcernedProjectByConcernedProjectInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserAffectedInRequest = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfUserByUserMakeRequestInRequest = {
	email?: Maybe<FilterStringOperator> /**  */
	vip?: Maybe<FilterBooleanOperator> /**  */
	company?: Maybe<FilterStringOperator> /**  */
	managerId?: Maybe<FilterGuidOperator> /**  */
	mainAssetId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfServiceLevelAgreementBySlaInRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfAssetByMainImpactedAssetInRequest = {
	name?: Maybe<FilterStringOperator> /**  */
	manufacturer?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	model?: Maybe<FilterDenormalizeOfModelManufacturerByManufacturerInAsset> /**  */
	serialNumber?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfArticleKnowledgeByPrivateResolutionArticleInRequest = {
	title?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfIncidentModelByIncidentModelInRequest = {
	modelName?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfTicketByParentInRequest = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
}


/**  */
export type FilterDenormalizeOfProblemByProblemInRequest = {
	title?: Maybe<FilterStringOperator> /**  */
	ticketNumber?: Maybe<FilterInt32Operator> /**  */
}


/**  */
export type QueryContextOfRequest = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeRequest>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfRequestQueriesResolver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
}


/** Représente les méthodes disponible pour le type Request */
export type FieldUpdateOperatorOfRequest = {
	set?: Maybe<SetOperatorInputOfRequest> /**  */
	inc?: Maybe<IncOperatorInputRequest> /**  */
	push?: Maybe<PushUpdateOperatorInputRequest> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputRequest> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Request */
export type SetOperatorInputOfRequest = {
	privateComment?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident?: Maybe<number> /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags: Array<string> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes?: Maybe<number> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber?: Maybe<number> /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	emailConnectorId?: Maybe<string> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	isParent?: Maybe<boolean> /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	appointmentConnector?: Maybe<SetOperatorInputOfAppointmentConnector> /**  */
	reminder?: Maybe<SetOperatorInputOfReminder> /**  */
	satisfaction?: Maybe<SetOperatorInputOfTicketSatisfaction> /**  */
}


/** Représente les propriétés de type numérique de l'entité Request */
export type IncOperatorInputRequest = {
	counterIncident?: Maybe<number> /**  */
	levelSupport?: Maybe<number> /**  */
	estimateMinutes?: Maybe<number> /**  */
	ticketNumber?: Maybe<number> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	reminder?: Maybe<IncOperatorInputReminder> /**  */
	satisfaction?: Maybe<IncOperatorInputTicketSatisfaction> /**  */
}


/** Représente les propriétés de type collection de l'entité Request */
export type PushUpdateOperatorInputRequest = {
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Request */
export type PullUpdateOperatorInputRequest = {
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type ResolutionCategory */
export type FieldUpdateOperatorOfResolutionCategory = {
	set?: Maybe<SetOperatorInputOfResolutionCategory> /**  */
	inc?: Maybe<IncOperatorInputResolutionCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputResolutionCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputResolutionCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ResolutionCategory */
export type SetOperatorInputOfResolutionCategory = {
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	resolutionModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité ResolutionCategory */
export type IncOperatorInputResolutionCategory = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ResolutionCategory */
export type PushUpdateOperatorInputResolutionCategory = {
	qualification: Array<string> /**  */
	resolutionModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ResolutionCategory */
export type PullUpdateOperatorInputResolutionCategory = {
	qualification: Array<string> /**  */
	resolutionModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfResultScanAzureAd = {
	scanByAzureAdId?: Maybe<FilterGuidOperator> /**  */
	userAzureAd?: Maybe<FilterOfUserAzureAD> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfResultScanAzureAd>> /**  */
	or?: Array<Maybe<FilterOfResultScanAzureAd>> /**  */
	nor?: Array<Maybe<FilterOfResultScanAzureAd>> /**  */
	not?: Array<Maybe<FilterOfResultScanAzureAd>> /**  */
}


/**  */
export type QueryContextOfResultScanAzureAd = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeResultScanAzureAd>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ResultScanAzureAd */
export type QuerySortTypeResultScanAzureAd = {
	userAzureAd?: Array<Maybe<QuerySortTypeUserAzureAD>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfRoleAccount = {
	roleId?: Maybe<FilterGuidOperator> /**  */
	accountId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfRoleAccount>> /**  */
	or?: Array<Maybe<FilterOfRoleAccount>> /**  */
	nor?: Array<Maybe<FilterOfRoleAccount>> /**  */
	not?: Array<Maybe<FilterOfRoleAccount>> /**  */
}


/**  */
export type QueryContextOfRoleAccount = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeRoleAccount>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité RoleAccount */
export type QuerySortTypeRoleAccount = {
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Role */
export type FieldUpdateOperatorOfRole = {
	set?: Maybe<SetOperatorInputOfRole> /**  */
	push?: Maybe<PushUpdateOperatorInputRole> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputRole> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Role */
export type SetOperatorInputOfRole = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	hidden?: Maybe<boolean> /**  */
	isSystem?: Maybe<boolean> /**  */
	type: Array<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Role */
export type PushUpdateOperatorInputRole = {
	type: Array<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Role */
export type PullUpdateOperatorInputRole = {
	type: Array<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfSatisfactionSetting = {
	name?: Maybe<FilterStringOperator> /**  */
	rating?: Maybe<FilterDoubleOperator> /**  */
	commentaryRequired?: Maybe<FilterBooleanOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSatisfactionSetting>> /**  */
	or?: Array<Maybe<FilterOfSatisfactionSetting>> /**  */
	nor?: Array<Maybe<FilterOfSatisfactionSetting>> /**  */
	not?: Array<Maybe<FilterOfSatisfactionSetting>> /**  */
}


/**  */
export type QueryContextOfSatisfactionSetting = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSatisfactionSetting>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SatisfactionSetting */
export type QuerySortTypeSatisfactionSetting = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	rating?: Maybe<Sort> /** Représente le type de trie. */
	commentaryRequired?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type SatisfactionSetting */
export type FieldUpdateOperatorOfSatisfactionSetting = {
	set?: Maybe<SetOperatorInputOfSatisfactionSetting> /**  */
	inc?: Maybe<IncOperatorInputSatisfactionSetting> /**  */
	push?: Maybe<PushUpdateOperatorInputSatisfactionSetting> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSatisfactionSetting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SatisfactionSetting */
export type SetOperatorInputOfSatisfactionSetting = {
	name?: Maybe<string> /**  */
	rating?: Maybe<number> /**  */
	commentaryRequired?: Maybe<boolean> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité SatisfactionSetting */
export type IncOperatorInputSatisfactionSetting = {
	rating?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité SatisfactionSetting */
export type PushUpdateOperatorInputSatisfactionSetting = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité SatisfactionSetting */
export type PullUpdateOperatorInputSatisfactionSetting = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanByAddressIPRange = {
	addressIpStart?: Maybe<FilterStringOperator> /**  */
	addressIpEnd?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByAddressIPRange> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByAddressIPRange> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByAddressIPRange>> /**  */
	or?: Array<Maybe<FilterOfScanByAddressIPRange>> /**  */
	nor?: Array<Maybe<FilterOfScanByAddressIPRange>> /**  */
	not?: Array<Maybe<FilterOfScanByAddressIPRange>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByAddressIPRange = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByAddressIPRange = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByAddressIPRange = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByAddressIPRange>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByAddressIPRange */
export type QuerySortTypeScanByAddressIPRange = {
	addressIpStart?: Maybe<Sort> /** Représente le type de trie. */
	addressIpEnd?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ScanByAddressIPRange */
export type FieldUpdateOperatorOfScanByAddressIPRange = {
	set?: Maybe<SetOperatorInputOfScanByAddressIPRange> /**  */
	inc?: Maybe<IncOperatorInputScanByAddressIPRange> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByAddressIPRange> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByAddressIPRange> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByAddressIPRange */
export type SetOperatorInputOfScanByAddressIPRange = {
	addressIpStart?: Maybe<string> /**  */
	addressIpEnd?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AuditOption */
export type SetOperatorInputOfAuditOption = {
	notAuditedType?: Maybe<NotAuditedType> /**  */
	sinceDay?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité PowerControl */
export type SetOperatorInputOfPowerControl = {
	enabled?: Maybe<boolean> /**  */
	hour?: Maybe<number> /**  */
	autoPurge?: Maybe<boolean> /**  */
	since?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Wol */
export type SetOperatorInputOfWol = {
	enabled?: Maybe<boolean> /**  */
	timeOut?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ShutDown */
export type SetOperatorInputOfShutDown = {
	enabled?: Maybe<boolean> /**  */
	onlyNotError?: Maybe<boolean> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByAddressIPRange */
export type IncOperatorInputScanByAddressIPRange = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type numérique de l'entité AuditOption */
export type IncOperatorInputAuditOption = {
	sinceDay?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité PowerControl */
export type IncOperatorInputPowerControl = {
	hour?: Maybe<number> /**  */
	since?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité Wol */
export type IncOperatorInputWol = {
	timeOut?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAddressIPRange */
export type PushUpdateOperatorInputScanByAddressIPRange = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAddressIPRange */
export type PullUpdateOperatorInputScanByAddressIPRange = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanByAzureAdAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanByAzureAdAppointmentTaskId?: Maybe<FilterGuidOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByAzureAdAppointment>> /**  */
	or?: Array<Maybe<FilterOfScanByAzureAdAppointment>> /**  */
	nor?: Array<Maybe<FilterOfScanByAzureAdAppointment>> /**  */
	not?: Array<Maybe<FilterOfScanByAzureAdAppointment>> /**  */
}


/**  */
export type QueryContextOfScanByAzureAdAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByAzureAdAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByAzureAdAppointment */
export type QuerySortTypeScanByAzureAdAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanByAzureAdAppointmentTask = {
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByAzureAdAppointmentTask>> /**  */
	or?: Array<Maybe<FilterOfScanByAzureAdAppointmentTask>> /**  */
	nor?: Array<Maybe<FilterOfScanByAzureAdAppointmentTask>> /**  */
	not?: Array<Maybe<FilterOfScanByAzureAdAppointmentTask>> /**  */
}


/**  */
export type QueryContextOfScanByAzureAdAppointmentTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByAzureAdAppointmentTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByAzureAdAppointmentTask */
export type QuerySortTypeScanByAzureAdAppointmentTask = {
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ScanByAzureAd */
export type FieldUpdateOperatorOfScanByAzureAd = {
	set?: Maybe<SetOperatorInputOfScanByAzureAd> /**  */
	inc?: Maybe<IncOperatorInputScanByAzureAd> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByAzureAd> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByAzureAd> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByAzureAd */
export type SetOperatorInputOfScanByAzureAd = {
	applicationAzureAdId?: Maybe<string> /**  */
	importDisabledUser?: Maybe<boolean> /**  */
	importMember?: Maybe<boolean> /**  */
	importGuest?: Maybe<boolean> /**  */
	importNoType?: Maybe<boolean> /**  */
	importGroup?: Maybe<boolean> /**  */
	groupObjectIds: Array<string> /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	recursiveGroup?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByAzureAd */
export type IncOperatorInputScanByAzureAd = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAd */
export type PushUpdateOperatorInputScanByAzureAd = {
	groupObjectIds: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAd */
export type PullUpdateOperatorInputScanByAzureAd = {
	groupObjectIds: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanByAzureAdTask = {
	scanByAzureAdAppointmentId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByAzureAdTask>> /**  */
	or?: Array<Maybe<FilterOfScanByAzureAdTask>> /**  */
	nor?: Array<Maybe<FilterOfScanByAzureAdTask>> /**  */
	not?: Array<Maybe<FilterOfScanByAzureAdTask>> /**  */
}


/**  */
export type QueryContextOfScanByAzureAdTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByAzureAdTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByAzureAdTask */
export type QuerySortTypeScanByAzureAdTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScanByHost = {
	hostName?: Maybe<ArrayStringTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByHost> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByHost> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByHost>> /**  */
	or?: Array<Maybe<FilterOfScanByHost>> /**  */
	nor?: Array<Maybe<FilterOfScanByHost>> /**  */
	not?: Array<Maybe<FilterOfScanByHost>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByHost = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByHost = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByHost = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByHost>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByHost */
export type QuerySortTypeScanByHost = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ScanByHost */
export type FieldUpdateOperatorOfScanByHost = {
	set?: Maybe<SetOperatorInputOfScanByHost> /**  */
	inc?: Maybe<IncOperatorInputScanByHost> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByHost> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByHost> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByHost */
export type SetOperatorInputOfScanByHost = {
	hostName: Array<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByHost */
export type IncOperatorInputScanByHost = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByHost */
export type PushUpdateOperatorInputScanByHost = {
	hostName: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByHost */
export type PullUpdateOperatorInputScanByHost = {
	hostName: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type ScanByLdap */
export type FieldUpdateOperatorOfScanByLdap = {
	set?: Maybe<SetOperatorInputOfScanByLdap> /**  */
	inc?: Maybe<IncOperatorInputScanByLdap> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByLdap> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByLdap> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByLdap */
export type SetOperatorInputOfScanByLdap = {
	domain?: Maybe<string> /**  */
	credentialId?: Maybe<string> /**  */
	useSsl?: Maybe<boolean> /**  */
	usePort?: Maybe<number> /**  */
	protocolVersion?: Maybe<number> /**  */
	connectionTimeoutIn?: Maybe<number> /**  */
	ldapAuthType?: Maybe<LdapAuthType> /**  */
	distinguishedName?: Maybe<string> /**  */
	importGroup?: Maybe<boolean> /**  */
	importUser?: Maybe<boolean> /**  */
	importDisabledUser?: Maybe<boolean> /**  */
	importRecursiveGroup?: Maybe<boolean> /**  */
	importComputer?: Maybe<boolean> /**  */
	importLdapStruct?: Maybe<boolean> /**  */
	disableUsers?: Maybe<boolean> /**  */
	importOrganizationalUnit?: Maybe<ImportOrganizationalUnit> /**  */
	forceSearchFilter?: Maybe<boolean> /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	ldapDistinguishedNames: Array<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByLdap */
export type IncOperatorInputScanByLdap = {
	usePort?: Maybe<number> /**  */
	protocolVersion?: Maybe<number> /**  */
	connectionTimeoutIn?: Maybe<number> /**  */
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByLdap */
export type PushUpdateOperatorInputScanByLdap = {
	ldapDistinguishedNames: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByLdap */
export type PullUpdateOperatorInputScanByLdap = {
	ldapDistinguishedNames: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanByLocalAgent = {
	locationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	organizationalUnitIds?: Maybe<ArrayUuidTypeOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByLocalAgent> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByLocalAgent> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByLocalAgent>> /**  */
	or?: Array<Maybe<FilterOfScanByLocalAgent>> /**  */
	nor?: Array<Maybe<FilterOfScanByLocalAgent>> /**  */
	not?: Array<Maybe<FilterOfScanByLocalAgent>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByLocalAgent = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByLocalAgent = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByLocalAgent = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByLocalAgent>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfScanConfigurationAppointment = {
	allDay?: Maybe<FilterBooleanOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	endDateTimeZone?: Maybe<FilterStringOperator> /**  */
	recurrenceException?: Maybe<FilterStringOperator> /**  */
	recurrenceRule?: Maybe<FilterStringOperator> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	startDateTimeZone?: Maybe<FilterStringOperator> /**  */
	text?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	disabled?: Maybe<FilterBooleanOperator> /**  */
	html?: Maybe<FilterStringOperator> /**  */
	template?: Maybe<FilterStringOperator> /**  */
	visible?: Maybe<FilterBooleanOperator> /**  */
	offSet?: Maybe<FilterInt32Operator> /**  */
	localTimeZone?: Maybe<FilterStringOperator> /**  */
	serverTimeZone?: Maybe<FilterStringOperator> /**  */
	initialStartDate?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanConfigurationAppointment>> /**  */
	or?: Array<Maybe<FilterOfScanConfigurationAppointment>> /**  */
	nor?: Array<Maybe<FilterOfScanConfigurationAppointment>> /**  */
	not?: Array<Maybe<FilterOfScanConfigurationAppointment>> /**  */
}


/**  */
export type QueryContextOfScanConfigurationAppointment = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanConfigurationAppointment>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanConfigurationAppointment */
export type QuerySortTypeScanConfigurationAppointment = {
	allDay?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	endDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceException?: Maybe<Sort> /** Représente le type de trie. */
	recurrenceRule?: Maybe<Sort> /** Représente le type de trie. */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	startDateTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	text?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	disabled?: Maybe<Sort> /** Représente le type de trie. */
	html?: Maybe<Sort> /** Représente le type de trie. */
	template?: Maybe<Sort> /** Représente le type de trie. */
	visible?: Maybe<Sort> /** Représente le type de trie. */
	offSet?: Maybe<Sort> /** Représente le type de trie. */
	localTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	serverTimeZone?: Maybe<Sort> /** Représente le type de trie. */
	initialStartDate?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ScanByLocalAgent */
export type FieldUpdateOperatorOfScanByLocalAgent = {
	set?: Maybe<SetOperatorInputOfScanByLocalAgent> /**  */
	inc?: Maybe<IncOperatorInputScanByLocalAgent> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByLocalAgent> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByLocalAgent> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByLocalAgent */
export type SetOperatorInputOfScanByLocalAgent = {
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByLocalAgent */
export type IncOperatorInputScanByLocalAgent = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByLocalAgent */
export type PushUpdateOperatorInputScanByLocalAgent = {
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByLocalAgent */
export type PullUpdateOperatorInputScanByLocalAgent = {
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanByWorkgroup = {
	workgroupName?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	pingTimeOut?: Maybe<FilterInt32Operator> /**  */
	disablePing?: Maybe<FilterBooleanOperator> /**  */
	scanTimeOut?: Maybe<FilterInt32Operator> /**  */
	auditOption?: Maybe<FilterOfAuditOption> /**  */
	powerControl?: Maybe<FilterOfPowerControl> /**  */
	wol?: Maybe<FilterOfWol> /**  */
	shutDown?: Maybe<FilterOfShutDown> /**  */
	lastStartScan?: Maybe<FilterDateTimeOperator> /**  */
	lastEndScan?: Maybe<FilterDateTimeOperator> /**  */
	lastModifyScan?: Maybe<FilterDateTimeOperator> /**  */
	lastScanId?: Maybe<FilterGuidOperator> /**  */
	totalScan?: Maybe<FilterInt32Operator> /**  */
	totalScanEnd?: Maybe<FilterInt32Operator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	state?: Maybe<FilterActionHistoryStateOperator> /**  */
	displayState?: Maybe<FilterStringOperator> /**  */
	scanRegistryKeyIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanDataFileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanImportOptionIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanMethodProfilId?: Maybe<FilterGuidOperator> /**  */
	scanMethodProfil?: Maybe<FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByWorkgroup> /**  */
	scanExcludeIds?: Maybe<ArrayUuidTypeOperator> /**  */
	clarilogServerId?: Maybe<FilterGuidOperator> /**  */
	clarilogServer?: Maybe<FilterDenormalizeOfClarilogServerByClarilogServerInScanByWorkgroup> /**  */
	networkCredentialIds?: Maybe<ArrayUuidTypeOperator> /**  */
	lastTask?: Maybe<FilterDateTimeOperator> /**  */
	endTask?: Maybe<FilterDateTimeOperator> /**  */
	scanSuccessCount?: Maybe<FilterInt32Operator> /**  */
	scanErrorCount?: Maybe<FilterInt32Operator> /**  */
	scanTotalCount?: Maybe<FilterInt32Operator> /**  */
	percent?: Maybe<FilterInt32Operator> /**  */
	onlyReadOnly?: Maybe<FilterBooleanOperator> /**  */
	nextTask?: Maybe<FilterOfIAppointment> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanByWorkgroup>> /**  */
	or?: Array<Maybe<FilterOfScanByWorkgroup>> /**  */
	nor?: Array<Maybe<FilterOfScanByWorkgroup>> /**  */
	not?: Array<Maybe<FilterOfScanByWorkgroup>> /**  */
}


/**  */
export type FilterDenormalizeOfScanMethodProfilByScanMethodProfilInScanByWorkgroup = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfClarilogServerByClarilogServerInScanByWorkgroup = {
	name?: Maybe<FilterStringOperator> /**  */
	lastCheck?: Maybe<FilterDateTimeOperator> /**  */
}


/**  */
export type QueryContextOfScanByWorkgroup = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanByWorkgroup>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanByWorkgroup */
export type QuerySortTypeScanByWorkgroup = {
	workgroupName?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	pingTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	disablePing?: Maybe<Sort> /** Représente le type de trie. */
	scanTimeOut?: Maybe<Sort> /** Représente le type de trie. */
	auditOption?: Array<Maybe<QuerySortTypeAuditOption>> /**  */
	powerControl?: Array<Maybe<QuerySortTypePowerControl>> /**  */
	wol?: Array<Maybe<QuerySortTypeWol>> /**  */
	shutDown?: Array<Maybe<QuerySortTypeShutDown>> /**  */
	lastStartScan?: Maybe<Sort> /** Représente le type de trie. */
	lastEndScan?: Maybe<Sort> /** Représente le type de trie. */
	lastModifyScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScan?: Maybe<Sort> /** Représente le type de trie. */
	totalScanEnd?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	displayState?: Maybe<Sort> /** Représente le type de trie. */
	scanRegistryKeyIds?: Maybe<Sort> /** Représente le type de trie. */
	scanDataFileIds?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOptionIds?: Maybe<Sort> /** Représente le type de trie. */
	scanMethodProfil?: Array<Maybe<QuerySortTypeScanMethodProfil>> /**  */
	scanExcludeIds?: Maybe<Sort> /** Représente le type de trie. */
	clarilogServer?: Array<Maybe<QuerySortTypeClarilogServer>> /**  */
	networkCredentialIds?: Maybe<Sort> /** Représente le type de trie. */
	lastTask?: Maybe<Sort> /** Représente le type de trie. */
	endTask?: Maybe<Sort> /** Représente le type de trie. */
	scanSuccessCount?: Maybe<Sort> /** Représente le type de trie. */
	scanErrorCount?: Maybe<Sort> /** Représente le type de trie. */
	scanTotalCount?: Maybe<Sort> /** Représente le type de trie. */
	percent?: Maybe<Sort> /** Représente le type de trie. */
	onlyReadOnly?: Maybe<Sort> /** Représente le type de trie. */
	nextTask?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ScanByWorkgroup */
export type FieldUpdateOperatorOfScanByWorkgroup = {
	set?: Maybe<SetOperatorInputOfScanByWorkgroup> /**  */
	inc?: Maybe<IncOperatorInputScanByWorkgroup> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByWorkgroup> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByWorkgroup> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByWorkgroup */
export type SetOperatorInputOfScanByWorkgroup = {
	workgroupName?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByWorkgroup */
export type IncOperatorInputScanByWorkgroup = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByWorkgroup */
export type PushUpdateOperatorInputScanByWorkgroup = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByWorkgroup */
export type PullUpdateOperatorInputScanByWorkgroup = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type ScanConfiguration */
export type FieldUpdateOperatorOfScanConfiguration = {
	set?: Maybe<SetOperatorInputOfScanConfiguration> /**  */
	inc?: Maybe<IncOperatorInputScanConfiguration> /**  */
	push?: Maybe<PushUpdateOperatorInputScanConfiguration> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanConfiguration> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanConfiguration */
export type SetOperatorInputOfScanConfiguration = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut?: Maybe<number> /**  */
	disablePing?: Maybe<boolean> /**  */
	scanTimeOut?: Maybe<number> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	enabled?: Maybe<boolean> /**  */
	state?: Maybe<ActionHistoryState> /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	onlyReadOnly?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	auditOption?: Maybe<SetOperatorInputOfAuditOption> /**  */
	powerControl?: Maybe<SetOperatorInputOfPowerControl> /**  */
	wol?: Maybe<SetOperatorInputOfWol> /**  */
	shutDown?: Maybe<SetOperatorInputOfShutDown> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanConfiguration */
export type IncOperatorInputScanConfiguration = {
	pingTimeOut?: Maybe<number> /**  */
	scanTimeOut?: Maybe<number> /**  */
	totalScan?: Maybe<number> /**  */
	totalScanEnd?: Maybe<number> /**  */
	scanSuccessCount?: Maybe<number> /**  */
	scanErrorCount?: Maybe<number> /**  */
	scanTotalCount?: Maybe<number> /**  */
	percent?: Maybe<number> /**  */
	auditOption?: Maybe<IncOperatorInputAuditOption> /**  */
	powerControl?: Maybe<IncOperatorInputPowerControl> /**  */
	wol?: Maybe<IncOperatorInputWol> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanConfiguration */
export type PushUpdateOperatorInputScanConfiguration = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanConfiguration */
export type PullUpdateOperatorInputScanConfiguration = {
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanExcludeIds: Array<string> /**  */
	networkCredentialIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanMethodProfil = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	scanMethodIds?: Maybe<ArrayUuidTypeOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanMethodProfil>> /**  */
	or?: Array<Maybe<FilterOfScanMethodProfil>> /**  */
	nor?: Array<Maybe<FilterOfScanMethodProfil>> /**  */
	not?: Array<Maybe<FilterOfScanMethodProfil>> /**  */
}


/**  */
export type QueryContextOfScanMethodProfil = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanMethodProfil>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type ScanMethodProfil */
export type FieldUpdateOperatorOfScanMethodProfil = {
	set?: Maybe<SetOperatorInputOfScanMethodProfil> /**  */
	push?: Maybe<PushUpdateOperatorInputScanMethodProfil> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanMethodProfil> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanMethodProfil */
export type SetOperatorInputOfScanMethodProfil = {
	scanMethodIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanMethodProfil */
export type PushUpdateOperatorInputScanMethodProfil = {
	scanMethodIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanMethodProfil */
export type PullUpdateOperatorInputScanMethodProfil = {
	scanMethodIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfScanMethod = {
	methodName?: Maybe<FilterStringOperator> /**  */
	fast?: Maybe<FilterBooleanOperator> /**  */
	normal?: Maybe<FilterBooleanOperator> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfScanMethod>> /**  */
	or?: Array<Maybe<FilterOfScanMethod>> /**  */
	nor?: Array<Maybe<FilterOfScanMethod>> /**  */
	not?: Array<Maybe<FilterOfScanMethod>> /**  */
}


/**  */
export type QueryContextOfScanMethod = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScanMethod>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScanMethod */
export type QuerySortTypeScanMethod = {
	methodName?: Maybe<Sort> /** Représente le type de trie. */
	fast?: Maybe<Sort> /** Représente le type de trie. */
	normal?: Maybe<Sort> /** Représente le type de trie. */
	enabled?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfScheduleTask = {
	typename?: Maybe<ArrayStringTypeOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	emailPrototypeId?: Maybe<FilterGuidOperator> /**  */
	queryContext?: Maybe<FilterStringOperator> /**  */
	force?: Maybe<FilterBooleanOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	scanByAzureAdAppointmentId?: Maybe<FilterGuidOperator> /**  */
	inboxId?: Maybe<FilterGuidOperator> /**  */
	emailId?: Maybe<FilterGuidOperator> /**  */
	messageId?: Maybe<FilterStringOperator> /**  */
	webhookId?: Maybe<FilterStringOperator> /**  */
	eventName?: Maybe<FilterStringOperator> /**  */
	createdAt?: Maybe<FilterDateTimeOperator> /**  */
	to?: Maybe<ArrayStringTypeOperator> /**  */
	from?: Maybe<FilterStringOperator> /**  */
	cc?: Maybe<ArrayStringTypeOperator> /**  */
	bcc?: Maybe<ArrayStringTypeOperator> /**  */
	subject?: Maybe<FilterStringOperator> /**  */
	attachmentMetaDatas?: Maybe<ArrayMailSlurpAttachmentMetaDataOperator> /**  */
	queryBuilderIds?: Maybe<ArrayUuidTypeOperator> /**  */
	timeZone?: Maybe<FilterStringOperator> /**  */
	alarmTriggerType?: Maybe<FilterAlarmTriggerTypeEnumOperator> /**  */
	progression?: Maybe<FilterDoubleOperator> /**  */
	methodName?: Maybe<FilterStringOperator> /**  */
	paramMap?: Maybe<FilterOfAsyncTaskParam> /**  */
	endExecution?: Maybe<FilterDateTimeOperator> /**  */
	cronJobHourlyType?: Maybe<FilterCronJobHourlyTypeOperator> /**  */
	cronExpression?: Maybe<FilterStringOperator> /**  */
	fileIds?: Maybe<ArrayUuidTypeOperator> /**  */
	ticketType?: Maybe<FilterStringOperator> /**  */
	asset?: Maybe<FilterOfAsset> /**  */
	auditResult?: Maybe<FilterOfAuditResult> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	model?: Maybe<FilterStringOperator> /**  */
	scanImportOptionId?: Maybe<FilterGuidOperator> /**  */
	scanImportOption?: Maybe<FilterDenormalizeOfScanImportOptionByScanImportOptionInScheduleTask> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	modifier?: Maybe<FilterTicketStatisticModifierOperator> /**  */
	savedTicket?: Maybe<FilterOfTicket> /**  */
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	oldName?: Maybe<FilterStringOperator> /**  */
	newName?: Maybe<FilterStringOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflowInvitation?: Maybe<FilterOfWorkflowInvitation> /**  */
	authorId?: Maybe<FilterGuidOperator> /**  */
	origin?: Maybe<FilterStringOperator> /**  */
	workflowMail?: Maybe<FilterOfWorkflowMail> /**  */
	callerId?: Maybe<FilterGuidOperator> /**  */
	workflowLinkedObjectId?: Maybe<FilterGuidOperator> /**  */
	workflowLinkedObjectType?: Maybe<FilterStringOperator> /**  */
	jobId?: Maybe<FilterGuidOperator> /**  */
	emailsToCheck?: Maybe<ArrayStringTypeOperator> /**  */
	and?: Array<Maybe<FilterOfScheduleTask>> /**  */
	or?: Array<Maybe<FilterOfScheduleTask>> /**  */
	nor?: Array<Maybe<FilterOfScheduleTask>> /**  */
	not?: Array<Maybe<FilterOfScheduleTask>> /**  */
}


/**  */
export type FilterDenormalizeOfScanImportOptionByScanImportOptionInScheduleTask = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterTicketStatisticModifierOperator = {
	eq?: Maybe<TicketStatisticModifier> /**  */
	ne?: Maybe<TicketStatisticModifier> /**  */
	in?: Array<Maybe<TicketStatisticModifier>> /**  */
	nin?: Array<Maybe<TicketStatisticModifier>> /**  */
}


/**  */
export type FilterOfWorkflowInvitation = {
	roleIds?: Maybe<ArrayUuidTypeOperator> /**  */
	modelType?: Maybe<FilterStringOperator> /**  */
	user?: Maybe<FilterOfUser> /**  */
	userIncidentRuleId?: Maybe<FilterGuidOperator> /**  */
	userRequestRuleId?: Maybe<FilterGuidOperator> /**  */
	userPrivilegeId?: Maybe<FilterGuidOperator> /**  */
	businessTimeId?: Maybe<FilterGuidOperator> /**  */
	userRequestListDesignerId?: Maybe<FilterGuidOperator> /**  */
	userIncidentListDesignerId?: Maybe<FilterGuidOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowInvitation> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowInvitation>> /**  */
	or?: Array<Maybe<FilterOfWorkflowInvitation>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowInvitation>> /**  */
	not?: Array<Maybe<FilterOfWorkflowInvitation>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowInvitation = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfScheduleTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeScheduleTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité ScheduleTask */
export type QuerySortTypeScheduleTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
	queryContext?: Maybe<Sort> /** Représente le type de trie. */
	force?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	messageId?: Maybe<Sort> /** Représente le type de trie. */
	webhookId?: Maybe<Sort> /** Représente le type de trie. */
	eventName?: Maybe<Sort> /** Représente le type de trie. */
	createdAt?: Maybe<Sort> /** Représente le type de trie. */
	from?: Maybe<Sort> /** Représente le type de trie. */
	subject?: Maybe<Sort> /** Représente le type de trie. */
	queryBuilderIds?: Maybe<Sort> /** Représente le type de trie. */
	timeZone?: Maybe<Sort> /** Représente le type de trie. */
	alarmTriggerType?: Maybe<Sort> /** Représente le type de trie. */
	progression?: Maybe<Sort> /** Représente le type de trie. */
	methodName?: Maybe<Sort> /** Représente le type de trie. */
	paramMap?: Array<Maybe<QuerySortTypeAsyncTaskParam>> /**  */
	endExecution?: Maybe<Sort> /** Représente le type de trie. */
	cronJobHourlyType?: Maybe<Sort> /** Représente le type de trie. */
	cronExpression?: Maybe<Sort> /** Représente le type de trie. */
	fileIds?: Maybe<Sort> /** Représente le type de trie. */
	ticketType?: Maybe<Sort> /** Représente le type de trie. */
	asset?: Array<Maybe<QuerySortTypeAsset>> /**  */
	auditResult?: Array<Maybe<QuerySortTypeAuditResult>> /**  */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	model?: Maybe<Sort> /** Représente le type de trie. */
	scanImportOption?: Array<Maybe<QuerySortTypeScanImportOption>> /**  */
	modifier?: Maybe<Sort> /** Représente le type de trie. */
	savedTicket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	tags?: Maybe<Sort> /** Représente le type de trie. */
	oldName?: Maybe<Sort> /** Représente le type de trie. */
	newName?: Maybe<Sort> /** Représente le type de trie. */
	workflowInvitation?: Array<Maybe<QuerySortTypeWorkflowInvitation>> /**  */
	origin?: Maybe<Sort> /** Représente le type de trie. */
	workflowMail?: Array<Maybe<QuerySortTypeWorkflowMail>> /**  */
	workflowLinkedObjectType?: Maybe<Sort> /** Représente le type de trie. */
	emailsToCheck?: Maybe<Sort> /** Représente le type de trie. */
	typename?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les propriétés triables de l'entité WorkflowInvitation */
export type QuerySortTypeWorkflowInvitation = {
	roleIds?: Maybe<Sort> /** Représente le type de trie. */
	modelType?: Maybe<Sort> /** Représente le type de trie. */
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type ServiceLevelAgreement */
export type FieldUpdateOperatorOfServiceLevelAgreement = {
	set?: Maybe<SetOperatorInputOfServiceLevelAgreement> /**  */
	inc?: Maybe<IncOperatorInputServiceLevelAgreement> /**  */
	push?: Maybe<PushUpdateOperatorInputServiceLevelAgreement> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputServiceLevelAgreement> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ServiceLevelAgreement */
export type SetOperatorInputOfServiceLevelAgreement = {
	qualification: Array<string> /**  */
	supportTimes?: Maybe<number> /**  */
	resolutionTimes?: Maybe<number> /**  */
	ticketCategoryIds: Array<string> /**  */
	priorityIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	workTimeId?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	populationsIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité ServiceLevelAgreement */
export type IncOperatorInputServiceLevelAgreement = {
	supportTimes?: Maybe<number> /**  */
	resolutionTimes?: Maybe<number> /**  */
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ServiceLevelAgreement */
export type PushUpdateOperatorInputServiceLevelAgreement = {
	qualification: Array<string> /**  */
	ticketCategoryIds: Array<string> /**  */
	priorityIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	dayOffIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	populationsIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ServiceLevelAgreement */
export type PullUpdateOperatorInputServiceLevelAgreement = {
	qualification: Array<string> /**  */
	ticketCategoryIds: Array<string> /**  */
	priorityIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	dayOffIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	populationsIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type SftpConnector */
export type FieldUpdateOperatorOfSftpConnector = {
	set?: Maybe<SetOperatorInputOfSftpConnector> /**  */
	inc?: Maybe<IncOperatorInputSftpConnector> /**  */
	push?: Maybe<PushUpdateOperatorInputSftpConnector> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSftpConnector> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SftpConnector */
export type SetOperatorInputOfSftpConnector = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	port?: Maybe<number> /**  */
	userName?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	remoteFolder?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	queryBuilderIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité SftpConnector */
export type IncOperatorInputSftpConnector = {
	port?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité SftpConnector */
export type PushUpdateOperatorInputSftpConnector = {
	queryBuilderIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SftpConnector */
export type PullUpdateOperatorInputSftpConnector = {
	queryBuilderIds: Array<string> /**  */
}


/**  */
export type FilterOfSnmpCredential = {
	community?: Maybe<FilterStringOperator> /**  */
	snmpVersion?: Maybe<FilterSnmpVersionOperator> /**  */
	authentificationSnmpV3?: Maybe<FilterAuthentificationSnmpV3Operator> /**  */
	encryptionSnmpV3?: Maybe<FilterEncryptionSnmpV3Operator> /**  */
	encryptionKey?: Maybe<FilterStringOperator> /**  */
	port?: Maybe<FilterInt32Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpCredential>> /**  */
	or?: Array<Maybe<FilterOfSnmpCredential>> /**  */
	nor?: Array<Maybe<FilterOfSnmpCredential>> /**  */
	not?: Array<Maybe<FilterOfSnmpCredential>> /**  */
}


/**  */
export type QueryContextOfSnmpCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSnmpCredential>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SnmpCredential */
export type QuerySortTypeSnmpCredential = {
	community?: Maybe<Sort> /** Représente le type de trie. */
	snmpVersion?: Maybe<Sort> /** Représente le type de trie. */
	authentificationSnmpV3?: Maybe<Sort> /** Représente le type de trie. */
	encryptionSnmpV3?: Maybe<Sort> /** Représente le type de trie. */
	encryptionKey?: Maybe<Sort> /** Représente le type de trie. */
	port?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type SnmpCredential */
export type FieldUpdateOperatorOfSnmpCredential = {
	set?: Maybe<SetOperatorInputOfSnmpCredential> /**  */
	inc?: Maybe<IncOperatorInputSnmpCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputSnmpCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSnmpCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SnmpCredential */
export type SetOperatorInputOfSnmpCredential = {
	community?: Maybe<string> /**  */
	snmpVersion?: Maybe<SnmpVersion> /**  */
	authentificationSnmpV3?: Maybe<AuthentificationSnmpV3> /**  */
	encryptionSnmpV3?: Maybe<EncryptionSnmpV3> /**  */
	encryptionKey?: Maybe<string> /**  */
	port?: Maybe<number> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité SnmpCredential */
export type IncOperatorInputSnmpCredential = {
	port?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpCredential */
export type PushUpdateOperatorInputSnmpCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpCredential */
export type PullUpdateOperatorInputSnmpCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type SnmpMapInformation */
export type FieldUpdateOperatorOfSnmpMapInformation = {
	set?: Maybe<SetOperatorInputOfSnmpMapInformation> /**  */
	push?: Maybe<PushUpdateOperatorInputSnmpMapInformation> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSnmpMapInformation> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SnmpMapInformation */
export type SetOperatorInputOfSnmpMapInformation = {
	sysOid?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	type?: Maybe<SnmpType> /**  */
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
	assetCategoryId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SnmpOidObjectInput = {
	objectOidMap: ObjectOidMap /**  */
	oid?: Maybe<string> /**  */
	knowledgeBase: boolean /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpMapInformation */
export type PushUpdateOperatorInputSnmpMapInformation = {
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpMapInformation */
export type PullUpdateOperatorInputSnmpMapInformation = {
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfSnmpVendor = {
	value?: Maybe<FilterInt32Operator> /**  */
	manufacturer?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSnmpVendor>> /**  */
	or?: Array<Maybe<FilterOfSnmpVendor>> /**  */
	nor?: Array<Maybe<FilterOfSnmpVendor>> /**  */
	not?: Array<Maybe<FilterOfSnmpVendor>> /**  */
}


/**  */
export type QueryContextOfSnmpVendor = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSnmpVendor>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SnmpVendor */
export type QuerySortTypeSnmpVendor = {
	value?: Maybe<Sort> /** Représente le type de trie. */
	manufacturer?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftwareAttributeType = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareAttributeType>> /**  */
	or?: Array<Maybe<FilterOfSoftwareAttributeType>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareAttributeType>> /**  */
	not?: Array<Maybe<FilterOfSoftwareAttributeType>> /**  */
}


/**  */
export type QueryContextOfSoftwareAttributeType = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareAttributeType>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SoftwareAttributeType */
export type QuerySortTypeSoftwareAttributeType = {
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	description?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfSoftwareConnectionMode = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareConnectionMode>> /**  */
	or?: Array<Maybe<FilterOfSoftwareConnectionMode>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareConnectionMode>> /**  */
	not?: Array<Maybe<FilterOfSoftwareConnectionMode>> /**  */
}


/**  */
export type QueryContextOfSoftwareConnectionMode = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareConnectionMode>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSoftwareHostedComponent = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareHostedComponent>> /**  */
	or?: Array<Maybe<FilterOfSoftwareHostedComponent>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareHostedComponent>> /**  */
	not?: Array<Maybe<FilterOfSoftwareHostedComponent>> /**  */
}


/**  */
export type QueryContextOfSoftwareHostedComponent = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareHostedComponent>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type SoftwarePackage */
export type FieldUpdateOperatorOfSoftwarePackage = {
	set?: Maybe<SetOperatorInputOfSoftwarePackage> /**  */
	push?: Maybe<PushUpdateOperatorInputSoftwarePackage> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSoftwarePackage> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwarePackage */
export type SetOperatorInputOfSoftwarePackage = {
	name?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	create?: Maybe<Date> /**  */
	validate?: Maybe<Date> /**  */
	userIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	softwareIds: Array<string> /**  */
	softwareGroupIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SoftwarePackage */
export type PushUpdateOperatorInputSoftwarePackage = {
	userIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	softwareIds: Array<string> /**  */
	softwareGroupIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité SoftwarePackage */
export type PullUpdateOperatorInputSoftwarePackage = {
	userIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	softwareIds: Array<string> /**  */
	softwareGroupIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfSoftwareReferentType = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSoftwareReferentType>> /**  */
	or?: Array<Maybe<FilterOfSoftwareReferentType>> /**  */
	nor?: Array<Maybe<FilterOfSoftwareReferentType>> /**  */
	not?: Array<Maybe<FilterOfSoftwareReferentType>> /**  */
}


/**  */
export type QueryContextOfSoftwareReferentType = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSoftwareReferentType>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type Software */
export type FieldUpdateOperatorOfSoftware = {
	set?: Maybe<SetOperatorInputOfSoftware> /**  */
	inc?: Maybe<IncOperatorInputSoftware> /**  */
	push?: Maybe<PushUpdateOperatorInputSoftware> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSoftware> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Software */
export type SetOperatorInputOfSoftware = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	internalName?: Maybe<string> /**  */
	internalCode?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hidden?: Maybe<boolean> /**  */
	notAllowed?: Maybe<boolean> /**  */
	obligatory?: Maybe<boolean> /**  */
	softwareAllowedType?: Maybe<SoftwareAllowedType> /**  */
	approved?: Maybe<boolean> /**  */
	recorded?: Maybe<boolean> /**  */
	publisher?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	softwareCategory?: Maybe<SoftwareCategory> /**  */
	manualData?: Maybe<boolean> /**  */
	softwareGroupId?: Maybe<string> /**  */
	softwarePackageIds: Array<string> /**  */
	installCount?: Maybe<number> /**  */
	contractIds: Array<string> /**  */
	auditType?: Maybe<AuditType> /**  */
	shortcutName?: Maybe<string> /**  */
	softwareAttributes: Array<SoftwareAttributeInput> /**  */
	ldapGroupDn: Array<string> /**  */
	softwareConnectionModeId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SoftwareAttributeInput = {
	name?: Maybe<string> /**  */
	softwareAttributeTypeId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Software */
export type IncOperatorInputSoftware = {
	installCount?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Software */
export type PushUpdateOperatorInputSoftware = {
	softwarePackageIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	softwareAttributes: Array<SoftwareAttributeInput> /**  */
	ldapGroupDn: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Software */
export type PullUpdateOperatorInputSoftware = {
	softwarePackageIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	softwareAttributes: Array<SoftwareAttributeInput> /**  */
	ldapGroupDn: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfSshCredential = {
	port?: Maybe<FilterInt32Operator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSshCredential>> /**  */
	or?: Array<Maybe<FilterOfSshCredential>> /**  */
	nor?: Array<Maybe<FilterOfSshCredential>> /**  */
	not?: Array<Maybe<FilterOfSshCredential>> /**  */
}


/**  */
export type QueryContextOfSshCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSshCredential>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité SshCredential */
export type QuerySortTypeSshCredential = {
	port?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type SshCredential */
export type FieldUpdateOperatorOfSshCredential = {
	set?: Maybe<SetOperatorInputOfSshCredential> /**  */
	inc?: Maybe<IncOperatorInputSshCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputSshCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSshCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SshCredential */
export type SetOperatorInputOfSshCredential = {
	port?: Maybe<number> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité SshCredential */
export type IncOperatorInputSshCredential = {
	port?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité SshCredential */
export type PushUpdateOperatorInputSshCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité SshCredential */
export type PullUpdateOperatorInputSshCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfStatisticTask = {
	ticketId?: Maybe<FilterGuidOperator> /**  */
	modifier?: Maybe<FilterTicketStatisticModifierOperator> /**  */
	savedTicket?: Maybe<FilterOfTicket> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStatisticTask>> /**  */
	or?: Array<Maybe<FilterOfStatisticTask>> /**  */
	nor?: Array<Maybe<FilterOfStatisticTask>> /**  */
	not?: Array<Maybe<FilterOfStatisticTask>> /**  */
}


/**  */
export type QueryContextOfStatisticTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStatisticTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité StatisticTask */
export type QuerySortTypeStatisticTask = {
	modifier?: Maybe<Sort> /** Représente le type de trie. */
	savedTicket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfStockInReason = {
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStockInReason>> /**  */
	or?: Array<Maybe<FilterOfStockInReason>> /**  */
	nor?: Array<Maybe<FilterOfStockInReason>> /**  */
	not?: Array<Maybe<FilterOfStockInReason>> /**  */
}


/**  */
export type QueryContextOfStockInReason = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStockInReason>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité StockInReason */
export type QuerySortTypeStockInReason = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfStockOutReason = {
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStockOutReason>> /**  */
	or?: Array<Maybe<FilterOfStockOutReason>> /**  */
	nor?: Array<Maybe<FilterOfStockOutReason>> /**  */
	not?: Array<Maybe<FilterOfStockOutReason>> /**  */
}


/**  */
export type QueryContextOfStockOutReason = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStockOutReason>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfStockTransferReason = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfStockTransferReason>> /**  */
	or?: Array<Maybe<FilterOfStockTransferReason>> /**  */
	nor?: Array<Maybe<FilterOfStockTransferReason>> /**  */
	not?: Array<Maybe<FilterOfStockTransferReason>> /**  */
}


/**  */
export type QueryContextOfStockTransferReason = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeStockTransferReason>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfSubstitute = {
	value?: Maybe<FilterStringOperator> /**  */
	replace?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfSubstitute>> /**  */
	or?: Array<Maybe<FilterOfSubstitute>> /**  */
	nor?: Array<Maybe<FilterOfSubstitute>> /**  */
	not?: Array<Maybe<FilterOfSubstitute>> /**  */
}


/**  */
export type QueryContextOfSubstitute = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeSubstitute>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Substitute */
export type QuerySortTypeSubstitute = {
	value?: Maybe<Sort> /** Représente le type de trie. */
	replace?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Supplier */
export type FieldUpdateOperatorOfSupplier = {
	set?: Maybe<SetOperatorInputOfSupplier> /**  */
	push?: Maybe<PushUpdateOperatorInputSupplier> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSupplier> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Supplier */
export type SetOperatorInputOfSupplier = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	naf?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	intraCommunity?: Maybe<string> /**  */
	hidden?: Maybe<boolean> /**  */
	webSite?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	contactIds: Array<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	serviceOfferIds: Array<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	assetModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
}


/** Représente les propriétés de type collection de l'entité Supplier */
export type PushUpdateOperatorInputSupplier = {
	contactIds: Array<string> /**  */
	serviceOfferIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Supplier */
export type PullUpdateOperatorInputSupplier = {
	contactIds: Array<string> /**  */
	serviceOfferIds: Array<string> /**  */
	assetModelIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type TaskSequence */
export type FieldUpdateOperatorOfTaskSequence = {
	set?: Maybe<SetOperatorInputOfTaskSequence> /**  */
	push?: Maybe<PushUpdateOperatorInputTaskSequence> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTaskSequence> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TaskSequence */
export type SetOperatorInputOfTaskSequence = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskIds: Array<string> /**  */
	activityId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskSequence */
export type PushUpdateOperatorInputTaskSequence = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	taskIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskSequence */
export type PullUpdateOperatorInputTaskSequence = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	taskIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTaskStatus = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	description?: Maybe<FilterOfTranslatedField> /**  */
	securityGroups?: Maybe<ArraySecurityGroupObjectOperator> /**  */
	securityGroup?: Maybe<FilterOfSecurityGroupObject> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTaskStatus>> /**  */
	or?: Array<Maybe<FilterOfTaskStatus>> /**  */
	nor?: Array<Maybe<FilterOfTaskStatus>> /**  */
	not?: Array<Maybe<FilterOfTaskStatus>> /**  */
}


/**  */
export type QueryContextOfTaskStatus = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTaskStatus>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type TaskStatus */
export type FieldUpdateOperatorOfTaskStatus = {
	set?: Maybe<SetOperatorInputOfTaskStatus> /**  */
	push?: Maybe<PushUpdateOperatorInputTaskStatus> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTaskStatus> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TaskStatus */
export type SetOperatorInputOfTaskStatus = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskStatus */
export type PushUpdateOperatorInputTaskStatus = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskStatus */
export type PullUpdateOperatorInputTaskStatus = {
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTemplate = {
	description?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTemplate>> /**  */
	or?: Array<Maybe<FilterOfTemplate>> /**  */
	nor?: Array<Maybe<FilterOfTemplate>> /**  */
	not?: Array<Maybe<FilterOfTemplate>> /**  */
}


/**  */
export type QueryContextOfTemplate = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTemplate>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Template */
export type QuerySortTypeTemplate = {
	description?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Template */
export type FieldUpdateOperatorOfTemplate = {
	set?: Maybe<SetOperatorInputOfTemplate> /**  */
	push?: Maybe<PushUpdateOperatorInputTemplate> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTemplate> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Template */
export type SetOperatorInputOfTemplate = {
	description?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Template */
export type PushUpdateOperatorInputTemplate = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Template */
export type PullUpdateOperatorInputTemplate = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTermsContract = {
	title?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTermsContract>> /**  */
	or?: Array<Maybe<FilterOfTermsContract>> /**  */
	nor?: Array<Maybe<FilterOfTermsContract>> /**  */
	not?: Array<Maybe<FilterOfTermsContract>> /**  */
}


/**  */
export type QueryContextOfTermsContract = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTermsContract>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TermsContract */
export type QuerySortTypeTermsContract = {
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type TicketAffectedRule */
export type FieldUpdateOperatorOfTicketAffectedRule = {
	set?: Maybe<SetOperatorInputOfTicketAffectedRule> /**  */
	inc?: Maybe<IncOperatorInputTicketAffectedRule> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketAffectedRule> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketAffectedRule> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketAffectedRule */
export type SetOperatorInputOfTicketAffectedRule = {
	categoryIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	applyOption?: Maybe<ApplyOption> /**  */
	referentOperatorId?: Maybe<string> /**  */
	referentOperatorTeamId?: Maybe<string> /**  */
	affectedOperatorId?: Maybe<string> /**  */
	affectedOperatorTeamId?: Maybe<string> /**  */
	supportLevel?: Maybe<number> /**  */
	qualification: Array<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketAffectedRule */
export type IncOperatorInputTicketAffectedRule = {
	supportLevel?: Maybe<number> /**  */
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketAffectedRule */
export type PushUpdateOperatorInputTicketAffectedRule = {
	categoryIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketAffectedRule */
export type PullUpdateOperatorInputTicketAffectedRule = {
	categoryIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTicketDailyBacklog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	createDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt64Operator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketDailyBacklog> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicketDailyBacklog> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketDailyBacklog> /**  */
	ticketBacklogType?: Maybe<FilterTicketBacklogTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketDailyBacklog>> /**  */
	or?: Array<Maybe<FilterOfTicketDailyBacklog>> /**  */
	nor?: Array<Maybe<FilterOfTicketDailyBacklog>> /**  */
	not?: Array<Maybe<FilterOfTicketDailyBacklog>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketDailyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicketDailyBacklog = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketDailyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketDailyBacklog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketDailyBacklog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketDailyBacklog */
export type QuerySortTypeTicketDailyBacklog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	createDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	ticketBacklogType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketDurationTask = {
	startDate?: Maybe<FilterDateTimeOperator> /**  */
	endDate?: Maybe<FilterDateTimeOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketDurationTask>> /**  */
	or?: Array<Maybe<FilterOfTicketDurationTask>> /**  */
	nor?: Array<Maybe<FilterOfTicketDurationTask>> /**  */
	not?: Array<Maybe<FilterOfTicketDurationTask>> /**  */
}


/**  */
export type QueryContextOfTicketDurationTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketDurationTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketDurationTask */
export type QuerySortTypeTicketDurationTask = {
	startDate?: Maybe<Sort> /** Représente le type de trie. */
	endDate?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfMailSlurpAttachmentMetaData = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeMailSlurpAttachmentMetaData>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité MailSlurpAttachmentMetaData */
export type QuerySortTypeMailSlurpAttachmentMetaData = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	contentType?: Maybe<Sort> /** Représente le type de trie. */
	contentLength?: Maybe<Sort> /** Représente le type de trie. */
	id?: Maybe<Sort> /** Représente le type de trie. */
	commentary?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketMonthlyBacklog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	createDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt64Operator> /**  */
	ticketBacklogType?: Maybe<FilterTicketBacklogTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketMonthlyBacklog> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicketMonthlyBacklog> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketMonthlyBacklog> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketMonthlyBacklog>> /**  */
	or?: Array<Maybe<FilterOfTicketMonthlyBacklog>> /**  */
	nor?: Array<Maybe<FilterOfTicketMonthlyBacklog>> /**  */
	not?: Array<Maybe<FilterOfTicketMonthlyBacklog>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketMonthlyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicketMonthlyBacklog = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketMonthlyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketMonthlyBacklog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketMonthlyBacklog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketMonthlyBacklog */
export type QuerySortTypeTicketMonthlyBacklog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	createDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	ticketBacklogType?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Ticket */
export type FieldUpdateOperatorOfTicket = {
	set?: Maybe<SetOperatorInputOfTicket> /**  */
	inc?: Maybe<IncOperatorInputTicket> /**  */
	push?: Maybe<PushUpdateOperatorInputTicket> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicket> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Ticket */
export type SetOperatorInputOfTicket = {
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident?: Maybe<number> /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags: Array<string> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes?: Maybe<number> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber?: Maybe<number> /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	emailConnectorId?: Maybe<string> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	isParent?: Maybe<boolean> /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	fieldParentUpdateToChild: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	appointmentConnector?: Maybe<SetOperatorInputOfAppointmentConnector> /**  */
	reminder?: Maybe<SetOperatorInputOfReminder> /**  */
	satisfaction?: Maybe<SetOperatorInputOfTicketSatisfaction> /**  */
}


/** Représente les propriétés de type numérique de l'entité Ticket */
export type IncOperatorInputTicket = {
	counterIncident?: Maybe<number> /**  */
	levelSupport?: Maybe<number> /**  */
	estimateMinutes?: Maybe<number> /**  */
	ticketNumber?: Maybe<number> /**  */
	elaspedTimeHour?: Maybe<number> /**  */
	takeChargeHours?: Maybe<number> /**  */
	endTreatmentHours?: Maybe<number> /**  */
	closedHours?: Maybe<number> /**  */
	reminder?: Maybe<IncOperatorInputReminder> /**  */
	satisfaction?: Maybe<IncOperatorInputTicketSatisfaction> /**  */
}


/** Représente les propriétés de type collection de l'entité Ticket */
export type PushUpdateOperatorInputTicket = {
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	fieldParentUpdateToChild: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Ticket */
export type PullUpdateOperatorInputTicket = {
	userIds: Array<string> /**  */
	tags: Array<string> /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	childrenIds: Array<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	fieldParentUpdateToChild: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTicketStatistic = {
	ticketId?: Maybe<FilterGuidOperator> /**  */
	elapsedSinceLastEvent?: Maybe<FilterDoubleOperator> /**  */
	savedTicket?: Maybe<FilterOfTicket> /**  */
	ticketBeforeState?: Maybe<FilterOfTicket> /**  */
	ticketEventDate?: Maybe<FilterDateTimeOperator> /**  */
	modifier?: Maybe<FilterStringOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketStatistic>> /**  */
	or?: Array<Maybe<FilterOfTicketStatistic>> /**  */
	nor?: Array<Maybe<FilterOfTicketStatistic>> /**  */
	not?: Array<Maybe<FilterOfTicketStatistic>> /**  */
}


/**  */
export type QueryContextOfTicketStatistic = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketStatistic>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketStatistic */
export type QuerySortTypeTicketStatistic = {
	elapsedSinceLastEvent?: Maybe<Sort> /** Représente le type de trie. */
	savedTicket?: Array<Maybe<QuerySortTypeTicket>> /**  */
	ticketBeforeState?: Array<Maybe<QuerySortTypeTicket>> /**  */
	ticketEventDate?: Maybe<Sort> /** Représente le type de trie. */
	modifier?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type TicketStatistic */
export type FieldUpdateOperatorOfTicketStatistic = {
	set?: Maybe<SetOperatorInputOfTicketStatistic> /**  */
	inc?: Maybe<IncOperatorInputTicketStatistic> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketStatistic> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketStatistic> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketStatistic */
export type SetOperatorInputOfTicketStatistic = {
	ticketId?: Maybe<string> /**  */
	elapsedSinceLastEvent?: Maybe<number> /**  */
	ticketEventDate?: Maybe<Date> /**  */
	modifier?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	savedTicket?: Maybe<SetOperatorInputOfTicket> /**  */
	ticketBeforeState?: Maybe<SetOperatorInputOfTicket> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketStatistic */
export type IncOperatorInputTicketStatistic = {
	elapsedSinceLastEvent?: Maybe<number> /**  */
	savedTicket?: Maybe<IncOperatorInputTicket> /**  */
	ticketBeforeState?: Maybe<IncOperatorInputTicket> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatistic */
export type PushUpdateOperatorInputTicketStatistic = {
	dynamicProperties?: Maybe<any> /**  */
	savedTicket?: Maybe<PushUpdateOperatorInputTicket> /**  */
	ticketBeforeState?: Maybe<PushUpdateOperatorInputTicket> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatistic */
export type PullUpdateOperatorInputTicketStatistic = {
	dynamicProperties?: Maybe<any> /**  */
	savedTicket?: Maybe<PullUpdateOperatorInputTicket> /**  */
	ticketBeforeState?: Maybe<PullUpdateOperatorInputTicket> /**  */
}


/** Représente les méthodes disponible pour le type TicketStatusReason */
export type FieldUpdateOperatorOfTicketStatusReason = {
	set?: Maybe<SetOperatorInputOfTicketStatusReason> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketStatusReason> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketStatusReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketStatusReason */
export type SetOperatorInputOfTicketStatusReason = {
	statusId?: Maybe<string> /**  */
	ticketsIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatusReason */
export type PushUpdateOperatorInputTicketStatusReason = {
	ticketsIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatusReason */
export type PullUpdateOperatorInputTicketStatusReason = {
	ticketsIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type TicketStatus */
export type FieldUpdateOperatorOfTicketStatus = {
	set?: Maybe<SetOperatorInputOfTicketStatus> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketStatus> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketStatus> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketStatus */
export type SetOperatorInputOfTicketStatus = {
	qualification: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatus */
export type PushUpdateOperatorInputTicketStatus = {
	qualification: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketStatus */
export type PullUpdateOperatorInputTicketStatus = {
	qualification: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTicketTag = {
	entityType?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketTag>> /**  */
	or?: Array<Maybe<FilterOfTicketTag>> /**  */
	nor?: Array<Maybe<FilterOfTicketTag>> /**  */
	not?: Array<Maybe<FilterOfTicketTag>> /**  */
}


/**  */
export type QueryContextOfTicketTag = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketTag>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketTag */
export type QuerySortTypeTicketTag = {
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketTagTask = {
	tags?: Maybe<ArrayStringTypeOperator> /**  */
	oldName?: Maybe<FilterStringOperator> /**  */
	newName?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketTagTask>> /**  */
	or?: Array<Maybe<FilterOfTicketTagTask>> /**  */
	nor?: Array<Maybe<FilterOfTicketTagTask>> /**  */
	not?: Array<Maybe<FilterOfTicketTagTask>> /**  */
}


/**  */
export type QueryContextOfTicketTagTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketTagTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketTagTask */
export type QuerySortTypeTicketTagTask = {
	tags?: Maybe<Sort> /** Représente le type de trie. */
	oldName?: Maybe<Sort> /** Représente le type de trie. */
	newName?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type TicketTaskModel */
export type FieldUpdateOperatorOfTicketTaskModel = {
	set?: Maybe<SetOperatorInputOfTicketTaskModel> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketTaskModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketTaskModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketTaskModel */
export type SetOperatorInputOfTicketTaskModel = {
	createdBy?: Maybe<string> /**  */
	qualification: Array<string> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	duration?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTaskModel */
export type PushUpdateOperatorInputTicketTaskModel = {
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTaskModel */
export type PullUpdateOperatorInputTicketTaskModel = {
	qualification: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type TicketTask */
export type FieldUpdateOperatorOfTicketTask = {
	set?: Maybe<SetOperatorInputOfTicketTask> /**  */
	inc?: Maybe<IncOperatorInputTicketTask> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketTask */
export type SetOperatorInputOfTicketTask = {
	childrenIds: Array<string> /**  */
	createdBy?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	interventionIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	scheduleAt?: Maybe<Date> /**  */
	endAt?: Maybe<Date> /**  */
	duration?: Maybe<string> /**  */
	durationMinutes?: Maybe<number> /**  */
	taskModel?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	order?: Maybe<number> /**  */
	statusId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskStatusWorkflowId?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
	taskNumber?: Maybe<number> /**  */
	isPredecessor?: Maybe<boolean> /**  */
	predecessorId?: Maybe<string> /**  */
	predecessorStatusId?: Maybe<string> /**  */
	successorsIds: Array<string> /**  */
	isGreyList?: Maybe<boolean> /**  */
	createdByTicketModel?: Maybe<boolean> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	appointmentConnector?: Maybe<SetOperatorInputOfAppointmentConnector> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketTask */
export type IncOperatorInputTicketTask = {
	durationMinutes?: Maybe<number> /**  */
	order?: Maybe<number> /**  */
	taskNumber?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTask */
export type PushUpdateOperatorInputTicketTask = {
	childrenIds: Array<string> /**  */
	interventionIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	successorsIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTask */
export type PullUpdateOperatorInputTicketTask = {
	childrenIds: Array<string> /**  */
	interventionIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	successorsIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfTicketWeeklyBacklog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	createDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt64Operator> /**  */
	ticketBacklogType?: Maybe<FilterTicketBacklogTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketWeeklyBacklog> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicketWeeklyBacklog> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketWeeklyBacklog> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketWeeklyBacklog>> /**  */
	or?: Array<Maybe<FilterOfTicketWeeklyBacklog>> /**  */
	nor?: Array<Maybe<FilterOfTicketWeeklyBacklog>> /**  */
	not?: Array<Maybe<FilterOfTicketWeeklyBacklog>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketWeeklyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicketWeeklyBacklog = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketWeeklyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketWeeklyBacklog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketWeeklyBacklog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketWeeklyBacklog */
export type QuerySortTypeTicketWeeklyBacklog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	createDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	ticketBacklogType?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfTicketYearlyBacklog = {
	date?: Maybe<FilterDateTimeOperator> /**  */
	createDate?: Maybe<FilterDateTimeOperator> /**  */
	qualification?: Maybe<FilterStringOperator> /**  */
	operatorTeamAffectedId?: Maybe<FilterGuidOperator> /**  */
	operatorAffectedId?: Maybe<FilterGuidOperator> /**  */
	statusId?: Maybe<FilterGuidOperator> /**  */
	levelSupport?: Maybe<FilterInt32Operator> /**  */
	total?: Maybe<FilterInt64Operator> /**  */
	ticketBacklogType?: Maybe<FilterTicketBacklogTypeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	operatorTeamAffected?: Maybe<FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketYearlyBacklog> /**  */
	operatorAffected?: Maybe<FilterDenormalizeOfUserByOperatorAffectedInTicketYearlyBacklog> /**  */
	status?: Maybe<FilterDenormalizeOfTicketStatusByStatusInTicketYearlyBacklog> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfTicketYearlyBacklog>> /**  */
	or?: Array<Maybe<FilterOfTicketYearlyBacklog>> /**  */
	nor?: Array<Maybe<FilterOfTicketYearlyBacklog>> /**  */
	not?: Array<Maybe<FilterOfTicketYearlyBacklog>> /**  */
}


/**  */
export type FilterDenormalizeOfOperatorTeamByOperatorTeamAffectedInTicketYearlyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type FilterDenormalizeOfUserByOperatorAffectedInTicketYearlyBacklog = {
	name?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type FilterDenormalizeOfTicketStatusByStatusInTicketYearlyBacklog = {
	name?: Maybe<FilterOfTranslatedField> /**  */
}


/**  */
export type QueryContextOfTicketYearlyBacklog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTicketYearlyBacklog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TicketYearlyBacklog */
export type QuerySortTypeTicketYearlyBacklog = {
	date?: Maybe<Sort> /** Représente le type de trie. */
	createDate?: Maybe<Sort> /** Représente le type de trie. */
	qualification?: Maybe<Sort> /** Représente le type de trie. */
	levelSupport?: Maybe<Sort> /** Représente le type de trie. */
	total?: Maybe<Sort> /** Représente le type de trie. */
	ticketBacklogType?: Maybe<Sort> /** Représente le type de trie. */
	operatorTeamAffected?: Array<Maybe<QuerySortTypeOperatorTeam>> /**  */
	operatorAffected?: Array<Maybe<QuerySortTypeUser>> /**  */
	status?: Array<Maybe<QuerySortTypeTicketStatus>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfTrackingEntity = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeTrackingEntity>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité TrackingEntity */
export type QuerySortTypeTrackingEntity = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	isTranslatable?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Urgency */
export type FieldUpdateOperatorOfUrgency = {
	set?: Maybe<SetOperatorInputOfUrgency> /**  */
	inc?: Maybe<IncOperatorInputUrgency> /**  */
	push?: Maybe<PushUpdateOperatorInputUrgency> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputUrgency> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Urgency */
export type SetOperatorInputOfUrgency = {
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Urgency */
export type IncOperatorInputUrgency = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Urgency */
export type PushUpdateOperatorInputUrgency = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Urgency */
export type PullUpdateOperatorInputUrgency = {
	qualification: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfUserAffectedRule = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserAffectedRule>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfUserPrivilege = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	isDefault?: Maybe<FilterBooleanOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	userIds?: Maybe<ArrayUuidTypeOperator> /**  */
	affectedUserEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	otherAffectedUserEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	responsableUserEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	accessTicketsObserverEntries?: Maybe<ArrayPrivilegeEntryOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserPrivilege>> /**  */
	or?: Array<Maybe<FilterOfUserPrivilege>> /**  */
	nor?: Array<Maybe<FilterOfUserPrivilege>> /**  */
	not?: Array<Maybe<FilterOfUserPrivilege>> /**  */
}


/**  */
export type QueryContextOfUserPrivilege = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserPrivilege>> /** Définit le trie les éléments. */
}


/** Représente les méthodes disponible pour le type UserPrivilege */
export type FieldUpdateOperatorOfUserPrivilege = {
	set?: Maybe<SetOperatorInputOfUserPrivilege> /**  */
	push?: Maybe<PushUpdateOperatorInputUserPrivilege> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputUserPrivilege> /**  */
}


/** Représente les propriétés de mise à jour de l'entité UserPrivilege */
export type SetOperatorInputOfUserPrivilege = {
	isDefault?: Maybe<boolean> /**  */
	defaultName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	affectedUserEntries: Array<PrivilegeEntryInput> /**  */
	otherAffectedUserEntries: Array<PrivilegeEntryInput> /**  */
	responsableUserEntries: Array<PrivilegeEntryInput> /**  */
	accessTicketsObserverEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité UserPrivilege */
export type PushUpdateOperatorInputUserPrivilege = {
	userIds: Array<string> /**  */
	affectedUserEntries: Array<PrivilegeEntryInput> /**  */
	otherAffectedUserEntries: Array<PrivilegeEntryInput> /**  */
	responsableUserEntries: Array<PrivilegeEntryInput> /**  */
	accessTicketsObserverEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité UserPrivilege */
export type PullUpdateOperatorInputUserPrivilege = {
	userIds: Array<string> /**  */
	affectedUserEntries: Array<PrivilegeEntryInput> /**  */
	otherAffectedUserEntries: Array<PrivilegeEntryInput> /**  */
	responsableUserEntries: Array<PrivilegeEntryInput> /**  */
	accessTicketsObserverEntries: Array<PrivilegeEntryInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfUserRole = {
	userId?: Maybe<FilterGuidOperator> /**  */
	roleId?: Maybe<FilterGuidOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfUserRole>> /**  */
	or?: Array<Maybe<FilterOfUserRole>> /**  */
	nor?: Array<Maybe<FilterOfUserRole>> /**  */
	not?: Array<Maybe<FilterOfUserRole>> /**  */
}


/**  */
export type QueryContextOfUserRole = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeUserRole>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité UserRole */
export type QuerySortTypeUserRole = {
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type UserRole */
export type FieldUpdateOperatorOfUserRole = {
	set?: Maybe<SetOperatorInputOfUserRole> /**  */
	push?: Maybe<PushUpdateOperatorInputUserRole> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputUserRole> /**  */
}


/** Représente les propriétés de mise à jour de l'entité UserRole */
export type SetOperatorInputOfUserRole = {
	userId?: Maybe<string> /**  */
	roleId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité UserRole */
export type PushUpdateOperatorInputUserRole = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité UserRole */
export type PullUpdateOperatorInputUserRole = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type QueryContextOfLayout = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeLayout>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité Layout */
export type QuerySortTypeLayout = {
	key?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	value?: Maybe<Sort> /** Représente le type de trie. */
	filterName?: Maybe<Sort> /** Représente le type de trie. */
	filterValue?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type User */
export type FieldUpdateOperatorOfUser = {
	set?: Maybe<SetOperatorInputOfUser> /**  */
	inc?: Maybe<IncOperatorInputUser> /**  */
	push?: Maybe<PushUpdateOperatorInputUser> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputUser> /**  */
}


/** Représente les propriétés de mise à jour de l'entité User */
export type SetOperatorInputOfUser = {
	subId?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	userAccountControl?: Maybe<number> /**  */
	userAccountDisabled?: Maybe<boolean> /**  */
	userAccountExpires?: Maybe<Date> /**  */
	initial?: Maybe<string> /**  */
	registrationNumber?: Maybe<string> /**  */
	vip?: Maybe<boolean> /**  */
	helpDeskActivated?: Maybe<boolean> /**  */
	function?: Maybe<string> /**  */
	instantMessaging?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	secretary?: Maybe<string> /**  */
	hiringDate?: Maybe<Date> /**  */
	exitDate?: Maybe<Date> /**  */
	lastLogOn?: Maybe<Date> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
	lastLogOff?: Maybe<Date> /**  */
	lastConnected?: Maybe<Date> /**  */
	accessFailedCount?: Maybe<number> /**  */
	homeDrive?: Maybe<string> /**  */
	homeDirectory?: Maybe<string> /**  */
	logonCount?: Maybe<number> /**  */
	profilePath?: Maybe<string> /**  */
	scriptPath?: Maybe<string> /**  */
	logOnTo?: Maybe<string> /**  */
	allowDialIn?: Maybe<boolean> /**  */
	officeName?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userPasswords: Array<UserPasswordInput> /**  */
	operatorTeamIds: Array<string> /**  */
	mainOperatorTeamId?: Maybe<string> /**  */
	isOperator?: Maybe<boolean> /**  */
	loans: Array<LoanInput> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations: Array<UserHabilitationInput> /**  */
	phones: Array<PhoneInput> /**  */
	webSites: Array<string> /**  */
	locations: Array<LocationInput> /**  */
	stockManagers: Array<LocationInput> /**  */
	locationId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	manager?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	containerDistinguishedName?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	lastImportAzureAd?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	principalPhone?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	principalHomePhone?: Maybe<string> /**  */
	principalIpPhone?: Maybe<string> /**  */
	principalMobile?: Maybe<string> /**  */
	principalPager?: Maybe<string> /**  */
	memberOf: Array<string> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
	assetIds: Array<string> /**  */
	category?: Maybe<UserCategory> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	forceLdapImport?: Maybe<boolean> /**  */
	domain?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	memberOfSecurityGroupIds: Array<SecurityGroupObjectInput> /**  */
	memberOfPopulationIds: Array<SecurityGroupObjectInput> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inviteById?: Maybe<string> /**  */
	revokeById?: Maybe<string> /**  */
	costHours?: Maybe<number> /**  */
	supportLevelOperator?: Maybe<number> /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	mainAssetId?: Maybe<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	privilegeId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	locationCharterAlreadyApply?: Maybe<boolean> /**  */
	securityGroupAlreadyApply?: Maybe<boolean> /**  */
	populationAlreadyApply?: Maybe<boolean> /**  */
	calendarId?: Maybe<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	operatorIncidentRuleId?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	operatorRequestRuleId?: Maybe<string> /**  */
	operatorProblemRuleId?: Maybe<string> /**  */
	serviceDeskActivated?: Maybe<boolean> /**  */
	dashboardIds: Array<string> /**  */
	allowEditOrganization?: Maybe<boolean> /**  */
	recipentSystemNotification?: Maybe<boolean> /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	userContractType?: Maybe<string> /**  */
	operatorIncidentListDesignerId?: Maybe<string> /**  */
	operatorRequestListDesignerId?: Maybe<string> /**  */
	operatorProblemListDesignerId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	settings?: Maybe<SetOperatorInputOfSettings> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
	invitation?: Maybe<SetOperatorInputOfInvitation> /**  */
	userAffectedRule?: Maybe<SetOperatorInputOfUserAffectedRule> /**  */
	inheritLocationCharterLocation?: Maybe<SetOperatorInputOfInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<SetOperatorInputOfInheritLocationCharter> /**  */
	entityLocationCharter?: Maybe<SetOperatorInputOfEntityLocationCharter> /**  */
}


/**  */
export type UserPasswordInput = {
	description?: Maybe<string> /**  */
	logIn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	authentificationTypeId?: Maybe<string> /**  */
	authentificationType?: Maybe<AuthentificationTypeInput> /**  */
}


/**  */
export type AuthentificationTypeInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	userPasswords?: Array<Maybe<UserPasswordInput>> /**  */
}


/**  */
export type LoanInput = {
	number: number /**  */
	slipNumber?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	provision?: Maybe<Date> /**  */
	returnDate?: Maybe<Date> /**  */
	realReturnDate?: Maybe<Date> /**  */
	duration: number /**  */
	information?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	accountIds?: Array<Maybe<UserAccountInput>> /**  */
	recordDate?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	contactId?: Maybe<string> /**  */
	loanStatusId?: Maybe<string> /**  */
	loanBorrowerType: LoanBorrowerType /**  */
	fileIds: Array<string> /**  */
}


/**  */
export type UserHabilitationInput = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	habilitationId?: Maybe<string> /**  */
	habilitation?: Maybe<HabilitationInput> /**  */
	permanent: boolean /**  */
}


/**  */
export type HabilitationInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	userHabilitations?: Array<Maybe<UserHabilitationInput>> /**  */
}


/**  */
export type PhoneInput = {
	number?: Maybe<string> /**  */
	phoneType: PhoneEnum /**  */
}


/**  */
export type GroupAzureAdInput = {
	objectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	memberOf?: Array<Maybe<string>> /**  */
	parentId?: Maybe<string> /**  */
	applicationAzureId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Settings */
export type SetOperatorInputOfSettings = {
	features: Array<FeatureInput> /**  */
	preferences?: Maybe<SetOperatorInputOfPreferences> /**  */
}


/**  */
export type FeatureInput = {
	name?: Maybe<string> /**  */
	rank: number /**  */
}


/** Représente les propriétés de mise à jour de l'entité Preferences */
export type SetOperatorInputOfPreferences = {
	lang?: Maybe<string> /**  */
	theme?: Maybe<string> /**  */
	dashboardId?: Maybe<string> /**  */
	paginate?: Maybe<number> /**  */
	layouts: Array<LayoutInput> /**  */
	defaultLayouts: Array<LayoutInput> /**  */
	pinStateMenu?: Maybe<boolean> /**  */
}


/**  */
export type LayoutInput = {
	key?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	filterName?: Maybe<string> /**  */
	filterValue?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Invitation */
export type SetOperatorInputOfInvitation = {
	sendDate?: Maybe<Date> /**  */
	acceptDate?: Maybe<Date> /**  */
	revokeDate?: Maybe<Date> /**  */
	token?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité UserAffectedRule */
export type SetOperatorInputOfUserAffectedRule = {
	byDistinguishedName?: Maybe<boolean> /**  */
	bySid?: Maybe<boolean> /**  */
	byEmail?: Maybe<boolean> /**  */
	byDisplayName?: Maybe<boolean> /**  */
	byUserPrincipalName?: Maybe<boolean> /**  */
	byLastNameAndFirstName?: Maybe<boolean> /**  */
	byDomain?: Maybe<boolean> /**  */
	byDifferentDomain?: Maybe<boolean> /**  */
	byAzureObjectId?: Maybe<boolean> /**  */
	byDomainEnum?: Maybe<ByDomainEnum> /**  */
	notTrackingFields: Array<string> /**  */
	enabled?: Maybe<boolean> /**  */
	searchInDeleted?: Maybe<boolean> /**  */
	searchInArchived?: Maybe<boolean> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité User */
export type IncOperatorInputUser = {
	userAccountControl?: Maybe<number> /**  */
	accessFailedCount?: Maybe<number> /**  */
	logonCount?: Maybe<number> /**  */
	costHours?: Maybe<number> /**  */
	supportLevelOperator?: Maybe<number> /**  */
	settings?: Maybe<IncOperatorInputSettings> /**  */
	userAffectedRule?: Maybe<IncOperatorInputUserAffectedRule> /**  */
	inheritLocationCharterLocation?: Maybe<IncOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<IncOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type numérique de l'entité Settings */
export type IncOperatorInputSettings = {
	preferences?: Maybe<IncOperatorInputPreferences> /**  */
}


/** Représente les propriétés de type numérique de l'entité Preferences */
export type IncOperatorInputPreferences = {
	paginate?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité UserAffectedRule */
export type IncOperatorInputUserAffectedRule = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité User */
export type PushUpdateOperatorInputUser = {
	userPasswords: Array<UserPasswordInput> /**  */
	operatorTeamIds: Array<string> /**  */
	loans: Array<LoanInput> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations: Array<UserHabilitationInput> /**  */
	phones: Array<PhoneInput> /**  */
	webSites: Array<string> /**  */
	locations: Array<LocationInput> /**  */
	stockManagers: Array<LocationInput> /**  */
	memberOf: Array<string> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
	assetIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	memberOfSecurityGroupIds: Array<SecurityGroupObjectInput> /**  */
	memberOfPopulationIds: Array<SecurityGroupObjectInput> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	dashboardIds: Array<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	settings?: Maybe<PushUpdateOperatorInputSettings> /**  */
	userAffectedRule?: Maybe<PushUpdateOperatorInputUserAffectedRule> /**  */
	inheritLocationCharterLocation?: Maybe<PushUpdateOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<PushUpdateOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type collection de l'entité Settings */
export type PushUpdateOperatorInputSettings = {
	features: Array<FeatureInput> /**  */
	preferences?: Maybe<PushUpdateOperatorInputPreferences> /**  */
}


/** Représente les propriétés de type collection de l'entité Preferences */
export type PushUpdateOperatorInputPreferences = {
	layouts: Array<LayoutInput> /**  */
	defaultLayouts: Array<LayoutInput> /**  */
}


/** Représente les propriétés de type collection de l'entité UserAffectedRule */
export type PushUpdateOperatorInputUserAffectedRule = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité User */
export type PullUpdateOperatorInputUser = {
	userPasswords: Array<UserPasswordInput> /**  */
	operatorTeamIds: Array<string> /**  */
	loans: Array<LoanInput> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations: Array<UserHabilitationInput> /**  */
	phones: Array<PhoneInput> /**  */
	webSites: Array<string> /**  */
	locations: Array<LocationInput> /**  */
	stockManagers: Array<LocationInput> /**  */
	memberOf: Array<string> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
	assetIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	memberOfSecurityGroupIds: Array<SecurityGroupObjectInput> /**  */
	memberOfPopulationIds: Array<SecurityGroupObjectInput> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	dashboardIds: Array<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	dynamicProperties?: Maybe<any> /**  */
	settings?: Maybe<PullUpdateOperatorInputSettings> /**  */
	userAffectedRule?: Maybe<PullUpdateOperatorInputUserAffectedRule> /**  */
	inheritLocationCharterLocation?: Maybe<PullUpdateOperatorInputInheritLocationCharter> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<PullUpdateOperatorInputInheritLocationCharter> /**  */
}


/** Représente les propriétés de type collection de l'entité Settings */
export type PullUpdateOperatorInputSettings = {
	features: Array<FeatureInput> /**  */
	preferences?: Maybe<PullUpdateOperatorInputPreferences> /**  */
}


/** Représente les propriétés de type collection de l'entité Preferences */
export type PullUpdateOperatorInputPreferences = {
	layouts: Array<LayoutInput> /**  */
	defaultLayouts: Array<LayoutInput> /**  */
}


/** Représente les propriétés de type collection de l'entité UserAffectedRule */
export type PullUpdateOperatorInputUserAffectedRule = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type VerifaliaEmailCheck */
export type FieldUpdateOperatorOfVerifaliaEmailCheck = {
	set?: Maybe<SetOperatorInputOfVerifaliaEmailCheck> /**  */
	inc?: Maybe<IncOperatorInputVerifaliaEmailCheck> /**  */
	push?: Maybe<PushUpdateOperatorInputVerifaliaEmailCheck> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputVerifaliaEmailCheck> /**  */
}


/** Représente les propriétés de mise à jour de l'entité VerifaliaEmailCheck */
export type SetOperatorInputOfVerifaliaEmailCheck = {
	email?: Maybe<string> /**  */
	expireUtc?: Maybe<Date> /**  */
	status?: Maybe<number> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité VerifaliaEmailCheck */
export type IncOperatorInputVerifaliaEmailCheck = {
	status?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité VerifaliaEmailCheck */
export type PushUpdateOperatorInputVerifaliaEmailCheck = {
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité VerifaliaEmailCheck */
export type PullUpdateOperatorInputVerifaliaEmailCheck = {
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfVerifaliaTask = {
	jobId?: Maybe<FilterGuidOperator> /**  */
	emailsToCheck?: Maybe<ArrayStringTypeOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfVerifaliaTask>> /**  */
	or?: Array<Maybe<FilterOfVerifaliaTask>> /**  */
	nor?: Array<Maybe<FilterOfVerifaliaTask>> /**  */
	not?: Array<Maybe<FilterOfVerifaliaTask>> /**  */
}


/**  */
export type QueryContextOfVerifaliaTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeVerifaliaTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité VerifaliaTask */
export type QuerySortTypeVerifaliaTask = {
	emailsToCheck?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWarningCustomer = {
	created?: Maybe<FilterOfEntityAttribute> /**  */
	updated?: Maybe<FilterOfEntityAttribute> /**  */
	title?: Maybe<FilterOfTranslatedField> /**  */
	content?: Maybe<FilterOfTranslatedField> /**  */
	start?: Maybe<FilterDateTimeOperator> /**  */
	end?: Maybe<FilterDateTimeOperator> /**  */
	enable?: Maybe<FilterBooleanOperator> /**  */
	availableForHelpMe?: Maybe<FilterBooleanOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWarningCustomer>> /**  */
	or?: Array<Maybe<FilterOfWarningCustomer>> /**  */
	nor?: Array<Maybe<FilterOfWarningCustomer>> /**  */
	not?: Array<Maybe<FilterOfWarningCustomer>> /**  */
}


/**  */
export type QueryContextOfWarningCustomer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWarningCustomer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WarningCustomer */
export type QuerySortTypeWarningCustomer = {
	created?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	updated?: Array<Maybe<QuerySortTypeEntityAttribute>> /**  */
	title?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	content?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	start?: Maybe<Sort> /** Représente le type de trie. */
	end?: Maybe<Sort> /** Représente le type de trie. */
	enable?: Maybe<Sort> /** Représente le type de trie. */
	availableForHelpMe?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWarningViewCustomer = {
	warningCustomerId?: Maybe<FilterGuidOperator> /**  */
	userId?: Maybe<FilterGuidOperator> /**  */
	lastDisplay?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWarningViewCustomer>> /**  */
	or?: Array<Maybe<FilterOfWarningViewCustomer>> /**  */
	nor?: Array<Maybe<FilterOfWarningViewCustomer>> /**  */
	not?: Array<Maybe<FilterOfWarningViewCustomer>> /**  */
}


/**  */
export type QueryContextOfWarningViewCustomer = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWarningViewCustomer>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WarningViewCustomer */
export type QuerySortTypeWarningViewCustomer = {
	lastDisplay?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWarrantyLinkVendor = {
	vendor?: Maybe<FilterStringOperator> /**  */
	manufacturers?: Maybe<ArrayStringTypeOperator> /**  */
	displayLink?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWarrantyLinkVendor>> /**  */
	or?: Array<Maybe<FilterOfWarrantyLinkVendor>> /**  */
	nor?: Array<Maybe<FilterOfWarrantyLinkVendor>> /**  */
	not?: Array<Maybe<FilterOfWarrantyLinkVendor>> /**  */
}


/**  */
export type QueryContextOfWarrantyLinkVendor = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWarrantyLinkVendor>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WarrantyLinkVendor */
export type QuerySortTypeWarrantyLinkVendor = {
	vendor?: Maybe<Sort> /** Représente le type de trie. */
	displayLink?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWmiCredential = {
	domain?: Maybe<FilterStringOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	description?: Maybe<FilterStringOperator> /**  */
	logOn?: Maybe<FilterStringOperator> /**  */
	password?: Maybe<FilterStringOperator> /**  */
	verifyPassword?: Maybe<FilterStringOperator> /**  */
	scanConfigurationIds?: Maybe<ArrayUuidTypeOperator> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<FilterBooleanOperator> /**  */
	attributes?: Maybe<FilterOfEntityAttributes> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWmiCredential>> /**  */
	or?: Array<Maybe<FilterOfWmiCredential>> /**  */
	nor?: Array<Maybe<FilterOfWmiCredential>> /**  */
	not?: Array<Maybe<FilterOfWmiCredential>> /**  */
}


/**  */
export type QueryContextOfWmiCredential = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWmiCredential>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WmiCredential */
export type QuerySortTypeWmiCredential = {
	domain?: Maybe<Sort> /** Représente le type de trie. */
	name?: Maybe<Sort> /** Représente le type de trie. */
	description?: Maybe<Sort> /** Représente le type de trie. */
	logOn?: Maybe<Sort> /** Représente le type de trie. */
	password?: Maybe<Sort> /** Représente le type de trie. */
	verifyPassword?: Maybe<Sort> /** Représente le type de trie. */
	scanConfigurationIds?: Maybe<Sort> /** Représente le type de trie. */
	dynamicProperties?: Maybe<any> /** Représente le type de trie. */
	isApproved?: Maybe<Sort> /** Représente le type de trie. */
	attributes?: Array<Maybe<QuerySortTypeEntityAttributes>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type WmiCredential */
export type FieldUpdateOperatorOfWmiCredential = {
	set?: Maybe<SetOperatorInputOfWmiCredential> /**  */
	push?: Maybe<PushUpdateOperatorInputWmiCredential> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWmiCredential> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WmiCredential */
export type SetOperatorInputOfWmiCredential = {
	domain?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WmiCredential */
export type PushUpdateOperatorInputWmiCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité WmiCredential */
export type PullUpdateOperatorInputWmiCredential = {
	scanConfigurationIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfWorkflowDelete = {
	workflowId?: Maybe<FilterGuidOperator> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowDelete> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowDelete>> /**  */
	or?: Array<Maybe<FilterOfWorkflowDelete>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowDelete>> /**  */
	not?: Array<Maybe<FilterOfWorkflowDelete>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowDelete = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowDelete = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowDelete>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowDelete */
export type QuerySortTypeWorkflowDelete = {
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowHttpRequest = {
	httpOutputConfig?: Maybe<FilterOfHttpOutput> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowHttpRequest> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowHttpRequest>> /**  */
	or?: Array<Maybe<FilterOfWorkflowHttpRequest>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowHttpRequest>> /**  */
	not?: Array<Maybe<FilterOfWorkflowHttpRequest>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowHttpRequest = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowHttpRequest = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowHttpRequest>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowHttpRequest */
export type QuerySortTypeWorkflowHttpRequest = {
	httpOutputConfig?: Array<Maybe<QuerySortTypeHttpOutput>> /**  */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowIntervalTask = {
	workflowId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowIntervalTask>> /**  */
	or?: Array<Maybe<FilterOfWorkflowIntervalTask>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowIntervalTask>> /**  */
	not?: Array<Maybe<FilterOfWorkflowIntervalTask>> /**  */
}


/**  */
export type QueryContextOfWorkflowIntervalTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowIntervalTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowIntervalTask */
export type QuerySortTypeWorkflowIntervalTask = {
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfWorkflowInvitation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowInvitation>> /** Définit le trie les éléments. */
}


/**  */
export type FilterOfWorkflowInvitationTask = {
	workflowInvitation?: Maybe<FilterOfWorkflowInvitation> /**  */
	authorId?: Maybe<FilterGuidOperator> /**  */
	origin?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowInvitationTask>> /**  */
	or?: Array<Maybe<FilterOfWorkflowInvitationTask>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowInvitationTask>> /**  */
	not?: Array<Maybe<FilterOfWorkflowInvitationTask>> /**  */
}


/**  */
export type QueryContextOfWorkflowInvitationTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowInvitationTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowInvitationTask */
export type QuerySortTypeWorkflowInvitationTask = {
	workflowInvitation?: Array<Maybe<QuerySortTypeWorkflowInvitation>> /**  */
	origin?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowLog = {
	created?: Maybe<FilterDateTimeOperator> /**  */
	name?: Maybe<FilterOfTranslatedField> /**  */
	type?: Maybe<FilterStringOperator> /**  */
	propertyName?: Maybe<FilterStringOperator> /**  */
	workflowActions?: Maybe<ArrayWorkflowActionOperator> /**  */
	state?: Maybe<FilterBooleanOperator> /**  */
	error?: Maybe<FilterStringOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflowType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityDisplay?: Maybe<FilterStringOperator> /**  */
	ticketId?: Maybe<FilterGuidOperator> /**  */
	ticketType?: Maybe<FilterStringOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowLog>> /**  */
	or?: Array<Maybe<FilterOfWorkflowLog>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowLog>> /**  */
	not?: Array<Maybe<FilterOfWorkflowLog>> /**  */
}


/**  */
export type ArrayWorkflowActionOperator = {
	elemMatch?: Maybe<FilterOfWorkflowAction> /**  */
}


/**  */
export type QueryContextOfWorkflowLog = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowLog>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowLog */
export type QuerySortTypeWorkflowLog = {
	created?: Maybe<Sort> /** Représente le type de trie. */
	name?: Array<Maybe<QuerySortTypeTranslatedField>> /**  */
	type?: Maybe<Sort> /** Représente le type de trie. */
	propertyName?: Maybe<Sort> /** Représente le type de trie. */
	workflowActions?: Maybe<Sort> /** Représente le type de trie. */
	state?: Maybe<Sort> /** Représente le type de trie. */
	error?: Maybe<Sort> /** Représente le type de trie. */
	workflowType?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	entityDisplay?: Maybe<Sort> /** Représente le type de trie. */
	ticketType?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowMailTask = {
	workflowMail?: Maybe<FilterOfWorkflowMail> /**  */
	callerId?: Maybe<FilterGuidOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowMailTask>> /**  */
	or?: Array<Maybe<FilterOfWorkflowMailTask>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowMailTask>> /**  */
	not?: Array<Maybe<FilterOfWorkflowMailTask>> /**  */
}


/**  */
export type QueryContextOfWorkflowMailTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowMailTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowMailTask */
export type QuerySortTypeWorkflowMailTask = {
	workflowMail?: Array<Maybe<QuerySortTypeWorkflowMail>> /**  */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowManaged = {
	managedKey?: Maybe<FilterStringOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowManaged> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowManaged>> /**  */
	or?: Array<Maybe<FilterOfWorkflowManaged>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowManaged>> /**  */
	not?: Array<Maybe<FilterOfWorkflowManaged>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowManaged = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowManaged = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowManaged>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowManaged */
export type QuerySortTypeWorkflowManaged = {
	managedKey?: Maybe<Sort> /** Représente le type de trie. */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowNotificationTeams = {
	recipient?: Maybe<FilterStringOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowNotificationTeams> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowNotificationTeams>> /**  */
	or?: Array<Maybe<FilterOfWorkflowNotificationTeams>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowNotificationTeams>> /**  */
	not?: Array<Maybe<FilterOfWorkflowNotificationTeams>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowNotificationTeams = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowNotificationTeams = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowNotificationTeams>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowNotificationTeams */
export type QuerySortTypeWorkflowNotificationTeams = {
	recipient?: Maybe<Sort> /** Représente le type de trie. */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowProperty = {
	workflowPropertyValue?: Maybe<FilterOfWorkflowPropertyValue> /**  */
	workflowPropertyState?: Maybe<FilterOfWorkflowPropertyState> /**  */
	propertyName?: Maybe<FilterStringOperator> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowProperty> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowProperty>> /**  */
	or?: Array<Maybe<FilterOfWorkflowProperty>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowProperty>> /**  */
	not?: Array<Maybe<FilterOfWorkflowProperty>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowProperty = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowProperty = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowProperty>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowProperty */
export type QuerySortTypeWorkflowProperty = {
	workflowPropertyValue?: Array<Maybe<QuerySortTypeWorkflowPropertyValue>> /**  */
	workflowPropertyState?: Array<Maybe<QuerySortTypeWorkflowPropertyState>> /**  */
	propertyName?: Maybe<Sort> /** Représente le type de trie. */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowRevocation = {
	user?: Maybe<FilterOfUser> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowRevocation> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowRevocation>> /**  */
	or?: Array<Maybe<FilterOfWorkflowRevocation>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowRevocation>> /**  */
	not?: Array<Maybe<FilterOfWorkflowRevocation>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowRevocation = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowRevocation = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowRevocation>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowRevocation */
export type QuerySortTypeWorkflowRevocation = {
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkflowRevocationWithoutEmail = {
	user?: Maybe<FilterOfUser> /**  */
	workflowId?: Maybe<FilterGuidOperator> /**  */
	workflow?: Maybe<FilterDenormalizeOfWorkflowByWorkflowInWorkflowRevocationWithoutEmail> /**  */
	name?: Maybe<FilterStringOperator> /**  */
	key?: Maybe<FilterStringOperator> /**  */
	defaultName?: Maybe<FilterStringOperator> /**  */
	workflowTrigger?: Maybe<FilterWorkflowTriggerOperator> /**  */
	optionSave?: Maybe<FilterOfOptionSave> /**  */
	optionDelete?: Maybe<FilterOfOptionDelete> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowRevocationWithoutEmail>> /**  */
	or?: Array<Maybe<FilterOfWorkflowRevocationWithoutEmail>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowRevocationWithoutEmail>> /**  */
	not?: Array<Maybe<FilterOfWorkflowRevocationWithoutEmail>> /**  */
}


/**  */
export type FilterDenormalizeOfWorkflowByWorkflowInWorkflowRevocationWithoutEmail = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
}


/**  */
export type QueryContextOfWorkflowRevocationWithoutEmail = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowRevocationWithoutEmail>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowRevocationWithoutEmail */
export type QuerySortTypeWorkflowRevocationWithoutEmail = {
	user?: Array<Maybe<QuerySortTypeUser>> /**  */
	workflow?: Array<Maybe<QuerySortTypeWorkflow>> /**  */
	name?: Maybe<Sort> /** Représente le type de trie. */
	key?: Maybe<Sort> /** Représente le type de trie. */
	defaultName?: Maybe<Sort> /** Représente le type de trie. */
	workflowTrigger?: Maybe<Sort> /** Représente le type de trie. */
	optionSave?: Array<Maybe<QuerySortTypeOptionSave>> /**  */
	optionDelete?: Array<Maybe<QuerySortTypeOptionDelete>> /**  */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/** Représente les méthodes disponible pour le type Workflow */
export type FieldUpdateOperatorOfWorkflow = {
	set?: Maybe<SetOperatorInputOfWorkflow> /**  */
	inc?: Maybe<IncOperatorInputWorkflow> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflow> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflow> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Workflow */
export type SetOperatorInputOfWorkflow = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	expression?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	entityType?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
	enableDelay?: Maybe<boolean> /**  */
	enableInterval?: Maybe<boolean> /**  */
	priority?: Maybe<number> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	managedChooseForApply?: Maybe<UserOrAsset> /**  */
	copyFromId?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	delayConfig?: Maybe<SetOperatorInputOfWorkflowDelay> /**  */
	intervalConfig?: Maybe<SetOperatorInputOfWorkflowDelay> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowDelay */
export type SetOperatorInputOfWorkflowDelay = {
	key?: Maybe<string> /**  */
	property?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
	displayName?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Workflow */
export type IncOperatorInputWorkflow = {
	priority?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Workflow */
export type PushUpdateOperatorInputWorkflow = {
	expression?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité Workflow */
export type PullUpdateOperatorInputWorkflow = {
	expression?: Maybe<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type FilterOfWorkflowScheduleTask = {
	workflowLinkedObjectId?: Maybe<FilterGuidOperator> /**  */
	workflowLinkedObjectType?: Maybe<FilterStringOperator> /**  */
	status?: Maybe<FilterScheduleTaskStatusOperator> /**  */
	dueDate?: Maybe<FilterDateTimeOperator> /**  */
	createdDate?: Maybe<FilterDateTimeOperator> /**  */
	lastModificationDate?: Maybe<FilterDateTimeOperator> /**  */
	entityType?: Maybe<FilterStringOperator> /**  */
	entityId?: Maybe<FilterGuidOperator> /**  */
	lockPodGuid?: Maybe<FilterGuidOperator> /**  */
	lockGuid?: Maybe<FilterGuidOperator> /**  */
	lockPodOn?: Maybe<FilterDateTimeOperator> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkflowScheduleTask>> /**  */
	or?: Array<Maybe<FilterOfWorkflowScheduleTask>> /**  */
	nor?: Array<Maybe<FilterOfWorkflowScheduleTask>> /**  */
	not?: Array<Maybe<FilterOfWorkflowScheduleTask>> /**  */
}


/**  */
export type QueryContextOfWorkflowScheduleTask = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkflowScheduleTask>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité WorkflowScheduleTask */
export type QuerySortTypeWorkflowScheduleTask = {
	workflowLinkedObjectType?: Maybe<Sort> /** Représente le type de trie. */
	status?: Maybe<Sort> /** Représente le type de trie. */
	dueDate?: Maybe<Sort> /** Représente le type de trie. */
	createdDate?: Maybe<Sort> /** Représente le type de trie. */
	lastModificationDate?: Maybe<Sort> /** Représente le type de trie. */
	entityType?: Maybe<Sort> /** Représente le type de trie. */
	lockPodOn?: Maybe<Sort> /** Représente le type de trie. */
	externalObjectId?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type FilterOfWorkTime = {
	name?: Maybe<FilterOfTranslatedField> /**  */
	enabled?: Maybe<FilterBooleanOperator> /**  */
	config?: Maybe<FilterOfWorkTimeConfig> /**  */
	id?: Maybe<FilterGuidOperator> /**  */
	externalObjectId?: Maybe<FilterStringOperator> /**  */
	and?: Array<Maybe<FilterOfWorkTime>> /**  */
	or?: Array<Maybe<FilterOfWorkTime>> /**  */
	nor?: Array<Maybe<FilterOfWorkTime>> /**  */
	not?: Array<Maybe<FilterOfWorkTime>> /**  */
}


/**  */
export type QueryContextOfWorkTime = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeWorkTime>> /** Définit le trie les éléments. */
}


/**  */
export type QueryContextOfOrganizationResult = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
	sort?: Array<Maybe<QuerySortTypeOrganizationResult>> /** Définit le trie les éléments. */
}


/** Représente les propriétés triables de l'entité OrganizationResult */
export type QuerySortTypeOrganizationResult = {
	userIsReponsible?: Maybe<Sort> /** Représente le type de trie. */
	roles?: Maybe<Sort> /** Représente le type de trie. */
	permissions?: Maybe<Sort> /** Représente le type de trie. */
	securityGroupIds?: Maybe<Sort> /** Représente le type de trie. */
	roleTypes?: Maybe<Sort> /** Représente le type de trie. */
	organization?: Array<Maybe<QuerySortTypeOrganization>> /**  */
	userLanguage?: Maybe<Sort> /** Représente le type de trie. */
	isOperator?: Maybe<Sort> /** Représente le type de trie. */
}


/**  */
export type QueryContextOfMyOrganizationQueriesResolver = {
	textSearch?: Maybe<string> /** Definit la valeur pour la propriété de recherche globale. */
	skip?: Maybe<number> /** Ignore un nombre spécifié d'éléments dans une séquence puis retourne les éléments restants. */
	limit?: Maybe<number> /** Retourne un nombre spécifié d'éléments contigus à partir du début d'une séquence. */
}


/**  */
export type ActivityInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
}


/** Représente les méthodes disponible pour le type AffectedRule */
export type FieldUpdateOperatorOfAffectedRule = {
	set?: Maybe<SetOperatorInputOfAffectedRule> /**  */
	inc?: Maybe<IncOperatorInputAffectedRule> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AffectedRule */
export type SetOperatorInputOfAffectedRule = {
	enabled?: Maybe<boolean> /**  */
	searchInDeleted?: Maybe<boolean> /**  */
	searchInArchived?: Maybe<boolean> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité AffectedRule */
export type IncOperatorInputAffectedRule = {
	rankOrder?: Maybe<number> /**  */
}


/**  */
export type AgentConfigurationInput = {
	configuration?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AgentConfiguration */
export type FieldUpdateOperatorOfAgentConfiguration = {
	set?: Maybe<SetOperatorInputOfAgentConfiguration> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AgentConfiguration */
export type SetOperatorInputOfAgentConfiguration = {
	configuration?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AgentHistory */
export type FieldUpdateOperatorOfAgentHistory = {
	set?: Maybe<SetOperatorInputOfAgentHistory> /**  */
	inc?: Maybe<IncOperatorInputAgentHistory> /**  */
	push?: Maybe<PushUpdateOperatorInputAgentHistory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAgentHistory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AgentHistory */
export type SetOperatorInputOfAgentHistory = {
	start?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	actionHistoryState?: Maybe<ActionHistoryState> /**  */
	host?: Maybe<string> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	log?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	hidden?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	clarilogException?: Maybe<SetOperatorInputOfClarilogException> /**  */
	assetAffectedRule?: Maybe<SetOperatorInputOfAssetAffectedRule> /**  */
}


/** Représente les propriétés de type numérique de l'entité AgentHistory */
export type IncOperatorInputAgentHistory = {
	progressValue?: Maybe<number> /**  */
	assetAffectedRule?: Maybe<IncOperatorInputAssetAffectedRule> /**  */
}


/** Représente les propriétés de type collection de l'entité AgentHistory */
export type PushUpdateOperatorInputAgentHistory = {
	assetAffectedRule?: Maybe<PushUpdateOperatorInputAssetAffectedRule> /**  */
}


/** Représente les propriétés de type collection de l'entité AgentHistory */
export type PullUpdateOperatorInputAgentHistory = {
	assetAffectedRule?: Maybe<PullUpdateOperatorInputAssetAffectedRule> /**  */
}


/** Représente les méthodes disponible pour le type AgentToken */
export type FieldUpdateOperatorOfAgentToken = {
	set?: Maybe<SetOperatorInputOfAgentToken> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AgentToken */
export type SetOperatorInputOfAgentToken = {
	date?: Maybe<Date> /**  */
	agentTokenAction?: Maybe<AgentTokenAction> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	agentId?: Maybe<string> /**  */
	query?: Maybe<string> /**  */
	queryName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AlarmMailTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	emailPrototypeId: string /**  */
	queryContext?: Maybe<string> /**  */
	force: boolean /**  */
}


/** Représente les méthodes disponible pour le type AlarmMailTask */
export type FieldUpdateOperatorOfAlarmMailTask = {
	set?: Maybe<SetOperatorInputOfAlarmMailTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AlarmMailTask */
export type SetOperatorInputOfAlarmMailTask = {
	emailPrototypeId?: Maybe<string> /**  */
	queryContext?: Maybe<string> /**  */
	force?: Maybe<boolean> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AlarmInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	type?: Maybe<string> /**  */
	category: AlarmCategoryEnum /**  */
	triggerType: AlarmTriggerTypeEnum /**  */
	expression?: Maybe<string> /**  */
	enabled: boolean /**  */
	trigger?: Maybe<any> /**  */
	managedName: ManagedAlarmEnum /**  */
	interval: number /**  */
	time?: Maybe<Date> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	allRecipient: boolean /**  */
	forceShowAlert: boolean /**  */
	recipientproperties?: Array<Maybe<string>> /**  */
}


/** Représente les méthodes disponible pour le type Alarm */
export type FieldUpdateOperatorOfAlarm = {
	set?: Maybe<SetOperatorInputOfAlarm> /**  */
	inc?: Maybe<IncOperatorInputAlarm> /**  */
	push?: Maybe<PushUpdateOperatorInputAlarm> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAlarm> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Alarm */
export type SetOperatorInputOfAlarm = {
	type?: Maybe<string> /**  */
	category?: Maybe<AlarmCategoryEnum> /**  */
	triggerType?: Maybe<AlarmTriggerTypeEnum> /**  */
	expression?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	managedName?: Maybe<ManagedAlarmEnum> /**  */
	interval?: Maybe<number> /**  */
	time?: Maybe<Date> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	allRecipient?: Maybe<boolean> /**  */
	forceShowAlert?: Maybe<boolean> /**  */
	recipientproperties: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Alarm */
export type IncOperatorInputAlarm = {
	interval?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Alarm */
export type PushUpdateOperatorInputAlarm = {
	expression?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	recipientproperties: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Alarm */
export type PullUpdateOperatorInputAlarm = {
	expression?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	recipientproperties: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type AlertInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	alarmId: string /**  */
	dateOfCreation?: Maybe<Date> /**  */
	acknowledgedUsersIds: Array<string> /**  */
	affectedUsersIds: Array<string> /**  */
	hiddenUsersIds: Array<string> /**  */
	hidden: boolean /**  */
	assetId?: Maybe<string> /**  */
	softwareId?: Maybe<string> /**  */
	softwarePackageId?: Maybe<string> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	budgetId?: Maybe<string> /**  */
	contractId?: Maybe<string> /**  */
	loanId?: Maybe<string> /**  */
	assetModelId?: Maybe<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	logicalDiskId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	printerConsumableId?: Maybe<string> /**  */
	messageId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	softwareGroupId?: Maybe<string> /**  */
	problemId?: Maybe<string> /**  */
	incidentId?: Maybe<string> /**  */
	requestId?: Maybe<string> /**  */
	ticketTaskId?: Maybe<string> /**  */
	interventionId?: Maybe<string> /**  */
	minimumStockByStorageUnitAlertResult?: Maybe<MinimumStockByStorageUnitAlertResultInput> /**  */
	sended: boolean /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
}


/**  */
export type MinimumStockByStorageUnitAlertResultInput = {
	minimum: number /**  */
	currentAmount: number /**  */
}


/**  */
export type AlertTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	alarmTriggerType: AlarmTriggerTypeEnum /**  */
	oldEntity?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type AlertTask */
export type FieldUpdateOperatorOfAlertTask = {
	set?: Maybe<SetOperatorInputOfAlertTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AlertTask */
export type SetOperatorInputOfAlertTask = {
	alarmTriggerType?: Maybe<AlarmTriggerTypeEnum> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AnalyseScanByAzureAdTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type AnalyseScanByAzureAdTask */
export type FieldUpdateOperatorOfAnalyseScanByAzureAdTask = {
	set?: Maybe<SetOperatorInputOfAnalyseScanByAzureAdTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AnalyseScanByAzureAdTask */
export type SetOperatorInputOfAnalyseScanByAzureAdTask = {
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type ApplicationAzureAdInput = {
	name?: Maybe<string> /**  */
	tenantAzureId?: Maybe<string> /**  */
	clientId?: Maybe<string> /**  */
	clientSecret?: Maybe<string> /**  */
	expireClientSecretDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
}


/** Représente les méthodes disponible pour le type ApplicationAzureAd */
export type FieldUpdateOperatorOfApplicationAzureAd = {
	set?: Maybe<SetOperatorInputOfApplicationAzureAd> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ApplicationAzureAd */
export type SetOperatorInputOfApplicationAzureAd = {
	name?: Maybe<string> /**  */
	tenantAzureId?: Maybe<string> /**  */
	clientId?: Maybe<string> /**  */
	clientSecret?: Maybe<string> /**  */
	expireClientSecretDate?: Maybe<Date> /**  */
	description?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AppLogInput = {
	date?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	data?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type AppLog */
export type FieldUpdateOperatorOfAppLog = {
	set?: Maybe<SetOperatorInputOfAppLog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AppLog */
export type SetOperatorInputOfAppLog = {
	date?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AppointmentInput = {
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type Appointment */
export type FieldUpdateOperatorOfAppointment = {
	set?: Maybe<SetOperatorInputOfAppointment> /**  */
	inc?: Maybe<IncOperatorInputAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Appointment */
export type SetOperatorInputOfAppointment = {
	allDay?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Appointment */
export type IncOperatorInputAppointment = {
	offSet?: Maybe<number> /**  */
}


/**  */
export type ArticleKnowledgeCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
}


/**  */
export type ArticleKnowledgeNoteInput = {
	userId: string /**  */
	rating: number /**  */
	articleKnowledgeId: string /**  */
}


/** Représente les méthodes disponible pour le type ArticleKnowledgeNote */
export type FieldUpdateOperatorOfArticleKnowledgeNote = {
	set?: Maybe<SetOperatorInputOfArticleKnowledgeNote> /**  */
	inc?: Maybe<IncOperatorInputArticleKnowledgeNote> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ArticleKnowledgeNote */
export type SetOperatorInputOfArticleKnowledgeNote = {
	userId?: Maybe<string> /**  */
	rating?: Maybe<number> /**  */
	articleKnowledgeId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ArticleKnowledgeNote */
export type IncOperatorInputArticleKnowledgeNote = {
	rating?: Maybe<number> /**  */
}


/**  */
export type ArticleKnowledgeInput = {
	dynamicProperties?: Maybe<string> /**  */
	title?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	descriptionText?: Maybe<TranslatedFieldInput> /**  */
	solution?: Maybe<TranslatedFieldInput> /**  */
	solutionText?: Maybe<TranslatedFieldInput> /**  */
	keyWords?: Array<Maybe<string>> /**  */
	links?: Array<Maybe<string>> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	populationsIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	lastViewByOperator?: Maybe<Date> /**  */
	countViewOperator: number /**  */
	lastViewByUser?: Maybe<Date> /**  */
	countViewUser: number /**  */
	averageRating: number /**  */
	countRating: number /**  */
	knowledgeNumber: number /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	resolvedTickets: number /**  */
	lastResolvedTicketDate?: Maybe<Date> /**  */
	articleKnowledgeCategoryId?: Maybe<string> /**  */
}


/**  */
export type AssetAccountInput = {
	assetId: string /**  */
	accountId: string /**  */
}


/** Représente les méthodes disponible pour le type AssetAccount */
export type FieldUpdateOperatorOfAssetAccount = {
	set?: Maybe<SetOperatorInputOfAssetAccount> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAccount */
export type SetOperatorInputOfAssetAccount = {
	assetId?: Maybe<string> /**  */
	accountId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AssetAcquisitionModeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetAcquisitionMode */
export type FieldUpdateOperatorOfAssetAcquisitionMode = {
	set?: Maybe<SetOperatorInputOfAssetAcquisitionMode> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetAcquisitionMode> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetAcquisitionMode> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAcquisitionMode */
export type SetOperatorInputOfAssetAcquisitionMode = {
	description?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAcquisitionMode */
export type PushUpdateOperatorInputAssetAcquisitionMode = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAcquisitionMode */
export type PullUpdateOperatorInputAssetAcquisitionMode = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type AssetAcquisitionStateInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type AssetAcquisitionState */
export type FieldUpdateOperatorOfAssetAcquisitionState = {
	set?: Maybe<SetOperatorInputOfAssetAcquisitionState> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetAcquisitionState> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetAcquisitionState> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetAcquisitionState */
export type SetOperatorInputOfAssetAcquisitionState = {
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAcquisitionState */
export type PushUpdateOperatorInputAssetAcquisitionState = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetAcquisitionState */
export type PullUpdateOperatorInputAssetAcquisitionState = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetAffectedRule */
export type FieldUpdateOperatorOfAssetAffectedRule = {
	set?: Maybe<SetOperatorInputOfAssetAffectedRule> /**  */
	inc?: Maybe<IncOperatorInputAssetAffectedRule> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetAffectedRule> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetAffectedRule> /**  */
}


/** Représente les méthodes disponible pour le type AssetCategory */
export type FieldUpdateOperatorOfAssetCategory = {
	set?: Maybe<SetOperatorInputOfAssetCategory> /**  */
	inc?: Maybe<IncOperatorInputAssetCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetCategory */
export type SetOperatorInputOfAssetCategory = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	defaultPower?: Maybe<number> /**  */
	stockageDurationMax?: Maybe<number> /**  */
	lifeDuration?: Maybe<number> /**  */
	snmpType?: Maybe<SnmpType> /**  */
	decayRate?: Maybe<number> /**  */
	parentId?: Maybe<string> /**  */
	namingPolicyRuleId?: Maybe<string> /**  */
	remoteControlId?: Maybe<string> /**  */
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	defaultCommandId?: Maybe<string> /**  */
	autoLaunchCommand?: Maybe<boolean> /**  */
	error?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	lifetime?: Maybe<SetOperatorInputOfAssetLifetime> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	path?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetLifetime */
export type SetOperatorInputOfAssetLifetime = {
	yearDuration?: Maybe<number> /**  */
	monthDuration?: Maybe<number> /**  */
	dayDuration?: Maybe<number> /**  */
	lifeTimeOption?: Maybe<LifetimeStartDateSelection> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetCategory */
export type IncOperatorInputAssetCategory = {
	defaultPower?: Maybe<number> /**  */
	stockageDurationMax?: Maybe<number> /**  */
	lifeDuration?: Maybe<number> /**  */
	decayRate?: Maybe<number> /**  */
	lifetime?: Maybe<IncOperatorInputAssetLifetime> /**  */
}


/** Représente les propriétés de type numérique de l'entité AssetLifetime */
export type IncOperatorInputAssetLifetime = {
	yearDuration?: Maybe<number> /**  */
	monthDuration?: Maybe<number> /**  */
	dayDuration?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCategory */
export type PushUpdateOperatorInputAssetCategory = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetCategory */
export type PullUpdateOperatorInputAssetCategory = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les méthodes disponible pour le type AssetCategoryRule */
export type FieldUpdateOperatorOfAssetCategoryRule = {
	set?: Maybe<SetOperatorInputOfAssetCategoryRule> /**  */
	inc?: Maybe<IncOperatorInputAssetCategoryRule> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetCategoryRule> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetCategoryRule> /**  */
}


/**  */
export type AssetContractInput = {
	assetId: string /**  */
	contractId: string /**  */
}


/** Représente les méthodes disponible pour le type AssetContract */
export type FieldUpdateOperatorOfAssetContract = {
	set?: Maybe<SetOperatorInputOfAssetContract> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetContract */
export type SetOperatorInputOfAssetContract = {
	assetId?: Maybe<string> /**  */
	contractId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AssetCostCenterInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
}


/**  */
export type AssetLoanInput = {
	assetId: string /**  */
	loanId: string /**  */
}


/** Représente les méthodes disponible pour le type AssetLoan */
export type FieldUpdateOperatorOfAssetLoan = {
	set?: Maybe<SetOperatorInputOfAssetLoan> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetLoan */
export type SetOperatorInputOfAssetLoan = {
	assetId?: Maybe<string> /**  */
	loanId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AssetModelInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	assetCategoryId?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
	modelId?: Maybe<string> /**  */
	assetStockEntryIds: Array<string> /**  */
	category: AssetModelCategory /**  */
	minimumStorageAmount: number /**  */
	minimumStockManagement: AssetModelMinimumStockManagement /**  */
	minimumStockByStorageUnits?: Array<Maybe<MinimumStockByStorageUnitInput>> /**  */
	storageUnitId?: Maybe<string> /**  */
	storageUnit?: Maybe<LocationInput> /**  */
	totalStorageAmout: number /**  */
	supplierIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	compatibleIds: Array<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	restockingThreshold?: Maybe<number> /**  */
	economicQuantity?: Maybe<number> /**  */
	technicalQuantity?: Maybe<number> /**  */
	restockingQuantity?: Maybe<number> /**  */
}


/**  */
export type AssetModelStockEntryInput = {
	dynamicProperties?: Maybe<string> /**  */
	storageUnitId?: Maybe<string> /**  */
	inStockId: string /**  */
	inStockCartId: string /**  */
	outStockCartId: string /**  */
	outStockId: string /**  */
	correctionStockCartId: string /**  */
	correctionStockId: string /**  */
	createdAssetId: string /**  */
	status: AssetModelStockEntryStatus /**  */
	assetModelId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
}


/** Représente les méthodes disponible pour le type AssetProperty */
export type FieldUpdateOperatorOfAssetProperty = {
	set?: Maybe<SetOperatorInputOfAssetProperty> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetProperty */
export type SetOperatorInputOfAssetProperty = {
	manualData?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	history?: Maybe<boolean> /**  */
	assetId?: Maybe<string> /**  */
	deleted?: Maybe<boolean> /**  */
	displayKey?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type AssetPropertyTrackingInput = {
	entityName?: Maybe<string> /**  */
	canUpdated: boolean /**  */
	canDeleted: boolean /**  */
	canAdded: boolean /**  */
	entityPropertyTracking?: Array<Maybe<EntityPropertyTrackingInput>> /**  */
}


/**  */
export type EntityPropertyTrackingInput = {
	propertyName?: Maybe<string> /**  */
	enabled: boolean /**  */
	parentProperty?: Maybe<string> /**  */
	propertyFullName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetPropertyTracking */
export type FieldUpdateOperatorOfAssetPropertyTracking = {
	set?: Maybe<SetOperatorInputOfAssetPropertyTracking> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetPropertyTracking> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetPropertyTracking> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetPropertyTracking */
export type SetOperatorInputOfAssetPropertyTracking = {
	entityName?: Maybe<string> /**  */
	canUpdated?: Maybe<boolean> /**  */
	canDeleted?: Maybe<boolean> /**  */
	canAdded?: Maybe<boolean> /**  */
	entityPropertyTracking: Array<EntityPropertyTrackingInput> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetPropertyTracking */
export type PushUpdateOperatorInputAssetPropertyTracking = {
	entityPropertyTracking: Array<EntityPropertyTrackingInput> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetPropertyTracking */
export type PullUpdateOperatorInputAssetPropertyTracking = {
	entityPropertyTracking: Array<EntityPropertyTrackingInput> /**  */
}


/**  */
export type AssetProvisionalCommissioningInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	assetId: string /**  */
	ownerId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
}


/**  */
export type AssetStatusInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetStatus */
export type FieldUpdateOperatorOfAssetStatus = {
	set?: Maybe<SetOperatorInputOfAssetStatus> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetStatus> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetStatus> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetStatus */
export type SetOperatorInputOfAssetStatus = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetStatus */
export type PushUpdateOperatorInputAssetStatus = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetStatus */
export type PullUpdateOperatorInputAssetStatus = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type AssetTypeAccountingInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetTypeAccounting */
export type FieldUpdateOperatorOfAssetTypeAccounting = {
	set?: Maybe<SetOperatorInputOfAssetTypeAccounting> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetTypeAccounting> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetTypeAccounting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetTypeAccounting */
export type SetOperatorInputOfAssetTypeAccounting = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetTypeAccounting */
export type PushUpdateOperatorInputAssetTypeAccounting = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetTypeAccounting */
export type PullUpdateOperatorInputAssetTypeAccounting = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type AssetTypeStaffingInput = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AssetTypeStaffing */
export type FieldUpdateOperatorOfAssetTypeStaffing = {
	set?: Maybe<SetOperatorInputOfAssetTypeStaffing> /**  */
	push?: Maybe<PushUpdateOperatorInputAssetTypeStaffing> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputAssetTypeStaffing> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AssetTypeStaffing */
export type SetOperatorInputOfAssetTypeStaffing = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetTypeStaffing */
export type PushUpdateOperatorInputAssetTypeStaffing = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité AssetTypeStaffing */
export type PullUpdateOperatorInputAssetTypeStaffing = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type AsyncTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	progression: number /**  */
	methodName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AsyncTask */
export type FieldUpdateOperatorOfAsyncTask = {
	set?: Maybe<SetOperatorInputOfAsyncTask> /**  */
	inc?: Maybe<IncOperatorInputAsyncTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AsyncTask */
export type SetOperatorInputOfAsyncTask = {
	progression?: Maybe<number> /**  */
	methodName?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	paramMap?: Maybe<SetOperatorInputOfAsyncTaskParam> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AsyncTaskParam */
export type SetOperatorInputOfAsyncTaskParam = {
	default?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité AsyncTask */
export type IncOperatorInputAsyncTask = {
	progression?: Maybe<number> /**  */
}


/**  */
export type AxelDataInput = {
	model?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type AxelData */
export type FieldUpdateOperatorOfAxelData = {
	set?: Maybe<SetOperatorInputOfAxelData> /**  */
}


/** Représente les propriétés de mise à jour de l'entité AxelData */
export type SetOperatorInputOfAxelData = {
	model?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type BudgetCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type BudgetInput = {
	dynamicProperties?: Maybe<string> /**  */
	hidden: boolean /**  */
	name?: Maybe<string> /**  */
	code?: Maybe<string> /**  */
	opened?: Maybe<Date> /**  */
	closed?: Maybe<Date> /**  */
	amount: number /**  */
	budgetCategoryId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	contractIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	groupIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type BusinessTimeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	config?: Maybe<WorkTimeConfigInput> /**  */
	isDefault: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	pauseStatusIds: Array<string> /**  */
	timeZoneId?: Maybe<string> /**  */
}


/**  */
export type WorkTimeConfigInput = {
	monday?: Maybe<WorkTimeConfigDayInput> /**  */
	tuesday?: Maybe<WorkTimeConfigDayInput> /**  */
	wednesday?: Maybe<WorkTimeConfigDayInput> /**  */
	thursday?: Maybe<WorkTimeConfigDayInput> /**  */
	friday?: Maybe<WorkTimeConfigDayInput> /**  */
	saturday?: Maybe<WorkTimeConfigDayInput> /**  */
	sunday?: Maybe<WorkTimeConfigDayInput> /**  */
}


/**  */
export type WorkTimeConfigDayInput = {
	enabled: boolean /**  */
	times?: Array<Maybe<WorkTimeConfigTimeInput>> /**  */
}


/**  */
export type WorkTimeConfigTimeInput = {
	start?: Maybe<Date> /**  */
	startTick: number /**  */
	end?: Maybe<Date> /**  */
	endTick: number /**  */
}


/** Représente les méthodes disponible pour le type BusinessTime */
export type FieldUpdateOperatorOfBusinessTime = {
	set?: Maybe<SetOperatorInputOfBusinessTime> /**  */
	push?: Maybe<PushUpdateOperatorInputBusinessTime> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputBusinessTime> /**  */
}


/** Représente les propriétés de mise à jour de l'entité BusinessTime */
export type SetOperatorInputOfBusinessTime = {
	enabled?: Maybe<boolean> /**  */
	isDefault?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	pauseStatusIds: Array<string> /**  */
	timeZoneId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	config?: Maybe<SetOperatorInputOfWorkTimeConfig> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkTimeConfig */
export type SetOperatorInputOfWorkTimeConfig = {
	monday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	tuesday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	wednesday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	thursday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	friday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	saturday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
	sunday?: Maybe<SetOperatorInputOfWorkTimeConfigDay> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkTimeConfigDay */
export type SetOperatorInputOfWorkTimeConfigDay = {
	enabled?: Maybe<boolean> /**  */
	times: Array<WorkTimeConfigTimeInput> /**  */
}


/** Représente les propriétés de type collection de l'entité BusinessTime */
export type PushUpdateOperatorInputBusinessTime = {
	dayOffIds: Array<string> /**  */
	pauseStatusIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	config?: Maybe<PushUpdateOperatorInputWorkTimeConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTimeConfig */
export type PushUpdateOperatorInputWorkTimeConfig = {
	monday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	tuesday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	wednesday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	thursday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	friday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	saturday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
	sunday?: Maybe<PushUpdateOperatorInputWorkTimeConfigDay> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTimeConfigDay */
export type PushUpdateOperatorInputWorkTimeConfigDay = {
	times: Array<WorkTimeConfigTimeInput> /**  */
}


/** Représente les propriétés de type collection de l'entité BusinessTime */
export type PullUpdateOperatorInputBusinessTime = {
	dayOffIds: Array<string> /**  */
	pauseStatusIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	config?: Maybe<PullUpdateOperatorInputWorkTimeConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTimeConfig */
export type PullUpdateOperatorInputWorkTimeConfig = {
	monday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	tuesday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	wednesday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	thursday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	friday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	saturday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
	sunday?: Maybe<PullUpdateOperatorInputWorkTimeConfigDay> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTimeConfigDay */
export type PullUpdateOperatorInputWorkTimeConfigDay = {
	times: Array<WorkTimeConfigTimeInput> /**  */
}


/**  */
export type CampaignHistoryInput = {
	date?: Maybe<Date> /**  */
	state: HistoryState /**  */
	packageId: string /**  */
	assetId: string /**  */
	campaignId: string /**  */
	error?: Maybe<string> /**  */
	deployId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type CampaignHistory */
export type FieldUpdateOperatorOfCampaignHistory = {
	set?: Maybe<SetOperatorInputOfCampaignHistory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CampaignHistory */
export type SetOperatorInputOfCampaignHistory = {
	date?: Maybe<Date> /**  */
	state?: Maybe<HistoryState> /**  */
	packageId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	campaignId?: Maybe<string> /**  */
	error?: Maybe<string> /**  */
	deployId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type CampaignInput = {
	name?: Maybe<string> /**  */
	status: CampaignStatusEnum /**  */
	packageIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	lastDeployId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type Campaign */
export type FieldUpdateOperatorOfCampaign = {
	set?: Maybe<SetOperatorInputOfCampaign> /**  */
	push?: Maybe<PushUpdateOperatorInputCampaign> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCampaign> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Campaign */
export type SetOperatorInputOfCampaign = {
	name?: Maybe<string> /**  */
	status?: Maybe<CampaignStatusEnum> /**  */
	packageIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	lastDeployId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Campaign */
export type PushUpdateOperatorInputCampaign = {
	packageIds: Array<string> /**  */
	assetIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Campaign */
export type PullUpdateOperatorInputCampaign = {
	packageIds: Array<string> /**  */
	assetIds: Array<string> /**  */
}


/**  */
export type ChannelInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type CheckFileTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type CheckFileTask */
export type FieldUpdateOperatorOfCheckFileTask = {
	set?: Maybe<SetOperatorInputOfCheckFileTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CheckFileTask */
export type SetOperatorInputOfCheckFileTask = {
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type CheckSizeFileTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type CheckSizeFileTask */
export type FieldUpdateOperatorOfCheckSizeFileTask = {
	set?: Maybe<SetOperatorInputOfCheckSizeFileTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CheckSizeFileTask */
export type SetOperatorInputOfCheckSizeFileTask = {
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type ClarilogServerInput = {
	dynamicProperties?: Maybe<string> /**  */
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	lastStart?: Maybe<Date> /**  */
	lastCheck?: Maybe<Date> /**  */
	domain?: Maybe<string> /**  */
	ipAddress?: Maybe<string> /**  */
	macAddress?: Maybe<string> /**  */
	osVersion?: Maybe<string> /**  */
	simultaneousScan: number /**  */
	scanHistoryOption?: Maybe<ScanHistoryOptionInput> /**  */
	locationId?: Maybe<string> /**  */
	clarilogServerLogs?: Array<Maybe<ClarilogServerLogInput>> /**  */
	localId: string /**  */
	version?: Maybe<string> /**  */
	clientApplicationtName?: Maybe<string> /**  */
	updater?: Maybe<UpdaterInput> /**  */
	clarilogAgentSettings?: Maybe<ClarilogAgentSettingsInput> /**  */
}


/**  */
export type ScanHistoryOptionInput = {
	keepSuccessAudit: number /**  */
	delayBeforePurge: number /**  */
	keepLastDayForEventLog: number /**  */
	keepLastDayForLogon: number /**  */
	keepLastDayForUptimeCalendar: number /**  */
}


/**  */
export type CommandInput = {
	dynamicProperties?: Maybe<string> /**  */
	category: CommandCategory /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	pathToExecutable?: Maybe<string> /**  */
	pathToParamterFile?: Maybe<string> /**  */
	port: number /**  */
	commandLine?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	protocole: CommandProtocole /**  */
	userIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	exceptionAssetIds: Array<string> /**  */
	dataCommand?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	commandAssetCategories?: Array<Maybe<CommandAssetCategoryInput>> /**  */
}


/**  */
export type CommentaryModelInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	type?: Array<Maybe<string>> /**  */
	commentarySupportLevelIds: Array<number> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	usageType?: Array<Maybe<string>> /**  */
}


/** Représente les méthodes disponible pour le type CommentaryModel */
export type FieldUpdateOperatorOfCommentaryModel = {
	set?: Maybe<SetOperatorInputOfCommentaryModel> /**  */
	push?: Maybe<PushUpdateOperatorInputCommentaryModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputCommentaryModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CommentaryModel */
export type SetOperatorInputOfCommentaryModel = {
	type: Array<string> /**  */
	commentarySupportLevelIds: Array<number> /**  */
	qualification: Array<string> /**  */
	usageType: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité CommentaryModel */
export type PushUpdateOperatorInputCommentaryModel = {
	type: Array<string> /**  */
	commentarySupportLevelIds: Array<number> /**  */
	qualification: Array<string> /**  */
	usageType: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité CommentaryModel */
export type PullUpdateOperatorInputCommentaryModel = {
	type: Array<string> /**  */
	commentarySupportLevelIds: Array<number> /**  */
	qualification: Array<string> /**  */
	usageType: Array<string> /**  */
}


/**  */
export type ConcernedProjectInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/** Représente les méthodes disponible pour le type ConcernedProject */
export type FieldUpdateOperatorOfConcernedProject = {
	set?: Maybe<SetOperatorInputOfConcernedProject> /**  */
	push?: Maybe<PushUpdateOperatorInputConcernedProject> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputConcernedProject> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ConcernedProject */
export type SetOperatorInputOfConcernedProject = {
	qualification: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ConcernedProject */
export type PushUpdateOperatorInputConcernedProject = {
	qualification: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ConcernedProject */
export type PullUpdateOperatorInputConcernedProject = {
	qualification: Array<string> /**  */
}


/**  */
export type ConsumableMovementInput = {
	dynamicProperties?: Maybe<string> /**  */
	movementId: string /**  */
	movementType?: Maybe<TranslatedFieldInput> /**  */
	name?: Maybe<string> /**  */
	movementDate?: Maybe<Date> /**  */
	quantity: number /**  */
	unitPrice?: Maybe<number> /**  */
	amount?: Maybe<number> /**  */
	reference?: Maybe<string> /**  */
	referenceName?: Maybe<TranslatedFieldInput> /**  */
	referencePath?: Maybe<TranslatedFieldInput> /**  */
	inStockMovement?: Maybe<InStockMovementInput> /**  */
	assetAcquisitionMode?: Maybe<TranslatedFieldInput> /**  */
	typeOfStaffing?: Maybe<string> /**  */
	supplier?: Maybe<string> /**  */
	outStockMovement?: Maybe<OutStockMovementInput> /**  */
	assetRecipient?: Maybe<string> /**  */
	storageUnit?: Maybe<TranslatedFieldInput> /**  */
	reason?: Maybe<string> /**  */
}


/**  */
export type InStockMovementInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	deliveryDate?: Maybe<Date> /**  */
	receivedById: string /**  */
	deliveryOrder?: Maybe<string> /**  */
	deliveryNumber: number /**  */
	defaultStorageUnitId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	order?: Maybe<OrderInput> /**  */
	bill?: Maybe<OrderBillInput> /**  */
	financial?: Maybe<StockFinancialInformationInput> /**  */
	carts?: Array<Maybe<InCartInput>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type OrderInput = {
	dynamicProperties?: Maybe<string> /**  */
	orderDate?: Maybe<Date> /**  */
	supplierId?: Maybe<string> /**  */
	supplierReferenceNumber?: Maybe<string> /**  */
	internalReferenceNumber?: Maybe<string> /**  */
	reason?: Maybe<string> /**  */
}


/**  */
export type OrderBillInput = {
	dynamicProperties?: Maybe<string> /**  */
	billDate?: Maybe<Date> /**  */
	total: number /**  */
	fileId?: Maybe<string> /**  */
	billNumber?: Maybe<string> /**  */
}


/**  */
export type StockFinancialInformationInput = {
	budgetId?: Maybe<string> /**  */
	modeId?: Maybe<string> /**  */
	typeOfStaffingId?: Maybe<string> /**  */
	lotDate?: Maybe<Date> /**  */
	lotNumber?: Maybe<string> /**  */
}


/**  */
export type OutStockMovementInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	deliveryLocationId?: Maybe<string> /**  */
	destinationOrganizationalUnitId?: Maybe<string> /**  */
	recipientId?: Maybe<string> /**  */
	carts?: Array<Maybe<OutCartInput>> /**  */
	date?: Maybe<Date> /**  */
	outById?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	storageUnitIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	assetIds: Array<string> /**  */
	hasConsumable: boolean /**  */
	hasAsset: boolean /**  */
}


/**  */
export type ContractCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
}


/**  */
export type CorrectionStockMovementInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	carts?: Array<Maybe<CorrectionCartInput>> /**  */
	date?: Maybe<Date> /**  */
	correctionById?: Maybe<string> /**  */
	storageUnitIds: Array<string> /**  */
	description?: Maybe<string> /**  */
	reasonId?: Maybe<string> /**  */
	inheritSecurityGroups?: Array<Maybe<SecurityGroupObjectInput>> /**  */
}


/**  */
export type CostCenterInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
}


/**  */
export type CronJobHourlyTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	endExecution?: Maybe<Date> /**  */
	cronJobHourlyType: CronJobHourlyType /**  */
	cronExpression?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type CronJobHourlyTask */
export type FieldUpdateOperatorOfCronJobHourlyTask = {
	set?: Maybe<SetOperatorInputOfCronJobHourlyTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CronJobHourlyTask */
export type SetOperatorInputOfCronJobHourlyTask = {
	endExecution?: Maybe<Date> /**  */
	cronJobHourlyType?: Maybe<CronJobHourlyType> /**  */
	cronExpression?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type CustomFieldInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	testField?: Maybe<TranslatedFieldInput> /**  */
	defaultName?: Maybe<TranslatedFieldInput> /**  */
	fieldName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
}


/**  */
export type DashboardInput = {
	title?: Maybe<TranslatedFieldInput> /**  */
	fileId?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	deleted: boolean /**  */
	updated: boolean /**  */
	serviceDesk: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	timerAutoRefresh: number /**  */
	autoRefresh: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	description?: Maybe<string> /**  */
	userIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type Dashboard */
export type FieldUpdateOperatorOfDashboard = {
	set?: Maybe<SetOperatorInputOfDashboard> /**  */
	inc?: Maybe<IncOperatorInputDashboard> /**  */
	push?: Maybe<PushUpdateOperatorInputDashboard> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDashboard> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Dashboard */
export type SetOperatorInputOfDashboard = {
	fileId?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	fileName?: Maybe<string> /**  */
	deleted?: Maybe<boolean> /**  */
	updated?: Maybe<boolean> /**  */
	serviceDesk?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	timerAutoRefresh?: Maybe<number> /**  */
	autoRefresh?: Maybe<boolean> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité Dashboard */
export type IncOperatorInputDashboard = {
	timerAutoRefresh?: Maybe<number> /**  */
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Dashboard */
export type PushUpdateOperatorInputDashboard = {
	userIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Dashboard */
export type PullUpdateOperatorInputDashboard = {
	userIds: Array<string> /**  */
}


/**  */
export type DayOffInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	businessTimeIds: Array<string> /**  */
	daysConfig?: Array<Maybe<DayOffConfigInput>> /**  */
	key?: Maybe<string> /**  */
}


/**  */
export type DayOffConfigInput = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type DayOff */
export type FieldUpdateOperatorOfDayOff = {
	set?: Maybe<SetOperatorInputOfDayOff> /**  */
	push?: Maybe<PushUpdateOperatorInputDayOff> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDayOff> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DayOff */
export type SetOperatorInputOfDayOff = {
	enabled?: Maybe<boolean> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	businessTimeIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	daysConfig: Array<DayOffConfigInput> /**  */
	key?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité DayOff */
export type PushUpdateOperatorInputDayOff = {
	serviceLevelAgreementIds: Array<string> /**  */
	businessTimeIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	daysConfig: Array<DayOffConfigInput> /**  */
}


/** Représente les propriétés de type collection de l'entité DayOff */
export type PullUpdateOperatorInputDayOff = {
	serviceLevelAgreementIds: Array<string> /**  */
	businessTimeIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	daysConfig: Array<DayOffConfigInput> /**  */
}


/**  */
export type DeleteFileTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	fileIds: Array<string> /**  */
	ticketType?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type DeleteFileTask */
export type FieldUpdateOperatorOfDeleteFileTask = {
	set?: Maybe<SetOperatorInputOfDeleteFileTask> /**  */
	push?: Maybe<PushUpdateOperatorInputDeleteFileTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDeleteFileTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DeleteFileTask */
export type SetOperatorInputOfDeleteFileTask = {
	fileIds: Array<string> /**  */
	ticketType?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité DeleteFileTask */
export type PushUpdateOperatorInputDeleteFileTask = {
	fileIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité DeleteFileTask */
export type PullUpdateOperatorInputDeleteFileTask = {
	fileIds: Array<string> /**  */
}


/**  */
export type DomainInput = {
	name?: Maybe<string> /**  */
	netbiosName?: Maybe<string> /**  */
	organisations?: Array<Maybe<OrganizationalUnitInput>> /**  */
	assets?: Array<Maybe<AssetInput>> /**  */
}


/**  */
export type OrganizationalUnitInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	error: boolean /**  */
	objectSid?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	address?: Maybe<AddressInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	manager?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedFieldInput> /**  */
	templateId?: Maybe<string> /**  */
	allRulesMustBeValidAsset: boolean /**  */
	allRulesMustBeValidUser: boolean /**  */
	scanByLdapId?: Maybe<string> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	allowSelection: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/** Représente les méthodes disponible pour le type Domain */
export type FieldUpdateOperatorOfDomain = {
	set?: Maybe<SetOperatorInputOfDomain> /**  */
	push?: Maybe<PushUpdateOperatorInputDomain> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDomain> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Domain */
export type SetOperatorInputOfDomain = {
	name?: Maybe<string> /**  */
	netbiosName?: Maybe<string> /**  */
	organisations: Array<OrganizationalUnitInput> /**  */
	assets: Array<AssetInput> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Domain */
export type PushUpdateOperatorInputDomain = {
	organisations: Array<OrganizationalUnitInput> /**  */
	assets: Array<AssetInput> /**  */
}


/** Représente les propriétés de type collection de l'entité Domain */
export type PullUpdateOperatorInputDomain = {
	organisations: Array<OrganizationalUnitInput> /**  */
	assets: Array<AssetInput> /**  */
}


/**  */
export type DynamicPropertyFieldInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	position: number /**  */
	dynamicPropertyGroupId: string /**  */
	propertyName?: Maybe<string> /**  */
	entityTypes?: Array<Maybe<string>> /**  */
	dynamicPropertyFieldType: DynamicPropertyFieldEnum /**  */
	dynamicPropertyNumericFormat?: Maybe<DynamicPropertyNumericInput> /**  */
	dynamicPropertyDateTime?: Maybe<DynamicPropertyDateTimeInput> /**  */
	dynamicPropertySelectList?: Maybe<DynamicPropertySelectListInput> /**  */
	dynamicPropertyColumnOption?: Maybe<DynamicPropertyColumnOptionInput> /**  */
	dynamicPropertyFormOption?: Maybe<DynamicPropertyFormOptionInput> /**  */
	expression?: Maybe<string> /**  */
}


/**  */
export type DynamicPropertyNumericInput = {
	dynamicPropertyNumericFormat: DynamicPropertyNumericFormat /**  */
	minimun: number /**  */
	maximun: number /**  */
}


/**  */
export type DynamicPropertyDateTimeInput = {
	dynamicPropertyDateFormat: DynamicPropertyDateFormat /**  */
}


/**  */
export type DynamicPropertySelectListInput = {
	dynamicPropertyListId?: Maybe<string> /**  */
	multiple: boolean /**  */
}


/**  */
export type DynamicPropertyColumnOptionInput = {
	sortable: boolean /**  */
	filterable: boolean /**  */
	showInColumnChooser: boolean /**  */
	visible: boolean /**  */
	template?: Maybe<string> /**  */
}


/**  */
export type DynamicPropertyFormOptionInput = {
	required: boolean /**  */
	visible: boolean /**  */
}


/** Représente les méthodes disponible pour le type DynamicPropertyField */
export type FieldUpdateOperatorOfDynamicPropertyField = {
	set?: Maybe<SetOperatorInputOfDynamicPropertyField> /**  */
	inc?: Maybe<IncOperatorInputDynamicPropertyField> /**  */
	push?: Maybe<PushUpdateOperatorInputDynamicPropertyField> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDynamicPropertyField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyField */
export type SetOperatorInputOfDynamicPropertyField = {
	position?: Maybe<number> /**  */
	dynamicPropertyGroupId?: Maybe<string> /**  */
	propertyName?: Maybe<string> /**  */
	entityTypes: Array<string> /**  */
	dynamicPropertyFieldType?: Maybe<DynamicPropertyFieldEnum> /**  */
	expression?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	dynamicPropertyNumericFormat?: Maybe<SetOperatorInputOfDynamicPropertyNumeric> /**  */
	dynamicPropertyDateTime?: Maybe<SetOperatorInputOfDynamicPropertyDateTime> /**  */
	dynamicPropertySelectList?: Maybe<SetOperatorInputOfDynamicPropertySelectList> /**  */
	dynamicPropertyColumnOption?: Maybe<SetOperatorInputOfDynamicPropertyColumnOption> /**  */
	dynamicPropertyFormOption?: Maybe<SetOperatorInputOfDynamicPropertyFormOption> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyNumeric */
export type SetOperatorInputOfDynamicPropertyNumeric = {
	dynamicPropertyNumericFormat?: Maybe<DynamicPropertyNumericFormat> /**  */
	minimun?: Maybe<number> /**  */
	maximun?: Maybe<number> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyDateTime */
export type SetOperatorInputOfDynamicPropertyDateTime = {
	dynamicPropertyDateFormat?: Maybe<DynamicPropertyDateFormat> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertySelectList */
export type SetOperatorInputOfDynamicPropertySelectList = {
	dynamicPropertyListId?: Maybe<string> /**  */
	multiple?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyColumnOption */
export type SetOperatorInputOfDynamicPropertyColumnOption = {
	sortable?: Maybe<boolean> /**  */
	filterable?: Maybe<boolean> /**  */
	showInColumnChooser?: Maybe<boolean> /**  */
	visible?: Maybe<boolean> /**  */
	template?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyFormOption */
export type SetOperatorInputOfDynamicPropertyFormOption = {
	required?: Maybe<boolean> /**  */
	visible?: Maybe<boolean> /**  */
}


/** Représente les propriétés de type numérique de l'entité DynamicPropertyField */
export type IncOperatorInputDynamicPropertyField = {
	position?: Maybe<number> /**  */
	dynamicPropertyNumericFormat?: Maybe<IncOperatorInputDynamicPropertyNumeric> /**  */
}


/** Représente les propriétés de type numérique de l'entité DynamicPropertyNumeric */
export type IncOperatorInputDynamicPropertyNumeric = {
	minimun?: Maybe<number> /**  */
	maximun?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyField */
export type PushUpdateOperatorInputDynamicPropertyField = {
	entityTypes: Array<string> /**  */
	expression?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyField */
export type PullUpdateOperatorInputDynamicPropertyField = {
	entityTypes: Array<string> /**  */
	expression?: Maybe<string> /**  */
}


/**  */
export type DynamicPropertyGroupInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	position: number /**  */
	collapse: boolean /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	useFreeSpace: boolean /**  */
	dynamicPropertyLocation: DynamicPropertyLocationEnum /**  */
	entityTypes?: Array<Maybe<string>> /**  */
}


/** Représente les méthodes disponible pour le type DynamicPropertyGroup */
export type FieldUpdateOperatorOfDynamicPropertyGroup = {
	set?: Maybe<SetOperatorInputOfDynamicPropertyGroup> /**  */
	inc?: Maybe<IncOperatorInputDynamicPropertyGroup> /**  */
	push?: Maybe<PushUpdateOperatorInputDynamicPropertyGroup> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDynamicPropertyGroup> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyGroup */
export type SetOperatorInputOfDynamicPropertyGroup = {
	position?: Maybe<number> /**  */
	collapse?: Maybe<boolean> /**  */
	useFreeSpace?: Maybe<boolean> /**  */
	dynamicPropertyLocation?: Maybe<DynamicPropertyLocationEnum> /**  */
	entityTypes: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité DynamicPropertyGroup */
export type IncOperatorInputDynamicPropertyGroup = {
	position?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyGroup */
export type PushUpdateOperatorInputDynamicPropertyGroup = {
	entityTypes: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyGroup */
export type PullUpdateOperatorInputDynamicPropertyGroup = {
	entityTypes: Array<string> /**  */
}


/**  */
export type DynamicPropertyListInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	values?: Array<Maybe<DynamicPropertyListValueInput>> /**  */
	entityTypes?: Array<Maybe<string>> /**  */
}


/**  */
export type DynamicPropertyListValueInput = {
	id?: string /**  */
	fr?: Maybe<string> /**  */
	nl?: Maybe<string> /**  */
	en?: Maybe<string> /**  */
	it?: Maybe<string> /**  */
	de?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type DynamicPropertyList */
export type FieldUpdateOperatorOfDynamicPropertyList = {
	set?: Maybe<SetOperatorInputOfDynamicPropertyList> /**  */
	push?: Maybe<PushUpdateOperatorInputDynamicPropertyList> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputDynamicPropertyList> /**  */
}


/** Représente les propriétés de mise à jour de l'entité DynamicPropertyList */
export type SetOperatorInputOfDynamicPropertyList = {
	description?: Maybe<string> /**  */
	values: Array<DynamicPropertyListValueInput> /**  */
	entityTypes: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyList */
export type PushUpdateOperatorInputDynamicPropertyList = {
	values: Array<DynamicPropertyListValueInput> /**  */
	entityTypes: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité DynamicPropertyList */
export type PullUpdateOperatorInputDynamicPropertyList = {
	values: Array<DynamicPropertyListValueInput> /**  */
	entityTypes: Array<string> /**  */
}


/**  */
export type EmailConnectorInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	type: EmailConnectorTypeEnum /**  */
	server?: Maybe<string> /**  */
	port: number /**  */
	ssl: boolean /**  */
	email?: Maybe<string> /**  */
	accountId?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	logOn?: Maybe<string> /**  */
	mailSlurpInbox?: Maybe<MailSlurpInboxInput> /**  */
	allowUnknowFrom: boolean /**  */
	allowCreateTicketByEmail: boolean /**  */
	allowCommunicationByMail: boolean /**  */
	allowAddInterventionByMail: boolean /**  */
	interventionByMailTag?: Maybe<string> /**  */
	interventionByMailActivityId?: Maybe<string> /**  */
	onCreateTicketAddAllRecipients: boolean /**  */
	onCommunicationTicketAddAllRecipients: boolean /**  */
	onCommunicationTicketAddFrom: boolean /**  */
	statusIds: Array<string> /**  */
	inboxName?: Maybe<string> /**  */
	send: boolean /**  */
	receive: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	countMailSend: number /**  */
	countMailReceive: number /**  */
	qualification?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
	applicationAzureAdId?: Maybe<string> /**  */
	default: boolean /**  */
	disabledSend?: Maybe<DisabledReasonInput> /**  */
	disabledReceive?: Maybe<DisabledReasonInput> /**  */
	allowEmbeddedImage: boolean /**  */
	smtpEncodingConstraint: number /**  */
	authAzurePassword: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type MailSlurpInboxInput = {
	email?: Maybe<string> /**  */
	inboxId: string /**  */
	webhookId: string /**  */
	endpoint?: Maybe<string> /**  */
	enabled: boolean /**  */
	useFiltering: boolean /**  */
	useEmailChecker: boolean /**  */
	emailCheckerLevel: number /**  */
}


/**  */
export type DisabledReasonInput = {
	disabled: boolean /**  */
	date?: Maybe<Date> /**  */
	reason?: Maybe<string> /**  */
}


/**  */
export type EmailCredentialInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
}


/**  */
export type EmailPrototypeLogInput = {
	email?: Maybe<EmailPrototypeSendInput> /**  */
	type?: Maybe<string> /**  */
	sent?: Maybe<Date> /**  */
	success: boolean /**  */
	error?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	alarmId?: Maybe<string> /**  */
}


/**  */
export type EmailPrototypeSendInput = {
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	enabled: boolean /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	from?: Maybe<string> /**  */
	to?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	attachedResult: boolean /**  */
	alarmIds: Array<string> /**  */
	triggerType: EmailPrototypeTriggerTypeEnum /**  */
	time?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type EmailPrototypeLog */
export type FieldUpdateOperatorOfEmailPrototypeLog = {
	set?: Maybe<SetOperatorInputOfEmailPrototypeLog> /**  */
	push?: Maybe<PushUpdateOperatorInputEmailPrototypeLog> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputEmailPrototypeLog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EmailPrototypeLog */
export type SetOperatorInputOfEmailPrototypeLog = {
	type?: Maybe<string> /**  */
	sent?: Maybe<Date> /**  */
	success?: Maybe<boolean> /**  */
	error?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	alarmId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	email?: Maybe<SetOperatorInputOfEmailPrototypeSend> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EmailPrototypeSend */
export type SetOperatorInputOfEmailPrototypeSend = {
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	from?: Maybe<string> /**  */
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	attachedResult?: Maybe<boolean> /**  */
	alarmIds: Array<string> /**  */
	triggerType?: Maybe<EmailPrototypeTriggerTypeEnum> /**  */
	time?: Maybe<Date> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototypeLog */
export type PushUpdateOperatorInputEmailPrototypeLog = {
	email?: Maybe<PushUpdateOperatorInputEmailPrototypeSend> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototypeSend */
export type PushUpdateOperatorInputEmailPrototypeSend = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	alarmIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototypeLog */
export type PullUpdateOperatorInputEmailPrototypeLog = {
	email?: Maybe<PullUpdateOperatorInputEmailPrototypeSend> /**  */
}


/** Représente les propriétés de type collection de l'entité EmailPrototypeSend */
export type PullUpdateOperatorInputEmailPrototypeSend = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	replyTo: Array<string> /**  */
	alarmIds: Array<string> /**  */
}


/**  */
export type EmailPrototypeInput = {
	dynamicProperties?: Maybe<string> /**  */
	subject?: Maybe<TranslatedFieldInput> /**  */
	copyFrom?: Maybe<string> /**  */
	body?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	type?: Maybe<string> /**  */
	connectorId?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	from?: Maybe<string> /**  */
	to?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	attachedResult: boolean /**  */
	alarmIds: Array<string> /**  */
	queryBuilderIds: Array<string> /**  */
	triggerType: EmailPrototypeTriggerTypeEnum /**  */
	time?: Maybe<Date> /**  */
	ticketCategoryIds: Array<string> /**  */
	disableFilterCategories: boolean /**  */
	sendInvitation: boolean /**  */
	eventDate?: Maybe<Date> /**  */
	attachFiles: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type EsxCredentialInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	webService?: Maybe<string> /**  */
}


/**  */
export type FeedbackInput = {
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type Feedback */
export type FieldUpdateOperatorOfFeedback = {
	set?: Maybe<SetOperatorInputOfFeedback> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Feedback */
export type SetOperatorInputOfFeedback = {
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	send?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type FieldMappingInput = {
	name?: Maybe<string> /**  */
	ldapFieldMappings?: Array<Maybe<LdapFieldMappingInput>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type LdapFieldMappingInput = {
	ldapField?: Maybe<string> /**  */
	entityField?: Maybe<string> /**  */
	phoneType: PhoneEnum /**  */
	updateLdapValue: UpdateLdapValue /**  */
	type: LdapObjectClassType /**  */
}


/** Représente les méthodes disponible pour le type FieldMapping */
export type FieldUpdateOperatorOfFieldMapping = {
	set?: Maybe<SetOperatorInputOfFieldMapping> /**  */
	push?: Maybe<PushUpdateOperatorInputFieldMapping> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputFieldMapping> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FieldMapping */
export type SetOperatorInputOfFieldMapping = {
	name?: Maybe<string> /**  */
	ldapFieldMappings: Array<LdapFieldMappingInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité FieldMapping */
export type PushUpdateOperatorInputFieldMapping = {
	ldapFieldMappings: Array<LdapFieldMappingInput> /**  */
}


/** Représente les propriétés de type collection de l'entité FieldMapping */
export type PullUpdateOperatorInputFieldMapping = {
	ldapFieldMappings: Array<LdapFieldMappingInput> /**  */
}


/**  */
export type FormDesignerInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	isDefault: boolean /**  */
	qualification?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	json?: Maybe<string> /**  */
	formDesignerTo: FormDesignerTo /**  */
	userIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type GroupAzureAd */
export type FieldUpdateOperatorOfGroupAzureAd = {
	set?: Maybe<SetOperatorInputOfGroupAzureAd> /**  */
	push?: Maybe<PushUpdateOperatorInputGroupAzureAd> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputGroupAzureAd> /**  */
}


/** Représente les propriétés de mise à jour de l'entité GroupAzureAd */
export type SetOperatorInputOfGroupAzureAd = {
	objectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	memberOf: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	applicationAzureId?: Maybe<string> /**  */
	members: Array<MembersAzureAdInput> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type MembersAzureAdInput = {
	objectId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité GroupAzureAd */
export type PushUpdateOperatorInputGroupAzureAd = {
	memberOf: Array<string> /**  */
	members: Array<MembersAzureAdInput> /**  */
}


/** Représente les propriétés de type collection de l'entité GroupAzureAd */
export type PullUpdateOperatorInputGroupAzureAd = {
	memberOf: Array<string> /**  */
	members: Array<MembersAzureAdInput> /**  */
}


/**  */
export type IamtCredentialInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	tls: boolean /**  */
}


/**  */
export type ImpactInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type ImportAuditTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	asset?: Maybe<AssetInput> /**  */
	auditResult?: Maybe<AuditResultInput> /**  */
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	scanImportOptionId: string /**  */
}


/** Représente les méthodes disponible pour le type ImportAuditTask */
export type FieldUpdateOperatorOfImportAuditTask = {
	set?: Maybe<SetOperatorInputOfImportAuditTask> /**  */
	inc?: Maybe<IncOperatorInputImportAuditTask> /**  */
	push?: Maybe<PushUpdateOperatorInputImportAuditTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputImportAuditTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ImportAuditTask */
export type SetOperatorInputOfImportAuditTask = {
	manufacturer?: Maybe<string> /**  */
	model?: Maybe<string> /**  */
	scanImportOptionId?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	asset?: Maybe<SetOperatorInputOfAsset> /**  */
	auditResult?: Maybe<SetOperatorInputOfAuditResult> /**  */
}


/** Représente les propriétés de type numérique de l'entité ImportAuditTask */
export type IncOperatorInputImportAuditTask = {
	asset?: Maybe<IncOperatorInputAsset> /**  */
}


/** Représente les propriétés de type collection de l'entité ImportAuditTask */
export type PushUpdateOperatorInputImportAuditTask = {
	asset?: Maybe<PushUpdateOperatorInputAsset> /**  */
	auditResult?: Maybe<PushUpdateOperatorInputAuditResult> /**  */
}


/** Représente les propriétés de type collection de l'entité ImportAuditTask */
export type PullUpdateOperatorInputImportAuditTask = {
	asset?: Maybe<PullUpdateOperatorInputAsset> /**  */
	auditResult?: Maybe<PullUpdateOperatorInputAuditResult> /**  */
}


/**  */
export type IncidentModelInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	modelName?: Maybe<TranslatedFieldInput> /**  */
	title?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	copyFrom?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	assetIds: Array<string> /**  */
	privateResolutionComment?: Maybe<TranslatedFieldInput> /**  */
	resolutionComment?: Maybe<TranslatedFieldInput> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	populationIds: Array<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	levelSupport: number /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
}


/**  */
export type IncidentInput = {
	dynamicProperties?: Maybe<string> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnectorInput> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<ReminderInput> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfactionInput> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
}


/**  */
export type AppointmentConnectorInput = {
	appointmentId?: Maybe<string> /**  */
	calendarConnectorId: string /**  */
}


/**  */
export type ReminderInput = {
	userId?: Maybe<string> /**  */
	lastReminder?: Maybe<Date> /**  */
	increment: number /**  */
	commentary?: Maybe<string> /**  */
}


/**  */
export type TicketSatisfactionInput = {
	created?: Maybe<Date> /**  */
	rating?: Maybe<number> /**  */
	commentary?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type InheritLocationCharter */
export type FieldUpdateOperatorOfInheritLocationCharter = {
	set?: Maybe<SetOperatorInputOfInheritLocationCharter> /**  */
	inc?: Maybe<IncOperatorInputInheritLocationCharter> /**  */
	push?: Maybe<PushUpdateOperatorInputInheritLocationCharter> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputInheritLocationCharter> /**  */
}


/**  */
export type InterventionModelInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	elaspedTime: string /**  */
	activityId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	cost: number /**  */
}


/** Représente les méthodes disponible pour le type InterventionModel */
export type FieldUpdateOperatorOfInterventionModel = {
	set?: Maybe<SetOperatorInputOfInterventionModel> /**  */
	inc?: Maybe<IncOperatorInputInterventionModel> /**  */
	push?: Maybe<PushUpdateOperatorInputInterventionModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputInterventionModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité InterventionModel */
export type SetOperatorInputOfInterventionModel = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	elaspedTime?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
	cost?: Maybe<number> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité InterventionModel */
export type IncOperatorInputInterventionModel = {
	cost?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité InterventionModel */
export type PushUpdateOperatorInputInterventionModel = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité InterventionModel */
export type PullUpdateOperatorInputInterventionModel = {
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
}


/**  */
export type InterventionInput = {
	dynamicProperties?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	interventionModel?: Maybe<string> /**  */
	elaspedTime: string /**  */
	elaspedTimeHour: number /**  */
	cost: number /**  */
	autoCost: boolean /**  */
	ticketId?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
	activityId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type InventoryHistory */
export type FieldUpdateOperatorOfInventoryHistory = {
	set?: Maybe<SetOperatorInputOfInventoryHistory> /**  */
	inc?: Maybe<IncOperatorInputInventoryHistory> /**  */
	push?: Maybe<PushUpdateOperatorInputInventoryHistory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputInventoryHistory> /**  */
}


/**  */
export type LabelTemplateInput = {
	dynamicProperties?: Maybe<string> /**  */
	barcodeFormat: BarcodeFormat /**  */
	availableFormats?: Array<Maybe<string>> /**  */
	paperType?: Maybe<string> /**  */
	availablePaperTypes?: Array<Maybe<string>> /**  */
	includeLogo: boolean /**  */
	includeBarcode: boolean /**  */
	barcodeType?: Maybe<string> /**  */
	visibleFields?: Array<Maybe<string>> /**  */
	height: number /**  */
	width: number /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	fileId?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/**  */
export type LayoutModelInput = {
	fileId?: Maybe<string> /**  */
	roleIds: Array<string> /**  */
	userIds: Array<string> /**  */
	type: LayoutModelCategory /**  */
	entityReference?: Maybe<EntityReferenceInput> /**  */
	version: string /**  */
}


/**  */
export type EntityReferenceInput = {
	id?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type LayoutModel */
export type FieldUpdateOperatorOfLayoutModel = {
	set?: Maybe<SetOperatorInputOfLayoutModel> /**  */
	push?: Maybe<PushUpdateOperatorInputLayoutModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLayoutModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LayoutModel */
export type SetOperatorInputOfLayoutModel = {
	fileId?: Maybe<string> /**  */
	roleIds: Array<string> /**  */
	userIds: Array<string> /**  */
	type?: Maybe<LayoutModelCategory> /**  */
	externalObjectId?: Maybe<string> /**  */
	entityReference?: Maybe<SetOperatorInputOfEntityReference> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EntityReference */
export type SetOperatorInputOfEntityReference = {
	name?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LayoutModel */
export type PushUpdateOperatorInputLayoutModel = {
	roleIds: Array<string> /**  */
	userIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LayoutModel */
export type PullUpdateOperatorInputLayoutModel = {
	roleIds: Array<string> /**  */
	userIds: Array<string> /**  */
}


/**  */
export type LdapAnalyseInput = {
	ldapObjectClassType: LdapObjectClassType /**  */
	assetId?: Maybe<string> /**  */
	ldapObject?: Maybe<LdapOrganizationalUnitInput> /**  */
	userId?: Maybe<string> /**  */
	find: boolean /**  */
	ldapAnalyseFindType: LdapAnalyseFindType /**  */
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	disabled: boolean /**  */
	ldapAnalyseProcess?: Maybe<LdapAnalyseProcessInput> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	azureObject?: Maybe<UserAzureADInput> /**  */
}


/**  */
export type LdapOrganizationalUnitInput = {
	distinguishedName?: Maybe<string> /**  */
	ldapObjectClassType: LdapObjectClassType /**  */
	lastUpdate?: Maybe<Date> /**  */
	scanByLdapId: string /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	userAccountControl: number /**  */
	disabled: boolean /**  */
}


/**  */
export type LdapAnalyseProcessInput = {
	start?: Maybe<Date> /**  */
	total: number /**  */
	analyse: number /**  */
	progressValue: number /**  */
	end?: Maybe<Date> /**  */
	cancel: boolean /**  */
}


/**  */
export type UserAzureADInput = {
	userAccountDisabled: boolean /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	officeName?: Maybe<string> /**  */
	address?: Maybe<AddressInput> /**  */
	principalMobile?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	phones?: Array<Maybe<PhoneInput>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAdInput>> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type LdapAnalyse */
export type FieldUpdateOperatorOfLdapAnalyse = {
	set?: Maybe<SetOperatorInputOfLdapAnalyse> /**  */
	inc?: Maybe<IncOperatorInputLdapAnalyse> /**  */
	push?: Maybe<PushUpdateOperatorInputLdapAnalyse> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLdapAnalyse> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LdapAnalyse */
export type SetOperatorInputOfLdapAnalyse = {
	ldapObjectClassType?: Maybe<LdapObjectClassType> /**  */
	assetId?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
	find?: Maybe<boolean> /**  */
	ldapAnalyseFindType?: Maybe<LdapAnalyseFindType> /**  */
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	ldapObject?: Maybe<SetOperatorInputOfLdapOrganizationalUnit> /**  */
	ldapAnalyseProcess?: Maybe<SetOperatorInputOfLdapAnalyseProcess> /**  */
	azureObject?: Maybe<SetOperatorInputOfUserAzureAD> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LdapOrganizationalUnit */
export type SetOperatorInputOfLdapOrganizationalUnit = {
	name?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	ldapObjectClassType?: Maybe<LdapObjectClassType> /**  */
	lastUpdate?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	userAccountControl?: Maybe<number> /**  */
	disabled?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LdapAnalyseProcess */
export type SetOperatorInputOfLdapAnalyseProcess = {
	start?: Maybe<Date> /**  */
	total?: Maybe<number> /**  */
	analyse?: Maybe<number> /**  */
	progressValue?: Maybe<number> /**  */
	end?: Maybe<Date> /**  */
	cancel?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité UserAzureAD */
export type SetOperatorInputOfUserAzureAD = {
	userAccountDisabled?: Maybe<boolean> /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	name?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	officeName?: Maybe<string> /**  */
	principalMobile?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	phones: Array<PhoneInput> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
}


/** Représente les propriétés de type numérique de l'entité LdapAnalyse */
export type IncOperatorInputLdapAnalyse = {
	ldapObject?: Maybe<IncOperatorInputLdapOrganizationalUnit> /**  */
	ldapAnalyseProcess?: Maybe<IncOperatorInputLdapAnalyseProcess> /**  */
}


/** Représente les propriétés de type numérique de l'entité LdapOrganizationalUnit */
export type IncOperatorInputLdapOrganizationalUnit = {
	userAccountControl?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité LdapAnalyseProcess */
export type IncOperatorInputLdapAnalyseProcess = {
	total?: Maybe<number> /**  */
	analyse?: Maybe<number> /**  */
	progressValue?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité LdapAnalyse */
export type PushUpdateOperatorInputLdapAnalyse = {
	azureObject?: Maybe<PushUpdateOperatorInputUserAzureAD> /**  */
}


/** Représente les propriétés de type collection de l'entité UserAzureAD */
export type PushUpdateOperatorInputUserAzureAD = {
	phones: Array<PhoneInput> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
}


/** Représente les propriétés de type collection de l'entité LdapAnalyse */
export type PullUpdateOperatorInputLdapAnalyse = {
	azureObject?: Maybe<PullUpdateOperatorInputUserAzureAD> /**  */
}


/** Représente les propriétés de type collection de l'entité UserAzureAD */
export type PullUpdateOperatorInputUserAzureAD = {
	phones: Array<PhoneInput> /**  */
	memberOfAzureGroup: Array<GroupAzureAdInput> /**  */
}


/**  */
export type LdapHistoryInput = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	actionHistoryState: ActionHistoryState /**  */
	progressValue: number /**  */
	progressDisplay?: Maybe<string> /**  */
	ldapUser?: Maybe<LdapTrackInput> /**  */
	ldapGroup?: Maybe<LdapTrackInput> /**  */
	ldapOrganizationalUnit?: Maybe<LdapTrackInput> /**  */
	ldapComputer?: Maybe<LdapTrackInput> /**  */
	forceSearchFilter: boolean /**  */
	ldapOrganizationalUnitFilter: number /**  */
	distinguishedName?: Maybe<string> /**  */
	exceptions?: Array<Maybe<ClarilogExceptionInput>> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	excludeFilterUsers?: Array<Maybe<ExcludeFilterUserInput>> /**  */
}


/**  */
export type LdapTrackInput = {
	total: number /**  */
	added: number /**  */
	updated: number /**  */
}


/**  */
export type ExcludeFilterUserInput = {
	name?: Maybe<string> /**  */
	filterExpression?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type LdapHistory */
export type FieldUpdateOperatorOfLdapHistory = {
	set?: Maybe<SetOperatorInputOfLdapHistory> /**  */
	inc?: Maybe<IncOperatorInputLdapHistory> /**  */
	push?: Maybe<PushUpdateOperatorInputLdapHistory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLdapHistory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LdapHistory */
export type SetOperatorInputOfLdapHistory = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	lastChanged?: Maybe<Date> /**  */
	actionHistoryState?: Maybe<ActionHistoryState> /**  */
	progressValue?: Maybe<number> /**  */
	progressDisplay?: Maybe<string> /**  */
	forceSearchFilter?: Maybe<boolean> /**  */
	ldapOrganizationalUnitFilter?: Maybe<number> /**  */
	distinguishedName?: Maybe<string> /**  */
	exceptions: Array<ClarilogExceptionInput> /**  */
	scanConfigurationId?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	lastScanId?: Maybe<string> /**  */
	excludeFilterUsers: Array<ExcludeFilterUserInput> /**  */
	externalObjectId?: Maybe<string> /**  */
	ldapUser?: Maybe<SetOperatorInputOfLdapTrack> /**  */
	ldapGroup?: Maybe<SetOperatorInputOfLdapTrack> /**  */
	ldapOrganizationalUnit?: Maybe<SetOperatorInputOfLdapTrack> /**  */
	ldapComputer?: Maybe<SetOperatorInputOfLdapTrack> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LdapTrack */
export type SetOperatorInputOfLdapTrack = {
	total?: Maybe<number> /**  */
	added?: Maybe<number> /**  */
	updated?: Maybe<number> /**  */
}


/** Représente les propriétés de type numérique de l'entité LdapHistory */
export type IncOperatorInputLdapHistory = {
	progressValue?: Maybe<number> /**  */
	ldapOrganizationalUnitFilter?: Maybe<number> /**  */
	ldapUser?: Maybe<IncOperatorInputLdapTrack> /**  */
	ldapGroup?: Maybe<IncOperatorInputLdapTrack> /**  */
	ldapOrganizationalUnit?: Maybe<IncOperatorInputLdapTrack> /**  */
	ldapComputer?: Maybe<IncOperatorInputLdapTrack> /**  */
}


/** Représente les propriétés de type numérique de l'entité LdapTrack */
export type IncOperatorInputLdapTrack = {
	total?: Maybe<number> /**  */
	added?: Maybe<number> /**  */
	updated?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité LdapHistory */
export type PushUpdateOperatorInputLdapHistory = {
	exceptions: Array<ClarilogExceptionInput> /**  */
	excludeFilterUsers: Array<ExcludeFilterUserInput> /**  */
}


/** Représente les propriétés de type collection de l'entité LdapHistory */
export type PullUpdateOperatorInputLdapHistory = {
	exceptions: Array<ClarilogExceptionInput> /**  */
	excludeFilterUsers: Array<ExcludeFilterUserInput> /**  */
}


/** Représente les méthodes disponible pour le type LdapOrganizationalUnit */
export type FieldUpdateOperatorOfLdapOrganizationalUnit = {
	set?: Maybe<SetOperatorInputOfLdapOrganizationalUnit> /**  */
	inc?: Maybe<IncOperatorInputLdapOrganizationalUnit> /**  */
}


/**  */
export type LifeCycleDestinationInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type LifeCycleDestination */
export type FieldUpdateOperatorOfLifeCycleDestination = {
	set?: Maybe<SetOperatorInputOfLifeCycleDestination> /**  */
	push?: Maybe<PushUpdateOperatorInputLifeCycleDestination> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLifeCycleDestination> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LifeCycleDestination */
export type SetOperatorInputOfLifeCycleDestination = {
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleDestination */
export type PushUpdateOperatorInputLifeCycleDestination = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleDestination */
export type PullUpdateOperatorInputLifeCycleDestination = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type LifeCycleReasonInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type LifeCycleReason */
export type FieldUpdateOperatorOfLifeCycleReason = {
	set?: Maybe<SetOperatorInputOfLifeCycleReason> /**  */
	push?: Maybe<PushUpdateOperatorInputLifeCycleReason> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLifeCycleReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LifeCycleReason */
export type SetOperatorInputOfLifeCycleReason = {
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleReason */
export type PushUpdateOperatorInputLifeCycleReason = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleReason */
export type PullUpdateOperatorInputLifeCycleReason = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type LifeCycleTicketInput = {
	name?: Maybe<string> /**  */
	qualification?: Maybe<string> /**  */
	config?: Array<Maybe<LifeCycleTicketConfigInput>> /**  */
}


/**  */
export type LifeCycleTicketConfigInput = {
	fromStatusId: string /**  */
	toStatusId: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type LifeCycleTicket */
export type FieldUpdateOperatorOfLifeCycleTicket = {
	set?: Maybe<SetOperatorInputOfLifeCycleTicket> /**  */
	push?: Maybe<PushUpdateOperatorInputLifeCycleTicket> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLifeCycleTicket> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LifeCycleTicket */
export type SetOperatorInputOfLifeCycleTicket = {
	name?: Maybe<string> /**  */
	qualification?: Maybe<string> /**  */
	config: Array<LifeCycleTicketConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleTicket */
export type PushUpdateOperatorInputLifeCycleTicket = {
	config: Array<LifeCycleTicketConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité LifeCycleTicket */
export type PullUpdateOperatorInputLifeCycleTicket = {
	config: Array<LifeCycleTicketConfigInput> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type LinkAssetInput = {
	sourceId: string /**  */
	destinationId: string /**  */
	type?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	isSystem: boolean /**  */
}


/** Représente les méthodes disponible pour le type LinkAsset */
export type FieldUpdateOperatorOfLinkAsset = {
	set?: Maybe<SetOperatorInputOfLinkAsset> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LinkAsset */
export type SetOperatorInputOfLinkAsset = {
	sourceId?: Maybe<string> /**  */
	destinationId?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	isSystem?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type ListDesignerInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	isDefault: boolean /**  */
	qualification?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	columns?: Maybe<string> /**  */
	listType: ListDesignerType /**  */
}


/**  */
export type LoanAppointmentInput = {
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	loanId: string /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type LoanAppointment */
export type FieldUpdateOperatorOfLoanAppointment = {
	set?: Maybe<SetOperatorInputOfLoanAppointment> /**  */
	inc?: Maybe<IncOperatorInputLoanAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LoanAppointment */
export type SetOperatorInputOfLoanAppointment = {
	allDay?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	loanId?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité LoanAppointment */
export type IncOperatorInputLoanAppointment = {
	offSet?: Maybe<number> /**  */
}


/** Représente les méthodes disponible pour le type Loan */
export type FieldUpdateOperatorOfLoan = {
	set?: Maybe<SetOperatorInputOfLoan> /**  */
	inc?: Maybe<IncOperatorInputLoan> /**  */
	push?: Maybe<PushUpdateOperatorInputLoan> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLoan> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Loan */
export type SetOperatorInputOfLoan = {
	number?: Maybe<number> /**  */
	slipNumber?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	provision?: Maybe<Date> /**  */
	returnDate?: Maybe<Date> /**  */
	realReturnDate?: Maybe<Date> /**  */
	duration?: Maybe<number> /**  */
	information?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	accountIds: Array<UserAccountInput> /**  */
	recordDate?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	contactId?: Maybe<string> /**  */
	loanStatusId?: Maybe<string> /**  */
	loanBorrowerType?: Maybe<LoanBorrowerType> /**  */
	notTrackingFields: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Loan */
export type IncOperatorInputLoan = {
	number?: Maybe<number> /**  */
	duration?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Loan */
export type PushUpdateOperatorInputLoan = {
	locationIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	accountIds: Array<UserAccountInput> /**  */
	notTrackingFields: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Loan */
export type PullUpdateOperatorInputLoan = {
	locationIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	accountIds: Array<UserAccountInput> /**  */
	notTrackingFields: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
}


/**  */
export type LoanStatusInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type LoanStatus */
export type FieldUpdateOperatorOfLoanStatus = {
	set?: Maybe<SetOperatorInputOfLoanStatus> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LoanStatus */
export type SetOperatorInputOfLoanStatus = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type LocationCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	icon?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	contentModel: Array<number> /**  */
}


/**  */
export type LocationCharterInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	locationCharterCategory: LocationCharterCategory /**  */
	locationCharterCategoryForImport?: Maybe<string> /**  */
	titles?: Array<Maybe<string>> /**  */
	companies?: Array<Maybe<string>> /**  */
	cities?: Array<Maybe<string>> /**  */
	departments?: Array<Maybe<string>> /**  */
	offices?: Array<Maybe<string>> /**  */
	ipAddressStart?: Maybe<string> /**  */
	ipAddressEnd?: Maybe<string> /**  */
	netbiosOrIpAddresses?: Array<Maybe<string>> /**  */
	macAddresses?: Array<Maybe<string>> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	user: boolean /**  */
	asset: boolean /**  */
	ldapDistinguishedName?: Array<Maybe<string>> /**  */
	azureGroupObjectIds?: Array<Maybe<string>> /**  */
	excludeAssetIds: Array<string> /**  */
	excludeUserIds: Array<string> /**  */
	locationCharterLdapAsset?: Maybe<LocationCharterLdapInput> /**  */
	locationCharterLdapUser?: Maybe<LocationCharterLdapInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type LocationCharterLdapInput = {
	location: boolean /**  */
	organizationalUnit: boolean /**  */
}


/** Représente les méthodes disponible pour le type Location */
export type FieldUpdateOperatorOfLocation = {
	set?: Maybe<SetOperatorInputOfLocation> /**  */
	push?: Maybe<PushUpdateOperatorInputLocation> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputLocation> /**  */
}


/**  */
export type MailSlurpBlackListInput = {
	email?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type MailSlurpBlackList */
export type FieldUpdateOperatorOfMailSlurpBlackList = {
	set?: Maybe<SetOperatorInputOfMailSlurpBlackList> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MailSlurpBlackList */
export type SetOperatorInputOfMailSlurpBlackList = {
	email?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type MailSlurpWebhookTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	inboxId: string /**  */
	emailId: string /**  */
	messageId?: Maybe<string> /**  */
	webhookId?: Maybe<string> /**  */
	eventName?: Maybe<string> /**  */
	createdAt?: Maybe<Date> /**  */
	to?: Array<Maybe<string>> /**  */
	from?: Maybe<string> /**  */
	cc?: Array<Maybe<string>> /**  */
	bcc?: Array<Maybe<string>> /**  */
	subject?: Maybe<string> /**  */
	attachmentMetaDatas?: Array<Maybe<MailSlurpAttachmentMetaDataInput>> /**  */
}


/**  */
export type MailSlurpAttachmentMetaDataInput = {
	name?: Maybe<string> /**  */
	contentType?: Maybe<string> /**  */
	contentLength: number /**  */
	id?: Maybe<string> /**  */
	fileId?: Maybe<string> /**  */
	commentary?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type MailSlurpWebhookTask */
export type FieldUpdateOperatorOfMailSlurpWebhookTask = {
	set?: Maybe<SetOperatorInputOfMailSlurpWebhookTask> /**  */
	push?: Maybe<PushUpdateOperatorInputMailSlurpWebhookTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputMailSlurpWebhookTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MailSlurpWebhookTask */
export type SetOperatorInputOfMailSlurpWebhookTask = {
	inboxId?: Maybe<string> /**  */
	emailId?: Maybe<string> /**  */
	messageId?: Maybe<string> /**  */
	webhookId?: Maybe<string> /**  */
	eventName?: Maybe<string> /**  */
	createdAt?: Maybe<Date> /**  */
	to: Array<string> /**  */
	from?: Maybe<string> /**  */
	cc: Array<string> /**  */
	bcc: Array<string> /**  */
	subject?: Maybe<string> /**  */
	attachmentMetaDatas: Array<MailSlurpAttachmentMetaDataInput> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité MailSlurpWebhookTask */
export type PushUpdateOperatorInputMailSlurpWebhookTask = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	bcc: Array<string> /**  */
	attachmentMetaDatas: Array<MailSlurpAttachmentMetaDataInput> /**  */
}


/** Représente les propriétés de type collection de l'entité MailSlurpWebhookTask */
export type PullUpdateOperatorInputMailSlurpWebhookTask = {
	to: Array<string> /**  */
	cc: Array<string> /**  */
	bcc: Array<string> /**  */
	attachmentMetaDatas: Array<MailSlurpAttachmentMetaDataInput> /**  */
}


/**  */
export type MatrixPriorityInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	config?: Array<Maybe<MatrixPriorityConfigInput>> /**  */
}


/**  */
export type MessageModelInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	fileIds: Array<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/** Représente les méthodes disponible pour le type MessageModel */
export type FieldUpdateOperatorOfMessageModel = {
	set?: Maybe<SetOperatorInputOfMessageModel> /**  */
	push?: Maybe<PushUpdateOperatorInputMessageModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputMessageModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MessageModel */
export type SetOperatorInputOfMessageModel = {
	fileIds: Array<string> /**  */
	qualification: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité MessageModel */
export type PushUpdateOperatorInputMessageModel = {
	fileIds: Array<string> /**  */
	qualification: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité MessageModel */
export type PullUpdateOperatorInputMessageModel = {
	fileIds: Array<string> /**  */
	qualification: Array<string> /**  */
}


/**  */
export type MessageInput = {
	date?: Maybe<Date> /**  */
	content?: Maybe<string> /**  */
	authorId: string /**  */
	ticketId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	isOperator: boolean /**  */
	isSenderUserConcerned: boolean /**  */
	authorName?: Maybe<string> /**  */
	alertedUsersIds: Array<string> /**  */
	fileIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type Message */
export type FieldUpdateOperatorOfMessage = {
	set?: Maybe<SetOperatorInputOfMessage> /**  */
	push?: Maybe<PushUpdateOperatorInputMessage> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputMessage> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Message */
export type SetOperatorInputOfMessage = {
	date?: Maybe<Date> /**  */
	content?: Maybe<string> /**  */
	authorId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	isOperator?: Maybe<boolean> /**  */
	isSenderUserConcerned?: Maybe<boolean> /**  */
	authorName?: Maybe<string> /**  */
	alertedUsersIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Message */
export type PushUpdateOperatorInputMessage = {
	alertedUsersIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Message */
export type PullUpdateOperatorInputMessage = {
	alertedUsersIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	alertableFields: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ModelManufacturer */
export type FieldUpdateOperatorOfModelManufacturer = {
	set?: Maybe<SetOperatorInputOfModelManufacturer> /**  */
	push?: Maybe<PushUpdateOperatorInputModelManufacturer> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputModelManufacturer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ModelManufacturer */
export type SetOperatorInputOfModelManufacturer = {
	name?: Maybe<string> /**  */
	manufacturerId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ModelManufacturer */
export type PushUpdateOperatorInputModelManufacturer = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ModelManufacturer */
export type PullUpdateOperatorInputModelManufacturer = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type ModelRulesDesignerInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	isDefault: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	rules?: Array<Maybe<ModelRuleInput>> /**  */
	editRules?: Array<Maybe<ModelRuleInput>> /**  */
	taskRules?: Array<Maybe<ModelRuleInput>> /**  */
	taskEditRules?: Array<Maybe<ModelRuleInput>> /**  */
	interventionRules?: Array<Maybe<ModelRuleInput>> /**  */
	interventionEditRules?: Array<Maybe<ModelRuleInput>> /**  */
	escalationRules?: Array<Maybe<ModelRuleInput>> /**  */
	escalationEditRules?: Array<Maybe<ModelRuleInput>> /**  */
	dynamicFieldRules?: Array<Maybe<ModelRuleInput>> /**  */
	dynamicFieldEditRules?: Array<Maybe<ModelRuleInput>> /**  */
	operatorIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type NamingConventionInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	assetCategoryIds: Array<string> /**  */
	excludeAssetCategoryIds: Array<string> /**  */
	namingConventionCategory: NamingConventionCategory /**  */
	locked: boolean /**  */
	empty: boolean /**  */
	inheritCategory?: Maybe<string> /**  */
	dateFormat?: Maybe<string> /**  */
	dateFormatWithoutHour?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	incrementNextValue?: Maybe<number> /**  */
	incrementLength?: Maybe<number> /**  */
}


/** Représente les méthodes disponible pour le type NamingConvention */
export type FieldUpdateOperatorOfNamingConvention = {
	set?: Maybe<SetOperatorInputOfNamingConvention> /**  */
	inc?: Maybe<IncOperatorInputNamingConvention> /**  */
	push?: Maybe<PushUpdateOperatorInputNamingConvention> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputNamingConvention> /**  */
}


/** Représente les propriétés de mise à jour de l'entité NamingConvention */
export type SetOperatorInputOfNamingConvention = {
	enabled?: Maybe<boolean> /**  */
	assetCategoryIds: Array<string> /**  */
	excludeAssetCategoryIds: Array<string> /**  */
	namingConventionCategory?: Maybe<NamingConventionCategory> /**  */
	locked?: Maybe<boolean> /**  */
	empty?: Maybe<boolean> /**  */
	inheritCategory?: Maybe<string> /**  */
	dateFormat?: Maybe<string> /**  */
	dateFormatWithoutHour?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	incrementNextValue?: Maybe<number> /**  */
	incrementLength?: Maybe<number> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité NamingConvention */
export type IncOperatorInputNamingConvention = {
	incrementNextValue?: Maybe<number> /**  */
	incrementLength?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité NamingConvention */
export type PushUpdateOperatorInputNamingConvention = {
	assetCategoryIds: Array<string> /**  */
	excludeAssetCategoryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité NamingConvention */
export type PullUpdateOperatorInputNamingConvention = {
	assetCategoryIds: Array<string> /**  */
	excludeAssetCategoryIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type NewsInput = {
	title?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	populationIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
}


/** Représente les méthodes disponible pour le type News */
export type FieldUpdateOperatorOfNews = {
	set?: Maybe<SetOperatorInputOfNews> /**  */
	inc?: Maybe<IncOperatorInputNews> /**  */
	push?: Maybe<PushUpdateOperatorInputNews> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputNews> /**  */
}


/** Représente les propriétés de mise à jour de l'entité News */
export type SetOperatorInputOfNews = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable?: Maybe<boolean> /**  */
	populationIds: Array<string> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	notTrackingFields: Array<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité News */
export type IncOperatorInputNews = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité News */
export type PushUpdateOperatorInputNews = {
	populationIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité News */
export type PullUpdateOperatorInputNews = {
	populationIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type NoteInput = {
	dynamicProperties?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	operatorId?: Maybe<string> /**  */
	externalAppName?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	context?: Maybe<string> /**  */
	oldValue?: Maybe<any> /**  */
	newValue?: Maybe<any> /**  */
}


/**  */
export type NotificationInput = {
	title?: Maybe<TranslatedFieldInput> /**  */
	text?: Maybe<TranslatedFieldInput> /**  */
	createdDate?: Maybe<Date> /**  */
	userId: string /**  */
	isRead: boolean /**  */
	urlKey: NotificationKey /**  */
	urlObjectId: string /**  */
}


/** Représente les méthodes disponible pour le type Notification */
export type FieldUpdateOperatorOfNotification = {
	set?: Maybe<SetOperatorInputOfNotification> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Notification */
export type SetOperatorInputOfNotification = {
	createdDate?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	isRead?: Maybe<boolean> /**  */
	urlKey?: Maybe<NotificationKey> /**  */
	urlObjectId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	text?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type ObjectClassMappingInput = {
	name?: Maybe<string> /**  */
	ldapObjectClassMappings?: Array<Maybe<LdapObjectClassMappingInput>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type LdapObjectClassMappingInput = {
	type: LdapObjectClassType /**  */
	name?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type ObjectClassMapping */
export type FieldUpdateOperatorOfObjectClassMapping = {
	set?: Maybe<SetOperatorInputOfObjectClassMapping> /**  */
	push?: Maybe<PushUpdateOperatorInputObjectClassMapping> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputObjectClassMapping> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ObjectClassMapping */
export type SetOperatorInputOfObjectClassMapping = {
	name?: Maybe<string> /**  */
	ldapObjectClassMappings: Array<LdapObjectClassMappingInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ObjectClassMapping */
export type PushUpdateOperatorInputObjectClassMapping = {
	ldapObjectClassMappings: Array<LdapObjectClassMappingInput> /**  */
}


/** Représente les propriétés de type collection de l'entité ObjectClassMapping */
export type PullUpdateOperatorInputObjectClassMapping = {
	ldapObjectClassMappings: Array<LdapObjectClassMappingInput> /**  */
}


/**  */
export type OneEventLogInput = {
	date?: Maybe<Date> /**  */
	type: OneEventLogAction /**  */
	data?: Maybe<any> /**  */
	oneEventLogUser?: Maybe<OneEventLogUserInput> /**  */
	entityId: string /**  */
	entityDefaultName?: Maybe<TranslatedFieldInput> /**  */
	entityType?: Maybe<string> /**  */
	isSystem: boolean /**  */
	isMigrating: boolean /**  */
	entityParentId?: Maybe<string> /**  */
	entityParentReference?: Maybe<any> /**  */
	applicationPodName?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
}


/**  */
export type OneEventLogUserInput = {
	subId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type OneEventLog */
export type FieldUpdateOperatorOfOneEventLog = {
	set?: Maybe<SetOperatorInputOfOneEventLog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OneEventLog */
export type SetOperatorInputOfOneEventLog = {
	date?: Maybe<Date> /**  */
	type?: Maybe<OneEventLogAction> /**  */
	entityId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	isSystem?: Maybe<boolean> /**  */
	isMigrating?: Maybe<boolean> /**  */
	entityParentId?: Maybe<string> /**  */
	applicationPodName?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	oneEventLogUser?: Maybe<SetOperatorInputOfOneEventLogUser> /**  */
	entityDefaultName?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OneEventLogUser */
export type SetOperatorInputOfOneEventLogUser = {
	subId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
}


/**  */
export type OneSettingInput = {
	mailSlurpServiceSetting?: Maybe<MailSlurpServiceSettingInput> /**  */
	scheduleTaskSettings?: Array<Maybe<ScheduleTaskSettingInput>> /**  */
	grantAccess?: Array<Maybe<string>> /**  */
}


/**  */
export type MailSlurpServiceSettingInput = {
	enableVerificationStatus: boolean /**  */
	blockService: boolean /**  */
	temporayBlockService?: Maybe<Date> /**  */
	temporayDelayBlockService: number /**  */
	preventDailyLimit: number /**  */
	preventTotalLimit: number /**  */
	rescheduleTime: number /**  */
	emailSetting?: Maybe<OneAlertEmailSettingInput> /**  */
}


/**  */
export type OneAlertEmailSettingInput = {
	sendAlertEmail: boolean /**  */
	alertEmails?: Array<Maybe<string>> /**  */
	lastSend?: Maybe<Date> /**  */
}


/**  */
export type ScheduleTaskSettingInput = {
	enable: boolean /**  */
	name?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type OneSetting */
export type FieldUpdateOperatorOfOneSetting = {
	set?: Maybe<SetOperatorInputOfOneSetting> /**  */
	inc?: Maybe<IncOperatorInputOneSetting> /**  */
	push?: Maybe<PushUpdateOperatorInputOneSetting> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOneSetting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OneSetting */
export type SetOperatorInputOfOneSetting = {
	scheduleTaskSettings: Array<ScheduleTaskSettingInput> /**  */
	grantAccess: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	mailSlurpServiceSetting?: Maybe<SetOperatorInputOfMailSlurpServiceSetting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité MailSlurpServiceSetting */
export type SetOperatorInputOfMailSlurpServiceSetting = {
	enableVerificationStatus?: Maybe<boolean> /**  */
	blockService?: Maybe<boolean> /**  */
	temporayBlockService?: Maybe<Date> /**  */
	temporayDelayBlockService?: Maybe<number> /**  */
	preventDailyLimit?: Maybe<number> /**  */
	preventTotalLimit?: Maybe<number> /**  */
	rescheduleTime?: Maybe<number> /**  */
	emailSetting?: Maybe<SetOperatorInputOfOneAlertEmailSetting> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OneAlertEmailSetting */
export type SetOperatorInputOfOneAlertEmailSetting = {
	sendAlertEmail?: Maybe<boolean> /**  */
	alertEmails: Array<string> /**  */
	lastSend?: Maybe<Date> /**  */
}


/** Représente les propriétés de type numérique de l'entité OneSetting */
export type IncOperatorInputOneSetting = {
	mailSlurpServiceSetting?: Maybe<IncOperatorInputMailSlurpServiceSetting> /**  */
}


/** Représente les propriétés de type numérique de l'entité MailSlurpServiceSetting */
export type IncOperatorInputMailSlurpServiceSetting = {
	temporayDelayBlockService?: Maybe<number> /**  */
	preventDailyLimit?: Maybe<number> /**  */
	preventTotalLimit?: Maybe<number> /**  */
	rescheduleTime?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité OneSetting */
export type PushUpdateOperatorInputOneSetting = {
	scheduleTaskSettings: Array<ScheduleTaskSettingInput> /**  */
	grantAccess: Array<string> /**  */
	mailSlurpServiceSetting?: Maybe<PushUpdateOperatorInputMailSlurpServiceSetting> /**  */
}


/** Représente les propriétés de type collection de l'entité MailSlurpServiceSetting */
export type PushUpdateOperatorInputMailSlurpServiceSetting = {
	emailSetting?: Maybe<PushUpdateOperatorInputOneAlertEmailSetting> /**  */
}


/** Représente les propriétés de type collection de l'entité OneAlertEmailSetting */
export type PushUpdateOperatorInputOneAlertEmailSetting = {
	alertEmails: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité OneSetting */
export type PullUpdateOperatorInputOneSetting = {
	scheduleTaskSettings: Array<ScheduleTaskSettingInput> /**  */
	grantAccess: Array<string> /**  */
	mailSlurpServiceSetting?: Maybe<PullUpdateOperatorInputMailSlurpServiceSetting> /**  */
}


/** Représente les propriétés de type collection de l'entité MailSlurpServiceSetting */
export type PullUpdateOperatorInputMailSlurpServiceSetting = {
	emailSetting?: Maybe<PullUpdateOperatorInputOneAlertEmailSetting> /**  */
}


/** Représente les propriétés de type collection de l'entité OneAlertEmailSetting */
export type PullUpdateOperatorInputOneAlertEmailSetting = {
	alertEmails: Array<string> /**  */
}


/**  */
export type OperatorTeamInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	emails?: Array<Maybe<string>> /**  */
	userIds: Array<string> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	default: boolean /**  */
	allowedTicketsCategories?: Array<Maybe<AllowedEntityInput>> /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible: boolean /**  */
	ticketCategoryAllowed: boolean /**  */
	allowedOperatorTeamIds?: Array<Maybe<AllowedEntityInput>> /**  */
	allowSelection: boolean /**  */
}


/**  */
export type OrganizationalUnitCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type OrganizationalUnit */
export type FieldUpdateOperatorOfOrganizationalUnit = {
	set?: Maybe<SetOperatorInputOfOrganizationalUnit> /**  */
	push?: Maybe<PushUpdateOperatorInputOrganizationalUnit> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputOrganizationalUnit> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OrganizationalUnit */
export type SetOperatorInputOfOrganizationalUnit = {
	error?: Maybe<boolean> /**  */
	objectSid?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	manager?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	templateId?: Maybe<string> /**  */
	allRulesMustBeValidAsset?: Maybe<boolean> /**  */
	allRulesMustBeValidUser?: Maybe<boolean> /**  */
	scanByLdapId?: Maybe<string> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	allowSelection?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	address?: Maybe<SetOperatorInputOfAddress> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	path?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité OrganizationalUnit */
export type PushUpdateOperatorInputOrganizationalUnit = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité OrganizationalUnit */
export type PullUpdateOperatorInputOrganizationalUnit = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	budgetIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type OrganizationInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	address?: Maybe<AddressInput> /**  */
	commercialInformation?: Maybe<CommercialInformationInput> /**  */
	contact?: Maybe<OrganizationContactInput> /**  */
	url?: Maybe<string> /**  */
	blocked: boolean /**  */
	status: OrganizationStatus /**  */
	expireAt?: Maybe<Date> /**  */
	approvedDate?: Maybe<Date> /**  */
	approvedUserId?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	lastConnected?: Maybe<Date> /**  */
	lastUserConnected?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	licenseAgreement?: Maybe<LicenseAgreementInput> /**  */
	license: Array<number> /**  */
	incrementTicket?: Maybe<string> /**  */
	incrementTask?: Maybe<string> /**  */
	incrementKnowledge?: Maybe<string> /**  */
	countMailSend: number /**  */
	countMailReceive: number /**  */
	contentCustomization?: Maybe<ContentCustomizationInput> /**  */
	fileId?: Maybe<string> /**  */
	lang?: Maybe<string> /**  */
	idleTimer: number /**  */
	lengthFiles: number /**  */
	maxFileSize: number /**  */
	allowedFileWithoutExtension: boolean /**  */
	allowedFileExtension?: Array<Maybe<string>> /**  */
	forbiddenFileExtension?: Array<Maybe<string>> /**  */
	fileIncidentId?: Maybe<string> /**  */
	urlImgIncident?: Maybe<string> /**  */
	urlImgRequest?: Maybe<string> /**  */
	fileRequestId?: Maybe<string> /**  */
	allowAnonymousViewFile: boolean /**  */
	marketPlaceToken?: Maybe<string> /**  */
	marketPlaceSubscriptionId?: Maybe<string> /**  */
	userIds?: Array<Maybe<string>> /**  */
	verifaliaEmails?: Array<Maybe<string>> /**  */
	dayOfEndWeek: number /**  */
	lastTicketBacklog?: Maybe<Date> /**  */
	cronTicketBacklog?: Maybe<Date> /**  */
}


/**  */
export type CommercialInformationInput = {
	users: number /**  */
	computers: number /**  */
}


/**  */
export type OrganizationContactInput = {
	lastName?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	civility: Civility /**  */
}


/**  */
export type LicenseAgreementInput = {
	accept: boolean /**  */
	acceptedDate?: Maybe<Date> /**  */
	acceptedSubId?: Maybe<string> /**  */
	acceptedUserName?: Maybe<string> /**  */
}


/**  */
export type ContentCustomizationInput = {
	helpMe?: Maybe<ThemeColorInput> /**  */
	helpMeAreaList?: Maybe<AreaListCustomizationInput> /**  */
}


/**  */
export type ThemeColorInput = {
	littleText?: Maybe<TranslatedFieldInput> /**  */
	boldTexte?: Maybe<TranslatedFieldInput> /**  */
	satisfactionLabel?: Maybe<TranslatedFieldInput> /**  */
	accessTicketsObserverLabel?: Maybe<TranslatedFieldInput> /**  */
	primaryColor?: Maybe<string> /**  */
	secondaryColor?: Maybe<string> /**  */
	headBandColor?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	timerRefresh: number /**  */
	timerRefreshEnable: boolean /**  */
	timerReminder: number /**  */
}


/**  */
export type AreaListCustomizationInput = {
	messageNumberEnable: boolean /**  */
	messageNumber: number /**  */
	articleKbNumberEnable: boolean /**  */
	articleKbNumber: number /**  */
	newsNumberEnable: boolean /**  */
	newsNumber: number /**  */
}


/**  */
export type OriginInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/** Représente les méthodes disponible pour le type Package */
export type FieldUpdateOperatorOfPackage = {
	set?: Maybe<SetOperatorInputOfPackage> /**  */
	push?: Maybe<PushUpdateOperatorInputPackage> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputPackage> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Package */
export type SetOperatorInputOfPackage = {
	name?: Maybe<string> /**  */
	packageName?: Maybe<string> /**  */
	packageVersion?: Maybe<string> /**  */
	packagePathSource?: Maybe<string> /**  */
	campaignIds: Array<string> /**  */
	campaignHistoryId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Package */
export type PushUpdateOperatorInputPackage = {
	campaignIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Package */
export type PullUpdateOperatorInputPackage = {
	campaignIds: Array<string> /**  */
}


/**  */
export type PatInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	token?: Maybe<string> /**  */
	tokenCache?: Maybe<string> /**  */
	expirationDate?: Maybe<Date> /**  */
	activationDate?: Maybe<Date> /**  */
	permissions?: Array<Maybe<string>> /**  */
	clientIds?: Array<Maybe<string>> /**  */
	fullAccess: boolean /**  */
	permissionScopes?: Array<Maybe<PermissionScopeInput>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	mustBeCache: boolean /**  */
	lastTokenUpdated?: Maybe<Date> /**  */
	lastTokenUpdatedBy?: Maybe<string> /**  */
	lastTokenExpiration?: Maybe<Date> /**  */
	tokenCount: number /**  */
}


/** Représente les méthodes disponible pour le type Pat */
export type FieldUpdateOperatorOfPat = {
	set?: Maybe<SetOperatorInputOfPat> /**  */
	inc?: Maybe<IncOperatorInputPat> /**  */
	push?: Maybe<PushUpdateOperatorInputPat> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputPat> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Pat */
export type SetOperatorInputOfPat = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	token?: Maybe<string> /**  */
	tokenCache?: Maybe<string> /**  */
	expirationDate?: Maybe<Date> /**  */
	activationDate?: Maybe<Date> /**  */
	permissions: Array<string> /**  */
	clientIds: Array<string> /**  */
	fullAccess?: Maybe<boolean> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	mustBeCache?: Maybe<boolean> /**  */
	lastTokenUpdated?: Maybe<Date> /**  */
	lastTokenUpdatedBy?: Maybe<string> /**  */
	lastTokenExpiration?: Maybe<Date> /**  */
	tokenCount?: Maybe<number> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité Pat */
export type IncOperatorInputPat = {
	tokenCount?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité Pat */
export type PushUpdateOperatorInputPat = {
	permissions: Array<string> /**  */
	clientIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Pat */
export type PullUpdateOperatorInputPat = {
	permissions: Array<string> /**  */
	clientIds: Array<string> /**  */
	permissionScopes: Array<PermissionScopeInput> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type PnpEntityBaseInput = {
	className?: Maybe<string> /**  */
	classGuid?: Maybe<string> /**  */
	base64Ico?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type PnpEntityBase */
export type FieldUpdateOperatorOfPnpEntityBase = {
	set?: Maybe<SetOperatorInputOfPnpEntityBase> /**  */
}


/** Représente les propriétés de mise à jour de l'entité PnpEntityBase */
export type SetOperatorInputOfPnpEntityBase = {
	className?: Maybe<string> /**  */
	classGuid?: Maybe<string> /**  */
	base64Ico?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type PnpVendorInput = {
	manufacturer?: Maybe<string> /**  */
	pnpId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type PnpVendor */
export type FieldUpdateOperatorOfPnpVendor = {
	set?: Maybe<SetOperatorInputOfPnpVendor> /**  */
}


/** Représente les propriétés de mise à jour de l'entité PnpVendor */
export type SetOperatorInputOfPnpVendor = {
	manufacturer?: Maybe<string> /**  */
	pnpId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type PopulationInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	isManager: boolean /**  */
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	ticketAffectedRuleIds: Array<string> /**  */
	memberDistinguishedNameGroups?: Array<Maybe<string>> /**  */
	memberAzureGroupObjectIds?: Array<Maybe<string>> /**  */
	allowedTicketsCategories?: Array<Maybe<AllowedEntityInput>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	isManual: boolean /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible: boolean /**  */
	ticketCategoryAllowed: boolean /**  */
	locationVisible: boolean /**  */
	locationAllowed: boolean /**  */
	allowedLocations?: Array<Maybe<AllowedEntityInput>> /**  */
	organizationalUnitVisible: boolean /**  */
	organizationalUnitAllowed: boolean /**  */
	allowedOrganizationalUnits?: Array<Maybe<AllowedEntityInput>> /**  */
}


/** Représente les méthodes disponible pour le type Population */
export type FieldUpdateOperatorOfPopulation = {
	set?: Maybe<SetOperatorInputOfPopulation> /**  */
	push?: Maybe<PushUpdateOperatorInputPopulation> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputPopulation> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Population */
export type SetOperatorInputOfPopulation = {
	description?: Maybe<string> /**  */
	isManager?: Maybe<boolean> /**  */
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	ticketAffectedRuleIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	memberAzureGroupObjectIds: Array<string> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	isManual?: Maybe<boolean> /**  */
	securityGroupId?: Maybe<string> /**  */
	ticketCategoryVisible?: Maybe<boolean> /**  */
	ticketCategoryAllowed?: Maybe<boolean> /**  */
	locationVisible?: Maybe<boolean> /**  */
	locationAllowed?: Maybe<boolean> /**  */
	allowedLocations: Array<AllowedEntityInput> /**  */
	organizationalUnitVisible?: Maybe<boolean> /**  */
	organizationalUnitAllowed?: Maybe<boolean> /**  */
	allowedOrganizationalUnits: Array<AllowedEntityInput> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité Population */
export type PushUpdateOperatorInputPopulation = {
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	ticketAffectedRuleIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	memberAzureGroupObjectIds: Array<string> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	allowedLocations: Array<AllowedEntityInput> /**  */
	allowedOrganizationalUnits: Array<AllowedEntityInput> /**  */
}


/** Représente les propriétés de type collection de l'entité Population */
export type PullUpdateOperatorInputPopulation = {
	memberLocationIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	ticketAffectedRuleIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	memberAzureGroupObjectIds: Array<string> /**  */
	allowedTicketsCategories: Array<AllowedEntityInput> /**  */
	allowedLocations: Array<AllowedEntityInput> /**  */
	allowedOrganizationalUnits: Array<AllowedEntityInput> /**  */
}


/**  */
export type PriorityInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	color?: Maybe<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
}


/**  */
export type PrivilegeInput = {
	dynamicProperties?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	isDefault: boolean /**  */
	defaultName?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	ticketEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	taskEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	actionEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	authEditEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	notificationEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
}


/**  */
export type ProblemInput = {
	dynamicProperties?: Maybe<string> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnectorInput> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<ReminderInput> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfactionInput> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	originId?: Maybe<string> /**  */
	cause?: Maybe<string> /**  */
	workaround?: Maybe<string> /**  */
	solution?: Maybe<string> /**  */
}


/**  */
export type QueryBuilderAppointmentInput = {
	allDay: boolean /**  */
	disabled: boolean /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	queryBuilderIds: Array<string> /**  */
	queryBuilderAppointmentTaskId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type QueryBuilderAppointment */
export type FieldUpdateOperatorOfQueryBuilderAppointment = {
	set?: Maybe<SetOperatorInputOfQueryBuilderAppointment> /**  */
	inc?: Maybe<IncOperatorInputQueryBuilderAppointment> /**  */
	push?: Maybe<PushUpdateOperatorInputQueryBuilderAppointment> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputQueryBuilderAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité QueryBuilderAppointment */
export type SetOperatorInputOfQueryBuilderAppointment = {
	allDay?: Maybe<boolean> /**  */
	disabled?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	queryBuilderIds: Array<string> /**  */
	queryBuilderAppointmentTaskId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité QueryBuilderAppointment */
export type IncOperatorInputQueryBuilderAppointment = {
	offSet?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilderAppointment */
export type PushUpdateOperatorInputQueryBuilderAppointment = {
	queryBuilderIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilderAppointment */
export type PullUpdateOperatorInputQueryBuilderAppointment = {
	queryBuilderIds: Array<string> /**  */
}


/**  */
export type QueryBuilderAppointmentTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	queryBuilderIds: Array<string> /**  */
	timeZone?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type QueryBuilderAppointmentTask */
export type FieldUpdateOperatorOfQueryBuilderAppointmentTask = {
	set?: Maybe<SetOperatorInputOfQueryBuilderAppointmentTask> /**  */
	push?: Maybe<PushUpdateOperatorInputQueryBuilderAppointmentTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputQueryBuilderAppointmentTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité QueryBuilderAppointmentTask */
export type SetOperatorInputOfQueryBuilderAppointmentTask = {
	queryBuilderIds: Array<string> /**  */
	timeZone?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilderAppointmentTask */
export type PushUpdateOperatorInputQueryBuilderAppointmentTask = {
	queryBuilderIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilderAppointmentTask */
export type PullUpdateOperatorInputQueryBuilderAppointmentTask = {
	queryBuilderIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type QueryBuilder */
export type FieldUpdateOperatorOfQueryBuilder = {
	set?: Maybe<SetOperatorInputOfQueryBuilder> /**  */
	push?: Maybe<PushUpdateOperatorInputQueryBuilder> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputQueryBuilder> /**  */
}


/** Représente les propriétés de mise à jour de l'entité QueryBuilder */
export type SetOperatorInputOfQueryBuilder = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	expression?: Maybe<string> /**  */
	columns?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	displayRequestToJob?: Maybe<boolean> /**  */
	typeTranslate?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	sftpConnectorIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilder */
export type PushUpdateOperatorInputQueryBuilder = {
	expression?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	sftpConnectorIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité QueryBuilder */
export type PullUpdateOperatorInputQueryBuilder = {
	expression?: Maybe<string> /**  */
	emailPrototypeIds: Array<string> /**  */
	sftpConnectorIds: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les méthodes disponible pour un tableau de type QueryBuilderAppointment */
export type FieldUpdatesOperatorOfQueryBuilderAppointment = {
	id?: Maybe<string> /**  */
	fields?: Maybe<FieldUpdateOperatorOfQueryBuilderAppointment> /**  */
}


/**  */
export type ReportCacheInput = {
	cacheId?: Maybe<string> /**  */
	number: number /**  */
	pageType: number /**  */
	content: Array<number> /**  */
}


/** Représente les méthodes disponible pour le type ReportCache */
export type FieldUpdateOperatorOfReportCache = {
	set?: Maybe<SetOperatorInputOfReportCache> /**  */
	inc?: Maybe<IncOperatorInputReportCache> /**  */
	push?: Maybe<PushUpdateOperatorInputReportCache> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputReportCache> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ReportCache */
export type SetOperatorInputOfReportCache = {
	cacheId?: Maybe<string> /**  */
	number?: Maybe<number> /**  */
	pageType?: Maybe<number> /**  */
	content: Array<number> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ReportCache */
export type IncOperatorInputReportCache = {
	number?: Maybe<number> /**  */
	pageType?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ReportCache */
export type PushUpdateOperatorInputReportCache = {
	content: Array<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ReportCache */
export type PullUpdateOperatorInputReportCache = {
	content: Array<number> /**  */
}


/**  */
export type ReportInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	url?: Maybe<string> /**  */
	fileId?: Maybe<string> /**  */
}


/**  */
export type RequestInput = {
	dynamicProperties?: Maybe<string> /**  */
	tenantIdString?: Maybe<string> /**  */
	created?: Maybe<Date> /**  */
	title?: Maybe<string> /**  */
	counterIncident: number /**  */
	description?: Maybe<string> /**  */
	impactId?: Maybe<string> /**  */
	urgencyId?: Maybe<string> /**  */
	priorityId?: Maybe<string> /**  */
	resolutionCategoryId?: Maybe<string> /**  */
	statusReasonId?: Maybe<string> /**  */
	ticketCategoryId?: Maybe<string> /**  */
	channelId?: Maybe<string> /**  */
	operatorTeamReferentId?: Maybe<string> /**  */
	operatorReferentId?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	levelSupport: number /**  */
	statusId?: Maybe<string> /**  */
	concernedProjectId?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	locationId?: Maybe<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userAffectedId?: Maybe<string> /**  */
	userMakeRequestId?: Maybe<string> /**  */
	tags?: Array<Maybe<string>> /**  */
	respondBefore?: Maybe<Date> /**  */
	resolveBefore?: Maybe<Date> /**  */
	slaId?: Maybe<string> /**  */
	planned?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnectorInput> /**  */
	estimate?: Maybe<string> /**  */
	estimateMinutes: number /**  */
	assetIds: Array<string> /**  */
	fileIds: Array<string> /**  */
	mainImpactedAssetId?: Maybe<string> /**  */
	favoriteUserTicketIds: Array<string> /**  */
	statusWorkflowId?: Maybe<string> /**  */
	ticketNumber: number /**  */
	privateResolutionArticleId?: Maybe<string> /**  */
	privateResolutionComment?: Maybe<string> /**  */
	resolutionComment?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	callbackNumber?: Maybe<string> /**  */
	customField1?: Maybe<string> /**  */
	customField2?: Maybe<string> /**  */
	customField3?: Maybe<string> /**  */
	customField4?: Maybe<string> /**  */
	customField5?: Maybe<string> /**  */
	takeChargeDate?: Maybe<Date> /**  */
	endTreatmentDate?: Maybe<Date> /**  */
	closedTicketDate?: Maybe<Date> /**  */
	reminder?: Maybe<ReminderInput> /**  */
	elaspedTime?: Maybe<string> /**  */
	elaspedTimeHour: number /**  */
	emailConnectorId?: Maybe<string> /**  */
	satisfaction?: Maybe<TicketSatisfactionInput> /**  */
	takeChargeHours: number /**  */
	endTreatmentHours: number /**  */
	closedHours: number /**  */
	isParent: boolean /**  */
	parentId?: Maybe<string> /**  */
	childrenIds: Array<string> /**  */
	problemId?: Maybe<string> /**  */
	externalAlreadySendFileIds: Array<string> /**  */
	privateComment?: Maybe<string> /**  */
	comment?: Maybe<string> /**  */
}


/**  */
export type ResolutionCategoryInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	resolutionModelIds: Array<string> /**  */
}


/**  */
export type ResolutionModelInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	type?: Array<Maybe<string>> /**  */
	resolutionCategoryIds: Array<string> /**  */
	ticketCategoriesIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ResolutionModel */
export type FieldUpdateOperatorOfResolutionModel = {
	set?: Maybe<SetOperatorInputOfResolutionModel> /**  */
	push?: Maybe<PushUpdateOperatorInputResolutionModel> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputResolutionModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ResolutionModel */
export type SetOperatorInputOfResolutionModel = {
	type: Array<string> /**  */
	resolutionCategoryIds: Array<string> /**  */
	ticketCategoriesIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ResolutionModel */
export type PushUpdateOperatorInputResolutionModel = {
	type: Array<string> /**  */
	resolutionCategoryIds: Array<string> /**  */
	ticketCategoriesIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ResolutionModel */
export type PullUpdateOperatorInputResolutionModel = {
	type: Array<string> /**  */
	resolutionCategoryIds: Array<string> /**  */
	ticketCategoriesIds: Array<string> /**  */
	qualification: Array<string> /**  */
	fileIds: Array<string> /**  */
}


/**  */
export type ResultScanAzureAdInput = {
	scanByAzureAdId: string /**  */
	userAzureAd?: Maybe<UserAzureADInput> /**  */
}


/** Représente les méthodes disponible pour le type ResultScanAzureAd */
export type FieldUpdateOperatorOfResultScanAzureAd = {
	set?: Maybe<SetOperatorInputOfResultScanAzureAd> /**  */
	push?: Maybe<PushUpdateOperatorInputResultScanAzureAd> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputResultScanAzureAd> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ResultScanAzureAd */
export type SetOperatorInputOfResultScanAzureAd = {
	scanByAzureAdId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	userAzureAd?: Maybe<SetOperatorInputOfUserAzureAD> /**  */
}


/** Représente les propriétés de type collection de l'entité ResultScanAzureAd */
export type PushUpdateOperatorInputResultScanAzureAd = {
	userAzureAd?: Maybe<PushUpdateOperatorInputUserAzureAD> /**  */
}


/** Représente les propriétés de type collection de l'entité ResultScanAzureAd */
export type PullUpdateOperatorInputResultScanAzureAd = {
	userAzureAd?: Maybe<PullUpdateOperatorInputUserAzureAD> /**  */
}


/**  */
export type RoleAccountInput = {
	roleId: string /**  */
	accountId: string /**  */
}


/** Représente les méthodes disponible pour le type RoleAccount */
export type FieldUpdateOperatorOfRoleAccount = {
	set?: Maybe<SetOperatorInputOfRoleAccount> /**  */
}


/** Représente les propriétés de mise à jour de l'entité RoleAccount */
export type SetOperatorInputOfRoleAccount = {
	roleId?: Maybe<string> /**  */
	accountId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type RoleInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScopeInput>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	hidden: boolean /**  */
	type?: Array<Maybe<string>> /**  */
}


/**  */
export type SatisfactionSettingInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	rating: number /**  */
	commentaryRequired: boolean /**  */
}


/**  */
export type ScanByAddressIPRangeInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	addressIpStart?: Maybe<string> /**  */
	addressIpEnd?: Maybe<string> /**  */
}


/**  */
export type AuditOptionInput = {
	notAuditedType: NotAuditedType /**  */
	sinceDay: number /**  */
}


/**  */
export type PowerControlInput = {
	enabled: boolean /**  */
	hour: number /**  */
	autoPurge: boolean /**  */
	since: number /**  */
}


/**  */
export type WolInput = {
	enabled: boolean /**  */
	timeOut: number /**  */
}


/**  */
export type ShutDownInput = {
	enabled: boolean /**  */
	onlyNotError: boolean /**  */
}


/**  */
export type ScanByAzureAdAppointmentInput = {
	allDay: boolean /**  */
	disabled: boolean /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	scanConfigurationIds: Array<string> /**  */
	scanByAzureAdAppointmentTaskId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type ScanByAzureAdAppointment */
export type FieldUpdateOperatorOfScanByAzureAdAppointment = {
	set?: Maybe<SetOperatorInputOfScanByAzureAdAppointment> /**  */
	inc?: Maybe<IncOperatorInputScanByAzureAdAppointment> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByAzureAdAppointment> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByAzureAdAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByAzureAdAppointment */
export type SetOperatorInputOfScanByAzureAdAppointment = {
	allDay?: Maybe<boolean> /**  */
	disabled?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	html?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	scanConfigurationIds: Array<string> /**  */
	scanByAzureAdAppointmentTaskId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanByAzureAdAppointment */
export type IncOperatorInputScanByAzureAdAppointment = {
	offSet?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAdAppointment */
export type PushUpdateOperatorInputScanByAzureAdAppointment = {
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAdAppointment */
export type PullUpdateOperatorInputScanByAzureAdAppointment = {
	scanConfigurationIds: Array<string> /**  */
}


/**  */
export type ScanByAzureAdAppointmentTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ScanByAzureAdAppointmentTask */
export type FieldUpdateOperatorOfScanByAzureAdAppointmentTask = {
	set?: Maybe<SetOperatorInputOfScanByAzureAdAppointmentTask> /**  */
	push?: Maybe<PushUpdateOperatorInputScanByAzureAdAppointmentTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanByAzureAdAppointmentTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByAzureAdAppointmentTask */
export type SetOperatorInputOfScanByAzureAdAppointmentTask = {
	scanConfigurationIds: Array<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAdAppointmentTask */
export type PushUpdateOperatorInputScanByAzureAdAppointmentTask = {
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanByAzureAdAppointmentTask */
export type PullUpdateOperatorInputScanByAzureAdAppointmentTask = {
	scanConfigurationIds: Array<string> /**  */
}


/**  */
export type ScanByAzureAdInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	applicationAzureAdId: string /**  */
	importDisabledUser: boolean /**  */
	importMember: boolean /**  */
	importGuest: boolean /**  */
	importNoType: boolean /**  */
	importGroup: boolean /**  */
	groupObjectIds?: Array<Maybe<string>> /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	recursiveGroup: boolean /**  */
}


/** Représente les méthodes disponible pour un tableau de type ScanByAzureAdAppointment */
export type FieldUpdatesOperatorOfScanByAzureAdAppointment = {
	id?: Maybe<string> /**  */
	fields?: Maybe<FieldUpdateOperatorOfScanByAzureAdAppointment> /**  */
}


/**  */
export type ScanByAzureAdTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	scanByAzureAdAppointmentId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type ScanByAzureAdTask */
export type FieldUpdateOperatorOfScanByAzureAdTask = {
	set?: Maybe<SetOperatorInputOfScanByAzureAdTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanByAzureAdTask */
export type SetOperatorInputOfScanByAzureAdTask = {
	scanByAzureAdAppointmentId?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type ScanByHostInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	hostName?: Array<Maybe<string>> /**  */
}


/**  */
export type ScanByLdapInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	domain?: Maybe<string> /**  */
	credentialId?: Maybe<string> /**  */
	useSsl: boolean /**  */
	usePort: number /**  */
	protocolVersion: number /**  */
	connectionTimeoutIn: number /**  */
	ldapAuthType: LdapAuthType /**  */
	distinguishedName?: Maybe<string> /**  */
	importGroup: boolean /**  */
	importUser: boolean /**  */
	importDisabledUser: boolean /**  */
	importRecursiveGroup: boolean /**  */
	importComputer: boolean /**  */
	importLdapStruct: boolean /**  */
	disableUsers: boolean /**  */
	importOrganizationalUnit: ImportOrganizationalUnit /**  */
	forceSearchFilter: boolean /**  */
	fieldMappingId?: Maybe<string> /**  */
	objectClassMappingId?: Maybe<string> /**  */
	ldapDistinguishedNames?: Array<Maybe<string>> /**  */
}


/**  */
export type ScanByLocalAgentInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
}


/**  */
export type ScanByWorkgroupInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	pingTimeOut: number /**  */
	disablePing: boolean /**  */
	scanTimeOut: number /**  */
	auditOption?: Maybe<AuditOptionInput> /**  */
	powerControl?: Maybe<PowerControlInput> /**  */
	wol?: Maybe<WolInput> /**  */
	shutDown?: Maybe<ShutDownInput> /**  */
	lastStartScan?: Maybe<Date> /**  */
	lastEndScan?: Maybe<Date> /**  */
	lastModifyScan?: Maybe<Date> /**  */
	lastScanId?: Maybe<string> /**  */
	totalScan: number /**  */
	totalScanEnd: number /**  */
	enabled: boolean /**  */
	state: ActionHistoryState /**  */
	displayState?: Maybe<string> /**  */
	scanRegistryKeyIds: Array<string> /**  */
	scanDataFileIds: Array<string> /**  */
	scanImportOptionIds: Array<string> /**  */
	scanMethodProfilId?: Maybe<string> /**  */
	scanExcludeIds: Array<string> /**  */
	clarilogServerId?: Maybe<string> /**  */
	networkCredentialIds: Array<string> /**  */
	lastTask?: Maybe<Date> /**  */
	endTask?: Maybe<Date> /**  */
	scanSuccessCount: number /**  */
	scanErrorCount: number /**  */
	scanTotalCount: number /**  */
	percent: number /**  */
	onlyReadOnly: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workgroupName?: Maybe<string> /**  */
}


/**  */
export type ScanConfigurationAppointmentInput = {
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type ScanConfigurationAppointment */
export type FieldUpdateOperatorOfScanConfigurationAppointment = {
	set?: Maybe<SetOperatorInputOfScanConfigurationAppointment> /**  */
	inc?: Maybe<IncOperatorInputScanConfigurationAppointment> /**  */
	push?: Maybe<PushUpdateOperatorInputScanConfigurationAppointment> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanConfigurationAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanConfigurationAppointment */
export type SetOperatorInputOfScanConfigurationAppointment = {
	allDay?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	disabled?: Maybe<boolean> /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanConfigurationAppointment */
export type IncOperatorInputScanConfigurationAppointment = {
	offSet?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanConfigurationAppointment */
export type PushUpdateOperatorInputScanConfigurationAppointment = {
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanConfigurationAppointment */
export type PullUpdateOperatorInputScanConfigurationAppointment = {
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour un tableau de type ScanConfigurationAppointment */
export type FieldUpdatesOperatorOfScanConfigurationAppointment = {
	id?: Maybe<string> /**  */
	fields?: Maybe<FieldUpdateOperatorOfScanConfigurationAppointment> /**  */
}


/**  */
export type ScanDataFileInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	driveType: Array<DriveType> /**  */
	scanDataFileObject: ScanDataFileObject /**  */
	enabled: boolean /**  */
	file?: Maybe<string> /**  */
	extensions?: Array<Maybe<string>> /**  */
	version?: Maybe<string> /**  */
	withSize: boolean /**  */
	size: number /**  */
	operator: CompareToType /**  */
	includeFolders?: Array<Maybe<IncludeFolderInput>> /**  */
	excludeFolders?: Array<Maybe<ExcludeFolderInput>> /**  */
	scanConfigurationIds: Array<string> /**  */
	softwareId?: Maybe<string> /**  */
}


/**  */
export type IncludeFolderInput = {
	name?: Maybe<string> /**  */
	includeSubFolder: boolean /**  */
	enabled: boolean /**  */
}


/**  */
export type ExcludeFolderInput = {
	name?: Maybe<string> /**  */
	enabled: boolean /**  */
}


/** Représente les méthodes disponible pour le type ScanDataFile */
export type FieldUpdateOperatorOfScanDataFile = {
	set?: Maybe<SetOperatorInputOfScanDataFile> /**  */
	inc?: Maybe<IncOperatorInputScanDataFile> /**  */
	push?: Maybe<PushUpdateOperatorInputScanDataFile> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanDataFile> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanDataFile */
export type SetOperatorInputOfScanDataFile = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	driveType: Array<DriveType> /**  */
	scanDataFileObject?: Maybe<ScanDataFileObject> /**  */
	enabled?: Maybe<boolean> /**  */
	file?: Maybe<string> /**  */
	extensions: Array<string> /**  */
	version?: Maybe<string> /**  */
	withSize?: Maybe<boolean> /**  */
	size?: Maybe<number> /**  */
	operator?: Maybe<CompareToType> /**  */
	includeFolders: Array<IncludeFolderInput> /**  */
	excludeFolders: Array<ExcludeFolderInput> /**  */
	scanConfigurationIds: Array<string> /**  */
	softwareId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanDataFile */
export type IncOperatorInputScanDataFile = {
	size?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanDataFile */
export type PushUpdateOperatorInputScanDataFile = {
	driveType: Array<DriveType> /**  */
	extensions: Array<string> /**  */
	includeFolders: Array<IncludeFolderInput> /**  */
	excludeFolders: Array<ExcludeFolderInput> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanDataFile */
export type PullUpdateOperatorInputScanDataFile = {
	driveType: Array<DriveType> /**  */
	extensions: Array<string> /**  */
	includeFolders: Array<IncludeFolderInput> /**  */
	excludeFolders: Array<ExcludeFolderInput> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/**  */
export type ScanExcludeInput = {
	scanExcludeOperator: ScanExcludeOperator /**  */
	scanExcludeCategory: ScanExcludeCategory /**  */
	name?: Maybe<string> /**  */
	value?: Array<Maybe<string>> /**  */
	description?: Maybe<string> /**  */
	enabled: boolean /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ScanExclude */
export type FieldUpdateOperatorOfScanExclude = {
	set?: Maybe<SetOperatorInputOfScanExclude> /**  */
	push?: Maybe<PushUpdateOperatorInputScanExclude> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanExclude> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanExclude */
export type SetOperatorInputOfScanExclude = {
	scanExcludeOperator?: Maybe<ScanExcludeOperator> /**  */
	scanExcludeCategory?: Maybe<ScanExcludeCategory> /**  */
	name?: Maybe<string> /**  */
	value: Array<string> /**  */
	description?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	scanConfigurationIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanExclude */
export type PushUpdateOperatorInputScanExclude = {
	value: Array<string> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanExclude */
export type PullUpdateOperatorInputScanExclude = {
	value: Array<string> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/**  */
export type ScanImportOptionInput = {
	autoApproved: boolean /**  */
	createScreen: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	serialNumberSearch: SerialNumberSearch /**  */
	inheritLocation: boolean /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	scanImportOptionLogonSessions?: Array<Maybe<ScanImportOptionLogonSessionInput>> /**  */
	scanImportOptionIpRanges?: Array<Maybe<ScanImportOptionIpRangeInput>> /**  */
	scanImportOptionIpAddresses?: Array<Maybe<ScanImportOptionIpAddressInput>> /**  */
	searchWarranty: boolean /**  */
	importSoftwareLinux: boolean /**  */
	importUser: boolean /**  */
	importUserMode: ImportUserMode /**  */
	mainUserMode: MainUserMode /**  */
	createUser: boolean /**  */
	updateManualData: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enabled: boolean /**  */
	description?: Maybe<string> /**  */
	dateUp: boolean /**  */
	assetStateDateUp: boolean /**  */
	forceMonitorName: boolean /**  */
	scanConfigurationIds: Array<string> /**  */
	changeStatusActivated?: Maybe<boolean> /**  */
	concernedStatusIds: Array<string> /**  */
	destinationStatusId?: Maybe<string> /**  */
}


/**  */
export type ScanImportOptionIpRangeInput = {
	ipAddressStart?: Maybe<string> /**  */
	ipAddressEnd?: Maybe<string> /**  */
}


/**  */
export type ScanImportOptionIpAddressInput = {
	value?: Maybe<string> /**  */
	scanExcludeOperator: ScanExcludeOperator /**  */
}


/** Représente les méthodes disponible pour le type ScanImportOption */
export type FieldUpdateOperatorOfScanImportOption = {
	set?: Maybe<SetOperatorInputOfScanImportOption> /**  */
	inc?: Maybe<IncOperatorInputScanImportOption> /**  */
	push?: Maybe<PushUpdateOperatorInputScanImportOption> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanImportOption> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanImportOption */
export type SetOperatorInputOfScanImportOption = {
	autoApproved?: Maybe<boolean> /**  */
	createScreen?: Maybe<boolean> /**  */
	searchInDeleted?: Maybe<boolean> /**  */
	searchInArchived?: Maybe<boolean> /**  */
	serialNumberSearch?: Maybe<SerialNumberSearch> /**  */
	inheritLocation?: Maybe<boolean> /**  */
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	scanImportOptionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	scanImportOptionIpRanges: Array<ScanImportOptionIpRangeInput> /**  */
	scanImportOptionIpAddresses: Array<ScanImportOptionIpAddressInput> /**  */
	searchWarranty?: Maybe<boolean> /**  */
	importSoftwareLinux?: Maybe<boolean> /**  */
	importUser?: Maybe<boolean> /**  */
	importUserMode?: Maybe<ImportUserMode> /**  */
	mainUserMode?: Maybe<MainUserMode> /**  */
	createUser?: Maybe<boolean> /**  */
	updateManualData?: Maybe<boolean> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	canDrag?: Maybe<boolean> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enabled?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	dateUp?: Maybe<boolean> /**  */
	assetStateDateUp?: Maybe<boolean> /**  */
	forceMonitorName?: Maybe<boolean> /**  */
	scanConfigurationIds: Array<string> /**  */
	changeStatusActivated?: Maybe<boolean> /**  */
	concernedStatusIds: Array<string> /**  */
	destinationStatusId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité ScanImportOption */
export type IncOperatorInputScanImportOption = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanImportOption */
export type PushUpdateOperatorInputScanImportOption = {
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	scanImportOptionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	scanImportOptionIpRanges: Array<ScanImportOptionIpRangeInput> /**  */
	scanImportOptionIpAddresses: Array<ScanImportOptionIpAddressInput> /**  */
	scanConfigurationIds: Array<string> /**  */
	concernedStatusIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanImportOption */
export type PullUpdateOperatorInputScanImportOption = {
	assetCategoryIds: Array<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	excludeAssetIds: Array<string> /**  */
	scanImportOptionLogonSessions: Array<ScanImportOptionLogonSessionInput> /**  */
	scanImportOptionIpRanges: Array<ScanImportOptionIpRangeInput> /**  */
	scanImportOptionIpAddresses: Array<ScanImportOptionIpAddressInput> /**  */
	scanConfigurationIds: Array<string> /**  */
	concernedStatusIds: Array<string> /**  */
}


/**  */
export type ScanMethodProfilInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	scanMethodIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type ScanMethodInput = {
	methodName?: Maybe<string> /**  */
	fast: boolean /**  */
	normal: boolean /**  */
	enabled: boolean /**  */
}


/** Représente les méthodes disponible pour le type ScanMethod */
export type FieldUpdateOperatorOfScanMethod = {
	set?: Maybe<SetOperatorInputOfScanMethod> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanMethod */
export type SetOperatorInputOfScanMethod = {
	methodName?: Maybe<string> /**  */
	fast?: Maybe<boolean> /**  */
	normal?: Maybe<boolean> /**  */
	enabled?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type ScanRegistryKeyInput = {
	enabled: boolean /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hKey: HKey /**  */
	allValue: boolean /**  */
	keyPath?: Maybe<string> /**  */
	value?: Array<Maybe<string>> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ScanRegistryKey */
export type FieldUpdateOperatorOfScanRegistryKey = {
	set?: Maybe<SetOperatorInputOfScanRegistryKey> /**  */
	push?: Maybe<PushUpdateOperatorInputScanRegistryKey> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputScanRegistryKey> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScanRegistryKey */
export type SetOperatorInputOfScanRegistryKey = {
	enabled?: Maybe<boolean> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hKey?: Maybe<HKey> /**  */
	allValue?: Maybe<boolean> /**  */
	keyPath?: Maybe<string> /**  */
	value: Array<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanRegistryKey */
export type PushUpdateOperatorInputScanRegistryKey = {
	value: Array<string> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité ScanRegistryKey */
export type PullUpdateOperatorInputScanRegistryKey = {
	value: Array<string> /**  */
	scanConfigurationIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ScheduleTask */
export type FieldUpdateOperatorOfScheduleTask = {
	set?: Maybe<SetOperatorInputOfScheduleTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ScheduleTask */
export type SetOperatorInputOfScheduleTask = {
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SecurityGroupInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	memberLocationIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups?: Array<Maybe<string>> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	securityGroupRuleIds: Array<string> /**  */
	isManual: boolean /**  */
	category: SecurityGroupCategory /**  */
}


/** Représente les méthodes disponible pour le type SecurityGroup */
export type FieldUpdateOperatorOfSecurityGroup = {
	set?: Maybe<SetOperatorInputOfSecurityGroup> /**  */
	push?: Maybe<PushUpdateOperatorInputSecurityGroup> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSecurityGroup> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SecurityGroup */
export type SetOperatorInputOfSecurityGroup = {
	description?: Maybe<string> /**  */
	memberLocationIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	securityGroupRuleIds: Array<string> /**  */
	isManual?: Maybe<boolean> /**  */
	category?: Maybe<SecurityGroupCategory> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité SecurityGroup */
export type PushUpdateOperatorInputSecurityGroup = {
	memberLocationIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	securityGroupRuleIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SecurityGroup */
export type PullUpdateOperatorInputSecurityGroup = {
	memberLocationIds: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	memberOrganizationalUnitIds: Array<string> /**  */
	memberDistinguishedNameGroups: Array<string> /**  */
	securityGroupRuleIds: Array<string> /**  */
}


/**  */
export type SecurityGroupRuleInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	securityGroupIds: Array<string> /**  */
	disableFilterLocation: boolean /**  */
	disableFilterOrganizationalUnit: boolean /**  */
	disableFilterAssetCategory: boolean /**  */
}


/** Représente les méthodes disponible pour le type SecurityGroupRule */
export type FieldUpdateOperatorOfSecurityGroupRule = {
	set?: Maybe<SetOperatorInputOfSecurityGroupRule> /**  */
	push?: Maybe<PushUpdateOperatorInputSecurityGroupRule> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSecurityGroupRule> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SecurityGroupRule */
export type SetOperatorInputOfSecurityGroupRule = {
	description?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	securityGroupIds: Array<string> /**  */
	disableFilterLocation?: Maybe<boolean> /**  */
	disableFilterOrganizationalUnit?: Maybe<boolean> /**  */
	disableFilterAssetCategory?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité SecurityGroupRule */
export type PushUpdateOperatorInputSecurityGroupRule = {
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	securityGroupIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SecurityGroupRule */
export type PullUpdateOperatorInputSecurityGroupRule = {
	locationIds: Array<string> /**  */
	organizationalUnitIds: Array<string> /**  */
	assetCategoryIds: Array<string> /**  */
	securityGroupIds: Array<string> /**  */
}


/**  */
export type ServiceLevelAgreementInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	supportTimes: number /**  */
	resolutionTimes: number /**  */
	ticketCategoryIds: Array<string> /**  */
	priorityIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	workTimeId?: Maybe<string> /**  */
	dayOffIds: Array<string> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	populationsIds: Array<string> /**  */
}


/**  */
export type ServiceOfferInput = {
	dynamicProperties?: Maybe<string> /**  */
	error: boolean /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	path?: Maybe<TranslatedFieldInput> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	supplierIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type ServiceOffer */
export type FieldUpdateOperatorOfServiceOffer = {
	set?: Maybe<SetOperatorInputOfServiceOffer> /**  */
	push?: Maybe<PushUpdateOperatorInputServiceOffer> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputServiceOffer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité ServiceOffer */
export type SetOperatorInputOfServiceOffer = {
	error?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	parentId?: Maybe<string> /**  */
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	supplierIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
	isApproved?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	path?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité ServiceOffer */
export type PushUpdateOperatorInputServiceOffer = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	supplierIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/** Représente les propriétés de type collection de l'entité ServiceOffer */
export type PullUpdateOperatorInputServiceOffer = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	supplierIds: Array<string> /**  */
	dynamicProperties?: Maybe<any> /**  */
}


/**  */
export type SftpConnectorHistoryInput = {
	sftpConnectorId: string /**  */
	date?: Maybe<Date> /**  */
	sftpAction: SftpAction /**  */
	hasError: boolean /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type SftpConnectorHistory */
export type FieldUpdateOperatorOfSftpConnectorHistory = {
	set?: Maybe<SetOperatorInputOfSftpConnectorHistory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SftpConnectorHistory */
export type SetOperatorInputOfSftpConnectorHistory = {
	sftpConnectorId?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	sftpAction?: Maybe<SftpAction> /**  */
	hasError?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SftpConnectorInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	server?: Maybe<string> /**  */
	port: number /**  */
	userName?: Maybe<string> /**  */
	remoteFolder?: Maybe<string> /**  */
	environnement?: Maybe<string> /**  */
	enabled: boolean /**  */
	queryBuilderIds: Array<string> /**  */
}


/**  */
export type SnmpCredentialInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	encryptionKey?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	community?: Maybe<string> /**  */
	snmpVersion: SnmpVersion /**  */
	authentificationSnmpV3: AuthentificationSnmpV3 /**  */
	encryptionSnmpV3: EncryptionSnmpV3 /**  */
	port: number /**  */
}


/**  */
export type SnmpMapInformationKbInput = {
	sysOid?: Maybe<string> /**  */
	type: SnmpType /**  */
	name?: Maybe<string> /**  */
	assetCategoryKey?: Maybe<string> /**  */
	snmpOidObjects?: Array<Maybe<SnmpOidObjectInput>> /**  */
	assetCategoryId?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type SnmpMapInformationKb */
export type FieldUpdateOperatorOfSnmpMapInformationKb = {
	set?: Maybe<SetOperatorInputOfSnmpMapInformationKb> /**  */
	push?: Maybe<PushUpdateOperatorInputSnmpMapInformationKb> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSnmpMapInformationKb> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SnmpMapInformationKb */
export type SetOperatorInputOfSnmpMapInformationKb = {
	sysOid?: Maybe<string> /**  */
	type?: Maybe<SnmpType> /**  */
	name?: Maybe<string> /**  */
	assetCategoryKey?: Maybe<string> /**  */
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
	assetCategoryId?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpMapInformationKb */
export type PushUpdateOperatorInputSnmpMapInformationKb = {
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
}


/** Représente les propriétés de type collection de l'entité SnmpMapInformationKb */
export type PullUpdateOperatorInputSnmpMapInformationKb = {
	snmpOidObjects: Array<SnmpOidObjectInput> /**  */
}


/**  */
export type SnmpMapInformationInput = {
	dynamicProperties?: Maybe<string> /**  */
	sysOid?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	type: SnmpType /**  */
	snmpOidObjects?: Array<Maybe<SnmpOidObjectInput>> /**  */
	assetCategoryId?: Maybe<string> /**  */
}


/**  */
export type SnmpVendorInput = {
	value: number /**  */
	manufacturer?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type SnmpVendor */
export type FieldUpdateOperatorOfSnmpVendor = {
	set?: Maybe<SetOperatorInputOfSnmpVendor> /**  */
	inc?: Maybe<IncOperatorInputSnmpVendor> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SnmpVendor */
export type SetOperatorInputOfSnmpVendor = {
	value?: Maybe<number> /**  */
	manufacturer?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité SnmpVendor */
export type IncOperatorInputSnmpVendor = {
	value?: Maybe<number> /**  */
}


/**  */
export type SoftwareAttributeTypeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareAttributeType */
export type FieldUpdateOperatorOfSoftwareAttributeType = {
	set?: Maybe<SetOperatorInputOfSoftwareAttributeType> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareAttributeType */
export type SetOperatorInputOfSoftwareAttributeType = {
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type SoftwareConnectionModeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareConnectionMode */
export type FieldUpdateOperatorOfSoftwareConnectionMode = {
	set?: Maybe<SetOperatorInputOfSoftwareConnectionMode> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareConnectionMode */
export type SetOperatorInputOfSoftwareConnectionMode = {
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type SoftwareGroupInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	obligatory: boolean /**  */
	softwarePackageIds: Array<string> /**  */
	expression?: Maybe<string> /**  */
	softwareGroupForbidden: boolean /**  */
	softwareGroupHidden: boolean /**  */
	contractIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareGroup */
export type FieldUpdateOperatorOfSoftwareGroup = {
	set?: Maybe<SetOperatorInputOfSoftwareGroup> /**  */
	push?: Maybe<PushUpdateOperatorInputSoftwareGroup> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputSoftwareGroup> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareGroup */
export type SetOperatorInputOfSoftwareGroup = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	obligatory?: Maybe<boolean> /**  */
	softwarePackageIds: Array<string> /**  */
	expression?: Maybe<string> /**  */
	softwareGroupForbidden?: Maybe<boolean> /**  */
	softwareGroupHidden?: Maybe<boolean> /**  */
	contractIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SoftwareGroup */
export type PushUpdateOperatorInputSoftwareGroup = {
	softwarePackageIds: Array<string> /**  */
	expression?: Maybe<string> /**  */
	contractIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité SoftwareGroup */
export type PullUpdateOperatorInputSoftwareGroup = {
	softwarePackageIds: Array<string> /**  */
	expression?: Maybe<string> /**  */
	contractIds: Array<string> /**  */
}


/**  */
export type SoftwareHostedComponentInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareHostedComponent */
export type FieldUpdateOperatorOfSoftwareHostedComponent = {
	set?: Maybe<SetOperatorInputOfSoftwareHostedComponent> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareHostedComponent */
export type SetOperatorInputOfSoftwareHostedComponent = {
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type SoftwareHostedLinkInput = {
	softwareId: string /**  */
	assetId: string /**  */
	softwareHostedComponentId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareHostedLink */
export type FieldUpdateOperatorOfSoftwareHostedLink = {
	set?: Maybe<SetOperatorInputOfSoftwareHostedLink> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareHostedLink */
export type SetOperatorInputOfSoftwareHostedLink = {
	softwareId?: Maybe<string> /**  */
	assetId?: Maybe<string> /**  */
	softwareHostedComponentId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SoftwarePackageInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	create?: Maybe<Date> /**  */
	validate?: Maybe<Date> /**  */
	userIds: Array<string> /**  */
	assetIds: Array<string> /**  */
	softwareIds: Array<string> /**  */
	softwareGroupIds: Array<string> /**  */
}


/**  */
export type SoftwareReferentLinkInput = {
	softwareId: string /**  */
	userId: string /**  */
	softwareReferentTypeId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareReferentLink */
export type FieldUpdateOperatorOfSoftwareReferentLink = {
	set?: Maybe<SetOperatorInputOfSoftwareReferentLink> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareReferentLink */
export type SetOperatorInputOfSoftwareReferentLink = {
	softwareId?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
	softwareReferentTypeId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SoftwareReferentTypeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type SoftwareReferentType */
export type FieldUpdateOperatorOfSoftwareReferentType = {
	set?: Maybe<SetOperatorInputOfSoftwareReferentType> /**  */
}


/** Représente les propriétés de mise à jour de l'entité SoftwareReferentType */
export type SetOperatorInputOfSoftwareReferentType = {
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type SoftwareInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	internalName?: Maybe<string> /**  */
	internalCode?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	hidden: boolean /**  */
	notAllowed: boolean /**  */
	obligatory: boolean /**  */
	softwareAllowedType: SoftwareAllowedType /**  */
	approved: boolean /**  */
	recorded: boolean /**  */
	publisher?: Maybe<string> /**  */
	version?: Maybe<string> /**  */
	softwareCategory: SoftwareCategory /**  */
	manualData: boolean /**  */
	softwareGroupId?: Maybe<string> /**  */
	softwarePackageIds: Array<string> /**  */
	installCount: number /**  */
	contractIds: Array<string> /**  */
	auditType: AuditType /**  */
	shortcutName?: Maybe<string> /**  */
	softwareAttributes?: Array<Maybe<SoftwareAttributeInput>> /**  */
	ldapGroupDn?: Array<Maybe<string>> /**  */
	softwareConnectionModeId?: Maybe<string> /**  */
}


/**  */
export type SshCredentialInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	port: number /**  */
}


/**  */
export type StatisticTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	ticketId: string /**  */
	modifier: TicketStatisticModifier /**  */
}


/** Représente les méthodes disponible pour le type StatisticTask */
export type FieldUpdateOperatorOfStatisticTask = {
	set?: Maybe<SetOperatorInputOfStatisticTask> /**  */
	inc?: Maybe<IncOperatorInputStatisticTask> /**  */
	push?: Maybe<PushUpdateOperatorInputStatisticTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputStatisticTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StatisticTask */
export type SetOperatorInputOfStatisticTask = {
	ticketId?: Maybe<string> /**  */
	modifier?: Maybe<TicketStatisticModifier> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	savedTicket?: Maybe<SetOperatorInputOfTicket> /**  */
}


/** Représente les propriétés de type numérique de l'entité StatisticTask */
export type IncOperatorInputStatisticTask = {
	savedTicket?: Maybe<IncOperatorInputTicket> /**  */
}


/** Représente les propriétés de type collection de l'entité StatisticTask */
export type PushUpdateOperatorInputStatisticTask = {
	savedTicket?: Maybe<PushUpdateOperatorInputTicket> /**  */
}


/** Représente les propriétés de type collection de l'entité StatisticTask */
export type PullUpdateOperatorInputStatisticTask = {
	savedTicket?: Maybe<PullUpdateOperatorInputTicket> /**  */
}


/**  */
export type StatusWorkflowInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	qualification?: Array<Maybe<string>> /**  */
	enable: boolean /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	copyFromId?: Maybe<string> /**  */
	config?: Maybe<StatusConfigInput> /**  */
}


/**  */
export type StatusConfigInput = {
	edges?: Array<Maybe<StatusEdgeInput>> /**  */
	nodes?: Array<Maybe<StatusNodeInput>> /**  */
}


/**  */
export type StatusEdgeInput = {
	title?: Maybe<string> /**  */
	fromId?: Maybe<string> /**  */
	toId?: Maybe<string> /**  */
	objId?: Maybe<string> /**  */
	fromPointIndex: number /**  */
	toPointIndex: number /**  */
	points?: Array<Maybe<StatusPointInput>> /**  */
}


/**  */
export type StatusPointInput = {
	x: number /**  */
	y: number /**  */
}


/**  */
export type StatusNodeInput = {
	isEntry: boolean /**  */
	isEnding: boolean /**  */
	isTakingCharge: boolean /**  */
	isEndTreatment: boolean /**  */
	isCommentaryRequired: boolean /**  */
	canAddSatisfaction: boolean /**  */
	isForbiddenPredecessor: boolean /**  */
	objId?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	statusId: string /**  */
	name?: Maybe<string> /**  */
	x: number /**  */
	y: number /**  */
	rightToMove?: Maybe<StatusMoveRightInput> /**  */
}


/**  */
export type StatusMoveRightInput = {
	allOperator: boolean /**  */
	referentTeam: boolean /**  */
	affectedTeam: boolean /**  */
	allConcerned: boolean /**  */
	makeRequest: boolean /**  */
	affected: boolean /**  */
	operatorAffected: boolean /**  */
	operatorReferent: boolean /**  */
	manager: boolean /**  */
	allTaskFinished: boolean /**  */
	customUserIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type StatusWorkflow */
export type FieldUpdateOperatorOfStatusWorkflow = {
	set?: Maybe<SetOperatorInputOfStatusWorkflow> /**  */
	push?: Maybe<PushUpdateOperatorInputStatusWorkflow> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputStatusWorkflow> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StatusWorkflow */
export type SetOperatorInputOfStatusWorkflow = {
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	qualification: Array<string> /**  */
	enable?: Maybe<boolean> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	copyFromId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	config?: Maybe<SetOperatorInputOfStatusConfig> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StatusConfig */
export type SetOperatorInputOfStatusConfig = {
	edges: Array<StatusEdgeInput> /**  */
	nodes: Array<StatusNodeInput> /**  */
}


/** Représente les propriétés de type collection de l'entité StatusWorkflow */
export type PushUpdateOperatorInputStatusWorkflow = {
	qualification: Array<string> /**  */
	config?: Maybe<PushUpdateOperatorInputStatusConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité StatusConfig */
export type PushUpdateOperatorInputStatusConfig = {
	edges: Array<StatusEdgeInput> /**  */
	nodes: Array<StatusNodeInput> /**  */
}


/** Représente les propriétés de type collection de l'entité StatusWorkflow */
export type PullUpdateOperatorInputStatusWorkflow = {
	qualification: Array<string> /**  */
	config?: Maybe<PullUpdateOperatorInputStatusConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité StatusConfig */
export type PullUpdateOperatorInputStatusConfig = {
	edges: Array<StatusEdgeInput> /**  */
	nodes: Array<StatusNodeInput> /**  */
}


/**  */
export type StockInReasonInput = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type StockInReason */
export type FieldUpdateOperatorOfStockInReason = {
	set?: Maybe<SetOperatorInputOfStockInReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StockInReason */
export type SetOperatorInputOfStockInReason = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type StockOutReasonInput = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type StockOutReason */
export type FieldUpdateOperatorOfStockOutReason = {
	set?: Maybe<SetOperatorInputOfStockOutReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StockOutReason */
export type SetOperatorInputOfStockOutReason = {
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type StockTransferReasonInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
}


/** Représente les méthodes disponible pour le type StockTransferReason */
export type FieldUpdateOperatorOfStockTransferReason = {
	set?: Maybe<SetOperatorInputOfStockTransferReason> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StockTransferReason */
export type SetOperatorInputOfStockTransferReason = {
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type StockTransferInput = {
	name?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	transferById: string /**  */
	fromStorageUnitId: string /**  */
	toStorageUnitId: string /**  */
	description?: Maybe<string> /**  */
	motifStockTransferId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	carts?: Array<Maybe<OutCartInput>> /**  */
	assetIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type StockTransfer */
export type FieldUpdateOperatorOfStockTransfer = {
	set?: Maybe<SetOperatorInputOfStockTransfer> /**  */
	push?: Maybe<PushUpdateOperatorInputStockTransfer> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputStockTransfer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité StockTransfer */
export type SetOperatorInputOfStockTransfer = {
	name?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	transferById?: Maybe<string> /**  */
	fromStorageUnitId?: Maybe<string> /**  */
	toStorageUnitId?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	motifStockTransferId?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	carts: Array<OutCartInput> /**  */
	assetIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité StockTransfer */
export type PushUpdateOperatorInputStockTransfer = {
	fileIds: Array<string> /**  */
	carts: Array<OutCartInput> /**  */
	assetIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité StockTransfer */
export type PullUpdateOperatorInputStockTransfer = {
	fileIds: Array<string> /**  */
	carts: Array<OutCartInput> /**  */
	assetIds: Array<string> /**  */
}


/**  */
export type SubstituteInput = {
	value?: Maybe<string> /**  */
	replace?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type Substitute */
export type FieldUpdateOperatorOfSubstitute = {
	set?: Maybe<SetOperatorInputOfSubstitute> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Substitute */
export type SetOperatorInputOfSubstitute = {
	value?: Maybe<string> /**  */
	replace?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type SupplierInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	naf?: Maybe<string> /**  */
	siret?: Maybe<string> /**  */
	intraCommunity?: Maybe<string> /**  */
	hidden: boolean /**  */
	webSite?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	tva?: Maybe<string> /**  */
	contactIds: Array<string> /**  */
	phone?: Maybe<string> /**  */
	fax?: Maybe<string> /**  */
	address?: Maybe<AddressInput> /**  */
	serviceOfferIds: Array<string> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	locationId?: Maybe<string> /**  */
	assetModelIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type TaskSequenceInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	fileIds: Array<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskIds: Array<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	activityId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type TaskStatusInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type TaskStatusWorkflowInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enable: boolean /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	config?: Maybe<StatusConfigInput> /**  */
}


/** Représente les méthodes disponible pour le type TaskStatusWorkflow */
export type FieldUpdateOperatorOfTaskStatusWorkflow = {
	set?: Maybe<SetOperatorInputOfTaskStatusWorkflow> /**  */
	push?: Maybe<PushUpdateOperatorInputTaskStatusWorkflow> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTaskStatusWorkflow> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TaskStatusWorkflow */
export type SetOperatorInputOfTaskStatusWorkflow = {
	defaultKey?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	enable?: Maybe<boolean> /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	config?: Maybe<SetOperatorInputOfStatusConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskStatusWorkflow */
export type PushUpdateOperatorInputTaskStatusWorkflow = {
	config?: Maybe<PushUpdateOperatorInputStatusConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité TaskStatusWorkflow */
export type PullUpdateOperatorInputTaskStatusWorkflow = {
	config?: Maybe<PullUpdateOperatorInputStatusConfig> /**  */
}


/**  */
export type TemplateInput = {
	dynamicProperties?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type TermsContractInput = {
	title?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	entityType?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TermsContract */
export type FieldUpdateOperatorOfTermsContract = {
	set?: Maybe<SetOperatorInputOfTermsContract> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TermsContract */
export type SetOperatorInputOfTermsContract = {
	entityType?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type TicketAffectedRuleInput = {
	dynamicProperties?: Maybe<string> /**  */
	categoryIds: Array<string> /**  */
	populationIds: Array<string> /**  */
	applyOption: ApplyOption /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	referentOperatorId?: Maybe<string> /**  */
	referentOperatorTeamId?: Maybe<string> /**  */
	affectedOperatorId?: Maybe<string> /**  */
	affectedOperatorTeamId?: Maybe<string> /**  */
	supportLevel: number /**  */
	qualification?: Array<Maybe<string>> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
}


/**  */
export type TicketAppointmentInput = {
	allDay: boolean /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	operatorId: string /**  */
	operatorTeamId: string /**  */
	ticketStatusId: string /**  */
	taskStatusId: string /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	ticketId: string /**  */
	disabled: boolean /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible: boolean /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	color?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TicketAppointment */
export type FieldUpdateOperatorOfTicketAppointment = {
	set?: Maybe<SetOperatorInputOfTicketAppointment> /**  */
	inc?: Maybe<IncOperatorInputTicketAppointment> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketAppointment */
export type SetOperatorInputOfTicketAppointment = {
	allDay?: Maybe<boolean> /**  */
	description?: Maybe<string> /**  */
	endDate?: Maybe<Date> /**  */
	endDateTimeZone?: Maybe<string> /**  */
	recurrenceException?: Maybe<string> /**  */
	recurrenceRule?: Maybe<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	ticketStatusId?: Maybe<string> /**  */
	taskStatusId?: Maybe<string> /**  */
	startDate?: Maybe<Date> /**  */
	startDateTimeZone?: Maybe<string> /**  */
	text?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	html?: Maybe<string> /**  */
	template?: Maybe<string> /**  */
	visible?: Maybe<boolean> /**  */
	offSet?: Maybe<number> /**  */
	localTimeZone?: Maybe<string> /**  */
	serverTimeZone?: Maybe<string> /**  */
	initialStartDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	color?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
	taskId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketAppointment */
export type IncOperatorInputTicketAppointment = {
	offSet?: Maybe<number> /**  */
}


/** Représente les méthodes disponible pour le type TicketBacklog */
export type FieldUpdateOperatorOfTicketBacklog = {
	set?: Maybe<SetOperatorInputOfTicketBacklog> /**  */
	inc?: Maybe<IncOperatorInputTicketBacklog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketBacklog */
export type SetOperatorInputOfTicketBacklog = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
	ticketBacklogType?: Maybe<TicketBacklogType> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketBacklog */
export type IncOperatorInputTicketBacklog = {
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
}


/**  */
export type TicketCategoryInput = {
	error: boolean /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	path?: Maybe<TranslatedFieldInput> /**  */
	parentNodes?: Array<Maybe<string>> /**  */
	parentIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	disabled: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	keywords?: Array<Maybe<string>> /**  */
	allowSelection: boolean /**  */
	fileId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TicketCategory */
export type FieldUpdateOperatorOfTicketCategory = {
	set?: Maybe<SetOperatorInputOfTicketCategory> /**  */
	inc?: Maybe<IncOperatorInputTicketCategory> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketCategory> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketCategory> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketCategory */
export type SetOperatorInputOfTicketCategory = {
	error?: Maybe<boolean> /**  */
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	parentId?: Maybe<string> /**  */
	disabled?: Maybe<boolean> /**  */
	qualification: Array<string> /**  */
	rankOrder?: Maybe<number> /**  */
	rankState?: Maybe<RankState> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	keywords: Array<string> /**  */
	allowSelection?: Maybe<boolean> /**  */
	fileId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	description?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	path?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketCategory */
export type IncOperatorInputTicketCategory = {
	rankOrder?: Maybe<number> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketCategory */
export type PushUpdateOperatorInputTicketCategory = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	qualification: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	keywords: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketCategory */
export type PullUpdateOperatorInputTicketCategory = {
	parentNodes: Array<string> /**  */
	parentIds: Array<string> /**  */
	qualification: Array<string> /**  */
	serviceLevelAgreementIds: Array<string> /**  */
	keywords: Array<string> /**  */
	notTrackingFields: Array<string> /**  */
}


/**  */
export type TicketDailyBacklogInput = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
}


/** Représente les méthodes disponible pour le type TicketDailyBacklog */
export type FieldUpdateOperatorOfTicketDailyBacklog = {
	set?: Maybe<SetOperatorInputOfTicketDailyBacklog> /**  */
	inc?: Maybe<IncOperatorInputTicketDailyBacklog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketDailyBacklog */
export type SetOperatorInputOfTicketDailyBacklog = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
	ticketBacklogType?: Maybe<TicketBacklogType> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketDailyBacklog */
export type IncOperatorInputTicketDailyBacklog = {
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
}


/**  */
export type TicketDurationTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	startDate?: Maybe<Date> /**  */
	endDate?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type TicketDurationTask */
export type FieldUpdateOperatorOfTicketDurationTask = {
	set?: Maybe<SetOperatorInputOfTicketDurationTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketDurationTask */
export type SetOperatorInputOfTicketDurationTask = {
	startDate?: Maybe<Date> /**  */
	endDate?: Maybe<Date> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type TicketEmailInput = {
	ticketId?: Maybe<string> /**  */
	ticketEmailAction: TicketEmailAction /**  */
	emailId?: Maybe<string> /**  */
	inboxId?: Maybe<string> /**  */
	emailConnectorId: string /**  */
	to?: Array<Maybe<string>> /**  */
	from?: Maybe<string> /**  */
	replyTo?: Array<Maybe<string>> /**  */
	cc?: Array<Maybe<string>> /**  */
	cci?: Array<Maybe<string>> /**  */
	bcc?: Array<Maybe<string>> /**  */
	attachments?: Array<Maybe<MailSlurpAttachmentMetaDataInput>> /**  */
	hasAttachment: boolean /**  */
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	isHtml: boolean /**  */
	createdAt?: Maybe<Date> /**  */
	errorMessage?: Maybe<string> /**  */
	hasError: boolean /**  */
	retryAt?: Maybe<Date> /**  */
	retryBy?: Maybe<string> /**  */
	retryComment?: Maybe<string> /**  */
	createdDate?: Maybe<Date> /**  */
	createdFromTicket: boolean /**  */
	fileIds: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TicketEmail */
export type FieldUpdateOperatorOfTicketEmail = {
	set?: Maybe<SetOperatorInputOfTicketEmail> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketEmail> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketEmail> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketEmail */
export type SetOperatorInputOfTicketEmail = {
	ticketId?: Maybe<string> /**  */
	ticketEmailAction?: Maybe<TicketEmailAction> /**  */
	emailId?: Maybe<string> /**  */
	inboxId?: Maybe<string> /**  */
	emailConnectorId?: Maybe<string> /**  */
	to: Array<string> /**  */
	from?: Maybe<string> /**  */
	replyTo: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	bcc: Array<string> /**  */
	attachments: Array<MailSlurpAttachmentMetaDataInput> /**  */
	hasAttachment?: Maybe<boolean> /**  */
	subject?: Maybe<string> /**  */
	body?: Maybe<string> /**  */
	isHtml?: Maybe<boolean> /**  */
	createdAt?: Maybe<Date> /**  */
	errorMessage?: Maybe<string> /**  */
	hasError?: Maybe<boolean> /**  */
	retryAt?: Maybe<Date> /**  */
	retryBy?: Maybe<string> /**  */
	retryComment?: Maybe<string> /**  */
	createdDate?: Maybe<Date> /**  */
	createdFromTicket?: Maybe<boolean> /**  */
	fileIds: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketEmail */
export type PushUpdateOperatorInputTicketEmail = {
	to: Array<string> /**  */
	replyTo: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	bcc: Array<string> /**  */
	attachments: Array<MailSlurpAttachmentMetaDataInput> /**  */
	fileIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketEmail */
export type PullUpdateOperatorInputTicketEmail = {
	to: Array<string> /**  */
	replyTo: Array<string> /**  */
	cc: Array<string> /**  */
	cci: Array<string> /**  */
	bcc: Array<string> /**  */
	attachments: Array<MailSlurpAttachmentMetaDataInput> /**  */
	fileIds: Array<string> /**  */
}


/**  */
export type TicketMonthlyBacklogInput = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
}


/** Représente les méthodes disponible pour le type TicketMonthlyBacklog */
export type FieldUpdateOperatorOfTicketMonthlyBacklog = {
	set?: Maybe<SetOperatorInputOfTicketMonthlyBacklog> /**  */
	inc?: Maybe<IncOperatorInputTicketMonthlyBacklog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketMonthlyBacklog */
export type SetOperatorInputOfTicketMonthlyBacklog = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
	ticketBacklogType?: Maybe<TicketBacklogType> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketMonthlyBacklog */
export type IncOperatorInputTicketMonthlyBacklog = {
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
}


/**  */
export type TicketStatisticInput = {
	dynamicProperties?: Maybe<string> /**  */
	ticketId: string /**  */
	elapsedSinceLastEvent: number /**  */
	ticketEventDate?: Maybe<Date> /**  */
	modifier?: Maybe<string> /**  */
}


/**  */
export type TicketStatusReasonInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	statusId: string /**  */
	ticketsIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type TicketStatusInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	ticketStatusReasonIds: Array<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
}


/**  */
export type TicketTagInput = {
	entityType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TicketTag */
export type FieldUpdateOperatorOfTicketTag = {
	set?: Maybe<SetOperatorInputOfTicketTag> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketTag */
export type SetOperatorInputOfTicketTag = {
	entityType?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type TicketTagTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	tags?: Array<Maybe<string>> /**  */
	oldName?: Maybe<string> /**  */
	newName?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type TicketTagTask */
export type FieldUpdateOperatorOfTicketTagTask = {
	set?: Maybe<SetOperatorInputOfTicketTagTask> /**  */
	push?: Maybe<PushUpdateOperatorInputTicketTagTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTicketTagTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketTagTask */
export type SetOperatorInputOfTicketTagTask = {
	tags: Array<string> /**  */
	oldName?: Maybe<string> /**  */
	newName?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTagTask */
export type PushUpdateOperatorInputTicketTagTask = {
	tags: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TicketTagTask */
export type PullUpdateOperatorInputTicketTagTask = {
	tags: Array<string> /**  */
}


/**  */
export type TicketTaskModelInput = {
	dynamicProperties?: Maybe<string> /**  */
	title?: Maybe<TranslatedFieldInput> /**  */
	createdBy?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	qualification?: Array<Maybe<string>> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	duration?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	activityId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type TicketTaskInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	title?: Maybe<TranslatedFieldInput> /**  */
	childrenIds: Array<string> /**  */
	createdBy?: Maybe<string> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	parentId?: Maybe<string> /**  */
	visibleForUser?: Maybe<boolean> /**  */
	interventionIds: Array<string> /**  */
	operatorId?: Maybe<string> /**  */
	operatorTeamId?: Maybe<string> /**  */
	scheduleAt?: Maybe<Date> /**  */
	endAt?: Maybe<Date> /**  */
	appointmentConnector?: Maybe<AppointmentConnectorInput> /**  */
	duration?: Maybe<string> /**  */
	durationMinutes: number /**  */
	taskModel?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	order: number /**  */
	statusId?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	taskStatusWorkflowId?: Maybe<string> /**  */
	incidentModelId?: Maybe<string> /**  */
	taskSequenceId?: Maybe<string> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	activityId?: Maybe<string> /**  */
	taskNumber: number /**  */
	isPredecessor: boolean /**  */
	predecessorId?: Maybe<string> /**  */
	predecessorStatusId?: Maybe<string> /**  */
	successorsIds: Array<string> /**  */
	isGreyList: boolean /**  */
	createdByTicketModel: boolean /**  */
}


/**  */
export type TicketWeeklyBacklogInput = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
}


/** Représente les méthodes disponible pour le type TicketWeeklyBacklog */
export type FieldUpdateOperatorOfTicketWeeklyBacklog = {
	set?: Maybe<SetOperatorInputOfTicketWeeklyBacklog> /**  */
	inc?: Maybe<IncOperatorInputTicketWeeklyBacklog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketWeeklyBacklog */
export type SetOperatorInputOfTicketWeeklyBacklog = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
	ticketBacklogType?: Maybe<TicketBacklogType> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketWeeklyBacklog */
export type IncOperatorInputTicketWeeklyBacklog = {
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
}


/**  */
export type TicketYearlyBacklogInput = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total: number /**  */
	ticketBacklogType: TicketBacklogType /**  */
}


/** Représente les méthodes disponible pour le type TicketYearlyBacklog */
export type FieldUpdateOperatorOfTicketYearlyBacklog = {
	set?: Maybe<SetOperatorInputOfTicketYearlyBacklog> /**  */
	inc?: Maybe<IncOperatorInputTicketYearlyBacklog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TicketYearlyBacklog */
export type SetOperatorInputOfTicketYearlyBacklog = {
	date?: Maybe<Date> /**  */
	createDate?: Maybe<Date> /**  */
	qualification?: Maybe<string> /**  */
	operatorTeamAffectedId?: Maybe<string> /**  */
	operatorAffectedId?: Maybe<string> /**  */
	statusId?: Maybe<string> /**  */
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
	ticketBacklogType?: Maybe<TicketBacklogType> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type numérique de l'entité TicketYearlyBacklog */
export type IncOperatorInputTicketYearlyBacklog = {
	levelSupport?: Maybe<number> /**  */
	total?: Maybe<number> /**  */
}


/**  */
export type TrackingEntityInput = {
	name?: Maybe<string> /**  */
	ids: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	isTranslatable: boolean /**  */
}


/** Représente les méthodes disponible pour le type TrackingEntity */
export type FieldUpdateOperatorOfTrackingEntity = {
	set?: Maybe<SetOperatorInputOfTrackingEntity> /**  */
	push?: Maybe<PushUpdateOperatorInputTrackingEntity> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTrackingEntity> /**  */
}


/** Représente les propriétés de mise à jour de l'entité TrackingEntity */
export type SetOperatorInputOfTrackingEntity = {
	name?: Maybe<string> /**  */
	ids: Array<string> /**  */
	entityType?: Maybe<string> /**  */
	isTranslatable?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TrackingEntity */
export type PushUpdateOperatorInputTrackingEntity = {
	ids: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité TrackingEntity */
export type PullUpdateOperatorInputTrackingEntity = {
	ids: Array<string> /**  */
}


/**  */
export type TrackingInput = {
	inventoryHistoryId?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	entityId?: Maybe<string> /**  */
	oldValue?: Maybe<any> /**  */
	newValue?: Maybe<any> /**  */
	value?: Maybe<any> /**  */
	entityState: EntityState /**  */
	entityPropertyName?: Maybe<string> /**  */
	entityName?: Maybe<string> /**  */
	entitySourceId: string /**  */
	entitySourceName?: Maybe<string> /**  */
	entitySource?: Maybe<any> /**  */
	system: boolean /**  */
	authorId?: Maybe<string> /**  */
	propertyKeyValue?: Maybe<string> /**  */
	changes?: Array<Maybe<TrackingChangeInput>> /**  */
	values?: Array<Maybe<TrackingValueInput>> /**  */
}


/**  */
export type TrackingChangeInput = {
	name?: Maybe<string> /**  */
	oldValue?: Maybe<any> /**  */
	oldTrackingEntity?: Maybe<TrackingEntityInput> /**  */
	newValue?: Maybe<any> /**  */
	newTrackingEntity?: Maybe<TrackingEntityInput> /**  */
	isTranslatable: boolean /**  */
}


/**  */
export type TrackingValueInput = {
	id?: string /**  */
	name?: Maybe<string> /**  */
	type?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type Tracking */
export type FieldUpdateOperatorOfTracking = {
	set?: Maybe<SetOperatorInputOfTracking> /**  */
	push?: Maybe<PushUpdateOperatorInputTracking> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputTracking> /**  */
}


/** Représente les propriétés de mise à jour de l'entité Tracking */
export type SetOperatorInputOfTracking = {
	inventoryHistoryId?: Maybe<string> /**  */
	date?: Maybe<Date> /**  */
	entityId?: Maybe<string> /**  */
	entityState?: Maybe<EntityState> /**  */
	entityPropertyName?: Maybe<string> /**  */
	entityName?: Maybe<string> /**  */
	entitySourceId?: Maybe<string> /**  */
	entitySourceName?: Maybe<string> /**  */
	system?: Maybe<boolean> /**  */
	authorId?: Maybe<string> /**  */
	propertyKeyValue?: Maybe<string> /**  */
	changes: Array<TrackingChangeInput> /**  */
	values: Array<TrackingValueInput> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité Tracking */
export type PushUpdateOperatorInputTracking = {
	changes: Array<TrackingChangeInput> /**  */
	values: Array<TrackingValueInput> /**  */
}


/** Représente les propriétés de type collection de l'entité Tracking */
export type PullUpdateOperatorInputTracking = {
	changes: Array<TrackingChangeInput> /**  */
	values: Array<TrackingValueInput> /**  */
}


/**  */
export type UrgencyInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	qualification?: Array<Maybe<string>> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
}


/**  */
export type UserAffectedRuleInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	searchInDeleted: boolean /**  */
	searchInArchived: boolean /**  */
	rankOrder: number /**  */
	rankState: RankState /**  */
	canDrag: boolean /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	byDistinguishedName: boolean /**  */
	bySid: boolean /**  */
	byEmail: boolean /**  */
	byDisplayName: boolean /**  */
	byUserPrincipalName: boolean /**  */
	byLastNameAndFirstName: boolean /**  */
	byDomain: boolean /**  */
	byDifferentDomain: boolean /**  */
	byAzureObjectId: boolean /**  */
	byDomainEnum: ByDomainEnum /**  */
}


/** Représente les méthodes disponible pour le type UserAffectedRule */
export type FieldUpdateOperatorOfUserAffectedRule = {
	set?: Maybe<SetOperatorInputOfUserAffectedRule> /**  */
	inc?: Maybe<IncOperatorInputUserAffectedRule> /**  */
	push?: Maybe<PushUpdateOperatorInputUserAffectedRule> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputUserAffectedRule> /**  */
}


/**  */
export type UserPrivilegeInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	isDefault: boolean /**  */
	defaultName?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	userIds: Array<string> /**  */
	affectedUserEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	otherAffectedUserEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	responsableUserEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
	accessTicketsObserverEntries?: Array<Maybe<PrivilegeEntryInput>> /**  */
}


/**  */
export type UserRoleInput = {
	dynamicProperties?: Maybe<string> /**  */
	userId: string /**  */
	roleId: string /**  */
}


/**  */
export type UserInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	memberOfIds: Array<string> /**  */
	permissionScopes?: Array<Maybe<PermissionScopeInput>> /**  */
	subId?: Maybe<string> /**  */
	firstName?: Maybe<string> /**  */
	lastName?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	userAccountControl: number /**  */
	userAccountDisabled: boolean /**  */
	userAccountExpires?: Maybe<Date> /**  */
	initial?: Maybe<string> /**  */
	registrationNumber?: Maybe<string> /**  */
	vip: boolean /**  */
	helpDeskActivated: boolean /**  */
	function?: Maybe<string> /**  */
	instantMessaging?: Maybe<string> /**  */
	userPrincipalName?: Maybe<string> /**  */
	title?: Maybe<string> /**  */
	secretary?: Maybe<string> /**  */
	hiringDate?: Maybe<Date> /**  */
	exitDate?: Maybe<Date> /**  */
	lastLogOn?: Maybe<Date> /**  */
	lastLogonTimestamp?: Maybe<Date> /**  */
	azureLastLogOnInteractive?: Maybe<Date> /**  */
	azureLastLogOnNotInteractive?: Maybe<Date> /**  */
	lastLogOff?: Maybe<Date> /**  */
	lastConnected?: Maybe<Date> /**  */
	accessFailedCount: number /**  */
	homeDrive?: Maybe<string> /**  */
	homeDirectory?: Maybe<string> /**  */
	logonCount: number /**  */
	profilePath?: Maybe<string> /**  */
	scriptPath?: Maybe<string> /**  */
	logOnTo?: Maybe<string> /**  */
	allowDialIn: boolean /**  */
	officeName?: Maybe<string> /**  */
	principalWebSite?: Maybe<string> /**  */
	settings?: Maybe<SettingsInput> /**  */
	organizationalUnitId?: Maybe<string> /**  */
	userPasswords?: Array<Maybe<UserPasswordInput>> /**  */
	operatorTeamIds: Array<string> /**  */
	mainOperatorTeamId?: Maybe<string> /**  */
	isOperator: boolean /**  */
	loans?: Array<Maybe<LoanInput>> /**  */
	ticketsIds: Array<string> /**  */
	userHabilitations?: Array<Maybe<UserHabilitationInput>> /**  */
	phones?: Array<Maybe<PhoneInput>> /**  */
	address?: Maybe<AddressInput> /**  */
	webSites?: Array<Maybe<string>> /**  */
	locations?: Array<Maybe<LocationInput>> /**  */
	stockManagers?: Array<Maybe<LocationInput>> /**  */
	locationId?: Maybe<string> /**  */
	objectSid?: Maybe<string> /**  */
	department?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
	manager?: Maybe<string> /**  */
	managerId?: Maybe<string> /**  */
	supplierId?: Maybe<string> /**  */
	distinguishedName?: Maybe<string> /**  */
	parentDistinguishedName?: Maybe<string> /**  */
	containerDistinguishedName?: Maybe<string> /**  */
	userName?: Maybe<string> /**  */
	whenCreated?: Maybe<Date> /**  */
	whenChanged?: Maybe<Date> /**  */
	firstImportLdap?: Maybe<Date> /**  */
	lastImportLdap?: Maybe<Date> /**  */
	lastImportAzureAd?: Maybe<Date> /**  */
	scanByLdapId?: Maybe<string> /**  */
	principalPhone?: Maybe<string> /**  */
	principalFax?: Maybe<string> /**  */
	principalHomePhone?: Maybe<string> /**  */
	principalIpPhone?: Maybe<string> /**  */
	principalMobile?: Maybe<string> /**  */
	principalPager?: Maybe<string> /**  */
	memberOf?: Array<Maybe<string>> /**  */
	memberOfAzureGroup?: Array<Maybe<GroupAzureAdInput>> /**  */
	assetIds: Array<string> /**  */
	invitation?: Maybe<InvitationInput> /**  */
	category: UserCategory /**  */
	userAffectedRule?: Maybe<UserAffectedRuleInput> /**  */
	populationsIds: Array<string> /**  */
	contractIds: Array<string> /**  */
	forceLdapImport: boolean /**  */
	domain?: Maybe<string> /**  */
	fileIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	memberOfSecurityGroupIds?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	memberOfPopulationIds?: Array<Maybe<SecurityGroupObjectInput>> /**  */
	softwarePackageIds: Array<string> /**  */
	locationCharterIds: Array<string> /**  */
	inviteById?: Maybe<string> /**  */
	revokeById?: Maybe<string> /**  */
	costHours: number /**  */
	supportLevelOperator: number /**  */
	azureObjectId?: Maybe<string> /**  */
	managerAzureObjectId?: Maybe<string> /**  */
	mainAssetId?: Maybe<string> /**  */
	inheritLocationCharterIds: Array<string> /**  */
	inheritLocationCharterLocation?: Maybe<InheritLocationCharterInput> /**  */
	inheritLocationCharterOrganizationalUnit?: Maybe<InheritLocationCharterInput> /**  */
	entityLocationCharter?: Maybe<EntityLocationCharterInput> /**  */
	privilegeId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	locationCharterAlreadyApply: boolean /**  */
	securityGroupAlreadyApply: boolean /**  */
	populationAlreadyApply: boolean /**  */
	calendarId?: Maybe<string> /**  */
	modelRulesDesignerIds: Array<string> /**  */
	formDesignerIds: Array<string> /**  */
	operatorIncidentRuleId?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	operatorRequestRuleId?: Maybe<string> /**  */
	operatorProblemRuleId?: Maybe<string> /**  */
	serviceDeskActivated: boolean /**  */
	dashboardIds: Array<string> /**  */
	allowEditOrganization: boolean /**  */
	recipentSystemNotification: boolean /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	userContractType?: Maybe<string> /**  */
	operatorIncidentListDesignerId?: Maybe<string> /**  */
	operatorRequestListDesignerId?: Maybe<string> /**  */
	operatorProblemListDesignerId?: Maybe<string> /**  */
}


/**  */
export type SettingsInput = {
	features?: Array<Maybe<FeatureInput>> /**  */
	preferences?: Maybe<PreferencesInput> /**  */
}


/**  */
export type PreferencesInput = {
	lang?: Maybe<string> /**  */
	theme?: Maybe<string> /**  */
	dashboardId?: Maybe<string> /**  */
	paginate: number /**  */
	layouts?: Array<Maybe<LayoutInput>> /**  */
	defaultLayouts?: Array<Maybe<LayoutInput>> /**  */
	pinStateMenu: boolean /**  */
}


/**  */
export type InvitationInput = {
	sendDate?: Maybe<Date> /**  */
	acceptDate?: Maybe<Date> /**  */
	revokeDate?: Maybe<Date> /**  */
	token?: Maybe<string> /**  */
	accessToken?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
}


/**  */
export type InvitationSettingInput = {
	userIds: Array<string> /**  */
	roleIds: Array<string> /**  */
	mainOperatorTeamId?: Maybe<string> /**  */
	emailModelType?: Maybe<string> /**  */
	operatorIncidentRuleId?: Maybe<string> /**  */
	operatorRequestRuleId?: Maybe<string> /**  */
	operatorProblemRuleId?: Maybe<string> /**  */
	operatorIncidentListDesignerId?: Maybe<string> /**  */
	operatorRequestListDesignerId?: Maybe<string> /**  */
	operatorProblemListDesignerId?: Maybe<string> /**  */
	privilegeId?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	businessTimeId?: Maybe<string> /**  */
	supportLevelOperator?: Maybe<number> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
}


/**  */
export type VerifaliaEmailCheckInput = {
	dynamicProperties?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	expireUtc?: Maybe<Date> /**  */
	classification?: Maybe<ValidationEntryClassificationInput> /**  */
	status: number /**  */
}


/**  */
export type ValidationEntryClassificationInput = {
	name?: Maybe<string> /**  */
}


/**  */
export type VerifaliaTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	jobId: string /**  */
	emailsToCheck?: Array<Maybe<string>> /**  */
}


/** Représente les méthodes disponible pour le type VerifaliaTask */
export type FieldUpdateOperatorOfVerifaliaTask = {
	set?: Maybe<SetOperatorInputOfVerifaliaTask> /**  */
	push?: Maybe<PushUpdateOperatorInputVerifaliaTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputVerifaliaTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité VerifaliaTask */
export type SetOperatorInputOfVerifaliaTask = {
	jobId?: Maybe<string> /**  */
	emailsToCheck: Array<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité VerifaliaTask */
export type PushUpdateOperatorInputVerifaliaTask = {
	emailsToCheck: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité VerifaliaTask */
export type PullUpdateOperatorInputVerifaliaTask = {
	emailsToCheck: Array<string> /**  */
}


/**  */
export type WallSocketInput = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	brokenDown: boolean /**  */
	locationId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WallSocket */
export type FieldUpdateOperatorOfWallSocket = {
	set?: Maybe<SetOperatorInputOfWallSocket> /**  */
	push?: Maybe<PushUpdateOperatorInputWallSocket> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWallSocket> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WallSocket */
export type SetOperatorInputOfWallSocket = {
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	brokenDown?: Maybe<boolean> /**  */
	locationId?: Maybe<string> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WallSocket */
export type PushUpdateOperatorInputWallSocket = {
	notTrackingFields: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WallSocket */
export type PullUpdateOperatorInputWallSocket = {
	notTrackingFields: Array<string> /**  */
}


/**  */
export type WarningCustomerInput = {
	created?: Maybe<EntityAttributeInput> /**  */
	updated?: Maybe<EntityAttributeInput> /**  */
	title?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	availableForHelpMe: boolean /**  */
}


/**  */
export type EntityAttributeInput = {
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	username?: Maybe<string> /**  */
	state: boolean /**  */
}


/** Représente les méthodes disponible pour le type WarningCustomer */
export type FieldUpdateOperatorOfWarningCustomer = {
	set?: Maybe<SetOperatorInputOfWarningCustomer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WarningCustomer */
export type SetOperatorInputOfWarningCustomer = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable?: Maybe<boolean> /**  */
	availableForHelpMe?: Maybe<boolean> /**  */
	externalObjectId?: Maybe<string> /**  */
	created?: Maybe<SetOperatorInputOfEntityAttribute> /**  */
	updated?: Maybe<SetOperatorInputOfEntityAttribute> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de mise à jour de l'entité EntityAttribute */
export type SetOperatorInputOfEntityAttribute = {
	date?: Maybe<Date> /**  */
	userId?: Maybe<string> /**  */
	username?: Maybe<string> /**  */
	state?: Maybe<boolean> /**  */
}


/**  */
export type WarningMessageInput = {
	title?: Maybe<TranslatedFieldInput> /**  */
	content?: Maybe<TranslatedFieldInput> /**  */
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable: boolean /**  */
	populationIds: Array<string> /**  */
	securityGroup?: Maybe<SecurityGroupObjectInput> /**  */
	warningMessageType: WarningMessageCategory /**  */
	warningMessageOperatorUsersIds: Array<string> /**  */
}


/** Représente les méthodes disponible pour le type WarningMessage */
export type FieldUpdateOperatorOfWarningMessage = {
	set?: Maybe<SetOperatorInputOfWarningMessage> /**  */
	push?: Maybe<PushUpdateOperatorInputWarningMessage> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWarningMessage> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WarningMessage */
export type SetOperatorInputOfWarningMessage = {
	start?: Maybe<Date> /**  */
	end?: Maybe<Date> /**  */
	enable?: Maybe<boolean> /**  */
	populationIds: Array<string> /**  */
	warningMessageType?: Maybe<WarningMessageCategory> /**  */
	warningMessageOperatorUsersIds: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	title?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	content?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/** Représente les propriétés de type collection de l'entité WarningMessage */
export type PushUpdateOperatorInputWarningMessage = {
	populationIds: Array<string> /**  */
	warningMessageOperatorUsersIds: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WarningMessage */
export type PullUpdateOperatorInputWarningMessage = {
	populationIds: Array<string> /**  */
	warningMessageOperatorUsersIds: Array<string> /**  */
}


/**  */
export type WarningViewCustomerInput = {
	warningCustomerId: string /**  */
	userId: string /**  */
	lastDisplay?: Maybe<Date> /**  */
}


/** Représente les méthodes disponible pour le type WarningViewCustomer */
export type FieldUpdateOperatorOfWarningViewCustomer = {
	set?: Maybe<SetOperatorInputOfWarningViewCustomer> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WarningViewCustomer */
export type SetOperatorInputOfWarningViewCustomer = {
	warningCustomerId?: Maybe<string> /**  */
	userId?: Maybe<string> /**  */
	lastDisplay?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type WarrantyLinkVendorInput = {
	vendor?: Maybe<string> /**  */
	manufacturers?: Array<Maybe<string>> /**  */
	displayLink?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WarrantyLinkVendor */
export type FieldUpdateOperatorOfWarrantyLinkVendor = {
	set?: Maybe<SetOperatorInputOfWarrantyLinkVendor> /**  */
	push?: Maybe<PushUpdateOperatorInputWarrantyLinkVendor> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWarrantyLinkVendor> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WarrantyLinkVendor */
export type SetOperatorInputOfWarrantyLinkVendor = {
	vendor?: Maybe<string> /**  */
	manufacturers: Array<string> /**  */
	displayLink?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WarrantyLinkVendor */
export type PushUpdateOperatorInputWarrantyLinkVendor = {
	manufacturers: Array<string> /**  */
}


/** Représente les propriétés de type collection de l'entité WarrantyLinkVendor */
export type PullUpdateOperatorInputWarrantyLinkVendor = {
	manufacturers: Array<string> /**  */
}


/**  */
export type WmiCredentialInput = {
	password?: Maybe<string> /**  */
	verifyPassword?: Maybe<string> /**  */
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	description?: Maybe<string> /**  */
	logOn?: Maybe<string> /**  */
	scanConfigurationIds: Array<string> /**  */
	domain?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowAction */
export type FieldUpdateOperatorOfWorkflowAction = {
	set?: Maybe<SetOperatorInputOfWorkflowAction> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowAction */
export type SetOperatorInputOfWorkflowAction = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OptionSave */
export type SetOperatorInputOfOptionSave = {
	insert?: Maybe<boolean> /**  */
	update?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité OptionDelete */
export type SetOperatorInputOfOptionDelete = {
	delete?: Maybe<boolean> /**  */
	trash?: Maybe<boolean> /**  */
}


/**  */
export type WorkflowDeleteInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
}


/**  */
export type OptionSaveInput = {
	insert: boolean /**  */
	update: boolean /**  */
}


/**  */
export type OptionDeleteInput = {
	delete: boolean /**  */
	trash: boolean /**  */
}


/** Représente les méthodes disponible pour le type WorkflowDelete */
export type FieldUpdateOperatorOfWorkflowDelete = {
	set?: Maybe<SetOperatorInputOfWorkflowDelete> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowDelete */
export type SetOperatorInputOfWorkflowDelete = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/**  */
export type WorkflowHttpRequestInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	httpOutputConfig?: Maybe<HttpOutputInput> /**  */
}


/**  */
export type HttpOutputInput = {
	method: ApiHttpMethod /**  */
	body?: Maybe<string> /**  */
	headers?: Array<Maybe<HttpRequestParamsInput>> /**  */
	url?: Maybe<string> /**  */
	queryParams?: Array<Maybe<HttpRequestParamsInput>> /**  */
	attachmentSetting: HttpOutputSendAttachment /**  */
	attachmentArrayProperty?: Maybe<string> /**  */
	attachmentFileName?: Maybe<string> /**  */
	attachmentSize?: Maybe<string> /**  */
	attachmentType?: Maybe<string> /**  */
	attachmentFileValue?: Maybe<string> /**  */
	attachmentLinkedFileIds?: Maybe<string> /**  */
	linkedObjectIdPath?: Maybe<string> /**  */
	bodyParams?: Array<Maybe<HttpRequestParamsInput>> /**  */
}


/**  */
export type HttpRequestParamsInput = {
	key?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	enable: boolean /**  */
}


/** Représente les méthodes disponible pour le type WorkflowHttpRequest */
export type FieldUpdateOperatorOfWorkflowHttpRequest = {
	set?: Maybe<SetOperatorInputOfWorkflowHttpRequest> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowHttpRequest> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowHttpRequest> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowHttpRequest */
export type SetOperatorInputOfWorkflowHttpRequest = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	httpOutputConfig?: Maybe<SetOperatorInputOfHttpOutput> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de mise à jour de l'entité HttpOutput */
export type SetOperatorInputOfHttpOutput = {
	method?: Maybe<ApiHttpMethod> /**  */
	body?: Maybe<string> /**  */
	headers: Array<HttpRequestParamsInput> /**  */
	url?: Maybe<string> /**  */
	queryParams: Array<HttpRequestParamsInput> /**  */
	attachmentSetting?: Maybe<HttpOutputSendAttachment> /**  */
	attachmentArrayProperty?: Maybe<string> /**  */
	attachmentFileName?: Maybe<string> /**  */
	attachmentSize?: Maybe<string> /**  */
	attachmentType?: Maybe<string> /**  */
	attachmentFileValue?: Maybe<string> /**  */
	attachmentLinkedFileIds?: Maybe<string> /**  */
	linkedObjectIdPath?: Maybe<string> /**  */
	bodyParams: Array<HttpRequestParamsInput> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowHttpRequest */
export type PushUpdateOperatorInputWorkflowHttpRequest = {
	httpOutputConfig?: Maybe<PushUpdateOperatorInputHttpOutput> /**  */
}


/** Représente les propriétés de type collection de l'entité HttpOutput */
export type PushUpdateOperatorInputHttpOutput = {
	headers: Array<HttpRequestParamsInput> /**  */
	queryParams: Array<HttpRequestParamsInput> /**  */
	bodyParams: Array<HttpRequestParamsInput> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowHttpRequest */
export type PullUpdateOperatorInputWorkflowHttpRequest = {
	httpOutputConfig?: Maybe<PullUpdateOperatorInputHttpOutput> /**  */
}


/** Représente les propriétés de type collection de l'entité HttpOutput */
export type PullUpdateOperatorInputHttpOutput = {
	headers: Array<HttpRequestParamsInput> /**  */
	queryParams: Array<HttpRequestParamsInput> /**  */
	bodyParams: Array<HttpRequestParamsInput> /**  */
}


/**  */
export type WorkflowIntervalTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowId: string /**  */
}


/** Représente les méthodes disponible pour le type WorkflowIntervalTask */
export type FieldUpdateOperatorOfWorkflowIntervalTask = {
	set?: Maybe<SetOperatorInputOfWorkflowIntervalTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowIntervalTask */
export type SetOperatorInputOfWorkflowIntervalTask = {
	workflowId?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type WorkflowInvitationInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	roleIds: Array<string> /**  */
	modelType?: Maybe<string> /**  */
	user?: Maybe<UserInput> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowInvitation */
export type FieldUpdateOperatorOfWorkflowInvitation = {
	set?: Maybe<SetOperatorInputOfWorkflowInvitation> /**  */
	inc?: Maybe<IncOperatorInputWorkflowInvitation> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowInvitation> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowInvitation> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowInvitation */
export type SetOperatorInputOfWorkflowInvitation = {
	roleIds: Array<string> /**  */
	modelType?: Maybe<string> /**  */
	userIncidentRuleId?: Maybe<string> /**  */
	userRequestRuleId?: Maybe<string> /**  */
	userPrivilegeId?: Maybe<string> /**  */
	businessTimeId?: Maybe<string> /**  */
	userRequestListDesignerId?: Maybe<string> /**  */
	userIncidentListDesignerId?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	user?: Maybe<SetOperatorInputOfUser> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de type numérique de l'entité WorkflowInvitation */
export type IncOperatorInputWorkflowInvitation = {
	user?: Maybe<IncOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowInvitation */
export type PushUpdateOperatorInputWorkflowInvitation = {
	roleIds: Array<string> /**  */
	user?: Maybe<PushUpdateOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowInvitation */
export type PullUpdateOperatorInputWorkflowInvitation = {
	roleIds: Array<string> /**  */
	user?: Maybe<PullUpdateOperatorInputUser> /**  */
}


/**  */
export type WorkflowInvitationTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowInvitation?: Maybe<WorkflowInvitationInput> /**  */
	authorId?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowInvitationTask */
export type FieldUpdateOperatorOfWorkflowInvitationTask = {
	set?: Maybe<SetOperatorInputOfWorkflowInvitationTask> /**  */
	inc?: Maybe<IncOperatorInputWorkflowInvitationTask> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowInvitationTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowInvitationTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowInvitationTask */
export type SetOperatorInputOfWorkflowInvitationTask = {
	authorId?: Maybe<string> /**  */
	origin?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	workflowInvitation?: Maybe<SetOperatorInputOfWorkflowInvitation> /**  */
}


/** Représente les propriétés de type numérique de l'entité WorkflowInvitationTask */
export type IncOperatorInputWorkflowInvitationTask = {
	workflowInvitation?: Maybe<IncOperatorInputWorkflowInvitation> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowInvitationTask */
export type PushUpdateOperatorInputWorkflowInvitationTask = {
	workflowInvitation?: Maybe<PushUpdateOperatorInputWorkflowInvitation> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowInvitationTask */
export type PullUpdateOperatorInputWorkflowInvitationTask = {
	workflowInvitation?: Maybe<PullUpdateOperatorInputWorkflowInvitation> /**  */
}


/**  */
export type WorkflowLogInput = {
	created?: Maybe<Date> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	type?: Maybe<string> /**  */
	propertyName?: Maybe<string> /**  */
	state: boolean /**  */
	error?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	workflowType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	entityDisplay?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	ticketType?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowLog */
export type FieldUpdateOperatorOfWorkflowLog = {
	set?: Maybe<SetOperatorInputOfWorkflowLog> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowLog */
export type SetOperatorInputOfWorkflowLog = {
	created?: Maybe<Date> /**  */
	type?: Maybe<string> /**  */
	propertyName?: Maybe<string> /**  */
	state?: Maybe<boolean> /**  */
	error?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	workflowType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	entityType?: Maybe<string> /**  */
	entityDisplay?: Maybe<string> /**  */
	ticketId?: Maybe<string> /**  */
	ticketType?: Maybe<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
}


/**  */
export type WorkflowMailInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	useProprotype: boolean /**  */
	sendInvitation: boolean /**  */
	emailPrototypeId?: Maybe<string> /**  */
	emailPrototype?: Maybe<EmailPrototypeInput> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowMail */
export type FieldUpdateOperatorOfWorkflowMail = {
	set?: Maybe<SetOperatorInputOfWorkflowMail> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowMail> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowMail> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowMail */
export type SetOperatorInputOfWorkflowMail = {
	useProprotype?: Maybe<boolean> /**  */
	sendInvitation?: Maybe<boolean> /**  */
	emailPrototypeId?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	emailPrototype?: Maybe<SetOperatorInputOfEmailPrototype> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowMail */
export type PushUpdateOperatorInputWorkflowMail = {
	emailPrototype?: Maybe<PushUpdateOperatorInputEmailPrototype> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowMail */
export type PullUpdateOperatorInputWorkflowMail = {
	emailPrototype?: Maybe<PullUpdateOperatorInputEmailPrototype> /**  */
}


/**  */
export type WorkflowMailTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowMail?: Maybe<WorkflowMailInput> /**  */
	entity?: Maybe<any> /**  */
	callerId?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowMailTask */
export type FieldUpdateOperatorOfWorkflowMailTask = {
	set?: Maybe<SetOperatorInputOfWorkflowMailTask> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowMailTask> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowMailTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowMailTask */
export type SetOperatorInputOfWorkflowMailTask = {
	callerId?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
	workflowMail?: Maybe<SetOperatorInputOfWorkflowMail> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowMailTask */
export type PushUpdateOperatorInputWorkflowMailTask = {
	workflowMail?: Maybe<PushUpdateOperatorInputWorkflowMail> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowMailTask */
export type PullUpdateOperatorInputWorkflowMailTask = {
	workflowMail?: Maybe<PullUpdateOperatorInputWorkflowMail> /**  */
}


/**  */
export type WorkflowManagedInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	managedKey?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowManaged */
export type FieldUpdateOperatorOfWorkflowManaged = {
	set?: Maybe<SetOperatorInputOfWorkflowManaged> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowManaged */
export type SetOperatorInputOfWorkflowManaged = {
	managedKey?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/**  */
export type WorkflowNotificationTeamsInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	recipient?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowNotificationTeams */
export type FieldUpdateOperatorOfWorkflowNotificationTeams = {
	set?: Maybe<SetOperatorInputOfWorkflowNotificationTeams> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowNotificationTeams */
export type SetOperatorInputOfWorkflowNotificationTeams = {
	recipient?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/**  */
export type WorkflowPropertyInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	workflowPropertyValue?: Maybe<WorkflowPropertyValueInput> /**  */
	workflowPropertyState?: Maybe<WorkflowPropertyStateInput> /**  */
	propertyName?: Maybe<string> /**  */
}


/**  */
export type WorkflowPropertyValueInput = {
	editType?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	changeValue: boolean /**  */
	replaceOnlyEmpty: boolean /**  */
}


/**  */
export type WorkflowPropertyStateInput = {
	changeState: boolean /**  */
	isReadOnly: boolean /**  */
	isRequired: boolean /**  */
	isVisible: boolean /**  */
}


/** Représente les méthodes disponible pour le type WorkflowProperty */
export type FieldUpdateOperatorOfWorkflowProperty = {
	set?: Maybe<SetOperatorInputOfWorkflowProperty> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowProperty */
export type SetOperatorInputOfWorkflowProperty = {
	propertyName?: Maybe<string> /**  */
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	workflowPropertyValue?: Maybe<SetOperatorInputOfWorkflowPropertyValue> /**  */
	workflowPropertyState?: Maybe<SetOperatorInputOfWorkflowPropertyState> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowPropertyValue */
export type SetOperatorInputOfWorkflowPropertyValue = {
	editType?: Maybe<string> /**  */
	value?: Maybe<string> /**  */
	changeValue?: Maybe<boolean> /**  */
	replaceOnlyEmpty?: Maybe<boolean> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowPropertyState */
export type SetOperatorInputOfWorkflowPropertyState = {
	changeState?: Maybe<boolean> /**  */
	isReadOnly?: Maybe<boolean> /**  */
	isRequired?: Maybe<boolean> /**  */
	isVisible?: Maybe<boolean> /**  */
}


/**  */
export type WorkflowRevocationInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	user?: Maybe<UserInput> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowRevocation */
export type FieldUpdateOperatorOfWorkflowRevocation = {
	set?: Maybe<SetOperatorInputOfWorkflowRevocation> /**  */
	inc?: Maybe<IncOperatorInputWorkflowRevocation> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowRevocation> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowRevocation> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowRevocation */
export type SetOperatorInputOfWorkflowRevocation = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	user?: Maybe<SetOperatorInputOfUser> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de type numérique de l'entité WorkflowRevocation */
export type IncOperatorInputWorkflowRevocation = {
	user?: Maybe<IncOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowRevocation */
export type PushUpdateOperatorInputWorkflowRevocation = {
	user?: Maybe<PushUpdateOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowRevocation */
export type PullUpdateOperatorInputWorkflowRevocation = {
	user?: Maybe<PullUpdateOperatorInputUser> /**  */
}


/**  */
export type WorkflowRevocationWithoutEmailInput = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger: WorkflowTrigger /**  */
	optionSave?: Maybe<OptionSaveInput> /**  */
	optionDelete?: Maybe<OptionDeleteInput> /**  */
	user?: Maybe<UserInput> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowRevocationWithoutEmail */
export type FieldUpdateOperatorOfWorkflowRevocationWithoutEmail = {
	set?: Maybe<SetOperatorInputOfWorkflowRevocationWithoutEmail> /**  */
	inc?: Maybe<IncOperatorInputWorkflowRevocationWithoutEmail> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkflowRevocationWithoutEmail> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkflowRevocationWithoutEmail> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowRevocationWithoutEmail */
export type SetOperatorInputOfWorkflowRevocationWithoutEmail = {
	workflowId?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	workflowTrigger?: Maybe<WorkflowTrigger> /**  */
	externalObjectId?: Maybe<string> /**  */
	user?: Maybe<SetOperatorInputOfUser> /**  */
	optionSave?: Maybe<SetOperatorInputOfOptionSave> /**  */
	optionDelete?: Maybe<SetOperatorInputOfOptionDelete> /**  */
}


/** Représente les propriétés de type numérique de l'entité WorkflowRevocationWithoutEmail */
export type IncOperatorInputWorkflowRevocationWithoutEmail = {
	user?: Maybe<IncOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowRevocationWithoutEmail */
export type PushUpdateOperatorInputWorkflowRevocationWithoutEmail = {
	user?: Maybe<PushUpdateOperatorInputUser> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkflowRevocationWithoutEmail */
export type PullUpdateOperatorInputWorkflowRevocationWithoutEmail = {
	user?: Maybe<PullUpdateOperatorInputUser> /**  */
}


/**  */
export type WorkflowInput = {
	dynamicProperties?: Maybe<string> /**  */
	name?: Maybe<TranslatedFieldInput> /**  */
	key?: Maybe<string> /**  */
	defaultName?: Maybe<string> /**  */
	expression?: Maybe<string> /**  */
	enabled: boolean /**  */
	description?: Maybe<TranslatedFieldInput> /**  */
	entityType?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
	delayConfig?: Maybe<WorkflowDelayInput> /**  */
	intervalConfig?: Maybe<WorkflowDelayInput> /**  */
	enableDelay: boolean /**  */
	enableInterval: boolean /**  */
	priority: number /**  */
	lastUpdateById?: Maybe<string> /**  */
	lastUpdated?: Maybe<Date> /**  */
	managedChooseForApply: UserOrAsset /**  */
	copyFromId?: Maybe<string> /**  */
}


/**  */
export type WorkflowDelayInput = {
	key?: Maybe<string> /**  */
	displayName?: Maybe<TranslatedFieldInput> /**  */
	property?: Maybe<string> /**  */
	delay?: Maybe<string> /**  */
}


/**  */
export type WorkflowScheduleTaskInput = {
	status: ScheduleTaskStatus /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId: string /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	workflowLinkedObjectId: string /**  */
	workflowLinkedObjectType?: Maybe<string> /**  */
}


/** Représente les méthodes disponible pour le type WorkflowScheduleTask */
export type FieldUpdateOperatorOfWorkflowScheduleTask = {
	set?: Maybe<SetOperatorInputOfWorkflowScheduleTask> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkflowScheduleTask */
export type SetOperatorInputOfWorkflowScheduleTask = {
	workflowLinkedObjectId?: Maybe<string> /**  */
	workflowLinkedObjectType?: Maybe<string> /**  */
	status?: Maybe<ScheduleTaskStatus> /**  */
	dueDate?: Maybe<Date> /**  */
	createdDate?: Maybe<Date> /**  */
	lastModificationDate?: Maybe<Date> /**  */
	entityType?: Maybe<string> /**  */
	entityId?: Maybe<string> /**  */
	lockPodGuid?: Maybe<string> /**  */
	lockGuid?: Maybe<string> /**  */
	lockPodOn?: Maybe<Date> /**  */
	externalObjectId?: Maybe<string> /**  */
}


/**  */
export type WorkTimeInput = {
	name?: Maybe<TranslatedFieldInput> /**  */
	enabled: boolean /**  */
	config?: Maybe<WorkTimeConfigInput> /**  */
}


/** Représente les méthodes disponible pour le type WorkTime */
export type FieldUpdateOperatorOfWorkTime = {
	set?: Maybe<SetOperatorInputOfWorkTime> /**  */
	push?: Maybe<PushUpdateOperatorInputWorkTime> /**  */
	pullAll?: Maybe<PullUpdateOperatorInputWorkTime> /**  */
}


/** Représente les propriétés de mise à jour de l'entité WorkTime */
export type SetOperatorInputOfWorkTime = {
	enabled?: Maybe<boolean> /**  */
	notTrackingFields: Array<string> /**  */
	externalObjectId?: Maybe<string> /**  */
	name?: Maybe<SetOperatorInputOfTranslatedField> /**  */
	config?: Maybe<SetOperatorInputOfWorkTimeConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTime */
export type PushUpdateOperatorInputWorkTime = {
	notTrackingFields: Array<string> /**  */
	config?: Maybe<PushUpdateOperatorInputWorkTimeConfig> /**  */
}


/** Représente les propriétés de type collection de l'entité WorkTime */
export type PullUpdateOperatorInputWorkTime = {
	notTrackingFields: Array<string> /**  */
	config?: Maybe<PullUpdateOperatorInputWorkTimeConfig> /**  */
}


/** Représente les méthodes disponible pour le type LicenseModel */
export type FieldUpdateOperatorOfLicenseModel = {
	set?: Maybe<SetOperatorInputOfLicenseModel> /**  */
	inc?: Maybe<IncOperatorInputLicenseModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité LicenseModel */
export type SetOperatorInputOfLicenseModel = {
	clientId?: Maybe<string> /**  */
	type?: Maybe<LicenseTypeEnum> /**  */
	expireAt?: Maybe<Date> /**  */
	blocked?: Maybe<boolean> /**  */
	customer?: Maybe<SetOperatorInputOfCustomerModel> /**  */
	features?: Maybe<SetOperatorInputOfFeaturesModel> /**  */
}


/** Représente les propriétés de mise à jour de l'entité CustomerModel */
export type SetOperatorInputOfCustomerModel = {
	name?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	company?: Maybe<string> /**  */
}


/** Représente les propriétés de mise à jour de l'entité FeaturesModel */
export type SetOperatorInputOfFeaturesModel = {
	assetUnlimited?: Maybe<boolean> /**  */
	assetNumber?: Maybe<number> /**  */
	assetAuditedUnlimited?: Maybe<boolean> /**  */
	assetAuditedNumber?: Maybe<number> /**  */
	managerNumber?: Maybe<number> /**  */
	operatorNumber?: Maybe<number> /**  */
	endUserNumber?: Maybe<number> /**  */
	mailBoxNumber?: Maybe<number> /**  */
	featureServiceDesk?: Maybe<boolean> /**  */
	featureServiceDeskExpireAt?: Maybe<Date> /**  */
	featureAssets?: Maybe<boolean> /**  */
	featureAssetsExpireAt?: Maybe<Date> /**  */
	featureContracts?: Maybe<boolean> /**  */
	featureContractsExpireAt?: Maybe<Date> /**  */
	featureFinancials?: Maybe<boolean> /**  */
	featureFinancialsExpireAt?: Maybe<Date> /**  */
	featureStocks?: Maybe<boolean> /**  */
	featureStocksExpireAt?: Maybe<Date> /**  */
	featureSuppliers?: Maybe<boolean> /**  */
	featureSuppliersExpireAt?: Maybe<Date> /**  */
	featureInventories?: Maybe<boolean> /**  */
	featureInventoriesExpireAt?: Maybe<Date> /**  */
	featureLoans?: Maybe<boolean> /**  */
	featureLoansExpireAt?: Maybe<Date> /**  */
	featureSoftware?: Maybe<boolean> /**  */
	featureSoftwareExpireAt?: Maybe<Date> /**  */
}


/** Représente les propriétés de type numérique de l'entité LicenseModel */
export type IncOperatorInputLicenseModel = {
	features?: Maybe<IncOperatorInputFeaturesModel> /**  */
}


/** Représente les propriétés de type numérique de l'entité FeaturesModel */
export type IncOperatorInputFeaturesModel = {
	assetNumber?: Maybe<number> /**  */
	assetAuditedNumber?: Maybe<number> /**  */
	managerNumber?: Maybe<number> /**  */
	operatorNumber?: Maybe<number> /**  */
	endUserNumber?: Maybe<number> /**  */
	mailBoxNumber?: Maybe<number> /**  */
}


/**  */
export type LicenseModelInput = {
	type: LicenseTypeEnum /**  */
	features?: Maybe<FeaturesModelInput> /**  */
	expireAt?: Maybe<Date> /**  */
	customer?: Maybe<CustomerModelInput> /**  */
	clientId: string /**  */
}


/**  */
export type FeaturesModelInput = {
	assetUnlimited?: Maybe<boolean> /**  */
	assetNumber?: Maybe<number> /**  */
	assetAuditedUnlimited?: Maybe<boolean> /**  */
	assetAuditedNumber?: Maybe<number> /**  */
	managerNumber: number /**  */
	operatorNumber: number /**  */
	endUserNumber: number /**  */
	mailBoxNumber: number /**  */
	featureServiceDesk?: Maybe<boolean> /**  */
	featureServiceDeskExpireAt?: Maybe<Date> /**  */
	featureAssets?: Maybe<boolean> /**  */
	featureAssetsExpireAt?: Maybe<Date> /**  */
	featureContracts?: Maybe<boolean> /**  */
	featureContractsExpireAt?: Maybe<Date> /**  */
	featureFinancials?: Maybe<boolean> /**  */
	featureFinancialsExpireAt?: Maybe<Date> /**  */
	featureStocks?: Maybe<boolean> /**  */
	featureStocksExpireAt?: Maybe<Date> /**  */
	featureSuppliers?: Maybe<boolean> /**  */
	featureSuppliersExpireAt?: Maybe<Date> /**  */
	featureInventories?: Maybe<boolean> /**  */
	featureInventoriesExpireAt?: Maybe<Date> /**  */
	featureLoans?: Maybe<boolean> /**  */
	featureLoansExpireAt?: Maybe<Date> /**  */
	featureSoftware?: Maybe<boolean> /**  */
	featureSoftwareExpireAt?: Maybe<Date> /**  */
}


/**  */
export type CustomerModelInput = {
	company?: Maybe<string> /**  */
	email?: Maybe<string> /**  */
	name?: Maybe<string> /**  */
}

        