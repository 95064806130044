import { AccountBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Permission, PermissionAccess } from '../types';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Injectable({ providedIn: 'root' })
export class AccountCoreService extends AccountBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient la liste des permissions d'accès. */
  @InjectArgs
  public permissionAccesses(
    @Args('current') current: Permission,
  ): { id: string; display: string }[] {
    return Object.values(PermissionAccess)
      .filter(
        (f) =>
          (current.value != 'all' && f !== PermissionAccess.Custom) ||
          current.value === 'all',
      )
      .map((m) => {
        let display = TranslateService.get(
          `enums/permissionAccess/${m.toString()}`,
        );
        if (display.startsWith('[')) {
          display = `${m.toString()}`;
        }
        return {
          id: m,
          display: display,
        };
      });
  }
}
