import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStockTransfersBaseVariables, FirstStockTransfersBaseVariables, CountStockTransfersBaseVariables, FindStockTransfersBaseVariables, SearchStockTransfersBaseVariables, ExportSchemaStockTransfersBaseVariables, ExportDataStockTransfersBaseVariables, CustomQueryStockTransfersBaseVariables, CustomQueryIdStockTransfersBaseVariables, FindFilesStockTransfersBaseVariables, FindAttachmentFilesStockTransfersBaseVariables, FindHelpDeskUserAttachmentFilesStockTransfersBaseVariables, UsedStockTransfersBaseVariables, FindStorageConsumableItemsStockTransfersBaseVariables, FindNotAssetsStockTransfersBaseVariables, ExistStockTransfersBaseVariables, FindUnassociatedAssetsStockTransfersBaseVariables, InsertStockTransfersBaseVariables, UpdateStockTransfersBaseVariables, ImportDataStockTransfersBaseVariables, UpdateManyStockTransfersBaseVariables, DeleteStockTransfersBaseVariables, AddFilesStockTransfersBaseVariables, RemoveFilesStockTransfersBaseVariables, UploadChunkFileStockTransfersBaseVariables, AddTransferAssetsStockTransfersBaseVariables, AddAssetsStockTransfersBaseVariables, RemoveAssetsStockTransfersBaseVariables } from '../gqls'
import { GetStockTransfersDocument, FirstStockTransfersDocument, CountStockTransfersDocument, FindStockTransfersDocument, SearchStockTransfersDocument, ExportSchemaStockTransfersDocument, ExportDataStockTransfersDocument, CustomQueryStockTransfersDocument, CustomQueryIdStockTransfersDocument, FindFilesStockTransfersDocument, FindAttachmentFilesStockTransfersDocument, FindHelpDeskUserAttachmentFilesStockTransfersDocument, UsedStockTransfersDocument, FindStorageConsumableItemsStockTransfersDocument, FindNotAssetsStockTransfersDocument, ExistStockTransfersDocument, FindUnassociatedAssetsStockTransfersDocument, InsertStockTransfersDocument, UpdateStockTransfersDocument, ImportDataStockTransfersDocument, UpdateManyStockTransfersDocument, DeleteStockTransfersDocument, AddFilesStockTransfersDocument, RemoveFilesStockTransfersDocument, UploadChunkFileStockTransfersDocument, AddTransferAssetsStockTransfersDocument, AddAssetsStockTransfersDocument, RemoveAssetsStockTransfersDocument } from '../gqls'
import { ServiceSingleResultOfStockTransfer, QueryContextOfStockTransfer, FilterOfStockTransfer, ServiceSingleResultOfInt64, ServiceListResultOfStockTransfer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfOutCart, QueryContextOfOutCart, FilterOfOutCart, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, StockTransferInput, FieldUpdateOperatorOfStockTransfer, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StockTransferBaseService {
    
public modelName = 'stockTransfer';
public modelPluralName = 'stockTransfers';

	private getStockTransfersQuery: GetStockTransfersDocument;
	private firstStockTransfersQuery: FirstStockTransfersDocument;
	private countStockTransfersQuery: CountStockTransfersDocument;
	private findStockTransfersQuery: FindStockTransfersDocument;
	private searchStockTransfersQuery: SearchStockTransfersDocument;
	private exportSchemaStockTransfersQuery: ExportSchemaStockTransfersDocument;
	private exportDataStockTransfersQuery: ExportDataStockTransfersDocument;
	private customQueryStockTransfersQuery: CustomQueryStockTransfersDocument;
	private customQueryIdStockTransfersQuery: CustomQueryIdStockTransfersDocument;
	private findFilesStockTransfersQuery: FindFilesStockTransfersDocument;
	private findAttachmentFilesStockTransfersQuery: FindAttachmentFilesStockTransfersDocument;
	private findHelpDeskUserAttachmentFilesStockTransfersQuery: FindHelpDeskUserAttachmentFilesStockTransfersDocument;
	private usedStockTransfersQuery: UsedStockTransfersDocument;
	private findStorageConsumableItemsStockTransfersQuery: FindStorageConsumableItemsStockTransfersDocument;
	private findNotAssetsStockTransfersQuery: FindNotAssetsStockTransfersDocument;
	private existStockTransfersQuery: ExistStockTransfersDocument;
	private findUnassociatedAssetsStockTransfersQuery: FindUnassociatedAssetsStockTransfersDocument;
	private insertStockTransfersMutation: InsertStockTransfersDocument;
	private updateStockTransfersMutation: UpdateStockTransfersDocument;
	private importDataStockTransfersMutation: ImportDataStockTransfersDocument;
	private updateManyStockTransfersMutation: UpdateManyStockTransfersDocument;
	private deleteStockTransfersMutation: DeleteStockTransfersDocument;
	private addFilesStockTransfersMutation: AddFilesStockTransfersDocument;
	private removeFilesStockTransfersMutation: RemoveFilesStockTransfersDocument;
	private uploadChunkFileStockTransfersMutation: UploadChunkFileStockTransfersDocument;
	private addTransferAssetsStockTransfersMutation: AddTransferAssetsStockTransfersDocument;
	private addAssetsStockTransfersMutation: AddAssetsStockTransfersDocument;
	private removeAssetsStockTransfersMutation: RemoveAssetsStockTransfersDocument;

	constructor(private injector: Injector) {
		this.getStockTransfersQuery = this.injector.get(GetStockTransfersDocument);
		this.firstStockTransfersQuery = this.injector.get(FirstStockTransfersDocument);
		this.countStockTransfersQuery = this.injector.get(CountStockTransfersDocument);
		this.findStockTransfersQuery = this.injector.get(FindStockTransfersDocument);
		this.searchStockTransfersQuery = this.injector.get(SearchStockTransfersDocument);
		this.exportSchemaStockTransfersQuery = this.injector.get(ExportSchemaStockTransfersDocument);
		this.exportDataStockTransfersQuery = this.injector.get(ExportDataStockTransfersDocument);
		this.customQueryStockTransfersQuery = this.injector.get(CustomQueryStockTransfersDocument);
		this.customQueryIdStockTransfersQuery = this.injector.get(CustomQueryIdStockTransfersDocument);
		this.findFilesStockTransfersQuery = this.injector.get(FindFilesStockTransfersDocument);
		this.findAttachmentFilesStockTransfersQuery = this.injector.get(FindAttachmentFilesStockTransfersDocument);
		this.findHelpDeskUserAttachmentFilesStockTransfersQuery = this.injector.get(FindHelpDeskUserAttachmentFilesStockTransfersDocument);
		this.usedStockTransfersQuery = this.injector.get(UsedStockTransfersDocument);
		this.findStorageConsumableItemsStockTransfersQuery = this.injector.get(FindStorageConsumableItemsStockTransfersDocument);
		this.findNotAssetsStockTransfersQuery = this.injector.get(FindNotAssetsStockTransfersDocument);
		this.existStockTransfersQuery = this.injector.get(ExistStockTransfersDocument);
		this.findUnassociatedAssetsStockTransfersQuery = this.injector.get(FindUnassociatedAssetsStockTransfersDocument);
		this.insertStockTransfersMutation = this.injector.get(InsertStockTransfersDocument);
		this.updateStockTransfersMutation = this.injector.get(UpdateStockTransfersDocument);
		this.importDataStockTransfersMutation = this.injector.get(ImportDataStockTransfersDocument);
		this.updateManyStockTransfersMutation = this.injector.get(UpdateManyStockTransfersDocument);
		this.deleteStockTransfersMutation = this.injector.get(DeleteStockTransfersDocument);
		this.addFilesStockTransfersMutation = this.injector.get(AddFilesStockTransfersDocument);
		this.removeFilesStockTransfersMutation = this.injector.get(RemoveFilesStockTransfersDocument);
		this.uploadChunkFileStockTransfersMutation = this.injector.get(UploadChunkFileStockTransfersDocument);
		this.addTransferAssetsStockTransfersMutation = this.injector.get(AddTransferAssetsStockTransfersDocument);
		this.addAssetsStockTransfersMutation = this.injector.get(AddAssetsStockTransfersDocument);
		this.removeAssetsStockTransfersMutation = this.injector.get(RemoveAssetsStockTransfersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransfer> {
    
    		let variables: GetStockTransfersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.get));
            }
            else{
                let result:ServiceSingleResultOfStockTransfer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransfer> {
    
    		let variables: FirstStockTransfersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStockTransfersBaseVariables = {
    			filter: filter
    		}
    				return this.countStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransfer> {
    
    		let variables: FindStockTransfersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransfer> {
    
    		let variables: SearchStockTransfersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStockTransfersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStockTransfersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransfer> {
    
    		let variables: CustomQueryStockTransfersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStockTransfer,
		@Args('filter?') filter?: FilterOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockTransfer> {
    
    		let variables: CustomQueryIdStockTransfersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesStockTransfersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesStockTransfersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesStockTransfersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStockTransfersBaseVariables = {
    			ids: ids
    		}
    				return this.usedStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.used));
    	}

    	/**  */
    	@InjectArgs
    	public findStorageConsumableItems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('toStorageUnitId?') toStorageUnitId?: string,
		@Args('options?') options?: QueryContextOfOutCart,
		@Args('id?') id?: string,
		@Args('fromStorageUnitId?') fromStorageUnitId?: string,
		@Args('filter?') filter?: FilterOfOutCart,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOutCart> {
    
    		let variables: FindStorageConsumableItemsStockTransfersBaseVariables = {
    			id: id,
			fromStorageUnitId: fromStorageUnitId,
			toStorageUnitId: toStorageUnitId,
			filter: filter,
			options: options
    		}
    				return this.findStorageConsumableItemsStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findStorageConsumableItems));
    	}

    	/**  */
    	@InjectArgs
    	public findNotAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('fromStorageUnitId?') fromStorageUnitId?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindNotAssetsStockTransfersBaseVariables = {
    			id: id,
			fromStorageUnitId: fromStorageUnitId,
			filter: filter,
			options: options
    		}
    				return this.findNotAssetsStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findNotAssets));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStockTransfersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsStockTransfersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsStockTransfersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StockTransferInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransfer> {
    
    		let variables: InsertStockTransfersBaseVariables = {
    			entity: entity
    		}
    				return this.insertStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockTransfer> {
    
    		let variables: UpdateStockTransfersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStockTransfersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockTransfer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStockTransfersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStockTransfersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesStockTransfersBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesStockTransfersBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('size') size: number,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileStockTransfersBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId
    		}
    				return this.uploadChunkFileStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addTransferAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTransferAssetsStockTransfersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addTransferAssetsStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.addTransferAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsStockTransfersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsStockTransfersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeAssetsStockTransfersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockTransfers.removeAssets));
    	}
    
}