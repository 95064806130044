import { Component, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { FileModelType } from '@clarilog/core';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { FileViewerType } from '@clarilog/shared2/components/file-manager/viewer/file-manager-viewer.component';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { alert } from 'devextreme/ui/dialog';
import model from './model.json';
/**
 * Liste relatif au fichiers attachés aux tickets (incident et demande)
 *
 */
@Component({
  selector: 'clc-ticket-attachment-list',
  templateUrl: './ticket-attachment-list.component.html',
  styleUrls: ['./ticket-attachment-list.component.scss'],
})
export class CoreTicketAttachmentListComponent {
  title: string;
  model: ModelState;

  selectedData: any[] = [];
  selectedKeys: any[] = [];

  _filesItem: FileModelType[] = [];
  dataSource: DataSource;

  /** Obtient ou définit si l'on traite la liste de tous les tickets ou des incidents/requests */
  @Input() allTickets: boolean = true;

  ticketType: string = 'tickets';

  /** Représente les valeur pour le viewer. */
  fileViewerInfo: FileViewerType;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    public fileManagerService: FileManagerCoreService,
    private router: Router,
    private route: ActivatedRoute,
  ) {}

  async ngOnInit() {
    if (this.router.url.indexOf('/attachments') > 0) {
      this.ticketType = this.router.url.substring(
        1,
        this.router.url.indexOf('/attachments'),
      );
    }

    let tempModel: any = JSON.parse(JSON.stringify(model));

    this.modelCompilerService.compile(tempModel).subscribe((model) => {
      this.model = model;
    });

    this.model.sharedContext.params.set('ticketType', () => this.ticketType);
  }

  /** Permet de visualiser le fichier. */
  onSelectedFileOpened = (e) => {
    this.fileViewerInfo = {
      display: true,
      file: e.data,
      files: this._filesItem,
    };
  };

  /** Événement de suppression */
  async onDeleted(e) {
    alert(
      TranslateService.get('entities/dataFile/msgDeleteTags'),
      TranslateService.get('globals/warning'),
    );
  }
}
