import { ScanImportOptionBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import { ScanConfigurationCoreService } from './scan-configuration.service';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs';
import {
  FilterOfScanConfiguration,
  QueryContextOfScanConfiguration,
  ServiceListResultOfScanConfiguration,
} from '../types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-import-option')
export class ScanImportOptionCoreService extends ScanImportOptionBaseService {
  constructor(
    injector: Injector,
    private scanConfigurationService: ScanConfigurationCoreService,
  ) {
    super(injector);
  }
}
