import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSubstitutesBaseVariables, FirstSubstitutesBaseVariables, CountSubstitutesBaseVariables, FindSubstitutesBaseVariables, SearchSubstitutesBaseVariables, ExportSchemaSubstitutesBaseVariables, ExportDataSubstitutesBaseVariables, CustomQuerySubstitutesBaseVariables, CustomQueryIdSubstitutesBaseVariables, UsedSubstitutesBaseVariables, ExistSubstitutesBaseVariables, InsertSubstitutesBaseVariables, UpdateSubstitutesBaseVariables, ImportDataSubstitutesBaseVariables, UpdateManySubstitutesBaseVariables, DeleteSubstitutesBaseVariables, RunSubstitutionSubstitutesBaseVariables } from '../gqls'
import { GetSubstitutesDocument, FirstSubstitutesDocument, CountSubstitutesDocument, FindSubstitutesDocument, SearchSubstitutesDocument, ExportSchemaSubstitutesDocument, ExportDataSubstitutesDocument, CustomQuerySubstitutesDocument, CustomQueryIdSubstitutesDocument, UsedSubstitutesDocument, ExistSubstitutesDocument, InsertSubstitutesDocument, UpdateSubstitutesDocument, ImportDataSubstitutesDocument, UpdateManySubstitutesDocument, DeleteSubstitutesDocument, RunSubstitutionSubstitutesDocument } from '../gqls'
import { ServiceSingleResultOfSubstitute, QueryContextOfSubstitute, FilterOfSubstitute, ServiceSingleResultOfInt64, ServiceListResultOfSubstitute, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SubstituteInput, FieldUpdateOperatorOfSubstitute } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SubstituteBaseService {
    
public modelName = 'substitute';
public modelPluralName = 'substitutes';

	private getSubstitutesQuery: GetSubstitutesDocument;
	private firstSubstitutesQuery: FirstSubstitutesDocument;
	private countSubstitutesQuery: CountSubstitutesDocument;
	private findSubstitutesQuery: FindSubstitutesDocument;
	private searchSubstitutesQuery: SearchSubstitutesDocument;
	private exportSchemaSubstitutesQuery: ExportSchemaSubstitutesDocument;
	private exportDataSubstitutesQuery: ExportDataSubstitutesDocument;
	private customQuerySubstitutesQuery: CustomQuerySubstitutesDocument;
	private customQueryIdSubstitutesQuery: CustomQueryIdSubstitutesDocument;
	private usedSubstitutesQuery: UsedSubstitutesDocument;
	private existSubstitutesQuery: ExistSubstitutesDocument;
	private insertSubstitutesMutation: InsertSubstitutesDocument;
	private updateSubstitutesMutation: UpdateSubstitutesDocument;
	private importDataSubstitutesMutation: ImportDataSubstitutesDocument;
	private updateManySubstitutesMutation: UpdateManySubstitutesDocument;
	private deleteSubstitutesMutation: DeleteSubstitutesDocument;
	private runSubstitutionSubstitutesMutation: RunSubstitutionSubstitutesDocument;

	constructor(private injector: Injector) {
		this.getSubstitutesQuery = this.injector.get(GetSubstitutesDocument);
		this.firstSubstitutesQuery = this.injector.get(FirstSubstitutesDocument);
		this.countSubstitutesQuery = this.injector.get(CountSubstitutesDocument);
		this.findSubstitutesQuery = this.injector.get(FindSubstitutesDocument);
		this.searchSubstitutesQuery = this.injector.get(SearchSubstitutesDocument);
		this.exportSchemaSubstitutesQuery = this.injector.get(ExportSchemaSubstitutesDocument);
		this.exportDataSubstitutesQuery = this.injector.get(ExportDataSubstitutesDocument);
		this.customQuerySubstitutesQuery = this.injector.get(CustomQuerySubstitutesDocument);
		this.customQueryIdSubstitutesQuery = this.injector.get(CustomQueryIdSubstitutesDocument);
		this.usedSubstitutesQuery = this.injector.get(UsedSubstitutesDocument);
		this.existSubstitutesQuery = this.injector.get(ExistSubstitutesDocument);
		this.insertSubstitutesMutation = this.injector.get(InsertSubstitutesDocument);
		this.updateSubstitutesMutation = this.injector.get(UpdateSubstitutesDocument);
		this.importDataSubstitutesMutation = this.injector.get(ImportDataSubstitutesDocument);
		this.updateManySubstitutesMutation = this.injector.get(UpdateManySubstitutesDocument);
		this.deleteSubstitutesMutation = this.injector.get(DeleteSubstitutesDocument);
		this.runSubstitutionSubstitutesMutation = this.injector.get(RunSubstitutionSubstitutesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSubstitute> {
    
    		let variables: GetSubstitutesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.get));
            }
            else{
                let result:ServiceSingleResultOfSubstitute={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSubstitute,
		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSubstitute> {
    
    		let variables: FirstSubstitutesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSubstitutesBaseVariables = {
    			filter: filter
    		}
    				return this.countSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSubstitute,
		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSubstitute> {
    
    		let variables: FindSubstitutesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSubstitute,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSubstitute> {
    
    		let variables: SearchSubstitutesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSubstitutesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSubstitutesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSubstitute,
		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSubstitute> {
    
    		let variables: CustomQuerySubstitutesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSubstitute,
		@Args('filter?') filter?: FilterOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSubstitute> {
    
    		let variables: CustomQueryIdSubstitutesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSubstitutesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSubstitutesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSubstitutesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SubstituteInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSubstitute> {
    
    		let variables: InsertSubstitutesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSubstitute> {
    
    		let variables: UpdateSubstitutesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSubstitutesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSubstitute,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySubstitutesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSubstitutesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public runSubstitution(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RunSubstitutionSubstitutesBaseVariables = {
    
    		}
    				return this.runSubstitutionSubstitutesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.substitutes.runSubstitution));
    	}
    
}