import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAutoRunsBaseVariables, FirstAutoRunsBaseVariables, CountAutoRunsBaseVariables, FindAutoRunsBaseVariables, SearchAutoRunsBaseVariables, ExportSchemaAutoRunsBaseVariables, ExportDataAutoRunsBaseVariables, CustomQueryAutoRunsBaseVariables, CustomQueryIdAutoRunsBaseVariables, UsedAutoRunsBaseVariables, ExistAutoRunsBaseVariables } from '../gqls'
import { GetAutoRunsDocument, FirstAutoRunsDocument, CountAutoRunsDocument, FindAutoRunsDocument, SearchAutoRunsDocument, ExportSchemaAutoRunsDocument, ExportDataAutoRunsDocument, CustomQueryAutoRunsDocument, CustomQueryIdAutoRunsDocument, UsedAutoRunsDocument, ExistAutoRunsDocument } from '../gqls'
import { ServiceSingleResultOfAutoRun, QueryContextOfAutoRun, FilterOfAutoRun, ServiceSingleResultOfInt64, ServiceListResultOfAutoRun, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AutoRunBaseService {
    
public modelName = 'autoRun';
public modelPluralName = 'autoRuns';

	private getAutoRunsQuery: GetAutoRunsDocument;
	private firstAutoRunsQuery: FirstAutoRunsDocument;
	private countAutoRunsQuery: CountAutoRunsDocument;
	private findAutoRunsQuery: FindAutoRunsDocument;
	private searchAutoRunsQuery: SearchAutoRunsDocument;
	private exportSchemaAutoRunsQuery: ExportSchemaAutoRunsDocument;
	private exportDataAutoRunsQuery: ExportDataAutoRunsDocument;
	private customQueryAutoRunsQuery: CustomQueryAutoRunsDocument;
	private customQueryIdAutoRunsQuery: CustomQueryIdAutoRunsDocument;
	private usedAutoRunsQuery: UsedAutoRunsDocument;
	private existAutoRunsQuery: ExistAutoRunsDocument;

	constructor(private injector: Injector) {
		this.getAutoRunsQuery = this.injector.get(GetAutoRunsDocument);
		this.firstAutoRunsQuery = this.injector.get(FirstAutoRunsDocument);
		this.countAutoRunsQuery = this.injector.get(CountAutoRunsDocument);
		this.findAutoRunsQuery = this.injector.get(FindAutoRunsDocument);
		this.searchAutoRunsQuery = this.injector.get(SearchAutoRunsDocument);
		this.exportSchemaAutoRunsQuery = this.injector.get(ExportSchemaAutoRunsDocument);
		this.exportDataAutoRunsQuery = this.injector.get(ExportDataAutoRunsDocument);
		this.customQueryAutoRunsQuery = this.injector.get(CustomQueryAutoRunsDocument);
		this.customQueryIdAutoRunsQuery = this.injector.get(CustomQueryIdAutoRunsDocument);
		this.usedAutoRunsQuery = this.injector.get(UsedAutoRunsDocument);
		this.existAutoRunsQuery = this.injector.get(ExistAutoRunsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAutoRun> {
    
    		let variables: GetAutoRunsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.get));
            }
            else{
                let result:ServiceSingleResultOfAutoRun={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAutoRun,
		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAutoRun> {
    
    		let variables: FirstAutoRunsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAutoRunsBaseVariables = {
    			filter: filter
    		}
    				return this.countAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAutoRun,
		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAutoRun> {
    
    		let variables: FindAutoRunsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAutoRun,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAutoRun> {
    
    		let variables: SearchAutoRunsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAutoRunsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAutoRunsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAutoRun,
		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAutoRun> {
    
    		let variables: CustomQueryAutoRunsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAutoRun,
		@Args('filter?') filter?: FilterOfAutoRun,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAutoRun> {
    
    		let variables: CustomQueryIdAutoRunsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAutoRunsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAutoRunsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAutoRunsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.autoRuns.exist));
    	}
    
    
}