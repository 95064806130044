import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLogicalDisksBaseVariables, FirstLogicalDisksBaseVariables, CountLogicalDisksBaseVariables, FindLogicalDisksBaseVariables, SearchLogicalDisksBaseVariables, ExportSchemaLogicalDisksBaseVariables, ExportDataLogicalDisksBaseVariables, CustomQueryLogicalDisksBaseVariables, CustomQueryIdLogicalDisksBaseVariables, UsedLogicalDisksBaseVariables, ExistLogicalDisksBaseVariables, FindUnassociatedAlertsLogicalDisksBaseVariables } from '../gqls'
import { GetLogicalDisksDocument, FirstLogicalDisksDocument, CountLogicalDisksDocument, FindLogicalDisksDocument, SearchLogicalDisksDocument, ExportSchemaLogicalDisksDocument, ExportDataLogicalDisksDocument, CustomQueryLogicalDisksDocument, CustomQueryIdLogicalDisksDocument, UsedLogicalDisksDocument, ExistLogicalDisksDocument, FindUnassociatedAlertsLogicalDisksDocument } from '../gqls'
import { ServiceSingleResultOfLogicalDisk, QueryContextOfLogicalDisk, FilterOfLogicalDisk, ServiceSingleResultOfInt64, ServiceListResultOfLogicalDisk, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LogicalDiskBaseService {
    
public modelName = 'logicalDisk';
public modelPluralName = 'logicalDisks';

	private getLogicalDisksQuery: GetLogicalDisksDocument;
	private firstLogicalDisksQuery: FirstLogicalDisksDocument;
	private countLogicalDisksQuery: CountLogicalDisksDocument;
	private findLogicalDisksQuery: FindLogicalDisksDocument;
	private searchLogicalDisksQuery: SearchLogicalDisksDocument;
	private exportSchemaLogicalDisksQuery: ExportSchemaLogicalDisksDocument;
	private exportDataLogicalDisksQuery: ExportDataLogicalDisksDocument;
	private customQueryLogicalDisksQuery: CustomQueryLogicalDisksDocument;
	private customQueryIdLogicalDisksQuery: CustomQueryIdLogicalDisksDocument;
	private usedLogicalDisksQuery: UsedLogicalDisksDocument;
	private existLogicalDisksQuery: ExistLogicalDisksDocument;
	private findUnassociatedAlertsLogicalDisksQuery: FindUnassociatedAlertsLogicalDisksDocument;

	constructor(private injector: Injector) {
		this.getLogicalDisksQuery = this.injector.get(GetLogicalDisksDocument);
		this.firstLogicalDisksQuery = this.injector.get(FirstLogicalDisksDocument);
		this.countLogicalDisksQuery = this.injector.get(CountLogicalDisksDocument);
		this.findLogicalDisksQuery = this.injector.get(FindLogicalDisksDocument);
		this.searchLogicalDisksQuery = this.injector.get(SearchLogicalDisksDocument);
		this.exportSchemaLogicalDisksQuery = this.injector.get(ExportSchemaLogicalDisksDocument);
		this.exportDataLogicalDisksQuery = this.injector.get(ExportDataLogicalDisksDocument);
		this.customQueryLogicalDisksQuery = this.injector.get(CustomQueryLogicalDisksDocument);
		this.customQueryIdLogicalDisksQuery = this.injector.get(CustomQueryIdLogicalDisksDocument);
		this.usedLogicalDisksQuery = this.injector.get(UsedLogicalDisksDocument);
		this.existLogicalDisksQuery = this.injector.get(ExistLogicalDisksDocument);
		this.findUnassociatedAlertsLogicalDisksQuery = this.injector.get(FindUnassociatedAlertsLogicalDisksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogicalDisk> {
    
    		let variables: GetLogicalDisksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.get));
            }
            else{
                let result:ServiceSingleResultOfLogicalDisk={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogicalDisk,
		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogicalDisk> {
    
    		let variables: FirstLogicalDisksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLogicalDisksBaseVariables = {
    			filter: filter
    		}
    				return this.countLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogicalDisk,
		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDisk> {
    
    		let variables: FindLogicalDisksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLogicalDisk,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDisk> {
    
    		let variables: SearchLogicalDisksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLogicalDisksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLogicalDisksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLogicalDisk,
		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDisk> {
    
    		let variables: CustomQueryLogicalDisksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLogicalDisk,
		@Args('filter?') filter?: FilterOfLogicalDisk,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDisk> {
    
    		let variables: CustomQueryIdLogicalDisksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLogicalDisksBaseVariables = {
    			ids: ids
    		}
    				return this.usedLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLogicalDisksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.exist));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsLogicalDisksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsLogicalDisksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDisks.findUnassociatedAlerts));
    	}
    
    
}