import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNotificationsBaseVariables, FirstNotificationsBaseVariables, CountNotificationsBaseVariables, FindNotificationsBaseVariables, SearchNotificationsBaseVariables, ExportSchemaNotificationsBaseVariables, ExportDataNotificationsBaseVariables, CustomQueryNotificationsBaseVariables, CustomQueryIdNotificationsBaseVariables, UsedNotificationsBaseVariables, FindMyNotificationNotificationsBaseVariables, FindNotificationFromMyTeamsNotificationsBaseVariables, CanViewNotificationComponentNotificationsBaseVariables, ExistNotificationsBaseVariables, InsertNotificationsBaseVariables, UpdateNotificationsBaseVariables, ImportDataNotificationsBaseVariables, UpdateManyNotificationsBaseVariables, DeleteNotificationsBaseVariables, MarkAsUnReadNotificationsBaseVariables, MarkAsReadNotificationsBaseVariables, MultipleMarkAsReadNotificationsBaseVariables, MultipleMarkAsUnReadNotificationsBaseVariables, RemoveNotificationByKeysNotificationsBaseVariables } from '../gqls'
import { GetNotificationsDocument, FirstNotificationsDocument, CountNotificationsDocument, FindNotificationsDocument, SearchNotificationsDocument, ExportSchemaNotificationsDocument, ExportDataNotificationsDocument, CustomQueryNotificationsDocument, CustomQueryIdNotificationsDocument, UsedNotificationsDocument, FindMyNotificationNotificationsDocument, FindNotificationFromMyTeamsNotificationsDocument, CanViewNotificationComponentNotificationsDocument, ExistNotificationsDocument, InsertNotificationsDocument, UpdateNotificationsDocument, ImportDataNotificationsDocument, UpdateManyNotificationsDocument, DeleteNotificationsDocument, MarkAsUnReadNotificationsDocument, MarkAsReadNotificationsDocument, MultipleMarkAsReadNotificationsDocument, MultipleMarkAsUnReadNotificationsDocument, RemoveNotificationByKeysNotificationsDocument } from '../gqls'
import { ServiceSingleResultOfNotification, QueryContextOfNotification, FilterOfNotification, ServiceSingleResultOfInt64, ServiceListResultOfNotification, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, NotificationInput, FieldUpdateOperatorOfNotification, NotificationKey } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NotificationBaseService {
    
public modelName = 'notification';
public modelPluralName = 'notifications';

	private getNotificationsQuery: GetNotificationsDocument;
	private firstNotificationsQuery: FirstNotificationsDocument;
	private countNotificationsQuery: CountNotificationsDocument;
	private findNotificationsQuery: FindNotificationsDocument;
	private searchNotificationsQuery: SearchNotificationsDocument;
	private exportSchemaNotificationsQuery: ExportSchemaNotificationsDocument;
	private exportDataNotificationsQuery: ExportDataNotificationsDocument;
	private customQueryNotificationsQuery: CustomQueryNotificationsDocument;
	private customQueryIdNotificationsQuery: CustomQueryIdNotificationsDocument;
	private usedNotificationsQuery: UsedNotificationsDocument;
	private findMyNotificationNotificationsQuery: FindMyNotificationNotificationsDocument;
	private findNotificationFromMyTeamsNotificationsQuery: FindNotificationFromMyTeamsNotificationsDocument;
	private canViewNotificationComponentNotificationsQuery: CanViewNotificationComponentNotificationsDocument;
	private existNotificationsQuery: ExistNotificationsDocument;
	private insertNotificationsMutation: InsertNotificationsDocument;
	private updateNotificationsMutation: UpdateNotificationsDocument;
	private importDataNotificationsMutation: ImportDataNotificationsDocument;
	private updateManyNotificationsMutation: UpdateManyNotificationsDocument;
	private deleteNotificationsMutation: DeleteNotificationsDocument;
	private markAsUnReadNotificationsMutation: MarkAsUnReadNotificationsDocument;
	private markAsReadNotificationsMutation: MarkAsReadNotificationsDocument;
	private multipleMarkAsReadNotificationsMutation: MultipleMarkAsReadNotificationsDocument;
	private multipleMarkAsUnReadNotificationsMutation: MultipleMarkAsUnReadNotificationsDocument;
	private removeNotificationByKeysNotificationsMutation: RemoveNotificationByKeysNotificationsDocument;

	constructor(private injector: Injector) {
		this.getNotificationsQuery = this.injector.get(GetNotificationsDocument);
		this.firstNotificationsQuery = this.injector.get(FirstNotificationsDocument);
		this.countNotificationsQuery = this.injector.get(CountNotificationsDocument);
		this.findNotificationsQuery = this.injector.get(FindNotificationsDocument);
		this.searchNotificationsQuery = this.injector.get(SearchNotificationsDocument);
		this.exportSchemaNotificationsQuery = this.injector.get(ExportSchemaNotificationsDocument);
		this.exportDataNotificationsQuery = this.injector.get(ExportDataNotificationsDocument);
		this.customQueryNotificationsQuery = this.injector.get(CustomQueryNotificationsDocument);
		this.customQueryIdNotificationsQuery = this.injector.get(CustomQueryIdNotificationsDocument);
		this.usedNotificationsQuery = this.injector.get(UsedNotificationsDocument);
		this.findMyNotificationNotificationsQuery = this.injector.get(FindMyNotificationNotificationsDocument);
		this.findNotificationFromMyTeamsNotificationsQuery = this.injector.get(FindNotificationFromMyTeamsNotificationsDocument);
		this.canViewNotificationComponentNotificationsQuery = this.injector.get(CanViewNotificationComponentNotificationsDocument);
		this.existNotificationsQuery = this.injector.get(ExistNotificationsDocument);
		this.insertNotificationsMutation = this.injector.get(InsertNotificationsDocument);
		this.updateNotificationsMutation = this.injector.get(UpdateNotificationsDocument);
		this.importDataNotificationsMutation = this.injector.get(ImportDataNotificationsDocument);
		this.updateManyNotificationsMutation = this.injector.get(UpdateManyNotificationsDocument);
		this.deleteNotificationsMutation = this.injector.get(DeleteNotificationsDocument);
		this.markAsUnReadNotificationsMutation = this.injector.get(MarkAsUnReadNotificationsDocument);
		this.markAsReadNotificationsMutation = this.injector.get(MarkAsReadNotificationsDocument);
		this.multipleMarkAsReadNotificationsMutation = this.injector.get(MultipleMarkAsReadNotificationsDocument);
		this.multipleMarkAsUnReadNotificationsMutation = this.injector.get(MultipleMarkAsUnReadNotificationsDocument);
		this.removeNotificationByKeysNotificationsMutation = this.injector.get(RemoveNotificationByKeysNotificationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNotification> {
    
    		let variables: GetNotificationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.get));
            }
            else{
                let result:ServiceSingleResultOfNotification={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNotification> {
    
    		let variables: FirstNotificationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNotificationsBaseVariables = {
    			filter: filter
    		}
    				return this.countNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: FindNotificationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNotification,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: SearchNotificationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNotificationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNotificationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: CustomQueryNotificationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: CustomQueryIdNotificationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNotificationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.used));
    	}

    	/**  */
    	@InjectArgs
    	public findMyNotification(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: FindMyNotificationNotificationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findMyNotificationNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.findMyNotification));
    	}

    	/**  */
    	@InjectArgs
    	public findNotificationFromMyTeams(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNotification,
		@Args('filter?') filter?: FilterOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNotification> {
    
    		let variables: FindNotificationFromMyTeamsNotificationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotificationFromMyTeamsNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.findNotificationFromMyTeams));
    	}

    	/**  */
    	@InjectArgs
    	public canViewNotificationComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CanViewNotificationComponentNotificationsBaseVariables = {
    
    		}
    				return this.canViewNotificationComponentNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.canViewNotificationComponent));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNotificationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNotificationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: NotificationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNotification> {
    
    		let variables: InsertNotificationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNotification> {
    
    		let variables: UpdateNotificationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataNotificationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfNotification,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyNotificationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteNotificationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.delete));
    	}

    	/**  */
    	@InjectArgs
    	public markAsUnRead(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: MarkAsUnReadNotificationsBaseVariables = {
    			id: id
    		}
    				return this.markAsUnReadNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.markAsUnRead));
    	}

    	/**  */
    	@InjectArgs
    	public markAsRead(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: MarkAsReadNotificationsBaseVariables = {
    			id: id
    		}
    				return this.markAsReadNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.markAsRead));
    	}

    	/**  */
    	@InjectArgs
    	public multipleMarkAsRead(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: MultipleMarkAsReadNotificationsBaseVariables = {
    			ids: ids
    		}
    				return this.multipleMarkAsReadNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.multipleMarkAsRead));
    	}

    	/**  */
    	@InjectArgs
    	public multipleMarkAsUnRead(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: MultipleMarkAsUnReadNotificationsBaseVariables = {
    			ids: ids
    		}
    				return this.multipleMarkAsUnReadNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.multipleMarkAsUnRead));
    	}

    	/**  */
    	@InjectArgs
    	public removeNotificationByKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('urlObjectId') urlObjectId: string,
		@Args('keys') keys: Array<NotificationKey>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveNotificationByKeysNotificationsBaseVariables = {
    			keys: keys,
			urlObjectId: urlObjectId
    		}
    				return this.removeNotificationByKeysNotificationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notifications.removeNotificationByKeys));
    	}
    
}