import { Directive, Input, OnChanges, SimpleChanges, TemplateRef, ViewContainerRef } from "@angular/core";
import { CorePolicyValidator, PolicyOperator } from "@clarilog/core/services2/authorization/authorization-policy-builder.service";

export class CoreHasPoliciesDirectiveParams {
  policies: string | string[] | any
  operator?: PolicyOperator
}

@Directive({
  selector: "[clcHasPolicies]" 
})
export class CoreHasPoliciesDirective implements OnChanges {
  private operator: PolicyOperator = "and";
  ngOnChanges(changes: SimpleChanges): void {

    if (
      changes.clcHasPolicies != undefined &&
      changes.clcHasPolicies.currentValue != undefined
    ) 
    {
      if(changes.clcHasPolicies.currentValue['operator'] != undefined)
      {
        this.clcHasPolicies = changes.clcHasPolicies.currentValue.policies;
        this.operator = changes.clcHasPolicies.currentValue.operator;
      }

      let validate = this.policyValidator.validate(
        this.clcHasPolicies,
        this.operator
      );

      if (validate) {
        // Ajout du template au DOM
        this.viewContainer.createEmbeddedView(this.templateRef);
      } else {
        // Suppression du template du DOM
        this.viewContainer.clear();
      }
    }
  }
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    private policyValidator: CorePolicyValidator
  ) { }

  @Input() clcHasPolicies: CoreHasPoliciesDirectiveParams;
  @Input() set clcHasPoliciesOperator(value) {
    this.operator = value;
  }
}
