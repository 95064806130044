export function createCheckBoxModule(Parchment, control) {
  //EmbedBlot
  const EmbedBlot = Parchment.EmbedBlot;
  return class CheckBoxHtmlEditor extends EmbedBlot {
    //not accurate name but needed for icon
    static blotName = 'checkList';
    static tagName = 'INPUT';

    static create(value) {
      EmbedBlot.prototype.appendChild = (e) => {};

      const node = super.create(value) as HTMLElement;

      if (value.classList != undefined) {
        if (value.attributes.getNamedItem('checked')) {
          node.setAttribute('checked', '');
        }
      }
      node.setAttribute('type', 'checkbox');

      const userAgent = navigator.userAgent;
      const isFirefoxBrowser = /firefox|fxios/i.test(userAgent);
      if (control.readOnly == null || !control.readOnly) {
        node.addEventListener(
          isFirefoxBrowser ? 'focus' : 'click',
          (e: any) => {
            if (isFirefoxBrowser) {
              if (e.srcElement.checked) {
                node.removeAttribute('checked');
              } else {
                node.setAttribute('checked', '');
              }
            } else {
              if (e.srcElement.checked) {
                node.setAttribute('checked', 'true');
              } else {
                //node.setAttribute('checked', '');
                node.removeAttribute('checked');
              }
            }

            isFirefoxBrowser ? node.blur() : null;
          },
        );
      } else {
        node.setAttribute('disabled', 'true');
      }

      return node;
    }
    static formats(domNode: Element) {
      return domNode;
    }

    static value(domNode: Element) {
      return domNode;
    }

    domNode: HTMLImageElement;
  };
}
