import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSerialNumbersBaseVariables, FirstSerialNumbersBaseVariables, CountSerialNumbersBaseVariables, FindSerialNumbersBaseVariables, SearchSerialNumbersBaseVariables, ExportSchemaSerialNumbersBaseVariables, ExportDataSerialNumbersBaseVariables, CustomQuerySerialNumbersBaseVariables, CustomQueryIdSerialNumbersBaseVariables, UsedSerialNumbersBaseVariables, ExistSerialNumbersBaseVariables } from '../gqls'
import { GetSerialNumbersDocument, FirstSerialNumbersDocument, CountSerialNumbersDocument, FindSerialNumbersDocument, SearchSerialNumbersDocument, ExportSchemaSerialNumbersDocument, ExportDataSerialNumbersDocument, CustomQuerySerialNumbersDocument, CustomQueryIdSerialNumbersDocument, UsedSerialNumbersDocument, ExistSerialNumbersDocument } from '../gqls'
import { ServiceSingleResultOfSerialNumber, QueryContextOfSerialNumber, FilterOfSerialNumber, ServiceSingleResultOfInt64, ServiceListResultOfSerialNumber, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SerialNumberBaseService {
    
public modelName = 'serialNumber';
public modelPluralName = 'serialNumbers';

	private getSerialNumbersQuery: GetSerialNumbersDocument;
	private firstSerialNumbersQuery: FirstSerialNumbersDocument;
	private countSerialNumbersQuery: CountSerialNumbersDocument;
	private findSerialNumbersQuery: FindSerialNumbersDocument;
	private searchSerialNumbersQuery: SearchSerialNumbersDocument;
	private exportSchemaSerialNumbersQuery: ExportSchemaSerialNumbersDocument;
	private exportDataSerialNumbersQuery: ExportDataSerialNumbersDocument;
	private customQuerySerialNumbersQuery: CustomQuerySerialNumbersDocument;
	private customQueryIdSerialNumbersQuery: CustomQueryIdSerialNumbersDocument;
	private usedSerialNumbersQuery: UsedSerialNumbersDocument;
	private existSerialNumbersQuery: ExistSerialNumbersDocument;

	constructor(private injector: Injector) {
		this.getSerialNumbersQuery = this.injector.get(GetSerialNumbersDocument);
		this.firstSerialNumbersQuery = this.injector.get(FirstSerialNumbersDocument);
		this.countSerialNumbersQuery = this.injector.get(CountSerialNumbersDocument);
		this.findSerialNumbersQuery = this.injector.get(FindSerialNumbersDocument);
		this.searchSerialNumbersQuery = this.injector.get(SearchSerialNumbersDocument);
		this.exportSchemaSerialNumbersQuery = this.injector.get(ExportSchemaSerialNumbersDocument);
		this.exportDataSerialNumbersQuery = this.injector.get(ExportDataSerialNumbersDocument);
		this.customQuerySerialNumbersQuery = this.injector.get(CustomQuerySerialNumbersDocument);
		this.customQueryIdSerialNumbersQuery = this.injector.get(CustomQueryIdSerialNumbersDocument);
		this.usedSerialNumbersQuery = this.injector.get(UsedSerialNumbersDocument);
		this.existSerialNumbersQuery = this.injector.get(ExistSerialNumbersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSerialNumber> {
    
    		let variables: GetSerialNumbersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.get));
            }
            else{
                let result:ServiceSingleResultOfSerialNumber={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSerialNumber,
		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSerialNumber> {
    
    		let variables: FirstSerialNumbersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSerialNumbersBaseVariables = {
    			filter: filter
    		}
    				return this.countSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSerialNumber,
		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialNumber> {
    
    		let variables: FindSerialNumbersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSerialNumber,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialNumber> {
    
    		let variables: SearchSerialNumbersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSerialNumbersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSerialNumbersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSerialNumber,
		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialNumber> {
    
    		let variables: CustomQuerySerialNumbersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSerialNumber,
		@Args('filter?') filter?: FilterOfSerialNumber,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialNumber> {
    
    		let variables: CustomQueryIdSerialNumbersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSerialNumbersBaseVariables = {
    			ids: ids
    		}
    				return this.usedSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSerialNumbersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSerialNumbersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialNumbers.exist));
    	}
    
    
}