import {
  Component,
  EventEmitter,
  Injector,
  Input,
  Output,
  ViewChild,
} from '@angular/core';

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';

import { AuthorizationCoreService } from '@clarilog/core/services2';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  EmailPrototypeCoreService,
  IncidentCoreService,
  ResolutionModelCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { OrganizationStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/organization-storage-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DxFileUploaderComponent } from 'devextreme-angular/ui/file-uploader';
import notify from 'devextreme/ui/notify';
import { camelCase } from 'lodash';
import { mimeTypes } from 'mime-wrapper';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { v4 as uuidv4 } from 'uuid';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { TranslatedFieldHelperService } from '../translate-field';
import { createCheckBoxModule } from './modules/checkbox-html-editor';
import { DxHtmlEditorComponent } from 'devextreme-angular/ui/html-editor';
import { DEFAULT_COLORS } from './constant/colors-html-editor';

@Component({
  selector: 'clc-html-editor',
  templateUrl: './html-editor.component.html',
  styleUrls: ['./html-editor.component.scss'],
})
export class HtmlEditorComponent {
  values: string;

  @Input() control: any;
  @Input() type: string;
  @Input() fg: UntypedFormGroup;
  @Input() state: ModelState;
  @Input() noForm: boolean = false;
  @Input() fullMode: boolean = false;
  @Input() isTranslateField: boolean = false;
  // Définit la visibilité du bouton "plein écran" lorsque le composant est en mode "readOnly"
  @Input() toolbarCanVisible: boolean = true;
  // Définit la visibilité du bouton "plein écran" lorsque le composant est en mode "readOnly"
  @Input() readOnlyFullScreenVisible: boolean = false;

  _value: string;
  @Input() get value(): string {
    return this._value;
  }
  set value(value: string) {
    this._value = value;
    this.valueChange.emit(this._value);
  }
  @Output() valueChange = new EventEmitter<string>();

  /** Attente valkue change de debounce */
  debouncer: Subject<string> = new Subject<string>();
  lastKeyDown: string;
  attachmentPopUpVisible: boolean = false;

  magicId;

  allowedFileExtensions: any[];
  forbidenFileExtensions: any[];
  maxSize: number;

  @ViewChild('fileUploader', { static: true })
  uploader: DxFileUploaderComponent;
  @ViewChild(DxHtmlEditorComponent, { static: false })
  htmlEditor: DxHtmlEditorComponent;

  constructor(
    private emailPrototypeService: EmailPrototypeCoreService,
    private authorizationService: AuthorizationCoreService,
    private translateFieldHelperService: TranslatedFieldHelperService,
    private resolutionModelService: ResolutionModelCoreService,
    private injector: Injector,
    private organizationStorageService: OrganizationStorageService,
  ) {
    this.applyResolutionModel = this.applyResolutionModel.bind(this);
    this.selectionChangedResolutionModel =
      this.selectionChangedResolutionModel.bind(this);
    this.applyCommentaryModel = this.applyCommentaryModel.bind(this);
    this.focusInResolutionModel = this.focusInResolutionModel.bind(this);
    this.focusInCommentaryModel = this.focusInCommentaryModel.bind(this);
    this.fullScreenMode = this.fullScreenMode.bind(this);
    this.uploadFileChunk = this.uploadFileChunk.bind(this);
    this.debouncer
      .pipe(debounceTime(300))
      .subscribe((value) => this.valueChanged.emit(value));
    this.maxSize = this.organizationStorageService.getMaxFileSize();
    this.allowedFileExtensions =
      this.organizationStorageService.getAllowedFileExtension();
    this.forbidenFileExtensions =
      this.organizationStorageService.getForbiddenFileExtension();
    this.magicId = 'htmlEditorComponent' + uuidv4();
  }
  ngOnInit(): void {
    if (this.control?.options?.fullScreenMode != undefined) {
      this.fullMode = this.control?.options?.fullScreenMode;
    }

    if (this.state?.formComponent?.onSubmit != undefined) {
      this.state?.formComponent?.onSubmit.subscribe((f) => {
        // Evite une eventuellement interprétation de changement apres le save et le get des données
        this.focusIn = false;
      });
    }
  }

  /** Vaidation */
  validate({ value }: UntypedFormControl) {
    return false;
  }

  writeValue(obj: any): void {
    this.values = obj;
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    throw new Error('Method not implemented.');
  }

  public focusInResolutionModel(e) {
    let control = this.control;
  }

  public focusInCommentaryModel(e) {
    let control = this.control;
  }

  public applyResolutionModel(e) {
    let fieldControl = FormGroupHelpers.formControlByName(
      this.fg,
      this.control.fieldName,
    );

    let value =
      e?.itemData != undefined && e?.itemData != null
        ? e.itemData[this.control.options.resolutionModel?.contentExpr]
        : e.value[this.control.options.resolutionModel?.contentExpr];
    let translateFieldContentValue =
      value[this.translateFieldHelperService.getTranslateKey()];

    setTimeout(() => {
      if (!this.isTranslateField) {
        value = translateFieldContentValue;
      }
      fieldControl.setValue(value, { emitEvent: true });
      fieldControl.markAsDirty();
    }, 50);
  }
  previousValueResolutionModel;
  public selectionChangedResolutionModel(e) {
    if (e.event != undefined) {
      this.state.on.emit({
        eventName: 'applyResolutionModel',
        eventData: {
          value:
            e.value?.id != undefined && e.value?.id != null ? e.value?.id : e,
          oldValue: this.previousValueResolutionModel,
        },
      });
      this.previousValueResolutionModel = e.value?.id;
      this.applyResolutionModel(e);
    }
  }

  public applyCommentaryModel(e) {
    let fieldControl = FormGroupHelpers.formControlByName(
      this.fg,
      this.control.fieldName,
    );

    if (fieldControl == undefined && this.control.fieldName.includes('.')) {
      fieldControl = FormGroupHelpers.formControlByName(
        this.fg,
        this.control.fieldName.replace('.', '_'),
      );
    }

    let splitContent =
      this.control.options.commentaryModel.contentExpr.split('.');
    let value = e.itemData;

    splitContent.forEach((f) => {
      value = value[f];
    });

    fieldControl.reset();
    fieldControl.markAsDirty();
    setTimeout(() => {
      fieldControl.setValue(value, { emitEvent: true });
      fieldControl.markAsDirty();
    }, 50);
  }

  stampButtonOptions: any = {
    stylingMode: 'text',
    icon: 'fas fa-stamp',
    onClick: () => {
      if (this.htmlComponent != undefined) {
        let value = this.htmlComponent.option('value');
        let stamp = TranslateService.get('cl-HtmlEditor-stamp');

        stamp = stamp.replace(
          '[0]',
          this.authorizationService.user.displayName(),
        );
        stamp = stamp.replace('[1]', new Date().toLocaleString());
        if (value != undefined) {
          value += '<BR>';
        } else {
          value = '';
        }
        value +=
          '<span style="font-weight:bold" >' + stamp + '</span> <br> <br>  ';
        this.htmlComponent.option('value', value);
        this.htmlComponent.focus();
        this.htmlComponent.setSelection(value.length + 1, 1);
      }
    },
  };

  showHtmlInfo: boolean = false;
  htmlValue: string = '';
  @Input() showHtmlCode: boolean = true;
  /** Permet d'ouvrir le code html  */
  showHtmlButton: any = {
    stylingMode: 'text',
    icon: 'variable',
    text: TranslateService.get('clHtmlEditor-markdown-title'),
    onClick: () => {
      // ouvre le pop up de modification
      this.htmlValue = this.htmlComponent.option('value');
      this.showHtmlInfo = true;
    },
  };

  /**
   * Couleurs par défaut
   */
  public arrayColors: string[] = DEFAULT_COLORS;

  /**
   * Couleur par défaut du texte
   * et de l'arrière plan
   */
  public textColor: string = '#000000';
  public backgroundColor: string = '#FFFFFF';

  colorPickerVisible: boolean = false;
  backgroundColorPickerVisible: boolean = false;
  /**
   * Permet d'ouvrir la pop-up
   * pour changer la couleur du texte
   */
  showColorPickerButton: any = {
    icon: 'fas fa-palette',
    onClick: () => {
      this.colorPickerVisible = true;
    },
  };
  /**
   * Permet d'ouvrir la pop-up
   * pour changer la couleur de fond du texte
   */
  showBackgroundColorPickerButton: any = {
    icon: 'fas fa-paint-roller',
    onClick: () => {
      this.backgroundColorPickerVisible = true;
    },
  };

  /**
   * Changement de la couleur du texte
   */
  editTextColor() {
    this.htmlEditor.instance.format('color', this.textColor);
    this.colorPickerVisible = false;
  }

  /**
   * Changement de la couleur de l'arrière plan
   */
  editBackgroundColor() {
    this.htmlEditor.instance.format('background', this.backgroundColor);
    this.backgroundColorPickerVisible = false;
  }

  /**
   * Fermeture du color picker
   * pour la couleur de fond
   */
  closeBackgroundPickerColor() {
    this.backgroundColorPickerVisible = false;
  }

  /**
   * Fermeture du color picker
   * pour la couleur du texte
   */
  closePickerColor() {
    this.colorPickerVisible = false;
  }

  test() {
    let value = this.htmlComponent.option('value');

    value += '<input type="checkbox" checked>';

    this.htmlComponent.option('value', value);
    debugger;
  }

  showFullPopUp: boolean = false;
  /** Permet d'ouvrir le contenu en plein ecran */
  fullScreenButton: any = {
    stylingMode: 'text',
    icon: 'fullscreen',
    text: TranslateService.get('clHtmlEditor-fullScreen'),
    onClick: () => {
      this.fullScreenMode();
    },
  };
  addFilesButton: any = {
    stylingMode: 'text',
    icon: 'upload',
    text: TranslateService.get('clHtmlEditor-upload'),
    onClick: () => {
      this.uploadFile();
    },
  };

  uploadFile() {
    this.uploader.instance.reset();
    this.attachmentPopUpVisible = true;
  }

  async uploadFileChunk(fileData, chunksInfo, destinationDir) {
    let type = mimeTypes.getExtension(fileData.type);
    if (type != undefined && type != '') {
      type = '.' + mimeTypes.getExtension(fileData.type);
    } else {
      type = fileData.name.substring(fileData.name.lastIndexOf('.'));
    }
    if (this.forbidenFileExtensions?.includes(type)) {
      notify(
        TranslateService.get('entities/files/forbiddenFiles'),
        'error',
        10000,
      );
      return;
    }
    if (chunksInfo.bytesUploaded == 0) {
      fileData['_tempFileId'] = null;
    }
    let chunks = await this.readFileAsync(chunksInfo);
    let lastChunk = chunksInfo.chunkIndex == chunksInfo.chunkCount - 1;
    let fields = [GqlField.create('data')];

    fileData['_tempFileId'] = await this.injector
      .get(IncidentCoreService)
      ['uploadChunkFile'](
        fields,
        fileData.size,
        lastChunk,
        chunksInfo.chunkIndex,
        fileData.type,
        fileData.name,
        fileData['_tempFileId']?.data,
        null,
        chunks,
      )
      .toPromise();
    if (chunksInfo.chunkCount == chunksInfo.chunkIndex + 1) {
      this.state.on.emit({ eventName: 'uploadFile', eventData: fileData });
    }
  }
  /** Permet de lire le fichier pour l'envoyer. */
  async readFileAsync(chunksInfo): Promise<string> {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();
      reader.onload = (file) => {
        let result = btoa(reader.result.toString());
        resolve(result);
      };
      reader.onerror = reject;
      reader.readAsBinaryString(chunksInfo.chunkBlob);
    });
  }

  isFullScreen;
  readOnlyFullScreen() {
    let element = document.getElementById(this.magicId);

    if (document.fullscreenElement != undefined) {
      document.exitFullscreen();
      return;
    }
    // this.showFullPopUp = true;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    }
  }

  fullScreenMode() {
    // ouvre le pop up de modification
    let element = this.htmlComponent.element();
    let tt = document.fullscreenElement;
    if (document.fullscreenElement != undefined) {
      document.exitFullscreen();
      return;
    }
    // this.showFullPopUp = true;
    if (element.requestFullscreen) {
      element.requestFullscreen();
    } else if (element.webkitRequestFullscreen) {
      // Safari
      element.webkitRequestFullscreen();
    } else if (element.msRequestFullscreen) {
      // IE
      element.msRequestFullscreen();
    }
  }
  /** Fermeture de l'édition */
  closeClick(e) {
    this.showHtmlInfo = false;
  }

  /** Fermeture de l'édition */
  editHtmlClick(e) {
    this.htmlComponent.option('value', this.htmlValue);
    this.showHtmlInfo = false;
    this.valueChanged.emit(this.htmlValue); //Enregistrement du changement dans une variable tampon
    this.focusLost.emit(e); //Enregistrement du changement définitif
  }

  /** Récupère le control via son nom. */
  getFormControl(name): UntypedFormControl {
    if (this.fg?.root != undefined) {
      let formControl = FormGroupHelpers.formControlByName(
        this.fg.root as UntypedFormGroup,
        name,
      );

      return formControl;
    }
    return undefined;
  }

  @Output() valueChanged = new EventEmitter<string>();

  @Output() focusLost = new EventEmitter();

  cancelEvent = false;
  focusIn = false;

  onFocusIn(e) {
    this.focusIn = true;
  }

  onValueChanged(e, control = null) {
    if (!this.focusIn) {
      return;
    }

    if (!this.cancelEvent) {
      this.cancelEvent = true;

      let newValue = e.value;
      if (e.component.NAME == 'dxHtmlEditor' && newValue != undefined) {
        newValue = ModelFieldCompilerService.createElementFromHTML(newValue);
      }

      if (
        control.type == 'HtmlEditorSimpleComponent' ||
        control.type == 'HtmlEditorSimpleTranslatedComponent'
      ) {
        // Supprime tout les retour chariot
        newValue = newValue.replace(/<br>/gi, ' ');
        newValue = newValue.replace(/<p>/gi, ' ');
        newValue = newValue.replace(/<\/p>/gi, ' ');

        if (
          (e?.event?.type != undefined &&
            e?.event?.type == 'keydown' &&
            e?.event?.originalEvent?.keyCode == 13) ||
          e?.event?.type == 'paste'
        ) {
          if (e?.event?.type == 'paste') {
            let newValue = e.value;
            newValue = newValue.replace(/<br>/gi, ' ');
            newValue = newValue.replace(/<p>/gi, ' ');
            newValue = newValue.replace(/<\/p>/gi, ' ');
            e.value = newValue;
          }
        }
        if (
          e?.event?.type == 'keydown' ||
          e?.event?.type == 'paste' ||
          e?.event?.type == 'dxclick'
        ) {
          this.cancelEvent = true;
          if (e?.event?.type == 'keydown') {
            // Cree une attente pour lancer l'emit d'un keydown (evite de le faire pour chaque touche appuye)
            this.debouncer.next(e.value);
          } else {
            // Indique tout de suite
            this.valueChanged.emit(e.value);
          }

          this.cancelEvent = false;
          return;
        }
      }

      if (e?.event?.type == 'keydown') {
        // Cree une attente pour lancer l'emit d'un keydown (evite de le faire pour chaque touche appuye)
        this.debouncer.next(e.value);
      } else {
        // Indique tout de suite

        let dxMentionCount = (e?.value?.match(/dx-mention/g) || []).length;
        let dxMentionCountOld = (e.previousValue?.match(/dx-mention/g) || [])
          .length;

        if (
          e?.event?.type != undefined ||
          dxMentionCountOld !== dxMentionCount ||
          (e?.value?.length !== e?.previousValue?.length &&
            control.type === 'HtmlEditorTranslatedComponent')
        ) {
          this.valueChanged.emit(e.value);
        } else if (
          e?.event == undefined &&
          e?.value != undefined &&
          e?.value != e?.previousValue
        ) {
          this.valueChanged.emit(e.value);
        }
      }

      this.cancelEvent = false;
    }
  }

  onBlur(event) {
    this.focusLost.emit(event);
  }

  @Input() get mentions(): any[] {
    if (this.mentionDataSource != undefined) {
      return this.mentionDataSource;
    } else {
      return this.control?.options?.mentions?.source;
    }
  }
  mentionDataSource;
  public getMention(control) {
    let typeEmailPrototype = this.state.sharedContext.entry.get('type');
    if (typeEmailPrototype == undefined) {
      typeEmailPrototype = this.state.sharedContext.entry.get('entityType');
    }
    if (
      typeEmailPrototype != undefined &&
      this.mentionDataSource == undefined &&
      control.options.mentions.type == 'EmailPrototype'
    ) {
      typeEmailPrototype = camelCase(typeEmailPrototype);
      this.mentionDataSource = [];
      this.emailPrototypeService
        .findFieldSubsitute(
          this.emailPrototypeService.getFields(),
          typeEmailPrototype,
        )
        .subscribe(async (res) => {
          res.data.forEach((r) => {
            r.name = TranslateService.get(
              'entities/' +
                typeEmailPrototype +
                '/' +
                (r.key.charAt(0).toLowerCase() + r.key.substring(1)),
            );
          });
          this.mentionDataSource = res.data.filter(
            (f) => f.name.indexOf('[') < 0 && f.key != 'Columns',
          );
        });
    }
    return this.mentionDataSource;
  }
  onContentReady(e) {
    let inputs = e.element.getElementsByClassName('dx-show-invalid-badge');

    if (inputs != undefined && inputs.length > 0) {
      let elements = [];

      for (let i = 0; i < inputs.length; i++) {
        elements.push(inputs[i]);
      }
      elements.forEach((f) => {
        f.classList.remove('dx-show-invalid-badge');
      });
    }
  }
  htmlComponent;
  /** Initialisation des composants devextreme. */
  onComponentInitialized(e, control) {
    this.htmlComponent = e.component;

    const parchment = this.htmlComponent.get('parchment');

    this.htmlComponent.register(createCheckBoxModule(parchment, control));

    const Link = this.htmlComponent.get('formats/link');

    class ClickableLink extends Link {
      static sanitize(url) {
        let sanitize = super.sanitize(url, this.PROTOCOL_WHITELIST);

        sanitize = sanitize
          ? url.includes != undefined &&
            (url.includes('http://') || url.includes('https://'))
            ? url
            : `http://${url}`
          : this.SANITIZED_URL;

        // Correction erreur double http
        if (sanitize != undefined) {
          sanitize = sanitize.replace('http://https://', 'https://');
        }
        return sanitize;
      }
      static create(value) {
        const node = super.create(value);
        node.setAttribute('contenteditable', 'false');
        return node;
      }
    }
    this.htmlComponent.register({ 'formats/link': ClickableLink });

    if (
      this.state != undefined &&
      this.state.sharedContext != undefined &&
      this.state.sharedContext.lock != undefined &&
      this.state.sharedContext.lock._params != undefined &&
      this.state.sharedContext.lock._params[control.fieldName] != undefined
    ) {
      e.component.option('readOnly', true);
    }
    if (
      control.type == 'HtmlEditorComponent' ||
      control.type == 'HtmlEditorSimpleComponent' ||
      control.type == 'HtmlEditorSimpleTranslatedComponent' ||
      control.type == 'HtmlEditorTranslatedComponent'
    ) {
      control.options = control.options || {};
      if (
        control.options.mentions != undefined &&
        control.options.mentions.type == 'EmailPrototype'
      ) {
        control.options.mentions.displayExpr = 'name';
        control.options.mentions.valueExpr = 'key';
        control.options.mentions.searchExpr = 'name';
        this.getMention(control);
      }
    }
    if (e.component.option('readOnly') == true) {
      this.toolbarCanVisible = false;
      if (this.control?.options?.fullScreenMode == true) {
        this.readOnlyFullScreenVisible = true;
      }
    }
  }
}
