<div class="scheduler-group-component">
  <cl-toolbar class="action">
    <cl-toolbar-items>
      <cl-toolbar-item-button
        [text]="'new' | translate"
        (onClick)="add()"
        icon="fas fa-plus"
        *ngIf="allowAdding"
      >
      </cl-toolbar-item-button>
    </cl-toolbar-items>
  </cl-toolbar>

  <div class="scheduler-component">
    <dx-scheduler
      class="scheduler"
      [dataSource]="remote ? currentSourceRemote : currentSource"
      [currentView]="currentView"
      [showAllDayPanel]="showAllDayPanel"
      [startDayHour]="startDayHour"
      [endDayHour]="endDayHour"
      [views]="views"
      [editing]="{
        allowUpdating: allowUpdating && !readOnly,
        allowDeleting: allowDeleting && !readOnly,
        allowAdding: allowAdding && !readOnly,
        allowDragging: allowUpdating && !readOnly,
        allowResizing: allowUpdating && !readOnly
      }"
      appointmentTooltipTemplate="tooltip-template"
      (onAppointmentAdded)="onAppointmentAdded($event)"
      (onAppointmentAdding)="onAppointmentAdding($event)"
      (onAppointmentDeleting)="onAppointmentDeleting($event)"
      (onAppointmentRendered)="onAppointmentRendered($event)"
      (onAppointmentUpdated)="onAppointmentUpdated($event)"
      (onAppointmentUpdating)="onAppointmentUpdating($event)"
      (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
      (onContentReady)="onContentReady($event)"
      (onOptionChanged)="onOptionChanged($event)"
    >
      <div *dxTemplate="let model of 'tooltip-template'">
        <div class="dx-tooltip-appointment-item">
          <div class="dx-tooltip-appointment-item-marker">
            <div class="dx-tooltip-appointment-item-marker-body"></div>
          </div>
          <div class="dx-tooltip-appointment-item-content">
            <div class="dx-tooltip-appointment-item-content-subject">
              {{ displayText(model.appointmentData.text) }}
            </div>
            <div *ngIf="model.appointmentData.allDay">
              <div class="dx-tooltip-appointment-item-content-date">
                {{ displayDate(model.appointmentData.startDate, 'full') }}
              </div>
            </div>
            <div *ngIf="!model.appointmentData.allDay">
              <div class="dx-tooltip-appointment-item-content-date">
                {{ displayTime(model.appointmentData.startDate) }} -
                {{ displayTime(model.appointmentData.endDate) }}
              </div>
            </div>
            <pre
              *ngIf="
                model.appointmentData.description != undefined &&
                model.appointmentData.description != ''
              "
              class="cl-tooltip-appointment-item-content-description"
              >{{ model.appointmentData.description }}</pre
            >
          </div>
          <div
            class="dx-tooltip-appointment-item-delete-button-container"
            *ngIf="allowDeleting"
          >
            <dx-button
              [visible]="!readOnly"
              icon="trash"
              (onClick)="deleteAppointment($event, model)"
            >
            </dx-button>
          </div>
        </div>
      </div>
    </dx-scheduler>
  </div>
  <dx-popup
    [width]="800"
    [height]="'80%'"
    [showTitle]="false"
    class="add-item-popup"
    #popup
    [resizeEnabled]="true"
    [hideOnOutsideClick]="true"
    [deferRendering]="true"
    [(visible)]="popupVisible"
    [disabled]="true"
  >
    <clc-select-list
      [title]="'globals/selectAudit' | translate"
      [buttonText]="'globals/selectAuditAgreed' | translate"
      [source]="sourceRessource"
      [columns]="schedulerAuditsColumns"
      [multiple]="true"
    >
    </clc-select-list>
  </dx-popup>
</div>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="loadWait"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="true"
></dx-load-panel>
