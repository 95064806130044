import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPhysicalMemoriesBaseVariables, FirstPhysicalMemoriesBaseVariables, CountPhysicalMemoriesBaseVariables, FindPhysicalMemoriesBaseVariables, SearchPhysicalMemoriesBaseVariables, ExportSchemaPhysicalMemoriesBaseVariables, ExportDataPhysicalMemoriesBaseVariables, CustomQueryPhysicalMemoriesBaseVariables, CustomQueryIdPhysicalMemoriesBaseVariables, UsedPhysicalMemoriesBaseVariables, ExistPhysicalMemoriesBaseVariables } from '../gqls'
import { GetPhysicalMemoriesDocument, FirstPhysicalMemoriesDocument, CountPhysicalMemoriesDocument, FindPhysicalMemoriesDocument, SearchPhysicalMemoriesDocument, ExportSchemaPhysicalMemoriesDocument, ExportDataPhysicalMemoriesDocument, CustomQueryPhysicalMemoriesDocument, CustomQueryIdPhysicalMemoriesDocument, UsedPhysicalMemoriesDocument, ExistPhysicalMemoriesDocument } from '../gqls'
import { ServiceSingleResultOfPhysicalMemory, QueryContextOfPhysicalMemory, FilterOfPhysicalMemory, ServiceSingleResultOfInt64, ServiceListResultOfPhysicalMemory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PhysicalMemoryBaseService {
    
public modelName = 'physicalMemory';
public modelPluralName = 'physicalMemories';

	private getPhysicalMemoriesQuery: GetPhysicalMemoriesDocument;
	private firstPhysicalMemoriesQuery: FirstPhysicalMemoriesDocument;
	private countPhysicalMemoriesQuery: CountPhysicalMemoriesDocument;
	private findPhysicalMemoriesQuery: FindPhysicalMemoriesDocument;
	private searchPhysicalMemoriesQuery: SearchPhysicalMemoriesDocument;
	private exportSchemaPhysicalMemoriesQuery: ExportSchemaPhysicalMemoriesDocument;
	private exportDataPhysicalMemoriesQuery: ExportDataPhysicalMemoriesDocument;
	private customQueryPhysicalMemoriesQuery: CustomQueryPhysicalMemoriesDocument;
	private customQueryIdPhysicalMemoriesQuery: CustomQueryIdPhysicalMemoriesDocument;
	private usedPhysicalMemoriesQuery: UsedPhysicalMemoriesDocument;
	private existPhysicalMemoriesQuery: ExistPhysicalMemoriesDocument;

	constructor(private injector: Injector) {
		this.getPhysicalMemoriesQuery = this.injector.get(GetPhysicalMemoriesDocument);
		this.firstPhysicalMemoriesQuery = this.injector.get(FirstPhysicalMemoriesDocument);
		this.countPhysicalMemoriesQuery = this.injector.get(CountPhysicalMemoriesDocument);
		this.findPhysicalMemoriesQuery = this.injector.get(FindPhysicalMemoriesDocument);
		this.searchPhysicalMemoriesQuery = this.injector.get(SearchPhysicalMemoriesDocument);
		this.exportSchemaPhysicalMemoriesQuery = this.injector.get(ExportSchemaPhysicalMemoriesDocument);
		this.exportDataPhysicalMemoriesQuery = this.injector.get(ExportDataPhysicalMemoriesDocument);
		this.customQueryPhysicalMemoriesQuery = this.injector.get(CustomQueryPhysicalMemoriesDocument);
		this.customQueryIdPhysicalMemoriesQuery = this.injector.get(CustomQueryIdPhysicalMemoriesDocument);
		this.usedPhysicalMemoriesQuery = this.injector.get(UsedPhysicalMemoriesDocument);
		this.existPhysicalMemoriesQuery = this.injector.get(ExistPhysicalMemoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPhysicalMemory> {
    
    		let variables: GetPhysicalMemoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.get));
            }
            else{
                let result:ServiceSingleResultOfPhysicalMemory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPhysicalMemory,
		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPhysicalMemory> {
    
    		let variables: FirstPhysicalMemoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPhysicalMemoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPhysicalMemory,
		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemory> {
    
    		let variables: FindPhysicalMemoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPhysicalMemory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemory> {
    
    		let variables: SearchPhysicalMemoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPhysicalMemoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPhysicalMemoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPhysicalMemory,
		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemory> {
    
    		let variables: CustomQueryPhysicalMemoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPhysicalMemory,
		@Args('filter?') filter?: FilterOfPhysicalMemory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemory> {
    
    		let variables: CustomQueryIdPhysicalMemoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPhysicalMemoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPhysicalMemoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPhysicalMemoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemories.exist));
    	}
    
    
}