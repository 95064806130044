import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetStatusesBaseVariables, FirstAssetStatusesBaseVariables, CountAssetStatusesBaseVariables, FindAssetStatusesBaseVariables, SearchAssetStatusesBaseVariables, ExportSchemaAssetStatusesBaseVariables, ExportDataAssetStatusesBaseVariables, CustomQueryAssetStatusesBaseVariables, CustomQueryIdAssetStatusesBaseVariables, UsedAssetStatusesBaseVariables, FindEndLifeStatusAssetStatusesBaseVariables, FindStatusWithoutOutOfParkAssetStatusesBaseVariables, ExistAssetStatusesBaseVariables, FindUnassociatedAssetsAssetStatusesBaseVariables, InsertAssetStatusesBaseVariables, UpdateAssetStatusesBaseVariables, ImportDataAssetStatusesBaseVariables, UpdateManyAssetStatusesBaseVariables, DeleteAssetStatusesBaseVariables, AddAssetsAssetStatusesBaseVariables, RemoveAssetsAssetStatusesBaseVariables } from '../gqls'
import { GetAssetStatusesDocument, FirstAssetStatusesDocument, CountAssetStatusesDocument, FindAssetStatusesDocument, SearchAssetStatusesDocument, ExportSchemaAssetStatusesDocument, ExportDataAssetStatusesDocument, CustomQueryAssetStatusesDocument, CustomQueryIdAssetStatusesDocument, UsedAssetStatusesDocument, FindEndLifeStatusAssetStatusesDocument, FindStatusWithoutOutOfParkAssetStatusesDocument, ExistAssetStatusesDocument, FindUnassociatedAssetsAssetStatusesDocument, InsertAssetStatusesDocument, UpdateAssetStatusesDocument, ImportDataAssetStatusesDocument, UpdateManyAssetStatusesDocument, DeleteAssetStatusesDocument, AddAssetsAssetStatusesDocument, RemoveAssetsAssetStatusesDocument } from '../gqls'
import { ServiceSingleResultOfAssetStatus, QueryContextOfAssetStatus, FilterOfAssetStatus, ServiceSingleResultOfInt64, ServiceListResultOfAssetStatus, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, AssetStatusInput, FieldUpdateOperatorOfAssetStatus } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetStatusBaseService {
    
public modelName = 'assetStatus';
public modelPluralName = 'assetStatuses';

	private getAssetStatusesQuery: GetAssetStatusesDocument;
	private firstAssetStatusesQuery: FirstAssetStatusesDocument;
	private countAssetStatusesQuery: CountAssetStatusesDocument;
	private findAssetStatusesQuery: FindAssetStatusesDocument;
	private searchAssetStatusesQuery: SearchAssetStatusesDocument;
	private exportSchemaAssetStatusesQuery: ExportSchemaAssetStatusesDocument;
	private exportDataAssetStatusesQuery: ExportDataAssetStatusesDocument;
	private customQueryAssetStatusesQuery: CustomQueryAssetStatusesDocument;
	private customQueryIdAssetStatusesQuery: CustomQueryIdAssetStatusesDocument;
	private usedAssetStatusesQuery: UsedAssetStatusesDocument;
	private findEndLifeStatusAssetStatusesQuery: FindEndLifeStatusAssetStatusesDocument;
	private findStatusWithoutOutOfParkAssetStatusesQuery: FindStatusWithoutOutOfParkAssetStatusesDocument;
	private existAssetStatusesQuery: ExistAssetStatusesDocument;
	private findUnassociatedAssetsAssetStatusesQuery: FindUnassociatedAssetsAssetStatusesDocument;
	private insertAssetStatusesMutation: InsertAssetStatusesDocument;
	private updateAssetStatusesMutation: UpdateAssetStatusesDocument;
	private importDataAssetStatusesMutation: ImportDataAssetStatusesDocument;
	private updateManyAssetStatusesMutation: UpdateManyAssetStatusesDocument;
	private deleteAssetStatusesMutation: DeleteAssetStatusesDocument;
	private addAssetsAssetStatusesMutation: AddAssetsAssetStatusesDocument;
	private removeAssetsAssetStatusesMutation: RemoveAssetsAssetStatusesDocument;

	constructor(private injector: Injector) {
		this.getAssetStatusesQuery = this.injector.get(GetAssetStatusesDocument);
		this.firstAssetStatusesQuery = this.injector.get(FirstAssetStatusesDocument);
		this.countAssetStatusesQuery = this.injector.get(CountAssetStatusesDocument);
		this.findAssetStatusesQuery = this.injector.get(FindAssetStatusesDocument);
		this.searchAssetStatusesQuery = this.injector.get(SearchAssetStatusesDocument);
		this.exportSchemaAssetStatusesQuery = this.injector.get(ExportSchemaAssetStatusesDocument);
		this.exportDataAssetStatusesQuery = this.injector.get(ExportDataAssetStatusesDocument);
		this.customQueryAssetStatusesQuery = this.injector.get(CustomQueryAssetStatusesDocument);
		this.customQueryIdAssetStatusesQuery = this.injector.get(CustomQueryIdAssetStatusesDocument);
		this.usedAssetStatusesQuery = this.injector.get(UsedAssetStatusesDocument);
		this.findEndLifeStatusAssetStatusesQuery = this.injector.get(FindEndLifeStatusAssetStatusesDocument);
		this.findStatusWithoutOutOfParkAssetStatusesQuery = this.injector.get(FindStatusWithoutOutOfParkAssetStatusesDocument);
		this.existAssetStatusesQuery = this.injector.get(ExistAssetStatusesDocument);
		this.findUnassociatedAssetsAssetStatusesQuery = this.injector.get(FindUnassociatedAssetsAssetStatusesDocument);
		this.insertAssetStatusesMutation = this.injector.get(InsertAssetStatusesDocument);
		this.updateAssetStatusesMutation = this.injector.get(UpdateAssetStatusesDocument);
		this.importDataAssetStatusesMutation = this.injector.get(ImportDataAssetStatusesDocument);
		this.updateManyAssetStatusesMutation = this.injector.get(UpdateManyAssetStatusesDocument);
		this.deleteAssetStatusesMutation = this.injector.get(DeleteAssetStatusesDocument);
		this.addAssetsAssetStatusesMutation = this.injector.get(AddAssetsAssetStatusesDocument);
		this.removeAssetsAssetStatusesMutation = this.injector.get(RemoveAssetsAssetStatusesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetStatus> {
    
    		let variables: GetAssetStatusesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.get));
            }
            else{
                let result:ServiceSingleResultOfAssetStatus={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetStatus> {
    
    		let variables: FirstAssetStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: FindAssetStatusesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: SearchAssetStatusesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetStatusesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: CustomQueryAssetStatusesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: CustomQueryIdAssetStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.used));
    	}

    	/**  */
    	@InjectArgs
    	public findEndLifeStatus(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: FindEndLifeStatusAssetStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findEndLifeStatusAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.findEndLifeStatus));
    	}

    	/**  */
    	@InjectArgs
    	public findStatusWithoutOutOfPark(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetStatus,
		@Args('filter?') filter?: FilterOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetStatus> {
    
    		let variables: FindStatusWithoutOutOfParkAssetStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findStatusWithoutOutOfParkAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.findStatusWithoutOutOfPark));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetStatusesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetStatusInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetStatus> {
    
    		let variables: InsertAssetStatusesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetStatus> {
    
    		let variables: UpdateAssetStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetStatusesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetStatusesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetStatusesBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetStatusesBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetStatuses.removeAssets));
    	}
    
}