import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ServiceWorkerModule } from '@angular/service-worker';
import { CoreModule } from '@clarilog/core';
import { SharedModule } from '@clarilog/shared';
import { StarRatingModule } from 'angular-star-rating';
import { provideUserIdleConfig } from 'angular-user-idle';
import { NgClickOutsideDirective } from 'ng-click-outside2';
import { environment } from '../environments/environment';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { CoreContentLayoutComponent } from './layouts2/content-layout/content-layout.component';
import { CoreDefaultLayoutComponent } from './layouts2/default-layout/default-layout.component';
import { MobileHeaderComponent } from './layouts2/mobile-layout/mobile-header/mobile-header.component';
import { MobileLayoutComponent } from './layouts2/mobile-layout/mobile-layout.component';
import { MobileTabBarComponent } from './layouts2/mobile-layout/mobile-tab-bar/mobile-tab-bar.component';
import { SharedCoreServicesModule } from './shared-services/shared-core-service.module';
import { SharedServicesModule } from './shared-services/shared-services.module';
import { SharedModule2 } from './shared2';

@NgModule({
  declarations: [
    AppComponent,
    CoreContentLayoutComponent,
    CoreDefaultLayoutComponent,
    MobileLayoutComponent,
    MobileTabBarComponent,
    MobileHeaderComponent,
  ],
  imports: [
    // Angular
    BrowserModule,
    BrowserAnimationsModule,
    HttpClientModule,

    // Module pour fermer les nav en cliquant à l'extérieur
    NgClickOutsideDirective,

    // Core & Shared
    CoreModule,
    //new
    SharedModule2,
    SharedModule2.forRoot(),
    StarRatingModule.forRoot(),

    SharedModule.forRoot(),
    SharedModule,
    // Tous les services disponibles dans les model.json
    SharedServicesModule,
    SharedCoreServicesModule,
    // App
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', {
      enabled: environment.production,
    }),

    // use provideUserIdleConfig(config: UserIdleConfig) instead
    // UserIdleModule.forRoot({
    //   idle: 3600,
    //   timeout: 5,
    //   ping: 120,
    // }),
  ],
  providers: [
    // Used instead of UserIdleModule.forRoot cause deprecated
    provideUserIdleConfig({
      idle: 3600,
      timeout: 5,
      ping: 120,
    }),
  ],
  bootstrap: [AppComponent],
  exports: [MobileHeaderComponent, MobileLayoutComponent],
})
export class AppModule {
  constructor() {}
}
