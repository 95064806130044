import { ExchangeConnectorBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import { Args } from '@clarilog/core/modules/decorators/args-decorator';

@Injectable({ providedIn: 'root' })
@Authorize('administration.calendar-connector')
export class ExchangeConnectorCoreService extends ExchangeConnectorBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Méthode de récupération des calendrier d'un opérateur pour les tasks et interventions */
  public findByTaskOperatorIdAppointments(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('start') start: Date,
    @Args('end') end: Date,
    @Args('operatorId?') operatorId?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    return this.findByOperatorIdAppointments(
      fields,
      start,
      end,
      operatorId,
      extendedVariables,
    );
  }
}
