import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetCostCentersBaseVariables, FirstAssetCostCentersBaseVariables, CountAssetCostCentersBaseVariables, FindAssetCostCentersBaseVariables, SearchAssetCostCentersBaseVariables, ExportSchemaAssetCostCentersBaseVariables, ExportDataAssetCostCentersBaseVariables, CustomQueryAssetCostCentersBaseVariables, CustomQueryIdAssetCostCentersBaseVariables, UsedAssetCostCentersBaseVariables, ExistAssetCostCentersBaseVariables, FindRecyclesAssetCostCentersBaseVariables, CountRecyclesAssetCostCentersBaseVariables, ReadOnlyAssetCostCentersBaseVariables, FindArchivedAssetCostCentersBaseVariables, CountAllAssetCostCentersBaseVariables, FindDynamicPropertyFieldsAssetCostCentersBaseVariables, FindUnassociatedAssetsAssetCostCentersBaseVariables, InsertAssetCostCentersBaseVariables, UpdateAssetCostCentersBaseVariables, ImportDataAssetCostCentersBaseVariables, UpdateManyAssetCostCentersBaseVariables, DeleteAssetCostCentersBaseVariables, RestoreAssetCostCentersBaseVariables, RecycleAssetCostCentersBaseVariables, RestoreArchivedAssetCostCentersBaseVariables, ArchivedAssetCostCentersBaseVariables, AddFileDynamicFieldAssetCostCentersBaseVariables, RemoveFileDynamicFieldAssetCostCentersBaseVariables, AddAssetsAssetCostCentersBaseVariables, RemoveAssetsAssetCostCentersBaseVariables } from '../gqls'
import { GetAssetCostCentersDocument, FirstAssetCostCentersDocument, CountAssetCostCentersDocument, FindAssetCostCentersDocument, SearchAssetCostCentersDocument, ExportSchemaAssetCostCentersDocument, ExportDataAssetCostCentersDocument, CustomQueryAssetCostCentersDocument, CustomQueryIdAssetCostCentersDocument, UsedAssetCostCentersDocument, ExistAssetCostCentersDocument, FindRecyclesAssetCostCentersDocument, CountRecyclesAssetCostCentersDocument, ReadOnlyAssetCostCentersDocument, FindArchivedAssetCostCentersDocument, CountAllAssetCostCentersDocument, FindDynamicPropertyFieldsAssetCostCentersDocument, FindUnassociatedAssetsAssetCostCentersDocument, InsertAssetCostCentersDocument, UpdateAssetCostCentersDocument, ImportDataAssetCostCentersDocument, UpdateManyAssetCostCentersDocument, DeleteAssetCostCentersDocument, RestoreAssetCostCentersDocument, RecycleAssetCostCentersDocument, RestoreArchivedAssetCostCentersDocument, ArchivedAssetCostCentersDocument, AddFileDynamicFieldAssetCostCentersDocument, RemoveFileDynamicFieldAssetCostCentersDocument, AddAssetsAssetCostCentersDocument, RemoveAssetsAssetCostCentersDocument } from '../gqls'
import { ServiceSingleResultOfAssetCostCenter, QueryContextOfAssetCostCenter, FilterOfAssetCostCenter, ServiceSingleResultOfInt64, ServiceListResultOfAssetCostCenter, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAssetCostCenter, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, AssetCostCenterInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetCostCenterBaseService {
    
public modelName = 'assetCostCenter';
public modelPluralName = 'assetCostCenters';

	private getAssetCostCentersQuery: GetAssetCostCentersDocument;
	private firstAssetCostCentersQuery: FirstAssetCostCentersDocument;
	private countAssetCostCentersQuery: CountAssetCostCentersDocument;
	private findAssetCostCentersQuery: FindAssetCostCentersDocument;
	private searchAssetCostCentersQuery: SearchAssetCostCentersDocument;
	private exportSchemaAssetCostCentersQuery: ExportSchemaAssetCostCentersDocument;
	private exportDataAssetCostCentersQuery: ExportDataAssetCostCentersDocument;
	private customQueryAssetCostCentersQuery: CustomQueryAssetCostCentersDocument;
	private customQueryIdAssetCostCentersQuery: CustomQueryIdAssetCostCentersDocument;
	private usedAssetCostCentersQuery: UsedAssetCostCentersDocument;
	private existAssetCostCentersQuery: ExistAssetCostCentersDocument;
	private findRecyclesAssetCostCentersQuery: FindRecyclesAssetCostCentersDocument;
	private countRecyclesAssetCostCentersQuery: CountRecyclesAssetCostCentersDocument;
	private readOnlyAssetCostCentersQuery: ReadOnlyAssetCostCentersDocument;
	private findArchivedAssetCostCentersQuery: FindArchivedAssetCostCentersDocument;
	private countAllAssetCostCentersQuery: CountAllAssetCostCentersDocument;
	private findDynamicPropertyFieldsAssetCostCentersQuery: FindDynamicPropertyFieldsAssetCostCentersDocument;
	private findUnassociatedAssetsAssetCostCentersQuery: FindUnassociatedAssetsAssetCostCentersDocument;
	private insertAssetCostCentersMutation: InsertAssetCostCentersDocument;
	private updateAssetCostCentersMutation: UpdateAssetCostCentersDocument;
	private importDataAssetCostCentersMutation: ImportDataAssetCostCentersDocument;
	private updateManyAssetCostCentersMutation: UpdateManyAssetCostCentersDocument;
	private deleteAssetCostCentersMutation: DeleteAssetCostCentersDocument;
	private restoreAssetCostCentersMutation: RestoreAssetCostCentersDocument;
	private recycleAssetCostCentersMutation: RecycleAssetCostCentersDocument;
	private restoreArchivedAssetCostCentersMutation: RestoreArchivedAssetCostCentersDocument;
	private archivedAssetCostCentersMutation: ArchivedAssetCostCentersDocument;
	private addFileDynamicFieldAssetCostCentersMutation: AddFileDynamicFieldAssetCostCentersDocument;
	private removeFileDynamicFieldAssetCostCentersMutation: RemoveFileDynamicFieldAssetCostCentersDocument;
	private addAssetsAssetCostCentersMutation: AddAssetsAssetCostCentersDocument;
	private removeAssetsAssetCostCentersMutation: RemoveAssetsAssetCostCentersDocument;

	constructor(private injector: Injector) {
		this.getAssetCostCentersQuery = this.injector.get(GetAssetCostCentersDocument);
		this.firstAssetCostCentersQuery = this.injector.get(FirstAssetCostCentersDocument);
		this.countAssetCostCentersQuery = this.injector.get(CountAssetCostCentersDocument);
		this.findAssetCostCentersQuery = this.injector.get(FindAssetCostCentersDocument);
		this.searchAssetCostCentersQuery = this.injector.get(SearchAssetCostCentersDocument);
		this.exportSchemaAssetCostCentersQuery = this.injector.get(ExportSchemaAssetCostCentersDocument);
		this.exportDataAssetCostCentersQuery = this.injector.get(ExportDataAssetCostCentersDocument);
		this.customQueryAssetCostCentersQuery = this.injector.get(CustomQueryAssetCostCentersDocument);
		this.customQueryIdAssetCostCentersQuery = this.injector.get(CustomQueryIdAssetCostCentersDocument);
		this.usedAssetCostCentersQuery = this.injector.get(UsedAssetCostCentersDocument);
		this.existAssetCostCentersQuery = this.injector.get(ExistAssetCostCentersDocument);
		this.findRecyclesAssetCostCentersQuery = this.injector.get(FindRecyclesAssetCostCentersDocument);
		this.countRecyclesAssetCostCentersQuery = this.injector.get(CountRecyclesAssetCostCentersDocument);
		this.readOnlyAssetCostCentersQuery = this.injector.get(ReadOnlyAssetCostCentersDocument);
		this.findArchivedAssetCostCentersQuery = this.injector.get(FindArchivedAssetCostCentersDocument);
		this.countAllAssetCostCentersQuery = this.injector.get(CountAllAssetCostCentersDocument);
		this.findDynamicPropertyFieldsAssetCostCentersQuery = this.injector.get(FindDynamicPropertyFieldsAssetCostCentersDocument);
		this.findUnassociatedAssetsAssetCostCentersQuery = this.injector.get(FindUnassociatedAssetsAssetCostCentersDocument);
		this.insertAssetCostCentersMutation = this.injector.get(InsertAssetCostCentersDocument);
		this.updateAssetCostCentersMutation = this.injector.get(UpdateAssetCostCentersDocument);
		this.importDataAssetCostCentersMutation = this.injector.get(ImportDataAssetCostCentersDocument);
		this.updateManyAssetCostCentersMutation = this.injector.get(UpdateManyAssetCostCentersDocument);
		this.deleteAssetCostCentersMutation = this.injector.get(DeleteAssetCostCentersDocument);
		this.restoreAssetCostCentersMutation = this.injector.get(RestoreAssetCostCentersDocument);
		this.recycleAssetCostCentersMutation = this.injector.get(RecycleAssetCostCentersDocument);
		this.restoreArchivedAssetCostCentersMutation = this.injector.get(RestoreArchivedAssetCostCentersDocument);
		this.archivedAssetCostCentersMutation = this.injector.get(ArchivedAssetCostCentersDocument);
		this.addFileDynamicFieldAssetCostCentersMutation = this.injector.get(AddFileDynamicFieldAssetCostCentersDocument);
		this.removeFileDynamicFieldAssetCostCentersMutation = this.injector.get(RemoveFileDynamicFieldAssetCostCentersDocument);
		this.addAssetsAssetCostCentersMutation = this.injector.get(AddAssetsAssetCostCentersDocument);
		this.removeAssetsAssetCostCentersMutation = this.injector.get(RemoveAssetsAssetCostCentersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCostCenter> {
    
    		let variables: GetAssetCostCentersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.get));
            }
            else{
                let result:ServiceSingleResultOfAssetCostCenter={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCostCenter> {
    
    		let variables: FirstAssetCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: FindAssetCostCentersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: SearchAssetCostCentersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetCostCentersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: CustomQueryAssetCostCentersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: CustomQueryIdAssetCostCentersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetCostCentersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: FindRecyclesAssetCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAssetCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAssetCostCentersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCostCenter,
		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCostCenter> {
    
    		let variables: FindArchivedAssetCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAssetCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAssetCostCentersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetCostCentersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetCostCenterInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCostCenter> {
    
    		let variables: InsertAssetCostCentersBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCostCenter> {
    
    		let variables: UpdateAssetCostCentersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetCostCentersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetCostCentersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAssetCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAssetCostCentersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAssetCostCentersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetCostCentersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetCostCentersBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCostCenters.removeAssets));
    	}
    
}