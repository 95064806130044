import { UrgencyBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs/internal/Observable';
import {
  FilterOfUrgency,
  QueryContextOfUrgency,
  ServiceListResultOfUrgency,
  Sort,
} from '../types';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-urgency')
export class UrgencyCoreService extends UrgencyBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findByQualification(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],   
    @Args('options?') options?: QueryContextOfUrgency,
    @Args('filter?') filter?: FilterOfUrgency,
  ): Observable<ServiceListResultOfUrgency> {
    let filterByQualification: FilterOfUrgency = {
      qualification: { elemMatch: { in: qualification } },
    };
    if (filter != undefined) {
      filterByQualification.and = [filter];
    }
    if (options == undefined) options = {};
    options.sort = [{ rankOrder: Sort.Asc }];
    return this.find(fields, options, filterByQualification);
  }
}
