import { VerifaliaEmailCheckBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
export class VerifaliaEmailCheckCoreService extends VerifaliaEmailCheckBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
  getEmailStatusArray(): Array<{ id: number; value: string }> {
    return Object.keys(VerifaliaEmailStatus)
      .filter((key) => isNaN(Number(key)))
      .map((key) => ({
        id: VerifaliaEmailStatus[key as keyof typeof VerifaliaEmailStatus],
        value: key,
      }));
  }
  getEmailStatusByCode(statusId: number): string {
    // Vérifiez si la valeur existe dans l'enum
    if (VerifaliaEmailStatus[statusId] !== undefined) {
      return VerifaliaEmailStatus[statusId];
    } else {
      return 'Statut inconnu'; // Gérer les cas où la valeur n'existe pas dans l'enum
    }
  }
}

export enum VerifaliaEmailStatus {
  Undefined = 0,
  Success = 1,
  MailboxDoesNotExist = 2,
  CannotConnectToMailserver = 3,
  ServerDoesNotAllowVerification = 4,
  CatchAllDomain = 5,
  MailboxIsFull = 6,
  MailboxTemporarilyUnavailable = 7,
  UnexpectedMailServerError = 8,
  Timeout = 9,
  InvalidEmailAddress = 10,
  DisposableEmailAddress = 11,
  RoleBasedEmailAddress = 12,
  MailServerTemporaryError = 13,
  QuotaExceeded = 14,
  AntiSpamDetected = 15,
  GreyListed = 16,
  MailServerNotAvailable = 17,
  MailboxQuarantined = 18,
  DNSIssue = 19,
  NoMailExchanger = 20,
  InvalidDomainPart = 21,
  InvalidLocalPart = 22,
  TimeoutExceeded = 23,
  SuccessPlusSyntaxError = 24,
  SuccessMinusSyntaxError = 25,
  SuccessButCatchAll = 26,
  SyntaxError = 27,
  DNSIssueWithSuccess = 28,
  TemporaryError = 29,
  UnroutableDomain = 30,
  InvalidDomain = 31,
  RoleAccount = 32,
  Greylisted = 33,
  Undeliverable = 34,
}
