import { Injectable, InjectionToken, Injector, Type } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Router,
  UrlSegment,
} from '@angular/router';
import { GC, GCFactory } from '../gc';

/** Classe permattant la gestion et la sauvegarde des états **/
@Injectable({
  providedIn: 'root',
})
export class StateManagementService {
  currentStateScope: string[];
  state: any = {};
  gc: GC;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
  }
  /** Permet de définir qu'elle est le scope du state et de l'activer */
  public activeState(scope: string[]) {
    if (JSON.stringify(this.currentStateScope) !== JSON.stringify(scope)) {
      this.state = {};
    }
    this.currentStateScope = scope;
  }
  /** Permet de savoir si nous somme sur la bonne route pour appliquer le state */
  private checkPathScope(): boolean {
    let pathScope = location.pathname.split('/');
    pathScope.splice(0, 1);

    /** Ici le state ne renvoit rien car on est dans le meme metier mais pas dans le bon ecran EX: on est dans edit */
    if (JSON.stringify(pathScope) !== JSON.stringify(this.currentStateScope)) {
      if (
        this.currentStateScope != undefined &&
        this.currentStateScope.length > 0 &&
        this.currentStateScope[0] != undefined &&
        this.currentStateScope[0].includes('/')
      ) {
        if (this.currentStateScope[0] === location.pathname) {
          return true;
        } else {
          let currentStateScopeSplit = this.currentStateScope[0].split('/');
          if (
            JSON.stringify(pathScope) !== JSON.stringify(currentStateScopeSplit)
          ) {
            return false;
          }
        }
        return false;
      } else {
        return false;
      }
    }
    return true;
  }
  /** Permet de retourner un état */
  public getState(key: string) {
    if (this.checkPathScope() === true) {
      return this.state[key];
    }
    return null;
  }
  /** Permet de definir un état */

  public setState(key: string, state: any) {
    if (this.checkPathScope() === true) {
      this.state[key] = state;
    }
  }
  /** Permet de supprimer un état */
  public deleteState(key: string) {
    if (this.checkPathScope() === true) {
      delete this.state[key];
    }
  }
}
