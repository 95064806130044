import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInternetExplorersBaseVariables, FirstInternetExplorersBaseVariables, CountInternetExplorersBaseVariables, FindInternetExplorersBaseVariables, SearchInternetExplorersBaseVariables, ExportSchemaInternetExplorersBaseVariables, ExportDataInternetExplorersBaseVariables, CustomQueryInternetExplorersBaseVariables, CustomQueryIdInternetExplorersBaseVariables, UsedInternetExplorersBaseVariables, ExistInternetExplorersBaseVariables } from '../gqls'
import { GetInternetExplorersDocument, FirstInternetExplorersDocument, CountInternetExplorersDocument, FindInternetExplorersDocument, SearchInternetExplorersDocument, ExportSchemaInternetExplorersDocument, ExportDataInternetExplorersDocument, CustomQueryInternetExplorersDocument, CustomQueryIdInternetExplorersDocument, UsedInternetExplorersDocument, ExistInternetExplorersDocument } from '../gqls'
import { ServiceSingleResultOfInternetExplorer, QueryContextOfInternetExplorer, FilterOfInternetExplorer, ServiceSingleResultOfInt64, ServiceListResultOfInternetExplorer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InternetExplorerBaseService {
    
public modelName = 'internetExplorer';
public modelPluralName = 'internetExplorers';

	private getInternetExplorersQuery: GetInternetExplorersDocument;
	private firstInternetExplorersQuery: FirstInternetExplorersDocument;
	private countInternetExplorersQuery: CountInternetExplorersDocument;
	private findInternetExplorersQuery: FindInternetExplorersDocument;
	private searchInternetExplorersQuery: SearchInternetExplorersDocument;
	private exportSchemaInternetExplorersQuery: ExportSchemaInternetExplorersDocument;
	private exportDataInternetExplorersQuery: ExportDataInternetExplorersDocument;
	private customQueryInternetExplorersQuery: CustomQueryInternetExplorersDocument;
	private customQueryIdInternetExplorersQuery: CustomQueryIdInternetExplorersDocument;
	private usedInternetExplorersQuery: UsedInternetExplorersDocument;
	private existInternetExplorersQuery: ExistInternetExplorersDocument;

	constructor(private injector: Injector) {
		this.getInternetExplorersQuery = this.injector.get(GetInternetExplorersDocument);
		this.firstInternetExplorersQuery = this.injector.get(FirstInternetExplorersDocument);
		this.countInternetExplorersQuery = this.injector.get(CountInternetExplorersDocument);
		this.findInternetExplorersQuery = this.injector.get(FindInternetExplorersDocument);
		this.searchInternetExplorersQuery = this.injector.get(SearchInternetExplorersDocument);
		this.exportSchemaInternetExplorersQuery = this.injector.get(ExportSchemaInternetExplorersDocument);
		this.exportDataInternetExplorersQuery = this.injector.get(ExportDataInternetExplorersDocument);
		this.customQueryInternetExplorersQuery = this.injector.get(CustomQueryInternetExplorersDocument);
		this.customQueryIdInternetExplorersQuery = this.injector.get(CustomQueryIdInternetExplorersDocument);
		this.usedInternetExplorersQuery = this.injector.get(UsedInternetExplorersDocument);
		this.existInternetExplorersQuery = this.injector.get(ExistInternetExplorersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInternetExplorer> {
    
    		let variables: GetInternetExplorersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.get));
            }
            else{
                let result:ServiceSingleResultOfInternetExplorer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInternetExplorer,
		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInternetExplorer> {
    
    		let variables: FirstInternetExplorersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInternetExplorersBaseVariables = {
    			filter: filter
    		}
    				return this.countInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInternetExplorer,
		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInternetExplorer> {
    
    		let variables: FindInternetExplorersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInternetExplorer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInternetExplorer> {
    
    		let variables: SearchInternetExplorersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInternetExplorersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInternetExplorersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInternetExplorer,
		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInternetExplorer> {
    
    		let variables: CustomQueryInternetExplorersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInternetExplorer,
		@Args('filter?') filter?: FilterOfInternetExplorer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInternetExplorer> {
    
    		let variables: CustomQueryIdInternetExplorersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInternetExplorersBaseVariables = {
    			ids: ids
    		}
    				return this.usedInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInternetExplorersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInternetExplorersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.internetExplorers.exist));
    	}
    
    
}