import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { CommentaryModelBaseService } from '../service-bases/commentary-model.service-base';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-commentary-model')
export class CommentaryModelCoreService extends CommentaryModelBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient toute les qualification */
  getAllUsageType(): any[] {
    return [
      {
        id: 'affectationUsageType',
        name: TranslateService.get(
          'entities/commentaryModel/affectationUsageType',
        ),
      },
      {
        id: 'statusChangeUsageType',
        name: TranslateService.get(
          'entities/commentaryModel/statusChangeUsageType',
        ),
      },
      {
        id: 'remindAsUsageType',
        name: TranslateService.get(
          'entities/commentaryModel/remindAsUsageType',
        ),
      },
    ];
  }
}
