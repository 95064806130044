import { Injectable } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  AlarmTriggerTypeEnum,
  AuditType,
  DynamicPropertyLocationEnum,
  PhoneEnum,
} from '../types';
import { EnumCoreBaseService } from './enum.service-base';
@Injectable({ providedIn: 'root' })
/** Service Global de gestion des enums */
export class EnumCoreService extends EnumCoreBaseService {
  /** Type de téléphone */
  phoneTypes(): { id: string; label: string }[] {
    let results = Object.values(PhoneEnum).map((m) => {
      let display = TranslateService.get(`enums/phone/${m.toString()}`);
      if (display.startsWith('[')) {
        display = `${m.toString()}`;
      }
      return {
        id: m,
        label: display,
      };
    });
    return results;
  }

  /** Obtient les valeur de l'enum ScanExcludeCategory */
  auditTypeFilter(): any[] {
    return this.toEnum(AuditType, 'auditType').filter(
      (x) => x.id != 'UNKNOW' && x.id != 'NONE',
    );
  }

  resolutionModelType(): any[] {
    return [
      {
        id: 'public',
        name: TranslateService.get('enums/resolutionType/public'),
      },
      {
        id: 'private',
        name: TranslateService.get('enums/resolutionType/private'),
      },
    ];
  }

  alarmTrigger(): any[] {
    return this.toEnum(AlarmTriggerTypeEnum, 'alarmTriggerTypeEnum').filter(
      (x) => x.id != 'TASKS',
    );
  }

  emailModelType(): any[] {
    return [
      {
        id: 'CUSTOMPASSWORD',
        value: TranslateService.get('entities/user/customPassword'),
      },
      {
        id: 'WITHOUTPASSWORD',
        value: TranslateService.get('entities/user/withoutPassword'),
      },
      {
        id: 'WITHOUTEMAIL',
        value: TranslateService.get('entities/user/withoutEmail'),
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  formatDateWithoutHour(): any[] {
    return [
      {
        id: 'yyyy/MM/dd',
        value: 'yyyy/MM/dd',
      },
      {
        id: 'dd/MM/yyyy',
        value: 'dd/MM/yyyy',
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  formatDateWithHour(): any[] {
    return [
      {
        id: 'yyyy/MM/dd HH:mm:ss',
        value: 'yyyy/MM/dd HH:mm:ss',
      },
      {
        id: 'dd/MM/yyyy HH:mm:ss',
        value: 'dd/MM/yyyy HH:mm:ss',
      },
    ].sort((a, b) => {
      return a.value.localeCompare(b.value);
    });
  }

  /** Obtient la liste des dynamicPropertyLocationEnum trié par position */
  dynamicPropertyLocationSort(): any[] {
    let sort = this.dynamicPropertyLocationEnum();

    let finalSort = [];
    finalSort.push(
      sort.find((d) => d.id == DynamicPropertyLocationEnum.Before),
    );
    finalSort.push(
      sort.find((d) => d.id == DynamicPropertyLocationEnum.Center),
    );
    finalSort.push(sort.find((d) => d.id == DynamicPropertyLocationEnum.After));
    finalSort.push(sort.find((d) => d.id == DynamicPropertyLocationEnum.Full));
    return finalSort;
  }
}
