import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfTicketTaskModel, FilterOfTicketTaskModel, QueryContextOfTicketTaskModel, ServiceSingleResultOfInt64, ServiceListResultOfTicketTaskModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, FilterOfOperatorTeam, QueryContextOfOperatorTeam, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketTaskModel, TicketTaskModelInput } from '../types'

export type GetTicketTaskModelsResultType = {
    ticketTaskModels: {
        get?: Maybe<ServiceSingleResultOfTicketTaskModel>
    }
}

export type GetTicketTaskModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getTicketTaskModels ${args} {
    ticketTaskModels {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstTicketTaskModelsResultType = {
    ticketTaskModels: {
        first?: Maybe<ServiceSingleResultOfTicketTaskModel>
    }
}

export type FirstTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstTicketTaskModels ${args} {
    ticketTaskModels {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountTicketTaskModelsResultType = {
    ticketTaskModels: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countTicketTaskModels ${args} {
    ticketTaskModels {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTicketTaskModelsResultType = {
    ticketTaskModels: {
        find?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type FindTicketTaskModelsBaseVariables = {
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfTicketTaskModel = null, $filter: FilterOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTicketTaskModels ${args} {
    ticketTaskModels {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchTicketTaskModelsResultType = {
    ticketTaskModels: {
        search?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type SearchTicketTaskModelsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchTicketTaskModels ${args} {
    ticketTaskModels {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaTicketTaskModelsResultType = {
    ticketTaskModels: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaTicketTaskModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaTicketTaskModels ${args} {
    ticketTaskModels {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataTicketTaskModelsResultType = {
    ticketTaskModels: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataTicketTaskModels ${args} {
    ticketTaskModels {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryTicketTaskModelsResultType = {
    ticketTaskModels: {
        customQuery?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type CustomQueryTicketTaskModelsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryTicketTaskModels ${args} {
    ticketTaskModels {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdTicketTaskModelsResultType = {
    ticketTaskModels: {
        customQueryId?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type CustomQueryIdTicketTaskModelsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdTicketTaskModels ${args} {
    ticketTaskModels {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsTicketTaskModelsResultType = {
    ticketTaskModels: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsTicketTaskModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsTicketTaskModels ${args} {
    ticketTaskModels {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsResultType = {
    ticketTaskModels: {
        findAssociatedOperatorTeamSecurityGroups?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les equipes d'operateur via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamSecurityGroupsTicketTaskModels ${args} {
    ticketTaskModels {
        findAssociatedOperatorTeamSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedTicketTaskModelsResultType = {
    ticketTaskModels: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedTicketTaskModelsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedTicketTaskModels ${args} {
    ticketTaskModels {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByOperatorTeamTicketTaskModelsResultType = {
    ticketTaskModels: {
        findByOperatorTeam?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type FindByOperatorTeamTicketTaskModelsBaseVariables = {
	qualification: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindByOperatorTeamTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: [String] = null, $filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByOperatorTeamTicketTaskModels ${args} {
    ticketTaskModels {
        findByOperatorTeam(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByOperatorTeamTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByOperatorTeamTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByQualificationTicketTaskModelsResultType = {
    ticketTaskModels: {
        findByQualification?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type FindByQualificationTicketTaskModelsBaseVariables = {
	qualification: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindByQualificationTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$qualification: [String] = null, $filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByQualificationTicketTaskModels ${args} {
    ticketTaskModels {
        findByQualification(qualification: $qualification, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByQualificationTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByQualificationTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOperatorTicketTaskModelsResultType = {
    ticketTaskModels: {
        findOperator?: Maybe<ServiceListResultOfUser>
    }
}

export type FindOperatorTicketTaskModelsBaseVariables = {
	operatorTeamId: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindOperatorTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$operatorTeamId: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOperatorTicketTaskModels ${args} {
    ticketTaskModels {
        findOperator(operatorTeamId: $operatorTeamId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOperatorTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOperatorTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOperatorTeamTicketTaskModelsResultType = {
    ticketTaskModels: {
        findOperatorTeam?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindOperatorTeamTicketTaskModelsBaseVariables = {
	operatorId: Maybe<string>, /**  */
	filter: Maybe<FilterOfOperatorTeam>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOperatorTeam>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindOperatorTeamTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$operatorId: Uuid = null, $filter: FilterOfOperatorTeam = null, $options: QueryContextOfOperatorTeam = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOperatorTeamTicketTaskModels ${args} {
    ticketTaskModels {
        findOperatorTeam(operatorId: $operatorId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOperatorTeamTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOperatorTeamTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistTicketTaskModelsResultType = {
    ticketTaskModels: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistTicketTaskModelsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existTicketTaskModels ${args} {
    ticketTaskModels {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesTicketTaskModelsResultType = {
    ticketTaskModels: {
        findRecycles?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type FindRecyclesTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesTicketTaskModels ${args} {
    ticketTaskModels {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesTicketTaskModelsResultType = {
    ticketTaskModels: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesTicketTaskModels ${args} {
    ticketTaskModels {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyTicketTaskModelsResultType = {
    ticketTaskModels: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyTicketTaskModelsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyTicketTaskModels ${args} {
    ticketTaskModels {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedTicketTaskModelsResultType = {
    ticketTaskModels: {
        findArchived?: Maybe<ServiceListResultOfTicketTaskModel>
    }
}

export type FindArchivedTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicketTaskModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null, $options: QueryContextOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedTicketTaskModels ${args} {
    ticketTaskModels {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllTicketTaskModelsResultType = {
    ticketTaskModels: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllTicketTaskModelsBaseVariables = {
	filter: Maybe<FilterOfTicketTaskModel>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllTicketTaskModels ${args} {
    ticketTaskModels {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsTicketTaskModelsResultType = {
    ticketTaskModels: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsTicketTaskModelsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfTicketTaskModel>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsTicketTaskModels ${args} {
    ticketTaskModels {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertTicketTaskModelsResultType = {
    ticketTaskModels: {
        insert?: Maybe<ServiceSingleResultOfTicketTaskModel>
    }
}

export type InsertTicketTaskModelsBaseVariables = {
	entity: TicketTaskModelInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: TicketTaskModelInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertTicketTaskModels ${args} {
    ticketTaskModels {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateTicketTaskModelsResultType = {
    ticketTaskModels: {
        update?: Maybe<ServiceSingleResultOfTicketTaskModel>
    }
}

export type UpdateTicketTaskModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfTicketTaskModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateTicketTaskModels ${args} {
    ticketTaskModels {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfTicketTaskModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataTicketTaskModelsResultType = {
    ticketTaskModels: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataTicketTaskModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataTicketTaskModels ${args} {
    ticketTaskModels {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyTicketTaskModelsResultType = {
    ticketTaskModels: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfTicketTaskModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfTicketTaskModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyTicketTaskModels ${args} {
    ticketTaskModels {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteTicketTaskModelsResultType = {
    ticketTaskModels: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteTicketTaskModels ${args} {
    ticketTaskModels {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupTicketTaskModelsResultType = {
    ticketTaskModels: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupTicketTaskModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupTicketTaskModels ${args} {
    ticketTaskModels {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupTicketTaskModelsResultType = {
    ticketTaskModels: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupTicketTaskModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupTicketTaskModels ${args} {
    ticketTaskModels {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorTeamSecurityGroupTicketTaskModelsResultType = {
    ticketTaskModels: {
        addOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorTeamSecurityGroupTicketTaskModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorTeamSecurityGroupTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorTeamSecurityGroupTicketTaskModels ${args} {
    ticketTaskModels {
        addOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorTeamSecurityGroupTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorTeamSecurityGroupTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorTeamSecurityGroupTicketTaskModelsResultType = {
    ticketTaskModels: {
        removeOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorTeamSecurityGroupTicketTaskModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorTeamSecurityGroupTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorTeamSecurityGroupTicketTaskModels ${args} {
    ticketTaskModels {
        removeOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorTeamSecurityGroupTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorTeamSecurityGroupTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreTicketTaskModelsResultType = {
    ticketTaskModels: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreTicketTaskModels ${args} {
    ticketTaskModels {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleTicketTaskModelsResultType = {
    ticketTaskModels: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleTicketTaskModels ${args} {
    ticketTaskModels {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedTicketTaskModelsResultType = {
    ticketTaskModels: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedTicketTaskModels ${args} {
    ticketTaskModels {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedTicketTaskModelsResultType = {
    ticketTaskModels: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedTicketTaskModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedTicketTaskModels ${args} {
    ticketTaskModels {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldTicketTaskModelsResultType = {
    ticketTaskModels: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldTicketTaskModelsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldTicketTaskModels ${args} {
    ticketTaskModels {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldTicketTaskModelsResultType = {
    ticketTaskModels: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldTicketTaskModelsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldTicketTaskModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldTicketTaskModels ${args} {
    ticketTaskModels {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldTicketTaskModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldTicketTaskModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
