<div class="list-component" #myList>
  <div class="cl-click-to-up" *ngIf="showTop" (click)="onTopGrid($event)">
    <i class="fa-solid fa-arrow-up"></i>
  </div>

  <div
    class="cl-click-count-grid"
    *ngIf="type === 'Grid' && showTotalCount === true && !compactMode"
    (click)="onCount($event)"
  >
    <div class="loader" *ngIf="inProgressCount === true"></div>
    <i class="fa-duotone fa-sigma"></i
    ><span>{{
      totalCount >= 0
        ? totalCount + ' ' + ('globals/items' | translate)
        : ('globals/count' | translate) + (inProgressCount ? '...' : '')
    }}</span>
  </div>

  <div
    class="cl-old-layout"
    *ngIf="type === 'Grid' && useOldLayout && !compactMode"
  >
    <i class="fa-regular fa-triangle-exclamation"></i
    ><span>{{ 'old-layout-version' | translate }} </span>
  </div>

  <dx-popover
    class="cl-popover"
    [width]="'auto'"
    [(visible)]="showAtlasIndex"
    [position]="{
      my: 'right top',
      at: 'right bottom',
      of: atlasButtonIndexes,
      collision: {
        x: 'flip',
        y: 'flip'
      }
    }"
  >
    <dx-list
      width="200px"
      [items]="atlasIndexes"
      displayExpr="value"
      keyExpr="field"
      selectionMode="all"
      selectAllMode="page"
      [showSelectionControls]="true"
      [(selectedItemKeys)]="selectedAtlasIndex"
    >
    </dx-list>
  </dx-popover>

  <dx-data-grid
    class="grid"
    *ngIf="type === 'Grid' && columns != undefined"
    [dataSource]="internalSource"
    [keyExpr]="isDataSourceArray ? keyExpr : undefined"
    (onInitialized)="onInitialized($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onContentReady)="onContentReadyGrid($event)"
    (onRowClick)="onRowClicked($event)"
    (onDataErrorOccurred)="onDataErrorOccurred($event)"
    (onSelectionChanged)="selectionChanged($event, masterDetail)"
    [rowAlternationEnabled]="true"
    (onCellPrepared)="onCellPrepared($event)"
    (onOptionChanged)="onOptionChanged($event)"
    (onEditingStart)="onEditingStart($event)"
    (onExporting)="onExporting($event)"
    [masterDetail]="{
      enabled: masterDetail != undefined,
      template: 'detail',
      autoExpandAll:
        masterDetail?.openMode != undefined && masterDetail?.openMode == 'all'
          ? true
          : false
    }"
    [selection]="{
      mode: multiple ? 'multiple' : 'single',
      showCheckBoxesMode: canSelect ? 'always' : 'none',
      deferred: false
    }"
    [showColumnHeaders]="!compactMode"
    [hoverStateEnabled]="true"
    [selectedRowKeys]="selectedKeys"
    [remoteOperations]="
      isDataSourceArray
        ? undefined
        : {
            paging: true,
            filtering: true,
            sorting: true,
            grouping: false,
            groupPaging: false,
            summary: false
          }
    "
    height="100%"
    [showBorders]="false"
    [showRowLines]="false"
    (onSelectionChanged)="onSelectionChanged($event)"
    [columnAutoWidth]="true"
    [columnMinWidth]="80"
    columnResizingMode="widget"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [searchPanel]="{
      visible:
        allowSearchPanel &&
        !(atlasIndexes != undefined && atlasIndexes.length > 0),
      width: 200,
      placeholder: 'globals/search' | translate
    }"
    [columnChooser]="{
      enabled: allowSelectColsPanel && !compactMode,
      sortOrder: 'asc'
    }"
    [paging]="{
      pageSize: pageSize
    }"
    [pager]="{
      showPageSizeSelector: false,
      showNavigationButtons: true,
      allowedPageSizes: [pageSize],
      showInfo: true,
      visible: false
    }"
    [groupPanel]="{
      visible: !compactMode
    }"
    [grouping]="{
      contextMenuEnabled: true,
      autoExpandAll: false
    }"
    [sorting]="{
      mode: 'multiple'
    }"
    [export]="{
      enabled: allowExportPanel && !compactMode,
      allowExportSelectedData: true,
      fileName: 'One by ClariLog'
    }"
    [filterRow]="{
      visible: !compactMode
    }"
    [stateStoring]="{
      enable: !compactMode,
      type: 'custom'
    }"
    [filterBuilder]="{
      allowHierarchicalFields: true,
      customOperations: customOperations
    }"
    [filterSyncEnabled]="true"
  >
    <dxo-state-storing
      [enabled]="!compactMode"
      type="custom"
      [customLoad]="loadStoring"
      [customSave]="saveStoring"
      [savingTimeout]="200"
    >
    </dxo-state-storing>

    <dxo-editing
      mode="cell"
      [allowUpdating]="allowUpdate != undefined ? allowUpdate : false"
      [allowDeleting]="allowDelete != undefined ? allowDelete : false"
      useIcons="true"
    >
    </dxo-editing>
    <dxo-row-dragging
      [allowDropInsideItem]="dragAndDrop"
      [onDragStart]="onDragStart"
      [onDragChange]="onDragChange"
      [onReorder]="onReorder"
    ></dxo-row-dragging>

    <dxo-filter-panel
      [visible]="allowFilterPanel && !compactMode"
      [customizeText]="customizeTextFilterPanel"
    ></dxo-filter-panel>
    <dxo-filter-builder [allowHierarchicalFields]="false"></dxo-filter-builder>

    <dxo-header-filter [visible]="false"></dxo-header-filter>

    <dxo-scrolling useNative="true" mode="virtual"></dxo-scrolling>
    <!-- calculateSortValue pour corrigé le souci de trie sur les .data -->
    <dxi-column
      *ngFor="let column of columns"
      [dataField]="column.field"
      [calculateSortValue]="
        column.field != undefined ? replaceField(column) : ''
      "
      [calculateGroupValue]="column?.customGrouping == true ? customColumnGrouping:''"
      [calculateFilterExpression]="
        column.field != undefined ? calculateFilterExpressionGrid : undefined
      "
      [caption]="column.label"
      [format]="column.format"
      [name]="column.label + column.field"
      [dataType]="
        column.dataType != undefined
          ? column.dataType
          : column.format == undefined && column.template == undefined
          ? 'string'
          : undefined
      "
      [cssClass]="column.customEditing === true ? 'cell-highlighted' : ''"
      [groupIndex]="column.groupIndex"
      [allowSearch]="
        (column.field != undefined &&
          column.field.endsWith != undefined &&
          (column.field.endsWith('Id') ||
            column.dataType === 'boolean' ||
            column.template === 'arrayEnumTemplate' ||
            column.template === 'arrayTemplate' ||
            column.visible === false)) ||
        column.allowFiltering === false ||
        column.showInColumnChooser === false
          ? false
          : true
      "
      [fixed]="column.fixed"
      [width]="column.template == 'editingTemplate' ? 50 : column.width"
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortOrder"
      [minWidth]="column.template == 'editingTemplate' ? 50 : column.minWidth"
      [allowResizing]="column.allowResizing"
      [allowExporting]="
        column.allowExporting != undefined ? column.allowExporting : true
      "
      [fixedPosition]="column.fixedPosition"
      [filterValue]="column.filterValue"
      [visible]="column.visible != undefined ? column.visible : true"
      [allowSorting]="
        column.template == 'arrayTemplate' ? false : column.allowSorting
      "
      [allowFiltering]="
        column.headerFilterLookup != undefined
          ? false
          : column.template == 'arrayTemplate'
          ? true
          : column.allowFiltering != undefined
          ? column.allowFiltering
          : column.showInColumnChooser === false
          ? false
          : undefined
      "
      [allowHeaderFiltering]="
        column.showInColumnChooser !== false &&
        column.allowFiltering !== false &&
        column.headerFilterLookup !== undefined
      "
      [showInColumnChooser]="
        column.showInColumnChooser != undefined
          ? column.showInColumnChooser
          : true
      "
      [allowGrouping]="column.allowGrouping"
      [allowEditing]="
        column.allowEditing != undefined ? column.allowEditing : false
      "
      [cellTemplate]="
        column.template == undefined && column.dataType == 'boolean'
          ? 'booleanTemplate'
          : column.template != undefined
          ? column.template
          : column.field == '__typename'
          ? column.link == true
            ? 'linkTypeNameTemplate'
            : 'typeNameTemplate'
          : column.link == true
          ? 'underlineTemplate'
          : column.template == undefined &&
            column.dataType == undefined &&
            column.dataType == 'boolean'
          ? 'customCellTemplate'
          : ''
      "
      [groupCellTemplate]="
        column.groupTemplate != undefined
          ? column.groupTemplate
          : column.field == '__typename'
          ? 'typeNameTemplate'
          : 'groupCellTemplate'
      "
      [headerCellTemplate]="
        column.headerFilterLookup !== undefined
          ? 'headerTemplate'
          : column.hintDisplay !== undefined
          ? 'headerHint'
          : undefined
      "
    >
      <div *dxTemplate="let data of 'headerHint'">
        <span>
          {{ data.column.caption }}
          <i
            class="fa-light fa-circle-info cl-header-hint"
            (click)="displayHint(data.column)"
          >
          </i></span
        >&nbsp;&nbsp;
      </div>

      <div *dxTemplate="let data of 'headerTemplate'">
        {{ data.column.caption }}
        <dx-button
          [ngClass]="
            data?.column?.filterValues !== undefined &&
            data?.column?.filterValues.length > 0
              ? 'cl-filter-button cl-filter-button-active'
              : 'cl-filter-button'
          "
          [ngStyle]="{
            'left.px': data?.cellElement?.offsetLeft,
            'min-width.px': data?.cellElement?.offsetWidth
          }"
          icon="filter"
          (onClick)="filterPopupClick($event, data.column)"
        ></dx-button>
      </div>

      <!-- <dxo-header-filter
        [allowSearch]="true"
        [dataSource]="column.headerFilterLookup"
        [groupInterval]="groupInterval"
      >
      </dxo-header-filter> -->

      <dxo-lookup
        *ngIf="column.lookup != undefined"
        [dataSource]="column.lookup.source"
        [valueExpr]="column.lookup.valueExpr"
        [displayExpr]="column.lookup.displayExpr"
      >
      </dxo-lookup>

      <dxi-validation-rule
        *ngIf="
          column.dataType != 'boolean' && column.validator == 'customMethod'
        "
        type="async"
        [ignoreEmptyValue]="true"
        [reevaluate]="true"
        [validationCallback]="customValidator"
        [message]="column.validatorErrorDisplay"
      >
      </dxi-validation-rule>
    </dxi-column>

    <div *dxTemplate="let condition of 'numberBoxTemplate'">
      <dx-number-box
        #numberBox
        [value]="condition.value == undefined ? 0 : condition.value[0][0]"
        (onValueChanged)="
          condition.setValue([[$event.value, typeSelectBox.value]])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> en </span>
      <dx-select-box
        #typeSelectBox
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumTimeType"
        [value]="condition.value == undefined ? 'days' : condition.value[0][1]"
        (onValueChanged)="condition.setValue([[numberBox.value, $event.value]])"
      ></dx-select-box>
    </div>

    <div *dxTemplate="let condition of 'betweenNumberBoxTemplate'">
      <dx-number-box
        #startNumberBox
        [value]="condition.value == undefined ? 0 : condition.value[0][0]"
        (onValueChanged)="
          condition.setValue([
            [$event.value, endNumberBox.value, typeSelectBox.value]
          ])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> et </span>
      <dx-number-box
        #endNumberBox
        [value]="condition.value == undefined ? 0 : condition.value[0][1]"
        (onValueChanged)="
          condition.setValue([
            [startNumberBox.value, $event.value, typeSelectBox.value]
          ])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> en </span>
      <dx-select-box
        #typeSelectBox
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumTimeType"
        [value]="condition.value == undefined ? 'days' : condition.value[0][2]"
        (onValueChanged)="
          condition.setValue([
            [startNumberBox.value, endNumberBox.value, $event.value]
          ])
        "
      ></dx-select-box>
    </div>

    <div *dxTemplate="let condition of 'defaultTimeTemplate'">
      <dx-select-box
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumDefaultTime"
        [value]="condition.value"
        (onValueChanged)="condition.setValue($event.value)"
      ></dx-select-box>
    </div>

    <div *dxTemplate="let condition of 'sizeGoTemplate'">
      <dx-number-box
        #numberBox
        [value]="condition.value / 1024 / 1024 / 1024"
        (onValueChanged)="condition.setValue($event.value * 1024 * 1024 * 1024)"
        width="60"
      ></dx-number-box>
    </div>

    <!-- Définition des master Detail -->
    <div class="cl-tab-header" *dxTemplate="let itemRow of 'detail'">
      <div [ngSwitch]="masterDetail.rowTemplate">
        <div *ngSwitchCase="'note'">
          <ng-template
            *ngTemplateOutlet="
              templateRowService.get(masterDetail.rowTemplate);
              context: { $implicit: itemRow.data }
            "
          >
          </ng-template>
        </div>
        <div *ngSwitchDefault>
          <dx-tab-panel
            #tabPanel
            [selectedIndex]="0"
            [height]="'100%'"
            [dataSource]="masterDetail"
            [loop]="false"
            [animationEnabled]="false"
            [swipeEnabled]="false"
            [showNavButtons]="false"
          >
            <div *dxTemplate="let item of 'title'">
              <i *ngIf="item.icon != undefined" [class]="item.icon"></i>
              <span>{{ item.label }}</span>
            </div>

            <div *dxTemplate="let item of 'item'">
              <dx-data-grid
                [showBorders]="true"
                [columnAutoWidth]="true"
                [showColumnHeaders]="
                  item.control.showColumnHeaders != undefined
                    ? item.control.showColumnHeaders
                    : true
                "
                [dataSource]="masterDataSource(itemRow.data.id, item)"
                [ngClass]="
                  masterDetail.rowTemplate !== undefined
                    ? masterDetail.rowTemplate ===
                      'ticketDescriptionMasterDetail'
                      ? 'ticket-description-master-detail-column'
                      : ''
                    : ''
                "
              >
                <dxi-column
                  *ngFor="let column of item.control.columns"
                  [dataField]="column.field"
                  [calculateSortValue]="column.field.replace('.data.', '.')"
                  [caption]="column.label"
                  [name]="column.label + column.field"
                  [format]="column.format"
                  [dataType]="column.dataType"
                  [width]="column.width"
                  [groupIndex]="column.groupIndex"
                  [fixed]="column.fixed"
                  [sortIndex]="column.sortIndex"
                  [sortOrder]="column.sortOrder"
                  [fixedPosition]="column.fixedPosition"
                  [filterValue]="column.filterValue"
                  [selectedFilterOperation]="
                    column.selectedFilterOperation != undefined
                      ? convertToOperator(column.selectedFilterOperation)
                      : undefined
                  "
                  [visible]="
                    column.visible != undefined ? column.visible : true
                  "
                  [showInColumnChooser]="
                    column.showInColumnChooser != undefined
                      ? column.showInColumnChooser
                      : true
                  "
                  [cellTemplate]="
                    column.template == undefined && column.dataType == 'boolean'
                      ? 'booleanTemplate'
                      : column.template != undefined
                      ? column.template
                      : column.field == '__typename'
                      ? 'typeNameTemplate'
                      : ''
                  "
                  [groupCellTemplate]="
                    column.groupTemplate != undefined
                      ? column.groupTemplate
                      : column.field == '__typename'
                      ? 'typeNameTemplate'
                      : ''
                  "
                >
                  <dxo-lookup
                    *ngIf="column.lookup != undefined"
                    [dataSource]="column.lookup.source"
                    [valueExpr]="column.lookup.valueExpr"
                    [displayExpr]="column.lookup.displayExpr"
                  >
                  </dxo-lookup>
                </dxi-column>

                <ng-container
                  *ngFor="
                    let columnMasterDetails of item.control.columns
                      | filter : 'template' : undefined : true
                  "
                >
                  <div
                    *dxTemplate="
                      let dataMasterDetails of columnMasterDetails.template
                    "
                    [ngClass]="getRowClass(dataMasterDetails.data)"
                  >
                    <ng-template
                      *ngTemplateOutlet="
                        templateService.get(columnMasterDetails.template);
                        context: {
                          $implicit: dataMasterDetails,
                          column: columnMasterDetails
                        }
                      "
                    >
                    </ng-template>
                  </div>
                </ng-container>
              </dx-data-grid>
            </div>
          </dx-tab-panel>
        </div>
      </div>
    </div>

    <!-- Template spécifique pour les zone de type booelan -->
    <div *dxTemplate="let data of 'booleanTemplate'">
      <i
        *ngIf="data.value"
        style="color: var(--primary)"
        class="far fa-check"
      ></i>
    </div>

    <!-- Template de base groupCellTemplate default -->

    <!-- <div *dxTemplate="let data of 'groupCellTemplate'">
      <p>{{ data.displayValue }} ({{ data.summaryItems[0].value }})</p>
    </div> -->

    <div *dxTemplate="let data of 'underlineTemplate'">
      <a
        href="{{ href(data) }}"
        (auxclick)="underlineAux($event, data); (false)"
        (click)="underline($event, data); (false)"
        *ngIf="withoutHref === false"
        >{{ data.value }}</a
      >
      <a
        style="text-decoration: underline; cursor: pointer"
        (auxclick)="underlineAux($event, data); (false)"
        (click)="underline($event, data); (false)"
        *ngIf="withoutHref === true"
        >{{ data.value }}</a
      >
    </div>

    <!-- Template de base groupCellTemplate -->
    <div *dxTemplate="let data of 'groupCellTemplate'">
      <p style="margin: 0px">
        {{ data.text }} ({{ data.summaryItems[0].value }})
      </p>
    </div>

    <!-- Template de base isGreyList -->
    <div
      *dxTemplate="let data of 'customCellTemplate'"
      [ngClass]="getRowClass(data.data)"
    >
      {{ data.value }}
    </div>

    <!-- Application des différents templates -->
    <ng-container
      *ngFor="let column of columns | filter : 'template' : undefined : true"
    >
      <div *dxTemplate="let data of column.template">
        <div
          [ngSwitch]="data.column.cellTemplate"
          [ngClass]="getRowClass(data.data)"
        >
          <div *ngSwitchCase="'editingTemplate'">
            <a
              style="padding-left: 10px"
              class="cl-no-click"
              target="_blank"
              [attr.href]="getLink(data.data)"
              ><i class="fas fa-pencil-alt"></i
            ></a>
          </div>

          <div style="text-align: center" *ngSwitchCase="'openSubFormTemplate'">
            <a
              *ngIf="checkPermissionToRead === true && withoutHref === false"
              href="{{ href(data) }}"
              (auxclick)="underlineAux($event, data); (false)"
              (click)="underline($event, data); (false)"
            >
              <i class="fas fa-folder-open"></i>
            </a>
            <a
              *ngIf="checkPermissionToRead === true && withoutHref === true"
              (auxclick)="underlineAux($event, data); (false)"
              (click)="underline($event, data); (false)"
            >
              <i class="fas fa-folder-open"></i>
            </a>
          </div>

          <div
            style="text-align: center"
            *ngSwitchCase="'openPrintSubFormTemplate'"
          >
            <a
              *ngIf="checkValidator(data?.data) === true"
              href="{{ href(data) }}"
              (auxclick)="underlineAux($event, data); (false)"
              (click)="underline($event, data); (false)"
            >
              <i class="fas fa-folder-open"></i>
            </a>
          </div>

          <div style="text-align: center" *ngSwitchCase="'entityPropertyName'">
            {{ getPropertyNameTemplate(data.value) }}
          </div>

          <div *ngSwitchCase="'articleKnowledgeTemplate'">
            <a
              class="cl-no-click"
              href="javascript:void(0)"
              (click)="openArticleKnowledge(data?.data?.id)"
            >
              {{ data?.value }}
            </a>
          </div>

          <div *ngSwitchDefault>
            <ng-template
              style="background-color: red !important"
              *ngTemplateOutlet="
                templateService.get(column.template);
                context: { $implicit: data, column: column }
              "
            >
            </ng-template>
          </div>
        </div>
      </div>
    </ng-container>
    <!-- Temps template array-->

    <!-- Application des différents templates pour les row -->
    <!-- <ng-container
      *ngIf="rowTemplate != undefined "
    >    <tbody *dxTemplate="let template of rowTemplate" >
          <clc-debug [value]="'data'"></clc-debug>
          <clc-debug [value]="template"></clc-debug>

              <ng-template
                *ngTemplateOutlet="
                  templateRowService.get(rowTemplate);
                  context: { $implicit: template.data }
                "
              >
              </ng-template>
          </tbody>


    </ng-container> -->
    <!-- Temps template array-->

    <div *dxTemplate="let data of 'arrayTemplate'">
      <ng-template
        *ngTemplateOutlet="
          templateService.get('arrayTemplate');
          context: { $implicit: data }
        "
      >
      </ng-template>
    </div>
    <!-- Application des différents templates pour les groupe -->
    <ng-container
      *ngFor="
        let column of columns | filter : 'groupTemplate' : undefined : true
      "
    >
      <div *dxTemplate="let data of column.groupTemplate">
        <ng-template
          *ngTemplateOutlet="
            templateService.get(column.groupTemplate);
            context: { $implicit: data, column: column }
          "
        >
        </ng-template>
      </div>
    </ng-container>

    <!-- Totalise le nombre d'élement sélectionné -->
    <div *dxTemplate="let data of 'selectedCount'">
      <span
        class="cl-selected-count"
        *ngIf="selectedKeys != undefined && selectedKeys.length > 0"
        class="cl-selected-count"
      >
        {{ selectedKeys.length }}
        {{ 'globals/toolBarSelectedCount' | translate }}</span
      >
    </div>

    <!-- Application des différents templates pour les la colonne de type __typename -->
    <div *dxTemplate="let data of 'typeNameTemplate'">
      {{
        'entities/' +
          (data.value.charAt(0).toLowerCase() + data.value.slice(1)).replace(
            'Type',
            ''
          ) +
          '/_title/singular' | translate
      }}
      {{
        data?.summaryItems != undefined && data.summaryItems.length > 0
          ? '(' + data.summaryItems[0].value + ')'
          : ''
      }}
    </div>

    <div *dxTemplate="let data of 'linkTypeNameTemplate'">
      <a
        href="{{ href(data) }}"
        (auxclick)="underlineAux($event, data); (false)"
        (click)="underline($event, data); (false)"
        >{{
          'entities/' +
            (data.value.charAt(0).toLowerCase() + data.value.slice(1)).replace(
              'Type',
              ''
            ) +
            '/_title/singular' | translate
        }}</a
      >
    </div>

    <dxo-summary>
      <dxi-group-item column="id" summaryType="count"> </dxi-group-item>
    </dxo-summary>

    <div *dxTemplate="let data of 'clearFilterPopup'">
      <dx-button
        icon="filter"
        [visible]="clearButtonFilterPopup"
        [ngClass]="'cl-filter-button-active'"
        (onClick)="onClearFilterPopup($event)"
      ></dx-button>
    </div>

    <div *dxTemplate="let data of 'searchByAtlasTemplate'">
      <dx-text-box
        [placeholder]="'globals/search' | translate"
        [(value)]="atlasSearchValue"
        [valueChangeEvent]="'keyup'"
        (onValueChanged)="onAtlasValueChanged($event)"
        (onInitialized)="onAtlasInitialized($event)"
        [showClearButton]="true"
        [mode]="'search'"
        [width]="'200px'"
      >
        <!-- <dxi-button
          name="today"
          location="after"
          [options]="atlasOptions"
        ></dxi-button> -->
      </dx-text-box>
      <!-- [validationStatus]="disabledAtlas ? 'pending' : 'valid'" -->
    </div>

    <div *dxTemplate="let data of 'layoutManagerTemplate'">
      <dx-drop-down-box
        *ngIf="isTenant"
        displayExpr="name"
        valueExpr="id"
        [value]="layoutValue && layoutValue[0]"
        [deferRendering]="false"
        [placeholder]="'globals/myCustomView' | translate"
        [dataSource]="
          sourceLayoutManager != undefined
            ? sourceLayoutManager.items()
            : sourceLayoutManager
        "
        [showClearButton]="true"
        [width]="'auto'"
        [dropDownOptions]="{
          width: '520px',
          onShowing: onPopupShowing
        }"
        (onValueChanged)="onLayoutValueChanged($event)"
      >
        <!-- style="margin-bottom: 2px;" -->
        <dxi-button
          name="save"
          location="after"
          [options]="{
            icon: 'fal fa-save',
            disabled: layoutValue && layoutValue[0] == undefined,
            hint: 'save' | translate,
            onClick: onUpdateLayoutClick
          }"
        ></dxi-button>
        <dxi-button
          name="customClear"
          location="after"
          [options]="{
            icon: 'fal fa-times',
            disabled: layoutValue && layoutValue[0] == undefined,
            hint: 'clear' | translate,
            onClick: onClearLayoutClick
          }"
        ></dxi-button>
        <dxi-button location="after" name="dropDown"></dxi-button>
        <div *dxTemplate="let data of 'content'" style="height: 200px">
          <dx-data-grid
            #layoutGrid
            [dataSource]="sourceLayoutManager"
            [columnAutoWidth]="true"
            (onToolbarPreparing)="onLayoutManagerToolbarPreparing($event)"
            [(selectedRowKeys)]="layoutValue"
            [showColumnHeaders]="false"
            [hoverStateEnabled]="true"
            [showRowLines]="false"
            [height]="'200px'"
          >
            <dxo-scrolling mode="virtual"></dxo-scrolling>
            <dxi-column
              dataField="value"
              cellTemplate="errorTemplate"
              caption=""
              width="15"
              [formItem]="{
                visible: false
              }"
            ></dxi-column>
            <dxi-column
              dataField="name"
              [caption]="'entities/layout/name' | translate"
            ></dxi-column>
            <dxi-column type="buttons" [width]="110">
              <dxi-button name="edit"></dxi-button>
              <dxi-button name="delete"></dxi-button>
              <dxi-button template="buttonFavoriteTemplate"></dxi-button>
              <div
                *dxTemplate="let item of 'buttonFavoriteTemplate'"
                style="display: inline-block; width: 16px; height: 16px"
              >
                <a
                  href="javascript:void(0)"
                  class="dx-link"
                  tabindex="-1"
                  (click)="onFavoriteClick(item)"
                >
                  <i
                    class="fa-star"
                    [ngClass]="{
                      fad: item.data.id === favoriteId,
                      fal: item.data.id !== favoriteId
                    }"
                  ></i>
                </a>
              </div>
            </dxi-column>
            <div *dxTemplate="let d of 'errorTemplate'">
              <div *ngIf="d?.data?.value == undefined">
                <i class="fa-solid fa-exclamation" style="color: #f7a636"></i>
              </div>
            </div>
            <dxo-selection [mode]="'single'" [allowSelectAll]="false">
            </dxo-selection>
            <dxo-editing
              mode="popup"
              [allowUpdating]="true"
              [allowDeleting]="true"
            >
              <dxo-popup
                [onShowing]="onShowingPopUp"
                [showTitle]="false"
                [width]="'500px'"
                [height]="'auto'"
                [position]="{ my: 'top', at: 'top', of: window }"
              >
              </dxo-popup>
              <dxo-form [colCount]="1"></dxo-form>
            </dxo-editing>
            <div *dxTemplate="let data of 'buttonNewLayoutTemplate'">
              <dx-button
                [text]="'new' | translate"
                [hint]="'new' | translate"
                icon="fas fa-plus"
                (onClick)="layoutGrid.instance.addRow()"
              >
              </dx-button>
            </div>
            <!-- <div *dxTemplate="let data of 'buttonDeleteLayoutTemplate'">
               <dx-button
                [text]="'delete' | translate"
                icon="fas fa-times"
                [hint]="'delete' | translate"
                [disabled]="
                  layoutGrid.instance.getSelectedRowKeys().length === 0
                "
                (onClick)="onLayoutDeleteClick({ component: layoutGrid })"
              ></dx-button>
            </div> -->
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>
    <!-- A laisser pour la fake column de redimensionnement -->
    <dxi-column type="buttons" [width]="'100%'"> </dxi-column>
  </dx-data-grid>

  <dx-tree-list
    class="tree"
    *ngIf="type === 'Tree'"
    [ngClass]="!useDevExtremSelectAllTree ? 'cl-clarilog-select-all' : ''"
    [dataSource]="internalSource"
    [rowAlternationEnabled]="true"
    [columnAutoWidth]="true"
    [columnMinWidth]="150"
    columnResizingMode="widget"
    [allowColumnResizing]="true"
    [keyExpr]="isDataSourceArray ? keyExpr : undefined"
    [parentIdExpr]="parentIdExpr"
    dataStructure="plain"
    [rootValue]="0"
    height="100%"
    (onRowClick)="onRowClicked($event)"
    (onCellPrepared)="onCellPrepared($event)"
    [hoverStateEnabled]="true"
    (onInitialized)="onInitialized($event)"
    [expandedRowKeys]="expandedRowKeys"
    (onOptionChanged)="onOptionChangedTree($event)"
    (onRowExpanded)="onRowExpandedTreeList($event)"
    (onRowCollapsed)="onRowCollapsedTreeList($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onContentReady)="onContentReadyTreeList($event)"
    (onContextMenuPreparing)="onContextMenuPreparing($event)"
    (onExporting)="onExporting($event)"
    [remoteOperations]="
      isDataSourceArray === true
        ? undefined
        : { filtering: false, sorting: false }
    "
    [showRowLines]="false"
    [showBorders]="false"
    [selectedRowKeys]="selectedKeys"
    (onSelectionChanged)="onSelectionChanged($event)"
    [showColumnHeaders]="!compactMode"
    [searchPanel]="{
      visible: true,
      highlightSearchText: true,
      highlightCaseSensitive: false,
      width: 200,
      placeholder: 'globals/search' | translate
    }"
    [columnChooser]="{ enabled: !compactMode }"
    [paging]="{
      pageSize: 20,
      enabled: false
    }"
    [pager]="{
      showPageSizeSelector: false,
      allowedPageSizes: [500000],
      showInfo: true,
      visible: !compactMode,
      infoText:
        '{2} ' +
        ('items' | translate) +
        (treelistAllSelectKey != undefined && treelistAllSelectKey > 0
          ? ' / ' +
            treelistAllSelectKey +
            ' ' +
            ('globals/toolBarSelectedCount' | translate)
          : '')
    }"
    [filterRow]="{
      visible: !compactMode
    }"
    [filterPanel]="{
      visible: !compactMode
    }"
    [sorting]="{
      mode: 'multiple'
    }"
  >
    <dxo-row-dragging
      *ngIf="dragAndDrop"
      [allowDropInsideItem]="dragAndDrop"
      [allowReordering]="'true'"
      [onDragStart]="onDragStart"
      [onDragChange]="onDragChange"
      [onReorder]="onReorder"
    ></dxo-row-dragging>
    <dxo-selection
      [mode]="multipleMode ? 'multiple' : 'single'"
      [showCheckBoxesMode]="canSelect ? 'always' : 'none'"
      [recursive]="recursive"
    ></dxo-selection>
    <dxo-scrolling useNative="true" [preloadEnabled]="true" mode="virtual">
    </dxo-scrolling>

    <dxi-column
      *ngFor="let column of columns"
      [dataField]="column.field"
      [caption]="column.label"
      [format]="column.format"
      [name]="column.label + column.field"
      [type]="column.type"
      [width]="column.width"
      [allowResizing]="column.allowResizing"
      [allowExporting]="
        column.allowExporting != undefined ? column.allowExporting : true
      "
      [minWidth]="column.minWidth"
      [allowFiltering]="
        column.field != undefined && column.template == undefined
      "
      [allowSorting]="
        column.template == 'arrayTemplate' ? false : column.allowSorting
      "
      [allowSearch]="
        (column.field != undefined &&
          column.field.endsWith != undefined &&
          (column.field.endsWith('Id') ||
            column.dataType === 'boolean' ||
            column.template === 'arrayEnumTemplate' ||
            column.template === 'arrayTemplate' ||
            column.visible === false)) ||
        column.allowFiltering === false ||
        column.showInColumnChooser === false
          ? false
          : true
      "
      [dataType]="column.dataType"
      [visible]="column.visible != undefined ? column.visible : true"
      [calculateFilterExpression]="
        column.field != undefined ? calculateFilterExpression : undefined
      "
      [filterValue]="column.filterValue"
      [selectedFilterOperation]="
        column.selectedFilterOperation != undefined
          ? convertToOperator(column.selectedFilterOperation)
          : undefined
      "
      [showInColumnChooser]="
        column.showInColumnChooser != undefined
          ? column.showInColumnChooser
          : true
      "
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortOrder"
      [cellTemplate]="
        column.link == true
          ? column.linkHref !== false
            ? 'underlineTemplate'
            : 'underlineWithoutHrefTemplate'
          : column.template
      "
    >
      <dxo-lookup
        *ngIf="column.lookup != undefined"
        [dataSource]="column.lookup.source"
        [valueExpr]="column.lookup.valueExpr"
        [displayExpr]="column.lookup.displayExpr"
      >
      </dxo-lookup>
    </dxi-column>
    <!-- A laisser pour la fake column de redimensionnement -->
    <dxi-column [width]="'100%'"> </dxi-column>

    <div
      *dxTemplate="let data of 'underlineTemplate'"
      style="display: inline-block"
    >
      <a
        *ngIf="
          data?.data[enabledExp] == undefined || data.data[enabledExp] === true
        "
        href="{{ href(data) }}"
        (auxclick)="underlineAux($event, data); (false)"
        (click)="underline($event, data); (false)"
        >{{ data.value }}</a
      >
      <span
        *ngIf="
          data?.data[enabledExp] != undefined && data.data[enabledExp] === false
        "
      >
        {{ data.value }}
      </span>
    </div>

    <div
      *dxTemplate="let data of 'underlineWithoutHrefTemplate'"
      style="display: inline-block"
    >
      <a
        style="text-decoration: underline; cursor: pointer"
        *ngIf="
          data?.data[enabledExp] == undefined || data.data[enabledExp] === true
        "
        (auxclick)="underlineAux($event, data); (false)"
        (click)="underline($event, data); (false)"
        href="#"
        >{{ data.value }}</a
      >
      <span
        *ngIf="
          data?.data[enabledExp] != undefined && data.data[enabledExp] === false
        "
      >
        {{ data.value }}
      </span>
    </div>

    <ng-container
      *ngFor="let column of columns | filter : 'template' : undefined : true"
    >
      <!-- Template spécifique pour les zone de type boolean -->
      <div *dxTemplate="let data of 'booleanTemplate'">
        <i *ngIf="data.value" style="color: #4caf50" class="far fa-check"></i>
      </div>

      <div *dxTemplate="let data of column.template">
        <ng-container
          *ngIf="maxLevel === -1 ? true : maxLevel > data.row.level + 1"
        >
          <ng-template
            *ngTemplateOutlet="
              templateService.get(column.template);
              context: {
                $implicit: data,
                column: column,
                component: this
              }
            "
          >
          </ng-template>
        </ng-container>
      </div>
    </ng-container>
  </dx-tree-list>
  <dx-scheduler
    *ngIf="type === 'Scheduler'"
    [dataSource]="schedulerSource"
    (onInitialized)="onInitialized($event)"
    (onContentReady)="onContentReadyScheduler($event)"
    class="scheduler"
    currentView="workWeek"
    (onAppointmentClick)="onAppointmentClick($event)"
    (onAppointmentRendered)="onAppointmentRendered($event)"
    (onAppointmentFormOpening)="onAppointmentFormOpening($event)"
    (onAppointmentContextMenu)="onAppointmentContextMenu($event)"
    (onAppointmentDeleting)="onAppointmentDeleting($event)"
    [views]="['day', 'week', 'workWeek', 'month', 'agenda']"
    appointmentTooltipTemplate="tooltip-template"
    [editing]="{
      allowUpdating:
        options?.allowAdding != undefined ? options?.allowAdding : true,
      allowDeleting:
        options?.allowDeleting != undefined ? options?.allowDeleting : false,
      allowAdding:
        options?.allowAdding != undefined ? options?.allowAdding : false,
      allowDragging: false,
      allowResizing: false
    }"
  >
    <div *dxTemplate="let model of 'tooltip-template'">
      <div class="dx-tooltip-appointment-item">
        <div class="dx-tooltip-appointment-item-marker">
          <div class="dx-tooltip-appointment-item-marker-body"></div>
        </div>
        <div class="dx-tooltip-appointment-item-content">
          <div class="dx-tooltip-appointment-item-content-subject">
            {{ model.appointmentData.text }}
          </div>
          <div
            *ngIf="
              model.appointmentData.allDay == true &&
              model.appointmentData.endDate != model.appointmentData.startDate
            "
          >
            <div class="dx-tooltip-appointment-item-content-date">
              {{ displayDate(model.targetedAppointmentData.startDate, 'full') }}
              -
              {{ displayDate(model.targetedAppointmentData.endDate, 'full') }}
            </div>
          </div>
          <div
            *ngIf="
              model.appointmentData.allDay == true &&
              model.appointmentData.endDate == model.appointmentData.startDate
            "
          >
            <div class="dx-tooltip-appointment-item-content-date">
              {{ displayDate(model.targetedAppointmentData.startDate, 'full') }}
            </div>
          </div>
          <div *ngIf="!model.targetedAppointmentData.allDay">
            <div class="dx-tooltip-appointment-item-content-date">
              {{
                compareDate(
                  model.targetedAppointmentData.startDate,
                  model.targetedAppointmentData.endDate
                )
              }}
            </div>
          </div>
          <div
            *ngIf="
              model.appointmentData.description != undefined &&
              model.appointmentData.description != ''
            "
            class="cl-tooltip-appointment-item-content-description"
          >
            {{ displayWithoutHtml(model.appointmentData.description) }}
          </div>
        </div>
        <div
          *ngIf="removeItems != undefined && options?.allowDeleting == true"
          class="dx-tooltip-appointment-item-delete-button-container ng-star-inserted"
        >
          <dx-button
            icon="trash"
            [height]="'100%'"
            [ngClass]="'cl-trash-appointment'"
            (onClick)="deleteAppointement($event, model)"
            ng-reflect-icon="trash"
            ng-reflect-visible="true"
            class="dx-button dx-button-normal dx-button-mode-contained dx-widget dx-button-has-icon"
            aria-label="trash"
            tabindex="0"
            role="button"
            ><div class="dx-button-content">
              <i class="dx-icon dx-icon-trash"></i></div
          ></dx-button>
        </div>
      </div>
    </div>
  </dx-scheduler>
  <dx-context-menu
    #contextMenu
    [dataSource]="dataSourceMenu"
    [disabled]="false"
    [target]="target"
    (onItemClick)="onContextMenuItemClick($event)"
    [width]="200"
  >
    <div *dxTemplate="let e of 'item'">
      <div *ngIf="e.color">
        <div class="item-badge" [ngStyle]="{ backgroundColor: e.color }"></div>
      </div>
      <div class="item-text">{{ e.text }}</div>
    </div>
  </dx-context-menu>
</div>

<dx-popup
  *ngIf="sourceRessource != undefined"
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  class="add-item-popup"
  #popup
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="popupVisible"
>
  <clc-select-list
    [title]="'globals/selectAudit' | translate"
    [buttonText]="'globals/selectAuditAgreed' | translate"
    [source]="sourceRessource"
    [columns]="schedulerAuditsColumns"
    (onClosed)="onClosed($event)"
    (onSelect)="selectAuditsAffect($event)"
    [(selectedKeys)]="selectedKeys"
    [multiple]="true"
  >
  </clc-select-list>
</dx-popup>

<!-- popup de filter -->
<dx-popover
  *ngIf="
    showFilterPopupSelect &&
    filterPopupDataSource?.model?.grid?.layout?.filters[0]?.items[0]?.list
      ?.source != undefined
  "
  class="cl-popover"
  [width]="'300px'"
  [height]="'auto'"
  [(visible)]="showFilterPopupSelect"
  [position]="{
    my: 'left top',
    at: 'left bottom',
    of: filterPopupColumn?.button?.element,
    collision: {
      x: 'flip',
      y: 'flip'
    }
  }"
>
  <div style="height: 300px">
    <clc-select-list
      [title]="'globals/selectAssetCategory' | translate"
      [buttonText]="'globals/select' | translate"
      [type]="filterPopupDataSource?.model?.grid?.layout?.type ?? 'Grid'"
      [source]="
        filterPopupDataSource?.model?.grid?.layout?.filters[0]?.items[0]?.list
          ?.source
      "
      [columns]="filterPopupDataSource?.model?.grid?.layout?.columns"
      (onClosed)="showFilterPopupSelect = false"
      [multiple]="true"
      [activeSelectLevelAction]="true"
      [selectedKeys]="filterPopupSelectedKeys"
      [selectedData]="filterPopupSelectedData"
      [compactMode]="true"
    >
    </clc-select-list>
  </div>
  <div class="cl-filter-popup-button-container">
    <dx-button
      *ngIf="showClearPopupFilterButton"
      [text]="'delete' | translate"
      (onClick)="onDeleteFilter($event)"
      [ngClass]="'dx-button-mode-text dx-button-default'"
    ></dx-button>

    <div style="float: right">
      <dx-button
        [text]="'ok' | translate"
        (onClick)="onFilterPopupSelect($event)"
        [ngClass]="'dx-button-mode-text dx-button-default'"
      ></dx-button>
      <dx-button
        [text]="'cancel' | translate"
        (onClick)="showFilterPopupSelect = false"
        [ngClass]="'dx-button-mode-text dx-button-default'"
      ></dx-button>
    </div>
  </div>
</dx-popover>
