import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLocationChartersBaseVariables, FirstLocationChartersBaseVariables, CountLocationChartersBaseVariables, FindLocationChartersBaseVariables, SearchLocationChartersBaseVariables, ExportSchemaLocationChartersBaseVariables, ExportDataLocationChartersBaseVariables, CustomQueryLocationChartersBaseVariables, CustomQueryIdLocationChartersBaseVariables, UsedLocationChartersBaseVariables, ExistLocationChartersBaseVariables, FindRecyclesLocationChartersBaseVariables, CountRecyclesLocationChartersBaseVariables, ReadOnlyLocationChartersBaseVariables, FindArchivedLocationChartersBaseVariables, CountAllLocationChartersBaseVariables, FindDynamicPropertyFieldsLocationChartersBaseVariables, FindUnassociatedExcludeAssetsLocationChartersBaseVariables, FindUnassociatedExcludeUsersLocationChartersBaseVariables, InsertLocationChartersBaseVariables, UpdateLocationChartersBaseVariables, ImportDataLocationChartersBaseVariables, UpdateManyLocationChartersBaseVariables, DeleteLocationChartersBaseVariables, ExecuteLocationChartersBaseVariables, ImportLocationCharterByTypeLocationChartersBaseVariables, DownloadLocationCharterSchemaByTypeLocationChartersBaseVariables, RestoreLocationChartersBaseVariables, RecycleLocationChartersBaseVariables, RestoreArchivedLocationChartersBaseVariables, ArchivedLocationChartersBaseVariables, AddFileDynamicFieldLocationChartersBaseVariables, RemoveFileDynamicFieldLocationChartersBaseVariables, AddExcludeAssetsLocationChartersBaseVariables, RemoveExcludeAssetsLocationChartersBaseVariables, AddExcludeUsersLocationChartersBaseVariables, RemoveExcludeUsersLocationChartersBaseVariables } from '../gqls'
import { GetLocationChartersDocument, FirstLocationChartersDocument, CountLocationChartersDocument, FindLocationChartersDocument, SearchLocationChartersDocument, ExportSchemaLocationChartersDocument, ExportDataLocationChartersDocument, CustomQueryLocationChartersDocument, CustomQueryIdLocationChartersDocument, UsedLocationChartersDocument, ExistLocationChartersDocument, FindRecyclesLocationChartersDocument, CountRecyclesLocationChartersDocument, ReadOnlyLocationChartersDocument, FindArchivedLocationChartersDocument, CountAllLocationChartersDocument, FindDynamicPropertyFieldsLocationChartersDocument, FindUnassociatedExcludeAssetsLocationChartersDocument, FindUnassociatedExcludeUsersLocationChartersDocument, InsertLocationChartersDocument, UpdateLocationChartersDocument, ImportDataLocationChartersDocument, UpdateManyLocationChartersDocument, DeleteLocationChartersDocument, ExecuteLocationChartersDocument, ImportLocationCharterByTypeLocationChartersDocument, DownloadLocationCharterSchemaByTypeLocationChartersDocument, RestoreLocationChartersDocument, RecycleLocationChartersDocument, RestoreArchivedLocationChartersDocument, ArchivedLocationChartersDocument, AddFileDynamicFieldLocationChartersDocument, RemoveFileDynamicFieldLocationChartersDocument, AddExcludeAssetsLocationChartersDocument, RemoveExcludeAssetsLocationChartersDocument, AddExcludeUsersLocationChartersDocument, RemoveExcludeUsersLocationChartersDocument } from '../gqls'
import { ServiceSingleResultOfLocationCharter, QueryContextOfLocationCharter, FilterOfLocationCharter, ServiceSingleResultOfInt64, ServiceListResultOfLocationCharter, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfLocationCharter, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, LocationCharterInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LocationCharterBaseService {
    
public modelName = 'locationCharter';
public modelPluralName = 'locationCharters';

	private getLocationChartersQuery: GetLocationChartersDocument;
	private firstLocationChartersQuery: FirstLocationChartersDocument;
	private countLocationChartersQuery: CountLocationChartersDocument;
	private findLocationChartersQuery: FindLocationChartersDocument;
	private searchLocationChartersQuery: SearchLocationChartersDocument;
	private exportSchemaLocationChartersQuery: ExportSchemaLocationChartersDocument;
	private exportDataLocationChartersQuery: ExportDataLocationChartersDocument;
	private customQueryLocationChartersQuery: CustomQueryLocationChartersDocument;
	private customQueryIdLocationChartersQuery: CustomQueryIdLocationChartersDocument;
	private usedLocationChartersQuery: UsedLocationChartersDocument;
	private existLocationChartersQuery: ExistLocationChartersDocument;
	private findRecyclesLocationChartersQuery: FindRecyclesLocationChartersDocument;
	private countRecyclesLocationChartersQuery: CountRecyclesLocationChartersDocument;
	private readOnlyLocationChartersQuery: ReadOnlyLocationChartersDocument;
	private findArchivedLocationChartersQuery: FindArchivedLocationChartersDocument;
	private countAllLocationChartersQuery: CountAllLocationChartersDocument;
	private findDynamicPropertyFieldsLocationChartersQuery: FindDynamicPropertyFieldsLocationChartersDocument;
	private findUnassociatedExcludeAssetsLocationChartersQuery: FindUnassociatedExcludeAssetsLocationChartersDocument;
	private findUnassociatedExcludeUsersLocationChartersQuery: FindUnassociatedExcludeUsersLocationChartersDocument;
	private insertLocationChartersMutation: InsertLocationChartersDocument;
	private updateLocationChartersMutation: UpdateLocationChartersDocument;
	private importDataLocationChartersMutation: ImportDataLocationChartersDocument;
	private updateManyLocationChartersMutation: UpdateManyLocationChartersDocument;
	private deleteLocationChartersMutation: DeleteLocationChartersDocument;
	private executeLocationChartersMutation: ExecuteLocationChartersDocument;
	private importLocationCharterByTypeLocationChartersMutation: ImportLocationCharterByTypeLocationChartersDocument;
	private downloadLocationCharterSchemaByTypeLocationChartersMutation: DownloadLocationCharterSchemaByTypeLocationChartersDocument;
	private restoreLocationChartersMutation: RestoreLocationChartersDocument;
	private recycleLocationChartersMutation: RecycleLocationChartersDocument;
	private restoreArchivedLocationChartersMutation: RestoreArchivedLocationChartersDocument;
	private archivedLocationChartersMutation: ArchivedLocationChartersDocument;
	private addFileDynamicFieldLocationChartersMutation: AddFileDynamicFieldLocationChartersDocument;
	private removeFileDynamicFieldLocationChartersMutation: RemoveFileDynamicFieldLocationChartersDocument;
	private addExcludeAssetsLocationChartersMutation: AddExcludeAssetsLocationChartersDocument;
	private removeExcludeAssetsLocationChartersMutation: RemoveExcludeAssetsLocationChartersDocument;
	private addExcludeUsersLocationChartersMutation: AddExcludeUsersLocationChartersDocument;
	private removeExcludeUsersLocationChartersMutation: RemoveExcludeUsersLocationChartersDocument;

	constructor(private injector: Injector) {
		this.getLocationChartersQuery = this.injector.get(GetLocationChartersDocument);
		this.firstLocationChartersQuery = this.injector.get(FirstLocationChartersDocument);
		this.countLocationChartersQuery = this.injector.get(CountLocationChartersDocument);
		this.findLocationChartersQuery = this.injector.get(FindLocationChartersDocument);
		this.searchLocationChartersQuery = this.injector.get(SearchLocationChartersDocument);
		this.exportSchemaLocationChartersQuery = this.injector.get(ExportSchemaLocationChartersDocument);
		this.exportDataLocationChartersQuery = this.injector.get(ExportDataLocationChartersDocument);
		this.customQueryLocationChartersQuery = this.injector.get(CustomQueryLocationChartersDocument);
		this.customQueryIdLocationChartersQuery = this.injector.get(CustomQueryIdLocationChartersDocument);
		this.usedLocationChartersQuery = this.injector.get(UsedLocationChartersDocument);
		this.existLocationChartersQuery = this.injector.get(ExistLocationChartersDocument);
		this.findRecyclesLocationChartersQuery = this.injector.get(FindRecyclesLocationChartersDocument);
		this.countRecyclesLocationChartersQuery = this.injector.get(CountRecyclesLocationChartersDocument);
		this.readOnlyLocationChartersQuery = this.injector.get(ReadOnlyLocationChartersDocument);
		this.findArchivedLocationChartersQuery = this.injector.get(FindArchivedLocationChartersDocument);
		this.countAllLocationChartersQuery = this.injector.get(CountAllLocationChartersDocument);
		this.findDynamicPropertyFieldsLocationChartersQuery = this.injector.get(FindDynamicPropertyFieldsLocationChartersDocument);
		this.findUnassociatedExcludeAssetsLocationChartersQuery = this.injector.get(FindUnassociatedExcludeAssetsLocationChartersDocument);
		this.findUnassociatedExcludeUsersLocationChartersQuery = this.injector.get(FindUnassociatedExcludeUsersLocationChartersDocument);
		this.insertLocationChartersMutation = this.injector.get(InsertLocationChartersDocument);
		this.updateLocationChartersMutation = this.injector.get(UpdateLocationChartersDocument);
		this.importDataLocationChartersMutation = this.injector.get(ImportDataLocationChartersDocument);
		this.updateManyLocationChartersMutation = this.injector.get(UpdateManyLocationChartersDocument);
		this.deleteLocationChartersMutation = this.injector.get(DeleteLocationChartersDocument);
		this.executeLocationChartersMutation = this.injector.get(ExecuteLocationChartersDocument);
		this.importLocationCharterByTypeLocationChartersMutation = this.injector.get(ImportLocationCharterByTypeLocationChartersDocument);
		this.downloadLocationCharterSchemaByTypeLocationChartersMutation = this.injector.get(DownloadLocationCharterSchemaByTypeLocationChartersDocument);
		this.restoreLocationChartersMutation = this.injector.get(RestoreLocationChartersDocument);
		this.recycleLocationChartersMutation = this.injector.get(RecycleLocationChartersDocument);
		this.restoreArchivedLocationChartersMutation = this.injector.get(RestoreArchivedLocationChartersDocument);
		this.archivedLocationChartersMutation = this.injector.get(ArchivedLocationChartersDocument);
		this.addFileDynamicFieldLocationChartersMutation = this.injector.get(AddFileDynamicFieldLocationChartersDocument);
		this.removeFileDynamicFieldLocationChartersMutation = this.injector.get(RemoveFileDynamicFieldLocationChartersDocument);
		this.addExcludeAssetsLocationChartersMutation = this.injector.get(AddExcludeAssetsLocationChartersDocument);
		this.removeExcludeAssetsLocationChartersMutation = this.injector.get(RemoveExcludeAssetsLocationChartersDocument);
		this.addExcludeUsersLocationChartersMutation = this.injector.get(AddExcludeUsersLocationChartersDocument);
		this.removeExcludeUsersLocationChartersMutation = this.injector.get(RemoveExcludeUsersLocationChartersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCharter> {
    
    		let variables: GetLocationChartersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.get));
            }
            else{
                let result:ServiceSingleResultOfLocationCharter={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCharter> {
    
    		let variables: FirstLocationChartersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.countLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: FindLocationChartersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: SearchLocationChartersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLocationChartersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: CustomQueryLocationChartersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: CustomQueryIdLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.usedLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLocationChartersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: FindRecyclesLocationChartersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyLocationChartersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCharter,
		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCharter> {
    
    		let variables: FindArchivedLocationChartersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsLocationChartersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedExcludeAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('excludeAssets', fields, null, [
			GqlSubFieldArg.create('filterOfExcludeAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfExcludeAssets', 'options'),
		]),
		])
		extendedVariables['filterOfExcludeAssets'] = filter;
		extendedVariables['optionsOfExcludeAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.excludeAssets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedExcludeAssetsLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedExcludeAssetsLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.findUnassociatedExcludeAssets));
    	}

	@InjectArgs
	public findAssociatedExcludeUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('excludeUsers', fields, null, [
			GqlSubFieldArg.create('filterOfExcludeUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfExcludeUsers', 'options'),
		]),
		])
		extendedVariables['filterOfExcludeUsers'] = filter;
		extendedVariables['optionsOfExcludeUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.excludeUsers));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedExcludeUsersLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedExcludeUsersLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.findUnassociatedExcludeUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LocationCharterInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCharter> {
    
    		let variables: InsertLocationChartersBaseVariables = {
    			entity: entity
    		}
    				return this.insertLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCharter> {
    
    		let variables: UpdateLocationChartersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLocationChartersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLocationChartersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.delete));
    	}

    	/**  */
    	@InjectArgs
    	public execute(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExecuteLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.executeLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.execute));
    	}

    	/**  */
    	@InjectArgs
    	public importLocationCharterByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('format') format: ImportFileFormat,
		@Args('locationCharterKey?') locationCharterKey?: string,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportLocationCharterByTypeLocationChartersBaseVariables = {
    			file: file,
			locationCharterKey: locationCharterKey,
			format: format
    		}
    				return this.importLocationCharterByTypeLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.importLocationCharterByType));
    	}

    	/**  */
    	@InjectArgs
    	public downloadLocationCharterSchemaByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('format') format: ImportFileFormat,
		@Args('locationCharterKey?') locationCharterKey?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: DownloadLocationCharterSchemaByTypeLocationChartersBaseVariables = {
    			locationCharterKey: locationCharterKey,
			format: format
    		}
    				return this.downloadLocationCharterSchemaByTypeLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.downloadLocationCharterSchemaByType));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldLocationChartersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldLocationChartersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddExcludeAssetsLocationChartersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addExcludeAssetsLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.addExcludeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveExcludeAssetsLocationChartersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeExcludeAssetsLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.removeExcludeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddExcludeUsersLocationChartersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addExcludeUsersLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.addExcludeUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveExcludeUsersLocationChartersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeExcludeUsersLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCharters.removeExcludeUsers));
    	}
    
}