import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetAccountsBaseVariables, FirstAssetAccountsBaseVariables, CountAssetAccountsBaseVariables, FindAssetAccountsBaseVariables, SearchAssetAccountsBaseVariables, ExportSchemaAssetAccountsBaseVariables, ExportDataAssetAccountsBaseVariables, CustomQueryAssetAccountsBaseVariables, CustomQueryIdAssetAccountsBaseVariables, UsedAssetAccountsBaseVariables, ExistAssetAccountsBaseVariables, InsertAssetAccountsBaseVariables, UpdateAssetAccountsBaseVariables, ImportDataAssetAccountsBaseVariables, UpdateManyAssetAccountsBaseVariables, DeleteAssetAccountsBaseVariables } from '../gqls'
import { GetAssetAccountsDocument, FirstAssetAccountsDocument, CountAssetAccountsDocument, FindAssetAccountsDocument, SearchAssetAccountsDocument, ExportSchemaAssetAccountsDocument, ExportDataAssetAccountsDocument, CustomQueryAssetAccountsDocument, CustomQueryIdAssetAccountsDocument, UsedAssetAccountsDocument, ExistAssetAccountsDocument, InsertAssetAccountsDocument, UpdateAssetAccountsDocument, ImportDataAssetAccountsDocument, UpdateManyAssetAccountsDocument, DeleteAssetAccountsDocument } from '../gqls'
import { ServiceSingleResultOfAssetAccount, QueryContextOfAssetAccount, FilterOfAssetAccount, ServiceSingleResultOfInt64, ServiceListResultOfAssetAccount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetAccountInput, FieldUpdateOperatorOfAssetAccount } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetAccountBaseService {
    
public modelName = 'assetAccount';
public modelPluralName = 'assetAccounts';

	private getAssetAccountsQuery: GetAssetAccountsDocument;
	private firstAssetAccountsQuery: FirstAssetAccountsDocument;
	private countAssetAccountsQuery: CountAssetAccountsDocument;
	private findAssetAccountsQuery: FindAssetAccountsDocument;
	private searchAssetAccountsQuery: SearchAssetAccountsDocument;
	private exportSchemaAssetAccountsQuery: ExportSchemaAssetAccountsDocument;
	private exportDataAssetAccountsQuery: ExportDataAssetAccountsDocument;
	private customQueryAssetAccountsQuery: CustomQueryAssetAccountsDocument;
	private customQueryIdAssetAccountsQuery: CustomQueryIdAssetAccountsDocument;
	private usedAssetAccountsQuery: UsedAssetAccountsDocument;
	private existAssetAccountsQuery: ExistAssetAccountsDocument;
	private insertAssetAccountsMutation: InsertAssetAccountsDocument;
	private updateAssetAccountsMutation: UpdateAssetAccountsDocument;
	private importDataAssetAccountsMutation: ImportDataAssetAccountsDocument;
	private updateManyAssetAccountsMutation: UpdateManyAssetAccountsDocument;
	private deleteAssetAccountsMutation: DeleteAssetAccountsDocument;

	constructor(private injector: Injector) {
		this.getAssetAccountsQuery = this.injector.get(GetAssetAccountsDocument);
		this.firstAssetAccountsQuery = this.injector.get(FirstAssetAccountsDocument);
		this.countAssetAccountsQuery = this.injector.get(CountAssetAccountsDocument);
		this.findAssetAccountsQuery = this.injector.get(FindAssetAccountsDocument);
		this.searchAssetAccountsQuery = this.injector.get(SearchAssetAccountsDocument);
		this.exportSchemaAssetAccountsQuery = this.injector.get(ExportSchemaAssetAccountsDocument);
		this.exportDataAssetAccountsQuery = this.injector.get(ExportDataAssetAccountsDocument);
		this.customQueryAssetAccountsQuery = this.injector.get(CustomQueryAssetAccountsDocument);
		this.customQueryIdAssetAccountsQuery = this.injector.get(CustomQueryIdAssetAccountsDocument);
		this.usedAssetAccountsQuery = this.injector.get(UsedAssetAccountsDocument);
		this.existAssetAccountsQuery = this.injector.get(ExistAssetAccountsDocument);
		this.insertAssetAccountsMutation = this.injector.get(InsertAssetAccountsDocument);
		this.updateAssetAccountsMutation = this.injector.get(UpdateAssetAccountsDocument);
		this.importDataAssetAccountsMutation = this.injector.get(ImportDataAssetAccountsDocument);
		this.updateManyAssetAccountsMutation = this.injector.get(UpdateManyAssetAccountsDocument);
		this.deleteAssetAccountsMutation = this.injector.get(DeleteAssetAccountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAccount> {
    
    		let variables: GetAssetAccountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.get));
            }
            else{
                let result:ServiceSingleResultOfAssetAccount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAccount,
		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAccount> {
    
    		let variables: FirstAssetAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAccount,
		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAccount> {
    
    		let variables: FindAssetAccountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetAccount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAccount> {
    
    		let variables: SearchAssetAccountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetAccountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetAccount,
		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAccount> {
    
    		let variables: CustomQueryAssetAccountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetAccount,
		@Args('filter?') filter?: FilterOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAccount> {
    
    		let variables: CustomQueryIdAssetAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetAccountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetAccountInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAccount> {
    
    		let variables: InsertAssetAccountsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAccount> {
    
    		let variables: UpdateAssetAccountsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetAccountsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetAccountsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAccounts.delete));
    	}
    
}