import {
  Component,
  NgZone,
  OnDestroy,
  OnInit,
  HostListener,
} from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { InitializationCoreService } from './core/services2/initialization/initialization.service';
import { TemplatesRowService } from './shared2/components/templates-row/templates-row.service';
import { TemplatesService } from './shared2/components/templates/templates.service';
import { Router } from '@angular/router';
import { UserIdleService } from 'angular-user-idle';
import {
  MyOrganizationCoreService,
  OrganizationCoreService,
} from './core/services2/graphql/generated-types/services';
import { ModelFieldCompilerService } from '@clarilog/shared2';
import { Subscription } from 'rxjs';

/** Représente le composant de base de l'application. */
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit, OnDestroy {
  isLoad = false;
  visible = false;
  private static ISVISIBLE = true;
  private static USER_INACTIVE_KEY = 'user-inactive';
  private static ACTIVITY_KEY = 'user-activity';
  private userIdleSubscription: Subscription | null = null;
  private idleTimerOrga: any;

  closeButtonOptions = {
    text: 'OK',
    onClick: this.itemMenuClick.bind(this),
  };

  constructor(
    private swUpdate: SwUpdate,
    private _initializationCoreService: InitializationCoreService,
    private _templatesService: TemplatesService,
    private _templatesRowService: TemplatesRowService,
    private router: Router,
    private ngZone: NgZone,
    private organizationService: OrganizationCoreService,
    private userIdle: UserIdleService,
    private myOrganizationService: MyOrganizationCoreService,
  ) {
    if ((window as any).Cypress) {
      (window as any).cypressNavigateByUrl = (url: string) =>
        this.cypressNavigateByUrl(url);
    }
  }

  public cypressNavigateByUrl(url: string) {
    this.ngZone.run(() => {
      this.router.navigateByUrl(url);
    });
  }

  @HostListener('window:click')
  @HostListener('window:keydown')
  resetIdleTimer() {
    localStorage.setItem(AppComponent.ACTIVITY_KEY, Date.now().toString());
  }

  async ngOnInit(): Promise<void> {
    this.userIdle.onTimeout().subscribe(() => this.handleTimeout());
    window.addEventListener('storage', this.syncIdleState.bind(this));
    window.addEventListener('storage', this.handleActivity.bind(this));
    try {
      this.swUpdate.versionUpdates.subscribe((version) => {
        if (version.type !== 'NO_NEW_VERSION_DETECTED') {
          window.location.reload();
        }
      });

      this._templatesService.appendToBody();
      this._templatesRowService.appendToBody();

      localStorage.removeItem('serviceDesk');

      let persistToken = window.location.search.split('inviteToken=')[1];

      if (window.location.search.split('inviteToken=').length > 1) {
        if (
          window.location.search.split('inviteToken=')[1] !=
          localStorage.invite_token
        ) {
          if (localStorage.persist == undefined) {
            localStorage.setItem('persist', persistToken);
          }
        }
        localStorage.setItem(
          'invite_token',
          window.location.search.split('inviteToken=')[1],
        );
      }

      if (
        window.location.search.split('use_ms_login=').length > 1 &&
        window.location.search.split('token=').length > 1
      ) {
        localStorage.setItem(
          'mpc_token',
          window.location.search.split('token=')[1],
        );
      } else {
        localStorage.setItem('force', 'false');
      }

      if (window.location.search.split('force=').length > 1) {
        localStorage.setItem(
          'force',
          window.location.search.split('force=')[1],
        );
      } else {
        localStorage.setItem('force', 'false');
      }

      this._initializationCoreService.setStartUrl(
        encodeURIComponent(window.location.pathname + window.location.search),
      );
      this._initializationCoreService.initialize();
      this.isLoad = await this._initializationCoreService.isLoaded$.toPromise();
      let correctPathname =
        window.location.pathname.includes('my-organizations');

      let OtherT = window.location.search.includes('invalidToken');

      if (this.isLoad && OtherT && AppComponent.ISVISIBLE) {
        this.visible = !this.visible;
        localStorage.setItem('invalid', 'true');
        AppComponent.ISVISIBLE = !AppComponent.ISVISIBLE;
      }
      if (!this.visible) {
        localStorage.removeItem('persist');
        localStorage.removeItem('invite_token');
      }
    } catch (e) {}

    this.organizationService.getIdleTimer().subscribe((result) => {
      if (result?.data != null) {
        this.idleTimerOrga = result.data;
        this.updateUserIdleConfig();
      }
    });
    this.userIdle.startWatching();

    this.userIdle.onTimeout().subscribe(() => this.handleTimeout());

    window.addEventListener('storage', this.syncIdleState.bind(this));
  }

  ngOnDestroy() {
    this.userIdle.stopWatching();
    window.removeEventListener('storage', this.syncIdleState.bind(this));
    window.removeEventListener('storage', this.handleActivity.bind(this));
    if (this.userIdleSubscription) {
      this.userIdleSubscription.unsubscribe();
    }
  }

  private handleTimeout() {
    localStorage.setItem(AppComponent.USER_INACTIVE_KEY, 'true');
    this.checkAllTabsInactive();
  }

  private syncIdleState(event: StorageEvent) {
    if (
      event.key === AppComponent.USER_INACTIVE_KEY &&
      event.newValue === 'true'
    ) {
      this.checkAllTabsInactive();
    }
  }

  private handleActivity(event: StorageEvent) {
    if (event.key === AppComponent.ACTIVITY_KEY) {
      this.userIdle.resetTimer();
    }
  }

  private checkAllTabsInactive() {
    const userInactive =
      localStorage.getItem(AppComponent.USER_INACTIVE_KEY) === 'true';

    if (userInactive) {
      this.logoutUser();
    } else {
      localStorage.setItem(AppComponent.USER_INACTIVE_KEY, 'false');
    }
  }

  private logoutUser() {
    localStorage.clear();
  }

  private updateUserIdleConfig() {
    this.userIdle.stopWatching();
    this.userIdle.setConfigValues({
      idle: this.idleTimerOrga.idleTimer,
      timeout: 5,
      ping: 120,
    });
    this.userIdle.startWatching();
  }

  public itemMenuClick(e) {
    this.visible = false;
    localStorage.removeItem('persist');
    localStorage.removeItem('invite_token');
  }
}
