import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareReferentLinksBaseVariables, FirstSoftwareReferentLinksBaseVariables, CountSoftwareReferentLinksBaseVariables, FindSoftwareReferentLinksBaseVariables, SearchSoftwareReferentLinksBaseVariables, ExportSchemaSoftwareReferentLinksBaseVariables, ExportDataSoftwareReferentLinksBaseVariables, CustomQuerySoftwareReferentLinksBaseVariables, CustomQueryIdSoftwareReferentLinksBaseVariables, UsedSoftwareReferentLinksBaseVariables, FindReferentComponentSoftwareReferentLinksBaseVariables, FindNotReferentComponentSoftwareReferentLinksBaseVariables, FindSoftwareReferentComponentSoftwareReferentLinksBaseVariables, FindNotSoftwareReferentComponentSoftwareReferentLinksBaseVariables, ExistSoftwareReferentLinksBaseVariables, InsertSoftwareReferentLinksBaseVariables, UpdateSoftwareReferentLinksBaseVariables, ImportDataSoftwareReferentLinksBaseVariables, UpdateManySoftwareReferentLinksBaseVariables, DeleteSoftwareReferentLinksBaseVariables, SaveReferentComponentSoftwareReferentLinksBaseVariables, SaveSoftwareReferentComponentSoftwareReferentLinksBaseVariables } from '../gqls'
import { GetSoftwareReferentLinksDocument, FirstSoftwareReferentLinksDocument, CountSoftwareReferentLinksDocument, FindSoftwareReferentLinksDocument, SearchSoftwareReferentLinksDocument, ExportSchemaSoftwareReferentLinksDocument, ExportDataSoftwareReferentLinksDocument, CustomQuerySoftwareReferentLinksDocument, CustomQueryIdSoftwareReferentLinksDocument, UsedSoftwareReferentLinksDocument, FindReferentComponentSoftwareReferentLinksDocument, FindNotReferentComponentSoftwareReferentLinksDocument, FindSoftwareReferentComponentSoftwareReferentLinksDocument, FindNotSoftwareReferentComponentSoftwareReferentLinksDocument, ExistSoftwareReferentLinksDocument, InsertSoftwareReferentLinksDocument, UpdateSoftwareReferentLinksDocument, ImportDataSoftwareReferentLinksDocument, UpdateManySoftwareReferentLinksDocument, DeleteSoftwareReferentLinksDocument, SaveReferentComponentSoftwareReferentLinksDocument, SaveSoftwareReferentComponentSoftwareReferentLinksDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareReferentLink, QueryContextOfSoftwareReferentLink, FilterOfSoftwareReferentLink, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareReferentLink, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceListResultOfSoftware, QueryContextOfSoftware, FilterOfSoftware, SoftwareReferentLinkInput, FieldUpdateOperatorOfSoftwareReferentLink } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareReferentLinkBaseService {
    
public modelName = 'softwareReferentLink';
public modelPluralName = 'softwareReferentLinks';

	private getSoftwareReferentLinksQuery: GetSoftwareReferentLinksDocument;
	private firstSoftwareReferentLinksQuery: FirstSoftwareReferentLinksDocument;
	private countSoftwareReferentLinksQuery: CountSoftwareReferentLinksDocument;
	private findSoftwareReferentLinksQuery: FindSoftwareReferentLinksDocument;
	private searchSoftwareReferentLinksQuery: SearchSoftwareReferentLinksDocument;
	private exportSchemaSoftwareReferentLinksQuery: ExportSchemaSoftwareReferentLinksDocument;
	private exportDataSoftwareReferentLinksQuery: ExportDataSoftwareReferentLinksDocument;
	private customQuerySoftwareReferentLinksQuery: CustomQuerySoftwareReferentLinksDocument;
	private customQueryIdSoftwareReferentLinksQuery: CustomQueryIdSoftwareReferentLinksDocument;
	private usedSoftwareReferentLinksQuery: UsedSoftwareReferentLinksDocument;
	private findReferentComponentSoftwareReferentLinksQuery: FindReferentComponentSoftwareReferentLinksDocument;
	private findNotReferentComponentSoftwareReferentLinksQuery: FindNotReferentComponentSoftwareReferentLinksDocument;
	private findSoftwareReferentComponentSoftwareReferentLinksQuery: FindSoftwareReferentComponentSoftwareReferentLinksDocument;
	private findNotSoftwareReferentComponentSoftwareReferentLinksQuery: FindNotSoftwareReferentComponentSoftwareReferentLinksDocument;
	private existSoftwareReferentLinksQuery: ExistSoftwareReferentLinksDocument;
	private insertSoftwareReferentLinksMutation: InsertSoftwareReferentLinksDocument;
	private updateSoftwareReferentLinksMutation: UpdateSoftwareReferentLinksDocument;
	private importDataSoftwareReferentLinksMutation: ImportDataSoftwareReferentLinksDocument;
	private updateManySoftwareReferentLinksMutation: UpdateManySoftwareReferentLinksDocument;
	private deleteSoftwareReferentLinksMutation: DeleteSoftwareReferentLinksDocument;
	private saveReferentComponentSoftwareReferentLinksMutation: SaveReferentComponentSoftwareReferentLinksDocument;
	private saveSoftwareReferentComponentSoftwareReferentLinksMutation: SaveSoftwareReferentComponentSoftwareReferentLinksDocument;

	constructor(private injector: Injector) {
		this.getSoftwareReferentLinksQuery = this.injector.get(GetSoftwareReferentLinksDocument);
		this.firstSoftwareReferentLinksQuery = this.injector.get(FirstSoftwareReferentLinksDocument);
		this.countSoftwareReferentLinksQuery = this.injector.get(CountSoftwareReferentLinksDocument);
		this.findSoftwareReferentLinksQuery = this.injector.get(FindSoftwareReferentLinksDocument);
		this.searchSoftwareReferentLinksQuery = this.injector.get(SearchSoftwareReferentLinksDocument);
		this.exportSchemaSoftwareReferentLinksQuery = this.injector.get(ExportSchemaSoftwareReferentLinksDocument);
		this.exportDataSoftwareReferentLinksQuery = this.injector.get(ExportDataSoftwareReferentLinksDocument);
		this.customQuerySoftwareReferentLinksQuery = this.injector.get(CustomQuerySoftwareReferentLinksDocument);
		this.customQueryIdSoftwareReferentLinksQuery = this.injector.get(CustomQueryIdSoftwareReferentLinksDocument);
		this.usedSoftwareReferentLinksQuery = this.injector.get(UsedSoftwareReferentLinksDocument);
		this.findReferentComponentSoftwareReferentLinksQuery = this.injector.get(FindReferentComponentSoftwareReferentLinksDocument);
		this.findNotReferentComponentSoftwareReferentLinksQuery = this.injector.get(FindNotReferentComponentSoftwareReferentLinksDocument);
		this.findSoftwareReferentComponentSoftwareReferentLinksQuery = this.injector.get(FindSoftwareReferentComponentSoftwareReferentLinksDocument);
		this.findNotSoftwareReferentComponentSoftwareReferentLinksQuery = this.injector.get(FindNotSoftwareReferentComponentSoftwareReferentLinksDocument);
		this.existSoftwareReferentLinksQuery = this.injector.get(ExistSoftwareReferentLinksDocument);
		this.insertSoftwareReferentLinksMutation = this.injector.get(InsertSoftwareReferentLinksDocument);
		this.updateSoftwareReferentLinksMutation = this.injector.get(UpdateSoftwareReferentLinksDocument);
		this.importDataSoftwareReferentLinksMutation = this.injector.get(ImportDataSoftwareReferentLinksDocument);
		this.updateManySoftwareReferentLinksMutation = this.injector.get(UpdateManySoftwareReferentLinksDocument);
		this.deleteSoftwareReferentLinksMutation = this.injector.get(DeleteSoftwareReferentLinksDocument);
		this.saveReferentComponentSoftwareReferentLinksMutation = this.injector.get(SaveReferentComponentSoftwareReferentLinksDocument);
		this.saveSoftwareReferentComponentSoftwareReferentLinksMutation = this.injector.get(SaveSoftwareReferentComponentSoftwareReferentLinksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentLink> {
    
    		let variables: GetSoftwareReferentLinksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareReferentLink={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentLink> {
    
    		let variables: FirstSoftwareReferentLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareReferentLinksBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: FindSoftwareReferentLinksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: SearchSoftwareReferentLinksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareReferentLinksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareReferentLinksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: CustomQuerySoftwareReferentLinksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: CustomQueryIdSoftwareReferentLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareReferentLinksBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.used));
    	}

    	/**  */
    	@InjectArgs
    	public findReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: FindReferentComponentSoftwareReferentLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findReferentComponentSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.findReferentComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findNotReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotReferentComponentSoftwareReferentLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotReferentComponentSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.findNotReferentComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findSoftwareReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentLink,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentLink> {
    
    		let variables: FindSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findSoftwareReferentComponentSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.findSoftwareReferentComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findNotSoftwareReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftware,
		@Args('filter?') filter?: FilterOfSoftware,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftware> {
    
    		let variables: FindNotSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotSoftwareReferentComponentSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.findNotSoftwareReferentComponent));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareReferentLinksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareReferentLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareReferentLinkInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentLink> {
    
    		let variables: InsertSoftwareReferentLinksBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentLink> {
    
    		let variables: UpdateSoftwareReferentLinksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareReferentLinksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareReferentLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareReferentLinksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareReferentLinksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.delete));
    	}

    	/**  */
    	@InjectArgs
    	public saveReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('items?') items?: Array<Maybe<SoftwareReferentLinkInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SaveReferentComponentSoftwareReferentLinksBaseVariables = {
    			id: id,
			items: items
    		}
    				return this.saveReferentComponentSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.saveReferentComponent));
    	}

    	/**  */
    	@InjectArgs
    	public saveSoftwareReferentComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('items?') items?: Array<Maybe<SoftwareReferentLinkInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SaveSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
    			id: id,
			items: items
    		}
    				return this.saveSoftwareReferentComponentSoftwareReferentLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentLinks.saveSoftwareReferentComponent));
    	}
    
}