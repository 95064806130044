<cl-validation-error [message]="error"></cl-validation-error>
<dx-load-indicator class="button-indicator" [visible]="!isInitiliazed">
</dx-load-indicator>

<div class="list-component">
  <div id="input_container">
    <dx-text-box
      [value]="selectedValue"
      [readOnly]="true"
      (onFocusIn)="showColumnChooser = true"
    >
    </dx-text-box>
  </div>
  <dx-popup
    [ngClass]="'cl-popup-column-chooser'"
    [width]="400"
    [height]="'80%'"
    [showTitle]="true"
    [title]="'globals/fieldChooserSingular' | translate"
    [resizeEnabled]="true"
    [hideOnOutsideClick]="false"
    (onHidden)="onHidden($event)"
    [deferRendering]="true"
    [showCloseButton]="false"
    [(visible)]="showColumnChooser"
  >
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item [ratio]="1">
        <dx-load-indicator
          id="large-indicator"
          [visible]="loadWait"
        ></dx-load-indicator>
        <dx-tree-list
          (onSelectionChanged)="onSelectionChanged($event)"
          [height]="'100%'"
          [dataSource]="columns"
          [(selectedRowKeys)]="columnVisible"
          [visible]="!loadWait"
          itemsExpr="items"
          dataStructure="plain"
          keyExpr="id"
          parentIdExpr="parentId"
          class='field-chooser-tree-list-color'
          [showRowLines]="true"
          [showBorders]="true"
          [columnAutoWidth]="true"
          [searchPanel]="{
            visible: true
          }"
          [selection]="{
            allowSelectAll: false,
            mode: 'single',
            recursive: true
          }"
        >
          <dxi-column dataField="caption" caption="Nom"></dxi-column>
        </dx-tree-list>
      </dxi-item>

      <dxi-item [baseSize]="50" [visible]="!loadWait">
        <div style="text-align: right; padding-top: 5px">
          <dx-button
            [text]="'ok' | translate"
            type="default"
            (onClick)="ok($event)"
            [disabled]="disabledButton"
            class='field-chooser-button'
            [elementAttr]="{ class: 'load-button' }"
          >
          </dx-button>

          <dx-button
            [text]="'cancel' | translate"
            type="default"
            class='field-chooser-button'
            (onClick)="cancel($event)"
            [elementAttr]="{ class: 'load-button' }"
          >
          </dx-button>
        </div>
      </dxi-item>
    </dx-box>
  </dx-popup>
</div>
