import { IncidentBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs';
import { GqlField, GqlFields, GqlSubField, GqlSubFieldArg } from '../helpers';
import {
  FieldUpdateOperatorOfTicket,
  FilterOfCommentaryModel,
  FilterOfIncident,
  FilterOfIncidentModel,
  FilterOfResolutionCategory,
  FilterOfTicketTask,
  QueryContextOfCommentaryModel,
  QueryContextOfIncident,
  QueryContextOfIncidentModel,
  QueryContextOfResolutionCategory,
  QueryContextOfTicketTask,
  ServiceListResultOfCommentaryModel,
  ServiceListResultOfIncident,
  ServiceListResultOfIncidentModel,
  ServiceListResultOfResolutionModel,
  ServiceListResultOfTicketTask,
  Sort,
} from '../types';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { map } from 'rxjs/operators';
import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import { TranslatedFieldHelperService } from 'src/app/shared2/components/translate-field/translate-field-helper-service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { ReadOnlyAttribute } from '@clarilog/shared2/models/read-only-attribute';
import { ValidationErrorType } from '@clarilog/core/services/graphql/graphql-types';

@Injectable({ providedIn: 'root' })
@Authorize('incident')
export class IncidentCoreService extends IncidentBaseService {
  constructor(
    injector: Injector,
    public modelRuleApplicationService: ModelRuleApplicationService,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findIncident(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('myFavorite') myFavorite: boolean,
    @Args('options?') options?: QueryContextOfIncident,
    @Args('filter?') filter?: FilterOfIncident,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfIncident> {
    return this.find(fields, options, filter, extendedVariables);
  }

  public appointmentFields(): Array<GqlField | GqlSubField> {
    return [
      GqlSubField.create('data', [
        GqlField.create('allDay'),
        GqlField.create('description'),
        GqlField.create('endDate'),
        GqlField.create('endDateTimeZone'),
        GqlField.create('recurrenceException'),
        GqlField.create('recurrenceRule'),
        GqlField.create('startDate'),
        GqlField.create('startDateTimeZone'),
        GqlField.create('text'),
        GqlField.create('disabled'),
        GqlField.create('html'),
        GqlField.create('template'),
        GqlField.create('id'),
        GqlField.create('ticketId'),
        GqlField.create('color'),
        GqlField.create('operatorId'),
        GqlField.create('operatorTeamId'),
        GqlField.create('type'),
        GqlField.create('taskId'),
      ]),
    ];
  }

  /** Recupere */
  @InjectArgs
  public findFirstStatus(@Args('id?') id?: string) {
    let res = this.entryStatus(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      id,
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      return res;
    });
  }

  @InjectArgs
  public findResolutionModelPublic(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('ticketCategoryId?') ticketCategoryId?: string,
    @Args('resolutionCategoryId?') resolutionCategoryId?: string,
    @Args('options?') options?: QueryContextOfResolutionCategory,
    @Args('filter?') filter?: FilterOfResolutionCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionModel> {
    return super.findResolutionModel(
      fields,
      'public',
      ticketCategoryId,
      resolutionCategoryId,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findResolutionModelPrivate(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('ticketCategoryId?') ticketCategoryId?: string,
    @Args('resolutionCategoryId?') resolutionCategoryId?: string,
    @Args('options?') options?: QueryContextOfResolutionCategory,
    @Args('filter?') filter?: FilterOfResolutionCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionModel> {
    return super.findResolutionModel(
      fields,
      'private',
      ticketCategoryId,
      resolutionCategoryId,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('ticketNumber'),
        GqlField.create('id'),
        GqlSubField.create('userAffected', [
          GqlSubField.create('data', [GqlField.create('name')]),
        ]),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  @InjectArgs
  public findSortedByDateAssociatedTasks(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('tasks', fields, null, [
        GqlSubFieldArg.create('filterOfTasks', 'filter'),
        GqlSubFieldArg.create('optionsOfTasks', 'options'),
      ]),
    ]);
    if (options == undefined) {
      options = {};
    }

    options.sort = [{ order: Sort.Asc }];
    extendedVariables['filterOfTasks'] = filter;
    extendedVariables['optionsOfTasks'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.tasks),
    );
  }

  /**  */
  @InjectArgs
  public findModelByCategoryWithVisibility(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('isCategoryVisible?') isCategoryVisible?: boolean,
    @Args('ticketCategoryId?') ticketCategoryId?: string,
    @Args('options?') options?: QueryContextOfIncidentModel,
    @Args('filter?') filter?: FilterOfIncidentModel,
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('id?') id?: string,
  ): Observable<ServiceListResultOfIncidentModel> {
    if (isCategoryVisible === false || id != undefined) {
      ticketCategoryId = null;
    }
    return this.findModelByCategory(
      fields,
      ticketCategoryId,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public getCountElement(@Args('type') type: string) {
    switch (type) {
      case '':
      case 'open':
      case 'closed':
      case 'processed':
        return this.findAll([GqlField.create('totalCount')], type);
      case 'all':
        return this.findAll([GqlField.create('totalCount')], type);
      case 'my':
      case 'my-open':
      case 'my-closed':
      case 'my-processed':
        return this.findMyTicket([GqlField.create('totalCount')], type);
      case 'my-all':
        return this.findMyTicket([GqlField.create('totalCount')], type);
      case 'my-team':
      case 'my-team-open':
      case 'my-team-closed':
      case 'my-team-processed':
        return this.findMyTeamTicket([GqlField.create('totalCount')], type);
      case 'my-team-all':
        return this.findMyTeamTicket([GqlField.create('totalCount')], type);
    }
  }

  @InjectArgs
  public readonlyTemplate(
    @Args('errors') errors?: ValidationErrorType[],
  ): ReadOnlyAttribute {
    let entityId = errors.find((x) => x.property == 'EntityId')?.messageError;
    let entityame = errors.find(
      (x) => x.property == 'EntityDisplay',
    )?.messageError;

    let viewHelpMe = localStorage.getItem('viewHelpMe');
    return {
      display:
        TranslateService.get(`entities/incident/lockedTicketParent`) +
        entityame,
      url:
        viewHelpMe == 'true'
          ? `/help-desk-incident/my/edit/${entityId}`
          : `/incidents/edit/${entityId}`,
    };
  }

  @InjectArgs
  canReloadNavMenu(
    @Args('update') update: FieldUpdateOperatorOfTicket,
  ): boolean {
    // uniquement dans le cas dd'une mise à jour du status
    if (update?.set?.statusId != undefined) {
      return true;
    }

    return false;
  }

  @InjectArgs
  public findAffectationCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'affectationUsageType',
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findStatusChangeUsageTypeCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'statusChangeUsageType',
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findRemindAsCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'remindAsUsageType',
      options,
      filter,
      extendedVariables,
    );
  }

  /**
   * Permet d'appliquer les filtres sur les localisations et les organigrammes
   * @param element
   * @returns
   */
  applyArchitecturePathsTemplate(element: any) {
    // Location.path & OrganizationalUnit.Path
    if (element.field.includes('location.data.path')) {
      element['headerFilterLookup'] = {
        model: 'location',
        columnExp: 'locationId',
      };
      element['allowFiltering'] = true;
    }

    if (element.field.includes('organizationalUnit.data.path')) {
      element['headerFilterLookup'] = {
        model: 'organizational-unit',
        columnExp: 'organizationalUnitId',
      };
      element['allowFiltering'] = true;
    }

    return element;
  }
}
