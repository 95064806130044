import { TicketTaskBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-incident-model')

export class TicketModelTaskCoreService extends TicketTaskBaseService {
  constructor(
    injector: Injector,
  ) {
    super(injector);
  }

  public getService(): TicketModelTaskCoreService {
    return this;
  }

  
}
