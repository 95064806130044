import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStatisticTasksBaseVariables, FirstStatisticTasksBaseVariables, CountStatisticTasksBaseVariables, FindStatisticTasksBaseVariables, SearchStatisticTasksBaseVariables, ExportSchemaStatisticTasksBaseVariables, ExportDataStatisticTasksBaseVariables, CustomQueryStatisticTasksBaseVariables, CustomQueryIdStatisticTasksBaseVariables, UsedStatisticTasksBaseVariables, ExistStatisticTasksBaseVariables, InsertStatisticTasksBaseVariables, UpdateStatisticTasksBaseVariables, ImportDataStatisticTasksBaseVariables, UpdateManyStatisticTasksBaseVariables, DeleteStatisticTasksBaseVariables } from '../gqls'
import { GetStatisticTasksDocument, FirstStatisticTasksDocument, CountStatisticTasksDocument, FindStatisticTasksDocument, SearchStatisticTasksDocument, ExportSchemaStatisticTasksDocument, ExportDataStatisticTasksDocument, CustomQueryStatisticTasksDocument, CustomQueryIdStatisticTasksDocument, UsedStatisticTasksDocument, ExistStatisticTasksDocument, InsertStatisticTasksDocument, UpdateStatisticTasksDocument, ImportDataStatisticTasksDocument, UpdateManyStatisticTasksDocument, DeleteStatisticTasksDocument } from '../gqls'
import { ServiceSingleResultOfStatisticTask, QueryContextOfStatisticTask, FilterOfStatisticTask, ServiceSingleResultOfInt64, ServiceListResultOfStatisticTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, StatisticTaskInput, FieldUpdateOperatorOfStatisticTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StatisticTaskBaseService {
    
public modelName = 'statisticTask';
public modelPluralName = 'statisticTasks';

	private getStatisticTasksQuery: GetStatisticTasksDocument;
	private firstStatisticTasksQuery: FirstStatisticTasksDocument;
	private countStatisticTasksQuery: CountStatisticTasksDocument;
	private findStatisticTasksQuery: FindStatisticTasksDocument;
	private searchStatisticTasksQuery: SearchStatisticTasksDocument;
	private exportSchemaStatisticTasksQuery: ExportSchemaStatisticTasksDocument;
	private exportDataStatisticTasksQuery: ExportDataStatisticTasksDocument;
	private customQueryStatisticTasksQuery: CustomQueryStatisticTasksDocument;
	private customQueryIdStatisticTasksQuery: CustomQueryIdStatisticTasksDocument;
	private usedStatisticTasksQuery: UsedStatisticTasksDocument;
	private existStatisticTasksQuery: ExistStatisticTasksDocument;
	private insertStatisticTasksMutation: InsertStatisticTasksDocument;
	private updateStatisticTasksMutation: UpdateStatisticTasksDocument;
	private importDataStatisticTasksMutation: ImportDataStatisticTasksDocument;
	private updateManyStatisticTasksMutation: UpdateManyStatisticTasksDocument;
	private deleteStatisticTasksMutation: DeleteStatisticTasksDocument;

	constructor(private injector: Injector) {
		this.getStatisticTasksQuery = this.injector.get(GetStatisticTasksDocument);
		this.firstStatisticTasksQuery = this.injector.get(FirstStatisticTasksDocument);
		this.countStatisticTasksQuery = this.injector.get(CountStatisticTasksDocument);
		this.findStatisticTasksQuery = this.injector.get(FindStatisticTasksDocument);
		this.searchStatisticTasksQuery = this.injector.get(SearchStatisticTasksDocument);
		this.exportSchemaStatisticTasksQuery = this.injector.get(ExportSchemaStatisticTasksDocument);
		this.exportDataStatisticTasksQuery = this.injector.get(ExportDataStatisticTasksDocument);
		this.customQueryStatisticTasksQuery = this.injector.get(CustomQueryStatisticTasksDocument);
		this.customQueryIdStatisticTasksQuery = this.injector.get(CustomQueryIdStatisticTasksDocument);
		this.usedStatisticTasksQuery = this.injector.get(UsedStatisticTasksDocument);
		this.existStatisticTasksQuery = this.injector.get(ExistStatisticTasksDocument);
		this.insertStatisticTasksMutation = this.injector.get(InsertStatisticTasksDocument);
		this.updateStatisticTasksMutation = this.injector.get(UpdateStatisticTasksDocument);
		this.importDataStatisticTasksMutation = this.injector.get(ImportDataStatisticTasksDocument);
		this.updateManyStatisticTasksMutation = this.injector.get(UpdateManyStatisticTasksDocument);
		this.deleteStatisticTasksMutation = this.injector.get(DeleteStatisticTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatisticTask> {
    
    		let variables: GetStatisticTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.get));
            }
            else{
                let result:ServiceSingleResultOfStatisticTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStatisticTask,
		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatisticTask> {
    
    		let variables: FirstStatisticTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStatisticTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStatisticTask,
		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatisticTask> {
    
    		let variables: FindStatisticTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStatisticTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatisticTask> {
    
    		let variables: SearchStatisticTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStatisticTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStatisticTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStatisticTask,
		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatisticTask> {
    
    		let variables: CustomQueryStatisticTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStatisticTask,
		@Args('filter?') filter?: FilterOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatisticTask> {
    
    		let variables: CustomQueryIdStatisticTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStatisticTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStatisticTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStatisticTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StatisticTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatisticTask> {
    
    		let variables: InsertStatisticTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertStatisticTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatisticTask> {
    
    		let variables: UpdateStatisticTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStatisticTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStatisticTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStatisticTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStatisticTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStatisticTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStatisticTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStatisticTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStatisticTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statisticTasks.delete));
    	}
    
}