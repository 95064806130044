import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowIntervalTasksBaseVariables, FirstWorkflowIntervalTasksBaseVariables, CountWorkflowIntervalTasksBaseVariables, FindWorkflowIntervalTasksBaseVariables, SearchWorkflowIntervalTasksBaseVariables, ExportSchemaWorkflowIntervalTasksBaseVariables, ExportDataWorkflowIntervalTasksBaseVariables, CustomQueryWorkflowIntervalTasksBaseVariables, CustomQueryIdWorkflowIntervalTasksBaseVariables, UsedWorkflowIntervalTasksBaseVariables, ExistWorkflowIntervalTasksBaseVariables, InsertWorkflowIntervalTasksBaseVariables, UpdateWorkflowIntervalTasksBaseVariables, ImportDataWorkflowIntervalTasksBaseVariables, UpdateManyWorkflowIntervalTasksBaseVariables, DeleteWorkflowIntervalTasksBaseVariables } from '../gqls'
import { GetWorkflowIntervalTasksDocument, FirstWorkflowIntervalTasksDocument, CountWorkflowIntervalTasksDocument, FindWorkflowIntervalTasksDocument, SearchWorkflowIntervalTasksDocument, ExportSchemaWorkflowIntervalTasksDocument, ExportDataWorkflowIntervalTasksDocument, CustomQueryWorkflowIntervalTasksDocument, CustomQueryIdWorkflowIntervalTasksDocument, UsedWorkflowIntervalTasksDocument, ExistWorkflowIntervalTasksDocument, InsertWorkflowIntervalTasksDocument, UpdateWorkflowIntervalTasksDocument, ImportDataWorkflowIntervalTasksDocument, UpdateManyWorkflowIntervalTasksDocument, DeleteWorkflowIntervalTasksDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowIntervalTask, QueryContextOfWorkflowIntervalTask, FilterOfWorkflowIntervalTask, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowIntervalTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowIntervalTaskInput, FieldUpdateOperatorOfWorkflowIntervalTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowIntervalTaskBaseService {
    
public modelName = 'workflowIntervalTask';
public modelPluralName = 'workflowIntervalTasks';

	private getWorkflowIntervalTasksQuery: GetWorkflowIntervalTasksDocument;
	private firstWorkflowIntervalTasksQuery: FirstWorkflowIntervalTasksDocument;
	private countWorkflowIntervalTasksQuery: CountWorkflowIntervalTasksDocument;
	private findWorkflowIntervalTasksQuery: FindWorkflowIntervalTasksDocument;
	private searchWorkflowIntervalTasksQuery: SearchWorkflowIntervalTasksDocument;
	private exportSchemaWorkflowIntervalTasksQuery: ExportSchemaWorkflowIntervalTasksDocument;
	private exportDataWorkflowIntervalTasksQuery: ExportDataWorkflowIntervalTasksDocument;
	private customQueryWorkflowIntervalTasksQuery: CustomQueryWorkflowIntervalTasksDocument;
	private customQueryIdWorkflowIntervalTasksQuery: CustomQueryIdWorkflowIntervalTasksDocument;
	private usedWorkflowIntervalTasksQuery: UsedWorkflowIntervalTasksDocument;
	private existWorkflowIntervalTasksQuery: ExistWorkflowIntervalTasksDocument;
	private insertWorkflowIntervalTasksMutation: InsertWorkflowIntervalTasksDocument;
	private updateWorkflowIntervalTasksMutation: UpdateWorkflowIntervalTasksDocument;
	private importDataWorkflowIntervalTasksMutation: ImportDataWorkflowIntervalTasksDocument;
	private updateManyWorkflowIntervalTasksMutation: UpdateManyWorkflowIntervalTasksDocument;
	private deleteWorkflowIntervalTasksMutation: DeleteWorkflowIntervalTasksDocument;

	constructor(private injector: Injector) {
		this.getWorkflowIntervalTasksQuery = this.injector.get(GetWorkflowIntervalTasksDocument);
		this.firstWorkflowIntervalTasksQuery = this.injector.get(FirstWorkflowIntervalTasksDocument);
		this.countWorkflowIntervalTasksQuery = this.injector.get(CountWorkflowIntervalTasksDocument);
		this.findWorkflowIntervalTasksQuery = this.injector.get(FindWorkflowIntervalTasksDocument);
		this.searchWorkflowIntervalTasksQuery = this.injector.get(SearchWorkflowIntervalTasksDocument);
		this.exportSchemaWorkflowIntervalTasksQuery = this.injector.get(ExportSchemaWorkflowIntervalTasksDocument);
		this.exportDataWorkflowIntervalTasksQuery = this.injector.get(ExportDataWorkflowIntervalTasksDocument);
		this.customQueryWorkflowIntervalTasksQuery = this.injector.get(CustomQueryWorkflowIntervalTasksDocument);
		this.customQueryIdWorkflowIntervalTasksQuery = this.injector.get(CustomQueryIdWorkflowIntervalTasksDocument);
		this.usedWorkflowIntervalTasksQuery = this.injector.get(UsedWorkflowIntervalTasksDocument);
		this.existWorkflowIntervalTasksQuery = this.injector.get(ExistWorkflowIntervalTasksDocument);
		this.insertWorkflowIntervalTasksMutation = this.injector.get(InsertWorkflowIntervalTasksDocument);
		this.updateWorkflowIntervalTasksMutation = this.injector.get(UpdateWorkflowIntervalTasksDocument);
		this.importDataWorkflowIntervalTasksMutation = this.injector.get(ImportDataWorkflowIntervalTasksDocument);
		this.updateManyWorkflowIntervalTasksMutation = this.injector.get(UpdateManyWorkflowIntervalTasksDocument);
		this.deleteWorkflowIntervalTasksMutation = this.injector.get(DeleteWorkflowIntervalTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervalTask> {
    
    		let variables: GetWorkflowIntervalTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowIntervalTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowIntervalTask,
		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervalTask> {
    
    		let variables: FirstWorkflowIntervalTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowIntervalTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowIntervalTask,
		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervalTask> {
    
    		let variables: FindWorkflowIntervalTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowIntervalTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervalTask> {
    
    		let variables: SearchWorkflowIntervalTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowIntervalTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowIntervalTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowIntervalTask,
		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervalTask> {
    
    		let variables: CustomQueryWorkflowIntervalTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowIntervalTask,
		@Args('filter?') filter?: FilterOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowIntervalTask> {
    
    		let variables: CustomQueryIdWorkflowIntervalTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowIntervalTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowIntervalTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowIntervalTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowIntervalTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervalTask> {
    
    		let variables: InsertWorkflowIntervalTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowIntervalTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowIntervalTask> {
    
    		let variables: UpdateWorkflowIntervalTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowIntervalTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowIntervalTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowIntervalTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowIntervalTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowIntervalTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowIntervalTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowIntervalTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowIntervalTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowIntervalTasks.delete));
    	}
    
}