import { OutStockMovementBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import {
  QueryContextOfOutStockMovement,
  FilterOfOutStockMovement,
  ServiceListResultOfOutStockMovement,
} from '../types';
import { AssetModelCoreService } from './asset-model.service';

@Injectable({ providedIn: 'root' })
@Authorize('out-stock')
export class OutStockMovementCoreService extends OutStockMovementBaseService {
  constructor(injector: Injector, private testeur: AssetModelCoreService) {
    super(injector);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public test(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfOutStockMovement,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfOutStockMovement,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfOutStockMovement> {
    if (filter != null) {
    } else {
      filter = { recipientId: { eq: id } };
    }

    return this.find(fields, options, filter, extendedVariables);
  }
}
