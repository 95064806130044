import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfSoftwareReferentLink, FilterOfSoftwareReferentLink, QueryContextOfSoftwareReferentLink, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareReferentLink, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfSoftware, FilterOfSoftware, QueryContextOfSoftware, SoftwareReferentLinkInput, FieldUpdateOperatorOfSoftwareReferentLink } from '../types'

export type GetSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        get?: Maybe<ServiceSingleResultOfSoftwareReferentLink>
    }
}

export type GetSoftwareReferentLinksBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        first?: Maybe<ServiceSingleResultOfSoftwareReferentLink>
    }
}

export type FirstSoftwareReferentLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareReferentLink = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountSoftwareReferentLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        find?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type FindSoftwareReferentLinksBaseVariables = {
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfSoftwareReferentLink = null, $filter: FilterOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        search?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type SearchSoftwareReferentLinksBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaSoftwareReferentLinksBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataSoftwareReferentLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQuerySoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        customQuery?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type CustomQuerySoftwareReferentLinksBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQuerySoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfSoftwareReferentLink = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQuerySoftwareReferentLinks ${args} {
    softwareReferentLinks {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQuerySoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQuerySoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        customQueryId?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type CustomQueryIdSoftwareReferentLinksBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfSoftwareReferentLink = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedSoftwareReferentLinksBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        findReferentComponent?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type FindReferentComponentSoftwareReferentLinksBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftwareReferentLink = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        findReferentComponent(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        findNotReferentComponent?: Maybe<ServiceListResultOfUser>
    }
}

export type FindNotReferentComponentSoftwareReferentLinksBaseVariables = {
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        findNotReferentComponent(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSoftwareReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        findSoftwareReferentComponent?: Maybe<ServiceListResultOfSoftwareReferentLink>
    }
}

export type FindSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftwareReferentLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareReferentLink>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSoftwareReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftwareReferentLink = null, $options: QueryContextOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSoftwareReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        findSoftwareReferentComponent(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSoftwareReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSoftwareReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotSoftwareReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        findNotSoftwareReferentComponent?: Maybe<ServiceListResultOfSoftware>
    }
}

export type FindNotSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
	filter: Maybe<FilterOfSoftware>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftware>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotSoftwareReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftware = null, $options: QueryContextOfSoftware = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotSoftwareReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        findNotSoftwareReferentComponent(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotSoftwareReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotSoftwareReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftware", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistSoftwareReferentLinksBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        insert?: Maybe<ServiceSingleResultOfSoftwareReferentLink>
    }
}

export type InsertSoftwareReferentLinksBaseVariables = {
	entity: SoftwareReferentLinkInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: SoftwareReferentLinkInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        update?: Maybe<ServiceSingleResultOfSoftwareReferentLink>
    }
}

export type UpdateSoftwareReferentLinksBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareReferentLink>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareReferentLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataSoftwareReferentLinksBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManySoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManySoftwareReferentLinksBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareReferentLink>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManySoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfSoftwareReferentLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManySoftwareReferentLinks ${args} {
    softwareReferentLinks {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManySoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManySoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteSoftwareReferentLinksBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SaveReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        saveReferentComponent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SaveReferentComponentSoftwareReferentLinksBaseVariables = {
	id: string, /**  */
	items: Array<Maybe<SoftwareReferentLinkInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SaveReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $items: [SoftwareReferentLinkInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation saveReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        saveReferentComponent(id: $id, items: $items) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SaveReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SaveReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SaveSoftwareReferentComponentSoftwareReferentLinksResultType = {
    softwareReferentLinks: {
        saveSoftwareReferentComponent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SaveSoftwareReferentComponentSoftwareReferentLinksBaseVariables = {
	id: string, /**  */
	items: Array<Maybe<SoftwareReferentLinkInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SaveSoftwareReferentComponentSoftwareReferentLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $items: [SoftwareReferentLinkInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation saveSoftwareReferentComponentSoftwareReferentLinks ${args} {
    softwareReferentLinks {
        saveSoftwareReferentComponent(id: $id, items: $items) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SaveSoftwareReferentComponentSoftwareReferentLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SaveSoftwareReferentComponentSoftwareReferentLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
