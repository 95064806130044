import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfPhysicalMemoryArray, FilterOfPhysicalMemoryArray, QueryContextOfPhysicalMemoryArray, ServiceSingleResultOfInt64, ServiceListResultOfPhysicalMemoryArray, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

export type GetPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        get?: Maybe<ServiceSingleResultOfPhysicalMemoryArray>
    }
}

export type GetPhysicalMemoryArraysBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        first?: Maybe<ServiceSingleResultOfPhysicalMemoryArray>
    }
}

export type FirstPhysicalMemoryArraysBaseVariables = {
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPhysicalMemoryArray>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPhysicalMemoryArray = null, $options: QueryContextOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountPhysicalMemoryArraysBaseVariables = {
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        find?: Maybe<ServiceListResultOfPhysicalMemoryArray>
    }
}

export type FindPhysicalMemoryArraysBaseVariables = {
	options: Maybe<QueryContextOfPhysicalMemoryArray>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfPhysicalMemoryArray = null, $filter: FilterOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        search?: Maybe<ServiceListResultOfPhysicalMemoryArray>
    }
}

export type SearchPhysicalMemoryArraysBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfPhysicalMemoryArray>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaPhysicalMemoryArraysBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataPhysicalMemoryArraysBaseVariables = {
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        customQuery?: Maybe<ServiceListResultOfPhysicalMemoryArray>
    }
}

export type CustomQueryPhysicalMemoryArraysBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPhysicalMemoryArray>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfPhysicalMemoryArray = null, $options: QueryContextOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        customQueryId?: Maybe<ServiceListResultOfPhysicalMemoryArray>
    }
}

export type CustomQueryIdPhysicalMemoryArraysBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfPhysicalMemoryArray>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPhysicalMemoryArray>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfPhysicalMemoryArray = null, $options: QueryContextOfPhysicalMemoryArray = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPhysicalMemoryArray", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedPhysicalMemoryArraysBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistPhysicalMemoryArraysResultType = {
    physicalMemoryArrays: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistPhysicalMemoryArraysBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistPhysicalMemoryArraysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existPhysicalMemoryArrays ${args} {
    physicalMemoryArrays {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistPhysicalMemoryArraysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistPhysicalMemoryArraysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

