import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFaxCountsBaseVariables, FirstFaxCountsBaseVariables, CountFaxCountsBaseVariables, FindFaxCountsBaseVariables, SearchFaxCountsBaseVariables, ExportSchemaFaxCountsBaseVariables, ExportDataFaxCountsBaseVariables, CustomQueryFaxCountsBaseVariables, CustomQueryIdFaxCountsBaseVariables, UsedFaxCountsBaseVariables, ExistFaxCountsBaseVariables } from '../gqls'
import { GetFaxCountsDocument, FirstFaxCountsDocument, CountFaxCountsDocument, FindFaxCountsDocument, SearchFaxCountsDocument, ExportSchemaFaxCountsDocument, ExportDataFaxCountsDocument, CustomQueryFaxCountsDocument, CustomQueryIdFaxCountsDocument, UsedFaxCountsDocument, ExistFaxCountsDocument } from '../gqls'
import { ServiceSingleResultOfFaxCount, QueryContextOfFaxCount, FilterOfFaxCount, ServiceSingleResultOfInt64, ServiceListResultOfFaxCount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FaxCountBaseService {
    
public modelName = 'faxCount';
public modelPluralName = 'faxCounts';

	private getFaxCountsQuery: GetFaxCountsDocument;
	private firstFaxCountsQuery: FirstFaxCountsDocument;
	private countFaxCountsQuery: CountFaxCountsDocument;
	private findFaxCountsQuery: FindFaxCountsDocument;
	private searchFaxCountsQuery: SearchFaxCountsDocument;
	private exportSchemaFaxCountsQuery: ExportSchemaFaxCountsDocument;
	private exportDataFaxCountsQuery: ExportDataFaxCountsDocument;
	private customQueryFaxCountsQuery: CustomQueryFaxCountsDocument;
	private customQueryIdFaxCountsQuery: CustomQueryIdFaxCountsDocument;
	private usedFaxCountsQuery: UsedFaxCountsDocument;
	private existFaxCountsQuery: ExistFaxCountsDocument;

	constructor(private injector: Injector) {
		this.getFaxCountsQuery = this.injector.get(GetFaxCountsDocument);
		this.firstFaxCountsQuery = this.injector.get(FirstFaxCountsDocument);
		this.countFaxCountsQuery = this.injector.get(CountFaxCountsDocument);
		this.findFaxCountsQuery = this.injector.get(FindFaxCountsDocument);
		this.searchFaxCountsQuery = this.injector.get(SearchFaxCountsDocument);
		this.exportSchemaFaxCountsQuery = this.injector.get(ExportSchemaFaxCountsDocument);
		this.exportDataFaxCountsQuery = this.injector.get(ExportDataFaxCountsDocument);
		this.customQueryFaxCountsQuery = this.injector.get(CustomQueryFaxCountsDocument);
		this.customQueryIdFaxCountsQuery = this.injector.get(CustomQueryIdFaxCountsDocument);
		this.usedFaxCountsQuery = this.injector.get(UsedFaxCountsDocument);
		this.existFaxCountsQuery = this.injector.get(ExistFaxCountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFaxCount> {
    
    		let variables: GetFaxCountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.get));
            }
            else{
                let result:ServiceSingleResultOfFaxCount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFaxCount,
		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFaxCount> {
    
    		let variables: FirstFaxCountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFaxCountsBaseVariables = {
    			filter: filter
    		}
    				return this.countFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFaxCount,
		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFaxCount> {
    
    		let variables: FindFaxCountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFaxCount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFaxCount> {
    
    		let variables: SearchFaxCountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFaxCountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFaxCountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFaxCount,
		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFaxCount> {
    
    		let variables: CustomQueryFaxCountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFaxCount,
		@Args('filter?') filter?: FilterOfFaxCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFaxCount> {
    
    		let variables: CustomQueryIdFaxCountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFaxCountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFaxCountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFaxCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.faxCounts.exist));
    	}
    
    
}