import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfOdbcDriver, FilterOfOdbcDriver, QueryContextOfOdbcDriver, ServiceSingleResultOfInt64, ServiceListResultOfOdbcDriver, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

export type GetOdbcDriversResultType = {
    odbcDrivers: {
        get?: Maybe<ServiceSingleResultOfOdbcDriver>
    }
}

export type GetOdbcDriversBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getOdbcDrivers ${args} {
    odbcDrivers {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstOdbcDriversResultType = {
    odbcDrivers: {
        first?: Maybe<ServiceSingleResultOfOdbcDriver>
    }
}

export type FirstOdbcDriversBaseVariables = {
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOdbcDriver>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOdbcDriver = null, $options: QueryContextOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstOdbcDrivers ${args} {
    odbcDrivers {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountOdbcDriversResultType = {
    odbcDrivers: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountOdbcDriversBaseVariables = {
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countOdbcDrivers ${args} {
    odbcDrivers {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOdbcDriversResultType = {
    odbcDrivers: {
        find?: Maybe<ServiceListResultOfOdbcDriver>
    }
}

export type FindOdbcDriversBaseVariables = {
	options: Maybe<QueryContextOfOdbcDriver>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfOdbcDriver = null, $filter: FilterOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOdbcDrivers ${args} {
    odbcDrivers {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchOdbcDriversResultType = {
    odbcDrivers: {
        search?: Maybe<ServiceListResultOfOdbcDriver>
    }
}

export type SearchOdbcDriversBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfOdbcDriver>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchOdbcDrivers ${args} {
    odbcDrivers {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaOdbcDriversResultType = {
    odbcDrivers: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaOdbcDriversBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaOdbcDrivers ${args} {
    odbcDrivers {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataOdbcDriversResultType = {
    odbcDrivers: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataOdbcDriversBaseVariables = {
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataOdbcDrivers ${args} {
    odbcDrivers {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryOdbcDriversResultType = {
    odbcDrivers: {
        customQuery?: Maybe<ServiceListResultOfOdbcDriver>
    }
}

export type CustomQueryOdbcDriversBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOdbcDriver>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfOdbcDriver = null, $options: QueryContextOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryOdbcDrivers ${args} {
    odbcDrivers {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdOdbcDriversResultType = {
    odbcDrivers: {
        customQueryId?: Maybe<ServiceListResultOfOdbcDriver>
    }
}

export type CustomQueryIdOdbcDriversBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOdbcDriver>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOdbcDriver>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOdbcDriver = null, $options: QueryContextOfOdbcDriver = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdOdbcDrivers ${args} {
    odbcDrivers {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOdbcDriver", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedOdbcDriversResultType = {
    odbcDrivers: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedOdbcDriversBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedOdbcDrivers ${args} {
    odbcDrivers {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistOdbcDriversResultType = {
    odbcDrivers: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistOdbcDriversBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistOdbcDriversDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existOdbcDrivers ${args} {
    odbcDrivers {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistOdbcDriversBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistOdbcDriversResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

