<clc-manage-list
  [title]="model.model.title"
  [modelState]="model"
  [service]="fileManagerService"
  [canImport]="false"
  [canAdd]="false"
  [canEdit]="false"
  [canRecycle]="false"
  [(selectedData)]="selectedData"
  [(selectedKeys)]="selectedKeys"
  (onSingleCellClick)="onSelectedFileOpened($event)"
  (onDeleted)="onDeleted($event)"
>
</clc-manage-list>

<clc-file-manager-viewer
  [fileViewerInfo]="fileViewerInfo"
  [closable]="true"
></clc-file-manager-viewer>
