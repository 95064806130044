
import { LayoutModelBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { LayoutModelCategory, ServiceSingleResultOfBoolean } from '@clarilog/core/services2/graphql/generated-types/types'
import { GqlField, GqlSubField } from '../helpers';

@Injectable({ providedIn: 'root' })
export class LayoutModelCoreService extends LayoutModelBaseService {
    constructor(injector: Injector) {
        super(injector)
    }

    // getModel(fields: Array<GqlSubField | GqlField>, type: LayoutModelCategory, name: string): Observable<string>;
    // getModel(
    //     fields: Array<GqlSubField | GqlField>,
    //     type: LayoutModelCategory,
    //     name: string,
    //     id: string,
    // ): Observable<string>;
    // getModel(
    //     fields: Array<GqlSubField | GqlField>,
    //     type: LayoutModelCategory,
    //     name: string,
    //     ...args: any[]
    // ): Observable<string> {
    //     // const filter = {
    //     //     entityName: name,
    //     //     entityId: args[0],
    //     //     type: type,
    //     // };

    //     const filter : FilterOfLayoutModel = {

    //     }

    //     this.first(fields, null, filter)

    //     // const query = this.first(fields)
    //     // return query
    //     //     .pipe(map(result => result.data.layoutModels.getModel.data))
    //     //     .pipe(first());
    // }
    // setModel(
    //     type: LayoutModelCategory,
    //     name: string,
    //     value: string,
    // ): Observable<ServiceSingleResultOfBoolean>;
    // setModel(
    //     type: LayoutModelCategory,
    //     name: string,
    //     id: string,
    //     value: string,
    // ): Observable<ServiceSingleResultOfBoolean>;
    // setModel(
    //     type: LayoutModelCategory,
    //     name: string,
    //     ...args: any[]
    // ): Observable<ServiceSingleResultOfBoolean> {
    //     let value = undefined;
    //     let entityId = undefined;

    //     if (args.length === 1) {
    //         value = args[0];
    //     } else {
    //         value = args[1];
    //         entityId = args[0];
    //     }
    //     const variables = {
    //         entityName: name,
    //         value: value,
    //         type: type,
    //         entityId: entityId,
    //     };
    //     const query = this.setMutation.mutate(variables);
    //     return query
    //         .pipe(map(result => result.data.layoutModels.setModel))
    //         .pipe(first());
    // }

}