import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { GC, GCFactory, QueryFilterInputType } from '@clarilog/core';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import { CommonCoreService } from '@clarilog/core/services2/graphql/generated-types/services/common.service';
import { LoanStatusCoreService } from '@clarilog/core/services2/graphql/generated-types/services/loan-status.service';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { CoreModelCompilerService } from '../../services/compiler/model-compiler.service';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { CoreCustomOperationService } from '../list/list/custom-operation.service';
import { TemplatesService } from '../templates/templates.service';
import { FilterBuilderExtensionService } from './services/filter-builder-extension.service';
import { CustomFilterOperation } from '@clarilog/core/services2/graphql/generated-types/custom-types';

/** Représente la classe du composent cl-filter-builder. */
@Component({
  selector: 'clc-filter-builder',
  templateUrl: './filter-builder.component.html',
  styleUrls: ['./filter-builder.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFilterBuilderComponent),
      multi: true,
    },
  ],
})
export class CoreFilterBuilderComponent
  implements ControlValueAccessor, OnInit
{
  /** Sauvegarde les valeurs. */
  _values: Array<QueryFilterInputType> = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;

  /** Obtient ou définit la source de données. */
  @Input() source: any;
  /** Definit le type sur lequel ce base le filterBuilder */
  @Input() type: any;

  /** Obtient ou définit si le choix 'A changé d valeur' est disponible dans les options */
  @Input() useChangeValue: boolean = false;

  /** Obtient ou définit la source de données. */
  fields: any[] = [];

  /** Vérifie si l'on est déjà passé dans le onContenteReady */
  added = false;
  /** Indique que le composant a bien été initialisé */
  isInitialize = false;

  /** instancie le composant */
  component: any;

  /**Contient les valeurs par défaut*/
  DefaultValues: any[] = [];

  /** */
  isManaged: boolean = false;

  /** Obtient ou définit la operation personalisé. */
  customOperations: Array<any>;

  /** Variable temporaire d'initialisation de la valeur */
  tempValues;

  /** Obtient ou définit la liste des type du temps. */
  enumTimeType: any;

  /** Obtient ou définit la liste des pérriode par defaut. */
  enumDefaultTime: any;

  dropDownComponent: any;
  filterComponent: any;

  /** Represente la methode pour avoir la methode à utiliser */
  @Input() useMethod: ModelFnContext;

  /** Represente la methode pour avoir la methode pour charger des custom operation */
  @Input() customFilterOperations: ModelFnContext;

  /** Obtient ou définit les valeurs. */
  get values(): Array<any> {
    return this._values;
  }

  writeValueInit: boolean = false;
  set values(values: Array<any>) {
    if (
      values != undefined &&
      values !== this._values &&
      !(Array.isArray(values) && values.length == 0)
    ) {
      try {
        if (typeof values[0] != 'string' && Array.isArray(values[0]) == false) {
          if (values.length > 0 && values[0].name !== undefined) {
            this.recursiftWriteValues(values);
            values = CriteriaHelpers.decompile(values, this.fields);
          } else if (
            values[0].groupOperation === '!' &&
            (values[0].groups as Array<any>)?.length > 0
          ) {
            values = CriteriaHelpers.decompile(values, this.fields);
          } else if (values[0].groupOperation === '!') {
            values.reverse();
            values = CriteriaHelpers.decompile(values, this.fields);
          } else {
            console.error('error decompile');
            values = [];
          }
        }
        // maybe unstable
        // if (values.length === 4) {
        //   let value = values[0];
        //   let foundedField = this.fields.find(x => x['dataField'] === value);

        //   value[3] = foundedField['keyName'];
        // }

        this._values = values;
        if (this.isInitialize && this.writeValueInit === false) {
          let compile = CriteriaHelpers.compile(this._values, this.fields);
          this.onChange(JSON.stringify(compile));
          this.onTouched();
        }
      } catch (error) {
        this.isManaged = true;
      }
    } else if (values == undefined) {
      if (this.isInitialize && this.writeValueInit === false) {
        this._values = undefined;
        this.onChange(undefined);
        this.onTouched();
      }
    }
  }

  /** Boucle sur l'ensemble des groupes */
  recursiftWriteValues(values) {
    if (values != undefined && values.length > 0) {
      values.forEach((val) => {
        if (val.groups != undefined && val.groups.length > 0) {
          for (let i = 0; i < val.groups.length; i++) {
            this.recursiftWriteValues(val.groups[i]);
            this.checkValue(val.groups[i]);
          }
        }

        this.checkValue(val);
      });
    }
  }

  /** Check la valeur */
  checkValue(val) {
    if (val != undefined) {
      if (val['value'] == undefined) {
        val['value'] = null;
      }
      this.fields.forEach((field) => {
        if (
          field['keyPathName'] != undefined &&
          val['name'] != undefined &&
          field['dataField'] != undefined &&
          field['keyPathName']?.toLowerCase() == val['name']?.toLowerCase()
        ) {
          val['name'] = field['dataField'];
        }

        if (field['dataField'] == val['name']) {
          if (field['keyName'] != undefined && val['keyName'] == undefined) {
            val['keyName'] = field['keyName'];
          }
          if (field['dataType'] != typeof val['value']) {
            // if (
            //   field['dataType'] == 'object' &&
            //   val['value'] == undefined &&
            //   val['operation'] == '='
            // ) {
            //   val['value'] = null;
            // } else if (
            //   field['dataType'] == 'object' &&
            //   val['value'] == undefined &&
            //   val['operation'] == '<>'
            // ) {
            //   val['value'] = null;
            // } else
            if (field['dataType'] == 'datetime') {
              if (
                !this.customOperations
                  .map((x) => x['name'])
                  .includes(val['operation'])
              ) {
                if (
                  (val['value'] == undefined && val['operation'] == '=') ||
                  (val['value'] == undefined && val['operation'] == '<>')
                ) {
                  debugger;
                } else {
                  if (val['value'] != undefined) {
                    if (val['operation'] != 'between') {
                      let changedDate = new Date(val['value']);
                      val['value'] = changedDate;
                    } else {
                      if (val['value'].length != undefined) {
                        if (val['value'].length > 0) {
                          let changedDate = new Date(val['value'][0]);
                          val['value'][0] = changedDate;
                        }
                        if (val['value'].length > 1) {
                          let changedDate = new Date(val['value'][1]);
                          val['value'][1] = changedDate;
                        }
                      }
                    }
                  }
                }
              }
            } else if (field['dataType'] == 'ID') {
              if (
                val['value'] != undefined &&
                val['value'].indexOf() < 0 &&
                val['value'].length == 32
              ) {
                var rxGetGuidGroups = /(\w{8})(\w{4})(\w{4})(\w{4})(\w{12})/;
                val['value'] = val['value'].replace(
                  rxGetGuidGroups,
                  '$1-$2-$3-$4-$5',
                );
              }
            }
          }
        }
      });
    }
  }

  @Input() fieldName: string;
  @Input() state: ModelState;

  formatSliderTooltip(value) {
    return value + ' | ' + (value * 100).toFixed(1) + '%';
  }

  gc: GC;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private commonService: CommonCoreService,
    private loanStatusService: LoanStatusCoreService,
    public customOperationService: CoreCustomOperationService,
    private filterExtension: FilterBuilderExtensionService,
    private formGroupDirective: FormGroupDirective,
    private route: ActivatedRoute,
    public templateService: TemplatesService,
    public gcFactory: GCFactory,
  ) {
    this.gc = this.gcFactory.create();
    this.customOperations = [
      this.customOperationService.customDateOperation,
      this.customOperationService.customDeadlineDateOperation,
      this.customOperationService.customDefaultDateOperation,
      this.customOperationService.customBetweenOperation,
      this.customOperationService.customSelectValues,
      this.customOperationService.customArrayEq,
      this.customOperationService.customArrayNotEq,
      this.customOperationService.customArrayMoreThan,
      this.customOperationService.customArrayLessThan,
      this.customOperationService.customArrayContains,
      this.customOperationService.customBetweenSizeGo,
    ];

    this.enumTimeType = this.customOperationService.getEnumTimeType();
    this.enumDefaultTime = this.customOperationService.getEnumDefaultTime();
  }

  getValues(e) {
    this.onTouched();
  }

  /** @inheritdoc */
  writeValue(values: any): void {
    this.writeValueInit = true;
    if (this.isInitialize && values != undefined) {
      // Composant totalement initialisé
      if (values != undefined && values.clear == undefined) {
        return;
      }
      this.values = values;
    } else if (!this.isInitialize && values != undefined) {
      // Composant non initialisé on temporise le value
      this.tempValues = values;
    } else {
      this.values = values;
    }
    if (this.values != undefined) {
      this.DefaultValues = JSON.parse(JSON.stringify(this.values));
    } else {
      this.DefaultValues = undefined;
    }
    this.writeValueInit = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @inheritdoc */
  ngOnInit() {
    if (this.useChangeValue) {
      this.customOperations.push(
        this.customOperationService.customValueChanged,
        this.customOperationService.customFromTo,
      );
    }

    this.gc.forDispose(
      this.state.form.onLoadedChanged.subscribe((res) => {
        let ctrl = FormGroupHelpers.formControlByName(
          this.state.form,
          this.fieldName,
        );
        if (res == true && this.isInitialize == true && ctrl != undefined) {
          let object = {};
          for (let key in ctrl.value) {
            object[key] = this.DefaultValues;
          }
        }
      }),
    );

    this.gc.forDispose(
      this.state.on.subscribe((event) => {
        if (event?.eventName == 'filterBuilderRefresh') {
          this.type = event.eventData?.type;
          this.loadData();
        }
      }),
    );

    this.loadData();
  }

  /** Obtient les opérateurs pour le type et le field */
  getOperations(field: string, customFilterOperation: CustomFilterOperation) {
    let getField = this.fields.find((f) => f.dataField == field);
    if (getField != undefined) {
      if (
        getField.filterOperations == undefined ||
        getField.filterOperations.length == 0
      ) {
        getField.filterOperations = [];
        // Ajout des default operation

        this.getDefaultOperations(getField.dataType).forEach((g) => {
          getField.filterOperations.push(g);
        });

        // ajout des opérations custom
        this.customOperations
          .filter(
            (f) =>
              f.dataTypes != undefined &&
              f.dataTypes.includes(getField.dataType),
          )
          .map((s) => s.name)
          .forEach((g) => {
            getField.filterOperations.push(g);
          });
      }
      // ajout de l'operation custom
      getField.filterOperations.push(customFilterOperation.name);
    }
  }

  /** Obtient les opérateurs de base d'un type
   * https://js.devexpress.com/Angular/Documentation/ApiReference/UI_Components/dxFilterBuilder/Configuration/fields/#filterOperations
   */
  getDefaultOperations(type: string) {
    switch (type) {
      case 'ID':
        return ['=', '<>', 'isblank', 'isnotblank'];
      case 'string':
        return [
          'contains',
          'notcontains',
          'startswith',
          'endswith',
          '=',
          '<>',
          'isblank',
          'isnotblank',
        ];
      case 'date':
      case 'datetime':
        return [
          '=',
          '<>',
          '<',
          '>',
          '<=',
          '>=',
          'between',
          'isblank',
          'isnotblank',
        ];
      case 'boolean':
        return ['=', '<>', 'isblank', 'isnotblank'];
      case 'numeric':
        return [
          '=',
          '<>',
          '<',
          '>',
          '<=',
          '>=',
          'between',
          'isblank',
          'isnotblank',
        ];
    }

    return [];
  }

  onValueChanged(_) {}

  getFromToValue(values, index) {
    if (values == undefined) {
      return null;
    }
    return values[0][index];
  }

  changeConditionValue(condition, e, origin) {
    if (origin == 'fromSelectBox') {
      if (condition.value == undefined) {
        condition.value = [[e.selectedRowKeys, null]];
        condition.setValue([[e.selectedRowKeys, null]]);
      } else {
        if (condition.value[0][1] == undefined) {
          condition.value = [[e.selectedRowKeys, null]];
          condition.setValue([[e.selectedRowKeys, null]]);
        } else {
          condition.value = [[e.selectedRowKeys, condition.value[0][1]]];
          condition.setValue([[e.selectedRowKeys, condition.value[0][1]]]);
        }
      }
    } else if (origin == 'toSelectBox') {
      if (condition.value == undefined) {
        condition.value = [[null, e.selectedRowKeys]];
        condition.setValue([[null, e.selectedRowKeys]]);
      } else {
        if (condition.value[0][0] == undefined) {
          condition.value = [[null, e.selectedRowKeys]];
          condition.setValue([[null, e.selectedRowKeys]]);
        } else {
          condition.value = [[condition.value[0][0], e.selectedRowKeys]];
          condition.setValue([[condition.value[0][0], e.selectedRowKeys]]);
        }
      }
    }

    if (this.dropDownComponent != undefined) {
      this.dropDownComponent.close();
    }
  }

  getConditionValue(condition, origin) {
    if (condition.value == null) {
      return null;
    }

    if (origin == 'fromSelectBox') {
      return condition.value[0][0];
    } else if (origin == 'toSelectBox') {
      return condition.value[0][1];
    }
  }
  selectionTemplateChanged(condition, e) {
    if (condition.filterOperation != 'customSelectValues') {
      if (e.selectedRowKeys.length > 0) {
        condition.setValue([e.selectedRowKeys]);
        condition.value = [e.selectedRowKeys];

        if (this.dropDownComponent != undefined) {
          this.dropDownComponent.close();
        }
      }
    } else {
      condition.setValue([e.component.getSelectedRowKeys('all')]);
    }
  }

  selectionTemplateGetValue(condition) {
    if (condition?.value?.length != null) {
      return condition.value[0];
    }
    return condition.value;
  }

  onDropDownInitialized(e) {
    this.dropDownComponent = e.component;
  }

  onDropDownInput(e) {
    e.component.open();
  }

  onTreelistContentReady(e, condition) {
    if (
      condition != undefined &&
      condition.value != undefined &&
      condition.value.length > 0
    ) {
      let isExpand = e.component.option('expandedRowKeys');
      if (isExpand == undefined || isExpand.length == 0) {
        let node = e.component.getNodeByKey(condition.value[0]);
        let expand = [];
        while (node != undefined) {
          if (node.parent == undefined) {
            break;
          }
          node = e.component.getNodeByKey(node.parent.key);
          if (node.level != -1) {
            expand.push(node.key);
          }
        }
        expand.reverse();
        e.component.option('expandedRowKeys', expand);
      }
    }
  }

  onInitialized(e) {
    this.filterComponent = e.component;
  }

  /** Rafraîchissement du composant */
  async refresh() {}

  /** Rafraîchissement du composant */
  loadData() {
    const typeByUrl = this.route.snapshot.queryParamMap.get('type');
    if ((this.type == '' || this.type == undefined) && typeByUrl != undefined) {
      this.type = typeByUrl;
    }
    if (this.type == '' || this.type == undefined) {
      let filterDataType =
        this.state?.sharedContext?.params?.get('filterDataType');
      if (filterDataType != undefined) {
        this.type = filterDataType;
      }
    }

    let methodName: any = 'fields';
    if (this.useMethod != undefined) {
      methodName = this.useMethod.fnCall();
    }

    this.commonService[methodName](
      this.commonService.gqlField(),
      this.type.charAt(0).toUpperCase() + this.type.substring(1),
    ).subscribe(async (res) => {
      this.fields = await this.commonService.createFields(
        this.type,
        res,
        true,
        false,
        undefined,
        undefined,
        undefined,
      );
      if (this.tempValues != undefined) {
        this.values = this.tempValues;
      }

      // custom Operation
      if (this.customFilterOperations != undefined) {
        var customOp = this.customFilterOperations.fnCall();
        if (
          customOp != undefined &&
          ((<any>customOp) as CustomFilterOperation[])
        ) {
          ((<any>customOp) as CustomFilterOperation[]).forEach((element) => {
            element.useDataFields.forEach((useField) => {
              this.getOperations(useField, element);
            });
            this.customOperations.push(element);
          });
        }
      }

      this.isInitialize = true;
    });
  }

  /** Obtient le datasource */
  getCustomLookupDataSource(e) {
    let source = this.customOperations.find((f) => f.name == e.filterOperation);
    return source?.dataSource;
  }
}
