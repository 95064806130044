import { Injectable } from "@angular/core";
import { AuthConfig } from "angular-oauth2-oidc";
import { EnvironmentService } from "../environment";

@Injectable({
  providedIn: "root"
})
/** Permet de recupérer les variable d'environment  */
export class AuthentificationConfiguratorService {
  constructor(private _envService: EnvironmentService) {}
  public get authConfig(): AuthConfig {
    return {
      // Url of the Identity Provider
      requireHttps: this._envService.requireHttps,
      issuer: this._envService.identityURL,
      postLogoutRedirectUri: window.location.origin,
      // URL of the SPA to redirect the user to after login
      redirectUri: window.location.origin,
      responseType: "id_token token",
      // The SPA's id. The SPA is registerd with this id at the auth-server
      clientId: "clarilog.one.web",
      // set the scope for the permissions the client should request
      // The first three are defined by OIDC. The 4th is a usecase-specific one
      scope: "openid profile clarilog_one_api.web_client",
      oidc: true,
      sessionChecksEnabled: true,
      showDebugInformation: true,
      clearHashAfterLogin: false,
      userinfoEndpoint: this._envService.identityURL + "/connect/userinfo",
      silentRefreshRedirectUri: window.location.origin + "/silent-refresh.html"
    };
  }
}
