import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOdbcDriversBaseVariables, FirstOdbcDriversBaseVariables, CountOdbcDriversBaseVariables, FindOdbcDriversBaseVariables, SearchOdbcDriversBaseVariables, ExportSchemaOdbcDriversBaseVariables, ExportDataOdbcDriversBaseVariables, CustomQueryOdbcDriversBaseVariables, CustomQueryIdOdbcDriversBaseVariables, UsedOdbcDriversBaseVariables, ExistOdbcDriversBaseVariables } from '../gqls'
import { GetOdbcDriversDocument, FirstOdbcDriversDocument, CountOdbcDriversDocument, FindOdbcDriversDocument, SearchOdbcDriversDocument, ExportSchemaOdbcDriversDocument, ExportDataOdbcDriversDocument, CustomQueryOdbcDriversDocument, CustomQueryIdOdbcDriversDocument, UsedOdbcDriversDocument, ExistOdbcDriversDocument } from '../gqls'
import { ServiceSingleResultOfOdbcDriver, QueryContextOfOdbcDriver, FilterOfOdbcDriver, ServiceSingleResultOfInt64, ServiceListResultOfOdbcDriver, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OdbcDriverBaseService {
    
public modelName = 'odbcDriver';
public modelPluralName = 'odbcDrivers';

	private getOdbcDriversQuery: GetOdbcDriversDocument;
	private firstOdbcDriversQuery: FirstOdbcDriversDocument;
	private countOdbcDriversQuery: CountOdbcDriversDocument;
	private findOdbcDriversQuery: FindOdbcDriversDocument;
	private searchOdbcDriversQuery: SearchOdbcDriversDocument;
	private exportSchemaOdbcDriversQuery: ExportSchemaOdbcDriversDocument;
	private exportDataOdbcDriversQuery: ExportDataOdbcDriversDocument;
	private customQueryOdbcDriversQuery: CustomQueryOdbcDriversDocument;
	private customQueryIdOdbcDriversQuery: CustomQueryIdOdbcDriversDocument;
	private usedOdbcDriversQuery: UsedOdbcDriversDocument;
	private existOdbcDriversQuery: ExistOdbcDriversDocument;

	constructor(private injector: Injector) {
		this.getOdbcDriversQuery = this.injector.get(GetOdbcDriversDocument);
		this.firstOdbcDriversQuery = this.injector.get(FirstOdbcDriversDocument);
		this.countOdbcDriversQuery = this.injector.get(CountOdbcDriversDocument);
		this.findOdbcDriversQuery = this.injector.get(FindOdbcDriversDocument);
		this.searchOdbcDriversQuery = this.injector.get(SearchOdbcDriversDocument);
		this.exportSchemaOdbcDriversQuery = this.injector.get(ExportSchemaOdbcDriversDocument);
		this.exportDataOdbcDriversQuery = this.injector.get(ExportDataOdbcDriversDocument);
		this.customQueryOdbcDriversQuery = this.injector.get(CustomQueryOdbcDriversDocument);
		this.customQueryIdOdbcDriversQuery = this.injector.get(CustomQueryIdOdbcDriversDocument);
		this.usedOdbcDriversQuery = this.injector.get(UsedOdbcDriversDocument);
		this.existOdbcDriversQuery = this.injector.get(ExistOdbcDriversDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOdbcDriver> {
    
    		let variables: GetOdbcDriversBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.get));
            }
            else{
                let result:ServiceSingleResultOfOdbcDriver={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOdbcDriver,
		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOdbcDriver> {
    
    		let variables: FirstOdbcDriversBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOdbcDriversBaseVariables = {
    			filter: filter
    		}
    				return this.countOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOdbcDriver,
		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOdbcDriver> {
    
    		let variables: FindOdbcDriversBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOdbcDriver,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOdbcDriver> {
    
    		let variables: SearchOdbcDriversBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOdbcDriversBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOdbcDriversBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOdbcDriver,
		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOdbcDriver> {
    
    		let variables: CustomQueryOdbcDriversBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOdbcDriver,
		@Args('filter?') filter?: FilterOfOdbcDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOdbcDriver> {
    
    		let variables: CustomQueryIdOdbcDriversBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOdbcDriversBaseVariables = {
    			ids: ids
    		}
    				return this.usedOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOdbcDriversBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOdbcDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.odbcDrivers.exist));
    	}
    
    
}