import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCronJobHourlyTasksBaseVariables, FirstCronJobHourlyTasksBaseVariables, CountCronJobHourlyTasksBaseVariables, FindCronJobHourlyTasksBaseVariables, SearchCronJobHourlyTasksBaseVariables, ExportSchemaCronJobHourlyTasksBaseVariables, ExportDataCronJobHourlyTasksBaseVariables, CustomQueryCronJobHourlyTasksBaseVariables, CustomQueryIdCronJobHourlyTasksBaseVariables, UsedCronJobHourlyTasksBaseVariables, ExistCronJobHourlyTasksBaseVariables, InsertCronJobHourlyTasksBaseVariables, UpdateCronJobHourlyTasksBaseVariables, ImportDataCronJobHourlyTasksBaseVariables, UpdateManyCronJobHourlyTasksBaseVariables, DeleteCronJobHourlyTasksBaseVariables } from '../gqls'
import { GetCronJobHourlyTasksDocument, FirstCronJobHourlyTasksDocument, CountCronJobHourlyTasksDocument, FindCronJobHourlyTasksDocument, SearchCronJobHourlyTasksDocument, ExportSchemaCronJobHourlyTasksDocument, ExportDataCronJobHourlyTasksDocument, CustomQueryCronJobHourlyTasksDocument, CustomQueryIdCronJobHourlyTasksDocument, UsedCronJobHourlyTasksDocument, ExistCronJobHourlyTasksDocument, InsertCronJobHourlyTasksDocument, UpdateCronJobHourlyTasksDocument, ImportDataCronJobHourlyTasksDocument, UpdateManyCronJobHourlyTasksDocument, DeleteCronJobHourlyTasksDocument } from '../gqls'
import { ServiceSingleResultOfCronJobHourlyTask, QueryContextOfCronJobHourlyTask, FilterOfCronJobHourlyTask, ServiceSingleResultOfInt64, ServiceListResultOfCronJobHourlyTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, CronJobHourlyTaskInput, FieldUpdateOperatorOfCronJobHourlyTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CronJobHourlyTaskBaseService {
    
public modelName = 'cronJobHourlyTask';
public modelPluralName = 'cronJobHourlyTasks';

	private getCronJobHourlyTasksQuery: GetCronJobHourlyTasksDocument;
	private firstCronJobHourlyTasksQuery: FirstCronJobHourlyTasksDocument;
	private countCronJobHourlyTasksQuery: CountCronJobHourlyTasksDocument;
	private findCronJobHourlyTasksQuery: FindCronJobHourlyTasksDocument;
	private searchCronJobHourlyTasksQuery: SearchCronJobHourlyTasksDocument;
	private exportSchemaCronJobHourlyTasksQuery: ExportSchemaCronJobHourlyTasksDocument;
	private exportDataCronJobHourlyTasksQuery: ExportDataCronJobHourlyTasksDocument;
	private customQueryCronJobHourlyTasksQuery: CustomQueryCronJobHourlyTasksDocument;
	private customQueryIdCronJobHourlyTasksQuery: CustomQueryIdCronJobHourlyTasksDocument;
	private usedCronJobHourlyTasksQuery: UsedCronJobHourlyTasksDocument;
	private existCronJobHourlyTasksQuery: ExistCronJobHourlyTasksDocument;
	private insertCronJobHourlyTasksMutation: InsertCronJobHourlyTasksDocument;
	private updateCronJobHourlyTasksMutation: UpdateCronJobHourlyTasksDocument;
	private importDataCronJobHourlyTasksMutation: ImportDataCronJobHourlyTasksDocument;
	private updateManyCronJobHourlyTasksMutation: UpdateManyCronJobHourlyTasksDocument;
	private deleteCronJobHourlyTasksMutation: DeleteCronJobHourlyTasksDocument;

	constructor(private injector: Injector) {
		this.getCronJobHourlyTasksQuery = this.injector.get(GetCronJobHourlyTasksDocument);
		this.firstCronJobHourlyTasksQuery = this.injector.get(FirstCronJobHourlyTasksDocument);
		this.countCronJobHourlyTasksQuery = this.injector.get(CountCronJobHourlyTasksDocument);
		this.findCronJobHourlyTasksQuery = this.injector.get(FindCronJobHourlyTasksDocument);
		this.searchCronJobHourlyTasksQuery = this.injector.get(SearchCronJobHourlyTasksDocument);
		this.exportSchemaCronJobHourlyTasksQuery = this.injector.get(ExportSchemaCronJobHourlyTasksDocument);
		this.exportDataCronJobHourlyTasksQuery = this.injector.get(ExportDataCronJobHourlyTasksDocument);
		this.customQueryCronJobHourlyTasksQuery = this.injector.get(CustomQueryCronJobHourlyTasksDocument);
		this.customQueryIdCronJobHourlyTasksQuery = this.injector.get(CustomQueryIdCronJobHourlyTasksDocument);
		this.usedCronJobHourlyTasksQuery = this.injector.get(UsedCronJobHourlyTasksDocument);
		this.existCronJobHourlyTasksQuery = this.injector.get(ExistCronJobHourlyTasksDocument);
		this.insertCronJobHourlyTasksMutation = this.injector.get(InsertCronJobHourlyTasksDocument);
		this.updateCronJobHourlyTasksMutation = this.injector.get(UpdateCronJobHourlyTasksDocument);
		this.importDataCronJobHourlyTasksMutation = this.injector.get(ImportDataCronJobHourlyTasksDocument);
		this.updateManyCronJobHourlyTasksMutation = this.injector.get(UpdateManyCronJobHourlyTasksDocument);
		this.deleteCronJobHourlyTasksMutation = this.injector.get(DeleteCronJobHourlyTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCronJobHourlyTask> {
    
    		let variables: GetCronJobHourlyTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.get));
            }
            else{
                let result:ServiceSingleResultOfCronJobHourlyTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCronJobHourlyTask,
		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCronJobHourlyTask> {
    
    		let variables: FirstCronJobHourlyTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCronJobHourlyTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCronJobHourlyTask,
		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCronJobHourlyTask> {
    
    		let variables: FindCronJobHourlyTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCronJobHourlyTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCronJobHourlyTask> {
    
    		let variables: SearchCronJobHourlyTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCronJobHourlyTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCronJobHourlyTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCronJobHourlyTask,
		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCronJobHourlyTask> {
    
    		let variables: CustomQueryCronJobHourlyTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCronJobHourlyTask,
		@Args('filter?') filter?: FilterOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCronJobHourlyTask> {
    
    		let variables: CustomQueryIdCronJobHourlyTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCronJobHourlyTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCronJobHourlyTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCronJobHourlyTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CronJobHourlyTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCronJobHourlyTask> {
    
    		let variables: InsertCronJobHourlyTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertCronJobHourlyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCronJobHourlyTask> {
    
    		let variables: UpdateCronJobHourlyTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCronJobHourlyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCronJobHourlyTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCronJobHourlyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCronJobHourlyTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCronJobHourlyTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCronJobHourlyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCronJobHourlyTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCronJobHourlyTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.cronJobHourlyTasks.delete));
    	}
    
}