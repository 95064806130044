import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTapeDrivesBaseVariables, FirstTapeDrivesBaseVariables, CountTapeDrivesBaseVariables, FindTapeDrivesBaseVariables, SearchTapeDrivesBaseVariables, ExportSchemaTapeDrivesBaseVariables, ExportDataTapeDrivesBaseVariables, CustomQueryTapeDrivesBaseVariables, CustomQueryIdTapeDrivesBaseVariables, UsedTapeDrivesBaseVariables, ExistTapeDrivesBaseVariables } from '../gqls'
import { GetTapeDrivesDocument, FirstTapeDrivesDocument, CountTapeDrivesDocument, FindTapeDrivesDocument, SearchTapeDrivesDocument, ExportSchemaTapeDrivesDocument, ExportDataTapeDrivesDocument, CustomQueryTapeDrivesDocument, CustomQueryIdTapeDrivesDocument, UsedTapeDrivesDocument, ExistTapeDrivesDocument } from '../gqls'
import { ServiceSingleResultOfTapeDrive, QueryContextOfTapeDrive, FilterOfTapeDrive, ServiceSingleResultOfInt64, ServiceListResultOfTapeDrive, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TapeDriveBaseService {
    
public modelName = 'tapeDrive';
public modelPluralName = 'tapeDrives';

	private getTapeDrivesQuery: GetTapeDrivesDocument;
	private firstTapeDrivesQuery: FirstTapeDrivesDocument;
	private countTapeDrivesQuery: CountTapeDrivesDocument;
	private findTapeDrivesQuery: FindTapeDrivesDocument;
	private searchTapeDrivesQuery: SearchTapeDrivesDocument;
	private exportSchemaTapeDrivesQuery: ExportSchemaTapeDrivesDocument;
	private exportDataTapeDrivesQuery: ExportDataTapeDrivesDocument;
	private customQueryTapeDrivesQuery: CustomQueryTapeDrivesDocument;
	private customQueryIdTapeDrivesQuery: CustomQueryIdTapeDrivesDocument;
	private usedTapeDrivesQuery: UsedTapeDrivesDocument;
	private existTapeDrivesQuery: ExistTapeDrivesDocument;

	constructor(private injector: Injector) {
		this.getTapeDrivesQuery = this.injector.get(GetTapeDrivesDocument);
		this.firstTapeDrivesQuery = this.injector.get(FirstTapeDrivesDocument);
		this.countTapeDrivesQuery = this.injector.get(CountTapeDrivesDocument);
		this.findTapeDrivesQuery = this.injector.get(FindTapeDrivesDocument);
		this.searchTapeDrivesQuery = this.injector.get(SearchTapeDrivesDocument);
		this.exportSchemaTapeDrivesQuery = this.injector.get(ExportSchemaTapeDrivesDocument);
		this.exportDataTapeDrivesQuery = this.injector.get(ExportDataTapeDrivesDocument);
		this.customQueryTapeDrivesQuery = this.injector.get(CustomQueryTapeDrivesDocument);
		this.customQueryIdTapeDrivesQuery = this.injector.get(CustomQueryIdTapeDrivesDocument);
		this.usedTapeDrivesQuery = this.injector.get(UsedTapeDrivesDocument);
		this.existTapeDrivesQuery = this.injector.get(ExistTapeDrivesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTapeDrive> {
    
    		let variables: GetTapeDrivesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.get));
            }
            else{
                let result:ServiceSingleResultOfTapeDrive={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTapeDrive,
		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTapeDrive> {
    
    		let variables: FirstTapeDrivesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTapeDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.countTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTapeDrive,
		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTapeDrive> {
    
    		let variables: FindTapeDrivesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTapeDrive,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTapeDrive> {
    
    		let variables: SearchTapeDrivesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTapeDrivesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTapeDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTapeDrive,
		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTapeDrive> {
    
    		let variables: CustomQueryTapeDrivesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTapeDrive,
		@Args('filter?') filter?: FilterOfTapeDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTapeDrive> {
    
    		let variables: CustomQueryIdTapeDrivesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTapeDrivesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTapeDrivesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTapeDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.tapeDrives.exist));
    	}
    
    
}