<div class="container-fill">
  <div class="content-middle-vertical">
    <div class="content-middle">
      <div class="rond"></div>
      <div class="rond2"></div>
      <img
        src="/static-files/logo-new/logo-one-by-clarilog-bleu.svg"
        style="width:90px;margin-top: 10px;"
      />
    </div>
  </div>
</div>
