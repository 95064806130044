import { ScanByAzureAdBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfScanExclude,
  QueryContextOfScanExclude,
  ScanExcludeCategory,
  ServiceListResultOfScanExclude,
} from '../types';
import { Observable } from 'rxjs';

@Authorize('scan-configuration')
@Injectable({ providedIn: 'root' })
export class ScanByAzureAdCoreService extends ScanByAzureAdBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** PErmet de récupérer tous les filtre d'exclusion associé de type Utilisateurs */
  @InjectArgs
  public findAssociatedScanExcludesAzureAd(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanExclude,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanExclude,

    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanExclude> {
    let addFilter: FilterOfScanExclude = {
      scanExcludeCategory: {
        eq: ScanExcludeCategory.User,
      },
    };
    if (filter == undefined) {
      filter = addFilter;
    } else {
      filter = {
        and: [addFilter, filter],
      };
    }
    return this.findAssociatedScanExcludes(fields, options, id, filter);
  }

  /** PErmet de récupérer tous les filtre d'exclusion non associé de type Utilisateurs */
  @InjectArgs
  public findUnassociatedScanExcludesAzureAd(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanExclude,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanExclude,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanExclude> {
    let addFilter: FilterOfScanExclude = {
      scanExcludeCategory: {
        eq: ScanExcludeCategory.User,
      },
    };
    if (filter == undefined) {
      filter = addFilter;
    } else {
      filter = {
        and: [addFilter, filter],
      };
    }
    return this.findUnassociatedScanExcludes(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }
}
