import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetAffectedRulesBaseVariables, FirstAssetAffectedRulesBaseVariables, CountAssetAffectedRulesBaseVariables, FindAssetAffectedRulesBaseVariables, SearchAssetAffectedRulesBaseVariables, ExportSchemaAssetAffectedRulesBaseVariables, ExportDataAssetAffectedRulesBaseVariables, CustomQueryAssetAffectedRulesBaseVariables, CustomQueryIdAssetAffectedRulesBaseVariables, UsedAssetAffectedRulesBaseVariables, ExistAssetAffectedRulesBaseVariables, InsertAssetAffectedRulesBaseVariables, UpdateAssetAffectedRulesBaseVariables, ImportDataAssetAffectedRulesBaseVariables, UpdateManyAssetAffectedRulesBaseVariables, DeleteAssetAffectedRulesBaseVariables, OrderAssetAffectedRulesBaseVariables } from '../gqls'
import { GetAssetAffectedRulesDocument, FirstAssetAffectedRulesDocument, CountAssetAffectedRulesDocument, FindAssetAffectedRulesDocument, SearchAssetAffectedRulesDocument, ExportSchemaAssetAffectedRulesDocument, ExportDataAssetAffectedRulesDocument, CustomQueryAssetAffectedRulesDocument, CustomQueryIdAssetAffectedRulesDocument, UsedAssetAffectedRulesDocument, ExistAssetAffectedRulesDocument, InsertAssetAffectedRulesDocument, UpdateAssetAffectedRulesDocument, ImportDataAssetAffectedRulesDocument, UpdateManyAssetAffectedRulesDocument, DeleteAssetAffectedRulesDocument, OrderAssetAffectedRulesDocument } from '../gqls'
import { ServiceSingleResultOfAssetAffectedRule, QueryContextOfAssetAffectedRule, FilterOfAssetAffectedRule, ServiceSingleResultOfInt64, ServiceListResultOfAssetAffectedRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetAffectedRuleInput, FieldUpdateOperatorOfAssetAffectedRule } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetAffectedRuleBaseService {
    
public modelName = 'assetAffectedRule';
public modelPluralName = 'assetAffectedRules';

	private getAssetAffectedRulesQuery: GetAssetAffectedRulesDocument;
	private firstAssetAffectedRulesQuery: FirstAssetAffectedRulesDocument;
	private countAssetAffectedRulesQuery: CountAssetAffectedRulesDocument;
	private findAssetAffectedRulesQuery: FindAssetAffectedRulesDocument;
	private searchAssetAffectedRulesQuery: SearchAssetAffectedRulesDocument;
	private exportSchemaAssetAffectedRulesQuery: ExportSchemaAssetAffectedRulesDocument;
	private exportDataAssetAffectedRulesQuery: ExportDataAssetAffectedRulesDocument;
	private customQueryAssetAffectedRulesQuery: CustomQueryAssetAffectedRulesDocument;
	private customQueryIdAssetAffectedRulesQuery: CustomQueryIdAssetAffectedRulesDocument;
	private usedAssetAffectedRulesQuery: UsedAssetAffectedRulesDocument;
	private existAssetAffectedRulesQuery: ExistAssetAffectedRulesDocument;
	private insertAssetAffectedRulesMutation: InsertAssetAffectedRulesDocument;
	private updateAssetAffectedRulesMutation: UpdateAssetAffectedRulesDocument;
	private importDataAssetAffectedRulesMutation: ImportDataAssetAffectedRulesDocument;
	private updateManyAssetAffectedRulesMutation: UpdateManyAssetAffectedRulesDocument;
	private deleteAssetAffectedRulesMutation: DeleteAssetAffectedRulesDocument;
	private orderAssetAffectedRulesMutation: OrderAssetAffectedRulesDocument;

	constructor(private injector: Injector) {
		this.getAssetAffectedRulesQuery = this.injector.get(GetAssetAffectedRulesDocument);
		this.firstAssetAffectedRulesQuery = this.injector.get(FirstAssetAffectedRulesDocument);
		this.countAssetAffectedRulesQuery = this.injector.get(CountAssetAffectedRulesDocument);
		this.findAssetAffectedRulesQuery = this.injector.get(FindAssetAffectedRulesDocument);
		this.searchAssetAffectedRulesQuery = this.injector.get(SearchAssetAffectedRulesDocument);
		this.exportSchemaAssetAffectedRulesQuery = this.injector.get(ExportSchemaAssetAffectedRulesDocument);
		this.exportDataAssetAffectedRulesQuery = this.injector.get(ExportDataAssetAffectedRulesDocument);
		this.customQueryAssetAffectedRulesQuery = this.injector.get(CustomQueryAssetAffectedRulesDocument);
		this.customQueryIdAssetAffectedRulesQuery = this.injector.get(CustomQueryIdAssetAffectedRulesDocument);
		this.usedAssetAffectedRulesQuery = this.injector.get(UsedAssetAffectedRulesDocument);
		this.existAssetAffectedRulesQuery = this.injector.get(ExistAssetAffectedRulesDocument);
		this.insertAssetAffectedRulesMutation = this.injector.get(InsertAssetAffectedRulesDocument);
		this.updateAssetAffectedRulesMutation = this.injector.get(UpdateAssetAffectedRulesDocument);
		this.importDataAssetAffectedRulesMutation = this.injector.get(ImportDataAssetAffectedRulesDocument);
		this.updateManyAssetAffectedRulesMutation = this.injector.get(UpdateManyAssetAffectedRulesDocument);
		this.deleteAssetAffectedRulesMutation = this.injector.get(DeleteAssetAffectedRulesDocument);
		this.orderAssetAffectedRulesMutation = this.injector.get(OrderAssetAffectedRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAffectedRule> {
    
    		let variables: GetAssetAffectedRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.get));
            }
            else{
                let result:ServiceSingleResultOfAssetAffectedRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAffectedRule,
		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAffectedRule> {
    
    		let variables: FirstAssetAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAffectedRule,
		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAffectedRule> {
    
    		let variables: FindAssetAffectedRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetAffectedRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAffectedRule> {
    
    		let variables: SearchAssetAffectedRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetAffectedRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetAffectedRule,
		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAffectedRule> {
    
    		let variables: CustomQueryAssetAffectedRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetAffectedRule,
		@Args('filter?') filter?: FilterOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAffectedRule> {
    
    		let variables: CustomQueryIdAssetAffectedRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetAffectedRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetAffectedRuleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAffectedRule> {
    
    		let variables: InsertAssetAffectedRulesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAffectedRule> {
    
    		let variables: UpdateAssetAffectedRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetAffectedRulesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetAffectedRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderAssetAffectedRulesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderAssetAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAffectedRules.order));
    	}
    
}