import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfScanConfiguration, ServiceSingleResultOfNetworkCredential, ServiceSingleResultOfScanExclude, ServiceSingleResultOfScanRegistryKey, ServiceSingleResultOfScanDataFile, ServiceSingleResultOfInventoryHistory, ServiceSingleResultOfClarilogServer, ServiceSingleResultOfString, ServiceSingleResultOfSnmpMapInformationKb, ServiceListResultOfScanConfiguration, ServiceSingleResultOfAsset, AssetInput, QueryContextOfAsset, ServiceListResultOfNetworkAdapterConfiguration, ClarilogLocalAgentInput, ClarilogServerInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfFileDescriptor, UpdaterState, FieldUpdateOperatorOfInventoryHistory, ServiceSingleResultOfGuid, InventoryHistoryInput, LdapOrganizationalUnitInput, OrganizationalUnitInput, LdapHistoryInput, UserInput, HistoryState, AgentTokenInput } from '../types'

export type GetScanConfigurationAgentsResultType = {
    agents: {
        getScanConfiguration?: Maybe<ServiceSingleResultOfScanConfiguration>
    }
}

export type GetScanConfigurationAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanConfigurationAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanConfigurationAgents ${args} {
    agents {
        getScanConfiguration(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanConfigurationAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanConfigurationAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetNetworkCredentialAgentsResultType = {
    agents: {
        getNetworkCredential?: Maybe<ServiceSingleResultOfNetworkCredential>
    }
}

export type GetNetworkCredentialAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetNetworkCredentialAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getNetworkCredentialAgents ${args} {
    agents {
        getNetworkCredential(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetNetworkCredentialAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetNetworkCredentialAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfNetworkCredential", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetScanExcludeAgentsResultType = {
    agents: {
        getScanExclude?: Maybe<ServiceSingleResultOfScanExclude>
    }
}

export type GetScanExcludeAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanExcludeAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanExcludeAgents ${args} {
    agents {
        getScanExclude(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanExcludeAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanExcludeAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanExclude", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetScanRegistryKeyAgentsResultType = {
    agents: {
        getScanRegistryKey?: Maybe<ServiceSingleResultOfScanRegistryKey>
    }
}

export type GetScanRegistryKeyAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanRegistryKeyAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanRegistryKeyAgents ${args} {
    agents {
        getScanRegistryKey(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanRegistryKeyAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanRegistryKeyAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetScanDataFileAgentsResultType = {
    agents: {
        getScanDataFile?: Maybe<ServiceSingleResultOfScanDataFile>
    }
}

export type GetScanDataFileAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanDataFileAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanDataFileAgents ${args} {
    agents {
        getScanDataFile(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanDataFileAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanDataFileAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetInventoryHistoryAgentsResultType = {
    agents: {
        getInventoryHistory?: Maybe<ServiceSingleResultOfInventoryHistory>
    }
}

export type GetInventoryHistoryAgentsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetInventoryHistoryAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getInventoryHistoryAgents ${args} {
    agents {
        getInventoryHistory(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetInventoryHistoryAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetInventoryHistoryAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInventoryHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetByLocalIdAgentsResultType = {
    agents: {
        getByLocalId?: Maybe<ServiceSingleResultOfClarilogServer>
    }
}

export type GetByLocalIdAgentsBaseVariables = {
	localId: string, /**  */
	version: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class GetByLocalIdAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$localId: Uuid!, $version: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getByLocalIdAgents ${args} {
    agents {
        getByLocalId(localId: $localId, version: $version) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetByLocalIdAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetByLocalIdAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfClarilogServer", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetLastAgentVersionAgentsResultType = {
    agents: {
        getLastAgentVersion?: Maybe<ServiceSingleResultOfString>
    }
}

export type GetLastAgentVersionAgentsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class GetLastAgentVersionAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLastAgentVersionAgents ${args} {
    agents {
        getLastAgentVersion {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLastAgentVersionAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLastAgentVersionAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetBySysOidAgentsResultType = {
    agents: {
        getBySysOid?: Maybe<ServiceSingleResultOfSnmpMapInformationKb>
    }
}

export type GetBySysOidAgentsBaseVariables = {
	sysOid: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class GetBySysOidAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$sysOid: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getBySysOidAgents ${args} {
    agents {
        getBySysOid(sysOid: $sysOid) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetBySysOidAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetBySysOidAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSnmpMapInformationKb", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByOpertationAgentAgentsResultType = {
    agents: {
        findByOpertationAgent?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindByOpertationAgentAgentsBaseVariables = {
	clarilogServerId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindByOpertationAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$clarilogServerId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByOpertationAgentAgents ${args} {
    agents {
        findByOpertationAgent(clarilogServerId: $clarilogServerId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByOpertationAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByOpertationAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetAssetByRuleAgentsResultType = {
    agents: {
        getAssetByRule?: Maybe<ServiceSingleResultOfAsset>
    }
}

export type GetAssetByRuleAgentsBaseVariables = {
	asset: Maybe<AssetInput>, /**  */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class GetAssetByRuleAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$asset: AssetInput = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getAssetByRuleAgents ${args} {
    agents {
        getAssetByRule(asset: $asset, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetAssetByRuleAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetAssetByRuleAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNetworkCardAgentsResultType = {
    agents: {
        findNetworkCard?: Maybe<ServiceListResultOfNetworkAdapterConfiguration>
    }
}

export type FindNetworkCardAgentsBaseVariables = {
	host: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNetworkCardAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$host: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNetworkCardAgents ${args} {
    agents {
        findNetworkCard(host: $host) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNetworkCardAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNetworkCardAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNetworkAdapterConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetLocalAgentByIdAgentsResultType = {
    agents: {
        getLocalAgentById?: Maybe<ServiceSingleResultOfAsset>
    }
}

export type GetLocalAgentByIdAgentsBaseVariables = {
	assetId: string, /**  */
	clarilogLocalAgent: Maybe<ClarilogLocalAgentInput>, /**  */
	starting: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class GetLocalAgentByIdAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetId: Uuid!, $clarilogLocalAgent: ClarilogLocalAgentInput = null, $starting: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLocalAgentByIdAgents ${args} {
    agents {
        getLocalAgentById(assetId: $assetId, clarilogLocalAgent: $clarilogLocalAgent, starting: $starting) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLocalAgentByIdAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLocalAgentByIdAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetLocalAgentByAssetAgentsResultType = {
    agents: {
        getLocalAgentByAsset?: Maybe<ServiceSingleResultOfAsset>
    }
}

export type GetLocalAgentByAssetAgentsBaseVariables = {
	asset: Maybe<AssetInput>, /**  */
	clarilogLocalAgent: Maybe<ClarilogLocalAgentInput>, /**  */
	starting: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class GetLocalAgentByAssetAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$asset: AssetInput = null, $clarilogLocalAgent: ClarilogLocalAgentInput = null, $starting: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLocalAgentByAssetAgents ${args} {
    agents {
        getLocalAgentByAsset(asset: $asset, clarilogLocalAgent: $clarilogLocalAgent, starting: $starting) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLocalAgentByAssetAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLocalAgentByAssetAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type GetLastLocalAgentVersionAgentsResultType = {
    agents: {
        getLastLocalAgentVersion?: Maybe<ServiceSingleResultOfString>
    }
}

export type GetLastLocalAgentVersionAgentsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class GetLastLocalAgentVersionAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLastLocalAgentVersionAgents ${args} {
    agents {
        getLastLocalAgentVersion {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLastLocalAgentVersionAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLastLocalAgentVersionAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertClarilogServerAgentsResultType = {
    agents: {
        insertClarilogServer?: Maybe<ServiceSingleResultOfClarilogServer>
    }
}

export type InsertClarilogServerAgentsBaseVariables = {
	entity: ClarilogServerInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertClarilogServerAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ClarilogServerInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertClarilogServerAgents ${args} {
    agents {
        insertClarilogServer(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertClarilogServerAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertClarilogServerAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfClarilogServer", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IsConnectedV2AgentsResultType = {
    agents: {
        isConnectedV2?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type IsConnectedV2AgentsBaseVariables = {
	id: string, /**  */
	name: Maybe<string>, /**  */
	domain: Maybe<string>, /**  */
	macAddress: Maybe<string>, /**  */
	ipAddress: Maybe<string>, /**  */
	osVersion: Maybe<string>, /**  */
	version: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class IsConnectedV2AgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $name: String = null, $domain: String = null, $macAddress: String = null, $ipAddress: String = null, $osVersion: String = null, $version: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation isConnectedV2Agents ${args} {
    agents {
        isConnectedV2(id: $id, name: $name, domain: $domain, macAddress: $macAddress, ipAddress: $ipAddress, osVersion: $osVersion, version: $version) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: IsConnectedV2AgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<IsConnectedV2AgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateFilesAgentAgentsResultType = {
    agents: {
        updateFilesAgent?: Maybe<ServiceSingleResultOfFileDescriptor>
    }
}

export type UpdateFilesAgentAgentsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateFilesAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateFilesAgentAgents ${args} {
    agents {
        updateFilesAgent {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateFilesAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateFilesAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFileDescriptor", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IsConnectedAgentsResultType = {
    agents: {
        isConnected?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type IsConnectedAgentsBaseVariables = {
	id: string, /**  */
	name: Maybe<string>, /**  */
	domain: Maybe<string>, /**  */
	macAddress: Maybe<string>, /**  */
	ipAddress: Maybe<string>, /**  */
	osVersion: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class IsConnectedAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $name: String = null, $domain: String = null, $macAddress: String = null, $ipAddress: String = null, $osVersion: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation isConnectedAgents ${args} {
    agents {
        isConnected(id: $id, name: $name, domain: $domain, macAddress: $macAddress, ipAddress: $ipAddress, osVersion: $osVersion) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: IsConnectedAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<IsConnectedAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateStatusAgentAgentsResultType = {
    agents: {
        updateStatusAgent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateStatusAgentAgentsBaseVariables = {
	agentId: string, /**  */
	updaterState: UpdaterState, /**  */
	version: Maybe<string>, /**  */
	error: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateStatusAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$agentId: Uuid!, $updaterState: UpdaterState!, $version: String = null, $error: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateStatusAgentAgents ${args} {
    agents {
        updateStatusAgent(agentId: $agentId, updaterState: $updaterState, version: $version, error: $error) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateStatusAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateStatusAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ProgressAuditAgentsResultType = {
    agents: {
        progressAudit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ProgressAuditAgentsBaseVariables = {
	id: string, /**  */
	entry: Maybe<FieldUpdateOperatorOfInventoryHistory>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class ProgressAuditAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation progressAuditAgents ${args} {
    agents {
        progressAudit(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ProgressAuditAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ProgressAuditAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CreateAuditV2AgentsResultType = {
    agents: {
        createAuditV2?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type CreateAuditV2AgentsBaseVariables = {
	entity: Maybe<InventoryHistoryInput>, /**  */
	scanConfigurationName: Maybe<string>, /**  */
	scanType: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CreateAuditV2AgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: InventoryHistoryInput = null, $scanConfigurationName: String = null, $scanType: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation createAuditV2Agents ${args} {
    agents {
        createAuditV2(entity: $entity, scanConfigurationName: $scanConfigurationName, scanType: $scanType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CreateAuditV2AgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CreateAuditV2AgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CreateAuditAgentsResultType = {
    agents: {
        createAudit?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type CreateAuditAgentsBaseVariables = {
	entity: Maybe<InventoryHistoryInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CreateAuditAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: InventoryHistoryInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation createAuditAgents ${args} {
    agents {
        createAudit(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CreateAuditAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CreateAuditAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportAssetAgentsResultType = {
    agents: {
        importAsset?: Maybe<ServiceSingleResultOfAsset>
    }
}

export type ImportAssetAgentsBaseVariables = {
	asset: Maybe<AssetInput>, /**  */
	manufacturer: Maybe<string>, /**  */
	model: Maybe<string>, /**  */
	inventoryHistoryId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportAssetAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$asset: AssetInput = null, $manufacturer: String = null, $model: String = null, $inventoryHistoryId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importAssetAgents ${args} {
    agents {
        importAsset(asset: $asset, manufacturer: $manufacturer, model: $model, inventoryHistoryId: $inventoryHistoryId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportAssetAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportAssetAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportLdapOrganizationalUnitAgentsResultType = {
    agents: {
        importLdapOrganizationalUnit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportLdapOrganizationalUnitAgentsBaseVariables = {
	ldapOrganizationalUnits: Array<Maybe<LdapOrganizationalUnitInput>>, /**  */
	id: string, /**  */
	initialize: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportLdapOrganizationalUnitAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapOrganizationalUnits: [LdapOrganizationalUnitInput] = null, $id: Uuid!, $initialize: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importLdapOrganizationalUnitAgents ${args} {
    agents {
        importLdapOrganizationalUnit(ldapOrganizationalUnits: $ldapOrganizationalUnits, id: $id, initialize: $initialize) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportLdapOrganizationalUnitAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportLdapOrganizationalUnitAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportOrganizationalUnitAgentsResultType = {
    agents: {
        importOrganizationalUnit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportOrganizationalUnitAgentsBaseVariables = {
	organizationalUnits: Array<Maybe<OrganizationalUnitInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportOrganizationalUnitAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$organizationalUnits: [OrganizationalUnitInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importOrganizationalUnitAgents ${args} {
    agents {
        importOrganizationalUnit(organizationalUnits: $organizationalUnits) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportOrganizationalUnitAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportOrganizationalUnitAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateHistoryLdapAgentsResultType = {
    agents: {
        updateHistoryLdap?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type UpdateHistoryLdapAgentsBaseVariables = {
	id: string, /**  */
	ldapHistory: Maybe<LdapHistoryInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateHistoryLdapAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ldapHistory: LdapHistoryInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateHistoryLdapAgents ${args} {
    agents {
        updateHistoryLdap(id: $id, ldapHistory: $ldapHistory) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateHistoryLdapAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateHistoryLdapAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FinishAgentsResultType = {
    agents: {
        finish?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type FinishAgentsBaseVariables = {
	scanConfigurationId: string, /**  */
	exception: Maybe<string>, /**  */
	computer: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FinishAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!, $exception: String = null, $computer: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation finishAgents ${args} {
    agents {
        finish(scanConfigurationId: $scanConfigurationId, exception: $exception, computer: $computer) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: FinishAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<FinishAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IsRunAgentsResultType = {
    agents: {
        isRun?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type IsRunAgentsBaseVariables = {
	scanConfigurationId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class IsRunAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation isRunAgents ${args} {
    agents {
        isRun(scanConfigurationId: $scanConfigurationId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: IsRunAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<IsRunAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CancelingAgentsResultType = {
    agents: {
        canceling?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CancelingAgentsBaseVariables = {
	scanConfigurationId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CancelingAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation cancelingAgents ${args} {
    agents {
        canceling(scanConfigurationId: $scanConfigurationId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CancelingAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CancelingAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type TotalAuditComputerAgentsResultType = {
    agents: {
        totalAuditComputer?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type TotalAuditComputerAgentsBaseVariables = {
	scanConfigurationId: string, /**  */
	total: number, /**  */
	appendTo: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class TotalAuditComputerAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!, $total: Int!, $appendTo: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation totalAuditComputerAgents ${args} {
    agents {
        totalAuditComputer(scanConfigurationId: $scanConfigurationId, total: $total, appendTo: $appendTo) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: TotalAuditComputerAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<TotalAuditComputerAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportUserAgentsResultType = {
    agents: {
        importUser?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportUserAgentsBaseVariables = {
	users: Array<Maybe<UserInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportUserAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$users: [UserInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importUserAgents ${args} {
    agents {
        importUser(users: $users) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportUserAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportUserAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportClarilogLocalAgentAgentsResultType = {
    agents: {
        importClarilogLocalAgent?: Maybe<ServiceSingleResultOfAsset>
    }
}

export type ImportClarilogLocalAgentAgentsBaseVariables = {
	assetId: Maybe<string>, /**  */
	asset: Maybe<AssetInput>, /**  */
	manufacturer: Maybe<string>, /**  */
	model: Maybe<string>, /**  */
	inventoryHistoryId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportClarilogLocalAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetId: Uuid = null, $asset: AssetInput = null, $manufacturer: String = null, $model: String = null, $inventoryHistoryId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importClarilogLocalAgentAgents ${args} {
    agents {
        importClarilogLocalAgent(assetId: $assetId, asset: $asset, manufacturer: $manufacturer, model: $model, inventoryHistoryId: $inventoryHistoryId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportClarilogLocalAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportClarilogLocalAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateFilesLocalAgentAgentsResultType = {
    agents: {
        updateFilesLocalAgent?: Maybe<ServiceSingleResultOfFileDescriptor>
    }
}

export type UpdateFilesLocalAgentAgentsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateFilesLocalAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateFilesLocalAgentAgents ${args} {
    agents {
        updateFilesLocalAgent {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateFilesLocalAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateFilesLocalAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfFileDescriptor", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type StartInventoryLocalAgentAgentsResultType = {
    agents: {
        startInventoryLocalAgent?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type StartInventoryLocalAgentAgentsBaseVariables = {
	assetId: string, /**  */
	inventoryHistory: Maybe<InventoryHistoryInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class StartInventoryLocalAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetId: Uuid!, $inventoryHistory: InventoryHistoryInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation startInventoryLocalAgentAgents ${args} {
    agents {
        startInventoryLocalAgent(assetId: $assetId, inventoryHistory: $inventoryHistory) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: StartInventoryLocalAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<StartInventoryLocalAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateStatusLocalAgentAgentsResultType = {
    agents: {
        updateStatusLocalAgent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateStatusLocalAgentAgentsBaseVariables = {
	agentId: string, /**  */
	updaterState: UpdaterState, /**  */
	version: Maybe<string>, /**  */
	error: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateStatusLocalAgentAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$agentId: Uuid!, $updaterState: UpdaterState!, $version: String = null, $error: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateStatusLocalAgentAgents ${args} {
    agents {
        updateStatusLocalAgent(agentId: $agentId, updaterState: $updaterState, version: $version, error: $error) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateStatusLocalAgentAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateStatusLocalAgentAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateCampaignHistoryAgentsResultType = {
    agents: {
        updateCampaignHistory?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateCampaignHistoryAgentsBaseVariables = {
	id: string, /**  */
	state: HistoryState, /**  */
	error: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateCampaignHistoryAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $state: HistoryState!, $error: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateCampaignHistoryAgents ${args} {
    agents {
        updateCampaignHistory(id: $id, state: $state, error: $error) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateCampaignHistoryAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateCampaignHistoryAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CreateTokenAgentsResultType = {
    agents: {
        createToken?: Maybe<ServiceSingleResultOfGuid>
    }
}

export type CreateTokenAgentsBaseVariables = {
	entity: Maybe<AgentTokenInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CreateTokenAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: AgentTokenInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation createTokenAgents ${args} {
    agents {
        createToken(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CreateTokenAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CreateTokenAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfGuid", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteTokenAgentsResultType = {
    agents: {
        deleteToken?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteTokenAgentsBaseVariables = {
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DeleteTokenAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteTokenAgents ${args} {
    agents {
        deleteToken(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteTokenAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteTokenAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
