import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPrioritiesBaseVariables, FirstPrioritiesBaseVariables, CountPrioritiesBaseVariables, FindPrioritiesBaseVariables, SearchPrioritiesBaseVariables, ExportSchemaPrioritiesBaseVariables, ExportDataPrioritiesBaseVariables, CustomQueryPrioritiesBaseVariables, CustomQueryIdPrioritiesBaseVariables, FindUnassociatedSecurityGroupsPrioritiesBaseVariables, FindAssociatedOperatorTeamSecurityGroupsPrioritiesBaseVariables, FindAssociatedPopulationSecurityGroupsPrioritiesBaseVariables, UsedPrioritiesBaseVariables, ExistPrioritiesBaseVariables, FindRecyclesPrioritiesBaseVariables, CountRecyclesPrioritiesBaseVariables, ReadOnlyPrioritiesBaseVariables, FindArchivedPrioritiesBaseVariables, CountAllPrioritiesBaseVariables, FindDynamicPropertyFieldsPrioritiesBaseVariables, FindUnassociatedServiceLevelAgreementsPrioritiesBaseVariables, FindUnassociatedTicketsPrioritiesBaseVariables, FindUnassociatedIncidentModelsPrioritiesBaseVariables, InsertPrioritiesBaseVariables, UpdatePrioritiesBaseVariables, ImportDataPrioritiesBaseVariables, UpdateManyPrioritiesBaseVariables, DeletePrioritiesBaseVariables, OrderPrioritiesBaseVariables, AddSecurityGroupPrioritiesBaseVariables, RemoveSecurityGroupPrioritiesBaseVariables, AddOperatorTeamSecurityGroupPrioritiesBaseVariables, RemoveOperatorTeamSecurityGroupPrioritiesBaseVariables, AddPopulationSecurityGroupPrioritiesBaseVariables, RemovePopulationSecurityGroupPrioritiesBaseVariables, RestorePrioritiesBaseVariables, RecyclePrioritiesBaseVariables, RestoreArchivedPrioritiesBaseVariables, ArchivedPrioritiesBaseVariables, AddFileDynamicFieldPrioritiesBaseVariables, RemoveFileDynamicFieldPrioritiesBaseVariables, AddServiceLevelAgreementsPrioritiesBaseVariables, RemoveServiceLevelAgreementsPrioritiesBaseVariables, AddIncidentModelsPrioritiesBaseVariables, RemoveIncidentModelsPrioritiesBaseVariables, AddTicketsPrioritiesBaseVariables, RemoveTicketsPrioritiesBaseVariables } from '../gqls'
import { GetPrioritiesDocument, FirstPrioritiesDocument, CountPrioritiesDocument, FindPrioritiesDocument, SearchPrioritiesDocument, ExportSchemaPrioritiesDocument, ExportDataPrioritiesDocument, CustomQueryPrioritiesDocument, CustomQueryIdPrioritiesDocument, FindUnassociatedSecurityGroupsPrioritiesDocument, FindAssociatedOperatorTeamSecurityGroupsPrioritiesDocument, FindAssociatedPopulationSecurityGroupsPrioritiesDocument, UsedPrioritiesDocument, ExistPrioritiesDocument, FindRecyclesPrioritiesDocument, CountRecyclesPrioritiesDocument, ReadOnlyPrioritiesDocument, FindArchivedPrioritiesDocument, CountAllPrioritiesDocument, FindDynamicPropertyFieldsPrioritiesDocument, FindUnassociatedServiceLevelAgreementsPrioritiesDocument, FindUnassociatedTicketsPrioritiesDocument, FindUnassociatedIncidentModelsPrioritiesDocument, InsertPrioritiesDocument, UpdatePrioritiesDocument, ImportDataPrioritiesDocument, UpdateManyPrioritiesDocument, DeletePrioritiesDocument, OrderPrioritiesDocument, AddSecurityGroupPrioritiesDocument, RemoveSecurityGroupPrioritiesDocument, AddOperatorTeamSecurityGroupPrioritiesDocument, RemoveOperatorTeamSecurityGroupPrioritiesDocument, AddPopulationSecurityGroupPrioritiesDocument, RemovePopulationSecurityGroupPrioritiesDocument, RestorePrioritiesDocument, RecyclePrioritiesDocument, RestoreArchivedPrioritiesDocument, ArchivedPrioritiesDocument, AddFileDynamicFieldPrioritiesDocument, RemoveFileDynamicFieldPrioritiesDocument, AddServiceLevelAgreementsPrioritiesDocument, RemoveServiceLevelAgreementsPrioritiesDocument, AddIncidentModelsPrioritiesDocument, RemoveIncidentModelsPrioritiesDocument, AddTicketsPrioritiesDocument, RemoveTicketsPrioritiesDocument } from '../gqls'
import { ServiceSingleResultOfPriority, QueryContextOfPriority, FilterOfPriority, ServiceSingleResultOfInt64, ServiceListResultOfPriority, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfPopulation, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfPriority, QueryContextOfServiceLevelAgreement, FilterOfServiceLevelAgreement, ServiceListResultOfServiceLevelAgreement, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, QueryContextOfIncidentModel, FilterOfIncidentModel, ServiceListResultOfIncidentModel, PriorityInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PriorityBaseService {
    
public modelName = 'priority';
public modelPluralName = 'priorities';

	private getPrioritiesQuery: GetPrioritiesDocument;
	private firstPrioritiesQuery: FirstPrioritiesDocument;
	private countPrioritiesQuery: CountPrioritiesDocument;
	private findPrioritiesQuery: FindPrioritiesDocument;
	private searchPrioritiesQuery: SearchPrioritiesDocument;
	private exportSchemaPrioritiesQuery: ExportSchemaPrioritiesDocument;
	private exportDataPrioritiesQuery: ExportDataPrioritiesDocument;
	private customQueryPrioritiesQuery: CustomQueryPrioritiesDocument;
	private customQueryIdPrioritiesQuery: CustomQueryIdPrioritiesDocument;
	private findUnassociatedSecurityGroupsPrioritiesQuery: FindUnassociatedSecurityGroupsPrioritiesDocument;
	private findAssociatedOperatorTeamSecurityGroupsPrioritiesQuery: FindAssociatedOperatorTeamSecurityGroupsPrioritiesDocument;
	private findAssociatedPopulationSecurityGroupsPrioritiesQuery: FindAssociatedPopulationSecurityGroupsPrioritiesDocument;
	private usedPrioritiesQuery: UsedPrioritiesDocument;
	private existPrioritiesQuery: ExistPrioritiesDocument;
	private findRecyclesPrioritiesQuery: FindRecyclesPrioritiesDocument;
	private countRecyclesPrioritiesQuery: CountRecyclesPrioritiesDocument;
	private readOnlyPrioritiesQuery: ReadOnlyPrioritiesDocument;
	private findArchivedPrioritiesQuery: FindArchivedPrioritiesDocument;
	private countAllPrioritiesQuery: CountAllPrioritiesDocument;
	private findDynamicPropertyFieldsPrioritiesQuery: FindDynamicPropertyFieldsPrioritiesDocument;
	private findUnassociatedServiceLevelAgreementsPrioritiesQuery: FindUnassociatedServiceLevelAgreementsPrioritiesDocument;
	private findUnassociatedTicketsPrioritiesQuery: FindUnassociatedTicketsPrioritiesDocument;
	private findUnassociatedIncidentModelsPrioritiesQuery: FindUnassociatedIncidentModelsPrioritiesDocument;
	private insertPrioritiesMutation: InsertPrioritiesDocument;
	private updatePrioritiesMutation: UpdatePrioritiesDocument;
	private importDataPrioritiesMutation: ImportDataPrioritiesDocument;
	private updateManyPrioritiesMutation: UpdateManyPrioritiesDocument;
	private deletePrioritiesMutation: DeletePrioritiesDocument;
	private orderPrioritiesMutation: OrderPrioritiesDocument;
	private addSecurityGroupPrioritiesMutation: AddSecurityGroupPrioritiesDocument;
	private removeSecurityGroupPrioritiesMutation: RemoveSecurityGroupPrioritiesDocument;
	private addOperatorTeamSecurityGroupPrioritiesMutation: AddOperatorTeamSecurityGroupPrioritiesDocument;
	private removeOperatorTeamSecurityGroupPrioritiesMutation: RemoveOperatorTeamSecurityGroupPrioritiesDocument;
	private addPopulationSecurityGroupPrioritiesMutation: AddPopulationSecurityGroupPrioritiesDocument;
	private removePopulationSecurityGroupPrioritiesMutation: RemovePopulationSecurityGroupPrioritiesDocument;
	private restorePrioritiesMutation: RestorePrioritiesDocument;
	private recyclePrioritiesMutation: RecyclePrioritiesDocument;
	private restoreArchivedPrioritiesMutation: RestoreArchivedPrioritiesDocument;
	private archivedPrioritiesMutation: ArchivedPrioritiesDocument;
	private addFileDynamicFieldPrioritiesMutation: AddFileDynamicFieldPrioritiesDocument;
	private removeFileDynamicFieldPrioritiesMutation: RemoveFileDynamicFieldPrioritiesDocument;
	private addServiceLevelAgreementsPrioritiesMutation: AddServiceLevelAgreementsPrioritiesDocument;
	private removeServiceLevelAgreementsPrioritiesMutation: RemoveServiceLevelAgreementsPrioritiesDocument;
	private addIncidentModelsPrioritiesMutation: AddIncidentModelsPrioritiesDocument;
	private removeIncidentModelsPrioritiesMutation: RemoveIncidentModelsPrioritiesDocument;
	private addTicketsPrioritiesMutation: AddTicketsPrioritiesDocument;
	private removeTicketsPrioritiesMutation: RemoveTicketsPrioritiesDocument;

	constructor(private injector: Injector) {
		this.getPrioritiesQuery = this.injector.get(GetPrioritiesDocument);
		this.firstPrioritiesQuery = this.injector.get(FirstPrioritiesDocument);
		this.countPrioritiesQuery = this.injector.get(CountPrioritiesDocument);
		this.findPrioritiesQuery = this.injector.get(FindPrioritiesDocument);
		this.searchPrioritiesQuery = this.injector.get(SearchPrioritiesDocument);
		this.exportSchemaPrioritiesQuery = this.injector.get(ExportSchemaPrioritiesDocument);
		this.exportDataPrioritiesQuery = this.injector.get(ExportDataPrioritiesDocument);
		this.customQueryPrioritiesQuery = this.injector.get(CustomQueryPrioritiesDocument);
		this.customQueryIdPrioritiesQuery = this.injector.get(CustomQueryIdPrioritiesDocument);
		this.findUnassociatedSecurityGroupsPrioritiesQuery = this.injector.get(FindUnassociatedSecurityGroupsPrioritiesDocument);
		this.findAssociatedOperatorTeamSecurityGroupsPrioritiesQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsPrioritiesDocument);
		this.findAssociatedPopulationSecurityGroupsPrioritiesQuery = this.injector.get(FindAssociatedPopulationSecurityGroupsPrioritiesDocument);
		this.usedPrioritiesQuery = this.injector.get(UsedPrioritiesDocument);
		this.existPrioritiesQuery = this.injector.get(ExistPrioritiesDocument);
		this.findRecyclesPrioritiesQuery = this.injector.get(FindRecyclesPrioritiesDocument);
		this.countRecyclesPrioritiesQuery = this.injector.get(CountRecyclesPrioritiesDocument);
		this.readOnlyPrioritiesQuery = this.injector.get(ReadOnlyPrioritiesDocument);
		this.findArchivedPrioritiesQuery = this.injector.get(FindArchivedPrioritiesDocument);
		this.countAllPrioritiesQuery = this.injector.get(CountAllPrioritiesDocument);
		this.findDynamicPropertyFieldsPrioritiesQuery = this.injector.get(FindDynamicPropertyFieldsPrioritiesDocument);
		this.findUnassociatedServiceLevelAgreementsPrioritiesQuery = this.injector.get(FindUnassociatedServiceLevelAgreementsPrioritiesDocument);
		this.findUnassociatedTicketsPrioritiesQuery = this.injector.get(FindUnassociatedTicketsPrioritiesDocument);
		this.findUnassociatedIncidentModelsPrioritiesQuery = this.injector.get(FindUnassociatedIncidentModelsPrioritiesDocument);
		this.insertPrioritiesMutation = this.injector.get(InsertPrioritiesDocument);
		this.updatePrioritiesMutation = this.injector.get(UpdatePrioritiesDocument);
		this.importDataPrioritiesMutation = this.injector.get(ImportDataPrioritiesDocument);
		this.updateManyPrioritiesMutation = this.injector.get(UpdateManyPrioritiesDocument);
		this.deletePrioritiesMutation = this.injector.get(DeletePrioritiesDocument);
		this.orderPrioritiesMutation = this.injector.get(OrderPrioritiesDocument);
		this.addSecurityGroupPrioritiesMutation = this.injector.get(AddSecurityGroupPrioritiesDocument);
		this.removeSecurityGroupPrioritiesMutation = this.injector.get(RemoveSecurityGroupPrioritiesDocument);
		this.addOperatorTeamSecurityGroupPrioritiesMutation = this.injector.get(AddOperatorTeamSecurityGroupPrioritiesDocument);
		this.removeOperatorTeamSecurityGroupPrioritiesMutation = this.injector.get(RemoveOperatorTeamSecurityGroupPrioritiesDocument);
		this.addPopulationSecurityGroupPrioritiesMutation = this.injector.get(AddPopulationSecurityGroupPrioritiesDocument);
		this.removePopulationSecurityGroupPrioritiesMutation = this.injector.get(RemovePopulationSecurityGroupPrioritiesDocument);
		this.restorePrioritiesMutation = this.injector.get(RestorePrioritiesDocument);
		this.recyclePrioritiesMutation = this.injector.get(RecyclePrioritiesDocument);
		this.restoreArchivedPrioritiesMutation = this.injector.get(RestoreArchivedPrioritiesDocument);
		this.archivedPrioritiesMutation = this.injector.get(ArchivedPrioritiesDocument);
		this.addFileDynamicFieldPrioritiesMutation = this.injector.get(AddFileDynamicFieldPrioritiesDocument);
		this.removeFileDynamicFieldPrioritiesMutation = this.injector.get(RemoveFileDynamicFieldPrioritiesDocument);
		this.addServiceLevelAgreementsPrioritiesMutation = this.injector.get(AddServiceLevelAgreementsPrioritiesDocument);
		this.removeServiceLevelAgreementsPrioritiesMutation = this.injector.get(RemoveServiceLevelAgreementsPrioritiesDocument);
		this.addIncidentModelsPrioritiesMutation = this.injector.get(AddIncidentModelsPrioritiesDocument);
		this.removeIncidentModelsPrioritiesMutation = this.injector.get(RemoveIncidentModelsPrioritiesDocument);
		this.addTicketsPrioritiesMutation = this.injector.get(AddTicketsPrioritiesDocument);
		this.removeTicketsPrioritiesMutation = this.injector.get(RemoveTicketsPrioritiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPriority> {
    
    		let variables: GetPrioritiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.get));
            }
            else{
                let result:ServiceSingleResultOfPriority={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPriority> {
    
    		let variables: FirstPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: FindPrioritiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPriority,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: SearchPrioritiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPrioritiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: CustomQueryPrioritiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: CustomQueryIdPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les population via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedPopulationSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindAssociatedPopulationSecurityGroupsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedPopulationSecurityGroupsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findAssociatedPopulationSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPrioritiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: FindRecyclesPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyPrioritiesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPriority,
		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPriority> {
    
    		let variables: FindArchivedPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsPrioritiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedServiceLevelAgreements(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfServiceLevelAgreement> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('serviceLevelAgreements', fields, null, [
			GqlSubFieldArg.create('filterOfServiceLevelAgreements', 'filter'),
			GqlSubFieldArg.create('optionsOfServiceLevelAgreements', 'options'),
		]),
		])
		extendedVariables['filterOfServiceLevelAgreements'] = filter;
		extendedVariables['optionsOfServiceLevelAgreements'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.serviceLevelAgreements));
            }
            else{
                let result: ServiceListResultOfServiceLevelAgreement = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceLevelAgreement> {
    
    		let variables: FindUnassociatedServiceLevelAgreementsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedServiceLevelAgreementsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findUnassociatedServiceLevelAgreements));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findUnassociatedTickets));
    	}

	@InjectArgs
	public findAssociatedIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('incidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfIncidentModels'] = filter;
		extendedVariables['optionsOfIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.incidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedIncidentModelsPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedIncidentModelsPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.findUnassociatedIncidentModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PriorityInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPriority> {
    
    		let variables: InsertPrioritiesBaseVariables = {
    			entity: entity
    		}
    				return this.insertPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPriority> {
    
    		let variables: UpdatePrioritiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPrioritiesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPrioritiesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.deletePrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderPrioritiesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.order));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			populationIds: populationIds,
			isNew: isNew
    		}
    				return this.addPopulationSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addPopulationSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationSecurityGroupPrioritiesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationSecurityGroupPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removePopulationSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestorePrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.restorePrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecyclePrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.recyclePrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldPrioritiesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldPrioritiesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddServiceLevelAgreementsPrioritiesBaseVariables = {
    			id: id,
			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.addServiceLevelAgreementsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addServiceLevelAgreements));
    	}

    	/**  */
    	@InjectArgs
    	public removeServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveServiceLevelAgreementsPrioritiesBaseVariables = {
    			id: id,
			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.removeServiceLevelAgreementsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeServiceLevelAgreements));
    	}

    	/**  */
    	@InjectArgs
    	public addIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddIncidentModelsPrioritiesBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addIncidentModelsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveIncidentModelsPrioritiesBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeIncidentModelsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsPrioritiesBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsPrioritiesBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.priorities.removeTickets));
    	}
    
}