import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByAzureAdTasksBaseVariables, FirstScanByAzureAdTasksBaseVariables, CountScanByAzureAdTasksBaseVariables, FindScanByAzureAdTasksBaseVariables, SearchScanByAzureAdTasksBaseVariables, ExportSchemaScanByAzureAdTasksBaseVariables, ExportDataScanByAzureAdTasksBaseVariables, CustomQueryScanByAzureAdTasksBaseVariables, CustomQueryIdScanByAzureAdTasksBaseVariables, UsedScanByAzureAdTasksBaseVariables, ExistScanByAzureAdTasksBaseVariables, InsertScanByAzureAdTasksBaseVariables, UpdateScanByAzureAdTasksBaseVariables, ImportDataScanByAzureAdTasksBaseVariables, UpdateManyScanByAzureAdTasksBaseVariables, DeleteScanByAzureAdTasksBaseVariables } from '../gqls'
import { GetScanByAzureAdTasksDocument, FirstScanByAzureAdTasksDocument, CountScanByAzureAdTasksDocument, FindScanByAzureAdTasksDocument, SearchScanByAzureAdTasksDocument, ExportSchemaScanByAzureAdTasksDocument, ExportDataScanByAzureAdTasksDocument, CustomQueryScanByAzureAdTasksDocument, CustomQueryIdScanByAzureAdTasksDocument, UsedScanByAzureAdTasksDocument, ExistScanByAzureAdTasksDocument, InsertScanByAzureAdTasksDocument, UpdateScanByAzureAdTasksDocument, ImportDataScanByAzureAdTasksDocument, UpdateManyScanByAzureAdTasksDocument, DeleteScanByAzureAdTasksDocument } from '../gqls'
import { ServiceSingleResultOfScanByAzureAdTask, QueryContextOfScanByAzureAdTask, FilterOfScanByAzureAdTask, ServiceSingleResultOfInt64, ServiceListResultOfScanByAzureAdTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ScanByAzureAdTaskInput, FieldUpdateOperatorOfScanByAzureAdTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByAzureAdTaskBaseService {
    
public modelName = 'scanByAzureAdTask';
public modelPluralName = 'scanByAzureAdTasks';

	private getScanByAzureAdTasksQuery: GetScanByAzureAdTasksDocument;
	private firstScanByAzureAdTasksQuery: FirstScanByAzureAdTasksDocument;
	private countScanByAzureAdTasksQuery: CountScanByAzureAdTasksDocument;
	private findScanByAzureAdTasksQuery: FindScanByAzureAdTasksDocument;
	private searchScanByAzureAdTasksQuery: SearchScanByAzureAdTasksDocument;
	private exportSchemaScanByAzureAdTasksQuery: ExportSchemaScanByAzureAdTasksDocument;
	private exportDataScanByAzureAdTasksQuery: ExportDataScanByAzureAdTasksDocument;
	private customQueryScanByAzureAdTasksQuery: CustomQueryScanByAzureAdTasksDocument;
	private customQueryIdScanByAzureAdTasksQuery: CustomQueryIdScanByAzureAdTasksDocument;
	private usedScanByAzureAdTasksQuery: UsedScanByAzureAdTasksDocument;
	private existScanByAzureAdTasksQuery: ExistScanByAzureAdTasksDocument;
	private insertScanByAzureAdTasksMutation: InsertScanByAzureAdTasksDocument;
	private updateScanByAzureAdTasksMutation: UpdateScanByAzureAdTasksDocument;
	private importDataScanByAzureAdTasksMutation: ImportDataScanByAzureAdTasksDocument;
	private updateManyScanByAzureAdTasksMutation: UpdateManyScanByAzureAdTasksDocument;
	private deleteScanByAzureAdTasksMutation: DeleteScanByAzureAdTasksDocument;

	constructor(private injector: Injector) {
		this.getScanByAzureAdTasksQuery = this.injector.get(GetScanByAzureAdTasksDocument);
		this.firstScanByAzureAdTasksQuery = this.injector.get(FirstScanByAzureAdTasksDocument);
		this.countScanByAzureAdTasksQuery = this.injector.get(CountScanByAzureAdTasksDocument);
		this.findScanByAzureAdTasksQuery = this.injector.get(FindScanByAzureAdTasksDocument);
		this.searchScanByAzureAdTasksQuery = this.injector.get(SearchScanByAzureAdTasksDocument);
		this.exportSchemaScanByAzureAdTasksQuery = this.injector.get(ExportSchemaScanByAzureAdTasksDocument);
		this.exportDataScanByAzureAdTasksQuery = this.injector.get(ExportDataScanByAzureAdTasksDocument);
		this.customQueryScanByAzureAdTasksQuery = this.injector.get(CustomQueryScanByAzureAdTasksDocument);
		this.customQueryIdScanByAzureAdTasksQuery = this.injector.get(CustomQueryIdScanByAzureAdTasksDocument);
		this.usedScanByAzureAdTasksQuery = this.injector.get(UsedScanByAzureAdTasksDocument);
		this.existScanByAzureAdTasksQuery = this.injector.get(ExistScanByAzureAdTasksDocument);
		this.insertScanByAzureAdTasksMutation = this.injector.get(InsertScanByAzureAdTasksDocument);
		this.updateScanByAzureAdTasksMutation = this.injector.get(UpdateScanByAzureAdTasksDocument);
		this.importDataScanByAzureAdTasksMutation = this.injector.get(ImportDataScanByAzureAdTasksDocument);
		this.updateManyScanByAzureAdTasksMutation = this.injector.get(UpdateManyScanByAzureAdTasksDocument);
		this.deleteScanByAzureAdTasksMutation = this.injector.get(DeleteScanByAzureAdTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdTask> {
    
    		let variables: GetScanByAzureAdTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.get));
            }
            else{
                let result:ServiceSingleResultOfScanByAzureAdTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdTask> {
    
    		let variables: FirstScanByAzureAdTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByAzureAdTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdTask> {
    
    		let variables: FindScanByAzureAdTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdTask> {
    
    		let variables: SearchScanByAzureAdTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByAzureAdTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByAzureAdTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdTask> {
    
    		let variables: CustomQueryScanByAzureAdTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdTask> {
    
    		let variables: CustomQueryIdScanByAzureAdTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByAzureAdTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByAzureAdTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByAzureAdTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdTask> {
    
    		let variables: InsertScanByAzureAdTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdTask> {
    
    		let variables: UpdateScanByAzureAdTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByAzureAdTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByAzureAdTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByAzureAdTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdTasks.delete));
    	}
    
}