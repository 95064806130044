import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetPropertyTrackingsBaseVariables, FirstAssetPropertyTrackingsBaseVariables, CountAssetPropertyTrackingsBaseVariables, FindAssetPropertyTrackingsBaseVariables, SearchAssetPropertyTrackingsBaseVariables, ExportSchemaAssetPropertyTrackingsBaseVariables, ExportDataAssetPropertyTrackingsBaseVariables, CustomQueryAssetPropertyTrackingsBaseVariables, CustomQueryIdAssetPropertyTrackingsBaseVariables, UsedAssetPropertyTrackingsBaseVariables, ExistAssetPropertyTrackingsBaseVariables, InsertAssetPropertyTrackingsBaseVariables, UpdateAssetPropertyTrackingsBaseVariables, ImportDataAssetPropertyTrackingsBaseVariables, UpdateManyAssetPropertyTrackingsBaseVariables, DeleteAssetPropertyTrackingsBaseVariables } from '../gqls'
import { GetAssetPropertyTrackingsDocument, FirstAssetPropertyTrackingsDocument, CountAssetPropertyTrackingsDocument, FindAssetPropertyTrackingsDocument, SearchAssetPropertyTrackingsDocument, ExportSchemaAssetPropertyTrackingsDocument, ExportDataAssetPropertyTrackingsDocument, CustomQueryAssetPropertyTrackingsDocument, CustomQueryIdAssetPropertyTrackingsDocument, UsedAssetPropertyTrackingsDocument, ExistAssetPropertyTrackingsDocument, InsertAssetPropertyTrackingsDocument, UpdateAssetPropertyTrackingsDocument, ImportDataAssetPropertyTrackingsDocument, UpdateManyAssetPropertyTrackingsDocument, DeleteAssetPropertyTrackingsDocument } from '../gqls'
import { ServiceSingleResultOfAssetPropertyTracking, QueryContextOfAssetPropertyTracking, FilterOfAssetPropertyTracking, ServiceSingleResultOfInt64, ServiceListResultOfAssetPropertyTracking, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetPropertyTrackingInput, FieldUpdateOperatorOfAssetPropertyTracking } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetPropertyTrackingBaseService {
    
public modelName = 'assetPropertyTracking';
public modelPluralName = 'assetPropertyTrackings';

	private getAssetPropertyTrackingsQuery: GetAssetPropertyTrackingsDocument;
	private firstAssetPropertyTrackingsQuery: FirstAssetPropertyTrackingsDocument;
	private countAssetPropertyTrackingsQuery: CountAssetPropertyTrackingsDocument;
	private findAssetPropertyTrackingsQuery: FindAssetPropertyTrackingsDocument;
	private searchAssetPropertyTrackingsQuery: SearchAssetPropertyTrackingsDocument;
	private exportSchemaAssetPropertyTrackingsQuery: ExportSchemaAssetPropertyTrackingsDocument;
	private exportDataAssetPropertyTrackingsQuery: ExportDataAssetPropertyTrackingsDocument;
	private customQueryAssetPropertyTrackingsQuery: CustomQueryAssetPropertyTrackingsDocument;
	private customQueryIdAssetPropertyTrackingsQuery: CustomQueryIdAssetPropertyTrackingsDocument;
	private usedAssetPropertyTrackingsQuery: UsedAssetPropertyTrackingsDocument;
	private existAssetPropertyTrackingsQuery: ExistAssetPropertyTrackingsDocument;
	private insertAssetPropertyTrackingsMutation: InsertAssetPropertyTrackingsDocument;
	private updateAssetPropertyTrackingsMutation: UpdateAssetPropertyTrackingsDocument;
	private importDataAssetPropertyTrackingsMutation: ImportDataAssetPropertyTrackingsDocument;
	private updateManyAssetPropertyTrackingsMutation: UpdateManyAssetPropertyTrackingsDocument;
	private deleteAssetPropertyTrackingsMutation: DeleteAssetPropertyTrackingsDocument;

	constructor(private injector: Injector) {
		this.getAssetPropertyTrackingsQuery = this.injector.get(GetAssetPropertyTrackingsDocument);
		this.firstAssetPropertyTrackingsQuery = this.injector.get(FirstAssetPropertyTrackingsDocument);
		this.countAssetPropertyTrackingsQuery = this.injector.get(CountAssetPropertyTrackingsDocument);
		this.findAssetPropertyTrackingsQuery = this.injector.get(FindAssetPropertyTrackingsDocument);
		this.searchAssetPropertyTrackingsQuery = this.injector.get(SearchAssetPropertyTrackingsDocument);
		this.exportSchemaAssetPropertyTrackingsQuery = this.injector.get(ExportSchemaAssetPropertyTrackingsDocument);
		this.exportDataAssetPropertyTrackingsQuery = this.injector.get(ExportDataAssetPropertyTrackingsDocument);
		this.customQueryAssetPropertyTrackingsQuery = this.injector.get(CustomQueryAssetPropertyTrackingsDocument);
		this.customQueryIdAssetPropertyTrackingsQuery = this.injector.get(CustomQueryIdAssetPropertyTrackingsDocument);
		this.usedAssetPropertyTrackingsQuery = this.injector.get(UsedAssetPropertyTrackingsDocument);
		this.existAssetPropertyTrackingsQuery = this.injector.get(ExistAssetPropertyTrackingsDocument);
		this.insertAssetPropertyTrackingsMutation = this.injector.get(InsertAssetPropertyTrackingsDocument);
		this.updateAssetPropertyTrackingsMutation = this.injector.get(UpdateAssetPropertyTrackingsDocument);
		this.importDataAssetPropertyTrackingsMutation = this.injector.get(ImportDataAssetPropertyTrackingsDocument);
		this.updateManyAssetPropertyTrackingsMutation = this.injector.get(UpdateManyAssetPropertyTrackingsDocument);
		this.deleteAssetPropertyTrackingsMutation = this.injector.get(DeleteAssetPropertyTrackingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetPropertyTracking> {
    
    		let variables: GetAssetPropertyTrackingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.get));
            }
            else{
                let result:ServiceSingleResultOfAssetPropertyTracking={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetPropertyTracking,
		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetPropertyTracking> {
    
    		let variables: FirstAssetPropertyTrackingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetPropertyTrackingsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetPropertyTracking,
		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetPropertyTracking> {
    
    		let variables: FindAssetPropertyTrackingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetPropertyTracking,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetPropertyTracking> {
    
    		let variables: SearchAssetPropertyTrackingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetPropertyTrackingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetPropertyTrackingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetPropertyTracking,
		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetPropertyTracking> {
    
    		let variables: CustomQueryAssetPropertyTrackingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetPropertyTracking,
		@Args('filter?') filter?: FilterOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetPropertyTracking> {
    
    		let variables: CustomQueryIdAssetPropertyTrackingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetPropertyTrackingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetPropertyTrackingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetPropertyTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetPropertyTrackingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetPropertyTracking> {
    
    		let variables: InsertAssetPropertyTrackingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetPropertyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetPropertyTracking> {
    
    		let variables: UpdateAssetPropertyTrackingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetPropertyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetPropertyTrackingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetPropertyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetPropertyTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetPropertyTrackingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetPropertyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetPropertyTrackingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetPropertyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetPropertyTrackings.delete));
    	}
    
}