import { Injectable } from '@angular/core';
import { LayoutModelCoreService } from '@clarilog/core/services2/graphql/generated-types/services/layout-model.service';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { LayoutModelCategory } from '../../../../core/services/graphql/graphql-types';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { LayoutModelContants } from '@clarilog/core/services/layout-model/layout-model.service';

/**
 * Service relatif aux Layout.
 *
 * @export
 * @class LayoutService
 */
export type LayoutConfig = {
  /**Est-ce que le model est un contrat de maintenance */
  name: string;
  id?: string;
  type: LayoutModelCategory;
  model: { [key: string]: any };
  isMaintenance?: boolean;
  contractCategoryKey?: string;
  contractHistoryReadOnly?: boolean;
};

@Injectable({
  providedIn: 'root',
})
export class LayoutCoreService {
  constructor(
    private layoutModelCoreService: LayoutModelCoreService,
    private modelCompilerService: CoreModelCompilerService,
  ) {}
  async load(config: LayoutConfig): Promise<ModelState> {
    let result = await this.layoutModelCoreService
      .getModel([GqlField.create('data')], config.type, config.name, config.id)
      .toPromise();
    //TODO RM
    result.data =
      config.name === LayoutModelContants.locationCategory
        ? JSON.stringify(config.model)
        : result.data;
    let model =
      result != undefined && result.data != undefined
        ? JSON.parse(result.data)
        : config.model;

    /**
     * Lecture seule pour les anciens contrats
     * (Historique des renouvellements)
    */
    if(config.contractHistoryReadOnly){
      model.form.layout.readOnly = true;
    }

    //ContractCategory
    if (config.name === LayoutModelContants.contractCategory) {
      let modelMaintenance = model.form.layout.pages[0].sections[0].groups[0]
        .controls as Array<any>;

      // suppression des validators
      modelMaintenance.forEach((element) => {
        if (
          config.isMaintenance &&
          config.isMaintenance != null &&
          config.isMaintenance != undefined
        ) {
          if (
            element['fieldName'] != 'name' &&
            element['validators'] != undefined
          ) {
            delete element['validators'];
          }
        }

        if (element['fieldName'] == 'contractCategoryId') {
          element['readOnly'] = true;
        }
      });

      if (
        config?.contractCategoryKey != undefined &&
        config?.contractCategoryKey?.toLowerCase() === 'maintenance'
      ) {
        for (let index = 0; index < modelMaintenance.length; index++) {
          const element = modelMaintenance[index];
          if (element['fieldName'] == 'isActiv') {
            delete modelMaintenance[index];
            break;
          }
        }
      }
    }

    let compileModel = await this.modelCompilerService
      .coreCompile(model)
      .toPromise();
    return compileModel;
  }
}
