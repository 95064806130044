import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMatrixPrioritiesBaseVariables, FirstMatrixPrioritiesBaseVariables, CountMatrixPrioritiesBaseVariables, FindMatrixPrioritiesBaseVariables, SearchMatrixPrioritiesBaseVariables, ExportSchemaMatrixPrioritiesBaseVariables, ExportDataMatrixPrioritiesBaseVariables, CustomQueryMatrixPrioritiesBaseVariables, CustomQueryIdMatrixPrioritiesBaseVariables, UsedMatrixPrioritiesBaseVariables, ExistMatrixPrioritiesBaseVariables, FindRecyclesMatrixPrioritiesBaseVariables, CountRecyclesMatrixPrioritiesBaseVariables, ReadOnlyMatrixPrioritiesBaseVariables, FindArchivedMatrixPrioritiesBaseVariables, CountAllMatrixPrioritiesBaseVariables, FindDynamicPropertyFieldsMatrixPrioritiesBaseVariables, InsertMatrixPrioritiesBaseVariables, UpdateMatrixPrioritiesBaseVariables, ImportDataMatrixPrioritiesBaseVariables, UpdateManyMatrixPrioritiesBaseVariables, DeleteMatrixPrioritiesBaseVariables, ForceMatrixPrioritiesBaseVariables, RestoreMatrixPrioritiesBaseVariables, RecycleMatrixPrioritiesBaseVariables, RestoreArchivedMatrixPrioritiesBaseVariables, ArchivedMatrixPrioritiesBaseVariables, AddFileDynamicFieldMatrixPrioritiesBaseVariables, RemoveFileDynamicFieldMatrixPrioritiesBaseVariables } from '../gqls'
import { GetMatrixPrioritiesDocument, FirstMatrixPrioritiesDocument, CountMatrixPrioritiesDocument, FindMatrixPrioritiesDocument, SearchMatrixPrioritiesDocument, ExportSchemaMatrixPrioritiesDocument, ExportDataMatrixPrioritiesDocument, CustomQueryMatrixPrioritiesDocument, CustomQueryIdMatrixPrioritiesDocument, UsedMatrixPrioritiesDocument, ExistMatrixPrioritiesDocument, FindRecyclesMatrixPrioritiesDocument, CountRecyclesMatrixPrioritiesDocument, ReadOnlyMatrixPrioritiesDocument, FindArchivedMatrixPrioritiesDocument, CountAllMatrixPrioritiesDocument, FindDynamicPropertyFieldsMatrixPrioritiesDocument, InsertMatrixPrioritiesDocument, UpdateMatrixPrioritiesDocument, ImportDataMatrixPrioritiesDocument, UpdateManyMatrixPrioritiesDocument, DeleteMatrixPrioritiesDocument, ForceMatrixPrioritiesDocument, RestoreMatrixPrioritiesDocument, RecycleMatrixPrioritiesDocument, RestoreArchivedMatrixPrioritiesDocument, ArchivedMatrixPrioritiesDocument, AddFileDynamicFieldMatrixPrioritiesDocument, RemoveFileDynamicFieldMatrixPrioritiesDocument } from '../gqls'
import { ServiceSingleResultOfMatrixPriority, QueryContextOfMatrixPriority, FilterOfMatrixPriority, ServiceSingleResultOfInt64, ServiceListResultOfMatrixPriority, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfMatrixPriority, MatrixPriorityInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MatrixPriorityBaseService {
    
public modelName = 'matrixPriority';
public modelPluralName = 'matrixPriorities';

	private getMatrixPrioritiesQuery: GetMatrixPrioritiesDocument;
	private firstMatrixPrioritiesQuery: FirstMatrixPrioritiesDocument;
	private countMatrixPrioritiesQuery: CountMatrixPrioritiesDocument;
	private findMatrixPrioritiesQuery: FindMatrixPrioritiesDocument;
	private searchMatrixPrioritiesQuery: SearchMatrixPrioritiesDocument;
	private exportSchemaMatrixPrioritiesQuery: ExportSchemaMatrixPrioritiesDocument;
	private exportDataMatrixPrioritiesQuery: ExportDataMatrixPrioritiesDocument;
	private customQueryMatrixPrioritiesQuery: CustomQueryMatrixPrioritiesDocument;
	private customQueryIdMatrixPrioritiesQuery: CustomQueryIdMatrixPrioritiesDocument;
	private usedMatrixPrioritiesQuery: UsedMatrixPrioritiesDocument;
	private existMatrixPrioritiesQuery: ExistMatrixPrioritiesDocument;
	private findRecyclesMatrixPrioritiesQuery: FindRecyclesMatrixPrioritiesDocument;
	private countRecyclesMatrixPrioritiesQuery: CountRecyclesMatrixPrioritiesDocument;
	private readOnlyMatrixPrioritiesQuery: ReadOnlyMatrixPrioritiesDocument;
	private findArchivedMatrixPrioritiesQuery: FindArchivedMatrixPrioritiesDocument;
	private countAllMatrixPrioritiesQuery: CountAllMatrixPrioritiesDocument;
	private findDynamicPropertyFieldsMatrixPrioritiesQuery: FindDynamicPropertyFieldsMatrixPrioritiesDocument;
	private insertMatrixPrioritiesMutation: InsertMatrixPrioritiesDocument;
	private updateMatrixPrioritiesMutation: UpdateMatrixPrioritiesDocument;
	private importDataMatrixPrioritiesMutation: ImportDataMatrixPrioritiesDocument;
	private updateManyMatrixPrioritiesMutation: UpdateManyMatrixPrioritiesDocument;
	private deleteMatrixPrioritiesMutation: DeleteMatrixPrioritiesDocument;
	private forceMatrixPrioritiesMutation: ForceMatrixPrioritiesDocument;
	private restoreMatrixPrioritiesMutation: RestoreMatrixPrioritiesDocument;
	private recycleMatrixPrioritiesMutation: RecycleMatrixPrioritiesDocument;
	private restoreArchivedMatrixPrioritiesMutation: RestoreArchivedMatrixPrioritiesDocument;
	private archivedMatrixPrioritiesMutation: ArchivedMatrixPrioritiesDocument;
	private addFileDynamicFieldMatrixPrioritiesMutation: AddFileDynamicFieldMatrixPrioritiesDocument;
	private removeFileDynamicFieldMatrixPrioritiesMutation: RemoveFileDynamicFieldMatrixPrioritiesDocument;

	constructor(private injector: Injector) {
		this.getMatrixPrioritiesQuery = this.injector.get(GetMatrixPrioritiesDocument);
		this.firstMatrixPrioritiesQuery = this.injector.get(FirstMatrixPrioritiesDocument);
		this.countMatrixPrioritiesQuery = this.injector.get(CountMatrixPrioritiesDocument);
		this.findMatrixPrioritiesQuery = this.injector.get(FindMatrixPrioritiesDocument);
		this.searchMatrixPrioritiesQuery = this.injector.get(SearchMatrixPrioritiesDocument);
		this.exportSchemaMatrixPrioritiesQuery = this.injector.get(ExportSchemaMatrixPrioritiesDocument);
		this.exportDataMatrixPrioritiesQuery = this.injector.get(ExportDataMatrixPrioritiesDocument);
		this.customQueryMatrixPrioritiesQuery = this.injector.get(CustomQueryMatrixPrioritiesDocument);
		this.customQueryIdMatrixPrioritiesQuery = this.injector.get(CustomQueryIdMatrixPrioritiesDocument);
		this.usedMatrixPrioritiesQuery = this.injector.get(UsedMatrixPrioritiesDocument);
		this.existMatrixPrioritiesQuery = this.injector.get(ExistMatrixPrioritiesDocument);
		this.findRecyclesMatrixPrioritiesQuery = this.injector.get(FindRecyclesMatrixPrioritiesDocument);
		this.countRecyclesMatrixPrioritiesQuery = this.injector.get(CountRecyclesMatrixPrioritiesDocument);
		this.readOnlyMatrixPrioritiesQuery = this.injector.get(ReadOnlyMatrixPrioritiesDocument);
		this.findArchivedMatrixPrioritiesQuery = this.injector.get(FindArchivedMatrixPrioritiesDocument);
		this.countAllMatrixPrioritiesQuery = this.injector.get(CountAllMatrixPrioritiesDocument);
		this.findDynamicPropertyFieldsMatrixPrioritiesQuery = this.injector.get(FindDynamicPropertyFieldsMatrixPrioritiesDocument);
		this.insertMatrixPrioritiesMutation = this.injector.get(InsertMatrixPrioritiesDocument);
		this.updateMatrixPrioritiesMutation = this.injector.get(UpdateMatrixPrioritiesDocument);
		this.importDataMatrixPrioritiesMutation = this.injector.get(ImportDataMatrixPrioritiesDocument);
		this.updateManyMatrixPrioritiesMutation = this.injector.get(UpdateManyMatrixPrioritiesDocument);
		this.deleteMatrixPrioritiesMutation = this.injector.get(DeleteMatrixPrioritiesDocument);
		this.forceMatrixPrioritiesMutation = this.injector.get(ForceMatrixPrioritiesDocument);
		this.restoreMatrixPrioritiesMutation = this.injector.get(RestoreMatrixPrioritiesDocument);
		this.recycleMatrixPrioritiesMutation = this.injector.get(RecycleMatrixPrioritiesDocument);
		this.restoreArchivedMatrixPrioritiesMutation = this.injector.get(RestoreArchivedMatrixPrioritiesDocument);
		this.archivedMatrixPrioritiesMutation = this.injector.get(ArchivedMatrixPrioritiesDocument);
		this.addFileDynamicFieldMatrixPrioritiesMutation = this.injector.get(AddFileDynamicFieldMatrixPrioritiesDocument);
		this.removeFileDynamicFieldMatrixPrioritiesMutation = this.injector.get(RemoveFileDynamicFieldMatrixPrioritiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMatrixPriority> {
    
    		let variables: GetMatrixPrioritiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.get));
            }
            else{
                let result:ServiceSingleResultOfMatrixPriority={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMatrixPriority> {
    
    		let variables: FirstMatrixPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMatrixPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: FindMatrixPrioritiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: SearchMatrixPrioritiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMatrixPrioritiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMatrixPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: CustomQueryMatrixPrioritiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: CustomQueryIdMatrixPrioritiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMatrixPrioritiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: FindRecyclesMatrixPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesMatrixPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyMatrixPrioritiesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMatrixPriority,
		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMatrixPriority> {
    
    		let variables: FindArchivedMatrixPrioritiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllMatrixPrioritiesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsMatrixPrioritiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsMatrixPrioritiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MatrixPriorityInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMatrixPriority> {
    
    		let variables: InsertMatrixPrioritiesBaseVariables = {
    			entity: entity
    		}
    				return this.insertMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMatrixPriority> {
    
    		let variables: UpdateMatrixPrioritiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMatrixPrioritiesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMatrixPriority,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMatrixPrioritiesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.delete));
    	}

    	/**  */
    	@InjectArgs
    	public force(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ForceMatrixPrioritiesBaseVariables = {
    			id: id
    		}
    				return this.forceMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.force));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedMatrixPrioritiesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldMatrixPrioritiesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldMatrixPrioritiesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldMatrixPrioritiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.matrixPriorities.removeFileDynamicField));
    	}
    
}