import { MyArg, MyType } from "./gql-introspection-parser.service";

import { camelCase } from 'change-case'

export class GeneratorHelper {

    public static download(data: any, name = 'file.ts') {
        let a = document.createElement("a");
        document.body.appendChild(a);

        let blob = new Blob([data], { type: "octet/stream" }),
            url = window.URL.createObjectURL(blob);
        a.href = url;
        a.download = name;
        a.click();
        window.URL.revokeObjectURL(url);

    }

    public static generateGqlType(type: MyType): string {
        if (type.kind === 'LIST') {
            return `[${this.generateGqlType(type.ofType as MyType)}]`
        }
        if (type.kind === 'NON_NULL') {
            return `${this.generateGqlType(type.ofType as MyType)}!`
        }
        return type.name as string
    }

    // public static generateFragmentFieldArgs(args: MyArg[]): string {
    //     if (args.length === 0) {
    //         return '';
    //     }
    //     return `( ${args.map(m => `${m.name}: `)} )`
    // }

    public static convertScalarToType(name: string) {

        switch (name) {
            case 'Int':
                return 'number';
                break;
            case 'Float':
                return 'number'
            case 'Long':
                return 'number'
            case 'Byte':
                return 'number'
            case 'DateTime':
            case 'Date':
                return 'Date'
            case 'Uuid':
            case 'UUID':
                return 'string'
            case 'ID':
                return 'string'
            case 'DecryptType':
                return 'string'; 
            case 'EncryptType':
                return 'string'
            case 'StringJsonType':
                return 'string'
            case 'DynamicPropertyScalarType':
                    return 'string'
            case 'TimeSpan':
                return 'string'
            default:
                return camelCase(name);
                break;
        }
    }

    public static generateType(type: MyType, isNonNull: boolean = false): string {
        let result = "";

        switch (type.kind) {
            case 'LIST':
                return `Array<${this.generateType(type.ofType as MyType)}>`
            case 'NON_NULL':
                return this.generateType(type.ofType as MyType, true);
            case 'SCALAR':
                result = this.convertScalarToType(type.name as string);
                break;
            case 'ENUM':
                result = type.name as string
                break;
            case 'OBJECT':
                result = type.name as string
                break;
            case 'INTERFACE':
                result = type.name as string
                break;
            case 'INPUT_OBJECT':
                result = type.name as string
                break;
            default:
                console.log(type)
                throw new Error('generateType')
                break;
        }

        if (isNonNull === false) {
            result = `Maybe<${result}>`
        }

        return result;
    }
    public static getApolloMethodName(operationType: string) {
        if (operationType === 'Query') {
            return 'query'
        }
        if (operationType === 'Mutation') {
            return 'mutate'
        }
        if (operationType === 'Subscription') {
            return 'subscribe'
        }
        throw new Error(operationType)

    }

    public static getApolloMethodReturn(operationType: string) {
        if (operationType === 'Query') {
            return 'ApolloQueryResult'
        }
        if (operationType === 'Mutation') {
            return 'FetchResult'
        }
        if (operationType === 'Subscription') {
            return 'ApolloQueryResult'
        }
        throw new Error(operationType)
    }

    public static getApolloMethodVariableName(operationType: string) {
        if (operationType === 'Query') {
            return 'query'
        }
        if (operationType === 'Mutation') {
            return 'mutation'
        }
        if (operationType === 'Subscription') {
            return 'query'
        }
        throw new Error(operationType)

    }

    public static getFinalType(type: MyType): MyType {
        if (type.kind === 'LIST' || type.kind === 'NON_NULL') {
            return this.getFinalType(type.ofType as MyType
            );
        } else {
            return type;
        }
    }

}