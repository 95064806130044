<div class="grid-list-component">
  <!-- 
  <cl-toolbar class="action" *ngIf="options.toolbarItems != undefined && options.toolbarItems.length>0">
    <cl-toolbar-items>
      <cl-toolbar-item-button *ngFor="let toolbarItem of options.toolbarItems" [text]="toolbarItem.label"
        (onClick)="toolbarClick()" [icon]="toolbarItem.icon">
      </cl-toolbar-item-button>
    </cl-toolbar-items>
  </cl-toolbar> -->

  <div class="list-component">
    <dx-data-grid
      *ngIf="options.type == 'Grid'"
      #grid
      class="grid"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      (onContentReady)="onContentReady($event)"
      (onInitialized)="initialized($event)"
      [keyExpr]="options.keyExpr"
      (onSelectionChanged)="
        onSelectionChanged($event, options['selectionChanged'])
      "
      [selectedRowKeys]="
        options['selectedRowKeys'] == undefined ? 'null' : selectedKeys
      "
      [dataSource]="source"
      [selection]="options.selection"
      [hoverStateEnabled]="true"
      [remoteOperations]="options.remoteOperations"
      [editing]="options.editing"
      height="100%"
      [showBorders]="false"
      [showRowLines]="false"
      [columnAutoWidth]="true"
      (onRowUpdating)="onRowUpdating($event)"
      [searchPanel]="{
        visible:
          options['searchPanel'] == undefined ? true : options['searchPanel'],
        width: 200,
        placeholder: 'globals/search' | translate
      }"
      [columnChooser]="{
        enabled:
          options['columnChooser'] == undefined
            ? true
            : options['columnChooser']
      }"
      [paging]="options.paging"
      [pager]="options.pager"
      [groupPanel]="options.groupPanel"
      [grouping]="{
        enabled: true,
        contextMenuEnabled: true
      }"
      [sorting]="{
        mode: 'multiple'
      }"
      [export]="{
        enabled: options['export'] == undefined ? true : options['export'],
        allowExportSelectedData: true,
        fileName: 'One by ClariLog'
      }"
      [filterRow]="{
        visible: true
      }"
      [filterPanel]="{
        visible:
          options['filterPanel'] == undefined ? true : options['filterPanel']
      }"
    >
      <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>

      <dxo-summary>
        <dxi-total-item
          column="id"
          summaryType="count"
          displayFormat="{0} item(s)"
        >
        </dxi-total-item>
      </dxo-summary>
      <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>

      <dxi-column
        *ngFor="let column of options.columns"
        [dataField]="column.field"
        [caption]="column.label"
        [groupIndex]="column.groupIndex"
        [cellTemplate]="column.template"
        [name]="column.field + column.label"
        [allowSorting]="column.allowSorting"
        [allowFiltering]="column.allowFiltering"
        [sortIndex]="column.sortIndex"
        [sortOrder]="column.sortOrder"
        [allowGrouping]="column.allowGrouping"
        [allowEditing]="
          column.allowEditing != undefined ? column.allowEditing : false
        "
        headerCellTemplate="headerTemplate"
        [cellTemplate]="column.link === true ? 'underlineTemplate' : undefined"
      >
        <dxo-lookup
          *ngIf="column.lookup != undefined"
          [dataSource]="
            column.lookup.source.lookup === true
              ? column.lookup.source.datasource.store()
              : column.lookup.source
          "
          [valueExpr]="column.lookup.valueExpr"
          [displayExpr]="column.lookup.displayExpr"
          [allowClearing]="
            column.lookup.allowClearing != undefined
              ? column.lookup.allowClearing
              : false
          "
        >
        </dxo-lookup>
      </dxi-column>

      <ng-container
        *ngFor="
          let column of options.columns | filter : 'template' : undefined : true
        "
      >
        <div *dxTemplate="let data of column.template">
          <ng-template
            *ngTemplateOutlet="
              templateService.get(column.template);
              context: { $implicit: data }
            "
          >
          </ng-template>
        </div>
      </ng-container>

      <div *dxTemplate="let info of 'headerTemplate'">
        <dx-check-box
          *ngIf="info.column.dataType == 'boolean' && info.column.allowEditing"
          (onValueChanged)="onValueChanged($event, info)"
          [text]="info.column.caption"
        ></dx-check-box>
        <div
          *ngIf="info.column.dataType != 'boolean' || !info.column.allowEditing"
        >
          {{ info.column.caption }}
        </div>
      </div>

      <div *dxTemplate="let data of 'underlineTemplate'">
        <a
          href=""
          (auxclick)="underline($event, data); (false)"
          (click)="underline($event, data); (false)"
          >{{ data.value }}</a
        >
      </div>
    </dx-data-grid>
    <dx-tree-list
      *ngIf="options.type == 'Tree'"
      class="tree"
      [dataSource]="source"
      [columnAutoWidth]="true"
      [keyExpr]="options.keyExpr"
      [parentIdExpr]="options.parentIdExpr"
      dataStructure="plain"
      [repaintChangesOnly]="true"
      (onContentReady)="onContentReady($event)"
      (onRowCollapsed)="onRowCollapsedTreeList($event)"
      (onInitialized)="initialized($event)"
      (onSelectionChanged)="onSelectionChanged($event, 'tree')"
      (onOptionChanged)="onOptionChangedTree($event)"
      [selectedRowKeys]="selectedKeys"
      [rootValue]="null"
      [height]="'100%'"
      [hoverStateEnabled]="true"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [noDataText]="options.noDataText"
      (onCellPrepared)="onCellPrepared($event)"
      (onContextMenuPreparing)="onContextMenuPreparing($event)"
      [remoteOperations]="
        options.remoteOperations != undefined
          ? options.remoteOperations
          : {
              filtering: false,
              grouping: false,
              paging: false,
              sorting: false,
              summary: false
            }
      "
      [showRowLines]="false"
      [showBorders]="false"
      [searchPanel]="{
        visible: true,
        width: 200,
        placeholder: 'globals/search' | translate
      }"
      [columnChooser]="{ enabled: true }"
      [paging]="
        options.paging != undefined
          ? options.paging
          : {
              enabled: true,
              pageSize: 20
            }
      "
      [pager]="{
        showPageSizeSelector: false,
        allowedPageSizes: [500000],
        showInfo: true,
        visible: true,
        infoText:
          '{2}' +
          ('items' | translate) +
          (treelistAllSelectKey != undefined && treelistAllSelectKey > 0
            ? ' / ' +
              treelistAllSelectKey +
              ' ' +
              ('globals/toolBarSelectedCount' | translate)
            : '')
      }"
      [filterRow]="{
        visible: true
      }"
      [filterPanel]="{
        visible: true
      }"
      [sorting]="{
        mode: 'multiple'
      }"
    >
      <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      <dxo-selection
        [allowSelectAll]="false"
        [mode]="
          readOnly === true ? 'none' : options.multiple ? 'multiple' : 'single'
        "
        [recursive]="options.recursive != undefined ? options.recursive : false"
      ></dxo-selection>

      <dxi-column
        *ngFor="let column of options.columns"
        [dataField]="column.field"
        [caption]="column.label"
        [format]="column.format"
        [type]="column.type"
        [width]="column.width"
        [cellTemplate]="
          column.template == undefined
            ? column.field == 'name' && options?.route != undefined
              ? 'underlineTemplate'
              : column.template
            : column.template
        "
        [dataType]="column.dataType"
        [visible]="column.visible != undefined ? column.visible : true"
        [showInColumnChooser]="
          column.showInColumnChooser != undefined
            ? column.showInColumnChooser
            : true
        "
        [sortIndex]="column.sortIndex"
        [sortOrder]="column.sortOrder"
        [allowEditing]="
          column.allowEditing != undefined ? column.allowEditing : false
        "
      ></dxi-column>

      <div
        *dxTemplate="let data of 'underlineTemplate'"
        style="display: inline-block"
      >
        <a
          href=""
          (auxclick)="underline($event, data); (false)"
          (click)="underline($event, data); (false)"
          >{{ data.value }}</a
        >
      </div>
      <ng-container
        *ngFor="
          let column of options.columns | filter : 'template' : undefined : true
        "
      >
        <div *dxTemplate="let data of column.template">
          <ng-template
            *ngTemplateOutlet="
              templateService.get(column.template);
              context: {
                $implicit: data,
                column: column
              }
            "
          >
          </ng-template>
        </div>
      </ng-container>
    </dx-tree-list>

    <dx-load-panel
      shadingColor="rgba(0,0,0,0.1)"
      [visible]="loadPanel"
      [showIndicator]="true"
      [showPane]="true"
      [shading]="true"
      [hideOnOutsideClick]="false"
      (onShowing)="onShowingPanel($event)"
    ></dx-load-panel>
  </div>
</div>

<dx-popup
  [width]="
    filterForSelect == undefined ||
    (filterForSelect.length === 1 && filterForSelect[0].items.length === 1)
      ? 800
      : 1050
  "
  [height]="'80%'"
  [showTitle]="false"
  [dragEnabled]="true"
  class="add-item-popup"
  #popup
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [(visible)]="associatePopup"
  (onShown)="show($event)"
>
  <clc-select-list
    #selectList
    [type]="'Grid'"
    [multiple]="true"
    [recursive]="false"
    [columns]="selectColumns"
    [source]="options.selectSource"
    (onClosed)="associatePopup = false"
    [forceLinkEmpty]="false"
    [filters]="filterForSelect"
  >
  </clc-select-list>
</dx-popup>
