import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLogonSessionsBaseVariables, FirstLogonSessionsBaseVariables, CountLogonSessionsBaseVariables, FindLogonSessionsBaseVariables, SearchLogonSessionsBaseVariables, ExportSchemaLogonSessionsBaseVariables, ExportDataLogonSessionsBaseVariables, CustomQueryLogonSessionsBaseVariables, CustomQueryIdLogonSessionsBaseVariables, UsedLogonSessionsBaseVariables, ExistLogonSessionsBaseVariables } from '../gqls'
import { GetLogonSessionsDocument, FirstLogonSessionsDocument, CountLogonSessionsDocument, FindLogonSessionsDocument, SearchLogonSessionsDocument, ExportSchemaLogonSessionsDocument, ExportDataLogonSessionsDocument, CustomQueryLogonSessionsDocument, CustomQueryIdLogonSessionsDocument, UsedLogonSessionsDocument, ExistLogonSessionsDocument } from '../gqls'
import { ServiceSingleResultOfLogonSession, QueryContextOfLogonSession, FilterOfLogonSession, ServiceSingleResultOfInt64, ServiceListResultOfLogonSession, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LogonSessionBaseService {
    
public modelName = 'logonSession';
public modelPluralName = 'logonSessions';

	private getLogonSessionsQuery: GetLogonSessionsDocument;
	private firstLogonSessionsQuery: FirstLogonSessionsDocument;
	private countLogonSessionsQuery: CountLogonSessionsDocument;
	private findLogonSessionsQuery: FindLogonSessionsDocument;
	private searchLogonSessionsQuery: SearchLogonSessionsDocument;
	private exportSchemaLogonSessionsQuery: ExportSchemaLogonSessionsDocument;
	private exportDataLogonSessionsQuery: ExportDataLogonSessionsDocument;
	private customQueryLogonSessionsQuery: CustomQueryLogonSessionsDocument;
	private customQueryIdLogonSessionsQuery: CustomQueryIdLogonSessionsDocument;
	private usedLogonSessionsQuery: UsedLogonSessionsDocument;
	private existLogonSessionsQuery: ExistLogonSessionsDocument;

	constructor(private injector: Injector) {
		this.getLogonSessionsQuery = this.injector.get(GetLogonSessionsDocument);
		this.firstLogonSessionsQuery = this.injector.get(FirstLogonSessionsDocument);
		this.countLogonSessionsQuery = this.injector.get(CountLogonSessionsDocument);
		this.findLogonSessionsQuery = this.injector.get(FindLogonSessionsDocument);
		this.searchLogonSessionsQuery = this.injector.get(SearchLogonSessionsDocument);
		this.exportSchemaLogonSessionsQuery = this.injector.get(ExportSchemaLogonSessionsDocument);
		this.exportDataLogonSessionsQuery = this.injector.get(ExportDataLogonSessionsDocument);
		this.customQueryLogonSessionsQuery = this.injector.get(CustomQueryLogonSessionsDocument);
		this.customQueryIdLogonSessionsQuery = this.injector.get(CustomQueryIdLogonSessionsDocument);
		this.usedLogonSessionsQuery = this.injector.get(UsedLogonSessionsDocument);
		this.existLogonSessionsQuery = this.injector.get(ExistLogonSessionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogonSession> {
    
    		let variables: GetLogonSessionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.get));
            }
            else{
                let result:ServiceSingleResultOfLogonSession={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogonSession,
		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogonSession> {
    
    		let variables: FirstLogonSessionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLogonSessionsBaseVariables = {
    			filter: filter
    		}
    				return this.countLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogonSession,
		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonSession> {
    
    		let variables: FindLogonSessionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLogonSession,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonSession> {
    
    		let variables: SearchLogonSessionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLogonSessionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLogonSessionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLogonSession,
		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonSession> {
    
    		let variables: CustomQueryLogonSessionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLogonSession,
		@Args('filter?') filter?: FilterOfLogonSession,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonSession> {
    
    		let variables: CustomQueryIdLogonSessionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLogonSessionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLogonSessionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLogonSessionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonSessions.exist));
    	}
    
    
}