import { Observable, OperatorFunction, Operator, Subscriber } from "rxjs";

/** Représente l'opérateur qui s'éxecute après toutes les autres.. */
export function after<T, R>(callback: (value: T, index: number) => void, thisArg?: any): OperatorFunction<T, R> {
  return function mapOperation(source: Observable<T>): Observable<R> {
    if (typeof callback !== 'function') {
      throw new TypeError('argument is not a function. Are you looking for `mapTo()`?');
    }
    return source.lift(new AfterOperator(callback, thisArg));
  };
}
/** @internal */
export class AfterOperator<T, R> implements Operator<T, R> {
  constructor(private project: (value: T, index: number) => void, private thisArg: any) {
  }

  call(subscriber: Subscriber<R>, source: any): any {
    return source.subscribe(new AfterSubscriber(subscriber, this.project, this.thisArg));
  }
}

/** @internal */
class AfterSubscriber<T, R> extends Subscriber<T> {
  count: number = 0;
  private thisArg: any;

  constructor(destination: Subscriber<R>,
              private callback: (value: T, index: number) => void,
              thisArg: any) {
    super(destination);
    this.thisArg = thisArg || this;
  }
  protected _next(value: T) {
    
    this.destination.next(value);

    try {
      this.callback.call(this.thisArg, value, this.count++);
    } catch (err) {
      this.destination.error(err);
      return;
    }
   
  }
}