import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { CriteriaHelpers } from '@clarilog/core/services2';
import { CoreAuthorizationSettings } from '@clarilog/core/services2/authorization';
import { ModelRuleApplicationService } from '@clarilog/core/services2/model-rule-application/model-rule-application.service';
import { TranslateService } from '@clarilog/shared';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlField, GqlSubField } from '../helpers';
import { TicketTaskBaseService } from '../service-bases';
import {
  FilterOfTicketTask,
  QueryContextOfTicketTask,
  ServiceListResultOfTicketTask,
  ServiceSingleResultOfTicketTask,
} from '../types';
import { LocalStorageService } from './local-storage-service/local-storage-service';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['task'],
  operator: 'or',
})
export class TicketTaskCoreService extends TicketTaskBaseService {
  constructor(
    injector: Injector,
    private authorizationSettings: CoreAuthorizationSettings,
    private localStorageService: LocalStorageService,
    private modelRulesApplicationService: ModelRuleApplicationService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  public getService(): TicketTaskCoreService {
    return this;
  }

  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    let id = state.sharedContext.params.get('id');
    if (id == undefined) {
      return of(true);
    }
    return this.find([GqlField.create('totalCount')], null, {
      ticketId: { eq: id },
    }).pipe(
      map((res) => {
        if (res.totalCount === 0) {
          return false;
        }
        return true;
      }),
    );
  }

  /** Recupere */
  @InjectArgs
  public findFirstStatus(@Args('id?') id?: string) {
    let res = this.entryStatus(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      id,
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      return res;
    });
  }

  @InjectArgs
  public findMyTask(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: string,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    let qualification = this.findTaskIncidentOrRequest();

    let getSortOrderTicketType = CriteriaHelpers.orderTicketStatusHelper(
      options.sort,
    );

    if (getSortOrderTicketType != null) {
      options.sort = CriteriaHelpers.createSortOptionForTicket(
        getSortOrderTicketType,
      );
    }

    return this.findMyTaskWithoutSequence(
      fields,
      type,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }
  @InjectArgs
  public findMyTeamTask(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: string,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    let qualification = this.findTaskIncidentOrRequest();

    let getSortOrderTicketType = CriteriaHelpers.orderTicketStatusHelper(
      options.sort,
    );

    if (getSortOrderTicketType != null) {
      options.sort = CriteriaHelpers.createSortOptionForTicket(
        getSortOrderTicketType,
      );
    }

    return this.findMyTeamTaskWithoutSequence(
      fields,
      type,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findAllTask(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: string,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    let qualification = this.findTaskIncidentOrRequest();

    let getSortOrderTicketType = CriteriaHelpers.orderTicketStatusHelper(
      options.sort,
    );

    if (getSortOrderTicketType != null) {
      options.sort = CriteriaHelpers.createSortOptionForTicket(
        getSortOrderTicketType,
      );
    }

    return this.findAllTaskWithoutSequence(
      fields,
      type,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findTaskIncidentOrRequest(): string {
    let type = null;
    const policies = this.authorizationSettings.settings._keys;
    const checkIncidentPolicies = policies.includes('incident');
    const checkRequestPolicies = policies.includes('request');
    if (!checkIncidentPolicies && checkRequestPolicies) {
      type = 'request';
    }
    if (checkIncidentPolicies && !checkRequestPolicies) {
      type = 'incident';
    }
    if (!checkIncidentPolicies && !checkRequestPolicies) {
      type = 'none';
    }
    return type;
  }

  @InjectArgs
  public counterByStatuses(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('extendedVariables') extendedVariables?: any,
  ): Observable<any> {
    let qualification = this.findTaskIncidentOrRequest();

    return super.counterByStatuses(fields, qualification, extendedVariables);
  }

  @InjectArgs
  /** Obtient les rule d'affichage */
  getIncidentRules(
    @Args('rawModel') rawModel: any,
    @Args('subFormId') subFormId: string,
    @Args('formGroup') formGroup: any,
  ) {
    return this.localStorageService.user
      .getIncidentModelRule()
      .subscribe((res) => {
        let rule = JSON.parse(JSON.stringify(res.data[0]));

        rule.rules = rule?.taskRules;
        rule.editRules = rule?.taskEditRules;

        this.modelRulesApplicationService.applyModelRule(
          rawModel as any,
          rule,
          undefined,
          formGroup != undefined ? formGroup : undefined,
          subFormId,
        );
      });
  }

  @InjectArgs
  /** Obtient les rule d'affichage */
  getRequestRules(
    @Args('rawModel') rawModel: any,
    @Args('subFormId') subFormId: string,
    @Args('formGroup') formGroup: any,
  ) {
    return this.localStorageService.user
      .getRequestModelRule()
      .subscribe((res) => {
        let rule = JSON.parse(JSON.stringify(res.data[0]));

        rule.rules = rule?.taskRules;
        rule.editRules = rule?.taskEditRules;

        this.modelRulesApplicationService.applyModelRule(
          rawModel as any,
          rule,
          undefined,
          formGroup != undefined ? formGroup : undefined,
          subFormId,
        );
      });
  }

  @InjectArgs
  public getParentsId(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('parentId') parentId: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfTicketTask> {
    let createField = [
      GqlSubField.create('data', [
        GqlSubField.create(
          'title',
          this.translatedFieldHelperService.translatedFields(),
        ),
      ]),
    ];
    return this.get(createField, parentId, extendedVariables);
  }

  @InjectArgs
  public getTicketTaskDescription(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    let descriptionField = [
      GqlSubField.create('data', [
        GqlSubField.create(
          'description',
          this.translatedFieldHelperService.translatedFields(),
        ),
      ]),
    ];

    return this.findTicketTaskInformation(
      descriptionField,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  /** Liste des champs de mention pour le sujet */
  findMentionEmail(): any {
    let mentions = [
      {
        key: '[ticketProperty].userAffected.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userAffected')}`,
      },
      {
        key: 'otherUserImpactedMailOperator',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/allUsersImpactedOperator',
        )}`,
      },
      {
        key: 'otherUserImpactedMailHelpDesk',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/allUsersImpactedHelpDesk',
        )}`,
      },
      {
        key: 'otherUserImpactedMailSupplier',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/allUsersImpactedSupplier',
        )}`,
      },

      {
        key: '[ticketProperty].operatorReferent.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/operatorReferent')}`,
      },
      {
        key: 'ticketOperatorTeamAffectedMail',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamAffectedId',
        )}`,
      },
      {
        key: '[ticketProperty].operatorAffected.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/operatorAffected')}`,
      },
      {
        key: 'ticketOperatorTeamReferentMail',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamReferentMailList',
        )}`,
      },
      {
        key: '[ticketProperty].operatorTeamAffected.emails',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamAffectedMail',
        )}`,
      },

      {
        key: '[ticketProperty].userMakeRequest.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userMakeRequest')}`,
      },
      {
        key: 'managerUserAffectedMail',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/managerUserAffected')}`,
      },
      {
        key: 'managerUserMakeRequestMail',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/managerUserMakeRequest',
        )}`,
      },

      {
        key: '[ticketTaskOperatorMail].operator.email',
        name: `${TranslateService.get(
          'entities/task/_title/singular',
        )} - ${TranslateService.get('entities/incident/operatorAffected')}`,
      },
      {
        key: 'ticketTaskOperatorTeamMail',
        name: `${TranslateService.get(
          'entities/task/_title/singular',
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamAffectedTo',
        )}`,
      },
      {
        key: '[operatorTeamAffectedMail].email',
        name: `${TranslateService.get(
          'entities/task/_title/singular',
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamAffectedId',
        )}`,
      },
      {
        key: '[ticketProperty].operatorTeamReferent.emails',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamReferentMail',
        )}`,
      },
      {
        key: 'otherUserImpactedMail',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/allUsersImpacted')}`,
      },
    ];

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  /** Liste des champs de mention pour le sujet */
  findMentionSubject(): any {
    let getLang = this.translatedFieldHelperService.getTranslateKey();
    let mentions = [
      {
        key: 'title',
        name: TranslateService.get(`entities/task/title`),
      },
      {
        key: 'taskNumber',
        name: TranslateService.get(`entities/task/taskNumber`),
      },
      {
        key: 'order',
        name: TranslateService.get(`entities/task/order`),
      },
      {
        key: 'status.name',
        name: TranslateService.get(`entities/incident/status`),
      },
      {
        key: 'duration',
        name: TranslateService.get(`entities/task/duration`),
      },

      {
        key: '[ticketTaskProperty].operator.name',
        name: TranslateService.get(`entities/task/operatorAffected`),
      },
      {
        key: '[ticketTaskProperty].operatorTeam.name',
        name: TranslateService.get(`entities/task/operatorTeamAffected`),
      },
      {
        key: '[ticketTaskProperty].predecessor.title',
        name: TranslateService.get(`entities/predecessor/title`),
      },
      {
        key: '[ticketTaskProperty].predecessor.taskNumber',
        name: TranslateService.get(`entities/predecessor/taskNumber`),
      },
      {
        key: '[ticketTaskProperty].predecessor.duration',
        name: TranslateService.get(`entities/predecessor/duration`),
      },
      {
        key: '[ticketTaskProperty].predecessor.description',
        name: TranslateService.get(`entities/predecessor/description`),
      },
      {
        key: '[ticketTaskProperty].predecessor.operator.name',
        name: TranslateService.get(`entities/predecessor/operatorAffected`),
      },
      {
        key: '[ticketTaskProperty].predecessor.operatorTeam.name',
        name: TranslateService.get(`entities/predecessor/operatorTeamAffected`),
      },
      {
        key: '[ticketTaskProperty].predecessor.activity.name',
        name: TranslateService.get(`entities/predecessor/activity`),
      },
      {
        key: '[ticketTaskProperty].predecessor.scheduleAt',
        name: TranslateService.get(`entities/predecessor/scheduleAtUTC`),
      },
      {
        key: '[ticketTaskProperty].predecessor.status.name',
        name: TranslateService.get(`entities/predecessor/status`),
      },
      {
        key: 'activity.name',
        name: TranslateService.get(`entities/task/activity`),
      },
      {
        key: '[userName].createdBy',
        name: TranslateService.get(`entities/task/createdBy`),
      },
      {
        key: 'scheduleAt',
        name: TranslateService.get(`entities/task/scheduleAtUTC`),
      },
      {
        key: 'endAt',
        name: TranslateService.get(`entities/task/endAtUTC`),
      },
      {
        key: '[ticketDateUTC].created',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/createdUTC`)}`,
      },

      {
        key: '[ticketDateUTC].respondBefore',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/respondBeforeUTC`)}`,
      },

      {
        key: '[ticketDateUTC].resolveBefore',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/resolveBeforeUTC`)}`,
      },

      {
        key: 'lastDateNoteUTC',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/lastDateNoteUTC')}`,
      },

      {
        key: 'lastDateStatusChangeUTC',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/lastDateStatusChangeUTC',
        )}`,
      },

      {
        key: '[ticketTaskProperty].scheduleAt',
        name: TranslateService.get(`entities/task/scheduleAt`),
      },
      {
        key: '[ticketTaskProperty].endAt',
        name: TranslateService.get(`entities/task/endAt`),
      },
      {
        key: '[ticketProperty].sla.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/serviceLevelAgreement',
        )}`,
      },

      {
        key: '[mainAssetImpacted].manufacturer.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/mainAssetImpactedManufacturer',
        )}`,
      },
      {
        key: '[mainAssetImpacted].model.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/mainAssetImpactedModel',
        )}`,
      },

      {
        key: '[ticketProperty].status.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/ticketStatus/_title/singular')}`,
      },

      {
        key: '[ticketProperty].ticketCategory.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/ticketCategory/_title/singular',
        )}`,
      },
      {
        key: '[ticketProperty].impact.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/impact/_title/singular')}`,
      },
      {
        key: '[ticketProperty].urgency.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/urgency/_title/singular')}`,
      },

      {
        key: '[ticketProperty].operatorReferent.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/operatorReferentId')}`,
      },

      {
        key: '[ticketProperty].ticketCategory.path',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/categoryPath')}`,
      },
      {
        key: '[ticketProperty].channel.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/channel/_title/singular')}`,
      },
      {
        key: '[ticketProperty].ticketStatusReasons.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/ticketStatusReason/reason')}`,
      },
      {
        key: '[ticketProperty].operatorTeamReferent.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamReferentId',
        )}`,
      },

      {
        key: '[ticketProperty].operatorTeamAffected.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorTeamAffectedTo',
        )}`,
      },
      {
        key: '[ticketProperty].location.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/location/_title/singular')}`,
      },
      {
        key: '[mainAssetImpacted].serialNumber',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/mainAssetImpactedSerialNumber',
        )}`,
      },
      {
        key: '[ticketProperty].userAffected.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userAffectedEmail')}`,
      },
      {
        key: '[ticketProperty].operatorAffected.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorAffectedEmail',
        )}`,
      },
      {
        key: '[ticketProperty].organizationalUnit.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/organizationalUnit/_title/singular',
        )}`,
      },
      {
        key: '[ticketProperty].userMakeRequest.email',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userMakeRequestEmail')}`,
      },
      {
        key: '[ticketUserMakeRequestProperty].PrincipalIpPhone',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userMakeRequestVoIP')}`,
      },
      {
        key: '[ticketUserMakeRequestProperty].PrincipalMobile',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/userMakeRequestMobile',
        )}`,
      },

      {
        key: '[ticketUserMakeRequestProperty].PrincipalPhone',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get('entities/incident/userMakeRequestPhone')}`,
      },

      {
        key: '[ticketOperatorConnectedProperty].name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          'entities/incident/operatorConnectedName',
        )}`,
      },
      {
        key: '[ticketOperatorAffectedProperty].principalIpPhone',
        name: `${TranslateService.get(
          'entities/ticket/_title/singular',
        )} - ${TranslateService.get('entities/incident/operatorAffectedVoIP')}`,
      },
      {
        key: '[ticketOperatorAffectedProperty].principalMobile',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          `entities/incident/operatorAffectedMobile`,
        )}`,
      },
      {
        key: '[ticketOperatorAffectedProperty].principalPhone',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          `entities/incident/operatorAffectedPhone`,
        )}`,
      },
      {
        key: '[ticketOperatorAffectedProperty].supplier.name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/supplier/name`)}`,
      },

      {
        key: '[ticketUserAffectedProperty].principalIpPhone',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/userAffectedVoIP`)}`,
      },
      {
        key: '[ticketUserAffectedProperty].principalMobile',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/userAffectedMobile`)}`,
      },
      {
        key: '[ticketUserAffectedProperty].principalPhone',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/userAffectedPhone`)}`,
      },
      {
        key: '[mainAssetImpacted].name',
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          `entities/incident/mainAssetImpactedName`,
        )}`,
      },
      {
        key: '[ticketProperty].externalObjectId',
        name: TranslateService.get(`entities/request/externalObjectId`),
      },
    ];

    let ticketProperty: Array<string> = [
      'created',
      'ticketNumber',
      'tags',
      'title',
      'levelSupport',
      'callbackNumber',
      'customField1',
      'customField2',
      'customField3',
      'customField4',
      'customField5',
      'replyTag',
      'planned',
      'estimate',
      'respondBefore',
      'resolveBefore',
      'userMakeRequest.name',
      'userAffected.name',
      'operatorAffected.name',
      'priority.name',
      'resolutionCategory.name',
    ];
    mentions = this.createMention(mentions, ticketProperty);

    return mentions;
  }
  /** Liste des champs de mention pour le corps */
  findMentionBody(): any {
    let ticketProperty: Array<string> = [
      'description',
      'resolutionComment',
      'privateResolutionComment',
    ];

    let ticketTaskProperty = [
      'urlUserMakeRequest',
      'urlIncidentHelpMe',
      'urlRawIncidentHelpMe',
      'urlUserAffected',
      'urlMainImpactedAsset',
      'urlIncidentOperator',
      'urlRawIncidentOperator',

      'lastCommentStatusChange',
      'lastDateStatusChange',
      'lastNote',

      'lastNoteOperator',
      'lastDateNote',
      'hasAttachments',
      'managerUserAffected',
      'managerUserMakeRequest',
      'mainAssetImpacted',

      'lastNoteAffectation',
      'allUsersImpacted',
      'allAssetsImpacted',
    ];

    let mentionsBody = this.findMentionSubject();
    mentionsBody = [
      {
        key: 'description',
        name: TranslateService.get(`entities/task/description`),
      },
      {
        key: 'urlTicketTask',
        name: TranslateService.get(`entities/task/urlTicketTask`),
      },
      ...mentionsBody,
    ];

    mentionsBody = this.createUrlMention(mentionsBody, ticketTaskProperty);
    mentionsBody = this.createMention(mentionsBody, ticketProperty);
    return mentionsBody;
  }

  private createMention(
    mentionList: Array<{ key: string; name: string }>,
    ticketPropertyToAdd: Array<string>,
  ): any {
    ticketPropertyToAdd.forEach((element) => {
      let name = `${TranslateService.get(
        `entities/ticket/_title/singular`,
      )} - ${TranslateService.get(`entities/incident/${element}`)}`;

      if (element.includes('name')) {
        name = `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(
          `entities/incident/${element.split('.name')[0]}`,
        )}`;
      }

      let object = {
        key: `[ticketProperty].${element}`,
        name: name,
      };

      mentionList.push(object);
    });

    mentionList.sort(function (a, b) {
      if (a.name < b.name) {
        return -1;
      }
      if (a.name > b.name) {
        return 1;
      }
      return 0;
    });
    return mentionList;
  }

  private createUrlMention(
    mentionList: Array<{ key: string; name: string }>,
    ticketPropertyToAdd: Array<string>,
  ): Array<{}> {
    ticketPropertyToAdd.forEach((element) => {
      let object = {
        key: element,
        name: `${TranslateService.get(
          `entities/ticket/_title/singular`,
        )} - ${TranslateService.get(`entities/incident/${element}`)}`,
      };

      mentionList.push(object);
    });

    return mentionList;
  }

  @InjectArgs
  public customTaskDisplayExpr(@Args('item') item) {
    if (item?.__typename === 'TicketTask') {
      let title = '';
      let status = '';
      if (item?.title != undefined) {
        title =
          item?.title[this.translatedFieldHelperService.getTranslateKey()];
      }
      if (item?.status.data.name != undefined) {
        status =
          item?.status.data.name[
            this.translatedFieldHelperService.getTranslateKey()
          ];
      }

      return of('N°' + item?.taskNumber + ' | ' + title + ' | ' + status);
    }

    return;
  }
}
