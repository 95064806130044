import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCampaignsBaseVariables, FirstCampaignsBaseVariables, CountCampaignsBaseVariables, FindCampaignsBaseVariables, SearchCampaignsBaseVariables, ExportSchemaCampaignsBaseVariables, ExportDataCampaignsBaseVariables, CustomQueryCampaignsBaseVariables, CustomQueryIdCampaignsBaseVariables, UsedCampaignsBaseVariables, FindNotAssetsCampaignsBaseVariables, FindHistoriesCampaignsBaseVariables, ReadOnlyCampaignsBaseVariables, ExistCampaignsBaseVariables, FindUnassociatedPackagesCampaignsBaseVariables, FindUnassociatedAssetsCampaignsBaseVariables, InsertCampaignsBaseVariables, UpdateCampaignsBaseVariables, ImportDataCampaignsBaseVariables, UpdateManyCampaignsBaseVariables, DeleteCampaignsBaseVariables, DeployCampaignsBaseVariables, CancelDeployCampaignsBaseVariables, DuplicateCampaignsBaseVariables, HiddenCampaignsBaseVariables, AddPackagesCampaignsBaseVariables, RemovePackagesCampaignsBaseVariables, AddAssetsCampaignsBaseVariables, RemoveAssetsCampaignsBaseVariables } from '../gqls'
import { GetCampaignsDocument, FirstCampaignsDocument, CountCampaignsDocument, FindCampaignsDocument, SearchCampaignsDocument, ExportSchemaCampaignsDocument, ExportDataCampaignsDocument, CustomQueryCampaignsDocument, CustomQueryIdCampaignsDocument, UsedCampaignsDocument, FindNotAssetsCampaignsDocument, FindHistoriesCampaignsDocument, ReadOnlyCampaignsDocument, ExistCampaignsDocument, FindUnassociatedPackagesCampaignsDocument, FindUnassociatedAssetsCampaignsDocument, InsertCampaignsDocument, UpdateCampaignsDocument, ImportDataCampaignsDocument, UpdateManyCampaignsDocument, DeleteCampaignsDocument, DeployCampaignsDocument, CancelDeployCampaignsDocument, DuplicateCampaignsDocument, HiddenCampaignsDocument, AddPackagesCampaignsDocument, RemovePackagesCampaignsDocument, AddAssetsCampaignsDocument, RemoveAssetsCampaignsDocument } from '../gqls'
import { ServiceSingleResultOfCampaign, QueryContextOfCampaign, FilterOfCampaign, ServiceSingleResultOfInt64, ServiceListResultOfCampaign, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfCampaignHistory, QueryContextOfCampaignHistory, FilterOfCampaignHistory, ServiceSingleResultOfEntityAttribute, QueryContextOfPackage, FilterOfPackage, ServiceListResultOfPackage, CampaignInput, FieldUpdateOperatorOfCampaign } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CampaignBaseService {
    
public modelName = 'campaign';
public modelPluralName = 'campaigns';

	private getCampaignsQuery: GetCampaignsDocument;
	private firstCampaignsQuery: FirstCampaignsDocument;
	private countCampaignsQuery: CountCampaignsDocument;
	private findCampaignsQuery: FindCampaignsDocument;
	private searchCampaignsQuery: SearchCampaignsDocument;
	private exportSchemaCampaignsQuery: ExportSchemaCampaignsDocument;
	private exportDataCampaignsQuery: ExportDataCampaignsDocument;
	private customQueryCampaignsQuery: CustomQueryCampaignsDocument;
	private customQueryIdCampaignsQuery: CustomQueryIdCampaignsDocument;
	private usedCampaignsQuery: UsedCampaignsDocument;
	private findNotAssetsCampaignsQuery: FindNotAssetsCampaignsDocument;
	private findHistoriesCampaignsQuery: FindHistoriesCampaignsDocument;
	private readOnlyCampaignsQuery: ReadOnlyCampaignsDocument;
	private existCampaignsQuery: ExistCampaignsDocument;
	private findUnassociatedPackagesCampaignsQuery: FindUnassociatedPackagesCampaignsDocument;
	private findUnassociatedAssetsCampaignsQuery: FindUnassociatedAssetsCampaignsDocument;
	private insertCampaignsMutation: InsertCampaignsDocument;
	private updateCampaignsMutation: UpdateCampaignsDocument;
	private importDataCampaignsMutation: ImportDataCampaignsDocument;
	private updateManyCampaignsMutation: UpdateManyCampaignsDocument;
	private deleteCampaignsMutation: DeleteCampaignsDocument;
	private deployCampaignsMutation: DeployCampaignsDocument;
	private cancelDeployCampaignsMutation: CancelDeployCampaignsDocument;
	private duplicateCampaignsMutation: DuplicateCampaignsDocument;
	private hiddenCampaignsMutation: HiddenCampaignsDocument;
	private addPackagesCampaignsMutation: AddPackagesCampaignsDocument;
	private removePackagesCampaignsMutation: RemovePackagesCampaignsDocument;
	private addAssetsCampaignsMutation: AddAssetsCampaignsDocument;
	private removeAssetsCampaignsMutation: RemoveAssetsCampaignsDocument;

	constructor(private injector: Injector) {
		this.getCampaignsQuery = this.injector.get(GetCampaignsDocument);
		this.firstCampaignsQuery = this.injector.get(FirstCampaignsDocument);
		this.countCampaignsQuery = this.injector.get(CountCampaignsDocument);
		this.findCampaignsQuery = this.injector.get(FindCampaignsDocument);
		this.searchCampaignsQuery = this.injector.get(SearchCampaignsDocument);
		this.exportSchemaCampaignsQuery = this.injector.get(ExportSchemaCampaignsDocument);
		this.exportDataCampaignsQuery = this.injector.get(ExportDataCampaignsDocument);
		this.customQueryCampaignsQuery = this.injector.get(CustomQueryCampaignsDocument);
		this.customQueryIdCampaignsQuery = this.injector.get(CustomQueryIdCampaignsDocument);
		this.usedCampaignsQuery = this.injector.get(UsedCampaignsDocument);
		this.findNotAssetsCampaignsQuery = this.injector.get(FindNotAssetsCampaignsDocument);
		this.findHistoriesCampaignsQuery = this.injector.get(FindHistoriesCampaignsDocument);
		this.readOnlyCampaignsQuery = this.injector.get(ReadOnlyCampaignsDocument);
		this.existCampaignsQuery = this.injector.get(ExistCampaignsDocument);
		this.findUnassociatedPackagesCampaignsQuery = this.injector.get(FindUnassociatedPackagesCampaignsDocument);
		this.findUnassociatedAssetsCampaignsQuery = this.injector.get(FindUnassociatedAssetsCampaignsDocument);
		this.insertCampaignsMutation = this.injector.get(InsertCampaignsDocument);
		this.updateCampaignsMutation = this.injector.get(UpdateCampaignsDocument);
		this.importDataCampaignsMutation = this.injector.get(ImportDataCampaignsDocument);
		this.updateManyCampaignsMutation = this.injector.get(UpdateManyCampaignsDocument);
		this.deleteCampaignsMutation = this.injector.get(DeleteCampaignsDocument);
		this.deployCampaignsMutation = this.injector.get(DeployCampaignsDocument);
		this.cancelDeployCampaignsMutation = this.injector.get(CancelDeployCampaignsDocument);
		this.duplicateCampaignsMutation = this.injector.get(DuplicateCampaignsDocument);
		this.hiddenCampaignsMutation = this.injector.get(HiddenCampaignsDocument);
		this.addPackagesCampaignsMutation = this.injector.get(AddPackagesCampaignsDocument);
		this.removePackagesCampaignsMutation = this.injector.get(RemovePackagesCampaignsDocument);
		this.addAssetsCampaignsMutation = this.injector.get(AddAssetsCampaignsDocument);
		this.removeAssetsCampaignsMutation = this.injector.get(RemoveAssetsCampaignsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaign> {
    
    		let variables: GetCampaignsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.get));
            }
            else{
                let result:ServiceSingleResultOfCampaign={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaign,
		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaign> {
    
    		let variables: FirstCampaignsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCampaignsBaseVariables = {
    			filter: filter
    		}
    				return this.countCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaign,
		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaign> {
    
    		let variables: FindCampaignsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCampaign,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaign> {
    
    		let variables: SearchCampaignsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCampaignsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCampaignsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCampaign,
		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaign> {
    
    		let variables: CustomQueryCampaignsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCampaign,
		@Args('filter?') filter?: FilterOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaign> {
    
    		let variables: CustomQueryIdCampaignsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindNotAssetsCampaignsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotAssetsCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.findNotAssets));
    	}

    	/**  */
    	@InjectArgs
    	public findHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCampaignHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfCampaignHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCampaignHistory> {
    
    		let variables: FindHistoriesCampaignsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHistoriesCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.findHistories));
    	}

    	/**  */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCampaignsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.readOnly));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCampaignsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.exist));
    	}

	@InjectArgs
	public findAssociatedPackages(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfPackage,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPackage,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfPackage> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('packages', fields, null, [
			GqlSubFieldArg.create('filterOfPackages', 'filter'),
			GqlSubFieldArg.create('optionsOfPackages', 'options'),
		]),
		])
		extendedVariables['filterOfPackages'] = filter;
		extendedVariables['optionsOfPackages'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.packages));
            }
            else{
                let result: ServiceListResultOfPackage = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedPackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPackage,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPackage> {
    
    		let variables: FindUnassociatedPackagesCampaignsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedPackagesCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.findUnassociatedPackages));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsCampaignsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsCampaignsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CampaignInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaign> {
    
    		let variables: InsertCampaignsBaseVariables = {
    			entity: entity
    		}
    				return this.insertCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCampaign> {
    
    		let variables: UpdateCampaignsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCampaignsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCampaign,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCampaignsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.delete));
    	}

    	/**  */
    	@InjectArgs
    	public deploy(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeployCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.deployCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.deploy));
    	}

    	/**  */
    	@InjectArgs
    	public cancelDeploy(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CancelDeployCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.cancelDeployCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.cancelDeploy));
    	}

    	/**  */
    	@InjectArgs
    	public duplicate(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.duplicateCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.duplicate));
    	}

    	/**  */
    	@InjectArgs
    	public hidden(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HiddenCampaignsBaseVariables = {
    			ids: ids
    		}
    				return this.hiddenCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.hidden));
    	}

    	/**  */
    	@InjectArgs
    	public addPackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('packageIds') packageIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPackagesCampaignsBaseVariables = {
    			id: id,
			packageIds: packageIds
    		}
    				return this.addPackagesCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.addPackages));
    	}

    	/**  */
    	@InjectArgs
    	public removePackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('packageIds') packageIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePackagesCampaignsBaseVariables = {
    			id: id,
			packageIds: packageIds
    		}
    				return this.removePackagesCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.removePackages));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsCampaignsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsCampaignsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeAssetsCampaignsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.campaigns.removeAssets));
    	}
    
}