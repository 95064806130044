import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInventoryHistoriesBaseVariables, FirstInventoryHistoriesBaseVariables, CountInventoryHistoriesBaseVariables, FindInventoryHistoriesBaseVariables, SearchInventoryHistoriesBaseVariables, ExportSchemaInventoryHistoriesBaseVariables, ExportDataInventoryHistoriesBaseVariables, CustomQueryInventoryHistoriesBaseVariables, CustomQueryIdInventoryHistoriesBaseVariables, UsedInventoryHistoriesBaseVariables, FindInventoryHistoriesAllByScanConfigurationInventoryHistoriesBaseVariables, FindInventoryHistoriesAllFinishedByLastScanInventoryHistoriesBaseVariables, FindInventoryHistoriesExcludeInventoryHistoriesBaseVariables, FindInventoryHistoriesInErrorInventoryHistoriesBaseVariables, FindInventoryHistoriesInProgressInventoryHistoriesBaseVariables, FindInventoryHistoriesInQueueInventoryHistoriesBaseVariables, FindInventoryHistoriesSuccessInventoryHistoriesBaseVariables, FindInventoryHistoriesWarningInventoryHistoriesBaseVariables, ExistInventoryHistoriesBaseVariables, InsertInventoryHistoriesBaseVariables, UpdateInventoryHistoriesBaseVariables, ImportDataInventoryHistoriesBaseVariables, UpdateManyInventoryHistoriesBaseVariables, DeleteInventoryHistoriesBaseVariables, HiddenInventoryHistoriesBaseVariables } from '../gqls'
import { GetInventoryHistoriesDocument, FirstInventoryHistoriesDocument, CountInventoryHistoriesDocument, FindInventoryHistoriesDocument, SearchInventoryHistoriesDocument, ExportSchemaInventoryHistoriesDocument, ExportDataInventoryHistoriesDocument, CustomQueryInventoryHistoriesDocument, CustomQueryIdInventoryHistoriesDocument, UsedInventoryHistoriesDocument, FindInventoryHistoriesAllByScanConfigurationInventoryHistoriesDocument, FindInventoryHistoriesAllFinishedByLastScanInventoryHistoriesDocument, FindInventoryHistoriesExcludeInventoryHistoriesDocument, FindInventoryHistoriesInErrorInventoryHistoriesDocument, FindInventoryHistoriesInProgressInventoryHistoriesDocument, FindInventoryHistoriesInQueueInventoryHistoriesDocument, FindInventoryHistoriesSuccessInventoryHistoriesDocument, FindInventoryHistoriesWarningInventoryHistoriesDocument, ExistInventoryHistoriesDocument, InsertInventoryHistoriesDocument, UpdateInventoryHistoriesDocument, ImportDataInventoryHistoriesDocument, UpdateManyInventoryHistoriesDocument, DeleteInventoryHistoriesDocument, HiddenInventoryHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfInventoryHistory, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceSingleResultOfInt64, ServiceListResultOfInventoryHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, InventoryHistoryInput, FieldUpdateOperatorOfInventoryHistory } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InventoryHistoryBaseService {
    
public modelName = 'inventoryHistory';
public modelPluralName = 'inventoryHistories';

	private getInventoryHistoriesQuery: GetInventoryHistoriesDocument;
	private firstInventoryHistoriesQuery: FirstInventoryHistoriesDocument;
	private countInventoryHistoriesQuery: CountInventoryHistoriesDocument;
	private findInventoryHistoriesQuery: FindInventoryHistoriesDocument;
	private searchInventoryHistoriesQuery: SearchInventoryHistoriesDocument;
	private exportSchemaInventoryHistoriesQuery: ExportSchemaInventoryHistoriesDocument;
	private exportDataInventoryHistoriesQuery: ExportDataInventoryHistoriesDocument;
	private customQueryInventoryHistoriesQuery: CustomQueryInventoryHistoriesDocument;
	private customQueryIdInventoryHistoriesQuery: CustomQueryIdInventoryHistoriesDocument;
	private usedInventoryHistoriesQuery: UsedInventoryHistoriesDocument;
	private findInventoryHistoriesAllByScanConfigurationInventoryHistoriesQuery: FindInventoryHistoriesAllByScanConfigurationInventoryHistoriesDocument;
	private findInventoryHistoriesAllFinishedByLastScanInventoryHistoriesQuery: FindInventoryHistoriesAllFinishedByLastScanInventoryHistoriesDocument;
	private findInventoryHistoriesExcludeInventoryHistoriesQuery: FindInventoryHistoriesExcludeInventoryHistoriesDocument;
	private findInventoryHistoriesInErrorInventoryHistoriesQuery: FindInventoryHistoriesInErrorInventoryHistoriesDocument;
	private findInventoryHistoriesInProgressInventoryHistoriesQuery: FindInventoryHistoriesInProgressInventoryHistoriesDocument;
	private findInventoryHistoriesInQueueInventoryHistoriesQuery: FindInventoryHistoriesInQueueInventoryHistoriesDocument;
	private findInventoryHistoriesSuccessInventoryHistoriesQuery: FindInventoryHistoriesSuccessInventoryHistoriesDocument;
	private findInventoryHistoriesWarningInventoryHistoriesQuery: FindInventoryHistoriesWarningInventoryHistoriesDocument;
	private existInventoryHistoriesQuery: ExistInventoryHistoriesDocument;
	private insertInventoryHistoriesMutation: InsertInventoryHistoriesDocument;
	private updateInventoryHistoriesMutation: UpdateInventoryHistoriesDocument;
	private importDataInventoryHistoriesMutation: ImportDataInventoryHistoriesDocument;
	private updateManyInventoryHistoriesMutation: UpdateManyInventoryHistoriesDocument;
	private deleteInventoryHistoriesMutation: DeleteInventoryHistoriesDocument;
	private hiddenInventoryHistoriesMutation: HiddenInventoryHistoriesDocument;

	constructor(private injector: Injector) {
		this.getInventoryHistoriesQuery = this.injector.get(GetInventoryHistoriesDocument);
		this.firstInventoryHistoriesQuery = this.injector.get(FirstInventoryHistoriesDocument);
		this.countInventoryHistoriesQuery = this.injector.get(CountInventoryHistoriesDocument);
		this.findInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesDocument);
		this.searchInventoryHistoriesQuery = this.injector.get(SearchInventoryHistoriesDocument);
		this.exportSchemaInventoryHistoriesQuery = this.injector.get(ExportSchemaInventoryHistoriesDocument);
		this.exportDataInventoryHistoriesQuery = this.injector.get(ExportDataInventoryHistoriesDocument);
		this.customQueryInventoryHistoriesQuery = this.injector.get(CustomQueryInventoryHistoriesDocument);
		this.customQueryIdInventoryHistoriesQuery = this.injector.get(CustomQueryIdInventoryHistoriesDocument);
		this.usedInventoryHistoriesQuery = this.injector.get(UsedInventoryHistoriesDocument);
		this.findInventoryHistoriesAllByScanConfigurationInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesAllByScanConfigurationInventoryHistoriesDocument);
		this.findInventoryHistoriesAllFinishedByLastScanInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesAllFinishedByLastScanInventoryHistoriesDocument);
		this.findInventoryHistoriesExcludeInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesExcludeInventoryHistoriesDocument);
		this.findInventoryHistoriesInErrorInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesInErrorInventoryHistoriesDocument);
		this.findInventoryHistoriesInProgressInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesInProgressInventoryHistoriesDocument);
		this.findInventoryHistoriesInQueueInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesInQueueInventoryHistoriesDocument);
		this.findInventoryHistoriesSuccessInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesSuccessInventoryHistoriesDocument);
		this.findInventoryHistoriesWarningInventoryHistoriesQuery = this.injector.get(FindInventoryHistoriesWarningInventoryHistoriesDocument);
		this.existInventoryHistoriesQuery = this.injector.get(ExistInventoryHistoriesDocument);
		this.insertInventoryHistoriesMutation = this.injector.get(InsertInventoryHistoriesDocument);
		this.updateInventoryHistoriesMutation = this.injector.get(UpdateInventoryHistoriesDocument);
		this.importDataInventoryHistoriesMutation = this.injector.get(ImportDataInventoryHistoriesDocument);
		this.updateManyInventoryHistoriesMutation = this.injector.get(UpdateManyInventoryHistoriesDocument);
		this.deleteInventoryHistoriesMutation = this.injector.get(DeleteInventoryHistoriesDocument);
		this.hiddenInventoryHistoriesMutation = this.injector.get(HiddenInventoryHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInventoryHistory> {
    
    		let variables: GetInventoryHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.get));
            }
            else{
                let result:ServiceSingleResultOfInventoryHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInventoryHistory> {
    
    		let variables: FirstInventoryHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInventoryHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: SearchInventoryHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInventoryHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInventoryHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: CustomQueryInventoryHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: CustomQueryIdInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInventoryHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.used));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesAllByScanConfiguration(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesAllByScanConfigurationInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesAllByScanConfigurationInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesAllByScanConfiguration));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesAllFinishedByLastScan(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesAllFinishedByLastScanInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesAllFinishedByLastScanInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesAllFinishedByLastScan));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesExclude(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesExcludeInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesExcludeInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesExclude));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesInError(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesInErrorInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesInErrorInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesInError));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesInProgress(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesInProgressInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesInProgressInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesInProgress));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesInQueue(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesInQueueInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesInQueueInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesInQueue));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesSuccess(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesSuccessInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesSuccessInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesSuccess));
    	}

    	/**  */
    	@InjectArgs
    	public findInventoryHistoriesWarning(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindInventoryHistoriesWarningInventoryHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInventoryHistoriesWarningInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.findInventoryHistoriesWarning));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInventoryHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInventoryHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: InventoryHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInventoryHistory> {
    
    		let variables: InsertInventoryHistoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInventoryHistory> {
    
    		let variables: UpdateInventoryHistoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataInventoryHistoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyInventoryHistoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteInventoryHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.delete));
    	}

    	/**  */
    	@InjectArgs
    	public hidden(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('value') value: boolean,
		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HiddenInventoryHistoriesBaseVariables = {
    			ids: ids,
			value: value
    		}
    				return this.hiddenInventoryHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inventoryHistories.hidden));
    	}
    
}