import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDataFilesBaseVariables, FirstDataFilesBaseVariables, CountDataFilesBaseVariables, FindDataFilesBaseVariables, SearchDataFilesBaseVariables, ExportSchemaDataFilesBaseVariables, ExportDataDataFilesBaseVariables, CustomQueryDataFilesBaseVariables, CustomQueryIdDataFilesBaseVariables, UsedDataFilesBaseVariables, ExistDataFilesBaseVariables } from '../gqls'
import { GetDataFilesDocument, FirstDataFilesDocument, CountDataFilesDocument, FindDataFilesDocument, SearchDataFilesDocument, ExportSchemaDataFilesDocument, ExportDataDataFilesDocument, CustomQueryDataFilesDocument, CustomQueryIdDataFilesDocument, UsedDataFilesDocument, ExistDataFilesDocument } from '../gqls'
import { ServiceSingleResultOfDataFile, QueryContextOfDataFile, FilterOfDataFile, ServiceSingleResultOfInt64, ServiceListResultOfDataFile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DataFileBaseService {
    
public modelName = 'dataFile';
public modelPluralName = 'dataFiles';

	private getDataFilesQuery: GetDataFilesDocument;
	private firstDataFilesQuery: FirstDataFilesDocument;
	private countDataFilesQuery: CountDataFilesDocument;
	private findDataFilesQuery: FindDataFilesDocument;
	private searchDataFilesQuery: SearchDataFilesDocument;
	private exportSchemaDataFilesQuery: ExportSchemaDataFilesDocument;
	private exportDataDataFilesQuery: ExportDataDataFilesDocument;
	private customQueryDataFilesQuery: CustomQueryDataFilesDocument;
	private customQueryIdDataFilesQuery: CustomQueryIdDataFilesDocument;
	private usedDataFilesQuery: UsedDataFilesDocument;
	private existDataFilesQuery: ExistDataFilesDocument;

	constructor(private injector: Injector) {
		this.getDataFilesQuery = this.injector.get(GetDataFilesDocument);
		this.firstDataFilesQuery = this.injector.get(FirstDataFilesDocument);
		this.countDataFilesQuery = this.injector.get(CountDataFilesDocument);
		this.findDataFilesQuery = this.injector.get(FindDataFilesDocument);
		this.searchDataFilesQuery = this.injector.get(SearchDataFilesDocument);
		this.exportSchemaDataFilesQuery = this.injector.get(ExportSchemaDataFilesDocument);
		this.exportDataDataFilesQuery = this.injector.get(ExportDataDataFilesDocument);
		this.customQueryDataFilesQuery = this.injector.get(CustomQueryDataFilesDocument);
		this.customQueryIdDataFilesQuery = this.injector.get(CustomQueryIdDataFilesDocument);
		this.usedDataFilesQuery = this.injector.get(UsedDataFilesDocument);
		this.existDataFilesQuery = this.injector.get(ExistDataFilesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDataFile> {
    
    		let variables: GetDataFilesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.get));
            }
            else{
                let result:ServiceSingleResultOfDataFile={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDataFile,
		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDataFile> {
    
    		let variables: FirstDataFilesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDataFilesBaseVariables = {
    			filter: filter
    		}
    				return this.countDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDataFile,
		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDataFile> {
    
    		let variables: FindDataFilesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDataFile,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDataFile> {
    
    		let variables: SearchDataFilesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDataFilesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDataFilesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDataFile,
		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDataFile> {
    
    		let variables: CustomQueryDataFilesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDataFile,
		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDataFile> {
    
    		let variables: CustomQueryIdDataFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDataFilesBaseVariables = {
    			ids: ids
    		}
    				return this.usedDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDataFilesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dataFiles.exist));
    	}
    
    
}