<div class="widget-container flex-box">
  <a *ngIf="this.urlImage" [attr.href]="this.urlImage" target="_blank"
    ><div class="cl-expand-file-uploader">
      <i class="fa-light fa-expand"></i>
    </div>
  </a>
  <div
    id="dropzone-external-{{ this.dropzoneId }}"
    class="dropzone-external flex-box"
    [ngClass]="
      isDropZoneActive
        ? ['dx-theme-accent-as-border-color', 'dropzone-active']
        : ['dx-theme-border-color']
    "
  >
    <img
      id="dropzone-image"
      [src]="imageSource"
      *ngIf="imageSource && !this.urlImage"
      alt=""
    />
    <img
      id="dropzone-image"
      [src]="this.urlImage"
      *ngIf="this.urlImage != undefined && imageSource != this.urlImage"
      alt=""
    />
    <div
      id="dropzone-text"
      class="flex-box"
      *ngIf="textVisible && !imageSource && !this.urlImage"
    >
      <span>{{ 'entities/fileUploader/drag' | translate }}</span>
      <span>{{ 'entities/fileUploader/browse' | translate }}</span>
    </div>
    <dx-progress-bar
      #uploadProgress
      [min]="0"
      [max]="100"
      width="30%"
      [showStatus]="false"
      [visible]="progressVisible"
      [value]="progressValue"
    ></dx-progress-bar>
  </div>
  <dx-file-uploader
    #fileUploader
    [chunkSize]="this.maxChunkSize"
    dialogTrigger="#dropzone-external-{{ this.dropzoneId }}"
    [uploadChunk]="uploadFileChunk"
    [allowedFileExtensions]="allowedFileExtensions"
    dropZone="#dropzone-external-{{ this.dropzoneId }}"
    [multiple]="false"
    uploadMode="instantly"
    allowCanceling="true"
    [visible]="true"
    (onDropZoneEnter)="onDropZoneEnter($event)"
    (onDropZoneLeave)="onDropZoneLeave($event)"
    (onUploaded)="onUploaded($event)"
    (onProgress)="onProgress($event)"
    (onUploadStarted)="onUploadStarted($event)"
    [maxFileSize]="4000000"
    [readOnly]="readOnly"
  ></dx-file-uploader>
  <br />
  <p class="infoText">
    {{ 'entities/fileUploader/format' | translate }}
  </p>
  <br />
  <dx-button
    stylingMode="contained"
    text="{{ 'entities/fileUploader/delete' | translate }}"
    type="danger"
    [disabled]="this.urlImage ? false : true"
    [width]="120"
    (onClick)="click($event)"
    [visible]="!readOnly"
  >
  </dx-button>
</div>
