import { Component, OnInit } from '@angular/core';

/** Représente l'en-tête du composant WorkPage. */
@Component({
  selector: 'cl-work-page-header',
  templateUrl:  './work-page-header.component.html',
  styleUrls: ['./work-page-header.component.scss']
})
export class WorkPageHeaderComponent {
  constructor() { }
}
