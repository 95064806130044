import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowDeletesBaseVariables, FirstWorkflowDeletesBaseVariables, CountWorkflowDeletesBaseVariables, FindWorkflowDeletesBaseVariables, SearchWorkflowDeletesBaseVariables, ExportSchemaWorkflowDeletesBaseVariables, ExportDataWorkflowDeletesBaseVariables, CustomQueryWorkflowDeletesBaseVariables, CustomQueryIdWorkflowDeletesBaseVariables, UsedWorkflowDeletesBaseVariables, ExistWorkflowDeletesBaseVariables, InsertWorkflowDeletesBaseVariables, UpdateWorkflowDeletesBaseVariables, ImportDataWorkflowDeletesBaseVariables, UpdateManyWorkflowDeletesBaseVariables, DeleteWorkflowDeletesBaseVariables } from '../gqls'
import { GetWorkflowDeletesDocument, FirstWorkflowDeletesDocument, CountWorkflowDeletesDocument, FindWorkflowDeletesDocument, SearchWorkflowDeletesDocument, ExportSchemaWorkflowDeletesDocument, ExportDataWorkflowDeletesDocument, CustomQueryWorkflowDeletesDocument, CustomQueryIdWorkflowDeletesDocument, UsedWorkflowDeletesDocument, ExistWorkflowDeletesDocument, InsertWorkflowDeletesDocument, UpdateWorkflowDeletesDocument, ImportDataWorkflowDeletesDocument, UpdateManyWorkflowDeletesDocument, DeleteWorkflowDeletesDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowDelete, QueryContextOfWorkflowDelete, FilterOfWorkflowDelete, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowDelete, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowDeleteInput, FieldUpdateOperatorOfWorkflowDelete } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowDeleteBaseService {
    
public modelName = 'workflowDelete';
public modelPluralName = 'workflowDeletes';

	private getWorkflowDeletesQuery: GetWorkflowDeletesDocument;
	private firstWorkflowDeletesQuery: FirstWorkflowDeletesDocument;
	private countWorkflowDeletesQuery: CountWorkflowDeletesDocument;
	private findWorkflowDeletesQuery: FindWorkflowDeletesDocument;
	private searchWorkflowDeletesQuery: SearchWorkflowDeletesDocument;
	private exportSchemaWorkflowDeletesQuery: ExportSchemaWorkflowDeletesDocument;
	private exportDataWorkflowDeletesQuery: ExportDataWorkflowDeletesDocument;
	private customQueryWorkflowDeletesQuery: CustomQueryWorkflowDeletesDocument;
	private customQueryIdWorkflowDeletesQuery: CustomQueryIdWorkflowDeletesDocument;
	private usedWorkflowDeletesQuery: UsedWorkflowDeletesDocument;
	private existWorkflowDeletesQuery: ExistWorkflowDeletesDocument;
	private insertWorkflowDeletesMutation: InsertWorkflowDeletesDocument;
	private updateWorkflowDeletesMutation: UpdateWorkflowDeletesDocument;
	private importDataWorkflowDeletesMutation: ImportDataWorkflowDeletesDocument;
	private updateManyWorkflowDeletesMutation: UpdateManyWorkflowDeletesDocument;
	private deleteWorkflowDeletesMutation: DeleteWorkflowDeletesDocument;

	constructor(private injector: Injector) {
		this.getWorkflowDeletesQuery = this.injector.get(GetWorkflowDeletesDocument);
		this.firstWorkflowDeletesQuery = this.injector.get(FirstWorkflowDeletesDocument);
		this.countWorkflowDeletesQuery = this.injector.get(CountWorkflowDeletesDocument);
		this.findWorkflowDeletesQuery = this.injector.get(FindWorkflowDeletesDocument);
		this.searchWorkflowDeletesQuery = this.injector.get(SearchWorkflowDeletesDocument);
		this.exportSchemaWorkflowDeletesQuery = this.injector.get(ExportSchemaWorkflowDeletesDocument);
		this.exportDataWorkflowDeletesQuery = this.injector.get(ExportDataWorkflowDeletesDocument);
		this.customQueryWorkflowDeletesQuery = this.injector.get(CustomQueryWorkflowDeletesDocument);
		this.customQueryIdWorkflowDeletesQuery = this.injector.get(CustomQueryIdWorkflowDeletesDocument);
		this.usedWorkflowDeletesQuery = this.injector.get(UsedWorkflowDeletesDocument);
		this.existWorkflowDeletesQuery = this.injector.get(ExistWorkflowDeletesDocument);
		this.insertWorkflowDeletesMutation = this.injector.get(InsertWorkflowDeletesDocument);
		this.updateWorkflowDeletesMutation = this.injector.get(UpdateWorkflowDeletesDocument);
		this.importDataWorkflowDeletesMutation = this.injector.get(ImportDataWorkflowDeletesDocument);
		this.updateManyWorkflowDeletesMutation = this.injector.get(UpdateManyWorkflowDeletesDocument);
		this.deleteWorkflowDeletesMutation = this.injector.get(DeleteWorkflowDeletesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowDelete> {
    
    		let variables: GetWorkflowDeletesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowDelete={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowDelete,
		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowDelete> {
    
    		let variables: FirstWorkflowDeletesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowDeletesBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowDelete,
		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowDelete> {
    
    		let variables: FindWorkflowDeletesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowDelete,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowDelete> {
    
    		let variables: SearchWorkflowDeletesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowDeletesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowDeletesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowDelete,
		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowDelete> {
    
    		let variables: CustomQueryWorkflowDeletesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowDelete,
		@Args('filter?') filter?: FilterOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowDelete> {
    
    		let variables: CustomQueryIdWorkflowDeletesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowDeletesBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowDeletesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowDeletesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowDeleteInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowDelete> {
    
    		let variables: InsertWorkflowDeletesBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowDeletesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowDelete> {
    
    		let variables: UpdateWorkflowDeletesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowDeletesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowDeletesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowDeletesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowDelete,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowDeletesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowDeletesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowDeletesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowDeletesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowDeletes.delete));
    	}
    
}