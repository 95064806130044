import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowPropertiesBaseVariables, FirstWorkflowPropertiesBaseVariables, CountWorkflowPropertiesBaseVariables, FindWorkflowPropertiesBaseVariables, SearchWorkflowPropertiesBaseVariables, ExportSchemaWorkflowPropertiesBaseVariables, ExportDataWorkflowPropertiesBaseVariables, CustomQueryWorkflowPropertiesBaseVariables, CustomQueryIdWorkflowPropertiesBaseVariables, UsedWorkflowPropertiesBaseVariables, ExistWorkflowPropertiesBaseVariables, InsertWorkflowPropertiesBaseVariables, UpdateWorkflowPropertiesBaseVariables, ImportDataWorkflowPropertiesBaseVariables, UpdateManyWorkflowPropertiesBaseVariables, DeleteWorkflowPropertiesBaseVariables } from '../gqls'
import { GetWorkflowPropertiesDocument, FirstWorkflowPropertiesDocument, CountWorkflowPropertiesDocument, FindWorkflowPropertiesDocument, SearchWorkflowPropertiesDocument, ExportSchemaWorkflowPropertiesDocument, ExportDataWorkflowPropertiesDocument, CustomQueryWorkflowPropertiesDocument, CustomQueryIdWorkflowPropertiesDocument, UsedWorkflowPropertiesDocument, ExistWorkflowPropertiesDocument, InsertWorkflowPropertiesDocument, UpdateWorkflowPropertiesDocument, ImportDataWorkflowPropertiesDocument, UpdateManyWorkflowPropertiesDocument, DeleteWorkflowPropertiesDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowProperty, QueryContextOfWorkflowProperty, FilterOfWorkflowProperty, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowProperty, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowPropertyInput, FieldUpdateOperatorOfWorkflowProperty } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowPropertyBaseService {
    
public modelName = 'workflowProperty';
public modelPluralName = 'workflowProperties';

	private getWorkflowPropertiesQuery: GetWorkflowPropertiesDocument;
	private firstWorkflowPropertiesQuery: FirstWorkflowPropertiesDocument;
	private countWorkflowPropertiesQuery: CountWorkflowPropertiesDocument;
	private findWorkflowPropertiesQuery: FindWorkflowPropertiesDocument;
	private searchWorkflowPropertiesQuery: SearchWorkflowPropertiesDocument;
	private exportSchemaWorkflowPropertiesQuery: ExportSchemaWorkflowPropertiesDocument;
	private exportDataWorkflowPropertiesQuery: ExportDataWorkflowPropertiesDocument;
	private customQueryWorkflowPropertiesQuery: CustomQueryWorkflowPropertiesDocument;
	private customQueryIdWorkflowPropertiesQuery: CustomQueryIdWorkflowPropertiesDocument;
	private usedWorkflowPropertiesQuery: UsedWorkflowPropertiesDocument;
	private existWorkflowPropertiesQuery: ExistWorkflowPropertiesDocument;
	private insertWorkflowPropertiesMutation: InsertWorkflowPropertiesDocument;
	private updateWorkflowPropertiesMutation: UpdateWorkflowPropertiesDocument;
	private importDataWorkflowPropertiesMutation: ImportDataWorkflowPropertiesDocument;
	private updateManyWorkflowPropertiesMutation: UpdateManyWorkflowPropertiesDocument;
	private deleteWorkflowPropertiesMutation: DeleteWorkflowPropertiesDocument;

	constructor(private injector: Injector) {
		this.getWorkflowPropertiesQuery = this.injector.get(GetWorkflowPropertiesDocument);
		this.firstWorkflowPropertiesQuery = this.injector.get(FirstWorkflowPropertiesDocument);
		this.countWorkflowPropertiesQuery = this.injector.get(CountWorkflowPropertiesDocument);
		this.findWorkflowPropertiesQuery = this.injector.get(FindWorkflowPropertiesDocument);
		this.searchWorkflowPropertiesQuery = this.injector.get(SearchWorkflowPropertiesDocument);
		this.exportSchemaWorkflowPropertiesQuery = this.injector.get(ExportSchemaWorkflowPropertiesDocument);
		this.exportDataWorkflowPropertiesQuery = this.injector.get(ExportDataWorkflowPropertiesDocument);
		this.customQueryWorkflowPropertiesQuery = this.injector.get(CustomQueryWorkflowPropertiesDocument);
		this.customQueryIdWorkflowPropertiesQuery = this.injector.get(CustomQueryIdWorkflowPropertiesDocument);
		this.usedWorkflowPropertiesQuery = this.injector.get(UsedWorkflowPropertiesDocument);
		this.existWorkflowPropertiesQuery = this.injector.get(ExistWorkflowPropertiesDocument);
		this.insertWorkflowPropertiesMutation = this.injector.get(InsertWorkflowPropertiesDocument);
		this.updateWorkflowPropertiesMutation = this.injector.get(UpdateWorkflowPropertiesDocument);
		this.importDataWorkflowPropertiesMutation = this.injector.get(ImportDataWorkflowPropertiesDocument);
		this.updateManyWorkflowPropertiesMutation = this.injector.get(UpdateManyWorkflowPropertiesDocument);
		this.deleteWorkflowPropertiesMutation = this.injector.get(DeleteWorkflowPropertiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowProperty> {
    
    		let variables: GetWorkflowPropertiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowProperty={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowProperty,
		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowProperty> {
    
    		let variables: FirstWorkflowPropertiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowPropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowProperty,
		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowProperty> {
    
    		let variables: FindWorkflowPropertiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowProperty,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowProperty> {
    
    		let variables: SearchWorkflowPropertiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowPropertiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowPropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowProperty,
		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowProperty> {
    
    		let variables: CustomQueryWorkflowPropertiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowProperty,
		@Args('filter?') filter?: FilterOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowProperty> {
    
    		let variables: CustomQueryIdWorkflowPropertiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowPropertiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowPropertiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowPropertyInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowProperty> {
    
    		let variables: InsertWorkflowPropertiesBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowPropertiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowProperty> {
    
    		let variables: UpdateWorkflowPropertiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowPropertiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowPropertiesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowPropertiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowPropertiesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowPropertiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowPropertiesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowPropertiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowProperties.delete));
    	}
    
}