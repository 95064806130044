import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByLdapsBaseVariables, FirstScanByLdapsBaseVariables, CountScanByLdapsBaseVariables, FindScanByLdapsBaseVariables, SearchScanByLdapsBaseVariables, ExportSchemaScanByLdapsBaseVariables, ExportDataScanByLdapsBaseVariables, CustomQueryScanByLdapsBaseVariables, CustomQueryIdScanByLdapsBaseVariables, UsedScanByLdapsBaseVariables, ExistScanByLdapsBaseVariables, FindRecyclesScanByLdapsBaseVariables, CountRecyclesScanByLdapsBaseVariables, ReadOnlyScanByLdapsBaseVariables, FindArchivedScanByLdapsBaseVariables, CountAllScanByLdapsBaseVariables, FindDynamicPropertyFieldsScanByLdapsBaseVariables, FindUnassociatedNetworkCredentialsScanByLdapsBaseVariables, FindUnassociatedScanExcludesScanByLdapsBaseVariables, FindUnassociatedScanDataFilesScanByLdapsBaseVariables, FindUnassociatedScanRegistryKeysScanByLdapsBaseVariables, FindUnassociatedScanImportOptionsScanByLdapsBaseVariables, FindUnassociatedUsersScanByLdapsBaseVariables, FindUnassociatedAssetsScanByLdapsBaseVariables, FindUnassociatedAlertsScanByLdapsBaseVariables, FindUnassociatedInventoryHistoriesScanByLdapsBaseVariables, FindUnassociatedLdapHistoriesScanByLdapsBaseVariables, InsertScanByLdapsBaseVariables, UpdateScanByLdapsBaseVariables, ImportDataScanByLdapsBaseVariables, UpdateManyScanByLdapsBaseVariables, DeleteScanByLdapsBaseVariables, RestoreScanByLdapsBaseVariables, RecycleScanByLdapsBaseVariables, RestoreArchivedScanByLdapsBaseVariables, ArchivedScanByLdapsBaseVariables, AddFileDynamicFieldScanByLdapsBaseVariables, RemoveFileDynamicFieldScanByLdapsBaseVariables } from '../gqls'
import { GetScanByLdapsDocument, FirstScanByLdapsDocument, CountScanByLdapsDocument, FindScanByLdapsDocument, SearchScanByLdapsDocument, ExportSchemaScanByLdapsDocument, ExportDataScanByLdapsDocument, CustomQueryScanByLdapsDocument, CustomQueryIdScanByLdapsDocument, UsedScanByLdapsDocument, ExistScanByLdapsDocument, FindRecyclesScanByLdapsDocument, CountRecyclesScanByLdapsDocument, ReadOnlyScanByLdapsDocument, FindArchivedScanByLdapsDocument, CountAllScanByLdapsDocument, FindDynamicPropertyFieldsScanByLdapsDocument, FindUnassociatedNetworkCredentialsScanByLdapsDocument, FindUnassociatedScanExcludesScanByLdapsDocument, FindUnassociatedScanDataFilesScanByLdapsDocument, FindUnassociatedScanRegistryKeysScanByLdapsDocument, FindUnassociatedScanImportOptionsScanByLdapsDocument, FindUnassociatedUsersScanByLdapsDocument, FindUnassociatedAssetsScanByLdapsDocument, FindUnassociatedAlertsScanByLdapsDocument, FindUnassociatedInventoryHistoriesScanByLdapsDocument, FindUnassociatedLdapHistoriesScanByLdapsDocument, InsertScanByLdapsDocument, UpdateScanByLdapsDocument, ImportDataScanByLdapsDocument, UpdateManyScanByLdapsDocument, DeleteScanByLdapsDocument, RestoreScanByLdapsDocument, RecycleScanByLdapsDocument, RestoreArchivedScanByLdapsDocument, ArchivedScanByLdapsDocument, AddFileDynamicFieldScanByLdapsDocument, RemoveFileDynamicFieldScanByLdapsDocument } from '../gqls'
import { ServiceSingleResultOfScanByLdap, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceSingleResultOfInt64, ServiceListResultOfScanByLdap, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanByLdap, QueryContextOfNetworkCredential, FilterOfNetworkCredential, ServiceListResultOfNetworkCredential, QueryContextOfScanExclude, FilterOfScanExclude, ServiceListResultOfScanExclude, QueryContextOfScanDataFile, FilterOfScanDataFile, ServiceListResultOfScanDataFile, QueryContextOfScanRegistryKey, FilterOfScanRegistryKey, ServiceListResultOfScanRegistryKey, QueryContextOfScanImportOption, FilterOfScanImportOption, ServiceListResultOfScanImportOption, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfLdapHistory, FilterOfLdapHistory, ServiceListResultOfLdapHistory, ScanByLdapInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByLdapBaseService {
    
public modelName = 'scanByLdap';
public modelPluralName = 'scanByLdaps';

	private getScanByLdapsQuery: GetScanByLdapsDocument;
	private firstScanByLdapsQuery: FirstScanByLdapsDocument;
	private countScanByLdapsQuery: CountScanByLdapsDocument;
	private findScanByLdapsQuery: FindScanByLdapsDocument;
	private searchScanByLdapsQuery: SearchScanByLdapsDocument;
	private exportSchemaScanByLdapsQuery: ExportSchemaScanByLdapsDocument;
	private exportDataScanByLdapsQuery: ExportDataScanByLdapsDocument;
	private customQueryScanByLdapsQuery: CustomQueryScanByLdapsDocument;
	private customQueryIdScanByLdapsQuery: CustomQueryIdScanByLdapsDocument;
	private usedScanByLdapsQuery: UsedScanByLdapsDocument;
	private existScanByLdapsQuery: ExistScanByLdapsDocument;
	private findRecyclesScanByLdapsQuery: FindRecyclesScanByLdapsDocument;
	private countRecyclesScanByLdapsQuery: CountRecyclesScanByLdapsDocument;
	private readOnlyScanByLdapsQuery: ReadOnlyScanByLdapsDocument;
	private findArchivedScanByLdapsQuery: FindArchivedScanByLdapsDocument;
	private countAllScanByLdapsQuery: CountAllScanByLdapsDocument;
	private findDynamicPropertyFieldsScanByLdapsQuery: FindDynamicPropertyFieldsScanByLdapsDocument;
	private findUnassociatedNetworkCredentialsScanByLdapsQuery: FindUnassociatedNetworkCredentialsScanByLdapsDocument;
	private findUnassociatedScanExcludesScanByLdapsQuery: FindUnassociatedScanExcludesScanByLdapsDocument;
	private findUnassociatedScanDataFilesScanByLdapsQuery: FindUnassociatedScanDataFilesScanByLdapsDocument;
	private findUnassociatedScanRegistryKeysScanByLdapsQuery: FindUnassociatedScanRegistryKeysScanByLdapsDocument;
	private findUnassociatedScanImportOptionsScanByLdapsQuery: FindUnassociatedScanImportOptionsScanByLdapsDocument;
	private findUnassociatedUsersScanByLdapsQuery: FindUnassociatedUsersScanByLdapsDocument;
	private findUnassociatedAssetsScanByLdapsQuery: FindUnassociatedAssetsScanByLdapsDocument;
	private findUnassociatedAlertsScanByLdapsQuery: FindUnassociatedAlertsScanByLdapsDocument;
	private findUnassociatedInventoryHistoriesScanByLdapsQuery: FindUnassociatedInventoryHistoriesScanByLdapsDocument;
	private findUnassociatedLdapHistoriesScanByLdapsQuery: FindUnassociatedLdapHistoriesScanByLdapsDocument;
	private insertScanByLdapsMutation: InsertScanByLdapsDocument;
	private updateScanByLdapsMutation: UpdateScanByLdapsDocument;
	private importDataScanByLdapsMutation: ImportDataScanByLdapsDocument;
	private updateManyScanByLdapsMutation: UpdateManyScanByLdapsDocument;
	private deleteScanByLdapsMutation: DeleteScanByLdapsDocument;
	private restoreScanByLdapsMutation: RestoreScanByLdapsDocument;
	private recycleScanByLdapsMutation: RecycleScanByLdapsDocument;
	private restoreArchivedScanByLdapsMutation: RestoreArchivedScanByLdapsDocument;
	private archivedScanByLdapsMutation: ArchivedScanByLdapsDocument;
	private addFileDynamicFieldScanByLdapsMutation: AddFileDynamicFieldScanByLdapsDocument;
	private removeFileDynamicFieldScanByLdapsMutation: RemoveFileDynamicFieldScanByLdapsDocument;

	constructor(private injector: Injector) {
		this.getScanByLdapsQuery = this.injector.get(GetScanByLdapsDocument);
		this.firstScanByLdapsQuery = this.injector.get(FirstScanByLdapsDocument);
		this.countScanByLdapsQuery = this.injector.get(CountScanByLdapsDocument);
		this.findScanByLdapsQuery = this.injector.get(FindScanByLdapsDocument);
		this.searchScanByLdapsQuery = this.injector.get(SearchScanByLdapsDocument);
		this.exportSchemaScanByLdapsQuery = this.injector.get(ExportSchemaScanByLdapsDocument);
		this.exportDataScanByLdapsQuery = this.injector.get(ExportDataScanByLdapsDocument);
		this.customQueryScanByLdapsQuery = this.injector.get(CustomQueryScanByLdapsDocument);
		this.customQueryIdScanByLdapsQuery = this.injector.get(CustomQueryIdScanByLdapsDocument);
		this.usedScanByLdapsQuery = this.injector.get(UsedScanByLdapsDocument);
		this.existScanByLdapsQuery = this.injector.get(ExistScanByLdapsDocument);
		this.findRecyclesScanByLdapsQuery = this.injector.get(FindRecyclesScanByLdapsDocument);
		this.countRecyclesScanByLdapsQuery = this.injector.get(CountRecyclesScanByLdapsDocument);
		this.readOnlyScanByLdapsQuery = this.injector.get(ReadOnlyScanByLdapsDocument);
		this.findArchivedScanByLdapsQuery = this.injector.get(FindArchivedScanByLdapsDocument);
		this.countAllScanByLdapsQuery = this.injector.get(CountAllScanByLdapsDocument);
		this.findDynamicPropertyFieldsScanByLdapsQuery = this.injector.get(FindDynamicPropertyFieldsScanByLdapsDocument);
		this.findUnassociatedNetworkCredentialsScanByLdapsQuery = this.injector.get(FindUnassociatedNetworkCredentialsScanByLdapsDocument);
		this.findUnassociatedScanExcludesScanByLdapsQuery = this.injector.get(FindUnassociatedScanExcludesScanByLdapsDocument);
		this.findUnassociatedScanDataFilesScanByLdapsQuery = this.injector.get(FindUnassociatedScanDataFilesScanByLdapsDocument);
		this.findUnassociatedScanRegistryKeysScanByLdapsQuery = this.injector.get(FindUnassociatedScanRegistryKeysScanByLdapsDocument);
		this.findUnassociatedScanImportOptionsScanByLdapsQuery = this.injector.get(FindUnassociatedScanImportOptionsScanByLdapsDocument);
		this.findUnassociatedUsersScanByLdapsQuery = this.injector.get(FindUnassociatedUsersScanByLdapsDocument);
		this.findUnassociatedAssetsScanByLdapsQuery = this.injector.get(FindUnassociatedAssetsScanByLdapsDocument);
		this.findUnassociatedAlertsScanByLdapsQuery = this.injector.get(FindUnassociatedAlertsScanByLdapsDocument);
		this.findUnassociatedInventoryHistoriesScanByLdapsQuery = this.injector.get(FindUnassociatedInventoryHistoriesScanByLdapsDocument);
		this.findUnassociatedLdapHistoriesScanByLdapsQuery = this.injector.get(FindUnassociatedLdapHistoriesScanByLdapsDocument);
		this.insertScanByLdapsMutation = this.injector.get(InsertScanByLdapsDocument);
		this.updateScanByLdapsMutation = this.injector.get(UpdateScanByLdapsDocument);
		this.importDataScanByLdapsMutation = this.injector.get(ImportDataScanByLdapsDocument);
		this.updateManyScanByLdapsMutation = this.injector.get(UpdateManyScanByLdapsDocument);
		this.deleteScanByLdapsMutation = this.injector.get(DeleteScanByLdapsDocument);
		this.restoreScanByLdapsMutation = this.injector.get(RestoreScanByLdapsDocument);
		this.recycleScanByLdapsMutation = this.injector.get(RecycleScanByLdapsDocument);
		this.restoreArchivedScanByLdapsMutation = this.injector.get(RestoreArchivedScanByLdapsDocument);
		this.archivedScanByLdapsMutation = this.injector.get(ArchivedScanByLdapsDocument);
		this.addFileDynamicFieldScanByLdapsMutation = this.injector.get(AddFileDynamicFieldScanByLdapsDocument);
		this.removeFileDynamicFieldScanByLdapsMutation = this.injector.get(RemoveFileDynamicFieldScanByLdapsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByLdap> {
    
    		let variables: GetScanByLdapsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.get));
            }
            else{
                let result:ServiceSingleResultOfScanByLdap={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByLdap> {
    
    		let variables: FirstScanByLdapsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByLdapsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindScanByLdapsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: SearchScanByLdapsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByLdapsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByLdapsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: CustomQueryScanByLdapsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: CustomQueryIdScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByLdapsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindRecyclesScanByLdapsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesScanByLdapsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyScanByLdapsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindArchivedScanByLdapsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllScanByLdapsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsScanByLdapsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedNetworkCredentials(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfNetworkCredential> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('networkCredentials', fields, null, [
			GqlSubFieldArg.create('filterOfNetworkCredentials', 'filter'),
			GqlSubFieldArg.create('optionsOfNetworkCredentials', 'options'),
		]),
		])
		extendedVariables['filterOfNetworkCredentials'] = filter;
		extendedVariables['optionsOfNetworkCredentials'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.networkCredentials));
            }
            else{
                let result: ServiceListResultOfNetworkCredential = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNetworkCredentials(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindUnassociatedNetworkCredentialsScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNetworkCredentialsScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedNetworkCredentials));
    	}

	@InjectArgs
	public findAssociatedScanExcludes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanExclude> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanExcludes', fields, null, [
			GqlSubFieldArg.create('filterOfScanExcludes', 'filter'),
			GqlSubFieldArg.create('optionsOfScanExcludes', 'options'),
		]),
		])
		extendedVariables['filterOfScanExcludes'] = filter;
		extendedVariables['optionsOfScanExcludes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanExcludes));
            }
            else{
                let result: ServiceListResultOfScanExclude = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanExcludes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: FindUnassociatedScanExcludesScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanExcludesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedScanExcludes));
    	}

	@InjectArgs
	public findAssociatedScanDataFiles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanDataFile> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanDataFiles', fields, null, [
			GqlSubFieldArg.create('filterOfScanDataFiles', 'filter'),
			GqlSubFieldArg.create('optionsOfScanDataFiles', 'options'),
		]),
		])
		extendedVariables['filterOfScanDataFiles'] = filter;
		extendedVariables['optionsOfScanDataFiles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanDataFiles));
            }
            else{
                let result: ServiceListResultOfScanDataFile = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: FindUnassociatedScanDataFilesScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanDataFilesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedScanDataFiles));
    	}

	@InjectArgs
	public findAssociatedScanRegistryKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanRegistryKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanRegistryKeys', fields, null, [
			GqlSubFieldArg.create('filterOfScanRegistryKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfScanRegistryKeys', 'options'),
		]),
		])
		extendedVariables['filterOfScanRegistryKeys'] = filter;
		extendedVariables['optionsOfScanRegistryKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanRegistryKeys));
            }
            else{
                let result: ServiceListResultOfScanRegistryKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanRegistryKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: FindUnassociatedScanRegistryKeysScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanRegistryKeysScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedScanRegistryKeys));
    	}

	@InjectArgs
	public findAssociatedScanImportOptions(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanImportOption> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanImportOptions', fields, null, [
			GqlSubFieldArg.create('filterOfScanImportOptions', 'filter'),
			GqlSubFieldArg.create('optionsOfScanImportOptions', 'options'),
		]),
		])
		extendedVariables['filterOfScanImportOptions'] = filter;
		extendedVariables['optionsOfScanImportOptions'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanImportOptions));
            }
            else{
                let result: ServiceListResultOfScanImportOption = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanImportOptions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: FindUnassociatedScanImportOptionsScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanImportOptionsScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedScanImportOptions));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedUsers));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedLdapHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLdapHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ldapHistories', fields, null, [
			GqlSubFieldArg.create('filterOfLdapHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfLdapHistories', 'options'),
		]),
		])
		extendedVariables['filterOfLdapHistories'] = filter;
		extendedVariables['optionsOfLdapHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ldapHistories));
            }
            else{
                let result: ServiceListResultOfLdapHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLdapHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: FindUnassociatedLdapHistoriesScanByLdapsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLdapHistoriesScanByLdapsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.findUnassociatedLdapHistories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByLdapInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByLdap> {
    
    		let variables: InsertScanByLdapsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByLdap> {
    
    		let variables: UpdateScanByLdapsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByLdapsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByLdapsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedScanByLdapsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldScanByLdapsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldScanByLdapsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldScanByLdapsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByLdaps.removeFileDynamicField));
    	}
    
}