import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVirtualMachinesBaseVariables, FirstVirtualMachinesBaseVariables, CountVirtualMachinesBaseVariables, FindVirtualMachinesBaseVariables, SearchVirtualMachinesBaseVariables, ExportSchemaVirtualMachinesBaseVariables, ExportDataVirtualMachinesBaseVariables, CustomQueryVirtualMachinesBaseVariables, CustomQueryIdVirtualMachinesBaseVariables, UsedVirtualMachinesBaseVariables, ExistVirtualMachinesBaseVariables } from '../gqls'
import { GetVirtualMachinesDocument, FirstVirtualMachinesDocument, CountVirtualMachinesDocument, FindVirtualMachinesDocument, SearchVirtualMachinesDocument, ExportSchemaVirtualMachinesDocument, ExportDataVirtualMachinesDocument, CustomQueryVirtualMachinesDocument, CustomQueryIdVirtualMachinesDocument, UsedVirtualMachinesDocument, ExistVirtualMachinesDocument } from '../gqls'
import { ServiceSingleResultOfVirtualMachine, QueryContextOfVirtualMachine, FilterOfVirtualMachine, ServiceSingleResultOfInt64, ServiceListResultOfVirtualMachine, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VirtualMachineBaseService {
    
public modelName = 'virtualMachine';
public modelPluralName = 'virtualMachines';

	private getVirtualMachinesQuery: GetVirtualMachinesDocument;
	private firstVirtualMachinesQuery: FirstVirtualMachinesDocument;
	private countVirtualMachinesQuery: CountVirtualMachinesDocument;
	private findVirtualMachinesQuery: FindVirtualMachinesDocument;
	private searchVirtualMachinesQuery: SearchVirtualMachinesDocument;
	private exportSchemaVirtualMachinesQuery: ExportSchemaVirtualMachinesDocument;
	private exportDataVirtualMachinesQuery: ExportDataVirtualMachinesDocument;
	private customQueryVirtualMachinesQuery: CustomQueryVirtualMachinesDocument;
	private customQueryIdVirtualMachinesQuery: CustomQueryIdVirtualMachinesDocument;
	private usedVirtualMachinesQuery: UsedVirtualMachinesDocument;
	private existVirtualMachinesQuery: ExistVirtualMachinesDocument;

	constructor(private injector: Injector) {
		this.getVirtualMachinesQuery = this.injector.get(GetVirtualMachinesDocument);
		this.firstVirtualMachinesQuery = this.injector.get(FirstVirtualMachinesDocument);
		this.countVirtualMachinesQuery = this.injector.get(CountVirtualMachinesDocument);
		this.findVirtualMachinesQuery = this.injector.get(FindVirtualMachinesDocument);
		this.searchVirtualMachinesQuery = this.injector.get(SearchVirtualMachinesDocument);
		this.exportSchemaVirtualMachinesQuery = this.injector.get(ExportSchemaVirtualMachinesDocument);
		this.exportDataVirtualMachinesQuery = this.injector.get(ExportDataVirtualMachinesDocument);
		this.customQueryVirtualMachinesQuery = this.injector.get(CustomQueryVirtualMachinesDocument);
		this.customQueryIdVirtualMachinesQuery = this.injector.get(CustomQueryIdVirtualMachinesDocument);
		this.usedVirtualMachinesQuery = this.injector.get(UsedVirtualMachinesDocument);
		this.existVirtualMachinesQuery = this.injector.get(ExistVirtualMachinesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVirtualMachine> {
    
    		let variables: GetVirtualMachinesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.get));
            }
            else{
                let result:ServiceSingleResultOfVirtualMachine={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVirtualMachine,
		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVirtualMachine> {
    
    		let variables: FirstVirtualMachinesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVirtualMachinesBaseVariables = {
    			filter: filter
    		}
    				return this.countVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVirtualMachine,
		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVirtualMachine> {
    
    		let variables: FindVirtualMachinesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVirtualMachine,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVirtualMachine> {
    
    		let variables: SearchVirtualMachinesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVirtualMachinesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVirtualMachinesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVirtualMachine,
		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVirtualMachine> {
    
    		let variables: CustomQueryVirtualMachinesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVirtualMachine,
		@Args('filter?') filter?: FilterOfVirtualMachine,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVirtualMachine> {
    
    		let variables: CustomQueryIdVirtualMachinesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVirtualMachinesBaseVariables = {
    			ids: ids
    		}
    				return this.usedVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVirtualMachinesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVirtualMachinesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.virtualMachines.exist));
    	}
    
    
}