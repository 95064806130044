import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Observable } from 'rxjs';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { LocationBaseService } from '../service-bases';
import {
  FilterOfLocation,
  QueryContextOfLocation,
  ServiceListResultOfLocation,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('administration.location')
export class LocationCoreService extends LocationBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findStockLocations(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter?: FilterOfLocation,
    @Args('options?') options?: QueryContextOfLocation,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLocation> {
    let stockLocationFilter: FilterOfLocation = {
      stock: { eq: true },
    };
    if (filter != undefined) {
      stockLocationFilter.and = [filter];
    }

    return this.find(fields, options, stockLocationFilter, extendedVariables);
  }

  @InjectArgs
  public findLoanableLocationDetail(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter?: FilterOfLocation,
    @Args('options?') options?: QueryContextOfLocation,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLocation> {
    let lonableFilter: FilterOfLocation = {
      loanable: { eq: true },
    };

    if (filter != undefined) {
      lonableFilter.and = [filter];
    }

    return this.find(fields, options, lonableFilter, extendedVariables);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create(
          'name',
          this.translatedFieldHelperService.translatedFields(),
        ),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  @InjectArgs
  public findListLocation(
    @Args('fields') fields: Array<GqlSubField | GqlField>,
    @Args('filter?') filter: FilterOfLocation,
    @Args('options?') options: QueryContextOfLocation,
    @Args('selectedKeys?') selectedKeys: any[],
    @Args('extendedVariable?') extendedVariables: any = null,
  ): Observable<ServiceListResultOfLocation> {
    let createFilter: FilterOfLocation = filter;

    let list: string[] = [];
    selectedKeys?.map((element) => {
      list.push(element.id);
      let childrenList = element.treeChildrenId;
      if (childrenList?.length > 0) {
        childrenList.map((child) => {
          let indexChild = list.findIndex((x) => x === child);
          if (child && indexChild === -1) {
            list.push(child);
          }
        });
      }
    });

    if (list?.length > 0) {
      createFilter = { id: { nin: list } };
    }
    if (filter != null) {
      createFilter = { and: [filter] };
    }
    filter = createFilter;
    return this.findListLocations(fields, options, filter, extendedVariables);
  }
}
