import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFeedbacksBaseVariables, FirstFeedbacksBaseVariables, CountFeedbacksBaseVariables, FindFeedbacksBaseVariables, SearchFeedbacksBaseVariables, ExportSchemaFeedbacksBaseVariables, ExportDataFeedbacksBaseVariables, CustomQueryFeedbacksBaseVariables, CustomQueryIdFeedbacksBaseVariables, UsedFeedbacksBaseVariables, ExistFeedbacksBaseVariables, InsertFeedbacksBaseVariables, UpdateFeedbacksBaseVariables, ImportDataFeedbacksBaseVariables, UpdateManyFeedbacksBaseVariables, DeleteFeedbacksBaseVariables } from '../gqls'
import { GetFeedbacksDocument, FirstFeedbacksDocument, CountFeedbacksDocument, FindFeedbacksDocument, SearchFeedbacksDocument, ExportSchemaFeedbacksDocument, ExportDataFeedbacksDocument, CustomQueryFeedbacksDocument, CustomQueryIdFeedbacksDocument, UsedFeedbacksDocument, ExistFeedbacksDocument, InsertFeedbacksDocument, UpdateFeedbacksDocument, ImportDataFeedbacksDocument, UpdateManyFeedbacksDocument, DeleteFeedbacksDocument } from '../gqls'
import { ServiceSingleResultOfFeedback, QueryContextOfFeedback, FilterOfFeedback, ServiceSingleResultOfInt64, ServiceListResultOfFeedback, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FeedbackInput, FieldUpdateOperatorOfFeedback } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FeedbackBaseService {
    
public modelName = 'feedback';
public modelPluralName = 'feedbacks';

	private getFeedbacksQuery: GetFeedbacksDocument;
	private firstFeedbacksQuery: FirstFeedbacksDocument;
	private countFeedbacksQuery: CountFeedbacksDocument;
	private findFeedbacksQuery: FindFeedbacksDocument;
	private searchFeedbacksQuery: SearchFeedbacksDocument;
	private exportSchemaFeedbacksQuery: ExportSchemaFeedbacksDocument;
	private exportDataFeedbacksQuery: ExportDataFeedbacksDocument;
	private customQueryFeedbacksQuery: CustomQueryFeedbacksDocument;
	private customQueryIdFeedbacksQuery: CustomQueryIdFeedbacksDocument;
	private usedFeedbacksQuery: UsedFeedbacksDocument;
	private existFeedbacksQuery: ExistFeedbacksDocument;
	private insertFeedbacksMutation: InsertFeedbacksDocument;
	private updateFeedbacksMutation: UpdateFeedbacksDocument;
	private importDataFeedbacksMutation: ImportDataFeedbacksDocument;
	private updateManyFeedbacksMutation: UpdateManyFeedbacksDocument;
	private deleteFeedbacksMutation: DeleteFeedbacksDocument;

	constructor(private injector: Injector) {
		this.getFeedbacksQuery = this.injector.get(GetFeedbacksDocument);
		this.firstFeedbacksQuery = this.injector.get(FirstFeedbacksDocument);
		this.countFeedbacksQuery = this.injector.get(CountFeedbacksDocument);
		this.findFeedbacksQuery = this.injector.get(FindFeedbacksDocument);
		this.searchFeedbacksQuery = this.injector.get(SearchFeedbacksDocument);
		this.exportSchemaFeedbacksQuery = this.injector.get(ExportSchemaFeedbacksDocument);
		this.exportDataFeedbacksQuery = this.injector.get(ExportDataFeedbacksDocument);
		this.customQueryFeedbacksQuery = this.injector.get(CustomQueryFeedbacksDocument);
		this.customQueryIdFeedbacksQuery = this.injector.get(CustomQueryIdFeedbacksDocument);
		this.usedFeedbacksQuery = this.injector.get(UsedFeedbacksDocument);
		this.existFeedbacksQuery = this.injector.get(ExistFeedbacksDocument);
		this.insertFeedbacksMutation = this.injector.get(InsertFeedbacksDocument);
		this.updateFeedbacksMutation = this.injector.get(UpdateFeedbacksDocument);
		this.importDataFeedbacksMutation = this.injector.get(ImportDataFeedbacksDocument);
		this.updateManyFeedbacksMutation = this.injector.get(UpdateManyFeedbacksDocument);
		this.deleteFeedbacksMutation = this.injector.get(DeleteFeedbacksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFeedback> {
    
    		let variables: GetFeedbacksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.get));
            }
            else{
                let result:ServiceSingleResultOfFeedback={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFeedback,
		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFeedback> {
    
    		let variables: FirstFeedbacksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFeedbacksBaseVariables = {
    			filter: filter
    		}
    				return this.countFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFeedback,
		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFeedback> {
    
    		let variables: FindFeedbacksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFeedback,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFeedback> {
    
    		let variables: SearchFeedbacksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFeedbacksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFeedbacksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFeedback,
		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFeedback> {
    
    		let variables: CustomQueryFeedbacksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFeedback,
		@Args('filter?') filter?: FilterOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFeedback> {
    
    		let variables: CustomQueryIdFeedbacksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFeedbacksBaseVariables = {
    			ids: ids
    		}
    				return this.usedFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFeedbacksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFeedbacksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: FeedbackInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFeedback> {
    
    		let variables: InsertFeedbacksBaseVariables = {
    			entity: entity
    		}
    				return this.insertFeedbacksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFeedback> {
    
    		let variables: UpdateFeedbacksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateFeedbacksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataFeedbacksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataFeedbacksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfFeedback,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyFeedbacksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyFeedbacksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteFeedbacksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteFeedbacksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.feedbacks.delete));
    	}
    
}