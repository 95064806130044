import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByWorkgroupsBaseVariables, FirstScanByWorkgroupsBaseVariables, CountScanByWorkgroupsBaseVariables, FindScanByWorkgroupsBaseVariables, SearchScanByWorkgroupsBaseVariables, ExportSchemaScanByWorkgroupsBaseVariables, ExportDataScanByWorkgroupsBaseVariables, CustomQueryScanByWorkgroupsBaseVariables, CustomQueryIdScanByWorkgroupsBaseVariables, UsedScanByWorkgroupsBaseVariables, ExistScanByWorkgroupsBaseVariables, FindRecyclesScanByWorkgroupsBaseVariables, CountRecyclesScanByWorkgroupsBaseVariables, ReadOnlyScanByWorkgroupsBaseVariables, FindArchivedScanByWorkgroupsBaseVariables, CountAllScanByWorkgroupsBaseVariables, FindDynamicPropertyFieldsScanByWorkgroupsBaseVariables, FindUnassociatedNetworkCredentialsScanByWorkgroupsBaseVariables, FindUnassociatedScanExcludesScanByWorkgroupsBaseVariables, FindUnassociatedScanDataFilesScanByWorkgroupsBaseVariables, FindUnassociatedScanRegistryKeysScanByWorkgroupsBaseVariables, FindUnassociatedScanImportOptionsScanByWorkgroupsBaseVariables, FindUnassociatedUsersScanByWorkgroupsBaseVariables, FindUnassociatedAssetsScanByWorkgroupsBaseVariables, FindUnassociatedAlertsScanByWorkgroupsBaseVariables, FindUnassociatedInventoryHistoriesScanByWorkgroupsBaseVariables, FindUnassociatedLdapHistoriesScanByWorkgroupsBaseVariables, InsertScanByWorkgroupsBaseVariables, UpdateScanByWorkgroupsBaseVariables, ImportDataScanByWorkgroupsBaseVariables, UpdateManyScanByWorkgroupsBaseVariables, DeleteScanByWorkgroupsBaseVariables, RestoreScanByWorkgroupsBaseVariables, RecycleScanByWorkgroupsBaseVariables, RestoreArchivedScanByWorkgroupsBaseVariables, ArchivedScanByWorkgroupsBaseVariables, AddFileDynamicFieldScanByWorkgroupsBaseVariables, RemoveFileDynamicFieldScanByWorkgroupsBaseVariables } from '../gqls'
import { GetScanByWorkgroupsDocument, FirstScanByWorkgroupsDocument, CountScanByWorkgroupsDocument, FindScanByWorkgroupsDocument, SearchScanByWorkgroupsDocument, ExportSchemaScanByWorkgroupsDocument, ExportDataScanByWorkgroupsDocument, CustomQueryScanByWorkgroupsDocument, CustomQueryIdScanByWorkgroupsDocument, UsedScanByWorkgroupsDocument, ExistScanByWorkgroupsDocument, FindRecyclesScanByWorkgroupsDocument, CountRecyclesScanByWorkgroupsDocument, ReadOnlyScanByWorkgroupsDocument, FindArchivedScanByWorkgroupsDocument, CountAllScanByWorkgroupsDocument, FindDynamicPropertyFieldsScanByWorkgroupsDocument, FindUnassociatedNetworkCredentialsScanByWorkgroupsDocument, FindUnassociatedScanExcludesScanByWorkgroupsDocument, FindUnassociatedScanDataFilesScanByWorkgroupsDocument, FindUnassociatedScanRegistryKeysScanByWorkgroupsDocument, FindUnassociatedScanImportOptionsScanByWorkgroupsDocument, FindUnassociatedUsersScanByWorkgroupsDocument, FindUnassociatedAssetsScanByWorkgroupsDocument, FindUnassociatedAlertsScanByWorkgroupsDocument, FindUnassociatedInventoryHistoriesScanByWorkgroupsDocument, FindUnassociatedLdapHistoriesScanByWorkgroupsDocument, InsertScanByWorkgroupsDocument, UpdateScanByWorkgroupsDocument, ImportDataScanByWorkgroupsDocument, UpdateManyScanByWorkgroupsDocument, DeleteScanByWorkgroupsDocument, RestoreScanByWorkgroupsDocument, RecycleScanByWorkgroupsDocument, RestoreArchivedScanByWorkgroupsDocument, ArchivedScanByWorkgroupsDocument, AddFileDynamicFieldScanByWorkgroupsDocument, RemoveFileDynamicFieldScanByWorkgroupsDocument } from '../gqls'
import { ServiceSingleResultOfScanByWorkgroup, QueryContextOfScanByWorkgroup, FilterOfScanByWorkgroup, ServiceSingleResultOfInt64, ServiceListResultOfScanByWorkgroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanByWorkgroup, QueryContextOfNetworkCredential, FilterOfNetworkCredential, ServiceListResultOfNetworkCredential, QueryContextOfScanExclude, FilterOfScanExclude, ServiceListResultOfScanExclude, QueryContextOfScanDataFile, FilterOfScanDataFile, ServiceListResultOfScanDataFile, QueryContextOfScanRegistryKey, FilterOfScanRegistryKey, ServiceListResultOfScanRegistryKey, QueryContextOfScanImportOption, FilterOfScanImportOption, ServiceListResultOfScanImportOption, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfLdapHistory, FilterOfLdapHistory, ServiceListResultOfLdapHistory, ScanByWorkgroupInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByWorkgroupBaseService {
    
public modelName = 'scanByWorkgroup';
public modelPluralName = 'scanByWorkgroups';

	private getScanByWorkgroupsQuery: GetScanByWorkgroupsDocument;
	private firstScanByWorkgroupsQuery: FirstScanByWorkgroupsDocument;
	private countScanByWorkgroupsQuery: CountScanByWorkgroupsDocument;
	private findScanByWorkgroupsQuery: FindScanByWorkgroupsDocument;
	private searchScanByWorkgroupsQuery: SearchScanByWorkgroupsDocument;
	private exportSchemaScanByWorkgroupsQuery: ExportSchemaScanByWorkgroupsDocument;
	private exportDataScanByWorkgroupsQuery: ExportDataScanByWorkgroupsDocument;
	private customQueryScanByWorkgroupsQuery: CustomQueryScanByWorkgroupsDocument;
	private customQueryIdScanByWorkgroupsQuery: CustomQueryIdScanByWorkgroupsDocument;
	private usedScanByWorkgroupsQuery: UsedScanByWorkgroupsDocument;
	private existScanByWorkgroupsQuery: ExistScanByWorkgroupsDocument;
	private findRecyclesScanByWorkgroupsQuery: FindRecyclesScanByWorkgroupsDocument;
	private countRecyclesScanByWorkgroupsQuery: CountRecyclesScanByWorkgroupsDocument;
	private readOnlyScanByWorkgroupsQuery: ReadOnlyScanByWorkgroupsDocument;
	private findArchivedScanByWorkgroupsQuery: FindArchivedScanByWorkgroupsDocument;
	private countAllScanByWorkgroupsQuery: CountAllScanByWorkgroupsDocument;
	private findDynamicPropertyFieldsScanByWorkgroupsQuery: FindDynamicPropertyFieldsScanByWorkgroupsDocument;
	private findUnassociatedNetworkCredentialsScanByWorkgroupsQuery: FindUnassociatedNetworkCredentialsScanByWorkgroupsDocument;
	private findUnassociatedScanExcludesScanByWorkgroupsQuery: FindUnassociatedScanExcludesScanByWorkgroupsDocument;
	private findUnassociatedScanDataFilesScanByWorkgroupsQuery: FindUnassociatedScanDataFilesScanByWorkgroupsDocument;
	private findUnassociatedScanRegistryKeysScanByWorkgroupsQuery: FindUnassociatedScanRegistryKeysScanByWorkgroupsDocument;
	private findUnassociatedScanImportOptionsScanByWorkgroupsQuery: FindUnassociatedScanImportOptionsScanByWorkgroupsDocument;
	private findUnassociatedUsersScanByWorkgroupsQuery: FindUnassociatedUsersScanByWorkgroupsDocument;
	private findUnassociatedAssetsScanByWorkgroupsQuery: FindUnassociatedAssetsScanByWorkgroupsDocument;
	private findUnassociatedAlertsScanByWorkgroupsQuery: FindUnassociatedAlertsScanByWorkgroupsDocument;
	private findUnassociatedInventoryHistoriesScanByWorkgroupsQuery: FindUnassociatedInventoryHistoriesScanByWorkgroupsDocument;
	private findUnassociatedLdapHistoriesScanByWorkgroupsQuery: FindUnassociatedLdapHistoriesScanByWorkgroupsDocument;
	private insertScanByWorkgroupsMutation: InsertScanByWorkgroupsDocument;
	private updateScanByWorkgroupsMutation: UpdateScanByWorkgroupsDocument;
	private importDataScanByWorkgroupsMutation: ImportDataScanByWorkgroupsDocument;
	private updateManyScanByWorkgroupsMutation: UpdateManyScanByWorkgroupsDocument;
	private deleteScanByWorkgroupsMutation: DeleteScanByWorkgroupsDocument;
	private restoreScanByWorkgroupsMutation: RestoreScanByWorkgroupsDocument;
	private recycleScanByWorkgroupsMutation: RecycleScanByWorkgroupsDocument;
	private restoreArchivedScanByWorkgroupsMutation: RestoreArchivedScanByWorkgroupsDocument;
	private archivedScanByWorkgroupsMutation: ArchivedScanByWorkgroupsDocument;
	private addFileDynamicFieldScanByWorkgroupsMutation: AddFileDynamicFieldScanByWorkgroupsDocument;
	private removeFileDynamicFieldScanByWorkgroupsMutation: RemoveFileDynamicFieldScanByWorkgroupsDocument;

	constructor(private injector: Injector) {
		this.getScanByWorkgroupsQuery = this.injector.get(GetScanByWorkgroupsDocument);
		this.firstScanByWorkgroupsQuery = this.injector.get(FirstScanByWorkgroupsDocument);
		this.countScanByWorkgroupsQuery = this.injector.get(CountScanByWorkgroupsDocument);
		this.findScanByWorkgroupsQuery = this.injector.get(FindScanByWorkgroupsDocument);
		this.searchScanByWorkgroupsQuery = this.injector.get(SearchScanByWorkgroupsDocument);
		this.exportSchemaScanByWorkgroupsQuery = this.injector.get(ExportSchemaScanByWorkgroupsDocument);
		this.exportDataScanByWorkgroupsQuery = this.injector.get(ExportDataScanByWorkgroupsDocument);
		this.customQueryScanByWorkgroupsQuery = this.injector.get(CustomQueryScanByWorkgroupsDocument);
		this.customQueryIdScanByWorkgroupsQuery = this.injector.get(CustomQueryIdScanByWorkgroupsDocument);
		this.usedScanByWorkgroupsQuery = this.injector.get(UsedScanByWorkgroupsDocument);
		this.existScanByWorkgroupsQuery = this.injector.get(ExistScanByWorkgroupsDocument);
		this.findRecyclesScanByWorkgroupsQuery = this.injector.get(FindRecyclesScanByWorkgroupsDocument);
		this.countRecyclesScanByWorkgroupsQuery = this.injector.get(CountRecyclesScanByWorkgroupsDocument);
		this.readOnlyScanByWorkgroupsQuery = this.injector.get(ReadOnlyScanByWorkgroupsDocument);
		this.findArchivedScanByWorkgroupsQuery = this.injector.get(FindArchivedScanByWorkgroupsDocument);
		this.countAllScanByWorkgroupsQuery = this.injector.get(CountAllScanByWorkgroupsDocument);
		this.findDynamicPropertyFieldsScanByWorkgroupsQuery = this.injector.get(FindDynamicPropertyFieldsScanByWorkgroupsDocument);
		this.findUnassociatedNetworkCredentialsScanByWorkgroupsQuery = this.injector.get(FindUnassociatedNetworkCredentialsScanByWorkgroupsDocument);
		this.findUnassociatedScanExcludesScanByWorkgroupsQuery = this.injector.get(FindUnassociatedScanExcludesScanByWorkgroupsDocument);
		this.findUnassociatedScanDataFilesScanByWorkgroupsQuery = this.injector.get(FindUnassociatedScanDataFilesScanByWorkgroupsDocument);
		this.findUnassociatedScanRegistryKeysScanByWorkgroupsQuery = this.injector.get(FindUnassociatedScanRegistryKeysScanByWorkgroupsDocument);
		this.findUnassociatedScanImportOptionsScanByWorkgroupsQuery = this.injector.get(FindUnassociatedScanImportOptionsScanByWorkgroupsDocument);
		this.findUnassociatedUsersScanByWorkgroupsQuery = this.injector.get(FindUnassociatedUsersScanByWorkgroupsDocument);
		this.findUnassociatedAssetsScanByWorkgroupsQuery = this.injector.get(FindUnassociatedAssetsScanByWorkgroupsDocument);
		this.findUnassociatedAlertsScanByWorkgroupsQuery = this.injector.get(FindUnassociatedAlertsScanByWorkgroupsDocument);
		this.findUnassociatedInventoryHistoriesScanByWorkgroupsQuery = this.injector.get(FindUnassociatedInventoryHistoriesScanByWorkgroupsDocument);
		this.findUnassociatedLdapHistoriesScanByWorkgroupsQuery = this.injector.get(FindUnassociatedLdapHistoriesScanByWorkgroupsDocument);
		this.insertScanByWorkgroupsMutation = this.injector.get(InsertScanByWorkgroupsDocument);
		this.updateScanByWorkgroupsMutation = this.injector.get(UpdateScanByWorkgroupsDocument);
		this.importDataScanByWorkgroupsMutation = this.injector.get(ImportDataScanByWorkgroupsDocument);
		this.updateManyScanByWorkgroupsMutation = this.injector.get(UpdateManyScanByWorkgroupsDocument);
		this.deleteScanByWorkgroupsMutation = this.injector.get(DeleteScanByWorkgroupsDocument);
		this.restoreScanByWorkgroupsMutation = this.injector.get(RestoreScanByWorkgroupsDocument);
		this.recycleScanByWorkgroupsMutation = this.injector.get(RecycleScanByWorkgroupsDocument);
		this.restoreArchivedScanByWorkgroupsMutation = this.injector.get(RestoreArchivedScanByWorkgroupsDocument);
		this.archivedScanByWorkgroupsMutation = this.injector.get(ArchivedScanByWorkgroupsDocument);
		this.addFileDynamicFieldScanByWorkgroupsMutation = this.injector.get(AddFileDynamicFieldScanByWorkgroupsDocument);
		this.removeFileDynamicFieldScanByWorkgroupsMutation = this.injector.get(RemoveFileDynamicFieldScanByWorkgroupsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByWorkgroup> {
    
    		let variables: GetScanByWorkgroupsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.get));
            }
            else{
                let result:ServiceSingleResultOfScanByWorkgroup={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByWorkgroup> {
    
    		let variables: FirstScanByWorkgroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByWorkgroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: FindScanByWorkgroupsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: SearchScanByWorkgroupsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByWorkgroupsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByWorkgroupsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: CustomQueryScanByWorkgroupsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: CustomQueryIdScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByWorkgroupsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: FindRecyclesScanByWorkgroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesScanByWorkgroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyScanByWorkgroupsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByWorkgroup,
		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByWorkgroup> {
    
    		let variables: FindArchivedScanByWorkgroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllScanByWorkgroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsScanByWorkgroupsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedNetworkCredentials(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfNetworkCredential> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('networkCredentials', fields, null, [
			GqlSubFieldArg.create('filterOfNetworkCredentials', 'filter'),
			GqlSubFieldArg.create('optionsOfNetworkCredentials', 'options'),
		]),
		])
		extendedVariables['filterOfNetworkCredentials'] = filter;
		extendedVariables['optionsOfNetworkCredentials'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.networkCredentials));
            }
            else{
                let result: ServiceListResultOfNetworkCredential = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNetworkCredentials(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindUnassociatedNetworkCredentialsScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNetworkCredentialsScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedNetworkCredentials));
    	}

	@InjectArgs
	public findAssociatedScanExcludes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanExclude> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanExcludes', fields, null, [
			GqlSubFieldArg.create('filterOfScanExcludes', 'filter'),
			GqlSubFieldArg.create('optionsOfScanExcludes', 'options'),
		]),
		])
		extendedVariables['filterOfScanExcludes'] = filter;
		extendedVariables['optionsOfScanExcludes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanExcludes));
            }
            else{
                let result: ServiceListResultOfScanExclude = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanExcludes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: FindUnassociatedScanExcludesScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanExcludesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedScanExcludes));
    	}

	@InjectArgs
	public findAssociatedScanDataFiles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanDataFile> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanDataFiles', fields, null, [
			GqlSubFieldArg.create('filterOfScanDataFiles', 'filter'),
			GqlSubFieldArg.create('optionsOfScanDataFiles', 'options'),
		]),
		])
		extendedVariables['filterOfScanDataFiles'] = filter;
		extendedVariables['optionsOfScanDataFiles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanDataFiles));
            }
            else{
                let result: ServiceListResultOfScanDataFile = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: FindUnassociatedScanDataFilesScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanDataFilesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedScanDataFiles));
    	}

	@InjectArgs
	public findAssociatedScanRegistryKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanRegistryKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanRegistryKeys', fields, null, [
			GqlSubFieldArg.create('filterOfScanRegistryKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfScanRegistryKeys', 'options'),
		]),
		])
		extendedVariables['filterOfScanRegistryKeys'] = filter;
		extendedVariables['optionsOfScanRegistryKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanRegistryKeys));
            }
            else{
                let result: ServiceListResultOfScanRegistryKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanRegistryKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: FindUnassociatedScanRegistryKeysScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanRegistryKeysScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedScanRegistryKeys));
    	}

	@InjectArgs
	public findAssociatedScanImportOptions(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanImportOption> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanImportOptions', fields, null, [
			GqlSubFieldArg.create('filterOfScanImportOptions', 'filter'),
			GqlSubFieldArg.create('optionsOfScanImportOptions', 'options'),
		]),
		])
		extendedVariables['filterOfScanImportOptions'] = filter;
		extendedVariables['optionsOfScanImportOptions'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanImportOptions));
            }
            else{
                let result: ServiceListResultOfScanImportOption = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanImportOptions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: FindUnassociatedScanImportOptionsScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanImportOptionsScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedScanImportOptions));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedUsers));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedLdapHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLdapHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ldapHistories', fields, null, [
			GqlSubFieldArg.create('filterOfLdapHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfLdapHistories', 'options'),
		]),
		])
		extendedVariables['filterOfLdapHistories'] = filter;
		extendedVariables['optionsOfLdapHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ldapHistories));
            }
            else{
                let result: ServiceListResultOfLdapHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLdapHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: FindUnassociatedLdapHistoriesScanByWorkgroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLdapHistoriesScanByWorkgroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.findUnassociatedLdapHistories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByWorkgroupInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByWorkgroup> {
    
    		let variables: InsertScanByWorkgroupsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByWorkgroup> {
    
    		let variables: UpdateScanByWorkgroupsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByWorkgroupsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByWorkgroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByWorkgroupsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedScanByWorkgroupsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldScanByWorkgroupsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldScanByWorkgroupsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldScanByWorkgroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByWorkgroups.removeFileDynamicField));
    	}
    
}