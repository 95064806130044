import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInterventionModelsBaseVariables, FirstInterventionModelsBaseVariables, CountInterventionModelsBaseVariables, FindInterventionModelsBaseVariables, SearchInterventionModelsBaseVariables, ExportSchemaInterventionModelsBaseVariables, ExportDataInterventionModelsBaseVariables, CustomQueryInterventionModelsBaseVariables, CustomQueryIdInterventionModelsBaseVariables, FindUnassociatedSecurityGroupsInterventionModelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsInterventionModelsBaseVariables, FindFilesInterventionModelsBaseVariables, FindAttachmentFilesInterventionModelsBaseVariables, FindHelpDeskUserAttachmentFilesInterventionModelsBaseVariables, UsedInterventionModelsBaseVariables, FindByOperatorTeamInterventionModelsBaseVariables, FindOperatorInterventionModelsBaseVariables, FindOperatorTeamInterventionModelsBaseVariables, FindByOperatorTeamAndTypeInterventionModelsBaseVariables, ExistInterventionModelsBaseVariables, InsertInterventionModelsBaseVariables, UpdateInterventionModelsBaseVariables, ImportDataInterventionModelsBaseVariables, UpdateManyInterventionModelsBaseVariables, DeleteInterventionModelsBaseVariables, AddSecurityGroupInterventionModelsBaseVariables, RemoveSecurityGroupInterventionModelsBaseVariables, AddOperatorTeamSecurityGroupInterventionModelsBaseVariables, RemoveOperatorTeamSecurityGroupInterventionModelsBaseVariables, AddFilesInterventionModelsBaseVariables, RemoveFilesInterventionModelsBaseVariables, UploadChunkFileInterventionModelsBaseVariables } from '../gqls'
import { GetInterventionModelsDocument, FirstInterventionModelsDocument, CountInterventionModelsDocument, FindInterventionModelsDocument, SearchInterventionModelsDocument, ExportSchemaInterventionModelsDocument, ExportDataInterventionModelsDocument, CustomQueryInterventionModelsDocument, CustomQueryIdInterventionModelsDocument, FindUnassociatedSecurityGroupsInterventionModelsDocument, FindAssociatedOperatorTeamSecurityGroupsInterventionModelsDocument, FindFilesInterventionModelsDocument, FindAttachmentFilesInterventionModelsDocument, FindHelpDeskUserAttachmentFilesInterventionModelsDocument, UsedInterventionModelsDocument, FindByOperatorTeamInterventionModelsDocument, FindOperatorInterventionModelsDocument, FindOperatorTeamInterventionModelsDocument, FindByOperatorTeamAndTypeInterventionModelsDocument, ExistInterventionModelsDocument, InsertInterventionModelsDocument, UpdateInterventionModelsDocument, ImportDataInterventionModelsDocument, UpdateManyInterventionModelsDocument, DeleteInterventionModelsDocument, AddSecurityGroupInterventionModelsDocument, RemoveSecurityGroupInterventionModelsDocument, AddOperatorTeamSecurityGroupInterventionModelsDocument, RemoveOperatorTeamSecurityGroupInterventionModelsDocument, AddFilesInterventionModelsDocument, RemoveFilesInterventionModelsDocument, UploadChunkFileInterventionModelsDocument } from '../gqls'
import { ServiceSingleResultOfInterventionModel, QueryContextOfInterventionModel, FilterOfInterventionModel, ServiceSingleResultOfInt64, ServiceListResultOfInterventionModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, QueryContextOfOperatorTeam, FilterOfOperatorTeam, InterventionModelInput, FieldUpdateOperatorOfInterventionModel, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InterventionModelBaseService {
    
public modelName = 'interventionModel';
public modelPluralName = 'interventionModels';

	private getInterventionModelsQuery: GetInterventionModelsDocument;
	private firstInterventionModelsQuery: FirstInterventionModelsDocument;
	private countInterventionModelsQuery: CountInterventionModelsDocument;
	private findInterventionModelsQuery: FindInterventionModelsDocument;
	private searchInterventionModelsQuery: SearchInterventionModelsDocument;
	private exportSchemaInterventionModelsQuery: ExportSchemaInterventionModelsDocument;
	private exportDataInterventionModelsQuery: ExportDataInterventionModelsDocument;
	private customQueryInterventionModelsQuery: CustomQueryInterventionModelsDocument;
	private customQueryIdInterventionModelsQuery: CustomQueryIdInterventionModelsDocument;
	private findUnassociatedSecurityGroupsInterventionModelsQuery: FindUnassociatedSecurityGroupsInterventionModelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsInterventionModelsQuery: FindAssociatedOperatorTeamSecurityGroupsInterventionModelsDocument;
	private findFilesInterventionModelsQuery: FindFilesInterventionModelsDocument;
	private findAttachmentFilesInterventionModelsQuery: FindAttachmentFilesInterventionModelsDocument;
	private findHelpDeskUserAttachmentFilesInterventionModelsQuery: FindHelpDeskUserAttachmentFilesInterventionModelsDocument;
	private usedInterventionModelsQuery: UsedInterventionModelsDocument;
	private findByOperatorTeamInterventionModelsQuery: FindByOperatorTeamInterventionModelsDocument;
	private findOperatorInterventionModelsQuery: FindOperatorInterventionModelsDocument;
	private findOperatorTeamInterventionModelsQuery: FindOperatorTeamInterventionModelsDocument;
	private findByOperatorTeamAndTypeInterventionModelsQuery: FindByOperatorTeamAndTypeInterventionModelsDocument;
	private existInterventionModelsQuery: ExistInterventionModelsDocument;
	private insertInterventionModelsMutation: InsertInterventionModelsDocument;
	private updateInterventionModelsMutation: UpdateInterventionModelsDocument;
	private importDataInterventionModelsMutation: ImportDataInterventionModelsDocument;
	private updateManyInterventionModelsMutation: UpdateManyInterventionModelsDocument;
	private deleteInterventionModelsMutation: DeleteInterventionModelsDocument;
	private addSecurityGroupInterventionModelsMutation: AddSecurityGroupInterventionModelsDocument;
	private removeSecurityGroupInterventionModelsMutation: RemoveSecurityGroupInterventionModelsDocument;
	private addOperatorTeamSecurityGroupInterventionModelsMutation: AddOperatorTeamSecurityGroupInterventionModelsDocument;
	private removeOperatorTeamSecurityGroupInterventionModelsMutation: RemoveOperatorTeamSecurityGroupInterventionModelsDocument;
	private addFilesInterventionModelsMutation: AddFilesInterventionModelsDocument;
	private removeFilesInterventionModelsMutation: RemoveFilesInterventionModelsDocument;
	private uploadChunkFileInterventionModelsMutation: UploadChunkFileInterventionModelsDocument;

	constructor(private injector: Injector) {
		this.getInterventionModelsQuery = this.injector.get(GetInterventionModelsDocument);
		this.firstInterventionModelsQuery = this.injector.get(FirstInterventionModelsDocument);
		this.countInterventionModelsQuery = this.injector.get(CountInterventionModelsDocument);
		this.findInterventionModelsQuery = this.injector.get(FindInterventionModelsDocument);
		this.searchInterventionModelsQuery = this.injector.get(SearchInterventionModelsDocument);
		this.exportSchemaInterventionModelsQuery = this.injector.get(ExportSchemaInterventionModelsDocument);
		this.exportDataInterventionModelsQuery = this.injector.get(ExportDataInterventionModelsDocument);
		this.customQueryInterventionModelsQuery = this.injector.get(CustomQueryInterventionModelsDocument);
		this.customQueryIdInterventionModelsQuery = this.injector.get(CustomQueryIdInterventionModelsDocument);
		this.findUnassociatedSecurityGroupsInterventionModelsQuery = this.injector.get(FindUnassociatedSecurityGroupsInterventionModelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsInterventionModelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsInterventionModelsDocument);
		this.findFilesInterventionModelsQuery = this.injector.get(FindFilesInterventionModelsDocument);
		this.findAttachmentFilesInterventionModelsQuery = this.injector.get(FindAttachmentFilesInterventionModelsDocument);
		this.findHelpDeskUserAttachmentFilesInterventionModelsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesInterventionModelsDocument);
		this.usedInterventionModelsQuery = this.injector.get(UsedInterventionModelsDocument);
		this.findByOperatorTeamInterventionModelsQuery = this.injector.get(FindByOperatorTeamInterventionModelsDocument);
		this.findOperatorInterventionModelsQuery = this.injector.get(FindOperatorInterventionModelsDocument);
		this.findOperatorTeamInterventionModelsQuery = this.injector.get(FindOperatorTeamInterventionModelsDocument);
		this.findByOperatorTeamAndTypeInterventionModelsQuery = this.injector.get(FindByOperatorTeamAndTypeInterventionModelsDocument);
		this.existInterventionModelsQuery = this.injector.get(ExistInterventionModelsDocument);
		this.insertInterventionModelsMutation = this.injector.get(InsertInterventionModelsDocument);
		this.updateInterventionModelsMutation = this.injector.get(UpdateInterventionModelsDocument);
		this.importDataInterventionModelsMutation = this.injector.get(ImportDataInterventionModelsDocument);
		this.updateManyInterventionModelsMutation = this.injector.get(UpdateManyInterventionModelsDocument);
		this.deleteInterventionModelsMutation = this.injector.get(DeleteInterventionModelsDocument);
		this.addSecurityGroupInterventionModelsMutation = this.injector.get(AddSecurityGroupInterventionModelsDocument);
		this.removeSecurityGroupInterventionModelsMutation = this.injector.get(RemoveSecurityGroupInterventionModelsDocument);
		this.addOperatorTeamSecurityGroupInterventionModelsMutation = this.injector.get(AddOperatorTeamSecurityGroupInterventionModelsDocument);
		this.removeOperatorTeamSecurityGroupInterventionModelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupInterventionModelsDocument);
		this.addFilesInterventionModelsMutation = this.injector.get(AddFilesInterventionModelsDocument);
		this.removeFilesInterventionModelsMutation = this.injector.get(RemoveFilesInterventionModelsDocument);
		this.uploadChunkFileInterventionModelsMutation = this.injector.get(UploadChunkFileInterventionModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInterventionModel> {
    
    		let variables: GetInterventionModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.get));
            }
            else{
                let result:ServiceSingleResultOfInterventionModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInterventionModel> {
    
    		let variables: FirstInterventionModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInterventionModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: FindInterventionModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: SearchInterventionModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInterventionModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInterventionModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: CustomQueryInterventionModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: CustomQueryIdInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesInterventionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInterventionModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByOperatorTeam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: FindByOperatorTeamInterventionModelsBaseVariables = {
    
    		}
    				return this.findByOperatorTeamInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findByOperatorTeam));
    	}

    	/**  */
    	@InjectArgs
    	public findOperator(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('operatorTeamId?') operatorTeamId?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindOperatorInterventionModelsBaseVariables = {
    			operatorTeamId: operatorTeamId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findOperator));
    	}

    	/**  */
    	@InjectArgs
    	public findOperatorTeam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOperatorTeam,
		@Args('operatorId?') operatorId?: string,
		@Args('filter?') filter?: FilterOfOperatorTeam,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindOperatorTeamInterventionModelsBaseVariables = {
    			operatorId: operatorId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorTeamInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findOperatorTeam));
    	}

    	/**  */
    	@InjectArgs
    	public findByOperatorTeamAndType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfInterventionModel,
		@Args('filter?') filter?: FilterOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInterventionModel> {
    
    		let variables: FindByOperatorTeamAndTypeInterventionModelsBaseVariables = {
    			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findByOperatorTeamAndTypeInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.findByOperatorTeamAndType));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInterventionModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInterventionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: InterventionModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInterventionModel> {
    
    		let variables: InsertInterventionModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInterventionModel> {
    
    		let variables: UpdateInterventionModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataInterventionModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfInterventionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyInterventionModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteInterventionModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupInterventionModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupInterventionModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupInterventionModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupInterventionModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesInterventionModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesInterventionModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('size') size: number,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileInterventionModelsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId
    		}
    				return this.uploadChunkFileInterventionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.interventionModels.uploadChunkFile));
    	}
    
}