import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByAddressIpRangesBaseVariables, FirstScanByAddressIpRangesBaseVariables, CountScanByAddressIpRangesBaseVariables, FindScanByAddressIpRangesBaseVariables, SearchScanByAddressIpRangesBaseVariables, ExportSchemaScanByAddressIpRangesBaseVariables, ExportDataScanByAddressIpRangesBaseVariables, CustomQueryScanByAddressIpRangesBaseVariables, CustomQueryIdScanByAddressIpRangesBaseVariables, UsedScanByAddressIpRangesBaseVariables, ExistScanByAddressIpRangesBaseVariables, FindRecyclesScanByAddressIpRangesBaseVariables, CountRecyclesScanByAddressIpRangesBaseVariables, ReadOnlyScanByAddressIpRangesBaseVariables, FindArchivedScanByAddressIpRangesBaseVariables, CountAllScanByAddressIpRangesBaseVariables, FindDynamicPropertyFieldsScanByAddressIpRangesBaseVariables, FindUnassociatedNetworkCredentialsScanByAddressIpRangesBaseVariables, FindUnassociatedScanExcludesScanByAddressIpRangesBaseVariables, FindUnassociatedScanDataFilesScanByAddressIpRangesBaseVariables, FindUnassociatedScanRegistryKeysScanByAddressIpRangesBaseVariables, FindUnassociatedScanImportOptionsScanByAddressIpRangesBaseVariables, FindUnassociatedUsersScanByAddressIpRangesBaseVariables, FindUnassociatedAssetsScanByAddressIpRangesBaseVariables, FindUnassociatedAlertsScanByAddressIpRangesBaseVariables, FindUnassociatedInventoryHistoriesScanByAddressIpRangesBaseVariables, FindUnassociatedLdapHistoriesScanByAddressIpRangesBaseVariables, InsertScanByAddressIpRangesBaseVariables, UpdateScanByAddressIpRangesBaseVariables, ImportDataScanByAddressIpRangesBaseVariables, UpdateManyScanByAddressIpRangesBaseVariables, DeleteScanByAddressIpRangesBaseVariables, RestoreScanByAddressIpRangesBaseVariables, RecycleScanByAddressIpRangesBaseVariables, RestoreArchivedScanByAddressIpRangesBaseVariables, ArchivedScanByAddressIpRangesBaseVariables, AddFileDynamicFieldScanByAddressIpRangesBaseVariables, RemoveFileDynamicFieldScanByAddressIpRangesBaseVariables } from '../gqls'
import { GetScanByAddressIpRangesDocument, FirstScanByAddressIpRangesDocument, CountScanByAddressIpRangesDocument, FindScanByAddressIpRangesDocument, SearchScanByAddressIpRangesDocument, ExportSchemaScanByAddressIpRangesDocument, ExportDataScanByAddressIpRangesDocument, CustomQueryScanByAddressIpRangesDocument, CustomQueryIdScanByAddressIpRangesDocument, UsedScanByAddressIpRangesDocument, ExistScanByAddressIpRangesDocument, FindRecyclesScanByAddressIpRangesDocument, CountRecyclesScanByAddressIpRangesDocument, ReadOnlyScanByAddressIpRangesDocument, FindArchivedScanByAddressIpRangesDocument, CountAllScanByAddressIpRangesDocument, FindDynamicPropertyFieldsScanByAddressIpRangesDocument, FindUnassociatedNetworkCredentialsScanByAddressIpRangesDocument, FindUnassociatedScanExcludesScanByAddressIpRangesDocument, FindUnassociatedScanDataFilesScanByAddressIpRangesDocument, FindUnassociatedScanRegistryKeysScanByAddressIpRangesDocument, FindUnassociatedScanImportOptionsScanByAddressIpRangesDocument, FindUnassociatedUsersScanByAddressIpRangesDocument, FindUnassociatedAssetsScanByAddressIpRangesDocument, FindUnassociatedAlertsScanByAddressIpRangesDocument, FindUnassociatedInventoryHistoriesScanByAddressIpRangesDocument, FindUnassociatedLdapHistoriesScanByAddressIpRangesDocument, InsertScanByAddressIpRangesDocument, UpdateScanByAddressIpRangesDocument, ImportDataScanByAddressIpRangesDocument, UpdateManyScanByAddressIpRangesDocument, DeleteScanByAddressIpRangesDocument, RestoreScanByAddressIpRangesDocument, RecycleScanByAddressIpRangesDocument, RestoreArchivedScanByAddressIpRangesDocument, ArchivedScanByAddressIpRangesDocument, AddFileDynamicFieldScanByAddressIpRangesDocument, RemoveFileDynamicFieldScanByAddressIpRangesDocument } from '../gqls'
import { ServiceSingleResultOfScanByAddressIPRange, QueryContextOfScanByAddressIPRange, FilterOfScanByAddressIPRange, ServiceSingleResultOfInt64, ServiceListResultOfScanByAddressIPRange, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanByAddressIPRange, QueryContextOfNetworkCredential, FilterOfNetworkCredential, ServiceListResultOfNetworkCredential, QueryContextOfScanExclude, FilterOfScanExclude, ServiceListResultOfScanExclude, QueryContextOfScanDataFile, FilterOfScanDataFile, ServiceListResultOfScanDataFile, QueryContextOfScanRegistryKey, FilterOfScanRegistryKey, ServiceListResultOfScanRegistryKey, QueryContextOfScanImportOption, FilterOfScanImportOption, ServiceListResultOfScanImportOption, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfLdapHistory, FilterOfLdapHistory, ServiceListResultOfLdapHistory, ScanByAddressIPRangeInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByAddressIpRangeBaseService {
    
public modelName = 'scanByAddressIPRange';
public modelPluralName = 'scanByAddressIPRanges';

	private getScanByAddressIpRangesQuery: GetScanByAddressIpRangesDocument;
	private firstScanByAddressIpRangesQuery: FirstScanByAddressIpRangesDocument;
	private countScanByAddressIpRangesQuery: CountScanByAddressIpRangesDocument;
	private findScanByAddressIpRangesQuery: FindScanByAddressIpRangesDocument;
	private searchScanByAddressIpRangesQuery: SearchScanByAddressIpRangesDocument;
	private exportSchemaScanByAddressIpRangesQuery: ExportSchemaScanByAddressIpRangesDocument;
	private exportDataScanByAddressIpRangesQuery: ExportDataScanByAddressIpRangesDocument;
	private customQueryScanByAddressIpRangesQuery: CustomQueryScanByAddressIpRangesDocument;
	private customQueryIdScanByAddressIpRangesQuery: CustomQueryIdScanByAddressIpRangesDocument;
	private usedScanByAddressIpRangesQuery: UsedScanByAddressIpRangesDocument;
	private existScanByAddressIpRangesQuery: ExistScanByAddressIpRangesDocument;
	private findRecyclesScanByAddressIpRangesQuery: FindRecyclesScanByAddressIpRangesDocument;
	private countRecyclesScanByAddressIpRangesQuery: CountRecyclesScanByAddressIpRangesDocument;
	private readOnlyScanByAddressIpRangesQuery: ReadOnlyScanByAddressIpRangesDocument;
	private findArchivedScanByAddressIpRangesQuery: FindArchivedScanByAddressIpRangesDocument;
	private countAllScanByAddressIpRangesQuery: CountAllScanByAddressIpRangesDocument;
	private findDynamicPropertyFieldsScanByAddressIpRangesQuery: FindDynamicPropertyFieldsScanByAddressIpRangesDocument;
	private findUnassociatedNetworkCredentialsScanByAddressIpRangesQuery: FindUnassociatedNetworkCredentialsScanByAddressIpRangesDocument;
	private findUnassociatedScanExcludesScanByAddressIpRangesQuery: FindUnassociatedScanExcludesScanByAddressIpRangesDocument;
	private findUnassociatedScanDataFilesScanByAddressIpRangesQuery: FindUnassociatedScanDataFilesScanByAddressIpRangesDocument;
	private findUnassociatedScanRegistryKeysScanByAddressIpRangesQuery: FindUnassociatedScanRegistryKeysScanByAddressIpRangesDocument;
	private findUnassociatedScanImportOptionsScanByAddressIpRangesQuery: FindUnassociatedScanImportOptionsScanByAddressIpRangesDocument;
	private findUnassociatedUsersScanByAddressIpRangesQuery: FindUnassociatedUsersScanByAddressIpRangesDocument;
	private findUnassociatedAssetsScanByAddressIpRangesQuery: FindUnassociatedAssetsScanByAddressIpRangesDocument;
	private findUnassociatedAlertsScanByAddressIpRangesQuery: FindUnassociatedAlertsScanByAddressIpRangesDocument;
	private findUnassociatedInventoryHistoriesScanByAddressIpRangesQuery: FindUnassociatedInventoryHistoriesScanByAddressIpRangesDocument;
	private findUnassociatedLdapHistoriesScanByAddressIpRangesQuery: FindUnassociatedLdapHistoriesScanByAddressIpRangesDocument;
	private insertScanByAddressIpRangesMutation: InsertScanByAddressIpRangesDocument;
	private updateScanByAddressIpRangesMutation: UpdateScanByAddressIpRangesDocument;
	private importDataScanByAddressIpRangesMutation: ImportDataScanByAddressIpRangesDocument;
	private updateManyScanByAddressIpRangesMutation: UpdateManyScanByAddressIpRangesDocument;
	private deleteScanByAddressIpRangesMutation: DeleteScanByAddressIpRangesDocument;
	private restoreScanByAddressIpRangesMutation: RestoreScanByAddressIpRangesDocument;
	private recycleScanByAddressIpRangesMutation: RecycleScanByAddressIpRangesDocument;
	private restoreArchivedScanByAddressIpRangesMutation: RestoreArchivedScanByAddressIpRangesDocument;
	private archivedScanByAddressIpRangesMutation: ArchivedScanByAddressIpRangesDocument;
	private addFileDynamicFieldScanByAddressIpRangesMutation: AddFileDynamicFieldScanByAddressIpRangesDocument;
	private removeFileDynamicFieldScanByAddressIpRangesMutation: RemoveFileDynamicFieldScanByAddressIpRangesDocument;

	constructor(private injector: Injector) {
		this.getScanByAddressIpRangesQuery = this.injector.get(GetScanByAddressIpRangesDocument);
		this.firstScanByAddressIpRangesQuery = this.injector.get(FirstScanByAddressIpRangesDocument);
		this.countScanByAddressIpRangesQuery = this.injector.get(CountScanByAddressIpRangesDocument);
		this.findScanByAddressIpRangesQuery = this.injector.get(FindScanByAddressIpRangesDocument);
		this.searchScanByAddressIpRangesQuery = this.injector.get(SearchScanByAddressIpRangesDocument);
		this.exportSchemaScanByAddressIpRangesQuery = this.injector.get(ExportSchemaScanByAddressIpRangesDocument);
		this.exportDataScanByAddressIpRangesQuery = this.injector.get(ExportDataScanByAddressIpRangesDocument);
		this.customQueryScanByAddressIpRangesQuery = this.injector.get(CustomQueryScanByAddressIpRangesDocument);
		this.customQueryIdScanByAddressIpRangesQuery = this.injector.get(CustomQueryIdScanByAddressIpRangesDocument);
		this.usedScanByAddressIpRangesQuery = this.injector.get(UsedScanByAddressIpRangesDocument);
		this.existScanByAddressIpRangesQuery = this.injector.get(ExistScanByAddressIpRangesDocument);
		this.findRecyclesScanByAddressIpRangesQuery = this.injector.get(FindRecyclesScanByAddressIpRangesDocument);
		this.countRecyclesScanByAddressIpRangesQuery = this.injector.get(CountRecyclesScanByAddressIpRangesDocument);
		this.readOnlyScanByAddressIpRangesQuery = this.injector.get(ReadOnlyScanByAddressIpRangesDocument);
		this.findArchivedScanByAddressIpRangesQuery = this.injector.get(FindArchivedScanByAddressIpRangesDocument);
		this.countAllScanByAddressIpRangesQuery = this.injector.get(CountAllScanByAddressIpRangesDocument);
		this.findDynamicPropertyFieldsScanByAddressIpRangesQuery = this.injector.get(FindDynamicPropertyFieldsScanByAddressIpRangesDocument);
		this.findUnassociatedNetworkCredentialsScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedNetworkCredentialsScanByAddressIpRangesDocument);
		this.findUnassociatedScanExcludesScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedScanExcludesScanByAddressIpRangesDocument);
		this.findUnassociatedScanDataFilesScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedScanDataFilesScanByAddressIpRangesDocument);
		this.findUnassociatedScanRegistryKeysScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedScanRegistryKeysScanByAddressIpRangesDocument);
		this.findUnassociatedScanImportOptionsScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedScanImportOptionsScanByAddressIpRangesDocument);
		this.findUnassociatedUsersScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedUsersScanByAddressIpRangesDocument);
		this.findUnassociatedAssetsScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedAssetsScanByAddressIpRangesDocument);
		this.findUnassociatedAlertsScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedAlertsScanByAddressIpRangesDocument);
		this.findUnassociatedInventoryHistoriesScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedInventoryHistoriesScanByAddressIpRangesDocument);
		this.findUnassociatedLdapHistoriesScanByAddressIpRangesQuery = this.injector.get(FindUnassociatedLdapHistoriesScanByAddressIpRangesDocument);
		this.insertScanByAddressIpRangesMutation = this.injector.get(InsertScanByAddressIpRangesDocument);
		this.updateScanByAddressIpRangesMutation = this.injector.get(UpdateScanByAddressIpRangesDocument);
		this.importDataScanByAddressIpRangesMutation = this.injector.get(ImportDataScanByAddressIpRangesDocument);
		this.updateManyScanByAddressIpRangesMutation = this.injector.get(UpdateManyScanByAddressIpRangesDocument);
		this.deleteScanByAddressIpRangesMutation = this.injector.get(DeleteScanByAddressIpRangesDocument);
		this.restoreScanByAddressIpRangesMutation = this.injector.get(RestoreScanByAddressIpRangesDocument);
		this.recycleScanByAddressIpRangesMutation = this.injector.get(RecycleScanByAddressIpRangesDocument);
		this.restoreArchivedScanByAddressIpRangesMutation = this.injector.get(RestoreArchivedScanByAddressIpRangesDocument);
		this.archivedScanByAddressIpRangesMutation = this.injector.get(ArchivedScanByAddressIpRangesDocument);
		this.addFileDynamicFieldScanByAddressIpRangesMutation = this.injector.get(AddFileDynamicFieldScanByAddressIpRangesDocument);
		this.removeFileDynamicFieldScanByAddressIpRangesMutation = this.injector.get(RemoveFileDynamicFieldScanByAddressIpRangesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAddressIPRange> {
    
    		let variables: GetScanByAddressIpRangesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.get));
            }
            else{
                let result:ServiceSingleResultOfScanByAddressIPRange={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAddressIPRange> {
    
    		let variables: FirstScanByAddressIpRangesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByAddressIpRangesBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: FindScanByAddressIpRangesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: SearchScanByAddressIpRangesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByAddressIpRangesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByAddressIpRangesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: CustomQueryScanByAddressIpRangesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: CustomQueryIdScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByAddressIpRangesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: FindRecyclesScanByAddressIpRangesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesScanByAddressIpRangesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyScanByAddressIpRangesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAddressIPRange,
		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAddressIPRange> {
    
    		let variables: FindArchivedScanByAddressIpRangesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllScanByAddressIpRangesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsScanByAddressIpRangesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedNetworkCredentials(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfNetworkCredential> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('networkCredentials', fields, null, [
			GqlSubFieldArg.create('filterOfNetworkCredentials', 'filter'),
			GqlSubFieldArg.create('optionsOfNetworkCredentials', 'options'),
		]),
		])
		extendedVariables['filterOfNetworkCredentials'] = filter;
		extendedVariables['optionsOfNetworkCredentials'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.networkCredentials));
            }
            else{
                let result: ServiceListResultOfNetworkCredential = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNetworkCredentials(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindUnassociatedNetworkCredentialsScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNetworkCredentialsScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedNetworkCredentials));
    	}

	@InjectArgs
	public findAssociatedScanExcludes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanExclude> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanExcludes', fields, null, [
			GqlSubFieldArg.create('filterOfScanExcludes', 'filter'),
			GqlSubFieldArg.create('optionsOfScanExcludes', 'options'),
		]),
		])
		extendedVariables['filterOfScanExcludes'] = filter;
		extendedVariables['optionsOfScanExcludes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanExcludes));
            }
            else{
                let result: ServiceListResultOfScanExclude = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanExcludes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: FindUnassociatedScanExcludesScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanExcludesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedScanExcludes));
    	}

	@InjectArgs
	public findAssociatedScanDataFiles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanDataFile> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanDataFiles', fields, null, [
			GqlSubFieldArg.create('filterOfScanDataFiles', 'filter'),
			GqlSubFieldArg.create('optionsOfScanDataFiles', 'options'),
		]),
		])
		extendedVariables['filterOfScanDataFiles'] = filter;
		extendedVariables['optionsOfScanDataFiles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanDataFiles));
            }
            else{
                let result: ServiceListResultOfScanDataFile = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: FindUnassociatedScanDataFilesScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanDataFilesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedScanDataFiles));
    	}

	@InjectArgs
	public findAssociatedScanRegistryKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanRegistryKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanRegistryKeys', fields, null, [
			GqlSubFieldArg.create('filterOfScanRegistryKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfScanRegistryKeys', 'options'),
		]),
		])
		extendedVariables['filterOfScanRegistryKeys'] = filter;
		extendedVariables['optionsOfScanRegistryKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanRegistryKeys));
            }
            else{
                let result: ServiceListResultOfScanRegistryKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanRegistryKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: FindUnassociatedScanRegistryKeysScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanRegistryKeysScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedScanRegistryKeys));
    	}

	@InjectArgs
	public findAssociatedScanImportOptions(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanImportOption> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanImportOptions', fields, null, [
			GqlSubFieldArg.create('filterOfScanImportOptions', 'filter'),
			GqlSubFieldArg.create('optionsOfScanImportOptions', 'options'),
		]),
		])
		extendedVariables['filterOfScanImportOptions'] = filter;
		extendedVariables['optionsOfScanImportOptions'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanImportOptions));
            }
            else{
                let result: ServiceListResultOfScanImportOption = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanImportOptions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: FindUnassociatedScanImportOptionsScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanImportOptionsScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedScanImportOptions));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedUsers));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedLdapHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLdapHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ldapHistories', fields, null, [
			GqlSubFieldArg.create('filterOfLdapHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfLdapHistories', 'options'),
		]),
		])
		extendedVariables['filterOfLdapHistories'] = filter;
		extendedVariables['optionsOfLdapHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ldapHistories));
            }
            else{
                let result: ServiceListResultOfLdapHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLdapHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: FindUnassociatedLdapHistoriesScanByAddressIpRangesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLdapHistoriesScanByAddressIpRangesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.findUnassociatedLdapHistories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByAddressIPRangeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAddressIPRange> {
    
    		let variables: InsertScanByAddressIpRangesBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAddressIPRange> {
    
    		let variables: UpdateScanByAddressIpRangesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByAddressIpRangesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAddressIPRange,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByAddressIpRangesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedScanByAddressIpRangesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldScanByAddressIpRangesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldScanByAddressIpRangesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldScanByAddressIpRangesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAddressIPRanges.removeFileDynamicField));
    	}
    
}