import { EmailPrototypeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  FilterOfEmailPrototype,
  QueryContextOfEmailPrototype,
  ServiceListResultOfEmailPrototype,
} from '../types';

import { Maybe } from 'graphql/jsutils/Maybe';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['administration.email-setting', 'query-builder'],
  operator: 'or',
})
export class EmailPrototypeCoreService extends EmailPrototypeBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient les fields graphql */
  getFields() {
    return [
      GqlSubField.create('data', [
        GqlField.create('key'),
        GqlField.create('method'),
        GqlField.create('name'),
      ]),
    ];
  }

  @InjectArgs
  findByOnlyType(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototype> {
    if (type != undefined && !Array.isArray(type)) {
      type = [type];
    }
    return this.findByType(fields, type, options, filter, extendedVariables);
  }

  @InjectArgs
  findByTypeAndSelectedTicketCategory(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: Array<Maybe<string>>,
    @Args('ticketCategoryId?') ticketCategoryId?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototype> {
    if (type != undefined && !Array.isArray(type)) {
      type = [type];
    }

    if (ticketCategoryId != undefined && !Array.isArray(ticketCategoryId)) {
      ticketCategoryId = [ticketCategoryId];
    }

    return this.findByTypeAndTicketCategory(
      fields,
      type,
      ticketCategoryId,
      options,
      filter,
      extendedVariables,
    );
  }
}
