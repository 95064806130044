import { Injectable } from '@angular/core';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { GetLicenseGQL, LicenseModelType } from '../graphql/graphql-types';
import { Check } from '../graphql/graphql.service';

/** Représente la licence courrante. */
@Injectable({
  providedIn: 'root',
})
export class LicenseReader {
  private _currentLicense: LicenseModelType;
  constructor(
    private authorizationService: AuthorizationCoreService,
    private getLicenseQuery: GetLicenseGQL,
  ) {}
  private getCurrentLicense(): Observable<LicenseModelType> {
    Check.notNull(this.getLicenseQuery, 'getLicenseQuery');
    const id = this.authorizationService.user.getClaim('tenantId');
    if (id != undefined) {
      const variables = { id: id };

      const query = this.getLicenseQuery.fetch(variables);
      return query
        .pipe(map((result) => result.data.licenses.get.data))
        .pipe(first());
    } else {
      return of(undefined);
    }
  }
  /** Test si le nombre max a été atteinte. */
  public max(
    value: number,
    propertyExpr: (license: LicenseModelType) => number,
  ): boolean {
    return value >= propertyExpr(this._currentLicense);
  }
  public get currentLicense() {
    return this._currentLicense;
  }
  /** Initialise la licence. */
  public async initialize(): Promise<void> {
    this._currentLicense = await this.getCurrentLicense().toPromise();
  }
}
