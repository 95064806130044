<div class="item-field">
  <div class="label">
    {{ label }}<span *ngIf="required" class="required">*</span>
  </div>
  <div [ngClass]="{
      'cl-invalid': loaded === true && errors != undefined,
      value: true
    }">
    <ng-content></ng-content>
  </div>
  <div
    [ngClass]="{
      hint: hintVisibility == undefined || hintVisibility === 'NORMAL',
      hintfull: hintVisibility === 'FULL'
    }"
  >
    {{ hint }}
  </div>
  <ng-container *ngIf="loaded === true && label != undefined">
    <div class="errors" *ngFor="let error of errors | keyvalue">
      <cl-validation-error [error]="error"></cl-validation-error>
    </div>
  </ng-container>
</div>