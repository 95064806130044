import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import {
  CoreGraphQLSource,
  CorePolicyValidator,
  CriteriaHelpers,
} from '@clarilog/core/services2';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  TaskSequenceCoreService,
  TicketTaskCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { FilterOfTaskSequence } from '@clarilog/core/services2/graphql/generated-types/types';
import { Column } from '@clarilog/shared2/models/schema';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { DxGanttComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { CoreWorkSubFormComponent } from '../../form/work-sub-form/work-sub-form.component';
import { alert, custom } from 'devextreme/ui/dialog';
import { TemplatesService } from '../../templates/templates.service';
import { dxButtonOptions } from 'devextreme/ui/button';
import { ServicePermission } from '@clarilog/core/services/graphql/graphql.service';
import { TranslatedFieldHelperService } from '../../translate-field';

export class Task {
  id: number;
  parentId: number;
  title: string;
  start: Date;
  end: Date;
}

export class Dependency {
  id: number;
  predecessorId: number;
  successorId: number;
  type: number;
}

export class Resource {
  id: number;
  text: string;
}

export class ResourceAssignment {
  id: number;
  taskId: number;
  resourceId: number;
}

@Component({
  selector: 'clc-sub-form-gantt',
  templateUrl: './sub-form-gantt.component.html',
  styleUrls: ['./sub-form-gantt.component.scss'],
  preserveWhitespaces: true,
})
export class CoreSubFormGanttComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() state: ModelState;
  @Input() key: string;
  @Input() routeTask: string;
  @Input() columns: Column[];
  isPopupOpen: boolean = false;
  popupVisible: boolean = false;
  taskSequenceBool: boolean = false;
  @Input() startExpr: string = 'start';
  @Input() endExpr: string = 'end';
  @Input() title: string;
  @Input() readOnly: boolean = false;
  @Input() canAdd: boolean = true;
  @Input() canEdit: boolean = true;
  @Input() canRemove: boolean = true;
  @Input() canAddTaskSequence: boolean = true;
  @Input() canVisible: boolean = true;
  loadPanelShow: boolean = false;
  buttonsSource: any;

  private gc: GC;

  buttonOptionsEdit = {
    text: TranslateService.get('edit'),
    type: 'normal',
    name: 'editTask1',
    onClick: (e) => {
      this.onCustomCommand(e, 'editTask1');
    },
  };

  buttonOptionsDelete = {
    text: TranslateService.get('delete'),
    type: 'normal',
    name: 'deleteTask1',
    onClick: (e) => {
      this.onCustomCommand(e, 'deleteTask1');
    },
  };

  buttonOptionsTaskSequence = {
    text: TranslateService.get('ganttAddTaskSequence'),
    type: 'normal',
    name: 'addTaskSequence1',
    onClick: (e) => {
      this.onCustomCommand(e, 'addTaskSequence1');
    },
  };

  @ViewChild(DxGanttComponent) gantt: DxGanttComponent;
  @ViewChild(CoreWorkSubFormComponent) subForm: CoreWorkSubFormComponent;
  @ViewChild(CoreSubFormGanttComponent) subFormgantt: CoreSubFormGanttComponent;

  @Input() serviceRetreiver: ModelFnContext;
  currentSource: ModelDataSourceContext;
  @Input() source: ModelDataSourceContext;
  taskSequenceSource: ModelDataSourceContext;
  @Input() viewGantt: boolean = true;
  /** Indique si on attend l'ouverture du popup */
  waitSave: boolean = false;
  /** Definit les colonne  */
  taskSequenceTypeColumns: Column[] = [
    {
      label: TranslateService.get('entities/taskSequence/name'),
      field: 'name' + this.translateFieldHelperService.getTranslateKey(),
    },
  ];

  service: any;
  _tasks: Task[];
  dependencies: Dependency[];
  resources: Resource[];
  resourceAssignments: ResourceAssignment[];
  selectedRowKey: string;
  url: boolean;

  // Recontruit l'interface utilisateur
  forceGanttRepaintUi: boolean = false;

  constructor(
    public templateService: TemplatesService,
    private route: ActivatedRoute,
    public router: Router,
    public taskSequenceService: TaskSequenceCoreService,
    public ticketTaskService: TicketTaskCoreService,
    public userService: UserCoreService,
    private servicePermission: ServicePermission,
    private policyValidator: CorePolicyValidator,
    private translateFieldHelperService: TranslatedFieldHelperService,
    public injector: Injector,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
    this.onButtonClick = this.onButtonClick.bind(this);
    this.taskSequenceTypeColumns = [
      {
        label: TranslateService.get('entities/taskSequence/name'),
        field:
          'name' + '.' + this.translateFieldHelperService.getTranslateKey(),
        translatable: true,
      },
    ];

    this.buttonsSource = [
      {
        value: TranslateService.get('ganttNewTaskUp'),
        type: 'normal',
        name: 'addTaskUp1',
        onClick: (e) => {
          this.onCustomCommand(e, 'addTaskUp1');
        },
      },
      {
        value: TranslateService.get('ganttNewTaskDown'),
        type: 'normal',
        name: 'addTaskDown1',
        onClick: (e) => {
          this.onCustomCommand(e, 'addTaskDown1');
        },
      },
      {
        value: TranslateService.get('ganttNewSubTask'),
        type: 'normal',
        name: 'addSubTask1',
        onClick: (e) => {
          this.onCustomCommand(e, 'addSubTask1');
        },
      },
      {
        value: TranslateService.get('ganttNewSucessor'),
        type: 'normal',
        name: 'addSuccessor1',
        onClick: (e) => {
          this.onCustomCommand(e, 'addSuccessor1');
        },
      },
    ];

    this.taskSequenceSource = new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filter?: any, options?: any) => {
          let myFilter: FilterOfTaskSequence =
            filter != undefined
              ? CriteriaHelpers.convertDxFilter(
                  CriteriaHelpers.decompile(filter),
                )
              : null;
          if (myFilter == undefined || myFilter == null) {
            myFilter = {};
          }
          let qualification =
            this.state.sharedContext.params.get('qualification');
          if (qualification != undefined) {
            myFilter.qualification = { elemMatch: { contains: qualification } };
          }
          return this.taskSequenceService.find(
            [
              GqlSubField.create('data', [
                GqlField.create('id'),
                GqlSubField.create(
                  'name',
                  this.translateFieldHelperService.translatedFields(),
                ),
              ]),
              GqlField.create('totalCount'),
            ],
            options,
            myFilter,
          );
        },
      }),
    });
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  public getTranslateTitleValue(title) {
    return this.translateFieldHelperService.findValueToShow(title);
  }

  ngAfterViewInit(): void {
    // Vérification si le tabs doit etre sélection

    this.gc.forDispose(
      this.route.queryParams.subscribe((params) => {
        this.service = this.injector.get('TicketTaskCoreService');
        this.setTaskTitle();
        if (params.eventId != undefined && params.tabs == 'task') {
          this.rawModel = this.state.sharedContext.params.get('task-subform');
          this.subForm.editId = params.eventId;
          this.getPermissions();
          setTimeout(() => {
            this.isPopupOpen = true;
          }, 800);
        }
      }),
    );

    if (this.route?.snapshot?.queryParams?.taskId != undefined) {
      // Open

      if (!this.readOnly) {
        this.subForm.editId = this.route?.snapshot?.queryParams?.taskId;
        this.isPopupOpen = true;
      }
    }

    if (this.state != undefined) {
      this.gc.forDispose(
        this.state.on.subscribe((event) => {
          if (event.eventName === 'tab-change') {
            if (event.eventData?.newTab?.itemKey === 'tasks') {
              this.forceGanttRepaintUi = false;
              this.refresh();
            }
          }
        }),
      );
    }
  }

  onContentReady(e) {
    this.forceGanttRepaintUi = true;
    e.component._treeList.option('columnResizingMode', 'widget');
  }

  getQualification() {
    let qualificationField = FormGroupHelpers.formControlByName(
      this.state.form,
      'qualification',
    );

    if (qualificationField != null) {
      return qualificationField.value;
    } else {
      return [];
    }
  }

  totoClick() {
    this.readOnly = true;
  }

  onButtonClick(e) {
    this.onCustomCommand(e, 'addTask1');
  }

  refresh() {
    if (
      this.gantt?.instance != undefined &&
      this.forceGanttRepaintUi === false
    ) {
      this.gantt.instance.repaint();
    }
    this.currentSource.datasource.reload();
  }

  getTime(date) {
    var dd = String(date.getDate()).padStart(2, '0');
    var mm = String(date.getMonth() + 1).padStart(2, '0'); //January is 0!
    var yyyy = date.getFullYear();

    let ddmmyyyy = dd + '/' + mm + '/' + yyyy;

    let heures = date.getHours();
    let minutes = date.getMinutes();

    return ddmmyyyy + ' ' + heures + ':' + minutes;
  }

  async openSubForm(taskSequence: boolean = false) {
    this.setTaskTitle();
    // Vérification si le modele est valide
    this.waitSave = false;
    let id = this.route.snapshot.paramMap.get('id') || this.state.id;
    if (id == undefined) {
      if (FormGroupHelpers.valid(this.state.formComponent.form) == false) {
        // Validation du formulaire
        alert(
          TranslateService.get('globals/formNotValid'),
          TranslateService.get('globals/warning'),
        );
        return false;
      } else {
        let confirm = await CoreSubFormGanttComponent.confirmSaveDialog(
          TranslateService.get('confirm-title'),
          TranslateService.get('globals/confirm-refresh'),
          TranslateService.get('save'),
          TranslateService.get('cancel'),
        );
        if (confirm) {
          this.waitSave = true;

          this.state.formComponent.save({ close: false }).then((result) => {
            if (result != null) {
              if (!taskSequence) {
                this.isPopupOpen = true;
              }
              id = this.state.id;
              return true;
            }
          });
        }
      }
    } else if (!taskSequence) {
      this.isPopupOpen = true;
      return true;
    }
    return true;
  }

  public static async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: (e) => {
          return true;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
    ];

    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  onSelect(e) {
    this.loadPanelShow = true;
    //On recupère l'id du ticket via l'url
    let id = this.route.snapshot.paramMap.get('id');
    if (id == undefined) {
      id = this.state.id;
    }
    //On recupère l'id de la sequence selectionnée dans le popup
    let taskSequenceId = e[0].id;
    //Si nous sommes sur la fiche d'un modèle d'incident
    if (this.router.url.includes('incident-model')) {
      //Appel de la requète
      this.taskSequenceService
        .addLinkIncidentModels(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          taskSequenceId,
          id,
        )
        .subscribe((result) => {
          this.loadPanelShow = false;

          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'reload-tabs',
              eventData: undefined,
            });
          }

          if (result) {
            notify(
              TranslateService.get('globals/addTaskSequence'),
              'success',
              5000,
            );
            //Rafraîchi la source de données avec les données nouvellement ajouté
            this.refresh();
          } else {
            notify(TranslateService.get('actionError'), 'error', 5000);
          }
        });
    } else {
      //Appel de la requète sur la fiche d'un tiket
      this.taskSequenceService
        .addLinkTickets(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          id,
          taskSequenceId,
        )
        .subscribe((result) => {
          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'reload-tabs',
              eventData: undefined,
            });
          }

          if (result) {
            notify(
              TranslateService.get('globals/addTaskSequence'),
              'success',
              5000,
            );
            //Rafraîchi la source de données avec les données nouvellement ajouté
            this.refresh();
            this.loadPanelShow = false;
          } else {
            notify(TranslateService.get('actionError'), 'error', 5000);
          }
        });
    }

    //On ferme le pop up
    this.taskSequenceBool = true;
    this.popupVisible = false;
  }

  async ngOnInit() {
    let id = this.route.snapshot.paramMap.get('id');
    if (id == undefined) {
      id = this.state.id;
    } else {
      this.setTaskTitle();
    }

    this.source.datasource.pageSize(5000);

    if (this.router.url.includes('task-sequence')) {
      this.url = false;
    }
    this.rawModel = this.state.sharedContext.params.get(this.key + '-subform');
    if (id != undefined && this.source != undefined) {
      this.currentSource = this.source;
      this.state.sharedContext.params.set(this.fieldName, () => id);
    } else {
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource([]),
      });
      let sub = this.state.formComponent.onSaved.subscribe((res) => {
        sub.unsubscribe();
        this.currentSource = this.source;
        this.state.sharedContext.params.set(this.fieldName, () => res);
        this.state.id = res;
        if (!this.taskSequenceBool) {
          this.isPopupOpen = true;
        }
      });
    }

    this.service = this.serviceRetreiver.fnCall();
    this.getPermissions();
  }

  num: number = 10;

  insertAt(array, index, ...elementsArray) {
    array.splice(index, 0, ...elementsArray);
  }

  onCustomCommand(e, origin = null) {
    if (
      (e == undefined || e == null) &&
      (origin == undefined || origin == null)
    ) {
      return;
    }

    if (
      (e != null || e != undefined) &&
      (origin != undefined || origin != null)
    ) {
      e.name = origin;
    }

    if (
      e.name === 'addTask1' ||
      e.name === 'addSubTask1' ||
      e.name === 'addTaskUp1' ||
      e.name === 'addTaskDown1' ||
      e.name === 'addSuccessor1'
    ) {
      this.openSubForm(false).then(() => {});

      // Position last si aucune sélection
      if (e.name === 'addTask1') {
        let unSub = this.state.subFormReady.subscribe(
          (data: { gc: GC; subModelState: ModelState; key: string }) => {
            unSub.unsubscribe();

            let orderControl = FormGroupHelpers.formControlByName(
              data.subModelState.form,
              'order',
            );
            if (orderControl != undefined) {
              orderControl.setValue(0);
              orderControl.markAsDirty();
              data.subModelState.sharedContext.entry.set('order', () => 0);
            }
          },
        );
      } else {
        let unSub = this.state.subFormReady.subscribe(
          (data: { gc: GC; subModelState: ModelState; key: string }) => {
            unSub.unsubscribe();
            if (data.key === this.key) {
              this.service
                .get(
                  [
                    GqlSubField.create('data', [
                      GqlField.create('id'),
                      GqlField.create('parentId'),
                      GqlField.create('order'),
                    ]),
                  ],
                  this.selectedRowKey,
                )
                .subscribe((res) => {
                  //data.subModelState.sharedContext.params.set('parentId', () => (e.name === 'addSubTask1') ? res.data.id : res.data.parentId);

                  let control = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'parentId',
                  );
                  let orderControl = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'order',
                  );

                  let predecessorIdControl = FormGroupHelpers.formControlByName(
                    data.subModelState.form,
                    'predecessorId',
                  );

                  if (control != undefined) {
                    if (e.name === 'addSuccessor1') {
                      predecessorIdControl.setValue(res.data.id);
                      predecessorIdControl.markAsDirty();
                    }
                  } else {
                    console.error('control undefined');
                  }

                  if (control != undefined) {
                    control.setValue(
                      e.name === 'addSubTask1'
                        ? res.data.id
                        : res.data.parentId,
                    );
                    control.markAsDirty();
                  } else {
                    console.error('control undefined');
                  }

                  if (orderControl != undefined) {
                    orderControl.setValue(
                      e.name === 'addSubTask1'
                        ? 1
                        : res.data.order +
                            (e.name === 'addTaskDown1' ||
                            e.name === 'addSuccessor1'
                              ? 1
                              : 0),
                    );
                    orderControl.markAsDirty();
                  } else {
                    console.error('orderControl undefined');
                  }
                });
            }
          },
        );
      }
    } else if (e.name === 'deleteTask1') {
      this.service
        .delete(ModelFieldCompilerService.createServiceSingleResultScalar(), [
          this.selectedRowKey,
        ])
        .subscribe((res) => {
          this.refresh();
          if (this.state != undefined) {
            this.state.on.emit({
              eventName: 'refresh-tabs',
              eventData: 'gantt-delete',
            });
          }
        });
    } else if (e.name === 'editTask1') {
      if (this.selectedRowKey != undefined) {
        this.subForm.editId = this.selectedRowKey;
        this.isPopupOpen = true;
      }
    } else if (e.name === 'addTaskSequence1') {
      this.openSubForm(true).then((result) => {
        if (result) {
          this.taskSequenceBool = true;
          this.popupVisible = true;
        }
      });
    } else if (e.name === 'moveUp1') {
      this.ticketTaskService
        .moveUp(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedRowKey,
        )
        .subscribe((res) => {
          this.refresh();
        });
    } else if (e.name === 'moveDown1') {
      this.ticketTaskService
        .moveDown(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedRowKey,
        )
        .subscribe((res) => {
          this.refresh();
        });
    }
  }

  rawModel: any;

  @Input() fieldName: string;
  @Input() number: string;
  /** Obtient ou définit les rules pour le designer */
  @Input() rules: ModelFnContext;

  saved(e) {
    this.currentSource.datasource.reload();
    this.subForm.editId = null;

    this.state.on.emit({
      eventName: 'reload-tabs',
      eventData: undefined,
    });
  }

  onTaskDblClick(e) {
    e.cancel = !this.canEdit;
  }

  onTaskEditDialogShowing(e) {
    this.setTaskTitle();

    e.cancel = true;
    this.subForm.editId = this.selectedRowKey;
    this.isPopupOpen = true;
  }

  onContextMenuPreparing(e) {
    e.cancel = this.readOnly;
  }

  setTaskTitle() {
    let taskNumber = '';
    this.gantt;
    this.subForm;
    let unSub = this.state.subFormReady.subscribe(
      (data: { gc: GC; subModelState: ModelState; key: string }) => {
        if (
          this.state?.sharedContext?.params?.get('qualification') === 'problem'
        ) {
          let visibleForUser = FormGroupHelpers.formControlByName(
            data.subModelState.form,
            'visibleForUser',
          );
          if (visibleForUser != undefined) {
            visibleForUser.invisible();
          }
        }

        unSub.unsubscribe();
        if (this.selectedRowKey != undefined) {
          this.service.get(
            [
              GqlSubField.create('data', [
                GqlField.create('id'),
                GqlField.create('taskNumber'),
              ]),
            ],
            this.selectedRowKey,
          );
        }
        let taskNumberField = FormGroupHelpers.formControlByName(
          data.subModelState.form,
          'taskNumber',
        );

        taskNumber = taskNumberField?.value;
        this.taskTitleSave();
        if (taskNumber != undefined && taskNumber != '' && taskNumber != null) {
          this.title = this.title + ' n° ' + taskNumber;
        }
      },
    );
  }

  taskTitleSave() {
    if (this.title != TranslateService.get('entities/task/_title/singular')) {
      this.title = TranslateService.get('entities/task/_title/singular');
    }
  }
  getPermissions() {
    let policies = this.servicePermission.getAuthorizations(this.service);
    if (policies != undefined) {
      let operator = 'and';
      if (policies['operator'] != undefined) {
        operator = policies['operator'];
        policies = policies['policies'];
      } else if (policies['policies'] != undefined) {
        policies = policies['policies'];
      }
      let canAddTmp = false;
      let canDeleteTmp = false;
      let canEditTmp = false;
      for (let policy of policies) {
        if (operator == 'or') {
          if (canAddTmp == false) {
            canAddTmp =
              this.canAdd &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
              );
          }
          if (canDeleteTmp == false) {
            canDeleteTmp =
              this.canRemove &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
              );
          }
          if (canEditTmp == false) {
            canEditTmp =
              this.canEdit &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
              );
          }
        } else {
          this.canAdd =
            this.canAdd &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
            );

          this.canRemove =
            this.canRemove &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
            );
          this.canEdit =
            this.canEdit &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
            );
        }
      }

      if (operator == 'or') {
        this.canAdd = canAddTmp;
        this.canRemove = canDeleteTmp;
        this.canEdit = canEditTmp;
      }
    }
  }

  getRowClass(rowData: any) {
    // Condition pour vérifier la valeur dans la rangée
    if (
      (rowData.data?.isGreyList != undefined && rowData.data?.isGreyList) ||
      (rowData?.isGreyList != undefined && rowData?.isGreyList)
    ) {
      return 'cell-greyvalue';
    }
    return '';
  }
}
