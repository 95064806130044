import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { DynamicPropertyListBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs';
import { ServiceListResultOfDynamicPropertyListValue } from '../types';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-dynamic-property')
export class DynamicPropertyListCoreService extends DynamicPropertyListBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  //   @InjectArgs
  //   findListValue(
  //     @Args('dynamicListId') dynamicListId,
  //   ): Observable<ServiceListResultOfDynamicPropertyListValue> {
  //     let fields = this.translatedFieldHelperService.translatedFields();
  //     fields.push(GqlField.create('id'));
  //     return this.findDynamicListValue(
  //       [GqlSubField.create('data', fields)],
  //       dynamicListId,
  //     );
  //   }
}
