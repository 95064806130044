import { EmailPrototypeLogBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfEmailPrototype,
  FilterOfEmailPrototypeLog,
  QueryContextOfEmailPrototype,
  QueryContextOfEmailPrototypeLog,
  ServiceListResultOfEmailPrototype,
  ServiceListResultOfEmailPrototypeLog,
} from '../types';
import { Maybe } from 'graphql/jsutils/Maybe';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize('administration.email-setting')
export class EmailPrototypeLogCoreService extends EmailPrototypeLogBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  findByOnlyType(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('type?') type?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfEmailPrototype,
    @Args('filter?') filter?: FilterOfEmailPrototype,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailPrototypeLog> {
    if (type != undefined && !Array.isArray(type)) {
      type = [type];
    }
    return this.findByType(fields, type, options, filter, extendedVariables);
  }
}
