import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { SftpConnectorHistoryBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfSftpConnectorHistory,
  QueryContextOfSftpConnectorHistory,
  ServiceListResultOfSftpConnectorHistory,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { field } from 'devexpress-reporting/scopes/reporting-designer-controls-pivotGrid-metadata';

@Authorize('administration.sftp-connector')
@Injectable({ providedIn: 'root' })
export class SftpConnectorHistoryCoreService extends SftpConnectorHistoryBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient l'historique */
  @InjectArgs
  findById(
    @Args('id') id: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfSftpConnectorHistory,
    @Args('filter?') filter?: FilterOfSftpConnectorHistory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfSftpConnectorHistory> {
    if (id != undefined) {
      var filterById: FilterOfSftpConnectorHistory = {
        sftpConnectorId: { eq: id },
      };

      if (filter != undefined) {
        filter.and = [filterById];
      }

      return this.find(fields, options, filter, extendedVariables);
    }
  }
}
