import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetResultScanAzureAdsBaseVariables, FirstResultScanAzureAdsBaseVariables, CountResultScanAzureAdsBaseVariables, FindResultScanAzureAdsBaseVariables, SearchResultScanAzureAdsBaseVariables, ExportSchemaResultScanAzureAdsBaseVariables, ExportDataResultScanAzureAdsBaseVariables, CustomQueryResultScanAzureAdsBaseVariables, CustomQueryIdResultScanAzureAdsBaseVariables, UsedResultScanAzureAdsBaseVariables, ExistResultScanAzureAdsBaseVariables, InsertResultScanAzureAdsBaseVariables, UpdateResultScanAzureAdsBaseVariables, ImportDataResultScanAzureAdsBaseVariables, UpdateManyResultScanAzureAdsBaseVariables, DeleteResultScanAzureAdsBaseVariables } from '../gqls'
import { GetResultScanAzureAdsDocument, FirstResultScanAzureAdsDocument, CountResultScanAzureAdsDocument, FindResultScanAzureAdsDocument, SearchResultScanAzureAdsDocument, ExportSchemaResultScanAzureAdsDocument, ExportDataResultScanAzureAdsDocument, CustomQueryResultScanAzureAdsDocument, CustomQueryIdResultScanAzureAdsDocument, UsedResultScanAzureAdsDocument, ExistResultScanAzureAdsDocument, InsertResultScanAzureAdsDocument, UpdateResultScanAzureAdsDocument, ImportDataResultScanAzureAdsDocument, UpdateManyResultScanAzureAdsDocument, DeleteResultScanAzureAdsDocument } from '../gqls'
import { ServiceSingleResultOfResultScanAzureAd, QueryContextOfResultScanAzureAd, FilterOfResultScanAzureAd, ServiceSingleResultOfInt64, ServiceListResultOfResultScanAzureAd, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ResultScanAzureAdInput, FieldUpdateOperatorOfResultScanAzureAd } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ResultScanAzureAdBaseService {
    
public modelName = 'resultScanAzureAd';
public modelPluralName = 'resultScanAzureAds';

	private getResultScanAzureAdsQuery: GetResultScanAzureAdsDocument;
	private firstResultScanAzureAdsQuery: FirstResultScanAzureAdsDocument;
	private countResultScanAzureAdsQuery: CountResultScanAzureAdsDocument;
	private findResultScanAzureAdsQuery: FindResultScanAzureAdsDocument;
	private searchResultScanAzureAdsQuery: SearchResultScanAzureAdsDocument;
	private exportSchemaResultScanAzureAdsQuery: ExportSchemaResultScanAzureAdsDocument;
	private exportDataResultScanAzureAdsQuery: ExportDataResultScanAzureAdsDocument;
	private customQueryResultScanAzureAdsQuery: CustomQueryResultScanAzureAdsDocument;
	private customQueryIdResultScanAzureAdsQuery: CustomQueryIdResultScanAzureAdsDocument;
	private usedResultScanAzureAdsQuery: UsedResultScanAzureAdsDocument;
	private existResultScanAzureAdsQuery: ExistResultScanAzureAdsDocument;
	private insertResultScanAzureAdsMutation: InsertResultScanAzureAdsDocument;
	private updateResultScanAzureAdsMutation: UpdateResultScanAzureAdsDocument;
	private importDataResultScanAzureAdsMutation: ImportDataResultScanAzureAdsDocument;
	private updateManyResultScanAzureAdsMutation: UpdateManyResultScanAzureAdsDocument;
	private deleteResultScanAzureAdsMutation: DeleteResultScanAzureAdsDocument;

	constructor(private injector: Injector) {
		this.getResultScanAzureAdsQuery = this.injector.get(GetResultScanAzureAdsDocument);
		this.firstResultScanAzureAdsQuery = this.injector.get(FirstResultScanAzureAdsDocument);
		this.countResultScanAzureAdsQuery = this.injector.get(CountResultScanAzureAdsDocument);
		this.findResultScanAzureAdsQuery = this.injector.get(FindResultScanAzureAdsDocument);
		this.searchResultScanAzureAdsQuery = this.injector.get(SearchResultScanAzureAdsDocument);
		this.exportSchemaResultScanAzureAdsQuery = this.injector.get(ExportSchemaResultScanAzureAdsDocument);
		this.exportDataResultScanAzureAdsQuery = this.injector.get(ExportDataResultScanAzureAdsDocument);
		this.customQueryResultScanAzureAdsQuery = this.injector.get(CustomQueryResultScanAzureAdsDocument);
		this.customQueryIdResultScanAzureAdsQuery = this.injector.get(CustomQueryIdResultScanAzureAdsDocument);
		this.usedResultScanAzureAdsQuery = this.injector.get(UsedResultScanAzureAdsDocument);
		this.existResultScanAzureAdsQuery = this.injector.get(ExistResultScanAzureAdsDocument);
		this.insertResultScanAzureAdsMutation = this.injector.get(InsertResultScanAzureAdsDocument);
		this.updateResultScanAzureAdsMutation = this.injector.get(UpdateResultScanAzureAdsDocument);
		this.importDataResultScanAzureAdsMutation = this.injector.get(ImportDataResultScanAzureAdsDocument);
		this.updateManyResultScanAzureAdsMutation = this.injector.get(UpdateManyResultScanAzureAdsDocument);
		this.deleteResultScanAzureAdsMutation = this.injector.get(DeleteResultScanAzureAdsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResultScanAzureAd> {
    
    		let variables: GetResultScanAzureAdsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.get));
            }
            else{
                let result:ServiceSingleResultOfResultScanAzureAd={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfResultScanAzureAd,
		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResultScanAzureAd> {
    
    		let variables: FirstResultScanAzureAdsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountResultScanAzureAdsBaseVariables = {
    			filter: filter
    		}
    				return this.countResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfResultScanAzureAd,
		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResultScanAzureAd> {
    
    		let variables: FindResultScanAzureAdsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfResultScanAzureAd,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResultScanAzureAd> {
    
    		let variables: SearchResultScanAzureAdsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaResultScanAzureAdsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataResultScanAzureAdsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfResultScanAzureAd,
		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResultScanAzureAd> {
    
    		let variables: CustomQueryResultScanAzureAdsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfResultScanAzureAd,
		@Args('filter?') filter?: FilterOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResultScanAzureAd> {
    
    		let variables: CustomQueryIdResultScanAzureAdsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedResultScanAzureAdsBaseVariables = {
    			ids: ids
    		}
    				return this.usedResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistResultScanAzureAdsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existResultScanAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ResultScanAzureAdInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResultScanAzureAd> {
    
    		let variables: InsertResultScanAzureAdsBaseVariables = {
    			entity: entity
    		}
    				return this.insertResultScanAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResultScanAzureAd> {
    
    		let variables: UpdateResultScanAzureAdsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateResultScanAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataResultScanAzureAdsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataResultScanAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfResultScanAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyResultScanAzureAdsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyResultScanAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteResultScanAzureAdsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteResultScanAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resultScanAzureAds.delete));
    	}
    
}