import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNetworkAdaptersBaseVariables, FirstNetworkAdaptersBaseVariables, CountNetworkAdaptersBaseVariables, FindNetworkAdaptersBaseVariables, SearchNetworkAdaptersBaseVariables, ExportSchemaNetworkAdaptersBaseVariables, ExportDataNetworkAdaptersBaseVariables, CustomQueryNetworkAdaptersBaseVariables, CustomQueryIdNetworkAdaptersBaseVariables, UsedNetworkAdaptersBaseVariables, ExistNetworkAdaptersBaseVariables } from '../gqls'
import { GetNetworkAdaptersDocument, FirstNetworkAdaptersDocument, CountNetworkAdaptersDocument, FindNetworkAdaptersDocument, SearchNetworkAdaptersDocument, ExportSchemaNetworkAdaptersDocument, ExportDataNetworkAdaptersDocument, CustomQueryNetworkAdaptersDocument, CustomQueryIdNetworkAdaptersDocument, UsedNetworkAdaptersDocument, ExistNetworkAdaptersDocument } from '../gqls'
import { ServiceSingleResultOfNetworkAdapter, QueryContextOfNetworkAdapter, FilterOfNetworkAdapter, ServiceSingleResultOfInt64, ServiceListResultOfNetworkAdapter, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NetworkAdapterBaseService {
    
public modelName = 'networkAdapter';
public modelPluralName = 'networkAdapters';

	private getNetworkAdaptersQuery: GetNetworkAdaptersDocument;
	private firstNetworkAdaptersQuery: FirstNetworkAdaptersDocument;
	private countNetworkAdaptersQuery: CountNetworkAdaptersDocument;
	private findNetworkAdaptersQuery: FindNetworkAdaptersDocument;
	private searchNetworkAdaptersQuery: SearchNetworkAdaptersDocument;
	private exportSchemaNetworkAdaptersQuery: ExportSchemaNetworkAdaptersDocument;
	private exportDataNetworkAdaptersQuery: ExportDataNetworkAdaptersDocument;
	private customQueryNetworkAdaptersQuery: CustomQueryNetworkAdaptersDocument;
	private customQueryIdNetworkAdaptersQuery: CustomQueryIdNetworkAdaptersDocument;
	private usedNetworkAdaptersQuery: UsedNetworkAdaptersDocument;
	private existNetworkAdaptersQuery: ExistNetworkAdaptersDocument;

	constructor(private injector: Injector) {
		this.getNetworkAdaptersQuery = this.injector.get(GetNetworkAdaptersDocument);
		this.firstNetworkAdaptersQuery = this.injector.get(FirstNetworkAdaptersDocument);
		this.countNetworkAdaptersQuery = this.injector.get(CountNetworkAdaptersDocument);
		this.findNetworkAdaptersQuery = this.injector.get(FindNetworkAdaptersDocument);
		this.searchNetworkAdaptersQuery = this.injector.get(SearchNetworkAdaptersDocument);
		this.exportSchemaNetworkAdaptersQuery = this.injector.get(ExportSchemaNetworkAdaptersDocument);
		this.exportDataNetworkAdaptersQuery = this.injector.get(ExportDataNetworkAdaptersDocument);
		this.customQueryNetworkAdaptersQuery = this.injector.get(CustomQueryNetworkAdaptersDocument);
		this.customQueryIdNetworkAdaptersQuery = this.injector.get(CustomQueryIdNetworkAdaptersDocument);
		this.usedNetworkAdaptersQuery = this.injector.get(UsedNetworkAdaptersDocument);
		this.existNetworkAdaptersQuery = this.injector.get(ExistNetworkAdaptersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkAdapter> {
    
    		let variables: GetNetworkAdaptersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.get));
            }
            else{
                let result:ServiceSingleResultOfNetworkAdapter={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkAdapter,
		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkAdapter> {
    
    		let variables: FirstNetworkAdaptersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNetworkAdaptersBaseVariables = {
    			filter: filter
    		}
    				return this.countNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkAdapter,
		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkAdapter> {
    
    		let variables: FindNetworkAdaptersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNetworkAdapter,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkAdapter> {
    
    		let variables: SearchNetworkAdaptersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNetworkAdaptersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNetworkAdaptersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNetworkAdapter,
		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkAdapter> {
    
    		let variables: CustomQueryNetworkAdaptersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNetworkAdapter,
		@Args('filter?') filter?: FilterOfNetworkAdapter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkAdapter> {
    
    		let variables: CustomQueryIdNetworkAdaptersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNetworkAdaptersBaseVariables = {
    			ids: ids
    		}
    				return this.usedNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNetworkAdaptersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNetworkAdaptersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkAdapters.exist));
    	}
    
    
}