import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAsyncTasksBaseVariables, FirstAsyncTasksBaseVariables, CountAsyncTasksBaseVariables, FindAsyncTasksBaseVariables, SearchAsyncTasksBaseVariables, ExportSchemaAsyncTasksBaseVariables, ExportDataAsyncTasksBaseVariables, CustomQueryAsyncTasksBaseVariables, CustomQueryIdAsyncTasksBaseVariables, UsedAsyncTasksBaseVariables, ExistAsyncTasksBaseVariables, InsertAsyncTasksBaseVariables, UpdateAsyncTasksBaseVariables, ImportDataAsyncTasksBaseVariables, UpdateManyAsyncTasksBaseVariables, DeleteAsyncTasksBaseVariables } from '../gqls'
import { GetAsyncTasksDocument, FirstAsyncTasksDocument, CountAsyncTasksDocument, FindAsyncTasksDocument, SearchAsyncTasksDocument, ExportSchemaAsyncTasksDocument, ExportDataAsyncTasksDocument, CustomQueryAsyncTasksDocument, CustomQueryIdAsyncTasksDocument, UsedAsyncTasksDocument, ExistAsyncTasksDocument, InsertAsyncTasksDocument, UpdateAsyncTasksDocument, ImportDataAsyncTasksDocument, UpdateManyAsyncTasksDocument, DeleteAsyncTasksDocument } from '../gqls'
import { ServiceSingleResultOfAsyncTask, QueryContextOfAsyncTask, FilterOfAsyncTask, ServiceSingleResultOfInt64, ServiceListResultOfAsyncTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AsyncTaskInput, FieldUpdateOperatorOfAsyncTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AsyncTaskBaseService {
    
public modelName = 'asyncTask';
public modelPluralName = 'asyncTasks';

	private getAsyncTasksQuery: GetAsyncTasksDocument;
	private firstAsyncTasksQuery: FirstAsyncTasksDocument;
	private countAsyncTasksQuery: CountAsyncTasksDocument;
	private findAsyncTasksQuery: FindAsyncTasksDocument;
	private searchAsyncTasksQuery: SearchAsyncTasksDocument;
	private exportSchemaAsyncTasksQuery: ExportSchemaAsyncTasksDocument;
	private exportDataAsyncTasksQuery: ExportDataAsyncTasksDocument;
	private customQueryAsyncTasksQuery: CustomQueryAsyncTasksDocument;
	private customQueryIdAsyncTasksQuery: CustomQueryIdAsyncTasksDocument;
	private usedAsyncTasksQuery: UsedAsyncTasksDocument;
	private existAsyncTasksQuery: ExistAsyncTasksDocument;
	private insertAsyncTasksMutation: InsertAsyncTasksDocument;
	private updateAsyncTasksMutation: UpdateAsyncTasksDocument;
	private importDataAsyncTasksMutation: ImportDataAsyncTasksDocument;
	private updateManyAsyncTasksMutation: UpdateManyAsyncTasksDocument;
	private deleteAsyncTasksMutation: DeleteAsyncTasksDocument;

	constructor(private injector: Injector) {
		this.getAsyncTasksQuery = this.injector.get(GetAsyncTasksDocument);
		this.firstAsyncTasksQuery = this.injector.get(FirstAsyncTasksDocument);
		this.countAsyncTasksQuery = this.injector.get(CountAsyncTasksDocument);
		this.findAsyncTasksQuery = this.injector.get(FindAsyncTasksDocument);
		this.searchAsyncTasksQuery = this.injector.get(SearchAsyncTasksDocument);
		this.exportSchemaAsyncTasksQuery = this.injector.get(ExportSchemaAsyncTasksDocument);
		this.exportDataAsyncTasksQuery = this.injector.get(ExportDataAsyncTasksDocument);
		this.customQueryAsyncTasksQuery = this.injector.get(CustomQueryAsyncTasksDocument);
		this.customQueryIdAsyncTasksQuery = this.injector.get(CustomQueryIdAsyncTasksDocument);
		this.usedAsyncTasksQuery = this.injector.get(UsedAsyncTasksDocument);
		this.existAsyncTasksQuery = this.injector.get(ExistAsyncTasksDocument);
		this.insertAsyncTasksMutation = this.injector.get(InsertAsyncTasksDocument);
		this.updateAsyncTasksMutation = this.injector.get(UpdateAsyncTasksDocument);
		this.importDataAsyncTasksMutation = this.injector.get(ImportDataAsyncTasksDocument);
		this.updateManyAsyncTasksMutation = this.injector.get(UpdateManyAsyncTasksDocument);
		this.deleteAsyncTasksMutation = this.injector.get(DeleteAsyncTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsyncTask> {
    
    		let variables: GetAsyncTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.get));
            }
            else{
                let result:ServiceSingleResultOfAsyncTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsyncTask,
		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsyncTask> {
    
    		let variables: FirstAsyncTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAsyncTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsyncTask,
		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsyncTask> {
    
    		let variables: FindAsyncTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAsyncTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsyncTask> {
    
    		let variables: SearchAsyncTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAsyncTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAsyncTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAsyncTask,
		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsyncTask> {
    
    		let variables: CustomQueryAsyncTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAsyncTask,
		@Args('filter?') filter?: FilterOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsyncTask> {
    
    		let variables: CustomQueryIdAsyncTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAsyncTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAsyncTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAsyncTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AsyncTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsyncTask> {
    
    		let variables: InsertAsyncTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertAsyncTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsyncTask> {
    
    		let variables: UpdateAsyncTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAsyncTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAsyncTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAsyncTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAsyncTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAsyncTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAsyncTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAsyncTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAsyncTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.asyncTasks.delete));
    	}
    
}