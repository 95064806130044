import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreModelCompilerService } from '../../../services/compiler/model-compiler.service';
import { CoreManageListComponent } from '../../list/manage-list/manage-list.component';
import model from './model.json';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { EmailPrototypeLogCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype-log.service';
import DataSource from 'devextreme/data/data_source';
import { TranslateService } from '@clarilog/shared/services';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import notify from 'devextreme/ui/notify';
import { alert } from 'devextreme/ui/dialog';
import purgeLogModel from './model-purge-log.json';

/** Représente la classe du composent cl-email-prototype-log-list. */
@Component({
  selector: 'clc-email-prototype-log-list',
  templateUrl: './email-prototype-log-list.component.html',
  styleUrls: ['./email-prototype-log-list.component.scss'],
})
export class CoreEmailPrototypeLogListComponent implements OnInit {
  model: ModelState;
  state: Observable<object>;
  type: any;
  displayPopUp: boolean = false;
  purgeModel: any;
  purgeDataSource: DataSource;
  /** Obtient ou définit la liste. */
  @ViewChild(CoreManageListComponent, { static: true })
  manageList: CoreManageListComponent;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    public emailPrototypeLogService: EmailPrototypeLogCoreService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.type = data.type;
    });
  }

  async ngOnInit() {
    this.purgeModel = purgeLogModel;
    this.modelCompilerService.compile(model).subscribe((model) => {
      this.model = model;
      if (this.type == undefined) this.type = 'alarm';
      this.model.sharedContext.params.set('type', () => this.type);
    });

    this.purgeDataSource = new DataSource({
      load: (option) => {
        return [
          {
            id: 'all',
            name: TranslateService.get('globals/purgeAll'),
          },
          {
            id: 'successOnly',
            name: TranslateService.get('globals/purgeSuccessOnly'),
          },
          {
            id: 'date',
            name: TranslateService.get('globals/purgeDate'),
          },
        ];
      },
    });
  }

  onItemClick(e) {
    let getAction = e.itemData.id;
    if (getAction == 'date') {
      this.displayPopUp = true;
    } else {
      this.executeAction(getAction);
    }
  }

  retrieveFormData(e) {
    let getAction = 'all';
    if (e['enabled']) {
      getAction = 'successOnly';
    }
    this.displayPopUp = false;
    this.executeAction(getAction, e.start, e.end);
  }

  private executeAction(action: string, start: Date = null, end: Date = null) {
    alert(
      TranslateService.get('globals/purgeWarning'),
      TranslateService.get('globals/warning'),
    ).then((r) => {
      this.emailPrototypeLogService
        .purgeTicketEmailPrototypeLog(
          [GqlField.create('data')],
          this.type,
          start,
          end,
          action,
        )
        .subscribe((response) => {
          if (response.data) {
            notify(TranslateService.get('actionSuccess'), 'success', 5000);
            this.manageList.refresh();
          } else {
            notify(TranslateService.get('actionError'), 'error', 5000);
          }
        });
    });
  }
}
