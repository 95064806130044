import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetConcernedProjectsBaseVariables, FirstConcernedProjectsBaseVariables, CountConcernedProjectsBaseVariables, FindConcernedProjectsBaseVariables, SearchConcernedProjectsBaseVariables, ExportSchemaConcernedProjectsBaseVariables, ExportDataConcernedProjectsBaseVariables, CustomQueryConcernedProjectsBaseVariables, CustomQueryIdConcernedProjectsBaseVariables, FindUnassociatedSecurityGroupsConcernedProjectsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsConcernedProjectsBaseVariables, UsedConcernedProjectsBaseVariables, ExistConcernedProjectsBaseVariables, FindUnassociatedTicketsConcernedProjectsBaseVariables, InsertConcernedProjectsBaseVariables, UpdateConcernedProjectsBaseVariables, ImportDataConcernedProjectsBaseVariables, UpdateManyConcernedProjectsBaseVariables, DeleteConcernedProjectsBaseVariables, AddSecurityGroupConcernedProjectsBaseVariables, RemoveSecurityGroupConcernedProjectsBaseVariables, AddOperatorTeamSecurityGroupConcernedProjectsBaseVariables, RemoveOperatorTeamSecurityGroupConcernedProjectsBaseVariables, AddTicketsConcernedProjectsBaseVariables, RemoveTicketsConcernedProjectsBaseVariables } from '../gqls'
import { GetConcernedProjectsDocument, FirstConcernedProjectsDocument, CountConcernedProjectsDocument, FindConcernedProjectsDocument, SearchConcernedProjectsDocument, ExportSchemaConcernedProjectsDocument, ExportDataConcernedProjectsDocument, CustomQueryConcernedProjectsDocument, CustomQueryIdConcernedProjectsDocument, FindUnassociatedSecurityGroupsConcernedProjectsDocument, FindAssociatedOperatorTeamSecurityGroupsConcernedProjectsDocument, UsedConcernedProjectsDocument, ExistConcernedProjectsDocument, FindUnassociatedTicketsConcernedProjectsDocument, InsertConcernedProjectsDocument, UpdateConcernedProjectsDocument, ImportDataConcernedProjectsDocument, UpdateManyConcernedProjectsDocument, DeleteConcernedProjectsDocument, AddSecurityGroupConcernedProjectsDocument, RemoveSecurityGroupConcernedProjectsDocument, AddOperatorTeamSecurityGroupConcernedProjectsDocument, RemoveOperatorTeamSecurityGroupConcernedProjectsDocument, AddTicketsConcernedProjectsDocument, RemoveTicketsConcernedProjectsDocument } from '../gqls'
import { ServiceSingleResultOfConcernedProject, QueryContextOfConcernedProject, FilterOfConcernedProject, ServiceSingleResultOfInt64, ServiceListResultOfConcernedProject, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, ConcernedProjectInput, FieldUpdateOperatorOfConcernedProject } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ConcernedProjectBaseService {
    
public modelName = 'concernedProject';
public modelPluralName = 'concernedProjects';

	private getConcernedProjectsQuery: GetConcernedProjectsDocument;
	private firstConcernedProjectsQuery: FirstConcernedProjectsDocument;
	private countConcernedProjectsQuery: CountConcernedProjectsDocument;
	private findConcernedProjectsQuery: FindConcernedProjectsDocument;
	private searchConcernedProjectsQuery: SearchConcernedProjectsDocument;
	private exportSchemaConcernedProjectsQuery: ExportSchemaConcernedProjectsDocument;
	private exportDataConcernedProjectsQuery: ExportDataConcernedProjectsDocument;
	private customQueryConcernedProjectsQuery: CustomQueryConcernedProjectsDocument;
	private customQueryIdConcernedProjectsQuery: CustomQueryIdConcernedProjectsDocument;
	private findUnassociatedSecurityGroupsConcernedProjectsQuery: FindUnassociatedSecurityGroupsConcernedProjectsDocument;
	private findAssociatedOperatorTeamSecurityGroupsConcernedProjectsQuery: FindAssociatedOperatorTeamSecurityGroupsConcernedProjectsDocument;
	private usedConcernedProjectsQuery: UsedConcernedProjectsDocument;
	private existConcernedProjectsQuery: ExistConcernedProjectsDocument;
	private findUnassociatedTicketsConcernedProjectsQuery: FindUnassociatedTicketsConcernedProjectsDocument;
	private insertConcernedProjectsMutation: InsertConcernedProjectsDocument;
	private updateConcernedProjectsMutation: UpdateConcernedProjectsDocument;
	private importDataConcernedProjectsMutation: ImportDataConcernedProjectsDocument;
	private updateManyConcernedProjectsMutation: UpdateManyConcernedProjectsDocument;
	private deleteConcernedProjectsMutation: DeleteConcernedProjectsDocument;
	private addSecurityGroupConcernedProjectsMutation: AddSecurityGroupConcernedProjectsDocument;
	private removeSecurityGroupConcernedProjectsMutation: RemoveSecurityGroupConcernedProjectsDocument;
	private addOperatorTeamSecurityGroupConcernedProjectsMutation: AddOperatorTeamSecurityGroupConcernedProjectsDocument;
	private removeOperatorTeamSecurityGroupConcernedProjectsMutation: RemoveOperatorTeamSecurityGroupConcernedProjectsDocument;
	private addTicketsConcernedProjectsMutation: AddTicketsConcernedProjectsDocument;
	private removeTicketsConcernedProjectsMutation: RemoveTicketsConcernedProjectsDocument;

	constructor(private injector: Injector) {
		this.getConcernedProjectsQuery = this.injector.get(GetConcernedProjectsDocument);
		this.firstConcernedProjectsQuery = this.injector.get(FirstConcernedProjectsDocument);
		this.countConcernedProjectsQuery = this.injector.get(CountConcernedProjectsDocument);
		this.findConcernedProjectsQuery = this.injector.get(FindConcernedProjectsDocument);
		this.searchConcernedProjectsQuery = this.injector.get(SearchConcernedProjectsDocument);
		this.exportSchemaConcernedProjectsQuery = this.injector.get(ExportSchemaConcernedProjectsDocument);
		this.exportDataConcernedProjectsQuery = this.injector.get(ExportDataConcernedProjectsDocument);
		this.customQueryConcernedProjectsQuery = this.injector.get(CustomQueryConcernedProjectsDocument);
		this.customQueryIdConcernedProjectsQuery = this.injector.get(CustomQueryIdConcernedProjectsDocument);
		this.findUnassociatedSecurityGroupsConcernedProjectsQuery = this.injector.get(FindUnassociatedSecurityGroupsConcernedProjectsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsConcernedProjectsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsConcernedProjectsDocument);
		this.usedConcernedProjectsQuery = this.injector.get(UsedConcernedProjectsDocument);
		this.existConcernedProjectsQuery = this.injector.get(ExistConcernedProjectsDocument);
		this.findUnassociatedTicketsConcernedProjectsQuery = this.injector.get(FindUnassociatedTicketsConcernedProjectsDocument);
		this.insertConcernedProjectsMutation = this.injector.get(InsertConcernedProjectsDocument);
		this.updateConcernedProjectsMutation = this.injector.get(UpdateConcernedProjectsDocument);
		this.importDataConcernedProjectsMutation = this.injector.get(ImportDataConcernedProjectsDocument);
		this.updateManyConcernedProjectsMutation = this.injector.get(UpdateManyConcernedProjectsDocument);
		this.deleteConcernedProjectsMutation = this.injector.get(DeleteConcernedProjectsDocument);
		this.addSecurityGroupConcernedProjectsMutation = this.injector.get(AddSecurityGroupConcernedProjectsDocument);
		this.removeSecurityGroupConcernedProjectsMutation = this.injector.get(RemoveSecurityGroupConcernedProjectsDocument);
		this.addOperatorTeamSecurityGroupConcernedProjectsMutation = this.injector.get(AddOperatorTeamSecurityGroupConcernedProjectsDocument);
		this.removeOperatorTeamSecurityGroupConcernedProjectsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupConcernedProjectsDocument);
		this.addTicketsConcernedProjectsMutation = this.injector.get(AddTicketsConcernedProjectsDocument);
		this.removeTicketsConcernedProjectsMutation = this.injector.get(RemoveTicketsConcernedProjectsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConcernedProject> {
    
    		let variables: GetConcernedProjectsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.get));
            }
            else{
                let result:ServiceSingleResultOfConcernedProject={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConcernedProject,
		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConcernedProject> {
    
    		let variables: FirstConcernedProjectsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountConcernedProjectsBaseVariables = {
    			filter: filter
    		}
    				return this.countConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConcernedProject,
		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConcernedProject> {
    
    		let variables: FindConcernedProjectsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfConcernedProject,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConcernedProject> {
    
    		let variables: SearchConcernedProjectsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaConcernedProjectsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataConcernedProjectsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfConcernedProject,
		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConcernedProject> {
    
    		let variables: CustomQueryConcernedProjectsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfConcernedProject,
		@Args('filter?') filter?: FilterOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConcernedProject> {
    
    		let variables: CustomQueryIdConcernedProjectsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsConcernedProjectsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsConcernedProjectsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedConcernedProjectsBaseVariables = {
    			ids: ids
    		}
    				return this.usedConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistConcernedProjectsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.exist));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsConcernedProjectsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsConcernedProjectsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.findUnassociatedTickets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ConcernedProjectInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConcernedProject> {
    
    		let variables: InsertConcernedProjectsBaseVariables = {
    			entity: entity
    		}
    				return this.insertConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConcernedProject> {
    
    		let variables: UpdateConcernedProjectsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataConcernedProjectsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfConcernedProject,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyConcernedProjectsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteConcernedProjectsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupConcernedProjectsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupConcernedProjectsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupConcernedProjectsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupConcernedProjectsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsConcernedProjectsBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsConcernedProjectsBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsConcernedProjectsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.concernedProjects.removeTickets));
    	}
    
}