import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';
import { Router } from '@angular/router';
@Injectable({ providedIn: 'root' })
export class RoutingCoreService {
  constructor(private auth: AuthorizationCoreService, private router: Router) {}
  @InjectArgs
  public customRoute(@Args('item') item) {
    if (item == undefined) return of('administration/security/permissions');
    switch (item.__typename) {
      case 'User':
        if (item?.serviceDeskActivated) {
          return of('suppliers/contacts');
        } else {
          return of('administration/security/permissions');
        }
      default:
        break;
    }
  }

  @InjectArgs
  public customRouteAssetAffected(): Observable<string> {
    let getRoute = 'assets';
    let isManager = this.auth.user.hasManager();
    if (isManager == false) {
      getRoute = `asset-affected`;
    }
    return of(getRoute);
  }
}
