import { ClarilogLocalAgentBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-clarilog-local-agent')
export class ClarilogLocalAgentCoreService extends ClarilogLocalAgentBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
