<clc-manage-list
  [newCommand]="active === true ? newCommandModel : null"
  (onNewClick)="active === true ? null : onNewTicketClick($event)"
  [modelState]="modelState"
  [service]="service"
  [canImport]="false"
  [(selectedKeys)]="selectedKeys"
  [(selectedData)]="selectedData"
  [canEdit]="false"
  [canAdd]="canAddTicket"
  [canDelete]="canDeleteTicket"
  (onSelectionChanged)="onSelectionTicketChanged($event)"
  [layoutKey]="layoutKey"
  [delayAutoRefresh]="30000"
  [displayAutoRefreshCheckbox]="true"
  [enableForceAutoRefresh]="false"
>
  <cl-toolbar-items>
    <cl-toolbar-item-button
      [visible]="!enableMassiveEdit && canEditTicket"
      [text]="'edit' | translate"
      [multipleText]="true"
      icon="fas fa-pencil-alt"
      (onClick)="onEditTicketClick($event)"
      [disabled]="!(selectedKeys?.length === 1 && enableEditTicket === true)"
    >
    </cl-toolbar-item-button>
    <cl-toolbar-item-dropdown-button
      [visible]="enableMassiveEdit && canEditTicket"
      [text]="'edit' | translate"
      [multipleText]="true"
      icon="fas fa-pencil-alt"
      [splitButton]="!(selectedKeys?.length > 1)"
      [source]="editMassiveDataSource.datasource"
      [keyExpr]="'key'"
      [displayExpr]="'name'"
      (onButtonClick)="
        selectedKeys?.length > 1 ? null : onEditTicketClick($event)
      "
      (onItemClick)="onItemEditButtonClick($event)"
      [disabled]="disabledButtonEdit()"
    >
    </cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-dropdown-button
      [visible]="canImportTicket && showImport && isQueryBuilder === false"
      [text]="'entities/scanConfiguration/import' | translate"
      icon="fas fa-paper-plane"
      [splitButton]="true"
      [source]="importDataSource.datasource"
      [keyExpr]="'key'"
      [displayExpr]="'name'"
      (onItemClick)="onButtonClick($event)"
    >
    </cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-wait-button
      [visible]="canEditTicket"
      [text]="'entities/incident/takeCharge' | translate"
      [icon]="'far fa-hand-point-down'"
      [disabled]="!(selectedKeys?.length > 0 && enableEditTicket === true)"
      (onClick)="takeCharge()"
    ></cl-toolbar-item-wait-button>
    <cl-toolbar-item-dropdown-button
      [text]="'entities/ticket/action' | translate"
      [source]="actionBtnSource"
      icon="far fa-tools"
      keyExpr="id"
      displayExpr="name"
      [visible]="archiveMode === false"
      [disabled]="selectedKeys?.length === 0"
      (onItemClick)="actionItemBtn($event)"
    ></cl-toolbar-item-dropdown-button>

    <cl-toolbar-item-wait-button
      [visible]="archiveMode"
      [text]="'globals/printForm' | translate"
      [icon]="'fal fa-print'"
      (onClick)="printTickets()"
      [wait]="waitPrint"
      [disabled]="selectedKeys?.length === 0"
    ></cl-toolbar-item-wait-button>

    <cl-toolbar-item-wait-button
      [visible]="visibleArchiveTicket && canEditTicket"
      [text]="'archive' | translate"
      [icon]="'fal fa-box-archive'"
      (onClick)="archive()"
      [wait]="waitArchive"
      [disabled]="!(selectedKeys?.length > 0 && enableEditTicket == true)"
    ></cl-toolbar-item-wait-button>

    <cl-toolbar-item-wait-button
      [visible]="archiveMode"
      [text]="'restore' | translate"
      [icon]="'fal fa-box-archive'"
      (onClick)="restoreArchive()"
      [wait]="waitArchive"
      [disabled]="!(selectedKeys?.length > 0)"
    ></cl-toolbar-item-wait-button>

    <cl-toolbar-item-button
      [visible]="displayResearchButton"
      [text]="'entities/ticket/research' | translate"
      [icon]="'fal fa-search'"
      (onClick)="makeReseach()"
    ></cl-toolbar-item-button>
  </cl-toolbar-items>
</clc-manage-list>

<clc-import-data-popup
  [downloadSchema]="true"
  #importPopup
  (onSuccess)="onSuccess($event)"
></clc-import-data-popup>

<clc-massive-edit
  [large]="'Large'"
  [title]="'entities/ticket/titleMassiveEdit' | translate"
  [(isVisible)]="isMassiveEditVisible"
  [inputModel]="massiveEditModel"
  [selectedKeys]="selectedKeys"
  [service]="service"
  (onAfterSaved)="massiveEditsaved($event)"
  (onFormLoaded)="massiveEditFormLoaded()"
  (isVisibleChange)="massiveEditVisibleChange($event)"
  [warningMessage]="warningMessage"
></clc-massive-edit>

<clc-research
  [(isVisible)]="displayPopUp"
  (onSaved)="onSaved($event)"
  [height]="'33%'"
  [width]="'40%'"
>
</clc-research>

<dx-popup
  [width]="'80%'"
  [height]="'80%'"
  [showTitle]="false"
  #popup
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="isGroupTicketVisible"
>
  <clc-select-list
    id="group-list-popup"
    [title]="'entities/incident/groupTicketTitleSource' | translate"
    [hint]="'entities/incident/parentTaskFinishWarningMessage' | translate"
    [buttonText]="'entities/incident/groupTicket' | translate"
    [source]="groupTicketSource"
    [columns]="groupTicketColumns"
    (onClosed)="isGroupTicketVisible = false"
    (onSelect)="groupTicketsaved($event)"
    [multiple]="false"
    [ngClass]="'group-ticket-select-list'"
  >
  </clc-select-list>

  <dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.3)"
    [position]="{ of: '#group-list-popup' }"
    [(visible)]="displayActionBeingPerformed"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
    [message]="'Loading...'"
  >
  </dx-load-panel>
</dx-popup>
