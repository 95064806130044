import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetContractsBaseVariables, FirstAssetContractsBaseVariables, CountAssetContractsBaseVariables, FindAssetContractsBaseVariables, SearchAssetContractsBaseVariables, ExportSchemaAssetContractsBaseVariables, ExportDataAssetContractsBaseVariables, CustomQueryAssetContractsBaseVariables, CustomQueryIdAssetContractsBaseVariables, UsedAssetContractsBaseVariables, ExistAssetContractsBaseVariables, InsertAssetContractsBaseVariables, UpdateAssetContractsBaseVariables, ImportDataAssetContractsBaseVariables, UpdateManyAssetContractsBaseVariables, DeleteAssetContractsBaseVariables } from '../gqls'
import { GetAssetContractsDocument, FirstAssetContractsDocument, CountAssetContractsDocument, FindAssetContractsDocument, SearchAssetContractsDocument, ExportSchemaAssetContractsDocument, ExportDataAssetContractsDocument, CustomQueryAssetContractsDocument, CustomQueryIdAssetContractsDocument, UsedAssetContractsDocument, ExistAssetContractsDocument, InsertAssetContractsDocument, UpdateAssetContractsDocument, ImportDataAssetContractsDocument, UpdateManyAssetContractsDocument, DeleteAssetContractsDocument } from '../gqls'
import { ServiceSingleResultOfAssetContract, QueryContextOfAssetContract, FilterOfAssetContract, ServiceSingleResultOfInt64, ServiceListResultOfAssetContract, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetContractInput, FieldUpdateOperatorOfAssetContract } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetContractBaseService {
    
public modelName = 'assetContract';
public modelPluralName = 'assetContracts';

	private getAssetContractsQuery: GetAssetContractsDocument;
	private firstAssetContractsQuery: FirstAssetContractsDocument;
	private countAssetContractsQuery: CountAssetContractsDocument;
	private findAssetContractsQuery: FindAssetContractsDocument;
	private searchAssetContractsQuery: SearchAssetContractsDocument;
	private exportSchemaAssetContractsQuery: ExportSchemaAssetContractsDocument;
	private exportDataAssetContractsQuery: ExportDataAssetContractsDocument;
	private customQueryAssetContractsQuery: CustomQueryAssetContractsDocument;
	private customQueryIdAssetContractsQuery: CustomQueryIdAssetContractsDocument;
	private usedAssetContractsQuery: UsedAssetContractsDocument;
	private existAssetContractsQuery: ExistAssetContractsDocument;
	private insertAssetContractsMutation: InsertAssetContractsDocument;
	private updateAssetContractsMutation: UpdateAssetContractsDocument;
	private importDataAssetContractsMutation: ImportDataAssetContractsDocument;
	private updateManyAssetContractsMutation: UpdateManyAssetContractsDocument;
	private deleteAssetContractsMutation: DeleteAssetContractsDocument;

	constructor(private injector: Injector) {
		this.getAssetContractsQuery = this.injector.get(GetAssetContractsDocument);
		this.firstAssetContractsQuery = this.injector.get(FirstAssetContractsDocument);
		this.countAssetContractsQuery = this.injector.get(CountAssetContractsDocument);
		this.findAssetContractsQuery = this.injector.get(FindAssetContractsDocument);
		this.searchAssetContractsQuery = this.injector.get(SearchAssetContractsDocument);
		this.exportSchemaAssetContractsQuery = this.injector.get(ExportSchemaAssetContractsDocument);
		this.exportDataAssetContractsQuery = this.injector.get(ExportDataAssetContractsDocument);
		this.customQueryAssetContractsQuery = this.injector.get(CustomQueryAssetContractsDocument);
		this.customQueryIdAssetContractsQuery = this.injector.get(CustomQueryIdAssetContractsDocument);
		this.usedAssetContractsQuery = this.injector.get(UsedAssetContractsDocument);
		this.existAssetContractsQuery = this.injector.get(ExistAssetContractsDocument);
		this.insertAssetContractsMutation = this.injector.get(InsertAssetContractsDocument);
		this.updateAssetContractsMutation = this.injector.get(UpdateAssetContractsDocument);
		this.importDataAssetContractsMutation = this.injector.get(ImportDataAssetContractsDocument);
		this.updateManyAssetContractsMutation = this.injector.get(UpdateManyAssetContractsDocument);
		this.deleteAssetContractsMutation = this.injector.get(DeleteAssetContractsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetContract> {
    
    		let variables: GetAssetContractsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.get));
            }
            else{
                let result:ServiceSingleResultOfAssetContract={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetContract,
		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetContract> {
    
    		let variables: FirstAssetContractsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetContractsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetContract,
		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetContract> {
    
    		let variables: FindAssetContractsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetContract,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetContract> {
    
    		let variables: SearchAssetContractsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetContractsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetContractsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetContract,
		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetContract> {
    
    		let variables: CustomQueryAssetContractsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetContract,
		@Args('filter?') filter?: FilterOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetContract> {
    
    		let variables: CustomQueryIdAssetContractsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetContractsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetContractsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetContractInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetContract> {
    
    		let variables: InsertAssetContractsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetContract> {
    
    		let variables: UpdateAssetContractsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetContractsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetContractsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetContractsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetContracts.delete));
    	}
    
}