import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowManagedsBaseVariables, FirstWorkflowManagedsBaseVariables, CountWorkflowManagedsBaseVariables, FindWorkflowManagedsBaseVariables, SearchWorkflowManagedsBaseVariables, ExportSchemaWorkflowManagedsBaseVariables, ExportDataWorkflowManagedsBaseVariables, CustomQueryWorkflowManagedsBaseVariables, CustomQueryIdWorkflowManagedsBaseVariables, UsedWorkflowManagedsBaseVariables, ExistWorkflowManagedsBaseVariables, InsertWorkflowManagedsBaseVariables, UpdateWorkflowManagedsBaseVariables, ImportDataWorkflowManagedsBaseVariables, UpdateManyWorkflowManagedsBaseVariables, DeleteWorkflowManagedsBaseVariables } from '../gqls'
import { GetWorkflowManagedsDocument, FirstWorkflowManagedsDocument, CountWorkflowManagedsDocument, FindWorkflowManagedsDocument, SearchWorkflowManagedsDocument, ExportSchemaWorkflowManagedsDocument, ExportDataWorkflowManagedsDocument, CustomQueryWorkflowManagedsDocument, CustomQueryIdWorkflowManagedsDocument, UsedWorkflowManagedsDocument, ExistWorkflowManagedsDocument, InsertWorkflowManagedsDocument, UpdateWorkflowManagedsDocument, ImportDataWorkflowManagedsDocument, UpdateManyWorkflowManagedsDocument, DeleteWorkflowManagedsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowManaged, QueryContextOfWorkflowManaged, FilterOfWorkflowManaged, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowManaged, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowManagedInput, FieldUpdateOperatorOfWorkflowManaged } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowManagedBaseService {
    
public modelName = 'workflowManaged';
public modelPluralName = 'workflowManageds';

	private getWorkflowManagedsQuery: GetWorkflowManagedsDocument;
	private firstWorkflowManagedsQuery: FirstWorkflowManagedsDocument;
	private countWorkflowManagedsQuery: CountWorkflowManagedsDocument;
	private findWorkflowManagedsQuery: FindWorkflowManagedsDocument;
	private searchWorkflowManagedsQuery: SearchWorkflowManagedsDocument;
	private exportSchemaWorkflowManagedsQuery: ExportSchemaWorkflowManagedsDocument;
	private exportDataWorkflowManagedsQuery: ExportDataWorkflowManagedsDocument;
	private customQueryWorkflowManagedsQuery: CustomQueryWorkflowManagedsDocument;
	private customQueryIdWorkflowManagedsQuery: CustomQueryIdWorkflowManagedsDocument;
	private usedWorkflowManagedsQuery: UsedWorkflowManagedsDocument;
	private existWorkflowManagedsQuery: ExistWorkflowManagedsDocument;
	private insertWorkflowManagedsMutation: InsertWorkflowManagedsDocument;
	private updateWorkflowManagedsMutation: UpdateWorkflowManagedsDocument;
	private importDataWorkflowManagedsMutation: ImportDataWorkflowManagedsDocument;
	private updateManyWorkflowManagedsMutation: UpdateManyWorkflowManagedsDocument;
	private deleteWorkflowManagedsMutation: DeleteWorkflowManagedsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowManagedsQuery = this.injector.get(GetWorkflowManagedsDocument);
		this.firstWorkflowManagedsQuery = this.injector.get(FirstWorkflowManagedsDocument);
		this.countWorkflowManagedsQuery = this.injector.get(CountWorkflowManagedsDocument);
		this.findWorkflowManagedsQuery = this.injector.get(FindWorkflowManagedsDocument);
		this.searchWorkflowManagedsQuery = this.injector.get(SearchWorkflowManagedsDocument);
		this.exportSchemaWorkflowManagedsQuery = this.injector.get(ExportSchemaWorkflowManagedsDocument);
		this.exportDataWorkflowManagedsQuery = this.injector.get(ExportDataWorkflowManagedsDocument);
		this.customQueryWorkflowManagedsQuery = this.injector.get(CustomQueryWorkflowManagedsDocument);
		this.customQueryIdWorkflowManagedsQuery = this.injector.get(CustomQueryIdWorkflowManagedsDocument);
		this.usedWorkflowManagedsQuery = this.injector.get(UsedWorkflowManagedsDocument);
		this.existWorkflowManagedsQuery = this.injector.get(ExistWorkflowManagedsDocument);
		this.insertWorkflowManagedsMutation = this.injector.get(InsertWorkflowManagedsDocument);
		this.updateWorkflowManagedsMutation = this.injector.get(UpdateWorkflowManagedsDocument);
		this.importDataWorkflowManagedsMutation = this.injector.get(ImportDataWorkflowManagedsDocument);
		this.updateManyWorkflowManagedsMutation = this.injector.get(UpdateManyWorkflowManagedsDocument);
		this.deleteWorkflowManagedsMutation = this.injector.get(DeleteWorkflowManagedsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowManaged> {
    
    		let variables: GetWorkflowManagedsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowManaged={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowManaged,
		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowManaged> {
    
    		let variables: FirstWorkflowManagedsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowManagedsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowManaged,
		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowManaged> {
    
    		let variables: FindWorkflowManagedsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowManaged,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowManaged> {
    
    		let variables: SearchWorkflowManagedsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowManagedsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowManagedsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowManaged,
		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowManaged> {
    
    		let variables: CustomQueryWorkflowManagedsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowManaged,
		@Args('filter?') filter?: FilterOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowManaged> {
    
    		let variables: CustomQueryIdWorkflowManagedsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowManagedsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowManagedsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowManagedsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowManagedInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowManaged> {
    
    		let variables: InsertWorkflowManagedsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowManagedsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowManaged> {
    
    		let variables: UpdateWorkflowManagedsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowManagedsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowManagedsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowManagedsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowManaged,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowManagedsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowManagedsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowManagedsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowManagedsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowManageds.delete));
    	}
    
}