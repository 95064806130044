<dx-box direction="col" width="100%" height="100%">
  <dxi-item [ratio]="1">
    <div class="cl-message-list">
      <dx-list
        [height]="'99%'"
        [items]="items"
        (onContentReady)="onContentReady($event)"
        #messageList
        (onScroll)="onScroll($event)"
        itemTemplate="data"
        selectionMode="none"
        [hoverStateEnabled]="false"
        [focusStateEnabled]="false"
        [disabled]="false"
        [useNativeScrolling]="true"
        [activeStateEnabled]="false"
      >
        <div class="templateItem" *dxTemplate="let item of 'data'">
          <div
            style="display: flex"
            [ngClass]="item?.isOperator === isOperator ? 'right' : 'left'"
          >
            {{ item?.author?.data?.name }} - {{ formatDate(item?.date) }}
          </div>
          <div
            style="display: flex; margin: 5px"
            [ngClass]="item?.isOperator === isOperator ? 'right' : 'left'"
          >
            <div
              class="content-message"
              contenteditable="true"
              autocorrect="'off'"
              spellcheck="false"
              (focus)="onFocusDiv($event)"
              [ngClass]="
                item?.isOperator === isOperator ? 'color-right' : 'color-left'
              "
              [innerHTML]="item?.content"
            ></div>
          </div>
        </div>
      </dx-list>
    </div>
  </dxi-item>
  <dxi-item
    [ratio]="0"
    [baseSize]="160"
    style="margin: 5px"
    [visible]="hideHtmlEditor"
  >
    <div class="select-model" *ngIf="messageModel?.source !== undefined">
      <div class="dx-field-value width-select-model">
        <dx-select-box
          [dataSource]="messageModel?.source?.datasource"
          [displayExpr]="getDisplayExpr()"
          [valueExpr]="messageModel?.valueExpr"
          [placeholder]="messageModel?.placeHolder"
          (onValueChanged)="selectMessageModelValueChanged($event)"
          (onInitialized)="onSelectBoxInitialized($event)"
          [width]="'100%'"
          fieldTemplate="field"
          [visible]="hideSelectMessageModel === true"
        >
          <div *dxTemplate="let data of 'field'">
            <dx-text-box
              [value]="getTextBoxValue(data)"
              [readOnly]="true"
              [placeholder]="messageModel?.placeHolder"
            >
            </dx-text-box>
          </div>
          <div
            *dxTemplate="let data of 'item'"
            (mouseenter)="
              mouseEvent(
                $event,
                translateFieldhelperService.findValueToShow(data.name)
              )
            "
            (mouseleave)="mouseEvent($event)"
          >
            <div>
              {{ translateFieldhelperService.findValueToShow(data.name) }}
            </div>
          </div>
        </dx-select-box>
      </div>
      <div>
        <dx-button
          icon="fal fa-expand-alt"
          (onClick)="expandEditor()"
          *ngIf="expand === false"
          [height]="'100%'"
        >
        </dx-button>
        <dx-button
          icon="fal fa-compress-alt"
          (onClick)="expandEditor()"
          *ngIf="expand === true"
          [height]="'100%'"
        >
        </dx-button>
      </div>
    </div>
    <dx-popover
      [(target)]="popover_target"
      position="left"
      [width]="'auto'"
      [(visible)]="visible"
    >
      <div style="padding: '3px'; white-space: pre-line">
        {{ text }}
      </div>
    </dx-popover>

    <dx-html-editor
      height="100%"
      placeholder="{{ 'globals/messagePlaceholder' | translate }}"
      (onValueChanged)="onMessageChanged($event)"
      (onInitialized)="onHtmlEditorInitialized($event)"
      [disabled]="disabledHtml"
    >
    </dx-html-editor>
    <dx-speed-dial-action
      [icon]="iconBtn"
      *ngIf="displayBtn === true"
      (onClick)="onSendMessage($event)"
    >
    </dx-speed-dial-action>
  </dxi-item>
</dx-box>
