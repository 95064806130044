import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetConsumableMovementsBaseVariables, FirstConsumableMovementsBaseVariables, CountConsumableMovementsBaseVariables, FindConsumableMovementsBaseVariables, SearchConsumableMovementsBaseVariables, ExportSchemaConsumableMovementsBaseVariables, ExportDataConsumableMovementsBaseVariables, CustomQueryConsumableMovementsBaseVariables, CustomQueryIdConsumableMovementsBaseVariables, UsedConsumableMovementsBaseVariables, ExistConsumableMovementsBaseVariables, FindRecyclesConsumableMovementsBaseVariables, CountRecyclesConsumableMovementsBaseVariables, ReadOnlyConsumableMovementsBaseVariables, FindArchivedConsumableMovementsBaseVariables, CountAllConsumableMovementsBaseVariables, FindDynamicPropertyFieldsConsumableMovementsBaseVariables, InsertConsumableMovementsBaseVariables, UpdateConsumableMovementsBaseVariables, ImportDataConsumableMovementsBaseVariables, UpdateManyConsumableMovementsBaseVariables, DeleteConsumableMovementsBaseVariables, RestoreConsumableMovementsBaseVariables, RecycleConsumableMovementsBaseVariables, RestoreArchivedConsumableMovementsBaseVariables, ArchivedConsumableMovementsBaseVariables, AddFileDynamicFieldConsumableMovementsBaseVariables, RemoveFileDynamicFieldConsumableMovementsBaseVariables } from '../gqls'
import { GetConsumableMovementsDocument, FirstConsumableMovementsDocument, CountConsumableMovementsDocument, FindConsumableMovementsDocument, SearchConsumableMovementsDocument, ExportSchemaConsumableMovementsDocument, ExportDataConsumableMovementsDocument, CustomQueryConsumableMovementsDocument, CustomQueryIdConsumableMovementsDocument, UsedConsumableMovementsDocument, ExistConsumableMovementsDocument, FindRecyclesConsumableMovementsDocument, CountRecyclesConsumableMovementsDocument, ReadOnlyConsumableMovementsDocument, FindArchivedConsumableMovementsDocument, CountAllConsumableMovementsDocument, FindDynamicPropertyFieldsConsumableMovementsDocument, InsertConsumableMovementsDocument, UpdateConsumableMovementsDocument, ImportDataConsumableMovementsDocument, UpdateManyConsumableMovementsDocument, DeleteConsumableMovementsDocument, RestoreConsumableMovementsDocument, RecycleConsumableMovementsDocument, RestoreArchivedConsumableMovementsDocument, ArchivedConsumableMovementsDocument, AddFileDynamicFieldConsumableMovementsDocument, RemoveFileDynamicFieldConsumableMovementsDocument } from '../gqls'
import { ServiceSingleResultOfConsumableMovement, QueryContextOfConsumableMovement, FilterOfConsumableMovement, ServiceSingleResultOfInt64, ServiceListResultOfConsumableMovement, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfConsumableMovement, ConsumableMovementInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ConsumableMovementBaseService {
    
public modelName = 'consumableMovement';
public modelPluralName = 'consumableMovements';

	private getConsumableMovementsQuery: GetConsumableMovementsDocument;
	private firstConsumableMovementsQuery: FirstConsumableMovementsDocument;
	private countConsumableMovementsQuery: CountConsumableMovementsDocument;
	private findConsumableMovementsQuery: FindConsumableMovementsDocument;
	private searchConsumableMovementsQuery: SearchConsumableMovementsDocument;
	private exportSchemaConsumableMovementsQuery: ExportSchemaConsumableMovementsDocument;
	private exportDataConsumableMovementsQuery: ExportDataConsumableMovementsDocument;
	private customQueryConsumableMovementsQuery: CustomQueryConsumableMovementsDocument;
	private customQueryIdConsumableMovementsQuery: CustomQueryIdConsumableMovementsDocument;
	private usedConsumableMovementsQuery: UsedConsumableMovementsDocument;
	private existConsumableMovementsQuery: ExistConsumableMovementsDocument;
	private findRecyclesConsumableMovementsQuery: FindRecyclesConsumableMovementsDocument;
	private countRecyclesConsumableMovementsQuery: CountRecyclesConsumableMovementsDocument;
	private readOnlyConsumableMovementsQuery: ReadOnlyConsumableMovementsDocument;
	private findArchivedConsumableMovementsQuery: FindArchivedConsumableMovementsDocument;
	private countAllConsumableMovementsQuery: CountAllConsumableMovementsDocument;
	private findDynamicPropertyFieldsConsumableMovementsQuery: FindDynamicPropertyFieldsConsumableMovementsDocument;
	private insertConsumableMovementsMutation: InsertConsumableMovementsDocument;
	private updateConsumableMovementsMutation: UpdateConsumableMovementsDocument;
	private importDataConsumableMovementsMutation: ImportDataConsumableMovementsDocument;
	private updateManyConsumableMovementsMutation: UpdateManyConsumableMovementsDocument;
	private deleteConsumableMovementsMutation: DeleteConsumableMovementsDocument;
	private restoreConsumableMovementsMutation: RestoreConsumableMovementsDocument;
	private recycleConsumableMovementsMutation: RecycleConsumableMovementsDocument;
	private restoreArchivedConsumableMovementsMutation: RestoreArchivedConsumableMovementsDocument;
	private archivedConsumableMovementsMutation: ArchivedConsumableMovementsDocument;
	private addFileDynamicFieldConsumableMovementsMutation: AddFileDynamicFieldConsumableMovementsDocument;
	private removeFileDynamicFieldConsumableMovementsMutation: RemoveFileDynamicFieldConsumableMovementsDocument;

	constructor(private injector: Injector) {
		this.getConsumableMovementsQuery = this.injector.get(GetConsumableMovementsDocument);
		this.firstConsumableMovementsQuery = this.injector.get(FirstConsumableMovementsDocument);
		this.countConsumableMovementsQuery = this.injector.get(CountConsumableMovementsDocument);
		this.findConsumableMovementsQuery = this.injector.get(FindConsumableMovementsDocument);
		this.searchConsumableMovementsQuery = this.injector.get(SearchConsumableMovementsDocument);
		this.exportSchemaConsumableMovementsQuery = this.injector.get(ExportSchemaConsumableMovementsDocument);
		this.exportDataConsumableMovementsQuery = this.injector.get(ExportDataConsumableMovementsDocument);
		this.customQueryConsumableMovementsQuery = this.injector.get(CustomQueryConsumableMovementsDocument);
		this.customQueryIdConsumableMovementsQuery = this.injector.get(CustomQueryIdConsumableMovementsDocument);
		this.usedConsumableMovementsQuery = this.injector.get(UsedConsumableMovementsDocument);
		this.existConsumableMovementsQuery = this.injector.get(ExistConsumableMovementsDocument);
		this.findRecyclesConsumableMovementsQuery = this.injector.get(FindRecyclesConsumableMovementsDocument);
		this.countRecyclesConsumableMovementsQuery = this.injector.get(CountRecyclesConsumableMovementsDocument);
		this.readOnlyConsumableMovementsQuery = this.injector.get(ReadOnlyConsumableMovementsDocument);
		this.findArchivedConsumableMovementsQuery = this.injector.get(FindArchivedConsumableMovementsDocument);
		this.countAllConsumableMovementsQuery = this.injector.get(CountAllConsumableMovementsDocument);
		this.findDynamicPropertyFieldsConsumableMovementsQuery = this.injector.get(FindDynamicPropertyFieldsConsumableMovementsDocument);
		this.insertConsumableMovementsMutation = this.injector.get(InsertConsumableMovementsDocument);
		this.updateConsumableMovementsMutation = this.injector.get(UpdateConsumableMovementsDocument);
		this.importDataConsumableMovementsMutation = this.injector.get(ImportDataConsumableMovementsDocument);
		this.updateManyConsumableMovementsMutation = this.injector.get(UpdateManyConsumableMovementsDocument);
		this.deleteConsumableMovementsMutation = this.injector.get(DeleteConsumableMovementsDocument);
		this.restoreConsumableMovementsMutation = this.injector.get(RestoreConsumableMovementsDocument);
		this.recycleConsumableMovementsMutation = this.injector.get(RecycleConsumableMovementsDocument);
		this.restoreArchivedConsumableMovementsMutation = this.injector.get(RestoreArchivedConsumableMovementsDocument);
		this.archivedConsumableMovementsMutation = this.injector.get(ArchivedConsumableMovementsDocument);
		this.addFileDynamicFieldConsumableMovementsMutation = this.injector.get(AddFileDynamicFieldConsumableMovementsDocument);
		this.removeFileDynamicFieldConsumableMovementsMutation = this.injector.get(RemoveFileDynamicFieldConsumableMovementsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConsumableMovement> {
    
    		let variables: GetConsumableMovementsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.get));
            }
            else{
                let result:ServiceSingleResultOfConsumableMovement={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConsumableMovement> {
    
    		let variables: FirstConsumableMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountConsumableMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: FindConsumableMovementsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: SearchConsumableMovementsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaConsumableMovementsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataConsumableMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: CustomQueryConsumableMovementsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: CustomQueryIdConsumableMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.usedConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistConsumableMovementsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: FindRecyclesConsumableMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesConsumableMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyConsumableMovementsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfConsumableMovement,
		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfConsumableMovement> {
    
    		let variables: FindArchivedConsumableMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllConsumableMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsConsumableMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsConsumableMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ConsumableMovementInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConsumableMovement> {
    
    		let variables: InsertConsumableMovementsBaseVariables = {
    			entity: entity
    		}
    				return this.insertConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfConsumableMovement> {
    
    		let variables: UpdateConsumableMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataConsumableMovementsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfConsumableMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyConsumableMovementsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedConsumableMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldConsumableMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldConsumableMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldConsumableMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.consumableMovements.removeFileDynamicField));
    	}
    
}