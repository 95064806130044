import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanConfigurationAgentsBaseVariables, GetNetworkCredentialAgentsBaseVariables, GetScanExcludeAgentsBaseVariables, GetScanRegistryKeyAgentsBaseVariables, GetScanDataFileAgentsBaseVariables, GetInventoryHistoryAgentsBaseVariables, GetByLocalIdAgentsBaseVariables, GetLastAgentVersionAgentsBaseVariables, GetBySysOidAgentsBaseVariables, FindByOpertationAgentAgentsBaseVariables, GetAssetByRuleAgentsBaseVariables, FindNetworkCardAgentsBaseVariables, GetLocalAgentByIdAgentsBaseVariables, GetLocalAgentByAssetAgentsBaseVariables, GetLastLocalAgentVersionAgentsBaseVariables, InsertClarilogServerAgentsBaseVariables, IsConnectedV2AgentsBaseVariables, UpdateFilesAgentAgentsBaseVariables, IsConnectedAgentsBaseVariables, UpdateStatusAgentAgentsBaseVariables, ProgressAuditAgentsBaseVariables, CreateAuditV2AgentsBaseVariables, CreateAuditAgentsBaseVariables, ImportAssetAgentsBaseVariables, ImportLdapOrganizationalUnitAgentsBaseVariables, ImportOrganizationalUnitAgentsBaseVariables, UpdateHistoryLdapAgentsBaseVariables, FinishAgentsBaseVariables, IsRunAgentsBaseVariables, CancelingAgentsBaseVariables, TotalAuditComputerAgentsBaseVariables, ImportUserAgentsBaseVariables, ImportClarilogLocalAgentAgentsBaseVariables, UpdateFilesLocalAgentAgentsBaseVariables, StartInventoryLocalAgentAgentsBaseVariables, UpdateStatusLocalAgentAgentsBaseVariables, UpdateCampaignHistoryAgentsBaseVariables, CreateTokenAgentsBaseVariables, DeleteTokenAgentsBaseVariables } from '../gqls'
import { GetScanConfigurationAgentsDocument, GetNetworkCredentialAgentsDocument, GetScanExcludeAgentsDocument, GetScanRegistryKeyAgentsDocument, GetScanDataFileAgentsDocument, GetInventoryHistoryAgentsDocument, GetByLocalIdAgentsDocument, GetLastAgentVersionAgentsDocument, GetBySysOidAgentsDocument, FindByOpertationAgentAgentsDocument, GetAssetByRuleAgentsDocument, FindNetworkCardAgentsDocument, GetLocalAgentByIdAgentsDocument, GetLocalAgentByAssetAgentsDocument, GetLastLocalAgentVersionAgentsDocument, InsertClarilogServerAgentsDocument, IsConnectedV2AgentsDocument, UpdateFilesAgentAgentsDocument, IsConnectedAgentsDocument, UpdateStatusAgentAgentsDocument, ProgressAuditAgentsDocument, CreateAuditV2AgentsDocument, CreateAuditAgentsDocument, ImportAssetAgentsDocument, ImportLdapOrganizationalUnitAgentsDocument, ImportOrganizationalUnitAgentsDocument, UpdateHistoryLdapAgentsDocument, FinishAgentsDocument, IsRunAgentsDocument, CancelingAgentsDocument, TotalAuditComputerAgentsDocument, ImportUserAgentsDocument, ImportClarilogLocalAgentAgentsDocument, UpdateFilesLocalAgentAgentsDocument, StartInventoryLocalAgentAgentsDocument, UpdateStatusLocalAgentAgentsDocument, UpdateCampaignHistoryAgentsDocument, CreateTokenAgentsDocument, DeleteTokenAgentsDocument } from '../gqls'
import { ServiceSingleResultOfScanConfiguration, ServiceSingleResultOfNetworkCredential, ServiceSingleResultOfScanExclude, ServiceSingleResultOfScanRegistryKey, ServiceSingleResultOfScanDataFile, ServiceSingleResultOfInventoryHistory, ServiceSingleResultOfClarilogServer, ServiceSingleResultOfString, ServiceSingleResultOfSnmpMapInformationKb, ServiceListResultOfScanConfiguration, ServiceSingleResultOfAsset, QueryContextOfAsset, AssetInput, ServiceListResultOfNetworkAdapterConfiguration, ClarilogLocalAgentInput, ClarilogServerInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfFileDescriptor, UpdaterState, FieldUpdateOperatorOfInventoryHistory, ServiceSingleResultOfGuid, InventoryHistoryInput, LdapOrganizationalUnitInput, OrganizationalUnitInput, LdapHistoryInput, UserInput, HistoryState, AgentTokenInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AgentBaseService {
    
public modelName = 'agent';
public modelPluralName = 'agents';

	private getScanConfigurationAgentsQuery: GetScanConfigurationAgentsDocument;
	private getNetworkCredentialAgentsQuery: GetNetworkCredentialAgentsDocument;
	private getScanExcludeAgentsQuery: GetScanExcludeAgentsDocument;
	private getScanRegistryKeyAgentsQuery: GetScanRegistryKeyAgentsDocument;
	private getScanDataFileAgentsQuery: GetScanDataFileAgentsDocument;
	private getInventoryHistoryAgentsQuery: GetInventoryHistoryAgentsDocument;
	private getByLocalIdAgentsQuery: GetByLocalIdAgentsDocument;
	private getLastAgentVersionAgentsQuery: GetLastAgentVersionAgentsDocument;
	private getBySysOidAgentsQuery: GetBySysOidAgentsDocument;
	private findByOpertationAgentAgentsQuery: FindByOpertationAgentAgentsDocument;
	private getAssetByRuleAgentsQuery: GetAssetByRuleAgentsDocument;
	private findNetworkCardAgentsQuery: FindNetworkCardAgentsDocument;
	private getLocalAgentByIdAgentsQuery: GetLocalAgentByIdAgentsDocument;
	private getLocalAgentByAssetAgentsQuery: GetLocalAgentByAssetAgentsDocument;
	private getLastLocalAgentVersionAgentsQuery: GetLastLocalAgentVersionAgentsDocument;
	private insertClarilogServerAgentsMutation: InsertClarilogServerAgentsDocument;
	private isConnectedV2AgentsMutation: IsConnectedV2AgentsDocument;
	private updateFilesAgentAgentsMutation: UpdateFilesAgentAgentsDocument;
	private isConnectedAgentsMutation: IsConnectedAgentsDocument;
	private updateStatusAgentAgentsMutation: UpdateStatusAgentAgentsDocument;
	private progressAuditAgentsMutation: ProgressAuditAgentsDocument;
	private createAuditV2AgentsMutation: CreateAuditV2AgentsDocument;
	private createAuditAgentsMutation: CreateAuditAgentsDocument;
	private importAssetAgentsMutation: ImportAssetAgentsDocument;
	private importLdapOrganizationalUnitAgentsMutation: ImportLdapOrganizationalUnitAgentsDocument;
	private importOrganizationalUnitAgentsMutation: ImportOrganizationalUnitAgentsDocument;
	private updateHistoryLdapAgentsMutation: UpdateHistoryLdapAgentsDocument;
	private finishAgentsMutation: FinishAgentsDocument;
	private isRunAgentsMutation: IsRunAgentsDocument;
	private cancelingAgentsMutation: CancelingAgentsDocument;
	private totalAuditComputerAgentsMutation: TotalAuditComputerAgentsDocument;
	private importUserAgentsMutation: ImportUserAgentsDocument;
	private importClarilogLocalAgentAgentsMutation: ImportClarilogLocalAgentAgentsDocument;
	private updateFilesLocalAgentAgentsMutation: UpdateFilesLocalAgentAgentsDocument;
	private startInventoryLocalAgentAgentsMutation: StartInventoryLocalAgentAgentsDocument;
	private updateStatusLocalAgentAgentsMutation: UpdateStatusLocalAgentAgentsDocument;
	private updateCampaignHistoryAgentsMutation: UpdateCampaignHistoryAgentsDocument;
	private createTokenAgentsMutation: CreateTokenAgentsDocument;
	private deleteTokenAgentsMutation: DeleteTokenAgentsDocument;

	constructor(private injector: Injector) {
		this.getScanConfigurationAgentsQuery = this.injector.get(GetScanConfigurationAgentsDocument);
		this.getNetworkCredentialAgentsQuery = this.injector.get(GetNetworkCredentialAgentsDocument);
		this.getScanExcludeAgentsQuery = this.injector.get(GetScanExcludeAgentsDocument);
		this.getScanRegistryKeyAgentsQuery = this.injector.get(GetScanRegistryKeyAgentsDocument);
		this.getScanDataFileAgentsQuery = this.injector.get(GetScanDataFileAgentsDocument);
		this.getInventoryHistoryAgentsQuery = this.injector.get(GetInventoryHistoryAgentsDocument);
		this.getByLocalIdAgentsQuery = this.injector.get(GetByLocalIdAgentsDocument);
		this.getLastAgentVersionAgentsQuery = this.injector.get(GetLastAgentVersionAgentsDocument);
		this.getBySysOidAgentsQuery = this.injector.get(GetBySysOidAgentsDocument);
		this.findByOpertationAgentAgentsQuery = this.injector.get(FindByOpertationAgentAgentsDocument);
		this.getAssetByRuleAgentsQuery = this.injector.get(GetAssetByRuleAgentsDocument);
		this.findNetworkCardAgentsQuery = this.injector.get(FindNetworkCardAgentsDocument);
		this.getLocalAgentByIdAgentsQuery = this.injector.get(GetLocalAgentByIdAgentsDocument);
		this.getLocalAgentByAssetAgentsQuery = this.injector.get(GetLocalAgentByAssetAgentsDocument);
		this.getLastLocalAgentVersionAgentsQuery = this.injector.get(GetLastLocalAgentVersionAgentsDocument);
		this.insertClarilogServerAgentsMutation = this.injector.get(InsertClarilogServerAgentsDocument);
		this.isConnectedV2AgentsMutation = this.injector.get(IsConnectedV2AgentsDocument);
		this.updateFilesAgentAgentsMutation = this.injector.get(UpdateFilesAgentAgentsDocument);
		this.isConnectedAgentsMutation = this.injector.get(IsConnectedAgentsDocument);
		this.updateStatusAgentAgentsMutation = this.injector.get(UpdateStatusAgentAgentsDocument);
		this.progressAuditAgentsMutation = this.injector.get(ProgressAuditAgentsDocument);
		this.createAuditV2AgentsMutation = this.injector.get(CreateAuditV2AgentsDocument);
		this.createAuditAgentsMutation = this.injector.get(CreateAuditAgentsDocument);
		this.importAssetAgentsMutation = this.injector.get(ImportAssetAgentsDocument);
		this.importLdapOrganizationalUnitAgentsMutation = this.injector.get(ImportLdapOrganizationalUnitAgentsDocument);
		this.importOrganizationalUnitAgentsMutation = this.injector.get(ImportOrganizationalUnitAgentsDocument);
		this.updateHistoryLdapAgentsMutation = this.injector.get(UpdateHistoryLdapAgentsDocument);
		this.finishAgentsMutation = this.injector.get(FinishAgentsDocument);
		this.isRunAgentsMutation = this.injector.get(IsRunAgentsDocument);
		this.cancelingAgentsMutation = this.injector.get(CancelingAgentsDocument);
		this.totalAuditComputerAgentsMutation = this.injector.get(TotalAuditComputerAgentsDocument);
		this.importUserAgentsMutation = this.injector.get(ImportUserAgentsDocument);
		this.importClarilogLocalAgentAgentsMutation = this.injector.get(ImportClarilogLocalAgentAgentsDocument);
		this.updateFilesLocalAgentAgentsMutation = this.injector.get(UpdateFilesLocalAgentAgentsDocument);
		this.startInventoryLocalAgentAgentsMutation = this.injector.get(StartInventoryLocalAgentAgentsDocument);
		this.updateStatusLocalAgentAgentsMutation = this.injector.get(UpdateStatusLocalAgentAgentsDocument);
		this.updateCampaignHistoryAgentsMutation = this.injector.get(UpdateCampaignHistoryAgentsDocument);
		this.createTokenAgentsMutation = this.injector.get(CreateTokenAgentsDocument);
		this.deleteTokenAgentsMutation = this.injector.get(DeleteTokenAgentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getScanConfiguration(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanConfiguration> {
    
    		let variables: GetScanConfigurationAgentsBaseVariables = {
    			id: id
    		}
    				return this.getScanConfigurationAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getScanConfiguration));
    	}

    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getNetworkCredential(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkCredential> {
    
    		let variables: GetNetworkCredentialAgentsBaseVariables = {
    			id: id
    		}
    				return this.getNetworkCredentialAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getNetworkCredential));
    	}

    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getScanExclude(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanExclude> {
    
    		let variables: GetScanExcludeAgentsBaseVariables = {
    			id: id
    		}
    				return this.getScanExcludeAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getScanExclude));
    	}

    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getScanRegistryKey(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanRegistryKey> {
    
    		let variables: GetScanRegistryKeyAgentsBaseVariables = {
    			id: id
    		}
    				return this.getScanRegistryKeyAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getScanRegistryKey));
    	}

    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getScanDataFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanDataFile> {
    
    		let variables: GetScanDataFileAgentsBaseVariables = {
    			id: id
    		}
    				return this.getScanDataFileAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getScanDataFile));
    	}

    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public getInventoryHistory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInventoryHistory> {
    
    		let variables: GetInventoryHistoryAgentsBaseVariables = {
    			id: id
    		}
    				return this.getInventoryHistoryAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getInventoryHistory));
    	}

    	/**  */
    	@InjectArgs
    	public getByLocalId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('localId') localId: string,
		@Args('version?') version?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: GetByLocalIdAgentsBaseVariables = {
    			localId: localId,
			version: version
    		}
    				return this.getByLocalIdAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getByLocalId));
    	}

    	/**  */
    	@InjectArgs
    	public getLastAgentVersion(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: GetLastAgentVersionAgentsBaseVariables = {
    
    		}
    				return this.getLastAgentVersionAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getLastAgentVersion));
    	}

    	/**  */
    	@InjectArgs
    	public getBySysOid(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('sysOid?') sysOid?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformationKb> {
    
    		let variables: GetBySysOidAgentsBaseVariables = {
    			sysOid: sysOid
    		}
    				return this.getBySysOidAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getBySysOid));
    	}

    	/**  */
    	@InjectArgs
    	public findByOpertationAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('clarilogServerId') clarilogServerId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindByOpertationAgentAgentsBaseVariables = {
    			clarilogServerId: clarilogServerId
    		}
    				return this.findByOpertationAgentAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.findByOpertationAgent));
    	}

    	/**  */
    	@InjectArgs
    	public getAssetByRule(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('asset?') asset?: AssetInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsset> {
    
    		let variables: GetAssetByRuleAgentsBaseVariables = {
    			asset: asset,
			options: options
    		}
    				return this.getAssetByRuleAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getAssetByRule));
    	}

    	/**  */
    	@InjectArgs
    	public findNetworkCard(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('host?') host?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkAdapterConfiguration> {
    
    		let variables: FindNetworkCardAgentsBaseVariables = {
    			host: host
    		}
    				return this.findNetworkCardAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.findNetworkCard));
    	}

    	/**  */
    	@InjectArgs
    	public getLocalAgentById(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('starting') starting: boolean,
		@Args('assetId') assetId: string,
		@Args('clarilogLocalAgent?') clarilogLocalAgent?: ClarilogLocalAgentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsset> {
    
    		let variables: GetLocalAgentByIdAgentsBaseVariables = {
    			assetId: assetId,
			clarilogLocalAgent: clarilogLocalAgent,
			starting: starting
    		}
    				return this.getLocalAgentByIdAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getLocalAgentById));
    	}

    	/**  */
    	@InjectArgs
    	public getLocalAgentByAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('starting') starting: boolean,
		@Args('clarilogLocalAgent?') clarilogLocalAgent?: ClarilogLocalAgentInput,
		@Args('asset?') asset?: AssetInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsset> {
    
    		let variables: GetLocalAgentByAssetAgentsBaseVariables = {
    			asset: asset,
			clarilogLocalAgent: clarilogLocalAgent,
			starting: starting
    		}
    				return this.getLocalAgentByAssetAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getLocalAgentByAsset));
    	}

    	/**  */
    	@InjectArgs
    	public getLastLocalAgentVersion(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: GetLastLocalAgentVersionAgentsBaseVariables = {
    
    		}
    				return this.getLastLocalAgentVersionAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agents.getLastLocalAgentVersion));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insertClarilogServer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ClarilogServerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfClarilogServer> {
    
    		let variables: InsertClarilogServerAgentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertClarilogServerAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.insertClarilogServer));
    	}

    	/**  */
    	@InjectArgs
    	public isConnectedV2(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('version?') version?: string,
		@Args('osVersion?') osVersion?: string,
		@Args('name?') name?: string,
		@Args('macAddress?') macAddress?: string,
		@Args('ipAddress?') ipAddress?: string,
		@Args('domain?') domain?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: IsConnectedV2AgentsBaseVariables = {
    			id: id,
			name: name,
			domain: domain,
			macAddress: macAddress,
			ipAddress: ipAddress,
			osVersion: osVersion,
			version: version
    		}
    				return this.isConnectedV2AgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.isConnectedV2));
    	}

    	/**  */
    	@InjectArgs
    	public updateFilesAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileDescriptor> {
    
    		let variables: UpdateFilesAgentAgentsBaseVariables = {
    
    		}
    				return this.updateFilesAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateFilesAgent));
    	}

    	/**  */
    	@InjectArgs
    	public isConnected(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('osVersion?') osVersion?: string,
		@Args('name?') name?: string,
		@Args('macAddress?') macAddress?: string,
		@Args('ipAddress?') ipAddress?: string,
		@Args('domain?') domain?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: IsConnectedAgentsBaseVariables = {
    			id: id,
			name: name,
			domain: domain,
			macAddress: macAddress,
			ipAddress: ipAddress,
			osVersion: osVersion
    		}
    				return this.isConnectedAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.isConnected));
    	}

    	/**  */
    	@InjectArgs
    	public updateStatusAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('updaterState') updaterState: UpdaterState,
		@Args('agentId') agentId: string,
		@Args('version?') version?: string,
		@Args('error?') error?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateStatusAgentAgentsBaseVariables = {
    			agentId: agentId,
			updaterState: updaterState,
			version: version,
			error: error
    		}
    				return this.updateStatusAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateStatusAgent));
    	}

    	/**  */
    	@InjectArgs
    	public progressAudit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ProgressAuditAgentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.progressAuditAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.progressAudit));
    	}

    	/**  */
    	@InjectArgs
    	public createAuditV2(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanType?') scanType?: string,
		@Args('scanConfigurationName?') scanConfigurationName?: string,
		@Args('entity?') entity?: InventoryHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: CreateAuditV2AgentsBaseVariables = {
    			entity: entity,
			scanConfigurationName: scanConfigurationName,
			scanType: scanType
    		}
    				return this.createAuditV2AgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.createAuditV2));
    	}

    	/**  */
    	@InjectArgs
    	public createAudit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity?') entity?: InventoryHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: CreateAuditAgentsBaseVariables = {
    			entity: entity
    		}
    				return this.createAuditAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.createAudit));
    	}

    	/**  */
    	@InjectArgs
    	public importAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryId') inventoryHistoryId: string,
		@Args('model?') model?: string,
		@Args('manufacturer?') manufacturer?: string,
		@Args('asset?') asset?: AssetInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsset> {
    
    		let variables: ImportAssetAgentsBaseVariables = {
    			asset: asset,
			manufacturer: manufacturer,
			model: model,
			inventoryHistoryId: inventoryHistoryId
    		}
    				return this.importAssetAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.importAsset));
    	}

    	/**  */
    	@InjectArgs
    	public importLdapOrganizationalUnit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('initialize') initialize: boolean,
		@Args('id') id: string,
		@Args('ldapOrganizationalUnits?') ldapOrganizationalUnits?: Array<Maybe<LdapOrganizationalUnitInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportLdapOrganizationalUnitAgentsBaseVariables = {
    			ldapOrganizationalUnits: ldapOrganizationalUnits,
			id: id,
			initialize: initialize
    		}
    				return this.importLdapOrganizationalUnitAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.importLdapOrganizationalUnit));
    	}

    	/**  */
    	@InjectArgs
    	public importOrganizationalUnit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('organizationalUnits?') organizationalUnits?: Array<Maybe<OrganizationalUnitInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportOrganizationalUnitAgentsBaseVariables = {
    			organizationalUnits: organizationalUnits
    		}
    				return this.importOrganizationalUnitAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.importOrganizationalUnit));
    	}

    	/**  */
    	@InjectArgs
    	public updateHistoryLdap(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('ldapHistory?') ldapHistory?: LdapHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UpdateHistoryLdapAgentsBaseVariables = {
    			id: id,
			ldapHistory: ldapHistory
    		}
    				return this.updateHistoryLdapAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateHistoryLdap));
    	}

    	/**  */
    	@InjectArgs
    	public finish(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationId') scanConfigurationId: string,
		@Args('computer') computer: boolean,
		@Args('exception?') exception?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: FinishAgentsBaseVariables = {
    			scanConfigurationId: scanConfigurationId,
			exception: exception,
			computer: computer
    		}
    				return this.finishAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.finish));
    	}

    	/**  */
    	@InjectArgs
    	public isRun(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationId') scanConfigurationId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: IsRunAgentsBaseVariables = {
    			scanConfigurationId: scanConfigurationId
    		}
    				return this.isRunAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.isRun));
    	}

    	/**  */
    	@InjectArgs
    	public canceling(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationId') scanConfigurationId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CancelingAgentsBaseVariables = {
    			scanConfigurationId: scanConfigurationId
    		}
    				return this.cancelingAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.canceling));
    	}

    	/**  */
    	@InjectArgs
    	public totalAuditComputer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('total') total: number,
		@Args('scanConfigurationId') scanConfigurationId: string,
		@Args('appendTo') appendTo: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: TotalAuditComputerAgentsBaseVariables = {
    			scanConfigurationId: scanConfigurationId,
			total: total,
			appendTo: appendTo
    		}
    				return this.totalAuditComputerAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.totalAuditComputer));
    	}

    	/**  */
    	@InjectArgs
    	public importUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('users?') users?: Array<Maybe<UserInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportUserAgentsBaseVariables = {
    			users: users
    		}
    				return this.importUserAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.importUser));
    	}

    	/**  */
    	@InjectArgs
    	public importClarilogLocalAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryId') inventoryHistoryId: string,
		@Args('model?') model?: string,
		@Args('manufacturer?') manufacturer?: string,
		@Args('assetId?') assetId?: string,
		@Args('asset?') asset?: AssetInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAsset> {
    
    		let variables: ImportClarilogLocalAgentAgentsBaseVariables = {
    			assetId: assetId,
			asset: asset,
			manufacturer: manufacturer,
			model: model,
			inventoryHistoryId: inventoryHistoryId
    		}
    				return this.importClarilogLocalAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.importClarilogLocalAgent));
    	}

    	/**  */
    	@InjectArgs
    	public updateFilesLocalAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileDescriptor> {
    
    		let variables: UpdateFilesLocalAgentAgentsBaseVariables = {
    
    		}
    				return this.updateFilesLocalAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateFilesLocalAgent));
    	}

    	/**  */
    	@InjectArgs
    	public startInventoryLocalAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetId') assetId: string,
		@Args('inventoryHistory?') inventoryHistory?: InventoryHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: StartInventoryLocalAgentAgentsBaseVariables = {
    			assetId: assetId,
			inventoryHistory: inventoryHistory
    		}
    				return this.startInventoryLocalAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.startInventoryLocalAgent));
    	}

    	/**  */
    	@InjectArgs
    	public updateStatusLocalAgent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('updaterState') updaterState: UpdaterState,
		@Args('agentId') agentId: string,
		@Args('version?') version?: string,
		@Args('error?') error?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateStatusLocalAgentAgentsBaseVariables = {
    			agentId: agentId,
			updaterState: updaterState,
			version: version,
			error: error
    		}
    				return this.updateStatusLocalAgentAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateStatusLocalAgent));
    	}

    	/**  */
    	@InjectArgs
    	public updateCampaignHistory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: HistoryState,
		@Args('id') id: string,
		@Args('error?') error?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateCampaignHistoryAgentsBaseVariables = {
    			id: id,
			state: state,
			error: error
    		}
    				return this.updateCampaignHistoryAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.updateCampaignHistory));
    	}

    	/**  */
    	@InjectArgs
    	public createToken(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity?') entity?: AgentTokenInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: CreateTokenAgentsBaseVariables = {
    			entity: entity
    		}
    				return this.createTokenAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.createToken));
    	}

    	/**  */
    	@InjectArgs
    	public deleteToken(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTokenAgentsBaseVariables = {
    			id: id
    		}
    				return this.deleteTokenAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agents.deleteToken));
    	}
    
}