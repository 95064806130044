import { NgModule } from '@angular/core';
import { IncidentHelpDeskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/incident.help-desk.service';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import { RequestHelpDeskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/request.help-desk.service';
import { TicketTaskHelpMeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket-task-help-me.service';
import { ReportService } from '@clarilog/modules2/report/shared/services/report.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { RoutingCoreService } from "@clarilog/core/services2/graphql/generated-types/services/routing.service";
import { TicketModelTaskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket-model-task';
import { LoanHelpDeskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/loan.help-desk.service';
import { AssetHelpDeskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.help-desk.service';


@NgModule({
  providers: [
    // Dashboard

    {
      provide: 'ReportService',
      useClass: ReportService,
    },
    {
      provide: 'TranslatedFieldHelperService',
      useClass: TranslatedFieldHelperService,
    },
    {
      provide: 'IncidentHelpDeskCoreService',
      useClass: IncidentHelpDeskCoreService,
    },
    {
      provide: 'RequestHelpDeskCoreService',
      useClass: RequestHelpDeskCoreService,
    },
    {
      provide: 'TicketTaskHelpMeCoreService',
      useClass: TicketTaskHelpMeCoreService,
    },
    {
      provide: 'LocalStorageService',
      useClass: LocalStorageService,
    },
		{ 
      provide: 'RoutingCoreService', 
      useClass: RoutingCoreService,
    },
		{ provide: 'TicketModelTaskCoreService', 
      useClass: TicketModelTaskCoreService 
    },
    { 
      provide: 'LoanHelpDeskCoreService', 
      useClass: LoanHelpDeskCoreService 
    },
    { 
      provide: 'AssetHelpDeskCoreService', 
      useClass: AssetHelpDeskCoreService 
    },


  ],
})
export class SharedServicesModule {}
