import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql';
import { Observable } from 'rxjs';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { BudgetBaseService } from '../service-bases';
import {
  FilterOfBudget,
  QueryContextOfBudget,
  ServiceListResultOfBudget,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('budget')
export class BudgetCoreService extends BudgetBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('name'),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  @InjectArgs
  public findCustomNotParent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('budgetIds') budgetIds: any,
    @Args('options?') options?: QueryContextOfBudget,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfBudget,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfBudget> {
    let addBudgetIds = null;
    if (budgetIds != undefined) {
      addBudgetIds = budgetIds['itemsAdded'];
    }
    return this.findNotParent(
      fields,
      addBudgetIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }
}
