import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';
import { Filter } from '@clarilog/shared2/models/schema';
import { ModelCompilerContextService } from '@clarilog/shared2/services/compiler/model-compiler-context.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlField, GqlFields, GqlSubField, GqlSubFieldArg } from '../helpers';
import { UserBaseService } from '../service-bases';
import {
  FilterOfUser,
  QueryContextOfUser,
  ServiceListResultOfDistinguishedNameModel,
  ServiceListResultOfGroupAzureAd,
  ServiceListResultOfUser,
  ServiceSingleResultOfPreferences,
  ServiceSingleResultOfPrivilege,
  ServiceSingleResultOfString,
  ServiceSingleResultOfUserPrivilege,
} from '../types';
import { PrivilegeCoreService } from './privilege.service';
import { UserPrivilegeCoreService } from './user-privilege.service';

@Injectable({ providedIn: 'root' })
@Authorize('user')
export class UserCoreService extends UserBaseService {
  constructor(
    injector: Injector,
    private serviceInjector: Injector,
    private authorizationService: AuthorizationCoreService,
    private modelCompilerContextService: ModelCompilerContextService,
  ) {
    super(injector);
  }

  /** Permet de généré un filtre particulier pour le composant property-change.component en fonction du field utilisé */
  @InjectArgs
  public propertyChangeCustomSource(
    @Args('defaultSource') defaultSource: any,
    @Args('fieldName') fieldName: string,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    if (fieldName != undefined) {
      if (
        fieldName.includes('operatorId') ||
        fieldName.includes('operatorAffectedId')
      ) {
        return this.findOperatorAndSupplier(
          fields,
          options,
          undefined,
          filter,
          extendedVariables,
        );
      } else if (fieldName.includes('operatorReferentId')) {
        return this.findOperatorUser(
          fields,
          options,
          filter,
          extendedVariables,
        );
      }
    }

    return defaultSource;
  }

  @InjectArgs
  public getPrivilege(): Observable<ServiceSingleResultOfPrivilege> {
    let service = this.serviceInjector.get(PrivilegeCoreService);
    let extendedVariables = {};
    let privilegeFields = service.getFields();

    return this.userPrivilege(privilegeFields, extendedVariables);
  }

  @InjectArgs
  public getHelpDeskPrivilege(): Observable<ServiceSingleResultOfUserPrivilege> {
    let service = this.serviceInjector.get(UserPrivilegeCoreService);
    let extendedVariables = {};
    let privilegeFields = service.getFields();

    return this.helpDeskPrivilege(privilegeFields, extendedVariables);
  }

  @InjectArgs
  public getUserLocalize(): Observable<ServiceSingleResultOfPreferences> {
    let extendedVariables = {};
    let preferenceFields = UserCoreService.getLanguageFields();
    return this.userLocalize(preferenceFields, extendedVariables);
  }

  public static getLanguageFields(): GqlFields {
    return [GqlSubField.create('data', [GqlField.create('lang')])];
  }

  @InjectArgs
  public getDisplayLanguage(): Observable<ServiceSingleResultOfString> {
    let extendedVariables = {};
    return this.displayLanguage([GqlField.create('data')], extendedVariables);
  }

  @InjectArgs
  public findUserUnassociatedLinkLocation(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter: FilterOfUser,
    @Args('options?') options: QueryContextOfUser,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      locationId: {
        eq: null,
      },
    };

    if (filter != undefined) {
      newFilter.and = [filter];
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  @InjectArgs
  public getUserRoleTypes(@Args('id') id: string): Observable<string[]> {
    let fields: GqlFields = [
      GqlSubField.create('data', [GqlField.create('roleTypes')]),
    ];

    let extendedVariables = {};

    return this.get(fields, id, extendedVariables).pipe(
      map((x) => x.data.roleTypes),
    );
  }

  @InjectArgs
  public findUserUnassociatedLinkOrganizationalUnit(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter: FilterOfUser,
    @Args('options?') options: QueryContextOfUser,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      organizationalUnitId: {
        eq: null,
      },
    };

    if (filter != undefined) {
      newFilter.and = [filter];
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  @InjectArgs
  public findUserUnassociatedLinkLocationAndOrganizationalUnit(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter: FilterOfUser,
    @Args('options?') options: QueryContextOfUser,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      organizationalUnitId: {
        eq: null,
      },
      locationId: { eq: null },
    };

    if (filter != undefined) {
      newFilter.and = [filter];
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  @InjectArgs
  public findUnassociatedToOwnerAssets(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter: FilterOfUser,
    @Args('options?') options: QueryContextOfUser,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfUser> {
    let newFilter: FilterOfUser = {
      and: [
        {
          assetIds: {
            size: 0,
          },
        },
        { mainAssetId: { eq: null } },
      ],
    };

    if (filter != undefined) {
      newFilter.and.push(filter);
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  @InjectArgs
  public findSubordinatesLdap(
    @Args('fields') fields: GqlFields,
    @Args('id') id: string,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfDistinguishedNameModel> {
    fields = [
      GqlSubField.create('data', [
        GqlSubField.create('subordinatesLdap', fields),
      ]),
    ];

    return this.get(fields, id, extendedVariables).pipe(
      map((data) => data.data.subordinatesLdap),
    );
  }

  @InjectArgs
  public getConnectedUser(): string {
    return this.authorizationService.user.getClaim('userId');
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('email'),
        GqlField.create('name'),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  @InjectArgs
  public memberOfGroups(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfDistinguishedNameModel> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('memberOfLdap', fields, null, [
        GqlSubFieldArg.create('filterOfCollaborators', 'filter'),
        GqlSubFieldArg.create('optionsOfCollaborators', 'options'),
      ]),
      GqlField.create('memberOf'),
    ]);
    extendedVariables['filterOfCollaborators'] = filter;
    extendedVariables['optionsOfCollaborators'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.memberOfLdap),
    );
  }

  @InjectArgs
  public memberOfAzureGroup(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfGroupAzureAd> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('memberOfAzure', fields, null, [
        GqlSubFieldArg.create('filterOfGroupAzureAd', 'filter'),
        GqlSubFieldArg.create('optionsOfGroupAzureAd', 'options'),
      ]),
      GqlSubField.create('memberOfAzureGroup', [
        GqlField.create('name'),
        GqlField.create('type'),
      ]),
    ]);
    extendedVariables['filterOfGroupAzureAd'] = filter;
    extendedVariables['optionsOfGroupAzureAd'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.memberOfAzure),
    );
  }

  getAzureGroupTypeDatasource(): Array<{ value: string; key: string }> {
    return [
      {
        key: 'security',
        value: TranslateService.get('entities/scanByAzureAd/typeSecurity'),
      },
      {
        key: 'microsoft365',
        value: TranslateService.get('entities/scanByAzureAd/typeMicrosoft365'),
      },
    ];
  }

  @InjectArgs
  public collaboratorsLdap(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfDistinguishedNameModel> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('findCollaboratorsLdap', fields, null, [
        GqlSubFieldArg.create('filterOfCollaborators', 'filter'),
        GqlSubFieldArg.create('optionsOfCollaborators', 'options'),
      ]),
      GqlField.create('distinguishedName'),
    ]);
    extendedVariables['filterOfCollaborators'] = filter;
    extendedVariables['optionsOfCollaborators'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.findCollaboratorsLdap),
    );
  }

  @InjectArgs
  public findUserByRole(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    if (id != null) {
      filter = { memberOfIds: { elemMatch: { eq: id } } };
    }
    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findUserInvited(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    if (filter == null) {
      filter = {};
    }
    filter.invitation = { sendDate: { ne: null } };
    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findUserEnableHelpDesk(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    if (filter == undefined) {
      filter = {};
    }
    filter.helpDeskActivated = { eq: true };

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Obtient les filtres par défaut */
  async filters(
    @Args('source') source: string,
    @Args('method') method: string,
  ): Promise<Filter[]> {
    let filters: Filter[];

    if (source == undefined || method == undefined) {
      return [];
    }

    filters = [
      {
        items: [
          {
            text: TranslateService.get('globals/all'),
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'all',
              },
            },
          },
        ],
      },
      {
        text: TranslateService.get('common/byRelation'),
        items: [
          {
            text: TranslateService.get('entities/location/_title/plural'),
            source: await (<any>(
              this.modelCompilerContextService
                .source('LocationCoreService', 'find')
                .toPromise()
            )),
            keyExpr: 'id',
            filterExpr: 'locationId',
            displayExpr: 'name',
            translatable: true,
            parentIdExpr: 'parentId',
            multiple: true,
            recursive: true,
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'location',
              },
            },
          },
          {
            text: TranslateService.get(
              'entities/organizationalUnit/_title/plural',
            ),
            source: await (<any>(
              this.modelCompilerContextService
                .source('OrganizationalUnitCoreService', 'find')
                .toPromise()
            )),
            filterExpr: 'organizationalUnitId',
            keyExpr: 'id',
            parentIdExpr: 'parentId',
            displayExpr: 'name',
            multiple: true,
            recursive: true,
            translatable: true,
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'organizational-unit',
              },
            },
          },
        ],
      },
    ];

    return filters;
  }

  /**  */
  @InjectArgs
  public findUnassociatedSubordinates(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter == null) {
      filter = {};
    }

    filter.id = { ne: id };

    return super.findUnassociatedSubordinates(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findOperatorByOperatorTeamAffected(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('operatorTeamAffectedId?') operatorTeamAffectedId?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    return this.findOperatorByOperatorTeam(
      fields,
      options,
      operatorTeamAffectedId,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findOperatorByOperatorTeamAffectedLevelSupport(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('levelSupport') levelSupport?: number,
    @Args('options?') options?: QueryContextOfUser,
    @Args('operatorTeamAffectedId?') operatorTeamAffectedId?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    return this.findOperatorByOperatorTeamLevelSupport(
      fields,
      options,
      operatorTeamAffectedId,
      levelSupport,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findOperatorByOperatorTeamReferent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('operatorTeamReferentId?') operatorTeamReferentId?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    return this.findOperatorByOperatorTeam(
      fields,
      options,
      operatorTeamReferentId,
      filter,
      extendedVariables,
    );
  }

  /**Permet de révoquer un ou plusieurs utilisateurs */
  @InjectArgs
  public revokeUser(
    @Args('listUser') listUser: string[],
    @Args('sendEmail') sendemail: boolean,
  ): Observable<{ state: string; message: string }> {
    let notifMessage = {
      state: 'error',
      message: TranslateService.get('actionError'),
    };

    return new Observable<{ state: string; message: string }>((observer) => {
      this.cancelInvitations(
        [
          GqlField.create('data'),
          GqlSubField.create('errors', [GqlField.create('messageError')]),
        ],
        listUser,
        sendemail,
      ).subscribe((result) => {
        if (result.data) {
          notifMessage = {
            state: 'success',
            message: TranslateService.get('confirmCancelInvitationSuccess', {
              count: listUser?.length,
            }),
          };
        } else {
          notifMessage = {
            state: 'error',
            message: result.errors
              .map(function (r) {
                return r.messageError;
              })
              .join('\r\n'),
          };
        }

        observer.next(notifMessage);
        observer.complete();
      });
    });
  }

  /**
   * permet d'appliquer une classe css à un composant en fonction du vip
   */
  @InjectArgs
  public vipUserRed(
    @Args('iRefValueExpr') iRefValueExpr: any,
    @Args('textbox') textbox,
    @Args('modelState') modelState,
  ) {
    let displayExpr: string;
    let refValue: any;
    let vip: boolean;

    if (iRefValueExpr != null && typeof iRefValueExpr == 'string') {
      displayExpr = iRefValueExpr.replace('Id', '');
      refValue = this.getExprValue(
        modelState.sharedContext.entity,
        displayExpr,
      );

      vip = refValue?.data?.vip;
    } else {
      vip = iRefValueExpr.addedItems[0]?.vip;
    }

    if (vip == true) {
      let textboxModif = textbox.instance
        .element()
        .querySelector('.dx-texteditor-input');
      textboxModif.classList.add('redText');
      return of(textboxModif);
    } else {
      let textboxModif = textbox.instance
        .element()
        .querySelector('.dx-texteditor-input');
      textboxModif.classList.remove('redText');
      return of(textboxModif);
    }
  }

  @InjectArgs
  public getExprValue(@Args('item') item, @Args('displayExpr') displayExpr) {
    const fields = displayExpr.split('.');
    let value = item;

    if (value != undefined) {
      for (let el of fields) {
        if (value != undefined) {
          value = value[el];
        }
      }
    }
    return value;
  }

  @InjectArgs
  public customOperatorDisplayExpr(@Args('item') item) {
    if (item?.__typename === 'User') {
      return of(
        item?.serviceDeskActivated
          ? TranslateService.get('globals/userSupplier').concat(item?.name)
          : item?.name,
      );
    }

    return;
  }
  /**  */
  @InjectArgs
  public findLinkedMembersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        memberOfPopulationIds =
          filter.and[index].memberOfPopulationIds.elemMatch['in'];
        delete filter.and[index].memberOfPopulationIds;
      } else {
        memberOfPopulationIds = filter.memberOfPopulationIds.elemMatch['in'];
        delete filter.memberOfPopulationIds;
      }
    }
    return this.findLinkedMembers(
      fields,
      memberOfPopulationIds,
      id,
      options,
      filter,
      extendedVariables,
    );
  }
  /**  */
  @InjectArgs
  public findAvailableMembersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfIds') memberOfIds: Array<string>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        memberOfPopulationIds =
          filter.and[index].memberOfPopulationIds.elemMatch['in'];
        delete filter.and[index].memberOfPopulationIds;
      } else {
        memberOfPopulationIds = filter.memberOfPopulationIds?.elemMatch['in'];
        delete filter.memberOfPopulationIds;
      }
    }
    return this.findAvailableMembers(
      fields,
      memberOfPopulationIds,
      memberOfIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }
  @InjectArgs
  getFields(): (GqlSubField | GqlField)[] {
    return [
      GqlField.create('name'),
      GqlField.create('email'),
      GqlField.create('firstName'),
      GqlField.create('lastName'),
      GqlField.create('userPrincipalName'),
    ];
  }
}
