import {
  Injectable,
  ComponentFactoryResolver,
  TemplateRef,
  Injector,
  ApplicationRef,
  ComponentRef,
} from '@angular/core';
import { CoreTemplatesComponent } from './templates.component';
import { TranslateService } from '../../services/translate/translate.service';

/** Permet d'utiliser les templates. */
@Injectable({
  providedIn: 'root',
})
export class TemplatesService {
  componentRef: ComponentRef<CoreTemplatesComponent>;
  isInitialize: boolean = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}
  /** Ajoute les templates au body. */
  appendToBody() {
    // Une seule fois.
    if (this.isInitialize === false) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          CoreTemplatesComponent,
        );
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      this.isInitialize = true;
    }
  }
  /** Récupère un template nommé via son id. */
  get(name: string): TemplateRef<any> {
    return this.componentRef.instance.get(name);
  }

  /**
   * Permet de traduire un libellé d'un tableau d'array
   * @param e
   * @param format
   */
  translateValue(e: string, format: string): string {
    if (format == undefined) {
      return e;
    } else {
      let translate = TranslateService.get('enums/' + format + '/' + e);
      if (translate.indexOf('[') >= 0) {
        return e;
      } else {
        return translate;
      }
    }
  }
}
