if (!String.prototype.toKebabCase) {
  String.prototype.toKebabCase = function() {
    return this.replace(/([a-z])([A-Z])/g, "$1-$2")
    .replace(/\s+/g, '-')
    .toLowerCase();
  };
}
if (!String.prototype.toPascalCase) {
  String.prototype.toPascalCase = function() {
    var converter = function(matches) {
      return matches[1].toUpperCase();
    };
  
    var result = this.replace(/(\-\w)/g, converter);
    
    
    result = result.charAt(0).toUpperCase() + result.slice(1);
    
    
    return result;
  };
}