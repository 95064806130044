<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <cl-toolbar class="action">
          <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
            <cl-toolbar-item-button
              [text]="'manage' | translate"
              [visible]="route !== undefined"
              (onClick)="onGoTo()"
              icon="fas fa-arrow-right"
            ></cl-toolbar-item-button>
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-left
    *ngIf="mode === LinkListMode.Current"
    [hide]="
      filters == undefined ||
      (filters.length === 1 && filters[0].items.length === 1)
    "
  >
    <clc-nav-menu>
      <clc-nav-group *ngFor="let filter of filters" [text]="filter.text">
        <clc-nav-menu-item
          *ngFor="let item of filter.items"
          [active]="item === currentFilter"
          [text]="item.text"
          [source]="item.source"
          [filterExpr]="item.filterExpr"
          [parentIdExpr]="item.parentIdExpr"
          [keyExpr]="item.keyExpr"
          [inContext]="item.inContext"
          [displayExpr]="item.displayExpr"
          [multiple]="item.multiple"
          [recursive]="item.recursive"
          (onClick)="currentFilter = item; onNavFilterClick($event)"
          (onItemSelectionChanged)="onItemSelectionChanged($event)"
        ></clc-nav-menu-item>
      </clc-nav-group>
    </clc-nav-menu>
  </cl-work-page-left>
  <cl-work-page-content>
    <div class="cl-hint" *ngIf="hintMessage != undefined">
      {{ hintMessage }}
    </div>
    <clc-list
      class="list"
      [isLiveUpdated]="isLiveUpdated"
      [canSelect]="selectSource != undefined || canDeleted"
      [source]="currentSource"
      [type]="type == undefined ? 'Grid' : type"
      [columns]="columns"
      [multiple]="multiple && !readOnly"
      [keyExpr]="keyExpr"
      [parentIdExpr]="parentIdExpr"
      [masterDetail]="masterDetail"
      [recursive]="false"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      (onSelectionKeyChanged)="onSelectionKeyChanged($event)"
      (onRowClick)="onRowListClick($event)"
      [forceLinkEmpty]="route != undefined"
      [clearSelectionRefresh]="false"
      [clearSelectedAfterFilterChange]="false"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
