import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {
  AngularGlobalizeModule,
  CANG_SUPPORTED_CULTURES,
} from '@code-art-eg/angular-globalize';

@NgModule({
  imports: [CommonModule, AngularGlobalizeModule.forRoot()],
  exports: [AngularGlobalizeModule],
  providers: [
    // Fournit les langues prises en charge par l'application.
    // Si vous ne spécifiez pas cette valeur, AngularGlobalizeModule.forRoot() utilisera par défaut la culture 'en' (États-Unis anglais).
    {
      provide: CANG_SUPPORTED_CULTURES,
      useValue: ['fr-FR', 'en-US', 'de-DE', 'nl-NL', 'it-IT'],
    },
  ],
})
export class GlobalizeModule {}
