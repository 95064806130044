import { Directive, Input } from "@angular/core";

/** Permet de créer une variable local. */
@Directive({
    selector:"[clLocalVariable]",
    exportAs:"clLocalVariable"
})
export class LocalVariableDirective {
    public value:any;

    @Input("clLocalVariable") 
    set clLocalVariable( value: any ) {
      this.value = value;
    }
    constructor( ) {
    }
}