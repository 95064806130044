import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetExchangeConnectorsBaseVariables, FirstExchangeConnectorsBaseVariables, CountExchangeConnectorsBaseVariables, FindExchangeConnectorsBaseVariables, SearchExchangeConnectorsBaseVariables, ExportSchemaExchangeConnectorsBaseVariables, ExportDataExchangeConnectorsBaseVariables, CustomQueryExchangeConnectorsBaseVariables, CustomQueryIdExchangeConnectorsBaseVariables, FindUnassociatedSecurityGroupsExchangeConnectorsBaseVariables, UsedExchangeConnectorsBaseVariables, FindCalendarsExchangeConnectorsBaseVariables, FindAppointmentsExchangeConnectorsBaseVariables, FindByOperatorIdAppointmentsExchangeConnectorsBaseVariables, ExistExchangeConnectorsBaseVariables, FindRecyclesExchangeConnectorsBaseVariables, CountRecyclesExchangeConnectorsBaseVariables, ReadOnlyExchangeConnectorsBaseVariables, FindArchivedExchangeConnectorsBaseVariables, CountAllExchangeConnectorsBaseVariables, FindDynamicPropertyFieldsExchangeConnectorsBaseVariables, FindUnassociatedUsersExchangeConnectorsBaseVariables, InsertExchangeConnectorsBaseVariables, UpdateExchangeConnectorsBaseVariables, ImportDataExchangeConnectorsBaseVariables, UpdateManyExchangeConnectorsBaseVariables, DeleteExchangeConnectorsBaseVariables, AddSecurityGroupExchangeConnectorsBaseVariables, RemoveSecurityGroupExchangeConnectorsBaseVariables, TestConnexionExchangeConnectorsBaseVariables, ResetPasswordExchangeConnectorsBaseVariables, RestoreExchangeConnectorsBaseVariables, RecycleExchangeConnectorsBaseVariables, RestoreArchivedExchangeConnectorsBaseVariables, ArchivedExchangeConnectorsBaseVariables, AddFileDynamicFieldExchangeConnectorsBaseVariables, RemoveFileDynamicFieldExchangeConnectorsBaseVariables } from '../gqls'
import { GetExchangeConnectorsDocument, FirstExchangeConnectorsDocument, CountExchangeConnectorsDocument, FindExchangeConnectorsDocument, SearchExchangeConnectorsDocument, ExportSchemaExchangeConnectorsDocument, ExportDataExchangeConnectorsDocument, CustomQueryExchangeConnectorsDocument, CustomQueryIdExchangeConnectorsDocument, FindUnassociatedSecurityGroupsExchangeConnectorsDocument, UsedExchangeConnectorsDocument, FindCalendarsExchangeConnectorsDocument, FindAppointmentsExchangeConnectorsDocument, FindByOperatorIdAppointmentsExchangeConnectorsDocument, ExistExchangeConnectorsDocument, FindRecyclesExchangeConnectorsDocument, CountRecyclesExchangeConnectorsDocument, ReadOnlyExchangeConnectorsDocument, FindArchivedExchangeConnectorsDocument, CountAllExchangeConnectorsDocument, FindDynamicPropertyFieldsExchangeConnectorsDocument, FindUnassociatedUsersExchangeConnectorsDocument, InsertExchangeConnectorsDocument, UpdateExchangeConnectorsDocument, ImportDataExchangeConnectorsDocument, UpdateManyExchangeConnectorsDocument, DeleteExchangeConnectorsDocument, AddSecurityGroupExchangeConnectorsDocument, RemoveSecurityGroupExchangeConnectorsDocument, TestConnexionExchangeConnectorsDocument, ResetPasswordExchangeConnectorsDocument, RestoreExchangeConnectorsDocument, RecycleExchangeConnectorsDocument, RestoreArchivedExchangeConnectorsDocument, ArchivedExchangeConnectorsDocument, AddFileDynamicFieldExchangeConnectorsDocument, RemoveFileDynamicFieldExchangeConnectorsDocument } from '../gqls'
import { ServiceSingleResultOfExchangeConnector, QueryContextOfExchangeConnector, FilterOfExchangeConnector, ServiceSingleResultOfInt64, ServiceListResultOfExchangeConnector, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceSingleResultOfBoolean, ServiceListResultOfExchangeCalendar, ExchangeConnectorInput, ServiceListResultOfAppointment, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfExchangeConnector, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ExchangeConnectorBaseService {
    
public modelName = 'exchangeConnector';
public modelPluralName = 'exchangeConnectors';

	private getExchangeConnectorsQuery: GetExchangeConnectorsDocument;
	private firstExchangeConnectorsQuery: FirstExchangeConnectorsDocument;
	private countExchangeConnectorsQuery: CountExchangeConnectorsDocument;
	private findExchangeConnectorsQuery: FindExchangeConnectorsDocument;
	private searchExchangeConnectorsQuery: SearchExchangeConnectorsDocument;
	private exportSchemaExchangeConnectorsQuery: ExportSchemaExchangeConnectorsDocument;
	private exportDataExchangeConnectorsQuery: ExportDataExchangeConnectorsDocument;
	private customQueryExchangeConnectorsQuery: CustomQueryExchangeConnectorsDocument;
	private customQueryIdExchangeConnectorsQuery: CustomQueryIdExchangeConnectorsDocument;
	private findUnassociatedSecurityGroupsExchangeConnectorsQuery: FindUnassociatedSecurityGroupsExchangeConnectorsDocument;
	private usedExchangeConnectorsQuery: UsedExchangeConnectorsDocument;
	private findCalendarsExchangeConnectorsQuery: FindCalendarsExchangeConnectorsDocument;
	private findAppointmentsExchangeConnectorsQuery: FindAppointmentsExchangeConnectorsDocument;
	private findByOperatorIdAppointmentsExchangeConnectorsQuery: FindByOperatorIdAppointmentsExchangeConnectorsDocument;
	private existExchangeConnectorsQuery: ExistExchangeConnectorsDocument;
	private findRecyclesExchangeConnectorsQuery: FindRecyclesExchangeConnectorsDocument;
	private countRecyclesExchangeConnectorsQuery: CountRecyclesExchangeConnectorsDocument;
	private readOnlyExchangeConnectorsQuery: ReadOnlyExchangeConnectorsDocument;
	private findArchivedExchangeConnectorsQuery: FindArchivedExchangeConnectorsDocument;
	private countAllExchangeConnectorsQuery: CountAllExchangeConnectorsDocument;
	private findDynamicPropertyFieldsExchangeConnectorsQuery: FindDynamicPropertyFieldsExchangeConnectorsDocument;
	private findUnassociatedUsersExchangeConnectorsQuery: FindUnassociatedUsersExchangeConnectorsDocument;
	private insertExchangeConnectorsMutation: InsertExchangeConnectorsDocument;
	private updateExchangeConnectorsMutation: UpdateExchangeConnectorsDocument;
	private importDataExchangeConnectorsMutation: ImportDataExchangeConnectorsDocument;
	private updateManyExchangeConnectorsMutation: UpdateManyExchangeConnectorsDocument;
	private deleteExchangeConnectorsMutation: DeleteExchangeConnectorsDocument;
	private addSecurityGroupExchangeConnectorsMutation: AddSecurityGroupExchangeConnectorsDocument;
	private removeSecurityGroupExchangeConnectorsMutation: RemoveSecurityGroupExchangeConnectorsDocument;
	private testConnexionExchangeConnectorsMutation: TestConnexionExchangeConnectorsDocument;
	private resetPasswordExchangeConnectorsMutation: ResetPasswordExchangeConnectorsDocument;
	private restoreExchangeConnectorsMutation: RestoreExchangeConnectorsDocument;
	private recycleExchangeConnectorsMutation: RecycleExchangeConnectorsDocument;
	private restoreArchivedExchangeConnectorsMutation: RestoreArchivedExchangeConnectorsDocument;
	private archivedExchangeConnectorsMutation: ArchivedExchangeConnectorsDocument;
	private addFileDynamicFieldExchangeConnectorsMutation: AddFileDynamicFieldExchangeConnectorsDocument;
	private removeFileDynamicFieldExchangeConnectorsMutation: RemoveFileDynamicFieldExchangeConnectorsDocument;

	constructor(private injector: Injector) {
		this.getExchangeConnectorsQuery = this.injector.get(GetExchangeConnectorsDocument);
		this.firstExchangeConnectorsQuery = this.injector.get(FirstExchangeConnectorsDocument);
		this.countExchangeConnectorsQuery = this.injector.get(CountExchangeConnectorsDocument);
		this.findExchangeConnectorsQuery = this.injector.get(FindExchangeConnectorsDocument);
		this.searchExchangeConnectorsQuery = this.injector.get(SearchExchangeConnectorsDocument);
		this.exportSchemaExchangeConnectorsQuery = this.injector.get(ExportSchemaExchangeConnectorsDocument);
		this.exportDataExchangeConnectorsQuery = this.injector.get(ExportDataExchangeConnectorsDocument);
		this.customQueryExchangeConnectorsQuery = this.injector.get(CustomQueryExchangeConnectorsDocument);
		this.customQueryIdExchangeConnectorsQuery = this.injector.get(CustomQueryIdExchangeConnectorsDocument);
		this.findUnassociatedSecurityGroupsExchangeConnectorsQuery = this.injector.get(FindUnassociatedSecurityGroupsExchangeConnectorsDocument);
		this.usedExchangeConnectorsQuery = this.injector.get(UsedExchangeConnectorsDocument);
		this.findCalendarsExchangeConnectorsQuery = this.injector.get(FindCalendarsExchangeConnectorsDocument);
		this.findAppointmentsExchangeConnectorsQuery = this.injector.get(FindAppointmentsExchangeConnectorsDocument);
		this.findByOperatorIdAppointmentsExchangeConnectorsQuery = this.injector.get(FindByOperatorIdAppointmentsExchangeConnectorsDocument);
		this.existExchangeConnectorsQuery = this.injector.get(ExistExchangeConnectorsDocument);
		this.findRecyclesExchangeConnectorsQuery = this.injector.get(FindRecyclesExchangeConnectorsDocument);
		this.countRecyclesExchangeConnectorsQuery = this.injector.get(CountRecyclesExchangeConnectorsDocument);
		this.readOnlyExchangeConnectorsQuery = this.injector.get(ReadOnlyExchangeConnectorsDocument);
		this.findArchivedExchangeConnectorsQuery = this.injector.get(FindArchivedExchangeConnectorsDocument);
		this.countAllExchangeConnectorsQuery = this.injector.get(CountAllExchangeConnectorsDocument);
		this.findDynamicPropertyFieldsExchangeConnectorsQuery = this.injector.get(FindDynamicPropertyFieldsExchangeConnectorsDocument);
		this.findUnassociatedUsersExchangeConnectorsQuery = this.injector.get(FindUnassociatedUsersExchangeConnectorsDocument);
		this.insertExchangeConnectorsMutation = this.injector.get(InsertExchangeConnectorsDocument);
		this.updateExchangeConnectorsMutation = this.injector.get(UpdateExchangeConnectorsDocument);
		this.importDataExchangeConnectorsMutation = this.injector.get(ImportDataExchangeConnectorsDocument);
		this.updateManyExchangeConnectorsMutation = this.injector.get(UpdateManyExchangeConnectorsDocument);
		this.deleteExchangeConnectorsMutation = this.injector.get(DeleteExchangeConnectorsDocument);
		this.addSecurityGroupExchangeConnectorsMutation = this.injector.get(AddSecurityGroupExchangeConnectorsDocument);
		this.removeSecurityGroupExchangeConnectorsMutation = this.injector.get(RemoveSecurityGroupExchangeConnectorsDocument);
		this.testConnexionExchangeConnectorsMutation = this.injector.get(TestConnexionExchangeConnectorsDocument);
		this.resetPasswordExchangeConnectorsMutation = this.injector.get(ResetPasswordExchangeConnectorsDocument);
		this.restoreExchangeConnectorsMutation = this.injector.get(RestoreExchangeConnectorsDocument);
		this.recycleExchangeConnectorsMutation = this.injector.get(RecycleExchangeConnectorsDocument);
		this.restoreArchivedExchangeConnectorsMutation = this.injector.get(RestoreArchivedExchangeConnectorsDocument);
		this.archivedExchangeConnectorsMutation = this.injector.get(ArchivedExchangeConnectorsDocument);
		this.addFileDynamicFieldExchangeConnectorsMutation = this.injector.get(AddFileDynamicFieldExchangeConnectorsDocument);
		this.removeFileDynamicFieldExchangeConnectorsMutation = this.injector.get(RemoveFileDynamicFieldExchangeConnectorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfExchangeConnector> {
    
    		let variables: GetExchangeConnectorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.get));
            }
            else{
                let result:ServiceSingleResultOfExchangeConnector={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfExchangeConnector> {
    
    		let variables: FirstExchangeConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountExchangeConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: FindExchangeConnectorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: SearchExchangeConnectorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaExchangeConnectorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataExchangeConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: CustomQueryExchangeConnectorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: CustomQueryIdExchangeConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsExchangeConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findUnassociatedSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.used));
    	}

    	/**  */
    	@InjectArgs
    	public findCalendars(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('exchangeConnector?') exchangeConnector?: ExchangeConnectorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeCalendar> {
    
    		let variables: FindCalendarsExchangeConnectorsBaseVariables = {
    			id: id,
			exchangeConnector: exchangeConnector
    		}
    				return this.findCalendarsExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findCalendars));
    	}

    	/**  */
    	@InjectArgs
    	public findAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('start') start: Date,
		@Args('end') end: Date,
		@Args('id?') id?: string,
		@Args('exchangeConnector?') exchangeConnector?: ExchangeConnectorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: FindAppointmentsExchangeConnectorsBaseVariables = {
    			id: id,
			exchangeConnector: exchangeConnector,
			start: start,
			end: end
    		}
    				return this.findAppointmentsExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findAppointments));
    	}

    	/**  */
    	@InjectArgs
    	public findByOperatorIdAppointments(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('start') start: Date,
		@Args('end') end: Date,
		@Args('operatorAffectedId?') operatorAffectedId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: FindByOperatorIdAppointmentsExchangeConnectorsBaseVariables = {
    			operatorAffectedId: operatorAffectedId,
			start: start,
			end: end
    		}
    				return this.findByOperatorIdAppointmentsExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findByOperatorIdAppointments));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistExchangeConnectorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: FindRecyclesExchangeConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesExchangeConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyExchangeConnectorsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfExchangeConnector,
		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExchangeConnector> {
    
    		let variables: FindArchivedExchangeConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllExchangeConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsExchangeConnectorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersExchangeConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersExchangeConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ExchangeConnectorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfExchangeConnector> {
    
    		let variables: InsertExchangeConnectorsBaseVariables = {
    			entity: entity
    		}
    				return this.insertExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfExchangeConnector> {
    
    		let variables: UpdateExchangeConnectorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataExchangeConnectorsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfExchangeConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyExchangeConnectorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupExchangeConnectorsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupExchangeConnectorsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public testConnexion(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('exchangeConnector?') exchangeConnector?: ExchangeConnectorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: TestConnexionExchangeConnectorsBaseVariables = {
    			exchangeConnector: exchangeConnector
    		}
    				return this.testConnexionExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.testConnexion));
    	}

    	/**  */
    	@InjectArgs
    	public resetPassword(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('newPassword?') newPassword?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ResetPasswordExchangeConnectorsBaseVariables = {
    			id: id,
			newPassword: newPassword
    		}
    				return this.resetPasswordExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.resetPassword));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedExchangeConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldExchangeConnectorsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldExchangeConnectorsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldExchangeConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.exchangeConnectors.removeFileDynamicField));
    	}
    
}