import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLdapOrganizationalUnitsBaseVariables, FirstLdapOrganizationalUnitsBaseVariables, CountLdapOrganizationalUnitsBaseVariables, FindLdapOrganizationalUnitsBaseVariables, SearchLdapOrganizationalUnitsBaseVariables, ExportSchemaLdapOrganizationalUnitsBaseVariables, ExportDataLdapOrganizationalUnitsBaseVariables, CustomQueryLdapOrganizationalUnitsBaseVariables, CustomQueryIdLdapOrganizationalUnitsBaseVariables, UsedLdapOrganizationalUnitsBaseVariables, FindTreeLdapLdapOrganizationalUnitsBaseVariables, FindTreeLdapByScanconfigurationLdapOrganizationalUnitsBaseVariables, ExistLdapOrganizationalUnitsBaseVariables, InsertLdapOrganizationalUnitsBaseVariables, UpdateLdapOrganizationalUnitsBaseVariables, ImportDataLdapOrganizationalUnitsBaseVariables, UpdateManyLdapOrganizationalUnitsBaseVariables, DeleteLdapOrganizationalUnitsBaseVariables } from '../gqls'
import { GetLdapOrganizationalUnitsDocument, FirstLdapOrganizationalUnitsDocument, CountLdapOrganizationalUnitsDocument, FindLdapOrganizationalUnitsDocument, SearchLdapOrganizationalUnitsDocument, ExportSchemaLdapOrganizationalUnitsDocument, ExportDataLdapOrganizationalUnitsDocument, CustomQueryLdapOrganizationalUnitsDocument, CustomQueryIdLdapOrganizationalUnitsDocument, UsedLdapOrganizationalUnitsDocument, FindTreeLdapLdapOrganizationalUnitsDocument, FindTreeLdapByScanconfigurationLdapOrganizationalUnitsDocument, ExistLdapOrganizationalUnitsDocument, InsertLdapOrganizationalUnitsDocument, UpdateLdapOrganizationalUnitsDocument, ImportDataLdapOrganizationalUnitsDocument, UpdateManyLdapOrganizationalUnitsDocument, DeleteLdapOrganizationalUnitsDocument } from '../gqls'
import { ServiceSingleResultOfLdapOrganizationalUnit, QueryContextOfLdapOrganizationalUnit, FilterOfLdapOrganizationalUnit, ServiceSingleResultOfInt64, ServiceListResultOfLdapOrganizationalUnit, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LdapObjectClassType, LdapOrganizationalUnitInput, FieldUpdateOperatorOfLdapOrganizationalUnit } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LdapOrganizationalUnitBaseService {
    
public modelName = 'ldapOrganizationalUnit';
public modelPluralName = 'ldapOrganizationalUnits';

	private getLdapOrganizationalUnitsQuery: GetLdapOrganizationalUnitsDocument;
	private firstLdapOrganizationalUnitsQuery: FirstLdapOrganizationalUnitsDocument;
	private countLdapOrganizationalUnitsQuery: CountLdapOrganizationalUnitsDocument;
	private findLdapOrganizationalUnitsQuery: FindLdapOrganizationalUnitsDocument;
	private searchLdapOrganizationalUnitsQuery: SearchLdapOrganizationalUnitsDocument;
	private exportSchemaLdapOrganizationalUnitsQuery: ExportSchemaLdapOrganizationalUnitsDocument;
	private exportDataLdapOrganizationalUnitsQuery: ExportDataLdapOrganizationalUnitsDocument;
	private customQueryLdapOrganizationalUnitsQuery: CustomQueryLdapOrganizationalUnitsDocument;
	private customQueryIdLdapOrganizationalUnitsQuery: CustomQueryIdLdapOrganizationalUnitsDocument;
	private usedLdapOrganizationalUnitsQuery: UsedLdapOrganizationalUnitsDocument;
	private findTreeLdapLdapOrganizationalUnitsQuery: FindTreeLdapLdapOrganizationalUnitsDocument;
	private findTreeLdapByScanconfigurationLdapOrganizationalUnitsQuery: FindTreeLdapByScanconfigurationLdapOrganizationalUnitsDocument;
	private existLdapOrganizationalUnitsQuery: ExistLdapOrganizationalUnitsDocument;
	private insertLdapOrganizationalUnitsMutation: InsertLdapOrganizationalUnitsDocument;
	private updateLdapOrganizationalUnitsMutation: UpdateLdapOrganizationalUnitsDocument;
	private importDataLdapOrganizationalUnitsMutation: ImportDataLdapOrganizationalUnitsDocument;
	private updateManyLdapOrganizationalUnitsMutation: UpdateManyLdapOrganizationalUnitsDocument;
	private deleteLdapOrganizationalUnitsMutation: DeleteLdapOrganizationalUnitsDocument;

	constructor(private injector: Injector) {
		this.getLdapOrganizationalUnitsQuery = this.injector.get(GetLdapOrganizationalUnitsDocument);
		this.firstLdapOrganizationalUnitsQuery = this.injector.get(FirstLdapOrganizationalUnitsDocument);
		this.countLdapOrganizationalUnitsQuery = this.injector.get(CountLdapOrganizationalUnitsDocument);
		this.findLdapOrganizationalUnitsQuery = this.injector.get(FindLdapOrganizationalUnitsDocument);
		this.searchLdapOrganizationalUnitsQuery = this.injector.get(SearchLdapOrganizationalUnitsDocument);
		this.exportSchemaLdapOrganizationalUnitsQuery = this.injector.get(ExportSchemaLdapOrganizationalUnitsDocument);
		this.exportDataLdapOrganizationalUnitsQuery = this.injector.get(ExportDataLdapOrganizationalUnitsDocument);
		this.customQueryLdapOrganizationalUnitsQuery = this.injector.get(CustomQueryLdapOrganizationalUnitsDocument);
		this.customQueryIdLdapOrganizationalUnitsQuery = this.injector.get(CustomQueryIdLdapOrganizationalUnitsDocument);
		this.usedLdapOrganizationalUnitsQuery = this.injector.get(UsedLdapOrganizationalUnitsDocument);
		this.findTreeLdapLdapOrganizationalUnitsQuery = this.injector.get(FindTreeLdapLdapOrganizationalUnitsDocument);
		this.findTreeLdapByScanconfigurationLdapOrganizationalUnitsQuery = this.injector.get(FindTreeLdapByScanconfigurationLdapOrganizationalUnitsDocument);
		this.existLdapOrganizationalUnitsQuery = this.injector.get(ExistLdapOrganizationalUnitsDocument);
		this.insertLdapOrganizationalUnitsMutation = this.injector.get(InsertLdapOrganizationalUnitsDocument);
		this.updateLdapOrganizationalUnitsMutation = this.injector.get(UpdateLdapOrganizationalUnitsDocument);
		this.importDataLdapOrganizationalUnitsMutation = this.injector.get(ImportDataLdapOrganizationalUnitsDocument);
		this.updateManyLdapOrganizationalUnitsMutation = this.injector.get(UpdateManyLdapOrganizationalUnitsDocument);
		this.deleteLdapOrganizationalUnitsMutation = this.injector.get(DeleteLdapOrganizationalUnitsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapOrganizationalUnit> {
    
    		let variables: GetLdapOrganizationalUnitsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.get));
            }
            else{
                let result:ServiceSingleResultOfLdapOrganizationalUnit={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapOrganizationalUnit> {
    
    		let variables: FirstLdapOrganizationalUnitsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLdapOrganizationalUnitsBaseVariables = {
    			filter: filter
    		}
    				return this.countLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: FindLdapOrganizationalUnitsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: SearchLdapOrganizationalUnitsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLdapOrganizationalUnitsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLdapOrganizationalUnitsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: CustomQueryLdapOrganizationalUnitsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
		@Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: CustomQueryIdLdapOrganizationalUnitsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLdapOrganizationalUnitsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.used));
    	}

    	/**  */
    	@InjectArgs
    	public findTreeLdap(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: FindTreeLdapLdapOrganizationalUnitsBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType
    		}
    				return this.findTreeLdapLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.findTreeLdap));
    	}

    	/**  */
    	@InjectArgs
    	public findTreeLdapByScanconfiguration(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapOrganizationalUnit> {
    
    		let variables: FindTreeLdapByScanconfigurationLdapOrganizationalUnitsBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType,
			id: id
    		}
    				return this.findTreeLdapByScanconfigurationLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.findTreeLdapByScanconfiguration));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLdapOrganizationalUnitsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLdapOrganizationalUnitsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LdapOrganizationalUnitInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapOrganizationalUnit> {
    
    		let variables: InsertLdapOrganizationalUnitsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLdapOrganizationalUnitsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapOrganizationalUnit> {
    
    		let variables: UpdateLdapOrganizationalUnitsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLdapOrganizationalUnitsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLdapOrganizationalUnitsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLdapOrganizationalUnitsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapOrganizationalUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLdapOrganizationalUnitsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLdapOrganizationalUnitsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLdapOrganizationalUnitsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLdapOrganizationalUnitsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapOrganizationalUnits.delete));
    	}
    
}