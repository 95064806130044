import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanImportOptionsBaseVariables, FirstScanImportOptionsBaseVariables, CountScanImportOptionsBaseVariables, FindScanImportOptionsBaseVariables, SearchScanImportOptionsBaseVariables, ExportSchemaScanImportOptionsBaseVariables, ExportDataScanImportOptionsBaseVariables, CustomQueryScanImportOptionsBaseVariables, CustomQueryIdScanImportOptionsBaseVariables, UsedScanImportOptionsBaseVariables, FindAllUnassociatedScanConfigurationsScanImportOptionsBaseVariables, FindAllAssociatedScanConfigurationsScanImportOptionsBaseVariables, ExistScanImportOptionsBaseVariables, FindUnassociatedAssetsScanImportOptionsBaseVariables, FindUnassociatedInventoryHistoriesScanImportOptionsBaseVariables, InsertScanImportOptionsBaseVariables, UpdateScanImportOptionsBaseVariables, ImportDataScanImportOptionsBaseVariables, UpdateManyScanImportOptionsBaseVariables, DeleteScanImportOptionsBaseVariables, OrderScanImportOptionsBaseVariables, AddAssetsScanImportOptionsBaseVariables, RemoveAssetsScanImportOptionsBaseVariables, AddScanConfigurationsScanImportOptionsBaseVariables, RemoveScanConfigurationsScanImportOptionsBaseVariables, AddInventoryHistoriesScanImportOptionsBaseVariables, RemoveInventoryHistoriesScanImportOptionsBaseVariables } from '../gqls'
import { GetScanImportOptionsDocument, FirstScanImportOptionsDocument, CountScanImportOptionsDocument, FindScanImportOptionsDocument, SearchScanImportOptionsDocument, ExportSchemaScanImportOptionsDocument, ExportDataScanImportOptionsDocument, CustomQueryScanImportOptionsDocument, CustomQueryIdScanImportOptionsDocument, UsedScanImportOptionsDocument, FindAllUnassociatedScanConfigurationsScanImportOptionsDocument, FindAllAssociatedScanConfigurationsScanImportOptionsDocument, ExistScanImportOptionsDocument, FindUnassociatedAssetsScanImportOptionsDocument, FindUnassociatedInventoryHistoriesScanImportOptionsDocument, InsertScanImportOptionsDocument, UpdateScanImportOptionsDocument, ImportDataScanImportOptionsDocument, UpdateManyScanImportOptionsDocument, DeleteScanImportOptionsDocument, OrderScanImportOptionsDocument, AddAssetsScanImportOptionsDocument, RemoveAssetsScanImportOptionsDocument, AddScanConfigurationsScanImportOptionsDocument, RemoveScanConfigurationsScanImportOptionsDocument, AddInventoryHistoriesScanImportOptionsDocument, RemoveInventoryHistoriesScanImportOptionsDocument } from '../gqls'
import { ServiceSingleResultOfScanImportOption, QueryContextOfScanImportOption, FilterOfScanImportOption, ServiceSingleResultOfInt64, ServiceListResultOfScanImportOption, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfiguration, QueryContextOfScanConfiguration, FilterOfScanConfiguration, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, ScanImportOptionInput, FieldUpdateOperatorOfScanImportOption } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanImportOptionBaseService {
    
public modelName = 'scanImportOption';
public modelPluralName = 'scanImportOptions';

	private getScanImportOptionsQuery: GetScanImportOptionsDocument;
	private firstScanImportOptionsQuery: FirstScanImportOptionsDocument;
	private countScanImportOptionsQuery: CountScanImportOptionsDocument;
	private findScanImportOptionsQuery: FindScanImportOptionsDocument;
	private searchScanImportOptionsQuery: SearchScanImportOptionsDocument;
	private exportSchemaScanImportOptionsQuery: ExportSchemaScanImportOptionsDocument;
	private exportDataScanImportOptionsQuery: ExportDataScanImportOptionsDocument;
	private customQueryScanImportOptionsQuery: CustomQueryScanImportOptionsDocument;
	private customQueryIdScanImportOptionsQuery: CustomQueryIdScanImportOptionsDocument;
	private usedScanImportOptionsQuery: UsedScanImportOptionsDocument;
	private findAllUnassociatedScanConfigurationsScanImportOptionsQuery: FindAllUnassociatedScanConfigurationsScanImportOptionsDocument;
	private findAllAssociatedScanConfigurationsScanImportOptionsQuery: FindAllAssociatedScanConfigurationsScanImportOptionsDocument;
	private existScanImportOptionsQuery: ExistScanImportOptionsDocument;
	private findUnassociatedAssetsScanImportOptionsQuery: FindUnassociatedAssetsScanImportOptionsDocument;
	private findUnassociatedInventoryHistoriesScanImportOptionsQuery: FindUnassociatedInventoryHistoriesScanImportOptionsDocument;
	private insertScanImportOptionsMutation: InsertScanImportOptionsDocument;
	private updateScanImportOptionsMutation: UpdateScanImportOptionsDocument;
	private importDataScanImportOptionsMutation: ImportDataScanImportOptionsDocument;
	private updateManyScanImportOptionsMutation: UpdateManyScanImportOptionsDocument;
	private deleteScanImportOptionsMutation: DeleteScanImportOptionsDocument;
	private orderScanImportOptionsMutation: OrderScanImportOptionsDocument;
	private addAssetsScanImportOptionsMutation: AddAssetsScanImportOptionsDocument;
	private removeAssetsScanImportOptionsMutation: RemoveAssetsScanImportOptionsDocument;
	private addScanConfigurationsScanImportOptionsMutation: AddScanConfigurationsScanImportOptionsDocument;
	private removeScanConfigurationsScanImportOptionsMutation: RemoveScanConfigurationsScanImportOptionsDocument;
	private addInventoryHistoriesScanImportOptionsMutation: AddInventoryHistoriesScanImportOptionsDocument;
	private removeInventoryHistoriesScanImportOptionsMutation: RemoveInventoryHistoriesScanImportOptionsDocument;

	constructor(private injector: Injector) {
		this.getScanImportOptionsQuery = this.injector.get(GetScanImportOptionsDocument);
		this.firstScanImportOptionsQuery = this.injector.get(FirstScanImportOptionsDocument);
		this.countScanImportOptionsQuery = this.injector.get(CountScanImportOptionsDocument);
		this.findScanImportOptionsQuery = this.injector.get(FindScanImportOptionsDocument);
		this.searchScanImportOptionsQuery = this.injector.get(SearchScanImportOptionsDocument);
		this.exportSchemaScanImportOptionsQuery = this.injector.get(ExportSchemaScanImportOptionsDocument);
		this.exportDataScanImportOptionsQuery = this.injector.get(ExportDataScanImportOptionsDocument);
		this.customQueryScanImportOptionsQuery = this.injector.get(CustomQueryScanImportOptionsDocument);
		this.customQueryIdScanImportOptionsQuery = this.injector.get(CustomQueryIdScanImportOptionsDocument);
		this.usedScanImportOptionsQuery = this.injector.get(UsedScanImportOptionsDocument);
		this.findAllUnassociatedScanConfigurationsScanImportOptionsQuery = this.injector.get(FindAllUnassociatedScanConfigurationsScanImportOptionsDocument);
		this.findAllAssociatedScanConfigurationsScanImportOptionsQuery = this.injector.get(FindAllAssociatedScanConfigurationsScanImportOptionsDocument);
		this.existScanImportOptionsQuery = this.injector.get(ExistScanImportOptionsDocument);
		this.findUnassociatedAssetsScanImportOptionsQuery = this.injector.get(FindUnassociatedAssetsScanImportOptionsDocument);
		this.findUnassociatedInventoryHistoriesScanImportOptionsQuery = this.injector.get(FindUnassociatedInventoryHistoriesScanImportOptionsDocument);
		this.insertScanImportOptionsMutation = this.injector.get(InsertScanImportOptionsDocument);
		this.updateScanImportOptionsMutation = this.injector.get(UpdateScanImportOptionsDocument);
		this.importDataScanImportOptionsMutation = this.injector.get(ImportDataScanImportOptionsDocument);
		this.updateManyScanImportOptionsMutation = this.injector.get(UpdateManyScanImportOptionsDocument);
		this.deleteScanImportOptionsMutation = this.injector.get(DeleteScanImportOptionsDocument);
		this.orderScanImportOptionsMutation = this.injector.get(OrderScanImportOptionsDocument);
		this.addAssetsScanImportOptionsMutation = this.injector.get(AddAssetsScanImportOptionsDocument);
		this.removeAssetsScanImportOptionsMutation = this.injector.get(RemoveAssetsScanImportOptionsDocument);
		this.addScanConfigurationsScanImportOptionsMutation = this.injector.get(AddScanConfigurationsScanImportOptionsDocument);
		this.removeScanConfigurationsScanImportOptionsMutation = this.injector.get(RemoveScanConfigurationsScanImportOptionsDocument);
		this.addInventoryHistoriesScanImportOptionsMutation = this.injector.get(AddInventoryHistoriesScanImportOptionsDocument);
		this.removeInventoryHistoriesScanImportOptionsMutation = this.injector.get(RemoveInventoryHistoriesScanImportOptionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanImportOption> {
    
    		let variables: GetScanImportOptionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.get));
            }
            else{
                let result:ServiceSingleResultOfScanImportOption={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanImportOption> {
    
    		let variables: FirstScanImportOptionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanImportOptionsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: FindScanImportOptionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: SearchScanImportOptionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanImportOptionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanImportOptionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: CustomQueryScanImportOptionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: CustomQueryIdScanImportOptionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanImportOptionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.used));
    	}

    	/**  */
    	@InjectArgs
    	public findAllUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllUnassociatedScanConfigurationsScanImportOptionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllUnassociatedScanConfigurationsScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.findAllUnassociatedScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public findAllAssociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllAssociatedScanConfigurationsScanImportOptionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllAssociatedScanConfigurationsScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.findAllAssociatedScanConfigurations));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanImportOptionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsScanImportOptionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesScanImportOptionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesScanImportOptionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.findUnassociatedInventoryHistories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanImportOptionInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanImportOption> {
    
    		let variables: InsertScanImportOptionsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanImportOption> {
    
    		let variables: UpdateScanImportOptionsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanImportOptionsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanImportOptionsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanImportOptionsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderScanImportOptionsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.order));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsScanImportOptionsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsScanImportOptionsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeAssetsScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.removeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsScanImportOptionsBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsScanImportOptionsBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.removeScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public addInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryIds') inventoryHistoryIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddInventoryHistoriesScanImportOptionsBaseVariables = {
    			id: id,
			inventoryHistoryIds: inventoryHistoryIds
    		}
    				return this.addInventoryHistoriesScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.addInventoryHistories));
    	}

    	/**  */
    	@InjectArgs
    	public removeInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryIds') inventoryHistoryIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveInventoryHistoriesScanImportOptionsBaseVariables = {
    			inventoryHistoryIds: inventoryHistoryIds
    		}
    				return this.removeInventoryHistoriesScanImportOptionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanImportOptions.removeInventoryHistories));
    	}
    
}