import "reflect-metadata";

const argsMetadataKey = Symbol('Args')

export function Args(
    parameterName: string
) {
    if (parameterName.trim() === '') {
        throw new Error('Args decorator cannot be null or empty')
    }
    return function (
        target: Object,
        propertyKey: string | symbol,
        parameterIndex: number
    ) {
        const symbol = Symbol.for("func");
        let meta = Reflect.getMetadata(symbol, target[propertyKey]);

        if (meta == undefined) {
            meta = [];
        }
        meta[parameterIndex] = parameterName;

        Reflect.defineMetadata(symbol, meta, target[propertyKey]);


        // let existingRequiredParameters: number[] =
        //     Reflect.getOwnMetadata(argsMetadataKey, target, propertyKey) || [];
        // existingRequiredParameters.push(parameterIndex);
        // Reflect.defineMetadata(
        //     argsMetadataKey,
        //     existingRequiredParameters,
        //     target,
        //     propertyKey
        // );
    }
}

export function InjectArgs(
    target: any,
    propertyName: string,
    descriptor: TypedPropertyDescriptor<Function>
) {
    const symbolKey = Symbol.for("func");

    let meta = Reflect.getMetadata(symbolKey, descriptor.value);

}
