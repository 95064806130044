import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareAttributeTypesBaseVariables, FirstSoftwareAttributeTypesBaseVariables, CountSoftwareAttributeTypesBaseVariables, FindSoftwareAttributeTypesBaseVariables, SearchSoftwareAttributeTypesBaseVariables, ExportSchemaSoftwareAttributeTypesBaseVariables, ExportDataSoftwareAttributeTypesBaseVariables, CustomQuerySoftwareAttributeTypesBaseVariables, CustomQueryIdSoftwareAttributeTypesBaseVariables, UsedSoftwareAttributeTypesBaseVariables, ExistSoftwareAttributeTypesBaseVariables, InsertSoftwareAttributeTypesBaseVariables, UpdateSoftwareAttributeTypesBaseVariables, ImportDataSoftwareAttributeTypesBaseVariables, UpdateManySoftwareAttributeTypesBaseVariables, DeleteSoftwareAttributeTypesBaseVariables } from '../gqls'
import { GetSoftwareAttributeTypesDocument, FirstSoftwareAttributeTypesDocument, CountSoftwareAttributeTypesDocument, FindSoftwareAttributeTypesDocument, SearchSoftwareAttributeTypesDocument, ExportSchemaSoftwareAttributeTypesDocument, ExportDataSoftwareAttributeTypesDocument, CustomQuerySoftwareAttributeTypesDocument, CustomQueryIdSoftwareAttributeTypesDocument, UsedSoftwareAttributeTypesDocument, ExistSoftwareAttributeTypesDocument, InsertSoftwareAttributeTypesDocument, UpdateSoftwareAttributeTypesDocument, ImportDataSoftwareAttributeTypesDocument, UpdateManySoftwareAttributeTypesDocument, DeleteSoftwareAttributeTypesDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareAttributeType, QueryContextOfSoftwareAttributeType, FilterOfSoftwareAttributeType, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareAttributeType, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SoftwareAttributeTypeInput, FieldUpdateOperatorOfSoftwareAttributeType } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareAttributeTypeBaseService {
    
public modelName = 'softwareAttributeType';
public modelPluralName = 'softwareAttributeTypes';

	private getSoftwareAttributeTypesQuery: GetSoftwareAttributeTypesDocument;
	private firstSoftwareAttributeTypesQuery: FirstSoftwareAttributeTypesDocument;
	private countSoftwareAttributeTypesQuery: CountSoftwareAttributeTypesDocument;
	private findSoftwareAttributeTypesQuery: FindSoftwareAttributeTypesDocument;
	private searchSoftwareAttributeTypesQuery: SearchSoftwareAttributeTypesDocument;
	private exportSchemaSoftwareAttributeTypesQuery: ExportSchemaSoftwareAttributeTypesDocument;
	private exportDataSoftwareAttributeTypesQuery: ExportDataSoftwareAttributeTypesDocument;
	private customQuerySoftwareAttributeTypesQuery: CustomQuerySoftwareAttributeTypesDocument;
	private customQueryIdSoftwareAttributeTypesQuery: CustomQueryIdSoftwareAttributeTypesDocument;
	private usedSoftwareAttributeTypesQuery: UsedSoftwareAttributeTypesDocument;
	private existSoftwareAttributeTypesQuery: ExistSoftwareAttributeTypesDocument;
	private insertSoftwareAttributeTypesMutation: InsertSoftwareAttributeTypesDocument;
	private updateSoftwareAttributeTypesMutation: UpdateSoftwareAttributeTypesDocument;
	private importDataSoftwareAttributeTypesMutation: ImportDataSoftwareAttributeTypesDocument;
	private updateManySoftwareAttributeTypesMutation: UpdateManySoftwareAttributeTypesDocument;
	private deleteSoftwareAttributeTypesMutation: DeleteSoftwareAttributeTypesDocument;

	constructor(private injector: Injector) {
		this.getSoftwareAttributeTypesQuery = this.injector.get(GetSoftwareAttributeTypesDocument);
		this.firstSoftwareAttributeTypesQuery = this.injector.get(FirstSoftwareAttributeTypesDocument);
		this.countSoftwareAttributeTypesQuery = this.injector.get(CountSoftwareAttributeTypesDocument);
		this.findSoftwareAttributeTypesQuery = this.injector.get(FindSoftwareAttributeTypesDocument);
		this.searchSoftwareAttributeTypesQuery = this.injector.get(SearchSoftwareAttributeTypesDocument);
		this.exportSchemaSoftwareAttributeTypesQuery = this.injector.get(ExportSchemaSoftwareAttributeTypesDocument);
		this.exportDataSoftwareAttributeTypesQuery = this.injector.get(ExportDataSoftwareAttributeTypesDocument);
		this.customQuerySoftwareAttributeTypesQuery = this.injector.get(CustomQuerySoftwareAttributeTypesDocument);
		this.customQueryIdSoftwareAttributeTypesQuery = this.injector.get(CustomQueryIdSoftwareAttributeTypesDocument);
		this.usedSoftwareAttributeTypesQuery = this.injector.get(UsedSoftwareAttributeTypesDocument);
		this.existSoftwareAttributeTypesQuery = this.injector.get(ExistSoftwareAttributeTypesDocument);
		this.insertSoftwareAttributeTypesMutation = this.injector.get(InsertSoftwareAttributeTypesDocument);
		this.updateSoftwareAttributeTypesMutation = this.injector.get(UpdateSoftwareAttributeTypesDocument);
		this.importDataSoftwareAttributeTypesMutation = this.injector.get(ImportDataSoftwareAttributeTypesDocument);
		this.updateManySoftwareAttributeTypesMutation = this.injector.get(UpdateManySoftwareAttributeTypesDocument);
		this.deleteSoftwareAttributeTypesMutation = this.injector.get(DeleteSoftwareAttributeTypesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareAttributeType> {
    
    		let variables: GetSoftwareAttributeTypesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareAttributeType={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareAttributeType,
		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareAttributeType> {
    
    		let variables: FirstSoftwareAttributeTypesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareAttributeTypesBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareAttributeType,
		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareAttributeType> {
    
    		let variables: FindSoftwareAttributeTypesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareAttributeType,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareAttributeType> {
    
    		let variables: SearchSoftwareAttributeTypesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareAttributeTypesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareAttributeTypesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareAttributeType,
		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareAttributeType> {
    
    		let variables: CustomQuerySoftwareAttributeTypesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareAttributeType,
		@Args('filter?') filter?: FilterOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareAttributeType> {
    
    		let variables: CustomQueryIdSoftwareAttributeTypesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareAttributeTypesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareAttributeTypesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareAttributeTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareAttributeTypeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareAttributeType> {
    
    		let variables: InsertSoftwareAttributeTypesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareAttributeTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareAttributeType> {
    
    		let variables: UpdateSoftwareAttributeTypesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareAttributeTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareAttributeTypesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareAttributeTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareAttributeType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareAttributeTypesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareAttributeTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareAttributeTypesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareAttributeTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareAttributeTypes.delete));
    	}
    
}