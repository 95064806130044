import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetRegistriesBaseVariables, FirstRegistriesBaseVariables, CountRegistriesBaseVariables, FindRegistriesBaseVariables, SearchRegistriesBaseVariables, ExportSchemaRegistriesBaseVariables, ExportDataRegistriesBaseVariables, CustomQueryRegistriesBaseVariables, CustomQueryIdRegistriesBaseVariables, UsedRegistriesBaseVariables, ExistRegistriesBaseVariables } from '../gqls'
import { GetRegistriesDocument, FirstRegistriesDocument, CountRegistriesDocument, FindRegistriesDocument, SearchRegistriesDocument, ExportSchemaRegistriesDocument, ExportDataRegistriesDocument, CustomQueryRegistriesDocument, CustomQueryIdRegistriesDocument, UsedRegistriesDocument, ExistRegistriesDocument } from '../gqls'
import { ServiceSingleResultOfRegistry, QueryContextOfRegistry, FilterOfRegistry, ServiceSingleResultOfInt64, ServiceListResultOfRegistry, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class RegistryBaseService {
    
public modelName = 'registry';
public modelPluralName = 'registries';

	private getRegistriesQuery: GetRegistriesDocument;
	private firstRegistriesQuery: FirstRegistriesDocument;
	private countRegistriesQuery: CountRegistriesDocument;
	private findRegistriesQuery: FindRegistriesDocument;
	private searchRegistriesQuery: SearchRegistriesDocument;
	private exportSchemaRegistriesQuery: ExportSchemaRegistriesDocument;
	private exportDataRegistriesQuery: ExportDataRegistriesDocument;
	private customQueryRegistriesQuery: CustomQueryRegistriesDocument;
	private customQueryIdRegistriesQuery: CustomQueryIdRegistriesDocument;
	private usedRegistriesQuery: UsedRegistriesDocument;
	private existRegistriesQuery: ExistRegistriesDocument;

	constructor(private injector: Injector) {
		this.getRegistriesQuery = this.injector.get(GetRegistriesDocument);
		this.firstRegistriesQuery = this.injector.get(FirstRegistriesDocument);
		this.countRegistriesQuery = this.injector.get(CountRegistriesDocument);
		this.findRegistriesQuery = this.injector.get(FindRegistriesDocument);
		this.searchRegistriesQuery = this.injector.get(SearchRegistriesDocument);
		this.exportSchemaRegistriesQuery = this.injector.get(ExportSchemaRegistriesDocument);
		this.exportDataRegistriesQuery = this.injector.get(ExportDataRegistriesDocument);
		this.customQueryRegistriesQuery = this.injector.get(CustomQueryRegistriesDocument);
		this.customQueryIdRegistriesQuery = this.injector.get(CustomQueryIdRegistriesDocument);
		this.usedRegistriesQuery = this.injector.get(UsedRegistriesDocument);
		this.existRegistriesQuery = this.injector.get(ExistRegistriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRegistry> {
    
    		let variables: GetRegistriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.get));
            }
            else{
                let result:ServiceSingleResultOfRegistry={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRegistry,
		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRegistry> {
    
    		let variables: FirstRegistriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRegistriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRegistry,
		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRegistry> {
    
    		let variables: FindRegistriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfRegistry,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRegistry> {
    
    		let variables: SearchRegistriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaRegistriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataRegistriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfRegistry,
		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRegistry> {
    
    		let variables: CustomQueryRegistriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRegistry,
		@Args('filter?') filter?: FilterOfRegistry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRegistry> {
    
    		let variables: CustomQueryIdRegistriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedRegistriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistRegistriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existRegistriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.registries.exist));
    	}
    
    
}