import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMailSlurpBlackListsBaseVariables, FirstMailSlurpBlackListsBaseVariables, CountMailSlurpBlackListsBaseVariables, FindMailSlurpBlackListsBaseVariables, SearchMailSlurpBlackListsBaseVariables, ExportSchemaMailSlurpBlackListsBaseVariables, ExportDataMailSlurpBlackListsBaseVariables, CustomQueryMailSlurpBlackListsBaseVariables, CustomQueryIdMailSlurpBlackListsBaseVariables, UsedMailSlurpBlackListsBaseVariables, ExistMailSlurpBlackListsBaseVariables, InsertMailSlurpBlackListsBaseVariables, UpdateMailSlurpBlackListsBaseVariables, ImportDataMailSlurpBlackListsBaseVariables, UpdateManyMailSlurpBlackListsBaseVariables, DeleteMailSlurpBlackListsBaseVariables } from '../gqls'
import { GetMailSlurpBlackListsDocument, FirstMailSlurpBlackListsDocument, CountMailSlurpBlackListsDocument, FindMailSlurpBlackListsDocument, SearchMailSlurpBlackListsDocument, ExportSchemaMailSlurpBlackListsDocument, ExportDataMailSlurpBlackListsDocument, CustomQueryMailSlurpBlackListsDocument, CustomQueryIdMailSlurpBlackListsDocument, UsedMailSlurpBlackListsDocument, ExistMailSlurpBlackListsDocument, InsertMailSlurpBlackListsDocument, UpdateMailSlurpBlackListsDocument, ImportDataMailSlurpBlackListsDocument, UpdateManyMailSlurpBlackListsDocument, DeleteMailSlurpBlackListsDocument } from '../gqls'
import { ServiceSingleResultOfMailSlurpBlackList, QueryContextOfMailSlurpBlackList, FilterOfMailSlurpBlackList, ServiceSingleResultOfInt64, ServiceListResultOfMailSlurpBlackList, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, MailSlurpBlackListInput, FieldUpdateOperatorOfMailSlurpBlackList } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MailSlurpBlackListBaseService {
    
public modelName = 'mailSlurpBlackList';
public modelPluralName = 'mailSlurpBlackLists';

	private getMailSlurpBlackListsQuery: GetMailSlurpBlackListsDocument;
	private firstMailSlurpBlackListsQuery: FirstMailSlurpBlackListsDocument;
	private countMailSlurpBlackListsQuery: CountMailSlurpBlackListsDocument;
	private findMailSlurpBlackListsQuery: FindMailSlurpBlackListsDocument;
	private searchMailSlurpBlackListsQuery: SearchMailSlurpBlackListsDocument;
	private exportSchemaMailSlurpBlackListsQuery: ExportSchemaMailSlurpBlackListsDocument;
	private exportDataMailSlurpBlackListsQuery: ExportDataMailSlurpBlackListsDocument;
	private customQueryMailSlurpBlackListsQuery: CustomQueryMailSlurpBlackListsDocument;
	private customQueryIdMailSlurpBlackListsQuery: CustomQueryIdMailSlurpBlackListsDocument;
	private usedMailSlurpBlackListsQuery: UsedMailSlurpBlackListsDocument;
	private existMailSlurpBlackListsQuery: ExistMailSlurpBlackListsDocument;
	private insertMailSlurpBlackListsMutation: InsertMailSlurpBlackListsDocument;
	private updateMailSlurpBlackListsMutation: UpdateMailSlurpBlackListsDocument;
	private importDataMailSlurpBlackListsMutation: ImportDataMailSlurpBlackListsDocument;
	private updateManyMailSlurpBlackListsMutation: UpdateManyMailSlurpBlackListsDocument;
	private deleteMailSlurpBlackListsMutation: DeleteMailSlurpBlackListsDocument;

	constructor(private injector: Injector) {
		this.getMailSlurpBlackListsQuery = this.injector.get(GetMailSlurpBlackListsDocument);
		this.firstMailSlurpBlackListsQuery = this.injector.get(FirstMailSlurpBlackListsDocument);
		this.countMailSlurpBlackListsQuery = this.injector.get(CountMailSlurpBlackListsDocument);
		this.findMailSlurpBlackListsQuery = this.injector.get(FindMailSlurpBlackListsDocument);
		this.searchMailSlurpBlackListsQuery = this.injector.get(SearchMailSlurpBlackListsDocument);
		this.exportSchemaMailSlurpBlackListsQuery = this.injector.get(ExportSchemaMailSlurpBlackListsDocument);
		this.exportDataMailSlurpBlackListsQuery = this.injector.get(ExportDataMailSlurpBlackListsDocument);
		this.customQueryMailSlurpBlackListsQuery = this.injector.get(CustomQueryMailSlurpBlackListsDocument);
		this.customQueryIdMailSlurpBlackListsQuery = this.injector.get(CustomQueryIdMailSlurpBlackListsDocument);
		this.usedMailSlurpBlackListsQuery = this.injector.get(UsedMailSlurpBlackListsDocument);
		this.existMailSlurpBlackListsQuery = this.injector.get(ExistMailSlurpBlackListsDocument);
		this.insertMailSlurpBlackListsMutation = this.injector.get(InsertMailSlurpBlackListsDocument);
		this.updateMailSlurpBlackListsMutation = this.injector.get(UpdateMailSlurpBlackListsDocument);
		this.importDataMailSlurpBlackListsMutation = this.injector.get(ImportDataMailSlurpBlackListsDocument);
		this.updateManyMailSlurpBlackListsMutation = this.injector.get(UpdateManyMailSlurpBlackListsDocument);
		this.deleteMailSlurpBlackListsMutation = this.injector.get(DeleteMailSlurpBlackListsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpBlackList> {
    
    		let variables: GetMailSlurpBlackListsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.get));
            }
            else{
                let result:ServiceSingleResultOfMailSlurpBlackList={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailSlurpBlackList,
		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpBlackList> {
    
    		let variables: FirstMailSlurpBlackListsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMailSlurpBlackListsBaseVariables = {
    			filter: filter
    		}
    				return this.countMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailSlurpBlackList,
		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpBlackList> {
    
    		let variables: FindMailSlurpBlackListsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMailSlurpBlackList,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpBlackList> {
    
    		let variables: SearchMailSlurpBlackListsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMailSlurpBlackListsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMailSlurpBlackListsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMailSlurpBlackList,
		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpBlackList> {
    
    		let variables: CustomQueryMailSlurpBlackListsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMailSlurpBlackList,
		@Args('filter?') filter?: FilterOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpBlackList> {
    
    		let variables: CustomQueryIdMailSlurpBlackListsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMailSlurpBlackListsBaseVariables = {
    			ids: ids
    		}
    				return this.usedMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMailSlurpBlackListsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMailSlurpBlackListsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MailSlurpBlackListInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpBlackList> {
    
    		let variables: InsertMailSlurpBlackListsBaseVariables = {
    			entity: entity
    		}
    				return this.insertMailSlurpBlackListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpBlackList> {
    
    		let variables: UpdateMailSlurpBlackListsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMailSlurpBlackListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMailSlurpBlackListsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMailSlurpBlackListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailSlurpBlackList,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMailSlurpBlackListsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMailSlurpBlackListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMailSlurpBlackListsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMailSlurpBlackListsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpBlackLists.delete));
    	}
    
}