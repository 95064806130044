import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetObjectClassMappingsBaseVariables, FirstObjectClassMappingsBaseVariables, CountObjectClassMappingsBaseVariables, FindObjectClassMappingsBaseVariables, SearchObjectClassMappingsBaseVariables, ExportSchemaObjectClassMappingsBaseVariables, ExportDataObjectClassMappingsBaseVariables, CustomQueryObjectClassMappingsBaseVariables, CustomQueryIdObjectClassMappingsBaseVariables, UsedObjectClassMappingsBaseVariables, DefaultObjectClassMappingsBaseVariables, ExistObjectClassMappingsBaseVariables, FindUnassociatedScanByLdapsObjectClassMappingsBaseVariables, InsertObjectClassMappingsBaseVariables, UpdateObjectClassMappingsBaseVariables, ImportDataObjectClassMappingsBaseVariables, UpdateManyObjectClassMappingsBaseVariables, DeleteObjectClassMappingsBaseVariables, AddScanByLdapsObjectClassMappingsBaseVariables, RemoveScanByLdapsObjectClassMappingsBaseVariables } from '../gqls'
import { GetObjectClassMappingsDocument, FirstObjectClassMappingsDocument, CountObjectClassMappingsDocument, FindObjectClassMappingsDocument, SearchObjectClassMappingsDocument, ExportSchemaObjectClassMappingsDocument, ExportDataObjectClassMappingsDocument, CustomQueryObjectClassMappingsDocument, CustomQueryIdObjectClassMappingsDocument, UsedObjectClassMappingsDocument, DefaultObjectClassMappingsDocument, ExistObjectClassMappingsDocument, FindUnassociatedScanByLdapsObjectClassMappingsDocument, InsertObjectClassMappingsDocument, UpdateObjectClassMappingsDocument, ImportDataObjectClassMappingsDocument, UpdateManyObjectClassMappingsDocument, DeleteObjectClassMappingsDocument, AddScanByLdapsObjectClassMappingsDocument, RemoveScanByLdapsObjectClassMappingsDocument } from '../gqls'
import { ServiceSingleResultOfObjectClassMapping, QueryContextOfObjectClassMapping, FilterOfObjectClassMapping, ServiceSingleResultOfInt64, ServiceListResultOfObjectClassMapping, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, ObjectClassMappingInput, FieldUpdateOperatorOfObjectClassMapping } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ObjectClassMappingBaseService {
    
public modelName = 'objectClassMapping';
public modelPluralName = 'objectClassMappings';

	private getObjectClassMappingsQuery: GetObjectClassMappingsDocument;
	private firstObjectClassMappingsQuery: FirstObjectClassMappingsDocument;
	private countObjectClassMappingsQuery: CountObjectClassMappingsDocument;
	private findObjectClassMappingsQuery: FindObjectClassMappingsDocument;
	private searchObjectClassMappingsQuery: SearchObjectClassMappingsDocument;
	private exportSchemaObjectClassMappingsQuery: ExportSchemaObjectClassMappingsDocument;
	private exportDataObjectClassMappingsQuery: ExportDataObjectClassMappingsDocument;
	private customQueryObjectClassMappingsQuery: CustomQueryObjectClassMappingsDocument;
	private customQueryIdObjectClassMappingsQuery: CustomQueryIdObjectClassMappingsDocument;
	private usedObjectClassMappingsQuery: UsedObjectClassMappingsDocument;
	private defaultObjectClassMappingsQuery: DefaultObjectClassMappingsDocument;
	private existObjectClassMappingsQuery: ExistObjectClassMappingsDocument;
	private findUnassociatedScanByLdapsObjectClassMappingsQuery: FindUnassociatedScanByLdapsObjectClassMappingsDocument;
	private insertObjectClassMappingsMutation: InsertObjectClassMappingsDocument;
	private updateObjectClassMappingsMutation: UpdateObjectClassMappingsDocument;
	private importDataObjectClassMappingsMutation: ImportDataObjectClassMappingsDocument;
	private updateManyObjectClassMappingsMutation: UpdateManyObjectClassMappingsDocument;
	private deleteObjectClassMappingsMutation: DeleteObjectClassMappingsDocument;
	private addScanByLdapsObjectClassMappingsMutation: AddScanByLdapsObjectClassMappingsDocument;
	private removeScanByLdapsObjectClassMappingsMutation: RemoveScanByLdapsObjectClassMappingsDocument;

	constructor(private injector: Injector) {
		this.getObjectClassMappingsQuery = this.injector.get(GetObjectClassMappingsDocument);
		this.firstObjectClassMappingsQuery = this.injector.get(FirstObjectClassMappingsDocument);
		this.countObjectClassMappingsQuery = this.injector.get(CountObjectClassMappingsDocument);
		this.findObjectClassMappingsQuery = this.injector.get(FindObjectClassMappingsDocument);
		this.searchObjectClassMappingsQuery = this.injector.get(SearchObjectClassMappingsDocument);
		this.exportSchemaObjectClassMappingsQuery = this.injector.get(ExportSchemaObjectClassMappingsDocument);
		this.exportDataObjectClassMappingsQuery = this.injector.get(ExportDataObjectClassMappingsDocument);
		this.customQueryObjectClassMappingsQuery = this.injector.get(CustomQueryObjectClassMappingsDocument);
		this.customQueryIdObjectClassMappingsQuery = this.injector.get(CustomQueryIdObjectClassMappingsDocument);
		this.usedObjectClassMappingsQuery = this.injector.get(UsedObjectClassMappingsDocument);
		this.defaultObjectClassMappingsQuery = this.injector.get(DefaultObjectClassMappingsDocument);
		this.existObjectClassMappingsQuery = this.injector.get(ExistObjectClassMappingsDocument);
		this.findUnassociatedScanByLdapsObjectClassMappingsQuery = this.injector.get(FindUnassociatedScanByLdapsObjectClassMappingsDocument);
		this.insertObjectClassMappingsMutation = this.injector.get(InsertObjectClassMappingsDocument);
		this.updateObjectClassMappingsMutation = this.injector.get(UpdateObjectClassMappingsDocument);
		this.importDataObjectClassMappingsMutation = this.injector.get(ImportDataObjectClassMappingsDocument);
		this.updateManyObjectClassMappingsMutation = this.injector.get(UpdateManyObjectClassMappingsDocument);
		this.deleteObjectClassMappingsMutation = this.injector.get(DeleteObjectClassMappingsDocument);
		this.addScanByLdapsObjectClassMappingsMutation = this.injector.get(AddScanByLdapsObjectClassMappingsDocument);
		this.removeScanByLdapsObjectClassMappingsMutation = this.injector.get(RemoveScanByLdapsObjectClassMappingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectClassMapping> {
    
    		let variables: GetObjectClassMappingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.get));
            }
            else{
                let result:ServiceSingleResultOfObjectClassMapping={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfObjectClassMapping,
		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectClassMapping> {
    
    		let variables: FirstObjectClassMappingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountObjectClassMappingsBaseVariables = {
    			filter: filter
    		}
    				return this.countObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfObjectClassMapping,
		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfObjectClassMapping> {
    
    		let variables: FindObjectClassMappingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfObjectClassMapping,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfObjectClassMapping> {
    
    		let variables: SearchObjectClassMappingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaObjectClassMappingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataObjectClassMappingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfObjectClassMapping,
		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfObjectClassMapping> {
    
    		let variables: CustomQueryObjectClassMappingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfObjectClassMapping,
		@Args('filter?') filter?: FilterOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfObjectClassMapping> {
    
    		let variables: CustomQueryIdObjectClassMappingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedObjectClassMappingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.used));
    	}

    	/**  */
    	@InjectArgs
    	public default(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectClassMapping> {
    
    		let variables: DefaultObjectClassMappingsBaseVariables = {
    
    		}
    				return this.defaultObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.default));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistObjectClassMappingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.exist));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsObjectClassMappingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsObjectClassMappingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ObjectClassMappingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectClassMapping> {
    
    		let variables: InsertObjectClassMappingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfObjectClassMapping> {
    
    		let variables: UpdateObjectClassMappingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataObjectClassMappingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfObjectClassMapping,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyObjectClassMappingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteObjectClassMappingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanByLdapsObjectClassMappingsBaseVariables = {
    			id: id,
			scanByLdapIds: scanByLdapIds
    		}
    				return this.addScanByLdapsObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.addScanByLdaps));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanByLdapsObjectClassMappingsBaseVariables = {
    			scanByLdapIds: scanByLdapIds
    		}
    				return this.removeScanByLdapsObjectClassMappingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.objectClassMappings.removeScanByLdaps));
    	}
    
}