<div>
  <h1>Votre licence ne vous permet pas d'accéder à cette page</h1>
  <p>
    Soit vous ne disposez pas de la fonctionnalité ou votre licence a
    expiré.
  </p>
  <p>
    Veuillez contacter clarilog.
  </p>
</div>
