import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslateService } from '@clarilog/shared2';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { AssetCategoryBaseService } from '../service-bases';
import {
  AssetModelCategory,
  FilterOfAssetCategory,
  QueryContextOfAssetCategory,
  ServiceListResultOfAssetCategory,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-category')
export class AssetCategoryCoreService extends AssetCategoryBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }
  private assetCategoryStock = false;

  /**  */
  @InjectArgs
  public findAssetCategories(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetCategory,
    @Args('filter?') filter?: FilterOfAssetCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetCategory> {
    ModelFieldCompilerService.createField('data.key', null, fields);
    ModelFieldCompilerService.createField('data.parentId', null, fields);

    if (
      (options.skip == undefined && options.limit >= 500000) ||
      options.limit == undefined
    ) {
      filter = {};
    }
    let result = super.findAssetCategories(
      fields,
      options,
      filter,
      extendedVariables,
    );

    if (options.limit != undefined && options.limit < 500000) {
      return result;
    }

    result.subscribe((res) => {
      const assetId = res.data.find((x) => x.key === 'asset');
      if (assetId != undefined) {
        res.data.forEach((element) => {
          if (element.parentId === assetId.id) {
            element.parentId = null;
          }
        });
        res.data = res.data.filter((obj) => obj !== assetId);
      }
    });
    return result;
  }

  @InjectArgs
  findByFirstHierarchies(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
  ) {
    let filter: FilterOfAssetCategory = {
      or: [{ key: { eq: 'consumable' } }, { key: { eq: 'asset' } }],
    };

    let fd = this.find(fields, null, filter);
    fd.forEach((x) =>
      x.data.forEach((f) => {
        f.name[this.translatedFieldHelperService.getTranslateKey()] =
          f.key === 'asset'
            ? TranslateService.get('entities/asset/_title/singular')
            : TranslateService.get('entities/consumable/_title/singular');
      }),
    );

    return fd;
  }

  /**  */
  @InjectArgs
  public findConsumableCategory(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetCategory,
    @Args('filter?') filter?: FilterOfAssetCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetCategory> {
    ModelFieldCompilerService.createField('data.key', null, fields);
    ModelFieldCompilerService.createField('data.parentId', null, fields);

    if (
      (options.skip == undefined && options.limit >= 500000) ||
      options.limit == undefined
    ) {
      filter = {};
    }
    let result = super.findConsumableCategory(
      fields,
      options,
      filter,
      extendedVariables,
    );

    if (options.limit != undefined && options.limit < 500000) {
      return result;
    }

    result.subscribe((res) => {
      const consumable = res.data.find((x) => x.key === 'consumable');
      if (consumable != undefined) {
        res.data.forEach((element) => {
          if (element.parentId === consumable.id) {
            element.parentId = null;
          }
        });
        res.data = res.data.filter((obj) => obj !== consumable);
      }
    });

    return result;
  }

  /** Récupère la liste des classements en lien en fonction du type de référence */
  @InjectArgs
  findByAssetModelCategory(
    @Args('assetModelCategory') assetModelCategory: AssetModelCategory,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetCategory,
    @Args('filter?') filter?: FilterOfAssetCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetCategory> {
    this.assetCategoryStock = true;
    if (assetModelCategory === AssetModelCategory.Asset) {
      return this.findAssetCategories(
        fields,
        options,
        filter,
        extendedVariables,
      );
    } else {
      return this.findConsumableCategory(
        fields,
        options,
        filter,
        extendedVariables,
      );
    }
  }
}
