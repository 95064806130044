import { Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  PageGroup,
  PageTab,
  WorkItemConfiguration,
} from '@clarilog/shared2/models/schema';
import { WorkItem } from '../work-item-form/work-item';
import { CoreWorkItemGroupDynamicComponent } from '../work-item-group-dynamic/work-item-group-dynamic.component';

/** Représente le service de @type {WorkFormComponent}. */
@Injectable({
  providedIn: 'root',
})
export class WorkFormCoreService {
  /** Permet de créer les éléments du menu de navigation. */
  public createItems(
    form: UntypedFormGroup,
    model: WorkItemConfiguration,
  ): Array<WorkItem> {
    let items = new Array<WorkItem>();
    for (let page of model.form.layout.pages.filter(
      (p) => p.visible !== false || p.forceLoading === true,
    )) {
      let pages = (page as PageGroup).pages;
      let tabs = (page as PageTab).tabs;

      if (pages !== undefined) {
        let item: WorkItem = {
          title: page.label,
          name: page.name,
          content: undefined,
          items: [],
          dependBadges: page['dependBadges'],
        };

        for (let page of pages) {
          item.items.push({
            title: page.label,
            icon: page.icon,
            name: page.name,
            content: CoreWorkItemGroupDynamicComponent,
            data: page,
          });
        }
        items.push(item);
      }
      // else if (tabs !== undefined) {

      //   let item: WorkItem = {
      //     title: undefined,
      //     name: undefined,
      //     content: undefined,
      //     items: []
      //   };

      //   for (let tab of tabs) {
      //     item.items.push({
      //       title: tab.label,
      //       name: tab.name,
      //       content: WorkItemGroupDynamicComponent,
      //       data: tab
      //     });

      //   }

      //   items.push(item);

      // }
      else {
        items.push({
          title: undefined,
          name: undefined,
          content: undefined,
          dependBadges: page['dependBadges'],
          items: [
            {
              title: page.label,
              icon: page.icon,
              name: page.name,
              content: CoreWorkItemGroupDynamicComponent,
              data: page,
            },
          ],
        });
      }
    }
    return items;
  }
}
