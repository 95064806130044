import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { TranslateService } from '@clarilog/shared2/services';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { WidgetDashboardService } from './widget-dashboard.service';
import { AlarmCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { AlarmInput } from '@clarilog/core/services2/graphql/generated-types/types';

@Component({
  selector: 'clc-widget-dashboard',
  templateUrl: './widget-dashboard.component.html',
  styleUrls: ['./widget-dashboard.component.scss'],
})
export class CoreWidgetDashboardComponent implements OnInit {
  public timer: NodeJS.Timeout;

  /**Permet dou definit la source de données */
  public defaultSource: Object[] = [];

  /** Permet ou définit le titre du widget à afficher. "Default = titre par défaut" */
  @Input() title: String = TranslateService.get('entities/alarm/_title/plural');

  /**Permet ou définit le type de données a utilisées (Alertes ou autres ) */
  @Input() type: String = 'alert';

  /**Permet ou definit la visibilité du load spinner  */
  public isVisible: Boolean = true;

  constructor(
    private router: Router,
    private widgetService: WidgetDashboardService,
    private translateHelperService: TranslatedFieldHelperService,
    private alarmService: AlarmCoreService,
  ) {}

  public ngOnInit(): void {
    this.generateData();
    this.timer = setInterval((_) => this.generateData(), 60000 * 5);
  }

  clickEvent(event, alarm) {
    this.router.navigate([`/alerts`], {
      queryParams: { alarmId: alarm.id },
    });
  }

  translateAlarmType(type: string) {
    let value = this.alarmService
      .alarmEnumType()
      .find((at) => at.id === type)?.value;

    return value;
  }

  getDisplayExpr(field) {
    return field[this.translateHelperService.getTranslateKey()];
  }
  /** Permet de récuprer les données nécessaires au fonctionnment du widget-dashbord */
  async generateData() {
    this.isVisible = true;

    if (this.type === 'alert') {
      this.defaultSource = await this.widgetService.getAlertData();
    }

    if (this.defaultSource != undefined || this.defaultSource != null) {
      this.isVisible = false;
    }

    this.modifyDataSourceFormToMatchGroupingTemplate();
  }

  modifyDataSourceFormToMatchGroupingTemplate() {
    if (this.defaultSource.length != 0) {
      let distinctAlarmTypes = [];
      this.defaultSource.forEach((alarm) => {
        let at = (alarm as AlarmInput).type;
        if (at !== null && !distinctAlarmTypes.includes(at))
          distinctAlarmTypes.push(at);
      });

      let newDataSource = [];
      distinctAlarmTypes.forEach((at) => {
        newDataSource.push({
          key: at,
          items: [],
        });
      });

      this.defaultSource.forEach((alarm) => {
        let alarmType = (alarm as AlarmInput).type;
        newDataSource.map((at) => {
          if (at.key === alarmType) at.items.push(alarm);
        });
      });

      newDataSource.sort((at1, at2) => {
        let translatedAt1 = this.translateAlarmType(at1.key);
        let translatedAt2 = this.translateAlarmType(at2.key);
        return translatedAt1 > translatedAt2 ? 1 : -1;
      });

      this.defaultSource = newDataSource;
    }
  }

  ngOnDestroy() {
    clearInterval(this.timer);
  }
}
