import { ScanDataFileBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfScanConfiguration,
  QueryContextOfScanConfiguration,
  ServiceListResultOfScanConfiguration,
} from '../types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ScanConfigurationCoreService } from './scan-configuration.service';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-data-file')
export class ScanDataFileCoreService extends ScanDataFileBaseService {
  constructor(
    injector: Injector,
    private scanConfigurationService: ScanConfigurationCoreService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findAllUnassociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findAllUnassociatedScanConfigurations(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }

  @InjectArgs
  public findAllAssociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findAllAssociatedScanConfigurations(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }
}
