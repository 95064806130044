import { Component, forwardRef, Input, OnDestroy, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { TranslatedField } from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { TranslatedFieldHelperService } from './translate-field-helper-service';
import { GC, GCFactory } from '@clarilog/core';

/** Représente un composant d'affichage d'erreur. */
@Component({
  selector: 'clc-translate-field',
  templateUrl: './translate-field.component.html',
  styleUrls: ['./translate-field.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TranslateFieldComponent),
      multi: true,
    },
  ],
})
export class TranslateFieldComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  _value: TranslatedField;
  disabled: boolean = false;
  isInit: boolean = false;

  @Input() state: ModelState;
  @Input() control: any;
  @Input() readOnly: boolean = false;
  languageSource: Array<{ key: string; value: string }> = [];
  selectedLanguage = 'en';
  supportedLanguages: string[];
  _gc: GC;

  constructor(
    private translatedFieldService: TranslatedFieldHelperService,
    public gcFactory: GCFactory,
  ) {
    this.languageSource = translatedFieldService.getLanguageDatasource();
    this.selectedLanguage = translatedFieldService.getTranslateKey();
    this.supportedLanguages = translatedFieldService.getAllSupportedLanguage();
    this._value = translatedFieldService.getDefaultvalue();
    this._gc = gcFactory.create();
  }
  ngOnDestroy(): void {
    this._gc.dispose();
  }

  get value(): string {
    return this._value[this.selectedLanguage];
  }
  set value(value: string) {
    this._value[this.selectedLanguage] = value;
  }

  /** @inheritdoc */
  public writeValue(value: any) {
    // TO ADD TRAD
    if (value != undefined) {
      this._value = JSON.parse(JSON.stringify(value));
    }
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  public setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  formControl: UntypedFormControl;
  ngOnInit() {
    this._gc.forDispose(
      FormGroupHelpers.onFormLoaded(this.state).subscribe((el) => {
        this.formControl = FormGroupHelpers.formControlByName(
          this.state.form,
          this.control.name,
        );
      }),
    );

    this._gc.forDispose(
      this.formControl?.valueChanges.subscribe((currentValue) => {
        if (this.readOnly && currentValue == null) {
          this.writeValue(this.translatedFieldService.getDefaultvalue());
        }
      }),
    );

    this.state.on.subscribe((event) => {
      if (
        event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
      ) {
        this.selectedLanguage = event.eventData;
      }
    });
  }

  onTextboxValueChanged(e) {
    if (this.formControl?.value != undefined && this._value != undefined) {
      if (
        JSON.stringify(this.formControl.value) !== JSON.stringify(this._value)
      ) {
        this.onChange(JSON.parse(JSON.stringify(this._value)));
        this.onTouched();
      }
    }
  }

  validate() {
    // this.isPopupVisible = false;
    // this.valueToShow = this.translatedFieldService.findValueToShow(this.value);
    // this.onChange(this.value);
    // this.onTouched();
  }
}
