import { Component, Input } from '@angular/core';

/** Représente un composant d'affichage de message dans le menu tous en haut */
@Component({
  selector: 'cl-layout-message-item',
  templateUrl: './layout-message-item.component.html',
  styleUrls: ['./layout-message-item.component.scss'],
})
export class LayoutMessageItemComponent {
  @Input() message: string;
  @Input() type: string;
  isViewHelpMe: boolean = false;
  systemNotification: boolean = false;
  constructor() {}

  // Ne pas afficher les messages d'erreurs pour la partie HelpDesk
  ngOnInit() {
    this.isViewHelpMe =
      localStorage.getItem('hasHelpDeskUser') == 'true' ? true : false;
  }

  ngDoCheck(): void {
    // console.log(localStorage);
  }
}
