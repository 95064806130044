import { Injectable } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

import { TicketTaskCoreService } from './ticket-task.service';

@Injectable({ providedIn: 'root' })
@Authorize({
  policies: ['incident-help-desk-user'],
})
export class TicketTaskHelpMeCoreService extends TicketTaskCoreService {
  public getService(): TicketTaskHelpMeCoreService {
    return this;
  }
}
