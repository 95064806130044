import { CompareOptions } from '../../models/schema';
import { TranslateService } from '../../services/translate/translate.service';

/** Représente les erreurs gérées. */
export const defaultErrors = {
  /** Message lorsqu'il y a une case qui doit être cochée. */
  requiredLicense: () => TranslateService.get('errors/requiredLicense'),
  /** Message lorsque la date n'est pas valide. */
  datetime: () => TranslateService.get('errors/datetime'),
  /** Message lorsque le champ est requis. */
  required: () => TranslateService.get('errors/required'),
  /** Message lorsqu'il y a une taille minimum.*/
  minlength: ({ requiredLength, actualLength }) =>
    TranslateService.get('errors/minlength', {
      requiredLength: requiredLength,
    }),
  /** Message lorsqu'il y a une taille maximum. */
  maxlength: ({ requiredLength, actualLength }) =>
    TranslateService.get('errors/maxlength', {
      requiredLength: requiredLength,
    }),
  /** Message lorsqu'il y a un chiffre minimum. */
  min: ({ min, actual }) => TranslateService.get('errors/min', { min: min }),
  /** Message lorsqu'il y a un chiffre maximum. */
  max: ({ max, actual }) => TranslateService.get('errors/max', { max: max }),
  /** Message lorsque l'email n'est pas valide. */
  email: () => TranslateService.get('errors/email'),
  /** Message lorsqu'il y a une expression régulière. */
  pattern: ({ actualValue: { value }, requiredPattern }) =>
    TranslateService.get('errors/pattern', { pattern: requiredPattern }),
  /** Message lorsqu'il y a une case qui doit être cochée. */
  requiredTrue: () => TranslateService.get('errors/requiredTrue'),
  /** Message lorsque le champ doit être unique. */
  unique: () => TranslateService.get('errors/unique'),
  /** Message lorsque la comparaison n'est pas bonne. */
  compare: (options: CompareOptions & { label: string }) =>
    TranslateService.get('errors/compare', options),
  notNullOrEmpty: () => TranslateService.get('errors/notNullOrEmpty'),
  /** Message lorsque la saisie n'est pas de type adresse ip. */
  ipAddress: () => TranslateService.get('errors/ipAddressFormat'),
  /** Message si la localisation n'est pas un stock */
  notStock: () => TranslateService.get('errors/notStock'),
  /** Message si la matrice n'est pas renseigné entierement */
  requiredMatrix: () => TranslateService.get('errors/requiredMatrix'),
  /** Message si la catégorie n'est pas sélectionnable */
  categoryNotValid: () => TranslateService.get('errors/categoryNotValid'),
};
