import {
  ChangeDetectorRef,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router } from '@angular/router';
import { Pooling } from '@clarilog/core';
import CustomStore from 'devextreme/data/custom_store';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { AlertCoreService } from '@clarilog/core/services2/graphql/generated-types/services/alert.service';
import {
  CoreGraphQLSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { AlarmCoreService, NotificationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2';
import { Sort } from '@clarilog/core/services2/graphql/generated-types/types';

@Component({
  selector: 'clc-alert',
  templateUrl: './alert.component.html',
  styleUrls: ['./alert.component.scss'],
})
/** Composant de gestion des alertes */
export class CoreAlertComponent implements OnInit, OnDestroy {
  source: ModelDataSourceContext;
  store: CustomStore;
  /** Variable des éléments cible de positionnement */
  itemButton: any;
  public showItem: boolean = false;
  private timer: NodeJS.Timeout;
  public alertNumber: number = 0;

  constructor(
    public alertService: AlertCoreService,
    public alarmService: AlarmCoreService,
    public notificationService: NotificationCoreService,

    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private poolingService: Pooling,
    private _injector: Injector,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) { }

  ngOnInit() {
    this.source = new ModelDataSourceContext({
      serviceName: 'alarmService',
      methodName: 'findAlarmWithNotViewedAlert',
    });

    this.source.datasource = CoreGraphQLSource.create({
      context: this.source,
      query: (filters?: any, options?: any) => {
        let key = this.translatedFieldHelperService.getTranslateKey();
        let sort = {};
        sort[key] = Sort.Asc;
        options.sort = [{ name: [sort] }];
        return this.alarmService.findAlarmWithNotViewedAlert(
          [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlSubField.create(
                'name',
                this.translatedFieldHelperService.translatedFields(),
              ),
              //GqlSubField.create('description', this.translatedFieldHelperService.translatedFields()),
              GqlField.create('countNotViewedAlert'),
            ]),
          ],
          options,
        );
      },
    });

    this.updateAlertNumber();
    this.store = this.source.datasource.store() as CustomStore;
    this.timer = this.poolingService.poolingByCallback(
      this.updateAlertNumber.bind(this),
      60000 * 5,
    );

    this.alertService.alertNumberServiceChange.subscribe((res) => {
      this.updateNumber(res);
    });
  }

  /** Événement d'initialisation */
  onAlertButtonInitialized(e) {
    this.itemButton = e.element;
    this.changeDetectorRef.detectChanges();
    // this.source.datasource.load();

    //update the number of unseen alert
    this.updateAlertNumber();
  }

  itemClick(e) {
    this.source.datasource.reload();

    //update the number of unseen alert
    this.updateAlertNumber();

    this.showItem = true;
  }

  clickEvent(event, alarm) {
    this.router.navigate([`/alerts`], {
      queryParams: { alarmId: alarm.id },
    });
    this.showItem = false;
  }
  /** Fonction callback permettant de mettre a jour en pooling le nombre d'alarmes non vue */
  updateAlertNumber() {
    this.alertService
      .countNotViewedAlert(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      )
      .subscribe((res) => {
        this.updateNumber(res.data);
      });
  }

  updateNumber(value: number) {
    this.alertNumber = value;
  }

  ngOnDestroy(): void {
    (
      this.source.datasource.store() as GraphQLStore
    ).context.context.clearTimeout();
    this.alertService.alertNumberServiceChange.unsubscribe();
    clearTimeout(this.timer);
  }
}
