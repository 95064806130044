
import { SatisfactionSettingBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core';

@Injectable({providedIn: 'root'})
@Authorize('help-desk-operator.manage-satisfaction-setting')
export class SatisfactionSettingCoreService extends SatisfactionSettingBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}
