import { LdapHistoryBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs/internal/Observable';
import { ClarilogException } from '../types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class LdapHistoryCoreService extends LdapHistoryBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Récupère les erreurs d'un paramètre d'audit */
  findExceptions(id: string): Observable<Array<ClarilogException>> {
    return this.get(
      [
        GqlSubField.create('data', [
          GqlSubField.create('exceptions', [GqlField.create('message')]),
        ]),
      ],
      id,
    ).pipe(map((s) => s.data?.exceptions));
  }
}
