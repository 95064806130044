<cl-work-page>
  <cl-work-page-header *ngIf="!compactMode">
    <cl-work-page-header-content>
      <cl-work-page-header-content-title
        [showBack]="showBack"
        [backMode]="backMode"
        (onBackClick)="backClick($event)"
        >{{ title }} <span class="hint-work-items">{{ hint }}</span>
      </cl-work-page-header-content-title>
      <cl-work-page-header-content-body>
        <cl-toolbar>
          <cl-toolbar-items [items]="toolbarItems?.itemsChildren.toArray()">
          </cl-toolbar-items>
        </cl-toolbar>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-errors
    *ngIf="!compactMode"
    [errors]="errors"
  ></cl-work-page-errors>
  <cl-work-page-left
    [hide]="
      modelState == undefined ||
      modelState.model == undefined ||
      (modelState?.model.grid.layout.filters.length === 1 &&
        (modelState?.model.grid.layout.filters)[0].items.length === 1 &&
        forceNavMenu === false)
    "
  >
    <clc-nav-menu>
      <clc-nav-group
        *ngFor="let filter of modelState?.model.grid.layout.filters"
        [text]="filter.text"
      >
        <clc-nav-menu-item
          *ngFor="let item of filter.items"
          [ngClass]="filter.items.length == 1 ? 'only-one-filter-item' : ''"
          [active]="item === current"
          [modelState]="modelState"
          [text]="item.text"
          [parameterType]="item?.list?.parameters?.type"
          [canAccumulateFitler]="item?.list?.canAccumulateFitler"
          [hint]="item.description"
          [source]="item.source"
          [filterExpr]="item.filterExpr"
          [parentIdExpr]="item.parentIdExpr"
          [keyExpr]="item.keyExpr"
          [inContext]="item.inContext"
          [displayExpr]="item.displayExpr"
          [multiple]="item.multiple"
          [filterLive]="item.filterLive"
          [recursive]="item.recursive"
          [visibled]="item.visible != undefined ? item.visible : true"
          [filterOperation]="item.filterOperator"
          (onClick)="onClick($event, item)"
          (onItemSelected)="itemSelected($event)"
          [filterContext]="item.filterContext"
          [refreshView]="refreshView"
          [deniedCumulateFilter]="deniedCumulateFilter"
          [canByPassItemSelectionChanged]="canByPassItemSelectionChanged"
          (onItemSelectionChanged)="
            item.command != undefined
              ? onItemSelectionChangedCommand($event)
              : onItemSelectionChanged($event)
          "
        ></clc-nav-menu-item>
      </clc-nav-group>
    </clc-nav-menu>
  </cl-work-page-left>
  <cl-work-page-content>
    <clc-list
      [compactMode]="compactMode"
      [layoutKey]="layoutKey"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      (onRowClick)="rowClick($event)"
      (onSingleCellClick)="singleCellClick($event)"
      [activeSelectLevelAction]="activeSelectLevelAction"
      [masterDetail]="modelState?.model?.grid?.layout?.masterDetail"
      (onSelectionKeyChanged)="selectionChanged($event)"
      [dragAndDrop]="modelState?.model?.grid.layout.dragAndDrop"
      [columns]="modelState?.model?.grid?.layout?.columns"
      [viewSelectMode]="modelState?.model?.grid.layout.viewSelectMode"
      [dragOnlySameLevel]="modelState?.model?.grid.layout.dragOnlySameLevel"
      [useDevExtremSelectAllTree]="true"
      [sourceRessource]="
        modelState?.model?.grid?.layout?.options?.sourceRessource
      "
      [insertRessource]="
        modelState?.model?.grid?.layout?.options?.insertRessource
      "
      [updateRessource]="
        modelState?.model?.grid?.layout?.options?.updateRessource
      "
      [removeItems]="modelState?.model?.grid?.layout?.options?.removeItems"
      [fields]="modelState?.model?.grid?.layout?.options?.fields"
      [canExport]="canExport"
      [options]="modelState?.model?.grid?.layout?.options"
      [drag]="modelState?.model?.grid.layout.drag"
      [type]="
        modelState?.model?.grid.layout.type != undefined
          ? modelState?.model?.grid.layout.type
          : type
      "
      [multiple]="modelState?.model?.grid.layout.multiple"
      [maxLevel]="modelState?.model?.grid.layout.maxLevel || -1"
      [fileName]="title"
      [exportFileName]="modelState?.model?.grid.layout.exportFileName"
      (onBeforeRefresh)="beforeRefresh($event)"
      (onDisplayFilter)="onDisplayFilter($event)"
      (onClearFilter)="onClearFilter($event)"
      [delayAutoRefresh]="delayAutoRefresh"
      [displayAutoRefreshCheckbox]="displayAutoRefreshCheckbox"
      [enableForceAutoRefresh]="enableForceAutoRefresh"
      [canSelect]="canSelect"
      [atlasIndexes]="modelState?.model?.grid?.layout?.atlasIndexes"
      [modelState]="modelState"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
