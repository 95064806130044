
import { AlertBaseService } from '../service-bases'
import { Injectable, Injector, Output, EventEmitter } from '@angular/core';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

@Injectable({ providedIn: 'root' })
export class AlertCoreService extends AlertBaseService {

  @Output() alertNumberServiceChange = new EventEmitter();
  public set alertNumberService(value: number) {
    this.alertNumberServiceChange.emit(value);
  }


  async forceRefreshNumberAlert() {
    let result = await this.countNotViewedAlert(ModelFieldCompilerService.createServiceSingleResultScalar()).toPromise();
    this.alertNumberServiceChange.emit(result.data);
  }
  constructor(injector: Injector) {
    super(injector)
  }
}