<dx-popup
  [width]="'37%'"
  [height]="'195px'"
  [showTitle]="false"
  class="popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [showCloseButton]="true"
  [(visible)]="isVisible"
>
  <cl-work-page class="container">
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title [showBack]="false">
          {{ 'globals/move' | translate }}
        </cl-work-page-header-content-title>
        <cl-work-page-header-content-body>
          <cl-toolbar>
            <cl-toolbar-items>
              <cl-toolbar-item-wait-button
                (onClick)="saving($event)"
                [type]="'default'"
                [text]="'globals/move' | translate"
                location="after"
                [disabled]="disabledButtonMove"
                [wait]="isWaiting"
              >
              </cl-toolbar-item-wait-button>
            </cl-toolbar-items>
          </cl-toolbar>
        </cl-work-page-header-content-body>
      </cl-work-page-header-content>
    </cl-work-page-header>
    <cl-work-page-content>
      <div class="container-content">
        <div
          *ngIf="modelState !== undefined"
          class="work-item-container-scroll"
        >
          <div>
            <clc-work-item-section-container
              *ngIf="modelState.form !== undefined && modelState.form !== null"
              [percentage]="0"
            >
              <cl-work-item-section
                *ngFor="
                  let section of modelState.model.form.layout.pages[0][
                    'sections'
                  ]
                "
                [formGroup]="
                  modelState.form?.get(
                    modelState.model.form.layout.pages[0].name
                  )
                "
              >
                <clc-work-item-section-group
                  *ngFor="let group of section.groups"
                  [title]="group.label"
                  [formGroup]="
                    modelState.form
                      .get(modelState.model.form.layout.pages[0].name)
                      .get(group.name)
                  "
                  [visibled]="
                    modelState.form
                      .get(modelState.model.form.layout.pages[0].name)
                      .get(group.name).visibled
                  "
                >
                  <clc-work-item-control-dynamic
                    [controls]="group.controls"
                    [mode]="'full'"
                    [autoFocus]="
                      modelState.model.form.layout.pages[0]['sections'][0] ===
                        section && section.groups[0] === group
                    "
                  ></clc-work-item-control-dynamic>
                </clc-work-item-section-group>
              </cl-work-item-section>
            </clc-work-item-section-container>
          </div>
        </div>
      </div>
    </cl-work-page-content>
  </cl-work-page>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="close()"></dx-button>
  </div>
</dx-popup>
