<div class="alert-container">
  <dx-button icon="fal fa-exclamation-triangle" (onInitialized)="onAlertButtonInitialized($event)"
    (onClick)="itemClick($event)" class="cl-item-button-alert">
  </dx-button>
  <span *ngIf="alertNumber > 0">{{ alertNumber }}</span>
</div>
<dx-popover class="cl-popover" [position]="{
    my: 'right top',
    at: 'right bottom',
    of: itemButton,
    collision: {
      x: 'flip',
      y: 'flip'
    }
  }" [width]="'auto'" [(visible)]="showItem">
  <dx-list width="450px" [dataSource]="source['datasource']" keyExpr="key" style="max-height: 900px">
    <div *dxTemplate="let alarm of 'item'" (click)="clickEvent($event, alarm)">
      <b> {{ translatedFieldHelperService.findValueToShow(alarm.name) }}</b>
      <b> ({{ alarm.countNotViewedAlert }})</b>
      <br />
      <div class="cl-content-alert" [innerHtml]="translatedFieldHelperService.findValueToShow(alarm.description)"></div>
    </div>
  </dx-list>
</dx-popover>