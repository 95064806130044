import {
  Component,
  AfterContentInit,
  ContentChildren,
  QueryList,
  HostBinding,
  OnDestroy,
  ElementRef,
  Input,
} from '@angular/core';
import { CoreWorkItemSectionGroupComponent } from '../work-item-section-group/work-item-section-group.component';
import { WorkItemSectionComponent } from '../work-item-section/work-item-section.component';

/** Représente le container des sections. */
@Component({
  selector: 'clc-work-item-section-container',
  templateUrl: './work-item-section-container.component.html',
  styleUrls: ['./work-item-section-container.component.scss'],
})
export class CoreWorkItemSectionContainerComponent implements AfterContentInit {
  /** Obtient ou définit les @type {WorkItemSectionGroupComponent} enfants. */
  @ContentChildren(CoreWorkItemSectionGroupComponent, { descendants: true })
  workItemSectionGroupChildren: QueryList<CoreWorkItemSectionGroupComponent>;
  @ContentChildren(WorkItemSectionComponent, { descendants: true })
  workItemSectionChildren: QueryList<WorkItemSectionComponent>;
  /** Bind la classe css du comopsant par rapport à la propriété hasTwoColumn. */
  // @HostBinding('class.two-column') hasTwoColumn: boolean = false;
  @HostBinding('class.two-column') hasTwoColumn: boolean = false;
  @HostBinding('class.seventies-column') has70Column: boolean = false;
  @HostBinding('class.fifty-column') has50Column: boolean = false;
  @HostBinding('class.auto') auto: boolean = false;

  @Input() percentage: string;

  constructor(private element: ElementRef) {}

  /** @inheritdoc */
  ngAfterContentInit(): void {
    this.workItemSectionGroupChildren.toArray().forEach((value) => {
      value.onVisibledChanged.subscribe((_) => {
        setTimeout(() => this.countDisplaySectionGroup());
      });
    });
    this.countDisplaySectionGroup();
  }

  /** Compte le nombre de groupe de section visible. */
  private countDisplaySectionGroup() {
    let count = 0;

    // for (let element of this.element.nativeElement.querySelectorAll('clc-work-item-section')) {
    //   for (let child of element.querySelectorAll('clc-work-item-section-group')) {
    //     if (child.style.display === 'block') {
    //       count++;
    //       break;
    //     }
    //   }
    // }
    if (this.workItemSectionChildren.length === 2) {
      switch (this.percentage) {
        case '70':
          this.has70Column = true;
          break;
        case '50':
          this.has50Column = true;
        default:
          this.hasTwoColumn = true;
      }
    } else if (this.percentage == '0') {
      this.auto = true;
    }

    // this.hasTwoColumn = count === 2;
  }
}
