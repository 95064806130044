import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLdapHistoriesBaseVariables, FirstLdapHistoriesBaseVariables, CountLdapHistoriesBaseVariables, FindLdapHistoriesBaseVariables, SearchLdapHistoriesBaseVariables, ExportSchemaLdapHistoriesBaseVariables, ExportDataLdapHistoriesBaseVariables, CustomQueryLdapHistoriesBaseVariables, CustomQueryIdLdapHistoriesBaseVariables, UsedLdapHistoriesBaseVariables, FindExcludeUsersLdapHistoriesBaseVariables, ExistLdapHistoriesBaseVariables, InsertLdapHistoriesBaseVariables, UpdateLdapHistoriesBaseVariables, ImportDataLdapHistoriesBaseVariables, UpdateManyLdapHistoriesBaseVariables, DeleteLdapHistoriesBaseVariables } from '../gqls'
import { GetLdapHistoriesDocument, FirstLdapHistoriesDocument, CountLdapHistoriesDocument, FindLdapHistoriesDocument, SearchLdapHistoriesDocument, ExportSchemaLdapHistoriesDocument, ExportDataLdapHistoriesDocument, CustomQueryLdapHistoriesDocument, CustomQueryIdLdapHistoriesDocument, UsedLdapHistoriesDocument, FindExcludeUsersLdapHistoriesDocument, ExistLdapHistoriesDocument, InsertLdapHistoriesDocument, UpdateLdapHistoriesDocument, ImportDataLdapHistoriesDocument, UpdateManyLdapHistoriesDocument, DeleteLdapHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfLdapHistory, QueryContextOfLdapHistory, FilterOfLdapHistory, ServiceSingleResultOfInt64, ServiceListResultOfLdapHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfExcludeFilterUser, QueryContextOfExcludeFilterUser, LdapHistoryInput, FieldUpdateOperatorOfLdapHistory } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LdapHistoryBaseService {
    
public modelName = 'ldapHistory';
public modelPluralName = 'ldapHistories';

	private getLdapHistoriesQuery: GetLdapHistoriesDocument;
	private firstLdapHistoriesQuery: FirstLdapHistoriesDocument;
	private countLdapHistoriesQuery: CountLdapHistoriesDocument;
	private findLdapHistoriesQuery: FindLdapHistoriesDocument;
	private searchLdapHistoriesQuery: SearchLdapHistoriesDocument;
	private exportSchemaLdapHistoriesQuery: ExportSchemaLdapHistoriesDocument;
	private exportDataLdapHistoriesQuery: ExportDataLdapHistoriesDocument;
	private customQueryLdapHistoriesQuery: CustomQueryLdapHistoriesDocument;
	private customQueryIdLdapHistoriesQuery: CustomQueryIdLdapHistoriesDocument;
	private usedLdapHistoriesQuery: UsedLdapHistoriesDocument;
	private findExcludeUsersLdapHistoriesQuery: FindExcludeUsersLdapHistoriesDocument;
	private existLdapHistoriesQuery: ExistLdapHistoriesDocument;
	private insertLdapHistoriesMutation: InsertLdapHistoriesDocument;
	private updateLdapHistoriesMutation: UpdateLdapHistoriesDocument;
	private importDataLdapHistoriesMutation: ImportDataLdapHistoriesDocument;
	private updateManyLdapHistoriesMutation: UpdateManyLdapHistoriesDocument;
	private deleteLdapHistoriesMutation: DeleteLdapHistoriesDocument;

	constructor(private injector: Injector) {
		this.getLdapHistoriesQuery = this.injector.get(GetLdapHistoriesDocument);
		this.firstLdapHistoriesQuery = this.injector.get(FirstLdapHistoriesDocument);
		this.countLdapHistoriesQuery = this.injector.get(CountLdapHistoriesDocument);
		this.findLdapHistoriesQuery = this.injector.get(FindLdapHistoriesDocument);
		this.searchLdapHistoriesQuery = this.injector.get(SearchLdapHistoriesDocument);
		this.exportSchemaLdapHistoriesQuery = this.injector.get(ExportSchemaLdapHistoriesDocument);
		this.exportDataLdapHistoriesQuery = this.injector.get(ExportDataLdapHistoriesDocument);
		this.customQueryLdapHistoriesQuery = this.injector.get(CustomQueryLdapHistoriesDocument);
		this.customQueryIdLdapHistoriesQuery = this.injector.get(CustomQueryIdLdapHistoriesDocument);
		this.usedLdapHistoriesQuery = this.injector.get(UsedLdapHistoriesDocument);
		this.findExcludeUsersLdapHistoriesQuery = this.injector.get(FindExcludeUsersLdapHistoriesDocument);
		this.existLdapHistoriesQuery = this.injector.get(ExistLdapHistoriesDocument);
		this.insertLdapHistoriesMutation = this.injector.get(InsertLdapHistoriesDocument);
		this.updateLdapHistoriesMutation = this.injector.get(UpdateLdapHistoriesDocument);
		this.importDataLdapHistoriesMutation = this.injector.get(ImportDataLdapHistoriesDocument);
		this.updateManyLdapHistoriesMutation = this.injector.get(UpdateManyLdapHistoriesDocument);
		this.deleteLdapHistoriesMutation = this.injector.get(DeleteLdapHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapHistory> {
    
    		let variables: GetLdapHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.get));
            }
            else{
                let result:ServiceSingleResultOfLdapHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapHistory> {
    
    		let variables: FirstLdapHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLdapHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: FindLdapHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: SearchLdapHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLdapHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLdapHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: CustomQueryLdapHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: CustomQueryIdLdapHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLdapHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.used));
    	}

    	/**  */
    	@InjectArgs
    	public findExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfExcludeFilterUser,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfExcludeFilterUser> {
    
    		let variables: FindExcludeUsersLdapHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findExcludeUsersLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.findExcludeUsers));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLdapHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLdapHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LdapHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapHistory> {
    
    		let variables: InsertLdapHistoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertLdapHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapHistory> {
    
    		let variables: UpdateLdapHistoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLdapHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLdapHistoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLdapHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLdapHistoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLdapHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLdapHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLdapHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapHistories.delete));
    	}
    
}