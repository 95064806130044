import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  Output,
} from '@angular/core';
import { EntityAttributesType } from '@clarilog/core';
import Globalize from 'globalize/dist/globalize';
import { Location } from '@angular/common';
import { ActivatedRoute, Router } from '@angular/router';

/** Représente le titre de l'en-tête du composent WorkPage. */
@Component({
  selector: 'cl-work-page-header-content-title',
  templateUrl: './work-page-header-content-title.component.html',
  styleUrls: ['./work-page-header-content-title.component.scss'],
})
export class WorkPageHeaderContentTitleComponent {
  constructor(
    public location: Location,
    private router: Router,
    private route: ActivatedRoute,
  ) {}
  @Input() showBack: boolean = false;
  @Input() backMode: boolean = false;
  @Input() mobileMode: boolean = false;
  @Input() attributesInfo: EntityAttributesType = undefined;
  @Input() menuNavMobileVisibility: boolean = false;
  @Output() onBackClick: EventEmitter<any> = new EventEmitter<any>();
  @Output() onMobileMenuClick: EventEmitter<any> = new EventEmitter<any>();

  backClick(e) {
    if (this.showBack === true) {
      if (this.backMode === true) {
        this.onBackClick.emit(e);
      } else {
        let prevState = sessionStorage.getItem('prevState');
        if (
          prevState &&
          prevState !== 'undefined' &&
          prevState !== this.router?.url
        ) {
          // Test si on provient d'une list qui enregistre le previous state
          this.router.navigateByUrl(prevState);
        } else {
          let segment = this.route?.snapshot?.url;
          if (
            segment != undefined &&
            segment.length > 0 &&
            this.router.url != undefined &&
            this.router.url.indexOf(segment[0].path) > 0
          ) {
            let newUrl = this.router.url.substring(
              0,
              this.router.url.indexOf(segment[0].path) - 1,
            );
            this.router.navigateByUrl(newUrl);
          } else {
            let length = window.history.length;
            if (length > 1) {
              window.history.go(-1);
            } else {
              this.onBackClick.emit(e);
            }
          }
        }
      }
    }
  }
  format(date) {
    return Globalize.formatDate(new Date(date), { date: 'medium' });
  }
}
