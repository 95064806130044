import { Component, HostBinding, Input, OnChanges, SimpleChanges } from '@angular/core';

/** Représente le mode d'affichage du libellé. */
export type WorkItemFieldMode = 'line' | 'default' | 'full' | 'inline';
/** Représente un composent field d'un formulaire. */
@Component({
  selector: 'clc-work-item-field',
  templateUrl: './work-item-field.component.html',
  styleUrls: ['./work-item-field.component.scss']
})
export class CoreWorkItemFieldComponent implements OnChanges {
  /** Obtient ou définit la visibilité. */
  @Input() visibled: boolean;
  /** Obtient ou définit le libellé. */
  @Input() label: string;
  /** Obtient ou définit les informations d'aide pour l'utilisateur. */
  @Input() hint: string | Function;
  /** Degfinit la visibilité du hint Ex on peux le mettre en FULL pour qu'il soit fortement visible */
  @Input() hintVisibility: string;
  /** Obtient ou définit une valeur indiquant si le form est chargé. */
  @Input() loaded: boolean = true;
  /** Obtient ou définit une valeur indiquant la validité. */
  @Input() isValid: boolean;
  /** Obtient ou définit la liste des erreurs. */
  @Input() errors;
  /** Obtient ou définit l'état indiquant si l'élément est requis. */
  @Input() required: boolean;
  /** Obtient ou définit le mode. */
  @Input() mode: WorkItemFieldMode = 'default';
  /** Obtient ou définit si la valeur contenu a changé. */
  @Input() valueChange: boolean = false;
  /** Obtient ou définit la classe css en fonction du mode. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.mode;
  }
  /** Active la classe css hide si la propriété @property {visibled} est false. */
  @HostBinding('class.hide')
  public get getCssHide(): any {
    return !this.visibled;
  }
  constructor() {
  }
  private hintFunc: Function;
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.hint != undefined && typeof changes.hint.currentValue === 'function') {
      this.hintFunc = <Function>this.hint;
      this.hint = this.hintFunc();
    }
    else if (changes.valueChange != undefined && changes.valueChange.currentValue === true) {
      if (this.hintFunc != undefined) {
        this.hint = this.hintFunc();
      }
    }
  }
}
