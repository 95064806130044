import { Type } from '@angular/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

/** Représente un espace de travail. */
export class WorkItem {
  /** Obtient ou définit le titre. */
  title?: string;
  /** Obtient ou définit l'icon. */
  icon?: string;
  /** Obtient ou définit le nom unique. */
  name?: string;
  /** Obtient ou définit le composent à afficher. */
  content?: Type<any>;
  /** Obtient ou définit les éléments enfants. */
  items?: WorkItem[];
  /** Obtient ou définit le nom unique. */
  dependBadges?: string[];
  // /** Obtient ou définit une valeur indiquant la validité de l'item. */
  // isValid?:boolean = true;
  /** Obtient ou définit des données supplémentaires. */
  data?: any;
  enabled?: boolean = true;
  /** Obtient ou définit si l'item est une recherche global */
  globalSearch?: boolean = false;

  /** Obtient ou définit si vibile */
  visible?: boolean = true;
}

/** Représente un espace de travail. */
export class MobileWorkItem extends WorkItem {
  /** Obtient ou définit les enfants du noeud (BETA) */
  children?: WorkItem[] = [];
  id?: string;
  isSelected?: boolean = false;
  expanded?: boolean = true;
  disabled?: boolean = false;
  visible?: boolean = true;
}
