import {
  Component,
  Injector,
  NgModule,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { EnvironmentService } from '@clarilog/core';
import {
  DxCheckBoxModule,
  DxDataGridComponent,
  DxTemplateHost,
} from 'devextreme-angular';
import { Column } from '@clarilog/shared2/models/schema';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';

/** Représente un composant de gestion des templates. */
@Component({
  selector: 'clc-templates-row',
  templateUrl: './templates-row.component.html',
  styleUrls: ['./templates-row.component.scss'],
  providers: [DxTemplateHost],
})
export class CoreTemplatesRowComponent {
  @ViewChild(DxDataGridComponent) dataGrid: DxDataGridComponent;

  console = console;
  /** Permet de mettre en cache les templates. */
  _cache: { [key: string]: TemplateRef<any> } = {};
  /** Obtient ou définit la listes des templates. */
  @ViewChildren(TemplateRef) templates: QueryList<TemplateRef<any>>;
  constructor(
    private injector: Injector,
    private _envService: EnvironmentService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {}

  /** Récupère un template via son id. */
  get(name: string): TemplateRef<any> {
    if (name == undefined) return undefined;
    if (this._cache[name] === undefined) {
      let templates = this.templates.toArray().filter(
        (
          f: TemplateRef<any> & {
            _declarationTContainer: { localNames: [any] };
          },
        ) => {
          if (
            f._declarationTContainer.localNames != undefined &&
            f._declarationTContainer.localNames.includes != undefined
          ) {
            return f._declarationTContainer.localNames.includes(name);
          }
        },
      );
      this._cache[name] = templates.length > 0 ? templates[0] : undefined;
    }
    return this._cache[name];
  }

  getTranslate(field) {
    if (field != undefined) {
      let value = field[this.translatedFieldHelperService.getTranslateKey()];
      if (value != undefined) {
        return value;
      }
    }

    return field;
  }
}
