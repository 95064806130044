import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLicensesBaseVariables, CurrentFeatureListLicensesBaseVariables, UpdateLicensesBaseVariables, DownloadLicensesBaseVariables, UploadLicensesBaseVariables } from '../gqls'
import { GetLicensesDocument, CurrentFeatureListLicensesDocument, UpdateLicensesDocument, DownloadLicensesDocument, UploadLicensesDocument } from '../gqls'
import { ServiceSingleResultOfLicenseModel, ServiceListResultOfProductFeature, FieldUpdateOperatorOfLicenseModel, ServiceSingleResultOfString, LicenseModelInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LicenseBaseService {
    
public modelName = 'license';
public modelPluralName = 'licenses';

	private getLicensesQuery: GetLicensesDocument;
	private currentFeatureListLicensesQuery: CurrentFeatureListLicensesDocument;
	private updateLicensesMutation: UpdateLicensesDocument;
	private downloadLicensesMutation: DownloadLicensesDocument;
	private uploadLicensesMutation: UploadLicensesDocument;

	constructor(private injector: Injector) {
		this.getLicensesQuery = this.injector.get(GetLicensesDocument);
		this.currentFeatureListLicensesQuery = this.injector.get(CurrentFeatureListLicensesDocument);
		this.updateLicensesMutation = this.injector.get(UpdateLicensesDocument);
		this.downloadLicensesMutation = this.injector.get(DownloadLicensesDocument);
		this.uploadLicensesMutation = this.injector.get(UploadLicensesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLicenseModel> {
    
    		let variables: GetLicensesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLicensesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.licenses.get));
            }
            else{
                let result:ServiceSingleResultOfLicenseModel={};
			    return of(result)
            }
            
    	}

    	/**  */
    	@InjectArgs
    	public currentFeatureList(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProductFeature> {
    
    		let variables: CurrentFeatureListLicensesBaseVariables = {
    
    		}
    				return this.currentFeatureListLicensesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.licenses.currentFeatureList));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLicenseModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLicenseModel> {
    
    		let variables: UpdateLicensesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLicensesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.licenses.update));
    	}

    	/** Permet de télécharger la license. */
    	@InjectArgs
    	public download(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('licenseModel?') licenseModel?: LicenseModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: DownloadLicensesBaseVariables = {
    			licenseModel: licenseModel
    		}
    				return this.downloadLicensesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.licenses.download));
    	}

    	/** Permet de télétransférer la license. */
    	@InjectArgs
    	public upload(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('license?') license?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UploadLicensesBaseVariables = {
    			id: id,
			license: license
    		}
    				return this.uploadLicensesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.licenses.upload));
    	}
    
}