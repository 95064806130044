import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfLdapOrganizationalUnit, FilterOfLdapOrganizationalUnit, QueryContextOfLdapOrganizationalUnit, ServiceSingleResultOfInt64, ServiceListResultOfLdapOrganizationalUnit, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LdapObjectClassType, LdapOrganizationalUnitInput, FieldUpdateOperatorOfLdapOrganizationalUnit } from '../types'

export type GetLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        get?: Maybe<ServiceSingleResultOfLdapOrganizationalUnit>
    }
}

export type GetLdapOrganizationalUnitsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        first?: Maybe<ServiceSingleResultOfLdapOrganizationalUnit>
    }
}

export type FirstLdapOrganizationalUnitsBaseVariables = {
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapOrganizationalUnit>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapOrganizationalUnit = null, $options: QueryContextOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountLdapOrganizationalUnitsBaseVariables = {
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        find?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type FindLdapOrganizationalUnitsBaseVariables = {
	options: Maybe<QueryContextOfLdapOrganizationalUnit>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfLdapOrganizationalUnit = null, $filter: FilterOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        search?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type SearchLdapOrganizationalUnitsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfLdapOrganizationalUnit>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaLdapOrganizationalUnitsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataLdapOrganizationalUnitsBaseVariables = {
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        customQuery?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type CustomQueryLdapOrganizationalUnitsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapOrganizationalUnit>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfLdapOrganizationalUnit = null, $options: QueryContextOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        customQueryId?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type CustomQueryIdLdapOrganizationalUnitsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfLdapOrganizationalUnit>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapOrganizationalUnit>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfLdapOrganizationalUnit = null, $options: QueryContextOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedLdapOrganizationalUnitsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTreeLdapLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        findTreeLdap?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type FindTreeLdapLdapOrganizationalUnitsBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTreeLdapLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTreeLdapLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        findTreeLdap(ldapObjectClassType: $ldapObjectClassType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTreeLdapLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTreeLdapLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindTreeLdapByScanconfigurationLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        findTreeLdapByScanconfiguration?: Maybe<ServiceListResultOfLdapOrganizationalUnit>
    }
}

export type FindTreeLdapByScanconfigurationLdapOrganizationalUnitsBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
	id: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindTreeLdapByScanconfigurationLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!, $id: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findTreeLdapByScanconfigurationLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        findTreeLdapByScanconfiguration(ldapObjectClassType: $ldapObjectClassType, id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindTreeLdapByScanconfigurationLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindTreeLdapByScanconfigurationLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistLdapOrganizationalUnitsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        insert?: Maybe<ServiceSingleResultOfLdapOrganizationalUnit>
    }
}

export type InsertLdapOrganizationalUnitsBaseVariables = {
	entity: LdapOrganizationalUnitInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: LdapOrganizationalUnitInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        update?: Maybe<ServiceSingleResultOfLdapOrganizationalUnit>
    }
}

export type UpdateLdapOrganizationalUnitsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfLdapOrganizationalUnit>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapOrganizationalUnit", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataLdapOrganizationalUnitsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyLdapOrganizationalUnitsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfLdapOrganizationalUnit>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfLdapOrganizationalUnit = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteLdapOrganizationalUnitsResultType = {
    ldapOrganizationalUnits: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteLdapOrganizationalUnitsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteLdapOrganizationalUnitsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteLdapOrganizationalUnits ${args} {
    ldapOrganizationalUnits {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteLdapOrganizationalUnitsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteLdapOrganizationalUnitsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
