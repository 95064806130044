import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVideoControllersBaseVariables, FirstVideoControllersBaseVariables, CountVideoControllersBaseVariables, FindVideoControllersBaseVariables, SearchVideoControllersBaseVariables, ExportSchemaVideoControllersBaseVariables, ExportDataVideoControllersBaseVariables, CustomQueryVideoControllersBaseVariables, CustomQueryIdVideoControllersBaseVariables, UsedVideoControllersBaseVariables, ExistVideoControllersBaseVariables } from '../gqls'
import { GetVideoControllersDocument, FirstVideoControllersDocument, CountVideoControllersDocument, FindVideoControllersDocument, SearchVideoControllersDocument, ExportSchemaVideoControllersDocument, ExportDataVideoControllersDocument, CustomQueryVideoControllersDocument, CustomQueryIdVideoControllersDocument, UsedVideoControllersDocument, ExistVideoControllersDocument } from '../gqls'
import { ServiceSingleResultOfVideoController, QueryContextOfVideoController, FilterOfVideoController, ServiceSingleResultOfInt64, ServiceListResultOfVideoController, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VideoControllerBaseService {
    
public modelName = 'videoController';
public modelPluralName = 'videoControllers';

	private getVideoControllersQuery: GetVideoControllersDocument;
	private firstVideoControllersQuery: FirstVideoControllersDocument;
	private countVideoControllersQuery: CountVideoControllersDocument;
	private findVideoControllersQuery: FindVideoControllersDocument;
	private searchVideoControllersQuery: SearchVideoControllersDocument;
	private exportSchemaVideoControllersQuery: ExportSchemaVideoControllersDocument;
	private exportDataVideoControllersQuery: ExportDataVideoControllersDocument;
	private customQueryVideoControllersQuery: CustomQueryVideoControllersDocument;
	private customQueryIdVideoControllersQuery: CustomQueryIdVideoControllersDocument;
	private usedVideoControllersQuery: UsedVideoControllersDocument;
	private existVideoControllersQuery: ExistVideoControllersDocument;

	constructor(private injector: Injector) {
		this.getVideoControllersQuery = this.injector.get(GetVideoControllersDocument);
		this.firstVideoControllersQuery = this.injector.get(FirstVideoControllersDocument);
		this.countVideoControllersQuery = this.injector.get(CountVideoControllersDocument);
		this.findVideoControllersQuery = this.injector.get(FindVideoControllersDocument);
		this.searchVideoControllersQuery = this.injector.get(SearchVideoControllersDocument);
		this.exportSchemaVideoControllersQuery = this.injector.get(ExportSchemaVideoControllersDocument);
		this.exportDataVideoControllersQuery = this.injector.get(ExportDataVideoControllersDocument);
		this.customQueryVideoControllersQuery = this.injector.get(CustomQueryVideoControllersDocument);
		this.customQueryIdVideoControllersQuery = this.injector.get(CustomQueryIdVideoControllersDocument);
		this.usedVideoControllersQuery = this.injector.get(UsedVideoControllersDocument);
		this.existVideoControllersQuery = this.injector.get(ExistVideoControllersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVideoController> {
    
    		let variables: GetVideoControllersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.get));
            }
            else{
                let result:ServiceSingleResultOfVideoController={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVideoController,
		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVideoController> {
    
    		let variables: FirstVideoControllersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVideoControllersBaseVariables = {
    			filter: filter
    		}
    				return this.countVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVideoController,
		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVideoController> {
    
    		let variables: FindVideoControllersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVideoController,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVideoController> {
    
    		let variables: SearchVideoControllersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVideoControllersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVideoControllersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVideoController,
		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVideoController> {
    
    		let variables: CustomQueryVideoControllersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVideoController,
		@Args('filter?') filter?: FilterOfVideoController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVideoController> {
    
    		let variables: CustomQueryIdVideoControllersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVideoControllersBaseVariables = {
    			ids: ids
    		}
    				return this.usedVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVideoControllersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVideoControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.videoControllers.exist));
    	}
    
    
}