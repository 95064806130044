<button
  type="button"
  [disabled]="!enabled"
  mat-button
  [title]="hint != undefined ? hint : ''"
  class="cl-menu-item-button"
  [ngClass]="{ 'cl-active': active, 'cl-disabled': !enabled }"
  style="vertical-align: baseline"
  onmousedown="return false;// Pour ne pas avoir le scroll"
  (click)="click($event)"
>
  <i
    *ngIf="icon != undefined"
    style="
      text-align: center;
      vertical-align: middle !important;
      font-size: 1.2em;
      min-width: 1.2em;
      margin-right: 5px;
    "
    class="{{ icon }}"
  ></i>

  <span
    class="text"
    [ngStyle]="{ display: mode === 'full' ? 'inline-block' : 'none' }"
    >{{ text }}
    <span *ngIf="countElement != undefined || countElement > 0">
      {{ '(' + countElement + ')' }}</span
    >
  </span>

  <div class="icon-container">
    <i
      *ngIf="isBadge === true && active === false && enabled === true"
      class="cl-badge-info fas fa-star-of-life"
    ></i>

    <i
      *ngIf="isValid === false && active === false && enabled === true"
      class="fal fa-exclamation-circle error"
    ></i>
    <i
      *ngIf="source != undefined"
      style="padding-left: 5px"
      [ngClass]="{
        'fa-chevron-down': !active,
        'fa-chevron-up': active
      }"
      class="fal"
    ></i>
  </div>
</button>
<div *ngIf="active && source != undefined">
  <div class="border-bottom">
    <cl-toolbar>
      <cl-toolbar-items>
        <cl-toolbar-item-button-group
          keyExpr="id"
          [selectionMode]="'multiple'"
          [items]="[
            {
              icon: 'fal fa-tasks',
              id: 'recursive',
              hint: 'globals/selectionMultiple' | translate
            }
          ]"
          [(selectedItemKeys)]="selectedItemKeys"
          (onItemClick)="changeRecursive({ component: treeView.instance })"
        >
        </cl-toolbar-item-button-group>
        <cl-toolbar-item-button
          icon="fal fa-minus-square"
          [hint]="'globals/collapseAll' | translate"
          (onClick)="treeView.instance.collapseAll()"
        ></cl-toolbar-item-button>
        <cl-toolbar-item-button
          icon="fal fa-plus-square"
          [hint]="'globals/expandAll' | translate"
          (onClick)="treeView.instance.expandAll()"
        ></cl-toolbar-item-button>
      </cl-toolbar-items>
    </cl-toolbar>
  </div>
  <div class="border-bottom">
    <dx-tree-view
      #treeView
      [searchEnabled]="true"
      [searchMode]="'contains'"
      [dataSource]="source.datasource"
      [parentIdExpr]="parentIdExpr"
      [keyExpr]="keyExpr"
      [displayExpr]="displayExpr"
      [selectByClick]="true"
      [scrollDirection]="'horizontal'"
      dataStructure="plain"
      showCheckBoxesMode="normal"
      [selectionMode]="multiple ? 'multiple' : 'single'"
      [selectNodesRecursive]="recursive"
      (onContentReady)="contentReady($event)"
      (onItemSelectionChanged)="itemSelectionChanged($event)"
      height="auto"
    >
      <!--Problème de scroll : [searchEnabled]="true"
      searchMode="contains" -->
    </dx-tree-view>
  </div>
</div>
