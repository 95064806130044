import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUserAffectedRulesBaseVariables, FirstUserAffectedRulesBaseVariables, CountUserAffectedRulesBaseVariables, FindUserAffectedRulesBaseVariables, SearchUserAffectedRulesBaseVariables, ExportSchemaUserAffectedRulesBaseVariables, ExportDataUserAffectedRulesBaseVariables, CustomQueryUserAffectedRulesBaseVariables, CustomQueryIdUserAffectedRulesBaseVariables, UsedUserAffectedRulesBaseVariables, ExistUserAffectedRulesBaseVariables, InsertUserAffectedRulesBaseVariables, UpdateUserAffectedRulesBaseVariables, ImportDataUserAffectedRulesBaseVariables, UpdateManyUserAffectedRulesBaseVariables, DeleteUserAffectedRulesBaseVariables, OrderUserAffectedRulesBaseVariables } from '../gqls'
import { GetUserAffectedRulesDocument, FirstUserAffectedRulesDocument, CountUserAffectedRulesDocument, FindUserAffectedRulesDocument, SearchUserAffectedRulesDocument, ExportSchemaUserAffectedRulesDocument, ExportDataUserAffectedRulesDocument, CustomQueryUserAffectedRulesDocument, CustomQueryIdUserAffectedRulesDocument, UsedUserAffectedRulesDocument, ExistUserAffectedRulesDocument, InsertUserAffectedRulesDocument, UpdateUserAffectedRulesDocument, ImportDataUserAffectedRulesDocument, UpdateManyUserAffectedRulesDocument, DeleteUserAffectedRulesDocument, OrderUserAffectedRulesDocument } from '../gqls'
import { ServiceSingleResultOfUserAffectedRule, QueryContextOfUserAffectedRule, FilterOfUserAffectedRule, ServiceSingleResultOfInt64, ServiceListResultOfUserAffectedRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, UserAffectedRuleInput, FieldUpdateOperatorOfUserAffectedRule } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UserAffectedRuleBaseService {
    
public modelName = 'userAffectedRule';
public modelPluralName = 'userAffectedRules';

	private getUserAffectedRulesQuery: GetUserAffectedRulesDocument;
	private firstUserAffectedRulesQuery: FirstUserAffectedRulesDocument;
	private countUserAffectedRulesQuery: CountUserAffectedRulesDocument;
	private findUserAffectedRulesQuery: FindUserAffectedRulesDocument;
	private searchUserAffectedRulesQuery: SearchUserAffectedRulesDocument;
	private exportSchemaUserAffectedRulesQuery: ExportSchemaUserAffectedRulesDocument;
	private exportDataUserAffectedRulesQuery: ExportDataUserAffectedRulesDocument;
	private customQueryUserAffectedRulesQuery: CustomQueryUserAffectedRulesDocument;
	private customQueryIdUserAffectedRulesQuery: CustomQueryIdUserAffectedRulesDocument;
	private usedUserAffectedRulesQuery: UsedUserAffectedRulesDocument;
	private existUserAffectedRulesQuery: ExistUserAffectedRulesDocument;
	private insertUserAffectedRulesMutation: InsertUserAffectedRulesDocument;
	private updateUserAffectedRulesMutation: UpdateUserAffectedRulesDocument;
	private importDataUserAffectedRulesMutation: ImportDataUserAffectedRulesDocument;
	private updateManyUserAffectedRulesMutation: UpdateManyUserAffectedRulesDocument;
	private deleteUserAffectedRulesMutation: DeleteUserAffectedRulesDocument;
	private orderUserAffectedRulesMutation: OrderUserAffectedRulesDocument;

	constructor(private injector: Injector) {
		this.getUserAffectedRulesQuery = this.injector.get(GetUserAffectedRulesDocument);
		this.firstUserAffectedRulesQuery = this.injector.get(FirstUserAffectedRulesDocument);
		this.countUserAffectedRulesQuery = this.injector.get(CountUserAffectedRulesDocument);
		this.findUserAffectedRulesQuery = this.injector.get(FindUserAffectedRulesDocument);
		this.searchUserAffectedRulesQuery = this.injector.get(SearchUserAffectedRulesDocument);
		this.exportSchemaUserAffectedRulesQuery = this.injector.get(ExportSchemaUserAffectedRulesDocument);
		this.exportDataUserAffectedRulesQuery = this.injector.get(ExportDataUserAffectedRulesDocument);
		this.customQueryUserAffectedRulesQuery = this.injector.get(CustomQueryUserAffectedRulesDocument);
		this.customQueryIdUserAffectedRulesQuery = this.injector.get(CustomQueryIdUserAffectedRulesDocument);
		this.usedUserAffectedRulesQuery = this.injector.get(UsedUserAffectedRulesDocument);
		this.existUserAffectedRulesQuery = this.injector.get(ExistUserAffectedRulesDocument);
		this.insertUserAffectedRulesMutation = this.injector.get(InsertUserAffectedRulesDocument);
		this.updateUserAffectedRulesMutation = this.injector.get(UpdateUserAffectedRulesDocument);
		this.importDataUserAffectedRulesMutation = this.injector.get(ImportDataUserAffectedRulesDocument);
		this.updateManyUserAffectedRulesMutation = this.injector.get(UpdateManyUserAffectedRulesDocument);
		this.deleteUserAffectedRulesMutation = this.injector.get(DeleteUserAffectedRulesDocument);
		this.orderUserAffectedRulesMutation = this.injector.get(OrderUserAffectedRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAffectedRule> {
    
    		let variables: GetUserAffectedRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.get));
            }
            else{
                let result:ServiceSingleResultOfUserAffectedRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserAffectedRule,
		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAffectedRule> {
    
    		let variables: FirstUserAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUserAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserAffectedRule,
		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAffectedRule> {
    
    		let variables: FindUserAffectedRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUserAffectedRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAffectedRule> {
    
    		let variables: SearchUserAffectedRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUserAffectedRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUserAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUserAffectedRule,
		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAffectedRule> {
    
    		let variables: CustomQueryUserAffectedRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUserAffectedRule,
		@Args('filter?') filter?: FilterOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAffectedRule> {
    
    		let variables: CustomQueryIdUserAffectedRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUserAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUserAffectedRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUserAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: UserAffectedRuleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAffectedRule> {
    
    		let variables: InsertUserAffectedRulesBaseVariables = {
    			entity: entity
    		}
    				return this.insertUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAffectedRule> {
    
    		let variables: UpdateUserAffectedRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataUserAffectedRulesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyUserAffectedRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteUserAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderUserAffectedRulesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderUserAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userAffectedRules.order));
    	}
    
}