import { RequestBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField } from '../helpers';

import { RequestCoreService } from './request.service';

@Injectable({ providedIn: 'root' })
@Authorize('request-help-desk-user')
export class RequestHelpDeskCoreService extends RequestCoreService {
  @InjectArgs
  public getCountElement(@Args('type') type: string) {
    switch (type) {
      case 'new':
        return this.findByUser([GqlField.create('totalCount')], 'new');
      case 'in-progress':
        return this.findByUser([GqlField.create('totalCount')], 'in-progress');
      case 'closed':
        return this.findByUser([GqlField.create('totalCount')], 'closed');
      case 'open':
        return this.findByUser([GqlField.create('totalCount')], 'open');

      case 'my-service':
        return this.findByCurrentSubordinate([GqlField.create('totalCount')]);
    }
  }
}
