
import { TicketAffectedRuleBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-ticket-affected-rule')
export class TicketAffectedRuleCoreService extends TicketAffectedRuleBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}