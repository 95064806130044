import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByHostsBaseVariables, FirstScanByHostsBaseVariables, CountScanByHostsBaseVariables, FindScanByHostsBaseVariables, SearchScanByHostsBaseVariables, ExportSchemaScanByHostsBaseVariables, ExportDataScanByHostsBaseVariables, CustomQueryScanByHostsBaseVariables, CustomQueryIdScanByHostsBaseVariables, UsedScanByHostsBaseVariables, ExistScanByHostsBaseVariables, FindRecyclesScanByHostsBaseVariables, CountRecyclesScanByHostsBaseVariables, ReadOnlyScanByHostsBaseVariables, FindArchivedScanByHostsBaseVariables, CountAllScanByHostsBaseVariables, FindDynamicPropertyFieldsScanByHostsBaseVariables, FindUnassociatedNetworkCredentialsScanByHostsBaseVariables, FindUnassociatedScanExcludesScanByHostsBaseVariables, FindUnassociatedScanDataFilesScanByHostsBaseVariables, FindUnassociatedScanRegistryKeysScanByHostsBaseVariables, FindUnassociatedScanImportOptionsScanByHostsBaseVariables, FindUnassociatedUsersScanByHostsBaseVariables, FindUnassociatedAssetsScanByHostsBaseVariables, FindUnassociatedAlertsScanByHostsBaseVariables, FindUnassociatedInventoryHistoriesScanByHostsBaseVariables, FindUnassociatedLdapHistoriesScanByHostsBaseVariables, InsertScanByHostsBaseVariables, UpdateScanByHostsBaseVariables, ImportDataScanByHostsBaseVariables, UpdateManyScanByHostsBaseVariables, DeleteScanByHostsBaseVariables, RestoreScanByHostsBaseVariables, RecycleScanByHostsBaseVariables, RestoreArchivedScanByHostsBaseVariables, ArchivedScanByHostsBaseVariables, AddFileDynamicFieldScanByHostsBaseVariables, RemoveFileDynamicFieldScanByHostsBaseVariables } from '../gqls'
import { GetScanByHostsDocument, FirstScanByHostsDocument, CountScanByHostsDocument, FindScanByHostsDocument, SearchScanByHostsDocument, ExportSchemaScanByHostsDocument, ExportDataScanByHostsDocument, CustomQueryScanByHostsDocument, CustomQueryIdScanByHostsDocument, UsedScanByHostsDocument, ExistScanByHostsDocument, FindRecyclesScanByHostsDocument, CountRecyclesScanByHostsDocument, ReadOnlyScanByHostsDocument, FindArchivedScanByHostsDocument, CountAllScanByHostsDocument, FindDynamicPropertyFieldsScanByHostsDocument, FindUnassociatedNetworkCredentialsScanByHostsDocument, FindUnassociatedScanExcludesScanByHostsDocument, FindUnassociatedScanDataFilesScanByHostsDocument, FindUnassociatedScanRegistryKeysScanByHostsDocument, FindUnassociatedScanImportOptionsScanByHostsDocument, FindUnassociatedUsersScanByHostsDocument, FindUnassociatedAssetsScanByHostsDocument, FindUnassociatedAlertsScanByHostsDocument, FindUnassociatedInventoryHistoriesScanByHostsDocument, FindUnassociatedLdapHistoriesScanByHostsDocument, InsertScanByHostsDocument, UpdateScanByHostsDocument, ImportDataScanByHostsDocument, UpdateManyScanByHostsDocument, DeleteScanByHostsDocument, RestoreScanByHostsDocument, RecycleScanByHostsDocument, RestoreArchivedScanByHostsDocument, ArchivedScanByHostsDocument, AddFileDynamicFieldScanByHostsDocument, RemoveFileDynamicFieldScanByHostsDocument } from '../gqls'
import { ServiceSingleResultOfScanByHost, QueryContextOfScanByHost, FilterOfScanByHost, ServiceSingleResultOfInt64, ServiceListResultOfScanByHost, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanByHost, QueryContextOfNetworkCredential, FilterOfNetworkCredential, ServiceListResultOfNetworkCredential, QueryContextOfScanExclude, FilterOfScanExclude, ServiceListResultOfScanExclude, QueryContextOfScanDataFile, FilterOfScanDataFile, ServiceListResultOfScanDataFile, QueryContextOfScanRegistryKey, FilterOfScanRegistryKey, ServiceListResultOfScanRegistryKey, QueryContextOfScanImportOption, FilterOfScanImportOption, ServiceListResultOfScanImportOption, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfLdapHistory, FilterOfLdapHistory, ServiceListResultOfLdapHistory, ScanByHostInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByHostBaseService {
    
public modelName = 'scanByHost';
public modelPluralName = 'scanByHosts';

	private getScanByHostsQuery: GetScanByHostsDocument;
	private firstScanByHostsQuery: FirstScanByHostsDocument;
	private countScanByHostsQuery: CountScanByHostsDocument;
	private findScanByHostsQuery: FindScanByHostsDocument;
	private searchScanByHostsQuery: SearchScanByHostsDocument;
	private exportSchemaScanByHostsQuery: ExportSchemaScanByHostsDocument;
	private exportDataScanByHostsQuery: ExportDataScanByHostsDocument;
	private customQueryScanByHostsQuery: CustomQueryScanByHostsDocument;
	private customQueryIdScanByHostsQuery: CustomQueryIdScanByHostsDocument;
	private usedScanByHostsQuery: UsedScanByHostsDocument;
	private existScanByHostsQuery: ExistScanByHostsDocument;
	private findRecyclesScanByHostsQuery: FindRecyclesScanByHostsDocument;
	private countRecyclesScanByHostsQuery: CountRecyclesScanByHostsDocument;
	private readOnlyScanByHostsQuery: ReadOnlyScanByHostsDocument;
	private findArchivedScanByHostsQuery: FindArchivedScanByHostsDocument;
	private countAllScanByHostsQuery: CountAllScanByHostsDocument;
	private findDynamicPropertyFieldsScanByHostsQuery: FindDynamicPropertyFieldsScanByHostsDocument;
	private findUnassociatedNetworkCredentialsScanByHostsQuery: FindUnassociatedNetworkCredentialsScanByHostsDocument;
	private findUnassociatedScanExcludesScanByHostsQuery: FindUnassociatedScanExcludesScanByHostsDocument;
	private findUnassociatedScanDataFilesScanByHostsQuery: FindUnassociatedScanDataFilesScanByHostsDocument;
	private findUnassociatedScanRegistryKeysScanByHostsQuery: FindUnassociatedScanRegistryKeysScanByHostsDocument;
	private findUnassociatedScanImportOptionsScanByHostsQuery: FindUnassociatedScanImportOptionsScanByHostsDocument;
	private findUnassociatedUsersScanByHostsQuery: FindUnassociatedUsersScanByHostsDocument;
	private findUnassociatedAssetsScanByHostsQuery: FindUnassociatedAssetsScanByHostsDocument;
	private findUnassociatedAlertsScanByHostsQuery: FindUnassociatedAlertsScanByHostsDocument;
	private findUnassociatedInventoryHistoriesScanByHostsQuery: FindUnassociatedInventoryHistoriesScanByHostsDocument;
	private findUnassociatedLdapHistoriesScanByHostsQuery: FindUnassociatedLdapHistoriesScanByHostsDocument;
	private insertScanByHostsMutation: InsertScanByHostsDocument;
	private updateScanByHostsMutation: UpdateScanByHostsDocument;
	private importDataScanByHostsMutation: ImportDataScanByHostsDocument;
	private updateManyScanByHostsMutation: UpdateManyScanByHostsDocument;
	private deleteScanByHostsMutation: DeleteScanByHostsDocument;
	private restoreScanByHostsMutation: RestoreScanByHostsDocument;
	private recycleScanByHostsMutation: RecycleScanByHostsDocument;
	private restoreArchivedScanByHostsMutation: RestoreArchivedScanByHostsDocument;
	private archivedScanByHostsMutation: ArchivedScanByHostsDocument;
	private addFileDynamicFieldScanByHostsMutation: AddFileDynamicFieldScanByHostsDocument;
	private removeFileDynamicFieldScanByHostsMutation: RemoveFileDynamicFieldScanByHostsDocument;

	constructor(private injector: Injector) {
		this.getScanByHostsQuery = this.injector.get(GetScanByHostsDocument);
		this.firstScanByHostsQuery = this.injector.get(FirstScanByHostsDocument);
		this.countScanByHostsQuery = this.injector.get(CountScanByHostsDocument);
		this.findScanByHostsQuery = this.injector.get(FindScanByHostsDocument);
		this.searchScanByHostsQuery = this.injector.get(SearchScanByHostsDocument);
		this.exportSchemaScanByHostsQuery = this.injector.get(ExportSchemaScanByHostsDocument);
		this.exportDataScanByHostsQuery = this.injector.get(ExportDataScanByHostsDocument);
		this.customQueryScanByHostsQuery = this.injector.get(CustomQueryScanByHostsDocument);
		this.customQueryIdScanByHostsQuery = this.injector.get(CustomQueryIdScanByHostsDocument);
		this.usedScanByHostsQuery = this.injector.get(UsedScanByHostsDocument);
		this.existScanByHostsQuery = this.injector.get(ExistScanByHostsDocument);
		this.findRecyclesScanByHostsQuery = this.injector.get(FindRecyclesScanByHostsDocument);
		this.countRecyclesScanByHostsQuery = this.injector.get(CountRecyclesScanByHostsDocument);
		this.readOnlyScanByHostsQuery = this.injector.get(ReadOnlyScanByHostsDocument);
		this.findArchivedScanByHostsQuery = this.injector.get(FindArchivedScanByHostsDocument);
		this.countAllScanByHostsQuery = this.injector.get(CountAllScanByHostsDocument);
		this.findDynamicPropertyFieldsScanByHostsQuery = this.injector.get(FindDynamicPropertyFieldsScanByHostsDocument);
		this.findUnassociatedNetworkCredentialsScanByHostsQuery = this.injector.get(FindUnassociatedNetworkCredentialsScanByHostsDocument);
		this.findUnassociatedScanExcludesScanByHostsQuery = this.injector.get(FindUnassociatedScanExcludesScanByHostsDocument);
		this.findUnassociatedScanDataFilesScanByHostsQuery = this.injector.get(FindUnassociatedScanDataFilesScanByHostsDocument);
		this.findUnassociatedScanRegistryKeysScanByHostsQuery = this.injector.get(FindUnassociatedScanRegistryKeysScanByHostsDocument);
		this.findUnassociatedScanImportOptionsScanByHostsQuery = this.injector.get(FindUnassociatedScanImportOptionsScanByHostsDocument);
		this.findUnassociatedUsersScanByHostsQuery = this.injector.get(FindUnassociatedUsersScanByHostsDocument);
		this.findUnassociatedAssetsScanByHostsQuery = this.injector.get(FindUnassociatedAssetsScanByHostsDocument);
		this.findUnassociatedAlertsScanByHostsQuery = this.injector.get(FindUnassociatedAlertsScanByHostsDocument);
		this.findUnassociatedInventoryHistoriesScanByHostsQuery = this.injector.get(FindUnassociatedInventoryHistoriesScanByHostsDocument);
		this.findUnassociatedLdapHistoriesScanByHostsQuery = this.injector.get(FindUnassociatedLdapHistoriesScanByHostsDocument);
		this.insertScanByHostsMutation = this.injector.get(InsertScanByHostsDocument);
		this.updateScanByHostsMutation = this.injector.get(UpdateScanByHostsDocument);
		this.importDataScanByHostsMutation = this.injector.get(ImportDataScanByHostsDocument);
		this.updateManyScanByHostsMutation = this.injector.get(UpdateManyScanByHostsDocument);
		this.deleteScanByHostsMutation = this.injector.get(DeleteScanByHostsDocument);
		this.restoreScanByHostsMutation = this.injector.get(RestoreScanByHostsDocument);
		this.recycleScanByHostsMutation = this.injector.get(RecycleScanByHostsDocument);
		this.restoreArchivedScanByHostsMutation = this.injector.get(RestoreArchivedScanByHostsDocument);
		this.archivedScanByHostsMutation = this.injector.get(ArchivedScanByHostsDocument);
		this.addFileDynamicFieldScanByHostsMutation = this.injector.get(AddFileDynamicFieldScanByHostsDocument);
		this.removeFileDynamicFieldScanByHostsMutation = this.injector.get(RemoveFileDynamicFieldScanByHostsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByHost> {
    
    		let variables: GetScanByHostsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.get));
            }
            else{
                let result:ServiceSingleResultOfScanByHost={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByHost> {
    
    		let variables: FirstScanByHostsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByHostsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: FindScanByHostsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: SearchScanByHostsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByHostsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByHostsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: CustomQueryScanByHostsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: CustomQueryIdScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByHostsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: FindRecyclesScanByHostsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesScanByHostsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyScanByHostsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByHost,
		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByHost> {
    
    		let variables: FindArchivedScanByHostsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllScanByHostsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsScanByHostsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedNetworkCredentials(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfNetworkCredential> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('networkCredentials', fields, null, [
			GqlSubFieldArg.create('filterOfNetworkCredentials', 'filter'),
			GqlSubFieldArg.create('optionsOfNetworkCredentials', 'options'),
		]),
		])
		extendedVariables['filterOfNetworkCredentials'] = filter;
		extendedVariables['optionsOfNetworkCredentials'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.networkCredentials));
            }
            else{
                let result: ServiceListResultOfNetworkCredential = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedNetworkCredentials(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindUnassociatedNetworkCredentialsScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedNetworkCredentialsScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedNetworkCredentials));
    	}

	@InjectArgs
	public findAssociatedScanExcludes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanExclude> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanExcludes', fields, null, [
			GqlSubFieldArg.create('filterOfScanExcludes', 'filter'),
			GqlSubFieldArg.create('optionsOfScanExcludes', 'options'),
		]),
		])
		extendedVariables['filterOfScanExcludes'] = filter;
		extendedVariables['optionsOfScanExcludes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanExcludes));
            }
            else{
                let result: ServiceListResultOfScanExclude = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanExcludes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: FindUnassociatedScanExcludesScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanExcludesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedScanExcludes));
    	}

	@InjectArgs
	public findAssociatedScanDataFiles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanDataFile> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanDataFiles', fields, null, [
			GqlSubFieldArg.create('filterOfScanDataFiles', 'filter'),
			GqlSubFieldArg.create('optionsOfScanDataFiles', 'options'),
		]),
		])
		extendedVariables['filterOfScanDataFiles'] = filter;
		extendedVariables['optionsOfScanDataFiles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanDataFiles));
            }
            else{
                let result: ServiceListResultOfScanDataFile = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: FindUnassociatedScanDataFilesScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanDataFilesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedScanDataFiles));
    	}

	@InjectArgs
	public findAssociatedScanRegistryKeys(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanRegistryKey> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanRegistryKeys', fields, null, [
			GqlSubFieldArg.create('filterOfScanRegistryKeys', 'filter'),
			GqlSubFieldArg.create('optionsOfScanRegistryKeys', 'options'),
		]),
		])
		extendedVariables['filterOfScanRegistryKeys'] = filter;
		extendedVariables['optionsOfScanRegistryKeys'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanRegistryKeys));
            }
            else{
                let result: ServiceListResultOfScanRegistryKey = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanRegistryKeys(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanRegistryKey,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanRegistryKey,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanRegistryKey> {
    
    		let variables: FindUnassociatedScanRegistryKeysScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanRegistryKeysScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedScanRegistryKeys));
    	}

	@InjectArgs
	public findAssociatedScanImportOptions(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanImportOption> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanImportOptions', fields, null, [
			GqlSubFieldArg.create('filterOfScanImportOptions', 'filter'),
			GqlSubFieldArg.create('optionsOfScanImportOptions', 'options'),
		]),
		])
		extendedVariables['filterOfScanImportOptions'] = filter;
		extendedVariables['optionsOfScanImportOptions'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanImportOptions));
            }
            else{
                let result: ServiceListResultOfScanImportOption = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanImportOptions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanImportOption,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanImportOption,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanImportOption> {
    
    		let variables: FindUnassociatedScanImportOptionsScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanImportOptionsScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedScanImportOptions));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedUsers));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedLdapHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfLdapHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ldapHistories', fields, null, [
			GqlSubFieldArg.create('filterOfLdapHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfLdapHistories', 'options'),
		]),
		])
		extendedVariables['filterOfLdapHistories'] = filter;
		extendedVariables['optionsOfLdapHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ldapHistories));
            }
            else{
                let result: ServiceListResultOfLdapHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedLdapHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfLdapHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapHistory> {
    
    		let variables: FindUnassociatedLdapHistoriesScanByHostsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedLdapHistoriesScanByHostsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.findUnassociatedLdapHistories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByHostInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByHost> {
    
    		let variables: InsertScanByHostsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByHost> {
    
    		let variables: UpdateScanByHostsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByHostsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByHost,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByHostsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedScanByHostsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldScanByHostsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldScanByHostsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldScanByHostsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByHosts.removeFileDynamicField));
    	}
    
}