<div *ngIf="message != undefined" class="filter">
  <span>{{ message }}</span>
</div>

<div class="left">
  <dx-list
    [height]="'100%'"
    [dataSource]="source"
    itemTemplate="data"
    selectionMode="single"
    (onSelectionChanged)="listSelectionChanged($event)"
    (onInitialized)="onInitialized($event)"
  >
    <dxo-scrolling useNative="true" mode="standard"> </dxo-scrolling>
    <div
      *dxTemplate="let item of 'data'; let indexColumn = index"
      [ngClass]="indexColumn % 2 == 0 ? '' : 'cl-row-color'"
    >
      <div class="info">
        <span *ngIf="item?.system === false" style="font-weight: bold">{{
          item.author?.data?.name
        }}</span>
        <span *ngIf="item?.system === true" style="font-weight: bold">{{
          'System'
        }}</span>
        <span> {{ 'entities/tracking/updateMessage' | translate }} </span>
        <span class="property"> {{ getFirstLog(item) }}</span>
        <span *ngIf="item?.changes != undefined && item.changes.length > 1">
          {{ 'entities/tracking/oherUpdateMessage' | translate }}</span
        >
      </div>
      <div style="text-align: right">{{ formatDate(item?.date) }}</div>
    </div>
  </dx-list>
</div>
<div class="right">
  <dx-data-grid
    [height]="'100%'"
    [dataSource]="currentLog.changes"
    [showBorders]="false"
    [showColumnHeaders]="true"
    [showColumnLines]="false"
    dataRowTemplate="rowTemplateName"
  >
    <!-- <dxo-scrolling mode="infinite"></dxo-scrolling> -->

    <dxi-column
      width="auto"
      caption="{{ 'entities/tracking/entityPropertyName' | translate }}"
    ></dxi-column>
    <dxi-column
      caption="{{ 'entities/tracking/value' | translate }}"
    ></dxi-column>

    <tbody
      *dxTemplate="let change of 'rowTemplateName'"
      style="padding-top: 10px"
    >
      <tr>
        <td>
          <span style="padding: 5px; font-weight: bold">{{
            change.data.name
          }}</span>
        </td>
        <td>
          <div width="100" style="white-space: normal">
            <div
              *ngIf="change.data.newValue"
              [innerHtml]="getData(change.data.newValue, change.data.name)"
              class="newValue"
            ></div>

            <div *ngIf="change.data.newValue == null" class="newValue">
              <span class="noValue">{{
                'globals/noDataOrigin' | translate
              }}</span>
            </div>

            <div
              *ngIf="
                change.data.oldValue != '' &&
                change.data.oldValue != null &&
                change.data.oldValue !=
                  'System.Collections.Generic.List`1[Clarilog.One.Data.Models.WorkTimeConfigTime]'
              "
              [innerHtml]="getData(change.data.oldValue, change.data.name)"
              class="oldValue"
            ></div>
            <div *ngIf="change.data.oldValue == null" class="oldValue">
              {{ 'globals/noDataOrigin' | translate }}
            </div>
          </div>
        </td>
      </tr>
    </tbody>
  </dx-data-grid>
</div>
