import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketTaskModelsBaseVariables, FirstTicketTaskModelsBaseVariables, CountTicketTaskModelsBaseVariables, FindTicketTaskModelsBaseVariables, SearchTicketTaskModelsBaseVariables, ExportSchemaTicketTaskModelsBaseVariables, ExportDataTicketTaskModelsBaseVariables, CustomQueryTicketTaskModelsBaseVariables, CustomQueryIdTicketTaskModelsBaseVariables, FindUnassociatedSecurityGroupsTicketTaskModelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsBaseVariables, UsedTicketTaskModelsBaseVariables, FindByOperatorTeamTicketTaskModelsBaseVariables, FindByQualificationTicketTaskModelsBaseVariables, FindOperatorTicketTaskModelsBaseVariables, FindOperatorTeamTicketTaskModelsBaseVariables, ExistTicketTaskModelsBaseVariables, FindRecyclesTicketTaskModelsBaseVariables, CountRecyclesTicketTaskModelsBaseVariables, ReadOnlyTicketTaskModelsBaseVariables, FindArchivedTicketTaskModelsBaseVariables, CountAllTicketTaskModelsBaseVariables, FindDynamicPropertyFieldsTicketTaskModelsBaseVariables, InsertTicketTaskModelsBaseVariables, UpdateTicketTaskModelsBaseVariables, ImportDataTicketTaskModelsBaseVariables, UpdateManyTicketTaskModelsBaseVariables, DeleteTicketTaskModelsBaseVariables, AddSecurityGroupTicketTaskModelsBaseVariables, RemoveSecurityGroupTicketTaskModelsBaseVariables, AddOperatorTeamSecurityGroupTicketTaskModelsBaseVariables, RemoveOperatorTeamSecurityGroupTicketTaskModelsBaseVariables, RestoreTicketTaskModelsBaseVariables, RecycleTicketTaskModelsBaseVariables, RestoreArchivedTicketTaskModelsBaseVariables, ArchivedTicketTaskModelsBaseVariables, AddFileDynamicFieldTicketTaskModelsBaseVariables, RemoveFileDynamicFieldTicketTaskModelsBaseVariables } from '../gqls'
import { GetTicketTaskModelsDocument, FirstTicketTaskModelsDocument, CountTicketTaskModelsDocument, FindTicketTaskModelsDocument, SearchTicketTaskModelsDocument, ExportSchemaTicketTaskModelsDocument, ExportDataTicketTaskModelsDocument, CustomQueryTicketTaskModelsDocument, CustomQueryIdTicketTaskModelsDocument, FindUnassociatedSecurityGroupsTicketTaskModelsDocument, FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsDocument, UsedTicketTaskModelsDocument, FindByOperatorTeamTicketTaskModelsDocument, FindByQualificationTicketTaskModelsDocument, FindOperatorTicketTaskModelsDocument, FindOperatorTeamTicketTaskModelsDocument, ExistTicketTaskModelsDocument, FindRecyclesTicketTaskModelsDocument, CountRecyclesTicketTaskModelsDocument, ReadOnlyTicketTaskModelsDocument, FindArchivedTicketTaskModelsDocument, CountAllTicketTaskModelsDocument, FindDynamicPropertyFieldsTicketTaskModelsDocument, InsertTicketTaskModelsDocument, UpdateTicketTaskModelsDocument, ImportDataTicketTaskModelsDocument, UpdateManyTicketTaskModelsDocument, DeleteTicketTaskModelsDocument, AddSecurityGroupTicketTaskModelsDocument, RemoveSecurityGroupTicketTaskModelsDocument, AddOperatorTeamSecurityGroupTicketTaskModelsDocument, RemoveOperatorTeamSecurityGroupTicketTaskModelsDocument, RestoreTicketTaskModelsDocument, RecycleTicketTaskModelsDocument, RestoreArchivedTicketTaskModelsDocument, ArchivedTicketTaskModelsDocument, AddFileDynamicFieldTicketTaskModelsDocument, RemoveFileDynamicFieldTicketTaskModelsDocument } from '../gqls'
import { ServiceSingleResultOfTicketTaskModel, QueryContextOfTicketTaskModel, FilterOfTicketTaskModel, ServiceSingleResultOfInt64, ServiceListResultOfTicketTaskModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, QueryContextOfOperatorTeam, FilterOfOperatorTeam, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketTaskModel, TicketTaskModelInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketTaskModelBaseService {
    
public modelName = 'ticketTaskModel';
public modelPluralName = 'ticketTaskModels';

	private getTicketTaskModelsQuery: GetTicketTaskModelsDocument;
	private firstTicketTaskModelsQuery: FirstTicketTaskModelsDocument;
	private countTicketTaskModelsQuery: CountTicketTaskModelsDocument;
	private findTicketTaskModelsQuery: FindTicketTaskModelsDocument;
	private searchTicketTaskModelsQuery: SearchTicketTaskModelsDocument;
	private exportSchemaTicketTaskModelsQuery: ExportSchemaTicketTaskModelsDocument;
	private exportDataTicketTaskModelsQuery: ExportDataTicketTaskModelsDocument;
	private customQueryTicketTaskModelsQuery: CustomQueryTicketTaskModelsDocument;
	private customQueryIdTicketTaskModelsQuery: CustomQueryIdTicketTaskModelsDocument;
	private findUnassociatedSecurityGroupsTicketTaskModelsQuery: FindUnassociatedSecurityGroupsTicketTaskModelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsTicketTaskModelsQuery: FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsDocument;
	private usedTicketTaskModelsQuery: UsedTicketTaskModelsDocument;
	private findByOperatorTeamTicketTaskModelsQuery: FindByOperatorTeamTicketTaskModelsDocument;
	private findByQualificationTicketTaskModelsQuery: FindByQualificationTicketTaskModelsDocument;
	private findOperatorTicketTaskModelsQuery: FindOperatorTicketTaskModelsDocument;
	private findOperatorTeamTicketTaskModelsQuery: FindOperatorTeamTicketTaskModelsDocument;
	private existTicketTaskModelsQuery: ExistTicketTaskModelsDocument;
	private findRecyclesTicketTaskModelsQuery: FindRecyclesTicketTaskModelsDocument;
	private countRecyclesTicketTaskModelsQuery: CountRecyclesTicketTaskModelsDocument;
	private readOnlyTicketTaskModelsQuery: ReadOnlyTicketTaskModelsDocument;
	private findArchivedTicketTaskModelsQuery: FindArchivedTicketTaskModelsDocument;
	private countAllTicketTaskModelsQuery: CountAllTicketTaskModelsDocument;
	private findDynamicPropertyFieldsTicketTaskModelsQuery: FindDynamicPropertyFieldsTicketTaskModelsDocument;
	private insertTicketTaskModelsMutation: InsertTicketTaskModelsDocument;
	private updateTicketTaskModelsMutation: UpdateTicketTaskModelsDocument;
	private importDataTicketTaskModelsMutation: ImportDataTicketTaskModelsDocument;
	private updateManyTicketTaskModelsMutation: UpdateManyTicketTaskModelsDocument;
	private deleteTicketTaskModelsMutation: DeleteTicketTaskModelsDocument;
	private addSecurityGroupTicketTaskModelsMutation: AddSecurityGroupTicketTaskModelsDocument;
	private removeSecurityGroupTicketTaskModelsMutation: RemoveSecurityGroupTicketTaskModelsDocument;
	private addOperatorTeamSecurityGroupTicketTaskModelsMutation: AddOperatorTeamSecurityGroupTicketTaskModelsDocument;
	private removeOperatorTeamSecurityGroupTicketTaskModelsMutation: RemoveOperatorTeamSecurityGroupTicketTaskModelsDocument;
	private restoreTicketTaskModelsMutation: RestoreTicketTaskModelsDocument;
	private recycleTicketTaskModelsMutation: RecycleTicketTaskModelsDocument;
	private restoreArchivedTicketTaskModelsMutation: RestoreArchivedTicketTaskModelsDocument;
	private archivedTicketTaskModelsMutation: ArchivedTicketTaskModelsDocument;
	private addFileDynamicFieldTicketTaskModelsMutation: AddFileDynamicFieldTicketTaskModelsDocument;
	private removeFileDynamicFieldTicketTaskModelsMutation: RemoveFileDynamicFieldTicketTaskModelsDocument;

	constructor(private injector: Injector) {
		this.getTicketTaskModelsQuery = this.injector.get(GetTicketTaskModelsDocument);
		this.firstTicketTaskModelsQuery = this.injector.get(FirstTicketTaskModelsDocument);
		this.countTicketTaskModelsQuery = this.injector.get(CountTicketTaskModelsDocument);
		this.findTicketTaskModelsQuery = this.injector.get(FindTicketTaskModelsDocument);
		this.searchTicketTaskModelsQuery = this.injector.get(SearchTicketTaskModelsDocument);
		this.exportSchemaTicketTaskModelsQuery = this.injector.get(ExportSchemaTicketTaskModelsDocument);
		this.exportDataTicketTaskModelsQuery = this.injector.get(ExportDataTicketTaskModelsDocument);
		this.customQueryTicketTaskModelsQuery = this.injector.get(CustomQueryTicketTaskModelsDocument);
		this.customQueryIdTicketTaskModelsQuery = this.injector.get(CustomQueryIdTicketTaskModelsDocument);
		this.findUnassociatedSecurityGroupsTicketTaskModelsQuery = this.injector.get(FindUnassociatedSecurityGroupsTicketTaskModelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsTicketTaskModelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsDocument);
		this.usedTicketTaskModelsQuery = this.injector.get(UsedTicketTaskModelsDocument);
		this.findByOperatorTeamTicketTaskModelsQuery = this.injector.get(FindByOperatorTeamTicketTaskModelsDocument);
		this.findByQualificationTicketTaskModelsQuery = this.injector.get(FindByQualificationTicketTaskModelsDocument);
		this.findOperatorTicketTaskModelsQuery = this.injector.get(FindOperatorTicketTaskModelsDocument);
		this.findOperatorTeamTicketTaskModelsQuery = this.injector.get(FindOperatorTeamTicketTaskModelsDocument);
		this.existTicketTaskModelsQuery = this.injector.get(ExistTicketTaskModelsDocument);
		this.findRecyclesTicketTaskModelsQuery = this.injector.get(FindRecyclesTicketTaskModelsDocument);
		this.countRecyclesTicketTaskModelsQuery = this.injector.get(CountRecyclesTicketTaskModelsDocument);
		this.readOnlyTicketTaskModelsQuery = this.injector.get(ReadOnlyTicketTaskModelsDocument);
		this.findArchivedTicketTaskModelsQuery = this.injector.get(FindArchivedTicketTaskModelsDocument);
		this.countAllTicketTaskModelsQuery = this.injector.get(CountAllTicketTaskModelsDocument);
		this.findDynamicPropertyFieldsTicketTaskModelsQuery = this.injector.get(FindDynamicPropertyFieldsTicketTaskModelsDocument);
		this.insertTicketTaskModelsMutation = this.injector.get(InsertTicketTaskModelsDocument);
		this.updateTicketTaskModelsMutation = this.injector.get(UpdateTicketTaskModelsDocument);
		this.importDataTicketTaskModelsMutation = this.injector.get(ImportDataTicketTaskModelsDocument);
		this.updateManyTicketTaskModelsMutation = this.injector.get(UpdateManyTicketTaskModelsDocument);
		this.deleteTicketTaskModelsMutation = this.injector.get(DeleteTicketTaskModelsDocument);
		this.addSecurityGroupTicketTaskModelsMutation = this.injector.get(AddSecurityGroupTicketTaskModelsDocument);
		this.removeSecurityGroupTicketTaskModelsMutation = this.injector.get(RemoveSecurityGroupTicketTaskModelsDocument);
		this.addOperatorTeamSecurityGroupTicketTaskModelsMutation = this.injector.get(AddOperatorTeamSecurityGroupTicketTaskModelsDocument);
		this.removeOperatorTeamSecurityGroupTicketTaskModelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupTicketTaskModelsDocument);
		this.restoreTicketTaskModelsMutation = this.injector.get(RestoreTicketTaskModelsDocument);
		this.recycleTicketTaskModelsMutation = this.injector.get(RecycleTicketTaskModelsDocument);
		this.restoreArchivedTicketTaskModelsMutation = this.injector.get(RestoreArchivedTicketTaskModelsDocument);
		this.archivedTicketTaskModelsMutation = this.injector.get(ArchivedTicketTaskModelsDocument);
		this.addFileDynamicFieldTicketTaskModelsMutation = this.injector.get(AddFileDynamicFieldTicketTaskModelsDocument);
		this.removeFileDynamicFieldTicketTaskModelsMutation = this.injector.get(RemoveFileDynamicFieldTicketTaskModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTaskModel> {
    
    		let variables: GetTicketTaskModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.get));
            }
            else{
                let result:ServiceSingleResultOfTicketTaskModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTaskModel> {
    
    		let variables: FirstTicketTaskModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketTaskModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindTicketTaskModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: SearchTicketTaskModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketTaskModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketTaskModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: CustomQueryTicketTaskModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: CustomQueryIdTicketTaskModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsTicketTaskModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsTicketTaskModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByOperatorTeam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindByOperatorTeamTicketTaskModelsBaseVariables = {
    			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findByOperatorTeamTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findByOperatorTeam));
    	}

    	/**  */
    	@InjectArgs
    	public findByQualification(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindByQualificationTicketTaskModelsBaseVariables = {
    			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findByQualificationTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findByQualification));
    	}

    	/**  */
    	@InjectArgs
    	public findOperator(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('operatorTeamId?') operatorTeamId?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindOperatorTicketTaskModelsBaseVariables = {
    			operatorTeamId: operatorTeamId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findOperator));
    	}

    	/**  */
    	@InjectArgs
    	public findOperatorTeam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOperatorTeam,
		@Args('operatorId?') operatorId?: string,
		@Args('filter?') filter?: FilterOfOperatorTeam,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindOperatorTeamTicketTaskModelsBaseVariables = {
    			operatorId: operatorId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorTeamTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findOperatorTeam));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketTaskModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindRecyclesTicketTaskModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTicketTaskModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTicketTaskModelsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTaskModel,
		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTaskModel> {
    
    		let variables: FindArchivedTicketTaskModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTicketTaskModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTicketTaskModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTicketTaskModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketTaskModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTaskModel> {
    
    		let variables: InsertTicketTaskModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTaskModel> {
    
    		let variables: UpdateTicketTaskModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketTaskModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketTaskModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketTaskModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupTicketTaskModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupTicketTaskModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupTicketTaskModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupTicketTaskModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.removeOperatorTeamSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTicketTaskModelsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTicketTaskModelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTicketTaskModelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTicketTaskModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTaskModels.removeFileDynamicField));
    	}
    
}