/* tslint:disable */
/**
 * This file was automatically generated by json-schema-to-typescript.
 * DO NOT MODIFY IT BY HAND. Instead, modify the source JSONSchema file,
 * and run json-schema-to-typescript to regenerate this file.
 */

export interface WorkItemConfiguration {
  /**
   * Obtient ou définit le numéro de version du model au format 4 chiffres (Par exemple : 1.0.0.0).
   */
  version?: string;
  /**
   * Obtient ou définit le titre de l'écran.
   */
  title?: string;
  /**
   * Obtient ou définit la configuration du form.
   */
  form?: Form;
  /**
   * Obtient ou définit la configuration des écrans listes.
   */
  grid?: Grid;
}
/**
 * Représente la configuration du formulaire.
 */
export interface Form {
  /**
   * Obtient ou définit la configuration du layout.
   */
  layout: FormLayout;
}
/**
 * Représente la configuration du layout.
 */
export interface FormLayout {
  defaultFieldName?: string;
  /**
   * Obtient ou définit la source pour utiliser un badge sur un Tab. Utilisez la propriété 'ItemKey' du tab pour faire la liaison.
   */
  badgeSource?: StaticSource | string;
  /**
   * Obtient ou définit la méthode de redirection cas de mauvais routing (exemple entre incident et request).
   */
  redirect?: StaticSource | string;
  /**
   * Obtient ou définit des propriétés supplémentaires devant être chargée.
   */
  dependProperties?: string[];
  /**
   * Obtient ou définit si le titre du formulaire est un champ traduisible
   */
  translatableTitle?: boolean;
  /**
   * Obtient ou définit si le titre du formulaire est un champ traduisible
   */
  hasTranslatedModule?: boolean;
  /**
   * Obtient ou définit si le formulaire est en lecture seule
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si le formulaire vérifie son mode de lecture seule.
   */
  checkReadOnly?: boolean;
  /**
   *  Obtient ou définit si on affiche le message de lecture seule
   */
  displayReadOnlyMsg?: boolean;
  /**
   * Obtient ou définit la fonction des informations de création.
   */
  attributesInfo?: string;
  /**
   * Obtient ou définit les controls systèmes.
   */
  systemControls?: SystemControls;
  /**
   * Obtient ou définit si le methode de service pour obtenir les propriété dynamique.
   */
  dynamicProperty?: DynamicPropertyOptions;
  /**
   * Obtient ou définit les configurations des pages.
   */
  pages: (PageSection | PageControl | PageGroup | PageTab)[];
}
/**
 * Obtient ou définit une source de données static
 */
export interface StaticSource {
  /**
   * Obtient ou définit une donnée
   */
  items?: ItemSource[];
}
export interface ItemSource {
  [k: string]: unknown;
}
/**
 * Représente la configuration du layout.
 */
export interface SystemControls {
  /**
   * Obtient ou définit les contrôles systèmes.
   */
  controls?: Control[];
}
/**
 * Représente le control du formulaire (Par exemple, un input, une liste, une checkbox, ...).
 */
export interface Control {
  /**
   * Obtient ou définit le nom unique du control.
   */
  name?: string;
  /**
   * Obtient ou définit le libellé du control.
   */
  label?: string;
  /**
   * Obtient ou définit si le champs est envoyé coté serveur
   */
  managed?: boolean;
  /**
   * Obtient ou définit si le champs est de type dynamique (dynamicProperty)
   */
  isDynamicProperty?: boolean;
  /**
   * Obtient ou définit si le champs est passé en disabled quand le formulaire est en readOnly
   */
  disableReadOnlyMode?: boolean;
  /**
   * Obtient ou définit le nom du champs en Iref
   */
  iRefExpr?: string;
  /**
   * Obtient ou définit si le champ doit etre envoyé coté serveur meme si il est read only
   */
  forceSendValue?: boolean;
  /**
   * Obtient ou définit le texte explicatif.
   */
  hint?:
    | string
    | {
        [k: string]: unknown;
      };
  /**
   * Obtient ou définit la visibilitée texte explicatif.
   */
  hintVisibility?: string;
  /**
   * Obtient ou définit le texte affiché lorsque la zone d'édition est vide.
   */
  emptyText?: string;
  /**
   * Obtient ou definit les champ nécéssaire a l'affichage du template (ignore le champ field)
   */
  neededFields?: unknown[];
  /**
   * Obtient ou définit le type de control.
   */
  type?:
    | 'TextAreaComponent'
    | 'TextBoxComponent'
    | 'NumberBoxComponent'
    | 'DateTimeComponent'
    | 'CalendarComponent'
    | 'ColorBoxComponent'
    | 'CheckBoxComponent'
    | 'SwitchComponent'
    | 'HtmlEditorComponent'
    | 'HtmlEditorSimpleComponent'
    | 'SelectBoxComponent'
    | 'AutoCompleteBoxComponent'
    | 'TagBoxComponent'
    | 'DropDownBoxComponent'
    | 'LinkListComponent'
    | 'StockListComponent'
    | 'StockCreatedAssetListComponent'
    | 'CurrentInStockListComponent'
    | 'GridComponent'
    | 'PnpEntityListComponent'
    | 'LinkComponent'
    | 'SimpleListComponent'
    | 'SelectSimpleListComponent'
    | 'SchedulerComponent'
    | 'RadioButtonComponent'
    | 'PermissionsListComponent'
    | 'FilterBuilderComponent'
    | 'FileManagerComponent'
    | 'FileUploaderComponent'
    | 'FlatListComponent'
    | 'TagBoxEmailComponent'
    | 'MemoAreaComponent'
    | 'CommandAssetCategoryListComponent'
    | 'MatrixComponent'
    | 'WorkTimeComponent'
    | 'LicenseComponent'
    | 'PasswordComponent'
    | 'TrackingLogListComponent'
    | 'CoreSubFormLinkListComponent'
    | 'DayOffComponent'
    | 'CoreSubFormGanttComponent'
    | 'ColumnChooserComponent'
    | 'PropertyChangeComponent'
    | 'FieldChooserComponent'
    | 'TimeSpanComponent'
    | 'DesignerRuleListComponent'
    | 'LogicDiagramComponent'
    | 'CheckLinkListComponent'
    | 'MessageTicketComponent'
    | 'TicketEmailListComponent'
    | 'HtmlEditorTranslatedComponent'
    | 'HtmlEditorSimpleTranslatedComponent'
    | 'TranslateFieldComponent'
    | 'TagBoxCustomItemComponent'
    | 'PrintLinkListComponent'
    | 'VisibleSelectableEntityComponent'
    | 'VisibleSelectableLinkEntityComponent'
    | 'EditableListComponent'
    | 'DynamicLinkListComponent';
  /**
   * Obtient ou définit le template de control.
   */
  templateType?: 'PasswordComponent' | 'SimplePasswordComponent';
  /**
   * Obtient ou définit les validateurs.
   */
  validators?: Validator[];
  /**
   * Obtient ou définit la valeur par défaut affiché dans le control.
   */
  defaultValue?:
    | (string | boolean | number | number)[]
    | (string | boolean | number | number)[]
    | (string | boolean | number | number)[]
    | (string | boolean | number | number)[]
    | (string | boolean | number | number)[];
  /**
   * Obtient ou définit le nom de la propriété dont dépend celle-ci.
   */
  dependsOn?: DependsOn;
  /**
   * Obtient ou définit le nom de les option du designer
   */
  designerOptions?: DesignerOptions;
  /**
   * Obtient ou définit l'état de lecture du champ.
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si le champ doit être affiché dans le formulaire.
   */
  visible?: boolean;
  /**
   * Obtient ou définit si le champ est actif.
   */
  disabled?: boolean;
  /**
   * Obtient ou définit le champ dans le model.
   */
  fieldName?: string;
  /**
   * Permet de savoir si le dataSource doit etre chargée
   */
  autohrizeDataSource?: boolean;
  /**
   * Obtient ou définit les options.
   */
  options?:
    | CheckBoxOptions
    | TextAreaOptions
    | FilterBuilderOptions
    | FieldChooserOptions
    | DateTimeOptions
    | TextBoxOptions
    | NumberBoxOptions
    | SelectBoxOptions
    | AutoCompleteBoxOptions
    | CurrentInStockListOptions
    | TagBoxOptions
    | DropDownBoxOptions
    | LinkListOptions
    | GridOptions
    | LinkOptions
    | SimpleListOptions
    | SelectSimpleListOptions
    | SchedulerOptions
    | FileManagerOptions
    | FileUploaderOptions
    | StockCreatedAssetOptions
    | CurrentInStockListOptions
    | StockListOptions
    | HtmlEditorOptions
    | MemoAreaOptions
    | PasswordOptions
    | SubFormLinkList
    | SubFormGantt
    | TimespanOptions
    | LogicTemplateOptions
    | PropertyChangeOptions
    | TagBoxEmailOptions
    | DesignerRuleListOptions
    | MessageTicketOptions
    | TicketEmailListOptions
    | VisibleSelectableEntityOptions
    | VisibleSelectableLinkEntityListOptions
    | CheckLinkListOptions;
  [k: string]: unknown;
}
/**
 * Représente un validateur.
 */
export interface Validator {
  /**
   * Obtient ou définit le nom du validator angular
   */
  name?:
    | 'min'
    | 'max'
    | 'minLength'
    | 'maxLength'
    | 'required'
    | 'requiredTrue'
    | 'email'
    | 'pattern'
    | 'unique'
    | 'compare'
    | 'notNullOrEmpty'
    | 'ipAddress'
    | 'hierarchicalUnique'
    | 'oneOrMoreAsync'
    | 'customPattern'
    | 'requiredLicense'
    | 'requiredMatrix';
  /**
   * Obtient ou définit le du paramètre pour le validateur
   */
  parameterName?: string;
  /**
   * Obtient ou définit la valeur de parametre du validateur.
   */
  value?: (number | string | unknown[]) | CompareOptions;
  /**
   * Obtient ou définit le message à affichier
   */
  message?: string;
}
/**
 * Représente les options du compare.
 */
export interface CompareOptions {
  /**
   * Obtient ou définit le nom du champ cible.
   */
  fieldName?: string;
  /**
   * Obtient ou définit l'action du filtre
   */
  operator?:
    | 'Equal'
    | 'NotNull'
    | 'Null'
    | 'NotEqual'
    | 'GreaterThan'
    | 'GreaterThanOrEqual'
    | 'LessThan'
    | 'LessThanOrEqual';
  /**
   * Obtient ou définit un convertisseur pour la valeur
   */
  converter?: 'IpAddress' | 'DateTime';
}
/**
 * Définis la dépendance entre controles
 */
export interface DependsOn {
  /**
   * Obtient ou définit le nom du control à l'origine de la dépendance
   */
  controlName?: string;
  /**
   * Obtient ou définit l'effet qu'aura le changement de valeur de la dépendance
   */
  effect?: 'Filter' | 'Disable' | 'Visible' | 'Value' | 'ClearErrors';
  /**
   * Obtient ou définit la valeur à utilise si la dépendance est correcte
   */
  valueIf?: string | boolean | number | number | null;
  /**
   * Obtient ou définit la valeur à utilise si la dépendance est n'est correcte pas
   */
  valueElse?: string | boolean | number | number | null;
  /**
   * Obtient ou définit les options de l'effect de la dépendance
   */
  options?: DependsOnOptions;
}
/**
 * Obtient ou définit la dépendance entre controles
 */
export interface DependsOnOptions {
  /**
   * Obtient ou définit la proprieté sur lequelle le filtre est appliqué
   */
  propertyName?: string;
  /**
   * Obtient ou définit la valeur du test
   */
  value?: (string | boolean | number | number) | [string | boolean | number | number];
  /**
   * Obtient ou définit l'action du filtre
   */
  operator?:
    | 'Equal'
    | 'NotNull'
    | 'NotNullOrEmpty'
    | 'Null'
    | 'NotEqual'
    | 'GreaterThan'
    | 'GreaterThanOrEqual'
    | 'LessThan'
    | 'LessThanOrEqual'
    | 'Contains'
    | 'NotContains';
}
/**
 * Définis les options du designer
 */
export interface DesignerOptions {
  /**
   * Obtient ou définit la clef
   */
  fieldName?: string;
  /**
   * Obtient ou définit si le champs sera géré dans le designer
   */
  managed?: boolean;
  /**
   * Obtient ou définit le champs des options
   */
  onlyVisibility?: boolean;
  /**
   * Ajouté pour les formulaires d'opérateurs - Obtient ou définit si l'option 'éditable' est uniquement visible sur la page des règles à la création ou à l'édition.
   */
  editableOptionOnlyVisibleOnView?: 'New' | 'Edit';
  /**
   * Ajouté pour les formulaires d'opérateurs - Obtient ou définit si l'option 'obligatoire' est uniquement visible sur la page des règles à la création ou à l'édition.
   */
  requiredOptionOnlyVisibleOnView?: 'New' | 'Edit';
  /**
   * N'affichage pas l'option sur la création d'une règle sur les formulaire
   */
  notVisibleOnNew?: boolean;
  /**
   * N'affichage pas l'option sur l'édition d'une règle sur les formulaire
   */
  notVisibleOnEdit?: boolean;
  /**
   * Ignore la propriété quelque soit l'état du formulaire
   */
  ignore?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour l'obligatoire
   */
  editRequiredValue?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour l'obligatoire
   */
  newRequiredValue?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour l'édition
   */
  editEditValue?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour l'édition
   */
  newEditValue?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour la visibilite
   */
  editVisibleValue?: boolean;
  /**
   * Obtient ou définit la valeur par defaut pour l'édition pour la visibilite
   */
  newVisibleValue?: boolean;
}
/**
 * Représente les options d'un control de type CheckBoxComponent.
 */
export interface CheckBoxOptions {
  /**
   * Obtient ou définit si le text contient de l'html.
   */
  isHTML?: boolean;
  /**
   * Obtient ou définit le texte.
   */
  text?: string;
  /**
   * Obtient ou définit si les trois états sont utilisés.
   */
  useIndeterminateState?: boolean;
}
/**
 * Représente les options d'un control de type TextAreaComponent.
 */
export interface TextAreaOptions {
  /**
   * Obtient ou définit le masque de saisie.
   */
  mask?: string;
  /**
   * Obtient ou définit le caractère du masque.
   */
  maskChar?: string;
  /**
   * Obtient ou définit le message lorsque le texte saisi ne correspond pas au pattern spécifié.
   */
  maskInvalidMessage?: string;
  /**
   * Obtient ou définit la règles du masque personnalisé.
   */
  maskRules?:
    | string
    | {
        [k: string]: unknown;
      };
  /**
   * Obtient ou définit le nombre maximum de caractère.
   */
  maxLength?: number;
  /**
   * Obtient ou définit si le bouton de copie est affiché.
   */
  showCopyButton?: boolean;
  /**
   * Obtient ou définit si le bouton du lien est affiché.
   */
  showLinkButton?: boolean;
  /**
   * Obtient ou définit si le bouton d'email est affiché.
   */
  showMailToButton?: boolean;
  /**
   * Obtient ou définit si le bouton de reset est affiché.
   */
  showClearButton?: boolean;
  mode?: 'text' | 'password';
}
/**
 * Représente les options d'un control de type FilterBuilderComponent.
 */
export interface FilterBuilderOptions {
  /**
   * Définit le type sur lequel ce base le filter-builder
   */
  type?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit si la proprité 'A changé de valeur' est disponible
   */
  useChangeValue?: boolean;
  /**
   * Obtient ou définit la function retournant le type de chargement des fields (exemple : allFields).
   */
  useMethod?: StaticSource | string;
  /**
   * Obtient ou définit la function permettant de charger des filterOperation Custom.
   */
  customFilterOperations?: StaticSource | string;
}
/**
 * Représente les options d'un control de type FieldChooserComponent.
 */
export interface FieldChooserOptions {
  /**
   * Définit le type sur lequel ce base le field-chooser
   */
  type?: string;
  /**
   * Obtient ou définit la methode pour ajouter des champs en fonction du type.
   */
  addFields?: {
    [k: string]: unknown;
  };
  /**
   * Permet d'exclure des champs
   */
  useRemoveFieldsArray?: boolean;
}
/**
 * Représente les options d'un control de type DateTimeComponent et CalendarComponent.
 */
export interface DateTimeOptions {
  /**
   * Obtient ou définit le type de date.
   */
  type?: 'date' | 'time' | 'datetime';
  /**
   * Obtient ou définit l'interval entre les minutes.
   */
  interval?: number;
  /**
   * Obtient ou définit la date maximum.
   */
  max?: string | number;
  /**
   * Obtient ou définit la date minimum.
   */
  min?: string | number;
  /**
   * Obtient ou définit le format de date.
   */
  displayFormat?: string;
  /**
   * Obtient ou définit le format de sérialisation de la date.
   */
  dateSerializationFormat?: string;
  /**
   * Obtient ou définit si les valeurs personnalisée sont acceptée.
   */
  acceptCustomValue?: boolean;
}
/**
 * Représente les options d'un control de type TextBoxComponent.
 */
export interface TextBoxOptions {
  /**
   * Obtient ou définit le masque de saisie.
   */
  mask?: string;
  /**
   * Obtient ou définit le caractère du masque.
   */
  maskChar?: string;
  /**
   * Obtient ou définit le message lorsque le texte saisi ne correspond pas au pattern spécifié.
   */
  maskInvalidMessage?: string;
  /**
   * Obtient ou définit la règles du masque personnalisé.
   */
  maskRules?:
    | string
    | {
        [k: string]: unknown;
      };
  /**
   * Obtient ou définit le nombre maximum de caractère.
   */
  maxLength?: number;
  /**
   * Obtient ou définit si le bouton de copie est affiché.
   */
  showCopyButton?: boolean;
  /**
   * Obtient ou définit si le bouton du lien est affiché.
   */
  showLinkButton?: boolean;
  /**
   * Obtient ou définit si le bouton d'action est affiché.
   */
  showActionToButton?: boolean;
  /**
   * Obtient ou définit si l'image du bouton.
   */
  iconActionButton?: string;
  /**
   * Obtient ou définit une route spécifique de l'application.
   */
  routeUrl?: string;
  /**
   * Obtient ou définit si le bouton d'email est affiché.
   */
  showMailToButton?: boolean;
  /**
   * Obtient ou définit si le bouton de reset est affiché.
   */
  showClearButton?: boolean;
  mode?: 'text' | 'password';
  /**
   * Obtient ou définit si le bouton de remise par défaut peut être visible.
   */
  showDefaultSystem?: boolean;
  /**
   * Obtient ou définit si le champ est traduit
   */
  translatable?: boolean;
}
/**
 * Représente les options d'un control de type NumberBoxComponent.
 */
export interface NumberBoxOptions {
  /**
   * Obtient ou définit le minimum.
   */
  min?: number;
  /**
   * Obtient ou définit le maximum.
   */
  max?: number;
  /**
   * Obtient ou définit le format personnalisé.
   */
  format?: string;
  /**
   * Obtient ou définit si le boutton de reset est présent.
   */
  showClearButton?: boolean;
}
/**
 * Représente les options d'un control de type SelectBoxComponent.
 */
export interface SelectBoxOptions {
  /**
   * Obtient ou définit la données à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Format d'une ligne de la selectbox
   */
  template?: 'iconTemplate';
  /**
   * Obtient ou définit si le boutton de reset est présent.
   */
  showClearButton?: boolean;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit le mode de recherche.
   */
  searchMode?: 'contains' | 'startswith';
}
/**
 * Représente les options d'un control de type AutoCompleteBoxComponent.
 */
export interface AutoCompleteBoxOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit le mode de recherche.
   */
  searchMode?: 'contains' | 'startswith';
}
/**
 * Représente les options d'un control de type LinkList.
 */
export interface CurrentInStockListOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour l'export
   */
  exportSource?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Définit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Définit le noeud parent
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
}
/**
 * Représente les données des colonnes.
 */
export interface Column {
  /**
   * Obtient ou définit le nom de l'en-tête de la colonne.
   */
  label?: string;
  /**
   * Obtient ou définit le champ de la source de données à afficher.
   */
  field?: string;
  /**
   * Obtient ou definit les champ nécéssaire a l'affichage du template (ignore le champ field)
   */
  neededFields?: unknown[];
  /**
   * Obtient ou definit le nom des params a passer au champs cacluler ex entriesAmount assetmodel, appliqué sur le premier field
   */
  calculatedFieldArgNames?: unknown[];
  /**
   * Obtient ou definit la l'alignement de la colonne
   */
  alignment?: string;
  /**
   * Obtient ou définit si le gens est recupéré.
   */
  managed?: boolean;
  /**
   * Obtient ou définit l'index du groupe.
   */
  groupIndex?: number;
  /**
   * Obtient ou définit si la colonne est éditable.
   */
  allowEditing?: boolean;
  /**
   * Obtient ou définit si la colonne un champs traduit
   */
  translatable?: boolean;
  /**
   * Obtient ou définit si la colonne est fixée.
   */
  fixed?: boolean;
  /**
   * Obtient ou définit la position de la colonne fixée (left / right).
   */
  fixedPosition?: string;
  /**
   * Obtient ou définit si la colonne est affichée.
   */
  visible?: boolean;
  /**
   * Obtient ou définit si la colonne doit être également chargée comme iref.
   */
  hasIref?: boolean;
  /**
   * Obtient ou définit si la colonne est affichée dans le choix des colonne.
   */
  showInColumnChooser?: boolean;
  headerFilterLookup?: HeaderFilterLookup;
  /**
   * Obtient ou définit si la colonne doit affiché une info d'aide.
   */
  hintDisplay?: string;
  /**
   * Obtient ou définit le nom du template à afficher.
   */
  template?:
    | 'urlTemplate'
    | 'assetTemplate'
    | 'entityNameTemplate'
    | 'sizeTemplate'
    | 'sizeTemplateWithZero'
    | 'speedTemplate'
    | 'arrayTemplate'
    | 'arrayEnumTemplate'
    | 'buttonsTemplate'
    | 'percentTemplate'
    | 'milliSecondToTimeTemplate'
    | 'iconTemplate'
    | 'mailToTemplate'
    | 'callToTemplate'
    | 'hrefTemplate'
    | 'assetLinkTemplate'
    | 'isReadTemplate'
    | 'isReadNotificationTemplate'
    | 'notificationObjectClick'
    | 'mainPriorityColorTemplate'
    | 'favoriteTemplate'
    | 'logicalDiskTemplate'
    | 'interventionModelLinkTemplate'
    | 'taskModelLinkTemplate'
    | 'activitiesLinkTemplate'
    | 'operatorTeamLinkTemplate'
    | 'permissionMemberTemplate'
    | 'messageTicketTemplate'
    | 'linkMessageTicketTemplate'
    | 'displayWithoutHTMLTemplate'
    | 'noteTemplate'
    | 'ratingTemplate'
    | 'linkTicketTemplate'
    | 'acceptDateTemplate'
    | 'incidentDataTemplate'
    | 'incidentTemplate'
    | 'entityPropertyName'
    | 'alertIncidentTemplate'
    | 'alertRequestTemplate'
    | 'alertTaskTemplate'
    | 'ticketTypeColorTemplate'
    | 'ticketTaskTemplate'
    | 'taskTemplate'
    | 'timeSpanTemplate'
    | 'estimatedTimeSpanTemplate'
    | 'estimatedTimeMinutesTemplate'
    | 'alertTicketTaskTemplate'
    | 'alertticketTypeColorTemplate'
    | 'alertInterventionTicketTypeColorTemplate'
    | 'alertInterventionTicketTemplate'
    | 'alertInterventionTicketNumberTemplate'
    | 'linkAltertInterventionTicketTaskTemplate'
    | 'linkTicketTaskTemplate'
    | 'linkAlertContractTemplate'
    | 'linkAlertAssetTemplate'
    | 'displayAmountTemplate'
    | 'booleanTypeTemplate'
    | 'privilegeEntryTemplate'
    | 'loanAssetsTemplate'
    | 'locationCharterTemplate'
    | 'locationCharterRulesTemplate'
    | 'attachmentTicketLink'
    | 'ticketAttachmentName'
    | 'termsContractTemplate'
    | 'verifaliaClassificationTemplate'
    | 'userIdentityLinkTemplate';
  /**
   * Obtient ou définit le type ou format de base de la valeur.
   */
  format?:
    | 'Octect'
    | 'KiloOctet'
    | 'MegaHertz'
    | 'shortDateShortTime'
    | 'Disk'
    | 'shortDate'
    | 'percent'
    | 'fixedPoint'
    | 'shortTime'
    | 'roleType'
    | 'ticketType'
    | 'usageType';
  /**
   * Obtient ou définit le type ou format de base de la valeur.
   */
  dataType?: 'string' | 'number' | 'date' | 'boolean' | 'object' | 'datetime';
  /**
   * Obtient ou définit les lookups.
   */
  lookup?: Lookup;
  /**
   * Obtient ou définit le nom du template pour le groupe à afficher.
   */
  groupTemplate?: string;
  /**
   * Obtient ou définit l'ordre de trie d'une colonne.
   */
  sortIndex?: number;
  /**
   * Obtient ou définit le sens du trie.
   */
  sortOrder?: 'asc' | 'desc';
  /**
   * Obtient ou définit le filtre par défaut d'une colonne.
   */
  filterValue?: (string | boolean | number | number) | (string | boolean | number | number)[];
  /**
   * Obtient ou définit la taille minimum.
   */
  minWidth?: number;
  /**
   * Obtient oud définit une valeur indiquant si la colonne est redimensionnable.
   */
  allowResizing?: boolean;
  /**
   * Obtient ou définit l'opérateur à appliquer.
   */
  selectedFilterOperation?:
    | 'Equal'
    | 'NotEqual'
    | 'GreaterThan'
    | 'GreaterThanOrEqual'
    | 'LessThan'
    | 'LessThanOrEqual'
    | 'Between'
    | 'Contains'
    | 'EndsWith'
    | 'NotContains'
    | 'StartsWith';
  /**
   * Obtient ou définit l'expression régulière pour le validateur.
   */
  validator?: 'unique' | 'folderPath' | 'customMethod';
  /**
   * Obtient ou définit la méthode de service de validation.
   */
  validatorMethod?: string;
  /**
   * Obtient ou définit le text d'erreur du validator.
   */
  validatorErrorDisplay?: string;
  /**
   * Obtient ou définit si la colonne est triable.
   */
  allowSorting?: boolean;
  /**
   * Obtient ou définit si la colonne est filtrable.
   */
  allowFiltering?: boolean;
  /**
   * Obtient ou définit si la colonne est groupable.
   */
  allowGrouping?: boolean;
  /**
   * Obtient ou définit si le lien vers la fiche de l'objet
   */
  link?: boolean;
  /**
   * Obtient ou définit la colonne de réfèrence pour l'id du Lien
   */
  linkIdRef?: string;
  /**
   * Obtient ou définit si l'on export ou non la colonne
   */
  toExportData?: boolean;
  /**
   * Obtient ou définit le type Gql pour la query principe du ... onType { fieldName }
   */
  onType?: string;
  /**
   * Obtient ou définit le type pour le lien href
   */
  linkType?: string;
  /**
   * Obtient ou définit le params pour le lien href
   */
  linkParams?: string;
  /**
   * Obtient ou définit si l'on affiche le href
   */
  linkHref?: boolean;
  /**
   * Obtient ou définit si la colonne est exportable.
   */
  allowExporting?: boolean;
}
/**
 * Représente les propriété du lookup d'un filter.
 */
export interface HeaderFilterLookup {
  /**
   * Obtient ou définit le model à utiliser.
   */
  model?: string;
  /**
   * Obtient ou définit le champ d'expression pour le filtre.
   */
  columnExp?: string;
  /**
   * Obtient ou définit si on utilise la valeur du champs (sinon par défaut l'id).
   */
  useFieldValue?: string;
}
/**
 * Représente les lookups.
 */
export interface Lookup {
  /**
   * Obtient ou définit la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit une source de données.
   */
  source?: string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit le displayExpr est traduisible.
   */
  translatable?: boolean;
  /**
   * Obtient ou définit l'ordre de trie d'une colonne.
   */
  sortIndex?: number;
  /**
   * Obtient ou définit si l'option clear est visible.
   */
  allowClearing?: boolean;
  /**
   * Obtient ou définit le sens du trie.
   */
  sortOrder?: 'asc' | 'desc';
}
/**
 * Représente les données des sous-grilles.
 */
export interface MasterDetail {
  /**
   * Obtient ou définit l'icone de la page affichée dans le menu.
   */
  icon?: string;
  /**
   * Obtient ou définit le libellé de la page affichée dans le menu.
   */
  label?: string;
  /**
   * Obtient ou définit si le filtre automatique est utilisé.
   */
  useAutoFilter?: boolean;
  /**
   * Obtient ou définit la configuration de la grille.
   */
  control?: LinkListOptions;
  /**
   * Obtient ou définit le template de ligne.
   */
  rowTemplate?: string;
  openMode?: 'all' | 'onClick';
}
/**
 * Représente les options d'un control de type LinkList.
 */
export interface LinkListOptions {
  /**
   * Obtient ou définit la source de données permettant de remplir avec des données par défaut.
   */
  defaultSource?: StaticSource | string;
  /**
   * Obtient ou définit l'utilisation de filtre pré-défini en fonction du type.
   */
  filterSource?: string;
  /**
   * Obtient ou définit si on affiche le menu (nav menu) des filtres que dans le pop-up d'association
   */
  displayFilterOnlyForPopup?: boolean;
  /**
   * Obtient ou définit les filtres à afficher.
   */
  filters?: Filter[];
  /**
   * Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer.
   */
  beforeRemoveItemsMessage?: string;
  /**
   * Obtient ou définit une fonction à appeler avant la suppression.
   */
  beforeRemoveItems?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour la liste de selection.
   */
  selectSource?: StaticSource | string;
  /**
   * Obtient ou définit le header est affiché.
   */
  showColumnHeaders?: boolean;
  /**
   * Obtient ou définit le boutin disassocier est affiché.
   */
  canDissociated?: boolean;
  /**
   * Obtient ou définit le clear est affiché.
   */
  clearVisibility?: boolean;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items ajoutés
   */
  addItems?: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items supprimés
   */
  removeItems?: string;
  /**
   * Définit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Définit le noeud parent
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit le bouton d'action'.
   */
  activeButton?: boolean;
  /**
   * Obtient ou définit le titre du bouton d'action'.
   */
  titleButton?: string;
  /**
   * Obtient ou définit la route du bouton d'action'.
   */
  routeButton?: string;
  /**
   * Obtient ou définit le nom du parametre de l'id dans l'url du bouton d'action'.
   */
  queryParamsFieldIdButton?: string;
}
/**
 * Représente les filtres.
 */
export interface Filter {
  /**
   * Obtient ou définit le nom du filtre à afficher.
   */
  text?: string;
  /**
   * Obtient ou définit l'ensemble des données à afficher.
   */
  list?: List;
  /**
   * Obtient ou définit l'ensemble des données à afficher.
   */
  items?: ButtonTreeViewOptions[];
}
/**
 * Obtient ou définit l'ensemble des données à afficher.
 */
export interface List {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit si la list doit être rafraîchit en temps réél
   */
  liveUpdate?: boolean;
  parameters?: {
    type?: string;
    [k: string]: unknown;
  };
}
/**
 * Représente les options d'un control de type TreeView.
 */
export interface ButtonTreeViewOptions {
  /**
   * Obtient ou définit le libellé à afficher.
   */
  text?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient la liste d'id qui ne sera pas activé dans le filtre.
   */
  enabledSource?: StaticSource | string;
  /**
   * Obtient ou définit une valeur indiquant si les valeurs cochées doivent être mises dans le context.
   */
  inContext?: boolean;
  /**
   * Obtient ou définit le filtre de la source de données à afficher.
   */
  filterExpr?: string;
  /**
   * Obtient ou définit le filtre d'expression est translatable.
   */
  filterExprTranslatable?: boolean;
  /**
   * Obtient ou définit le datasource du filtre doit etre recharger
   */
  filterLive?: boolean;
  /**
   * Obtient ou définit un filtre personnalisé à passé dans le context d'exécution
   */
  filterContext?: string;
  /**
   * Obtient ou définit si le champ est traduit
   */
  translatable?: boolean;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit la description du hint.
   */
  description?: string;
  /**
   * Obtient ou définit la clé du champs de données.
   */
  keyExpr?: string;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit l'operateur appliqué pour le filtre, default =
   */
  filterOperator?: '=' | '>' | '>=' | '<' | '<=' | '<>' | 'contains' | 'notcontains' | 'stringcontains';
  /**
   * Obtient ou définit la value de la récursivité.
   */
  recursive?: boolean;
  /**
   * Obtient ou définit la value de l'appelle a une command special
   */
  command?: boolean;
  /**
   * Obtient ou définit l'ensemble des données à afficher.
   */
  list?: List;
  /**
   * Obtient ou définit si l'item est visible.
   */
  visible?: boolean;
}
/**
 * Représente les options d'un control de type TagBoxComponent.
 */
export interface TagBoxOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit si la liste s'ouvre automatiquement.
   */
  openOnFieldClick?: boolean;
  /**
   * Obtient ou définit si l'objet detiens un ID
   */
  needId?: boolean;
  /**
   * Obtient ou définit le methode pour obtenir les tags ne pouvant être désélectionné.
   */
  unDeselectItem?: string;
  /**
   * Obtient ou définit le message d'avertissement de tag ne pouvant être désélectionné.
   */
  unDeselectItemMessage?: string;
  /**
   * Obtient ou définit le methode pour obtenir vérifier l'unicité d'une valeur.
   */
  unique?: string;
  /**
   * Obtient ou définit le comportement de sélection dans le dataSource.
   */
  applyValueMode?: 'instantly' | 'useButtons';
  /**
   * Obtient ou définit le champ de la source de données à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit si le displayExrp doit etre traduit.
   */
  translatable?: boolean;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit le mode de recherche.
   */
  searchMode?: 'contains' | 'startswith';
  /**
   * Obtient ou définit si le boutton de reset est présent.
   */
  showClearButton?: boolean;
  /**
   * Obtient ou définit si les valeurs personnalisée sont acceptée.
   */
  acceptCustomValue?: boolean;
  /**
   * Obtient ou définit si les valeurs peuvent être réordonnées.
   */
  dragAndDrop?: boolean;
  /**
   * Obtient ou définit le template pour afficher les tags.
   */
  tagTemplate?: string;
  /**
   * Obtient ou définit le template pour afficher les items.
   */
  itemTemplate?: string;
  /**
   * Obtient ou définit le chemin de traduction des template pour les items et tags.
   */
  translatePath?: string;
  /**
   * Obtient ou définit si le composant d'interface utilisateur est ou non multiligne.
   */
  multiline?: boolean;
}
/**
 * Représente les options d'un control de type DropDownBox.
 */
export interface DropDownBoxOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit la value de la récursivité.
   */
  recursive?: boolean;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit le mode d'affichage.
   */
  mode?: 'Grid' | 'Tree';
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit le mode de recherche.
   */
  searchMode?: 'contains' | 'startswith';
}
/**
 * Représente les options d'un control de type Grid.
 */
export interface GridOptions {
  /**
   * Obtient ou définit le type.
   */
  type?: 'Tree' | 'Grid';
  /**
   * Obtient ou définit la clé pour charger les properties dynamiques.
   */
  dynamicPropertyKey?: string;
  /**
   * Obtient ou définit si le fieldname doit etre chargé dans le graphql
   */
  managed?: boolean;
  /**
   * Obtient ou définit si le composant doit se raffraichir automatiquement lorsqu'on revient dessus
   */
  autoRefresh?: boolean;
  /**
   * Obtient ou définit le message si aucune données affichées.
   */
  noDataText?: string;
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit le champs de désactivation pour un noeud.
   */
  disabledExpr?: string;
  /**
   * Obtient ou définit la valeur pour d'équalité pour désactiver un noeud.
   */
  disabledValue?: {
    [k: string]: unknown;
  };
  /**
   * Obtient ou définit le champs clé.
   */
  keyExpr?: string;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit si uniquement les parentId doivent être sauvegardé.
   */
  excludeRecursiveId?: boolean;
  /**
   * Obtient ou définit le mode de récursivité.
   */
  recursiveMode?: 'Free' | 'DisableChild';
  /**
   * Obtient ou définit la visibilité du choix de la récursivité.
   */
  showButonRecursif?: boolean;
  /**
   * Obtient ou définit la value de la récursivité.
   */
  recursive?: boolean;
  /**
   * Obtient ou définit si le bouton de mode de sélection est visible.
   */
  viewSelectMode?: boolean;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données.
   */
  selectSource?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  selectColumns?: Column[];
  /**
   * Obtient ou définit la méthode à appeler pour leur d'un ajout d'item.
   */
  selectMethodAdd?: string;
  /**
   * Obtient ou définit la méthode à appeler pour leur d'une suppression d'item.
   */
  selectMethodDelete?: string;
  /**
   * Obtient ou définit la méthode à appeler pour la mise en forme de l'enregistrement.
   */
  saveItems?: string;
  /**
   * Obtient ou définit l'utilisation de filtre pré-défini en fonction du type.
   */
  filterSource?: string;
  /**
   * Obtient ou définit les informations de sélection de la page.
   */
  paging?: GridPagingOptions;
  /**
   * Obtient ou définit les options de sélection.
   */
  selection?: GridSelectOptions;
  /**
   * Obtient ou définit la colonne d'expression pour la sélection.
   */
  selectedRowKeys?: string;
  /**
   * Obtient ou définit si la zone de recherche est visible.
   */
  searchPanel?: boolean;
  /**
   * Obtient ou définit si la zone de filtre est visible.
   */
  filterPanel?: boolean;
  /**
   * Obtient ou définit si l'export est disponible.
   */
  export?: boolean;
  /**
   * Obtient ou définit si le choix des colonnes est disponible.
   */
  columnChooser?: boolean;
  /**
   * Obtient ou définit si l'event onSelectChanged doit etre emit.
   */
  selectionChanged?: boolean;
  /**
   * Obtient ou définit les options d'action côté serveur.
   */
  remoteOperations?: GridRemoteOptions;
  /**
   * Obtient ou définit les options d'édition.
   */
  editing?: GridEditigOptions;
  /**
   * Obtient ou définit les options pour la pagination.
   */
  pager?: GridPagingOptions1;
  /**
   * Obtient ou définit les options pour le groupe de colonne.
   */
  groupPanel?: GridGroupPanelOptions;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit les boutons d'actions.
   */
  toolbarItems?: ToolbarItem[];
}
/**
 * Représente les options de pagination d'un contrôle de type Grid.
 */
export interface GridPagingOptions {
  /**
   * Obtient ou définit si la pagination est active.
   */
  enabled?: boolean;
  /**
   * Obtient ou définit la page affiché.
   */
  pageIndex?: number;
  /**
   * Obtient ou définit le nombre d'éléments par page.
   */
  pageSize?: number;
}
/**
 * Représente les options de sélection d'un contrôle de type Grid.
 */
export interface GridSelectOptions {
  /**
   * Obtient ou définit tout peut être sélectionné.
   */
  allowSelectAll?: boolean;
  /**
   * Obtient ou définit le mode de sélection.
   */
  mode?: 'multiple' | 'none' | 'single';
  /**
   * Obtient ou définit le mode de sélection complète.
   */
  selectAllMode?: 'allPages' | 'page';
  /**
   * Obtient ou définit le mode de sélection de la checkbox.
   */
  showCheckBoxesMode?: 'always' | 'none' | 'onClick' | 'onLongTap';
}
/**
 * Représente les options de action côté serveur d'un contrôle de type Grid.
 */
export interface GridRemoteOptions {
  /**
   * Obtient ou définit le filtre se fait côté serveur.
   */
  filtering?: boolean;
  /**
   * Obtient ou définit le groupe se fait côté serveur.
   */
  grouping?: boolean;
  /**
   * Obtient ou définit la pagination se fait côté serveur.
   */
  paging?: boolean;
  /**
   * Obtient ou définit le trie se fait côté serveur.
   */
  sorting?: boolean;
  /**
   * Obtient ou définit le résumé se fait côté serveur.
   */
  summary?: boolean;
}
/**
 * Représente les options d'édition d'un control de type Grid.
 */
export interface GridEditigOptions {
  /**
   * Obtient ou définit si la grille peut être édité.
   */
  allowUpdating?: boolean;
  /**
   * Obtient ou définit le mode d'édition.
   */
  mode?: 'batch' | 'cell' | 'row' | 'form' | 'popup';
}
/**
 * Représente les options de pagination d'un contrôle de type Grid.
 */
export interface GridPagingOptions1 {
  /**
   * Obtient ou définit le sélecteur de page est visible.
   */
  showPageSizeSelector?: boolean;
  /**
   * Obtient ou définit les possibilités d'affichage d'éléments par page.
   */
  allowedPageSizes?: number[];
  /**
   * Obtient ou définit si les informations sont affichées.
   */
  showInfo?: boolean;
  /**
   * Obtient ou définit si le pager est visible.
   */
  visible?: boolean;
}
/**
 * Représente les options d'affichage du groupe d'un contrôle de type Grid.
 */
export interface GridGroupPanelOptions {
  /**
   * Obtient ou définit si le groupe panel est affiché.
   */
  visible?: boolean;
}
/**
 * Représente un bouton d'action.
 */
export interface ToolbarItem {
  /**
   * Obtient ou définit le nom unique du control.
   */
  name: string;
  /**
   * Obtient ou définit le libellé du control.
   */
  label?: string;
  /**
   * Obtient ou définit l'icône à afficher.
   */
  icon?: string;
  /**
   * Obtient ou définit le texte explicatif.
   */
  hint?:
    | string
    | {
        [k: string]: unknown;
      };
  /**
   * Obtient ou définit le type du bouton d'action
   */
  buttonType?: 'button' | 'button-wait' | 'button-dropdown';
  /**
   * Obtient ou définit le bouton d'action est grisé par le selectedKey
   */
  disabledBySelectedKey?: boolean;
  /**
   * Obtient ou définit le bouton d'action envoye les items sélectionné
   */
  sendSelectedKey?: boolean;
  /**
   * Obtient ou définit l'événement sur le clic.
   */
  click?: string;
  /**
   * Obtient ou définit la source de données pour le dropdown button
   */
  dropdownSource?: StaticSource | string;
}
/**
 * Représente les options d'un control de type Link.
 */
export interface LinkOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit le nom du champ permettant de vérifier si le noeud est actif.
   */
  enabledExp?: string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit si le champ est traduit
   */
  translatable?: boolean;
  /**
   * Obtient ou définit si le boutton effacer est affiché
   */
  showClearButton?: boolean;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit l'id pour une liste dynamique.
   */
  dynamicListId?: string;
  /**
   * Obtient ou définit le nom du template d'affichage a appliquer
   */
  template?: string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit le champs pour le popup d'incation.
   */
  itemHint?: string;
  /**
   * Obtient ou définit le champs pour le popup d'incation est traduisible.
   */
  itemHintTranslatable?: boolean;
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit le paramètre à placer dans la route
   */
  params?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le mode d'affichage.
   */
  mode?: 'Grid' | 'Tree';
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit les filtres à afficher.
   */
  filters?: Filter[];
  /**
   * Obtient ou définit l'utilisation de filtre pré-défini en fonction du type.
   */
  filterSource?: string;
  /**
   * Obtient ou définit l'utilisation d'une classe personnalisé.
   */
  useClass?: {
    [k: string]: unknown;
  };
  /**
   * Obtient ou définit la fonction à utiliser pour la customisation du display expression.
   */
  customDisplayExpr?: {
    [k: string]: unknown;
  };
}
/**
 * Représente les options d'un control de type SimpleList.
 */
export interface SimpleListOptions {
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit si le composent est en lecture seule.
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si un ID (guid) doit etre généré.
   */
  generateId?: boolean;
  /**
   * Obtient ou définit si les titres des colonnes sont affichées.
   */
  showColumnHeaders?: boolean;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
}
/**
 * Représente les options d'un control de type SelectSimpleList.
 */
export interface SelectSimpleListOptions {
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit si le composent est en lecture seule.
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si un ID (guid) doit etre généré.
   */
  generateId?: boolean;
  /**
   * Obtient ou définit si les titres des colonnes sont affichées.
   */
  showColumnHeaders?: boolean;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
}
/**
 * Représente les options d'un control de type Scheduler.
 */
export interface SchedulerOptions {
  /**
   * Obtient ou définit les vues disponibles.
   */
  views?: (
    | 'day'
    | 'week'
    | 'workWeek'
    | 'month'
    | 'timelineDay'
    | 'timelineWeek'
    | 'timelineWorkWeek'
    | 'timelineMonth'
    | 'agenda'
  )[];
  /**
   * Obtient ou définit les vues disponibles.
   */
  currentView?:
    | 'day'
    | 'week'
    | 'workWeek'
    | 'month'
    | 'timelineDay'
    | 'timelineWeek'
    | 'timelineWorkWeek'
    | 'timelineMonth'
    | 'agenda';
  /**
   * Obtient ou définit l'heure de début du calendrier
   */
  startDayHour?: number;
  /**
   * Obtient ou définit l'heure de début du calendrier
   */
  endDayHour?: number;
  /**
   * Obtient ou définit la pagination est faite coté serveur par les dates affichés
   */
  remote?: boolean;
  /**
   * Obtient ou définit si la partie journée entière est visible
   */
  showAllDayPanel?: boolean;
  /**
   * Obtient ou définit si l'ajout est autorisé
   */
  allowAdding?: boolean;
  /**
   * Obtient ou définit si la suppression est autorisé
   */
  allowDeleting?: boolean;
  /**
   * Obtient ou définit si la mise à jour est autorisé
   */
  allowUpdating?: boolean;
  /**
   * Définit la méthode appelée pour sauvegarder les items ajoutés
   */
  addItems?: string;
  /**
   * Définit ou obtient la source des ressources.
   */
  sourceRessource?: string;
  /**
   * Définit ou obtient la méthode d'insertion des ressources.
   */
  insertRessource?: string;
  /**
   * Définit ou obtient la méthode de modifications des ressources.
   */
  updateRessource?: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items supprimés
   */
  removeItems?: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items modifiés
   */
  updateItems?: string;
  /**
   * Obtient ou definit les champ nécéssaire a l'affichage du template (ignore le champ field)
   */
  neededFields?: unknown[];
  /**
   * Obtient ou définit la durée par défaut d'un rendez-vous en minute
   */
  defaultDuration?: ('endOfDay' | 'oneDay') | number;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit les champs à récupérer.
   */
  fields?: string;
  /**
   * Obtient ou définit si le composant doit se raffraichir automatiquement.
   */
  autoRefresh?: boolean;
  /**
   * Obtient ou définit si l'id doit être check pour le chragement).
   */
  checkId?: string;
  /**
   * Obtient ou définit le titre de la sélection pour les forms des planifications.
   */
  selectTitle?: string;
  /**
   * Obtient ou définit l'item à ne pas afficher dans le champ récurrence pour les planifications.
   */
  removeRecurrenceItem?: ('hourly' | 'daily' | 'weekly' | 'monthly' | 'yearly')[];
}
/**
 * Représente les options d'un control de type FileManager.
 */
export interface FileManagerOptions {
  /**
   * Definis la methode appelée pour sauvegarder les items ajoutés.
   */
  addItems?: string;
  /**
   * Definis la methode appelée pour sauvegarder les items supprimés.
   */
  removeItems?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Active l'ajout d'un commentaire.
   */
  canCommentary?: boolean;
  /**
   * Active la visibilité des fichiers au utilisateurs.
   */
  canVisibleHelpDesk?: boolean;
  /**
   * Active l'envoie de la piéce jointe dans l'email sortant'.
   */
  canOutgoingEmails?: boolean;
}
/**
 * Représente les options d'un control de type FileUploader.
 */
export interface FileUploaderOptions {
  /**
   * Definis la methode appelée pour sauvegarder les items ajoutés.
   */
  addItems?: string;
  /**
   * Definis la methode appelée pour sauvegarder les items supprimés.
   */
  removeItems?: string;
  /**
   * Obtient ou défint si on est en mode pour les champs dynamique et le nom du champs associé.
   */
  dynamicFieldName?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
}
/**
 * Représente les options d'un control de type LinkList.
 */
export interface StockCreatedAssetOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour l'export
   */
  exportSource?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Définit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Définit le noeud parent
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
}
/**
 * Représente les options d'un control de type LinkList.
 */
export interface StockListOptions {
  /**
   * Obtient ou définit la source de données permettant de remplir avec des données par défaut.
   */
  defaultSource?: StaticSource | string;
  /**
   * Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer.
   */
  beforeRemoveItemsMessage?: string;
  /**
   * Obtient ou définit une fonction à appeler avant la suppression.
   */
  beforeRemoveItems?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour la liste de selection.
   */
  selectSource?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Décrit qu'elle type de mouvement de stock
   */
  movementType?: string;
  /**
   * Definis la methode appelée pour sauvegarder les items ajoutés
   */
  addItems?: string;
  /**
   * Definis la methode appelée pour sauvegarder les items supprimés
   */
  removeItems?: string;
  /**
   * Definit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Definit le noeud parent
   */
  parentIdExpr?: string;
}
/**
 * Représente les options d'un control de type HtmlEditorComponent.
 */
export interface HtmlEditorOptions {
  /**
   * Obtient ou définit l'affichage des toolbar sur 2 lignes.
   */
  multiline?: boolean;
  /**
   * Obtient ou définit l'affichage des outils de tableau dans la toolbar.
   */
  enableTable?: boolean;
  /**
   * Obtient ou définit si le mode plein ecrant est disponible
   */
  fullScreenMode?: boolean;
  /**
   * Obtient ou définit l'affichage du bouton d'ajout d'un cachet.
   */
  enabledStamp?: boolean;
  /**
   * Obtient ou définit l'affichage du bouton du checkList.
   */
  enabledCheckList?: boolean;
  mentions?: {
    [k: string]: unknown;
  };
  resolutionModel?: ResolutionModelOptions;
  commentaryModel?: CommentaryModelOptions;
}
/**
 * Représente la configuration des modéles de résolution.
 */
export interface ResolutionModelOptions {
  /**
   * Obtient ou définit la données représentant la valeur du placeHolder.
   */
  placeHolder?: string;
  /**
   * Obtient ou définit le champ de la source de données à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée en contenu.
   */
  contentExpr?: string;
  source?: unknown[][];
}
/**
 * Représente la configuration des modéles de commentaire.
 */
export interface CommentaryModelOptions {
  /**
   * Obtient ou définit la données représentant la valeur du placeHolder.
   */
  placeHolder?: string;
  /**
   * Obtient ou définit le champ de la source de données à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit si le champ displayExpr est traduit
   */
  translatable?: boolean;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée en contenu.
   */
  contentExpr?: string;
  /**
   * Obtient ou définit si le champ content est traduit
   */
  contentTranslatable?: boolean;
  source?: unknown[][];
}
/**
 * Représente les options d'un control de type MemoAreaComponent.
 */
export interface MemoAreaOptions {}
/**
 * Représente les options d'un control de type PasswordComponent.
 */
export interface PasswordOptions {
  /**
   * Obtient ou définit le masque de saisie.
   */
  mask?: string;
  /**
   * Obtient ou définit le caractère du masque.
   */
  maskChar?: string;
  /**
   * Obtient ou définit le message lorsque le texte saisi ne correspond pas au pattern spécifié.
   */
  maskInvalidMessage?: string;
  /**
   * Obtient ou définit la règles du masque personnalisé.
   */
  maskRules?:
    | string
    | {
        [k: string]: unknown;
      };
  /**
   * Obtient ou définit le nombre maximum de caractère.
   */
  maxLength?: number;
  /**
   * Obtient ou définit si le bouton de copie est affiché.
   */
  showCopyButton?: boolean;
  /**
   * Obtient ou définit si le bouton du lien est affiché.
   */
  showLinkButton?: boolean;
  /**
   * Obtient ou définit si le bouton d'email est affiché.
   */
  showMailToButton?: boolean;
  /**
   * Obtient ou définit si le bouton de reset est affiché.
   */
  showClearButton?: boolean;
  /**
   * Obtient ou definit le type du mot de passe (standard ou chiffrement)
   */
  mode?: 'encryptionKey' | 'password';
  /**
   * Obtient ou définit si le bouton de remise par défaut peut être visible.
   */
  showDefaultSystem?: boolean;
}
/**
 * Représente les options d'un control de type Sub form link List.
 */
export interface SubFormLinkList {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour le bouton Nouveau.
   */
  newCommandSource?: StaticSource | string;
  /**
   * Définit le nom du contrôle
   */
  fieldName?: string;
  /**
   * Définit la méthode appelée pour sauvegarder l'items ajouté
   */
  insert?: string;
  /**
   * Définit la méthode appelée pour supprimer les items à supprimés
   */
  delete?: string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit le nom du template d'affichage a appliquer
   */
  template?: string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit si le bouton ajouter est disponible.
   */
  CanAdd?: boolean;
  /**
   * Obtient ou définit si le bouton editer est disponible.
   */
  canEdit?: boolean;
  /**
   * Obtient ou définit si le bouton supprimer est disponible.
   */
  CanDelete?: boolean;
  /**
   * Obtient ou définit le mode d'affichage.
   */
  mode?: 'Grid' | 'Tree';
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit si le composant est en mode readOnly
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si il faut utiliser un methode spécifique pour charger les règles d'affichages.
   */
  applyModelRule?: string;
  /**
   * Obtient ou définit si le formulaire parent sauvegarde après la validation du sous formulaire.
   */
  saveParentFormOnSubSaved?: boolean;
}
/**
 * Représente les options d'un control de type Sub form link List.
 */
export interface SubFormGantt {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour le bouton Nouveau.
   */
  newCommandSource?: StaticSource | string;
  /**
   * Définit le nom du contrôle
   */
  fieldName?: string;
  /**
   * Définit la méthode appelée pour sauvegarder l'items ajouté
   */
  insert?: string;
  /**
   * Définit la méthode appelée pour supprimer les items à supprimés
   */
  delete?: string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit le nom du template d'affichage a appliquer
   */
  template?: string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit si le bouton ajouter est disponible.
   */
  CanAdd?: boolean;
  /**
   * Obtient ou définit si le bouton ajouter est disponible.
   */
  canAddTaskSequence?: boolean;
  /**
   * Obtient ou définit si le bouton editer est disponible.
   */
  canEdit?: boolean;
  /**
   * Obtient ou définit si le bouton supprimer est disponible.
   */
  CanDelete?: boolean;
  /**
   * Obtient ou définit le mode d'affichage.
   */
  mode?: 'Grid' | 'Tree';
  /**
   * Obtient ou définit le ou les noms des champs de recherche.
   */
  searchExpr?: string[] | string[];
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit si la planification du Gantt est visible.
   */
  viewGantt?: boolean;
  /**
   * Obtient ou définit la taille du popup
   */
  heightNew?: 'normal' | 'note';
  /**
   * Obtient ou définit si le composant est en mode readOnly
   */
  readOnly?: boolean;
  /**
   * Obtient ou définit si il faut utiliser un methode spécifique pour charger les règles d'affichages.
   */
  applyModelRule?: string;
}
/**
 * Représente les options d'un control de type TimespanComponent.
 */
export interface TimespanOptions {
  /**
   * Obtient ou définit le texte.
   */
  text?: string;
  /**
   * Obtient ou définit si le chronomètre est activé
   */
  enableChrono?: boolean;
}
/**
 * Représente les options d'un control de type Link.
 */
export interface LogicTemplateOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit si la qualification est un critère de statut.
   */
  useQualification?: boolean;
  /**
   * Obtient ou définit la methode renvoyant l'affichage d'edition d'un statut.
   */
  displaySource?: string;
}
/**
 * Représente les options d'un control de type Link.
 */
export interface PropertyChangeOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la methode représentant les champs à exclure pour l'action 'vider le champ'
   */
  clearFieldToExclude?: {
    [k: string]: unknown;
  };
}
/**
 * Représente les options d'un control de type SimpleList.
 */
export interface TagBoxEmailOptions {
  /**
   * Obtient ou définit la source de données pour la source des emails.
   */
  dataSource?: StaticSource | string;
  /**
   * Obtient ou définit le champ de la source de données à afficher.
   */
  displayExpr?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit la source de données pour la source des emails.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit si la liste des rôles doit être automatiquement chargée.
   */
  loadRoles?: boolean;
  /**
   * Obtient ou définit si la liste des connecteur d'email.
   */
  loadConnectors?: boolean;
  /**
   * Obtient ou définit si la liste des utilisateurs ne doit comporter que des utilisateurs visibles dans les tickets.
   */
  onlyVisibleHelpDesk?: boolean;
}
/**
 * Représente les options d'un control de type DesignerRuleList.
 */
export interface DesignerRuleListOptions {
  /**
   * Obtient ou définit si le composent est en lecture seule.
   */
  readOnly?: boolean;
}
/**
 * Représente les options d'un control de type MesasgeTickeComponent.
 */
export interface MessageTicketOptions {
  /**
   * Obtient si le compte est opérateur
   */
  isOperator?: boolean;
  messageModel?: ResolutionModelOptions;
}
/**
 * Représente les options d'un control de type Ticket Email List.
 */
export interface TicketEmailListOptions {
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Définit le nom du contrôle
   */
  fieldName?: string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit la données représentant la valeur de la donnée affichée.
   */
  valueExpr?: string;
  /**
   * Obtient ou définit le mode d'affichage.
   */
  mode?: 'Grid' | 'Tree';
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit si le composant est en mode readOnly
   */
  readOnly?: boolean;
}
/**
 * Représente les options d'un control de type VisibleSelectableEntityComponent.
 */
export interface VisibleSelectableEntityOptions {
  /**
   * Obtient ou définit le message si aucune données affichées.
   */
  noDataText?: string;
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
}
/**
 * Représente les options d'un control de type VisibleSelectableLinkEntityComponent.
 */
export interface VisibleSelectableLinkEntityListOptions {
  /**
   * Obtient ou définit la source de données permettant de remplir avec des données par défaut.
   */
  defaultSource?: StaticSource | string;
  /**
   * Obtient ou définit l'utilisation de filtre pré-défini en fonction du type.
   */
  filterSource?: string;
  /**
   * Obtient ou définit si on affiche le menu (nav menu) des filtres que dans le pop-up d'association
   */
  displayFilterOnlyForPopup?: boolean;
  /**
   * Obtient ou définit les filtres à afficher.
   */
  filters?: Filter[];
  /**
   * Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer.
   */
  beforeRemoveItemsMessage?: string;
  /**
   * Obtient ou définit une fonction à appeler avant la suppression.
   */
  beforeRemoveItems?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour la liste de selection.
   */
  selectSource?: StaticSource | string;
  /**
   * Obtient ou définit le type de l'entité manipulée.
   */
  entityType: 'LOCATION' | 'TICKET_CATEGORY' | 'ORGANIZATIONAL_UNIT';
  /**
   * Obtient ou définit le header est affiché.
   */
  showColumnHeaders?: boolean;
  /**
   * Obtient ou définit le boutin disassocier est affiché.
   */
  canDissociated?: boolean;
  /**
   * Obtient ou définit le clear est affiché.
   */
  clearVisibility?: boolean;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items ajoutés
   */
  addItems?: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items supprimés
   */
  removeItems?: string;
  /**
   * Définit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Définit le noeud parent
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit le bouton d'action'.
   */
  activeButton?: boolean;
  /**
   * Obtient ou définit le titre du bouton d'action'.
   */
  titleButton?: string;
  /**
   * Obtient ou définit la route du bouton d'action'.
   */
  routeButton?: string;
  /**
   * Obtient ou définit le nom du parametre de l'id dans l'url du bouton d'action'.
   */
  queryParamsFieldIdButton?: string;
}
/**
 * Représente les options d'un control de type CheckLinkList.
 */
export interface CheckLinkListOptions {
  /**
   * Obtient ou définit la source de données permettant de remplir avec des données par défaut.
   */
  defaultSource?: StaticSource | string;
  /**
   * Obtient ou définit l'utilisation de filtre pré-défini en fonction du type.
   */
  filterSource?: string;
  /**
   * Obtient ou définit les filtres à afficher.
   */
  filters?: Filter[];
  /**
   * Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer.
   */
  beforeRemoveItemsMessage?: string;
  /**
   * Obtient ou définit une fonction à appeler avant la suppression.
   */
  beforeRemoveItems?: string;
  /**
   * Obtient ou définit la source de données.
   */
  source?: StaticSource | string;
  /**
   * Obtient ou définit la source de données pour la liste de selection.
   */
  selectSource?: StaticSource | string;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit la route de gestion de l'object.
   */
  route: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items ajoutés
   */
  addItems?: string;
  /**
   * Définit la méthode appelée pour sauvegarder les items supprimés
   */
  removeItems?: string;
  /**
   * Définit le type d'affichage Tree ou Grid
   */
  type?: 'Tree' | 'Grid';
  /**
   * Définit le noeud parent
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Définit le hint à afficher
   */
  hintMessage?: string;
}
/**
 * Représente les options pour afficher les champs personnalisés.
 */
export interface DynamicPropertyOptions {
  /**
   * Obtient ou définit la position de la page des champs personnalisés. Exemple : '2' pour la seconde page, 'last' pour la derniere page, 'before' pour avant une page
   */
  location?: 'first' | 'last' | 'before' | 'after' | 'static';
  /**
   * Obtient ou définit la page de référence pour les valeur 'after' ou 'before'
   */
  pageIndexReference?: string | number;
  /**
   * Obtient ou définit si le methode de service pour obtenir les champs personnalisés.
   */
  source?: string;
  additionalProperties?: unknown;
  [k: string]: unknown;
}
/**
 * Représente la configuration de la page.
 */
export interface PageSection {
  /**
   * Obtient ou définit si le methode de service pour obtenir les propriété dynamique.
   */
  dynamicProperty?: string;
  /**
   * Obtient ou définit l'icone de la page affichée dans le menu.
   */
  icon?: string;
  /**
   * Obtient ou définit le texte explicatif
   */
  hint?: string;
  /**
   * Obtient ou définit la visibilité
   */
  visible?: boolean;
  /**
   * Obtient ou définit le libellé de la page affichée dans le menu.
   */
  label: string;
  /**
   * Obtient ou définit la visibilité pour l'utilisateur
   */
  userVisible?: boolean;
  /**
   * Permet de charge la page meme si le visible est a false
   */
  forceLoading?: boolean;
  /**
   * Obtient ou définit les configurations des sections.
   */
  sections: Section[];
  /**
   * Obtient ou définit le nom de la propriété dont dépend celle-ci.
   */
  dependsOn?: DependsOn;
  /**
   * Obtient ou définit le pourcentage de la premierer colonne.
   */
  percentage?: '30' | '50' | '70';
}
/**
 * Représente la configuration d'une section du formulaire.
 */
export interface Section {
  /**
   * Obtient ou définit les groupes de la section.
   */
  groups?: SectionGroup[];
  /**
   * Obtient ou définit la position de la section.
   */
  location?: 'before' | 'center' | 'after' | 'full' | 'right' | 'left';
}
/**
 * Représente la configuration d'un groupe de section.
 */
export interface SectionGroup {
  /**
   * Obtient ou définit le libellé de la section.
   */
  label: string;
  /**
   * Obtient ou définit si le groupe doit être fermé.
   */
  expanded?: boolean;
  /**
   * Obtient ou définit le nom de la propriété dont dépend celle-ci.
   */
  dependsOn?: DependsOn;
  /**
   * Obtient ou définit les controls.
   */
  controls: Control[];
}
/**
 * Représente la configuration de la page.
 */
export interface PageControl {
  /**
   * Obtient ou définit le libellé de la page affichée dans le menu.
   */
  label: string;
  /**
   * Obtient ou définit le texte explicatif
   */
  hint?: string;
  /**
   * Obtient ou définit l'icône de la page affichée dans le menu.
   */
  icon: string;
  /**
   * Obtient ou définit la visibilité
   */
  visible?: boolean;
  /**
   * Obtient ou définit la visibilité pour l'utilisateur
   */
  userVisible?: boolean;
  /**
   * Permet de charge la page meme si le visible est a false
   */
  forceLoading?: boolean;
  /**
   * Obtient ou définit le control unique de la page.
   */
  control: Control;
  /**
   * Obtient ou définit le nom de la propriété dont dépend celle-ci.
   */
  dependsOn?: DependsOn;
}
/**
 * Représente la configuration du groupe de page.
 */
export interface PageGroup {
  /**
   * Obtient ou définit si le methode de service pour obtenir les propriété dynamique.
   */
  dynamicProperty?: string;
  /**
   * Obtient ou définit l'icone de la page affichée dans le menu.
   */
  icon?: string;
  /**
   * Obtient ou définit le texte explicatif
   */
  hint?: string;
  /**
   * Obtient ou définit la visibilité
   */
  visible?: boolean;
  /**
   * Obtient ou définit le libellé du groupe.
   */
  label: string;
  /**
   * Obtient ou définit la visibilité pour l'utilisateur
   */
  userVisible?: boolean;
  /**
   * Permet de charge la page meme si le visible est a false
   */
  forceLoading?: boolean;
  /**
   * Obtient ou définit les configurations des pages.
   */
  pages: (PageSection | PageControl)[];
}
/**
 * Représente les données des tabs.
 */
export interface PageTab {
  /**
   * Obtient ou définit si le methode de service pour obtenir les propriété dynamique.
   */
  dynamicProperty?: string;
  /**
   * Obtient ou définit l'icone de la page affichée dans le menu.
   */
  icon?: string;
  /**
   * Obtient ou définit la visibilité
   */
  visible?: boolean;
  /**
   * Obtient ou définit la visibilité pour l'utilisateur
   */
  userVisible?: boolean;
  /**
   * Permet de charge la page meme si le visible est a false
   */
  forceLoading?: boolean;
  /**
   * Obtient ou définit le libellé de la page affichée dans le menu.
   */
  label?: string;
  /**
   * Obtient ou définit une clé pour le tab.
   */
  itemKey?: string;
  /**
   * Obtient ou définit le texte explicatif
   */
  hint?: string;
  /**
   * Obtient ou définit les configurations du tab.
   */
  tabs?: (PageSection | PageControl)[];
  /**
   * Obtient ou définit le nom de la propriété dont dépend celle-ci.
   */
  dependsOn?: DependsOn;
}
/**
 * Représente la configuration des écrans listes.
 */
export interface Grid {
  /**
   * Obtient ou définit la configuration du layout des écrans listes.
   */
  layout: GridLayout;
}
/**
 * Représente la configuration du layout des écrans listes.
 */
export interface GridLayout {
  /**
   * Obtient ou définit le type.
   */
  type?: 'Tree' | 'Grid' | 'Scheduler';
  /**
   * Obtient ou définit les commandes.
   */
  commands?: (Command | CommandItems)[];
  /**
   * Obtient ou définit la fonction à utiliser pour tester si l'objet est utilisé.
   */
  isUsed?: string;
  /**
   * Obtient ou définit le mode de multi-sélection.
   */
  multiple?: boolean;
  /**
   * Obtient ou définit le nom du champ correspondant au parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit le nom du champ permettant de vérifier si le noeud est actif.
   */
  enabledExp?: string;
  /**
   * Obtient ou définit le nombre de noeud max autorisé.
   */
  maxLevel?: number;
  /**
   * Obtient ou définit si le drag and drop est activé.
   */
  dragAndDrop?: boolean;
  /**
   * Obtient ou définit le drag est autorisé uniquement dans un niveau identique.
   */
  dragOnlySameLevel?: boolean;
  /**
   * Obtient ou définit la fonction de déplacement à appeler.
   */
  drag?: string;
  /**
   * Obtient ou définit la value de la récursivité.
   */
  recursive?: boolean;
  /**
   * Obtient ou définit si le bouton de mode de sélection est visible.
   */
  viewSelectMode?: boolean;
  /**
   * Obtient ou définit le mode de récursivité.
   */
  recursiveMode?: 'Free' | 'DisableChild';
  /**
   * Obtient ou définit la clé pour charger les properties dynamiques.
   */
  dynamicPropertyKey?: unknown[];
  /**
   * Obtient ou définit si on est dans le mode de queryBuilder.
   */
  isQueryBuilder?: boolean;
  /**
   * Obtient ou définit les colonnes à afficher.
   */
  columns?: Column[];
  /**
   * Obtient ou définit l'utilisation de Atlas Search et la liste des index Atlas .
   */
  atlasIndexes?: string;
  /**
   * Obtient ou définit les sous-grilles à afficher.
   */
  masterDetail?: MasterDetail[];
  /**
   * Obtient ou définit la fonction de suppression à appeler.
   */
  delete?: string;
  /**
   * Obtient ou définit la fonction de restoration à appeler.
   */
  restore?: string;
  /**
   * Obtient ou définit la fonction permettant de définir le nombre d'objet à supprimer.
   */
  countRecycles?: string;
  /**
   * Obtient ou définit les filtres à afficher.
   */
  filters?: Filter[];
  /**
   * Obtient ou définit le nom du fichier d'export.
   */
  exportFileName?: string;
  /**
   * Obtient ou définit les options.
   */
  options?: SchedulerOptions;
}
/**
 * Représente une commande.
 */
export interface Command {
  /**
   * Obtient ou définit le nom unique de la commande.
   */
  name?: string;
}
/**
 * Représente une commande avec une source de données.
 */
export interface CommandItems {
  /**
   * Obtient ou définit le nom unique de la commande.
   */
  name?: string;
  /**
   * Obtient ou définit une source de données.
   */
  source?: string;
  /**
   * Obtient ou définit le npm du champ de la clé de l'object cible.
   */
  targetIdExpr?: string;
  /**
   * Obtient ou définit le nom du champ correspondant au champ parent.
   */
  parentIdExpr?: string;
  /**
   * Obtient ou définit le nom du champ représentant la clé.
   */
  keyExpr?: string;
  /**
   * Obtient ou définit le nom du champ à afficher.
   */
  displayExpr?: string;
}
