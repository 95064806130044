import { TaskStatusBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { LogicDiagramView } from '@clarilog/shared2/components/logic-diagram/logic-diagram.component';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-task-status')
export class TaskStatusCoreService extends TaskStatusBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService() {
    return this;
  }
}
