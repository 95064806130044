import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAntiVirusProductsBaseVariables, FirstAntiVirusProductsBaseVariables, CountAntiVirusProductsBaseVariables, FindAntiVirusProductsBaseVariables, SearchAntiVirusProductsBaseVariables, ExportSchemaAntiVirusProductsBaseVariables, ExportDataAntiVirusProductsBaseVariables, CustomQueryAntiVirusProductsBaseVariables, CustomQueryIdAntiVirusProductsBaseVariables, UsedAntiVirusProductsBaseVariables, ExistAntiVirusProductsBaseVariables } from '../gqls'
import { GetAntiVirusProductsDocument, FirstAntiVirusProductsDocument, CountAntiVirusProductsDocument, FindAntiVirusProductsDocument, SearchAntiVirusProductsDocument, ExportSchemaAntiVirusProductsDocument, ExportDataAntiVirusProductsDocument, CustomQueryAntiVirusProductsDocument, CustomQueryIdAntiVirusProductsDocument, UsedAntiVirusProductsDocument, ExistAntiVirusProductsDocument } from '../gqls'
import { ServiceSingleResultOfAntiVirusProduct, QueryContextOfAntiVirusProduct, FilterOfAntiVirusProduct, ServiceSingleResultOfInt64, ServiceListResultOfAntiVirusProduct, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AntiVirusProductBaseService {
    
public modelName = 'antiVirusProduct';
public modelPluralName = 'antiVirusProducts';

	private getAntiVirusProductsQuery: GetAntiVirusProductsDocument;
	private firstAntiVirusProductsQuery: FirstAntiVirusProductsDocument;
	private countAntiVirusProductsQuery: CountAntiVirusProductsDocument;
	private findAntiVirusProductsQuery: FindAntiVirusProductsDocument;
	private searchAntiVirusProductsQuery: SearchAntiVirusProductsDocument;
	private exportSchemaAntiVirusProductsQuery: ExportSchemaAntiVirusProductsDocument;
	private exportDataAntiVirusProductsQuery: ExportDataAntiVirusProductsDocument;
	private customQueryAntiVirusProductsQuery: CustomQueryAntiVirusProductsDocument;
	private customQueryIdAntiVirusProductsQuery: CustomQueryIdAntiVirusProductsDocument;
	private usedAntiVirusProductsQuery: UsedAntiVirusProductsDocument;
	private existAntiVirusProductsQuery: ExistAntiVirusProductsDocument;

	constructor(private injector: Injector) {
		this.getAntiVirusProductsQuery = this.injector.get(GetAntiVirusProductsDocument);
		this.firstAntiVirusProductsQuery = this.injector.get(FirstAntiVirusProductsDocument);
		this.countAntiVirusProductsQuery = this.injector.get(CountAntiVirusProductsDocument);
		this.findAntiVirusProductsQuery = this.injector.get(FindAntiVirusProductsDocument);
		this.searchAntiVirusProductsQuery = this.injector.get(SearchAntiVirusProductsDocument);
		this.exportSchemaAntiVirusProductsQuery = this.injector.get(ExportSchemaAntiVirusProductsDocument);
		this.exportDataAntiVirusProductsQuery = this.injector.get(ExportDataAntiVirusProductsDocument);
		this.customQueryAntiVirusProductsQuery = this.injector.get(CustomQueryAntiVirusProductsDocument);
		this.customQueryIdAntiVirusProductsQuery = this.injector.get(CustomQueryIdAntiVirusProductsDocument);
		this.usedAntiVirusProductsQuery = this.injector.get(UsedAntiVirusProductsDocument);
		this.existAntiVirusProductsQuery = this.injector.get(ExistAntiVirusProductsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAntiVirusProduct> {
    
    		let variables: GetAntiVirusProductsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.get));
            }
            else{
                let result:ServiceSingleResultOfAntiVirusProduct={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAntiVirusProduct,
		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAntiVirusProduct> {
    
    		let variables: FirstAntiVirusProductsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAntiVirusProductsBaseVariables = {
    			filter: filter
    		}
    				return this.countAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAntiVirusProduct,
		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiVirusProduct> {
    
    		let variables: FindAntiVirusProductsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAntiVirusProduct,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiVirusProduct> {
    
    		let variables: SearchAntiVirusProductsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAntiVirusProductsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAntiVirusProductsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAntiVirusProduct,
		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiVirusProduct> {
    
    		let variables: CustomQueryAntiVirusProductsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAntiVirusProduct,
		@Args('filter?') filter?: FilterOfAntiVirusProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiVirusProduct> {
    
    		let variables: CustomQueryIdAntiVirusProductsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAntiVirusProductsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAntiVirusProductsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAntiVirusProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiVirusProducts.exist));
    	}
    
    
}