<div class="title">
  <div class="container-title">
    <div class="button-back-mobile">
      <dx-button
        icon="fal fa-chevron-left"
        *ngIf="showBack === true"
        (onClick)="backClick($event)"
      ></dx-button>
      <dx-button
        [icon]="'menu'"
        *ngIf="mobileMode === true"
        (onClick)="onMobileMenuClick.emit($event)"
        [(visible)]="menuNavMobileVisibility"
      ></dx-button>
    </div>

    <div
      class="separator"
      *ngIf="showBack === true || mobileMode === true"
    ></div>
    <div class="content-title">
      <ng-content></ng-content>
      <!-- <span *ngIf="createInfo != undefined" class="create-info">
        {{ 'globals/create-info' | translate: createInfo }}</span
      > -->
      <div *ngIf="attributesInfo != undefined" class="create-info">
        <span>{{
          'globals/create-info-date'
            | translate : { date: format(attributesInfo.created.date) }
        }}</span
        >&nbsp;
        <span *ngIf="attributesInfo.created.fullName != undefined">{{
          'globals/create-info-by'
            | translate : { fullName: attributesInfo.created.fullName }
        }}</span>
        <!-- <span></span>
        {{
          'globals/create-info'
            | translate
              : {
                  date: format(createInfo.date),
                  fullName: createInfo.fullName
                }
        }} -->
      </div>
    </div>
  </div>
</div>
