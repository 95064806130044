import { Authorize } from '@clarilog/core';
import { LabelTemplateBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-asset-label-template')
export class LabelTemplateCoreService extends LabelTemplateBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
