import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketStatusesBaseVariables, FirstTicketStatusesBaseVariables, CountTicketStatusesBaseVariables, FindTicketStatusesBaseVariables, SearchTicketStatusesBaseVariables, ExportSchemaTicketStatusesBaseVariables, ExportDataTicketStatusesBaseVariables, CustomQueryTicketStatusesBaseVariables, CustomQueryIdTicketStatusesBaseVariables, UsedTicketStatusesBaseVariables, FindByWorkflowTicketStatusesBaseVariables, ExistTicketStatusesBaseVariables, FindRecyclesTicketStatusesBaseVariables, CountRecyclesTicketStatusesBaseVariables, ReadOnlyTicketStatusesBaseVariables, FindArchivedTicketStatusesBaseVariables, CountAllTicketStatusesBaseVariables, FindDynamicPropertyFieldsTicketStatusesBaseVariables, FindUnassociatedTicketsTicketStatusesBaseVariables, FindUnassociatedTicketBacklogsTicketStatusesBaseVariables, InsertTicketStatusesBaseVariables, UpdateTicketStatusesBaseVariables, ImportDataTicketStatusesBaseVariables, UpdateManyTicketStatusesBaseVariables, DeleteTicketStatusesBaseVariables, RestoreTicketStatusesBaseVariables, RecycleTicketStatusesBaseVariables, RestoreArchivedTicketStatusesBaseVariables, ArchivedTicketStatusesBaseVariables, AddFileDynamicFieldTicketStatusesBaseVariables, RemoveFileDynamicFieldTicketStatusesBaseVariables, AddTicketBacklogsTicketStatusesBaseVariables, RemoveTicketBacklogsTicketStatusesBaseVariables, AddTicketsTicketStatusesBaseVariables, RemoveTicketsTicketStatusesBaseVariables } from '../gqls'
import { GetTicketStatusesDocument, FirstTicketStatusesDocument, CountTicketStatusesDocument, FindTicketStatusesDocument, SearchTicketStatusesDocument, ExportSchemaTicketStatusesDocument, ExportDataTicketStatusesDocument, CustomQueryTicketStatusesDocument, CustomQueryIdTicketStatusesDocument, UsedTicketStatusesDocument, FindByWorkflowTicketStatusesDocument, ExistTicketStatusesDocument, FindRecyclesTicketStatusesDocument, CountRecyclesTicketStatusesDocument, ReadOnlyTicketStatusesDocument, FindArchivedTicketStatusesDocument, CountAllTicketStatusesDocument, FindDynamicPropertyFieldsTicketStatusesDocument, FindUnassociatedTicketsTicketStatusesDocument, FindUnassociatedTicketBacklogsTicketStatusesDocument, InsertTicketStatusesDocument, UpdateTicketStatusesDocument, ImportDataTicketStatusesDocument, UpdateManyTicketStatusesDocument, DeleteTicketStatusesDocument, RestoreTicketStatusesDocument, RecycleTicketStatusesDocument, RestoreArchivedTicketStatusesDocument, ArchivedTicketStatusesDocument, AddFileDynamicFieldTicketStatusesDocument, RemoveFileDynamicFieldTicketStatusesDocument, AddTicketBacklogsTicketStatusesDocument, RemoveTicketBacklogsTicketStatusesDocument, AddTicketsTicketStatusesDocument, RemoveTicketsTicketStatusesDocument } from '../gqls'
import { ServiceSingleResultOfTicketStatus, QueryContextOfTicketStatus, FilterOfTicketStatus, ServiceSingleResultOfInt64, ServiceListResultOfTicketStatus, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketStatus, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, QueryContextOfTicketBacklog, FilterOfTicketBacklog, ServiceListResultOfTicketBacklog, TicketStatusInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketStatusBaseService {
    
public modelName = 'ticketStatus';
public modelPluralName = 'ticketStatuses';

	private getTicketStatusesQuery: GetTicketStatusesDocument;
	private firstTicketStatusesQuery: FirstTicketStatusesDocument;
	private countTicketStatusesQuery: CountTicketStatusesDocument;
	private findTicketStatusesQuery: FindTicketStatusesDocument;
	private searchTicketStatusesQuery: SearchTicketStatusesDocument;
	private exportSchemaTicketStatusesQuery: ExportSchemaTicketStatusesDocument;
	private exportDataTicketStatusesQuery: ExportDataTicketStatusesDocument;
	private customQueryTicketStatusesQuery: CustomQueryTicketStatusesDocument;
	private customQueryIdTicketStatusesQuery: CustomQueryIdTicketStatusesDocument;
	private usedTicketStatusesQuery: UsedTicketStatusesDocument;
	private findByWorkflowTicketStatusesQuery: FindByWorkflowTicketStatusesDocument;
	private existTicketStatusesQuery: ExistTicketStatusesDocument;
	private findRecyclesTicketStatusesQuery: FindRecyclesTicketStatusesDocument;
	private countRecyclesTicketStatusesQuery: CountRecyclesTicketStatusesDocument;
	private readOnlyTicketStatusesQuery: ReadOnlyTicketStatusesDocument;
	private findArchivedTicketStatusesQuery: FindArchivedTicketStatusesDocument;
	private countAllTicketStatusesQuery: CountAllTicketStatusesDocument;
	private findDynamicPropertyFieldsTicketStatusesQuery: FindDynamicPropertyFieldsTicketStatusesDocument;
	private findUnassociatedTicketsTicketStatusesQuery: FindUnassociatedTicketsTicketStatusesDocument;
	private findUnassociatedTicketBacklogsTicketStatusesQuery: FindUnassociatedTicketBacklogsTicketStatusesDocument;
	private insertTicketStatusesMutation: InsertTicketStatusesDocument;
	private updateTicketStatusesMutation: UpdateTicketStatusesDocument;
	private importDataTicketStatusesMutation: ImportDataTicketStatusesDocument;
	private updateManyTicketStatusesMutation: UpdateManyTicketStatusesDocument;
	private deleteTicketStatusesMutation: DeleteTicketStatusesDocument;
	private restoreTicketStatusesMutation: RestoreTicketStatusesDocument;
	private recycleTicketStatusesMutation: RecycleTicketStatusesDocument;
	private restoreArchivedTicketStatusesMutation: RestoreArchivedTicketStatusesDocument;
	private archivedTicketStatusesMutation: ArchivedTicketStatusesDocument;
	private addFileDynamicFieldTicketStatusesMutation: AddFileDynamicFieldTicketStatusesDocument;
	private removeFileDynamicFieldTicketStatusesMutation: RemoveFileDynamicFieldTicketStatusesDocument;
	private addTicketBacklogsTicketStatusesMutation: AddTicketBacklogsTicketStatusesDocument;
	private removeTicketBacklogsTicketStatusesMutation: RemoveTicketBacklogsTicketStatusesDocument;
	private addTicketsTicketStatusesMutation: AddTicketsTicketStatusesDocument;
	private removeTicketsTicketStatusesMutation: RemoveTicketsTicketStatusesDocument;

	constructor(private injector: Injector) {
		this.getTicketStatusesQuery = this.injector.get(GetTicketStatusesDocument);
		this.firstTicketStatusesQuery = this.injector.get(FirstTicketStatusesDocument);
		this.countTicketStatusesQuery = this.injector.get(CountTicketStatusesDocument);
		this.findTicketStatusesQuery = this.injector.get(FindTicketStatusesDocument);
		this.searchTicketStatusesQuery = this.injector.get(SearchTicketStatusesDocument);
		this.exportSchemaTicketStatusesQuery = this.injector.get(ExportSchemaTicketStatusesDocument);
		this.exportDataTicketStatusesQuery = this.injector.get(ExportDataTicketStatusesDocument);
		this.customQueryTicketStatusesQuery = this.injector.get(CustomQueryTicketStatusesDocument);
		this.customQueryIdTicketStatusesQuery = this.injector.get(CustomQueryIdTicketStatusesDocument);
		this.usedTicketStatusesQuery = this.injector.get(UsedTicketStatusesDocument);
		this.findByWorkflowTicketStatusesQuery = this.injector.get(FindByWorkflowTicketStatusesDocument);
		this.existTicketStatusesQuery = this.injector.get(ExistTicketStatusesDocument);
		this.findRecyclesTicketStatusesQuery = this.injector.get(FindRecyclesTicketStatusesDocument);
		this.countRecyclesTicketStatusesQuery = this.injector.get(CountRecyclesTicketStatusesDocument);
		this.readOnlyTicketStatusesQuery = this.injector.get(ReadOnlyTicketStatusesDocument);
		this.findArchivedTicketStatusesQuery = this.injector.get(FindArchivedTicketStatusesDocument);
		this.countAllTicketStatusesQuery = this.injector.get(CountAllTicketStatusesDocument);
		this.findDynamicPropertyFieldsTicketStatusesQuery = this.injector.get(FindDynamicPropertyFieldsTicketStatusesDocument);
		this.findUnassociatedTicketsTicketStatusesQuery = this.injector.get(FindUnassociatedTicketsTicketStatusesDocument);
		this.findUnassociatedTicketBacklogsTicketStatusesQuery = this.injector.get(FindUnassociatedTicketBacklogsTicketStatusesDocument);
		this.insertTicketStatusesMutation = this.injector.get(InsertTicketStatusesDocument);
		this.updateTicketStatusesMutation = this.injector.get(UpdateTicketStatusesDocument);
		this.importDataTicketStatusesMutation = this.injector.get(ImportDataTicketStatusesDocument);
		this.updateManyTicketStatusesMutation = this.injector.get(UpdateManyTicketStatusesDocument);
		this.deleteTicketStatusesMutation = this.injector.get(DeleteTicketStatusesDocument);
		this.restoreTicketStatusesMutation = this.injector.get(RestoreTicketStatusesDocument);
		this.recycleTicketStatusesMutation = this.injector.get(RecycleTicketStatusesDocument);
		this.restoreArchivedTicketStatusesMutation = this.injector.get(RestoreArchivedTicketStatusesDocument);
		this.archivedTicketStatusesMutation = this.injector.get(ArchivedTicketStatusesDocument);
		this.addFileDynamicFieldTicketStatusesMutation = this.injector.get(AddFileDynamicFieldTicketStatusesDocument);
		this.removeFileDynamicFieldTicketStatusesMutation = this.injector.get(RemoveFileDynamicFieldTicketStatusesDocument);
		this.addTicketBacklogsTicketStatusesMutation = this.injector.get(AddTicketBacklogsTicketStatusesDocument);
		this.removeTicketBacklogsTicketStatusesMutation = this.injector.get(RemoveTicketBacklogsTicketStatusesDocument);
		this.addTicketsTicketStatusesMutation = this.injector.get(AddTicketsTicketStatusesDocument);
		this.removeTicketsTicketStatusesMutation = this.injector.get(RemoveTicketsTicketStatusesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatus> {
    
    		let variables: GetTicketStatusesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.get));
            }
            else{
                let result:ServiceSingleResultOfTicketStatus={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatus> {
    
    		let variables: FirstTicketStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: FindTicketStatusesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: SearchTicketStatusesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketStatusesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: CustomQueryTicketStatusesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: CustomQueryIdTicketStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByWorkflow(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('qualification?') qualification?: string,
		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: FindByWorkflowTicketStatusesBaseVariables = {
    			type: type,
			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findByWorkflowTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findByWorkflow));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketStatusesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: FindRecyclesTicketStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTicketStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTicketStatusesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatus,
		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatus> {
    
    		let variables: FindArchivedTicketStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTicketStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTicketStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsTicketStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findUnassociatedTickets));
    	}

	@InjectArgs
	public findAssociatedTicketBacklogs(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketBacklog,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicketBacklog> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ticketBacklogs', fields, null, [
			GqlSubFieldArg.create('filterOfTicketBacklogs', 'filter'),
			GqlSubFieldArg.create('optionsOfTicketBacklogs', 'options'),
		]),
		])
		extendedVariables['filterOfTicketBacklogs'] = filter;
		extendedVariables['optionsOfTicketBacklogs'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ticketBacklogs));
            }
            else{
                let result: ServiceListResultOfTicketBacklog = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTicketBacklogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketBacklog> {
    
    		let variables: FindUnassociatedTicketBacklogsTicketStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketBacklogsTicketStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.findUnassociatedTicketBacklogs));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketStatusInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatus> {
    
    		let variables: InsertTicketStatusesBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatus> {
    
    		let variables: UpdateTicketStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketStatusesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketStatusesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTicketStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTicketStatusesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTicketStatusesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addTicketBacklogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketBacklogIds') ticketBacklogIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketBacklogsTicketStatusesBaseVariables = {
    			id: id,
			ticketBacklogIds: ticketBacklogIds
    		}
    				return this.addTicketBacklogsTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.addTicketBacklogs));
    	}

    	/**  */
    	@InjectArgs
    	public removeTicketBacklogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketBacklogIds') ticketBacklogIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketBacklogsTicketStatusesBaseVariables = {
    			ticketBacklogIds: ticketBacklogIds
    		}
    				return this.removeTicketBacklogsTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.removeTicketBacklogs));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsTicketStatusesBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsTicketStatusesBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsTicketStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatuses.removeTickets));
    	}
    
}