import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLoanAppointmentsBaseVariables, FirstLoanAppointmentsBaseVariables, CountLoanAppointmentsBaseVariables, FindLoanAppointmentsBaseVariables, SearchLoanAppointmentsBaseVariables, ExportSchemaLoanAppointmentsBaseVariables, ExportDataLoanAppointmentsBaseVariables, CustomQueryLoanAppointmentsBaseVariables, CustomQueryIdLoanAppointmentsBaseVariables, UsedLoanAppointmentsBaseVariables, ExistLoanAppointmentsBaseVariables, InsertLoanAppointmentsBaseVariables, UpdateLoanAppointmentsBaseVariables, ImportDataLoanAppointmentsBaseVariables, UpdateManyLoanAppointmentsBaseVariables, DeleteLoanAppointmentsBaseVariables } from '../gqls'
import { GetLoanAppointmentsDocument, FirstLoanAppointmentsDocument, CountLoanAppointmentsDocument, FindLoanAppointmentsDocument, SearchLoanAppointmentsDocument, ExportSchemaLoanAppointmentsDocument, ExportDataLoanAppointmentsDocument, CustomQueryLoanAppointmentsDocument, CustomQueryIdLoanAppointmentsDocument, UsedLoanAppointmentsDocument, ExistLoanAppointmentsDocument, InsertLoanAppointmentsDocument, UpdateLoanAppointmentsDocument, ImportDataLoanAppointmentsDocument, UpdateManyLoanAppointmentsDocument, DeleteLoanAppointmentsDocument } from '../gqls'
import { ServiceSingleResultOfLoanAppointment, QueryContextOfLoanAppointment, FilterOfLoanAppointment, ServiceSingleResultOfInt64, ServiceListResultOfLoanAppointment, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LoanAppointmentInput, FieldUpdateOperatorOfLoanAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LoanAppointmentBaseService {
    
public modelName = 'loanAppointment';
public modelPluralName = 'loanAppointments';

	private getLoanAppointmentsQuery: GetLoanAppointmentsDocument;
	private firstLoanAppointmentsQuery: FirstLoanAppointmentsDocument;
	private countLoanAppointmentsQuery: CountLoanAppointmentsDocument;
	private findLoanAppointmentsQuery: FindLoanAppointmentsDocument;
	private searchLoanAppointmentsQuery: SearchLoanAppointmentsDocument;
	private exportSchemaLoanAppointmentsQuery: ExportSchemaLoanAppointmentsDocument;
	private exportDataLoanAppointmentsQuery: ExportDataLoanAppointmentsDocument;
	private customQueryLoanAppointmentsQuery: CustomQueryLoanAppointmentsDocument;
	private customQueryIdLoanAppointmentsQuery: CustomQueryIdLoanAppointmentsDocument;
	private usedLoanAppointmentsQuery: UsedLoanAppointmentsDocument;
	private existLoanAppointmentsQuery: ExistLoanAppointmentsDocument;
	private insertLoanAppointmentsMutation: InsertLoanAppointmentsDocument;
	private updateLoanAppointmentsMutation: UpdateLoanAppointmentsDocument;
	private importDataLoanAppointmentsMutation: ImportDataLoanAppointmentsDocument;
	private updateManyLoanAppointmentsMutation: UpdateManyLoanAppointmentsDocument;
	private deleteLoanAppointmentsMutation: DeleteLoanAppointmentsDocument;

	constructor(private injector: Injector) {
		this.getLoanAppointmentsQuery = this.injector.get(GetLoanAppointmentsDocument);
		this.firstLoanAppointmentsQuery = this.injector.get(FirstLoanAppointmentsDocument);
		this.countLoanAppointmentsQuery = this.injector.get(CountLoanAppointmentsDocument);
		this.findLoanAppointmentsQuery = this.injector.get(FindLoanAppointmentsDocument);
		this.searchLoanAppointmentsQuery = this.injector.get(SearchLoanAppointmentsDocument);
		this.exportSchemaLoanAppointmentsQuery = this.injector.get(ExportSchemaLoanAppointmentsDocument);
		this.exportDataLoanAppointmentsQuery = this.injector.get(ExportDataLoanAppointmentsDocument);
		this.customQueryLoanAppointmentsQuery = this.injector.get(CustomQueryLoanAppointmentsDocument);
		this.customQueryIdLoanAppointmentsQuery = this.injector.get(CustomQueryIdLoanAppointmentsDocument);
		this.usedLoanAppointmentsQuery = this.injector.get(UsedLoanAppointmentsDocument);
		this.existLoanAppointmentsQuery = this.injector.get(ExistLoanAppointmentsDocument);
		this.insertLoanAppointmentsMutation = this.injector.get(InsertLoanAppointmentsDocument);
		this.updateLoanAppointmentsMutation = this.injector.get(UpdateLoanAppointmentsDocument);
		this.importDataLoanAppointmentsMutation = this.injector.get(ImportDataLoanAppointmentsDocument);
		this.updateManyLoanAppointmentsMutation = this.injector.get(UpdateManyLoanAppointmentsDocument);
		this.deleteLoanAppointmentsMutation = this.injector.get(DeleteLoanAppointmentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanAppointment> {
    
    		let variables: GetLoanAppointmentsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.get));
            }
            else{
                let result:ServiceSingleResultOfLoanAppointment={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanAppointment> {
    
    		let variables: FirstLoanAppointmentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLoanAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.countLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanAppointment> {
    
    		let variables: FindLoanAppointmentsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanAppointment> {
    
    		let variables: SearchLoanAppointmentsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLoanAppointmentsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLoanAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanAppointment> {
    
    		let variables: CustomQueryLoanAppointmentsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLoanAppointment,
		@Args('filter?') filter?: FilterOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLoanAppointment> {
    
    		let variables: CustomQueryIdLoanAppointmentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLoanAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLoanAppointmentsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLoanAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LoanAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanAppointment> {
    
    		let variables: InsertLoanAppointmentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLoanAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLoanAppointment> {
    
    		let variables: UpdateLoanAppointmentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLoanAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLoanAppointmentsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLoanAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLoanAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLoanAppointmentsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLoanAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLoanAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLoanAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.loanAppointments.delete));
    	}
    
}