import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDeleteFileTasksBaseVariables, FirstDeleteFileTasksBaseVariables, CountDeleteFileTasksBaseVariables, FindDeleteFileTasksBaseVariables, SearchDeleteFileTasksBaseVariables, ExportSchemaDeleteFileTasksBaseVariables, ExportDataDeleteFileTasksBaseVariables, CustomQueryDeleteFileTasksBaseVariables, CustomQueryIdDeleteFileTasksBaseVariables, UsedDeleteFileTasksBaseVariables, ExistDeleteFileTasksBaseVariables, InsertDeleteFileTasksBaseVariables, UpdateDeleteFileTasksBaseVariables, ImportDataDeleteFileTasksBaseVariables, UpdateManyDeleteFileTasksBaseVariables, DeleteDeleteFileTasksBaseVariables } from '../gqls'
import { GetDeleteFileTasksDocument, FirstDeleteFileTasksDocument, CountDeleteFileTasksDocument, FindDeleteFileTasksDocument, SearchDeleteFileTasksDocument, ExportSchemaDeleteFileTasksDocument, ExportDataDeleteFileTasksDocument, CustomQueryDeleteFileTasksDocument, CustomQueryIdDeleteFileTasksDocument, UsedDeleteFileTasksDocument, ExistDeleteFileTasksDocument, InsertDeleteFileTasksDocument, UpdateDeleteFileTasksDocument, ImportDataDeleteFileTasksDocument, UpdateManyDeleteFileTasksDocument, DeleteDeleteFileTasksDocument } from '../gqls'
import { ServiceSingleResultOfDeleteFileTask, QueryContextOfDeleteFileTask, FilterOfDeleteFileTask, ServiceSingleResultOfInt64, ServiceListResultOfDeleteFileTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, DeleteFileTaskInput, FieldUpdateOperatorOfDeleteFileTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DeleteFileTaskBaseService {
    
public modelName = 'deleteFileTask';
public modelPluralName = 'deleteFileTasks';

	private getDeleteFileTasksQuery: GetDeleteFileTasksDocument;
	private firstDeleteFileTasksQuery: FirstDeleteFileTasksDocument;
	private countDeleteFileTasksQuery: CountDeleteFileTasksDocument;
	private findDeleteFileTasksQuery: FindDeleteFileTasksDocument;
	private searchDeleteFileTasksQuery: SearchDeleteFileTasksDocument;
	private exportSchemaDeleteFileTasksQuery: ExportSchemaDeleteFileTasksDocument;
	private exportDataDeleteFileTasksQuery: ExportDataDeleteFileTasksDocument;
	private customQueryDeleteFileTasksQuery: CustomQueryDeleteFileTasksDocument;
	private customQueryIdDeleteFileTasksQuery: CustomQueryIdDeleteFileTasksDocument;
	private usedDeleteFileTasksQuery: UsedDeleteFileTasksDocument;
	private existDeleteFileTasksQuery: ExistDeleteFileTasksDocument;
	private insertDeleteFileTasksMutation: InsertDeleteFileTasksDocument;
	private updateDeleteFileTasksMutation: UpdateDeleteFileTasksDocument;
	private importDataDeleteFileTasksMutation: ImportDataDeleteFileTasksDocument;
	private updateManyDeleteFileTasksMutation: UpdateManyDeleteFileTasksDocument;
	private deleteDeleteFileTasksMutation: DeleteDeleteFileTasksDocument;

	constructor(private injector: Injector) {
		this.getDeleteFileTasksQuery = this.injector.get(GetDeleteFileTasksDocument);
		this.firstDeleteFileTasksQuery = this.injector.get(FirstDeleteFileTasksDocument);
		this.countDeleteFileTasksQuery = this.injector.get(CountDeleteFileTasksDocument);
		this.findDeleteFileTasksQuery = this.injector.get(FindDeleteFileTasksDocument);
		this.searchDeleteFileTasksQuery = this.injector.get(SearchDeleteFileTasksDocument);
		this.exportSchemaDeleteFileTasksQuery = this.injector.get(ExportSchemaDeleteFileTasksDocument);
		this.exportDataDeleteFileTasksQuery = this.injector.get(ExportDataDeleteFileTasksDocument);
		this.customQueryDeleteFileTasksQuery = this.injector.get(CustomQueryDeleteFileTasksDocument);
		this.customQueryIdDeleteFileTasksQuery = this.injector.get(CustomQueryIdDeleteFileTasksDocument);
		this.usedDeleteFileTasksQuery = this.injector.get(UsedDeleteFileTasksDocument);
		this.existDeleteFileTasksQuery = this.injector.get(ExistDeleteFileTasksDocument);
		this.insertDeleteFileTasksMutation = this.injector.get(InsertDeleteFileTasksDocument);
		this.updateDeleteFileTasksMutation = this.injector.get(UpdateDeleteFileTasksDocument);
		this.importDataDeleteFileTasksMutation = this.injector.get(ImportDataDeleteFileTasksDocument);
		this.updateManyDeleteFileTasksMutation = this.injector.get(UpdateManyDeleteFileTasksDocument);
		this.deleteDeleteFileTasksMutation = this.injector.get(DeleteDeleteFileTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDeleteFileTask> {
    
    		let variables: GetDeleteFileTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.get));
            }
            else{
                let result:ServiceSingleResultOfDeleteFileTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDeleteFileTask,
		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDeleteFileTask> {
    
    		let variables: FirstDeleteFileTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDeleteFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDeleteFileTask,
		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDeleteFileTask> {
    
    		let variables: FindDeleteFileTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDeleteFileTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDeleteFileTask> {
    
    		let variables: SearchDeleteFileTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDeleteFileTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDeleteFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDeleteFileTask,
		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDeleteFileTask> {
    
    		let variables: CustomQueryDeleteFileTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDeleteFileTask,
		@Args('filter?') filter?: FilterOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDeleteFileTask> {
    
    		let variables: CustomQueryIdDeleteFileTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDeleteFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDeleteFileTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDeleteFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DeleteFileTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDeleteFileTask> {
    
    		let variables: InsertDeleteFileTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertDeleteFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDeleteFileTask> {
    
    		let variables: UpdateDeleteFileTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDeleteFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDeleteFileTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDeleteFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDeleteFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDeleteFileTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDeleteFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDeleteFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDeleteFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.deleteFileTasks.delete));
    	}
    
}