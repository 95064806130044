import { Component, Input, forwardRef } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';

/** Représente un composant de note dans un formulaire celui ci est afficher a titre indicatif mais n'est pas envoyer coté serveur */
@Component({
  selector: 'clc-memo-area',
  templateUrl: './memo-area.component.html',
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreMemoAreaComponent),
      multi: true,
    },
  ],
})
export class CoreMemoAreaComponent {
  @Input() value: any;
  @Input() height: any;
  @Input() contentType: any;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  constructor() {}

  /** @inheritdoc */
  writeValue(obj: any): void {
    if (obj != undefined) {
      this.value = obj;
    }
  }
  /** @inheritdoc */
  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  public registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
