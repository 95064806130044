import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetComputerSystemsBaseVariables, FirstComputerSystemsBaseVariables, CountComputerSystemsBaseVariables, FindComputerSystemsBaseVariables, SearchComputerSystemsBaseVariables, ExportSchemaComputerSystemsBaseVariables, ExportDataComputerSystemsBaseVariables, CustomQueryComputerSystemsBaseVariables, CustomQueryIdComputerSystemsBaseVariables, UsedComputerSystemsBaseVariables, ExistComputerSystemsBaseVariables } from '../gqls'
import { GetComputerSystemsDocument, FirstComputerSystemsDocument, CountComputerSystemsDocument, FindComputerSystemsDocument, SearchComputerSystemsDocument, ExportSchemaComputerSystemsDocument, ExportDataComputerSystemsDocument, CustomQueryComputerSystemsDocument, CustomQueryIdComputerSystemsDocument, UsedComputerSystemsDocument, ExistComputerSystemsDocument } from '../gqls'
import { ServiceSingleResultOfComputerSystem, QueryContextOfComputerSystem, FilterOfComputerSystem, ServiceSingleResultOfInt64, ServiceListResultOfComputerSystem, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ComputerSystemBaseService {
    
public modelName = 'computerSystem';
public modelPluralName = 'computerSystems';

	private getComputerSystemsQuery: GetComputerSystemsDocument;
	private firstComputerSystemsQuery: FirstComputerSystemsDocument;
	private countComputerSystemsQuery: CountComputerSystemsDocument;
	private findComputerSystemsQuery: FindComputerSystemsDocument;
	private searchComputerSystemsQuery: SearchComputerSystemsDocument;
	private exportSchemaComputerSystemsQuery: ExportSchemaComputerSystemsDocument;
	private exportDataComputerSystemsQuery: ExportDataComputerSystemsDocument;
	private customQueryComputerSystemsQuery: CustomQueryComputerSystemsDocument;
	private customQueryIdComputerSystemsQuery: CustomQueryIdComputerSystemsDocument;
	private usedComputerSystemsQuery: UsedComputerSystemsDocument;
	private existComputerSystemsQuery: ExistComputerSystemsDocument;

	constructor(private injector: Injector) {
		this.getComputerSystemsQuery = this.injector.get(GetComputerSystemsDocument);
		this.firstComputerSystemsQuery = this.injector.get(FirstComputerSystemsDocument);
		this.countComputerSystemsQuery = this.injector.get(CountComputerSystemsDocument);
		this.findComputerSystemsQuery = this.injector.get(FindComputerSystemsDocument);
		this.searchComputerSystemsQuery = this.injector.get(SearchComputerSystemsDocument);
		this.exportSchemaComputerSystemsQuery = this.injector.get(ExportSchemaComputerSystemsDocument);
		this.exportDataComputerSystemsQuery = this.injector.get(ExportDataComputerSystemsDocument);
		this.customQueryComputerSystemsQuery = this.injector.get(CustomQueryComputerSystemsDocument);
		this.customQueryIdComputerSystemsQuery = this.injector.get(CustomQueryIdComputerSystemsDocument);
		this.usedComputerSystemsQuery = this.injector.get(UsedComputerSystemsDocument);
		this.existComputerSystemsQuery = this.injector.get(ExistComputerSystemsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfComputerSystem> {
    
    		let variables: GetComputerSystemsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.get));
            }
            else{
                let result:ServiceSingleResultOfComputerSystem={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfComputerSystem,
		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfComputerSystem> {
    
    		let variables: FirstComputerSystemsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountComputerSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.countComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfComputerSystem,
		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfComputerSystem> {
    
    		let variables: FindComputerSystemsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfComputerSystem,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfComputerSystem> {
    
    		let variables: SearchComputerSystemsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaComputerSystemsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataComputerSystemsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfComputerSystem,
		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfComputerSystem> {
    
    		let variables: CustomQueryComputerSystemsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfComputerSystem,
		@Args('filter?') filter?: FilterOfComputerSystem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfComputerSystem> {
    
    		let variables: CustomQueryIdComputerSystemsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedComputerSystemsBaseVariables = {
    			ids: ids
    		}
    				return this.usedComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistComputerSystemsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existComputerSystemsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.computerSystems.exist));
    	}
    
    
}