import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTrackingEntitiesBaseVariables, FirstTrackingEntitiesBaseVariables, CountTrackingEntitiesBaseVariables, FindTrackingEntitiesBaseVariables, SearchTrackingEntitiesBaseVariables, ExportSchemaTrackingEntitiesBaseVariables, ExportDataTrackingEntitiesBaseVariables, CustomQueryTrackingEntitiesBaseVariables, CustomQueryIdTrackingEntitiesBaseVariables, UsedTrackingEntitiesBaseVariables, ExistTrackingEntitiesBaseVariables, InsertTrackingEntitiesBaseVariables, UpdateTrackingEntitiesBaseVariables, ImportDataTrackingEntitiesBaseVariables, UpdateManyTrackingEntitiesBaseVariables, DeleteTrackingEntitiesBaseVariables } from '../gqls'
import { GetTrackingEntitiesDocument, FirstTrackingEntitiesDocument, CountTrackingEntitiesDocument, FindTrackingEntitiesDocument, SearchTrackingEntitiesDocument, ExportSchemaTrackingEntitiesDocument, ExportDataTrackingEntitiesDocument, CustomQueryTrackingEntitiesDocument, CustomQueryIdTrackingEntitiesDocument, UsedTrackingEntitiesDocument, ExistTrackingEntitiesDocument, InsertTrackingEntitiesDocument, UpdateTrackingEntitiesDocument, ImportDataTrackingEntitiesDocument, UpdateManyTrackingEntitiesDocument, DeleteTrackingEntitiesDocument } from '../gqls'
import { ServiceSingleResultOfTrackingEntity, QueryContextOfTrackingEntity, FilterOfTrackingEntity, ServiceSingleResultOfInt64, ServiceListResultOfTrackingEntity, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TrackingEntityInput, FieldUpdateOperatorOfTrackingEntity } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TrackingEntityBaseService {
    
public modelName = 'trackingEntity';
public modelPluralName = 'trackingEntities';

	private getTrackingEntitiesQuery: GetTrackingEntitiesDocument;
	private firstTrackingEntitiesQuery: FirstTrackingEntitiesDocument;
	private countTrackingEntitiesQuery: CountTrackingEntitiesDocument;
	private findTrackingEntitiesQuery: FindTrackingEntitiesDocument;
	private searchTrackingEntitiesQuery: SearchTrackingEntitiesDocument;
	private exportSchemaTrackingEntitiesQuery: ExportSchemaTrackingEntitiesDocument;
	private exportDataTrackingEntitiesQuery: ExportDataTrackingEntitiesDocument;
	private customQueryTrackingEntitiesQuery: CustomQueryTrackingEntitiesDocument;
	private customQueryIdTrackingEntitiesQuery: CustomQueryIdTrackingEntitiesDocument;
	private usedTrackingEntitiesQuery: UsedTrackingEntitiesDocument;
	private existTrackingEntitiesQuery: ExistTrackingEntitiesDocument;
	private insertTrackingEntitiesMutation: InsertTrackingEntitiesDocument;
	private updateTrackingEntitiesMutation: UpdateTrackingEntitiesDocument;
	private importDataTrackingEntitiesMutation: ImportDataTrackingEntitiesDocument;
	private updateManyTrackingEntitiesMutation: UpdateManyTrackingEntitiesDocument;
	private deleteTrackingEntitiesMutation: DeleteTrackingEntitiesDocument;

	constructor(private injector: Injector) {
		this.getTrackingEntitiesQuery = this.injector.get(GetTrackingEntitiesDocument);
		this.firstTrackingEntitiesQuery = this.injector.get(FirstTrackingEntitiesDocument);
		this.countTrackingEntitiesQuery = this.injector.get(CountTrackingEntitiesDocument);
		this.findTrackingEntitiesQuery = this.injector.get(FindTrackingEntitiesDocument);
		this.searchTrackingEntitiesQuery = this.injector.get(SearchTrackingEntitiesDocument);
		this.exportSchemaTrackingEntitiesQuery = this.injector.get(ExportSchemaTrackingEntitiesDocument);
		this.exportDataTrackingEntitiesQuery = this.injector.get(ExportDataTrackingEntitiesDocument);
		this.customQueryTrackingEntitiesQuery = this.injector.get(CustomQueryTrackingEntitiesDocument);
		this.customQueryIdTrackingEntitiesQuery = this.injector.get(CustomQueryIdTrackingEntitiesDocument);
		this.usedTrackingEntitiesQuery = this.injector.get(UsedTrackingEntitiesDocument);
		this.existTrackingEntitiesQuery = this.injector.get(ExistTrackingEntitiesDocument);
		this.insertTrackingEntitiesMutation = this.injector.get(InsertTrackingEntitiesDocument);
		this.updateTrackingEntitiesMutation = this.injector.get(UpdateTrackingEntitiesDocument);
		this.importDataTrackingEntitiesMutation = this.injector.get(ImportDataTrackingEntitiesDocument);
		this.updateManyTrackingEntitiesMutation = this.injector.get(UpdateManyTrackingEntitiesDocument);
		this.deleteTrackingEntitiesMutation = this.injector.get(DeleteTrackingEntitiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTrackingEntity> {
    
    		let variables: GetTrackingEntitiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.get));
            }
            else{
                let result:ServiceSingleResultOfTrackingEntity={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTrackingEntity,
		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTrackingEntity> {
    
    		let variables: FirstTrackingEntitiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTrackingEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.countTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTrackingEntity,
		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTrackingEntity> {
    
    		let variables: FindTrackingEntitiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTrackingEntity,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTrackingEntity> {
    
    		let variables: SearchTrackingEntitiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTrackingEntitiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTrackingEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTrackingEntity,
		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTrackingEntity> {
    
    		let variables: CustomQueryTrackingEntitiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTrackingEntity,
		@Args('filter?') filter?: FilterOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTrackingEntity> {
    
    		let variables: CustomQueryIdTrackingEntitiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTrackingEntitiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTrackingEntitiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTrackingEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TrackingEntityInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTrackingEntity> {
    
    		let variables: InsertTrackingEntitiesBaseVariables = {
    			entity: entity
    		}
    				return this.insertTrackingEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTrackingEntity> {
    
    		let variables: UpdateTrackingEntitiesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTrackingEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTrackingEntitiesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTrackingEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTrackingEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTrackingEntitiesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTrackingEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTrackingEntitiesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTrackingEntitiesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackingEntities.delete));
    	}
    
}