import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEmailPrototypeLogsBaseVariables, FirstEmailPrototypeLogsBaseVariables, CountEmailPrototypeLogsBaseVariables, FindEmailPrototypeLogsBaseVariables, SearchEmailPrototypeLogsBaseVariables, ExportSchemaEmailPrototypeLogsBaseVariables, ExportDataEmailPrototypeLogsBaseVariables, CustomQueryEmailPrototypeLogsBaseVariables, CustomQueryIdEmailPrototypeLogsBaseVariables, UsedEmailPrototypeLogsBaseVariables, FindByConnectorEmailPrototypeLogsBaseVariables, FindByTypeEmailPrototypeLogsBaseVariables, CountByTypeEmailPrototypeLogsBaseVariables, FindInformationOnEmailEmailPrototypeLogsBaseVariables, PurgeTicketEmailPrototypeLogEmailPrototypeLogsBaseVariables, ExistEmailPrototypeLogsBaseVariables, InsertEmailPrototypeLogsBaseVariables, UpdateEmailPrototypeLogsBaseVariables, ImportDataEmailPrototypeLogsBaseVariables, UpdateManyEmailPrototypeLogsBaseVariables, DeleteEmailPrototypeLogsBaseVariables } from '../gqls'
import { GetEmailPrototypeLogsDocument, FirstEmailPrototypeLogsDocument, CountEmailPrototypeLogsDocument, FindEmailPrototypeLogsDocument, SearchEmailPrototypeLogsDocument, ExportSchemaEmailPrototypeLogsDocument, ExportDataEmailPrototypeLogsDocument, CustomQueryEmailPrototypeLogsDocument, CustomQueryIdEmailPrototypeLogsDocument, UsedEmailPrototypeLogsDocument, FindByConnectorEmailPrototypeLogsDocument, FindByTypeEmailPrototypeLogsDocument, CountByTypeEmailPrototypeLogsDocument, FindInformationOnEmailEmailPrototypeLogsDocument, PurgeTicketEmailPrototypeLogEmailPrototypeLogsDocument, ExistEmailPrototypeLogsDocument, InsertEmailPrototypeLogsDocument, UpdateEmailPrototypeLogsDocument, ImportDataEmailPrototypeLogsDocument, UpdateManyEmailPrototypeLogsDocument, DeleteEmailPrototypeLogsDocument } from '../gqls'
import { ServiceSingleResultOfEmailPrototypeLog, QueryContextOfEmailPrototypeLog, FilterOfEmailPrototypeLog, ServiceSingleResultOfInt64, ServiceListResultOfEmailPrototypeLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfEmailPrototypeLogQueriesResolver, EmailPrototypeLogInput, FieldUpdateOperatorOfEmailPrototypeLog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EmailPrototypeLogBaseService {
    
public modelName = 'emailPrototypeLog';
public modelPluralName = 'emailPrototypeLogs';

	private getEmailPrototypeLogsQuery: GetEmailPrototypeLogsDocument;
	private firstEmailPrototypeLogsQuery: FirstEmailPrototypeLogsDocument;
	private countEmailPrototypeLogsQuery: CountEmailPrototypeLogsDocument;
	private findEmailPrototypeLogsQuery: FindEmailPrototypeLogsDocument;
	private searchEmailPrototypeLogsQuery: SearchEmailPrototypeLogsDocument;
	private exportSchemaEmailPrototypeLogsQuery: ExportSchemaEmailPrototypeLogsDocument;
	private exportDataEmailPrototypeLogsQuery: ExportDataEmailPrototypeLogsDocument;
	private customQueryEmailPrototypeLogsQuery: CustomQueryEmailPrototypeLogsDocument;
	private customQueryIdEmailPrototypeLogsQuery: CustomQueryIdEmailPrototypeLogsDocument;
	private usedEmailPrototypeLogsQuery: UsedEmailPrototypeLogsDocument;
	private findByConnectorEmailPrototypeLogsQuery: FindByConnectorEmailPrototypeLogsDocument;
	private findByTypeEmailPrototypeLogsQuery: FindByTypeEmailPrototypeLogsDocument;
	private countByTypeEmailPrototypeLogsQuery: CountByTypeEmailPrototypeLogsDocument;
	private findInformationOnEmailEmailPrototypeLogsQuery: FindInformationOnEmailEmailPrototypeLogsDocument;
	private purgeTicketEmailPrototypeLogEmailPrototypeLogsQuery: PurgeTicketEmailPrototypeLogEmailPrototypeLogsDocument;
	private existEmailPrototypeLogsQuery: ExistEmailPrototypeLogsDocument;
	private insertEmailPrototypeLogsMutation: InsertEmailPrototypeLogsDocument;
	private updateEmailPrototypeLogsMutation: UpdateEmailPrototypeLogsDocument;
	private importDataEmailPrototypeLogsMutation: ImportDataEmailPrototypeLogsDocument;
	private updateManyEmailPrototypeLogsMutation: UpdateManyEmailPrototypeLogsDocument;
	private deleteEmailPrototypeLogsMutation: DeleteEmailPrototypeLogsDocument;

	constructor(private injector: Injector) {
		this.getEmailPrototypeLogsQuery = this.injector.get(GetEmailPrototypeLogsDocument);
		this.firstEmailPrototypeLogsQuery = this.injector.get(FirstEmailPrototypeLogsDocument);
		this.countEmailPrototypeLogsQuery = this.injector.get(CountEmailPrototypeLogsDocument);
		this.findEmailPrototypeLogsQuery = this.injector.get(FindEmailPrototypeLogsDocument);
		this.searchEmailPrototypeLogsQuery = this.injector.get(SearchEmailPrototypeLogsDocument);
		this.exportSchemaEmailPrototypeLogsQuery = this.injector.get(ExportSchemaEmailPrototypeLogsDocument);
		this.exportDataEmailPrototypeLogsQuery = this.injector.get(ExportDataEmailPrototypeLogsDocument);
		this.customQueryEmailPrototypeLogsQuery = this.injector.get(CustomQueryEmailPrototypeLogsDocument);
		this.customQueryIdEmailPrototypeLogsQuery = this.injector.get(CustomQueryIdEmailPrototypeLogsDocument);
		this.usedEmailPrototypeLogsQuery = this.injector.get(UsedEmailPrototypeLogsDocument);
		this.findByConnectorEmailPrototypeLogsQuery = this.injector.get(FindByConnectorEmailPrototypeLogsDocument);
		this.findByTypeEmailPrototypeLogsQuery = this.injector.get(FindByTypeEmailPrototypeLogsDocument);
		this.countByTypeEmailPrototypeLogsQuery = this.injector.get(CountByTypeEmailPrototypeLogsDocument);
		this.findInformationOnEmailEmailPrototypeLogsQuery = this.injector.get(FindInformationOnEmailEmailPrototypeLogsDocument);
		this.purgeTicketEmailPrototypeLogEmailPrototypeLogsQuery = this.injector.get(PurgeTicketEmailPrototypeLogEmailPrototypeLogsDocument);
		this.existEmailPrototypeLogsQuery = this.injector.get(ExistEmailPrototypeLogsDocument);
		this.insertEmailPrototypeLogsMutation = this.injector.get(InsertEmailPrototypeLogsDocument);
		this.updateEmailPrototypeLogsMutation = this.injector.get(UpdateEmailPrototypeLogsDocument);
		this.importDataEmailPrototypeLogsMutation = this.injector.get(ImportDataEmailPrototypeLogsDocument);
		this.updateManyEmailPrototypeLogsMutation = this.injector.get(UpdateManyEmailPrototypeLogsDocument);
		this.deleteEmailPrototypeLogsMutation = this.injector.get(DeleteEmailPrototypeLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailPrototypeLog> {
    
    		let variables: GetEmailPrototypeLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.get));
            }
            else{
                let result:ServiceSingleResultOfEmailPrototypeLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailPrototypeLog> {
    
    		let variables: FirstEmailPrototypeLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEmailPrototypeLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: FindEmailPrototypeLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: SearchEmailPrototypeLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEmailPrototypeLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEmailPrototypeLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: CustomQueryEmailPrototypeLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: CustomQueryIdEmailPrototypeLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEmailPrototypeLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByConnector(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: FindByConnectorEmailPrototypeLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findByConnectorEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.findByConnector));
    	}

    	/**  */
    	@InjectArgs
    	public findByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: FindByTypeEmailPrototypeLogsBaseVariables = {
    			type: type,
			filter: filter,
			options: options
    		}
    				return this.findByTypeEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.findByType));
    	}

    	/**  */
    	@InjectArgs
    	public countByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfEmailPrototypeLogQueriesResolver,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountByTypeEmailPrototypeLogsBaseVariables = {
    			type: type,
			filter: filter,
			options: options
    		}
    				return this.countByTypeEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.countByType));
    	}

    	/**  */
    	@InjectArgs
    	public findInformationOnEmail(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: FindInformationOnEmailEmailPrototypeLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findInformationOnEmailEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.findInformationOnEmail));
    	}

    	/** Purge les journaux sortant */
    	@InjectArgs
    	public purgeTicketEmailPrototypeLog(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: Array<Maybe<string>>,
		@Args('start?') start?: Date,
		@Args('end?') end?: Date,
		@Args('action?') action?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: PurgeTicketEmailPrototypeLogEmailPrototypeLogsBaseVariables = {
    			type: type,
			action: action,
			start: start,
			end: end
    		}
    				return this.purgeTicketEmailPrototypeLogEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.purgeTicketEmailPrototypeLog));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEmailPrototypeLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEmailPrototypeLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: EmailPrototypeLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailPrototypeLog> {
    
    		let variables: InsertEmailPrototypeLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertEmailPrototypeLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailPrototypeLog> {
    
    		let variables: UpdateEmailPrototypeLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateEmailPrototypeLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataEmailPrototypeLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataEmailPrototypeLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEmailPrototypeLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEmailPrototypeLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEmailPrototypeLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEmailPrototypeLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailPrototypeLogs.delete));
    	}
    
}