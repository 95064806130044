<dx-data-grid
  id="gridContainer"
  [dataSource]="values"
  [showColumnHeaders]="true"
  [showBorders]="false"
  (onCellPrepared)="onCellPrepared($event)"
  (onSaved)="onSaved($event)"
  (onRowUpdated)="onRowUpdated($event)"
  [allowColumnResizing]="true"
  columnResizingMode="widget"
  [columnAutoWidth]="true"
  [showBorders]="true"  
  width="100%"
  >
>
  <dxo-scrolling [useNative]="true" mode="standard"> </dxo-scrolling>

  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    [allowUpdating]="true"
    mode="cell"
    [allowDeleting]="false"
    [allowAdding]="false"
  >
  </dxo-editing>

  <dxi-column
    [dataField]="'title'"
    [caption]="'entities/designerRule/title' | translate"
    [allowEditing]="false"
    [allowResizing]="true"
    cellTemplate="designerCellTemplate"
  >
    <div *dxTemplate="let cellInfo of 'designerCellTemplate'">
      {{ printTitle(cellInfo)}}
    </div>
  </dxi-column>
  <dxi-column
    [dataField]="'visible'"
    [caption]="'entities/designerRule/visible' | translate"
    [allowEditing]="true"
    [setCellValue]="setCellValue"
    [showEditorAlways]="true"
    [allowResizing]="true"
    [editCellTemplate]="'1checkBoxEditCellTemplate'"
  >
    <div *dxTemplate="let cellInfo of '1checkBoxEditCellTemplate'">
      <dx-check-box
        [(value)]="cellInfo.value"
        (onValueChanged)="checkBoxValueChanged(cellInfo, $event)"
        style="padding-top: 15px; padding-bottom: 15px"
      ></dx-check-box>
    </div>
  </dxi-column>
  <dxi-column
    [dataField]="'editable'"
    [caption]="'entities/designerRule/editable' | translate"
    [allowEditing]="true"
    [setCellValue]="setCellValue"
    [showEditorAlways]="true"
    [allowResizing]="true"
    [editCellTemplate]="'2checkBoxEditCellTemplate'"
  >
    <div *dxTemplate="let cellInfo of '2checkBoxEditCellTemplate'">
      <dx-check-box
        *ngIf="cellInfo.data.option.allowEdit"
        [(value)]="cellInfo.value"
        (onValueChanged)="checkBoxValueChanged(cellInfo, $event)"
      ></dx-check-box>
    </div>
  </dxi-column>
  <dxi-column
    [dataField]="'required'"
    [allowResizing]="true"
    [caption]="'entities/designerRule/required' | translate"
    [allowEditing]="true"
    [setCellValue]="setCellValue"
    [showEditorAlways]="true"
    [editCellTemplate]="'3checkBoxEditCellTemplate'"
  >
    <div *dxTemplate="let cellInfo of '3checkBoxEditCellTemplate'">
      <dx-check-box
        *ngIf="cellInfo.data.option.allowRequire"
        [(value)]="cellInfo.value"
        (onValueChanged)="checkBoxValueChanged(cellInfo, $event)"
      ></dx-check-box>
    </div>
  </dxi-column>

  <dxi-column
    *ngIf="hasStatus"
    [dataField]="'statusIds'"
    [caption]="'entities/designerRule/status' | translate"
    [allowEditing]="true"
    [editCellTemplate]="'tagBoxEditCellTemplate'"
    [cellTemplate]="'tagBoxCellTemplate'"
    [setCellValue]="setCellValue"
    [allowResizing]="true"
  >
    <div *dxTemplate="let cellInfo of 'tagBoxCellTemplate'">
      <dx-tag-box
        *ngIf="
          cellInfo.data.option.allowStatus && cellInfo.data.required === true
        "
        [value]="cellInfo.value"
        [disabled]="true"
        [readOnly]="true"
        [dataSource]="tagSource.datasource"
        [showClearButton]="true"
        placeholder=""
        [searchMode]="'contains'"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [valueExpr]="'id'"
        [showSelectionControls]="true"
        [displayExpr]="getDisplayExprTransalted()"
        [acceptCustomValue]="false"
        [multiline]="true"
      >
      </dx-tag-box>
    </div>
    <div
      *dxTemplate="let cellInfo of 'tagBoxEditCellTemplate'"
      style="padding-top: 10px; padding-bottom: 10px"
    >
      <dx-tag-box
        *ngIf="
          cellInfo.data.option.allowStatus && cellInfo.data.required === true
        "
        [value]="cellInfo.value"
        [dataSource]="tagSource.datasource"
        [showClearButton]="true"
        [placeholder]="'selectValue' | translate"
        [searchMode]="'contains'"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [valueExpr]="'id'"
        [showSelectionControls]="true"
        [displayExpr]="getDisplayExprTransalted()"
        [acceptCustomValue]="false"
        [multiline]="true"
        (onSelectionChanged)="selectionTemplateChanged(cellInfo, $event)"
      >
      </dx-tag-box>
    </div>
  </dxi-column>
</dx-data-grid>
