import { ConfigureDevExtremeService } from './services/configure/configure-dev-extreme.service';
import { NgModule, Optional, SkipSelf } from '@angular/core';
import { CommonModule } from '@angular/common';
import { throwIfAlreadyLoaded } from './guards/module-import.guard';
import { OAuthModule } from 'angular-oauth2-oidc';
import { GlobalizeModule } from './modules/globalize/globalize.module';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './interceptors/auth.interceptor';
//import { httpInterceptorProviders } from './interceptors/http-interceptor.provider';

@NgModule({
  declarations: [],
  imports: [OAuthModule.forRoot(), CommonModule, GlobalizeModule],
  providers: [
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
  ],
  exports: [GlobalizeModule],
})
export class CoreModule {
  constructor(
    @Optional() @SkipSelf() parentModule: CoreModule,
    configureDevExtreme: ConfigureDevExtremeService,
  ) {
    throwIfAlreadyLoaded(parentModule, 'CoreModule');
    configureDevExtreme.initialize();
  }
}
