import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOrdersBaseVariables, FirstOrdersBaseVariables, CountOrdersBaseVariables, FindOrdersBaseVariables, SearchOrdersBaseVariables, ExportSchemaOrdersBaseVariables, ExportDataOrdersBaseVariables, CustomQueryOrdersBaseVariables, CustomQueryIdOrdersBaseVariables, UsedOrdersBaseVariables, ExistOrdersBaseVariables, FindRecyclesOrdersBaseVariables, CountRecyclesOrdersBaseVariables, ReadOnlyOrdersBaseVariables, FindArchivedOrdersBaseVariables, CountAllOrdersBaseVariables, FindDynamicPropertyFieldsOrdersBaseVariables, InsertOrdersBaseVariables, UpdateOrdersBaseVariables, ImportDataOrdersBaseVariables, UpdateManyOrdersBaseVariables, DeleteOrdersBaseVariables, RestoreOrdersBaseVariables, RecycleOrdersBaseVariables, RestoreArchivedOrdersBaseVariables, ArchivedOrdersBaseVariables, AddFileDynamicFieldOrdersBaseVariables, RemoveFileDynamicFieldOrdersBaseVariables } from '../gqls'
import { GetOrdersDocument, FirstOrdersDocument, CountOrdersDocument, FindOrdersDocument, SearchOrdersDocument, ExportSchemaOrdersDocument, ExportDataOrdersDocument, CustomQueryOrdersDocument, CustomQueryIdOrdersDocument, UsedOrdersDocument, ExistOrdersDocument, FindRecyclesOrdersDocument, CountRecyclesOrdersDocument, ReadOnlyOrdersDocument, FindArchivedOrdersDocument, CountAllOrdersDocument, FindDynamicPropertyFieldsOrdersDocument, InsertOrdersDocument, UpdateOrdersDocument, ImportDataOrdersDocument, UpdateManyOrdersDocument, DeleteOrdersDocument, RestoreOrdersDocument, RecycleOrdersDocument, RestoreArchivedOrdersDocument, ArchivedOrdersDocument, AddFileDynamicFieldOrdersDocument, RemoveFileDynamicFieldOrdersDocument } from '../gqls'
import { ServiceSingleResultOfOrder, QueryContextOfOrder, FilterOfOrder, ServiceSingleResultOfInt64, ServiceListResultOfOrder, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrder, OrderInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OrderBaseService {
    
public modelName = 'order';
public modelPluralName = 'orders';

	private getOrdersQuery: GetOrdersDocument;
	private firstOrdersQuery: FirstOrdersDocument;
	private countOrdersQuery: CountOrdersDocument;
	private findOrdersQuery: FindOrdersDocument;
	private searchOrdersQuery: SearchOrdersDocument;
	private exportSchemaOrdersQuery: ExportSchemaOrdersDocument;
	private exportDataOrdersQuery: ExportDataOrdersDocument;
	private customQueryOrdersQuery: CustomQueryOrdersDocument;
	private customQueryIdOrdersQuery: CustomQueryIdOrdersDocument;
	private usedOrdersQuery: UsedOrdersDocument;
	private existOrdersQuery: ExistOrdersDocument;
	private findRecyclesOrdersQuery: FindRecyclesOrdersDocument;
	private countRecyclesOrdersQuery: CountRecyclesOrdersDocument;
	private readOnlyOrdersQuery: ReadOnlyOrdersDocument;
	private findArchivedOrdersQuery: FindArchivedOrdersDocument;
	private countAllOrdersQuery: CountAllOrdersDocument;
	private findDynamicPropertyFieldsOrdersQuery: FindDynamicPropertyFieldsOrdersDocument;
	private insertOrdersMutation: InsertOrdersDocument;
	private updateOrdersMutation: UpdateOrdersDocument;
	private importDataOrdersMutation: ImportDataOrdersDocument;
	private updateManyOrdersMutation: UpdateManyOrdersDocument;
	private deleteOrdersMutation: DeleteOrdersDocument;
	private restoreOrdersMutation: RestoreOrdersDocument;
	private recycleOrdersMutation: RecycleOrdersDocument;
	private restoreArchivedOrdersMutation: RestoreArchivedOrdersDocument;
	private archivedOrdersMutation: ArchivedOrdersDocument;
	private addFileDynamicFieldOrdersMutation: AddFileDynamicFieldOrdersDocument;
	private removeFileDynamicFieldOrdersMutation: RemoveFileDynamicFieldOrdersDocument;

	constructor(private injector: Injector) {
		this.getOrdersQuery = this.injector.get(GetOrdersDocument);
		this.firstOrdersQuery = this.injector.get(FirstOrdersDocument);
		this.countOrdersQuery = this.injector.get(CountOrdersDocument);
		this.findOrdersQuery = this.injector.get(FindOrdersDocument);
		this.searchOrdersQuery = this.injector.get(SearchOrdersDocument);
		this.exportSchemaOrdersQuery = this.injector.get(ExportSchemaOrdersDocument);
		this.exportDataOrdersQuery = this.injector.get(ExportDataOrdersDocument);
		this.customQueryOrdersQuery = this.injector.get(CustomQueryOrdersDocument);
		this.customQueryIdOrdersQuery = this.injector.get(CustomQueryIdOrdersDocument);
		this.usedOrdersQuery = this.injector.get(UsedOrdersDocument);
		this.existOrdersQuery = this.injector.get(ExistOrdersDocument);
		this.findRecyclesOrdersQuery = this.injector.get(FindRecyclesOrdersDocument);
		this.countRecyclesOrdersQuery = this.injector.get(CountRecyclesOrdersDocument);
		this.readOnlyOrdersQuery = this.injector.get(ReadOnlyOrdersDocument);
		this.findArchivedOrdersQuery = this.injector.get(FindArchivedOrdersDocument);
		this.countAllOrdersQuery = this.injector.get(CountAllOrdersDocument);
		this.findDynamicPropertyFieldsOrdersQuery = this.injector.get(FindDynamicPropertyFieldsOrdersDocument);
		this.insertOrdersMutation = this.injector.get(InsertOrdersDocument);
		this.updateOrdersMutation = this.injector.get(UpdateOrdersDocument);
		this.importDataOrdersMutation = this.injector.get(ImportDataOrdersDocument);
		this.updateManyOrdersMutation = this.injector.get(UpdateManyOrdersDocument);
		this.deleteOrdersMutation = this.injector.get(DeleteOrdersDocument);
		this.restoreOrdersMutation = this.injector.get(RestoreOrdersDocument);
		this.recycleOrdersMutation = this.injector.get(RecycleOrdersDocument);
		this.restoreArchivedOrdersMutation = this.injector.get(RestoreArchivedOrdersDocument);
		this.archivedOrdersMutation = this.injector.get(ArchivedOrdersDocument);
		this.addFileDynamicFieldOrdersMutation = this.injector.get(AddFileDynamicFieldOrdersDocument);
		this.removeFileDynamicFieldOrdersMutation = this.injector.get(RemoveFileDynamicFieldOrdersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrder> {
    
    		let variables: GetOrdersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.get));
            }
            else{
                let result:ServiceSingleResultOfOrder={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrder> {
    
    		let variables: FirstOrdersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOrdersBaseVariables = {
    			filter: filter
    		}
    				return this.countOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: FindOrdersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOrder,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: SearchOrdersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOrdersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOrdersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: CustomQueryOrdersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: CustomQueryIdOrdersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.usedOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOrdersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: FindRecyclesOrdersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesOrdersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyOrdersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrder,
		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrder> {
    
    		let variables: FindArchivedOrdersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllOrdersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsOrdersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsOrdersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orders.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OrderInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrder> {
    
    		let variables: InsertOrdersBaseVariables = {
    			entity: entity
    		}
    				return this.insertOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrder> {
    
    		let variables: UpdateOrdersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOrdersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOrdersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedOrdersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldOrdersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldOrdersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldOrdersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orders.removeFileDynamicField));
    	}
    
}