import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSerialPortsBaseVariables, FirstSerialPortsBaseVariables, CountSerialPortsBaseVariables, FindSerialPortsBaseVariables, SearchSerialPortsBaseVariables, ExportSchemaSerialPortsBaseVariables, ExportDataSerialPortsBaseVariables, CustomQuerySerialPortsBaseVariables, CustomQueryIdSerialPortsBaseVariables, UsedSerialPortsBaseVariables, ExistSerialPortsBaseVariables } from '../gqls'
import { GetSerialPortsDocument, FirstSerialPortsDocument, CountSerialPortsDocument, FindSerialPortsDocument, SearchSerialPortsDocument, ExportSchemaSerialPortsDocument, ExportDataSerialPortsDocument, CustomQuerySerialPortsDocument, CustomQueryIdSerialPortsDocument, UsedSerialPortsDocument, ExistSerialPortsDocument } from '../gqls'
import { ServiceSingleResultOfSerialPort, QueryContextOfSerialPort, FilterOfSerialPort, ServiceSingleResultOfInt64, ServiceListResultOfSerialPort, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SerialPortBaseService {
    
public modelName = 'serialPort';
public modelPluralName = 'serialPorts';

	private getSerialPortsQuery: GetSerialPortsDocument;
	private firstSerialPortsQuery: FirstSerialPortsDocument;
	private countSerialPortsQuery: CountSerialPortsDocument;
	private findSerialPortsQuery: FindSerialPortsDocument;
	private searchSerialPortsQuery: SearchSerialPortsDocument;
	private exportSchemaSerialPortsQuery: ExportSchemaSerialPortsDocument;
	private exportDataSerialPortsQuery: ExportDataSerialPortsDocument;
	private customQuerySerialPortsQuery: CustomQuerySerialPortsDocument;
	private customQueryIdSerialPortsQuery: CustomQueryIdSerialPortsDocument;
	private usedSerialPortsQuery: UsedSerialPortsDocument;
	private existSerialPortsQuery: ExistSerialPortsDocument;

	constructor(private injector: Injector) {
		this.getSerialPortsQuery = this.injector.get(GetSerialPortsDocument);
		this.firstSerialPortsQuery = this.injector.get(FirstSerialPortsDocument);
		this.countSerialPortsQuery = this.injector.get(CountSerialPortsDocument);
		this.findSerialPortsQuery = this.injector.get(FindSerialPortsDocument);
		this.searchSerialPortsQuery = this.injector.get(SearchSerialPortsDocument);
		this.exportSchemaSerialPortsQuery = this.injector.get(ExportSchemaSerialPortsDocument);
		this.exportDataSerialPortsQuery = this.injector.get(ExportDataSerialPortsDocument);
		this.customQuerySerialPortsQuery = this.injector.get(CustomQuerySerialPortsDocument);
		this.customQueryIdSerialPortsQuery = this.injector.get(CustomQueryIdSerialPortsDocument);
		this.usedSerialPortsQuery = this.injector.get(UsedSerialPortsDocument);
		this.existSerialPortsQuery = this.injector.get(ExistSerialPortsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSerialPort> {
    
    		let variables: GetSerialPortsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.get));
            }
            else{
                let result:ServiceSingleResultOfSerialPort={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSerialPort,
		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSerialPort> {
    
    		let variables: FirstSerialPortsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSerialPortsBaseVariables = {
    			filter: filter
    		}
    				return this.countSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSerialPort,
		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialPort> {
    
    		let variables: FindSerialPortsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSerialPort,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialPort> {
    
    		let variables: SearchSerialPortsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSerialPortsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSerialPortsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSerialPort,
		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialPort> {
    
    		let variables: CustomQuerySerialPortsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSerialPort,
		@Args('filter?') filter?: FilterOfSerialPort,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSerialPort> {
    
    		let variables: CustomQueryIdSerialPortsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSerialPortsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSerialPortsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSerialPortsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.serialPorts.exist));
    	}
    
    
}