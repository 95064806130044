import { ScanMethodBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfScanMethod,
  QueryContextOfScanMethod,
  ServiceListResultOfScanMethod,
} from '../types';
import { Observable } from 'rxjs';
import { FindScanMethodsBaseVariables } from '../gqls/scan-method.gqls';
import { map } from 'rxjs/operators';
import { TranslateService } from '@clarilog/shared';

@Injectable({ providedIn: 'root' })
export class ScanMethodCoreService extends ScanMethodBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findMethods(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanMethod,
    @Args('filter?') filter?: FilterOfScanMethod,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanMethod> {
    let variables: FindScanMethodsBaseVariables = {
      options: options,
      filter: filter,
    };
    return this.find(fields, options, filter, extendedVariables).pipe(
      map((result) => {
        result.data.forEach(
          (g) =>
            (g['name'] = TranslateService.get(
              'entities/' +
                g.methodName.charAt(0).toLowerCase() +
                g.methodName.slice(1) +
                '/_title/singular',
            )),
        );
        return result;
      }),
    );
  }
}
