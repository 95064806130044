import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetGroupAzureAdsBaseVariables, FirstGroupAzureAdsBaseVariables, CountGroupAzureAdsBaseVariables, FindGroupAzureAdsBaseVariables, SearchGroupAzureAdsBaseVariables, ExportSchemaGroupAzureAdsBaseVariables, ExportDataGroupAzureAdsBaseVariables, CustomQueryGroupAzureAdsBaseVariables, CustomQueryIdGroupAzureAdsBaseVariables, UsedGroupAzureAdsBaseVariables, ExistGroupAzureAdsBaseVariables, InsertGroupAzureAdsBaseVariables, UpdateGroupAzureAdsBaseVariables, ImportDataGroupAzureAdsBaseVariables, UpdateManyGroupAzureAdsBaseVariables, DeleteGroupAzureAdsBaseVariables } from '../gqls'
import { GetGroupAzureAdsDocument, FirstGroupAzureAdsDocument, CountGroupAzureAdsDocument, FindGroupAzureAdsDocument, SearchGroupAzureAdsDocument, ExportSchemaGroupAzureAdsDocument, ExportDataGroupAzureAdsDocument, CustomQueryGroupAzureAdsDocument, CustomQueryIdGroupAzureAdsDocument, UsedGroupAzureAdsDocument, ExistGroupAzureAdsDocument, InsertGroupAzureAdsDocument, UpdateGroupAzureAdsDocument, ImportDataGroupAzureAdsDocument, UpdateManyGroupAzureAdsDocument, DeleteGroupAzureAdsDocument } from '../gqls'
import { ServiceSingleResultOfGroupAzureAd, QueryContextOfGroupAzureAd, FilterOfGroupAzureAd, ServiceSingleResultOfInt64, ServiceListResultOfGroupAzureAd, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, GroupAzureAdInput, FieldUpdateOperatorOfGroupAzureAd } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class GroupAzureAdBaseService {
    
public modelName = 'groupAzureAd';
public modelPluralName = 'groupAzureAds';

	private getGroupAzureAdsQuery: GetGroupAzureAdsDocument;
	private firstGroupAzureAdsQuery: FirstGroupAzureAdsDocument;
	private countGroupAzureAdsQuery: CountGroupAzureAdsDocument;
	private findGroupAzureAdsQuery: FindGroupAzureAdsDocument;
	private searchGroupAzureAdsQuery: SearchGroupAzureAdsDocument;
	private exportSchemaGroupAzureAdsQuery: ExportSchemaGroupAzureAdsDocument;
	private exportDataGroupAzureAdsQuery: ExportDataGroupAzureAdsDocument;
	private customQueryGroupAzureAdsQuery: CustomQueryGroupAzureAdsDocument;
	private customQueryIdGroupAzureAdsQuery: CustomQueryIdGroupAzureAdsDocument;
	private usedGroupAzureAdsQuery: UsedGroupAzureAdsDocument;
	private existGroupAzureAdsQuery: ExistGroupAzureAdsDocument;
	private insertGroupAzureAdsMutation: InsertGroupAzureAdsDocument;
	private updateGroupAzureAdsMutation: UpdateGroupAzureAdsDocument;
	private importDataGroupAzureAdsMutation: ImportDataGroupAzureAdsDocument;
	private updateManyGroupAzureAdsMutation: UpdateManyGroupAzureAdsDocument;
	private deleteGroupAzureAdsMutation: DeleteGroupAzureAdsDocument;

	constructor(private injector: Injector) {
		this.getGroupAzureAdsQuery = this.injector.get(GetGroupAzureAdsDocument);
		this.firstGroupAzureAdsQuery = this.injector.get(FirstGroupAzureAdsDocument);
		this.countGroupAzureAdsQuery = this.injector.get(CountGroupAzureAdsDocument);
		this.findGroupAzureAdsQuery = this.injector.get(FindGroupAzureAdsDocument);
		this.searchGroupAzureAdsQuery = this.injector.get(SearchGroupAzureAdsDocument);
		this.exportSchemaGroupAzureAdsQuery = this.injector.get(ExportSchemaGroupAzureAdsDocument);
		this.exportDataGroupAzureAdsQuery = this.injector.get(ExportDataGroupAzureAdsDocument);
		this.customQueryGroupAzureAdsQuery = this.injector.get(CustomQueryGroupAzureAdsDocument);
		this.customQueryIdGroupAzureAdsQuery = this.injector.get(CustomQueryIdGroupAzureAdsDocument);
		this.usedGroupAzureAdsQuery = this.injector.get(UsedGroupAzureAdsDocument);
		this.existGroupAzureAdsQuery = this.injector.get(ExistGroupAzureAdsDocument);
		this.insertGroupAzureAdsMutation = this.injector.get(InsertGroupAzureAdsDocument);
		this.updateGroupAzureAdsMutation = this.injector.get(UpdateGroupAzureAdsDocument);
		this.importDataGroupAzureAdsMutation = this.injector.get(ImportDataGroupAzureAdsDocument);
		this.updateManyGroupAzureAdsMutation = this.injector.get(UpdateManyGroupAzureAdsDocument);
		this.deleteGroupAzureAdsMutation = this.injector.get(DeleteGroupAzureAdsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroupAzureAd> {
    
    		let variables: GetGroupAzureAdsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.get));
            }
            else{
                let result:ServiceSingleResultOfGroupAzureAd={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfGroupAzureAd,
		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroupAzureAd> {
    
    		let variables: FirstGroupAzureAdsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountGroupAzureAdsBaseVariables = {
    			filter: filter
    		}
    				return this.countGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfGroupAzureAd,
		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroupAzureAd> {
    
    		let variables: FindGroupAzureAdsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfGroupAzureAd,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroupAzureAd> {
    
    		let variables: SearchGroupAzureAdsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaGroupAzureAdsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataGroupAzureAdsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfGroupAzureAd,
		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroupAzureAd> {
    
    		let variables: CustomQueryGroupAzureAdsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfGroupAzureAd,
		@Args('filter?') filter?: FilterOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroupAzureAd> {
    
    		let variables: CustomQueryIdGroupAzureAdsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedGroupAzureAdsBaseVariables = {
    			ids: ids
    		}
    				return this.usedGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistGroupAzureAdsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existGroupAzureAdsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: GroupAzureAdInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroupAzureAd> {
    
    		let variables: InsertGroupAzureAdsBaseVariables = {
    			entity: entity
    		}
    				return this.insertGroupAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroupAzureAd> {
    
    		let variables: UpdateGroupAzureAdsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateGroupAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataGroupAzureAdsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataGroupAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfGroupAzureAd,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyGroupAzureAdsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyGroupAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteGroupAzureAdsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteGroupAzureAdsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.groupAzureAds.delete));
    	}
    
}