import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSftpConnectorHistoriesBaseVariables, FirstSftpConnectorHistoriesBaseVariables, CountSftpConnectorHistoriesBaseVariables, FindSftpConnectorHistoriesBaseVariables, SearchSftpConnectorHistoriesBaseVariables, ExportSchemaSftpConnectorHistoriesBaseVariables, ExportDataSftpConnectorHistoriesBaseVariables, CustomQuerySftpConnectorHistoriesBaseVariables, CustomQueryIdSftpConnectorHistoriesBaseVariables, UsedSftpConnectorHistoriesBaseVariables, ExistSftpConnectorHistoriesBaseVariables, InsertSftpConnectorHistoriesBaseVariables, UpdateSftpConnectorHistoriesBaseVariables, ImportDataSftpConnectorHistoriesBaseVariables, UpdateManySftpConnectorHistoriesBaseVariables, DeleteSftpConnectorHistoriesBaseVariables } from '../gqls'
import { GetSftpConnectorHistoriesDocument, FirstSftpConnectorHistoriesDocument, CountSftpConnectorHistoriesDocument, FindSftpConnectorHistoriesDocument, SearchSftpConnectorHistoriesDocument, ExportSchemaSftpConnectorHistoriesDocument, ExportDataSftpConnectorHistoriesDocument, CustomQuerySftpConnectorHistoriesDocument, CustomQueryIdSftpConnectorHistoriesDocument, UsedSftpConnectorHistoriesDocument, ExistSftpConnectorHistoriesDocument, InsertSftpConnectorHistoriesDocument, UpdateSftpConnectorHistoriesDocument, ImportDataSftpConnectorHistoriesDocument, UpdateManySftpConnectorHistoriesDocument, DeleteSftpConnectorHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfSftpConnectorHistory, QueryContextOfSftpConnectorHistory, FilterOfSftpConnectorHistory, ServiceSingleResultOfInt64, ServiceListResultOfSftpConnectorHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SftpConnectorHistoryInput, FieldUpdateOperatorOfSftpConnectorHistory } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SftpConnectorHistoryBaseService {
    
public modelName = 'sftpConnectorHistory';
public modelPluralName = 'sftpConnectorHistories';

	private getSftpConnectorHistoriesQuery: GetSftpConnectorHistoriesDocument;
	private firstSftpConnectorHistoriesQuery: FirstSftpConnectorHistoriesDocument;
	private countSftpConnectorHistoriesQuery: CountSftpConnectorHistoriesDocument;
	private findSftpConnectorHistoriesQuery: FindSftpConnectorHistoriesDocument;
	private searchSftpConnectorHistoriesQuery: SearchSftpConnectorHistoriesDocument;
	private exportSchemaSftpConnectorHistoriesQuery: ExportSchemaSftpConnectorHistoriesDocument;
	private exportDataSftpConnectorHistoriesQuery: ExportDataSftpConnectorHistoriesDocument;
	private customQuerySftpConnectorHistoriesQuery: CustomQuerySftpConnectorHistoriesDocument;
	private customQueryIdSftpConnectorHistoriesQuery: CustomQueryIdSftpConnectorHistoriesDocument;
	private usedSftpConnectorHistoriesQuery: UsedSftpConnectorHistoriesDocument;
	private existSftpConnectorHistoriesQuery: ExistSftpConnectorHistoriesDocument;
	private insertSftpConnectorHistoriesMutation: InsertSftpConnectorHistoriesDocument;
	private updateSftpConnectorHistoriesMutation: UpdateSftpConnectorHistoriesDocument;
	private importDataSftpConnectorHistoriesMutation: ImportDataSftpConnectorHistoriesDocument;
	private updateManySftpConnectorHistoriesMutation: UpdateManySftpConnectorHistoriesDocument;
	private deleteSftpConnectorHistoriesMutation: DeleteSftpConnectorHistoriesDocument;

	constructor(private injector: Injector) {
		this.getSftpConnectorHistoriesQuery = this.injector.get(GetSftpConnectorHistoriesDocument);
		this.firstSftpConnectorHistoriesQuery = this.injector.get(FirstSftpConnectorHistoriesDocument);
		this.countSftpConnectorHistoriesQuery = this.injector.get(CountSftpConnectorHistoriesDocument);
		this.findSftpConnectorHistoriesQuery = this.injector.get(FindSftpConnectorHistoriesDocument);
		this.searchSftpConnectorHistoriesQuery = this.injector.get(SearchSftpConnectorHistoriesDocument);
		this.exportSchemaSftpConnectorHistoriesQuery = this.injector.get(ExportSchemaSftpConnectorHistoriesDocument);
		this.exportDataSftpConnectorHistoriesQuery = this.injector.get(ExportDataSftpConnectorHistoriesDocument);
		this.customQuerySftpConnectorHistoriesQuery = this.injector.get(CustomQuerySftpConnectorHistoriesDocument);
		this.customQueryIdSftpConnectorHistoriesQuery = this.injector.get(CustomQueryIdSftpConnectorHistoriesDocument);
		this.usedSftpConnectorHistoriesQuery = this.injector.get(UsedSftpConnectorHistoriesDocument);
		this.existSftpConnectorHistoriesQuery = this.injector.get(ExistSftpConnectorHistoriesDocument);
		this.insertSftpConnectorHistoriesMutation = this.injector.get(InsertSftpConnectorHistoriesDocument);
		this.updateSftpConnectorHistoriesMutation = this.injector.get(UpdateSftpConnectorHistoriesDocument);
		this.importDataSftpConnectorHistoriesMutation = this.injector.get(ImportDataSftpConnectorHistoriesDocument);
		this.updateManySftpConnectorHistoriesMutation = this.injector.get(UpdateManySftpConnectorHistoriesDocument);
		this.deleteSftpConnectorHistoriesMutation = this.injector.get(DeleteSftpConnectorHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnectorHistory> {
    
    		let variables: GetSftpConnectorHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.get));
            }
            else{
                let result:ServiceSingleResultOfSftpConnectorHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSftpConnectorHistory,
		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnectorHistory> {
    
    		let variables: FirstSftpConnectorHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSftpConnectorHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSftpConnectorHistory,
		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnectorHistory> {
    
    		let variables: FindSftpConnectorHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSftpConnectorHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnectorHistory> {
    
    		let variables: SearchSftpConnectorHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSftpConnectorHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSftpConnectorHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSftpConnectorHistory,
		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnectorHistory> {
    
    		let variables: CustomQuerySftpConnectorHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSftpConnectorHistory,
		@Args('filter?') filter?: FilterOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnectorHistory> {
    
    		let variables: CustomQueryIdSftpConnectorHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSftpConnectorHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSftpConnectorHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSftpConnectorHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SftpConnectorHistoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnectorHistory> {
    
    		let variables: InsertSftpConnectorHistoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSftpConnectorHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnectorHistory> {
    
    		let variables: UpdateSftpConnectorHistoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSftpConnectorHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSftpConnectorHistoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSftpConnectorHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSftpConnectorHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySftpConnectorHistoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySftpConnectorHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSftpConnectorHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSftpConnectorHistoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectorHistories.delete));
    	}
    
}