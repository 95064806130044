import { ChangeDetectorRef, Component, forwardRef, Input, OnInit, QueryList, ViewChild, ViewChildren } from "@angular/core";
import { AbstractControl, ControlValueAccessor, UntypedFormControl, NgControl, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator } from "@angular/forms";
import { Subscription } from 'rxjs';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { DxTextBoxComponent } from 'devextreme-angular/ui/text-box';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-day-off',
  templateUrl: './day-off.component.html',
  styleUrls: ['./day-off.component.scss'],
  providers: [  
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreDayOffComponent),
      multi: true,
    },
    {
        provide: NG_VALIDATORS,
        useExisting: forwardRef(() => CoreDayOffComponent),
        multi: true,
    },
  ],

})
export class CoreDayOffComponent implements OnInit, ControlValueAccessor, Validator {
//ControlValueAccessor, OnInit, AfterViewInit, OnDestroy,Validator {
  /** Represente le modelState du formulaire */
  @Input() modelState: ModelState;
  /** Sauvegarde les valeurs. */
  _values: any;
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => { };
  /** @inheritdoc */
  onTouched: any = () => { };
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;

  _initializeValue: boolean = false;
  _initializeComponent: boolean = false;
  _firstLoad: boolean = false;
  formChangeSubscriber: Subscription;

  days: Array<any> = []
  dayOffs: any = []
  indexId:number = 0;

  DayOffDayBoutons: any[] = [];
  DayOffDayComponents: any[] = [];
  validationComponents: any;
  dateBoxComponents: any[] = [];
  textBoxComponents: any[] = [];
  dayOffDayConfigComponents: any[] = [];
  _initializeState: boolean = false;
  isvalid:boolean=true;
  gc: GC;
  orginalValue: string;
  control: AbstractControl

  @ViewChildren(DxDateBoxComponent) listDateBoxComponent: QueryList<DxDateBoxComponent>;
  //@ViewChildren(DxTextBoxComponent) listTextBoxComponent: QueryList<DxTextBoxComponent>;

  get values(): any[] {
    if (this._values != undefined) {
      this._values.forEach(f => delete f["__KEY__"]);
      this._values.forEach(f => delete f["__typename"]);      
    } else {
      this._values = [];
    }
    return this._values;
  }

  set values(values: any[]) {
    this._values = values;
    if (this._initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }
  constructor(
    private _gcFactory: GCFactory
  ) {
    this.gc = _gcFactory.create();
    //controlDirective.valueAccessor = this;
    this._initializeComponent == false;
  }
  /** @inheritdoc */
  writeValue(values: any): void {
    this._initializeValue = true;
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
    this._initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  async ngOnInit() {    
    //this.controlDirective.control.setValidators([this.isValid.bind(this)]);
    //this.controlDirective.control.updateValueAndValidity();
    this.formChangeSubscriber = this.modelState.form.root.onLoadedChanged.subscribe((res) => {
      if (res == true && this._initializeComponent == true) {
        let obj = {};
        for (let member in this.modelState.form.value) {
          obj[member] = this._originalValues;
        }
        if (this._firstLoad == false) {
          this.initValue();
          this._firstLoad = true;
        }
        else {
          this.modelState.form.reset(obj);
        }
      }
    });
    this._initializeComponent = true;
  }
  ngAfterViewInit(): void {
    this.listDateBoxComponent.forEach(el => {
      this.gc.forRelease(el.isValidChange.subscribe(res => {
        this.removeInvalidBadge();
      }))
    });

    // this.listTextBoxComponent.forEach(el => {
    //   this.gc.forRelease(el.isValidChange.subscribe(res => {
    //     this.removeInvalidBadge();
    //   }))
    // });
  }

  ngOnDestroy() {
    if (this.formChangeSubscriber != undefined) {
      this.formChangeSubscriber.unsubscribe();
    }
  }
  initializeDateBox(e) {
    this.dateBoxComponents.push(e.component);
    // setTimeout(() => {
    //e.component.element().classList.remove('dx-show-invalid-badge');   
    // });    
     
  }
  initializeTextBox(e) {
    this.textBoxComponents.push(e.component);
    //e.component.element().classList.remove('dx-show-invalid-badge');    
  }
  initializeBox(e) {
    this.dayOffDayConfigComponents.push(e.component);
  }
  async initialized(e) {
    this._initializeComponent = true;
  }

  removeInvalidBadge() {
    // this.listDateBoxComponent.forEach(element => {
    //   if ((element as any).element.nativeElement.classList.contains('dx-show-invalid-badge')) {
    //     (element as any).element.nativeElement.classList.remove('dx-show-invalid-badge')
    //   }
    // });
    // this.listTextBoxComponent.forEach(element => {
    //   if ((element as any).element.nativeElement.classList.contains('dx-show-invalid-badge')) {
    //     (element as any).element.nativeElement.classList.remove('dx-show-invalid-badge')
    //   }
    // });
  }

  /** Vérifie la valeur est valide */
  isValid(control: AbstractControl): ValidationErrors | null {
    let isValid = this.dateBoxComponents.findIndex(x => x.option("isValid") == false) == -1;
    if (!isValid) {
      return { invalidMsg: 'Valeur invalide' };
    }
    return null;
  }

  /** Vaidation */
  validate(value: UntypedFormControl) {
    //this.isvalid = true;
    // this.dateBoxComponents.forEach(d=>{
    //   let value = d.option("value");
    //   if(value == null)
    //   {
    //     d.option("isValid",false);
    //     this.isvalid = false;
    //   }
    //   else{
    //     d.option("isValid",true);
    //   }
    // });

    let isValid = this.dateBoxComponents.findIndex(x => x.option("isValid") == false) == -1;
    if (!isValid) {
       return { invalidMsg: 'Valeur invalide' };
    }
    return null;
  }

  initValue() {
    if (this._values == undefined || this._values.length == 0) {
      this.values = this._values;      
    }
    else {
      this._values.forEach(function (day) {
        day.id = this.indexId;
        this.days.push(day);
        this.indexId++;
      }, this);
    }
    setTimeout(() => {
      this.removeInvalidBadge();
    }, 50);    
  }

  /** Compare les dates début et fin */
  dateCompare() {
    return (e) => {
      let elementId = e.validator.element().id;
      let indexId = elementId.substr(0, 1);

      let startCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_start'));
      let endCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_end'));

      if(startCmp != null && endCmp != null)
      {
        let startValue = startCmp.option("value");
        if(startValue == '')
        {
          startValue = null;        
        }
        let endValue = endCmp.option("value");
        if(endValue == '')
        {
          endValue = null;        
        }

        if(startValue != null && endValue != null){
          startCmp.option("isValid",true);
          endCmp.option("isValid",true);
          return true;
        }
        else{
          startCmp.option("isValid",false);
          endCmp.option("isValid",false);
          return false;
        }
      }
      return true;
    }
  }  
  
  valuesChanged(e) {
    let elementId = e.element.id;
    let indexId = elementId.substr(0, 1);
    let currentValue = this._values.find(x=> x.id == indexId);  
   

    let startCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_start'));
      let endCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_end'));
      let startValue = startCmp.option("value");  
      let endValue = endCmp.option("value");

    if(currentValue == null)
    {
      let startCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_start'));
      let endCmp = this.dateBoxComponents.find(x => (x as any).element().id == (indexId + '_end'));
      let startValue = startCmp.option("value");  
      let endValue = endCmp.option("value");
      this._values.push({ id:indexId, start: startValue, end: endValue, description: null });
    }
    else
    {
      let indexValue = this._values.indexOf(currentValue);
      let propertyName = elementId.substr(elementId.indexOf('_')+Number(1), elementId.length);
      this._values[indexValue][propertyName] = e.value;
    } 

    

   
      const value = [];
      this._values.forEach(v=>{
        value.push({ start: v.start, end: v.end, description: v.description });
      });
      this.onChange(value);
      this.onTouched();

  }

  addLine() {
    let newIndexId = this.indexId+ Number(1);
    this.days.push({id:newIndexId});
    this.indexId = newIndexId;
    setTimeout(() => {      
      let startCmp = this.dateBoxComponents.find(x => (x as any).element().id == (newIndexId + '_start'));
      let endCmp = this.dateBoxComponents.find(x => (x as any).element().id == (newIndexId + '_end'));
      let descriptionCmp = this.textBoxComponents.find(x => (x as any).element().id == (newIndexId + '_description'));
      // startCmp.element().classList.remove('dx-show-invalid-badge');     
      // endCmp.element().classList.remove('dx-show-invalid-badge');
      // descriptionCmp.element().classList.remove('dx-show-invalid-badge');
      
      //startCmp.option('isValid',false);
      //this.removeInvalidBadge();
    })
  }
  removeLine(i) {    
    let indexId = i.element.id.substr(0, 1);  

    let indexStartCmp = this.dateBoxComponents.findIndex(x => (x as any).element().id == (indexId + '_start'));
    let indexEndCmp = this.dateBoxComponents.findIndex(x => (x as any).element().id == (indexId + '_end'));
    this.dateBoxComponents.splice(indexStartCmp, 1);
    this.dateBoxComponents.splice(indexEndCmp, 1);

    let indexDay = this.days.findIndex(x=> x.id == indexId);  
    this.days.splice(indexDay, 1);

    let indexValue = this._values.findIndex(x=> x.id == indexId);  
    this._values.splice(indexValue, 1);

    setTimeout(() => {
      this.onChange(this._values);
      this.onTouched();
    }, 200);
  }
}
