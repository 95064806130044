import { CommandBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Observable } from 'rxjs';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('administration.command')
export class CommandCoreService extends CommandBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }
  /** Obtient la liste des mention pour actions sur les commandes */
  @InjectArgs
  findMentionBalise(): any {
    return [
      { key: 'chemin', name: TranslateService.get(`entities/command/chemin`) },
      { key: 'port', name: TranslateService.get(`entities/command/port`) },
      { key: 'ip', name: TranslateService.get(`entities/command/ip`) },
      {
        key: 'netbios',
        name: TranslateService.get(`entities/command/netbios`),
      },
      { key: 'mac', name: TranslateService.get(`entities/command/mac`) },
      {
        key: 'fichier',
        name: TranslateService.get(`entities/command/fichier`),
      },
    ];
  }

  findMentionManageBalise(): any {
    return [
      { key: 'ip', name: TranslateService.get(`entities/command/ip`) },
      {
        key: 'netbios',
        name: TranslateService.get(`entities/command/netbios`),
      },
      { key: 'mac', name: TranslateService.get(`entities/command/mac`) },
    ];
  }

  /** Obtient les fields de la query */
  fields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('dataCommand'),
        GqlField.create('category'),
        GqlSubField.create(
          'name',
          this.translatedFieldHelperService.translatedFields(),
        ),
      ]),
    ];
  }
}
