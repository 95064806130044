import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfEmailPrototype, FilterOfEmailPrototype, QueryContextOfEmailPrototype, ServiceListResultOfEmailPrototype, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceListResultOfEmailSubstituteField, ServiceSingleResultOfInt64, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfEmailPrototype, ServiceListResultOfAlarm, FilterOfAlarm, QueryContextOfAlarm, ServiceListResultOfQueryBuilder, FilterOfQueryBuilder, QueryContextOfQueryBuilder, ServiceListResultOfWorkflowMail, FilterOfWorkflowMail, QueryContextOfWorkflowMail, EmailPrototypeInput } from '../types'

export type GetEmailPrototypesResultType = {
    emailPrototypes: {
        get?: Maybe<ServiceSingleResultOfEmailPrototype>
    }
}

export type GetEmailPrototypesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getEmailPrototypes ${args} {
    emailPrototypes {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstEmailPrototypesResultType = {
    emailPrototypes: {
        first?: Maybe<ServiceSingleResultOfEmailPrototype>
    }
}

export type FirstEmailPrototypesBaseVariables = {
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstEmailPrototypes ${args} {
    emailPrototypes {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchEmailPrototypesResultType = {
    emailPrototypes: {
        search?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type SearchEmailPrototypesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchEmailPrototypes ${args} {
    emailPrototypes {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaEmailPrototypesResultType = {
    emailPrototypes: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaEmailPrototypesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaEmailPrototypes ${args} {
    emailPrototypes {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataEmailPrototypesResultType = {
    emailPrototypes: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataEmailPrototypesBaseVariables = {
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataEmailPrototypes ${args} {
    emailPrototypes {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryEmailPrototypesResultType = {
    emailPrototypes: {
        customQuery?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type CustomQueryEmailPrototypesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryEmailPrototypes ${args} {
    emailPrototypes {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdEmailPrototypesResultType = {
    emailPrototypes: {
        customQueryId?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type CustomQueryIdEmailPrototypesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdEmailPrototypes ${args} {
    emailPrototypes {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsEmailPrototypesResultType = {
    emailPrototypes: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsEmailPrototypes ${args} {
    emailPrototypes {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsEmailPrototypesResultType = {
    emailPrototypes: {
        findAssociatedOperatorTeamSecurityGroups?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les equipes d'operateur via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamSecurityGroupsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamSecurityGroupsEmailPrototypes ${args} {
    emailPrototypes {
        findAssociatedOperatorTeamSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamSecurityGroupsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamSecurityGroupsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedEmailPrototypesResultType = {
    emailPrototypes: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedEmailPrototypesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedEmailPrototypes ${args} {
    emailPrototypes {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindFieldSubsituteEmailPrototypesResultType = {
    emailPrototypes: {
        findFieldSubsitute?: Maybe<ServiceListResultOfEmailSubstituteField>
    }
}

export type FindFieldSubsituteEmailPrototypesBaseVariables = {
	type: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindFieldSubsituteEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findFieldSubsituteEmailPrototypes ${args} {
    emailPrototypes {
        findFieldSubsitute(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindFieldSubsituteEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindFieldSubsituteEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailSubstituteField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByTypeEmailPrototypesResultType = {
    emailPrototypes: {
        findByType?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type FindByTypeEmailPrototypesBaseVariables = {
	type: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindByTypeEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: [String] = null, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByTypeEmailPrototypes ${args} {
    emailPrototypes {
        findByType(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByTypeEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByTypeEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecycleByTypeEmailPrototypesResultType = {
    emailPrototypes: {
        findRecycleByType?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type FindRecycleByTypeEmailPrototypesBaseVariables = {
	type: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindRecycleByTypeEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: [String] = null, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecycleByTypeEmailPrototypes ${args} {
    emailPrototypes {
        findRecycleByType(type: $type, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecycleByTypeEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecycleByTypeEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountByTypeEmailPrototypesResultType = {
    emailPrototypes: {
        countByType?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountByTypeEmailPrototypesBaseVariables = {
	type: Array<Maybe<string>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CountByTypeEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: [String] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countByTypeEmailPrototypes ${args} {
    emailPrototypes {
        countByType(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountByTypeEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountByTypeEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecycleByTypeEmailPrototypesResultType = {
    emailPrototypes: {
        countRecycleByType?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecycleByTypeEmailPrototypesBaseVariables = {
	type: Array<Maybe<string>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CountRecycleByTypeEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: [String] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecycleByTypeEmailPrototypes ${args} {
    emailPrototypes {
        countRecycleByType(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecycleByTypeEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecycleByTypeEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type EmailPrototypeWithSubsituteEmailPrototypesResultType = {
    emailPrototypes: {
        emailPrototypeWithSubsitute?: Maybe<ServiceSingleResultOfEmailPrototype>
    }
}

export type EmailPrototypeWithSubsituteEmailPrototypesBaseVariables = {
	id: string, /**  */
	ticketId: string, /**  */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class EmailPrototypeWithSubsituteEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketId: Uuid!, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query emailPrototypeWithSubsituteEmailPrototypes ${args} {
    emailPrototypes {
        emailPrototypeWithSubsitute(id: $id, ticketId: $ticketId, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: EmailPrototypeWithSubsituteEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<EmailPrototypeWithSubsituteEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindByTypeAndTicketCategoryEmailPrototypesResultType = {
    emailPrototypes: {
        findByTypeAndTicketCategory?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type FindByTypeAndTicketCategoryEmailPrototypesBaseVariables = {
	type: Array<Maybe<string>>, /**  */
	ticketCategoryId: Array<Maybe<string>>, /**  */
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindByTypeAndTicketCategoryEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: [String] = null, $ticketCategoryId: [String] = null, $filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findByTypeAndTicketCategoryEmailPrototypes ${args} {
    emailPrototypes {
        findByTypeAndTicketCategory(type: $type, ticketCategoryId: $ticketCategoryId, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindByTypeAndTicketCategoryEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindByTypeAndTicketCategoryEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistEmailPrototypesResultType = {
    emailPrototypes: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistEmailPrototypesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existEmailPrototypes ${args} {
    emailPrototypes {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyEmailPrototypesResultType = {
    emailPrototypes: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyEmailPrototypesBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyEmailPrototypes ${args} {
    emailPrototypes {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedEmailPrototypesResultType = {
    emailPrototypes: {
        findArchived?: Maybe<ServiceListResultOfEmailPrototype>
    }
}

export type FindArchivedEmailPrototypesBaseVariables = {
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfEmailPrototype>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfEmailPrototype = null, $options: QueryContextOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedEmailPrototypes ${args} {
    emailPrototypes {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllEmailPrototypesResultType = {
    emailPrototypes: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllEmailPrototypesBaseVariables = {
	filter: Maybe<FilterOfEmailPrototype>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllEmailPrototypes ${args} {
    emailPrototypes {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsEmailPrototypesResultType = {
    emailPrototypes: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfEmailPrototype>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsEmailPrototypes ${args} {
    emailPrototypes {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAlarmsEmailPrototypesResultType = {
    emailPrototypes: {
        findUnassociatedAlarms?: Maybe<ServiceListResultOfAlarm>
    }
}

export type FindUnassociatedAlarmsEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAlarm>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAlarm>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAlarmsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAlarm = null, $options: QueryContextOfAlarm = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAlarmsEmailPrototypes ${args} {
    emailPrototypes {
        findUnassociatedAlarms(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAlarmsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAlarmsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAlarm", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedQueryBuildersEmailPrototypesResultType = {
    emailPrototypes: {
        findUnassociatedQueryBuilders?: Maybe<ServiceListResultOfQueryBuilder>
    }
}

export type FindUnassociatedQueryBuildersEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfQueryBuilder>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfQueryBuilder>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedQueryBuildersEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfQueryBuilder = null, $options: QueryContextOfQueryBuilder = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedQueryBuildersEmailPrototypes ${args} {
    emailPrototypes {
        findUnassociatedQueryBuilders(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedQueryBuildersEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedQueryBuildersEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfQueryBuilder", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedWorkflowMailsEmailPrototypesResultType = {
    emailPrototypes: {
        findUnassociatedWorkflowMails?: Maybe<ServiceListResultOfWorkflowMail>
    }
}

export type FindUnassociatedWorkflowMailsEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfWorkflowMail>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWorkflowMail>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedWorkflowMailsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfWorkflowMail = null, $options: QueryContextOfWorkflowMail = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedWorkflowMailsEmailPrototypes ${args} {
    emailPrototypes {
        findUnassociatedWorkflowMails(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedWorkflowMailsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedWorkflowMailsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWorkflowMail", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertEmailPrototypesResultType = {
    emailPrototypes: {
        insert?: Maybe<ServiceSingleResultOfEmailPrototype>
    }
}

export type InsertEmailPrototypesBaseVariables = {
	entity: EmailPrototypeInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: EmailPrototypeInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertEmailPrototypes ${args} {
    emailPrototypes {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateEmailPrototypesResultType = {
    emailPrototypes: {
        update?: Maybe<ServiceSingleResultOfEmailPrototype>
    }
}

export type UpdateEmailPrototypesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfEmailPrototype>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateEmailPrototypes ${args} {
    emailPrototypes {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEmailPrototype", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataEmailPrototypesResultType = {
    emailPrototypes: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataEmailPrototypesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataEmailPrototypes ${args} {
    emailPrototypes {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyEmailPrototypesResultType = {
    emailPrototypes: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyEmailPrototypesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfEmailPrototype>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfEmailPrototype = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyEmailPrototypes ${args} {
    emailPrototypes {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteEmailPrototypesResultType = {
    emailPrototypes: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteEmailPrototypesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteEmailPrototypes ${args} {
    emailPrototypes {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupEmailPrototypesResultType = {
    emailPrototypes: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupEmailPrototypesBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupEmailPrototypes ${args} {
    emailPrototypes {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupEmailPrototypesResultType = {
    emailPrototypes: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupEmailPrototypesBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupEmailPrototypes ${args} {
    emailPrototypes {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorTeamSecurityGroupEmailPrototypesResultType = {
    emailPrototypes: {
        addOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorTeamSecurityGroupEmailPrototypesBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorTeamSecurityGroupEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorTeamSecurityGroupEmailPrototypes ${args} {
    emailPrototypes {
        addOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorTeamSecurityGroupEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorTeamSecurityGroupEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorTeamSecurityGroupEmailPrototypesResultType = {
    emailPrototypes: {
        removeOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorTeamSecurityGroupEmailPrototypesBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorTeamSecurityGroupEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorTeamSecurityGroupEmailPrototypes ${args} {
    emailPrototypes {
        removeOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorTeamSecurityGroupEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorTeamSecurityGroupEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DuplicateByTypeEmailPrototypesResultType = {
    emailPrototypes: {
        duplicateByType?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DuplicateByTypeEmailPrototypesBaseVariables = {
	id: string, /**  */
	newType: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DuplicateByTypeEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $newType: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation duplicateByTypeEmailPrototypes ${args} {
    emailPrototypes {
        duplicateByType(id: $id, newType: $newType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DuplicateByTypeEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DuplicateByTypeEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreEmailPrototypesResultType = {
    emailPrototypes: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreEmailPrototypesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreEmailPrototypes ${args} {
    emailPrototypes {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleEmailPrototypesResultType = {
    emailPrototypes: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleEmailPrototypesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleEmailPrototypes ${args} {
    emailPrototypes {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedEmailPrototypesResultType = {
    emailPrototypes: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedEmailPrototypesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedEmailPrototypes ${args} {
    emailPrototypes {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedEmailPrototypesResultType = {
    emailPrototypes: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedEmailPrototypesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedEmailPrototypes ${args} {
    emailPrototypes {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldEmailPrototypesResultType = {
    emailPrototypes: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldEmailPrototypes ${args} {
    emailPrototypes {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldEmailPrototypesResultType = {
    emailPrototypes: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldEmailPrototypesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldEmailPrototypes ${args} {
    emailPrototypes {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAlarmsEmailPrototypesResultType = {
    emailPrototypes: {
        addAlarms?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAlarmsEmailPrototypesBaseVariables = {
	id: string, /**  */
	alarmIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAlarmsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $alarmIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAlarmsEmailPrototypes ${args} {
    emailPrototypes {
        addAlarms(id: $id, alarmIds: $alarmIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAlarmsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAlarmsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAlarmsEmailPrototypesResultType = {
    emailPrototypes: {
        removeAlarms?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAlarmsEmailPrototypesBaseVariables = {
	id: string, /**  */
	alarmIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAlarmsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $alarmIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAlarmsEmailPrototypes ${args} {
    emailPrototypes {
        removeAlarms(id: $id, alarmIds: $alarmIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAlarmsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAlarmsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddQueryBuildersEmailPrototypesResultType = {
    emailPrototypes: {
        addQueryBuilders?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddQueryBuildersEmailPrototypesBaseVariables = {
	id: string, /**  */
	queryBuilderIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddQueryBuildersEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $queryBuilderIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addQueryBuildersEmailPrototypes ${args} {
    emailPrototypes {
        addQueryBuilders(id: $id, queryBuilderIds: $queryBuilderIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddQueryBuildersEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddQueryBuildersEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveQueryBuildersEmailPrototypesResultType = {
    emailPrototypes: {
        removeQueryBuilders?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveQueryBuildersEmailPrototypesBaseVariables = {
	id: string, /**  */
	queryBuilderIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveQueryBuildersEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $queryBuilderIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeQueryBuildersEmailPrototypes ${args} {
    emailPrototypes {
        removeQueryBuilders(id: $id, queryBuilderIds: $queryBuilderIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveQueryBuildersEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveQueryBuildersEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddWorkflowMailsEmailPrototypesResultType = {
    emailPrototypes: {
        addWorkflowMails?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddWorkflowMailsEmailPrototypesBaseVariables = {
	id: string, /**  */
	workflowMailIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddWorkflowMailsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $workflowMailIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addWorkflowMailsEmailPrototypes ${args} {
    emailPrototypes {
        addWorkflowMails(id: $id, workflowMailIds: $workflowMailIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddWorkflowMailsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddWorkflowMailsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveWorkflowMailsEmailPrototypesResultType = {
    emailPrototypes: {
        removeWorkflowMails?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveWorkflowMailsEmailPrototypesBaseVariables = {
	workflowMailIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveWorkflowMailsEmailPrototypesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$workflowMailIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeWorkflowMailsEmailPrototypes ${args} {
    emailPrototypes {
        removeWorkflowMails(workflowMailIds: $workflowMailIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveWorkflowMailsEmailPrototypesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveWorkflowMailsEmailPrototypesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
