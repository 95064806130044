import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareHostedLinksBaseVariables, FirstSoftwareHostedLinksBaseVariables, CountSoftwareHostedLinksBaseVariables, FindSoftwareHostedLinksBaseVariables, SearchSoftwareHostedLinksBaseVariables, ExportSchemaSoftwareHostedLinksBaseVariables, ExportDataSoftwareHostedLinksBaseVariables, CustomQuerySoftwareHostedLinksBaseVariables, CustomQueryIdSoftwareHostedLinksBaseVariables, UsedSoftwareHostedLinksBaseVariables, FindHostedComponentSoftwareHostedLinksBaseVariables, FindNotHostedComponentSoftwareHostedLinksBaseVariables, FindSoftwareHostedComponentSoftwareHostedLinksBaseVariables, FindNotSoftwareHostedComponentSoftwareHostedLinksBaseVariables, ExistSoftwareHostedLinksBaseVariables, InsertSoftwareHostedLinksBaseVariables, UpdateSoftwareHostedLinksBaseVariables, ImportDataSoftwareHostedLinksBaseVariables, UpdateManySoftwareHostedLinksBaseVariables, DeleteSoftwareHostedLinksBaseVariables, SaveHostedComponentSoftwareHostedLinksBaseVariables, SaveSoftwareHostedComponentSoftwareHostedLinksBaseVariables } from '../gqls'
import { GetSoftwareHostedLinksDocument, FirstSoftwareHostedLinksDocument, CountSoftwareHostedLinksDocument, FindSoftwareHostedLinksDocument, SearchSoftwareHostedLinksDocument, ExportSchemaSoftwareHostedLinksDocument, ExportDataSoftwareHostedLinksDocument, CustomQuerySoftwareHostedLinksDocument, CustomQueryIdSoftwareHostedLinksDocument, UsedSoftwareHostedLinksDocument, FindHostedComponentSoftwareHostedLinksDocument, FindNotHostedComponentSoftwareHostedLinksDocument, FindSoftwareHostedComponentSoftwareHostedLinksDocument, FindNotSoftwareHostedComponentSoftwareHostedLinksDocument, ExistSoftwareHostedLinksDocument, InsertSoftwareHostedLinksDocument, UpdateSoftwareHostedLinksDocument, ImportDataSoftwareHostedLinksDocument, UpdateManySoftwareHostedLinksDocument, DeleteSoftwareHostedLinksDocument, SaveHostedComponentSoftwareHostedLinksDocument, SaveSoftwareHostedComponentSoftwareHostedLinksDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareHostedLink, QueryContextOfSoftwareHostedLink, FilterOfSoftwareHostedLink, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareHostedLink, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfSoftware, QueryContextOfSoftware, FilterOfSoftware, SoftwareHostedLinkInput, FieldUpdateOperatorOfSoftwareHostedLink } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareHostedLinkBaseService {
    
public modelName = 'softwareHostedLink';
public modelPluralName = 'softwareHostedLinks';

	private getSoftwareHostedLinksQuery: GetSoftwareHostedLinksDocument;
	private firstSoftwareHostedLinksQuery: FirstSoftwareHostedLinksDocument;
	private countSoftwareHostedLinksQuery: CountSoftwareHostedLinksDocument;
	private findSoftwareHostedLinksQuery: FindSoftwareHostedLinksDocument;
	private searchSoftwareHostedLinksQuery: SearchSoftwareHostedLinksDocument;
	private exportSchemaSoftwareHostedLinksQuery: ExportSchemaSoftwareHostedLinksDocument;
	private exportDataSoftwareHostedLinksQuery: ExportDataSoftwareHostedLinksDocument;
	private customQuerySoftwareHostedLinksQuery: CustomQuerySoftwareHostedLinksDocument;
	private customQueryIdSoftwareHostedLinksQuery: CustomQueryIdSoftwareHostedLinksDocument;
	private usedSoftwareHostedLinksQuery: UsedSoftwareHostedLinksDocument;
	private findHostedComponentSoftwareHostedLinksQuery: FindHostedComponentSoftwareHostedLinksDocument;
	private findNotHostedComponentSoftwareHostedLinksQuery: FindNotHostedComponentSoftwareHostedLinksDocument;
	private findSoftwareHostedComponentSoftwareHostedLinksQuery: FindSoftwareHostedComponentSoftwareHostedLinksDocument;
	private findNotSoftwareHostedComponentSoftwareHostedLinksQuery: FindNotSoftwareHostedComponentSoftwareHostedLinksDocument;
	private existSoftwareHostedLinksQuery: ExistSoftwareHostedLinksDocument;
	private insertSoftwareHostedLinksMutation: InsertSoftwareHostedLinksDocument;
	private updateSoftwareHostedLinksMutation: UpdateSoftwareHostedLinksDocument;
	private importDataSoftwareHostedLinksMutation: ImportDataSoftwareHostedLinksDocument;
	private updateManySoftwareHostedLinksMutation: UpdateManySoftwareHostedLinksDocument;
	private deleteSoftwareHostedLinksMutation: DeleteSoftwareHostedLinksDocument;
	private saveHostedComponentSoftwareHostedLinksMutation: SaveHostedComponentSoftwareHostedLinksDocument;
	private saveSoftwareHostedComponentSoftwareHostedLinksMutation: SaveSoftwareHostedComponentSoftwareHostedLinksDocument;

	constructor(private injector: Injector) {
		this.getSoftwareHostedLinksQuery = this.injector.get(GetSoftwareHostedLinksDocument);
		this.firstSoftwareHostedLinksQuery = this.injector.get(FirstSoftwareHostedLinksDocument);
		this.countSoftwareHostedLinksQuery = this.injector.get(CountSoftwareHostedLinksDocument);
		this.findSoftwareHostedLinksQuery = this.injector.get(FindSoftwareHostedLinksDocument);
		this.searchSoftwareHostedLinksQuery = this.injector.get(SearchSoftwareHostedLinksDocument);
		this.exportSchemaSoftwareHostedLinksQuery = this.injector.get(ExportSchemaSoftwareHostedLinksDocument);
		this.exportDataSoftwareHostedLinksQuery = this.injector.get(ExportDataSoftwareHostedLinksDocument);
		this.customQuerySoftwareHostedLinksQuery = this.injector.get(CustomQuerySoftwareHostedLinksDocument);
		this.customQueryIdSoftwareHostedLinksQuery = this.injector.get(CustomQueryIdSoftwareHostedLinksDocument);
		this.usedSoftwareHostedLinksQuery = this.injector.get(UsedSoftwareHostedLinksDocument);
		this.findHostedComponentSoftwareHostedLinksQuery = this.injector.get(FindHostedComponentSoftwareHostedLinksDocument);
		this.findNotHostedComponentSoftwareHostedLinksQuery = this.injector.get(FindNotHostedComponentSoftwareHostedLinksDocument);
		this.findSoftwareHostedComponentSoftwareHostedLinksQuery = this.injector.get(FindSoftwareHostedComponentSoftwareHostedLinksDocument);
		this.findNotSoftwareHostedComponentSoftwareHostedLinksQuery = this.injector.get(FindNotSoftwareHostedComponentSoftwareHostedLinksDocument);
		this.existSoftwareHostedLinksQuery = this.injector.get(ExistSoftwareHostedLinksDocument);
		this.insertSoftwareHostedLinksMutation = this.injector.get(InsertSoftwareHostedLinksDocument);
		this.updateSoftwareHostedLinksMutation = this.injector.get(UpdateSoftwareHostedLinksDocument);
		this.importDataSoftwareHostedLinksMutation = this.injector.get(ImportDataSoftwareHostedLinksDocument);
		this.updateManySoftwareHostedLinksMutation = this.injector.get(UpdateManySoftwareHostedLinksDocument);
		this.deleteSoftwareHostedLinksMutation = this.injector.get(DeleteSoftwareHostedLinksDocument);
		this.saveHostedComponentSoftwareHostedLinksMutation = this.injector.get(SaveHostedComponentSoftwareHostedLinksDocument);
		this.saveSoftwareHostedComponentSoftwareHostedLinksMutation = this.injector.get(SaveSoftwareHostedComponentSoftwareHostedLinksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareHostedLink> {
    
    		let variables: GetSoftwareHostedLinksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareHostedLink={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareHostedLink> {
    
    		let variables: FirstSoftwareHostedLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareHostedLinksBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: FindSoftwareHostedLinksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: SearchSoftwareHostedLinksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareHostedLinksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareHostedLinksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: CustomQuerySoftwareHostedLinksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: CustomQueryIdSoftwareHostedLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareHostedLinksBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.used));
    	}

    	/**  */
    	@InjectArgs
    	public findHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: FindHostedComponentSoftwareHostedLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHostedComponentSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.findHostedComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findNotHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindNotHostedComponentSoftwareHostedLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotHostedComponentSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.findNotHostedComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findSoftwareHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareHostedLink,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareHostedLink> {
    
    		let variables: FindSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findSoftwareHostedComponentSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.findSoftwareHostedComponent));
    	}

    	/**  */
    	@InjectArgs
    	public findNotSoftwareHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftware,
		@Args('filter?') filter?: FilterOfSoftware,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftware> {
    
    		let variables: FindNotSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotSoftwareHostedComponentSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.findNotSoftwareHostedComponent));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareHostedLinksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareHostedLinksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareHostedLinkInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareHostedLink> {
    
    		let variables: InsertSoftwareHostedLinksBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareHostedLink> {
    
    		let variables: UpdateSoftwareHostedLinksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareHostedLinksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareHostedLink,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareHostedLinksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareHostedLinksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.delete));
    	}

    	/**  */
    	@InjectArgs
    	public saveHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('items?') items?: Array<Maybe<SoftwareHostedLinkInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SaveHostedComponentSoftwareHostedLinksBaseVariables = {
    			id: id,
			items: items
    		}
    				return this.saveHostedComponentSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.saveHostedComponent));
    	}

    	/**  */
    	@InjectArgs
    	public saveSoftwareHostedComponent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('items?') items?: Array<Maybe<SoftwareHostedLinkInput>>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SaveSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
    			id: id,
			items: items
    		}
    				return this.saveSoftwareHostedComponentSoftwareHostedLinksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareHostedLinks.saveSoftwareHostedComponent));
    	}
    
}