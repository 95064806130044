import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCodecFilesBaseVariables, FirstCodecFilesBaseVariables, CountCodecFilesBaseVariables, FindCodecFilesBaseVariables, SearchCodecFilesBaseVariables, ExportSchemaCodecFilesBaseVariables, ExportDataCodecFilesBaseVariables, CustomQueryCodecFilesBaseVariables, CustomQueryIdCodecFilesBaseVariables, UsedCodecFilesBaseVariables, ExistCodecFilesBaseVariables } from '../gqls'
import { GetCodecFilesDocument, FirstCodecFilesDocument, CountCodecFilesDocument, FindCodecFilesDocument, SearchCodecFilesDocument, ExportSchemaCodecFilesDocument, ExportDataCodecFilesDocument, CustomQueryCodecFilesDocument, CustomQueryIdCodecFilesDocument, UsedCodecFilesDocument, ExistCodecFilesDocument } from '../gqls'
import { ServiceSingleResultOfCodecFile, QueryContextOfCodecFile, FilterOfCodecFile, ServiceSingleResultOfInt64, ServiceListResultOfCodecFile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CodecFileBaseService {
    
public modelName = 'codecFile';
public modelPluralName = 'codecFiles';

	private getCodecFilesQuery: GetCodecFilesDocument;
	private firstCodecFilesQuery: FirstCodecFilesDocument;
	private countCodecFilesQuery: CountCodecFilesDocument;
	private findCodecFilesQuery: FindCodecFilesDocument;
	private searchCodecFilesQuery: SearchCodecFilesDocument;
	private exportSchemaCodecFilesQuery: ExportSchemaCodecFilesDocument;
	private exportDataCodecFilesQuery: ExportDataCodecFilesDocument;
	private customQueryCodecFilesQuery: CustomQueryCodecFilesDocument;
	private customQueryIdCodecFilesQuery: CustomQueryIdCodecFilesDocument;
	private usedCodecFilesQuery: UsedCodecFilesDocument;
	private existCodecFilesQuery: ExistCodecFilesDocument;

	constructor(private injector: Injector) {
		this.getCodecFilesQuery = this.injector.get(GetCodecFilesDocument);
		this.firstCodecFilesQuery = this.injector.get(FirstCodecFilesDocument);
		this.countCodecFilesQuery = this.injector.get(CountCodecFilesDocument);
		this.findCodecFilesQuery = this.injector.get(FindCodecFilesDocument);
		this.searchCodecFilesQuery = this.injector.get(SearchCodecFilesDocument);
		this.exportSchemaCodecFilesQuery = this.injector.get(ExportSchemaCodecFilesDocument);
		this.exportDataCodecFilesQuery = this.injector.get(ExportDataCodecFilesDocument);
		this.customQueryCodecFilesQuery = this.injector.get(CustomQueryCodecFilesDocument);
		this.customQueryIdCodecFilesQuery = this.injector.get(CustomQueryIdCodecFilesDocument);
		this.usedCodecFilesQuery = this.injector.get(UsedCodecFilesDocument);
		this.existCodecFilesQuery = this.injector.get(ExistCodecFilesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCodecFile> {
    
    		let variables: GetCodecFilesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.get));
            }
            else{
                let result:ServiceSingleResultOfCodecFile={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCodecFile,
		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCodecFile> {
    
    		let variables: FirstCodecFilesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCodecFilesBaseVariables = {
    			filter: filter
    		}
    				return this.countCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCodecFile,
		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCodecFile> {
    
    		let variables: FindCodecFilesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCodecFile,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCodecFile> {
    
    		let variables: SearchCodecFilesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCodecFilesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCodecFilesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCodecFile,
		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCodecFile> {
    
    		let variables: CustomQueryCodecFilesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCodecFile,
		@Args('filter?') filter?: FilterOfCodecFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCodecFile> {
    
    		let variables: CustomQueryIdCodecFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCodecFilesBaseVariables = {
    			ids: ids
    		}
    				return this.usedCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCodecFilesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCodecFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.codecFiles.exist));
    	}
    
    
}