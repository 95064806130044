import { ResolutionModelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize, Maybe } from '@clarilog/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfResolutionCategory,
  FilterOfResolutionModel,
  QueryContextOfResolutionCategory,
  QueryContextOfResolutionModel,
  ServiceListResultOfResolutionCategory,
  ServiceListResultOfResolutionModel,
} from '../types';

@Authorize('help-desk-operator.manage-resolution-model')
@Injectable({ providedIn: 'root' })
export class ResolutionModelCoreService extends ResolutionModelBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Permet de recuperer les modèles d'intervention associé a certaines équipes opérateurs. */
  @InjectArgs
  public findByTeamOperatorLinkedCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('ticketCategoriesIds?') ticketCategoriesIds?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfResolutionModel,
    @Args('filter?') filter?: FilterOfResolutionModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionModel> {
    if (filter != null) {
      ticketCategoriesIds = filter.ticketCategoriesIds.elemMatch.in;
    }
    return this.findByTeamOperatorLinked(
      fields,
      ticketCategoriesIds,
      options,
      filter,
      extendedVariables,
    );
  }

  //Retourne les Categories non associé a une resolution
  @InjectArgs
  public findUnassociatedResolutionCategoriesCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],
    @Args('options?') options?: QueryContextOfResolutionCategory,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfResolutionCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfResolutionCategory> {
    let qualificationFilter: FilterOfResolutionCategory = {
      qualification: { elemMatch: { in: qualification } },
    };
    let createFilter: FilterOfResolutionCategory = {};
    if (qualification.length == 1) {
      if (filter == null) {
        createFilter = qualificationFilter;
      } else {
        createFilter = this.createFilter(qualificationFilter, filter);
      }
    } else {
      createFilter = filter;
    }

    return this.findUnassociatedResolutionCategories(
      fields,
      options,
      id,
      createFilter,
      extendedVariables,
    );
  }

  //Construction du filtre pour la méthode findUnassociatedResolutionCategoriesCustom
  private createFilter(
    qualificationFilter: FilterOfResolutionCategory,
    filter: FilterOfResolutionCategory,
  ): FilterOfResolutionCategory {
    let createFilter: FilterOfResolutionCategory = {};
    if (filter?.and?.length > 1) {
      let createAndFilterTable = [];
      filter?.and?.forEach((element) => {
        if (element?.qualification == undefined) {
          createAndFilterTable.push(element);
        }
      });
      createAndFilterTable.push(qualificationFilter);
      if (createAndFilterTable.length > 1) {
        createFilter = { and: createAndFilterTable };
      } else if (
        createAndFilterTable.length == 1 &&
        createAndFilterTable.length > 0
      ) {
        createFilter = createAndFilterTable[0];
      }
    } else {
      if (filter?.qualification != undefined) {
        createFilter = qualificationFilter;
      } else {
        createFilter = { and: [filter, qualificationFilter] };
      }
    }

    return createFilter;
  }
}
