import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnChanges,
  OnInit,
  Output,
  SimpleChanges,
  Directive,
  ElementRef,
} from '@angular/core';
import { RouterLink } from '@angular/router';
import DevExpress from 'devextreme/bundles/dx.all';
import { TranslatedFieldHelperService } from '../../translate-field';

/** Représente le type de l'action :
 * - normal : action commune.
 * - default : action par défaut.
 */
export type ActionType = 'default' | 'normal';
/** Représente la localisation dans le toolbar. */
export type LocationType = 'after' | 'before' | 'center';
/** Représente le type d'affichage dans le toolbar. */
export type LocateInMenuType = 'always' | 'auto' | 'never';
/** Représente el mode d'affichage du texte. */
export type ShowTextMode = 'always' | 'inMenu';
/**
 * Représente un item d'un toolbar.
 */
@Directive()
export abstract class ToolbarItemComponent implements OnChanges {
  /** Lorsqu'une option change. */
  @Output() onOptionChanged: EventEmitter<{
    key: string;
    value: any;
  }> = new EventEmitter<{ key: string; value: any }>();
  /** Obtient ou définit l'état de position. */
  @Input() location: LocationType = 'before';
  /** Obtient ou définit la localisation du composant action dans le toolbar. */
  @Input() locateInMenu: LocateInMenuType = 'auto';
  /** Obtient ou définit le text. */
  @Input() text: string;
  /** Obtient ou définit le text. */
  @Input() multipleText: boolean = false;
  /** Obtient ou définit la visibilité. */
  @Input() visible: boolean = true;
  /** Obtient ou définit l'état désactivé. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit le template lorsque l'élément est dans le menu. */
  @Input() menuItemTemplate: string;
  /** Obtient ou définit la ou les classes css. */
  @Input() cssClass: string = undefined;

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    Object.keys(changes).map((key) => {
      this.onOptionChanged.emit({ key: key, value: changes[key].currentValue });
    });
  }
}
/** Représente le mode du style. */
export type ButtonStylingMode = 'text' | 'outlined' | 'contained';
/** Représente un item button de base d'un toolbar. */
@Directive()
export abstract class ToolbarItemButtonBaseComponent
  extends ToolbarItemComponent
  implements OnInit
{
  constructor() {
    super();
  }
  /** Obtient ou définit le template. */
  @Input() template: string;
  /** Obtient ou définit le texte au survole de la souris. */
  @Input() hint: string;
  /** Obtient ou définit le type de l'action. */
  @Input() type: ActionType = 'normal';
  /** Obtient ou définit l'icône. */
  @Input() icon: string;
  /** Obtient ou définit le mode du style. */
  @Input() stylingMode: ButtonStylingMode = 'text';
  /** Obtient ou définit les propriétés supplémentaires à ajouter sur le tag html. */
  @Input() elementAttr = {
    class: '',
  };
  /** @inheritdoc */
  ngOnInit(): void {
    if (this.hint === undefined) {
      this.hint = this.text;
    }
    if (this.elementAttr != undefined && this.elementAttr.class != undefined) {
      this.elementAttr.class = `${this.elementAttr.class} ${this.type}`;
    }
  }
}
/** Représente un item button d'un toolbar. */
// @Component({
//   selector: 'cl-toolbar-separator',
//   template: '<ng-content></ng-content>',
//   providers: [
//     RouterLink,
//     [{ provide: ToolbarItemComponent, useExisting: forwardRef(() => ToolbarSeparatorComponent) }]
//   ]
// })
// export class ToolbarSeparatorComponent extends ToolbarItemComponent {
//   /** Obtient ou définit le mode d'affichage du texte. */
//   constructor(routerLink: RouterLink) {
//     super();
//     // this.text = '|';
//     // this.locateInMenu = 'never';
//     // this.menuItemTemplate = '<div style="border-bottom: 1px solid #e0e0e0;height:1px"></div>';
//     this.disabled = true;
//     this.cssClass = 'cl-toolbar-separator';
//   }
// }
/** Représente un item button d'un toolbar. */
@Component({
  selector: 'cl-toolbar-item-button',
  template: '<ng-content></ng-content>',
  providers: [
    RouterLink,
    [
      {
        provide: ToolbarItemComponent,
        useExisting: forwardRef(() => ToolbarItemButtonComponent),
      },
    ],
  ],
})
export class ToolbarItemButtonComponent extends ToolbarItemButtonBaseComponent {
  /** Événement du click. */
  @Output() readonly onClick: EventEmitter<void> = new EventEmitter<void>();
  /** Obtient ou définit le mode d'affichage du texte. */
  @Input() showText: ShowTextMode = 'always';

  constructor(public elementRef: ElementRef) {
    super();

    this.onClick.subscribe((_) => {
      this.elementRef.nativeElement.click();
    });
  }
}

/** Représente un item button d'un toolbar. */
@Component({
  selector: 'cl-toolbar-item-wait-button',
  template: '<ng-content></ng-content>',
  providers: [
    RouterLink,
    [
      {
        provide: ToolbarItemComponent,
        useExisting: forwardRef(() => ToolbarItemWaitButtonComponent),
      },
    ],
  ],
})
export class ToolbarItemWaitButtonComponent extends ToolbarItemButtonBaseComponent {
  /** Événement du click. */
  @Output() readonly onClick: EventEmitter<void> = new EventEmitter<void>();
  /** Obtient ou définit le mode d'affichage du texte. */
  @Input() showText: ShowTextMode = 'always';

  /** Obtient ou définit l'icône original de base. */
  @Input() originalIcon: string;
  /** Obtient ou définit l'etat pour l'icone de chargement. */
  @Input() wait: boolean = false;
  /** Obtient ou définit si  le panel de chargement est affiché */
  @Input() showLoadPanel: boolean = false;

  constructor(public elementRef: ElementRef) {
    super();
    this.onClick.subscribe((_) => {
      this.elementRef.nativeElement.click();
    });
  }
}

/** Représente un item button d'un toolbar. */
@Component({
  selector: 'cl-toolbar-item-dropdown-button',
  template: '<ng-content></ng-content>',
  providers: [
    RouterLink,
    [
      {
        provide: ToolbarItemComponent,
        useExisting: forwardRef(() => ToolbarItemDropDownButtonComponent),
      },
    ],
  ],
})
export class ToolbarItemDropDownButtonComponent
  extends ToolbarItemButtonBaseComponent
  implements OnInit
{
  /** Obtient ou définit la source de données. */
  @Input() source: any;
  /** Obtient ou définit le nom du champ de la clé. */
  @Input() keyExpr: string;
  /** Obtient ou définit le nom du champ affiché. */
  private _displayExpr: string;

  @Input() set displayExpr(value: string) {
    this._displayExpr = value;
    return;
    // if (this.translatable === true) {
    //   this._displayExpr = value + "." + this.translatedFieldHelperService.getTranslateKey();
    // } else {
    //   this._displayExpr = value;
    // }
  }
  get displayExpr(): string {
    // console.log(this._displayExpr);
    if (this.translatable === true) {
      return this.translatedFieldHelperService.setColumnTranslateField(
        this._displayExpr,
      );
    }
    return this._displayExpr;
  }

  @Input() translatable: boolean;
  /** Obtient ou définit le mode split du dropdown. */
  @Input() splitButton: boolean = false;
  /** Obtient ou définit les options du dropdown. */
  @Input() dropDownOptions = <
    DevExpress.ui.dxPopupOptions<ToolbarItemDropDownButtonComponent>
  >{
    width: '250px',
    height: 'auto',
    /** Valeur d'origine à 218px */

    maxHeight: 'max-content',
  };
  /** Obtient ou définit les items. */
  @Input() items: any;
  /** Obtient ou définit une valeur indiquant si le choix de l'utilisateur est recopié sur le bouton. */
  @Input() useSelectMode: boolean = false;
  /** Se déclenche lors du clique du bouton. */
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  /** Se déclenche lors du clique d'un élément. */
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>();

  constructor(
    routerLink: RouterLink,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super();
    this.onButtonClick.subscribe((_) => {
      if (
        routerLink['commands'] != undefined &&
        routerLink['commands'].length > 0
      ) {
        // routerLink.onClick();
      }
    });
  }
}

/** Représente un item button d'un toolbar. */
@Component({
  selector: 'cl-toolbar-item-menu-button',
  template: '',
  providers: [
    RouterLink,
    [
      {
        provide: ToolbarItemComponent,
        useExisting: forwardRef(() => ToolbarItemMenuButtonComponent),
      },
    ],
  ],
})
export class ToolbarItemMenuButtonComponent
  extends ToolbarItemButtonBaseComponent
  implements OnInit
{
  /** Obtient ou définit la source de données. */
  @Input() source: any;
  /** Obtient ou définit le nom du champ de la clé. */
  @Input() keyExpr: string;
  /** Obtient ou définit le nom du champ affiché. */
  @Input() displayExpr: string;

  /** Obtient ou définit les options du dropdown. */
  @Input() dropDownOptions = <
    DevExpress.ui.dxPopupOptions<ToolbarItemMenuButtonComponent>
  >{
    width: '250px',
    height: 'auto',
    maxHeight: '218px',
  };

  /** Obtient ou définit les items. */
  @Input() items: any;
  /** Obtient ou définit une valeur indiquant si le choix de l'utilisateur est recopié sur le bouton. */
  @Input() useSelectMode: boolean = false;
  /** Se déclenche lors du clique du bouton. */
  @Output() onButtonClick: EventEmitter<any> = new EventEmitter<any>();
  /** Se déclenche lors du clique d'un élément. */
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>();

  constructor() {
    super();
  }
}

/** Représente le mode de sélection. */
export type ButtonGroupSelectionMode = 'multiple' | 'single';
/** Représente un item GroupButton d'un toolbar. */
@Component({
  selector: 'cl-toolbar-item-button-group',
  template: '<ng-content></ng-content>',
  providers: [
    [
      {
        provide: ToolbarItemComponent,
        useExisting: forwardRef(() => ToolbarItemButtonGroupComponent),
      },
    ],
  ],
})
export class ToolbarItemButtonGroupComponent extends ToolbarItemButtonBaseComponent {
  /** Obtient ou définit les items. */
  @Input() items: any;
  /** Obtient ou définit le nom du champ de la clé. */
  @Input() keyExpr: string;
  /** Obtient ou définit le mode de sélection. */
  @Input() selectionMode: ButtonGroupSelectionMode = 'single';
  /** Se déclenche lors du clique d'un élément. */
  @Output() onItemClick: EventEmitter<any> = new EventEmitter<any>();
  /** Obtient ou définit les clés sélectionnées. */
  @Input() selectedItemKeys: any[];
  constructor() {
    super();
  }
}
