import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoundDevicesBaseVariables, FirstSoundDevicesBaseVariables, CountSoundDevicesBaseVariables, FindSoundDevicesBaseVariables, SearchSoundDevicesBaseVariables, ExportSchemaSoundDevicesBaseVariables, ExportDataSoundDevicesBaseVariables, CustomQuerySoundDevicesBaseVariables, CustomQueryIdSoundDevicesBaseVariables, UsedSoundDevicesBaseVariables, ExistSoundDevicesBaseVariables } from '../gqls'
import { GetSoundDevicesDocument, FirstSoundDevicesDocument, CountSoundDevicesDocument, FindSoundDevicesDocument, SearchSoundDevicesDocument, ExportSchemaSoundDevicesDocument, ExportDataSoundDevicesDocument, CustomQuerySoundDevicesDocument, CustomQueryIdSoundDevicesDocument, UsedSoundDevicesDocument, ExistSoundDevicesDocument } from '../gqls'
import { ServiceSingleResultOfSoundDevice, QueryContextOfSoundDevice, FilterOfSoundDevice, ServiceSingleResultOfInt64, ServiceListResultOfSoundDevice, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoundDeviceBaseService {
    
public modelName = 'soundDevice';
public modelPluralName = 'soundDevices';

	private getSoundDevicesQuery: GetSoundDevicesDocument;
	private firstSoundDevicesQuery: FirstSoundDevicesDocument;
	private countSoundDevicesQuery: CountSoundDevicesDocument;
	private findSoundDevicesQuery: FindSoundDevicesDocument;
	private searchSoundDevicesQuery: SearchSoundDevicesDocument;
	private exportSchemaSoundDevicesQuery: ExportSchemaSoundDevicesDocument;
	private exportDataSoundDevicesQuery: ExportDataSoundDevicesDocument;
	private customQuerySoundDevicesQuery: CustomQuerySoundDevicesDocument;
	private customQueryIdSoundDevicesQuery: CustomQueryIdSoundDevicesDocument;
	private usedSoundDevicesQuery: UsedSoundDevicesDocument;
	private existSoundDevicesQuery: ExistSoundDevicesDocument;

	constructor(private injector: Injector) {
		this.getSoundDevicesQuery = this.injector.get(GetSoundDevicesDocument);
		this.firstSoundDevicesQuery = this.injector.get(FirstSoundDevicesDocument);
		this.countSoundDevicesQuery = this.injector.get(CountSoundDevicesDocument);
		this.findSoundDevicesQuery = this.injector.get(FindSoundDevicesDocument);
		this.searchSoundDevicesQuery = this.injector.get(SearchSoundDevicesDocument);
		this.exportSchemaSoundDevicesQuery = this.injector.get(ExportSchemaSoundDevicesDocument);
		this.exportDataSoundDevicesQuery = this.injector.get(ExportDataSoundDevicesDocument);
		this.customQuerySoundDevicesQuery = this.injector.get(CustomQuerySoundDevicesDocument);
		this.customQueryIdSoundDevicesQuery = this.injector.get(CustomQueryIdSoundDevicesDocument);
		this.usedSoundDevicesQuery = this.injector.get(UsedSoundDevicesDocument);
		this.existSoundDevicesQuery = this.injector.get(ExistSoundDevicesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoundDevice> {
    
    		let variables: GetSoundDevicesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.get));
            }
            else{
                let result:ServiceSingleResultOfSoundDevice={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoundDevice,
		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoundDevice> {
    
    		let variables: FirstSoundDevicesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoundDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.countSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoundDevice,
		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoundDevice> {
    
    		let variables: FindSoundDevicesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoundDevice,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoundDevice> {
    
    		let variables: SearchSoundDevicesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoundDevicesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoundDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoundDevice,
		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoundDevice> {
    
    		let variables: CustomQuerySoundDevicesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoundDevice,
		@Args('filter?') filter?: FilterOfSoundDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoundDevice> {
    
    		let variables: CustomQueryIdSoundDevicesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoundDevicesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoundDevicesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoundDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.soundDevices.exist));
    	}
    
    
}