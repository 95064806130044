import { Component, OnInit } from '@angular/core';

/** Représente le contenu du composant WorkPage. */
@Component({
  selector: 'cl-work-page-content',
  template: '<ng-content></ng-content>',
  styleUrls: ['./work-page-content.component.scss']
})
export class WorkPageContentComponent {
  constructor() { }
}
