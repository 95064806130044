import gql from 'graphql-tag';
import { map } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ApolloQueryResult } from '@apollo/client/core/types';
import { Apollo } from 'apollo-angular';
import { ParseCustomGqlField } from './generated-types/helpers';
import { Observable } from 'rxjs';

export type OfTypeGetTypeFields = {
  name: string;
  kind: string;
};
export type TypeGetTypeFields = {
  name;
  kind;
  ofType: OfTypeGetTypeFields;
};

export type FieldGetTypeFields = {
  name: string;
  type: TypeGetTypeFields;
};

export type GetTypeFieldsResultType = {
  __type: {
    name: string;
    fields: Array<FieldGetTypeFields>;
  };
};

export type GetTypeFieldsBaseVariables = {
  type: String /** L'expression du filtre à appliquer. */;
};

/** Compte le nombre d'entité selon le filtre. */
@Injectable({ providedIn: 'root' })
export class GetTypeFieldsDocument {
  public document() {
    return gql`
      query getTypeFields($type: String!) {
        __type(name: $type) {
          name
          fields {
            name
            type {
              name
              kind
              ofType {
                name
                kind
              }
            }
          }
        }
      }
    `;
  }

  constructor(
    private apollo: Apollo,
    private fieldGenerator: ParseCustomGqlField,
  ) { }

  public query(
    variables: GetTypeFieldsBaseVariables,
  ): Observable<ApolloQueryResult<GetTypeFieldsResultType>> {
    return this.apollo.use('v2').query({
      query: this.document(),
      variables: { ...variables },
    });
  }
}

@Injectable({
  providedIn: 'root',
})
export class GetTypeFieldsService {
  constructor(private getTypeFieldsQuery: GetTypeFieldsDocument) { }

  getTypeFields(type: string): Observable<Array<FieldGetTypeFields>> {
    switch (type) {
      case 'ScanConfiguration':
        type = 'IScanConfiguration';
        break;
    }
    return this.getTypeFieldsQuery.query({ type: type }).pipe(
      map((result) => {
        return result.data.__type.fields;
      }),
    );
  }
}
