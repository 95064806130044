import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';
import { LoanCoreService } from './loan.service';

@Authorize('help-desk-user.my-loan-desk-user')
@Injectable({ providedIn: 'root' })
export class LoanHelpDeskCoreService extends LoanCoreService {
  constructor(      
    injector: Injector   
  ) {
    super(injector);
  }
}