import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDynamicPropertyFieldsBaseVariables, FirstDynamicPropertyFieldsBaseVariables, CountDynamicPropertyFieldsBaseVariables, FindDynamicPropertyFieldsBaseVariables, SearchDynamicPropertyFieldsBaseVariables, ExportSchemaDynamicPropertyFieldsBaseVariables, ExportDataDynamicPropertyFieldsBaseVariables, CustomQueryDynamicPropertyFieldsBaseVariables, CustomQueryIdDynamicPropertyFieldsBaseVariables, UsedDynamicPropertyFieldsBaseVariables, FindByTypeDynamicPropertyFieldsBaseVariables, ExistDynamicPropertyFieldsBaseVariables, InsertDynamicPropertyFieldsBaseVariables, UpdateDynamicPropertyFieldsBaseVariables, ImportDataDynamicPropertyFieldsBaseVariables, UpdateManyDynamicPropertyFieldsBaseVariables, DeleteDynamicPropertyFieldsBaseVariables } from '../gqls'
import { GetDynamicPropertyFieldsDocument, FirstDynamicPropertyFieldsDocument, CountDynamicPropertyFieldsDocument, FindDynamicPropertyFieldsDocument, SearchDynamicPropertyFieldsDocument, ExportSchemaDynamicPropertyFieldsDocument, ExportDataDynamicPropertyFieldsDocument, CustomQueryDynamicPropertyFieldsDocument, CustomQueryIdDynamicPropertyFieldsDocument, UsedDynamicPropertyFieldsDocument, FindByTypeDynamicPropertyFieldsDocument, ExistDynamicPropertyFieldsDocument, InsertDynamicPropertyFieldsDocument, UpdateDynamicPropertyFieldsDocument, ImportDataDynamicPropertyFieldsDocument, UpdateManyDynamicPropertyFieldsDocument, DeleteDynamicPropertyFieldsDocument } from '../gqls'
import { ServiceSingleResultOfDynamicPropertyField, QueryContextOfDynamicPropertyField, FilterOfDynamicPropertyField, ServiceSingleResultOfInt64, ServiceListResultOfDynamicPropertyField, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, DynamicPropertyFieldInput, FieldUpdateOperatorOfDynamicPropertyField } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DynamicPropertyFieldBaseService {
    
public modelName = 'dynamicPropertyField';
public modelPluralName = 'dynamicPropertyFields';

	private getDynamicPropertyFieldsQuery: GetDynamicPropertyFieldsDocument;
	private firstDynamicPropertyFieldsQuery: FirstDynamicPropertyFieldsDocument;
	private countDynamicPropertyFieldsQuery: CountDynamicPropertyFieldsDocument;
	private findDynamicPropertyFieldsQuery: FindDynamicPropertyFieldsDocument;
	private searchDynamicPropertyFieldsQuery: SearchDynamicPropertyFieldsDocument;
	private exportSchemaDynamicPropertyFieldsQuery: ExportSchemaDynamicPropertyFieldsDocument;
	private exportDataDynamicPropertyFieldsQuery: ExportDataDynamicPropertyFieldsDocument;
	private customQueryDynamicPropertyFieldsQuery: CustomQueryDynamicPropertyFieldsDocument;
	private customQueryIdDynamicPropertyFieldsQuery: CustomQueryIdDynamicPropertyFieldsDocument;
	private usedDynamicPropertyFieldsQuery: UsedDynamicPropertyFieldsDocument;
	private findByTypeDynamicPropertyFieldsQuery: FindByTypeDynamicPropertyFieldsDocument;
	private existDynamicPropertyFieldsQuery: ExistDynamicPropertyFieldsDocument;
	private insertDynamicPropertyFieldsMutation: InsertDynamicPropertyFieldsDocument;
	private updateDynamicPropertyFieldsMutation: UpdateDynamicPropertyFieldsDocument;
	private importDataDynamicPropertyFieldsMutation: ImportDataDynamicPropertyFieldsDocument;
	private updateManyDynamicPropertyFieldsMutation: UpdateManyDynamicPropertyFieldsDocument;
	private deleteDynamicPropertyFieldsMutation: DeleteDynamicPropertyFieldsDocument;

	constructor(private injector: Injector) {
		this.getDynamicPropertyFieldsQuery = this.injector.get(GetDynamicPropertyFieldsDocument);
		this.firstDynamicPropertyFieldsQuery = this.injector.get(FirstDynamicPropertyFieldsDocument);
		this.countDynamicPropertyFieldsQuery = this.injector.get(CountDynamicPropertyFieldsDocument);
		this.findDynamicPropertyFieldsQuery = this.injector.get(FindDynamicPropertyFieldsDocument);
		this.searchDynamicPropertyFieldsQuery = this.injector.get(SearchDynamicPropertyFieldsDocument);
		this.exportSchemaDynamicPropertyFieldsQuery = this.injector.get(ExportSchemaDynamicPropertyFieldsDocument);
		this.exportDataDynamicPropertyFieldsQuery = this.injector.get(ExportDataDynamicPropertyFieldsDocument);
		this.customQueryDynamicPropertyFieldsQuery = this.injector.get(CustomQueryDynamicPropertyFieldsDocument);
		this.customQueryIdDynamicPropertyFieldsQuery = this.injector.get(CustomQueryIdDynamicPropertyFieldsDocument);
		this.usedDynamicPropertyFieldsQuery = this.injector.get(UsedDynamicPropertyFieldsDocument);
		this.findByTypeDynamicPropertyFieldsQuery = this.injector.get(FindByTypeDynamicPropertyFieldsDocument);
		this.existDynamicPropertyFieldsQuery = this.injector.get(ExistDynamicPropertyFieldsDocument);
		this.insertDynamicPropertyFieldsMutation = this.injector.get(InsertDynamicPropertyFieldsDocument);
		this.updateDynamicPropertyFieldsMutation = this.injector.get(UpdateDynamicPropertyFieldsDocument);
		this.importDataDynamicPropertyFieldsMutation = this.injector.get(ImportDataDynamicPropertyFieldsDocument);
		this.updateManyDynamicPropertyFieldsMutation = this.injector.get(UpdateManyDynamicPropertyFieldsDocument);
		this.deleteDynamicPropertyFieldsMutation = this.injector.get(DeleteDynamicPropertyFieldsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyField> {
    
    		let variables: GetDynamicPropertyFieldsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.get));
            }
            else{
                let result:ServiceSingleResultOfDynamicPropertyField={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyField> {
    
    		let variables: FirstDynamicPropertyFieldsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDynamicPropertyFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.countDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: SearchDynamicPropertyFieldsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDynamicPropertyFieldsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDynamicPropertyFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: CustomQueryDynamicPropertyFieldsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: CustomQueryIdDynamicPropertyFieldsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDynamicPropertyFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('types?') types?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfDynamicPropertyField,
		@Args('filter?') filter?: FilterOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindByTypeDynamicPropertyFieldsBaseVariables = {
    			types: types,
			filter: filter,
			options: options
    		}
    				return this.findByTypeDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.findByType));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDynamicPropertyFieldsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDynamicPropertyFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DynamicPropertyFieldInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyField> {
    
    		let variables: InsertDynamicPropertyFieldsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDynamicPropertyFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyField> {
    
    		let variables: UpdateDynamicPropertyFieldsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDynamicPropertyFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDynamicPropertyFieldsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDynamicPropertyFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDynamicPropertyFieldsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDynamicPropertyFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDynamicPropertyFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDynamicPropertyFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyFields.delete));
    	}
    
}