<div
  (click)="onMessageClick()"
  class="cl-warning-customer-box"
  *ngIf="showWarning"
>
  <i class="fa-solid fa-circle-exclamation"></i
  ><span>{{ 'globals/importantInformation' | translate }}</span>
</div>

<dx-popup
  [hideOnOutsideClick]="false"
  [width]="'auto'"
  [height]="'auto'"
  [minHeight]="'200px'"
  [maxHeight]="'90vh'"
  [maxWidth]="'1024'"
  [showCloseButton]="false"
  [(visible)]="showPopup"
  [title]="'globals/importantInformation' | translate"
>
  <dx-scroll-view width="100%" height="100%">
    <div class="cl-warnings-div">
      <div *ngFor="let warning of warnings">
        <span class="cl-warning-span">{{ warning?.title[lang] }}</span>
        <div
          class="cl-warning-div"
          *ngIf="warning?.content[lang] != undefined"
          [innerHTML]="warning?.content[lang] | sanitizeHtml"
        ></div>
      </div>
    </div>
  </dx-scroll-view>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="center"
    [options]="okButton"
  >
  </dxi-toolbar-item>
</dx-popup>
