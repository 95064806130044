<div class="tagbox-container">
  <dx-button
    class="tagbox-chrono-btn"
    [visible]="enableMinus"
    (onClick)="setMinus()"
    >{{ minus === true ? '-' : '+' }}
  </dx-button>
  <div
    [ngClass]="
      modeInline == true ? ['tagbox-item'] : ['tagbox-part', 'tagbox-item']
    "
    *ngIf="showDays"
  >
    <span class="tagbox-text">{{ 'globals/day' | translate }}</span>
    <dx-number-box
      [disabled]="isChronoStart"
      (onValueChanged)="numberBoxValueChanges($event)"
      [(value)]="days"
      [readOnly]="readOnly"
      [min]="0"
      showSpinButtons="true"
      [class]="modeInline == true ? 'tagbox-number-inline' : 'tagbox-number'"
    ></dx-number-box>
  </div>
  <div
    [ngClass]="
      modeInline == true ? ['tagbox-item'] : ['tagbox-part', 'tagbox-item']
    "
  >
    <span class="tagbox-text">{{ 'globals/hour' | translate }}</span>
    <dx-number-box
      [disabled]="isChronoStart"
      (onValueChanged)="numberBoxValueChanges($event)"
      [(value)]="hours"
      [readOnly]="readOnly"
      [min]="0"
      showSpinButtons="true"
      [class]="modeInline == true ? 'tagbox-number-inline' : 'tagbox-number'"
    ></dx-number-box>
  </div>
  <div
    [ngClass]="
      modeInline == true ? ['tagbox-item'] : ['tagbox-part', 'tagbox-item']
    "
  >
    <span class="tagbox-text">{{ 'globals/minutes' | translate }}</span>
    <dx-number-box
      [disabled]="isChronoStart"
      (onValueChanged)="numberBoxValueChanges($event)"
      [(value)]="minutes"
      [readOnly]="readOnly"
      [min]="0"
      showSpinButtons="true"
      [class]="modeInline == true ? 'tagbox-number-inline' : 'tagbox-number'"
    >
    </dx-number-box>
  </div>
  <div class="tagbox-part tagbox-item" style="display: none">
    <span class="tagbox-text">{{ 'globals/seconds' | translate }}</span>
    <dx-number-box
      [disabled]="isChronoStart"
      (onValueChanged)="numberBoxValueChanges($event)"
      [(value)]="secondes"
      [readOnly]="readOnly"
      [min]="0"
      showSpinButtons="true"
      class="tagbox-number"
    >
    </dx-number-box>
  </div>
  <dx-button
    class="tagbox-chrono-btn"
    [visible]="modeChrono && readOnly !== true"
    (onClick)="startStopChrono()"
  >
    <i
      [class]="
        isChronoStart === false
          ? 'dx-icon-custom-style dx-icon-clock'
          : 'dx-icon-custom-style dx-icon-close'
      "
    ></i>
  </dx-button>
</div>
