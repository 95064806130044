import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStockInReasonsBaseVariables, FirstStockInReasonsBaseVariables, CountStockInReasonsBaseVariables, FindStockInReasonsBaseVariables, SearchStockInReasonsBaseVariables, ExportSchemaStockInReasonsBaseVariables, ExportDataStockInReasonsBaseVariables, CustomQueryStockInReasonsBaseVariables, CustomQueryIdStockInReasonsBaseVariables, UsedStockInReasonsBaseVariables, ExistStockInReasonsBaseVariables, InsertStockInReasonsBaseVariables, UpdateStockInReasonsBaseVariables, ImportDataStockInReasonsBaseVariables, UpdateManyStockInReasonsBaseVariables, DeleteStockInReasonsBaseVariables } from '../gqls'
import { GetStockInReasonsDocument, FirstStockInReasonsDocument, CountStockInReasonsDocument, FindStockInReasonsDocument, SearchStockInReasonsDocument, ExportSchemaStockInReasonsDocument, ExportDataStockInReasonsDocument, CustomQueryStockInReasonsDocument, CustomQueryIdStockInReasonsDocument, UsedStockInReasonsDocument, ExistStockInReasonsDocument, InsertStockInReasonsDocument, UpdateStockInReasonsDocument, ImportDataStockInReasonsDocument, UpdateManyStockInReasonsDocument, DeleteStockInReasonsDocument } from '../gqls'
import { ServiceSingleResultOfStockInReason, QueryContextOfStockInReason, FilterOfStockInReason, ServiceSingleResultOfInt64, ServiceListResultOfStockInReason, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, StockInReasonInput, FieldUpdateOperatorOfStockInReason } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StockInReasonBaseService {
    
public modelName = 'stockInReason';
public modelPluralName = 'stockInReasons';

	private getStockInReasonsQuery: GetStockInReasonsDocument;
	private firstStockInReasonsQuery: FirstStockInReasonsDocument;
	private countStockInReasonsQuery: CountStockInReasonsDocument;
	private findStockInReasonsQuery: FindStockInReasonsDocument;
	private searchStockInReasonsQuery: SearchStockInReasonsDocument;
	private exportSchemaStockInReasonsQuery: ExportSchemaStockInReasonsDocument;
	private exportDataStockInReasonsQuery: ExportDataStockInReasonsDocument;
	private customQueryStockInReasonsQuery: CustomQueryStockInReasonsDocument;
	private customQueryIdStockInReasonsQuery: CustomQueryIdStockInReasonsDocument;
	private usedStockInReasonsQuery: UsedStockInReasonsDocument;
	private existStockInReasonsQuery: ExistStockInReasonsDocument;
	private insertStockInReasonsMutation: InsertStockInReasonsDocument;
	private updateStockInReasonsMutation: UpdateStockInReasonsDocument;
	private importDataStockInReasonsMutation: ImportDataStockInReasonsDocument;
	private updateManyStockInReasonsMutation: UpdateManyStockInReasonsDocument;
	private deleteStockInReasonsMutation: DeleteStockInReasonsDocument;

	constructor(private injector: Injector) {
		this.getStockInReasonsQuery = this.injector.get(GetStockInReasonsDocument);
		this.firstStockInReasonsQuery = this.injector.get(FirstStockInReasonsDocument);
		this.countStockInReasonsQuery = this.injector.get(CountStockInReasonsDocument);
		this.findStockInReasonsQuery = this.injector.get(FindStockInReasonsDocument);
		this.searchStockInReasonsQuery = this.injector.get(SearchStockInReasonsDocument);
		this.exportSchemaStockInReasonsQuery = this.injector.get(ExportSchemaStockInReasonsDocument);
		this.exportDataStockInReasonsQuery = this.injector.get(ExportDataStockInReasonsDocument);
		this.customQueryStockInReasonsQuery = this.injector.get(CustomQueryStockInReasonsDocument);
		this.customQueryIdStockInReasonsQuery = this.injector.get(CustomQueryIdStockInReasonsDocument);
		this.usedStockInReasonsQuery = this.injector.get(UsedStockInReasonsDocument);
		this.existStockInReasonsQuery = this.injector.get(ExistStockInReasonsDocument);
		this.insertStockInReasonsMutation = this.injector.get(InsertStockInReasonsDocument);
		this.updateStockInReasonsMutation = this.injector.get(UpdateStockInReasonsDocument);
		this.importDataStockInReasonsMutation = this.injector.get(ImportDataStockInReasonsDocument);
		this.updateManyStockInReasonsMutation = this.injector.get(UpdateManyStockInReasonsDocument);
		this.deleteStockInReasonsMutation = this.injector.get(DeleteStockInReasonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockInReason> {
    
    		let variables: GetStockInReasonsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.get));
            }
            else{
                let result:ServiceSingleResultOfStockInReason={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockInReason,
		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockInReason> {
    
    		let variables: FirstStockInReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStockInReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStockInReason,
		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockInReason> {
    
    		let variables: FindStockInReasonsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStockInReason,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockInReason> {
    
    		let variables: SearchStockInReasonsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStockInReasonsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStockInReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStockInReason,
		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockInReason> {
    
    		let variables: CustomQueryStockInReasonsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStockInReason,
		@Args('filter?') filter?: FilterOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStockInReason> {
    
    		let variables: CustomQueryIdStockInReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStockInReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.usedStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStockInReasonsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStockInReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StockInReasonInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockInReason> {
    
    		let variables: InsertStockInReasonsBaseVariables = {
    			entity: entity
    		}
    				return this.insertStockInReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStockInReason> {
    
    		let variables: UpdateStockInReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStockInReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStockInReasonsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStockInReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStockInReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStockInReasonsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStockInReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStockInReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStockInReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.stockInReasons.delete));
    	}
    
}