import { Injectable, Injector } from '@angular/core';
import {
  PopulationCoreService,
  RoleCoreService,
  TicketStatusReasonCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { AssetCategoryCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset-category.service';
import { AssetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.service';
import { ContractCategoryCoreService } from '@clarilog/core/services2/graphql/generated-types/services/contract-category.service';
import { LoanStatusCoreService } from '@clarilog/core/services2/graphql/generated-types/services/loan-status.service';
import { PropertyField } from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { camelCase } from 'lodash';

@Injectable({
  providedIn: 'root',
})
/** Représente les extensions lié au filter builder */
export class FilterBuilderExtensionService {
  customfieldIdEnum: { name: string; translatedName: string; service: any }[];
  constructor(
    private loanStatusService: LoanStatusCoreService,
    private contractCategoryService: ContractCategoryCoreService,
    private assetCategoryService: AssetCategoryCoreService,
    private assetService: AssetCoreService,
    private roleService: RoleCoreService,
    private injector: Injector,
  ) {
    this.customfieldIdEnum = this.getCustomFielIdEnum();
  }

  /** Obtient le champs Id à afficher dans le filter builder en fonction du nom*/
  getCustomFieldId(field: PropertyField) {
    let result = this.customfieldIdEnum.find((x) => x.name == field.name);
    if (result == undefined) {
      let serviceName = '';
      let service = null;

      if (field.serviceName != undefined && field.serviceName != '') {
        serviceName = field.serviceName;
      } else if (field.name.indexOf('Id') > 0) {
        serviceName = field.name.substring(0, field.name.length - 2);
      } else {
        return undefined;
      }

      service = this.injector.get(
        serviceName.toPascalCase() + 'CoreService',
        '',
      );

      if (
        field.name === 'userAffected.managerId' ||
        field.name === 'userMakeRequest.managerId'
      ) {
        serviceName = 'User';
        service = this.injector.get(UserCoreService);
      }

      if (
        field.name === 'memberOfPopulationIds' ||
        field.name.includes('.memberOfPopulationIds')
      ) {
        serviceName = 'Population';
        field.isTranslatable = true;
        service = this.injector.get(PopulationCoreService);
      }

      if (field.name === 'ticket.statusReasonId') {
        serviceName = 'TicketStatusReason';
        field.isTranslatable = true;
        field.serviceName = 'TicketStatusReason';
        field.displayExpression = 'name';
        field.valueExpression = 'id';
        service = this.injector.get(TicketStatusReasonCoreService);
      }

      if (service != null && service != '') {
        var translate = '';

        translate = TranslateService.get(
          'entities/' +
            camelCase(field.parentType) +
            '/' +
            camelCase(field.name),
        );
        // Manager case
        if (
          field.completeName?.includes('managerId') &&
          field.parentType == 'user'
        ) {
          translate = TranslateService.get(
            'entities/' + camelCase(field.parentType) + '/managerId',
          );
        }

        // Revoked by case
        if (
          field.completeName?.includes('revokeById') &&
          field.parentType == 'user'
        ) {
          translate = TranslateService.get(
            'entities/' + camelCase(field.parentType) + '/revokeById',
          );
        }

        if(field.completeName?.includes('outStockMovement') &&
           field.parentType == 'outStockMovement'
        ){
          const strings = field.completeName.split('.');
          translate = TranslateService.get(
            'entities/' + camelCase(field.parentType) + '/' + strings[1]
          )
        }

        if (translate.indexOf('[') >= 0) {
          translate = TranslateService.get(
            'entities/' + camelCase(serviceName) + '/_title/singular',
          );
        }

        if (translate.indexOf('[') == 0) {
          //translate = '[' + camelCase(serviceName) + ']';
          // Non implémenté dans la traduction
          return undefined;
        }

        return {
          name: field.name,
          translatedName: translate,
          service: service,
        };
      }
    }

    return result;
  }

  /** Defini les champs Id à afficher dans le filter builder */
  getCustomFielIdEnum() {
    return [
      {
        name: 'loanStatusId',
        translatedName: TranslateService.get('entities/loan/loanStatus'),
        service: this.loanStatusService,
      },
      {
        name: 'contractCategoryId',
        translatedName: TranslateService.get(
          'entities/contract/contractCategoryId',
        ),
        service: this.contractCategoryService,
      },
      {
        name: 'assetCategoryId',
        translatedName: TranslateService.get(
          'entities/assetCategory/_title/singular',
        ),
        service: this.assetCategoryService,
      },
      {
        name: 'mainImpactedAsset',
        translatedName: TranslateService.get('entities/incident/assetAffected'),
        service: this.assetService,
      },
      {
        name: 'memberOfIds',
        translatedName: TranslateService.get('entities/incident/assetAffected'),
        service: this.roleService,
      },
    ];
  }
}
