import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cl-mobile-toolbar',
  templateUrl: './mobile-toolbar.component.html',
  styleUrls: ['./mobile-toolbar.component.scss']
})
export class MobileToolbarComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
