<dx-data-grid
  id="gridContainer"
  [dataSource]="values"
  [showColumnHeaders]="showColumnHeaders"
  [showBorders]="false"
  (onRowRemoved)="onRowRemoved($event)"
  (onRowInserted)="onRowInserted($event)"
  (onRowPrepared)="onRowPrepared($event)"
  (onFocusedCellChanged)="onFocusedCellChanged($event)"
  (onEditingStart)="onEditingStart($event)"
  (onRowUpdated)="onRowUpdated($event)"
  (onInitialized)="onInitialized($event)"
  (onSaved)="onSaved($event)"
>
  <dxo-paging [enabled]="false"></dxo-paging>
  <dxo-editing
    mode="cell"
    [allowUpdating]="readOnly === false || readOnly == undefined"
    [allowDeleting]="
      (readOnly === false || readOnly == undefined) &&
      (allowDelete === true || allowDelete == undefined)
    "
    [allowAdding]="
      (readOnly === false || readOnly == undefined) &&
      (allowAdding === true || allowAdding == undefined)
    "
  >
  </dxo-editing>

  <dxi-column
    *ngFor="let column of columns"
    [dataField]="column.field"
    [caption]="column.label"
    [dataType]="column.dataType != undefined ? column.dataType : undefined"
    [sortIndex]="column.sortIndex"
    [sortOrder]="column.sortOrder"
    [visible]="column.visible"
    [allowEditing]="
      column.allowEditing != undefined ? column.allowEditing : true
    "
    [width]="
      column.dataType == 'boolean'
        ? '150px'
        : column.width != undefined
        ? column.width
        : 'undefined'
    "
    [cellTemplate]="
      column['tagListTemplate'] != undefined
        ? 'tagBoxCellTemplate'
        : column.template != undefined
        ? column.template
        : ''
    "
    [editCellTemplate]="
      column['tagListTemplate'] != undefined ? 'tagBoxEditCellTemplate' : null
    "
    [setCellValue]="setCellValue"
  >
    <dxo-lookup
      *ngIf="column.lookup != undefined"
      [dataSource]="
        column.lookup.source.lookup === true
          ? column.lookup.source.datasource.store()
          : column.lookup.source
      "
      [valueExpr]="column.lookup.valueExpr"
      [displayExpr]="column.lookup.displayExpr"
    >
    </dxo-lookup>

    <div *dxTemplate="let cellInfo of 'tagBoxCellTemplate'">
      <dx-tag-box
        [value]="cellInfo.value"
        [disabled]="true"
        [readOnly]="true"
        [dataSource]="column['tagListTemplate'].source.datasource"
        [showClearButton]="true"
        placeholder=""
        [searchMode]="'contains'"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [valueExpr]="column['tagListTemplate'].valueExpr"
        [showSelectionControls]="true"
        [displayExpr]="column['tagListTemplate'].displayExpr"
        [acceptCustomValue]="false"
        [multiline]="true"
      >
      </dx-tag-box>
    </div>
    <div *dxTemplate="let cellInfo of 'tagBoxEditCellTemplate'">
      <dx-tag-box
        [value]="cellInfo.value"
        [dataSource]="column['tagListTemplate'].source.datasource"
        [showClearButton]="true"
        [placeholder]="'selectValue' | translate"
        [searchMode]="'contains'"
        [minSearchLength]="0"
        [searchEnabled]="true"
        [valueExpr]="column['tagListTemplate'].valueExpr"
        [showSelectionControls]="true"
        [displayExpr]="column['tagListTemplate'].displayExpr"
        [acceptCustomValue]="false"
        [multiline]="true"
        (onSelectionChanged)="
          selectionTemplateChanged(column['tagListTemplate'], cellInfo, $event)
        "
      >
      </dx-tag-box>
      <!-- <dx-drop-down-box [valueExpr]="column['tagListTemplate'].valueExpr" [value]="cellInfo.value"
        [displayExpr]="column['tagListTemplate'].valueExpr" (onInitialized)="onDropDownInitialized($event)"
        (onFocusIn)="onDropDownInput($event)" placeholder="Sélectionner une valeur..." [showClearButton]="true"
        [dataSource]="column['tagListTemplate'].source.datasource" [width]="345">
        <div *dxTemplate="let data of 'content'">
          <dx-tree-list [dataSource]="column['tagListTemplate'].source.datasource"
            [keyExpr]="column['tagListTemplate'].valueExpr" [columns]="[column['tagListTemplate'].displayExpr]"
            [selection]="{ mode: 'multiple' }" [hoverStateEnabled]="true" [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }" [showRowLines]="false" [showColumnHeaders]="false"
            (onContentReady)="onTreelistContentReady($event, column)" [selectedRowKeys]="cellInfo.value"
            (onSelectionChanged)="selectionTemplateChanged(cellInfo, $event)">
          </dx-tree-list>
        </div>
      </dx-drop-down-box> -->
    </div>
    <dxi-validation-rule
      *ngIf="
        column.dataType != 'boolean' &&
        column.dataType != 'array' &&
        column.dataType != 'type' &&
        column.visible === true
      "
      type="required"
    >
    </dxi-validation-rule>

    <dxi-validation-rule
      *ngIf="column.dataType != 'boolean' && column.validator == 'folderPath'"
      type="custom"
      [validationCallback]="folderPathValidator"
      [message]="'errors/pattern' | translate"
    >
    </dxi-validation-rule>

    <dxi-validation-rule
      *ngIf="column.dataType != 'boolean' && column.validator == 'unique'"
      type="custom"
      [validationCallback]="uniqueValidator"
      [message]="'errors/notUnique' | translate"
    >
    </dxi-validation-rule>

    <dxi-validation-rule
      *ngIf="column.field === 'oid'"
      type="pattern"
      [pattern]="namePattern"
      [message]="'errors/oid' | translate"
    ></dxi-validation-rule>
  </dxi-column>
  <dxo-editing
    *ngIf="batchMode"
    mode="batch"
    [allowUpdating]="true"
  ></dxo-editing>

  <!-- Application des différents templates -->
  <ng-container
    *ngFor="let column of columns | filter : 'template' : undefined : true"
  >
    <div *dxTemplate="let data of column.template">
      <ng-template
        *ngTemplateOutlet="
          templateService.get(column.template);
          context: { $implicit: data, column: column }
        "
      >
      </ng-template>
    </div>
  </ng-container>
</dx-data-grid>
