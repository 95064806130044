import { KeyValueDiffers, KeyValueDiffer } from '@angular/core';
import { TypeHelpers } from './type-helpers';
/**
 * Permet de tracker un objet.
 */
export class ObjectTrack<T = {}> {
  private differ: KeyValueDiffer<any, any>;
  private patch: any = {};
  constructor(private differs: KeyValueDiffers) { }
  /**
   * Permet de sauvegarder un état de l'objet à surveiller.
   * @param value La valeur à suivre.
   */
  snapshot(value: T) {
    this.differ = this.differs.find(value).create();
    // Permet d'initialiser
    this.differ.diff(value);
  }
  /**
   * Permet de savoir s'il y a des changements.
   */
  hasChanges(): boolean {
    return TypeHelpers.isObjectEmpty(this.patch) === false;
  }
  /**
   * Permet de récupérer uniquement les changements
   * @param value L'object à suivre
   */
  changes(value: T): any {
    const changes = this.differ.diff(value);
    if(changes){
      // Des champs ont été supprimé dans l'object
      // changes.forEachRemovedItem((record) =>  console.log(record));
      // Des champs ont été rajouté dans l'object
      // changes.forEachAddedItem((record) => console.log(record));
      // Modifications de champs
      // Suit seulement les changement
      changes.forEachChangedItem((record) => this.patch[record.key] = record.currentValue);
    }
    return this.patch;
  }
  /**
   * Permet de réinitialiser les changements
   */
  reset() {
    this.patch = {};
  }
}
