import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetRoleAccountsBaseVariables, FirstRoleAccountsBaseVariables, CountRoleAccountsBaseVariables, FindRoleAccountsBaseVariables, SearchRoleAccountsBaseVariables, ExportSchemaRoleAccountsBaseVariables, ExportDataRoleAccountsBaseVariables, CustomQueryRoleAccountsBaseVariables, CustomQueryIdRoleAccountsBaseVariables, UsedRoleAccountsBaseVariables, ExistRoleAccountsBaseVariables, InsertRoleAccountsBaseVariables, UpdateRoleAccountsBaseVariables, ImportDataRoleAccountsBaseVariables, UpdateManyRoleAccountsBaseVariables, DeleteRoleAccountsBaseVariables } from '../gqls'
import { GetRoleAccountsDocument, FirstRoleAccountsDocument, CountRoleAccountsDocument, FindRoleAccountsDocument, SearchRoleAccountsDocument, ExportSchemaRoleAccountsDocument, ExportDataRoleAccountsDocument, CustomQueryRoleAccountsDocument, CustomQueryIdRoleAccountsDocument, UsedRoleAccountsDocument, ExistRoleAccountsDocument, InsertRoleAccountsDocument, UpdateRoleAccountsDocument, ImportDataRoleAccountsDocument, UpdateManyRoleAccountsDocument, DeleteRoleAccountsDocument } from '../gqls'
import { ServiceSingleResultOfRoleAccount, QueryContextOfRoleAccount, FilterOfRoleAccount, ServiceSingleResultOfInt64, ServiceListResultOfRoleAccount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, RoleAccountInput, FieldUpdateOperatorOfRoleAccount } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class RoleAccountBaseService {
    
public modelName = 'roleAccount';
public modelPluralName = 'roleAccounts';

	private getRoleAccountsQuery: GetRoleAccountsDocument;
	private firstRoleAccountsQuery: FirstRoleAccountsDocument;
	private countRoleAccountsQuery: CountRoleAccountsDocument;
	private findRoleAccountsQuery: FindRoleAccountsDocument;
	private searchRoleAccountsQuery: SearchRoleAccountsDocument;
	private exportSchemaRoleAccountsQuery: ExportSchemaRoleAccountsDocument;
	private exportDataRoleAccountsQuery: ExportDataRoleAccountsDocument;
	private customQueryRoleAccountsQuery: CustomQueryRoleAccountsDocument;
	private customQueryIdRoleAccountsQuery: CustomQueryIdRoleAccountsDocument;
	private usedRoleAccountsQuery: UsedRoleAccountsDocument;
	private existRoleAccountsQuery: ExistRoleAccountsDocument;
	private insertRoleAccountsMutation: InsertRoleAccountsDocument;
	private updateRoleAccountsMutation: UpdateRoleAccountsDocument;
	private importDataRoleAccountsMutation: ImportDataRoleAccountsDocument;
	private updateManyRoleAccountsMutation: UpdateManyRoleAccountsDocument;
	private deleteRoleAccountsMutation: DeleteRoleAccountsDocument;

	constructor(private injector: Injector) {
		this.getRoleAccountsQuery = this.injector.get(GetRoleAccountsDocument);
		this.firstRoleAccountsQuery = this.injector.get(FirstRoleAccountsDocument);
		this.countRoleAccountsQuery = this.injector.get(CountRoleAccountsDocument);
		this.findRoleAccountsQuery = this.injector.get(FindRoleAccountsDocument);
		this.searchRoleAccountsQuery = this.injector.get(SearchRoleAccountsDocument);
		this.exportSchemaRoleAccountsQuery = this.injector.get(ExportSchemaRoleAccountsDocument);
		this.exportDataRoleAccountsQuery = this.injector.get(ExportDataRoleAccountsDocument);
		this.customQueryRoleAccountsQuery = this.injector.get(CustomQueryRoleAccountsDocument);
		this.customQueryIdRoleAccountsQuery = this.injector.get(CustomQueryIdRoleAccountsDocument);
		this.usedRoleAccountsQuery = this.injector.get(UsedRoleAccountsDocument);
		this.existRoleAccountsQuery = this.injector.get(ExistRoleAccountsDocument);
		this.insertRoleAccountsMutation = this.injector.get(InsertRoleAccountsDocument);
		this.updateRoleAccountsMutation = this.injector.get(UpdateRoleAccountsDocument);
		this.importDataRoleAccountsMutation = this.injector.get(ImportDataRoleAccountsDocument);
		this.updateManyRoleAccountsMutation = this.injector.get(UpdateManyRoleAccountsDocument);
		this.deleteRoleAccountsMutation = this.injector.get(DeleteRoleAccountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRoleAccount> {
    
    		let variables: GetRoleAccountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.get));
            }
            else{
                let result:ServiceSingleResultOfRoleAccount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRoleAccount,
		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRoleAccount> {
    
    		let variables: FirstRoleAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRoleAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRoleAccount,
		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRoleAccount> {
    
    		let variables: FindRoleAccountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfRoleAccount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRoleAccount> {
    
    		let variables: SearchRoleAccountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaRoleAccountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataRoleAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfRoleAccount,
		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRoleAccount> {
    
    		let variables: CustomQueryRoleAccountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRoleAccount,
		@Args('filter?') filter?: FilterOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRoleAccount> {
    
    		let variables: CustomQueryIdRoleAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedRoleAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistRoleAccountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existRoleAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: RoleAccountInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRoleAccount> {
    
    		let variables: InsertRoleAccountsBaseVariables = {
    			entity: entity
    		}
    				return this.insertRoleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRoleAccount> {
    
    		let variables: UpdateRoleAccountsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateRoleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataRoleAccountsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataRoleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfRoleAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyRoleAccountsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyRoleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteRoleAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteRoleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roleAccounts.delete));
    	}
    
}