import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { dirtyCheck } from '../form/work-item-form/dirty-check.service';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  CoreFormRecorder,
  CoreFormLoader,
} from '@clarilog/shared2/components/form/work-form';
import { GqlFields } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ServiceResult } from '@clarilog/core/services/graphql/graphql.service';
import { ServiceSingleResultOfBoolean } from '@clarilog/core/services2/graphql/generated-types/types';
import DataSource from 'devextreme/data/data_source';
/**
 * PopUp dynamic à partir d'un model.json
 *
 */
@Component({
  selector: 'clc-dynamic-popup',
  templateUrl: './dynamic-popup.component.html',
  styleUrls: ['./dynamic-popup.component.scss'],
})
export class CoreDynamicPopUpComponent implements OnInit {
  @Input() selectedKeys: any[];
  @Input() inputModel: any;
  @Input() service: {
    updateMany: (
      fields: GqlFields,
      ids: string[],
      ...args
    ) => Observable<ServiceSingleResultOfBoolean>;
  };
  @Input() height: any;
  @Input() width: any;
  @Input() large: any;

  @Input() updateFn: (
    fields: GqlFields,
    ids: string[],
    ...args
  ) => Observable<ServiceSingleResultOfBoolean>;
  @Input() customTitle: boolean = false;
  @Input() showNotification: boolean = true;
  @Input() hideOnOutsideClick: boolean = true;
  @Input() rootForm: UntypedFormGroup;
  model: ModelState;
  _isLoading: boolean = false;
  @Input()
  set isLoading(value: boolean) {
    this._isLoading = value;
    this.isLoadingChange.emit(this._isVisible);
  }
  get isLoading(): boolean {
    return this._isLoading;
  }

  formGroup: UntypedFormGroup = null;

  @Output() onSaved = new EventEmitter<any>();
  @Output() onFormLoaded = new EventEmitter<any>();

  @Input() title = '';
  @Input() titleBtn = '';

  _isVisible: boolean = false;

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() isLoadingChange = new EventEmitter<boolean>();
  @Output() selectedKeysChange = new EventEmitter<any[]>();

  @Input()
  set isVisible(value: boolean) {
    this._isVisible = value;
    this.isVisibleChange.emit(this._isVisible);
    if (this._isVisible === true && this.inputModel != undefined) {
      this.createForm();
    }
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private loader: CoreFormLoader,
    private recoder: CoreFormRecorder,
    private _injector: Injector,
  ) {
    try {
      let svc = this._injector.get(FormGroupDirective);
      this.rootForm = svc.form.root as UntypedFormGroup;
    } catch (error) {
      this.rootForm = null;
    }
  }

  ngOnInit(): void {
    if (this.height == undefined || this.height == null) {
      this.height = '80%';
    }
    if (
      this.width == undefined ||
      this.width == null ||
      this.large == 'Normal' ||
      this.large == undefined ||
      this.large == null
    ) {
      this.width = '800';
    }

    if (this.large == 'Large') {
      this.width = '1200';
    }
  }
  close() {
    this.isLoading = false;
    this.isVisible = false;
  }

  _inDefaultStaticProperty = [];

  @Input() inDefaultProperty = [];
  @Input() set inDefaultStaticProperty(value) {
    this._inDefaultStaticProperty = value;
  }

  get inDefaultStaticProperty(): any {
    return this._inDefaultStaticProperty;
  }

  setFormDefaultValue(from, to) {
    let fromControl = FormGroupHelpers.formControlByName(this.rootForm, from);
    let toControl = FormGroupHelpers.formControlByName(this.formGroup, to);

    if (fromControl != undefined && toControl != undefined) {
      toControl.setValue(fromControl.value, {
        emitEvent: false,
      });
      setTimeout(() => toControl.markAsDirty());
    }
  }

  setStaticDefaultValue(value, controlName) {
    let toControl = FormGroupHelpers.formControlByName(
      this.formGroup,
      controlName,
    );

    if (toControl != undefined) {
      toControl.setValue(value, {
        emitEvent: false,
      });
      setTimeout(() => toControl.markAsDirty());
    }
  }

  setFormDefaultValues() {
    if (this.inDefaultProperty != undefined) {
      this.inDefaultProperty.forEach((element) => {
        this.setFormDefaultValue(element.from, element.to);
      });
    }
    if (this.inDefaultStaticProperty != undefined) {
      this.inDefaultStaticProperty.forEach((element) => {
        this.setStaticDefaultValue(element.value, element.controlName);
      });
    }
  }

  save(e) {
    let update = this.recoder.materialize(this.formGroup);
    this.onSaved.emit(update);

    //   let warningMessage = this.title.toLocaleLowerCase().trim();
    //   if( this.title == null || this.title ==="" ||  !this.customTitle){
    //     warningMessage = TranslateService.get("updateMassiseEdit");
    //   }

    //   var result = alert(
    //     TranslateService.get("mgsWarning",{value:warningMessage}),
    //     TranslateService.get("globals/warning")
    //   );
    //   result.then(result => {
    //     let update = this.recoder.materialize(this.formGroup);
    //     this.updateFn(this.selectedKeys, update).subscribe((res) => {
    //       if (res.data === true) {
    //         if (this.showNotification === true) {
    //           notify(TranslateService.get('saveSuccess'), 'success', 5000);
    //         }
    //         this.onSaved.emit(update);
    //       } else {
    //         if (this.showNotification === true) {
    //           if(res.errors.length > 0){
    //             notify(res.errors[0].messageError, 'error', 5000);
    //           }
    //           else{
    //             notify(TranslateService.get('actionError'), 'error', 5000);
    //            }
    //         }
    //       }
    //     });
    //     this.selectedKeysChange.emit([]);
    //     this.close();
    //   });
  }
  isDirty$: Observable<boolean>;
  canSave$: Observable<boolean>;
  private createForm() {
    this.modelCompilerService
      .compile(this.inputModel)
      .subscribe(async (res) => {
        this.model = res;
        this.model.sharedContext.params.set('id', () => null);
        this.model.contexts.forEach((element) => {
          // Mettre dans l'ordre les valeurs de la requete find (RoleCoreService)
          // PopUp Invitation user

          if (element.serviceName == 'RoleCoreService') {
            let getSource = element['datasource'] as DataSource;
            if (getSource != undefined && getSource != null) {
              getSource.sort([{ selector: 'name', desc: false }]);
            }
          }
        });

        this.formGroup = await this.loader.create(this.model);
        // Attendre que le formGroup soit générer
        if (this.formGroup != null) {
          // Préferabel d'attendre le formGroup
          this.formGroup.markAsLoaded();
          this.isDirty$ = dirtyCheck(
            this.formGroup,
            of(JSON.parse(JSON.stringify(this.formGroup.value))),
          )(this.formGroup.valueChanges);
          this.canSave$ = this.isDirty$.pipe(
            map(
              (dirty) =>
                dirty &&
                FormGroupHelpers.valid(this.formGroup, undefined, true),
            ),
          );

          this.setFormDefaultValues();
          if (this.updateFn == undefined) {
            if (this.service.updateMany != undefined) {
              this.updateFn = this.service.updateMany.bind(this.service);
            }
          }

          let controls = FormGroupHelpers.formControls(this.formGroup);

          for (let control of controls) {
            (control.value as AbstractControl).markAsDirty();
          }

          this.onFormLoaded.emit(this.formGroup);
        }
      });
  }

  public open(e) {
    this.createForm();
  }
}
