import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { OneSettingBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfBoolean } from '../types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize('clarilog.one-settings')
export class OneSettingCoreService extends OneSettingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public resetUserName(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('newPassword?') newPassword?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    return this.resetPassword(
      fields,
      'verifaliaSetting.userName',
      newPassword,
      extendedVariables,
    );
  }

  @InjectArgs
  public resetPwd(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('newPassword?') newPassword?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    return this.resetPassword(
      fields,
      'verifaliaSetting.password',
      newPassword,
      extendedVariables,
    );
  }
}
