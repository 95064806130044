import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ListDesignerBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfListDesigner,
  ListDesignerType,
  QueryContextOfListDesigner,
  ServiceListResultOfListDesigner,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import modelIncident from '../../../../../modules2/help-desk-incident/pages/help-desk-incident-list/model.json';
import modelRequest from '../../../../../modules2/help-desk-request/pages/help-desk-request-list/model.json';
import { map } from 'rxjs/operators';

import modelOperatorIncident from '../../../../../modules2/incident/pages/incident-list/model.json';
import modelOperatorRequest from '../../../../../modules2/request/pages/request-list/model.json';
import modelOperatorProblem from '../../../../../modules2/problem/pages/problem-list/model.json';
import modelOperatorTicket from '../../../../../modules2/ticket/pages/ticket-list/model.json';
import { Column } from '@clarilog/shared2/models/schema';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-user-list-designer')
export class ListDesignerCoreService extends ListDesignerBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Recherche des listes utilisateur des incidents */
  @InjectArgs
  public findUserIncident(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'incident',
        },
        listType: {
          eq: ListDesignerType.HelpDeskUser,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Recherche des listes utilisateur des demandes */
  @InjectArgs
  public findUserRequest(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'request',
        },
        listType: {
          eq: ListDesignerType.HelpDeskUser,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Recherche des listes opérateurs (incident) */
  @InjectArgs
  public findOperatorIncident(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'incident',
        },
        listType: {
          eq: ListDesignerType.HelpDeskOperator,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Recherche des listes opérateurs (demande) */
  @InjectArgs
  public findOperatorRequest(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'request',
        },
        listType: {
          eq: ListDesignerType.HelpDeskOperator,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Recherche des listes opérateurs (demande) */
  @InjectArgs
  public findOperatorProblem(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'problem',
        },
        listType: {
          eq: ListDesignerType.HelpDeskOperator,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Recherche des listes opérateurs (ticket) */
  @InjectArgs
  public findOperatorTicket(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        qualification: {
          eq: 'ticket',
        },
        listType: {
          eq: ListDesignerType.HelpDeskOperator,
        },
      },
    ];

    return this.find(fields, options, filter, extendedVariables);
  }

  /** Obtient les colonnes à inclure */
  @InjectArgs
  public includeColunms(
    @Args('qualification') qualification: string,
    @Args('listType') listType: ListDesignerType,
  ): Column[] {
    let model;

    if (qualification == 'incident') {
      if (listType == ListDesignerType.HelpDeskUser) {
        model = modelIncident;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        model = modelOperatorIncident;
      }
    } else if (qualification == 'request') {
      if (listType == ListDesignerType.HelpDeskUser) {
        model = modelRequest;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        model = modelOperatorRequest;
      }
    } else if (
      qualification == 'ticket' &&
      listType == ListDesignerType.HelpDeskOperator
    ) {
      model = modelOperatorTicket;
    } else if (
      qualification == 'problem' &&
      listType == ListDesignerType.HelpDeskOperator
    ) {
      model = modelOperatorProblem;
    } else {
      model = modelIncident;
    }

    return model.grid.layout.columns.filter(
      (s) => s.showInColumnChooser !== false,
    );
  }

  /** Obtient le model par defaut */
  @InjectArgs
  public defaultModel(
    @Args('qualification') qualification: string,
    @Args('listType') listType: ListDesignerType,
  ) {
    let model;

    if (qualification == 'incident') {
      if (listType == ListDesignerType.HelpDeskUser) {
        model = modelIncident;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        model = modelOperatorIncident;
      }
    } else if (qualification == 'request') {
      if (listType == ListDesignerType.HelpDeskUser) {
        model = modelRequest;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        model = modelOperatorRequest;
      }
    } else if (qualification == 'ticket') {
      if (listType == ListDesignerType.HelpDeskUser) {
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        model = modelOperatorTicket;
      }
    } else if (
      qualification == 'problem' &&
      listType == ListDesignerType.HelpDeskOperator
    ) {
      model = modelOperatorProblem;
    } else {
      model = modelIncident;
    }

    return model;
  }

  /** Obtient le model help me par défaut pour les demandes */
  public getUserRequestDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      {
        qualification: { eq: 'request' },
        listType: { eq: ListDesignerType.HelpDeskUser },
        isDefault: { eq: true },
      },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }

  /** Obtient le model help me par défaut pour les incidents */
  public getUserIncidentDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      {
        qualification: { eq: 'incident' },
        listType: { eq: ListDesignerType.HelpDeskUser },
        isDefault: { eq: true },
      },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }

  /**
   * Obtient uniquement les listes utilisateurs
   */
  @InjectArgs
  public findUsersLists(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        listType: {
          eq: ListDesignerType.HelpDeskUser,
        },
      },
    ];

    return super.find(fields, options, filter, extendedVariables);
  }

  /**
   * Obtient uniquement les listes opérateurs
   */
  @InjectArgs
  public findOperatorsLists(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfListDesigner,
    @Args('filter?') filter?: FilterOfListDesigner,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfListDesigner> {
    if (filter == undefined) {
      filter = {};
    }

    filter.and = [
      {
        listType: {
          eq: ListDesignerType.HelpDeskOperator,
        },
      },
    ];

    return super.find(fields, options, filter, extendedVariables);
  }
}
