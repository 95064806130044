import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetArticleKnowledgeNotesBaseVariables, FirstArticleKnowledgeNotesBaseVariables, CountArticleKnowledgeNotesBaseVariables, FindArticleKnowledgeNotesBaseVariables, SearchArticleKnowledgeNotesBaseVariables, ExportSchemaArticleKnowledgeNotesBaseVariables, ExportDataArticleKnowledgeNotesBaseVariables, CustomQueryArticleKnowledgeNotesBaseVariables, CustomQueryIdArticleKnowledgeNotesBaseVariables, UsedArticleKnowledgeNotesBaseVariables, NoteByUserArticleKnowledgeNotesBaseVariables, ExistArticleKnowledgeNotesBaseVariables, InsertArticleKnowledgeNotesBaseVariables, UpdateArticleKnowledgeNotesBaseVariables, ImportDataArticleKnowledgeNotesBaseVariables, UpdateManyArticleKnowledgeNotesBaseVariables, DeleteArticleKnowledgeNotesBaseVariables, SetNoteByUserArticleKnowledgeNotesBaseVariables } from '../gqls'
import { GetArticleKnowledgeNotesDocument, FirstArticleKnowledgeNotesDocument, CountArticleKnowledgeNotesDocument, FindArticleKnowledgeNotesDocument, SearchArticleKnowledgeNotesDocument, ExportSchemaArticleKnowledgeNotesDocument, ExportDataArticleKnowledgeNotesDocument, CustomQueryArticleKnowledgeNotesDocument, CustomQueryIdArticleKnowledgeNotesDocument, UsedArticleKnowledgeNotesDocument, NoteByUserArticleKnowledgeNotesDocument, ExistArticleKnowledgeNotesDocument, InsertArticleKnowledgeNotesDocument, UpdateArticleKnowledgeNotesDocument, ImportDataArticleKnowledgeNotesDocument, UpdateManyArticleKnowledgeNotesDocument, DeleteArticleKnowledgeNotesDocument, SetNoteByUserArticleKnowledgeNotesDocument } from '../gqls'
import { ServiceSingleResultOfArticleKnowledgeNote, QueryContextOfArticleKnowledgeNote, FilterOfArticleKnowledgeNote, ServiceSingleResultOfInt64, ServiceListResultOfArticleKnowledgeNote, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfDouble, ArticleKnowledgeNoteInput, FieldUpdateOperatorOfArticleKnowledgeNote, ServiceSingleResultOfArticleKnowledge } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ArticleKnowledgeNoteBaseService {
    
public modelName = 'articleKnowledgeNote';
public modelPluralName = 'articleKnowledgeNotes';

	private getArticleKnowledgeNotesQuery: GetArticleKnowledgeNotesDocument;
	private firstArticleKnowledgeNotesQuery: FirstArticleKnowledgeNotesDocument;
	private countArticleKnowledgeNotesQuery: CountArticleKnowledgeNotesDocument;
	private findArticleKnowledgeNotesQuery: FindArticleKnowledgeNotesDocument;
	private searchArticleKnowledgeNotesQuery: SearchArticleKnowledgeNotesDocument;
	private exportSchemaArticleKnowledgeNotesQuery: ExportSchemaArticleKnowledgeNotesDocument;
	private exportDataArticleKnowledgeNotesQuery: ExportDataArticleKnowledgeNotesDocument;
	private customQueryArticleKnowledgeNotesQuery: CustomQueryArticleKnowledgeNotesDocument;
	private customQueryIdArticleKnowledgeNotesQuery: CustomQueryIdArticleKnowledgeNotesDocument;
	private usedArticleKnowledgeNotesQuery: UsedArticleKnowledgeNotesDocument;
	private noteByUserArticleKnowledgeNotesQuery: NoteByUserArticleKnowledgeNotesDocument;
	private existArticleKnowledgeNotesQuery: ExistArticleKnowledgeNotesDocument;
	private insertArticleKnowledgeNotesMutation: InsertArticleKnowledgeNotesDocument;
	private updateArticleKnowledgeNotesMutation: UpdateArticleKnowledgeNotesDocument;
	private importDataArticleKnowledgeNotesMutation: ImportDataArticleKnowledgeNotesDocument;
	private updateManyArticleKnowledgeNotesMutation: UpdateManyArticleKnowledgeNotesDocument;
	private deleteArticleKnowledgeNotesMutation: DeleteArticleKnowledgeNotesDocument;
	private setNoteByUserArticleKnowledgeNotesMutation: SetNoteByUserArticleKnowledgeNotesDocument;

	constructor(private injector: Injector) {
		this.getArticleKnowledgeNotesQuery = this.injector.get(GetArticleKnowledgeNotesDocument);
		this.firstArticleKnowledgeNotesQuery = this.injector.get(FirstArticleKnowledgeNotesDocument);
		this.countArticleKnowledgeNotesQuery = this.injector.get(CountArticleKnowledgeNotesDocument);
		this.findArticleKnowledgeNotesQuery = this.injector.get(FindArticleKnowledgeNotesDocument);
		this.searchArticleKnowledgeNotesQuery = this.injector.get(SearchArticleKnowledgeNotesDocument);
		this.exportSchemaArticleKnowledgeNotesQuery = this.injector.get(ExportSchemaArticleKnowledgeNotesDocument);
		this.exportDataArticleKnowledgeNotesQuery = this.injector.get(ExportDataArticleKnowledgeNotesDocument);
		this.customQueryArticleKnowledgeNotesQuery = this.injector.get(CustomQueryArticleKnowledgeNotesDocument);
		this.customQueryIdArticleKnowledgeNotesQuery = this.injector.get(CustomQueryIdArticleKnowledgeNotesDocument);
		this.usedArticleKnowledgeNotesQuery = this.injector.get(UsedArticleKnowledgeNotesDocument);
		this.noteByUserArticleKnowledgeNotesQuery = this.injector.get(NoteByUserArticleKnowledgeNotesDocument);
		this.existArticleKnowledgeNotesQuery = this.injector.get(ExistArticleKnowledgeNotesDocument);
		this.insertArticleKnowledgeNotesMutation = this.injector.get(InsertArticleKnowledgeNotesDocument);
		this.updateArticleKnowledgeNotesMutation = this.injector.get(UpdateArticleKnowledgeNotesDocument);
		this.importDataArticleKnowledgeNotesMutation = this.injector.get(ImportDataArticleKnowledgeNotesDocument);
		this.updateManyArticleKnowledgeNotesMutation = this.injector.get(UpdateManyArticleKnowledgeNotesDocument);
		this.deleteArticleKnowledgeNotesMutation = this.injector.get(DeleteArticleKnowledgeNotesDocument);
		this.setNoteByUserArticleKnowledgeNotesMutation = this.injector.get(SetNoteByUserArticleKnowledgeNotesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeNote> {
    
    		let variables: GetArticleKnowledgeNotesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.get));
            }
            else{
                let result:ServiceSingleResultOfArticleKnowledgeNote={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeNote,
		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeNote> {
    
    		let variables: FirstArticleKnowledgeNotesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountArticleKnowledgeNotesBaseVariables = {
    			filter: filter
    		}
    				return this.countArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeNote,
		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeNote> {
    
    		let variables: FindArticleKnowledgeNotesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfArticleKnowledgeNote,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeNote> {
    
    		let variables: SearchArticleKnowledgeNotesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaArticleKnowledgeNotesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataArticleKnowledgeNotesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfArticleKnowledgeNote,
		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeNote> {
    
    		let variables: CustomQueryArticleKnowledgeNotesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfArticleKnowledgeNote,
		@Args('filter?') filter?: FilterOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeNote> {
    
    		let variables: CustomQueryIdArticleKnowledgeNotesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedArticleKnowledgeNotesBaseVariables = {
    			ids: ids
    		}
    				return this.usedArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.used));
    	}

    	/**  */
    	@InjectArgs
    	public noteByUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('articleKnowledgeId') articleKnowledgeId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDouble> {
    
    		let variables: NoteByUserArticleKnowledgeNotesBaseVariables = {
    			articleKnowledgeId: articleKnowledgeId
    		}
    				return this.noteByUserArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.noteByUser));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistArticleKnowledgeNotesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existArticleKnowledgeNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ArticleKnowledgeNoteInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeNote> {
    
    		let variables: InsertArticleKnowledgeNotesBaseVariables = {
    			entity: entity
    		}
    				return this.insertArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeNote> {
    
    		let variables: UpdateArticleKnowledgeNotesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataArticleKnowledgeNotesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfArticleKnowledgeNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyArticleKnowledgeNotesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteArticleKnowledgeNotesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public setNoteByUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('note') note: number,
		@Args('articleKnowledgeId') articleKnowledgeId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledge> {
    
    		let variables: SetNoteByUserArticleKnowledgeNotesBaseVariables = {
    			articleKnowledgeId: articleKnowledgeId,
			note: note
    		}
    				return this.setNoteByUserArticleKnowledgeNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeNotes.setNoteByUser));
    	}
    
}