import { Injectable, Injector } from '@angular/core';
import {
  GqlField,
  GqlSubField,
  GqlSubFieldArg,
  GqlFields,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ButtonTreeViewOptions,
  Column,
  Command,
  CommandItems,
  Control,
  Form,
  GridLayout,
  LinkListOptions,
  LinkOptions,
  PageControl,
  PageGroup,
  PageSection,
  PageTab,
  SchedulerOptions,
  Section,
  SectionGroup,
  SimpleListOptions,
  SelectSimpleListOptions,
  Validator,
  GridOptions,
  StockListOptions,
  StockCreatedAssetOptions,
  CurrentInStockListOptions,
  AutoCompleteBoxOptions,
  MasterDetail,
  FileManagerOptions,
  HtmlEditorOptions,
  TagBoxOptions,
  SubFormLinkList,
  Filter,
  FileUploaderOptions,
  LogicTemplateOptions,
  PropertyChangeOptions,
  DesignerRuleListOptions,
  MessageTicketOptions,
  TicketEmailListOptions,
  TagBoxEmailOptions,
  TextBoxOptions,
  VisibleSelectableLinkEntityListOptions,
} from '@clarilog/shared2/models/schema';
import {
  AbstractModelContext,
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from './model-state';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';
import {
  TranslateService,
  TranslatedFieldHelperService,
} from '@clarilog/shared2';
import { ModelRulesDesignerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/model-rules-designer.service';
import {
  AssetCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { OrganizationStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/organization-storage-service';
import {
  DynamicPropertyDateFormat,
  DynamicPropertyFieldEnum,
  DynamicPropertyNumericFormat,
} from '@clarilog/core/services2/graphql/generated-types/types';

@Injectable({
  providedIn: 'root',
})
export class ModelFieldCompilerService {
  constructor(
    private injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private organizationStorageService: OrganizationStorageService,
  ) {}

  private setFieldsContext(
    context: any,
    fields: Array<GqlSubField | GqlField>,
  ) {
    try {
      if ((context as ModelFnContext)?.context?.params != undefined) {
        (context as ModelFnContext).context.params.set('fields', () => fields);
      }
    } catch (error) {
      console.log(context);
      throw new Error('setFieldsContext');
    }
  }

  public static createServiceSingleResultScalar(): Array<
    GqlSubField | GqlField
  > {
    return [
      GqlField.create('data'),
      ModelFieldCompilerService.createErrorField(),
    ];
  }
  public static createFieldsLayout(isList: boolean): GqlFields {
    let fields: GqlFields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('key'),
        GqlField.create('name'),
        GqlField.create('value'),
      ]),
    ];

    if (isList) {
      fields.push(GqlField.create('totalCount'));
    }

    return fields;
  }

  public static createServiceSingleResultOfFileDescriptor(): Array<
    GqlSubField | GqlField
  > {
    return [
      GqlSubField.create('data', [
        GqlField.create('data'),
        GqlField.create('name'),
        GqlField.create('type'),
      ]),
      ModelFieldCompilerService.createErrorField(),
    ];
  }
  public static createErrorField(): GqlField | GqlSubField {
    return GqlSubField.create('errors', [
      GqlField.create('messageError'),
      GqlField.create('property'),
      GqlField.create('validationCategory'),
    ]);
  }
  public static createServiceResultDefaultFields(
    isList: boolean,
    needId: boolean = true,
  ): Array<GqlSubField | GqlField> {
    let res: Array<GqlSubField | GqlField> = [
      GqlSubField.create('data', needId ? [GqlField.create('id')] : []),
      ModelFieldCompilerService.createErrorField(),
    ];
    if (isList) {
      res.push(GqlField.create('totalCount'));
    }
    return res;
  }

  public static appointmentFields(
    additionnalField: any[] = [],
    ticketFields: boolean,
  ): Array<GqlField | GqlSubField> {
    let add = [
      GqlField.create('allDay'),
      GqlField.create('description'),
      GqlField.create('endDate'),
      GqlField.create('endDateTimeZone'),
      GqlField.create('recurrenceException'),
      GqlField.create('recurrenceRule'),
      GqlField.create('startDate'),
      GqlField.create('startDateTimeZone'),
      GqlField.create('text'),
      GqlField.create('disabled'),
      GqlField.create('html'),
      GqlField.create('template'),
      GqlField.create('visible'),
      GqlField.create('id'),
    ];
    if (additionnalField != undefined && additionnalField.length > 0) {
      additionnalField.forEach((f) => {
        add.push(GqlField.create('scanConfigurationIds'));
      });
    }
    if (ticketFields == true) {
      add.push(GqlField.create('operatorId'));
      add.push(GqlField.create('operatorTeamId'));
      add.push(GqlField.create('ticketStatusId'));
      add.push(GqlField.create('taskStatusId'));
      add.push(GqlField.create('color'));
      add.push(GqlField.create('type'));
      add.push(GqlField.create('ticketId'));
      add.push(GqlField.create('taskId'));
    }
    return [GqlSubField.create('data', add)];
  }

  public static createOrUseField(
    name: string,
    onType: string,
    fields: Array<GqlSubField | GqlField>,
    isSubField: boolean,
    argNames: Array<GqlSubFieldArg> = null,
  ): GqlSubField | GqlField {
    let result: GqlSubField | GqlField;
    let alreadyExist = fields.find((f) => f.name === name);

    if (name == 'rankOrder') {
      this.createOrUseField('canDrag', onType, fields, false);
      this.createOrUseField('rankState', onType, fields, false);
    }
    if (alreadyExist == undefined) {
      if (isSubField === false) {
        result = {
          name: name,
          kind: 'GqlField',
          onType: onType,
        };
      } else {
        let argg = argNames != undefined ? argNames : [];
        result = {
          kind: 'GqlSubField',
          name: name,
          args: argg,
          fields: [],
          onType: onType,
        };
      }
      fields.push(result);
    } else {
      if (
        (alreadyExist.kind === 'GqlField' && isSubField === true) ||
        (alreadyExist.kind === 'GqlSubField' && isSubField === false)
      ) {
        throw new Error(
          'createOrUseField : field wanted and founded has different type subfield and not',
        );
      }
      return alreadyExist;
    }

    return result;
  }

  public static createField(
    fieldPath: string,
    onType: string,
    fields: Array<GqlSubField | GqlField>,
    argNames: Array<GqlSubFieldArg> = null,
  ) {
    if (fieldPath == undefined) {
      return;
    }
    let testReg = fieldPath.match(/^\w{1,}/g);

    const regex = /(^\w{1,})\.(.*?$)/g;
    let subFields = regex.exec(fieldPath);

    if (subFields != undefined) {
      let currentField = subFields[1];
      let subField = subFields[2];
      let result = this.createOrUseField(
        currentField,
        onType,
        fields,
        true,
        argNames,
      ); // true so return is GqlSubField
      ModelFieldCompilerService.createField(
        subField,
        null,
        (result as GqlSubField).fields,
      );
    } else {
      // if (fieldPath === 'isSystem') { // permet de pas avoir un issystem toujours a false
      //   this.createOrUseField('key', onType, fields, false);
      // }
      let result = this.createOrUseField(fieldPath, onType, fields, false);
    }
    // fieldPath.split('.').forEach()
  }

  /** Ajoute les colonnes dynamiq */
  public dynamicColumns(
    translatedFieldHelperService: TranslatedFieldHelperService,
    state: ModelState = undefined,
    allColumns: Column[],
  ) {
    let col = [];
    // Vérification si champs perso
    let dynamicPropertyKey = state?.model?.grid?.layout?.dynamicPropertyKey;
    dynamicPropertyKey = dynamicPropertyKey?.map((d) =>
      d?.toString()?.toLowerCase(),
    );
    if (dynamicPropertyKey != undefined) {
      let dynamicProperties =
        this.organizationStorageService?.getDynamicPropertyFields();
      if (dynamicProperties != undefined) {
        dynamicProperties
          .filter(
            (s) =>
              s.entityTypes.find((t) =>
                dynamicPropertyKey.includes(t.toLowerCase()),
              ) != undefined,
          )
          .forEach((f) => {
            let dataType = undefined;
            let format = undefined;
            let template = undefined;
            let excludeField = false;
            let translatable = false;
            let field = 'dynamicProperties.' + f.propertyName;

            switch (f.dynamicPropertyFieldType) {
              case DynamicPropertyFieldEnum.Email:
                template = 'mailToTemplate';
                break;
              case DynamicPropertyFieldEnum.Html:
                template = 'displayWithoutHTMLTemplate';
                break;
              case DynamicPropertyFieldEnum.Link:
                template = 'hrefTemplate';
                break;
              case DynamicPropertyFieldEnum.Numeric:
                dataType = 'number';
                if (
                  f.dynamicPropertyNumericFormat
                    ?.dynamicPropertyNumericFormat ==
                  DynamicPropertyNumericFormat.Decimal
                ) {
                  format = '#,##0.00';
                }
                break;
              case DynamicPropertyFieldEnum.DateTime:
                dataType = 'datetime';
                format = 'shortDateShortTime';
                switch (f.dynamicPropertyDateTime?.dynamicPropertyDateFormat) {
                  case DynamicPropertyDateFormat.LongDate:
                    dataType = 'datetime';
                    format = 'shortDateShortTime';
                    break;
                  case DynamicPropertyDateFormat.ShortDate:
                    dataType = 'date';
                    format = 'shortDate';
                    break;
                  case DynamicPropertyDateFormat.Time:
                    dataType = 'date';
                    format = 'shortTime';
                    break;
                }

                break;
              case DynamicPropertyFieldEnum.Bool:
                dataType = 'boolean';
                break;
              case DynamicPropertyFieldEnum.Image:
                excludeField = true;
                break;
              case DynamicPropertyFieldEnum.SelectList:
                translatable = true;
                field +=
                  '.data.' + translatedFieldHelperService.getTranslateKey();
                // on désactive temporairement le temps de trouver une solution en back
                // pour filtrer sur les dynamicProperty de type liste
                f.dynamicPropertyColumnOption.filterable = true;
                break;
            }
            if (excludeField == false) {
              var dynamicCol = {
                allowSorting: f.dynamicPropertyColumnOption.sortable,
                allowFiltering: f.dynamicPropertyColumnOption.filterable,
                allowGrouping: f.dynamicPropertyColumnOption.sortable,
                visible:
                  f.dynamicPropertyColumnOption.visible &&
                  f.dynamicPropertyColumnOption.showInColumnChooser,
                showInColumnChooser:
                  f.dynamicPropertyColumnOption.showInColumnChooser,
                allowResizing: true,
                field: field,
                label: f.name[translatedFieldHelperService.getTranslateKey()],
                dataType: dataType,
                template: template,
                format: format,
                translatable: translatable,
              };

              if (state?.model?.grid?.layout?.isQueryBuilder !== true) {
                // Mode liste classique on ajoute constament les champs perso
                col.push(dynamicCol);
              } else {
                // Mode query builder met a jour les colonnes dynamique uniquement si présent
                var alreadAdd = allColumns.findIndex(
                  (f) => (field + '.').indexOf(f.field + '.') >= 0,
                );
                if (alreadAdd >= 0) {
                  dynamicCol.visible = allColumns[alreadAdd].visible;
                  allColumns[alreadAdd] = dynamicCol;
                }
              }
            }
          });
      }
    }

    // Ajout les colonnes
    col.forEach((f) => {
      allColumns.push(f);
    });
  }

  public setColumnsFields(
    columns: Column[],
    fields: Array<GqlSubField | GqlField>,
    translatedFieldHelperService: TranslatedFieldHelperService,
    state: ModelState = undefined,
  ) {
    this.dynamicColumns(translatedFieldHelperService, state, columns);

    columns?.forEach((col) => {
      if (col.field == '__typename' || col.field == 'ticket.data.__typename') {
        col.allowFiltering = col.headerFilterLookup != undefined ? true : false;
      }

      if (col.field == '__typename' && col.format == 'ticketType') {
        col.headerFilterLookup = {
          model: '__typename',
          columnExp: '__typename',
        };
        col.allowFiltering = true;
      }

      if (col.field == 'qualification') {
        col.allowFiltering = col.headerFilterLookup != undefined ? true : false;
      }

      if (col.field == 'qualification' && col.format == 'ticketType') {
        col.headerFilterLookup = {
          model: col.headerFilterLookup?.model ?? 'qualification',
          columnExp: 'qualification',
        };
        col.allowFiltering = true;
      }

      if (col.targetIdExpr) {
        console.log(col);
        throw new Error('getColumnsFields');
      }
      if (col.parentIdExpr) {
        console.log(col);
        throw new Error('getColumnsFields');
      }

      if (col.template == 'arrayEnumTemplate') {
        col.allowFiltering = false;
        col.allowSorting = false;
      }

      if (col.field && col.managed !== false) {
        if (col.neededFields != undefined && col.neededFields.length > 0) {
          col.neededFields.forEach((element: string) => {
            ModelFieldCompilerService.createField(element, col.onType, fields);
          });
        }
        let args: Array<GqlSubFieldArg> = null;
        if (col.calculatedFieldArgNames != undefined) {
          args = [];
          col.calculatedFieldArgNames.forEach((el: string) => {
            args.push(GqlSubFieldArg.create(el, el));
          });
        }
        if (col.translatable === true) {
          // TODO TRAD
          col.field = translatedFieldHelperService.setColumnTranslateField(
            col.field,
          );
        }
        if (
          col.neededFields == undefined ||
          col.neededFields.find((el: string) => el.includes(col.field)) ==
            undefined
        ) {
          ModelFieldCompilerService.createField(
            col.field,
            col.onType,
            fields,
            args,
          );
        }
      }
    });
  }

  private setFilterFields(
    filter: ButtonTreeViewOptions,
    fields: Array<GqlSubField | GqlField>,
  ) {
    let filterFields: Array<GqlSubField | GqlField> = JSON.parse(
      JSON.stringify(fields),
    );

    if (filter.filterExpr) {
      if (filter.filterExprTranslatable === true) {
        filter.filterExpr =
          this.translatedFieldHelperService.setColumnTranslateField(
            filter.filterExpr,
          );
      }
      ModelFieldCompilerService.createField(
        filter.filterExpr,
        null,
        (filterFields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }
    if (filter.source != undefined) {
      if (filter.displayExpr == undefined || filter.keyExpr == undefined) {
        console.log('setFilterFields');
        console.log(filter);
        throw new Error(
          'setFilterFields filter.displayExpr == undefined || filter.keyExpr == undefined',
        );
      }

      let filterSourceFields =
        ModelFieldCompilerService.createServiceResultDefaultFields(true);
      if (filter.parentIdExpr) {
        ModelFieldCompilerService.createField(
          filter.parentIdExpr,
          null,
          (filterSourceFields.find((f) => f.name === 'data') as GqlSubField)
            .fields,
        );
      }
      if (filter.keyExpr) {
        ModelFieldCompilerService.createField(
          filter.keyExpr,
          null,
          (filterSourceFields.find((f) => f.name === 'data') as GqlSubField)
            .fields,
        );
      }
      if (filter.translatable === true) {
        filter.displayExpr =
          this.translatedFieldHelperService.setColumnTranslateField(
            filter.displayExpr,
          );
      }
      ModelFieldCompilerService.createField(
        filter.displayExpr,
        null,
        (filterSourceFields.find((f) => f.name === 'data') as GqlSubField)
          .fields,
      );
      ModelFieldCompilerService.createField(
        filter.keyExpr,
        null,
        (filterSourceFields.find((f) => f.name === 'data') as GqlSubField)
          .fields,
      );

      this.setFieldsContext(filter.source, filterSourceFields);
    }

    if (filter.list != undefined && filter.list.source != undefined) {
      this.setFieldsContext(filter.list.source, filterFields);
    }
  }

  private compileScheduler(layout: any, state: ModelState) {
    let fields = layout.options.fields.fnCall();

    if (layout.filters != undefined) {
      let plusfields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.appointmentFields(null, true);

      layout.filters.forEach((filter) => {
        filter.items.forEach((item) => {
          this.setFilterFields(item, plusfields);
        });

        if (filter.list != undefined) {
        }
      });
    }

    if (layout.options.fields != undefined) {
      this.setFieldsContext(layout.options.source, fields);
    } else {
      console.error(
        'Le composant "Scheduler" doit comporter la propriété "fields".',
      );
    }

    if (layout.options.sourceRessource != undefined) {
      this.setFieldsContext(layout.options.sourceRessource, [
        GqlSubField.create('data', [
          GqlField.create('id'),
          GqlField.create('name'),
        ]),
        GqlField.create('totalCount'),
      ]);
    }

    if (layout.options.fields != undefined) {
      this.setFieldsContext(layout.filters[0].items[0].list.source, fields);
    }

    if (layout.options.insertRessource != undefined) {
      this.setFieldsContext(
        layout.options.insertRessource,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }

    if (layout.options.updateRessource != undefined) {
      this.setFieldsContext(
        layout.options.updateRessource,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }

    if (layout.options.fields != undefined) {
      this.setFieldsContext(
        layout.options.fields,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
  }

  private compileFileMangerOptions(options: FileManagerOptions) {
    let fields: Array<GqlSubField | GqlField> =
      FileManagerCoreService.FileModelFields();

    if (options.addItems != undefined) {
      this.setFieldsContext(
        options.addItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }

    if (options.removeItems != undefined) {
      this.setFieldsContext(
        options.removeItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (options.source != undefined) {
      (options.source as any as ModelDataSourceContext).context.params.set(
        'fields',
        () => fields,
      );
    }
  }
  private compileFileUploaderOptions(options: FileUploaderOptions) {
    let fields: Array<GqlSubField | GqlField> =
      FileManagerCoreService.FileModelFields();

    if (options.addItems != undefined) {
      this.setFieldsContext(
        options.addItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }

    if (options.removeItems != undefined) {
      this.setFieldsContext(
        options.removeItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (options.source != undefined) {
      (options.source as any as ModelDataSourceContext).context.params.set(
        'fields',
        () => fields,
      );
    }
  }

  private compileHtmlEditorOptions(
    options: HtmlEditorOptions,
    translatable: boolean,
    state: ModelState,
  ) {
    if (options != undefined) {
      if (options.resolutionModel != undefined) {
        let fields: Array<GqlSubField | GqlField> =
          ModelFieldCompilerService.createServiceResultDefaultFields(true);

        if (options.resolutionModel['translatable'] === true) {
          this.setTranslateFieldField(
            options.resolutionModel.displayExpr,
            fields,
          );
          options.resolutionModel.displayExpr +=
            '.' + this.translatedFieldHelperService.getTranslateKey();
          this.setTranslateFieldField(
            options.resolutionModel.contentExpr,
            fields,
          );
        } else {
          ModelFieldCompilerService.createField(
            options.resolutionModel.displayExpr,
            null,
            (fields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
          ModelFieldCompilerService.createField(
            options.resolutionModel.contentExpr,
            null,
            (fields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
        }

        // ModelFieldCompilerService.createField(
        //   options.resolutionModel.valueExpr,
        //   null,
        //   (fields.find((f) => f.name === 'data') as GqlSubField)
        //     .fields,
        // );

        if (options.resolutionModel.source != undefined) {
          this.setFieldsContext(options.resolutionModel.source, fields);
        }
      }
      if (options.commentaryModel != undefined) {
        let fields: Array<GqlSubField | GqlField> =
          ModelFieldCompilerService.createServiceResultDefaultFields(true);
        if (options.commentaryModel.translatable === true) {
          options.commentaryModel.displayExpr =
            this.translatedFieldHelperService.setColumnTranslateField(
              options.commentaryModel.displayExpr,
            );
        }
        if (options.commentaryModel.contentTranslatable === true) {
          options.commentaryModel.contentExpr =
            this.translatedFieldHelperService.setColumnTranslateField(
              options.commentaryModel.contentExpr,
            );
        }

        ModelFieldCompilerService.createField(
          options.commentaryModel.displayExpr,
          null,
          (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        );
        ModelFieldCompilerService.createField(
          options.commentaryModel.contentExpr,
          null,
          (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        );

        if (options.commentaryModel.source != undefined) {
          this.setFieldsContext(options.commentaryModel.source, fields);
        }
      }
    }
  }

  private compileMessageModelOptions(options: MessageTicketOptions) {
    if (options != undefined) {
      if (options.messageModel != undefined) {
        let fields: Array<GqlSubField | GqlField> =
          ModelFieldCompilerService.createServiceResultDefaultFields(true);
        if (options.messageModel['translatable'] === true) {
          this.setTranslateFieldField(options.messageModel.displayExpr, fields);

          options.messageModel.displayExpr +=
            '.' + this.translatedFieldHelperService.getTranslateKey();
          this.setTranslateFieldField(options.messageModel.contentExpr, fields);
        } else {
          ModelFieldCompilerService.createField(
            options.messageModel.displayExpr,
            null,
            (fields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
          ModelFieldCompilerService.createField(
            options.messageModel.contentExpr,
            null,
            (fields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
        }

        if (options.messageModel.source != undefined) {
          this.setFieldsContext(options.messageModel.source, fields);
        }
      }
    }
  }

  private compileTicketEmailListOptions(
    options: TicketEmailListOptions,
    translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        translatedFieldHelperService,
      );
    }

    if (options.masterDetail != undefined) {
      this.compileMasterDetail(options.masterDetail);
    }

    if (options.source != undefined) {
      this.setFieldsContext(options.source, fields);
    }
  }

  private compileTagBoxEmailOptions(options: TagBoxEmailOptions) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);

    if (options?.dataSource != undefined) {
      ModelFieldCompilerService.createField(
        options.valueExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
      ModelFieldCompilerService.createField(
        options.displayExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
      this.setFieldsContext(options.dataSource, fields);
    }
  }

  private createGridLayoutFields(
    layout: GridLayout,
    state: ModelState,
    translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    if (layout.type != 'Scheduler') {
      let findFields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.createServiceResultDefaultFields(false);

      this.setColumnsFields(
        layout.columns,
        (findFields.find((f) => f.name === 'data') as GqlSubField).fields,
        translatedFieldHelperService,
        state,
      );
      if (layout.type === 'Tree') {
        if (layout.parentIdExpr == null) {
          layout.parentIdExpr = 'parentId';
        }
        ModelFieldCompilerService.createField(
          layout.parentIdExpr,
          null,
          (findFields.find((f) => f.name === 'data') as GqlSubField).fields,
        );

        if (layout.enabledExp != undefined) {
          ModelFieldCompilerService.createField(
            layout.enabledExp,
            null,
            (findFields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
        }
      }
      this.compileCommands(layout.commands, state, findFields);

      layout.filters.forEach((filter) => {
        filter.items.forEach((item) => {
          this.setFilterFields(item, findFields);
        });

        if (filter.list != undefined) {
        }
      });

      if (
        layout.masterDetail != undefined &&
        Array.isArray(layout.masterDetail)
      ) {
        this.compileMasterDetail(layout.masterDetail);
      }
    } else {
      this.compileScheduler(layout, state);
    }
  }

  private compileCommands(
    commands: (Command | CommandItems)[],
    state: ModelState,
    findFields: GqlFields,
  ) {
    if (commands != undefined) {
      commands.forEach((el) => {
        let command = el as CommandItems;
        let commandSourceFields: Array<GqlSubField | GqlField> =
          ModelFieldCompilerService.createServiceResultDefaultFields(true);
        if (command.source === undefined) {
          commandSourceFields = findFields;
          // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
        }

        if (command['translatable'] === true) {
          this.setTranslateFieldField(command.displayExpr, commandSourceFields);
          command.displayExpr +=
            '.' + this.translatedFieldHelperService.getTranslateKey();
        } else {
          ModelFieldCompilerService.createField(
            command.displayExpr,
            null,
            (commandSourceFields.find((f) => f.name === 'data') as GqlSubField)
              .fields,
          );
        }

        ModelFieldCompilerService.createField(
          command.keyExpr,
          null,
          (commandSourceFields.find((f) => f.name === 'data') as GqlSubField)
            .fields,
        );
        if (command.parentIdExpr != undefined) {
          ModelFieldCompilerService.createField(
            command.parentIdExpr,
            null,
            (commandSourceFields.find((f) => f.name === 'data') as GqlSubField)
              .fields,
          );
        }
        if (command.source != undefined) {
          this.setFieldsContext(command.source, commandSourceFields);
        }
      });
    }
  }

  private compileLayout(layout: GridLayout, state: ModelState) {
    // let fields: Array<GqlSubField | GqlField> = []

    // fields = [{ name: "data", kind: 'GqlField' }];

    // let countRecyclesContext = (layout as any).countRecycles as ModelFnContext;

    // countRecyclesContext.context.params.set('fields', () => fields)
    if (layout.countRecycles != undefined) {
      this.setFieldsContext(
        layout.countRecycles,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }

    if (layout.delete != undefined) {
      this.setFieldsContext(
        layout.delete,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (layout.restore != undefined) {
      this.setFieldsContext(
        layout.restore,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (layout.isUsed != undefined) {
      this.setFieldsContext(
        layout.isUsed,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (layout.drag != undefined) {
      this.setFieldsContext(
        layout.drag,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    this.createGridLayoutFields(
      layout,
      state,
      this.translatedFieldHelperService,
    );

    //console.log(layout.countRecycles.toString())
  }

  // NEXT method compile form

  /**
     *
    | 'required'
    | 'requiredTrue'
    | 'email'
    | 'pattern'
    | 'unique'
    | 'compare'
    | 'notNullOrEmpty'
    | 'ipAddress'
    | 'hierarchicalUnique'
    | 'customPattern'
    | 'requiredLicense'
    | 'requiredMatrix';
     */

  private async compileLinkOptions(
    options: LinkOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(
        false,
        options['needId'] === false ? false : true,
      );

    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileLinkOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }

    //#partie Filtre
    let defaultFilter: Filter[] = [];
    if (
      options?.filterSource != undefined &&
      ((<any>options.filterSource) as ModelFnContext).fnCall != undefined
    ) {
      if (
        options.source['methodeName'] != undefined &&
        options.source['serviceName'] != undefined
      ) {
        let m = (<any>options.filterSource) as ModelFnContext;
        m.context.params.set('source', () => options.source['serviceName']);
        m.context.params.set('method', () => options.source['methodeName']);
        defaultFilter = <any>await m.fnCall();
      }
    }

    // Vérification si null mais route connu
    if (defaultFilter.length == 0 && options.route != undefined) {
      let service = this.serviceByRoute(options.route);
      if (service != undefined && service['filters'] != undefined) {
        defaultFilter = await service['filters'](
          options.source['serviceName'],
          options.source['methodeName'],
        );
      }
    }

    // Vérification si le displayExpr est dans les colonne
    if (
      options.displayExpr != undefined &&
      options.columns != undefined &&
      options.translatable !== true
    ) {
      // Permet de vérifie si la colonne n'ets pas défini comme translatable
      let translatableExpr = options.columns.find(
        (d) => d.field == options.displayExpr && d.translatable === true,
      );
      if (translatableExpr != undefined) {
        options.translatable = true;
      }
    }
    // Vérificaiton inverse
    if (
      options.displayExpr != undefined &&
      options.columns != undefined &&
      options.translatable === true
    ) {
      options.columns.forEach((f) => {
        if (
          f.field == options.displayExpr &&
          options.translatable != f.translatable
        ) {
          f.translatable = options.translatable;
        }
      });
    }

    ModelFieldCompilerService.createField(
      options.valueExpr,
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );
    if (options.translatable === true) {
      let langs = this.translatedFieldHelperService.getLanguageDatasource();
      langs.forEach((lang) => {
        ModelFieldCompilerService.createField(
          options.displayExpr + '.' + lang.key,
          null,
          (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        );
      });
      options.displayExpr =
        this.translatedFieldHelperService.setColumnTranslateField(
          options.displayExpr,
        );
    } else {
      ModelFieldCompilerService.createField(
        options.displayExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }
    if (options.enabledExp != undefined) {
      ModelFieldCompilerService.createField(
        options.enabledExp,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options.itemHint != undefined) {
      if (options.itemHintTranslatable === true) {
        // TODO TRAD
        options.itemHint =
          this.translatedFieldHelperService.setColumnTranslateField(
            options.itemHint,
          );
      }

      ModelFieldCompilerService.createField(
        options.itemHint,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options['type'] != undefined && options['type'] == 'Tree') {
      ModelFieldCompilerService.createField(
        options.parentIdExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    this.setColumnsFields(
      options.columns,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      this.translatedFieldHelperService,
      state,
    );

    // Validation des filtre

    if (defaultFilter != undefined && defaultFilter.length > 0) {
      let filters = <Filter[]>options['filters'];
      options['filters'] = this.getFilters(filters, defaultFilter, state);
    }

    if (options['filters'] != undefined) {
      (options['filters'] as ButtonTreeViewOptions[]).forEach((f) => {
        this.setFilterFields(f, fields);
      });

      (options['filters'] as Filter[]).forEach((f) => {
        if (f.items != undefined) {
          f.items.forEach((item) => {
            this.setFilterFields(item, fields);
          });
        }
      });
    }

    if (options?.dynamicListId != undefined) {
      if ((options.source as ModelFnContext)?.context?.params != undefined) {
        (options.source as ModelFnContext).context.params.set(
          'dynamicListId',
          () => options.dynamicListId,
        );
      }
    }

    this.setFieldsContext(options.source, fields);
  }

  /** Fusion des filter spécifique et filtre automatique */
  private getFilters(
    filters: Filter[],
    defaultFilter: Filter[],
    state: ModelState,
  ): Filter[] {
    if (filters == undefined) filters = [];

    // Force le root context
    if (defaultFilter != undefined) {
      defaultFilter.forEach((f) => {
        if (f.items != undefined) {
          f.items.forEach((i) => {
            if (i.list.source != undefined) {
              i.list.source['rootState'] = state;
            }
            if (i.source != undefined) {
              i.source['rootState'] = state;
            }
          });
        }
        if (f.list != undefined) {
          f.list.source['rootState'] = state;
        }
      });
    }
    //#fin filtre

    defaultFilter.forEach((df) => {
      if (df.text != undefined) {
        // Par groupe
        let exist = filters.findIndex((f) => f.text == df.text);
        if (exist == undefined || exist < 0) {
          filters.push(df);
        } else {
          // PArcours les items
          df.items.forEach((item) => {
            let existItem = filters[exist].items.filter(
              (f) => f.text == item.text,
            );
            if (existItem == undefined || existItem.length == 0) {
              filters[exist].items.push(item);
            }
          });
        }
      } else {
        let exist = filters.findIndex((f) => f.text == undefined);
        if (exist == undefined || exist < 0) {
          filters.push(df);
        } else {
          df.items.forEach((item) => {
            let existItem = filters[exist].items.filter(
              (f) => f.text == item.text,
            );
            if (existItem == undefined || existItem.length == 0) {
              filters[exist].items.push(item);
            }
          });
        }
      }
    });

    return filters;
  }

  private compileTagBoxComponentOptions(
    options: TagBoxOptions,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(
        true,
        options.needId != undefined && options.needId == false ? false : true,
      );
    if (options != undefined && options.valueExpr != undefined) {
      ModelFieldCompilerService.createField(
        options.valueExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );

      if (options.translatable == true) {
        options.displayExpr +=
          '.' + this.translatedFieldHelperService.getTranslateKey();
      }
      ModelFieldCompilerService.createField(
        options.displayExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }
    if (
      options.source != undefined &&
      options.source instanceof AbstractModelContext
    ) {
      this.setFieldsContext(options.source, fields);
    }
  }

  // Compilation des options du component VisibleSelectableEntity
  private compileVisibleSelectableEntityOptions(
    options: any,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);

    if (options.source === undefined) {
      throw new Error('compileGridOptions');
    }

    if (options.keyExpr !== 'id') {
      ModelFieldCompilerService.createField(
        'id',
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options.keyExpr != undefined) {
      ModelFieldCompilerService.createField(
        options.keyExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options.parentIdExpr != undefined) {
      ModelFieldCompilerService.createField(
        options.parentIdExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
    }
    this.setFieldsContext(options.source, fields);
  }

  /** Compile les option de lookup */
  private compileLookupOptions(columns: Column[]) {
    columns.forEach((col) => {
      if (col.lookup != undefined) {
        let lookupFields =
          ModelFieldCompilerService.createServiceResultDefaultFields(
            true,
            false,
          );

        if (col.lookup.translatable == true) {
          col.lookup.displayExpr +=
            '.' + this.translatedFieldHelperService.getTranslateKey();
        }

        ModelFieldCompilerService.createField(
          col.lookup.displayExpr,
          null,
          (lookupFields.find((f) => f.name === 'data') as GqlSubField).fields,
        );

        ModelFieldCompilerService.createField(
          col.lookup.valueExpr,
          null,
          (lookupFields.find((f) => f.name === 'data') as GqlSubField).fields,
        );
        this.setFieldsContext(col.lookup.source, lookupFields);
      }
    });
  }

  private async compileGridOptions(
    options: GridOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);

    if (control.type != 'CommandAssetCategoryListComponent') {
      if (options.managed !== false && control.fieldName != undefined) {
        ModelFieldCompilerService.createField(
          control.fieldName,
          control.onType,
          (state.formFields[0] as GqlSubField).fields,
        ); // because we need fieldname: name but we request data.name
      }
    }

    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileGridOptions');
    }
    if (options.keyExpr !== 'id') {
      ModelFieldCompilerService.createField(
        'id',
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }
    if (options.keyExpr != undefined) {
      ModelFieldCompilerService.createField(
        options.keyExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (options.parentIdExpr != undefined) {
      ModelFieldCompilerService.createField(
        options.parentIdExpr,
        null,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }

    if (
      options.selectSource != undefined &&
      options.selectColumns == undefined
    ) {
      options.selectColumns = options.columns;
    }

    if (
      options.selectSource != undefined &&
      options.selectColumns != undefined
    ) {
      let selectFields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.createServiceResultDefaultFields(true);
      this.setColumnsFields(
        options.selectColumns,
        (selectFields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );

      this.setFieldsContext(options.selectSource, selectFields);

      this.compileLookupOptions(options.selectColumns);
    }

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
      this.compileLookupOptions(options.columns);
    }

    this.setFieldsContext(options.source, fields);

    //Filter
    await this.setFilter(options, state, fields);
  }

  private async compileEditableListOptions(
    options: any,
    control: Control,
    state: ModelState,
  ) {
    if (options.managed !== false && control.fieldName != undefined) {
      // ModelFieldCompilerService.createField(
      //   control.fieldName,
      //   control.onType,
      //   (state.formFields[0] as GqlSubField).fields,
      // );
    }

    if (options.sourceColumns != undefined) {
      let fields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.createServiceResultDefaultFields(true, false);

      this.setColumnsFields(
        options.sourceColumns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
      this.setFieldsContext(options.source, fields);
    }

    if (options.objectColumns != undefined) {
      let fields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.createServiceResultDefaultFields(true, false);

      this.setColumnsFields(
        options.objectColumns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );

      for (let field of (fields.find((f) => f.name === 'data') as GqlSubField)
        .fields) {
        ModelFieldCompilerService.createField(
          control.fieldName + '.' + field.name,
          control.onType,
          (state.formFields[0] as GqlSubField).fields,
        );
      }
    }
  }

  private async compileLinkListOptions(
    options: LinkListOptions,
    control: Control,
    state: ModelState,
    totalCount: boolean = true,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(totalCount);
    let actionFields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceSingleResultScalar();

    if (options.addItems != undefined) {
      this.setFieldsContext(options.addItems, actionFields);
    }

    if (options.removeItems != undefined) {
      this.setFieldsContext(options.removeItems, actionFields);
    }

    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileLinkOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }
    // ModelFieldCompilerService.createField(options.valueExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    ModelFieldCompilerService.createField(
      'id',
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
    }
    this.setFieldsContext(options.source, fields);
    if (options.defaultSource != undefined) {
      this.setFieldsContext(options.defaultSource, fields);
    }
    if (options.selectSource != undefined) {
      this.setFieldsContext(options.selectSource, fields);
    }

    if (
      options.masterDetail != undefined &&
      Array.isArray(options.masterDetail)
    ) {
      this.compileMasterDetail(options.masterDetail);
    }

    await this.setFilter(options, state, fields);
  }

  /** Set de filter */
  async setFilter(
    options,
    state: ModelState,
    fields: Array<GqlSubField | GqlField>,
  ) {
    //Filter
    let defaultFilter: Filter[] = [];
    if (
      options?.filterSource != undefined &&
      ((<any>options.filterSource) as ModelFnContext).fnCall != undefined &&
      options.source != undefined
    ) {
      if (
        options.source['methodeName'] != undefined &&
        options.source['serviceName'] != undefined
      ) {
        let m = (<any>options.filterSource) as ModelFnContext;
        m.context.params.set('source', () => options.source['serviceName']);
        m.context.params.set('method', () => options.source['methodeName']);
        defaultFilter = <any>await m.fnCall();
      }
    }
    // Vérification si null mais route connu
    if (defaultFilter.length == 0 && options.route != undefined) {
      let service = this.serviceByRoute(options.route);
      if (service != undefined && service['filters'] != undefined) {
        defaultFilter = await service['filters'](
          options.source['serviceName'],
          options.source['methodeName'],
        );
      }
    }

    if (defaultFilter != undefined && defaultFilter.length > 0) {
      options.filters = this.getFilters(options.filters, defaultFilter, state);
    }

    if (options.filters != undefined) {
      options.filters.forEach((filter) => {
        filter.items.forEach((item) => {
          this.setFilterFields(item, fields);
        });
      });
    }
  }

  /** Renvoi le nom du service */
  serviceByRoute(route: string) {
    switch (route) {
      case 'assets':
        return this.injector.get(AssetCoreService);
      case 'users':
        return this.injector.get(UserCoreService);
    }

    return undefined;
  }

  /** Création du context et paramètres du master detail */
  private compileMasterDetail(masterDetails: MasterDetail[]) {
    masterDetails.forEach((master) => {
      let fields: Array<GqlSubField | GqlField> =
        ModelFieldCompilerService.createServiceResultDefaultFields(true, false);

      this.setColumnsFields(
        master.control.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
      );

      this.setFieldsContext(master.control.source, fields);
    });
  }

  private compileValidator(validator: Validator, state: ModelState) {
    if (
      validator.name === 'unique' ||
      validator.name === 'hierarchicalUnique'
    ) {
      this.setFieldsContext(
        validator.value as ModelFnContext,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
  }

  private createAttributesFields(): GqlSubField | GqlField {
    let field: GqlSubField | GqlField = GqlSubField.create('attributes', [
      GqlSubField.create('created', [
        GqlField.create('date'),
        GqlField.create('state'),
        GqlField.create('userId'),
      ]),
      GqlSubField.create('deleted', [
        GqlField.create('date'),
        GqlField.create('state'),
        GqlField.create('userId'),
      ]),
      GqlSubField.create('locked', [
        GqlField.create('date'),
        GqlField.create('state'),
        GqlField.create('userId'),
      ]),
      GqlSubField.create('updated', [
        GqlField.create('date'),
        GqlField.create('state'),
        GqlField.create('userId'),
      ]),
    ]);

    return field;
  }

  private compileSchedulerOptions(options: SchedulerOptions) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.appointmentFields(options.neededFields, false);

    if (options.addItems != undefined) {
      (options.addItems as any as ModelFnContext).context.params.set(
        'fields',
        () => fields,
      );

      this.setFieldsContext(
        options.addItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (options.updateItems != undefined) {
      (options.updateItems as any as ModelFnContext).context.params.set(
        'fields',
        () => fields,
      );

      this.setFieldsContext(
        options.updateItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (options.removeItems != undefined) {
      (options.removeItems as any as ModelFnContext).context.params.set(
        'fields',
        () => fields,
      );

      this.setFieldsContext(
        options.removeItems,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
    if (options.source != undefined) {
      (options.source as any as ModelDataSourceContext).context.params.set(
        'fields',
        () => fields,
      );
    }

    if (options.sourceRessource != undefined) {
      (
        options.sourceRessource as any as ModelDataSourceContext
      ).context.params.set('fields', () => fields);
    }

    if (options.insertRessource != undefined) {
      (options.insertRessource as any as ModelFnContext).context.params.set(
        'fields',
        () => fields,
      );
    }

    if (options.updateRessource != undefined) {
      (options.updateRessource as any as ModelFnContext).context.params.set(
        'fields',
        () => fields,
      );
    }
  }
  private compileSimpleListOptions(
    options: SimpleListOptions,
    control: Control,
    state: ModelState,
  ) {
    //ModelFieldCompilerService.createField(control.fieldName, control.onType, (state.formFields[0] as GqlSubField).fields); // because we need fieldname: name but we request data.name
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true, false);

    if (options.columns != undefined) {
      this.compileLookupOptions(options.columns);

      options.columns.forEach((col) => {
        if (control.managed !== false) {
          if (col.template === 'logicalDiskTemplate') {
            ModelFieldCompilerService.createField(
              control.fieldName + '.' + 'caption',
              null,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name
            ModelFieldCompilerService.createField(
              control.fieldName + '.' + 'size',
              null,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name
            ModelFieldCompilerService.createField(
              control.fieldName + '.' + 'volumeName',
              null,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name
            ModelFieldCompilerService.createField(
              control.fieldName + '.' + 'freeSpace',
              null,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name
          }

          if (col.translatable === true) {
            // Charge toutes les traduction

            for (let lang of this.translatedFieldHelperService.getAllSupportedLanguage()) {
              ModelFieldCompilerService.createField(
                control.fieldName + '.' + col.field + '.' + lang,
                null,
                (state.formFields[0] as GqlSubField).fields,
              ); // because we need fieldname: name but we request data.name}
            }
          } else {
            ModelFieldCompilerService.createField(
              control.fieldName + '.' + col.field,
              null,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name}
          }
        }

        if (col['tagListTemplate'] != undefined) {
          let lookupFields =
            ModelFieldCompilerService.createServiceResultDefaultFields(
              true,
              false,
            );
          ModelFieldCompilerService.createField(
            col['tagListTemplate'].displayExpr,
            null,
            (lookupFields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
          ModelFieldCompilerService.createField(
            col['tagListTemplate'].valueExpr,
            null,
            (lookupFields.find((f) => f.name === 'data') as GqlSubField).fields,
          );
          this.setFieldsContext(col['tagListTemplate'].source, lookupFields);
        }
      });
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
      this.setFieldsContext(options.source, fields);
    }
  }

  private compileSelectSimpleListOptions(
    options: SelectSimpleListOptions,
    control: Control,
    state: ModelState,
  ) {
    //ModelFieldCompilerService.createField(control.fieldName, control.onType, (state.formFields[0] as GqlSubField).fields); // because we need fieldname: name but we request data.name
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true, false);

    if (options.columns != undefined) {
      this.compileLookupOptions(options.columns);
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
      this.setFieldsContext(options.source, fields);
    }
  }

  private compileDesignerRuleListOptions(
    options: DesignerRuleListOptions,
    control: Control,
    state: ModelState,
  ) {
    //ModelFieldCompilerService.createField(control.fieldName, control.onType, (state.formFields[0] as GqlSubField).fields); // because we need fieldname: name but we request data.name
    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(
      GqlSubField.create(
        control.fieldName,
        ModelRulesDesignerCoreService.getRuleFields(),
      ),
    );
  }

  private compileStockListOptions(
    options: StockListOptions,
    control: Control,
    state: ModelState,
  ) {
    if (options.columns != undefined) {
      options.columns.forEach((col) => {
        if (col.managed !== false) {
          ModelFieldCompilerService.createField(
            control.fieldName + '.' + col.field,
            null,
            (state.formFields[0] as GqlSubField).fields,
          ); // because we need fieldname: name but we request data.name
        }
      });
    }
  }

  private compileStockCreatedAssetOptions(
    options: StockCreatedAssetOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);
    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileStockCreatedAssetOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }
    // ModelFieldCompilerService.createField(options.valueExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    ModelFieldCompilerService.createField(
      'id',
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
    }
    this.setFieldsContext(options.source, fields);
    if (options.exportSource != undefined) {
      this.setFieldsContext(
        options.exportSource,
        ModelFieldCompilerService.createServiceSingleResultScalar(),
      );
    }
  }

  private compileAutoCompleteBoxOptions(
    options: AutoCompleteBoxOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(
        true,
        (options as any).needId === false ? false : true,
      );
    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileAutoCompleteBoxOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }

    if ((control?.options as any).translatable == true) {
      (control.options as any).valueExpr +=
        '.' + this.translatedFieldHelperService.getTranslateKey();
      (control.options as any).searchExpr +=
        '.' + this.translatedFieldHelperService.getTranslateKey();
    }

    ModelFieldCompilerService.createField(
      options.valueExpr,
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );

    this.setFieldsContext(options.source, fields);
  }

  private compileCurrentInStockListOptions(
    options: CurrentInStockListOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);
    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileCurrentInStockListOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }
    // ModelFieldCompilerService.createField(options.valueExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    ModelFieldCompilerService.createField(
      'id',
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );
    ModelFieldCompilerService.createField(
      'carts.amount',
      null,
      (state.formFields.find((f) => f.name === 'data') as GqlSubField).fields,
    );
    ModelFieldCompilerService.createField(
      'carts.cartId',
      null,
      (state.formFields.find((f) => f.name === 'data') as GqlSubField).fields,
    );
    ModelFieldCompilerService.createField(
      'carts.entryId',
      null,
      (state.formFields.find((f) => f.name === 'data') as GqlSubField).fields,
    );

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
    }
    this.setFieldsContext(options.source, fields);
  }

  private compilePermissionListOptions(control: Control, state: ModelState) {
    let permissionScopeFields: GqlFields = [
      GqlSubField.create('permissions', [
        GqlField.create('access'),
        GqlField.create('inheritFromIds'),
        GqlField.create('inheritType'),
        GqlField.create('inheritFrom'),
        GqlField.create('order'),
        GqlField.create('value'),
      ]),
      GqlField.create('type'),
      GqlField.create('value'),
    ];

    let sourceField: GqlFields = [
      GqlSubField.create('data', permissionScopeFields),
      ModelFieldCompilerService.createErrorField(),
    ];

    this.setFieldsContext((control.options as any).source, sourceField);

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, permissionScopeFields));
  }

  public static createTrackingResultScalar() {
    let trackingScopeFields: GqlFields = [
      GqlField.create('id'),
      GqlSubField.create('author', [
        GqlSubField.create('data', [GqlField.create('name')]),
      ]),
      GqlField.create('authorId'),
      GqlSubField.create('changes', [
        GqlField.create('id'),
        GqlField.create('name'),
        GqlField.create('newValue'),
        GqlField.create('oldValue'),
        GqlField.create('isTranslatable'),
      ]),
      GqlField.create('date'),
      GqlField.create('entityId'),
      GqlField.create('entityName'),
      GqlField.create('entityState'),
      GqlField.create('entityPropertyName'),
      GqlField.create('entitySourceId'),
      GqlField.create('entitySourceName'),
      GqlField.create('system'),
      GqlField.create('propertyKeyValue'),
      GqlField.create('inventoryHistoryId'),
    ];

    return [
      GqlSubField.create('data', trackingScopeFields),
      ModelFieldCompilerService.createErrorField(),
    ];
  }
  /** Field pour les TrackingLogListComponent */
  private compileTackingListComponentOptions(
    control: Control,
    state: ModelState,
  ) {
    let sourceField = ModelFieldCompilerService.createTrackingResultScalar();
    this.setFieldsContext((control.options as any).source, sourceField);

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, sourceField));
  }

  /** Field pour les MatrixComponent */
  private compileMatrixComponentOptions(control: Control, state: ModelState) {
    ModelFieldCompilerService.createField(
      control.fieldName + '.impactId',
      null,
      (state.formFields[0] as GqlSubField).fields,
    );
    ModelFieldCompilerService.createField(
      control.fieldName + '.priorityId',
      null,
      (state.formFields[0] as GqlSubField).fields,
    );
    ModelFieldCompilerService.createField(
      control.fieldName + '.urgencyId',
      null,
      (state.formFields[0] as GqlSubField).fields,
    );
  }

  private compileWorkTimeComponentOptions(control: Control, state: ModelState) {
    let fields: GqlFields = [
      GqlSubField.create('friday', this.GetWorkTimeConfigField()),
      GqlSubField.create('monday', this.GetWorkTimeConfigField()),
      GqlSubField.create('saturday', this.GetWorkTimeConfigField()),
      GqlSubField.create('sunday', this.GetWorkTimeConfigField()),
      GqlSubField.create('thursday', this.GetWorkTimeConfigField()),
      GqlSubField.create('tuesday', this.GetWorkTimeConfigField()),
      GqlSubField.create('wednesday', this.GetWorkTimeConfigField()),
    ];

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, fields));
  }

  private setTranslateFieldField(fieldName: string, existfields: GqlFields) {
    let fields: string[] = this.translatedFieldHelperService
      .getLanguageDatasource()
      .map((el) => el.key);

    for (let field of fields) {
      ModelFieldCompilerService.createField(
        fieldName + '.' + field,
        null,
        (existfields.find((f) => f.name === 'data') as GqlSubField).fields,
      );
    }
  }

  private compileTranslateFieldComponentOptions(
    control: Control,
    state: ModelState,
  ) {
    this.setTranslateFieldField(control.fieldName, state.formFields);
  }

  private compileDayOffComponentOptions(control: Control, state: ModelState) {
    let fields: GqlFields = [
      GqlField.create('start'),
      GqlField.create('end'),
      GqlField.create('description'),
    ];

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, fields));
  }
  compileLogicDiagramOptions(
    options: LogicTemplateOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: GqlFields = [
      GqlSubField.create('nodes', [
        GqlField.create('name'),
        GqlField.create('statusId'),
        GqlField.create('objId'),
        GqlField.create('x'),
        GqlField.create('y'),
        GqlField.create('isEntry'),
        GqlField.create('isEnding'),
        GqlField.create('isTakingCharge'),
        GqlField.create('isEndTreatment'),
        GqlField.create('isCommentaryRequired'),
        GqlField.create('canAddSatisfaction'),
        GqlField.create('isForbiddenPredecessor'),
        GqlField.create('type'),
        GqlSubField.create('rightToMove', [
          GqlField.create('allOperator'),
          GqlField.create('referentTeam'),
          GqlField.create('affectedTeam'),
          GqlField.create('allConcerned'),
          GqlField.create('operatorAffected'),
          GqlField.create('operatorReferent'),
          GqlField.create('makeRequest'),
          GqlField.create('affected'),
          GqlField.create('manager'),
          GqlField.create('customUserIds'),
          GqlField.create('allTaskFinished'),
        ]),
      ]),
      GqlSubField.create('edges', [
        GqlField.create('fromId'),
        GqlField.create('toId'),
        GqlField.create('objId'),
        GqlSubField.create('points', [
          GqlField.create('x'),
          GqlField.create('y'),
        ]),
        GqlField.create('fromPointIndex'),
        GqlField.create('toPointIndex'),
        GqlField.create('title'),
      ]),
    ];

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, fields));

    // (
    //   state.formFields.find((f) => f.name === 'data') as GqlSubField
    // ).fields.push(GqlSubField.create(control.fieldName, fields));
  }

  compilePropertyChangeOptions(
    options: PropertyChangeOptions,
    control: Control,
    state: ModelState,
  ) {
    let fields: GqlFields = [
      GqlField.create('editType'),
      GqlField.create('value'),
    ];

    (
      state.formFields.find((f) => f.name === 'data') as GqlSubField
    ).fields.push(GqlSubField.create(control.fieldName, fields));

    // (
    //   state.formFields.find((f) => f.name === 'data') as GqlSubField
    // ).fields.push(GqlSubField.create(control.fieldName, fields));
  }

  private compileSubFormLinkListOptions(
    control: Control,
    options: SubFormLinkList,
    state: ModelState,
  ) {
    let fields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceResultDefaultFields(true);
    let actionFields: Array<GqlSubField | GqlField> =
      ModelFieldCompilerService.createServiceSingleResultScalar();

    if (options.source === undefined) {
      ////console.log(control);
      throw new Error('compileSubFormLinkListOptions');
      // ModelFieldCompilerService.createField(command.filterExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    }
    // ModelFieldCompilerService.createField(options.valueExpr, (fields.find(f => f.name === 'data') as GqlSubField).fields);
    ModelFieldCompilerService.createField(
      'id',
      null,
      (fields.find((f) => f.name === 'data') as GqlSubField).fields,
    );

    if (options.columns != undefined) {
      this.setColumnsFields(
        options.columns,
        (fields.find((f) => f.name === 'data') as GqlSubField).fields,
        this.translatedFieldHelperService,
        state,
      );
    }

    this.setFieldsContext(options.source, fields);
  }

  private GetWorkTimeConfigField(): GqlFields {
    return [
      GqlField.create('enabled'),
      GqlSubField.create('times', [
        GqlField.create('start'),
        GqlField.create('startTick'),
        GqlField.create('end'),
        GqlField.create('endTick'),
      ]),
    ];
  }

  private compileControl(control: Control, state: ModelState) {
    if (
      !(
        control.managed != undefined &&
        control.managed === false &&
        (control.type === 'LinkComponent' ||
          control.type === 'SimpleListComponent' ||
          control.type === 'SelectSimpleListComponent' ||
          control.type === 'EditableListComponent' ||
          control.type === 'TagBoxCustomItemComponent' ||
          control.type === 'GridComponent' ||
          control.type === 'TagBoxComponent')
      )
    ) {
      if (
        (control.managed != undefined && control.managed === false) ||
        (control.type != undefined &&
          control.type == 'TrackingLogListComponent')
      ) {
        return;
      }
    }

    if (control.fieldName === 'attributes') {
      (state.formFields[0] as GqlSubField).fields.push(
        this.createAttributesFields(),
      );
    } else {
      if (control.type != undefined) {
        switch (control.type) {
          case 'MemoAreaComponent':
            break;
          case 'LicenseComponent':
            ModelFieldCompilerService.createField(
              control.fieldName + '.accept',
              null,
              (state.formFields[0] as GqlSubField).fields,
            );
            ModelFieldCompilerService.createField(
              control.fieldName + '.acceptedDate',
              null,
              (state.formFields[0] as GqlSubField).fields,
            );
            break;
          case 'LinkComponent':
            this.compileLinkOptions(
              control.options as LinkOptions,
              control,
              state,
            );
            if (control.managed !== false) {
              ModelFieldCompilerService.createField(
                control.fieldName,
                control.onType,
                (state.formFields[0] as GqlSubField).fields,
              ); // because we need fieldname: name but we request data.name

              //Si le control possède des needed fields on les parcours
              if (
                control.neededFields != undefined &&
                control.neededFields.length > 0
              ) {
                control.neededFields.forEach((element: string) => {
                  ModelFieldCompilerService.createField(
                    element,
                    control.onType,
                    (state.formFields[0] as GqlSubField).fields,
                  );
                });
              }
            }

            // Gestion du Iref
            if (control.iRefExpr != undefined) {
              let columnIref = (
                control.options as LinkOptions
              )?.columns?.filter((s) => s.hasIref === true);
              if (columnIref != undefined) {
                columnIref.forEach((f) => {
                  this.compileIRefOption(
                    control.iRefExpr,
                    f.field,
                    f.translatable,
                    (state.formFields[0] as GqlSubField).fields,
                  );
                });
              }
              this.compileIRefOption(
                control.iRefExpr,
                (control.options as LinkOptions).displayExpr,
                (control.options as LinkOptions).translatable,
                (state.formFields[0] as GqlSubField).fields,
              );
            }
            break;
          case 'LinkListComponent':
          case 'CheckLinkListComponent':
          case 'PrintLinkListComponent':
          case 'DynamicLinkListComponent':
            this.compileLinkListOptions(
              control.options as LinkListOptions,
              control,
              state,
              false,
            );
            break;
          case 'SchedulerComponent':
            this.compileSchedulerOptions(control.options as SchedulerOptions);
            break;
          case 'PropertyChangeComponent':
            this.compilePropertyChangeOptions(
              control.options as PropertyChangeOptions,
              control,
              state,
            );
            break;
          case 'SimpleListComponent':
            this.compileSimpleListOptions(
              control.options as SimpleListOptions,
              control,
              state,
            );
            break;
          case 'SelectSimpleListComponent':
            this.compileSelectSimpleListOptions(
              control.options as SelectSimpleListOptions,
              control,
              state,
            );
            break;
          case 'DesignerRuleListComponent':
            this.compileDesignerRuleListOptions(
              control.options as DesignerRuleListOptions,
              control,
              state,
            );
            break;
          case 'EditableListComponent':
            this.compileEditableListOptions(
              control.options as any,
              control,
              state,
            );
            break;
          case 'FlatListComponent':
            this.compileLinkListOptions(
              control.options as LinkListOptions,
              control,
              state,
            );
            break;
          case 'PnpEntityListComponent':
            this.compileLinkListOptions(
              control.options as LinkListOptions,
              control,
              state,
            );
            break;
          case 'GridComponent':
            this.compileGridOptions(
              control.options as GridOptions,
              control,
              state,
            );
            break;
          case 'StockListComponent':
            this.compileStockListOptions(
              control.options as StockListOptions,
              control,
              state,
            );
            break;
          case 'StockCreatedAssetListComponent':
            this.compileStockCreatedAssetOptions(
              control.options as StockCreatedAssetOptions,
              control,
              state,
            );
            break;
          case 'CurrentInStockListComponent':
            this.compileCurrentInStockListOptions(
              control.options as CurrentInStockListOptions,
              control,
              state,
            );
            break;
          case 'LogicDiagramComponent':
            this.compileLogicDiagramOptions(
              control.options as LogicTemplateOptions,
              control,
              state,
            );
            break;
          case 'AutoCompleteBoxComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            ); // because we need fieldname: name but we request data.name
            this.compileAutoCompleteBoxOptions(
              control.options as AutoCompleteBoxOptions,
              control,
              state,
            );
            break;
          case 'PasswordComponent':
            this.setFieldsContext(
              (control.options as any).resetPassword as any,
              ModelFieldCompilerService.createServiceSingleResultScalar(),
            );
            break;
          case 'PermissionsListComponent':
            this.compilePermissionListOptions(control, state);
            break;
          case 'CommandAssetCategoryListComponent':
            this.compileGridOptions(
              control.options as GridOptions,
              control,
              state,
            );
            break;
          case 'TrackingLogListComponent':
            this.compileTackingListComponentOptions(control, state);
            break;
          case 'FileManagerComponent':
            this.compileFileMangerOptions(
              control.options as FileManagerOptions,
            );
            if (control.fieldName == 'fileIds') {
              ModelFieldCompilerService.createField(
                control.fieldName,
                control.onType,
                (state.formFields[0] as GqlSubField).fields,
              );
            }
            break;
          case 'FileUploaderComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );

            this.compileFileUploaderOptions(
              control.options as FileUploaderOptions,
            );
            break;
          case 'MatrixComponent':
            this.compileMatrixComponentOptions(control, state);
            break;
          case 'WorkTimeComponent':
            this.compileWorkTimeComponentOptions(control, state);
            break;
          case 'DayOffComponent':
            this.compileDayOffComponentOptions(control, state);
            break;
          case 'TagBoxComponent':
            this.compileTagBoxComponentOptions(
              control.options as TagBoxOptions,
              state,
            );
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            break;
          case 'TagBoxCustomItemComponent':
            this.compileTagBoxComponentOptions(
              control.options as TagBoxOptions,
              state,
            );
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            break;
          case 'TextBoxComponent':
            if (
              control.fieldName == 'defaultName' ||
              control.fieldName == 'key'
            ) {
              control.visible = false;
            }

            if ((control.options as TextBoxOptions)?.translatable === true) {
              control.fieldName =
                this.translatedFieldHelperService.setColumnTranslateField(
                  control.fieldName,
                );
            }

            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            break;
          case 'CoreSubFormLinkListComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileSubFormLinkListOptions(
              control,
              control.options as SubFormLinkList,
              state,
            );
            break;
          case 'TranslateFieldComponent':
            this.compileTranslateFieldComponentOptions(control, state);

            break;
          case 'CoreSubFormGanttComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileSubFormLinkListOptions(
              control,
              control.options as SubFormLinkList,
              state,
            );
            break;
          case 'HtmlEditorComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileHtmlEditorOptions(
              control.options as HtmlEditorOptions,
              false,
              state,
            );
            break;
          case 'HtmlEditorTranslatedComponent':
            for (let lang of this.translatedFieldHelperService.getAllSupportedLanguage()) {
              ModelFieldCompilerService.createField(
                control.fieldName + '.' + lang,
                control.onType,
                (state.formFields[0] as GqlSubField).fields,
              );
            }
            this.compileHtmlEditorOptions(
              control.options as HtmlEditorOptions,
              true,
              state,
            );
            break;
          case 'HtmlEditorSimpleTranslatedComponent':
            for (let lang of this.translatedFieldHelperService.getAllSupportedLanguage()) {
              ModelFieldCompilerService.createField(
                control.fieldName + '.' + lang,
                control.onType,
                (state.formFields[0] as GqlSubField).fields,
              );
            }
            this.compileHtmlEditorOptions(
              control.options as HtmlEditorOptions,
              true,
              state,
            );
            break;
          case 'MessageTicketComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileMessageModelOptions(
              control.options as MessageTicketOptions,
            );
            break;
          case 'TicketEmailListComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileTicketEmailListOptions(
              control.options as TicketEmailListOptions,
              this.translatedFieldHelperService,
            );
            break;
          case 'TagBoxEmailComponent':
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            this.compileTagBoxEmailOptions(
              control.options as TagBoxEmailOptions,
            );
            break;
          case 'VisibleSelectableEntityComponent':
            let allFields = (state.formFields[0] as GqlSubField).fields;

            allFields.push(
              GqlSubField.create(control.fieldName, [
                GqlField.create('entityId'),
                GqlField.create('visible'),
                GqlField.create('allowed'),
              ]),
            );

            (state.formFields[0] as GqlSubField).fields = allFields;
            this.compileVisibleSelectableEntityOptions(
              control.options as GridOptions,
              control,
              state,
            );

            break;
          case 'VisibleSelectableLinkEntityComponent':
            if (control.options?.['entityType'] === 'LOCATION') {
              control.options['columns'] = [
                {
                  label: TranslateService.get('entities/population/name'),
                  field: 'name',
                  link: true,
                  linkType: 'administration/access/population',
                  sortIndex: 0,
                  sortOrder: 'asc',
                  translatable: true,
                },
                {
                  label: TranslateService.get('globals/show'),
                  field: 'locationVisible',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
                {
                  label: TranslateService.get('globals/allowView'),
                  field: 'locationAllowed',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
              ];
            } else if (
              control.options?.['entityType'] === 'ORGANIZATIONAL_UNIT'
            ) {
              control.options['columns'] = [
                {
                  label: TranslateService.get('entities/population/name'),
                  field: 'name',
                  link: true,
                  linkType: 'administration/access/population',
                  sortIndex: 0,
                  sortOrder: 'asc',
                  translatable: true,
                },
                {
                  label: TranslateService.get('globals/show'),
                  field: 'organizationalUnitVisible',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
                {
                  label: TranslateService.get('globals/allowView'),
                  field: 'organizationalUnitAllowed',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
              ];
            } else {
              control.options['columns'] = [
                {
                  label: TranslateService.get('entities/operatorTeam/name'),
                  field: 'name',
                  link: true,
                  linkType: 'administration/access/operator-team',
                  sortIndex: 0,
                  sortOrder: 'asc',
                  translatable: true,
                },
                {
                  label: TranslateService.get('globals/show'),
                  field: 'ticketCategoryVisible',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
                {
                  label: TranslateService.get('globals/allowView'),
                  field: 'ticketCategoryAllowed',
                  dataType: 'boolean',
                  allowEditing: true,
                  width: 150,
                },
              ];
            }

            this.compileLinkListOptions(
              control.options as VisibleSelectableLinkEntityListOptions,
              control,
              state,
            );
            break;
          default: // because we need fieldname: name but we request data.name
            ModelFieldCompilerService.createField(
              control.fieldName,
              control.onType,
              (state.formFields[0] as GqlSubField).fields,
            );
            break;
        }
      } else {
        ModelFieldCompilerService.createField(
          control.fieldName,
          control.onType,
          (state.formFields[0] as GqlSubField).fields,
        ); // because we need fieldname: name but we request data.name
      }

      if (control.validators) {
        control.validators.forEach((validator) =>
          this.compileValidator(validator, state),
        );
      }
    }
  }

  private compileIRefOption(
    irefName: string,
    displayExpr: string,
    translatable: boolean,
    fields: Array<GqlSubField | GqlField>,
  ) {
    // Vérification si chargement d'un champs de reference

    if (irefName != undefined) {
      if (displayExpr != undefined) {
        irefName = irefName + '.data.' + displayExpr;
        if (translatable === true) {
          // TODO TRAD
          irefName =
            this.translatedFieldHelperService.setColumnTranslateField(irefName);
        }

        ModelFieldCompilerService.createField(irefName, null, fields);
      }
    }
  }

  private compileFormPageSectionGroup(group: SectionGroup, state: ModelState) {
    group.controls.forEach((control) => {
      this.compileControl(control, state);
    });
  }

  private compileFormPageSection(section: Section, state: ModelState) {
    section.groups.forEach((group) => {
      this.compileFormPageSectionGroup(group, state);
    });
  }

  private compileFormPage(
    page: PageSection | PageControl | PageGroup | PageTab,
    state: ModelState,
  ) {
    if ((page as PageSection).sections != undefined) {
      (page as PageSection).sections.forEach((section) => {
        this.compileFormPageSection(section, state);
      });
    }
    if (page['tabs'] != undefined) {
      (page as PageTab).tabs.forEach((tab) => {
        if (tab['sections'] != undefined) {
          (tab as PageSection).sections.forEach((section) => {
            this.compileFormPageSection(section, state);
          });
        } else {
          this.compileControl((tab as PageControl).control, state);
        }
      });
    }
    if ((page as PageGroup).pages != undefined) {
      (page as PageGroup).pages.forEach((page) => {
        if (page['tabs'] != undefined) {
          (page as PageTab).tabs.forEach((tab) => {
            if (tab['sections'] != undefined) {
              (tab as PageSection).sections.forEach((section) => {
                this.compileFormPageSection(section, state);
              });
            } else {
              this.compileControl((tab as PageControl).control, state);
            }
          });
        } else {
          if (page['sections'] != undefined) {
            (page as PageSection).sections.forEach((section) => {
              this.compileFormPageSection(section, state);
            });
          } else {
            this.compileControl((page as PageControl).control, state);
          }
        }
      });
    }
    if ((page as PageControl).control != undefined) {
      this.compileControl((page as PageControl).control, state);
    }
  }

  private compileForm(form: Form, state: ModelState) {
    state.formFields =
      ModelFieldCompilerService.createServiceResultDefaultFields(false);
    form.layout.pages.forEach((page) => {
      this.compileFormPage(page, state);
    });

    /** Propritété dependante de l'entité graphql */
    if (form?.layout?.dependProperties != undefined) {
      form.layout.dependProperties.forEach((f) => {
        ModelFieldCompilerService.createField(
          f,
          null,
          (state.formFields[0] as GqlSubField).fields,
        );
      });
    }
  }

  public compile(modelState: ModelState): ModelState {
    let grid = modelState.model.grid;
    let form = modelState.model.form;

    if (grid != undefined) {
      this.compileLayout(grid.layout, modelState);
    }
    if (form != undefined) {
      this.compileForm(form, modelState);
      // champ licence nned pour edition orga
      // let licIndex = (modelState.formFields[0] as GqlSubField).fields.findIndex(
      //   (f) => f.name === 'license',
      // );
      // if (licIndex !== -1) {
      //   (modelState.formFields[0] as GqlSubField).fields.splice(licIndex, 1);
      // }
    }

    return modelState;
  }

  public static createElementFromHTML(htmlString): string {
    try {
      var div = document.createElement('div');
      div.innerHTML = htmlString.trim();

      if (div != undefined) {
        let hrefs = div.getElementsByTagName('a');
        if (hrefs != undefined && hrefs.length > 0) {
          for (let i = 0; i < hrefs.length; i++) {
            let hrefAttribute = hrefs[i]?.attributes['href'];
            if (
              hrefAttribute?.value != undefined &&
              hrefAttribute.value.indexOf('http') < 0
            ) {
              hrefs[i].href = 'https://' + hrefAttribute.value;
            }
          }

          htmlString = div.innerHTML;
        }
      }
      // Change this to div.childNodes to support multiple top-level nodes.
    } catch {
      console.log('Error parse html');
    }

    return htmlString;
  }

  public static convertToPlain(html) {
    // Create a new div element
    var tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.textContent || tempDivElement.innerText || '';
  }

  public static convertInnerHtml(html) {
    // Create a new div element
    var tempDivElement = document.createElement('div');

    // Set the HTML content with the given value
    tempDivElement.innerHTML = html;

    // Retrieve the text property of the element
    return tempDivElement.innerHTML;
  }
}
