import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTemplatesBaseVariables, FirstTemplatesBaseVariables, CountTemplatesBaseVariables, FindTemplatesBaseVariables, SearchTemplatesBaseVariables, ExportSchemaTemplatesBaseVariables, ExportDataTemplatesBaseVariables, CustomQueryTemplatesBaseVariables, CustomQueryIdTemplatesBaseVariables, UsedTemplatesBaseVariables, ExistTemplatesBaseVariables, FindRecyclesTemplatesBaseVariables, CountRecyclesTemplatesBaseVariables, ReadOnlyTemplatesBaseVariables, FindArchivedTemplatesBaseVariables, CountAllTemplatesBaseVariables, FindDynamicPropertyFieldsTemplatesBaseVariables, InsertTemplatesBaseVariables, UpdateTemplatesBaseVariables, ImportDataTemplatesBaseVariables, UpdateManyTemplatesBaseVariables, DeleteTemplatesBaseVariables, RestoreTemplatesBaseVariables, RecycleTemplatesBaseVariables, RestoreArchivedTemplatesBaseVariables, ArchivedTemplatesBaseVariables, AddFileDynamicFieldTemplatesBaseVariables, RemoveFileDynamicFieldTemplatesBaseVariables } from '../gqls'
import { GetTemplatesDocument, FirstTemplatesDocument, CountTemplatesDocument, FindTemplatesDocument, SearchTemplatesDocument, ExportSchemaTemplatesDocument, ExportDataTemplatesDocument, CustomQueryTemplatesDocument, CustomQueryIdTemplatesDocument, UsedTemplatesDocument, ExistTemplatesDocument, FindRecyclesTemplatesDocument, CountRecyclesTemplatesDocument, ReadOnlyTemplatesDocument, FindArchivedTemplatesDocument, CountAllTemplatesDocument, FindDynamicPropertyFieldsTemplatesDocument, InsertTemplatesDocument, UpdateTemplatesDocument, ImportDataTemplatesDocument, UpdateManyTemplatesDocument, DeleteTemplatesDocument, RestoreTemplatesDocument, RecycleTemplatesDocument, RestoreArchivedTemplatesDocument, ArchivedTemplatesDocument, AddFileDynamicFieldTemplatesDocument, RemoveFileDynamicFieldTemplatesDocument } from '../gqls'
import { ServiceSingleResultOfTemplate, QueryContextOfTemplate, FilterOfTemplate, ServiceSingleResultOfInt64, ServiceListResultOfTemplate, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTemplate, TemplateInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TemplateBaseService {
    
public modelName = 'template';
public modelPluralName = 'templates';

	private getTemplatesQuery: GetTemplatesDocument;
	private firstTemplatesQuery: FirstTemplatesDocument;
	private countTemplatesQuery: CountTemplatesDocument;
	private findTemplatesQuery: FindTemplatesDocument;
	private searchTemplatesQuery: SearchTemplatesDocument;
	private exportSchemaTemplatesQuery: ExportSchemaTemplatesDocument;
	private exportDataTemplatesQuery: ExportDataTemplatesDocument;
	private customQueryTemplatesQuery: CustomQueryTemplatesDocument;
	private customQueryIdTemplatesQuery: CustomQueryIdTemplatesDocument;
	private usedTemplatesQuery: UsedTemplatesDocument;
	private existTemplatesQuery: ExistTemplatesDocument;
	private findRecyclesTemplatesQuery: FindRecyclesTemplatesDocument;
	private countRecyclesTemplatesQuery: CountRecyclesTemplatesDocument;
	private readOnlyTemplatesQuery: ReadOnlyTemplatesDocument;
	private findArchivedTemplatesQuery: FindArchivedTemplatesDocument;
	private countAllTemplatesQuery: CountAllTemplatesDocument;
	private findDynamicPropertyFieldsTemplatesQuery: FindDynamicPropertyFieldsTemplatesDocument;
	private insertTemplatesMutation: InsertTemplatesDocument;
	private updateTemplatesMutation: UpdateTemplatesDocument;
	private importDataTemplatesMutation: ImportDataTemplatesDocument;
	private updateManyTemplatesMutation: UpdateManyTemplatesDocument;
	private deleteTemplatesMutation: DeleteTemplatesDocument;
	private restoreTemplatesMutation: RestoreTemplatesDocument;
	private recycleTemplatesMutation: RecycleTemplatesDocument;
	private restoreArchivedTemplatesMutation: RestoreArchivedTemplatesDocument;
	private archivedTemplatesMutation: ArchivedTemplatesDocument;
	private addFileDynamicFieldTemplatesMutation: AddFileDynamicFieldTemplatesDocument;
	private removeFileDynamicFieldTemplatesMutation: RemoveFileDynamicFieldTemplatesDocument;

	constructor(private injector: Injector) {
		this.getTemplatesQuery = this.injector.get(GetTemplatesDocument);
		this.firstTemplatesQuery = this.injector.get(FirstTemplatesDocument);
		this.countTemplatesQuery = this.injector.get(CountTemplatesDocument);
		this.findTemplatesQuery = this.injector.get(FindTemplatesDocument);
		this.searchTemplatesQuery = this.injector.get(SearchTemplatesDocument);
		this.exportSchemaTemplatesQuery = this.injector.get(ExportSchemaTemplatesDocument);
		this.exportDataTemplatesQuery = this.injector.get(ExportDataTemplatesDocument);
		this.customQueryTemplatesQuery = this.injector.get(CustomQueryTemplatesDocument);
		this.customQueryIdTemplatesQuery = this.injector.get(CustomQueryIdTemplatesDocument);
		this.usedTemplatesQuery = this.injector.get(UsedTemplatesDocument);
		this.existTemplatesQuery = this.injector.get(ExistTemplatesDocument);
		this.findRecyclesTemplatesQuery = this.injector.get(FindRecyclesTemplatesDocument);
		this.countRecyclesTemplatesQuery = this.injector.get(CountRecyclesTemplatesDocument);
		this.readOnlyTemplatesQuery = this.injector.get(ReadOnlyTemplatesDocument);
		this.findArchivedTemplatesQuery = this.injector.get(FindArchivedTemplatesDocument);
		this.countAllTemplatesQuery = this.injector.get(CountAllTemplatesDocument);
		this.findDynamicPropertyFieldsTemplatesQuery = this.injector.get(FindDynamicPropertyFieldsTemplatesDocument);
		this.insertTemplatesMutation = this.injector.get(InsertTemplatesDocument);
		this.updateTemplatesMutation = this.injector.get(UpdateTemplatesDocument);
		this.importDataTemplatesMutation = this.injector.get(ImportDataTemplatesDocument);
		this.updateManyTemplatesMutation = this.injector.get(UpdateManyTemplatesDocument);
		this.deleteTemplatesMutation = this.injector.get(DeleteTemplatesDocument);
		this.restoreTemplatesMutation = this.injector.get(RestoreTemplatesDocument);
		this.recycleTemplatesMutation = this.injector.get(RecycleTemplatesDocument);
		this.restoreArchivedTemplatesMutation = this.injector.get(RestoreArchivedTemplatesDocument);
		this.archivedTemplatesMutation = this.injector.get(ArchivedTemplatesDocument);
		this.addFileDynamicFieldTemplatesMutation = this.injector.get(AddFileDynamicFieldTemplatesDocument);
		this.removeFileDynamicFieldTemplatesMutation = this.injector.get(RemoveFileDynamicFieldTemplatesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTemplate> {
    
    		let variables: GetTemplatesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.get));
            }
            else{
                let result:ServiceSingleResultOfTemplate={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTemplate> {
    
    		let variables: FirstTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: FindTemplatesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTemplate,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: SearchTemplatesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTemplatesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: CustomQueryTemplatesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: CustomQueryIdTemplatesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTemplatesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: FindRecyclesTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTemplatesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTemplate,
		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTemplate> {
    
    		let variables: FindArchivedTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTemplatesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.templates.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TemplateInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTemplate> {
    
    		let variables: InsertTemplatesBaseVariables = {
    			entity: entity
    		}
    				return this.insertTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTemplate> {
    
    		let variables: UpdateTemplatesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTemplatesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTemplatesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTemplatesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTemplatesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.templates.removeFileDynamicField));
    	}
    
}