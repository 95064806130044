import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSnmpMapInformationKbsBaseVariables, FirstSnmpMapInformationKbsBaseVariables, CountSnmpMapInformationKbsBaseVariables, FindSnmpMapInformationKbsBaseVariables, SearchSnmpMapInformationKbsBaseVariables, ExportSchemaSnmpMapInformationKbsBaseVariables, ExportDataSnmpMapInformationKbsBaseVariables, CustomQuerySnmpMapInformationKbsBaseVariables, CustomQueryIdSnmpMapInformationKbsBaseVariables, UsedSnmpMapInformationKbsBaseVariables, ExistSnmpMapInformationKbsBaseVariables, InsertSnmpMapInformationKbsBaseVariables, UpdateSnmpMapInformationKbsBaseVariables, ImportDataSnmpMapInformationKbsBaseVariables, UpdateManySnmpMapInformationKbsBaseVariables, DeleteSnmpMapInformationKbsBaseVariables } from '../gqls'
import { GetSnmpMapInformationKbsDocument, FirstSnmpMapInformationKbsDocument, CountSnmpMapInformationKbsDocument, FindSnmpMapInformationKbsDocument, SearchSnmpMapInformationKbsDocument, ExportSchemaSnmpMapInformationKbsDocument, ExportDataSnmpMapInformationKbsDocument, CustomQuerySnmpMapInformationKbsDocument, CustomQueryIdSnmpMapInformationKbsDocument, UsedSnmpMapInformationKbsDocument, ExistSnmpMapInformationKbsDocument, InsertSnmpMapInformationKbsDocument, UpdateSnmpMapInformationKbsDocument, ImportDataSnmpMapInformationKbsDocument, UpdateManySnmpMapInformationKbsDocument, DeleteSnmpMapInformationKbsDocument } from '../gqls'
import { ServiceSingleResultOfSnmpMapInformationKb, QueryContextOfSnmpMapInformationKb, FilterOfSnmpMapInformationKb, ServiceSingleResultOfInt64, ServiceListResultOfSnmpMapInformationKb, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SnmpMapInformationKbInput, FieldUpdateOperatorOfSnmpMapInformationKb } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SnmpMapInformationKbBaseService {
    
public modelName = 'snmpMapInformationKb';
public modelPluralName = 'snmpMapInformationKbs';

	private getSnmpMapInformationKbsQuery: GetSnmpMapInformationKbsDocument;
	private firstSnmpMapInformationKbsQuery: FirstSnmpMapInformationKbsDocument;
	private countSnmpMapInformationKbsQuery: CountSnmpMapInformationKbsDocument;
	private findSnmpMapInformationKbsQuery: FindSnmpMapInformationKbsDocument;
	private searchSnmpMapInformationKbsQuery: SearchSnmpMapInformationKbsDocument;
	private exportSchemaSnmpMapInformationKbsQuery: ExportSchemaSnmpMapInformationKbsDocument;
	private exportDataSnmpMapInformationKbsQuery: ExportDataSnmpMapInformationKbsDocument;
	private customQuerySnmpMapInformationKbsQuery: CustomQuerySnmpMapInformationKbsDocument;
	private customQueryIdSnmpMapInformationKbsQuery: CustomQueryIdSnmpMapInformationKbsDocument;
	private usedSnmpMapInformationKbsQuery: UsedSnmpMapInformationKbsDocument;
	private existSnmpMapInformationKbsQuery: ExistSnmpMapInformationKbsDocument;
	private insertSnmpMapInformationKbsMutation: InsertSnmpMapInformationKbsDocument;
	private updateSnmpMapInformationKbsMutation: UpdateSnmpMapInformationKbsDocument;
	private importDataSnmpMapInformationKbsMutation: ImportDataSnmpMapInformationKbsDocument;
	private updateManySnmpMapInformationKbsMutation: UpdateManySnmpMapInformationKbsDocument;
	private deleteSnmpMapInformationKbsMutation: DeleteSnmpMapInformationKbsDocument;

	constructor(private injector: Injector) {
		this.getSnmpMapInformationKbsQuery = this.injector.get(GetSnmpMapInformationKbsDocument);
		this.firstSnmpMapInformationKbsQuery = this.injector.get(FirstSnmpMapInformationKbsDocument);
		this.countSnmpMapInformationKbsQuery = this.injector.get(CountSnmpMapInformationKbsDocument);
		this.findSnmpMapInformationKbsQuery = this.injector.get(FindSnmpMapInformationKbsDocument);
		this.searchSnmpMapInformationKbsQuery = this.injector.get(SearchSnmpMapInformationKbsDocument);
		this.exportSchemaSnmpMapInformationKbsQuery = this.injector.get(ExportSchemaSnmpMapInformationKbsDocument);
		this.exportDataSnmpMapInformationKbsQuery = this.injector.get(ExportDataSnmpMapInformationKbsDocument);
		this.customQuerySnmpMapInformationKbsQuery = this.injector.get(CustomQuerySnmpMapInformationKbsDocument);
		this.customQueryIdSnmpMapInformationKbsQuery = this.injector.get(CustomQueryIdSnmpMapInformationKbsDocument);
		this.usedSnmpMapInformationKbsQuery = this.injector.get(UsedSnmpMapInformationKbsDocument);
		this.existSnmpMapInformationKbsQuery = this.injector.get(ExistSnmpMapInformationKbsDocument);
		this.insertSnmpMapInformationKbsMutation = this.injector.get(InsertSnmpMapInformationKbsDocument);
		this.updateSnmpMapInformationKbsMutation = this.injector.get(UpdateSnmpMapInformationKbsDocument);
		this.importDataSnmpMapInformationKbsMutation = this.injector.get(ImportDataSnmpMapInformationKbsDocument);
		this.updateManySnmpMapInformationKbsMutation = this.injector.get(UpdateManySnmpMapInformationKbsDocument);
		this.deleteSnmpMapInformationKbsMutation = this.injector.get(DeleteSnmpMapInformationKbsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformationKb> {
    
    		let variables: GetSnmpMapInformationKbsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.get));
            }
            else{
                let result:ServiceSingleResultOfSnmpMapInformationKb={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformationKb,
		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformationKb> {
    
    		let variables: FirstSnmpMapInformationKbsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSnmpMapInformationKbsBaseVariables = {
    			filter: filter
    		}
    				return this.countSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformationKb,
		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformationKb> {
    
    		let variables: FindSnmpMapInformationKbsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSnmpMapInformationKb,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformationKb> {
    
    		let variables: SearchSnmpMapInformationKbsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSnmpMapInformationKbsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSnmpMapInformationKbsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSnmpMapInformationKb,
		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformationKb> {
    
    		let variables: CustomQuerySnmpMapInformationKbsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSnmpMapInformationKb,
		@Args('filter?') filter?: FilterOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformationKb> {
    
    		let variables: CustomQueryIdSnmpMapInformationKbsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSnmpMapInformationKbsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSnmpMapInformationKbsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSnmpMapInformationKbsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SnmpMapInformationKbInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformationKb> {
    
    		let variables: InsertSnmpMapInformationKbsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSnmpMapInformationKbsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformationKb> {
    
    		let variables: UpdateSnmpMapInformationKbsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSnmpMapInformationKbsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSnmpMapInformationKbsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSnmpMapInformationKbsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpMapInformationKb,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySnmpMapInformationKbsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySnmpMapInformationKbsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSnmpMapInformationKbsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSnmpMapInformationKbsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformationKbs.delete));
    	}
    
}