import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDesktopsBaseVariables, FirstDesktopsBaseVariables, CountDesktopsBaseVariables, FindDesktopsBaseVariables, SearchDesktopsBaseVariables, ExportSchemaDesktopsBaseVariables, ExportDataDesktopsBaseVariables, CustomQueryDesktopsBaseVariables, CustomQueryIdDesktopsBaseVariables, UsedDesktopsBaseVariables, ExistDesktopsBaseVariables } from '../gqls'
import { GetDesktopsDocument, FirstDesktopsDocument, CountDesktopsDocument, FindDesktopsDocument, SearchDesktopsDocument, ExportSchemaDesktopsDocument, ExportDataDesktopsDocument, CustomQueryDesktopsDocument, CustomQueryIdDesktopsDocument, UsedDesktopsDocument, ExistDesktopsDocument } from '../gqls'
import { ServiceSingleResultOfDesktop, QueryContextOfDesktop, FilterOfDesktop, ServiceSingleResultOfInt64, ServiceListResultOfDesktop, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DesktopBaseService {
    
public modelName = 'desktop';
public modelPluralName = 'desktops';

	private getDesktopsQuery: GetDesktopsDocument;
	private firstDesktopsQuery: FirstDesktopsDocument;
	private countDesktopsQuery: CountDesktopsDocument;
	private findDesktopsQuery: FindDesktopsDocument;
	private searchDesktopsQuery: SearchDesktopsDocument;
	private exportSchemaDesktopsQuery: ExportSchemaDesktopsDocument;
	private exportDataDesktopsQuery: ExportDataDesktopsDocument;
	private customQueryDesktopsQuery: CustomQueryDesktopsDocument;
	private customQueryIdDesktopsQuery: CustomQueryIdDesktopsDocument;
	private usedDesktopsQuery: UsedDesktopsDocument;
	private existDesktopsQuery: ExistDesktopsDocument;

	constructor(private injector: Injector) {
		this.getDesktopsQuery = this.injector.get(GetDesktopsDocument);
		this.firstDesktopsQuery = this.injector.get(FirstDesktopsDocument);
		this.countDesktopsQuery = this.injector.get(CountDesktopsDocument);
		this.findDesktopsQuery = this.injector.get(FindDesktopsDocument);
		this.searchDesktopsQuery = this.injector.get(SearchDesktopsDocument);
		this.exportSchemaDesktopsQuery = this.injector.get(ExportSchemaDesktopsDocument);
		this.exportDataDesktopsQuery = this.injector.get(ExportDataDesktopsDocument);
		this.customQueryDesktopsQuery = this.injector.get(CustomQueryDesktopsDocument);
		this.customQueryIdDesktopsQuery = this.injector.get(CustomQueryIdDesktopsDocument);
		this.usedDesktopsQuery = this.injector.get(UsedDesktopsDocument);
		this.existDesktopsQuery = this.injector.get(ExistDesktopsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDesktop> {
    
    		let variables: GetDesktopsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.get));
            }
            else{
                let result:ServiceSingleResultOfDesktop={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDesktop,
		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDesktop> {
    
    		let variables: FirstDesktopsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDesktopsBaseVariables = {
    			filter: filter
    		}
    				return this.countDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDesktop,
		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDesktop> {
    
    		let variables: FindDesktopsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDesktop,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDesktop> {
    
    		let variables: SearchDesktopsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDesktopsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDesktopsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDesktop,
		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDesktop> {
    
    		let variables: CustomQueryDesktopsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDesktop,
		@Args('filter?') filter?: FilterOfDesktop,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDesktop> {
    
    		let variables: CustomQueryIdDesktopsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDesktopsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDesktopsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDesktopsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.desktops.exist));
    	}
    
    
}