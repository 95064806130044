import {
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { GC, GCFactory } from '@clarilog/core';
import { confirm } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { TranslateService } from '../../../services';
import { ListComponentBase } from '../list/list.component-base';
import {
  ServiceSingleResultOfBoolean,
  ValidationError,
} from '@clarilog/core/services2/graphql/generated-types/types';

import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { CoreListComponent } from '../list/list.component';
import { CoreWorkItemsComponent } from '../work-items/work-items.component';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

export enum TrashMode {
  Trash = 'Trash',
  Archived = 'Archived',
  OutOfPark = 'OutOfPark',
}
@Component({
  selector: 'clc-trash-list',
  templateUrl: './trash-list.component.html',
})
export class CoreTrashListComponent
  extends ListComponentBase
  implements OnChanges, OnDestroy, OnInit
{
  constructor(
    private gcFactory: GCFactory,
    public localStorageService: LocalStorageService,
  ) {
    super();
    this.gc = gcFactory.create();
  }

  gc: GC;
  formTilte: string;
  errors: ValidationError[];
  /** Obtient ou définit les resources. */
  @Input() resources: any;
  /** Obtient ou définit les paramétres. */
  @Input() parameters: any;
  /** Obtient ou définit le model. */
  @Input() model: ModelState;
  /** Obtient ou définit le mode d'ouverture. */
  @Input() mode: TrashMode = TrashMode.Trash;

  /** Obtient ou définit le model. */
  @Input() title: string;
  /** Obtient ou définit le model. */
  @Input() restoreCaption: string = 'restore';
  /** Obtient ou définit le model. */
  @Input() restoreIcon: string = 'fas fa-trash-restore-alt';

  /** Obtient ou définit le composent List. */
  @ViewChild(CoreListComponent, { static: true })
  listComponent: CoreListComponent;
  /** Représente la fonction de restauration. */
  restoreFunction: ModelFnContext;
  /** Représente la fonction de suppression. */
  deleteFunction: ModelFnContext;

  /** Obtient ou définit si le wait est affiché  */
  deleteLoadIndicator = false;

  /**Permet ou définit l'etat du bouton restaure */
  waitRestore: boolean = false;

  /**Permet ou définit l'etat du bouton delete*/
  waitDelete: boolean = false;

  refreshCounter: boolean = false;

  /** Se déclenche avant la suppression. */
  @Input() beforeDeleted: (e: any) => Promise<boolean> = (e) => {
    return of(true).toPromise();
  };

  /** Se déclenche apres la restauration. */
  @Input() afterRestore: (e: any) => Promise<boolean> = (e) => {
    return of(true).toPromise();
  };

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.model.currentValue != undefined) {
      this.restoreFunction = this.model.model.grid.layout.restore as any;
      this.deleteFunction = this.model.model.grid.layout.delete as any;
    }
  }
  ngOnDestroy(): void {
    this.gc.dispose();
  }

  ngOnInit(): void {
    // Titre de la fenêtre
    if (this.mode == TrashMode.Archived) {
      this.formTilte = 'globals/archivedList';
    } else {
      this.mode = TrashMode.Trash;
      this.formTilte = 'globals/trashList';
    }

    if (this.title != undefined) {
      this.formTilte = this.title;
    }
    this.formTilte = TranslateService.get(this.formTilte, {
      title: this.model.model.title,
    });

    // Nom du bouton restauré
  }

  /** Obtient ou définit le composent WorkItems. */
  @ViewChild(CoreWorkItemsComponent, { static: true })
  WorkItemsComponent: CoreWorkItemsComponent;
  /** Permet de lancer la suppression des éléments */
  delete() {
    if (this.selectedKeys.length > 0) {
      let message = TranslateService.get('confirm-permanent-delete', {
        count: this.selectedKeys.length,
      });
      if (this.errors != undefined) {
        this.errors.clear();
      }
      //Vérification si l'item est utilisé en base de données
      let isUsed;
      let isUsedFunction: ModelFnContext;
      if (this.model.model.grid.layout.isUsed == undefined) {
        isUsed = of({ data: false });
      } else {
        isUsedFunction = this.model.model.grid.layout
          .isUsed as unknown as ModelFnContext;
        isUsedFunction.context.params.set('ids', () => this.selectedKeys);
        isUsed = isUsedFunction.fnCall();
      }

      this.gc.forDispose(
        isUsed.subscribe((isUsed) => {
          if (isUsedFunction != undefined) {
            isUsedFunction.context.params.remove('ids');
          }
          if (isUsed.data) {
            message =
              TranslateService.get('warningIsUsed', {
                count: this.selectedKeys.length,
              }) +
              '<br><br>' +
              message;
          }

          confirm(message, TranslateService.get('confirm-title')).then(
            (isOk) => {
              if (isOk) {
                let fncbeforeDeleted = this.beforeDeleted(this.selectedKeys);
                fncbeforeDeleted.then((s) => {
                  this.deleteLoadIndicator = true;
                  this.waitDelete = true;

                  this.deleteFunction.context.params.set(
                    'ids',
                    () => this.selectedKeys,
                  );

                  this.gc.forDispose(
                    this.deleteFunction
                      .fnCall()
                      .subscribe((result: ServiceSingleResultOfBoolean) => {
                        this.deleteFunction.context.params.remove('ids');
                        this.deleteLoadIndicator = false;
                        this.waitDelete = false;
                        if (!result.data) {
                          if (result.errors.length > 0) {
                            notify(
                              result.errors[0].messageError,
                              'error',
                              10000,
                            );
                          } else {
                            notify(
                              TranslateService.get('deletedError'),
                              'error',
                              10000,
                            );
                          }
                        } else {
                          notify(
                            TranslateService.get('deletedSuccess'),
                            'success',
                            4000,
                          );
                          if (
                            this.WorkItemsComponent != undefined &&
                            this.WorkItemsComponent.list.refresh != undefined
                          ) {
                            this.WorkItemsComponent.list.refresh();
                          }
                          this.selectedKeys = [];
                        }
                      }),
                  );
                });
              }
            },
          );
        }),
      );
    }
  }
  /** Permet de lancer la restauration les éléments */
  async restore() {
    if (this.mode == TrashMode.OutOfPark) {
      this.restoreArchived();
    } else if (this.mode == TrashMode.Trash) {
      await this.restoreItems();
    } else {
      throw new Error('Trow mode not implemented.');
    }
  }

  async restoreItems() {
    if (this.selectedKeys.length > 0) {
      let isOk = await confirm(
        TranslateService.get('confirm-restore', {
          count: this.selectedKeys.length,
        }),
        TranslateService.get('confirm-title'),
      ).then((result) => {
        return result;
      });
      if (this.errors != undefined) {
        this.errors.clear();
      }
      if (isOk) {
        this.deleteLoadIndicator = true;
        this.restoreFunction.context.params.set('ids', () => this.selectedKeys);

        this.restoreFunction
          .fnCall()
          .subscribe((result: ServiceSingleResultOfBoolean) => {
            this.restoreFunction.context.params.remove('ids');

            if (result?.errors != undefined && result.errors.length > 0) {
              // console.log(result.errors);
              // throw new Error('restore not implem error');

              let messageError = result.errors[0].messageError;
              let errorType = result.errors[0].validationCategory
                .replace(/simple/gi, '')
                .toLowerCase();

              this.errors = result.errors;
              this.errors.map((e) => {
                e.property = undefined;
              });

              notify(messageError, errorType, 5000);
            }

            // if (err.graphQLErrors.length > 0) {
            //   let lastErrorMessage = err.graphQLErrors[0].message.replace("Clarilog.One.GraphQL.Extensions.LicenseError: ", "");
            //   this.errors = [{ messageError: lastErrorMessage }];
            // }

            this.deleteLoadIndicator = false;
            // Si il y a un résultat
            if (result != undefined) {
              if (!result.data) {
                let errorMessage = TranslateService.get('restoreError');
                if (
                  (this.errors != null || this.errors != undefined) &&
                  this.errors.length != 0
                ) {
                  // A revoir
                  if (
                    this.restoreFunction.serviceName !=
                    'AssetCategoryCoreService'
                  )
                    notify(errorMessage, 'error', 10000);
                }
              } else {
                let fncAfterRestore = this.afterRestore(this.selectedKeys);
                fncAfterRestore.then((s) => {});
                notify(TranslateService.get('restoreSuccess'), 'success', 4000);
              }

              this.reloadNavMenuConst();

              if (
                this.WorkItemsComponent != undefined &&
                this.WorkItemsComponent.list.refresh != undefined
              ) {
                this.WorkItemsComponent.list.refresh();
              }

              this.selectedKeys = [];
            }
          });
      }
    }
  }

  /** Emit le pour indiquer un refresh des compteur d'un menu */
  reloadNavMenuConst() {
    if (this.localStorageService?.ModelState != undefined) {
      this.localStorageService.ModelState.on.emit({
        eventName: LocalStorageService.EventConst.ReloadNavMenuConst,
        eventData: undefined,
      });
    }
  }

  /** Remet en service un bien */
  restoreArchived() {
    confirm(
      TranslateService.get('entities/asset/backIntoServiceConfirm', {
        count: this.selectedKeys.length,
      }),
      TranslateService.get('confirm-title'),
    ).then((result) => {
      if (result) {
        this.deleteLoadIndicator = true;
        this.gc.forDispose(
          this.restoreFunction.fnCall().subscribe((result) => {
            if (result.errors?.length == 0) {
              notify(
                TranslateService.get('entities/asset/endBackIntoService'),
                'success',
                5000,
              );
            } else {
              notify(TranslateService.get('actionError'), 'error', 5000);
            }
            this.deleteLoadIndicator = false;

            if (
              this.WorkItemsComponent != undefined &&
              this.WorkItemsComponent.list.refresh != undefined
            ) {
              this.WorkItemsComponent.list.refresh();
            }

            this.selectedKeys = [];
          }),
        );
      }
    });
  }

  public refresh() {
    this.listComponent.refresh();
  }

  onBeforeRefresh(e) {
    if (this.refreshCounter) {
      this.reloadNavMenuConst();
    }
  }
}
