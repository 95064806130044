import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUserAccountsBaseVariables, FirstUserAccountsBaseVariables, CountUserAccountsBaseVariables, FindUserAccountsBaseVariables, SearchUserAccountsBaseVariables, ExportSchemaUserAccountsBaseVariables, ExportDataUserAccountsBaseVariables, CustomQueryUserAccountsBaseVariables, CustomQueryIdUserAccountsBaseVariables, UsedUserAccountsBaseVariables, ExistUserAccountsBaseVariables } from '../gqls'
import { GetUserAccountsDocument, FirstUserAccountsDocument, CountUserAccountsDocument, FindUserAccountsDocument, SearchUserAccountsDocument, ExportSchemaUserAccountsDocument, ExportDataUserAccountsDocument, CustomQueryUserAccountsDocument, CustomQueryIdUserAccountsDocument, UsedUserAccountsDocument, ExistUserAccountsDocument } from '../gqls'
import { ServiceSingleResultOfUserAccount, QueryContextOfUserAccount, FilterOfUserAccount, ServiceSingleResultOfInt64, ServiceListResultOfUserAccount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UserAccountBaseService {
    
public modelName = 'userAccount';
public modelPluralName = 'userAccounts';

	private getUserAccountsQuery: GetUserAccountsDocument;
	private firstUserAccountsQuery: FirstUserAccountsDocument;
	private countUserAccountsQuery: CountUserAccountsDocument;
	private findUserAccountsQuery: FindUserAccountsDocument;
	private searchUserAccountsQuery: SearchUserAccountsDocument;
	private exportSchemaUserAccountsQuery: ExportSchemaUserAccountsDocument;
	private exportDataUserAccountsQuery: ExportDataUserAccountsDocument;
	private customQueryUserAccountsQuery: CustomQueryUserAccountsDocument;
	private customQueryIdUserAccountsQuery: CustomQueryIdUserAccountsDocument;
	private usedUserAccountsQuery: UsedUserAccountsDocument;
	private existUserAccountsQuery: ExistUserAccountsDocument;

	constructor(private injector: Injector) {
		this.getUserAccountsQuery = this.injector.get(GetUserAccountsDocument);
		this.firstUserAccountsQuery = this.injector.get(FirstUserAccountsDocument);
		this.countUserAccountsQuery = this.injector.get(CountUserAccountsDocument);
		this.findUserAccountsQuery = this.injector.get(FindUserAccountsDocument);
		this.searchUserAccountsQuery = this.injector.get(SearchUserAccountsDocument);
		this.exportSchemaUserAccountsQuery = this.injector.get(ExportSchemaUserAccountsDocument);
		this.exportDataUserAccountsQuery = this.injector.get(ExportDataUserAccountsDocument);
		this.customQueryUserAccountsQuery = this.injector.get(CustomQueryUserAccountsDocument);
		this.customQueryIdUserAccountsQuery = this.injector.get(CustomQueryIdUserAccountsDocument);
		this.usedUserAccountsQuery = this.injector.get(UsedUserAccountsDocument);
		this.existUserAccountsQuery = this.injector.get(ExistUserAccountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAccount> {
    
    		let variables: GetUserAccountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.get));
            }
            else{
                let result:ServiceSingleResultOfUserAccount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserAccount,
		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserAccount> {
    
    		let variables: FirstUserAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUserAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserAccount,
		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAccount> {
    
    		let variables: FindUserAccountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUserAccount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAccount> {
    
    		let variables: SearchUserAccountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUserAccountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUserAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUserAccount,
		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAccount> {
    
    		let variables: CustomQueryUserAccountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUserAccount,
		@Args('filter?') filter?: FilterOfUserAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserAccount> {
    
    		let variables: CustomQueryIdUserAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUserAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUserAccountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUserAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userAccounts.exist));
    	}
    
    
}