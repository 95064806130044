import { Component, HostBinding, Input } from '@angular/core';

/** Permet de choisir l'emplacement de la section
 * before : en premier
 * center : au milieu
 * after : en dernier
 */
export type WorkItemSectionLocation = 'before' | 'after' | 'center' | 'full';

/** Représente une section d'un formulaire. */
@Component({
  selector: 'cl-work-item-section',
  templateUrl: './work-item-section.component.html',
  styleUrls: ['./work-item-section.component.scss']
})
export class WorkItemSectionComponent {
  /** Représente l'emplacement. */
  @Input() location: WorkItemSectionLocation = 'before';
  /** Obtient la classe css du composant par rapport à la location. */
  @HostBinding('class')
  public get getCssClasses(): any {
    return this.location;
  }
  constructor() { }
}
