import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validator,
} from '@angular/forms';
import { CoreGraphQLSource, CriteriaHelpers } from '@clarilog/core/services2';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  QueryContextOfUser,
  Sort,
  StatusConfig,
  StatusEdge,
  StatusMoveRight,
  StatusNode,
} from '@clarilog/core/services2/graphql/generated-types/types';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { DxDiagramComponent } from 'devextreme-angular';
import DataSource from 'devextreme/data/data_source';
import { confirm } from 'devextreme/ui/dialog';
import model from './model.json';
import { v4 as uuidv4 } from 'uuid';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import {
  TicketCoreService,
  TicketStatusCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { TranslateService } from '@clarilog/shared2/services';
import { TranslatedFieldHelperService } from '../translate-field';
import { TemplatesService } from '../templates/templates.service';
import ArrayStore from 'devextreme/data/array_store';
import { GC, GCFactory } from '@clarilog/core';

/** Définit les mode d'affichage */
export enum DiagramDisplayMode {
  Grid = 'Grid',
  Diagram = 'Diagram',
}

/** Definit les options d'affichage  */
export class LogicDiagramView {
  type: string;
  isTakingCharge: boolean;
  isEndTreatment: boolean;
  isCommentaryRequired: boolean;
  allTaskFinished: boolean;
  canAddSatisfaction: boolean;
  isForbiddenPredecessor: boolean;
  referentTeam: boolean;
  operatorReferent: boolean;
  allConcerned: boolean;
  makeRequest: boolean;
  affected: boolean;
  manager: boolean;
  allOperator: boolean;
  affectedTeam: boolean;
  operatorAffected: boolean;
  isEnding: boolean;
}

/** Représente les données de la grille */
export class GridDiagramSource {
  to: string[];
  status: string;
  positions: string[];
  edit: any;
  isOperator: boolean;
  isUser: boolean;
  isManage: boolean;
  isUsers: boolean;
}

@Component({
  selector: 'cl-logic-diagram',
  templateUrl: './logic-diagram.component.html',
  styleUrls: ['./logic-diagram.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LogicDiagramComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => LogicDiagramComponent),
      multi: true,
    },
  ],
})
export class LogicDiagramComponent
  implements OnInit, AfterViewInit, OnDestroy, ControlValueAccessor, Validator
{
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  @ViewChild(DxDiagramComponent) dxDiagram: DxDiagramComponent;

  @Input() state: ModelState;
  @Input() fieldName: string;
  @Input() newKey: string = 'new';
  @Input() closedKey: string = 'closed';
  @Input() needRight: boolean = false;
  @Input() useQualification: boolean = true;
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;

  @Input() displaySource: ModelFnContext;

  isPopupOpen: boolean = false;
  rawModel: any;
  isValid: boolean = true;

  flowNodesDataSource: ArrayStore;
  flowEdgesDataSource: ArrayStore;
  _data: StatusNode[] = [];
  _dataEdge: StatusEdge[] = [];
  statusDatasource: ModelDataSourceContext;
  currentEdit: StatusNode = undefined;
  objId: string = null;
  init: boolean = false;
  qualification: string[];
  initStatusList: boolean = false;
  userDatasource: ModelDataSourceContext;
  checkboxForbiddenState: boolean = false;

  /** Obtient ou définit le mode affichage */
  displayMode: DiagramDisplayMode = DiagramDisplayMode.Diagram;
  /** Indique si le bouton auto  */
  autoSelect: boolean = true;
  /** Datasource de la grille */
  gridDataSource: GridDiagramSource[];

  switchOptions: any;
  autoSelectOptions: any;

  /** Représentes les statut */
  allStatuses: any;
  @Input() serviceRetriver: ModelFnContext;

  statusService: any;

  WORKFLOW_TASK: string = 'workflow-task';

  logicDiagramView: LogicDiagramView;
  _gc: GC;

  constructor(
    public cd: ChangeDetectorRef,
    public userService: UserCoreService,
    public statusSvc: TicketStatusCoreService,
    public ticketCoreService: TicketCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    public templateService: TemplatesService,
    gcFactory: GCFactory,
  ) {
    this._gc = gcFactory.create();

    // Display par defaut si pas de methode
    this.logicDiagramView = {
      type: 'ticket',
      isEnding: true,
      allOperator: true,
      affectedTeam: true,
      operatorAffected: true,
      isTakingCharge: true,
      isEndTreatment: true,
      isCommentaryRequired: true,
      allTaskFinished: true,
      canAddSatisfaction: true,
      isForbiddenPredecessor: false,
      referentTeam: true,
      operatorReferent: true,
      allConcerned: true,
      makeRequest: true,
      affected: true,
      manager: true,
    };

    this.autoSelectOptions = {
      icon: 'detailslayout',
      text: TranslateService.get('globals/autoSelect').toLocaleUpperCase(),
      value: this.autoSelect,
      onValueChanged: (e) => {
        this.autoSelect = e.component.option('value');
      },
    };
    this.switchOptions = {
      icon: 'detailslayout',
      text: TranslateService.get('globals/viewGrid'),
      onClick: (e) => {
        if (this.displayMode == DiagramDisplayMode.Diagram) {
          this.displayMode = DiagramDisplayMode.Grid;
        } else {
          this.displayMode = DiagramDisplayMode.Diagram;
        }

        e.component.option(
          'text',
          this.displayMode == DiagramDisplayMode.Grid
            ? TranslateService.get('globals/viewDiagram')
            : TranslateService.get('globals/viewGrid'),
        );
        e.component.option(
          'icon',
          this.displayMode == DiagramDisplayMode.Grid
            ? 'hierarchy'
            : 'detailslayout',
        );

        this.refreshDataGrid();
      },
    };

    this.rawModel = model;
    this.beforeSave = this.beforeSave.bind(this);

    this.statusDatasource = new ModelDataSourceContext({
      serviceName: 'alarmService',
      methodName: 'find',
    });

    this.statusDatasource.datasource = CoreGraphQLSource.create({
      context: this.statusDatasource,
      query: (filter?: any, options?: any) => {
        let myFilter =
          filter != undefined
            ? CriteriaHelpers.convertDxFilter(CriteriaHelpers.decompile(filter))
            : {};
        this.qualification = this.getQualification();
        if (this.useQualification == true) {
          myFilter.qualification = { elemMatch: { in: this.qualification } };
        }
        this.initStatusList = true;
        return this.statusService.find(
          [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlSubField.create(
                'name',
                this.translatedFieldHelperService.translatedFields(),
              ),
              GqlField.create('key'),
            ]),
          ],
          options,
          myFilter,
        );
      },
    });
    this.statusDatasource.datasource.sort([
      {
        selector:
          'name' + '.' + this.translatedFieldHelperService.getTranslateKey(),
        desc: false,
      },
    ]);
  }
  ngOnDestroy(): void {
    this._gc.dispose();
  }

  getQualification() {
    let qualificationField = FormGroupHelpers.formControlByName(
      this.state.form,
      'qualification',
    );

    if (qualificationField != null) {
      return qualificationField.value;
    } else {
      return this.ticketCoreService.getTicketType().map((s) => s.id);
    }
  }

  /** import du json */
  import() {
    let json = this.toJson();
    if (json != null) {
      this.dxDiagram.instance.import(json);
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      // Création du diagram
      this.import();
    }, 500);
  }

  /** Mise à jour des data */
  updateValues() {
    // Mise à jour des node
    let json = JSON.parse(this.dxDiagram.instance.export());
    // Gestion des noeud
    if (json != undefined && json.shapes != undefined) {
      json.shapes.forEach((el) => {
        let item = this._data.find((x) => x.objId === el.dataKey);
        if (item != undefined) {
          item.x = el.x;
          item.y = el.y;
        } else {
          let newNode: StatusNode = {
            name: '',
            statusId: '',
            objId: uuidv4(),
            isEntry: false,
            isEnding: false,
            isEndTreatment: false,
            isTakingCharge: false,
            isCommentaryRequired: false,
            canAddSatisfaction: false,
            isForbiddenPredecessor: false,
            rightToMove: this.defaultRight(),
            type: 'status',
            x: el.x,
            y: el.y,
          };
          el.dataKey = newNode.objId;

          this._data.push(newNode);

          this.flowEdgesDataSource.clear();
        }
      });

      // Suppression des inconnu
      this._data = this._data.filter(
        (f) => json.shapes.filter((sf) => f.objId === sf.dataKey).length > 0,
      );
    }

    // Gestion des connecteur
    if (json != undefined && json.connectors != undefined) {
      json.connectors.forEach((el) => {
        let item = this._dataEdge.find((x) => x.objId === el.dataKey);
        if (item != undefined) {
          // Update
          item.points = el.points;
          item.fromId = json.shapes.find(
            (s) => s.key == el.beginItemKey,
          )?.dataKey;
          item.toId = json.shapes.find((s) => s.key == el.endItemKey)?.dataKey;
        } else {
          // Creation
          let newVal: StatusEdge = {
            fromId: json.shapes.find((s) => s.key == el.beginItemKey)?.dataKey,
            toId: json.shapes.find((s) => s.key == el.endItemKey)?.dataKey,
            objId: el.dataKey,
            title: '',
            fromPointIndex: el.beginConnectionPointIndex,
            toPointIndex: el.endConnectionPointIndex,
            points: el.points,
          };

          this._dataEdge.push(newVal);
        }
      });

      // Suppression des inconnu
      this._dataEdge = this._dataEdge.filter(
        (f) =>
          json.connectors.filter((sf) => f.objId === sf.dataKey).length > 0,
      );
    }

    let value: StatusConfig = {
      nodes: this._data.map((x) => {
        delete x['__typename'];
        return x;
      }),
      edges: this._dataEdge.map((x) => {
        delete x['__typename'];
        return x;
      }),
    };

    this.onChange(value);
    this.onTouched();
  }

  errors: any;

  toJson(): string {
    //devextreme
    // dataKey == objId
    let index = 0;

    let invalid: boolean;

    let json = {
      page: {
        width: 8391,
        height: 11906,
        pageColor: -1,
        pageWidth: 8391,
        pageHeight: 11906,
        pageLandscape: false,
      },
      connectors: [],
      shapes: [],
    };

    this._data.forEach((el) => {
      json.shapes.push({
        key: index.toString(),
        dataKey: el.objId,
        x: el.x,
        y: el.y,
        type: el.type,
        width: 2160,
        height: 1440,
      });
      index++;
    });

    this._dataEdge.forEach((el) => {
      el.points = el.points.map((x) => {
        delete x['__typename'];
        return x;
      });
      if (el.points.length === 0) {
        invalid = true;
      }

      if (json.shapes.find((x) => x.dataKey === el.toId)?.key != undefined) {
        json.connectors.push({
          key: index.toString(),
          dataKey: el.objId,
          points: el.points,
          beginItemKey: json.shapes.find((x) => x.dataKey === el.fromId).key,
          endItemKey: json.shapes.find((x) => x.dataKey === el.toId).key,
          endConnectionPointIndex: el.toPointIndex,
          beginConnectionPointIndex: el.fromPointIndex,
        });
        index++;
      }
    });

    if (invalid === true) {
      return null;
    }

    return JSON.stringify(json);
  }

  /** Vérification de la validation des connectors */
  checkEdgeValidity() {
    let result: boolean = true;
    let errors = {};

    this._dataEdge.forEach((el) => {
      if (el.toId == undefined || el.fromId == undefined) {
        result = false;
        errors['edgeNotValid'] = true;
      }
    });

    let withoutStatus = this._data.filter(
      (s) => s.statusId == '' || s.statusId == undefined,
    ).length;
    if (withoutStatus > 0) {
      errors['withoutStatus'] = true;
    }

    this._data.forEach((el) => {
      let foundIndex = this._dataEdge.findIndex(
        (x) =>
          x.toId === el.objId.toString() || x.fromId === el.objId.toString(),
      );

      if (foundIndex == -1) {
        errors['nodeNotLinked'] = true;

        result = false;
      }

      let nodeWithOnlyOutEdges = this._dataEdge.findIndex(
        (x) => x.fromId === el.objId.toString(),
      );

      if (nodeWithOnlyOutEdges == -1 && el.isEnding === false) {
        errors['nodeBlockedState'] = true;

        result = false;
      }

      if (el.isEnding === true) {
        if (
          this._dataEdge.findIndex((x) => x.fromId === el.objId.toString()) !==
          -1
        ) {
          errors['outFromEnding'] = true;
          result = false;
        }
      }

      let foundDuplicate = this._data.filter(
        (x) => x.objId != el.objId && x.statusId === el.statusId,
      );

      if (foundDuplicate.length > 0) {
        errors['hasDuplicate'] = true;
        result = false;
      }
    });

    if (this._data.findIndex((x) => x.isEntry === true) == -1) {
      result = false;
      errors['noEntry'] = true;
      //pas de point d'entrée
    }

    if (this._data.findIndex((x) => x.isEnding === true) == -1) {
      result = false;
      errors['noEnding'] = true;
      //pas de point d'entrée
    }

    if (this._data.length <= 1) {
      result = false;
      errors['noNode'] = true;
    }

    this.isValid = result;
    if (result === false) {
      return errors;
    }
    return null;
  }

  defaultRight(): StatusMoveRight {
    return {
      allOperator: true,
      allConcerned: false,
      affected: false,
      affectedTeam: false,
      makeRequest: false,
      manager: false,
      referentTeam: false,
      customUserIds: [],
      operatorAffected: false,
      operatorReferent: false,
      allTaskFinished: false,
    };
  }

  lastWriteValues: any = {};

  async initEmpty() {
    let newStatus = await this.statusService
      .find(
        [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlSubField.create('name', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlField.create('key'),
          ]),
        ],
        null,
        { key: { eq: this.newKey } },
      )
      .toPromise();
    let closedStatus = await this.statusService
      .find(
        [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlSubField.create('name', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlField.create('key'),
          ]),
        ],
        null,
        { key: { eq: this.closedKey } },
      )
      .toPromise();

    this._data.push({
      name: this.translatedFieldHelperService.findValueToShow(
        newStatus.data[0].name,
      ),
      statusId: newStatus.data[0].id,
      objId: uuidv4(),
      isEntry: true,
      isEnding: false,
      isEndTreatment: false,
      isTakingCharge: false,
      isCommentaryRequired: false,
      canAddSatisfaction: false,
      isForbiddenPredecessor: false,
      rightToMove: this.defaultRight(),
      x: 2700,
      y: 560,
      type: 'status',
    });

    this._data.push({
      name: this.translatedFieldHelperService.findValueToShow(
        closedStatus.data[0].name,
      ),
      statusId: closedStatus.data[0].id,
      objId: uuidv4(),
      isEntry: false,
      isEnding: true,
      isEndTreatment: false,
      isTakingCharge: false,
      isCommentaryRequired: false,
      canAddSatisfaction: false,
      isForbiddenPredecessor: false,
      rightToMove: this.defaultRight(),
      type: 'status',
      x: 2700,
      y: 3400,
    });
  }

  async writeValue(obj: any) {
    let control = FormGroupHelpers.formControlByName(
      this.state.form,
      this.fieldName,
    );

    this.allStatuses = await this.statusService
      .find([
        GqlSubField.create('data', [
          GqlField.create('id'),
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('key'),
        ]),
      ])
      .toPromise();

    if (obj != undefined) {
      let tmp = JSON.stringify(this.lastWriteValues);

      this.lastWriteValues = obj;

      if (tmp != JSON.stringify(obj)) {
        control.reset(obj, { emitEvent: false });
      }

      this.init = true;
      if (
        obj.edges != undefined &&
        Array.isArray(obj.edges) &&
        (this._dataEdge == undefined || this._dataEdge.length == 0)
      ) {
        this._dataEdge = obj.edges.map((x) => {
          x.points = x.points.map((y) => {
            delete y['__typename'];
            return y;
          });
          delete x['__typename'];
          return x;
        });
      }
      if (
        obj.nodes != undefined &&
        Array.isArray(obj.nodes) &&
        (this._data == undefined || this._data.length == 0)
      ) {
        this._data = obj.nodes.map((x) => {
          delete x['__typename'];
          if (x.rightToMove == undefined) {
            x.rightToMove = this.defaultRight();
          }
          if (x.isEndTreatment == undefined) {
            x.isEndTreatment = false;
          }

          if (x.isTakingCharge == undefined) {
            x.isTakingCharge = false;
          }

          //traduction
          x.name = this.retriveStatusName(x.statusId);
          return x;
        });
      }
    } else {
      // Nouveau
      await this.initEmpty();
    }

    // Uniquement la 1er fois
    if (this.flowNodesDataSource == undefined) {
      this.flowNodesDataSource = new ArrayStore({
        key: 'objId',
        data: this._data,
      });

      this.flowEdgesDataSource = new ArrayStore({
        key: 'objId',
        data: this._dataEdge,
      });
    }

    this.errors = this.checkEdgeValidity();
  }

  /** Recherche de la traduction */
  retriveStatusName(id) {
    if (this.allStatuses != undefined) {
      return this.translatedFieldHelperService.findValueToShow(
        this.allStatuses.data.find((x) => x.id === id)?.name,
      );
    }
    return undefined;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /**
   * Le passage d'Angular 14 à 15 a amené un breaking changes pour @angular/forms
   * L'attribut [attr.disabled] ne fonctionne plus comme avant.
   * setDisabledState est appelé quand ControlValueAccessor est rattaché au composant.
   * Liens : https://github.com/angular/angular/releases/tag/15.0.0
   * https://stackoverflow.com/questions/76041158/attr-disabled-true-not-working-in-reactive-form-angular-15
   **/
  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  initUserDatasource() {
    let fields: GqlSubField[] = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
        GqlField.create('email'),
      ]),
    ];
    let options: QueryContextOfUser = {
      sort: [{ name: Sort.Asc }],
    };
    this.userDatasource = new ModelDataSourceContext({
      serviceName: 'alarmService',
      methodName: 'findAlarmWithNotViewedAlert',
    });

    if (this.logicDiagramView.type === this.WORKFLOW_TASK) {
      this._gc.forDispose(
        this.userService.findOperatorUser(fields, options).subscribe((res) => {
          this.userDatasource.datasource = new DataSource(res.data);
        }),
      );
    } else {
      this._gc.forDispose(
        this.userService
          .findOperatorUserHelpDesk(fields, options)
          .subscribe((res) => {
            this.userDatasource.datasource = new DataSource(res.data);
          }),
      );
    }
  }

  ngOnInit() {
    if (
      this.displaySource != undefined &&
      (this.displaySource as ModelFnContext)
    ) {
      let display = (this.displaySource as ModelFnContext).fnCall();
      if (display != undefined && ((<any>display) as LogicDiagramView)) {
        this.logicDiagramView = (<any>display) as LogicDiagramView;
      }
    }

    this.statusService = this.serviceRetriver.fnCall();
    this.initUserDatasource();
  }

  edit(e) {
    let data = this._data.find((s) => s.objId == e.objId);
    if (data == undefined) {
      data = {
        name: '',
        statusId: '',
        objId: e.objId,
        isEntry: false,
        isEnding: false,
        isEndTreatment: false,
        isTakingCharge: false,
        isCommentaryRequired: false,
        canAddSatisfaction: false,
        isForbiddenPredecessor: false,
        rightToMove: this.defaultRight(),
        type: 'status',
        x: 0,
        y: 0,
      };

      this._data.push(data);
    }
    this.currentEdit = data;
    if (
      this.logicDiagramView.type == this.WORKFLOW_TASK &&
      this.currentEdit.isEnding == true
    ) {
      this.checkboxForbiddenState = true;
    }
    // this.objId = this.currentEdit.statusId;
    this.isPopupOpen = true;
  }
  remove(objId) {
    if (objId != undefined) {
      // NEttoyge datasource
      // this._data = this._data.filter((x) => x.objId !== objId);
      // this._dataEdge = this._dataEdge.filter(
      //   (x) => !(x.fromId === objId || x.toId === objId),
      // );

      // Nettoyage du diagram
      let json = JSON.parse(this.dxDiagram.instance.export());
      if (json?.shapes != undefined) {
        json.shapes = json.shapes.filter((x) => x.dataKey !== objId);

        this.dxDiagram.instance.import(JSON.stringify(json));
        this.updateValues();
      }
    }
  }

  saved(e) {}

  selectBoxValueChanged(e) {
    if (e.value !== this.currentEdit.statusId) {
      this.currentEdit.statusId = e.value;
    }
  }

  async checkBoxValueChangedEndTreament(e) {
    let res = true;

    let nodeWithIsEntryTrue = this._data.filter(
      (x) => x.isEndTreatment === true,
    );
    // Gestion multi end treatment
    // if (
    //   e.value === true &&
    //   nodeWithIsEntryTrue.findIndex(
    //     (x) => x.objId !== this.currentEdit.objId,
    //   ) >= 0
    // ) {
    //   res = await confirm(
    //     TranslateService.get(
    //       'entities/status-workflow/error/oneIsEndTreatment',
    //     ),
    //     TranslateService.get('globals/warning'),
    //   );
    // }
    if (
      e.value === false &&
      nodeWithIsEntryTrue.findIndex(
        (x) => x.objId === this.currentEdit.objId,
      ) >= 0
    ) {
      res = await confirm(
        TranslateService.get(
          'entities/status-workflow/error/oneIsnoMoreEndTreament',
        ),
        TranslateService.get('globals/warning'),
      );
    }

    if (res === true && e.value !== this.currentEdit.isEndTreatment) {
      this.currentEdit.isEndTreatment = e.value;
    } else {
      e.value = this.currentEdit.isEndTreatment;
    }
  }
  async checkBoxValueChangedTakeCharge(e) {
    let res = true;

    let nodeWithIsEntryTrue = this._data.filter(
      (x) => x.isTakingCharge === true,
    );
    // Gestion Multi take in charge
    // if (
    //   e.value === true &&
    //   nodeWithIsEntryTrue.findIndex(
    //     (x) => x.objId !== this.currentEdit.objId,
    //   ) >= 0
    // ) {
    //   res = await confirm(
    //     TranslateService.get(
    //       'entities/status-workflow/error/oneIsTakingCharge',
    //     ),
    //     TranslateService.get('globals/warning'),
    //   );
    // }
    if (
      e.value === false &&
      nodeWithIsEntryTrue.findIndex(
        (x) => x.objId === this.currentEdit.objId,
      ) >= 0
    ) {
      res = await confirm(
        TranslateService.get(
          'entities/status-workflow/error/oneIsnoMoreTakingCharge',
        ),
        TranslateService.get('globals/warning'),
      );
    }

    if (res === true && e.value !== this.currentEdit.isTakingCharge) {
      this.currentEdit.isTakingCharge = e.value;
    } else {
      e.value = this.currentEdit.isTakingCharge;
    }
  }
  async checkBoxValueChanged(e) {
    let res = true;

    let nodeWithIsEntryTrue = this._data.filter((x) => x.isEntry === true);

    if (
      e.value === true &&
      nodeWithIsEntryTrue.findIndex(
        (x) => x.objId !== this.currentEdit.objId,
      ) >= 0
    ) {
      res = await confirm(
        TranslateService.get('entities/status-workflow/error/oneIsEntry'),
        TranslateService.get('globals/warning'),
      );
    }
    if (
      e.value === false &&
      nodeWithIsEntryTrue.findIndex(
        (x) => x.objId === this.currentEdit.objId,
      ) >= 0
    ) {
      res = await confirm(
        TranslateService.get('entities/status-workflow/error/oneIsnoMoreEntry'),
        TranslateService.get('globals/warning'),
      );
    }

    if (res === true && e.value !== this.currentEdit.isEntry) {
      this.currentEdit.isEntry = e.value;
    } else {
      e.value = this.currentEdit.isEntry;
    }
  }

  async checkBoxValueChanged2(e) {
    let res = true;

    if (this.logicDiagramView.type == this.WORKFLOW_TASK) {
      this.checkboxForbiddenState = e.value;
      this.currentEdit.isForbiddenPredecessor = e.value;
    }

    let nodeWithIsEndingTrue = this._data.filter((x) => x.isEnding === true);

    // Gestion multi ending
    // if (
    //   e.value === true &&
    //   nodeWithIsEndingTrue.findIndex(
    //     (x) => x.objId !== this.currentEdit.objId,
    //   ) >= 0
    // ) {
    //   res = await confirm(
    //     TranslateService.get('entities/status-workflow/error/oneIsEnding'),
    //     TranslateService.get('globals/warning'),
    //   );
    // }
    // if (
    //   e.value === false &&
    //   nodeWithIsEndingTrue.findIndex(
    //     (x) => x.objId === this.currentEdit.objId,
    //   ) >= 0
    // ) {
    //   res = await confirm(
    //     TranslateService.get(
    //       'entities/status-workflow/error/oneIsnoMoreEnding',
    //     ),
    //     TranslateService.get('globals/warning'),
    //   );
    // }
    this.currentEdit.isEnding = e.value;

    // if (res === true && e.value !== this.currentEdit.isEntry) {
    //   this.currentEdit.isEnding = e.value;
    // } else {
    //   e.value = this.currentEdit.isEnding;
    // }
  }

  async checkBoxValueChangedCommentaryRequired(e) {
    let res = true;

    let nodeWithIsEndingTrue = this._data.filter((x) => x.isEnding === true);
    this.currentEdit.isCommentaryRequired = e.value;
  }

  onComboboxInit(e) {}

  /** Vaidation */
  validate(value: UntypedFormControl) {
    if (value.dirty === true) {
      this.errors = this.checkEdgeValidity();
      return this.errors;
    }
    return null;
  }

  public getDisplayExpr() {
    return this.translatedFieldHelperService.setColumnTranslateField('name');
  }

  /** Mise à jour d'un noeud */
  async update() {
    let index = this._data.findIndex((x) => x.objId === this.currentEdit.objId);

    this._data.forEach((el) => {
      if (
        el.isEntry === true &&
        el.objId !== this.currentEdit.objId &&
        this.currentEdit.isEntry === true
      ) {
        el.isEntry = false;
      }
      // if (
      //   el.isEnding === true &&
      //   el.objId !== this.currentEdit.objId &&
      //   this.currentEdit.isEnding === true
      // ) {
      //   el.isEnding = false;
      // }
      // if (
      //   el.isEndTreatment === true &&
      //   el.objId !== this.currentEdit.objId &&
      //   this.currentEdit.isEndTreatment === true
      // ) {
      //   el.isEndTreatment = false;
      // }
      // if (
      //   el.isTakingCharge === true &&
      //   el.objId !== this.currentEdit.objId &&
      //   this.currentEdit.isTakingCharge === true
      // ) {
      //   el.isTakingCharge = false;
      // }
    });

    this._data[index].statusId = this.currentEdit.statusId;
    this._data[index].name = this.retriveStatusName(this.currentEdit.statusId);

    this._data[index].isEntry = this.currentEdit.isEntry;
    this._data[index].isEnding = this.currentEdit.isEnding;
    this._data[index].isEndTreatment = this.currentEdit.isEndTreatment;
    this._data[index].isTakingCharge = this.currentEdit.isTakingCharge;
    this._data[index].canAddSatisfaction = this.currentEdit.canAddSatisfaction;
    this._data[index].isCommentaryRequired =
      this.currentEdit.isCommentaryRequired;
    this._data[index].rightToMove = this.currentEdit.rightToMove;
    this._data[index].isForbiddenPredecessor =
      this.currentEdit.isForbiddenPredecessor;

    if (
      this._data[index].isEntry === true &&
      this._data[index].isEnding === true
    ) {
      this._data[index].isEnding = false;
    }

    this.currentEdit = undefined;
    this.isPopupOpen = false;
    this.updateValues();
    this.refreshDataGrid();
  }

  allConcernedChanged(e) {
    if (this.currentEdit.rightToMove.allConcerned === true) {
      this.currentEdit.rightToMove.makeRequest = true;
      this.currentEdit.rightToMove.affected = true;
    }
  }
  requestLayoutUpdateHandler(e) {
    if (e?.changes != undefined) {
      let canUpdate = e.changes.filter(
        (f) =>
          f.type === 'insert' ||
          f.type === 'update' ||
          f.type === 'delete' ||
          f.type === 'remove',
      );
      if (canUpdate.length > 0) {
        this.updateValues();
      }
    }
  }

  requestEditOperationHandler(e) {
    let canUpdate = false;
    if (e.operation === 'addShape') {
      canUpdate = true;
      if (e.args.shape.type !== 'status') {
        e.allowed = false;
      }
    }

    // Déplacement
    if (e.operation === 'moveShape') {
      canUpdate = true;
    }

    // // Suppression d'un connecteur
    // if (e.operation === 'deleteConnector') {
    //   canUpdate = true;
    // }

    // // Suppression d'un noeud
    // if (e.operation === 'deleteShape') {
    //   canUpdate = true;
    // }

    // Si vérification d'update
    if (canUpdate === true) {
      this.updateValues();
    }
  }

  beforeSave(obj) {
    return false;
  }

  /**
   * Permet de générer des balises <tspan> pour faire un retour à la ligne
   * si la taille du texte dépasse 18 caractères.
   * @param text texte à afficher
   * @returns tableau de string
   */
  generateTspans(text: string): string[] {
    const maxLength = 18;
    const tspans: string[] = [];

    if (text.length <= maxLength) {
      tspans.push(text);
    } else {
      let currentIndex = 0;
      while (currentIndex < text.length) {
        tspans.push(text.substring(currentIndex, currentIndex + maxLength));
        currentIndex += maxLength;
      }
    }
    return tspans;
  }

  /** Statut */
  getStatusPointValue(e) {
    if (this._data != undefined && e != undefined) {
      let item = this._data.find((f) => f.objId == e.objId);
      if (item?.name != undefined) {
        return item?.name;
      }
    }
    return '';
  }
  /** Indique dans le cadre le type de point */
  getTextPointValue(e) {
    if (this._data != undefined && e != undefined) {
      let item = this._data.find((f) => f.objId == e.objId);
      let pointValues = [];
      if (item != undefined) {
        if (item.isEntry === true) {
          pointValues.push(
            TranslateService.get('entities/status-workflow/start'),
          );
        }

        if (item.isTakingCharge === true) {
          pointValues.push(
            TranslateService.get('entities/status-workflow/takeCharge'),
          );
        }

        if (item.isEndTreatment === true) {
          pointValues.push(
            TranslateService.get('entities/status-workflow/endTreatment'),
          );
        }

        if (item.isEnding === true) {
          pointValues.push(
            TranslateService.get('entities/status-workflow/end'),
          );
        }
      }

      return pointValues;
    }
  }

  /** Sélection dans le diagram */
  onItemClick(e) {
    if (e.item.itemType == 'shape' && this.autoSelect) {
      let nodeSelected = e.item.attachedConnectorIds;

      if (nodeSelected != undefined && nodeSelected.length > 0) {
        let edges = [];
        edges.push(e.component.getItemById(e.item.id));
        nodeSelected.forEach((f) => {
          let edge = e.component.getItemById(f);
          edges.push(edge);
        });

        e.component.setSelectedItems(edges);
      }
    }
  }

  /** Raffraichi la grille */
  refreshDataGrid() {
    if (this.displayMode == DiagramDisplayMode.Grid) {
      // Chargement des données
      this.gridDataSource = [];
      if (this.dxDiagram?.instance != undefined) {
        // obtient les shapes
        let nodes = this.dxDiagram.instance.getNodeDataSource()?.items();
        if (nodes != undefined) {
          // obtient les noeuds
          let edges = this.dxDiagram.instance.getEdgeDataSource()?.items();

          // Commence par les points d'entrée sans l'appel recursif en fonction des liens
          this.addingPoints((s) => s.isEntry, nodes, edges);
          // Les points avec Position = prise en charge
          this.addingPoints((s) => s.isTakingCharge, nodes, edges);
          // Les points sans Position
          this.addingPoints(
            (s) =>
              !s.isEntry &&
              !s.isTakingCharge &&
              !s.isEndTreatment &&
              !s.isEnding,
            nodes,
            edges,
          );
          // Les points avec Position = fin de traitement
          this.addingPoints((s) => s.isEndTreatment, nodes, edges);
          // Ajoute les points de sortie
          this.addingPoints((s) => s.isEnding, nodes, edges);
        }
      }
    }
  }

  addingPoints(filter, nodes, edges) {
    let points = nodes.filter(filter);

    points.sort((a, b) => a.name.localeCompare(b.name));

    points.forEach((f) => {
      let positions = [];
      let to = [];

      // Type de points
      if (f.isEntry) {
        positions.push(TranslateService.get('entities/status-workflow/start'));
      }
      if (f.isTakingCharge) {
        positions.push(
          TranslateService.get('entities/status-workflow/takeCharge'),
        );
      }
      if (f.isEndTreatment) {
        positions.push(
          TranslateService.get('entities/status-workflow/endTreatment'),
        );
      }
      if (f.isEnding) {
        positions.push(TranslateService.get('entities/status-workflow/end'));
      }

      // Recherche des liens
      if (edges != undefined) {
        edges
          .filter((edge) => edge.fromId == f.objId)
          .forEach((endPointId) => {
            let endPoint = nodes.find((s) => s.objId == endPointId.toId);
            if (endPoint != undefined) {
              to.push(endPoint);
            }
          });
      }

      let isfind = this.gridDataSource.find(
        (find) => find.edit.objId == f.objId,
      );
      if (isfind == undefined) {
        this.gridDataSource.push({
          status: f.name,
          to: to.map((m) => m.name),
          positions: positions,
          edit: f,
          isOperator:
            f.rightToMove?.allOperator ||
            f.rightToMove?.referentTeam ||
            f.rightToMove?.operatorReferent ||
            f.rightToMove?.affectedTeam ||
            f.rightToMove?.operatorAffected,
          isUser:
            f.rightToMove?.allConcerned ||
            f.rightToMove?.makeRequest ||
            f.rightToMove?.affected,
          isManage: f.rightToMove?.manager,
          isUsers: f.rightToMove?.customUserIds?.length > 0,
        });
      }

      // if (to != undefined) {
      //   // Recherche les statut suivant
      //   to.forEach((toObject) => {
      //     let isfind = this.gridDataSource.find(
      //       (find) => find.edit.objId == toObject.objId,
      //     );
      //     if (isfind == undefined) {
      //       this.addingPoints(
      //         (s) => !s.isEntry && !s.isEnding && toObject.objId == s.objId,
      //         nodes,
      //         edges,
      //       );
      //     }
      //   });
      // }
    });
  }
}
