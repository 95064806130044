import { LocationCharterBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { EnumCoreService } from '../enum-service';
import { of } from 'rxjs';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('administration.location-charter')
export class LocationCharterCoreService extends LocationCharterBaseService {
  constructor(injector: Injector, public enumCoreService: EnumCoreService) {
    super(injector);
  }

  /** Obtient la liste des catégories des charte */
  locationCharterCategory(): Observable<{ id: string; name: string }[]> {
    return of(
      this.enumCoreService
        .locationCharterCategory()
        .filter((f) => f.id != 'LDAP'),
    );
  }
}
