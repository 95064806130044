import { Directive, Input, TemplateRef, ViewContainerRef, Host } from '@angular/core';


@Directive({ selector: '[clcSwitchOnlyOne]' })
export class ClcSwitchOnlyOne {
  @Input() clcSwitchOnlyOne;

}
@Directive({ selector: '[clcSwitchOnlyOneCase]' })
export class ClcSwitchOnlyOneCase {
  constructor(
    private templateRef: TemplateRef<any>,
    private viewContainer: ViewContainerRef,
    @Host() private clcSwitch: ClcSwitchOnlyOne) {

  }
  @Input() set clcSwitchOnlyOneCase(value: any) {
    if (this.clcSwitch.clcSwitchOnlyOne === value) {
      this.viewContainer.createEmbeddedView(this.templateRef);
    }
    else {
      this.templateRef.elementRef.nativeElement.remove();
      this.viewContainer.remove();
    }
  }
}