import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPnpVendorsBaseVariables, FirstPnpVendorsBaseVariables, CountPnpVendorsBaseVariables, FindPnpVendorsBaseVariables, SearchPnpVendorsBaseVariables, ExportSchemaPnpVendorsBaseVariables, ExportDataPnpVendorsBaseVariables, CustomQueryPnpVendorsBaseVariables, CustomQueryIdPnpVendorsBaseVariables, UsedPnpVendorsBaseVariables, ExistPnpVendorsBaseVariables, InsertPnpVendorsBaseVariables, UpdatePnpVendorsBaseVariables, ImportDataPnpVendorsBaseVariables, UpdateManyPnpVendorsBaseVariables, DeletePnpVendorsBaseVariables } from '../gqls'
import { GetPnpVendorsDocument, FirstPnpVendorsDocument, CountPnpVendorsDocument, FindPnpVendorsDocument, SearchPnpVendorsDocument, ExportSchemaPnpVendorsDocument, ExportDataPnpVendorsDocument, CustomQueryPnpVendorsDocument, CustomQueryIdPnpVendorsDocument, UsedPnpVendorsDocument, ExistPnpVendorsDocument, InsertPnpVendorsDocument, UpdatePnpVendorsDocument, ImportDataPnpVendorsDocument, UpdateManyPnpVendorsDocument, DeletePnpVendorsDocument } from '../gqls'
import { ServiceSingleResultOfPnpVendor, QueryContextOfPnpVendor, FilterOfPnpVendor, ServiceSingleResultOfInt64, ServiceListResultOfPnpVendor, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, PnpVendorInput, FieldUpdateOperatorOfPnpVendor } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PnpVendorBaseService {
    
public modelName = 'pnpVendor';
public modelPluralName = 'pnpVendors';

	private getPnpVendorsQuery: GetPnpVendorsDocument;
	private firstPnpVendorsQuery: FirstPnpVendorsDocument;
	private countPnpVendorsQuery: CountPnpVendorsDocument;
	private findPnpVendorsQuery: FindPnpVendorsDocument;
	private searchPnpVendorsQuery: SearchPnpVendorsDocument;
	private exportSchemaPnpVendorsQuery: ExportSchemaPnpVendorsDocument;
	private exportDataPnpVendorsQuery: ExportDataPnpVendorsDocument;
	private customQueryPnpVendorsQuery: CustomQueryPnpVendorsDocument;
	private customQueryIdPnpVendorsQuery: CustomQueryIdPnpVendorsDocument;
	private usedPnpVendorsQuery: UsedPnpVendorsDocument;
	private existPnpVendorsQuery: ExistPnpVendorsDocument;
	private insertPnpVendorsMutation: InsertPnpVendorsDocument;
	private updatePnpVendorsMutation: UpdatePnpVendorsDocument;
	private importDataPnpVendorsMutation: ImportDataPnpVendorsDocument;
	private updateManyPnpVendorsMutation: UpdateManyPnpVendorsDocument;
	private deletePnpVendorsMutation: DeletePnpVendorsDocument;

	constructor(private injector: Injector) {
		this.getPnpVendorsQuery = this.injector.get(GetPnpVendorsDocument);
		this.firstPnpVendorsQuery = this.injector.get(FirstPnpVendorsDocument);
		this.countPnpVendorsQuery = this.injector.get(CountPnpVendorsDocument);
		this.findPnpVendorsQuery = this.injector.get(FindPnpVendorsDocument);
		this.searchPnpVendorsQuery = this.injector.get(SearchPnpVendorsDocument);
		this.exportSchemaPnpVendorsQuery = this.injector.get(ExportSchemaPnpVendorsDocument);
		this.exportDataPnpVendorsQuery = this.injector.get(ExportDataPnpVendorsDocument);
		this.customQueryPnpVendorsQuery = this.injector.get(CustomQueryPnpVendorsDocument);
		this.customQueryIdPnpVendorsQuery = this.injector.get(CustomQueryIdPnpVendorsDocument);
		this.usedPnpVendorsQuery = this.injector.get(UsedPnpVendorsDocument);
		this.existPnpVendorsQuery = this.injector.get(ExistPnpVendorsDocument);
		this.insertPnpVendorsMutation = this.injector.get(InsertPnpVendorsDocument);
		this.updatePnpVendorsMutation = this.injector.get(UpdatePnpVendorsDocument);
		this.importDataPnpVendorsMutation = this.injector.get(ImportDataPnpVendorsDocument);
		this.updateManyPnpVendorsMutation = this.injector.get(UpdateManyPnpVendorsDocument);
		this.deletePnpVendorsMutation = this.injector.get(DeletePnpVendorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpVendor> {
    
    		let variables: GetPnpVendorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.get));
            }
            else{
                let result:ServiceSingleResultOfPnpVendor={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpVendor,
		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpVendor> {
    
    		let variables: FirstPnpVendorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPnpVendorsBaseVariables = {
    			filter: filter
    		}
    				return this.countPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpVendor,
		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpVendor> {
    
    		let variables: FindPnpVendorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPnpVendor,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpVendor> {
    
    		let variables: SearchPnpVendorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPnpVendorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPnpVendorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPnpVendor,
		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpVendor> {
    
    		let variables: CustomQueryPnpVendorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPnpVendor,
		@Args('filter?') filter?: FilterOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpVendor> {
    
    		let variables: CustomQueryIdPnpVendorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPnpVendorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPnpVendorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPnpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PnpVendorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpVendor> {
    
    		let variables: InsertPnpVendorsBaseVariables = {
    			entity: entity
    		}
    				return this.insertPnpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpVendor> {
    
    		let variables: UpdatePnpVendorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePnpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPnpVendorsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPnpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPnpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPnpVendorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPnpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePnpVendorsBaseVariables = {
    			ids: ids
    		}
    				return this.deletePnpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpVendors.delete));
    	}
    
}