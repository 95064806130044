import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { PackageBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration')
export class PackageCoreService extends PackageBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
