import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTrackingsBaseVariables, FirstTrackingsBaseVariables, CountTrackingsBaseVariables, FindTrackingsBaseVariables, SearchTrackingsBaseVariables, ExportSchemaTrackingsBaseVariables, ExportDataTrackingsBaseVariables, CustomQueryTrackingsBaseVariables, CustomQueryIdTrackingsBaseVariables, UsedTrackingsBaseVariables, FindByEntityIdTrackingsBaseVariables, ExistTrackingsBaseVariables, InsertTrackingsBaseVariables, UpdateTrackingsBaseVariables, ImportDataTrackingsBaseVariables, UpdateManyTrackingsBaseVariables, DeleteTrackingsBaseVariables } from '../gqls'
import { GetTrackingsDocument, FirstTrackingsDocument, CountTrackingsDocument, FindTrackingsDocument, SearchTrackingsDocument, ExportSchemaTrackingsDocument, ExportDataTrackingsDocument, CustomQueryTrackingsDocument, CustomQueryIdTrackingsDocument, UsedTrackingsDocument, FindByEntityIdTrackingsDocument, ExistTrackingsDocument, InsertTrackingsDocument, UpdateTrackingsDocument, ImportDataTrackingsDocument, UpdateManyTrackingsDocument, DeleteTrackingsDocument } from '../gqls'
import { ServiceSingleResultOfTracking, QueryContextOfTracking, FilterOfTracking, ServiceSingleResultOfInt64, ServiceListResultOfTracking, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TrackingInput, FieldUpdateOperatorOfTracking } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TrackingBaseService {
    
public modelName = 'tracking';
public modelPluralName = 'trackings';

	private getTrackingsQuery: GetTrackingsDocument;
	private firstTrackingsQuery: FirstTrackingsDocument;
	private countTrackingsQuery: CountTrackingsDocument;
	private findTrackingsQuery: FindTrackingsDocument;
	private searchTrackingsQuery: SearchTrackingsDocument;
	private exportSchemaTrackingsQuery: ExportSchemaTrackingsDocument;
	private exportDataTrackingsQuery: ExportDataTrackingsDocument;
	private customQueryTrackingsQuery: CustomQueryTrackingsDocument;
	private customQueryIdTrackingsQuery: CustomQueryIdTrackingsDocument;
	private usedTrackingsQuery: UsedTrackingsDocument;
	private findByEntityIdTrackingsQuery: FindByEntityIdTrackingsDocument;
	private existTrackingsQuery: ExistTrackingsDocument;
	private insertTrackingsMutation: InsertTrackingsDocument;
	private updateTrackingsMutation: UpdateTrackingsDocument;
	private importDataTrackingsMutation: ImportDataTrackingsDocument;
	private updateManyTrackingsMutation: UpdateManyTrackingsDocument;
	private deleteTrackingsMutation: DeleteTrackingsDocument;

	constructor(private injector: Injector) {
		this.getTrackingsQuery = this.injector.get(GetTrackingsDocument);
		this.firstTrackingsQuery = this.injector.get(FirstTrackingsDocument);
		this.countTrackingsQuery = this.injector.get(CountTrackingsDocument);
		this.findTrackingsQuery = this.injector.get(FindTrackingsDocument);
		this.searchTrackingsQuery = this.injector.get(SearchTrackingsDocument);
		this.exportSchemaTrackingsQuery = this.injector.get(ExportSchemaTrackingsDocument);
		this.exportDataTrackingsQuery = this.injector.get(ExportDataTrackingsDocument);
		this.customQueryTrackingsQuery = this.injector.get(CustomQueryTrackingsDocument);
		this.customQueryIdTrackingsQuery = this.injector.get(CustomQueryIdTrackingsDocument);
		this.usedTrackingsQuery = this.injector.get(UsedTrackingsDocument);
		this.findByEntityIdTrackingsQuery = this.injector.get(FindByEntityIdTrackingsDocument);
		this.existTrackingsQuery = this.injector.get(ExistTrackingsDocument);
		this.insertTrackingsMutation = this.injector.get(InsertTrackingsDocument);
		this.updateTrackingsMutation = this.injector.get(UpdateTrackingsDocument);
		this.importDataTrackingsMutation = this.injector.get(ImportDataTrackingsDocument);
		this.updateManyTrackingsMutation = this.injector.get(UpdateManyTrackingsDocument);
		this.deleteTrackingsMutation = this.injector.get(DeleteTrackingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTracking> {
    
    		let variables: GetTrackingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.get));
            }
            else{
                let result:ServiceSingleResultOfTracking={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTracking,
		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTracking> {
    
    		let variables: FirstTrackingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTrackingsBaseVariables = {
    			filter: filter
    		}
    				return this.countTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTracking,
		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTracking> {
    
    		let variables: FindTrackingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTracking,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTracking> {
    
    		let variables: SearchTrackingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTrackingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTrackingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTracking,
		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTracking> {
    
    		let variables: CustomQueryTrackingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTracking,
		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTracking> {
    
    		let variables: CustomQueryIdTrackingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTrackingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByEntityId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTracking,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTracking> {
    
    		let variables: FindByEntityIdTrackingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findByEntityIdTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.findByEntityId));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTrackingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTrackingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TrackingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTracking> {
    
    		let variables: InsertTrackingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTracking> {
    
    		let variables: UpdateTrackingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTrackingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTracking,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTrackingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTrackingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTrackingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.trackings.delete));
    	}
    
}