import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketAppointmentsBaseVariables, FirstTicketAppointmentsBaseVariables, CountTicketAppointmentsBaseVariables, FindTicketAppointmentsBaseVariables, SearchTicketAppointmentsBaseVariables, ExportSchemaTicketAppointmentsBaseVariables, ExportDataTicketAppointmentsBaseVariables, CustomQueryTicketAppointmentsBaseVariables, CustomQueryIdTicketAppointmentsBaseVariables, UsedTicketAppointmentsBaseVariables, ExistTicketAppointmentsBaseVariables, InsertTicketAppointmentsBaseVariables, UpdateTicketAppointmentsBaseVariables, ImportDataTicketAppointmentsBaseVariables, UpdateManyTicketAppointmentsBaseVariables, DeleteTicketAppointmentsBaseVariables } from '../gqls'
import { GetTicketAppointmentsDocument, FirstTicketAppointmentsDocument, CountTicketAppointmentsDocument, FindTicketAppointmentsDocument, SearchTicketAppointmentsDocument, ExportSchemaTicketAppointmentsDocument, ExportDataTicketAppointmentsDocument, CustomQueryTicketAppointmentsDocument, CustomQueryIdTicketAppointmentsDocument, UsedTicketAppointmentsDocument, ExistTicketAppointmentsDocument, InsertTicketAppointmentsDocument, UpdateTicketAppointmentsDocument, ImportDataTicketAppointmentsDocument, UpdateManyTicketAppointmentsDocument, DeleteTicketAppointmentsDocument } from '../gqls'
import { ServiceSingleResultOfTicketAppointment, QueryContextOfTicketAppointment, FilterOfTicketAppointment, ServiceSingleResultOfInt64, ServiceListResultOfTicketAppointment, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketAppointmentInput, FieldUpdateOperatorOfTicketAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketAppointmentBaseService {
    
public modelName = 'ticketAppointment';
public modelPluralName = 'ticketAppointments';

	private getTicketAppointmentsQuery: GetTicketAppointmentsDocument;
	private firstTicketAppointmentsQuery: FirstTicketAppointmentsDocument;
	private countTicketAppointmentsQuery: CountTicketAppointmentsDocument;
	private findTicketAppointmentsQuery: FindTicketAppointmentsDocument;
	private searchTicketAppointmentsQuery: SearchTicketAppointmentsDocument;
	private exportSchemaTicketAppointmentsQuery: ExportSchemaTicketAppointmentsDocument;
	private exportDataTicketAppointmentsQuery: ExportDataTicketAppointmentsDocument;
	private customQueryTicketAppointmentsQuery: CustomQueryTicketAppointmentsDocument;
	private customQueryIdTicketAppointmentsQuery: CustomQueryIdTicketAppointmentsDocument;
	private usedTicketAppointmentsQuery: UsedTicketAppointmentsDocument;
	private existTicketAppointmentsQuery: ExistTicketAppointmentsDocument;
	private insertTicketAppointmentsMutation: InsertTicketAppointmentsDocument;
	private updateTicketAppointmentsMutation: UpdateTicketAppointmentsDocument;
	private importDataTicketAppointmentsMutation: ImportDataTicketAppointmentsDocument;
	private updateManyTicketAppointmentsMutation: UpdateManyTicketAppointmentsDocument;
	private deleteTicketAppointmentsMutation: DeleteTicketAppointmentsDocument;

	constructor(private injector: Injector) {
		this.getTicketAppointmentsQuery = this.injector.get(GetTicketAppointmentsDocument);
		this.firstTicketAppointmentsQuery = this.injector.get(FirstTicketAppointmentsDocument);
		this.countTicketAppointmentsQuery = this.injector.get(CountTicketAppointmentsDocument);
		this.findTicketAppointmentsQuery = this.injector.get(FindTicketAppointmentsDocument);
		this.searchTicketAppointmentsQuery = this.injector.get(SearchTicketAppointmentsDocument);
		this.exportSchemaTicketAppointmentsQuery = this.injector.get(ExportSchemaTicketAppointmentsDocument);
		this.exportDataTicketAppointmentsQuery = this.injector.get(ExportDataTicketAppointmentsDocument);
		this.customQueryTicketAppointmentsQuery = this.injector.get(CustomQueryTicketAppointmentsDocument);
		this.customQueryIdTicketAppointmentsQuery = this.injector.get(CustomQueryIdTicketAppointmentsDocument);
		this.usedTicketAppointmentsQuery = this.injector.get(UsedTicketAppointmentsDocument);
		this.existTicketAppointmentsQuery = this.injector.get(ExistTicketAppointmentsDocument);
		this.insertTicketAppointmentsMutation = this.injector.get(InsertTicketAppointmentsDocument);
		this.updateTicketAppointmentsMutation = this.injector.get(UpdateTicketAppointmentsDocument);
		this.importDataTicketAppointmentsMutation = this.injector.get(ImportDataTicketAppointmentsDocument);
		this.updateManyTicketAppointmentsMutation = this.injector.get(UpdateManyTicketAppointmentsDocument);
		this.deleteTicketAppointmentsMutation = this.injector.get(DeleteTicketAppointmentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAppointment> {
    
    		let variables: GetTicketAppointmentsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.get));
            }
            else{
                let result:ServiceSingleResultOfTicketAppointment={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAppointment,
		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAppointment> {
    
    		let variables: FirstTicketAppointmentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAppointment,
		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAppointment> {
    
    		let variables: FindTicketAppointmentsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketAppointment,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAppointment> {
    
    		let variables: SearchTicketAppointmentsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketAppointmentsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketAppointment,
		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAppointment> {
    
    		let variables: CustomQueryTicketAppointmentsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketAppointment,
		@Args('filter?') filter?: FilterOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAppointment> {
    
    		let variables: CustomQueryIdTicketAppointmentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketAppointmentsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAppointment> {
    
    		let variables: InsertTicketAppointmentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAppointment> {
    
    		let variables: UpdateTicketAppointmentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketAppointmentsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketAppointmentsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAppointments.delete));
    	}
    
}