
import { BudgetCategoryBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';

@Injectable({ providedIn: 'root' })
@Authorize('budget.manage-category')
export class BudgetCategoryCoreService extends BudgetCategoryBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}