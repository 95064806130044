import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetModelStockEntriesBaseVariables, FirstAssetModelStockEntriesBaseVariables, CountAssetModelStockEntriesBaseVariables, FindAssetModelStockEntriesBaseVariables, SearchAssetModelStockEntriesBaseVariables, ExportSchemaAssetModelStockEntriesBaseVariables, ExportDataAssetModelStockEntriesBaseVariables, CustomQueryAssetModelStockEntriesBaseVariables, CustomQueryIdAssetModelStockEntriesBaseVariables, UsedAssetModelStockEntriesBaseVariables, ExistAssetModelStockEntriesBaseVariables, FindRecyclesAssetModelStockEntriesBaseVariables, CountRecyclesAssetModelStockEntriesBaseVariables, ReadOnlyAssetModelStockEntriesBaseVariables, FindArchivedAssetModelStockEntriesBaseVariables, CountAllAssetModelStockEntriesBaseVariables, FindDynamicPropertyFieldsAssetModelStockEntriesBaseVariables, InsertAssetModelStockEntriesBaseVariables, UpdateAssetModelStockEntriesBaseVariables, ImportDataAssetModelStockEntriesBaseVariables, UpdateManyAssetModelStockEntriesBaseVariables, DeleteAssetModelStockEntriesBaseVariables, RestoreAssetModelStockEntriesBaseVariables, RecycleAssetModelStockEntriesBaseVariables, RestoreArchivedAssetModelStockEntriesBaseVariables, ArchivedAssetModelStockEntriesBaseVariables, AddFileDynamicFieldAssetModelStockEntriesBaseVariables, RemoveFileDynamicFieldAssetModelStockEntriesBaseVariables } from '../gqls'
import { GetAssetModelStockEntriesDocument, FirstAssetModelStockEntriesDocument, CountAssetModelStockEntriesDocument, FindAssetModelStockEntriesDocument, SearchAssetModelStockEntriesDocument, ExportSchemaAssetModelStockEntriesDocument, ExportDataAssetModelStockEntriesDocument, CustomQueryAssetModelStockEntriesDocument, CustomQueryIdAssetModelStockEntriesDocument, UsedAssetModelStockEntriesDocument, ExistAssetModelStockEntriesDocument, FindRecyclesAssetModelStockEntriesDocument, CountRecyclesAssetModelStockEntriesDocument, ReadOnlyAssetModelStockEntriesDocument, FindArchivedAssetModelStockEntriesDocument, CountAllAssetModelStockEntriesDocument, FindDynamicPropertyFieldsAssetModelStockEntriesDocument, InsertAssetModelStockEntriesDocument, UpdateAssetModelStockEntriesDocument, ImportDataAssetModelStockEntriesDocument, UpdateManyAssetModelStockEntriesDocument, DeleteAssetModelStockEntriesDocument, RestoreAssetModelStockEntriesDocument, RecycleAssetModelStockEntriesDocument, RestoreArchivedAssetModelStockEntriesDocument, ArchivedAssetModelStockEntriesDocument, AddFileDynamicFieldAssetModelStockEntriesDocument, RemoveFileDynamicFieldAssetModelStockEntriesDocument } from '../gqls'
import { ServiceSingleResultOfAssetModelStockEntry, QueryContextOfAssetModelStockEntry, FilterOfAssetModelStockEntry, ServiceSingleResultOfInt64, ServiceListResultOfAssetModelStockEntry, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAssetModelStockEntry, AssetModelStockEntryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetModelStockEntryBaseService {
    
public modelName = 'assetModelStockEntry';
public modelPluralName = 'assetModelStockEntries';

	private getAssetModelStockEntriesQuery: GetAssetModelStockEntriesDocument;
	private firstAssetModelStockEntriesQuery: FirstAssetModelStockEntriesDocument;
	private countAssetModelStockEntriesQuery: CountAssetModelStockEntriesDocument;
	private findAssetModelStockEntriesQuery: FindAssetModelStockEntriesDocument;
	private searchAssetModelStockEntriesQuery: SearchAssetModelStockEntriesDocument;
	private exportSchemaAssetModelStockEntriesQuery: ExportSchemaAssetModelStockEntriesDocument;
	private exportDataAssetModelStockEntriesQuery: ExportDataAssetModelStockEntriesDocument;
	private customQueryAssetModelStockEntriesQuery: CustomQueryAssetModelStockEntriesDocument;
	private customQueryIdAssetModelStockEntriesQuery: CustomQueryIdAssetModelStockEntriesDocument;
	private usedAssetModelStockEntriesQuery: UsedAssetModelStockEntriesDocument;
	private existAssetModelStockEntriesQuery: ExistAssetModelStockEntriesDocument;
	private findRecyclesAssetModelStockEntriesQuery: FindRecyclesAssetModelStockEntriesDocument;
	private countRecyclesAssetModelStockEntriesQuery: CountRecyclesAssetModelStockEntriesDocument;
	private readOnlyAssetModelStockEntriesQuery: ReadOnlyAssetModelStockEntriesDocument;
	private findArchivedAssetModelStockEntriesQuery: FindArchivedAssetModelStockEntriesDocument;
	private countAllAssetModelStockEntriesQuery: CountAllAssetModelStockEntriesDocument;
	private findDynamicPropertyFieldsAssetModelStockEntriesQuery: FindDynamicPropertyFieldsAssetModelStockEntriesDocument;
	private insertAssetModelStockEntriesMutation: InsertAssetModelStockEntriesDocument;
	private updateAssetModelStockEntriesMutation: UpdateAssetModelStockEntriesDocument;
	private importDataAssetModelStockEntriesMutation: ImportDataAssetModelStockEntriesDocument;
	private updateManyAssetModelStockEntriesMutation: UpdateManyAssetModelStockEntriesDocument;
	private deleteAssetModelStockEntriesMutation: DeleteAssetModelStockEntriesDocument;
	private restoreAssetModelStockEntriesMutation: RestoreAssetModelStockEntriesDocument;
	private recycleAssetModelStockEntriesMutation: RecycleAssetModelStockEntriesDocument;
	private restoreArchivedAssetModelStockEntriesMutation: RestoreArchivedAssetModelStockEntriesDocument;
	private archivedAssetModelStockEntriesMutation: ArchivedAssetModelStockEntriesDocument;
	private addFileDynamicFieldAssetModelStockEntriesMutation: AddFileDynamicFieldAssetModelStockEntriesDocument;
	private removeFileDynamicFieldAssetModelStockEntriesMutation: RemoveFileDynamicFieldAssetModelStockEntriesDocument;

	constructor(private injector: Injector) {
		this.getAssetModelStockEntriesQuery = this.injector.get(GetAssetModelStockEntriesDocument);
		this.firstAssetModelStockEntriesQuery = this.injector.get(FirstAssetModelStockEntriesDocument);
		this.countAssetModelStockEntriesQuery = this.injector.get(CountAssetModelStockEntriesDocument);
		this.findAssetModelStockEntriesQuery = this.injector.get(FindAssetModelStockEntriesDocument);
		this.searchAssetModelStockEntriesQuery = this.injector.get(SearchAssetModelStockEntriesDocument);
		this.exportSchemaAssetModelStockEntriesQuery = this.injector.get(ExportSchemaAssetModelStockEntriesDocument);
		this.exportDataAssetModelStockEntriesQuery = this.injector.get(ExportDataAssetModelStockEntriesDocument);
		this.customQueryAssetModelStockEntriesQuery = this.injector.get(CustomQueryAssetModelStockEntriesDocument);
		this.customQueryIdAssetModelStockEntriesQuery = this.injector.get(CustomQueryIdAssetModelStockEntriesDocument);
		this.usedAssetModelStockEntriesQuery = this.injector.get(UsedAssetModelStockEntriesDocument);
		this.existAssetModelStockEntriesQuery = this.injector.get(ExistAssetModelStockEntriesDocument);
		this.findRecyclesAssetModelStockEntriesQuery = this.injector.get(FindRecyclesAssetModelStockEntriesDocument);
		this.countRecyclesAssetModelStockEntriesQuery = this.injector.get(CountRecyclesAssetModelStockEntriesDocument);
		this.readOnlyAssetModelStockEntriesQuery = this.injector.get(ReadOnlyAssetModelStockEntriesDocument);
		this.findArchivedAssetModelStockEntriesQuery = this.injector.get(FindArchivedAssetModelStockEntriesDocument);
		this.countAllAssetModelStockEntriesQuery = this.injector.get(CountAllAssetModelStockEntriesDocument);
		this.findDynamicPropertyFieldsAssetModelStockEntriesQuery = this.injector.get(FindDynamicPropertyFieldsAssetModelStockEntriesDocument);
		this.insertAssetModelStockEntriesMutation = this.injector.get(InsertAssetModelStockEntriesDocument);
		this.updateAssetModelStockEntriesMutation = this.injector.get(UpdateAssetModelStockEntriesDocument);
		this.importDataAssetModelStockEntriesMutation = this.injector.get(ImportDataAssetModelStockEntriesDocument);
		this.updateManyAssetModelStockEntriesMutation = this.injector.get(UpdateManyAssetModelStockEntriesDocument);
		this.deleteAssetModelStockEntriesMutation = this.injector.get(DeleteAssetModelStockEntriesDocument);
		this.restoreAssetModelStockEntriesMutation = this.injector.get(RestoreAssetModelStockEntriesDocument);
		this.recycleAssetModelStockEntriesMutation = this.injector.get(RecycleAssetModelStockEntriesDocument);
		this.restoreArchivedAssetModelStockEntriesMutation = this.injector.get(RestoreArchivedAssetModelStockEntriesDocument);
		this.archivedAssetModelStockEntriesMutation = this.injector.get(ArchivedAssetModelStockEntriesDocument);
		this.addFileDynamicFieldAssetModelStockEntriesMutation = this.injector.get(AddFileDynamicFieldAssetModelStockEntriesDocument);
		this.removeFileDynamicFieldAssetModelStockEntriesMutation = this.injector.get(RemoveFileDynamicFieldAssetModelStockEntriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModelStockEntry> {
    
    		let variables: GetAssetModelStockEntriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.get));
            }
            else{
                let result:ServiceSingleResultOfAssetModelStockEntry={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModelStockEntry> {
    
    		let variables: FirstAssetModelStockEntriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetModelStockEntriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: FindAssetModelStockEntriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: SearchAssetModelStockEntriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetModelStockEntriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetModelStockEntriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: CustomQueryAssetModelStockEntriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: CustomQueryIdAssetModelStockEntriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetModelStockEntriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: FindRecyclesAssetModelStockEntriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAssetModelStockEntriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAssetModelStockEntriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: FindArchivedAssetModelStockEntriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAssetModelStockEntriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAssetModelStockEntriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAssetModelStockEntriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetModelStockEntryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModelStockEntry> {
    
    		let variables: InsertAssetModelStockEntriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModelStockEntry> {
    
    		let variables: UpdateAssetModelStockEntriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetModelStockEntriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetModelStockEntriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAssetModelStockEntriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAssetModelStockEntriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAssetModelStockEntriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAssetModelStockEntriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModelStockEntries.removeFileDynamicField));
    	}
    
}