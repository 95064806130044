import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketWeeklyBacklogsBaseVariables, FirstTicketWeeklyBacklogsBaseVariables, CountTicketWeeklyBacklogsBaseVariables, FindTicketWeeklyBacklogsBaseVariables, SearchTicketWeeklyBacklogsBaseVariables, ExportSchemaTicketWeeklyBacklogsBaseVariables, ExportDataTicketWeeklyBacklogsBaseVariables, CustomQueryTicketWeeklyBacklogsBaseVariables, CustomQueryIdTicketWeeklyBacklogsBaseVariables, UsedTicketWeeklyBacklogsBaseVariables, ExistTicketWeeklyBacklogsBaseVariables, InsertTicketWeeklyBacklogsBaseVariables, UpdateTicketWeeklyBacklogsBaseVariables, ImportDataTicketWeeklyBacklogsBaseVariables, UpdateManyTicketWeeklyBacklogsBaseVariables, DeleteTicketWeeklyBacklogsBaseVariables } from '../gqls'
import { GetTicketWeeklyBacklogsDocument, FirstTicketWeeklyBacklogsDocument, CountTicketWeeklyBacklogsDocument, FindTicketWeeklyBacklogsDocument, SearchTicketWeeklyBacklogsDocument, ExportSchemaTicketWeeklyBacklogsDocument, ExportDataTicketWeeklyBacklogsDocument, CustomQueryTicketWeeklyBacklogsDocument, CustomQueryIdTicketWeeklyBacklogsDocument, UsedTicketWeeklyBacklogsDocument, ExistTicketWeeklyBacklogsDocument, InsertTicketWeeklyBacklogsDocument, UpdateTicketWeeklyBacklogsDocument, ImportDataTicketWeeklyBacklogsDocument, UpdateManyTicketWeeklyBacklogsDocument, DeleteTicketWeeklyBacklogsDocument } from '../gqls'
import { ServiceSingleResultOfTicketWeeklyBacklog, QueryContextOfTicketWeeklyBacklog, FilterOfTicketWeeklyBacklog, ServiceSingleResultOfInt64, ServiceListResultOfTicketWeeklyBacklog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketWeeklyBacklogInput, FieldUpdateOperatorOfTicketWeeklyBacklog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketWeeklyBacklogBaseService {
    
public modelName = 'ticketWeeklyBacklog';
public modelPluralName = 'ticketWeeklyBacklogs';

	private getTicketWeeklyBacklogsQuery: GetTicketWeeklyBacklogsDocument;
	private firstTicketWeeklyBacklogsQuery: FirstTicketWeeklyBacklogsDocument;
	private countTicketWeeklyBacklogsQuery: CountTicketWeeklyBacklogsDocument;
	private findTicketWeeklyBacklogsQuery: FindTicketWeeklyBacklogsDocument;
	private searchTicketWeeklyBacklogsQuery: SearchTicketWeeklyBacklogsDocument;
	private exportSchemaTicketWeeklyBacklogsQuery: ExportSchemaTicketWeeklyBacklogsDocument;
	private exportDataTicketWeeklyBacklogsQuery: ExportDataTicketWeeklyBacklogsDocument;
	private customQueryTicketWeeklyBacklogsQuery: CustomQueryTicketWeeklyBacklogsDocument;
	private customQueryIdTicketWeeklyBacklogsQuery: CustomQueryIdTicketWeeklyBacklogsDocument;
	private usedTicketWeeklyBacklogsQuery: UsedTicketWeeklyBacklogsDocument;
	private existTicketWeeklyBacklogsQuery: ExistTicketWeeklyBacklogsDocument;
	private insertTicketWeeklyBacklogsMutation: InsertTicketWeeklyBacklogsDocument;
	private updateTicketWeeklyBacklogsMutation: UpdateTicketWeeklyBacklogsDocument;
	private importDataTicketWeeklyBacklogsMutation: ImportDataTicketWeeklyBacklogsDocument;
	private updateManyTicketWeeklyBacklogsMutation: UpdateManyTicketWeeklyBacklogsDocument;
	private deleteTicketWeeklyBacklogsMutation: DeleteTicketWeeklyBacklogsDocument;

	constructor(private injector: Injector) {
		this.getTicketWeeklyBacklogsQuery = this.injector.get(GetTicketWeeklyBacklogsDocument);
		this.firstTicketWeeklyBacklogsQuery = this.injector.get(FirstTicketWeeklyBacklogsDocument);
		this.countTicketWeeklyBacklogsQuery = this.injector.get(CountTicketWeeklyBacklogsDocument);
		this.findTicketWeeklyBacklogsQuery = this.injector.get(FindTicketWeeklyBacklogsDocument);
		this.searchTicketWeeklyBacklogsQuery = this.injector.get(SearchTicketWeeklyBacklogsDocument);
		this.exportSchemaTicketWeeklyBacklogsQuery = this.injector.get(ExportSchemaTicketWeeklyBacklogsDocument);
		this.exportDataTicketWeeklyBacklogsQuery = this.injector.get(ExportDataTicketWeeklyBacklogsDocument);
		this.customQueryTicketWeeklyBacklogsQuery = this.injector.get(CustomQueryTicketWeeklyBacklogsDocument);
		this.customQueryIdTicketWeeklyBacklogsQuery = this.injector.get(CustomQueryIdTicketWeeklyBacklogsDocument);
		this.usedTicketWeeklyBacklogsQuery = this.injector.get(UsedTicketWeeklyBacklogsDocument);
		this.existTicketWeeklyBacklogsQuery = this.injector.get(ExistTicketWeeklyBacklogsDocument);
		this.insertTicketWeeklyBacklogsMutation = this.injector.get(InsertTicketWeeklyBacklogsDocument);
		this.updateTicketWeeklyBacklogsMutation = this.injector.get(UpdateTicketWeeklyBacklogsDocument);
		this.importDataTicketWeeklyBacklogsMutation = this.injector.get(ImportDataTicketWeeklyBacklogsDocument);
		this.updateManyTicketWeeklyBacklogsMutation = this.injector.get(UpdateManyTicketWeeklyBacklogsDocument);
		this.deleteTicketWeeklyBacklogsMutation = this.injector.get(DeleteTicketWeeklyBacklogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketWeeklyBacklog> {
    
    		let variables: GetTicketWeeklyBacklogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.get));
            }
            else{
                let result:ServiceSingleResultOfTicketWeeklyBacklog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketWeeklyBacklog,
		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketWeeklyBacklog> {
    
    		let variables: FirstTicketWeeklyBacklogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketWeeklyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketWeeklyBacklog,
		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketWeeklyBacklog> {
    
    		let variables: FindTicketWeeklyBacklogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketWeeklyBacklog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketWeeklyBacklog> {
    
    		let variables: SearchTicketWeeklyBacklogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketWeeklyBacklogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketWeeklyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketWeeklyBacklog,
		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketWeeklyBacklog> {
    
    		let variables: CustomQueryTicketWeeklyBacklogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketWeeklyBacklog,
		@Args('filter?') filter?: FilterOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketWeeklyBacklog> {
    
    		let variables: CustomQueryIdTicketWeeklyBacklogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketWeeklyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketWeeklyBacklogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketWeeklyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketWeeklyBacklogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketWeeklyBacklog> {
    
    		let variables: InsertTicketWeeklyBacklogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketWeeklyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketWeeklyBacklog> {
    
    		let variables: UpdateTicketWeeklyBacklogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketWeeklyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketWeeklyBacklogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketWeeklyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketWeeklyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketWeeklyBacklogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketWeeklyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketWeeklyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketWeeklyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketWeeklyBacklogs.delete));
    	}
    
}