import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
import { GqlField, GqlSubField } from '../../helpers';
import { ServiceListResultOfCustomField } from '../../types';
import { CustomFieldCoreService } from '../custom-field.service';
import { UserCoreService } from '../user.service';
import { BaseStorageService } from './base-storage-service';

/** Classe de sauvegarde des données de champs perso */
export class CustomFieldStorageService extends BaseStorageService {
  private _customFields: ServiceListResultOfCustomField = undefined;

  constructor(
    private customFieldCoreService: CustomFieldCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    userService: UserCoreService,
    userId: string,
  ) {
    super(userService, userId);
  }

  /** Obtient les champs custom en fonction du model */
  get(model): Observable<any> {
    if (this._customFields == undefined) {
      return this.customFieldCoreService
        .find([
          GqlSubField.create('data', [
            GqlSubField.create(
              'name',
              this.translatedFieldHelperService.translatedFields(),
            ),
            GqlField.create('id'),
            GqlField.create('fieldName'),
            GqlField.create('key'),
          ]),
        ])
        .pipe(
          map((res) => {
            this._customFields = res;
            return this.ParseModel(model);
          }),
        );
    } else {
      return of(this.ParseModel(model));
    }
  }

  /** Reset */
  reset() {
    this._customFields = undefined;
  }

  /** Parse et applique les champs custom au model */
  private ParseModel(model) {
    let stringModel = JSON.stringify(model);

    stringModel = stringModel.replace(
      /\[resource\('entities\/incident\/customField1\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField1').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/request\/customField1\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField1').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/request\/customField2\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField2').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/incident\/customField2\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField2').name,
      ),
    );

    stringModel = stringModel.replace(
      /\[resource\('entities\/request\/customField3\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField3').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/incident\/customField3\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField3').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/request\/customField4\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField4').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/incident\/customField4\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField4').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/request\/customField5\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField5').name,
      ),
    );
    stringModel = stringModel.replace(
      /\[resource\('entities\/incident\/customField5\'\)\]/g,
      this.translatedFieldHelperService.findValueToShow(
        this._customFields.data.find((x) => x.key === 'customField5').name,
      ),
    );

    return JSON.parse(stringModel);
  }
}
