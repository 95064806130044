import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslateService } from '@clarilog/shared';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Filter } from '@clarilog/shared2/models/schema';
import { ModelCompilerContextService } from '@clarilog/shared2/services/compiler/model-compiler-context.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { AssetBaseService } from '../service-bases';
import {
  FilterOfAsset,
  QueryContextOfAsset,
  ServiceListResultOfAsset,
  ServiceSingleResultOfBoolean,
  ServiceSingleResultOfInt64,
} from '../types';
import JsBarcode from 'jsbarcode';
@Injectable({ providedIn: 'root' })
@Authorize('asset')
export class AssetCoreService extends AssetBaseService {
  constructor(
    injector: Injector,
    private modelCompilerContextService: ModelCompilerContextService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }
  public route = 'assets';

  @InjectArgs
  public findByAssetStatusKey(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('assetStatusKey') assetStatusKey: string,
    @Args('filter?') filter: FilterOfAsset,
    @Args('options?') options: QueryContextOfAsset,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfAsset> {
    let statusFilter: FilterOfAsset = {
      assetStatus: {
        key: { eq: assetStatusKey },
      },
    };

    if (filter != undefined) {
      statusFilter.and = [filter];
    }

    return this.find(fields, options, statusFilter, extendedVariables);
  }

  @InjectArgs
  public findLoanableAssetDetail(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('filter?') filter: FilterOfAsset,
    @Args('options?') options: QueryContextOfAsset,
    @Args('extendedVariables') extendedVariables: any,
  ): Observable<ServiceListResultOfAsset> {
    let loanableFilter: FilterOfAsset = {
      loanable: {
        eq: true,
      },
    };

    if (filter != undefined) {
      loanableFilter.and = [filter];
    }

    return this.find(fields, options, loanableFilter, extendedVariables);
  }

  public static assetModelFields(): Array<GqlField | GqlSubField> {
    return [
      GqlSubField.create('data', [
        GqlField.create('assetCategoryId'),
        GqlField.create('manufacturerId'),
        GqlField.create('modelId'),
        GqlField.create('id'),
      ]),
    ];
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findAssets(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('myAsset') myAsset: boolean,
    @Args('options?') options?: QueryContextOfAsset,
    @Args('filter?') filter?: FilterOfAsset,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAsset> {
    if (myAsset === true) {
      return this.findMyAssets(fields, options, filter, extendedVariables);
    } else {
      return this.find(fields, options, filter, extendedVariables);
    }
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findRecyclesAssets(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('myAsset') myAsset: boolean,
    @Args('options?') options?: QueryContextOfAsset,
    @Args('filter?') filter?: FilterOfAsset,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAsset> {
    if (myAsset === true) {
      return this.findMyRecycleAssets(
        fields,
        options,
        filter,
        extendedVariables,
      );
    } else {
      return this.findRecycles(fields, options, filter, extendedVariables);
    }
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public countRecyclesAssets(
    @Args('myAsset') myAsset: boolean,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('filter?') filter?: FilterOfAsset,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfInt64> {
    if (myAsset === true) {
      return this.countMyRecycleAssets(fields, extendedVariables);
    } else {
      return this.countRecycles(fields, filter, extendedVariables);
    }
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('assetCategoryId'),
        GqlField.create('name'),
        GqlSubField.create('assetCategory', [
          GqlSubField.create('data', [
            GqlSubField.create(
              'name',
              this.translatedFieldHelperService.translatedFields(),
            ),
          ]),
        ]),
        GqlField.create('ipAddress'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  /** Obtient les filtres par défaut */
  async filters(
    @Args('source') source: string,
    @Args('method') method: string,
  ): Promise<Filter[]> {
    let filters: Filter[];

    if (source == undefined || method == undefined) {
      return [];
    }

    filters = [
      {
        items: [
          {
            text: TranslateService.get('globals/all'),
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'all',
              },
            },
          },
        ],
      },
      {
        text: TranslateService.get('common/byRelation'),
        items: [
          {
            text: TranslateService.get('entities/assetCategory/_title/plural'),
            source: await (<any>(
              this.modelCompilerContextService
                .source('AssetCategoryCoreService', 'findAssetCategories')
                .toPromise()
            )),
            filterExpr: 'assetCategoryId',
            parentIdExpr: 'parentId',
            keyExpr: 'id',
            displayExpr: 'name',
            multiple: true,
            recursive: true,
            translatable: true,
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'asset-category',
              },
            },
          },
          {
            text: TranslateService.get('entities/location/_title/plural'),
            source: await (<any>(
              this.modelCompilerContextService
                .source('LocationCoreService', 'find')
                .toPromise()
            )),
            keyExpr: 'id',
            filterExpr: 'locationId',
            displayExpr: 'name',
            translatable: true,
            parentIdExpr: 'parentId',
            multiple: true,
            recursive: true,
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'location',
              },
            },
          },
          {
            text: TranslateService.get(
              'entities/organizationalUnit/_title/plural',
            ),
            source: await (<any>(
              this.modelCompilerContextService
                .source('OrganizationalUnitCoreService', 'find')
                .toPromise()
            )),
            filterExpr: 'organizationalUnitId',
            keyExpr: 'id',
            parentIdExpr: 'parentId',
            displayExpr: 'name',
            multiple: true,
            recursive: true,
            translatable: true,
            list: {
              source: await (<any>(
                this.modelCompilerContextService
                  .source(source, method)
                  .toPromise()
              )),
              parameters: {
                type: 'organizational-unit',
              },
            },
          },
        ],
      },
    ];

    return filters;
  }

  //TODO Optimize
  private s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }

  public createHTMLFile(filename: string, data: any) {
    const element = document.createElement('a');
    let type = 'text/html';
    let extension = '.html';

    //Création du BinaryLargeObject comprenant le fichier encodé
    const blob = new Blob([this.s2ab(atob(data))], {
      type: type,
    });

    const date = new Date();
    const dateString =
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2);

    element.href = URL.createObjectURL(blob);
    element.download = filename + '_print_' + dateString + extension;

    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      element.remove();
    });
  }

  /** Définit la commande automatique à exécuter pour un contrôle à distance */
  public getAutomaticRemoteCommand(assetId: string) {
    let result: {
      defaultCommandId: string;
      autoLaunchCommand: boolean;
      disabledButton: boolean;
    };

    return this.get(
      [
        GqlSubField.create('data', [
          GqlSubField.create('assetCategory', [
            GqlSubField.create('data', [
              GqlField.create('defaultCommandId'),
              GqlField.create('autoLaunchCommand'),
            ]),
          ]),
        ]),
      ],
      assetId,
    ).pipe(
      map((response) => {
        let defaultCommandId =
          response?.data?.assetCategory?.data?.defaultCommandId;

        let autoLaunchCommand =
          response?.data?.assetCategory?.data?.autoLaunchCommand;

        let disabledButton = true;
        if (defaultCommandId != null) {
          disabledButton = false;
        }

        result = {
          defaultCommandId: defaultCommandId,
          autoLaunchCommand: autoLaunchCommand,
          disabledButton: disabledButton,
        };

        return result;
      }),
    );
  }

  @InjectArgs
  public existSerialNumberAssets(
    @Args('value') value,
    @Args('id?') id?: string,
  ): Observable<ServiceSingleResultOfBoolean> {
    return this.exist(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      undefined,
      undefined,
      undefined,
      value,
      'serialNumber',
      id,
    );
  }
  /** Fonction de génération de Code barre */
  async generateBarcode(
    idAsset,
    options?: {
      height?: number;
      width?: number;
      format?: string;
    },
    logo?,
  ): Promise<any> {
    const asset = await this.get(
      [
        GqlSubField.create('data', [
          GqlField.create('serialNumber'),
          GqlField.create('barCode'),
          GqlField.create('inventoryNumber'),
        ]),
      ],
      idAsset,
    )
      .toPromise()
      .then(async (x) => {
        if (x.data.serialNumber !== undefined && x.data.serialNumber !== '') {
          return x.data.serialNumber;
        } else if (x.data.barCode !== undefined && x.data.barCode !== '') {
          return x.data.barCode;
        } else if (
          x.data.inventoryNumber !== undefined &&
          x.data.inventoryNumber !== ''
        ) {
          return x.data.inventoryNumber;
        } else {
          return ''; // Retourne null si aucune des propriétés n'est définie ou vide
        }
      });

    //format CODE_128 to code128
    const format = options?.format
      ? options.format.toLowerCase().replace('_', '')
      : 'code128';
    const px2mmFactor = this.calcPx2MmFactor(); // 1 px = 0.352778 mm
    // Options du code barre (cf: https://lindell.me/JsBarcode/#options)
    const barcodeOptions = {
      format: format,
      height: 10 * px2mmFactor,
      width: 2,
      text: asset != null ? asset : '',
      background: '#FFFFFF',
      font: 'monospace',
      fontSize: 16,
      lineColor: '#000000',
      margin: 5 * px2mmFactor,
      textMargin: 0,
    };

    const barcodeElement = document.createElement('svg');
    barcodeElement.id = 'barcode';

    JsBarcode(barcodeElement, asset, barcodeOptions);

    // Convertir l'élément <svg> en une chaîne de texte SVG
    const svgString = new XMLSerializer().serializeToString(barcodeElement);
    // Création du contenu HTML avec le code-barres SVG
    const htmlContent = `${svgString}`;
    return htmlContent;
  }

  /** Calcul le facteur de conversion entre les pixels et les millimètres */
  private calcPx2MmFactor() {
    // Création d'un élément div temporaire
    let e = document.createElement('div');
    // Positionnement absolu pour obtenir une mesure exacte
    e.style.position = 'absolute';
    // Définition de la largeur de l'élément à 100mm pour calculer le facteur de conversion
    e.style.width = '100mm';
    // Ajout de l'élément temporaire au corps du document pour l'affichage
    document.body.appendChild(e);
    // Récupération des dimensions de l'élément dans le rectangle
    let rect = e.getBoundingClientRect();
    // Suppression de l'élément temporaire une fois les dimensions récupérées
    document.body.removeChild(e);
    // Calcul du facteur de conversion entre pixels et millimètres
    // en divisant la largeur du rectangle par 100mm
    return rect.width / 100;
  }
}
