import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfLdapAnalyse, FilterOfLdapAnalyse, QueryContextOfLdapAnalyse, ServiceSingleResultOfInt64, ServiceListResultOfLdapAnalyse, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LdapObjectClassType, LdapAnalyseInput, FieldUpdateOperatorOfLdapAnalyse } from '../types'

export type GetLdapAnalysesResultType = {
    ldapAnalyses: {
        get?: Maybe<ServiceSingleResultOfLdapAnalyse>
    }
}

export type GetLdapAnalysesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLdapAnalyses ${args} {
    ldapAnalyses {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstLdapAnalysesResultType = {
    ldapAnalyses: {
        first?: Maybe<ServiceSingleResultOfLdapAnalyse>
    }
}

export type FirstLdapAnalysesBaseVariables = {
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstLdapAnalyses ${args} {
    ldapAnalyses {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountLdapAnalysesResultType = {
    ldapAnalyses: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountLdapAnalysesBaseVariables = {
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countLdapAnalyses ${args} {
    ldapAnalyses {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLdapAnalysesResultType = {
    ldapAnalyses: {
        find?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type FindLdapAnalysesBaseVariables = {
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfLdapAnalyse = null, $filter: FilterOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLdapAnalyses ${args} {
    ldapAnalyses {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchLdapAnalysesResultType = {
    ldapAnalyses: {
        search?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type SearchLdapAnalysesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchLdapAnalyses ${args} {
    ldapAnalyses {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaLdapAnalysesResultType = {
    ldapAnalyses: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaLdapAnalysesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaLdapAnalyses ${args} {
    ldapAnalyses {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataLdapAnalysesResultType = {
    ldapAnalyses: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataLdapAnalysesBaseVariables = {
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataLdapAnalyses ${args} {
    ldapAnalyses {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryLdapAnalysesResultType = {
    ldapAnalyses: {
        customQuery?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type CustomQueryLdapAnalysesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryLdapAnalyses ${args} {
    ldapAnalyses {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdLdapAnalysesResultType = {
    ldapAnalyses: {
        customQueryId?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type CustomQueryIdLdapAnalysesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdLdapAnalyses ${args} {
    ldapAnalyses {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedLdapAnalysesResultType = {
    ldapAnalyses: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedLdapAnalysesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedLdapAnalyses ${args} {
    ldapAnalyses {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CancelLdapAssetLdapAnalysesResultType = {
    ldapAnalyses: {
        cancelLdapAsset?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CancelLdapAssetLdapAnalysesBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class CancelLdapAssetLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query cancelLdapAssetLdapAnalyses ${args} {
    ldapAnalyses {
        cancelLdapAsset {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CancelLdapAssetLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CancelLdapAssetLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CancelLdapUserLdapAnalysesResultType = {
    ldapAnalyses: {
        cancelLdapUser?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CancelLdapUserLdapAnalysesBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class CancelLdapUserLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query cancelLdapUserLdapAnalyses ${args} {
    ldapAnalyses {
        cancelLdapUser {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CancelLdapUserLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CancelLdapUserLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CompareLdapAssetLdapAnalysesResultType = {
    ldapAnalyses: {
        compareLdapAsset?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CompareLdapAssetLdapAnalysesBaseVariables = {
	distinguishedName: boolean, /**  */
	netbios: boolean, /**  */
	name: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CompareLdapAssetLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$distinguishedName: Boolean!, $netbios: Boolean!, $name: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query compareLdapAssetLdapAnalyses ${args} {
    ldapAnalyses {
        compareLdapAsset(distinguishedName: $distinguishedName, netbios: $netbios, name: $name) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CompareLdapAssetLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CompareLdapAssetLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CompareLdapUserLdapAnalysesResultType = {
    ldapAnalyses: {
        compareLdapUser?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CompareLdapUserLdapAnalysesBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CompareLdapUserLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query compareLdapUserLdapAnalyses ${args} {
    ldapAnalyses {
        compareLdapUser(ldapObjectClassType: $ldapObjectClassType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CompareLdapUserLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CompareLdapUserLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetLdapLdapAnalysesResultType = {
    ldapAnalyses: {
        findAssetLdap?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type FindAssetLdapLdapAnalysesBaseVariables = {
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAssetLdapLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetLdapLdapAnalyses ${args} {
    ldapAnalyses {
        findAssetLdap(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetLdapLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetLdapLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLdapAssetLdapAnalysesResultType = {
    ldapAnalyses: {
        findLdapAsset?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type FindLdapAssetLdapAnalysesBaseVariables = {
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLdapAssetLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLdapAssetLdapAnalyses ${args} {
    ldapAnalyses {
        findLdapAsset(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLdapAssetLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLdapAssetLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLdapUserLdapAnalysesResultType = {
    ldapAnalyses: {
        findLdapUser?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type FindLdapUserLdapAnalysesBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLdapUserLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!, $filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLdapUserLdapAnalyses ${args} {
    ldapAnalyses {
        findLdapUser(ldapObjectClassType: $ldapObjectClassType, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLdapUserLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLdapUserLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUserLdapLdapAnalysesResultType = {
    ldapAnalyses: {
        findUserLdap?: Maybe<ServiceListResultOfLdapAnalyse>
    }
}

export type FindUserLdapLdapAnalysesBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
	filter: Maybe<FilterOfLdapAnalyse>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapAnalyse>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUserLdapLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!, $filter: FilterOfLdapAnalyse = null, $options: QueryContextOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUserLdapLdapAnalyses ${args} {
    ldapAnalyses {
        findUserLdap(ldapObjectClassType: $ldapObjectClassType, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUserLdapLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUserLdapLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type IsProgressLdapAnalysesResultType = {
    ldapAnalyses: {
        isProgress?: Maybe<ServiceSingleResultOfLdapAnalyse>
    }
}

export type IsProgressLdapAnalysesBaseVariables = {
	ldapObjectClassType: LdapObjectClassType, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class IsProgressLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapObjectClassType: LdapObjectClassType!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query isProgressLdapAnalyses ${args} {
    ldapAnalyses {
        isProgress(ldapObjectClassType: $ldapObjectClassType) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: IsProgressLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<IsProgressLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistLdapAnalysesResultType = {
    ldapAnalyses: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistLdapAnalysesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existLdapAnalyses ${args} {
    ldapAnalyses {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertLdapAnalysesResultType = {
    ldapAnalyses: {
        insert?: Maybe<ServiceSingleResultOfLdapAnalyse>
    }
}

export type InsertLdapAnalysesBaseVariables = {
	entity: LdapAnalyseInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: LdapAnalyseInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertLdapAnalyses ${args} {
    ldapAnalyses {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateLdapAnalysesResultType = {
    ldapAnalyses: {
        update?: Maybe<ServiceSingleResultOfLdapAnalyse>
    }
}

export type UpdateLdapAnalysesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfLdapAnalyse>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateLdapAnalyses ${args} {
    ldapAnalyses {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLdapAnalyse", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataLdapAnalysesResultType = {
    ldapAnalyses: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataLdapAnalysesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataLdapAnalyses ${args} {
    ldapAnalyses {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyLdapAnalysesResultType = {
    ldapAnalyses: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyLdapAnalysesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfLdapAnalyse>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfLdapAnalyse = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyLdapAnalyses ${args} {
    ldapAnalyses {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteLdapAnalysesResultType = {
    ldapAnalyses: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteLdapAnalysesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteLdapAnalyses ${args} {
    ldapAnalyses {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CreateAssetsLdapAnalysesResultType = {
    ldapAnalyses: {
        createAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CreateAssetsLdapAnalysesBaseVariables = {
	ldapAnalyseIds: Array<string>, /**  */
	assetCategoryId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CreateAssetsLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapAnalyseIds: [Uuid!], $assetCategoryId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation createAssetsLdapAnalyses ${args} {
    ldapAnalyses {
        createAssets(ldapAnalyseIds: $ldapAnalyseIds, assetCategoryId: $assetCategoryId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CreateAssetsLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CreateAssetsLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteObjectsLdapAnalysesResultType = {
    ldapAnalyses: {
        deleteObjects?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteObjectsLdapAnalysesBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DeleteObjectsLdapAnalysesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteObjectsLdapAnalyses ${args} {
    ldapAnalyses {
        deleteObjects(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteObjectsLdapAnalysesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteObjectsLdapAnalysesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
