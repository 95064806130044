import { Injectable } from '@angular/core';
import { Unsubscribable } from 'rxjs';
/** Le type de la ressource. */
export enum GCType {
  Release,
  Dispose,
}
/** L'instance de la resource. */
export type GCInstance = { key: Unsubscribable; type?: GCType };
/** Représente une classe permettant de libérer des ressources automatiquements. */
export class GC {
  instances: GCInstance[] = [];
  forRelease(obj: Unsubscribable) {
    this.instances.push({ key: obj, type: GCType.Release });
  }
  forDispose(obj: Unsubscribable) {
    this.instances.push({ key: obj, type: GCType.Dispose });
  }
  /** Libère les ressources marquées comme release. */
  release() {
    let releaseIndexes = [];
    for (let instance of this.instances) {
      if (GCType.Release === instance.type) {
        instance.key?.unsubscribe();
        releaseIndexes.push(this.instances.indexOf(instance));
      }
    }
    for (let index of releaseIndexes) {
      this.instances.splice(index, 1);
    }
  }
  /** Libère toutes les resources. */
  dispose() {
    for (let instance of this.instances) {
      instance.key?.unsubscribe();
    }
  }
}
/** Permet de créer un Garbage Collector. */
@Injectable({
  providedIn: 'root',
})
export class GCFactory {
  create(): GC {
    return new GC();
  }
}
