<clc-work-item-form
  *ngIf="model != undefined"
  [mode]="mode"
  [formGroup]="form"
  [title]="!readOnly ? title : title"
  [attributesInfo]="attributesInfo"
  [items]="items"
  (onSubmit)="save($event)"
  (onClosed)="close($event)"
  [errors]="errors"
  [savePolicies]="savePolicies"
  [saveSplitButton]="saveSplitButton"
  [onlySaveButton]="onlySaveButton"
  [showBack]="showBack"
  [showSave]="showSave && !readOnly"
  [saveTilte]="saveTilte"
  [readOnly]="readOnly"
  [state]="model"
  [userName]="userName"
  [readOnlyAttribute]="readOnlyAttribute"
  [displaySaveIcon]="displaySaveIcon"
  [displayReadOnlyMsg]="displayReadOnlyMsg"
  [enableMobileMode]="enableMobileMode"
  [backMode]="backMode"
>
  <cl-toolbar-items [items]="toolbar?.itemsChildren?.toArray()">
    <cl-toolbar-item-button
      [text]="translateChooseFieldTitle"
      [icon]="'fal fa-globe'"
      *ngIf="model?.model?.form?.layout?.hasTranslatedModule === true"
      (onClick)="isTranslatePopupVisible = true"
    >
    </cl-toolbar-item-button>
  </cl-toolbar-items>
  <ng-content select="cl-work-item-form-system"></ng-content>

  <cl-work-item-form-system
    *ngIf="
      model != undefined &&
      model.model.form.layout.systemControls?.controls != undefined
    "
  >
    <!-- <clc-work-item-control-dynamic [controls]="model.model.form.layout.systemControls.controls" [mode]="'line'">
    </clc-work-item-control-dynamic> -->
  </cl-work-item-form-system>
</clc-work-item-form>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!form.root.loaded || loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>

<dx-popup
  [width]="600"
  [height]="'auto'"
  [showTitle]="false"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="isTranslatePopupVisible"
  [showCloseButton]="false"
>
  <cl-work-page>
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title [showBack]="false">
          {{ 'entities/translatedField/chooseLanguage' | translate }}
        </cl-work-page-header-content-title>
        <cl-work-page-header-content-body>
          <div style="padding: 10px; text-align: right">
            <dx-button
              [text]="'validate' | translate"
              type="default"
              [useSubmitBehavior]="true"
              style="background-color: var(--secondary) !important"
              (onClick)="changeLanguageTranslation()"
            >
            </dx-button>
          </div>
        </cl-work-page-header-content-body>
      </cl-work-page-header-content>
    </cl-work-page-header>
    <cl-work-page-content>
      <div style="padding: 10px">
        <div class="item-field">
          <div class="label" style="padding-bottom: 5px; padding-top: 5px">
            {{ 'entities/translatedField/chooseLanguage' | translate }}
          </div>
          <dx-select-box
            valueExpr="key"
            displayExpr="value"
            [dataSource]="translateDataSource"
            [value]="selectedLanguage"
            (onValueChanged)="onSelectLanguageValueChanged($event)"
          >
          </dx-select-box>
        </div>
      </div>
    </cl-work-page-content>
  </cl-work-page>
  <!-- <div class="close-btn">
        <dx-button icon="fal fa-times" (onClick)="validate()"></dx-button>
    </div> -->
</dx-popup>
