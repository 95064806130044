import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketStatusReasonsBaseVariables, FirstTicketStatusReasonsBaseVariables, CountTicketStatusReasonsBaseVariables, FindTicketStatusReasonsBaseVariables, SearchTicketStatusReasonsBaseVariables, ExportSchemaTicketStatusReasonsBaseVariables, ExportDataTicketStatusReasonsBaseVariables, CustomQueryTicketStatusReasonsBaseVariables, CustomQueryIdTicketStatusReasonsBaseVariables, FindUnassociatedSecurityGroupsTicketStatusReasonsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsBaseVariables, UsedTicketStatusReasonsBaseVariables, ExistTicketStatusReasonsBaseVariables, FindRecyclesTicketStatusReasonsBaseVariables, CountRecyclesTicketStatusReasonsBaseVariables, ReadOnlyTicketStatusReasonsBaseVariables, FindArchivedTicketStatusReasonsBaseVariables, CountAllTicketStatusReasonsBaseVariables, FindDynamicPropertyFieldsTicketStatusReasonsBaseVariables, FindUnassociatedTicketsTicketStatusReasonsBaseVariables, InsertTicketStatusReasonsBaseVariables, UpdateTicketStatusReasonsBaseVariables, ImportDataTicketStatusReasonsBaseVariables, UpdateManyTicketStatusReasonsBaseVariables, DeleteTicketStatusReasonsBaseVariables, AddSecurityGroupTicketStatusReasonsBaseVariables, RemoveSecurityGroupTicketStatusReasonsBaseVariables, AddOperatorTeamSecurityGroupTicketStatusReasonsBaseVariables, RemoveOperatorTeamSecurityGroupTicketStatusReasonsBaseVariables, RestoreTicketStatusReasonsBaseVariables, RecycleTicketStatusReasonsBaseVariables, RestoreArchivedTicketStatusReasonsBaseVariables, ArchivedTicketStatusReasonsBaseVariables, AddFileDynamicFieldTicketStatusReasonsBaseVariables, RemoveFileDynamicFieldTicketStatusReasonsBaseVariables, AddTicketsTicketStatusReasonsBaseVariables, RemoveTicketsTicketStatusReasonsBaseVariables } from '../gqls'
import { GetTicketStatusReasonsDocument, FirstTicketStatusReasonsDocument, CountTicketStatusReasonsDocument, FindTicketStatusReasonsDocument, SearchTicketStatusReasonsDocument, ExportSchemaTicketStatusReasonsDocument, ExportDataTicketStatusReasonsDocument, CustomQueryTicketStatusReasonsDocument, CustomQueryIdTicketStatusReasonsDocument, FindUnassociatedSecurityGroupsTicketStatusReasonsDocument, FindAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsDocument, UsedTicketStatusReasonsDocument, ExistTicketStatusReasonsDocument, FindRecyclesTicketStatusReasonsDocument, CountRecyclesTicketStatusReasonsDocument, ReadOnlyTicketStatusReasonsDocument, FindArchivedTicketStatusReasonsDocument, CountAllTicketStatusReasonsDocument, FindDynamicPropertyFieldsTicketStatusReasonsDocument, FindUnassociatedTicketsTicketStatusReasonsDocument, InsertTicketStatusReasonsDocument, UpdateTicketStatusReasonsDocument, ImportDataTicketStatusReasonsDocument, UpdateManyTicketStatusReasonsDocument, DeleteTicketStatusReasonsDocument, AddSecurityGroupTicketStatusReasonsDocument, RemoveSecurityGroupTicketStatusReasonsDocument, AddOperatorTeamSecurityGroupTicketStatusReasonsDocument, RemoveOperatorTeamSecurityGroupTicketStatusReasonsDocument, RestoreTicketStatusReasonsDocument, RecycleTicketStatusReasonsDocument, RestoreArchivedTicketStatusReasonsDocument, ArchivedTicketStatusReasonsDocument, AddFileDynamicFieldTicketStatusReasonsDocument, RemoveFileDynamicFieldTicketStatusReasonsDocument, AddTicketsTicketStatusReasonsDocument, RemoveTicketsTicketStatusReasonsDocument } from '../gqls'
import { ServiceSingleResultOfTicketStatusReason, QueryContextOfTicketStatusReason, FilterOfTicketStatusReason, ServiceSingleResultOfInt64, ServiceListResultOfTicketStatusReason, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketStatusReason, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, TicketStatusReasonInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketStatusReasonBaseService {
    
public modelName = 'ticketStatusReason';
public modelPluralName = 'ticketStatusReasons';

	private getTicketStatusReasonsQuery: GetTicketStatusReasonsDocument;
	private firstTicketStatusReasonsQuery: FirstTicketStatusReasonsDocument;
	private countTicketStatusReasonsQuery: CountTicketStatusReasonsDocument;
	private findTicketStatusReasonsQuery: FindTicketStatusReasonsDocument;
	private searchTicketStatusReasonsQuery: SearchTicketStatusReasonsDocument;
	private exportSchemaTicketStatusReasonsQuery: ExportSchemaTicketStatusReasonsDocument;
	private exportDataTicketStatusReasonsQuery: ExportDataTicketStatusReasonsDocument;
	private customQueryTicketStatusReasonsQuery: CustomQueryTicketStatusReasonsDocument;
	private customQueryIdTicketStatusReasonsQuery: CustomQueryIdTicketStatusReasonsDocument;
	private findUnassociatedSecurityGroupsTicketStatusReasonsQuery: FindUnassociatedSecurityGroupsTicketStatusReasonsDocument;
	private findAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsQuery: FindAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsDocument;
	private usedTicketStatusReasonsQuery: UsedTicketStatusReasonsDocument;
	private existTicketStatusReasonsQuery: ExistTicketStatusReasonsDocument;
	private findRecyclesTicketStatusReasonsQuery: FindRecyclesTicketStatusReasonsDocument;
	private countRecyclesTicketStatusReasonsQuery: CountRecyclesTicketStatusReasonsDocument;
	private readOnlyTicketStatusReasonsQuery: ReadOnlyTicketStatusReasonsDocument;
	private findArchivedTicketStatusReasonsQuery: FindArchivedTicketStatusReasonsDocument;
	private countAllTicketStatusReasonsQuery: CountAllTicketStatusReasonsDocument;
	private findDynamicPropertyFieldsTicketStatusReasonsQuery: FindDynamicPropertyFieldsTicketStatusReasonsDocument;
	private findUnassociatedTicketsTicketStatusReasonsQuery: FindUnassociatedTicketsTicketStatusReasonsDocument;
	private insertTicketStatusReasonsMutation: InsertTicketStatusReasonsDocument;
	private updateTicketStatusReasonsMutation: UpdateTicketStatusReasonsDocument;
	private importDataTicketStatusReasonsMutation: ImportDataTicketStatusReasonsDocument;
	private updateManyTicketStatusReasonsMutation: UpdateManyTicketStatusReasonsDocument;
	private deleteTicketStatusReasonsMutation: DeleteTicketStatusReasonsDocument;
	private addSecurityGroupTicketStatusReasonsMutation: AddSecurityGroupTicketStatusReasonsDocument;
	private removeSecurityGroupTicketStatusReasonsMutation: RemoveSecurityGroupTicketStatusReasonsDocument;
	private addOperatorTeamSecurityGroupTicketStatusReasonsMutation: AddOperatorTeamSecurityGroupTicketStatusReasonsDocument;
	private removeOperatorTeamSecurityGroupTicketStatusReasonsMutation: RemoveOperatorTeamSecurityGroupTicketStatusReasonsDocument;
	private restoreTicketStatusReasonsMutation: RestoreTicketStatusReasonsDocument;
	private recycleTicketStatusReasonsMutation: RecycleTicketStatusReasonsDocument;
	private restoreArchivedTicketStatusReasonsMutation: RestoreArchivedTicketStatusReasonsDocument;
	private archivedTicketStatusReasonsMutation: ArchivedTicketStatusReasonsDocument;
	private addFileDynamicFieldTicketStatusReasonsMutation: AddFileDynamicFieldTicketStatusReasonsDocument;
	private removeFileDynamicFieldTicketStatusReasonsMutation: RemoveFileDynamicFieldTicketStatusReasonsDocument;
	private addTicketsTicketStatusReasonsMutation: AddTicketsTicketStatusReasonsDocument;
	private removeTicketsTicketStatusReasonsMutation: RemoveTicketsTicketStatusReasonsDocument;

	constructor(private injector: Injector) {
		this.getTicketStatusReasonsQuery = this.injector.get(GetTicketStatusReasonsDocument);
		this.firstTicketStatusReasonsQuery = this.injector.get(FirstTicketStatusReasonsDocument);
		this.countTicketStatusReasonsQuery = this.injector.get(CountTicketStatusReasonsDocument);
		this.findTicketStatusReasonsQuery = this.injector.get(FindTicketStatusReasonsDocument);
		this.searchTicketStatusReasonsQuery = this.injector.get(SearchTicketStatusReasonsDocument);
		this.exportSchemaTicketStatusReasonsQuery = this.injector.get(ExportSchemaTicketStatusReasonsDocument);
		this.exportDataTicketStatusReasonsQuery = this.injector.get(ExportDataTicketStatusReasonsDocument);
		this.customQueryTicketStatusReasonsQuery = this.injector.get(CustomQueryTicketStatusReasonsDocument);
		this.customQueryIdTicketStatusReasonsQuery = this.injector.get(CustomQueryIdTicketStatusReasonsDocument);
		this.findUnassociatedSecurityGroupsTicketStatusReasonsQuery = this.injector.get(FindUnassociatedSecurityGroupsTicketStatusReasonsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsDocument);
		this.usedTicketStatusReasonsQuery = this.injector.get(UsedTicketStatusReasonsDocument);
		this.existTicketStatusReasonsQuery = this.injector.get(ExistTicketStatusReasonsDocument);
		this.findRecyclesTicketStatusReasonsQuery = this.injector.get(FindRecyclesTicketStatusReasonsDocument);
		this.countRecyclesTicketStatusReasonsQuery = this.injector.get(CountRecyclesTicketStatusReasonsDocument);
		this.readOnlyTicketStatusReasonsQuery = this.injector.get(ReadOnlyTicketStatusReasonsDocument);
		this.findArchivedTicketStatusReasonsQuery = this.injector.get(FindArchivedTicketStatusReasonsDocument);
		this.countAllTicketStatusReasonsQuery = this.injector.get(CountAllTicketStatusReasonsDocument);
		this.findDynamicPropertyFieldsTicketStatusReasonsQuery = this.injector.get(FindDynamicPropertyFieldsTicketStatusReasonsDocument);
		this.findUnassociatedTicketsTicketStatusReasonsQuery = this.injector.get(FindUnassociatedTicketsTicketStatusReasonsDocument);
		this.insertTicketStatusReasonsMutation = this.injector.get(InsertTicketStatusReasonsDocument);
		this.updateTicketStatusReasonsMutation = this.injector.get(UpdateTicketStatusReasonsDocument);
		this.importDataTicketStatusReasonsMutation = this.injector.get(ImportDataTicketStatusReasonsDocument);
		this.updateManyTicketStatusReasonsMutation = this.injector.get(UpdateManyTicketStatusReasonsDocument);
		this.deleteTicketStatusReasonsMutation = this.injector.get(DeleteTicketStatusReasonsDocument);
		this.addSecurityGroupTicketStatusReasonsMutation = this.injector.get(AddSecurityGroupTicketStatusReasonsDocument);
		this.removeSecurityGroupTicketStatusReasonsMutation = this.injector.get(RemoveSecurityGroupTicketStatusReasonsDocument);
		this.addOperatorTeamSecurityGroupTicketStatusReasonsMutation = this.injector.get(AddOperatorTeamSecurityGroupTicketStatusReasonsDocument);
		this.removeOperatorTeamSecurityGroupTicketStatusReasonsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupTicketStatusReasonsDocument);
		this.restoreTicketStatusReasonsMutation = this.injector.get(RestoreTicketStatusReasonsDocument);
		this.recycleTicketStatusReasonsMutation = this.injector.get(RecycleTicketStatusReasonsDocument);
		this.restoreArchivedTicketStatusReasonsMutation = this.injector.get(RestoreArchivedTicketStatusReasonsDocument);
		this.archivedTicketStatusReasonsMutation = this.injector.get(ArchivedTicketStatusReasonsDocument);
		this.addFileDynamicFieldTicketStatusReasonsMutation = this.injector.get(AddFileDynamicFieldTicketStatusReasonsDocument);
		this.removeFileDynamicFieldTicketStatusReasonsMutation = this.injector.get(RemoveFileDynamicFieldTicketStatusReasonsDocument);
		this.addTicketsTicketStatusReasonsMutation = this.injector.get(AddTicketsTicketStatusReasonsDocument);
		this.removeTicketsTicketStatusReasonsMutation = this.injector.get(RemoveTicketsTicketStatusReasonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatusReason> {
    
    		let variables: GetTicketStatusReasonsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.get));
            }
            else{
                let result:ServiceSingleResultOfTicketStatusReason={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatusReason> {
    
    		let variables: FirstTicketStatusReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketStatusReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: FindTicketStatusReasonsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: SearchTicketStatusReasonsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketStatusReasonsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketStatusReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: CustomQueryTicketStatusReasonsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: CustomQueryIdTicketStatusReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsTicketStatusReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketStatusReasonsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: FindRecyclesTicketStatusReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTicketStatusReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTicketStatusReasonsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketStatusReason,
		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketStatusReason> {
    
    		let variables: FindArchivedTicketStatusReasonsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTicketStatusReasonsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTicketStatusReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsTicketStatusReasonsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsTicketStatusReasonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.findUnassociatedTickets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketStatusReasonInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatusReason> {
    
    		let variables: InsertTicketStatusReasonsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketStatusReason> {
    
    		let variables: UpdateTicketStatusReasonsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketStatusReasonsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketStatusReason,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketStatusReasonsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupTicketStatusReasonsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupTicketStatusReasonsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupTicketStatusReasonsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupTicketStatusReasonsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.removeOperatorTeamSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTicketStatusReasonsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTicketStatusReasonsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTicketStatusReasonsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsTicketStatusReasonsBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsTicketStatusReasonsBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsTicketStatusReasonsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketStatusReasons.removeTickets));
    	}
    
}