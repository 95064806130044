<dx-box  direction="row">
    <dxi-item [ratio]="0" [baseSize]="70">      
    </dxi-item>    
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">{{'entities/workTime/start' | translate}}</span>
    </dxi-item>
    <dxi-item  [ratio]="0" [baseSize]="5"> 
        <span class="cl-text-center"></span>
    </dxi-item>
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">{{'entities/workTime/end' | translate}}</span>
    </dxi-item>
    <dxi-item  [ratio]="0" [baseSize]="20"></dxi-item>
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">{{'entities/workTime/start' | translate}}</span>
    </dxi-item>
    <dxi-item  [ratio]="0" [baseSize]="5"> 
        <span class="cl-text-center"></span>
    </dxi-item>
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">{{'entities/workTime/end' | translate}}</span>
    </dxi-item>
</dx-box>

<ng-container *ngFor="let day of days" >
    <dx-box   direction="row" class="cl-box-main"  id={{day.id}}  (onInitialized)="initializeBox($event)">    
        <dxi-item [ratio]="0" [baseSize]="70">
            <div class="cl-day-center">              
                <dx-button
                stylingMode="outlined"
                [text]="day.value[0]"
                type="default"
                width= 30  
                height=100%
                id={{day.id}}  
                (onInitialized)="initializeBtn($event)"
                (onClick)="clickBtn($event)">
            </dx-button>
            </div>
        </dxi-item>    
        <dxi-item  [ratio]="2">
            <dx-date-box  type="time" id={{day.id}}_1 [useMaskBehavior]="true" disabled="true"
            [showClearButton]="true" (onInitialized)="initializeDateBox($event)"  [acceptCustomValue]="true"(onValueChanged)="valueTimeChanged($event)">
                <dx-validator>
                    <dxi-validation-rule                         
                        type="custom" 
                        [validationCallback]="validateTimeCallback()"        
                        [message]="'entities/workTime/timeInferior' | translate">
                    </dxi-validation-rule>   
                </dx-validator>  
            </dx-date-box>             
        </dxi-item>
        <dxi-item  [ratio]="0" [baseSize]="5"> 
            <span class="cl-text-center"></span>
        </dxi-item>
        <dxi-item  [ratio]="2" >
            <dx-date-box  type="time" id={{day.id}}_2 [showClearButton]="true" disabled="true" [useMaskBehavior]="true"
            (onInitialized)="initializeDateBox($event)" [acceptCustomValue]="true" (onValueChanged)="valueTimeChanged($event)">
                <dx-validator>
                    <dxi-validation-rule                         
                        type="custom" 
                        [validationCallback]="validateTimeCallback()"        
                        [message]="'entities/workTime/timeSuperior' | translate">
                    </dxi-validation-rule>   
                </dx-validator>  
            </dx-date-box>
        </dxi-item>scope
        <dxi-item  [ratio]="0" [baseSize]="20"></dxi-item>
        <dxi-item  [ratio]="2">
            <dx-date-box  type="time" id={{day.id}}_3 [showClearButton]="true" disabled="true" [useMaskBehavior]="true"
            (onInitialized)="initializeDateBox($event)" [acceptCustomValue]="true"(onValueChanged)="valueTimeChanged($event)">
                <dx-validator>
                    <dxi-validation-rule                         
                        type="custom" 
                        [validationCallback]="validateTimeCallback()"        
                        [message]="'entities/workTime/timeSuperior' | translate">
                    </dxi-validation-rule>   
                </dx-validator>  
            </dx-date-box>
        </dxi-item>
        <dxi-item  [ratio]="0" [baseSize]="5"> 
            <span class="cl-text-center"></span>
        </dxi-item>
        <dxi-item  [ratio]="2">
            <dx-date-box  type="time" id={{day.id}}_4 [showClearButton]="true" disabled="true" [useMaskBehavior]="true"
            (onInitialized)="initializeDateBox($event)" [acceptCustomValue]="true"(onValueChanged)="valueTimeChanged($event)">
                <dx-validator>
                    <dxi-validation-rule                         
                        type="custom" 
                        [validationCallback]="validateTimeCallback()"        
                        [message]="'entities/workTime/timeSuperior' | translate">
                    </dxi-validation-rule>   
                </dx-validator>  
            </dx-date-box>
        </dxi-item>
        <dxi-item  [ratio]="0" [baseSize]="30"> 
            <dx-button  *ngIf="day.id === 'monday'"          
            icon="fal fa-clone"
            [hint]="'entities/workTime/copyTime' | translate"
            [width]="15"
            (onClick)="copyTime($event)"                
            >
        </dx-button>
        </dxi-item>
    </dx-box>   
</ng-container>