<dx-data-grid
  class="grid"
  id="gridContainer"
  *ngIf="type === 'Grid' && columns != undefined"
  [dataSource]="internalSource"
  [keyExpr]="isDataSourceArray ? keyExpr : undefined"
  (onInitialized)="onInitialized($event)"
  (onToolbarPreparing)="onToolbarPreparing($event)"
  (onContentReady)="onContentReadyGrid($event)"
  (onRowClick)="onRowClicked($event)"
  (onDataErrorOccurred)="onDataErrorOccurred($event)"
  (onSelectionChanged)="selectionChanged($event, masterDetail)"
  [rowAlternationEnabled]="true"
  (onCellPrepared)="onCellPrepared($event)"
  (onOptionChanged)="onOptionChanged($event)"
  [showBorders]="false"
  [showRowLines]="false"
  [selection]="{
    mode: multiple ? 'multiple' : 'single',
    showCheckBoxesMode: canSelect ? 'always' : 'none'
  }"
  [hoverStateEnabled]="true"
  [selectedRowKeys]="selectedKeys"
  height="100%"
  (onSelectionChanged)="onSelectionChanged($event)"
  [sorting]="{
    mode: 'multiple'
  }"
  [stateStoring]="{
    enabled: true,
    type: 'custom'
  }"
  [dataRowTemplate]="dataRowTemplateName"
>
  <!--    columnHidingEnabled='true'-->
  <dxo-scrolling mode="infinite"></dxo-scrolling>

  <!--    <dxo-grouping-->
  <!--      [contextMenuEnabled]='true'-->
  <!--      expandMode='rowClick'-->
  <!--    ></dxo-grouping>-->
  <!--    <dxo-state-storing-->
  <!--      [enabled]="true"-->
  <!--      type="custom"-->
  <!--      [customLoad]="loadState"-->
  <!--      [customSave]="saveState"-->
  <!--      [savingTimeout]="200"-->
  <!--    >-->
  <!--    </dxo-state-storing>-->

  <!--    <dxo-editing-->
  <!--      mode="cell"-->
  <!--      [allowUpdating]="allowUpdate != undefined ? allowUpdate : false"-->
  <!--      useIcons="true"-->
  <!--    >-->
  <!--    </dxo-editing>-->
  <!--    <dxo-row-dragging-->
  <!--      [allowDropInsideItem]="dragAndDrop"-->
  <!--      [onDragStart]="onDragStart"-->
  <!--      [onDragChange]="onDragChange"-->
  <!--      [onReorder]="onReorder"-->
  <!--    ></dxo-row-dragging>-->
  <!--    <dxo-filter-builder [allowHierarchicalFields]="false"></dxo-filter-builder>-->
  <!--    <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>-->
  <dxi-column
    *ngFor="let column of columns"
    [dataField]="column.field"
    [caption]="column.label"
    [format]="column.format"
    [name]="column.label + column.field"
    [dataType]="column.dataType"
    [cssClass]="column.customEditing === true ? 'cell-highlighted' : ''"
    [groupIndex]="column.groupIndex"
    [allowSearch]="
      (column.field != undefined &&
        column.field.endsWith != undefined &&
        (column.field.endsWith('Id') ||
          column.dataType === 'boolean' ||
          column.template === 'arrayEnumTemplate' ||
          column.template === 'arrayTemplate' ||
          column.visible === false)) ||
      column.allowFiltering === false ||
      column.showInColumnChooser === false
        ? false
        : true
    "
    [fixed]="column.fixed"
    [width]="column.template == 'editingTemplate' ? 50 : column.width"
    [sortIndex]="column.sortIndex"
    [sortOrder]="column.sortOrder"
    [minWidth]="column.template == 'editingTemplate' ? 50 : column.minWidth"
    [allowResizing]="column.allowResizing"
    [fixedPosition]="column.fixedPosition"
    [filterValue]="column.filterValue"
    [visible]="column.visible != undefined ? column.visible : true"
    [allowSorting]="
      column.template == 'arrayTemplate' ? false : column.allowSorting
    "
    [allowFiltering]="
      column.template == 'arrayTemplate'
        ? false
        : column.allowFiltering != undefined
        ? column.allowFiltering
        : column.showInColumnChooser === false
        ? false
        : undefined
    "
    [showInColumnChooser]="
      column.showInColumnChooser != undefined
        ? column.showInColumnChooser
        : true
    "
    [allowGrouping]="column.allowGrouping"
    [allowEditing]="
      column.allowEditing != undefined ? column.allowEditing : false
    "
    [cellTemplate]="
      column.template == undefined && column.dataType == 'boolean'
        ? 'booleanTemplate'
        : column.template != undefined
        ? column.template
        : column.field == '__typename'
        ? column.link == true
          ? 'linkTypeNameTemplate'
          : 'typeNameTemplate'
        : column.link == true
        ? 'underlineTemplate'
        : ''
    "
  >
    <dxo-lookup
      *ngIf="column.lookup != undefined"
      [dataSource]="column.lookup.source"
      [valueExpr]="column.lookup.valueExpr"
      [displayExpr]="column.lookup.displayExpr"
    >
    </dxo-lookup>
  </dxi-column>

  <div *dxTemplate="let data of 'underlineTemplate'">
    <a
      href=""
      (auxclick)="underlineAux($event, data); (false)"
      (click)="underline($event, data); (false)"
      >{{ data.value }}</a
    >
  </div>

  <!-- Application des différents templates -->
  <ng-container
    *ngFor="let column of columns | filter : 'template' : undefined : true"
  >
    <div *dxTemplate="let data of column.template">
      <div [ngSwitch]="data.column.cellTemplate">
        <div *ngSwitchCase="'editingTemplate'">
          <a
            style="padding-left: 10px"
            class="cl-no-click"
            target="_blank"
            [attr.href]="getLink(data.data)"
            ><i class="fas fa-pencil-alt"></i
          ></a>
        </div>

        <div style="text-align: center" *ngSwitchCase="'openSubFormTemplate'">
          <a
            href=""
            (auxclick)="underlineAux($event, data); (false)"
            (click)="underline($event, data); (false)"
          >
            <i class="fas fa-folder-open"></i>
          </a>
        </div>

        <div style="text-align: center" *ngSwitchCase="'entityPropertyName'">
          {{ getPropertyNameTemplate(data.value) }}
        </div>

        <div *ngSwitchDefault>
          <ng-template
            *ngTemplateOutlet="
              templateService.get(column.template);
              context: { $implicit: data, column: column }
            "
          >
          </ng-template>
        </div>
      </div>
    </div>
  </ng-container>
  <!-- Temps template array-->

  <!-- Application des différents templates pour les row -->
  <!-- <ng-container
      *ngIf="rowTemplate != undefined "
    >    <tbody *dxTemplate="let template of rowTemplate" >
          <clc-debug [value]="'data'"></clc-debug>
          <clc-debug [value]="template"></clc-debug>

              <ng-template
                *ngTemplateOutlet="
                  templateRowService.get(rowTemplate);
                  context: { $implicit: template.data }
                "
              >
              </ng-template>
          </tbody>

body > app-root > app-root > cl-mobile-layout > div > cl-mobile-help-desk-layout > cl-mobile-help-desk-incident-list-list > cl-mobile-manage-list > div > cl-mobile-list > div > dx-data-grid > div > div.dx-datagrid-rowsview.dx-datagrid-nowrap.dx-scrollable.dx-visibility-change-handler.dx-scrollable-both.dx-scrollable-native.dx-scrollable-native-android.dx-scrollable-scrollbar-simulated > div.dx-scrollable-wrapper > div > div > div > table > tbody:nth-child(3) > tr.notes-row.dx-template-wrapper
    </ng-container> -->
  <!-- A laisser pour la fake column de redimensionnement -->
  <ng-container *dxTemplate="let item of 'dataRowTemplateName'" class="test">
    <tr class="main-row">
      <td rowspan="1">{{ item.data.ticketNumber }}</td>
      <td>{{ item.data.created | date : 'd/M/yyyy' }}</td>
      <td>{{ item?.data?.ticketCategory?.data?.path.fr }}</td>
      <td>{{ item?.data?.status?.data?.name.fr }}</td>

      <!--        <td>{{ employee.data.LastName }}</td>-->
      <!--        <td>{{ employee.data.Position }}</td>-->
      <!--        <td>{{ employee.data.BirthDate | date : "M/d/yyyy" }}</td>-->
      <!--        <td>{{ employee.data.HireDate | date : "M/d/yyyy" }}</td>-->
    </tr>
    <tr class="notes-row">
      <td colspan="4">
        <div>{{ item.data.title }}</div>
      </td>
    </tr>
  </ng-container>

  <ng-container
    *dxTemplate="let item of 'organizationRowTemplate'"
    class="test"
  >
    <tr class="main-row">
      <!--        <td>{{ item.data.created | date : "d/M/yyyy"}}</td>-->
      <td colspan="4">{{ item?.data?.name }}</td>
    </tr>
    <tr class="notes-row">
      <td colspan="4">
        <div>Url : {{ item.data.url }}</div>
      </td>
    </tr>
  </ng-container>
  <!--    <ng-container *dxTemplate="let employee of 'dataRowTemplateName'">-->
  <!--      <tr class="main-row">-->
  <!--        <td rowspan="2" style='width: 50px'>{{ employee.data.ticketNumber }}</td>-->
  <!--        <td>{{ employee.data.created }}</td>-->
  <!--        <td>{{ employee?.data?.ticketCategory?.data?.path.fr }}</td>-->
  <!--&lt;!&ndash;        <td>{{ employee.data.LastName }}</td>&ndash;&gt;-->
  <!--&lt;!&ndash;        <td>{{ employee.data.Position }}</td>&ndash;&gt;-->
  <!--&lt;!&ndash;        <td>{{ employee.data.BirthDate | date : "M/d/yyyy" }}</td>&ndash;&gt;-->
  <!--&lt;!&ndash;        <td>{{ employee.data.HireDate | date : "M/d/yyyy" }}</td>&ndash;&gt;-->
  <!--      </tr>-->
  <!--      <tr class="notes-row">-->
  <!--        <td colspan="2"-->
  <!--        ><div><p>{{ employee.data.title}}</p></div></td-->
  <!--        >-->
  <!--      </tr>-->
  <!--    </ng-container>-->
</dx-data-grid>
