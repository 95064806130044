import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBootConfigurationsBaseVariables, FirstBootConfigurationsBaseVariables, CountBootConfigurationsBaseVariables, FindBootConfigurationsBaseVariables, SearchBootConfigurationsBaseVariables, ExportSchemaBootConfigurationsBaseVariables, ExportDataBootConfigurationsBaseVariables, CustomQueryBootConfigurationsBaseVariables, CustomQueryIdBootConfigurationsBaseVariables, UsedBootConfigurationsBaseVariables, ExistBootConfigurationsBaseVariables } from '../gqls'
import { GetBootConfigurationsDocument, FirstBootConfigurationsDocument, CountBootConfigurationsDocument, FindBootConfigurationsDocument, SearchBootConfigurationsDocument, ExportSchemaBootConfigurationsDocument, ExportDataBootConfigurationsDocument, CustomQueryBootConfigurationsDocument, CustomQueryIdBootConfigurationsDocument, UsedBootConfigurationsDocument, ExistBootConfigurationsDocument } from '../gqls'
import { ServiceSingleResultOfBootConfiguration, QueryContextOfBootConfiguration, FilterOfBootConfiguration, ServiceSingleResultOfInt64, ServiceListResultOfBootConfiguration, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BootConfigurationBaseService {
    
public modelName = 'bootConfiguration';
public modelPluralName = 'bootConfigurations';

	private getBootConfigurationsQuery: GetBootConfigurationsDocument;
	private firstBootConfigurationsQuery: FirstBootConfigurationsDocument;
	private countBootConfigurationsQuery: CountBootConfigurationsDocument;
	private findBootConfigurationsQuery: FindBootConfigurationsDocument;
	private searchBootConfigurationsQuery: SearchBootConfigurationsDocument;
	private exportSchemaBootConfigurationsQuery: ExportSchemaBootConfigurationsDocument;
	private exportDataBootConfigurationsQuery: ExportDataBootConfigurationsDocument;
	private customQueryBootConfigurationsQuery: CustomQueryBootConfigurationsDocument;
	private customQueryIdBootConfigurationsQuery: CustomQueryIdBootConfigurationsDocument;
	private usedBootConfigurationsQuery: UsedBootConfigurationsDocument;
	private existBootConfigurationsQuery: ExistBootConfigurationsDocument;

	constructor(private injector: Injector) {
		this.getBootConfigurationsQuery = this.injector.get(GetBootConfigurationsDocument);
		this.firstBootConfigurationsQuery = this.injector.get(FirstBootConfigurationsDocument);
		this.countBootConfigurationsQuery = this.injector.get(CountBootConfigurationsDocument);
		this.findBootConfigurationsQuery = this.injector.get(FindBootConfigurationsDocument);
		this.searchBootConfigurationsQuery = this.injector.get(SearchBootConfigurationsDocument);
		this.exportSchemaBootConfigurationsQuery = this.injector.get(ExportSchemaBootConfigurationsDocument);
		this.exportDataBootConfigurationsQuery = this.injector.get(ExportDataBootConfigurationsDocument);
		this.customQueryBootConfigurationsQuery = this.injector.get(CustomQueryBootConfigurationsDocument);
		this.customQueryIdBootConfigurationsQuery = this.injector.get(CustomQueryIdBootConfigurationsDocument);
		this.usedBootConfigurationsQuery = this.injector.get(UsedBootConfigurationsDocument);
		this.existBootConfigurationsQuery = this.injector.get(ExistBootConfigurationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBootConfiguration> {
    
    		let variables: GetBootConfigurationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.get));
            }
            else{
                let result:ServiceSingleResultOfBootConfiguration={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBootConfiguration,
		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBootConfiguration> {
    
    		let variables: FirstBootConfigurationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBootConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.countBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBootConfiguration,
		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBootConfiguration> {
    
    		let variables: FindBootConfigurationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBootConfiguration,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBootConfiguration> {
    
    		let variables: SearchBootConfigurationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBootConfigurationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBootConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBootConfiguration,
		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBootConfiguration> {
    
    		let variables: CustomQueryBootConfigurationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBootConfiguration,
		@Args('filter?') filter?: FilterOfBootConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBootConfiguration> {
    
    		let variables: CustomQueryIdBootConfigurationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBootConfigurationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBootConfigurationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBootConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bootConfigurations.exist));
    	}
    
    
}