import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetRolesBaseVariables, FirstRolesBaseVariables, CountRolesBaseVariables, FindRolesBaseVariables, SearchRolesBaseVariables, ExportSchemaRolesBaseVariables, ExportDataRolesBaseVariables, CustomQueryRolesBaseVariables, CustomQueryIdRolesBaseVariables, UsedRolesBaseVariables, FindAvailableMembersRolesBaseVariables, FindMembersRolesBaseVariables, FindAllRolesBaseVariables, FindDefaultsRolesBaseVariables, CheckIfOperatorRoleRolesBaseVariables, FindHelpDeskUserRolesBaseVariables, FindNotHiddenRolesBaseVariables, CustomFindRoleRolesBaseVariables, ExistRolesBaseVariables, FindRecyclesRolesBaseVariables, CountRecyclesRolesBaseVariables, ReadOnlyRolesBaseVariables, FindArchivedRolesBaseVariables, CountAllRolesBaseVariables, FindDynamicPropertyFieldsRolesBaseVariables, InsertRolesBaseVariables, UpdateRolesBaseVariables, ImportDataRolesBaseVariables, UpdateManyRolesBaseVariables, DeleteRolesBaseVariables, AddMembersRolesBaseVariables, RemoveMembersRolesBaseVariables, RestoreRolesBaseVariables, RecycleRolesBaseVariables, RestoreArchivedRolesBaseVariables, ArchivedRolesBaseVariables, AddFileDynamicFieldRolesBaseVariables, RemoveFileDynamicFieldRolesBaseVariables } from '../gqls'
import { GetRolesDocument, FirstRolesDocument, CountRolesDocument, FindRolesDocument, SearchRolesDocument, ExportSchemaRolesDocument, ExportDataRolesDocument, CustomQueryRolesDocument, CustomQueryIdRolesDocument, UsedRolesDocument, FindAvailableMembersRolesDocument, FindMembersRolesDocument, FindAllRolesDocument, FindDefaultsRolesDocument, CheckIfOperatorRoleRolesDocument, FindHelpDeskUserRolesDocument, FindNotHiddenRolesDocument, CustomFindRoleRolesDocument, ExistRolesDocument, FindRecyclesRolesDocument, CountRecyclesRolesDocument, ReadOnlyRolesDocument, FindArchivedRolesDocument, CountAllRolesDocument, FindDynamicPropertyFieldsRolesDocument, InsertRolesDocument, UpdateRolesDocument, ImportDataRolesDocument, UpdateManyRolesDocument, DeleteRolesDocument, AddMembersRolesDocument, RemoveMembersRolesDocument, RestoreRolesDocument, RecycleRolesDocument, RestoreArchivedRolesDocument, ArchivedRolesDocument, AddFileDynamicFieldRolesDocument, RemoveFileDynamicFieldRolesDocument } from '../gqls'
import { ServiceSingleResultOfRole, QueryContextOfRole, FilterOfRole, ServiceSingleResultOfInt64, ServiceListResultOfRole, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfAccount, QueryContextOfAccount, FilterOfAccount, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfRole, RoleInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class RoleBaseService {
    
public modelName = 'role';
public modelPluralName = 'roles';

	private getRolesQuery: GetRolesDocument;
	private firstRolesQuery: FirstRolesDocument;
	private countRolesQuery: CountRolesDocument;
	private findRolesQuery: FindRolesDocument;
	private searchRolesQuery: SearchRolesDocument;
	private exportSchemaRolesQuery: ExportSchemaRolesDocument;
	private exportDataRolesQuery: ExportDataRolesDocument;
	private customQueryRolesQuery: CustomQueryRolesDocument;
	private customQueryIdRolesQuery: CustomQueryIdRolesDocument;
	private usedRolesQuery: UsedRolesDocument;
	private findAvailableMembersRolesQuery: FindAvailableMembersRolesDocument;
	private findMembersRolesQuery: FindMembersRolesDocument;
	private findAllRolesQuery: FindAllRolesDocument;
	private findDefaultsRolesQuery: FindDefaultsRolesDocument;
	private checkIfOperatorRoleRolesQuery: CheckIfOperatorRoleRolesDocument;
	private findHelpDeskUserRolesQuery: FindHelpDeskUserRolesDocument;
	private findNotHiddenRolesQuery: FindNotHiddenRolesDocument;
	private customFindRoleRolesQuery: CustomFindRoleRolesDocument;
	private existRolesQuery: ExistRolesDocument;
	private findRecyclesRolesQuery: FindRecyclesRolesDocument;
	private countRecyclesRolesQuery: CountRecyclesRolesDocument;
	private readOnlyRolesQuery: ReadOnlyRolesDocument;
	private findArchivedRolesQuery: FindArchivedRolesDocument;
	private countAllRolesQuery: CountAllRolesDocument;
	private findDynamicPropertyFieldsRolesQuery: FindDynamicPropertyFieldsRolesDocument;
	private insertRolesMutation: InsertRolesDocument;
	private updateRolesMutation: UpdateRolesDocument;
	private importDataRolesMutation: ImportDataRolesDocument;
	private updateManyRolesMutation: UpdateManyRolesDocument;
	private deleteRolesMutation: DeleteRolesDocument;
	private addMembersRolesMutation: AddMembersRolesDocument;
	private removeMembersRolesMutation: RemoveMembersRolesDocument;
	private restoreRolesMutation: RestoreRolesDocument;
	private recycleRolesMutation: RecycleRolesDocument;
	private restoreArchivedRolesMutation: RestoreArchivedRolesDocument;
	private archivedRolesMutation: ArchivedRolesDocument;
	private addFileDynamicFieldRolesMutation: AddFileDynamicFieldRolesDocument;
	private removeFileDynamicFieldRolesMutation: RemoveFileDynamicFieldRolesDocument;

	constructor(private injector: Injector) {
		this.getRolesQuery = this.injector.get(GetRolesDocument);
		this.firstRolesQuery = this.injector.get(FirstRolesDocument);
		this.countRolesQuery = this.injector.get(CountRolesDocument);
		this.findRolesQuery = this.injector.get(FindRolesDocument);
		this.searchRolesQuery = this.injector.get(SearchRolesDocument);
		this.exportSchemaRolesQuery = this.injector.get(ExportSchemaRolesDocument);
		this.exportDataRolesQuery = this.injector.get(ExportDataRolesDocument);
		this.customQueryRolesQuery = this.injector.get(CustomQueryRolesDocument);
		this.customQueryIdRolesQuery = this.injector.get(CustomQueryIdRolesDocument);
		this.usedRolesQuery = this.injector.get(UsedRolesDocument);
		this.findAvailableMembersRolesQuery = this.injector.get(FindAvailableMembersRolesDocument);
		this.findMembersRolesQuery = this.injector.get(FindMembersRolesDocument);
		this.findAllRolesQuery = this.injector.get(FindAllRolesDocument);
		this.findDefaultsRolesQuery = this.injector.get(FindDefaultsRolesDocument);
		this.checkIfOperatorRoleRolesQuery = this.injector.get(CheckIfOperatorRoleRolesDocument);
		this.findHelpDeskUserRolesQuery = this.injector.get(FindHelpDeskUserRolesDocument);
		this.findNotHiddenRolesQuery = this.injector.get(FindNotHiddenRolesDocument);
		this.customFindRoleRolesQuery = this.injector.get(CustomFindRoleRolesDocument);
		this.existRolesQuery = this.injector.get(ExistRolesDocument);
		this.findRecyclesRolesQuery = this.injector.get(FindRecyclesRolesDocument);
		this.countRecyclesRolesQuery = this.injector.get(CountRecyclesRolesDocument);
		this.readOnlyRolesQuery = this.injector.get(ReadOnlyRolesDocument);
		this.findArchivedRolesQuery = this.injector.get(FindArchivedRolesDocument);
		this.countAllRolesQuery = this.injector.get(CountAllRolesDocument);
		this.findDynamicPropertyFieldsRolesQuery = this.injector.get(FindDynamicPropertyFieldsRolesDocument);
		this.insertRolesMutation = this.injector.get(InsertRolesDocument);
		this.updateRolesMutation = this.injector.get(UpdateRolesDocument);
		this.importDataRolesMutation = this.injector.get(ImportDataRolesDocument);
		this.updateManyRolesMutation = this.injector.get(UpdateManyRolesDocument);
		this.deleteRolesMutation = this.injector.get(DeleteRolesDocument);
		this.addMembersRolesMutation = this.injector.get(AddMembersRolesDocument);
		this.removeMembersRolesMutation = this.injector.get(RemoveMembersRolesDocument);
		this.restoreRolesMutation = this.injector.get(RestoreRolesDocument);
		this.recycleRolesMutation = this.injector.get(RecycleRolesDocument);
		this.restoreArchivedRolesMutation = this.injector.get(RestoreArchivedRolesDocument);
		this.archivedRolesMutation = this.injector.get(ArchivedRolesDocument);
		this.addFileDynamicFieldRolesMutation = this.injector.get(AddFileDynamicFieldRolesDocument);
		this.removeFileDynamicFieldRolesMutation = this.injector.get(RemoveFileDynamicFieldRolesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRole> {
    
    		let variables: GetRolesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.get));
            }
            else{
                let result:ServiceSingleResultOfRole={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRole> {
    
    		let variables: FirstRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindRolesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfRole,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: SearchRolesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaRolesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataRolesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: CustomQueryRolesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: CustomQueryIdRolesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedRolesBaseVariables = {
    			ids: ids
    		}
    				return this.usedRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.used));
    	}

    	/**  */
    	@InjectArgs
    	public findAvailableMembers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfIds') memberOfIds: Array<string>,
		@Args('options?') options?: QueryContextOfAccount,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: FindAvailableMembersRolesBaseVariables = {
    			id: id,
			memberOfIds: memberOfIds,
			filter: filter,
			options: options
    		}
    				return this.findAvailableMembersRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findAvailableMembers));
    	}

    	/**  */
    	@InjectArgs
    	public findMembers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: FindMembersRolesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findMembersRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findMembers));
    	}

    	/**  */
    	@InjectArgs
    	public findAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindAllRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findAllRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDefaults(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindDefaultsRolesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findDefaultsRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findDefaults));
    	}

    	/**  */
    	@InjectArgs
    	public checkIfOperatorRole(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CheckIfOperatorRoleRolesBaseVariables = {
    			ids: ids
    		}
    				return this.checkIfOperatorRoleRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.checkIfOperatorRole));
    	}

    	/**  */
    	@InjectArgs
    	public findHelpDeskUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindHelpDeskUserRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findHelpDeskUser));
    	}

    	/**  */
    	@InjectArgs
    	public findNotHidden(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindNotHiddenRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findNotHiddenRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findNotHidden));
    	}

    	/**  */
    	@InjectArgs
    	public customFindRole(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('roleIds') roleIds: Array<string>,
		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: CustomFindRoleRolesBaseVariables = {
    			roleIds: roleIds,
			filter: filter,
			options: options
    		}
    				return this.customFindRoleRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.customFindRole));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistRolesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindRecyclesRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyRolesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindArchivedRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsRolesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.roles.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: RoleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRole> {
    
    		let variables: InsertRolesBaseVariables = {
    			entity: entity
    		}
    				return this.insertRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfRole> {
    
    		let variables: UpdateRolesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataRolesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyRolesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteRolesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addMembers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberIds') memberIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddMembersRolesBaseVariables = {
    			id: id,
			memberIds: memberIds
    		}
    				return this.addMembersRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.addMembers));
    	}

    	/**  */
    	@InjectArgs
    	public removeMembers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberIds') memberIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveMembersRolesBaseVariables = {
    			id: id,
			memberIds: memberIds
    		}
    				return this.removeMembersRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.removeMembers));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreRolesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleRolesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedRolesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedRolesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldRolesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldRolesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.roles.removeFileDynamicField));
    	}
    
}