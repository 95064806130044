import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFontInfoActionsBaseVariables, FirstFontInfoActionsBaseVariables, CountFontInfoActionsBaseVariables, FindFontInfoActionsBaseVariables, SearchFontInfoActionsBaseVariables, ExportSchemaFontInfoActionsBaseVariables, ExportDataFontInfoActionsBaseVariables, CustomQueryFontInfoActionsBaseVariables, CustomQueryIdFontInfoActionsBaseVariables, UsedFontInfoActionsBaseVariables, ExistFontInfoActionsBaseVariables } from '../gqls'
import { GetFontInfoActionsDocument, FirstFontInfoActionsDocument, CountFontInfoActionsDocument, FindFontInfoActionsDocument, SearchFontInfoActionsDocument, ExportSchemaFontInfoActionsDocument, ExportDataFontInfoActionsDocument, CustomQueryFontInfoActionsDocument, CustomQueryIdFontInfoActionsDocument, UsedFontInfoActionsDocument, ExistFontInfoActionsDocument } from '../gqls'
import { ServiceSingleResultOfFontInfoAction, QueryContextOfFontInfoAction, FilterOfFontInfoAction, ServiceSingleResultOfInt64, ServiceListResultOfFontInfoAction, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FontInfoActionBaseService {
    
public modelName = 'fontInfoAction';
public modelPluralName = 'fontInfoActions';

	private getFontInfoActionsQuery: GetFontInfoActionsDocument;
	private firstFontInfoActionsQuery: FirstFontInfoActionsDocument;
	private countFontInfoActionsQuery: CountFontInfoActionsDocument;
	private findFontInfoActionsQuery: FindFontInfoActionsDocument;
	private searchFontInfoActionsQuery: SearchFontInfoActionsDocument;
	private exportSchemaFontInfoActionsQuery: ExportSchemaFontInfoActionsDocument;
	private exportDataFontInfoActionsQuery: ExportDataFontInfoActionsDocument;
	private customQueryFontInfoActionsQuery: CustomQueryFontInfoActionsDocument;
	private customQueryIdFontInfoActionsQuery: CustomQueryIdFontInfoActionsDocument;
	private usedFontInfoActionsQuery: UsedFontInfoActionsDocument;
	private existFontInfoActionsQuery: ExistFontInfoActionsDocument;

	constructor(private injector: Injector) {
		this.getFontInfoActionsQuery = this.injector.get(GetFontInfoActionsDocument);
		this.firstFontInfoActionsQuery = this.injector.get(FirstFontInfoActionsDocument);
		this.countFontInfoActionsQuery = this.injector.get(CountFontInfoActionsDocument);
		this.findFontInfoActionsQuery = this.injector.get(FindFontInfoActionsDocument);
		this.searchFontInfoActionsQuery = this.injector.get(SearchFontInfoActionsDocument);
		this.exportSchemaFontInfoActionsQuery = this.injector.get(ExportSchemaFontInfoActionsDocument);
		this.exportDataFontInfoActionsQuery = this.injector.get(ExportDataFontInfoActionsDocument);
		this.customQueryFontInfoActionsQuery = this.injector.get(CustomQueryFontInfoActionsDocument);
		this.customQueryIdFontInfoActionsQuery = this.injector.get(CustomQueryIdFontInfoActionsDocument);
		this.usedFontInfoActionsQuery = this.injector.get(UsedFontInfoActionsDocument);
		this.existFontInfoActionsQuery = this.injector.get(ExistFontInfoActionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFontInfoAction> {
    
    		let variables: GetFontInfoActionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.get));
            }
            else{
                let result:ServiceSingleResultOfFontInfoAction={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFontInfoAction,
		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFontInfoAction> {
    
    		let variables: FirstFontInfoActionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFontInfoActionsBaseVariables = {
    			filter: filter
    		}
    				return this.countFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFontInfoAction,
		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFontInfoAction> {
    
    		let variables: FindFontInfoActionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFontInfoAction,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFontInfoAction> {
    
    		let variables: SearchFontInfoActionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFontInfoActionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFontInfoActionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFontInfoAction,
		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFontInfoAction> {
    
    		let variables: CustomQueryFontInfoActionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFontInfoAction,
		@Args('filter?') filter?: FilterOfFontInfoAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFontInfoAction> {
    
    		let variables: CustomQueryIdFontInfoActionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFontInfoActionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFontInfoActionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFontInfoActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.fontInfoActions.exist));
    	}
    
    
}