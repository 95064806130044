import { TicketCategoryBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  FilterOfTicketCategory,
  QueryContextOfTicketCategory,
  ServiceListResultOfTicketCategory,
} from '../types';
import { InjectArgs, Args } from '@clarilog/core';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { Maybe } from 'graphql/jsutils/Maybe';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-ticket-category')
export class TicketCategoryCoreService extends TicketCategoryBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findByQualification(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],
    @Args('options?') options?: QueryContextOfTicketCategory,
    @Args('filter?') filter?: FilterOfTicketCategory,
  ): Observable<ServiceListResultOfTicketCategory> {
    let filterByQualification: FilterOfTicketCategory = {
      qualification: { elemMatch: { in: qualification } },
    };
    if (filter != undefined) {
      filterByQualification.and = [filter];
    }
    return this.find(fields, options, filterByQualification);
  }

  @InjectArgs
  public findTicketCategoryByTypeTest(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification?') qualification?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfTicketCategory,
    @Args('filter?') filter?: FilterOfTicketCategory,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketCategory> {
    return this.findTicketCategoryByType(
      fields,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }
}
