import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPcmciaControllersBaseVariables, FirstPcmciaControllersBaseVariables, CountPcmciaControllersBaseVariables, FindPcmciaControllersBaseVariables, SearchPcmciaControllersBaseVariables, ExportSchemaPcmciaControllersBaseVariables, ExportDataPcmciaControllersBaseVariables, CustomQueryPcmciaControllersBaseVariables, CustomQueryIdPcmciaControllersBaseVariables, UsedPcmciaControllersBaseVariables, ExistPcmciaControllersBaseVariables } from '../gqls'
import { GetPcmciaControllersDocument, FirstPcmciaControllersDocument, CountPcmciaControllersDocument, FindPcmciaControllersDocument, SearchPcmciaControllersDocument, ExportSchemaPcmciaControllersDocument, ExportDataPcmciaControllersDocument, CustomQueryPcmciaControllersDocument, CustomQueryIdPcmciaControllersDocument, UsedPcmciaControllersDocument, ExistPcmciaControllersDocument } from '../gqls'
import { ServiceSingleResultOfPcmciaController, QueryContextOfPcmciaController, FilterOfPcmciaController, ServiceSingleResultOfInt64, ServiceListResultOfPcmciaController, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PcmciaControllerBaseService {
    
public modelName = 'pcmciaController';
public modelPluralName = 'pcmciaControllers';

	private getPcmciaControllersQuery: GetPcmciaControllersDocument;
	private firstPcmciaControllersQuery: FirstPcmciaControllersDocument;
	private countPcmciaControllersQuery: CountPcmciaControllersDocument;
	private findPcmciaControllersQuery: FindPcmciaControllersDocument;
	private searchPcmciaControllersQuery: SearchPcmciaControllersDocument;
	private exportSchemaPcmciaControllersQuery: ExportSchemaPcmciaControllersDocument;
	private exportDataPcmciaControllersQuery: ExportDataPcmciaControllersDocument;
	private customQueryPcmciaControllersQuery: CustomQueryPcmciaControllersDocument;
	private customQueryIdPcmciaControllersQuery: CustomQueryIdPcmciaControllersDocument;
	private usedPcmciaControllersQuery: UsedPcmciaControllersDocument;
	private existPcmciaControllersQuery: ExistPcmciaControllersDocument;

	constructor(private injector: Injector) {
		this.getPcmciaControllersQuery = this.injector.get(GetPcmciaControllersDocument);
		this.firstPcmciaControllersQuery = this.injector.get(FirstPcmciaControllersDocument);
		this.countPcmciaControllersQuery = this.injector.get(CountPcmciaControllersDocument);
		this.findPcmciaControllersQuery = this.injector.get(FindPcmciaControllersDocument);
		this.searchPcmciaControllersQuery = this.injector.get(SearchPcmciaControllersDocument);
		this.exportSchemaPcmciaControllersQuery = this.injector.get(ExportSchemaPcmciaControllersDocument);
		this.exportDataPcmciaControllersQuery = this.injector.get(ExportDataPcmciaControllersDocument);
		this.customQueryPcmciaControllersQuery = this.injector.get(CustomQueryPcmciaControllersDocument);
		this.customQueryIdPcmciaControllersQuery = this.injector.get(CustomQueryIdPcmciaControllersDocument);
		this.usedPcmciaControllersQuery = this.injector.get(UsedPcmciaControllersDocument);
		this.existPcmciaControllersQuery = this.injector.get(ExistPcmciaControllersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPcmciaController> {
    
    		let variables: GetPcmciaControllersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.get));
            }
            else{
                let result:ServiceSingleResultOfPcmciaController={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPcmciaController,
		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPcmciaController> {
    
    		let variables: FirstPcmciaControllersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPcmciaControllersBaseVariables = {
    			filter: filter
    		}
    				return this.countPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPcmciaController,
		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPcmciaController> {
    
    		let variables: FindPcmciaControllersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPcmciaController,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPcmciaController> {
    
    		let variables: SearchPcmciaControllersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPcmciaControllersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPcmciaControllersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPcmciaController,
		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPcmciaController> {
    
    		let variables: CustomQueryPcmciaControllersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPcmciaController,
		@Args('filter?') filter?: FilterOfPcmciaController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPcmciaController> {
    
    		let variables: CustomQueryIdPcmciaControllersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPcmciaControllersBaseVariables = {
    			ids: ids
    		}
    				return this.usedPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPcmciaControllersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPcmciaControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pcmciaControllers.exist));
    	}
    
    
}