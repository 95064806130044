import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetContractCategoriesBaseVariables, FirstContractCategoriesBaseVariables, CountContractCategoriesBaseVariables, FindContractCategoriesBaseVariables, SearchContractCategoriesBaseVariables, ExportSchemaContractCategoriesBaseVariables, ExportDataContractCategoriesBaseVariables, CustomQueryContractCategoriesBaseVariables, CustomQueryIdContractCategoriesBaseVariables, UsedContractCategoriesBaseVariables, ExistContractCategoriesBaseVariables, FindRecyclesContractCategoriesBaseVariables, CountRecyclesContractCategoriesBaseVariables, ReadOnlyContractCategoriesBaseVariables, FindArchivedContractCategoriesBaseVariables, CountAllContractCategoriesBaseVariables, FindDynamicPropertyFieldsContractCategoriesBaseVariables, FindUnassociatedChildrenContractCategoriesBaseVariables, InsertContractCategoriesBaseVariables, UpdateContractCategoriesBaseVariables, ImportDataContractCategoriesBaseVariables, UpdateManyContractCategoriesBaseVariables, DeleteContractCategoriesBaseVariables, RestoreContractCategoriesBaseVariables, RecycleContractCategoriesBaseVariables, RestoreArchivedContractCategoriesBaseVariables, ArchivedContractCategoriesBaseVariables, AddFileDynamicFieldContractCategoriesBaseVariables, RemoveFileDynamicFieldContractCategoriesBaseVariables, AddChildrenContractCategoriesBaseVariables, RemoveChildrenContractCategoriesBaseVariables } from '../gqls'
import { GetContractCategoriesDocument, FirstContractCategoriesDocument, CountContractCategoriesDocument, FindContractCategoriesDocument, SearchContractCategoriesDocument, ExportSchemaContractCategoriesDocument, ExportDataContractCategoriesDocument, CustomQueryContractCategoriesDocument, CustomQueryIdContractCategoriesDocument, UsedContractCategoriesDocument, ExistContractCategoriesDocument, FindRecyclesContractCategoriesDocument, CountRecyclesContractCategoriesDocument, ReadOnlyContractCategoriesDocument, FindArchivedContractCategoriesDocument, CountAllContractCategoriesDocument, FindDynamicPropertyFieldsContractCategoriesDocument, FindUnassociatedChildrenContractCategoriesDocument, InsertContractCategoriesDocument, UpdateContractCategoriesDocument, ImportDataContractCategoriesDocument, UpdateManyContractCategoriesDocument, DeleteContractCategoriesDocument, RestoreContractCategoriesDocument, RecycleContractCategoriesDocument, RestoreArchivedContractCategoriesDocument, ArchivedContractCategoriesDocument, AddFileDynamicFieldContractCategoriesDocument, RemoveFileDynamicFieldContractCategoriesDocument, AddChildrenContractCategoriesDocument, RemoveChildrenContractCategoriesDocument } from '../gqls'
import { ServiceSingleResultOfContractCategory, QueryContextOfContractCategory, FilterOfContractCategory, ServiceSingleResultOfInt64, ServiceListResultOfContractCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfContractCategory, QueryContextOfContract, FilterOfContract, ServiceListResultOfContract, ContractCategoryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ContractCategoryBaseService {
    
public modelName = 'contractCategory';
public modelPluralName = 'contractCategories';

	private getContractCategoriesQuery: GetContractCategoriesDocument;
	private firstContractCategoriesQuery: FirstContractCategoriesDocument;
	private countContractCategoriesQuery: CountContractCategoriesDocument;
	private findContractCategoriesQuery: FindContractCategoriesDocument;
	private searchContractCategoriesQuery: SearchContractCategoriesDocument;
	private exportSchemaContractCategoriesQuery: ExportSchemaContractCategoriesDocument;
	private exportDataContractCategoriesQuery: ExportDataContractCategoriesDocument;
	private customQueryContractCategoriesQuery: CustomQueryContractCategoriesDocument;
	private customQueryIdContractCategoriesQuery: CustomQueryIdContractCategoriesDocument;
	private usedContractCategoriesQuery: UsedContractCategoriesDocument;
	private existContractCategoriesQuery: ExistContractCategoriesDocument;
	private findRecyclesContractCategoriesQuery: FindRecyclesContractCategoriesDocument;
	private countRecyclesContractCategoriesQuery: CountRecyclesContractCategoriesDocument;
	private readOnlyContractCategoriesQuery: ReadOnlyContractCategoriesDocument;
	private findArchivedContractCategoriesQuery: FindArchivedContractCategoriesDocument;
	private countAllContractCategoriesQuery: CountAllContractCategoriesDocument;
	private findDynamicPropertyFieldsContractCategoriesQuery: FindDynamicPropertyFieldsContractCategoriesDocument;
	private findUnassociatedChildrenContractCategoriesQuery: FindUnassociatedChildrenContractCategoriesDocument;
	private insertContractCategoriesMutation: InsertContractCategoriesDocument;
	private updateContractCategoriesMutation: UpdateContractCategoriesDocument;
	private importDataContractCategoriesMutation: ImportDataContractCategoriesDocument;
	private updateManyContractCategoriesMutation: UpdateManyContractCategoriesDocument;
	private deleteContractCategoriesMutation: DeleteContractCategoriesDocument;
	private restoreContractCategoriesMutation: RestoreContractCategoriesDocument;
	private recycleContractCategoriesMutation: RecycleContractCategoriesDocument;
	private restoreArchivedContractCategoriesMutation: RestoreArchivedContractCategoriesDocument;
	private archivedContractCategoriesMutation: ArchivedContractCategoriesDocument;
	private addFileDynamicFieldContractCategoriesMutation: AddFileDynamicFieldContractCategoriesDocument;
	private removeFileDynamicFieldContractCategoriesMutation: RemoveFileDynamicFieldContractCategoriesDocument;
	private addChildrenContractCategoriesMutation: AddChildrenContractCategoriesDocument;
	private removeChildrenContractCategoriesMutation: RemoveChildrenContractCategoriesDocument;

	constructor(private injector: Injector) {
		this.getContractCategoriesQuery = this.injector.get(GetContractCategoriesDocument);
		this.firstContractCategoriesQuery = this.injector.get(FirstContractCategoriesDocument);
		this.countContractCategoriesQuery = this.injector.get(CountContractCategoriesDocument);
		this.findContractCategoriesQuery = this.injector.get(FindContractCategoriesDocument);
		this.searchContractCategoriesQuery = this.injector.get(SearchContractCategoriesDocument);
		this.exportSchemaContractCategoriesQuery = this.injector.get(ExportSchemaContractCategoriesDocument);
		this.exportDataContractCategoriesQuery = this.injector.get(ExportDataContractCategoriesDocument);
		this.customQueryContractCategoriesQuery = this.injector.get(CustomQueryContractCategoriesDocument);
		this.customQueryIdContractCategoriesQuery = this.injector.get(CustomQueryIdContractCategoriesDocument);
		this.usedContractCategoriesQuery = this.injector.get(UsedContractCategoriesDocument);
		this.existContractCategoriesQuery = this.injector.get(ExistContractCategoriesDocument);
		this.findRecyclesContractCategoriesQuery = this.injector.get(FindRecyclesContractCategoriesDocument);
		this.countRecyclesContractCategoriesQuery = this.injector.get(CountRecyclesContractCategoriesDocument);
		this.readOnlyContractCategoriesQuery = this.injector.get(ReadOnlyContractCategoriesDocument);
		this.findArchivedContractCategoriesQuery = this.injector.get(FindArchivedContractCategoriesDocument);
		this.countAllContractCategoriesQuery = this.injector.get(CountAllContractCategoriesDocument);
		this.findDynamicPropertyFieldsContractCategoriesQuery = this.injector.get(FindDynamicPropertyFieldsContractCategoriesDocument);
		this.findUnassociatedChildrenContractCategoriesQuery = this.injector.get(FindUnassociatedChildrenContractCategoriesDocument);
		this.insertContractCategoriesMutation = this.injector.get(InsertContractCategoriesDocument);
		this.updateContractCategoriesMutation = this.injector.get(UpdateContractCategoriesDocument);
		this.importDataContractCategoriesMutation = this.injector.get(ImportDataContractCategoriesDocument);
		this.updateManyContractCategoriesMutation = this.injector.get(UpdateManyContractCategoriesDocument);
		this.deleteContractCategoriesMutation = this.injector.get(DeleteContractCategoriesDocument);
		this.restoreContractCategoriesMutation = this.injector.get(RestoreContractCategoriesDocument);
		this.recycleContractCategoriesMutation = this.injector.get(RecycleContractCategoriesDocument);
		this.restoreArchivedContractCategoriesMutation = this.injector.get(RestoreArchivedContractCategoriesDocument);
		this.archivedContractCategoriesMutation = this.injector.get(ArchivedContractCategoriesDocument);
		this.addFileDynamicFieldContractCategoriesMutation = this.injector.get(AddFileDynamicFieldContractCategoriesDocument);
		this.removeFileDynamicFieldContractCategoriesMutation = this.injector.get(RemoveFileDynamicFieldContractCategoriesDocument);
		this.addChildrenContractCategoriesMutation = this.injector.get(AddChildrenContractCategoriesDocument);
		this.removeChildrenContractCategoriesMutation = this.injector.get(RemoveChildrenContractCategoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractCategory> {
    
    		let variables: GetContractCategoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.get));
            }
            else{
                let result:ServiceSingleResultOfContractCategory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractCategory> {
    
    		let variables: FirstContractCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountContractCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: FindContractCategoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: SearchContractCategoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaContractCategoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataContractCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: CustomQueryContractCategoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: CustomQueryIdContractCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistContractCategoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: FindRecyclesContractCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesContractCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyContractCategoriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContractCategory,
		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContractCategory> {
    
    		let variables: FindArchivedContractCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllContractCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsContractCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedChildren(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfContract> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('children', fields, null, [
			GqlSubFieldArg.create('filterOfChildren', 'filter'),
			GqlSubFieldArg.create('optionsOfChildren', 'options'),
		]),
		])
		extendedVariables['filterOfChildren'] = filter;
		extendedVariables['optionsOfChildren'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.children));
            }
            else{
                let result: ServiceListResultOfContract = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedChildren(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContract> {
    
    		let variables: FindUnassociatedChildrenContractCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedChildrenContractCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.findUnassociatedChildren));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ContractCategoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractCategory> {
    
    		let variables: InsertContractCategoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfContractCategory> {
    
    		let variables: UpdateContractCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataContractCategoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfContractCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyContractCategoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedContractCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldContractCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldContractCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addChildren(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddChildrenContractCategoriesBaseVariables = {
    			id: id,
			contractIds: contractIds
    		}
    				return this.addChildrenContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.addChildren));
    	}

    	/**  */
    	@InjectArgs
    	public removeChildren(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveChildrenContractCategoriesBaseVariables = {
    			contractIds: contractIds
    		}
    				return this.removeChildrenContractCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.contractCategories.removeChildren));
    	}
    
}