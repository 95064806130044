import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSshCredentialsBaseVariables, FirstSshCredentialsBaseVariables, CountSshCredentialsBaseVariables, FindSshCredentialsBaseVariables, SearchSshCredentialsBaseVariables, ExportSchemaSshCredentialsBaseVariables, ExportDataSshCredentialsBaseVariables, CustomQuerySshCredentialsBaseVariables, CustomQueryIdSshCredentialsBaseVariables, UsedSshCredentialsBaseVariables, ExistSshCredentialsBaseVariables, FindRecyclesSshCredentialsBaseVariables, CountRecyclesSshCredentialsBaseVariables, ReadOnlySshCredentialsBaseVariables, FindArchivedSshCredentialsBaseVariables, CountAllSshCredentialsBaseVariables, FindDynamicPropertyFieldsSshCredentialsBaseVariables, FindUnassociatedScanConfigurationsSshCredentialsBaseVariables, FindUnassociatedInventoryHistoriesSshCredentialsBaseVariables, FindUnassociatedScanByLdapsSshCredentialsBaseVariables, InsertSshCredentialsBaseVariables, UpdateSshCredentialsBaseVariables, ImportDataSshCredentialsBaseVariables, UpdateManySshCredentialsBaseVariables, DeleteSshCredentialsBaseVariables, RestoreSshCredentialsBaseVariables, RecycleSshCredentialsBaseVariables, RestoreArchivedSshCredentialsBaseVariables, ArchivedSshCredentialsBaseVariables, AddFileDynamicFieldSshCredentialsBaseVariables, RemoveFileDynamicFieldSshCredentialsBaseVariables } from '../gqls'
import { GetSshCredentialsDocument, FirstSshCredentialsDocument, CountSshCredentialsDocument, FindSshCredentialsDocument, SearchSshCredentialsDocument, ExportSchemaSshCredentialsDocument, ExportDataSshCredentialsDocument, CustomQuerySshCredentialsDocument, CustomQueryIdSshCredentialsDocument, UsedSshCredentialsDocument, ExistSshCredentialsDocument, FindRecyclesSshCredentialsDocument, CountRecyclesSshCredentialsDocument, ReadOnlySshCredentialsDocument, FindArchivedSshCredentialsDocument, CountAllSshCredentialsDocument, FindDynamicPropertyFieldsSshCredentialsDocument, FindUnassociatedScanConfigurationsSshCredentialsDocument, FindUnassociatedInventoryHistoriesSshCredentialsDocument, FindUnassociatedScanByLdapsSshCredentialsDocument, InsertSshCredentialsDocument, UpdateSshCredentialsDocument, ImportDataSshCredentialsDocument, UpdateManySshCredentialsDocument, DeleteSshCredentialsDocument, RestoreSshCredentialsDocument, RecycleSshCredentialsDocument, RestoreArchivedSshCredentialsDocument, ArchivedSshCredentialsDocument, AddFileDynamicFieldSshCredentialsDocument, RemoveFileDynamicFieldSshCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfSshCredential, QueryContextOfSshCredential, FilterOfSshCredential, ServiceSingleResultOfInt64, ServiceListResultOfSshCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfSshCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, SshCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SshCredentialBaseService {
    
public modelName = 'sshCredential';
public modelPluralName = 'sshCredentials';

	private getSshCredentialsQuery: GetSshCredentialsDocument;
	private firstSshCredentialsQuery: FirstSshCredentialsDocument;
	private countSshCredentialsQuery: CountSshCredentialsDocument;
	private findSshCredentialsQuery: FindSshCredentialsDocument;
	private searchSshCredentialsQuery: SearchSshCredentialsDocument;
	private exportSchemaSshCredentialsQuery: ExportSchemaSshCredentialsDocument;
	private exportDataSshCredentialsQuery: ExportDataSshCredentialsDocument;
	private customQuerySshCredentialsQuery: CustomQuerySshCredentialsDocument;
	private customQueryIdSshCredentialsQuery: CustomQueryIdSshCredentialsDocument;
	private usedSshCredentialsQuery: UsedSshCredentialsDocument;
	private existSshCredentialsQuery: ExistSshCredentialsDocument;
	private findRecyclesSshCredentialsQuery: FindRecyclesSshCredentialsDocument;
	private countRecyclesSshCredentialsQuery: CountRecyclesSshCredentialsDocument;
	private readOnlySshCredentialsQuery: ReadOnlySshCredentialsDocument;
	private findArchivedSshCredentialsQuery: FindArchivedSshCredentialsDocument;
	private countAllSshCredentialsQuery: CountAllSshCredentialsDocument;
	private findDynamicPropertyFieldsSshCredentialsQuery: FindDynamicPropertyFieldsSshCredentialsDocument;
	private findUnassociatedScanConfigurationsSshCredentialsQuery: FindUnassociatedScanConfigurationsSshCredentialsDocument;
	private findUnassociatedInventoryHistoriesSshCredentialsQuery: FindUnassociatedInventoryHistoriesSshCredentialsDocument;
	private findUnassociatedScanByLdapsSshCredentialsQuery: FindUnassociatedScanByLdapsSshCredentialsDocument;
	private insertSshCredentialsMutation: InsertSshCredentialsDocument;
	private updateSshCredentialsMutation: UpdateSshCredentialsDocument;
	private importDataSshCredentialsMutation: ImportDataSshCredentialsDocument;
	private updateManySshCredentialsMutation: UpdateManySshCredentialsDocument;
	private deleteSshCredentialsMutation: DeleteSshCredentialsDocument;
	private restoreSshCredentialsMutation: RestoreSshCredentialsDocument;
	private recycleSshCredentialsMutation: RecycleSshCredentialsDocument;
	private restoreArchivedSshCredentialsMutation: RestoreArchivedSshCredentialsDocument;
	private archivedSshCredentialsMutation: ArchivedSshCredentialsDocument;
	private addFileDynamicFieldSshCredentialsMutation: AddFileDynamicFieldSshCredentialsDocument;
	private removeFileDynamicFieldSshCredentialsMutation: RemoveFileDynamicFieldSshCredentialsDocument;

	constructor(private injector: Injector) {
		this.getSshCredentialsQuery = this.injector.get(GetSshCredentialsDocument);
		this.firstSshCredentialsQuery = this.injector.get(FirstSshCredentialsDocument);
		this.countSshCredentialsQuery = this.injector.get(CountSshCredentialsDocument);
		this.findSshCredentialsQuery = this.injector.get(FindSshCredentialsDocument);
		this.searchSshCredentialsQuery = this.injector.get(SearchSshCredentialsDocument);
		this.exportSchemaSshCredentialsQuery = this.injector.get(ExportSchemaSshCredentialsDocument);
		this.exportDataSshCredentialsQuery = this.injector.get(ExportDataSshCredentialsDocument);
		this.customQuerySshCredentialsQuery = this.injector.get(CustomQuerySshCredentialsDocument);
		this.customQueryIdSshCredentialsQuery = this.injector.get(CustomQueryIdSshCredentialsDocument);
		this.usedSshCredentialsQuery = this.injector.get(UsedSshCredentialsDocument);
		this.existSshCredentialsQuery = this.injector.get(ExistSshCredentialsDocument);
		this.findRecyclesSshCredentialsQuery = this.injector.get(FindRecyclesSshCredentialsDocument);
		this.countRecyclesSshCredentialsQuery = this.injector.get(CountRecyclesSshCredentialsDocument);
		this.readOnlySshCredentialsQuery = this.injector.get(ReadOnlySshCredentialsDocument);
		this.findArchivedSshCredentialsQuery = this.injector.get(FindArchivedSshCredentialsDocument);
		this.countAllSshCredentialsQuery = this.injector.get(CountAllSshCredentialsDocument);
		this.findDynamicPropertyFieldsSshCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsSshCredentialsDocument);
		this.findUnassociatedScanConfigurationsSshCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsSshCredentialsDocument);
		this.findUnassociatedInventoryHistoriesSshCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesSshCredentialsDocument);
		this.findUnassociatedScanByLdapsSshCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsSshCredentialsDocument);
		this.insertSshCredentialsMutation = this.injector.get(InsertSshCredentialsDocument);
		this.updateSshCredentialsMutation = this.injector.get(UpdateSshCredentialsDocument);
		this.importDataSshCredentialsMutation = this.injector.get(ImportDataSshCredentialsDocument);
		this.updateManySshCredentialsMutation = this.injector.get(UpdateManySshCredentialsDocument);
		this.deleteSshCredentialsMutation = this.injector.get(DeleteSshCredentialsDocument);
		this.restoreSshCredentialsMutation = this.injector.get(RestoreSshCredentialsDocument);
		this.recycleSshCredentialsMutation = this.injector.get(RecycleSshCredentialsDocument);
		this.restoreArchivedSshCredentialsMutation = this.injector.get(RestoreArchivedSshCredentialsDocument);
		this.archivedSshCredentialsMutation = this.injector.get(ArchivedSshCredentialsDocument);
		this.addFileDynamicFieldSshCredentialsMutation = this.injector.get(AddFileDynamicFieldSshCredentialsDocument);
		this.removeFileDynamicFieldSshCredentialsMutation = this.injector.get(RemoveFileDynamicFieldSshCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSshCredential> {
    
    		let variables: GetSshCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfSshCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSshCredential> {
    
    		let variables: FirstSshCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSshCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: FindSshCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: SearchSshCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSshCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSshCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: CustomQuerySshCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: CustomQueryIdSshCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSshCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: FindRecyclesSshCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesSshCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlySshCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlySshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSshCredential,
		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSshCredential> {
    
    		let variables: FindArchivedSshCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllSshCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsSshCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsSshCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesSshCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsSshCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsSshCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SshCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSshCredential> {
    
    		let variables: InsertSshCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSshCredential> {
    
    		let variables: UpdateSshCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSshCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSshCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySshCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedSshCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldSshCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldSshCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldSshCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sshCredentials.removeFileDynamicField));
    	}
    
}