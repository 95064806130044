import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLdapPropertiesBaseVariables, FirstLdapPropertiesBaseVariables, CountLdapPropertiesBaseVariables, FindLdapPropertiesBaseVariables, SearchLdapPropertiesBaseVariables, ExportSchemaLdapPropertiesBaseVariables, ExportDataLdapPropertiesBaseVariables, CustomQueryLdapPropertiesBaseVariables, CustomQueryIdLdapPropertiesBaseVariables, UsedLdapPropertiesBaseVariables, ExistLdapPropertiesBaseVariables } from '../gqls'
import { GetLdapPropertiesDocument, FirstLdapPropertiesDocument, CountLdapPropertiesDocument, FindLdapPropertiesDocument, SearchLdapPropertiesDocument, ExportSchemaLdapPropertiesDocument, ExportDataLdapPropertiesDocument, CustomQueryLdapPropertiesDocument, CustomQueryIdLdapPropertiesDocument, UsedLdapPropertiesDocument, ExistLdapPropertiesDocument } from '../gqls'
import { ServiceSingleResultOfLdapProperty, QueryContextOfLdapProperty, FilterOfLdapProperty, ServiceSingleResultOfInt64, ServiceListResultOfLdapProperty, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LdapPropertyBaseService {
    
public modelName = 'ldapProperty';
public modelPluralName = 'ldapProperties';

	private getLdapPropertiesQuery: GetLdapPropertiesDocument;
	private firstLdapPropertiesQuery: FirstLdapPropertiesDocument;
	private countLdapPropertiesQuery: CountLdapPropertiesDocument;
	private findLdapPropertiesQuery: FindLdapPropertiesDocument;
	private searchLdapPropertiesQuery: SearchLdapPropertiesDocument;
	private exportSchemaLdapPropertiesQuery: ExportSchemaLdapPropertiesDocument;
	private exportDataLdapPropertiesQuery: ExportDataLdapPropertiesDocument;
	private customQueryLdapPropertiesQuery: CustomQueryLdapPropertiesDocument;
	private customQueryIdLdapPropertiesQuery: CustomQueryIdLdapPropertiesDocument;
	private usedLdapPropertiesQuery: UsedLdapPropertiesDocument;
	private existLdapPropertiesQuery: ExistLdapPropertiesDocument;

	constructor(private injector: Injector) {
		this.getLdapPropertiesQuery = this.injector.get(GetLdapPropertiesDocument);
		this.firstLdapPropertiesQuery = this.injector.get(FirstLdapPropertiesDocument);
		this.countLdapPropertiesQuery = this.injector.get(CountLdapPropertiesDocument);
		this.findLdapPropertiesQuery = this.injector.get(FindLdapPropertiesDocument);
		this.searchLdapPropertiesQuery = this.injector.get(SearchLdapPropertiesDocument);
		this.exportSchemaLdapPropertiesQuery = this.injector.get(ExportSchemaLdapPropertiesDocument);
		this.exportDataLdapPropertiesQuery = this.injector.get(ExportDataLdapPropertiesDocument);
		this.customQueryLdapPropertiesQuery = this.injector.get(CustomQueryLdapPropertiesDocument);
		this.customQueryIdLdapPropertiesQuery = this.injector.get(CustomQueryIdLdapPropertiesDocument);
		this.usedLdapPropertiesQuery = this.injector.get(UsedLdapPropertiesDocument);
		this.existLdapPropertiesQuery = this.injector.get(ExistLdapPropertiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapProperty> {
    
    		let variables: GetLdapPropertiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.get));
            }
            else{
                let result:ServiceSingleResultOfLdapProperty={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapProperty,
		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapProperty> {
    
    		let variables: FirstLdapPropertiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLdapPropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.countLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapProperty,
		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapProperty> {
    
    		let variables: FindLdapPropertiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLdapProperty,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapProperty> {
    
    		let variables: SearchLdapPropertiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLdapPropertiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLdapPropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLdapProperty,
		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapProperty> {
    
    		let variables: CustomQueryLdapPropertiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLdapProperty,
		@Args('filter?') filter?: FilterOfLdapProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapProperty> {
    
    		let variables: CustomQueryIdLdapPropertiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLdapPropertiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLdapPropertiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLdapPropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapProperties.exist));
    	}
    
    
}