import { Component, Input, OnInit } from '@angular/core';
import { AuthentificationService } from '@clarilog/core';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

@Component({
  selector: 'cl-mobile-tab-bar',
  templateUrl: './mobile-tab-bar.component.html',
  styleUrls: ['./mobile-tab-bar.component.scss'],
})
export class MobileTabBarComponent implements OnInit {
  @Input() navItems: [];

  @Input() state: ModelState;

  constructor() {}

  ngOnInit(): void {}
}
