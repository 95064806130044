import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPnpSignedDriversBaseVariables, FirstPnpSignedDriversBaseVariables, CountPnpSignedDriversBaseVariables, FindPnpSignedDriversBaseVariables, SearchPnpSignedDriversBaseVariables, ExportSchemaPnpSignedDriversBaseVariables, ExportDataPnpSignedDriversBaseVariables, CustomQueryPnpSignedDriversBaseVariables, CustomQueryIdPnpSignedDriversBaseVariables, UsedPnpSignedDriversBaseVariables, ExistPnpSignedDriversBaseVariables } from '../gqls'
import { GetPnpSignedDriversDocument, FirstPnpSignedDriversDocument, CountPnpSignedDriversDocument, FindPnpSignedDriversDocument, SearchPnpSignedDriversDocument, ExportSchemaPnpSignedDriversDocument, ExportDataPnpSignedDriversDocument, CustomQueryPnpSignedDriversDocument, CustomQueryIdPnpSignedDriversDocument, UsedPnpSignedDriversDocument, ExistPnpSignedDriversDocument } from '../gqls'
import { ServiceSingleResultOfPnpSignedDriver, QueryContextOfPnpSignedDriver, FilterOfPnpSignedDriver, ServiceSingleResultOfInt64, ServiceListResultOfPnpSignedDriver, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PnpSignedDriverBaseService {
    
public modelName = 'pnpSignedDriver';
public modelPluralName = 'pnpSignedDrivers';

	private getPnpSignedDriversQuery: GetPnpSignedDriversDocument;
	private firstPnpSignedDriversQuery: FirstPnpSignedDriversDocument;
	private countPnpSignedDriversQuery: CountPnpSignedDriversDocument;
	private findPnpSignedDriversQuery: FindPnpSignedDriversDocument;
	private searchPnpSignedDriversQuery: SearchPnpSignedDriversDocument;
	private exportSchemaPnpSignedDriversQuery: ExportSchemaPnpSignedDriversDocument;
	private exportDataPnpSignedDriversQuery: ExportDataPnpSignedDriversDocument;
	private customQueryPnpSignedDriversQuery: CustomQueryPnpSignedDriversDocument;
	private customQueryIdPnpSignedDriversQuery: CustomQueryIdPnpSignedDriversDocument;
	private usedPnpSignedDriversQuery: UsedPnpSignedDriversDocument;
	private existPnpSignedDriversQuery: ExistPnpSignedDriversDocument;

	constructor(private injector: Injector) {
		this.getPnpSignedDriversQuery = this.injector.get(GetPnpSignedDriversDocument);
		this.firstPnpSignedDriversQuery = this.injector.get(FirstPnpSignedDriversDocument);
		this.countPnpSignedDriversQuery = this.injector.get(CountPnpSignedDriversDocument);
		this.findPnpSignedDriversQuery = this.injector.get(FindPnpSignedDriversDocument);
		this.searchPnpSignedDriversQuery = this.injector.get(SearchPnpSignedDriversDocument);
		this.exportSchemaPnpSignedDriversQuery = this.injector.get(ExportSchemaPnpSignedDriversDocument);
		this.exportDataPnpSignedDriversQuery = this.injector.get(ExportDataPnpSignedDriversDocument);
		this.customQueryPnpSignedDriversQuery = this.injector.get(CustomQueryPnpSignedDriversDocument);
		this.customQueryIdPnpSignedDriversQuery = this.injector.get(CustomQueryIdPnpSignedDriversDocument);
		this.usedPnpSignedDriversQuery = this.injector.get(UsedPnpSignedDriversDocument);
		this.existPnpSignedDriversQuery = this.injector.get(ExistPnpSignedDriversDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpSignedDriver> {
    
    		let variables: GetPnpSignedDriversBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.get));
            }
            else{
                let result:ServiceSingleResultOfPnpSignedDriver={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpSignedDriver,
		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpSignedDriver> {
    
    		let variables: FirstPnpSignedDriversBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPnpSignedDriversBaseVariables = {
    			filter: filter
    		}
    				return this.countPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpSignedDriver,
		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpSignedDriver> {
    
    		let variables: FindPnpSignedDriversBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPnpSignedDriver,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpSignedDriver> {
    
    		let variables: SearchPnpSignedDriversBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPnpSignedDriversBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPnpSignedDriversBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPnpSignedDriver,
		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpSignedDriver> {
    
    		let variables: CustomQueryPnpSignedDriversBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPnpSignedDriver,
		@Args('filter?') filter?: FilterOfPnpSignedDriver,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpSignedDriver> {
    
    		let variables: CustomQueryIdPnpSignedDriversBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPnpSignedDriversBaseVariables = {
    			ids: ids
    		}
    				return this.usedPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPnpSignedDriversBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPnpSignedDriversQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpSignedDrivers.exist));
    	}
    
    
}