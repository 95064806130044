import { AfterContentInit, Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Column, ListComponentBase } from '@clarilog/shared2';
import { ModelDataSourceContext, ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { LocationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { GqlField, GqlSubField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import DataSource from 'devextreme/data/data_source';
import ArrayStore from 'devextreme/data/array_store';
import { CoreGraphQLSource, CriteriaHelpers } from '@clarilog/core/services2';
import { lastValueFrom, of } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { LoadOptions } from 'devextreme/data/load_options';

@Component({
  selector: 'clc-editable-list',
  templateUrl: './editable-list.component.html',
  styleUrls: ['./editable-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => EditableListComponent),
      multi: true,
    },
  ],
})
export class EditableListComponent implements ControlValueAccessor, OnInit {

  @Input() state: ModelState;
  @Input() options: any;

  isInit = false;

  constructor(private locationService: LocationCoreService) {
  }

  private getSubField(path: string, obj: any): any {
    if (path.split(".").length > 1) {
      let splitPath = path.split(".")
      let first = splitPath[0];

      splitPath.splice(0, 1);
      return this.getSubField(splitPath.join("."), obj[first])
    } else {
      return obj[path];
    }
  }

  sourceInitialisation() {
    this.source = new ModelDataSourceContext({
      datasource: new DataSource({
        store: new CustomStore({
          key: this.options.keyExpr,
          load: (loadOptions: LoadOptions) => {
            this.options.source.datasource.filter(loadOptions.filter ?? null);
            this.options.source.datasource.sort(loadOptions.sort ?? null);
            return this.options.source.datasource.load().then((res: any[]) => {
              res = res.map(el => {
                let field = this.getSubField(this.options.keyExpr, el);
                let existObject = this.objectData.find(p => this.getSubField(this.options.objectKeyMapping,p) === field);
                if (existObject == undefined) {
                  existObject = {};
                  for (let col of this.options?.objectColumns) {
                    if (col.editOptions?.defaultValue != undefined) {
                      existObject[col.field] = col.editOptions?.defaultValue;
                    }
                  }
                }
                return { ...el, ...existObject };
              });
              return { data: res, totalCount: res.length };
            });
          },
          update: (id, entry) => {
            let existObject = this.objectData.find(el => el[this.options.objectKeyMapping] === id);
            let object = {};
            if (existObject != undefined) {
              object = existObject;
            } else {
              object[this.options?.objectKeyMapping] = id;
            }

            let keys = Object.keys(entry);

            for (let key of keys) {
              object[key] = entry[key];
            }

            if (existObject == undefined) {
              this.objectData.push(object);
            }
            this.onTouched(this.objectData);
            this.onChange(this.objectData);
            return lastValueFrom(of(true));
          },
        }),
      }),
    });
    this.isInit = true;
  }

  /** @inheritdoc */
  onChange: any = () => {
  };
  /** @inheritdoc */
  onTouched: any = () => {
  };
  //options.objectKeyMapping
  //permet de faire le liens entre la source de données et la liste sur object
  source: ModelDataSourceContext;
  columns: Column[] = [];
  objectData: any[];

  selectedKeys: any[];

  update(e) {
    console.log('update', e);
  }

  ngOnInit(): void {
    let objectColumns = JSON.parse(JSON.stringify(this.options.objectColumns));
    let sourceColumns = JSON.parse(JSON.stringify(this.options.sourceColumns));
    this.columns.push(...sourceColumns);
    this.columns.push(...objectColumns);
    this.sourceInitialisation();

  }

  writeValue(obj: any): void {

    if (obj != undefined && obj.length != undefined) {
      this.objectData = obj;
      this.source.datasource.reload();
    }
  }

  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {
  }

}
