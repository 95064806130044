import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSnmpMapInformationsBaseVariables, FirstSnmpMapInformationsBaseVariables, CountSnmpMapInformationsBaseVariables, FindSnmpMapInformationsBaseVariables, SearchSnmpMapInformationsBaseVariables, ExportSchemaSnmpMapInformationsBaseVariables, ExportDataSnmpMapInformationsBaseVariables, CustomQuerySnmpMapInformationsBaseVariables, CustomQueryIdSnmpMapInformationsBaseVariables, UsedSnmpMapInformationsBaseVariables, ExistSnmpMapInformationsBaseVariables, FindRecyclesSnmpMapInformationsBaseVariables, CountRecyclesSnmpMapInformationsBaseVariables, ReadOnlySnmpMapInformationsBaseVariables, FindArchivedSnmpMapInformationsBaseVariables, CountAllSnmpMapInformationsBaseVariables, FindDynamicPropertyFieldsSnmpMapInformationsBaseVariables, InsertSnmpMapInformationsBaseVariables, UpdateSnmpMapInformationsBaseVariables, ImportDataSnmpMapInformationsBaseVariables, UpdateManySnmpMapInformationsBaseVariables, DeleteSnmpMapInformationsBaseVariables, ImportFileSnmpMapInformationsBaseVariables, DownloadClarilogSnmpBrowserSnmpMapInformationsBaseVariables, RestoreSnmpMapInformationsBaseVariables, RecycleSnmpMapInformationsBaseVariables, RestoreArchivedSnmpMapInformationsBaseVariables, ArchivedSnmpMapInformationsBaseVariables, AddFileDynamicFieldSnmpMapInformationsBaseVariables, RemoveFileDynamicFieldSnmpMapInformationsBaseVariables } from '../gqls'
import { GetSnmpMapInformationsDocument, FirstSnmpMapInformationsDocument, CountSnmpMapInformationsDocument, FindSnmpMapInformationsDocument, SearchSnmpMapInformationsDocument, ExportSchemaSnmpMapInformationsDocument, ExportDataSnmpMapInformationsDocument, CustomQuerySnmpMapInformationsDocument, CustomQueryIdSnmpMapInformationsDocument, UsedSnmpMapInformationsDocument, ExistSnmpMapInformationsDocument, FindRecyclesSnmpMapInformationsDocument, CountRecyclesSnmpMapInformationsDocument, ReadOnlySnmpMapInformationsDocument, FindArchivedSnmpMapInformationsDocument, CountAllSnmpMapInformationsDocument, FindDynamicPropertyFieldsSnmpMapInformationsDocument, InsertSnmpMapInformationsDocument, UpdateSnmpMapInformationsDocument, ImportDataSnmpMapInformationsDocument, UpdateManySnmpMapInformationsDocument, DeleteSnmpMapInformationsDocument, ImportFileSnmpMapInformationsDocument, DownloadClarilogSnmpBrowserSnmpMapInformationsDocument, RestoreSnmpMapInformationsDocument, RecycleSnmpMapInformationsDocument, RestoreArchivedSnmpMapInformationsDocument, ArchivedSnmpMapInformationsDocument, AddFileDynamicFieldSnmpMapInformationsDocument, RemoveFileDynamicFieldSnmpMapInformationsDocument } from '../gqls'
import { ServiceSingleResultOfSnmpMapInformation, QueryContextOfSnmpMapInformation, FilterOfSnmpMapInformation, ServiceSingleResultOfInt64, ServiceListResultOfSnmpMapInformation, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfSnmpMapInformation, SnmpMapInformationInput, ServiceSingleResultOfFileDescriptor } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SnmpMapInformationBaseService {
    
public modelName = 'snmpMapInformation';
public modelPluralName = 'snmpMapInformations';

	private getSnmpMapInformationsQuery: GetSnmpMapInformationsDocument;
	private firstSnmpMapInformationsQuery: FirstSnmpMapInformationsDocument;
	private countSnmpMapInformationsQuery: CountSnmpMapInformationsDocument;
	private findSnmpMapInformationsQuery: FindSnmpMapInformationsDocument;
	private searchSnmpMapInformationsQuery: SearchSnmpMapInformationsDocument;
	private exportSchemaSnmpMapInformationsQuery: ExportSchemaSnmpMapInformationsDocument;
	private exportDataSnmpMapInformationsQuery: ExportDataSnmpMapInformationsDocument;
	private customQuerySnmpMapInformationsQuery: CustomQuerySnmpMapInformationsDocument;
	private customQueryIdSnmpMapInformationsQuery: CustomQueryIdSnmpMapInformationsDocument;
	private usedSnmpMapInformationsQuery: UsedSnmpMapInformationsDocument;
	private existSnmpMapInformationsQuery: ExistSnmpMapInformationsDocument;
	private findRecyclesSnmpMapInformationsQuery: FindRecyclesSnmpMapInformationsDocument;
	private countRecyclesSnmpMapInformationsQuery: CountRecyclesSnmpMapInformationsDocument;
	private readOnlySnmpMapInformationsQuery: ReadOnlySnmpMapInformationsDocument;
	private findArchivedSnmpMapInformationsQuery: FindArchivedSnmpMapInformationsDocument;
	private countAllSnmpMapInformationsQuery: CountAllSnmpMapInformationsDocument;
	private findDynamicPropertyFieldsSnmpMapInformationsQuery: FindDynamicPropertyFieldsSnmpMapInformationsDocument;
	private insertSnmpMapInformationsMutation: InsertSnmpMapInformationsDocument;
	private updateSnmpMapInformationsMutation: UpdateSnmpMapInformationsDocument;
	private importDataSnmpMapInformationsMutation: ImportDataSnmpMapInformationsDocument;
	private updateManySnmpMapInformationsMutation: UpdateManySnmpMapInformationsDocument;
	private deleteSnmpMapInformationsMutation: DeleteSnmpMapInformationsDocument;
	private importFileSnmpMapInformationsMutation: ImportFileSnmpMapInformationsDocument;
	private downloadClarilogSnmpBrowserSnmpMapInformationsMutation: DownloadClarilogSnmpBrowserSnmpMapInformationsDocument;
	private restoreSnmpMapInformationsMutation: RestoreSnmpMapInformationsDocument;
	private recycleSnmpMapInformationsMutation: RecycleSnmpMapInformationsDocument;
	private restoreArchivedSnmpMapInformationsMutation: RestoreArchivedSnmpMapInformationsDocument;
	private archivedSnmpMapInformationsMutation: ArchivedSnmpMapInformationsDocument;
	private addFileDynamicFieldSnmpMapInformationsMutation: AddFileDynamicFieldSnmpMapInformationsDocument;
	private removeFileDynamicFieldSnmpMapInformationsMutation: RemoveFileDynamicFieldSnmpMapInformationsDocument;

	constructor(private injector: Injector) {
		this.getSnmpMapInformationsQuery = this.injector.get(GetSnmpMapInformationsDocument);
		this.firstSnmpMapInformationsQuery = this.injector.get(FirstSnmpMapInformationsDocument);
		this.countSnmpMapInformationsQuery = this.injector.get(CountSnmpMapInformationsDocument);
		this.findSnmpMapInformationsQuery = this.injector.get(FindSnmpMapInformationsDocument);
		this.searchSnmpMapInformationsQuery = this.injector.get(SearchSnmpMapInformationsDocument);
		this.exportSchemaSnmpMapInformationsQuery = this.injector.get(ExportSchemaSnmpMapInformationsDocument);
		this.exportDataSnmpMapInformationsQuery = this.injector.get(ExportDataSnmpMapInformationsDocument);
		this.customQuerySnmpMapInformationsQuery = this.injector.get(CustomQuerySnmpMapInformationsDocument);
		this.customQueryIdSnmpMapInformationsQuery = this.injector.get(CustomQueryIdSnmpMapInformationsDocument);
		this.usedSnmpMapInformationsQuery = this.injector.get(UsedSnmpMapInformationsDocument);
		this.existSnmpMapInformationsQuery = this.injector.get(ExistSnmpMapInformationsDocument);
		this.findRecyclesSnmpMapInformationsQuery = this.injector.get(FindRecyclesSnmpMapInformationsDocument);
		this.countRecyclesSnmpMapInformationsQuery = this.injector.get(CountRecyclesSnmpMapInformationsDocument);
		this.readOnlySnmpMapInformationsQuery = this.injector.get(ReadOnlySnmpMapInformationsDocument);
		this.findArchivedSnmpMapInformationsQuery = this.injector.get(FindArchivedSnmpMapInformationsDocument);
		this.countAllSnmpMapInformationsQuery = this.injector.get(CountAllSnmpMapInformationsDocument);
		this.findDynamicPropertyFieldsSnmpMapInformationsQuery = this.injector.get(FindDynamicPropertyFieldsSnmpMapInformationsDocument);
		this.insertSnmpMapInformationsMutation = this.injector.get(InsertSnmpMapInformationsDocument);
		this.updateSnmpMapInformationsMutation = this.injector.get(UpdateSnmpMapInformationsDocument);
		this.importDataSnmpMapInformationsMutation = this.injector.get(ImportDataSnmpMapInformationsDocument);
		this.updateManySnmpMapInformationsMutation = this.injector.get(UpdateManySnmpMapInformationsDocument);
		this.deleteSnmpMapInformationsMutation = this.injector.get(DeleteSnmpMapInformationsDocument);
		this.importFileSnmpMapInformationsMutation = this.injector.get(ImportFileSnmpMapInformationsDocument);
		this.downloadClarilogSnmpBrowserSnmpMapInformationsMutation = this.injector.get(DownloadClarilogSnmpBrowserSnmpMapInformationsDocument);
		this.restoreSnmpMapInformationsMutation = this.injector.get(RestoreSnmpMapInformationsDocument);
		this.recycleSnmpMapInformationsMutation = this.injector.get(RecycleSnmpMapInformationsDocument);
		this.restoreArchivedSnmpMapInformationsMutation = this.injector.get(RestoreArchivedSnmpMapInformationsDocument);
		this.archivedSnmpMapInformationsMutation = this.injector.get(ArchivedSnmpMapInformationsDocument);
		this.addFileDynamicFieldSnmpMapInformationsMutation = this.injector.get(AddFileDynamicFieldSnmpMapInformationsDocument);
		this.removeFileDynamicFieldSnmpMapInformationsMutation = this.injector.get(RemoveFileDynamicFieldSnmpMapInformationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformation> {
    
    		let variables: GetSnmpMapInformationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.get));
            }
            else{
                let result:ServiceSingleResultOfSnmpMapInformation={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformation> {
    
    		let variables: FirstSnmpMapInformationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSnmpMapInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.countSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: FindSnmpMapInformationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: SearchSnmpMapInformationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSnmpMapInformationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSnmpMapInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: CustomQuerySnmpMapInformationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: CustomQueryIdSnmpMapInformationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSnmpMapInformationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: FindRecyclesSnmpMapInformationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesSnmpMapInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlySnmpMapInformationsBaseVariables = {
    			id: id
    		}
    				return this.readOnlySnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpMapInformation,
		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpMapInformation> {
    
    		let variables: FindArchivedSnmpMapInformationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllSnmpMapInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsSnmpMapInformationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsSnmpMapInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SnmpMapInformationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformation> {
    
    		let variables: InsertSnmpMapInformationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpMapInformation> {
    
    		let variables: UpdateSnmpMapInformationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSnmpMapInformationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpMapInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySnmpMapInformationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.delete));
    	}

    	/**  */
    	@InjectArgs
    	public importFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('snmpMapInformation?') snmpMapInformation?: SnmpMapInformationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportFileSnmpMapInformationsBaseVariables = {
    			snmpMapInformation: snmpMapInformation
    		}
    				return this.importFileSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.importFile));
    	}

    	/**  */
    	@InjectArgs
    	public downloadClarilogSnmpBrowser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFileDescriptor> {
    
    		let variables: DownloadClarilogSnmpBrowserSnmpMapInformationsBaseVariables = {
    
    		}
    				return this.downloadClarilogSnmpBrowserSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.downloadClarilogSnmpBrowser));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedSnmpMapInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldSnmpMapInformationsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldSnmpMapInformationsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldSnmpMapInformationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpMapInformations.removeFileDynamicField));
    	}
    
}