import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAlertTasksBaseVariables, FirstAlertTasksBaseVariables, CountAlertTasksBaseVariables, FindAlertTasksBaseVariables, SearchAlertTasksBaseVariables, ExportSchemaAlertTasksBaseVariables, ExportDataAlertTasksBaseVariables, CustomQueryAlertTasksBaseVariables, CustomQueryIdAlertTasksBaseVariables, UsedAlertTasksBaseVariables, ExistAlertTasksBaseVariables, InsertAlertTasksBaseVariables, UpdateAlertTasksBaseVariables, ImportDataAlertTasksBaseVariables, UpdateManyAlertTasksBaseVariables, DeleteAlertTasksBaseVariables } from '../gqls'
import { GetAlertTasksDocument, FirstAlertTasksDocument, CountAlertTasksDocument, FindAlertTasksDocument, SearchAlertTasksDocument, ExportSchemaAlertTasksDocument, ExportDataAlertTasksDocument, CustomQueryAlertTasksDocument, CustomQueryIdAlertTasksDocument, UsedAlertTasksDocument, ExistAlertTasksDocument, InsertAlertTasksDocument, UpdateAlertTasksDocument, ImportDataAlertTasksDocument, UpdateManyAlertTasksDocument, DeleteAlertTasksDocument } from '../gqls'
import { ServiceSingleResultOfAlertTask, QueryContextOfAlertTask, FilterOfAlertTask, ServiceSingleResultOfInt64, ServiceListResultOfAlertTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AlertTaskInput, FieldUpdateOperatorOfAlertTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AlertTaskBaseService {
    
public modelName = 'alertTask';
public modelPluralName = 'alertTasks';

	private getAlertTasksQuery: GetAlertTasksDocument;
	private firstAlertTasksQuery: FirstAlertTasksDocument;
	private countAlertTasksQuery: CountAlertTasksDocument;
	private findAlertTasksQuery: FindAlertTasksDocument;
	private searchAlertTasksQuery: SearchAlertTasksDocument;
	private exportSchemaAlertTasksQuery: ExportSchemaAlertTasksDocument;
	private exportDataAlertTasksQuery: ExportDataAlertTasksDocument;
	private customQueryAlertTasksQuery: CustomQueryAlertTasksDocument;
	private customQueryIdAlertTasksQuery: CustomQueryIdAlertTasksDocument;
	private usedAlertTasksQuery: UsedAlertTasksDocument;
	private existAlertTasksQuery: ExistAlertTasksDocument;
	private insertAlertTasksMutation: InsertAlertTasksDocument;
	private updateAlertTasksMutation: UpdateAlertTasksDocument;
	private importDataAlertTasksMutation: ImportDataAlertTasksDocument;
	private updateManyAlertTasksMutation: UpdateManyAlertTasksDocument;
	private deleteAlertTasksMutation: DeleteAlertTasksDocument;

	constructor(private injector: Injector) {
		this.getAlertTasksQuery = this.injector.get(GetAlertTasksDocument);
		this.firstAlertTasksQuery = this.injector.get(FirstAlertTasksDocument);
		this.countAlertTasksQuery = this.injector.get(CountAlertTasksDocument);
		this.findAlertTasksQuery = this.injector.get(FindAlertTasksDocument);
		this.searchAlertTasksQuery = this.injector.get(SearchAlertTasksDocument);
		this.exportSchemaAlertTasksQuery = this.injector.get(ExportSchemaAlertTasksDocument);
		this.exportDataAlertTasksQuery = this.injector.get(ExportDataAlertTasksDocument);
		this.customQueryAlertTasksQuery = this.injector.get(CustomQueryAlertTasksDocument);
		this.customQueryIdAlertTasksQuery = this.injector.get(CustomQueryIdAlertTasksDocument);
		this.usedAlertTasksQuery = this.injector.get(UsedAlertTasksDocument);
		this.existAlertTasksQuery = this.injector.get(ExistAlertTasksDocument);
		this.insertAlertTasksMutation = this.injector.get(InsertAlertTasksDocument);
		this.updateAlertTasksMutation = this.injector.get(UpdateAlertTasksDocument);
		this.importDataAlertTasksMutation = this.injector.get(ImportDataAlertTasksDocument);
		this.updateManyAlertTasksMutation = this.injector.get(UpdateManyAlertTasksDocument);
		this.deleteAlertTasksMutation = this.injector.get(DeleteAlertTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlertTask> {
    
    		let variables: GetAlertTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.get));
            }
            else{
                let result:ServiceSingleResultOfAlertTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlertTask,
		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlertTask> {
    
    		let variables: FirstAlertTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAlertTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlertTask,
		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlertTask> {
    
    		let variables: FindAlertTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAlertTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlertTask> {
    
    		let variables: SearchAlertTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAlertTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAlertTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAlertTask,
		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlertTask> {
    
    		let variables: CustomQueryAlertTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAlertTask,
		@Args('filter?') filter?: FilterOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlertTask> {
    
    		let variables: CustomQueryIdAlertTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAlertTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAlertTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAlertTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AlertTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlertTask> {
    
    		let variables: InsertAlertTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertAlertTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlertTask> {
    
    		let variables: UpdateAlertTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAlertTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAlertTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAlertTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlertTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAlertTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAlertTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAlertTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAlertTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alertTasks.delete));
    	}
    
}