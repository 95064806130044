import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetKeyboardsBaseVariables, FirstKeyboardsBaseVariables, CountKeyboardsBaseVariables, FindKeyboardsBaseVariables, SearchKeyboardsBaseVariables, ExportSchemaKeyboardsBaseVariables, ExportDataKeyboardsBaseVariables, CustomQueryKeyboardsBaseVariables, CustomQueryIdKeyboardsBaseVariables, UsedKeyboardsBaseVariables, ExistKeyboardsBaseVariables } from '../gqls'
import { GetKeyboardsDocument, FirstKeyboardsDocument, CountKeyboardsDocument, FindKeyboardsDocument, SearchKeyboardsDocument, ExportSchemaKeyboardsDocument, ExportDataKeyboardsDocument, CustomQueryKeyboardsDocument, CustomQueryIdKeyboardsDocument, UsedKeyboardsDocument, ExistKeyboardsDocument } from '../gqls'
import { ServiceSingleResultOfKeyboard, QueryContextOfKeyboard, FilterOfKeyboard, ServiceSingleResultOfInt64, ServiceListResultOfKeyboard, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class KeyboardBaseService {
    
public modelName = 'keyboard';
public modelPluralName = 'keyboards';

	private getKeyboardsQuery: GetKeyboardsDocument;
	private firstKeyboardsQuery: FirstKeyboardsDocument;
	private countKeyboardsQuery: CountKeyboardsDocument;
	private findKeyboardsQuery: FindKeyboardsDocument;
	private searchKeyboardsQuery: SearchKeyboardsDocument;
	private exportSchemaKeyboardsQuery: ExportSchemaKeyboardsDocument;
	private exportDataKeyboardsQuery: ExportDataKeyboardsDocument;
	private customQueryKeyboardsQuery: CustomQueryKeyboardsDocument;
	private customQueryIdKeyboardsQuery: CustomQueryIdKeyboardsDocument;
	private usedKeyboardsQuery: UsedKeyboardsDocument;
	private existKeyboardsQuery: ExistKeyboardsDocument;

	constructor(private injector: Injector) {
		this.getKeyboardsQuery = this.injector.get(GetKeyboardsDocument);
		this.firstKeyboardsQuery = this.injector.get(FirstKeyboardsDocument);
		this.countKeyboardsQuery = this.injector.get(CountKeyboardsDocument);
		this.findKeyboardsQuery = this.injector.get(FindKeyboardsDocument);
		this.searchKeyboardsQuery = this.injector.get(SearchKeyboardsDocument);
		this.exportSchemaKeyboardsQuery = this.injector.get(ExportSchemaKeyboardsDocument);
		this.exportDataKeyboardsQuery = this.injector.get(ExportDataKeyboardsDocument);
		this.customQueryKeyboardsQuery = this.injector.get(CustomQueryKeyboardsDocument);
		this.customQueryIdKeyboardsQuery = this.injector.get(CustomQueryIdKeyboardsDocument);
		this.usedKeyboardsQuery = this.injector.get(UsedKeyboardsDocument);
		this.existKeyboardsQuery = this.injector.get(ExistKeyboardsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfKeyboard> {
    
    		let variables: GetKeyboardsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.get));
            }
            else{
                let result:ServiceSingleResultOfKeyboard={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfKeyboard,
		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfKeyboard> {
    
    		let variables: FirstKeyboardsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountKeyboardsBaseVariables = {
    			filter: filter
    		}
    				return this.countKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfKeyboard,
		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfKeyboard> {
    
    		let variables: FindKeyboardsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfKeyboard,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfKeyboard> {
    
    		let variables: SearchKeyboardsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaKeyboardsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataKeyboardsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfKeyboard,
		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfKeyboard> {
    
    		let variables: CustomQueryKeyboardsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfKeyboard,
		@Args('filter?') filter?: FilterOfKeyboard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfKeyboard> {
    
    		let variables: CustomQueryIdKeyboardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedKeyboardsBaseVariables = {
    			ids: ids
    		}
    				return this.usedKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistKeyboardsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existKeyboardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.keyboards.exist));
    	}
    
    
}