import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanConfigurationAppointmentsBaseVariables, FirstScanConfigurationAppointmentsBaseVariables, CountScanConfigurationAppointmentsBaseVariables, FindScanConfigurationAppointmentsBaseVariables, SearchScanConfigurationAppointmentsBaseVariables, ExportSchemaScanConfigurationAppointmentsBaseVariables, ExportDataScanConfigurationAppointmentsBaseVariables, CustomQueryScanConfigurationAppointmentsBaseVariables, CustomQueryIdScanConfigurationAppointmentsBaseVariables, UsedScanConfigurationAppointmentsBaseVariables, ExistScanConfigurationAppointmentsBaseVariables, InsertScanConfigurationAppointmentsBaseVariables, UpdateScanConfigurationAppointmentsBaseVariables, ImportDataScanConfigurationAppointmentsBaseVariables, UpdateManyScanConfigurationAppointmentsBaseVariables, DeleteScanConfigurationAppointmentsBaseVariables } from '../gqls'
import { GetScanConfigurationAppointmentsDocument, FirstScanConfigurationAppointmentsDocument, CountScanConfigurationAppointmentsDocument, FindScanConfigurationAppointmentsDocument, SearchScanConfigurationAppointmentsDocument, ExportSchemaScanConfigurationAppointmentsDocument, ExportDataScanConfigurationAppointmentsDocument, CustomQueryScanConfigurationAppointmentsDocument, CustomQueryIdScanConfigurationAppointmentsDocument, UsedScanConfigurationAppointmentsDocument, ExistScanConfigurationAppointmentsDocument, InsertScanConfigurationAppointmentsDocument, UpdateScanConfigurationAppointmentsDocument, ImportDataScanConfigurationAppointmentsDocument, UpdateManyScanConfigurationAppointmentsDocument, DeleteScanConfigurationAppointmentsDocument } from '../gqls'
import { ServiceSingleResultOfScanConfigurationAppointment, QueryContextOfScanConfigurationAppointment, FilterOfScanConfigurationAppointment, ServiceSingleResultOfInt64, ServiceListResultOfScanConfigurationAppointment, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ScanConfigurationAppointmentInput, FieldUpdateOperatorOfScanConfigurationAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanConfigurationAppointmentBaseService {
    
public modelName = 'scanConfigurationAppointment';
public modelPluralName = 'scanConfigurationAppointments';

	private getScanConfigurationAppointmentsQuery: GetScanConfigurationAppointmentsDocument;
	private firstScanConfigurationAppointmentsQuery: FirstScanConfigurationAppointmentsDocument;
	private countScanConfigurationAppointmentsQuery: CountScanConfigurationAppointmentsDocument;
	private findScanConfigurationAppointmentsQuery: FindScanConfigurationAppointmentsDocument;
	private searchScanConfigurationAppointmentsQuery: SearchScanConfigurationAppointmentsDocument;
	private exportSchemaScanConfigurationAppointmentsQuery: ExportSchemaScanConfigurationAppointmentsDocument;
	private exportDataScanConfigurationAppointmentsQuery: ExportDataScanConfigurationAppointmentsDocument;
	private customQueryScanConfigurationAppointmentsQuery: CustomQueryScanConfigurationAppointmentsDocument;
	private customQueryIdScanConfigurationAppointmentsQuery: CustomQueryIdScanConfigurationAppointmentsDocument;
	private usedScanConfigurationAppointmentsQuery: UsedScanConfigurationAppointmentsDocument;
	private existScanConfigurationAppointmentsQuery: ExistScanConfigurationAppointmentsDocument;
	private insertScanConfigurationAppointmentsMutation: InsertScanConfigurationAppointmentsDocument;
	private updateScanConfigurationAppointmentsMutation: UpdateScanConfigurationAppointmentsDocument;
	private importDataScanConfigurationAppointmentsMutation: ImportDataScanConfigurationAppointmentsDocument;
	private updateManyScanConfigurationAppointmentsMutation: UpdateManyScanConfigurationAppointmentsDocument;
	private deleteScanConfigurationAppointmentsMutation: DeleteScanConfigurationAppointmentsDocument;

	constructor(private injector: Injector) {
		this.getScanConfigurationAppointmentsQuery = this.injector.get(GetScanConfigurationAppointmentsDocument);
		this.firstScanConfigurationAppointmentsQuery = this.injector.get(FirstScanConfigurationAppointmentsDocument);
		this.countScanConfigurationAppointmentsQuery = this.injector.get(CountScanConfigurationAppointmentsDocument);
		this.findScanConfigurationAppointmentsQuery = this.injector.get(FindScanConfigurationAppointmentsDocument);
		this.searchScanConfigurationAppointmentsQuery = this.injector.get(SearchScanConfigurationAppointmentsDocument);
		this.exportSchemaScanConfigurationAppointmentsQuery = this.injector.get(ExportSchemaScanConfigurationAppointmentsDocument);
		this.exportDataScanConfigurationAppointmentsQuery = this.injector.get(ExportDataScanConfigurationAppointmentsDocument);
		this.customQueryScanConfigurationAppointmentsQuery = this.injector.get(CustomQueryScanConfigurationAppointmentsDocument);
		this.customQueryIdScanConfigurationAppointmentsQuery = this.injector.get(CustomQueryIdScanConfigurationAppointmentsDocument);
		this.usedScanConfigurationAppointmentsQuery = this.injector.get(UsedScanConfigurationAppointmentsDocument);
		this.existScanConfigurationAppointmentsQuery = this.injector.get(ExistScanConfigurationAppointmentsDocument);
		this.insertScanConfigurationAppointmentsMutation = this.injector.get(InsertScanConfigurationAppointmentsDocument);
		this.updateScanConfigurationAppointmentsMutation = this.injector.get(UpdateScanConfigurationAppointmentsDocument);
		this.importDataScanConfigurationAppointmentsMutation = this.injector.get(ImportDataScanConfigurationAppointmentsDocument);
		this.updateManyScanConfigurationAppointmentsMutation = this.injector.get(UpdateManyScanConfigurationAppointmentsDocument);
		this.deleteScanConfigurationAppointmentsMutation = this.injector.get(DeleteScanConfigurationAppointmentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanConfigurationAppointment> {
    
    		let variables: GetScanConfigurationAppointmentsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.get));
            }
            else{
                let result:ServiceSingleResultOfScanConfigurationAppointment={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfigurationAppointment,
		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanConfigurationAppointment> {
    
    		let variables: FirstScanConfigurationAppointmentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanConfigurationAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfigurationAppointment,
		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfigurationAppointment> {
    
    		let variables: FindScanConfigurationAppointmentsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanConfigurationAppointment,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfigurationAppointment> {
    
    		let variables: SearchScanConfigurationAppointmentsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanConfigurationAppointmentsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanConfigurationAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanConfigurationAppointment,
		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfigurationAppointment> {
    
    		let variables: CustomQueryScanConfigurationAppointmentsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanConfigurationAppointment,
		@Args('filter?') filter?: FilterOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfigurationAppointment> {
    
    		let variables: CustomQueryIdScanConfigurationAppointmentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanConfigurationAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanConfigurationAppointmentsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanConfigurationAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanConfigurationAppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanConfigurationAppointment> {
    
    		let variables: InsertScanConfigurationAppointmentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanConfigurationAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanConfigurationAppointment> {
    
    		let variables: UpdateScanConfigurationAppointmentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanConfigurationAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanConfigurationAppointmentsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanConfigurationAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanConfigurationAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanConfigurationAppointmentsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanConfigurationAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanConfigurationAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanConfigurationAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanConfigurationAppointments.delete));
    	}
    
}