import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAppLogsBaseVariables, FirstAppLogsBaseVariables, CountAppLogsBaseVariables, FindAppLogsBaseVariables, SearchAppLogsBaseVariables, ExportSchemaAppLogsBaseVariables, ExportDataAppLogsBaseVariables, CustomQueryAppLogsBaseVariables, CustomQueryIdAppLogsBaseVariables, UsedAppLogsBaseVariables, ExistAppLogsBaseVariables, InsertAppLogsBaseVariables, UpdateAppLogsBaseVariables, ImportDataAppLogsBaseVariables, UpdateManyAppLogsBaseVariables, DeleteAppLogsBaseVariables } from '../gqls'
import { GetAppLogsDocument, FirstAppLogsDocument, CountAppLogsDocument, FindAppLogsDocument, SearchAppLogsDocument, ExportSchemaAppLogsDocument, ExportDataAppLogsDocument, CustomQueryAppLogsDocument, CustomQueryIdAppLogsDocument, UsedAppLogsDocument, ExistAppLogsDocument, InsertAppLogsDocument, UpdateAppLogsDocument, ImportDataAppLogsDocument, UpdateManyAppLogsDocument, DeleteAppLogsDocument } from '../gqls'
import { ServiceSingleResultOfAppLog, QueryContextOfAppLog, FilterOfAppLog, ServiceSingleResultOfInt64, ServiceListResultOfAppLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AppLogInput, FieldUpdateOperatorOfAppLog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AppLogBaseService {
    
public modelName = 'appLog';
public modelPluralName = 'appLogs';

	private getAppLogsQuery: GetAppLogsDocument;
	private firstAppLogsQuery: FirstAppLogsDocument;
	private countAppLogsQuery: CountAppLogsDocument;
	private findAppLogsQuery: FindAppLogsDocument;
	private searchAppLogsQuery: SearchAppLogsDocument;
	private exportSchemaAppLogsQuery: ExportSchemaAppLogsDocument;
	private exportDataAppLogsQuery: ExportDataAppLogsDocument;
	private customQueryAppLogsQuery: CustomQueryAppLogsDocument;
	private customQueryIdAppLogsQuery: CustomQueryIdAppLogsDocument;
	private usedAppLogsQuery: UsedAppLogsDocument;
	private existAppLogsQuery: ExistAppLogsDocument;
	private insertAppLogsMutation: InsertAppLogsDocument;
	private updateAppLogsMutation: UpdateAppLogsDocument;
	private importDataAppLogsMutation: ImportDataAppLogsDocument;
	private updateManyAppLogsMutation: UpdateManyAppLogsDocument;
	private deleteAppLogsMutation: DeleteAppLogsDocument;

	constructor(private injector: Injector) {
		this.getAppLogsQuery = this.injector.get(GetAppLogsDocument);
		this.firstAppLogsQuery = this.injector.get(FirstAppLogsDocument);
		this.countAppLogsQuery = this.injector.get(CountAppLogsDocument);
		this.findAppLogsQuery = this.injector.get(FindAppLogsDocument);
		this.searchAppLogsQuery = this.injector.get(SearchAppLogsDocument);
		this.exportSchemaAppLogsQuery = this.injector.get(ExportSchemaAppLogsDocument);
		this.exportDataAppLogsQuery = this.injector.get(ExportDataAppLogsDocument);
		this.customQueryAppLogsQuery = this.injector.get(CustomQueryAppLogsDocument);
		this.customQueryIdAppLogsQuery = this.injector.get(CustomQueryIdAppLogsDocument);
		this.usedAppLogsQuery = this.injector.get(UsedAppLogsDocument);
		this.existAppLogsQuery = this.injector.get(ExistAppLogsDocument);
		this.insertAppLogsMutation = this.injector.get(InsertAppLogsDocument);
		this.updateAppLogsMutation = this.injector.get(UpdateAppLogsDocument);
		this.importDataAppLogsMutation = this.injector.get(ImportDataAppLogsDocument);
		this.updateManyAppLogsMutation = this.injector.get(UpdateManyAppLogsDocument);
		this.deleteAppLogsMutation = this.injector.get(DeleteAppLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppLog> {
    
    		let variables: GetAppLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.get));
            }
            else{
                let result:ServiceSingleResultOfAppLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAppLog,
		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppLog> {
    
    		let variables: FirstAppLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAppLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAppLog,
		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppLog> {
    
    		let variables: FindAppLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAppLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppLog> {
    
    		let variables: SearchAppLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAppLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAppLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAppLog,
		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppLog> {
    
    		let variables: CustomQueryAppLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAppLog,
		@Args('filter?') filter?: FilterOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppLog> {
    
    		let variables: CustomQueryIdAppLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAppLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAppLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAppLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AppLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppLog> {
    
    		let variables: InsertAppLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAppLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppLog> {
    
    		let variables: UpdateAppLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAppLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAppLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAppLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAppLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAppLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAppLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAppLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAppLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appLogs.delete));
    	}
    
}