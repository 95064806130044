import { ContractCategoryBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';
import { GqlField, GqlSubField } from '../helpers';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import {
  FilterOfContractCategory,
  QueryContextOfContractCategory,
  ServiceListResultOfContractCategory,
} from '../types';
import { Args, InjectArgs, EntityAttributesType } from '@clarilog/core';
import { Observable, of } from 'rxjs';
@Injectable({ providedIn: 'root' })
@Authorize('contract.manage-category')
export class ContractCategoryCoreService extends ContractCategoryBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
