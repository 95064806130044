import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAxelDataBaseVariables, FirstAxelDataBaseVariables, CountAxelDataBaseVariables, FindAxelDataBaseVariables, SearchAxelDataBaseVariables, ExportSchemaAxelDataBaseVariables, ExportDataAxelDataBaseVariables, CustomQueryAxelDataBaseVariables, CustomQueryIdAxelDataBaseVariables, UsedAxelDataBaseVariables, ExistAxelDataBaseVariables, InsertAxelDataBaseVariables, UpdateAxelDataBaseVariables, ImportDataAxelDataBaseVariables, UpdateManyAxelDataBaseVariables, DeleteAxelDataBaseVariables } from '../gqls'
import { GetAxelDataDocument, FirstAxelDataDocument, CountAxelDataDocument, FindAxelDataDocument, SearchAxelDataDocument, ExportSchemaAxelDataDocument, ExportDataAxelDataDocument, CustomQueryAxelDataDocument, CustomQueryIdAxelDataDocument, UsedAxelDataDocument, ExistAxelDataDocument, InsertAxelDataDocument, UpdateAxelDataDocument, ImportDataAxelDataDocument, UpdateManyAxelDataDocument, DeleteAxelDataDocument } from '../gqls'
import { ServiceSingleResultOfAxelData, QueryContextOfAxelData, FilterOfAxelData, ServiceSingleResultOfInt64, ServiceListResultOfAxelData, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AxelDataInput, FieldUpdateOperatorOfAxelData } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AxelDatumBaseService {
    
public modelName = 'axelDatum';
public modelPluralName = 'axelData';

	private getAxelDataQuery: GetAxelDataDocument;
	private firstAxelDataQuery: FirstAxelDataDocument;
	private countAxelDataQuery: CountAxelDataDocument;
	private findAxelDataQuery: FindAxelDataDocument;
	private searchAxelDataQuery: SearchAxelDataDocument;
	private exportSchemaAxelDataQuery: ExportSchemaAxelDataDocument;
	private exportDataAxelDataQuery: ExportDataAxelDataDocument;
	private customQueryAxelDataQuery: CustomQueryAxelDataDocument;
	private customQueryIdAxelDataQuery: CustomQueryIdAxelDataDocument;
	private usedAxelDataQuery: UsedAxelDataDocument;
	private existAxelDataQuery: ExistAxelDataDocument;
	private insertAxelDataMutation: InsertAxelDataDocument;
	private updateAxelDataMutation: UpdateAxelDataDocument;
	private importDataAxelDataMutation: ImportDataAxelDataDocument;
	private updateManyAxelDataMutation: UpdateManyAxelDataDocument;
	private deleteAxelDataMutation: DeleteAxelDataDocument;

	constructor(private injector: Injector) {
		this.getAxelDataQuery = this.injector.get(GetAxelDataDocument);
		this.firstAxelDataQuery = this.injector.get(FirstAxelDataDocument);
		this.countAxelDataQuery = this.injector.get(CountAxelDataDocument);
		this.findAxelDataQuery = this.injector.get(FindAxelDataDocument);
		this.searchAxelDataQuery = this.injector.get(SearchAxelDataDocument);
		this.exportSchemaAxelDataQuery = this.injector.get(ExportSchemaAxelDataDocument);
		this.exportDataAxelDataQuery = this.injector.get(ExportDataAxelDataDocument);
		this.customQueryAxelDataQuery = this.injector.get(CustomQueryAxelDataDocument);
		this.customQueryIdAxelDataQuery = this.injector.get(CustomQueryIdAxelDataDocument);
		this.usedAxelDataQuery = this.injector.get(UsedAxelDataDocument);
		this.existAxelDataQuery = this.injector.get(ExistAxelDataDocument);
		this.insertAxelDataMutation = this.injector.get(InsertAxelDataDocument);
		this.updateAxelDataMutation = this.injector.get(UpdateAxelDataDocument);
		this.importDataAxelDataMutation = this.injector.get(ImportDataAxelDataDocument);
		this.updateManyAxelDataMutation = this.injector.get(UpdateManyAxelDataDocument);
		this.deleteAxelDataMutation = this.injector.get(DeleteAxelDataDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAxelData> {
    
    		let variables: GetAxelDataBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.get));
            }
            else{
                let result:ServiceSingleResultOfAxelData={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAxelData,
		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAxelData> {
    
    		let variables: FirstAxelDataBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAxelDataBaseVariables = {
    			filter: filter
    		}
    				return this.countAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAxelData,
		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAxelData> {
    
    		let variables: FindAxelDataBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAxelData,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAxelData> {
    
    		let variables: SearchAxelDataBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAxelDataBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAxelDataBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAxelData,
		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAxelData> {
    
    		let variables: CustomQueryAxelDataBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAxelData,
		@Args('filter?') filter?: FilterOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAxelData> {
    
    		let variables: CustomQueryIdAxelDataBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAxelDataBaseVariables = {
    			ids: ids
    		}
    				return this.usedAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAxelDataBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAxelDataQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AxelDataInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAxelData> {
    
    		let variables: InsertAxelDataBaseVariables = {
    			entity: entity
    		}
    				return this.insertAxelDataMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAxelData> {
    
    		let variables: UpdateAxelDataBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAxelDataMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAxelDataBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAxelDataMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAxelData,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAxelDataBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAxelDataMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAxelDataBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAxelDataMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.axelData.delete));
    	}
    
}