<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content>
      <cl-work-page-header-content-title
        >{{ title }}
      </cl-work-page-header-content-title>
      <cl-work-page-header-content-body> </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>
  <cl-work-page-content>
    <div class="page-content">
      <div class="page-content-wrapper">
        <div class="description">
          Veuillez sélectionner :
        </div>
        <div class="buttons">
          <dx-button>Site</dx-button>
          <dx-button>Bâtiment</dx-button>
          <dx-button>Etage</dx-button>
          <dx-button>Bureau</dx-button>
          <dx-button>Template 5</dx-button>
          <dx-button>Template 6</dx-button>
          <dx-button>Template 7</dx-button>
          <dx-button>Template 8</dx-button>
        </div>
      </div>
    </div>
  </cl-work-page-content>
</cl-work-page>
