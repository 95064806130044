import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetListDesignersBaseVariables, FirstListDesignersBaseVariables, CountListDesignersBaseVariables, FindListDesignersBaseVariables, SearchListDesignersBaseVariables, ExportSchemaListDesignersBaseVariables, ExportDataListDesignersBaseVariables, CustomQueryListDesignersBaseVariables, CustomQueryIdListDesignersBaseVariables, UsedListDesignersBaseVariables, FindNotUsersLinkListDesignersBaseVariables, FindUsersLinkListDesignersBaseVariables, FindLinkedOperatorsListDesignersBaseVariables, FindNotLinkedOperatorsListDesignersBaseVariables, ExistListDesignersBaseVariables, FindRecyclesListDesignersBaseVariables, CountRecyclesListDesignersBaseVariables, ReadOnlyListDesignersBaseVariables, FindArchivedListDesignersBaseVariables, CountAllListDesignersBaseVariables, FindDynamicPropertyFieldsListDesignersBaseVariables, InsertListDesignersBaseVariables, UpdateListDesignersBaseVariables, ImportDataListDesignersBaseVariables, UpdateManyListDesignersBaseVariables, DeleteListDesignersBaseVariables, AddUsersLinkListDesignersBaseVariables, RestoreListDesignersBaseVariables, RecycleListDesignersBaseVariables, RestoreArchivedListDesignersBaseVariables, ArchivedListDesignersBaseVariables, AddFileDynamicFieldListDesignersBaseVariables, RemoveFileDynamicFieldListDesignersBaseVariables } from '../gqls'
import { GetListDesignersDocument, FirstListDesignersDocument, CountListDesignersDocument, FindListDesignersDocument, SearchListDesignersDocument, ExportSchemaListDesignersDocument, ExportDataListDesignersDocument, CustomQueryListDesignersDocument, CustomQueryIdListDesignersDocument, UsedListDesignersDocument, FindNotUsersLinkListDesignersDocument, FindUsersLinkListDesignersDocument, FindLinkedOperatorsListDesignersDocument, FindNotLinkedOperatorsListDesignersDocument, ExistListDesignersDocument, FindRecyclesListDesignersDocument, CountRecyclesListDesignersDocument, ReadOnlyListDesignersDocument, FindArchivedListDesignersDocument, CountAllListDesignersDocument, FindDynamicPropertyFieldsListDesignersDocument, InsertListDesignersDocument, UpdateListDesignersDocument, ImportDataListDesignersDocument, UpdateManyListDesignersDocument, DeleteListDesignersDocument, AddUsersLinkListDesignersDocument, RestoreListDesignersDocument, RecycleListDesignersDocument, RestoreArchivedListDesignersDocument, ArchivedListDesignersDocument, AddFileDynamicFieldListDesignersDocument, RemoveFileDynamicFieldListDesignersDocument } from '../gqls'
import { ServiceSingleResultOfListDesigner, QueryContextOfListDesigner, FilterOfListDesigner, ServiceSingleResultOfInt64, ServiceListResultOfListDesigner, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfListDesigner, ListDesignerInput, ListDesignerType } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ListDesignerBaseService {
    
public modelName = 'listDesigner';
public modelPluralName = 'listDesigners';

	private getListDesignersQuery: GetListDesignersDocument;
	private firstListDesignersQuery: FirstListDesignersDocument;
	private countListDesignersQuery: CountListDesignersDocument;
	private findListDesignersQuery: FindListDesignersDocument;
	private searchListDesignersQuery: SearchListDesignersDocument;
	private exportSchemaListDesignersQuery: ExportSchemaListDesignersDocument;
	private exportDataListDesignersQuery: ExportDataListDesignersDocument;
	private customQueryListDesignersQuery: CustomQueryListDesignersDocument;
	private customQueryIdListDesignersQuery: CustomQueryIdListDesignersDocument;
	private usedListDesignersQuery: UsedListDesignersDocument;
	private findNotUsersLinkListDesignersQuery: FindNotUsersLinkListDesignersDocument;
	private findUsersLinkListDesignersQuery: FindUsersLinkListDesignersDocument;
	private findLinkedOperatorsListDesignersQuery: FindLinkedOperatorsListDesignersDocument;
	private findNotLinkedOperatorsListDesignersQuery: FindNotLinkedOperatorsListDesignersDocument;
	private existListDesignersQuery: ExistListDesignersDocument;
	private findRecyclesListDesignersQuery: FindRecyclesListDesignersDocument;
	private countRecyclesListDesignersQuery: CountRecyclesListDesignersDocument;
	private readOnlyListDesignersQuery: ReadOnlyListDesignersDocument;
	private findArchivedListDesignersQuery: FindArchivedListDesignersDocument;
	private countAllListDesignersQuery: CountAllListDesignersDocument;
	private findDynamicPropertyFieldsListDesignersQuery: FindDynamicPropertyFieldsListDesignersDocument;
	private insertListDesignersMutation: InsertListDesignersDocument;
	private updateListDesignersMutation: UpdateListDesignersDocument;
	private importDataListDesignersMutation: ImportDataListDesignersDocument;
	private updateManyListDesignersMutation: UpdateManyListDesignersDocument;
	private deleteListDesignersMutation: DeleteListDesignersDocument;
	private addUsersLinkListDesignersMutation: AddUsersLinkListDesignersDocument;
	private restoreListDesignersMutation: RestoreListDesignersDocument;
	private recycleListDesignersMutation: RecycleListDesignersDocument;
	private restoreArchivedListDesignersMutation: RestoreArchivedListDesignersDocument;
	private archivedListDesignersMutation: ArchivedListDesignersDocument;
	private addFileDynamicFieldListDesignersMutation: AddFileDynamicFieldListDesignersDocument;
	private removeFileDynamicFieldListDesignersMutation: RemoveFileDynamicFieldListDesignersDocument;

	constructor(private injector: Injector) {
		this.getListDesignersQuery = this.injector.get(GetListDesignersDocument);
		this.firstListDesignersQuery = this.injector.get(FirstListDesignersDocument);
		this.countListDesignersQuery = this.injector.get(CountListDesignersDocument);
		this.findListDesignersQuery = this.injector.get(FindListDesignersDocument);
		this.searchListDesignersQuery = this.injector.get(SearchListDesignersDocument);
		this.exportSchemaListDesignersQuery = this.injector.get(ExportSchemaListDesignersDocument);
		this.exportDataListDesignersQuery = this.injector.get(ExportDataListDesignersDocument);
		this.customQueryListDesignersQuery = this.injector.get(CustomQueryListDesignersDocument);
		this.customQueryIdListDesignersQuery = this.injector.get(CustomQueryIdListDesignersDocument);
		this.usedListDesignersQuery = this.injector.get(UsedListDesignersDocument);
		this.findNotUsersLinkListDesignersQuery = this.injector.get(FindNotUsersLinkListDesignersDocument);
		this.findUsersLinkListDesignersQuery = this.injector.get(FindUsersLinkListDesignersDocument);
		this.findLinkedOperatorsListDesignersQuery = this.injector.get(FindLinkedOperatorsListDesignersDocument);
		this.findNotLinkedOperatorsListDesignersQuery = this.injector.get(FindNotLinkedOperatorsListDesignersDocument);
		this.existListDesignersQuery = this.injector.get(ExistListDesignersDocument);
		this.findRecyclesListDesignersQuery = this.injector.get(FindRecyclesListDesignersDocument);
		this.countRecyclesListDesignersQuery = this.injector.get(CountRecyclesListDesignersDocument);
		this.readOnlyListDesignersQuery = this.injector.get(ReadOnlyListDesignersDocument);
		this.findArchivedListDesignersQuery = this.injector.get(FindArchivedListDesignersDocument);
		this.countAllListDesignersQuery = this.injector.get(CountAllListDesignersDocument);
		this.findDynamicPropertyFieldsListDesignersQuery = this.injector.get(FindDynamicPropertyFieldsListDesignersDocument);
		this.insertListDesignersMutation = this.injector.get(InsertListDesignersDocument);
		this.updateListDesignersMutation = this.injector.get(UpdateListDesignersDocument);
		this.importDataListDesignersMutation = this.injector.get(ImportDataListDesignersDocument);
		this.updateManyListDesignersMutation = this.injector.get(UpdateManyListDesignersDocument);
		this.deleteListDesignersMutation = this.injector.get(DeleteListDesignersDocument);
		this.addUsersLinkListDesignersMutation = this.injector.get(AddUsersLinkListDesignersDocument);
		this.restoreListDesignersMutation = this.injector.get(RestoreListDesignersDocument);
		this.recycleListDesignersMutation = this.injector.get(RecycleListDesignersDocument);
		this.restoreArchivedListDesignersMutation = this.injector.get(RestoreArchivedListDesignersDocument);
		this.archivedListDesignersMutation = this.injector.get(ArchivedListDesignersDocument);
		this.addFileDynamicFieldListDesignersMutation = this.injector.get(AddFileDynamicFieldListDesignersDocument);
		this.removeFileDynamicFieldListDesignersMutation = this.injector.get(RemoveFileDynamicFieldListDesignersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfListDesigner> {
    
    		let variables: GetListDesignersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.get));
            }
            else{
                let result:ServiceSingleResultOfListDesigner={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfListDesigner> {
    
    		let variables: FirstListDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountListDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: FindListDesignersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: SearchListDesignersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaListDesignersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataListDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: CustomQueryListDesignersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: CustomQueryIdListDesignersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.usedListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsersLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('qualification?') qualification?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersLinkListDesignersBaseVariables = {
    			id: id,
			qualification: qualification,
			populationIds: populationIds,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersLinkListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findNotUsersLink));
    	}

    	/**  */
    	@InjectArgs
    	public findUsersLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('qualification?') qualification?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUsersLinkListDesignersBaseVariables = {
    			id: id,
			qualification: qualification,
			populationIds: populationIds,
			filter: filter,
			options: options
    		}
    				return this.findUsersLinkListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findUsersLink));
    	}

    	/**  */
    	@InjectArgs
    	public findLinkedOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('qualification?') qualification?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindLinkedOperatorsListDesignersBaseVariables = {
    			id: id,
			qualification: qualification,
			operatorTeamIds: operatorTeamIds,
			filter: filter,
			options: options
    		}
    				return this.findLinkedOperatorsListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findLinkedOperators));
    	}

    	/**  */
    	@InjectArgs
    	public findNotLinkedOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('qualification?') qualification?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotLinkedOperatorsListDesignersBaseVariables = {
    			id: id,
			qualification: qualification,
			operatorTeamIds: operatorTeamIds,
			filter: filter,
			options: options
    		}
    				return this.findNotLinkedOperatorsListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findNotLinkedOperators));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistListDesignersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: FindRecyclesListDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesListDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyListDesignersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfListDesigner,
		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfListDesigner> {
    
    		let variables: FindArchivedListDesignersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllListDesignersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsListDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsListDesignersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ListDesignerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfListDesigner> {
    
    		let variables: InsertListDesignersBaseVariables = {
    			entity: entity
    		}
    				return this.insertListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfListDesigner> {
    
    		let variables: UpdateListDesignersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataListDesignersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfListDesigner,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyListDesignersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addUsersLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('listType') listType: ListDesignerType,
		@Args('id') id: string,
		@Args('qualification?') qualification?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersLinkListDesignersBaseVariables = {
    			id: id,
			qualification: qualification,
			listType: listType,
			userIds: userIds
    		}
    				return this.addUsersLinkListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.addUsersLink));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedListDesignersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldListDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldListDesignersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldListDesignersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.listDesigners.removeFileDynamicField));
    	}
    
}