import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfScanRegistryKey, FilterOfScanRegistryKey, QueryContextOfScanRegistryKey, ServiceSingleResultOfInt64, ServiceListResultOfScanRegistryKey, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfiguration, FilterOfScanConfiguration, QueryContextOfScanConfiguration, ServiceListResultOfScanRegistry, FilterOfScanRegistry, QueryContextOfScanRegistry, ScanRegistryKeyInput, FieldUpdateOperatorOfScanRegistryKey } from '../types'

export type GetScanRegistryKeysResultType = {
    scanRegistryKeys: {
        get?: Maybe<ServiceSingleResultOfScanRegistryKey>
    }
}

export type GetScanRegistryKeysBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanRegistryKeys ${args} {
    scanRegistryKeys {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstScanRegistryKeysResultType = {
    scanRegistryKeys: {
        first?: Maybe<ServiceSingleResultOfScanRegistryKey>
    }
}

export type FirstScanRegistryKeysBaseVariables = {
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanRegistryKey = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstScanRegistryKeys ${args} {
    scanRegistryKeys {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountScanRegistryKeysResultType = {
    scanRegistryKeys: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountScanRegistryKeysBaseVariables = {
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countScanRegistryKeys ${args} {
    scanRegistryKeys {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindScanRegistryKeysResultType = {
    scanRegistryKeys: {
        find?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type FindScanRegistryKeysBaseVariables = {
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfScanRegistryKey = null, $filter: FilterOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findScanRegistryKeys ${args} {
    scanRegistryKeys {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchScanRegistryKeysResultType = {
    scanRegistryKeys: {
        search?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type SearchScanRegistryKeysBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchScanRegistryKeys ${args} {
    scanRegistryKeys {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaScanRegistryKeysResultType = {
    scanRegistryKeys: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaScanRegistryKeysBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaScanRegistryKeys ${args} {
    scanRegistryKeys {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataScanRegistryKeysResultType = {
    scanRegistryKeys: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataScanRegistryKeysBaseVariables = {
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataScanRegistryKeys ${args} {
    scanRegistryKeys {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryScanRegistryKeysResultType = {
    scanRegistryKeys: {
        customQuery?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type CustomQueryScanRegistryKeysBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfScanRegistryKey = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryScanRegistryKeys ${args} {
    scanRegistryKeys {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdScanRegistryKeysResultType = {
    scanRegistryKeys: {
        customQueryId?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type CustomQueryIdScanRegistryKeysBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfScanRegistryKey = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdScanRegistryKeys ${args} {
    scanRegistryKeys {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedScanRegistryKeysResultType = {
    scanRegistryKeys: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedScanRegistryKeysBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedScanRegistryKeys ${args} {
    scanRegistryKeys {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllUnassociatedScanConfigurationsScanRegistryKeysResultType = {
    scanRegistryKeys: {
        findAllUnassociatedScanConfigurations?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindAllUnassociatedScanConfigurationsScanRegistryKeysBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllUnassociatedScanConfigurationsScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllUnassociatedScanConfigurationsScanRegistryKeys ${args} {
    scanRegistryKeys {
        findAllUnassociatedScanConfigurations(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllUnassociatedScanConfigurationsScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllUnassociatedScanConfigurationsScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllAssociatedScanConfigurationsScanRegistryKeysResultType = {
    scanRegistryKeys: {
        findAllAssociatedScanConfigurations?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindAllAssociatedScanConfigurationsScanRegistryKeysBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllAssociatedScanConfigurationsScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllAssociatedScanConfigurationsScanRegistryKeys ${args} {
    scanRegistryKeys {
        findAllAssociatedScanConfigurations(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllAssociatedScanConfigurationsScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllAssociatedScanConfigurationsScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistScanRegistryKeysResultType = {
    scanRegistryKeys: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistScanRegistryKeysBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existScanRegistryKeys ${args} {
    scanRegistryKeys {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanRegistriesScanRegistryKeysResultType = {
    scanRegistryKeys: {
        findUnassociatedScanRegistries?: Maybe<ServiceListResultOfScanRegistry>
    }
}

export type FindUnassociatedScanRegistriesScanRegistryKeysBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanRegistry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistry>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanRegistriesScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanRegistry = null, $options: QueryContextOfScanRegistry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanRegistriesScanRegistryKeys ${args} {
    scanRegistryKeys {
        findUnassociatedScanRegistries(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanRegistriesScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanRegistriesScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertScanRegistryKeysResultType = {
    scanRegistryKeys: {
        insert?: Maybe<ServiceSingleResultOfScanRegistryKey>
    }
}

export type InsertScanRegistryKeysBaseVariables = {
	entity: ScanRegistryKeyInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ScanRegistryKeyInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertScanRegistryKeys ${args} {
    scanRegistryKeys {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateScanRegistryKeysResultType = {
    scanRegistryKeys: {
        update?: Maybe<ServiceSingleResultOfScanRegistryKey>
    }
}

export type UpdateScanRegistryKeysBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfScanRegistryKey>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateScanRegistryKeys ${args} {
    scanRegistryKeys {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataScanRegistryKeysResultType = {
    scanRegistryKeys: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataScanRegistryKeysBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataScanRegistryKeys ${args} {
    scanRegistryKeys {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyScanRegistryKeysResultType = {
    scanRegistryKeys: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyScanRegistryKeysBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfScanRegistryKey>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyScanRegistryKeys ${args} {
    scanRegistryKeys {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteScanRegistryKeysResultType = {
    scanRegistryKeys: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteScanRegistryKeysBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteScanRegistryKeys ${args} {
    scanRegistryKeys {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanConfigurationsScanRegistryKeysResultType = {
    scanRegistryKeys: {
        addScanConfigurations?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanConfigurationsScanRegistryKeysBaseVariables = {
	id: string, /**  */
	scanConfigurationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanConfigurationsScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanConfigurationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanConfigurationsScanRegistryKeys ${args} {
    scanRegistryKeys {
        addScanConfigurations(id: $id, scanConfigurationIds: $scanConfigurationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanConfigurationsScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanConfigurationsScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanConfigurationsScanRegistryKeysResultType = {
    scanRegistryKeys: {
        removeScanConfigurations?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanConfigurationsScanRegistryKeysBaseVariables = {
	id: string, /**  */
	scanConfigurationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanConfigurationsScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanConfigurationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanConfigurationsScanRegistryKeys ${args} {
    scanRegistryKeys {
        removeScanConfigurations(id: $id, scanConfigurationIds: $scanConfigurationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanConfigurationsScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanConfigurationsScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanRegistriesScanRegistryKeysResultType = {
    scanRegistryKeys: {
        addScanRegistries?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanRegistriesScanRegistryKeysBaseVariables = {
	id: string, /**  */
	scanRegistryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanRegistriesScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanRegistryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanRegistriesScanRegistryKeys ${args} {
    scanRegistryKeys {
        addScanRegistries(id: $id, scanRegistryIds: $scanRegistryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanRegistriesScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanRegistriesScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanRegistriesScanRegistryKeysResultType = {
    scanRegistryKeys: {
        removeScanRegistries?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanRegistriesScanRegistryKeysBaseVariables = {
	scanRegistryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanRegistriesScanRegistryKeysDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanRegistryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanRegistriesScanRegistryKeys ${args} {
    scanRegistryKeys {
        removeScanRegistries(scanRegistryIds: $scanRegistryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanRegistriesScanRegistryKeysBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanRegistriesScanRegistryKeysResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
