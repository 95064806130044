import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { TrackingType } from '@clarilog/core';
import { TrackingCoreService } from '@clarilog/core/services2/graphql/generated-types/services/tracking.service';
import { FilterOfTracking } from '@clarilog/core/services2/graphql/generated-types/types';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import camelcase from 'camelcase';
import DataSource from 'devextreme/data/data_source';
import Globalize from 'globalize/dist/globalize';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/internal/operators/map';
import { CoreManageListComponent } from '../../list/manage-list/manage-list.component';
import { TranslatedFieldHelperService } from '../../translate-field';

/** Représente la classe du composent cl-tracking-log-list. */
@Component({
  selector: 'clc-tracking-log-list',
  templateUrl: './tracking-log-list.component.html',
  styleUrls: ['./tracking-log-list.component.scss'],
})
export class CoreTrackingLogListComponent implements OnInit {
  type: any;
  /** Obtient ou définit le model en cours. */
  @Input() model: ModelState;
  state: Observable<object>;

  /** Obtient ou definit la presence d'un bouton pour filtrer les historique de changements */
  @Input() filter: boolean;

  /**Obtient ou définit le format de la date pour chaque champs */
  listFormatDate = [];

  /**Texte */
  @Input() message: string;

  /** Obtient ou définit la liste. */
  @ViewChild(CoreManageListComponent, { static: true })
  manageList: CoreManageListComponent;

  // /** Obtient ou définit la source de données. */
  source: DataSource;

  id: string;

  /** Obtient ou définit le log selectioné. */
  currentLog: TrackingType;
  instanceListComponent: any;
  constructor(
    public trackingCoreService: TrackingCoreService,
    private route: ActivatedRoute,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    this.currentLog = { changes: [] };
  }

  async ngOnInit() {
    this.id = this.route.snapshot.paramMap.get('id');
    if (this.id == undefined) {
      this.id = this.model.id;
    }
    if (this.filter) {
      this.simpleView();
    } else {
      this.source = this.createDataSource();
    }
  }

  onInitialized(e) {
    this.instanceListComponent = e.component;
    let dataSource = <DataSource>(<unknown>this.source);

    if (
      this.model != undefined &&
      this.model.sharedContext != undefined &&
      this.model.sharedContext.params.get('id') != undefined &&
      dataSource != undefined
    ) {
      dataSource.load().then((result) => {
        if (result != null && result.length > 0) {
          this.setCurrentLog(result[0]);
          this.instanceListComponent.selectItem(0);
        }
      });
    }
  }

  listSelectionChanged = (e) => {
    this.setCurrentLog(e.addedItems[0]);
  };

  /** Vérifie si la valeur peut etre parsé */
  parseJSON(value) {
    try {
      if (value != undefined && typeof value == 'string') {
        // Correction erreur
        value = value.trim();
        if (value != '') {
          value = this.removeBalise(value);
          value = JSON.parse(value);
        }
      }
    } catch (e) {
      // PAs d'erreur si non parsable
      //console.error(e);
    }

    return value;
  }

  getTranslatableValue(value) {
    let valueParts = value?.match(/{([^}]*)}/gi);

    if (value != null && valueParts == null) {
      return this.parseJSON(value);
    }

    return valueParts
      ?.map((part) => {
        let partJson = this.parseJSON(part);

        if (partJson != undefined && typeof partJson != 'string') {
          return partJson[
            Object.keys(partJson).find(
              (key) =>
                key.toLowerCase() ===
                this.translatedFieldHelperService.getTranslateKey(),
            )
          ];
        }

        return partJson;
      })
      ?.join(', ');
  }

  formatDayOffConfigsDates(date) {
    return new Date(date).toLocaleDateString(Globalize.locale().locale, {
      year: 'numeric',
      month: '2-digit',
      day: '2-digit',
    });
  }

  setCurrentLog(newLog) {
    if (newLog != undefined && newLog.alreadyCheck == undefined) {
      newLog.alreadyCheck = true;
      let validChanges = [];

      newLog.changes.forEach(function (log) {
        if (log.name != undefined) {
          let fieldModel = this.getFieldModel(
            this.model.model,
            log.name,
            undefined,
          );

          if (fieldModel != undefined) {
            this.getDateFormat(fieldModel);

            let originalName = log.name;

            log.name = this.getFieldLabel(fieldModel, log.name);

            if (
              log.name == fieldModel.fieldName &&
              fieldModel?.previousControl?.label != undefined
            ) {
              log.name = fieldModel?.previousControl?.label;
            }

            // Vérification du nombre de traduction similaire
            let countTanslate = this.getFieldTranslate(
              this.model.model,
              log.name,
            );

            if (
              countTanslate > 1 &&
              fieldModel?.previousControl?.label != undefined
            ) {
              log.name = fieldModel?.previousControl?.label + ' / ' + log.name;
            }

            // Vérification si plusieurs traduction identique
            this.manageEnumField(fieldModel, log);

            fieldModel['caption'] = log.name;

            this.getDateFormat(fieldModel);

            if (
              newLog.entitySourceName == 'DayOff' &&
              originalName == 'daysConfig'
            ) {
              log.oldValue = log.oldValue
                .map((x) => this.formatDayOffConfigsDates(x))
                .join('; ');
              log.oldValue = log.oldValue.trim() == '' ? null : log.oldValue;

              log.newValue = log.newValue
                .map((x) => this.formatDayOffConfigsDates(x))
                .join('; ');
              log.newValue = log.newValue.trim() == '' ? null : log.newValue;
            }

            if (log.oldValue == 'True' || log.oldValue == 'False') {
              log.oldValue = TranslateService.get(
                'entities/tracking/' +
                  (log.oldValue == 'True' ? 'enable' : 'disable'),
              );
            }
            if (log.newValue == 'True' || log.newValue == 'False') {
              log.newValue = TranslateService.get(
                'entities/tracking/' +
                  (log.newValue == 'True' ? 'enable' : 'disable'),
              );
            }
          } else {
            log.name = this.getFieldLabel(null, log.name);

            if (
              log.name ==
                TranslateService.get('entities/tracking/createdDate') ||
              log.name == TranslateService.get('entities/tracking/lastReminder')
            ) {
              log.newValue = new Date(log.newValue).toLocaleString(
                Globalize.locale().locale,
              );

              if (
                log.name ==
                  TranslateService.get('entities/tracking/lastReminder') &&
                log.oldValue != null
              ) {
                log.oldValue = new Date(log.oldValue).toLocaleString(
                  Globalize.locale().locale,
                );
              }
            }

            if (log.oldValue == 'True' || log.oldValue == 'False') {
              log.oldValue = TranslateService.get(
                'entities/tracking/' +
                  (log.oldValue == 'True' ? 'enable' : 'disable'),
              );
            }

            if (log.newValue == 'True' || log.newValue == 'False') {
              log.newValue = TranslateService.get(
                'entities/tracking/' +
                  (log.newValue == 'True' ? 'enable' : 'disable'),
              );
            }
          }

          if (log.isTranslatable) {
            if (log.oldValue != null) {
              let returnValue = this.getTranslatableValue(log.oldValue);

              if (returnValue != null) {
                log.oldValue = returnValue;
              } else {
                let objOld = this.parseJSON(log.oldValue);

                if (objOld != undefined && typeof objOld != 'string') {
                  log.oldValue =
                    objOld[
                      Object.keys(objOld).find(
                        (key) =>
                          key.toLowerCase() ===
                          this.translatedFieldHelperService.getTranslateKey(),
                      )
                    ];
                } else {
                  log.oldValue = objOld;
                }
              }
            }

            if (log.newValue != null) {
              let returnValue = this.getTranslatableValue(log.newValue);

              if (returnValue != null) {
                log.newValue = returnValue;
              } else {
                let objNew = this.parseJSON(log.newValue);

                if (objNew != undefined && typeof objNew != 'string') {
                  log.newValue =
                    objNew[
                      Object.keys(objNew).find(
                        (key) =>
                          key.toLowerCase() ===
                          this.translatedFieldHelperService.getTranslateKey(),
                      )
                    ];
                } else {
                  log.newValue = objNew;
                }
              }
            }
          } else {
            if (log.oldValue?.includes('{"Fr":')) {
              log.oldValue = this.getTranslatableValue(log.oldValue);
            }

            if (log.newValue?.includes('{"Fr":')) {
              log.newValue = this.getTranslatableValue(log.newValue);
            }
          }

          validChanges.push(log);
        }
      }, this);

      if (validChanges.length > 0) {
        newLog.changes = validChanges;
        newLog.alreadyCheck = true;
      }
    }

    this.currentLog = newLog;
  }

  /** Suppression des balsie (exemple <i>) */
  removeBalise(value) {
    if (value != undefined) {
      //value = value.replace('[<i></i>]', '');
      value = value.replace(/\[<i>/g, '');
      value = value.replace(/\<\/i>\]/g, '');
    }

    return value;
  }

  manageEnumField(modelField, log) {
    if (modelField.type == 'SelectBoxComponent') {
      let sourceField = modelField.options.source;

      let oldValue = sourceField.find(
        (x) =>
          x[modelField.options.valueExpr].replace('_', '').toLowerCase() ==
          log.oldValue?.toLowerCase(),
      );

      if (oldValue == undefined) {
        oldValue = sourceField.find(
          (x) =>
            x[modelField.options.valueExpr].toLowerCase() ==
            log.oldValue?.toLowerCase(),
        );
      }

      if (oldValue != undefined) {
        log.oldValue = oldValue[modelField.options.displayExpr];
      }

      let newValue = sourceField.find(
        (x) =>
          x[modelField.options.valueExpr].replace('_', '').toLowerCase() ==
          log.newValue?.toLowerCase(),
      );

      if (newValue == undefined) {
        newValue = sourceField.find(
          (x) =>
            x[modelField.options.valueExpr].toLowerCase() ==
            log.newValue?.toLowerCase(),
        );
      }

      if (newValue != undefined) {
        log.newValue = newValue[modelField.options.displayExpr];
      }
    }
  }

  getFieldLabel(modelField, fieldName) {
    if (fieldName == 'Description') return 'Description';

    if (
      modelField == null ||
      modelField['label'] == '' ||
      modelField['label'] == undefined
    ) {
      let translate = TranslateService.get(
        'entities/tracking/' + camelcase(fieldName),
      );

      if (!translate.startsWith('[')) {
        return translate;
      }

      return fieldName;
    }

    return modelField['label'];
  }

  getDateFormat(modelField) {
    if (modelField.type == 'DateTimeComponent') {
      this.listFormatDate[modelField.label] = modelField.options.displayFormat;
      if (modelField['caption'] != undefined) {
        this.listFormatDate[modelField['caption']] =
          modelField.options.displayFormat;
      }
    }
  }
  public createDataSource(addFilter = {}) {
    return CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        if (this.id == undefined) {
          return of({ totalCount: 0, data: [] });
        }
        return this.trackingCoreService
          .findByEntityId(
            ModelFieldCompilerService.createTrackingResultScalar(),
            options,
            this.id,
            addFilter,
          )
          .pipe(
            map((result) => {
              if (result == undefined) {
                return { totalCount: 0, data: [] };
              }
              return result;
            }),
          );
      },
    });
  }
  getFieldModel(model, fieldName, previousControl) {
    if (model == undefined) {
      return null;
    }
    let keys = Object.keys(model);
    if (model != undefined && model.model != undefined) {
      return null;
    }
    if (keys.includes('fieldName')) {
      if (model['fieldName'].toLowerCase() == fieldName.toLowerCase()) {
        return model;
      }
    } else {
      for (let key of keys) {
        if (typeof model[key] == 'object') {
          let field = this.getFieldModel(model[key], fieldName, model);

          if (field != undefined) {
            field = {
              ...field,
            };
            if (field['previousControl'] == undefined) {
              field['previousControl'] = previousControl;
            }
            return field;
          }
        }
      }
    }
    return null;
  }

  getFieldTranslate(model, translate) {
    let count = 0;

    if (model != undefined) {
      let keys = Object.keys(model);

      if (model != undefined && model.model != undefined) {
        return 0;
      }

      if (keys.includes('label')) {
        if (
          model['label'] != undefined &&
          model['label'].toLowerCase() == translate.toLowerCase()
        ) {
          count++;
        }
      }

      for (let key of keys) {
        if (typeof model[key] == 'object') {
          if (
            !(model[key] instanceof ModelDataSourceContext) &&
            !(model[key] instanceof ModelFnContext)
          ) {
            count = count + this.getFieldTranslate(model[key], translate);
          }
        }
      }
    }
    return count;
  }

  formatDate(sDate) {
    let date = new Date(sDate).toLocaleString(Globalize.locale().locale, {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
    return date;
  }

  getFirstLog(log) {
    if (log == '' || log == undefined) return '';
    if (log.alreadyCheck == undefined) {
      this.setCurrentLog(log);
    } else {
      if (log.changes.length > 0) {
        return log.changes[0].name;
      }
    }
  }

  //Modifie le format de la date à afficher (avec ou sans les heures)
  getData(value: string, propertieName: string): string {
    let keyListFormatDate = this.listFormatDate[propertieName];
    // Verifie que notre clé existe dans notre tableau spé Date
    if (keyListFormatDate !== undefined) {
      if (this.listFormatDate[propertieName] == 'shortDate') {
        let date = new Date(value)
          .toLocaleString(Globalize.locale().locale)
          .split(', ')[0];

        // Impossible de convertir la date si notre date n'est pas par defaut dans le format Anglais.
        if (date === 'Invalid Date') {
          return value.split(' ')[0];
        }
        return date;
      } else {
        let date = new Date(value)
          .toLocaleString(Globalize.locale().locale)
          .replace(',', '');

        if (date === 'Invalid Date') {
          return value;
        }
        return date;
      }
    }

    let text = value;
    var htmlToString = new DOMParser().parseFromString(value, 'text/html')
      .documentElement.textContent;

    return text;
  }

  /** Rafraîchi la liste. */
  public refresh() {
    if (this.id == undefined) {
      this.id = this.model.id;
    }
    this.source.pageIndex(0);
    this.source.reload().then((result) => {
      if (result != null && result.length > 0) {
        this.setCurrentLog(result[0]);
        this.instanceListComponent.selectItem(0);
      }
    });
  }

  /**Affciher une vue simplifier des historiques des changements sur les assets */
  public simpleView(): void {
    let filters: FilterOfTracking = {
      changes: {
        elemMatch: {
          name: {
            in: ['LocationId', 'ownerId', 'OrganizationalUnitId'],
          },
        },
      },
    };
    // if (this.click) {
    //   this.text = TranslateService.get('globals/globalView');
    //   this.click = false;
    // } else {
    //   this.click = true;
    //   this.text = TranslateService.get('globals/simpleView');
    //   filters = {};
    // }

    this.source = this.createDataSource(filters);

    // this.source.reload();
  }
}
