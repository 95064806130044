<div
  class="box"
  *ngIf="
    message !== undefined &&
    message.length > 0 &&
    isViewHelpMe !== true &&
    systemNotification === true
  "
>
  <span>{{ message }}</span>
</div>
