import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfCommentaryModel, FilterOfCommentaryModel, QueryContextOfCommentaryModel, ServiceSingleResultOfInt64, ServiceListResultOfCommentaryModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, CommentaryModelInput, FieldUpdateOperatorOfCommentaryModel } from '../types'

export type GetCommentaryModelsResultType = {
    commentaryModels: {
        get?: Maybe<ServiceSingleResultOfCommentaryModel>
    }
}

export type GetCommentaryModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getCommentaryModels ${args} {
    commentaryModels {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstCommentaryModelsResultType = {
    commentaryModels: {
        first?: Maybe<ServiceSingleResultOfCommentaryModel>
    }
}

export type FirstCommentaryModelsBaseVariables = {
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfCommentaryModel>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfCommentaryModel = null, $options: QueryContextOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstCommentaryModels ${args} {
    commentaryModels {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountCommentaryModelsResultType = {
    commentaryModels: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountCommentaryModelsBaseVariables = {
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countCommentaryModels ${args} {
    commentaryModels {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindCommentaryModelsResultType = {
    commentaryModels: {
        find?: Maybe<ServiceListResultOfCommentaryModel>
    }
}

export type FindCommentaryModelsBaseVariables = {
	options: Maybe<QueryContextOfCommentaryModel>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfCommentaryModel = null, $filter: FilterOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findCommentaryModels ${args} {
    commentaryModels {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchCommentaryModelsResultType = {
    commentaryModels: {
        search?: Maybe<ServiceListResultOfCommentaryModel>
    }
}

export type SearchCommentaryModelsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfCommentaryModel>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchCommentaryModels ${args} {
    commentaryModels {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaCommentaryModelsResultType = {
    commentaryModels: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaCommentaryModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaCommentaryModels ${args} {
    commentaryModels {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataCommentaryModelsResultType = {
    commentaryModels: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataCommentaryModelsBaseVariables = {
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataCommentaryModels ${args} {
    commentaryModels {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryCommentaryModelsResultType = {
    commentaryModels: {
        customQuery?: Maybe<ServiceListResultOfCommentaryModel>
    }
}

export type CustomQueryCommentaryModelsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfCommentaryModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfCommentaryModel = null, $options: QueryContextOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryCommentaryModels ${args} {
    commentaryModels {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdCommentaryModelsResultType = {
    commentaryModels: {
        customQueryId?: Maybe<ServiceListResultOfCommentaryModel>
    }
}

export type CustomQueryIdCommentaryModelsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfCommentaryModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfCommentaryModel>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfCommentaryModel = null, $options: QueryContextOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdCommentaryModels ${args} {
    commentaryModels {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsCommentaryModelsResultType = {
    commentaryModels: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsCommentaryModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsCommentaryModels ${args} {
    commentaryModels {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsResultType = {
    commentaryModels: {
        findAssociatedOperatorTeamSecurityGroups?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les equipes d'operateur via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamSecurityGroupsCommentaryModels ${args} {
    commentaryModels {
        findAssociatedOperatorTeamSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedCommentaryModelsResultType = {
    commentaryModels: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedCommentaryModelsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedCommentaryModels ${args} {
    commentaryModels {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistCommentaryModelsResultType = {
    commentaryModels: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistCommentaryModelsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existCommentaryModels ${args} {
    commentaryModels {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertCommentaryModelsResultType = {
    commentaryModels: {
        insert?: Maybe<ServiceSingleResultOfCommentaryModel>
    }
}

export type InsertCommentaryModelsBaseVariables = {
	entity: CommentaryModelInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: CommentaryModelInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertCommentaryModels ${args} {
    commentaryModels {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateCommentaryModelsResultType = {
    commentaryModels: {
        update?: Maybe<ServiceSingleResultOfCommentaryModel>
    }
}

export type UpdateCommentaryModelsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfCommentaryModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateCommentaryModels ${args} {
    commentaryModels {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfCommentaryModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataCommentaryModelsResultType = {
    commentaryModels: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataCommentaryModelsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataCommentaryModels ${args} {
    commentaryModels {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyCommentaryModelsResultType = {
    commentaryModels: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyCommentaryModelsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfCommentaryModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfCommentaryModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyCommentaryModels ${args} {
    commentaryModels {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteCommentaryModelsResultType = {
    commentaryModels: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteCommentaryModelsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteCommentaryModels ${args} {
    commentaryModels {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupCommentaryModelsResultType = {
    commentaryModels: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupCommentaryModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupCommentaryModels ${args} {
    commentaryModels {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupCommentaryModelsResultType = {
    commentaryModels: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupCommentaryModelsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupCommentaryModels ${args} {
    commentaryModels {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorTeamSecurityGroupCommentaryModelsResultType = {
    commentaryModels: {
        addOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorTeamSecurityGroupCommentaryModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorTeamSecurityGroupCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorTeamSecurityGroupCommentaryModels ${args} {
    commentaryModels {
        addOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorTeamSecurityGroupCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorTeamSecurityGroupCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorTeamSecurityGroupCommentaryModelsResultType = {
    commentaryModels: {
        removeOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorTeamSecurityGroupCommentaryModelsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorTeamSecurityGroupCommentaryModelsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorTeamSecurityGroupCommentaryModels ${args} {
    commentaryModels {
        removeOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorTeamSecurityGroupCommentaryModelsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorTeamSecurityGroupCommentaryModelsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
