import {
  Injectable,
  ComponentFactoryResolver,
  TemplateRef,
  Injector,
  ApplicationRef,
  ComponentRef,
} from '@angular/core';
import { CoreTemplatesRowComponent } from './templates-row.component';

/** Permet d'utiliser les templates. */
@Injectable({
  providedIn: 'root',
})
export class TemplatesRowService {
  componentRef: ComponentRef<CoreTemplatesRowComponent>;
  isInitialize: boolean = false;
  constructor(
    private componentFactoryResolver: ComponentFactoryResolver,
    private appRef: ApplicationRef,
    private injector: Injector,
  ) {}
  /** Ajoute les templates au body. */
  appendToBody() {
    // Une seule fois.
    if (this.isInitialize === false) {
      const componentFactory =
        this.componentFactoryResolver.resolveComponentFactory(
          CoreTemplatesRowComponent,
        );
      this.componentRef = componentFactory.create(this.injector);
      this.appRef.attachView(this.componentRef.hostView);
      this.isInitialize = true;
    }
  }
  /** Récupère un template nommé via son id. */
  get(name: string): TemplateRef<any> {
    return this.componentRef.instance.get(name);
  }
}
