import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { SftpConnectorBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Authorize('administration.sftp-connector')
@Injectable({ providedIn: 'root' })
export class SftpConnectorCoreService extends SftpConnectorBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
