import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAlertsBaseVariables, FirstAlertsBaseVariables, CountAlertsBaseVariables, FindAlertsBaseVariables, SearchAlertsBaseVariables, ExportSchemaAlertsBaseVariables, ExportDataAlertsBaseVariables, CustomQueryAlertsBaseVariables, CustomQueryIdAlertsBaseVariables, UsedAlertsBaseVariables, CountNotViewedAlertAlertsBaseVariables, FindAlertByAlarmIdAlertsBaseVariables, FindAlertHiddenByAlarmIdAlertsBaseVariables, ExistAlertsBaseVariables, FindRecyclesAlertsBaseVariables, CountRecyclesAlertsBaseVariables, ReadOnlyAlertsBaseVariables, FindArchivedAlertsBaseVariables, CountAllAlertsBaseVariables, FindDynamicPropertyFieldsAlertsBaseVariables, InsertAlertsBaseVariables, UpdateAlertsBaseVariables, ImportDataAlertsBaseVariables, UpdateManyAlertsBaseVariables, DeleteAlertsBaseVariables, ChangeStateViewedAlertsBaseVariables, HiddenAlertAlertsBaseVariables, RestoreAlertsBaseVariables, RecycleAlertsBaseVariables, RestoreArchivedAlertsBaseVariables, ArchivedAlertsBaseVariables, AddFileDynamicFieldAlertsBaseVariables, RemoveFileDynamicFieldAlertsBaseVariables } from '../gqls'
import { GetAlertsDocument, FirstAlertsDocument, CountAlertsDocument, FindAlertsDocument, SearchAlertsDocument, ExportSchemaAlertsDocument, ExportDataAlertsDocument, CustomQueryAlertsDocument, CustomQueryIdAlertsDocument, UsedAlertsDocument, CountNotViewedAlertAlertsDocument, FindAlertByAlarmIdAlertsDocument, FindAlertHiddenByAlarmIdAlertsDocument, ExistAlertsDocument, FindRecyclesAlertsDocument, CountRecyclesAlertsDocument, ReadOnlyAlertsDocument, FindArchivedAlertsDocument, CountAllAlertsDocument, FindDynamicPropertyFieldsAlertsDocument, InsertAlertsDocument, UpdateAlertsDocument, ImportDataAlertsDocument, UpdateManyAlertsDocument, DeleteAlertsDocument, ChangeStateViewedAlertsDocument, HiddenAlertAlertsDocument, RestoreAlertsDocument, RecycleAlertsDocument, RestoreArchivedAlertsDocument, ArchivedAlertsDocument, AddFileDynamicFieldAlertsDocument, RemoveFileDynamicFieldAlertsDocument } from '../gqls'
import { ServiceSingleResultOfAlert, QueryContextOfAlert, FilterOfAlert, ServiceSingleResultOfInt64, ServiceListResultOfAlert, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAlert, AlertInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AlertBaseService {
    
public modelName = 'alert';
public modelPluralName = 'alerts';

	private getAlertsQuery: GetAlertsDocument;
	private firstAlertsQuery: FirstAlertsDocument;
	private countAlertsQuery: CountAlertsDocument;
	private findAlertsQuery: FindAlertsDocument;
	private searchAlertsQuery: SearchAlertsDocument;
	private exportSchemaAlertsQuery: ExportSchemaAlertsDocument;
	private exportDataAlertsQuery: ExportDataAlertsDocument;
	private customQueryAlertsQuery: CustomQueryAlertsDocument;
	private customQueryIdAlertsQuery: CustomQueryIdAlertsDocument;
	private usedAlertsQuery: UsedAlertsDocument;
	private countNotViewedAlertAlertsQuery: CountNotViewedAlertAlertsDocument;
	private findAlertByAlarmIdAlertsQuery: FindAlertByAlarmIdAlertsDocument;
	private findAlertHiddenByAlarmIdAlertsQuery: FindAlertHiddenByAlarmIdAlertsDocument;
	private existAlertsQuery: ExistAlertsDocument;
	private findRecyclesAlertsQuery: FindRecyclesAlertsDocument;
	private countRecyclesAlertsQuery: CountRecyclesAlertsDocument;
	private readOnlyAlertsQuery: ReadOnlyAlertsDocument;
	private findArchivedAlertsQuery: FindArchivedAlertsDocument;
	private countAllAlertsQuery: CountAllAlertsDocument;
	private findDynamicPropertyFieldsAlertsQuery: FindDynamicPropertyFieldsAlertsDocument;
	private insertAlertsMutation: InsertAlertsDocument;
	private updateAlertsMutation: UpdateAlertsDocument;
	private importDataAlertsMutation: ImportDataAlertsDocument;
	private updateManyAlertsMutation: UpdateManyAlertsDocument;
	private deleteAlertsMutation: DeleteAlertsDocument;
	private changeStateViewedAlertsMutation: ChangeStateViewedAlertsDocument;
	private hiddenAlertAlertsMutation: HiddenAlertAlertsDocument;
	private restoreAlertsMutation: RestoreAlertsDocument;
	private recycleAlertsMutation: RecycleAlertsDocument;
	private restoreArchivedAlertsMutation: RestoreArchivedAlertsDocument;
	private archivedAlertsMutation: ArchivedAlertsDocument;
	private addFileDynamicFieldAlertsMutation: AddFileDynamicFieldAlertsDocument;
	private removeFileDynamicFieldAlertsMutation: RemoveFileDynamicFieldAlertsDocument;

	constructor(private injector: Injector) {
		this.getAlertsQuery = this.injector.get(GetAlertsDocument);
		this.firstAlertsQuery = this.injector.get(FirstAlertsDocument);
		this.countAlertsQuery = this.injector.get(CountAlertsDocument);
		this.findAlertsQuery = this.injector.get(FindAlertsDocument);
		this.searchAlertsQuery = this.injector.get(SearchAlertsDocument);
		this.exportSchemaAlertsQuery = this.injector.get(ExportSchemaAlertsDocument);
		this.exportDataAlertsQuery = this.injector.get(ExportDataAlertsDocument);
		this.customQueryAlertsQuery = this.injector.get(CustomQueryAlertsDocument);
		this.customQueryIdAlertsQuery = this.injector.get(CustomQueryIdAlertsDocument);
		this.usedAlertsQuery = this.injector.get(UsedAlertsDocument);
		this.countNotViewedAlertAlertsQuery = this.injector.get(CountNotViewedAlertAlertsDocument);
		this.findAlertByAlarmIdAlertsQuery = this.injector.get(FindAlertByAlarmIdAlertsDocument);
		this.findAlertHiddenByAlarmIdAlertsQuery = this.injector.get(FindAlertHiddenByAlarmIdAlertsDocument);
		this.existAlertsQuery = this.injector.get(ExistAlertsDocument);
		this.findRecyclesAlertsQuery = this.injector.get(FindRecyclesAlertsDocument);
		this.countRecyclesAlertsQuery = this.injector.get(CountRecyclesAlertsDocument);
		this.readOnlyAlertsQuery = this.injector.get(ReadOnlyAlertsDocument);
		this.findArchivedAlertsQuery = this.injector.get(FindArchivedAlertsDocument);
		this.countAllAlertsQuery = this.injector.get(CountAllAlertsDocument);
		this.findDynamicPropertyFieldsAlertsQuery = this.injector.get(FindDynamicPropertyFieldsAlertsDocument);
		this.insertAlertsMutation = this.injector.get(InsertAlertsDocument);
		this.updateAlertsMutation = this.injector.get(UpdateAlertsDocument);
		this.importDataAlertsMutation = this.injector.get(ImportDataAlertsDocument);
		this.updateManyAlertsMutation = this.injector.get(UpdateManyAlertsDocument);
		this.deleteAlertsMutation = this.injector.get(DeleteAlertsDocument);
		this.changeStateViewedAlertsMutation = this.injector.get(ChangeStateViewedAlertsDocument);
		this.hiddenAlertAlertsMutation = this.injector.get(HiddenAlertAlertsDocument);
		this.restoreAlertsMutation = this.injector.get(RestoreAlertsDocument);
		this.recycleAlertsMutation = this.injector.get(RecycleAlertsDocument);
		this.restoreArchivedAlertsMutation = this.injector.get(RestoreArchivedAlertsDocument);
		this.archivedAlertsMutation = this.injector.get(ArchivedAlertsDocument);
		this.addFileDynamicFieldAlertsMutation = this.injector.get(AddFileDynamicFieldAlertsDocument);
		this.removeFileDynamicFieldAlertsMutation = this.injector.get(RemoveFileDynamicFieldAlertsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlert> {
    
    		let variables: GetAlertsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.get));
            }
            else{
                let result:ServiceSingleResultOfAlert={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlert> {
    
    		let variables: FirstAlertsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAlertsBaseVariables = {
    			filter: filter
    		}
    				return this.countAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindAlertsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: SearchAlertsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAlertsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAlertsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: CustomQueryAlertsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: CustomQueryIdAlertsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.used));
    	}

    	/**  */
    	@InjectArgs
    	public countNotViewedAlert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNotViewedAlertAlertsBaseVariables = {
    
    		}
    				return this.countNotViewedAlertAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.countNotViewedAlert));
    	}

    	/**  */
    	@InjectArgs
    	public findAlertByAlarmId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alarmId') alarmId: string,
		@Args('readSort?') readSort?: string,
		@Args('readFilter?') readFilter?: string,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindAlertByAlarmIdAlertsBaseVariables = {
    			alarmId: alarmId,
			readFilter: readFilter,
			readSort: readSort,
			filter: filter,
			options: options
    		}
    				return this.findAlertByAlarmIdAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.findAlertByAlarmId));
    	}

    	/**  */
    	@InjectArgs
    	public findAlertHiddenByAlarmId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alarmId') alarmId: string,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindAlertHiddenByAlarmIdAlertsBaseVariables = {
    			alarmId: alarmId,
			filter: filter,
			options: options
    		}
    				return this.findAlertHiddenByAlarmIdAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.findAlertHiddenByAlarmId));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAlertsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindRecyclesAlertsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAlertsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAlertsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindArchivedAlertsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAlertsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAlertsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAlertsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AlertInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlert> {
    
    		let variables: InsertAlertsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlert> {
    
    		let variables: UpdateAlertsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAlertsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAlertsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.delete));
    	}

    	/**  */
    	@InjectArgs
    	public changeStateViewed(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ChangeStateViewedAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.changeStateViewedAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.changeStateViewed));
    	}

    	/**  */
    	@InjectArgs
    	public hiddenAlert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: HiddenAlertAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.hiddenAlertAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.hiddenAlert));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAlertsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAlertsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAlertsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAlertsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alerts.removeFileDynamicField));
    	}
    
}