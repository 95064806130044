import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOsRecoveryConfigurationsBaseVariables, FirstOsRecoveryConfigurationsBaseVariables, CountOsRecoveryConfigurationsBaseVariables, FindOsRecoveryConfigurationsBaseVariables, SearchOsRecoveryConfigurationsBaseVariables, ExportSchemaOsRecoveryConfigurationsBaseVariables, ExportDataOsRecoveryConfigurationsBaseVariables, CustomQueryOsRecoveryConfigurationsBaseVariables, CustomQueryIdOsRecoveryConfigurationsBaseVariables, UsedOsRecoveryConfigurationsBaseVariables, ExistOsRecoveryConfigurationsBaseVariables } from '../gqls'
import { GetOsRecoveryConfigurationsDocument, FirstOsRecoveryConfigurationsDocument, CountOsRecoveryConfigurationsDocument, FindOsRecoveryConfigurationsDocument, SearchOsRecoveryConfigurationsDocument, ExportSchemaOsRecoveryConfigurationsDocument, ExportDataOsRecoveryConfigurationsDocument, CustomQueryOsRecoveryConfigurationsDocument, CustomQueryIdOsRecoveryConfigurationsDocument, UsedOsRecoveryConfigurationsDocument, ExistOsRecoveryConfigurationsDocument } from '../gqls'
import { ServiceSingleResultOfOsRecoveryConfiguration, QueryContextOfOsRecoveryConfiguration, FilterOfOsRecoveryConfiguration, ServiceSingleResultOfInt64, ServiceListResultOfOsRecoveryConfiguration, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OsRecoveryConfigurationBaseService {
    
public modelName = 'osRecoveryConfiguration';
public modelPluralName = 'osRecoveryConfigurations';

	private getOsRecoveryConfigurationsQuery: GetOsRecoveryConfigurationsDocument;
	private firstOsRecoveryConfigurationsQuery: FirstOsRecoveryConfigurationsDocument;
	private countOsRecoveryConfigurationsQuery: CountOsRecoveryConfigurationsDocument;
	private findOsRecoveryConfigurationsQuery: FindOsRecoveryConfigurationsDocument;
	private searchOsRecoveryConfigurationsQuery: SearchOsRecoveryConfigurationsDocument;
	private exportSchemaOsRecoveryConfigurationsQuery: ExportSchemaOsRecoveryConfigurationsDocument;
	private exportDataOsRecoveryConfigurationsQuery: ExportDataOsRecoveryConfigurationsDocument;
	private customQueryOsRecoveryConfigurationsQuery: CustomQueryOsRecoveryConfigurationsDocument;
	private customQueryIdOsRecoveryConfigurationsQuery: CustomQueryIdOsRecoveryConfigurationsDocument;
	private usedOsRecoveryConfigurationsQuery: UsedOsRecoveryConfigurationsDocument;
	private existOsRecoveryConfigurationsQuery: ExistOsRecoveryConfigurationsDocument;

	constructor(private injector: Injector) {
		this.getOsRecoveryConfigurationsQuery = this.injector.get(GetOsRecoveryConfigurationsDocument);
		this.firstOsRecoveryConfigurationsQuery = this.injector.get(FirstOsRecoveryConfigurationsDocument);
		this.countOsRecoveryConfigurationsQuery = this.injector.get(CountOsRecoveryConfigurationsDocument);
		this.findOsRecoveryConfigurationsQuery = this.injector.get(FindOsRecoveryConfigurationsDocument);
		this.searchOsRecoveryConfigurationsQuery = this.injector.get(SearchOsRecoveryConfigurationsDocument);
		this.exportSchemaOsRecoveryConfigurationsQuery = this.injector.get(ExportSchemaOsRecoveryConfigurationsDocument);
		this.exportDataOsRecoveryConfigurationsQuery = this.injector.get(ExportDataOsRecoveryConfigurationsDocument);
		this.customQueryOsRecoveryConfigurationsQuery = this.injector.get(CustomQueryOsRecoveryConfigurationsDocument);
		this.customQueryIdOsRecoveryConfigurationsQuery = this.injector.get(CustomQueryIdOsRecoveryConfigurationsDocument);
		this.usedOsRecoveryConfigurationsQuery = this.injector.get(UsedOsRecoveryConfigurationsDocument);
		this.existOsRecoveryConfigurationsQuery = this.injector.get(ExistOsRecoveryConfigurationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOsRecoveryConfiguration> {
    
    		let variables: GetOsRecoveryConfigurationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.get));
            }
            else{
                let result:ServiceSingleResultOfOsRecoveryConfiguration={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOsRecoveryConfiguration,
		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOsRecoveryConfiguration> {
    
    		let variables: FirstOsRecoveryConfigurationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOsRecoveryConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.countOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOsRecoveryConfiguration,
		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOsRecoveryConfiguration> {
    
    		let variables: FindOsRecoveryConfigurationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOsRecoveryConfiguration,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOsRecoveryConfiguration> {
    
    		let variables: SearchOsRecoveryConfigurationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOsRecoveryConfigurationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOsRecoveryConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOsRecoveryConfiguration,
		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOsRecoveryConfiguration> {
    
    		let variables: CustomQueryOsRecoveryConfigurationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOsRecoveryConfiguration,
		@Args('filter?') filter?: FilterOfOsRecoveryConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOsRecoveryConfiguration> {
    
    		let variables: CustomQueryIdOsRecoveryConfigurationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOsRecoveryConfigurationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOsRecoveryConfigurationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOsRecoveryConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.osRecoveryConfigurations.exist));
    	}
    
    
}