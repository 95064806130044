
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ConsumableMovementBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';

@Injectable({providedIn: 'root'})
@Authorize('consumable-movement')
export class ConsumableMovementCoreService extends ConsumableMovementBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}