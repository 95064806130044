import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNetworkCredentialsBaseVariables, FirstNetworkCredentialsBaseVariables, CountNetworkCredentialsBaseVariables, FindNetworkCredentialsBaseVariables, SearchNetworkCredentialsBaseVariables, ExportSchemaNetworkCredentialsBaseVariables, ExportDataNetworkCredentialsBaseVariables, CustomQueryNetworkCredentialsBaseVariables, CustomQueryIdNetworkCredentialsBaseVariables, UsedNetworkCredentialsBaseVariables, ExistNetworkCredentialsBaseVariables, FindRecyclesNetworkCredentialsBaseVariables, CountRecyclesNetworkCredentialsBaseVariables, ReadOnlyNetworkCredentialsBaseVariables, FindArchivedNetworkCredentialsBaseVariables, CountAllNetworkCredentialsBaseVariables, FindDynamicPropertyFieldsNetworkCredentialsBaseVariables, FindUnassociatedScanConfigurationsNetworkCredentialsBaseVariables, FindUnassociatedInventoryHistoriesNetworkCredentialsBaseVariables, FindUnassociatedScanByLdapsNetworkCredentialsBaseVariables, UpdateManyNetworkCredentialsBaseVariables, DeleteNetworkCredentialsBaseVariables, ResetPasswordNetworkCredentialsBaseVariables, RestoreNetworkCredentialsBaseVariables, RecycleNetworkCredentialsBaseVariables, RestoreArchivedNetworkCredentialsBaseVariables, ArchivedNetworkCredentialsBaseVariables, AddFileDynamicFieldNetworkCredentialsBaseVariables, RemoveFileDynamicFieldNetworkCredentialsBaseVariables, AddScanConfigurationsNetworkCredentialsBaseVariables, RemoveScanConfigurationsNetworkCredentialsBaseVariables, AddInventoryHistoriesNetworkCredentialsBaseVariables, RemoveInventoryHistoriesNetworkCredentialsBaseVariables, AddScanByLdapsNetworkCredentialsBaseVariables, RemoveScanByLdapsNetworkCredentialsBaseVariables } from '../gqls'
import { GetNetworkCredentialsDocument, FirstNetworkCredentialsDocument, CountNetworkCredentialsDocument, FindNetworkCredentialsDocument, SearchNetworkCredentialsDocument, ExportSchemaNetworkCredentialsDocument, ExportDataNetworkCredentialsDocument, CustomQueryNetworkCredentialsDocument, CustomQueryIdNetworkCredentialsDocument, UsedNetworkCredentialsDocument, ExistNetworkCredentialsDocument, FindRecyclesNetworkCredentialsDocument, CountRecyclesNetworkCredentialsDocument, ReadOnlyNetworkCredentialsDocument, FindArchivedNetworkCredentialsDocument, CountAllNetworkCredentialsDocument, FindDynamicPropertyFieldsNetworkCredentialsDocument, FindUnassociatedScanConfigurationsNetworkCredentialsDocument, FindUnassociatedInventoryHistoriesNetworkCredentialsDocument, FindUnassociatedScanByLdapsNetworkCredentialsDocument, UpdateManyNetworkCredentialsDocument, DeleteNetworkCredentialsDocument, ResetPasswordNetworkCredentialsDocument, RestoreNetworkCredentialsDocument, RecycleNetworkCredentialsDocument, RestoreArchivedNetworkCredentialsDocument, ArchivedNetworkCredentialsDocument, AddFileDynamicFieldNetworkCredentialsDocument, RemoveFileDynamicFieldNetworkCredentialsDocument, AddScanConfigurationsNetworkCredentialsDocument, RemoveScanConfigurationsNetworkCredentialsDocument, AddInventoryHistoriesNetworkCredentialsDocument, RemoveInventoryHistoriesNetworkCredentialsDocument, AddScanByLdapsNetworkCredentialsDocument, RemoveScanByLdapsNetworkCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfNetworkCredential, QueryContextOfNetworkCredential, FilterOfNetworkCredential, ServiceSingleResultOfInt64, ServiceListResultOfNetworkCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfNetworkCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NetworkCredentialBaseService {
    
public modelName = 'networkCredential';
public modelPluralName = 'networkCredentials';

	private getNetworkCredentialsQuery: GetNetworkCredentialsDocument;
	private firstNetworkCredentialsQuery: FirstNetworkCredentialsDocument;
	private countNetworkCredentialsQuery: CountNetworkCredentialsDocument;
	private findNetworkCredentialsQuery: FindNetworkCredentialsDocument;
	private searchNetworkCredentialsQuery: SearchNetworkCredentialsDocument;
	private exportSchemaNetworkCredentialsQuery: ExportSchemaNetworkCredentialsDocument;
	private exportDataNetworkCredentialsQuery: ExportDataNetworkCredentialsDocument;
	private customQueryNetworkCredentialsQuery: CustomQueryNetworkCredentialsDocument;
	private customQueryIdNetworkCredentialsQuery: CustomQueryIdNetworkCredentialsDocument;
	private usedNetworkCredentialsQuery: UsedNetworkCredentialsDocument;
	private existNetworkCredentialsQuery: ExistNetworkCredentialsDocument;
	private findRecyclesNetworkCredentialsQuery: FindRecyclesNetworkCredentialsDocument;
	private countRecyclesNetworkCredentialsQuery: CountRecyclesNetworkCredentialsDocument;
	private readOnlyNetworkCredentialsQuery: ReadOnlyNetworkCredentialsDocument;
	private findArchivedNetworkCredentialsQuery: FindArchivedNetworkCredentialsDocument;
	private countAllNetworkCredentialsQuery: CountAllNetworkCredentialsDocument;
	private findDynamicPropertyFieldsNetworkCredentialsQuery: FindDynamicPropertyFieldsNetworkCredentialsDocument;
	private findUnassociatedScanConfigurationsNetworkCredentialsQuery: FindUnassociatedScanConfigurationsNetworkCredentialsDocument;
	private findUnassociatedInventoryHistoriesNetworkCredentialsQuery: FindUnassociatedInventoryHistoriesNetworkCredentialsDocument;
	private findUnassociatedScanByLdapsNetworkCredentialsQuery: FindUnassociatedScanByLdapsNetworkCredentialsDocument;
	private updateManyNetworkCredentialsMutation: UpdateManyNetworkCredentialsDocument;
	private deleteNetworkCredentialsMutation: DeleteNetworkCredentialsDocument;
	private resetPasswordNetworkCredentialsMutation: ResetPasswordNetworkCredentialsDocument;
	private restoreNetworkCredentialsMutation: RestoreNetworkCredentialsDocument;
	private recycleNetworkCredentialsMutation: RecycleNetworkCredentialsDocument;
	private restoreArchivedNetworkCredentialsMutation: RestoreArchivedNetworkCredentialsDocument;
	private archivedNetworkCredentialsMutation: ArchivedNetworkCredentialsDocument;
	private addFileDynamicFieldNetworkCredentialsMutation: AddFileDynamicFieldNetworkCredentialsDocument;
	private removeFileDynamicFieldNetworkCredentialsMutation: RemoveFileDynamicFieldNetworkCredentialsDocument;
	private addScanConfigurationsNetworkCredentialsMutation: AddScanConfigurationsNetworkCredentialsDocument;
	private removeScanConfigurationsNetworkCredentialsMutation: RemoveScanConfigurationsNetworkCredentialsDocument;
	private addInventoryHistoriesNetworkCredentialsMutation: AddInventoryHistoriesNetworkCredentialsDocument;
	private removeInventoryHistoriesNetworkCredentialsMutation: RemoveInventoryHistoriesNetworkCredentialsDocument;
	private addScanByLdapsNetworkCredentialsMutation: AddScanByLdapsNetworkCredentialsDocument;
	private removeScanByLdapsNetworkCredentialsMutation: RemoveScanByLdapsNetworkCredentialsDocument;

	constructor(private injector: Injector) {
		this.getNetworkCredentialsQuery = this.injector.get(GetNetworkCredentialsDocument);
		this.firstNetworkCredentialsQuery = this.injector.get(FirstNetworkCredentialsDocument);
		this.countNetworkCredentialsQuery = this.injector.get(CountNetworkCredentialsDocument);
		this.findNetworkCredentialsQuery = this.injector.get(FindNetworkCredentialsDocument);
		this.searchNetworkCredentialsQuery = this.injector.get(SearchNetworkCredentialsDocument);
		this.exportSchemaNetworkCredentialsQuery = this.injector.get(ExportSchemaNetworkCredentialsDocument);
		this.exportDataNetworkCredentialsQuery = this.injector.get(ExportDataNetworkCredentialsDocument);
		this.customQueryNetworkCredentialsQuery = this.injector.get(CustomQueryNetworkCredentialsDocument);
		this.customQueryIdNetworkCredentialsQuery = this.injector.get(CustomQueryIdNetworkCredentialsDocument);
		this.usedNetworkCredentialsQuery = this.injector.get(UsedNetworkCredentialsDocument);
		this.existNetworkCredentialsQuery = this.injector.get(ExistNetworkCredentialsDocument);
		this.findRecyclesNetworkCredentialsQuery = this.injector.get(FindRecyclesNetworkCredentialsDocument);
		this.countRecyclesNetworkCredentialsQuery = this.injector.get(CountRecyclesNetworkCredentialsDocument);
		this.readOnlyNetworkCredentialsQuery = this.injector.get(ReadOnlyNetworkCredentialsDocument);
		this.findArchivedNetworkCredentialsQuery = this.injector.get(FindArchivedNetworkCredentialsDocument);
		this.countAllNetworkCredentialsQuery = this.injector.get(CountAllNetworkCredentialsDocument);
		this.findDynamicPropertyFieldsNetworkCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsNetworkCredentialsDocument);
		this.findUnassociatedScanConfigurationsNetworkCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsNetworkCredentialsDocument);
		this.findUnassociatedInventoryHistoriesNetworkCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesNetworkCredentialsDocument);
		this.findUnassociatedScanByLdapsNetworkCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsNetworkCredentialsDocument);
		this.updateManyNetworkCredentialsMutation = this.injector.get(UpdateManyNetworkCredentialsDocument);
		this.deleteNetworkCredentialsMutation = this.injector.get(DeleteNetworkCredentialsDocument);
		this.resetPasswordNetworkCredentialsMutation = this.injector.get(ResetPasswordNetworkCredentialsDocument);
		this.restoreNetworkCredentialsMutation = this.injector.get(RestoreNetworkCredentialsDocument);
		this.recycleNetworkCredentialsMutation = this.injector.get(RecycleNetworkCredentialsDocument);
		this.restoreArchivedNetworkCredentialsMutation = this.injector.get(RestoreArchivedNetworkCredentialsDocument);
		this.archivedNetworkCredentialsMutation = this.injector.get(ArchivedNetworkCredentialsDocument);
		this.addFileDynamicFieldNetworkCredentialsMutation = this.injector.get(AddFileDynamicFieldNetworkCredentialsDocument);
		this.removeFileDynamicFieldNetworkCredentialsMutation = this.injector.get(RemoveFileDynamicFieldNetworkCredentialsDocument);
		this.addScanConfigurationsNetworkCredentialsMutation = this.injector.get(AddScanConfigurationsNetworkCredentialsDocument);
		this.removeScanConfigurationsNetworkCredentialsMutation = this.injector.get(RemoveScanConfigurationsNetworkCredentialsDocument);
		this.addInventoryHistoriesNetworkCredentialsMutation = this.injector.get(AddInventoryHistoriesNetworkCredentialsDocument);
		this.removeInventoryHistoriesNetworkCredentialsMutation = this.injector.get(RemoveInventoryHistoriesNetworkCredentialsDocument);
		this.addScanByLdapsNetworkCredentialsMutation = this.injector.get(AddScanByLdapsNetworkCredentialsDocument);
		this.removeScanByLdapsNetworkCredentialsMutation = this.injector.get(RemoveScanByLdapsNetworkCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkCredential> {
    
    		let variables: GetNetworkCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfNetworkCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkCredential> {
    
    		let variables: FirstNetworkCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNetworkCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindNetworkCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: SearchNetworkCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNetworkCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNetworkCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: CustomQueryNetworkCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: CustomQueryIdNetworkCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNetworkCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindRecyclesNetworkCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesNetworkCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyNetworkCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkCredential,
		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkCredential> {
    
    		let variables: FindArchivedNetworkCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllNetworkCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsNetworkCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsNetworkCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesNetworkCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsNetworkCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsNetworkCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfNetworkCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyNetworkCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.delete));
    	}

    	/**  */
    	@InjectArgs
    	public resetPassword(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('type?') type?: string,
		@Args('newPassword?') newPassword?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ResetPasswordNetworkCredentialsBaseVariables = {
    			id: id,
			newPassword: newPassword,
			type: type
    		}
    				return this.resetPasswordNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.resetPassword));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedNetworkCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldNetworkCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldNetworkCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsNetworkCredentialsBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsNetworkCredentialsBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.removeScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public addInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryIds') inventoryHistoryIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddInventoryHistoriesNetworkCredentialsBaseVariables = {
    			id: id,
			inventoryHistoryIds: inventoryHistoryIds
    		}
    				return this.addInventoryHistoriesNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.addInventoryHistories));
    	}

    	/**  */
    	@InjectArgs
    	public removeInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('inventoryHistoryIds') inventoryHistoryIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveInventoryHistoriesNetworkCredentialsBaseVariables = {
    			inventoryHistoryIds: inventoryHistoryIds
    		}
    				return this.removeInventoryHistoriesNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.removeInventoryHistories));
    	}

    	/**  */
    	@InjectArgs
    	public addScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanByLdapsNetworkCredentialsBaseVariables = {
    			id: id,
			scanByLdapIds: scanByLdapIds
    		}
    				return this.addScanByLdapsNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.addScanByLdaps));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanByLdapIds') scanByLdapIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanByLdapsNetworkCredentialsBaseVariables = {
    			scanByLdapIds: scanByLdapIds
    		}
    				return this.removeScanByLdapsNetworkCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.networkCredentials.removeScanByLdaps));
    	}
    
}