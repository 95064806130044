import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSnmpCredentialsBaseVariables, FirstSnmpCredentialsBaseVariables, CountSnmpCredentialsBaseVariables, FindSnmpCredentialsBaseVariables, SearchSnmpCredentialsBaseVariables, ExportSchemaSnmpCredentialsBaseVariables, ExportDataSnmpCredentialsBaseVariables, CustomQuerySnmpCredentialsBaseVariables, CustomQueryIdSnmpCredentialsBaseVariables, UsedSnmpCredentialsBaseVariables, ExistSnmpCredentialsBaseVariables, FindRecyclesSnmpCredentialsBaseVariables, CountRecyclesSnmpCredentialsBaseVariables, ReadOnlySnmpCredentialsBaseVariables, FindArchivedSnmpCredentialsBaseVariables, CountAllSnmpCredentialsBaseVariables, FindDynamicPropertyFieldsSnmpCredentialsBaseVariables, FindUnassociatedScanConfigurationsSnmpCredentialsBaseVariables, FindUnassociatedInventoryHistoriesSnmpCredentialsBaseVariables, FindUnassociatedScanByLdapsSnmpCredentialsBaseVariables, InsertSnmpCredentialsBaseVariables, UpdateSnmpCredentialsBaseVariables, ImportDataSnmpCredentialsBaseVariables, UpdateManySnmpCredentialsBaseVariables, DeleteSnmpCredentialsBaseVariables, RestoreSnmpCredentialsBaseVariables, RecycleSnmpCredentialsBaseVariables, RestoreArchivedSnmpCredentialsBaseVariables, ArchivedSnmpCredentialsBaseVariables, AddFileDynamicFieldSnmpCredentialsBaseVariables, RemoveFileDynamicFieldSnmpCredentialsBaseVariables } from '../gqls'
import { GetSnmpCredentialsDocument, FirstSnmpCredentialsDocument, CountSnmpCredentialsDocument, FindSnmpCredentialsDocument, SearchSnmpCredentialsDocument, ExportSchemaSnmpCredentialsDocument, ExportDataSnmpCredentialsDocument, CustomQuerySnmpCredentialsDocument, CustomQueryIdSnmpCredentialsDocument, UsedSnmpCredentialsDocument, ExistSnmpCredentialsDocument, FindRecyclesSnmpCredentialsDocument, CountRecyclesSnmpCredentialsDocument, ReadOnlySnmpCredentialsDocument, FindArchivedSnmpCredentialsDocument, CountAllSnmpCredentialsDocument, FindDynamicPropertyFieldsSnmpCredentialsDocument, FindUnassociatedScanConfigurationsSnmpCredentialsDocument, FindUnassociatedInventoryHistoriesSnmpCredentialsDocument, FindUnassociatedScanByLdapsSnmpCredentialsDocument, InsertSnmpCredentialsDocument, UpdateSnmpCredentialsDocument, ImportDataSnmpCredentialsDocument, UpdateManySnmpCredentialsDocument, DeleteSnmpCredentialsDocument, RestoreSnmpCredentialsDocument, RecycleSnmpCredentialsDocument, RestoreArchivedSnmpCredentialsDocument, ArchivedSnmpCredentialsDocument, AddFileDynamicFieldSnmpCredentialsDocument, RemoveFileDynamicFieldSnmpCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfSnmpCredential, QueryContextOfSnmpCredential, FilterOfSnmpCredential, ServiceSingleResultOfInt64, ServiceListResultOfSnmpCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfSnmpCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, SnmpCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SnmpCredentialBaseService {
    
public modelName = 'snmpCredential';
public modelPluralName = 'snmpCredentials';

	private getSnmpCredentialsQuery: GetSnmpCredentialsDocument;
	private firstSnmpCredentialsQuery: FirstSnmpCredentialsDocument;
	private countSnmpCredentialsQuery: CountSnmpCredentialsDocument;
	private findSnmpCredentialsQuery: FindSnmpCredentialsDocument;
	private searchSnmpCredentialsQuery: SearchSnmpCredentialsDocument;
	private exportSchemaSnmpCredentialsQuery: ExportSchemaSnmpCredentialsDocument;
	private exportDataSnmpCredentialsQuery: ExportDataSnmpCredentialsDocument;
	private customQuerySnmpCredentialsQuery: CustomQuerySnmpCredentialsDocument;
	private customQueryIdSnmpCredentialsQuery: CustomQueryIdSnmpCredentialsDocument;
	private usedSnmpCredentialsQuery: UsedSnmpCredentialsDocument;
	private existSnmpCredentialsQuery: ExistSnmpCredentialsDocument;
	private findRecyclesSnmpCredentialsQuery: FindRecyclesSnmpCredentialsDocument;
	private countRecyclesSnmpCredentialsQuery: CountRecyclesSnmpCredentialsDocument;
	private readOnlySnmpCredentialsQuery: ReadOnlySnmpCredentialsDocument;
	private findArchivedSnmpCredentialsQuery: FindArchivedSnmpCredentialsDocument;
	private countAllSnmpCredentialsQuery: CountAllSnmpCredentialsDocument;
	private findDynamicPropertyFieldsSnmpCredentialsQuery: FindDynamicPropertyFieldsSnmpCredentialsDocument;
	private findUnassociatedScanConfigurationsSnmpCredentialsQuery: FindUnassociatedScanConfigurationsSnmpCredentialsDocument;
	private findUnassociatedInventoryHistoriesSnmpCredentialsQuery: FindUnassociatedInventoryHistoriesSnmpCredentialsDocument;
	private findUnassociatedScanByLdapsSnmpCredentialsQuery: FindUnassociatedScanByLdapsSnmpCredentialsDocument;
	private insertSnmpCredentialsMutation: InsertSnmpCredentialsDocument;
	private updateSnmpCredentialsMutation: UpdateSnmpCredentialsDocument;
	private importDataSnmpCredentialsMutation: ImportDataSnmpCredentialsDocument;
	private updateManySnmpCredentialsMutation: UpdateManySnmpCredentialsDocument;
	private deleteSnmpCredentialsMutation: DeleteSnmpCredentialsDocument;
	private restoreSnmpCredentialsMutation: RestoreSnmpCredentialsDocument;
	private recycleSnmpCredentialsMutation: RecycleSnmpCredentialsDocument;
	private restoreArchivedSnmpCredentialsMutation: RestoreArchivedSnmpCredentialsDocument;
	private archivedSnmpCredentialsMutation: ArchivedSnmpCredentialsDocument;
	private addFileDynamicFieldSnmpCredentialsMutation: AddFileDynamicFieldSnmpCredentialsDocument;
	private removeFileDynamicFieldSnmpCredentialsMutation: RemoveFileDynamicFieldSnmpCredentialsDocument;

	constructor(private injector: Injector) {
		this.getSnmpCredentialsQuery = this.injector.get(GetSnmpCredentialsDocument);
		this.firstSnmpCredentialsQuery = this.injector.get(FirstSnmpCredentialsDocument);
		this.countSnmpCredentialsQuery = this.injector.get(CountSnmpCredentialsDocument);
		this.findSnmpCredentialsQuery = this.injector.get(FindSnmpCredentialsDocument);
		this.searchSnmpCredentialsQuery = this.injector.get(SearchSnmpCredentialsDocument);
		this.exportSchemaSnmpCredentialsQuery = this.injector.get(ExportSchemaSnmpCredentialsDocument);
		this.exportDataSnmpCredentialsQuery = this.injector.get(ExportDataSnmpCredentialsDocument);
		this.customQuerySnmpCredentialsQuery = this.injector.get(CustomQuerySnmpCredentialsDocument);
		this.customQueryIdSnmpCredentialsQuery = this.injector.get(CustomQueryIdSnmpCredentialsDocument);
		this.usedSnmpCredentialsQuery = this.injector.get(UsedSnmpCredentialsDocument);
		this.existSnmpCredentialsQuery = this.injector.get(ExistSnmpCredentialsDocument);
		this.findRecyclesSnmpCredentialsQuery = this.injector.get(FindRecyclesSnmpCredentialsDocument);
		this.countRecyclesSnmpCredentialsQuery = this.injector.get(CountRecyclesSnmpCredentialsDocument);
		this.readOnlySnmpCredentialsQuery = this.injector.get(ReadOnlySnmpCredentialsDocument);
		this.findArchivedSnmpCredentialsQuery = this.injector.get(FindArchivedSnmpCredentialsDocument);
		this.countAllSnmpCredentialsQuery = this.injector.get(CountAllSnmpCredentialsDocument);
		this.findDynamicPropertyFieldsSnmpCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsSnmpCredentialsDocument);
		this.findUnassociatedScanConfigurationsSnmpCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsSnmpCredentialsDocument);
		this.findUnassociatedInventoryHistoriesSnmpCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesSnmpCredentialsDocument);
		this.findUnassociatedScanByLdapsSnmpCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsSnmpCredentialsDocument);
		this.insertSnmpCredentialsMutation = this.injector.get(InsertSnmpCredentialsDocument);
		this.updateSnmpCredentialsMutation = this.injector.get(UpdateSnmpCredentialsDocument);
		this.importDataSnmpCredentialsMutation = this.injector.get(ImportDataSnmpCredentialsDocument);
		this.updateManySnmpCredentialsMutation = this.injector.get(UpdateManySnmpCredentialsDocument);
		this.deleteSnmpCredentialsMutation = this.injector.get(DeleteSnmpCredentialsDocument);
		this.restoreSnmpCredentialsMutation = this.injector.get(RestoreSnmpCredentialsDocument);
		this.recycleSnmpCredentialsMutation = this.injector.get(RecycleSnmpCredentialsDocument);
		this.restoreArchivedSnmpCredentialsMutation = this.injector.get(RestoreArchivedSnmpCredentialsDocument);
		this.archivedSnmpCredentialsMutation = this.injector.get(ArchivedSnmpCredentialsDocument);
		this.addFileDynamicFieldSnmpCredentialsMutation = this.injector.get(AddFileDynamicFieldSnmpCredentialsDocument);
		this.removeFileDynamicFieldSnmpCredentialsMutation = this.injector.get(RemoveFileDynamicFieldSnmpCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpCredential> {
    
    		let variables: GetSnmpCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfSnmpCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpCredential> {
    
    		let variables: FirstSnmpCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSnmpCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: FindSnmpCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: SearchSnmpCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSnmpCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSnmpCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: CustomQuerySnmpCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: CustomQueryIdSnmpCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSnmpCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: FindRecyclesSnmpCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesSnmpCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlySnmpCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlySnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpCredential,
		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpCredential> {
    
    		let variables: FindArchivedSnmpCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllSnmpCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsSnmpCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsSnmpCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesSnmpCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsSnmpCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsSnmpCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SnmpCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpCredential> {
    
    		let variables: InsertSnmpCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpCredential> {
    
    		let variables: UpdateSnmpCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSnmpCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySnmpCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedSnmpCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldSnmpCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldSnmpCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldSnmpCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpCredentials.removeFileDynamicField));
    	}
    
}