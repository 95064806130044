<clc-work-item [hint]="data.hint">
  <clc-work-item-field
    *ngIf="
      data.control !== undefined && data.control.type !== 'SchedulerComponent'
    "
    [clcSwitchOnlyOne]="data.control.type"
    mode="full"
  >
    <clc-permissions-list
      *clcSwitchOnlyOneCase="'PermissionsListComponent'"
      [formControlName]="data.control.name"
      [source]="data.control.options.source"
      [fields]="data.control.options.fields"
      [labelExpr]="data.control.options.labelExpr"
      [valueExpr]="data.control.options.valueExpr"
    >
    </clc-permissions-list>
    <clc-sub-form-link-list
      #subList
      *clcSwitchOnlyOneCase="'CoreSubFormLinkListComponent'"
      [readOnly]="
        data.control.readOnly != undefined ? data.control.readOnly : false
      "
      [state]="state"
      [columns]="data.control.options.columns"
      [source]="data.control.options.source"
      [canAdd]="data.control.options['canAdd']"
      [canEdit]="data.control.options['canEdit']"
      [rules]="data.control.options.applyModelRule"
      [canDelete]="data.control.options.canDelete"
      [fieldName]="data.control.options.fieldName"
      [serviceRetreiver]="data.control.options.service"
      [newCommandMenu]="data.control.options.newCommandSource"
      [masterDetail]="data.control.options.masterDetail"
      [multiple]="
        data.control.options?.multiple != undefined
          ? data.control.options.multiple
          : true
      "
      [key]="data.control.options.key"
      [keyModelName]="data.control.options.keyModelName"
      [checkPermissionToRead]="data.control.options.checkPermissionToRead"
      [heightNew]="data.control.options.heightNew"
      [canEdit]="
        data.control.options.canEdit != undefined
          ? data.control.options.canEdit
          : true
      "
      [canAdd]="
        data.control.options.canAdd != undefined
          ? data.control.options.canAdd
          : true
      "
      [canDelete]="
        data.control.options.canDelete != undefined
          ? data.control.options.canDelete
          : true
      "
      [canVisible]="
        data.control.options.canVisible != undefined
          ? data.control.options.canVisible
          : true
      "
      [saveParentFormOnSubSaved]="
        data.control?.options?.saveParentFormOnSubSaved != undefined
          ? data.control.options.saveParentFormOnSubSaved
          : true
      "
    >
    </clc-sub-form-link-list>
    <clc-sub-form-gantt
      #gantt
      *clcSwitchOnlyOneCase="'CoreSubFormGanttComponent'"
      [readOnly]="
        data.control.readOnly != undefined ? data.control.readOnly : false
      "
      [state]="state"
      [columns]="data.control.options.columns"
      [source]="data.control.options.source"
      [serviceRetreiver]="data.control.options.service"
      [fieldName]="data.control.options.fieldName"
      [startExpr]="data.control.options.startExpr"
      [endExpr]="data.control.options.endExpr"
      [rules]="data.control.options.applyModelRule"
      [key]="data.control.options.key"
      [viewGantt]="
        data.control.options.viewGantt != undefined
          ? data.control.options.viewGantt
          : true
      "
      [canEdit]="
        data.control.options.canEdit != undefined
          ? data.control.options.canEdit
          : true
      "
      [canAdd]="
        data.control.options.canAdd != undefined
          ? data.control.options.canAdd
          : true
      "
      [canRemove]="
        data.control.options.canDelete != undefined
          ? data.control.options.canDelete
          : true
      "
      [canVisible]="
        data.control.options.canVisible != undefined
          ? data.control.options.canVisible
          : true
      "
      [canAddTaskSequence]="
        data.control.options.canAddTaskSequence != undefined
          ? data.control.options.canAddTaskSequence
          : true
      "
    >
    </clc-sub-form-gantt>
    <clc-ticket-email-list
      #ticketEmailList
      *clcSwitchOnlyOneCase="'TicketEmailListComponent'"
      [state]="state"
      [key]="data.control.options.key"
      [fieldName]="data.control.options.fieldName"
      [readOnly]="
        data.control.readOnly != undefined ? data.control.readOnly : false
      "
      [columns]="data.control.options.columns"
      [source]="data.control.options.source"
      [serviceRetreiver]="data.control.options.service"
      [masterDetail]="data.control.options.masterDetail"
    >
    </clc-ticket-email-list>
    <clc-link-list
      *clcSwitchOnlyOneCase="'LinkListComponent'"
      [isLiveUpdated]="data.control.options.isLiveUpdated"
      [beforeRemoveItems]="data.control.options.beforeRemoveItems"
      [defaultSource]="data.control.options?.defaultSource"
      [beforeRemoveItemsMessage]="data.control.options.beforeRemoveItemsMessage"
      [source]="data.control.options.source"
      [selectSource]="data.control.options.selectSource"
      [columns]="data.control.options.columns"
      [formControlName]="data.control.name"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [label]="data.control.label"
      [route]="data.control.options.route"
      [masterDetail]="data.control.options.masterDetail"
      [activeButton]="data.control.options.activeButton"
      [titleButton]="data.control.options.titleButton"
      [routeButton]="data.control.options.routeButton"
      [policiesButton]="data.control.options.policiesButton"
      [queryParamsFieldIdButton]="data.control.options.queryParamsFieldIdButton"
      [readOnly]="data.control.readOnly"
      [filters]="data.control.options.filters"
      [displayFilterOnlyForPopup]="
        data.control.options.displayFilterOnlyForPopup
      "
      [canDeleted]="data.control.options.removeItems != undefined"
      [canDissociated]="
        data.control.options.canDissociated != undefined
          ? data.control.options.canDissociated
          : true
      "
      [addSource]="data.control.options.addItems != undefined"
      [removeSource]="data.control.options.removeItems != undefined"
      [hint]="data.control.options.hint"
      [modelState]="state"
      [toolbarItems]="data.control.options.toolbarItems"
      [canSelect]="data.control.options.canSelect"
    >
    </clc-link-list>

    <clc-print-link-list
      *clcSwitchOnlyOneCase="'PrintLinkListComponent'"
      [currentSource]="data.control.options.source"
      [columns]="data.control.options.columns"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [masterDetail]="data.control.options.masterDetail"
      [hint]="data.control.options.hint"
      [modelState]="state"
      [canSelect]="data.control.options.canSelect"
    >
    </clc-print-link-list>

    <clc-dynamic-link-list
      *clcSwitchOnlyOneCase="'DynamicLinkListComponent'"
      [currentSource]="data.control.options.source"
      [columns]="data.control.options.columns"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [masterDetail]="data.control.options.masterDetail"
      [hint]="data.control.options.hint"
      [modelState]="state"
      [canSelect]="data.control.options.canSelect"
      [recursive]="data.control.options.recursive"
      [toolbarItems]="data.control.options.toolbarItems"
      [eventName]="data.control.options.eventName"
    >
    </clc-dynamic-link-list>
    <clc-check-link-list
      *clcSwitchOnlyOneCase="'CheckLinkListComponent'"
      [isLiveUpdated]="data.control.options.isLiveUpdated"
      [beforeRemoveItems]="data.control.options.beforeRemoveItems"
      [defaultSource]="data.control.options?.defaultSource"
      [beforeRemoveItemsMessage]="data.control.options.beforeRemoveItemsMessage"
      [source]="data.control.options.source"
      [selectSource]="data.control.options.selectSource"
      [columns]="data.control.options.columns"
      [formControlName]="data.control.name"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [label]="data.control.label"
      [route]="data.control.options.route"
      [masterDetail]="data.control.options.masterDetail"
      [activeButton]="data.control.options.activeButton"
      [titleButton]="data.control.options.titleButton"
      [routeButton]="data.control.options.routeButton"
      [readOnly]="data.control.readOnly"
      [filters]="data.control.options.filters"
      [canDeleted]="data.control.options.removeItems != undefined"
      [addSource]="data.control.options.addItems != undefined"
      [removeSource]="data.control.options.removeItems != undefined"
      [modelState]="state"
      [hintMessage]="data.control.options.hintMessage"
    >
    </clc-check-link-list>

    <clc-cart-list
      *clcSwitchOnlyOneCase="'StockListComponent'"
      [movementType]="data.control.options.movementType"
      [beforeRemoveItems]="data.control.options.beforeRemoveItems"
      [beforeRemoveItemsMessage]="data.control.options.beforeRemoveItemsMessage"
      [columns]="data.control.options.columns"
      [formControlName]="data.control.name"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [label]="data.control.label"
      [disableEdit]="data.control.disableEdit"
      [route]="data.control.options.route"
      [policies]="data.control.options.policies"
      [canAdd]="
        data.control?.options?.canAdd == undefined
          ? true
          : data.control.options.canAdd
      "
      [canDelete]="
        data.control?.options?.canDelete == undefined
          ? true
          : data.control.options.canDelete
      "
      [canEdit]="
        data.control?.options?.canEdit == undefined
          ? true
          : data.control.options.canEdit
      "
    >
    </clc-cart-list>
    <clc-stock-created-asset-list
      *clcSwitchOnlyOneCase="'StockCreatedAssetListComponent'"
      [source]="data.control.options.source"
      [options]="data.control.options.editing"
      [isOverride]="data.control.options.isOverride"
      [exportSource]="data.control.options.exportSource"
      [columns]="data.control.options.columns"
      [formControlName]="data.control.name"
      [type]="data.control.options.type"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [label]="data.control.label"
      [route]="data.control.options.route"
      [policies]="data.control.options.policies"
    >
    </clc-stock-created-asset-list>
    <clc-tracking-log-list
      *clcSwitchOnlyOneCase="'TrackingLogListComponent'"
      [filter]="data.control.filter ? true : false"
      [message]="data.control.filterMessage"
      [model]="state"
    >
    </clc-tracking-log-list>
    <clc-current-in-stock-list
      *clcSwitchOnlyOneCase="'CurrentInStockListComponent'"
      [source]="data.control.options.source"
      [mode]="data.control.options?.mode"
      [columns]="data.control.options.columns"
      [formControlName]="data.control.name"
      [parentIdExpr]="data.control.options.parentIdExpr"
      [label]="data.control.label"
      [type]="data.control.options.type"
      [route]="data.control.options.route"
      [disableEdit]="data.control.disableEdit"
      [allowDeleting]="data.control.options.allowDeleting"
    >
    </clc-current-in-stock-list>
    <clc-grid
      *clcSwitchOnlyOneCase="'GridComponent'"
      [options]="data.control.options"
      [formControlName]="data.control.name"
      [readOnly]="data.control.readOnly"
      [authorize]="data.control.autohrizeDataSource"
      [modelState]="state"
      [autoRefresh]="data.control?.options?.autoRefresh === true"
      [managed]="data.control.managed"
      [filters]="data.control.options.filters"
    >
    </clc-grid>
    <clc-flat-list
      *clcSwitchOnlyOneCase="'FlatListComponent'"
      [columns]="data.control.options.columns"
      [label]="data.control.options.label"
      [source]="data.control.options.source"
      [formControlName]="data.control.name"
    >
    </clc-flat-list>

    <cl-logic-diagram
      *clcSwitchOnlyOneCase="'LogicDiagramComponent'"
      [formControlName]="data.control.name"
      [fieldName]="data.control.name"
      [state]="state"
      [needRight]="data.control.options.needRight"
      [serviceRetriver]="data.control.options.service"
      [newKey]="data.control.options.newKey"
      [closedKey]="data.control.options.closedKey"
      [useQualification]="
        data.control.options.useQualification !== undefined
          ? data.control.options.useQualification
          : true
      "
      [displaySource]="data.control.options.displaySource"
    >
    </cl-logic-diagram>

    <clc-file-manager
      *clcSwitchOnlyOneCase="'FileManagerComponent'"
      [formControlName]="data.control.name"
      [canRemove]="data.control.options.canDelete"
      [source]="data.control.options.source"
      [readOnly]="data.control.readOnly"
      [canCommentary]="data.control.options.canCommentary"
      [canVisibleHelpDesk]="data.control.options.canVisibleHelpDesk"
      [canOutgoingEmails]="data.control.options.canOutgoingEmails"
      [modelState]="state"
    >
    </clc-file-manager>

    <clc-command-asset-category-list
      *clcSwitchOnlyOneCase="'CommandAssetCategoryListComponent'"
      [options]="data.control.options"
      [formControlName]="data.control.name"
      [readOnly]="data.control.readOnly"
    >
    </clc-command-asset-category-list>

    <clc-pnp-entity-list
      *clcSwitchOnlyOneCase="'PnpEntityListComponent'"
      [dataSource]="data.control.options.source"
    >
    </clc-pnp-entity-list>

    <clc-message-ticket
      *clcSwitchOnlyOneCase="'MessageTicketComponent'"
      [isOperator]="data.control.options?.isOperator"
      [model]="state"
      [isReadOnly]="data.control.readOnly"
      [messageModel]="data.control.options?.messageModel"
    >
    </clc-message-ticket>
    <clc-designer-rule-list
      *clcSwitchOnlyOneCase="'DesignerRuleListComponent'"
      [fieldName]="data.control.name"
      [hasStatus]="data.control.options?.hasStatus"
      [formControlName]="data.control.name"
      [defaultValue]="data.control.defaultValue"
      [state]="state"
      [readOnly]="data.control.readOnly"
    >
    </clc-designer-rule-list>

    <clc-visible-selectable-link-entity
      *clcSwitchOnlyOneCase="'VisibleSelectableLinkEntityComponent'"
      [options]="data.control.options"
      [formControlName]="data.control.name"
      [state]="state"
      [source]="data.control.options.source"
      [entityType]="data.control.options?.entityType"
    >
    </clc-visible-selectable-link-entity>
    <clc-editable-list
      *clcSwitchOnlyOneCase="'EditableListComponent'"
      [options]="data.control.options"
      [formControlName]="data.control.name"
      [state]="state"
    >
    </clc-editable-list>
    <clc-visible-selectable-entity
      *clcSwitchOnlyOneCase="'VisibleSelectableEntityComponent'"
      [options]="data.control.options"
      [formControlName]="data.control.name"
      [state]="state"
    ></clc-visible-selectable-entity>
  </clc-work-item-field>

  <clc-work-item-field
    *ngIf="
      data.control !== undefined && data.control.type === 'SchedulerComponent'
    "
    [clcSwitchOnlyOne]="data.control.type"
    mode="custom"
  >
    <clc-scheduler
      *clcSwitchOnlyOneCase="'SchedulerComponent'"
      [source]="data.control.options.source"
      [showAllDayPanel]="data.control.options.showAllDayPanel"
      [fieldExpr]="data.control.options.fieldExpr"
      [currentView]="data.control.options.currentView"
      [startDayHour]="data.control.options.startDayHour"
      [endDayHour]="data.control.options.endDayHour"
      [allowUpdating]="data.control.options.allowUpdating"
      [allowDeleting]="data.control.options.allowDeleting"
      [allowAdding]="data.control.options.allowAdding"
      [views]="data.control.options.views"
      [readOnly]="data.control.readOnly"
      [defaultDuration]="data.control.options.defaultDuration"
      [remote]="data.control.options.remote"
      [formControlName]="data.control.name"
      [sourceRessource]="data.control.options.sourceRessource"
      [insertRessource]="data.control.options.insertRessource"
      [updateRessource]="data.control.options.updateRessource"
      [fields]="data.control.options.fields"
      [autoRefresh]="data.control.options.autoRefresh"
      [model]="state"
      [checkId]="data.control.options.checkId"
      [selectTitle]="data.control.options.selectTitle"
      [removeRecurrenceItem]="data.control.options.removeRecurrenceItem"
    >
    </clc-scheduler>
  </clc-work-item-field>
  <clc-work-item-section-container
    [percentage]="calcPercentageByVisibility(data)"
    *ngIf="data.sections !== undefined"
  >
    <cl-work-item-section
      *ngFor="let section of returnVisibleSection(data.sections)"
      [location]="calcLocationByVisibility(section, data.sections)"
    >
      <clc-work-item-section-group
        *ngFor="let group of section.groups"
        [title]="group.label"
        [expanded]="group.expanded"
        [formGroup]="parent.form.get(group.name)"
        [visibled]="
          parent.form.get(group.name).visibled && checkControlVisibility(group)
        "
      >
        <clc-work-item-control-dynamic
          [state]="state"
          [controls]="group.controls"
          [autoFocus]="
            data.sections[0] === section && section.groups[0] === group
          "
          (onInitialized)="onComponentInitialized($event)"
        ></clc-work-item-control-dynamic>
      </clc-work-item-section-group>
    </cl-work-item-section>
  </clc-work-item-section-container>

  <clc-work-item-tab-group
    *ngIf="data.tabs !== undefined"
    [tabs]="data.tabs"
    [state]="state"
  >
  </clc-work-item-tab-group>
</clc-work-item>
