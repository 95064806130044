import { Component } from '@angular/core';

/** Représente le composent WorkPage. */
@Component({
  selector: 'cl-work-page',
  templateUrl: './work-page.component.html',
  styleUrls: ['./work-page.component.scss']
})
export class WorkPageComponent {
  // @Input() right = false;
  constructor() { }
}
