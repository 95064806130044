/**
 * Classe contenant les options par défaut d'une grille
*/
export class TreeViewConfigure {

  readonly options = {
    keyExpr: "id",
    parentIdExpr:"parentId",
    displayExpr:"name",
    dataStructure:"plain",
    showCheckBoxesMode:"normal"

  }

  getOptions():any{
    return this.options;
  }
}
