import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfAssetModelStockEntry, FilterOfAssetModelStockEntry, QueryContextOfAssetModelStockEntry, ServiceSingleResultOfInt64, ServiceListResultOfAssetModelStockEntry, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAssetModelStockEntry, AssetModelStockEntryInput } from '../types'

export type GetAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        get?: Maybe<ServiceSingleResultOfAssetModelStockEntry>
    }
}

export type GetAssetModelStockEntriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getAssetModelStockEntries ${args} {
    assetModelStockEntries {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        first?: Maybe<ServiceSingleResultOfAssetModelStockEntry>
    }
}

export type FirstAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstAssetModelStockEntries ${args} {
    assetModelStockEntries {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAssetModelStockEntries ${args} {
    assetModelStockEntries {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        find?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type FindAssetModelStockEntriesBaseVariables = {
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfAssetModelStockEntry = null, $filter: FilterOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssetModelStockEntries ${args} {
    assetModelStockEntries {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        search?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type SearchAssetModelStockEntriesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchAssetModelStockEntries ${args} {
    assetModelStockEntries {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaAssetModelStockEntriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaAssetModelStockEntries ${args} {
    assetModelStockEntries {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataAssetModelStockEntries ${args} {
    assetModelStockEntries {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        customQuery?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type CustomQueryAssetModelStockEntriesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfAssetModelStockEntry = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryAssetModelStockEntries ${args} {
    assetModelStockEntries {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        customQueryId?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type CustomQueryIdAssetModelStockEntriesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfAssetModelStockEntry = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdAssetModelStockEntries ${args} {
    assetModelStockEntries {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedAssetModelStockEntries ${args} {
    assetModelStockEntries {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistAssetModelStockEntriesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existAssetModelStockEntries ${args} {
    assetModelStockEntries {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        findRecycles?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type FindRecyclesAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesAssetModelStockEntries ${args} {
    assetModelStockEntries {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesAssetModelStockEntries ${args} {
    assetModelStockEntries {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyAssetModelStockEntriesBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyAssetModelStockEntries ${args} {
    assetModelStockEntries {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        findArchived?: Maybe<ServiceListResultOfAssetModelStockEntry>
    }
}

export type FindArchivedAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAssetModelStockEntry>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null, $options: QueryContextOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedAssetModelStockEntries ${args} {
    assetModelStockEntries {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllAssetModelStockEntriesBaseVariables = {
	filter: Maybe<FilterOfAssetModelStockEntry>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllAssetModelStockEntries ${args} {
    assetModelStockEntries {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsAssetModelStockEntriesBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfAssetModelStockEntry>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsAssetModelStockEntries ${args} {
    assetModelStockEntries {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        insert?: Maybe<ServiceSingleResultOfAssetModelStockEntry>
    }
}

export type InsertAssetModelStockEntriesBaseVariables = {
	entity: AssetModelStockEntryInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: AssetModelStockEntryInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertAssetModelStockEntries ${args} {
    assetModelStockEntries {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        update?: Maybe<ServiceSingleResultOfAssetModelStockEntry>
    }
}

export type UpdateAssetModelStockEntriesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfAssetModelStockEntry>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAssetModelStockEntries ${args} {
    assetModelStockEntries {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfAssetModelStockEntry", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataAssetModelStockEntriesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataAssetModelStockEntries ${args} {
    assetModelStockEntries {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfAssetModelStockEntry>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfAssetModelStockEntry = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyAssetModelStockEntries ${args} {
    assetModelStockEntries {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteAssetModelStockEntries ${args} {
    assetModelStockEntries {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreAssetModelStockEntries ${args} {
    assetModelStockEntries {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleAssetModelStockEntries ${args} {
    assetModelStockEntries {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedAssetModelStockEntries ${args} {
    assetModelStockEntries {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedAssetModelStockEntriesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedAssetModelStockEntries ${args} {
    assetModelStockEntries {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldAssetModelStockEntriesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldAssetModelStockEntries ${args} {
    assetModelStockEntries {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldAssetModelStockEntriesResultType = {
    assetModelStockEntries: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldAssetModelStockEntriesBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldAssetModelStockEntriesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldAssetModelStockEntries ${args} {
    assetModelStockEntries {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldAssetModelStockEntriesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldAssetModelStockEntriesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
