import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetGroupsBaseVariables, FirstGroupsBaseVariables, CountGroupsBaseVariables, FindGroupsBaseVariables, SearchGroupsBaseVariables, ExportSchemaGroupsBaseVariables, ExportDataGroupsBaseVariables, CustomQueryGroupsBaseVariables, CustomQueryIdGroupsBaseVariables, UsedGroupsBaseVariables, ExistGroupsBaseVariables } from '../gqls'
import { GetGroupsDocument, FirstGroupsDocument, CountGroupsDocument, FindGroupsDocument, SearchGroupsDocument, ExportSchemaGroupsDocument, ExportDataGroupsDocument, CustomQueryGroupsDocument, CustomQueryIdGroupsDocument, UsedGroupsDocument, ExistGroupsDocument } from '../gqls'
import { ServiceSingleResultOfGroup, QueryContextOfGroup, FilterOfGroup, ServiceSingleResultOfInt64, ServiceListResultOfGroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class GroupBaseService {
    
public modelName = 'group';
public modelPluralName = 'groups';

	private getGroupsQuery: GetGroupsDocument;
	private firstGroupsQuery: FirstGroupsDocument;
	private countGroupsQuery: CountGroupsDocument;
	private findGroupsQuery: FindGroupsDocument;
	private searchGroupsQuery: SearchGroupsDocument;
	private exportSchemaGroupsQuery: ExportSchemaGroupsDocument;
	private exportDataGroupsQuery: ExportDataGroupsDocument;
	private customQueryGroupsQuery: CustomQueryGroupsDocument;
	private customQueryIdGroupsQuery: CustomQueryIdGroupsDocument;
	private usedGroupsQuery: UsedGroupsDocument;
	private existGroupsQuery: ExistGroupsDocument;

	constructor(private injector: Injector) {
		this.getGroupsQuery = this.injector.get(GetGroupsDocument);
		this.firstGroupsQuery = this.injector.get(FirstGroupsDocument);
		this.countGroupsQuery = this.injector.get(CountGroupsDocument);
		this.findGroupsQuery = this.injector.get(FindGroupsDocument);
		this.searchGroupsQuery = this.injector.get(SearchGroupsDocument);
		this.exportSchemaGroupsQuery = this.injector.get(ExportSchemaGroupsDocument);
		this.exportDataGroupsQuery = this.injector.get(ExportDataGroupsDocument);
		this.customQueryGroupsQuery = this.injector.get(CustomQueryGroupsDocument);
		this.customQueryIdGroupsQuery = this.injector.get(CustomQueryIdGroupsDocument);
		this.usedGroupsQuery = this.injector.get(UsedGroupsDocument);
		this.existGroupsQuery = this.injector.get(ExistGroupsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroup> {
    
    		let variables: GetGroupsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.get));
            }
            else{
                let result:ServiceSingleResultOfGroup={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfGroup,
		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGroup> {
    
    		let variables: FirstGroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfGroup,
		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroup> {
    
    		let variables: FindGroupsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfGroup,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroup> {
    
    		let variables: SearchGroupsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaGroupsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfGroup,
		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroup> {
    
    		let variables: CustomQueryGroupsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfGroup,
		@Args('filter?') filter?: FilterOfGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfGroup> {
    
    		let variables: CustomQueryIdGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedGroupsBaseVariables = {
    			ids: ids
    		}
    				return this.usedGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistGroupsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.groups.exist));
    	}
    
    
}