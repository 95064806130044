import { PriorityBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs/internal/Observable';
import {
  FilterOfPriority,
  QueryContextOfPriority,
  ServiceListResultOfPriority,
  Sort,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-priority')
export class PriorityCoreService extends PriorityBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findByQualification(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],    
    @Args('options?') options?: QueryContextOfPriority,
    @Args('filter?') filter?: FilterOfPriority,
  ): Observable<ServiceListResultOfPriority> {
    let filterByQualification: FilterOfPriority = {
      qualification: { elemMatch: { in: qualification } },
    };

    if (filter != undefined) {
      filterByQualification.and = [filter];
    }
    if (options == undefined) options = {};
    options.sort = [{ rankOrder: Sort.Asc }];
    return this.find(fields, options, filterByQualification);
  }
}
