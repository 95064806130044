import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfBusinessTime, FilterOfBusinessTime, QueryContextOfBusinessTime, ServiceSingleResultOfInt64, ServiceListResultOfBusinessTime, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfTimeZone, ServiceListResultOfDayOff, FilterOfDayOff, QueryContextOfDayOff, BusinessTimeInput, FieldUpdateOperatorOfBusinessTime } from '../types'

export type GetBusinessTimesResultType = {
    businessTimes: {
        get?: Maybe<ServiceSingleResultOfBusinessTime>
    }
}

export type GetBusinessTimesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getBusinessTimes ${args} {
    businessTimes {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstBusinessTimesResultType = {
    businessTimes: {
        first?: Maybe<ServiceSingleResultOfBusinessTime>
    }
}

export type FirstBusinessTimesBaseVariables = {
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfBusinessTime>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfBusinessTime = null, $options: QueryContextOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstBusinessTimes ${args} {
    businessTimes {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountBusinessTimesResultType = {
    businessTimes: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountBusinessTimesBaseVariables = {
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countBusinessTimes ${args} {
    businessTimes {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindBusinessTimesResultType = {
    businessTimes: {
        find?: Maybe<ServiceListResultOfBusinessTime>
    }
}

export type FindBusinessTimesBaseVariables = {
	options: Maybe<QueryContextOfBusinessTime>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfBusinessTime = null, $filter: FilterOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findBusinessTimes ${args} {
    businessTimes {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchBusinessTimesResultType = {
    businessTimes: {
        search?: Maybe<ServiceListResultOfBusinessTime>
    }
}

export type SearchBusinessTimesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfBusinessTime>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchBusinessTimes ${args} {
    businessTimes {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaBusinessTimesResultType = {
    businessTimes: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaBusinessTimesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaBusinessTimes ${args} {
    businessTimes {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataBusinessTimesResultType = {
    businessTimes: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataBusinessTimesBaseVariables = {
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataBusinessTimes ${args} {
    businessTimes {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryBusinessTimesResultType = {
    businessTimes: {
        customQuery?: Maybe<ServiceListResultOfBusinessTime>
    }
}

export type CustomQueryBusinessTimesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfBusinessTime>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfBusinessTime = null, $options: QueryContextOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryBusinessTimes ${args} {
    businessTimes {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdBusinessTimesResultType = {
    businessTimes: {
        customQueryId?: Maybe<ServiceListResultOfBusinessTime>
    }
}

export type CustomQueryIdBusinessTimesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfBusinessTime>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfBusinessTime>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfBusinessTime = null, $options: QueryContextOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdBusinessTimes ${args} {
    businessTimes {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedBusinessTimesResultType = {
    businessTimes: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedBusinessTimesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedBusinessTimes ${args} {
    businessTimes {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotUsersBusinessTimesResultType = {
    businessTimes: {
        findNotUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindNotUsersBusinessTimesBaseVariables = {
	id: Maybe<string>, /**  */
	memberOfPopulationIds: Array<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotUsersBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $memberOfPopulationIds: [Uuid!], $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotUsersBusinessTimes ${args} {
    businessTimes {
        findNotUsers(id: $id, memberOfPopulationIds: $memberOfPopulationIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotUsersBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotUsersBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUsersBusinessTimesResultType = {
    businessTimes: {
        findUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindUsersBusinessTimesBaseVariables = {
	id: Maybe<string>, /**  */
	memberOfPopulationIds: Array<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUsersBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $memberOfPopulationIds: [Uuid!], $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUsersBusinessTimes ${args} {
    businessTimes {
        findUsers(id: $id, memberOfPopulationIds: $memberOfPopulationIds, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUsersBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUsersBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type TimeZonesBusinessTimesResultType = {
    businessTimes: {
        timeZones?: Maybe<ServiceListResultOfTimeZone>
    }
}

export type TimeZonesBusinessTimesBaseVariables = {
	id: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class TimeZonesBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query timeZonesBusinessTimes ${args} {
    businessTimes {
        timeZones(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: TimeZonesBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<TimeZonesBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTimeZone", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistBusinessTimesResultType = {
    businessTimes: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistBusinessTimesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existBusinessTimes ${args} {
    businessTimes {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedDayOffsBusinessTimesResultType = {
    businessTimes: {
        findUnassociatedDayOffs?: Maybe<ServiceListResultOfDayOff>
    }
}

export type FindUnassociatedDayOffsBusinessTimesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfDayOff>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfDayOff>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedDayOffsBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfDayOff = null, $options: QueryContextOfDayOff = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedDayOffsBusinessTimes ${args} {
    businessTimes {
        findUnassociatedDayOffs(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedDayOffsBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedDayOffsBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDayOff", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUsersBusinessTimesResultType = {
    businessTimes: {
        findUnassociatedUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindUnassociatedUsersBusinessTimesBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUsersBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUsersBusinessTimes ${args} {
    businessTimes {
        findUnassociatedUsers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUsersBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUsersBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertBusinessTimesResultType = {
    businessTimes: {
        insert?: Maybe<ServiceSingleResultOfBusinessTime>
    }
}

export type InsertBusinessTimesBaseVariables = {
	entity: BusinessTimeInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: BusinessTimeInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertBusinessTimes ${args} {
    businessTimes {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateBusinessTimesResultType = {
    businessTimes: {
        update?: Maybe<ServiceSingleResultOfBusinessTime>
    }
}

export type UpdateBusinessTimesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfBusinessTime>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateBusinessTimes ${args} {
    businessTimes {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBusinessTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataBusinessTimesResultType = {
    businessTimes: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataBusinessTimesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataBusinessTimes ${args} {
    businessTimes {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyBusinessTimesResultType = {
    businessTimes: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyBusinessTimesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfBusinessTime>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfBusinessTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyBusinessTimes ${args} {
    businessTimes {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteBusinessTimesResultType = {
    businessTimes: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteBusinessTimesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteBusinessTimes ${args} {
    businessTimes {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUsersCustomBusinessTimesResultType = {
    businessTimes: {
        addUsersCustom?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUsersCustomBusinessTimesBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUsersCustomBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUsersCustomBusinessTimes ${args} {
    businessTimes {
        addUsersCustom(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUsersCustomBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUsersCustomBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUsersCustomBusinessTimesResultType = {
    businessTimes: {
        removeUsersCustom?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUsersCustomBusinessTimesBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUsersCustomBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUsersCustomBusinessTimes ${args} {
    businessTimes {
        removeUsersCustom(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUsersCustomBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUsersCustomBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecalculateDeadlinesBusinessTimesResultType = {
    businessTimes: {
        recalculateDeadlines?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecalculateDeadlinesBusinessTimesBaseVariables = {
	startDate: Date, /**  */
	endDate: Maybe<Date>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RecalculateDeadlinesBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$startDate: DateTime!, $endDate: DateTime = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recalculateDeadlinesBusinessTimes ${args} {
    businessTimes {
        recalculateDeadlines(startDate: $startDate, endDate: $endDate) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecalculateDeadlinesBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecalculateDeadlinesBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddDayOffsBusinessTimesResultType = {
    businessTimes: {
        addDayOffs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddDayOffsBusinessTimesBaseVariables = {
	id: string, /**  */
	dayOffIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddDayOffsBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $dayOffIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addDayOffsBusinessTimes ${args} {
    businessTimes {
        addDayOffs(id: $id, dayOffIds: $dayOffIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddDayOffsBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddDayOffsBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveDayOffsBusinessTimesResultType = {
    businessTimes: {
        removeDayOffs?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveDayOffsBusinessTimesBaseVariables = {
	id: string, /**  */
	dayOffIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveDayOffsBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $dayOffIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeDayOffsBusinessTimes ${args} {
    businessTimes {
        removeDayOffs(id: $id, dayOffIds: $dayOffIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveDayOffsBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveDayOffsBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUsersBusinessTimesResultType = {
    businessTimes: {
        addUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUsersBusinessTimesBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUsersBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUsersBusinessTimes ${args} {
    businessTimes {
        addUsers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUsersBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUsersBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUsersBusinessTimesResultType = {
    businessTimes: {
        removeUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUsersBusinessTimesBaseVariables = {
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUsersBusinessTimesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUsersBusinessTimes ${args} {
    businessTimes {
        removeUsers(userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUsersBusinessTimesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUsersBusinessTimesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
