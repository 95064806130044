import { Injectable } from '@angular/core';
import { GridOptions } from '@clarilog/shared2/models/schema';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import CheckBox from 'devextreme/ui/check_box';

/** Service d'extension des méthodes utilisées par un TreeList */
@Injectable({
  providedIn: 'root',
})
export class TreeListService {
  /* obtient ou définit si recursif */
  hasRecursif = true;

  /* Obtient les noeud indéfinie */
  indeterminateNodes = [];

  /** Options le source */
  internalTreeSource = undefined;

  /** Indique si le datasource et chargé */
  dataSourceLoaded = false;

  /** Obtient ou définit les clé désactivé */
  disabledKeys = undefined;
  parentIdExpr;
  keyExpr;

  extractItem(items) {
    let source = [];
    if (items != undefined) {
      items.forEach((f) => {
        if (f.data != undefined) {
          source.push(f.data);
          let itemChild = this.extractItem(f.children);
          if (itemChild != undefined) {
            source = source.concat(itemChild);
          }
        }
      });
    }
    return source;
  }

  /** Initialisation des information */
  intit(e, options) {
    this.parentIdExpr = e.component.option('parentIdExpr');
    this.keyExpr = e.component.option('keyExpr');

    if (this.parentIdExpr == undefined) {
      this.parentIdExpr = 'parentId';
    }
    if (this.keyExpr == undefined) {
      this.keyExpr = 'id';
    }
    if (e.component.option('dataSource') != undefined) {
      let source = e.component.option('dataSource');

      if (!Array.isArray(source)) {
        source = [];
        let items = e.component.getDataSource()?.items();
        source = this.extractItem(items);
      }

      let setDatasource = false;
      if (this.internalTreeSource == undefined) {
        setDatasource = true;
      } else if (this.internalTreeSource != undefined) {
        if (
          source != undefined &&
          source.length != this.internalTreeSource.length
        ) {
          setDatasource = true;
        }
      }
      // annule si pas besoin
      if (!setDatasource) return;

      this.internalTreeSource = source;

      // Ajout d'une proriété pour obtenir l'ensemble des noeud enfant par noeud
      if (this.internalTreeSource != undefined) {
        this.internalTreeSource.forEach((element) => {
          let children = this.FindChildren(
            this.internalTreeSource,
            element[this.keyExpr],
            this.keyExpr,
            this.parentIdExpr,
          );
          let childrenId = [];
          if (children != undefined) {
            childrenId = children.map((s) => s[this.keyExpr]);
          }

          element['treeChildrenId'] = childrenId;
        });
      }

      // Supprime la sélection des noeud désactivé
      if (
        options?.disabledExpr != undefined &&
        this.disabledKeys == undefined
      ) {
        let disableValue = true;
        if (options?.disabledValue != undefined) {
          disableValue = options.disabledValue;
        }
        this.disabledKeys = this.internalTreeSource.filter(
          (s) => s[options.disabledExpr] == disableValue,
        );
        if (this.disabledKeys != undefined && this.disabledKeys.length > 0) {
          this.disabledKeys = this.disabledKeys.map((s) => s[this.keyExpr]);
        }
      }

      if (this.disabledKeys == undefined) {
        this.disabledKeys = [];
      }
    }
  }
  setRecusirf() {
    this.dataSourceLoaded = true;
    this.hasRecursif = true;
  }
  /** Permet de gérer le mode de sélection du Treelist */
  treelistSelectionChanged(e, options) {
    this.intit(e, options);

    // Ne le fait que dans le cas d'un mode non recursif
    if (options?.recursive !== undefined && options?.recursive !== false)
      return;

    if (
      this.internalTreeSource == undefined ||
      !Array.isArray(this.internalTreeSource)
    ) {
      return;
    }

    e.component.beginUpdate();

    let isSelect = false;
    let nodes = [];
    if (e.currentDeselectedRowKeys.length > 0) {
      isSelect = false;
      nodes = e.currentDeselectedRowKeys;
    } else if (e.currentSelectedRowKeys.length > 0) {
      isSelect = true;
      nodes = e.currentSelectedRowKeys;
    }
    // Obtient toute les clé sélectionné
    let allNodes = e.selectedRowKeys;

    if (nodes.length == 1) {
      // Cas d'une sélection d'une noeud
      if (this.hasRecursif) {
        // Sélectionne / Déselection tout les enfant
        let itemDataSource = this.internalTreeSource.find(
          (f) => f[this.keyExpr] == nodes[0],
        );
        if (itemDataSource != undefined) {
          let children = itemDataSource['treeChildrenId'];
          if (
            children != undefined &&
            children.length > 0 &&
            this.dataSourceLoaded
          ) {
            if (isSelect) {
              allNodes = allNodes.concat(children);
            } else {
              allNodes = allNodes.filter((s) => !children.includes(s));
            }
          }
        }
      }
    } else {
      // Select all
      // this.internalTreeSource.forEach((element) => {
      //   let children = element['treeChildrenId'];
      //   if (children != undefined && children.length > 0) {
      //     if (isSelect) {
      //       allNodes = allNodes.concat(children);
      //     } else {
      //       allNodes = allNodes.filter((s) => !children.includes(s));
      //     }
      //   }
      // });
    }

    // Elimine les doublon
    allNodes = allNodes.filter((v, i, a) => a.indexOf(v) === i);

    if (this.disabledKeys != undefined && this.disabledKeys.length > 0) {
      allNodes = allNodes.filter((f) => !this.disabledKeys.includes(f));
    }

    // Annuler les ancien etat indeterminé
    this.indeterminateNodes.forEach((f) => {
      this.treelistParentStateNode(
        e.component,
        f,
        nodes.length == 1 && nodes[0] == f && isSelect ? true : false,
      );
    });
    this.indeterminateNodes = [];

    // Validation de l'état des parent, récupère tous les key ayant fes enfant
    let hasChildrenNodes = this.internalTreeSource.filter(
      (f) => f['treeChildrenId'] != undefined && f['treeChildrenId'].length > 0,
    );

    if (hasChildrenNodes != undefined && hasChildrenNodes.length > 0) {
      hasChildrenNodes.forEach((f) => {
        let isSelect = allNodes.find((item) => item == f[this.keyExpr]);
        if (isSelect == undefined) {
          // Vérification si un noeud enfant est check
          let hasChildren = f['treeChildrenId'];
          if (hasChildren != undefined && hasChildren.length > 0) {
            let atLeastOne = allNodes.filter((filter) =>
              hasChildren.includes(filter),
            );
            if (atLeastOne != undefined && atLeastOne.length > 0) {
              // Vérification si pas une clé désactivé
              this.indeterminateNodes.push(f[this.keyExpr]);
              this.treelistParentStateNode(
                e.component,
                f[this.keyExpr],
                undefined,
              );
            }
          }
        }
      });
    }

    e.component.selectRows(allNodes);
    e.component.endUpdate();

    this.dataSourceLoaded = true;
    this.hasRecursif = true;
  }

  FindChildren(
    itemSources: any,
    nodeId: string,
    keyExpr: string,
    parentIdExpr: string,
  ) {
    if (
      nodeId != undefined &&
      itemSources != undefined &&
      Array.isArray(itemSources)
    ) {
      let children = itemSources.filter((s) => s[parentIdExpr] == nodeId);
      if (children != undefined && children.length > 0) {
        let recusifChildren = [];
        children.forEach((f) => {
          let find = this.FindChildren(
            itemSources,
            f[keyExpr],
            keyExpr,
            parentIdExpr,
          );
          if (find != undefined && find.length > 0) {
            recusifChildren = recusifChildren.concat(find);
          }
        });

        if (recusifChildren != undefined && recusifChildren.length > 0) {
          children = children.concat(recusifChildren);
        }
      }

      return children;
    }

    return [];
  }

  /** Permet de modifié l'état du check d'un noeud parent en fonction du mode de sélection */
  treelistParentStateNode(component, key, select) {
    if (key != undefined) {
      // Met un etat indéterminé pour les parents
      let index = component.getRowIndexByKey(key);
      let elements = component.getRowElement(index);

      if (elements != undefined && elements.length > 0 && index >= 0) {
        let checks = elements[0].getElementsByClassName('dx-select-checkbox');
        if (checks.length > 0) {
          //Etat indéterminé si pas dans les noeud sélectionné et automatiquement si le précédent parent est en indéterminé
          let editor = CheckBox.getInstance(checks[0]);
          if (editor != undefined) {
            editor.option('value', select);
            //checks[0].classList.add('dx-checkbox-indeterminate');
          }
        }
      }
    }
  }

  /** Permet de modifié l'état du check d'un noeud parent en fonction du mode de sélection */
  treelistDisabledNode(component, key) {
    if (key != undefined) {
      // Met un etat indéterminé pour les parents
      let index = component.getRowIndexByKey(key);
      let elements = component.getRowElement(index);

      if (elements != undefined && elements.length > 0 && index >= 0) {
        let checks = elements[0].getElementsByClassName('dx-select-checkbox');
        if (checks.length > 0) {
          //Etat indéterminé si pas dans les noeud sélectionné et automatiquement si le précédent parent est en indéterminé
          checks[0].classList.add('dx-state-disabled');
          checks[0].classList.add('cl-state-node-disabled');
        }
      }
    }
  }

  /** Sélection tout */
  treelistCheckAll(e, options): any[] {
    let selectedKeys = [];
    e.component.forEachNode((node) => {
      if (!this.treelistNodeHasDisabled(node.key, undefined, options)) {
        selectedKeys.push(node.key);
      }
    });

    return selectedKeys;
  }

  treelistCollapseAll(e) {
    e.component.beginUpdate();

    e.component.forEachNode((node) => {
      e.component.collapseRow(node.key);
    });
    e.component.option('autoExpandAll', false);
    e.component.endUpdate();
  }

  treelistExpandAll(e) {
    e.component.beginUpdate();
    e.component.option('autoExpandAll', true);
    // e.component.forEachNode((node) => {
    //   e.component.expandRow(node.key);
    // });
    e.component.endUpdate();
  }

  /** Permet de rafraîchir l'état de la grille en fonction de son mode de selection */
  treelistContentReadyTreeList(e, options) {
    this.intit(e, options);

    if (this.indeterminateNodes != null && this.indeterminateNodes.length > 0) {
      this.indeterminateNodes.forEach((f) => {
        this.treelistParentStateNode(e.component, f, undefined);
      });
    }

    if (this.disabledKeys != null && this.disabledKeys.length > 0) {
      this.disabledKeys.forEach((f) => {
        this.treelistDisabledNode(e.component, f);
      });
    }
  }

  treelistNodeHasDisabled(key, data, options) {
    if (
      options != undefined &&
      key != undefined &&
      this.disabledKeys != undefined &&
      this.disabledKeys.length > 0
    ) {
      return this.disabledKeys.find((f) => f == key) == undefined
        ? false
        : true;
    } else {
      if (
        options != undefined &&
        data != undefined &&
        options.disabledExpr != undefined
      ) {
        return data[options.disabledExpr] === true ? true : false;
      }
    }

    return false;
  }

  /** Préparation du menu de la grille */
  treelistContextMenuPreparing(
    e,
    readOnly,
    options,
    activeSelectLevelAction: boolean = true,
  ) {
    if (e.target != 'header') {
      // e.items can be undefined
      if (!e.items) e.items = [];

      //Vérifie que le noeud n'est pas disable
      let key = e.component.getKeyByRowIndex(e.rowIndex);
      let hasDisbale = this.treelistNodeHasDisabled(
        undefined,
        e.row?.data,
        options,
      );

      if (key != undefined && !(readOnly === true) && !hasDisbale) {
        let node = e.component.getNodeByKey(key);

        if (node.hasChildren && activeSelectLevelAction) {
          // Add a custom menu item
          e.items.push({
            beginGroup: true,
            text: TranslateService.get('globals/selectLevel'),
            onItemClick: () => {
              this.treelistSelectChild(e, true, e.rowIndex);
            },
          });

          e.items.push({
            text: TranslateService.get('globals/unSelectLevel'),
            onItemClick: () => {
              this.treelistSelectChild(e, false, e.rowIndex);
            },
          });
        }
      }

      e.items.push({
        beginGroup: true,
        text: TranslateService.get('globals/expandAll'),
        onItemClick: () => {
          this.treelistExpandAll(e);
        },
      });

      e.items.push({
        text: TranslateService.get('globals/collapseAll'),
        onItemClick: () => {
          this.treelistCollapseAll(e);
        },
      });
    }
  }

  /** Sélectionne ou désecltionne les noeuds enfants */
  treelistSelectChild(e, select, rowIndex) {
    this.hasRecursif = false;
    let allNodes = e.component.getSelectedRowKeys('all');

    let key = e.component.getKeyByRowIndex(rowIndex);
    if (select) {
      allNodes.push(key);
    } else {
      allNodes = allNodes.filter((s) => key != s);
    }
    allNodes = allNodes.filter((v, i, a) => a.indexOf(v) === i);

    e.component.selectRows(allNodes);
  }

  /** Permet de gérer l'apparence d'une cellule */
  treelistCellPrepared(e, options: GridOptions) {}

  treelistCollapsed(e, options) {
    //this.checkNode(e, e.key, options);
  }
}
