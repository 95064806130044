import { PopulationBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfWarningMessage,
  QueryContextOfWarningMessage,
  ServiceListResultOfWarningMessage,
  WarningMessageCategory,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize('user')
export class PopulationCoreService extends PopulationBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findWarningMessages(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfWarningMessage,
    @Args('filter?') filter?: FilterOfWarningMessage,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfWarningMessage> {
    let defaultFilter: FilterOfWarningMessage;
    defaultFilter = {
      warningMessageType: { eq: WarningMessageCategory.HelpMe },
    };
    if (filter != null) {
      filter = { and: [filter, defaultFilter] };
    } else {
      filter = defaultFilter;
    }

    return super.findWarningMessages(
      fields,
      options,
      filter,
      extendedVariables,
    );
  }
}
