import { UserPrivilegeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize, Maybe } from '@clarilog/core/services/graphql';
import {
  FilterOfUser,
  Privilege,
  PrivilegeEntry,
  QueryContextOfUser,
  ServiceListResultOfUser,
  UserPrivilege,
} from '../types';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { map } from 'rxjs/operators';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable } from 'rxjs/internal/Observable';
import { TranslatedFieldHelperService } from '@clarilog/shared2';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-user-privilege')
export class UserPrivilegeCoreService extends UserPrivilegeBaseService {
  private translatedFieldHelperService: TranslatedFieldHelperService;

  constructor(injector: Injector) {
    super(injector);
    this.translatedFieldHelperService = injector.get(
      'TranslatedFieldHelperService',
    );
  }

  public getEntryFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ];
  }

  public getPrivilegeEntriesFields(): GqlFields {
    return [
      GqlField.create('title'),
      GqlField.create('enable'),
      GqlField.create('key'),
    ];
  }

  public getFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlSubField.create(
          'name',
          this.translatedFieldHelperService.translatedFields(),
        ),
        GqlField.create('key'),
        GqlField.create('isDefault'),
        GqlSubField.create(
          'affectedUserEntries',
          this.getPrivilegeEntriesFields(),
        ),
        GqlSubField.create(
          'otherAffectedUserEntries',
          this.getPrivilegeEntriesFields(),
        ),
        GqlSubField.create(
          'responsableUserEntries',
          this.getPrivilegeEntriesFields(),
        ),
        GqlSubField.create(
          'accessTicketsObserverEntries',
          this.getPrivilegeEntriesFields(),
        ),
      ]),
    ];
  }

  public static hasPrivilege(
    privilege: UserPrivilege,
    privilegeEntry: string,
  ): boolean {
    if (
      privilege.affectedUserEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(privilegeEntry)
    ) {
      return true;
    }
    return false;
  }
  public static hasPrivilegeEntry(
    privilegeEntries: PrivilegeEntry[],
    key: string,
  ): boolean {
    if (
      privilegeEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(key)
    ) {
      return true;
    }
    return false;
  }
  public async defaultAffectedUserValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultEntryValue(
      this.getEntryFields(),
      'DefaultAffectedUserEntries',
    )
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }
  public async defaultOtherAffectedUserValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultEntryValue(
      this.getEntryFields(),
      'DefaultOtherAffectedUserEntries',
    )
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }
  public async defaultaccessTicketsObserverValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultEntryValue(
      this.getEntryFields(),
      'DefaultAccessTicketsObserverEntries',
    )
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }
  public async defaultResponsableUserValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultEntryValue(
      this.getEntryFields(),
      'DefaultResponsableUserEntries',
    )
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }

  /**  */
  @InjectArgs
  public findAssociatedUsersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        memberOfPopulationIds =
          filter.and[index].memberOfPopulationIds.elemMatch['in'];
        delete filter.and[index].memberOfPopulationIds;
      } else {
        memberOfPopulationIds = filter.memberOfPopulationIds.elemMatch['in'];
        delete filter.memberOfPopulationIds;
      }
    }
    return this.findUsers(
      fields,
      memberOfPopulationIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  /**  */
  @InjectArgs
  public findNotUsersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        memberOfPopulationIds =
          filter.and[index].memberOfPopulationIds.elemMatch['in'];
        delete filter.and[index].memberOfPopulationIds;
      } else {
        memberOfPopulationIds = filter.memberOfPopulationIds?.elemMatch['in'];
        delete filter.memberOfPopulationIds;
      }
    }
    return this.findNotUsers(
      fields,
      memberOfPopulationIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  public getDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      { isDefault: { eq: true } },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }
}
