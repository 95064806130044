import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfVerifaliaEmailCheckMetric, FilterOfVerifaliaEmailCheckMetric, QueryContextOfVerifaliaEmailCheckMetric, ServiceSingleResultOfInt64, ServiceListResultOfVerifaliaEmailCheckMetric, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, VerifaliaEmailCheckMetricInput, FieldUpdateOperatorOfVerifaliaEmailCheckMetric } from '../types'

export type GetVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        get?: Maybe<ServiceSingleResultOfVerifaliaEmailCheckMetric>
    }
}

export type GetVerifaliaEmailCheckMetricsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        first?: Maybe<ServiceSingleResultOfVerifaliaEmailCheckMetric>
    }
}

export type FirstVerifaliaEmailCheckMetricsBaseVariables = {
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfVerifaliaEmailCheckMetric>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfVerifaliaEmailCheckMetric = null, $options: QueryContextOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountVerifaliaEmailCheckMetricsBaseVariables = {
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        find?: Maybe<ServiceListResultOfVerifaliaEmailCheckMetric>
    }
}

export type FindVerifaliaEmailCheckMetricsBaseVariables = {
	options: Maybe<QueryContextOfVerifaliaEmailCheckMetric>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfVerifaliaEmailCheckMetric = null, $filter: FilterOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        search?: Maybe<ServiceListResultOfVerifaliaEmailCheckMetric>
    }
}

export type SearchVerifaliaEmailCheckMetricsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfVerifaliaEmailCheckMetric>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaVerifaliaEmailCheckMetricsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataVerifaliaEmailCheckMetricsBaseVariables = {
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        customQuery?: Maybe<ServiceListResultOfVerifaliaEmailCheckMetric>
    }
}

export type CustomQueryVerifaliaEmailCheckMetricsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfVerifaliaEmailCheckMetric>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfVerifaliaEmailCheckMetric = null, $options: QueryContextOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        customQueryId?: Maybe<ServiceListResultOfVerifaliaEmailCheckMetric>
    }
}

export type CustomQueryIdVerifaliaEmailCheckMetricsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfVerifaliaEmailCheckMetric>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfVerifaliaEmailCheckMetric>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfVerifaliaEmailCheckMetric = null, $options: QueryContextOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedVerifaliaEmailCheckMetricsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistVerifaliaEmailCheckMetricsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        insert?: Maybe<ServiceSingleResultOfVerifaliaEmailCheckMetric>
    }
}

export type InsertVerifaliaEmailCheckMetricsBaseVariables = {
	entity: VerifaliaEmailCheckMetricInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: VerifaliaEmailCheckMetricInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        update?: Maybe<ServiceSingleResultOfVerifaliaEmailCheckMetric>
    }
}

export type UpdateVerifaliaEmailCheckMetricsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfVerifaliaEmailCheckMetric>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfVerifaliaEmailCheckMetric", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataVerifaliaEmailCheckMetricsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyVerifaliaEmailCheckMetricsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfVerifaliaEmailCheckMetric>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfVerifaliaEmailCheckMetric = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteVerifaliaEmailCheckMetricsResultType = {
    verifaliaEmailCheckMetrics: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteVerifaliaEmailCheckMetricsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteVerifaliaEmailCheckMetricsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteVerifaliaEmailCheckMetrics ${args} {
    verifaliaEmailCheckMetrics {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteVerifaliaEmailCheckMetricsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteVerifaliaEmailCheckMetricsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
