<star-rating
  *ngIf="isInit === true"
  [starType]="'svg'"
  [hoverEnabled]="true"
  [rating]="personalRating"
  (starClickChange)="starClickChange($event)"
  labelPosition="right"
  [numOfStars]="numOfStars ?? 6"
  [labelText]="getLabelText()"
  (ratingChange)="onRatingChange($event)"
  [readOnly]="readonly"
  (hoverRatingChange)="onHoverRatingChange($event)"
></star-rating>
