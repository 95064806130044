import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceListResultOfPropertyField } from '../types'

export type FieldsCommonsResultType = {
    commons: {
        fields?: Maybe<ServiceListResultOfPropertyField>
    }
}

export type FieldsCommonsBaseVariables = {
	type: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class FieldsCommonsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query fieldsCommons ${args} {
    commons {
        fields(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FieldsCommonsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FieldsCommonsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AllFieldsCommonsResultType = {
    commons: {
        allFields?: Maybe<ServiceListResultOfPropertyField>
    }
}

export type AllFieldsCommonsBaseVariables = {
	type: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AllFieldsCommonsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query allFieldsCommons ${args} {
    commons {
        allFields(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: AllFieldsCommonsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<AllFieldsCommonsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

