import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router } from '@angular/router';
import { Column } from '@clarilog/shared2/models/schema';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import Globalize from 'globalize/dist/globalize';
import { TreeListService } from '../list/tree-list.service';
import { TemplatesService } from '../../templates/templates.service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-flat-list',
  templateUrl: './flat-list.component.html',
  styleUrls: ['./flat-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreFlatListComponent),
      multi: true,
    },
  ],
})
export class CoreFlatListComponent implements ControlValueAccessor, OnInit {
  /** Sauvegarde les valeurs. */
  _values: any[] = [];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit les colonnes */
  @Input() columns: Column[];
  /** Obtient ou définit la source de données. */
  @Input() source: ModelDataSourceContext;
  /** Obtient ou définit la source de données. */
  @Input() label: string;

  /** Liste des items à afficher */
  items = [];

  /** Récupère le composant en cours. */
  component;

  /** Tableau des index sélectionnées */
  selectedKeys: any[] = [];

  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    this.onChange(this._values);
    this.onTouched();
  }
  constructor(
    public templateService: TemplatesService,
    private _treeListService: TreeListService,
    private _router: Router,
  ) {}
  /** @inheritdoc */
  writeValue(values: any[]): void {
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  async ngOnInit() {
    let dataSource = this.source.datasource;
    this.items = await dataSource.load();
    this.columns = this.columns.filter(
      (f) => f.visible == undefined || f.visible === true,
    );
  }

  /** Rafraichi la liste. */
  refresh() {
    // Reset origine
    // (<DataSource>(<unknown>this.options.source)).load().then(results => {
    //   this.source = results;
    //   this._values = JSON.parse(JSON.stringify(this._originalValues));
    //   this.onChange(this._values);
    //   this.onTouched();
    //   this.selectDefaultValues();
    // });
  }

  /** Permet de renvoyer la valeur */
  public displayValue(value, column) {
    if (column.lookup != undefined && column.lookup.source != undefined) {
      let filter = column.lookup.source.filter((d) => d.id == value);
      if (filter.length > 0) {
        return filter[0].value;
      }
    }

    if (column.dataType === 'date' && value != undefined) {
      if (column.format === 'shortDate') {
        value = new Date(value)
          .toLocaleString(Globalize.locale().locale)
          .split(', ')[0];
      } else if (column.format === 'shortDateShortTime') {
        value = new Date(value).toLocaleString(Globalize.locale().locale);
      } else {
      }
    }
    return value;
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo() {
    // let url = this._router.createUrlTree([this.options.route], {
    //   skipLocationChange: true,
    // });
    // let win = window.open(url.toString(), '_blank');
    // win.opener.callback = async () => await this.refresh();
  }
}
