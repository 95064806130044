import { Injectable } from '@angular/core';

/** Représente le service de paramètre. */
@Injectable({
  providedIn: 'root',
})
export class ParameterService {
  _parameters: { [k: string]: any }[];
  load(values) {
    this._parameters = values;
  }
  clear() {
    this._parameters.clear();
  }
  get(key: string): any {
    return this._parameters[key];
  }
}
/** Représente le context du service. */
@Injectable({
  providedIn: 'root',
})
export class ModelContextService {
  private _params: ParamsMap;
  private _entry: ParamsMap;
  private _lock: ParamsMap;
  private _excludeParams: string[] = ['filters', 'options'];

  constructor() {
    this._params = new ParamsMap();
    this._entry = new ParamsMap();
    this._lock = new ParamsMap();
  }

  /** Permet de verrouillé un champs */
  public get lock(): ParamsMap {
    return this._lock;
  }
  public get entry(): ParamsMap {
    return this._entry;
  }
  public get params(): ParamsMap {
    return this._params;
  }
  public isInternal(key: string): boolean {
    return this._excludeParams.indexOf(key) > -1;
  }
  public clear() {
    this._params.clear();
    this._entry.clear();
    this._lock.clear();
  }
}
/** Représente un map de clé/valeur. */
export class ParamsMap {
  _params: { [k: string]: () => any } = {};
  set(key: string, builder: () => any) {
    key = key.replace(/\./g, '_');
    this._params[key] = builder;
  }
  remove(key: string) {
    delete this._params[key];
  }
  clear() {
    this._params = {};
  }
  get(key: string): any {
    let rawKey = key;

    key = key.replace(/\_/g, '.').replace('?', '');
    if (key == 'currentContext') {
      return this._params;
    }

    let parameter = this._params[key];

    if (parameter === undefined) {
      // if params = filter? optional params
      parameter = this._params[key + '?'];
    }

    if (parameter == undefined && rawKey.includes('?') === false) {
      throw new Error(
        `Le paramètre '${rawKey}' n'existe pas dans le context d'execution.`,
      );
    }
    if (parameter == undefined) {
      return null;
    }
    return parameter();
  }
  materialize(): any {
    let obj = {};
    if (this.count() > 0) {
      const entry = this.all();
      for (let member in entry) {
        obj[member] = this.get(member);
      }
    }
    return obj;
  }
  all() {
    return this._params;
  }
  count() {
    return Object.keys(this._params).length;
  }
}
