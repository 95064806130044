import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWallSocketsBaseVariables, FirstWallSocketsBaseVariables, CountWallSocketsBaseVariables, FindWallSocketsBaseVariables, SearchWallSocketsBaseVariables, ExportSchemaWallSocketsBaseVariables, ExportDataWallSocketsBaseVariables, CustomQueryWallSocketsBaseVariables, CustomQueryIdWallSocketsBaseVariables, UsedWallSocketsBaseVariables, ExistWallSocketsBaseVariables, InsertWallSocketsBaseVariables, UpdateWallSocketsBaseVariables, ImportDataWallSocketsBaseVariables, UpdateManyWallSocketsBaseVariables, DeleteWallSocketsBaseVariables } from '../gqls'
import { GetWallSocketsDocument, FirstWallSocketsDocument, CountWallSocketsDocument, FindWallSocketsDocument, SearchWallSocketsDocument, ExportSchemaWallSocketsDocument, ExportDataWallSocketsDocument, CustomQueryWallSocketsDocument, CustomQueryIdWallSocketsDocument, UsedWallSocketsDocument, ExistWallSocketsDocument, InsertWallSocketsDocument, UpdateWallSocketsDocument, ImportDataWallSocketsDocument, UpdateManyWallSocketsDocument, DeleteWallSocketsDocument } from '../gqls'
import { ServiceSingleResultOfWallSocket, QueryContextOfWallSocket, FilterOfWallSocket, ServiceSingleResultOfInt64, ServiceListResultOfWallSocket, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WallSocketInput, FieldUpdateOperatorOfWallSocket } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WallSocketBaseService {
    
public modelName = 'wallSocket';
public modelPluralName = 'wallSockets';

	private getWallSocketsQuery: GetWallSocketsDocument;
	private firstWallSocketsQuery: FirstWallSocketsDocument;
	private countWallSocketsQuery: CountWallSocketsDocument;
	private findWallSocketsQuery: FindWallSocketsDocument;
	private searchWallSocketsQuery: SearchWallSocketsDocument;
	private exportSchemaWallSocketsQuery: ExportSchemaWallSocketsDocument;
	private exportDataWallSocketsQuery: ExportDataWallSocketsDocument;
	private customQueryWallSocketsQuery: CustomQueryWallSocketsDocument;
	private customQueryIdWallSocketsQuery: CustomQueryIdWallSocketsDocument;
	private usedWallSocketsQuery: UsedWallSocketsDocument;
	private existWallSocketsQuery: ExistWallSocketsDocument;
	private insertWallSocketsMutation: InsertWallSocketsDocument;
	private updateWallSocketsMutation: UpdateWallSocketsDocument;
	private importDataWallSocketsMutation: ImportDataWallSocketsDocument;
	private updateManyWallSocketsMutation: UpdateManyWallSocketsDocument;
	private deleteWallSocketsMutation: DeleteWallSocketsDocument;

	constructor(private injector: Injector) {
		this.getWallSocketsQuery = this.injector.get(GetWallSocketsDocument);
		this.firstWallSocketsQuery = this.injector.get(FirstWallSocketsDocument);
		this.countWallSocketsQuery = this.injector.get(CountWallSocketsDocument);
		this.findWallSocketsQuery = this.injector.get(FindWallSocketsDocument);
		this.searchWallSocketsQuery = this.injector.get(SearchWallSocketsDocument);
		this.exportSchemaWallSocketsQuery = this.injector.get(ExportSchemaWallSocketsDocument);
		this.exportDataWallSocketsQuery = this.injector.get(ExportDataWallSocketsDocument);
		this.customQueryWallSocketsQuery = this.injector.get(CustomQueryWallSocketsDocument);
		this.customQueryIdWallSocketsQuery = this.injector.get(CustomQueryIdWallSocketsDocument);
		this.usedWallSocketsQuery = this.injector.get(UsedWallSocketsDocument);
		this.existWallSocketsQuery = this.injector.get(ExistWallSocketsDocument);
		this.insertWallSocketsMutation = this.injector.get(InsertWallSocketsDocument);
		this.updateWallSocketsMutation = this.injector.get(UpdateWallSocketsDocument);
		this.importDataWallSocketsMutation = this.injector.get(ImportDataWallSocketsDocument);
		this.updateManyWallSocketsMutation = this.injector.get(UpdateManyWallSocketsDocument);
		this.deleteWallSocketsMutation = this.injector.get(DeleteWallSocketsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWallSocket> {
    
    		let variables: GetWallSocketsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.get));
            }
            else{
                let result:ServiceSingleResultOfWallSocket={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWallSocket,
		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWallSocket> {
    
    		let variables: FirstWallSocketsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWallSocketsBaseVariables = {
    			filter: filter
    		}
    				return this.countWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWallSocket,
		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWallSocket> {
    
    		let variables: FindWallSocketsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWallSocket,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWallSocket> {
    
    		let variables: SearchWallSocketsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWallSocketsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWallSocketsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWallSocket,
		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWallSocket> {
    
    		let variables: CustomQueryWallSocketsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWallSocket,
		@Args('filter?') filter?: FilterOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWallSocket> {
    
    		let variables: CustomQueryIdWallSocketsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWallSocketsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWallSocketsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWallSocketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WallSocketInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWallSocket> {
    
    		let variables: InsertWallSocketsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWallSocketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWallSocket> {
    
    		let variables: UpdateWallSocketsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWallSocketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWallSocketsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWallSocketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWallSocket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWallSocketsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWallSocketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWallSocketsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWallSocketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wallSockets.delete));
    	}
    
}