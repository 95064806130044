import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { MyOrganizationsMobileComponent } from '@clarilog/modules2/organizations/modules/my-organizations-mobile/my-organizations-mobile.component';
import { AppComponent } from './app.component';
import { AuthCoreGuard, AuthThemeLoaderGuard } from './core';
import { CoreContentLayoutComponent } from './layouts2/content-layout/content-layout.component';
import { MobileLayoutComponent } from './layouts2/mobile-layout/mobile-layout.component';
import { ErrorComponent } from './shared2/pages/error/error.component';
import { NoLicenseComponent } from './shared2/pages/no-license/no-license.component';
import { NotFoundComponent } from './shared2/pages/not-found/not-found.component';
import { UnauthorizedComponent } from './shared2/pages/unauthorized/unauthorized.component';

/** Gestion des routes. */
const routes: Routes = [
  {
    path: '',
    component: AppComponent,
    canActivate: [AuthCoreGuard],
    canActivateChild: [AuthThemeLoaderGuard, AuthCoreGuard],
    children: [
      {
        path: '',
        component: CoreContentLayoutComponent,
        canActivate: [AuthCoreGuard],
        children: [
          // { path: "", redirectTo: "dashboard", pathMatch: "full" },
          {
            path: 'dashboard',
            loadChildren: () =>
              import('./modules2/dashboard/dashboard.module').then(
                (m) => m.DashboardModule,
              ),
          },
          {
            path: 'reports',
            loadChildren: () =>
              import('@clarilog/modules2/report/report.module').then(
                (m) => m.ReportModule,
              ),
          },
          {
            path: 'contracts',
            loadChildren: () =>
              import('@clarilog/modules2/contracts/contracts.module').then(
                (m) => m.ContractsModule,
              ),
          },
          {
            path: 'assets',
            loadChildren: () =>
              import('@clarilog/modules2/assets/assets.module').then(
                (m) => m.AssetsModule,
              ),
          },
          {
            path: 'financials',
            loadChildren: () =>
              import('@clarilog/modules2/financials/financials.module').then(
                (m) => m.FinancialsModule,
              ),
          },
          {
            path: 'inventory',
            loadChildren: () =>
              import('@clarilog/modules2/inventory/inventory.module').then(
                (m) => m.InventoryModule,
              ),
          },
          {
            path: 'stocks',
            loadChildren: () =>
              import('@clarilog/modules2/stocks/stocks.module').then(
                (m) => m.StocksModule,
              ),
          },
          {
            path: 'loans',
            loadChildren: () =>
              import('@clarilog/modules2/loans/loans.module').then(
                (m) => m.LoansModule,
              ),
          },
          {
            path: 'users',
            loadChildren: () =>
              import('@clarilog/modules2/users/users.module').then(
                (m) => m.UsersModule,
              ),
          },
          {
            path: 'suppliers',
            loadChildren: () =>
              import('@clarilog/modules2/suppliers/suppliers.module').then(
                (m) => m.SuppliersModule,
              ),
          },
          {
            path: 'tasks',
            loadChildren: () =>
              import('@clarilog/modules2/task/task.module').then(
                (m) => m.TaskModule,
              ),
          },
          {
            path: 'tickets',
            loadChildren: () =>
              import('@clarilog/modules2/ticket/ticket.module').then(
                (m) => m.TicketModule,
              ),
          },
          {
            path: 'favorite-tickets',
            loadChildren: () =>
              import(
                '@clarilog/modules2/ticket/modules/favorite/favorite.module'
              ).then((m) => m.FavoriteModule),
          },
          {
            path: 'incidents',
            loadChildren: () =>
              import('@clarilog/modules2/incident/incident.module').then(
                (m) => m.IncidentModule,
              ),
          },
          {
            path: 'requests',
            loadChildren: () =>
              import('@clarilog/modules2/request/request.module').then(
                (m) => m.RequestModule,
              ),
          },
          {
            path: 'problems',
            loadChildren: () =>
              import('@clarilog/modules2/problem/problem.module').then(
                (m) => m.ProblemModule,
              ),
          },
          {
            path: 'article-knowledge',
            loadChildren: () =>
              import(
                '@clarilog/modules2/article-knowledge/article-knowledge.module'
              ).then((m) => m.ArticleKnowledgeModule),
          },
          {
            path: 'administration',
            loadChildren: () =>
              import(
                '@clarilog/modules2/administration/administration.module'
              ).then((m) => m.AdministrationModule),
          },
          {
            path: 'alerts',
            loadChildren: () =>
              import('@clarilog/modules2/alerts/alerts.module').then(
                (m) => m.AlertsModule,
              ),
          },
          {
            path: 'notifications',
            loadChildren: () =>
              import(
                '@clarilog/modules2/notification/notification.module'
              ).then((m) => m.NotificationModule),
          },
          {
            path: 'softwares',
            loadChildren: () =>
              import('@clarilog/modules2/software/software.module').then(
                (m) => m.SoftwareModule,
              ),
          },
          {
            path: 'service-desk',
            loadChildren: () =>
              import(
                '@clarilog/modules2/administration/modules/service-desk/service-desk.module'
              ).then((m) => m.ServiceDeskModule),
          },
          {
            path: 'home',
            loadChildren: () =>
              import(
                '@clarilog/modules2/dashboard-user/dashboard-user.module'
              ).then((m) => m.DashboardUserModule),
          },
          {
            path: 'query-builders',
            loadChildren: () =>
              import(
                '@clarilog/modules2/query-builders/query-builders.module'
              ).then((m) => m.QueryBuildersModule),
          },
          {
            path: 'help-desk-incident',
            loadChildren: () =>
              import(
                '@clarilog/modules2/help-desk-incident/help-desk-incident.module'
              ).then((m) => m.HelpDeskUserIncidentModule),
          },
          {
            path: 'help-desk-request',
            loadChildren: () =>
              import(
                '@clarilog/modules2/help-desk-request/help-desk-request.module'
              ).then((m) => m.HelpDeskUserRequestModule),
          },
          {
            path: 'help-desk-knowledge',
            loadChildren: () =>
              import(
                '@clarilog/modules2/help-desk-knowledge/help-desk-knowledge.module'
              ).then((m) => m.HelpDeskUserKnowledgeModule),
          },
          {
            path: 'help-desk-my-asset',
            loadChildren: () =>
              import(
                '@clarilog/modules2/help-desk-myAsset/help-desk-my-asset.module'
              ).then((m) => m.HelpDeskUserMyAssetModule),
          },
          {
            path: 'help-desk-my-loan',
            loadChildren: () =>
              import(
                '@clarilog/modules2/help-desk-myLoan/help-desk-my-loan.module'
              ).then((m) => m.HelpDeskUserMyLoanModule),
          },
        ],
      },
      {
        path: 'mobile',
        component: MobileLayoutComponent,
        canActivate: [AuthCoreGuard],
        loadChildren: () =>
          import(
            '@clarilog/modules2/mobile-help-desk/mobile-help-desk.module'
          ).then((m) => m.MobileHelpDeskModule),
      },
      {
        path: 'organizations',
        loadChildren: () =>
          import('@clarilog/modules2/organizations/organizations.module').then(
            (m) => m.OrganizationsModule,
          ),
      },
      {
        path: 'file',
        loadChildren: () =>
          import('@clarilog/modules2/file/file.module').then(
            (m) => m.FileModule,
          ),
      },
      {
        path: 'asset-affected',
        loadChildren: () =>
          import(
            '@clarilog/modules2/asset-affected/asset-affected.module'
          ).then((m) => m.AssetAffectedModule),
      },
      {
        path: 'organizations/my-organizations/mobile',
        component: MyOrganizationsMobileComponent,
      },
    ],
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'unauthorized',
    component: UnauthorizedComponent,
  },
  {
    path: 'no-license',
    component: NoLicenseComponent,
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];
/**
 * Import des modules et des fournisseurs
 * les providers 'AuthCoreGuardService' et 'AuthService' servent à l'authentification
 */
@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [AuthCoreGuard],
})
export class AppRoutingModule {
  constructor() {}
}
