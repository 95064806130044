<dx-drawer
  [openedStateMode]="'overlap'"
  [position]="'left'"
  [revealMode]="'expand'"
  template="template"
  [(opened)]="_isSubMenuOpen"
  hideOnOutsideClick="true"
  height="90vh"
>
  <div *dxTemplate="let data of 'template'" style="width: 100vh">
    <dx-tree-view
      class="panel-list mobile-nav-treeview"
      [dataSource]="itemNavList"
      selectionMode="single"
      [selectByClick]="true"
      [displayExpr]="'title'"
      [keyExpr]="'id'"
      [parentIdExpr]="'parentId'"
      [itemsExpr]="'children'"
      [selectedExpr]="'isSelected'"
      (onItemSelectionChanged)="menuSelectionChanged($event)"
      (onItemClick)="onItemClick($event)"
    ></dx-tree-view>
  </div>
</dx-drawer>
