import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTermsContractsBaseVariables, FirstTermsContractsBaseVariables, CountTermsContractsBaseVariables, FindTermsContractsBaseVariables, SearchTermsContractsBaseVariables, ExportSchemaTermsContractsBaseVariables, ExportDataTermsContractsBaseVariables, CustomQueryTermsContractsBaseVariables, CustomQueryIdTermsContractsBaseVariables, UsedTermsContractsBaseVariables, FindCustomTermsContractsBaseVariables, ExistTermsContractsBaseVariables, InsertTermsContractsBaseVariables, UpdateTermsContractsBaseVariables, ImportDataTermsContractsBaseVariables, UpdateManyTermsContractsBaseVariables, DeleteTermsContractsBaseVariables, DeleteCustomTermsContractsBaseVariables } from '../gqls'
import { GetTermsContractsDocument, FirstTermsContractsDocument, CountTermsContractsDocument, FindTermsContractsDocument, SearchTermsContractsDocument, ExportSchemaTermsContractsDocument, ExportDataTermsContractsDocument, CustomQueryTermsContractsDocument, CustomQueryIdTermsContractsDocument, UsedTermsContractsDocument, FindCustomTermsContractsDocument, ExistTermsContractsDocument, InsertTermsContractsDocument, UpdateTermsContractsDocument, ImportDataTermsContractsDocument, UpdateManyTermsContractsDocument, DeleteTermsContractsDocument, DeleteCustomTermsContractsDocument } from '../gqls'
import { ServiceSingleResultOfTermsContract, QueryContextOfTermsContract, FilterOfTermsContract, ServiceSingleResultOfInt64, ServiceListResultOfTermsContract, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TermsContractInput, FieldUpdateOperatorOfTermsContract } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TermsContractBaseService {
    
public modelName = 'termsContract';
public modelPluralName = 'termsContracts';

	private getTermsContractsQuery: GetTermsContractsDocument;
	private firstTermsContractsQuery: FirstTermsContractsDocument;
	private countTermsContractsQuery: CountTermsContractsDocument;
	private findTermsContractsQuery: FindTermsContractsDocument;
	private searchTermsContractsQuery: SearchTermsContractsDocument;
	private exportSchemaTermsContractsQuery: ExportSchemaTermsContractsDocument;
	private exportDataTermsContractsQuery: ExportDataTermsContractsDocument;
	private customQueryTermsContractsQuery: CustomQueryTermsContractsDocument;
	private customQueryIdTermsContractsQuery: CustomQueryIdTermsContractsDocument;
	private usedTermsContractsQuery: UsedTermsContractsDocument;
	private findCustomTermsContractsQuery: FindCustomTermsContractsDocument;
	private existTermsContractsQuery: ExistTermsContractsDocument;
	private insertTermsContractsMutation: InsertTermsContractsDocument;
	private updateTermsContractsMutation: UpdateTermsContractsDocument;
	private importDataTermsContractsMutation: ImportDataTermsContractsDocument;
	private updateManyTermsContractsMutation: UpdateManyTermsContractsDocument;
	private deleteTermsContractsMutation: DeleteTermsContractsDocument;
	private deleteCustomTermsContractsMutation: DeleteCustomTermsContractsDocument;

	constructor(private injector: Injector) {
		this.getTermsContractsQuery = this.injector.get(GetTermsContractsDocument);
		this.firstTermsContractsQuery = this.injector.get(FirstTermsContractsDocument);
		this.countTermsContractsQuery = this.injector.get(CountTermsContractsDocument);
		this.findTermsContractsQuery = this.injector.get(FindTermsContractsDocument);
		this.searchTermsContractsQuery = this.injector.get(SearchTermsContractsDocument);
		this.exportSchemaTermsContractsQuery = this.injector.get(ExportSchemaTermsContractsDocument);
		this.exportDataTermsContractsQuery = this.injector.get(ExportDataTermsContractsDocument);
		this.customQueryTermsContractsQuery = this.injector.get(CustomQueryTermsContractsDocument);
		this.customQueryIdTermsContractsQuery = this.injector.get(CustomQueryIdTermsContractsDocument);
		this.usedTermsContractsQuery = this.injector.get(UsedTermsContractsDocument);
		this.findCustomTermsContractsQuery = this.injector.get(FindCustomTermsContractsDocument);
		this.existTermsContractsQuery = this.injector.get(ExistTermsContractsDocument);
		this.insertTermsContractsMutation = this.injector.get(InsertTermsContractsDocument);
		this.updateTermsContractsMutation = this.injector.get(UpdateTermsContractsDocument);
		this.importDataTermsContractsMutation = this.injector.get(ImportDataTermsContractsDocument);
		this.updateManyTermsContractsMutation = this.injector.get(UpdateManyTermsContractsDocument);
		this.deleteTermsContractsMutation = this.injector.get(DeleteTermsContractsDocument);
		this.deleteCustomTermsContractsMutation = this.injector.get(DeleteCustomTermsContractsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTermsContract> {
    
    		let variables: GetTermsContractsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.get));
            }
            else{
                let result:ServiceSingleResultOfTermsContract={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTermsContract> {
    
    		let variables: FirstTermsContractsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTermsContractsBaseVariables = {
    			filter: filter
    		}
    				return this.countTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTermsContract> {
    
    		let variables: FindTermsContractsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTermsContract> {
    
    		let variables: SearchTermsContractsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTermsContractsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTermsContractsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTermsContract> {
    
    		let variables: CustomQueryTermsContractsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTermsContract> {
    
    		let variables: CustomQueryIdTermsContractsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTermsContractsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.used));
    	}

    	/**  */
    	@InjectArgs
    	public findCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('options?') options?: QueryContextOfTermsContract,
		@Args('filter?') filter?: FilterOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTermsContract> {
    
    		let variables: FindCustomTermsContractsBaseVariables = {
    			type: type,
			filter: filter,
			options: options
    		}
    				return this.findCustomTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.findCustom));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTermsContractsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTermsContractsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TermsContractInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTermsContract> {
    
    		let variables: InsertTermsContractsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTermsContract> {
    
    		let variables: UpdateTermsContractsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTermsContractsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTermsContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTermsContractsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTermsContractsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.delete));
    	}

    	/**  */
    	@InjectArgs
    	public deleteCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('type?') type?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCustomTermsContractsBaseVariables = {
    			ids: ids,
			type: type
    		}
    				return this.deleteCustomTermsContractsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.termsContracts.deleteCustom));
    	}
    
}