import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAlarmsBaseVariables, FirstAlarmsBaseVariables, CountAlarmsBaseVariables, FindAlarmsBaseVariables, SearchAlarmsBaseVariables, ExportSchemaAlarmsBaseVariables, ExportDataAlarmsBaseVariables, CustomQueryAlarmsBaseVariables, CustomQueryIdAlarmsBaseVariables, FindUnassociatedSecurityGroupsAlarmsBaseVariables, UsedAlarmsBaseVariables, FindAlarmWithNotViewedAlertAlarmsBaseVariables, FindAlarmWithAlertAlarmsBaseVariables, FindAlarmWithAlertHiddenAlarmsBaseVariables, FindEmailPrototypesUnassociatedAlarmsBaseVariables, ExistAlarmsBaseVariables, FindUnassociatedEmailPrototypesAlarmsBaseVariables, FindUnassociatedEmailPrototypeLogsAlarmsBaseVariables, InsertAlarmsBaseVariables, UpdateAlarmsBaseVariables, ImportDataAlarmsBaseVariables, UpdateManyAlarmsBaseVariables, DeleteAlarmsBaseVariables, AddSecurityGroupAlarmsBaseVariables, RemoveSecurityGroupAlarmsBaseVariables, SetEnableAlarmsBaseVariables, ForceCheckAlarmsAlarmsBaseVariables, ForceSendEmailByAlarmsAlarmsBaseVariables, PurgeAllAlertsAlarmsBaseVariables, AddEmailPrototypesAlarmsBaseVariables, RemoveEmailPrototypesAlarmsBaseVariables, AddEmailPrototypeLogsAlarmsBaseVariables, RemoveEmailPrototypeLogsAlarmsBaseVariables } from '../gqls'
import { GetAlarmsDocument, FirstAlarmsDocument, CountAlarmsDocument, FindAlarmsDocument, SearchAlarmsDocument, ExportSchemaAlarmsDocument, ExportDataAlarmsDocument, CustomQueryAlarmsDocument, CustomQueryIdAlarmsDocument, FindUnassociatedSecurityGroupsAlarmsDocument, UsedAlarmsDocument, FindAlarmWithNotViewedAlertAlarmsDocument, FindAlarmWithAlertAlarmsDocument, FindAlarmWithAlertHiddenAlarmsDocument, FindEmailPrototypesUnassociatedAlarmsDocument, ExistAlarmsDocument, FindUnassociatedEmailPrototypesAlarmsDocument, FindUnassociatedEmailPrototypeLogsAlarmsDocument, InsertAlarmsDocument, UpdateAlarmsDocument, ImportDataAlarmsDocument, UpdateManyAlarmsDocument, DeleteAlarmsDocument, AddSecurityGroupAlarmsDocument, RemoveSecurityGroupAlarmsDocument, SetEnableAlarmsDocument, ForceCheckAlarmsAlarmsDocument, ForceSendEmailByAlarmsAlarmsDocument, PurgeAllAlertsAlarmsDocument, AddEmailPrototypesAlarmsDocument, RemoveEmailPrototypesAlarmsDocument, AddEmailPrototypeLogsAlarmsDocument, RemoveEmailPrototypeLogsAlarmsDocument } from '../gqls'
import { ServiceSingleResultOfAlarm, QueryContextOfAlarm, FilterOfAlarm, ServiceSingleResultOfInt64, ServiceListResultOfAlarm, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceSingleResultOfBoolean, ServiceListResultOfEmailPrototype, QueryContextOfEmailPrototype, FilterOfEmailPrototype, QueryContextOfEmailPrototypeLog, FilterOfEmailPrototypeLog, ServiceListResultOfEmailPrototypeLog, AlarmInput, FieldUpdateOperatorOfAlarm } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AlarmBaseService {
    
public modelName = 'alarm';
public modelPluralName = 'alarms';

	private getAlarmsQuery: GetAlarmsDocument;
	private firstAlarmsQuery: FirstAlarmsDocument;
	private countAlarmsQuery: CountAlarmsDocument;
	private findAlarmsQuery: FindAlarmsDocument;
	private searchAlarmsQuery: SearchAlarmsDocument;
	private exportSchemaAlarmsQuery: ExportSchemaAlarmsDocument;
	private exportDataAlarmsQuery: ExportDataAlarmsDocument;
	private customQueryAlarmsQuery: CustomQueryAlarmsDocument;
	private customQueryIdAlarmsQuery: CustomQueryIdAlarmsDocument;
	private findUnassociatedSecurityGroupsAlarmsQuery: FindUnassociatedSecurityGroupsAlarmsDocument;
	private usedAlarmsQuery: UsedAlarmsDocument;
	private findAlarmWithNotViewedAlertAlarmsQuery: FindAlarmWithNotViewedAlertAlarmsDocument;
	private findAlarmWithAlertAlarmsQuery: FindAlarmWithAlertAlarmsDocument;
	private findAlarmWithAlertHiddenAlarmsQuery: FindAlarmWithAlertHiddenAlarmsDocument;
	private findEmailPrototypesUnassociatedAlarmsQuery: FindEmailPrototypesUnassociatedAlarmsDocument;
	private existAlarmsQuery: ExistAlarmsDocument;
	private findUnassociatedEmailPrototypesAlarmsQuery: FindUnassociatedEmailPrototypesAlarmsDocument;
	private findUnassociatedEmailPrototypeLogsAlarmsQuery: FindUnassociatedEmailPrototypeLogsAlarmsDocument;
	private insertAlarmsMutation: InsertAlarmsDocument;
	private updateAlarmsMutation: UpdateAlarmsDocument;
	private importDataAlarmsMutation: ImportDataAlarmsDocument;
	private updateManyAlarmsMutation: UpdateManyAlarmsDocument;
	private deleteAlarmsMutation: DeleteAlarmsDocument;
	private addSecurityGroupAlarmsMutation: AddSecurityGroupAlarmsDocument;
	private removeSecurityGroupAlarmsMutation: RemoveSecurityGroupAlarmsDocument;
	private setEnableAlarmsMutation: SetEnableAlarmsDocument;
	private forceCheckAlarmsAlarmsMutation: ForceCheckAlarmsAlarmsDocument;
	private forceSendEmailByAlarmsAlarmsMutation: ForceSendEmailByAlarmsAlarmsDocument;
	private purgeAllAlertsAlarmsMutation: PurgeAllAlertsAlarmsDocument;
	private addEmailPrototypesAlarmsMutation: AddEmailPrototypesAlarmsDocument;
	private removeEmailPrototypesAlarmsMutation: RemoveEmailPrototypesAlarmsDocument;
	private addEmailPrototypeLogsAlarmsMutation: AddEmailPrototypeLogsAlarmsDocument;
	private removeEmailPrototypeLogsAlarmsMutation: RemoveEmailPrototypeLogsAlarmsDocument;

	constructor(private injector: Injector) {
		this.getAlarmsQuery = this.injector.get(GetAlarmsDocument);
		this.firstAlarmsQuery = this.injector.get(FirstAlarmsDocument);
		this.countAlarmsQuery = this.injector.get(CountAlarmsDocument);
		this.findAlarmsQuery = this.injector.get(FindAlarmsDocument);
		this.searchAlarmsQuery = this.injector.get(SearchAlarmsDocument);
		this.exportSchemaAlarmsQuery = this.injector.get(ExportSchemaAlarmsDocument);
		this.exportDataAlarmsQuery = this.injector.get(ExportDataAlarmsDocument);
		this.customQueryAlarmsQuery = this.injector.get(CustomQueryAlarmsDocument);
		this.customQueryIdAlarmsQuery = this.injector.get(CustomQueryIdAlarmsDocument);
		this.findUnassociatedSecurityGroupsAlarmsQuery = this.injector.get(FindUnassociatedSecurityGroupsAlarmsDocument);
		this.usedAlarmsQuery = this.injector.get(UsedAlarmsDocument);
		this.findAlarmWithNotViewedAlertAlarmsQuery = this.injector.get(FindAlarmWithNotViewedAlertAlarmsDocument);
		this.findAlarmWithAlertAlarmsQuery = this.injector.get(FindAlarmWithAlertAlarmsDocument);
		this.findAlarmWithAlertHiddenAlarmsQuery = this.injector.get(FindAlarmWithAlertHiddenAlarmsDocument);
		this.findEmailPrototypesUnassociatedAlarmsQuery = this.injector.get(FindEmailPrototypesUnassociatedAlarmsDocument);
		this.existAlarmsQuery = this.injector.get(ExistAlarmsDocument);
		this.findUnassociatedEmailPrototypesAlarmsQuery = this.injector.get(FindUnassociatedEmailPrototypesAlarmsDocument);
		this.findUnassociatedEmailPrototypeLogsAlarmsQuery = this.injector.get(FindUnassociatedEmailPrototypeLogsAlarmsDocument);
		this.insertAlarmsMutation = this.injector.get(InsertAlarmsDocument);
		this.updateAlarmsMutation = this.injector.get(UpdateAlarmsDocument);
		this.importDataAlarmsMutation = this.injector.get(ImportDataAlarmsDocument);
		this.updateManyAlarmsMutation = this.injector.get(UpdateManyAlarmsDocument);
		this.deleteAlarmsMutation = this.injector.get(DeleteAlarmsDocument);
		this.addSecurityGroupAlarmsMutation = this.injector.get(AddSecurityGroupAlarmsDocument);
		this.removeSecurityGroupAlarmsMutation = this.injector.get(RemoveSecurityGroupAlarmsDocument);
		this.setEnableAlarmsMutation = this.injector.get(SetEnableAlarmsDocument);
		this.forceCheckAlarmsAlarmsMutation = this.injector.get(ForceCheckAlarmsAlarmsDocument);
		this.forceSendEmailByAlarmsAlarmsMutation = this.injector.get(ForceSendEmailByAlarmsAlarmsDocument);
		this.purgeAllAlertsAlarmsMutation = this.injector.get(PurgeAllAlertsAlarmsDocument);
		this.addEmailPrototypesAlarmsMutation = this.injector.get(AddEmailPrototypesAlarmsDocument);
		this.removeEmailPrototypesAlarmsMutation = this.injector.get(RemoveEmailPrototypesAlarmsDocument);
		this.addEmailPrototypeLogsAlarmsMutation = this.injector.get(AddEmailPrototypeLogsAlarmsDocument);
		this.removeEmailPrototypeLogsAlarmsMutation = this.injector.get(RemoveEmailPrototypeLogsAlarmsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlarm> {
    
    		let variables: GetAlarmsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.get));
            }
            else{
                let result:ServiceSingleResultOfAlarm={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlarm> {
    
    		let variables: FirstAlarmsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAlarmsBaseVariables = {
    			filter: filter
    		}
    				return this.countAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: FindAlarmsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAlarm,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: SearchAlarmsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAlarmsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAlarmsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: CustomQueryAlarmsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: CustomQueryIdAlarmsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsAlarmsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findUnassociatedSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAlarmsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.used));
    	}

    	/**  */
    	@InjectArgs
    	public findAlarmWithNotViewedAlert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: FindAlarmWithNotViewedAlertAlarmsBaseVariables = {
    			options: options
    		}
    				return this.findAlarmWithNotViewedAlertAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findAlarmWithNotViewedAlert));
    	}

    	/**  */
    	@InjectArgs
    	public findAlarmWithAlert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: FindAlarmWithAlertAlarmsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findAlarmWithAlertAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findAlarmWithAlert));
    	}

    	/**  */
    	@InjectArgs
    	public findAlarmWithAlertHidden(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlarm,
		@Args('filter?') filter?: FilterOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlarm> {
    
    		let variables: FindAlarmWithAlertHiddenAlarmsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findAlarmWithAlertHiddenAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findAlarmWithAlertHidden));
    	}

    	/**  */
    	@InjectArgs
    	public findEmailPrototypesUnassociated(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('typeAlarm?') typeAlarm?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfEmailPrototype,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototype,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototype> {
    
    		let variables: FindEmailPrototypesUnassociatedAlarmsBaseVariables = {
    			id: id,
			typeAlarm: typeAlarm,
			filter: filter,
			options: options
    		}
    				return this.findEmailPrototypesUnassociatedAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findEmailPrototypesUnassociated));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAlarmsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.exist));
    	}

	@InjectArgs
	public findAssociatedEmailPrototypes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfEmailPrototype,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototype,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfEmailPrototype> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('emailPrototypes', fields, null, [
			GqlSubFieldArg.create('filterOfEmailPrototypes', 'filter'),
			GqlSubFieldArg.create('optionsOfEmailPrototypes', 'options'),
		]),
		])
		extendedVariables['filterOfEmailPrototypes'] = filter;
		extendedVariables['optionsOfEmailPrototypes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.emailPrototypes));
            }
            else{
                let result: ServiceListResultOfEmailPrototype = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototype,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototype,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototype> {
    
    		let variables: FindUnassociatedEmailPrototypesAlarmsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedEmailPrototypesAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findUnassociatedEmailPrototypes));
    	}

	@InjectArgs
	public findAssociatedEmailPrototypeLogs(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfEmailPrototypeLog> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('emailPrototypeLogs', fields, null, [
			GqlSubFieldArg.create('filterOfEmailPrototypeLogs', 'filter'),
			GqlSubFieldArg.create('optionsOfEmailPrototypeLogs', 'options'),
		]),
		])
		extendedVariables['filterOfEmailPrototypeLogs'] = filter;
		extendedVariables['optionsOfEmailPrototypeLogs'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.emailPrototypeLogs));
            }
            else{
                let result: ServiceListResultOfEmailPrototypeLog = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedEmailPrototypeLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailPrototypeLog,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailPrototypeLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailPrototypeLog> {
    
    		let variables: FindUnassociatedEmailPrototypeLogsAlarmsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedEmailPrototypeLogsAlarmsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.findUnassociatedEmailPrototypeLogs));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AlarmInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlarm> {
    
    		let variables: InsertAlarmsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAlarm> {
    
    		let variables: UpdateAlarmsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAlarmsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAlarm,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAlarmsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAlarmsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupAlarmsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupAlarmsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.removeSecurityGroup));
    	}

    	/** Permet d'activé ou désactivé l'entité. */
    	@InjectArgs
    	public setEnable(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: boolean,
		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SetEnableAlarmsBaseVariables = {
    			ids: ids,
			state: state
    		}
    				return this.setEnableAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.setEnable));
    	}

    	/**  */
    	@InjectArgs
    	public forceCheckAlarms(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ForceCheckAlarmsAlarmsBaseVariables = {
    			ids: ids
    		}
    				return this.forceCheckAlarmsAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.forceCheckAlarms));
    	}

    	/**  */
    	@InjectArgs
    	public forceSendEmailByAlarms(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ForceSendEmailByAlarmsAlarmsBaseVariables = {
    			ids: ids
    		}
    				return this.forceSendEmailByAlarmsAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.forceSendEmailByAlarms));
    	}

    	/**  */
    	@InjectArgs
    	public purgeAllAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: PurgeAllAlertsAlarmsBaseVariables = {
    
    		}
    				return this.purgeAllAlertsAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.purgeAllAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public addEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailPrototypeIds') emailPrototypeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddEmailPrototypesAlarmsBaseVariables = {
    			id: id,
			emailPrototypeIds: emailPrototypeIds
    		}
    				return this.addEmailPrototypesAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.addEmailPrototypes));
    	}

    	/**  */
    	@InjectArgs
    	public removeEmailPrototypes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailPrototypeIds') emailPrototypeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveEmailPrototypesAlarmsBaseVariables = {
    			id: id,
			emailPrototypeIds: emailPrototypeIds
    		}
    				return this.removeEmailPrototypesAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.removeEmailPrototypes));
    	}

    	/**  */
    	@InjectArgs
    	public addEmailPrototypeLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailPrototypeLogIds') emailPrototypeLogIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddEmailPrototypeLogsAlarmsBaseVariables = {
    			id: id,
			emailPrototypeLogIds: emailPrototypeLogIds
    		}
    				return this.addEmailPrototypeLogsAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.addEmailPrototypeLogs));
    	}

    	/**  */
    	@InjectArgs
    	public removeEmailPrototypeLogs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('emailPrototypeLogIds') emailPrototypeLogIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveEmailPrototypeLogsAlarmsBaseVariables = {
    			emailPrototypeLogIds: emailPrototypeLogIds
    		}
    				return this.removeEmailPrototypeLogsAlarmsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.alarms.removeEmailPrototypeLogs));
    	}
    
}