import { UserCoreService } from '../user.service';

/** Classe de base des type de storage */
export class BaseStorageService {
  _userService: UserCoreService;
  _userId: string;

  constructor(userService: UserCoreService, userId: string) {
    this._userService = userService;
    this._userId = userId;
  }
}
