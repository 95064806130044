import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOrderBillsBaseVariables, FirstOrderBillsBaseVariables, CountOrderBillsBaseVariables, FindOrderBillsBaseVariables, SearchOrderBillsBaseVariables, ExportSchemaOrderBillsBaseVariables, ExportDataOrderBillsBaseVariables, CustomQueryOrderBillsBaseVariables, CustomQueryIdOrderBillsBaseVariables, UsedOrderBillsBaseVariables, ExistOrderBillsBaseVariables, FindRecyclesOrderBillsBaseVariables, CountRecyclesOrderBillsBaseVariables, ReadOnlyOrderBillsBaseVariables, FindArchivedOrderBillsBaseVariables, CountAllOrderBillsBaseVariables, FindDynamicPropertyFieldsOrderBillsBaseVariables, InsertOrderBillsBaseVariables, UpdateOrderBillsBaseVariables, ImportDataOrderBillsBaseVariables, UpdateManyOrderBillsBaseVariables, DeleteOrderBillsBaseVariables, RestoreOrderBillsBaseVariables, RecycleOrderBillsBaseVariables, RestoreArchivedOrderBillsBaseVariables, ArchivedOrderBillsBaseVariables, AddFileDynamicFieldOrderBillsBaseVariables, RemoveFileDynamicFieldOrderBillsBaseVariables } from '../gqls'
import { GetOrderBillsDocument, FirstOrderBillsDocument, CountOrderBillsDocument, FindOrderBillsDocument, SearchOrderBillsDocument, ExportSchemaOrderBillsDocument, ExportDataOrderBillsDocument, CustomQueryOrderBillsDocument, CustomQueryIdOrderBillsDocument, UsedOrderBillsDocument, ExistOrderBillsDocument, FindRecyclesOrderBillsDocument, CountRecyclesOrderBillsDocument, ReadOnlyOrderBillsDocument, FindArchivedOrderBillsDocument, CountAllOrderBillsDocument, FindDynamicPropertyFieldsOrderBillsDocument, InsertOrderBillsDocument, UpdateOrderBillsDocument, ImportDataOrderBillsDocument, UpdateManyOrderBillsDocument, DeleteOrderBillsDocument, RestoreOrderBillsDocument, RecycleOrderBillsDocument, RestoreArchivedOrderBillsDocument, ArchivedOrderBillsDocument, AddFileDynamicFieldOrderBillsDocument, RemoveFileDynamicFieldOrderBillsDocument } from '../gqls'
import { ServiceSingleResultOfOrderBill, QueryContextOfOrderBill, FilterOfOrderBill, ServiceSingleResultOfInt64, ServiceListResultOfOrderBill, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrderBill, OrderBillInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OrderBillBaseService {
    
public modelName = 'orderBill';
public modelPluralName = 'orderBills';

	private getOrderBillsQuery: GetOrderBillsDocument;
	private firstOrderBillsQuery: FirstOrderBillsDocument;
	private countOrderBillsQuery: CountOrderBillsDocument;
	private findOrderBillsQuery: FindOrderBillsDocument;
	private searchOrderBillsQuery: SearchOrderBillsDocument;
	private exportSchemaOrderBillsQuery: ExportSchemaOrderBillsDocument;
	private exportDataOrderBillsQuery: ExportDataOrderBillsDocument;
	private customQueryOrderBillsQuery: CustomQueryOrderBillsDocument;
	private customQueryIdOrderBillsQuery: CustomQueryIdOrderBillsDocument;
	private usedOrderBillsQuery: UsedOrderBillsDocument;
	private existOrderBillsQuery: ExistOrderBillsDocument;
	private findRecyclesOrderBillsQuery: FindRecyclesOrderBillsDocument;
	private countRecyclesOrderBillsQuery: CountRecyclesOrderBillsDocument;
	private readOnlyOrderBillsQuery: ReadOnlyOrderBillsDocument;
	private findArchivedOrderBillsQuery: FindArchivedOrderBillsDocument;
	private countAllOrderBillsQuery: CountAllOrderBillsDocument;
	private findDynamicPropertyFieldsOrderBillsQuery: FindDynamicPropertyFieldsOrderBillsDocument;
	private insertOrderBillsMutation: InsertOrderBillsDocument;
	private updateOrderBillsMutation: UpdateOrderBillsDocument;
	private importDataOrderBillsMutation: ImportDataOrderBillsDocument;
	private updateManyOrderBillsMutation: UpdateManyOrderBillsDocument;
	private deleteOrderBillsMutation: DeleteOrderBillsDocument;
	private restoreOrderBillsMutation: RestoreOrderBillsDocument;
	private recycleOrderBillsMutation: RecycleOrderBillsDocument;
	private restoreArchivedOrderBillsMutation: RestoreArchivedOrderBillsDocument;
	private archivedOrderBillsMutation: ArchivedOrderBillsDocument;
	private addFileDynamicFieldOrderBillsMutation: AddFileDynamicFieldOrderBillsDocument;
	private removeFileDynamicFieldOrderBillsMutation: RemoveFileDynamicFieldOrderBillsDocument;

	constructor(private injector: Injector) {
		this.getOrderBillsQuery = this.injector.get(GetOrderBillsDocument);
		this.firstOrderBillsQuery = this.injector.get(FirstOrderBillsDocument);
		this.countOrderBillsQuery = this.injector.get(CountOrderBillsDocument);
		this.findOrderBillsQuery = this.injector.get(FindOrderBillsDocument);
		this.searchOrderBillsQuery = this.injector.get(SearchOrderBillsDocument);
		this.exportSchemaOrderBillsQuery = this.injector.get(ExportSchemaOrderBillsDocument);
		this.exportDataOrderBillsQuery = this.injector.get(ExportDataOrderBillsDocument);
		this.customQueryOrderBillsQuery = this.injector.get(CustomQueryOrderBillsDocument);
		this.customQueryIdOrderBillsQuery = this.injector.get(CustomQueryIdOrderBillsDocument);
		this.usedOrderBillsQuery = this.injector.get(UsedOrderBillsDocument);
		this.existOrderBillsQuery = this.injector.get(ExistOrderBillsDocument);
		this.findRecyclesOrderBillsQuery = this.injector.get(FindRecyclesOrderBillsDocument);
		this.countRecyclesOrderBillsQuery = this.injector.get(CountRecyclesOrderBillsDocument);
		this.readOnlyOrderBillsQuery = this.injector.get(ReadOnlyOrderBillsDocument);
		this.findArchivedOrderBillsQuery = this.injector.get(FindArchivedOrderBillsDocument);
		this.countAllOrderBillsQuery = this.injector.get(CountAllOrderBillsDocument);
		this.findDynamicPropertyFieldsOrderBillsQuery = this.injector.get(FindDynamicPropertyFieldsOrderBillsDocument);
		this.insertOrderBillsMutation = this.injector.get(InsertOrderBillsDocument);
		this.updateOrderBillsMutation = this.injector.get(UpdateOrderBillsDocument);
		this.importDataOrderBillsMutation = this.injector.get(ImportDataOrderBillsDocument);
		this.updateManyOrderBillsMutation = this.injector.get(UpdateManyOrderBillsDocument);
		this.deleteOrderBillsMutation = this.injector.get(DeleteOrderBillsDocument);
		this.restoreOrderBillsMutation = this.injector.get(RestoreOrderBillsDocument);
		this.recycleOrderBillsMutation = this.injector.get(RecycleOrderBillsDocument);
		this.restoreArchivedOrderBillsMutation = this.injector.get(RestoreArchivedOrderBillsDocument);
		this.archivedOrderBillsMutation = this.injector.get(ArchivedOrderBillsDocument);
		this.addFileDynamicFieldOrderBillsMutation = this.injector.get(AddFileDynamicFieldOrderBillsDocument);
		this.removeFileDynamicFieldOrderBillsMutation = this.injector.get(RemoveFileDynamicFieldOrderBillsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrderBill> {
    
    		let variables: GetOrderBillsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.get));
            }
            else{
                let result:ServiceSingleResultOfOrderBill={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrderBill> {
    
    		let variables: FirstOrderBillsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOrderBillsBaseVariables = {
    			filter: filter
    		}
    				return this.countOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: FindOrderBillsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: SearchOrderBillsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOrderBillsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOrderBillsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: CustomQueryOrderBillsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: CustomQueryIdOrderBillsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.usedOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOrderBillsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: FindRecyclesOrderBillsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesOrderBillsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyOrderBillsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrderBill,
		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrderBill> {
    
    		let variables: FindArchivedOrderBillsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllOrderBillsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsOrderBillsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsOrderBillsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OrderBillInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrderBill> {
    
    		let variables: InsertOrderBillsBaseVariables = {
    			entity: entity
    		}
    				return this.insertOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrderBill> {
    
    		let variables: UpdateOrderBillsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOrderBillsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrderBill,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOrderBillsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedOrderBillsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldOrderBillsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldOrderBillsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldOrderBillsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.orderBills.removeFileDynamicField));
    	}
    
}