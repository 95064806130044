import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInheritLocationChartersBaseVariables, FirstInheritLocationChartersBaseVariables, CountInheritLocationChartersBaseVariables, FindInheritLocationChartersBaseVariables, SearchInheritLocationChartersBaseVariables, ExportSchemaInheritLocationChartersBaseVariables, ExportDataInheritLocationChartersBaseVariables, CustomQueryInheritLocationChartersBaseVariables, CustomQueryIdInheritLocationChartersBaseVariables, UsedInheritLocationChartersBaseVariables, ExistInheritLocationChartersBaseVariables, FindUnassociatedExcludeUsersInheritLocationChartersBaseVariables, FindUnassociatedExcludeAssetsInheritLocationChartersBaseVariables, InsertInheritLocationChartersBaseVariables, UpdateInheritLocationChartersBaseVariables, ImportDataInheritLocationChartersBaseVariables, UpdateManyInheritLocationChartersBaseVariables, DeleteInheritLocationChartersBaseVariables, OrderInheritLocationChartersBaseVariables, AddExcludeAssetsInheritLocationChartersBaseVariables, RemoveExcludeAssetsInheritLocationChartersBaseVariables, AddExcludeUsersInheritLocationChartersBaseVariables, RemoveExcludeUsersInheritLocationChartersBaseVariables } from '../gqls'
import { GetInheritLocationChartersDocument, FirstInheritLocationChartersDocument, CountInheritLocationChartersDocument, FindInheritLocationChartersDocument, SearchInheritLocationChartersDocument, ExportSchemaInheritLocationChartersDocument, ExportDataInheritLocationChartersDocument, CustomQueryInheritLocationChartersDocument, CustomQueryIdInheritLocationChartersDocument, UsedInheritLocationChartersDocument, ExistInheritLocationChartersDocument, FindUnassociatedExcludeUsersInheritLocationChartersDocument, FindUnassociatedExcludeAssetsInheritLocationChartersDocument, InsertInheritLocationChartersDocument, UpdateInheritLocationChartersDocument, ImportDataInheritLocationChartersDocument, UpdateManyInheritLocationChartersDocument, DeleteInheritLocationChartersDocument, OrderInheritLocationChartersDocument, AddExcludeAssetsInheritLocationChartersDocument, RemoveExcludeAssetsInheritLocationChartersDocument, AddExcludeUsersInheritLocationChartersDocument, RemoveExcludeUsersInheritLocationChartersDocument } from '../gqls'
import { ServiceSingleResultOfInheritLocationCharter, QueryContextOfInheritLocationCharter, FilterOfInheritLocationCharter, ServiceSingleResultOfInt64, ServiceListResultOfInheritLocationCharter, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfUser, FilterOfUser, ServiceListResultOfUser, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, InheritLocationCharterInput, FieldUpdateOperatorOfInheritLocationCharter } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InheritLocationCharterBaseService {
    
public modelName = 'inheritLocationCharter';
public modelPluralName = 'inheritLocationCharters';

	private getInheritLocationChartersQuery: GetInheritLocationChartersDocument;
	private firstInheritLocationChartersQuery: FirstInheritLocationChartersDocument;
	private countInheritLocationChartersQuery: CountInheritLocationChartersDocument;
	private findInheritLocationChartersQuery: FindInheritLocationChartersDocument;
	private searchInheritLocationChartersQuery: SearchInheritLocationChartersDocument;
	private exportSchemaInheritLocationChartersQuery: ExportSchemaInheritLocationChartersDocument;
	private exportDataInheritLocationChartersQuery: ExportDataInheritLocationChartersDocument;
	private customQueryInheritLocationChartersQuery: CustomQueryInheritLocationChartersDocument;
	private customQueryIdInheritLocationChartersQuery: CustomQueryIdInheritLocationChartersDocument;
	private usedInheritLocationChartersQuery: UsedInheritLocationChartersDocument;
	private existInheritLocationChartersQuery: ExistInheritLocationChartersDocument;
	private findUnassociatedExcludeUsersInheritLocationChartersQuery: FindUnassociatedExcludeUsersInheritLocationChartersDocument;
	private findUnassociatedExcludeAssetsInheritLocationChartersQuery: FindUnassociatedExcludeAssetsInheritLocationChartersDocument;
	private insertInheritLocationChartersMutation: InsertInheritLocationChartersDocument;
	private updateInheritLocationChartersMutation: UpdateInheritLocationChartersDocument;
	private importDataInheritLocationChartersMutation: ImportDataInheritLocationChartersDocument;
	private updateManyInheritLocationChartersMutation: UpdateManyInheritLocationChartersDocument;
	private deleteInheritLocationChartersMutation: DeleteInheritLocationChartersDocument;
	private orderInheritLocationChartersMutation: OrderInheritLocationChartersDocument;
	private addExcludeAssetsInheritLocationChartersMutation: AddExcludeAssetsInheritLocationChartersDocument;
	private removeExcludeAssetsInheritLocationChartersMutation: RemoveExcludeAssetsInheritLocationChartersDocument;
	private addExcludeUsersInheritLocationChartersMutation: AddExcludeUsersInheritLocationChartersDocument;
	private removeExcludeUsersInheritLocationChartersMutation: RemoveExcludeUsersInheritLocationChartersDocument;

	constructor(private injector: Injector) {
		this.getInheritLocationChartersQuery = this.injector.get(GetInheritLocationChartersDocument);
		this.firstInheritLocationChartersQuery = this.injector.get(FirstInheritLocationChartersDocument);
		this.countInheritLocationChartersQuery = this.injector.get(CountInheritLocationChartersDocument);
		this.findInheritLocationChartersQuery = this.injector.get(FindInheritLocationChartersDocument);
		this.searchInheritLocationChartersQuery = this.injector.get(SearchInheritLocationChartersDocument);
		this.exportSchemaInheritLocationChartersQuery = this.injector.get(ExportSchemaInheritLocationChartersDocument);
		this.exportDataInheritLocationChartersQuery = this.injector.get(ExportDataInheritLocationChartersDocument);
		this.customQueryInheritLocationChartersQuery = this.injector.get(CustomQueryInheritLocationChartersDocument);
		this.customQueryIdInheritLocationChartersQuery = this.injector.get(CustomQueryIdInheritLocationChartersDocument);
		this.usedInheritLocationChartersQuery = this.injector.get(UsedInheritLocationChartersDocument);
		this.existInheritLocationChartersQuery = this.injector.get(ExistInheritLocationChartersDocument);
		this.findUnassociatedExcludeUsersInheritLocationChartersQuery = this.injector.get(FindUnassociatedExcludeUsersInheritLocationChartersDocument);
		this.findUnassociatedExcludeAssetsInheritLocationChartersQuery = this.injector.get(FindUnassociatedExcludeAssetsInheritLocationChartersDocument);
		this.insertInheritLocationChartersMutation = this.injector.get(InsertInheritLocationChartersDocument);
		this.updateInheritLocationChartersMutation = this.injector.get(UpdateInheritLocationChartersDocument);
		this.importDataInheritLocationChartersMutation = this.injector.get(ImportDataInheritLocationChartersDocument);
		this.updateManyInheritLocationChartersMutation = this.injector.get(UpdateManyInheritLocationChartersDocument);
		this.deleteInheritLocationChartersMutation = this.injector.get(DeleteInheritLocationChartersDocument);
		this.orderInheritLocationChartersMutation = this.injector.get(OrderInheritLocationChartersDocument);
		this.addExcludeAssetsInheritLocationChartersMutation = this.injector.get(AddExcludeAssetsInheritLocationChartersDocument);
		this.removeExcludeAssetsInheritLocationChartersMutation = this.injector.get(RemoveExcludeAssetsInheritLocationChartersDocument);
		this.addExcludeUsersInheritLocationChartersMutation = this.injector.get(AddExcludeUsersInheritLocationChartersDocument);
		this.removeExcludeUsersInheritLocationChartersMutation = this.injector.get(RemoveExcludeUsersInheritLocationChartersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInheritLocationCharter> {
    
    		let variables: GetInheritLocationChartersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.get));
            }
            else{
                let result:ServiceSingleResultOfInheritLocationCharter={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInheritLocationCharter,
		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInheritLocationCharter> {
    
    		let variables: FirstInheritLocationChartersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInheritLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.countInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInheritLocationCharter,
		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInheritLocationCharter> {
    
    		let variables: FindInheritLocationChartersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInheritLocationCharter,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInheritLocationCharter> {
    
    		let variables: SearchInheritLocationChartersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInheritLocationChartersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInheritLocationChartersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInheritLocationCharter,
		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInheritLocationCharter> {
    
    		let variables: CustomQueryInheritLocationChartersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInheritLocationCharter,
		@Args('filter?') filter?: FilterOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInheritLocationCharter> {
    
    		let variables: CustomQueryIdInheritLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInheritLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.usedInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInheritLocationChartersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.exist));
    	}

	@InjectArgs
	public findAssociatedExcludeUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('excludeUsers', fields, null, [
			GqlSubFieldArg.create('filterOfExcludeUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfExcludeUsers', 'options'),
		]),
		])
		extendedVariables['filterOfExcludeUsers'] = filter;
		extendedVariables['optionsOfExcludeUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.excludeUsers));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedExcludeUsersInheritLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedExcludeUsersInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.findUnassociatedExcludeUsers));
    	}

	@InjectArgs
	public findAssociatedExcludeAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('excludeAssets', fields, null, [
			GqlSubFieldArg.create('filterOfExcludeAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfExcludeAssets', 'options'),
		]),
		])
		extendedVariables['filterOfExcludeAssets'] = filter;
		extendedVariables['optionsOfExcludeAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.excludeAssets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedExcludeAssetsInheritLocationChartersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedExcludeAssetsInheritLocationChartersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.findUnassociatedExcludeAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: InheritLocationCharterInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInheritLocationCharter> {
    
    		let variables: InsertInheritLocationChartersBaseVariables = {
    			entity: entity
    		}
    				return this.insertInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInheritLocationCharter> {
    
    		let variables: UpdateInheritLocationChartersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataInheritLocationChartersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfInheritLocationCharter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyInheritLocationChartersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteInheritLocationChartersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderInheritLocationChartersBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.order));
    	}

    	/**  */
    	@InjectArgs
    	public addExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddExcludeAssetsInheritLocationChartersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addExcludeAssetsInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.addExcludeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeExcludeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveExcludeAssetsInheritLocationChartersBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeExcludeAssetsInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.removeExcludeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddExcludeUsersInheritLocationChartersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addExcludeUsersInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.addExcludeUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeExcludeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveExcludeUsersInheritLocationChartersBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeExcludeUsersInheritLocationChartersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.inheritLocationCharters.removeExcludeUsers));
    	}
    
}