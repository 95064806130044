import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLifeCycleTicketsBaseVariables, FirstLifeCycleTicketsBaseVariables, CountLifeCycleTicketsBaseVariables, FindLifeCycleTicketsBaseVariables, SearchLifeCycleTicketsBaseVariables, ExportSchemaLifeCycleTicketsBaseVariables, ExportDataLifeCycleTicketsBaseVariables, CustomQueryLifeCycleTicketsBaseVariables, CustomQueryIdLifeCycleTicketsBaseVariables, UsedLifeCycleTicketsBaseVariables, ExistLifeCycleTicketsBaseVariables, InsertLifeCycleTicketsBaseVariables, UpdateLifeCycleTicketsBaseVariables, ImportDataLifeCycleTicketsBaseVariables, UpdateManyLifeCycleTicketsBaseVariables, DeleteLifeCycleTicketsBaseVariables } from '../gqls'
import { GetLifeCycleTicketsDocument, FirstLifeCycleTicketsDocument, CountLifeCycleTicketsDocument, FindLifeCycleTicketsDocument, SearchLifeCycleTicketsDocument, ExportSchemaLifeCycleTicketsDocument, ExportDataLifeCycleTicketsDocument, CustomQueryLifeCycleTicketsDocument, CustomQueryIdLifeCycleTicketsDocument, UsedLifeCycleTicketsDocument, ExistLifeCycleTicketsDocument, InsertLifeCycleTicketsDocument, UpdateLifeCycleTicketsDocument, ImportDataLifeCycleTicketsDocument, UpdateManyLifeCycleTicketsDocument, DeleteLifeCycleTicketsDocument } from '../gqls'
import { ServiceSingleResultOfLifeCycleTicket, QueryContextOfLifeCycleTicket, FilterOfLifeCycleTicket, ServiceSingleResultOfInt64, ServiceListResultOfLifeCycleTicket, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LifeCycleTicketInput, FieldUpdateOperatorOfLifeCycleTicket } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LifeCycleTicketBaseService {
    
public modelName = 'lifeCycleTicket';
public modelPluralName = 'lifeCycleTickets';

	private getLifeCycleTicketsQuery: GetLifeCycleTicketsDocument;
	private firstLifeCycleTicketsQuery: FirstLifeCycleTicketsDocument;
	private countLifeCycleTicketsQuery: CountLifeCycleTicketsDocument;
	private findLifeCycleTicketsQuery: FindLifeCycleTicketsDocument;
	private searchLifeCycleTicketsQuery: SearchLifeCycleTicketsDocument;
	private exportSchemaLifeCycleTicketsQuery: ExportSchemaLifeCycleTicketsDocument;
	private exportDataLifeCycleTicketsQuery: ExportDataLifeCycleTicketsDocument;
	private customQueryLifeCycleTicketsQuery: CustomQueryLifeCycleTicketsDocument;
	private customQueryIdLifeCycleTicketsQuery: CustomQueryIdLifeCycleTicketsDocument;
	private usedLifeCycleTicketsQuery: UsedLifeCycleTicketsDocument;
	private existLifeCycleTicketsQuery: ExistLifeCycleTicketsDocument;
	private insertLifeCycleTicketsMutation: InsertLifeCycleTicketsDocument;
	private updateLifeCycleTicketsMutation: UpdateLifeCycleTicketsDocument;
	private importDataLifeCycleTicketsMutation: ImportDataLifeCycleTicketsDocument;
	private updateManyLifeCycleTicketsMutation: UpdateManyLifeCycleTicketsDocument;
	private deleteLifeCycleTicketsMutation: DeleteLifeCycleTicketsDocument;

	constructor(private injector: Injector) {
		this.getLifeCycleTicketsQuery = this.injector.get(GetLifeCycleTicketsDocument);
		this.firstLifeCycleTicketsQuery = this.injector.get(FirstLifeCycleTicketsDocument);
		this.countLifeCycleTicketsQuery = this.injector.get(CountLifeCycleTicketsDocument);
		this.findLifeCycleTicketsQuery = this.injector.get(FindLifeCycleTicketsDocument);
		this.searchLifeCycleTicketsQuery = this.injector.get(SearchLifeCycleTicketsDocument);
		this.exportSchemaLifeCycleTicketsQuery = this.injector.get(ExportSchemaLifeCycleTicketsDocument);
		this.exportDataLifeCycleTicketsQuery = this.injector.get(ExportDataLifeCycleTicketsDocument);
		this.customQueryLifeCycleTicketsQuery = this.injector.get(CustomQueryLifeCycleTicketsDocument);
		this.customQueryIdLifeCycleTicketsQuery = this.injector.get(CustomQueryIdLifeCycleTicketsDocument);
		this.usedLifeCycleTicketsQuery = this.injector.get(UsedLifeCycleTicketsDocument);
		this.existLifeCycleTicketsQuery = this.injector.get(ExistLifeCycleTicketsDocument);
		this.insertLifeCycleTicketsMutation = this.injector.get(InsertLifeCycleTicketsDocument);
		this.updateLifeCycleTicketsMutation = this.injector.get(UpdateLifeCycleTicketsDocument);
		this.importDataLifeCycleTicketsMutation = this.injector.get(ImportDataLifeCycleTicketsDocument);
		this.updateManyLifeCycleTicketsMutation = this.injector.get(UpdateManyLifeCycleTicketsDocument);
		this.deleteLifeCycleTicketsMutation = this.injector.get(DeleteLifeCycleTicketsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleTicket> {
    
    		let variables: GetLifeCycleTicketsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.get));
            }
            else{
                let result:ServiceSingleResultOfLifeCycleTicket={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLifeCycleTicket,
		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleTicket> {
    
    		let variables: FirstLifeCycleTicketsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLifeCycleTicketsBaseVariables = {
    			filter: filter
    		}
    				return this.countLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLifeCycleTicket,
		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleTicket> {
    
    		let variables: FindLifeCycleTicketsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLifeCycleTicket,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleTicket> {
    
    		let variables: SearchLifeCycleTicketsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLifeCycleTicketsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLifeCycleTicketsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLifeCycleTicket,
		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleTicket> {
    
    		let variables: CustomQueryLifeCycleTicketsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLifeCycleTicket,
		@Args('filter?') filter?: FilterOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLifeCycleTicket> {
    
    		let variables: CustomQueryIdLifeCycleTicketsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLifeCycleTicketsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLifeCycleTicketsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLifeCycleTicketsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LifeCycleTicketInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleTicket> {
    
    		let variables: InsertLifeCycleTicketsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLifeCycleTicketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLifeCycleTicket> {
    
    		let variables: UpdateLifeCycleTicketsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLifeCycleTicketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLifeCycleTicketsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLifeCycleTicketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLifeCycleTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLifeCycleTicketsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLifeCycleTicketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLifeCycleTicketsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLifeCycleTicketsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.lifeCycleTickets.delete));
    	}
    
}