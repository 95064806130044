import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUserRolesBaseVariables, FirstUserRolesBaseVariables, CountUserRolesBaseVariables, FindUserRolesBaseVariables, SearchUserRolesBaseVariables, ExportSchemaUserRolesBaseVariables, ExportDataUserRolesBaseVariables, CustomQueryUserRolesBaseVariables, CustomQueryIdUserRolesBaseVariables, UsedUserRolesBaseVariables, ExistUserRolesBaseVariables, FindRecyclesUserRolesBaseVariables, CountRecyclesUserRolesBaseVariables, ReadOnlyUserRolesBaseVariables, FindArchivedUserRolesBaseVariables, CountAllUserRolesBaseVariables, FindDynamicPropertyFieldsUserRolesBaseVariables, InsertUserRolesBaseVariables, UpdateUserRolesBaseVariables, ImportDataUserRolesBaseVariables, UpdateManyUserRolesBaseVariables, DeleteUserRolesBaseVariables, RestoreUserRolesBaseVariables, RecycleUserRolesBaseVariables, RestoreArchivedUserRolesBaseVariables, ArchivedUserRolesBaseVariables, AddFileDynamicFieldUserRolesBaseVariables, RemoveFileDynamicFieldUserRolesBaseVariables } from '../gqls'
import { GetUserRolesDocument, FirstUserRolesDocument, CountUserRolesDocument, FindUserRolesDocument, SearchUserRolesDocument, ExportSchemaUserRolesDocument, ExportDataUserRolesDocument, CustomQueryUserRolesDocument, CustomQueryIdUserRolesDocument, UsedUserRolesDocument, ExistUserRolesDocument, FindRecyclesUserRolesDocument, CountRecyclesUserRolesDocument, ReadOnlyUserRolesDocument, FindArchivedUserRolesDocument, CountAllUserRolesDocument, FindDynamicPropertyFieldsUserRolesDocument, InsertUserRolesDocument, UpdateUserRolesDocument, ImportDataUserRolesDocument, UpdateManyUserRolesDocument, DeleteUserRolesDocument, RestoreUserRolesDocument, RecycleUserRolesDocument, RestoreArchivedUserRolesDocument, ArchivedUserRolesDocument, AddFileDynamicFieldUserRolesDocument, RemoveFileDynamicFieldUserRolesDocument } from '../gqls'
import { ServiceSingleResultOfUserRole, QueryContextOfUserRole, FilterOfUserRole, ServiceSingleResultOfInt64, ServiceListResultOfUserRole, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfUserRole, UserRoleInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UserRoleBaseService {
    
public modelName = 'userRole';
public modelPluralName = 'userRoles';

	private getUserRolesQuery: GetUserRolesDocument;
	private firstUserRolesQuery: FirstUserRolesDocument;
	private countUserRolesQuery: CountUserRolesDocument;
	private findUserRolesQuery: FindUserRolesDocument;
	private searchUserRolesQuery: SearchUserRolesDocument;
	private exportSchemaUserRolesQuery: ExportSchemaUserRolesDocument;
	private exportDataUserRolesQuery: ExportDataUserRolesDocument;
	private customQueryUserRolesQuery: CustomQueryUserRolesDocument;
	private customQueryIdUserRolesQuery: CustomQueryIdUserRolesDocument;
	private usedUserRolesQuery: UsedUserRolesDocument;
	private existUserRolesQuery: ExistUserRolesDocument;
	private findRecyclesUserRolesQuery: FindRecyclesUserRolesDocument;
	private countRecyclesUserRolesQuery: CountRecyclesUserRolesDocument;
	private readOnlyUserRolesQuery: ReadOnlyUserRolesDocument;
	private findArchivedUserRolesQuery: FindArchivedUserRolesDocument;
	private countAllUserRolesQuery: CountAllUserRolesDocument;
	private findDynamicPropertyFieldsUserRolesQuery: FindDynamicPropertyFieldsUserRolesDocument;
	private insertUserRolesMutation: InsertUserRolesDocument;
	private updateUserRolesMutation: UpdateUserRolesDocument;
	private importDataUserRolesMutation: ImportDataUserRolesDocument;
	private updateManyUserRolesMutation: UpdateManyUserRolesDocument;
	private deleteUserRolesMutation: DeleteUserRolesDocument;
	private restoreUserRolesMutation: RestoreUserRolesDocument;
	private recycleUserRolesMutation: RecycleUserRolesDocument;
	private restoreArchivedUserRolesMutation: RestoreArchivedUserRolesDocument;
	private archivedUserRolesMutation: ArchivedUserRolesDocument;
	private addFileDynamicFieldUserRolesMutation: AddFileDynamicFieldUserRolesDocument;
	private removeFileDynamicFieldUserRolesMutation: RemoveFileDynamicFieldUserRolesDocument;

	constructor(private injector: Injector) {
		this.getUserRolesQuery = this.injector.get(GetUserRolesDocument);
		this.firstUserRolesQuery = this.injector.get(FirstUserRolesDocument);
		this.countUserRolesQuery = this.injector.get(CountUserRolesDocument);
		this.findUserRolesQuery = this.injector.get(FindUserRolesDocument);
		this.searchUserRolesQuery = this.injector.get(SearchUserRolesDocument);
		this.exportSchemaUserRolesQuery = this.injector.get(ExportSchemaUserRolesDocument);
		this.exportDataUserRolesQuery = this.injector.get(ExportDataUserRolesDocument);
		this.customQueryUserRolesQuery = this.injector.get(CustomQueryUserRolesDocument);
		this.customQueryIdUserRolesQuery = this.injector.get(CustomQueryIdUserRolesDocument);
		this.usedUserRolesQuery = this.injector.get(UsedUserRolesDocument);
		this.existUserRolesQuery = this.injector.get(ExistUserRolesDocument);
		this.findRecyclesUserRolesQuery = this.injector.get(FindRecyclesUserRolesDocument);
		this.countRecyclesUserRolesQuery = this.injector.get(CountRecyclesUserRolesDocument);
		this.readOnlyUserRolesQuery = this.injector.get(ReadOnlyUserRolesDocument);
		this.findArchivedUserRolesQuery = this.injector.get(FindArchivedUserRolesDocument);
		this.countAllUserRolesQuery = this.injector.get(CountAllUserRolesDocument);
		this.findDynamicPropertyFieldsUserRolesQuery = this.injector.get(FindDynamicPropertyFieldsUserRolesDocument);
		this.insertUserRolesMutation = this.injector.get(InsertUserRolesDocument);
		this.updateUserRolesMutation = this.injector.get(UpdateUserRolesDocument);
		this.importDataUserRolesMutation = this.injector.get(ImportDataUserRolesDocument);
		this.updateManyUserRolesMutation = this.injector.get(UpdateManyUserRolesDocument);
		this.deleteUserRolesMutation = this.injector.get(DeleteUserRolesDocument);
		this.restoreUserRolesMutation = this.injector.get(RestoreUserRolesDocument);
		this.recycleUserRolesMutation = this.injector.get(RecycleUserRolesDocument);
		this.restoreArchivedUserRolesMutation = this.injector.get(RestoreArchivedUserRolesDocument);
		this.archivedUserRolesMutation = this.injector.get(ArchivedUserRolesDocument);
		this.addFileDynamicFieldUserRolesMutation = this.injector.get(AddFileDynamicFieldUserRolesDocument);
		this.removeFileDynamicFieldUserRolesMutation = this.injector.get(RemoveFileDynamicFieldUserRolesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserRole> {
    
    		let variables: GetUserRolesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.get));
            }
            else{
                let result:ServiceSingleResultOfUserRole={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserRole> {
    
    		let variables: FirstUserRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUserRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: FindUserRolesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUserRole,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: SearchUserRolesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUserRolesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUserRolesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: CustomQueryUserRolesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: CustomQueryIdUserRolesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.usedUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUserRolesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: FindRecyclesUserRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesUserRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyUserRolesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserRole,
		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserRole> {
    
    		let variables: FindArchivedUserRolesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllUserRolesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsUserRolesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsUserRolesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: UserRoleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserRole> {
    
    		let variables: InsertUserRolesBaseVariables = {
    			entity: entity
    		}
    				return this.insertUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserRole> {
    
    		let variables: UpdateUserRolesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataUserRolesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfUserRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyUserRolesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedUserRolesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldUserRolesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldUserRolesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldUserRolesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.userRoles.removeFileDynamicField));
    	}
    
}