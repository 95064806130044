import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkTimesBaseVariables, FirstWorkTimesBaseVariables, CountWorkTimesBaseVariables, FindWorkTimesBaseVariables, SearchWorkTimesBaseVariables, ExportSchemaWorkTimesBaseVariables, ExportDataWorkTimesBaseVariables, CustomQueryWorkTimesBaseVariables, CustomQueryIdWorkTimesBaseVariables, UsedWorkTimesBaseVariables, ExistWorkTimesBaseVariables, FindUnassociatedServiceLevelAgreementsWorkTimesBaseVariables, InsertWorkTimesBaseVariables, UpdateWorkTimesBaseVariables, ImportDataWorkTimesBaseVariables, UpdateManyWorkTimesBaseVariables, DeleteWorkTimesBaseVariables, AddServiceLevelAgreementsWorkTimesBaseVariables, RemoveServiceLevelAgreementsWorkTimesBaseVariables } from '../gqls'
import { GetWorkTimesDocument, FirstWorkTimesDocument, CountWorkTimesDocument, FindWorkTimesDocument, SearchWorkTimesDocument, ExportSchemaWorkTimesDocument, ExportDataWorkTimesDocument, CustomQueryWorkTimesDocument, CustomQueryIdWorkTimesDocument, UsedWorkTimesDocument, ExistWorkTimesDocument, FindUnassociatedServiceLevelAgreementsWorkTimesDocument, InsertWorkTimesDocument, UpdateWorkTimesDocument, ImportDataWorkTimesDocument, UpdateManyWorkTimesDocument, DeleteWorkTimesDocument, AddServiceLevelAgreementsWorkTimesDocument, RemoveServiceLevelAgreementsWorkTimesDocument } from '../gqls'
import { ServiceSingleResultOfWorkTime, QueryContextOfWorkTime, FilterOfWorkTime, ServiceSingleResultOfInt64, ServiceListResultOfWorkTime, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfServiceLevelAgreement, FilterOfServiceLevelAgreement, ServiceListResultOfServiceLevelAgreement, WorkTimeInput, FieldUpdateOperatorOfWorkTime } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkTimeBaseService {
    
public modelName = 'workTime';
public modelPluralName = 'workTimes';

	private getWorkTimesQuery: GetWorkTimesDocument;
	private firstWorkTimesQuery: FirstWorkTimesDocument;
	private countWorkTimesQuery: CountWorkTimesDocument;
	private findWorkTimesQuery: FindWorkTimesDocument;
	private searchWorkTimesQuery: SearchWorkTimesDocument;
	private exportSchemaWorkTimesQuery: ExportSchemaWorkTimesDocument;
	private exportDataWorkTimesQuery: ExportDataWorkTimesDocument;
	private customQueryWorkTimesQuery: CustomQueryWorkTimesDocument;
	private customQueryIdWorkTimesQuery: CustomQueryIdWorkTimesDocument;
	private usedWorkTimesQuery: UsedWorkTimesDocument;
	private existWorkTimesQuery: ExistWorkTimesDocument;
	private findUnassociatedServiceLevelAgreementsWorkTimesQuery: FindUnassociatedServiceLevelAgreementsWorkTimesDocument;
	private insertWorkTimesMutation: InsertWorkTimesDocument;
	private updateWorkTimesMutation: UpdateWorkTimesDocument;
	private importDataWorkTimesMutation: ImportDataWorkTimesDocument;
	private updateManyWorkTimesMutation: UpdateManyWorkTimesDocument;
	private deleteWorkTimesMutation: DeleteWorkTimesDocument;
	private addServiceLevelAgreementsWorkTimesMutation: AddServiceLevelAgreementsWorkTimesDocument;
	private removeServiceLevelAgreementsWorkTimesMutation: RemoveServiceLevelAgreementsWorkTimesDocument;

	constructor(private injector: Injector) {
		this.getWorkTimesQuery = this.injector.get(GetWorkTimesDocument);
		this.firstWorkTimesQuery = this.injector.get(FirstWorkTimesDocument);
		this.countWorkTimesQuery = this.injector.get(CountWorkTimesDocument);
		this.findWorkTimesQuery = this.injector.get(FindWorkTimesDocument);
		this.searchWorkTimesQuery = this.injector.get(SearchWorkTimesDocument);
		this.exportSchemaWorkTimesQuery = this.injector.get(ExportSchemaWorkTimesDocument);
		this.exportDataWorkTimesQuery = this.injector.get(ExportDataWorkTimesDocument);
		this.customQueryWorkTimesQuery = this.injector.get(CustomQueryWorkTimesDocument);
		this.customQueryIdWorkTimesQuery = this.injector.get(CustomQueryIdWorkTimesDocument);
		this.usedWorkTimesQuery = this.injector.get(UsedWorkTimesDocument);
		this.existWorkTimesQuery = this.injector.get(ExistWorkTimesDocument);
		this.findUnassociatedServiceLevelAgreementsWorkTimesQuery = this.injector.get(FindUnassociatedServiceLevelAgreementsWorkTimesDocument);
		this.insertWorkTimesMutation = this.injector.get(InsertWorkTimesDocument);
		this.updateWorkTimesMutation = this.injector.get(UpdateWorkTimesDocument);
		this.importDataWorkTimesMutation = this.injector.get(ImportDataWorkTimesDocument);
		this.updateManyWorkTimesMutation = this.injector.get(UpdateManyWorkTimesDocument);
		this.deleteWorkTimesMutation = this.injector.get(DeleteWorkTimesDocument);
		this.addServiceLevelAgreementsWorkTimesMutation = this.injector.get(AddServiceLevelAgreementsWorkTimesDocument);
		this.removeServiceLevelAgreementsWorkTimesMutation = this.injector.get(RemoveServiceLevelAgreementsWorkTimesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkTime> {
    
    		let variables: GetWorkTimesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.get));
            }
            else{
                let result:ServiceSingleResultOfWorkTime={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkTime,
		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkTime> {
    
    		let variables: FirstWorkTimesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkTimesBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkTime,
		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkTime> {
    
    		let variables: FindWorkTimesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkTime,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkTime> {
    
    		let variables: SearchWorkTimesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkTimesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkTimesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkTime,
		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkTime> {
    
    		let variables: CustomQueryWorkTimesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkTime,
		@Args('filter?') filter?: FilterOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkTime> {
    
    		let variables: CustomQueryIdWorkTimesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkTimesBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkTimesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.exist));
    	}

	@InjectArgs
	public findAssociatedServiceLevelAgreements(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfServiceLevelAgreement> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('serviceLevelAgreements', fields, null, [
			GqlSubFieldArg.create('filterOfServiceLevelAgreements', 'filter'),
			GqlSubFieldArg.create('optionsOfServiceLevelAgreements', 'options'),
		]),
		])
		extendedVariables['filterOfServiceLevelAgreements'] = filter;
		extendedVariables['optionsOfServiceLevelAgreements'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.serviceLevelAgreements));
            }
            else{
                let result: ServiceListResultOfServiceLevelAgreement = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceLevelAgreement> {
    
    		let variables: FindUnassociatedServiceLevelAgreementsWorkTimesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedServiceLevelAgreementsWorkTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.findUnassociatedServiceLevelAgreements));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkTimeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkTime> {
    
    		let variables: InsertWorkTimesBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkTime> {
    
    		let variables: UpdateWorkTimesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkTimesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkTimesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkTimesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddServiceLevelAgreementsWorkTimesBaseVariables = {
    			id: id,
			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.addServiceLevelAgreementsWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.addServiceLevelAgreements));
    	}

    	/**  */
    	@InjectArgs
    	public removeServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveServiceLevelAgreementsWorkTimesBaseVariables = {
    			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.removeServiceLevelAgreementsWorkTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workTimes.removeServiceLevelAgreements));
    	}
    
}