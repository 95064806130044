import { ScanConfigurationBaseService } from '../service-bases';
import { Injectable, Injector, EventEmitter } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { of, Observable } from 'rxjs';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql';
import { CoreLicenseReader } from '@clarilog/core/services2/license-reader/license-reader';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfNetworkCredential,
  FilterOfScanConfiguration,
  QueryContextOfNetworkCredential,
  QueryContextOfScanConfiguration,
  ServiceListResultOfNetworkCredential,
  ServiceListResultOfScanConfiguration,
  Sort,
} from '../types';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration')
export class ScanConfigurationCoreService extends ScanConfigurationBaseService {
  constructor(injector: Injector, public licenseReader: CoreLicenseReader) {
    super(injector);
  }
  public findScanConfigurationByAppointment(
    @Args('extendedVariables?') extendedVariables?: any,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('filter?') filter?: FilterOfScanConfiguration,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let fields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
      ]),
      GqlField.create('totalCount'),
    ];
    return this.find(fields, options, filter, extendedVariables);
  }
  /** Permet de choisir le type d'élément à créer  */
  findByKey(): Observable<{ id: string; name: string }[]> {
    let licenceAsset = this.licenseReader.hasFeature('featureAssets');
    let items = [
      {
        id: 'scan-by-address-ip-range',
        name: TranslateService.get(
          'entities/scanByAddressIpRange/_title/singular',
        ),
        visibled: licenceAsset,
      },
      {
        id: 'scan-by-host',
        name: TranslateService.get('entities/scanByHost/_title/singular'),
        visibled: licenceAsset,
      },
      {
        id: 'scan-by-ldap-only-structure',
        name: TranslateService.get('entities/scanByLdap/_titleOnly/singular'),
        visibled: true,
      },
      {
        id: 'scan-by-ldap',
        name: TranslateService.get('entities/scanByLdap/_title/singular'),
        visibled: true,
      },
      {
        id: 'scan-by-workgroup',
        name: TranslateService.get('entities/scanByWorkgroup/_title/singular'),
        visibled: licenceAsset,
      },
    ];

    items = items.filter((f) => f.visibled);
    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }
  /** Converti la valeur en url */
  @InjectArgs
  toUrl(@Args('type') type: string): string {
    switch (type) {
      case 'ScanByAddressIPRange':
        return 'scan-by-address-ip-range';
      case 'ScanByHost':
        return 'scan-by-host';
      case 'ScanByLdap':
        return 'scan-by-ldap';
      case 'ScanByWorkgroup':
        return 'scan-by-workgroup';
      case 'ScanByLocalAgent':
        return 'scan-by-local-agent';
      default:
        return '';
    }
  }

  /** Obtient le nom traduit du type  */
  @InjectArgs
  translateType(@Args('type') type: string): string {
    switch (type) {
      case 'scan-by-address-ip-range':
        return 'scanByAddressIPRange';
      case 'ScanByAssetCategory':
        return '';
      case 'ScanByAssetCategory':
        return '';
      case 'scan-by-host':
        return 'scanByHost';
      case 'scan-by-ldap':
      case 'scan-by-ldap-only-structure':
        return 'scanByLdap';
      case 'ScanByLocalization':
        return '';
      case 'scan-by-workgroup':
        return 'scanByWorkgroup';
      case 'scan-by-local-agent':
        return 'scanByLocalAgent';
    }
  }

  static _runStructLdap: EventEmitter<void> = new EventEmitter<void>();
  runStructLdap(): EventEmitter<void> {
    return ScanConfigurationCoreService._runStructLdap;
  }
  @InjectArgs
  canRemoveImportOptions(@Args('items') items: any): Observable<boolean> {
    if (items != null) {
      if (items.filter((f) => f.key == 'default').length > 0) {
        return of(false);
      }
    }
    return of(true);
  }

  @InjectArgs
  public findCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption = this.cleanAndRetrieveNewOption(options);
    return this.find(
      fields,
      dataOption.options,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.orderResultByType(result, dataOption.sortTypenameValue);
      }),
    );
  }

  public cleanAndRetrieveNewOption(options: any) {
    let sortTypenameValue = null;
    if (options != null) {
      options.sort?.map((element, index) => {
        if (element['__typename'] != null && element['__typename']) {
          sortTypenameValue = element['__typename'];
          options.sort[index] = null;
        }
      });
    }
    return { sortTypenameValue, options };
  }

  //Mettre dans l'ordre le type
  public orderResultByType(array: any, orderType: string) {
    if (orderType != null) {
      array.data?.map((element) => {
        let typeName = element['__typename'] as string;
        let type = typeName[0].toLowerCase() + typeName.slice(1);
        let newField = TranslateService.get(`entities/${type}/_title/singular`);
        element['translateType'] = newField;
      });

      if (orderType == Sort.Asc) {
        array.data = array.data.sort((a, b) =>
          a['translateType'].localeCompare(b['translateType']),
        );
      } else {
        array.data = array.data.sort((a, b) =>
          b['translateType'].localeCompare(a['translateType']),
        );
      }
    }
    return array;
  }

  @InjectArgs
  public findAssociatedNetworkCredentialsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfNetworkCredential,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfNetworkCredential,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfNetworkCredential> {
    let dataOption = this.cleanAndRetrieveNewOption(options);
    return this.findAssociatedNetworkCredentials(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.orderResultByType(result, dataOption.sortTypenameValue);
      }),
    );
  }

  @InjectArgs
  public findUnassociatedNetworkCredentialsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfNetworkCredential,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfNetworkCredential,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfNetworkCredential> {
    let dataOption = this.cleanAndRetrieveNewOption(options);
    return this.findUnassociatedNetworkCredentials(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.orderResultByType(result, dataOption.sortTypenameValue);
      }),
    );
  }
}
