import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPnpEntitiesBaseVariables, FirstPnpEntitiesBaseVariables, CountPnpEntitiesBaseVariables, FindPnpEntitiesBaseVariables, SearchPnpEntitiesBaseVariables, ExportSchemaPnpEntitiesBaseVariables, ExportDataPnpEntitiesBaseVariables, CustomQueryPnpEntitiesBaseVariables, CustomQueryIdPnpEntitiesBaseVariables, UsedPnpEntitiesBaseVariables, ExistPnpEntitiesBaseVariables } from '../gqls'
import { GetPnpEntitiesDocument, FirstPnpEntitiesDocument, CountPnpEntitiesDocument, FindPnpEntitiesDocument, SearchPnpEntitiesDocument, ExportSchemaPnpEntitiesDocument, ExportDataPnpEntitiesDocument, CustomQueryPnpEntitiesDocument, CustomQueryIdPnpEntitiesDocument, UsedPnpEntitiesDocument, ExistPnpEntitiesDocument } from '../gqls'
import { ServiceSingleResultOfPnpEntity, QueryContextOfPnpEntity, FilterOfPnpEntity, ServiceSingleResultOfInt64, ServiceListResultOfPnpEntity, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PnpEntityBaseService {
    
public modelName = 'pnpEntity';
public modelPluralName = 'pnpEntities';

	private getPnpEntitiesQuery: GetPnpEntitiesDocument;
	private firstPnpEntitiesQuery: FirstPnpEntitiesDocument;
	private countPnpEntitiesQuery: CountPnpEntitiesDocument;
	private findPnpEntitiesQuery: FindPnpEntitiesDocument;
	private searchPnpEntitiesQuery: SearchPnpEntitiesDocument;
	private exportSchemaPnpEntitiesQuery: ExportSchemaPnpEntitiesDocument;
	private exportDataPnpEntitiesQuery: ExportDataPnpEntitiesDocument;
	private customQueryPnpEntitiesQuery: CustomQueryPnpEntitiesDocument;
	private customQueryIdPnpEntitiesQuery: CustomQueryIdPnpEntitiesDocument;
	private usedPnpEntitiesQuery: UsedPnpEntitiesDocument;
	private existPnpEntitiesQuery: ExistPnpEntitiesDocument;

	constructor(private injector: Injector) {
		this.getPnpEntitiesQuery = this.injector.get(GetPnpEntitiesDocument);
		this.firstPnpEntitiesQuery = this.injector.get(FirstPnpEntitiesDocument);
		this.countPnpEntitiesQuery = this.injector.get(CountPnpEntitiesDocument);
		this.findPnpEntitiesQuery = this.injector.get(FindPnpEntitiesDocument);
		this.searchPnpEntitiesQuery = this.injector.get(SearchPnpEntitiesDocument);
		this.exportSchemaPnpEntitiesQuery = this.injector.get(ExportSchemaPnpEntitiesDocument);
		this.exportDataPnpEntitiesQuery = this.injector.get(ExportDataPnpEntitiesDocument);
		this.customQueryPnpEntitiesQuery = this.injector.get(CustomQueryPnpEntitiesDocument);
		this.customQueryIdPnpEntitiesQuery = this.injector.get(CustomQueryIdPnpEntitiesDocument);
		this.usedPnpEntitiesQuery = this.injector.get(UsedPnpEntitiesDocument);
		this.existPnpEntitiesQuery = this.injector.get(ExistPnpEntitiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntity> {
    
    		let variables: GetPnpEntitiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.get));
            }
            else{
                let result:ServiceSingleResultOfPnpEntity={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpEntity,
		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntity> {
    
    		let variables: FirstPnpEntitiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPnpEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.countPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpEntity,
		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntity> {
    
    		let variables: FindPnpEntitiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPnpEntity,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntity> {
    
    		let variables: SearchPnpEntitiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPnpEntitiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPnpEntitiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPnpEntity,
		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntity> {
    
    		let variables: CustomQueryPnpEntitiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPnpEntity,
		@Args('filter?') filter?: FilterOfPnpEntity,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntity> {
    
    		let variables: CustomQueryIdPnpEntitiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPnpEntitiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPnpEntitiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPnpEntitiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntities.exist));
    	}
    
    
}