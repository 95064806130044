import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNotesBaseVariables, FirstNotesBaseVariables, CountNotesBaseVariables, FindNotesBaseVariables, SearchNotesBaseVariables, ExportSchemaNotesBaseVariables, ExportDataNotesBaseVariables, CustomQueryNotesBaseVariables, CustomQueryIdNotesBaseVariables, UsedNotesBaseVariables, ExistNotesBaseVariables, FindRecyclesNotesBaseVariables, CountRecyclesNotesBaseVariables, ReadOnlyNotesBaseVariables, FindArchivedNotesBaseVariables, CountAllNotesBaseVariables, FindDynamicPropertyFieldsNotesBaseVariables, InsertNotesBaseVariables, UpdateNotesBaseVariables, ImportDataNotesBaseVariables, UpdateManyNotesBaseVariables, DeleteNotesBaseVariables, RestoreNotesBaseVariables, RecycleNotesBaseVariables, RestoreArchivedNotesBaseVariables, ArchivedNotesBaseVariables, AddFileDynamicFieldNotesBaseVariables, RemoveFileDynamicFieldNotesBaseVariables } from '../gqls'
import { GetNotesDocument, FirstNotesDocument, CountNotesDocument, FindNotesDocument, SearchNotesDocument, ExportSchemaNotesDocument, ExportDataNotesDocument, CustomQueryNotesDocument, CustomQueryIdNotesDocument, UsedNotesDocument, ExistNotesDocument, FindRecyclesNotesDocument, CountRecyclesNotesDocument, ReadOnlyNotesDocument, FindArchivedNotesDocument, CountAllNotesDocument, FindDynamicPropertyFieldsNotesDocument, InsertNotesDocument, UpdateNotesDocument, ImportDataNotesDocument, UpdateManyNotesDocument, DeleteNotesDocument, RestoreNotesDocument, RecycleNotesDocument, RestoreArchivedNotesDocument, ArchivedNotesDocument, AddFileDynamicFieldNotesDocument, RemoveFileDynamicFieldNotesDocument } from '../gqls'
import { ServiceSingleResultOfNote, QueryContextOfNote, FilterOfNote, ServiceSingleResultOfInt64, ServiceListResultOfNote, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfNote, NoteInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NoteBaseService {
    
public modelName = 'note';
public modelPluralName = 'notes';

	private getNotesQuery: GetNotesDocument;
	private firstNotesQuery: FirstNotesDocument;
	private countNotesQuery: CountNotesDocument;
	private findNotesQuery: FindNotesDocument;
	private searchNotesQuery: SearchNotesDocument;
	private exportSchemaNotesQuery: ExportSchemaNotesDocument;
	private exportDataNotesQuery: ExportDataNotesDocument;
	private customQueryNotesQuery: CustomQueryNotesDocument;
	private customQueryIdNotesQuery: CustomQueryIdNotesDocument;
	private usedNotesQuery: UsedNotesDocument;
	private existNotesQuery: ExistNotesDocument;
	private findRecyclesNotesQuery: FindRecyclesNotesDocument;
	private countRecyclesNotesQuery: CountRecyclesNotesDocument;
	private readOnlyNotesQuery: ReadOnlyNotesDocument;
	private findArchivedNotesQuery: FindArchivedNotesDocument;
	private countAllNotesQuery: CountAllNotesDocument;
	private findDynamicPropertyFieldsNotesQuery: FindDynamicPropertyFieldsNotesDocument;
	private insertNotesMutation: InsertNotesDocument;
	private updateNotesMutation: UpdateNotesDocument;
	private importDataNotesMutation: ImportDataNotesDocument;
	private updateManyNotesMutation: UpdateManyNotesDocument;
	private deleteNotesMutation: DeleteNotesDocument;
	private restoreNotesMutation: RestoreNotesDocument;
	private recycleNotesMutation: RecycleNotesDocument;
	private restoreArchivedNotesMutation: RestoreArchivedNotesDocument;
	private archivedNotesMutation: ArchivedNotesDocument;
	private addFileDynamicFieldNotesMutation: AddFileDynamicFieldNotesDocument;
	private removeFileDynamicFieldNotesMutation: RemoveFileDynamicFieldNotesDocument;

	constructor(private injector: Injector) {
		this.getNotesQuery = this.injector.get(GetNotesDocument);
		this.firstNotesQuery = this.injector.get(FirstNotesDocument);
		this.countNotesQuery = this.injector.get(CountNotesDocument);
		this.findNotesQuery = this.injector.get(FindNotesDocument);
		this.searchNotesQuery = this.injector.get(SearchNotesDocument);
		this.exportSchemaNotesQuery = this.injector.get(ExportSchemaNotesDocument);
		this.exportDataNotesQuery = this.injector.get(ExportDataNotesDocument);
		this.customQueryNotesQuery = this.injector.get(CustomQueryNotesDocument);
		this.customQueryIdNotesQuery = this.injector.get(CustomQueryIdNotesDocument);
		this.usedNotesQuery = this.injector.get(UsedNotesDocument);
		this.existNotesQuery = this.injector.get(ExistNotesDocument);
		this.findRecyclesNotesQuery = this.injector.get(FindRecyclesNotesDocument);
		this.countRecyclesNotesQuery = this.injector.get(CountRecyclesNotesDocument);
		this.readOnlyNotesQuery = this.injector.get(ReadOnlyNotesDocument);
		this.findArchivedNotesQuery = this.injector.get(FindArchivedNotesDocument);
		this.countAllNotesQuery = this.injector.get(CountAllNotesDocument);
		this.findDynamicPropertyFieldsNotesQuery = this.injector.get(FindDynamicPropertyFieldsNotesDocument);
		this.insertNotesMutation = this.injector.get(InsertNotesDocument);
		this.updateNotesMutation = this.injector.get(UpdateNotesDocument);
		this.importDataNotesMutation = this.injector.get(ImportDataNotesDocument);
		this.updateManyNotesMutation = this.injector.get(UpdateManyNotesDocument);
		this.deleteNotesMutation = this.injector.get(DeleteNotesDocument);
		this.restoreNotesMutation = this.injector.get(RestoreNotesDocument);
		this.recycleNotesMutation = this.injector.get(RecycleNotesDocument);
		this.restoreArchivedNotesMutation = this.injector.get(RestoreArchivedNotesDocument);
		this.archivedNotesMutation = this.injector.get(ArchivedNotesDocument);
		this.addFileDynamicFieldNotesMutation = this.injector.get(AddFileDynamicFieldNotesDocument);
		this.removeFileDynamicFieldNotesMutation = this.injector.get(RemoveFileDynamicFieldNotesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNote> {
    
    		let variables: GetNotesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.get));
            }
            else{
                let result:ServiceSingleResultOfNote={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNote> {
    
    		let variables: FirstNotesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNotesBaseVariables = {
    			filter: filter
    		}
    				return this.countNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: FindNotesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNote,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: SearchNotesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNotesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNotesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: CustomQueryNotesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: CustomQueryIdNotesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNotesBaseVariables = {
    			ids: ids
    		}
    				return this.usedNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNotesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: FindRecyclesNotesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesNotesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyNotesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNote,
		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNote> {
    
    		let variables: FindArchivedNotesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllNotesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsNotesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsNotesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.notes.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: NoteInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNote> {
    
    		let variables: InsertNotesBaseVariables = {
    			entity: entity
    		}
    				return this.insertNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNote> {
    
    		let variables: UpdateNotesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataNotesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfNote,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyNotesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteNotesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreNotesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleNotesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedNotesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedNotesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldNotesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldNotesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldNotesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.notes.removeFileDynamicField));
    	}
    
}