import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowMailsBaseVariables, FirstWorkflowMailsBaseVariables, CountWorkflowMailsBaseVariables, FindWorkflowMailsBaseVariables, SearchWorkflowMailsBaseVariables, ExportSchemaWorkflowMailsBaseVariables, ExportDataWorkflowMailsBaseVariables, CustomQueryWorkflowMailsBaseVariables, CustomQueryIdWorkflowMailsBaseVariables, UsedWorkflowMailsBaseVariables, ExistWorkflowMailsBaseVariables, InsertWorkflowMailsBaseVariables, UpdateWorkflowMailsBaseVariables, ImportDataWorkflowMailsBaseVariables, UpdateManyWorkflowMailsBaseVariables, DeleteWorkflowMailsBaseVariables } from '../gqls'
import { GetWorkflowMailsDocument, FirstWorkflowMailsDocument, CountWorkflowMailsDocument, FindWorkflowMailsDocument, SearchWorkflowMailsDocument, ExportSchemaWorkflowMailsDocument, ExportDataWorkflowMailsDocument, CustomQueryWorkflowMailsDocument, CustomQueryIdWorkflowMailsDocument, UsedWorkflowMailsDocument, ExistWorkflowMailsDocument, InsertWorkflowMailsDocument, UpdateWorkflowMailsDocument, ImportDataWorkflowMailsDocument, UpdateManyWorkflowMailsDocument, DeleteWorkflowMailsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowMail, QueryContextOfWorkflowMail, FilterOfWorkflowMail, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowMail, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowMailInput, FieldUpdateOperatorOfWorkflowMail } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowMailBaseService {
    
public modelName = 'workflowMail';
public modelPluralName = 'workflowMails';

	private getWorkflowMailsQuery: GetWorkflowMailsDocument;
	private firstWorkflowMailsQuery: FirstWorkflowMailsDocument;
	private countWorkflowMailsQuery: CountWorkflowMailsDocument;
	private findWorkflowMailsQuery: FindWorkflowMailsDocument;
	private searchWorkflowMailsQuery: SearchWorkflowMailsDocument;
	private exportSchemaWorkflowMailsQuery: ExportSchemaWorkflowMailsDocument;
	private exportDataWorkflowMailsQuery: ExportDataWorkflowMailsDocument;
	private customQueryWorkflowMailsQuery: CustomQueryWorkflowMailsDocument;
	private customQueryIdWorkflowMailsQuery: CustomQueryIdWorkflowMailsDocument;
	private usedWorkflowMailsQuery: UsedWorkflowMailsDocument;
	private existWorkflowMailsQuery: ExistWorkflowMailsDocument;
	private insertWorkflowMailsMutation: InsertWorkflowMailsDocument;
	private updateWorkflowMailsMutation: UpdateWorkflowMailsDocument;
	private importDataWorkflowMailsMutation: ImportDataWorkflowMailsDocument;
	private updateManyWorkflowMailsMutation: UpdateManyWorkflowMailsDocument;
	private deleteWorkflowMailsMutation: DeleteWorkflowMailsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowMailsQuery = this.injector.get(GetWorkflowMailsDocument);
		this.firstWorkflowMailsQuery = this.injector.get(FirstWorkflowMailsDocument);
		this.countWorkflowMailsQuery = this.injector.get(CountWorkflowMailsDocument);
		this.findWorkflowMailsQuery = this.injector.get(FindWorkflowMailsDocument);
		this.searchWorkflowMailsQuery = this.injector.get(SearchWorkflowMailsDocument);
		this.exportSchemaWorkflowMailsQuery = this.injector.get(ExportSchemaWorkflowMailsDocument);
		this.exportDataWorkflowMailsQuery = this.injector.get(ExportDataWorkflowMailsDocument);
		this.customQueryWorkflowMailsQuery = this.injector.get(CustomQueryWorkflowMailsDocument);
		this.customQueryIdWorkflowMailsQuery = this.injector.get(CustomQueryIdWorkflowMailsDocument);
		this.usedWorkflowMailsQuery = this.injector.get(UsedWorkflowMailsDocument);
		this.existWorkflowMailsQuery = this.injector.get(ExistWorkflowMailsDocument);
		this.insertWorkflowMailsMutation = this.injector.get(InsertWorkflowMailsDocument);
		this.updateWorkflowMailsMutation = this.injector.get(UpdateWorkflowMailsDocument);
		this.importDataWorkflowMailsMutation = this.injector.get(ImportDataWorkflowMailsDocument);
		this.updateManyWorkflowMailsMutation = this.injector.get(UpdateManyWorkflowMailsDocument);
		this.deleteWorkflowMailsMutation = this.injector.get(DeleteWorkflowMailsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowMail> {
    
    		let variables: GetWorkflowMailsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowMail={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowMail,
		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowMail> {
    
    		let variables: FirstWorkflowMailsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowMailsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowMail,
		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowMail> {
    
    		let variables: FindWorkflowMailsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowMail,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowMail> {
    
    		let variables: SearchWorkflowMailsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowMailsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowMailsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowMail,
		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowMail> {
    
    		let variables: CustomQueryWorkflowMailsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowMail,
		@Args('filter?') filter?: FilterOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowMail> {
    
    		let variables: CustomQueryIdWorkflowMailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowMailsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowMailsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowMailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowMailInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowMail> {
    
    		let variables: InsertWorkflowMailsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowMailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowMail> {
    
    		let variables: UpdateWorkflowMailsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowMailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowMailsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowMailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowMail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowMailsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowMailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowMailsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowMailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowMails.delete));
    	}
    
}