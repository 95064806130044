import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowHttpRequestsBaseVariables, FirstWorkflowHttpRequestsBaseVariables, CountWorkflowHttpRequestsBaseVariables, FindWorkflowHttpRequestsBaseVariables, SearchWorkflowHttpRequestsBaseVariables, ExportSchemaWorkflowHttpRequestsBaseVariables, ExportDataWorkflowHttpRequestsBaseVariables, CustomQueryWorkflowHttpRequestsBaseVariables, CustomQueryIdWorkflowHttpRequestsBaseVariables, UsedWorkflowHttpRequestsBaseVariables, ExistWorkflowHttpRequestsBaseVariables, InsertWorkflowHttpRequestsBaseVariables, UpdateWorkflowHttpRequestsBaseVariables, ImportDataWorkflowHttpRequestsBaseVariables, UpdateManyWorkflowHttpRequestsBaseVariables, DeleteWorkflowHttpRequestsBaseVariables } from '../gqls'
import { GetWorkflowHttpRequestsDocument, FirstWorkflowHttpRequestsDocument, CountWorkflowHttpRequestsDocument, FindWorkflowHttpRequestsDocument, SearchWorkflowHttpRequestsDocument, ExportSchemaWorkflowHttpRequestsDocument, ExportDataWorkflowHttpRequestsDocument, CustomQueryWorkflowHttpRequestsDocument, CustomQueryIdWorkflowHttpRequestsDocument, UsedWorkflowHttpRequestsDocument, ExistWorkflowHttpRequestsDocument, InsertWorkflowHttpRequestsDocument, UpdateWorkflowHttpRequestsDocument, ImportDataWorkflowHttpRequestsDocument, UpdateManyWorkflowHttpRequestsDocument, DeleteWorkflowHttpRequestsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowHttpRequest, QueryContextOfWorkflowHttpRequest, FilterOfWorkflowHttpRequest, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowHttpRequest, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowHttpRequestInput, FieldUpdateOperatorOfWorkflowHttpRequest } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowHttpRequestBaseService {
    
public modelName = 'workflowHttpRequest';
public modelPluralName = 'workflowHttpRequests';

	private getWorkflowHttpRequestsQuery: GetWorkflowHttpRequestsDocument;
	private firstWorkflowHttpRequestsQuery: FirstWorkflowHttpRequestsDocument;
	private countWorkflowHttpRequestsQuery: CountWorkflowHttpRequestsDocument;
	private findWorkflowHttpRequestsQuery: FindWorkflowHttpRequestsDocument;
	private searchWorkflowHttpRequestsQuery: SearchWorkflowHttpRequestsDocument;
	private exportSchemaWorkflowHttpRequestsQuery: ExportSchemaWorkflowHttpRequestsDocument;
	private exportDataWorkflowHttpRequestsQuery: ExportDataWorkflowHttpRequestsDocument;
	private customQueryWorkflowHttpRequestsQuery: CustomQueryWorkflowHttpRequestsDocument;
	private customQueryIdWorkflowHttpRequestsQuery: CustomQueryIdWorkflowHttpRequestsDocument;
	private usedWorkflowHttpRequestsQuery: UsedWorkflowHttpRequestsDocument;
	private existWorkflowHttpRequestsQuery: ExistWorkflowHttpRequestsDocument;
	private insertWorkflowHttpRequestsMutation: InsertWorkflowHttpRequestsDocument;
	private updateWorkflowHttpRequestsMutation: UpdateWorkflowHttpRequestsDocument;
	private importDataWorkflowHttpRequestsMutation: ImportDataWorkflowHttpRequestsDocument;
	private updateManyWorkflowHttpRequestsMutation: UpdateManyWorkflowHttpRequestsDocument;
	private deleteWorkflowHttpRequestsMutation: DeleteWorkflowHttpRequestsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowHttpRequestsQuery = this.injector.get(GetWorkflowHttpRequestsDocument);
		this.firstWorkflowHttpRequestsQuery = this.injector.get(FirstWorkflowHttpRequestsDocument);
		this.countWorkflowHttpRequestsQuery = this.injector.get(CountWorkflowHttpRequestsDocument);
		this.findWorkflowHttpRequestsQuery = this.injector.get(FindWorkflowHttpRequestsDocument);
		this.searchWorkflowHttpRequestsQuery = this.injector.get(SearchWorkflowHttpRequestsDocument);
		this.exportSchemaWorkflowHttpRequestsQuery = this.injector.get(ExportSchemaWorkflowHttpRequestsDocument);
		this.exportDataWorkflowHttpRequestsQuery = this.injector.get(ExportDataWorkflowHttpRequestsDocument);
		this.customQueryWorkflowHttpRequestsQuery = this.injector.get(CustomQueryWorkflowHttpRequestsDocument);
		this.customQueryIdWorkflowHttpRequestsQuery = this.injector.get(CustomQueryIdWorkflowHttpRequestsDocument);
		this.usedWorkflowHttpRequestsQuery = this.injector.get(UsedWorkflowHttpRequestsDocument);
		this.existWorkflowHttpRequestsQuery = this.injector.get(ExistWorkflowHttpRequestsDocument);
		this.insertWorkflowHttpRequestsMutation = this.injector.get(InsertWorkflowHttpRequestsDocument);
		this.updateWorkflowHttpRequestsMutation = this.injector.get(UpdateWorkflowHttpRequestsDocument);
		this.importDataWorkflowHttpRequestsMutation = this.injector.get(ImportDataWorkflowHttpRequestsDocument);
		this.updateManyWorkflowHttpRequestsMutation = this.injector.get(UpdateManyWorkflowHttpRequestsDocument);
		this.deleteWorkflowHttpRequestsMutation = this.injector.get(DeleteWorkflowHttpRequestsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowHttpRequest> {
    
    		let variables: GetWorkflowHttpRequestsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowHttpRequest={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowHttpRequest,
		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowHttpRequest> {
    
    		let variables: FirstWorkflowHttpRequestsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowHttpRequestsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowHttpRequest,
		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowHttpRequest> {
    
    		let variables: FindWorkflowHttpRequestsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowHttpRequest,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowHttpRequest> {
    
    		let variables: SearchWorkflowHttpRequestsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowHttpRequestsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowHttpRequestsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowHttpRequest,
		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowHttpRequest> {
    
    		let variables: CustomQueryWorkflowHttpRequestsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowHttpRequest,
		@Args('filter?') filter?: FilterOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowHttpRequest> {
    
    		let variables: CustomQueryIdWorkflowHttpRequestsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowHttpRequestsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowHttpRequestsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowHttpRequestsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowHttpRequestInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowHttpRequest> {
    
    		let variables: InsertWorkflowHttpRequestsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowHttpRequestsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowHttpRequest> {
    
    		let variables: UpdateWorkflowHttpRequestsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowHttpRequestsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowHttpRequestsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowHttpRequestsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowHttpRequest,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowHttpRequestsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowHttpRequestsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowHttpRequestsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowHttpRequestsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowHttpRequests.delete));
    	}
    
}