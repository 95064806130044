import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAnalyseScanByAzureAdTasksBaseVariables, FirstAnalyseScanByAzureAdTasksBaseVariables, CountAnalyseScanByAzureAdTasksBaseVariables, FindAnalyseScanByAzureAdTasksBaseVariables, SearchAnalyseScanByAzureAdTasksBaseVariables, ExportSchemaAnalyseScanByAzureAdTasksBaseVariables, ExportDataAnalyseScanByAzureAdTasksBaseVariables, CustomQueryAnalyseScanByAzureAdTasksBaseVariables, CustomQueryIdAnalyseScanByAzureAdTasksBaseVariables, UsedAnalyseScanByAzureAdTasksBaseVariables, ExistAnalyseScanByAzureAdTasksBaseVariables, InsertAnalyseScanByAzureAdTasksBaseVariables, UpdateAnalyseScanByAzureAdTasksBaseVariables, ImportDataAnalyseScanByAzureAdTasksBaseVariables, UpdateManyAnalyseScanByAzureAdTasksBaseVariables, DeleteAnalyseScanByAzureAdTasksBaseVariables } from '../gqls'
import { GetAnalyseScanByAzureAdTasksDocument, FirstAnalyseScanByAzureAdTasksDocument, CountAnalyseScanByAzureAdTasksDocument, FindAnalyseScanByAzureAdTasksDocument, SearchAnalyseScanByAzureAdTasksDocument, ExportSchemaAnalyseScanByAzureAdTasksDocument, ExportDataAnalyseScanByAzureAdTasksDocument, CustomQueryAnalyseScanByAzureAdTasksDocument, CustomQueryIdAnalyseScanByAzureAdTasksDocument, UsedAnalyseScanByAzureAdTasksDocument, ExistAnalyseScanByAzureAdTasksDocument, InsertAnalyseScanByAzureAdTasksDocument, UpdateAnalyseScanByAzureAdTasksDocument, ImportDataAnalyseScanByAzureAdTasksDocument, UpdateManyAnalyseScanByAzureAdTasksDocument, DeleteAnalyseScanByAzureAdTasksDocument } from '../gqls'
import { ServiceSingleResultOfAnalyseScanByAzureAdTask, QueryContextOfAnalyseScanByAzureAdTask, FilterOfAnalyseScanByAzureAdTask, ServiceSingleResultOfInt64, ServiceListResultOfAnalyseScanByAzureAdTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AnalyseScanByAzureAdTaskInput, FieldUpdateOperatorOfAnalyseScanByAzureAdTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AnalyseScanByAzureAdTaskBaseService {
    
public modelName = 'analyseScanByAzureAdTask';
public modelPluralName = 'analyseScanByAzureAdTasks';

	private getAnalyseScanByAzureAdTasksQuery: GetAnalyseScanByAzureAdTasksDocument;
	private firstAnalyseScanByAzureAdTasksQuery: FirstAnalyseScanByAzureAdTasksDocument;
	private countAnalyseScanByAzureAdTasksQuery: CountAnalyseScanByAzureAdTasksDocument;
	private findAnalyseScanByAzureAdTasksQuery: FindAnalyseScanByAzureAdTasksDocument;
	private searchAnalyseScanByAzureAdTasksQuery: SearchAnalyseScanByAzureAdTasksDocument;
	private exportSchemaAnalyseScanByAzureAdTasksQuery: ExportSchemaAnalyseScanByAzureAdTasksDocument;
	private exportDataAnalyseScanByAzureAdTasksQuery: ExportDataAnalyseScanByAzureAdTasksDocument;
	private customQueryAnalyseScanByAzureAdTasksQuery: CustomQueryAnalyseScanByAzureAdTasksDocument;
	private customQueryIdAnalyseScanByAzureAdTasksQuery: CustomQueryIdAnalyseScanByAzureAdTasksDocument;
	private usedAnalyseScanByAzureAdTasksQuery: UsedAnalyseScanByAzureAdTasksDocument;
	private existAnalyseScanByAzureAdTasksQuery: ExistAnalyseScanByAzureAdTasksDocument;
	private insertAnalyseScanByAzureAdTasksMutation: InsertAnalyseScanByAzureAdTasksDocument;
	private updateAnalyseScanByAzureAdTasksMutation: UpdateAnalyseScanByAzureAdTasksDocument;
	private importDataAnalyseScanByAzureAdTasksMutation: ImportDataAnalyseScanByAzureAdTasksDocument;
	private updateManyAnalyseScanByAzureAdTasksMutation: UpdateManyAnalyseScanByAzureAdTasksDocument;
	private deleteAnalyseScanByAzureAdTasksMutation: DeleteAnalyseScanByAzureAdTasksDocument;

	constructor(private injector: Injector) {
		this.getAnalyseScanByAzureAdTasksQuery = this.injector.get(GetAnalyseScanByAzureAdTasksDocument);
		this.firstAnalyseScanByAzureAdTasksQuery = this.injector.get(FirstAnalyseScanByAzureAdTasksDocument);
		this.countAnalyseScanByAzureAdTasksQuery = this.injector.get(CountAnalyseScanByAzureAdTasksDocument);
		this.findAnalyseScanByAzureAdTasksQuery = this.injector.get(FindAnalyseScanByAzureAdTasksDocument);
		this.searchAnalyseScanByAzureAdTasksQuery = this.injector.get(SearchAnalyseScanByAzureAdTasksDocument);
		this.exportSchemaAnalyseScanByAzureAdTasksQuery = this.injector.get(ExportSchemaAnalyseScanByAzureAdTasksDocument);
		this.exportDataAnalyseScanByAzureAdTasksQuery = this.injector.get(ExportDataAnalyseScanByAzureAdTasksDocument);
		this.customQueryAnalyseScanByAzureAdTasksQuery = this.injector.get(CustomQueryAnalyseScanByAzureAdTasksDocument);
		this.customQueryIdAnalyseScanByAzureAdTasksQuery = this.injector.get(CustomQueryIdAnalyseScanByAzureAdTasksDocument);
		this.usedAnalyseScanByAzureAdTasksQuery = this.injector.get(UsedAnalyseScanByAzureAdTasksDocument);
		this.existAnalyseScanByAzureAdTasksQuery = this.injector.get(ExistAnalyseScanByAzureAdTasksDocument);
		this.insertAnalyseScanByAzureAdTasksMutation = this.injector.get(InsertAnalyseScanByAzureAdTasksDocument);
		this.updateAnalyseScanByAzureAdTasksMutation = this.injector.get(UpdateAnalyseScanByAzureAdTasksDocument);
		this.importDataAnalyseScanByAzureAdTasksMutation = this.injector.get(ImportDataAnalyseScanByAzureAdTasksDocument);
		this.updateManyAnalyseScanByAzureAdTasksMutation = this.injector.get(UpdateManyAnalyseScanByAzureAdTasksDocument);
		this.deleteAnalyseScanByAzureAdTasksMutation = this.injector.get(DeleteAnalyseScanByAzureAdTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: GetAnalyseScanByAzureAdTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.get));
            }
            else{
                let result:ServiceSingleResultOfAnalyseScanByAzureAdTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAnalyseScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: FirstAnalyseScanByAzureAdTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAnalyseScanByAzureAdTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAnalyseScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: FindAnalyseScanByAzureAdTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAnalyseScanByAzureAdTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: SearchAnalyseScanByAzureAdTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAnalyseScanByAzureAdTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAnalyseScanByAzureAdTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAnalyseScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: CustomQueryAnalyseScanByAzureAdTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAnalyseScanByAzureAdTask,
		@Args('filter?') filter?: FilterOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: CustomQueryIdAnalyseScanByAzureAdTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAnalyseScanByAzureAdTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAnalyseScanByAzureAdTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAnalyseScanByAzureAdTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AnalyseScanByAzureAdTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: InsertAnalyseScanByAzureAdTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertAnalyseScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAnalyseScanByAzureAdTask> {
    
    		let variables: UpdateAnalyseScanByAzureAdTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAnalyseScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAnalyseScanByAzureAdTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAnalyseScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAnalyseScanByAzureAdTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAnalyseScanByAzureAdTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAnalyseScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAnalyseScanByAzureAdTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAnalyseScanByAzureAdTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.analyseScanByAzureAdTasks.delete));
    	}
    
}