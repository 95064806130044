import {
  AfterViewInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { of } from 'rxjs';
import { ServiceListResult, ServiceSingleResult } from '../../../../core';
import { TranslateService } from '../../../services/translate/translate.service';
import { ListComponentBase } from '../list/list.component-base';
import massiveEditModel from './asset-massive.model.json';
import addWarrantyModel from './warranty-massive.model.json';
import { CoreImportDataPopupComponent } from '../../import-data-popup/import-data-popup.component';
import { ContractCoreService } from '@clarilog/core/services2/graphql/generated-types/services/contract.service';
import { AssetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.service';
import {
  CoreGraphQLDataSource,
  CoreGraphQLSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { CoreListComponent } from '../list/list.component';
import notify from 'devextreme/ui/notify';
import { ImportFileFormat } from '@clarilog/core/services2/graphql/generated-types/types';
import {
  ModelDataSourceContext,
  ModelFnContext,
} from '@clarilog/shared2/services/compiler/model-state';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import { InStockMovementCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { CriteriaHelpers } from '@clarilog/core/services2';
/**
 * Représente l'état dans lequel se trouve le composent LinkList.
 */

export enum StockMode {
  /** État normal. */
  Current,
  /** État corbeille. */
  Deleted,
}

/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-stock-created-asset-list',
  templateUrl: './stock-created-asset-list.component.html',
  styleUrls: ['./stock-created-asset-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreStockCreatedAssetListComponent),
      multi: true,
    },
  ],
})
export class CoreStockCreatedAssetListComponent
  extends ListComponentBase
  implements ControlValueAccessor, AfterViewInit, OnInit
{
  /** Obtient ou définit l'état d'ouverture du popup. */
  @Input() associatePopup: boolean = false;
  /** Obtient ou définit le nom du label du control */
  @Input() label: string = undefined;
  /** Obtient ou définit la query du select. */
  @Input() select: any = undefined;
  /** Parametre pour editier une grille*/
  @Input() options: any = undefined;

  currentSource: ModelDataSourceContext = new ModelDataSourceContext({
    datasource: new DataSource([]),
  });
  forceRepaintList: boolean = false;
  /** Obtient ou définit le mode d'affichage. */
  public mode: StockMode = StockMode.Current;

  /** Utile pour l'enum dans la vue. */
  public StockMode: typeof StockMode = StockMode;

  /**Permet d'override le datasource pour les 'biens creés' */
  @Input() isOverride: boolean = false;
  /** Obtient ou définit le composent list. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;
  @ViewChild(CoreImportDataPopupComponent, { static: true })
  importComponent: CoreImportDataPopupComponent;
  /** Obtient ou définit le composent StockSelect. */
  // @ViewChild(AssetMassiveEditComponent, { static: true })
  // massiveEditComponent: AssetMassiveEditComponent;

  isMassiveEditVisible: boolean = false;
  isAddWarrantyVisible: boolean = false;
  addWarrantyCallback;
  massiveEditModel;
  addWarrantyModel;

  /** Visibilité du import */
  canImport: boolean = false;

  /** Visibilité du wait */
  loadWait: boolean = false;

  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;
  /** Obtient ou définit l'état activé du composent. */
  @Input() disabled: boolean;
  /** Obtient ou définit la source de données par défaut. */
  @Input() defaultSource: any = undefined;
  /** obtient ou définit le fonction permettant s'executant avant la suppression. */
  @Input() beforeRemoveItems: any = undefined;
  /** Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer. */
  @Input() beforeRemoveItemsMessage: string;
  /** Obtient ou définit la valeur. */
  @Input() movementType: string;
  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() routeButton: string;
  /** Obtient ou définit la policie des boutons */
  @Input() policies: string;

  @Input() exportSource: any;

  /** Va Permettre la mise des valeur pas default */
  // rootForm: FormGroup;

  newCommandSource: DataSource = new DataSource({
    store: new ArrayStore({
      data: [
        {
          name: TranslateService.get('entities/stock/massiveEdit'),
          key: 'massiveEdit',
        },
        {
          name: TranslateService.get('entities/stock/addWarranty'),
          key: 'addWarranty',
        },
      ],
    }),
  });

  currentValues: any[] = [];
  removeValues: any[] = [];
  serialNumbers: any[] = [];

  constructor(
    public assetService: AssetCoreService,
    public contractService: ContractCoreService,
    private InStockMovementCoreService: InStockMovementCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private _router: Router,
    private routerActiveted: ActivatedRoute,
    public _route: ActivatedRoute,
    private modelFieldCompilerService: ModelFieldCompilerService,
  ) {
    super();
    this.massiveEditModel = massiveEditModel;
    this.addWarrantyModel = addWarrantyModel;
    this.addWarrantyCallback = (fields, keys, entry) => {
      return this.contractService.addMassiveWarrantyContract(
        fields,
        keys,
        entry,
      );
    };
    this.addWarrantyCallback = this.addWarrantyCallback.bind(this);
  }

  goTo(id) {
    let url = this._router.createUrlTree([this.route, 'edit', id], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Affiche les données en cours de l'élément. */
  public async current() {
    this.mode = StockMode.Current;

    let sourceContext = new ModelDataSourceContext({
      serviceName: 'InStockMovementCoreService',
      methodName: 'findCreatedAssets',
    });
    this.createDataSource(sourceContext);
    setTimeout(() => {
      this.selectedKeys = [];
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource(this.source.datasource.store()),
      });
    });
    if (this.forceRepaintList) {
      this.repaintList(false);
    }
    this.refresh();
  }

  /** Affiche les éléments à ajouter. */
  public deletedList() {
    this.mode = StockMode.Deleted;

    let testSource = new ModelDataSourceContext({
      serviceName: 'InStockMovementCoreService',
      methodName: 'findCreatedRecycleAssets',
    });

    this.source.datasource = CoreGraphQLSource.create({
      context: testSource,
      key: 'id',
      query: (filter?: any, options?: any) => {
        filter = CriteriaHelpers.convertDxFilter(filter);
        return this.InStockMovementCoreService.findCreatedRecycleAssets(
          this.createField(),
          this._route.snapshot.paramMap.get('id'),
          options,
          filter,
        );
      },
      update: (id: string, entry: any) => {
        return of(<ServiceSingleResult<any>>{
          data: [],
          totalCount: 0,
          errors: [],
        });
      },
    });

    setTimeout(() => {
      this.selectedKeys = [];
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource(this.source.datasource.store()),
      });
    });
    this.repaintList(true);
    this.refresh();
  }

  restore() {
    this.loadWait = true;

    this.assetService
      .restore(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.selectedKeys,
      )
      .subscribe((x) => {
        if (x.data === true) {
          notify(TranslateService.get('success'), 'success', 5000);
        } else {
          notify(TranslateService.get('saveError'), 'error', 5000);
        }

        this.loadWait = false;
        this.current();
      });
  }

  deleteAsset(e) {
    this.loadWait = true;

    if (this.mode == 0) {
      this.assetService
        .recycle(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys,
        )
        .subscribe((x) => {
          if (x.data === true) {
            notify(TranslateService.get('success'), 'success', 5000);
          } else {
            notify(TranslateService.get('saveError'), 'error', 5000);
          }

          this.loadWait = false;
          this.current();
        });
    } else if (this.mode == 1) {
      this.assetService
        .delete(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys,
        )
        .subscribe((x) => {
          if (x.data === true) {
            notify(TranslateService.get('success'), 'success', 5000);
          } else {
            notify(TranslateService.get('saveError'), 'error', 5000);
          }

          this.loadWait = false;
          this.current();
        });
    }
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    let id = this.routerActiveted.snapshot.paramMap.get('id');

    /** liste les différents paramètre à intégrer dans l'url */
    let filter: object = {};
    if (id != null) {
      /** Ajoute de l'id dans l'objet filter */
      Object.assign(filter, { type: id });
    }
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);

    if (defaultRoute && this.routeButton != undefined) {
      if (filter != undefined) {
        url = this._router.createUrlTree([this.routeButton], {
          queryParams: filter,
          skipLocationChange: true,
        } as NavigationExtras);
      }
    }
    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  editClick(e) {
    if (!e.event.isDefaultPrevented()) {
      this.goTo(this.selectedKeys[0]);
    }
  }

  exportDataFn(e) {
    (this.exportSource as ModelFnContext).context.params.set('filter', () =>
      this.source.context.params.get('filter'),
    );
    (this.exportSource as ModelFnContext).context.params.set('options', () =>
      this.source.context.params.get('options'),
    );
    return this.exportSource.fnCall();
  }

  onRowClick(e) {
    this.goTo(e.value);
  }

  /** Attention si dans le json le fieldName est toto.titi alors pour le helpers ca sera toto_titi */
  inEditDefaultProperty = [
    { from: 'defaultStorageUnitId', to: 'storageUnitId' },
    { from: 'receivedById', to: 'receivedById' },
    { from: 'financial_budgetId', to: 'budgetId' },
  ];

  /** @inheritdoc */
  ngOnInit() {
    if (this.isOverride) {
      let secondeSource = this.source;
      let sourceContext = new ModelDataSourceContext({
        serviceName: 'InStockMovementCoreService',
        rootState: secondeSource.rootState,
      });

      this.createDataSource(sourceContext);
    }
    let query = (this.source.datasource.store() as GraphQLStore).context.context
      .config.query;
    let defaultQuery = undefined;

    if (this.defaultSource != undefined) {
      defaultQuery = this.defaultSource.store().context.context.config.query;
    }

    if (this.source.datasource instanceof CoreGraphQLDataSource) {
      (
        this.source.datasource.store() as GraphQLStore
      ).context.context.config.query = (...args) => {
        if (this.sharedContext.params.get('id') != undefined) {
          return query.apply(
            (this.source.datasource.store() as GraphQLStore).context.context
              .config,
            args,
          );
        } else {
          if (defaultQuery != undefined) {
            return defaultQuery.apply(
              this.defaultSource.store().context.context.config,
              args,
            );
          } else {
            return of(<ServiceListResult<any>>{
              data: [],
              totalCount: 0,
              errors: [],
            });
          }
        }
      };
    }
    setTimeout(() => {
      this.selectedKeys = [];
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource(this.source.datasource.store()),
      });
    });

    this.importVisibility();
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  writeValue(value: any): void {}
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  public ngAfterViewInit(): void {
    this.importComponent.exportSchemaFn = this.exportDataFn.bind(this);
    this.importComponent.importDataFn = this.importData.bind(this);
  }

  public importData(
    fields: Array<GqlField | GqlSubField>,
    type: ImportFileFormat,
    file?: string,
    extendedVariables?: any,
  ) {
    return this.assetService.importLinkByType(
      fields,
      type,
      'asset-stock',
      file,
      extendedVariables,
    );
  }
  /** Rafraîchi la liste. */
  public refresh() {
    this.source.datasource.reload();
    this.importVisibility();
    this.list.refresh();
  }

  /** check la liste pour la visibilité de l'import */
  public importVisibility() {
    this.source.datasource.load().then((data) => {
      if (data.length > 0) {
        this.canImport = false;
      } else {
        this.canImport = true;
      }
    });
  }

  private createField(): Array<GqlField | GqlSubField> {
    let fields = GqlSubField.create('data', [GqlField.create('id')]);
    this.modelFieldCompilerService.setColumnsFields(
      this.columns,
      fields.fields,
      this.translatedFieldHelperService,
    );
    let field = [
      fields,
      GqlField.create('totalCount'),
      GqlSubField.create('errors', [GqlField.create('messageError')]),
    ];
    return field;
  }

  private repaintList(forceRepaint: boolean = false) {
    this.columns.forEach((element) => {
      if (element?.allowEditing != undefined) {
        element.allowEditing = !forceRepaint;
      }
      let custom = element['customEditing'];

      if (custom != undefined) {
        element['customEditing'] = !forceRepaint;
      }
    });
    this.forceRepaintList = forceRepaint;
  }

  private createDataSource(context: ModelDataSourceContext) {
    this.source.datasource = CoreGraphQLSource.create({
      context: context,
      key: 'id',
      query: (filter?: any, options?: any) => {
        filter = CriteriaHelpers.convertDxFilter(filter);
        let result = this.InStockMovementCoreService.findCreatedAssets(
          this.createField(),
          this._route.snapshot.paramMap.get('id'),
          options,
          filter,
        );
        return result;
      },
      update: (id: string, entry: any) => {
        let isManufacturerIdExist = 'manufacturerId' in entry;
        if (isManufacturerIdExist) {
          entry.modelId = null;
        }
        let entryUpdate = {
          set: entry,
        };

        let message = TranslateService.get('actionSuccess');
        let action = 'success';
        let result = this.assetService.update(
          [
            GqlSubField.create('data', [GqlField.create('id')]),
            GqlSubField.create('errors', [GqlField.create('messageError')]),
          ],
          id,
          entryUpdate,
        );
        result.subscribe((response) => {
          if (response.errors.length > 0) {
            message = response.errors[0].messageError;
            action = 'error';
          }
          notify(`${message}`, `${action}`, 5000);
        });
        return result;
      },
    });
  }

  public openMassiveEditPopUp(e) {
    if (e.itemData.key === 'massiveEdit') {
      this.isMassiveEditVisible = true;
    } else {
      this.isAddWarrantyVisible = true;
    }
  }
}
