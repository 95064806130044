import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAgentConfigurationsBaseVariables, FirstAgentConfigurationsBaseVariables, CountAgentConfigurationsBaseVariables, FindAgentConfigurationsBaseVariables, SearchAgentConfigurationsBaseVariables, ExportSchemaAgentConfigurationsBaseVariables, ExportDataAgentConfigurationsBaseVariables, CustomQueryAgentConfigurationsBaseVariables, CustomQueryIdAgentConfigurationsBaseVariables, UsedAgentConfigurationsBaseVariables, ExistAgentConfigurationsBaseVariables, InsertAgentConfigurationsBaseVariables, UpdateAgentConfigurationsBaseVariables, ImportDataAgentConfigurationsBaseVariables, UpdateManyAgentConfigurationsBaseVariables, DeleteAgentConfigurationsBaseVariables } from '../gqls'
import { GetAgentConfigurationsDocument, FirstAgentConfigurationsDocument, CountAgentConfigurationsDocument, FindAgentConfigurationsDocument, SearchAgentConfigurationsDocument, ExportSchemaAgentConfigurationsDocument, ExportDataAgentConfigurationsDocument, CustomQueryAgentConfigurationsDocument, CustomQueryIdAgentConfigurationsDocument, UsedAgentConfigurationsDocument, ExistAgentConfigurationsDocument, InsertAgentConfigurationsDocument, UpdateAgentConfigurationsDocument, ImportDataAgentConfigurationsDocument, UpdateManyAgentConfigurationsDocument, DeleteAgentConfigurationsDocument } from '../gqls'
import { ServiceSingleResultOfAgentConfiguration, QueryContextOfAgentConfiguration, FilterOfAgentConfiguration, ServiceSingleResultOfInt64, ServiceListResultOfAgentConfiguration, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AgentConfigurationInput, FieldUpdateOperatorOfAgentConfiguration } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AgentConfigurationBaseService {
    
public modelName = 'agentConfiguration';
public modelPluralName = 'agentConfigurations';

	private getAgentConfigurationsQuery: GetAgentConfigurationsDocument;
	private firstAgentConfigurationsQuery: FirstAgentConfigurationsDocument;
	private countAgentConfigurationsQuery: CountAgentConfigurationsDocument;
	private findAgentConfigurationsQuery: FindAgentConfigurationsDocument;
	private searchAgentConfigurationsQuery: SearchAgentConfigurationsDocument;
	private exportSchemaAgentConfigurationsQuery: ExportSchemaAgentConfigurationsDocument;
	private exportDataAgentConfigurationsQuery: ExportDataAgentConfigurationsDocument;
	private customQueryAgentConfigurationsQuery: CustomQueryAgentConfigurationsDocument;
	private customQueryIdAgentConfigurationsQuery: CustomQueryIdAgentConfigurationsDocument;
	private usedAgentConfigurationsQuery: UsedAgentConfigurationsDocument;
	private existAgentConfigurationsQuery: ExistAgentConfigurationsDocument;
	private insertAgentConfigurationsMutation: InsertAgentConfigurationsDocument;
	private updateAgentConfigurationsMutation: UpdateAgentConfigurationsDocument;
	private importDataAgentConfigurationsMutation: ImportDataAgentConfigurationsDocument;
	private updateManyAgentConfigurationsMutation: UpdateManyAgentConfigurationsDocument;
	private deleteAgentConfigurationsMutation: DeleteAgentConfigurationsDocument;

	constructor(private injector: Injector) {
		this.getAgentConfigurationsQuery = this.injector.get(GetAgentConfigurationsDocument);
		this.firstAgentConfigurationsQuery = this.injector.get(FirstAgentConfigurationsDocument);
		this.countAgentConfigurationsQuery = this.injector.get(CountAgentConfigurationsDocument);
		this.findAgentConfigurationsQuery = this.injector.get(FindAgentConfigurationsDocument);
		this.searchAgentConfigurationsQuery = this.injector.get(SearchAgentConfigurationsDocument);
		this.exportSchemaAgentConfigurationsQuery = this.injector.get(ExportSchemaAgentConfigurationsDocument);
		this.exportDataAgentConfigurationsQuery = this.injector.get(ExportDataAgentConfigurationsDocument);
		this.customQueryAgentConfigurationsQuery = this.injector.get(CustomQueryAgentConfigurationsDocument);
		this.customQueryIdAgentConfigurationsQuery = this.injector.get(CustomQueryIdAgentConfigurationsDocument);
		this.usedAgentConfigurationsQuery = this.injector.get(UsedAgentConfigurationsDocument);
		this.existAgentConfigurationsQuery = this.injector.get(ExistAgentConfigurationsDocument);
		this.insertAgentConfigurationsMutation = this.injector.get(InsertAgentConfigurationsDocument);
		this.updateAgentConfigurationsMutation = this.injector.get(UpdateAgentConfigurationsDocument);
		this.importDataAgentConfigurationsMutation = this.injector.get(ImportDataAgentConfigurationsDocument);
		this.updateManyAgentConfigurationsMutation = this.injector.get(UpdateManyAgentConfigurationsDocument);
		this.deleteAgentConfigurationsMutation = this.injector.get(DeleteAgentConfigurationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentConfiguration> {
    
    		let variables: GetAgentConfigurationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.get));
            }
            else{
                let result:ServiceSingleResultOfAgentConfiguration={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentConfiguration,
		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentConfiguration> {
    
    		let variables: FirstAgentConfigurationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAgentConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.countAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentConfiguration,
		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentConfiguration> {
    
    		let variables: FindAgentConfigurationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAgentConfiguration,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentConfiguration> {
    
    		let variables: SearchAgentConfigurationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAgentConfigurationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAgentConfigurationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAgentConfiguration,
		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentConfiguration> {
    
    		let variables: CustomQueryAgentConfigurationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAgentConfiguration,
		@Args('filter?') filter?: FilterOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentConfiguration> {
    
    		let variables: CustomQueryIdAgentConfigurationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAgentConfigurationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAgentConfigurationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAgentConfigurationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AgentConfigurationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentConfiguration> {
    
    		let variables: InsertAgentConfigurationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAgentConfigurationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentConfiguration> {
    
    		let variables: UpdateAgentConfigurationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAgentConfigurationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAgentConfigurationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAgentConfigurationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAgentConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAgentConfigurationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAgentConfigurationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAgentConfigurationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAgentConfigurationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentConfigurations.delete));
    	}
    
}