import {
  Component,
  OnInit,
  HostBinding,
  Input,
  ElementRef,
  OnDestroy,
} from '@angular/core';

/** Représente l'espace de travail d'une édition ou d'une création. */
@Component({
  selector: 'clc-work-item',
  templateUrl: './work-item.component.html',
  styleUrls: ['./work-item.component.scss'],
})
export class CoreWorkItemComponent implements OnDestroy {
  /** Obtient ou définit le message. */
  @Input() hint: string;
  constructor(private element: ElementRef) {
    this.manualScroller = this.manualScroller.bind(this);
  }

  /** @inheritdoc */
  ngAfterContentInit(): void {
    if (this.element?.nativeElement != undefined) {
      this.element.nativeElement.addEventListener('wheel', this.manualScroller);
    }
  }

  ngOnDestroy() {
    this.element.nativeElement.removeEventListener(
      'wheel',
      this.manualScroller,
    );
  }

  manualScroller(args) {
    if (
      this.element?.nativeElement?.children != undefined &&
      this.element?.nativeElement?.children.length > 0
    ) {
      this.element.nativeElement.children[0].scrollBy({
        top: args.wheelDeltaY * -1,
        left: args.wheelDeltaX * -1,
        behavior: 'instant',
      });
    }
  }
}
