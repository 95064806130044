import { StatusWorkflowBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { LogicDiagramView } from '@clarilog/shared2/components/logic-diagram/logic-diagram.component';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-workflow-status')
export class StatusWorkflowCoreService extends StatusWorkflowBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public getService() {
    return this;
  }

  /** Obtient l'affichage de l'edition */
  @InjectArgs
  public getLogicDiagramView(
    @Args('qualification') qualification: string,
  ): LogicDiagramView {
    let result = {
      type: qualification,
      isEnding: true,
      allOperator: true,
      affectedTeam: true,
      operatorAffected: true,
      isTakingCharge: true,
      isEndTreatment: true,
      isCommentaryRequired: true,
      allTaskFinished: true,
      canAddSatisfaction: true,
      isForbiddenPredecessor: false,
      referentTeam: true,
      operatorReferent: true,
      allConcerned: true,
      makeRequest: true,
      affected: true,
      manager: true,
    };
    if (qualification != undefined) {
      if (qualification == 'problem') {
        result = {
          type: qualification,
          isEnding: true,
          allOperator: true,
          affectedTeam: true,
          operatorAffected: true,
          isTakingCharge: false,
          isEndTreatment: false,
          isCommentaryRequired: false,
          allTaskFinished: true,
          canAddSatisfaction: false,
          isForbiddenPredecessor: false,
          referentTeam: true,
          operatorReferent: true,
          allConcerned: false,
          makeRequest: false,
          affected: false,
          manager: false,
        };
      }
    }

    return result;
  }
}
