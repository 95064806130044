import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLayoutModelsBaseVariables, FirstLayoutModelsBaseVariables, CountLayoutModelsBaseVariables, FindLayoutModelsBaseVariables, SearchLayoutModelsBaseVariables, ExportSchemaLayoutModelsBaseVariables, ExportDataLayoutModelsBaseVariables, CustomQueryLayoutModelsBaseVariables, CustomQueryIdLayoutModelsBaseVariables, FindFilesLayoutModelsBaseVariables, UsedLayoutModelsBaseVariables, GetModelLayoutModelsBaseVariables, ExistLayoutModelsBaseVariables, InsertLayoutModelsBaseVariables, UpdateLayoutModelsBaseVariables, ImportDataLayoutModelsBaseVariables, UpdateManyLayoutModelsBaseVariables, DeleteLayoutModelsBaseVariables, UploadChunkFileLayoutModelsBaseVariables, AddFileLinkLayoutModelsBaseVariables, RemoveFileLinkLayoutModelsBaseVariables } from '../gqls'
import { GetLayoutModelsDocument, FirstLayoutModelsDocument, CountLayoutModelsDocument, FindLayoutModelsDocument, SearchLayoutModelsDocument, ExportSchemaLayoutModelsDocument, ExportDataLayoutModelsDocument, CustomQueryLayoutModelsDocument, CustomQueryIdLayoutModelsDocument, FindFilesLayoutModelsDocument, UsedLayoutModelsDocument, GetModelLayoutModelsDocument, ExistLayoutModelsDocument, InsertLayoutModelsDocument, UpdateLayoutModelsDocument, ImportDataLayoutModelsDocument, UpdateManyLayoutModelsDocument, DeleteLayoutModelsDocument, UploadChunkFileLayoutModelsDocument, AddFileLinkLayoutModelsDocument, RemoveFileLinkLayoutModelsDocument } from '../gqls'
import { ServiceSingleResultOfLayoutModel, QueryContextOfLayoutModel, FilterOfLayoutModel, ServiceSingleResultOfInt64, ServiceListResultOfLayoutModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, LayoutModelCategory, LayoutModelInput, FieldUpdateOperatorOfLayoutModel, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LayoutModelBaseService {
    
public modelName = 'layoutModel';
public modelPluralName = 'layoutModels';

	private getLayoutModelsQuery: GetLayoutModelsDocument;
	private firstLayoutModelsQuery: FirstLayoutModelsDocument;
	private countLayoutModelsQuery: CountLayoutModelsDocument;
	private findLayoutModelsQuery: FindLayoutModelsDocument;
	private searchLayoutModelsQuery: SearchLayoutModelsDocument;
	private exportSchemaLayoutModelsQuery: ExportSchemaLayoutModelsDocument;
	private exportDataLayoutModelsQuery: ExportDataLayoutModelsDocument;
	private customQueryLayoutModelsQuery: CustomQueryLayoutModelsDocument;
	private customQueryIdLayoutModelsQuery: CustomQueryIdLayoutModelsDocument;
	private findFilesLayoutModelsQuery: FindFilesLayoutModelsDocument;
	private usedLayoutModelsQuery: UsedLayoutModelsDocument;
	private getModelLayoutModelsQuery: GetModelLayoutModelsDocument;
	private existLayoutModelsQuery: ExistLayoutModelsDocument;
	private insertLayoutModelsMutation: InsertLayoutModelsDocument;
	private updateLayoutModelsMutation: UpdateLayoutModelsDocument;
	private importDataLayoutModelsMutation: ImportDataLayoutModelsDocument;
	private updateManyLayoutModelsMutation: UpdateManyLayoutModelsDocument;
	private deleteLayoutModelsMutation: DeleteLayoutModelsDocument;
	private uploadChunkFileLayoutModelsMutation: UploadChunkFileLayoutModelsDocument;
	private addFileLinkLayoutModelsMutation: AddFileLinkLayoutModelsDocument;
	private removeFileLinkLayoutModelsMutation: RemoveFileLinkLayoutModelsDocument;

	constructor(private injector: Injector) {
		this.getLayoutModelsQuery = this.injector.get(GetLayoutModelsDocument);
		this.firstLayoutModelsQuery = this.injector.get(FirstLayoutModelsDocument);
		this.countLayoutModelsQuery = this.injector.get(CountLayoutModelsDocument);
		this.findLayoutModelsQuery = this.injector.get(FindLayoutModelsDocument);
		this.searchLayoutModelsQuery = this.injector.get(SearchLayoutModelsDocument);
		this.exportSchemaLayoutModelsQuery = this.injector.get(ExportSchemaLayoutModelsDocument);
		this.exportDataLayoutModelsQuery = this.injector.get(ExportDataLayoutModelsDocument);
		this.customQueryLayoutModelsQuery = this.injector.get(CustomQueryLayoutModelsDocument);
		this.customQueryIdLayoutModelsQuery = this.injector.get(CustomQueryIdLayoutModelsDocument);
		this.findFilesLayoutModelsQuery = this.injector.get(FindFilesLayoutModelsDocument);
		this.usedLayoutModelsQuery = this.injector.get(UsedLayoutModelsDocument);
		this.getModelLayoutModelsQuery = this.injector.get(GetModelLayoutModelsDocument);
		this.existLayoutModelsQuery = this.injector.get(ExistLayoutModelsDocument);
		this.insertLayoutModelsMutation = this.injector.get(InsertLayoutModelsDocument);
		this.updateLayoutModelsMutation = this.injector.get(UpdateLayoutModelsDocument);
		this.importDataLayoutModelsMutation = this.injector.get(ImportDataLayoutModelsDocument);
		this.updateManyLayoutModelsMutation = this.injector.get(UpdateManyLayoutModelsDocument);
		this.deleteLayoutModelsMutation = this.injector.get(DeleteLayoutModelsDocument);
		this.uploadChunkFileLayoutModelsMutation = this.injector.get(UploadChunkFileLayoutModelsDocument);
		this.addFileLinkLayoutModelsMutation = this.injector.get(AddFileLinkLayoutModelsDocument);
		this.removeFileLinkLayoutModelsMutation = this.injector.get(RemoveFileLinkLayoutModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLayoutModel> {
    
    		let variables: GetLayoutModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.get));
            }
            else{
                let result:ServiceSingleResultOfLayoutModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLayoutModel,
		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLayoutModel> {
    
    		let variables: FirstLayoutModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLayoutModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLayoutModel,
		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLayoutModel> {
    
    		let variables: FindLayoutModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLayoutModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLayoutModel> {
    
    		let variables: SearchLayoutModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLayoutModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLayoutModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLayoutModel,
		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLayoutModel> {
    
    		let variables: CustomQueryLayoutModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLayoutModel,
		@Args('filter?') filter?: FilterOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLayoutModel> {
    
    		let variables: CustomQueryIdLayoutModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesLayoutModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLayoutModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.used));
    	}

    	/** Récupère le model */
    	@InjectArgs
    	public getModel(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: LayoutModelCategory,
		@Args('entityName?') entityName?: string,
		@Args('entityId?') entityId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: GetModelLayoutModelsBaseVariables = {
    			type: type,
			entityName: entityName,
			entityId: entityId
    		}
    				return this.getModelLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.getModel));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLayoutModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLayoutModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LayoutModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLayoutModel> {
    
    		let variables: InsertLayoutModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLayoutModel> {
    
    		let variables: UpdateLayoutModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLayoutModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLayoutModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLayoutModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLayoutModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('size') size: number,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileLayoutModelsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId
    		}
    				return this.uploadChunkFileLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileLinkLayoutModelsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.addFileLinkLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.addFileLink));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileLinkLayoutModelsBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.removeFileLinkLayoutModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.layoutModels.removeFileLink));
    	}
    
}