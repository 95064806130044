import { Component, Input } from "@angular/core";

/** Représente un composant de gestion des templates. */
@Component({
  selector: "cl-select-template",
  templateUrl: "./select-template.component.html",
  styleUrls: ["./select-template.component.scss"]
})
export class SelectTemplateComponent {
  @Input() title: string;
  constructor() {}
}
