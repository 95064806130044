import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketDailyBacklogsBaseVariables, FirstTicketDailyBacklogsBaseVariables, CountTicketDailyBacklogsBaseVariables, FindTicketDailyBacklogsBaseVariables, SearchTicketDailyBacklogsBaseVariables, ExportSchemaTicketDailyBacklogsBaseVariables, ExportDataTicketDailyBacklogsBaseVariables, CustomQueryTicketDailyBacklogsBaseVariables, CustomQueryIdTicketDailyBacklogsBaseVariables, UsedTicketDailyBacklogsBaseVariables, ExistTicketDailyBacklogsBaseVariables, InsertTicketDailyBacklogsBaseVariables, UpdateTicketDailyBacklogsBaseVariables, ImportDataTicketDailyBacklogsBaseVariables, UpdateManyTicketDailyBacklogsBaseVariables, DeleteTicketDailyBacklogsBaseVariables } from '../gqls'
import { GetTicketDailyBacklogsDocument, FirstTicketDailyBacklogsDocument, CountTicketDailyBacklogsDocument, FindTicketDailyBacklogsDocument, SearchTicketDailyBacklogsDocument, ExportSchemaTicketDailyBacklogsDocument, ExportDataTicketDailyBacklogsDocument, CustomQueryTicketDailyBacklogsDocument, CustomQueryIdTicketDailyBacklogsDocument, UsedTicketDailyBacklogsDocument, ExistTicketDailyBacklogsDocument, InsertTicketDailyBacklogsDocument, UpdateTicketDailyBacklogsDocument, ImportDataTicketDailyBacklogsDocument, UpdateManyTicketDailyBacklogsDocument, DeleteTicketDailyBacklogsDocument } from '../gqls'
import { ServiceSingleResultOfTicketDailyBacklog, QueryContextOfTicketDailyBacklog, FilterOfTicketDailyBacklog, ServiceSingleResultOfInt64, ServiceListResultOfTicketDailyBacklog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketDailyBacklogInput, FieldUpdateOperatorOfTicketDailyBacklog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketDailyBacklogBaseService {
    
public modelName = 'ticketDailyBacklog';
public modelPluralName = 'ticketDailyBacklogs';

	private getTicketDailyBacklogsQuery: GetTicketDailyBacklogsDocument;
	private firstTicketDailyBacklogsQuery: FirstTicketDailyBacklogsDocument;
	private countTicketDailyBacklogsQuery: CountTicketDailyBacklogsDocument;
	private findTicketDailyBacklogsQuery: FindTicketDailyBacklogsDocument;
	private searchTicketDailyBacklogsQuery: SearchTicketDailyBacklogsDocument;
	private exportSchemaTicketDailyBacklogsQuery: ExportSchemaTicketDailyBacklogsDocument;
	private exportDataTicketDailyBacklogsQuery: ExportDataTicketDailyBacklogsDocument;
	private customQueryTicketDailyBacklogsQuery: CustomQueryTicketDailyBacklogsDocument;
	private customQueryIdTicketDailyBacklogsQuery: CustomQueryIdTicketDailyBacklogsDocument;
	private usedTicketDailyBacklogsQuery: UsedTicketDailyBacklogsDocument;
	private existTicketDailyBacklogsQuery: ExistTicketDailyBacklogsDocument;
	private insertTicketDailyBacklogsMutation: InsertTicketDailyBacklogsDocument;
	private updateTicketDailyBacklogsMutation: UpdateTicketDailyBacklogsDocument;
	private importDataTicketDailyBacklogsMutation: ImportDataTicketDailyBacklogsDocument;
	private updateManyTicketDailyBacklogsMutation: UpdateManyTicketDailyBacklogsDocument;
	private deleteTicketDailyBacklogsMutation: DeleteTicketDailyBacklogsDocument;

	constructor(private injector: Injector) {
		this.getTicketDailyBacklogsQuery = this.injector.get(GetTicketDailyBacklogsDocument);
		this.firstTicketDailyBacklogsQuery = this.injector.get(FirstTicketDailyBacklogsDocument);
		this.countTicketDailyBacklogsQuery = this.injector.get(CountTicketDailyBacklogsDocument);
		this.findTicketDailyBacklogsQuery = this.injector.get(FindTicketDailyBacklogsDocument);
		this.searchTicketDailyBacklogsQuery = this.injector.get(SearchTicketDailyBacklogsDocument);
		this.exportSchemaTicketDailyBacklogsQuery = this.injector.get(ExportSchemaTicketDailyBacklogsDocument);
		this.exportDataTicketDailyBacklogsQuery = this.injector.get(ExportDataTicketDailyBacklogsDocument);
		this.customQueryTicketDailyBacklogsQuery = this.injector.get(CustomQueryTicketDailyBacklogsDocument);
		this.customQueryIdTicketDailyBacklogsQuery = this.injector.get(CustomQueryIdTicketDailyBacklogsDocument);
		this.usedTicketDailyBacklogsQuery = this.injector.get(UsedTicketDailyBacklogsDocument);
		this.existTicketDailyBacklogsQuery = this.injector.get(ExistTicketDailyBacklogsDocument);
		this.insertTicketDailyBacklogsMutation = this.injector.get(InsertTicketDailyBacklogsDocument);
		this.updateTicketDailyBacklogsMutation = this.injector.get(UpdateTicketDailyBacklogsDocument);
		this.importDataTicketDailyBacklogsMutation = this.injector.get(ImportDataTicketDailyBacklogsDocument);
		this.updateManyTicketDailyBacklogsMutation = this.injector.get(UpdateManyTicketDailyBacklogsDocument);
		this.deleteTicketDailyBacklogsMutation = this.injector.get(DeleteTicketDailyBacklogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDailyBacklog> {
    
    		let variables: GetTicketDailyBacklogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.get));
            }
            else{
                let result:ServiceSingleResultOfTicketDailyBacklog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketDailyBacklog,
		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDailyBacklog> {
    
    		let variables: FirstTicketDailyBacklogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketDailyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketDailyBacklog,
		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDailyBacklog> {
    
    		let variables: FindTicketDailyBacklogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketDailyBacklog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDailyBacklog> {
    
    		let variables: SearchTicketDailyBacklogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketDailyBacklogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketDailyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketDailyBacklog,
		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDailyBacklog> {
    
    		let variables: CustomQueryTicketDailyBacklogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketDailyBacklog,
		@Args('filter?') filter?: FilterOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDailyBacklog> {
    
    		let variables: CustomQueryIdTicketDailyBacklogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketDailyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketDailyBacklogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketDailyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketDailyBacklogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDailyBacklog> {
    
    		let variables: InsertTicketDailyBacklogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketDailyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDailyBacklog> {
    
    		let variables: UpdateTicketDailyBacklogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketDailyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketDailyBacklogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketDailyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketDailyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketDailyBacklogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketDailyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketDailyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketDailyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDailyBacklogs.delete));
    	}
    
}