<cl-work-page>
  <cl-work-page-header>
    <cl-work-page-header-content style="border-left: 0px !important">
      <cl-work-page-header-content-body>
        <div *ngIf="hint !== undefined" class="cl-hint">{{ hint }}</div>
      </cl-work-page-header-content-body>
    </cl-work-page-header-content>
  </cl-work-page-header>

  <cl-work-page-content>
    <clc-list
      class="list"
      [source]="currentSource"
      [type]="typeMode === undefined ? 'Grid' : typeMode"
      [columns]="columns"
      [keyExpr]="keyExpr"
      [multiple]="true"
      [parentIdExpr]="parentIdExpr"
      [masterDetail]="masterDetail"
      [recursive]="false"
      [(selectedKeys)]="selectedKeys"
      [(selectedData)]="selectedData"
      [canSelect]="canSelect"
      [modelState]="modelState"
      (onRowClick)="onRowClick($event)"
    >
    </clc-list>
  </cl-work-page-content>
</cl-work-page>
