<clc-html-editor
  *ngIf="control.type === 'HtmlEditorTranslatedComponent'"
  [type]="'HtmlEditorComponent'"
  [noForm]="true"
  [control]="control"
  [value]="htmlValue"
  [ngClass]="{
    'htmleditor-full': fullMode,
    'htmleditor-native': !fullMode || fullMode == undefined
  }"
  (focusLost)="lostFocus($event)"
  (valueChanged)="onValueChanged($event)"
  [state]="state"
  [isTranslateField]="true"
  [fg]="fg"
>
</clc-html-editor>

<clc-html-editor
  *ngIf="control.type === 'HtmlEditorSimpleTranslatedComponent'"
  [type]="'HtmlEditorSimpleComponent'"
  [showHtmlCode]="false"
  [noForm]="true"
  [control]="control"
  [value]="htmlValue"
  (focusLost)="lostFocus($event)"
  (valueChanged)="onValueChanged($event)"
  [state]="state"
  [isTranslateField]="true"
  [fg]="fg"
>
</clc-html-editor>
