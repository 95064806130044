import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetStatusWorkflowsBaseVariables, FirstStatusWorkflowsBaseVariables, CountStatusWorkflowsBaseVariables, FindStatusWorkflowsBaseVariables, SearchStatusWorkflowsBaseVariables, ExportSchemaStatusWorkflowsBaseVariables, ExportDataStatusWorkflowsBaseVariables, CustomQueryStatusWorkflowsBaseVariables, CustomQueryIdStatusWorkflowsBaseVariables, UsedStatusWorkflowsBaseVariables, FindNotIncidentModelsStatusWorkflowsBaseVariables, ExistStatusWorkflowsBaseVariables, FindUnassociatedIncidentModelsStatusWorkflowsBaseVariables, InsertStatusWorkflowsBaseVariables, UpdateStatusWorkflowsBaseVariables, ImportDataStatusWorkflowsBaseVariables, UpdateManyStatusWorkflowsBaseVariables, DeleteStatusWorkflowsBaseVariables, DuplicateStatusWorkflowStatusWorkflowsBaseVariables, AddIncidentModelsStatusWorkflowsBaseVariables, RemoveIncidentModelsStatusWorkflowsBaseVariables } from '../gqls'
import { GetStatusWorkflowsDocument, FirstStatusWorkflowsDocument, CountStatusWorkflowsDocument, FindStatusWorkflowsDocument, SearchStatusWorkflowsDocument, ExportSchemaStatusWorkflowsDocument, ExportDataStatusWorkflowsDocument, CustomQueryStatusWorkflowsDocument, CustomQueryIdStatusWorkflowsDocument, UsedStatusWorkflowsDocument, FindNotIncidentModelsStatusWorkflowsDocument, ExistStatusWorkflowsDocument, FindUnassociatedIncidentModelsStatusWorkflowsDocument, InsertStatusWorkflowsDocument, UpdateStatusWorkflowsDocument, ImportDataStatusWorkflowsDocument, UpdateManyStatusWorkflowsDocument, DeleteStatusWorkflowsDocument, DuplicateStatusWorkflowStatusWorkflowsDocument, AddIncidentModelsStatusWorkflowsDocument, RemoveIncidentModelsStatusWorkflowsDocument } from '../gqls'
import { ServiceSingleResultOfStatusWorkflow, QueryContextOfStatusWorkflow, FilterOfStatusWorkflow, ServiceSingleResultOfInt64, ServiceListResultOfStatusWorkflow, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfIncidentModel, QueryContextOfIncidentModel, FilterOfIncidentModel, StatusWorkflowInput, FieldUpdateOperatorOfStatusWorkflow } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class StatusWorkflowBaseService {
    
public modelName = 'statusWorkflow';
public modelPluralName = 'statusWorkflows';

	private getStatusWorkflowsQuery: GetStatusWorkflowsDocument;
	private firstStatusWorkflowsQuery: FirstStatusWorkflowsDocument;
	private countStatusWorkflowsQuery: CountStatusWorkflowsDocument;
	private findStatusWorkflowsQuery: FindStatusWorkflowsDocument;
	private searchStatusWorkflowsQuery: SearchStatusWorkflowsDocument;
	private exportSchemaStatusWorkflowsQuery: ExportSchemaStatusWorkflowsDocument;
	private exportDataStatusWorkflowsQuery: ExportDataStatusWorkflowsDocument;
	private customQueryStatusWorkflowsQuery: CustomQueryStatusWorkflowsDocument;
	private customQueryIdStatusWorkflowsQuery: CustomQueryIdStatusWorkflowsDocument;
	private usedStatusWorkflowsQuery: UsedStatusWorkflowsDocument;
	private findNotIncidentModelsStatusWorkflowsQuery: FindNotIncidentModelsStatusWorkflowsDocument;
	private existStatusWorkflowsQuery: ExistStatusWorkflowsDocument;
	private findUnassociatedIncidentModelsStatusWorkflowsQuery: FindUnassociatedIncidentModelsStatusWorkflowsDocument;
	private insertStatusWorkflowsMutation: InsertStatusWorkflowsDocument;
	private updateStatusWorkflowsMutation: UpdateStatusWorkflowsDocument;
	private importDataStatusWorkflowsMutation: ImportDataStatusWorkflowsDocument;
	private updateManyStatusWorkflowsMutation: UpdateManyStatusWorkflowsDocument;
	private deleteStatusWorkflowsMutation: DeleteStatusWorkflowsDocument;
	private duplicateStatusWorkflowStatusWorkflowsMutation: DuplicateStatusWorkflowStatusWorkflowsDocument;
	private addIncidentModelsStatusWorkflowsMutation: AddIncidentModelsStatusWorkflowsDocument;
	private removeIncidentModelsStatusWorkflowsMutation: RemoveIncidentModelsStatusWorkflowsDocument;

	constructor(private injector: Injector) {
		this.getStatusWorkflowsQuery = this.injector.get(GetStatusWorkflowsDocument);
		this.firstStatusWorkflowsQuery = this.injector.get(FirstStatusWorkflowsDocument);
		this.countStatusWorkflowsQuery = this.injector.get(CountStatusWorkflowsDocument);
		this.findStatusWorkflowsQuery = this.injector.get(FindStatusWorkflowsDocument);
		this.searchStatusWorkflowsQuery = this.injector.get(SearchStatusWorkflowsDocument);
		this.exportSchemaStatusWorkflowsQuery = this.injector.get(ExportSchemaStatusWorkflowsDocument);
		this.exportDataStatusWorkflowsQuery = this.injector.get(ExportDataStatusWorkflowsDocument);
		this.customQueryStatusWorkflowsQuery = this.injector.get(CustomQueryStatusWorkflowsDocument);
		this.customQueryIdStatusWorkflowsQuery = this.injector.get(CustomQueryIdStatusWorkflowsDocument);
		this.usedStatusWorkflowsQuery = this.injector.get(UsedStatusWorkflowsDocument);
		this.findNotIncidentModelsStatusWorkflowsQuery = this.injector.get(FindNotIncidentModelsStatusWorkflowsDocument);
		this.existStatusWorkflowsQuery = this.injector.get(ExistStatusWorkflowsDocument);
		this.findUnassociatedIncidentModelsStatusWorkflowsQuery = this.injector.get(FindUnassociatedIncidentModelsStatusWorkflowsDocument);
		this.insertStatusWorkflowsMutation = this.injector.get(InsertStatusWorkflowsDocument);
		this.updateStatusWorkflowsMutation = this.injector.get(UpdateStatusWorkflowsDocument);
		this.importDataStatusWorkflowsMutation = this.injector.get(ImportDataStatusWorkflowsDocument);
		this.updateManyStatusWorkflowsMutation = this.injector.get(UpdateManyStatusWorkflowsDocument);
		this.deleteStatusWorkflowsMutation = this.injector.get(DeleteStatusWorkflowsDocument);
		this.duplicateStatusWorkflowStatusWorkflowsMutation = this.injector.get(DuplicateStatusWorkflowStatusWorkflowsDocument);
		this.addIncidentModelsStatusWorkflowsMutation = this.injector.get(AddIncidentModelsStatusWorkflowsDocument);
		this.removeIncidentModelsStatusWorkflowsMutation = this.injector.get(RemoveIncidentModelsStatusWorkflowsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatusWorkflow> {
    
    		let variables: GetStatusWorkflowsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.get));
            }
            else{
                let result:ServiceSingleResultOfStatusWorkflow={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStatusWorkflow,
		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatusWorkflow> {
    
    		let variables: FirstStatusWorkflowsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountStatusWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.countStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfStatusWorkflow,
		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatusWorkflow> {
    
    		let variables: FindStatusWorkflowsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfStatusWorkflow,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatusWorkflow> {
    
    		let variables: SearchStatusWorkflowsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaStatusWorkflowsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataStatusWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfStatusWorkflow,
		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatusWorkflow> {
    
    		let variables: CustomQueryStatusWorkflowsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfStatusWorkflow,
		@Args('filter?') filter?: FilterOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfStatusWorkflow> {
    
    		let variables: CustomQueryIdStatusWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedStatusWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.usedStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('qualification?') qualification?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindNotIncidentModelsStatusWorkflowsBaseVariables = {
    			id: id,
			qualification: qualification,
			filter: filter,
			options: options
    		}
    				return this.findNotIncidentModelsStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.findNotIncidentModels));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistStatusWorkflowsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.exist));
    	}

	@InjectArgs
	public findAssociatedIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('incidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfIncidentModels'] = filter;
		extendedVariables['optionsOfIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.incidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedIncidentModelsStatusWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedIncidentModelsStatusWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.findUnassociatedIncidentModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: StatusWorkflowInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatusWorkflow> {
    
    		let variables: InsertStatusWorkflowsBaseVariables = {
    			entity: entity
    		}
    				return this.insertStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfStatusWorkflow> {
    
    		let variables: UpdateStatusWorkflowsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataStatusWorkflowsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfStatusWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyStatusWorkflowsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteStatusWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.delete));
    	}

    	/**  */
    	@InjectArgs
    	public duplicateStatusWorkflow(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateStatusWorkflowStatusWorkflowsBaseVariables = {
    			id: id
    		}
    				return this.duplicateStatusWorkflowStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.duplicateStatusWorkflow));
    	}

    	/**  */
    	@InjectArgs
    	public addIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddIncidentModelsStatusWorkflowsBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addIncidentModelsStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.addIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveIncidentModelsStatusWorkflowsBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeIncidentModelsStatusWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.statusWorkflows.removeIncidentModels));
    	}
    
}