import { Component, OnInit } from '@angular/core';

/** Représente le contenu de l'en-tête du composent WorkPage. */
@Component({
  selector: 'cl-work-page-header-content',
  templateUrl: './work-page-header-content.component.html',
  styleUrls: ['./work-page-header-content.component.scss']
})
export class WorkPageHeaderContentComponent {

  constructor() { }
}
