import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetModelsBaseVariables, FirstAssetModelsBaseVariables, CountAssetModelsBaseVariables, FindAssetModelsBaseVariables, SearchAssetModelsBaseVariables, ExportSchemaAssetModelsBaseVariables, ExportDataAssetModelsBaseVariables, CustomQueryAssetModelsBaseVariables, CustomQueryIdAssetModelsBaseVariables, UsedAssetModelsBaseVariables, FindByCategoryAssetModelsBaseVariables, FindByStorageUnitEntriesAssetModelsBaseVariables, FindAllStorageAssetModelsBaseVariables, FindStorageAssetModelsBaseVariables, FindStorageAssetModelItemsAssetModelsBaseVariables, FindAssetsModelsInStockAssetModelsBaseVariables, FindConsumableModelManufacturerAssetModelsBaseVariables, ExistAssetModelsBaseVariables, FindRecyclesAssetModelsBaseVariables, CountRecyclesAssetModelsBaseVariables, ReadOnlyAssetModelsBaseVariables, FindArchivedAssetModelsBaseVariables, CountAllAssetModelsBaseVariables, FindDynamicPropertyFieldsAssetModelsBaseVariables, FindUnassociatedSuppliersAssetModelsBaseVariables, FindUnassociatedAssetsAssetModelsBaseVariables, FindUnassociatedAlertsAssetModelsBaseVariables, FindUnassociatedAssetModelStockEntriesAssetModelsBaseVariables, InsertAssetModelsBaseVariables, UpdateAssetModelsBaseVariables, ImportDataAssetModelsBaseVariables, UpdateManyAssetModelsBaseVariables, DeleteAssetModelsBaseVariables, RestoreAssetModelsBaseVariables, RecycleAssetModelsBaseVariables, RestoreArchivedAssetModelsBaseVariables, ArchivedAssetModelsBaseVariables, AddFileDynamicFieldAssetModelsBaseVariables, RemoveFileDynamicFieldAssetModelsBaseVariables, AddSuppliersAssetModelsBaseVariables, RemoveSuppliersAssetModelsBaseVariables, AddAlertsAssetModelsBaseVariables, RemoveAlertsAssetModelsBaseVariables, AddAssetsAssetModelsBaseVariables, RemoveAssetsAssetModelsBaseVariables, AddAssetModelStockEntriesAssetModelsBaseVariables, RemoveAssetModelStockEntriesAssetModelsBaseVariables } from '../gqls'
import { GetAssetModelsDocument, FirstAssetModelsDocument, CountAssetModelsDocument, FindAssetModelsDocument, SearchAssetModelsDocument, ExportSchemaAssetModelsDocument, ExportDataAssetModelsDocument, CustomQueryAssetModelsDocument, CustomQueryIdAssetModelsDocument, UsedAssetModelsDocument, FindByCategoryAssetModelsDocument, FindByStorageUnitEntriesAssetModelsDocument, FindAllStorageAssetModelsDocument, FindStorageAssetModelsDocument, FindStorageAssetModelItemsAssetModelsDocument, FindAssetsModelsInStockAssetModelsDocument, FindConsumableModelManufacturerAssetModelsDocument, ExistAssetModelsDocument, FindRecyclesAssetModelsDocument, CountRecyclesAssetModelsDocument, ReadOnlyAssetModelsDocument, FindArchivedAssetModelsDocument, CountAllAssetModelsDocument, FindDynamicPropertyFieldsAssetModelsDocument, FindUnassociatedSuppliersAssetModelsDocument, FindUnassociatedAssetsAssetModelsDocument, FindUnassociatedAlertsAssetModelsDocument, FindUnassociatedAssetModelStockEntriesAssetModelsDocument, InsertAssetModelsDocument, UpdateAssetModelsDocument, ImportDataAssetModelsDocument, UpdateManyAssetModelsDocument, DeleteAssetModelsDocument, RestoreAssetModelsDocument, RecycleAssetModelsDocument, RestoreArchivedAssetModelsDocument, ArchivedAssetModelsDocument, AddFileDynamicFieldAssetModelsDocument, RemoveFileDynamicFieldAssetModelsDocument, AddSuppliersAssetModelsDocument, RemoveSuppliersAssetModelsDocument, AddAlertsAssetModelsDocument, RemoveAlertsAssetModelsDocument, AddAssetsAssetModelsDocument, RemoveAssetsAssetModelsDocument, AddAssetModelStockEntriesAssetModelsDocument, RemoveAssetModelStockEntriesAssetModelsDocument } from '../gqls'
import { ServiceSingleResultOfAssetModel, QueryContextOfAssetModel, FilterOfAssetModel, ServiceSingleResultOfInt64, ServiceListResultOfAssetModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetModelCategory, ServiceListResultOfAssetModelStockStorageUnit, QueryContextOfAssetModelStockStorageUnit, FilterOfAssetModelStockStorageUnit, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAssetModel, QueryContextOfSupplier, FilterOfSupplier, ServiceListResultOfSupplier, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, QueryContextOfAssetModelStockEntry, FilterOfAssetModelStockEntry, ServiceListResultOfAssetModelStockEntry, AssetModelInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetModelBaseService {
    
public modelName = 'assetModel';
public modelPluralName = 'assetModels';

	private getAssetModelsQuery: GetAssetModelsDocument;
	private firstAssetModelsQuery: FirstAssetModelsDocument;
	private countAssetModelsQuery: CountAssetModelsDocument;
	private findAssetModelsQuery: FindAssetModelsDocument;
	private searchAssetModelsQuery: SearchAssetModelsDocument;
	private exportSchemaAssetModelsQuery: ExportSchemaAssetModelsDocument;
	private exportDataAssetModelsQuery: ExportDataAssetModelsDocument;
	private customQueryAssetModelsQuery: CustomQueryAssetModelsDocument;
	private customQueryIdAssetModelsQuery: CustomQueryIdAssetModelsDocument;
	private usedAssetModelsQuery: UsedAssetModelsDocument;
	private findByCategoryAssetModelsQuery: FindByCategoryAssetModelsDocument;
	private findByStorageUnitEntriesAssetModelsQuery: FindByStorageUnitEntriesAssetModelsDocument;
	private findAllStorageAssetModelsQuery: FindAllStorageAssetModelsDocument;
	private findStorageAssetModelsQuery: FindStorageAssetModelsDocument;
	private findStorageAssetModelItemsAssetModelsQuery: FindStorageAssetModelItemsAssetModelsDocument;
	private findAssetsModelsInStockAssetModelsQuery: FindAssetsModelsInStockAssetModelsDocument;
	private findConsumableModelManufacturerAssetModelsQuery: FindConsumableModelManufacturerAssetModelsDocument;
	private existAssetModelsQuery: ExistAssetModelsDocument;
	private findRecyclesAssetModelsQuery: FindRecyclesAssetModelsDocument;
	private countRecyclesAssetModelsQuery: CountRecyclesAssetModelsDocument;
	private readOnlyAssetModelsQuery: ReadOnlyAssetModelsDocument;
	private findArchivedAssetModelsQuery: FindArchivedAssetModelsDocument;
	private countAllAssetModelsQuery: CountAllAssetModelsDocument;
	private findDynamicPropertyFieldsAssetModelsQuery: FindDynamicPropertyFieldsAssetModelsDocument;
	private findUnassociatedSuppliersAssetModelsQuery: FindUnassociatedSuppliersAssetModelsDocument;
	private findUnassociatedAssetsAssetModelsQuery: FindUnassociatedAssetsAssetModelsDocument;
	private findUnassociatedAlertsAssetModelsQuery: FindUnassociatedAlertsAssetModelsDocument;
	private findUnassociatedAssetModelStockEntriesAssetModelsQuery: FindUnassociatedAssetModelStockEntriesAssetModelsDocument;
	private insertAssetModelsMutation: InsertAssetModelsDocument;
	private updateAssetModelsMutation: UpdateAssetModelsDocument;
	private importDataAssetModelsMutation: ImportDataAssetModelsDocument;
	private updateManyAssetModelsMutation: UpdateManyAssetModelsDocument;
	private deleteAssetModelsMutation: DeleteAssetModelsDocument;
	private restoreAssetModelsMutation: RestoreAssetModelsDocument;
	private recycleAssetModelsMutation: RecycleAssetModelsDocument;
	private restoreArchivedAssetModelsMutation: RestoreArchivedAssetModelsDocument;
	private archivedAssetModelsMutation: ArchivedAssetModelsDocument;
	private addFileDynamicFieldAssetModelsMutation: AddFileDynamicFieldAssetModelsDocument;
	private removeFileDynamicFieldAssetModelsMutation: RemoveFileDynamicFieldAssetModelsDocument;
	private addSuppliersAssetModelsMutation: AddSuppliersAssetModelsDocument;
	private removeSuppliersAssetModelsMutation: RemoveSuppliersAssetModelsDocument;
	private addAlertsAssetModelsMutation: AddAlertsAssetModelsDocument;
	private removeAlertsAssetModelsMutation: RemoveAlertsAssetModelsDocument;
	private addAssetsAssetModelsMutation: AddAssetsAssetModelsDocument;
	private removeAssetsAssetModelsMutation: RemoveAssetsAssetModelsDocument;
	private addAssetModelStockEntriesAssetModelsMutation: AddAssetModelStockEntriesAssetModelsDocument;
	private removeAssetModelStockEntriesAssetModelsMutation: RemoveAssetModelStockEntriesAssetModelsDocument;

	constructor(private injector: Injector) {
		this.getAssetModelsQuery = this.injector.get(GetAssetModelsDocument);
		this.firstAssetModelsQuery = this.injector.get(FirstAssetModelsDocument);
		this.countAssetModelsQuery = this.injector.get(CountAssetModelsDocument);
		this.findAssetModelsQuery = this.injector.get(FindAssetModelsDocument);
		this.searchAssetModelsQuery = this.injector.get(SearchAssetModelsDocument);
		this.exportSchemaAssetModelsQuery = this.injector.get(ExportSchemaAssetModelsDocument);
		this.exportDataAssetModelsQuery = this.injector.get(ExportDataAssetModelsDocument);
		this.customQueryAssetModelsQuery = this.injector.get(CustomQueryAssetModelsDocument);
		this.customQueryIdAssetModelsQuery = this.injector.get(CustomQueryIdAssetModelsDocument);
		this.usedAssetModelsQuery = this.injector.get(UsedAssetModelsDocument);
		this.findByCategoryAssetModelsQuery = this.injector.get(FindByCategoryAssetModelsDocument);
		this.findByStorageUnitEntriesAssetModelsQuery = this.injector.get(FindByStorageUnitEntriesAssetModelsDocument);
		this.findAllStorageAssetModelsQuery = this.injector.get(FindAllStorageAssetModelsDocument);
		this.findStorageAssetModelsQuery = this.injector.get(FindStorageAssetModelsDocument);
		this.findStorageAssetModelItemsAssetModelsQuery = this.injector.get(FindStorageAssetModelItemsAssetModelsDocument);
		this.findAssetsModelsInStockAssetModelsQuery = this.injector.get(FindAssetsModelsInStockAssetModelsDocument);
		this.findConsumableModelManufacturerAssetModelsQuery = this.injector.get(FindConsumableModelManufacturerAssetModelsDocument);
		this.existAssetModelsQuery = this.injector.get(ExistAssetModelsDocument);
		this.findRecyclesAssetModelsQuery = this.injector.get(FindRecyclesAssetModelsDocument);
		this.countRecyclesAssetModelsQuery = this.injector.get(CountRecyclesAssetModelsDocument);
		this.readOnlyAssetModelsQuery = this.injector.get(ReadOnlyAssetModelsDocument);
		this.findArchivedAssetModelsQuery = this.injector.get(FindArchivedAssetModelsDocument);
		this.countAllAssetModelsQuery = this.injector.get(CountAllAssetModelsDocument);
		this.findDynamicPropertyFieldsAssetModelsQuery = this.injector.get(FindDynamicPropertyFieldsAssetModelsDocument);
		this.findUnassociatedSuppliersAssetModelsQuery = this.injector.get(FindUnassociatedSuppliersAssetModelsDocument);
		this.findUnassociatedAssetsAssetModelsQuery = this.injector.get(FindUnassociatedAssetsAssetModelsDocument);
		this.findUnassociatedAlertsAssetModelsQuery = this.injector.get(FindUnassociatedAlertsAssetModelsDocument);
		this.findUnassociatedAssetModelStockEntriesAssetModelsQuery = this.injector.get(FindUnassociatedAssetModelStockEntriesAssetModelsDocument);
		this.insertAssetModelsMutation = this.injector.get(InsertAssetModelsDocument);
		this.updateAssetModelsMutation = this.injector.get(UpdateAssetModelsDocument);
		this.importDataAssetModelsMutation = this.injector.get(ImportDataAssetModelsDocument);
		this.updateManyAssetModelsMutation = this.injector.get(UpdateManyAssetModelsDocument);
		this.deleteAssetModelsMutation = this.injector.get(DeleteAssetModelsDocument);
		this.restoreAssetModelsMutation = this.injector.get(RestoreAssetModelsDocument);
		this.recycleAssetModelsMutation = this.injector.get(RecycleAssetModelsDocument);
		this.restoreArchivedAssetModelsMutation = this.injector.get(RestoreArchivedAssetModelsDocument);
		this.archivedAssetModelsMutation = this.injector.get(ArchivedAssetModelsDocument);
		this.addFileDynamicFieldAssetModelsMutation = this.injector.get(AddFileDynamicFieldAssetModelsDocument);
		this.removeFileDynamicFieldAssetModelsMutation = this.injector.get(RemoveFileDynamicFieldAssetModelsDocument);
		this.addSuppliersAssetModelsMutation = this.injector.get(AddSuppliersAssetModelsDocument);
		this.removeSuppliersAssetModelsMutation = this.injector.get(RemoveSuppliersAssetModelsDocument);
		this.addAlertsAssetModelsMutation = this.injector.get(AddAlertsAssetModelsDocument);
		this.removeAlertsAssetModelsMutation = this.injector.get(RemoveAlertsAssetModelsDocument);
		this.addAssetsAssetModelsMutation = this.injector.get(AddAssetsAssetModelsDocument);
		this.removeAssetsAssetModelsMutation = this.injector.get(RemoveAssetsAssetModelsDocument);
		this.addAssetModelStockEntriesAssetModelsMutation = this.injector.get(AddAssetModelStockEntriesAssetModelsDocument);
		this.removeAssetModelStockEntriesAssetModelsMutation = this.injector.get(RemoveAssetModelStockEntriesAssetModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModel> {
    
    		let variables: GetAssetModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.get));
            }
            else{
                let result:ServiceSingleResultOfAssetModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModel> {
    
    		let variables: FirstAssetModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindAssetModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: SearchAssetModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: CustomQueryAssetModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: CustomQueryIdAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByCategory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('category') category: AssetModelCategory,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindByCategoryAssetModelsBaseVariables = {
    			category: category,
			filter: filter,
			options: options
    		}
    				return this.findByCategoryAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findByCategory));
    	}

    	/**  */
    	@InjectArgs
    	public findByStorageUnitEntries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('storageUnitIds') storageUnitIds: Array<string>,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindByStorageUnitEntriesAssetModelsBaseVariables = {
    			storageUnitIds: storageUnitIds,
			filter: filter,
			options: options
    		}
    				return this.findByStorageUnitEntriesAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findByStorageUnitEntries));
    	}

    	/**  */
    	@InjectArgs
    	public findAllStorage(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetModelStockStorageUnit,
		@Args('filter?') filter?: FilterOfAssetModelStockStorageUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockStorageUnit> {
    
    		let variables: FindAllStorageAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllStorageAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findAllStorage));
    	}

    	/**  */
    	@InjectArgs
    	public findStorage(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetModelStockStorageUnit,
		@Args('filter?') filter?: FilterOfAssetModelStockStorageUnit,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockStorageUnit> {
    
    		let variables: FindStorageAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findStorageAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findStorage));
    	}

    	/**  */
    	@InjectArgs
    	public findStorageAssetModelItems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('storageUnitIds') storageUnitIds: Array<string>,
		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindStorageAssetModelItemsAssetModelsBaseVariables = {
    			storageUnitIds: storageUnitIds,
			filter: filter,
			options: options
    		}
    				return this.findStorageAssetModelItemsAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findStorageAssetModelItems));
    	}

    	/**  */
    	@InjectArgs
    	public findAssetsModelsInStock(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindAssetsModelsInStockAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssetsModelsInStockAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findAssetsModelsInStock));
    	}

    	/** Obtient la liste des références de type consommable  */
    	@InjectArgs
    	public findConsumableModelManufacturer(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindConsumableModelManufacturerAssetModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findConsumableModelManufacturerAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findConsumableModelManufacturer));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindRecyclesAssetModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAssetModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAssetModelsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModel,
		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModel> {
    
    		let variables: FindArchivedAssetModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAssetModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAssetModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedSuppliers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSupplier,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSupplier,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSupplier> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('suppliers', fields, null, [
			GqlSubFieldArg.create('filterOfSuppliers', 'filter'),
			GqlSubFieldArg.create('optionsOfSuppliers', 'options'),
		]),
		])
		extendedVariables['filterOfSuppliers'] = filter;
		extendedVariables['optionsOfSuppliers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.suppliers));
            }
            else{
                let result: ServiceListResultOfSupplier = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSupplier,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSupplier,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSupplier> {
    
    		let variables: FindUnassociatedSuppliersAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSuppliersAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findUnassociatedSuppliers));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findUnassociatedAssets));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findUnassociatedAlerts));
    	}

	@InjectArgs
	public findAssociatedAssetModelStockEntries(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAssetModelStockEntry> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assetModelStockEntries', fields, null, [
			GqlSubFieldArg.create('filterOfAssetModelStockEntries', 'filter'),
			GqlSubFieldArg.create('optionsOfAssetModelStockEntries', 'options'),
		]),
		])
		extendedVariables['filterOfAssetModelStockEntries'] = filter;
		extendedVariables['optionsOfAssetModelStockEntries'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assetModelStockEntries));
            }
            else{
                let result: ServiceListResultOfAssetModelStockEntry = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssetModelStockEntries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetModelStockEntry,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAssetModelStockEntry,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetModelStockEntry> {
    
    		let variables: FindUnassociatedAssetModelStockEntriesAssetModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetModelStockEntriesAssetModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.findUnassociatedAssetModelStockEntries));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModel> {
    
    		let variables: InsertAssetModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetModel> {
    
    		let variables: UpdateAssetModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAssetModelsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAssetModelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAssetModelsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('supplierIds') supplierIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSuppliersAssetModelsBaseVariables = {
    			id: id,
			supplierIds: supplierIds
    		}
    				return this.addSuppliersAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.addSuppliers));
    	}

    	/**  */
    	@InjectArgs
    	public removeSuppliers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('supplierIds') supplierIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSuppliersAssetModelsBaseVariables = {
    			id: id,
			supplierIds: supplierIds
    		}
    				return this.removeSuppliersAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.removeSuppliers));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsAssetModelsBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsAssetModelsBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.removeAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetModelsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetModelsBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.removeAssets));
    	}

    	/**  */
    	@InjectArgs
    	public addAssetModelStockEntries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetModelStockEntryIds') assetModelStockEntryIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetModelStockEntriesAssetModelsBaseVariables = {
    			id: id,
			assetModelStockEntryIds: assetModelStockEntryIds
    		}
    				return this.addAssetModelStockEntriesAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.addAssetModelStockEntries));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssetModelStockEntries(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetModelStockEntryIds') assetModelStockEntryIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetModelStockEntriesAssetModelsBaseVariables = {
    			assetModelStockEntryIds: assetModelStockEntryIds
    		}
    				return this.removeAssetModelStockEntriesAssetModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetModels.removeAssetModelStockEntries));
    	}
    
}