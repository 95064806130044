import { NotificationBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Notification,
  NotificationKey,
  ServiceSingleResultOfBoolean,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { Router, UrlSerializer } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreManageListComponent, ModelFieldCompilerService } from '@clarilog/shared2';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';

@Injectable({ providedIn: 'root' })
export class NotificationCoreService extends NotificationBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  changeRead(readKey: any, selectedKeys: string[], manageList: CoreManageListComponent, modelState: ModelState, generalState: ModelState = undefined) {
    let isRead = readKey !== 'read';
    let subscribeResult: Observable<ServiceSingleResultOfBoolean>;

    if (manageList != undefined && isRead != undefined) {
      if (isRead === false) {
        subscribeResult = this.multipleMarkAsRead(ModelFieldCompilerService.createServiceSingleResultScalar(), selectedKeys);
      } else {
        subscribeResult = this.multipleMarkAsUnRead(ModelFieldCompilerService.createServiceSingleResultScalar(), selectedKeys);
      }

      if (subscribeResult != undefined) {
        modelState.gc.forDispose(subscribeResult.subscribe(_ => {
          manageList.refresh();
          if (generalState != undefined) {
            generalState.on.emit({ eventName: 'refresh-notification', eventData: null })

          }
        }));
      }
    }
  }

  manageNotificationOpening(router: Router, notification: Notification, serializer: UrlSerializer, retValue = false) {
    if (
      notification.urlKey == NotificationKey.MessageIncident ||
      notification.urlKey == NotificationKey.MessageIncidentTeam
    ) {
      if (
        router.url.search('incidents') ||
        router.url.search('requests')
      ) {
        let url = router.createUrlTree(
          [`/incidents/edit/` + notification.urlObjectId],
          { queryParams: { navigateTo: 'communication' } },
        );
        if (retValue === true) {
          return serializer.serialize(url);
        }
        window.open(serializer.serialize(url), '_blank');
      } else {
        if (retValue === true) {
          return `/incidents/edit/` + notification.urlObjectId + '?navigateTo=communication';
        }
        router.navigate([`/incidents/edit/` + notification.urlObjectId], {
          queryParams: { navigateTo: 'communication' },
        });
      }

      //Solution si on ne veut plus l'ourverture d'un nouvel onglet
      //   this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      //     this.router.navigate([`/incidents/edit/` + notification.urlObjectId], {
      //       queryParams: { navigateTo: 'communication' },
      //     });
      // });
    }

    if (
      notification.urlKey == NotificationKey.MessageRequest ||
      notification.urlKey == NotificationKey.MessageRequestTeam
    ) {
      if (
        router.url.search('incidents') ||
        router.url.search('requests')
      ) {
        let url = router.createUrlTree(
          [`/requests/edit/` + notification.urlObjectId],
          { queryParams: { navigateTo: 'communication' } },
        );
        if (retValue === true) {
          return serializer.serialize(url);
        }
        window.open(serializer.serialize(url), '_blank');
      } else {
        if (retValue === true) {
          return `/requests/edit/` + notification.urlObjectId + '?navigateTo=communication';
        }
        router.navigate([`/requests/edit/` + notification.urlObjectId], {
          queryParams: { navigateTo: 'communication' },
        });
      }
    }

    if (
      notification.urlKey == NotificationKey.EmailIncident ||
      notification.urlKey == NotificationKey.EmailIncidentTeam
    ) {
      if (
        router.url.search('incidents') ||
        router.url.search('requests')
      ) {
        let url = router.createUrlTree(
          [`/incidents/edit/` + notification.urlObjectId],
          {
            queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
          },
        );
        if (retValue === true) {
          return serializer.serialize(url);
        }
        window.open(serializer.serialize(url), '_blank');
      } else {
        if (retValue === true) {
          return `/incidents/edit/` + notification.urlObjectId + '?navigateTo=communication&tabs=ticketEmails';
        }
        router.navigate([`/incidents/edit/` + notification.urlObjectId], {
          queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
        });
      }
    }

    if (
      notification.urlKey == NotificationKey.EmailRequest ||
      notification.urlKey == NotificationKey.EmailRequestTeam
    ) {
      if (
        router.url.search('incidents') ||
        router.url.search('requests')
      ) {
        let url = router.createUrlTree(
          [`/requests/edit/` + notification.urlObjectId],
          {
            queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
          },
        );
        if (retValue === true) {
          return serializer.serialize(url);
        }
        window.open(serializer.serialize(url), '_blank');
      } else {
        if (retValue === true) {
          return `/requests/edit/` + notification.urlObjectId + '?navigateTo=communication&tabs=ticketEmails';
        }
        router.navigate([`/requests/edit/` + notification.urlObjectId], {
          queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
        });
      }
    }
    if (
      notification.urlKey == NotificationKey.EmailProblem ||
      notification.urlKey == NotificationKey.EmailProblemTeam
    ) {
      if (
        router.url.search('problems')
      ) {
        let url = router.createUrlTree(
          [`/problems/edit/` + notification.urlObjectId],
          {
            queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
          },
        );
        if (retValue === true) {
          return serializer.serialize(url);
        }
        window.open(serializer.serialize(url), '_blank');
      } else {
        if (retValue === true) {
          return `/problems/edit/` + notification.urlObjectId + '?navigateTo=communication&tabs=ticketEmails';
        }
        router.navigate([`/problems/edit/` + notification.urlObjectId], {
          queryParams: { navigateTo: 'communication', tabs: 'ticketEmails' },
        });
      }
    }
  }
}
