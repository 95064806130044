import { Injectable, Type } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators';
import { CoreHasPoliciesDirectiveParams } from '@clarilog/shared2/directives/has-policies.directive';
import { Mutation, Query } from 'apollo-angular';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import {
  EntityAttributesType,
  GetTypeFieldsGQL,
  QueryFilterInputType,
  QueryOptionsInputType,
  ValidationErrorType
} from './graphql-types';

export interface DefaultServiceArgument {
  filters?: QueryFilterInputType[],
  options?: QueryOptionsInputType,
}
export type ServiceListResult<TType> = {
  data?: TType[];
  totalCount?: number;
  errors?: ValidationErrorType[];
};
export type ServiceSingleResult<TType> = {
  data?: TType;
  errors?: ValidationErrorType[];
};
export type ServiceResult<TType> = {
  data: TType;
  errors: ValidationErrorType[];
  isValid: boolean;
};

const policiesSymbolKey = Symbol.for('cl:policies');

export function Authorize<T extends { new(...args: any[]): void }>(
  policy: any,
) {  
    return function (target: T) 
    {
      if(typeof policy == 'string')
      {
        let policies: string[] = Reflect.getMetadata(policiesSymbolKey, target,target.name);
        if (policies == undefined) {
          policies = [policy];
        } else {
          policies.push(policy);
        }
        Reflect.defineMetadata(policiesSymbolKey, policies, target,target.name);
      }
      else{
        Reflect.defineMetadata(policiesSymbolKey, policy, target,target.name);
      }     
    };
  
}

@Injectable({
  providedIn: 'root',
})
export class ServicePermission {
  getAuthorizations(target: Type<any>): string[] {
    let policies: string[] = Reflect.getMetadata(
      policiesSymbolKey,
      target.constructor,
      target.constructor.name
    );
    // if (policies == undefined) {
    //   throw new Error(`Le service '${target.constructor.name}' doit posséder un décorateur : @Authorize('scope').`);
    // }
    return policies;
  }
}

@Injectable({
  providedIn: 'root',
})
export class ServiceUtils {
  constructor(private getTypeFieldsQuery: GetTypeFieldsGQL) { }

  getTypeFields(type: string): Observable<any> {
    const variables = {
      type: type,
    };
    const query = this.getTypeFieldsQuery.fetch(variables);
    let res = query.pipe(map(result => result.data.__type.fields));
    return res;
  }
}

export interface IService<TType, TInputType> {
  /** Permet de rechercher des éléments en fonction de filtres et d'options. */
  find(filters?: QueryFilterInputType[], options?: QueryOptionsInputType): Observable<any>;
  /** Permet de compter le nombre d'élément en fonction de filtres. */
  count(
    filters?: QueryFilterInputType[],
  ): Observable<ServiceSingleResult<number>>;
  /** Récupère un élément. */
  get(id: string): Observable<ServiceSingleResult<TType>>;
  /** Met à jour un élément. */
  update(id: string, entry: TInputType): Observable<ServiceResult<TType>>;
  /** Insère un élément. */
  insert(entry: TInputType): Observable<ServiceResult<TType>>;
  /** Supprime un élément. */
  delete(ids: string[]): Observable<ServiceSingleResult<boolean>>;
  /** Obtient une instance de type @type {TInputType}. */
  model(): TInputType;
  /** Récupère l'espace de nom de la requête GraphQL. */
  readonly name: string;
  /** Permet de faire une recherche global en fonction d'une valeur */
  search(
    value: string,
    filters?: QueryFilterInputType[],
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>>;
  /** Permet de recuperer la liste des tracking sur l'entité */
  findTracking(
    id: string,
    filters?: QueryFilterInputType[],
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>>;
}
export interface IEntityService<TType, TInputType>
  extends IService<TType, TInputType> {
  /** Permet de rechercher les éléments mis en corbeille en fonction de filtre et d'options. */
  findRecycles(
    filters?: QueryFilterInputType[],
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>>;
  /** Permet de compter le nombre d'élément mis en corbeille en fonction de filtres. */
  countRecycles(
    filters?: QueryFilterInputType[],
  ): Observable<ServiceSingleResult<number>>;
  /** Permet de restaurer des éléments mis en corbeille. */
  restore(ids: string[]): Observable<ServiceSingleResult<boolean>>;
  /** Permet de supprimer définitivement les éléments mis en corbeille. */
  recycle(ids: string[]): Observable<ServiceSingleResult<boolean>>;

  /** Permet d'afficher les attributs de l'entité */
  defaultName(currentContext: any): Observable<EntityAttributesType>

  /** Obtient si l'entité est en lecture seule */
  readOnly(id: string): Observable<ServiceSingleResult<boolean>>
}
/**
 * Interface permettant de définir si un modèle est utilisé
 */
export interface IIsUsed {
  /** Permet de savoir si l'élément est utilisé. */
  isUsed(ids: string[], name: string): Observable<ServiceSingleResult<boolean>>;
}

function nameof<T>(key: keyof T, instance?: T): keyof T {
  return key;
}
export class Check {
  public static notNull(obj: any, name: string): void {
    if (obj == undefined) {
      throw new Error(`L'objet '${name}' ne doit pas être null ou undefined.`);
    }
  }
}
/** Représente le service de gestion simple d'un type. */
export abstract class Service<TType, TInputType>
  implements IService<TType, TInputType> {
  private _name: string;

  constructor(
    private findQuery: Query,
    private getQuery: Query,
    private countQuery: Query,
    private updateMutation: Mutation,
    private insertMutation: Mutation,
    private deleteMutation: Mutation,
    private searchQuery?: Query,
    private findTrackingQuery?: Query,
  ) {
    // La query doit être sous cette forme.
    this._name = findQuery.document.definitions
      .filter(f => f.kind === 'OperationDefinition')
      .map(m => (m as any).selectionSet)
      .map(m => m.selections)
      .reduce(r => r)
      .filter(f => f.kind === 'Field')
      .map(m => m.name)
      .filter(f => f.kind === 'Name')
      .map(m => m.value)
      .reduce(r => r);
  }
  /** @inheritdoc */
  public get name(): string {
    return this._name;
  }
  /** @inheritdoc */
  public model(): TInputType {
    throw new Error("La method 'model' du service n'est pas implémentée.");
  }
  @InjectArgs
  public get(@Args("id") id: string): Observable<ServiceSingleResult<TType>> {
    const variables = { id: id };
    const query = this.getQuery.fetch(variables);
    return query.pipe(map(result => result.data[this.name].get)).pipe(first());
  }
  @InjectArgs
  public find(@Args("filters") filters?: QueryFilterInputType[], @Args("options") options?: QueryOptionsInputType): Observable<any> {

    Check.notNull(this.findQuery, 'findQuery');
    const variables = {
      queryFilters: filters,
      queryOptions: options,
    };
    const query = this.findQuery.fetch(variables);
    return query.pipe(map(result => result.data[this.name].find)).pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public search(
    @Args("value")
    value: string,
    @Args("filters")
    filters?: QueryFilterInputType[],
    @Args("options")
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>> {
    const variables = {
      value: value,
      queryFilters: filters,
      queryOptions: options,
    };
    const query = this.searchQuery.fetch(variables);
    return query.pipe(map(result => result.data[this.name].search));
  }
  /** @inheritdoc */
  @InjectArgs
  public findTracking(
    @Args("id")
    id: string,
    @Args("filters")
    filters?: QueryFilterInputType[],
    @Args("options")
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>> {
    const variables = {
      id: id,
      queryFilters: filters,
      queryOptions: options,
    };
    const query = this.findTrackingQuery.fetch(variables);
    return query.pipe(map(result => result.data['trackings'].findByEntityId));
  }
  /** @inheritdoc */
  @InjectArgs
  public count(
    @Args("filters")
    filters?: QueryFilterInputType[],
  ): Observable<ServiceSingleResult<number>> {
    Check.notNull(this.countQuery, 'countQuery');

    const variables = {
      queryFilters: filters,
    };
    const query = this.countQuery.fetch(variables);
    return query
      .pipe(map(result => result.data[this.name].count))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public update(
    @Args("id")
    id: string,
    @Args("entry")
    entry: TInputType,
  ): Observable<ServiceResult<TType>> {
    Check.notNull(this.updateMutation, 'updateMutation');

    const variables = { id: id, entity: entry };
    const query = this.updateMutation.mutate(variables);
    return query
      .pipe(map(result => result.data[this.name].update))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public insert(@Args("entry") entry: TInputType): Observable<ServiceResult<TType>> {
    Check.notNull(this.insertMutation, 'insertMutation');

    const variables = { entity: entry };
    const query = this.insertMutation.mutate(variables);
    return query
      .pipe(map(result => result.data[this.name].insert))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public delete(@Args("ids") ids: string[]): Observable<ServiceSingleResult<boolean>> {
    Check.notNull(this.deleteMutation, 'deleteMutation');

    const variables = { ids: ids };
    const query = this.deleteMutation.mutate(variables);
    return query
      .pipe(map(result => result.data[this.name].delete))
      .pipe(first());
  }
}
/** Représente la gestion d'un type avec la corbeille. */
export abstract class EntityService<TType, TInputType>
  extends Service<TType, TInputType>
  implements IEntityService<TType, TInputType> {
  constructor(
    findQuery: Query,
    getQuery: Query,
    countQuery: Query,
    updateMutation: Mutation,
    insertMutation: Mutation,
    deleteMutation: Mutation,
    private findRecyclesQuery: Query,
    private countRecyclesQuery: Query,
    private recycleMutation: Mutation,
    private restoreMutation: Mutation,
    searchQuery?: Query,
    findTracking?: Query,
    private readOnlyQuery?: Query
  ) {
    super(
      findQuery,
      getQuery,
      countQuery,
      updateMutation,
      insertMutation,
      deleteMutation,
      searchQuery,
      findTracking,
    );
  }
  /** @inheritdoc */
  @InjectArgs
  public defaultName(@Args("currentContext") currentContext: any): Observable<EntityAttributesType> {
    if (currentContext.attributes != undefined) {
      let attributes = currentContext.attributes() as EntityAttributesType;
      return of(attributes);
    }
    return null;
  }

  /** @inheritdoc */
  @InjectArgs
  public findRecycles(
    @Args("filters")
    filters?: QueryFilterInputType[],
    @Args("options")
    options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<TType>> {
    Check.notNull(this.findRecyclesQuery, 'findRecyclesQuery');
    const variables = {
      queryFilters: filters,
      queryOptions: options,
    };
    const query = this.findRecyclesQuery.fetch(variables);
    return query
      .pipe(map(result => result.data[this.name]))
      .pipe(map(result => result.findRecycles))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public countRecycles(
    @Args("filters") filters?: QueryFilterInputType[],
  ): Observable<ServiceSingleResult<number>> {
    Check.notNull(this.countRecyclesQuery, 'countRecyclesQuery');
    const variables = {
      queryFilters: filters,
    };
    const query = this.countRecyclesQuery.fetch(variables);
    return query
      .pipe(map(result => result.data[this.name].countRecycles))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public recycle(@Args("ids") ids: string[]): Observable<ServiceSingleResult<boolean>> {
    Check.notNull(this.recycleMutation, 'recycleMutation');

    const variables = { ids: ids };
    const query = this.recycleMutation.mutate(variables);
    return query
      .pipe(map(result => result.data[this.name].recycle))
      .pipe(first());
  }
  /** @inheritdoc */
  @InjectArgs
  public restore(@Args("ids") ids: string[]): Observable<ServiceSingleResult<boolean>> {
    Check.notNull(this.restoreMutation, 'restoreMutation');

    const variables = { ids: ids };
    const query = this.restoreMutation.mutate(variables);
    return query
      .pipe(map(result => result.data[this.name].restore))
      .pipe(first());
  }
  /** Obtient si l'entité est en lecture seule */
  public readOnly(id: string): Observable<ServiceSingleResult<boolean>> {

    if (this.readOnlyQuery != undefined && id != undefined) {

      const variables = {
        id: id
      };
      const query = this.readOnlyQuery.fetch(variables);
      return query
        .pipe(map(result => result.data[this.name]))
        .pipe(map(result => result.isReadOnly))
        .pipe(first());
    }

    return of({ data: false });
  }
}
