<div class="filter-container">
  <dx-load-indicator
    class="button-indicator"
    [visible]="!isInitialize && !isManaged"
  >
  </dx-load-indicator>

  <div *ngIf="isManaged">
    {{ 'globals/managed' | translate }}
  </div>
  <dx-filter-builder
    [allowHierarchicalFields]="true"
    [disabled]="!isInitialize || isManaged || disabled === true"
    [fields]="fields"
    [(value)]="values"
    [customOperations]="customOperations"
    (onValueChanged)="onValueChanged($event)"
    (onInitialized)="onInitialized($event)"
  >
    <div *dxTemplate="let condition of 'numberBoxTemplate'" class="cl-no-wrap">
      <dx-number-box
        #numberBox
        [value]="condition.value == undefined ? null : condition.value[0][0]"
        [min]="0"
        (onValueChanged)="
          condition.setValue([[$event.value, typeSelectBox.value]])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> en </span>
      <dx-select-box
        #typeSelectBox
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumTimeType"
        [value]="condition.value == undefined ? 'days' : condition.value[0][1]"
        (onValueChanged)="condition.setValue([[numberBox.value, $event.value]])"
      ></dx-select-box>
    </div>

    <div
      *dxTemplate="let condition of 'betweenNumberBoxTemplate'"
      class="cl-no-wrap"
    >
      <dx-number-box
        #startNumberBox
        [value]="condition.value == undefined ? 0 : condition.value[0][0]"
        (onValueChanged)="
          condition.setValue([
            [$event.value, endNumberBox.value, typeSelectBox.value]
          ])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> et </span>
      <dx-number-box
        #endNumberBox
        [value]="condition.value == undefined ? 0 : condition.value[0][1]"
        (onValueChanged)="
          condition.setValue([
            [startNumberBox.value, $event.value, typeSelectBox.value]
          ])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> en </span>
      <dx-select-box
        #typeSelectBox
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumTimeType"
        [value]="condition.value == undefined ? 'days' : condition.value[0][2]"
        (onValueChanged)="
          condition.setValue([
            [startNumberBox.value, endNumberBox.value, $event.value]
          ])
        "
      ></dx-select-box>
    </div>

    <div *dxTemplate="let condition of 'fromToTemplate'" class="cl-no-wrap">
      <dx-drop-down-box
        #fromSelectBox
        [value]="getFromToValue(condition.value, 0)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        [placeholder]="'selectValue' | translate"
        [showClearButton]="true"
        [dataSource]="condition.field.lookup.dataSource"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            *ngIf="condition.field.lookup.hasTreeField == false"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{ mode: 'single', showCheckBoxesMode: 'always' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showColumnHeaders]="false"
            [showRowLines]="false"
            [selectedRowKeys]="getConditionValue(condition, 'fromSelectBox')"
            (onSelectionChanged)="
              changeConditionValue(condition, $event, 'fromSelectBox')
            "
          >
          </dx-data-grid>

          <dx-tree-list
            *ngIf="condition.field.lookup.hasTreeField == true"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{
              mode: 'single',
              showCheckBoxesMode: 'always',
              recursive: 'true'
            }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showRowLines]="false"
            [showColumnHeaders]="false"
            [selectedRowKeys]="getConditionValue(condition, 'fromSelectBox')"
            (onSelectionChanged)="
              changeConditionValue(condition, $event, 'fromSelectBox')
            "
          >
          </dx-tree-list>
        </div>
      </dx-drop-down-box>
      <dx-drop-down-box
        #toSelectBox
        [value]="getFromToValue(condition.value, 1)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        [placeholder]="'selectValue' | translate"
        [showClearButton]="true"
        [dataSource]="condition.field.lookup.dataSource"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            *ngIf="condition.field.lookup.hasTreeField == false"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{ mode: 'single', showCheckBoxesMode: 'always' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showColumnHeaders]="false"
            [showRowLines]="false"
            [selectedRowKeys]="getConditionValue(condition, 'toSelectBox')"
            (onSelectionChanged)="
              changeConditionValue(condition, $event, 'toSelectBox')
            "
          >
          </dx-data-grid>

          <dx-tree-list
            *ngIf="condition.field.lookup.hasTreeField == true"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{
              mode: 'single',
              showCheckBoxesMode: 'always',
              recursive: 'true'
            }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showRowLines]="false"
            [showColumnHeaders]="false"
            [selectedRowKeys]="getConditionValue(condition, 'toSelectBox')"
            (onSelectionChanged)="
              changeConditionValue(condition, $event, 'toSelectBox')
            "
          >
          </dx-tree-list>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let condition of 'selectTemplate'" class="cl-no-wrap">
      <dx-drop-down-box
        [value]="selectionTemplateGetValue(condition)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        [placeholder]="'selectValue' | translate"
        (onFocusIn)="onDropDownInput($event)"
        [showClearButton]="true"
        [dataSource]="condition.field.lookup.dataSource"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            *ngIf="condition.field.lookup.hasTreeField == false"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{ mode: 'multiple', showCheckBoxesMode: 'always' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showColumnHeaders]="false"
            [showRowLines]="false"
            [selectedRowKeys]="selectionTemplateGetValue(condition)"
            (onSelectionChanged)="selectionTemplateChanged(condition, $event)"
          >
          </dx-data-grid>

          <dx-tree-list
            *ngIf="condition.field.lookup.hasTreeField == true"
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{
              mode: 'multiple',
              showCheckBoxesMode: 'always',
              recursive: 'true'
            }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showRowLines]="false"
            [showColumnHeaders]="false"
            [selectedRowKeys]="selectionTemplateGetValue(condition)"
            (onSelectionChanged)="selectionTemplateChanged(condition, $event)"
          >
          </dx-tree-list>
        </div>
      </dx-drop-down-box>
    </div>

    <div
      *dxTemplate="let condition of 'defaultTimeTemplate'"
      class="cl-no-wrap"
    >
      <dx-select-box
        valueExpr="id"
        displayExpr="name"
        [dataSource]="enumDefaultTime"
        [value]="condition.value"
        (onValueChanged)="condition.setValue($event.value)"
      ></dx-select-box>
    </div>

    <div *dxTemplate="let condition of 'timeTemplate'" class="cl-no-wrap">
      <clc-timespan
        [value]="condition.value"
        (onValueChanged)="condition.setValue($event)"
        [modeInline]="true"
      >
      </clc-timespan>
    </div>

    <div *dxTemplate="let condition of 'treeviewTemplate'" class="cl-no-wrap">
      <dx-drop-down-box
        [value]="selectionTemplateGetValue(condition)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        (onInitialized)="onDropDownInitialized($event)"
        (onFocusIn)="onDropDownInput($event)"
        [placeholder]="'selectValue' | translate"
        [showClearButton]="true"
        [dataSource]="condition.field.lookup.dataSource"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-tree-list
            [dataSource]="condition.field.lookup.dataSource"
            [keyExpr]="condition.field.lookup.valueExpr"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{ mode: 'single' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showRowLines]="false"
            [showColumnHeaders]="false"
            [columnChooser]="{ enabled: false }"
            [filterRow]="{
              visible: true
            }"
            [filterPanel]="{
              visible: false
            }"
            (onContentReady)="onTreelistContentReady($event, condition)"
            [selectedRowKeys]="selectionTemplateGetValue(condition)"
            (onSelectionChanged)="selectionTemplateChanged(condition, $event)"
          >
          </dx-tree-list>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let condition of 'gridViewTemplate'" class="cl-no-wrap">
      <dx-drop-down-box
        [value]="selectionTemplateGetValue(condition)"
        [valueExpr]="condition.field.lookup.valueExpr"
        [displayExpr]="condition.field.lookup.displayExpr"
        (onInitialized)="onDropDownInitialized($event)"
        (onFocusIn)="onDropDownInput($event)"
        [placeholder]="'selectValue' | translate"
        [showClearButton]="true"
        [dataSource]="condition.field.lookup.dataSource"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            [dataSource]="condition.field.lookup.dataSource"
            [columns]="[condition.field.lookup.displayExpr]"
            [selection]="{ mode: 'single' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [remoteOperations]="{
              paging: true,
              filtering: true,
              sorting: true,
              grouping: false,
              groupPaging: false,
              summary: false
            }"
            [filterRow]="{
              visible: true
            }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showColumnHeaders]="false"
            [showRowLines]="false"
            [selectedRowKeys]="selectionTemplateGetValue(condition)"
            (onSelectionChanged)="selectionTemplateChanged(condition, $event)"
          >
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let condition of 'customLookupSource'" class="cl-no-wrap">
      <dx-drop-down-box
        [value]="selectionTemplateGetValue(condition)"
        [valueExpr]="'id'"
        [displayExpr]="'name'"
        [placeholder]="'selectValue' | translate"
        (onFocusIn)="onDropDownInput($event)"
        [showClearButton]="true"
        [dataSource]="getCustomLookupDataSource(condition)"
        [width]="345"
      >
        <div *dxTemplate="let data of 'content'">
          <dx-data-grid
            [dataSource]="getCustomLookupDataSource(condition)"
            [keyExpr]="'id'"
            [columns]="['name']"
            [selection]="{ mode: 'single' }"
            [hoverStateEnabled]="true"
            [paging]="{ enabled: true, pageSize: 10 }"
            [scrolling]="{ mode: 'virtual' }"
            [height]="320"
            [width]="'100%'"
            [showColumnHeaders]="false"
            [showRowLines]="false"
            [selectedRowKeys]="selectionTemplateGetValue(condition)"
            (onSelectionChanged)="selectionTemplateChanged(condition, $event)"
          >
          </dx-data-grid>
        </div>
      </dx-drop-down-box>
    </div>

    <div *dxTemplate="let condition of 'sizeGoTemplate'" class="cl-no-wrap">
      <dx-number-box
        #numberBox
        format="#0 Go"
        [value]="condition.value / 1024 / 1024 / 1024"
        (onValueChanged)="condition.setValue($event.value * 1024 * 1024 * 1024)"
        width="60"
      ></dx-number-box>
    </div>
    <div
      *dxTemplate="let condition of 'customBetweenSizeGo'"
      class="cl-no-wrap"
    >
      <dx-number-box
        #startNumberBox
        format="#0 Go"
        [value]="
          condition.value == undefined
            ? 0
            : condition.value[0][0] / 1024 / 1024 / 1024
        "
        (onValueChanged)="
          condition.setValue([
            [
              $event.value * 1024 * 1024 * 1024,
              endNumberBox.value * 1024 * 1024 * 1024
            ]
          ])
        "
        width="60"
      ></dx-number-box>
      <span style="margin-left: 5px; margin-right: 5px"> - </span>
      <dx-number-box
        #endNumberBox
        format="#0 Go"
        [value]="
          condition.value == undefined
            ? 0
            : condition.value[0][1] / 1024 / 1024 / 1024
        "
        (onValueChanged)="
          condition.setValue([
            [
              startNumberBox.value * 1024 * 1024 * 1024,
              $event.value * 1024 * 1024 * 1024
            ]
          ])
        "
        width="60"
      ></dx-number-box>
    </div>
    <div *dxTemplate="let condition of 'percentTemplate'" class="cl-no-wrap">
      <dx-number-box
        #percentTemplate
        [value]="condition.value"
        (onValueChanged)="condition.setValue($event.value)"
        [min]="0"
        [max]="100"
        width="60"
      ></dx-number-box>
    </div>
  </dx-filter-builder>
</div>
