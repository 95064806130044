import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPageFilesBaseVariables, FirstPageFilesBaseVariables, CountPageFilesBaseVariables, FindPageFilesBaseVariables, SearchPageFilesBaseVariables, ExportSchemaPageFilesBaseVariables, ExportDataPageFilesBaseVariables, CustomQueryPageFilesBaseVariables, CustomQueryIdPageFilesBaseVariables, UsedPageFilesBaseVariables, ExistPageFilesBaseVariables } from '../gqls'
import { GetPageFilesDocument, FirstPageFilesDocument, CountPageFilesDocument, FindPageFilesDocument, SearchPageFilesDocument, ExportSchemaPageFilesDocument, ExportDataPageFilesDocument, CustomQueryPageFilesDocument, CustomQueryIdPageFilesDocument, UsedPageFilesDocument, ExistPageFilesDocument } from '../gqls'
import { ServiceSingleResultOfPageFile, QueryContextOfPageFile, FilterOfPageFile, ServiceSingleResultOfInt64, ServiceListResultOfPageFile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PageFileBaseService {
    
public modelName = 'pageFile';
public modelPluralName = 'pageFiles';

	private getPageFilesQuery: GetPageFilesDocument;
	private firstPageFilesQuery: FirstPageFilesDocument;
	private countPageFilesQuery: CountPageFilesDocument;
	private findPageFilesQuery: FindPageFilesDocument;
	private searchPageFilesQuery: SearchPageFilesDocument;
	private exportSchemaPageFilesQuery: ExportSchemaPageFilesDocument;
	private exportDataPageFilesQuery: ExportDataPageFilesDocument;
	private customQueryPageFilesQuery: CustomQueryPageFilesDocument;
	private customQueryIdPageFilesQuery: CustomQueryIdPageFilesDocument;
	private usedPageFilesQuery: UsedPageFilesDocument;
	private existPageFilesQuery: ExistPageFilesDocument;

	constructor(private injector: Injector) {
		this.getPageFilesQuery = this.injector.get(GetPageFilesDocument);
		this.firstPageFilesQuery = this.injector.get(FirstPageFilesDocument);
		this.countPageFilesQuery = this.injector.get(CountPageFilesDocument);
		this.findPageFilesQuery = this.injector.get(FindPageFilesDocument);
		this.searchPageFilesQuery = this.injector.get(SearchPageFilesDocument);
		this.exportSchemaPageFilesQuery = this.injector.get(ExportSchemaPageFilesDocument);
		this.exportDataPageFilesQuery = this.injector.get(ExportDataPageFilesDocument);
		this.customQueryPageFilesQuery = this.injector.get(CustomQueryPageFilesDocument);
		this.customQueryIdPageFilesQuery = this.injector.get(CustomQueryIdPageFilesDocument);
		this.usedPageFilesQuery = this.injector.get(UsedPageFilesDocument);
		this.existPageFilesQuery = this.injector.get(ExistPageFilesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPageFile> {
    
    		let variables: GetPageFilesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.get));
            }
            else{
                let result:ServiceSingleResultOfPageFile={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPageFile,
		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPageFile> {
    
    		let variables: FirstPageFilesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPageFilesBaseVariables = {
    			filter: filter
    		}
    				return this.countPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPageFile,
		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPageFile> {
    
    		let variables: FindPageFilesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPageFile,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPageFile> {
    
    		let variables: SearchPageFilesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPageFilesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPageFilesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPageFile,
		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPageFile> {
    
    		let variables: CustomQueryPageFilesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPageFile,
		@Args('filter?') filter?: FilterOfPageFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPageFile> {
    
    		let variables: CustomQueryIdPageFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPageFilesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPageFilesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPageFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pageFiles.exist));
    	}
    
    
}