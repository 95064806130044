import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSftpConnectorsBaseVariables, FirstSftpConnectorsBaseVariables, CountSftpConnectorsBaseVariables, FindSftpConnectorsBaseVariables, SearchSftpConnectorsBaseVariables, ExportSchemaSftpConnectorsBaseVariables, ExportDataSftpConnectorsBaseVariables, CustomQuerySftpConnectorsBaseVariables, CustomQueryIdSftpConnectorsBaseVariables, UsedSftpConnectorsBaseVariables, ConnectionTestSftpConnectorsBaseVariables, ExistSftpConnectorsBaseVariables, FindUnassociatedQueryBuildersSftpConnectorsBaseVariables, InsertSftpConnectorsBaseVariables, UpdateSftpConnectorsBaseVariables, ImportDataSftpConnectorsBaseVariables, UpdateManySftpConnectorsBaseVariables, DeleteSftpConnectorsBaseVariables, ResetPasswordSftpConnectorsBaseVariables, AddQueryBuildersSftpConnectorsBaseVariables, RemoveQueryBuildersSftpConnectorsBaseVariables } from '../gqls'
import { GetSftpConnectorsDocument, FirstSftpConnectorsDocument, CountSftpConnectorsDocument, FindSftpConnectorsDocument, SearchSftpConnectorsDocument, ExportSchemaSftpConnectorsDocument, ExportDataSftpConnectorsDocument, CustomQuerySftpConnectorsDocument, CustomQueryIdSftpConnectorsDocument, UsedSftpConnectorsDocument, ConnectionTestSftpConnectorsDocument, ExistSftpConnectorsDocument, FindUnassociatedQueryBuildersSftpConnectorsDocument, InsertSftpConnectorsDocument, UpdateSftpConnectorsDocument, ImportDataSftpConnectorsDocument, UpdateManySftpConnectorsDocument, DeleteSftpConnectorsDocument, ResetPasswordSftpConnectorsDocument, AddQueryBuildersSftpConnectorsDocument, RemoveQueryBuildersSftpConnectorsDocument } from '../gqls'
import { ServiceSingleResultOfSftpConnector, QueryContextOfSftpConnector, FilterOfSftpConnector, ServiceSingleResultOfInt64, ServiceListResultOfSftpConnector, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfSftpConnector, QueryContextOfQueryBuilder, FilterOfQueryBuilder, ServiceListResultOfQueryBuilder, SftpConnectorInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SftpConnectorBaseService {
    
public modelName = 'sftpConnector';
public modelPluralName = 'sftpConnectors';

	private getSftpConnectorsQuery: GetSftpConnectorsDocument;
	private firstSftpConnectorsQuery: FirstSftpConnectorsDocument;
	private countSftpConnectorsQuery: CountSftpConnectorsDocument;
	private findSftpConnectorsQuery: FindSftpConnectorsDocument;
	private searchSftpConnectorsQuery: SearchSftpConnectorsDocument;
	private exportSchemaSftpConnectorsQuery: ExportSchemaSftpConnectorsDocument;
	private exportDataSftpConnectorsQuery: ExportDataSftpConnectorsDocument;
	private customQuerySftpConnectorsQuery: CustomQuerySftpConnectorsDocument;
	private customQueryIdSftpConnectorsQuery: CustomQueryIdSftpConnectorsDocument;
	private usedSftpConnectorsQuery: UsedSftpConnectorsDocument;
	private connectionTestSftpConnectorsQuery: ConnectionTestSftpConnectorsDocument;
	private existSftpConnectorsQuery: ExistSftpConnectorsDocument;
	private findUnassociatedQueryBuildersSftpConnectorsQuery: FindUnassociatedQueryBuildersSftpConnectorsDocument;
	private insertSftpConnectorsMutation: InsertSftpConnectorsDocument;
	private updateSftpConnectorsMutation: UpdateSftpConnectorsDocument;
	private importDataSftpConnectorsMutation: ImportDataSftpConnectorsDocument;
	private updateManySftpConnectorsMutation: UpdateManySftpConnectorsDocument;
	private deleteSftpConnectorsMutation: DeleteSftpConnectorsDocument;
	private resetPasswordSftpConnectorsMutation: ResetPasswordSftpConnectorsDocument;
	private addQueryBuildersSftpConnectorsMutation: AddQueryBuildersSftpConnectorsDocument;
	private removeQueryBuildersSftpConnectorsMutation: RemoveQueryBuildersSftpConnectorsDocument;

	constructor(private injector: Injector) {
		this.getSftpConnectorsQuery = this.injector.get(GetSftpConnectorsDocument);
		this.firstSftpConnectorsQuery = this.injector.get(FirstSftpConnectorsDocument);
		this.countSftpConnectorsQuery = this.injector.get(CountSftpConnectorsDocument);
		this.findSftpConnectorsQuery = this.injector.get(FindSftpConnectorsDocument);
		this.searchSftpConnectorsQuery = this.injector.get(SearchSftpConnectorsDocument);
		this.exportSchemaSftpConnectorsQuery = this.injector.get(ExportSchemaSftpConnectorsDocument);
		this.exportDataSftpConnectorsQuery = this.injector.get(ExportDataSftpConnectorsDocument);
		this.customQuerySftpConnectorsQuery = this.injector.get(CustomQuerySftpConnectorsDocument);
		this.customQueryIdSftpConnectorsQuery = this.injector.get(CustomQueryIdSftpConnectorsDocument);
		this.usedSftpConnectorsQuery = this.injector.get(UsedSftpConnectorsDocument);
		this.connectionTestSftpConnectorsQuery = this.injector.get(ConnectionTestSftpConnectorsDocument);
		this.existSftpConnectorsQuery = this.injector.get(ExistSftpConnectorsDocument);
		this.findUnassociatedQueryBuildersSftpConnectorsQuery = this.injector.get(FindUnassociatedQueryBuildersSftpConnectorsDocument);
		this.insertSftpConnectorsMutation = this.injector.get(InsertSftpConnectorsDocument);
		this.updateSftpConnectorsMutation = this.injector.get(UpdateSftpConnectorsDocument);
		this.importDataSftpConnectorsMutation = this.injector.get(ImportDataSftpConnectorsDocument);
		this.updateManySftpConnectorsMutation = this.injector.get(UpdateManySftpConnectorsDocument);
		this.deleteSftpConnectorsMutation = this.injector.get(DeleteSftpConnectorsDocument);
		this.resetPasswordSftpConnectorsMutation = this.injector.get(ResetPasswordSftpConnectorsDocument);
		this.addQueryBuildersSftpConnectorsMutation = this.injector.get(AddQueryBuildersSftpConnectorsDocument);
		this.removeQueryBuildersSftpConnectorsMutation = this.injector.get(RemoveQueryBuildersSftpConnectorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnector> {
    
    		let variables: GetSftpConnectorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.get));
            }
            else{
                let result:ServiceSingleResultOfSftpConnector={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnector> {
    
    		let variables: FirstSftpConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSftpConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnector> {
    
    		let variables: FindSftpConnectorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnector> {
    
    		let variables: SearchSftpConnectorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSftpConnectorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSftpConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnector> {
    
    		let variables: CustomQuerySftpConnectorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSftpConnector,
		@Args('filter?') filter?: FilterOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSftpConnector> {
    
    		let variables: CustomQueryIdSftpConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSftpConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.used));
    	}

    	/**  */
    	@InjectArgs
    	public connectionTest(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ConnectionTestSftpConnectorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.connectionTestSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.connectionTest));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSftpConnectorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.exist));
    	}

	@InjectArgs
	public findAssociatedQueryBuilders(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfQueryBuilder,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfQueryBuilder> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('queryBuilders', fields, null, [
			GqlSubFieldArg.create('filterOfQueryBuilders', 'filter'),
			GqlSubFieldArg.create('optionsOfQueryBuilders', 'options'),
		]),
		])
		extendedVariables['filterOfQueryBuilders'] = filter;
		extendedVariables['optionsOfQueryBuilders'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.queryBuilders));
            }
            else{
                let result: ServiceListResultOfQueryBuilder = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedQueryBuilders(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilder,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfQueryBuilder,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilder> {
    
    		let variables: FindUnassociatedQueryBuildersSftpConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedQueryBuildersSftpConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.findUnassociatedQueryBuilders));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SftpConnectorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnector> {
    
    		let variables: InsertSftpConnectorsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSftpConnector> {
    
    		let variables: UpdateSftpConnectorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSftpConnectorsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSftpConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySftpConnectorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSftpConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.delete));
    	}

    	/**  */
    	@InjectArgs
    	public resetPassword(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('newPassword?') newPassword?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ResetPasswordSftpConnectorsBaseVariables = {
    			id: id,
			newPassword: newPassword
    		}
    				return this.resetPasswordSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.resetPassword));
    	}

    	/**  */
    	@InjectArgs
    	public addQueryBuilders(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilderIds') queryBuilderIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddQueryBuildersSftpConnectorsBaseVariables = {
    			id: id,
			queryBuilderIds: queryBuilderIds
    		}
    				return this.addQueryBuildersSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.addQueryBuilders));
    	}

    	/**  */
    	@InjectArgs
    	public removeQueryBuilders(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilderIds') queryBuilderIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveQueryBuildersSftpConnectorsBaseVariables = {
    			id: id,
			queryBuilderIds: queryBuilderIds
    		}
    				return this.removeQueryBuildersSftpConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.sftpConnectors.removeQueryBuilders));
    	}
    
}