import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { UntypedFormGroup, FormGroupDirective } from '@angular/forms';
import {
  TranslateService,
} from '@clarilog/shared/services';
import { ListMode } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { AssetModelCategory } from '@clarilog/core/services2/graphql/generated-types/types';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { CoreFormLoader } from '../../form/work-form/form-loader';
import { CoreFormRecorder } from '../../form/work-form/form-recorder';
import { CoreModelCompilerService } from '@clarilog/shared2/services/compiler/model-compiler.service';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { PageSection } from '@clarilog/shared2/models/schema';
// import inAssetCartModel from './in-asset-cart-model.json';
// import inAssetModelCartModel from './in-asset-model-cart-model.json';
// import outAssetCartModel from './out-asset-cart-model.json';
// import outAssetModelCartModel from './out-asset-model-cart-model.json';

/** Représente le composent de sélection d'élément. */
@Component({
  selector: 'clc-cart-list-select',
  templateUrl: './cart-list-select.component.html',
  styleUrls: ['./cart-list-select.component.scss'],
})
export class CoreCartListSelectComponent
  extends ListComponentBase
  implements OnInit {
  model: ModelState = null;

  @Input() mode: ListMode = 'default';
  /** Se produit lors de l'appui sur le boutton close. */
  @Output() onClosed: EventEmitter<any[]> = new EventEmitter<any[]>();
  /** Obtien ou définit la valeur du label */
  @Input() label: string;
  /** Recupére le titre de la liste de selection */
  @Input() title: string;
  /** Obtient ou définit le texte du bouton. */
  @Input() buttonText: string;

  @Input() movementType: string;
  category: AssetModelCategory = AssetModelCategory.Consumable;

  indexUpdate: any;

  @Output() isVisibleChange = new EventEmitter<boolean>();

  _isVisible: boolean = false;
  @Input() set isVisible(value: boolean) {
    this._isVisible = value;
    this.isVisibleChange.emit(value);
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  private _gc: GC;

  @Output() onAdded: EventEmitter<any> = new EventEmitter<any>();

  @Input() inputModel;

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private changeDetectorRef: ChangeDetectorRef,
    private loader: CoreFormLoader,
    private recorder: CoreFormRecorder,
    public gcFactory: GCFactory,
  ) {
    super();
    this._gc = gcFactory.create();
  }
  /** Déclenche l'évènement close.. */
  public close() {
    this.onClosed.emit();
  }

  public test() {
    this.model.form;
  }

  public page(): PageSection {
    return this.model.model.form.layout.pages[0] as PageSection;
  }

  /** @inheritdoc */
  ngOnInit(): void {
    this.initForm({});
  }

  public initForm(value) {
    this.modelCompilerService
      .compile(this.inputModel)
      .subscribe(async (model) => {
        this.model = model;
        if (this.title == undefined) {
          this.title = TranslateService.get('addNewElements');
        }
        this.model.sharedContext.params.set('category', () => this.category);
        this.model.sharedContext.params.set('id', () => null);
        this.model.form = await this.loader.create(this.model);
        this.setFormDefaultValues();
        if (value != undefined) {
          let entry: any = {};
          Object.assign(entry, value);

          this.loader.load(this.model.form, entry);
          this.changeDetectorRef.detectChanges();
        }
      });
  }

  @Input() inDefaultProperty: any[];

  @Input() rootForm: UntypedFormGroup;

  setFormDefaultValue(from, to) {
    let fromControl = FormGroupHelpers.formControlByName(this.rootForm, from);
    let toControl = FormGroupHelpers.formControlByName(this.model.form, to);

    if (fromControl != undefined && toControl != undefined) {
      toControl.setValue(fromControl.value, {
        emitEvent: false,
      });
      setTimeout(() => toControl.markAsDirty());
    }
  }

  setFormDefaultValues() {
    if (this.movementType === 'IN') {
      let amountControl = FormGroupHelpers.formControlByName(
        this.model.form,
        'amount',
      );

      if (amountControl != undefined) {
        amountControl.markAsDirty();
      }
      this.inDefaultProperty.forEach((element) => {
        this.setFormDefaultValue(element.from, element.to);
      });
    }
    this.setFormAutomations();
  }

  private createTotalAutomation() {
    let amount = FormGroupHelpers.formControlByName(this.model.form, 'amount');
    let unitCost = FormGroupHelpers.formControlByName(
      this.model.form,
      'unitCost',
    );
    let totalCost = FormGroupHelpers.formControlByName(
      this.model.form,
      'totalCost',
    );

    let callBack = () => {
      if (unitCost.value != undefined) {
        let resultat = amount.value * unitCost.value;

        totalCost.setValue;
        totalCost.setValue(resultat, {
          emitEvent: false,
        });
        setTimeout(() => totalCost.markAsDirty());
      }
    };

    if (amount.value == 0) {
      amount.setValue(1, { onlySelf: true, emitEvent: true });
    }

    // if (unitCost.value == -1) {
    //   unitCost.setValue(0, {
    //     emitEvent: true,
    //   });
    //   setTimeout(() => unitCost.markAsDirty());
    // }

    amount.valueChanges.subscribe((resp) => {
      let value = resp;
      if (resp == 0) {
        value = 1;
      }
      amount.setValue(value, { onlySelf: true, emitEvent: false });
    });

    let subscribeAmount = amount.valueChanges.subscribe((value) => {
      callBack();
    });

    let subscribeUnitCost = unitCost.valueChanges.subscribe((value) => {
      callBack();
    });
    setTimeout(() => amount.markAsDirty());
    setTimeout(() => unitCost.markAsDirty());

    this._gc.forRelease(subscribeAmount);
    this._gc.forRelease(subscribeUnitCost);
  }

  setFormAutomations() {
    if (this.movementType === 'IN') {
      this.createTotalAutomation();
    }
  }

  open(category: AssetModelCategory, value: any = undefined) {
    this.category = category;

    this.initForm(value);
  }

  pass(e): any {
    let materialize = this.recorder.materialize(this.model.form);

    this.onAdded.emit(materialize);
    this.close();
  }

  ngOnDestroy(): void {
    this._gc.dispose();
  }
}
