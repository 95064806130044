import { AssetTypeAccountingBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-type-accounting')
export class AssetTypeAccountingCoreService extends AssetTypeAccountingBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
