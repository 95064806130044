import { ScanConfigurationAppointmentBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

@Injectable({ providedIn: 'root' })
export class ScanConfigurationAppointmentCoreService extends ScanConfigurationAppointmentBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public scanConfigurationAppointmentFields(): Array<GqlField | GqlSubField> {
    return ScanConfigurationAppointmentCoreService.ScanConfigurationAppointmentFields();
  }
  public static ScanConfigurationAppointmentFields(): Array<
    GqlField | GqlSubField
  > {
    return ModelFieldCompilerService.appointmentFields(['scanByAzureAdId'], false);
  }
}
