import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetCategoryRulesBaseVariables, FirstAssetCategoryRulesBaseVariables, CountAssetCategoryRulesBaseVariables, FindAssetCategoryRulesBaseVariables, SearchAssetCategoryRulesBaseVariables, ExportSchemaAssetCategoryRulesBaseVariables, ExportDataAssetCategoryRulesBaseVariables, CustomQueryAssetCategoryRulesBaseVariables, CustomQueryIdAssetCategoryRulesBaseVariables, UsedAssetCategoryRulesBaseVariables, ExistAssetCategoryRulesBaseVariables, InsertAssetCategoryRulesBaseVariables, UpdateAssetCategoryRulesBaseVariables, ImportDataAssetCategoryRulesBaseVariables, UpdateManyAssetCategoryRulesBaseVariables, DeleteAssetCategoryRulesBaseVariables, OrderAssetCategoryRulesBaseVariables, ExecuteAssetCategoryRulesBaseVariables } from '../gqls'
import { GetAssetCategoryRulesDocument, FirstAssetCategoryRulesDocument, CountAssetCategoryRulesDocument, FindAssetCategoryRulesDocument, SearchAssetCategoryRulesDocument, ExportSchemaAssetCategoryRulesDocument, ExportDataAssetCategoryRulesDocument, CustomQueryAssetCategoryRulesDocument, CustomQueryIdAssetCategoryRulesDocument, UsedAssetCategoryRulesDocument, ExistAssetCategoryRulesDocument, InsertAssetCategoryRulesDocument, UpdateAssetCategoryRulesDocument, ImportDataAssetCategoryRulesDocument, UpdateManyAssetCategoryRulesDocument, DeleteAssetCategoryRulesDocument, OrderAssetCategoryRulesDocument, ExecuteAssetCategoryRulesDocument } from '../gqls'
import { ServiceSingleResultOfAssetCategoryRule, QueryContextOfAssetCategoryRule, FilterOfAssetCategoryRule, ServiceSingleResultOfInt64, ServiceListResultOfAssetCategoryRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AssetCategoryRuleInput, FieldUpdateOperatorOfAssetCategoryRule } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetCategoryRuleBaseService {
    
public modelName = 'assetCategoryRule';
public modelPluralName = 'assetCategoryRules';

	private getAssetCategoryRulesQuery: GetAssetCategoryRulesDocument;
	private firstAssetCategoryRulesQuery: FirstAssetCategoryRulesDocument;
	private countAssetCategoryRulesQuery: CountAssetCategoryRulesDocument;
	private findAssetCategoryRulesQuery: FindAssetCategoryRulesDocument;
	private searchAssetCategoryRulesQuery: SearchAssetCategoryRulesDocument;
	private exportSchemaAssetCategoryRulesQuery: ExportSchemaAssetCategoryRulesDocument;
	private exportDataAssetCategoryRulesQuery: ExportDataAssetCategoryRulesDocument;
	private customQueryAssetCategoryRulesQuery: CustomQueryAssetCategoryRulesDocument;
	private customQueryIdAssetCategoryRulesQuery: CustomQueryIdAssetCategoryRulesDocument;
	private usedAssetCategoryRulesQuery: UsedAssetCategoryRulesDocument;
	private existAssetCategoryRulesQuery: ExistAssetCategoryRulesDocument;
	private insertAssetCategoryRulesMutation: InsertAssetCategoryRulesDocument;
	private updateAssetCategoryRulesMutation: UpdateAssetCategoryRulesDocument;
	private importDataAssetCategoryRulesMutation: ImportDataAssetCategoryRulesDocument;
	private updateManyAssetCategoryRulesMutation: UpdateManyAssetCategoryRulesDocument;
	private deleteAssetCategoryRulesMutation: DeleteAssetCategoryRulesDocument;
	private orderAssetCategoryRulesMutation: OrderAssetCategoryRulesDocument;
	private executeAssetCategoryRulesMutation: ExecuteAssetCategoryRulesDocument;

	constructor(private injector: Injector) {
		this.getAssetCategoryRulesQuery = this.injector.get(GetAssetCategoryRulesDocument);
		this.firstAssetCategoryRulesQuery = this.injector.get(FirstAssetCategoryRulesDocument);
		this.countAssetCategoryRulesQuery = this.injector.get(CountAssetCategoryRulesDocument);
		this.findAssetCategoryRulesQuery = this.injector.get(FindAssetCategoryRulesDocument);
		this.searchAssetCategoryRulesQuery = this.injector.get(SearchAssetCategoryRulesDocument);
		this.exportSchemaAssetCategoryRulesQuery = this.injector.get(ExportSchemaAssetCategoryRulesDocument);
		this.exportDataAssetCategoryRulesQuery = this.injector.get(ExportDataAssetCategoryRulesDocument);
		this.customQueryAssetCategoryRulesQuery = this.injector.get(CustomQueryAssetCategoryRulesDocument);
		this.customQueryIdAssetCategoryRulesQuery = this.injector.get(CustomQueryIdAssetCategoryRulesDocument);
		this.usedAssetCategoryRulesQuery = this.injector.get(UsedAssetCategoryRulesDocument);
		this.existAssetCategoryRulesQuery = this.injector.get(ExistAssetCategoryRulesDocument);
		this.insertAssetCategoryRulesMutation = this.injector.get(InsertAssetCategoryRulesDocument);
		this.updateAssetCategoryRulesMutation = this.injector.get(UpdateAssetCategoryRulesDocument);
		this.importDataAssetCategoryRulesMutation = this.injector.get(ImportDataAssetCategoryRulesDocument);
		this.updateManyAssetCategoryRulesMutation = this.injector.get(UpdateManyAssetCategoryRulesDocument);
		this.deleteAssetCategoryRulesMutation = this.injector.get(DeleteAssetCategoryRulesDocument);
		this.orderAssetCategoryRulesMutation = this.injector.get(OrderAssetCategoryRulesDocument);
		this.executeAssetCategoryRulesMutation = this.injector.get(ExecuteAssetCategoryRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCategoryRule> {
    
    		let variables: GetAssetCategoryRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.get));
            }
            else{
                let result:ServiceSingleResultOfAssetCategoryRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCategoryRule,
		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCategoryRule> {
    
    		let variables: FirstAssetCategoryRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetCategoryRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetCategoryRule,
		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCategoryRule> {
    
    		let variables: FindAssetCategoryRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetCategoryRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCategoryRule> {
    
    		let variables: SearchAssetCategoryRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetCategoryRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetCategoryRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetCategoryRule,
		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCategoryRule> {
    
    		let variables: CustomQueryAssetCategoryRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetCategoryRule,
		@Args('filter?') filter?: FilterOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCategoryRule> {
    
    		let variables: CustomQueryIdAssetCategoryRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetCategoryRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetCategoryRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetCategoryRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetCategoryRuleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCategoryRule> {
    
    		let variables: InsertAssetCategoryRulesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetCategoryRule> {
    
    		let variables: UpdateAssetCategoryRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetCategoryRulesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetCategoryRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetCategoryRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetCategoryRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderAssetCategoryRulesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.order));
    	}

    	/**  */
    	@InjectArgs
    	public execute(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ruleIds') ruleIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExecuteAssetCategoryRulesBaseVariables = {
    			ruleIds: ruleIds
    		}
    				return this.executeAssetCategoryRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetCategoryRules.execute));
    	}
    
}