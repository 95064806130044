import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAppointmentsBaseVariables, FirstAppointmentsBaseVariables, CountAppointmentsBaseVariables, FindAppointmentsBaseVariables, SearchAppointmentsBaseVariables, ExportSchemaAppointmentsBaseVariables, ExportDataAppointmentsBaseVariables, CustomQueryAppointmentsBaseVariables, CustomQueryIdAppointmentsBaseVariables, UsedAppointmentsBaseVariables, ExistAppointmentsBaseVariables, InsertAppointmentsBaseVariables, UpdateAppointmentsBaseVariables, ImportDataAppointmentsBaseVariables, UpdateManyAppointmentsBaseVariables, DeleteAppointmentsBaseVariables } from '../gqls'
import { GetAppointmentsDocument, FirstAppointmentsDocument, CountAppointmentsDocument, FindAppointmentsDocument, SearchAppointmentsDocument, ExportSchemaAppointmentsDocument, ExportDataAppointmentsDocument, CustomQueryAppointmentsDocument, CustomQueryIdAppointmentsDocument, UsedAppointmentsDocument, ExistAppointmentsDocument, InsertAppointmentsDocument, UpdateAppointmentsDocument, ImportDataAppointmentsDocument, UpdateManyAppointmentsDocument, DeleteAppointmentsDocument } from '../gqls'
import { ServiceSingleResultOfAppointment, QueryContextOfAppointment, FilterOfAppointment, ServiceSingleResultOfInt64, ServiceListResultOfAppointment, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, AppointmentInput, FieldUpdateOperatorOfAppointment } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AppointmentBaseService {
    
public modelName = 'appointment';
public modelPluralName = 'appointments';

	private getAppointmentsQuery: GetAppointmentsDocument;
	private firstAppointmentsQuery: FirstAppointmentsDocument;
	private countAppointmentsQuery: CountAppointmentsDocument;
	private findAppointmentsQuery: FindAppointmentsDocument;
	private searchAppointmentsQuery: SearchAppointmentsDocument;
	private exportSchemaAppointmentsQuery: ExportSchemaAppointmentsDocument;
	private exportDataAppointmentsQuery: ExportDataAppointmentsDocument;
	private customQueryAppointmentsQuery: CustomQueryAppointmentsDocument;
	private customQueryIdAppointmentsQuery: CustomQueryIdAppointmentsDocument;
	private usedAppointmentsQuery: UsedAppointmentsDocument;
	private existAppointmentsQuery: ExistAppointmentsDocument;
	private insertAppointmentsMutation: InsertAppointmentsDocument;
	private updateAppointmentsMutation: UpdateAppointmentsDocument;
	private importDataAppointmentsMutation: ImportDataAppointmentsDocument;
	private updateManyAppointmentsMutation: UpdateManyAppointmentsDocument;
	private deleteAppointmentsMutation: DeleteAppointmentsDocument;

	constructor(private injector: Injector) {
		this.getAppointmentsQuery = this.injector.get(GetAppointmentsDocument);
		this.firstAppointmentsQuery = this.injector.get(FirstAppointmentsDocument);
		this.countAppointmentsQuery = this.injector.get(CountAppointmentsDocument);
		this.findAppointmentsQuery = this.injector.get(FindAppointmentsDocument);
		this.searchAppointmentsQuery = this.injector.get(SearchAppointmentsDocument);
		this.exportSchemaAppointmentsQuery = this.injector.get(ExportSchemaAppointmentsDocument);
		this.exportDataAppointmentsQuery = this.injector.get(ExportDataAppointmentsDocument);
		this.customQueryAppointmentsQuery = this.injector.get(CustomQueryAppointmentsDocument);
		this.customQueryIdAppointmentsQuery = this.injector.get(CustomQueryIdAppointmentsDocument);
		this.usedAppointmentsQuery = this.injector.get(UsedAppointmentsDocument);
		this.existAppointmentsQuery = this.injector.get(ExistAppointmentsDocument);
		this.insertAppointmentsMutation = this.injector.get(InsertAppointmentsDocument);
		this.updateAppointmentsMutation = this.injector.get(UpdateAppointmentsDocument);
		this.importDataAppointmentsMutation = this.injector.get(ImportDataAppointmentsDocument);
		this.updateManyAppointmentsMutation = this.injector.get(UpdateManyAppointmentsDocument);
		this.deleteAppointmentsMutation = this.injector.get(DeleteAppointmentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppointment> {
    
    		let variables: GetAppointmentsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.get));
            }
            else{
                let result:ServiceSingleResultOfAppointment={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAppointment,
		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppointment> {
    
    		let variables: FirstAppointmentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.countAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAppointment,
		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: FindAppointmentsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAppointment,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: SearchAppointmentsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAppointmentsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAppointmentsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAppointment,
		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: CustomQueryAppointmentsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAppointment,
		@Args('filter?') filter?: FilterOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAppointment> {
    
    		let variables: CustomQueryIdAppointmentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAppointmentsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAppointmentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AppointmentInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppointment> {
    
    		let variables: InsertAppointmentsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAppointment> {
    
    		let variables: UpdateAppointmentsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAppointmentsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAppointment,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAppointmentsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAppointmentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAppointmentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.appointments.delete));
    	}
    
}