import { Injectable } from '@angular/core';
import { DynamicPropertyField, User } from '../../types';

@Injectable({ providedIn: 'root' })
/** Classe de sauvegarde des données de pagination */
export class OrganizationStorageService {
  private _maxFileSize: number = undefined;
  private _allowedFileExtension: string[] = [];
  private _forbiddenFileExtension: string[] = [];
  private _dynamicPropertyFields: DynamicPropertyField[] = [];
  private _allSubIds: User[] = [];
  constructor() {}

  /** Permet de récupéré la taille max de fichier à uploader */
  getMaxFileSize() {
    return this._maxFileSize * 1024 * 1024;
  }
  /** Enregistre la taille max de fichier à uploader  */
  setMaxFileSize(value: number) {
    this._maxFileSize = value;
  }

  /** Permet de récupéré les type de fichier à autorisé */
  getAllowedFileExtension() {
    if (
      this._allowedFileExtension != null &&
      this._allowedFileExtension != undefined
    ) {
      return this._allowedFileExtension;
    }
    return [];
  }
  /** Enregistre les type de fichier à autorisé */
  setAllowedFileExtension(value: string[]) {
    this._allowedFileExtension = value;
  }

  /** Permet de récupéré les type de fichier à interdire */
  getForbiddenFileExtension() {
    if (
      this._forbiddenFileExtension != null &&
      this._forbiddenFileExtension != undefined
    ) {
      return this._forbiddenFileExtension;
    }
    return [];
  }
  /** Enregistre les type de fichier à interdire */
  setForbiddenFileExtension(value: string[]) {
    this._forbiddenFileExtension = value;
  }

  /** Obtient les propriété dynamique */
  getDynamicPropertyFields(): DynamicPropertyField[] {
    return this._dynamicPropertyFields ?? [];
  }
  /** Obtient les propriété dynamique */
  setDynamicPropertyFields(value: DynamicPropertyField[]) {
    this._dynamicPropertyFields = value;
  }
  /** Obtient les subIds */
  getAllSubIdsFields(): User[] {
    return this._allSubIds ?? [];
  }
  /** Obtient les subIds */
  setAllSubIdsFields(value: User[]) {
    this._allSubIds = value;
  }
}
