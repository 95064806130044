import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { DynamicPropertyGroupBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-dynamic-property')
export class DynamicPropertyGroupCoreService extends DynamicPropertyGroupBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
