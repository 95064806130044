import { Component, Input, OnInit } from '@angular/core';
import { defaultErrors } from './default-errors';

/** Représente un composant d'affichage d'erreur. */
@Component({
  selector: 'cl-validation-error',
  templateUrl: './validation-error.component.html',
  styleUrls: ['./validation-error.component.scss'],
})
export class ValidationErrorComponent implements OnInit {
  /** Obtient ou définit le message. */
  @Input() message: string;
  /** Obtien ou définit les erreurs. */
  @Input() error: any;

  constructor() { }

  ngOnInit() {
    if (this.error != undefined) {
      if (this.error.key == 'invalidMsg') {
        this.message = this.error.value;
      } else {
        const getError = defaultErrors[this.error.key];
        if (getError == undefined) {
          // Renvoit la clé si non trouvé.
          this.message = this.error.key;
        } else {
          this.message = getError(this.error.value);
        }
      }
    }
  }
}
