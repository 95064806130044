<mat-expansion-panel
  [expanded]="expanded != undefined ? expanded : true"
  class="work-item-section-container"
>
  <mat-expansion-panel-header
    *ngIf="title != undefined && title != ''"
    collapsedHeight="36px"
    expandedHeight="36px"
    class="work-item-section-header"
  >
    <mat-panel-title>{{ title }}</mat-panel-title>
  </mat-expansion-panel-header>
  <div class="work-item-section-body">
    <ng-content></ng-content>
  </div>
</mat-expansion-panel>
