import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMailSlurpWebhookTasksBaseVariables, FirstMailSlurpWebhookTasksBaseVariables, CountMailSlurpWebhookTasksBaseVariables, FindMailSlurpWebhookTasksBaseVariables, SearchMailSlurpWebhookTasksBaseVariables, ExportSchemaMailSlurpWebhookTasksBaseVariables, ExportDataMailSlurpWebhookTasksBaseVariables, CustomQueryMailSlurpWebhookTasksBaseVariables, CustomQueryIdMailSlurpWebhookTasksBaseVariables, UsedMailSlurpWebhookTasksBaseVariables, ExistMailSlurpWebhookTasksBaseVariables, InsertMailSlurpWebhookTasksBaseVariables, UpdateMailSlurpWebhookTasksBaseVariables, ImportDataMailSlurpWebhookTasksBaseVariables, UpdateManyMailSlurpWebhookTasksBaseVariables, DeleteMailSlurpWebhookTasksBaseVariables } from '../gqls'
import { GetMailSlurpWebhookTasksDocument, FirstMailSlurpWebhookTasksDocument, CountMailSlurpWebhookTasksDocument, FindMailSlurpWebhookTasksDocument, SearchMailSlurpWebhookTasksDocument, ExportSchemaMailSlurpWebhookTasksDocument, ExportDataMailSlurpWebhookTasksDocument, CustomQueryMailSlurpWebhookTasksDocument, CustomQueryIdMailSlurpWebhookTasksDocument, UsedMailSlurpWebhookTasksDocument, ExistMailSlurpWebhookTasksDocument, InsertMailSlurpWebhookTasksDocument, UpdateMailSlurpWebhookTasksDocument, ImportDataMailSlurpWebhookTasksDocument, UpdateManyMailSlurpWebhookTasksDocument, DeleteMailSlurpWebhookTasksDocument } from '../gqls'
import { ServiceSingleResultOfMailSlurpWebhookTask, QueryContextOfMailSlurpWebhookTask, FilterOfMailSlurpWebhookTask, ServiceSingleResultOfInt64, ServiceListResultOfMailSlurpWebhookTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, MailSlurpWebhookTaskInput, FieldUpdateOperatorOfMailSlurpWebhookTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MailSlurpWebhookTaskBaseService {
    
public modelName = 'mailSlurpWebhookTask';
public modelPluralName = 'mailSlurpWebhookTasks';

	private getMailSlurpWebhookTasksQuery: GetMailSlurpWebhookTasksDocument;
	private firstMailSlurpWebhookTasksQuery: FirstMailSlurpWebhookTasksDocument;
	private countMailSlurpWebhookTasksQuery: CountMailSlurpWebhookTasksDocument;
	private findMailSlurpWebhookTasksQuery: FindMailSlurpWebhookTasksDocument;
	private searchMailSlurpWebhookTasksQuery: SearchMailSlurpWebhookTasksDocument;
	private exportSchemaMailSlurpWebhookTasksQuery: ExportSchemaMailSlurpWebhookTasksDocument;
	private exportDataMailSlurpWebhookTasksQuery: ExportDataMailSlurpWebhookTasksDocument;
	private customQueryMailSlurpWebhookTasksQuery: CustomQueryMailSlurpWebhookTasksDocument;
	private customQueryIdMailSlurpWebhookTasksQuery: CustomQueryIdMailSlurpWebhookTasksDocument;
	private usedMailSlurpWebhookTasksQuery: UsedMailSlurpWebhookTasksDocument;
	private existMailSlurpWebhookTasksQuery: ExistMailSlurpWebhookTasksDocument;
	private insertMailSlurpWebhookTasksMutation: InsertMailSlurpWebhookTasksDocument;
	private updateMailSlurpWebhookTasksMutation: UpdateMailSlurpWebhookTasksDocument;
	private importDataMailSlurpWebhookTasksMutation: ImportDataMailSlurpWebhookTasksDocument;
	private updateManyMailSlurpWebhookTasksMutation: UpdateManyMailSlurpWebhookTasksDocument;
	private deleteMailSlurpWebhookTasksMutation: DeleteMailSlurpWebhookTasksDocument;

	constructor(private injector: Injector) {
		this.getMailSlurpWebhookTasksQuery = this.injector.get(GetMailSlurpWebhookTasksDocument);
		this.firstMailSlurpWebhookTasksQuery = this.injector.get(FirstMailSlurpWebhookTasksDocument);
		this.countMailSlurpWebhookTasksQuery = this.injector.get(CountMailSlurpWebhookTasksDocument);
		this.findMailSlurpWebhookTasksQuery = this.injector.get(FindMailSlurpWebhookTasksDocument);
		this.searchMailSlurpWebhookTasksQuery = this.injector.get(SearchMailSlurpWebhookTasksDocument);
		this.exportSchemaMailSlurpWebhookTasksQuery = this.injector.get(ExportSchemaMailSlurpWebhookTasksDocument);
		this.exportDataMailSlurpWebhookTasksQuery = this.injector.get(ExportDataMailSlurpWebhookTasksDocument);
		this.customQueryMailSlurpWebhookTasksQuery = this.injector.get(CustomQueryMailSlurpWebhookTasksDocument);
		this.customQueryIdMailSlurpWebhookTasksQuery = this.injector.get(CustomQueryIdMailSlurpWebhookTasksDocument);
		this.usedMailSlurpWebhookTasksQuery = this.injector.get(UsedMailSlurpWebhookTasksDocument);
		this.existMailSlurpWebhookTasksQuery = this.injector.get(ExistMailSlurpWebhookTasksDocument);
		this.insertMailSlurpWebhookTasksMutation = this.injector.get(InsertMailSlurpWebhookTasksDocument);
		this.updateMailSlurpWebhookTasksMutation = this.injector.get(UpdateMailSlurpWebhookTasksDocument);
		this.importDataMailSlurpWebhookTasksMutation = this.injector.get(ImportDataMailSlurpWebhookTasksDocument);
		this.updateManyMailSlurpWebhookTasksMutation = this.injector.get(UpdateManyMailSlurpWebhookTasksDocument);
		this.deleteMailSlurpWebhookTasksMutation = this.injector.get(DeleteMailSlurpWebhookTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpWebhookTask> {
    
    		let variables: GetMailSlurpWebhookTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.get));
            }
            else{
                let result:ServiceSingleResultOfMailSlurpWebhookTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailSlurpWebhookTask,
		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpWebhookTask> {
    
    		let variables: FirstMailSlurpWebhookTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMailSlurpWebhookTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMailSlurpWebhookTask,
		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpWebhookTask> {
    
    		let variables: FindMailSlurpWebhookTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMailSlurpWebhookTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpWebhookTask> {
    
    		let variables: SearchMailSlurpWebhookTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMailSlurpWebhookTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMailSlurpWebhookTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMailSlurpWebhookTask,
		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpWebhookTask> {
    
    		let variables: CustomQueryMailSlurpWebhookTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMailSlurpWebhookTask,
		@Args('filter?') filter?: FilterOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMailSlurpWebhookTask> {
    
    		let variables: CustomQueryIdMailSlurpWebhookTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMailSlurpWebhookTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMailSlurpWebhookTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMailSlurpWebhookTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: MailSlurpWebhookTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpWebhookTask> {
    
    		let variables: InsertMailSlurpWebhookTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertMailSlurpWebhookTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMailSlurpWebhookTask> {
    
    		let variables: UpdateMailSlurpWebhookTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateMailSlurpWebhookTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataMailSlurpWebhookTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataMailSlurpWebhookTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfMailSlurpWebhookTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyMailSlurpWebhookTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyMailSlurpWebhookTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteMailSlurpWebhookTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteMailSlurpWebhookTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.mailSlurpWebhookTasks.delete));
    	}
    
}