import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetTypeStaffingsBaseVariables, FirstAssetTypeStaffingsBaseVariables, CountAssetTypeStaffingsBaseVariables, FindAssetTypeStaffingsBaseVariables, SearchAssetTypeStaffingsBaseVariables, ExportSchemaAssetTypeStaffingsBaseVariables, ExportDataAssetTypeStaffingsBaseVariables, CustomQueryAssetTypeStaffingsBaseVariables, CustomQueryIdAssetTypeStaffingsBaseVariables, UsedAssetTypeStaffingsBaseVariables, ExistAssetTypeStaffingsBaseVariables, FindUnassociatedAssetsAssetTypeStaffingsBaseVariables, InsertAssetTypeStaffingsBaseVariables, UpdateAssetTypeStaffingsBaseVariables, ImportDataAssetTypeStaffingsBaseVariables, UpdateManyAssetTypeStaffingsBaseVariables, DeleteAssetTypeStaffingsBaseVariables, AddAssetsAssetTypeStaffingsBaseVariables, RemoveAssetsAssetTypeStaffingsBaseVariables } from '../gqls'
import { GetAssetTypeStaffingsDocument, FirstAssetTypeStaffingsDocument, CountAssetTypeStaffingsDocument, FindAssetTypeStaffingsDocument, SearchAssetTypeStaffingsDocument, ExportSchemaAssetTypeStaffingsDocument, ExportDataAssetTypeStaffingsDocument, CustomQueryAssetTypeStaffingsDocument, CustomQueryIdAssetTypeStaffingsDocument, UsedAssetTypeStaffingsDocument, ExistAssetTypeStaffingsDocument, FindUnassociatedAssetsAssetTypeStaffingsDocument, InsertAssetTypeStaffingsDocument, UpdateAssetTypeStaffingsDocument, ImportDataAssetTypeStaffingsDocument, UpdateManyAssetTypeStaffingsDocument, DeleteAssetTypeStaffingsDocument, AddAssetsAssetTypeStaffingsDocument, RemoveAssetsAssetTypeStaffingsDocument } from '../gqls'
import { ServiceSingleResultOfAssetTypeStaffing, QueryContextOfAssetTypeStaffing, FilterOfAssetTypeStaffing, ServiceSingleResultOfInt64, ServiceListResultOfAssetTypeStaffing, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, AssetTypeStaffingInput, FieldUpdateOperatorOfAssetTypeStaffing } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetTypeStaffingBaseService {
    
public modelName = 'assetTypeStaffing';
public modelPluralName = 'assetTypeStaffings';

	private getAssetTypeStaffingsQuery: GetAssetTypeStaffingsDocument;
	private firstAssetTypeStaffingsQuery: FirstAssetTypeStaffingsDocument;
	private countAssetTypeStaffingsQuery: CountAssetTypeStaffingsDocument;
	private findAssetTypeStaffingsQuery: FindAssetTypeStaffingsDocument;
	private searchAssetTypeStaffingsQuery: SearchAssetTypeStaffingsDocument;
	private exportSchemaAssetTypeStaffingsQuery: ExportSchemaAssetTypeStaffingsDocument;
	private exportDataAssetTypeStaffingsQuery: ExportDataAssetTypeStaffingsDocument;
	private customQueryAssetTypeStaffingsQuery: CustomQueryAssetTypeStaffingsDocument;
	private customQueryIdAssetTypeStaffingsQuery: CustomQueryIdAssetTypeStaffingsDocument;
	private usedAssetTypeStaffingsQuery: UsedAssetTypeStaffingsDocument;
	private existAssetTypeStaffingsQuery: ExistAssetTypeStaffingsDocument;
	private findUnassociatedAssetsAssetTypeStaffingsQuery: FindUnassociatedAssetsAssetTypeStaffingsDocument;
	private insertAssetTypeStaffingsMutation: InsertAssetTypeStaffingsDocument;
	private updateAssetTypeStaffingsMutation: UpdateAssetTypeStaffingsDocument;
	private importDataAssetTypeStaffingsMutation: ImportDataAssetTypeStaffingsDocument;
	private updateManyAssetTypeStaffingsMutation: UpdateManyAssetTypeStaffingsDocument;
	private deleteAssetTypeStaffingsMutation: DeleteAssetTypeStaffingsDocument;
	private addAssetsAssetTypeStaffingsMutation: AddAssetsAssetTypeStaffingsDocument;
	private removeAssetsAssetTypeStaffingsMutation: RemoveAssetsAssetTypeStaffingsDocument;

	constructor(private injector: Injector) {
		this.getAssetTypeStaffingsQuery = this.injector.get(GetAssetTypeStaffingsDocument);
		this.firstAssetTypeStaffingsQuery = this.injector.get(FirstAssetTypeStaffingsDocument);
		this.countAssetTypeStaffingsQuery = this.injector.get(CountAssetTypeStaffingsDocument);
		this.findAssetTypeStaffingsQuery = this.injector.get(FindAssetTypeStaffingsDocument);
		this.searchAssetTypeStaffingsQuery = this.injector.get(SearchAssetTypeStaffingsDocument);
		this.exportSchemaAssetTypeStaffingsQuery = this.injector.get(ExportSchemaAssetTypeStaffingsDocument);
		this.exportDataAssetTypeStaffingsQuery = this.injector.get(ExportDataAssetTypeStaffingsDocument);
		this.customQueryAssetTypeStaffingsQuery = this.injector.get(CustomQueryAssetTypeStaffingsDocument);
		this.customQueryIdAssetTypeStaffingsQuery = this.injector.get(CustomQueryIdAssetTypeStaffingsDocument);
		this.usedAssetTypeStaffingsQuery = this.injector.get(UsedAssetTypeStaffingsDocument);
		this.existAssetTypeStaffingsQuery = this.injector.get(ExistAssetTypeStaffingsDocument);
		this.findUnassociatedAssetsAssetTypeStaffingsQuery = this.injector.get(FindUnassociatedAssetsAssetTypeStaffingsDocument);
		this.insertAssetTypeStaffingsMutation = this.injector.get(InsertAssetTypeStaffingsDocument);
		this.updateAssetTypeStaffingsMutation = this.injector.get(UpdateAssetTypeStaffingsDocument);
		this.importDataAssetTypeStaffingsMutation = this.injector.get(ImportDataAssetTypeStaffingsDocument);
		this.updateManyAssetTypeStaffingsMutation = this.injector.get(UpdateManyAssetTypeStaffingsDocument);
		this.deleteAssetTypeStaffingsMutation = this.injector.get(DeleteAssetTypeStaffingsDocument);
		this.addAssetsAssetTypeStaffingsMutation = this.injector.get(AddAssetsAssetTypeStaffingsDocument);
		this.removeAssetsAssetTypeStaffingsMutation = this.injector.get(RemoveAssetsAssetTypeStaffingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeStaffing> {
    
    		let variables: GetAssetTypeStaffingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.get));
            }
            else{
                let result:ServiceSingleResultOfAssetTypeStaffing={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetTypeStaffing,
		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeStaffing> {
    
    		let variables: FirstAssetTypeStaffingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetTypeStaffingsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetTypeStaffing,
		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeStaffing> {
    
    		let variables: FindAssetTypeStaffingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetTypeStaffing,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeStaffing> {
    
    		let variables: SearchAssetTypeStaffingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetTypeStaffingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetTypeStaffingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetTypeStaffing,
		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeStaffing> {
    
    		let variables: CustomQueryAssetTypeStaffingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetTypeStaffing,
		@Args('filter?') filter?: FilterOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeStaffing> {
    
    		let variables: CustomQueryIdAssetTypeStaffingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetTypeStaffingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetTypeStaffingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetTypeStaffingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetTypeStaffingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetTypeStaffingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeStaffing> {
    
    		let variables: InsertAssetTypeStaffingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeStaffing> {
    
    		let variables: UpdateAssetTypeStaffingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetTypeStaffingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetTypeStaffing,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetTypeStaffingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetTypeStaffingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetTypeStaffingsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetTypeStaffingsBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetTypeStaffingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeStaffings.removeAssets));
    	}
    
}