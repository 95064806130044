import { Component, OnInit, Input, HostBinding } from '@angular/core';
import { ValidationErrorType } from '@clarilog/core';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';

@Component({
  selector: 'cl-work-page-errors',
  templateUrl: './work-page-errors.component.html',
  styleUrls: ['./work-page-errors.component.scss']
})
export class WorkPageErrorsComponent {
  /** Obtient ou définit les erreurs. */
  @Input() errors: ValidationError[];
  /** Host le display style. */
  @HostBinding('style.display')
  get display(): string {
    return this.errors == undefined || this.errors.filter(e => e.property == undefined).length === 0 ? 'none' : 'block';
  }
  constructor() { }
}
