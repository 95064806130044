
import { LoanAppointmentBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { GqlField, GqlSubField } from '../helpers';

@Injectable({providedIn: 'root'})
export class LoanAppointmentCoreService extends LoanAppointmentBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
    public loanAppointmentFields(): Array<
    GqlField | GqlSubField
  > {
    return [
      GqlSubField.create('data', [
        GqlField.create('allDay'),
        GqlField.create('description'),
        GqlField.create('endDate'),
        GqlField.create('endDateTimeZone'),
        GqlField.create('recurrenceException'),
        GqlField.create('recurrenceRule'),
        GqlField.create('startDate'),
        GqlField.create('startDateTimeZone'),
        GqlField.create('text'),
        GqlField.create('disabled'),
        GqlField.create('html'),
        GqlField.create('template'),
        GqlField.create('id'),
        GqlField.create('loanId'),
      ]),
    ];
  }
}