<dx-tab-panel
  #tabPanel
  [selectedIndex]="0"
  [height]="'100%'"
  [dataSource]="source"
  [loop]="false"
  [animationEnabled]="false"
  [swipeEnabled]="false"
  [showNavButtons]="false"
  (onSelectionChanged)="onSelectionChanged($event)"
  (onContentReady)="onContentReady($event)"
  (onInitialized)="onInitialized($event)"
>
  <div class='testBM'>
    <div *dxTemplate="let title of 'title'">
    <span [title]="title.hint"
    >{{ title.title }}
      <i
        class="fal fa-exclamation-circle error"
        *ngIf="valid(parent.form.get(title.item.name), title)"
      ></i>
    </span>
    </div>
  </div>

<div>
  <div *dxTemplate="let item of 'item'">
    <div [formGroup]="parent.form.get(item.item.name)">
      <clc-work-item-group-dynamic
        [data]="item.item"
        [state]="state"
        (onLoaded)="workItemLoaded($event)"
      >
      </clc-work-item-group-dynamic>
    </div>
  </div>
</div>

</dx-tab-panel>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
