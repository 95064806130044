<dx-load-indicator class="button-indicator" [visible]='control.readOnly != true && isInit === false' ></dx-load-indicator>
<dx-tag-box
  [readOnly]="control.readOnly || !isInit"
  [value]="value"
  [showClearButton]="control.options.showClearButton"
  [placeholder]="control.options.emptyText"
  [dataSource]='tagBoxDataSource'
  [searchMode]="'contains'"
  [minSearchLength]="0"
  [searchEnabled]="true"
  [showSelectionControls]="true"
  [acceptCustomValue]="true"
  [multiline]="true"
  (onCustomItemCreating)="addTagBoxCustomItem($event, control)"
  (onInitialized)="initialiseTagBox($event, control)"
  (onValueChanged)="onTagBoxValueChanged($event, control)"
  (onFocusOut)="lostFocusTagBox($event, control)"
  (onKeyUp)="tagBoxKeyPressed($event, control)"
  [itemTemplate]="'item'"
>
  <ng-container *ngIf="control?.options?.itemTemplate">
    <div *dxTemplate="let data of 'item'">
      <ng-template
        *ngTemplateOutlet="
          templateService.get(control.options.itemTemplate);
          context: { $implicit: data }
        "
      >
      </ng-template>
    </div>
  </ng-container>
</dx-tag-box>
