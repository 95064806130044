<dx-popup
  [height]="heightPopup"
  [width]="widthPopup"
  [(visible)]="isPopupOpen"
  [showTitle]="false"
  [ngClass]="'cl-popup'"
>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="isPopupOpen = false"></dx-button>
  </div>
  <clc-work-form
    *ngIf="modelState != undefined"
    [showBack]="false"
    [model]="modelState"
    [isExternalForm]="true"
    [displaySaveIcon]="displaySaveIcon"
    [service]="service"
    (onSaved)="saved($event)"
    [beforeSave]="beforeSave"
    [afterSave]="afterSave"
    [saveTilte]="saveTitle"
    [saveSplitButton]="saveSplitButton"
    [(title)]="title"
    [(readOnly)]="readOnly"
  ></clc-work-form>
</dx-popup>
