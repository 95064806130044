import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLogonHistoriesBaseVariables, FirstLogonHistoriesBaseVariables, CountLogonHistoriesBaseVariables, FindLogonHistoriesBaseVariables, SearchLogonHistoriesBaseVariables, ExportSchemaLogonHistoriesBaseVariables, ExportDataLogonHistoriesBaseVariables, CustomQueryLogonHistoriesBaseVariables, CustomQueryIdLogonHistoriesBaseVariables, UsedLogonHistoriesBaseVariables, ExistLogonHistoriesBaseVariables } from '../gqls'
import { GetLogonHistoriesDocument, FirstLogonHistoriesDocument, CountLogonHistoriesDocument, FindLogonHistoriesDocument, SearchLogonHistoriesDocument, ExportSchemaLogonHistoriesDocument, ExportDataLogonHistoriesDocument, CustomQueryLogonHistoriesDocument, CustomQueryIdLogonHistoriesDocument, UsedLogonHistoriesDocument, ExistLogonHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfLogonHistory, QueryContextOfLogonHistory, FilterOfLogonHistory, ServiceSingleResultOfInt64, ServiceListResultOfLogonHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LogonHistoryBaseService {
    
public modelName = 'logonHistory';
public modelPluralName = 'logonHistories';

	private getLogonHistoriesQuery: GetLogonHistoriesDocument;
	private firstLogonHistoriesQuery: FirstLogonHistoriesDocument;
	private countLogonHistoriesQuery: CountLogonHistoriesDocument;
	private findLogonHistoriesQuery: FindLogonHistoriesDocument;
	private searchLogonHistoriesQuery: SearchLogonHistoriesDocument;
	private exportSchemaLogonHistoriesQuery: ExportSchemaLogonHistoriesDocument;
	private exportDataLogonHistoriesQuery: ExportDataLogonHistoriesDocument;
	private customQueryLogonHistoriesQuery: CustomQueryLogonHistoriesDocument;
	private customQueryIdLogonHistoriesQuery: CustomQueryIdLogonHistoriesDocument;
	private usedLogonHistoriesQuery: UsedLogonHistoriesDocument;
	private existLogonHistoriesQuery: ExistLogonHistoriesDocument;

	constructor(private injector: Injector) {
		this.getLogonHistoriesQuery = this.injector.get(GetLogonHistoriesDocument);
		this.firstLogonHistoriesQuery = this.injector.get(FirstLogonHistoriesDocument);
		this.countLogonHistoriesQuery = this.injector.get(CountLogonHistoriesDocument);
		this.findLogonHistoriesQuery = this.injector.get(FindLogonHistoriesDocument);
		this.searchLogonHistoriesQuery = this.injector.get(SearchLogonHistoriesDocument);
		this.exportSchemaLogonHistoriesQuery = this.injector.get(ExportSchemaLogonHistoriesDocument);
		this.exportDataLogonHistoriesQuery = this.injector.get(ExportDataLogonHistoriesDocument);
		this.customQueryLogonHistoriesQuery = this.injector.get(CustomQueryLogonHistoriesDocument);
		this.customQueryIdLogonHistoriesQuery = this.injector.get(CustomQueryIdLogonHistoriesDocument);
		this.usedLogonHistoriesQuery = this.injector.get(UsedLogonHistoriesDocument);
		this.existLogonHistoriesQuery = this.injector.get(ExistLogonHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogonHistory> {
    
    		let variables: GetLogonHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.get));
            }
            else{
                let result:ServiceSingleResultOfLogonHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogonHistory,
		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogonHistory> {
    
    		let variables: FirstLogonHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLogonHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogonHistory,
		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonHistory> {
    
    		let variables: FindLogonHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLogonHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonHistory> {
    
    		let variables: SearchLogonHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLogonHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLogonHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLogonHistory,
		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonHistory> {
    
    		let variables: CustomQueryLogonHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLogonHistory,
		@Args('filter?') filter?: FilterOfLogonHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogonHistory> {
    
    		let variables: CustomQueryIdLogonHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLogonHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLogonHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLogonHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logonHistories.exist));
    	}
    
    
}