import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowNotificationTeamsBaseVariables, FirstWorkflowNotificationTeamsBaseVariables, CountWorkflowNotificationTeamsBaseVariables, FindWorkflowNotificationTeamsBaseVariables, SearchWorkflowNotificationTeamsBaseVariables, ExportSchemaWorkflowNotificationTeamsBaseVariables, ExportDataWorkflowNotificationTeamsBaseVariables, CustomQueryWorkflowNotificationTeamsBaseVariables, CustomQueryIdWorkflowNotificationTeamsBaseVariables, UsedWorkflowNotificationTeamsBaseVariables, ExistWorkflowNotificationTeamsBaseVariables, InsertWorkflowNotificationTeamsBaseVariables, UpdateWorkflowNotificationTeamsBaseVariables, ImportDataWorkflowNotificationTeamsBaseVariables, UpdateManyWorkflowNotificationTeamsBaseVariables, DeleteWorkflowNotificationTeamsBaseVariables } from '../gqls'
import { GetWorkflowNotificationTeamsDocument, FirstWorkflowNotificationTeamsDocument, CountWorkflowNotificationTeamsDocument, FindWorkflowNotificationTeamsDocument, SearchWorkflowNotificationTeamsDocument, ExportSchemaWorkflowNotificationTeamsDocument, ExportDataWorkflowNotificationTeamsDocument, CustomQueryWorkflowNotificationTeamsDocument, CustomQueryIdWorkflowNotificationTeamsDocument, UsedWorkflowNotificationTeamsDocument, ExistWorkflowNotificationTeamsDocument, InsertWorkflowNotificationTeamsDocument, UpdateWorkflowNotificationTeamsDocument, ImportDataWorkflowNotificationTeamsDocument, UpdateManyWorkflowNotificationTeamsDocument, DeleteWorkflowNotificationTeamsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowNotificationTeams, QueryContextOfWorkflowNotificationTeams, FilterOfWorkflowNotificationTeams, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowNotificationTeams, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowNotificationTeamsInput, FieldUpdateOperatorOfWorkflowNotificationTeams } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowNotificationTeamBaseService {
    
public modelName = 'workflowNotificationTeam';
public modelPluralName = 'workflowNotificationTeams';

	private getWorkflowNotificationTeamsQuery: GetWorkflowNotificationTeamsDocument;
	private firstWorkflowNotificationTeamsQuery: FirstWorkflowNotificationTeamsDocument;
	private countWorkflowNotificationTeamsQuery: CountWorkflowNotificationTeamsDocument;
	private findWorkflowNotificationTeamsQuery: FindWorkflowNotificationTeamsDocument;
	private searchWorkflowNotificationTeamsQuery: SearchWorkflowNotificationTeamsDocument;
	private exportSchemaWorkflowNotificationTeamsQuery: ExportSchemaWorkflowNotificationTeamsDocument;
	private exportDataWorkflowNotificationTeamsQuery: ExportDataWorkflowNotificationTeamsDocument;
	private customQueryWorkflowNotificationTeamsQuery: CustomQueryWorkflowNotificationTeamsDocument;
	private customQueryIdWorkflowNotificationTeamsQuery: CustomQueryIdWorkflowNotificationTeamsDocument;
	private usedWorkflowNotificationTeamsQuery: UsedWorkflowNotificationTeamsDocument;
	private existWorkflowNotificationTeamsQuery: ExistWorkflowNotificationTeamsDocument;
	private insertWorkflowNotificationTeamsMutation: InsertWorkflowNotificationTeamsDocument;
	private updateWorkflowNotificationTeamsMutation: UpdateWorkflowNotificationTeamsDocument;
	private importDataWorkflowNotificationTeamsMutation: ImportDataWorkflowNotificationTeamsDocument;
	private updateManyWorkflowNotificationTeamsMutation: UpdateManyWorkflowNotificationTeamsDocument;
	private deleteWorkflowNotificationTeamsMutation: DeleteWorkflowNotificationTeamsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowNotificationTeamsQuery = this.injector.get(GetWorkflowNotificationTeamsDocument);
		this.firstWorkflowNotificationTeamsQuery = this.injector.get(FirstWorkflowNotificationTeamsDocument);
		this.countWorkflowNotificationTeamsQuery = this.injector.get(CountWorkflowNotificationTeamsDocument);
		this.findWorkflowNotificationTeamsQuery = this.injector.get(FindWorkflowNotificationTeamsDocument);
		this.searchWorkflowNotificationTeamsQuery = this.injector.get(SearchWorkflowNotificationTeamsDocument);
		this.exportSchemaWorkflowNotificationTeamsQuery = this.injector.get(ExportSchemaWorkflowNotificationTeamsDocument);
		this.exportDataWorkflowNotificationTeamsQuery = this.injector.get(ExportDataWorkflowNotificationTeamsDocument);
		this.customQueryWorkflowNotificationTeamsQuery = this.injector.get(CustomQueryWorkflowNotificationTeamsDocument);
		this.customQueryIdWorkflowNotificationTeamsQuery = this.injector.get(CustomQueryIdWorkflowNotificationTeamsDocument);
		this.usedWorkflowNotificationTeamsQuery = this.injector.get(UsedWorkflowNotificationTeamsDocument);
		this.existWorkflowNotificationTeamsQuery = this.injector.get(ExistWorkflowNotificationTeamsDocument);
		this.insertWorkflowNotificationTeamsMutation = this.injector.get(InsertWorkflowNotificationTeamsDocument);
		this.updateWorkflowNotificationTeamsMutation = this.injector.get(UpdateWorkflowNotificationTeamsDocument);
		this.importDataWorkflowNotificationTeamsMutation = this.injector.get(ImportDataWorkflowNotificationTeamsDocument);
		this.updateManyWorkflowNotificationTeamsMutation = this.injector.get(UpdateManyWorkflowNotificationTeamsDocument);
		this.deleteWorkflowNotificationTeamsMutation = this.injector.get(DeleteWorkflowNotificationTeamsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowNotificationTeams> {
    
    		let variables: GetWorkflowNotificationTeamsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowNotificationTeams={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowNotificationTeams,
		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowNotificationTeams> {
    
    		let variables: FirstWorkflowNotificationTeamsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowNotificationTeamsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowNotificationTeams,
		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowNotificationTeams> {
    
    		let variables: FindWorkflowNotificationTeamsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowNotificationTeams,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowNotificationTeams> {
    
    		let variables: SearchWorkflowNotificationTeamsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowNotificationTeamsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowNotificationTeamsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowNotificationTeams,
		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowNotificationTeams> {
    
    		let variables: CustomQueryWorkflowNotificationTeamsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowNotificationTeams,
		@Args('filter?') filter?: FilterOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowNotificationTeams> {
    
    		let variables: CustomQueryIdWorkflowNotificationTeamsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowNotificationTeamsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowNotificationTeamsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowNotificationTeamsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowNotificationTeamsInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowNotificationTeams> {
    
    		let variables: InsertWorkflowNotificationTeamsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowNotificationTeamsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowNotificationTeams> {
    
    		let variables: UpdateWorkflowNotificationTeamsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowNotificationTeamsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowNotificationTeamsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowNotificationTeamsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowNotificationTeams,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowNotificationTeamsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowNotificationTeamsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowNotificationTeamsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowNotificationTeamsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowNotificationTeams.delete));
    	}
    
}