import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCheckFileTasksBaseVariables, FirstCheckFileTasksBaseVariables, CountCheckFileTasksBaseVariables, FindCheckFileTasksBaseVariables, SearchCheckFileTasksBaseVariables, ExportSchemaCheckFileTasksBaseVariables, ExportDataCheckFileTasksBaseVariables, CustomQueryCheckFileTasksBaseVariables, CustomQueryIdCheckFileTasksBaseVariables, UsedCheckFileTasksBaseVariables, ExistCheckFileTasksBaseVariables, InsertCheckFileTasksBaseVariables, UpdateCheckFileTasksBaseVariables, ImportDataCheckFileTasksBaseVariables, UpdateManyCheckFileTasksBaseVariables, DeleteCheckFileTasksBaseVariables } from '../gqls'
import { GetCheckFileTasksDocument, FirstCheckFileTasksDocument, CountCheckFileTasksDocument, FindCheckFileTasksDocument, SearchCheckFileTasksDocument, ExportSchemaCheckFileTasksDocument, ExportDataCheckFileTasksDocument, CustomQueryCheckFileTasksDocument, CustomQueryIdCheckFileTasksDocument, UsedCheckFileTasksDocument, ExistCheckFileTasksDocument, InsertCheckFileTasksDocument, UpdateCheckFileTasksDocument, ImportDataCheckFileTasksDocument, UpdateManyCheckFileTasksDocument, DeleteCheckFileTasksDocument } from '../gqls'
import { ServiceSingleResultOfCheckFileTask, QueryContextOfCheckFileTask, FilterOfCheckFileTask, ServiceSingleResultOfInt64, ServiceListResultOfCheckFileTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, CheckFileTaskInput, FieldUpdateOperatorOfCheckFileTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CheckFileTaskBaseService {
    
public modelName = 'checkFileTask';
public modelPluralName = 'checkFileTasks';

	private getCheckFileTasksQuery: GetCheckFileTasksDocument;
	private firstCheckFileTasksQuery: FirstCheckFileTasksDocument;
	private countCheckFileTasksQuery: CountCheckFileTasksDocument;
	private findCheckFileTasksQuery: FindCheckFileTasksDocument;
	private searchCheckFileTasksQuery: SearchCheckFileTasksDocument;
	private exportSchemaCheckFileTasksQuery: ExportSchemaCheckFileTasksDocument;
	private exportDataCheckFileTasksQuery: ExportDataCheckFileTasksDocument;
	private customQueryCheckFileTasksQuery: CustomQueryCheckFileTasksDocument;
	private customQueryIdCheckFileTasksQuery: CustomQueryIdCheckFileTasksDocument;
	private usedCheckFileTasksQuery: UsedCheckFileTasksDocument;
	private existCheckFileTasksQuery: ExistCheckFileTasksDocument;
	private insertCheckFileTasksMutation: InsertCheckFileTasksDocument;
	private updateCheckFileTasksMutation: UpdateCheckFileTasksDocument;
	private importDataCheckFileTasksMutation: ImportDataCheckFileTasksDocument;
	private updateManyCheckFileTasksMutation: UpdateManyCheckFileTasksDocument;
	private deleteCheckFileTasksMutation: DeleteCheckFileTasksDocument;

	constructor(private injector: Injector) {
		this.getCheckFileTasksQuery = this.injector.get(GetCheckFileTasksDocument);
		this.firstCheckFileTasksQuery = this.injector.get(FirstCheckFileTasksDocument);
		this.countCheckFileTasksQuery = this.injector.get(CountCheckFileTasksDocument);
		this.findCheckFileTasksQuery = this.injector.get(FindCheckFileTasksDocument);
		this.searchCheckFileTasksQuery = this.injector.get(SearchCheckFileTasksDocument);
		this.exportSchemaCheckFileTasksQuery = this.injector.get(ExportSchemaCheckFileTasksDocument);
		this.exportDataCheckFileTasksQuery = this.injector.get(ExportDataCheckFileTasksDocument);
		this.customQueryCheckFileTasksQuery = this.injector.get(CustomQueryCheckFileTasksDocument);
		this.customQueryIdCheckFileTasksQuery = this.injector.get(CustomQueryIdCheckFileTasksDocument);
		this.usedCheckFileTasksQuery = this.injector.get(UsedCheckFileTasksDocument);
		this.existCheckFileTasksQuery = this.injector.get(ExistCheckFileTasksDocument);
		this.insertCheckFileTasksMutation = this.injector.get(InsertCheckFileTasksDocument);
		this.updateCheckFileTasksMutation = this.injector.get(UpdateCheckFileTasksDocument);
		this.importDataCheckFileTasksMutation = this.injector.get(ImportDataCheckFileTasksDocument);
		this.updateManyCheckFileTasksMutation = this.injector.get(UpdateManyCheckFileTasksDocument);
		this.deleteCheckFileTasksMutation = this.injector.get(DeleteCheckFileTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckFileTask> {
    
    		let variables: GetCheckFileTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.get));
            }
            else{
                let result:ServiceSingleResultOfCheckFileTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCheckFileTask,
		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckFileTask> {
    
    		let variables: FirstCheckFileTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCheckFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCheckFileTask,
		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckFileTask> {
    
    		let variables: FindCheckFileTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCheckFileTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckFileTask> {
    
    		let variables: SearchCheckFileTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCheckFileTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCheckFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCheckFileTask,
		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckFileTask> {
    
    		let variables: CustomQueryCheckFileTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCheckFileTask,
		@Args('filter?') filter?: FilterOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckFileTask> {
    
    		let variables: CustomQueryIdCheckFileTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCheckFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCheckFileTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCheckFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CheckFileTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckFileTask> {
    
    		let variables: InsertCheckFileTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertCheckFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckFileTask> {
    
    		let variables: UpdateCheckFileTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCheckFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCheckFileTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCheckFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCheckFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCheckFileTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCheckFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCheckFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCheckFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkFileTasks.delete));
    	}
    
}