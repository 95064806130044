import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCommandsBaseVariables, FirstCommandsBaseVariables, CountCommandsBaseVariables, FindCommandsBaseVariables, SearchCommandsBaseVariables, ExportSchemaCommandsBaseVariables, ExportDataCommandsBaseVariables, CustomQueryCommandsBaseVariables, CustomQueryIdCommandsBaseVariables, FindUnassociatedSecurityGroupsCommandsBaseVariables, UsedCommandsBaseVariables, FindByAssetCommandsBaseVariables, DefaultByAssetCommandsBaseVariables, ExecuteCommandCommandsBaseVariables, FindCommandAssetCategoriesCommandsBaseVariables, FindByAssetCategoryCommandsBaseVariables, FindCustomCommandAssetCategoriesCommandsBaseVariables, FindAvailableFilterExceptionAssetsCommandsBaseVariables, AutoLaunchByAssetCommandsBaseVariables, ExistCommandsBaseVariables, FindRecyclesCommandsBaseVariables, CountRecyclesCommandsBaseVariables, ReadOnlyCommandsBaseVariables, FindArchivedCommandsBaseVariables, CountAllCommandsBaseVariables, FindDynamicPropertyFieldsCommandsBaseVariables, FindUnassociatedExceptionAssetsCommandsBaseVariables, InsertCommandsBaseVariables, UpdateCommandsBaseVariables, ImportDataCommandsBaseVariables, UpdateManyCommandsBaseVariables, DeleteCommandsBaseVariables, AddSecurityGroupCommandsBaseVariables, RemoveSecurityGroupCommandsBaseVariables, RestoreCommandsBaseVariables, RecycleCommandsBaseVariables, RestoreArchivedCommandsBaseVariables, ArchivedCommandsBaseVariables, AddFileDynamicFieldCommandsBaseVariables, RemoveFileDynamicFieldCommandsBaseVariables, AddExceptionAssetsCommandsBaseVariables, RemoveExceptionAssetsCommandsBaseVariables } from '../gqls'
import { GetCommandsDocument, FirstCommandsDocument, CountCommandsDocument, FindCommandsDocument, SearchCommandsDocument, ExportSchemaCommandsDocument, ExportDataCommandsDocument, CustomQueryCommandsDocument, CustomQueryIdCommandsDocument, FindUnassociatedSecurityGroupsCommandsDocument, UsedCommandsDocument, FindByAssetCommandsDocument, DefaultByAssetCommandsDocument, ExecuteCommandCommandsDocument, FindCommandAssetCategoriesCommandsDocument, FindByAssetCategoryCommandsDocument, FindCustomCommandAssetCategoriesCommandsDocument, FindAvailableFilterExceptionAssetsCommandsDocument, AutoLaunchByAssetCommandsDocument, ExistCommandsDocument, FindRecyclesCommandsDocument, CountRecyclesCommandsDocument, ReadOnlyCommandsDocument, FindArchivedCommandsDocument, CountAllCommandsDocument, FindDynamicPropertyFieldsCommandsDocument, FindUnassociatedExceptionAssetsCommandsDocument, InsertCommandsDocument, UpdateCommandsDocument, ImportDataCommandsDocument, UpdateManyCommandsDocument, DeleteCommandsDocument, AddSecurityGroupCommandsDocument, RemoveSecurityGroupCommandsDocument, RestoreCommandsDocument, RecycleCommandsDocument, RestoreArchivedCommandsDocument, ArchivedCommandsDocument, AddFileDynamicFieldCommandsDocument, RemoveFileDynamicFieldCommandsDocument, AddExceptionAssetsCommandsDocument, RemoveExceptionAssetsCommandsDocument } from '../gqls'
import { ServiceSingleResultOfCommand, QueryContextOfCommand, FilterOfCommand, ServiceSingleResultOfInt64, ServiceListResultOfCommand, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceSingleResultOfBoolean, ServiceSingleResultOfGuid, ServiceListResultOfCommandAssetCategory, QueryContextOfCommandAssetCategory, FilterOfCommandAssetCategory, ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfCommand, CommandInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CommandBaseService {
    
public modelName = 'command';
public modelPluralName = 'commands';

	private getCommandsQuery: GetCommandsDocument;
	private firstCommandsQuery: FirstCommandsDocument;
	private countCommandsQuery: CountCommandsDocument;
	private findCommandsQuery: FindCommandsDocument;
	private searchCommandsQuery: SearchCommandsDocument;
	private exportSchemaCommandsQuery: ExportSchemaCommandsDocument;
	private exportDataCommandsQuery: ExportDataCommandsDocument;
	private customQueryCommandsQuery: CustomQueryCommandsDocument;
	private customQueryIdCommandsQuery: CustomQueryIdCommandsDocument;
	private findUnassociatedSecurityGroupsCommandsQuery: FindUnassociatedSecurityGroupsCommandsDocument;
	private usedCommandsQuery: UsedCommandsDocument;
	private findByAssetCommandsQuery: FindByAssetCommandsDocument;
	private defaultByAssetCommandsQuery: DefaultByAssetCommandsDocument;
	private executeCommandCommandsQuery: ExecuteCommandCommandsDocument;
	private findCommandAssetCategoriesCommandsQuery: FindCommandAssetCategoriesCommandsDocument;
	private findByAssetCategoryCommandsQuery: FindByAssetCategoryCommandsDocument;
	private findCustomCommandAssetCategoriesCommandsQuery: FindCustomCommandAssetCategoriesCommandsDocument;
	private findAvailableFilterExceptionAssetsCommandsQuery: FindAvailableFilterExceptionAssetsCommandsDocument;
	private autoLaunchByAssetCommandsQuery: AutoLaunchByAssetCommandsDocument;
	private existCommandsQuery: ExistCommandsDocument;
	private findRecyclesCommandsQuery: FindRecyclesCommandsDocument;
	private countRecyclesCommandsQuery: CountRecyclesCommandsDocument;
	private readOnlyCommandsQuery: ReadOnlyCommandsDocument;
	private findArchivedCommandsQuery: FindArchivedCommandsDocument;
	private countAllCommandsQuery: CountAllCommandsDocument;
	private findDynamicPropertyFieldsCommandsQuery: FindDynamicPropertyFieldsCommandsDocument;
	private findUnassociatedExceptionAssetsCommandsQuery: FindUnassociatedExceptionAssetsCommandsDocument;
	private insertCommandsMutation: InsertCommandsDocument;
	private updateCommandsMutation: UpdateCommandsDocument;
	private importDataCommandsMutation: ImportDataCommandsDocument;
	private updateManyCommandsMutation: UpdateManyCommandsDocument;
	private deleteCommandsMutation: DeleteCommandsDocument;
	private addSecurityGroupCommandsMutation: AddSecurityGroupCommandsDocument;
	private removeSecurityGroupCommandsMutation: RemoveSecurityGroupCommandsDocument;
	private restoreCommandsMutation: RestoreCommandsDocument;
	private recycleCommandsMutation: RecycleCommandsDocument;
	private restoreArchivedCommandsMutation: RestoreArchivedCommandsDocument;
	private archivedCommandsMutation: ArchivedCommandsDocument;
	private addFileDynamicFieldCommandsMutation: AddFileDynamicFieldCommandsDocument;
	private removeFileDynamicFieldCommandsMutation: RemoveFileDynamicFieldCommandsDocument;
	private addExceptionAssetsCommandsMutation: AddExceptionAssetsCommandsDocument;
	private removeExceptionAssetsCommandsMutation: RemoveExceptionAssetsCommandsDocument;

	constructor(private injector: Injector) {
		this.getCommandsQuery = this.injector.get(GetCommandsDocument);
		this.firstCommandsQuery = this.injector.get(FirstCommandsDocument);
		this.countCommandsQuery = this.injector.get(CountCommandsDocument);
		this.findCommandsQuery = this.injector.get(FindCommandsDocument);
		this.searchCommandsQuery = this.injector.get(SearchCommandsDocument);
		this.exportSchemaCommandsQuery = this.injector.get(ExportSchemaCommandsDocument);
		this.exportDataCommandsQuery = this.injector.get(ExportDataCommandsDocument);
		this.customQueryCommandsQuery = this.injector.get(CustomQueryCommandsDocument);
		this.customQueryIdCommandsQuery = this.injector.get(CustomQueryIdCommandsDocument);
		this.findUnassociatedSecurityGroupsCommandsQuery = this.injector.get(FindUnassociatedSecurityGroupsCommandsDocument);
		this.usedCommandsQuery = this.injector.get(UsedCommandsDocument);
		this.findByAssetCommandsQuery = this.injector.get(FindByAssetCommandsDocument);
		this.defaultByAssetCommandsQuery = this.injector.get(DefaultByAssetCommandsDocument);
		this.executeCommandCommandsQuery = this.injector.get(ExecuteCommandCommandsDocument);
		this.findCommandAssetCategoriesCommandsQuery = this.injector.get(FindCommandAssetCategoriesCommandsDocument);
		this.findByAssetCategoryCommandsQuery = this.injector.get(FindByAssetCategoryCommandsDocument);
		this.findCustomCommandAssetCategoriesCommandsQuery = this.injector.get(FindCustomCommandAssetCategoriesCommandsDocument);
		this.findAvailableFilterExceptionAssetsCommandsQuery = this.injector.get(FindAvailableFilterExceptionAssetsCommandsDocument);
		this.autoLaunchByAssetCommandsQuery = this.injector.get(AutoLaunchByAssetCommandsDocument);
		this.existCommandsQuery = this.injector.get(ExistCommandsDocument);
		this.findRecyclesCommandsQuery = this.injector.get(FindRecyclesCommandsDocument);
		this.countRecyclesCommandsQuery = this.injector.get(CountRecyclesCommandsDocument);
		this.readOnlyCommandsQuery = this.injector.get(ReadOnlyCommandsDocument);
		this.findArchivedCommandsQuery = this.injector.get(FindArchivedCommandsDocument);
		this.countAllCommandsQuery = this.injector.get(CountAllCommandsDocument);
		this.findDynamicPropertyFieldsCommandsQuery = this.injector.get(FindDynamicPropertyFieldsCommandsDocument);
		this.findUnassociatedExceptionAssetsCommandsQuery = this.injector.get(FindUnassociatedExceptionAssetsCommandsDocument);
		this.insertCommandsMutation = this.injector.get(InsertCommandsDocument);
		this.updateCommandsMutation = this.injector.get(UpdateCommandsDocument);
		this.importDataCommandsMutation = this.injector.get(ImportDataCommandsDocument);
		this.updateManyCommandsMutation = this.injector.get(UpdateManyCommandsDocument);
		this.deleteCommandsMutation = this.injector.get(DeleteCommandsDocument);
		this.addSecurityGroupCommandsMutation = this.injector.get(AddSecurityGroupCommandsDocument);
		this.removeSecurityGroupCommandsMutation = this.injector.get(RemoveSecurityGroupCommandsDocument);
		this.restoreCommandsMutation = this.injector.get(RestoreCommandsDocument);
		this.recycleCommandsMutation = this.injector.get(RecycleCommandsDocument);
		this.restoreArchivedCommandsMutation = this.injector.get(RestoreArchivedCommandsDocument);
		this.archivedCommandsMutation = this.injector.get(ArchivedCommandsDocument);
		this.addFileDynamicFieldCommandsMutation = this.injector.get(AddFileDynamicFieldCommandsDocument);
		this.removeFileDynamicFieldCommandsMutation = this.injector.get(RemoveFileDynamicFieldCommandsDocument);
		this.addExceptionAssetsCommandsMutation = this.injector.get(AddExceptionAssetsCommandsDocument);
		this.removeExceptionAssetsCommandsMutation = this.injector.get(RemoveExceptionAssetsCommandsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommand> {
    
    		let variables: GetCommandsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.get));
            }
            else{
                let result:ServiceSingleResultOfCommand={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommand> {
    
    		let variables: FirstCommandsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCommandsBaseVariables = {
    			filter: filter
    		}
    				return this.countCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: FindCommandsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCommand,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: SearchCommandsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCommandsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCommandsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: CustomQueryCommandsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: CustomQueryIdCommandsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsCommandsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findUnassociatedSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetId') assetId: string,
		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: FindByAssetCommandsBaseVariables = {
    			assetId: assetId,
			options: options,
			filter: filter
    		}
    				return this.findByAssetCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findByAsset));
    	}

    	/**  */
    	@InjectArgs
    	public defaultByAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetId') assetId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: DefaultByAssetCommandsBaseVariables = {
    			assetId: assetId
    		}
    				return this.defaultByAssetCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.defaultByAsset));
    	}

    	/**  */
    	@InjectArgs
    	public executeCommand(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('commandId') commandId: string,
		@Args('assetId') assetId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommand> {
    
    		let variables: ExecuteCommandCommandsBaseVariables = {
    			assetId: assetId,
			commandId: commandId
    		}
    				return this.executeCommandCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.executeCommand));
    	}

    	/**  */
    	@InjectArgs
    	public findCommandAssetCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommandAssetCategory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfCommandAssetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommandAssetCategory> {
    
    		let variables: FindCommandAssetCategoriesCommandsBaseVariables = {
    			id: id,
			options: options,
			filter: filter
    		}
    				return this.findCommandAssetCategoriesCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findCommandAssetCategories));
    	}

    	/**  */
    	@InjectArgs
    	public findByAssetCategory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: FindByAssetCategoryCommandsBaseVariables = {
    			id: id,
			options: options,
			filter: filter
    		}
    				return this.findByAssetCategoryCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findByAssetCategory));
    	}

    	/**  */
    	@InjectArgs
    	public findCustomCommandAssetCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommandAssetCategory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfCommandAssetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommandAssetCategory> {
    
    		let variables: FindCustomCommandAssetCategoriesCommandsBaseVariables = {
    			id: id,
			options: options,
			filter: filter
    		}
    				return this.findCustomCommandAssetCategoriesCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findCustomCommandAssetCategories));
    	}

    	/**  */
    	@InjectArgs
    	public findAvailableFilterExceptionAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetCategoryIds') assetCategoryIds: Array<string>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindAvailableFilterExceptionAssetsCommandsBaseVariables = {
    			id: id,
			assetCategoryIds: assetCategoryIds,
			options: options,
			filter: filter
    		}
    				return this.findAvailableFilterExceptionAssetsCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findAvailableFilterExceptionAssets));
    	}

    	/**  */
    	@InjectArgs
    	public autoLaunchByAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: AutoLaunchByAssetCommandsBaseVariables = {
    			id: id
    		}
    				return this.autoLaunchByAssetCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.autoLaunchByAsset));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCommandsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: FindRecyclesCommandsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesCommandsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCommandsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommand,
		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommand> {
    
    		let variables: FindArchivedCommandsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllCommandsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsCommandsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedExceptionAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('exceptionAssets', fields, null, [
			GqlSubFieldArg.create('filterOfExceptionAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfExceptionAssets', 'options'),
		]),
		])
		extendedVariables['filterOfExceptionAssets'] = filter;
		extendedVariables['optionsOfExceptionAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.exceptionAssets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedExceptionAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedExceptionAssetsCommandsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedExceptionAssetsCommandsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commands.findUnassociatedExceptionAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CommandInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommand> {
    
    		let variables: InsertCommandsBaseVariables = {
    			entity: entity
    		}
    				return this.insertCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommand> {
    
    		let variables: UpdateCommandsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCommandsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCommand,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCommandsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupCommandsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupCommandsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.removeSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedCommandsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldCommandsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldCommandsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addExceptionAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddExceptionAssetsCommandsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addExceptionAssetsCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.addExceptionAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeExceptionAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveExceptionAssetsCommandsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.removeExceptionAssetsCommandsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commands.removeExceptionAssets));
    	}
    
}