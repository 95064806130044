import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetAcquisitionStatesBaseVariables, FirstAssetAcquisitionStatesBaseVariables, CountAssetAcquisitionStatesBaseVariables, FindAssetAcquisitionStatesBaseVariables, SearchAssetAcquisitionStatesBaseVariables, ExportSchemaAssetAcquisitionStatesBaseVariables, ExportDataAssetAcquisitionStatesBaseVariables, CustomQueryAssetAcquisitionStatesBaseVariables, CustomQueryIdAssetAcquisitionStatesBaseVariables, UsedAssetAcquisitionStatesBaseVariables, ExistAssetAcquisitionStatesBaseVariables, FindUnassociatedAssetsAssetAcquisitionStatesBaseVariables, InsertAssetAcquisitionStatesBaseVariables, UpdateAssetAcquisitionStatesBaseVariables, ImportDataAssetAcquisitionStatesBaseVariables, UpdateManyAssetAcquisitionStatesBaseVariables, DeleteAssetAcquisitionStatesBaseVariables, AddAssetsAssetAcquisitionStatesBaseVariables, RemoveAssetsAssetAcquisitionStatesBaseVariables } from '../gqls'
import { GetAssetAcquisitionStatesDocument, FirstAssetAcquisitionStatesDocument, CountAssetAcquisitionStatesDocument, FindAssetAcquisitionStatesDocument, SearchAssetAcquisitionStatesDocument, ExportSchemaAssetAcquisitionStatesDocument, ExportDataAssetAcquisitionStatesDocument, CustomQueryAssetAcquisitionStatesDocument, CustomQueryIdAssetAcquisitionStatesDocument, UsedAssetAcquisitionStatesDocument, ExistAssetAcquisitionStatesDocument, FindUnassociatedAssetsAssetAcquisitionStatesDocument, InsertAssetAcquisitionStatesDocument, UpdateAssetAcquisitionStatesDocument, ImportDataAssetAcquisitionStatesDocument, UpdateManyAssetAcquisitionStatesDocument, DeleteAssetAcquisitionStatesDocument, AddAssetsAssetAcquisitionStatesDocument, RemoveAssetsAssetAcquisitionStatesDocument } from '../gqls'
import { ServiceSingleResultOfAssetAcquisitionState, QueryContextOfAssetAcquisitionState, FilterOfAssetAcquisitionState, ServiceSingleResultOfInt64, ServiceListResultOfAssetAcquisitionState, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, AssetAcquisitionStateInput, FieldUpdateOperatorOfAssetAcquisitionState } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetAcquisitionStateBaseService {
    
public modelName = 'assetAcquisitionState';
public modelPluralName = 'assetAcquisitionStates';

	private getAssetAcquisitionStatesQuery: GetAssetAcquisitionStatesDocument;
	private firstAssetAcquisitionStatesQuery: FirstAssetAcquisitionStatesDocument;
	private countAssetAcquisitionStatesQuery: CountAssetAcquisitionStatesDocument;
	private findAssetAcquisitionStatesQuery: FindAssetAcquisitionStatesDocument;
	private searchAssetAcquisitionStatesQuery: SearchAssetAcquisitionStatesDocument;
	private exportSchemaAssetAcquisitionStatesQuery: ExportSchemaAssetAcquisitionStatesDocument;
	private exportDataAssetAcquisitionStatesQuery: ExportDataAssetAcquisitionStatesDocument;
	private customQueryAssetAcquisitionStatesQuery: CustomQueryAssetAcquisitionStatesDocument;
	private customQueryIdAssetAcquisitionStatesQuery: CustomQueryIdAssetAcquisitionStatesDocument;
	private usedAssetAcquisitionStatesQuery: UsedAssetAcquisitionStatesDocument;
	private existAssetAcquisitionStatesQuery: ExistAssetAcquisitionStatesDocument;
	private findUnassociatedAssetsAssetAcquisitionStatesQuery: FindUnassociatedAssetsAssetAcquisitionStatesDocument;
	private insertAssetAcquisitionStatesMutation: InsertAssetAcquisitionStatesDocument;
	private updateAssetAcquisitionStatesMutation: UpdateAssetAcquisitionStatesDocument;
	private importDataAssetAcquisitionStatesMutation: ImportDataAssetAcquisitionStatesDocument;
	private updateManyAssetAcquisitionStatesMutation: UpdateManyAssetAcquisitionStatesDocument;
	private deleteAssetAcquisitionStatesMutation: DeleteAssetAcquisitionStatesDocument;
	private addAssetsAssetAcquisitionStatesMutation: AddAssetsAssetAcquisitionStatesDocument;
	private removeAssetsAssetAcquisitionStatesMutation: RemoveAssetsAssetAcquisitionStatesDocument;

	constructor(private injector: Injector) {
		this.getAssetAcquisitionStatesQuery = this.injector.get(GetAssetAcquisitionStatesDocument);
		this.firstAssetAcquisitionStatesQuery = this.injector.get(FirstAssetAcquisitionStatesDocument);
		this.countAssetAcquisitionStatesQuery = this.injector.get(CountAssetAcquisitionStatesDocument);
		this.findAssetAcquisitionStatesQuery = this.injector.get(FindAssetAcquisitionStatesDocument);
		this.searchAssetAcquisitionStatesQuery = this.injector.get(SearchAssetAcquisitionStatesDocument);
		this.exportSchemaAssetAcquisitionStatesQuery = this.injector.get(ExportSchemaAssetAcquisitionStatesDocument);
		this.exportDataAssetAcquisitionStatesQuery = this.injector.get(ExportDataAssetAcquisitionStatesDocument);
		this.customQueryAssetAcquisitionStatesQuery = this.injector.get(CustomQueryAssetAcquisitionStatesDocument);
		this.customQueryIdAssetAcquisitionStatesQuery = this.injector.get(CustomQueryIdAssetAcquisitionStatesDocument);
		this.usedAssetAcquisitionStatesQuery = this.injector.get(UsedAssetAcquisitionStatesDocument);
		this.existAssetAcquisitionStatesQuery = this.injector.get(ExistAssetAcquisitionStatesDocument);
		this.findUnassociatedAssetsAssetAcquisitionStatesQuery = this.injector.get(FindUnassociatedAssetsAssetAcquisitionStatesDocument);
		this.insertAssetAcquisitionStatesMutation = this.injector.get(InsertAssetAcquisitionStatesDocument);
		this.updateAssetAcquisitionStatesMutation = this.injector.get(UpdateAssetAcquisitionStatesDocument);
		this.importDataAssetAcquisitionStatesMutation = this.injector.get(ImportDataAssetAcquisitionStatesDocument);
		this.updateManyAssetAcquisitionStatesMutation = this.injector.get(UpdateManyAssetAcquisitionStatesDocument);
		this.deleteAssetAcquisitionStatesMutation = this.injector.get(DeleteAssetAcquisitionStatesDocument);
		this.addAssetsAssetAcquisitionStatesMutation = this.injector.get(AddAssetsAssetAcquisitionStatesDocument);
		this.removeAssetsAssetAcquisitionStatesMutation = this.injector.get(RemoveAssetsAssetAcquisitionStatesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionState> {
    
    		let variables: GetAssetAcquisitionStatesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.get));
            }
            else{
                let result:ServiceSingleResultOfAssetAcquisitionState={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAcquisitionState,
		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionState> {
    
    		let variables: FirstAssetAcquisitionStatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetAcquisitionStatesBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetAcquisitionState,
		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionState> {
    
    		let variables: FindAssetAcquisitionStatesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetAcquisitionState,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionState> {
    
    		let variables: SearchAssetAcquisitionStatesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetAcquisitionStatesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetAcquisitionStatesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetAcquisitionState,
		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionState> {
    
    		let variables: CustomQueryAssetAcquisitionStatesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetAcquisitionState,
		@Args('filter?') filter?: FilterOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetAcquisitionState> {
    
    		let variables: CustomQueryIdAssetAcquisitionStatesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetAcquisitionStatesBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetAcquisitionStatesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetAcquisitionStatesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetAcquisitionStatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetAcquisitionStateInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionState> {
    
    		let variables: InsertAssetAcquisitionStatesBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetAcquisitionState> {
    
    		let variables: UpdateAssetAcquisitionStatesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetAcquisitionStatesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetAcquisitionState,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetAcquisitionStatesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetAcquisitionStatesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetAcquisitionStatesBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetAcquisitionStatesBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetAcquisitionStatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetAcquisitionStates.removeAssets));
    	}
    
}