import { Location } from '@angular/common';
import { Component } from '@angular/core';
import {
  ActivatedRoute,
  NavigationEnd,
  Route,
  Router,
  Scroll,
  UrlTree,
} from '@angular/router';
import { filter } from 'rxjs/operators';

export type BreadcrumbItem = {
  label: string;
  url: string;
  queryParams?: any;
};

/** Représente le composent beadcrumb. */
@Component({
  selector: 'cl-breadcrumb',
  templateUrl: './breadcrumb.component.html',
  styleUrls: ['./breadcrumb.component.scss'],
})
export class BreadcrumbComponent {
  static readonly ROUTE_DATA_BREADCRUMB = 'breadcrumb';
  items: BreadcrumbItem[];

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private _location: Location,
  ) {
    this.router.events
      .pipe(
        filter((event) => {
          if (
            (this.items == undefined || this.items.length == 0) &&
            event instanceof Scroll
          ) {
            return event.routerEvent instanceof NavigationEnd;
          }

          return event instanceof NavigationEnd;
        }),
      )
      .subscribe(
        () => (this.items = this.createBreadcrumbs(this.activatedRoute.root)),
      );

    function findRecurssive(
      routes: Route[],
      path: string,
      isEdit: boolean = false,
    ): Route {
      for (let route of routes) {
        if (route.path == '') {
          if (route.children !== undefined && route.children.length > 0) {
            let r = findRecurssive(route.children, path, isEdit);

            if (r != undefined) {
              return r;
            }
          }
        } else if (isEdit || route.path == path) {
          return route;
        } else if (
          route['_loadedRoutes'] !== undefined &&
          route['_loadedRoutes'][0].children.length > 0
        ) {
          let r = findRecurssive(
            route['_loadedRoutes'][0].children,
            path,
            isEdit,
          );

          if (r != undefined) {
            return r;
          }
        }
      }
      return undefined;
    }

    this._location.onUrlChange(async (nextUrl: string, state: unknown) => {
      // En cas de replaceState
      if (state == undefined) {
        let urlTree: UrlTree = this.router.parseUrl(nextUrl);

        let segments = urlTree.root.children.primary.segments;
        let configs = this.router.config;

        if (segments.length > 0) {
          var breadCrumbIndex = 0;
          var newCrumbs = [];
          let url = '';
          let routes = this.router.config;

          for (let segment of segments) {
            let currentConfig: Route = undefined;
            url += `/${segment.path}`;

            let isEdit = segment.path == 'edit';

            if (configs != undefined) {
              currentConfig = findRecurssive(routes, segment.path, isEdit);

              if (currentConfig == undefined) continue;

              if ((currentConfig as any)._loadedConfig != undefined) {
                routes = (currentConfig as any)._loadedConfig.routes;
              }
            }

            var newCrumb: BreadcrumbItem = {
              label: '',
              url: url,
              queryParams: undefined,
            };

            if (!isEdit) {
              if (currentConfig.data === undefined) {
                // Au cas où les routes ont changé
                let recursif = findRecurssive(routes, segment.path);
                newCrumb.label = this.getNewCrumbDeepLabel(recursif);
              } else {
                newCrumb.label = this.getNewCrumbDeepLabel(currentConfig);
              }
            } else {
              newCrumb.label = 'edit';
              newCrumb.url = nextUrl;
            }

            newCrumbs.push(newCrumb);
          }

          this.items = newCrumbs;
        }
      }
    });
  }

  private getNewCrumbDeepLabel(config: Route) {
    let label = '';
    if (config?.data != undefined && config.data['breadcrumb'] != undefined) {
      label = config.data['breadcrumb'];
    } else if (config != undefined && config['_loadedRoutes'] != undefined) {
      if (
        config['_loadedRoutes'][0].data != undefined &&
        config['_loadedRoutes'][0].data['breadcrumb'] != undefined
      ) {
        label = config['_loadedRoutes'][0].data['breadcrumb'];
      } else {
        if (
          config['_loadedRoutes'][0].children != undefined &&
          config['_loadedRoutes'][0].children[0].data != undefined
        ) {
          label = config['_loadedRoutes'][0].children[0].data['breadcrumb'];
        }
      }
    } else {
      console.log('BreadCrumb ERROR empty');
    }

    return label;
  }

  private createBreadcrumbs(
    route: ActivatedRoute,
    url: string = '',
    breadcrumbs: BreadcrumbItem[] = [],
  ): BreadcrumbItem[] {
    const children: ActivatedRoute[] = route.children;

    if (children.length === 0) {
      return breadcrumbs;
    }

    for (const child of children) {
      const routeURL: string = child.snapshot.url
        .map((segment) => segment.path)
        .join('/');
      if (routeURL !== '') {
        url += `/${routeURL}`;
      }
      let queryParams = child.snapshot.queryParams;

      const label =
        child.snapshot.data[BreadcrumbComponent.ROUTE_DATA_BREADCRUMB];
      if (label != undefined) {
        //!isNullOrUndefined(label)) {
        breadcrumbs.push({ label, url, queryParams });
      }

      return this.createBreadcrumbs(child, url, breadcrumbs);
    }
  }
}
