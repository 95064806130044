import { CalendarConnectorBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Observable } from 'rxjs/internal/Observable';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { of } from 'rxjs/internal/observable/of';

@Injectable({ providedIn: 'root' })
export class CalendarConnectorCoreService extends CalendarConnectorBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Permet de choisir le type d'élément à créer  */
  findByKey(): Observable<{ id: string; name: string }[]> {
    let items = [
      {
        id: 'exchange',
        name: TranslateService.get(
          'entities/exchangeConnector/_title/singular',
        ),
      },
      {
        id: 'office_365',
        name: TranslateService.get(
          'entities/exchangeConnector/office365',
        ),
      }
    ];

    return of(items);
  }
}
