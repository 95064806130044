import {
  AfterViewInit,
  Component,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { alert, confirm } from 'devextreme/ui/dialog';
import { camelCase } from 'lodash';

import { Column, MasterDetail } from '@clarilog/shared2/models/schema';

import { ActivatedRoute, Router } from '@angular/router';
import { GC, GCFactory } from '@clarilog/core';
import { ServicePermission } from '@clarilog/core/services/graphql/graphql.service';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { dxButtonOptions } from 'devextreme/ui/button';
import { custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { CoreWorkSubFormComponent } from '../../form/work-sub-form';
import { CoreListComponent } from '../list';

/** Représente le menu nouveau */
export class SubFormNewMenuItem {
  /** Clé de l'item */
  id: string;
  /** Nom traduit de l'item */
  name: string;
  /** Service associé */
  service: any;
  /** indique si l'insertion du type à besoin d'un formulaire */
  withoutForm: boolean = false;
  /** indique si l'insertion du type peut être en double */
  unique: boolean = false;
}

@Component({
  selector: 'clc-sub-form-link-list',
  templateUrl: './sub-form-link-list.component.html',
  styleUrls: ['./sub-form-link-list.component.scss'],
})
export class CoreSubFormLinkListComponent
  implements OnInit, AfterViewInit, OnDestroy
{
  @Input() state: ModelState;
  @Input() fieldName: string;
  @Input() multiple: boolean;
  @Input() selectedKeys: string[] = [];
  @ViewChild(CoreListComponent) list: CoreListComponent;
  @ViewChild(CoreWorkSubFormComponent) subForm: CoreWorkSubFormComponent;
  @Input() saveParentFormOnSubSaved: boolean = true;
  @Input() key: string;

  @Input() keyModelName: string;
  @Input() checkPermissionToRead: boolean = false;

  /** Représente les informations sur les master detail */
  @Input() masterDetail: MasterDetail;
  @Input() readOnly: boolean = false;
  @Input() canVisible: boolean = true;
  /** Obtient ou définit les rules pour le designer */
  @Input() rules: ModelFnContext;
  service: any;

  @Input() source: ModelDataSourceContext;
  @Input() currentSource: ModelDataSourceContext;
  @Input() newCommandMenu: ModelDataSourceContext;

  @Input() heightNew: string;

  @Input() subFormModel: any;

  isPopupOpen: boolean = false;
  disableBtnEdit: boolean = true;
  /** Obtient ou définit si on peut ajouter */
  @Input() canAdd: boolean = true;
  /** Obtient ou définit si on peut modifer */
  @Input() canEdit: boolean = true;
  /** Obtient ou définit si on peut supprimer */
  @Input() canDelete: boolean = true;

  @Input() serviceRetreiver: ModelFnContext;
  /** Obtient ou définit les données sélectionnées. */
  @Input() selectedData: any[] = [];
  /** Indique si on attend l'ouverture du popup */
  waitSave: boolean = false;

  private gc: GC;

  /** @inheritdoc */
  ngOnDestroy(): void {
    this.gc.dispose();
  }

  async ngOnInit() {
    let id =
      this.state.isSubForm === true
        ? this.state.id
        : this.route.snapshot.paramMap.get('id');
    if (id === null) {
      id = this.state.id;
    }

    if (id != undefined && this.source != undefined) {
      this.currentSource = this.source;
      this.state.sharedContext.params.set(this.fieldName, () => id);
    } else {
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource([]),
      });
      this.gc.forDispose(
        this.state.formComponent.onSaved.subscribe((res) => {
          this.currentSource = this.source;
          this.state.sharedContext.params.set(this.fieldName, () => res);
          this.state.id = res;
          if (this.waitSave == true) {
            this.isPopupOpen = true;
            this.waitSave = false;
          }
        }),
      );
    }
    if (this.state != undefined) {
      this.gc.forDispose(
        this.state.on.subscribe((f) => {
          if (f.eventName == 'refresh-tabs') {
            this.refresh();
          }
          if (f.eventName == 'tab-change') {
            let list = ['interventions', 'notes'];
            if (list.includes(f?.eventData?.newTab?.itemKey)) {
              this.refresh();
            } else {
              this.list.checkPermissionToRead = true;
            }
          }
        }),
      );
    }
    if (this.newCommandMenu == undefined) {
      this.subFormModel = this.state.sharedContext.params.get(
        this.key + '-subform',
      );
      if (this.subFormModel == undefined && !this.notShowError()) {
        notify("Model n'est pas chargé", 'ERROR');
      }
    }
    this.service = this.serviceRetreiver.fnCall();

    this.getPermissions();
  }

  getParentId(): string {
    if (this.state.id != undefined) {
      return this.state.id;
    } else {
      let id = this.state.formComponent.id;
      return id;
    }
  }
  /**Réinitialisation du popUp en cas de changement d'onglet (intervention / note ) */
  private resetSubFormPopup() {
    this.subForm.editId = undefined;
    this.subFormModel = this.state.sharedContext.params.get(
      this.key + '-subform',
    );
    this.heightNew = this.key;
    this.service = this.injector.get(`${this.key.toPascalCase()}CoreService`);
  }

  async openSubForm(isNew: boolean = false) {
    if (isNew) {
      this.resetSubFormPopup();
    }

    // Vérification si le modele est valide
    this.waitSave = false;
    if (this.getParentId() == undefined) {
      if (FormGroupHelpers.valid(this.state.formComponent.form) == false) {
        // Validation du formulaire
        alert(
          TranslateService.get('globals/formNotValid'),
          TranslateService.get('globals/warning'),
        );
      } else {
        let confirm = await CoreSubFormLinkListComponent.confirmSaveDialog(
          TranslateService.get('confirm-title'),
          TranslateService.get('globals/confirm-refresh'),
          TranslateService.get('save'),
          TranslateService.get('cancel'),
        );
        if (confirm) {
          this.waitSave = true;
          this.state.formComponent.save({ close: false });
        }
      }
    } else {
      this.isPopupOpen = true;
    }
  }

  public static async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: (e) => {
          return true;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
    ];

    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  onSelectionChanged() {
    if (this.selectedData.length === 1) {
      this.disableBtnEdit = false;
      let items = this.newCommandMenu?.datasource?.items();
      if (items != undefined) {
        if (items?.length === 0) {
          this.newCommandMenu.datasource.load();
          items = this.newCommandMenu.datasource.items();
        }
        items = items.filter(
          (f) =>
            camelCase(f.id) == camelCase(this.selectedData[0]['__typename']),
        );
        if (items.length > 0) {
          this.disableBtnEdit = items[0].withoutForm;
        }
      }
    } else {
      this.disableBtnEdit = true;
    }
  }

  async edit() {
    if (this.selectedKeys == undefined || this.selectedKeys.length == 0) return;
    this.editOpenId(this.selectedKeys[0], this.selectedData[0]);
  }

  async editOpenId(id, data) {
    if (id == undefined) return;
    this.subForm.editId = id;

    if (this.newCommandMenu != undefined) {
      await this.newCommandMenu.datasource.reload();
      let items = this.newCommandMenu.datasource.items();
      items = items.filter(
        (f) => camelCase(f.id) == camelCase(data['__typename']),
      );
      if (items.length > 0) {
        let item: SubFormNewMenuItem = items[0] as SubFormNewMenuItem;

        this.subFormModel = this.state.sharedContext.params.get(
          item.id + '-subform',
        );
        if (item.withoutForm) {
          notify(
            TranslateService.get('entities/workflowAction/withoutAction'),
            'error',
            5000,
          );
          return;
        }
        if (this.subFormModel != undefined) {
          this.service = item.service;
          this.openSubForm();
        }
      }
    } else {
      this.openSubForm();
    }
  }

  private async savedParentForm() {
    if (
      this.state.form.dirty === true &&
      this.saveParentFormOnSubSaved === true
    ) {
      await this.state.formComponent.save({ close: false });
    }
  }

  public remove() {
    if (this.selectedKeys.length > 0) {
      let message = TranslateService.get('confirm-permanent-delete', {
        count: this.selectedKeys.length,
      });

      confirm(message, TranslateService.get('confirm-title')).then((isOk) => {
        if (isOk) {
          this.gc.forDispose(
            this.service
              .delete(
                ModelFieldCompilerService.createServiceSingleResultScalar(),
                this.selectedKeys,
              )
              .subscribe((res) => {
                if (res.errors.length === 0) {
                  this.savedParentForm();
                  this.list.clearSelection();
                  this.list.refresh();

                  this.state.on.emit({
                    eventName: 'reload-tabs',
                    eventData: undefined,
                  });

                  notify(
                    TranslateService.get('deletedSuccess'),
                    'success',
                    4000,
                  );
                } else {
                  notify(TranslateService.get('deletedError'), 'error', 10000);
                }
              }),
          );
        }
      });
    }
  }

  saved(id) {
    if (this.subForm.editId == undefined) {
    } else {
      this.list.clearSelection();
    }
    this.savedParentForm();
    this.list.refresh();
    if (this.originaService != undefined) {
      this.service = this.originaService;
    }
    this.state.on.emit({
      eventName: 'reload-tabs',
      eventData: undefined,
    });
  }

  refresh() {
    let checkPermissionToReadList = ['intervention', 'note'];
    if (checkPermissionToReadList.includes(this.keyModelName)) {
      this.list.checkPermissionToRead = this.policyValidator.validate(
        `${this.keyModelName}.read`,
      );
    } else {
      this.list.checkPermissionToRead = true;
    }

    this.list.clearSelection();
    this.list.refresh();
  }

  @Input() columns: Column[] = [];

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private servicePermission: ServicePermission,
    private policyValidator: CorePolicyValidator,
    public injector: Injector,
    private gcFactory: GCFactory,
  ) {
    this.gc = gcFactory.create();
  }

  onButtonClick(e) {}

  notShowError() {
    if (this.readOnly) {
      return true;
    } else if (!this.canEdit && !this.canAdd) {
      return true;
    }
    return false;
  }

  originaService: any;
  async onItemClick(e) {
    // Il faut définir dans le context du formulaire du composant les différent model JSON avec les clé identique au items du menu
    // Il faut également inclure le service dans les items du menu
    this.subFormModel = this.state.sharedContext.params.get(
      e.itemData.id + '-subform',
    );
    if (this.subFormModel == undefined && e.itemData.withoutForm == false) {
      if (!this.notShowError()) {
        notify("Model n'est pas chargé", 'ERROR');
      }
    } else {
      if (e.itemData.service == undefined) {
        notify("Aucun service n'a été trouvé", 'ERROR');
      } else {
        this.originaService = this.service;
        this.service = e.itemData.service;

        if (e.itemData.unique) {
          // Vérifie si un item de se type existe déjà
          await this.currentSource.datasource.reload();
          let items = this.currentSource.datasource.items();
          if (
            items.length > 0 &&
            items.some(
              (f) => f.__typename.toLowerCase() == e.itemData.id.toLowerCase(),
            )
          ) {
            notify(
              TranslateService.get('entities/workflowAction/onlyOne'),
              'error',
              5000,
            );
            return;
          }
        }

        if (e.itemData.withoutForm) {
          let obj = {};
          obj[this.fieldName] = this.getParentId();
          this.service
            .insert(
              ModelFieldCompilerService.createServiceResultDefaultFields(
                false,
                true,
              ),
              obj,
            )
            .subscribe((r) => {
              if (r.errors.length === 0) {
                this.savedParentForm();
                this.list.clearSelection();
                this.list.refresh();
                let msg = TranslateService.get('saveSuccess');
                notify(msg, 'success', 5000);
                this.service = this.originaService;
              } else {
                notify(TranslateService.get('saveError'), 'error', 5000);
              }
            });
        } else {
          this.openSubForm();
        }
      }
    }
  }

  /** Clic sur un lien */
  onRowClick(e) {
    this.editOpenId(e.value, e.data);
  }

  ngAfterViewInit(): void {
    this.gc.forDispose(
      this.route.queryParams.subscribe((params) => {
        this.openAnEvent(params.tabs, params.eventId);
      }),
    );

    // Vérification si le tabs doit etre sélection
    let interventionId = this.route?.snapshot?.queryParams?.interventionId;
    if (interventionId != undefined) {
      // Ouvre la fiche d'une intervention automatiquement
      if (!this.readOnly) {
        this.subForm.editId = interventionId;
        this.isPopupOpen = true;
      }
    }
  }

  private openAnEvent(tabs: string, eventId: string) {
    if (
      (tabs != undefined || tabs != null) &&
      (eventId != undefined || eventId != null)
    ) {
      let whiteListRoute = ['intervention', 'note'];
      if (whiteListRoute.includes(tabs)) {
        this.subFormModel = this.state.sharedContext.params.get(
          `${tabs}-subform`,
        );
        this.subForm.editId = eventId;
        this.service = this.injector.get(`${tabs.toPascalCase()}CoreService`);
        this.heightNew = tabs != undefined ? tabs : undefined;
        if (this.keyModelName == tabs) {
          this.list.checkPermissionToRead = this.policyValidator.validate(
            `${this.key}.read`,
          );
          setTimeout(() => {
            this.isPopupOpen = true;
          }, 800);
        } else {
          this.list.checkPermissionToRead = true;
          this.isPopupOpen = false;
          this.resetSubFormPopup();
        }
      }
    }
  }

  getPermissions() {
    let policies = this.servicePermission.getAuthorizations(this.service);
    if (policies != undefined) {
      let operator = 'and';
      if (policies['operator'] != undefined) {
        operator = policies['operator'];
        policies = policies['policies'];
      } else if (policies['policies'] != undefined) {
        policies = policies['policies'];
      }
      let canAddTmp = false;
      let canDeleteTmp = false;
      let canEditTmp = false;
      for (let policy of policies) {
        if (operator == 'or') {
          if (canAddTmp == false) {
            canAddTmp =
              this.canAdd &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
              );
          }
          if (canDeleteTmp == false) {
            canDeleteTmp =
              this.canDelete &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
              );
          }
          if (canEditTmp == false) {
            canEditTmp =
              this.canEdit &&
              this.policyValidator.validate(
                policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
              );
          }
        } else {
          this.canAdd =
            this.canAdd &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.write`,
            );

          this.canDelete =
            this.canDelete &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.delete`,
            );
          this.canEdit =
            this.canEdit &&
            this.policyValidator.validate(
              policy.indexOf('.') > -1 ? `${policy}` : `${policy}.update`,
            );
        }
      }

      if (operator == 'or') {
        this.canAdd = canAddTmp;
        this.canDelete = canDeleteTmp;
        this.canEdit = canEditTmp;
      }
    }
  }
}
