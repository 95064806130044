import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBaseBoardsBaseVariables, FirstBaseBoardsBaseVariables, CountBaseBoardsBaseVariables, FindBaseBoardsBaseVariables, SearchBaseBoardsBaseVariables, ExportSchemaBaseBoardsBaseVariables, ExportDataBaseBoardsBaseVariables, CustomQueryBaseBoardsBaseVariables, CustomQueryIdBaseBoardsBaseVariables, UsedBaseBoardsBaseVariables, ExistBaseBoardsBaseVariables } from '../gqls'
import { GetBaseBoardsDocument, FirstBaseBoardsDocument, CountBaseBoardsDocument, FindBaseBoardsDocument, SearchBaseBoardsDocument, ExportSchemaBaseBoardsDocument, ExportDataBaseBoardsDocument, CustomQueryBaseBoardsDocument, CustomQueryIdBaseBoardsDocument, UsedBaseBoardsDocument, ExistBaseBoardsDocument } from '../gqls'
import { ServiceSingleResultOfBaseBoard, QueryContextOfBaseBoard, FilterOfBaseBoard, ServiceSingleResultOfInt64, ServiceListResultOfBaseBoard, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BaseBoardBaseService {
    
public modelName = 'baseBoard';
public modelPluralName = 'baseBoards';

	private getBaseBoardsQuery: GetBaseBoardsDocument;
	private firstBaseBoardsQuery: FirstBaseBoardsDocument;
	private countBaseBoardsQuery: CountBaseBoardsDocument;
	private findBaseBoardsQuery: FindBaseBoardsDocument;
	private searchBaseBoardsQuery: SearchBaseBoardsDocument;
	private exportSchemaBaseBoardsQuery: ExportSchemaBaseBoardsDocument;
	private exportDataBaseBoardsQuery: ExportDataBaseBoardsDocument;
	private customQueryBaseBoardsQuery: CustomQueryBaseBoardsDocument;
	private customQueryIdBaseBoardsQuery: CustomQueryIdBaseBoardsDocument;
	private usedBaseBoardsQuery: UsedBaseBoardsDocument;
	private existBaseBoardsQuery: ExistBaseBoardsDocument;

	constructor(private injector: Injector) {
		this.getBaseBoardsQuery = this.injector.get(GetBaseBoardsDocument);
		this.firstBaseBoardsQuery = this.injector.get(FirstBaseBoardsDocument);
		this.countBaseBoardsQuery = this.injector.get(CountBaseBoardsDocument);
		this.findBaseBoardsQuery = this.injector.get(FindBaseBoardsDocument);
		this.searchBaseBoardsQuery = this.injector.get(SearchBaseBoardsDocument);
		this.exportSchemaBaseBoardsQuery = this.injector.get(ExportSchemaBaseBoardsDocument);
		this.exportDataBaseBoardsQuery = this.injector.get(ExportDataBaseBoardsDocument);
		this.customQueryBaseBoardsQuery = this.injector.get(CustomQueryBaseBoardsDocument);
		this.customQueryIdBaseBoardsQuery = this.injector.get(CustomQueryIdBaseBoardsDocument);
		this.usedBaseBoardsQuery = this.injector.get(UsedBaseBoardsDocument);
		this.existBaseBoardsQuery = this.injector.get(ExistBaseBoardsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBaseBoard> {
    
    		let variables: GetBaseBoardsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.get));
            }
            else{
                let result:ServiceSingleResultOfBaseBoard={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBaseBoard,
		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBaseBoard> {
    
    		let variables: FirstBaseBoardsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBaseBoardsBaseVariables = {
    			filter: filter
    		}
    				return this.countBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBaseBoard,
		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBaseBoard> {
    
    		let variables: FindBaseBoardsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBaseBoard,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBaseBoard> {
    
    		let variables: SearchBaseBoardsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBaseBoardsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBaseBoardsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBaseBoard,
		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBaseBoard> {
    
    		let variables: CustomQueryBaseBoardsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBaseBoard,
		@Args('filter?') filter?: FilterOfBaseBoard,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBaseBoard> {
    
    		let variables: CustomQueryIdBaseBoardsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBaseBoardsBaseVariables = {
    			ids: ids
    		}
    				return this.usedBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBaseBoardsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBaseBoardsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.baseBoards.exist));
    	}
    
    
}