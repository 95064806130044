import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanMethodProfilsBaseVariables, FirstScanMethodProfilsBaseVariables, CountScanMethodProfilsBaseVariables, FindScanMethodProfilsBaseVariables, SearchScanMethodProfilsBaseVariables, ExportSchemaScanMethodProfilsBaseVariables, ExportDataScanMethodProfilsBaseVariables, CustomQueryScanMethodProfilsBaseVariables, CustomQueryIdScanMethodProfilsBaseVariables, UsedScanMethodProfilsBaseVariables, DefaultScanMethodProfilsBaseVariables, ExistScanMethodProfilsBaseVariables, FindRecyclesScanMethodProfilsBaseVariables, CountRecyclesScanMethodProfilsBaseVariables, ReadOnlyScanMethodProfilsBaseVariables, FindArchivedScanMethodProfilsBaseVariables, CountAllScanMethodProfilsBaseVariables, FindDynamicPropertyFieldsScanMethodProfilsBaseVariables, FindUnassociatedScanConfigurationsScanMethodProfilsBaseVariables, InsertScanMethodProfilsBaseVariables, UpdateScanMethodProfilsBaseVariables, ImportDataScanMethodProfilsBaseVariables, UpdateManyScanMethodProfilsBaseVariables, DeleteScanMethodProfilsBaseVariables, RestoreScanMethodProfilsBaseVariables, RecycleScanMethodProfilsBaseVariables, RestoreArchivedScanMethodProfilsBaseVariables, ArchivedScanMethodProfilsBaseVariables, AddFileDynamicFieldScanMethodProfilsBaseVariables, RemoveFileDynamicFieldScanMethodProfilsBaseVariables, AddScanConfigurationsScanMethodProfilsBaseVariables, RemoveScanConfigurationsScanMethodProfilsBaseVariables } from '../gqls'
import { GetScanMethodProfilsDocument, FirstScanMethodProfilsDocument, CountScanMethodProfilsDocument, FindScanMethodProfilsDocument, SearchScanMethodProfilsDocument, ExportSchemaScanMethodProfilsDocument, ExportDataScanMethodProfilsDocument, CustomQueryScanMethodProfilsDocument, CustomQueryIdScanMethodProfilsDocument, UsedScanMethodProfilsDocument, DefaultScanMethodProfilsDocument, ExistScanMethodProfilsDocument, FindRecyclesScanMethodProfilsDocument, CountRecyclesScanMethodProfilsDocument, ReadOnlyScanMethodProfilsDocument, FindArchivedScanMethodProfilsDocument, CountAllScanMethodProfilsDocument, FindDynamicPropertyFieldsScanMethodProfilsDocument, FindUnassociatedScanConfigurationsScanMethodProfilsDocument, InsertScanMethodProfilsDocument, UpdateScanMethodProfilsDocument, ImportDataScanMethodProfilsDocument, UpdateManyScanMethodProfilsDocument, DeleteScanMethodProfilsDocument, RestoreScanMethodProfilsDocument, RecycleScanMethodProfilsDocument, RestoreArchivedScanMethodProfilsDocument, ArchivedScanMethodProfilsDocument, AddFileDynamicFieldScanMethodProfilsDocument, RemoveFileDynamicFieldScanMethodProfilsDocument, AddScanConfigurationsScanMethodProfilsDocument, RemoveScanConfigurationsScanMethodProfilsDocument } from '../gqls'
import { ServiceSingleResultOfScanMethodProfil, QueryContextOfScanMethodProfil, FilterOfScanMethodProfil, ServiceSingleResultOfInt64, ServiceListResultOfScanMethodProfil, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanMethodProfil, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, ScanMethodProfilInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanMethodProfilBaseService {
    
public modelName = 'scanMethodProfil';
public modelPluralName = 'scanMethodProfils';

	private getScanMethodProfilsQuery: GetScanMethodProfilsDocument;
	private firstScanMethodProfilsQuery: FirstScanMethodProfilsDocument;
	private countScanMethodProfilsQuery: CountScanMethodProfilsDocument;
	private findScanMethodProfilsQuery: FindScanMethodProfilsDocument;
	private searchScanMethodProfilsQuery: SearchScanMethodProfilsDocument;
	private exportSchemaScanMethodProfilsQuery: ExportSchemaScanMethodProfilsDocument;
	private exportDataScanMethodProfilsQuery: ExportDataScanMethodProfilsDocument;
	private customQueryScanMethodProfilsQuery: CustomQueryScanMethodProfilsDocument;
	private customQueryIdScanMethodProfilsQuery: CustomQueryIdScanMethodProfilsDocument;
	private usedScanMethodProfilsQuery: UsedScanMethodProfilsDocument;
	private defaultScanMethodProfilsQuery: DefaultScanMethodProfilsDocument;
	private existScanMethodProfilsQuery: ExistScanMethodProfilsDocument;
	private findRecyclesScanMethodProfilsQuery: FindRecyclesScanMethodProfilsDocument;
	private countRecyclesScanMethodProfilsQuery: CountRecyclesScanMethodProfilsDocument;
	private readOnlyScanMethodProfilsQuery: ReadOnlyScanMethodProfilsDocument;
	private findArchivedScanMethodProfilsQuery: FindArchivedScanMethodProfilsDocument;
	private countAllScanMethodProfilsQuery: CountAllScanMethodProfilsDocument;
	private findDynamicPropertyFieldsScanMethodProfilsQuery: FindDynamicPropertyFieldsScanMethodProfilsDocument;
	private findUnassociatedScanConfigurationsScanMethodProfilsQuery: FindUnassociatedScanConfigurationsScanMethodProfilsDocument;
	private insertScanMethodProfilsMutation: InsertScanMethodProfilsDocument;
	private updateScanMethodProfilsMutation: UpdateScanMethodProfilsDocument;
	private importDataScanMethodProfilsMutation: ImportDataScanMethodProfilsDocument;
	private updateManyScanMethodProfilsMutation: UpdateManyScanMethodProfilsDocument;
	private deleteScanMethodProfilsMutation: DeleteScanMethodProfilsDocument;
	private restoreScanMethodProfilsMutation: RestoreScanMethodProfilsDocument;
	private recycleScanMethodProfilsMutation: RecycleScanMethodProfilsDocument;
	private restoreArchivedScanMethodProfilsMutation: RestoreArchivedScanMethodProfilsDocument;
	private archivedScanMethodProfilsMutation: ArchivedScanMethodProfilsDocument;
	private addFileDynamicFieldScanMethodProfilsMutation: AddFileDynamicFieldScanMethodProfilsDocument;
	private removeFileDynamicFieldScanMethodProfilsMutation: RemoveFileDynamicFieldScanMethodProfilsDocument;
	private addScanConfigurationsScanMethodProfilsMutation: AddScanConfigurationsScanMethodProfilsDocument;
	private removeScanConfigurationsScanMethodProfilsMutation: RemoveScanConfigurationsScanMethodProfilsDocument;

	constructor(private injector: Injector) {
		this.getScanMethodProfilsQuery = this.injector.get(GetScanMethodProfilsDocument);
		this.firstScanMethodProfilsQuery = this.injector.get(FirstScanMethodProfilsDocument);
		this.countScanMethodProfilsQuery = this.injector.get(CountScanMethodProfilsDocument);
		this.findScanMethodProfilsQuery = this.injector.get(FindScanMethodProfilsDocument);
		this.searchScanMethodProfilsQuery = this.injector.get(SearchScanMethodProfilsDocument);
		this.exportSchemaScanMethodProfilsQuery = this.injector.get(ExportSchemaScanMethodProfilsDocument);
		this.exportDataScanMethodProfilsQuery = this.injector.get(ExportDataScanMethodProfilsDocument);
		this.customQueryScanMethodProfilsQuery = this.injector.get(CustomQueryScanMethodProfilsDocument);
		this.customQueryIdScanMethodProfilsQuery = this.injector.get(CustomQueryIdScanMethodProfilsDocument);
		this.usedScanMethodProfilsQuery = this.injector.get(UsedScanMethodProfilsDocument);
		this.defaultScanMethodProfilsQuery = this.injector.get(DefaultScanMethodProfilsDocument);
		this.existScanMethodProfilsQuery = this.injector.get(ExistScanMethodProfilsDocument);
		this.findRecyclesScanMethodProfilsQuery = this.injector.get(FindRecyclesScanMethodProfilsDocument);
		this.countRecyclesScanMethodProfilsQuery = this.injector.get(CountRecyclesScanMethodProfilsDocument);
		this.readOnlyScanMethodProfilsQuery = this.injector.get(ReadOnlyScanMethodProfilsDocument);
		this.findArchivedScanMethodProfilsQuery = this.injector.get(FindArchivedScanMethodProfilsDocument);
		this.countAllScanMethodProfilsQuery = this.injector.get(CountAllScanMethodProfilsDocument);
		this.findDynamicPropertyFieldsScanMethodProfilsQuery = this.injector.get(FindDynamicPropertyFieldsScanMethodProfilsDocument);
		this.findUnassociatedScanConfigurationsScanMethodProfilsQuery = this.injector.get(FindUnassociatedScanConfigurationsScanMethodProfilsDocument);
		this.insertScanMethodProfilsMutation = this.injector.get(InsertScanMethodProfilsDocument);
		this.updateScanMethodProfilsMutation = this.injector.get(UpdateScanMethodProfilsDocument);
		this.importDataScanMethodProfilsMutation = this.injector.get(ImportDataScanMethodProfilsDocument);
		this.updateManyScanMethodProfilsMutation = this.injector.get(UpdateManyScanMethodProfilsDocument);
		this.deleteScanMethodProfilsMutation = this.injector.get(DeleteScanMethodProfilsDocument);
		this.restoreScanMethodProfilsMutation = this.injector.get(RestoreScanMethodProfilsDocument);
		this.recycleScanMethodProfilsMutation = this.injector.get(RecycleScanMethodProfilsDocument);
		this.restoreArchivedScanMethodProfilsMutation = this.injector.get(RestoreArchivedScanMethodProfilsDocument);
		this.archivedScanMethodProfilsMutation = this.injector.get(ArchivedScanMethodProfilsDocument);
		this.addFileDynamicFieldScanMethodProfilsMutation = this.injector.get(AddFileDynamicFieldScanMethodProfilsDocument);
		this.removeFileDynamicFieldScanMethodProfilsMutation = this.injector.get(RemoveFileDynamicFieldScanMethodProfilsDocument);
		this.addScanConfigurationsScanMethodProfilsMutation = this.injector.get(AddScanConfigurationsScanMethodProfilsDocument);
		this.removeScanConfigurationsScanMethodProfilsMutation = this.injector.get(RemoveScanConfigurationsScanMethodProfilsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethodProfil> {
    
    		let variables: GetScanMethodProfilsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.get));
            }
            else{
                let result:ServiceSingleResultOfScanMethodProfil={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethodProfil> {
    
    		let variables: FirstScanMethodProfilsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanMethodProfilsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: FindScanMethodProfilsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: SearchScanMethodProfilsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanMethodProfilsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanMethodProfilsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: CustomQueryScanMethodProfilsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: CustomQueryIdScanMethodProfilsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.used));
    	}

    	/**  */
    	@InjectArgs
    	public default(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethodProfil> {
    
    		let variables: DefaultScanMethodProfilsBaseVariables = {
    
    		}
    				return this.defaultScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.default));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanMethodProfilsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: FindRecyclesScanMethodProfilsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesScanMethodProfilsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyScanMethodProfilsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethodProfil,
		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethodProfil> {
    
    		let variables: FindArchivedScanMethodProfilsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllScanMethodProfilsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsScanMethodProfilsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsScanMethodProfilsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsScanMethodProfilsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.findUnassociatedScanConfigurations));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanMethodProfilInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethodProfil> {
    
    		let variables: InsertScanMethodProfilsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethodProfil> {
    
    		let variables: UpdateScanMethodProfilsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanMethodProfilsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanMethodProfil,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanMethodProfilsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedScanMethodProfilsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldScanMethodProfilsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldScanMethodProfilsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsScanMethodProfilsBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsScanMethodProfilsBaseVariables = {
    			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsScanMethodProfilsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethodProfils.removeScanConfigurations));
    	}
    
}