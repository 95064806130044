import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCheckSizeFileTasksBaseVariables, FirstCheckSizeFileTasksBaseVariables, CountCheckSizeFileTasksBaseVariables, FindCheckSizeFileTasksBaseVariables, SearchCheckSizeFileTasksBaseVariables, ExportSchemaCheckSizeFileTasksBaseVariables, ExportDataCheckSizeFileTasksBaseVariables, CustomQueryCheckSizeFileTasksBaseVariables, CustomQueryIdCheckSizeFileTasksBaseVariables, UsedCheckSizeFileTasksBaseVariables, ExistCheckSizeFileTasksBaseVariables, InsertCheckSizeFileTasksBaseVariables, UpdateCheckSizeFileTasksBaseVariables, ImportDataCheckSizeFileTasksBaseVariables, UpdateManyCheckSizeFileTasksBaseVariables, DeleteCheckSizeFileTasksBaseVariables } from '../gqls'
import { GetCheckSizeFileTasksDocument, FirstCheckSizeFileTasksDocument, CountCheckSizeFileTasksDocument, FindCheckSizeFileTasksDocument, SearchCheckSizeFileTasksDocument, ExportSchemaCheckSizeFileTasksDocument, ExportDataCheckSizeFileTasksDocument, CustomQueryCheckSizeFileTasksDocument, CustomQueryIdCheckSizeFileTasksDocument, UsedCheckSizeFileTasksDocument, ExistCheckSizeFileTasksDocument, InsertCheckSizeFileTasksDocument, UpdateCheckSizeFileTasksDocument, ImportDataCheckSizeFileTasksDocument, UpdateManyCheckSizeFileTasksDocument, DeleteCheckSizeFileTasksDocument } from '../gqls'
import { ServiceSingleResultOfCheckSizeFileTask, QueryContextOfCheckSizeFileTask, FilterOfCheckSizeFileTask, ServiceSingleResultOfInt64, ServiceListResultOfCheckSizeFileTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, CheckSizeFileTaskInput, FieldUpdateOperatorOfCheckSizeFileTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CheckSizeFileTaskBaseService {
    
public modelName = 'checkSizeFileTask';
public modelPluralName = 'checkSizeFileTasks';

	private getCheckSizeFileTasksQuery: GetCheckSizeFileTasksDocument;
	private firstCheckSizeFileTasksQuery: FirstCheckSizeFileTasksDocument;
	private countCheckSizeFileTasksQuery: CountCheckSizeFileTasksDocument;
	private findCheckSizeFileTasksQuery: FindCheckSizeFileTasksDocument;
	private searchCheckSizeFileTasksQuery: SearchCheckSizeFileTasksDocument;
	private exportSchemaCheckSizeFileTasksQuery: ExportSchemaCheckSizeFileTasksDocument;
	private exportDataCheckSizeFileTasksQuery: ExportDataCheckSizeFileTasksDocument;
	private customQueryCheckSizeFileTasksQuery: CustomQueryCheckSizeFileTasksDocument;
	private customQueryIdCheckSizeFileTasksQuery: CustomQueryIdCheckSizeFileTasksDocument;
	private usedCheckSizeFileTasksQuery: UsedCheckSizeFileTasksDocument;
	private existCheckSizeFileTasksQuery: ExistCheckSizeFileTasksDocument;
	private insertCheckSizeFileTasksMutation: InsertCheckSizeFileTasksDocument;
	private updateCheckSizeFileTasksMutation: UpdateCheckSizeFileTasksDocument;
	private importDataCheckSizeFileTasksMutation: ImportDataCheckSizeFileTasksDocument;
	private updateManyCheckSizeFileTasksMutation: UpdateManyCheckSizeFileTasksDocument;
	private deleteCheckSizeFileTasksMutation: DeleteCheckSizeFileTasksDocument;

	constructor(private injector: Injector) {
		this.getCheckSizeFileTasksQuery = this.injector.get(GetCheckSizeFileTasksDocument);
		this.firstCheckSizeFileTasksQuery = this.injector.get(FirstCheckSizeFileTasksDocument);
		this.countCheckSizeFileTasksQuery = this.injector.get(CountCheckSizeFileTasksDocument);
		this.findCheckSizeFileTasksQuery = this.injector.get(FindCheckSizeFileTasksDocument);
		this.searchCheckSizeFileTasksQuery = this.injector.get(SearchCheckSizeFileTasksDocument);
		this.exportSchemaCheckSizeFileTasksQuery = this.injector.get(ExportSchemaCheckSizeFileTasksDocument);
		this.exportDataCheckSizeFileTasksQuery = this.injector.get(ExportDataCheckSizeFileTasksDocument);
		this.customQueryCheckSizeFileTasksQuery = this.injector.get(CustomQueryCheckSizeFileTasksDocument);
		this.customQueryIdCheckSizeFileTasksQuery = this.injector.get(CustomQueryIdCheckSizeFileTasksDocument);
		this.usedCheckSizeFileTasksQuery = this.injector.get(UsedCheckSizeFileTasksDocument);
		this.existCheckSizeFileTasksQuery = this.injector.get(ExistCheckSizeFileTasksDocument);
		this.insertCheckSizeFileTasksMutation = this.injector.get(InsertCheckSizeFileTasksDocument);
		this.updateCheckSizeFileTasksMutation = this.injector.get(UpdateCheckSizeFileTasksDocument);
		this.importDataCheckSizeFileTasksMutation = this.injector.get(ImportDataCheckSizeFileTasksDocument);
		this.updateManyCheckSizeFileTasksMutation = this.injector.get(UpdateManyCheckSizeFileTasksDocument);
		this.deleteCheckSizeFileTasksMutation = this.injector.get(DeleteCheckSizeFileTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckSizeFileTask> {
    
    		let variables: GetCheckSizeFileTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.get));
            }
            else{
                let result:ServiceSingleResultOfCheckSizeFileTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCheckSizeFileTask,
		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckSizeFileTask> {
    
    		let variables: FirstCheckSizeFileTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCheckSizeFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCheckSizeFileTask,
		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckSizeFileTask> {
    
    		let variables: FindCheckSizeFileTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCheckSizeFileTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckSizeFileTask> {
    
    		let variables: SearchCheckSizeFileTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCheckSizeFileTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCheckSizeFileTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCheckSizeFileTask,
		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckSizeFileTask> {
    
    		let variables: CustomQueryCheckSizeFileTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCheckSizeFileTask,
		@Args('filter?') filter?: FilterOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCheckSizeFileTask> {
    
    		let variables: CustomQueryIdCheckSizeFileTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCheckSizeFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCheckSizeFileTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCheckSizeFileTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CheckSizeFileTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckSizeFileTask> {
    
    		let variables: InsertCheckSizeFileTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertCheckSizeFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCheckSizeFileTask> {
    
    		let variables: UpdateCheckSizeFileTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCheckSizeFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCheckSizeFileTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCheckSizeFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCheckSizeFileTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCheckSizeFileTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCheckSizeFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCheckSizeFileTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCheckSizeFileTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.checkSizeFileTasks.delete));
    	}
    
}