import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowRevocationWithoutEmailsBaseVariables, FirstWorkflowRevocationWithoutEmailsBaseVariables, CountWorkflowRevocationWithoutEmailsBaseVariables, FindWorkflowRevocationWithoutEmailsBaseVariables, SearchWorkflowRevocationWithoutEmailsBaseVariables, ExportSchemaWorkflowRevocationWithoutEmailsBaseVariables, ExportDataWorkflowRevocationWithoutEmailsBaseVariables, CustomQueryWorkflowRevocationWithoutEmailsBaseVariables, CustomQueryIdWorkflowRevocationWithoutEmailsBaseVariables, UsedWorkflowRevocationWithoutEmailsBaseVariables, ExistWorkflowRevocationWithoutEmailsBaseVariables, InsertWorkflowRevocationWithoutEmailsBaseVariables, UpdateWorkflowRevocationWithoutEmailsBaseVariables, ImportDataWorkflowRevocationWithoutEmailsBaseVariables, UpdateManyWorkflowRevocationWithoutEmailsBaseVariables, DeleteWorkflowRevocationWithoutEmailsBaseVariables } from '../gqls'
import { GetWorkflowRevocationWithoutEmailsDocument, FirstWorkflowRevocationWithoutEmailsDocument, CountWorkflowRevocationWithoutEmailsDocument, FindWorkflowRevocationWithoutEmailsDocument, SearchWorkflowRevocationWithoutEmailsDocument, ExportSchemaWorkflowRevocationWithoutEmailsDocument, ExportDataWorkflowRevocationWithoutEmailsDocument, CustomQueryWorkflowRevocationWithoutEmailsDocument, CustomQueryIdWorkflowRevocationWithoutEmailsDocument, UsedWorkflowRevocationWithoutEmailsDocument, ExistWorkflowRevocationWithoutEmailsDocument, InsertWorkflowRevocationWithoutEmailsDocument, UpdateWorkflowRevocationWithoutEmailsDocument, ImportDataWorkflowRevocationWithoutEmailsDocument, UpdateManyWorkflowRevocationWithoutEmailsDocument, DeleteWorkflowRevocationWithoutEmailsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowRevocationWithoutEmail, QueryContextOfWorkflowRevocationWithoutEmail, FilterOfWorkflowRevocationWithoutEmail, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowRevocationWithoutEmail, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowRevocationWithoutEmailInput, FieldUpdateOperatorOfWorkflowRevocationWithoutEmail } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowRevocationWithoutEmailBaseService {
    
public modelName = 'workflowRevocationWithoutEmail';
public modelPluralName = 'workflowRevocationWithoutEmails';

	private getWorkflowRevocationWithoutEmailsQuery: GetWorkflowRevocationWithoutEmailsDocument;
	private firstWorkflowRevocationWithoutEmailsQuery: FirstWorkflowRevocationWithoutEmailsDocument;
	private countWorkflowRevocationWithoutEmailsQuery: CountWorkflowRevocationWithoutEmailsDocument;
	private findWorkflowRevocationWithoutEmailsQuery: FindWorkflowRevocationWithoutEmailsDocument;
	private searchWorkflowRevocationWithoutEmailsQuery: SearchWorkflowRevocationWithoutEmailsDocument;
	private exportSchemaWorkflowRevocationWithoutEmailsQuery: ExportSchemaWorkflowRevocationWithoutEmailsDocument;
	private exportDataWorkflowRevocationWithoutEmailsQuery: ExportDataWorkflowRevocationWithoutEmailsDocument;
	private customQueryWorkflowRevocationWithoutEmailsQuery: CustomQueryWorkflowRevocationWithoutEmailsDocument;
	private customQueryIdWorkflowRevocationWithoutEmailsQuery: CustomQueryIdWorkflowRevocationWithoutEmailsDocument;
	private usedWorkflowRevocationWithoutEmailsQuery: UsedWorkflowRevocationWithoutEmailsDocument;
	private existWorkflowRevocationWithoutEmailsQuery: ExistWorkflowRevocationWithoutEmailsDocument;
	private insertWorkflowRevocationWithoutEmailsMutation: InsertWorkflowRevocationWithoutEmailsDocument;
	private updateWorkflowRevocationWithoutEmailsMutation: UpdateWorkflowRevocationWithoutEmailsDocument;
	private importDataWorkflowRevocationWithoutEmailsMutation: ImportDataWorkflowRevocationWithoutEmailsDocument;
	private updateManyWorkflowRevocationWithoutEmailsMutation: UpdateManyWorkflowRevocationWithoutEmailsDocument;
	private deleteWorkflowRevocationWithoutEmailsMutation: DeleteWorkflowRevocationWithoutEmailsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowRevocationWithoutEmailsQuery = this.injector.get(GetWorkflowRevocationWithoutEmailsDocument);
		this.firstWorkflowRevocationWithoutEmailsQuery = this.injector.get(FirstWorkflowRevocationWithoutEmailsDocument);
		this.countWorkflowRevocationWithoutEmailsQuery = this.injector.get(CountWorkflowRevocationWithoutEmailsDocument);
		this.findWorkflowRevocationWithoutEmailsQuery = this.injector.get(FindWorkflowRevocationWithoutEmailsDocument);
		this.searchWorkflowRevocationWithoutEmailsQuery = this.injector.get(SearchWorkflowRevocationWithoutEmailsDocument);
		this.exportSchemaWorkflowRevocationWithoutEmailsQuery = this.injector.get(ExportSchemaWorkflowRevocationWithoutEmailsDocument);
		this.exportDataWorkflowRevocationWithoutEmailsQuery = this.injector.get(ExportDataWorkflowRevocationWithoutEmailsDocument);
		this.customQueryWorkflowRevocationWithoutEmailsQuery = this.injector.get(CustomQueryWorkflowRevocationWithoutEmailsDocument);
		this.customQueryIdWorkflowRevocationWithoutEmailsQuery = this.injector.get(CustomQueryIdWorkflowRevocationWithoutEmailsDocument);
		this.usedWorkflowRevocationWithoutEmailsQuery = this.injector.get(UsedWorkflowRevocationWithoutEmailsDocument);
		this.existWorkflowRevocationWithoutEmailsQuery = this.injector.get(ExistWorkflowRevocationWithoutEmailsDocument);
		this.insertWorkflowRevocationWithoutEmailsMutation = this.injector.get(InsertWorkflowRevocationWithoutEmailsDocument);
		this.updateWorkflowRevocationWithoutEmailsMutation = this.injector.get(UpdateWorkflowRevocationWithoutEmailsDocument);
		this.importDataWorkflowRevocationWithoutEmailsMutation = this.injector.get(ImportDataWorkflowRevocationWithoutEmailsDocument);
		this.updateManyWorkflowRevocationWithoutEmailsMutation = this.injector.get(UpdateManyWorkflowRevocationWithoutEmailsDocument);
		this.deleteWorkflowRevocationWithoutEmailsMutation = this.injector.get(DeleteWorkflowRevocationWithoutEmailsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: GetWorkflowRevocationWithoutEmailsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowRevocationWithoutEmail={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowRevocationWithoutEmail,
		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: FirstWorkflowRevocationWithoutEmailsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowRevocationWithoutEmailsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowRevocationWithoutEmail,
		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: FindWorkflowRevocationWithoutEmailsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowRevocationWithoutEmail,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: SearchWorkflowRevocationWithoutEmailsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowRevocationWithoutEmailsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowRevocationWithoutEmailsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowRevocationWithoutEmail,
		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: CustomQueryWorkflowRevocationWithoutEmailsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowRevocationWithoutEmail,
		@Args('filter?') filter?: FilterOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: CustomQueryIdWorkflowRevocationWithoutEmailsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowRevocationWithoutEmailsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowRevocationWithoutEmailsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowRevocationWithoutEmailsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowRevocationWithoutEmailInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: InsertWorkflowRevocationWithoutEmailsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowRevocationWithoutEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowRevocationWithoutEmail> {
    
    		let variables: UpdateWorkflowRevocationWithoutEmailsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowRevocationWithoutEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowRevocationWithoutEmailsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowRevocationWithoutEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowRevocationWithoutEmail,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowRevocationWithoutEmailsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowRevocationWithoutEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowRevocationWithoutEmailsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowRevocationWithoutEmailsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowRevocationWithoutEmails.delete));
    	}
    
}