<dx-tree-list
  [(dataSource)]="source"
  class="tree"
  [showRowLines]="false"
  [showBorders]="false"
  dataStructure="tree"
  keyExpr="id"
  [showColumnHeaders]="false"
  itemsExpr="items"
  [expandedRowKeys]="expandedRowKeys"
  (onRowClick)="onRowClick($event)"
  [columnAutoWidth]="true"
  [height]="'100%'"
>
  <dxo-selection mode="single"></dxo-selection>>
  <dxi-column cellTemplate="errorTemplate"></dxi-column>
  <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>

  <div *dxTemplate="let options of 'errorTemplate'">
    <img
      *ngIf="options.data.ico"
      style="padding: 0 5px 0 0"
      [src]="loadIco(options.data.ico)"
    />
    <i
      *ngIf="options.data.configManagerErrorCode != 0"
      style="margin-left: -10px; padding-right: 5px; color: var(--input-error)"
      class="fas fa-exclamation-circle"
    ></i>
    <span>{{ options.data.name }}</span>
  </div>
</dx-tree-list>
