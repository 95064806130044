import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNetworkDrivesBaseVariables, FirstNetworkDrivesBaseVariables, CountNetworkDrivesBaseVariables, FindNetworkDrivesBaseVariables, SearchNetworkDrivesBaseVariables, ExportSchemaNetworkDrivesBaseVariables, ExportDataNetworkDrivesBaseVariables, CustomQueryNetworkDrivesBaseVariables, CustomQueryIdNetworkDrivesBaseVariables, UsedNetworkDrivesBaseVariables, ExistNetworkDrivesBaseVariables } from '../gqls'
import { GetNetworkDrivesDocument, FirstNetworkDrivesDocument, CountNetworkDrivesDocument, FindNetworkDrivesDocument, SearchNetworkDrivesDocument, ExportSchemaNetworkDrivesDocument, ExportDataNetworkDrivesDocument, CustomQueryNetworkDrivesDocument, CustomQueryIdNetworkDrivesDocument, UsedNetworkDrivesDocument, ExistNetworkDrivesDocument } from '../gqls'
import { ServiceSingleResultOfNetworkDrive, QueryContextOfNetworkDrive, FilterOfNetworkDrive, ServiceSingleResultOfInt64, ServiceListResultOfNetworkDrive, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NetworkDriveBaseService {
    
public modelName = 'networkDrive';
public modelPluralName = 'networkDrives';

	private getNetworkDrivesQuery: GetNetworkDrivesDocument;
	private firstNetworkDrivesQuery: FirstNetworkDrivesDocument;
	private countNetworkDrivesQuery: CountNetworkDrivesDocument;
	private findNetworkDrivesQuery: FindNetworkDrivesDocument;
	private searchNetworkDrivesQuery: SearchNetworkDrivesDocument;
	private exportSchemaNetworkDrivesQuery: ExportSchemaNetworkDrivesDocument;
	private exportDataNetworkDrivesQuery: ExportDataNetworkDrivesDocument;
	private customQueryNetworkDrivesQuery: CustomQueryNetworkDrivesDocument;
	private customQueryIdNetworkDrivesQuery: CustomQueryIdNetworkDrivesDocument;
	private usedNetworkDrivesQuery: UsedNetworkDrivesDocument;
	private existNetworkDrivesQuery: ExistNetworkDrivesDocument;

	constructor(private injector: Injector) {
		this.getNetworkDrivesQuery = this.injector.get(GetNetworkDrivesDocument);
		this.firstNetworkDrivesQuery = this.injector.get(FirstNetworkDrivesDocument);
		this.countNetworkDrivesQuery = this.injector.get(CountNetworkDrivesDocument);
		this.findNetworkDrivesQuery = this.injector.get(FindNetworkDrivesDocument);
		this.searchNetworkDrivesQuery = this.injector.get(SearchNetworkDrivesDocument);
		this.exportSchemaNetworkDrivesQuery = this.injector.get(ExportSchemaNetworkDrivesDocument);
		this.exportDataNetworkDrivesQuery = this.injector.get(ExportDataNetworkDrivesDocument);
		this.customQueryNetworkDrivesQuery = this.injector.get(CustomQueryNetworkDrivesDocument);
		this.customQueryIdNetworkDrivesQuery = this.injector.get(CustomQueryIdNetworkDrivesDocument);
		this.usedNetworkDrivesQuery = this.injector.get(UsedNetworkDrivesDocument);
		this.existNetworkDrivesQuery = this.injector.get(ExistNetworkDrivesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkDrive> {
    
    		let variables: GetNetworkDrivesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.get));
            }
            else{
                let result:ServiceSingleResultOfNetworkDrive={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkDrive,
		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNetworkDrive> {
    
    		let variables: FirstNetworkDrivesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNetworkDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.countNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNetworkDrive,
		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkDrive> {
    
    		let variables: FindNetworkDrivesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNetworkDrive,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkDrive> {
    
    		let variables: SearchNetworkDrivesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNetworkDrivesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNetworkDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNetworkDrive,
		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkDrive> {
    
    		let variables: CustomQueryNetworkDrivesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNetworkDrive,
		@Args('filter?') filter?: FilterOfNetworkDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNetworkDrive> {
    
    		let variables: CustomQueryIdNetworkDrivesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNetworkDrivesBaseVariables = {
    			ids: ids
    		}
    				return this.usedNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNetworkDrivesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNetworkDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.networkDrives.exist));
    	}
    
    
}