<button
  class="group-title"
  [title]="hint != undefined ? hint : ''"
  style="cursor: pointer"
  *ngIf="
    mode === 'normal' && text !== undefined && items.length > 0 && visibled
  "
  (click)="expanded = !expanded"
  type="button"
>
  {{ text }}
  <div class="icon-container">
    <i
      style="padding-left: 5px"
      [ngClass]="{
        'fa-chevron-down': !expanded,
        'fa-chevron-up': expanded
      }"
      class="fal"
    ></i>
  </div>
</button>

<div
  class="border-bottom"
  *ngIf="
    (expanded === true || mode === 'compact') &&
    text !== undefined &&
    items.length > 0 &&
    visibled
  "
></div>

<div
  [ngStyle]="{
    display: expanded === true || mode === 'compact' ? 'block' : 'none'
  }"
>
  <ng-content select="clc-nav-menu-item"></ng-content>
</div>
