import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanMethodsBaseVariables, FirstScanMethodsBaseVariables, CountScanMethodsBaseVariables, FindScanMethodsBaseVariables, SearchScanMethodsBaseVariables, ExportSchemaScanMethodsBaseVariables, ExportDataScanMethodsBaseVariables, CustomQueryScanMethodsBaseVariables, CustomQueryIdScanMethodsBaseVariables, UsedScanMethodsBaseVariables, ExistScanMethodsBaseVariables, InsertScanMethodsBaseVariables, UpdateScanMethodsBaseVariables, ImportDataScanMethodsBaseVariables, UpdateManyScanMethodsBaseVariables, DeleteScanMethodsBaseVariables } from '../gqls'
import { GetScanMethodsDocument, FirstScanMethodsDocument, CountScanMethodsDocument, FindScanMethodsDocument, SearchScanMethodsDocument, ExportSchemaScanMethodsDocument, ExportDataScanMethodsDocument, CustomQueryScanMethodsDocument, CustomQueryIdScanMethodsDocument, UsedScanMethodsDocument, ExistScanMethodsDocument, InsertScanMethodsDocument, UpdateScanMethodsDocument, ImportDataScanMethodsDocument, UpdateManyScanMethodsDocument, DeleteScanMethodsDocument } from '../gqls'
import { ServiceSingleResultOfScanMethod, QueryContextOfScanMethod, FilterOfScanMethod, ServiceSingleResultOfInt64, ServiceListResultOfScanMethod, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ScanMethodInput, FieldUpdateOperatorOfScanMethod } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanMethodBaseService {
    
public modelName = 'scanMethod';
public modelPluralName = 'scanMethods';

	private getScanMethodsQuery: GetScanMethodsDocument;
	private firstScanMethodsQuery: FirstScanMethodsDocument;
	private countScanMethodsQuery: CountScanMethodsDocument;
	private findScanMethodsQuery: FindScanMethodsDocument;
	private searchScanMethodsQuery: SearchScanMethodsDocument;
	private exportSchemaScanMethodsQuery: ExportSchemaScanMethodsDocument;
	private exportDataScanMethodsQuery: ExportDataScanMethodsDocument;
	private customQueryScanMethodsQuery: CustomQueryScanMethodsDocument;
	private customQueryIdScanMethodsQuery: CustomQueryIdScanMethodsDocument;
	private usedScanMethodsQuery: UsedScanMethodsDocument;
	private existScanMethodsQuery: ExistScanMethodsDocument;
	private insertScanMethodsMutation: InsertScanMethodsDocument;
	private updateScanMethodsMutation: UpdateScanMethodsDocument;
	private importDataScanMethodsMutation: ImportDataScanMethodsDocument;
	private updateManyScanMethodsMutation: UpdateManyScanMethodsDocument;
	private deleteScanMethodsMutation: DeleteScanMethodsDocument;

	constructor(private injector: Injector) {
		this.getScanMethodsQuery = this.injector.get(GetScanMethodsDocument);
		this.firstScanMethodsQuery = this.injector.get(FirstScanMethodsDocument);
		this.countScanMethodsQuery = this.injector.get(CountScanMethodsDocument);
		this.findScanMethodsQuery = this.injector.get(FindScanMethodsDocument);
		this.searchScanMethodsQuery = this.injector.get(SearchScanMethodsDocument);
		this.exportSchemaScanMethodsQuery = this.injector.get(ExportSchemaScanMethodsDocument);
		this.exportDataScanMethodsQuery = this.injector.get(ExportDataScanMethodsDocument);
		this.customQueryScanMethodsQuery = this.injector.get(CustomQueryScanMethodsDocument);
		this.customQueryIdScanMethodsQuery = this.injector.get(CustomQueryIdScanMethodsDocument);
		this.usedScanMethodsQuery = this.injector.get(UsedScanMethodsDocument);
		this.existScanMethodsQuery = this.injector.get(ExistScanMethodsDocument);
		this.insertScanMethodsMutation = this.injector.get(InsertScanMethodsDocument);
		this.updateScanMethodsMutation = this.injector.get(UpdateScanMethodsDocument);
		this.importDataScanMethodsMutation = this.injector.get(ImportDataScanMethodsDocument);
		this.updateManyScanMethodsMutation = this.injector.get(UpdateManyScanMethodsDocument);
		this.deleteScanMethodsMutation = this.injector.get(DeleteScanMethodsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethod> {
    
    		let variables: GetScanMethodsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.get));
            }
            else{
                let result:ServiceSingleResultOfScanMethod={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethod,
		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethod> {
    
    		let variables: FirstScanMethodsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanMethodsBaseVariables = {
    			filter: filter
    		}
    				return this.countScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanMethod,
		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethod> {
    
    		let variables: FindScanMethodsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanMethod,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethod> {
    
    		let variables: SearchScanMethodsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanMethodsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanMethodsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanMethod,
		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethod> {
    
    		let variables: CustomQueryScanMethodsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanMethod,
		@Args('filter?') filter?: FilterOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanMethod> {
    
    		let variables: CustomQueryIdScanMethodsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanMethodsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanMethodsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanMethodsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanMethodInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethod> {
    
    		let variables: InsertScanMethodsBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanMethodsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanMethod> {
    
    		let variables: UpdateScanMethodsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanMethodsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanMethodsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanMethodsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanMethod,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanMethodsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanMethodsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanMethodsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanMethodsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanMethods.delete));
    	}
    
}