<dx-popup
  class="popup"
  [width]="500"
  [height]="250"
  [showTitle]="true"
  [title]="title"
  [(visible)]="popupVisible"
  [hideOnOutsideClick]="true"
  [showCloseButton]="true"
>
  <div *ngIf="downloadSchema">
    <dx-button
      [text]="'globals/downloadSchema' | translate"
      stylingMode="text"
      icon="fal fa-download"
      (onClick)="exportSchemaTemplate()"
    ></dx-button>
  </div>

  <dx-file-uploader
    [multiple]="false"
    [allowedFileExtensions]="['.csv', '.xls', '.xlsx']"
    uploadMode="useForm"
  ></dx-file-uploader>

  <dx-load-panel
    #loadPanel
    shadingColor="rgba(0,0,0,0.4)"
    [(visible)]="loadPanelShow"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  >
  </dx-load-panel>

  <div *ngFor="let error of errors">
    <cl-validation-error [message]="error.messageError"></cl-validation-error>
  </div>
</dx-popup>
