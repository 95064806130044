import { AssetStatusBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { GqlField, GqlSubField } from '../helpers';
import { FilterOfAssetStatus, QueryContextOfAssetStatus } from '../types';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-status')
export class AssetStatusCoreService extends AssetStatusBaseService {
  constructor(injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService) {
    super(injector);
  }

  @InjectArgs
  public firstInStockKey(
    @Args('fields') fields?: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetStatus,
    @Args('filter?') filter?: FilterOfAssetStatus,
    @Args('extendedVariables?') extendedVariables?: any
  ){
        fields = [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlSubField.create('name', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
          ])
        ];
        filter = { key: { eq: 'in-stock' } }
        let result = this.first(fields,options,filter,extendedVariables).pipe(map((res) => [res.data.id])).toPromise();
        return result;
  }

  @InjectArgs
  public firstInStockAndOutOfParkKey(
    @Args('fields') fields?: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetStatus,
    @Args('extendedVariables?') extendedVariables?: any
  ){
    fields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('name', [
          GqlField.create(
            this.translatedFieldHelperService.getTranslateKey(),
          ),
        ]),
      ])
    ];

    const filterInStock = {key: { eq: 'in-stock' }};
    const filterOutOfPark = {key: { eq: 'out-of-park' }};

    let resultInStock$ = this.first(fields,options,filterInStock,extendedVariables).pipe(map((res) => [res.data.id])).toPromise();
    let resultOutOfPark$ = this.first(fields,options,filterOutOfPark,extendedVariables).pipe(map((res) => [res.data.id])).toPromise();

    const combinedPromise: Promise<string[]> = Promise.all([resultInStock$, resultOutOfPark$])
    .then(([result1, result2]) => [...result1, ...result2]);

    return combinedPromise;  
  }

  @InjectArgs
  public firstInServiceKey(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetStatus,
    @Args('filter?') filter?: FilterOfAssetStatus,
    @Args('extendedVariables?') extendedVariables?: any
  ) {
        fields = [
          GqlSubField.create('data', [
          GqlField.create('id'),
          GqlSubField.create('name', [
            GqlField.create(
              this.translatedFieldHelperService.getTranslateKey(),
            ),
          ]),
          ])];
        filter = { key: { eq: 'in-service' } }
        let res = this.first(fields,options,filter,extendedVariables).pipe(map((res) => res.data.id)).toPromise();
        return res;
  }
}
