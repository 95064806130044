
import { ReportBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('report')
export class ReportCoreService extends ReportBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}