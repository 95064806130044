import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLocationCategoriesBaseVariables, FirstLocationCategoriesBaseVariables, CountLocationCategoriesBaseVariables, FindLocationCategoriesBaseVariables, SearchLocationCategoriesBaseVariables, ExportSchemaLocationCategoriesBaseVariables, ExportDataLocationCategoriesBaseVariables, CustomQueryLocationCategoriesBaseVariables, CustomQueryIdLocationCategoriesBaseVariables, UsedLocationCategoriesBaseVariables, ExistLocationCategoriesBaseVariables, FindRecyclesLocationCategoriesBaseVariables, CountRecyclesLocationCategoriesBaseVariables, ReadOnlyLocationCategoriesBaseVariables, FindArchivedLocationCategoriesBaseVariables, CountAllLocationCategoriesBaseVariables, FindDynamicPropertyFieldsLocationCategoriesBaseVariables, InsertLocationCategoriesBaseVariables, UpdateLocationCategoriesBaseVariables, ImportDataLocationCategoriesBaseVariables, UpdateManyLocationCategoriesBaseVariables, DeleteLocationCategoriesBaseVariables, RestoreLocationCategoriesBaseVariables, RecycleLocationCategoriesBaseVariables, RestoreArchivedLocationCategoriesBaseVariables, ArchivedLocationCategoriesBaseVariables, AddFileDynamicFieldLocationCategoriesBaseVariables, RemoveFileDynamicFieldLocationCategoriesBaseVariables } from '../gqls'
import { GetLocationCategoriesDocument, FirstLocationCategoriesDocument, CountLocationCategoriesDocument, FindLocationCategoriesDocument, SearchLocationCategoriesDocument, ExportSchemaLocationCategoriesDocument, ExportDataLocationCategoriesDocument, CustomQueryLocationCategoriesDocument, CustomQueryIdLocationCategoriesDocument, UsedLocationCategoriesDocument, ExistLocationCategoriesDocument, FindRecyclesLocationCategoriesDocument, CountRecyclesLocationCategoriesDocument, ReadOnlyLocationCategoriesDocument, FindArchivedLocationCategoriesDocument, CountAllLocationCategoriesDocument, FindDynamicPropertyFieldsLocationCategoriesDocument, InsertLocationCategoriesDocument, UpdateLocationCategoriesDocument, ImportDataLocationCategoriesDocument, UpdateManyLocationCategoriesDocument, DeleteLocationCategoriesDocument, RestoreLocationCategoriesDocument, RecycleLocationCategoriesDocument, RestoreArchivedLocationCategoriesDocument, ArchivedLocationCategoriesDocument, AddFileDynamicFieldLocationCategoriesDocument, RemoveFileDynamicFieldLocationCategoriesDocument } from '../gqls'
import { ServiceSingleResultOfLocationCategory, QueryContextOfLocationCategory, FilterOfLocationCategory, ServiceSingleResultOfInt64, ServiceListResultOfLocationCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfLocationCategory, LocationCategoryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LocationCategoryBaseService {
    
public modelName = 'locationCategory';
public modelPluralName = 'locationCategories';

	private getLocationCategoriesQuery: GetLocationCategoriesDocument;
	private firstLocationCategoriesQuery: FirstLocationCategoriesDocument;
	private countLocationCategoriesQuery: CountLocationCategoriesDocument;
	private findLocationCategoriesQuery: FindLocationCategoriesDocument;
	private searchLocationCategoriesQuery: SearchLocationCategoriesDocument;
	private exportSchemaLocationCategoriesQuery: ExportSchemaLocationCategoriesDocument;
	private exportDataLocationCategoriesQuery: ExportDataLocationCategoriesDocument;
	private customQueryLocationCategoriesQuery: CustomQueryLocationCategoriesDocument;
	private customQueryIdLocationCategoriesQuery: CustomQueryIdLocationCategoriesDocument;
	private usedLocationCategoriesQuery: UsedLocationCategoriesDocument;
	private existLocationCategoriesQuery: ExistLocationCategoriesDocument;
	private findRecyclesLocationCategoriesQuery: FindRecyclesLocationCategoriesDocument;
	private countRecyclesLocationCategoriesQuery: CountRecyclesLocationCategoriesDocument;
	private readOnlyLocationCategoriesQuery: ReadOnlyLocationCategoriesDocument;
	private findArchivedLocationCategoriesQuery: FindArchivedLocationCategoriesDocument;
	private countAllLocationCategoriesQuery: CountAllLocationCategoriesDocument;
	private findDynamicPropertyFieldsLocationCategoriesQuery: FindDynamicPropertyFieldsLocationCategoriesDocument;
	private insertLocationCategoriesMutation: InsertLocationCategoriesDocument;
	private updateLocationCategoriesMutation: UpdateLocationCategoriesDocument;
	private importDataLocationCategoriesMutation: ImportDataLocationCategoriesDocument;
	private updateManyLocationCategoriesMutation: UpdateManyLocationCategoriesDocument;
	private deleteLocationCategoriesMutation: DeleteLocationCategoriesDocument;
	private restoreLocationCategoriesMutation: RestoreLocationCategoriesDocument;
	private recycleLocationCategoriesMutation: RecycleLocationCategoriesDocument;
	private restoreArchivedLocationCategoriesMutation: RestoreArchivedLocationCategoriesDocument;
	private archivedLocationCategoriesMutation: ArchivedLocationCategoriesDocument;
	private addFileDynamicFieldLocationCategoriesMutation: AddFileDynamicFieldLocationCategoriesDocument;
	private removeFileDynamicFieldLocationCategoriesMutation: RemoveFileDynamicFieldLocationCategoriesDocument;

	constructor(private injector: Injector) {
		this.getLocationCategoriesQuery = this.injector.get(GetLocationCategoriesDocument);
		this.firstLocationCategoriesQuery = this.injector.get(FirstLocationCategoriesDocument);
		this.countLocationCategoriesQuery = this.injector.get(CountLocationCategoriesDocument);
		this.findLocationCategoriesQuery = this.injector.get(FindLocationCategoriesDocument);
		this.searchLocationCategoriesQuery = this.injector.get(SearchLocationCategoriesDocument);
		this.exportSchemaLocationCategoriesQuery = this.injector.get(ExportSchemaLocationCategoriesDocument);
		this.exportDataLocationCategoriesQuery = this.injector.get(ExportDataLocationCategoriesDocument);
		this.customQueryLocationCategoriesQuery = this.injector.get(CustomQueryLocationCategoriesDocument);
		this.customQueryIdLocationCategoriesQuery = this.injector.get(CustomQueryIdLocationCategoriesDocument);
		this.usedLocationCategoriesQuery = this.injector.get(UsedLocationCategoriesDocument);
		this.existLocationCategoriesQuery = this.injector.get(ExistLocationCategoriesDocument);
		this.findRecyclesLocationCategoriesQuery = this.injector.get(FindRecyclesLocationCategoriesDocument);
		this.countRecyclesLocationCategoriesQuery = this.injector.get(CountRecyclesLocationCategoriesDocument);
		this.readOnlyLocationCategoriesQuery = this.injector.get(ReadOnlyLocationCategoriesDocument);
		this.findArchivedLocationCategoriesQuery = this.injector.get(FindArchivedLocationCategoriesDocument);
		this.countAllLocationCategoriesQuery = this.injector.get(CountAllLocationCategoriesDocument);
		this.findDynamicPropertyFieldsLocationCategoriesQuery = this.injector.get(FindDynamicPropertyFieldsLocationCategoriesDocument);
		this.insertLocationCategoriesMutation = this.injector.get(InsertLocationCategoriesDocument);
		this.updateLocationCategoriesMutation = this.injector.get(UpdateLocationCategoriesDocument);
		this.importDataLocationCategoriesMutation = this.injector.get(ImportDataLocationCategoriesDocument);
		this.updateManyLocationCategoriesMutation = this.injector.get(UpdateManyLocationCategoriesDocument);
		this.deleteLocationCategoriesMutation = this.injector.get(DeleteLocationCategoriesDocument);
		this.restoreLocationCategoriesMutation = this.injector.get(RestoreLocationCategoriesDocument);
		this.recycleLocationCategoriesMutation = this.injector.get(RecycleLocationCategoriesDocument);
		this.restoreArchivedLocationCategoriesMutation = this.injector.get(RestoreArchivedLocationCategoriesDocument);
		this.archivedLocationCategoriesMutation = this.injector.get(ArchivedLocationCategoriesDocument);
		this.addFileDynamicFieldLocationCategoriesMutation = this.injector.get(AddFileDynamicFieldLocationCategoriesDocument);
		this.removeFileDynamicFieldLocationCategoriesMutation = this.injector.get(RemoveFileDynamicFieldLocationCategoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCategory> {
    
    		let variables: GetLocationCategoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.get));
            }
            else{
                let result:ServiceSingleResultOfLocationCategory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCategory> {
    
    		let variables: FirstLocationCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLocationCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: FindLocationCategoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: SearchLocationCategoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLocationCategoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLocationCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: CustomQueryLocationCategoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: CustomQueryIdLocationCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLocationCategoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: FindRecyclesLocationCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesLocationCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyLocationCategoriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLocationCategory,
		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLocationCategory> {
    
    		let variables: FindArchivedLocationCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllLocationCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsLocationCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsLocationCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LocationCategoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCategory> {
    
    		let variables: InsertLocationCategoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLocationCategory> {
    
    		let variables: UpdateLocationCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLocationCategoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLocationCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLocationCategoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedLocationCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldLocationCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldLocationCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldLocationCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.locationCategories.removeFileDynamicField));
    	}
    
}