<div class="cl-flat-component">

  <div class="cl-flat-block" *ngFor="let item of items; let index = index">

    <span class="cl-flat-title">{{label}}<span *ngIf="items.length>1"> #{{index}}</span></span>


    <div style="display: grid;grid-template-columns:auto 1fr ">


      <div style="grid-column:  1;">
        <div *ngFor="let column of columns; let indexColumn = index">
          <span [ngClass]="indexColumn%2 == 0 ? 'cl-flat-row': 'cl-flat-row cl-flat-row-odd' "
            class="cl-flat-property">{{column.label}}</span>

        </div>
      </div>

      <div style="grid-column:  2;">
        <div *ngFor="let column of columns; let indexColumn = index">
          <span [ngClass]="indexColumn%2 == 0 ? 'cl-flat-row': 'cl-flat-row cl-flat-row-odd' " class="cl-flat-data">
            <span *ngIf="column.template != undefined">
              <ng-container *ngTemplateOutlet="
                  templateService.get(column.template);
                  context: { $implicit: {value: item[column.field], data: item}, column: column }
                ">
              </ng-container>
            </span>
            <span *ngIf="column.template == undefined">
              {{displayValue(item[column.field], column)}}&nbsp;
            </span>
          </span>

        </div>
      </div>
    </div>

  </div>

</div>