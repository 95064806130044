import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVlansBaseVariables, FirstVlansBaseVariables, CountVlansBaseVariables, FindVlansBaseVariables, SearchVlansBaseVariables, ExportSchemaVlansBaseVariables, ExportDataVlansBaseVariables, CustomQueryVlansBaseVariables, CustomQueryIdVlansBaseVariables, UsedVlansBaseVariables, ExistVlansBaseVariables } from '../gqls'
import { GetVlansDocument, FirstVlansDocument, CountVlansDocument, FindVlansDocument, SearchVlansDocument, ExportSchemaVlansDocument, ExportDataVlansDocument, CustomQueryVlansDocument, CustomQueryIdVlansDocument, UsedVlansDocument, ExistVlansDocument } from '../gqls'
import { ServiceSingleResultOfVlan, QueryContextOfVlan, FilterOfVlan, ServiceSingleResultOfInt64, ServiceListResultOfVlan, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VlanBaseService {
    
public modelName = 'vlan';
public modelPluralName = 'vlans';

	private getVlansQuery: GetVlansDocument;
	private firstVlansQuery: FirstVlansDocument;
	private countVlansQuery: CountVlansDocument;
	private findVlansQuery: FindVlansDocument;
	private searchVlansQuery: SearchVlansDocument;
	private exportSchemaVlansQuery: ExportSchemaVlansDocument;
	private exportDataVlansQuery: ExportDataVlansDocument;
	private customQueryVlansQuery: CustomQueryVlansDocument;
	private customQueryIdVlansQuery: CustomQueryIdVlansDocument;
	private usedVlansQuery: UsedVlansDocument;
	private existVlansQuery: ExistVlansDocument;

	constructor(private injector: Injector) {
		this.getVlansQuery = this.injector.get(GetVlansDocument);
		this.firstVlansQuery = this.injector.get(FirstVlansDocument);
		this.countVlansQuery = this.injector.get(CountVlansDocument);
		this.findVlansQuery = this.injector.get(FindVlansDocument);
		this.searchVlansQuery = this.injector.get(SearchVlansDocument);
		this.exportSchemaVlansQuery = this.injector.get(ExportSchemaVlansDocument);
		this.exportDataVlansQuery = this.injector.get(ExportDataVlansDocument);
		this.customQueryVlansQuery = this.injector.get(CustomQueryVlansDocument);
		this.customQueryIdVlansQuery = this.injector.get(CustomQueryIdVlansDocument);
		this.usedVlansQuery = this.injector.get(UsedVlansDocument);
		this.existVlansQuery = this.injector.get(ExistVlansDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVlan> {
    
    		let variables: GetVlansBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.get));
            }
            else{
                let result:ServiceSingleResultOfVlan={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVlan,
		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVlan> {
    
    		let variables: FirstVlansBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVlansBaseVariables = {
    			filter: filter
    		}
    				return this.countVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVlan,
		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVlan> {
    
    		let variables: FindVlansBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVlan,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVlan> {
    
    		let variables: SearchVlansBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVlansBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVlansBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVlan,
		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVlan> {
    
    		let variables: CustomQueryVlansBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVlan,
		@Args('filter?') filter?: FilterOfVlan,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVlan> {
    
    		let variables: CustomQueryIdVlansBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVlansBaseVariables = {
    			ids: ids
    		}
    				return this.usedVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVlansBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVlansQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.vlans.exist));
    	}
    
    
}