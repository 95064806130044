import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLabelFormatsBaseVariables, FirstLabelFormatsBaseVariables, CountLabelFormatsBaseVariables, FindLabelFormatsBaseVariables, SearchLabelFormatsBaseVariables, ExportSchemaLabelFormatsBaseVariables, ExportDataLabelFormatsBaseVariables, CustomQueryLabelFormatsBaseVariables, CustomQueryIdLabelFormatsBaseVariables, UsedLabelFormatsBaseVariables, ExistLabelFormatsBaseVariables, FindRecyclesLabelFormatsBaseVariables, CountRecyclesLabelFormatsBaseVariables, ReadOnlyLabelFormatsBaseVariables, FindArchivedLabelFormatsBaseVariables, CountAllLabelFormatsBaseVariables, FindDynamicPropertyFieldsLabelFormatsBaseVariables, InsertLabelFormatsBaseVariables, UpdateLabelFormatsBaseVariables, ImportDataLabelFormatsBaseVariables, UpdateManyLabelFormatsBaseVariables, DeleteLabelFormatsBaseVariables, RestoreLabelFormatsBaseVariables, RecycleLabelFormatsBaseVariables, RestoreArchivedLabelFormatsBaseVariables, ArchivedLabelFormatsBaseVariables, AddFileDynamicFieldLabelFormatsBaseVariables, RemoveFileDynamicFieldLabelFormatsBaseVariables } from '../gqls'
import { GetLabelFormatsDocument, FirstLabelFormatsDocument, CountLabelFormatsDocument, FindLabelFormatsDocument, SearchLabelFormatsDocument, ExportSchemaLabelFormatsDocument, ExportDataLabelFormatsDocument, CustomQueryLabelFormatsDocument, CustomQueryIdLabelFormatsDocument, UsedLabelFormatsDocument, ExistLabelFormatsDocument, FindRecyclesLabelFormatsDocument, CountRecyclesLabelFormatsDocument, ReadOnlyLabelFormatsDocument, FindArchivedLabelFormatsDocument, CountAllLabelFormatsDocument, FindDynamicPropertyFieldsLabelFormatsDocument, InsertLabelFormatsDocument, UpdateLabelFormatsDocument, ImportDataLabelFormatsDocument, UpdateManyLabelFormatsDocument, DeleteLabelFormatsDocument, RestoreLabelFormatsDocument, RecycleLabelFormatsDocument, RestoreArchivedLabelFormatsDocument, ArchivedLabelFormatsDocument, AddFileDynamicFieldLabelFormatsDocument, RemoveFileDynamicFieldLabelFormatsDocument } from '../gqls'
import { ServiceSingleResultOfLabelFormat, QueryContextOfLabelFormat, FilterOfLabelFormat, ServiceSingleResultOfInt64, ServiceListResultOfLabelFormat, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfLabelFormat, LabelFormatInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LabelFormatBaseService {
    
public modelName = 'labelFormat';
public modelPluralName = 'labelFormats';

	private getLabelFormatsQuery: GetLabelFormatsDocument;
	private firstLabelFormatsQuery: FirstLabelFormatsDocument;
	private countLabelFormatsQuery: CountLabelFormatsDocument;
	private findLabelFormatsQuery: FindLabelFormatsDocument;
	private searchLabelFormatsQuery: SearchLabelFormatsDocument;
	private exportSchemaLabelFormatsQuery: ExportSchemaLabelFormatsDocument;
	private exportDataLabelFormatsQuery: ExportDataLabelFormatsDocument;
	private customQueryLabelFormatsQuery: CustomQueryLabelFormatsDocument;
	private customQueryIdLabelFormatsQuery: CustomQueryIdLabelFormatsDocument;
	private usedLabelFormatsQuery: UsedLabelFormatsDocument;
	private existLabelFormatsQuery: ExistLabelFormatsDocument;
	private findRecyclesLabelFormatsQuery: FindRecyclesLabelFormatsDocument;
	private countRecyclesLabelFormatsQuery: CountRecyclesLabelFormatsDocument;
	private readOnlyLabelFormatsQuery: ReadOnlyLabelFormatsDocument;
	private findArchivedLabelFormatsQuery: FindArchivedLabelFormatsDocument;
	private countAllLabelFormatsQuery: CountAllLabelFormatsDocument;
	private findDynamicPropertyFieldsLabelFormatsQuery: FindDynamicPropertyFieldsLabelFormatsDocument;
	private insertLabelFormatsMutation: InsertLabelFormatsDocument;
	private updateLabelFormatsMutation: UpdateLabelFormatsDocument;
	private importDataLabelFormatsMutation: ImportDataLabelFormatsDocument;
	private updateManyLabelFormatsMutation: UpdateManyLabelFormatsDocument;
	private deleteLabelFormatsMutation: DeleteLabelFormatsDocument;
	private restoreLabelFormatsMutation: RestoreLabelFormatsDocument;
	private recycleLabelFormatsMutation: RecycleLabelFormatsDocument;
	private restoreArchivedLabelFormatsMutation: RestoreArchivedLabelFormatsDocument;
	private archivedLabelFormatsMutation: ArchivedLabelFormatsDocument;
	private addFileDynamicFieldLabelFormatsMutation: AddFileDynamicFieldLabelFormatsDocument;
	private removeFileDynamicFieldLabelFormatsMutation: RemoveFileDynamicFieldLabelFormatsDocument;

	constructor(private injector: Injector) {
		this.getLabelFormatsQuery = this.injector.get(GetLabelFormatsDocument);
		this.firstLabelFormatsQuery = this.injector.get(FirstLabelFormatsDocument);
		this.countLabelFormatsQuery = this.injector.get(CountLabelFormatsDocument);
		this.findLabelFormatsQuery = this.injector.get(FindLabelFormatsDocument);
		this.searchLabelFormatsQuery = this.injector.get(SearchLabelFormatsDocument);
		this.exportSchemaLabelFormatsQuery = this.injector.get(ExportSchemaLabelFormatsDocument);
		this.exportDataLabelFormatsQuery = this.injector.get(ExportDataLabelFormatsDocument);
		this.customQueryLabelFormatsQuery = this.injector.get(CustomQueryLabelFormatsDocument);
		this.customQueryIdLabelFormatsQuery = this.injector.get(CustomQueryIdLabelFormatsDocument);
		this.usedLabelFormatsQuery = this.injector.get(UsedLabelFormatsDocument);
		this.existLabelFormatsQuery = this.injector.get(ExistLabelFormatsDocument);
		this.findRecyclesLabelFormatsQuery = this.injector.get(FindRecyclesLabelFormatsDocument);
		this.countRecyclesLabelFormatsQuery = this.injector.get(CountRecyclesLabelFormatsDocument);
		this.readOnlyLabelFormatsQuery = this.injector.get(ReadOnlyLabelFormatsDocument);
		this.findArchivedLabelFormatsQuery = this.injector.get(FindArchivedLabelFormatsDocument);
		this.countAllLabelFormatsQuery = this.injector.get(CountAllLabelFormatsDocument);
		this.findDynamicPropertyFieldsLabelFormatsQuery = this.injector.get(FindDynamicPropertyFieldsLabelFormatsDocument);
		this.insertLabelFormatsMutation = this.injector.get(InsertLabelFormatsDocument);
		this.updateLabelFormatsMutation = this.injector.get(UpdateLabelFormatsDocument);
		this.importDataLabelFormatsMutation = this.injector.get(ImportDataLabelFormatsDocument);
		this.updateManyLabelFormatsMutation = this.injector.get(UpdateManyLabelFormatsDocument);
		this.deleteLabelFormatsMutation = this.injector.get(DeleteLabelFormatsDocument);
		this.restoreLabelFormatsMutation = this.injector.get(RestoreLabelFormatsDocument);
		this.recycleLabelFormatsMutation = this.injector.get(RecycleLabelFormatsDocument);
		this.restoreArchivedLabelFormatsMutation = this.injector.get(RestoreArchivedLabelFormatsDocument);
		this.archivedLabelFormatsMutation = this.injector.get(ArchivedLabelFormatsDocument);
		this.addFileDynamicFieldLabelFormatsMutation = this.injector.get(AddFileDynamicFieldLabelFormatsDocument);
		this.removeFileDynamicFieldLabelFormatsMutation = this.injector.get(RemoveFileDynamicFieldLabelFormatsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelFormat> {
    
    		let variables: GetLabelFormatsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.get));
            }
            else{
                let result:ServiceSingleResultOfLabelFormat={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelFormat> {
    
    		let variables: FirstLabelFormatsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLabelFormatsBaseVariables = {
    			filter: filter
    		}
    				return this.countLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: FindLabelFormatsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: SearchLabelFormatsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLabelFormatsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLabelFormatsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: CustomQueryLabelFormatsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: CustomQueryIdLabelFormatsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLabelFormatsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: FindRecyclesLabelFormatsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesLabelFormatsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyLabelFormatsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelFormat,
		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelFormat> {
    
    		let variables: FindArchivedLabelFormatsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllLabelFormatsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsLabelFormatsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsLabelFormatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LabelFormatInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelFormat> {
    
    		let variables: InsertLabelFormatsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelFormat> {
    
    		let variables: UpdateLabelFormatsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLabelFormatsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLabelFormatsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedLabelFormatsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldLabelFormatsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldLabelFormatsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldLabelFormatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelFormats.removeFileDynamicField));
    	}
    
}