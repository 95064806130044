<dx-box direction="col" class="aeraItem" *ngIf="hasWarning">
  <dxi-item [baseSize]="40">
    <div
      [ngClass]="isAWarningMessageOperator ? 'aeraItemtitle' : 'aeraItemTitle'"
    >
      <div class="itemTitle">
        <span class="iconContent"
          ><i class="fas fa-exclamation-triangle"></i
        ></span>
        {{ 'permissions/home-alert' | translate }}
      </div>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div class="aeraItemContent">
      <dx-list
        [dataSource]="source"
        pageLoadMode="scrollBottom"
        itemTemplate="data"
        selectionMode="single"
        height="100%"
      >
        <div class="templateItem" *dxTemplate="let item of 'data'">
          <div>
            <span class="iconListContent"><i class="fad fa-circle"></i></span
            >{{ getTranlate(item?.title) }}

            <dx-html-editor
              height="100%"
              readOnly="true"
              [value]="getTranlate(item?.content)"
            ></dx-html-editor>
          </div>
        </div>
      </dx-list>
    </div>
  </dxi-item>
</dx-box>
