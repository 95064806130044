/**
 * Représente des aides sur les types.
 */
export class TypeHelpers {
  /**
   * Teste si la valeur est une fonction.
   * @param obj L'object à tester.
   */
  static isFunction(obj: any): boolean {
    return {}.toString.apply(obj) === '[object Function]';
  }
  /**
   * Teste si la valeur est un tableau.
   * @param obj L'object à tester.
   */
  static isArray(obj: any): boolean {
    return {}.toString.apply(obj) === '[object Array]';
  }
  /**
   * Teste si la valeur est un object.
   * @param obj L'object à tester.
   */
  static isObject(obj: any): boolean {
    return {}.toString.apply(obj) === '[object Object]';
  }
  /**
   * Teste si la valeur est une date.
   * @param obj L'object à tester.
   */
  static isDate(obj: any): boolean {
    return {}.toString.apply(obj) === '[object Date]';
  }
  /**
   * Teste si la valeur est une valeur (scalar).
   * @param obj L'object à tester.
   */
  static isValue(obj: any): boolean {
    return !TypeHelpers.isObject(obj) && !TypeHelpers.isArray(obj);
  }
  /**
   * Teste si la valeur est un objet vide.
   * @param obj L'object à tester.
   */
  static isObjectEmpty(obj:any):boolean {
    for(var key in obj) {
      if(obj.hasOwnProperty(key)) {
          return false;
      }
    }
    return true;
  }
}
