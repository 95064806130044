import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfPnpEntityBase, FilterOfPnpEntityBase, QueryContextOfPnpEntityBase, ServiceSingleResultOfInt64, ServiceListResultOfPnpEntityBase, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, PnpEntityBaseInput, FieldUpdateOperatorOfPnpEntityBase } from '../types'

export type GetPnpEntityBasesResultType = {
    pnpEntityBases: {
        get?: Maybe<ServiceSingleResultOfPnpEntityBase>
    }
}

export type GetPnpEntityBasesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getPnpEntityBases ${args} {
    pnpEntityBases {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstPnpEntityBasesResultType = {
    pnpEntityBases: {
        first?: Maybe<ServiceSingleResultOfPnpEntityBase>
    }
}

export type FirstPnpEntityBasesBaseVariables = {
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPnpEntityBase>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPnpEntityBase = null, $options: QueryContextOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstPnpEntityBases ${args} {
    pnpEntityBases {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountPnpEntityBasesResultType = {
    pnpEntityBases: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountPnpEntityBasesBaseVariables = {
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countPnpEntityBases ${args} {
    pnpEntityBases {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindPnpEntityBasesResultType = {
    pnpEntityBases: {
        find?: Maybe<ServiceListResultOfPnpEntityBase>
    }
}

export type FindPnpEntityBasesBaseVariables = {
	options: Maybe<QueryContextOfPnpEntityBase>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfPnpEntityBase = null, $filter: FilterOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findPnpEntityBases ${args} {
    pnpEntityBases {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchPnpEntityBasesResultType = {
    pnpEntityBases: {
        search?: Maybe<ServiceListResultOfPnpEntityBase>
    }
}

export type SearchPnpEntityBasesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfPnpEntityBase>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchPnpEntityBases ${args} {
    pnpEntityBases {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaPnpEntityBasesResultType = {
    pnpEntityBases: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaPnpEntityBasesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaPnpEntityBases ${args} {
    pnpEntityBases {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataPnpEntityBasesResultType = {
    pnpEntityBases: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataPnpEntityBasesBaseVariables = {
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataPnpEntityBases ${args} {
    pnpEntityBases {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryPnpEntityBasesResultType = {
    pnpEntityBases: {
        customQuery?: Maybe<ServiceListResultOfPnpEntityBase>
    }
}

export type CustomQueryPnpEntityBasesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPnpEntityBase>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfPnpEntityBase = null, $options: QueryContextOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryPnpEntityBases ${args} {
    pnpEntityBases {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdPnpEntityBasesResultType = {
    pnpEntityBases: {
        customQueryId?: Maybe<ServiceListResultOfPnpEntityBase>
    }
}

export type CustomQueryIdPnpEntityBasesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfPnpEntityBase>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfPnpEntityBase>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfPnpEntityBase = null, $options: QueryContextOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdPnpEntityBases ${args} {
    pnpEntityBases {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedPnpEntityBasesResultType = {
    pnpEntityBases: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedPnpEntityBasesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedPnpEntityBases ${args} {
    pnpEntityBases {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistPnpEntityBasesResultType = {
    pnpEntityBases: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistPnpEntityBasesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existPnpEntityBases ${args} {
    pnpEntityBases {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertPnpEntityBasesResultType = {
    pnpEntityBases: {
        insert?: Maybe<ServiceSingleResultOfPnpEntityBase>
    }
}

export type InsertPnpEntityBasesBaseVariables = {
	entity: PnpEntityBaseInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: PnpEntityBaseInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertPnpEntityBases ${args} {
    pnpEntityBases {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdatePnpEntityBasesResultType = {
    pnpEntityBases: {
        update?: Maybe<ServiceSingleResultOfPnpEntityBase>
    }
}

export type UpdatePnpEntityBasesBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfPnpEntityBase>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdatePnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updatePnpEntityBases ${args} {
    pnpEntityBases {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdatePnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdatePnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfPnpEntityBase", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataPnpEntityBasesResultType = {
    pnpEntityBases: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataPnpEntityBasesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataPnpEntityBases ${args} {
    pnpEntityBases {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyPnpEntityBasesResultType = {
    pnpEntityBases: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyPnpEntityBasesBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfPnpEntityBase>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyPnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfPnpEntityBase = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyPnpEntityBases ${args} {
    pnpEntityBases {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyPnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyPnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeletePnpEntityBasesResultType = {
    pnpEntityBases: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeletePnpEntityBasesBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeletePnpEntityBasesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deletePnpEntityBases ${args} {
    pnpEntityBases {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeletePnpEntityBasesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeletePnpEntityBasesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
