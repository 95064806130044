import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { DynamicPropertyGroupBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfDynamicPropertyField,
  QueryContextOfDynamicPropertyField,
  ServiceListResultOfDynamicPropertyField,
} from '../types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('asset.manage-dynamic-property')
export class DynamicPropertyGroupCoreService extends DynamicPropertyGroupBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  findUnassociatedDynamicPropertyFieldsByType(
    @Args('types') types: string[],
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfDynamicPropertyField,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfDynamicPropertyField,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfDynamicPropertyField> {
    let type = '';
    if (types != undefined && types.length > 0) {
      type = types[0];
    }

    let addFilter = {
      entityTypes: {
        eq: types[0],
      },
    };
    if (filter == undefined) {
      filter = addFilter;
    } else {
      filter = {
        and: [filter, addFilter],
      };
    }

    return this.findUnassociatedDynamicPropertyFields(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  findAssociatedDynamicPropertyFieldsByType(
    @Args('types') types: string[],
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfDynamicPropertyField,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfDynamicPropertyField,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfDynamicPropertyField> {
    let type = '';
    if (types != undefined && types.length > 0) {
      type = types[0];
    }

    let addFilter = {
      entityTypes: {
        eq: type,
      },
    };
    if (filter == undefined) {
      filter = addFilter;
    } else {
      filter = {
        and: [filter, addFilter],
      };
    }

    return this.findAssociatedDynamicPropertyFields(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }
}
