import {
  Component,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import {
  AbstractControl,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ServiceSingleResultOfBoolean,
  ValidationError,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import notify from 'devextreme/ui/notify';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { CoreModelCompilerService } from '../../services/compiler/model-compiler.service';
import { TranslateService } from '../../services/translate/translate.service';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { CoreFormLoader } from '../form/work-form/form-loader';
import { CoreFormRecorder } from '../form/work-form/form-recorder';
import { dirtyCheck } from '../form/work-item-form/dirty-check.service';
import { alert } from 'devextreme/ui/dialog';

/**
 * Action de mise a jour massive du lifecycle de biens
 *
 */
@Component({
  selector: 'clc-massive-edit',
  templateUrl: './massive-edit.component.html',
  styleUrls: ['./massive-edit.component.scss'],
})
export class CoreMassiveEditComponent implements OnInit {
  @Input() selectedKeys: string[];
  @Input() inputModel;
  saveSelectedKeys: any[];

  @Input() hideOnOutsideClick: boolean = true;

  @Input() showCloseButton: boolean = true;
  @Input() service: any;
  @Input() height: any;
  @Input() width: any;
  @Input() large: any;
  @Input() errors: ValidationError[];
  @Input() showMessage: boolean = true;
  @Input() warningMessage;
  @Input() updateFn: (
    fields: Array<GqlSubField | GqlField>,
    ...args
  ) => Observable<ServiceSingleResultOfBoolean>;
  @Input() customTitle: boolean = false;
  @Input() showNotification: boolean = true;

  @Input()
  forceLoaded() {
    this.createForm();
  }

  @Input() rootForm: UntypedFormGroup;
  modelState: ModelState;
  isLoading = false;
  //formGroup: FormGroup = new FormGroup({});
  percentage = '30;';
  @Output() onSaved = new EventEmitter<any>();
  @Output() displayErrorMessage = new EventEmitter<any>();
  @Output() onAfterSaved = new EventEmitter<any>();
  @Output() onFormLoaded = new EventEmitter<any>();

  @Input() showWarningMessage: boolean = true;

  @Input() title = '';

  _isVisible: boolean = false;
  isCreateForm: boolean = false;
  permissionToUpdate: boolean = true;

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() selectedKeysChange = new EventEmitter<any[]>();

  // Permet d'afficher le message d'erreur dans la notification ou sur la grille (manage-list)
  @Input() displayNotifOrManageList = undefined;

  @Input() beforeSave: (e: any) => Promise<boolean> = (e) => {
    return of(false).toPromise();
  };

  @Input()
  set isVisible(value: boolean) {
    this._isVisible = value;
    this.isVisibleChange.emit(this._isVisible);
    if (
      this._isVisible === true &&
      this.inputModel != undefined &&
      this.isCreateForm === false
    ) {
      this.isCreateForm = true;
      this.createForm();
      this.inputModel = undefined;
    } else if (this._isVisible) {
      this.setFormDefaultValues();
    }
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  constructor(
    private modelCompilerService: CoreModelCompilerService,
    private loader: CoreFormLoader,
    private recoder: CoreFormRecorder,
    private _injector: Injector,
  ) {
    try {
      let svc = this._injector.get(FormGroupDirective);
      this.rootForm = svc.form.root as UntypedFormGroup;
    } catch (error) {
      this.rootForm = null;
    }
  }

  ngOnInit(): void {
    if (this.height == undefined || this.height == null) {
      this.height = '80%';
    }
    if (
      this.width == undefined ||
      this.width == null ||
      this.large == 'Normal' ||
      this.large == undefined ||
      this.large == null
    ) {
      this.width = '800';
    }

    if (this.large == 'Large') {
      this.width = '1200';
    }
  }
  close() {
    this.isLoading = false;
    this.isVisible = false;
    this.isCreateForm = false;
  }

  _inDefaultStaticProperty = [];

  @Input() inDefaultProperty = [];
  @Input() set inDefaultStaticProperty(value) {
    this._inDefaultStaticProperty = value;
  }

  get inDefaultStaticProperty(): any {
    return this._inDefaultStaticProperty;
  }

  setFormDefaultValue(from, to) {
    let fromControl = FormGroupHelpers.formControlByName(this.rootForm, from);
    let toControl = FormGroupHelpers.formControlByName(
      this.modelState.form,
      to,
    );

    if (fromControl != undefined && toControl != undefined) {
      toControl.setValue(fromControl.value, {
        emitEvent: false,
      });
      setTimeout(() => toControl.markAsDirty());
    }
  }

  setStaticDefaultValue(value, controlName) {
    let toControl = FormGroupHelpers.formControlByName(
      this.modelState.form,
      controlName,
    );

    if (toControl != undefined) {
      toControl.setValue(value, {
        emitEvent: false,
      });
      setTimeout(() => toControl.markAsDirty());
    }
  }
  onShown(e) {
    this.isLoading = false;
  }
  setFormDefaultValues() {
    if (this.inDefaultProperty != undefined) {
      this.canSave$ = new Observable<boolean>((subscriber) => {
        subscriber.next(true);
      });
      this.inDefaultProperty.forEach((element) => {
        this.setFormDefaultValue(element.from, element.to);
      });
    }
    if (this.inDefaultStaticProperty != undefined) {
      this.canSave$ = new Observable<boolean>((subscriber) => {
        subscriber.next(true);
      });
      this.inDefaultStaticProperty.forEach((element) => {
        this.setStaticDefaultValue(element.value, element.controlName);
      });
    }
  }

  async save(e) {
    this.errors = [];
    if (this.beforeSave != undefined) {
      let bs = await this.beforeSave(e);
      if (bs === true) {
        return;
      }
    }
    let warningMessage = this.title.toLocaleLowerCase().trim();
    if (this.title == null || this.title === '' || !this.customTitle) {
      warningMessage = TranslateService.get('updateMassiseEdit');
    }

    this.saveSelectedKeys = this.selectedKeys;

    if (this.showMessage == true) {
      let result;

      if (this.showWarningMessage) {
        if (warningMessage === 'edition massive') {
          warningMessage = TranslateService.get('updateMassiseEdit');
          result = alert(
            TranslateService.get('mgsWarning', { value: warningMessage }),
            TranslateService.get('globals/warning'),
          );
        } else {
          result = alert(
            TranslateService.get('mgsWarning', { value: warningMessage }),
            TranslateService.get('globals/warning'),
          );
        }
      } else {
        result = of(true).toPromise();
      }

      result.then((result) => {
        let update = this.recoder.materialize(
          this.modelState.form,
          false,
          true,
        );
        this.isLoading = true;

        if (Object.keys(update).length > 0) {
          this.updateFn(
            ModelFieldCompilerService.createServiceSingleResultScalar(),
            this.saveSelectedKeys,
            { set: update },
          ).subscribe((res) => {
            this.isLoading = false;
            if (res.data === true) {
              if (this.showNotification === true) {
                notify(TranslateService.get('saveSuccess'), 'success', 5000);
              }
              this.onSaved.emit(update);
            } else {
              if (this.showNotification === true) {
                if (res.errors.length > 0) {
                  switch (this.displayNotifOrManageList) {
                    case true: {
                      notify(res.errors[0].messageError, 'error', 5000);
                      this.displayErrorMessage.emit(res.errors[0].messageError);
                      break;
                    }
                    case false: {
                      this.displayErrorMessage.emit(res.errors[0].messageError);
                      break;
                    }
                    default: {
                      notify(res.errors[0].messageError, 'error', 5000);
                      break;
                    }
                  }
                } else {
                  notify(TranslateService.get('actionError'), 'error', 5000);
                }
              }
            }
            this.onAfterSaved.emit(update);
          });
        } else {
          this.onAfterSaved.emit(update);
        }
      });
    } else {
      let update = this.recoder.materialize(this.modelState.form, false, true);
      this.isLoading = true;
      if (this.permissionToUpdate) {
        this.updateFn(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.saveSelectedKeys,
          { set: update },
        ).subscribe((res) => {
          this.isLoading = false;
          if (res.data === true) {
            if (this.showNotification === true) {
              notify(TranslateService.get('saveSuccess'), 'success', 5000);
            }
            this.onSaved.emit(update);
          } else {
            if (this.showNotification === true) {
              if (res.errors.length > 0) {
                notify(res.errors[0].messageError, 'error', 5000);
              } else {
                notify(TranslateService.get('actionError'), 'error', 5000);
              }
            }
          }
          this.onAfterSaved.emit(update);
        });
      } else {
        this.onSaved.emit(update);
        this.onAfterSaved.emit(update);
      }
    }

    this.selectedKeysChange.emit([]);
    this.close();
  }
  isDirty$: Observable<boolean>;
  canSave$: Observable<boolean>;
  private createForm() {
    if (this.inputModel.form.layout.pages[0]?.percentage != undefined) {
      this.percentage = this.inputModel.form.layout.pages[0].percentage;
    }
    this.modelCompilerService
      .coreCompile(this.inputModel)
      .subscribe(async (res) => {
        this.modelState = res;
        await this.loader
          .create(this.modelState)
          .then((response) => {
            this.modelState.form = response;
            if (this.modelState.form != null) {
              this.modelState.form.markAsLoaded();
              this.isDirty$ = dirtyCheck(
                this.modelState.form,
                of(JSON.parse(JSON.stringify(this.modelState.form.value))),
              )(this.modelState.form.valueChanges);
              this.canSave$ = this.isDirty$.pipe(
                map(
                  (dirty) =>
                    dirty && FormGroupHelpers.valid(this.modelState.form),
                ),
              );
              this.setFormDefaultValues();
              if (this.updateFn == undefined) {
                this.updateFn = this.service.updateMany.bind(this.service);
              }
              let controls = FormGroupHelpers.formControls(
                this.modelState.form,
              );
              for (let control of controls) {
                (control.value as AbstractControl).markAsDirty();
              }
              this.onFormLoaded.emit();
            }
          })
          .catch((error) => {
            console.error(error);
          });
      });
  }

  public open(e) {
    this.createForm();
  }
}
