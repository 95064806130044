import { Component } from '@angular/core';

/** Représente la page de page non trouvé. */
@Component({
  selector: 'cl-not-found',
  templateUrl: './not-found.component.html'
})
export class NotFoundComponent {

  constructor() { }
}
