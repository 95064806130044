<dx-popup  
  *ngIf="visible"
  [width]="500"
  [height]="160"
  [showTitle]="true"
  [title]="'globals/remoteControl' | translate"
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="visible"  
>
  <div style="padding: 10px">
    <clc-link
      [valueExpr]="'id'"
      [displayExpr]="getDisplayExpr()"
      [(_value)]="selectCommandId"
      [source]="selectCommandSource"
      [columns]="commandColumns"
      [route]="'administration/connector/commands'"
      [titleAssociate]="'globals/remoteControl' | translate"
      (onValueChanged)="changeSelectCommand($event)"
      [translatable]="true"
    ></clc-link>
  </div>
  <div style="padding: 10px; text-align: right">
    <dx-button
      [text]="'Ok'"
      type="default"
      (onClick)="executeCommand($event)"
      [elementAttr]="{ class: 'load-button' }"
      [disabled]="disabledBtnCommand"
    >
      <div *dxTemplate="let data of 'content'">
        <dx-load-indicator
          class="button-indicator"
          [visible]="waitRemoteControl"
        >
        </dx-load-indicator>
        <span class="dx-button-text">Ok</span>
      </div>
    </dx-button>
  </div>
</dx-popup>
