import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNewsBaseVariables, FirstNewsBaseVariables, CountNewsBaseVariables, FindNewsBaseVariables, SearchNewsBaseVariables, ExportSchemaNewsBaseVariables, ExportDataNewsBaseVariables, CustomQueryNewsBaseVariables, CustomQueryIdNewsBaseVariables, FindUnassociatedSecurityGroupsNewsBaseVariables, FindAssociatedPopulationSecurityGroupsNewsBaseVariables, FindUnassociatedPopulationsNewsBaseVariables, UsedNewsBaseVariables, FindEnableNewsBaseVariables, ExistNewsBaseVariables, InsertNewsBaseVariables, UpdateNewsBaseVariables, ImportDataNewsBaseVariables, UpdateManyNewsBaseVariables, DeleteNewsBaseVariables, OrderNewsBaseVariables, AddSecurityGroupNewsBaseVariables, RemoveSecurityGroupNewsBaseVariables, AddPopulationsNewsBaseVariables, RemovePopulationsNewsBaseVariables, AddPopulationSecurityGroupNewsBaseVariables, RemovePopulationSecurityGroupNewsBaseVariables } from '../gqls'
import { GetNewsDocument, FirstNewsDocument, CountNewsDocument, FindNewsDocument, SearchNewsDocument, ExportSchemaNewsDocument, ExportDataNewsDocument, CustomQueryNewsDocument, CustomQueryIdNewsDocument, FindUnassociatedSecurityGroupsNewsDocument, FindAssociatedPopulationSecurityGroupsNewsDocument, FindUnassociatedPopulationsNewsDocument, UsedNewsDocument, FindEnableNewsDocument, ExistNewsDocument, InsertNewsDocument, UpdateNewsDocument, ImportDataNewsDocument, UpdateManyNewsDocument, DeleteNewsDocument, OrderNewsDocument, AddSecurityGroupNewsDocument, RemoveSecurityGroupNewsDocument, AddPopulationsNewsDocument, RemovePopulationsNewsDocument, AddPopulationSecurityGroupNewsDocument, RemovePopulationSecurityGroupNewsDocument } from '../gqls'
import { ServiceSingleResultOfNews, QueryContextOfNews, FilterOfNews, ServiceSingleResultOfInt64, ServiceListResultOfNews, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfPopulation, QueryContextOfPopulation, FilterOfPopulation, ServiceSingleResultOfBoolean, NewsInput, FieldUpdateOperatorOfNews } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NewsBaseService {
    
public modelName = 'news';
public modelPluralName = 'news';

	private getNewsQuery: GetNewsDocument;
	private firstNewsQuery: FirstNewsDocument;
	private countNewsQuery: CountNewsDocument;
	private findNewsQuery: FindNewsDocument;
	private searchNewsQuery: SearchNewsDocument;
	private exportSchemaNewsQuery: ExportSchemaNewsDocument;
	private exportDataNewsQuery: ExportDataNewsDocument;
	private customQueryNewsQuery: CustomQueryNewsDocument;
	private customQueryIdNewsQuery: CustomQueryIdNewsDocument;
	private findUnassociatedSecurityGroupsNewsQuery: FindUnassociatedSecurityGroupsNewsDocument;
	private findAssociatedPopulationSecurityGroupsNewsQuery: FindAssociatedPopulationSecurityGroupsNewsDocument;
	private findUnassociatedPopulationsNewsQuery: FindUnassociatedPopulationsNewsDocument;
	private usedNewsQuery: UsedNewsDocument;
	private findEnableNewsQuery: FindEnableNewsDocument;
	private existNewsQuery: ExistNewsDocument;
	private insertNewsMutation: InsertNewsDocument;
	private updateNewsMutation: UpdateNewsDocument;
	private importDataNewsMutation: ImportDataNewsDocument;
	private updateManyNewsMutation: UpdateManyNewsDocument;
	private deleteNewsMutation: DeleteNewsDocument;
	private orderNewsMutation: OrderNewsDocument;
	private addSecurityGroupNewsMutation: AddSecurityGroupNewsDocument;
	private removeSecurityGroupNewsMutation: RemoveSecurityGroupNewsDocument;
	private addPopulationsNewsMutation: AddPopulationsNewsDocument;
	private removePopulationsNewsMutation: RemovePopulationsNewsDocument;
	private addPopulationSecurityGroupNewsMutation: AddPopulationSecurityGroupNewsDocument;
	private removePopulationSecurityGroupNewsMutation: RemovePopulationSecurityGroupNewsDocument;

	constructor(private injector: Injector) {
		this.getNewsQuery = this.injector.get(GetNewsDocument);
		this.firstNewsQuery = this.injector.get(FirstNewsDocument);
		this.countNewsQuery = this.injector.get(CountNewsDocument);
		this.findNewsQuery = this.injector.get(FindNewsDocument);
		this.searchNewsQuery = this.injector.get(SearchNewsDocument);
		this.exportSchemaNewsQuery = this.injector.get(ExportSchemaNewsDocument);
		this.exportDataNewsQuery = this.injector.get(ExportDataNewsDocument);
		this.customQueryNewsQuery = this.injector.get(CustomQueryNewsDocument);
		this.customQueryIdNewsQuery = this.injector.get(CustomQueryIdNewsDocument);
		this.findUnassociatedSecurityGroupsNewsQuery = this.injector.get(FindUnassociatedSecurityGroupsNewsDocument);
		this.findAssociatedPopulationSecurityGroupsNewsQuery = this.injector.get(FindAssociatedPopulationSecurityGroupsNewsDocument);
		this.findUnassociatedPopulationsNewsQuery = this.injector.get(FindUnassociatedPopulationsNewsDocument);
		this.usedNewsQuery = this.injector.get(UsedNewsDocument);
		this.findEnableNewsQuery = this.injector.get(FindEnableNewsDocument);
		this.existNewsQuery = this.injector.get(ExistNewsDocument);
		this.insertNewsMutation = this.injector.get(InsertNewsDocument);
		this.updateNewsMutation = this.injector.get(UpdateNewsDocument);
		this.importDataNewsMutation = this.injector.get(ImportDataNewsDocument);
		this.updateManyNewsMutation = this.injector.get(UpdateManyNewsDocument);
		this.deleteNewsMutation = this.injector.get(DeleteNewsDocument);
		this.orderNewsMutation = this.injector.get(OrderNewsDocument);
		this.addSecurityGroupNewsMutation = this.injector.get(AddSecurityGroupNewsDocument);
		this.removeSecurityGroupNewsMutation = this.injector.get(RemoveSecurityGroupNewsDocument);
		this.addPopulationsNewsMutation = this.injector.get(AddPopulationsNewsDocument);
		this.removePopulationsNewsMutation = this.injector.get(RemovePopulationsNewsDocument);
		this.addPopulationSecurityGroupNewsMutation = this.injector.get(AddPopulationSecurityGroupNewsDocument);
		this.removePopulationSecurityGroupNewsMutation = this.injector.get(RemovePopulationSecurityGroupNewsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNews> {
    
    		let variables: GetNewsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.get));
            }
            else{
                let result:ServiceSingleResultOfNews={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNews,
		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNews> {
    
    		let variables: FirstNewsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNewsBaseVariables = {
    			filter: filter
    		}
    				return this.countNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNews,
		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNews> {
    
    		let variables: FindNewsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNews,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNews> {
    
    		let variables: SearchNewsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNewsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNewsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNews,
		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNews> {
    
    		let variables: CustomQueryNewsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNews,
		@Args('filter?') filter?: FilterOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNews> {
    
    		let variables: CustomQueryIdNewsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsNewsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les population via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedPopulationSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindAssociatedPopulationSecurityGroupsNewsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedPopulationSecurityGroupsNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.findAssociatedPopulationSecurityGroups));
    	}

	@InjectArgs
	public findAssociatedPopulations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfPopulation> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('populations', fields, null, [
			GqlSubFieldArg.create('filterOfPopulations', 'filter'),
			GqlSubFieldArg.create('optionsOfPopulations', 'options'),
		]),
		])
		extendedVariables['filterOfPopulations'] = filter;
		extendedVariables['optionsOfPopulations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.populations));
            }
            else{
                let result: ServiceListResultOfPopulation = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les populations non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindUnassociatedPopulationsNewsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedPopulationsNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.findUnassociatedPopulations));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNewsBaseVariables = {
    			ids: ids
    		}
    				return this.usedNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.used));
    	}

    	/**  */
    	@InjectArgs
    	public findEnable(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNews> {
    
    		let variables: FindEnableNewsBaseVariables = {
    			options: options
    		}
    				return this.findEnableNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.findEnable));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNewsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNewsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.news.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: NewsInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNews> {
    
    		let variables: InsertNewsBaseVariables = {
    			entity: entity
    		}
    				return this.insertNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNews> {
    
    		let variables: UpdateNewsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataNewsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfNews,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyNewsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteNewsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderNewsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.order));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupNewsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupNewsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationsNewsBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.addPopulationsNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.addPopulations));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationsNewsBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationsNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.removePopulations));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationSecurityGroupNewsBaseVariables = {
    			id: id,
			populationIds: populationIds,
			isNew: isNew
    		}
    				return this.addPopulationSecurityGroupNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.addPopulationSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationSecurityGroupNewsBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationSecurityGroupNewsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.news.removePopulationSecurityGroup));
    	}
    
}