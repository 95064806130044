<cl-load *ngIf="isLoad === false"></cl-load>

<dx-popup
  *ngIf="visible === true"
  [width]="'30%'"
  [height]="'auto'"
  [showTitle]="false"
  [dragEnabled]="false"
  [hideOnOutsideClick]="false"
  [showCloseButton]="false"
  class="add-item-popup"
  [resizeEnabled]="false"
  [(visible)]="visible"
>
  <cl-work-page>
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title [showBack]="false">
          {{ 'globals/Information' | translate }}
        </cl-work-page-header-content-title>
      </cl-work-page-header-content>
    </cl-work-page-header>
    <cl-work-page-content>
      <div style="padding: 10px">
        <p *ngIf="visible === true">
          {{ 'globals/accessTokenDenied' | translate }}
        </p>
      </div>
    </cl-work-page-content>
  </cl-work-page>

  <dxi-toolbar-item
    widget="dxButton"
    toolbar="bottom"
    location="center"
    [options]="closeButtonOptions"
  >
  </dxi-toolbar-item>
</dx-popup>
<router-outlet></router-outlet>
