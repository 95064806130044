import { SnmpMapInformationBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-snmp-kb-option')
export class SnmpMapInformationCoreService extends SnmpMapInformationBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
