import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { FindClarilogLocalAgentsBaseVariables, FindAllInventoryHistoryClarilogLocalAgentsBaseVariables, FindLastInventoryHistoryClarilogLocalAgentsBaseVariables, TokenClarilogLocalAgentsBaseVariables, DeleteClarilogLocalAgentsBaseVariables, CancelAuditClarilogLocalAgentsBaseVariables, RunAuditClarilogLocalAgentsBaseVariables, SetEnableClarilogLocalAgentsBaseVariables, UpdateCampaignHistoryClarilogLocalAgentsBaseVariables } from '../gqls'
import { FindClarilogLocalAgentsDocument, FindAllInventoryHistoryClarilogLocalAgentsDocument, FindLastInventoryHistoryClarilogLocalAgentsDocument, TokenClarilogLocalAgentsDocument, DeleteClarilogLocalAgentsDocument, CancelAuditClarilogLocalAgentsDocument, RunAuditClarilogLocalAgentsDocument, SetEnableClarilogLocalAgentsDocument, UpdateCampaignHistoryClarilogLocalAgentsDocument } from '../gqls'
import { ServiceListResultOfAsset, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfInventoryHistory, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfAuditTime, ServiceSingleResultOfString, ServiceSingleResultOfBoolean, HistoryState } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ClarilogLocalAgentBaseService {
    
public modelName = 'clarilogLocalAgent';
public modelPluralName = 'clarilogLocalAgents';

	private findClarilogLocalAgentsQuery: FindClarilogLocalAgentsDocument;
	private findAllInventoryHistoryClarilogLocalAgentsQuery: FindAllInventoryHistoryClarilogLocalAgentsDocument;
	private findLastInventoryHistoryClarilogLocalAgentsQuery: FindLastInventoryHistoryClarilogLocalAgentsDocument;
	private tokenClarilogLocalAgentsQuery: TokenClarilogLocalAgentsDocument;
	private deleteClarilogLocalAgentsMutation: DeleteClarilogLocalAgentsDocument;
	private cancelAuditClarilogLocalAgentsMutation: CancelAuditClarilogLocalAgentsDocument;
	private runAuditClarilogLocalAgentsMutation: RunAuditClarilogLocalAgentsDocument;
	private setEnableClarilogLocalAgentsMutation: SetEnableClarilogLocalAgentsDocument;
	private updateCampaignHistoryClarilogLocalAgentsMutation: UpdateCampaignHistoryClarilogLocalAgentsDocument;

	constructor(private injector: Injector) {
		this.findClarilogLocalAgentsQuery = this.injector.get(FindClarilogLocalAgentsDocument);
		this.findAllInventoryHistoryClarilogLocalAgentsQuery = this.injector.get(FindAllInventoryHistoryClarilogLocalAgentsDocument);
		this.findLastInventoryHistoryClarilogLocalAgentsQuery = this.injector.get(FindLastInventoryHistoryClarilogLocalAgentsDocument);
		this.tokenClarilogLocalAgentsQuery = this.injector.get(TokenClarilogLocalAgentsDocument);
		this.deleteClarilogLocalAgentsMutation = this.injector.get(DeleteClarilogLocalAgentsDocument);
		this.cancelAuditClarilogLocalAgentsMutation = this.injector.get(CancelAuditClarilogLocalAgentsDocument);
		this.runAuditClarilogLocalAgentsMutation = this.injector.get(RunAuditClarilogLocalAgentsDocument);
		this.setEnableClarilogLocalAgentsMutation = this.injector.get(SetEnableClarilogLocalAgentsDocument);
		this.updateCampaignHistoryClarilogLocalAgentsMutation = this.injector.get(UpdateCampaignHistoryClarilogLocalAgentsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/**  */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindClarilogLocalAgentsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findClarilogLocalAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.find));
    	}

    	/**  */
    	@InjectArgs
    	public findAllInventoryHistory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindAllInventoryHistoryClarilogLocalAgentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllInventoryHistoryClarilogLocalAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.findAllInventoryHistory));
    	}

    	/**  */
    	@InjectArgs
    	public findLastInventoryHistory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAuditTime> {
    
    		let variables: FindLastInventoryHistoryClarilogLocalAgentsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findLastInventoryHistoryClarilogLocalAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.findLastInventoryHistory));
    	}

    	/**  */
    	@InjectArgs
    	public token(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: TokenClarilogLocalAgentsBaseVariables = {
    
    		}
    				return this.tokenClarilogLocalAgentsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.token));
    	}
    
    	/**  */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteClarilogLocalAgentsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteClarilogLocalAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.delete));
    	}

    	/**  */
    	@InjectArgs
    	public cancelAudit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CancelAuditClarilogLocalAgentsBaseVariables = {
    			ids: ids
    		}
    				return this.cancelAuditClarilogLocalAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.cancelAudit));
    	}

    	/**  */
    	@InjectArgs
    	public runAudit(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RunAuditClarilogLocalAgentsBaseVariables = {
    			ids: ids
    		}
    				return this.runAuditClarilogLocalAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.runAudit));
    	}

    	/**  */
    	@InjectArgs
    	public setEnable(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: boolean,
		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SetEnableClarilogLocalAgentsBaseVariables = {
    			ids: ids,
			state: state
    		}
    				return this.setEnableClarilogLocalAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.setEnable));
    	}

    	/**  */
    	@InjectArgs
    	public updateCampaignHistory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: HistoryState,
		@Args('id') id: string,
		@Args('error?') error?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateCampaignHistoryClarilogLocalAgentsBaseVariables = {
    			id: id,
			state: state,
			error: error
    		}
    				return this.updateCampaignHistoryClarilogLocalAgentsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.clarilogLocalAgents.updateCampaignHistory));
    	}
    
}