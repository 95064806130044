import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCostCentersBaseVariables, FirstCostCentersBaseVariables, CountCostCentersBaseVariables, FindCostCentersBaseVariables, SearchCostCentersBaseVariables, ExportSchemaCostCentersBaseVariables, ExportDataCostCentersBaseVariables, CustomQueryCostCentersBaseVariables, CustomQueryIdCostCentersBaseVariables, UsedCostCentersBaseVariables, ExistCostCentersBaseVariables, FindRecyclesCostCentersBaseVariables, CountRecyclesCostCentersBaseVariables, ReadOnlyCostCentersBaseVariables, FindArchivedCostCentersBaseVariables, CountAllCostCentersBaseVariables, FindDynamicPropertyFieldsCostCentersBaseVariables, InsertCostCentersBaseVariables, UpdateCostCentersBaseVariables, ImportDataCostCentersBaseVariables, UpdateManyCostCentersBaseVariables, DeleteCostCentersBaseVariables, RestoreCostCentersBaseVariables, RecycleCostCentersBaseVariables, RestoreArchivedCostCentersBaseVariables, ArchivedCostCentersBaseVariables, AddFileDynamicFieldCostCentersBaseVariables, RemoveFileDynamicFieldCostCentersBaseVariables } from '../gqls'
import { GetCostCentersDocument, FirstCostCentersDocument, CountCostCentersDocument, FindCostCentersDocument, SearchCostCentersDocument, ExportSchemaCostCentersDocument, ExportDataCostCentersDocument, CustomQueryCostCentersDocument, CustomQueryIdCostCentersDocument, UsedCostCentersDocument, ExistCostCentersDocument, FindRecyclesCostCentersDocument, CountRecyclesCostCentersDocument, ReadOnlyCostCentersDocument, FindArchivedCostCentersDocument, CountAllCostCentersDocument, FindDynamicPropertyFieldsCostCentersDocument, InsertCostCentersDocument, UpdateCostCentersDocument, ImportDataCostCentersDocument, UpdateManyCostCentersDocument, DeleteCostCentersDocument, RestoreCostCentersDocument, RecycleCostCentersDocument, RestoreArchivedCostCentersDocument, ArchivedCostCentersDocument, AddFileDynamicFieldCostCentersDocument, RemoveFileDynamicFieldCostCentersDocument } from '../gqls'
import { ServiceSingleResultOfCostCenter, QueryContextOfCostCenter, FilterOfCostCenter, ServiceSingleResultOfInt64, ServiceListResultOfCostCenter, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfCostCenter, CostCenterInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CostCenterBaseService {
    
public modelName = 'costCenter';
public modelPluralName = 'costCenters';

	private getCostCentersQuery: GetCostCentersDocument;
	private firstCostCentersQuery: FirstCostCentersDocument;
	private countCostCentersQuery: CountCostCentersDocument;
	private findCostCentersQuery: FindCostCentersDocument;
	private searchCostCentersQuery: SearchCostCentersDocument;
	private exportSchemaCostCentersQuery: ExportSchemaCostCentersDocument;
	private exportDataCostCentersQuery: ExportDataCostCentersDocument;
	private customQueryCostCentersQuery: CustomQueryCostCentersDocument;
	private customQueryIdCostCentersQuery: CustomQueryIdCostCentersDocument;
	private usedCostCentersQuery: UsedCostCentersDocument;
	private existCostCentersQuery: ExistCostCentersDocument;
	private findRecyclesCostCentersQuery: FindRecyclesCostCentersDocument;
	private countRecyclesCostCentersQuery: CountRecyclesCostCentersDocument;
	private readOnlyCostCentersQuery: ReadOnlyCostCentersDocument;
	private findArchivedCostCentersQuery: FindArchivedCostCentersDocument;
	private countAllCostCentersQuery: CountAllCostCentersDocument;
	private findDynamicPropertyFieldsCostCentersQuery: FindDynamicPropertyFieldsCostCentersDocument;
	private insertCostCentersMutation: InsertCostCentersDocument;
	private updateCostCentersMutation: UpdateCostCentersDocument;
	private importDataCostCentersMutation: ImportDataCostCentersDocument;
	private updateManyCostCentersMutation: UpdateManyCostCentersDocument;
	private deleteCostCentersMutation: DeleteCostCentersDocument;
	private restoreCostCentersMutation: RestoreCostCentersDocument;
	private recycleCostCentersMutation: RecycleCostCentersDocument;
	private restoreArchivedCostCentersMutation: RestoreArchivedCostCentersDocument;
	private archivedCostCentersMutation: ArchivedCostCentersDocument;
	private addFileDynamicFieldCostCentersMutation: AddFileDynamicFieldCostCentersDocument;
	private removeFileDynamicFieldCostCentersMutation: RemoveFileDynamicFieldCostCentersDocument;

	constructor(private injector: Injector) {
		this.getCostCentersQuery = this.injector.get(GetCostCentersDocument);
		this.firstCostCentersQuery = this.injector.get(FirstCostCentersDocument);
		this.countCostCentersQuery = this.injector.get(CountCostCentersDocument);
		this.findCostCentersQuery = this.injector.get(FindCostCentersDocument);
		this.searchCostCentersQuery = this.injector.get(SearchCostCentersDocument);
		this.exportSchemaCostCentersQuery = this.injector.get(ExportSchemaCostCentersDocument);
		this.exportDataCostCentersQuery = this.injector.get(ExportDataCostCentersDocument);
		this.customQueryCostCentersQuery = this.injector.get(CustomQueryCostCentersDocument);
		this.customQueryIdCostCentersQuery = this.injector.get(CustomQueryIdCostCentersDocument);
		this.usedCostCentersQuery = this.injector.get(UsedCostCentersDocument);
		this.existCostCentersQuery = this.injector.get(ExistCostCentersDocument);
		this.findRecyclesCostCentersQuery = this.injector.get(FindRecyclesCostCentersDocument);
		this.countRecyclesCostCentersQuery = this.injector.get(CountRecyclesCostCentersDocument);
		this.readOnlyCostCentersQuery = this.injector.get(ReadOnlyCostCentersDocument);
		this.findArchivedCostCentersQuery = this.injector.get(FindArchivedCostCentersDocument);
		this.countAllCostCentersQuery = this.injector.get(CountAllCostCentersDocument);
		this.findDynamicPropertyFieldsCostCentersQuery = this.injector.get(FindDynamicPropertyFieldsCostCentersDocument);
		this.insertCostCentersMutation = this.injector.get(InsertCostCentersDocument);
		this.updateCostCentersMutation = this.injector.get(UpdateCostCentersDocument);
		this.importDataCostCentersMutation = this.injector.get(ImportDataCostCentersDocument);
		this.updateManyCostCentersMutation = this.injector.get(UpdateManyCostCentersDocument);
		this.deleteCostCentersMutation = this.injector.get(DeleteCostCentersDocument);
		this.restoreCostCentersMutation = this.injector.get(RestoreCostCentersDocument);
		this.recycleCostCentersMutation = this.injector.get(RecycleCostCentersDocument);
		this.restoreArchivedCostCentersMutation = this.injector.get(RestoreArchivedCostCentersDocument);
		this.archivedCostCentersMutation = this.injector.get(ArchivedCostCentersDocument);
		this.addFileDynamicFieldCostCentersMutation = this.injector.get(AddFileDynamicFieldCostCentersDocument);
		this.removeFileDynamicFieldCostCentersMutation = this.injector.get(RemoveFileDynamicFieldCostCentersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCostCenter> {
    
    		let variables: GetCostCentersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.get));
            }
            else{
                let result:ServiceSingleResultOfCostCenter={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCostCenter> {
    
    		let variables: FirstCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: FindCostCentersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: SearchCostCentersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCostCentersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: CustomQueryCostCentersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: CustomQueryIdCostCentersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.usedCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCostCentersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: FindRecyclesCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCostCentersBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCostCenter,
		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCostCenter> {
    
    		let variables: FindArchivedCostCentersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllCostCentersBaseVariables = {
    			filter: filter
    		}
    				return this.countAllCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsCostCentersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsCostCentersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CostCenterInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCostCenter> {
    
    		let variables: InsertCostCentersBaseVariables = {
    			entity: entity
    		}
    				return this.insertCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCostCenter> {
    
    		let variables: UpdateCostCentersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCostCentersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCostCenter,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCostCentersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.recycleCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedCostCentersBaseVariables = {
    			ids: ids
    		}
    				return this.archivedCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldCostCentersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldCostCentersBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldCostCentersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.costCenters.removeFileDynamicField));
    	}
    
}