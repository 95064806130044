import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { MobileWorkItem } from '@clarilog/shared2';
import { MobileLayoutNavService } from '@clarilog/layouts2/mobile-layout/mobile-layout-nav.service';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { GC, GCFactory } from '@clarilog/core';
import { DxDrawerComponent, DxTreeViewComponent } from 'devextreme-angular';

export enum MobileNavFormEvent {
  NAVIGATE = 'MOBILE_NAV_FORM_NAVIGATE',
}

@Component({
  selector: 'cl-mobile-nav-menu',
  templateUrl: './mobile-nav-menu.component.html',
  styleUrls: ['./mobile-nav-menu.component.scss'],
})
export class MobileNavMenuComponent implements OnInit {
  constructor(private gcFactory: GCFactory) {
    this._gc = gcFactory.create();
  }

  @ViewChild(DxDrawerComponent) dxDrawer: DxDrawerComponent;
  @ViewChild(DxTreeViewComponent) treeView: DxTreeViewComponent;
  @Output() menuNavMobileVisibility: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  _gc: GC;
  _isSubMenuOpen: boolean = false;
  _debounceMenuOpen = false;
  @Input() set isMenuOpen(value) {
    let oldValue = this._isSubMenuOpen;
    this._isSubMenuOpen = value;
    this.itemNavList = this.itemNavList.filter((x) => x.data.visible != false);
    this.isMenuOpenChange.emit(this._isSubMenuOpen);

    if (this._isSubMenuOpen === true && oldValue != this._isSubMenuOpen) {
      this.treeView.instance.focus();
    }
  }

  debounceMenuOpen() {
    this._debounceMenuOpen = true;

    setTimeout((_) => {
      this._debounceMenuOpen = false;
    }, 200);
  }

  get isMenuOpen() {
    return this._isSubMenuOpen;
  }

  @Output() isMenuOpenChange: EventEmitter<boolean> =
    new EventEmitter<boolean>();

  @Input() state: ModelState;

  /**
   * Items du menu pour la version mobile
   */
  itemNavList: MobileWorkItem[] = [];

  @Input() set navItems(items: MobileWorkItem[]) {
    if (items != undefined) {
      for (let item of items) {
        // On push que les items qui doivent être visibles par l'utilisateur
        if (item.items[0].data.userVisible)
          this.itemNavList.push(item.items[0]);
      }
      MobileLayoutNavService.setChildId(this.itemNavList);
      let first = this.selectFirstMenu(this.itemNavList);
      first.isSelected = true;
      this.selected = first;
    }
  }

  private selectFirstMenu(items: MobileWorkItem[]) {
    if (items.length > 0 && items[0].title != undefined) {
      this.menuNavMobileVisibility.emit(true);
    } else {
      this.menuNavMobileVisibility.emit(false);
    }
    for (let item of items) {
      if (item.children == undefined || item.children.length == 0) {
        return item;
      } else {
        return this.selectFirstMenu(item.children);
      }
    }
  }

  selected: MobileWorkItem;
  clickOutside = true;

  menuSelectionChanged(e) {
    let item = e.itemData as MobileWorkItem;

    if (item.isSelected === true) {
      // because event deselection actuel et slelection du nouveau

      this.selected = item;

      this.isMenuOpen = false;
      this.state.on.emit({
        eventName: MobileNavFormEvent.NAVIGATE,
        eventData: item,
      });
    }
    //gestion du clic sur menu parent redirect vers premier enfant
    if (item.children != undefined && item.children.length > 0) {
      // item.isSelected = false;
      //
      // let defaultChild = this.selectFirstMenu(item.children);
      //
      // defaultChild.isSelected = true;
      // item = defaultChild;
    }
  }

  close() {
    setTimeout((_) => {
      if (this._isSubMenuOpen === true) {
        this._isSubMenuOpen = false;
        this.isMenuOpenChange.emit(false);
        this.debounceMenuOpen();
      }
    });
  }

  onItemClick(e) {
    this.close();
  }

  onFocusOut(e) {
    this.close();
  }

  ngOnInit(): void {}
}
