import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWmiCredentialsBaseVariables, FirstWmiCredentialsBaseVariables, CountWmiCredentialsBaseVariables, FindWmiCredentialsBaseVariables, SearchWmiCredentialsBaseVariables, ExportSchemaWmiCredentialsBaseVariables, ExportDataWmiCredentialsBaseVariables, CustomQueryWmiCredentialsBaseVariables, CustomQueryIdWmiCredentialsBaseVariables, UsedWmiCredentialsBaseVariables, ExistWmiCredentialsBaseVariables, FindRecyclesWmiCredentialsBaseVariables, CountRecyclesWmiCredentialsBaseVariables, ReadOnlyWmiCredentialsBaseVariables, FindArchivedWmiCredentialsBaseVariables, CountAllWmiCredentialsBaseVariables, FindDynamicPropertyFieldsWmiCredentialsBaseVariables, FindUnassociatedScanConfigurationsWmiCredentialsBaseVariables, FindUnassociatedInventoryHistoriesWmiCredentialsBaseVariables, FindUnassociatedScanByLdapsWmiCredentialsBaseVariables, InsertWmiCredentialsBaseVariables, UpdateWmiCredentialsBaseVariables, ImportDataWmiCredentialsBaseVariables, UpdateManyWmiCredentialsBaseVariables, DeleteWmiCredentialsBaseVariables, RestoreWmiCredentialsBaseVariables, RecycleWmiCredentialsBaseVariables, RestoreArchivedWmiCredentialsBaseVariables, ArchivedWmiCredentialsBaseVariables, AddFileDynamicFieldWmiCredentialsBaseVariables, RemoveFileDynamicFieldWmiCredentialsBaseVariables } from '../gqls'
import { GetWmiCredentialsDocument, FirstWmiCredentialsDocument, CountWmiCredentialsDocument, FindWmiCredentialsDocument, SearchWmiCredentialsDocument, ExportSchemaWmiCredentialsDocument, ExportDataWmiCredentialsDocument, CustomQueryWmiCredentialsDocument, CustomQueryIdWmiCredentialsDocument, UsedWmiCredentialsDocument, ExistWmiCredentialsDocument, FindRecyclesWmiCredentialsDocument, CountRecyclesWmiCredentialsDocument, ReadOnlyWmiCredentialsDocument, FindArchivedWmiCredentialsDocument, CountAllWmiCredentialsDocument, FindDynamicPropertyFieldsWmiCredentialsDocument, FindUnassociatedScanConfigurationsWmiCredentialsDocument, FindUnassociatedInventoryHistoriesWmiCredentialsDocument, FindUnassociatedScanByLdapsWmiCredentialsDocument, InsertWmiCredentialsDocument, UpdateWmiCredentialsDocument, ImportDataWmiCredentialsDocument, UpdateManyWmiCredentialsDocument, DeleteWmiCredentialsDocument, RestoreWmiCredentialsDocument, RecycleWmiCredentialsDocument, RestoreArchivedWmiCredentialsDocument, ArchivedWmiCredentialsDocument, AddFileDynamicFieldWmiCredentialsDocument, RemoveFileDynamicFieldWmiCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfWmiCredential, QueryContextOfWmiCredential, FilterOfWmiCredential, ServiceSingleResultOfInt64, ServiceListResultOfWmiCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfWmiCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, WmiCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WmiCredentialBaseService {
    
public modelName = 'wmiCredential';
public modelPluralName = 'wmiCredentials';

	private getWmiCredentialsQuery: GetWmiCredentialsDocument;
	private firstWmiCredentialsQuery: FirstWmiCredentialsDocument;
	private countWmiCredentialsQuery: CountWmiCredentialsDocument;
	private findWmiCredentialsQuery: FindWmiCredentialsDocument;
	private searchWmiCredentialsQuery: SearchWmiCredentialsDocument;
	private exportSchemaWmiCredentialsQuery: ExportSchemaWmiCredentialsDocument;
	private exportDataWmiCredentialsQuery: ExportDataWmiCredentialsDocument;
	private customQueryWmiCredentialsQuery: CustomQueryWmiCredentialsDocument;
	private customQueryIdWmiCredentialsQuery: CustomQueryIdWmiCredentialsDocument;
	private usedWmiCredentialsQuery: UsedWmiCredentialsDocument;
	private existWmiCredentialsQuery: ExistWmiCredentialsDocument;
	private findRecyclesWmiCredentialsQuery: FindRecyclesWmiCredentialsDocument;
	private countRecyclesWmiCredentialsQuery: CountRecyclesWmiCredentialsDocument;
	private readOnlyWmiCredentialsQuery: ReadOnlyWmiCredentialsDocument;
	private findArchivedWmiCredentialsQuery: FindArchivedWmiCredentialsDocument;
	private countAllWmiCredentialsQuery: CountAllWmiCredentialsDocument;
	private findDynamicPropertyFieldsWmiCredentialsQuery: FindDynamicPropertyFieldsWmiCredentialsDocument;
	private findUnassociatedScanConfigurationsWmiCredentialsQuery: FindUnassociatedScanConfigurationsWmiCredentialsDocument;
	private findUnassociatedInventoryHistoriesWmiCredentialsQuery: FindUnassociatedInventoryHistoriesWmiCredentialsDocument;
	private findUnassociatedScanByLdapsWmiCredentialsQuery: FindUnassociatedScanByLdapsWmiCredentialsDocument;
	private insertWmiCredentialsMutation: InsertWmiCredentialsDocument;
	private updateWmiCredentialsMutation: UpdateWmiCredentialsDocument;
	private importDataWmiCredentialsMutation: ImportDataWmiCredentialsDocument;
	private updateManyWmiCredentialsMutation: UpdateManyWmiCredentialsDocument;
	private deleteWmiCredentialsMutation: DeleteWmiCredentialsDocument;
	private restoreWmiCredentialsMutation: RestoreWmiCredentialsDocument;
	private recycleWmiCredentialsMutation: RecycleWmiCredentialsDocument;
	private restoreArchivedWmiCredentialsMutation: RestoreArchivedWmiCredentialsDocument;
	private archivedWmiCredentialsMutation: ArchivedWmiCredentialsDocument;
	private addFileDynamicFieldWmiCredentialsMutation: AddFileDynamicFieldWmiCredentialsDocument;
	private removeFileDynamicFieldWmiCredentialsMutation: RemoveFileDynamicFieldWmiCredentialsDocument;

	constructor(private injector: Injector) {
		this.getWmiCredentialsQuery = this.injector.get(GetWmiCredentialsDocument);
		this.firstWmiCredentialsQuery = this.injector.get(FirstWmiCredentialsDocument);
		this.countWmiCredentialsQuery = this.injector.get(CountWmiCredentialsDocument);
		this.findWmiCredentialsQuery = this.injector.get(FindWmiCredentialsDocument);
		this.searchWmiCredentialsQuery = this.injector.get(SearchWmiCredentialsDocument);
		this.exportSchemaWmiCredentialsQuery = this.injector.get(ExportSchemaWmiCredentialsDocument);
		this.exportDataWmiCredentialsQuery = this.injector.get(ExportDataWmiCredentialsDocument);
		this.customQueryWmiCredentialsQuery = this.injector.get(CustomQueryWmiCredentialsDocument);
		this.customQueryIdWmiCredentialsQuery = this.injector.get(CustomQueryIdWmiCredentialsDocument);
		this.usedWmiCredentialsQuery = this.injector.get(UsedWmiCredentialsDocument);
		this.existWmiCredentialsQuery = this.injector.get(ExistWmiCredentialsDocument);
		this.findRecyclesWmiCredentialsQuery = this.injector.get(FindRecyclesWmiCredentialsDocument);
		this.countRecyclesWmiCredentialsQuery = this.injector.get(CountRecyclesWmiCredentialsDocument);
		this.readOnlyWmiCredentialsQuery = this.injector.get(ReadOnlyWmiCredentialsDocument);
		this.findArchivedWmiCredentialsQuery = this.injector.get(FindArchivedWmiCredentialsDocument);
		this.countAllWmiCredentialsQuery = this.injector.get(CountAllWmiCredentialsDocument);
		this.findDynamicPropertyFieldsWmiCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsWmiCredentialsDocument);
		this.findUnassociatedScanConfigurationsWmiCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsWmiCredentialsDocument);
		this.findUnassociatedInventoryHistoriesWmiCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesWmiCredentialsDocument);
		this.findUnassociatedScanByLdapsWmiCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsWmiCredentialsDocument);
		this.insertWmiCredentialsMutation = this.injector.get(InsertWmiCredentialsDocument);
		this.updateWmiCredentialsMutation = this.injector.get(UpdateWmiCredentialsDocument);
		this.importDataWmiCredentialsMutation = this.injector.get(ImportDataWmiCredentialsDocument);
		this.updateManyWmiCredentialsMutation = this.injector.get(UpdateManyWmiCredentialsDocument);
		this.deleteWmiCredentialsMutation = this.injector.get(DeleteWmiCredentialsDocument);
		this.restoreWmiCredentialsMutation = this.injector.get(RestoreWmiCredentialsDocument);
		this.recycleWmiCredentialsMutation = this.injector.get(RecycleWmiCredentialsDocument);
		this.restoreArchivedWmiCredentialsMutation = this.injector.get(RestoreArchivedWmiCredentialsDocument);
		this.archivedWmiCredentialsMutation = this.injector.get(ArchivedWmiCredentialsDocument);
		this.addFileDynamicFieldWmiCredentialsMutation = this.injector.get(AddFileDynamicFieldWmiCredentialsDocument);
		this.removeFileDynamicFieldWmiCredentialsMutation = this.injector.get(RemoveFileDynamicFieldWmiCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWmiCredential> {
    
    		let variables: GetWmiCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfWmiCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWmiCredential> {
    
    		let variables: FirstWmiCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWmiCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: FindWmiCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: SearchWmiCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWmiCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWmiCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: CustomQueryWmiCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: CustomQueryIdWmiCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWmiCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: FindRecyclesWmiCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesWmiCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyWmiCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWmiCredential,
		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWmiCredential> {
    
    		let variables: FindArchivedWmiCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllWmiCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsWmiCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsWmiCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesWmiCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsWmiCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsWmiCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WmiCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWmiCredential> {
    
    		let variables: InsertWmiCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWmiCredential> {
    
    		let variables: UpdateWmiCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWmiCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWmiCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWmiCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedWmiCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldWmiCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldWmiCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldWmiCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.wmiCredentials.removeFileDynamicField));
    	}
    
}