import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOrganizationalUnitCategoriesBaseVariables, FirstOrganizationalUnitCategoriesBaseVariables, CountOrganizationalUnitCategoriesBaseVariables, FindOrganizationalUnitCategoriesBaseVariables, SearchOrganizationalUnitCategoriesBaseVariables, ExportSchemaOrganizationalUnitCategoriesBaseVariables, ExportDataOrganizationalUnitCategoriesBaseVariables, CustomQueryOrganizationalUnitCategoriesBaseVariables, CustomQueryIdOrganizationalUnitCategoriesBaseVariables, UsedOrganizationalUnitCategoriesBaseVariables, ExistOrganizationalUnitCategoriesBaseVariables, FindRecyclesOrganizationalUnitCategoriesBaseVariables, CountRecyclesOrganizationalUnitCategoriesBaseVariables, ReadOnlyOrganizationalUnitCategoriesBaseVariables, FindArchivedOrganizationalUnitCategoriesBaseVariables, CountAllOrganizationalUnitCategoriesBaseVariables, FindDynamicPropertyFieldsOrganizationalUnitCategoriesBaseVariables, InsertOrganizationalUnitCategoriesBaseVariables, UpdateOrganizationalUnitCategoriesBaseVariables, ImportDataOrganizationalUnitCategoriesBaseVariables, UpdateManyOrganizationalUnitCategoriesBaseVariables, DeleteOrganizationalUnitCategoriesBaseVariables, RestoreOrganizationalUnitCategoriesBaseVariables, RecycleOrganizationalUnitCategoriesBaseVariables, RestoreArchivedOrganizationalUnitCategoriesBaseVariables, ArchivedOrganizationalUnitCategoriesBaseVariables, AddFileDynamicFieldOrganizationalUnitCategoriesBaseVariables, RemoveFileDynamicFieldOrganizationalUnitCategoriesBaseVariables } from '../gqls'
import { GetOrganizationalUnitCategoriesDocument, FirstOrganizationalUnitCategoriesDocument, CountOrganizationalUnitCategoriesDocument, FindOrganizationalUnitCategoriesDocument, SearchOrganizationalUnitCategoriesDocument, ExportSchemaOrganizationalUnitCategoriesDocument, ExportDataOrganizationalUnitCategoriesDocument, CustomQueryOrganizationalUnitCategoriesDocument, CustomQueryIdOrganizationalUnitCategoriesDocument, UsedOrganizationalUnitCategoriesDocument, ExistOrganizationalUnitCategoriesDocument, FindRecyclesOrganizationalUnitCategoriesDocument, CountRecyclesOrganizationalUnitCategoriesDocument, ReadOnlyOrganizationalUnitCategoriesDocument, FindArchivedOrganizationalUnitCategoriesDocument, CountAllOrganizationalUnitCategoriesDocument, FindDynamicPropertyFieldsOrganizationalUnitCategoriesDocument, InsertOrganizationalUnitCategoriesDocument, UpdateOrganizationalUnitCategoriesDocument, ImportDataOrganizationalUnitCategoriesDocument, UpdateManyOrganizationalUnitCategoriesDocument, DeleteOrganizationalUnitCategoriesDocument, RestoreOrganizationalUnitCategoriesDocument, RecycleOrganizationalUnitCategoriesDocument, RestoreArchivedOrganizationalUnitCategoriesDocument, ArchivedOrganizationalUnitCategoriesDocument, AddFileDynamicFieldOrganizationalUnitCategoriesDocument, RemoveFileDynamicFieldOrganizationalUnitCategoriesDocument } from '../gqls'
import { ServiceSingleResultOfOrganizationalUnitCategory, QueryContextOfOrganizationalUnitCategory, FilterOfOrganizationalUnitCategory, ServiceSingleResultOfInt64, ServiceListResultOfOrganizationalUnitCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrganizationalUnitCategory, OrganizationalUnitCategoryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OrganizationalUnitCategoryBaseService {
    
public modelName = 'organizationalUnitCategory';
public modelPluralName = 'organizationalUnitCategories';

	private getOrganizationalUnitCategoriesQuery: GetOrganizationalUnitCategoriesDocument;
	private firstOrganizationalUnitCategoriesQuery: FirstOrganizationalUnitCategoriesDocument;
	private countOrganizationalUnitCategoriesQuery: CountOrganizationalUnitCategoriesDocument;
	private findOrganizationalUnitCategoriesQuery: FindOrganizationalUnitCategoriesDocument;
	private searchOrganizationalUnitCategoriesQuery: SearchOrganizationalUnitCategoriesDocument;
	private exportSchemaOrganizationalUnitCategoriesQuery: ExportSchemaOrganizationalUnitCategoriesDocument;
	private exportDataOrganizationalUnitCategoriesQuery: ExportDataOrganizationalUnitCategoriesDocument;
	private customQueryOrganizationalUnitCategoriesQuery: CustomQueryOrganizationalUnitCategoriesDocument;
	private customQueryIdOrganizationalUnitCategoriesQuery: CustomQueryIdOrganizationalUnitCategoriesDocument;
	private usedOrganizationalUnitCategoriesQuery: UsedOrganizationalUnitCategoriesDocument;
	private existOrganizationalUnitCategoriesQuery: ExistOrganizationalUnitCategoriesDocument;
	private findRecyclesOrganizationalUnitCategoriesQuery: FindRecyclesOrganizationalUnitCategoriesDocument;
	private countRecyclesOrganizationalUnitCategoriesQuery: CountRecyclesOrganizationalUnitCategoriesDocument;
	private readOnlyOrganizationalUnitCategoriesQuery: ReadOnlyOrganizationalUnitCategoriesDocument;
	private findArchivedOrganizationalUnitCategoriesQuery: FindArchivedOrganizationalUnitCategoriesDocument;
	private countAllOrganizationalUnitCategoriesQuery: CountAllOrganizationalUnitCategoriesDocument;
	private findDynamicPropertyFieldsOrganizationalUnitCategoriesQuery: FindDynamicPropertyFieldsOrganizationalUnitCategoriesDocument;
	private insertOrganizationalUnitCategoriesMutation: InsertOrganizationalUnitCategoriesDocument;
	private updateOrganizationalUnitCategoriesMutation: UpdateOrganizationalUnitCategoriesDocument;
	private importDataOrganizationalUnitCategoriesMutation: ImportDataOrganizationalUnitCategoriesDocument;
	private updateManyOrganizationalUnitCategoriesMutation: UpdateManyOrganizationalUnitCategoriesDocument;
	private deleteOrganizationalUnitCategoriesMutation: DeleteOrganizationalUnitCategoriesDocument;
	private restoreOrganizationalUnitCategoriesMutation: RestoreOrganizationalUnitCategoriesDocument;
	private recycleOrganizationalUnitCategoriesMutation: RecycleOrganizationalUnitCategoriesDocument;
	private restoreArchivedOrganizationalUnitCategoriesMutation: RestoreArchivedOrganizationalUnitCategoriesDocument;
	private archivedOrganizationalUnitCategoriesMutation: ArchivedOrganizationalUnitCategoriesDocument;
	private addFileDynamicFieldOrganizationalUnitCategoriesMutation: AddFileDynamicFieldOrganizationalUnitCategoriesDocument;
	private removeFileDynamicFieldOrganizationalUnitCategoriesMutation: RemoveFileDynamicFieldOrganizationalUnitCategoriesDocument;

	constructor(private injector: Injector) {
		this.getOrganizationalUnitCategoriesQuery = this.injector.get(GetOrganizationalUnitCategoriesDocument);
		this.firstOrganizationalUnitCategoriesQuery = this.injector.get(FirstOrganizationalUnitCategoriesDocument);
		this.countOrganizationalUnitCategoriesQuery = this.injector.get(CountOrganizationalUnitCategoriesDocument);
		this.findOrganizationalUnitCategoriesQuery = this.injector.get(FindOrganizationalUnitCategoriesDocument);
		this.searchOrganizationalUnitCategoriesQuery = this.injector.get(SearchOrganizationalUnitCategoriesDocument);
		this.exportSchemaOrganizationalUnitCategoriesQuery = this.injector.get(ExportSchemaOrganizationalUnitCategoriesDocument);
		this.exportDataOrganizationalUnitCategoriesQuery = this.injector.get(ExportDataOrganizationalUnitCategoriesDocument);
		this.customQueryOrganizationalUnitCategoriesQuery = this.injector.get(CustomQueryOrganizationalUnitCategoriesDocument);
		this.customQueryIdOrganizationalUnitCategoriesQuery = this.injector.get(CustomQueryIdOrganizationalUnitCategoriesDocument);
		this.usedOrganizationalUnitCategoriesQuery = this.injector.get(UsedOrganizationalUnitCategoriesDocument);
		this.existOrganizationalUnitCategoriesQuery = this.injector.get(ExistOrganizationalUnitCategoriesDocument);
		this.findRecyclesOrganizationalUnitCategoriesQuery = this.injector.get(FindRecyclesOrganizationalUnitCategoriesDocument);
		this.countRecyclesOrganizationalUnitCategoriesQuery = this.injector.get(CountRecyclesOrganizationalUnitCategoriesDocument);
		this.readOnlyOrganizationalUnitCategoriesQuery = this.injector.get(ReadOnlyOrganizationalUnitCategoriesDocument);
		this.findArchivedOrganizationalUnitCategoriesQuery = this.injector.get(FindArchivedOrganizationalUnitCategoriesDocument);
		this.countAllOrganizationalUnitCategoriesQuery = this.injector.get(CountAllOrganizationalUnitCategoriesDocument);
		this.findDynamicPropertyFieldsOrganizationalUnitCategoriesQuery = this.injector.get(FindDynamicPropertyFieldsOrganizationalUnitCategoriesDocument);
		this.insertOrganizationalUnitCategoriesMutation = this.injector.get(InsertOrganizationalUnitCategoriesDocument);
		this.updateOrganizationalUnitCategoriesMutation = this.injector.get(UpdateOrganizationalUnitCategoriesDocument);
		this.importDataOrganizationalUnitCategoriesMutation = this.injector.get(ImportDataOrganizationalUnitCategoriesDocument);
		this.updateManyOrganizationalUnitCategoriesMutation = this.injector.get(UpdateManyOrganizationalUnitCategoriesDocument);
		this.deleteOrganizationalUnitCategoriesMutation = this.injector.get(DeleteOrganizationalUnitCategoriesDocument);
		this.restoreOrganizationalUnitCategoriesMutation = this.injector.get(RestoreOrganizationalUnitCategoriesDocument);
		this.recycleOrganizationalUnitCategoriesMutation = this.injector.get(RecycleOrganizationalUnitCategoriesDocument);
		this.restoreArchivedOrganizationalUnitCategoriesMutation = this.injector.get(RestoreArchivedOrganizationalUnitCategoriesDocument);
		this.archivedOrganizationalUnitCategoriesMutation = this.injector.get(ArchivedOrganizationalUnitCategoriesDocument);
		this.addFileDynamicFieldOrganizationalUnitCategoriesMutation = this.injector.get(AddFileDynamicFieldOrganizationalUnitCategoriesDocument);
		this.removeFileDynamicFieldOrganizationalUnitCategoriesMutation = this.injector.get(RemoveFileDynamicFieldOrganizationalUnitCategoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganizationalUnitCategory> {
    
    		let variables: GetOrganizationalUnitCategoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.get));
            }
            else{
                let result:ServiceSingleResultOfOrganizationalUnitCategory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganizationalUnitCategory> {
    
    		let variables: FirstOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: FindOrganizationalUnitCategoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: SearchOrganizationalUnitCategoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOrganizationalUnitCategoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: CustomQueryOrganizationalUnitCategoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: CustomQueryIdOrganizationalUnitCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOrganizationalUnitCategoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: FindRecyclesOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyOrganizationalUnitCategoriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrganizationalUnitCategory,
		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrganizationalUnitCategory> {
    
    		let variables: FindArchivedOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllOrganizationalUnitCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsOrganizationalUnitCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsOrganizationalUnitCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OrganizationalUnitCategoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganizationalUnitCategory> {
    
    		let variables: InsertOrganizationalUnitCategoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrganizationalUnitCategory> {
    
    		let variables: UpdateOrganizationalUnitCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOrganizationalUnitCategoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrganizationalUnitCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedOrganizationalUnitCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldOrganizationalUnitCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldOrganizationalUnitCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldOrganizationalUnitCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.organizationalUnitCategories.removeFileDynamicField));
    	}
    
}