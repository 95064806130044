<dx-popup
  [width]="width"
  [height]="height"
  [showTitle]="false"
  class="add-item-popup clc-massive-edit"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="hideOnOutsideClick"
  [deferRendering]="true"
  [(visible)]="isVisible"
>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.1)"
    [visible]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  ></dx-load-panel>
  <cl-work-page>
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title
          >{{ title }}
        </cl-work-page-header-content-title>
        <cl-work-page-header-content-body>
          <cl-toolbar>
            <cl-toolbar-items>
              <cl-toolbar-item-button
                (onClick)="save($event)"
                [type]="'default'"
                [text]="titleBtn"
                location="after"
                [disabled]="!(canSave$ | async)"
              >
              </cl-toolbar-item-button>

              <!-- <cl-toolbar-item-button
                (onClick)="close($event)"
                [text]="'close' | translate"
                location="after"
              > -->
              <!-- icon="fas fa-plus" -->
              <!-- </cl-toolbar-item-button> -->
            </cl-toolbar-items>
          </cl-toolbar>
        </cl-work-page-header-content-body>
      </cl-work-page-header-content>
    </cl-work-page-header>

    <cl-work-page-content>
      <div
        *ngIf="model != undefined"
        class="work-item-container-scroll"
        (mousewheel)="$event.stopPropagation()"
      >
        <div class="pop-up-hint">
          {{ model.model.form.layout.pages[0].hint }}
        </div>
        <div class="work-item-container">
          <clc-work-item-section-container *ngIf="model.form != null">
            <cl-work-item-section
              *ngFor="
                let section of model.model.form.layout.pages[0]['sections']
              "
              [formGroup]="
                model.form.get(model.model.form.layout.pages[0].name)
              "
              [location]="section.location"
            >
              <clc-work-item-section-group
                *ngFor="let group of section.groups"
                [title]="group.label"
                [formGroup]="
                  model.form
                    .get(model.model.form.layout.pages[0].name)
                    .get(group.name)
                "
                [visibled]="
                  model.form
                    .get(model.model.form.layout.pages[0].name)
                    .get(group.name).visibled
                "
              >
                <clc-work-item-control-dynamic
                  [state]="model"
                  [controls]="group.controls"
                  [mode]="'full'"
                  [autoFocus]="
                    model.model.form.layout.pages[0]['sections'][0] ===
                      section && section.groups[0] === group
                  "
                ></clc-work-item-control-dynamic>
              </clc-work-item-section-group>
            </cl-work-item-section>
          </clc-work-item-section-container>
        </div>
      </div>
    </cl-work-page-content>
  </cl-work-page>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="close()"></dx-button>
  </div>
</dx-popup>
