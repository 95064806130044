import {
  AutoCompleteBoxOptions,
  Control,
  DateTimeOptions,
  DropDownBoxOptions,
  HtmlEditorOptions,
  LinkOptions,
  MessageTicketOptions,
  NumberBoxOptions,
  SelectBoxOptions,
  SimpleListOptions,
  TagBoxOptions,
  TextAreaOptions,
  TextBoxOptions,
} from '@clarilog/shared2/models/schema';
import { ModelFnContext } from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

export class FormDefaultValues {
  public static setDefaultValues(control: Control) {
    let options;

    if (control.options == undefined) {
      control.options = {};
    }

    switch (control.type) {
      // case "UploadComponent":
      //   options = control.options as {};
      //   options.defaultValue = control.defaultValue || [];
      //   break;
      case 'TextBoxComponent':
        options = control.options as TextBoxOptions;
        options.mask = options.mask || '';
        options.maskChar = options.maskChar || '_';
        options.maskInvalidMessage =
          options.maskInvalidMessage || TranslateService.get('invalid-value');
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        options.maskRules = options.maskRules || {};
        options.showCopyButton = options.showCopyButton || false;
        break;
      case 'TextAreaComponent':
        options = control.options as TextAreaOptions;
        options.mask = options.mask || '';
        options.maskChar = options.maskChar || '_';
        options.maskInvalidMessage =
          options.maskInvalidMessage || TranslateService.get('invalid-value');
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        options.maskRules = options.maskRules || {};
        options.showCopyButton = options.showCopyButton || false;
        break;
      case 'NumberBoxComponent':
        options = control.options as NumberBoxOptions;
        options.defaultValue = control.defaultValue || 0;
        options.min = options?.min || 0;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'CalendarComponent':
        break;
      case 'SimpleListComponent':
        options = control.options as SimpleListOptions;
        options.showColumnHeaders =
          options.showColumnHeaders != undefined
            ? options.showColumnHeaders
            : false;
        break;
      case 'DateTimeComponent':
        options = control.options as DateTimeOptions;
        options.interval = options.interval || 30;
        options.type = options.type || 'date';
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'ColorBoxComponent':
        break;
      case 'CheckBoxComponent':
        break;
      case 'SwitchComponent':
        break;
      case 'HtmlEditorComponent':
        options = control.options as HtmlEditorOptions;
        break;
      case 'MessageTicketComponent':
        options = control.options as MessageTicketOptions;
        break;
      case 'SelectBoxComponent':
        options = control.options as SelectBoxOptions;
        options.searchMode = options.searchMode || 'contains';
        options.source = options.source;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'AutoCompleteBoxComponent':
        options = control.options as AutoCompleteBoxOptions;
        options.searchMode = options.searchMode || 'contains';
        options.source = options.source;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'TagBoxComponent':
        options = control.options as TagBoxOptions;
        options.searchMode = options.searchMode || 'contains';
        options.source = options.source;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'TagBoxCustomItemComponent':
        options = control.options as TagBoxOptions;
        options.searchMode = options.searchMode || 'contains';
        options.source = options.source;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'DropDownBoxComponent':
        options = control.options as DropDownBoxOptions;
        options.source = options.source;
        options.multiple = options.multiple || false;
        options.mode = options.mode || 'Grid';
        options.columns = options.columns || undefined;
        options.showClearButton =
          options.showClearButton != undefined ? options.showClearButton : true;
        break;
      case 'RadioButtonComponent':
        options = control.options as any;
        options.layout = options.layout || 'vertical';
        break;
      case 'LinkComponent':
        options = control.options as LinkOptions;
        options.source = options.source;
        options.multiple = options.multiple || false;
        options.mode = options.mode || 'Grid';
        options.columns = options.columns || undefined;
      // default:
      //   throw new Error(
      //     `Le control de type '${control.type}' n'est pas pris en charge.`
      //   );
    }
  }
}
