import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetTypeAccountingsBaseVariables, FirstAssetTypeAccountingsBaseVariables, CountAssetTypeAccountingsBaseVariables, FindAssetTypeAccountingsBaseVariables, SearchAssetTypeAccountingsBaseVariables, ExportSchemaAssetTypeAccountingsBaseVariables, ExportDataAssetTypeAccountingsBaseVariables, CustomQueryAssetTypeAccountingsBaseVariables, CustomQueryIdAssetTypeAccountingsBaseVariables, UsedAssetTypeAccountingsBaseVariables, ExistAssetTypeAccountingsBaseVariables, FindUnassociatedAssetsAssetTypeAccountingsBaseVariables, InsertAssetTypeAccountingsBaseVariables, UpdateAssetTypeAccountingsBaseVariables, ImportDataAssetTypeAccountingsBaseVariables, UpdateManyAssetTypeAccountingsBaseVariables, DeleteAssetTypeAccountingsBaseVariables, AddAssetsAssetTypeAccountingsBaseVariables, RemoveAssetsAssetTypeAccountingsBaseVariables } from '../gqls'
import { GetAssetTypeAccountingsDocument, FirstAssetTypeAccountingsDocument, CountAssetTypeAccountingsDocument, FindAssetTypeAccountingsDocument, SearchAssetTypeAccountingsDocument, ExportSchemaAssetTypeAccountingsDocument, ExportDataAssetTypeAccountingsDocument, CustomQueryAssetTypeAccountingsDocument, CustomQueryIdAssetTypeAccountingsDocument, UsedAssetTypeAccountingsDocument, ExistAssetTypeAccountingsDocument, FindUnassociatedAssetsAssetTypeAccountingsDocument, InsertAssetTypeAccountingsDocument, UpdateAssetTypeAccountingsDocument, ImportDataAssetTypeAccountingsDocument, UpdateManyAssetTypeAccountingsDocument, DeleteAssetTypeAccountingsDocument, AddAssetsAssetTypeAccountingsDocument, RemoveAssetsAssetTypeAccountingsDocument } from '../gqls'
import { ServiceSingleResultOfAssetTypeAccounting, QueryContextOfAssetTypeAccounting, FilterOfAssetTypeAccounting, ServiceSingleResultOfInt64, ServiceListResultOfAssetTypeAccounting, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAsset, FilterOfAsset, ServiceListResultOfAsset, AssetTypeAccountingInput, FieldUpdateOperatorOfAssetTypeAccounting } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetTypeAccountingBaseService {
    
public modelName = 'assetTypeAccounting';
public modelPluralName = 'assetTypeAccountings';

	private getAssetTypeAccountingsQuery: GetAssetTypeAccountingsDocument;
	private firstAssetTypeAccountingsQuery: FirstAssetTypeAccountingsDocument;
	private countAssetTypeAccountingsQuery: CountAssetTypeAccountingsDocument;
	private findAssetTypeAccountingsQuery: FindAssetTypeAccountingsDocument;
	private searchAssetTypeAccountingsQuery: SearchAssetTypeAccountingsDocument;
	private exportSchemaAssetTypeAccountingsQuery: ExportSchemaAssetTypeAccountingsDocument;
	private exportDataAssetTypeAccountingsQuery: ExportDataAssetTypeAccountingsDocument;
	private customQueryAssetTypeAccountingsQuery: CustomQueryAssetTypeAccountingsDocument;
	private customQueryIdAssetTypeAccountingsQuery: CustomQueryIdAssetTypeAccountingsDocument;
	private usedAssetTypeAccountingsQuery: UsedAssetTypeAccountingsDocument;
	private existAssetTypeAccountingsQuery: ExistAssetTypeAccountingsDocument;
	private findUnassociatedAssetsAssetTypeAccountingsQuery: FindUnassociatedAssetsAssetTypeAccountingsDocument;
	private insertAssetTypeAccountingsMutation: InsertAssetTypeAccountingsDocument;
	private updateAssetTypeAccountingsMutation: UpdateAssetTypeAccountingsDocument;
	private importDataAssetTypeAccountingsMutation: ImportDataAssetTypeAccountingsDocument;
	private updateManyAssetTypeAccountingsMutation: UpdateManyAssetTypeAccountingsDocument;
	private deleteAssetTypeAccountingsMutation: DeleteAssetTypeAccountingsDocument;
	private addAssetsAssetTypeAccountingsMutation: AddAssetsAssetTypeAccountingsDocument;
	private removeAssetsAssetTypeAccountingsMutation: RemoveAssetsAssetTypeAccountingsDocument;

	constructor(private injector: Injector) {
		this.getAssetTypeAccountingsQuery = this.injector.get(GetAssetTypeAccountingsDocument);
		this.firstAssetTypeAccountingsQuery = this.injector.get(FirstAssetTypeAccountingsDocument);
		this.countAssetTypeAccountingsQuery = this.injector.get(CountAssetTypeAccountingsDocument);
		this.findAssetTypeAccountingsQuery = this.injector.get(FindAssetTypeAccountingsDocument);
		this.searchAssetTypeAccountingsQuery = this.injector.get(SearchAssetTypeAccountingsDocument);
		this.exportSchemaAssetTypeAccountingsQuery = this.injector.get(ExportSchemaAssetTypeAccountingsDocument);
		this.exportDataAssetTypeAccountingsQuery = this.injector.get(ExportDataAssetTypeAccountingsDocument);
		this.customQueryAssetTypeAccountingsQuery = this.injector.get(CustomQueryAssetTypeAccountingsDocument);
		this.customQueryIdAssetTypeAccountingsQuery = this.injector.get(CustomQueryIdAssetTypeAccountingsDocument);
		this.usedAssetTypeAccountingsQuery = this.injector.get(UsedAssetTypeAccountingsDocument);
		this.existAssetTypeAccountingsQuery = this.injector.get(ExistAssetTypeAccountingsDocument);
		this.findUnassociatedAssetsAssetTypeAccountingsQuery = this.injector.get(FindUnassociatedAssetsAssetTypeAccountingsDocument);
		this.insertAssetTypeAccountingsMutation = this.injector.get(InsertAssetTypeAccountingsDocument);
		this.updateAssetTypeAccountingsMutation = this.injector.get(UpdateAssetTypeAccountingsDocument);
		this.importDataAssetTypeAccountingsMutation = this.injector.get(ImportDataAssetTypeAccountingsDocument);
		this.updateManyAssetTypeAccountingsMutation = this.injector.get(UpdateManyAssetTypeAccountingsDocument);
		this.deleteAssetTypeAccountingsMutation = this.injector.get(DeleteAssetTypeAccountingsDocument);
		this.addAssetsAssetTypeAccountingsMutation = this.injector.get(AddAssetsAssetTypeAccountingsDocument);
		this.removeAssetsAssetTypeAccountingsMutation = this.injector.get(RemoveAssetsAssetTypeAccountingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeAccounting> {
    
    		let variables: GetAssetTypeAccountingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.get));
            }
            else{
                let result:ServiceSingleResultOfAssetTypeAccounting={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetTypeAccounting,
		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeAccounting> {
    
    		let variables: FirstAssetTypeAccountingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetTypeAccountingsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetTypeAccounting,
		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeAccounting> {
    
    		let variables: FindAssetTypeAccountingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetTypeAccounting,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeAccounting> {
    
    		let variables: SearchAssetTypeAccountingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetTypeAccountingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetTypeAccountingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetTypeAccounting,
		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeAccounting> {
    
    		let variables: CustomQueryAssetTypeAccountingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetTypeAccounting,
		@Args('filter?') filter?: FilterOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetTypeAccounting> {
    
    		let variables: CustomQueryIdAssetTypeAccountingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetTypeAccountingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetTypeAccountingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.exist));
    	}

	@InjectArgs
	public findAssociatedAssets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAsset> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('assets', fields, null, [
			GqlSubFieldArg.create('filterOfAssets', 'filter'),
			GqlSubFieldArg.create('optionsOfAssets', 'options'),
		]),
		])
		extendedVariables['filterOfAssets'] = filter;
		extendedVariables['optionsOfAssets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.assets));
            }
            else{
                let result: ServiceListResultOfAsset = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAsset,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAsset> {
    
    		let variables: FindUnassociatedAssetsAssetTypeAccountingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAssetsAssetTypeAccountingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.findUnassociatedAssets));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetTypeAccountingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeAccounting> {
    
    		let variables: InsertAssetTypeAccountingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetTypeAccounting> {
    
    		let variables: UpdateAssetTypeAccountingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetTypeAccountingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetTypeAccounting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetTypeAccountingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetTypeAccountingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAssetsAssetTypeAccountingsBaseVariables = {
    			id: id,
			assetIds: assetIds
    		}
    				return this.addAssetsAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.addAssets));
    	}

    	/**  */
    	@InjectArgs
    	public removeAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetIds') assetIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAssetsAssetTypeAccountingsBaseVariables = {
    			assetIds: assetIds
    		}
    				return this.removeAssetsAssetTypeAccountingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetTypeAccountings.removeAssets));
    	}
    
}