import { TranslateService } from '@clarilog/shared';

export type QuerySort = {
  selector: string;
  desc: boolean;
};

export type DxFilter = Array<string | number | DxFilter>;

/**
 * Permet de convertir le filtre DeveXtreme en Filtre compatible avec les services GraphQL.
 */
export class CriteriaHelpers {
  // permet de convertir le sort devextreme en sort clarilog
  public static convertSortQuerySortType(
    sorts: QuerySort[],
    result: any[] = [],
  ) {
    sorts.forEach((sort) => {
      let convertedSort = {};

      sort.selector = sort.selector.split('.data').join('');

      if (sort.selector.includes('.')) {
        // name.toto.tutu want {name: [{toto: [{tutu: DESC}]}]}
        let scopeResult: any = result;
        let lastField = 'NOPE';
        sort.selector.split('.').forEach((el) => {
          // name
          let existEl = scopeResult.find((f) => Object.keys(f)[0] === el); //if exist //name if you have find not function check the sort if for exemeple you dont have name and name.toto

          if (existEl === undefined) {
            // not exist
            existEl = {};
            existEl[el] = []; // {name: []};
            scopeResult.push(existEl);
          }
          scopeResult = existEl[el];
          lastField = existEl;
        });
        lastField[Object.keys(lastField)[0]] =
          sort.desc === true ? 'DESC' : 'ASC';
      } else {
        convertedSort[sort.selector] = sort.desc === true ? 'DESC' : 'ASC'; // name: DESC
        result.push(convertedSort);
      }
    });
    return result;
  }
  /**
   *         let filterScope = filters
        data.forEach((el, index) => {
          if (index >= 1) {
            filterScope['or'] = {}
            filterScope = filterScope.or;
          }
          filterScope[this.filterExpr] = { eq: (this.filterOperation == undefined ? '=' : this.filterOperation, el[this.keyExpr]) }
        })
   */

  public static orderTicketStatusHelper(options: any): string {
    let translateRequest = TranslateService.get(
      'entities/request/_title/singular',
    );
    let translateIncident = TranslateService.get(
      'entities/incident/_title/singular',
    );

    let arrayToOrderTicketStatus = [
      { id: 'request', value: translateIncident },
      { id: 'incident', value: translateRequest },
    ];

    let sortOrder = null;
    if (options != null) {
      options.map((element) => {
        if (element['ticket'] != null) {
          element['ticket'].map((argu) => {
            if (argu['__typename'] != null && argu['__typename'] != undefined)
              sortOrder = argu['__typename'];

            let testeurs = CriteriaHelpers.sortTicketStatus(
              arrayToOrderTicketStatus,
              sortOrder,
            );

            if (testeurs[0].id.toLocaleLowerCase() !== 'request') {
              sortOrder = 'ASC';
            } else {
              sortOrder = 'DESC';
            }
          });
        }
      });
    }
    return sortOrder;
  }

  private static sortTicketStatus(
    dataArray: {
      id: string;
      value: string;
    }[],
    mode: string = 'ASC',
  ): {
    id: string;
    value: string;
  }[] {
    if (mode === 'ASC') {
      return dataArray.sort((a, b) => a.value.localeCompare(b.value));
    }
    return dataArray.sort((a, b) => b.value.localeCompare(a.value));
  }

  public static createSortOptionForTicket(other: string): any[] {
    let bool: boolean = false;
    if (other.toUpperCase() === 'ASC') bool = true;
    let option = CriteriaHelpers.convertSortQuerySortType([
      { selector: '__typename', desc: bool },
    ]);

    return [{ ticket: option }];
  }

  private static getFilterValue(operator, value) {
    if (Array.isArray(value)) {
      switch (operator) {
        case '=':
          return { eq: value };
        case '!':
        case '<>':
          return { ne: value };
        case 'contains':
          return { elemMatch: { in: value } };
        case 'notcontains':
          return { elemMatch: { nin: value } };
        default:
          throw new Error('CriteriaHelpers - getFilterValue');
      }
    }
    switch (operator) {
      case '=':
        return { eq: value };
      case '!':
      case '<>':
        return { ne: value };
      case '>':
        return { gt: value };
      case '<':
        return { lt: value };
      case '<=':
        return { lte: value };
      case '>=':
        return { gte: value };
      case 'contains':
        return { contains: value };
      case 'notcontains':
        return { notcontains: value };
      case 'endswith':
        return { endsWith: value };
      case 'startswith':
        return { startsWith: value };
      default:
        throw new Error('CriteriaHelpers - getFilterValue');
    }
  }

  private static addFilter(fieldName, operator, value, filter) {
    fieldName = fieldName.replace('displayOperatorFilter', 'name');
    fieldName = fieldName.split('.data').join(''); // contractCategory.data.name = contractCategory.name

    if (fieldName.includes('.')) {
      // ["name.toto", '=', 'toto']
      let scopeResult: any = filter;
      let lastField = {};
      fieldName.split('.').forEach((el) => {
        // name
        if (el != 'id') {
          scopeResult[el] = {};

          lastField = scopeResult;
          scopeResult = scopeResult[el];
        }
      });

      lastField[Object.keys(lastField)[0]] = CriteriaHelpers.getFilterValue(
        operator,
        value,
      );
    } else {
      filter[fieldName] = CriteriaHelpers.getFilterValue(operator, value);
      // result.push(convertedSort);
    }
  }

  private static addCompOperationFilter(
    compOperator: string,
    value: any,
    filter: any,
  ) {
    if (filter[compOperator] == undefined) {
      filter[compOperator] = [];
    }
    filter[compOperator].push(value);
    // Object.assign(filter, CriteriaHelpers.getFilterValue(compOperator, value))
  }

  /**
   * Permet de gérer le filtre "N'est pas parmis"
   * @param filters filters
   * @param filter filter
   */
  private static processFilter(filters: any, filter: any) {
    let expression = filters[1][0];
    let operator = filters[0];

    if (operator === '!') {
      expression[1] = operator.replace('=', operator);
      let compOperator1 = filters[1][1];
      let convertedExpression: any = {};

      CriteriaHelpers.convertDxFilter(expression, convertedExpression);
      CriteriaHelpers.addCompOperationFilter(
        compOperator1,
        convertedExpression,
        filter,
      );

      for (let index = 1; index < filters[1].length; index += 2) {
        let andOperator = 'and';
        let expr = filters[1][index + 1] as DxFilter;
        expr[1] = expr[1].toString().replace('=', operator);
        let convExpr: any = {};

        CriteriaHelpers.convertDxFilter(expr, convExpr);
        CriteriaHelpers.addCompOperationFilter(andOperator, convExpr, filter);
      }
    }
  }

  public static convertDxFilter(filters: DxFilter, filter: any = {}) {
    if (filters == undefined || filters.length === 0) {
      return null;
    }
    // Bad case in our core ex link list on added elelm denerate this case
    if (filters.length === 1) {
      return this.convertDxFilter(filters[0] as any, filter);
    }

    if (filters.length === 3 && typeof filters[0] === 'string') {
      // binary filter
      let fieldName = filters[0];
      let operator = filters[1];
      let value = filters[2];

      this.addFilter(fieldName, operator, value, filter);
    } else if (
      filters.length === 2 &&
      typeof filters[0] === 'string' &&
      typeof filters[1] === 'object'
    ) {
      if (
        filters[1].length >= 3 &&
        typeof filters[1][0] === 'object' &&
        typeof filters[1][1] === 'string'
      ) {
        this.processFilter(filters, filter);
      } else {
        let fieldName = filters[1][0];
        let operator = filters[0];
        let value = filters[1][2];

        this.addFilter(fieldName, operator, value, filter);
      }
    } else if (
      filters.length >= 3 &&
      typeof filters[0] === 'object' &&
      typeof filters[1] === 'string'
    ) {
      let expr1 = filters[0];
      let compOperator1 = filters[1] as string;
      let convExpr1: any = {};

      CriteriaHelpers.convertDxFilter(expr1, convExpr1);
      CriteriaHelpers.addCompOperationFilter(compOperator1, convExpr1, filter);

      for (let index = 1; index < filters.length; index += 2) {
        let compOperator = filters[index] as string;
        let expr = filters[index + 1] as DxFilter;
        let convExpr: any = {};

        CriteriaHelpers.convertDxFilter(expr, convExpr);
        CriteriaHelpers.addCompOperationFilter(compOperator, convExpr, filter);
      }
    } else if (filters.length > 4) {
      if (
        typeof filters[0] === 'string' &&
        typeof filters[1] === 'string' &&
        typeof filters[2] === 'string'
      ) {
        let fieldName = filters[0];
        let operator = filters[1];
        let value = filters[2];

        this.addFilter(fieldName, operator, value, filter);

        if (typeof filters[3] === 'string' && typeof filters[4] === 'object') {
          let expr1 = filters[4];
          let compOperator1 = filters[3] as string;
          let convExpr1: any = {};

          CriteriaHelpers.convertDxFilter(expr1, convExpr1);
          CriteriaHelpers.addCompOperationFilter(
            compOperator1,
            convExpr1,
            filter,
          );
        }
      }
    } else if (
      filters.length >= 2 &&
      filters.every((x) => typeof x === 'object')
    ) {
      let compOperator = 'and';

      for (let index = 0; index < filters.length; index++) {
        const exprFilter = filters[index];
        let convExpr: any = {};

        CriteriaHelpers.convertDxFilter(exprFilter as DxFilter, convExpr);
        CriteriaHelpers.addCompOperationFilter(compOperator, convExpr, filter);
      }
    } else {
      throw new Error('convertDxFilter');
    }

    return filter;
  }

  /**
   * Permet de compiler les critères issus de DevExtreme.
   * @param criteria Les critères de recherche.
   */
  public static compile(criteria, fields: any[] = null) {
    let toto =
      criteria[0] instanceof Array || criteria[0] === '!'
        ? CriteriaHelpers.compileGroup(criteria, fields)
        : [CriteriaHelpers.compileBinary(criteria, fields)];
    return toto;
  }
  /**
   * Permet de compiler les groupes de critères issus de DevExtreme.
   * @param criterias Les critères de recherche.
   */
  private static compileGroup(criterias, fields: any[] = null) {
    let groups = [],
      lastGroup = undefined;

    for (let i = 0, l = criterias.length; i < l; i++) {
      let criteria = <any>criterias[i];
      if (
        criteria[0] instanceof Array &&
        // Si il n'y a pas plusieurs colonnes ['col1','col2',...]
        !(criteria.length == 3 && !(criteria[2] instanceof Array))
      ) {
        if (lastGroup === undefined) {
          groups = CriteriaHelpers.compileGroup(criteria, fields);
        } else {
          lastGroup.groups = CriteriaHelpers.compileGroup(criteria, fields);
        }
      } else {
        if (criteria.length > 1 && criteria[0] === '!') {
          if (lastGroup === undefined) {
            groups = CriteriaHelpers.compileGroup(criteria, fields);
          } else {
            lastGroup.groups = CriteriaHelpers.compileGroup(criteria, fields);
          }
        } else {
          lastGroup = CriteriaHelpers.compileBinary(criteria, fields);
          if (Array.isArray(lastGroup)) {
            groups = groups.concat(lastGroup);
          } else {
            groups.push(lastGroup);
          }
        }
      }
    }
    return groups;
  }

  private static resolveFieldName(
    fieldName: string,
    compileOrNot: boolean,
    fields: any[],
  ) {
    if (fields === null || fields.length === 0) {
      return fieldName;
    }
    let resolv = fields.find(
      (x) => x.keyName === fieldName || x.dataField === fieldName,
    );

    if (compileOrNot === true) {
      return resolv.keyName ? resolv.keyName : resolv.dataField;
    }
    return resolv.dataField;
  }

  /**
   * Permet de compiler les critères issus de DevExtreme.
   * @param criterias Les critères de recherche.
   */
  private static compileBinary(criteria, fields: any[] = null) {
    if (criteria instanceof Array) {
      if (Array.isArray(criteria[0])) {
        let results = [];
        for (let column of criteria[0]) {
          let fieldName = this.resolveFieldName(column, true, fields);
          results.push({
            name: fieldName,
            operation: criteria[1],
            value: criteria[2],
            keyName: criteria[3],
          });
          results.push({ groupOperation: 'or' });
        }
        results.pop();
        return results;
      } else {
        let fieldName = this.resolveFieldName(criteria[0], true, fields);

        return {
          name: fieldName,
          operation: criteria[1],
          value: criteria[2],
          keyName: criteria[3],
        };
      }
    } else {
      return { groupOperation: criteria };
    }
  }

  public static decompile(
    data: Array<any>,
    fields: any[] = null,
    createArray: boolean = false,
  ) {
    let result: Array<any> = [];
    for (let entry of data) {
      if (entry.groupOperation == undefined) {
        //may cause instability
        if (entry.name == undefined) return data;
        entry.name = this.resolveFieldName(entry.name, false, fields);

        if (entry.operation === 'customValueChanged') {
          result.push([entry.name, entry.operation]);
        } else {
          result.push([entry.name, entry.operation, entry.value]);
        }
      } else {
        let entryGroupOperation = entry.groupOperation;

        if (entry.groups != undefined) {
          if (
            result.length > 0 &&
            result[0][0] instanceof Array == false &&
            createArray == false
          ) {
            result = [result];
          }

          let decompileGroups = this.decompile(entry.groups, fields, true);
          if (entry.groupOperation === '!' && decompileGroups?.length > 0) {
            if (typeof decompileGroups[1] == 'string') {
              entryGroupOperation =
                decompileGroups[1] === 'or' ? 'notOr' : 'notAnd';
            } else {
              console.error('error with notAnd/notOr');
            }
          }
          result.push(entryGroupOperation);
          result.push(decompileGroups);
        } else {
          if (
            result.length > 0 &&
            result[0][0] instanceof Array == false &&
            result[1] != undefined &&
            createArray == false
          ) {
            result = [result];
          }

          if (entry.groupOperation === '!' && result.length > 0) {
            if (typeof result[0][1] == 'string') {
              entryGroupOperation = result[0][1] === 'or' ? 'notOr' : 'notAnd';
            } else {
              console.error('groupOperation issue');
            }
          }
          result.push(entryGroupOperation);
        }
      }
    }
    return result;
  }
}
