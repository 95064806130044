import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Observable, of } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { TicketStatusReasonBaseService } from '../service-bases';
import {
  FilterOfTicketStatusReason,
  QueryContextOfTicketStatusReason,
  ServiceListResultOfTicketStatusReason,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-ticket-status-reason')
export class TicketStatusReasonCoreService extends TicketStatusReasonBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findTicketStatus(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketStatusReason,
    @Args('statusId?') statusId?: string,
    @Args('statusReasonId?') statusReasonId?: string,
    @Args('filter?') filter?: FilterOfTicketStatusReason,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketStatusReason> {
    let filters: FilterOfTicketStatusReason = { statusId: { eq: statusId } };

    if (filter == null) {
      filter = filters;
    } else {
      filter.or = [filters];
    }
    if (statusId == null) {
      return of({ data: [], totalCount: 0 });
    }

    return this.find(fields, options, filter, extendedVariables);
  }

  @InjectArgs
  public findReasonStatus(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketStatusReason,
    @Args('statusId?') statusId?: string,
    @Args('filter?') filter?: FilterOfTicketStatusReason,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketStatusReason> {
    let createFilter: FilterOfTicketStatusReason = {};
    if (statusId != null) {
      createFilter = { statusId: { eq: statusId } };
    }
    if (filter != null) {
      createFilter.and = [filter];
    }
    return this.find(fields, options, createFilter, extendedVariables);
  }
}
