import { Component, OnInit } from '@angular/core';

/** Représente le contenu du composent WorkPage. */
@Component({
  selector: 'cl-work-page-header-content-body',
  templateUrl: './work-page-header-content-body.component.html',
  styleUrls: ['./work-page-header-content-body.component.scss']
})
export class WorkPageHeaderContentBodyComponent {
  constructor() { }
}
