import { Component, Injectable } from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { dxButtonOptions } from 'devextreme/ui/button';
import { custom } from 'devextreme/ui/dialog';
import { combineLatest, fromEvent, Observable, of, Subscription } from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  finalize,
  map,
  shareReplay,
  startWith,
  switchMap,
  take,
} from 'rxjs/operators';
import { CoreWorkItemFormComponent } from '.';
import { TranslateService } from '../../../services/translate/translate.service';
import { FormGroupHelpers } from '../work-form/form-group-helpers';
import { FormAnyLinqArray } from '../work-form';

var cleanTypeName = function (data: any): any {
  if (data == undefined) {
    return;
  }

  if (data['__typename'] != undefined) {
    delete data['__typename'];
  }

  if (Array.isArray(data)) {
    (data as Array<any>).forEach((el) => {
      if (typeof el == 'object') {
        el = cleanTypeName(el);
      }
    });
  } else {
    let keys = Object.keys(data);

    keys.forEach((key) => {
      if (typeof data[key] == 'object') {
        data[key] = cleanTypeName(data[key]);
      }
    });
  }
  return data;
};

/** Permet de retrouver la différence entre 2 objets. */
var isEqual = function (value, other, convertToPlainText) {
  // Get the value type
  var type = Object.prototype.toString.call(value);

  // If the two objects are not the same type, return false
  if (type !== Object.prototype.toString.call(other)) {
    var othertype = Object.prototype.toString.call(other);
    if (othertype == '[object Date]' && value != undefined) {
      value = new Date(value);
      return value.getTime() == other.getTime();
    } else {
      return false;
    }
  }
  // If items are not an object or array, return false
  if (['[object Array]', '[object Object]'].indexOf(type) < 0) return false;

  // Compare the length of the length of the two items
  if (type !== '[object Array]') {
    if (value != undefined && value['__typename'] != undefined) {
      delete value['__typename'];
    }
    if (other != undefined && other['__typename'] != undefined) {
      delete other['__typename'];
    }
  }

  // Ne comptabilise pas une valeur de key == undefined
  var countKey = function (item1) {
    let total = 0;
    for (var key in item1) {
      if (value[key] != undefined) {
        total++;
      }
    }

    return total;
  };

  var valueLen = type === '[object Array]' ? value.length : countKey(value);
  var otherLen = type === '[object Array]' ? other.length : countKey(other);

  if (valueLen !== otherLen) return false;

  // Compare two items
  var compare = function (item1, item2) {
    // Get the object type
    var itemType = Object.prototype.toString.call(item1);

    // If an object or array, compare recursively
    if (['[object Array]', '[object Object]'].indexOf(itemType) >= 0) {
      if (!isEqual(item1, item2, convertToPlainText)) return false;
    }

    // Otherwise, do a simple comparison
    else {
      // If the two items are not the same type, return false
      if (itemType !== Object.prototype.toString.call(item2)) return false;

      // Else if it's a function, convert to a string and compare
      // Otherwise, just compare
      if (itemType === '[object Function]') {
        if (item1.toString() !== item2.toString()) return false;
      } else {
        if (item1 !== item2) return false;
      }
    }
  };

  // Compare properties
  if (type === '[object Array]') {
    // Json comparaison
    try {
      let valueClean = cleanTypeName(value);
      let otherClean = cleanTypeName(other);

      let jsonValue = JSON.stringify(valueClean);
      let jsonOther = JSON.stringify(otherClean);
      if (jsonOther == jsonValue) {
        return true;
      }
    } catch (e) {}

    for (var i = 0; i < valueLen; i++) {
      // Recherche de l'élément
      let indexOther = other.indexOf(value[i]);
      if (indexOther === -1) return false; //compare(value[i], other[i]) === false) return false;
    }
    for (var i = 0; i < otherLen; i++) {
      // Recherche de l'élément
      let indexValue = value.indexOf(other[i]);
      if (indexValue === -1) return false; //compare(value[i], other[i]) === false) return false;
    }
  } else {
    for (var key in value) {
      if (value.hasOwnProperty(key)) {
        let v1 = value[key];
        let v2 = other[key];
        if (convertToPlainText) {
          v1 = ModelFieldCompilerService.convertInnerHtml(v1);
          v2 = ModelFieldCompilerService.convertInnerHtml(v2);
        }
        if (compare(v1, v2) === false) return false;
      }
    }
  }

  // If nothing failed, return true
  return true;
};

/** Permet de vérifier l'état de modification des champs du fomulaire. */
export function dirtyCheck<U>(
  formGroup: UntypedFormGroup,
  source: Observable<U>,
  unload: boolean = true,
) {
  let subscription: Subscription;
  let isDirty = false;
  let controls = FormGroupHelpers.formControls(formGroup);

  return function <T>(valueChanges: Observable<T>): Observable<boolean> {
    if (subscription != undefined) {
      subscription.unsubscribe();
    }
    const isDirty$ = combineLatest(source, valueChanges).pipe(
      debounceTime(300),
      distinctUntilChanged(),

      map((_) => {
        isDirty = false;
        for (let control of controls.filter(
          (c) => c.value.visibled === true && c.value.enabled && c.value.dirty,
        )) {
          if (control.value.dirty === true) {
            if (Array.isArray(control.value.value)) {
              if (
                (control.value as FormAnyLinqArray)?.isFormAnyLinqArray === true
              ) {
                if (control.value?.value != undefined) {
                  isDirty = true;
                }
                break;
              } else {
                if (
                  !isEqual(
                    control.value.originalValue,
                    control.value.value,
                    false,
                  )
                ) {
                  isDirty = true;
                  break;
                }
              }
            } else if (typeof control.value.value == 'string') {
              if (
                control.value.value != undefined &&
                control.value.value.localeCompare(
                  control.value.originalValue,
                ) !== 0
              ) {
                isDirty = true;
                break;
              } else if (
                control.value.originalValue != undefined &&
                control.value.originalValue.localeCompare(
                  control.value.value,
                ) !== 0
              ) {
                isDirty = true;
                break;
              }
            } else if (
              control.value.value !== null &&
              typeof control.value.value === 'object'
            ) {
              let convertToPlainText = false;
              if (
                control?.value?.componentType != undefined &&
                control.value.componentType.includes('HtmlEditor')
              ) {
                convertToPlainText = true;
              }
              if (
                !isEqual(
                  control.value.originalValue,
                  control.value.value,
                  convertToPlainText,
                )
              ) {
                isDirty = true;
                break;
              }
            } else if (
              control.value.value === null &&
              control.value.originalValue == undefined
            ) {
              //ne valide pas la condition
            } else if (control.value.value !== control.value.originalValue) {
              isDirty = true;
              break;
            }
          }
        }
        return isDirty;
      }),
      finalize(() => {
        if (subscription != undefined) subscription.unsubscribe();
      }),
      startWith(false),
      shareReplay({ bufferSize: 1, refCount: true }),
    );
    if (unload === true) {
      subscription = fromEvent(window, 'beforeunload').subscribe((event) => {
        isDirty && (event.returnValue = false);
      });
    }
    return isDirty$;
  };
}
/** Représente un service permettant de récupérer le formulaire. */
@Injectable({ providedIn: 'root' })
export class DirtyFormService {
  constructor() {}
  private _form: CoreWorkItemFormComponent;
  /** Permet de définir le formulaire. */
  setForm(form: CoreWorkItemFormComponent) {
    this._form = form;
  }
  /** Obtient le formulaire. */
  get form(): CoreWorkItemFormComponent {
    return this._form;
  }
}

/** Représente un guard permettant d'avertir l'utilisateur si le formulaire a été modifié sans être sauvegardé. */
@Injectable({ providedIn: 'root' })
export class DirtyCheckGuard {
  subscription: Subscription;
  constructor(private _dirtyFormService: DirtyFormService) {}

  async confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    discardButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let canSave = this._dirtyFormService.form.canUseSaveShortcut;
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: async (e) => {
          this._dirtyFormService.form.submit();

          this._dirtyFormService.form.state.canBeSave = true;

          let attempt = 0;
          while (
            this._dirtyFormService?.form?.state?.canBeSave === true &&
            attempt < 30
          ) {
            // MAx 3s d'attente (30essai * 100ms)
            attempt++;
            await new Promise((r) => setTimeout(r, 100));
          }

          return true;
        },
      },
      {
        text: discardButtonMessage,
        onClick: (e) => {
          return true;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
    ];
    if (canSave === false) {
      buttons.splice(0, 1);
    }
    return await custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  /** @inheritdoc */
  canDeactivate(
    component: Component,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    if (this._dirtyFormService.form == undefined) {
      return of(true);
    }
    return Observable.create((observer) => {
      if (component != undefined) {
        if (component['readOnly'] === true) {
          observer.next(true);
          observer.complete();
          return;
        }

        if (this._dirtyFormService.form.isDirty$ != undefined) {
          if (this.subscription != undefined) {
            this.subscription.unsubscribe();
          }

          this.subscription = this._dirtyFormService.form.isDirty$
            .pipe(
              debounceTime(300),
              distinctUntilChanged(),
              // map(d => d),
              shareReplay({ bufferSize: 1, refCount: true }),
              switchMap((d) => of(d)),
              take(1),
            )
            .subscribe(async (dirty) => {
              if (
                dirty === false ||
                this._dirtyFormService.form.inClosed === true
              ) {
                //return of(true);
                observer.next(true);
              } else {
                // let result = await confirm(
                //   TranslateService.get("globals/confirm-refresh"),
                //   TranslateService.get("confirm-title")
                // );
                let result = await this.confirmSaveDialog(
                  TranslateService.get('confirm-title'),
                  TranslateService.get('globals/confirm-refresh'),
                  TranslateService.get('save'),
                  TranslateService.get('notSave'),
                  TranslateService.get('cancel'),
                );

                if (result === true) {
                  this.subscription?.unsubscribe();
                }
                observer.next(result);
              }
              observer.complete();
            });
        } else {
          observer.next(true);
          observer.complete();
        }
      } else {
        observer.next(true);
        observer.complete();
      }
    });
  }
}
