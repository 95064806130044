<div style="width: 100%; height: 100%; display: flex; flex-direction: row">
  <div
    style="height: 100%; flex-basis: 75%; display: flex; flex-direction: column"
    [ngClass]="ngClassFlex100"
  >
    <div class="title" [ngStyle]="{ color: myTheme?.helpMe?.primaryColor }">
      <div class="test">
        <img
          src="{{ img64 }}"
          alt=""
          class="logo"
          *ngIf="img64 != null && img64 != undefined"
        />
      </div>
      <div class="titleDiv">
        {{ littleText }}<br />
        <div class="organisationTitle">
          {{ boldTexte }}
        </div>
      </div>
    </div>

    <div
      class="contentServiceCatalog"
      (click)="serviceCatalogClick($event)"
      *clcHasPolicies="['help-desk-user.service-catalog']"
    >
      <div
        style="
          width: 100%;
          background-color: #f5f5f5;
          padding: 10px;
          border-radius: 0.2em;
        "
      >
        <div
          class="backgroundServiceCatalog"
          style="
            width: 100%;
            border-radius: 0.2em;
            height: 60px;
            color: #fff;
            display: flex;
            align-items: center;
            justify-content: space-between;
          "
        >
          <div class="labelServiceCatalog">
            <span class="fa-regular fa-list-dropdown"></span
            ><span class="labelServiceCatalog">{{
              'entities/helpme/serviceCatalog' | translate
            }}</span>
          </div>
          <div class="addServiceCatalog">
            <span [ngClass]="classOpenServiceCatalog"></span>
          </div>
        </div>
      </div>
    </div>
    <div class="contentCenter">
      <div class="containerServiceCatalog" *ngIf="visibleServiceCatalog">
        <cl-service-catalog></cl-service-catalog>
      </div>

      <div
        [ngClass]="ngclassHiddden"
        class="flex-item flex-content-item"
        *clcHasPolicies="['incident-help-desk-user']"
      >
        <div class="containerInfo">
          <dx-box direction="row" width="100%" height="100%">
            <dxi-item [ratio]="1" class="item backgroundIncident">
              <span
                class="fal fa-file-medical-alt itemIconAlign itemIconSize"
              ></span>
            </dxi-item>
            <dxi-item [ratio]="2" class="itemInfo">
              <div class="itemInfotitle colorIncident">
                {{ 'entities/incident/_title/plural' | translate }}
              </div>
              <div class="containerGlobalInfoDetails">
                <div class="containerInfoDetails colorInfoIncident">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(0, '')"
                  >
                    {{ 'globals/isNew' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(0, '')"
                  >
                    {{ nbrNewIncident }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoIncident">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(0, 'my/in-progress')"
                  >
                    {{ 'globals/isOpen' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(0, 'my/in-progress')"
                  >
                    {{ nbrOpenIncident }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoIncident">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(0, 'my/resolved')"
                  >
                    {{ 'globals/processed' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(0, 'my/resolved')"
                  >
                    {{ nbrProcessedIncident }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoSecond">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(0, 'my/closed')"
                  >
                    {{ 'globals/isClosed' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(0, 'my/closed')"
                  >
                    {{ nbrClosedIncident }}
                  </div>
                </div>
                <div
                  class="containerInfoDetails colorInfoMyService"
                  *ngIf="authorizationService.user.hasHelpDeskResponsible()"
                >
                  <div
                    class="flex-item-80 textLeft"
                    (click)="openTypeListByStatus(0, 'my-service')"
                  >
                    {{ 'globals/myService' | translate }}
                  </div>
                  <div
                    class="flex-item-20 textRight"
                    (click)="openTypeListByStatus(0, 'my-service')"
                  >
                    {{ nbrMyServiceIncident }}
                  </div>
                </div>
                <div
                  class="containerInfoDetails colorInfoMyService"
                  *ngIf="isAccesTicketObserver"
                >
                  <div
                    class="flex-item-80 textLeft"
                    (click)="openTypeListByStatus(0, 'my-area')"
                  >
                    <span>{{ accessTicketsObserverLabel }}</span>
                  </div>
                  <div
                    class="flex-item-20 textRight"
                    (click)="openTypeListByStatus(0, 'my-area')"
                  >
                    {{ nbrMyAreaIncident }}
                  </div>
                </div>
              </div>
            </dxi-item>
            <dxi-item
              [ratio]="1"
              class="item backgroundIncidentClick"
              (click)="createIncidentClick($event)"
              *clcHasPolicies="['incident-help-desk-user.write']"
            >
              <div
                class="fal fa-plus-circle itemIconAlign itemIconClickSize"
              ></div>
            </dxi-item>
          </dx-box>
        </div>
        <div class="illustration-div">
          <a target="_blank" href="{{ urlImgIncident }}"
            ><img
              src="{{ img64Incident }}"
              alt=""
              class="illustration-img"
              [ngClass]="{
                'illustration-img2':
                  img64Incident != null && img64Incident != undefined
              }"
          /></a>
        </div>
      </div>

      <div
        class="flex-item flex-content-item"
        *clcHasPolicies="['request-help-desk-user']"
        [ngClass]="ngclassHiddden"
      >
        <div class="containerInfo">
          <dx-box direction="row" width="100%" height="100%">
            <dxi-item [ratio]="1" class="item backgroundDemand">
              <span
                class="fal fa-user-headset itemIconAlign itemIconSize"
              ></span>
            </dxi-item>
            <dxi-item [ratio]="2" class="itemInfo">
              <div class="itemInfotitle colorDemand">
                {{ 'entities/request/_title/plural' | translate }}
              </div>
              <div class="containerGlobalInfoDetails">
                <div class="containerInfoDetails colorInfoDemand">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(1, '')"
                  >
                    {{ 'globals/isNew' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(1, '')"
                  >
                    {{ nbrNewRequest }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoDemand">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(1, 'my/in-progress')"
                  >
                    {{ 'globals/isOpen' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(1, 'my/in-progress')"
                  >
                    {{ nbrOpenRequest }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoDemand">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(1, 'my/resolved')"
                  >
                    {{ 'globals/processed' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(1, 'my/resolved')"
                  >
                    {{ nbrProcessedRequest }}
                  </div>
                </div>
                <div class="containerInfoDetails colorInfoSecondDemand">
                  <div
                    class="flex-item textLeft"
                    (click)="openTypeListByStatus(1, 'my/closed')"
                  >
                    {{ 'globals/isClosed' | translate }}
                  </div>
                  <div
                    class="flex-item textRight"
                    (click)="openTypeListByStatus(1, 'my/closed')"
                  >
                    {{ nbrClosedRequest }}
                  </div>
                </div>
                <div
                  class="containerInfoDetails colorInfoMyService"
                  *ngIf="authorizationService.user.hasHelpDeskResponsible()"
                >
                  <div
                    class="flex-item-80 textLeft"
                    (click)="openTypeListByStatus(1, 'my-service')"
                  >
                    {{ 'globals/myService' | translate }}
                  </div>
                  <div
                    class="flex-item-20 textRight"
                    (click)="openTypeListByStatus(1, 'my-service')"
                  >
                    {{ nbrMyServiceRequest }}
                  </div>
                </div>
                <div
                  class="containerInfoDetails colorInfoMyService"
                  *ngIf="isAccesTicketObserver"
                >
                  <div
                    class="flex-item-80 textLeft"
                    (click)="openTypeListByStatus(1, 'my-area')"
                  >
                    {{ accessTicketsObserverLabel }}
                  </div>
                  <div
                    class="flex-item-20 textRight"
                    (click)="openTypeListByStatus(1, 'my-area')"
                  >
                    {{ nbrMyAreaRequest }}
                  </div>
                </div>
              </div>
            </dxi-item>
            <dxi-item
              [ratio]="1"
              class="item backgroundDemandClick"
              (click)="createRequestClick($event)"
              *clcHasPolicies="['request-help-desk-user.write']"
            >
              <div
                class="fal fa-plus-circle itemIconAlign itemIconClickSize"
              ></div>
            </dxi-item>
          </dx-box>
        </div>
        <div class="illustration-div">
          <a target="_blank" href="{{ urlImgRequest }}"
            ><img
              src="{{ img64Request }}"
              alt=""
              class="illustration-img"
              [ngClass]="{
                'illustration-img2':
                  img64Request != null && img64Request != undefined
              }"
          /></a>
        </div>
      </div>
    </div>
  </div>
  <div style="flex-basis: 25%" [ngClass]="ngclassHiddden">
    <div class="maxheight25">
      <clc-dashboard-user-aera-list
        *clcHasPolicies="['help-desk-user.home-message']"
        [title]="'entities/incident/messages' | translate"
        color="#7ACEE8"
        [sourceContext]="sourceMessages"
        [reloadTimer]="10000"
        url="/service-desk/messages"
        icon="fal fa-comment-alt-lines"
      >
      </clc-dashboard-user-aera-list>
    </div>
    <div class="maxheight25">
      <clc-dashboard-user-aera-list
        *clcHasPolicies="['help-desk-user.knowledge-help-desk-user']"
        [title]="'entities/articleKnowledge/_title/plural' | translate"
        [linkTitle]="'/help-desk-knowledge'"
        [sourceContext]="sourceKbContext"
        icon="fal fa-book-open"
        color="#ba73ed"
        secondColor="#663399"
        [titleTranslatable]="true"
        [showEvaluateZone]="'ArticleKnowledgeNoteCoreService'"
        [countService]="'ArticleKnowledgeCoreService'"
        [countMethodeService]="'countViewByUser'"
        [reloadTimer]="autoTimerRefresh"
      >
      </clc-dashboard-user-aera-list>
    </div>
    <div class="maxheight25">
      <clc-dashboard-user-aera-list
        *clcHasPolicies="['help-desk-user.news-help-desk-user']"
        [title]="'entities/news/_title/plural' | translate"
        [sourceContext]="sourceNewsContext"
        icon="fal fa-newspaper"
        color="#80e8ab"
        secondColor="#579E74"
        [titleTranslatable]="true"
        [contentTranslatable]="true"
        [reloadTimer]="autoTimerRefresh"
      >
      </clc-dashboard-user-aera-list>
    </div>
  </div>
</div>
