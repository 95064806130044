import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfScanByWorkgroup, FilterOfScanByWorkgroup, QueryContextOfScanByWorkgroup, ServiceSingleResultOfInt64, ServiceListResultOfScanByWorkgroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanByWorkgroup, ServiceListResultOfNetworkCredential, FilterOfNetworkCredential, QueryContextOfNetworkCredential, ServiceListResultOfScanExclude, FilterOfScanExclude, QueryContextOfScanExclude, ServiceListResultOfScanDataFile, FilterOfScanDataFile, QueryContextOfScanDataFile, ServiceListResultOfScanRegistryKey, FilterOfScanRegistryKey, QueryContextOfScanRegistryKey, ServiceListResultOfScanImportOption, FilterOfScanImportOption, QueryContextOfScanImportOption, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfAlert, FilterOfAlert, QueryContextOfAlert, ServiceListResultOfInventoryHistory, FilterOfInventoryHistory, QueryContextOfInventoryHistory, ServiceListResultOfLdapHistory, FilterOfLdapHistory, QueryContextOfLdapHistory, ScanByWorkgroupInput } from '../types'

export type GetScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        get?: Maybe<ServiceSingleResultOfScanByWorkgroup>
    }
}

export type GetScanByWorkgroupsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanByWorkgroups ${args} {
    scanByWorkgroups {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        first?: Maybe<ServiceSingleResultOfScanByWorkgroup>
    }
}

export type FirstScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstScanByWorkgroups ${args} {
    scanByWorkgroups {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countScanByWorkgroups ${args} {
    scanByWorkgroups {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        find?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type FindScanByWorkgroupsBaseVariables = {
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfScanByWorkgroup = null, $filter: FilterOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findScanByWorkgroups ${args} {
    scanByWorkgroups {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        search?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type SearchScanByWorkgroupsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchScanByWorkgroups ${args} {
    scanByWorkgroups {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaScanByWorkgroupsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaScanByWorkgroups ${args} {
    scanByWorkgroups {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataScanByWorkgroups ${args} {
    scanByWorkgroups {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        customQuery?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type CustomQueryScanByWorkgroupsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfScanByWorkgroup = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryScanByWorkgroups ${args} {
    scanByWorkgroups {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        customQueryId?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type CustomQueryIdScanByWorkgroupsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfScanByWorkgroup = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdScanByWorkgroups ${args} {
    scanByWorkgroups {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedScanByWorkgroups ${args} {
    scanByWorkgroups {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistScanByWorkgroupsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existScanByWorkgroups ${args} {
    scanByWorkgroups {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findRecycles?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type FindRecyclesScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesScanByWorkgroups ${args} {
    scanByWorkgroups {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesScanByWorkgroups ${args} {
    scanByWorkgroups {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyScanByWorkgroupsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyScanByWorkgroups ${args} {
    scanByWorkgroups {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findArchived?: Maybe<ServiceListResultOfScanByWorkgroup>
    }
}

export type FindArchivedScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanByWorkgroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null, $options: QueryContextOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedScanByWorkgroups ${args} {
    scanByWorkgroups {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllScanByWorkgroupsBaseVariables = {
	filter: Maybe<FilterOfScanByWorkgroup>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllScanByWorkgroups ${args} {
    scanByWorkgroups {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfScanByWorkgroup>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsScanByWorkgroups ${args} {
    scanByWorkgroups {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedNetworkCredentialsScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedNetworkCredentials?: Maybe<ServiceListResultOfNetworkCredential>
    }
}

export type FindUnassociatedNetworkCredentialsScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNetworkCredential>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNetworkCredential>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedNetworkCredentialsScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNetworkCredential = null, $options: QueryContextOfNetworkCredential = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedNetworkCredentialsScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedNetworkCredentials(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedNetworkCredentialsScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedNetworkCredentialsScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNetworkCredential", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanExcludesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedScanExcludes?: Maybe<ServiceListResultOfScanExclude>
    }
}

export type FindUnassociatedScanExcludesScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanExclude>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanExclude>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanExcludesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanExclude = null, $options: QueryContextOfScanExclude = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanExcludesScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedScanExcludes(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanExcludesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanExcludesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanExclude", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanDataFilesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedScanDataFiles?: Maybe<ServiceListResultOfScanDataFile>
    }
}

export type FindUnassociatedScanDataFilesScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanDataFile>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanDataFile>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanDataFilesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanDataFile = null, $options: QueryContextOfScanDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanDataFilesScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedScanDataFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanDataFilesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanDataFilesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanRegistryKeysScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedScanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type FindUnassociatedScanRegistryKeysScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanRegistryKeysScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanRegistryKey = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanRegistryKeysScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedScanRegistryKeys(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanRegistryKeysScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanRegistryKeysScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanImportOptionsScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedScanImportOptions?: Maybe<ServiceListResultOfScanImportOption>
    }
}

export type FindUnassociatedScanImportOptionsScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanImportOption>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanImportOption>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanImportOptionsScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanImportOption = null, $options: QueryContextOfScanImportOption = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanImportOptionsScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedScanImportOptions(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanImportOptionsScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanImportOptionsScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanImportOption", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUsersScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindUnassociatedUsersScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUsersScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUsersScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedUsers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUsersScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUsersScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetsScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindUnassociatedAssetsScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetsScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetsScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetsScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetsScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAlertsScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedAlerts?: Maybe<ServiceListResultOfAlert>
    }
}

export type FindUnassociatedAlertsScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAlert>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAlert>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAlertsScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAlert = null, $options: QueryContextOfAlert = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAlertsScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedAlerts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAlertsScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAlertsScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAlert", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedInventoryHistoriesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedInventoryHistories?: Maybe<ServiceListResultOfInventoryHistory>
    }
}

export type FindUnassociatedInventoryHistoriesScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfInventoryHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfInventoryHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedInventoryHistoriesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfInventoryHistory = null, $options: QueryContextOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedInventoryHistoriesScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedInventoryHistories(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedInventoryHistoriesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedInventoryHistoriesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfInventoryHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedLdapHistoriesScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        findUnassociatedLdapHistories?: Maybe<ServiceListResultOfLdapHistory>
    }
}

export type FindUnassociatedLdapHistoriesScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfLdapHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedLdapHistoriesScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfLdapHistory = null, $options: QueryContextOfLdapHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedLdapHistoriesScanByWorkgroups ${args} {
    scanByWorkgroups {
        findUnassociatedLdapHistories(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedLdapHistoriesScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedLdapHistoriesScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        insert?: Maybe<ServiceSingleResultOfScanByWorkgroup>
    }
}

export type InsertScanByWorkgroupsBaseVariables = {
	entity: ScanByWorkgroupInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ScanByWorkgroupInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertScanByWorkgroups ${args} {
    scanByWorkgroups {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        update?: Maybe<ServiceSingleResultOfScanByWorkgroup>
    }
}

export type UpdateScanByWorkgroupsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfScanByWorkgroup>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateScanByWorkgroups ${args} {
    scanByWorkgroups {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanByWorkgroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataScanByWorkgroupsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataScanByWorkgroups ${args} {
    scanByWorkgroups {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfScanByWorkgroup>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfScanByWorkgroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyScanByWorkgroups ${args} {
    scanByWorkgroups {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteScanByWorkgroups ${args} {
    scanByWorkgroups {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreScanByWorkgroups ${args} {
    scanByWorkgroups {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleScanByWorkgroups ${args} {
    scanByWorkgroups {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedScanByWorkgroups ${args} {
    scanByWorkgroups {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedScanByWorkgroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedScanByWorkgroups ${args} {
    scanByWorkgroups {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldScanByWorkgroups ${args} {
    scanByWorkgroups {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldScanByWorkgroupsResultType = {
    scanByWorkgroups: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldScanByWorkgroupsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldScanByWorkgroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldScanByWorkgroups ${args} {
    scanByWorkgroups {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldScanByWorkgroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldScanByWorkgroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
