import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScsiControllersBaseVariables, FirstScsiControllersBaseVariables, CountScsiControllersBaseVariables, FindScsiControllersBaseVariables, SearchScsiControllersBaseVariables, ExportSchemaScsiControllersBaseVariables, ExportDataScsiControllersBaseVariables, CustomQueryScsiControllersBaseVariables, CustomQueryIdScsiControllersBaseVariables, UsedScsiControllersBaseVariables, ExistScsiControllersBaseVariables } from '../gqls'
import { GetScsiControllersDocument, FirstScsiControllersDocument, CountScsiControllersDocument, FindScsiControllersDocument, SearchScsiControllersDocument, ExportSchemaScsiControllersDocument, ExportDataScsiControllersDocument, CustomQueryScsiControllersDocument, CustomQueryIdScsiControllersDocument, UsedScsiControllersDocument, ExistScsiControllersDocument } from '../gqls'
import { ServiceSingleResultOfScsiController, QueryContextOfScsiController, FilterOfScsiController, ServiceSingleResultOfInt64, ServiceListResultOfScsiController, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScsiControllerBaseService {
    
public modelName = 'scsiController';
public modelPluralName = 'scsiControllers';

	private getScsiControllersQuery: GetScsiControllersDocument;
	private firstScsiControllersQuery: FirstScsiControllersDocument;
	private countScsiControllersQuery: CountScsiControllersDocument;
	private findScsiControllersQuery: FindScsiControllersDocument;
	private searchScsiControllersQuery: SearchScsiControllersDocument;
	private exportSchemaScsiControllersQuery: ExportSchemaScsiControllersDocument;
	private exportDataScsiControllersQuery: ExportDataScsiControllersDocument;
	private customQueryScsiControllersQuery: CustomQueryScsiControllersDocument;
	private customQueryIdScsiControllersQuery: CustomQueryIdScsiControllersDocument;
	private usedScsiControllersQuery: UsedScsiControllersDocument;
	private existScsiControllersQuery: ExistScsiControllersDocument;

	constructor(private injector: Injector) {
		this.getScsiControllersQuery = this.injector.get(GetScsiControllersDocument);
		this.firstScsiControllersQuery = this.injector.get(FirstScsiControllersDocument);
		this.countScsiControllersQuery = this.injector.get(CountScsiControllersDocument);
		this.findScsiControllersQuery = this.injector.get(FindScsiControllersDocument);
		this.searchScsiControllersQuery = this.injector.get(SearchScsiControllersDocument);
		this.exportSchemaScsiControllersQuery = this.injector.get(ExportSchemaScsiControllersDocument);
		this.exportDataScsiControllersQuery = this.injector.get(ExportDataScsiControllersDocument);
		this.customQueryScsiControllersQuery = this.injector.get(CustomQueryScsiControllersDocument);
		this.customQueryIdScsiControllersQuery = this.injector.get(CustomQueryIdScsiControllersDocument);
		this.usedScsiControllersQuery = this.injector.get(UsedScsiControllersDocument);
		this.existScsiControllersQuery = this.injector.get(ExistScsiControllersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScsiController> {
    
    		let variables: GetScsiControllersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.get));
            }
            else{
                let result:ServiceSingleResultOfScsiController={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScsiController,
		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScsiController> {
    
    		let variables: FirstScsiControllersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScsiControllersBaseVariables = {
    			filter: filter
    		}
    				return this.countScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScsiController,
		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScsiController> {
    
    		let variables: FindScsiControllersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScsiController,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScsiController> {
    
    		let variables: SearchScsiControllersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScsiControllersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScsiControllersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScsiController,
		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScsiController> {
    
    		let variables: CustomQueryScsiControllersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScsiController,
		@Args('filter?') filter?: FilterOfScsiController,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScsiController> {
    
    		let variables: CustomQueryIdScsiControllersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScsiControllersBaseVariables = {
    			ids: ids
    		}
    				return this.usedScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScsiControllersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScsiControllersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scsiControllers.exist));
    	}
    
    
}