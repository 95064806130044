import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBusinessTimesBaseVariables, FirstBusinessTimesBaseVariables, CountBusinessTimesBaseVariables, FindBusinessTimesBaseVariables, SearchBusinessTimesBaseVariables, ExportSchemaBusinessTimesBaseVariables, ExportDataBusinessTimesBaseVariables, CustomQueryBusinessTimesBaseVariables, CustomQueryIdBusinessTimesBaseVariables, UsedBusinessTimesBaseVariables, FindNotUsersBusinessTimesBaseVariables, FindUsersBusinessTimesBaseVariables, TimeZonesBusinessTimesBaseVariables, ExistBusinessTimesBaseVariables, FindUnassociatedDayOffsBusinessTimesBaseVariables, FindUnassociatedUsersBusinessTimesBaseVariables, InsertBusinessTimesBaseVariables, UpdateBusinessTimesBaseVariables, ImportDataBusinessTimesBaseVariables, UpdateManyBusinessTimesBaseVariables, DeleteBusinessTimesBaseVariables, AddUsersCustomBusinessTimesBaseVariables, RemoveUsersCustomBusinessTimesBaseVariables, RecalculateDeadlinesBusinessTimesBaseVariables, AddDayOffsBusinessTimesBaseVariables, RemoveDayOffsBusinessTimesBaseVariables, AddUsersBusinessTimesBaseVariables, RemoveUsersBusinessTimesBaseVariables } from '../gqls'
import { GetBusinessTimesDocument, FirstBusinessTimesDocument, CountBusinessTimesDocument, FindBusinessTimesDocument, SearchBusinessTimesDocument, ExportSchemaBusinessTimesDocument, ExportDataBusinessTimesDocument, CustomQueryBusinessTimesDocument, CustomQueryIdBusinessTimesDocument, UsedBusinessTimesDocument, FindNotUsersBusinessTimesDocument, FindUsersBusinessTimesDocument, TimeZonesBusinessTimesDocument, ExistBusinessTimesDocument, FindUnassociatedDayOffsBusinessTimesDocument, FindUnassociatedUsersBusinessTimesDocument, InsertBusinessTimesDocument, UpdateBusinessTimesDocument, ImportDataBusinessTimesDocument, UpdateManyBusinessTimesDocument, DeleteBusinessTimesDocument, AddUsersCustomBusinessTimesDocument, RemoveUsersCustomBusinessTimesDocument, RecalculateDeadlinesBusinessTimesDocument, AddDayOffsBusinessTimesDocument, RemoveDayOffsBusinessTimesDocument, AddUsersBusinessTimesDocument, RemoveUsersBusinessTimesDocument } from '../gqls'
import { ServiceSingleResultOfBusinessTime, QueryContextOfBusinessTime, FilterOfBusinessTime, ServiceSingleResultOfInt64, ServiceListResultOfBusinessTime, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceListResultOfTimeZone, QueryContextOfDayOff, FilterOfDayOff, ServiceListResultOfDayOff, BusinessTimeInput, FieldUpdateOperatorOfBusinessTime } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BusinessTimeBaseService {
    
public modelName = 'businessTime';
public modelPluralName = 'businessTimes';

	private getBusinessTimesQuery: GetBusinessTimesDocument;
	private firstBusinessTimesQuery: FirstBusinessTimesDocument;
	private countBusinessTimesQuery: CountBusinessTimesDocument;
	private findBusinessTimesQuery: FindBusinessTimesDocument;
	private searchBusinessTimesQuery: SearchBusinessTimesDocument;
	private exportSchemaBusinessTimesQuery: ExportSchemaBusinessTimesDocument;
	private exportDataBusinessTimesQuery: ExportDataBusinessTimesDocument;
	private customQueryBusinessTimesQuery: CustomQueryBusinessTimesDocument;
	private customQueryIdBusinessTimesQuery: CustomQueryIdBusinessTimesDocument;
	private usedBusinessTimesQuery: UsedBusinessTimesDocument;
	private findNotUsersBusinessTimesQuery: FindNotUsersBusinessTimesDocument;
	private findUsersBusinessTimesQuery: FindUsersBusinessTimesDocument;
	private timeZonesBusinessTimesQuery: TimeZonesBusinessTimesDocument;
	private existBusinessTimesQuery: ExistBusinessTimesDocument;
	private findUnassociatedDayOffsBusinessTimesQuery: FindUnassociatedDayOffsBusinessTimesDocument;
	private findUnassociatedUsersBusinessTimesQuery: FindUnassociatedUsersBusinessTimesDocument;
	private insertBusinessTimesMutation: InsertBusinessTimesDocument;
	private updateBusinessTimesMutation: UpdateBusinessTimesDocument;
	private importDataBusinessTimesMutation: ImportDataBusinessTimesDocument;
	private updateManyBusinessTimesMutation: UpdateManyBusinessTimesDocument;
	private deleteBusinessTimesMutation: DeleteBusinessTimesDocument;
	private addUsersCustomBusinessTimesMutation: AddUsersCustomBusinessTimesDocument;
	private removeUsersCustomBusinessTimesMutation: RemoveUsersCustomBusinessTimesDocument;
	private recalculateDeadlinesBusinessTimesMutation: RecalculateDeadlinesBusinessTimesDocument;
	private addDayOffsBusinessTimesMutation: AddDayOffsBusinessTimesDocument;
	private removeDayOffsBusinessTimesMutation: RemoveDayOffsBusinessTimesDocument;
	private addUsersBusinessTimesMutation: AddUsersBusinessTimesDocument;
	private removeUsersBusinessTimesMutation: RemoveUsersBusinessTimesDocument;

	constructor(private injector: Injector) {
		this.getBusinessTimesQuery = this.injector.get(GetBusinessTimesDocument);
		this.firstBusinessTimesQuery = this.injector.get(FirstBusinessTimesDocument);
		this.countBusinessTimesQuery = this.injector.get(CountBusinessTimesDocument);
		this.findBusinessTimesQuery = this.injector.get(FindBusinessTimesDocument);
		this.searchBusinessTimesQuery = this.injector.get(SearchBusinessTimesDocument);
		this.exportSchemaBusinessTimesQuery = this.injector.get(ExportSchemaBusinessTimesDocument);
		this.exportDataBusinessTimesQuery = this.injector.get(ExportDataBusinessTimesDocument);
		this.customQueryBusinessTimesQuery = this.injector.get(CustomQueryBusinessTimesDocument);
		this.customQueryIdBusinessTimesQuery = this.injector.get(CustomQueryIdBusinessTimesDocument);
		this.usedBusinessTimesQuery = this.injector.get(UsedBusinessTimesDocument);
		this.findNotUsersBusinessTimesQuery = this.injector.get(FindNotUsersBusinessTimesDocument);
		this.findUsersBusinessTimesQuery = this.injector.get(FindUsersBusinessTimesDocument);
		this.timeZonesBusinessTimesQuery = this.injector.get(TimeZonesBusinessTimesDocument);
		this.existBusinessTimesQuery = this.injector.get(ExistBusinessTimesDocument);
		this.findUnassociatedDayOffsBusinessTimesQuery = this.injector.get(FindUnassociatedDayOffsBusinessTimesDocument);
		this.findUnassociatedUsersBusinessTimesQuery = this.injector.get(FindUnassociatedUsersBusinessTimesDocument);
		this.insertBusinessTimesMutation = this.injector.get(InsertBusinessTimesDocument);
		this.updateBusinessTimesMutation = this.injector.get(UpdateBusinessTimesDocument);
		this.importDataBusinessTimesMutation = this.injector.get(ImportDataBusinessTimesDocument);
		this.updateManyBusinessTimesMutation = this.injector.get(UpdateManyBusinessTimesDocument);
		this.deleteBusinessTimesMutation = this.injector.get(DeleteBusinessTimesDocument);
		this.addUsersCustomBusinessTimesMutation = this.injector.get(AddUsersCustomBusinessTimesDocument);
		this.removeUsersCustomBusinessTimesMutation = this.injector.get(RemoveUsersCustomBusinessTimesDocument);
		this.recalculateDeadlinesBusinessTimesMutation = this.injector.get(RecalculateDeadlinesBusinessTimesDocument);
		this.addDayOffsBusinessTimesMutation = this.injector.get(AddDayOffsBusinessTimesDocument);
		this.removeDayOffsBusinessTimesMutation = this.injector.get(RemoveDayOffsBusinessTimesDocument);
		this.addUsersBusinessTimesMutation = this.injector.get(AddUsersBusinessTimesDocument);
		this.removeUsersBusinessTimesMutation = this.injector.get(RemoveUsersBusinessTimesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBusinessTime> {
    
    		let variables: GetBusinessTimesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.get));
            }
            else{
                let result:ServiceSingleResultOfBusinessTime={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBusinessTime> {
    
    		let variables: FirstBusinessTimesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBusinessTimesBaseVariables = {
    			filter: filter
    		}
    				return this.countBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBusinessTime> {
    
    		let variables: FindBusinessTimesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBusinessTime> {
    
    		let variables: SearchBusinessTimesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBusinessTimesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBusinessTimesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBusinessTime> {
    
    		let variables: CustomQueryBusinessTimesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBusinessTime> {
    
    		let variables: CustomQueryIdBusinessTimesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBusinessTimesBaseVariables = {
    			ids: ids
    		}
    				return this.usedBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotUsersBusinessTimesBaseVariables = {
    			id: id,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findNotUsersBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.findNotUsers));
    	}

    	/**  */
    	@InjectArgs
    	public findUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUsersBusinessTimesBaseVariables = {
    			id: id,
			memberOfPopulationIds: memberOfPopulationIds,
			filter: filter,
			options: options
    		}
    				return this.findUsersBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.findUsers));
    	}

    	/**  */
    	@InjectArgs
    	public timeZones(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTimeZone> {
    
    		let variables: TimeZonesBusinessTimesBaseVariables = {
    			id: id
    		}
    				return this.timeZonesBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.timeZones));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBusinessTimesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.exist));
    	}

	@InjectArgs
	public findAssociatedDayOffs(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfDayOff,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfDayOff,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfDayOff> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('dayOffs', fields, null, [
			GqlSubFieldArg.create('filterOfDayOffs', 'filter'),
			GqlSubFieldArg.create('optionsOfDayOffs', 'options'),
		]),
		])
		extendedVariables['filterOfDayOffs'] = filter;
		extendedVariables['optionsOfDayOffs'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.dayOffs));
            }
            else{
                let result: ServiceListResultOfDayOff = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedDayOffs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDayOff,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDayOff> {
    
    		let variables: FindUnassociatedDayOffsBusinessTimesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedDayOffsBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.findUnassociatedDayOffs));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersBusinessTimesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersBusinessTimesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.findUnassociatedUsers));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: BusinessTimeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBusinessTime> {
    
    		let variables: InsertBusinessTimesBaseVariables = {
    			entity: entity
    		}
    				return this.insertBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBusinessTime> {
    
    		let variables: UpdateBusinessTimesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataBusinessTimesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyBusinessTimesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteBusinessTimesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addUsersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersCustomBusinessTimesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersCustomBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.addUsersCustom));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsersCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersCustomBusinessTimesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.removeUsersCustomBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.removeUsersCustom));
    	}

    	/**  */
    	@InjectArgs
    	public recalculateDeadlines(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('startDate') startDate: Date,
		@Args('endDate?') endDate?: Date,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecalculateDeadlinesBusinessTimesBaseVariables = {
    			startDate: startDate,
			endDate: endDate
    		}
    				return this.recalculateDeadlinesBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.recalculateDeadlines));
    	}

    	/**  */
    	@InjectArgs
    	public addDayOffs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('dayOffIds') dayOffIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddDayOffsBusinessTimesBaseVariables = {
    			id: id,
			dayOffIds: dayOffIds
    		}
    				return this.addDayOffsBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.addDayOffs));
    	}

    	/**  */
    	@InjectArgs
    	public removeDayOffs(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('dayOffIds') dayOffIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveDayOffsBusinessTimesBaseVariables = {
    			id: id,
			dayOffIds: dayOffIds
    		}
    				return this.removeDayOffsBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.removeDayOffs));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersBusinessTimesBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersBusinessTimesBaseVariables = {
    			userIds: userIds
    		}
    				return this.removeUsersBusinessTimesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.businessTimes.removeUsers));
    	}
    
}