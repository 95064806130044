import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPointingDevicesBaseVariables, FirstPointingDevicesBaseVariables, CountPointingDevicesBaseVariables, FindPointingDevicesBaseVariables, SearchPointingDevicesBaseVariables, ExportSchemaPointingDevicesBaseVariables, ExportDataPointingDevicesBaseVariables, CustomQueryPointingDevicesBaseVariables, CustomQueryIdPointingDevicesBaseVariables, UsedPointingDevicesBaseVariables, ExistPointingDevicesBaseVariables } from '../gqls'
import { GetPointingDevicesDocument, FirstPointingDevicesDocument, CountPointingDevicesDocument, FindPointingDevicesDocument, SearchPointingDevicesDocument, ExportSchemaPointingDevicesDocument, ExportDataPointingDevicesDocument, CustomQueryPointingDevicesDocument, CustomQueryIdPointingDevicesDocument, UsedPointingDevicesDocument, ExistPointingDevicesDocument } from '../gqls'
import { ServiceSingleResultOfPointingDevice, QueryContextOfPointingDevice, FilterOfPointingDevice, ServiceSingleResultOfInt64, ServiceListResultOfPointingDevice, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PointingDeviceBaseService {
    
public modelName = 'pointingDevice';
public modelPluralName = 'pointingDevices';

	private getPointingDevicesQuery: GetPointingDevicesDocument;
	private firstPointingDevicesQuery: FirstPointingDevicesDocument;
	private countPointingDevicesQuery: CountPointingDevicesDocument;
	private findPointingDevicesQuery: FindPointingDevicesDocument;
	private searchPointingDevicesQuery: SearchPointingDevicesDocument;
	private exportSchemaPointingDevicesQuery: ExportSchemaPointingDevicesDocument;
	private exportDataPointingDevicesQuery: ExportDataPointingDevicesDocument;
	private customQueryPointingDevicesQuery: CustomQueryPointingDevicesDocument;
	private customQueryIdPointingDevicesQuery: CustomQueryIdPointingDevicesDocument;
	private usedPointingDevicesQuery: UsedPointingDevicesDocument;
	private existPointingDevicesQuery: ExistPointingDevicesDocument;

	constructor(private injector: Injector) {
		this.getPointingDevicesQuery = this.injector.get(GetPointingDevicesDocument);
		this.firstPointingDevicesQuery = this.injector.get(FirstPointingDevicesDocument);
		this.countPointingDevicesQuery = this.injector.get(CountPointingDevicesDocument);
		this.findPointingDevicesQuery = this.injector.get(FindPointingDevicesDocument);
		this.searchPointingDevicesQuery = this.injector.get(SearchPointingDevicesDocument);
		this.exportSchemaPointingDevicesQuery = this.injector.get(ExportSchemaPointingDevicesDocument);
		this.exportDataPointingDevicesQuery = this.injector.get(ExportDataPointingDevicesDocument);
		this.customQueryPointingDevicesQuery = this.injector.get(CustomQueryPointingDevicesDocument);
		this.customQueryIdPointingDevicesQuery = this.injector.get(CustomQueryIdPointingDevicesDocument);
		this.usedPointingDevicesQuery = this.injector.get(UsedPointingDevicesDocument);
		this.existPointingDevicesQuery = this.injector.get(ExistPointingDevicesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPointingDevice> {
    
    		let variables: GetPointingDevicesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.get));
            }
            else{
                let result:ServiceSingleResultOfPointingDevice={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPointingDevice,
		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPointingDevice> {
    
    		let variables: FirstPointingDevicesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPointingDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.countPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPointingDevice,
		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPointingDevice> {
    
    		let variables: FindPointingDevicesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPointingDevice,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPointingDevice> {
    
    		let variables: SearchPointingDevicesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPointingDevicesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPointingDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPointingDevice,
		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPointingDevice> {
    
    		let variables: CustomQueryPointingDevicesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPointingDevice,
		@Args('filter?') filter?: FilterOfPointingDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPointingDevice> {
    
    		let variables: CustomQueryIdPointingDevicesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPointingDevicesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPointingDevicesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPointingDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pointingDevices.exist));
    	}
    
    
}