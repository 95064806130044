import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanDataFilesBaseVariables, FirstScanDataFilesBaseVariables, CountScanDataFilesBaseVariables, FindScanDataFilesBaseVariables, SearchScanDataFilesBaseVariables, ExportSchemaScanDataFilesBaseVariables, ExportDataScanDataFilesBaseVariables, CustomQueryScanDataFilesBaseVariables, CustomQueryIdScanDataFilesBaseVariables, UsedScanDataFilesBaseVariables, FindAllUnassociatedScanConfigurationsScanDataFilesBaseVariables, FindAllAssociatedScanConfigurationsScanDataFilesBaseVariables, ExistScanDataFilesBaseVariables, FindUnassociatedDataFilesScanDataFilesBaseVariables, InsertScanDataFilesBaseVariables, UpdateScanDataFilesBaseVariables, ImportDataScanDataFilesBaseVariables, UpdateManyScanDataFilesBaseVariables, DeleteScanDataFilesBaseVariables, AddScanConfigurationsScanDataFilesBaseVariables, RemoveScanConfigurationsScanDataFilesBaseVariables, AddDataFilesScanDataFilesBaseVariables, RemoveDataFilesScanDataFilesBaseVariables } from '../gqls'
import { GetScanDataFilesDocument, FirstScanDataFilesDocument, CountScanDataFilesDocument, FindScanDataFilesDocument, SearchScanDataFilesDocument, ExportSchemaScanDataFilesDocument, ExportDataScanDataFilesDocument, CustomQueryScanDataFilesDocument, CustomQueryIdScanDataFilesDocument, UsedScanDataFilesDocument, FindAllUnassociatedScanConfigurationsScanDataFilesDocument, FindAllAssociatedScanConfigurationsScanDataFilesDocument, ExistScanDataFilesDocument, FindUnassociatedDataFilesScanDataFilesDocument, InsertScanDataFilesDocument, UpdateScanDataFilesDocument, ImportDataScanDataFilesDocument, UpdateManyScanDataFilesDocument, DeleteScanDataFilesDocument, AddScanConfigurationsScanDataFilesDocument, RemoveScanConfigurationsScanDataFilesDocument, AddDataFilesScanDataFilesDocument, RemoveDataFilesScanDataFilesDocument } from '../gqls'
import { ServiceSingleResultOfScanDataFile, QueryContextOfScanDataFile, FilterOfScanDataFile, ServiceSingleResultOfInt64, ServiceListResultOfScanDataFile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfiguration, QueryContextOfScanConfiguration, FilterOfScanConfiguration, QueryContextOfDataFile, FilterOfDataFile, ServiceListResultOfDataFile, ScanDataFileInput, FieldUpdateOperatorOfScanDataFile } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanDataFileBaseService {
    
public modelName = 'scanDataFile';
public modelPluralName = 'scanDataFiles';

	private getScanDataFilesQuery: GetScanDataFilesDocument;
	private firstScanDataFilesQuery: FirstScanDataFilesDocument;
	private countScanDataFilesQuery: CountScanDataFilesDocument;
	private findScanDataFilesQuery: FindScanDataFilesDocument;
	private searchScanDataFilesQuery: SearchScanDataFilesDocument;
	private exportSchemaScanDataFilesQuery: ExportSchemaScanDataFilesDocument;
	private exportDataScanDataFilesQuery: ExportDataScanDataFilesDocument;
	private customQueryScanDataFilesQuery: CustomQueryScanDataFilesDocument;
	private customQueryIdScanDataFilesQuery: CustomQueryIdScanDataFilesDocument;
	private usedScanDataFilesQuery: UsedScanDataFilesDocument;
	private findAllUnassociatedScanConfigurationsScanDataFilesQuery: FindAllUnassociatedScanConfigurationsScanDataFilesDocument;
	private findAllAssociatedScanConfigurationsScanDataFilesQuery: FindAllAssociatedScanConfigurationsScanDataFilesDocument;
	private existScanDataFilesQuery: ExistScanDataFilesDocument;
	private findUnassociatedDataFilesScanDataFilesQuery: FindUnassociatedDataFilesScanDataFilesDocument;
	private insertScanDataFilesMutation: InsertScanDataFilesDocument;
	private updateScanDataFilesMutation: UpdateScanDataFilesDocument;
	private importDataScanDataFilesMutation: ImportDataScanDataFilesDocument;
	private updateManyScanDataFilesMutation: UpdateManyScanDataFilesDocument;
	private deleteScanDataFilesMutation: DeleteScanDataFilesDocument;
	private addScanConfigurationsScanDataFilesMutation: AddScanConfigurationsScanDataFilesDocument;
	private removeScanConfigurationsScanDataFilesMutation: RemoveScanConfigurationsScanDataFilesDocument;
	private addDataFilesScanDataFilesMutation: AddDataFilesScanDataFilesDocument;
	private removeDataFilesScanDataFilesMutation: RemoveDataFilesScanDataFilesDocument;

	constructor(private injector: Injector) {
		this.getScanDataFilesQuery = this.injector.get(GetScanDataFilesDocument);
		this.firstScanDataFilesQuery = this.injector.get(FirstScanDataFilesDocument);
		this.countScanDataFilesQuery = this.injector.get(CountScanDataFilesDocument);
		this.findScanDataFilesQuery = this.injector.get(FindScanDataFilesDocument);
		this.searchScanDataFilesQuery = this.injector.get(SearchScanDataFilesDocument);
		this.exportSchemaScanDataFilesQuery = this.injector.get(ExportSchemaScanDataFilesDocument);
		this.exportDataScanDataFilesQuery = this.injector.get(ExportDataScanDataFilesDocument);
		this.customQueryScanDataFilesQuery = this.injector.get(CustomQueryScanDataFilesDocument);
		this.customQueryIdScanDataFilesQuery = this.injector.get(CustomQueryIdScanDataFilesDocument);
		this.usedScanDataFilesQuery = this.injector.get(UsedScanDataFilesDocument);
		this.findAllUnassociatedScanConfigurationsScanDataFilesQuery = this.injector.get(FindAllUnassociatedScanConfigurationsScanDataFilesDocument);
		this.findAllAssociatedScanConfigurationsScanDataFilesQuery = this.injector.get(FindAllAssociatedScanConfigurationsScanDataFilesDocument);
		this.existScanDataFilesQuery = this.injector.get(ExistScanDataFilesDocument);
		this.findUnassociatedDataFilesScanDataFilesQuery = this.injector.get(FindUnassociatedDataFilesScanDataFilesDocument);
		this.insertScanDataFilesMutation = this.injector.get(InsertScanDataFilesDocument);
		this.updateScanDataFilesMutation = this.injector.get(UpdateScanDataFilesDocument);
		this.importDataScanDataFilesMutation = this.injector.get(ImportDataScanDataFilesDocument);
		this.updateManyScanDataFilesMutation = this.injector.get(UpdateManyScanDataFilesDocument);
		this.deleteScanDataFilesMutation = this.injector.get(DeleteScanDataFilesDocument);
		this.addScanConfigurationsScanDataFilesMutation = this.injector.get(AddScanConfigurationsScanDataFilesDocument);
		this.removeScanConfigurationsScanDataFilesMutation = this.injector.get(RemoveScanConfigurationsScanDataFilesDocument);
		this.addDataFilesScanDataFilesMutation = this.injector.get(AddDataFilesScanDataFilesDocument);
		this.removeDataFilesScanDataFilesMutation = this.injector.get(RemoveDataFilesScanDataFilesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanDataFile> {
    
    		let variables: GetScanDataFilesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.get));
            }
            else{
                let result:ServiceSingleResultOfScanDataFile={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanDataFile> {
    
    		let variables: FirstScanDataFilesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanDataFilesBaseVariables = {
    			filter: filter
    		}
    				return this.countScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: FindScanDataFilesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: SearchScanDataFilesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanDataFilesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanDataFilesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: CustomQueryScanDataFilesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanDataFile,
		@Args('filter?') filter?: FilterOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanDataFile> {
    
    		let variables: CustomQueryIdScanDataFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanDataFilesBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.used));
    	}

    	/**  */
    	@InjectArgs
    	public findAllUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllUnassociatedScanConfigurationsScanDataFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllUnassociatedScanConfigurationsScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.findAllUnassociatedScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public findAllAssociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindAllAssociatedScanConfigurationsScanDataFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAllAssociatedScanConfigurationsScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.findAllAssociatedScanConfigurations));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanDataFilesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.exist));
    	}

	@InjectArgs
	public findAssociatedDataFiles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfDataFile,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfDataFile> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('dataFiles', fields, null, [
			GqlSubFieldArg.create('filterOfDataFiles', 'filter'),
			GqlSubFieldArg.create('optionsOfDataFiles', 'options'),
		]),
		])
		extendedVariables['filterOfDataFiles'] = filter;
		extendedVariables['optionsOfDataFiles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.dataFiles));
            }
            else{
                let result: ServiceListResultOfDataFile = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDataFile,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDataFile> {
    
    		let variables: FindUnassociatedDataFilesScanDataFilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedDataFilesScanDataFilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.findUnassociatedDataFiles));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanDataFileInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanDataFile> {
    
    		let variables: InsertScanDataFilesBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanDataFile> {
    
    		let variables: UpdateScanDataFilesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanDataFilesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanDataFile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanDataFilesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanDataFilesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsScanDataFilesBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsScanDataFilesBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.removeScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public addDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('dataFileIds') dataFileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddDataFilesScanDataFilesBaseVariables = {
    			id: id,
			dataFileIds: dataFileIds
    		}
    				return this.addDataFilesScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.addDataFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeDataFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('dataFileIds') dataFileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveDataFilesScanDataFilesBaseVariables = {
    			dataFileIds: dataFileIds
    		}
    				return this.removeDataFilesScanDataFilesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanDataFiles.removeDataFiles));
    	}
    
}