import { SecurityGroupBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { Args, InjectArgs } from '@clarilog/core';
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import {
  FilterOfAssetCategory,
  FilterOfSecurityGroup,
  QueryContextOfAssetCategory,
  QueryContextOfSecurityGroup,
  SecurityGroupCategory,
  ServiceListResultOfAssetCategory,
  ServiceListResultOfSecurityGroup,
  ServiceSingleResultOfSecurityGroup,
} from '../types';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { GetSecurityGroupsBaseVariables } from '../gqls';

@Injectable({ providedIn: 'root' })
@Authorize('administration.group')
export class SecurityGroupCoreService extends SecurityGroupBaseService {
  constructor(injector: Injector) {
    super(injector);
  } 

  @InjectArgs
  public findOperatorTeam(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfSecurityGroup,    
    @Args('filter?') filter?: FilterOfSecurityGroup,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfSecurityGroup> 
  {
    if(filter == null){
      filter = { };
    }
    filter.category = { eq: SecurityGroupCategory.OperatorTeam};
    return this.find(fields,options,filter);
  }

  @InjectArgs
  public findManager(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfSecurityGroup,    
    @Args('filter?') filter?: FilterOfSecurityGroup,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfSecurityGroup> 
  {
    if(filter == null){
      filter = { };
    }
    filter.category = { eq: SecurityGroupCategory.Manager};
    return this.find(fields,options,filter);
  }

}
