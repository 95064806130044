import { Injectable } from '@angular/core';
import { GqlSubField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  DynamicPropertyDateFormat,
  DynamicPropertyFieldEnum,
  DynamicPropertyLocationEnum,
  DynamicPropertyNumericFormat,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslateService } from '@clarilog/shared/services/translate';
import {
  Control,
  DynamicPropertyOptions,
  PageControl,
  PageSection,
  Section,
} from '@clarilog/shared2/models/schema';
import {
  CoreModelCompilerOption,
  CoreModelCompilerService,
} from './model-compiler.service';
import { DynamicPropertyFieldCoreService } from '@clarilog/core/services2/graphql/generated-types/services/dynamic-property-field.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({
  providedIn: 'root',
})
export class DynamicFieldSection {
  name: string;
  section: Section;
}

/** Représente un compiler du service. */
@Injectable({
  providedIn: 'root',
})
export class CoreModelDynamicFieldCompilerService {
  constructor(
    private dynamicPropertyCoreService: DynamicPropertyFieldCoreService,
    private translateFieldHelperService: TranslatedFieldHelperService,
  ) {}

  /** Vérifie si le model contient des champs dynamique */
  public getDynamicField(copyModel: any): DynamicPropertyOptions {
    let hasDynamic = copyModel?.form?.layout
      ?.dynamicProperty as DynamicPropertyOptions;
    if (hasDynamic != undefined) {
      if (hasDynamic.source == undefined) {
        console.error("Can't load dynamic field (source not set).");
        return undefined;
      }
      if (hasDynamic.location == undefined) {
        // Position par défaut
        hasDynamic.location = 'static';
        hasDynamic.pageIndexReference = 2;
      }

      if (hasDynamic.pageIndexReference == undefined) {
        switch (hasDynamic.location) {
          case 'after':
          case 'before':
            console.error(
              "Can't determine position for dynamic properties (option not set).",
            );
            return undefined;
          case 'static':
            // Force en seconde position
            hasDynamic.pageIndexReference = 2;
        }
      }
      return hasDynamic;
    }
    return undefined;
  }

  /** Test dynamic Field */
  public async createDynamicField(
    copyModel: { [k: string]: any },
    option: CoreModelCompilerOption,
    modelCompilerService: CoreModelCompilerService,
  ): Promise<{ [k: string]: any }> {
    let dynamicPageField = this.getDynamicField(copyModel);

    /** Création la page section des champs personnalisés */
    let dynamicPage: PageSection = {
      name: 'dynamicPropertyPage',
      label: TranslateService.get('entities/dynamicProperty/_title/plural'),
      sections: [],
    };

    if (dynamicPageField != undefined) {
      let finalSections: DynamicFieldSection[] = [];

      // Si deja present on enleve la section
      copyModel.form.layout.pages = copyModel.form.layout.pages.filter(
        (s) => s.name != dynamicPage.name,
      );

      // Requetes de récupération des champs dynamic par rapport au service passé
      let setMethod = {};
      let fnPropertyName = 'dynamicProperty';
      setMethod[fnPropertyName] = dynamicPageField.source;
      let compileFn = await modelCompilerService
        .coreCompile(setMethod)
        .toPromise();
      let fn = compileFn?.model[fnPropertyName];
      if (fn != undefined) {
        let fields = [
          GqlSubField.create(
            'data',
            this.dynamicPropertyCoreService.getFields(),
          ),
        ];

        fn.context.params.set('id', () => option?.id);
        fn.context.params.set('fields', () => fields);
        fn.context.params.set('entry', () => option?.entry);

        let dn = await fn.fnCall().toPromise();

        // Vérification si champs perso
        if (dn?.data?.length != undefined && dn?.data?.length > 0) {
          let dynamicPropertyKey = dn?.data[0].entityTypes[0];
          let dynamicProperties = dn?.data;
          if (dynamicProperties != undefined) {
            let sections: DynamicFieldSection[] = [];
            let languague = this.translateFieldHelperService.getTranslateKey();

            // Trie les groupes en ordre de priorité de position
            dynamicProperties = dynamicProperties.sort(function (a, b) {
              let zIndexGroup = 999999999;
              let aGroup =
                (a?.dynamicPropertyGroup?.data?.position ?? 0) * zIndexGroup;
              let bGroup =
                (b?.dynamicPropertyGroup?.data?.position ?? 0) * zIndexGroup;
              let aField = a?.position ?? 0;
              let bField = b?.position ?? 0;

              let a1 = aGroup + aField;
              let b1 = bGroup + bField;

              return a1 - b1;
            });

            dynamicProperties
              .filter(
                (s) =>
                  s?.dynamicPropertyGroup?.data != undefined &&
                  s?.entityTypes?.find(
                    (et) =>
                      et.toLowerCase() == dynamicPropertyKey.toLowerCase(),
                  ) != undefined,
              )
              .forEach((f) => {
                let dataType = undefined;
                let format = undefined;
                let defaultValue = undefined;

                //sections représente la position d'un groupe
                let sectionName =
                  f.dynamicPropertyGroup.data.dynamicPropertyLocation;

                let section = sections.find((f) => f.name == sectionName);
                if (section == undefined) {
                  let location:
                    | 'before'
                    | 'center'
                    | 'after'
                    | 'full'
                    | 'right'
                    | 'left' = 'full';
                  switch (sectionName) {
                    case DynamicPropertyLocationEnum.After:
                      location = 'after';
                      break;
                    case DynamicPropertyLocationEnum.Before:
                      location = 'before';
                      break;
                    case DynamicPropertyLocationEnum.Center:
                      location = 'center';
                      break;
                    case DynamicPropertyLocationEnum.Full:
                      location = 'full';
                      break;
                  }
                  //Cré la section
                  section = {
                    section: {
                      groups: [],
                      location: location,
                    },
                    name: sectionName,
                  };

                  sections.push(section);
                }

                // Groupes
                let groupeName = f.dynamicPropertyGroup.data.name[languague];
                let group = section.section.groups.find(
                  (s) => s.name == groupeName,
                );
                if (group == undefined) {
                  group = {
                    name: groupeName,
                    label: groupeName,
                    controls: [],
                    expanded:
                      f.dynamicPropertyGroup.data.collapse != undefined
                        ? !f.dynamicPropertyGroup.data.collapse
                        : true,
                    dependsOn: undefined,
                  };

                  section.section.groups.push(group);
                }

                // Composant
                let control: Control = {
                  fieldName: 'dynamicProperties.' + f.propertyName,
                  label: f.name[languague],
                };
                if (f?.dynamicPropertyFormOption?.required === true) {
                  control.validators = [
                    {
                      name: 'required',
                    },
                  ];
                }
                control.visible = f?.dynamicPropertyFormOption?.visible ?? true;
                control.isDynamicProperty = true;
                switch (f.dynamicPropertyFieldType) {
                  case DynamicPropertyFieldEnum.Email:
                    control.type = 'TextBoxComponent';
                    control.options = {
                      showMailToButton: true,
                    };
                    break;
                  case DynamicPropertyFieldEnum.Html:
                    control.type = 'HtmlEditorComponent';
                    break;
                  case DynamicPropertyFieldEnum.Link:
                    control.type = 'TextBoxComponent';
                    control.options = {
                      showLinkButton: true,
                    };
                    break;
                  case DynamicPropertyFieldEnum.Numeric:
                    control.type = 'NumberBoxComponent';
                    let min = f.dynamicPropertyNumericFormat?.minimun;
                    let max = f.dynamicPropertyNumericFormat?.maximun;

                    if (
                      f.dynamicPropertyNumericFormat
                        ?.dynamicPropertyNumericFormat ==
                      DynamicPropertyNumericFormat.Decimal
                    ) {
                      format = '#,##0.00';
                    } else {
                      //format = '#';
                    }

                    let defaultValue = min ?? 0;
                    if (f?.dynamicPropertyFormOption?.required === true) {
                      control.validators = [
                        {
                          name: 'notNullOrEmpty',
                        },
                      ];
                      defaultValue = undefined;
                    }
                    control.options = {
                      format: format,
                      defaultValue: defaultValue,
                      min: min,
                      max: max,
                    };
                    break;
                  case DynamicPropertyFieldEnum.DateTime:
                    control.type = 'DateTimeComponent';
                    let displayFormat = 'shortDateShortTime';
                    dataType = 'datetime';
                    switch (
                      f.dynamicPropertyDateTime.dynamicPropertyDateFormat
                    ) {
                      case DynamicPropertyDateFormat.LongDate:
                        displayFormat = 'shortDateShortTime';
                        dataType = 'datetime';
                        break;
                      case DynamicPropertyDateFormat.ShortDate:
                        displayFormat = 'shortDate';
                        dataType = 'date';
                        break;
                      case DynamicPropertyDateFormat.Time:
                        displayFormat = 'shortTime';
                        dataType = 'time';
                        break;
                    }
                    control.options = {
                      dateSerializationFormat: undefined,
                      type: dataType,
                      displayFormat: displayFormat,
                    };

                    break;
                  case DynamicPropertyFieldEnum.Bool:
                    control.type = 'CheckBoxComponent';
                    dataType = 'boolean';
                    if (f?.dynamicPropertyFormOption?.required === true) {
                      control.validators = [
                        {
                          name: 'requiredTrue',
                        },
                      ];
                    }
                    break;
                  case DynamicPropertyFieldEnum.String:
                    control.type = 'TextBoxComponent';

                    break;
                  case DynamicPropertyFieldEnum.Image:
                    if (fn.serviceName != undefined) {
                      control.type = 'FileUploaderComponent';
                      control.options = {
                        dynamicFieldName: control.fieldName,
                        source:
                          "[source('" + fn.serviceName + "','findFiles')]",
                        addItems:
                          "[call('" +
                          fn.serviceName +
                          "','addFileDynamicField')]",
                        removeItems:
                          "[call('" +
                          fn.serviceName +
                          "','removeFileDynamicField')]",
                      };
                    }
                    break;

                  case DynamicPropertyFieldEnum.SelectList:
                    control.type = 'LinkComponent';

                    let displayLang =
                      this.translateFieldHelperService.getTranslateKey();
                    let columns = [];
                    this.translateFieldHelperService
                      .getLanguageDatasource()
                      .forEach((f) => {
                        let sort = displayLang == f.key ? 0 : undefined;
                        columns.push({
                          label: f.value,
                          field: f.key,
                          sortIndex: sort,
                          sortOrder: sort != undefined ? 'asc' : undefined,
                          visible: sort != undefined,
                        });
                      });
                    control.options = {
                      valueExpr: 'id',
                      displayExpr: displayLang,
                      dynamicListId:
                        f.dynamicPropertySelectList.dynamicPropertyListId,
                      source:
                        "[coreSource('DynamicPropertyListCoreService','findDynamicListValue')]",

                      columns: columns,
                    };
                    break;
                }

                // Ajout du controle
                if (control?.type != undefined && control.visible === true) {
                  group.controls.push(control);
                }
              });

            if (sections != undefined) {
              let counterFn = () => {
                // // Vérification si au moins un section en Full ou en before
                let before = sections.filter(
                  (s) => s.section.location == 'before',
                );
                let center = sections.filter(
                  (s) => s.section.location == 'center',
                );
                let after = sections.filter(
                  (s) => s.section.location == 'after',
                );
                let full = sections.filter((s) => s.section.location == 'full');
                return {
                  before: before,
                  center: center,
                  after: after,
                  full: full,
                };
              };

              let counter = counterFn();

              // Si pas de before mais un center ou after
              if (counter.before.length == 0) {
                if (counter.center.length > 0) {
                  counter.center.forEach((f) => {
                    if (counter.after.length == 0) {
                      f.section.location = 'full';
                    } else {
                      f.section.location = 'before';
                    }
                  });
                } else if (counter.after.length > 0) {
                  counter.after.forEach((f) => {
                    f.section.location = 'full';
                  });
                }
              } else {
                if (counter.center.length == 0 && counter.after.length == 0) {
                  counter.before.forEach((f) => {
                    f.section.location = 'full';
                  });
                }
              }

              counter = counterFn();
              if (
                counter.center.length > 0 &&
                counter.after.length == 0 &&
                counter.full.length == 0
              ) {
                counter.center.forEach((f) => {
                  f.section.location = 'after';
                });
              }

              // Ordonne
              sections
                .filter((s) => s.name == DynamicPropertyLocationEnum.Before)
                .forEach((f) => {
                  finalSections.push(f);
                });
              sections
                .filter((s) => s.name == DynamicPropertyLocationEnum.Center)
                .forEach((f) => {
                  finalSections.push(f);
                });
              sections
                .filter((s) => s.name == DynamicPropertyLocationEnum.After)
                .forEach((f) => {
                  finalSections.push(f);
                });
              sections
                .filter((s) => s.name == DynamicPropertyLocationEnum.Full)
                .forEach((f) => {
                  finalSections.push(f);
                });
            }
          }
        }
      }

      // Ajout des section
      finalSections.forEach((s) => {
        dynamicPage.sections.push(s.section);
      });

      if (dynamicPage.sections.length > 0) {
        // Placement de la section
        switch (dynamicPageField.location) {
          case 'first':
            copyModel.form.layout.pages.splice(0, 0, dynamicPage);
            break;
          case 'last':
            copyModel.form.layout.pages.push(dynamicPage);
            break;
          case 'before':
            let indexBefore = copyModel.form.layout.pages.findIndex(
              (s) =>
                s.name == dynamicPageField.pageIndexReference ||
                s.label == dynamicPageField.pageIndexReference,
            );
            if (indexBefore != undefined) {
              if (indexBefore > 1) indexBefore--;
              copyModel.form.layout.pages.splice(indexBefore, 0, dynamicPage);
            } else {
              console.error("Can't find relative position for dynamic field");
            }
            break;
          case 'after':
            let indexAfter = copyModel.form.layout.pages.findIndex(
              (s) =>
                s.name == dynamicPageField.pageIndexReference ||
                s.label == dynamicPageField.pageIndexReference,
            );
            if (indexAfter != undefined) {
              copyModel.form.layout.pages.splice(indexAfter, 0, dynamicPage);
            } else {
              console.error("Can't find relative position for dynamic field");
            }
            break;
          case 'static':
            let index = dynamicPageField.pageIndexReference as number;
            if (index == undefined) index = 2;
            if (index > 0) {
              index--;
            }

            copyModel.form.layout.pages.splice(index, 0, dynamicPage);
            break;
        }
      }
    }
    return copyModel;
  }
}
