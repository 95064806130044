import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLogicalDiskHistoriesBaseVariables, FirstLogicalDiskHistoriesBaseVariables, CountLogicalDiskHistoriesBaseVariables, FindLogicalDiskHistoriesBaseVariables, SearchLogicalDiskHistoriesBaseVariables, ExportSchemaLogicalDiskHistoriesBaseVariables, ExportDataLogicalDiskHistoriesBaseVariables, CustomQueryLogicalDiskHistoriesBaseVariables, CustomQueryIdLogicalDiskHistoriesBaseVariables, UsedLogicalDiskHistoriesBaseVariables, ExistLogicalDiskHistoriesBaseVariables } from '../gqls'
import { GetLogicalDiskHistoriesDocument, FirstLogicalDiskHistoriesDocument, CountLogicalDiskHistoriesDocument, FindLogicalDiskHistoriesDocument, SearchLogicalDiskHistoriesDocument, ExportSchemaLogicalDiskHistoriesDocument, ExportDataLogicalDiskHistoriesDocument, CustomQueryLogicalDiskHistoriesDocument, CustomQueryIdLogicalDiskHistoriesDocument, UsedLogicalDiskHistoriesDocument, ExistLogicalDiskHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfLogicalDiskHistory, QueryContextOfLogicalDiskHistory, FilterOfLogicalDiskHistory, ServiceSingleResultOfInt64, ServiceListResultOfLogicalDiskHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LogicalDiskHistoryBaseService {
    
public modelName = 'logicalDiskHistory';
public modelPluralName = 'logicalDiskHistories';

	private getLogicalDiskHistoriesQuery: GetLogicalDiskHistoriesDocument;
	private firstLogicalDiskHistoriesQuery: FirstLogicalDiskHistoriesDocument;
	private countLogicalDiskHistoriesQuery: CountLogicalDiskHistoriesDocument;
	private findLogicalDiskHistoriesQuery: FindLogicalDiskHistoriesDocument;
	private searchLogicalDiskHistoriesQuery: SearchLogicalDiskHistoriesDocument;
	private exportSchemaLogicalDiskHistoriesQuery: ExportSchemaLogicalDiskHistoriesDocument;
	private exportDataLogicalDiskHistoriesQuery: ExportDataLogicalDiskHistoriesDocument;
	private customQueryLogicalDiskHistoriesQuery: CustomQueryLogicalDiskHistoriesDocument;
	private customQueryIdLogicalDiskHistoriesQuery: CustomQueryIdLogicalDiskHistoriesDocument;
	private usedLogicalDiskHistoriesQuery: UsedLogicalDiskHistoriesDocument;
	private existLogicalDiskHistoriesQuery: ExistLogicalDiskHistoriesDocument;

	constructor(private injector: Injector) {
		this.getLogicalDiskHistoriesQuery = this.injector.get(GetLogicalDiskHistoriesDocument);
		this.firstLogicalDiskHistoriesQuery = this.injector.get(FirstLogicalDiskHistoriesDocument);
		this.countLogicalDiskHistoriesQuery = this.injector.get(CountLogicalDiskHistoriesDocument);
		this.findLogicalDiskHistoriesQuery = this.injector.get(FindLogicalDiskHistoriesDocument);
		this.searchLogicalDiskHistoriesQuery = this.injector.get(SearchLogicalDiskHistoriesDocument);
		this.exportSchemaLogicalDiskHistoriesQuery = this.injector.get(ExportSchemaLogicalDiskHistoriesDocument);
		this.exportDataLogicalDiskHistoriesQuery = this.injector.get(ExportDataLogicalDiskHistoriesDocument);
		this.customQueryLogicalDiskHistoriesQuery = this.injector.get(CustomQueryLogicalDiskHistoriesDocument);
		this.customQueryIdLogicalDiskHistoriesQuery = this.injector.get(CustomQueryIdLogicalDiskHistoriesDocument);
		this.usedLogicalDiskHistoriesQuery = this.injector.get(UsedLogicalDiskHistoriesDocument);
		this.existLogicalDiskHistoriesQuery = this.injector.get(ExistLogicalDiskHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogicalDiskHistory> {
    
    		let variables: GetLogicalDiskHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.get));
            }
            else{
                let result:ServiceSingleResultOfLogicalDiskHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogicalDiskHistory,
		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLogicalDiskHistory> {
    
    		let variables: FirstLogicalDiskHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLogicalDiskHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLogicalDiskHistory,
		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDiskHistory> {
    
    		let variables: FindLogicalDiskHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLogicalDiskHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDiskHistory> {
    
    		let variables: SearchLogicalDiskHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLogicalDiskHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLogicalDiskHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLogicalDiskHistory,
		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDiskHistory> {
    
    		let variables: CustomQueryLogicalDiskHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLogicalDiskHistory,
		@Args('filter?') filter?: FilterOfLogicalDiskHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLogicalDiskHistory> {
    
    		let variables: CustomQueryIdLogicalDiskHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLogicalDiskHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLogicalDiskHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLogicalDiskHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.logicalDiskHistories.exist));
    	}
    
    
}