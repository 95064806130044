import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUserProfilesBaseVariables, FirstUserProfilesBaseVariables, CountUserProfilesBaseVariables, FindUserProfilesBaseVariables, SearchUserProfilesBaseVariables, ExportSchemaUserProfilesBaseVariables, ExportDataUserProfilesBaseVariables, CustomQueryUserProfilesBaseVariables, CustomQueryIdUserProfilesBaseVariables, UsedUserProfilesBaseVariables, ExistUserProfilesBaseVariables } from '../gqls'
import { GetUserProfilesDocument, FirstUserProfilesDocument, CountUserProfilesDocument, FindUserProfilesDocument, SearchUserProfilesDocument, ExportSchemaUserProfilesDocument, ExportDataUserProfilesDocument, CustomQueryUserProfilesDocument, CustomQueryIdUserProfilesDocument, UsedUserProfilesDocument, ExistUserProfilesDocument } from '../gqls'
import { ServiceSingleResultOfUserProfile, QueryContextOfUserProfile, FilterOfUserProfile, ServiceSingleResultOfInt64, ServiceListResultOfUserProfile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UserProfileBaseService {
    
public modelName = 'userProfile';
public modelPluralName = 'userProfiles';

	private getUserProfilesQuery: GetUserProfilesDocument;
	private firstUserProfilesQuery: FirstUserProfilesDocument;
	private countUserProfilesQuery: CountUserProfilesDocument;
	private findUserProfilesQuery: FindUserProfilesDocument;
	private searchUserProfilesQuery: SearchUserProfilesDocument;
	private exportSchemaUserProfilesQuery: ExportSchemaUserProfilesDocument;
	private exportDataUserProfilesQuery: ExportDataUserProfilesDocument;
	private customQueryUserProfilesQuery: CustomQueryUserProfilesDocument;
	private customQueryIdUserProfilesQuery: CustomQueryIdUserProfilesDocument;
	private usedUserProfilesQuery: UsedUserProfilesDocument;
	private existUserProfilesQuery: ExistUserProfilesDocument;

	constructor(private injector: Injector) {
		this.getUserProfilesQuery = this.injector.get(GetUserProfilesDocument);
		this.firstUserProfilesQuery = this.injector.get(FirstUserProfilesDocument);
		this.countUserProfilesQuery = this.injector.get(CountUserProfilesDocument);
		this.findUserProfilesQuery = this.injector.get(FindUserProfilesDocument);
		this.searchUserProfilesQuery = this.injector.get(SearchUserProfilesDocument);
		this.exportSchemaUserProfilesQuery = this.injector.get(ExportSchemaUserProfilesDocument);
		this.exportDataUserProfilesQuery = this.injector.get(ExportDataUserProfilesDocument);
		this.customQueryUserProfilesQuery = this.injector.get(CustomQueryUserProfilesDocument);
		this.customQueryIdUserProfilesQuery = this.injector.get(CustomQueryIdUserProfilesDocument);
		this.usedUserProfilesQuery = this.injector.get(UsedUserProfilesDocument);
		this.existUserProfilesQuery = this.injector.get(ExistUserProfilesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserProfile> {
    
    		let variables: GetUserProfilesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.get));
            }
            else{
                let result:ServiceSingleResultOfUserProfile={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserProfile,
		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUserProfile> {
    
    		let variables: FirstUserProfilesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUserProfilesBaseVariables = {
    			filter: filter
    		}
    				return this.countUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUserProfile,
		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserProfile> {
    
    		let variables: FindUserProfilesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUserProfile,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserProfile> {
    
    		let variables: SearchUserProfilesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUserProfilesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUserProfilesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUserProfile,
		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserProfile> {
    
    		let variables: CustomQueryUserProfilesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUserProfile,
		@Args('filter?') filter?: FilterOfUserProfile,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUserProfile> {
    
    		let variables: CustomQueryIdUserProfilesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUserProfilesBaseVariables = {
    			ids: ids
    		}
    				return this.usedUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUserProfilesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUserProfilesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.userProfiles.exist));
    	}
    
    
}