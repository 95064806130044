import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { GC, GCFactory, ImportFileFormat } from '@clarilog/core';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { DxFileUploaderComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { Column } from '@clarilog/shared2/models';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  AssetCoreService,
  CommandCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';

@Component({
  selector: 'clc-select-remote-popup',
  templateUrl: './select-remote-popup.component.html',
})
export class CoreSelectRemotePopupComponent implements OnInit {
  visible: boolean = false;
  /** Definit les colonne  */
  commandColumns: Column[];
  selectCommandSource;
  @Input() assetId: string;
  selectCommandId: string;
  disabledBtnCommand: boolean = true;
  waitRemoteControl: boolean = false;
  gc: GC;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private commandService: CommandCoreService,
    private translateFieldHelperService: TranslatedFieldHelperService,
    private gcFactory: GCFactory,
    private assetService: AssetCoreService,
  ) {
    this.gc = gcFactory.create();
    this.commandColumns = [
      {
        label: TranslateService.get('entities/command/name'),
        field: this.getDisplayExpr(),
      },
    ];
    this.setDataSource();
  }

  ngOnInit() {}

  getDisplayExpr() {
    return this.translateFieldHelperService.setColumnTranslateField('name');
  }

  setDataSource() {
    let commandContext = new ModelDataSourceContext({
      serviceName: 'CommandCoreService',
      methodName: 'findByAsset',
    });
    let source = CoreGraphQLSource.create({
      context: commandContext,
      query: (filters?: any, options?: any) => {
        let filter = {};
        if (filters != undefined) {
          filter = CriteriaHelpers.convertDxFilter(filters);
        }

        return this.commandService.findByAsset(
          [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlSubField.create(
                'name',
                this.translateFieldHelperService.translatedFields(),
              ),
            ]),
            GqlField.create('totalCount'),
          ],
          this.assetId,
          options,
          filter,
        );
      },
    });
    commandContext.datasource = source;
    this.selectCommandSource = commandContext.datasource;
  }

  changeSelectCommand(e) {
    this.selectCommandId = e;
    if (e == null) {
      this.disabledBtnCommand = true;
      this.waitRemoteControl = false;
    } else {
      this.disabledBtnCommand = false;
    }
  }

  executeCommand(e) {
    this.disabledBtnCommand = true;
    this.waitRemoteControl = true;
    this.gc.forDispose(
      this.commandService
        .executeCommand(
          this.commandService.fields(),
          this.selectCommandId,
          this.assetId,
        )
        .subscribe((result) => {
          if (result.data.category == 'REMOTE_CONTROL') {
            let bin = atob(result.data.dataCommand);
            const contentType = 'application/x-msdownload';
            const byteNumbers = new Array(bin.length);
            for (let i = 0; i < bin.length; i++) {
              byteNumbers[i] = bin.charCodeAt(i);
            }
            const byteArray = new Uint8Array(byteNumbers);
            let blob = new Blob([byteArray], { type: contentType });
            const element = document.createElement('a');
            element.href = URL.createObjectURL(blob);
            let resultName = this.translateFieldHelperService.findValueToShow(
              result.data.name,
            );
            element.download = resultName + '.exe';
            document.body.appendChild(element);
            element.click();
          } else {
            window.open(result.data.dataCommand, '_blank');
          }
          this.disabledBtnCommand = false;
          this.visible = false;
          this.waitRemoteControl = false;
        }),
    );
  }

  manageRemoteControl(assetId: string) {
    if (assetId !== undefined && assetId !== null) {
      this.assetId = assetId;
    }

    if (this.assetId !== undefined && this.assetId !== null) {
      this.assetService
        .getAutomaticRemoteCommand(this.assetId)
        .subscribe((response) => {
          let defaultCommandId = response.defaultCommandId;
          this.disabledBtnCommand = response.disabledButton;
          this.selectCommandId = defaultCommandId;

          if (response.autoLaunchCommand) {
            this.executeCommand(null);
          } else {
            this.open();
          }
        });
    } else {
      this.open();
    }
  }

  public open() {
    this.visible = true;
  }
  public close() {
    this.visible = false;
  }
}
