<div class="link-list-component">
  <cl-toolbar class="action">
    <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
      <cl-toolbar-item-dropdown-button
        *clcHasPolicies="policies + '.write'"
        [text]="'add' | translate"
        icon="far fa-link"
        [source]="newCommandSource"
        [keyExpr]="'key'"
        [displayExpr]="'name'"
        [visible]="mode == CartListMode.Current && canAdd"
        (onItemClick)="showPopUp($event)"
      ></cl-toolbar-item-dropdown-button>
      <cl-toolbar-item-button
        [text]="'update' | translate"
        icon="far fa-unlink"
        [disabled]="selectedKeys.length !== 1 || disableEdit"
        (onClick)="update($event)"
        [visible]="mode == CartListMode.Current && !disableEdit && canEdit"
      ></cl-toolbar-item-button>
      <cl-toolbar-item-button
        [text]="'manage' | translate"
        [visible]="route !== undefined"
        (onClick)="onGoTo()"
        icon="fas fa-arrow-right"
      >
      </cl-toolbar-item-button>
      <cl-toolbar-item-button
        *clcHasPolicies="policies + '.delete'"
        [text]="'remove' | translate"
        icon="far fa-unlink"
        (onClick)="dissociate($event)"
        [visible]="mode == CartListMode.Current && canDelete"
      ></cl-toolbar-item-button>
      <cl-toolbar-item-button
        [text]="'cancel' | translate"
        icon="far fa-unlink"
        [disabled]="selectedKeys.length === 0"
        (onClick)="cancelList($event)"
        [visible]="mode == CartListMode.Removed"
      ></cl-toolbar-item-button>
    </cl-toolbar-items>
  </cl-toolbar>

  <mat-tab-nav-panel #tabPanelCart></mat-tab-nav-panel>
  <nav
    mat-tab-nav-bar
    [tabPanel]="tabPanelCart"
    mat-stretch-tabs="false"
    class="tab-nav-bar"
  >
    <a
      mat-tab-link
      (click)="mode = CartListMode.Current; current($event)"
      [active]="mode == CartListMode.Current"
      >{{ 'current-data' | translate }}</a
    >
    <a
      mat-tab-link
      (click)="mode = CartListMode.Removed; removedList($event)"
      *ngIf="removeValues.length > 0"
      [active]="mode == CartListMode.Removed"
    >
      <span [matBadge]="removeValues.length" matBadgeOverlap="false">{{
        'remove-data' | translate : { count: removeValues.length }
      }}</span></a
    >
  </nav>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.1)"
    [visible]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  ></dx-load-panel>
  <clc-list
    class="list"
    [canSelect]="true"
    [source]="source"
    [type]="type == undefined ? 'Grid' : type"
    [columns]="columns"
    [multiple]="multiple"
    [keyExpr]="keyExpr"
    [parentIdExpr]="parentIdExpr"
    [recursive]="recursive"
    [(selectedKeys)]="selectedKeys"
    [(selectedData)]="selectedData"
  >
  </clc-list>
</div>

<clc-cart-list-select
  #selectList
  [rootForm]="rootForm"
  [inDefaultProperty]="inDefaultProperty"
  [inputModel]="inCartModel"
  [movementType]="movementType"
  [label]="label"
  [columns]="columns"
  (onClosed)="associatePopup = false; close($event)"
  (onAdded)="objectPass($event)"
  [(isVisible)]="associatePopup"
></clc-cart-list-select>
