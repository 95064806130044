import { Component, HostBinding, Input, OnInit } from "@angular/core";

@Component({
  selector: "cl-work-page-left",
  templateUrl: "./work-page-left.component.html",
  styleUrls: ["./work-page-left.component.scss"]
})
export class WorkPageLeftComponent implements OnInit {
  constructor() {}
  @Input()
  @HostBinding("class.hide")
  hide: boolean = false;

  ngOnInit() {}
}
