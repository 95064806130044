import { WrapperStore } from '@clarilog/core/services2/graphql/graphql-store-options';
import { debug } from 'console';
import { filter } from 'devexpress-dashboard/model/index.metadata';
import CustomStore, { CustomStoreOptions } from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { wrap } from 'module';
import { Subject } from 'rxjs';
import { CorePooling } from '../pooling';
import { CriteriaHelpers } from './criteria-helpers';
import { ServiceListResult } from './generated-types/types';
import { GraphQLStoreConfig, IQueryableStore } from './graphql-store-options';
import { v4 as uuidv4 } from 'uuid';
import { HttpClient } from '@angular/common/http';
import { AuthorizationCoreService } from '@clarilog/core/services2';
import { EnvironmentService } from '@clarilog/core';

/** Class de gestion du datasource */
export class CoreGraphQLDataSource extends DataSource {
  private operationId = null;
  public parentFilterSource;

  constructor(obj: CustomStore);
  constructor(obj: CustomStoreOptions | DataSourceOptions);
  constructor(obj: string);
  constructor(obj: any) {
    super(obj);
    this.on('changed', this.onLoaded);
  }

  private onLoaded() {
    this.operationId = uuidv4();
  }

  /** Permet de stopper l'ecoute de promise si une est déjà en cours */
  public load(loadOption: LoadOptions = null): Promise<any> {
    if (this.operationId != undefined) {
      let cancelFn = super.cancel.bind(this);

      cancelFn(this.operationId);
      this.operationId = null;
    }
    let tmp = super.load();
    this.operationId = (tmp as any).operationId;
    return tmp;
  }
}

/**
 * Permet de générer un DataSource entre GraphQL et DeveExtreme.
 */
export class CoreGraphQLSource {
  /**
   * Permet de construire le dataSource.
   * @param options Les options de configuration.
   */
  static create(
    options: GraphQLStoreConfig,
    pooling: CorePooling = null,
    postProcess: any = null,
    onChanged: any = null,
  ): DataSource {
    return new CoreGraphQLDataSource({
      //    key:options.key || "id",
      store: GraphQLStore.create(options, pooling),
      postProcess: postProcess,
      onChanged: onChanged,
    });
  }
  /**
   * Permet de construire le store Devextreme.
   * @param options Les options de configuration.
   */
}
export class GraphQLStore extends CustomStore {
  public context: WrapperStore;

  /**
   * Permet de construire le store Devextreme.
   * @param options Les options de configuration.
   */
  public static create(
    options: GraphQLStoreConfig,
    pooling: CorePooling,
  ): GraphQLStore {
    const query = new CoreGraphQLQuery(options, pooling);
    const wrapper = new WrapperStore(query);
    const store = new GraphQLStore(wrapper);
    // Pour resoudre le problème devextreme sur le context d'execution
    query.store = store;
    return store;
  }

  constructor(wrapper: WrapperStore) {
    super(wrapper);
    this.context = wrapper;
  }
}
/**
 * Représente la configuration du query du Store DevExtreme.
 */
export class CoreGraphQLQuery implements IQueryableStore {
  errors: Subject<any>;
  cache: any;
  tmp: any;
  key: string;
  loadMode: 'processed' | 'raw';
  public config: GraphQLStoreConfig;
  pooling: CorePooling;
  store: CustomStore;
  hasTimeout: boolean = false;
  timers: NodeJS.Timeout[] = [];
  interval: number = 2000;
  constructor(config: GraphQLStoreConfig, pooling: CorePooling = null) {
    this.key = config.key || 'id';
    this.loadMode = 'processed';
    this.config = config;
    this.pooling = pooling;
  }

  byKey(key: string): Promise<any> {
    if (typeof key != 'string' || key === '') return undefined;
    //let queryFilters: QueryFilterInputType[] = CriteriaHelpers.compile(['id', '=', key]);
    let args: any[] = [
      ...(this.config.parameters != undefined &&
      this.config.parameters.length > 0
        ? this.config.parameters
        : []),
      [{ name: 'id', operation: '=', value: key }],
      undefined,
    ];
    let result = this.config.query
      .apply(this.config.query, args)
      .toPromise()
      .then((result) => {
        return result.data && result.data[0];
      });
    return result;
  }

  timeout2(n) {
    return
  }

  async sendMetricLog(filter, option, time, ) {


    await new Promise(cb => {
      // console.log("========")
      // console.log("low perf request : ", time)
      // console.log("filter", JSON.stringify(filter))
      // console.log("options", JSON.stringify(option))
      // console.log("datasource service", this?.config?.context?.serviceName)
      // console.log("datasource func", this?.config?.context?.methodeName)
      // console.log("origin", window.origin)
      // console.log("origin", this.config?.context?.rootState)
      // console.log("origin", this.config?.context?.context)
      // console.log("sub", authService.user.getClaim('userId'))

      if (this.config?.context?.rootState) {
        let inject = this.config?.context?.rootState?.injector;

        let httpclient = inject.get(HttpClient);
        let authService = inject.get(AuthorizationCoreService);
        let envService = inject.get(EnvironmentService);

        let myPayload = {
          time : time,
          filter : JSON.stringify(filter),
          options : JSON.stringify(option),
          service : this?.config?.context?.serviceName,
          method : this?.config?.context?.methodeName,
          origin : window.origin,
          href : window?.location?.href,
          subId : authService.user.getClaim('userId'),
          fields: null,
          params: null
        }

        let params = JSON.parse(JSON.stringify(this?.config?.context?.context?.params?.all()));

        if (Object.keys(params).includes("fields")) {
          myPayload['fields'] = JSON.stringify(params.fields);
          delete params['fields'];
        }

        if (Object.keys(params).includes("filter")) {
          delete params['filter'];
        }

        if (Object.keys(params).includes("options")) {
          delete params['options'];
        }

        myPayload.params = JSON.stringify(params);

        httpclient.post(envService.apiURL + '/client-metric/add', myPayload).subscribe(res => {
        })
      }
    })

  }

  /**
   * Permet de charger les données
   * @param loadOptions Les options du chargement des données.
   */
  load(loadOptions: LoadOptions): Promise<ServiceListResult<any>> {
    try {
      // Pour le tri
      let sort: any[] = loadOptions.sort ? (loadOptions.sort as any[]) : [];
      // Pour le filtre
      let filter;

      // Force le search Value si présent
      if (
        this.config != undefined &&
        this.config.options != undefined &&
        this.config.options.searchValue != undefined
      ) {
        if (loadOptions != undefined && loadOptions.searchValue == undefined) {
          loadOptions.searchValue = this.config.options.searchValue;
        }
      }

      if (this.config.filters != undefined && this.config.filters.length > 0) {
        if (loadOptions.filter != undefined) {
          filter = [...this.config.filters, 'and', [...loadOptions.filter]];
        } else {
          filter = [...this.config.filters];
        }
      } else {
        filter = loadOptions.filter;
      }
      if (
        loadOptions.searchValue != undefined &&
        loadOptions.searchExpr != undefined
      ) {
        if (filter != undefined) {
          filter = [
            ...(filter != undefined ? filter : []),
            'and',
            [
              [
                loadOptions.searchExpr,
                loadOptions.searchOperation,
                loadOptions.searchValue,
              ],
            ],
          ];
        } else {
          filter = [
            [
              [
                loadOptions.searchExpr,
                loadOptions.searchOperation,
                loadOptions.searchValue,
              ],
            ],
          ];
        }
      }
      // Sauvegarde des options de requête
      this.config.options = loadOptions;
      let queryFilters: any = filter;
      // let queryFilters: any[] = filter
      //   ? CriteriaHelpers.compile(filter)
      //   : [];
      // Construction du queryOptions
      let queryOptions: any = {
        // TODO RECAST to type
        sort: CriteriaHelpers.convertSortQuerySortType(sort),
        skip: loadOptions.skip,
        limit: loadOptions.take,
        textSearch: loadOptions.searchValue,
      };

      const args: any[] = [
        ...(this.config.parameters !== undefined &&
        this.config.parameters.length > 0
          ? this.config.parameters
          : []),
        queryFilters,
        queryOptions,
        this.config.manyToManyId,
      ];
      if (this.config?.context?.context != undefined) {
        this.config.context.context.params.set('options', () => queryOptions);
      }

      queryFilters = CriteriaHelpers.convertDxFilter(queryFilters);
      if (
        // queryFilters != undefined &&
        this.config?.context?.context != undefined
      ) {
        this.config.context.context.params.set('filter', () => queryFilters);
      }
      let result = this.config.query.apply(this.config.query, args);
      // Probleme lorsqu'il faut affciher le dropdow dans les logiciel /Bibliotheque
      if (Array.isArray(result)) {
        return new Promise((resolve, reject) => {
          resolve(result);
        });
      }
      // result.pipe()
      if (result.toPromise != undefined) {
        result = result.toPromise();
      }
      let startTime = Date.now();
      result.then((res) => {
        let endTime = Date.now();

        let diff = (endTime - startTime);

        if (diff > 1000) {
          this.sendMetricLog(queryFilters, queryOptions, diff);
        }

        if (
          this.hasTimeout === true &&
          res.data !== undefined &&
          Array.isArray(res.data)
        ) {
          this.clearTimeout();
          for (let elem of res.data) {
            this.timers.push(
              this.pooling.poolingById(
                this.store,
                elem.id,
                this.config.poolingOptions.query,
                this.interval,
              ),
            );
          }
        }

        if (res != undefined && res.totalCount == undefined) {
          res.totalCount = -1;
        }

        return res;
      });
      result.catch((err) => {
        return result;
        // this.errors.next(err);
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  }
  /** @inheritdoc */
  insert(values: any): Promise<any> {
    if (this.config.insert == undefined) {
      throw new Error(
        `Le paramètre 'insert' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [values];

    let result = this.config.insert.apply(this.config.insert, args).toPromise();
    return result;
  }
  /** @inheritdoc */
  remove(key: any): Promise<any> {
    if (this.config.delete == undefined) {
      throw new Error(
        `Le paramètre 'delete' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [key];
    let result = this.config.delete.apply(this.config.delete, args).toPromise();
    return result;
  }
  /** @inheritdoc */
  update(key: any, values: any): Promise<any> {
    if (this.config.update == undefined) {
      throw new Error(
        `Le paramètre 'update' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [key, values];
    let result = this.config.update.apply(this.config.update, args).toPromise();
    return result;
  }

  clearTimeout(): void {
    for (let timer of this.timers) {
      clearTimeout(timer);
    }
  }
  timeout(value: boolean, interval: number = 2000) {
    this.hasTimeout = value;
    this.interval = interval;
  }
}
