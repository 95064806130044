import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLabelTemplatesBaseVariables, FirstLabelTemplatesBaseVariables, CountLabelTemplatesBaseVariables, FindLabelTemplatesBaseVariables, SearchLabelTemplatesBaseVariables, ExportSchemaLabelTemplatesBaseVariables, ExportDataLabelTemplatesBaseVariables, CustomQueryLabelTemplatesBaseVariables, CustomQueryIdLabelTemplatesBaseVariables, FindFilesLabelTemplatesBaseVariables, UsedLabelTemplatesBaseVariables, ExistLabelTemplatesBaseVariables, FindRecyclesLabelTemplatesBaseVariables, CountRecyclesLabelTemplatesBaseVariables, ReadOnlyLabelTemplatesBaseVariables, FindArchivedLabelTemplatesBaseVariables, CountAllLabelTemplatesBaseVariables, FindDynamicPropertyFieldsLabelTemplatesBaseVariables, InsertLabelTemplatesBaseVariables, UpdateLabelTemplatesBaseVariables, ImportDataLabelTemplatesBaseVariables, UpdateManyLabelTemplatesBaseVariables, DeleteLabelTemplatesBaseVariables, UploadChunkFileLabelTemplatesBaseVariables, RestoreLabelTemplatesBaseVariables, RecycleLabelTemplatesBaseVariables, RestoreArchivedLabelTemplatesBaseVariables, ArchivedLabelTemplatesBaseVariables, AddFileDynamicFieldLabelTemplatesBaseVariables, RemoveFileDynamicFieldLabelTemplatesBaseVariables, AddFileLinkLabelTemplatesBaseVariables, RemoveFileLinkLabelTemplatesBaseVariables } from '../gqls'
import { GetLabelTemplatesDocument, FirstLabelTemplatesDocument, CountLabelTemplatesDocument, FindLabelTemplatesDocument, SearchLabelTemplatesDocument, ExportSchemaLabelTemplatesDocument, ExportDataLabelTemplatesDocument, CustomQueryLabelTemplatesDocument, CustomQueryIdLabelTemplatesDocument, FindFilesLabelTemplatesDocument, UsedLabelTemplatesDocument, ExistLabelTemplatesDocument, FindRecyclesLabelTemplatesDocument, CountRecyclesLabelTemplatesDocument, ReadOnlyLabelTemplatesDocument, FindArchivedLabelTemplatesDocument, CountAllLabelTemplatesDocument, FindDynamicPropertyFieldsLabelTemplatesDocument, InsertLabelTemplatesDocument, UpdateLabelTemplatesDocument, ImportDataLabelTemplatesDocument, UpdateManyLabelTemplatesDocument, DeleteLabelTemplatesDocument, UploadChunkFileLabelTemplatesDocument, RestoreLabelTemplatesDocument, RecycleLabelTemplatesDocument, RestoreArchivedLabelTemplatesDocument, ArchivedLabelTemplatesDocument, AddFileDynamicFieldLabelTemplatesDocument, RemoveFileDynamicFieldLabelTemplatesDocument, AddFileLinkLabelTemplatesDocument, RemoveFileLinkLabelTemplatesDocument } from '../gqls'
import { ServiceSingleResultOfLabelTemplate, QueryContextOfLabelTemplate, FilterOfLabelTemplate, ServiceSingleResultOfInt64, ServiceListResultOfLabelTemplate, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfLabelTemplate, LabelTemplateInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LabelTemplateBaseService {
    
public modelName = 'labelTemplate';
public modelPluralName = 'labelTemplates';

	private getLabelTemplatesQuery: GetLabelTemplatesDocument;
	private firstLabelTemplatesQuery: FirstLabelTemplatesDocument;
	private countLabelTemplatesQuery: CountLabelTemplatesDocument;
	private findLabelTemplatesQuery: FindLabelTemplatesDocument;
	private searchLabelTemplatesQuery: SearchLabelTemplatesDocument;
	private exportSchemaLabelTemplatesQuery: ExportSchemaLabelTemplatesDocument;
	private exportDataLabelTemplatesQuery: ExportDataLabelTemplatesDocument;
	private customQueryLabelTemplatesQuery: CustomQueryLabelTemplatesDocument;
	private customQueryIdLabelTemplatesQuery: CustomQueryIdLabelTemplatesDocument;
	private findFilesLabelTemplatesQuery: FindFilesLabelTemplatesDocument;
	private usedLabelTemplatesQuery: UsedLabelTemplatesDocument;
	private existLabelTemplatesQuery: ExistLabelTemplatesDocument;
	private findRecyclesLabelTemplatesQuery: FindRecyclesLabelTemplatesDocument;
	private countRecyclesLabelTemplatesQuery: CountRecyclesLabelTemplatesDocument;
	private readOnlyLabelTemplatesQuery: ReadOnlyLabelTemplatesDocument;
	private findArchivedLabelTemplatesQuery: FindArchivedLabelTemplatesDocument;
	private countAllLabelTemplatesQuery: CountAllLabelTemplatesDocument;
	private findDynamicPropertyFieldsLabelTemplatesQuery: FindDynamicPropertyFieldsLabelTemplatesDocument;
	private insertLabelTemplatesMutation: InsertLabelTemplatesDocument;
	private updateLabelTemplatesMutation: UpdateLabelTemplatesDocument;
	private importDataLabelTemplatesMutation: ImportDataLabelTemplatesDocument;
	private updateManyLabelTemplatesMutation: UpdateManyLabelTemplatesDocument;
	private deleteLabelTemplatesMutation: DeleteLabelTemplatesDocument;
	private uploadChunkFileLabelTemplatesMutation: UploadChunkFileLabelTemplatesDocument;
	private restoreLabelTemplatesMutation: RestoreLabelTemplatesDocument;
	private recycleLabelTemplatesMutation: RecycleLabelTemplatesDocument;
	private restoreArchivedLabelTemplatesMutation: RestoreArchivedLabelTemplatesDocument;
	private archivedLabelTemplatesMutation: ArchivedLabelTemplatesDocument;
	private addFileDynamicFieldLabelTemplatesMutation: AddFileDynamicFieldLabelTemplatesDocument;
	private removeFileDynamicFieldLabelTemplatesMutation: RemoveFileDynamicFieldLabelTemplatesDocument;
	private addFileLinkLabelTemplatesMutation: AddFileLinkLabelTemplatesDocument;
	private removeFileLinkLabelTemplatesMutation: RemoveFileLinkLabelTemplatesDocument;

	constructor(private injector: Injector) {
		this.getLabelTemplatesQuery = this.injector.get(GetLabelTemplatesDocument);
		this.firstLabelTemplatesQuery = this.injector.get(FirstLabelTemplatesDocument);
		this.countLabelTemplatesQuery = this.injector.get(CountLabelTemplatesDocument);
		this.findLabelTemplatesQuery = this.injector.get(FindLabelTemplatesDocument);
		this.searchLabelTemplatesQuery = this.injector.get(SearchLabelTemplatesDocument);
		this.exportSchemaLabelTemplatesQuery = this.injector.get(ExportSchemaLabelTemplatesDocument);
		this.exportDataLabelTemplatesQuery = this.injector.get(ExportDataLabelTemplatesDocument);
		this.customQueryLabelTemplatesQuery = this.injector.get(CustomQueryLabelTemplatesDocument);
		this.customQueryIdLabelTemplatesQuery = this.injector.get(CustomQueryIdLabelTemplatesDocument);
		this.findFilesLabelTemplatesQuery = this.injector.get(FindFilesLabelTemplatesDocument);
		this.usedLabelTemplatesQuery = this.injector.get(UsedLabelTemplatesDocument);
		this.existLabelTemplatesQuery = this.injector.get(ExistLabelTemplatesDocument);
		this.findRecyclesLabelTemplatesQuery = this.injector.get(FindRecyclesLabelTemplatesDocument);
		this.countRecyclesLabelTemplatesQuery = this.injector.get(CountRecyclesLabelTemplatesDocument);
		this.readOnlyLabelTemplatesQuery = this.injector.get(ReadOnlyLabelTemplatesDocument);
		this.findArchivedLabelTemplatesQuery = this.injector.get(FindArchivedLabelTemplatesDocument);
		this.countAllLabelTemplatesQuery = this.injector.get(CountAllLabelTemplatesDocument);
		this.findDynamicPropertyFieldsLabelTemplatesQuery = this.injector.get(FindDynamicPropertyFieldsLabelTemplatesDocument);
		this.insertLabelTemplatesMutation = this.injector.get(InsertLabelTemplatesDocument);
		this.updateLabelTemplatesMutation = this.injector.get(UpdateLabelTemplatesDocument);
		this.importDataLabelTemplatesMutation = this.injector.get(ImportDataLabelTemplatesDocument);
		this.updateManyLabelTemplatesMutation = this.injector.get(UpdateManyLabelTemplatesDocument);
		this.deleteLabelTemplatesMutation = this.injector.get(DeleteLabelTemplatesDocument);
		this.uploadChunkFileLabelTemplatesMutation = this.injector.get(UploadChunkFileLabelTemplatesDocument);
		this.restoreLabelTemplatesMutation = this.injector.get(RestoreLabelTemplatesDocument);
		this.recycleLabelTemplatesMutation = this.injector.get(RecycleLabelTemplatesDocument);
		this.restoreArchivedLabelTemplatesMutation = this.injector.get(RestoreArchivedLabelTemplatesDocument);
		this.archivedLabelTemplatesMutation = this.injector.get(ArchivedLabelTemplatesDocument);
		this.addFileDynamicFieldLabelTemplatesMutation = this.injector.get(AddFileDynamicFieldLabelTemplatesDocument);
		this.removeFileDynamicFieldLabelTemplatesMutation = this.injector.get(RemoveFileDynamicFieldLabelTemplatesDocument);
		this.addFileLinkLabelTemplatesMutation = this.injector.get(AddFileLinkLabelTemplatesDocument);
		this.removeFileLinkLabelTemplatesMutation = this.injector.get(RemoveFileLinkLabelTemplatesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelTemplate> {
    
    		let variables: GetLabelTemplatesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.get));
            }
            else{
                let result:ServiceSingleResultOfLabelTemplate={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelTemplate> {
    
    		let variables: FirstLabelTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLabelTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: FindLabelTemplatesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: SearchLabelTemplatesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLabelTemplatesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLabelTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: CustomQueryLabelTemplatesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: CustomQueryIdLabelTemplatesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.customQueryId));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesLabelTemplatesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.findFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLabelTemplatesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: FindRecyclesLabelTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesLabelTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyLabelTemplatesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLabelTemplate,
		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLabelTemplate> {
    
    		let variables: FindArchivedLabelTemplatesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllLabelTemplatesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsLabelTemplatesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsLabelTemplatesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LabelTemplateInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelTemplate> {
    
    		let variables: InsertLabelTemplatesBaseVariables = {
    			entity: entity
    		}
    				return this.insertLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLabelTemplate> {
    
    		let variables: UpdateLabelTemplatesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLabelTemplatesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLabelTemplate,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLabelTemplatesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.delete));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('size') size: number,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileLabelTemplatesBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId
    		}
    				return this.uploadChunkFileLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.uploadChunkFile));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedLabelTemplatesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldLabelTemplatesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldLabelTemplatesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileLinkLabelTemplatesBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.addFileLinkLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.addFileLink));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileLink(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileId') fileId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileLinkLabelTemplatesBaseVariables = {
    			id: id,
			fileId: fileId
    		}
    				return this.removeFileLinkLabelTemplatesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.labelTemplates.removeFileLink));
    	}
    
}