<dx-data-grid
  (onInitialized)="initialized($event)"
  [dataSource]="source"
  [hoverStateEnabled]="true"
  height="100%"
  [showBorders]="false"
  [showRowLines]="true"
  [showColumnLines]="false"
  [columnAutoWidth]="true"
  [columnChooser]="{ enabled: false }"
  [rowAlternationEnabled]="true"
  (onRowUpdating)="onRowUpdating($event)"
>
  <dxi-column
    [dataField]="name"
    caption="Impact/Urgence"
    [width]="150"
    [allowSorting]="false"
    [allowEditing]="false"
    cssClass="matrix-class"
  >
  </dxi-column>
  <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
</dx-data-grid>
<div class="cl-matrix-hint">
  {{ 'entities/matrixPriority/msgApply' | translate }}
</div>
