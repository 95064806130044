<ng-template #urlTemplate let-data>
  <a class="cl-no-click" style="cursor: pointer" [attr.href]="generateUrl(data?.value, false)">{{ data?.value }}</a>
</ng-template>

<ng-template #hrefTemplate let-data>
  <a class="cl-no-click" target="_blank" [attr.href]="generateUrl(data?.value, true)">{{ data?.value }}</a>
</ng-template>

<ng-template #displayWithoutHTMLTemplate let-data>
  {{ displayWithoutHtml(data?.value) }}
</ng-template>

<ng-template #assetTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.id }}">
    {{ data?.data?.name }}</a>
</ng-template>

<ng-template #userTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/users/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #userDataTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/users/edit/{{ data?.data?.userId }}">{{
    data?.data?.user?.data?.name }}</a>
</ng-template>

<ng-template #scanConfigurationTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/inventory/scan-configurations/edit/{{
      data?.data?.scanConfigurationId
    }}">{{ data?.data?.scanConfiguration?.data?.name }}</a>
</ng-template>

<ng-template #assetVlanTemplate let-data>
  <a *ngIf="data?.data?.assetLinkId == undefined" class="cl-no-click" target="_blank"
    routerLink="/assets/edit/{{ data?.data?.assetLink?.id }}">{{ data?.data?.assetLink?.name }}</a>
  <a *ngIf="data?.data?.assetLinkId != undefined" class="cl-no-click" target="_blank"
    routerLink="/assets/edit/{{ data?.data?.assetLinkId }}">{{ data?.data?.assetLink?.data?.name }}</a>
</ng-template>
<ng-template #displayAmountTemplate let-data>
  {{ DisplayAmountOutStock(data) }}
</ng-template>

<ng-template #assetLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.id }}">{{ data?.data?.name }}</a>
</ng-template>

<ng-template #assetModelLinkTemplateEntry let-data>
  <a class="cl-no-click" target="_blank" routerLink="/stocks/asset-models/edit/{{ data?.data?.entryId }}">{{
    data?.data?.entry?.name }}</a>
</ng-template>

<ng-template #locationModelLinkTemplateEntry let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/architecture/locations/edit/{{
      data?.data?.storageUnit.id
    }}">{{ getTranslate(data?.data?.storageUnit?.name) }}</a>
</ng-template>

<ng-template #userNameLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/users/edit/{{ data?.data?.id }}">{{ data?.data?.name }}</a>
</ng-template>

<ng-template #operatorNameLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/security/permissions/edit/{{ data?.data?.id }}">{{
    data?.data?.name }}</a>
</ng-template>

<ng-template #userNameEmailTemplate let-data>
  <div class="in-field-hint-wrapper">
    <div class="item-search-result in-field-hint-flexbox">
      <div>
        <span class="supplier" *ngIf="data?.serviceDeskActivated">
          {{ 'globals/userSupplier' | translate }}</span>
        {{ data?.name }}
      </div>
      <span>{{ data?.email }}</span>
    </div>
  </div>
</ng-template>

<ng-template #userNameEmailVipTemplate let-data>
  <div class="in-field-hint-wrapper">
    <div class="item-search-result in-field-hint-flexbox" [ngStyle]="{
        color: data?.vip ? 'red' : ''
      }">
      <div>{{ data?.name }}</div>
      <span>{{ data?.email }}</span>
    </div>
  </div>
</ng-template>

<ng-template #securityGroupLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/access/groups/edit/{{ data?.data?.id }}"
    [queryParams]="{ type: 'group' }">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #loanAssetDateLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.id }}">{{ data?.data?.name }}</a>
</ng-template>

<ng-template #loanLocationDateLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/architecture/locations/edit/{{
      data?.data?.id
    }}">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #assetModelsLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/stocks/asset-models/edit/{{ data?.data?.id }}">{{ data?.data?.name
    }}</a>
</ng-template>
<ng-template #outStockLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/stocks/out-stocks/edit/{{ data?.data?.outStockMovementId }}">{{
    data?.data?.outStockMovementName }}</a>
</ng-template>

<ng-template #workflowTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/workflow/edit/{{
      data?.data?.workflow.data.id
    }}">{{ getTranslate(data?.data?.workflow?.data?.name) }}</a>
</ng-template>

<ng-template #priorityLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/priorities/edit/{{
      data?.data?.id
    }}">{{ translatedFieldHelperService.findValueToShow(data?.data?.name) }}</a>
</ng-template>

<ng-template #dayOffLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/days-off/edit/{{ data?.data?.id }}">{{
    translatedFieldHelperService.findValueToShow(data?.data?.name) }}</a>
</ng-template>

<ng-template #localAgentLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/inventory/clarilog-local-agents/edit/{{ data?.data?.id }}">{{
    data?.data?.name }}</a>
</ng-template>

<ng-template #campaignPackageLinkTemplate let-data>
  <a class="cl-no-click" target="_blank"
    routerLink="/teledistribution/packages/edit/{{ data?.data?.package?.data?.id}}">{{
    data?.data?.package?.data?.name
    }}</a>
</ng-template>

<ng-template #assetDataTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.assetId }}">{{
    data?.data?.asset?.data?.name }}</a>
</ng-template>

<ng-template #softwareTemplate let-data>
  @if(getSoftwareRoute()){
  <a class="cl-no-click" target="_blank" routerLink="/softwares/software/edit/{{ data?.data?.softwareId }}">{{
    data?.value }}</a>
  } @else{
  <span>{{ data?.value }}</span>
  }
</ng-template>

<ng-template #softwareIdTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/softwares/software/edit/{{ data?.data?.id }}">{{ data?.value
    }}</a>
</ng-template>

<ng-template #operatingSystemTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/softwares/operatingSystems/edit/{{ data?.data?.softwareId }}">{{
    data?.data?.caption }}</a>
</ng-template>
<ng-template #updatesTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/softwares/updates/edit/{{ data?.data?.softwareId }}">{{
    data?.data?.hotFixId }}</a>
</ng-template>

<ng-template #loanTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/loans/edit/{{ data?.data?.id }}">{{ data?.data?.number }}</a>
</ng-template>

<ng-template #incidentTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.id }}"
    [ngClass]="isTicketChidlClassTemplate(data)">{{ data?.value }}</a>
</ng-template>

<ng-template #requestTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.id }}">{{ data?.value }}</a>
</ng-template>
<ng-template #verifaliaClassificationTemplate let-data let-column="column">
  <span [class]="{
      'dx-tag-content': true,
      'dx-tag-deliverable': data?.value === 'Deliverable',
      'dx-tag-risky': data?.value === 'Risky',
      'dx-tag-undeliverable': data?.value === 'Undeliverable'
    }" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != 0">{{ data?.value }}</span>
</ng-template>
<ng-template #userIdentityLinkTemplate let-data>
  <ng-container *ngIf="data?.data.subId != undefined; else linkTemplate">
    <a class="cl-no-click" target="_blank" [attr.href]="generateIdentityUserUrl(data?.data.subId)">{{ data?.value }}</a>
  </ng-container>
  <ng-template #linkTemplate>
    <span>{{ data?.value }}</span>
  </ng-template>
</ng-template>

<ng-template #incidentDataTemplate let-data>
  <a class="cl-no-click" target="_blank" [attr.href]="generateTicketUrl(data?.data)">{{
    data?.data?.ticket?.data?.ticketNumber }}</a>
</ng-template>

<ng-template #alarmLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/alarm/alarms/edit/{{ data?.data?.id }}">{{
    data?.value }}</a>
</ng-template>

<ng-template #namingConventionTemplate let-data>
  {{ getConventionValue(data.column.dataField, data.data) }}
</ng-template>

<ng-template #assetCommissioningLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.id }}">{{ data?.data?.name }}</a>
</ng-template>
<ng-template #assetSoftwareGroupLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.id }}">{{ data?.data?.name }}</a>
</ng-template>

<ng-template #auditStateTemplate let-data>
  <span style="width: 20px; display: inline-block">
    <i *ngIf="
        data?.value == 'IN_QUEUE' &&
        stateAgent(data?.data?.clarilogServer?.data) == true
      " class="fas fa-hourglass-start" style="color: green"></i>
    <i *ngIf="
        data?.value == 'CANCELING' &&
        stateAgent(data?.data?.clarilogServer?.data) == true
      " class="far fa-play-circle" style="color: #fd7e14"></i>
    <i *ngIf="
        data?.value == 'IN_PROGRESS' &&
        stateAgent(data?.data?.clarilogServer?.data) == true
      " class="fas fa-play" style="color: #45a7b9"></i>
    <i *ngIf="
        (data?.value == 'IN_QUEUE' ||
          data?.value == 'IN_PROGRESS' ||
          data?.value == 'CANCELING') &&
        stateAgent(data?.data?.clarilogServer?.data) == false
      " class="fas fa-question" style="color: #fd7e14"></i>

    <i *ngIf="data?.value == 'CANCEL'" class="fas fa-ban" style="color: red"></i>
    <i *ngIf="data?.value == 'COMPLETED'" class="fas fa-stop" style="color: black"></i>
    <i *ngIf="data?.value == 'ERROR'" class="fas fa-exclamation-circle" style="color: #fd7e14"></i>
  </span>
  <span style="padding-left: 5px">{{ data?.displayValue }}</span>
</ng-template>

<ng-template #auditAzureStateTemplate let-data>
  <span style="width: 20px; display: inline-block">
    <i *ngIf="data?.value == 'IN_PROGRESS'" class="fas fa-play" style="color: #45a7b9"></i>
    <i *ngIf="data?.value == 'IN_QUEUE'" class="fas fa-hourglass-start" style="color: #fd7e14"></i>

    <i *ngIf="data?.value == 'COMPLETED'" class="fas fa-stop" style="color: black"></i>
    <i *ngIf="data?.value == 'ERROR'" class="fas fa-exclamation-circle" style="color: #fd7e14"></i>
  </span>
  <span style="padding-left: 5px">{{ data?.displayValue }}</span>
</ng-template>

<ng-template #addressTemplate let-data>
  {{ data?.data?.address.street }} {{ data?.data?.address.additional }}
  {{ data?.data?.address.postalCode }} {{ data?.data?.address.city }}
  {{ data?.data?.address.country }} {{ data?.data?.address.zipCode }}
</ng-template>

<ng-template #mailToTemplate let-data>
  <a class="cl-no-click" target="_blank" href="mailto:{{ data?.value }}">{{
    data?.value
    }}</a>
</ng-template>

<ng-template #callToTemplate let-data>
  <a class="cl-no-click" target="_blank" href="tel:{{ data?.value }}">{{
    data?.value
    }}</a>
</ng-template>

<ng-template #faxTemplate let-data>
  <a class="cl-no-click" target="_blank" href="fax:{{ data?.value }}">{{
    data?.value
    }}</a>
</ng-template>

<ng-template #connectedTemplate let-data>
  <i *ngIf="data?.value != null" class="fas fa-circle" style="font-size: 0.8em" [ngStyle]="{
      color: isConnectedAgent(data) === true ? 'green' : 'red'
    }"></i>
  <i *ngIf="data?.value === null" class="fas fa-exclamation-circle"
    style="font-size: 0.8em; color: var(--input-error)"></i>
</ng-template>

<ng-template #favoriteTemplate let-data>
  <i *ngIf="data?.value === true" class="fa fa-star" style="font-size: 0.8em; color: orange"></i>
</ng-template>

<ng-template #entityNameTemplate let-data>
  <div *ngIf="data?.value != null">
    {{
    'entities/' +
    data?.value.charAt(0).toLowerCase() +
    data?.value.slice(1) +
    '/_title/singular' | translate
    }}
  </div>
</ng-template>

<ng-template #entityArrayNameTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngFor="let value of data?.value">
    {{
    'entities/' +
    value.charAt(0).toLowerCase() +
    value.slice(1) +
    '/_title/singular' | translate
    }}</span>
</ng-template>

<ng-template #sizeTemplate let-data let-column="column">
  <div *ngIf="data?.value != 0">
    {{ convertToOctet(data?.value, column.format) }}
  </div>
</ng-template>

<ng-template #warrantyEndTemplate let-data let-column="column">
  <div *ngIf="data?.value != 0">
    {{ convertUTCToDate(data?.value, column.format) }}
  </div>
</ng-template>

<ng-template #sizeTemplateWithZero let-data let-column="column">
  {{ convertToOctet(data?.value, column.format, true) }}
</ng-template>

<ng-template #sizeMegaOctetTemplate let-data let-column="column">
  {{ convertToOctet(data?.value, column.format) }}
</ng-template>

<ng-template #priceTemplate let-data let-column="column">
  <span *ngIf="data?.value != 0">{{ data?.value }}</span>
</ng-template>

<ng-template #speedTemplate let-data let-column="column">
  {{ convertToSpeed(data?.value, column.format) }}
</ng-template>

<ng-template #operatorTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngFor="let value of data?.data?.listOperatorTeams; let i = index">{{
    translatedFieldHelperService.findValueToShow(value.name) }}</span>
</ng-template>

<ng-template #arrayTemplate let-data let-column="column">
  <div class="arrayTemplateColumn">
    <div style="height: 20px" *ngFor="let value of data?.value?.slice(0, 16); let i = index">
      <span class="dx-tag-content arrayTemplateTagItem">{{ value }}</span>
    </div>
  </div>
</ng-template>

<ng-template #keyWordsTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngFor="let value of data?.value">{{ value }}</span>
</ng-template>

<ng-template #arrayEnumTemplate let-data let-column="column">
  <span
  *ngFor="let value of data?.value"
    [ngStyle]="{
      'background-color': data?.data?.key != null ? 'gray !important' : ''
    }"
      [class]="{
      'dx-tag-content': true,
      'dx-tag-incident': getValue(value) === 'incident',
      'dx-tag-request': getValue(value) === 'request',
      'dx-tag-problem': getValue(value) === 'problem',
      'dx-tag-ticket': getValue(value) === 'ticket'
    }"
    style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
      background-color: gray;
    "
    
    >{{ templatesService.translateValue(value, data?.column.format) }}</span
  >
</ng-template>

<ng-template #workflowTagboxTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngFor="let value of data?.value">{{ getDisplay(value) }}</span>
</ng-template>

<ng-template #operatorTeamArrayTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngFor="let value of data?.data?.operatorTeams?.data">{{ value.name }}</span>
</ng-template>

<ng-template #ticketTypeTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != 0">{{ templatesService.translateValue(data?.value, column.format) }}</span>
</ng-template>

<ng-template #ticketCategoryLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="administration/service-desk/ticket-categories/edit/{{
      data?.data?.id
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #incidentModelLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="administration/service-desk/status-workflow/edit/{{
      data?.data?.id
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #namingConventionTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/rule/naming-conventions/edit/{{
      data?.value.id
    }}">
    {{ data?.value?.value }}
  </a>
</ng-template>

<ng-template #milliSecondToTimeTemplate let-data>
  {{ milliSecondToTime(data?.value) }}
</ng-template>

<ng-template #iconTemplate let-data>
  <i *ngIf="data?.value !== undefined" class="fas fa-{{ data?.value }} fa-lg"></i>
  <i *ngIf="data?.value === undefined" class="fas fa-{{ data }} fa-3x"></i>
</ng-template>

<ng-template #locationCharterTemplate let-data>
  <i *ngIf="data?.value > 0" style="color: var(--primary)" class="fas fa-exchange">
    ({{ data?.value }})
  </i>
</ng-template>

<ng-template #locationCharterRulesTemplate let-data let-column="column">
  <span *ngIf="data?.data?.charters?.totalCount > 0 && data?.value">
    {{ 'globals/allCharterValidate' | translate }}
  </span>
  <span *ngIf="data?.data?.charters?.totalCount > 0 && !data?.value">
    {{ 'globals/atLeastCharterValidate' | translate }}
  </span>
</ng-template>
<ng-template #locationCharterRulesAssetTemplate let-data let-column="column">
  <span *ngIf="
      data?.data?.charters?.totalCount > 0 &&
      data?.value &&
      data?.data?.charters?.data[0]?.asset === true
    ">
    {{ 'globals/allCharterValidate' | translate }}
  </span>
  <span *ngIf="
      data?.data?.charters?.totalCount > 0 &&
      !data?.value &&
      data?.data?.charters?.data[0]?.asset === true
    ">
    {{ 'globals/atLeastCharterValidate' | translate }}
  </span>
</ng-template>
<ng-template #locationCharterRulesUserTemplate let-data let-column="column">
  <span *ngIf="
      data?.data?.charters?.totalCount > 0 &&
      data?.value &&
      data?.data?.charters?.data[0]?.user === true
    ">
    {{ 'globals/allCharterValidate' | translate }}
  </span>
  <span *ngIf="
      data?.data?.charters?.totalCount > 0 &&
      !data?.value &&
      data?.data?.charters?.data[0]?.user === true
    ">
    {{ 'globals/atLeastCharterValidate' | translate }}
  </span>
</ng-template>

<ng-template #loaderButtonTemplate let-data class="loader-button">
  <dx-load-indicator class="button-indicator" [visible]="data?.disabled">
  </dx-load-indicator>
  <span class="dx-button-text">{{ data?.options.text }}</span>
</ng-template>

<ng-template #ldapTemplate let-data>
  <i *ngIf="data?.data?.ldapObjectClassType == 'ASSET'" style="color: #455adb" class="far fa-desktop"></i>
  <i *ngIf="data?.data?.ldapObjectClassType == 'USER'" style="color: #aac7e4" class="fas fa-user"></i>
  <i *ngIf="data?.data?.ldapObjectClassType == 'ORGANIZATION'" style="color: #fad988" class="fas fa-folder"></i>
  <i *ngIf="data?.data?.ldapObjectClassType == 'ROLE'" style="color: #92b86c" class="fas fa-users"></i>
  <i *ngIf="data?.data?.ldapObjectClassType == 'CONTAINER'" style="color: #d8af49" class="far fa-folder"></i>
  <i *ngIf="data?.data?.ldapObjectClassType == 'ROOT'" style="color: #aac7e4" class="fas fa-sitemap"></i>
  {{ data?.value }}
</ng-template>

<ng-template #groupTypeAzureTemplate let-data let-column="column">
  <span *ngIf="data?.value == 'security'">
    {{ 'entities/scanByAzureAd/typeSecurity' | translate }}
  </span>
  <span *ngIf="data?.value == 'microsoft365'">
    {{ 'entities/scanByAzureAd/typeMicrosoft365' | translate }}
  </span>
</ng-template>

<ng-template #booleanTypeTemplate let-data let-column="column">
  <i *ngIf="data?.value" style="color: var(--primary)" class="far fa-check"></i>
</ng-template>

<ng-template #percentTemplate let-data let-column="column">
  <div *ngIf="data?.value > 0">
    <div style="
        width: 100%;
        height: 20px;
        border: 1px solid #ddd;
        position: relative;
      ">
      <div style="height: 100%; position: absolute" [style.width.%]="getPercentValue(data?.value, data?.data)"
        [style.background-color]="
          getPercentColor(data?.value, data?.data, column.format)
        "></div>
      <div style="
          position: absolute;
          right: 0;
          width: 100%;
          text-align: center;
          top: 2px;
          color: var(--secondary-font);
        ">
        {{ getPercentValue(data?.value, data?.data) }}
        %
      </div>
      <div style="
          position: absolute;
          right: 0;
          width: 100%;
          text-align: center;
          top: 2px;
        " [ngStyle]="{
          'clip-path':
            'inset(0 0 0 ' + getPercentValue(data?.value, data?.data) + '%)'
        }">
        {{ getPercentValue(data?.value, data?.data) }} %
      </div>
    </div>
  </div>
</ng-template>

<ng-template #messageTicketTemplate let-data let-column="column">
  <div [innerHtml]="data?.value"></div>
</ng-template>

<ng-template #linkMessageTicketTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" [routerLink]="
      getUrlLinkTicket(
        data?.data?.message.data.ticket?.data.__typename,
        data?.data?.message.data.ticketId
      )
    " [queryParams]="{ navigateTo: 'communication' }">{{ data?.data?.message.data.ticket?.data?.title }}</a>
</ng-template>

<ng-template #alertIncidentTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.incidentId }}">{{
    data?.data?.incident?.data?.title }}</a>
</ng-template>
<ng-template #alertRequestTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.requestId }}">{{
    data?.data?.request?.data?.title }}</a>
</ng-template>
<ng-template #alertTaskTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.taskId }}">{{
    data?.data?.task?.data?.title }}</a>
</ng-template>

<ng-template #favoritelinkTicketTemplate let-data let-column="column">
  <a *ngIf="data?.data?.__typename == 'Incident'" class="cl-no-click" routerLink="/incidents/edit/{{ data?.data?.id }}"
    [queryParams]="{ isFavorite: true, backUrl: 'favorite-tickets' }" [ngClass]="isTicketChidlClassTemplate(data)">{{
    data?.value }}
  </a>
  <a *ngIf="data?.data?.__typename == 'Request'" class="cl-no-click" routerLink="/requests/edit/{{ data?.data?.id }}"
    [queryParams]="{ isFavorite: true, backUrl: 'favorite-tickets' }" [ngClass]="isTicketChidlClassTemplate(data)">{{
    data?.value }}
  </a>
</ng-template>

<ng-template #linkTicketTemplate let-data let-column="column">
  <a *ngIf="getPolicyEditTicket(data?.data?.__typename)" [routerLink]="
      getUrlLinkTicket(
        data?.data?.__typename,
        data?.data?.id,
        column?.editUrlTicketLink
      )
    " [queryParams]="{ backUrl: getCurrentUrl() }" [ngClass]="
      data?.column?.dataField === 'title'
        ? isTicketChidlClassTemplate(data)
        : ''
    ">{{ data?.value }}
  </a>

  <span *ngIf="!getPolicyEditTicket(data?.data?.__typename)" [ngClass]="
      data?.column?.dataField === 'title'
        ? isTicketChidlClassTemplate(data)
        : ''
    ">
    {{ data?.value }}
  </span>
</ng-template>

<ng-template #linkToParentTemplate let-data let-column="column">
  <a [routerLink]="
      getUrlLinkTicket(
        data?.data?.__typename,
        data?.data?.id,
        column?.editUrlTicketLink
      )
    " [queryParams]="{ backUrl: getCurrentUrl() }"
    [ngClass]="data?.column?.dataField === 'title' ? 'ticket-parent' : ''">
    {{ data?.value }}
  </a>

  <span [ngClass]="data?.column?.dataField === 'title' ? 'ticket-parent' : ''">
    {{ data?.valuue }}
  </span>
</ng-template>

<ng-template #logicalDiskTemplate let-data let-column="column">
  <div style="text-align: left">
    <div style="font-size: 0.8em">
      {{ data?.data?.caption }}
      <span *ngIf="
          data?.data?.volumeName != undefined && data?.data?.volumeName != ''
        ">
        ({{ data?.data?.volumeName }})</span>
    </div>
    <div>
      <div style="float: left; width: 95%; height: 15px; border: 1px solid #ddd">
        <div style="height: 100%" [style.width.%]="data?.value != undefined ? data?.value : 0" [style.background-color]="
            getPercentColor(data?.value, data?.data, 'Disk')
          "></div>
      </div>
    </div>
    <div style="clear: both; font-size: 0.8em; color: #999">
      <span *ngIf="data?.data?.freeSpace != 0">{{
        convertToOctet(data?.data?.freeSpace, '')
        }}</span>
      <span *ngIf="data?.data?.freeSpace == 0">{{ '0 Go' }}</span>
      <span> {{ 'globals/freeOn' | translate }}</span>
      <span> {{ convertToOctet(data?.data?.size, '') }}</span>
      <span> ({{ data?.value }} %)</span>
    </div>
  </div>
</ng-template>

<ng-template #buttonsTemplate let-item let-component="component" let-source="source" let-column="column">
  <dx-drop-down-button #dropdownButton *ngIf="column.source != undefined" class="add-treeview"
    [ngClass]="{ opened: dropdownButton.opened || false }" icon="fal fa-plus" [showArrowIcon]="false" stylingMode="text"
    [dataSource]="column.source" (onItemClick)="itemClick($event, item.data, column, component)"
    [displayExpr]="column.displayExpr" [keyExpr]="column.keyExpr" [dropDownOptions]="{
      width: '200px',
      height: 'auto',
      maxHeight: '218px',
      position: {
        of: dropdownButton['element'].nativeElement,
        my: 'left top',
        at: 'right top'
      }
    }"></dx-drop-down-button>
  <dx-button *ngIf="
      column.source == undefined &&
      item.data != undefined &&
      item.data['allowSelection'] !== false
    " class="add-treeview" [activeStateEnabled]="false" [focusStateEnabled]="false" [hoverStateEnabled]="false"
    stylingMode="text" icon="fal fa-plus" (onClick)="navigate(item.data, column)"></dx-button>
</ng-template>

<ng-template #userLinkTemplate let-data>
  <div>{{ data?.name }}</div>
  <div class="link-component-template-email">{{ data?.email }}</div>
</ng-template>

<ng-template #assetCategoryLinkTemplate let-data>
  <div>{{ getTranslate(data?.name) }}</div>
  <div class="link-component-template-email">
    {{ getTranslate(data?.path) }}
  </div>
</ng-template>
<ng-template #storageUnitLinkTemplate let-data>
  <div>{{ getTranslate(data?.name) }}</div>
  <div class="link-component-template-email">
    {{ getTranslate(data?.path) }}
  </div>
</ng-template>

<ng-template #contactLinkTemplate let-data>
  <div *ngIf="data?.name != null">{{ data?.name }}</div>
  <div *ngIf="data?.supplier?.data != null" class="link-component-template-email">
    {{ data?.supplier.data?.name }}
  </div>
</ng-template>

<ng-template #stockAssetLinkTemplate let-data>
  <div>{{ data?.asset.data?.name }}</div>
</ng-template>

<ng-template #stockAssetModelLinkTemplate let-data>
  <div>{{ data?.assetModel.data?.name }}</div>
</ng-template>

<ng-template #budgetLinkTemplate let-data>
  <div>{{ data?.code }}</div>
  <div class="link-component-template-email">{{ data?.name }}</div>
</ng-template>

<ng-template #alarmTemplate let-data>
  <a class="cl-no-click" [routerLink]="['alerts']" [queryParams]="{ alarmId: data?.data?.id }"
    skipLocationChange="true">{{ data?.value }}</a>
</ng-template>

<ng-template #isReadTemplate let-data>
  <i *ngIf="data?.value == false" class="fal fa-envelope-open" style="font-size: 0.8em"></i>
  <i *ngIf="data?.value != false" class="fas fa-envelope" style="font-size: 0.8em"></i>
</ng-template>
<ng-template #isReadNotificationTemplate let-data>
  <i *ngIf="data?.value != false" class="fal fa-envelope-open" style="font-size: 0.8em"></i>
  <i *ngIf="data?.value == false" class="fas fa-envelope" style="font-size: 0.8em"></i>
</ng-template>

<ng-template #notificationObjectClick let-data>
  <a class="cl-no-click" target="_blank" href="{{ manageNotificationOpening(data.data) }}">{{
    getTranslate(data.data.title) }}</a>
</ng-template>
<ng-template #priorityColorTemplate let-data>
  <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.value }"></i>
</ng-template>

<ng-template #priorityNameColorTemplate let-data>
  <div *ngIf="data?.value != null">
    <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.data?.color }"></i>
    {{ data?.value }}
  </div>
</ng-template>

<ng-template #mainPriorityColorTemplate let-data>
  <div *ngIf="data?.value != null">
    <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.data?.priority?.data?.color }"></i>
    {{ data?.value }}
  </div>
</ng-template>

<ng-template #mainPriorityColorTemplateQueryBuilder let-data>
  <div *ngIf="data?.value != null">
    <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.data?.priority?.data?.color }"></i>
  </div>
</ng-template>

<ng-template #selectPriorityColorTemplate let-data>
  <div>
    <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.color }">
    </i>
    {{ translatedFieldHelperService.findValueToShow(data?.name) }}
  </div>
</ng-template>

<ng-template #flagPriorityTemplate let-data>
  <div>
    <i class="fas fa-flag" style="font-size: 0.8em" [ngStyle]="{ color: data?.color }"></i>
    {{ translatedFieldHelperService.findValueToShow(data?.name) }}
  </div>
</ng-template>

<ng-template #logStateTemplate let-data>
  <i [ngClass]="getlogStateTemplateIco(data, true)" [ngStyle]="{ color: getlogStateTemplateColor(data, true) }"></i>
</ng-template>

<ng-template #locationLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/architecture/locations/edit/{{
      data?.data?.id
    }}">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #assetCategoryLinksTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/settings/categories/edit/{{ data?.data?.id }}">{{
    getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #interventionModelLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/intervention-model/edit/{{
      data?.data?.id
    }}">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>
<ng-template #organizationalUnitLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/architecture/organizational-units/edit/{{
      data?.data?.id
    }}">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #slaLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/service-level-agreements/edit/{{
      data?.data?.id
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #taskModelLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/task-model/edit/{{
      data?.data?.id
    }}">{{ data?.data?.name }}</a>
</ng-template>

<ng-template #activitiesLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/activities/edit/{{
      data?.data?.id
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #operatorTeamLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/access/operator-team/edit/{{ data?.data?.id }}">{{
    getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #runTemplate let-data>
  <a class="cl-no-click" routerLink="/query-builders/result/{{ data.data.id }}"><i class="fas fa-play"></i></a>
</ng-template>

<ng-template #permissionMemberTemplate let-data>
  <a *ngIf="
      data?.data?.__typename == 'User' || data?.data?.__typename == 'Account'
    " class="cl-no-click" target="_blank"
    routerLink="/administration/security/permissions/edit/{{ data?.data?.id }}">{{ data?.data?.name }}
  </a>

  <a *ngIf="data?.data?.__typename == 'Role'" class="cl-no-click" target="_blank"
    routerLink="administration/security/permissions/edit/{{ data?.data?.id }}" [queryParams]="{ type: 'role' }">{{
    data?.data?.name }}
  </a>
</ng-template>

<ng-template #specificLinkTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/{{ column.specificLinkTemplate }}/edit/{{
      data?.data[column.specificLinkFieldId]
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #acceptDateTemplate let-data let-column="column">
  <i *ngIf="data?.value != null" style="color: var(--primary)" class="far fa-check"></i>
</ng-template>

<ng-template #noteTemplate let-data let-column="column">
  <star-rating [starType]="'svg'" [disabled]="true" [rating]="data?.value"></star-rating>
</ng-template>
<ng-template #ratingTemplate let-data let-column="column">
  <star-rating [starType]="'svg'" [disabled]="true" [numOfStars]="6" [rating]="data?.value"></star-rating>
</ng-template>
<ng-template #ticketEmailAction let-data let-column="column">
  <i class="far fa-inbox-out" style="color: rgb(0, 140, 255)" *ngIf="data?.value == 'SEND'"></i>
  <i class="far fa-inbox-in" style="color: green" *ngIf="data?.value == 'RECEIVE'"></i>
</ng-template>
<ng-template #hasAttachment let-data let-column="column">
  <i class="fas fa-paperclip" *ngIf="data?.value != undefined && data.value === true"></i>
</ng-template>
<ng-template #hasFilesAttachment let-data let-column="column">
  <i class="fas fa-paperclip" *ngIf="data?.value != undefined && data.value.length > 0"></i>
</ng-template>
<ng-template #attachmentLink let-data>
  <a target="_blank" class="cl-no-click" [attr.href]="getAttachment(data?.data?.fileId)">{{ data?.value }}</a>
</ng-template>

<ng-template #hasError let-data let-column="column">
  <i [ngClass]="getlogStateTemplateIco(data, false)" [ngStyle]="{ color: getlogStateTemplateColor(data, false) }"></i>
</ng-template>

<ng-template #timeSpanTemplate let-data>
  <span> {{ fromTimespan(data) }}</span>
</ng-template>

<ng-template #estimatedTimeSpanTemplate let-data>
  <span> {{ estimatedTimeSpan(data?.value) }} </span>
</ng-template>

<ng-template #estimatedTimeMinutesTemplate let-data>
  <span> {{ estimatedTimeSpan(data?.value, true) }} </span>
</ng-template>

<ng-template #taskTemplate let-data>
  <a routerLink="{{ generateTicketUrl(data?.data) }}" [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'tasks',
      taskId: data?.data?.id
    }" [ngClass]="{ 'ma-classe': data?.data?.isGreyList }">
    {{ data?.value }}
  </a>
</ng-template>

<ng-template #ticketTaskTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.ticketTask?.data?.ticketId }}"
    *ngIf="data?.data?.ticketTask?.data?.ticket?.data?.__typename === 'Request'">{{
    data?.data?.ticketTask?.data?.ticket?.data?.ticketNumber }}
  </a>
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.ticketTask?.data?.ticketId }}"
    *ngIf="
      data?.data?.ticketTask?.data?.ticket?.data?.__typename === 'Incident'
    ">{{ data?.data?.ticketTask?.data?.ticket?.data?.ticketNumber }}
  </a>
</ng-template>


<ng-template #qualificationTemplate let-data let-column="column">
  <span
    
    >{{ getQualifications(data?.value) }}</span
  > 
</ng-template>

<ng-template #workflowTypeTemplate let-data let-column="column">
  <span>{{ getWorkflowType(data?.value) }}</span> 
</ng-template>

<ng-template #emailConnectorTypeTemplate let-data let-column="column">
 <span>{{ getEmailConnectorType(data?.value) }}</span> 
</ng-template>



<ng-template #ticketTypeColorTemplate let-data let-column="column">
  <span [class]="{
      'dx-tag-content': true,
      'dx-tag-incident': getValue(data?.value) === 'incident',
      'dx-tag-request': getValue(data?.value) === 'request',
      'dx-tag-problem': getValue(data?.value) === 'problem',
      'dx-tag-ticket': getValue(data?.value) === 'ticket'
    }" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != 0">{{ templatesService.translateValue(data?.value, column.format) }}</span>
</ng-template>

<ng-template #ticketTypeWithoutColorTemplate let-data let-column="column">
  <span [class]="{
      'dx-tag-content': true,
    }" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != 0">{{ templatesService.translateValue(data?.value, column.format) }}</span>
</ng-template>

<ng-template #alertticketTypeColorTemplate let-data let-column="column">
  <span [class]="{
      'dx-tag-content': true,
      'dx-tag-incident': data?.value === 'Incident'
    }" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != 0">{{ templatesService.translateValue(data?.value, 'ticketType') }}</span>
</ng-template>

<ng-template #alertTicketTaskTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.ticketTask?.data?.ticketId }}"
    [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'tasks',
      taskId: data?.data?.ticketTaskId
    }" *ngIf="data?.data?.ticketTask?.data?.ticket?.data?.__typename === 'Request'">{{
    translatedFieldHelperService.findValueToShow(
    data?.data?.ticketTask?.data?.title
    )
    }}
  </a>
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.ticketTask?.data?.ticketId }}"
    [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'tasks',
      taskId: data?.data?.ticketTaskId
    }" *ngIf="
      data?.data?.ticketTask?.data?.ticket?.data?.__typename === 'Incident'
    ">{{
    translatedFieldHelperService.findValueToShow(
    data?.data?.ticketTask?.data?.title
    )
    }}
  </a>
</ng-template>

<ng-template #alertInterventionTicketTypeColorTemplate let-data let-column="column">
  <span [class]="{
      'dx-tag-content': true,
      'dx-tag-incident':
        data?.data?.intervention?.data?.ticket?.data?.__typename === 'Incident'
    }" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.data?.intervention?.data?.ticket?.data?.__typename != 0">{{
    templatesService.translateValue(
    data?.data?.intervention?.data?.ticket?.data?.__typename,
    'ticketType'
    )
    }}</span>
</ng-template>
<ng-template #alertInterventionTicketTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.intervention?.data?.ticketId }}"
    [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'interventions',
      interventionId: data?.data?.interventionId
    }" *ngIf="
      data?.data?.intervention?.data?.ticket?.data?.__typename === 'Request'
    ">{{ data?.data?.intervention?.data?.ticket?.data?.title }}
  </a>
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.intervention?.data?.ticketId }}"
    [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'interventions',
      interventionId: data?.data?.interventionId
    }" *ngIf="
      data?.data?.intervention?.data?.ticket?.data?.__typename === 'Incident'
    ">{{ data?.data?.intervention?.data?.ticket?.data?.title }}
  </a>
</ng-template>
<ng-template #alertInterventionTicketNumberTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/requests/edit/{{ data?.data?.intervention?.data?.ticketId }}"
    *ngIf="
      data?.data?.intervention?.data?.ticket?.data?.__typename === 'Request'
    ">{{ data?.data?.intervention?.data?.ticket?.data?.ticketNumber }}
  </a>
  <a class="cl-no-click" target="_blank" routerLink="/incidents/edit/{{ data?.data?.intervention?.data?.ticketId }}"
    *ngIf="
      data?.data?.intervention?.data?.ticket?.data?.__typename === 'Incident'
    ">{{ data?.data?.intervention?.data?.ticket?.data?.ticketNumber }}
  </a>
</ng-template>
<ng-template #linkTicketTaskTemplate let-data>
  <a class="cl-no-click" target="_blank" [routerLink]="getCurrentBlankUrl()" [queryParams]="{
      navigateTo: 'treatment',
      tabs: 'tasks',
      taskId: data?.data?.taskId
    }">{{ getTranslate(data?.data?.task?.data?.title) }}
  </a>
</ng-template>

<ng-template #manufacturerTemplate let-data>
  {{ dependsOnTemplates(data) }}
</ng-template>

<ng-template #linkAlertContractTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/contracts/list-contract/edit/{{ data?.data?.contractId }}">{{
    data?.data?.contract?.data?.name }}</a>
</ng-template>

<ng-template #populationTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/administration/access/populations/edit/{{ data?.data?.id }}">{{
    data?.value }}</a>
</ng-template>

<ng-template #linkAlertAssetTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" routerLink="/assets/edit/{{ data?.data?.printerConsumable?.data?.assetId }}">{{
    data?.data?.printerConsumable?.data?.asset?.data?.name }}</a>
</ng-template>

<ng-template #workflowLogActionTypeTemplate let-data let-column="column">
  <span *ngIf="data?.value == 'WorkflowMail'">
    {{ 'entities/workflowMail/_title/singular' | translate }}
  </span>
  <span *ngIf="data?.value == 'WorkflowProperty'">
    {{ 'entities/workflowProperty/_title/singular' | translate }}
  </span>
  <span *ngIf="data?.value == 'WorkflowInvitation'">
    {{ 'entities/workflowInvitation/_title/singular' | translate }}
  </span>
  <span *ngIf="data?.value == 'WorkflowRevocation'">
    {{ 'entities/workflowRevocation/_title/singular' | translate }}
  </span>
  <span *ngIf="data?.value == 'WorkflowManaged'">
    {{ 'entities/workflowlog/workflowManaged' | translate }}
  </span>
  <span *ngIf="data?.value == 'WorkflowNotificationTeams'">
    {{ 'entities/workflowNotificationTeams/_title/singular' | translate }}
  </span>
</ng-template>

<ng-template #workflowLogLinkTypeTemplate let-data let-column="column">
  <a [routerLink]="workflowLogLink(data?.data)" [queryParams]="
      queryParamsLogLink(data?.data?.entityType, data?.data?.entityId)
    ">
    {{ data?.value }}
  </a>
</ng-template>

<ng-template #ticketDescriptionTemplate let-data let-column="column">
  <dx-html-editor [ngClass]="'ticket-description-master-detail'" [readOnly]="true"
    [value]="getTicketOrTicketTaskDescriptionValue(data)" [allowSoftLineBreak]="true">
  </dx-html-editor>
</ng-template>

<ng-template #trackingActionTemplate let-data let-column="column">
  <span *ngIf="data?.value == 'ASSOCIATED'">
    {{ 'entities/tracking/association' | translate }}
  </span>
  <span *ngIf="data?.value == 'DISSOCIATED'">
    {{ 'entities/tracking/discotation' | translate }}
  </span>
</ng-template>

<ng-template #trackingIsSystemTemplate let-data let-column="column">
  <span *ngIf="data?.value == true">
    {{ 'entities/tracking/system' | translate }}
  </span>
  <span *ngIf="data?.value == false">
    {{ 'entities/tracking/manuel' | translate }}
  </span>
</ng-template>

<ng-template #trackingUserFieldTemplate let-data let-column="column">
  <span>
    {{ 'entities/user/' + data?.value | translate }}
  </span>
</ng-template>
<ng-template #trackingAssetFieldTemplate let-data let-column="column">
  <span>
    {{ 'entities/asset/' + data?.value | translate }}
  </span>
</ng-template>
<ng-template #workflowFieldTemplate let-data let-column="column">
  <span>
    {{ getLabelField(data?.value, data?.data?.workflowType) }}
  </span>
</ng-template>

<ng-template #ticketTemplateLink let-data let-column="column">
  <a [routerLink]="
      getUrlTicket(data?.data?.__typename, data?.data?.id, 'research')
    ">{{ data?.value }}
  </a>
</ng-template>

<ng-template #trackingValuesTemplate let-data let-column="column">
  <span *ngFor="let value of data?.data?.values; let i = index" style="padding-right: 3px">
    <a *ngIf="value?.type != undefined" target="_blank" [routerLink]="getUrlTicket(value.type, value.id)">{{ value?.name
      }}</a>
    <span *ngIf="value?.type == undefined">{{ value?.name }}</span>
  </span>
</ng-template>

<ng-template #ticketParentTemplate let-data let-column="column">
  <a *ngIf="data?.data?.parentId != null" [routerLink]="getUrlTicket(data?.data?.__typename, data?.data?.parentId)">{{
    data?.value }}
  </a>
</ng-template>

<ng-template #problemLinkTemplate let-data let-column="column">
  <a *ngIf="data?.data?.problemId != null" [routerLink]="getUrlTicket('problem', data?.data?.problemId)">{{ data?.value
    }}
  </a>
</ng-template>

<ng-template #vipUserAffectedTemplate let-data>
  <span *ngIf="
      data?.data?.userAffected != null;
      then trueCondition;
      else falseCondition
    "></span>
  <ng-template #trueCondition>
    <a [ngStyle]="{
        color: data?.data?.userAffected?.data?.vip ? 'red' : ''
      }" class="cl-no-click" target="_blank" routerLink="/users/edit/{{ data?.data?.userAffectedId }}">{{ data?.value
      }}</a></ng-template>
  <ng-template #falseCondition>
    <a [ngStyle]="{
        color: data?.data?.vip ? 'red' : ''
      }" class="cl-no-click" target="_blank" routerLink="/users/edit/{{ data?.data?.id }}">{{ data?.value
      }}</a></ng-template>
</ng-template>

<ng-template #vipUserMakeRequestTemplate let-data>
  <span *ngIf="
      data?.data?.userMakeRequest != null;
      then trueCondition;
      else falseCondition
    "></span>
  <ng-template #trueCondition>
    <span [ngStyle]="{
        color: data?.data?.userMakeRequest?.data?.vip ? 'red' : ''
      }">{{ data?.value }}</span></ng-template>
  <ng-template #falseCondition>
    <span [ngStyle]="{
        color: data?.data?.vip ? 'red' : ''
      }">{{ data?.value }}</span></ng-template>
</ng-template>

<ng-template #operatorNameTaskTemplate let-data>
  <span class="supplier" *ngIf="data?.data.operator?.data?.serviceDeskActivated">
    {{ 'globals/userSupplier' | translate }}
  </span>
  {{ data?.value }}
</ng-template>

<ng-template #userLoanTemplate let-data let-column="column">
  <a target="_blank" [routerLink]="
      getAssetOrUserUrl(column?.editUrl, data?.data?.owner?.data?.id)
    ">{{ data?.value }}
  </a>
</ng-template>

<ng-template #assetLoanTemplate let-data let-column="column">
  <a target="_blank" [routerLink]="getAssetOrUserUrl(column?.editUrl, data?.data?.id)">{{ data?.value }}
  </a>
</ng-template>

<ng-template #inviteUserRoleTemplate let-data>
  <span>
    <span *ngIf="data?.key" class="invite-user-system-role">
      {{ 'entities/role/isSystem' | translate }} -
    </span>

    {{ data?.name }}
  </span>
</ng-template>

<ng-template #contractTemplate let-data>
  @if (data?.data?.renewalId !== undefined || data?.data?.renewalId !== null) {
  <a class="cl-no-click" target="_blank" routerLink="/contracts/list-contract/edit/{{ data?.data?.id }}">
    {{ data?.data?.name }}</a>
  } @else {
  <span>{{ data?.data?.name }}</span>
  }
</ng-template>

<ng-template #supplierTemplate let-data let-column="column">
  <a class="cl-no-click" target="_blank" [routerLink]="getSupplierUrl(data?.data?.id, column?.editUrl)">
    {{ data?.data?.name }}</a>
</ng-template>

<ng-template #financialsBudgetsTemplate let-data>
  <a class="cl-no-click" target="_blank" [routerLink]="financialsRoute(data?.data)">
    {{ data?.value }}</a>
</ng-template>

<ng-template #softwareGroupPackageTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/softwares/software/edit/{{ data?.data?.id }}">
    {{ data?.data?.name }}</a>
</ng-template>

<ng-template #resolutionCategoriesTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/service-desk/resolution-categories/edit/{{
      data?.data?.id
    }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #inStocksTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/stocks/in-stocks/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #typeStaffingTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/settings/type-staffing/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #acquisitionStateTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/settings/acquisition-state/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #acquisitionModeTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/assets/settings/acquisition-mode/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #warningMessageTemplate let-data let-column="column">
  <span class="dx-tag-content" style="
      padding-right: 10px;
      margin-right: 5px;
      padding-top: 1px;
      padding-bottom: 1px;
      margin-top: 0px;
      margin-bottom: 0px;
    " *ngIf="data?.value != null && data?.value != undefined">{{ getWarningMessageType(data?.value) }}</span>
</ng-template>

<ng-template #emailPrototypeTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/administration/alarm/prototypes/edit/{{ data?.data?.id }}">
    {{ data?.value }}</a>
</ng-template>

<ng-template #privilegeEntryTemplate let-data let-column="column">
  {{ 'entities/privilegeEntry/' + data?.value | translate }}
</ng-template>

<ng-template #loanAssetsTemplate let-data>
  <div [title]="getLoanAssetsToltip(data?.data?.assets?.data)" class="arrayPopulation">
    <span class="dx-tag-content loan-assets-array-item" *ngFor="let asset of data?.data?.assets?.data">
      {{ asset?.name }}
    </span>
  </div>
</ng-template>

<ng-template #workflowLogLinkTemplate let-data>
  <a class="cl-no-click" target="_blank" [routerLink]="
      workflowRoute(data?.data?.workflowType, data?.data?.workflowId)
    ">{{ getTranslate(data?.data?.name) }}</a>
</ng-template>

<ng-template #stateTaskTemplae let-data>
  <span style="width: 20px; display: inline-block">
    <i *ngIf="data?.value == 'WAIT_FOR_EXEC'" class="fas fa-hourglass-start" style="color: green"></i>

    <i *ngIf="data?.value == 'EXEC'" class="fas fa-play" style="color: #45a7b9"></i>
  </span>
</ng-template>

<ng-template #stateTaskTemplae let-data>
  <span style="width: 20px; display: inline-block">
    <i *ngIf="data?.value == 'WAIT_FOR_EXEC'" class="fas fa-hourglass-start" style="color: green"></i>

    <i *ngIf="data?.value == 'EXEC'" class="fas fa-play" style="color: #45a7b9"></i>
  </span>
</ng-template>

<ng-template #ticketAttachmentName let-data>
  <span [ngClass]="'ticket-attachment-name'">{{ data.value }}</span>
</ng-template>

<ng-template #attachmentTicketLink let-data let-column="column">
  <span *ngIf="data?.value == 1">{{ data?.value }}
    {{ 'entities/ticket/_title/singular' | translate | lowercase }}</span>
  <span *ngIf="data?.value > 1">{{ data?.value }}
    {{ 'entities/ticket/_title/plural' | translate | lowercase }}</span>
</ng-template>

<ng-template #softwareGroupContractTemplate let-data>
  <a class="cl-no-click" target="_blank" routerLink="/softwares/softwareGroups/edit/{{
      data?.data?.software?.data?.softwareGroupId
    }}">{{ data?.value }}</a>
</ng-template>

<ng-template #linkEmailLinkConnectorTemplate let-data>
  <a class="cl-no-click" target="_blank" [routerLink]="getUrlForEmailLink(data)">
    {{ data?.value }}</a>
</ng-template>

<ng-template #openEventTemplate let-data>
  <a class="cl-no-click">
    <i class="fas fa-folder-open"></i>
  </a>
</ng-template>

<ng-template #ticketLogAuthorTemplate let-data>
  <div *ngIf="data?.data?.entityType === 'ticketEmails'" class="arrayTemplateColumn">
    <div style="height: 20px">
      <span class="dx-tag-content arrayTemplateTagItem">{{ data?.value }}</span>
    </div>
  </div>
  <div *ngIf="data?.data?.entityType !== 'ticketEmails'">
    <span>{{ data?.value }}</span>
  </div>
  <span *ngIf="data?.value == ''">
    {{ 'entities/tracking/system' | translate }}</span>
</ng-template>

<ng-template #userDistinguishNameTemplate let-data>
  <div *ngIf="data?.value != undefined && data?.value?.includes('CN=')">
    <span>{{ getDistinguishName(data?.value) }}</span>
  </div>

  <div *ngIf="data?.value == undefined || !data?.value?.includes('CN=')">
    {{ data?.value }}
  </div>
</ng-template>

<ng-template #termsContractTemplate let-data>
  <a class="cl-no-click" target="_blank" [routerLink]="getRoutingTerms(data?.data)">{{ data?.value }}</a>
</ng-template>

<ng-template #cartStockListTemplate let-data>
  <div class="in-field-hint-wrapper">
    <div class="item-search-result in-field-hint-flexbox">
      <div>
        {{ data?.name }}
      </div>
      <div *ngIf="
          (data?.manufacturer?.data?.name !== undefined &&
            data?.manufacturer?.data?.name !== null) ||
          (data?.model?.data?.name !== undefined &&
            data?.model?.data?.name !== null)
        " style="display: inline">
        <span>{{ data?.manufacturer?.data?.name }} </span>
        <span>/</span>
        <span>{{ data?.model?.data?.name }}</span>
      </div>
    </div>
  </div>
</ng-template>
<ng-template #taskSuccessorTemplate let-data>
  <div class="user-name-email-wrapper">
    @if(data?.taskNumber != undefined){
    <div>
      N°{{ data?.taskNumber }} | {{ getTranslate(data?.title) }} |
      {{ getTranslate(data?.status.data.name) }}
    </div>
    } @else {
    <div>
      {{ data?.order }} | {{ getTranslate(data?.title) }} |
      {{ getTranslate(data?.status.data.name) }}
    </div>
    }
  </div>
</ng-template>

<ng-template #taskSuccessorLinkTemplate let-data>
  @if(data?.data.predecessor?.data?.taskNumber != undefined &&
  data?.data.ticketId != undefined){
  <div>
    <a class="cl-no-click" target="_blank" [routerLink]="taskLink(data?.data)" [queryParams]="
        queryParamsLogLink(data?.data?.__typename, data?.data?.predecessorId)
      ">
      N°{{ data?.data.predecessor?.data?.taskNumber }} |
      {{ getTranslate(data?.data.predecessor?.data?.title) }} |
      {{ getTranslate(data?.data.predecessor?.data?.status?.data?.name) }}
    </a>
  </div>
  } @if(data?.data.predecessor?.data?.taskNumber != undefined &&
  data?.data.ticketId == undefined){
  <div>
    N°{{ data?.data.predecessor?.data?.taskNumber }} |
    {{ getTranslate(data?.data.predecessor?.data?.title) }} |
    {{ getTranslate(data?.data.predecessor?.data?.status?.data?.name) }}
  </div>
  }
</ng-template>

<ng-template #taskSuccessorTaskSequenceTemplate let-data>
  <div>
    @if(data?.data.predecessor?.data?.order != undefined &&
    data?.data.incidentModelId == undefined){
    {{ data?.data.predecessor?.data?.order }} |
    {{ getTranslate(data?.data.predecessor?.data?.title) }} |
    {{ getTranslate(data?.data.predecessor?.data?.status?.data?.name) }}
    } @if(data?.data.predecessor?.data?.order == undefined &&
    data?.data.predecessor?.data?.title != undefined &&
    data?.data.incidentModelId == undefined){
    {{ getTranslate(data?.data.predecessor?.data?.title) }} |
    {{ getTranslate(data?.data.predecessor?.data?.status?.data?.name) }}
    }
  </div>
</ng-template>

<ng-template #arrayPopulationTemplate let-data let-column="column">
  <div class="arrayPopulation">
    <span class="dx-tag-content population-array-item" *ngFor="let value of data?.data?.articleKnowledgePopulation">{{
      getTranslate(value) }}</span>
  </div>
</ng-template>

<ng-template #consumableMovementTemplate let-data>
  <a target="_blank" [routerLink]="generateMovementUrl(data?.data)">{{
    data?.value
    }}</a>
</ng-template>

<ng-template #ticketAttachmentTemplate let-data>
  <a target="_blank" [routerLink]="getUrlTicket(data?.data?.__typename, data?.data?.id)">{{
    data?.value
  }}</a>
</ng-template>
