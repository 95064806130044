export class PrintHelper {
  public static createHTMLFile(filename: string, data: any) {
    const element = document.createElement('a');
    let type = 'text/html';
    let extension = '.html';

    //Création du BinaryLargeObject comprenant le fichier encodé
    const blob = new Blob([this.s2ab(atob(data))], {
      type: type,
    });

    const date = new Date();
    const dateString =
      date.getFullYear() +
      ('0' + (date.getMonth() + 1)).slice(-2) +
      ('0' + date.getDate()).slice(-2) +
      ('0' + date.getHours()).slice(-2) +
      ('0' + date.getMinutes()).slice(-2);

    element.href = URL.createObjectURL(blob);
    element.download = filename + '_print_' + dateString + extension;

    document.body.appendChild(element);
    element.click();
    setTimeout(() => {
      element.remove();
    });
  }

  private static s2ab(s) {
    var buf = new ArrayBuffer(s.length);
    var view = new Uint8Array(buf);
    for (var i = 0; i != s.length; ++i) view[i] = s.charCodeAt(i) & 0xff;
    return buf;
  }
}
