import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { StarRatingComponent as StarCompo } from 'angular-star-rating';
import { SatisfactionSettingCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { SatisfactionSetting } from '@clarilog/core/services2/graphql/generated-types/types';

@Component({
  selector: 'cl-star-rating',
  templateUrl: './star-rating.component.html',
  styleUrls: ['./star-rating.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StarRatingComponent),
      multi: true,
    },
  ],
})
export class StarRatingComponent implements ControlValueAccessor, OnInit {
  constructor(private ratingOption: SatisfactionSettingCoreService) {}

  @ViewChild(StarCompo) startComponent: StarCompo;
  @Input() numOfStars: number = 6;

  title: string = '';
  getLabelText() {
    return this.title;
  }
  timeout;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  selectedRating = 6;

  onRatingChange(e) {
    if (e.rating === undefined || e.rating === 0) {
      this.personalRating = this.selectedRating;
      this.startComponent.setRating(this.selectedRating);
    } else {
      this.personalRating = e.rating;
    }

    this.title = this.findTitleRating(this.personalRating);
  }

  findTitleRating(rating: number): string {
    if (
      this.satisfactionSettings == undefined ||
      this.satisfactionSettings.length === 0 ||
      this.optionMode === true
    ) {
      return '';
    }

    return this.satisfactionSettings.find((el) => el.rating === rating)?.name;
  }

  onHoverRatingChange(e) {
    clearTimeout(this.timeout);

    if (this.startComponent) {
      this.startComponent.onStopHover = () => {
        this.timeout = setTimeout((_) => {
          this.personalRating = this.selectedRating;
          this.title = this.findTitleRating(this.personalRating);
          clearTimeout(this.timeout);
        }, 300);
      };
    }

    this.timeout = setTimeout((_) => {
      if (e.hoverRating != undefined && e.hoverRating != this.personalRating) {
        this.personalRating = e.hoverRating;
        this.title = this.findTitleRating(e.hoverRating);
      }
    }, 200);
  }
  writeValue(obj: any): void {
    this.personalRating = obj;

    if (this.personalRating == undefined || this.personalRating === 0) {
      this.personalRating = 6;
    }

    if (this.personalRating === 6) {
      //fix bug sinon tous le temps 5
      setTimeout((_) => {
        this.personalRating = 6;
      }, 100);
    }
    if (obj == undefined && this.readonly !== true) {
      this.onChange(this.personalRating);
      this.onTouched();
    }
    this.title = this.findTitleRating(this.personalRating);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    this.readonly = isDisabled;
  }

  public personalRating: number = 6;
  @Input() fieldName: string;
  @Input() state: ModelState;
  @Input() type: string;
  @Input() readonly: boolean;
  @Input() optionMode: boolean = false;

  public satisfactionSettings: SatisfactionSetting[] = [];
  public isInit = true;
  starClickChange(event) {
    this.selectedRating = event.rating;
    this.title = this.findTitleRating(event.rating);
    if (this.readonly !== true) {
      this.onChange(this.personalRating);
      this.onTouched();
    }
  }
  ngOnInit(): void {
    this.ratingOption
      .find([
        GqlSubField.create('data', [
          GqlField.create('rating'),
          GqlField.create('commentaryRequired'),
          GqlField.create('name'),
        ]),
      ])
      .subscribe((res) => {
        this.satisfactionSettings = res.data;
        this.title = this.findTitleRating(this.personalRating);
        setTimeout((_) => {
          this.isInit = true;
        });
      });
  }
}
