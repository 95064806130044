import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSystemAccountsBaseVariables, FirstSystemAccountsBaseVariables, CountSystemAccountsBaseVariables, FindSystemAccountsBaseVariables, SearchSystemAccountsBaseVariables, ExportSchemaSystemAccountsBaseVariables, ExportDataSystemAccountsBaseVariables, CustomQuerySystemAccountsBaseVariables, CustomQueryIdSystemAccountsBaseVariables, UsedSystemAccountsBaseVariables, ExistSystemAccountsBaseVariables } from '../gqls'
import { GetSystemAccountsDocument, FirstSystemAccountsDocument, CountSystemAccountsDocument, FindSystemAccountsDocument, SearchSystemAccountsDocument, ExportSchemaSystemAccountsDocument, ExportDataSystemAccountsDocument, CustomQuerySystemAccountsDocument, CustomQueryIdSystemAccountsDocument, UsedSystemAccountsDocument, ExistSystemAccountsDocument } from '../gqls'
import { ServiceSingleResultOfSystemAccount, QueryContextOfSystemAccount, FilterOfSystemAccount, ServiceSingleResultOfInt64, ServiceListResultOfSystemAccount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SystemAccountBaseService {
    
public modelName = 'systemAccount';
public modelPluralName = 'systemAccounts';

	private getSystemAccountsQuery: GetSystemAccountsDocument;
	private firstSystemAccountsQuery: FirstSystemAccountsDocument;
	private countSystemAccountsQuery: CountSystemAccountsDocument;
	private findSystemAccountsQuery: FindSystemAccountsDocument;
	private searchSystemAccountsQuery: SearchSystemAccountsDocument;
	private exportSchemaSystemAccountsQuery: ExportSchemaSystemAccountsDocument;
	private exportDataSystemAccountsQuery: ExportDataSystemAccountsDocument;
	private customQuerySystemAccountsQuery: CustomQuerySystemAccountsDocument;
	private customQueryIdSystemAccountsQuery: CustomQueryIdSystemAccountsDocument;
	private usedSystemAccountsQuery: UsedSystemAccountsDocument;
	private existSystemAccountsQuery: ExistSystemAccountsDocument;

	constructor(private injector: Injector) {
		this.getSystemAccountsQuery = this.injector.get(GetSystemAccountsDocument);
		this.firstSystemAccountsQuery = this.injector.get(FirstSystemAccountsDocument);
		this.countSystemAccountsQuery = this.injector.get(CountSystemAccountsDocument);
		this.findSystemAccountsQuery = this.injector.get(FindSystemAccountsDocument);
		this.searchSystemAccountsQuery = this.injector.get(SearchSystemAccountsDocument);
		this.exportSchemaSystemAccountsQuery = this.injector.get(ExportSchemaSystemAccountsDocument);
		this.exportDataSystemAccountsQuery = this.injector.get(ExportDataSystemAccountsDocument);
		this.customQuerySystemAccountsQuery = this.injector.get(CustomQuerySystemAccountsDocument);
		this.customQueryIdSystemAccountsQuery = this.injector.get(CustomQueryIdSystemAccountsDocument);
		this.usedSystemAccountsQuery = this.injector.get(UsedSystemAccountsDocument);
		this.existSystemAccountsQuery = this.injector.get(ExistSystemAccountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSystemAccount> {
    
    		let variables: GetSystemAccountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.get));
            }
            else{
                let result:ServiceSingleResultOfSystemAccount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSystemAccount,
		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSystemAccount> {
    
    		let variables: FirstSystemAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSystemAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSystemAccount,
		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemAccount> {
    
    		let variables: FindSystemAccountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSystemAccount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemAccount> {
    
    		let variables: SearchSystemAccountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSystemAccountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSystemAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSystemAccount,
		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemAccount> {
    
    		let variables: CustomQuerySystemAccountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSystemAccount,
		@Args('filter?') filter?: FilterOfSystemAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemAccount> {
    
    		let variables: CustomQueryIdSystemAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSystemAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSystemAccountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSystemAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemAccounts.exist));
    	}
    
    
}