import { Injectable, KeyValueDiffers } from '@angular/core';
import { ObjectTrack } from './object-track';
/**
 * Permet de créer une instance d'un tracker
 */
@Injectable({
  providedIn: 'root'
})
export class ObjectTrackFactory {
  constructor(private differs: KeyValueDiffers) { }
  /**
   * Créé une instance d'un objet de type @see ObjectTrack<T> .
   */
  create<T = {}>() {
    return new ObjectTrack<T>(this.differs);
  }
}
