<dx-tag-box
  [readOnly]="control.readOnly || disabled === true"
  [value]="value"
  [dataSource]="tagBoxDataSource"
  [showClearButton]="control.options.showClearButton"
  [placeholder]="control.options.emptyText"
  [searchMode]="'contains'"
  [minSearchLength]="0"
  [searchEnabled]="true"
  [valueExpr]="control.options.valueExpr"
  [showSelectionControls]="true"
  [displayExpr]="displayExpr"
  [acceptCustomValue]="control.options.acceptCustomValue"
  [multiline]="true"
  (onCustomItemCreating)="addTagBoxCustomItem($event, control)"
  (onInitialized)="initialiseTagBox($event, control)"
  (onValueChanged)="onTagBoxValueChanged($event, control)"
  (onFocusOut)="lostFocusTagBox($event, control)"
  (onKeyUp)="tagBoxKeyPressed($event, control)"
  [itemTemplate]="'item'"
>
  <ng-container *ngIf="control?.options?.itemTemplate">
    <div *dxTemplate="let data of 'item'">
      <ng-template
        *ngTemplateOutlet="
          templateService.get(control.options.itemTemplate);
          context: { $implicit: data }
        "
      >
      </ng-template>
    </div>
  </ng-container>
</dx-tag-box>
