import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';
import DataSource from 'devextreme/data/data_source';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '../../../shared2/services/translate/translate.service';
import { CoreListComponent } from '@clarilog/shared2/components/list/list/list.component';
import { CoreSelectListComponent } from '@clarilog/shared2/components/list/select-list/select-list.component';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import {
  CoreModelCompilerService,
  ModelFieldCompilerService,
} from '@clarilog/shared2/services';

/**
 * Action de mise a jour massive de liens entre objets
 *
 */
@Component({
  selector: 'clc-massive-link',
  templateUrl: './massive-link.component.html',
  styleUrls: ['./massive-link.component.scss'],
})
export class CoreMassiveLinkComponent implements OnInit {
  @Input() selectedKeys: any[];
  @Input() inputModel: any;
  @Input() service: any;

  @Input() addFn;
  @Input() removeFn;

  isLoading = false;
  formGroup: UntypedFormGroup = new UntypedFormGroup({});

  @Output() onSaved = new EventEmitter<any>();
  @ViewChild(CoreSelectListComponent, { static: true })
  selectListComponent: CoreSelectListComponent;
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;

  @Input() title = '';

  _isVisible: boolean = false;
  isVisibleSelectPopup = false;
  selectedIds: any[] = [];
  selectedData: any[] = [];

  items = [];
  dataSource: ModelDataSourceContext;

  @Input() state: ModelState;

  @Output() isVisibleChange = new EventEmitter<boolean>();
  @Output() selectedKeysChange = new EventEmitter<any[]>();

  @Input()
  set isVisible(value: boolean) {
    this._isVisible = value;
    this.isVisibleChange.emit(this._isVisible);
    if (this._isVisible === true) {
      this.createForm();
    }
  }
  get isVisible(): boolean {
    return this._isVisible;
  }

  get control(): any {
    return this.state.model.form.layout.pages[0]['control'];
  }

  constructor(
    private _router: Router,
    private route: ActivatedRoute,
    private modelCompilerService: CoreModelCompilerService,
  ) { }

  ngOnInit(): void {
    this.createForm();
  }
  close() {
    this.isLoading = false;
    this.isVisible = false;
  }

  add(e) {
    if (this.addFn === undefined) {
      throw new Error("Pas de fonction d'ajout dans le MassiveLinkComponent");
    } else {
      this.isLoading = true;
      this.state.sharedContext.params.set('ids', () => this.selectedKeys);
      this.state.sharedContext.params.set(this.control.fieldName, () =>
        this.items.map((f) => f.id),
      );
      let addFnc = this.addFn.fnCall();
      addFnc.subscribe((res) => {
        if (res.data === true) {
          notify(TranslateService.get('saveSuccess'), 'success', 5000);
          this.onSaved.emit(this.items.map((x) => x.id));
        } else {
          notify(TranslateService.get('actionError'), 'error', 5000);
        }
        this.selectedKeysChange.emit([]);
        this.isLoading = false;

        this.close();
      });
    }
  }

  setCompilerContext() { }

  remove(e) {
    if (this.removeFn === undefined) {
      throw new Error(
        'Pas de fonction de suppression dans le MassiveLinkComponent',
      );
    } else {
      this.isLoading = true;
      this.state.sharedContext.params.set('ids', () => this.selectedKeys);
      this.state.sharedContext.params.set(this.control.fieldName, () =>
        this.items.map((f) => f.id),
      );
      let removeFnc = this.removeFn.fnCall();

      removeFnc.subscribe((res) => {
        if (res.data === true) {
          notify(TranslateService.get('saveSuccess'), 'success', 5000);
          this.onSaved.emit(this.items.map((x) => x.id));
        } else {
          notify(TranslateService.get('actionError'), 'error', 5000);
        }
        this.selectedKeysChange.emit([]);
        this.isLoading = false;

        this.close();
      });
    }
  }

  private createDataSource() {
    this.dataSource = new ModelDataSourceContext({
      datasource: new DataSource(this.items),
    });
  }

  private createForm() {
    this.modelCompilerService.compile(this.inputModel).subscribe((res) => {
      this.state = res;
      this.state.sharedContext.params.set('id', () => null);
      this.state.sharedContext.params.set('ids', () => this.selectedKeys);
      this.state.sharedContext.params.set('linkIds', () =>
        this.items.map((x) => x.id),
      );
      // this.formGroup = this.loader.create(this.model);
      this.items = [];
      this.createDataSource();
      this.addFn = this.control.options.addItems;
      this.removeFn = this.control.options.removeItems;
    });
  }

  public open(e) {
    this.createForm();
  }

  removeLink(e) {
    for (let item of this.selectedData) {
      const index: number = this.items.indexOf(item);
      if (index !== -1) {
        this.items.splice(index, 1);
      }
    }
    this.list.refresh();
  }

  onGoTo() {
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
  }

  show(e) {
    let filters: any[] = [];

    for (let item of this.items) {
      if (filters.length > 0) {
        filters.push('and');
      }
      filters.push(['id', '<>', item['id']]);
    }

    if (this.selectListComponent.source === undefined) {
      this.selectListComponent.source = this.control.options.selectSource;
    }

    if (filters.length > 0) {
      this.selectListComponent.source.datasource.filter(filters);
    }
    this.selectListComponent.refresh();
  }

  hidden(e) {
    this.selectListComponent.clear();
  }

  select(e) {
    this.items.push(...e);
    this.isVisibleSelectPopup = false;
    this.list.refresh();
    this.selectListComponent.clear();
  }
}
