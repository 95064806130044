import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNtLogEventsBaseVariables, FirstNtLogEventsBaseVariables, CountNtLogEventsBaseVariables, FindNtLogEventsBaseVariables, SearchNtLogEventsBaseVariables, ExportSchemaNtLogEventsBaseVariables, ExportDataNtLogEventsBaseVariables, CustomQueryNtLogEventsBaseVariables, CustomQueryIdNtLogEventsBaseVariables, UsedNtLogEventsBaseVariables, ExistNtLogEventsBaseVariables } from '../gqls'
import { GetNtLogEventsDocument, FirstNtLogEventsDocument, CountNtLogEventsDocument, FindNtLogEventsDocument, SearchNtLogEventsDocument, ExportSchemaNtLogEventsDocument, ExportDataNtLogEventsDocument, CustomQueryNtLogEventsDocument, CustomQueryIdNtLogEventsDocument, UsedNtLogEventsDocument, ExistNtLogEventsDocument } from '../gqls'
import { ServiceSingleResultOfNtLogEvent, QueryContextOfNtLogEvent, FilterOfNtLogEvent, ServiceSingleResultOfInt64, ServiceListResultOfNtLogEvent, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NtLogEventBaseService {
    
public modelName = 'ntLogEvent';
public modelPluralName = 'ntLogEvents';

	private getNtLogEventsQuery: GetNtLogEventsDocument;
	private firstNtLogEventsQuery: FirstNtLogEventsDocument;
	private countNtLogEventsQuery: CountNtLogEventsDocument;
	private findNtLogEventsQuery: FindNtLogEventsDocument;
	private searchNtLogEventsQuery: SearchNtLogEventsDocument;
	private exportSchemaNtLogEventsQuery: ExportSchemaNtLogEventsDocument;
	private exportDataNtLogEventsQuery: ExportDataNtLogEventsDocument;
	private customQueryNtLogEventsQuery: CustomQueryNtLogEventsDocument;
	private customQueryIdNtLogEventsQuery: CustomQueryIdNtLogEventsDocument;
	private usedNtLogEventsQuery: UsedNtLogEventsDocument;
	private existNtLogEventsQuery: ExistNtLogEventsDocument;

	constructor(private injector: Injector) {
		this.getNtLogEventsQuery = this.injector.get(GetNtLogEventsDocument);
		this.firstNtLogEventsQuery = this.injector.get(FirstNtLogEventsDocument);
		this.countNtLogEventsQuery = this.injector.get(CountNtLogEventsDocument);
		this.findNtLogEventsQuery = this.injector.get(FindNtLogEventsDocument);
		this.searchNtLogEventsQuery = this.injector.get(SearchNtLogEventsDocument);
		this.exportSchemaNtLogEventsQuery = this.injector.get(ExportSchemaNtLogEventsDocument);
		this.exportDataNtLogEventsQuery = this.injector.get(ExportDataNtLogEventsDocument);
		this.customQueryNtLogEventsQuery = this.injector.get(CustomQueryNtLogEventsDocument);
		this.customQueryIdNtLogEventsQuery = this.injector.get(CustomQueryIdNtLogEventsDocument);
		this.usedNtLogEventsQuery = this.injector.get(UsedNtLogEventsDocument);
		this.existNtLogEventsQuery = this.injector.get(ExistNtLogEventsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNtLogEvent> {
    
    		let variables: GetNtLogEventsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.get));
            }
            else{
                let result:ServiceSingleResultOfNtLogEvent={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNtLogEvent,
		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNtLogEvent> {
    
    		let variables: FirstNtLogEventsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNtLogEventsBaseVariables = {
    			filter: filter
    		}
    				return this.countNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNtLogEvent,
		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNtLogEvent> {
    
    		let variables: FindNtLogEventsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNtLogEvent,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNtLogEvent> {
    
    		let variables: SearchNtLogEventsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNtLogEventsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNtLogEventsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNtLogEvent,
		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNtLogEvent> {
    
    		let variables: CustomQueryNtLogEventsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNtLogEvent,
		@Args('filter?') filter?: FilterOfNtLogEvent,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNtLogEvent> {
    
    		let variables: CustomQueryIdNtLogEventsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNtLogEventsBaseVariables = {
    			ids: ids
    		}
    				return this.usedNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNtLogEventsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNtLogEventsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ntLogEvents.exist));
    	}
    
    
}