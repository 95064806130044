export const DEFAULT_COLORS: string[] = [
    '#51A7F9',
    '#6FC040',
    '#F5D427',
    '#F3901D',
    '#ED5C57',
    '#B36AE2',
    '#0C64C0',
    '#0C882A',
    '#DCBE22',
    '#DE6A19',
    '#C82613',
    '#763E9B',
    '#174E86',
    '#0F5C1A',
    '#C3971D',
    '#BE5B17',
    '#861106',
    '#5E327C',
    '#002451',
    '#06400C',
    '#A37519',
    '#934511',
    '#570606',
    '#3B204D',
    '#000000',
    '#333333',
    '#666666', 
    '#999999',
    '#CCCCCC',
    '#FFFFFF'
];