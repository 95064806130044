<div *ngIf="model" style="height: 100%">
  <dx-tab-panel
    #tabPanel
    [selectedIndex]="0"
    [height]="'100%'"
    [dataSource]="sourceTab"
    [loop]="false"
    [animationEnabled]="false"
    [swipeEnabled]="false"
    [showNavButtons]="false"
  >
    <div *dxTemplate="let item of 'item'" style="overflow: auto">
      <cl-work-item-section
        *ngFor="let section of item.item.sections"
        [formGroup]="
          model.form
            .get(model.model.form.layout.pages[0].name)
            .get(item.item.name)
        "
        [location]="section.location"
      >
        <clc-work-item-section-group
          *ngFor="let group of section.groups | orderBy: sortByName"
          [title]="group.label "
          [formGroup]="
            model.form
              .get(model.model.form.layout.pages[0].name)
              .get(item.item.name)
              .get(group.name)
          "
          [visibled]="
            model.form
              .get(model.model.form.layout.pages[0].name)
              .get(item.item.name)
              .get(group.name).visibled
          "
        >
          <clc-work-item-control-dynamic
            [controls]="group.controls "
            [mode]="'inline'"
            [autoFocus]="
              page().sections[0] === section && section.groups[0] === group
            "
            [state]="model"
          >
          </clc-work-item-control-dynamic>
        </clc-work-item-section-group>
      </cl-work-item-section>
    </div>
  </dx-tab-panel>
</div>

<div
  class="no-data"
  [ngStyle]="{ display: noData === true ? 'block' : 'none' }"
>
  <div class="center">
    {{ 'globals/noData' | translate }}
  </div>
</div>
<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="!loaded"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
