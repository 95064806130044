import { Injectable } from '@angular/core';
import { TranslateService } from '@clarilog/shared';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { CoreWorkFormComponent } from '@clarilog/shared2/components/form/work-form/work-form.component';

@Injectable({
  providedIn: 'root',
})
export class TextBoxHelperService {
  constructor() {}

  translateEntityType(
    workForm: CoreWorkFormComponent,
    type: string,
    typeFieldName: string = 'typeTranslate',
    subscribeChange: boolean = false,
  ) {
    let typeField = FormGroupHelpers.formControlByName(
      workForm.form,
      typeFieldName,
    );
    if (typeField != undefined) {
      typeField.setValue(
        TranslateService.get(`entities/${type}/_title/singular`),
      );
      if (subscribeChange) {
        //probleme de reload apres save
        typeField.valueChanges.subscribe((d) => {
          typeField.setValue(
            TranslateService.get(`entities/${type}/_title/singular`),
            { emitEvent: false },
          );
        });
      }
    }
  }
}
