import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowScheduleTasksBaseVariables, FirstWorkflowScheduleTasksBaseVariables, CountWorkflowScheduleTasksBaseVariables, FindWorkflowScheduleTasksBaseVariables, SearchWorkflowScheduleTasksBaseVariables, ExportSchemaWorkflowScheduleTasksBaseVariables, ExportDataWorkflowScheduleTasksBaseVariables, CustomQueryWorkflowScheduleTasksBaseVariables, CustomQueryIdWorkflowScheduleTasksBaseVariables, UsedWorkflowScheduleTasksBaseVariables, ExistWorkflowScheduleTasksBaseVariables, InsertWorkflowScheduleTasksBaseVariables, UpdateWorkflowScheduleTasksBaseVariables, ImportDataWorkflowScheduleTasksBaseVariables, UpdateManyWorkflowScheduleTasksBaseVariables, DeleteWorkflowScheduleTasksBaseVariables } from '../gqls'
import { GetWorkflowScheduleTasksDocument, FirstWorkflowScheduleTasksDocument, CountWorkflowScheduleTasksDocument, FindWorkflowScheduleTasksDocument, SearchWorkflowScheduleTasksDocument, ExportSchemaWorkflowScheduleTasksDocument, ExportDataWorkflowScheduleTasksDocument, CustomQueryWorkflowScheduleTasksDocument, CustomQueryIdWorkflowScheduleTasksDocument, UsedWorkflowScheduleTasksDocument, ExistWorkflowScheduleTasksDocument, InsertWorkflowScheduleTasksDocument, UpdateWorkflowScheduleTasksDocument, ImportDataWorkflowScheduleTasksDocument, UpdateManyWorkflowScheduleTasksDocument, DeleteWorkflowScheduleTasksDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowScheduleTask, QueryContextOfWorkflowScheduleTask, FilterOfWorkflowScheduleTask, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowScheduleTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowScheduleTaskInput, FieldUpdateOperatorOfWorkflowScheduleTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowScheduleTaskBaseService {
    
public modelName = 'workflowScheduleTask';
public modelPluralName = 'workflowScheduleTasks';

	private getWorkflowScheduleTasksQuery: GetWorkflowScheduleTasksDocument;
	private firstWorkflowScheduleTasksQuery: FirstWorkflowScheduleTasksDocument;
	private countWorkflowScheduleTasksQuery: CountWorkflowScheduleTasksDocument;
	private findWorkflowScheduleTasksQuery: FindWorkflowScheduleTasksDocument;
	private searchWorkflowScheduleTasksQuery: SearchWorkflowScheduleTasksDocument;
	private exportSchemaWorkflowScheduleTasksQuery: ExportSchemaWorkflowScheduleTasksDocument;
	private exportDataWorkflowScheduleTasksQuery: ExportDataWorkflowScheduleTasksDocument;
	private customQueryWorkflowScheduleTasksQuery: CustomQueryWorkflowScheduleTasksDocument;
	private customQueryIdWorkflowScheduleTasksQuery: CustomQueryIdWorkflowScheduleTasksDocument;
	private usedWorkflowScheduleTasksQuery: UsedWorkflowScheduleTasksDocument;
	private existWorkflowScheduleTasksQuery: ExistWorkflowScheduleTasksDocument;
	private insertWorkflowScheduleTasksMutation: InsertWorkflowScheduleTasksDocument;
	private updateWorkflowScheduleTasksMutation: UpdateWorkflowScheduleTasksDocument;
	private importDataWorkflowScheduleTasksMutation: ImportDataWorkflowScheduleTasksDocument;
	private updateManyWorkflowScheduleTasksMutation: UpdateManyWorkflowScheduleTasksDocument;
	private deleteWorkflowScheduleTasksMutation: DeleteWorkflowScheduleTasksDocument;

	constructor(private injector: Injector) {
		this.getWorkflowScheduleTasksQuery = this.injector.get(GetWorkflowScheduleTasksDocument);
		this.firstWorkflowScheduleTasksQuery = this.injector.get(FirstWorkflowScheduleTasksDocument);
		this.countWorkflowScheduleTasksQuery = this.injector.get(CountWorkflowScheduleTasksDocument);
		this.findWorkflowScheduleTasksQuery = this.injector.get(FindWorkflowScheduleTasksDocument);
		this.searchWorkflowScheduleTasksQuery = this.injector.get(SearchWorkflowScheduleTasksDocument);
		this.exportSchemaWorkflowScheduleTasksQuery = this.injector.get(ExportSchemaWorkflowScheduleTasksDocument);
		this.exportDataWorkflowScheduleTasksQuery = this.injector.get(ExportDataWorkflowScheduleTasksDocument);
		this.customQueryWorkflowScheduleTasksQuery = this.injector.get(CustomQueryWorkflowScheduleTasksDocument);
		this.customQueryIdWorkflowScheduleTasksQuery = this.injector.get(CustomQueryIdWorkflowScheduleTasksDocument);
		this.usedWorkflowScheduleTasksQuery = this.injector.get(UsedWorkflowScheduleTasksDocument);
		this.existWorkflowScheduleTasksQuery = this.injector.get(ExistWorkflowScheduleTasksDocument);
		this.insertWorkflowScheduleTasksMutation = this.injector.get(InsertWorkflowScheduleTasksDocument);
		this.updateWorkflowScheduleTasksMutation = this.injector.get(UpdateWorkflowScheduleTasksDocument);
		this.importDataWorkflowScheduleTasksMutation = this.injector.get(ImportDataWorkflowScheduleTasksDocument);
		this.updateManyWorkflowScheduleTasksMutation = this.injector.get(UpdateManyWorkflowScheduleTasksDocument);
		this.deleteWorkflowScheduleTasksMutation = this.injector.get(DeleteWorkflowScheduleTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowScheduleTask> {
    
    		let variables: GetWorkflowScheduleTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowScheduleTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowScheduleTask,
		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowScheduleTask> {
    
    		let variables: FirstWorkflowScheduleTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowScheduleTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowScheduleTask,
		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowScheduleTask> {
    
    		let variables: FindWorkflowScheduleTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowScheduleTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowScheduleTask> {
    
    		let variables: SearchWorkflowScheduleTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowScheduleTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowScheduleTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowScheduleTask,
		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowScheduleTask> {
    
    		let variables: CustomQueryWorkflowScheduleTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowScheduleTask,
		@Args('filter?') filter?: FilterOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowScheduleTask> {
    
    		let variables: CustomQueryIdWorkflowScheduleTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowScheduleTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowScheduleTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowScheduleTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowScheduleTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowScheduleTask> {
    
    		let variables: InsertWorkflowScheduleTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowScheduleTask> {
    
    		let variables: UpdateWorkflowScheduleTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowScheduleTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowScheduleTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowScheduleTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowScheduleTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowScheduleTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowScheduleTasks.delete));
    	}
    
}