<cl-validation-error [message]="error"></cl-validation-error>
<div class="list-component">
  <dx-data-grid
    #grid
    class="grid"
    [keyExpr]="'id'"
    (onInitialized)="onInitialized($event)"
    (onToolbarPreparing)="onToolbarPreparing($event)"
    (onOptionChanged)="onOptionChanged($event)"
    [dataSource]="source"
    [columnAutoWidth]="true"
    [columnMinWidth]="150"
    columnResizingMode="widget"
    [allowColumnResizing]="true"
    [allowColumnReordering]="true"
    [selection]="{
      mode: false
    }"
    [hoverStateEnabled]="true"
    [remoteOperations]="{
      paging: true,
      filtering: true,
      sorting: true,
      grouping: false,
      groupPaging: true,
      summary: false
    }"
    [showBorders]="false"
    [showRowLines]="false"
    [columnAutoWidth]="false"
    [allowColumnResizing]="true"
    [searchPanel]="{
      visible: false,
      width: 200,
      placeholder: 'globals/search' | translate
    }"
    [columnChooser]="{ enabled: false }"
    [paging]="{
      enable: true,
      pageIndex: 0,
      pageSize: 10
    }"
    [pager]="{
      visible: false
    }"
    [groupPanel]="{
      visible: true
    }"
    [grouping]="{
      enabled: true,
      contextMenuEnabled: true
    }"
    [sorting]="{
      mode: 'multiple'
    }"
    [filterRow]="{
      visible: false
    }"
    [filterPanel]="{
      visible: false
    }"
  >
    <dxo-scrolling useNative="true" mode="standard"></dxo-scrolling>

    <dxi-column
      *ngFor="let column of gridColumns"
      [dataField]="column.dataField"
      [caption]="column.caption"
      [groupIndex]="column.groupIndex"
      [cellTemplate]="column.template"
      [name]="column.name"
      [allowSorting]="column.allowSorting"
      [allowFiltering]="false"
      [sortIndex]="column.sortIndex"
      [sortOrder]="column.sortOrder"
      [allowGrouping]="column.allowGrouping"
      [visible]="column.visible"
      [dataType]="column.dataType"
      [format]="column.format"
      [minWidth]="150"
      [width]="150"
    >
    </dxi-column>
    <!-- Template spécifique pour les zone de type booelan -->
    <div *dxTemplate="let data of 'booleanTemplate'">
      <i
        *ngIf="data.value"
        style="color: var(--primary)"
        class="far fa-check"
      ></i>
    </div>

    <ng-container *ngFor="let template of defaultTemplates">
      <div *dxTemplate="let data of template">
        <ng-template
          *ngTemplateOutlet="
            templateService.get(template);
            context: { $implicit: data }
          "
        >
        </ng-template>
      </div>
    </ng-container>
  </dx-data-grid>

  <dx-popup
    [ngClass]="'cl-popup-column-chooser'"
    [width]="400"
    [height]="'80%'"
    [showTitle]="true"
    [title]="'globals/columnChooser' | translate"
    [resizeEnabled]="true"
    [hideOnOutsideClick]="false"
    [deferRendering]="true"
    [showCloseButton]="false"
    [(visible)]="showColumnChooser"
    (onHidden)="onHidden($event)"
  >
    <dx-box direction="col" width="100%" height="100%">
      <dxi-item [ratio]="1">
        <dx-tree-list
          (onSelectionChanged)="onSelectionChanged($event)"
          [height]="'100%'"
          [dataSource]="columnsChooser"
          [(selectedRowKeys)]="columnVisible"
          itemsExpr="items"
          dataStructure="plain"
          keyExpr="id"
          parentIdExpr="parentId"
          [showRowLines]="true"
          [showBorders]="true"
          [columnAutoWidth]="true"
          [searchPanel]="{
            visible: true
          }"
          [selection]="{
            allowSelectAll: true,
            mode: 'multiple',
            recursive: true
          }"
        >
          <dxi-column dataField="caption" caption="Nom"></dxi-column>
        </dx-tree-list>
      </dxi-item>

      <dxi-item [baseSize]="50">
        <div style="text-align: right; padding-top: 5px">
          <dx-button
            [text]="'ok' | translate"
            type="default"
            (onClick)="ok($event)"
            [elementAttr]="{ class: 'load-button' }"
          >
          </dx-button>

          <dx-button
            [text]="'cancel' | translate"
            type="default"
            (onClick)="cancel($event)"
            [elementAttr]="{ class: 'load-button' }"
          >
          </dx-button>
        </div>
      </dxi-item>
    </dx-box>
  </dx-popup>
</div>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="loadPanel"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
