<dx-button
  class="button"
  stylingMode="contained"
  [text]="text"
  type="normal"
  [width]="'auto'"
  (onClick)="click($event)"
  *ngIf="id != undefined"
>
</dx-button>

<!-- <div  *ngIf="id == undefined"></div>>
<clc-work-item-field
  *ngFor="let control of controls"
  [mode]="control.mode || mode"
  [label]="control.label"
  [hint]="control.hint"
  [loaded]="parent.form.root.loaded"
  [errors]="getFormControl(control.name).errors"
  [required]="getFormControl(control.name).required"
  [visibled]="getVisible(control)"
  [clcSwitchOnlyOne]="control.type"
  [formGroup]="parent.form"
  [(valueChange)]="valueChange"
>
  <dx-text-box></dx-text-box>
</clc-work-item-field>
</div> -->

<dx-popup
  [width]="'35%'"
  [height]="'30%'"
  [showTitle]="false"
  class="add-item-popup create-password-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="false"
  [deferRendering]="true"
  [(visible)]="isVisible"
  [showCloseButton]="false"
>
  <cl-work-page>
    <cl-work-page-header>
      <cl-work-page-header-content>
        <cl-work-page-header-content-title [showBack]="false">
          Réinitialisation: {{ title }}
        </cl-work-page-header-content-title>
      </cl-work-page-header-content>
    </cl-work-page-header>
    <cl-work-page-content>
      <div style="padding: 10px">
        <dx-form
          id="form"
          [formData]="changePassword"
          validationGroup="customerData"
        >
          <dxi-item
            class="text-box"
            dataField="NewPassword"
            editorType="dxTextBox"
            [label]="{
              text: 'entities/networkCredential/password' | translate
            }"
            [editorOptions]="{ mode: 'password', showClearButton: true }"
          >
            <dxi-validation-rule
              type="required"
              [message]="'errors/notNullOrEmpty' | translate"
            >
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item
            class="text-box"
            dataField="confirmNewPassword"
            editorType="dxTextBox"
            [label]="{
              text: 'entities/networkCredential/verifyPassword' | translate
            }"
            [editorOptions]="{ mode: 'password', showClearButton: true }"
          >
            <dxi-validation-rule
              type="required"
              [message]="'errors/notNullOrEmpty' | translate"
            >
            </dxi-validation-rule>
            <dxi-validation-rule
              type="compare"
              [comparisonTarget]="comparePassword"
              [message]="
                'La valeur doit être égale à la valeur du champ \'Mot de passe\''
              "
            >
            </dxi-validation-rule>
          </dxi-item>

          <dxi-item
            itemType="button"
            horizontalAlignment="left"
            [buttonOptions]="{
              text: 'save' | translate,
              type: 'default',
              useSubmitBehavior: true,
              onClick: onSubmit
            }"
          >
          </dxi-item>
        </dx-form>
      </div>
    </cl-work-page-content>
  </cl-work-page>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="close($event)"></dx-button>
  </div>
</dx-popup>
