import {
  AfterContentInit,
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { Router, ActivatedRoute, NavigationExtras } from '@angular/router';
import ArrayStore from 'devextreme/data/array_store';
import DataSource from 'devextreme/data/data_source';
import { AssetModelCategory } from '../../../../core';
import { TranslateService } from '../../../services/translate/translate.service';
import { ListComponentBase } from '../list/list.component-base';
import inCartModel from './in.model.json';
import { CoreCartListSelectComponent } from '../cart-list-select/cart-list-select.component';
import { CoreListComponent } from '../list/list.component';
import { AssetModelCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset-model.service';
import { LocationCoreService } from '@clarilog/core/services2/graphql/generated-types/services/location.service';
import { AssetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.service';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { InCart } from '@clarilog/core/services2/graphql/generated-types/types';
import { alert } from 'devextreme/ui/dialog';
import {
  BudgetCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { TranslatedFieldHelperService } from '../../translate-field';
import { CoreLicenseReader } from '@clarilog/core/services2/license-reader/license-reader';
/**
 * Représente l'état dans lequel se trouve le composent LinkList.
 */
export enum CartListMode {
  /** État normal. */
  Current,
  /** État ajout. */
  Added,
  /** État supprimé */
  Removed,
}
/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-cart-list',
  templateUrl: './cart-list.component.html',
  styleUrls: ['./cart-list.component.scss'],
  viewProviders: [],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreCartListComponent),
      multi: true,
    },
  ],
})
export class CoreCartListComponent
  extends ListComponentBase
  implements ControlValueAccessor, AfterContentInit, OnInit
{
  /** Obtient ou définit le mode d'affichage. */
  public mode: CartListMode = CartListMode.Current;
  public CartListMode: typeof CartListMode = CartListMode;

  /** Obtient ou définit la query globale. */
  @Input() query: any = undefined;
  // /** Obtient ou définit les éléments ajoutés. */
  @Input() itemsAdded: any[] = [];
  // /** Obtient ou définit les éléments supprimés. */
  @Input() itemsRemoved: any[] = [];
  // /** Obtient ou définit les éléments modifiés. */
  @Input() itemsUpdated: any[] = [];
  /** Obtient ou définit l'état d'ouverture du popup. */
  @Input() associatePopup: boolean = false;
  /** Obtient ou définit le nom du label du control */
  @Input() label: string = undefined;
  /** Obtient ou définit la query du select. */
  @Input() select: any = undefined;
  /** Obtient ou définit le composent list. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;
  @Input() disableEdit: boolean = false;
  /** Obtient ou définit le composent StockSelect. */
  @ViewChild(CoreCartListSelectComponent, { static: true })
  selectComponent: CoreCartListSelectComponent;

  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;
  /** Obtient ou définit l'état activé du composent. */
  @Input() disabled: boolean;
  /** Obtient ou définit la source de données par défaut. */
  @Input() defaultSource: any = undefined;
  /** obtient ou définit le fonction permettant s'executant avant la suppression. */
  @Input() beforeRemoveItems: any = undefined;
  /** Obtient ou définit le message affiché lorsque l'on ne peut pas supprimer. */
  @Input() beforeRemoveItemsMessage: string;
  /** Obtient ou définit la valeur. */
  @Input() movementType: string;
  /** Obtient ou définit la route d'un nouveau bouton */
  @Input() routeButton: string;

  /** Obtient ou définit si on peut ajouter */
  @Input() canAdd: boolean = true;
  /** Obtient ou définit si on peut ajouter */
  @Input() canDelete: boolean = true;
  /** Obtient ou définit si on peut ajouter */
  @Input() canEdit: boolean = true;
  /** Obtient ou définit la policie des boutons */
  @Input() policies: string;
  rootForm: UntypedFormGroup;

  inCartModel;
  /** Attention si dans le json le fieldName est toto.titi alors pour le helpers ca sera toto_titi */
  inDefaultProperty = [
    { from: 'defaultStorageUnitId', to: 'storageUnitId' },
    { from: 'receivedById', to: 'receivedById' },
    { from: 'financial_budgetId', to: 'budgetId' },
  ];

  newCommandSource: DataSource = new DataSource({
    store: new ArrayStore({
      data: [
        {
          name: TranslateService.get('entities/asset/_title/singular'),
          key: AssetModelCategory.Asset,
        },
        {
          name: TranslateService.get('entities/consumable/_title/singular'),
          key: AssetModelCategory.Consumable,
        },
      ],
    }),
  });

  currentValues: any[] = [];
  removeValues: any[] = [];
  maxAssetNumber = 0;

  indexUpdate: any;
  id: string;
  isLoading: boolean;

  constructor(
    private assetModelService: AssetModelCoreService,
    public formGroupDirective: FormGroupDirective,
    private assetService: AssetCoreService,
    public licenseReader: CoreLicenseReader,
    private locationService: LocationCoreService,
    private budgetSevice: BudgetCoreService,
    private userService: UserCoreService,
    private _route: ActivatedRoute,
    private _router: Router,
    private router: ActivatedRoute,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super();
    this.rootForm = formGroupDirective.form.root as UntypedFormGroup;
    this.inCartModel = inCartModel;
    this.getMaxAssetNumber(0);
    this.id = this._route.snapshot.paramMap.get('id');

    this.source = new ModelDataSourceContext({
      datasource: new DataSource({
        store: new ArrayStore({
          data: [],
        }),
      }),
    });
  }

  /** @inheritdoc */
  ngOnInit() {}

  private async postProcess(data: any[]) {
    this.isLoading = true;
    let res = [];
    let idList = [];
    for (let item of data) {
      let result;
      let storageUnit;
      let budget;
      let receivedBy;

      result = await this.assetModelService
        .get(
          [GqlSubField.create('data', [GqlField.create('name')])],
          item.entryId,
        )
        .toPromise();
      let entryId = item.entryId;
      idList.push({ entryId, result });

      if (item?.storageUnitId != undefined) {
        if (idList.find((i) => i.storageUnitId === item.storageUnitId)) {
          storageUnit = idList.find(
            (i) => i.storageUnitId === item?.storageUnitId,
          ).storageUnit;
        } else {
          storageUnit = await this.locationService
            .get(
              [
                GqlSubField.create('data', [
                  GqlSubField.create('path', [
                    GqlField.create(
                      this.translatedFieldHelperService.getTranslateKey(),
                    ),
                  ]),
                ]),
              ],
              item.storageUnitId,
            )
            .toPromise();
          let storageUnitId = item.storageUnitId;
          idList.push({ storageUnitId, storageUnit });
        }
      }

      if (item?.budgetId != undefined) {
        if (idList.find((i) => i.budgetId === item?.budgetId)) {
          budget = idList.find((i) => i.budgetId === item?.budgetId).budget;
        } else {
          budget = await this.budgetSevice
            .get(
              [GqlSubField.create('data', [GqlField.create('name')])],
              item.budgetId,
            )
            .toPromise();
          let budgetId = item.budgetId;
          idList.push({ budgetId, budget });
        }
      }

      if (item?.receivedById != undefined) {
        if (idList.find((i) => i.receivedById === item?.receivedById)) {
          receivedBy = idList.find(
            (i) => i.receivedById === item?.receivedById,
          ).receivedBy;
        } else {
          receivedBy = await this.userService
            .get(
              [GqlSubField.create('data', [GqlField.create('name')])],
              item.receivedById,
            )
            .toPromise();
          let receivedById = item.receivedById;
          idList.push({ receivedById, receivedBy });
        }
      }

      item['entry'] = result?.data;

      if (
        storageUnit.data?.path != undefined &&
        storageUnit.data?.path[
          this.translatedFieldHelperService.getTranslateKey()
        ] != undefined
      ) {
        storageUnit.data.path =
          storageUnit.data.path[
            this.translatedFieldHelperService.getTranslateKey()
          ];
      }
      item['storage'] = storageUnit?.data;
      item['budget'] = budget?.data;
      item['receivedBy'] = receivedBy?.data;
      const itemCopy = { ...item };
      res.push(itemCopy);
    }
    this.isLoading = false;
    return res;
  }

  private getMaxAssetNumber(existValue) {
    this.assetService
      .countAll(ModelFieldCompilerService.createServiceSingleResultScalar())
      .subscribe((res) => {
        /** Peut etre quand on a un write value cas EDIT soustraire les amount de asset */
        if (res.data === -1) {
          /** Cas asset illimité */
          this.maxAssetNumber = -1;
        } else {
          this.maxAssetNumber = res.data + existValue;
        }
      });
  }

  /** Permet de gérer les données a afficher dans la liste */
  private async load(values: any[], emitEvent: boolean = false) {
    this.source = new ModelDataSourceContext({
      datasource: new DataSource({
        store: new ArrayStore({
          data: await this.postProcess(values),
        }),
        postProcess: (data) => {
          this.cleanData(this.currentValues, emitEvent);
          return data;
        },
      }),
    });
  }
  /** Permet de calculer le nombre d'asset que l'on peut créer en fonction du nombre dispo via la license et le nombre utilisé mais pas encore en base */
  private async calculateAvailableAsset() {
    let usedAsset = 0;

    for (let entry of this.currentValues) {
      usedAsset +=
        entry.category === AssetModelCategory.Asset ? entry.amount : 0;
    }
    if (
      this.selectedData[0] != undefined &&
      this.selectedData[0].category === AssetModelCategory.Asset
    ) {
      usedAsset -= this.selectedData[0].amount;
    }

    // Nombre total de bien (corbeille et hors parc)
    let total = await this.assetService
      .countAll(ModelFieldCompilerService.createServiceSingleResultScalar())
      .toPromise();

    let count = total.data + usedAsset;

    if (this.licenseReader.max(count, (l) => l.features.assetNumber)) {
      this.associatePopup = false;
      alert(
        TranslateService.get('globals/licenceMax'),
        TranslateService.get('globals/warning'),
      );
      return 0;
    }

    let available =
      this.licenseReader.currentLicense.features.assetNumber - count;
    if (available < 0) available = 0;
    return available;
  }

  /** Affiche le popup */
  async showPopUp(event, value = undefined) {
    let selectedCategory =
      event.itemData === undefined ? event : event.itemData.key;

    if (value == undefined) {
      this.indexUpdate = null;
    }

    // Mode readOnly
    this.inCartModel.form.layout.pages[0].sections[0].groups[0].controls[0].readOnly =
      this.id != undefined && value != undefined;
    this.inCartModel.form.layout.pages[0].sections[0].groups[1].controls[1].readOnly =
      this.id != undefined && value != undefined;

    if (
      selectedCategory === AssetModelCategory.Asset &&
      this.maxAssetNumber !== -1
    ) {
      let count = await this.calculateAvailableAsset();
      if (count > 0) {
        this.associatePopup = true;
      } else {
        return;
      }
      this.inCartModel.form.layout.pages[0].sections[0].groups[1].controls[1].options.max =
        count;

      if (count > 0) {
        this.inCartModel.form.layout.pages[0].sections[0].groups[1].controls[1].hint =
          TranslateService.get('entities/stock/amountMaxQuantity', count);
      }
      if (count <= 0) {
        this.inCartModel.form.layout.pages[0].sections[0].groups[1].controls[1].readOnly =
          true;
      }

      // this.inCartModel.form.layout.pages[0].sections[1].groups[0].controls[1].options.max = this.calculateAvailableAsset();
    } else {
      this.associatePopup = true;
      delete this.inCartModel.form.layout.pages[0].sections[0].groups[1]
        .controls[1].options.max;
    }

    this.selectComponent.open(selectedCategory, value);
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** @inheritdoc */
  writeValue(value: any): void {
    if (value != undefined) {
      this.currentValues = this.cleanData(value, false);
      let existingAmount = 0;
      for (let val of this.currentValues) {
        existingAmount +=
          val.category === AssetModelCategory.Asset ? val.amount : 0;
      }
      // Permets de mettre a zero la liste des elements a supprimer apres enregistrement
      if (this.removeValues.length > 0) {
        this.removeValues = [];
        this.mode = CartListMode.Current;
      }

      this.getMaxAssetNumber(existingAmount);
      this.load(this.currentValues);
    }
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  public ngAfterContentInit(): void {}

  /** Dissocie les éléments sélectionnés. */
  async dissociate(e) {
    this.selectedData.forEach((item) => {
      this.cleanItem(item);
      this.currentValues = this.arrayRemove(this.currentValues, item);
      this.removeValues.push(item);
    });
    // this.mode = CartListMode.Removed;
    this.cleanData(this.currentValues, true);
    this.load(this.currentValues);
  }

  /** permet de dissocier */
  arrayRemove(arr, value) {
    return arr.filter(function (ele) {
      return JSON.stringify(ele) !== JSON.stringify(value);
    });
  }

  /** Rafraîchi la liste. */
  public refresh() {
    this.source.datasource.reload();
  }

  /** Enlève les change inutile au formulaire pour un élement */
  private cleanItem(item: any) {
    delete item['entry'];
    delete item['storage'];
    delete item['budget'];
    delete item['receivedBy'];
    delete item['__typename'];
    for (let key of Object.keys(item)) {
      if (item[key] == null) {
        delete item[key];
      }
    }
    return item;
  }
  /** Enlève les champs pour tous un tableau */
  private cleanData(data: any[], emitEvent: boolean) {
    let res = [];
    for (let item of data) {
      res.push(this.cleanItem(item));
    }
    if (emitEvent === true) {
      this.onChange(res);
      this.onTouched();
    }
    return res;
  }
  /** Method permettant de gérer le retour de la fermeture du popup avec le retour du formulaire intermédiaire */
  public objectPass(e) {
    e.category = this.selectComponent.category;
    if (this.indexUpdate != undefined && Object.keys(e).length !== 0) {
      Object.assign(this.currentValues[this.indexUpdate], e);
    } else {
      this.currentValues.push(e);
    }

    this.load(this.currentValues, true);
    this.indexUpdate = undefined;
  }

  /** Affiche les données en cours de l'élément. */
  public current(e = null) {
    this.load(this.currentValues);
  }

  /** Ouvre le popup en mode edition et sauvegarde l'index de l'item éditer pour pouvoir le mettre a jour */
  public update(e = null) {
    const selectedItem = this.cleanItem(this.selectedData[0]);
    let index = this.currentValues.findIndex((el) => {
      return JSON.stringify(el) === JSON.stringify(selectedItem);
    });
    this.indexUpdate = index;

    this.showPopUp(
      (this.selectedData[0] as InCart).category,
      this.selectedData[0] as InCart,
    );
  }

  /** Affiche les éléments à ajouter. */
  public cancelList(e = null) {
    if (this.mode == CartListMode.Removed) {
      this.selectedData.forEach((item) => {
        this.removeValues = this.arrayRemove(this.removeValues, item);
        this.currentValues.push(item);
      });

      if (this.removeValues.length == 0) {
        this.load(this.currentValues);
      } else {
        this.load(this.removeValues);
      }
      this.mode = CartListMode.Current;
    }
  }
  /** Affiche les éléments à supprimer. */
  public removedList(e = null) {
    this.load(this.removeValues);
  }
  /** Gère la fermeture du popup */
  public close(e = null) {
    this.indexUpdate = undefined;
    this.load(this.currentValues, true);
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    let id = this.router.snapshot.paramMap.get('id');

    /** liste les différents paramètre à intégrer dans l'url */
    let filter: object = {};
    if (id != null) {
      /** Ajoute de l'id dans l'objet filter */
      Object.assign(filter, { type: id });
    }
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);

    if (defaultRoute && this.routeButton != undefined) {
      if (filter != undefined) {
        url = this._router.createUrlTree([this.routeButton], {
          queryParams: filter,
          skipLocationChange: true,
        } as NavigationExtras);
      }
    }
    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }
}
