import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketTagsBaseVariables, FirstTicketTagsBaseVariables, CountTicketTagsBaseVariables, FindTicketTagsBaseVariables, SearchTicketTagsBaseVariables, ExportSchemaTicketTagsBaseVariables, ExportDataTicketTagsBaseVariables, CustomQueryTicketTagsBaseVariables, CustomQueryIdTicketTagsBaseVariables, UsedTicketTagsBaseVariables, ExistTicketTagsBaseVariables, InsertTicketTagsBaseVariables, UpdateTicketTagsBaseVariables, ImportDataTicketTagsBaseVariables, UpdateManyTicketTagsBaseVariables, DeleteTicketTagsBaseVariables } from '../gqls'
import { GetTicketTagsDocument, FirstTicketTagsDocument, CountTicketTagsDocument, FindTicketTagsDocument, SearchTicketTagsDocument, ExportSchemaTicketTagsDocument, ExportDataTicketTagsDocument, CustomQueryTicketTagsDocument, CustomQueryIdTicketTagsDocument, UsedTicketTagsDocument, ExistTicketTagsDocument, InsertTicketTagsDocument, UpdateTicketTagsDocument, ImportDataTicketTagsDocument, UpdateManyTicketTagsDocument, DeleteTicketTagsDocument } from '../gqls'
import { ServiceSingleResultOfTicketTag, QueryContextOfTicketTag, FilterOfTicketTag, ServiceSingleResultOfInt64, ServiceListResultOfTicketTag, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketTagInput, FieldUpdateOperatorOfTicketTag } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketTagBaseService {
    
public modelName = 'ticketTag';
public modelPluralName = 'ticketTags';

	private getTicketTagsQuery: GetTicketTagsDocument;
	private firstTicketTagsQuery: FirstTicketTagsDocument;
	private countTicketTagsQuery: CountTicketTagsDocument;
	private findTicketTagsQuery: FindTicketTagsDocument;
	private searchTicketTagsQuery: SearchTicketTagsDocument;
	private exportSchemaTicketTagsQuery: ExportSchemaTicketTagsDocument;
	private exportDataTicketTagsQuery: ExportDataTicketTagsDocument;
	private customQueryTicketTagsQuery: CustomQueryTicketTagsDocument;
	private customQueryIdTicketTagsQuery: CustomQueryIdTicketTagsDocument;
	private usedTicketTagsQuery: UsedTicketTagsDocument;
	private existTicketTagsQuery: ExistTicketTagsDocument;
	private insertTicketTagsMutation: InsertTicketTagsDocument;
	private updateTicketTagsMutation: UpdateTicketTagsDocument;
	private importDataTicketTagsMutation: ImportDataTicketTagsDocument;
	private updateManyTicketTagsMutation: UpdateManyTicketTagsDocument;
	private deleteTicketTagsMutation: DeleteTicketTagsDocument;

	constructor(private injector: Injector) {
		this.getTicketTagsQuery = this.injector.get(GetTicketTagsDocument);
		this.firstTicketTagsQuery = this.injector.get(FirstTicketTagsDocument);
		this.countTicketTagsQuery = this.injector.get(CountTicketTagsDocument);
		this.findTicketTagsQuery = this.injector.get(FindTicketTagsDocument);
		this.searchTicketTagsQuery = this.injector.get(SearchTicketTagsDocument);
		this.exportSchemaTicketTagsQuery = this.injector.get(ExportSchemaTicketTagsDocument);
		this.exportDataTicketTagsQuery = this.injector.get(ExportDataTicketTagsDocument);
		this.customQueryTicketTagsQuery = this.injector.get(CustomQueryTicketTagsDocument);
		this.customQueryIdTicketTagsQuery = this.injector.get(CustomQueryIdTicketTagsDocument);
		this.usedTicketTagsQuery = this.injector.get(UsedTicketTagsDocument);
		this.existTicketTagsQuery = this.injector.get(ExistTicketTagsDocument);
		this.insertTicketTagsMutation = this.injector.get(InsertTicketTagsDocument);
		this.updateTicketTagsMutation = this.injector.get(UpdateTicketTagsDocument);
		this.importDataTicketTagsMutation = this.injector.get(ImportDataTicketTagsDocument);
		this.updateManyTicketTagsMutation = this.injector.get(UpdateManyTicketTagsDocument);
		this.deleteTicketTagsMutation = this.injector.get(DeleteTicketTagsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTag> {
    
    		let variables: GetTicketTagsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.get));
            }
            else{
                let result:ServiceSingleResultOfTicketTag={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTag,
		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTag> {
    
    		let variables: FirstTicketTagsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketTagsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTag,
		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTag> {
    
    		let variables: FindTicketTagsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketTag,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTag> {
    
    		let variables: SearchTicketTagsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketTagsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketTagsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketTag,
		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTag> {
    
    		let variables: CustomQueryTicketTagsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketTag,
		@Args('filter?') filter?: FilterOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTag> {
    
    		let variables: CustomQueryIdTicketTagsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketTagsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketTagsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketTagsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketTagInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTag> {
    
    		let variables: InsertTicketTagsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketTagsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketTag> {
    
    		let variables: UpdateTicketTagsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketTagsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketTagsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketTagsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketTag,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketTagsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketTagsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketTagsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketTagsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketTags.delete));
    	}
    
}