import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Column } from '@clarilog/shared2/models/schema';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { TemplatesService } from '../../templates/templates.service';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { TranslatedFieldHelperService } from '../../translate-field';
import { v4 as uuidv4 } from 'uuid';

/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-simple-list',
  templateUrl: './simple-list.component.html',
  styleUrls: ['./simple-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreSimpleListComponent),
      multi: true,
    },
  ],
})
export class CoreSimpleListComponent implements ControlValueAccessor, OnInit {
  @Input() noForm: boolean = false;
  @Input() type: string;
  @Input() control: any;
  @Input() fg: UntypedFormGroup;

  @Input() generateId: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() columns: Column[];
  @Input() source: ModelDataSourceContext;
  @Input() allowAdding: boolean = true;
  @Input() allowDelete: boolean = true;
  @Input() batchMode: boolean = true;
  @Input() state: ModelState;
  @Input() fieldName: string;
  namePattern = /^(\d*(\.))*\d+$/;
  /** Sauvegarde les valeurs. */
  _values: any[];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** Obtient le cellule active */
  cell: any;
  component: any;
  /** Indique si le composant est en edition */
  inEditing: boolean = false;

  /** Affiche les titres des colonnes */
  @Input() showColumnHeaders: boolean = false;
  _initializeState: boolean = false;

  selectedLanguage = 'en';
  languageSource: Array<{ key: string; value: string }> = [];
  supportedLanguages: string[];
  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    if (this._values != undefined) {
      this._values.forEach((f) => delete f['__KEY__']);
      this._values.forEach((f) => delete f['__typename']);

      // Permet de "masquer" les champs sur Nombre de biens audités illimités et Nombre de biens illimités
      this._values.map((element, index) => {
        let langKey = this.translateFieldHelperService.getTranslateKey();
        let elementTodelete = 'description.' + langKey;
        delete element[elementTodelete];
        if (
          element.name ===
          TranslateService.get('entities/license/assetUnlimited')
        ) {
          this._values.splice(index, 1);
        }
        if (
          element.name ===
          TranslateService.get('entities/license/assetAuditedUnlimited')
        ) {
          this._values.splice(index, 1);
        }
      });
    } else {
      this._values = [];
    }
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this._initializeState === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  constructor(
    public templateService: TemplatesService,
    private cd: ChangeDetectorRef,
    private translateFieldHelperService: TranslatedFieldHelperService,
  ) {
    this.uniqueValidator = this.uniqueValidator.bind(this);
    this.folderPathValidator = this.folderPathValidator.bind(this);

    this.languageSource = translateFieldHelperService.getLanguageDatasource();
    this.selectedLanguage = translateFieldHelperService.getTranslateKey();
    this.supportedLanguages =
      translateFieldHelperService.getAllSupportedLanguage();
  }

  ngOnInit() {
    if (this.source != undefined) {
      this.source.datasource.load().then((r) => {
        this.values = r;
      });
    }
    this.state.on.subscribe((event) => {
      if (
        event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
      ) {
        this.selectedLanguage = event.eventData;
        // Changement de langue
        let gridColumn = this.component.option('columns');
        gridColumn.forEach((col) => {
          let isTranslatable = this.columns.find(
            (s) => s.label == col.caption && s.translatable === true,
          );
          if (isTranslatable != undefined) {
            let split = col.dataField.split('.');
            split.splice(split.length - 1, 1);
            split.push(this.selectedLanguage);
            col.dataField = split.join('.');
          }
        });
        this.component.option('columns', gridColumn);
      }
    });
  }

  /** @inheritdoc */
  writeValue(values: any[]): void {
    this._initializeState = true;
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
    this._initializeState = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** Cas de la suppression */
  onRowRemoved(e) {
    this.getValues(e);
  }

  /** Vérifie si il faut ajouter un ID */
  canAddId(e) {
    if (this.generateId === true) {
      if (e.data['id'] == undefined) {
        e.data['id'] = uuidv4();
      }
    }
  }
  onRowInserted(e) {
    this.canAddId(e);
    this.getValues(e);
    this.inEditing = false;
  }
  onEditingStart(e) {
    this.inEditing = true;
    if (e.key == undefined) {
      this.cell = undefined;
    }
  }
  onRowUpdated(e) {
    this.canAddId(e);
    this.getValues(e);
    this.inEditing = false;
  }
  onFocusedCellChanged(e) {
    this.cell = e;
  }

  /** Controle les valeur de type trnaslated */
  controlValue() {
    this.columns
      .filter((s) => s.translatable === true)
      .forEach((c) => {
        let splitCol = c.field.split('.');
        splitCol.splice(splitCol.length - 1, 1);
        let colTranslateName = splitCol.join('.');
        if (this._values != undefined) {
          this._values.forEach((value) => {
            // Vérification des langue
            for (let lang of this.translateFieldHelperService.getAllSupportedLanguage()) {
              let keyValue = value[colTranslateName + '.' + lang];
              if (keyValue != undefined) {
                value[colTranslateName][lang] = keyValue;
                // Supprime la propriété auto génrée (exemple : description.en)
                delete value[colTranslateName + '.' + lang];
              }
            }
          });
        }
      });
  }

  getValues(e) {
    setTimeout(() => {
      this.controlValue();
      this.onChange(this._values);
      this.onTouched();
    });
    // Recupération des valeurs de la grille
    // this.onChange(this._values);
    // this.onTouched();
    // console.log(this.onTouched());
  }

  onSaved(e) {
    if (this.batchMode === true) {
      this.state.formComponent.save({ close: false });
      let ctrl = FormGroupHelpers.formControlByName(
        this.state.form,
        this.fieldName,
      );
      ctrl.reset(this._values);
    }
  }

  /** Gestion des ajouts de lignes */
  onRowPrepared(e) {
    for (let cell of e.cells) {
      if (
        cell.column.dataType == 'boolean' &&
        (e.isNewRow === true || cell.value == undefined)
      ) {
        cell.value = true;
      }
    }
  }

  /** Validation spécifique */
  folderPathValidator(e) {
    let column = this.columns.filter((f) => f.field == e.column.dataField);
    let validator = '';
    if (column != undefined && column.length > 0) {
      validator = column[0].validator;
    }

    let value = e.value;
    if (e?.value?.trim != undefined) {
      value = e.value.trim();
    }

    if (value != undefined) {
      /** Définit l'expression régulière */
      let pathFolderRegex =
        /^[A-Za-z]:\\([^"*/:?|<>\\.\x00-\x20]([^"*/:?|<>\\\x00-\x1F]*[^"*/:?|<>\\.\x00-\x20])\\?)*$/g;
      let result = pathFolderRegex.test(value);

      return result;
    }
    // this.messageValidator = '';
    return true;
  }

  /** Validation spécifique */
  uniqueValidator(e) {
    let column = this.columns.filter((f) => f.field == e.column.dataField);
    let validator = '';
    if (column != undefined && column.length > 0) {
      validator = column[0].validator;
    }

    let value = e.value;
    if (e?.value?.trim != undefined) {
      value = e.value.trim();
    }

    if (value != undefined) {
      if (this.values != undefined && this.values.length > 0) {
        if (!this.uniqueApplyValidator(e, value)) {
          return false;
        }
      }
    }
    // this.messageValidator = '';
    return true;
  }

  /** Validation de l'unicité */
  uniqueApplyValidator(e, value) {
    let index = 0;
    if (this.cell != undefined && e.data['__KEY__'] == undefined) {
      index = this.cell.rowIndex;
    }
    let rows = this.component.getVisibleRows();

    if (e.column.dataType == 'date') {
      value = this.formatDate(value);
    }

    for (let i = 0; i < rows.length; i++) {
      if (i != index) {
        if (e.column.dataType === 'date') {
          if (
            this.formatDate(rows[i].data[e.column.dataField]).indexOf(value) >=
            0
          ) {
            return false;
          }
          if (
            new Date(value).toDateString() ==
            new Date(rows[i].data[e.column.dataField]).toDateString()
          ) {
            return false;
          }
        } else {
          if (rows[i].data[e.column.dataField] == value) {
            return false;
          }
        }
      }
    }

    return true;
  }

  onInitialized(e) {
    this.component = e.component;
  }

  formatDate(date) {
    var d = new Date(date),
      month = '' + (d.getMonth() + 1),
      day = '' + d.getDate(),
      year = d.getFullYear();

    if (month.length < 2) month = '0' + month;
    if (day.length < 2) day = '0' + day;

    return [year, month, day].join('-');
  }

  compareDate(value: Date, compareValue: Date, validator: string) {
    if (validator == 'GreaterThanOrEqual') {
      return value >= compareValue;
    }
    if (validator == 'LessThanOrEqual') {
      return value <= compareValue;
    }
  }

  /**
   * Teplate tagbox
   */

  dropDownComponent: any;

  onDropDownInitialized(e) {
    this.dropDownComponent = e.component;
  }

  onDropDownInput(e) {
    e.component.open();
  }

  onTreelistContentReady(e, condition) {
    if (
      condition != undefined &&
      condition.value != undefined &&
      condition.value.length > 0
    ) {
      let isExpand = e.component.option('expandedRowKeys');
      if (isExpand == undefined || isExpand.length == 0) {
        let node = e.component.getNodeByKey(condition.value[0]);
        let expand = [];
        while (node != undefined) {
          if (node.parent == undefined) {
            break;
          }
          node = e.component.getNodeByKey(node.parent.key);
          if (node.level != -1) {
            expand.push(node.key);
          }
        }
        expand.reverse();
        e.component.option('expandedRowKeys', expand);
      }
    }
  }

  setCellValue(newData, value, currentRowData) {
    let that = this as any as DxiColumnComponent;
    // ici ne jamais faire de bind de cette fonction sinon on pers le this
    // console.log(value);
    // console.log(currentRowData);
    // console.log(that);
    newData[that.dataField] = value;
    // console.log(that.dataField);

    if (currentRowData.type === 'rule') {
      if (that.dataField === 'editable' && value === true) {
        newData.visible = true;
      }
      if (that.dataField === 'editable' && value === false) {
        newData.required = false;
      }
      if (that.dataField === 'visible' && value === false) {
        newData.required = false;
        newData.editable = false;
      }
      if (that.dataField === 'required') {
        newData.visible = true;
        newData.editable = true;
      }
    }
    (that as any).defaultSetCellValue(newData, value);
  }

  selectionTemplateChanged(option: any, cellInfo, e) {
    let items = [];

    if (cellInfo.value != undefined) {
      items = cellInfo.value;
    }

    e.addedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element[option.valueExpr]);

      if (index < 0) {
        items.push(element[option.valueExpr]);
      }
    });

    e.removedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element[option.valueExpr]);

      if (index >= 0) {
        items.splice(index, 1);
      }
    });
    cellInfo.setValue(items);
  }
}
