import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEntryTablesBaseVariables, FirstEntryTablesBaseVariables, CountEntryTablesBaseVariables, FindEntryTablesBaseVariables, SearchEntryTablesBaseVariables, ExportSchemaEntryTablesBaseVariables, ExportDataEntryTablesBaseVariables, CustomQueryEntryTablesBaseVariables, CustomQueryIdEntryTablesBaseVariables, UsedEntryTablesBaseVariables, ExistEntryTablesBaseVariables } from '../gqls'
import { GetEntryTablesDocument, FirstEntryTablesDocument, CountEntryTablesDocument, FindEntryTablesDocument, SearchEntryTablesDocument, ExportSchemaEntryTablesDocument, ExportDataEntryTablesDocument, CustomQueryEntryTablesDocument, CustomQueryIdEntryTablesDocument, UsedEntryTablesDocument, ExistEntryTablesDocument } from '../gqls'
import { ServiceSingleResultOfEntryTable, QueryContextOfEntryTable, FilterOfEntryTable, ServiceSingleResultOfInt64, ServiceListResultOfEntryTable, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EntryTableBaseService {
    
public modelName = 'entryTable';
public modelPluralName = 'entryTables';

	private getEntryTablesQuery: GetEntryTablesDocument;
	private firstEntryTablesQuery: FirstEntryTablesDocument;
	private countEntryTablesQuery: CountEntryTablesDocument;
	private findEntryTablesQuery: FindEntryTablesDocument;
	private searchEntryTablesQuery: SearchEntryTablesDocument;
	private exportSchemaEntryTablesQuery: ExportSchemaEntryTablesDocument;
	private exportDataEntryTablesQuery: ExportDataEntryTablesDocument;
	private customQueryEntryTablesQuery: CustomQueryEntryTablesDocument;
	private customQueryIdEntryTablesQuery: CustomQueryIdEntryTablesDocument;
	private usedEntryTablesQuery: UsedEntryTablesDocument;
	private existEntryTablesQuery: ExistEntryTablesDocument;

	constructor(private injector: Injector) {
		this.getEntryTablesQuery = this.injector.get(GetEntryTablesDocument);
		this.firstEntryTablesQuery = this.injector.get(FirstEntryTablesDocument);
		this.countEntryTablesQuery = this.injector.get(CountEntryTablesDocument);
		this.findEntryTablesQuery = this.injector.get(FindEntryTablesDocument);
		this.searchEntryTablesQuery = this.injector.get(SearchEntryTablesDocument);
		this.exportSchemaEntryTablesQuery = this.injector.get(ExportSchemaEntryTablesDocument);
		this.exportDataEntryTablesQuery = this.injector.get(ExportDataEntryTablesDocument);
		this.customQueryEntryTablesQuery = this.injector.get(CustomQueryEntryTablesDocument);
		this.customQueryIdEntryTablesQuery = this.injector.get(CustomQueryIdEntryTablesDocument);
		this.usedEntryTablesQuery = this.injector.get(UsedEntryTablesDocument);
		this.existEntryTablesQuery = this.injector.get(ExistEntryTablesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntryTable> {
    
    		let variables: GetEntryTablesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.get));
            }
            else{
                let result:ServiceSingleResultOfEntryTable={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEntryTable,
		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntryTable> {
    
    		let variables: FirstEntryTablesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEntryTablesBaseVariables = {
    			filter: filter
    		}
    				return this.countEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEntryTable,
		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEntryTable> {
    
    		let variables: FindEntryTablesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEntryTable,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEntryTable> {
    
    		let variables: SearchEntryTablesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEntryTablesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEntryTablesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEntryTable,
		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEntryTable> {
    
    		let variables: CustomQueryEntryTablesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEntryTable,
		@Args('filter?') filter?: FilterOfEntryTable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEntryTable> {
    
    		let variables: CustomQueryIdEntryTablesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEntryTablesBaseVariables = {
    			ids: ids
    		}
    				return this.usedEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEntryTablesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEntryTablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.entryTables.exist));
    	}
    
    
}