<div class="alert-container">
  <dx-button
    icon="fal fa-bell"
    (onInitialized)="onAlertButtonInitialized($event)"
    (onClick)="itemClick($event)"
    class="cl-item-button-alert"
  >
  </dx-button>
  <span *ngIf="alertNumber > 0">{{ alertNumber }}</span>
</div>
<dx-popover
  class="cl-popover"
  [position]="{
    my: 'right top',
    at: 'right bottom',
    of: itemButton,
    collision: {
      x: 'flip',
      y: 'flip'
    }
  }"
  [width]="'auto'"
  [(visible)]="showItem"
  (onShowing)="onShowing($event)"
  (onHiding)="onHiding($event)"
>
  <dx-list
    width="450px"
    [dataSource]="source['datasource']"
    keyExpr="key"
    style="max-height: 900px"
  >
    <div *dxTemplate="let notification of 'item'">
      <div style="display: flex; justify-content: space-between">
        <div style="display: flex; max-width: 85%">
          <dx-button
            [icon]="
              notification.isRead === true ? 'fal fa-circle' : 'fas fa-circle'
            "
            (onClick)="toogleReadUnRead(notification)"
            class="cl-notif-read-unread"
          >
          </dx-button>
          <div
            (click)="clickEvent($event, notification)"
            style="max-width: 100%"
          >
            <b style="white-space: normal">
              {{
                translatedFieldHelperService.findValueToShow(notification.title)
              }}
            </b>
            <!-- <b> ({{ notification.countNotViewedAlert }})</b> -->
            <br />
            <div
              class="cl-content-alert"
              [innerHtml]="
                translatedFieldHelperService.findValueToShow(notification.text)
              "
            ></div>
          </div>
        </div>
        <dx-button [icon]="'fas fa-times'" (onClick)="remove(notification)">
        </dx-button>
      </div>
    </div>
  </dx-list>
</dx-popover>
