import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfLicenseModel, ServiceListResultOfProductFeature, FieldUpdateOperatorOfLicenseModel, ServiceSingleResultOfString, LicenseModelInput, ServiceSingleResultOfBoolean } from '../types'

export type GetLicensesResultType = {
    licenses: {
        get?: Maybe<ServiceSingleResultOfLicenseModel>
    }
}

export type GetLicensesBaseVariables = {
	id: string, /** L'identifiant de l'organisation. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetLicensesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getLicenses ${args} {
    licenses {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetLicensesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetLicensesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLicenseModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CurrentFeatureListLicensesResultType = {
    licenses: {
        currentFeatureList?: Maybe<ServiceListResultOfProductFeature>
    }
}

export type CurrentFeatureListLicensesBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class CurrentFeatureListLicensesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query currentFeatureListLicenses ${args} {
    licenses {
        currentFeatureList {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CurrentFeatureListLicensesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CurrentFeatureListLicensesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfProductFeature", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateLicensesResultType = {
    licenses: {
        update?: Maybe<ServiceSingleResultOfLicenseModel>
    }
}

export type UpdateLicensesBaseVariables = {
	id: string, /** L'identifiant de l'organisation. */
	entry: Maybe<FieldUpdateOperatorOfLicenseModel>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateLicensesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfLicenseModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateLicenses ${args} {
    licenses {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateLicensesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateLicensesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfLicenseModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DownloadLicensesResultType = {
    licenses: {
        download?: Maybe<ServiceSingleResultOfString>
    }
}

export type DownloadLicensesBaseVariables = {
	licenseModel: Maybe<LicenseModelInput>, /** La license. */
}

/** Permet de télécharger la license. */
@Injectable({providedIn: 'root'})
export class DownloadLicensesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$licenseModel: LicenseModelInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation downloadLicenses ${args} {
    licenses {
        download(licenseModel: $licenseModel) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DownloadLicensesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DownloadLicensesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UploadLicensesResultType = {
    licenses: {
        upload?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UploadLicensesBaseVariables = {
	id: string, /** L'identifiant de l'organisation. */
	license: Maybe<string>, /** La license. */
}

/** Permet de télétransférer la license. */
@Injectable({providedIn: 'root'})
export class UploadLicensesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $license: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation uploadLicenses ${args} {
    licenses {
        upload(id: $id, license: $license) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UploadLicensesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UploadLicensesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
