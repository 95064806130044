import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfSoftwareGroup, FilterOfSoftwareGroup, QueryContextOfSoftwareGroup, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareGroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfContract, FilterOfContract, QueryContextOfContract, ServiceListResultOfSoftware, FilterOfSoftware, QueryContextOfSoftware, ServiceListResultOfSoftwarePackage, FilterOfSoftwarePackage, QueryContextOfSoftwarePackage, ServiceListResultOfAlert, FilterOfAlert, QueryContextOfAlert, SoftwareGroupInput, FieldUpdateOperatorOfSoftwareGroup } from '../types'

export type GetSoftwareGroupsResultType = {
    softwareGroups: {
        get?: Maybe<ServiceSingleResultOfSoftwareGroup>
    }
}

export type GetSoftwareGroupsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getSoftwareGroups ${args} {
    softwareGroups {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstSoftwareGroupsResultType = {
    softwareGroups: {
        first?: Maybe<ServiceSingleResultOfSoftwareGroup>
    }
}

export type FirstSoftwareGroupsBaseVariables = {
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareGroup = null, $options: QueryContextOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstSoftwareGroups ${args} {
    softwareGroups {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountSoftwareGroupsResultType = {
    softwareGroups: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountSoftwareGroupsBaseVariables = {
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countSoftwareGroups ${args} {
    softwareGroups {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSoftwareGroupsResultType = {
    softwareGroups: {
        find?: Maybe<ServiceListResultOfSoftwareGroup>
    }
}

export type FindSoftwareGroupsBaseVariables = {
	options: Maybe<QueryContextOfSoftwareGroup>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfSoftwareGroup = null, $filter: FilterOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSoftwareGroups ${args} {
    softwareGroups {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchSoftwareGroupsResultType = {
    softwareGroups: {
        search?: Maybe<ServiceListResultOfSoftwareGroup>
    }
}

export type SearchSoftwareGroupsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfSoftwareGroup>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchSoftwareGroups ${args} {
    softwareGroups {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaSoftwareGroupsResultType = {
    softwareGroups: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaSoftwareGroupsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaSoftwareGroups ${args} {
    softwareGroups {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataSoftwareGroupsResultType = {
    softwareGroups: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataSoftwareGroupsBaseVariables = {
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataSoftwareGroups ${args} {
    softwareGroups {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQuerySoftwareGroupsResultType = {
    softwareGroups: {
        customQuery?: Maybe<ServiceListResultOfSoftwareGroup>
    }
}

export type CustomQuerySoftwareGroupsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareGroup>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQuerySoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfSoftwareGroup = null, $options: QueryContextOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQuerySoftwareGroups ${args} {
    softwareGroups {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQuerySoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQuerySoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdSoftwareGroupsResultType = {
    softwareGroups: {
        customQueryId?: Maybe<ServiceListResultOfSoftwareGroup>
    }
}

export type CustomQueryIdSoftwareGroupsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfSoftwareGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareGroup>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfSoftwareGroup = null, $options: QueryContextOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdSoftwareGroups ${args} {
    softwareGroups {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedSoftwareGroupsResultType = {
    softwareGroups: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedSoftwareGroupsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedSoftwareGroups ${args} {
    softwareGroups {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindCustomUnassociatedGroupContractsSoftwareGroupsResultType = {
    softwareGroups: {
        findCustomUnassociatedGroupContracts?: Maybe<ServiceListResultOfContract>
    }
}

export type FindCustomUnassociatedGroupContractsSoftwareGroupsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfContract>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfContract>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindCustomUnassociatedGroupContractsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfContract = null, $options: QueryContextOfContract = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findCustomUnassociatedGroupContractsSoftwareGroups ${args} {
    softwareGroups {
        findCustomUnassociatedGroupContracts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindCustomUnassociatedGroupContractsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindCustomUnassociatedGroupContractsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfContract", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistSoftwareGroupsResultType = {
    softwareGroups: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistSoftwareGroupsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existSoftwareGroups ${args} {
    softwareGroups {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSoftwareSoftwareGroupsResultType = {
    softwareGroups: {
        findUnassociatedSoftware?: Maybe<ServiceListResultOfSoftware>
    }
}

export type FindUnassociatedSoftwareSoftwareGroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftware>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftware>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSoftwareSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftware = null, $options: QueryContextOfSoftware = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSoftwareSoftwareGroups ${args} {
    softwareGroups {
        findUnassociatedSoftware(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSoftwareSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSoftwareSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftware", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedContractsSoftwareGroupsResultType = {
    softwareGroups: {
        findUnassociatedContracts?: Maybe<ServiceListResultOfContract>
    }
}

export type FindUnassociatedContractsSoftwareGroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfContract>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfContract>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedContractsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfContract = null, $options: QueryContextOfContract = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedContractsSoftwareGroups ${args} {
    softwareGroups {
        findUnassociatedContracts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedContractsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedContractsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfContract", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSoftwarePackagesSoftwareGroupsResultType = {
    softwareGroups: {
        findUnassociatedSoftwarePackages?: Maybe<ServiceListResultOfSoftwarePackage>
    }
}

export type FindUnassociatedSoftwarePackagesSoftwareGroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftwarePackage>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwarePackage>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSoftwarePackagesSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftwarePackage = null, $options: QueryContextOfSoftwarePackage = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSoftwarePackagesSoftwareGroups ${args} {
    softwareGroups {
        findUnassociatedSoftwarePackages(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSoftwarePackagesSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSoftwarePackagesSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwarePackage", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAlertsSoftwareGroupsResultType = {
    softwareGroups: {
        findUnassociatedAlerts?: Maybe<ServiceListResultOfAlert>
    }
}

export type FindUnassociatedAlertsSoftwareGroupsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAlert>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAlert>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAlertsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAlert = null, $options: QueryContextOfAlert = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAlertsSoftwareGroups ${args} {
    softwareGroups {
        findUnassociatedAlerts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAlertsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAlertsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAlert", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertSoftwareGroupsResultType = {
    softwareGroups: {
        insert?: Maybe<ServiceSingleResultOfSoftwareGroup>
    }
}

export type InsertSoftwareGroupsBaseVariables = {
	entity: SoftwareGroupInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: SoftwareGroupInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertSoftwareGroups ${args} {
    softwareGroups {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateSoftwareGroupsResultType = {
    softwareGroups: {
        update?: Maybe<ServiceSingleResultOfSoftwareGroup>
    }
}

export type UpdateSoftwareGroupsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareGroup>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateSoftwareGroups ${args} {
    softwareGroups {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataSoftwareGroupsResultType = {
    softwareGroups: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataSoftwareGroupsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataSoftwareGroups ${args} {
    softwareGroups {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManySoftwareGroupsResultType = {
    softwareGroups: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManySoftwareGroupsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareGroup>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManySoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfSoftwareGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManySoftwareGroups ${args} {
    softwareGroups {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManySoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManySoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteSoftwareGroupsResultType = {
    softwareGroups: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteSoftwareGroupsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteSoftwareGroups ${args} {
    softwareGroups {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ForceCharterSoftwareGroupsResultType = {
    softwareGroups: {
        forceCharter?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ForceCharterSoftwareGroupsBaseVariables = {
	ids: Array<string>, /**  */
	force: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ForceCharterSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $force: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation forceCharterSoftwareGroups ${args} {
    softwareGroups {
        forceCharter(ids: $ids, force: $force) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ForceCharterSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ForceCharterSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSoftwareCustomSoftwareGroupsResultType = {
    softwareGroups: {
        addSoftwareCustom?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSoftwareCustomSoftwareGroupsBaseVariables = {
	id: string, /**  */
	softwareIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSoftwareCustomSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $softwareIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSoftwareCustomSoftwareGroups ${args} {
    softwareGroups {
        addSoftwareCustom(id: $id, softwareIds: $softwareIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSoftwareCustomSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSoftwareCustomSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SoftwareGroupContractAssociateSoftwareGroupsResultType = {
    softwareGroups: {
        softwareGroupContractAssociate?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SoftwareGroupContractAssociateSoftwareGroupsBaseVariables = {
	softwareGroupIds: Array<string>, /**  */
	contractIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareGroupContractAssociateSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$softwareGroupIds: [Uuid!], $contractIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation softwareGroupContractAssociateSoftwareGroups ${args} {
    softwareGroups {
        softwareGroupContractAssociate(softwareGroupIds: $softwareGroupIds, contractIds: $contractIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SoftwareGroupContractAssociateSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SoftwareGroupContractAssociateSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSoftwareCustomSoftwareGroupsResultType = {
    softwareGroups: {
        removeSoftwareCustom?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSoftwareCustomSoftwareGroupsBaseVariables = {
	softwareIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSoftwareCustomSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$softwareIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSoftwareCustomSoftwareGroups ${args} {
    softwareGroups {
        removeSoftwareCustom(softwareIds: $softwareIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSoftwareCustomSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSoftwareCustomSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddContractsSoftwareGroupsResultType = {
    softwareGroups: {
        addContracts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddContractsSoftwareGroupsBaseVariables = {
	id: string, /**  */
	contractIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddContractsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $contractIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addContractsSoftwareGroups ${args} {
    softwareGroups {
        addContracts(id: $id, contractIds: $contractIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddContractsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddContractsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveContractsSoftwareGroupsResultType = {
    softwareGroups: {
        removeContracts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveContractsSoftwareGroupsBaseVariables = {
	id: string, /**  */
	contractIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveContractsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $contractIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeContractsSoftwareGroups ${args} {
    softwareGroups {
        removeContracts(id: $id, contractIds: $contractIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveContractsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveContractsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSoftwarePackagesSoftwareGroupsResultType = {
    softwareGroups: {
        addSoftwarePackages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSoftwarePackagesSoftwareGroupsBaseVariables = {
	id: string, /**  */
	softwarePackageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSoftwarePackagesSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $softwarePackageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSoftwarePackagesSoftwareGroups ${args} {
    softwareGroups {
        addSoftwarePackages(id: $id, softwarePackageIds: $softwarePackageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSoftwarePackagesSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSoftwarePackagesSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSoftwarePackagesSoftwareGroupsResultType = {
    softwareGroups: {
        removeSoftwarePackages?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSoftwarePackagesSoftwareGroupsBaseVariables = {
	id: string, /**  */
	softwarePackageIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSoftwarePackagesSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $softwarePackageIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSoftwarePackagesSoftwareGroups ${args} {
    softwareGroups {
        removeSoftwarePackages(id: $id, softwarePackageIds: $softwarePackageIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSoftwarePackagesSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSoftwarePackagesSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAlertsSoftwareGroupsResultType = {
    softwareGroups: {
        addAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAlertsSoftwareGroupsBaseVariables = {
	id: string, /**  */
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAlertsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAlertsSoftwareGroups ${args} {
    softwareGroups {
        addAlerts(id: $id, alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAlertsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAlertsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAlertsSoftwareGroupsResultType = {
    softwareGroups: {
        removeAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAlertsSoftwareGroupsBaseVariables = {
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAlertsSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAlertsSoftwareGroups ${args} {
    softwareGroups {
        removeAlerts(alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAlertsSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAlertsSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSoftwareSoftwareGroupsResultType = {
    softwareGroups: {
        addSoftware?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSoftwareSoftwareGroupsBaseVariables = {
	id: string, /**  */
	softwareIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSoftwareSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $softwareIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSoftwareSoftwareGroups ${args} {
    softwareGroups {
        addSoftware(id: $id, softwareIds: $softwareIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSoftwareSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSoftwareSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSoftwareSoftwareGroupsResultType = {
    softwareGroups: {
        removeSoftware?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSoftwareSoftwareGroupsBaseVariables = {
	softwareIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSoftwareSoftwareGroupsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$softwareIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSoftwareSoftwareGroups ${args} {
    softwareGroups {
        removeSoftware(softwareIds: $softwareIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSoftwareSoftwareGroupsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSoftwareSoftwareGroupsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
