import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetReportCachesBaseVariables, FirstReportCachesBaseVariables, CountReportCachesBaseVariables, FindReportCachesBaseVariables, SearchReportCachesBaseVariables, ExportSchemaReportCachesBaseVariables, ExportDataReportCachesBaseVariables, CustomQueryReportCachesBaseVariables, CustomQueryIdReportCachesBaseVariables, UsedReportCachesBaseVariables, ExistReportCachesBaseVariables, InsertReportCachesBaseVariables, UpdateReportCachesBaseVariables, ImportDataReportCachesBaseVariables, UpdateManyReportCachesBaseVariables, DeleteReportCachesBaseVariables } from '../gqls'
import { GetReportCachesDocument, FirstReportCachesDocument, CountReportCachesDocument, FindReportCachesDocument, SearchReportCachesDocument, ExportSchemaReportCachesDocument, ExportDataReportCachesDocument, CustomQueryReportCachesDocument, CustomQueryIdReportCachesDocument, UsedReportCachesDocument, ExistReportCachesDocument, InsertReportCachesDocument, UpdateReportCachesDocument, ImportDataReportCachesDocument, UpdateManyReportCachesDocument, DeleteReportCachesDocument } from '../gqls'
import { ServiceSingleResultOfReportCache, QueryContextOfReportCache, FilterOfReportCache, ServiceSingleResultOfInt64, ServiceListResultOfReportCache, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ReportCacheInput, FieldUpdateOperatorOfReportCache } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ReportCachBaseService {
    
public modelName = 'reportCach';
public modelPluralName = 'reportCaches';

	private getReportCachesQuery: GetReportCachesDocument;
	private firstReportCachesQuery: FirstReportCachesDocument;
	private countReportCachesQuery: CountReportCachesDocument;
	private findReportCachesQuery: FindReportCachesDocument;
	private searchReportCachesQuery: SearchReportCachesDocument;
	private exportSchemaReportCachesQuery: ExportSchemaReportCachesDocument;
	private exportDataReportCachesQuery: ExportDataReportCachesDocument;
	private customQueryReportCachesQuery: CustomQueryReportCachesDocument;
	private customQueryIdReportCachesQuery: CustomQueryIdReportCachesDocument;
	private usedReportCachesQuery: UsedReportCachesDocument;
	private existReportCachesQuery: ExistReportCachesDocument;
	private insertReportCachesMutation: InsertReportCachesDocument;
	private updateReportCachesMutation: UpdateReportCachesDocument;
	private importDataReportCachesMutation: ImportDataReportCachesDocument;
	private updateManyReportCachesMutation: UpdateManyReportCachesDocument;
	private deleteReportCachesMutation: DeleteReportCachesDocument;

	constructor(private injector: Injector) {
		this.getReportCachesQuery = this.injector.get(GetReportCachesDocument);
		this.firstReportCachesQuery = this.injector.get(FirstReportCachesDocument);
		this.countReportCachesQuery = this.injector.get(CountReportCachesDocument);
		this.findReportCachesQuery = this.injector.get(FindReportCachesDocument);
		this.searchReportCachesQuery = this.injector.get(SearchReportCachesDocument);
		this.exportSchemaReportCachesQuery = this.injector.get(ExportSchemaReportCachesDocument);
		this.exportDataReportCachesQuery = this.injector.get(ExportDataReportCachesDocument);
		this.customQueryReportCachesQuery = this.injector.get(CustomQueryReportCachesDocument);
		this.customQueryIdReportCachesQuery = this.injector.get(CustomQueryIdReportCachesDocument);
		this.usedReportCachesQuery = this.injector.get(UsedReportCachesDocument);
		this.existReportCachesQuery = this.injector.get(ExistReportCachesDocument);
		this.insertReportCachesMutation = this.injector.get(InsertReportCachesDocument);
		this.updateReportCachesMutation = this.injector.get(UpdateReportCachesDocument);
		this.importDataReportCachesMutation = this.injector.get(ImportDataReportCachesDocument);
		this.updateManyReportCachesMutation = this.injector.get(UpdateManyReportCachesDocument);
		this.deleteReportCachesMutation = this.injector.get(DeleteReportCachesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReportCache> {
    
    		let variables: GetReportCachesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.get));
            }
            else{
                let result:ServiceSingleResultOfReportCache={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReportCache,
		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReportCache> {
    
    		let variables: FirstReportCachesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountReportCachesBaseVariables = {
    			filter: filter
    		}
    				return this.countReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfReportCache,
		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReportCache> {
    
    		let variables: FindReportCachesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfReportCache,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReportCache> {
    
    		let variables: SearchReportCachesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaReportCachesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataReportCachesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfReportCache,
		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReportCache> {
    
    		let variables: CustomQueryReportCachesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfReportCache,
		@Args('filter?') filter?: FilterOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfReportCache> {
    
    		let variables: CustomQueryIdReportCachesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedReportCachesBaseVariables = {
    			ids: ids
    		}
    				return this.usedReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistReportCachesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existReportCachesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ReportCacheInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReportCache> {
    
    		let variables: InsertReportCachesBaseVariables = {
    			entity: entity
    		}
    				return this.insertReportCachesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfReportCache> {
    
    		let variables: UpdateReportCachesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateReportCachesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataReportCachesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataReportCachesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfReportCache,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyReportCachesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyReportCachesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteReportCachesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteReportCachesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.reportCaches.delete));
    	}
    
}