import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOptionalFeaturesBaseVariables, FirstOptionalFeaturesBaseVariables, CountOptionalFeaturesBaseVariables, FindOptionalFeaturesBaseVariables, SearchOptionalFeaturesBaseVariables, ExportSchemaOptionalFeaturesBaseVariables, ExportDataOptionalFeaturesBaseVariables, CustomQueryOptionalFeaturesBaseVariables, CustomQueryIdOptionalFeaturesBaseVariables, UsedOptionalFeaturesBaseVariables, ExistOptionalFeaturesBaseVariables } from '../gqls'
import { GetOptionalFeaturesDocument, FirstOptionalFeaturesDocument, CountOptionalFeaturesDocument, FindOptionalFeaturesDocument, SearchOptionalFeaturesDocument, ExportSchemaOptionalFeaturesDocument, ExportDataOptionalFeaturesDocument, CustomQueryOptionalFeaturesDocument, CustomQueryIdOptionalFeaturesDocument, UsedOptionalFeaturesDocument, ExistOptionalFeaturesDocument } from '../gqls'
import { ServiceSingleResultOfOptionalFeature, QueryContextOfOptionalFeature, FilterOfOptionalFeature, ServiceSingleResultOfInt64, ServiceListResultOfOptionalFeature, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OptionalFeatureBaseService {
    
public modelName = 'optionalFeature';
public modelPluralName = 'optionalFeatures';

	private getOptionalFeaturesQuery: GetOptionalFeaturesDocument;
	private firstOptionalFeaturesQuery: FirstOptionalFeaturesDocument;
	private countOptionalFeaturesQuery: CountOptionalFeaturesDocument;
	private findOptionalFeaturesQuery: FindOptionalFeaturesDocument;
	private searchOptionalFeaturesQuery: SearchOptionalFeaturesDocument;
	private exportSchemaOptionalFeaturesQuery: ExportSchemaOptionalFeaturesDocument;
	private exportDataOptionalFeaturesQuery: ExportDataOptionalFeaturesDocument;
	private customQueryOptionalFeaturesQuery: CustomQueryOptionalFeaturesDocument;
	private customQueryIdOptionalFeaturesQuery: CustomQueryIdOptionalFeaturesDocument;
	private usedOptionalFeaturesQuery: UsedOptionalFeaturesDocument;
	private existOptionalFeaturesQuery: ExistOptionalFeaturesDocument;

	constructor(private injector: Injector) {
		this.getOptionalFeaturesQuery = this.injector.get(GetOptionalFeaturesDocument);
		this.firstOptionalFeaturesQuery = this.injector.get(FirstOptionalFeaturesDocument);
		this.countOptionalFeaturesQuery = this.injector.get(CountOptionalFeaturesDocument);
		this.findOptionalFeaturesQuery = this.injector.get(FindOptionalFeaturesDocument);
		this.searchOptionalFeaturesQuery = this.injector.get(SearchOptionalFeaturesDocument);
		this.exportSchemaOptionalFeaturesQuery = this.injector.get(ExportSchemaOptionalFeaturesDocument);
		this.exportDataOptionalFeaturesQuery = this.injector.get(ExportDataOptionalFeaturesDocument);
		this.customQueryOptionalFeaturesQuery = this.injector.get(CustomQueryOptionalFeaturesDocument);
		this.customQueryIdOptionalFeaturesQuery = this.injector.get(CustomQueryIdOptionalFeaturesDocument);
		this.usedOptionalFeaturesQuery = this.injector.get(UsedOptionalFeaturesDocument);
		this.existOptionalFeaturesQuery = this.injector.get(ExistOptionalFeaturesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOptionalFeature> {
    
    		let variables: GetOptionalFeaturesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.get));
            }
            else{
                let result:ServiceSingleResultOfOptionalFeature={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOptionalFeature,
		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOptionalFeature> {
    
    		let variables: FirstOptionalFeaturesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOptionalFeaturesBaseVariables = {
    			filter: filter
    		}
    				return this.countOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOptionalFeature,
		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOptionalFeature> {
    
    		let variables: FindOptionalFeaturesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOptionalFeature,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOptionalFeature> {
    
    		let variables: SearchOptionalFeaturesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOptionalFeaturesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOptionalFeaturesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOptionalFeature,
		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOptionalFeature> {
    
    		let variables: CustomQueryOptionalFeaturesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOptionalFeature,
		@Args('filter?') filter?: FilterOfOptionalFeature,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOptionalFeature> {
    
    		let variables: CustomQueryIdOptionalFeaturesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOptionalFeaturesBaseVariables = {
    			ids: ids
    		}
    				return this.usedOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOptionalFeaturesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOptionalFeaturesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.optionalFeatures.exist));
    	}
    
    
}