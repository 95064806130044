import { LoanBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  EntityAttributes,
  FilterOfLoan,
  QueryContextOfLoan,
  ServiceListResultOfLoan,
} from '../types';
import { of, Observable } from 'rxjs';
import { AbstractModelContext } from '@clarilog/shared2/services/compiler/model-state';
import { FormGroupHelpers } from '@clarilog/shared2/components/form/work-form/form-group-helpers';
import { Authorize } from '@clarilog/core/services/graphql';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { TranslateService } from '@clarilog/shared';

@Injectable({ providedIn: 'root' })
@Authorize('loan')
export class LoanCoreService extends LoanBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** @inheritdoc */
  @InjectArgs
  public defaultName(
    @Args('currentContext') currentContext: AbstractModelContext,
  ): Observable<EntityAttributes> {
    let control = FormGroupHelpers.formControlByName(
      currentContext.rootState.form,
      'recordDate',
    );
    if (control != undefined) {
      return of({ created: { date: control.value } } as any);
    }
    return null;
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('number'),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  /** Récupère les entités selon le filtre. */
  @InjectArgs
  public findLoans(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('myLoan') myLoan: boolean,
    @Args('options?') options?: QueryContextOfLoan,
    @Args('filter?') filter?: FilterOfLoan,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLoan> {
    if (myLoan === true) {
      return this.findMyLoans(fields, options, filter, extendedVariables);
    } else {
      return this.find(fields, options, filter, extendedVariables);
    }
  }

  /** Liste mention pour le sujet */
  public findMentionSubject(): { key: string; name: string }[] {
    let mentions: { key: string; name: string }[] = this.commonMentionLoan();

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  /**Liste mention pour les destinataires */
  public findMentionEmail(): { key: string; name: string }[] {
    let mentions: { key: string; name: string }[] = [
      {
        key: '[loanBorrowerProperties].email',
        name: TranslateService.get(`entities/loan/borrower`),
      },
      {
        key: 'loanManagerProperties',
        name: TranslateService.get(`entities/loan/manager`),
      },
    ];

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  /**Liste mention pour le corps du mail */
  public findMentionBody(): any {
    let mentions: { key: string; name: string }[] = this.commonMentionLoan();
    mentions.push({
      key: 'loanAssetsProperties',
      name: TranslateService.get(`entities/loan/loanAssets`),
    });

    mentions.sort((a, b) => {
      return a.name.localeCompare(b.name);
    });

    return mentions;
  }

  /** Liste de mention commun à l'objet et au corps du mail*/
  private commonMentionLoan(): { key: string; name: string }[] {
    return [
      {
        key: 'number',
        name: TranslateService.get(`entities/loan/number`),
      },
      {
        key: 'slipNumber',
        name: TranslateService.get(`entities/loan/slipNumber`),
      },
      {
        key: 'returnDate',
        name: TranslateService.get(`entities/loan/returnDateUTC`),
      },
      {
        key: '[loanProperties].returnDate',
        name: TranslateService.get(`entities/loan/returnDate`),
      },
      {
        key: 'realReturnDate',
        name: TranslateService.get(`entities/loan/realReturnDateUTC`),
      },
      {
        key: '[loanProperties].realReturnDate',
        name: TranslateService.get(`entities/loan/realReturnDate`),
      },
      {
        key: 'provision',
        name: TranslateService.get(`entities/loan/provisionUTC`),
      },
      {
        key: '[loanProperties].provision',
        name: TranslateService.get(`entities/loan/provision`),
      },
      {
        key: 'loanBorrower',
        name: TranslateService.get(`entities/loan/borrower`),
      },
      {
        key: 'manager.name',
        name: TranslateService.get(`entities/loan/manager`),
      },
    ];
  }
}
