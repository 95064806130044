import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAntiSpywareProductsBaseVariables, FirstAntiSpywareProductsBaseVariables, CountAntiSpywareProductsBaseVariables, FindAntiSpywareProductsBaseVariables, SearchAntiSpywareProductsBaseVariables, ExportSchemaAntiSpywareProductsBaseVariables, ExportDataAntiSpywareProductsBaseVariables, CustomQueryAntiSpywareProductsBaseVariables, CustomQueryIdAntiSpywareProductsBaseVariables, UsedAntiSpywareProductsBaseVariables, ExistAntiSpywareProductsBaseVariables } from '../gqls'
import { GetAntiSpywareProductsDocument, FirstAntiSpywareProductsDocument, CountAntiSpywareProductsDocument, FindAntiSpywareProductsDocument, SearchAntiSpywareProductsDocument, ExportSchemaAntiSpywareProductsDocument, ExportDataAntiSpywareProductsDocument, CustomQueryAntiSpywareProductsDocument, CustomQueryIdAntiSpywareProductsDocument, UsedAntiSpywareProductsDocument, ExistAntiSpywareProductsDocument } from '../gqls'
import { ServiceSingleResultOfAntiSpywareProduct, QueryContextOfAntiSpywareProduct, FilterOfAntiSpywareProduct, ServiceSingleResultOfInt64, ServiceListResultOfAntiSpywareProduct, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AntiSpywareProductBaseService {
    
public modelName = 'antiSpywareProduct';
public modelPluralName = 'antiSpywareProducts';

	private getAntiSpywareProductsQuery: GetAntiSpywareProductsDocument;
	private firstAntiSpywareProductsQuery: FirstAntiSpywareProductsDocument;
	private countAntiSpywareProductsQuery: CountAntiSpywareProductsDocument;
	private findAntiSpywareProductsQuery: FindAntiSpywareProductsDocument;
	private searchAntiSpywareProductsQuery: SearchAntiSpywareProductsDocument;
	private exportSchemaAntiSpywareProductsQuery: ExportSchemaAntiSpywareProductsDocument;
	private exportDataAntiSpywareProductsQuery: ExportDataAntiSpywareProductsDocument;
	private customQueryAntiSpywareProductsQuery: CustomQueryAntiSpywareProductsDocument;
	private customQueryIdAntiSpywareProductsQuery: CustomQueryIdAntiSpywareProductsDocument;
	private usedAntiSpywareProductsQuery: UsedAntiSpywareProductsDocument;
	private existAntiSpywareProductsQuery: ExistAntiSpywareProductsDocument;

	constructor(private injector: Injector) {
		this.getAntiSpywareProductsQuery = this.injector.get(GetAntiSpywareProductsDocument);
		this.firstAntiSpywareProductsQuery = this.injector.get(FirstAntiSpywareProductsDocument);
		this.countAntiSpywareProductsQuery = this.injector.get(CountAntiSpywareProductsDocument);
		this.findAntiSpywareProductsQuery = this.injector.get(FindAntiSpywareProductsDocument);
		this.searchAntiSpywareProductsQuery = this.injector.get(SearchAntiSpywareProductsDocument);
		this.exportSchemaAntiSpywareProductsQuery = this.injector.get(ExportSchemaAntiSpywareProductsDocument);
		this.exportDataAntiSpywareProductsQuery = this.injector.get(ExportDataAntiSpywareProductsDocument);
		this.customQueryAntiSpywareProductsQuery = this.injector.get(CustomQueryAntiSpywareProductsDocument);
		this.customQueryIdAntiSpywareProductsQuery = this.injector.get(CustomQueryIdAntiSpywareProductsDocument);
		this.usedAntiSpywareProductsQuery = this.injector.get(UsedAntiSpywareProductsDocument);
		this.existAntiSpywareProductsQuery = this.injector.get(ExistAntiSpywareProductsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAntiSpywareProduct> {
    
    		let variables: GetAntiSpywareProductsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.get));
            }
            else{
                let result:ServiceSingleResultOfAntiSpywareProduct={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAntiSpywareProduct,
		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAntiSpywareProduct> {
    
    		let variables: FirstAntiSpywareProductsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAntiSpywareProductsBaseVariables = {
    			filter: filter
    		}
    				return this.countAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAntiSpywareProduct,
		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiSpywareProduct> {
    
    		let variables: FindAntiSpywareProductsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAntiSpywareProduct,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiSpywareProduct> {
    
    		let variables: SearchAntiSpywareProductsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAntiSpywareProductsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAntiSpywareProductsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAntiSpywareProduct,
		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiSpywareProduct> {
    
    		let variables: CustomQueryAntiSpywareProductsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAntiSpywareProduct,
		@Args('filter?') filter?: FilterOfAntiSpywareProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAntiSpywareProduct> {
    
    		let variables: CustomQueryIdAntiSpywareProductsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAntiSpywareProductsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAntiSpywareProductsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAntiSpywareProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.antiSpywareProducts.exist));
    	}
    
    
}