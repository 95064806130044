import { ArticleKnowledgeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { TranslatedFieldHelperService } from '@clarilog/shared2';
import {
  AreaListCustomization,
  FilterOfArticleKnowledge,
  QueryContextOfArticleKnowledge,
  QuerySortTypeArticleKnowledge,
  ServiceListResultOfArticleKnowledge,
  Sort,
} from '../types';
import { Observable } from 'rxjs';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization';

@Injectable({ providedIn: 'root' })
@Authorize('knowledge')
export class ArticleKnowledgeCoreService extends ArticleKnowledgeBaseService {
  _injector: Injector;

  constructor(
    injector: Injector,
    private authorizationService: AuthorizationCoreService,
  ) {
    super(injector);
    this._injector = injector;
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    let trransSvc = this._injector.get(TranslatedFieldHelperService);
    return [
      GqlSubField.create('data', [
        GqlSubField.create('title', trransSvc.translatedFields()),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  @InjectArgs
  /** Obtient le lien (administration / helpMe) vers l'article */
  getArticleLink(origin, type, id, isHelpMe: boolean = false) {
    let url = `${origin}/article-knowledge/view?navigateTo=${id}`;

    if (isHelpMe) {
      url = `${origin}/help-desk-${type}?navigateTo=${id}`;
    }

    var txtArea = document.createElement('textarea');
    txtArea.style.position = 'fixed';
    txtArea.style.top = '0';
    txtArea.style.left = '0';
    txtArea.style.opacity = '0';
    txtArea.value = url;
    document.body.appendChild(txtArea);
    txtArea.select();
    document.execCommand('copy');
    document.body.removeChild(txtArea);
  }

  @InjectArgs
  public findVisibleOnHelpMePortal(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('textSearch?') textSearch?: string,
    @Args('options?') options?: QueryContextOfArticleKnowledge,
    @Args('filter?') filter?: FilterOfArticleKnowledge,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfArticleKnowledge> {
    let dateSorting: QuerySortTypeArticleKnowledge = {
      attributes: [{ updated: [{ date: Sort.Desc }] }],
    };

    if (options == undefined || options == null) {
      options = {};
    }

    if (options.sort == undefined || options.sort == null) {
      options.sort = [dateSorting];
    } else {
      options.sort.push(dateSorting);
    }

    let myHelpMePortal: AreaListCustomization;

    if (
      this.authorizationService.user.getHelpMeAreaListCustomization() !=
      undefined
    ) {
      myHelpMePortal =
        this.authorizationService.user.getHelpMeAreaListCustomization();

      if (myHelpMePortal.articleKbNumberEnable == true) {
        if (myHelpMePortal.articleKbNumber > 0) {
          options.limit = myHelpMePortal.articleKbNumber;
        }
      }
    }

    return this.findEnable(
      fields,
      textSearch,
      options,
      filter,
      extendedVariables,
    );
  }

  /**  */
  //   @InjectArgs
  //   public findArticleKnowledgeByTicket(
  //     @Args('fields') fields: Array<GqlField | GqlSubField>,
  //     @Args('title?') title?: string,
  //     @Args('options?') options?: QueryContextOfArticleKnowledge,
  //     @Args('filter?') filter?: FilterOfArticleKnowledge,
  //     @Args('ticketCategoryId?') ticketCategoryId?: string,
  //     @Args('extendedVariables?') extendedVariables?: any,
  //   ): Observable<ServiceListResultOfArticleKnowledge> {
  //     console.log(title, filter, ticketCategoryId);
  //     if (ticketCategoryId !== undefined && ticketCategoryId !== null){
  //         filter.and.push({ticekt})
  //     }
  //       return this.findEnable(fields, '', options, filter, extendedVariables);
  //   }
}
