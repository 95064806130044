import { ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import {
  ControlContainer,
  UntypedFormGroup,
  FormGroupDirective,
} from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { BadgeType } from '@clarilog/core/services2/graphql/generated-types/types';
import { PageControl, PageTab } from '@clarilog/shared2/models/schema';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { FormGroupHelpers } from '../work-form/form-group-helpers';
import pluralize from 'pluralize';

/** Représente la classe du composent cl-work-item-tab-group. */
@Component({
  selector: 'clc-work-item-tab-group',
  templateUrl: './work-item-tab-group.component.html',
  styleUrls: ['./work-item-tab-group.component.scss'],
  viewProviders: [
    {
      provide: ControlContainer,
      useExisting: FormGroupDirective,
    },
  ],
})
export class CoreWorkItemTabGroupComponent implements OnInit {
  /** Obtient ou définit les tabs à afficher. */
  @Input() tabs: PageTab[];

  @Input() state: ModelState;

  /** Représente la directive FormGroup. */
  public parent: FormGroupDirective;

  /** Obtient ou définit l'affichage du Wait */
  loaded: boolean = false;

  /** Obtient ou définit la source du tab */
  source: any[];

  /** Contient l'ensemble des items */
  items: any[];

  instanceWorkGroup: any[] = [];
  component: any;

  /** subscription au save */
  saveSubscription;
  emitSubscription;

  constructor(
    private formGroupDirective: FormGroupDirective,
    private route: ActivatedRoute,
    private cd: ChangeDetectorRef,
  ) {
    this.parent = formGroupDirective;
  }

  ngOnInit(): void {
    this.source = this.tabs
      //.filter((x) => x.visible == undefined || x.visible === true)
      .map((tab) => {
        let hint = '';

        let tabHint = tab as PageControl;

        if (
          tabHint != undefined &&
          tabHint.control != undefined &&
          tabHint.control.hint != undefined
        ) {
          hint = '' + tabHint.control.hint;
        }

        return {
          title: tab.label,
          item: tab,
          hint: hint,
          itemKey: tab.itemKey,
        };
      });

    this.loaded = true;

    if (this.state != undefined) {
      this.saveSubscription = this.state.formComponent.onSaved.subscribe(
        (res) => {
          this.checkVisibilityTab();
          this.refreshBadges();
        },
      );

      this.emitSubscription = this.state.on.subscribe((f) => {
        if (f.eventName == 'refresh-tabs') {
          this.refreshBadges();
        }
        if (f?.eventName == 'ticket-event' && f?.eventData?.change_tab_index) {
          let route: string =
            f?.eventData?.tabs != undefined ? f?.eventData?.tabs : undefined;
          this.getTheEventTab(route);
        }
      });
    }
  }

  private getTheEventTab(route: string) {
    let items = this.component.option('items');
    if (route != undefined) {
      route = pluralize.plural(route);
      for (const index in Object.keys(items)) {
        let itemKey = items[index].itemKey as string;
        if (itemKey?.toLowerCase() == route) {
          this.component.option('selectedIndex', index);
          break;
        }
      }
    } else {
      console.error('We have an issue with the tab...');
    }
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    if (this.saveSubscription != undefined) {
      this.saveSubscription.unsubscribe();
    }
    if (this.emitSubscription != undefined) {
      this.emitSubscription.unsubscribe();
    }
  }

  workItemLoaded(comp) {
    this.instanceWorkGroup.push(comp);
  }

  /** Initialisation du controle */
  onInitialized(e) {
    this.component = e.component;
  }

  /** Validation du tab */
  valid(formGroup, e): boolean {
    let value = FormGroupHelpers.valid(formGroup);
    return !value;
  }

  /** Permet de vérifier et sélectionné le 1er Tab disponible (en fonction des rules) */
  checkVisibilityTab() {
    let firstIndexVisible = -1;
    let canChangeTab = false;

    if (this.items != undefined) {
      for (let i = 0; i < this.items.length; i++) {
        let form = this.parent.form.get(this.items[i].item.name);
        if (form != undefined) {
          let isVisible = this.canBeVisible(
            (form as UntypedFormGroup).controls,
          );
          this.items[i].visible = isVisible;
          if (isVisible && firstIndexVisible == -1) {
            firstIndexVisible = i;
          }
          //Selection auto du 1er tab disponible est visible
          if (this.component != undefined && !isVisible) {
            let selectedTab = this.component.option('selectedItem');
            if (selectedTab?.item?.name == this.items[i]?.item?.name) {
              canChangeTab = true;
            }
          }
        }
      }
    }
    if (canChangeTab && firstIndexVisible != -1) {
      this.component.option('selectedIndex', firstIndexVisible);
      this.cd.detectChanges();
    }
  }

  /** Vérifie si au moins un des controls est visible */
  canBeVisible(controls) {
    if (controls != undefined) {
      for (let key in controls) {
        let visibility = controls[key]?.visibled;
        if (visibility === true) {
          return true;
        } else {
          this.canBeVisible(controls[key]?.controls);
        }
      }
    } else if (controls?.visibled != undefined) {
      return controls.visibled;
    }

    return false;
  }

  onContentReady(e) {
    this.items = e.component.option('items');

    this.checkVisibilityTab();
    // Vérification si le tabs doit etre sélection
    if (
      this.items != undefined &&
      this.route?.snapshot?.queryParams?.tabs != undefined
    ) {
      let index = this.items.findIndex(
        (f) => f.itemKey == this.route?.snapshot?.queryParams?.tabs,
      );
      if (
        this.route?.snapshot?.queryParams?.eventId != undefined &&
        this.route?.snapshot?.queryParams?.eventId != null &&
        this.state.isSubForm == false
      ) {
        this.component = e.component;
        this.getTheEventTab(this.route.snapshot.queryParams.tabs);
      } else if (index >= 0) {
        e.component.option('selectedIndex', index);
        this.cd.detectChanges();
      }
    }

    this.refreshBadges();
  }

  onSelectionChanged(e) {
    let data = {
      oldTab: e.removedItems[0],
      newTab: e.addedItems[0],
    };
    this.state.on.emit({ eventName: 'tab-change', eventData: data });
  }

  /** Action de refresh des badge */
  refreshBadges() {
    if (this.items != undefined) {
      this.items.forEach((tab) => {
        if (tab.visible === true) {
          if (this.state?.sharedContext?.badges != undefined) {
            tab.badge = undefined;
            let badge = this.state.sharedContext.badges.find(
              (s) => s.key == tab.itemKey,
            );
            if (badge != undefined) {
              if (badge.type == BadgeType.Int && badge.value != '0') {
                tab.badge = badge.value;
              } else if (
                badge.type == BadgeType.Boolean &&
                badge.value == 'True'
              ) {
                tab.badge = '*';
              } else if (badge.type == BadgeType.String && badge.value != '') {
                tab.badge = badge.value;
              }
            }
          }
        }
      });
    }
  }
}
