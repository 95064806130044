/**
 * Permet de convertir le filtre DeveXtreme en Filtre compatible avec les services GraphQL.
 */
export class CriteriaHelpers {
  /**
   * Permet de compiler les critères issus de DevExtreme.
   * @param criteria Les critères de recherche.
   */
  public static compile(criteria) {
    let toto =
      criteria[0] instanceof Array || criteria[0] === '!'
        ? CriteriaHelpers.compileGroup(criteria)
        : [CriteriaHelpers.compileBinary(criteria)];
    return toto;
  }
  /**
   * Permet de compiler les groupes de critères issus de DevExtreme.
   * @param criterias Les critères de recherche.
   */
  private static compileGroup(criterias) {
    let groups = [],
      lastGroup = undefined;

    for (let i = 0, l = criterias.length; i < l; i++) {
      let criteria = <any>criterias[i];
      if (
        criteria[0] instanceof Array &&
        // Si il n'y a pas plusieurs colonnes ['col1','col2',...]
        !(criteria.length == 3 && !(criteria[2] instanceof Array))
      ) {
        if (lastGroup === undefined) {
          groups = CriteriaHelpers.compileGroup(criteria);
        } else {
          lastGroup.groups = CriteriaHelpers.compileGroup(criteria);
        }
      } else {
        if (criteria.length > 1 && criteria[0] === '!') {
          if (lastGroup === undefined) {
            groups = CriteriaHelpers.compileGroup(criteria);
          } else {
            lastGroup.groups = CriteriaHelpers.compileGroup(criteria);
          }
        } else {
          lastGroup = CriteriaHelpers.compileBinary(criteria);
          if (Array.isArray(lastGroup)) {
            groups = groups.concat(lastGroup);
          } else {
            groups.push(lastGroup);
          }
        }
      }
    }
    return groups;
  }
  /**
   * Permet de compiler les critères issus de DevExtreme.
   * @param criterias Les critères de recherche.
   */
  private static compileBinary(criteria) {
    if (criteria instanceof Array) {
      if (Array.isArray(criteria[0])) {
        let results = [];
        for (let column of criteria[0]) {
          results.push({
            name: column,
            operation: criteria[1],
            value: criteria[2],
          });
          results.push({ groupOperation: 'or' });
        }
        results.pop();
        return results;
      } else {
        return {
          name: criteria[0],
          operation: criteria[1],
          value: criteria[2],
        };
      }
    } else {
      return { groupOperation: criteria };
    }
  }

  public static decompile(data: Array<any>) {
    let result: Array<any> = [];
    for (let entry of data) {
      if (entry.GroupOperation == undefined) {
        result.push([entry.Name, entry.Operation, entry.Value]);
      } else {
        if (entry.Groups != undefined) {
          result.push(entry.GroupOperation);
          result.push(this.decompile(entry.Groups));
        } else {
          result.push(entry.GroupOperation);
        }
      }
    }
    return result;
  }
}
