import { Injectable } from '@angular/core';
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { Observable } from 'rxjs';

export interface Theme {
  name: string;
  properties: any;
}
export const defaultTheme: Theme = {
  name: 'default',
  properties: {
    '--primary-color': '26, 29, 58',
    '--secondary-color': '39, 44, 86',
    '--headband-color': '26, 29, 58',

    // Bandeau
    '--headband': 'rgb(var(--headband-color))',
    '--headband-50': 'rgb(var(--headband-color),0.5)',
    '--headband-10': 'rgb(var(--headband-color),0.7)',
    // Couleur par défaut à réécraser
    '--input-color': '248, 248, 248',
    '--input-hover-color': '238, 238, 238',
    '--input-disabled-color': '243, 243, 243',
    '--border-color': '204, 204, 204',
    '--border-color-active': '26, 29, 58',

    '--error-color': '244, 67, 54',
    '--primary-font-color': '0,0,0',
    '--primary-font-hover-color': '255,255,255',
    '--secondary-font-color': '255,255,255',
    // Bordure
    '--border': 'rgb(var(--border-color))',
    // Les champs input
    '--input-error': 'rgb(var(--error-color))',

    '--input': 'rgb(var(--input-color))',
    '--input-disabled': 'rgb(var(--input-disabled-color))',
    '--input-hover': 'rgb(var(--input-hover-color))',
    '--input-border': 'rgb(var(--border-color))',
    '--input-border-active': 'rgb(var(--border-color-active))',

    '--input-font': 'rgb(var(--primary-font-color))',
    '--input-font-hover': 'rgb(var(--primary-font-hover-color))',

    // TagsBox
    '--tag-incident-color': '255, 69, 0',
    '--tag-request-color': '65, 153, 190',
    '--tag-problem-color': '204, 0, 0',
    '--tags-box-request-background': 'rgb(var(--tag-request-color))',
    '--tags-box-incident-background': 'rgb(var(--tag-incident-color))',
    '--tags-box-problem-background': 'rgb(var(--tag-problem-color))',

    // TagsBoxVerifaliaClassification
    '--tag-risky-color': '255, 0, 0',
    '--tag-deliverable-color': '0, 128, 0',
    '--tag-undeliverable-color': '255, 165, 0',
    '--tags-box-risky-background': 'rgb(var(--tag-risky-color))',
    '--tags-box-deliverable-background': 'rgb(var(--tag-deliverable-color))',
    '--tags-box-undeliverable-background':
      'rgb(var(--tag-undeliverable-color))',

    // Les couleurs principales
    '--primary-font': 'rgb(var(--primary-font-color))',
    '--primary': 'rgb(var(--primary-color))',
    '--primary-75': 'rgba(var(--primary-color), 0.75)',
    '--primary-50': 'rgba(var(--primary-color), 0.5)',
    '--primary-20': 'rgba(var(--primary-color), 0.2)',
    '--primary-10': 'rgba(var(--primary-color), 0.1)',
    '--primary-05': 'rgba(var(--primary-color), 0.05)',
    // Les couleurs secondaires
    '--secondary-font': 'rgb(var(--secondary-font-color))',
    '--secondary': 'rgb(var(--secondary-color))',
    '--secondary-75': 'rgba(var(--secondary-color), 0.75)',
    '--secondary-50': 'rgba(var(--secondary-color), 0.5)',
    '--secondary-20': 'rgba(var(--secondary-color), 0.2)',
    '--secondary-10': 'rgba(var(--secondary-color), 0.1)',
    '--secondary-05': 'rgba(var(--secondary-color), 0.05)',
  },
};

@Injectable({
  providedIn: 'root',
})
export class ThemeService {
  private active: Theme = defaultTheme;
  private availableThemes: Theme[] = [defaultTheme];

  register(theme: Theme) {
    // Uniquement pour le débug
    // let themes = this.availableThemes.filter(t => t.name == theme.name);
    // if (themes.length > 0) {
    //   throw new Error(`Le thème '${theme.name}' existe déjà.`);
    // }
    this.availableThemes.push(theme);
  }
  setDefault() {
    this.setActiveTheme(defaultTheme.name);
  }
  getAvailableThemes(): Theme[] {
    return this.availableThemes;
  }

  getActiveTheme(): Theme {
    return this.active;
  }
  setActiveTheme(name: string): void {
    let themes = this.availableThemes.filter((t) => t.name === name);
    if (themes.length > 0) {
      this.active = themes[0];

      Object.keys(this.active.properties).forEach((property) => {
        document.documentElement.style.setProperty(
          property,
          this.active.properties[property],
        );
      });
    }
  }
}

/** Représente la classe d'accès au route. */
@Injectable({
  providedIn: 'root',
})
export class AuthThemeLoaderGuard {
  constructor(private _themeService: ThemeService) {}
  canActivateChild(
    childRoute: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ):
    | boolean
    | UrlTree
    | Observable<boolean | UrlTree>
    | Promise<boolean | UrlTree> {
    let theme = this.searchTheme(childRoute);
    if (theme != undefined) {
      this._themeService.setActiveTheme(theme);
    } else {
      this._themeService.setDefault();
    }
    return true;
  }
  private searchTheme(childRoute: ActivatedRouteSnapshot) {
    if (childRoute.data.theme != undefined) {
      return childRoute.data.theme;
    } else if (childRoute.parent != undefined) {
      return this.searchTheme(childRoute.parent);
    } else {
      return undefined;
    }
  }
}
