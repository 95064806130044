import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDiskRamInformationsBaseVariables, FirstDiskRamInformationsBaseVariables, CountDiskRamInformationsBaseVariables, FindDiskRamInformationsBaseVariables, SearchDiskRamInformationsBaseVariables, ExportSchemaDiskRamInformationsBaseVariables, ExportDataDiskRamInformationsBaseVariables, CustomQueryDiskRamInformationsBaseVariables, CustomQueryIdDiskRamInformationsBaseVariables, UsedDiskRamInformationsBaseVariables, ExistDiskRamInformationsBaseVariables } from '../gqls'
import { GetDiskRamInformationsDocument, FirstDiskRamInformationsDocument, CountDiskRamInformationsDocument, FindDiskRamInformationsDocument, SearchDiskRamInformationsDocument, ExportSchemaDiskRamInformationsDocument, ExportDataDiskRamInformationsDocument, CustomQueryDiskRamInformationsDocument, CustomQueryIdDiskRamInformationsDocument, UsedDiskRamInformationsDocument, ExistDiskRamInformationsDocument } from '../gqls'
import { ServiceSingleResultOfDiskRamInformation, QueryContextOfDiskRamInformation, FilterOfDiskRamInformation, ServiceSingleResultOfInt64, ServiceListResultOfDiskRamInformation, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DiskRamInformationBaseService {
    
public modelName = 'diskRamInformation';
public modelPluralName = 'diskRamInformations';

	private getDiskRamInformationsQuery: GetDiskRamInformationsDocument;
	private firstDiskRamInformationsQuery: FirstDiskRamInformationsDocument;
	private countDiskRamInformationsQuery: CountDiskRamInformationsDocument;
	private findDiskRamInformationsQuery: FindDiskRamInformationsDocument;
	private searchDiskRamInformationsQuery: SearchDiskRamInformationsDocument;
	private exportSchemaDiskRamInformationsQuery: ExportSchemaDiskRamInformationsDocument;
	private exportDataDiskRamInformationsQuery: ExportDataDiskRamInformationsDocument;
	private customQueryDiskRamInformationsQuery: CustomQueryDiskRamInformationsDocument;
	private customQueryIdDiskRamInformationsQuery: CustomQueryIdDiskRamInformationsDocument;
	private usedDiskRamInformationsQuery: UsedDiskRamInformationsDocument;
	private existDiskRamInformationsQuery: ExistDiskRamInformationsDocument;

	constructor(private injector: Injector) {
		this.getDiskRamInformationsQuery = this.injector.get(GetDiskRamInformationsDocument);
		this.firstDiskRamInformationsQuery = this.injector.get(FirstDiskRamInformationsDocument);
		this.countDiskRamInformationsQuery = this.injector.get(CountDiskRamInformationsDocument);
		this.findDiskRamInformationsQuery = this.injector.get(FindDiskRamInformationsDocument);
		this.searchDiskRamInformationsQuery = this.injector.get(SearchDiskRamInformationsDocument);
		this.exportSchemaDiskRamInformationsQuery = this.injector.get(ExportSchemaDiskRamInformationsDocument);
		this.exportDataDiskRamInformationsQuery = this.injector.get(ExportDataDiskRamInformationsDocument);
		this.customQueryDiskRamInformationsQuery = this.injector.get(CustomQueryDiskRamInformationsDocument);
		this.customQueryIdDiskRamInformationsQuery = this.injector.get(CustomQueryIdDiskRamInformationsDocument);
		this.usedDiskRamInformationsQuery = this.injector.get(UsedDiskRamInformationsDocument);
		this.existDiskRamInformationsQuery = this.injector.get(ExistDiskRamInformationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDiskRamInformation> {
    
    		let variables: GetDiskRamInformationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.get));
            }
            else{
                let result:ServiceSingleResultOfDiskRamInformation={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDiskRamInformation,
		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDiskRamInformation> {
    
    		let variables: FirstDiskRamInformationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDiskRamInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.countDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDiskRamInformation,
		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskRamInformation> {
    
    		let variables: FindDiskRamInformationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDiskRamInformation,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskRamInformation> {
    
    		let variables: SearchDiskRamInformationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDiskRamInformationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDiskRamInformationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDiskRamInformation,
		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskRamInformation> {
    
    		let variables: CustomQueryDiskRamInformationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDiskRamInformation,
		@Args('filter?') filter?: FilterOfDiskRamInformation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskRamInformation> {
    
    		let variables: CustomQueryIdDiskRamInformationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDiskRamInformationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDiskRamInformationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDiskRamInformationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskRamInformations.exist));
    	}
    
    
}