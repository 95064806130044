import {
  Component,
  ElementRef,
  forwardRef,
  HostListener,
  Injector,
  Input,
  NgZone,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { GC, GCFactory } from '@clarilog/core';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { dirtyCheck } from '../../form/work-item-form/dirty-check.service';

import {
  PageSection,
  PageTab,
  PageControl,
  Control,
  SectionGroup,
  Section,
  WorkItemConfiguration,
} from '@clarilog/shared2/models/schema';

import { of } from 'rxjs';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { CoreFormLoader } from '../../form/work-form/form-loader';
import { CoreFormRecorder } from '../../form/work-form/form-recorder';
import { AccountCoreService } from '@clarilog/core/services2/graphql/generated-types/services';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-permissions-list',
  templateUrl: './permissions-list.component.html',
  styleUrls: ['./permissions-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CorePermissionsListComponent),
      multi: true,
    },
  ],
})
export class CorePermissionsListComponent
  implements ControlValueAccessor, OnInit, OnChanges, OnDestroy
{
  private gc: GC;
  private scrollTop: number = 0;
  private _isInSaveMode = false;
  /** Obtient ou définit une valeur si le formulaire est chargé. */
  loaded = false;
  /** Obtient ou définit le model. */
  model: ModelState;
  /** Obtient ou définit le FormGroup. */
  // form: FormGroup = new FormGroup({});
  /** Obtient les données modifiés à envoyer au serveur. */
  private _values: any[];
  /** Obtient ou définit les valeurs en cours de modifications. */
  private _updateValues: any[] = [];
  /** Obtient les données modifiées à l'origine. */
  private _originalValue: any[] = [];
  /** Permet de conserver les données du serveur. */
  private _originalData: any[];
  /** Déclenche l'évènement du changement. */
  onChange: any = () => {};
  /** Déclenche l'évènement du touché. */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit la source de données. */
  @Input() source: ModelFnContext;
  /** Obtient ou définit le champ représentant le label. */
  @Input() labelExpr: string;
  /** Obtient ou définit le champ représentant la valeur. */
  @Input() valueExpr: string;
  /** Obtient ou définit les champs à afficher. */
  @Input() fields: any[];

  /** Obtient ou définit la source du tab */
  sourceTab: any[];

  noData: boolean = false;
  constructor(
    gcFactory: GCFactory,
    private element: ElementRef,
    private loader: CoreFormLoader,
    private recorder: CoreFormRecorder,
    private modelFieldCompilerService: ModelFieldCompilerService,
    /** Récupère le FormGroup parent. */
    public parent: FormGroupDirective,
    private ngZone: NgZone,
    private accountService: AccountCoreService,
    private injector: Injector,
  ) {
    this.gc = gcFactory.create();
  }
  @HostListener('scroll', ['$event'])
  scroll(event) {
    if (this.loaded === true) {
      this.scrollTop = event.srcElement.scrollTop;
    }
  }

  public page(): PageSection {
    let pageTab = this.model.model.form.layout.pages[0] as PageTab;
    return pageTab.tabs[0] as PageSection;
  }
  public pageTab(): PageTab {
    return this.model.model.form.layout.pages[0] as PageTab;
  }

  /** Initialisation des champs déjà modifiés. */
  private initOriginalUpdate(data) {
    let results = data.permissions.filter((f) => f.inheritFromIds.length === 0);
    if (results.length > 0) {
      let save = {
        value: data.value,
        type: data.type,
        permissions: [],
      };

      for (let r of results) {
        save.permissions.push({
          value: r.value,
          access: r.access,
        });
      }
      this._originalValue.push(save);
    }
  }
  /** Met en place la detection des mise à jour des données. */
  private detectUpdate() {  
    let subscription = this.model.form.valueChanges.subscribe((r) => {
      let materialize = this.recorder.materialize(this.model.form);
      this._updateValues = [];

      for (let i in materialize['items']) {
        let value = this._originalData[i][this.valueExpr];

        if (value != undefined) {
          let obj = {};
          if (this._originalData[i]['type'] != undefined) {
            obj['type'] = this._originalData[i]['type'];
          }
          let hasAdded = false;
          // Ajout de la value
          obj[this.valueExpr] = value;
          for (let field of this.fields) {
            obj[field.fieldNameExpr] = [];
            for (let j in materialize['items'][i][field.fieldNameExpr]) {
              let itemValue =
                this._originalData[i][field.fieldNameExpr][j][
                  field.control.valueExpr
                ];
              if (
                itemValue != undefined &&
                materialize['items'][i][field.fieldNameExpr][j][
                  field.control.defaultValueExpr
                ] !=
                  this._originalData[i][field.fieldNameExpr][j][
                    field.control.defaultValueExpr
                  ]
              ) {
                hasAdded = true;
                let item = {};
                item[field.control.valueExpr] = itemValue;
                item[field.control.defaultValueExpr] =
                  materialize['items'][i][field.fieldNameExpr][j][
                    field.control.defaultValueExpr
                  ];
                obj[field.fieldNameExpr].push(item);
                if (
                  item[field.control.valueExpr] == 'all' &&
                  item[field.control.defaultValueExpr] == 'CUSTOM'
                ) {
                  for (let p of this._originalData[i][field.fieldNameExpr]) {
                    if (p[field.control.valueExpr] != 'all') {
                      let pitem = {};
                      pitem[field.control.valueExpr] =
                        p[field.control.valueExpr];
                      pitem[field.control.defaultValueExpr] =
                        p[field.control.defaultValueExpr];
                      obj[field.fieldNameExpr].push(pitem);
                    }
                  }
                }
                if (item[field.control.valueExpr] == 'update' && 
                      item[field.control.defaultValueExpr] == 'ALLOW')
                {                  
                  obj[field.fieldNameExpr].push({value:'read',access:'ALLOW'});
                }
                if (item[field.control.valueExpr] == 'read' && item[field.control.defaultValueExpr] == 'DENY')
                {         
                  let original = this._originalData;  
                  let permissions = original.find(x=> x.value == obj['value']).permissions 
                  let update = permissions.find(x=> x.value == 'update');    
                  if(update.access == 'ALLOW'){
                    obj[field.fieldNameExpr].push({value:'update',access:'DENY'});
                  }                  
                }
              }
            }
          }
          if (hasAdded === true) {
            this._updateValues.push(obj);
          }
        }
      }
    });
    this.gc.forRelease(subscription);
  }
  /** Met en place le suivi des modifications. */
  private dirtyCheck() {
    let isDirty$ = dirtyCheck(
      this.model.form,
      of(JSON.parse(JSON.stringify(this.model.form.value))),
      false,
    )(this.model.form.valueChanges);
    let subscription = isDirty$.subscribe((dirty) => {
      if (this.model.form.loaded === true) {
        if (dirty === true) {
          this.onChange(this._updateValues);
        } else if (this.model.form.dirty === true) {
          this.reset(this._originalValue);
        }
      }
    });

    this.gc.forRelease(subscription);
  }
  /** Mise à zéro. */
  private reset(values) {
    let obj = {};
    for (let member in this.parent.value) {
      obj[member] = values;
    }
    this.parent.reset(obj);
  }
  /** Charge les données et créé les composants. */
  private async load() {
    this.gc.release();
    this._isInSaveMode = false;
    this.noData = false;
    this.loaded = false;

    let roleType = null;
    let typeField = FormGroupHelpers.formControlByName(
      this.parent.form.root as UntypedFormGroup,
      'type',
    );
    if (typeField == null || typeField == undefined) {
      let roleTypeOfField = FormGroupHelpers.formControlByName(
        this.parent.form.root as UntypedFormGroup,
        'roleTypes',
      );
      if (roleTypeOfField != null || roleTypeOfField != undefined)
        roleType = roleTypeOfField.value;
    } else {
      roleType = typeField.value;
    }

    if (roleType == null) {
      roleType = ['Manager', 'HelpDeskOperator', 'HelpDeskUser'];
    }

    await this.source
      .fnCall()
      .toPromise()
      .then(async (result) => {
        let model = this.createModel();
        let pageTab = this.createPageTab();
        let pageSectionCommon = this.createPageSection();
        let pageSectionManager = this.createPageSection();
        let pageSectionOperator = this.createPageSection();
        let pageSectionUserHelpDesk = this.createPageSection();
        let sectionCommon = this.createSection();
        let sectionManager = this.createSection();
        let sectionOperator = this.createSection();
        let sectionUserHelpDesk = this.createSection();
        let inheritTabManager = false;
        let inheritTabOperator = false;
        let inheritTabHelpDesk = false;

        this.model = new ModelState(this.injector);
        if (result.data.length > 0) {
          // Conserve les données de la base
          this._originalData = result.data;

          let commonData = result.data.filter((f) => f.type == 'NONE');
          for (let data of commonData) {
            let sectionGroupCommon = this.createSectionGroup(data);

            for (let field of this.fields) {
              for (let item of data[field.fieldNameExpr]) {
                sectionGroupCommon.controls.push(
                  this.createControl(result.data, data, field, item),
                );
                sectionGroupCommon.controls.sort(this.sortControlByName);
              }
            }
            sectionCommon.groups.push(sectionGroupCommon);

            this.initOriginalUpdate(data);
          }

          let managerData = result.data.filter((f) => f.type == 'MANAGER');
          for (let data of managerData) {
            let sectionGroupManager = this.createSectionGroup(data);

            for (let field of this.fields) {
              for (let item of data[field.fieldNameExpr]) {
                if (
                  inheritTabManager == false &&
                  item.inheritType != undefined &&
                  item.inheritType.includes('Manager')
                ) {
                  inheritTabManager = true;
                }
                sectionGroupManager.controls.push(
                  this.createControl(result.data, data, field, item),
                );
                sectionGroupManager.controls.sort(this.sortControlByName);
              }
            }
            sectionManager.groups.push(sectionGroupManager);

            this.initOriginalUpdate(data);
          }
          let operatorData = result.data.filter(
            (f) => f.type == 'HELP_DESK_OPERATOR',
          );
          for (let data of operatorData) {
            let sectionGroupOperator = this.createSectionGroup(data);

            for (let field of this.fields) {
              for (let item of data[field.fieldNameExpr]) {
                if (
                  inheritTabOperator == false &&
                  item.inheritType != undefined &&
                  item.inheritType.includes('HelpDeskOperator')
                ) {
                  inheritTabOperator = true;
                }
                let control = this.createControl(
                  result.data,
                  data,
                  field,
                  item,
                );
                sectionGroupOperator.controls.push(control);
                sectionGroupOperator.controls.sort(this.sortControlByName);
              }
            }
            sectionOperator.groups.push(sectionGroupOperator);

            this.initOriginalUpdate(data);
          }

          let userHelpDeskData = result.data.filter(
            (f) => f.type == 'HELP_DESK_USER',
          );
          for (let data of userHelpDeskData) {
            let sectionGroupUserHelpDesk = this.createSectionGroup(data);

            for (let field of this.fields) {
              for (let item of data[field.fieldNameExpr]) {
                if (
                  inheritTabHelpDesk == false &&
                  item.inheritType != undefined &&
                  item.inheritType.includes('HelpDeskUser')
                ) {
                  inheritTabHelpDesk = true;
                }
                let control = this.createControl(
                  result.data,
                  data,
                  field,
                  item,
                );
                sectionGroupUserHelpDesk.controls.push(control);
                sectionGroupUserHelpDesk.controls.sort(this.sortControlByName);
              }
            }
            sectionUserHelpDesk.groups.push(sectionGroupUserHelpDesk);
            this.initOriginalUpdate(data);
          }
        } else {
          this.noData = true;
        }

        pageSectionCommon.sections.push(sectionCommon);
        pageSectionCommon.label = TranslateService.get(
          'entities/role/commonTypeLabel',
        );

        pageSectionManager.sections.push(sectionManager);
        pageSectionManager.label = TranslateService.get(
          'entities/role/managerTypeLabel',
        );

        pageSectionOperator.sections.push(sectionOperator);
        pageSectionOperator.label = TranslateService.get(
          'entities/role/operatorTypeLabel',
        );

        pageSectionUserHelpDesk.sections.push(sectionUserHelpDesk);
        pageSectionUserHelpDesk.label = TranslateService.get(
          'entities/role/userTypeLabel',
        );

        if (
          roleType.includes('Manager') ||
          roleType.includes('HelpDeskOperator')
        ) {
          pageTab.tabs.push(pageSectionCommon);
        }

        if (roleType.includes('Manager')) {
          pageTab.tabs.push(pageSectionManager);
        }

        if (roleType.includes('HelpDeskOperator')) {
          pageTab.tabs.push(pageSectionOperator);
        }

        if (roleType.includes('HelpDeskUser')) {
          pageTab.tabs.push(pageSectionUserHelpDesk);
        }

        model.form.layout.pages.push(pageTab);

        this.model.model = model;
        this.modelFieldCompilerService.compile(this.model);

        this.sourceTab = pageTab.tabs.map((tab) => {
          let hint = '';
          let tabHint = tab as PageControl;
          if (
            tabHint != undefined &&
            tabHint.control != undefined &&
            tabHint.control.hint != undefined
          ) {
            hint = '' + tabHint.control.hint;
          }
          return { title: tab.label, item: tab, hint: hint };
        });

        await this.loader.create(this.model);
        // Valeurs par défaut
        this.loader.load(this.model.form, { items: this._originalData });

        if (this._updateValues.length > 0) {
          let originalData = JSON.parse(JSON.stringify(this._originalData));
          for (let updatePermissionScope of this._updateValues) {
            let searchPermissionScopes = originalData.filter(
              (f) => f.value === updatePermissionScope.value,
            );
            if (searchPermissionScopes.length > 0) {
              let searchPermissionScope = searchPermissionScopes[0];
              for (let updatePermission of updatePermissionScope.permissions) {
                let searchPermissions =
                  searchPermissionScope.permissions.filter(
                    (f) => f.value === updatePermission.value,
                  );
                if (searchPermissions.length > 0) {
                  let searchPermission = searchPermissions[0];
                  searchPermission.access = updatePermission.access;
                }
              }
            }
          }
          this.loader.load(this.model.form, { items: originalData }, true);
        }

        this.updateDisable();

        this.dirtyCheck();

        setTimeout(() => {
          this.model.form.markAsLoaded();
        });
        this.detectUpdate();

        let subscription = this.parent.form.root.onLoadedChanged.subscribe(
          (result) => {
            if (this._isInSaveMode === false) {
              if (result === false) {
                this.model.form.markAsLoaded();
                setTimeout(() => {
                  if (this.isDisposed === false) {
                    this._isInSaveMode = true;
                    this._updateValues = [];
                    this.reset(this._originalValue);
                    this.refresh();
                  }
                }, 2100);
              }
            }
          },
        );

        this.gc.forRelease(subscription);
        subscription = this.model.form.root.onLoadedChanged.subscribe(
          (result) => {
            setTimeout(() => {
              this.element.nativeElement.scrollTop = this.scrollTop;
              this.loaded = true;
            });
          },
        );
        this.gc.forRelease(subscription);
      });
  }
  /** Recharge les données. */
  refresh() {
    this.load();
  }
  /** Met à jour l'état de désactivation. */
  private updateDisable() {
    this.ngZone.run(() => {
      if (this.model != undefined && this.model.form != undefined) {
        if (this.disabled) {
          this.model.form.disable({ emitEvent: false });
        } else {
          this.model.form.enable({ emitEvent: false });
        }
      }
    });
  }
  /** Permet de créer le control de bouton radio. */
  private createControl(datas, data, field, item): Control {
    let indexAll = data[field.fieldNameExpr].findIndex(
      (f) => f[field.control.valueExpr] === 'all',
    );
    // Passe le paramètre "fieldName" à la source.
    (
      ((field.control as Control).options as any)
        .source as any as ModelFnContext
    ).context.params.set('current', () => item);
    // TODO A prendre en compte lors de la refonte du context : Créé la source de données
    let fn = (
      ((field.control as Control).options as any)
        .source as any as ModelFnContext
    ).fnCall();
    return <Control>(<unknown>{
      mode: field.control.mode,
      label: TranslateService.get(
        `permissions/${item[field.control.valueExpr]}`,
      ),
      fieldName: `items[${datas.indexOf(data)}].${field.fieldNameExpr}[${data[
        field.fieldNameExpr
      ].indexOf(item)}].${field.control.defaultValueExpr}`,
      type: 'RadioButtonComponent',
      hint: () => {
        let hint = item[field.control.hintExpr];
        let searchPermissionScopes = this._updateValues.filter(
          (f) => f.value === data.value,
        );
        if (searchPermissionScopes.length > 0) {
          let searchPermissionScope = searchPermissionScopes[0];
          let permissions = searchPermissionScope.permissions.filter(
            (f) => f.value === item.value,
          );
          if (permissions.length > 0) {
            let permission = permissions[0];
            if (
              permission.access !== item.access ||
              permission.inheritFrom !== ''
            ) {
              hint = '';
            }
          }
        }
        return hint;
      },
      options: {
        ...field.control.options,
        source: fn,
      },
      // TODO A optimiser : Ne pas laisser là - Optimisation lors du changement de context et dependsOn
      dependsOn:
        item.value != 'all'
          ? {
              effect: 'Visible',
              controlName: `items[${datas.indexOf(data)}].${
                field.fieldNameExpr
              }[${indexAll}].access`,
              options: {
                value: 'CUSTOM',
              },
            }
          : undefined,
    });
  }
  /** Créer un groupe de section. */
  private createSectionGroup(data): SectionGroup {
    return <SectionGroup>{
      label: TranslateService.get(`permissions/${data[this.valueExpr]}`),
      name: data[this.valueExpr],
      controls: [],
    };
  }
  /** Créer un model vide. */
  private createModel(): WorkItemConfiguration {
    let form = {
      form: {
        layout: {
          pages: [],
        },
      },
    };
    return form;
  }
  /** Créer une page en onglet. */
  private createPageTab(): PageTab {
    return <PageTab>{
      tabs: [],
    };
  }
  /** Créer une section de page. */
  private createPageSection(): PageSection {
    return <PageSection>{
      sections: [],
    };
  }
  /** Créer une section. */
  private createSection(): Section {
    return <Section>{
      location: 'full',
      groups: [],
    };
  }
  /** @inheritdoc */
  writeValue(values: any[]): void {
    this._values = values;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.updateDisable();
  }
  /** @inheritdoc */
  ngOnInit(): void {}
  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.source != undefined) {
      this.load();
    }
  }
  isDisposed: boolean = false;
  /** @inheritdoc */
  ngOnDestroy(): void {
    this.isDisposed = true;
    this.gc.dispose();
  }
  sortByName(a, b): number {
    const labelA = a.label.toUpperCase();
    const labelB = b.label.toUpperCase();
    if (labelA < labelB) {
      return -1;
    }
    if (labelA > labelB) {
      return 1;
    }
    return 0;
  }

  /**
   * Trie les permissions dans l'ordre alphabétique sauf les 4 premières :
   * (Contrôle total, Mes ..., ... de mes équipes, Tous ...)
   */
  sortControlByName(a, b): number {
    const hasPermissionField = (fieldName: string) => [0, 1, 2, 3]
      .some(i => fieldName.includes(`permissions[${i}].access`));

    if (!hasPermissionField(a.fieldName) && !hasPermissionField(b.fieldName)) 
    {
      const labelA = a.label.toUpperCase();
      const labelB = b.label.toUpperCase();

      return labelA.localeCompare(labelB);
    }
    return 0;
  }
}
