import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetQueryBuilderAppointmentTasksBaseVariables, FirstQueryBuilderAppointmentTasksBaseVariables, CountQueryBuilderAppointmentTasksBaseVariables, FindQueryBuilderAppointmentTasksBaseVariables, SearchQueryBuilderAppointmentTasksBaseVariables, ExportSchemaQueryBuilderAppointmentTasksBaseVariables, ExportDataQueryBuilderAppointmentTasksBaseVariables, CustomQueryQueryBuilderAppointmentTasksBaseVariables, CustomQueryIdQueryBuilderAppointmentTasksBaseVariables, UsedQueryBuilderAppointmentTasksBaseVariables, ExistQueryBuilderAppointmentTasksBaseVariables, InsertQueryBuilderAppointmentTasksBaseVariables, UpdateQueryBuilderAppointmentTasksBaseVariables, ImportDataQueryBuilderAppointmentTasksBaseVariables, UpdateManyQueryBuilderAppointmentTasksBaseVariables, DeleteQueryBuilderAppointmentTasksBaseVariables } from '../gqls'
import { GetQueryBuilderAppointmentTasksDocument, FirstQueryBuilderAppointmentTasksDocument, CountQueryBuilderAppointmentTasksDocument, FindQueryBuilderAppointmentTasksDocument, SearchQueryBuilderAppointmentTasksDocument, ExportSchemaQueryBuilderAppointmentTasksDocument, ExportDataQueryBuilderAppointmentTasksDocument, CustomQueryQueryBuilderAppointmentTasksDocument, CustomQueryIdQueryBuilderAppointmentTasksDocument, UsedQueryBuilderAppointmentTasksDocument, ExistQueryBuilderAppointmentTasksDocument, InsertQueryBuilderAppointmentTasksDocument, UpdateQueryBuilderAppointmentTasksDocument, ImportDataQueryBuilderAppointmentTasksDocument, UpdateManyQueryBuilderAppointmentTasksDocument, DeleteQueryBuilderAppointmentTasksDocument } from '../gqls'
import { ServiceSingleResultOfQueryBuilderAppointmentTask, QueryContextOfQueryBuilderAppointmentTask, FilterOfQueryBuilderAppointmentTask, ServiceSingleResultOfInt64, ServiceListResultOfQueryBuilderAppointmentTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryBuilderAppointmentTaskInput, FieldUpdateOperatorOfQueryBuilderAppointmentTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class QueryBuilderAppointmentTaskBaseService {
    
public modelName = 'queryBuilderAppointmentTask';
public modelPluralName = 'queryBuilderAppointmentTasks';

	private getQueryBuilderAppointmentTasksQuery: GetQueryBuilderAppointmentTasksDocument;
	private firstQueryBuilderAppointmentTasksQuery: FirstQueryBuilderAppointmentTasksDocument;
	private countQueryBuilderAppointmentTasksQuery: CountQueryBuilderAppointmentTasksDocument;
	private findQueryBuilderAppointmentTasksQuery: FindQueryBuilderAppointmentTasksDocument;
	private searchQueryBuilderAppointmentTasksQuery: SearchQueryBuilderAppointmentTasksDocument;
	private exportSchemaQueryBuilderAppointmentTasksQuery: ExportSchemaQueryBuilderAppointmentTasksDocument;
	private exportDataQueryBuilderAppointmentTasksQuery: ExportDataQueryBuilderAppointmentTasksDocument;
	private customQueryQueryBuilderAppointmentTasksQuery: CustomQueryQueryBuilderAppointmentTasksDocument;
	private customQueryIdQueryBuilderAppointmentTasksQuery: CustomQueryIdQueryBuilderAppointmentTasksDocument;
	private usedQueryBuilderAppointmentTasksQuery: UsedQueryBuilderAppointmentTasksDocument;
	private existQueryBuilderAppointmentTasksQuery: ExistQueryBuilderAppointmentTasksDocument;
	private insertQueryBuilderAppointmentTasksMutation: InsertQueryBuilderAppointmentTasksDocument;
	private updateQueryBuilderAppointmentTasksMutation: UpdateQueryBuilderAppointmentTasksDocument;
	private importDataQueryBuilderAppointmentTasksMutation: ImportDataQueryBuilderAppointmentTasksDocument;
	private updateManyQueryBuilderAppointmentTasksMutation: UpdateManyQueryBuilderAppointmentTasksDocument;
	private deleteQueryBuilderAppointmentTasksMutation: DeleteQueryBuilderAppointmentTasksDocument;

	constructor(private injector: Injector) {
		this.getQueryBuilderAppointmentTasksQuery = this.injector.get(GetQueryBuilderAppointmentTasksDocument);
		this.firstQueryBuilderAppointmentTasksQuery = this.injector.get(FirstQueryBuilderAppointmentTasksDocument);
		this.countQueryBuilderAppointmentTasksQuery = this.injector.get(CountQueryBuilderAppointmentTasksDocument);
		this.findQueryBuilderAppointmentTasksQuery = this.injector.get(FindQueryBuilderAppointmentTasksDocument);
		this.searchQueryBuilderAppointmentTasksQuery = this.injector.get(SearchQueryBuilderAppointmentTasksDocument);
		this.exportSchemaQueryBuilderAppointmentTasksQuery = this.injector.get(ExportSchemaQueryBuilderAppointmentTasksDocument);
		this.exportDataQueryBuilderAppointmentTasksQuery = this.injector.get(ExportDataQueryBuilderAppointmentTasksDocument);
		this.customQueryQueryBuilderAppointmentTasksQuery = this.injector.get(CustomQueryQueryBuilderAppointmentTasksDocument);
		this.customQueryIdQueryBuilderAppointmentTasksQuery = this.injector.get(CustomQueryIdQueryBuilderAppointmentTasksDocument);
		this.usedQueryBuilderAppointmentTasksQuery = this.injector.get(UsedQueryBuilderAppointmentTasksDocument);
		this.existQueryBuilderAppointmentTasksQuery = this.injector.get(ExistQueryBuilderAppointmentTasksDocument);
		this.insertQueryBuilderAppointmentTasksMutation = this.injector.get(InsertQueryBuilderAppointmentTasksDocument);
		this.updateQueryBuilderAppointmentTasksMutation = this.injector.get(UpdateQueryBuilderAppointmentTasksDocument);
		this.importDataQueryBuilderAppointmentTasksMutation = this.injector.get(ImportDataQueryBuilderAppointmentTasksDocument);
		this.updateManyQueryBuilderAppointmentTasksMutation = this.injector.get(UpdateManyQueryBuilderAppointmentTasksDocument);
		this.deleteQueryBuilderAppointmentTasksMutation = this.injector.get(DeleteQueryBuilderAppointmentTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilderAppointmentTask> {
    
    		let variables: GetQueryBuilderAppointmentTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.get));
            }
            else{
                let result:ServiceSingleResultOfQueryBuilderAppointmentTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilderAppointmentTask,
		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilderAppointmentTask> {
    
    		let variables: FirstQueryBuilderAppointmentTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountQueryBuilderAppointmentTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQueryBuilderAppointmentTask,
		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilderAppointmentTask> {
    
    		let variables: FindQueryBuilderAppointmentTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfQueryBuilderAppointmentTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilderAppointmentTask> {
    
    		let variables: SearchQueryBuilderAppointmentTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaQueryBuilderAppointmentTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataQueryBuilderAppointmentTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfQueryBuilderAppointmentTask,
		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilderAppointmentTask> {
    
    		let variables: CustomQueryQueryBuilderAppointmentTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfQueryBuilderAppointmentTask,
		@Args('filter?') filter?: FilterOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQueryBuilderAppointmentTask> {
    
    		let variables: CustomQueryIdQueryBuilderAppointmentTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedQueryBuilderAppointmentTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistQueryBuilderAppointmentTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existQueryBuilderAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: QueryBuilderAppointmentTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilderAppointmentTask> {
    
    		let variables: InsertQueryBuilderAppointmentTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertQueryBuilderAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQueryBuilderAppointmentTask> {
    
    		let variables: UpdateQueryBuilderAppointmentTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateQueryBuilderAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataQueryBuilderAppointmentTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataQueryBuilderAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfQueryBuilderAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyQueryBuilderAppointmentTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyQueryBuilderAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteQueryBuilderAppointmentTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteQueryBuilderAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.queryBuilderAppointmentTasks.delete));
    	}
    
}