import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfSoftwareHostedLink, FilterOfSoftwareHostedLink, QueryContextOfSoftwareHostedLink, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareHostedLink, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfSoftware, FilterOfSoftware, QueryContextOfSoftware, SoftwareHostedLinkInput, FieldUpdateOperatorOfSoftwareHostedLink } from '../types'

export type GetSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        get?: Maybe<ServiceSingleResultOfSoftwareHostedLink>
    }
}

export type GetSoftwareHostedLinksBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        first?: Maybe<ServiceSingleResultOfSoftwareHostedLink>
    }
}

export type FirstSoftwareHostedLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareHostedLink = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountSoftwareHostedLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        find?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type FindSoftwareHostedLinksBaseVariables = {
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfSoftwareHostedLink = null, $filter: FilterOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        search?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type SearchSoftwareHostedLinksBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaSoftwareHostedLinksBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataSoftwareHostedLinksBaseVariables = {
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQuerySoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        customQuery?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type CustomQuerySoftwareHostedLinksBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQuerySoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfSoftwareHostedLink = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQuerySoftwareHostedLinks ${args} {
    softwareHostedLinks {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQuerySoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQuerySoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        customQueryId?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type CustomQueryIdSoftwareHostedLinksBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfSoftwareHostedLink = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedSoftwareHostedLinksBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        findHostedComponent?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type FindHostedComponentSoftwareHostedLinksBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftwareHostedLink = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        findHostedComponent(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        findNotHostedComponent?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindNotHostedComponentSoftwareHostedLinksBaseVariables = {
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        findNotHostedComponent(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindSoftwareHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        findSoftwareHostedComponent?: Maybe<ServiceListResultOfSoftwareHostedLink>
    }
}

export type FindSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSoftwareHostedLink>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftwareHostedLink>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindSoftwareHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSoftwareHostedLink = null, $options: QueryContextOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findSoftwareHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        findSoftwareHostedComponent(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindSoftwareHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindSoftwareHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindNotSoftwareHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        findNotSoftwareHostedComponent?: Maybe<ServiceListResultOfSoftware>
    }
}

export type FindNotSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
	filter: Maybe<FilterOfSoftware>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSoftware>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindNotSoftwareHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfSoftware = null, $options: QueryContextOfSoftware = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findNotSoftwareHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        findNotSoftwareHostedComponent(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindNotSoftwareHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindNotSoftwareHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSoftware", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistSoftwareHostedLinksBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        insert?: Maybe<ServiceSingleResultOfSoftwareHostedLink>
    }
}

export type InsertSoftwareHostedLinksBaseVariables = {
	entity: SoftwareHostedLinkInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: SoftwareHostedLinkInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        update?: Maybe<ServiceSingleResultOfSoftwareHostedLink>
    }
}

export type UpdateSoftwareHostedLinksBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareHostedLink>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfSoftwareHostedLink", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataSoftwareHostedLinksBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManySoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManySoftwareHostedLinksBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfSoftwareHostedLink>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManySoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfSoftwareHostedLink = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManySoftwareHostedLinks ${args} {
    softwareHostedLinks {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManySoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManySoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteSoftwareHostedLinksBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SaveHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        saveHostedComponent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SaveHostedComponentSoftwareHostedLinksBaseVariables = {
	id: string, /**  */
	items: Array<Maybe<SoftwareHostedLinkInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SaveHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $items: [SoftwareHostedLinkInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation saveHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        saveHostedComponent(id: $id, items: $items) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SaveHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SaveHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SaveSoftwareHostedComponentSoftwareHostedLinksResultType = {
    softwareHostedLinks: {
        saveSoftwareHostedComponent?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SaveSoftwareHostedComponentSoftwareHostedLinksBaseVariables = {
	id: string, /**  */
	items: Array<Maybe<SoftwareHostedLinkInput>>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SaveSoftwareHostedComponentSoftwareHostedLinksDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $items: [SoftwareHostedLinkInput] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation saveSoftwareHostedComponentSoftwareHostedLinks ${args} {
    softwareHostedLinks {
        saveSoftwareHostedComponent(id: $id, items: $items) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SaveSoftwareHostedComponentSoftwareHostedLinksBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SaveSoftwareHostedComponentSoftwareHostedLinksResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
