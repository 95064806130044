import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { GqlField, GqlSubField } from '../helpers';
import { EmailConnectorBaseService } from '../service-bases';
import {
  FilterOfEmailConnector,
  QueryContextOfEmailConnector,
  ServiceListResultOfEmailConnector,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('administration.email-setting')
export class EmailConnectorCoreService extends EmailConnectorBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  emailConnectorEnumType(): any[] {
    return [
      {
        id: 'SMTP',
        value: 'SMTP',
        name: TranslateService.get('enums/emailConnectorTypeEnum/SMTP'),
      },
      {
        id: 'ONE',
        value: 'ONE',
        name: TranslateService.get('enums/emailConnectorTypeEnum/ONE'),
      },
      {
        id: 'OFFICE_365',
        value: 'OFFICE_365',
        name: TranslateService.get('enums/emailConnectorTypeEnum/OFFICE_365'),
      },
    ];
  }

  @InjectArgs
  public findInput(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfEmailConnector,
    @Args('filter?') filter?: FilterOfEmailConnector,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfEmailConnector> {
    if (filter == undefined) {
      filter = {};
    }
    filter.receive = { eq: true };
    return this.find(fields, options, filter, extendedVariables);
  }

  public findDefaultConnectorId() {
    let fields = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('name', [
          GqlField.create(this.translatedFieldHelperService.getTranslateKey()),
        ]),
        GqlField.create('server'),
      ]),
    ];

    let defaultConnector = this.findDefaultConnector(fields).toPromise();

    return defaultConnector.then((res) => {
      return res?.data?.id;
    });
  }
}
