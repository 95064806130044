import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCopyCountsBaseVariables, FirstCopyCountsBaseVariables, CountCopyCountsBaseVariables, FindCopyCountsBaseVariables, SearchCopyCountsBaseVariables, ExportSchemaCopyCountsBaseVariables, ExportDataCopyCountsBaseVariables, CustomQueryCopyCountsBaseVariables, CustomQueryIdCopyCountsBaseVariables, UsedCopyCountsBaseVariables, ExistCopyCountsBaseVariables } from '../gqls'
import { GetCopyCountsDocument, FirstCopyCountsDocument, CountCopyCountsDocument, FindCopyCountsDocument, SearchCopyCountsDocument, ExportSchemaCopyCountsDocument, ExportDataCopyCountsDocument, CustomQueryCopyCountsDocument, CustomQueryIdCopyCountsDocument, UsedCopyCountsDocument, ExistCopyCountsDocument } from '../gqls'
import { ServiceSingleResultOfCopyCount, QueryContextOfCopyCount, FilterOfCopyCount, ServiceSingleResultOfInt64, ServiceListResultOfCopyCount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CopyCountBaseService {
    
public modelName = 'copyCount';
public modelPluralName = 'copyCounts';

	private getCopyCountsQuery: GetCopyCountsDocument;
	private firstCopyCountsQuery: FirstCopyCountsDocument;
	private countCopyCountsQuery: CountCopyCountsDocument;
	private findCopyCountsQuery: FindCopyCountsDocument;
	private searchCopyCountsQuery: SearchCopyCountsDocument;
	private exportSchemaCopyCountsQuery: ExportSchemaCopyCountsDocument;
	private exportDataCopyCountsQuery: ExportDataCopyCountsDocument;
	private customQueryCopyCountsQuery: CustomQueryCopyCountsDocument;
	private customQueryIdCopyCountsQuery: CustomQueryIdCopyCountsDocument;
	private usedCopyCountsQuery: UsedCopyCountsDocument;
	private existCopyCountsQuery: ExistCopyCountsDocument;

	constructor(private injector: Injector) {
		this.getCopyCountsQuery = this.injector.get(GetCopyCountsDocument);
		this.firstCopyCountsQuery = this.injector.get(FirstCopyCountsDocument);
		this.countCopyCountsQuery = this.injector.get(CountCopyCountsDocument);
		this.findCopyCountsQuery = this.injector.get(FindCopyCountsDocument);
		this.searchCopyCountsQuery = this.injector.get(SearchCopyCountsDocument);
		this.exportSchemaCopyCountsQuery = this.injector.get(ExportSchemaCopyCountsDocument);
		this.exportDataCopyCountsQuery = this.injector.get(ExportDataCopyCountsDocument);
		this.customQueryCopyCountsQuery = this.injector.get(CustomQueryCopyCountsDocument);
		this.customQueryIdCopyCountsQuery = this.injector.get(CustomQueryIdCopyCountsDocument);
		this.usedCopyCountsQuery = this.injector.get(UsedCopyCountsDocument);
		this.existCopyCountsQuery = this.injector.get(ExistCopyCountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCopyCount> {
    
    		let variables: GetCopyCountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.get));
            }
            else{
                let result:ServiceSingleResultOfCopyCount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCopyCount,
		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCopyCount> {
    
    		let variables: FirstCopyCountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCopyCountsBaseVariables = {
    			filter: filter
    		}
    				return this.countCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCopyCount,
		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCopyCount> {
    
    		let variables: FindCopyCountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCopyCount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCopyCount> {
    
    		let variables: SearchCopyCountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCopyCountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCopyCountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCopyCount,
		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCopyCount> {
    
    		let variables: CustomQueryCopyCountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCopyCount,
		@Args('filter?') filter?: FilterOfCopyCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCopyCount> {
    
    		let variables: CustomQueryIdCopyCountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCopyCountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCopyCountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCopyCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.copyCounts.exist));
    	}
    
    
}