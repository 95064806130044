import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOneSettingsBaseVariables, FirstOneSettingsBaseVariables, CountOneSettingsBaseVariables, FindOneSettingsBaseVariables, SearchOneSettingsBaseVariables, ExportSchemaOneSettingsBaseVariables, ExportDataOneSettingsBaseVariables, CustomQueryOneSettingsBaseVariables, CustomQueryIdOneSettingsBaseVariables, UsedOneSettingsBaseVariables, GrantedAccessOneSettingsBaseVariables, ExistOneSettingsBaseVariables, InsertOneSettingsBaseVariables, UpdateOneSettingsBaseVariables, ImportDataOneSettingsBaseVariables, UpdateManyOneSettingsBaseVariables, DeleteOneSettingsBaseVariables } from '../gqls'
import { GetOneSettingsDocument, FirstOneSettingsDocument, CountOneSettingsDocument, FindOneSettingsDocument, SearchOneSettingsDocument, ExportSchemaOneSettingsDocument, ExportDataOneSettingsDocument, CustomQueryOneSettingsDocument, CustomQueryIdOneSettingsDocument, UsedOneSettingsDocument, GrantedAccessOneSettingsDocument, ExistOneSettingsDocument, InsertOneSettingsDocument, UpdateOneSettingsDocument, ImportDataOneSettingsDocument, UpdateManyOneSettingsDocument, DeleteOneSettingsDocument } from '../gqls'
import { ServiceSingleResultOfOneSetting, QueryContextOfOneSetting, FilterOfOneSetting, ServiceSingleResultOfInt64, ServiceListResultOfOneSetting, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, OneSettingInput, FieldUpdateOperatorOfOneSetting } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OneSettingBaseService {
    
public modelName = 'oneSetting';
public modelPluralName = 'oneSettings';

	private getOneSettingsQuery: GetOneSettingsDocument;
	private firstOneSettingsQuery: FirstOneSettingsDocument;
	private countOneSettingsQuery: CountOneSettingsDocument;
	private findOneSettingsQuery: FindOneSettingsDocument;
	private searchOneSettingsQuery: SearchOneSettingsDocument;
	private exportSchemaOneSettingsQuery: ExportSchemaOneSettingsDocument;
	private exportDataOneSettingsQuery: ExportDataOneSettingsDocument;
	private customQueryOneSettingsQuery: CustomQueryOneSettingsDocument;
	private customQueryIdOneSettingsQuery: CustomQueryIdOneSettingsDocument;
	private usedOneSettingsQuery: UsedOneSettingsDocument;
	private grantedAccessOneSettingsQuery: GrantedAccessOneSettingsDocument;
	private existOneSettingsQuery: ExistOneSettingsDocument;
	private insertOneSettingsMutation: InsertOneSettingsDocument;
	private updateOneSettingsMutation: UpdateOneSettingsDocument;
	private importDataOneSettingsMutation: ImportDataOneSettingsDocument;
	private updateManyOneSettingsMutation: UpdateManyOneSettingsDocument;
	private deleteOneSettingsMutation: DeleteOneSettingsDocument;

	constructor(private injector: Injector) {
		this.getOneSettingsQuery = this.injector.get(GetOneSettingsDocument);
		this.firstOneSettingsQuery = this.injector.get(FirstOneSettingsDocument);
		this.countOneSettingsQuery = this.injector.get(CountOneSettingsDocument);
		this.findOneSettingsQuery = this.injector.get(FindOneSettingsDocument);
		this.searchOneSettingsQuery = this.injector.get(SearchOneSettingsDocument);
		this.exportSchemaOneSettingsQuery = this.injector.get(ExportSchemaOneSettingsDocument);
		this.exportDataOneSettingsQuery = this.injector.get(ExportDataOneSettingsDocument);
		this.customQueryOneSettingsQuery = this.injector.get(CustomQueryOneSettingsDocument);
		this.customQueryIdOneSettingsQuery = this.injector.get(CustomQueryIdOneSettingsDocument);
		this.usedOneSettingsQuery = this.injector.get(UsedOneSettingsDocument);
		this.grantedAccessOneSettingsQuery = this.injector.get(GrantedAccessOneSettingsDocument);
		this.existOneSettingsQuery = this.injector.get(ExistOneSettingsDocument);
		this.insertOneSettingsMutation = this.injector.get(InsertOneSettingsDocument);
		this.updateOneSettingsMutation = this.injector.get(UpdateOneSettingsDocument);
		this.importDataOneSettingsMutation = this.injector.get(ImportDataOneSettingsDocument);
		this.updateManyOneSettingsMutation = this.injector.get(UpdateManyOneSettingsDocument);
		this.deleteOneSettingsMutation = this.injector.get(DeleteOneSettingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneSetting> {
    
    		let variables: GetOneSettingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.get));
            }
            else{
                let result:ServiceSingleResultOfOneSetting={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOneSetting,
		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneSetting> {
    
    		let variables: FirstOneSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOneSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOneSetting,
		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneSetting> {
    
    		let variables: FindOneSettingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOneSetting,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneSetting> {
    
    		let variables: SearchOneSettingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOneSettingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOneSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOneSetting,
		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneSetting> {
    
    		let variables: CustomQueryOneSettingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOneSetting,
		@Args('filter?') filter?: FilterOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneSetting> {
    
    		let variables: CustomQueryIdOneSettingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOneSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.used));
    	}

    	/**  */
    	@InjectArgs
    	public grantedAccess(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('email?') email?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: GrantedAccessOneSettingsBaseVariables = {
    			email: email
    		}
    				return this.grantedAccessOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.grantedAccess));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOneSettingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOneSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OneSettingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneSetting> {
    
    		let variables: InsertOneSettingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertOneSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneSetting> {
    
    		let variables: UpdateOneSettingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOneSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOneSettingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOneSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOneSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOneSettingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOneSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOneSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOneSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneSettings.delete));
    	}
    
}