<dx-box  direction="row">
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">Début</span>
    </dxi-item>
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">Fin</span>
    </dxi-item>    
    <dxi-item  [ratio]="2">
        <span class="cl-text-center">Description</span>
    </dxi-item>    
    <dxi-item  [ratio]="0" [baseSize]="30"> 
            <dx-button       
            icon="fal fa-plus"
            [hint]="'entities/dayOff/addDate' | translate"
            [width]="15"    
            (onClick)="addLine()">
        </dx-button>
    </dxi-item>
</dx-box>

<ng-container *ngFor="let day of days">
    <div *ngIf="day != undefined">
        <dx-box  direction="row" class="cl-box-main">     
            <dxi-item  [ratio]="2">
                <dx-date-box  type="date" id={{day.id}}_start [showClearButton]="true" [acceptCustomValue]="true" [useMaskBehavior]="true" 
                (onInitialized)="initializeDateBox($event)"
                (onValueChanged)="valuesChanged($event)" [value]="day.start">    
                    <dx-validator>
                        <dxi-validation-rule                         
                            type="custom"
                            [validationCallback]="dateCompare()"       
                            [message]="'entities/dayOff/dateSuperior' | translate">
                        </dxi-validation-rule>                           
                    </dx-validator>                   
                </dx-date-box>
            </dxi-item>
            <dxi-item  [ratio]="0" [baseSize]="5"> 
                <span class="cl-text-center"></span>
            </dxi-item>
            <dxi-item  [ratio]="2">
                <dx-date-box  type="date" id={{day.id}}_end [showClearButton]="true" [acceptCustomValue]="true" [useMaskBehavior]="true"
                 (onInitialized)="initializeDateBox($event)"
                 (onValueChanged)="valuesChanged($event)" [value]="day.end">
                    <dx-validator>
                        <dxi-validation-rule                         
                            type="custom"
                            [validationCallback]="dateCompare()"       
                            [message]="'entities/dayOff/dateSuperior' | translate">
                        </dxi-validation-rule>   
                    </dx-validator>                  
                </dx-date-box>
            </dxi-item>     
            <dxi-item  [ratio]="0" [baseSize]="5"> 
                <span class="cl-text-center"></span>
            </dxi-item>
            <dxi-item  [ratio]="2">
                <dx-text-box #description id={{day.id}}_description (onInitialized)="initializeTextBox($event)"
                (onValueChanged)="valuesChanged($event)" [value]="day.description">
                     <!-- <dx-validator>
                        <dxi-validation-rule
                            type="required"
                            [message]="'errors/notNullOrEmpty' | translate"
                        ></dxi-validation-rule>
                    </dx-validator> -->
                </dx-text-box>
            </dxi-item>    
            <dxi-item  [ratio]="0" [baseSize]="30"> 
                <dx-button
                id={{day.id}}_remove
                icon="fal fa-minus"
                [hint]="'entities/dayOff/removeDate' | translate"
                [width]="15"    
                (onClick)="removeLine($event)"       
                >
            </dx-button>
        </dxi-item>
        </dx-box> 
    </div>      
</ng-container>