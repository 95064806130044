export {};
declare module './schema' {
  /** @inheritdoc */
  interface CommandItems {
    /** Représente le clique sur un item. */
    onItemClick?: Function;
    /** Obtient ou définit si le split bouton est activé. */
    splitButton?: boolean;
  }
  /** @inheritdoc */
  interface Column {
    /** Obtient ou définit le type. */
    type?: string;
    /** Obtient ou définit la largeur. */
    width?: string | number;
    /** Obtient ou définit une source de données. */
    source?: any;
    /** Obtient ou définit le champ de la clé étrangère. */
    targetIdExpr?: string;
    /** Obtient ou définit le nom champ du parent id. */
    parentIdExpr?: string;
    /** Obtient ou définit le nom du champ id. */
    keyExpr?: string;
    /** Obtient ou définit le nom du champ à afficher. */
    displayExpr?: string;
  }
  /** @inheritdoc */
  interface SectionGroup {
    /** Obtient ou définit le nom unique. */
    name: string;
  }
  /** @inheritdoc */
  interface PageSection {
    /** Obtient ou définit le nom unique. */
    name: string;
  }
  /** @inheritdoc */
  interface PageControl {
    /** Obtient ou définit le nom unique. */
    name: string;
  }
  /** @inheritdoc */
  interface PageGroup {
    /** Obtient ou définit le nom unique. */
    name: string;
  }
  /** @inheritdoc */
  interface PageTab {
    /** Obtient ou définit le nom unique. */
    name: string;
  }

  /** @inheritdoc */
  interface Control {
    /** Obtient definit le type ...on <> {} pour le graphql */
    onType?: string;
  }
}

export abstract class AtlasIndex {
  id: number;
  value: string;
  field: string;
  type: string;
  isTranslateField: boolean;
}
