import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Args, InjectArgs, Maybe, ServiceListResult } from '@clarilog/core';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import {
  ArticleKnowledgeCoreService,
  AssetModelCoreService,
  ContactCoreService,
  CorrectionStockMovementCoreService,
  InStockMovementCoreService,
  LocationCoreService,
  OutStockMovementCoreService,
  ProblemCoreService,
  RequestCoreService,
  SoftwareGroupCoreService,
  SoftwarePackageCoreService,
  TicketCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { AssetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/asset.service';
import { BudgetCoreService } from '@clarilog/core/services2/graphql/generated-types/services/budget.service';
import { ContractCoreService } from '@clarilog/core/services2/graphql/generated-types/services/contract.service';
import { IncidentCoreService } from '@clarilog/core/services2/graphql/generated-types/services/incident.service';
import { LoanCoreService } from '@clarilog/core/services2/graphql/generated-types/services/loan.service';
import { SoftwareCoreService } from '@clarilog/core/services2/graphql/generated-types/services/software.service';
import { SupplierCoreService } from '@clarilog/core/services2/graphql/generated-types/services/supplier.service';
import { UserCoreService } from '@clarilog/core/services2/graphql/generated-types/services/user.service';
import {
  QueryContextOfAccount,
  Sort,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslateService } from '@clarilog/shared';
import { forkJoin, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

/** Object pour la liste des items à rechercher */
export class resultSearch {
  key: string;
  items: any[];
  count: number;
  id: string;
  icon: string;
}

export class itemSearch {
  // Definit l'id d'un item du menu (utilisé pour les items par défaut)
  itemKey: string;
  // Définit la traduction du groupe
  translate: string;
  // Définit le service de recherche
  service: any;
  // Définit une clé spécifique de recherche
  specificKey?: string;
  // Définit l'url de l'objet
  url: string;
  // Définit l'url du groupe
  urlGroup?: string;
  // Definit si la recherche se fait uniquement depuis le menu global (choix)
  useInDefaultMenu?: boolean = false;
  // Définit si il s'agit de requete help me
  hasHelpMe?: boolean = false;
  // Définit si le mode d'édition est choisi pour ouvrir l'élément
  useEditMode?: boolean = true;
  // Définit le trie par défaut
  sort?: string;
}

/**
 * Service relatif aux recherche global
 *
 */
@Injectable({
  providedIn: 'root',
})
export class CoreGlobalSearchService {
  constructor(
    private assetService: AssetCoreService,
    private softwareService: SoftwareCoreService,
    private userService: UserCoreService,
    private contractService: ContractCoreService,
    private loanService: LoanCoreService,
    private supplierService: SupplierCoreService,
    private budgetService: BudgetCoreService,
    private incidentService: IncidentCoreService,
    private articleKnowledgeService: ArticleKnowledgeCoreService,
    private requestService: RequestCoreService,
    private problemService: ProblemCoreService,
    private ticketService: TicketCoreService,
    private softwareGroupService: SoftwareGroupCoreService,
    private softwarePackageService: SoftwarePackageCoreService,
    private assetModelService: AssetModelCoreService,
    private inStockMovementService: InStockMovementCoreService,
    private outStockMovementService: OutStockMovementCoreService,
    private correctionStockMovementService: CorrectionStockMovementCoreService,
    private locationService: LocationCoreService,
    private contactService: ContactCoreService,
    private router: Router,
    private authorizationService: AuthorizationCoreService,
  ) {}

  /** Contruit le tableau de recherche */
  findItemSearch() {
    let methods: itemSearch[] = this.assets();

    // Opérateur
    methods.push.apply(methods, this.contracts());
    methods.push.apply(methods, this.users());
    methods.push.apply(methods, this.softwares());
    methods.push.apply(methods, this.financials());
    methods.push.apply(methods, this.stocks());
    methods.push.apply(methods, this.loans());
    methods.push.apply(methods, this.suppliers());
    methods.push.apply(methods, this.incidents());
    methods.push.apply(methods, this.requests());
    methods.push.apply(methods, this.problems());
    methods.push.apply(methods, this.tickets());
    methods.push.apply(methods, this.favoriteIncidents());
    methods.push.apply(methods, this.articleKnowledge());

    // Help me
    methods.push.apply(methods, this.helpMeIncidents());
    methods.push.apply(methods, this.helpMeRequests());
    methods.push.apply(methods, this.helpMeArticleKnowledge());

    methods.map((f) => {
      if (f.hasHelpMe == undefined) {
        f.hasHelpMe = false;
      }
      if (f.useInDefaultMenu == undefined) {
        f.useInDefaultMenu = false;
      }
      if (f.urlGroup == undefined) {
        f.urlGroup = f.url;
      }
    });
    return methods;
  }

  /** Ajout des recherche sur le metier utilisateurs */
  assets(): itemSearch[] {
    return [
      {
        itemKey: 'assets',
        translate: TranslateService.get('entities/asset/_title/plural'),
        service: this.assetService,
        url: 'assets',
        useInDefaultMenu: true,
      },
    ];
  }

  /** Ajout des recherche sur le metier finances */
  financials(): itemSearch[] {
    return [
      {
        itemKey: 'financials',
        translate: TranslateService.get('entities/budget/_title/plural'),
        service: this.budgetService,
        url: 'financials/budgets',
      },
    ];
  }

  /** Ajout des recherche sur le metier Prestataire */
  suppliers(): itemSearch[] {
    return [
      {
        itemKey: 'suppliers',
        translate: TranslateService.get('entities/supplier/_title/plural'),
        service: this.supplierService,
        url: 'suppliers',
        useInDefaultMenu: true,
      },
      {
        itemKey: 'suppliers',
        translate: TranslateService.get('entities/contact/_title/plural'),
        service: this.contactService,
        url: 'suppliers/contacts',
      },
    ];
  }

  /** Ajout des recherche sur le metier Incident */
  incidents(): itemSearch[] {
    return [
      {
        itemKey: 'incidents',
        translate: TranslateService.get('entities/incident/_title/plural'),
        service: this.incidentService,
        url: 'incidents/all',
        useInDefaultMenu: true,
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier Ticket */
  tickets(): itemSearch[] {
    return [
      {
        itemKey: 'tickets',
        translate: TranslateService.get('entities/ticket/_title/plural'),
        service: this.ticketService,
        url: 'tickets/all',
        useInDefaultMenu: false,
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier Incident */
  favoriteIncidents(): itemSearch[] {
    return [
      {
        itemKey: 'favorite-tickets',
        translate: TranslateService.get('entities/incident/favorite'),
        service: this.ticketService,
        url: 'incidents/all',
        specificKey: 'favorite',
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier Demande */
  requests(): itemSearch[] {
    return [
      {
        itemKey: 'requests',
        translate: TranslateService.get('entities/request/_title/plural'),
        service: this.requestService,
        url: 'requests/all',
        useInDefaultMenu: true,
        sort: 'title',
      },
    ];
  }
  /** Ajout des recherche sur le metier Probleme */
  problems(): itemSearch[] {
    return [
      {
        itemKey: 'problems',
        translate: TranslateService.get('entities/problem/_title/plural'),
        service: this.problemService,
        url: 'problems/all',
        useInDefaultMenu: true,
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier KB */
  articleKnowledge(): itemSearch[] {
    return [
      {
        itemKey: 'article-knowledge',
        translate: TranslateService.get(
          'entities/articleKnowledge/_title/plural',
        ),
        service: this.articleKnowledgeService,
        url: 'article-knowledge/management',
      },
    ];
  }

  /** Ajout des recherche sur le metier Demande */
  helpMeRequests(): itemSearch[] {
    return [
      {
        itemKey: 'help-desk-request',
        translate: TranslateService.get('entities/request/_title/plural'),
        service: this.requestService,
        url: 'help-desk-request/my',
        useInDefaultMenu: true,
        hasHelpMe: true,
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier Incident */
  helpMeIncidents(): itemSearch[] {
    return [
      {
        itemKey: 'help-desk-incident',
        translate: TranslateService.get('entities/incident/_title/plural'),
        service: this.incidentService,
        url: 'help-desk-incident/my',
        useInDefaultMenu: true,
        hasHelpMe: true,
        sort: 'title',
      },
    ];
  }

  /** Ajout des recherche sur le metier KB */
  helpMeArticleKnowledge(): itemSearch[] {
    return [
      {
        itemKey: 'help-desk-knowledge',
        translate: TranslateService.get(
          'entities/articleKnowledge/_title/plural',
        ),
        service: this.articleKnowledgeService,
        url: 'help-desk-knowledge',
        useInDefaultMenu: true,
        hasHelpMe: true,
        useEditMode: false,
      },
    ];
  }

  /** Ajout des recherche sur le metier contrats */
  contracts(): itemSearch[] {
    return [
      {
        itemKey: 'contracts',
        translate: TranslateService.get('entities/contract/_title/plural'),
        service: this.contractService,
        url: 'contracts/list-contract',
        useInDefaultMenu: true,
      },
    ];
  }

  /** Ajout des recherche sur le metier utilisateurs */
  users(): itemSearch[] {
    return [
      {
        itemKey: 'users',
        translate: TranslateService.get('entities/user/_title/plural'),
        service: this.userService,
        url: 'users',
        useInDefaultMenu: true,
      },
    ];
  }

  /** Ajout des recherche sur le metier Prêt */
  loans(): itemSearch[] {
    return [
      {
        itemKey: 'loans',
        translate: TranslateService.get('entities/loan/_title/plural'),
        service: this.loanService,
        url: 'loans',
        useInDefaultMenu: true,
      },
      {
        itemKey: 'loans',
        translate: TranslateService.get('entities/assetLoan/_title/plural'),
        service: this.assetService,
        url: 'assets',
        urlGroup: 'loans/asset',
        specificKey: 'loan',
      },
      {
        itemKey: 'loans',
        translate: TranslateService.get('entities/locationLoan/_title/plural'),
        service: this.locationService,
        url: 'administration/architecture/locations',
        urlGroup: 'loans/location',
        specificKey: 'loan',
      },
    ];
  }

  /** Ajout des recherche sur le metier Stock */
  stocks(): itemSearch[] {
    return [
      {
        itemKey: 'stocks',
        translate: TranslateService.get('entities/assetModel/_title/plural'),
        service: this.assetModelService,
        url: 'stocks/asset-models',
      },
      {
        itemKey: 'stocks',
        translate: TranslateService.get('entities/stock/assetModelStock'),
        service: this.assetModelService,
        urlGroup: 'stocks/asset-model',
        url: 'stocks/asset-models',
        specificKey: 'stock',
      },
      {
        itemKey: 'stocks',
        translate: TranslateService.get('entities/stock/assetStock'),
        service: this.assetService,
        url: 'assets',
        specificKey: 'stock',
      },
      {
        itemKey: 'stocks',
        translate: TranslateService.get('entities/stock/inStock/_title/plural'),
        service: this.inStockMovementService,
        url: 'stocks/in-stocks',
      },
      {
        itemKey: 'stocks',
        translate: TranslateService.get(
          'entities/stock/outStock/_title/plural',
        ),
        service: this.outStockMovementService,
        url: 'stocks/out-stocks',
      },
      {
        itemKey: 'stocks',
        translate: TranslateService.get(
          'entities/stock/correctionStock/_title/plural',
        ),
        service: this.correctionStockMovementService,
        url: 'stocks/correction-stocks',
      },
    ];
  }

  /** Ajout des recherche sur le metier programmes */
  softwares(): itemSearch[] {
    return [
      {
        itemKey: 'softwares',
        url: 'softwares/all',
        translate: TranslateService.get('entities/software/_title/plural'),
        service: this.softwareService,
      },
      {
        itemKey: 'softwares',
        url: 'softwares/softwareGroups',
        translate: TranslateService.get('entities/softwareGroup/_title/plural'),
        service: this.softwareGroupService,
      },
      {
        itemKey: 'softwares',
        url: 'softwares/softwarePackages',
        translate: TranslateService.get(
          'entities/softwarePackage/_title/plural',
        ),
        service: this.softwarePackageService,
      },
    ];
  }

  /** Effectue une recherche globale */
  @InjectArgs
  search(
    @Args('searchIn') searchIn: string[],
    @Args('value') value: string,
  ): Observable<resultSearch[]> {
    let methods = this.findItemSearch();

    // Recherche uniquement dans le menu ouvert
    let observables$: Observable<resultSearch>[] = [];

    // Ajoute les recherche en fonciton de l'url
    let url = '/' + window.location.pathname + '/';
    var itemByUrl = methods.filter(
      (s) => url.indexOf('/' + s.itemKey + '/') >= 0,
    );

    if (itemByUrl.length == 0) {
      // NE prend en compte que les item contenu et sélectionné dans le menu
      itemByUrl = methods.filter(
        (s) =>
          s.useInDefaultMenu &&
          s.itemKey != undefined &&
          searchIn.indexOf(s.itemKey) >= 0,
      );
    }

    // Prend en charge que les requête pouvant être exécuter
    let hasHelpMe = localStorage.getItem('viewHelpMe') == 'true' ? true : false;
    if (
      !hasHelpMe &&
      (this.authorizationService.user.hasHelpDeskOperator() ||
        this.authorizationService.user.hasManager())
    ) {
    } else {
      if (!hasHelpMe && this.authorizationService.user.hasHelpDeskUser()) {
        hasHelpMe = true;
      }
    }

    itemByUrl = itemByUrl.filter((f) => f.hasHelpMe == hasHelpMe);

    // Force la recherche par rapport à l'url pour un métier
    itemByUrl.forEach((f) => observables$.push(this.searchData(f, value)));

    let observablesResult = observables$.filter((f) => f != undefined);
    if (observablesResult.length > 0) {
      return forkJoin(observablesResult).pipe(
        map((result) => {
          let searchResult: resultSearch[] = [];
          for (let data of result.filter((f) => f != undefined)) {
            searchResult.push(data);
          }
          return searchResult;
        }),
      );
    }

    return of([]);
  }

  /** Affiche ou masque le menu de choix de recherche en fonction de l'url */
  showChooseMenu() {
    let methods = this.findItemSearch();
    return (
      methods.filter(
        (s) =>
          this.router.url.indexOf(s.itemKey) >= 0 ||
          this.router.url.indexOf(s.url) >= 0,
      ).length == 0
    );
  }

  /** Recherche */
  @InjectArgs
  private searchData(
    @Args('itemSearch') itemSearch: itemSearch,
    @Args('value') value: string,
  ): Observable<resultSearch> {
    //Trie par défaut
    let defaultSort = {};
    let sortOrder: QueryContextOfAccount;

    if (itemSearch?.sort != undefined) {
      defaultSort[itemSearch.sort] = Sort.Asc;
      sortOrder = {
        sort: [defaultSort],
      };
    }

    // Execution
    if (itemSearch.service['search'] != undefined) {
      return itemSearch.service['search'](
        itemSearch.service['searchFields'](),
        itemSearch.hasHelpMe,
        value,
        sortOrder,
        itemSearch.specificKey,
      ).pipe(
        map((result) => {
          if (result != undefined) {
            const data = result as ServiceListResult<any>;
            if (data.data != undefined && data.data.length > 0) {
              let key = `${itemSearch.translate}`;
              if (data.data != undefined && data.data.length >= 5) {
                key = `${key} (${TranslateService.get('common/all')})`;
              }
              return {
                key: key,
                items: data.data,
                count: data.totalCount,
                url: itemSearch.url,
                urlGroup: itemSearch.urlGroup,
                useEditMode: itemSearch.useEditMode,
              };
            }
          }
          return undefined;
        }),
      );
    }

    return undefined;
  }
}
