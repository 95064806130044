import { Component, EventEmitter, HostBinding, Input, OnChanges, Output, SimpleChanges } from '@angular/core';

/** Représente les groupes dans les sections. */
@Component({
  selector: 'clc-work-item-section-group',
  templateUrl: './work-item-section-group.component.html',
  styleUrls: ['./work-item-section-group.component.scss']
})
export class CoreWorkItemSectionGroupComponent implements OnChanges {
  /** Obtient ou définit le titre du groupe. */
  @Input() title: string = "";
  /** Obtient ou définit la visibilité du groupe. */
  @Input() visibled: boolean = true;
  /** Obtient ou définit si le groupe est fermé. */
  @Input() expanded: boolean = true;
  /** Host le style display. */
  @Output() onVisibledChanged: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Permet de binder le style display. */
  @HostBinding('style.display')
  get display(): string {
    return this.visibled ? 'block' : 'none';
  }
  constructor() { }
  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.visibled !== undefined) {
      this.onVisibledChanged.emit(this.visibled);
    }
  }
}
