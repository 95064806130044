import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetIamtCredentialsBaseVariables, FirstIamtCredentialsBaseVariables, CountIamtCredentialsBaseVariables, FindIamtCredentialsBaseVariables, SearchIamtCredentialsBaseVariables, ExportSchemaIamtCredentialsBaseVariables, ExportDataIamtCredentialsBaseVariables, CustomQueryIamtCredentialsBaseVariables, CustomQueryIdIamtCredentialsBaseVariables, UsedIamtCredentialsBaseVariables, ExistIamtCredentialsBaseVariables, FindRecyclesIamtCredentialsBaseVariables, CountRecyclesIamtCredentialsBaseVariables, ReadOnlyIamtCredentialsBaseVariables, FindArchivedIamtCredentialsBaseVariables, CountAllIamtCredentialsBaseVariables, FindDynamicPropertyFieldsIamtCredentialsBaseVariables, FindUnassociatedScanConfigurationsIamtCredentialsBaseVariables, FindUnassociatedInventoryHistoriesIamtCredentialsBaseVariables, FindUnassociatedScanByLdapsIamtCredentialsBaseVariables, InsertIamtCredentialsBaseVariables, UpdateIamtCredentialsBaseVariables, ImportDataIamtCredentialsBaseVariables, UpdateManyIamtCredentialsBaseVariables, DeleteIamtCredentialsBaseVariables, RestoreIamtCredentialsBaseVariables, RecycleIamtCredentialsBaseVariables, RestoreArchivedIamtCredentialsBaseVariables, ArchivedIamtCredentialsBaseVariables, AddFileDynamicFieldIamtCredentialsBaseVariables, RemoveFileDynamicFieldIamtCredentialsBaseVariables } from '../gqls'
import { GetIamtCredentialsDocument, FirstIamtCredentialsDocument, CountIamtCredentialsDocument, FindIamtCredentialsDocument, SearchIamtCredentialsDocument, ExportSchemaIamtCredentialsDocument, ExportDataIamtCredentialsDocument, CustomQueryIamtCredentialsDocument, CustomQueryIdIamtCredentialsDocument, UsedIamtCredentialsDocument, ExistIamtCredentialsDocument, FindRecyclesIamtCredentialsDocument, CountRecyclesIamtCredentialsDocument, ReadOnlyIamtCredentialsDocument, FindArchivedIamtCredentialsDocument, CountAllIamtCredentialsDocument, FindDynamicPropertyFieldsIamtCredentialsDocument, FindUnassociatedScanConfigurationsIamtCredentialsDocument, FindUnassociatedInventoryHistoriesIamtCredentialsDocument, FindUnassociatedScanByLdapsIamtCredentialsDocument, InsertIamtCredentialsDocument, UpdateIamtCredentialsDocument, ImportDataIamtCredentialsDocument, UpdateManyIamtCredentialsDocument, DeleteIamtCredentialsDocument, RestoreIamtCredentialsDocument, RecycleIamtCredentialsDocument, RestoreArchivedIamtCredentialsDocument, ArchivedIamtCredentialsDocument, AddFileDynamicFieldIamtCredentialsDocument, RemoveFileDynamicFieldIamtCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfIamtCredential, QueryContextOfIamtCredential, FilterOfIamtCredential, ServiceSingleResultOfInt64, ServiceListResultOfIamtCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfIamtCredential, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ServiceListResultOfScanConfiguration, QueryContextOfInventoryHistory, FilterOfInventoryHistory, ServiceListResultOfInventoryHistory, QueryContextOfScanByLdap, FilterOfScanByLdap, ServiceListResultOfScanByLdap, IamtCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class IamtCredentialBaseService {
    
public modelName = 'iamtCredential';
public modelPluralName = 'iamtCredentials';

	private getIamtCredentialsQuery: GetIamtCredentialsDocument;
	private firstIamtCredentialsQuery: FirstIamtCredentialsDocument;
	private countIamtCredentialsQuery: CountIamtCredentialsDocument;
	private findIamtCredentialsQuery: FindIamtCredentialsDocument;
	private searchIamtCredentialsQuery: SearchIamtCredentialsDocument;
	private exportSchemaIamtCredentialsQuery: ExportSchemaIamtCredentialsDocument;
	private exportDataIamtCredentialsQuery: ExportDataIamtCredentialsDocument;
	private customQueryIamtCredentialsQuery: CustomQueryIamtCredentialsDocument;
	private customQueryIdIamtCredentialsQuery: CustomQueryIdIamtCredentialsDocument;
	private usedIamtCredentialsQuery: UsedIamtCredentialsDocument;
	private existIamtCredentialsQuery: ExistIamtCredentialsDocument;
	private findRecyclesIamtCredentialsQuery: FindRecyclesIamtCredentialsDocument;
	private countRecyclesIamtCredentialsQuery: CountRecyclesIamtCredentialsDocument;
	private readOnlyIamtCredentialsQuery: ReadOnlyIamtCredentialsDocument;
	private findArchivedIamtCredentialsQuery: FindArchivedIamtCredentialsDocument;
	private countAllIamtCredentialsQuery: CountAllIamtCredentialsDocument;
	private findDynamicPropertyFieldsIamtCredentialsQuery: FindDynamicPropertyFieldsIamtCredentialsDocument;
	private findUnassociatedScanConfigurationsIamtCredentialsQuery: FindUnassociatedScanConfigurationsIamtCredentialsDocument;
	private findUnassociatedInventoryHistoriesIamtCredentialsQuery: FindUnassociatedInventoryHistoriesIamtCredentialsDocument;
	private findUnassociatedScanByLdapsIamtCredentialsQuery: FindUnassociatedScanByLdapsIamtCredentialsDocument;
	private insertIamtCredentialsMutation: InsertIamtCredentialsDocument;
	private updateIamtCredentialsMutation: UpdateIamtCredentialsDocument;
	private importDataIamtCredentialsMutation: ImportDataIamtCredentialsDocument;
	private updateManyIamtCredentialsMutation: UpdateManyIamtCredentialsDocument;
	private deleteIamtCredentialsMutation: DeleteIamtCredentialsDocument;
	private restoreIamtCredentialsMutation: RestoreIamtCredentialsDocument;
	private recycleIamtCredentialsMutation: RecycleIamtCredentialsDocument;
	private restoreArchivedIamtCredentialsMutation: RestoreArchivedIamtCredentialsDocument;
	private archivedIamtCredentialsMutation: ArchivedIamtCredentialsDocument;
	private addFileDynamicFieldIamtCredentialsMutation: AddFileDynamicFieldIamtCredentialsDocument;
	private removeFileDynamicFieldIamtCredentialsMutation: RemoveFileDynamicFieldIamtCredentialsDocument;

	constructor(private injector: Injector) {
		this.getIamtCredentialsQuery = this.injector.get(GetIamtCredentialsDocument);
		this.firstIamtCredentialsQuery = this.injector.get(FirstIamtCredentialsDocument);
		this.countIamtCredentialsQuery = this.injector.get(CountIamtCredentialsDocument);
		this.findIamtCredentialsQuery = this.injector.get(FindIamtCredentialsDocument);
		this.searchIamtCredentialsQuery = this.injector.get(SearchIamtCredentialsDocument);
		this.exportSchemaIamtCredentialsQuery = this.injector.get(ExportSchemaIamtCredentialsDocument);
		this.exportDataIamtCredentialsQuery = this.injector.get(ExportDataIamtCredentialsDocument);
		this.customQueryIamtCredentialsQuery = this.injector.get(CustomQueryIamtCredentialsDocument);
		this.customQueryIdIamtCredentialsQuery = this.injector.get(CustomQueryIdIamtCredentialsDocument);
		this.usedIamtCredentialsQuery = this.injector.get(UsedIamtCredentialsDocument);
		this.existIamtCredentialsQuery = this.injector.get(ExistIamtCredentialsDocument);
		this.findRecyclesIamtCredentialsQuery = this.injector.get(FindRecyclesIamtCredentialsDocument);
		this.countRecyclesIamtCredentialsQuery = this.injector.get(CountRecyclesIamtCredentialsDocument);
		this.readOnlyIamtCredentialsQuery = this.injector.get(ReadOnlyIamtCredentialsDocument);
		this.findArchivedIamtCredentialsQuery = this.injector.get(FindArchivedIamtCredentialsDocument);
		this.countAllIamtCredentialsQuery = this.injector.get(CountAllIamtCredentialsDocument);
		this.findDynamicPropertyFieldsIamtCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsIamtCredentialsDocument);
		this.findUnassociatedScanConfigurationsIamtCredentialsQuery = this.injector.get(FindUnassociatedScanConfigurationsIamtCredentialsDocument);
		this.findUnassociatedInventoryHistoriesIamtCredentialsQuery = this.injector.get(FindUnassociatedInventoryHistoriesIamtCredentialsDocument);
		this.findUnassociatedScanByLdapsIamtCredentialsQuery = this.injector.get(FindUnassociatedScanByLdapsIamtCredentialsDocument);
		this.insertIamtCredentialsMutation = this.injector.get(InsertIamtCredentialsDocument);
		this.updateIamtCredentialsMutation = this.injector.get(UpdateIamtCredentialsDocument);
		this.importDataIamtCredentialsMutation = this.injector.get(ImportDataIamtCredentialsDocument);
		this.updateManyIamtCredentialsMutation = this.injector.get(UpdateManyIamtCredentialsDocument);
		this.deleteIamtCredentialsMutation = this.injector.get(DeleteIamtCredentialsDocument);
		this.restoreIamtCredentialsMutation = this.injector.get(RestoreIamtCredentialsDocument);
		this.recycleIamtCredentialsMutation = this.injector.get(RecycleIamtCredentialsDocument);
		this.restoreArchivedIamtCredentialsMutation = this.injector.get(RestoreArchivedIamtCredentialsDocument);
		this.archivedIamtCredentialsMutation = this.injector.get(ArchivedIamtCredentialsDocument);
		this.addFileDynamicFieldIamtCredentialsMutation = this.injector.get(AddFileDynamicFieldIamtCredentialsDocument);
		this.removeFileDynamicFieldIamtCredentialsMutation = this.injector.get(RemoveFileDynamicFieldIamtCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIamtCredential> {
    
    		let variables: GetIamtCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfIamtCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIamtCredential> {
    
    		let variables: FirstIamtCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountIamtCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: FindIamtCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: SearchIamtCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaIamtCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataIamtCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: CustomQueryIamtCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: CustomQueryIdIamtCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistIamtCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: FindRecyclesIamtCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesIamtCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyIamtCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIamtCredential,
		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIamtCredential> {
    
    		let variables: FindArchivedIamtCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllIamtCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsIamtCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedScanConfigurations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanConfiguration> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanConfigurations', fields, null, [
			GqlSubFieldArg.create('filterOfScanConfigurations', 'filter'),
			GqlSubFieldArg.create('optionsOfScanConfigurations', 'options'),
		]),
		])
		extendedVariables['filterOfScanConfigurations'] = filter;
		extendedVariables['optionsOfScanConfigurations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanConfigurations));
            }
            else{
                let result: ServiceListResultOfScanConfiguration = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindUnassociatedScanConfigurationsIamtCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanConfigurationsIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findUnassociatedScanConfigurations));
    	}

	@InjectArgs
	public findAssociatedInventoryHistories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfInventoryHistory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('inventoryHistories', fields, null, [
			GqlSubFieldArg.create('filterOfInventoryHistories', 'filter'),
			GqlSubFieldArg.create('optionsOfInventoryHistories', 'options'),
		]),
		])
		extendedVariables['filterOfInventoryHistories'] = filter;
		extendedVariables['optionsOfInventoryHistories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.inventoryHistories));
            }
            else{
                let result: ServiceListResultOfInventoryHistory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedInventoryHistories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInventoryHistory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfInventoryHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInventoryHistory> {
    
    		let variables: FindUnassociatedInventoryHistoriesIamtCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedInventoryHistoriesIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findUnassociatedInventoryHistories));
    	}

	@InjectArgs
	public findAssociatedScanByLdaps(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfScanByLdap> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('scanByLdaps', fields, null, [
			GqlSubFieldArg.create('filterOfScanByLdaps', 'filter'),
			GqlSubFieldArg.create('optionsOfScanByLdaps', 'options'),
		]),
		])
		extendedVariables['filterOfScanByLdaps'] = filter;
		extendedVariables['optionsOfScanByLdaps'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.scanByLdaps));
            }
            else{
                let result: ServiceListResultOfScanByLdap = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedScanByLdaps(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByLdap,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanByLdap,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByLdap> {
    
    		let variables: FindUnassociatedScanByLdapsIamtCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedScanByLdapsIamtCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.findUnassociatedScanByLdaps));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: IamtCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIamtCredential> {
    
    		let variables: InsertIamtCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIamtCredential> {
    
    		let variables: UpdateIamtCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataIamtCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfIamtCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyIamtCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedIamtCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldIamtCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldIamtCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldIamtCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.iamtCredentials.removeFileDynamicField));
    	}
    
}