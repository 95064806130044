<clc-work-items
  [title]="modelState?.model?.title || title"
  [modelState]="modelState"
  [resources]="resources"
  [parameters]="parameters"
  [selectedKeys]="selectedKeys"
  [(selectedData)]="selectedData"
  (onRowClick)="rowClick($event)"
  (onSingleCellClick)="singleCellClick($event)"
  (onSelectionChanged)="selectionChanged($event)"
  [layoutKey]="layoutKey != undefined ? layoutKey : service.modelPluralName"
  [errors]="errors"
  [showBack]="showBack"
  [backUrl]="backUrl"
  [usePreviousBackUrl]="usePreviousBackUrl"
  (onBeforeRefresh)="onBeforeRefresh($event)"
  (onNavListSelectionChanged)="navListSelectionChanged($event)"
  [canExport]="canExport"
  [stateScope]="stateScope"
  [type]="type"
  [activeSelectLevelAction]="activeSelectLevelAction"
  [delayAutoRefresh]="delayAutoRefresh"
  [displayAutoRefreshCheckbox]="displayAutoRefreshCheckbox"
  [enableForceAutoRefresh]="enableForceAutoRefresh"
  [canSelect]="canSelect"
>
  <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
    <cl-toolbar-item-button
      *ngIf="newCommand?.source == undefined"
      [visible]="canAdd === true"
      [text]="'new' | translate"
      icon="fas fa-plus"
      (onClick)="newClick($event)"
    ></cl-toolbar-item-button>
    <cl-toolbar-item-dropdown-button
      *ngIf="newCommand?.source != undefined"
      [visible]="canAdd === true"
      [text]="'new' | translate"
      icon="fas fa-plus"
      [splitButton]="newCommand.splitButton"
      [source]="newCommand.source['datasource']"
      [keyExpr]="newCommand.keyExpr"
      [displayExpr]="newCommand.displayExpr"
      (onButtonClick)="newCommand.splitButton && newClick($event)"
      (onItemClick)="newItemClick($event)"
    >
    </cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-button
      *ngIf="
        editCommand == undefined &&
        selectedKeys != undefined &&
        canEdit === true
      "
      [visible]="canEdit === true"
      [text]="'edit' | translate"
      icon="fas fa-pencil-alt"
      [disabled]="selectedKeys?.length !== 1"
      [routerLink]="editRouterLink"
      [queryParams]="route.snapshot.queryParams"
    ></cl-toolbar-item-button>

    <!--Dans le cas d'un besoin de routage spécifique -->
    <cl-toolbar-item-button
      *ngIf="
        editCommand != undefined &&
        editCommand?.source == undefined &&
        selectedKeys != undefined &&
        canEdit === true
      "
      [visible]="canEdit === true"
      [text]="'edit' | translate"
      icon="fas fa-pencil-alt"
      [disabled]="selectedKeys?.length !== 1"
      (onClick)="editItemClick($event)"
    ></cl-toolbar-item-button>

    <cl-toolbar-item-dropdown-button
      *ngIf="
        editCommand?.source != undefined &&
        selectedKeys != undefined &&
        canEdit === true
      "
      [visible]="canEdit === true"
      [text]="'edit' | translate"
      [icon]="iconEdit"
      [splitButton]="
        selectedKeys?.length !== 1 && editCommand.splitButton === true
          ? false
          : editCommand.splitButton
      "
      [source]="editCommand.source['datasource']"
      [keyExpr]="editCommand.keyExpr"
      [disabled]="selectedKeys?.length === 0 || disableBtnEdit"
      [displayExpr]="editCommand.displayExpr"
      (onButtonClick)="editCommand.splitButton && editClick($event)"
      (onItemClick)="editItemClick($event)"
    >
    </cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-dropdown-button
      [visible]="canEnable === true && canEdit === true"
      [text]="'globals/enabledDisabled' | translate"
      icon="fal fa-retweet"
      keyExpr="id"
      displayExpr="name"
      [source]="setEnableSource"
      (onItemClick)="setEnableEvent($event)"
      [disabled]="selectedKeys?.length === 0"
    ></cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-button
      [visible]="
        canDelete === true &&
        selectedKeys !== undefined &&
        hideDeleteButton === false
      "
      [text]="'delete' | translate"
      icon="fas fa-times"
      (onClick)="delete()"
      [disabled]="selectedKeys?.length === 0"
    >
    </cl-toolbar-item-button>
    <cl-toolbar-item-button
      [visible]="canDelete === true && countRecyclesFunction !== undefined"
      [text]="'trash' | translate : { count: countRecycles }"
      icon="fas fa-trash-alt"
      [routerLink]="['trash']"
      [queryParams]="route.snapshot.queryParams"
      [disabled]="countRecycles === 0"
    ></cl-toolbar-item-button>
    <cl-toolbar-item-dropdown-button
      *ngIf="canImport === true"
      [visible]="canImport === true && service.exportSchema !== undefined"
      [text]="'globals/import' | translate"
      icon="fas fa-paper-plane"
      keyExpr="id"
      displayExpr="name"
      [source]="importPopup.importSource"
      (onItemClick)="importPopup.import($event)"
    ></cl-toolbar-item-dropdown-button>
  </cl-toolbar-items>
</clc-work-items>
<clc-import-data-popup [service]="service" (onSuccess)="refresh()" #importPopup>
</clc-import-data-popup>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="deleteLoadIndicator"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
