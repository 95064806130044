import { ScanMethodProfilBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable, of } from 'rxjs';
import {
  FilterOfScanConfiguration,
  QueryContextOfScanConfiguration,
  ScanMethod,
  ServiceListResultOfScanConfiguration,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { ScanConfigurationCoreService } from './scan-configuration.service';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-scan-method-profil')
export class ScanMethodProfilCoreService extends ScanMethodProfilBaseService {
  constructor(
    injector: Injector,
    private scanConfigurationService: ScanConfigurationCoreService,
  ) {
    super(injector);
  }

  /** */
  @InjectArgs
  saveMethods(@Args('items') items: ScanMethod[]): Observable<string[]> {
    debugger;
    return of(items.filter((f) => f.enabled).map((m) => m.id));
  }

  @InjectArgs
  public findAssociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findAssociatedScanConfigurations(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }

  @InjectArgs
  public findUnassociatedScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findUnassociatedScanConfigurations(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }
}
