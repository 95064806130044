import { Component } from '@angular/core';

/** Représente les informations systèmes permanents.  */
@Component({
  selector: 'cl-work-item-form-system',
  templateUrl: './work-item-form-system.component.html',
  styleUrls: ['./work-item-form-system.component.scss']
})
export class WorkItemFormSystemComponent {
  constructor() { }
}
