import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { SoftwareHostedLinkBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Asset,
  ServiceSingleResultOfBoolean,
  Software,
  SoftwareHostedComponent,
  SoftwareHostedLink,
  SoftwareHostedLinkInput,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { Maybe } from '@clarilog/core/services/graphql/graphql-types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class SoftwareHostedLinkCoreService extends SoftwareHostedLinkBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public savingHostedComponent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('items?') items?: Array<Maybe<SoftwareHostedLinkInput>>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    // Mise en forme
    items = items.map((d) => {
      return {
        softwareId: d.softwareId == undefined ? id : d.softwareId,
        assetId: d.assetId,
        softwareHostedComponentId: d.softwareHostedComponentId,
      };
    });
    return this.saveHostedComponent(fields, id, items, extendedVariables);
  }

  @InjectArgs
  public savingSoftwareHostedComponent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('items?') items?: Array<Maybe<SoftwareHostedLinkInput>>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    // Mise en forme

    items = items.map((d) => {
      return {
        softwareId: d.softwareId,
        assetId: d.assetId == undefined ? id : d.assetId,
        softwareHostedComponentId: d.softwareHostedComponentId,
      };
    });

    return this.saveSoftwareHostedComponent(
      fields,
      id,
      items,
      extendedVariables,
    );
  }

  /** Mise en forme des données de liason entre porgramme un asset*/
  @InjectArgs
  selectMethodAdd(
    @Args('items') items: Asset[],
    @Args('id') id: string,
  ): SoftwareHostedLink[] {
    let result = items.map((f) => {
      let item: SoftwareHostedLink = {
        assetId: f.id,
        asset: {
          data: f,
        },
        softwareId: id,
      };

      return item;
    });

    return result;
  }

  /** Mise en forme des données de liason entre asset un programme */
  @InjectArgs
  selectSoftwareMethodAdd(
    @Args('items') items: Software[],
    @Args('id') id: string,
  ): SoftwareHostedLink[] {
    let result = items.map((f) => {
      let item: SoftwareHostedLink = {
        assetId: id,
        software: {
          data: f,
        },
        softwareId: f.id,
      };

      return item;
    });

    return result;
  }
}
