import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUrgenciesBaseVariables, FirstUrgenciesBaseVariables, CountUrgenciesBaseVariables, FindUrgenciesBaseVariables, SearchUrgenciesBaseVariables, ExportSchemaUrgenciesBaseVariables, ExportDataUrgenciesBaseVariables, CustomQueryUrgenciesBaseVariables, CustomQueryIdUrgenciesBaseVariables, FindUnassociatedSecurityGroupsUrgenciesBaseVariables, FindAssociatedOperatorTeamSecurityGroupsUrgenciesBaseVariables, FindAssociatedPopulationSecurityGroupsUrgenciesBaseVariables, UsedUrgenciesBaseVariables, ExistUrgenciesBaseVariables, FindRecyclesUrgenciesBaseVariables, CountRecyclesUrgenciesBaseVariables, ReadOnlyUrgenciesBaseVariables, FindArchivedUrgenciesBaseVariables, CountAllUrgenciesBaseVariables, FindDynamicPropertyFieldsUrgenciesBaseVariables, FindUnassociatedTicketsUrgenciesBaseVariables, FindUnassociatedIncidentModelsUrgenciesBaseVariables, InsertUrgenciesBaseVariables, UpdateUrgenciesBaseVariables, ImportDataUrgenciesBaseVariables, UpdateManyUrgenciesBaseVariables, DeleteUrgenciesBaseVariables, OrderUrgenciesBaseVariables, AddSecurityGroupUrgenciesBaseVariables, RemoveSecurityGroupUrgenciesBaseVariables, AddOperatorTeamSecurityGroupUrgenciesBaseVariables, RemoveOperatorTeamSecurityGroupUrgenciesBaseVariables, AddPopulationSecurityGroupUrgenciesBaseVariables, RemovePopulationSecurityGroupUrgenciesBaseVariables, RestoreUrgenciesBaseVariables, RecycleUrgenciesBaseVariables, RestoreArchivedUrgenciesBaseVariables, ArchivedUrgenciesBaseVariables, AddFileDynamicFieldUrgenciesBaseVariables, RemoveFileDynamicFieldUrgenciesBaseVariables, AddIncidentModelsUrgenciesBaseVariables, RemoveIncidentModelsUrgenciesBaseVariables, AddTicketsUrgenciesBaseVariables, RemoveTicketsUrgenciesBaseVariables } from '../gqls'
import { GetUrgenciesDocument, FirstUrgenciesDocument, CountUrgenciesDocument, FindUrgenciesDocument, SearchUrgenciesDocument, ExportSchemaUrgenciesDocument, ExportDataUrgenciesDocument, CustomQueryUrgenciesDocument, CustomQueryIdUrgenciesDocument, FindUnassociatedSecurityGroupsUrgenciesDocument, FindAssociatedOperatorTeamSecurityGroupsUrgenciesDocument, FindAssociatedPopulationSecurityGroupsUrgenciesDocument, UsedUrgenciesDocument, ExistUrgenciesDocument, FindRecyclesUrgenciesDocument, CountRecyclesUrgenciesDocument, ReadOnlyUrgenciesDocument, FindArchivedUrgenciesDocument, CountAllUrgenciesDocument, FindDynamicPropertyFieldsUrgenciesDocument, FindUnassociatedTicketsUrgenciesDocument, FindUnassociatedIncidentModelsUrgenciesDocument, InsertUrgenciesDocument, UpdateUrgenciesDocument, ImportDataUrgenciesDocument, UpdateManyUrgenciesDocument, DeleteUrgenciesDocument, OrderUrgenciesDocument, AddSecurityGroupUrgenciesDocument, RemoveSecurityGroupUrgenciesDocument, AddOperatorTeamSecurityGroupUrgenciesDocument, RemoveOperatorTeamSecurityGroupUrgenciesDocument, AddPopulationSecurityGroupUrgenciesDocument, RemovePopulationSecurityGroupUrgenciesDocument, RestoreUrgenciesDocument, RecycleUrgenciesDocument, RestoreArchivedUrgenciesDocument, ArchivedUrgenciesDocument, AddFileDynamicFieldUrgenciesDocument, RemoveFileDynamicFieldUrgenciesDocument, AddIncidentModelsUrgenciesDocument, RemoveIncidentModelsUrgenciesDocument, AddTicketsUrgenciesDocument, RemoveTicketsUrgenciesDocument } from '../gqls'
import { ServiceSingleResultOfUrgency, QueryContextOfUrgency, FilterOfUrgency, ServiceSingleResultOfInt64, ServiceListResultOfUrgency, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfPopulation, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfUrgency, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, QueryContextOfIncidentModel, FilterOfIncidentModel, ServiceListResultOfIncidentModel, UrgencyInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UrgencyBaseService {
    
public modelName = 'urgency';
public modelPluralName = 'urgencies';

	private getUrgenciesQuery: GetUrgenciesDocument;
	private firstUrgenciesQuery: FirstUrgenciesDocument;
	private countUrgenciesQuery: CountUrgenciesDocument;
	private findUrgenciesQuery: FindUrgenciesDocument;
	private searchUrgenciesQuery: SearchUrgenciesDocument;
	private exportSchemaUrgenciesQuery: ExportSchemaUrgenciesDocument;
	private exportDataUrgenciesQuery: ExportDataUrgenciesDocument;
	private customQueryUrgenciesQuery: CustomQueryUrgenciesDocument;
	private customQueryIdUrgenciesQuery: CustomQueryIdUrgenciesDocument;
	private findUnassociatedSecurityGroupsUrgenciesQuery: FindUnassociatedSecurityGroupsUrgenciesDocument;
	private findAssociatedOperatorTeamSecurityGroupsUrgenciesQuery: FindAssociatedOperatorTeamSecurityGroupsUrgenciesDocument;
	private findAssociatedPopulationSecurityGroupsUrgenciesQuery: FindAssociatedPopulationSecurityGroupsUrgenciesDocument;
	private usedUrgenciesQuery: UsedUrgenciesDocument;
	private existUrgenciesQuery: ExistUrgenciesDocument;
	private findRecyclesUrgenciesQuery: FindRecyclesUrgenciesDocument;
	private countRecyclesUrgenciesQuery: CountRecyclesUrgenciesDocument;
	private readOnlyUrgenciesQuery: ReadOnlyUrgenciesDocument;
	private findArchivedUrgenciesQuery: FindArchivedUrgenciesDocument;
	private countAllUrgenciesQuery: CountAllUrgenciesDocument;
	private findDynamicPropertyFieldsUrgenciesQuery: FindDynamicPropertyFieldsUrgenciesDocument;
	private findUnassociatedTicketsUrgenciesQuery: FindUnassociatedTicketsUrgenciesDocument;
	private findUnassociatedIncidentModelsUrgenciesQuery: FindUnassociatedIncidentModelsUrgenciesDocument;
	private insertUrgenciesMutation: InsertUrgenciesDocument;
	private updateUrgenciesMutation: UpdateUrgenciesDocument;
	private importDataUrgenciesMutation: ImportDataUrgenciesDocument;
	private updateManyUrgenciesMutation: UpdateManyUrgenciesDocument;
	private deleteUrgenciesMutation: DeleteUrgenciesDocument;
	private orderUrgenciesMutation: OrderUrgenciesDocument;
	private addSecurityGroupUrgenciesMutation: AddSecurityGroupUrgenciesDocument;
	private removeSecurityGroupUrgenciesMutation: RemoveSecurityGroupUrgenciesDocument;
	private addOperatorTeamSecurityGroupUrgenciesMutation: AddOperatorTeamSecurityGroupUrgenciesDocument;
	private removeOperatorTeamSecurityGroupUrgenciesMutation: RemoveOperatorTeamSecurityGroupUrgenciesDocument;
	private addPopulationSecurityGroupUrgenciesMutation: AddPopulationSecurityGroupUrgenciesDocument;
	private removePopulationSecurityGroupUrgenciesMutation: RemovePopulationSecurityGroupUrgenciesDocument;
	private restoreUrgenciesMutation: RestoreUrgenciesDocument;
	private recycleUrgenciesMutation: RecycleUrgenciesDocument;
	private restoreArchivedUrgenciesMutation: RestoreArchivedUrgenciesDocument;
	private archivedUrgenciesMutation: ArchivedUrgenciesDocument;
	private addFileDynamicFieldUrgenciesMutation: AddFileDynamicFieldUrgenciesDocument;
	private removeFileDynamicFieldUrgenciesMutation: RemoveFileDynamicFieldUrgenciesDocument;
	private addIncidentModelsUrgenciesMutation: AddIncidentModelsUrgenciesDocument;
	private removeIncidentModelsUrgenciesMutation: RemoveIncidentModelsUrgenciesDocument;
	private addTicketsUrgenciesMutation: AddTicketsUrgenciesDocument;
	private removeTicketsUrgenciesMutation: RemoveTicketsUrgenciesDocument;

	constructor(private injector: Injector) {
		this.getUrgenciesQuery = this.injector.get(GetUrgenciesDocument);
		this.firstUrgenciesQuery = this.injector.get(FirstUrgenciesDocument);
		this.countUrgenciesQuery = this.injector.get(CountUrgenciesDocument);
		this.findUrgenciesQuery = this.injector.get(FindUrgenciesDocument);
		this.searchUrgenciesQuery = this.injector.get(SearchUrgenciesDocument);
		this.exportSchemaUrgenciesQuery = this.injector.get(ExportSchemaUrgenciesDocument);
		this.exportDataUrgenciesQuery = this.injector.get(ExportDataUrgenciesDocument);
		this.customQueryUrgenciesQuery = this.injector.get(CustomQueryUrgenciesDocument);
		this.customQueryIdUrgenciesQuery = this.injector.get(CustomQueryIdUrgenciesDocument);
		this.findUnassociatedSecurityGroupsUrgenciesQuery = this.injector.get(FindUnassociatedSecurityGroupsUrgenciesDocument);
		this.findAssociatedOperatorTeamSecurityGroupsUrgenciesQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsUrgenciesDocument);
		this.findAssociatedPopulationSecurityGroupsUrgenciesQuery = this.injector.get(FindAssociatedPopulationSecurityGroupsUrgenciesDocument);
		this.usedUrgenciesQuery = this.injector.get(UsedUrgenciesDocument);
		this.existUrgenciesQuery = this.injector.get(ExistUrgenciesDocument);
		this.findRecyclesUrgenciesQuery = this.injector.get(FindRecyclesUrgenciesDocument);
		this.countRecyclesUrgenciesQuery = this.injector.get(CountRecyclesUrgenciesDocument);
		this.readOnlyUrgenciesQuery = this.injector.get(ReadOnlyUrgenciesDocument);
		this.findArchivedUrgenciesQuery = this.injector.get(FindArchivedUrgenciesDocument);
		this.countAllUrgenciesQuery = this.injector.get(CountAllUrgenciesDocument);
		this.findDynamicPropertyFieldsUrgenciesQuery = this.injector.get(FindDynamicPropertyFieldsUrgenciesDocument);
		this.findUnassociatedTicketsUrgenciesQuery = this.injector.get(FindUnassociatedTicketsUrgenciesDocument);
		this.findUnassociatedIncidentModelsUrgenciesQuery = this.injector.get(FindUnassociatedIncidentModelsUrgenciesDocument);
		this.insertUrgenciesMutation = this.injector.get(InsertUrgenciesDocument);
		this.updateUrgenciesMutation = this.injector.get(UpdateUrgenciesDocument);
		this.importDataUrgenciesMutation = this.injector.get(ImportDataUrgenciesDocument);
		this.updateManyUrgenciesMutation = this.injector.get(UpdateManyUrgenciesDocument);
		this.deleteUrgenciesMutation = this.injector.get(DeleteUrgenciesDocument);
		this.orderUrgenciesMutation = this.injector.get(OrderUrgenciesDocument);
		this.addSecurityGroupUrgenciesMutation = this.injector.get(AddSecurityGroupUrgenciesDocument);
		this.removeSecurityGroupUrgenciesMutation = this.injector.get(RemoveSecurityGroupUrgenciesDocument);
		this.addOperatorTeamSecurityGroupUrgenciesMutation = this.injector.get(AddOperatorTeamSecurityGroupUrgenciesDocument);
		this.removeOperatorTeamSecurityGroupUrgenciesMutation = this.injector.get(RemoveOperatorTeamSecurityGroupUrgenciesDocument);
		this.addPopulationSecurityGroupUrgenciesMutation = this.injector.get(AddPopulationSecurityGroupUrgenciesDocument);
		this.removePopulationSecurityGroupUrgenciesMutation = this.injector.get(RemovePopulationSecurityGroupUrgenciesDocument);
		this.restoreUrgenciesMutation = this.injector.get(RestoreUrgenciesDocument);
		this.recycleUrgenciesMutation = this.injector.get(RecycleUrgenciesDocument);
		this.restoreArchivedUrgenciesMutation = this.injector.get(RestoreArchivedUrgenciesDocument);
		this.archivedUrgenciesMutation = this.injector.get(ArchivedUrgenciesDocument);
		this.addFileDynamicFieldUrgenciesMutation = this.injector.get(AddFileDynamicFieldUrgenciesDocument);
		this.removeFileDynamicFieldUrgenciesMutation = this.injector.get(RemoveFileDynamicFieldUrgenciesDocument);
		this.addIncidentModelsUrgenciesMutation = this.injector.get(AddIncidentModelsUrgenciesDocument);
		this.removeIncidentModelsUrgenciesMutation = this.injector.get(RemoveIncidentModelsUrgenciesDocument);
		this.addTicketsUrgenciesMutation = this.injector.get(AddTicketsUrgenciesDocument);
		this.removeTicketsUrgenciesMutation = this.injector.get(RemoveTicketsUrgenciesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUrgency> {
    
    		let variables: GetUrgenciesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.get));
            }
            else{
                let result:ServiceSingleResultOfUrgency={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUrgency> {
    
    		let variables: FirstUrgenciesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUrgenciesBaseVariables = {
    			filter: filter
    		}
    				return this.countUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: FindUrgenciesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUrgency,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: SearchUrgenciesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUrgenciesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUrgenciesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: CustomQueryUrgenciesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: CustomQueryIdUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les population via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedPopulationSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindAssociatedPopulationSecurityGroupsUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedPopulationSecurityGroupsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findAssociatedPopulationSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.usedUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUrgenciesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: FindRecyclesUrgenciesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesUrgenciesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyUrgenciesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUrgency,
		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUrgency> {
    
    		let variables: FindArchivedUrgenciesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllUrgenciesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsUrgenciesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findUnassociatedTickets));
    	}

	@InjectArgs
	public findAssociatedIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('incidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfIncidentModels'] = filter;
		extendedVariables['optionsOfIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.incidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedIncidentModelsUrgenciesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedIncidentModelsUrgenciesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.findUnassociatedIncidentModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: UrgencyInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUrgency> {
    
    		let variables: InsertUrgenciesBaseVariables = {
    			entity: entity
    		}
    				return this.insertUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUrgency> {
    
    		let variables: UpdateUrgenciesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataUrgenciesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfUrgency,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyUrgenciesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderUrgenciesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.order));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			populationIds: populationIds,
			isNew: isNew
    		}
    				return this.addPopulationSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addPopulationSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationSecurityGroupUrgenciesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationSecurityGroupUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removePopulationSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedUrgenciesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldUrgenciesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldUrgenciesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddIncidentModelsUrgenciesBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addIncidentModelsUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveIncidentModelsUrgenciesBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeIncidentModelsUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removeIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsUrgenciesBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsUrgenciesBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsUrgenciesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.urgencies.removeTickets));
    	}
    
}