import Globalize from 'globalize/dist/globalize';

/** Représente un service de traduction */
export class TranslateService {
  /**
   * Permet de traduire une clé en sa représentation traduite
   *
   * @export
   * @class TranslateService
   * @example
   *  let text = TranslateService.get('key1');
   * @example
   *  let text = TranslateService.get('key1',{
   *    param1:'value'
   *  });
   * @example
   *  // Pour force le texte dans une autre langue
   *  let text = TranslateService.get('key1',{
   *    param1:'value'
   *  },'en-US');
   */
  static get(value: string, ...args: any[]) {
    try {
      // Le premier arguments sont les paramètres
      let options = args.length > 0 ? args[0] : undefined;
      // Le deuxième argument peut être une langue
      let lang = args.length > 1 ? args[1] : undefined;
      let locale = Globalize.locale();
      // Vérifie si la clé existe
      let hasValue =
        locale.get(['globalize-messages/{bundle}'].concat(value)) !== undefined;
      let text = `[${value}]`;
      if (hasValue === true && value != undefined && value != '') {
        text = Globalize.messageFormatter(value)(options);
      }
      if (text.includes('[')) {
        let backup = Globalize.locale('en');
        let backTranslation = backup.get(
          ['globalize-messages/{bundle}'].concat(value),
        );
        if (backTranslation != undefined) {
          text = backTranslation;
        }
        locale = Globalize.locale(locale.attributes.language);
      }

      return text;
    } catch (e) {
      console.log('Translate not found : ' + value);
      return '[' + value + ']';
    }
  }
}
