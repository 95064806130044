import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetEmailCredentialsBaseVariables, FirstEmailCredentialsBaseVariables, CountEmailCredentialsBaseVariables, FindEmailCredentialsBaseVariables, SearchEmailCredentialsBaseVariables, ExportSchemaEmailCredentialsBaseVariables, ExportDataEmailCredentialsBaseVariables, CustomQueryEmailCredentialsBaseVariables, CustomQueryIdEmailCredentialsBaseVariables, UsedEmailCredentialsBaseVariables, ExistEmailCredentialsBaseVariables, FindRecyclesEmailCredentialsBaseVariables, CountRecyclesEmailCredentialsBaseVariables, ReadOnlyEmailCredentialsBaseVariables, FindArchivedEmailCredentialsBaseVariables, CountAllEmailCredentialsBaseVariables, FindDynamicPropertyFieldsEmailCredentialsBaseVariables, FindUnassociatedEmailConnectorsEmailCredentialsBaseVariables, InsertEmailCredentialsBaseVariables, UpdateEmailCredentialsBaseVariables, ImportDataEmailCredentialsBaseVariables, UpdateManyEmailCredentialsBaseVariables, DeleteEmailCredentialsBaseVariables, RestoreEmailCredentialsBaseVariables, RecycleEmailCredentialsBaseVariables, RestoreArchivedEmailCredentialsBaseVariables, ArchivedEmailCredentialsBaseVariables, AddFileDynamicFieldEmailCredentialsBaseVariables, RemoveFileDynamicFieldEmailCredentialsBaseVariables, AddEmailConnectorsEmailCredentialsBaseVariables, RemoveEmailConnectorsEmailCredentialsBaseVariables } from '../gqls'
import { GetEmailCredentialsDocument, FirstEmailCredentialsDocument, CountEmailCredentialsDocument, FindEmailCredentialsDocument, SearchEmailCredentialsDocument, ExportSchemaEmailCredentialsDocument, ExportDataEmailCredentialsDocument, CustomQueryEmailCredentialsDocument, CustomQueryIdEmailCredentialsDocument, UsedEmailCredentialsDocument, ExistEmailCredentialsDocument, FindRecyclesEmailCredentialsDocument, CountRecyclesEmailCredentialsDocument, ReadOnlyEmailCredentialsDocument, FindArchivedEmailCredentialsDocument, CountAllEmailCredentialsDocument, FindDynamicPropertyFieldsEmailCredentialsDocument, FindUnassociatedEmailConnectorsEmailCredentialsDocument, InsertEmailCredentialsDocument, UpdateEmailCredentialsDocument, ImportDataEmailCredentialsDocument, UpdateManyEmailCredentialsDocument, DeleteEmailCredentialsDocument, RestoreEmailCredentialsDocument, RecycleEmailCredentialsDocument, RestoreArchivedEmailCredentialsDocument, ArchivedEmailCredentialsDocument, AddFileDynamicFieldEmailCredentialsDocument, RemoveFileDynamicFieldEmailCredentialsDocument, AddEmailConnectorsEmailCredentialsDocument, RemoveEmailConnectorsEmailCredentialsDocument } from '../gqls'
import { ServiceSingleResultOfEmailCredential, QueryContextOfEmailCredential, FilterOfEmailCredential, ServiceSingleResultOfInt64, ServiceListResultOfEmailCredential, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfEmailCredential, QueryContextOfEmailConnector, FilterOfEmailConnector, ServiceListResultOfEmailConnector, EmailCredentialInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class EmailCredentialBaseService {
    
public modelName = 'emailCredential';
public modelPluralName = 'emailCredentials';

	private getEmailCredentialsQuery: GetEmailCredentialsDocument;
	private firstEmailCredentialsQuery: FirstEmailCredentialsDocument;
	private countEmailCredentialsQuery: CountEmailCredentialsDocument;
	private findEmailCredentialsQuery: FindEmailCredentialsDocument;
	private searchEmailCredentialsQuery: SearchEmailCredentialsDocument;
	private exportSchemaEmailCredentialsQuery: ExportSchemaEmailCredentialsDocument;
	private exportDataEmailCredentialsQuery: ExportDataEmailCredentialsDocument;
	private customQueryEmailCredentialsQuery: CustomQueryEmailCredentialsDocument;
	private customQueryIdEmailCredentialsQuery: CustomQueryIdEmailCredentialsDocument;
	private usedEmailCredentialsQuery: UsedEmailCredentialsDocument;
	private existEmailCredentialsQuery: ExistEmailCredentialsDocument;
	private findRecyclesEmailCredentialsQuery: FindRecyclesEmailCredentialsDocument;
	private countRecyclesEmailCredentialsQuery: CountRecyclesEmailCredentialsDocument;
	private readOnlyEmailCredentialsQuery: ReadOnlyEmailCredentialsDocument;
	private findArchivedEmailCredentialsQuery: FindArchivedEmailCredentialsDocument;
	private countAllEmailCredentialsQuery: CountAllEmailCredentialsDocument;
	private findDynamicPropertyFieldsEmailCredentialsQuery: FindDynamicPropertyFieldsEmailCredentialsDocument;
	private findUnassociatedEmailConnectorsEmailCredentialsQuery: FindUnassociatedEmailConnectorsEmailCredentialsDocument;
	private insertEmailCredentialsMutation: InsertEmailCredentialsDocument;
	private updateEmailCredentialsMutation: UpdateEmailCredentialsDocument;
	private importDataEmailCredentialsMutation: ImportDataEmailCredentialsDocument;
	private updateManyEmailCredentialsMutation: UpdateManyEmailCredentialsDocument;
	private deleteEmailCredentialsMutation: DeleteEmailCredentialsDocument;
	private restoreEmailCredentialsMutation: RestoreEmailCredentialsDocument;
	private recycleEmailCredentialsMutation: RecycleEmailCredentialsDocument;
	private restoreArchivedEmailCredentialsMutation: RestoreArchivedEmailCredentialsDocument;
	private archivedEmailCredentialsMutation: ArchivedEmailCredentialsDocument;
	private addFileDynamicFieldEmailCredentialsMutation: AddFileDynamicFieldEmailCredentialsDocument;
	private removeFileDynamicFieldEmailCredentialsMutation: RemoveFileDynamicFieldEmailCredentialsDocument;
	private addEmailConnectorsEmailCredentialsMutation: AddEmailConnectorsEmailCredentialsDocument;
	private removeEmailConnectorsEmailCredentialsMutation: RemoveEmailConnectorsEmailCredentialsDocument;

	constructor(private injector: Injector) {
		this.getEmailCredentialsQuery = this.injector.get(GetEmailCredentialsDocument);
		this.firstEmailCredentialsQuery = this.injector.get(FirstEmailCredentialsDocument);
		this.countEmailCredentialsQuery = this.injector.get(CountEmailCredentialsDocument);
		this.findEmailCredentialsQuery = this.injector.get(FindEmailCredentialsDocument);
		this.searchEmailCredentialsQuery = this.injector.get(SearchEmailCredentialsDocument);
		this.exportSchemaEmailCredentialsQuery = this.injector.get(ExportSchemaEmailCredentialsDocument);
		this.exportDataEmailCredentialsQuery = this.injector.get(ExportDataEmailCredentialsDocument);
		this.customQueryEmailCredentialsQuery = this.injector.get(CustomQueryEmailCredentialsDocument);
		this.customQueryIdEmailCredentialsQuery = this.injector.get(CustomQueryIdEmailCredentialsDocument);
		this.usedEmailCredentialsQuery = this.injector.get(UsedEmailCredentialsDocument);
		this.existEmailCredentialsQuery = this.injector.get(ExistEmailCredentialsDocument);
		this.findRecyclesEmailCredentialsQuery = this.injector.get(FindRecyclesEmailCredentialsDocument);
		this.countRecyclesEmailCredentialsQuery = this.injector.get(CountRecyclesEmailCredentialsDocument);
		this.readOnlyEmailCredentialsQuery = this.injector.get(ReadOnlyEmailCredentialsDocument);
		this.findArchivedEmailCredentialsQuery = this.injector.get(FindArchivedEmailCredentialsDocument);
		this.countAllEmailCredentialsQuery = this.injector.get(CountAllEmailCredentialsDocument);
		this.findDynamicPropertyFieldsEmailCredentialsQuery = this.injector.get(FindDynamicPropertyFieldsEmailCredentialsDocument);
		this.findUnassociatedEmailConnectorsEmailCredentialsQuery = this.injector.get(FindUnassociatedEmailConnectorsEmailCredentialsDocument);
		this.insertEmailCredentialsMutation = this.injector.get(InsertEmailCredentialsDocument);
		this.updateEmailCredentialsMutation = this.injector.get(UpdateEmailCredentialsDocument);
		this.importDataEmailCredentialsMutation = this.injector.get(ImportDataEmailCredentialsDocument);
		this.updateManyEmailCredentialsMutation = this.injector.get(UpdateManyEmailCredentialsDocument);
		this.deleteEmailCredentialsMutation = this.injector.get(DeleteEmailCredentialsDocument);
		this.restoreEmailCredentialsMutation = this.injector.get(RestoreEmailCredentialsDocument);
		this.recycleEmailCredentialsMutation = this.injector.get(RecycleEmailCredentialsDocument);
		this.restoreArchivedEmailCredentialsMutation = this.injector.get(RestoreArchivedEmailCredentialsDocument);
		this.archivedEmailCredentialsMutation = this.injector.get(ArchivedEmailCredentialsDocument);
		this.addFileDynamicFieldEmailCredentialsMutation = this.injector.get(AddFileDynamicFieldEmailCredentialsDocument);
		this.removeFileDynamicFieldEmailCredentialsMutation = this.injector.get(RemoveFileDynamicFieldEmailCredentialsDocument);
		this.addEmailConnectorsEmailCredentialsMutation = this.injector.get(AddEmailConnectorsEmailCredentialsDocument);
		this.removeEmailConnectorsEmailCredentialsMutation = this.injector.get(RemoveEmailConnectorsEmailCredentialsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailCredential> {
    
    		let variables: GetEmailCredentialsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.get));
            }
            else{
                let result:ServiceSingleResultOfEmailCredential={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailCredential> {
    
    		let variables: FirstEmailCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountEmailCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: FindEmailCredentialsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: SearchEmailCredentialsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaEmailCredentialsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataEmailCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: CustomQueryEmailCredentialsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: CustomQueryIdEmailCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.usedEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistEmailCredentialsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: FindRecyclesEmailCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesEmailCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyEmailCredentialsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailCredential,
		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailCredential> {
    
    		let variables: FindArchivedEmailCredentialsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllEmailCredentialsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsEmailCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedEmailConnectors(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfEmailConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailConnector,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfEmailConnector> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('emailConnectors', fields, null, [
			GqlSubFieldArg.create('filterOfEmailConnectors', 'filter'),
			GqlSubFieldArg.create('optionsOfEmailConnectors', 'options'),
		]),
		])
		extendedVariables['filterOfEmailConnectors'] = filter;
		extendedVariables['optionsOfEmailConnectors'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.emailConnectors));
            }
            else{
                let result: ServiceListResultOfEmailConnector = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfEmailConnector,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfEmailConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfEmailConnector> {
    
    		let variables: FindUnassociatedEmailConnectorsEmailCredentialsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedEmailConnectorsEmailCredentialsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.findUnassociatedEmailConnectors));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: EmailCredentialInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailCredential> {
    
    		let variables: InsertEmailCredentialsBaseVariables = {
    			entity: entity
    		}
    				return this.insertEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEmailCredential> {
    
    		let variables: UpdateEmailCredentialsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataEmailCredentialsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfEmailCredential,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyEmailCredentialsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedEmailCredentialsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldEmailCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldEmailCredentialsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('emailConnectorIds') emailConnectorIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddEmailConnectorsEmailCredentialsBaseVariables = {
    			id: id,
			emailConnectorIds: emailConnectorIds
    		}
    				return this.addEmailConnectorsEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.addEmailConnectors));
    	}

    	/**  */
    	@InjectArgs
    	public removeEmailConnectors(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('emailConnectorIds') emailConnectorIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveEmailConnectorsEmailCredentialsBaseVariables = {
    			emailConnectorIds: emailConnectorIds
    		}
    				return this.removeEmailConnectorsEmailCredentialsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.emailCredentials.removeEmailConnectors));
    	}
    
}