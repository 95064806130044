
import { WorkTimeBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({providedIn: 'root'})
@Authorize('help-desk-operator.manage-work-time')
export class WorkTimeCoreService extends WorkTimeBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}