import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketDurationTasksBaseVariables, FirstTicketDurationTasksBaseVariables, CountTicketDurationTasksBaseVariables, FindTicketDurationTasksBaseVariables, SearchTicketDurationTasksBaseVariables, ExportSchemaTicketDurationTasksBaseVariables, ExportDataTicketDurationTasksBaseVariables, CustomQueryTicketDurationTasksBaseVariables, CustomQueryIdTicketDurationTasksBaseVariables, UsedTicketDurationTasksBaseVariables, ExistTicketDurationTasksBaseVariables, InsertTicketDurationTasksBaseVariables, UpdateTicketDurationTasksBaseVariables, ImportDataTicketDurationTasksBaseVariables, UpdateManyTicketDurationTasksBaseVariables, DeleteTicketDurationTasksBaseVariables } from '../gqls'
import { GetTicketDurationTasksDocument, FirstTicketDurationTasksDocument, CountTicketDurationTasksDocument, FindTicketDurationTasksDocument, SearchTicketDurationTasksDocument, ExportSchemaTicketDurationTasksDocument, ExportDataTicketDurationTasksDocument, CustomQueryTicketDurationTasksDocument, CustomQueryIdTicketDurationTasksDocument, UsedTicketDurationTasksDocument, ExistTicketDurationTasksDocument, InsertTicketDurationTasksDocument, UpdateTicketDurationTasksDocument, ImportDataTicketDurationTasksDocument, UpdateManyTicketDurationTasksDocument, DeleteTicketDurationTasksDocument } from '../gqls'
import { ServiceSingleResultOfTicketDurationTask, QueryContextOfTicketDurationTask, FilterOfTicketDurationTask, ServiceSingleResultOfInt64, ServiceListResultOfTicketDurationTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketDurationTaskInput, FieldUpdateOperatorOfTicketDurationTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketDurationTaskBaseService {
    
public modelName = 'ticketDurationTask';
public modelPluralName = 'ticketDurationTasks';

	private getTicketDurationTasksQuery: GetTicketDurationTasksDocument;
	private firstTicketDurationTasksQuery: FirstTicketDurationTasksDocument;
	private countTicketDurationTasksQuery: CountTicketDurationTasksDocument;
	private findTicketDurationTasksQuery: FindTicketDurationTasksDocument;
	private searchTicketDurationTasksQuery: SearchTicketDurationTasksDocument;
	private exportSchemaTicketDurationTasksQuery: ExportSchemaTicketDurationTasksDocument;
	private exportDataTicketDurationTasksQuery: ExportDataTicketDurationTasksDocument;
	private customQueryTicketDurationTasksQuery: CustomQueryTicketDurationTasksDocument;
	private customQueryIdTicketDurationTasksQuery: CustomQueryIdTicketDurationTasksDocument;
	private usedTicketDurationTasksQuery: UsedTicketDurationTasksDocument;
	private existTicketDurationTasksQuery: ExistTicketDurationTasksDocument;
	private insertTicketDurationTasksMutation: InsertTicketDurationTasksDocument;
	private updateTicketDurationTasksMutation: UpdateTicketDurationTasksDocument;
	private importDataTicketDurationTasksMutation: ImportDataTicketDurationTasksDocument;
	private updateManyTicketDurationTasksMutation: UpdateManyTicketDurationTasksDocument;
	private deleteTicketDurationTasksMutation: DeleteTicketDurationTasksDocument;

	constructor(private injector: Injector) {
		this.getTicketDurationTasksQuery = this.injector.get(GetTicketDurationTasksDocument);
		this.firstTicketDurationTasksQuery = this.injector.get(FirstTicketDurationTasksDocument);
		this.countTicketDurationTasksQuery = this.injector.get(CountTicketDurationTasksDocument);
		this.findTicketDurationTasksQuery = this.injector.get(FindTicketDurationTasksDocument);
		this.searchTicketDurationTasksQuery = this.injector.get(SearchTicketDurationTasksDocument);
		this.exportSchemaTicketDurationTasksQuery = this.injector.get(ExportSchemaTicketDurationTasksDocument);
		this.exportDataTicketDurationTasksQuery = this.injector.get(ExportDataTicketDurationTasksDocument);
		this.customQueryTicketDurationTasksQuery = this.injector.get(CustomQueryTicketDurationTasksDocument);
		this.customQueryIdTicketDurationTasksQuery = this.injector.get(CustomQueryIdTicketDurationTasksDocument);
		this.usedTicketDurationTasksQuery = this.injector.get(UsedTicketDurationTasksDocument);
		this.existTicketDurationTasksQuery = this.injector.get(ExistTicketDurationTasksDocument);
		this.insertTicketDurationTasksMutation = this.injector.get(InsertTicketDurationTasksDocument);
		this.updateTicketDurationTasksMutation = this.injector.get(UpdateTicketDurationTasksDocument);
		this.importDataTicketDurationTasksMutation = this.injector.get(ImportDataTicketDurationTasksDocument);
		this.updateManyTicketDurationTasksMutation = this.injector.get(UpdateManyTicketDurationTasksDocument);
		this.deleteTicketDurationTasksMutation = this.injector.get(DeleteTicketDurationTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDurationTask> {
    
    		let variables: GetTicketDurationTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.get));
            }
            else{
                let result:ServiceSingleResultOfTicketDurationTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketDurationTask,
		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDurationTask> {
    
    		let variables: FirstTicketDurationTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketDurationTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketDurationTask,
		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDurationTask> {
    
    		let variables: FindTicketDurationTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketDurationTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDurationTask> {
    
    		let variables: SearchTicketDurationTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketDurationTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketDurationTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketDurationTask,
		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDurationTask> {
    
    		let variables: CustomQueryTicketDurationTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketDurationTask,
		@Args('filter?') filter?: FilterOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketDurationTask> {
    
    		let variables: CustomQueryIdTicketDurationTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketDurationTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketDurationTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketDurationTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketDurationTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDurationTask> {
    
    		let variables: InsertTicketDurationTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketDurationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketDurationTask> {
    
    		let variables: UpdateTicketDurationTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketDurationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketDurationTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketDurationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketDurationTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketDurationTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketDurationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketDurationTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketDurationTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketDurationTasks.delete));
    	}
    
}