import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import { CoreListComponent } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';

import { GC, GCFactory } from '@clarilog/core';
import { MasterDetail, ToolbarItem } from '@clarilog/shared2/models';

/** Représente le composent PrintLinkList. */
@Component({
  selector: 'clc-dynamic-link-list',
  templateUrl: './dynamic-link-list.component.html',
  styleUrls: ['./dynamic-link-list.component.scss'],
})
export class CoreDynamicLinkListComponent
  extends ListComponentBase
  implements OnDestroy, AfterViewInit
{
  /** Obtient ou définit la source de données courante. */
  @Input() currentSource: ModelDataSourceContext = new ModelDataSourceContext({
    datasource: new DataSource([]),
  });

  @Input() modelState: ModelState;
  @Input() hint: string;
  @Input() canSelect: boolean = true;
  @Input() typeMode: string = 'Grid';
  @Input() masterDetail: MasterDetail;
  @Input() toolbarItems: ToolbarItem[];
  @Input() recursive: boolean = false;
  @Input() eventName: string;

  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;

  REFRESH_ORDER = 'refresh-dynamic-toolbar';
  gc: GC;

  constructor(
    public _route: ActivatedRoute,
    public gcFactory: GCFactory,
    public router: Router,
  ) {
    super();
    this.gc = gcFactory.create();
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }
  ngAfterViewInit(): void {
    this.gc.forDispose(
      this.modelState.on.subscribe((event) => {
        if (event.eventName === this.REFRESH_ORDER) {
          this.refresh();
        }
      }),
    );
  }

  refresh() {
    this.source?.datasource?.reload();
    this.list.refresh();
  }

  /** Action pour les boutons */
  buttonAction(e: any, item: any = null) {
    let actionName = item.name;
    let itemData = e['itemData'];
    if (itemData !== undefined && itemData !== null) {
      actionName = itemData.key;
    }

    let toolbarItem: {
      actionType: string;
      selectedKeys: string[];
      selectedData: any[] | undefined;
    } = {
      actionType: actionName,
      selectedKeys: this.selectedKeys,
      selectedData: this.selectedData,
    };

    if (this.eventName !== undefined && this.eventName != null) {
      this.modelState.on.emit({
        eventName: this.eventName,
        eventData: toolbarItem,
      });
    }
  }
}
