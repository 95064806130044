import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPatsBaseVariables, FirstPatsBaseVariables, CountPatsBaseVariables, FindPatsBaseVariables, SearchPatsBaseVariables, ExportSchemaPatsBaseVariables, ExportDataPatsBaseVariables, CustomQueryPatsBaseVariables, CustomQueryIdPatsBaseVariables, UsedPatsBaseVariables, FindPermissionScopesPatsBaseVariables, ExistPatsBaseVariables, InsertPatsBaseVariables, UpdatePatsBaseVariables, ImportDataPatsBaseVariables, UpdateManyPatsBaseVariables, DeletePatsBaseVariables } from '../gqls'
import { GetPatsDocument, FirstPatsDocument, CountPatsDocument, FindPatsDocument, SearchPatsDocument, ExportSchemaPatsDocument, ExportDataPatsDocument, CustomQueryPatsDocument, CustomQueryIdPatsDocument, UsedPatsDocument, FindPermissionScopesPatsDocument, ExistPatsDocument, InsertPatsDocument, UpdatePatsDocument, ImportDataPatsDocument, UpdateManyPatsDocument, DeletePatsDocument } from '../gqls'
import { ServiceSingleResultOfPat, QueryContextOfPat, FilterOfPat, ServiceSingleResultOfInt64, ServiceListResultOfPat, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfPermissionScope, PatInput, FieldUpdateOperatorOfPat } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PatBaseService {
    
public modelName = 'pat';
public modelPluralName = 'pats';

	private getPatsQuery: GetPatsDocument;
	private firstPatsQuery: FirstPatsDocument;
	private countPatsQuery: CountPatsDocument;
	private findPatsQuery: FindPatsDocument;
	private searchPatsQuery: SearchPatsDocument;
	private exportSchemaPatsQuery: ExportSchemaPatsDocument;
	private exportDataPatsQuery: ExportDataPatsDocument;
	private customQueryPatsQuery: CustomQueryPatsDocument;
	private customQueryIdPatsQuery: CustomQueryIdPatsDocument;
	private usedPatsQuery: UsedPatsDocument;
	private findPermissionScopesPatsQuery: FindPermissionScopesPatsDocument;
	private existPatsQuery: ExistPatsDocument;
	private insertPatsMutation: InsertPatsDocument;
	private updatePatsMutation: UpdatePatsDocument;
	private importDataPatsMutation: ImportDataPatsDocument;
	private updateManyPatsMutation: UpdateManyPatsDocument;
	private deletePatsMutation: DeletePatsDocument;

	constructor(private injector: Injector) {
		this.getPatsQuery = this.injector.get(GetPatsDocument);
		this.firstPatsQuery = this.injector.get(FirstPatsDocument);
		this.countPatsQuery = this.injector.get(CountPatsDocument);
		this.findPatsQuery = this.injector.get(FindPatsDocument);
		this.searchPatsQuery = this.injector.get(SearchPatsDocument);
		this.exportSchemaPatsQuery = this.injector.get(ExportSchemaPatsDocument);
		this.exportDataPatsQuery = this.injector.get(ExportDataPatsDocument);
		this.customQueryPatsQuery = this.injector.get(CustomQueryPatsDocument);
		this.customQueryIdPatsQuery = this.injector.get(CustomQueryIdPatsDocument);
		this.usedPatsQuery = this.injector.get(UsedPatsDocument);
		this.findPermissionScopesPatsQuery = this.injector.get(FindPermissionScopesPatsDocument);
		this.existPatsQuery = this.injector.get(ExistPatsDocument);
		this.insertPatsMutation = this.injector.get(InsertPatsDocument);
		this.updatePatsMutation = this.injector.get(UpdatePatsDocument);
		this.importDataPatsMutation = this.injector.get(ImportDataPatsDocument);
		this.updateManyPatsMutation = this.injector.get(UpdateManyPatsDocument);
		this.deletePatsMutation = this.injector.get(DeletePatsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPat> {
    
    		let variables: GetPatsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.get));
            }
            else{
                let result:ServiceSingleResultOfPat={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPat,
		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPat> {
    
    		let variables: FirstPatsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPatsBaseVariables = {
    			filter: filter
    		}
    				return this.countPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPat,
		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPat> {
    
    		let variables: FindPatsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPat,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPat> {
    
    		let variables: SearchPatsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPatsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPatsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPat,
		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPat> {
    
    		let variables: CustomQueryPatsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPat,
		@Args('filter?') filter?: FilterOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPat> {
    
    		let variables: CustomQueryIdPatsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPatsBaseVariables = {
    			ids: ids
    		}
    				return this.usedPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.used));
    	}

    	/**  */
    	@InjectArgs
    	public findPermissionScopes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fullAccess') fullAccess: boolean,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPermissionScope> {
    
    		let variables: FindPermissionScopesPatsBaseVariables = {
    			id: id,
			fullAccess: fullAccess
    		}
    				return this.findPermissionScopesPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.findPermissionScopes));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPatsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPatsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pats.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PatInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPat> {
    
    		let variables: InsertPatsBaseVariables = {
    			entity: entity
    		}
    				return this.insertPatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pats.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPat> {
    
    		let variables: UpdatePatsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pats.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPatsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pats.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPatsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pats.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePatsBaseVariables = {
    			ids: ids
    		}
    				return this.deletePatsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pats.delete));
    	}
    
}