import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSqlServersBaseVariables, FirstSqlServersBaseVariables, CountSqlServersBaseVariables, FindSqlServersBaseVariables, SearchSqlServersBaseVariables, ExportSchemaSqlServersBaseVariables, ExportDataSqlServersBaseVariables, CustomQuerySqlServersBaseVariables, CustomQueryIdSqlServersBaseVariables, UsedSqlServersBaseVariables, ExistSqlServersBaseVariables } from '../gqls'
import { GetSqlServersDocument, FirstSqlServersDocument, CountSqlServersDocument, FindSqlServersDocument, SearchSqlServersDocument, ExportSchemaSqlServersDocument, ExportDataSqlServersDocument, CustomQuerySqlServersDocument, CustomQueryIdSqlServersDocument, UsedSqlServersDocument, ExistSqlServersDocument } from '../gqls'
import { ServiceSingleResultOfSqlServer, QueryContextOfSqlServer, FilterOfSqlServer, ServiceSingleResultOfInt64, ServiceListResultOfSqlServer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SqlServerBaseService {
    
public modelName = 'sqlServer';
public modelPluralName = 'sqlServers';

	private getSqlServersQuery: GetSqlServersDocument;
	private firstSqlServersQuery: FirstSqlServersDocument;
	private countSqlServersQuery: CountSqlServersDocument;
	private findSqlServersQuery: FindSqlServersDocument;
	private searchSqlServersQuery: SearchSqlServersDocument;
	private exportSchemaSqlServersQuery: ExportSchemaSqlServersDocument;
	private exportDataSqlServersQuery: ExportDataSqlServersDocument;
	private customQuerySqlServersQuery: CustomQuerySqlServersDocument;
	private customQueryIdSqlServersQuery: CustomQueryIdSqlServersDocument;
	private usedSqlServersQuery: UsedSqlServersDocument;
	private existSqlServersQuery: ExistSqlServersDocument;

	constructor(private injector: Injector) {
		this.getSqlServersQuery = this.injector.get(GetSqlServersDocument);
		this.firstSqlServersQuery = this.injector.get(FirstSqlServersDocument);
		this.countSqlServersQuery = this.injector.get(CountSqlServersDocument);
		this.findSqlServersQuery = this.injector.get(FindSqlServersDocument);
		this.searchSqlServersQuery = this.injector.get(SearchSqlServersDocument);
		this.exportSchemaSqlServersQuery = this.injector.get(ExportSchemaSqlServersDocument);
		this.exportDataSqlServersQuery = this.injector.get(ExportDataSqlServersDocument);
		this.customQuerySqlServersQuery = this.injector.get(CustomQuerySqlServersDocument);
		this.customQueryIdSqlServersQuery = this.injector.get(CustomQueryIdSqlServersDocument);
		this.usedSqlServersQuery = this.injector.get(UsedSqlServersDocument);
		this.existSqlServersQuery = this.injector.get(ExistSqlServersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSqlServer> {
    
    		let variables: GetSqlServersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.get));
            }
            else{
                let result:ServiceSingleResultOfSqlServer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSqlServer,
		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSqlServer> {
    
    		let variables: FirstSqlServersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSqlServersBaseVariables = {
    			filter: filter
    		}
    				return this.countSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSqlServer,
		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSqlServer> {
    
    		let variables: FindSqlServersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSqlServer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSqlServer> {
    
    		let variables: SearchSqlServersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSqlServersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSqlServersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSqlServer,
		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSqlServer> {
    
    		let variables: CustomQuerySqlServersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSqlServer,
		@Args('filter?') filter?: FilterOfSqlServer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSqlServer> {
    
    		let variables: CustomQueryIdSqlServersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSqlServersBaseVariables = {
    			ids: ids
    		}
    				return this.usedSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSqlServersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSqlServersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.sqlServers.exist));
    	}
    
    
}