import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketYearlyBacklogsBaseVariables, FirstTicketYearlyBacklogsBaseVariables, CountTicketYearlyBacklogsBaseVariables, FindTicketYearlyBacklogsBaseVariables, SearchTicketYearlyBacklogsBaseVariables, ExportSchemaTicketYearlyBacklogsBaseVariables, ExportDataTicketYearlyBacklogsBaseVariables, CustomQueryTicketYearlyBacklogsBaseVariables, CustomQueryIdTicketYearlyBacklogsBaseVariables, UsedTicketYearlyBacklogsBaseVariables, ExistTicketYearlyBacklogsBaseVariables, InsertTicketYearlyBacklogsBaseVariables, UpdateTicketYearlyBacklogsBaseVariables, ImportDataTicketYearlyBacklogsBaseVariables, UpdateManyTicketYearlyBacklogsBaseVariables, DeleteTicketYearlyBacklogsBaseVariables } from '../gqls'
import { GetTicketYearlyBacklogsDocument, FirstTicketYearlyBacklogsDocument, CountTicketYearlyBacklogsDocument, FindTicketYearlyBacklogsDocument, SearchTicketYearlyBacklogsDocument, ExportSchemaTicketYearlyBacklogsDocument, ExportDataTicketYearlyBacklogsDocument, CustomQueryTicketYearlyBacklogsDocument, CustomQueryIdTicketYearlyBacklogsDocument, UsedTicketYearlyBacklogsDocument, ExistTicketYearlyBacklogsDocument, InsertTicketYearlyBacklogsDocument, UpdateTicketYearlyBacklogsDocument, ImportDataTicketYearlyBacklogsDocument, UpdateManyTicketYearlyBacklogsDocument, DeleteTicketYearlyBacklogsDocument } from '../gqls'
import { ServiceSingleResultOfTicketYearlyBacklog, QueryContextOfTicketYearlyBacklog, FilterOfTicketYearlyBacklog, ServiceSingleResultOfInt64, ServiceListResultOfTicketYearlyBacklog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketYearlyBacklogInput, FieldUpdateOperatorOfTicketYearlyBacklog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketYearlyBacklogBaseService {
    
public modelName = 'ticketYearlyBacklog';
public modelPluralName = 'ticketYearlyBacklogs';

	private getTicketYearlyBacklogsQuery: GetTicketYearlyBacklogsDocument;
	private firstTicketYearlyBacklogsQuery: FirstTicketYearlyBacklogsDocument;
	private countTicketYearlyBacklogsQuery: CountTicketYearlyBacklogsDocument;
	private findTicketYearlyBacklogsQuery: FindTicketYearlyBacklogsDocument;
	private searchTicketYearlyBacklogsQuery: SearchTicketYearlyBacklogsDocument;
	private exportSchemaTicketYearlyBacklogsQuery: ExportSchemaTicketYearlyBacklogsDocument;
	private exportDataTicketYearlyBacklogsQuery: ExportDataTicketYearlyBacklogsDocument;
	private customQueryTicketYearlyBacklogsQuery: CustomQueryTicketYearlyBacklogsDocument;
	private customQueryIdTicketYearlyBacklogsQuery: CustomQueryIdTicketYearlyBacklogsDocument;
	private usedTicketYearlyBacklogsQuery: UsedTicketYearlyBacklogsDocument;
	private existTicketYearlyBacklogsQuery: ExistTicketYearlyBacklogsDocument;
	private insertTicketYearlyBacklogsMutation: InsertTicketYearlyBacklogsDocument;
	private updateTicketYearlyBacklogsMutation: UpdateTicketYearlyBacklogsDocument;
	private importDataTicketYearlyBacklogsMutation: ImportDataTicketYearlyBacklogsDocument;
	private updateManyTicketYearlyBacklogsMutation: UpdateManyTicketYearlyBacklogsDocument;
	private deleteTicketYearlyBacklogsMutation: DeleteTicketYearlyBacklogsDocument;

	constructor(private injector: Injector) {
		this.getTicketYearlyBacklogsQuery = this.injector.get(GetTicketYearlyBacklogsDocument);
		this.firstTicketYearlyBacklogsQuery = this.injector.get(FirstTicketYearlyBacklogsDocument);
		this.countTicketYearlyBacklogsQuery = this.injector.get(CountTicketYearlyBacklogsDocument);
		this.findTicketYearlyBacklogsQuery = this.injector.get(FindTicketYearlyBacklogsDocument);
		this.searchTicketYearlyBacklogsQuery = this.injector.get(SearchTicketYearlyBacklogsDocument);
		this.exportSchemaTicketYearlyBacklogsQuery = this.injector.get(ExportSchemaTicketYearlyBacklogsDocument);
		this.exportDataTicketYearlyBacklogsQuery = this.injector.get(ExportDataTicketYearlyBacklogsDocument);
		this.customQueryTicketYearlyBacklogsQuery = this.injector.get(CustomQueryTicketYearlyBacklogsDocument);
		this.customQueryIdTicketYearlyBacklogsQuery = this.injector.get(CustomQueryIdTicketYearlyBacklogsDocument);
		this.usedTicketYearlyBacklogsQuery = this.injector.get(UsedTicketYearlyBacklogsDocument);
		this.existTicketYearlyBacklogsQuery = this.injector.get(ExistTicketYearlyBacklogsDocument);
		this.insertTicketYearlyBacklogsMutation = this.injector.get(InsertTicketYearlyBacklogsDocument);
		this.updateTicketYearlyBacklogsMutation = this.injector.get(UpdateTicketYearlyBacklogsDocument);
		this.importDataTicketYearlyBacklogsMutation = this.injector.get(ImportDataTicketYearlyBacklogsDocument);
		this.updateManyTicketYearlyBacklogsMutation = this.injector.get(UpdateManyTicketYearlyBacklogsDocument);
		this.deleteTicketYearlyBacklogsMutation = this.injector.get(DeleteTicketYearlyBacklogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketYearlyBacklog> {
    
    		let variables: GetTicketYearlyBacklogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.get));
            }
            else{
                let result:ServiceSingleResultOfTicketYearlyBacklog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketYearlyBacklog,
		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketYearlyBacklog> {
    
    		let variables: FirstTicketYearlyBacklogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketYearlyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketYearlyBacklog,
		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketYearlyBacklog> {
    
    		let variables: FindTicketYearlyBacklogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketYearlyBacklog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketYearlyBacklog> {
    
    		let variables: SearchTicketYearlyBacklogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketYearlyBacklogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketYearlyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketYearlyBacklog,
		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketYearlyBacklog> {
    
    		let variables: CustomQueryTicketYearlyBacklogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketYearlyBacklog,
		@Args('filter?') filter?: FilterOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketYearlyBacklog> {
    
    		let variables: CustomQueryIdTicketYearlyBacklogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketYearlyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketYearlyBacklogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketYearlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketYearlyBacklogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketYearlyBacklog> {
    
    		let variables: InsertTicketYearlyBacklogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketYearlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketYearlyBacklog> {
    
    		let variables: UpdateTicketYearlyBacklogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketYearlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketYearlyBacklogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketYearlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketYearlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketYearlyBacklogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketYearlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketYearlyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketYearlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketYearlyBacklogs.delete));
    	}
    
}