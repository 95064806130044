import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetFirewallProductsBaseVariables, FirstFirewallProductsBaseVariables, CountFirewallProductsBaseVariables, FindFirewallProductsBaseVariables, SearchFirewallProductsBaseVariables, ExportSchemaFirewallProductsBaseVariables, ExportDataFirewallProductsBaseVariables, CustomQueryFirewallProductsBaseVariables, CustomQueryIdFirewallProductsBaseVariables, UsedFirewallProductsBaseVariables, ExistFirewallProductsBaseVariables } from '../gqls'
import { GetFirewallProductsDocument, FirstFirewallProductsDocument, CountFirewallProductsDocument, FindFirewallProductsDocument, SearchFirewallProductsDocument, ExportSchemaFirewallProductsDocument, ExportDataFirewallProductsDocument, CustomQueryFirewallProductsDocument, CustomQueryIdFirewallProductsDocument, UsedFirewallProductsDocument, ExistFirewallProductsDocument } from '../gqls'
import { ServiceSingleResultOfFirewallProduct, QueryContextOfFirewallProduct, FilterOfFirewallProduct, ServiceSingleResultOfInt64, ServiceListResultOfFirewallProduct, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class FirewallProductBaseService {
    
public modelName = 'firewallProduct';
public modelPluralName = 'firewallProducts';

	private getFirewallProductsQuery: GetFirewallProductsDocument;
	private firstFirewallProductsQuery: FirstFirewallProductsDocument;
	private countFirewallProductsQuery: CountFirewallProductsDocument;
	private findFirewallProductsQuery: FindFirewallProductsDocument;
	private searchFirewallProductsQuery: SearchFirewallProductsDocument;
	private exportSchemaFirewallProductsQuery: ExportSchemaFirewallProductsDocument;
	private exportDataFirewallProductsQuery: ExportDataFirewallProductsDocument;
	private customQueryFirewallProductsQuery: CustomQueryFirewallProductsDocument;
	private customQueryIdFirewallProductsQuery: CustomQueryIdFirewallProductsDocument;
	private usedFirewallProductsQuery: UsedFirewallProductsDocument;
	private existFirewallProductsQuery: ExistFirewallProductsDocument;

	constructor(private injector: Injector) {
		this.getFirewallProductsQuery = this.injector.get(GetFirewallProductsDocument);
		this.firstFirewallProductsQuery = this.injector.get(FirstFirewallProductsDocument);
		this.countFirewallProductsQuery = this.injector.get(CountFirewallProductsDocument);
		this.findFirewallProductsQuery = this.injector.get(FindFirewallProductsDocument);
		this.searchFirewallProductsQuery = this.injector.get(SearchFirewallProductsDocument);
		this.exportSchemaFirewallProductsQuery = this.injector.get(ExportSchemaFirewallProductsDocument);
		this.exportDataFirewallProductsQuery = this.injector.get(ExportDataFirewallProductsDocument);
		this.customQueryFirewallProductsQuery = this.injector.get(CustomQueryFirewallProductsDocument);
		this.customQueryIdFirewallProductsQuery = this.injector.get(CustomQueryIdFirewallProductsDocument);
		this.usedFirewallProductsQuery = this.injector.get(UsedFirewallProductsDocument);
		this.existFirewallProductsQuery = this.injector.get(ExistFirewallProductsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFirewallProduct> {
    
    		let variables: GetFirewallProductsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.get));
            }
            else{
                let result:ServiceSingleResultOfFirewallProduct={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFirewallProduct,
		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfFirewallProduct> {
    
    		let variables: FirstFirewallProductsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountFirewallProductsBaseVariables = {
    			filter: filter
    		}
    				return this.countFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFirewallProduct,
		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFirewallProduct> {
    
    		let variables: FindFirewallProductsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfFirewallProduct,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFirewallProduct> {
    
    		let variables: SearchFirewallProductsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaFirewallProductsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataFirewallProductsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfFirewallProduct,
		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFirewallProduct> {
    
    		let variables: CustomQueryFirewallProductsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfFirewallProduct,
		@Args('filter?') filter?: FilterOfFirewallProduct,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFirewallProduct> {
    
    		let variables: CustomQueryIdFirewallProductsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedFirewallProductsBaseVariables = {
    			ids: ids
    		}
    				return this.usedFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistFirewallProductsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existFirewallProductsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.firewallProducts.exist));
    	}
    
    
}