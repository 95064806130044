import {
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
} from '@angular/core';
import { EnvironmentService, FileModelType } from '@clarilog/core';
import { GqlField } from '@clarilog/core/services2/graphql/generated-types/helpers';
import { FileManagerCoreService } from '@clarilog/core/services2/graphql/generated-types/services/file-manager.service';

/** Représente la classe des parametre pour le composent cl-file-manager-viewer. */
export type FileViewerType = {
  display: boolean;
  file: FileModelType;
  files: FileModelType[];
};

interface FileViewerModelType extends FileModelType {
  blob?: Blob;
  url?: string;
  download?: string;
}

/** Représente la classe du composent cl-file-manager-viewer. */
@Component({
  selector: 'clc-file-manager-viewer',
  templateUrl: './file-manager-viewer.component.html',
  styleUrls: ['./file-manager-viewer.component.scss'],
  viewProviders: [],
})
export class CoreFileManagerViewerComponent {
  /** Obtient ou définit la source de données. */
  @Output() fileViewerInfoChange = new EventEmitter();
  private _fileViewerInfo: FileViewerType;
  @Input()
  public get fileViewerInfo(): FileViewerType {
    return this._fileViewerInfo;
  }
  public set fileViewerInfo(value: FileViewerType) {
    this._fileViewerInfo = value;
    this.fileViewerInfoChange.emit(value);
  }
  /**  Obtient ou définit la valeur afin d'affichier ou caché la visualisation . */
  display: boolean;
  /** Obtient ou définit le fichier courant. */
  file: FileViewerModelType;
  /** Obtient ou définit la liste des fichies. */
  files: FileViewerModelType[];
  /** Obtient ou définit le nom du fichier courant. */
  fileName: string;
  /** Obtient ou définit la valeur afin d'afficher la fleche de droite. */
  displayNext: boolean;
  /** Obtient ou définit la valeur afin d'afficher la fleche de gauche. */
  displayPrevious: boolean;
  /** Obtient ou définit la valeur afin de fermer le popup. */
  @Input() closable: boolean;
  constructor(
    elementRef: ElementRef,
    public fileManagerService: FileManagerCoreService,
    private _envService: EnvironmentService,
  ) {
    let elementMainContent = document.body
      .getElementsByTagName(elementRef.nativeElement.tagName)
      .item(0);
    if (elementMainContent != null) {
      document.body.removeChild(elementMainContent);
    }
    elementRef.nativeElement.parentNode.removeChild(elementRef.nativeElement);
    document.body.appendChild(elementRef.nativeElement);

    this.fileViewerInfoChange.subscribe((value) => {
      if (value != undefined && value.display == true) {
        this.display = value.display;
        this.file = value.file;
        this.files = value.files;
        this.loadViewer();
        document.onkeyup = (e) => {
          if (e.keyCode == 39) {
            this.nextFile(e);
          }
          if (e.keyCode == 37) {
            this.previousFile(e);
          }
          if (e.keyCode == 27) {
            this.closeViewer(e);
          }
        };
      }
    });
  }

  /** Permet d'afficher le visionneur. */
  async loadViewer() {
    this.openCurrentFile();
  }

  /** Permet de fermer le visionneur. */
  closeViewer(e) {
    this.display = false;
  }

  /** Obtient le fichier suivant. */
  async nextFile(e) {
    let currentIndex = this.files.findIndex(
      (f) => f.fileId == this.file.fileId,
    );
    if (this.files.length > currentIndex + 1) {
      this.file = this.files[currentIndex + 1];
      this.openCurrentFile();
    }
  }

  /** Obtient le fichier précedent. */
  async previousFile(e) {
    let currentIndex = this.files.findIndex(
      (f) => f.fileId == this.file.fileId,
    );
    if (currentIndex - 1 >= 0) {
      this.file = this.files[currentIndex - 1];
      this.openCurrentFile();
    }
  }

  /** Affiche le fichier courant. */
  async openCurrentFile() {
    this.fileName = this.file.name;
    let currentIndex = this.files.findIndex(
      (f) => f.fileId == this.file.fileId,
    );
    this.displayNext = true;
    this.displayPrevious = false;
    if (currentIndex > 0) this.displayPrevious = true;
    if (currentIndex + 1 == this.files.length) this.displayNext = false;

    let elementContent = document
      .getElementsByClassName('fileContent')
      .item(0) as HTMLDivElement;
    if (elementContent.lastChild != undefined)
      elementContent.removeChild(elementContent.lastChild);
    let fileId = this.file.fileId;
    if (fileId == undefined) {
      fileId = this.file['_tempFileId']?.data;
    }
    this.file.url = this._envService.apiURL + '/file/get/' + fileId;
    switch (true) {
      case this.file.type.includes('image'):
        elementContent.appendChild(this.getContentForImg());
        break;
      case this.file.type.includes('msword'):
      case this.file.type.includes(
        'vnd.openxmlformats-officedocument.wordprocessingml.document',
      ):
      case this.file.type.includes('application/vnd.ms-excel'):
      case this.file.type.includes(
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
      ):
      case this.file.type.includes('application/vnd.ms-powerpoint'):
      case this.file.type.includes(
        'application/vnd.openxmlformats-officedocument.presentationml.presentation',
      ):
        elementContent.appendChild(await this.getContentForOffice());
        break;
      case this.file.type.includes('x-msdownload'):
      case this.file.type.includes('xml'):
      case this.file.type.trim() == '':
        elementContent.appendChild(this.getContentFoError());
        break;
      default:
        elementContent.appendChild(this.getContentForObject());
    }
    this.file.download = this._envService.apiURL + '/file/download/' + fileId;
  }

  /** Créer et retourne l'element afin de visualiser les fichiers de type image. */
  getContentForImg() {
    let elementImg = document.createElement('img');
    elementImg.setAttribute('class', 'element-img');
    elementImg.src = this.file.url;
    return elementImg;
  }

  /** Créer et retourne l'element afin de visualiser tout type de fichier. */
  getContentForObject() {
    if (this.file.name.toLocaleLowerCase().endsWith('.pdf')) {
      this.file.type = 'application/pdf';
    }
    let elementObject = document.createElement('object');
    elementObject.setAttribute('class', 'element-object');
    elementObject.type = this.file.type;
    elementObject.data = this.file.url;
    elementObject.onerror = (e) => {
      let elementContent = document.getElementsByClassName('fileContent');
      elementContent[0].replaceChild(this.getContentFoError(), elementObject);
    };
    return elementObject;
  }
  /** Créer et retourne l'element afin de visualiser les fichiers de type office. */
  async getContentForOffice() {
    let iframe = document.createElement('iframe');
    iframe.setAttribute('class', 'element-object');
    let token = await this.fileManagerService
      .tokenFile([GqlField.create('data')], this.file.fileId)
      .toPromise();

    let urlApi = this._envService.apiURL;

    // Test dev avec ngroc
    // urlApi =  'https://fbd2-185-185-9-229.ngrok-free.app'

    let fileUrl = urlApi + '/file/getforoffice/' + token?.data;
    iframe.src =
      'https://view.officeapps.live.com/op/embed.aspx?src=' + fileUrl;
    return iframe;
  }

  /** Recupere et retourne l'element en cas d'erreur de visualisation. */
  getContentFoError() {
    let elementError = document
      .getElementsByClassName('element-error')
      .item(0)
      .cloneNode(true) as HTMLDivElement;
    let downloadElement = elementError
      .getElementsByClassName('element-download-icon')
      .item(0) as HTMLDivElement;
    downloadElement.onclick = (e) => {
      this.downloadFile(e);
    };

    return elementError;
  }

  /** Télécharge le fichier et retourne le fichier en blob. */
  async getFileBlob(fileInfo) {
    let fields = [GqlField.create('data')];

    var sourceFile = await this.fileManagerService
      .downloadFile(fields, fileInfo.fileId)
      .toPromise();
    var b64Data = sourceFile.data;
    var bin = atob(b64Data);
    const contentType = fileInfo.type;
    const byteNumbers = new Array(bin.length);
    for (let i = 0; i < bin.length; i++) {
      byteNumbers[i] = bin.charCodeAt(i);
    }
    const byteArray = new Uint8Array(byteNumbers);
    return new Blob([byteArray], { type: contentType });
  }

  /** Télécharge le fichier. */
  async downloadFile(e) {
    const element = document.createElement('a');
    if (this.file.download == undefined) {
      this.file.blob = await this.getFileBlob(this.file);
      this.file.download = URL.createObjectURL(this.file.blob);
    }
    element.href = this.file.download;
    element.download = this.file.name;
    document.body.appendChild(element);
    element.click();
  }
}
