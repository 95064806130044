import { Component } from '@angular/core';
import { CoreModelCompilerService } from '../../../services/compiler/model-compiler.service';
import model from './model.json';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'cl-email-prototype-trash-list',
  templateUrl: './email-prototype-trash-list.component.html',
})
export class CoreEmailPrototypeTrashListComponent {
  model: ModelState;
  type: any;
  constructor(
    private modelCompilerService: CoreModelCompilerService,
    public activatedRoute: ActivatedRoute,
  ) {
    this.activatedRoute.data.subscribe((data) => {
      this.type = data.type;
    });
  }

  async ngOnInit() {
    this.modelCompilerService.compile(model).subscribe((model) => {
      this.model = model;
      this.model.sharedContext.params.set('type', () => this.type);
    });
  }
}
