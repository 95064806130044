import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowsBaseVariables, FirstWorkflowsBaseVariables, CountWorkflowsBaseVariables, FindWorkflowsBaseVariables, SearchWorkflowsBaseVariables, ExportSchemaWorkflowsBaseVariables, ExportDataWorkflowsBaseVariables, CustomQueryWorkflowsBaseVariables, CustomQueryIdWorkflowsBaseVariables, UsedWorkflowsBaseVariables, FindActionsWorkflowsBaseVariables, FindDelayConfigByTypeWorkflowsBaseVariables, FindWorkflowWorkflowsBaseVariables, ExistWorkflowsBaseVariables, FindRecyclesWorkflowsBaseVariables, CountRecyclesWorkflowsBaseVariables, ReadOnlyWorkflowsBaseVariables, FindArchivedWorkflowsBaseVariables, CountAllWorkflowsBaseVariables, FindDynamicPropertyFieldsWorkflowsBaseVariables, FindUnassociatedWorkflowActionsWorkflowsBaseVariables, InsertWorkflowsBaseVariables, UpdateWorkflowsBaseVariables, ImportDataWorkflowsBaseVariables, UpdateManyWorkflowsBaseVariables, DeleteWorkflowsBaseVariables, SetEnableWorkflowsBaseVariables, DuplicateWorflowWorkflowsBaseVariables, TestAddHttpWorkflowWorkflowsBaseVariables, RestoreWorkflowsBaseVariables, RecycleWorkflowsBaseVariables, RestoreArchivedWorkflowsBaseVariables, ArchivedWorkflowsBaseVariables, AddFileDynamicFieldWorkflowsBaseVariables, RemoveFileDynamicFieldWorkflowsBaseVariables, AddWorkflowActionsWorkflowsBaseVariables, RemoveWorkflowActionsWorkflowsBaseVariables } from '../gqls'
import { GetWorkflowsDocument, FirstWorkflowsDocument, CountWorkflowsDocument, FindWorkflowsDocument, SearchWorkflowsDocument, ExportSchemaWorkflowsDocument, ExportDataWorkflowsDocument, CustomQueryWorkflowsDocument, CustomQueryIdWorkflowsDocument, UsedWorkflowsDocument, FindActionsWorkflowsDocument, FindDelayConfigByTypeWorkflowsDocument, FindWorkflowWorkflowsDocument, ExistWorkflowsDocument, FindRecyclesWorkflowsDocument, CountRecyclesWorkflowsDocument, ReadOnlyWorkflowsDocument, FindArchivedWorkflowsDocument, CountAllWorkflowsDocument, FindDynamicPropertyFieldsWorkflowsDocument, FindUnassociatedWorkflowActionsWorkflowsDocument, InsertWorkflowsDocument, UpdateWorkflowsDocument, ImportDataWorkflowsDocument, UpdateManyWorkflowsDocument, DeleteWorkflowsDocument, SetEnableWorkflowsDocument, DuplicateWorflowWorkflowsDocument, TestAddHttpWorkflowWorkflowsDocument, RestoreWorkflowsDocument, RecycleWorkflowsDocument, RestoreArchivedWorkflowsDocument, ArchivedWorkflowsDocument, AddFileDynamicFieldWorkflowsDocument, RemoveFileDynamicFieldWorkflowsDocument, AddWorkflowActionsWorkflowsDocument, RemoveWorkflowActionsWorkflowsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflow, QueryContextOfWorkflow, FilterOfWorkflow, ServiceSingleResultOfInt64, ServiceListResultOfWorkflow, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfString, ServiceListResultOfWorkflowDelay, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfWorkflow, QueryContextOfWorkflowAction, FilterOfWorkflowAction, ServiceListResultOfWorkflowAction, WorkflowInput, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowBaseService {
    
public modelName = 'workflow';
public modelPluralName = 'workflows';

	private getWorkflowsQuery: GetWorkflowsDocument;
	private firstWorkflowsQuery: FirstWorkflowsDocument;
	private countWorkflowsQuery: CountWorkflowsDocument;
	private findWorkflowsQuery: FindWorkflowsDocument;
	private searchWorkflowsQuery: SearchWorkflowsDocument;
	private exportSchemaWorkflowsQuery: ExportSchemaWorkflowsDocument;
	private exportDataWorkflowsQuery: ExportDataWorkflowsDocument;
	private customQueryWorkflowsQuery: CustomQueryWorkflowsDocument;
	private customQueryIdWorkflowsQuery: CustomQueryIdWorkflowsDocument;
	private usedWorkflowsQuery: UsedWorkflowsDocument;
	private findActionsWorkflowsQuery: FindActionsWorkflowsDocument;
	private findDelayConfigByTypeWorkflowsQuery: FindDelayConfigByTypeWorkflowsDocument;
	private findWorkflowWorkflowsQuery: FindWorkflowWorkflowsDocument;
	private existWorkflowsQuery: ExistWorkflowsDocument;
	private findRecyclesWorkflowsQuery: FindRecyclesWorkflowsDocument;
	private countRecyclesWorkflowsQuery: CountRecyclesWorkflowsDocument;
	private readOnlyWorkflowsQuery: ReadOnlyWorkflowsDocument;
	private findArchivedWorkflowsQuery: FindArchivedWorkflowsDocument;
	private countAllWorkflowsQuery: CountAllWorkflowsDocument;
	private findDynamicPropertyFieldsWorkflowsQuery: FindDynamicPropertyFieldsWorkflowsDocument;
	private findUnassociatedWorkflowActionsWorkflowsQuery: FindUnassociatedWorkflowActionsWorkflowsDocument;
	private insertWorkflowsMutation: InsertWorkflowsDocument;
	private updateWorkflowsMutation: UpdateWorkflowsDocument;
	private importDataWorkflowsMutation: ImportDataWorkflowsDocument;
	private updateManyWorkflowsMutation: UpdateManyWorkflowsDocument;
	private deleteWorkflowsMutation: DeleteWorkflowsDocument;
	private setEnableWorkflowsMutation: SetEnableWorkflowsDocument;
	private duplicateWorflowWorkflowsMutation: DuplicateWorflowWorkflowsDocument;
	private testAddHttpWorkflowWorkflowsMutation: TestAddHttpWorkflowWorkflowsDocument;
	private restoreWorkflowsMutation: RestoreWorkflowsDocument;
	private recycleWorkflowsMutation: RecycleWorkflowsDocument;
	private restoreArchivedWorkflowsMutation: RestoreArchivedWorkflowsDocument;
	private archivedWorkflowsMutation: ArchivedWorkflowsDocument;
	private addFileDynamicFieldWorkflowsMutation: AddFileDynamicFieldWorkflowsDocument;
	private removeFileDynamicFieldWorkflowsMutation: RemoveFileDynamicFieldWorkflowsDocument;
	private addWorkflowActionsWorkflowsMutation: AddWorkflowActionsWorkflowsDocument;
	private removeWorkflowActionsWorkflowsMutation: RemoveWorkflowActionsWorkflowsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowsQuery = this.injector.get(GetWorkflowsDocument);
		this.firstWorkflowsQuery = this.injector.get(FirstWorkflowsDocument);
		this.countWorkflowsQuery = this.injector.get(CountWorkflowsDocument);
		this.findWorkflowsQuery = this.injector.get(FindWorkflowsDocument);
		this.searchWorkflowsQuery = this.injector.get(SearchWorkflowsDocument);
		this.exportSchemaWorkflowsQuery = this.injector.get(ExportSchemaWorkflowsDocument);
		this.exportDataWorkflowsQuery = this.injector.get(ExportDataWorkflowsDocument);
		this.customQueryWorkflowsQuery = this.injector.get(CustomQueryWorkflowsDocument);
		this.customQueryIdWorkflowsQuery = this.injector.get(CustomQueryIdWorkflowsDocument);
		this.usedWorkflowsQuery = this.injector.get(UsedWorkflowsDocument);
		this.findActionsWorkflowsQuery = this.injector.get(FindActionsWorkflowsDocument);
		this.findDelayConfigByTypeWorkflowsQuery = this.injector.get(FindDelayConfigByTypeWorkflowsDocument);
		this.findWorkflowWorkflowsQuery = this.injector.get(FindWorkflowWorkflowsDocument);
		this.existWorkflowsQuery = this.injector.get(ExistWorkflowsDocument);
		this.findRecyclesWorkflowsQuery = this.injector.get(FindRecyclesWorkflowsDocument);
		this.countRecyclesWorkflowsQuery = this.injector.get(CountRecyclesWorkflowsDocument);
		this.readOnlyWorkflowsQuery = this.injector.get(ReadOnlyWorkflowsDocument);
		this.findArchivedWorkflowsQuery = this.injector.get(FindArchivedWorkflowsDocument);
		this.countAllWorkflowsQuery = this.injector.get(CountAllWorkflowsDocument);
		this.findDynamicPropertyFieldsWorkflowsQuery = this.injector.get(FindDynamicPropertyFieldsWorkflowsDocument);
		this.findUnassociatedWorkflowActionsWorkflowsQuery = this.injector.get(FindUnassociatedWorkflowActionsWorkflowsDocument);
		this.insertWorkflowsMutation = this.injector.get(InsertWorkflowsDocument);
		this.updateWorkflowsMutation = this.injector.get(UpdateWorkflowsDocument);
		this.importDataWorkflowsMutation = this.injector.get(ImportDataWorkflowsDocument);
		this.updateManyWorkflowsMutation = this.injector.get(UpdateManyWorkflowsDocument);
		this.deleteWorkflowsMutation = this.injector.get(DeleteWorkflowsDocument);
		this.setEnableWorkflowsMutation = this.injector.get(SetEnableWorkflowsDocument);
		this.duplicateWorflowWorkflowsMutation = this.injector.get(DuplicateWorflowWorkflowsDocument);
		this.testAddHttpWorkflowWorkflowsMutation = this.injector.get(TestAddHttpWorkflowWorkflowsDocument);
		this.restoreWorkflowsMutation = this.injector.get(RestoreWorkflowsDocument);
		this.recycleWorkflowsMutation = this.injector.get(RecycleWorkflowsDocument);
		this.restoreArchivedWorkflowsMutation = this.injector.get(RestoreArchivedWorkflowsDocument);
		this.archivedWorkflowsMutation = this.injector.get(ArchivedWorkflowsDocument);
		this.addFileDynamicFieldWorkflowsMutation = this.injector.get(AddFileDynamicFieldWorkflowsDocument);
		this.removeFileDynamicFieldWorkflowsMutation = this.injector.get(RemoveFileDynamicFieldWorkflowsDocument);
		this.addWorkflowActionsWorkflowsMutation = this.injector.get(AddWorkflowActionsWorkflowsDocument);
		this.removeWorkflowActionsWorkflowsMutation = this.injector.get(RemoveWorkflowActionsWorkflowsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflow> {
    
    		let variables: GetWorkflowsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflow={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflow> {
    
    		let variables: FirstWorkflowsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: FindWorkflowsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: SearchWorkflowsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: CustomQueryWorkflowsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: CustomQueryIdWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.used));
    	}

    	/**  */
    	@InjectArgs
    	public findActions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfString> {
    
    		let variables: FindActionsWorkflowsBaseVariables = {
    
    		}
    				return this.findActionsWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findActions));
    	}

    	/**  */
    	@InjectArgs
    	public findDelayConfigByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowDelay> {
    
    		let variables: FindDelayConfigByTypeWorkflowsBaseVariables = {
    			type: type
    		}
    				return this.findDelayConfigByTypeWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findDelayConfigByType));
    	}

    	/**  */
    	@InjectArgs
    	public findWorkflow(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('workflowList?') workflowList?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: FindWorkflowWorkflowsBaseVariables = {
    			workflowList: workflowList,
			filter: filter,
			options: options
    		}
    				return this.findWorkflowWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findWorkflow));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: FindRecyclesWorkflowsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyWorkflowsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflow,
		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflow> {
    
    		let variables: FindArchivedWorkflowsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllWorkflowsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsWorkflowsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedWorkflowActions(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfWorkflowAction,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfWorkflowAction> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('workflowActions', fields, null, [
			GqlSubFieldArg.create('filterOfWorkflowActions', 'filter'),
			GqlSubFieldArg.create('optionsOfWorkflowActions', 'options'),
		]),
		])
		extendedVariables['filterOfWorkflowActions'] = filter;
		extendedVariables['optionsOfWorkflowActions'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.workflowActions));
            }
            else{
                let result: ServiceListResultOfWorkflowAction = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedWorkflowActions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowAction> {
    
    		let variables: FindUnassociatedWorkflowActionsWorkflowsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedWorkflowActionsWorkflowsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.findUnassociatedWorkflowActions));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflow> {
    
    		let variables: InsertWorkflowsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflow> {
    
    		let variables: UpdateWorkflowsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflow,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.delete));
    	}

    	/** Permet d'activé ou désactivé l'entité. */
    	@InjectArgs
    	public setEnable(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('state') state: boolean,
		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SetEnableWorkflowsBaseVariables = {
    			ids: ids,
			state: state
    		}
    				return this.setEnableWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.setEnable));
    	}

    	/**  */
    	@InjectArgs
    	public duplicateWorflow(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('workflowId') workflowId: string,
		@Args('type?') type?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateWorflowWorkflowsBaseVariables = {
    			workflowId: workflowId,
			type: type
    		}
    				return this.duplicateWorflowWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.duplicateWorflow));
    	}

    	/**  */
    	@InjectArgs
    	public testAddHttpWorkflow(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: TestAddHttpWorkflowWorkflowsBaseVariables = {
    
    		}
    				return this.testAddHttpWorkflowWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.testAddHttpWorkflow));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedWorkflowsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldWorkflowsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldWorkflowsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addWorkflowActions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('workflowActionIds') workflowActionIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddWorkflowActionsWorkflowsBaseVariables = {
    			id: id,
			workflowActionIds: workflowActionIds
    		}
    				return this.addWorkflowActionsWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.addWorkflowActions));
    	}

    	/**  */
    	@InjectArgs
    	public removeWorkflowActions(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('workflowActionIds') workflowActionIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveWorkflowActionsWorkflowsBaseVariables = {
    			workflowActionIds: workflowActionIds
    		}
    				return this.removeWorkflowActionsWorkflowsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflows.removeWorkflowActions));
    	}
    
}