import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowInvitationsBaseVariables, FirstWorkflowInvitationsBaseVariables, CountWorkflowInvitationsBaseVariables, FindWorkflowInvitationsBaseVariables, SearchWorkflowInvitationsBaseVariables, ExportSchemaWorkflowInvitationsBaseVariables, ExportDataWorkflowInvitationsBaseVariables, CustomQueryWorkflowInvitationsBaseVariables, CustomQueryIdWorkflowInvitationsBaseVariables, UsedWorkflowInvitationsBaseVariables, ExistWorkflowInvitationsBaseVariables, InsertWorkflowInvitationsBaseVariables, UpdateWorkflowInvitationsBaseVariables, ImportDataWorkflowInvitationsBaseVariables, UpdateManyWorkflowInvitationsBaseVariables, DeleteWorkflowInvitationsBaseVariables } from '../gqls'
import { GetWorkflowInvitationsDocument, FirstWorkflowInvitationsDocument, CountWorkflowInvitationsDocument, FindWorkflowInvitationsDocument, SearchWorkflowInvitationsDocument, ExportSchemaWorkflowInvitationsDocument, ExportDataWorkflowInvitationsDocument, CustomQueryWorkflowInvitationsDocument, CustomQueryIdWorkflowInvitationsDocument, UsedWorkflowInvitationsDocument, ExistWorkflowInvitationsDocument, InsertWorkflowInvitationsDocument, UpdateWorkflowInvitationsDocument, ImportDataWorkflowInvitationsDocument, UpdateManyWorkflowInvitationsDocument, DeleteWorkflowInvitationsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowInvitation, QueryContextOfWorkflowInvitation, FilterOfWorkflowInvitation, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowInvitation, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowInvitationInput, FieldUpdateOperatorOfWorkflowInvitation } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowInvitationBaseService {
    
public modelName = 'workflowInvitation';
public modelPluralName = 'workflowInvitations';

	private getWorkflowInvitationsQuery: GetWorkflowInvitationsDocument;
	private firstWorkflowInvitationsQuery: FirstWorkflowInvitationsDocument;
	private countWorkflowInvitationsQuery: CountWorkflowInvitationsDocument;
	private findWorkflowInvitationsQuery: FindWorkflowInvitationsDocument;
	private searchWorkflowInvitationsQuery: SearchWorkflowInvitationsDocument;
	private exportSchemaWorkflowInvitationsQuery: ExportSchemaWorkflowInvitationsDocument;
	private exportDataWorkflowInvitationsQuery: ExportDataWorkflowInvitationsDocument;
	private customQueryWorkflowInvitationsQuery: CustomQueryWorkflowInvitationsDocument;
	private customQueryIdWorkflowInvitationsQuery: CustomQueryIdWorkflowInvitationsDocument;
	private usedWorkflowInvitationsQuery: UsedWorkflowInvitationsDocument;
	private existWorkflowInvitationsQuery: ExistWorkflowInvitationsDocument;
	private insertWorkflowInvitationsMutation: InsertWorkflowInvitationsDocument;
	private updateWorkflowInvitationsMutation: UpdateWorkflowInvitationsDocument;
	private importDataWorkflowInvitationsMutation: ImportDataWorkflowInvitationsDocument;
	private updateManyWorkflowInvitationsMutation: UpdateManyWorkflowInvitationsDocument;
	private deleteWorkflowInvitationsMutation: DeleteWorkflowInvitationsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowInvitationsQuery = this.injector.get(GetWorkflowInvitationsDocument);
		this.firstWorkflowInvitationsQuery = this.injector.get(FirstWorkflowInvitationsDocument);
		this.countWorkflowInvitationsQuery = this.injector.get(CountWorkflowInvitationsDocument);
		this.findWorkflowInvitationsQuery = this.injector.get(FindWorkflowInvitationsDocument);
		this.searchWorkflowInvitationsQuery = this.injector.get(SearchWorkflowInvitationsDocument);
		this.exportSchemaWorkflowInvitationsQuery = this.injector.get(ExportSchemaWorkflowInvitationsDocument);
		this.exportDataWorkflowInvitationsQuery = this.injector.get(ExportDataWorkflowInvitationsDocument);
		this.customQueryWorkflowInvitationsQuery = this.injector.get(CustomQueryWorkflowInvitationsDocument);
		this.customQueryIdWorkflowInvitationsQuery = this.injector.get(CustomQueryIdWorkflowInvitationsDocument);
		this.usedWorkflowInvitationsQuery = this.injector.get(UsedWorkflowInvitationsDocument);
		this.existWorkflowInvitationsQuery = this.injector.get(ExistWorkflowInvitationsDocument);
		this.insertWorkflowInvitationsMutation = this.injector.get(InsertWorkflowInvitationsDocument);
		this.updateWorkflowInvitationsMutation = this.injector.get(UpdateWorkflowInvitationsDocument);
		this.importDataWorkflowInvitationsMutation = this.injector.get(ImportDataWorkflowInvitationsDocument);
		this.updateManyWorkflowInvitationsMutation = this.injector.get(UpdateManyWorkflowInvitationsDocument);
		this.deleteWorkflowInvitationsMutation = this.injector.get(DeleteWorkflowInvitationsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitation> {
    
    		let variables: GetWorkflowInvitationsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowInvitation={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowInvitation,
		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitation> {
    
    		let variables: FirstWorkflowInvitationsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowInvitationsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowInvitation,
		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitation> {
    
    		let variables: FindWorkflowInvitationsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowInvitation,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitation> {
    
    		let variables: SearchWorkflowInvitationsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowInvitationsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowInvitationsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowInvitation,
		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitation> {
    
    		let variables: CustomQueryWorkflowInvitationsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowInvitation,
		@Args('filter?') filter?: FilterOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowInvitation> {
    
    		let variables: CustomQueryIdWorkflowInvitationsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowInvitationsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowInvitationsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowInvitationsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WorkflowInvitationInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitation> {
    
    		let variables: InsertWorkflowInvitationsBaseVariables = {
    			entity: entity
    		}
    				return this.insertWorkflowInvitationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowInvitation> {
    
    		let variables: UpdateWorkflowInvitationsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWorkflowInvitationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWorkflowInvitationsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWorkflowInvitationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowInvitation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowInvitationsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowInvitationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowInvitationsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowInvitationsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowInvitations.delete));
    	}
    
}