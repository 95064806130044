import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { ModelCompilerContextService } from '@clarilog/shared2/services/compiler/model-compiler-context.service';
import { AssetCoreService } from './asset.service';

@Authorize('help-desk-user.my-asset-desk-user')
@Injectable({ providedIn: 'root' })
export class AssetHelpDeskCoreService extends AssetCoreService {
  constructor(      
    injector: Injector,
    modelCompilerContextService: ModelCompilerContextService,
    translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector,modelCompilerContextService,translatedFieldHelperService);
  }   
}