import { Injectable } from '@angular/core';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
/** Permet de recupérer les variable d'environment  */
export class EnvironmentService {
  private browserWindow = window || {};
  private browserWindowEnv = this.browserWindow['__env'] || {};
  constructor() {}

  private getEnvPropertyValue(key: string): any {
    if (this.browserWindowEnv.hasOwnProperty(key)) {
      return window['__env'][key];
    } else {
      return environment[key];
    }
  }

  public get apiURL(): string {
    return this.getEnvPropertyValue('apiURL');
  }

  public get identityURL(): string {
    return this.getEnvPropertyValue('identityURL');
  }

  public get baseURL(): string {
    return this.getEnvPropertyValue('baseURL');
  }
  public get requireHttps(): boolean {
    return this.getEnvPropertyValue('requireHttps');
  }
  public get isProduction(): boolean {
    return this.getEnvPropertyValue('production');
  }
  public get isDev(): boolean {
    return this.getEnvPropertyValue('dev');
  }
}
