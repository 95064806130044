import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBudgetCategoriesBaseVariables, FirstBudgetCategoriesBaseVariables, CountBudgetCategoriesBaseVariables, FindBudgetCategoriesBaseVariables, SearchBudgetCategoriesBaseVariables, ExportSchemaBudgetCategoriesBaseVariables, ExportDataBudgetCategoriesBaseVariables, CustomQueryBudgetCategoriesBaseVariables, CustomQueryIdBudgetCategoriesBaseVariables, UsedBudgetCategoriesBaseVariables, ExistBudgetCategoriesBaseVariables, FindRecyclesBudgetCategoriesBaseVariables, CountRecyclesBudgetCategoriesBaseVariables, ReadOnlyBudgetCategoriesBaseVariables, FindArchivedBudgetCategoriesBaseVariables, CountAllBudgetCategoriesBaseVariables, FindDynamicPropertyFieldsBudgetCategoriesBaseVariables, InsertBudgetCategoriesBaseVariables, UpdateBudgetCategoriesBaseVariables, ImportDataBudgetCategoriesBaseVariables, UpdateManyBudgetCategoriesBaseVariables, DeleteBudgetCategoriesBaseVariables, RestoreBudgetCategoriesBaseVariables, RecycleBudgetCategoriesBaseVariables, RestoreArchivedBudgetCategoriesBaseVariables, ArchivedBudgetCategoriesBaseVariables, AddFileDynamicFieldBudgetCategoriesBaseVariables, RemoveFileDynamicFieldBudgetCategoriesBaseVariables } from '../gqls'
import { GetBudgetCategoriesDocument, FirstBudgetCategoriesDocument, CountBudgetCategoriesDocument, FindBudgetCategoriesDocument, SearchBudgetCategoriesDocument, ExportSchemaBudgetCategoriesDocument, ExportDataBudgetCategoriesDocument, CustomQueryBudgetCategoriesDocument, CustomQueryIdBudgetCategoriesDocument, UsedBudgetCategoriesDocument, ExistBudgetCategoriesDocument, FindRecyclesBudgetCategoriesDocument, CountRecyclesBudgetCategoriesDocument, ReadOnlyBudgetCategoriesDocument, FindArchivedBudgetCategoriesDocument, CountAllBudgetCategoriesDocument, FindDynamicPropertyFieldsBudgetCategoriesDocument, InsertBudgetCategoriesDocument, UpdateBudgetCategoriesDocument, ImportDataBudgetCategoriesDocument, UpdateManyBudgetCategoriesDocument, DeleteBudgetCategoriesDocument, RestoreBudgetCategoriesDocument, RecycleBudgetCategoriesDocument, RestoreArchivedBudgetCategoriesDocument, ArchivedBudgetCategoriesDocument, AddFileDynamicFieldBudgetCategoriesDocument, RemoveFileDynamicFieldBudgetCategoriesDocument } from '../gqls'
import { ServiceSingleResultOfBudgetCategory, QueryContextOfBudgetCategory, FilterOfBudgetCategory, ServiceSingleResultOfInt64, ServiceListResultOfBudgetCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfBudgetCategory, BudgetCategoryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BudgetCategoryBaseService {
    
public modelName = 'budgetCategory';
public modelPluralName = 'budgetCategories';

	private getBudgetCategoriesQuery: GetBudgetCategoriesDocument;
	private firstBudgetCategoriesQuery: FirstBudgetCategoriesDocument;
	private countBudgetCategoriesQuery: CountBudgetCategoriesDocument;
	private findBudgetCategoriesQuery: FindBudgetCategoriesDocument;
	private searchBudgetCategoriesQuery: SearchBudgetCategoriesDocument;
	private exportSchemaBudgetCategoriesQuery: ExportSchemaBudgetCategoriesDocument;
	private exportDataBudgetCategoriesQuery: ExportDataBudgetCategoriesDocument;
	private customQueryBudgetCategoriesQuery: CustomQueryBudgetCategoriesDocument;
	private customQueryIdBudgetCategoriesQuery: CustomQueryIdBudgetCategoriesDocument;
	private usedBudgetCategoriesQuery: UsedBudgetCategoriesDocument;
	private existBudgetCategoriesQuery: ExistBudgetCategoriesDocument;
	private findRecyclesBudgetCategoriesQuery: FindRecyclesBudgetCategoriesDocument;
	private countRecyclesBudgetCategoriesQuery: CountRecyclesBudgetCategoriesDocument;
	private readOnlyBudgetCategoriesQuery: ReadOnlyBudgetCategoriesDocument;
	private findArchivedBudgetCategoriesQuery: FindArchivedBudgetCategoriesDocument;
	private countAllBudgetCategoriesQuery: CountAllBudgetCategoriesDocument;
	private findDynamicPropertyFieldsBudgetCategoriesQuery: FindDynamicPropertyFieldsBudgetCategoriesDocument;
	private insertBudgetCategoriesMutation: InsertBudgetCategoriesDocument;
	private updateBudgetCategoriesMutation: UpdateBudgetCategoriesDocument;
	private importDataBudgetCategoriesMutation: ImportDataBudgetCategoriesDocument;
	private updateManyBudgetCategoriesMutation: UpdateManyBudgetCategoriesDocument;
	private deleteBudgetCategoriesMutation: DeleteBudgetCategoriesDocument;
	private restoreBudgetCategoriesMutation: RestoreBudgetCategoriesDocument;
	private recycleBudgetCategoriesMutation: RecycleBudgetCategoriesDocument;
	private restoreArchivedBudgetCategoriesMutation: RestoreArchivedBudgetCategoriesDocument;
	private archivedBudgetCategoriesMutation: ArchivedBudgetCategoriesDocument;
	private addFileDynamicFieldBudgetCategoriesMutation: AddFileDynamicFieldBudgetCategoriesDocument;
	private removeFileDynamicFieldBudgetCategoriesMutation: RemoveFileDynamicFieldBudgetCategoriesDocument;

	constructor(private injector: Injector) {
		this.getBudgetCategoriesQuery = this.injector.get(GetBudgetCategoriesDocument);
		this.firstBudgetCategoriesQuery = this.injector.get(FirstBudgetCategoriesDocument);
		this.countBudgetCategoriesQuery = this.injector.get(CountBudgetCategoriesDocument);
		this.findBudgetCategoriesQuery = this.injector.get(FindBudgetCategoriesDocument);
		this.searchBudgetCategoriesQuery = this.injector.get(SearchBudgetCategoriesDocument);
		this.exportSchemaBudgetCategoriesQuery = this.injector.get(ExportSchemaBudgetCategoriesDocument);
		this.exportDataBudgetCategoriesQuery = this.injector.get(ExportDataBudgetCategoriesDocument);
		this.customQueryBudgetCategoriesQuery = this.injector.get(CustomQueryBudgetCategoriesDocument);
		this.customQueryIdBudgetCategoriesQuery = this.injector.get(CustomQueryIdBudgetCategoriesDocument);
		this.usedBudgetCategoriesQuery = this.injector.get(UsedBudgetCategoriesDocument);
		this.existBudgetCategoriesQuery = this.injector.get(ExistBudgetCategoriesDocument);
		this.findRecyclesBudgetCategoriesQuery = this.injector.get(FindRecyclesBudgetCategoriesDocument);
		this.countRecyclesBudgetCategoriesQuery = this.injector.get(CountRecyclesBudgetCategoriesDocument);
		this.readOnlyBudgetCategoriesQuery = this.injector.get(ReadOnlyBudgetCategoriesDocument);
		this.findArchivedBudgetCategoriesQuery = this.injector.get(FindArchivedBudgetCategoriesDocument);
		this.countAllBudgetCategoriesQuery = this.injector.get(CountAllBudgetCategoriesDocument);
		this.findDynamicPropertyFieldsBudgetCategoriesQuery = this.injector.get(FindDynamicPropertyFieldsBudgetCategoriesDocument);
		this.insertBudgetCategoriesMutation = this.injector.get(InsertBudgetCategoriesDocument);
		this.updateBudgetCategoriesMutation = this.injector.get(UpdateBudgetCategoriesDocument);
		this.importDataBudgetCategoriesMutation = this.injector.get(ImportDataBudgetCategoriesDocument);
		this.updateManyBudgetCategoriesMutation = this.injector.get(UpdateManyBudgetCategoriesDocument);
		this.deleteBudgetCategoriesMutation = this.injector.get(DeleteBudgetCategoriesDocument);
		this.restoreBudgetCategoriesMutation = this.injector.get(RestoreBudgetCategoriesDocument);
		this.recycleBudgetCategoriesMutation = this.injector.get(RecycleBudgetCategoriesDocument);
		this.restoreArchivedBudgetCategoriesMutation = this.injector.get(RestoreArchivedBudgetCategoriesDocument);
		this.archivedBudgetCategoriesMutation = this.injector.get(ArchivedBudgetCategoriesDocument);
		this.addFileDynamicFieldBudgetCategoriesMutation = this.injector.get(AddFileDynamicFieldBudgetCategoriesDocument);
		this.removeFileDynamicFieldBudgetCategoriesMutation = this.injector.get(RemoveFileDynamicFieldBudgetCategoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBudgetCategory> {
    
    		let variables: GetBudgetCategoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.get));
            }
            else{
                let result:ServiceSingleResultOfBudgetCategory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBudgetCategory> {
    
    		let variables: FirstBudgetCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBudgetCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: FindBudgetCategoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: SearchBudgetCategoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBudgetCategoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBudgetCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: CustomQueryBudgetCategoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: CustomQueryIdBudgetCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBudgetCategoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: FindRecyclesBudgetCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesBudgetCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyBudgetCategoriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBudgetCategory,
		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBudgetCategory> {
    
    		let variables: FindArchivedBudgetCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllBudgetCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsBudgetCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsBudgetCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: BudgetCategoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBudgetCategory> {
    
    		let variables: InsertBudgetCategoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBudgetCategory> {
    
    		let variables: UpdateBudgetCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataBudgetCategoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfBudgetCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyBudgetCategoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedBudgetCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldBudgetCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldBudgetCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldBudgetCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.budgetCategories.removeFileDynamicField));
    	}
    
}