import { SoftwareBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  GetTypeFieldsService,
  FieldGetTypeFields,
} from '../../get-fields.service';
import { Observable } from 'rxjs';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlFields, GqlSubField } from '../helpers';

@Injectable({ providedIn: 'root' })
@Authorize('software')
export class SoftwareCoreService extends SoftwareBaseService {
  constructor(injector: Injector, private getFieldType: GetTypeFieldsService) {
    super(injector);
  }

  public getTypeFields(): Observable<FieldGetTypeFields[]> {
    return this.getFieldType.getTypeFields('softwares');
  }

  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('softwareCategory'),
        GqlField.create('name'),
        GqlField.create('id'),
      ]),
      GqlField.create('totalCount'),
    ];
  }
}
