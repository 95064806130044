import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPortOpensBaseVariables, FirstPortOpensBaseVariables, CountPortOpensBaseVariables, FindPortOpensBaseVariables, SearchPortOpensBaseVariables, ExportSchemaPortOpensBaseVariables, ExportDataPortOpensBaseVariables, CustomQueryPortOpensBaseVariables, CustomQueryIdPortOpensBaseVariables, UsedPortOpensBaseVariables, ExistPortOpensBaseVariables } from '../gqls'
import { GetPortOpensDocument, FirstPortOpensDocument, CountPortOpensDocument, FindPortOpensDocument, SearchPortOpensDocument, ExportSchemaPortOpensDocument, ExportDataPortOpensDocument, CustomQueryPortOpensDocument, CustomQueryIdPortOpensDocument, UsedPortOpensDocument, ExistPortOpensDocument } from '../gqls'
import { ServiceSingleResultOfPortOpen, QueryContextOfPortOpen, FilterOfPortOpen, ServiceSingleResultOfInt64, ServiceListResultOfPortOpen, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PortOpenBaseService {
    
public modelName = 'portOpen';
public modelPluralName = 'portOpens';

	private getPortOpensQuery: GetPortOpensDocument;
	private firstPortOpensQuery: FirstPortOpensDocument;
	private countPortOpensQuery: CountPortOpensDocument;
	private findPortOpensQuery: FindPortOpensDocument;
	private searchPortOpensQuery: SearchPortOpensDocument;
	private exportSchemaPortOpensQuery: ExportSchemaPortOpensDocument;
	private exportDataPortOpensQuery: ExportDataPortOpensDocument;
	private customQueryPortOpensQuery: CustomQueryPortOpensDocument;
	private customQueryIdPortOpensQuery: CustomQueryIdPortOpensDocument;
	private usedPortOpensQuery: UsedPortOpensDocument;
	private existPortOpensQuery: ExistPortOpensDocument;

	constructor(private injector: Injector) {
		this.getPortOpensQuery = this.injector.get(GetPortOpensDocument);
		this.firstPortOpensQuery = this.injector.get(FirstPortOpensDocument);
		this.countPortOpensQuery = this.injector.get(CountPortOpensDocument);
		this.findPortOpensQuery = this.injector.get(FindPortOpensDocument);
		this.searchPortOpensQuery = this.injector.get(SearchPortOpensDocument);
		this.exportSchemaPortOpensQuery = this.injector.get(ExportSchemaPortOpensDocument);
		this.exportDataPortOpensQuery = this.injector.get(ExportDataPortOpensDocument);
		this.customQueryPortOpensQuery = this.injector.get(CustomQueryPortOpensDocument);
		this.customQueryIdPortOpensQuery = this.injector.get(CustomQueryIdPortOpensDocument);
		this.usedPortOpensQuery = this.injector.get(UsedPortOpensDocument);
		this.existPortOpensQuery = this.injector.get(ExistPortOpensDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortOpen> {
    
    		let variables: GetPortOpensBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.get));
            }
            else{
                let result:ServiceSingleResultOfPortOpen={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortOpen,
		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortOpen> {
    
    		let variables: FirstPortOpensBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPortOpensBaseVariables = {
    			filter: filter
    		}
    				return this.countPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortOpen,
		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortOpen> {
    
    		let variables: FindPortOpensBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPortOpen,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortOpen> {
    
    		let variables: SearchPortOpensBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPortOpensBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPortOpensBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPortOpen,
		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortOpen> {
    
    		let variables: CustomQueryPortOpensBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPortOpen,
		@Args('filter?') filter?: FilterOfPortOpen,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortOpen> {
    
    		let variables: CustomQueryIdPortOpensBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPortOpensBaseVariables = {
    			ids: ids
    		}
    				return this.usedPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPortOpensBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPortOpensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portOpens.exist));
    	}
    
    
}