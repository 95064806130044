import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAssetProvisionalCommissioningsBaseVariables, FirstAssetProvisionalCommissioningsBaseVariables, CountAssetProvisionalCommissioningsBaseVariables, FindAssetProvisionalCommissioningsBaseVariables, SearchAssetProvisionalCommissioningsBaseVariables, ExportSchemaAssetProvisionalCommissioningsBaseVariables, ExportDataAssetProvisionalCommissioningsBaseVariables, CustomQueryAssetProvisionalCommissioningsBaseVariables, CustomQueryIdAssetProvisionalCommissioningsBaseVariables, UsedAssetProvisionalCommissioningsBaseVariables, ExistAssetProvisionalCommissioningsBaseVariables, FindRecyclesAssetProvisionalCommissioningsBaseVariables, CountRecyclesAssetProvisionalCommissioningsBaseVariables, ReadOnlyAssetProvisionalCommissioningsBaseVariables, FindArchivedAssetProvisionalCommissioningsBaseVariables, CountAllAssetProvisionalCommissioningsBaseVariables, FindDynamicPropertyFieldsAssetProvisionalCommissioningsBaseVariables, InsertAssetProvisionalCommissioningsBaseVariables, UpdateAssetProvisionalCommissioningsBaseVariables, ImportDataAssetProvisionalCommissioningsBaseVariables, UpdateManyAssetProvisionalCommissioningsBaseVariables, DeleteAssetProvisionalCommissioningsBaseVariables, RestoreAssetProvisionalCommissioningsBaseVariables, RecycleAssetProvisionalCommissioningsBaseVariables, RestoreArchivedAssetProvisionalCommissioningsBaseVariables, ArchivedAssetProvisionalCommissioningsBaseVariables, AddFileDynamicFieldAssetProvisionalCommissioningsBaseVariables, RemoveFileDynamicFieldAssetProvisionalCommissioningsBaseVariables } from '../gqls'
import { GetAssetProvisionalCommissioningsDocument, FirstAssetProvisionalCommissioningsDocument, CountAssetProvisionalCommissioningsDocument, FindAssetProvisionalCommissioningsDocument, SearchAssetProvisionalCommissioningsDocument, ExportSchemaAssetProvisionalCommissioningsDocument, ExportDataAssetProvisionalCommissioningsDocument, CustomQueryAssetProvisionalCommissioningsDocument, CustomQueryIdAssetProvisionalCommissioningsDocument, UsedAssetProvisionalCommissioningsDocument, ExistAssetProvisionalCommissioningsDocument, FindRecyclesAssetProvisionalCommissioningsDocument, CountRecyclesAssetProvisionalCommissioningsDocument, ReadOnlyAssetProvisionalCommissioningsDocument, FindArchivedAssetProvisionalCommissioningsDocument, CountAllAssetProvisionalCommissioningsDocument, FindDynamicPropertyFieldsAssetProvisionalCommissioningsDocument, InsertAssetProvisionalCommissioningsDocument, UpdateAssetProvisionalCommissioningsDocument, ImportDataAssetProvisionalCommissioningsDocument, UpdateManyAssetProvisionalCommissioningsDocument, DeleteAssetProvisionalCommissioningsDocument, RestoreAssetProvisionalCommissioningsDocument, RecycleAssetProvisionalCommissioningsDocument, RestoreArchivedAssetProvisionalCommissioningsDocument, ArchivedAssetProvisionalCommissioningsDocument, AddFileDynamicFieldAssetProvisionalCommissioningsDocument, RemoveFileDynamicFieldAssetProvisionalCommissioningsDocument } from '../gqls'
import { ServiceSingleResultOfAssetProvisionalCommissioning, QueryContextOfAssetProvisionalCommissioning, FilterOfAssetProvisionalCommissioning, ServiceSingleResultOfInt64, ServiceListResultOfAssetProvisionalCommissioning, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAssetProvisionalCommissioning, AssetProvisionalCommissioningInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AssetProvisionalCommissioningBaseService {
    
public modelName = 'assetProvisionalCommissioning';
public modelPluralName = 'assetProvisionalCommissionings';

	private getAssetProvisionalCommissioningsQuery: GetAssetProvisionalCommissioningsDocument;
	private firstAssetProvisionalCommissioningsQuery: FirstAssetProvisionalCommissioningsDocument;
	private countAssetProvisionalCommissioningsQuery: CountAssetProvisionalCommissioningsDocument;
	private findAssetProvisionalCommissioningsQuery: FindAssetProvisionalCommissioningsDocument;
	private searchAssetProvisionalCommissioningsQuery: SearchAssetProvisionalCommissioningsDocument;
	private exportSchemaAssetProvisionalCommissioningsQuery: ExportSchemaAssetProvisionalCommissioningsDocument;
	private exportDataAssetProvisionalCommissioningsQuery: ExportDataAssetProvisionalCommissioningsDocument;
	private customQueryAssetProvisionalCommissioningsQuery: CustomQueryAssetProvisionalCommissioningsDocument;
	private customQueryIdAssetProvisionalCommissioningsQuery: CustomQueryIdAssetProvisionalCommissioningsDocument;
	private usedAssetProvisionalCommissioningsQuery: UsedAssetProvisionalCommissioningsDocument;
	private existAssetProvisionalCommissioningsQuery: ExistAssetProvisionalCommissioningsDocument;
	private findRecyclesAssetProvisionalCommissioningsQuery: FindRecyclesAssetProvisionalCommissioningsDocument;
	private countRecyclesAssetProvisionalCommissioningsQuery: CountRecyclesAssetProvisionalCommissioningsDocument;
	private readOnlyAssetProvisionalCommissioningsQuery: ReadOnlyAssetProvisionalCommissioningsDocument;
	private findArchivedAssetProvisionalCommissioningsQuery: FindArchivedAssetProvisionalCommissioningsDocument;
	private countAllAssetProvisionalCommissioningsQuery: CountAllAssetProvisionalCommissioningsDocument;
	private findDynamicPropertyFieldsAssetProvisionalCommissioningsQuery: FindDynamicPropertyFieldsAssetProvisionalCommissioningsDocument;
	private insertAssetProvisionalCommissioningsMutation: InsertAssetProvisionalCommissioningsDocument;
	private updateAssetProvisionalCommissioningsMutation: UpdateAssetProvisionalCommissioningsDocument;
	private importDataAssetProvisionalCommissioningsMutation: ImportDataAssetProvisionalCommissioningsDocument;
	private updateManyAssetProvisionalCommissioningsMutation: UpdateManyAssetProvisionalCommissioningsDocument;
	private deleteAssetProvisionalCommissioningsMutation: DeleteAssetProvisionalCommissioningsDocument;
	private restoreAssetProvisionalCommissioningsMutation: RestoreAssetProvisionalCommissioningsDocument;
	private recycleAssetProvisionalCommissioningsMutation: RecycleAssetProvisionalCommissioningsDocument;
	private restoreArchivedAssetProvisionalCommissioningsMutation: RestoreArchivedAssetProvisionalCommissioningsDocument;
	private archivedAssetProvisionalCommissioningsMutation: ArchivedAssetProvisionalCommissioningsDocument;
	private addFileDynamicFieldAssetProvisionalCommissioningsMutation: AddFileDynamicFieldAssetProvisionalCommissioningsDocument;
	private removeFileDynamicFieldAssetProvisionalCommissioningsMutation: RemoveFileDynamicFieldAssetProvisionalCommissioningsDocument;

	constructor(private injector: Injector) {
		this.getAssetProvisionalCommissioningsQuery = this.injector.get(GetAssetProvisionalCommissioningsDocument);
		this.firstAssetProvisionalCommissioningsQuery = this.injector.get(FirstAssetProvisionalCommissioningsDocument);
		this.countAssetProvisionalCommissioningsQuery = this.injector.get(CountAssetProvisionalCommissioningsDocument);
		this.findAssetProvisionalCommissioningsQuery = this.injector.get(FindAssetProvisionalCommissioningsDocument);
		this.searchAssetProvisionalCommissioningsQuery = this.injector.get(SearchAssetProvisionalCommissioningsDocument);
		this.exportSchemaAssetProvisionalCommissioningsQuery = this.injector.get(ExportSchemaAssetProvisionalCommissioningsDocument);
		this.exportDataAssetProvisionalCommissioningsQuery = this.injector.get(ExportDataAssetProvisionalCommissioningsDocument);
		this.customQueryAssetProvisionalCommissioningsQuery = this.injector.get(CustomQueryAssetProvisionalCommissioningsDocument);
		this.customQueryIdAssetProvisionalCommissioningsQuery = this.injector.get(CustomQueryIdAssetProvisionalCommissioningsDocument);
		this.usedAssetProvisionalCommissioningsQuery = this.injector.get(UsedAssetProvisionalCommissioningsDocument);
		this.existAssetProvisionalCommissioningsQuery = this.injector.get(ExistAssetProvisionalCommissioningsDocument);
		this.findRecyclesAssetProvisionalCommissioningsQuery = this.injector.get(FindRecyclesAssetProvisionalCommissioningsDocument);
		this.countRecyclesAssetProvisionalCommissioningsQuery = this.injector.get(CountRecyclesAssetProvisionalCommissioningsDocument);
		this.readOnlyAssetProvisionalCommissioningsQuery = this.injector.get(ReadOnlyAssetProvisionalCommissioningsDocument);
		this.findArchivedAssetProvisionalCommissioningsQuery = this.injector.get(FindArchivedAssetProvisionalCommissioningsDocument);
		this.countAllAssetProvisionalCommissioningsQuery = this.injector.get(CountAllAssetProvisionalCommissioningsDocument);
		this.findDynamicPropertyFieldsAssetProvisionalCommissioningsQuery = this.injector.get(FindDynamicPropertyFieldsAssetProvisionalCommissioningsDocument);
		this.insertAssetProvisionalCommissioningsMutation = this.injector.get(InsertAssetProvisionalCommissioningsDocument);
		this.updateAssetProvisionalCommissioningsMutation = this.injector.get(UpdateAssetProvisionalCommissioningsDocument);
		this.importDataAssetProvisionalCommissioningsMutation = this.injector.get(ImportDataAssetProvisionalCommissioningsDocument);
		this.updateManyAssetProvisionalCommissioningsMutation = this.injector.get(UpdateManyAssetProvisionalCommissioningsDocument);
		this.deleteAssetProvisionalCommissioningsMutation = this.injector.get(DeleteAssetProvisionalCommissioningsDocument);
		this.restoreAssetProvisionalCommissioningsMutation = this.injector.get(RestoreAssetProvisionalCommissioningsDocument);
		this.recycleAssetProvisionalCommissioningsMutation = this.injector.get(RecycleAssetProvisionalCommissioningsDocument);
		this.restoreArchivedAssetProvisionalCommissioningsMutation = this.injector.get(RestoreArchivedAssetProvisionalCommissioningsDocument);
		this.archivedAssetProvisionalCommissioningsMutation = this.injector.get(ArchivedAssetProvisionalCommissioningsDocument);
		this.addFileDynamicFieldAssetProvisionalCommissioningsMutation = this.injector.get(AddFileDynamicFieldAssetProvisionalCommissioningsDocument);
		this.removeFileDynamicFieldAssetProvisionalCommissioningsMutation = this.injector.get(RemoveFileDynamicFieldAssetProvisionalCommissioningsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetProvisionalCommissioning> {
    
    		let variables: GetAssetProvisionalCommissioningsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.get));
            }
            else{
                let result:ServiceSingleResultOfAssetProvisionalCommissioning={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetProvisionalCommissioning> {
    
    		let variables: FirstAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter
    		}
    				return this.countAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: FindAssetProvisionalCommissioningsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: SearchAssetProvisionalCommissioningsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAssetProvisionalCommissioningsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: CustomQueryAssetProvisionalCommissioningsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: CustomQueryIdAssetProvisionalCommissioningsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAssetProvisionalCommissioningsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: FindRecyclesAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAssetProvisionalCommissioningsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAssetProvisionalCommissioning,
		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetProvisionalCommissioning> {
    
    		let variables: FindArchivedAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAssetProvisionalCommissioningsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAssetProvisionalCommissioningsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAssetProvisionalCommissioningsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AssetProvisionalCommissioningInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetProvisionalCommissioning> {
    
    		let variables: InsertAssetProvisionalCommissioningsBaseVariables = {
    			entity: entity
    		}
    				return this.insertAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetProvisionalCommissioning> {
    
    		let variables: UpdateAssetProvisionalCommissioningsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAssetProvisionalCommissioningsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAssetProvisionalCommissioning,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAssetProvisionalCommissioningsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAssetProvisionalCommissioningsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAssetProvisionalCommissioningsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAssetProvisionalCommissioningsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.assetProvisionalCommissionings.removeFileDynamicField));
    	}
    
}