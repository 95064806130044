import { Observable } from 'rxjs';
import { ImportFileFormat, ServiceSingleResult } from '..';

/** Définis l'implementation de l'importation via fichier plat  */
export interface IImportable {
  /** Permet de télécharger le schéma de données des utilisateurs */
  importSchema(type: ImportFileFormat): Observable<ServiceSingleResult<string>>;
  /** Permet d'importer des utilisateurs via un fichier CSV */
  importData(
    type: ImportFileFormat,
    file: string,
  ): Observable<ServiceSingleResult<boolean>>;
}
