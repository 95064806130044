import { ActivityBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Args, InjectArgs } from '@clarilog/core/modules';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfActivity,
  QueryContextOfActivity,
  ServiceListResultOfActivity,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { Maybe } from 'graphql/jsutils/Maybe';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-activity')
export class ActivityCoreService extends ActivityBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findActivitiesWithSpecificFilter(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification?') qualification?: Array<Maybe<string>>,
    @Args('options?') options?: QueryContextOfActivity,
    @Args('filter?') filter?: FilterOfActivity,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfActivity> {
    let createDefault: FilterOfActivity = filter;
    if (filter != null) {
      if (qualification?.length == 1) {
        if (filter?.and != undefined) {
          let createAndTableFilter = [];

          filter?.and?.map((element, index) => {
            if (element?.qualification == undefined) {
              createAndTableFilter.push(element);
            }
          });
          if (createAndTableFilter.length > 1) {
            createDefault = { and: createAndTableFilter };
          } else if (
            createAndTableFilter.length == 1 &&
            createAndTableFilter.length > 0
          ) {
            createDefault = createAndTableFilter[0];
          }
        } else if (filter?.qualification == undefined) {
          createDefault = filter;
        }
      }
    }

    return this.findByQualification(
      fields,
      qualification,
      options,
      createDefault,
      extendedVariables,
    );
  }
}
