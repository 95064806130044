import {
  AfterContentInit,
  ChangeDetectorRef,
  Component,
  ContentChild,
  Input,
  OnDestroy,
  ViewChild,
} from '@angular/core';
import { DxToolbarComponent } from 'devextreme-angular';
import DevExpress from 'devextreme/bundles/dx.all';
import { Subscription } from 'rxjs';
import { startWith } from 'rxjs/operators';
import {
  ToolbarItemButtonComponent,
  ToolbarItemButtonGroupComponent,
  ToolbarItemComponent,
  ToolbarItemDropDownButtonComponent,
  ToolbarItemMenuButtonComponent,
  ToolbarItemWaitButtonComponent,
} from './toolbar-item/toolbar-item.component';
import { ToolbarItemsComponent } from './toolbar-items';
import { TemplatesService } from '@clarilog/shared2/components/templates/templates.service';

/** Représente les actions d'un toolbar. */
@Component({
  selector: 'cl-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent implements AfterContentInit, OnDestroy {
  @Input() items: DevExpress.ui.dxToolbarItem[] = [];
  @ViewChild(DxToolbarComponent, { static: true }) toolbar: DxToolbarComponent;
  @ContentChild(ToolbarItemsComponent, { static: true })
  toolbarItem: ToolbarItemsComponent;
  subscription: Subscription;

  /** Obtient ou définit l'affichage d'un bouton avec le load Panel actif */
  loadPanel: boolean = false;
  constructor(
    public templateService: TemplatesService,
    private _changeDetectorRef: ChangeDetectorRef,
  ) {}
  ngOnDestroy(): void {
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
  }
  /** @inheritdoc */
  ngAfterContentInit(): void {
    //this.toolbar.visible = false;
    // this.applyItems();
    this.subscription = this.toolbarItem.itemsChildren.changes
      .pipe(startWith(this.toolbarItem.itemsChildren.toArray()))
      .subscribe((items) => {
        this.applyItems();
        this._changeDetectorRef.detectChanges();
        //this.items.clear();
        // items.forEach(item => this.addItem(item));
        // setTimeout(() => {
        //   let items = this.toolbar.instance.option('items') as Array<any>;

        //   this.toolbar.instance.option('visible', items.filter(f => f.visible).length > 0);
        // });
      });
  }
  /** Permet de créer un item compatible pour le toolbar DevExtreme. */
  private createItem(item: ToolbarItemComponent): DevExpress.ui.dxToolbarItem {
    let subscriptionOptionChanged: Subscription;
    let unsubscribeOptionChanged = () => {
      if (subscriptionOptionChanged != undefined) {
        subscriptionOptionChanged.unsubscribe();
      }
    };
    let initialItem = {};

    Object.keys(item)
      .filter(
        (key) =>
          !key.startsWith('on') && key !== 'locateInMenu' && key !== 'location',
      )
      .map((key) => {
        if (key === '_displayExpr') {
          key = 'displayExpr';
        }
        if (item[key] != undefined) {
          initialItem[key === 'source' ? 'dataSource' : key] = item[key];
        }
      });
    let widget = this.getWidget(item);
    let widgetOptions: any = {};
    if (widget != undefined) {
      widgetOptions.widget = widget;
    } else {
      widgetOptions.text = item.text;
    }

    // Any pour l'ajout de la propriété : _instance
    return <any>{
      ...widgetOptions,
      _instance: item,
      location: item.location,
      widget: this.getWidget(item),
      locateInMenu: item.locateInMenu,
      showText: item['showText'] != undefined ? item['showText'] : 'always',
      disabled: item.disabled,
      visible: item.visible,
      menuItemTemplate: item.menuItemTemplate,
      cssClass: item.cssClass,
      options: {
        ...initialItem,

        onDisposing: (_) => {
          unsubscribeOptionChanged();
        },
        onInitialized: (e) => {
          const component = e.component;
          unsubscribeOptionChanged();

          subscriptionOptionChanged = item.onOptionChanged.subscribe(
            (e: { key: string; value: any }) => {
              component.option(
                e.key === 'source' ? 'dataSource' : e.key,
                e.value,
              );
              // Wrap pour le disabled et visible, pour que le menu soit mis à jour
              if (e.key === 'disabled' || e.key === 'visible') {
                const items = this.toolbar.instance.option(
                  'items',
                ) as Array<any>;
                const index = items.findIndex(
                  (value) => value._instance === item,
                );
                this.toolbar.instance.option(
                  `items[${index}].${e.key}`,
                  e.value,
                );
              }

              if (e.key === 'wait') {
                const items = this.toolbar.instance.option(
                  'items',
                ) as Array<any>;
                const index = items.findIndex(
                  (value) => value._instance === item,
                );

                this.toolbar.instance.option(
                  `items[${index}].options.disabled`,
                  e.value,
                );
                if (
                  items[index].options.showLoadPanel != undefined &&
                  items[index].options.showLoadPanel == true
                ) {
                  this.loadPanel = e.value && items[index].options;
                }
                if (e.value == true) {
                  items[index].originalIcon = items[index].options.icon;
                  this.toolbar.instance.option(
                    `items[${index}].options.icon`,
                    'fas fa-circle-notch fa-spin',
                  );
                } else {
                  this.toolbar.instance.option(
                    `items[${index}].options.icon`,
                    items[index].originalIcon,
                  );
                }
              }
            },
          );

          // Initialize le composent DevExtreme
          let options = component.option();

          // Masque ou affiche le composant si au moins 1 bouton est visible
          setTimeout(() => {
            let items = this.toolbar.instance.option('items') as Array<any>;

            this.toolbar.instance.option(
              'visible',
              items.filter((f) => f.visible).length > 0,
            );
          });

          Object.keys(options)
            .filter((key) => key !== 'onOptionChanged' && key.startsWith('on'))
            .map((key) => {
              if (item[key] != undefined) {
                component.option(key, (e) => {
                  item[key].emit(e);
                });
              }
            });
        },
        onClick: () => {},
      },
    };
  }
  /** Ajoute un item. */
  private addItem(item: ToolbarItemComponent) {
    let items = this.items.filter(
      (i) =>
        (item.text != undefined &&
          i.options.text == item.text &&
          item.multipleText == false &&
          i.options.multipleText == false) ||
        (item.text == undefined && i.options.icon == (<any>item).icon),
    );
    if (items.length === 0) {
      let newItem = this.createItem(item);
      this.items.push(newItem);
    }
  }
  /** Ajoute les éléments dans le toolbar DevExtreme. */
  private applyItems() {
    this.toolbarItem.itemsChildren.forEach((item) => {
      this.addItem(item);
    });
  }
  /** Permet de connaître le type du composant DevExtreme en fonction du type de l'objet.  */
  private getWidget(item: ToolbarItemComponent) {
    switch (true) {
      case item instanceof ToolbarItemButtonComponent:
        return 'dxButton';
      case item instanceof ToolbarItemWaitButtonComponent:
        return 'dxButton';
      case item instanceof ToolbarItemButtonGroupComponent:
        return 'dxButtonGroup';
      case item instanceof ToolbarItemDropDownButtonComponent:
        return 'dxDropDownButton';
      case item instanceof ToolbarItemMenuButtonComponent:
        return 'dxMenu';
      default:
        return undefined;
    }
  }
}
