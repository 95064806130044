import { Injectable } from '@angular/core';

import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  PageControl,
  WorkItemConfiguration,
  PageGroup,
  PageSection,
  Section,
} from '@clarilog/shared2/models/schema';

import editPassword from './templates/password-edit-mode.json';
import newPassword from './templates/password-new-mode.json';
import newSimplePassword from './templates/simple-password-new-mode.json';
import { TypeHelpers } from '@clarilog/core/services/tracks/type-helpers';

export function isPageControl(obj: any) {
  return (obj as PageControl).control !== undefined;
}

@Injectable({
  providedIn: 'root',
})
export class CoreWorkItemExtensions {
  assignControls(controls: any[], toAdd: any[]): any[] {
    let result = [];

    controls.forEach((control) => {
      let exitControl = toAdd.find((f) => f.fieldName === control.fieldName);
      if (exitControl != undefined) {
        control = Object.assign(control, exitControl);
      }
      result.push(control);
    });

    toAdd.forEach((control) => {
      let exitControl = result.find((f) => f.fieldName === control.fieldName);
      if (exitControl == undefined) {
        result.push(control);
      }
    });
    return result;
  }

  assignGroups(groups: any[], toAdd: any[]): any[] {
    let result = [];

    groups.forEach((group) => {
      let exitGroup = toAdd.find((f) => f.label === group.label);
      if (exitGroup != undefined) {
        group.controls = this.assignControls(
          group.controls,
          exitGroup.controls,
        );
      }
      result.push(group);
    });

    toAdd.forEach((group) => {
      let exitGroup = result.find((f) => f.label === group.label);
      if (exitGroup == undefined) {
        result.push(group);
      }
    });
    return result;
  }

  assignSections(sections: any[], toAdd: any[]): any[] {
    let result = [];

    sections.forEach((section) => {
      let exitSection = toAdd.find(
        (f) =>
          (f.location == undefined && section.location == undefined) ||
          f.location === section.location,
      );
      if (exitSection != undefined) {
        section.groups = this.assignGroups(section.groups, exitSection.groups);
      }
      result.push(section);
    });

    toAdd.forEach((section) => {
      let exitSection = result.find((f) => f?.location === section?.location);
      if (exitSection == undefined) {
        result.push(section);
      }
    });
    return result;
  }

  assignPages(pages: any[], toAdd: any[]): any[] {
    let result = [];

    pages.forEach((page) => {
      let exitPage = toAdd.find((f) => f.label === page.label);
      if (exitPage != undefined) {
        if (exitPage.sections != undefined) {
          page.sections = this.assignSections(page.sections, exitPage.sections);
        } else if (exitPage.control != undefined) {
          page.control = exitPage.control;
        } else if (exitPage.tabs != undefined) {
          page.tabs = exitPage.tabs;
        } else if (page.pages != undefined && exitPage.pages != undefined) {
          page.pages = this.assignPages(page.pages, exitPage.pages);
        }

        if (exitPage.visible != undefined) {
          page['visible'] = exitPage.visible;
        }
        if (exitPage.dependsOn != undefined) {
          page['dependsOn'] = exitPage.dependsOn;
        }
      }
      result.push(page);
    });

    toAdd.forEach((page) => {
      let exitPage = result.find((f) => f.label === page.label);
      if (exitPage == undefined) {
        result.push(page);
      }
    });

    return result;
  }

  /** Applique les template squr le model JSON */
  buildTemplate(id: string, model: any): any {
    let template: any = JSON.parse(JSON.stringify(model));
    let result = this.recursifStringify(id, template);
    return result;
  }

  /** Boucle récursif de rempalcement d'un composant / template */
  recursifStringify(id: string, model: any) {
    if (TypeHelpers.isArray(model)) {
      // Mise en temporaire pour faire la boucle
      let modelTemp = JSON.parse(JSON.stringify(model));
      let indexAdd = 0;
      for (let i = 0; i < modelTemp.length; i++) {
        if (
          modelTemp[i].type == undefined &&
          modelTemp[i].templateType != undefined
        ) {
          modelTemp[i].type = modelTemp[i].templateType;
        }
        if (modelTemp[i].type != undefined) {
          let component: any;

          // Template ou type particulier
          switch (modelTemp[i].type) {
            case 'PasswordComponent':
              // Chargement du layout
              if (id == undefined) {
                let newPwd = newPassword;
                if(modelTemp[i].dependsOn != undefined)
                {
                  newPwd[1]['dependsOn'] = modelTemp[i].dependsOn;
                }
                component = newPwd;
              } else {
                component = editPassword;
              }
              break;
            case 'SimplePasswordComponent':
              // Chargement du layout
              if (id == undefined) {
                component = newSimplePassword;
              } else {
                component = editPassword;
              }
              break;
          }

          if (component != undefined) {
            if (TypeHelpers.isArray(component)) {
              // insert
              component.forEach((c) => {
                model.splice(i + indexAdd, 0, c);
                indexAdd++;
              });
            } else {
              // remplacement
              delete model[i].type;
              model[i] = Object.assign(model[i], component);
            }
          }
        } else {
          model[i] = this.recursifStringify(id, model[i]);
        }
      }
    } else if (TypeHelpers.isObject(model)) {
      // Object
      let keys = Object.keys(model);
      if (keys != undefined) {
        for (let i = 0; i < keys.length; i++) {
          model[keys[i]] = this.recursifStringify(id, model[keys[i]]);
        }
      }
    }

    return model;
  }

  coreAssign(from: any, toAdd: any): any {
    let modelTemp = JSON.stringify(from);
    let initial = JSON.parse(modelTemp);

    if (
      initial?.form?.layout?.pages &&
      Array.isArray(initial.form.layout.pages) &&
      toAdd?.form?.layout?.pages &&
      Array.isArray(toAdd.form.layout.pages)
    ) {
      initial.form.layout.pages = this.assignPages(
        initial.form.layout.pages,
        toAdd.form.layout.pages,
      );
    }
    return initial;
  }

  /** @deprecated */
  assign(initial: ModelState, add: ModelState): WorkItemConfiguration {
    //TODO a stringify au plus haut de l'appel.

    initial.contexts = [...initial.contexts, ...add.contexts];

    let newWorkItem: WorkItemConfiguration = initial.model; //JS*ON.parse(JSON.stringify(initial));

    if (add != undefined && newWorkItem != undefined) {
      for (var i = 0; i < add.model.form.layout.pages.length; i++) {
        //Vérification des pages
        if (
          i <= newWorkItem.form.layout.pages.length &&
          newWorkItem.form.layout.pages.length > 0
        ) {
          var page = newWorkItem.form.layout.pages.filter(
            (f) => f.label == add.model.form.layout.pages[i].label,
          );

          if (page.length > 0) {
            if (
              (add.model.form.layout.pages[i] as PageGroup).pages != undefined
            ) {
              let pages = (add.model.form.layout.pages[i] as PageGroup).pages;
              for (var y = 0; y < pages.length; y++) {
                (page[0] as PageGroup).pages.push(pages[y]);
              }
            } else {
              this.addingSection(
                page[0] as PageSection,
                add.model.form.layout.pages[i] as PageSection,
              );
            }
          } else {
            newWorkItem.form.layout.pages.push(add.model.form.layout.pages[i]);
          }
        } else {
          //Push
          newWorkItem.form.layout.pages.push(add.model.form.layout.pages[i]);
        }
      }
    }

    return newWorkItem;
  }
  /** @deprecated */
  addingSection(initialPage: PageSection, addPage: PageSection) {
    for (var i = 0; i < addPage.sections.length; i++) {
      //Vérification des section
      if (
        i <= initialPage.sections.length &&
        initialPage.sections.length > 0 &&
        initialPage.sections[i] != undefined &&
        addPage.sections[i] != undefined &&
        addPage.sections[i].location == initialPage.sections[i].location
      ) {
        this.addingGroup(
          initialPage.sections[i] as Section,
          addPage.sections[i] as Section,
        );
      } else {
        //Push
        initialPage.sections.push(addPage.sections[i]);
      }
    }
  }
  /** @deprecated */
  addingGroup(initialSection: Section, addSection: Section) {
    for (var i = 0; i < addSection.groups.length; i++) {
      //Vérification des section
      if (
        i <= initialSection.groups.length &&
        initialSection.groups.length > 0 &&
        addSection.groups[i] != undefined &&
        initialSection.groups[i] != undefined &&
        addSection.groups[i].label == initialSection.groups[i].label
      ) {
        addSection.groups[i].controls.forEach((f) =>
          initialSection.groups[i].controls.push(f),
        );
      } else {
        //Push
        initialSection.groups.push(addSection.groups[i]);
      }
    }
  }
}
