import { PrivilegeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { Privilege, PrivilegeEntry } from '../types';
import { map } from 'rxjs/operators';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-privilege')
export class PrivilegeCoreService extends PrivilegeBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }
  public getEntryFields(): GqlFields {
    return [
      GqlField.create('title'),
      GqlField.create('key'),
      GqlField.create('enable'),
    ];
  }

  public getFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlSubField.create(
          'name',
          this.translatedFieldHelperService.translatedFields(),
        ),
        GqlField.create('key'),
        GqlField.create('isDefault'),
        GqlSubField.create('ticketEntries', this.getEntryFields()),
        GqlSubField.create('taskEntries', this.getEntryFields()),
        GqlSubField.create('actionEntries', this.getEntryFields()),
        GqlSubField.create('authEditEntries', this.getEntryFields()),
      ]),
    ];
  }

  public static hasPrivilege(
    privilege: Privilege,
    privilegeEntry: string,
  ): boolean {
    if (
      privilege.actionEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(privilegeEntry)
    ) {
      return true;
    }
    if (
      privilege.ticketEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(privilegeEntry)
    ) {
      return true;
    }
    if (
      privilege.authEditEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(privilegeEntry)
    ) {
      return true;
    }
    if (
      privilege.taskEntries
        .filter((x) => x.enable === true)
        .map((x) => x.key)
        .includes(privilegeEntry)
    ) {
      return true;
    }
    return false;
  }

  public async defaultTicketValuesWithFields(): Promise<Array<PrivilegeEntry>> {
    return this.defaultTicketValues([
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ])
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }

  public async defaultNotificationValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultNotificationValue([
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ])
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }

  public async defaultTaskValuesWithFields(): Promise<Array<PrivilegeEntry>> {
    return this.defaultTaskValues([
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ])
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }

  public async defaultAuthEditValuesWithFields(): Promise<
    Array<PrivilegeEntry>
  > {
    return this.defaultAuthEditValues([
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ])
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }

  public async defaultActionValuesWithFields(): Promise<Array<PrivilegeEntry>> {
    return this.defaultActionValues([
      GqlSubField.create('data', [
        GqlField.create('title'),
        GqlField.create('enable'),
        GqlField.create('key'),
      ]),
    ])
      .pipe(map((x) => x.data))
      .pipe(
        map((x) => {
          x.forEach((el) => {
            delete el['__typename'];
          });
          return x;
        }),
      )
      .toPromise();
  }
}
