import {
  AbstractControlOptions,
  AsyncValidatorFn,
  UntypedFormControl,
  ValidatorFn,
} from '@angular/forms';
import {
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { forkJoin, Observable } from 'rxjs';
import { finalize, switchMap } from 'rxjs/operators';
import {
  GridOptions,
  LinkListOptions,
  SchedulerOptions,
} from '@clarilog/shared2/models/schema';
import { ServiceSingleResult } from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

/** Représente un type fonction qui renvoie un observable. */
export type FunctionAsync<T> = (
  name: string,
  value: T,
) => Observable<ServiceSingleResult<any>>;

// Sauvegarde de la fonction reset.
let resetFn = UntypedFormControl.prototype.reset;
/** @inheritdoc */
UntypedFormControl.prototype.reset = function (value?, options?) {
  if (value != undefined) {
    this.originalValue = JSON.parse(JSON.stringify(value));
  } else {
    this.originalValue = undefined;
  }
  resetFn.apply(this, [value, options]);
};

/** Représente des FormControl dont la sauvegarde est asynchrone. */
export abstract class AsyncFormControl extends UntypedFormControl {
  private _functionAsync: FunctionAsync<ItemsValue>;
  constructor(
    functionAsync: FunctionAsync<ItemsValue>,
    formState?: any,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(formState, validatorOrOpts, asyncValidator);
    this._functionAsync = functionAsync;
  }
  /** Obtient une fonction qui se déclenche à la sauvegarde. */
  get functionAsync(): FunctionAsync<ItemsValue> {
    return this._functionAsync;
  }
}
/** Permet de créer une function callback par rapport aux options de @type {LinkListOptions}. */
function createAsync(
  modelState: ModelState,
  options: LinkListOptions | SchedulerOptions | GridOptions,
  asyncForm?: () => AsyncFormControl,
): FunctionAsync<ItemsValue> {
  return (name: string, value: ItemsValue | any) => {
    let observables$: Observable<ServiceSingleResult<any>>[] = [];

    if (
      (options as GridOptions)?.saveItems != undefined &&
      value != undefined
    ) {
      if (((<any>options).saveItems as ModelFnContext)?.fnCall != undefined) {
        observables$.push(
          Observable.create((observer) => {
            modelState.sharedContext.params.set('items', () => value);
            modelState.sharedContext.params.set('fields', () =>
              ModelFieldCompilerService.createServiceSingleResultScalar(),
            );
            let obs = ((<any>options).saveItems as ModelFnContext).fnCall();
            obs.subscribe(
              (result) => {
                observer.next(result);
                observer.complete();
              },
              (err) => {
                let form = asyncForm();
                (form as any).resultError = true;
                observer.error(err);
                observer.complete();
              },
            );
          }),
        );
      }
    } else {
      if (value?.itemsAdded != undefined && value.itemsAdded.length > 0) {
        value.itemsAdded.forEach((f) => {
          delete f['id'];
          delete f['__typename'];
        });

        observables$.push(
          Observable.create((observer) => {
            let old = undefined;
            try {
              old = modelState.sharedContext.params.get(name);
            } catch (e) {}
            modelState.sharedContext.params.set(name, () => value.itemsAdded);

            let obs = ((<any>options).addItems as ModelFnContext).fnCall();
            if (old != undefined) {
              modelState.sharedContext.params.set(name, () => old);
            }
            obs.subscribe(
              (result) => {
                if (result == undefined) {
                  console.error(
                    'Le service doit retourner un type QuerySingleResultTypeBooleanType.',
                  );
                } else {
                  if (asyncForm != undefined) {
                    let form = asyncForm();
                    if (result.data === false) {
                      // TODO Trouver une autre solution
                      (form as any).resultError = true;
                    } else {
                      (form as any).resultError = undefined;
                    }
                  }
                }
                observer.next(result);
                observer.complete();
              },
              (err) => {
                let form = asyncForm();
                // TODO Trouver une autre solution
                (form as any).resultError = true;
                observer.error(err);
                observer.complete();
              },
            );
          }),
        );
      }
      if (value?.itemsRemoved != undefined && value.itemsRemoved.length > 0) {
        value.itemsRemoved.forEach((f) => {
          delete f['__typename'];
        });

        observables$.push(
          Observable.create((observer) => {
            let old = undefined;
            try {
              old = modelState.sharedContext.params.get(name);
            } catch (e) {}

            modelState.sharedContext.params.set(name, () => value.itemsRemoved);
            let obs = ((<any>options).removeItems as ModelFnContext).fnCall();

            if (old != undefined) {
              modelState.sharedContext.params.set(name, () => old);
            }

            obs.subscribe(
              (result) => {
                if (result == undefined) {
                  console.error(
                    'Le service doit retourner un type QuerySingleResultTypeBooleanType.',
                  );
                } else {
                  if (asyncForm != undefined) {
                    let form = asyncForm();
                    if (result.data === false) {
                      // TODO Trouver une autre solution
                      (form as any).resultError = true;
                    } else {
                      (form as any).resultError = undefined;
                    }
                  }
                }
                observer.next(result);
                observer.complete();
              },
              (err) => {
                let form = asyncForm();
                // TODO Trouver une autre solution
                (form as any).resultError = true;
                observer.error(err);
                observer.complete();
              },
            );
          }),
        );
      }
      if (value?.itemsUpdated != undefined && value.itemsUpdated.length > 0) {
        observables$.push(
          Observable.create((observer) => {
            let old = undefined;
            try {
              old = modelState.sharedContext.params.get(name);
            } catch (e) {}

            modelState.sharedContext.params.set(name, () => value.itemsUpdated);
            let entry = JSON.parse(JSON.stringify(value.itemsUpdated));
            entry.forEach((f) => {
              delete f['__typename'];

              if (f?.set != undefined) {
                delete f.set['__typename'];
                delete f['id'];
              }

              if (f?.fields?.set != undefined) {
                delete f.fields.set['__typename'];
                delete f.fields.set['id'];
              }
            });

            modelState.sharedContext.params.set('entry', () => entry);
            let obs = ((<any>options).updateItems as ModelFnContext).fnCall();
            if (old != undefined) {
              modelState.sharedContext.params.set(name, () => old);
            }

            obs.subscribe(
              (result) => {
                if (result == undefined) {
                  console.error(
                    'Le service doit retourner un type QuerySingleResultTypeBooleanType.',
                  );
                } else {
                  if (asyncForm != undefined) {
                    let form = asyncForm();
                    if (result.data === false) {
                      // TODO Trouver une autre solution
                      (form as any).resultError = true;
                    } else {
                      (form as any).resultError = undefined;
                    }
                  }
                }
                observer.next(result);
                observer.complete();
              },
              (err) => {
                let form = asyncForm();
                // TODO Trouver une autre solution
                (form as any).resultError = true;
                observer.error(err);
                observer.complete();
              },
            );
          }),
        );
      }
    }
    return observables$.length > 0
      ? forkJoin(observables$)
          .pipe(
            finalize(() => {
              if (asyncForm != undefined) {
                let form = asyncForm();
                if ((form as any).resultError == undefined) {
                  if (
                    value?.itemsAdded != undefined &&
                    value.itemsAdded.length > 0
                  ) {
                    value.itemsAdded.clear();
                  }
                  if (
                    value?.itemsRemoved != undefined &&
                    value.itemsRemoved.length > 0
                  ) {
                    value.itemsRemoved.clear();
                  }
                  if (
                    value?.itemsUpdated != undefined &&
                    value.itemsUpdated.length > 0
                  ) {
                    value.itemsUpdated.clear();
                  }
                  form.reset();
                }
              }
            }),
          )
          .pipe(switchMap((r) => r))
      : undefined;
  };
}
/** Représente les valeurs de @type {FormLinkArray}. */
export class ItemsValue {
  /** Obtient ou définit les éléments ajoutés. */
  itemsAdded: any[] = [];
  /** Obtient ou définit les éléments supprimés. */
  itemsRemoved: any[] = [];
  /** Obtient ou définit les éléments modifiés. */
  itemsUpdated: any[] = [];
}
/** Représente un @type {AsyncFormControl} manipulant des valeurs de type @type {ItemsValue}. */
export class FormLinkArray extends AsyncFormControl {
  value: ItemsValue;
  isSubForm: boolean = false;
  constructor(
    // A supprimer lorsqu'il y aura le scope
    modelState: ModelState,
    options: LinkListOptions | SchedulerOptions | GridOptions,
    disabled: boolean = false,
    isSubForm: boolean = false,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(
      createAsync(modelState, options, () => this),
      {
        value: new ItemsValue(),
        disabled: disabled != undefined ? disabled : false,
      },
      validatorOrOpts,
      asyncValidator,
    );
    this.isSubForm = isSubForm;
    // Sauvegarde de la valeur d'origine via un clone.
    this.originalValue = JSON.parse(JSON.stringify(this.value));
  }

  /** @inheritdoc */
  reset(formState?: ItemsValue) {
    this.originalValue = JSON.parse(
      JSON.stringify(formState || new ItemsValue()),
    );
    super.reset(formState || new ItemsValue());
  }
}

/** Représente un @type {AsyncFormControl} manipulant des valeurs de type @type {any[]}. */
export class FormAnyLinqArray extends AsyncFormControl {
  value: any[];
  isSubForm: boolean = false;
  isFormAnyLinqArray = true;
  constructor(
    // A supprimer lorsqu'il y aura le scope
    modelState: ModelState,
    options: GridOptions,
    disabled: boolean = false,
    isSubForm: boolean = false,
    validatorOrOpts?:
      | ValidatorFn
      | ValidatorFn[]
      | AbstractControlOptions
      | null,
    asyncValidator?: AsyncValidatorFn | AsyncValidatorFn[] | null,
  ) {
    super(
      createAsync(modelState, options, () => this),
      {
        value: undefined,
        disabled: disabled != undefined ? disabled : false,
      },
      validatorOrOpts,
      asyncValidator,
    );
    this.isSubForm = isSubForm;
    // Sauvegarde de la valeur d'origine via un clone.
    this.originalValue = undefined;
  }

  /** @inheritdoc */
  reset(formState?: any[]) {
    super.reset(formState);
  }
}
