<div class="mobile-nav-container">
  <!--  <div *ngFor='let item of navItems' class='mobile-nav-item'>-->
  <!--    {{item.title}}-->
  <!--  </div>-->
  <dx-tabs
    #apiTabs
    [dataSource]="navItems"
    [scrollByContent]="true"
    [selectedIndex]="0"
  ></dx-tabs>
</div>
