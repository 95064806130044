<div class="mobile-container">
  <div class="mobile-header">
    <cl-mobile-header
      [items]="items"
      [state]="state"
      [optionalStartingRoute]="'mobile'"
    ></cl-mobile-header>
  </div>

  <div class="mobile-content">
    <router-outlet (activate)="onOutletLoaded($event)"></router-outlet>
  </div>
  <div class="mobile-footer">
    <div *ngIf="isInit === true">
      <!--      <div *ngFor='let toto of test'>-->
      <!--        {{toto.title}}-->
      <!--      </div>-->
      <!--      <cl-mobile-tab-bar [navItems]='test' [state]='state'></cl-mobile-tab-bar>-->
    </div>
  </div>
</div>
