import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { CoreModelCompilerService } from '../../../services/compiler/model-compiler.service';
import { CoreManageListComponent } from '../../list/manage-list/manage-list.component';
import model from './model.json';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { EmailPrototypeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype.service';
import DataSource from 'devextreme/data/data_source';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import notify from 'devextreme/ui/notify';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

/** Représente la classe du composant cl-email-prototype-list. */
@Component({
  selector: 'cl-email-prototype-list',
  templateUrl: './email-prototype-list.component.html',
  styleUrls: ['./email-prototype-list.component.scss'],
})
export class CoreEmailPrototypeListComponent implements OnInit {
  modelState: ModelState;
  state: Observable<object>;
  type: any;
  /** Obtient ou définit la liste. */
  @ViewChild(CoreManageListComponent, { static: true })
  manageList: CoreManageListComponent;
  selectedKeys: any[] = [];
  newCommand = undefined;
  duplicateDataSource: DataSource;
  visibleDupicate: boolean = true;
  dropdownButton: boolean = true;
  newDuplicateType: string;
  constructor(
    private modelCompilerService: CoreModelCompilerService,
    public emailPrototypeService: EmailPrototypeCoreService,
    private router: Router,
    public route: ActivatedRoute,
  ) {
    this.route.data.subscribe((data) => {
      this.type = data.type;
      let dataSource = [];

      // If the data type is not undefined, and it's an array (where used for the mail prototype on ServDesk)
      if (this.type != undefined && Array.isArray(this.type)) {
        this.type.forEach((f) =>
          dataSource.push({
            id: f,
            value: f,
            name: TranslateService.get(`entities/${f}/_title/singular`),
          }),
        );

        dataSource = dataSource.sort((a, b) => {
          return a.name.localeCompare(b.name);
        });

        this.newCommand = {
          keyExpr: 'id',
          displayExpr: 'name',
          targetIdExpr: 'modelId',
          source: new ModelDataSourceContext({
            datasource: new DataSource(dataSource),
          }),
          onItemClick: this.onItemClick.bind(this),
          splitButton: false,
        };
      } else {
        //this.visibleDupicate = false; // After un-commenting this line, you'll have to un-comment some lines in the "alarm-prototypes-routing.module.ts"

        dataSource.push({
          id: this.type,
          value: this.type,
          name: TranslateService.get(`entities/${this.type}/_title/singular`),
        });

        if (this.type == 'queryBuilder' || this.type == 'alarm') {
          this.dropdownButton = false;
          this.newDuplicateType = this.type;
        }

        this.type = [data.type];
      }

      this.duplicateDataSource = new DataSource({
        load: (loadOptions) => {
          return dataSource;
        },
      });
    });
  }

  async ngOnInit() {
    this.modelCompilerService.compile(model).subscribe((model) => {
      this.modelState = model;
      this.modelState.sharedContext.params.set('type', () => this.type);
    });
  }

  /** Gestion du click */
  async onItemClick(e) {
    // // Navigation
    let params = {
      type: e.itemData.value,
    };
    this.router.navigate(['new'], {
      relativeTo: this.route,
      queryParams: params,
    });
  }

  /** Duplication */
  duplicateType(e) {
    let newType: string = e.itemData?.id;
    if (newType == undefined) {
      newType = this.newDuplicateType;
    }

    this.emailPrototypeService
      .duplicateByType(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.selectedKeys[0],
        newType,
      )
      .subscribe((result) => {
        if (result?.errors.length == 0) {
          notify(
            TranslateService.get('entities/incident/changeSuccess'),
            'success',
            5000,
          );
          this.manageList.refresh();
        } else {
          notify(result.errors[0].messageError, 'error', 5000);
        }
      });
  }
}
