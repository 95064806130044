import { Component, forwardRef, Input, OnInit } from '@angular/core';
import {
  ControlValueAccessor,
  FormGroupDirective,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { NavigationExtras, Router } from '@angular/router';
import { CommandAssetCategoryType } from '@clarilog/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { ListMode } from '../list/list.component';
import { GridOptions } from '@clarilog/shared2/models/schema';
import { TemplatesService } from '../../templates/templates.service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-command-asset-category-list',
  templateUrl: './command-asset-category-list.component.html',
  styleUrls: ['./command-asset-category-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreCommandAssetCategoryListComponent),
      multi: true,
    },
  ],
})
export class CoreCommandAssetCategoryListComponent
  implements ControlValueAccessor, OnInit
{
  /** Sauvegarde les valeurs. */
  _values: any[] = [];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit les options (Attention, solution de facilité malheureusement, cette méthode ne permet
   * pas de suivre les modifications. Donc, 'options' à revoir !!!!). */
  @Input() options: GridOptions;
  @Input() mode: ListMode = 'default';
  @Input() readOnly: boolean = false;

  /**Obtient ou définit si la selection du noeud parent à est effectuée depuis un click droit */
  isSelectedByRightClick: boolean = false;

  /** Obtient ou définit la source de données. */
  source: any[];

  /** Récupère le composant en cours. */
  component;

  /** Tableau des index sélectionnées */
  selectedKeys: any[] = [];
  initializeValue: boolean = false;
  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this.initializeValue === false) {
      // this.onChange(this._values);
      // this.onTouched();
    }
  }
  constructor(
    public templateService: TemplatesService,
    private _router: Router,
    private formGroupDirective: FormGroupDirective,
  ) {
    formGroupDirective.form.root.onLoadedChanged.subscribe((res) => {
      if (res == true) {
        formGroupDirective.form.reset();
      }
    });
  }
  /** @inheritdoc */
  writeValue(values: any[]): void {
    // ne gere pas les changement de sélection
    if (this.readOnly != undefined && this.readOnly == true) {
      return;
    }
    this.initializeValue = true;
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
    this.initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
  /** @inheritdoc */
  async ngOnInit() {}

  private _tRowUpdating: boolean = false;
  public onRowUpdating(e) {
    if (this._tRowUpdating == false) {
      this._tRowUpdating = true;
      if (
        e.newData.active != undefined &&
        this.isSelectedByRightClick == false
      ) {
        let rowIndex = e.component.getRowIndexByKey(e.key);
        e.component.beginUpdate();
        e.component.cellValue(rowIndex, 'active', e.newData.active);
        let childreens = [];
        childreens = this.GetAllChildreen(e.component, e.key, childreens);
        childreens.forEach((c) => {
          let childreenIndex = e.component.getRowIndexByKey(c);
          e.component.cellValue(childreenIndex, 'active', e.newData.active);
          let cs = this.source.find((x) => x.id == c);
          cs.active = e.newData.active;
          this.UpdateValue(cs);
        });

        e.component.saveEditData();
        e.component.endUpdate();
        setTimeout(() => {
          if (childreens?.length > 0) {
            e.component.saveEditData();
          }
          setTimeout(() => {
            this._tRowUpdating = false;
          }, 400);
        }, 10);
      }
      if (
        e.newData.default != undefined &&
        this.isSelectedByRightClick == false
      ) {
        let childreens = [];
        let rowIndex = e.component.getRowIndexByKey(e.key);
        e.component.beginUpdate();
        if (e.newData.default == true) {
          e.component.cellValue(rowIndex, 'active', true);
        }
        e.component.cellValue(rowIndex, 'default', e.newData.default);

        childreens = this.GetAllChildreen(e.component, e.key, childreens);
        childreens.forEach((c) => {
          let childreenIndex = e.component.getRowIndexByKey(c);

          e.component.cellValue(childreenIndex, 'default', e.newData.default);
          let cs = this.source.find((x) => x.id == c);
          if (e.newData.default == true) {
            e.component.cellValue(childreenIndex, 'active', true);
            cs.active = true;
          }
          cs.default = e.newData.default;
          this.UpdateValue(cs);
        });

        e.component.saveEditData();
        e.component.endUpdate();
        setTimeout(() => {
          if (childreens?.length > 0) {
            e.component.saveEditData();
          }
          setTimeout(() => {
            this._tRowUpdating = false;
          }, 400);
        }, 10);
      }
      if (
        e.newData.automatique != undefined &&
        this.isSelectedByRightClick == false
      ) {
        let childreens: any[] = [];
        let rowIndex = e.component.getRowIndexByKey(e.key);
        e.component.beginUpdate();
        if (e.newData.automatique == true) {
          e.component.cellValue(rowIndex, 'active', true);
          e.component.cellValue(rowIndex, 'default', true);
        }
        e.component.cellValue(rowIndex, 'automatique', e.newData.automatique);

        childreens = this.GetAllChildreen(e.component, e.key, childreens);
        childreens.forEach((c) => {
          let childreenIndex = e.component.getRowIndexByKey(c);

          e.component.cellValue(
            childreenIndex,
            'automatique',
            e.newData.automatique,
          );
          let cs = this.source.find((x) => x.id == c);
          if (e.newData.automatique == true) {
            e.component.cellValue(childreenIndex, 'active', true);
            e.component.cellValue(childreenIndex, 'default', true);
            cs.active = true;
            cs.default = true;
          }
          cs.automatique = e.newData.automatique;
          this.UpdateValue(cs);
        });

        e.component.saveEditData();
        e.component.endUpdate();
        setTimeout(() => {
          if (childreens?.length > 0) {
            e.component.saveEditData();
          }
          setTimeout(() => {
            this._tRowUpdating = false;
          }, 400);
        }, 10);
      }
    } else {
      this._tRowUpdating = false;
    }
    this.isSelectedByRightClick = false;
  }

  public onRowUpdated(e) {
    this.UpdateValue(e.data);
  }

  private GetAllChildreen(component: any, key: any, childreenKeys: any[]) {
    let node = component.getNodeByKey(key);
    if (node?.hasChildren == true) {
      node.children.forEach((c) => {
        childreenKeys.push(c.key);
        if (c.hasChildren == true) {
          this.GetAllChildreen(component, c.key, childreenKeys);
        }
      });
    }
    return childreenKeys;
  }

  onContextMenuPreparing(e) {
    if (!e.items) e.items = [];

    if (
      e.row != undefined &&
      e.row.node.hasChildren &&
      e.column?.dataField != 'name'
    ) {
      e.items.push(
        {
          text: TranslateService.get('globals/selectLevel'),
          onClick: () => {
            this.selectOrUnselectRow(e, e.column.dataField, true);
          },
        },
        {
          text: TranslateService.get('globals/unSelectLevel'),
          onClick: () => {
            this.selectOrUnselectRow(e, e.column.dataField, false);
          },
        },
      );
    }
    e.items.push(
      {
        text: TranslateService.get('globals/expandAll'),
        onClick: () => {
          e.component.forEachNode((node) => {
            e.component.expandRow(node.key);
          });
        },
      },
      {
        text: TranslateService.get('globals/collapseAll'),
        onClick: () => {
          e.component.forEachNode((node) => {
            e.component.collapseRow(node.key);
          });
        },
      },
    );
  }

  onSaving(e) {
    //Permet d'activer ou de désactiver certaines case à coché
    e.changes.map((element) => {
      if (element.data?.active == false) {
        element.data.default = false;
      }
      if (element.data?.default) {
        element.data['active'] = true;
      } else if (element.data?.automatique) {
        element.data['active'] = true;
        element.data['default'] = true;
      } else {
        element.data.automatique = false;
      }
    });
  }

  private UpdateValue(data: any) {
    if (this.values == undefined) this.values = [];
    let index = this.values.findIndex((x) => x.id == data.id);
    let c = data as CommandAssetCategoryType;
    delete c['__typename'];
    if (index === -1) {
      this._values.push(c);
    } else {
      this._values[index] = c;
    }
    this.onChange(this._values);
    this.onTouched();
  }

  public onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'refresh',
        onClick: () => {
          this.refresh();
        },
      },
    });

    if (this.options.type === 'Tree') {
      e.toolbarOptions.items.unshift(
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-button-style',
          options: {
            icon: 'fal fa-minus-square',
            stylingMode: 'text',
            text:
              this.mode === 'popup'
                ? undefined
                : TranslateService.get('globals/collapseAll'),
            hint: TranslateService.get('globals/collapseAll'),
            onClick: () => {
              e.component.forEachNode((node) => {
                e.component.collapseRow(node.key);
              });
            },
          },
        },
        {
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-button-style',
          options: {
            icon: 'fal fa-plus-square',
            stylingMode: 'text',
            text:
              this.mode === 'popup'
                ? undefined
                : TranslateService.get('globals/expandAll'),
            hint: TranslateService.get('globals/expandAll'),
            onClick: () => {
              e.component.forEachNode((node) => {
                e.component.expandRow(node.key);
              });
            },
          },
        },
      );
    }

    if (this.options.route != undefined) {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'before',
        cssClass: 'cl-button-style',
        options: {
          icon: 'fas fa-arrow-right',
          stylingMode: 'text',
          text:
            this.mode === 'popup' ? undefined : TranslateService.get('manage'),
          hint: TranslateService.get('manage'),
          onClick: () => {
            this.onGoTo();
          },
        },
      });
    }

    if (this.options.toolbarItems != undefined) {
      for (let toolbarItem of this.options.toolbarItems) {
        e.toolbarOptions.items.unshift({
          widget: 'dxButton',
          location: 'before',
          options: {
            icon: toolbarItem.icon,
            stylingMode: 'text',
            text: toolbarItem.label,
            hint: toolbarItem.hint,
            onClick: () => {
              (toolbarItem.click as any)().emit();
            },
          },
        });
      }
    }
  }
  initialized(e) {
    this.component = e.component;
    // Pour le load panel
    setTimeout(() => {
      let dataSource = <DataSource>(
        (<unknown>(this.options.source as any).datasource)
      );

      if (this.options.type == 'Grid') {
        if (this.options.paging != undefined) {
          if (this.options.paging.pageIndex != undefined) {
            dataSource.pageIndex(this.options.paging.pageIndex);
          }
          if (this.options.paging.pageSize != undefined) {
            dataSource.pageSize(this.options.paging.pageSize);
          }
        }
      } else {
        dataSource.pageSize(500000);
      }

      this.component.beginCustomLoading();
      // Pour le load panel
      setTimeout(async () => {
        this.source = await dataSource.reload();

        this.component.endCustomLoading();
      });
    });
  }
  /** initialisation du composant */
  onContentReady(e) {
    if (this.options.type == 'Tree') {
    }
  }

  /** Rafraichi la liste. */
  refresh() {
    // Reset origine
    (<DataSource>(<unknown>(this.options.source as any).datasource))
      .load()
      .then((results) => {
        this.source = results;
        this._values = JSON.parse(JSON.stringify(this._originalValues));
        this.onChange(this._values);
        this.onTouched();
      });
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo() {
    let url = this._router.createUrlTree([this.options.route], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Permet de gérer l'apparence d'une cellule */
  onCellPrepared(e) {
    // Par défaut
    let disabledValue: any = true;
    if (this.options.disabledValue != undefined) {
      disabledValue = this.options.disabledValue;
    }
    if (this.options != undefined && this.options.disabledExpr != undefined) {
      if (
        e.data != undefined &&
        e.data[this.options.disabledExpr] == disabledValue
      ) {
        let checks = e.cellElement.getElementsByClassName('dx-select-checkbox');
        if (checks.length > 0) {
          checks[0].classList.add('dx-state-disabled');
          checks[0].classList.add('cl-state-node-disabled');
        }
      }
    }
  }

  public selectOrUnselectRow(e, column: string, isSelected: boolean) {
    let rowIndex = e.rowIndex;
    e.component.cellValue(rowIndex, column, isSelected);
    if (isSelected == false) {
      if (column == 'active') {
        e.component.cellValue(rowIndex, column, isSelected);
        e.component.cellValue(rowIndex, 'automatique', isSelected);
      }

      if (column == 'default') {
        e.component.cellValue(rowIndex, 'automatique', isSelected);
      }
    }
    this.isSelectedByRightClick = true;
    e.component.saveEditData();
  }
}
