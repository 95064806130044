
import { StockInReasonBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({ providedIn: 'root' })
@Authorize('stock.manage-stock-in-reason')
export class StockInReasonCoreService extends StockInReasonBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}