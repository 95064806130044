import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'count'
})
export class CountPipe implements PipeTransform {
  transform(items: any[], value : any): boolean {
    if (!items) return false;
    return items.length === value;
  }
}
