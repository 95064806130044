import {
  Component,
  forwardRef,
  Input,
  OnInit,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { ServiceSingleResultOfBoolean } from '@clarilog/core/services2/graphql/generated-types/types';
import { ModelFnContext } from '@clarilog/shared2/services/compiler/model-state';
import { DxFormComponent } from 'devextreme-angular';
import notify from 'devextreme/ui/notify';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

@Component({
  selector: 'clc-password',
  templateUrl: './password.component.html',
  styleUrls: ['./password.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CorePasswordComponent),
      multi: true,
    },
  ],
})
export class CorePasswordComponent implements ControlValueAccessor, OnInit {
  @ViewChild(DxFormComponent, { static: false }) form: DxFormComponent;
  @Input() text: string;
  @Input() type: string;
  @Input() title: string;
  @Input() visible: boolean = false;
  @Input() resetPassword: ModelFnContext;
  isVisible: boolean = false;
  isVisibleButton = true;
  changePassword: {
    NewPassword: String;
    confirmNewPassword: String;
  };

  id: string;

  constructor(
    public activatedRoute: ActivatedRoute, //public service :ScanConfigurationService
  ) {
    this.onSubmit = this.onSubmit.bind(this);
    this.comparePassword = this.comparePassword.bind(this);
  }

  writeValue(obj: any): void {
    //throw new Error('Method not implemented.');
  }
  registerOnChange(fn: any): void {
    // throw new Error('Method not implemented.');
  }
  registerOnTouched(fn: any): void {
    //throw new Error('Method not implemented.');
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error('Method not implemented.');
  }

  ngOnInit(): void {
    this.changePassword = {
      NewPassword: '',
      confirmNewPassword: '',
    };
    this.id = this.activatedRoute.snapshot.paramMap.get('id');
  }

  onSubmit(e) {
    // const isFormValid = this.form.instance.validate().isValid;
    const newPassword = <string>this.changePassword.NewPassword;
    const confirmPassword = this.changePassword.confirmNewPassword;
    if (
      (this.id != null || this.id != undefined) &&
      confirmPassword === newPassword
    ) {
      //subscribe(this.id, newPassword, this.type)

      this.resetPassword.context.params.set('id', () => this.id);
      this.resetPassword.context.params.set('newPassword', () => newPassword);
      this.resetPassword.context.params.set('type', () => this.type);
      this.resetPassword
        .fnCall()
        .subscribe((result: ServiceSingleResultOfBoolean) => {
          this.resetPassword.context.params.remove('id');
          this.resetPassword.context.params.remove('newPassword');
          this.resetPassword.context.params.remove('type');
          if (result.data === true) {
            notify(TranslateService.get('saveSuccess'), 'success', 5000);
          } else {
            notify(TranslateService.get('saveError'), 'error', 5000);
          }
          this.form.instance.resetValues();
        });
      this.close(e);
    }
  }

  comparePassword(e) {
    return this.form.instance.option('formData').NewPassword;
  }

  close(e) {
    this.isVisible = false;
    this.form.instance.resetValues();
  }

  click(e) {
    this.isVisible = true;
  }
}
