import {
  Component,
  ElementRef,
  Injector,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';

import { GC, GCFactory } from '@clarilog/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Observable, Subscription } from 'rxjs';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ActivatedRoute, Router } from '@angular/router';
import {
  MessageInput,
  NotificationKey,
} from '@clarilog/core/services2/graphql/generated-types/types';
import Globalize from 'globalize/dist/globalize';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { FormGroupHelpers } from '../form/work-form/form-group-helpers';
import { alert, custom } from 'devextreme/ui/dialog';
import { dxButtonOptions } from 'devextreme/ui/button';
import { TranslatedFieldHelperService } from '../translate-field';
import { DxListComponent } from 'devextreme-angular';
import {
  NotificationCoreService,
  TicketCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import Timeout = NodeJS.Timeout;
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-message-ticket',
  templateUrl: './message-ticket.component.html',
  styleUrls: ['./message-ticket.component.scss'],
})
export class MessageTicketComponent implements OnInit, OnDestroy {
  /** Obtient ou définit la source de données. */
  source: DataSource;
  /** Obtient ou définit l'état d'affichage du bouton. */
  displayBtn: boolean = false;
  visible: boolean = false;
  popover_target: string;
  /** Obtient ou définit l'objet du htmlEditor */
  htmlEditorField: any;
  /** Obtient ou définit l'objet du select-box */
  selectModelField: any;
  /** Obtient ou définit l'id du ticket */
  id: string;
  /** Obtient ou définit le service */
  service: any;
  sourceContext: ModelDataSourceContext;
  /** Desactive la zone de message */
  disabledHtml: boolean = false;
  routerSubsccriber: Subscription;

  /**Obtient ou définit si on doit afficher ou masquer la zone de texte */
  @Input() isReadOnly: boolean = false;
  /** Obtient ou définit le type */
  @Input() isOperator: boolean;
  /** Obtient ou définit le model en cours. */
  @Input() model: ModelState;
  state: Observable<object>;
  iconBtn: string;
  _gc: GC;
  enableScroolBottom: boolean = true;

  /**Permet de désactiver l'envoi de message pour les tickets clos  */
  hideHtmlEditor: boolean = true;
  /**Permet de rendre visible ou non la sélection du modèle de message pour les utilisateurs */
  hideSelectMessageModel: boolean = true;
  text: string;

  checkIsEndingTicket: boolean = true;
  @Input() messageModel: any;

  @ViewChild('message-chat') input: ElementRef<HTMLAreaElement>;
  @ViewChild(DxListComponent, { static: false }) list: DxListComponent;

  /** Obtient la liste */
  listComponent = undefined;

  /** Affiche le bouton d'expansion ou de compression */
  expand: boolean = false;

  constructor(
    public authService: AuthorizationCoreService,
    private route: ActivatedRoute,
    private _gcFactory: GCFactory,
    injector: Injector,
    private router: Router,
    public translateFieldhelperService: TranslatedFieldHelperService,
    private ticketService: TicketCoreService,
    private notficationService: NotificationCoreService,
  ) {
    this._gc = _gcFactory.create();
    this.getId();

    this.sourceContext = new ModelDataSourceContext({
      serviceName: 'MessageCoreService',
      methodName: 'find',
    });

    // TODO check
    this.service = injector.get(this.sourceContext.serviceName);
    this.iconBtn = 'fal fa-paper-plane';
  }

  items = [];

  skip = 0;
  inProgress = false;
  inScroll = false;
  isEmpty = false;
  reloader: Timeout;
  itemCount: number = 0;

  async countItems() {
    if (!this.inProgress && this.id != null) {
      let source = await this.service[this.sourceContext.methodeName](
        [GqlSubField.create('data', [GqlField.create('id')])],
        null,
        { ticketId: { eq: this.id } },
      ).toPromise();

      return source.data.length;
    }
  }

  async loadItems() {
    if (!this.inProgress && !this.isEmpty && this.id != null) {
      this.inProgress = true;
      this.getId();
      this.disabledHtml = this.id == undefined;
      let opt = {
        limit: 20,
        skip: this.skip,
        sort: [{ date: 'DESC' }],
      };

      let source = await this.service[this.sourceContext.methodeName](
        [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlField.create('date'),
            GqlField.create('content'),
            GqlField.create('isOperator'),
            GqlSubField.create('author', [
              GqlSubField.create('data', [GqlField.create('name')]),
            ]),
          ]),
        ],
        opt,
        { ticketId: { eq: this.id } },
      ).toPromise();

      source.data.forEach((element) => {
        this.items.splice(0, 0, element);
      });

      this.isEmpty = source.data.length < opt.limit;

      this.skip += 20;
      this.inProgress = false;
      this.readMessage();
    }
  }

  getTextBoxValue(data) {
    if (data != undefined) {
      return this.translateFieldhelperService.findValueToShow(data.name);
    }
    return data;
  }

  getDisplayExpr() {
    return (
      this.messageModel?.displayExpr +
      '.' +
      this.translateFieldhelperService.getTranslateKey()
    );
  }
  /** Gestion du scroll */
  async onScroll(e) {
    if (
      e.scrollOffset.top <= 10 &&
      !this.inProgress &&
      !this.inScroll &&
      !this.isEmpty
    ) {
      this.inScroll = true;
      this.listComponent.component.option('disabled', true);
      let scrollTop = e.scrollOffset.top;
      let lastScrollHeight = e.component.scrollHeight();
      // NEw chargement
      await this.loadItems();

      setTimeout(() => {
        let newScrollHeight = e.component.scrollHeight();
        e.component.scrollBy(newScrollHeight - lastScrollHeight + scrollTop);
        this.listComponent.component.option('disabled', false);
        this.inScroll = false;
      }, 200);
    }
  }

  getId() {
    if (this.model != undefined && this.model.id != undefined) {
      this.id = this.model.id;
    } else {
      this.id = this.route.snapshot.paramMap.get('id');
    }
  }
  mouseEvent(e, value: string = null) {
    if (value != null) {
      setTimeout(() => {
        {
          this.visible = !this.visible;
          this.text = value;
          this.popover_target = e;
        }
      }, 500);
    } else {
      this.visible = false;
    }
  }

  /** @inheritdoc */
  async ngOnInit() {
    this.hideHtmlEditor = !this.isReadOnly;
    this.displayHtmlEditor();
    if (this.id != null) {
      this.removeNotificationEmail();
    }
    //Permet de caché la selection du modèle de ticket pour les utilisateur HELPME
    let helpMe = localStorage.getItem('viewHelpMe') == 'true' ? true : false;
    if (helpMe === true) {
      this.hideSelectMessageModel = false;
    }
    this.readMessage();
    if (this.messageModel != undefined) {
      (this.messageModel?.source?.datasource as DataSource).sort([
        {
          selector:
            'name' + '.' + this.translateFieldhelperService.getTranslateKey(),
          desc: false,
        },
      ]);
    }
    this._gc.forDispose(
      this.model.on.subscribe((data) => {
        if (data.eventName === 'tab-change') {
          if (
            data.eventData.oldTab.item?.control?.type ===
            'MessageTicketComponent'
          ) {
            this.displayBtn = false;
          } else if (
            data.eventData.newTab.item?.control?.type ===
            'MessageTicketComponent'
          ) {
            if (this.htmlEditorField.value !== '' && this.hideHtmlEditor) {
              this.displayBtn = true;
            }
          }
        }
      }),
    );
  }

  private async displayHtmlEditor() {
    if (this.id) {
      this._gc.forDispose(
        await this.ticketService
          .isEndingTicket([GqlField.create('data')], this.id)
          .subscribe((response) => {
            if (response.data && response.data != null) {
              this.hideHtmlEditor = false;
            }
          }),
      );
    }
  }

  firstSaveOnNew: boolean = false;
  subOnSaved;
  noAutoReload: boolean = false;
  onSendMessage(e) {
    // Vérification si deja enregistré
    this.getId();
    if (this.id == undefined) {
      this.firstSaveOnNew = true;
      if (FormGroupHelpers.valid(this.model.formComponent.form) == false) {
        // Validation du formulaire
        alert(
          TranslateService.get('globals/formNotValid'),
          TranslateService.get('globals/warning'),
        );
      } else {
        this.confirmSaveDialog(
          TranslateService.get('confirm-title'),
          TranslateService.get('globals/confirm-refresh'),
          TranslateService.get('save'),
          TranslateService.get('cancel'),
        ).then((r) => {
          if (r) {
            this._gc.forDispose(
              (this.subOnSaved = this.model.formComponent.onSaved.subscribe(
                (d) => {
                  if (this.firstSaveOnNew) {
                    this.firstSaveOnNew = false;
                    this.noAutoReload = true;
                    this.getId();

                    this.saveMessage();
                  }
                },
              )),
            );

            this.model.formComponent
              .save({ close: false })
              .then((result) => {});
          }
        });
      }
    } else {
      this.saveMessage();
    }
  }

  confirmSaveDialog(
    title: string,
    message: string,
    saveButtonMessage: string,
    cancelButtonMessage: string,
  ): Promise<boolean> {
    let buttons: dxButtonOptions[] = [
      {
        type: 'default',
        text: saveButtonMessage,
        onClick: (e) => {
          return true;
        },
      },
      {
        text: cancelButtonMessage,
        onClick: (e) => {
          return false;
        },
      },
    ];

    return custom({
      title: title,
      messageHtml: message,
      buttons: buttons,
    }).show();
  }

  async removeNotificationEmail() {
    this.notficationService
      .removeNotificationByKeys(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.id,
        [
          NotificationKey.MessageIncident,
          NotificationKey.MessageRequest,
          NotificationKey.MessageIncidentTeam,
          NotificationKey.MessageRequestTeam,
        ],
      )
      .subscribe();
  }

  saveMessage() {
    this.itemCount++;
    if (this.iconBtn == 'fal fa-paper-plane') {
      this.iconBtn = 'fas fa-circle-notch fa-spin';
      let entity: MessageInput;
      entity = {
        content: this.htmlEditorField.option('value'),
        ticketId: this.id,
        authorId: this.authService.user.getClaim('userId'),
        isOperator: this.isOperator,
        alertedUsersIds: [],
        fileIds: [],
        date: new Date(),
        entityType: 'incident',
        isSenderUserConcerned: true,
      };

      let returnField = [
        GqlSubField.create('data', [
          GqlField.create('id'),
          GqlSubField.create('author', [
            GqlSubField.create('data', [GqlField.create('name')]),
          ]),
        ]),
      ];

      this._gc.forDispose(
        this.service['insert'](returnField, entity).subscribe((result) => {
          entity['author'] = result?.data?.author;

          //this.source.reload();
          this.items.push(entity);

          if (this.listComponent != undefined) {
            setTimeout(() => {
              this.listComponent.component.scrollBy(
                this.listComponent.component.scrollHeight(),
              );
            }, 100);
          }

          this.htmlEditorField.option('value', '');
          if (this.selectModelField != undefined) {
            this.selectModelField.option('value', '');
          }
          this.enableScroolBottom = true;
          this.iconBtn = 'fal fa-paper-plane';

          this.model.on.emit({
            eventName: 'reload-tabs',
            eventData: undefined,
          });
        }),
      );
    }
  }

  selectMessageModelValueChanged(e) {
    let value = this.translateFieldhelperService.findValueToShow(
      e.value.content,
    );
    this.htmlEditorField.option('value', value);
  }

  readMessage() {
    this.getId();
    if (this.id != undefined) {
      //if(this.isOperator == false){
      let returnField = [GqlField.create('data')];
      this._gc.forDispose(
        this.service['readMessageByTicketId'](
          returnField,
          this.id,
          this.isOperator,
        ).subscribe(),
      );
    }
    //}
  }

  async refresh() {
    if (!this.noAutoReload) {
      this.skip = 0;
      this.isEmpty = false;
      this.items = [];

      this.firstLoad = false;
      await this.loadItems();

      setTimeout(() => {
        this.listComponent.component.scrollBy(
          this.listComponent.component.scrollHeight(),
        );
      }, 200);
    }
    this.noAutoReload = false;
  }

  firstLoad = true;

  async onContentReady(e) {
    if (this.checkIsEndingTicket == false) {
      await this.displayHtmlEditor();
    }

    this.checkIsEndingTicket = false;

    if (this.firstLoad) {
      this.listComponent = e;
      await this.refresh();
      this.itemCount = await this.countItems();
      this.reloader = setInterval(async () => {
        let count = await this.countItems();
        if (this.itemCount != count) {
          this.skip = 0;
          this.isEmpty = false;
          this.items = [];
          await this.loadItems();
          this.itemCount = count;
          this.scrollToLastItem();
        }
      }, 10000);
    }
  }

  onHtmlEditorInitialized(e) {
    this.htmlEditorField = e.component;
  }

  onSelectBoxInitialized(e) {
    this.selectModelField = e.component;
  }

  onMessageChanged(e) {
    this.displayBtn = e.value !== undefined && e.value.trim().length !== 0;
  }

  formatDate(sDate) {
    return new Date(sDate).toLocaleString(Globalize.locale().locale, {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: '2-digit',
      minute: '2-digit',
    });
  }

  async initialized(e) {}

  ngOnDestroy() {
    this._gc.dispose();

    if (this.routerSubsccriber != undefined) {
      this.routerSubsccriber.unsubscribe();
    }
    clearInterval(this.reloader);
  }

  scrollToLastItem() {
    setTimeout(() => {
      const listItems = this.list.instance.option('items');
      this.list.instance.scrollToItem(listItems.length - 1);
    }, 50); //Pour laisser le temps d'afficher les éléments chargés
  }

  /**
   * Pour étendre l'éditeur ou le rétrécir
   */
  expandEditor() {
    this.expand = !this.expand;
    let select = document.querySelector('dx-box').children;
    let second = select.item(1);
    if (this.expand === true) {
      second.attributes[1].value = second.attributes[1].value.replace(
        '160',
        '500',
      );
      second.children.item(0).attributes[1].value = second.children
        .item(0)
        .attributes[1].value.replace('width: auto', 'min-width: 99%');
    } else {
      second.attributes[1].value = second.attributes[1].value.replace(
        '500',
        '160',
      );
      second.children.item(0).attributes[1].value = second.children
        .item(0)
        .attributes[1].value.replace('min-width: 99%', 'width: auto');
    }
  }

  public applyModel(e) {
    // let control = this.controls[0];
    // let fieldControl = FormGroupHelpers.formControlByName(
    //   this.formGroupDirective.form,
    //   control.fieldName,
    // );
    // let value = e.itemData[control.options.resolutionModel.contentExpr];
    // fieldControl.reset();
    // fieldControl.markAsDirty();
    // setTimeout(() => {
    //   fieldControl.setValue(value, { emitEvent: true });
    //   fieldControl.markAsDirty();
    // }, 50);
  }

  public focusInModel(e) {
    //let control = this.controls[0];
    //let datasource = control.options.resolutionModel.source.datasource;
    //datasource.reload();
  }

  /** Gestion des événements du message */
  onFocusDiv(e) {
    e.srcElement.onkeydown = (args) => {
      if (args.ctrlKey !== true) {
        // annule tout appuie de touche different de ctrl
        args.preventDefault();
        args.stopPropagation();
        return false;
      }
    };

    e.srcElement.oncut = (args) => {
      //Interdit le couper
      args.preventDefault();
      args.stopPropagation();
      return false;
    };
    e.srcElement.ondrag = (args) => {
      //Interdit le drag
      args.preventDefault();
      args.stopPropagation();
      return false;
    };
    e.srcElement.ondrop = (args) => {
      //Interdit le drop
      args.preventDefault();
      args.stopPropagation();
      return false;
    };
    e.srcElement.onpaste = (args) => {
      //Interdit le copier
      args.preventDefault();
      args.stopPropagation();
      return false;
    };
    e.srcElement.oncontextmenu = (args) => {
      // pas de menu
      return false;
    };
    e.srcElement.onclick = (args) => {
      if (
        args?.srcElement?.nodeName == 'IMG' &&
        args?.srcElement?.currentSrc != undefined
      ) {
        // ouverture automatique d'une image
        window.open(args?.srcElement?.currentSrc, '_blank');
      }
    };
  }
}
