import { Injectable } from '@angular/core';
import { FindAlarmSecurityGroupsDocument } from '@clarilog/core';
import {
  AuthorizationCoreService,
  ClaimsPrincipal,
} from './authorization.service';
import { Dictionary } from './dictionary';

/** Permet de paramétrer les règles d'autorisation
 * @example
 *  this.addPolicy('user', policy => {
 *    policy.requireClaim('roles', 'administrateur', 'valid-user');
 *  });
 *  this.addPolicy('user-can-add', policy => {
 *    policy
 *      .requireClaim('roles', 'administrateur', 'valid-user')
 *      .requireClaim('features', 'add');
 *  });
 *  this.addPolicy('user-can-edit', policy => {
 *    policy
 *      .requireClaim('roles', 'administrateur', 'valid-user')
 *      .requireClaim('features', 'edit');
 *  });
 */
@Injectable({
  providedIn: 'root',
})
export class CoreAuthorizationSettings {
  settings: Dictionary<IAuthorizationRequirement[]>;
  constructor() {
    this.settings = new Dictionary<IAuthorizationRequirement[]>();
  }
  addAuthorization(
    name: string,
    builder: (builder: AuthorizationPolicyBuilderService) => void,
  ): CoreAuthorizationSettings {
    if (!this.settings.containsKey(name)) {
      let fnBuilder = new AuthorizationPolicyBuilderService();
      builder(fnBuilder);
      this.settings.add(name, fnBuilder.policies);
    }
    return this;
  }
}
/** Représente le type de la règle. */
export type PolicyOperator = 'and' | 'or';
/** Permet de valider les règles à partir de l'utilisateur connecté. */
@Injectable({
  providedIn: 'root',
})
export class CorePolicyValidator {
  constructor(
    private authorizationService: AuthorizationCoreService,
    private authorizationSettings: CoreAuthorizationSettings,
  ) {}
  validate(
    names: string | string[] | any,
    operator: PolicyOperator = 'and',
  ): boolean {
    let result: boolean = false;
    let context = new AuthorizationContext(this.authorizationService.user);

    // if (typeof names === "string" || names.length == 1)
    // {
    //   if(names.includes('||') || names[0].includes('||'))
    //   {
    //     operator = "or";
    //     let array = (typeof names === "string" ? names.split(' || ') : names[0].split(' || ')) as Array<string>;
    //     names =  array.map(x=> x.trim());
    //   }

    //   if(typeof names === "string")
    //   {
    //     names = [names];
    //   }
    // }

    if (typeof names === 'string') {
      names = [names];
    }

    for (let name of names) {
      let check = this.isvalid(name, context);
      if (check === true) {
        if (operator != undefined && operator.toLowerCase() === 'or') {
          return true;
        } else {
          result = true;
        }
      } else {
        if (operator != undefined && operator.toLowerCase() === 'and') {
          return false;
        }
      }
    }

    return result;
  }

  isvalid(policyName: string, context: AuthorizationContext): boolean {
    let result: boolean = false;
    if (policyName.toString().startsWith('/')) {
      let res = this.authorizationSettings.settings._keys.filter((key) =>
        key.match(policyName.substring(1, policyName.length - 1)),
      );
      return res.length > 0;
    } else {
      let settings = this.authorizationSettings.settings.get(policyName);
      if (settings != undefined) {
        for (let setting of settings) {
          result = setting.authorize(context);
          if (result === true) {
            result = true;
          }
        }
      }
      return result;
    }
  }

  /**
   * Permet de gérer l'url de redirection en fonction des permissions de l'opérateur
   * @param url l'url à vérifier
   * @param ticketType "incident" | "request" | "problem"
   * @returns l'url correct
   */
  manageUrlBasedOnPermissions(url: string, ticketType: string) {
    if (url.includes('my')) {
      if (url.includes('/my-team/')) {
        if (!this.validate(`${ticketType}.my-team-${ticketType}s`)) {
          if (this.validate(`${ticketType}.all-${ticketType}s`)) {
            url = url.replace('my-team/', '');
          } else if (this.validate(`${ticketType}.my-${ticketType}s`)) {
            url = url.replace('-team', '');
          }
        }
      } else if (url.includes('/my/')) {
        if (!this.validate(`${ticketType}.my-${ticketType}s`)) {
          if (this.validate(`${ticketType}.all-${ticketType}s`)) {
            url = url.replace('my/', '');
          } else if (this.validate(`${ticketType}.my-team-${ticketType}s`)) {
            url = url.replace('my/', 'my-team/');
          }
        }
      }
    } else {
      if (!this.validate(`${ticketType}.all-${ticketType}s`)) {
        if (this.validate(`${ticketType}.my-team-${ticketType}s`)) {
          url = url.replace(`${ticketType}s/`, `${ticketType}s/my-team/`);
        } else if (this.validate(`${ticketType}.my-${ticketType}s`)) {
          url = url.replace(`${ticketType}s/`, `${ticketType}s/my/`);
        }
      }
    }

    return url;
  }
}
/** Permet de fournir une règle de gestion des autorisations. */
export interface IAuthorizationRequirement {
  authorize(authorizationContext: AuthorizationContext): boolean;
}
/** Représente un système qui permet de tester l'obligation de la présence de la permission. */
class RequireClaim implements IAuthorizationRequirement {
  constructor(private name: string, private values: string[]) {}
  authorize(authorizationContext: AuthorizationContext): boolean {
    if (authorizationContext.user.hasClaim(this.name)) {
      if (this.values != undefined && this.values.length > 0) {
        let value = authorizationContext.user.getClaim(this.name);
        let values = value;
        if (!Array.isArray(value)) {
          values = [value];
        }
        let intersections = values.filter((value) =>
          this.values.includes(value),
        );
        return intersections.length > 0;
      } else {
        return true;
      }
    }
    return false;
  }
}
/** Représente le context d'autorisation. */
export class AuthorizationContext {
  constructor(private _user: ClaimsPrincipal) {}
  get user(): ClaimsPrincipal {
    return this._user;
  }
}
/** Teste si le scope de permission est présent sur l'utilisateur connecté. */
export class PermissionNamespaceRequirement
  implements IAuthorizationRequirement
{
  constructor(private name: string) {}
  authorize(authorizationContext: AuthorizationContext): boolean {
    let permissionsClaim = authorizationContext.user.getClaim('permissions');
    let permissionScopes = permissionsClaim.reduce(function (r, a: string) {
      let key = a.substring(0, a.indexOf('.'));
      r[key] = r[key] || [];
      r[key].push(a);
      return r;
    }, Object.create(null));
    return permissionScopes[this.name] != undefined;
  }
}
/** Teste si la permission est présente sur l'utilisateur connecté. */
export class PermissionRequirement implements IAuthorizationRequirement {
  constructor(private name: string) {}
  authorize(authorizationContext: AuthorizationContext): boolean {
    let permissionsClaim = authorizationContext.user.getClaim('permissions');
    return permissionsClaim.indexOf(this.name) > -1;
  }
}
// A garder pour l'instant.
// export class PermissionAtLeastRequirement implements IAuthorizationRequirement {
//   private names: string[];
//   constructor(...names: string[]) {
//     this.names = names;
//   }
//   authorize(authorizationContext: AuthorizationContext): boolean {
//     let permissionsClaim = authorizationContext.user.getClaim('permissions');
//     for (let name of this.names) {

//       if (permissionsClaim.indexOf(name) > -1) {
//         return true;
//       }
//     }
//     return false;
//   }
// }
/** Représente la classe de gestion des règles d'autorisation. */
@Injectable({
  providedIn: 'root',
})
export class AuthorizationPolicyBuilderService {
  policies: IAuthorizationRequirement[] = [];
  constructor() {}
  requireClaim(
    name: string,
    ...values: string[]
  ): AuthorizationPolicyBuilderService {
    this.policies.push(new RequireClaim(name, values));
    return this;
  }
  addRequirement(requirement: IAuthorizationRequirement) {
    this.policies.push(requirement);
    return this;
  }
}
