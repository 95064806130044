<div class="cl-button-container">
  <button
    class="cl-button cl-button-pin"
    *ngIf="showPin"
    tabindex="-1"
    [ngStyle]="{ visibility: toggle ? 'hidden' : 'visible' }"
    (click)="onPinClick()"
  >
    <i
      class="fal fa-thumbtack"
      style="font-size: 0.8em"
      [ngClass]="{
        'un-pin': !pin,
        pin: pin
      }"
    ></i>
  </button>
  <button
    class="cl-button cl-button-reduce"
    *ngIf="showToggle"
    (click)="toggleClick()"
    tabindex="-1"
  >
    <i
      class="fal"
      [ngClass]="{
        'fa-angle-double-left': !toggle,
        'fa-angle-double-right': toggle
      }"
    ></i>
  </button>
</div>
<div
  class="clc-nav-menu-container"
  [ngClass]="{ 'cl-hide': mode === 'normal' && toggle }"
>
  <div class="clc-nav-menu-panel">
    <ng-content select="clc-nav-group:not([locate=footer])"></ng-content>
  </div>
</div>
<div class="clc-nav-menu-container-footer">
  <mat-divider *ngIf="hasFooter" style="margin: 0 10px"></mat-divider>
  <div class="clc-nav-menu-footer">
    <ng-content select="clc-nav-group[locate=footer]"></ng-content>
  </div>
</div>
