import {
  ChangeDetectorRef,
  Component,
  DoCheck,
  EventEmitter,
  Injector,
  Input,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { TranslateService } from '@clarilog/shared/services';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { ListMode } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';
import { CoreWorkItemsComponent } from '../work-items/work-items.component';
import { Filter } from '@clarilog/shared2/models/schema';
import { ActivatedRoute, NavigationExtras, Router } from '@angular/router';

/** Représente le composent de sélection d'élément. */
@Component({
  selector: 'clc-select-list',
  templateUrl: './select-list.component.html',
  styleUrls: ['./select-list.component.scss'],
})
export class CoreSelectListComponent
  extends ListComponentBase
  implements OnInit, DoCheck
{
  @Input() mode: ListMode = 'default';
  /** Obtient le composant List. */
  @ViewChild(CoreWorkItemsComponent) workItems: CoreWorkItemsComponent;
  /** Se produit lorsque des éléments ont été sélectionnés. */
  @Output() onSelect: EventEmitter<any[]> = new EventEmitter<any[]>();
  /** Se produit lors de l'appui sur le bouton close. */
  @Output() onClosed: EventEmitter<any[]> = new EventEmitter<any[]>();
  /** Obtient ou définit la valeur du label */
  @Input() label: string;
  /** Récupère le titre de la liste de selection */
  @Input() title: string;
  /** Récupère le hint de la liste de selection */
  @Input() hint: string;
  /** Obtient ou définit le texte du bouton. */
  @Input() buttonText: string;
  /** Obtient ou définit le model. */
  model: ModelState;
  /** Obtient ou définit le champ à afficher. */
  @Input() enabledExp: string;
  /** Obtient ou définit les filtres. */
  @Input() filters: Filter[];
  /** Obtient ou définit la valeur de la route de gestion */
  @Input() route: string;

  /** Donne l'accès aux actions de sélection/désélection du noeud parents */
  @Input() activeSelectLevelAction: boolean;

  /** Obtient ou définit le mode compact */
  @Input() compactMode: boolean = false;

  constructor(
    private dc: ChangeDetectorRef,
    private _router: Router,
    private router: ActivatedRoute,
    public _route: ActivatedRoute,
    public _injector: Injector,
  ) {
    super();
    this.model = new ModelState(_injector);
  }

  initModel() {
    if (this.source == undefined) return;
    if (this.type == 'Tree') {
      this.source.datasource.pageSize(500000);
    }
    let filters = this.filters != undefined ? this.filters : [];

    let allString = TranslateService.get('globals/all');
    if (
      filters == undefined ||
      (filters != undefined &&
        filters.filter(
          (f) => f.items.filter((s) => s.text == allString).length == 0,
        ).length == 0)
    ) {
      filters.push({
        items: [
          {
            // Tout : orignal query
            text: allString,
            list: {
              source: <any>this.source,
            },
          },
        ],
      });
    }

    this.model.model = {
      grid: {
        layout: {
          type: this.type || 'Grid',
          parentIdExpr: this.parentIdExpr,
          recursive: this.recursive || false,
          multiple: this.multiple,
          columns: this.columns,
          maxLevel: 2,
          enabledExp: this.enabledExp,
          filters: [...filters],
        },
      },
    };
  }

  ngDoCheck(): void {
    if (this.model == undefined && this.source != undefined) {
      this.initModel();
      this.dc.detectChanges();
    }
  }

  /** Déclenche la sélection. */
  public select() {
    if (
      this.enabledExp != undefined &&
      this.selectedData != undefined &&
      this.selectedData.length > 0
    ) {
      if (
        this.selectedData[0] != undefined &&
        this.selectedData[0][this.enabledExp] === false
      ) {
        this.selectedData = [];
        return;
      }
    }
    // this.list.component.hideColumnChooser();
    this.onSelect.emit(this.selectedData);
  }
  /** Déclenche l'évènement close.. */
  public close() {
    // if (this.list != undefined && this.list.component != undefined) {
    //   this.list.component.hideColumnChooser();
    // }
    this.onClosed.emit();
  }
  /** Efface la liste. */
  public clear() {
    if (this.workItems != undefined) {
      this.workItems.clear();
    }
  }

  // Set le filtre
  public filter(filter) {
    if (this.workItems?.list != undefined) {
      this.workItems.filter(filter);
    }
  }

  /** Rafraichit la liste */
  public refresh(force: boolean = false) {
    if (force) {
      this.initModel();
      this.dc.detectChanges();
    }
    if (this.workItems != undefined) {
      this.workItems.refresh();
    }
  }
  /** @inheritdoc */
  ngOnInit(): void {
    if (this.title == undefined) {
      this.title = TranslateService.get('addNewElements');
    }
    this.initModel();
  }

  /** Active ou désactive le bouton */
  canDisabled() {
    if (this.selectedKeys != undefined) {
      return this.selectedKeys.length === 0;
    }
    return true;
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo(defaultRoute: boolean = false) {
    let id = this.router.snapshot.paramMap.get('id');

    /** liste les différents paramètres à intégrer dans l'url */
    let filter: object = {};
    if (id != null) {
      /** Ajoute de l'id dans l'objet filter */
      Object.assign(filter, { type: id });
    }
    let url = this._router.createUrlTree([this.route], {
      skipLocationChange: true,
    } as NavigationExtras);

    let win = window.open(this._router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }
}
