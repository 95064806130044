import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScannerCountsBaseVariables, FirstScannerCountsBaseVariables, CountScannerCountsBaseVariables, FindScannerCountsBaseVariables, SearchScannerCountsBaseVariables, ExportSchemaScannerCountsBaseVariables, ExportDataScannerCountsBaseVariables, CustomQueryScannerCountsBaseVariables, CustomQueryIdScannerCountsBaseVariables, UsedScannerCountsBaseVariables, ExistScannerCountsBaseVariables } from '../gqls'
import { GetScannerCountsDocument, FirstScannerCountsDocument, CountScannerCountsDocument, FindScannerCountsDocument, SearchScannerCountsDocument, ExportSchemaScannerCountsDocument, ExportDataScannerCountsDocument, CustomQueryScannerCountsDocument, CustomQueryIdScannerCountsDocument, UsedScannerCountsDocument, ExistScannerCountsDocument } from '../gqls'
import { ServiceSingleResultOfScannerCount, QueryContextOfScannerCount, FilterOfScannerCount, ServiceSingleResultOfInt64, ServiceListResultOfScannerCount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScannerCountBaseService {
    
public modelName = 'scannerCount';
public modelPluralName = 'scannerCounts';

	private getScannerCountsQuery: GetScannerCountsDocument;
	private firstScannerCountsQuery: FirstScannerCountsDocument;
	private countScannerCountsQuery: CountScannerCountsDocument;
	private findScannerCountsQuery: FindScannerCountsDocument;
	private searchScannerCountsQuery: SearchScannerCountsDocument;
	private exportSchemaScannerCountsQuery: ExportSchemaScannerCountsDocument;
	private exportDataScannerCountsQuery: ExportDataScannerCountsDocument;
	private customQueryScannerCountsQuery: CustomQueryScannerCountsDocument;
	private customQueryIdScannerCountsQuery: CustomQueryIdScannerCountsDocument;
	private usedScannerCountsQuery: UsedScannerCountsDocument;
	private existScannerCountsQuery: ExistScannerCountsDocument;

	constructor(private injector: Injector) {
		this.getScannerCountsQuery = this.injector.get(GetScannerCountsDocument);
		this.firstScannerCountsQuery = this.injector.get(FirstScannerCountsDocument);
		this.countScannerCountsQuery = this.injector.get(CountScannerCountsDocument);
		this.findScannerCountsQuery = this.injector.get(FindScannerCountsDocument);
		this.searchScannerCountsQuery = this.injector.get(SearchScannerCountsDocument);
		this.exportSchemaScannerCountsQuery = this.injector.get(ExportSchemaScannerCountsDocument);
		this.exportDataScannerCountsQuery = this.injector.get(ExportDataScannerCountsDocument);
		this.customQueryScannerCountsQuery = this.injector.get(CustomQueryScannerCountsDocument);
		this.customQueryIdScannerCountsQuery = this.injector.get(CustomQueryIdScannerCountsDocument);
		this.usedScannerCountsQuery = this.injector.get(UsedScannerCountsDocument);
		this.existScannerCountsQuery = this.injector.get(ExistScannerCountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScannerCount> {
    
    		let variables: GetScannerCountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.get));
            }
            else{
                let result:ServiceSingleResultOfScannerCount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScannerCount,
		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScannerCount> {
    
    		let variables: FirstScannerCountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScannerCountsBaseVariables = {
    			filter: filter
    		}
    				return this.countScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScannerCount,
		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScannerCount> {
    
    		let variables: FindScannerCountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScannerCount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScannerCount> {
    
    		let variables: SearchScannerCountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScannerCountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScannerCountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScannerCount,
		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScannerCount> {
    
    		let variables: CustomQueryScannerCountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScannerCount,
		@Args('filter?') filter?: FilterOfScannerCount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScannerCount> {
    
    		let variables: CustomQueryIdScannerCountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScannerCountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScannerCountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScannerCountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scannerCounts.exist));
    	}
    
    
}