import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketMonthlyBacklogsBaseVariables, FirstTicketMonthlyBacklogsBaseVariables, CountTicketMonthlyBacklogsBaseVariables, FindTicketMonthlyBacklogsBaseVariables, SearchTicketMonthlyBacklogsBaseVariables, ExportSchemaTicketMonthlyBacklogsBaseVariables, ExportDataTicketMonthlyBacklogsBaseVariables, CustomQueryTicketMonthlyBacklogsBaseVariables, CustomQueryIdTicketMonthlyBacklogsBaseVariables, UsedTicketMonthlyBacklogsBaseVariables, ExistTicketMonthlyBacklogsBaseVariables, InsertTicketMonthlyBacklogsBaseVariables, UpdateTicketMonthlyBacklogsBaseVariables, ImportDataTicketMonthlyBacklogsBaseVariables, UpdateManyTicketMonthlyBacklogsBaseVariables, DeleteTicketMonthlyBacklogsBaseVariables } from '../gqls'
import { GetTicketMonthlyBacklogsDocument, FirstTicketMonthlyBacklogsDocument, CountTicketMonthlyBacklogsDocument, FindTicketMonthlyBacklogsDocument, SearchTicketMonthlyBacklogsDocument, ExportSchemaTicketMonthlyBacklogsDocument, ExportDataTicketMonthlyBacklogsDocument, CustomQueryTicketMonthlyBacklogsDocument, CustomQueryIdTicketMonthlyBacklogsDocument, UsedTicketMonthlyBacklogsDocument, ExistTicketMonthlyBacklogsDocument, InsertTicketMonthlyBacklogsDocument, UpdateTicketMonthlyBacklogsDocument, ImportDataTicketMonthlyBacklogsDocument, UpdateManyTicketMonthlyBacklogsDocument, DeleteTicketMonthlyBacklogsDocument } from '../gqls'
import { ServiceSingleResultOfTicketMonthlyBacklog, QueryContextOfTicketMonthlyBacklog, FilterOfTicketMonthlyBacklog, ServiceSingleResultOfInt64, ServiceListResultOfTicketMonthlyBacklog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, TicketMonthlyBacklogInput, FieldUpdateOperatorOfTicketMonthlyBacklog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketMonthlyBacklogBaseService {
    
public modelName = 'ticketMonthlyBacklog';
public modelPluralName = 'ticketMonthlyBacklogs';

	private getTicketMonthlyBacklogsQuery: GetTicketMonthlyBacklogsDocument;
	private firstTicketMonthlyBacklogsQuery: FirstTicketMonthlyBacklogsDocument;
	private countTicketMonthlyBacklogsQuery: CountTicketMonthlyBacklogsDocument;
	private findTicketMonthlyBacklogsQuery: FindTicketMonthlyBacklogsDocument;
	private searchTicketMonthlyBacklogsQuery: SearchTicketMonthlyBacklogsDocument;
	private exportSchemaTicketMonthlyBacklogsQuery: ExportSchemaTicketMonthlyBacklogsDocument;
	private exportDataTicketMonthlyBacklogsQuery: ExportDataTicketMonthlyBacklogsDocument;
	private customQueryTicketMonthlyBacklogsQuery: CustomQueryTicketMonthlyBacklogsDocument;
	private customQueryIdTicketMonthlyBacklogsQuery: CustomQueryIdTicketMonthlyBacklogsDocument;
	private usedTicketMonthlyBacklogsQuery: UsedTicketMonthlyBacklogsDocument;
	private existTicketMonthlyBacklogsQuery: ExistTicketMonthlyBacklogsDocument;
	private insertTicketMonthlyBacklogsMutation: InsertTicketMonthlyBacklogsDocument;
	private updateTicketMonthlyBacklogsMutation: UpdateTicketMonthlyBacklogsDocument;
	private importDataTicketMonthlyBacklogsMutation: ImportDataTicketMonthlyBacklogsDocument;
	private updateManyTicketMonthlyBacklogsMutation: UpdateManyTicketMonthlyBacklogsDocument;
	private deleteTicketMonthlyBacklogsMutation: DeleteTicketMonthlyBacklogsDocument;

	constructor(private injector: Injector) {
		this.getTicketMonthlyBacklogsQuery = this.injector.get(GetTicketMonthlyBacklogsDocument);
		this.firstTicketMonthlyBacklogsQuery = this.injector.get(FirstTicketMonthlyBacklogsDocument);
		this.countTicketMonthlyBacklogsQuery = this.injector.get(CountTicketMonthlyBacklogsDocument);
		this.findTicketMonthlyBacklogsQuery = this.injector.get(FindTicketMonthlyBacklogsDocument);
		this.searchTicketMonthlyBacklogsQuery = this.injector.get(SearchTicketMonthlyBacklogsDocument);
		this.exportSchemaTicketMonthlyBacklogsQuery = this.injector.get(ExportSchemaTicketMonthlyBacklogsDocument);
		this.exportDataTicketMonthlyBacklogsQuery = this.injector.get(ExportDataTicketMonthlyBacklogsDocument);
		this.customQueryTicketMonthlyBacklogsQuery = this.injector.get(CustomQueryTicketMonthlyBacklogsDocument);
		this.customQueryIdTicketMonthlyBacklogsQuery = this.injector.get(CustomQueryIdTicketMonthlyBacklogsDocument);
		this.usedTicketMonthlyBacklogsQuery = this.injector.get(UsedTicketMonthlyBacklogsDocument);
		this.existTicketMonthlyBacklogsQuery = this.injector.get(ExistTicketMonthlyBacklogsDocument);
		this.insertTicketMonthlyBacklogsMutation = this.injector.get(InsertTicketMonthlyBacklogsDocument);
		this.updateTicketMonthlyBacklogsMutation = this.injector.get(UpdateTicketMonthlyBacklogsDocument);
		this.importDataTicketMonthlyBacklogsMutation = this.injector.get(ImportDataTicketMonthlyBacklogsDocument);
		this.updateManyTicketMonthlyBacklogsMutation = this.injector.get(UpdateManyTicketMonthlyBacklogsDocument);
		this.deleteTicketMonthlyBacklogsMutation = this.injector.get(DeleteTicketMonthlyBacklogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketMonthlyBacklog> {
    
    		let variables: GetTicketMonthlyBacklogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.get));
            }
            else{
                let result:ServiceSingleResultOfTicketMonthlyBacklog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketMonthlyBacklog,
		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketMonthlyBacklog> {
    
    		let variables: FirstTicketMonthlyBacklogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketMonthlyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketMonthlyBacklog,
		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketMonthlyBacklog> {
    
    		let variables: FindTicketMonthlyBacklogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketMonthlyBacklog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketMonthlyBacklog> {
    
    		let variables: SearchTicketMonthlyBacklogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketMonthlyBacklogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketMonthlyBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketMonthlyBacklog,
		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketMonthlyBacklog> {
    
    		let variables: CustomQueryTicketMonthlyBacklogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketMonthlyBacklog,
		@Args('filter?') filter?: FilterOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketMonthlyBacklog> {
    
    		let variables: CustomQueryIdTicketMonthlyBacklogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketMonthlyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketMonthlyBacklogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketMonthlyBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketMonthlyBacklogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketMonthlyBacklog> {
    
    		let variables: InsertTicketMonthlyBacklogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketMonthlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketMonthlyBacklog> {
    
    		let variables: UpdateTicketMonthlyBacklogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketMonthlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketMonthlyBacklogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketMonthlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketMonthlyBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketMonthlyBacklogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketMonthlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketMonthlyBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketMonthlyBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketMonthlyBacklogs.delete));
    	}
    
}