import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { SoftwareHostedComponentBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfSoftwareHostedComponent,
  QueryContextOfSoftwareHostedComponent,
  ServiceListResultOfSoftwareHostedComponent,
  Sort,
} from '../types';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
@Authorize('software.manage-software-hosted-component')
export class SoftwareHostedComponentCoreService extends SoftwareHostedComponentBaseService {
  constructor(
    injector: Injector,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findSortByName(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfSoftwareHostedComponent,
    @Args('filter?') filter?: FilterOfSoftwareHostedComponent,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfSoftwareHostedComponent> {
    if (options == undefined) {
      options = {};
    }

    let sort = this.translatedFieldHelperService.getSortDefault(Sort.Asc);
    options.sort = [{ name: [sort] }];
    return this.find(fields, options, filter, extendedVariables);
  }
}
