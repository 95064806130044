import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetMonitorsBaseVariables, FirstMonitorsBaseVariables, CountMonitorsBaseVariables, FindMonitorsBaseVariables, SearchMonitorsBaseVariables, ExportSchemaMonitorsBaseVariables, ExportDataMonitorsBaseVariables, CustomQueryMonitorsBaseVariables, CustomQueryIdMonitorsBaseVariables, UsedMonitorsBaseVariables, ExistMonitorsBaseVariables } from '../gqls'
import { GetMonitorsDocument, FirstMonitorsDocument, CountMonitorsDocument, FindMonitorsDocument, SearchMonitorsDocument, ExportSchemaMonitorsDocument, ExportDataMonitorsDocument, CustomQueryMonitorsDocument, CustomQueryIdMonitorsDocument, UsedMonitorsDocument, ExistMonitorsDocument } from '../gqls'
import { ServiceSingleResultOfMonitor, QueryContextOfMonitor, FilterOfMonitor, ServiceSingleResultOfInt64, ServiceListResultOfMonitor, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class MonitorBaseService {
    
public modelName = 'monitor';
public modelPluralName = 'monitors';

	private getMonitorsQuery: GetMonitorsDocument;
	private firstMonitorsQuery: FirstMonitorsDocument;
	private countMonitorsQuery: CountMonitorsDocument;
	private findMonitorsQuery: FindMonitorsDocument;
	private searchMonitorsQuery: SearchMonitorsDocument;
	private exportSchemaMonitorsQuery: ExportSchemaMonitorsDocument;
	private exportDataMonitorsQuery: ExportDataMonitorsDocument;
	private customQueryMonitorsQuery: CustomQueryMonitorsDocument;
	private customQueryIdMonitorsQuery: CustomQueryIdMonitorsDocument;
	private usedMonitorsQuery: UsedMonitorsDocument;
	private existMonitorsQuery: ExistMonitorsDocument;

	constructor(private injector: Injector) {
		this.getMonitorsQuery = this.injector.get(GetMonitorsDocument);
		this.firstMonitorsQuery = this.injector.get(FirstMonitorsDocument);
		this.countMonitorsQuery = this.injector.get(CountMonitorsDocument);
		this.findMonitorsQuery = this.injector.get(FindMonitorsDocument);
		this.searchMonitorsQuery = this.injector.get(SearchMonitorsDocument);
		this.exportSchemaMonitorsQuery = this.injector.get(ExportSchemaMonitorsDocument);
		this.exportDataMonitorsQuery = this.injector.get(ExportDataMonitorsDocument);
		this.customQueryMonitorsQuery = this.injector.get(CustomQueryMonitorsDocument);
		this.customQueryIdMonitorsQuery = this.injector.get(CustomQueryIdMonitorsDocument);
		this.usedMonitorsQuery = this.injector.get(UsedMonitorsDocument);
		this.existMonitorsQuery = this.injector.get(ExistMonitorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMonitor> {
    
    		let variables: GetMonitorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.get));
            }
            else{
                let result:ServiceSingleResultOfMonitor={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMonitor,
		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfMonitor> {
    
    		let variables: FirstMonitorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountMonitorsBaseVariables = {
    			filter: filter
    		}
    				return this.countMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfMonitor,
		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMonitor> {
    
    		let variables: FindMonitorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfMonitor,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMonitor> {
    
    		let variables: SearchMonitorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaMonitorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataMonitorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfMonitor,
		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMonitor> {
    
    		let variables: CustomQueryMonitorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfMonitor,
		@Args('filter?') filter?: FilterOfMonitor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfMonitor> {
    
    		let variables: CustomQueryIdMonitorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedMonitorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistMonitorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existMonitorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.monitors.exist));
    	}
    
    
}