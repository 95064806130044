import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCalendarConnectorsBaseVariables, FirstCalendarConnectorsBaseVariables, CountCalendarConnectorsBaseVariables, FindCalendarConnectorsBaseVariables, SearchCalendarConnectorsBaseVariables, ExportSchemaCalendarConnectorsBaseVariables, ExportDataCalendarConnectorsBaseVariables, CustomQueryCalendarConnectorsBaseVariables, CustomQueryIdCalendarConnectorsBaseVariables, UsedCalendarConnectorsBaseVariables, FindOperatorsCalendarConnectorsBaseVariables, FindNotOperatorsCalendarConnectorsBaseVariables, ExistCalendarConnectorsBaseVariables, FindRecyclesCalendarConnectorsBaseVariables, CountRecyclesCalendarConnectorsBaseVariables, ReadOnlyCalendarConnectorsBaseVariables, FindArchivedCalendarConnectorsBaseVariables, CountAllCalendarConnectorsBaseVariables, FindDynamicPropertyFieldsCalendarConnectorsBaseVariables, FindUnassociatedUsersCalendarConnectorsBaseVariables, UpdateManyCalendarConnectorsBaseVariables, DeleteCalendarConnectorsBaseVariables, AddSecurityGroupCalendarConnectorsBaseVariables, RemoveSecurityGroupCalendarConnectorsBaseVariables, RestoreCalendarConnectorsBaseVariables, RecycleCalendarConnectorsBaseVariables, RestoreArchivedCalendarConnectorsBaseVariables, ArchivedCalendarConnectorsBaseVariables, AddFileDynamicFieldCalendarConnectorsBaseVariables, RemoveFileDynamicFieldCalendarConnectorsBaseVariables, AddUsersCalendarConnectorsBaseVariables, RemoveUsersCalendarConnectorsBaseVariables } from '../gqls'
import { GetCalendarConnectorsDocument, FirstCalendarConnectorsDocument, CountCalendarConnectorsDocument, FindCalendarConnectorsDocument, SearchCalendarConnectorsDocument, ExportSchemaCalendarConnectorsDocument, ExportDataCalendarConnectorsDocument, CustomQueryCalendarConnectorsDocument, CustomQueryIdCalendarConnectorsDocument, UsedCalendarConnectorsDocument, FindOperatorsCalendarConnectorsDocument, FindNotOperatorsCalendarConnectorsDocument, ExistCalendarConnectorsDocument, FindRecyclesCalendarConnectorsDocument, CountRecyclesCalendarConnectorsDocument, ReadOnlyCalendarConnectorsDocument, FindArchivedCalendarConnectorsDocument, CountAllCalendarConnectorsDocument, FindDynamicPropertyFieldsCalendarConnectorsDocument, FindUnassociatedUsersCalendarConnectorsDocument, UpdateManyCalendarConnectorsDocument, DeleteCalendarConnectorsDocument, AddSecurityGroupCalendarConnectorsDocument, RemoveSecurityGroupCalendarConnectorsDocument, RestoreCalendarConnectorsDocument, RecycleCalendarConnectorsDocument, RestoreArchivedCalendarConnectorsDocument, ArchivedCalendarConnectorsDocument, AddFileDynamicFieldCalendarConnectorsDocument, RemoveFileDynamicFieldCalendarConnectorsDocument, AddUsersCalendarConnectorsDocument, RemoveUsersCalendarConnectorsDocument } from '../gqls'
import { ServiceSingleResultOfCalendarConnector, QueryContextOfCalendarConnector, FilterOfCalendarConnector, ServiceSingleResultOfInt64, ServiceListResultOfCalendarConnector, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfCalendarConnector } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CalendarConnectorBaseService {
    
public modelName = 'calendarConnector';
public modelPluralName = 'calendarConnectors';

	private getCalendarConnectorsQuery: GetCalendarConnectorsDocument;
	private firstCalendarConnectorsQuery: FirstCalendarConnectorsDocument;
	private countCalendarConnectorsQuery: CountCalendarConnectorsDocument;
	private findCalendarConnectorsQuery: FindCalendarConnectorsDocument;
	private searchCalendarConnectorsQuery: SearchCalendarConnectorsDocument;
	private exportSchemaCalendarConnectorsQuery: ExportSchemaCalendarConnectorsDocument;
	private exportDataCalendarConnectorsQuery: ExportDataCalendarConnectorsDocument;
	private customQueryCalendarConnectorsQuery: CustomQueryCalendarConnectorsDocument;
	private customQueryIdCalendarConnectorsQuery: CustomQueryIdCalendarConnectorsDocument;
	private usedCalendarConnectorsQuery: UsedCalendarConnectorsDocument;
	private findOperatorsCalendarConnectorsQuery: FindOperatorsCalendarConnectorsDocument;
	private findNotOperatorsCalendarConnectorsQuery: FindNotOperatorsCalendarConnectorsDocument;
	private existCalendarConnectorsQuery: ExistCalendarConnectorsDocument;
	private findRecyclesCalendarConnectorsQuery: FindRecyclesCalendarConnectorsDocument;
	private countRecyclesCalendarConnectorsQuery: CountRecyclesCalendarConnectorsDocument;
	private readOnlyCalendarConnectorsQuery: ReadOnlyCalendarConnectorsDocument;
	private findArchivedCalendarConnectorsQuery: FindArchivedCalendarConnectorsDocument;
	private countAllCalendarConnectorsQuery: CountAllCalendarConnectorsDocument;
	private findDynamicPropertyFieldsCalendarConnectorsQuery: FindDynamicPropertyFieldsCalendarConnectorsDocument;
	private findUnassociatedUsersCalendarConnectorsQuery: FindUnassociatedUsersCalendarConnectorsDocument;
	private updateManyCalendarConnectorsMutation: UpdateManyCalendarConnectorsDocument;
	private deleteCalendarConnectorsMutation: DeleteCalendarConnectorsDocument;
	private addSecurityGroupCalendarConnectorsMutation: AddSecurityGroupCalendarConnectorsDocument;
	private removeSecurityGroupCalendarConnectorsMutation: RemoveSecurityGroupCalendarConnectorsDocument;
	private restoreCalendarConnectorsMutation: RestoreCalendarConnectorsDocument;
	private recycleCalendarConnectorsMutation: RecycleCalendarConnectorsDocument;
	private restoreArchivedCalendarConnectorsMutation: RestoreArchivedCalendarConnectorsDocument;
	private archivedCalendarConnectorsMutation: ArchivedCalendarConnectorsDocument;
	private addFileDynamicFieldCalendarConnectorsMutation: AddFileDynamicFieldCalendarConnectorsDocument;
	private removeFileDynamicFieldCalendarConnectorsMutation: RemoveFileDynamicFieldCalendarConnectorsDocument;
	private addUsersCalendarConnectorsMutation: AddUsersCalendarConnectorsDocument;
	private removeUsersCalendarConnectorsMutation: RemoveUsersCalendarConnectorsDocument;

	constructor(private injector: Injector) {
		this.getCalendarConnectorsQuery = this.injector.get(GetCalendarConnectorsDocument);
		this.firstCalendarConnectorsQuery = this.injector.get(FirstCalendarConnectorsDocument);
		this.countCalendarConnectorsQuery = this.injector.get(CountCalendarConnectorsDocument);
		this.findCalendarConnectorsQuery = this.injector.get(FindCalendarConnectorsDocument);
		this.searchCalendarConnectorsQuery = this.injector.get(SearchCalendarConnectorsDocument);
		this.exportSchemaCalendarConnectorsQuery = this.injector.get(ExportSchemaCalendarConnectorsDocument);
		this.exportDataCalendarConnectorsQuery = this.injector.get(ExportDataCalendarConnectorsDocument);
		this.customQueryCalendarConnectorsQuery = this.injector.get(CustomQueryCalendarConnectorsDocument);
		this.customQueryIdCalendarConnectorsQuery = this.injector.get(CustomQueryIdCalendarConnectorsDocument);
		this.usedCalendarConnectorsQuery = this.injector.get(UsedCalendarConnectorsDocument);
		this.findOperatorsCalendarConnectorsQuery = this.injector.get(FindOperatorsCalendarConnectorsDocument);
		this.findNotOperatorsCalendarConnectorsQuery = this.injector.get(FindNotOperatorsCalendarConnectorsDocument);
		this.existCalendarConnectorsQuery = this.injector.get(ExistCalendarConnectorsDocument);
		this.findRecyclesCalendarConnectorsQuery = this.injector.get(FindRecyclesCalendarConnectorsDocument);
		this.countRecyclesCalendarConnectorsQuery = this.injector.get(CountRecyclesCalendarConnectorsDocument);
		this.readOnlyCalendarConnectorsQuery = this.injector.get(ReadOnlyCalendarConnectorsDocument);
		this.findArchivedCalendarConnectorsQuery = this.injector.get(FindArchivedCalendarConnectorsDocument);
		this.countAllCalendarConnectorsQuery = this.injector.get(CountAllCalendarConnectorsDocument);
		this.findDynamicPropertyFieldsCalendarConnectorsQuery = this.injector.get(FindDynamicPropertyFieldsCalendarConnectorsDocument);
		this.findUnassociatedUsersCalendarConnectorsQuery = this.injector.get(FindUnassociatedUsersCalendarConnectorsDocument);
		this.updateManyCalendarConnectorsMutation = this.injector.get(UpdateManyCalendarConnectorsDocument);
		this.deleteCalendarConnectorsMutation = this.injector.get(DeleteCalendarConnectorsDocument);
		this.addSecurityGroupCalendarConnectorsMutation = this.injector.get(AddSecurityGroupCalendarConnectorsDocument);
		this.removeSecurityGroupCalendarConnectorsMutation = this.injector.get(RemoveSecurityGroupCalendarConnectorsDocument);
		this.restoreCalendarConnectorsMutation = this.injector.get(RestoreCalendarConnectorsDocument);
		this.recycleCalendarConnectorsMutation = this.injector.get(RecycleCalendarConnectorsDocument);
		this.restoreArchivedCalendarConnectorsMutation = this.injector.get(RestoreArchivedCalendarConnectorsDocument);
		this.archivedCalendarConnectorsMutation = this.injector.get(ArchivedCalendarConnectorsDocument);
		this.addFileDynamicFieldCalendarConnectorsMutation = this.injector.get(AddFileDynamicFieldCalendarConnectorsDocument);
		this.removeFileDynamicFieldCalendarConnectorsMutation = this.injector.get(RemoveFileDynamicFieldCalendarConnectorsDocument);
		this.addUsersCalendarConnectorsMutation = this.injector.get(AddUsersCalendarConnectorsDocument);
		this.removeUsersCalendarConnectorsMutation = this.injector.get(RemoveUsersCalendarConnectorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCalendarConnector> {
    
    		let variables: GetCalendarConnectorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.get));
            }
            else{
                let result:ServiceSingleResultOfCalendarConnector={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCalendarConnector> {
    
    		let variables: FirstCalendarConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCalendarConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: FindCalendarConnectorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: SearchCalendarConnectorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCalendarConnectorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCalendarConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: CustomQueryCalendarConnectorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: CustomQueryIdCalendarConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.used));
    	}

    	/**  */
    	@InjectArgs
    	public findOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindOperatorsCalendarConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findOperatorsCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findOperators));
    	}

    	/**  */
    	@InjectArgs
    	public findNotOperators(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindNotOperatorsCalendarConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotOperatorsCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findNotOperators));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCalendarConnectorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: FindRecyclesCalendarConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesCalendarConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCalendarConnectorsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCalendarConnector,
		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCalendarConnector> {
    
    		let variables: FindArchivedCalendarConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllCalendarConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsCalendarConnectorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedUsers(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfUser> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('users', fields, null, [
			GqlSubFieldArg.create('filterOfUsers', 'filter'),
			GqlSubFieldArg.create('optionsOfUsers', 'options'),
		]),
		])
		extendedVariables['filterOfUsers'] = filter;
		extendedVariables['optionsOfUsers'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.users));
            }
            else{
                let result: ServiceListResultOfUser = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindUnassociatedUsersCalendarConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedUsersCalendarConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.findUnassociatedUsers));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCalendarConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCalendarConnectorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupCalendarConnectorsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupCalendarConnectorsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.removeSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedCalendarConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldCalendarConnectorsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldCalendarConnectorsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddUsersCalendarConnectorsBaseVariables = {
    			id: id,
			userIds: userIds
    		}
    				return this.addUsersCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.addUsers));
    	}

    	/**  */
    	@InjectArgs
    	public removeUsers(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('userIds') userIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveUsersCalendarConnectorsBaseVariables = {
    			userIds: userIds
    		}
    				return this.removeUsersCalendarConnectorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.calendarConnectors.removeUsers));
    	}
    
}