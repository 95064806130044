<div #testBM>
  <dx-speed-dial-action
    [icon]="icon"
    [label]="title"
    (onClick)="onButtonClick($event)"
    [visible]="_visible"
  >
  </dx-speed-dial-action>
</div>

<div *ngIf="dataSource != undefined">
  <dx-popup
    [id]="popupId"
    [(visible)]="isDropdownPopupVisible"
    [hideOnOutsideClick]="true"
    [maxWidth]="400"
    [maxHeight]="250"
    [title]="popupText"
  >
    <dx-list
      [dataSource]="dataSource"
      [displayExpr]="finalDisplayExpr"
      (onItemClick)="onItemClick($event)"
    ></dx-list>
  </dx-popup>
</div>

<!--<dx-speed-dial-action-->
<!--  *ngIf='hasSave'-->
<!--  icon="fa-solid fa-floppy-disk-circle-xmark"-->
<!--  label="Enregistrer"-->
<!--  [index]="1"-->
<!--&gt;-->
<!--</dx-speed-dial-action>-->

<!--<dx-speed-dial-action-->
<!--  icon="fas fa-home"-->
<!--  label="Changement de status"-->
<!--  [index]="2"-->
<!--&gt;-->
<!--</dx-speed-dial-action>-->
<!--<dx-speed-dial-action-->
<!--  icon="edit"-->
<!--  label="Affectation"-->
<!--  [index]="3"-->
<!--&gt;-->
<!--</dx-speed-dial-action>-->
