import {
  Component,
  ContentChild,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { StateManagementService } from '@clarilog/core';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { Observable, Subscription } from 'rxjs';
import { ClickEventArgs, CoreListComponent } from '../list/list.component';
import { ValidationError } from '@clarilog/core/services2/graphql/generated-types/types';
import { EventArgs } from '../../nav-menu/nav-menu-item/nav-menu-item.component';
import { ButtonTreeViewOptions, Filter } from '@clarilog/shared2/models/schema';
import { ToolbarItemsComponent } from '../../toolbar/toolbar-items/toolbar-items.component';
import { ListType } from '../list/list.component-base';
import { Location } from '@angular/common';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

/** Permet de créer une page liste généré via un schema. */
@Component({
  selector: 'clc-work-items',
  templateUrl: './work-items.component.html',
  styleUrls: ['./work-items.component.scss'],
})
export class CoreWorkItemsComponent implements OnChanges, OnInit, OnDestroy {
  @Input() errors: ValidationError[];
  /** Filtre sélectionné. */
  current: ButtonTreeViewOptions;
  /** Active ou désactive l'affichage du bouton retour. */
  @Input() showBack: boolean = false;
  /**  Obtient ou définit le mode de retour.. */
  @Input() backMode: boolean = false;
  /** Obtient ou définit le model basé sur le schema de la liste. */
  @Input() modelState: ModelState;
  /** Obtient ou définit les resources. */
  @Input() resources: any;
  /** Obtient ou définit les paramètres. */
  @Input() parameters: any;
  /** Obtient ou définit le titre. */
  @Input() title: string = undefined;
  /** Obtient ou définit le hint. */
  @Input() hint: string = undefined;
  /** Obtient ou définit les clés sélectionnés. */
  @Input() selectedKeys: string[] = [];
  /** Obtient ou définit les clés sélectionnés. */
  @Input() selectedData: any[] = [];
  /** Optient ou définit si on peut exporter les données */
  @Input() canExport: boolean = true;
  /** Obtient ou définit les actions de la liste. */
  @ContentChild(ToolbarItemsComponent, { static: true })
  toolbarItems: ToolbarItemsComponent;
  /** Obtient ou définit la liste. */
  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;
  /** Obtient ou définit la clé du layout. */
  @Input() layoutKey: string;
  /** Obtient definis le scope du state pour un metier */
  @Input() stateScope: string[];
  /** Obtient ou définit si on utilise le previous url */
  @Input() usePreviousBackUrl: boolean = false;
  /** Obtient ou définit l'url de retour */
  @Input() backUrl: string;
  /** Obtient ou définit le type de la liste. */
  @Input() type: ListType = undefined;

  /** Donne l'accès aux actions de sélection/désélection du noeud parents */
  @Input() activeSelectLevelAction: boolean;

  /** Obtient ou définit le delais de refresh du datasource. */
  @Input() delayAutoRefresh: number = undefined;

  /** Obtient ou définit si la checkbox auto-refresh est affichée ou non */
  @Input() displayAutoRefreshCheckbox: boolean = true;

  /** Si à false => Pause l'auto-refresh d'une liste pendant un certain moment (jusqu'au refresh de la page) */
  @Input() enableForceAutoRefresh: boolean = true;

  /** Obtient ou définit si la sélection est active. */
  @Input() canSelect: boolean = true;
  /** Se déclenche sur le clic d'une ligne. */
  @Output() onRowClick: EventEmitter<ClickEventArgs<CoreListComponent, any>> =
    new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  @Output() onSingleCellClick: EventEmitter<
    ClickEventArgs<CoreListComponent, any>
  > = new EventEmitter<ClickEventArgs<CoreListComponent, any>>();

  /** Se déclenche sur si la sélection change. */
  @Output() onSelectionChanged: EventEmitter<EventArgs<any>> = new EventEmitter<
    EventArgs<any>
  >();

  /** Se déclenche lors du changement de la selection d'une liste du menu de navigation */
  @Output()
  onNavListSelectionChanged: EventEmitter<any> = new EventEmitter<any>();

  /** Événement de rafraîchissement */
  @Output() onBeforeRefresh: EventEmitter<EventArgs<any>> = new EventEmitter<
    EventArgs<any>
  >();

  /** Sauvegarde le filtre actif */
  saveFilter: any;

  forceNavMenu: boolean = false;

  /** Obtient ou définit le type de la liste. */
  @Input() allowChangeTitle: boolean = true;

  /** Obtient ou définit le mode compact */
  @Input() compactMode: boolean = false;

  errorSubscription: Subscription;
  refreshView: Array<string> = undefined;

  //Force la reconstruction du composant de type Tree List
  repaintTree: boolean = false;
  /** Instancie un nouvel objet de type @type {WorkItemsComponent}. */
  constructor(
    private _router: Router,
    private _route: ActivatedRoute,
    private _state: StateManagementService,
    private _location: Location,
  ) {}

  ngOnDestroy(): void {
    if (this.errorSubscription != undefined) {
      this.errorSubscription.unsubscribe();
    }
  }
  /** @inheritdoc */
  ngOnInit(): void {
    this.errorSubscription = this.list.onError.subscribe((errors) => {
      this.errors = errors;
    });
    // Met dans le context d'execution les ids sélectionnés.
    if (this.modelState != undefined) {
      this.modelState.sharedContext.params.set('ids', () => this.selectedKeys);
    }
  }
  /** Récupère les informations de la route courante. */
  private routeInfo(
    callback: (parentCount: number, segments: UrlSegment[]) => void,
  ) {
    let segments: UrlSegment[] = [];
    let parent: ActivatedRoute = this._route;

    let parentCount = 0;
    while (parent != undefined) {
      if (parent.snapshot.url.length > 0) {
        parentCount++;
      }
      let sg = parent.snapshot.url;
      if (sg != undefined) {
        sg.reverse();
      }
      segments = segments.concat(sg);
      parent = parent.parent;
    }
    callback(parentCount, segments);
  }
  //temp function because shcema can make ModelContextDatasource
  private currentSource(): ModelDataSourceContext {
    if (this.current?.list?.source != undefined) {
      return this.current.list.source as any as ModelDataSourceContext;
    } else {
      return null;
    }
  }

  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    if (this.modelState == undefined) return;

    if (this.title == undefined && this.modelState.model != undefined) {
      this.title = this.modelState.model.title;
    }
    if (this.allowChangeTitle) {
      document.title = this.title + ' - One by Clarilog';
    }
    this.createContext();
  }
  /** Permet de créer le context d'execution. */
  private createContext() {
    if (this.modelState.model == undefined) return;
    let filters = this.modelState.model.grid.layout.filters;

    // Il y a obligatoirement 1 filtre avec 1 item
    if (filters.length > 0 && filters[0].items.length > 0) {
      // Recherche par rapport aux paramètres pour positioner automatiquement
      for (let filter of filters) {
        for (let item of filter.items) {
          let list = item.list as any;
          if (list.parameters != undefined) {
            let parameterKeys = Object.keys(list.parameters);
            let results = parameterKeys.filter(
              (key) =>
                this._route.snapshot.queryParams[key] === list.parameters[key],
            );
            if (results.length === parameterKeys.length) {
              this._state.setState('nav', item.text);

              this.current = item;
              break;
            }
          }
        }
        if (this.current != undefined) {
          break;
        }
      }
      if (this.current == undefined) {
        if (this._state.getState('nav') != undefined) {
          let state = this._state.getState('nav');

          for (let filter of filters) {
            for (let item of filter.items) {
              if (state === item.text) {
                this.current = item;
                break;
              }
            }
          }
        }
        if (this.current == undefined) {
          this.current = filters[0].items[0];
        }
      }
    }

    let layout = this.modelState.model.grid.layout;
    this.list.type = layout.type;
    this.list.parentIdExpr = layout.parentIdExpr;
    this.list.recursive = layout.recursive;
    this.list.multiple = layout.multiple;
    this.list.enabledExp = layout.enabledExp;

    this.updateParameters();

    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }

    if (
      window.history != undefined &&
      window.history.state != undefined &&
      window.history.state.filters == undefined
    ) {
      this.list.source = this.currentSource();
    }

    if (
      this.list.source == undefined &&
      this.current?.list?.source != undefined
    ) {
      this.list.source = this.currentSource();
    }
    this.list.isLiveUpdated = this.current?.list?.liveUpdate;
  }
  subscription: Subscription;

  /** Se déclenche lorsque la sélection change. */
  onClick(e, item = null) {
    if (item != undefined) {
      this.current = item;
      this._state.setState('nav', item.text);
    }
    this.updateParameters();
    if (this.subscription != undefined) {
      this.subscription.unsubscribe();
    }
    this._state.setState('filterName', item?.list?.parameters?.type);
    this.list.source = this.currentSource();
    this.list.isLiveUpdated = this.current?.list?.liveUpdate;

    if (this._route.snapshot.routeConfig.path === 'scheduler') {
      this.list.refresh();
      this.list.createSourceScheduler();
    }

    if (this.type == 'Tree' && this.repaintTree) {
      this.list.component.refresh(false);
    }
  }
  /** Ajoute des paramètres à l'url en fonction de l'élément cliqué. */
  private updateParameters() {
    if (
      this.current != undefined &&
      this.current.list.parameters != undefined
    ) {
      setTimeout(() => {
        this._router.navigate([], {
          queryParams: {
            ...this._route.snapshot.queryParams,
            ...this.current.list.parameters,
          },
          queryParamsHandling: 'merge',
          replaceUrl: true,
        });
      });
    }
    let route = this.current?.list?.parameters?.type;
    if (route === null && route === undefined) {
      route = null;
    }
    localStorage.setItem('filterName', route);
  }
  /** Se déclenche lorsque un item sélectionné change. */
  public onItemSelectionChanged(filter) {
    delete window.history.state.filters;
    if (this.list.source == undefined) {
      this.list.source = this.currentSource();
    }
    this.saveFilter = filter.filters;
    if (filter != undefined && filter.filters != undefined) {
      this.list.source.datasource.filter(filter.filters);
    } else {
      this.list.source.datasource.filter(null);
    }
    // TODO : Pas de reload car sinon les template explose
    // ou alors laisser reload mais avec un repaint
    this.list.refresh();
    // rafraîchit l'agenda si sélection d'un item
    this.list.createSourceScheduler();
  }

  /** Permet une gestion spécial du lien entre un filtre et la liste */
  public onItemSelectionChangedCommand(e) {
    this.onNavListSelectionChanged.emit(e);
  }

  /**
   * Intercepte le changement de sélection.
   */
  selectionChanged(e: EventArgs<any>) {
    this.onSelectionChanged.emit(e);
  }

  /**Passe le filtre utilisé pour la vue personnalisée */
  onDisplayFilter(e: Array<any>) {
    this.getNavMenuToActive(e[0]);

    this.list.source = this.currentSource();
    this.list.source.datasource.filter(e[1]);
    this.getNode(e[1]);
    this.updateParameters();
  }

  /**
   * Intercepte le clique d'une ligne.
   */
  rowClick(e: ClickEventArgs<CoreListComponent, any>) {
    this.onRowClick.emit(e);
  }

  singleCellClick(e: ClickEventArgs<CoreListComponent, any>) {
    this.onSingleCellClick.emit(e);
  }

  /** Se déclenche au clique sur le bouton retour. */
  public backClick(e) {
    if (this.backUrl != undefined) {
      this._router.navigate([`/${this.backUrl}`]);
      return;
    }

    if (this.usePreviousBackUrl == true) {
      this._location.back();
      return;
    }

    this.routeInfo((parentCount, segments) => {
      let reverseSegments = segments.reverse();
      reverseSegments.pop();
      let path = segments.map((result) => result.path).join('/');

      this._router.navigate([`/${path}`], {
        // Tant que l'on est dans un sous-système, on préserve les paramètres
        queryParams:
          parentCount == 2 ? undefined : this._route.snapshot.queryParams,
        relativeTo: this._route.parent,
      });
    });
  }
  /** Execute l'appel du click. */
  execute(action: any) {
    if (action.click !== undefined) {
      this.recursiveCall(action.click);
    }
  }
  /** Appel de façon recursive les fonctions. */
  recursiveCall(fn: () => Observable<any>) {
    let observable = fn();
    observable.subscribe((result) => {
      if (result.data != undefined) {
        if (result.data === true) {
          this.selectedKeys.clear();
          this.list.source.datasource.reload();
        }
      } else {
        this.recursiveCall(result);
      }
    });
  }
  /** Rafraîchi la liste. */
  public async refresh() {
    await this.list.refresh();
  }

  // Set le filtre
  public filter(filter) {
    if (this.list?.source?.datasource != undefined) {
      this.list.source.datasource.filter(filter);
    }
  }

  /** Efface la liste. */
  public clear() {
    if (this.modelState?.model?.grid?.layout?.filters != undefined) {
      this.current = this.modelState?.model?.grid?.layout?.filters[0].items[0];
    }

    this.list.clear();
  }
  /** Événement de rafraîchissement */
  beforeRefresh(e) {
    this.onBeforeRefresh.emit(e);
  }

  /** Par rapport au paramatre type (voir model.json) on met en avant quel menu */
  public getNavMenuToActive(customViewFilterName: string) {
    if (this.modelState.model == undefined) return;
    let filters = this.modelState.model.grid.layout.filters;

    // Il y a obligatoirement 1 filtre avec 1 item
    if (
      filters.length > 0 &&
      filters[0].items.length > 0 &&
      (customViewFilterName != null || customViewFilterName != undefined) &&
      (filters[0]['navigationByView'] == undefined ||
        filters[0]['navigationByView'] == true)
    ) {
      let filterItem = this.getMenuByParameterType(
        filters,
        customViewFilterName,
      );
      if (filterItem == null) {
        filterItem = this.getMenuByParameterType(filters, 'all');
      }

      if (filterItem != null) {
        this.current = filterItem;
      }
    }
  }

  /**Par rapport au filtre DevExtreme on coche quoi*/
  public getNode(array: Array<any>) {
    let getAllKey = [];
    if (array === null) {
      this.refreshView = null;
    } else {
      if (array.includes('or')) {
        array?.map((element) => {
          let isArrayType = Array.isArray(element);
          if (isArrayType) {
            let result = (element as Array<any>).join('');
            let filterValueId = result.split('=')[1];
            getAllKey.push(filterValueId);
          }
          this.refreshView = getAllKey;
        });
      } else if (array.includes('contains')) {
        array?.map((element) => {
          let isArrayType = Array.isArray(element);
          if (isArrayType) {
            (element as Array<any>).map((filterValue) => {
              getAllKey.push(filterValue);
            });
          }
          this.refreshView = getAllKey;
        });
      } else if (array.includes('=')) {
        let result = array.join('');
        let filterValueId = result.split('=')[1];
        getAllKey.push(filterValueId);

        this.refreshView = getAllKey;
      } else {
        console.error('Il y a un oublie ...');
      }
    }
  }
  /** Cherche le filtre    */
  public getMenuByParameterType(
    filters: Filter[],
    parametersType: string,
  ): ButtonTreeViewOptions {
    let navMenuName: ButtonTreeViewOptions = null;
    filters.find((filter) =>
      filter?.items?.find((value) => {
        if (parametersType != null && parametersType === 'all') {
          let all = TranslateService.get(`globals/${parametersType}`);
          if (value?.text === all) {
            navMenuName = value;
          }
        } else if (value?.list?.parameters?.type === parametersType) {
          navMenuName = value;
        }
      }),
    );
    return navMenuName;
  }
}
