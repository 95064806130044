import {
  Component,
  Input,
  OnChanges,
  SimpleChanges,
  DoCheck,
} from '@angular/core';

/*** Représente un composant de débogage. */
@Component({
  selector: 'clc-debug',
  template: '',
})
export class CoreDebugComponent implements OnChanges, DoCheck {
  /** Obtient ou définit la valeur. */
  @Input() value: any;

  @Input() keyValue: any;
  /** Active ou désactive le check constant de la valeur. */
  @Input() check: boolean = false;
  constructor() {}
  /** @inheritdoc */
  ngDoCheck(): void {
    if (this.check === true) {
      console.log(this.value);
    }
  }
  /** @inheritdoc */
  ngOnChanges(changes: SimpleChanges): void {
    console.log(this.keyValue, ' ', changes.value.currentValue);
  }
}
