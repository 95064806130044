import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLdapAnalysesBaseVariables, FirstLdapAnalysesBaseVariables, CountLdapAnalysesBaseVariables, FindLdapAnalysesBaseVariables, SearchLdapAnalysesBaseVariables, ExportSchemaLdapAnalysesBaseVariables, ExportDataLdapAnalysesBaseVariables, CustomQueryLdapAnalysesBaseVariables, CustomQueryIdLdapAnalysesBaseVariables, UsedLdapAnalysesBaseVariables, CancelLdapAssetLdapAnalysesBaseVariables, CancelLdapUserLdapAnalysesBaseVariables, CompareLdapAssetLdapAnalysesBaseVariables, CompareLdapUserLdapAnalysesBaseVariables, FindAssetLdapLdapAnalysesBaseVariables, FindLdapAssetLdapAnalysesBaseVariables, FindLdapUserLdapAnalysesBaseVariables, FindUserLdapLdapAnalysesBaseVariables, IsProgressLdapAnalysesBaseVariables, ExistLdapAnalysesBaseVariables, InsertLdapAnalysesBaseVariables, UpdateLdapAnalysesBaseVariables, ImportDataLdapAnalysesBaseVariables, UpdateManyLdapAnalysesBaseVariables, DeleteLdapAnalysesBaseVariables, CreateAssetsLdapAnalysesBaseVariables, DeleteObjectsLdapAnalysesBaseVariables } from '../gqls'
import { GetLdapAnalysesDocument, FirstLdapAnalysesDocument, CountLdapAnalysesDocument, FindLdapAnalysesDocument, SearchLdapAnalysesDocument, ExportSchemaLdapAnalysesDocument, ExportDataLdapAnalysesDocument, CustomQueryLdapAnalysesDocument, CustomQueryIdLdapAnalysesDocument, UsedLdapAnalysesDocument, CancelLdapAssetLdapAnalysesDocument, CancelLdapUserLdapAnalysesDocument, CompareLdapAssetLdapAnalysesDocument, CompareLdapUserLdapAnalysesDocument, FindAssetLdapLdapAnalysesDocument, FindLdapAssetLdapAnalysesDocument, FindLdapUserLdapAnalysesDocument, FindUserLdapLdapAnalysesDocument, IsProgressLdapAnalysesDocument, ExistLdapAnalysesDocument, InsertLdapAnalysesDocument, UpdateLdapAnalysesDocument, ImportDataLdapAnalysesDocument, UpdateManyLdapAnalysesDocument, DeleteLdapAnalysesDocument, CreateAssetsLdapAnalysesDocument, DeleteObjectsLdapAnalysesDocument } from '../gqls'
import { ServiceSingleResultOfLdapAnalyse, QueryContextOfLdapAnalyse, FilterOfLdapAnalyse, ServiceSingleResultOfInt64, ServiceListResultOfLdapAnalyse, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LdapObjectClassType, LdapAnalyseInput, FieldUpdateOperatorOfLdapAnalyse } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LdapAnalysisBaseService {
    
public modelName = 'ldapAnalysis';
public modelPluralName = 'ldapAnalyses';

	private getLdapAnalysesQuery: GetLdapAnalysesDocument;
	private firstLdapAnalysesQuery: FirstLdapAnalysesDocument;
	private countLdapAnalysesQuery: CountLdapAnalysesDocument;
	private findLdapAnalysesQuery: FindLdapAnalysesDocument;
	private searchLdapAnalysesQuery: SearchLdapAnalysesDocument;
	private exportSchemaLdapAnalysesQuery: ExportSchemaLdapAnalysesDocument;
	private exportDataLdapAnalysesQuery: ExportDataLdapAnalysesDocument;
	private customQueryLdapAnalysesQuery: CustomQueryLdapAnalysesDocument;
	private customQueryIdLdapAnalysesQuery: CustomQueryIdLdapAnalysesDocument;
	private usedLdapAnalysesQuery: UsedLdapAnalysesDocument;
	private cancelLdapAssetLdapAnalysesQuery: CancelLdapAssetLdapAnalysesDocument;
	private cancelLdapUserLdapAnalysesQuery: CancelLdapUserLdapAnalysesDocument;
	private compareLdapAssetLdapAnalysesQuery: CompareLdapAssetLdapAnalysesDocument;
	private compareLdapUserLdapAnalysesQuery: CompareLdapUserLdapAnalysesDocument;
	private findAssetLdapLdapAnalysesQuery: FindAssetLdapLdapAnalysesDocument;
	private findLdapAssetLdapAnalysesQuery: FindLdapAssetLdapAnalysesDocument;
	private findLdapUserLdapAnalysesQuery: FindLdapUserLdapAnalysesDocument;
	private findUserLdapLdapAnalysesQuery: FindUserLdapLdapAnalysesDocument;
	private isProgressLdapAnalysesQuery: IsProgressLdapAnalysesDocument;
	private existLdapAnalysesQuery: ExistLdapAnalysesDocument;
	private insertLdapAnalysesMutation: InsertLdapAnalysesDocument;
	private updateLdapAnalysesMutation: UpdateLdapAnalysesDocument;
	private importDataLdapAnalysesMutation: ImportDataLdapAnalysesDocument;
	private updateManyLdapAnalysesMutation: UpdateManyLdapAnalysesDocument;
	private deleteLdapAnalysesMutation: DeleteLdapAnalysesDocument;
	private createAssetsLdapAnalysesMutation: CreateAssetsLdapAnalysesDocument;
	private deleteObjectsLdapAnalysesMutation: DeleteObjectsLdapAnalysesDocument;

	constructor(private injector: Injector) {
		this.getLdapAnalysesQuery = this.injector.get(GetLdapAnalysesDocument);
		this.firstLdapAnalysesQuery = this.injector.get(FirstLdapAnalysesDocument);
		this.countLdapAnalysesQuery = this.injector.get(CountLdapAnalysesDocument);
		this.findLdapAnalysesQuery = this.injector.get(FindLdapAnalysesDocument);
		this.searchLdapAnalysesQuery = this.injector.get(SearchLdapAnalysesDocument);
		this.exportSchemaLdapAnalysesQuery = this.injector.get(ExportSchemaLdapAnalysesDocument);
		this.exportDataLdapAnalysesQuery = this.injector.get(ExportDataLdapAnalysesDocument);
		this.customQueryLdapAnalysesQuery = this.injector.get(CustomQueryLdapAnalysesDocument);
		this.customQueryIdLdapAnalysesQuery = this.injector.get(CustomQueryIdLdapAnalysesDocument);
		this.usedLdapAnalysesQuery = this.injector.get(UsedLdapAnalysesDocument);
		this.cancelLdapAssetLdapAnalysesQuery = this.injector.get(CancelLdapAssetLdapAnalysesDocument);
		this.cancelLdapUserLdapAnalysesQuery = this.injector.get(CancelLdapUserLdapAnalysesDocument);
		this.compareLdapAssetLdapAnalysesQuery = this.injector.get(CompareLdapAssetLdapAnalysesDocument);
		this.compareLdapUserLdapAnalysesQuery = this.injector.get(CompareLdapUserLdapAnalysesDocument);
		this.findAssetLdapLdapAnalysesQuery = this.injector.get(FindAssetLdapLdapAnalysesDocument);
		this.findLdapAssetLdapAnalysesQuery = this.injector.get(FindLdapAssetLdapAnalysesDocument);
		this.findLdapUserLdapAnalysesQuery = this.injector.get(FindLdapUserLdapAnalysesDocument);
		this.findUserLdapLdapAnalysesQuery = this.injector.get(FindUserLdapLdapAnalysesDocument);
		this.isProgressLdapAnalysesQuery = this.injector.get(IsProgressLdapAnalysesDocument);
		this.existLdapAnalysesQuery = this.injector.get(ExistLdapAnalysesDocument);
		this.insertLdapAnalysesMutation = this.injector.get(InsertLdapAnalysesDocument);
		this.updateLdapAnalysesMutation = this.injector.get(UpdateLdapAnalysesDocument);
		this.importDataLdapAnalysesMutation = this.injector.get(ImportDataLdapAnalysesDocument);
		this.updateManyLdapAnalysesMutation = this.injector.get(UpdateManyLdapAnalysesDocument);
		this.deleteLdapAnalysesMutation = this.injector.get(DeleteLdapAnalysesDocument);
		this.createAssetsLdapAnalysesMutation = this.injector.get(CreateAssetsLdapAnalysesDocument);
		this.deleteObjectsLdapAnalysesMutation = this.injector.get(DeleteObjectsLdapAnalysesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapAnalyse> {
    
    		let variables: GetLdapAnalysesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.get));
            }
            else{
                let result:ServiceSingleResultOfLdapAnalyse={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapAnalyse> {
    
    		let variables: FirstLdapAnalysesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLdapAnalysesBaseVariables = {
    			filter: filter
    		}
    				return this.countLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: FindLdapAnalysesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: SearchLdapAnalysesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLdapAnalysesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLdapAnalysesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: CustomQueryLdapAnalysesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: CustomQueryIdLdapAnalysesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLdapAnalysesBaseVariables = {
    			ids: ids
    		}
    				return this.usedLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.used));
    	}

    	/**  */
    	@InjectArgs
    	public cancelLdapAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CancelLdapAssetLdapAnalysesBaseVariables = {
    
    		}
    				return this.cancelLdapAssetLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.cancelLdapAsset));
    	}

    	/**  */
    	@InjectArgs
    	public cancelLdapUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CancelLdapUserLdapAnalysesBaseVariables = {
    
    		}
    				return this.cancelLdapUserLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.cancelLdapUser));
    	}

    	/**  */
    	@InjectArgs
    	public compareLdapAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('netbios') netbios: boolean,
		@Args('name') name: boolean,
		@Args('distinguishedName') distinguishedName: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CompareLdapAssetLdapAnalysesBaseVariables = {
    			distinguishedName: distinguishedName,
			netbios: netbios,
			name: name
    		}
    				return this.compareLdapAssetLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.compareLdapAsset));
    	}

    	/**  */
    	@InjectArgs
    	public compareLdapUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CompareLdapUserLdapAnalysesBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType
    		}
    				return this.compareLdapUserLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.compareLdapUser));
    	}

    	/**  */
    	@InjectArgs
    	public findAssetLdap(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: FindAssetLdapLdapAnalysesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findAssetLdapLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.findAssetLdap));
    	}

    	/**  */
    	@InjectArgs
    	public findLdapAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: FindLdapAssetLdapAnalysesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findLdapAssetLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.findLdapAsset));
    	}

    	/**  */
    	@InjectArgs
    	public findLdapUser(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: FindLdapUserLdapAnalysesBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType,
			filter: filter,
			options: options
    		}
    				return this.findLdapUserLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.findLdapUser));
    	}

    	/**  */
    	@InjectArgs
    	public findUserLdap(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('options?') options?: QueryContextOfLdapAnalyse,
		@Args('filter?') filter?: FilterOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLdapAnalyse> {
    
    		let variables: FindUserLdapLdapAnalysesBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType,
			filter: filter,
			options: options
    		}
    				return this.findUserLdapLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.findUserLdap));
    	}

    	/**  */
    	@InjectArgs
    	public isProgress(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapAnalyse> {
    
    		let variables: IsProgressLdapAnalysesBaseVariables = {
    			ldapObjectClassType: ldapObjectClassType
    		}
    				return this.isProgressLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.isProgress));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLdapAnalysesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLdapAnalysesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LdapAnalyseInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapAnalyse> {
    
    		let variables: InsertLdapAnalysesBaseVariables = {
    			entity: entity
    		}
    				return this.insertLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLdapAnalyse> {
    
    		let variables: UpdateLdapAnalysesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLdapAnalysesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLdapAnalyse,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLdapAnalysesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLdapAnalysesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.delete));
    	}

    	/**  */
    	@InjectArgs
    	public createAssets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ldapAnalyseIds') ldapAnalyseIds: Array<string>,
		@Args('assetCategoryId') assetCategoryId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: CreateAssetsLdapAnalysesBaseVariables = {
    			ldapAnalyseIds: ldapAnalyseIds,
			assetCategoryId: assetCategoryId
    		}
    				return this.createAssetsLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.createAssets));
    	}

    	/**  */
    	@InjectArgs
    	public deleteObjects(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteObjectsLdapAnalysesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteObjectsLdapAnalysesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ldapAnalyses.deleteObjects));
    	}
    
}