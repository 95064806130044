import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TemplatesService } from '../../templates/templates.service';
import { NavigationExtras, Router, UrlTree } from '@angular/router';
import { TranslateService } from '@clarilog/shared/services';
import { AllowedEntity } from '@clarilog/core/services2/graphql/generated-types/types';

/** Représente la classe du composent clc-visible-selectable-entity. */
@Component({
  selector: 'clc-visible-selectable-entity',
  templateUrl: './visible-selectable-entity.component.html',
  styleUrls: ['./visible-selectable-entity.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreVisibleSelectableEntityComponent),
      multi: true,
    },
  ],
})
export class CoreVisibleSelectableEntityComponent
  implements ControlValueAccessor, OnInit
{
  /** @inheritdoc */
  onChange: any = () => {};

  /** @inheritdoc */
  onTouched: any = () => {};

  /** Obtient ou définit les options */
  @Input() options: any;

  /** Obtient ou définit le modelState */
  @Input() state: ModelState;

  /** Obtient ou définit la source de données. */
  sourceShow: any[];

  /** Obtient ou définit la source de données. */
  sourceAllow: any[];

  /** Tableau des index sélectionnées pour la vue*/
  selectedKeysShow: any[] = [];

  /** Tableau des index sélectionnées pour la vue*/
  selectedKeysAllow: any[] = [];

  /** Definit le composant d'autorisation */
  allowedComponent: any;

  /** Definit le composant d'affichage */
  showComponent: any;

  originalValue: AllowedEntity[] = [];

  selectDataValue: AllowedEntity[] = [];

  constructor(
    public templateService: TemplatesService,
    private router: Router,
  ) {}

  /** Obtient ou définit la valeur. */
  @Input() get value(): AllowedEntity[] {
    return this.selectDataValue;
  }
  set value(value: AllowedEntity[]) {
    this.onChange(value);
    this.onTouched();
  }

  /** @inheritdoc */
  writeValue(values: any[]): void {
    this.originalValue = values;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  ngOnInit() {
    this.refresh();
  }

  /** Recharge de la grille */
  refresh() {
    let dataSource = (<ModelDataSourceContext>(<unknown>this.options.source))
      .datasource;
    dataSource.pageSize(500000);

    dataSource.load().then((results) => {
      this.sourceShow = results;

      if (this.originalValue != undefined && this.originalValue.length > 0) {
        this.selectedKeysShow = this.originalValue.map((s) => {
          return s.entityId;
        });

        this.selectedKeysAllow = this.originalValue
          .filter((s) => s.allowed)
          .map((s) => s.entityId);
      }
    });
  }

  /** Gestion du lien */
  underline(e, data) {
    let params = undefined;

    let url: UrlTree;
    let id = data.key;
    url = this.router.createUrlTree([this.options.route, 'edit', id], {
      queryParams: params,
      skipLocationChange: true,
    } as NavigationExtras);

    let win = window.open(this.router.serializeUrl(url), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Création du toolbar */
  onToolbarPreparing(e, firstTree = true) {
    e.toolbarOptions.items.unshift({
      widget: 'dxCheckBox',
      cssClass: 'cl-checkbox-style',
      location: 'after',
      options: {
        onValueChanged: (args) => {
          if (args.value) {
            let keys = e.component.option('dataSource').map((s) => s.id);
            e.component.option('selectedRowKeys', keys);
          } else {
            e.component.option('selectedRowKeys', []);
          }
        },
      },
    });

    if (firstTree) {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'refresh',
          hint: TranslateService.get('globals/refresh'),
          onClick: () => {
            this.refresh();
          },
        },
      });
    }

    e.toolbarOptions.items.unshift(
      {
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'minus',
          stylingMode: 'text',
          hint: TranslateService.get('globals/collapseAll'),
          onClick: () => {
            e.component.beginUpdate();

            e.component.forEachNode((node) => {
              e.component.collapseRow(node.key);
            });
            e.component.option('autoExpandAll', false);
            e.component.endUpdate();
          },
        },
      },
      {
        widget: 'dxButton',
        location: 'after',

        options: {
          icon: 'plus',
          stylingMode: 'text',
          hint: TranslateService.get('globals/expandAll'),
          onClick: () => {
            e.component.beginUpdate();
            e.component.option('autoExpandAll', true);
            e.component.endUpdate();
          },
        },
      },
    );

    let tilteTree = TranslateService.get('globals/show');
    if (!firstTree) {
      tilteTree = TranslateService.get('globals/allowView');
    }
    e.toolbarOptions.items.unshift({
      html: "<div class='cl-tilte-bar'>" + tilteTree + '</div>',
      location: 'before',
    });

    if (this.options.route != undefined && firstTree) {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'arrowright',
          stylingMode: 'text',
          hint: TranslateService.get('manage'),
          onClick: () => {
            this.onGoTo();
          },
        },
      });
    }
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo() {
    let url = this.router.createUrlTree([this.options.route], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Obtient les noeud enfants */
  GetChildren(source, nodeId) {
    // Si a des noeud enfant on les sélectionne
    let children = [];
    source
      .filter((f) => f.parentId == nodeId)
      .forEach((f) => {
        children.push(f);
        this.GetChildren(source, f.id).forEach((element) => {
          children.push(element);
        });
      });
    return children;
  }

  /** Obtient les noeud parents */
  GetParents(source, nodeId) {
    // Si a des noeud enfant on les sélectionne
    let parents = [];
    source
      .filter((f) => f.id == nodeId)
      .forEach((f) => {
        parents.push(f);
        if (f.parentId != undefined) {
          this.GetParents(source, f.parentId).forEach((element) => {
            parents.push(element);
          });
        }
      });
    return parents;
  }

  /** Evénement de changement de sélection de la liste d'affichage*/
  isCheckingSelection = false;
  onSelectionShowChanged(e) {
    // si en cours de check on annule
    if (this.isCheckingSelection) return;

    let source = e.component.option('dataSource');

    this.isCheckingSelection = true;

    if (
      e.selectedRowKeys.length != 0 &&
      source.length != e.selectedRowKeys.length
    ) {
      // Action de vérification de sélection Parent / enfant
      if (
        e.currentSelectedRowKeys != undefined &&
        e.currentSelectedRowKeys.length > 0
      ) {
        let children =
          e.currentSelectedRowKeys.length == 1
            ? this.GetChildren(source, e.currentSelectedRowKeys[0])
            : [];

        e.currentSelectedRowKeys.forEach((current) => {
          // Vérifie la sélection d'un noeud parent pour sélectionner l'ensemble des enfant
          let parents = this.GetParents(source, current);
          children.push(...parents);
        });

        let ids = [...new Set(children.map((s) => s.id))];
        e.component.selectRows(ids, true);
      } else if (
        e.currentDeselectedRowKeys != undefined &&
        e.currentDeselectedRowKeys.length == 1
      ) {
        // Vérifie la désélection d'un noeud parent pour désélectionner l'ensemble des enfant
        let children = this.GetChildren(source, e.currentDeselectedRowKeys[0]);
        if (children != undefined && children.length > 0) {
          e.component.deselectRows(
            children.map((s) => s.id),
            true,
          );
        }
      }
    }
    this.isCheckingSelection = false;

    // assigne le datasource de la grille d'autorisation
    this.sourceAllow = e.component.getSelectedRowsData('all');

    /** Assigne la valeur */
    this.setDataValue();
  }

  /** Evénement de changement de sélection de la liste d'autorisation*/
  onSelectionAllowChanged(e) {
    /** Assigne la valeur */
    this.setDataValue();
  }

  /** Initialisation du composant d'affichage */
  onInitializedShow(e) {
    this.showComponent = e.component;
  }
  /** Initialisation du composant d'autorisation */
  onInitializedAllow(e) {
    this.allowedComponent = e.component;
  }

  /** Construit la valeur final */
  setDataValue() {
    let showData = this.showComponent.getSelectedRowsData('all');
    let allowData = this.allowedComponent.getSelectedRowsData('all');

    this.selectDataValue = showData.map((f) => {
      let result: AllowedEntity = {
        entityId: f.id,
        visible: true,
        allowed: allowData.find((s) => s.id == f.id) != undefined,
      };

      return result;
    });

    this.selectDataValue.sort((a, b) => {
      if (a.entityId < b.entityId) {
        return -1;
      }
      if (a.entityId > b.entityId) {
        return 1;
      }
      return 0;
    });

    this.onChange(this.selectDataValue);
    this.onTouched();
  }
}
