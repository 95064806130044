import { Pipe, PipeTransform } from '@angular/core';
import { resolve } from '../components/form/work-form/object-helpers';

@Pipe({
  name: 'filter'
})
export class FilterPipe implements PipeTransform {

  // transform(value: any, args?: any): any {
  //   return null;
  // }

  //TODO TEST  
  transform(items: any[] | any, field: string, value: any, inverse: boolean = false): any[] {
    // console.log("msut be array")
    // console.log(items)
    if (!items) return [];
    if (items.filter == undefined) return [];
    let results = inverse ?
      items.filter(it => resolve(it, field) !== value)
      :
      items.filter(it => resolve(it, field) === value);
    return results;
  }
}
