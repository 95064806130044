import { Injectable } from '@angular/core';
import { Args, Authorize, CountRecyclesReportGQL, CountReportsGQL, DeleteReportsGQL, EntityService, ExportReportsSchemaGQL, FindRecyclesReportsGQL, FindReportsGQL, FindTemplatesReportsGQL, GetReportGQL, IImportable, ImportFileFormat, ImportReportsGQL, InjectArgs, InsertReportGQL, QueryFilterInputType, QueryOptionsInputType, RecycleReportsGQL, ReportInputType, ReportType, RestoreReportsGQL, SearchReportsGQL, ServiceListResult, ServiceSingleResult, UpdateReportGQL } from '@clarilog/core';
import { Observable } from 'rxjs';
import { first, map } from 'rxjs/operators';

/**
 * Service relatif aux reports.
 *
 * @export
 * @class ReportService
 */
@Injectable({
  providedIn: 'root',
})
@Authorize('report')
export class ReportService extends EntityService<ReportType, ReportInputType>
  implements IImportable {
  constructor(
    findQuery: FindReportsGQL,
    getQuery: GetReportGQL,
    countQuery: CountReportsGQL,
    updateMutation: UpdateReportGQL,
    insertMutation: InsertReportGQL,
    deleteMutation: DeleteReportsGQL,
    findRecyclesQuery: FindRecyclesReportsGQL,
    countRecyclesQuery: CountRecyclesReportGQL,
    recycleMutation: RecycleReportsGQL,
    restoreMutation: RestoreReportsGQL,
    searchQuery: SearchReportsGQL,
    private exportSchemaQuery: ExportReportsSchemaGQL,
    private importDataMutation: ImportReportsGQL,
    private findTemplatesQuery: FindTemplatesReportsGQL,
  ) {
    super(
      findQuery,
      getQuery,
      countQuery,
      updateMutation,
      insertMutation,
      deleteMutation,
      findRecyclesQuery,
      countRecyclesQuery,
      recycleMutation,
      restoreMutation,
      searchQuery,
    );
  }

  /** Permet de télécharger le schéma de données */
  @InjectArgs
  public importSchema(
    @Args("type") type: ImportFileFormat,
  ): Observable<ServiceSingleResult<string>> {
    const variables = {
      type: type,
    };
    const query = this.exportSchemaQuery.fetch(variables);
    return query.pipe(map(result => result.data[this.name].exportSchema));
  }
  /** Permet d'importer via un fichier CSV */
  @InjectArgs
  public importData(
    @Args("type") type: ImportFileFormat,
    @Args("file") file: string,
  ): Observable<ServiceSingleResult<boolean>> {
    const variables = {
      type: type,
      file: file,
    };
    const query = this.importDataMutation.mutate(variables);
    return query.pipe(map(result => result.data[this.name].importData));
  }

  @InjectArgs
  public findTemplates(
    @Args("filters") filters?: QueryFilterInputType[],
    @Args("options") options?: QueryOptionsInputType,
  ): Observable<ServiceListResult<ReportType>> {
    const variables = {
      queryFilters: filters,
      queryOptions: options,
    };
    const query = this.findTemplatesQuery.fetch(variables);

    return query
      .pipe(map(result => result.data.reports.findTemplates))
      .pipe(first());
  }
}
