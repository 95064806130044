import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVerifaliaEmailChecksBaseVariables, FirstVerifaliaEmailChecksBaseVariables, CountVerifaliaEmailChecksBaseVariables, FindVerifaliaEmailChecksBaseVariables, SearchVerifaliaEmailChecksBaseVariables, ExportSchemaVerifaliaEmailChecksBaseVariables, ExportDataVerifaliaEmailChecksBaseVariables, CustomQueryVerifaliaEmailChecksBaseVariables, CustomQueryIdVerifaliaEmailChecksBaseVariables, UsedVerifaliaEmailChecksBaseVariables, ExistVerifaliaEmailChecksBaseVariables, FindRecyclesVerifaliaEmailChecksBaseVariables, CountRecyclesVerifaliaEmailChecksBaseVariables, ReadOnlyVerifaliaEmailChecksBaseVariables, FindArchivedVerifaliaEmailChecksBaseVariables, CountAllVerifaliaEmailChecksBaseVariables, FindDynamicPropertyFieldsVerifaliaEmailChecksBaseVariables, InsertVerifaliaEmailChecksBaseVariables, UpdateVerifaliaEmailChecksBaseVariables, ImportDataVerifaliaEmailChecksBaseVariables, UpdateManyVerifaliaEmailChecksBaseVariables, DeleteVerifaliaEmailChecksBaseVariables, RestoreVerifaliaEmailChecksBaseVariables, RecycleVerifaliaEmailChecksBaseVariables, RestoreArchivedVerifaliaEmailChecksBaseVariables, ArchivedVerifaliaEmailChecksBaseVariables, AddFileDynamicFieldVerifaliaEmailChecksBaseVariables, RemoveFileDynamicFieldVerifaliaEmailChecksBaseVariables } from '../gqls'
import { GetVerifaliaEmailChecksDocument, FirstVerifaliaEmailChecksDocument, CountVerifaliaEmailChecksDocument, FindVerifaliaEmailChecksDocument, SearchVerifaliaEmailChecksDocument, ExportSchemaVerifaliaEmailChecksDocument, ExportDataVerifaliaEmailChecksDocument, CustomQueryVerifaliaEmailChecksDocument, CustomQueryIdVerifaliaEmailChecksDocument, UsedVerifaliaEmailChecksDocument, ExistVerifaliaEmailChecksDocument, FindRecyclesVerifaliaEmailChecksDocument, CountRecyclesVerifaliaEmailChecksDocument, ReadOnlyVerifaliaEmailChecksDocument, FindArchivedVerifaliaEmailChecksDocument, CountAllVerifaliaEmailChecksDocument, FindDynamicPropertyFieldsVerifaliaEmailChecksDocument, InsertVerifaliaEmailChecksDocument, UpdateVerifaliaEmailChecksDocument, ImportDataVerifaliaEmailChecksDocument, UpdateManyVerifaliaEmailChecksDocument, DeleteVerifaliaEmailChecksDocument, RestoreVerifaliaEmailChecksDocument, RecycleVerifaliaEmailChecksDocument, RestoreArchivedVerifaliaEmailChecksDocument, ArchivedVerifaliaEmailChecksDocument, AddFileDynamicFieldVerifaliaEmailChecksDocument, RemoveFileDynamicFieldVerifaliaEmailChecksDocument } from '../gqls'
import { ServiceSingleResultOfVerifaliaEmailCheck, QueryContextOfVerifaliaEmailCheck, FilterOfVerifaliaEmailCheck, ServiceSingleResultOfInt64, ServiceListResultOfVerifaliaEmailCheck, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfVerifaliaEmailCheck, VerifaliaEmailCheckInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VerifaliaEmailCheckBaseService {
    
public modelName = 'verifaliaEmailCheck';
public modelPluralName = 'verifaliaEmailChecks';

	private getVerifaliaEmailChecksQuery: GetVerifaliaEmailChecksDocument;
	private firstVerifaliaEmailChecksQuery: FirstVerifaliaEmailChecksDocument;
	private countVerifaliaEmailChecksQuery: CountVerifaliaEmailChecksDocument;
	private findVerifaliaEmailChecksQuery: FindVerifaliaEmailChecksDocument;
	private searchVerifaliaEmailChecksQuery: SearchVerifaliaEmailChecksDocument;
	private exportSchemaVerifaliaEmailChecksQuery: ExportSchemaVerifaliaEmailChecksDocument;
	private exportDataVerifaliaEmailChecksQuery: ExportDataVerifaliaEmailChecksDocument;
	private customQueryVerifaliaEmailChecksQuery: CustomQueryVerifaliaEmailChecksDocument;
	private customQueryIdVerifaliaEmailChecksQuery: CustomQueryIdVerifaliaEmailChecksDocument;
	private usedVerifaliaEmailChecksQuery: UsedVerifaliaEmailChecksDocument;
	private existVerifaliaEmailChecksQuery: ExistVerifaliaEmailChecksDocument;
	private findRecyclesVerifaliaEmailChecksQuery: FindRecyclesVerifaliaEmailChecksDocument;
	private countRecyclesVerifaliaEmailChecksQuery: CountRecyclesVerifaliaEmailChecksDocument;
	private readOnlyVerifaliaEmailChecksQuery: ReadOnlyVerifaliaEmailChecksDocument;
	private findArchivedVerifaliaEmailChecksQuery: FindArchivedVerifaliaEmailChecksDocument;
	private countAllVerifaliaEmailChecksQuery: CountAllVerifaliaEmailChecksDocument;
	private findDynamicPropertyFieldsVerifaliaEmailChecksQuery: FindDynamicPropertyFieldsVerifaliaEmailChecksDocument;
	private insertVerifaliaEmailChecksMutation: InsertVerifaliaEmailChecksDocument;
	private updateVerifaliaEmailChecksMutation: UpdateVerifaliaEmailChecksDocument;
	private importDataVerifaliaEmailChecksMutation: ImportDataVerifaliaEmailChecksDocument;
	private updateManyVerifaliaEmailChecksMutation: UpdateManyVerifaliaEmailChecksDocument;
	private deleteVerifaliaEmailChecksMutation: DeleteVerifaliaEmailChecksDocument;
	private restoreVerifaliaEmailChecksMutation: RestoreVerifaliaEmailChecksDocument;
	private recycleVerifaliaEmailChecksMutation: RecycleVerifaliaEmailChecksDocument;
	private restoreArchivedVerifaliaEmailChecksMutation: RestoreArchivedVerifaliaEmailChecksDocument;
	private archivedVerifaliaEmailChecksMutation: ArchivedVerifaliaEmailChecksDocument;
	private addFileDynamicFieldVerifaliaEmailChecksMutation: AddFileDynamicFieldVerifaliaEmailChecksDocument;
	private removeFileDynamicFieldVerifaliaEmailChecksMutation: RemoveFileDynamicFieldVerifaliaEmailChecksDocument;

	constructor(private injector: Injector) {
		this.getVerifaliaEmailChecksQuery = this.injector.get(GetVerifaliaEmailChecksDocument);
		this.firstVerifaliaEmailChecksQuery = this.injector.get(FirstVerifaliaEmailChecksDocument);
		this.countVerifaliaEmailChecksQuery = this.injector.get(CountVerifaliaEmailChecksDocument);
		this.findVerifaliaEmailChecksQuery = this.injector.get(FindVerifaliaEmailChecksDocument);
		this.searchVerifaliaEmailChecksQuery = this.injector.get(SearchVerifaliaEmailChecksDocument);
		this.exportSchemaVerifaliaEmailChecksQuery = this.injector.get(ExportSchemaVerifaliaEmailChecksDocument);
		this.exportDataVerifaliaEmailChecksQuery = this.injector.get(ExportDataVerifaliaEmailChecksDocument);
		this.customQueryVerifaliaEmailChecksQuery = this.injector.get(CustomQueryVerifaliaEmailChecksDocument);
		this.customQueryIdVerifaliaEmailChecksQuery = this.injector.get(CustomQueryIdVerifaliaEmailChecksDocument);
		this.usedVerifaliaEmailChecksQuery = this.injector.get(UsedVerifaliaEmailChecksDocument);
		this.existVerifaliaEmailChecksQuery = this.injector.get(ExistVerifaliaEmailChecksDocument);
		this.findRecyclesVerifaliaEmailChecksQuery = this.injector.get(FindRecyclesVerifaliaEmailChecksDocument);
		this.countRecyclesVerifaliaEmailChecksQuery = this.injector.get(CountRecyclesVerifaliaEmailChecksDocument);
		this.readOnlyVerifaliaEmailChecksQuery = this.injector.get(ReadOnlyVerifaliaEmailChecksDocument);
		this.findArchivedVerifaliaEmailChecksQuery = this.injector.get(FindArchivedVerifaliaEmailChecksDocument);
		this.countAllVerifaliaEmailChecksQuery = this.injector.get(CountAllVerifaliaEmailChecksDocument);
		this.findDynamicPropertyFieldsVerifaliaEmailChecksQuery = this.injector.get(FindDynamicPropertyFieldsVerifaliaEmailChecksDocument);
		this.insertVerifaliaEmailChecksMutation = this.injector.get(InsertVerifaliaEmailChecksDocument);
		this.updateVerifaliaEmailChecksMutation = this.injector.get(UpdateVerifaliaEmailChecksDocument);
		this.importDataVerifaliaEmailChecksMutation = this.injector.get(ImportDataVerifaliaEmailChecksDocument);
		this.updateManyVerifaliaEmailChecksMutation = this.injector.get(UpdateManyVerifaliaEmailChecksDocument);
		this.deleteVerifaliaEmailChecksMutation = this.injector.get(DeleteVerifaliaEmailChecksDocument);
		this.restoreVerifaliaEmailChecksMutation = this.injector.get(RestoreVerifaliaEmailChecksDocument);
		this.recycleVerifaliaEmailChecksMutation = this.injector.get(RecycleVerifaliaEmailChecksDocument);
		this.restoreArchivedVerifaliaEmailChecksMutation = this.injector.get(RestoreArchivedVerifaliaEmailChecksDocument);
		this.archivedVerifaliaEmailChecksMutation = this.injector.get(ArchivedVerifaliaEmailChecksDocument);
		this.addFileDynamicFieldVerifaliaEmailChecksMutation = this.injector.get(AddFileDynamicFieldVerifaliaEmailChecksDocument);
		this.removeFileDynamicFieldVerifaliaEmailChecksMutation = this.injector.get(RemoveFileDynamicFieldVerifaliaEmailChecksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheck> {
    
    		let variables: GetVerifaliaEmailChecksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.get));
            }
            else{
                let result:ServiceSingleResultOfVerifaliaEmailCheck={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheck> {
    
    		let variables: FirstVerifaliaEmailChecksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVerifaliaEmailChecksBaseVariables = {
    			filter: filter
    		}
    				return this.countVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: FindVerifaliaEmailChecksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: SearchVerifaliaEmailChecksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVerifaliaEmailChecksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVerifaliaEmailChecksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: CustomQueryVerifaliaEmailChecksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: CustomQueryIdVerifaliaEmailChecksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.usedVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVerifaliaEmailChecksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: FindRecyclesVerifaliaEmailChecksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesVerifaliaEmailChecksBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyVerifaliaEmailChecksBaseVariables = {
    			id: id
    		}
    				return this.readOnlyVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheck,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheck> {
    
    		let variables: FindArchivedVerifaliaEmailChecksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllVerifaliaEmailChecksBaseVariables = {
    			filter: filter
    		}
    				return this.countAllVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsVerifaliaEmailChecksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsVerifaliaEmailChecksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: VerifaliaEmailCheckInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheck> {
    
    		let variables: InsertVerifaliaEmailChecksBaseVariables = {
    			entity: entity
    		}
    				return this.insertVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheck> {
    
    		let variables: UpdateVerifaliaEmailChecksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataVerifaliaEmailChecksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaEmailCheck,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyVerifaliaEmailChecksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.restoreVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.recycleVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedVerifaliaEmailChecksBaseVariables = {
    			ids: ids
    		}
    				return this.archivedVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldVerifaliaEmailChecksBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldVerifaliaEmailChecksBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldVerifaliaEmailChecksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailChecks.removeFileDynamicField));
    	}
    
}