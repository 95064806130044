import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormControl,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { ThemeService } from '@clarilog/core/services/theme/theme.service';
import { TranslatedField } from '@clarilog/core/services2/graphql/generated-types/types';
import { FormGroupHelpers, HtmlEditorComponent } from '@clarilog/shared2';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { TranslatedFieldHelperService } from '../translate-field';
import { GC, GCFactory } from '@clarilog/core';

@Component({
  selector: 'clc-html-editor-translated',
  templateUrl: './html-editor-translated.component.html',
  styleUrls: ['./html-editor-translated.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => HtmlEditorTranslatedComponent),
      multi: true,
    },
  ],
})
export class HtmlEditorTranslatedComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  values: string;

  @Input() control: any;
  @Input() fg: UntypedFormGroup;
  @Input() state: ModelState;
  @Input() isPopupVisible: boolean = false;
  @ViewChild(HtmlEditorComponent) selectHtmlEditor: HtmlEditorComponent;
  languageSource: Array<{ key: string; value: string }> = [];
  selectedLanguage = 'en';
  supportedLanguages: string[];
  isInitialized: boolean = false;
  formControl: UntypedFormControl;
  currentValue: TranslatedField;
  _value: TranslatedField;
  _originalValues: TranslatedField;
  @Input() fullMode: boolean = false;

  htmlValue: string = '';

  _gc: GC;

  constructor(
    private translateHelperService: TranslatedFieldHelperService,
    private themeService: ThemeService,
    private changeDetectorRef: ChangeDetectorRef,
    public gcFactory: GCFactory,
  ) {
    this.languageSource = translateHelperService.getLanguageDatasource();
    this.selectedLanguage = translateHelperService.getTranslateKey();
    this.supportedLanguages = translateHelperService.getAllSupportedLanguage();
    this._value = this.translateHelperService.getDefaultvalue();

    this._gc = gcFactory.create();
  }
  ngOnDestroy(): void {
    this._gc.dispose();
  }

  /** Obtient le théme **/
  getThemeStyle() {
    let result = [];
    Object.keys(this.themeService.getActiveTheme().properties).forEach(
      (property) => {
        result.push(
          property +
            ': ' +
            this.themeService.getActiveTheme().properties[property],
        );
        document.documentElement.style.setProperty(
          property,
          this.themeService.getActiveTheme().properties[property],
        );
      },
    );
    return result.join(';');
  }

  ngOnInit(): void {
    this._gc.forDispose(
      FormGroupHelpers.onFormLoaded(this.state).subscribe((el) => {
        this.formControl = FormGroupHelpers.formControlByName(
          this.state.form,
          this.control.name,
        );
      }),
    );

    this.state.on.subscribe((event) => {
      if (
        event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
      ) {
        this.selectedLanguage = event.eventData;
        this.htmlValue = this._value[this.selectedLanguage];
        this.changeDetectorRef.detectChanges();
      }
    });

    if (this.control?.options?.fullScreenMode != undefined) {
      this.fullMode = this.control?.options?.fullScreenMode;
    }
  }

  writeValue(obj: TranslatedField): void {
    if (obj != undefined) {
      setTimeout((_) => {
        let translateValue = this.manageTranslateMention(obj);
        this._originalValues = JSON.parse(JSON.stringify(translateValue));
        this._value = JSON.parse(JSON.stringify(translateValue));

        if (this._value != undefined && this.selectedLanguage != undefined) {
          this.htmlValue = this._value[this.selectedLanguage];
        }

        this.changeDetectorRef.detectChanges();
      });
    }
    this.isInitialized = true;
  }

  /** Gere la traduction des mentions **/
  manageTranslateMention(value) {
    this.supportedLanguages.forEach(function (lang) {
      if (value[lang] != undefined && value[lang] != '') {
        let el = document.createElement('html');
        el.innerHTML = value[lang];
        let htmlMentions = el.getElementsByClassName('dx-mention');
        let mentions = this.selectHtmlEditor.mentions;
        if (htmlMentions.length > 0) {
          Array.from(htmlMentions).forEach((htmlMention) => {
            let attribute = htmlMention.attributes['data-id'];
            if (attribute != undefined && mentions != undefined) {
              let dataId = attribute.value;
              let translate = mentions.find((x) => x.key == dataId);
              if (translate != undefined) {
                htmlMention.attributes['data-mention-value'].value =
                  translate.name;
              }
            }
          });
          value[lang] = el.innerHTML;
        }
      }
    }, this);
    return value;
  }

  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState?(isDisabled: boolean): void {}

  onValueChanged(e: string) {
    if (this.isInitialized === true) {
      let updateValue = JSON.parse(JSON.stringify(this._value));
      updateValue[this.selectedLanguage] = e;

      let controlValue = this.formControl?.value;
      if (controlValue == undefined) {
        controlValue = '';
        return;
      }
      let isDiff = JSON.stringify(controlValue) !== JSON.stringify(updateValue);
      if (isDiff) {
        this.currentValue = updateValue;
        this.changeDetectorRef.markForCheck();

        this.onChange(JSON.parse(JSON.stringify(updateValue)));
        this.onTouched();
      }
    }
    this.isInitialized = true;
  }

  lostFocus(event) {
    if (this.currentValue) this._value = this.currentValue;
  }

  /** Obtient la valeur en fonction de la langue **/
  getTranslatedLanguageLabel(language) {
    return this.languageSource.find((el) => el.key === language).value;
  }
}
