import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { TranslateService } from '@clarilog/shared2';

import { of } from 'rxjs';
import CustomStore from 'devextreme/data/custom_store';
import { TicketCoreService } from '@clarilog/core/services2/graphql/generated-types/services';

@Component({
  selector: 'clc-research',
  templateUrl: './research.component.html',
  styleUrls: ['./research.component.scss'],
})
export class CoreResearchComponent implements OnInit {
  @Input() height: any;
  @Input() width: any;

  @Input() showWarningMessage: boolean = true;
  @Input() warningMessage;
  isLoading = false;

  @Output() onSaved = new EventEmitter<any>();

  @Input() title: string = TranslateService.get('entities/ticket/research');

  _isVisible: boolean = false;

  @Output() isVisibleChange = new EventEmitter<boolean>();

  @Input() beforeSave: (e: any) => Promise<boolean> = (e) => {
    return of(false).toPromise();
  };

  @Input()
  set isVisible(value: boolean) {
    this._isVisible = value;
    this.isVisibleChange.emit(this._isVisible);
  }

  get isVisible(): boolean {
    return this._isVisible;
  }

  /** tes */

  textSearch: string;

  gridBoxValue: number[] = [0];
  dataSource: CustomStore;
  columns: any[];
  dataList: any[];
  /** */

  constructor(private ticketService: TicketCoreService) {}

  ngOnInit(): void {
    if (this.height == undefined || this.height == null) {
      this.height = '80%';
    }
    if (this.width == undefined || this.width == null) {
      this.width = '800';
    }

    let lists = this.ticketService.getField();
    this.dataList = lists;
    this.dataSource = new CustomStore({
      loadMode: 'raw',
      key: 'id',
      load() {
        return lists;
      },
    });

    this.columns = [
      {
        label: TranslateService.get('entities/ticket/researchIn'),
        field: 'value',
      },
    ];
  }
  close() {
    this.isLoading = false;
    this.isVisible = false;
    // this.isCreateForm = false;
  }

  onShown(e) {
    this.isLoading = false;
  }

  async save(e) {
    if ((await this.beforeSave(e)) === true) {
      return;
    }

    this.createDataRecord();
  }

  /**Effctue la recherche en appuyant sur la touche "Enter/ entrée" */
  onEnterKey(e) {
    this.createDataRecord();
  }

  /**Permet de sélectionner ou de déselectionner les critères de recherches */
  onRowClick(event) {
    if (!event.isSelected) {
      event.component.selectRows([event.data.id], true);
    } else {
      event.component.deselectRows([event.data.id], true);
    }
  }

  /**Permet  de  structuer les données à envoyer pour la recherche */
  private createDataRecord() {
    if (this.textSearch?.trim() === '' || this.textSearch === undefined) {
      this.textSearch = null;
    }

    if (this.gridBoxValue?.length == 0) {
    }

    let researchLists = this.dataList.filter((x) =>
      this.gridBoxValue?.includes(x['id']),
    );

    let record = {
      textSearch: this.textSearch,
      research: researchLists,
    };
    this.onSaved.emit([record]);

    this.close();
  }
}
