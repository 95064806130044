import { AfterContentInit, Component, ContentChildren, DoCheck, Input, IterableDiffer, IterableDiffers, QueryList } from '@angular/core';
import { TranslateService } from '@clarilog/shared2/services';
import { ToolbarItemComponent } from '../toolbar-item/toolbar-item.component';

/** Représente les actions d'un toolbar. */
@Component({
  selector: 'cl-toolbar-items',
  template: '<ng-content></ng-content>'
})
export class ToolbarItemsComponent implements AfterContentInit, DoCheck {
  private _iterableDiffer: IterableDiffer<ToolbarItemComponent>;
  /** Obtient la liste des composent enfant @type {ActionComponent}. */
  @ContentChildren(ToolbarItemComponent, { descendants: false }) itemsChildren: QueryList<ToolbarItemComponent>;
  /** Obtient ou définit les actions. */
  @Input() items: ToolbarItemComponent[] = [];

  constructor(private iterableDiffers: IterableDiffers) {
    this._iterableDiffer = this.iterableDiffers.find([]).create(null);
  }
  /** @inheritdoc */
  ngAfterContentInit(): void {
    if (this.items != undefined) {
      this.itemsChildren.reset(
        [...this.itemsChildren.toArray(), ...this.items]
      );
      // reorganisation ordre toolbar 
      // Supprimer et Corbeille a la fin
      let defaultItems = this.itemsChildren.toArray();
      var disconnectTranlate = TranslateService.get('globals/disconnect');
      var isDisconnectPresent = defaultItems.find(element => element.text == disconnectTranlate);
      if (isDisconnectPresent == null || isDisconnectPresent == undefined) {
        defaultItems.map((element, index) => {
          if (element.text == TranslateService.get('permissions/delete')) {
            let insertElement = defaultItems.splice(index, 2);
            insertElement.map((el, i) => {
              defaultItems.push(insertElement[i]);
            })
          }



        });
      }
      this.itemsChildren.reset(
        [...defaultItems]
      );
      this.itemsChildren.notifyOnChanges();
    }
  }
  /** @inheritdoc */
  ngDoCheck(): void {

    let changes = this._iterableDiffer.diff(this.items);
    if (changes && this.itemsChildren != undefined) {
      changes.forEachAddedItem(add => {
        this.itemsChildren.reset(
          [...this.itemsChildren.toArray(), add.item]
        );
      });
      changes.forEachRemovedItem(remove => {
        let items = this.itemsChildren.toArray();
        let index = items.indexOf(remove.item);
        items.splice(index, 1);
        this.itemsChildren.reset(
          [...items]
        );
      });
      this.itemsChildren.notifyOnChanges();
    }
  }
}
