<div class="grid-container">
  <aside class="side-nav">
    <div class="menu-header" [ngClass]="{ toggle: toggle, pin: pin }">
      <a href="javascript:void(0)"
        ><img
          *ngIf="!pin"
          class="small-ico"
          src="/static-files/logo-new/embleme-blanc-50x50.svg" /><img
          *ngIf="pin"
          class="ico"
          src="/static-files/logo-new/logo-one-by-clarilog-blanc-250x50.svg"
      /></a>
    </div>
    <div class="menu-container">
      <clc-nav-menu
        mode="compact"
        #menu
        [toggle]="toggle"
        (onToggle)="toggle = $event"
        (onPinned)="pin = $event"
        [showToggle]="false"
        [pin]="pin"
        [hover]="true"
        [showPin]="true"
        style="background-color: #ececec"
      >
        <clc-nav-group
          *ngFor="let feature of features"
          [text]="feature.title && (feature.title | translate)"
          [hint]="feature.title && (feature.title | translate)"
        >
          <clc-nav-menu-item
            *ngFor="let item of feature.items"
            [enabled]="item.enabled === true"
            [icon]="item.icon"
            [hint]="item.title | translate"
            [text]="item.title | translate"
            [active]="item === currentItem"
            (onMouseDown)="itemFeatureMouseDown($event, item)"
            (onClick)="itemFeatureClick($event, item)"
          >
          </clc-nav-menu-item>
        </clc-nav-group>

        <clc-nav-group locate="footer">
          <clc-nav-menu-item
            [visibled]="!viewHelpMe"
            *clcHasPolicies="{
              policies: ['administration', '/^help-desk-operator.manage'],
              operator: 'or'
            }"
            icon="fal fa-tools"
            [hint]="'globals/administration' | translate"
            [text]="'globals/administration' | translate"
            [active]="'administration' === currentItem"
            (onMouseDown)="itemFeatureMouseDown($event, 'administration')"
            (onClick)="itemFeatureClick($event, 'administration')"
          >
          </clc-nav-menu-item>
        </clc-nav-group>
      </clc-nav-menu>
    </div>
  </aside>
  <main class="content">
    <header class="header">
      <div class="nav-bar-content">
        <dx-toolbar class="navbar">
          <dxi-item location="before">
            <cl-breadcrumb></cl-breadcrumb>
          </dxi-item>

          <dxi-item location="center">
            <cl-warning-customer-item></cl-warning-customer-item>
          </dxi-item>

          <dxi-item location="center">
            <cl-warning-message-item></cl-warning-message-item>
          </dxi-item>


          <dxi-item location="center">
            <cl-layout-message-item></cl-layout-message-item>
          </dxi-item>

          <dxi-item location="after" class="cl-textbox-search">
            <dx-text-box
              #searchText
              [placeholder]="'globals/search' | translate"
              mode="search"
              width="200px"
              [visible]="showFieldSearch"
              (onFocusIn)="onSearchTextFocusIn($event)"
              (onFocusOut)="onSearchTextFocusOut($event)"
              (onInitialized)="onSearchTextInitialized($event)"
              (onKeyUp)="keyUp.next($event)"
              (onKeyDown)="onSearchTextKeyDown($event)"
            >
            </dx-text-box>
          </dxi-item>
          <dxi-item location="after">
            <dx-button
              icon="fal fa-tasks"
              [visible]="showFieldSearch && showChooserSearch"
              itemTemplate="itemsSearchTemplate"
              (onInitialized)="onSearchButtonInitialized($event)"
              (onClick)="itemSearchClick($event)"
              class="cl-item-button-search"
            >
            </dx-button>
          </dxi-item>
          <dxi-item location="after">
            <div *ngIf="viewHelpMe === false">
              <clc-alert *clcHasPolicies="['alert.read']"></clc-alert>
            </div>
          </dxi-item>
          <dxi-item location="after">
            <clc-notification
              *ngIf="viewHelpMe === false && canViewNotification === true"
              [state]='state'
            ></clc-notification>
          </dxi-item>
          <dxi-item location="after">
            <dx-menu (onItemClick)="itemMenuClick($event)" [items]="menuItems">
              <!-- <div
                *dxTemplate="let item of 'separator'"
                style="padding-top: 0;"
              >
               <div
                  style="border-top: 1px rgb(230, 230, 230) solid;width: 100%;padding-bottom: 6px;margin: 0;"
                ></div>
                {{ item.text }}
              </div> -->
            </dx-menu>
          </dxi-item>
        </dx-toolbar>
      </div>
      <div
        (clickOutside)="onClickedOutside($event)"
        #rightNav
        class="right-nav"
        [ngClass]="{ open: isOpened === true }"
      >
        <div class="close-btn">
          <a href="javascript:void(0)" class="close-btn" (click)="close()"
            ><i class="fal fa-times"></i
          ></a>
        </div>
        <mat-form-field>
          <mat-label>{{ 'globals/languages' | translate }}</mat-label>
          <mat-select
            (selectionChange)="setPreferenceLang($event)"
            [(value)]="currentLang"
          >
            <mat-option *ngFor="let langue of langs" value="{{ langue.key }}">{{
              langue.value
            }}</mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </header>

    <div class="container">
      <router-outlet class="body" (activate)='onOutletLoaded($event)'></router-outlet>
    </div>
  </main>
</div>

<dx-popover
  *ngIf="showItemSearch"
  class="cl-popover"
  [position]="{
    my: 'right top',
    at: 'right bottom',
    of: itemSearchButtonElement,
    collision: {
      x: 'flip',
      y: 'flip'
    }
  }"
  [width]="'auto'"
  [(visible)]="showItemSearch"
>
  <dx-load-indicator [visible]="isSearchLoading"></dx-load-indicator>
  <dx-list
    [visible]="!isSearchLoading"
    width="200px"
    [items]="itemsSearch"
    displayExpr="title"
    keyExpr="id"
    selectionMode="all"
    selectAllMode="page"
    [showSelectionControls]="true"
    [(selectedItemKeys)]="selectedItemsSearch"
  >
  </dx-list>
</dx-popover>

<dx-popover
  *ngIf="showResultSearch"
  class="cl-popover"
  (onHidden)="onResultSearchHidden($event)"
  [position]="{
    my: 'left top',
    at: 'left bottom',
    of: itemSearchTextElement,
    collision: {
      x: 'flip',
      y: 'flip'
    }
  }"
  [width]="'auto'"
  [(visible)]="showResultSearch"
>
  <div class="cl-list-search-result"></div>
  <dx-load-indicator
    class="cl-search-load"
    [visible]="isSearchLoading"
  ></dx-load-indicator>

  <dx-list
    #searchResultList
    [visible]="!isSearchLoading"
    class="cl-list-search-result"
    [dataSource]="sourceSearch"
    keyExpr="id"
    selectionMode="none"
    selectAllMode="page"
    [grouped]="true"
    [collapsibleGroups]="false"
    (onItemClick)="onSearchResultItemClick($event)"
  >
    <div *dxTemplate="let item of 'group'">
      <div
        class="cl-list-item-group"
        (click)="onItemGroupClick(item)"
        (auxclick)="onItemGroupAuxClick(item)"
      >
        <i [class]="item.icon"></i> {{ item.key }}
      </div>
    </div>

    <div
      *dxTemplate="let item of 'item'"
      [ngSwitch]="item.__typename"
      class="item-search-result"
    >
      <div *ngSwitchCase="'Asset'">
        <div>{{ item.name }}</div>
        <span>{{ getTranslateField(item.assetCategory?.data?.name) }} </span>
        <span *ngIf="item.ipAddress != undefined"> \ {{ item.ipAddress }}</span>
      </div>

      <div *ngSwitchCase="'Software'">
        <span
          >{{ 'enums/softwareCategory/' + item.softwareCategory | translate }}
        </span>
        <div>{{ item.name }}</div>
      </div>

      <div *ngSwitchCase="'Incident'">
        <div>{{ item.title }}</div>
        <span>
          {{ 'entities/incident/ticketNumber' | translate }} :
          {{ item.ticketNumber }}
        </span>
        <span *ngIf="item.userAffected?.data?.name !== null">
          | {{ 'entities/incident/userAffected' | translate }} :
          {{ item.userAffected?.data?.name }}
        </span>
      </div>

      <div *ngSwitchCase="'Request'">
        <div>{{ item.title }}</div>
        <span>
          {{ 'entities/incident/ticketNumber' | translate }} :
          {{ item.ticketNumber }}
        </span>
        <span *ngIf="item.userAffected?.data?.name !== null">
          | {{ 'entities/incident/userAffected' | translate }} :
          {{ item.userAffected?.data?.name }}
        </span>
      </div>

      <div *ngSwitchCase="'ArticleKnowledge'">
        <div>{{ getTranslateField(item.title) }}</div>
      </div>

      <div *ngSwitchCase="'User'">
        <div>{{ item.name }}</div>
        <span>{{ item.email }}</span>
      </div>

      <div *ngSwitchCase="'Contract'">
        <span>{{ getTranslateField(item.contractCategory?.data?.name) }} </span>
        <div>{{ item.name }}</div>
      </div>

      <div *ngSwitchCase="'Loan'">
        <div>
          {{ 'entities/loan/_title/singular' | translate }} : {{ item.number }}
        </div>
      </div>

      <div *ngSwitchDefault>
        {{ getTranslateValue(item.name) }}
      </div>
    </div>
  </dx-list>
</dx-popover>

<dx-popup
  *ngIf="showFeedback"
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="showFeedback"
>
  <clc-work-form
    [model]="modelFeedback"
    [service]="feedbackService"
    (onSubmit)="onSubmit($event)"
    [showBack]="false"
    [successMessage]="'globals/sendFeedback'"
    [navigateAfterSave]="false"
    [saveTilte]="'globals/send'"
    [saveSplitButton]="false"
  >
  </clc-work-form>
</dx-popup>

<clc-viewer-popup
  [(showViewer)]="showViewer"
  [showEvaluateZone]="'ArticleKnowledgeNoteCoreService'"
  [titleTranslatable]="true"
  [countService]="'ArticleKnowledgeCoreService'"
  [serviceName]="'ArticleKnowledgeCoreService'"
  [id]="idEntity"
  [icon]="'fal fa-book-open'"
  [color]="'rgb(var(--headband-color))'"
  [secondColor]="'rgb(var(--primary-color))'"
  [countMethodeService]="'countViewByOperator'"
></clc-viewer-popup>
