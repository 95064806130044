<div class="mobile-header">
  <div class="mobile-header-logo" style="display: flex">
    <div class="logo">
      <img
        class="small-ico"
        src="/static-files/logo-new/embleme-blanc-50x50.svg"
      />
    </div>
  </div>
</div>
<cl-mobile-manage-list
  *ngIf="modelState != undefined"
  [modelState]="modelState"
  [layoutState]="layoutState"
  dataRowTemplateName="organizationRowTemplate"
  disableOnRowClickNavigation="true"
  (onRowClick)="onClick($event)"
>
  <dx-speed-dial-action
    icon="fad fa-power-off"
    label="Se déconnecter"
    (onClick)="disconnect()"
    [visible]="true"
  >
  </dx-speed-dial-action>
</cl-mobile-manage-list>
