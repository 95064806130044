import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVerifaliaEmailCheckMetricsBaseVariables, FirstVerifaliaEmailCheckMetricsBaseVariables, CountVerifaliaEmailCheckMetricsBaseVariables, FindVerifaliaEmailCheckMetricsBaseVariables, SearchVerifaliaEmailCheckMetricsBaseVariables, ExportSchemaVerifaliaEmailCheckMetricsBaseVariables, ExportDataVerifaliaEmailCheckMetricsBaseVariables, CustomQueryVerifaliaEmailCheckMetricsBaseVariables, CustomQueryIdVerifaliaEmailCheckMetricsBaseVariables, UsedVerifaliaEmailCheckMetricsBaseVariables, ExistVerifaliaEmailCheckMetricsBaseVariables, InsertVerifaliaEmailCheckMetricsBaseVariables, UpdateVerifaliaEmailCheckMetricsBaseVariables, ImportDataVerifaliaEmailCheckMetricsBaseVariables, UpdateManyVerifaliaEmailCheckMetricsBaseVariables, DeleteVerifaliaEmailCheckMetricsBaseVariables } from '../gqls'
import { GetVerifaliaEmailCheckMetricsDocument, FirstVerifaliaEmailCheckMetricsDocument, CountVerifaliaEmailCheckMetricsDocument, FindVerifaliaEmailCheckMetricsDocument, SearchVerifaliaEmailCheckMetricsDocument, ExportSchemaVerifaliaEmailCheckMetricsDocument, ExportDataVerifaliaEmailCheckMetricsDocument, CustomQueryVerifaliaEmailCheckMetricsDocument, CustomQueryIdVerifaliaEmailCheckMetricsDocument, UsedVerifaliaEmailCheckMetricsDocument, ExistVerifaliaEmailCheckMetricsDocument, InsertVerifaliaEmailCheckMetricsDocument, UpdateVerifaliaEmailCheckMetricsDocument, ImportDataVerifaliaEmailCheckMetricsDocument, UpdateManyVerifaliaEmailCheckMetricsDocument, DeleteVerifaliaEmailCheckMetricsDocument } from '../gqls'
import { ServiceSingleResultOfVerifaliaEmailCheckMetric, QueryContextOfVerifaliaEmailCheckMetric, FilterOfVerifaliaEmailCheckMetric, ServiceSingleResultOfInt64, ServiceListResultOfVerifaliaEmailCheckMetric, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, VerifaliaEmailCheckMetricInput, FieldUpdateOperatorOfVerifaliaEmailCheckMetric } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VerifaliaEmailCheckMetricBaseService {
    
public modelName = 'verifaliaEmailCheckMetric';
public modelPluralName = 'verifaliaEmailCheckMetrics';

	private getVerifaliaEmailCheckMetricsQuery: GetVerifaliaEmailCheckMetricsDocument;
	private firstVerifaliaEmailCheckMetricsQuery: FirstVerifaliaEmailCheckMetricsDocument;
	private countVerifaliaEmailCheckMetricsQuery: CountVerifaliaEmailCheckMetricsDocument;
	private findVerifaliaEmailCheckMetricsQuery: FindVerifaliaEmailCheckMetricsDocument;
	private searchVerifaliaEmailCheckMetricsQuery: SearchVerifaliaEmailCheckMetricsDocument;
	private exportSchemaVerifaliaEmailCheckMetricsQuery: ExportSchemaVerifaliaEmailCheckMetricsDocument;
	private exportDataVerifaliaEmailCheckMetricsQuery: ExportDataVerifaliaEmailCheckMetricsDocument;
	private customQueryVerifaliaEmailCheckMetricsQuery: CustomQueryVerifaliaEmailCheckMetricsDocument;
	private customQueryIdVerifaliaEmailCheckMetricsQuery: CustomQueryIdVerifaliaEmailCheckMetricsDocument;
	private usedVerifaliaEmailCheckMetricsQuery: UsedVerifaliaEmailCheckMetricsDocument;
	private existVerifaliaEmailCheckMetricsQuery: ExistVerifaliaEmailCheckMetricsDocument;
	private insertVerifaliaEmailCheckMetricsMutation: InsertVerifaliaEmailCheckMetricsDocument;
	private updateVerifaliaEmailCheckMetricsMutation: UpdateVerifaliaEmailCheckMetricsDocument;
	private importDataVerifaliaEmailCheckMetricsMutation: ImportDataVerifaliaEmailCheckMetricsDocument;
	private updateManyVerifaliaEmailCheckMetricsMutation: UpdateManyVerifaliaEmailCheckMetricsDocument;
	private deleteVerifaliaEmailCheckMetricsMutation: DeleteVerifaliaEmailCheckMetricsDocument;

	constructor(private injector: Injector) {
		this.getVerifaliaEmailCheckMetricsQuery = this.injector.get(GetVerifaliaEmailCheckMetricsDocument);
		this.firstVerifaliaEmailCheckMetricsQuery = this.injector.get(FirstVerifaliaEmailCheckMetricsDocument);
		this.countVerifaliaEmailCheckMetricsQuery = this.injector.get(CountVerifaliaEmailCheckMetricsDocument);
		this.findVerifaliaEmailCheckMetricsQuery = this.injector.get(FindVerifaliaEmailCheckMetricsDocument);
		this.searchVerifaliaEmailCheckMetricsQuery = this.injector.get(SearchVerifaliaEmailCheckMetricsDocument);
		this.exportSchemaVerifaliaEmailCheckMetricsQuery = this.injector.get(ExportSchemaVerifaliaEmailCheckMetricsDocument);
		this.exportDataVerifaliaEmailCheckMetricsQuery = this.injector.get(ExportDataVerifaliaEmailCheckMetricsDocument);
		this.customQueryVerifaliaEmailCheckMetricsQuery = this.injector.get(CustomQueryVerifaliaEmailCheckMetricsDocument);
		this.customQueryIdVerifaliaEmailCheckMetricsQuery = this.injector.get(CustomQueryIdVerifaliaEmailCheckMetricsDocument);
		this.usedVerifaliaEmailCheckMetricsQuery = this.injector.get(UsedVerifaliaEmailCheckMetricsDocument);
		this.existVerifaliaEmailCheckMetricsQuery = this.injector.get(ExistVerifaliaEmailCheckMetricsDocument);
		this.insertVerifaliaEmailCheckMetricsMutation = this.injector.get(InsertVerifaliaEmailCheckMetricsDocument);
		this.updateVerifaliaEmailCheckMetricsMutation = this.injector.get(UpdateVerifaliaEmailCheckMetricsDocument);
		this.importDataVerifaliaEmailCheckMetricsMutation = this.injector.get(ImportDataVerifaliaEmailCheckMetricsDocument);
		this.updateManyVerifaliaEmailCheckMetricsMutation = this.injector.get(UpdateManyVerifaliaEmailCheckMetricsDocument);
		this.deleteVerifaliaEmailCheckMetricsMutation = this.injector.get(DeleteVerifaliaEmailCheckMetricsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: GetVerifaliaEmailCheckMetricsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.get));
            }
            else{
                let result:ServiceSingleResultOfVerifaliaEmailCheckMetric={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheckMetric,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: FirstVerifaliaEmailCheckMetricsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVerifaliaEmailCheckMetricsBaseVariables = {
    			filter: filter
    		}
    				return this.countVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaEmailCheckMetric,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: FindVerifaliaEmailCheckMetricsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheckMetric,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: SearchVerifaliaEmailCheckMetricsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVerifaliaEmailCheckMetricsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVerifaliaEmailCheckMetricsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheckMetric,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: CustomQueryVerifaliaEmailCheckMetricsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVerifaliaEmailCheckMetric,
		@Args('filter?') filter?: FilterOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: CustomQueryIdVerifaliaEmailCheckMetricsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVerifaliaEmailCheckMetricsBaseVariables = {
    			ids: ids
    		}
    				return this.usedVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVerifaliaEmailCheckMetricsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVerifaliaEmailCheckMetricsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: VerifaliaEmailCheckMetricInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: InsertVerifaliaEmailCheckMetricsBaseVariables = {
    			entity: entity
    		}
    				return this.insertVerifaliaEmailCheckMetricsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaEmailCheckMetric> {
    
    		let variables: UpdateVerifaliaEmailCheckMetricsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateVerifaliaEmailCheckMetricsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataVerifaliaEmailCheckMetricsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataVerifaliaEmailCheckMetricsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaEmailCheckMetric,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyVerifaliaEmailCheckMetricsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyVerifaliaEmailCheckMetricsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteVerifaliaEmailCheckMetricsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteVerifaliaEmailCheckMetricsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaEmailCheckMetrics.delete));
    	}
    
}