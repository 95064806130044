import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWarningCustomersBaseVariables, FirstWarningCustomersBaseVariables, CountWarningCustomersBaseVariables, FindWarningCustomersBaseVariables, SearchWarningCustomersBaseVariables, ExportSchemaWarningCustomersBaseVariables, ExportDataWarningCustomersBaseVariables, CustomQueryWarningCustomersBaseVariables, CustomQueryIdWarningCustomersBaseVariables, UsedWarningCustomersBaseVariables, ExistWarningCustomersBaseVariables, InsertWarningCustomersBaseVariables, UpdateWarningCustomersBaseVariables, ImportDataWarningCustomersBaseVariables, UpdateManyWarningCustomersBaseVariables, DeleteWarningCustomersBaseVariables } from '../gqls'
import { GetWarningCustomersDocument, FirstWarningCustomersDocument, CountWarningCustomersDocument, FindWarningCustomersDocument, SearchWarningCustomersDocument, ExportSchemaWarningCustomersDocument, ExportDataWarningCustomersDocument, CustomQueryWarningCustomersDocument, CustomQueryIdWarningCustomersDocument, UsedWarningCustomersDocument, ExistWarningCustomersDocument, InsertWarningCustomersDocument, UpdateWarningCustomersDocument, ImportDataWarningCustomersDocument, UpdateManyWarningCustomersDocument, DeleteWarningCustomersDocument } from '../gqls'
import { ServiceSingleResultOfWarningCustomer, QueryContextOfWarningCustomer, FilterOfWarningCustomer, ServiceSingleResultOfInt64, ServiceListResultOfWarningCustomer, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WarningCustomerInput, FieldUpdateOperatorOfWarningCustomer } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WarningCustomerBaseService {
    
public modelName = 'warningCustomer';
public modelPluralName = 'warningCustomers';

	private getWarningCustomersQuery: GetWarningCustomersDocument;
	private firstWarningCustomersQuery: FirstWarningCustomersDocument;
	private countWarningCustomersQuery: CountWarningCustomersDocument;
	private findWarningCustomersQuery: FindWarningCustomersDocument;
	private searchWarningCustomersQuery: SearchWarningCustomersDocument;
	private exportSchemaWarningCustomersQuery: ExportSchemaWarningCustomersDocument;
	private exportDataWarningCustomersQuery: ExportDataWarningCustomersDocument;
	private customQueryWarningCustomersQuery: CustomQueryWarningCustomersDocument;
	private customQueryIdWarningCustomersQuery: CustomQueryIdWarningCustomersDocument;
	private usedWarningCustomersQuery: UsedWarningCustomersDocument;
	private existWarningCustomersQuery: ExistWarningCustomersDocument;
	private insertWarningCustomersMutation: InsertWarningCustomersDocument;
	private updateWarningCustomersMutation: UpdateWarningCustomersDocument;
	private importDataWarningCustomersMutation: ImportDataWarningCustomersDocument;
	private updateManyWarningCustomersMutation: UpdateManyWarningCustomersDocument;
	private deleteWarningCustomersMutation: DeleteWarningCustomersDocument;

	constructor(private injector: Injector) {
		this.getWarningCustomersQuery = this.injector.get(GetWarningCustomersDocument);
		this.firstWarningCustomersQuery = this.injector.get(FirstWarningCustomersDocument);
		this.countWarningCustomersQuery = this.injector.get(CountWarningCustomersDocument);
		this.findWarningCustomersQuery = this.injector.get(FindWarningCustomersDocument);
		this.searchWarningCustomersQuery = this.injector.get(SearchWarningCustomersDocument);
		this.exportSchemaWarningCustomersQuery = this.injector.get(ExportSchemaWarningCustomersDocument);
		this.exportDataWarningCustomersQuery = this.injector.get(ExportDataWarningCustomersDocument);
		this.customQueryWarningCustomersQuery = this.injector.get(CustomQueryWarningCustomersDocument);
		this.customQueryIdWarningCustomersQuery = this.injector.get(CustomQueryIdWarningCustomersDocument);
		this.usedWarningCustomersQuery = this.injector.get(UsedWarningCustomersDocument);
		this.existWarningCustomersQuery = this.injector.get(ExistWarningCustomersDocument);
		this.insertWarningCustomersMutation = this.injector.get(InsertWarningCustomersDocument);
		this.updateWarningCustomersMutation = this.injector.get(UpdateWarningCustomersDocument);
		this.importDataWarningCustomersMutation = this.injector.get(ImportDataWarningCustomersDocument);
		this.updateManyWarningCustomersMutation = this.injector.get(UpdateManyWarningCustomersDocument);
		this.deleteWarningCustomersMutation = this.injector.get(DeleteWarningCustomersDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningCustomer> {
    
    		let variables: GetWarningCustomersBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.get));
            }
            else{
                let result:ServiceSingleResultOfWarningCustomer={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWarningCustomer,
		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningCustomer> {
    
    		let variables: FirstWarningCustomersBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWarningCustomersBaseVariables = {
    			filter: filter
    		}
    				return this.countWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWarningCustomer,
		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningCustomer> {
    
    		let variables: FindWarningCustomersBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWarningCustomer,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningCustomer> {
    
    		let variables: SearchWarningCustomersBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWarningCustomersBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWarningCustomersBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWarningCustomer,
		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningCustomer> {
    
    		let variables: CustomQueryWarningCustomersBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWarningCustomer,
		@Args('filter?') filter?: FilterOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWarningCustomer> {
    
    		let variables: CustomQueryIdWarningCustomersBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWarningCustomersBaseVariables = {
    			ids: ids
    		}
    				return this.usedWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWarningCustomersBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWarningCustomersQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: WarningCustomerInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningCustomer> {
    
    		let variables: InsertWarningCustomersBaseVariables = {
    			entity: entity
    		}
    				return this.insertWarningCustomersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWarningCustomer> {
    
    		let variables: UpdateWarningCustomersBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateWarningCustomersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataWarningCustomersBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataWarningCustomersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWarningCustomer,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWarningCustomersBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWarningCustomersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWarningCustomersBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWarningCustomersMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.warningCustomers.delete));
    	}
    
}