import { AfterViewInit, ChangeDetectorRef, Component, ElementRef, forwardRef, SecurityContext, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { LicenseAgreementType } from '@clarilog/core';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer } from '@angular/platform-browser';

/** Représente le composent License. Permet d'accepter les conditions d'utilisation. */
@Component({
  selector: 'cl-license',
  templateUrl: './license.component.html',
  styleUrls: ['./license.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => LicenseComponent),
      multi: true,
    },
  ],
})
export class LicenseComponent implements ControlValueAccessor, AfterViewInit {
  lockedLicenceAlreadyUse: boolean = false;
  showPopup: boolean = false;
  scrollTop: number = 0;
  licenseEnabled: boolean = false;
  value: LicenseAgreementType;
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  @ViewChild('iframe') iframe: ElementRef;
  private externalHtml: any;
  constructor(
    private _changeDetectorRef: ChangeDetectorRef,
    private authorizationService: AuthorizationCoreService,
    private http: HttpClient,
    private sanitizer: DomSanitizer
  ) {}
  ngAfterViewInit(): void {
    this.http.get('/static-files/license/license-fr.html',{responseType:'text'}).subscribe(
      data => this.externalHtml = this.sanitizer.sanitize(SecurityContext.HTML, data)
    );
  }
  writeValue(obj: LicenseAgreementType): void {
    if (obj != undefined) {
      this.value = obj;
    } else {
      this.value = <LicenseAgreementType>{
        accept: false,
      };
    }
    delete this.value.__typename;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    //throw new Error("Method not implemented.");
    if (isDisabled == true) {
      this.lockedLicenceAlreadyUse = true;
    } else if (isDisabled == false) {
      this.lockedLicenceAlreadyUse = false;
    }
  }
  showing(e) {
    if (this.value.accept !== true) {
      this.licenseEnabled = false;
    }
    this._changeDetectorRef.detectChanges();
  }
  shown(e) {
    let content = this.iframe.nativeElement.contentWindow;
    let document = content.document;
    document.open();
    document.write(this.externalHtml);
    document.close();
    content.onscroll = this.scroll.bind(this);
    this.iframe.nativeElement.contentWindow.document.scrollingElement.scrollTop = this.scrollTop;
  }
  click(e) {
    this.showPopup = true;
  }
  scroll(e) {
    let content = e.target.scrollingElement;

    this.scrollTop = content.scrollTop;
    if (content.scrollHeight - content.clientHeight - 10 <= content.scrollTop) {
      if (this.lockedLicenceAlreadyUse == false) {
        this.licenseEnabled = true;
        this._changeDetectorRef.detectChanges();
      }
    }
  }
  reject(e) {
    this.value.accept = false;
    this.value.acceptedDate = undefined;
    this.onChange(this.value);
    this.showPopup = false;
  }
  accept(e) {
    this.value.accept = true;
    this.value.acceptedDate = new Date();

    this.value.acceptedSubId = this.authorizationService.user.getClaim('sub');
    this.value.acceptedUserName =
      this.authorizationService.user.getClaim('firstName') +
      ' ' +
      this.authorizationService.user.getClaim('family_name');
    this.onChange(this.value);
    this.showPopup = false;
  }
}
