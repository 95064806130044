import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetUptimeCalendarsBaseVariables, FirstUptimeCalendarsBaseVariables, CountUptimeCalendarsBaseVariables, FindUptimeCalendarsBaseVariables, SearchUptimeCalendarsBaseVariables, ExportSchemaUptimeCalendarsBaseVariables, ExportDataUptimeCalendarsBaseVariables, CustomQueryUptimeCalendarsBaseVariables, CustomQueryIdUptimeCalendarsBaseVariables, UsedUptimeCalendarsBaseVariables, ExistUptimeCalendarsBaseVariables } from '../gqls'
import { GetUptimeCalendarsDocument, FirstUptimeCalendarsDocument, CountUptimeCalendarsDocument, FindUptimeCalendarsDocument, SearchUptimeCalendarsDocument, ExportSchemaUptimeCalendarsDocument, ExportDataUptimeCalendarsDocument, CustomQueryUptimeCalendarsDocument, CustomQueryIdUptimeCalendarsDocument, UsedUptimeCalendarsDocument, ExistUptimeCalendarsDocument } from '../gqls'
import { ServiceSingleResultOfUptimeCalendar, QueryContextOfUptimeCalendar, FilterOfUptimeCalendar, ServiceSingleResultOfInt64, ServiceListResultOfUptimeCalendar, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class UptimeCalendarBaseService {
    
public modelName = 'uptimeCalendar';
public modelPluralName = 'uptimeCalendars';

	private getUptimeCalendarsQuery: GetUptimeCalendarsDocument;
	private firstUptimeCalendarsQuery: FirstUptimeCalendarsDocument;
	private countUptimeCalendarsQuery: CountUptimeCalendarsDocument;
	private findUptimeCalendarsQuery: FindUptimeCalendarsDocument;
	private searchUptimeCalendarsQuery: SearchUptimeCalendarsDocument;
	private exportSchemaUptimeCalendarsQuery: ExportSchemaUptimeCalendarsDocument;
	private exportDataUptimeCalendarsQuery: ExportDataUptimeCalendarsDocument;
	private customQueryUptimeCalendarsQuery: CustomQueryUptimeCalendarsDocument;
	private customQueryIdUptimeCalendarsQuery: CustomQueryIdUptimeCalendarsDocument;
	private usedUptimeCalendarsQuery: UsedUptimeCalendarsDocument;
	private existUptimeCalendarsQuery: ExistUptimeCalendarsDocument;

	constructor(private injector: Injector) {
		this.getUptimeCalendarsQuery = this.injector.get(GetUptimeCalendarsDocument);
		this.firstUptimeCalendarsQuery = this.injector.get(FirstUptimeCalendarsDocument);
		this.countUptimeCalendarsQuery = this.injector.get(CountUptimeCalendarsDocument);
		this.findUptimeCalendarsQuery = this.injector.get(FindUptimeCalendarsDocument);
		this.searchUptimeCalendarsQuery = this.injector.get(SearchUptimeCalendarsDocument);
		this.exportSchemaUptimeCalendarsQuery = this.injector.get(ExportSchemaUptimeCalendarsDocument);
		this.exportDataUptimeCalendarsQuery = this.injector.get(ExportDataUptimeCalendarsDocument);
		this.customQueryUptimeCalendarsQuery = this.injector.get(CustomQueryUptimeCalendarsDocument);
		this.customQueryIdUptimeCalendarsQuery = this.injector.get(CustomQueryIdUptimeCalendarsDocument);
		this.usedUptimeCalendarsQuery = this.injector.get(UsedUptimeCalendarsDocument);
		this.existUptimeCalendarsQuery = this.injector.get(ExistUptimeCalendarsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUptimeCalendar> {
    
    		let variables: GetUptimeCalendarsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.get));
            }
            else{
                let result:ServiceSingleResultOfUptimeCalendar={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUptimeCalendar,
		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfUptimeCalendar> {
    
    		let variables: FirstUptimeCalendarsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountUptimeCalendarsBaseVariables = {
    			filter: filter
    		}
    				return this.countUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUptimeCalendar,
		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUptimeCalendar> {
    
    		let variables: FindUptimeCalendarsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfUptimeCalendar,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUptimeCalendar> {
    
    		let variables: SearchUptimeCalendarsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaUptimeCalendarsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataUptimeCalendarsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfUptimeCalendar,
		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUptimeCalendar> {
    
    		let variables: CustomQueryUptimeCalendarsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfUptimeCalendar,
		@Args('filter?') filter?: FilterOfUptimeCalendar,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUptimeCalendar> {
    
    		let variables: CustomQueryIdUptimeCalendarsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedUptimeCalendarsBaseVariables = {
    			ids: ids
    		}
    				return this.usedUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistUptimeCalendarsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existUptimeCalendarsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.uptimeCalendars.exist));
    	}
    
    
}