import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { FieldsCommonsBaseVariables, AllFieldsCommonsBaseVariables } from '../gqls'
import { FieldsCommonsDocument, AllFieldsCommonsDocument } from '../gqls'
import { ServiceListResultOfPropertyField } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CommonBaseService {
    
public modelName = 'common';
public modelPluralName = 'commons';

	private fieldsCommonsQuery: FieldsCommonsDocument;
	private allFieldsCommonsQuery: AllFieldsCommonsDocument;

	constructor(private injector: Injector) {
		this.fieldsCommonsQuery = this.injector.get(FieldsCommonsDocument);
		this.allFieldsCommonsQuery = this.injector.get(AllFieldsCommonsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/**  */
    	@InjectArgs
    	public fields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPropertyField> {
    
    		let variables: FieldsCommonsBaseVariables = {
    			type: type
    		}
    				return this.fieldsCommonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commons.fields));
    	}

    	/**  */
    	@InjectArgs
    	public allFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPropertyField> {
    
    		let variables: AllFieldsCommonsBaseVariables = {
    			type: type
    		}
    				return this.allFieldsCommonsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commons.allFields));
    	}
    
    
}