import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfImpact, FilterOfImpact, QueryContextOfImpact, ServiceSingleResultOfInt64, ServiceListResultOfImpact, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceListResultOfSecurityGroup, FilterOfSecurityGroup, QueryContextOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfPopulation, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfImpact, ServiceListResultOfTicket, FilterOfTicket, QueryContextOfTicket, ServiceListResultOfIncidentModel, FilterOfIncidentModel, QueryContextOfIncidentModel, ImpactInput } from '../types'

export type GetImpactsResultType = {
    impacts: {
        get?: Maybe<ServiceSingleResultOfImpact>
    }
}

export type GetImpactsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getImpacts ${args} {
    impacts {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstImpactsResultType = {
    impacts: {
        first?: Maybe<ServiceSingleResultOfImpact>
    }
}

export type FirstImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstImpacts ${args} {
    impacts {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountImpactsResultType = {
    impacts: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countImpacts ${args} {
    impacts {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindImpactsResultType = {
    impacts: {
        find?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindImpactsBaseVariables = {
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfImpact = null, $filter: FilterOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findImpacts ${args} {
    impacts {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchImpactsResultType = {
    impacts: {
        search?: Maybe<ServiceListResultOfImpact>
    }
}

export type SearchImpactsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchImpacts ${args} {
    impacts {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaImpactsResultType = {
    impacts: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaImpactsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaImpacts ${args} {
    impacts {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataImpactsResultType = {
    impacts: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataImpacts ${args} {
    impacts {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryImpactsResultType = {
    impacts: {
        customQuery?: Maybe<ServiceListResultOfImpact>
    }
}

export type CustomQueryImpactsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryImpacts ${args} {
    impacts {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdImpactsResultType = {
    impacts: {
        customQueryId?: Maybe<ServiceListResultOfImpact>
    }
}

export type CustomQueryIdImpactsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdImpacts ${args} {
    impacts {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedSecurityGroupsImpactsResultType = {
    impacts: {
        findUnassociatedSecurityGroups?: Maybe<ServiceListResultOfSecurityGroup>
    }
}

export type FindUnassociatedSecurityGroupsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les profils non liés de l'entité. */
@Injectable({providedIn: 'root'})
export class FindUnassociatedSecurityGroupsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedSecurityGroupsImpacts ${args} {
    impacts {
        findUnassociatedSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedSecurityGroupsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedSecurityGroupsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfSecurityGroup", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsImpactsResultType = {
    impacts: {
        findAssociatedOperatorTeamSecurityGroups?: Maybe<ServiceListResultOfOperatorTeam>
    }
}

export type FindAssociatedOperatorTeamSecurityGroupsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les equipes d'operateur via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedOperatorTeamSecurityGroupsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedOperatorTeamSecurityGroupsImpacts ${args} {
    impacts {
        findAssociatedOperatorTeamSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedOperatorTeamSecurityGroupsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedOperatorTeamSecurityGroupsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOperatorTeam", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAssociatedPopulationSecurityGroupsImpactsResultType = {
    impacts: {
        findAssociatedPopulationSecurityGroups?: Maybe<ServiceListResultOfPopulation>
    }
}

export type FindAssociatedPopulationSecurityGroupsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfSecurityGroup>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfSecurityGroup>, /** Les options de requêtage à appliquer. */
}

/** Récupère les population via les profils de l'entité. */
@Injectable({providedIn: 'root'})
export class FindAssociatedPopulationSecurityGroupsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfSecurityGroup = null, $options: QueryContextOfSecurityGroup = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAssociatedPopulationSecurityGroupsImpacts ${args} {
    impacts {
        findAssociatedPopulationSecurityGroups(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAssociatedPopulationSecurityGroupsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAssociatedPopulationSecurityGroupsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfPopulation", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedImpactsResultType = {
    impacts: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedImpactsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedImpacts ${args} {
    impacts {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistImpactsResultType = {
    impacts: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistImpactsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existImpacts ${args} {
    impacts {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesImpactsResultType = {
    impacts: {
        findRecycles?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindRecyclesImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesImpacts ${args} {
    impacts {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesImpactsResultType = {
    impacts: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesImpacts ${args} {
    impacts {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyImpactsResultType = {
    impacts: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyImpactsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyImpacts ${args} {
    impacts {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedImpactsResultType = {
    impacts: {
        findArchived?: Maybe<ServiceListResultOfImpact>
    }
}

export type FindArchivedImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfImpact>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null, $options: QueryContextOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedImpacts ${args} {
    impacts {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllImpactsResultType = {
    impacts: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllImpactsBaseVariables = {
	filter: Maybe<FilterOfImpact>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllImpacts ${args} {
    impacts {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsImpactsResultType = {
    impacts: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfImpact>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsImpacts ${args} {
    impacts {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedTicketsImpactsResultType = {
    impacts: {
        findUnassociatedTickets?: Maybe<ServiceListResultOfTicket>
    }
}

export type FindUnassociatedTicketsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfTicket>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfTicket>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedTicketsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfTicket = null, $options: QueryContextOfTicket = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedTicketsImpacts ${args} {
    impacts {
        findUnassociatedTickets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedTicketsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedTicketsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfTicket", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedIncidentModelsImpactsResultType = {
    impacts: {
        findUnassociatedIncidentModels?: Maybe<ServiceListResultOfIncidentModel>
    }
}

export type FindUnassociatedIncidentModelsImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfIncidentModel>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfIncidentModel>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedIncidentModelsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfIncidentModel = null, $options: QueryContextOfIncidentModel = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedIncidentModelsImpacts ${args} {
    impacts {
        findUnassociatedIncidentModels(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedIncidentModelsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedIncidentModelsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfIncidentModel", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertImpactsResultType = {
    impacts: {
        insert?: Maybe<ServiceSingleResultOfImpact>
    }
}

export type InsertImpactsBaseVariables = {
	entity: ImpactInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: ImpactInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertImpacts ${args} {
    impacts {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateImpactsResultType = {
    impacts: {
        update?: Maybe<ServiceSingleResultOfImpact>
    }
}

export type UpdateImpactsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfImpact>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateImpacts ${args} {
    impacts {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfImpact", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataImpactsResultType = {
    impacts: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataImpactsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataImpacts ${args} {
    impacts {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyImpactsResultType = {
    impacts: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyImpactsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfImpact>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfImpact = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyImpacts ${args} {
    impacts {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteImpactsResultType = {
    impacts: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteImpactsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteImpacts ${args} {
    impacts {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type OrderImpactsResultType = {
    impacts: {
        order?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type OrderImpactsBaseVariables = {
	fromId: string, /** L'identifiant à ordonner. */
	toId: Maybe<string>, /** L'identifiant de l'élément vers qui l'ordonner. */
}

/** Permet d'ordonner l'entité. */
@Injectable({providedIn: 'root'})
export class OrderImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fromId: Uuid!, $toId: Uuid = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation orderImpacts ${args} {
    impacts {
        order(fromId: $fromId, toId: $toId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: OrderImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<OrderImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddSecurityGroupImpactsResultType = {
    impacts: {
        addSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addSecurityGroupImpacts ${args} {
    impacts {
        addSecurityGroup(id: $id, securityGroupIds: $securityGroupIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveSecurityGroupImpactsResultType = {
    impacts: {
        removeSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	securityGroupIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $securityGroupIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeSecurityGroupImpacts ${args} {
    impacts {
        removeSecurityGroup(id: $id, securityGroupIds: $securityGroupIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddOperatorTeamSecurityGroupImpactsResultType = {
    impacts: {
        addOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddOperatorTeamSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddOperatorTeamSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addOperatorTeamSecurityGroupImpacts ${args} {
    impacts {
        addOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddOperatorTeamSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddOperatorTeamSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveOperatorTeamSecurityGroupImpactsResultType = {
    impacts: {
        removeOperatorTeamSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveOperatorTeamSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	operatorTeamIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveOperatorTeamSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $operatorTeamIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeOperatorTeamSecurityGroupImpacts ${args} {
    impacts {
        removeOperatorTeamSecurityGroup(id: $id, operatorTeamIds: $operatorTeamIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveOperatorTeamSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveOperatorTeamSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddPopulationSecurityGroupImpactsResultType = {
    impacts: {
        addPopulationSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddPopulationSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	populationIds: Array<string>, /**  */
	isNew: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddPopulationSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $populationIds: [Uuid!], $isNew: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addPopulationSecurityGroupImpacts ${args} {
    impacts {
        addPopulationSecurityGroup(id: $id, populationIds: $populationIds, isNew: $isNew) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddPopulationSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddPopulationSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemovePopulationSecurityGroupImpactsResultType = {
    impacts: {
        removePopulationSecurityGroup?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemovePopulationSecurityGroupImpactsBaseVariables = {
	id: string, /**  */
	populationIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemovePopulationSecurityGroupImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $populationIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removePopulationSecurityGroupImpacts ${args} {
    impacts {
        removePopulationSecurityGroup(id: $id, populationIds: $populationIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemovePopulationSecurityGroupImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemovePopulationSecurityGroupImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreImpactsResultType = {
    impacts: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreImpactsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreImpacts ${args} {
    impacts {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleImpactsResultType = {
    impacts: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleImpactsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleImpacts ${args} {
    impacts {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedImpactsResultType = {
    impacts: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedImpactsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedImpacts ${args} {
    impacts {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedImpactsResultType = {
    impacts: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedImpactsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedImpacts ${args} {
    impacts {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldImpactsResultType = {
    impacts: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldImpacts ${args} {
    impacts {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldImpactsResultType = {
    impacts: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldImpactsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldImpacts ${args} {
    impacts {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddIncidentModelsImpactsResultType = {
    impacts: {
        addIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddIncidentModelsImpactsBaseVariables = {
	id: string, /**  */
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddIncidentModelsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addIncidentModelsImpacts ${args} {
    impacts {
        addIncidentModels(id: $id, incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddIncidentModelsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddIncidentModelsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveIncidentModelsImpactsResultType = {
    impacts: {
        removeIncidentModels?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveIncidentModelsImpactsBaseVariables = {
	incidentModelIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveIncidentModelsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$incidentModelIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeIncidentModelsImpacts ${args} {
    impacts {
        removeIncidentModels(incidentModelIds: $incidentModelIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveIncidentModelsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveIncidentModelsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddTicketsImpactsResultType = {
    impacts: {
        addTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddTicketsImpactsBaseVariables = {
	id: string, /**  */
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddTicketsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addTicketsImpacts ${args} {
    impacts {
        addTickets(id: $id, ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddTicketsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddTicketsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveTicketsImpactsResultType = {
    impacts: {
        removeTickets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveTicketsImpactsBaseVariables = {
	ticketIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveTicketsImpactsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ticketIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeTicketsImpacts ${args} {
    impacts {
        removeTickets(ticketIds: $ticketIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveTicketsImpactsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveTicketsImpactsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
