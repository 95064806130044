import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChildren,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnInit,
  Output,
  QueryList,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { UserCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import { ModelFieldCompilerService } from '@clarilog/shared2/services';
import {
  CoreNavGroupComponent,
  SelectedEventArgs,
} from './nav-group/nav-group.component';
import {
  FilterArgs,
  CoreNavMenuItemComponent,
} from './nav-menu-item/nav-menu-item.component';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';

export type NavMenuSelectMode = 'none' | 'first';

export type NavMenuMode = 'compact' | 'normal';
/** Représente un composent NavMenuComponent. */
@Component({
  selector: 'clc-nav-menu',
  templateUrl: './nav-menu.component.html',
  styleUrls: ['./nav-menu.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  //changeDetection: ChangeDetectionStrategy.Default,
})
export class CoreNavMenuComponent
  implements AfterContentInit, OnInit, AfterViewInit
{
  private _handleOpen: NodeJS.Timeout;
  private _handleClose: NodeJS.Timeout;
  private _pin = false;
  /** Définit le chemin de la page en cour */
  private _path = '';
  /** Obtient ou définit l'état du pin. */
  @HostBinding('class.pin')
  @Input()
  get pin() {
    return this._pin;
  }
  set pin(value: boolean) {
    this._pin = value;
    this.onPinned.emit(value);
  }
  /** Obtient ou définit le mode. Bind le mode sur la classe css du composant.*/
  @HostBinding('class')
  @Input()
  mode: NavMenuMode = 'normal';
  /** Obtient ou définit les groups enfants. */
  @ContentChildren(CoreNavGroupComponent, { descendants: true })
  groups: QueryList<CoreNavGroupComponent>;

  /** Obtient ou définit les groups enfants. */
  @ContentChildren(CoreNavMenuItemComponent, { descendants: true })
  itemComponents: QueryList<CoreNavMenuItemComponent>;

  /** Ajoute la classe de style 'toggle' lorsque sont état est toggle. */
  @HostBinding('class.toggle')
  @Input()
  toggle = false;
  /** Obtient ou définit si le survole déclenche l'ouverture. */
  @HostBinding('class.hover')
  @Input()
  hover = false;
  /** Se produit lorsque la selection de l'item a changé. */
  @Output() onSelectionChanged: EventEmitter<
    SelectedEventArgs<CoreNavMenuComponent, CoreNavMenuItemComponent>
  > = new EventEmitter<
    SelectedEventArgs<CoreNavMenuComponent, CoreNavMenuItemComponent>
  >();
  /** Se produit lorsque un élément de filtre est cliqué */
  @Output() onItemSelectionChanged: EventEmitter<FilterArgs> =
    new EventEmitter<FilterArgs>();
  /** Se produit lors du clique sur le bouton Réduire/Ouvrir. */
  @Output() onToggle: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Se produit lors du clique sur le bouton épingler/dépingler. */
  @Output() onPinned: EventEmitter<boolean> = new EventEmitter<boolean>();
  /** Obtient ou définit si le bouton toggle est affiché. */
  @Input() showToggle = true;
  /** Obtient ou définit l'affichage du pin. */
  @Input() showPin = false;

  /** Obtient ou définit la fonction nombre d'élément à affiché. */
  @Input() counterSource;

  /** Représente une classe css d'initialisation. */
  @HostBinding('class.initialized') initialized = false;
  /** Instancie un nouvel objet de type @type {NavMenuComponent}. */
  hasFooter: boolean = false;

  private _gc: GC;

  timer: NodeJS.Timeout;

  constructor(
    private element: ElementRef,
    private router: Router,
    private route: ActivatedRoute,
    private _changeDetectorRef: ChangeDetectorRef,
    private userService: UserCoreService,
    public authService: AuthorizationCoreService,
    gcFactory: GCFactory,
    private localStorageService: LocalStorageService,
  ) {
    this._gc = gcFactory.create();
    let hasHelpMe = localStorage.getItem('viewHelpMe') == 'true' ? true : false;
    if (hasHelpMe === true) {
      //refresh counter auto
      if (authService.user.hasTimerHelpMe) {
        // Refrsh auto
        this.timer = setInterval(() => {
          this.refreshCounter();
        }, authService.user.getTimerHelpMe());
      }
    } else {
      // Si non helmp me ajout d'un timer auto de refresh
      let interval = 5 * 60 * 1000;
      this.timer = setInterval(() => {
        this.refreshCounter();
      }, interval);
    }

    if (this.localStorageService.ModelState != undefined) {
      this._gc.forDispose(
        this.localStorageService.ModelState.on.subscribe((f) => {
          if (
            f.eventName == LocalStorageService.EventConst.ReloadNavMenuConst
          ) {
            this.refreshCounter();
          }
        }),
      );
    }
  }

  /** Lorsque les items du treeview sont sélectionnés. */
  itemSelectionChanged(e: FilterArgs) {
    this.onItemSelectionChanged.emit(e);
  }
  /** Se déclenche au passage de la souris. */
  @HostListener('mouseenter')
  onMouseEnter() {
    if (this.hover) {
      clearTimeout(this._handleClose);
      this._handleOpen = setTimeout(() => {
        this.open();
      }, 300);
    }
  }
  /** Se déclenche lorsque la souris sort du composent. */
  @HostListener('mouseleave')
  onMouseLeave() {
    if (this.hover) {
      clearTimeout(this._handleOpen);
      this._handleClose = setTimeout(() => {
        this.close();
      }, 250);
    }
  }
  /** Se produit lors du clique sur le toggle. */
  toggleClick() {
    if (this.pin === false || this.showPin === false) {
      this.toggle = !this.toggle;
      this.onToggle.emit(this.toggle);
      this.updateScroll();
    }
  }
  /** ouvre le menu. */
  open() {
    if (this.pin === false || this.showPin === false) {
      this.toggle = false;
      this.onToggle.emit(this.toggle);
      this.updateScroll();
    }
  }
  /** Ferme le menu. */
  close() {
    if (this.pin === false || this.showPin === false) {
      this.toggle = true;
      this.onToggle.emit(this.toggle);
      this.updateScroll();
    }
  }
  /** Hack : Pour forcer Chrome à rafraîchir la scrollbars. */
  private updateScroll() {
    localStorage.setItem(`navmenu_${this._path}`, this.toggle.toString());

    const element = this.element.nativeElement.querySelector(
      '.clc-nav-menu-container',
    );
    const style = element.style;

    style.overflow = 'hidden';

    // Force l'application du css
    const h = element.clientHeight;

    if (this.toggle === true) {
      style.overflowY = 'auto';
    } else {
      style.overflow = 'auto';
    }
    if (this.mode === 'compact') {
      this.groups.forEach((group) => {
        group.mode = this.toggle === true ? 'compact' : 'normal';

        group.items.forEach((item) => {
          item.mode = this.toggle === true ? 'icon' : 'full';
          item.change();
        });
      });
    } else if (this.mode === 'normal') {
      this.groups.forEach((group) => {
        group.mode = this.toggle === true ? 'compact' : 'normal';

        group.items.forEach((item) => {
          item.mode = this.toggle === true ? 'icon' : 'full';
          item.change();
        });
      });
    }
  }
  /** Propage le changement de sélection. */
  selectionChanged(
    e: SelectedEventArgs<CoreNavGroupComponent, CoreNavMenuItemComponent>,
  ) {
    if (
      e == undefined ||
      e.event == undefined ||
      (e.event instanceof Event && e.event.defaultPrevented === false)
    ) {
      this.groups.forEach((group) => {
        group.items.forEach((item) => {
          if (item != e.selected) {
            item.active = false;
            this._changeDetectorRef.detectChanges();
          }
        });
      });
    }
    this.onSelectionChanged.emit({
      component: this,
      selected: e.selected,
      event: e.event,
    });
  }

  /** Force l'ouverture du menu */
  openMenu() {
    this.hover = true;
    clearTimeout(this._handleClose);
    this._handleOpen = setTimeout(() => {
      this.open();
      this.pin = true;
      localStorage.setItem('pinMenuState', '' + this.pin);
    }, 300);
  }

  /** @inheritdoc */
  ngOnInit(): void {
    if (this.pin === false && this.hover === true) {
      this.toggle = true;
      this._changeDetectorRef.detectChanges();
    }

    /** Menu principal avec le pin */
    if (this.showPin) {
      let stateMenu = localStorage.getItem('pinMenuState');
      if (stateMenu != undefined && stateMenu === 'true') {
        this.openMenu();
      } else {
        this.userService
          .pinMenuState(
            ModelFieldCompilerService.createServiceSingleResultScalar(),
          )
          .subscribe((s) => {
            // load
            if (s?.data != undefined && s.data == true) {
              this.openMenu();
            }
          });
      }
    }

    if (this.mode === 'normal') {
      this._path = this.router.url;
      const menuState = localStorage.getItem(`navmenu_${this._path}`);
      if (menuState != undefined) {
        this.toggle = menuState === 'true' ? true : false;
        this._changeDetectorRef.detectChanges();
      }
    }
  }

  /** Gestion des compteur sur les item du menu (par clé) */
  refreshCounter() {
    if (this.counterSource?.subscribe != undefined) {
      this.counterSource.subscribe((res) => {
        if (res != undefined && this.itemComponents != undefined) {
          this.itemComponents.forEach((f) => {
            if (
              f.countElementFn != undefined &&
              typeof f.countElementFn == 'string'
            ) {
              let item = res[f.countElementFn];
              if (item != undefined && item != '0') {
                f.countElement = item;
              } else {
                if (f.countElement != undefined && f.countElement > 0) {
                  f.countElement = undefined;
                }
              }
            }
          });
        }
      });
    }
  }

  /** @inheritdoc */
  ngAfterContentInit(): void {
    this.hasFooter = this.element.nativeElement.querySelector(
      'clc-nav-group[locate=footer]',
    );

    this.groups.changes.subscribe((groups) => {
      groups.forEach((group) => {
        group.mode = this.toggle === true ? 'compact' : 'normal';
        group.onItemSelectionChanged.subscribe((e) =>
          this.itemSelectionChanged(e),
        );
        group.onSelectionChanged.subscribe((e) => this.selectionChanged(e));

        group.items.forEach((item) => {
          item.onClick.subscribe((_) => {
            if (this.showPin == true) {
              this.close();
            }
          });
          item.onMouseEnter.subscribe((_) => {
            if (this.showPin == true) {
              this.open();
            }
          });
          item.mode = this.toggle === true ? 'icon' : 'full';
          item.change();
        });
      });
    });
  }
  /** @inheritdoc */
  ngAfterViewInit(): void {
    setTimeout(() => {
      this.initialized = true;
      this.refreshCounter();
      //this._changeDetectorRef.detectChanges();
    });
  }

  /** Gestin du pin */
  onPinClick() {
    this.pin = !this.pin;

    this.userService
      .setPinMenuState(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.pin,
      )
      .subscribe((s) => {
        // Save
        localStorage.setItem('pinMenuState', '' + this.pin);
      });
  }

  ngOnDestroy() {
    this._gc.dispose();
    if (this.timer != undefined) {
      clearTimeout(this.timer);
    }
  }
}
