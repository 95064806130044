import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfWorkflowNotificationTeams, FilterOfWorkflowNotificationTeams, QueryContextOfWorkflowNotificationTeams, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowNotificationTeams, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, WorkflowNotificationTeamsInput, FieldUpdateOperatorOfWorkflowNotificationTeams } from '../types'

export type GetWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        get?: Maybe<ServiceSingleResultOfWorkflowNotificationTeams>
    }
}

export type GetWorkflowNotificationTeamsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        first?: Maybe<ServiceSingleResultOfWorkflowNotificationTeams>
    }
}

export type FirstWorkflowNotificationTeamsBaseVariables = {
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWorkflowNotificationTeams>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfWorkflowNotificationTeams = null, $options: QueryContextOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountWorkflowNotificationTeamsBaseVariables = {
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        find?: Maybe<ServiceListResultOfWorkflowNotificationTeams>
    }
}

export type FindWorkflowNotificationTeamsBaseVariables = {
	options: Maybe<QueryContextOfWorkflowNotificationTeams>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfWorkflowNotificationTeams = null, $filter: FilterOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        search?: Maybe<ServiceListResultOfWorkflowNotificationTeams>
    }
}

export type SearchWorkflowNotificationTeamsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfWorkflowNotificationTeams>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaWorkflowNotificationTeamsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataWorkflowNotificationTeamsBaseVariables = {
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        customQuery?: Maybe<ServiceListResultOfWorkflowNotificationTeams>
    }
}

export type CustomQueryWorkflowNotificationTeamsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWorkflowNotificationTeams>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfWorkflowNotificationTeams = null, $options: QueryContextOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        customQueryId?: Maybe<ServiceListResultOfWorkflowNotificationTeams>
    }
}

export type CustomQueryIdWorkflowNotificationTeamsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfWorkflowNotificationTeams>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfWorkflowNotificationTeams>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfWorkflowNotificationTeams = null, $options: QueryContextOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedWorkflowNotificationTeamsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistWorkflowNotificationTeamsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type InsertWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        insert?: Maybe<ServiceSingleResultOfWorkflowNotificationTeams>
    }
}

export type InsertWorkflowNotificationTeamsBaseVariables = {
	entity: WorkflowNotificationTeamsInput, /** L'entité à ajouter. */
}

/** Permet d'ajouter une nouvelle entité. */
@Injectable({providedIn: 'root'})
export class InsertWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entity: WorkflowNotificationTeamsInput!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation insertWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        insert(entity: $entity) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: InsertWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<InsertWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        update?: Maybe<ServiceSingleResultOfWorkflowNotificationTeams>
    }
}

export type UpdateWorkflowNotificationTeamsBaseVariables = {
	id: string, /** L'identifiant de l'entité à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfWorkflowNotificationTeams>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $entry: FieldUpdateOperatorOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        update(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfWorkflowNotificationTeams", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportDataWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        importData?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportDataWorkflowNotificationTeamsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
	file: Maybe<string>, /** Représente le fichier d'importation encodé en base64. */
}

/** Permet d'importer des données via un fichier. */
@Injectable({providedIn: 'root'})
export class ImportDataWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!, $file: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importDataWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        importData(type: $type, file: $file) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportDataWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportDataWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyWorkflowNotificationTeamsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfWorkflowNotificationTeams>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfWorkflowNotificationTeams = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteWorkflowNotificationTeamsResultType = {
    workflowNotificationTeams: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteWorkflowNotificationTeamsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteWorkflowNotificationTeamsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteWorkflowNotificationTeams ${args} {
    workflowNotificationTeams {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteWorkflowNotificationTeamsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteWorkflowNotificationTeamsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
