import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { SoftwareConnectionModeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('software.manage-software-connection-mode')
export class SoftwareConnectionModeCoreService extends SoftwareConnectionModeBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
