<div>
  <dx-check-box
    [readOnly]="true"
    [value]="value.accept"
    [validationMessageMode]="'always'"
    (click)="click($event)"
  >
  </dx-check-box>
  <a [routerLink]="" (click)="click($event)" class="link">{{
    'globals/licenceCondition' | translate
  }}</a>
</div>
<dx-popup
  *ngIf="showPopup"
  [hideOnOutsideClick]="true"
  [title]="'entities/organization/licenseAgreement' | translate"
  [(visible)]="showPopup"
  class="license-popup"
  (onShowing)="showing($event)"
  (onShown)="shown($event)"
>
  <div class="container">
    <div class="container-license">
      <iframe
        class="frame-license"
        frameBorder="0"
        #iframe
        (scroll)="scroll($event)"
      ></iframe>
    </div>
    <div class="container-buttons">
      <cl-toolbar>
        <cl-toolbar-items>
          <cl-toolbar-item-button
            [location]="'after'"
            [text]="'globals/refuse' | translate"
            [disabled]="!licenseEnabled"
            (onClick)="reject($event)"
          ></cl-toolbar-item-button>
          <cl-toolbar-item-button
            [location]="'after'"
            [text]="'globals/accept' | translate"
            [disabled]="!licenseEnabled"
            (onClick)="accept($event)"
          ></cl-toolbar-item-button>
        </cl-toolbar-items>
      </cl-toolbar>
    </div>
  </div>
</dx-popup>
