import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareConnectionModesBaseVariables, FirstSoftwareConnectionModesBaseVariables, CountSoftwareConnectionModesBaseVariables, FindSoftwareConnectionModesBaseVariables, SearchSoftwareConnectionModesBaseVariables, ExportSchemaSoftwareConnectionModesBaseVariables, ExportDataSoftwareConnectionModesBaseVariables, CustomQuerySoftwareConnectionModesBaseVariables, CustomQueryIdSoftwareConnectionModesBaseVariables, UsedSoftwareConnectionModesBaseVariables, ExistSoftwareConnectionModesBaseVariables, FindUnassociatedSoftwareSoftwareConnectionModesBaseVariables, InsertSoftwareConnectionModesBaseVariables, UpdateSoftwareConnectionModesBaseVariables, ImportDataSoftwareConnectionModesBaseVariables, UpdateManySoftwareConnectionModesBaseVariables, DeleteSoftwareConnectionModesBaseVariables, AddSoftwareSoftwareConnectionModesBaseVariables, RemoveSoftwareSoftwareConnectionModesBaseVariables } from '../gqls'
import { GetSoftwareConnectionModesDocument, FirstSoftwareConnectionModesDocument, CountSoftwareConnectionModesDocument, FindSoftwareConnectionModesDocument, SearchSoftwareConnectionModesDocument, ExportSchemaSoftwareConnectionModesDocument, ExportDataSoftwareConnectionModesDocument, CustomQuerySoftwareConnectionModesDocument, CustomQueryIdSoftwareConnectionModesDocument, UsedSoftwareConnectionModesDocument, ExistSoftwareConnectionModesDocument, FindUnassociatedSoftwareSoftwareConnectionModesDocument, InsertSoftwareConnectionModesDocument, UpdateSoftwareConnectionModesDocument, ImportDataSoftwareConnectionModesDocument, UpdateManySoftwareConnectionModesDocument, DeleteSoftwareConnectionModesDocument, AddSoftwareSoftwareConnectionModesDocument, RemoveSoftwareSoftwareConnectionModesDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareConnectionMode, QueryContextOfSoftwareConnectionMode, FilterOfSoftwareConnectionMode, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareConnectionMode, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfSoftware, FilterOfSoftware, ServiceListResultOfSoftware, SoftwareConnectionModeInput, FieldUpdateOperatorOfSoftwareConnectionMode } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareConnectionModeBaseService {
    
public modelName = 'softwareConnectionMode';
public modelPluralName = 'softwareConnectionModes';

	private getSoftwareConnectionModesQuery: GetSoftwareConnectionModesDocument;
	private firstSoftwareConnectionModesQuery: FirstSoftwareConnectionModesDocument;
	private countSoftwareConnectionModesQuery: CountSoftwareConnectionModesDocument;
	private findSoftwareConnectionModesQuery: FindSoftwareConnectionModesDocument;
	private searchSoftwareConnectionModesQuery: SearchSoftwareConnectionModesDocument;
	private exportSchemaSoftwareConnectionModesQuery: ExportSchemaSoftwareConnectionModesDocument;
	private exportDataSoftwareConnectionModesQuery: ExportDataSoftwareConnectionModesDocument;
	private customQuerySoftwareConnectionModesQuery: CustomQuerySoftwareConnectionModesDocument;
	private customQueryIdSoftwareConnectionModesQuery: CustomQueryIdSoftwareConnectionModesDocument;
	private usedSoftwareConnectionModesQuery: UsedSoftwareConnectionModesDocument;
	private existSoftwareConnectionModesQuery: ExistSoftwareConnectionModesDocument;
	private findUnassociatedSoftwareSoftwareConnectionModesQuery: FindUnassociatedSoftwareSoftwareConnectionModesDocument;
	private insertSoftwareConnectionModesMutation: InsertSoftwareConnectionModesDocument;
	private updateSoftwareConnectionModesMutation: UpdateSoftwareConnectionModesDocument;
	private importDataSoftwareConnectionModesMutation: ImportDataSoftwareConnectionModesDocument;
	private updateManySoftwareConnectionModesMutation: UpdateManySoftwareConnectionModesDocument;
	private deleteSoftwareConnectionModesMutation: DeleteSoftwareConnectionModesDocument;
	private addSoftwareSoftwareConnectionModesMutation: AddSoftwareSoftwareConnectionModesDocument;
	private removeSoftwareSoftwareConnectionModesMutation: RemoveSoftwareSoftwareConnectionModesDocument;

	constructor(private injector: Injector) {
		this.getSoftwareConnectionModesQuery = this.injector.get(GetSoftwareConnectionModesDocument);
		this.firstSoftwareConnectionModesQuery = this.injector.get(FirstSoftwareConnectionModesDocument);
		this.countSoftwareConnectionModesQuery = this.injector.get(CountSoftwareConnectionModesDocument);
		this.findSoftwareConnectionModesQuery = this.injector.get(FindSoftwareConnectionModesDocument);
		this.searchSoftwareConnectionModesQuery = this.injector.get(SearchSoftwareConnectionModesDocument);
		this.exportSchemaSoftwareConnectionModesQuery = this.injector.get(ExportSchemaSoftwareConnectionModesDocument);
		this.exportDataSoftwareConnectionModesQuery = this.injector.get(ExportDataSoftwareConnectionModesDocument);
		this.customQuerySoftwareConnectionModesQuery = this.injector.get(CustomQuerySoftwareConnectionModesDocument);
		this.customQueryIdSoftwareConnectionModesQuery = this.injector.get(CustomQueryIdSoftwareConnectionModesDocument);
		this.usedSoftwareConnectionModesQuery = this.injector.get(UsedSoftwareConnectionModesDocument);
		this.existSoftwareConnectionModesQuery = this.injector.get(ExistSoftwareConnectionModesDocument);
		this.findUnassociatedSoftwareSoftwareConnectionModesQuery = this.injector.get(FindUnassociatedSoftwareSoftwareConnectionModesDocument);
		this.insertSoftwareConnectionModesMutation = this.injector.get(InsertSoftwareConnectionModesDocument);
		this.updateSoftwareConnectionModesMutation = this.injector.get(UpdateSoftwareConnectionModesDocument);
		this.importDataSoftwareConnectionModesMutation = this.injector.get(ImportDataSoftwareConnectionModesDocument);
		this.updateManySoftwareConnectionModesMutation = this.injector.get(UpdateManySoftwareConnectionModesDocument);
		this.deleteSoftwareConnectionModesMutation = this.injector.get(DeleteSoftwareConnectionModesDocument);
		this.addSoftwareSoftwareConnectionModesMutation = this.injector.get(AddSoftwareSoftwareConnectionModesDocument);
		this.removeSoftwareSoftwareConnectionModesMutation = this.injector.get(RemoveSoftwareSoftwareConnectionModesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareConnectionMode> {
    
    		let variables: GetSoftwareConnectionModesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareConnectionMode={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareConnectionMode,
		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareConnectionMode> {
    
    		let variables: FirstSoftwareConnectionModesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareConnectionModesBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareConnectionMode,
		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareConnectionMode> {
    
    		let variables: FindSoftwareConnectionModesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareConnectionMode,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareConnectionMode> {
    
    		let variables: SearchSoftwareConnectionModesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareConnectionModesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareConnectionModesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareConnectionMode,
		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareConnectionMode> {
    
    		let variables: CustomQuerySoftwareConnectionModesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareConnectionMode,
		@Args('filter?') filter?: FilterOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareConnectionMode> {
    
    		let variables: CustomQueryIdSoftwareConnectionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareConnectionModesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareConnectionModesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.exist));
    	}

	@InjectArgs
	public findAssociatedSoftware(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSoftware,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftware,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSoftware> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('software', fields, null, [
			GqlSubFieldArg.create('filterOfSoftware', 'filter'),
			GqlSubFieldArg.create('optionsOfSoftware', 'options'),
		]),
		])
		extendedVariables['filterOfSoftware'] = filter;
		extendedVariables['optionsOfSoftware'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.software));
            }
            else{
                let result: ServiceListResultOfSoftware = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftware,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftware,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftware> {
    
    		let variables: FindUnassociatedSoftwareSoftwareConnectionModesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSoftwareSoftwareConnectionModesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.findUnassociatedSoftware));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareConnectionModeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareConnectionMode> {
    
    		let variables: InsertSoftwareConnectionModesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareConnectionMode> {
    
    		let variables: UpdateSoftwareConnectionModesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareConnectionModesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareConnectionMode,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareConnectionModesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareConnectionModesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSoftwareSoftwareConnectionModesBaseVariables = {
    			id: id,
			softwareIds: softwareIds
    		}
    				return this.addSoftwareSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.addSoftware));
    	}

    	/**  */
    	@InjectArgs
    	public removeSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSoftwareSoftwareConnectionModesBaseVariables = {
    			softwareIds: softwareIds
    		}
    				return this.removeSoftwareSoftwareConnectionModesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareConnectionModes.removeSoftware));
    	}
    
}