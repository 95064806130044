<dx-popup
  [width]="800"
  [height]="'80%'"
  [showTitle]="false"
  class="add-item-popup"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="isVisible"
>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.1)"
    [visible]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  ></dx-load-panel>
  <div style="height: 100%">
    <cl-work-page>
      <cl-work-page-header>
        <cl-work-page-header-content>
          <cl-work-page-header-content-title
            >{{
              control.label != undefined
                ? ('addMassiveLinkElementsWith'
                  | translate: { elementName: control.label })
                : title
            }}
          </cl-work-page-header-content-title>
          <cl-work-page-header-content-body>
            <cl-toolbar>
              <cl-toolbar-items>
                <cl-toolbar-item-button
                  (onClick)="add($event)"
                  [text]="'addLink' | translate"
                  location="after"
                  [disabled]="items.length === 0"
                >
                </cl-toolbar-item-button>
                <cl-toolbar-item-button
                  (onClick)="remove($event)"
                  [text]="'removeLink' | translate"
                  location="after"
                  [disabled]="items.length === 0"
                >
                </cl-toolbar-item-button>
              </cl-toolbar-items>
            </cl-toolbar>
          </cl-work-page-header-content-body>
        </cl-work-page-header-content>
      </cl-work-page-header>
      <cl-work-page-content>
        <div class="link-list-component">
          <cl-toolbar class="action">
            <cl-toolbar-items>
              <cl-toolbar-item-button
                [text]="'add' | translate"
                icon="far fa-link"
                (onClick)="isVisibleSelectPopup = true"
              ></cl-toolbar-item-button>
              <cl-toolbar-item-button
                [text]="'remove' | translate"
                icon="far fa-unlink"
                [disabled]="selectedIds.length === 0"
                (onClick)="removeLink($event)"
              >
              </cl-toolbar-item-button>
              <cl-toolbar-item-button
                [text]="'manage' | translate"
                [visible]="control.options.route !== undefined"
                (onClick)="onGoTo()"
                icon="fas fa-arrow-right"
              ></cl-toolbar-item-button>
            </cl-toolbar-items>
          </cl-toolbar>
          <clc-list
            class="list"
            [isLiveUpdated]="'false'"
            style="height: 100%"
            [isLiveUpdated]="'false'"
            [source]="dataSource"
            [multiple]="'true'"
            [type]="control.options.type == undefined ? 'Grid' : control.type"
            [columns]="control.options.columns"
            [multiple]="control.options.multiple"
            [(selectedKeys)]="selectedIds"
            [(selectedData)]="selectedData"
          >
          </clc-list>
        </div>
        <dx-popup
          [width]="800"
          [height]="'80%'"
          [showTitle]="false"
          [dragEnabled]="true"
          class="add-item-popup"
          #popup
          [resizeEnabled]="true"
          [hideOnOutsideClick]="true"
          [deferRendering]="true"
          [(visible)]="isVisibleSelectPopup"
          (onShowing)="show($event)"
          (onHidden)="hidden($event)"
        >
          <clc-select-list
            #selectList
            [label]="control.label"
            [source]="control.options.selectSource"
            [columns]="control.options.columns"
            (onClosed)="isVisibleSelectPopup = false"
            (onSelect)="select($event)"
          >
          </clc-select-list>
        </dx-popup>
      </cl-work-page-content>
    </cl-work-page>
  </div>
  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="close()"></dx-button>
  </div>
</dx-popup>
