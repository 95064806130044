import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetResolutionModelsBaseVariables, FirstResolutionModelsBaseVariables, CountResolutionModelsBaseVariables, FindResolutionModelsBaseVariables, SearchResolutionModelsBaseVariables, ExportSchemaResolutionModelsBaseVariables, ExportDataResolutionModelsBaseVariables, CustomQueryResolutionModelsBaseVariables, CustomQueryIdResolutionModelsBaseVariables, FindUnassociatedSecurityGroupsResolutionModelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsResolutionModelsBaseVariables, FindFilesResolutionModelsBaseVariables, FindAttachmentFilesResolutionModelsBaseVariables, FindHelpDeskUserAttachmentFilesResolutionModelsBaseVariables, UsedResolutionModelsBaseVariables, FindByTeamOperatorLinkedResolutionModelsBaseVariables, ExistResolutionModelsBaseVariables, FindUnassociatedResolutionCategoriesResolutionModelsBaseVariables, InsertResolutionModelsBaseVariables, UpdateResolutionModelsBaseVariables, ImportDataResolutionModelsBaseVariables, UpdateManyResolutionModelsBaseVariables, DeleteResolutionModelsBaseVariables, AddSecurityGroupResolutionModelsBaseVariables, RemoveSecurityGroupResolutionModelsBaseVariables, AddOperatorTeamSecurityGroupResolutionModelsBaseVariables, RemoveOperatorTeamSecurityGroupResolutionModelsBaseVariables, AddFilesResolutionModelsBaseVariables, RemoveFilesResolutionModelsBaseVariables, UploadChunkFileResolutionModelsBaseVariables, AddResolutionCategoriesResolutionModelsBaseVariables, RemoveResolutionCategoriesResolutionModelsBaseVariables } from '../gqls'
import { GetResolutionModelsDocument, FirstResolutionModelsDocument, CountResolutionModelsDocument, FindResolutionModelsDocument, SearchResolutionModelsDocument, ExportSchemaResolutionModelsDocument, ExportDataResolutionModelsDocument, CustomQueryResolutionModelsDocument, CustomQueryIdResolutionModelsDocument, FindUnassociatedSecurityGroupsResolutionModelsDocument, FindAssociatedOperatorTeamSecurityGroupsResolutionModelsDocument, FindFilesResolutionModelsDocument, FindAttachmentFilesResolutionModelsDocument, FindHelpDeskUserAttachmentFilesResolutionModelsDocument, UsedResolutionModelsDocument, FindByTeamOperatorLinkedResolutionModelsDocument, ExistResolutionModelsDocument, FindUnassociatedResolutionCategoriesResolutionModelsDocument, InsertResolutionModelsDocument, UpdateResolutionModelsDocument, ImportDataResolutionModelsDocument, UpdateManyResolutionModelsDocument, DeleteResolutionModelsDocument, AddSecurityGroupResolutionModelsDocument, RemoveSecurityGroupResolutionModelsDocument, AddOperatorTeamSecurityGroupResolutionModelsDocument, RemoveOperatorTeamSecurityGroupResolutionModelsDocument, AddFilesResolutionModelsDocument, RemoveFilesResolutionModelsDocument, UploadChunkFileResolutionModelsDocument, AddResolutionCategoriesResolutionModelsDocument, RemoveResolutionCategoriesResolutionModelsDocument } from '../gqls'
import { ServiceSingleResultOfResolutionModel, QueryContextOfResolutionModel, FilterOfResolutionModel, ServiceSingleResultOfInt64, ServiceListResultOfResolutionModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfFileModel, QueryContextOfFileModel, FilterOfFileModel, ServiceSingleResultOfBoolean, QueryContextOfResolutionCategory, FilterOfResolutionCategory, ServiceListResultOfResolutionCategory, ResolutionModelInput, FieldUpdateOperatorOfResolutionModel, ServiceSingleResultOfGuid } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ResolutionModelBaseService {
    
public modelName = 'resolutionModel';
public modelPluralName = 'resolutionModels';

	private getResolutionModelsQuery: GetResolutionModelsDocument;
	private firstResolutionModelsQuery: FirstResolutionModelsDocument;
	private countResolutionModelsQuery: CountResolutionModelsDocument;
	private findResolutionModelsQuery: FindResolutionModelsDocument;
	private searchResolutionModelsQuery: SearchResolutionModelsDocument;
	private exportSchemaResolutionModelsQuery: ExportSchemaResolutionModelsDocument;
	private exportDataResolutionModelsQuery: ExportDataResolutionModelsDocument;
	private customQueryResolutionModelsQuery: CustomQueryResolutionModelsDocument;
	private customQueryIdResolutionModelsQuery: CustomQueryIdResolutionModelsDocument;
	private findUnassociatedSecurityGroupsResolutionModelsQuery: FindUnassociatedSecurityGroupsResolutionModelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsResolutionModelsQuery: FindAssociatedOperatorTeamSecurityGroupsResolutionModelsDocument;
	private findFilesResolutionModelsQuery: FindFilesResolutionModelsDocument;
	private findAttachmentFilesResolutionModelsQuery: FindAttachmentFilesResolutionModelsDocument;
	private findHelpDeskUserAttachmentFilesResolutionModelsQuery: FindHelpDeskUserAttachmentFilesResolutionModelsDocument;
	private usedResolutionModelsQuery: UsedResolutionModelsDocument;
	private findByTeamOperatorLinkedResolutionModelsQuery: FindByTeamOperatorLinkedResolutionModelsDocument;
	private existResolutionModelsQuery: ExistResolutionModelsDocument;
	private findUnassociatedResolutionCategoriesResolutionModelsQuery: FindUnassociatedResolutionCategoriesResolutionModelsDocument;
	private insertResolutionModelsMutation: InsertResolutionModelsDocument;
	private updateResolutionModelsMutation: UpdateResolutionModelsDocument;
	private importDataResolutionModelsMutation: ImportDataResolutionModelsDocument;
	private updateManyResolutionModelsMutation: UpdateManyResolutionModelsDocument;
	private deleteResolutionModelsMutation: DeleteResolutionModelsDocument;
	private addSecurityGroupResolutionModelsMutation: AddSecurityGroupResolutionModelsDocument;
	private removeSecurityGroupResolutionModelsMutation: RemoveSecurityGroupResolutionModelsDocument;
	private addOperatorTeamSecurityGroupResolutionModelsMutation: AddOperatorTeamSecurityGroupResolutionModelsDocument;
	private removeOperatorTeamSecurityGroupResolutionModelsMutation: RemoveOperatorTeamSecurityGroupResolutionModelsDocument;
	private addFilesResolutionModelsMutation: AddFilesResolutionModelsDocument;
	private removeFilesResolutionModelsMutation: RemoveFilesResolutionModelsDocument;
	private uploadChunkFileResolutionModelsMutation: UploadChunkFileResolutionModelsDocument;
	private addResolutionCategoriesResolutionModelsMutation: AddResolutionCategoriesResolutionModelsDocument;
	private removeResolutionCategoriesResolutionModelsMutation: RemoveResolutionCategoriesResolutionModelsDocument;

	constructor(private injector: Injector) {
		this.getResolutionModelsQuery = this.injector.get(GetResolutionModelsDocument);
		this.firstResolutionModelsQuery = this.injector.get(FirstResolutionModelsDocument);
		this.countResolutionModelsQuery = this.injector.get(CountResolutionModelsDocument);
		this.findResolutionModelsQuery = this.injector.get(FindResolutionModelsDocument);
		this.searchResolutionModelsQuery = this.injector.get(SearchResolutionModelsDocument);
		this.exportSchemaResolutionModelsQuery = this.injector.get(ExportSchemaResolutionModelsDocument);
		this.exportDataResolutionModelsQuery = this.injector.get(ExportDataResolutionModelsDocument);
		this.customQueryResolutionModelsQuery = this.injector.get(CustomQueryResolutionModelsDocument);
		this.customQueryIdResolutionModelsQuery = this.injector.get(CustomQueryIdResolutionModelsDocument);
		this.findUnassociatedSecurityGroupsResolutionModelsQuery = this.injector.get(FindUnassociatedSecurityGroupsResolutionModelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsResolutionModelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsResolutionModelsDocument);
		this.findFilesResolutionModelsQuery = this.injector.get(FindFilesResolutionModelsDocument);
		this.findAttachmentFilesResolutionModelsQuery = this.injector.get(FindAttachmentFilesResolutionModelsDocument);
		this.findHelpDeskUserAttachmentFilesResolutionModelsQuery = this.injector.get(FindHelpDeskUserAttachmentFilesResolutionModelsDocument);
		this.usedResolutionModelsQuery = this.injector.get(UsedResolutionModelsDocument);
		this.findByTeamOperatorLinkedResolutionModelsQuery = this.injector.get(FindByTeamOperatorLinkedResolutionModelsDocument);
		this.existResolutionModelsQuery = this.injector.get(ExistResolutionModelsDocument);
		this.findUnassociatedResolutionCategoriesResolutionModelsQuery = this.injector.get(FindUnassociatedResolutionCategoriesResolutionModelsDocument);
		this.insertResolutionModelsMutation = this.injector.get(InsertResolutionModelsDocument);
		this.updateResolutionModelsMutation = this.injector.get(UpdateResolutionModelsDocument);
		this.importDataResolutionModelsMutation = this.injector.get(ImportDataResolutionModelsDocument);
		this.updateManyResolutionModelsMutation = this.injector.get(UpdateManyResolutionModelsDocument);
		this.deleteResolutionModelsMutation = this.injector.get(DeleteResolutionModelsDocument);
		this.addSecurityGroupResolutionModelsMutation = this.injector.get(AddSecurityGroupResolutionModelsDocument);
		this.removeSecurityGroupResolutionModelsMutation = this.injector.get(RemoveSecurityGroupResolutionModelsDocument);
		this.addOperatorTeamSecurityGroupResolutionModelsMutation = this.injector.get(AddOperatorTeamSecurityGroupResolutionModelsDocument);
		this.removeOperatorTeamSecurityGroupResolutionModelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupResolutionModelsDocument);
		this.addFilesResolutionModelsMutation = this.injector.get(AddFilesResolutionModelsDocument);
		this.removeFilesResolutionModelsMutation = this.injector.get(RemoveFilesResolutionModelsDocument);
		this.uploadChunkFileResolutionModelsMutation = this.injector.get(UploadChunkFileResolutionModelsDocument);
		this.addResolutionCategoriesResolutionModelsMutation = this.injector.get(AddResolutionCategoriesResolutionModelsDocument);
		this.removeResolutionCategoriesResolutionModelsMutation = this.injector.get(RemoveResolutionCategoriesResolutionModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResolutionModel> {
    
    		let variables: GetResolutionModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.get));
            }
            else{
                let result:ServiceSingleResultOfResolutionModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResolutionModel> {
    
    		let variables: FirstResolutionModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountResolutionModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionModel> {
    
    		let variables: FindResolutionModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionModel> {
    
    		let variables: SearchResolutionModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaResolutionModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataResolutionModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionModel> {
    
    		let variables: CustomQueryResolutionModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionModel> {
    
    		let variables: CustomQueryIdResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les fichiers liés. */
    	@InjectArgs
    	public findFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindFilesResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findFilesResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findFiles));
    	}

    	/** Récupère les fichiers attachés liés. */
    	@InjectArgs
    	public findAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindAttachmentFilesResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAttachmentFilesResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findAttachmentFiles));
    	}

    	/** Récupère les fichiers attachés liés visible pour les users help desk. */
    	@InjectArgs
    	public findHelpDeskUserAttachmentFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfFileModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfFileModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfFileModel> {
    
    		let variables: FindHelpDeskUserAttachmentFilesResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findHelpDeskUserAttachmentFilesResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findHelpDeskUserAttachmentFiles));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedResolutionModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.used));
    	}

    	/** Permet de recuperer les modèles d'intervention associé a certaines équipes opérateurs. */
    	@InjectArgs
    	public findByTeamOperatorLinked(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketCategoriesIds?') ticketCategoriesIds?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfResolutionModel,
		@Args('filter?') filter?: FilterOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionModel> {
    
    		let variables: FindByTeamOperatorLinkedResolutionModelsBaseVariables = {
    			ticketCategoriesIds: ticketCategoriesIds,
			filter: filter,
			options: options
    		}
    				return this.findByTeamOperatorLinkedResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findByTeamOperatorLinked));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistResolutionModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.exist));
    	}

	@InjectArgs
	public findAssociatedResolutionCategories(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfResolutionCategory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfResolutionCategory,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfResolutionCategory> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('resolutionCategories', fields, null, [
			GqlSubFieldArg.create('filterOfResolutionCategories', 'filter'),
			GqlSubFieldArg.create('optionsOfResolutionCategories', 'options'),
		]),
		])
		extendedVariables['filterOfResolutionCategories'] = filter;
		extendedVariables['optionsOfResolutionCategories'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.resolutionCategories));
            }
            else{
                let result: ServiceListResultOfResolutionCategory = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedResolutionCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfResolutionCategory,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfResolutionCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfResolutionCategory> {
    
    		let variables: FindUnassociatedResolutionCategoriesResolutionModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedResolutionCategoriesResolutionModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.findUnassociatedResolutionCategories));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ResolutionModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResolutionModel> {
    
    		let variables: InsertResolutionModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfResolutionModel> {
    
    		let variables: UpdateResolutionModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataResolutionModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfResolutionModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyResolutionModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteResolutionModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupResolutionModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupResolutionModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupResolutionModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupResolutionModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFilesResolutionModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.addFilesResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.addFiles));
    	}

    	/**  */
    	@InjectArgs
    	public removeFiles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('fileIds') fileIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFilesResolutionModelsBaseVariables = {
    			id: id,
			fileIds: fileIds
    		}
    				return this.removeFilesResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.removeFiles));
    	}

    	/**  */
    	@InjectArgs
    	public uploadChunkFile(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('size') size: number,
		@Args('lastChunk') lastChunk: boolean,
		@Args('indexChunk') indexChunk: number,
		@Args('type?') type?: string,
		@Args('name?') name?: string,
		@Args('fileId?') fileId?: string,
		@Args('entityId?') entityId?: string,
		@Args('chunks?') chunks?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfGuid> {
    
    		let variables: UploadChunkFileResolutionModelsBaseVariables = {
    			name: name,
			size: size,
			type: type,
			chunks: chunks,
			indexChunk: indexChunk,
			lastChunk: lastChunk,
			fileId: fileId,
			entityId: entityId
    		}
    				return this.uploadChunkFileResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.uploadChunkFile));
    	}

    	/**  */
    	@InjectArgs
    	public addResolutionCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('resolutionCategoryIds') resolutionCategoryIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddResolutionCategoriesResolutionModelsBaseVariables = {
    			id: id,
			resolutionCategoryIds: resolutionCategoryIds
    		}
    				return this.addResolutionCategoriesResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.addResolutionCategories));
    	}

    	/**  */
    	@InjectArgs
    	public removeResolutionCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('resolutionCategoryIds') resolutionCategoryIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveResolutionCategoriesResolutionModelsBaseVariables = {
    			id: id,
			resolutionCategoryIds: resolutionCategoryIds
    		}
    				return this.removeResolutionCategoriesResolutionModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.resolutionModels.removeResolutionCategories));
    	}
    
}