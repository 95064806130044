import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetLinkAssetsBaseVariables, FirstLinkAssetsBaseVariables, CountLinkAssetsBaseVariables, FindLinkAssetsBaseVariables, SearchLinkAssetsBaseVariables, ExportSchemaLinkAssetsBaseVariables, ExportDataLinkAssetsBaseVariables, CustomQueryLinkAssetsBaseVariables, CustomQueryIdLinkAssetsBaseVariables, UsedLinkAssetsBaseVariables, ExistLinkAssetsBaseVariables, InsertLinkAssetsBaseVariables, UpdateLinkAssetsBaseVariables, ImportDataLinkAssetsBaseVariables, UpdateManyLinkAssetsBaseVariables, DeleteLinkAssetsBaseVariables } from '../gqls'
import { GetLinkAssetsDocument, FirstLinkAssetsDocument, CountLinkAssetsDocument, FindLinkAssetsDocument, SearchLinkAssetsDocument, ExportSchemaLinkAssetsDocument, ExportDataLinkAssetsDocument, CustomQueryLinkAssetsDocument, CustomQueryIdLinkAssetsDocument, UsedLinkAssetsDocument, ExistLinkAssetsDocument, InsertLinkAssetsDocument, UpdateLinkAssetsDocument, ImportDataLinkAssetsDocument, UpdateManyLinkAssetsDocument, DeleteLinkAssetsDocument } from '../gqls'
import { ServiceSingleResultOfLinkAsset, QueryContextOfLinkAsset, FilterOfLinkAsset, ServiceSingleResultOfInt64, ServiceListResultOfLinkAsset, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, LinkAssetInput, FieldUpdateOperatorOfLinkAsset } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class LinkAssetBaseService {
    
public modelName = 'linkAsset';
public modelPluralName = 'linkAssets';

	private getLinkAssetsQuery: GetLinkAssetsDocument;
	private firstLinkAssetsQuery: FirstLinkAssetsDocument;
	private countLinkAssetsQuery: CountLinkAssetsDocument;
	private findLinkAssetsQuery: FindLinkAssetsDocument;
	private searchLinkAssetsQuery: SearchLinkAssetsDocument;
	private exportSchemaLinkAssetsQuery: ExportSchemaLinkAssetsDocument;
	private exportDataLinkAssetsQuery: ExportDataLinkAssetsDocument;
	private customQueryLinkAssetsQuery: CustomQueryLinkAssetsDocument;
	private customQueryIdLinkAssetsQuery: CustomQueryIdLinkAssetsDocument;
	private usedLinkAssetsQuery: UsedLinkAssetsDocument;
	private existLinkAssetsQuery: ExistLinkAssetsDocument;
	private insertLinkAssetsMutation: InsertLinkAssetsDocument;
	private updateLinkAssetsMutation: UpdateLinkAssetsDocument;
	private importDataLinkAssetsMutation: ImportDataLinkAssetsDocument;
	private updateManyLinkAssetsMutation: UpdateManyLinkAssetsDocument;
	private deleteLinkAssetsMutation: DeleteLinkAssetsDocument;

	constructor(private injector: Injector) {
		this.getLinkAssetsQuery = this.injector.get(GetLinkAssetsDocument);
		this.firstLinkAssetsQuery = this.injector.get(FirstLinkAssetsDocument);
		this.countLinkAssetsQuery = this.injector.get(CountLinkAssetsDocument);
		this.findLinkAssetsQuery = this.injector.get(FindLinkAssetsDocument);
		this.searchLinkAssetsQuery = this.injector.get(SearchLinkAssetsDocument);
		this.exportSchemaLinkAssetsQuery = this.injector.get(ExportSchemaLinkAssetsDocument);
		this.exportDataLinkAssetsQuery = this.injector.get(ExportDataLinkAssetsDocument);
		this.customQueryLinkAssetsQuery = this.injector.get(CustomQueryLinkAssetsDocument);
		this.customQueryIdLinkAssetsQuery = this.injector.get(CustomQueryIdLinkAssetsDocument);
		this.usedLinkAssetsQuery = this.injector.get(UsedLinkAssetsDocument);
		this.existLinkAssetsQuery = this.injector.get(ExistLinkAssetsDocument);
		this.insertLinkAssetsMutation = this.injector.get(InsertLinkAssetsDocument);
		this.updateLinkAssetsMutation = this.injector.get(UpdateLinkAssetsDocument);
		this.importDataLinkAssetsMutation = this.injector.get(ImportDataLinkAssetsDocument);
		this.updateManyLinkAssetsMutation = this.injector.get(UpdateManyLinkAssetsDocument);
		this.deleteLinkAssetsMutation = this.injector.get(DeleteLinkAssetsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLinkAsset> {
    
    		let variables: GetLinkAssetsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.get));
            }
            else{
                let result:ServiceSingleResultOfLinkAsset={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLinkAsset,
		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLinkAsset> {
    
    		let variables: FirstLinkAssetsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountLinkAssetsBaseVariables = {
    			filter: filter
    		}
    				return this.countLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfLinkAsset,
		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLinkAsset> {
    
    		let variables: FindLinkAssetsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfLinkAsset,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLinkAsset> {
    
    		let variables: SearchLinkAssetsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaLinkAssetsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataLinkAssetsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfLinkAsset,
		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLinkAsset> {
    
    		let variables: CustomQueryLinkAssetsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfLinkAsset,
		@Args('filter?') filter?: FilterOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfLinkAsset> {
    
    		let variables: CustomQueryIdLinkAssetsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedLinkAssetsBaseVariables = {
    			ids: ids
    		}
    				return this.usedLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistLinkAssetsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existLinkAssetsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: LinkAssetInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLinkAsset> {
    
    		let variables: InsertLinkAssetsBaseVariables = {
    			entity: entity
    		}
    				return this.insertLinkAssetsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfLinkAsset> {
    
    		let variables: UpdateLinkAssetsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateLinkAssetsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataLinkAssetsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataLinkAssetsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfLinkAsset,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyLinkAssetsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyLinkAssetsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteLinkAssetsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteLinkAssetsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.linkAssets.delete));
    	}
    
}