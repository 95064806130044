import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { Column, MasterDetail } from '@clarilog/shared2/models/schema';
import DataSource from 'devextreme/data/data_source';
import { TemplatesService } from '../../templates/templates.service';
import {
  ModelDataSourceContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { alert } from 'devextreme/ui/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { CoreWorkSubFormComponent } from '../../form/work-sub-form';
import { CoreListComponent } from '../list';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { CoreSubFormLinkListComponent } from '../sub-form-link-list/sub-form-link-list.component';
import emailModel from './email.model.json';
import { EmailConnectorCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-connector.service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { UntypedFormGroup } from '@angular/forms';
import { TicketEmailCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket-email.service';
import Globalize from 'globalize/dist/globalize';
import { EmailPrototypeCoreService } from '@clarilog/core/services2/graphql/generated-types/services/email-prototype.service';
import pluralize from 'pluralize';
import { TranslatedFieldHelperService } from '../../translate-field/translate-field-helper-service';
import {
  NotificationCoreService,
  TicketCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { ModelFieldCompilerService } from '@clarilog/shared2';
import notify from 'devextreme/ui/notify';
import {
  EmailPrototype,
  NotificationKey,
} from '@clarilog/core/services2/graphql/generated-types/types';

/** Représente le composent des emails des ticket.*/
@Component({
  selector: 'clc-ticket-email-list',
  templateUrl: './ticket-email-list.component.html',
  styleUrls: ['./ticket-email-list.component.scss'],
})
export class TicketEmailListComponent implements OnInit {
  @Input() source: ModelDataSourceContext;
  @Input() currentSource: ModelDataSourceContext;
  @Input() fieldName: string;
  @Input() masterDetail: MasterDetail;
  @Input() readOnly: boolean = false;
  @Input() columns: Column[] = [];
  @Input() selectedKeys: string[] = [];
  @Input() selectedData: any[] = [];
  @Input() key: string;
  @Input() serviceRetreiver: ModelFnContext;
  @Input() state: ModelState;
  @Input() subFormModel: any;
  @ViewChild(CoreListComponent) list: CoreListComponent;
  @ViewChild(CoreWorkSubFormComponent) subForm: CoreWorkSubFormComponent;
  _initializeState: boolean = false;
  service: any;
  /** Indique si on attend l'ouverture du popup */
  waitSave: boolean = false;
  isPopupOpen: boolean = false;
  emailInputConnectors: any;
  ticketId: any;
  gc: GC;
  iconSendBack = 'fas fa-paper-plane';
  waitBtnSendBack: boolean = false;

  /**Permet de désactiver l'envoi de mail pour les tickets clos  */
  disabledEmailfunction: boolean = false;

  ticketCategoryId: any;

  /** Sauvegarde le prototye sélectionné */
  emailPrototypeData: EmailPrototype;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    public templateService: TemplatesService,
    public emailConnectorService: EmailConnectorCoreService,
    public ticketEmailService: TicketEmailCoreService,
    public emailPrototypeCoreService: EmailPrototypeCoreService,
    public gcFactory: GCFactory,
    private translatedFieldHelperService: TranslatedFieldHelperService,
    private ticketService: TicketCoreService,
    private notficationService: NotificationCoreService,
  ) {
    this.gc = this.gcFactory.create();
    this.loadInputConnector();
  }

  async removeNotificationEmail() {
    if (this.ticketId != undefined) {
      this.notficationService
        .removeNotificationByKeys(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.ticketId,
          [
            NotificationKey.EmailIncident,
            NotificationKey.EmailIncidentTeam,
            NotificationKey.EmailRequest,
            NotificationKey.EmailRequestTeam,
            NotificationKey.EmailProblem,
            NotificationKey.EmailProblemTeam,
          ],
        )
        .subscribe();
    }
  }

  async ngOnInit() {
    let id =
      this.state.id == undefined
        ? this.route.snapshot.paramMap.get('id')
        : this.state.id;
    // this.state.isSubForm === true
    //   ? this.state.id
    //   : this.route.snapshot.paramMap.get('id');
    if (id != null) {
      await this.ticketService
        .isEndingTicket([GqlField.create('data')], id)
        .subscribe((response) => {
          if (response.data) {
            this.disabledEmailfunction = true;
          }
        });
    }

    if (id != undefined && this.source != undefined) {
      this.currentSource = this.source;
      this.state.sharedContext.params.set(this.fieldName, () => id);
    } else {
      this.currentSource = new ModelDataSourceContext({
        datasource: new DataSource([]),
      });
      let sub = this.state.formComponent.onSaved.subscribe((res) => {
        sub.unsubscribe();
        this.currentSource = this.source;
        this.state.sharedContext.params.set(this.fieldName, () => res);
        this.state.id = res;
        if (this.waitSave == true) {
          this.isPopupOpen = true;
          this.waitSave = false;
        }
      });
    }
    this.subFormModel = emailModel;
    this.service = this.serviceRetreiver.fnCall();
    this.ticketId = id;

    await this.removeNotificationEmail();

    this.state.subFormReady.subscribe(
      (data: { gc: GC; subModelState: ModelState; key: string }) => {
        this.setSharedContext(data.subModelState);
      },
    );
  }

  /** Permet de rechercher le connecteur par defaut */
  loadDefaultConnector(form: UntypedFormGroup, sub: ModelState) {
    this.emailConnectorService
      .find([GqlSubField.create('data', [GqlField.create('id')])], undefined, {
        default: {
          eq: true,
        },
      })
      .subscribe((r) => {
        if (r?.data != undefined && r.data.length > 0) {
          let connectorField = FormGroupHelpers.formControlByName(
            form,
            'emailConnectorId',
          );
          if (connectorField != undefined) {
            connectorField.setValue(r.data[0].id);
            sub.sharedContext.entry.set('emailConnectorId', () => r.data[0].id);
          }
        }
      });
  }

  loadInputConnector() {
    this.gc.forDispose(
      this.emailConnectorService
        .findInput([
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlSubField.create('name', [
              GqlField.create(
                this.translatedFieldHelperService.getTranslateKey(),
              ),
            ]),
            GqlField.create('email'),
          ]),
        ])
        .subscribe((result) => {
          this.emailInputConnectors = result.data;
        }),
    );
  }

  onInitialized(e) {}

  getParentId(): string {
    if (this.state.id != undefined) {
      this.ticketId = this.state.id;
      return this.state.id;
    } else {
      let id = this.route.snapshot.paramMap.get('id');
      this.ticketId = id;
      return id;
    }
  }

  async openSubForm() {
    // Vérification si le modele est valide
    this.waitSave = false;
    if (this.getParentId() == undefined) {
      if (FormGroupHelpers.valid(this.state.formComponent.form) == false) {
        // Validation du formulaire
        alert(
          TranslateService.get('globals/formNotValid'),
          TranslateService.get('globals/warning'),
        );
      } else {
        let confirm = await CoreSubFormLinkListComponent.confirmSaveDialog(
          TranslateService.get('confirm-title'),
          TranslateService.get('globals/confirm-refresh'),
          TranslateService.get('save'),
          TranslateService.get('cancel'),
        );
        if (confirm) {
          this.waitSave = true;
          this.state.formComponent.save({ close: false });
        }
      }
    } else {
      this.isPopupOpen = true;
    }
  }

  saved(id) {
    this.gc.forDispose(
      this.ticketEmailService
        .sendBack(
          [
            GqlField.create('data'),
            ModelFieldCompilerService.createErrorField(),
          ],
          id,
        )
        .subscribe((result) => {
          if (
            result?.data === false &&
            result?.errors != undefined &&
            result.errors.length > 0
          ) {
            notify(result.errors[0].messageError, 'error', 5000);
          }
          this.list.refresh();

          this.state.on.emit({
            eventName: 'reload-tabs',
            eventData: undefined,
          });
        }),
    );
  }

  manageFrom(form: UntypedFormGroup, subModelState: ModelState) {
    let segments = this.router.parseUrl(this.router.url).root.children.primary
      .segments;
    let type = pluralize.singular(segments[0].path);
    subModelState.sharedContext.params.set('type', () => [type]);
    let connectorField = FormGroupHelpers.formControlByName(
      form,
      'emailConnectorId',
    );
    connectorField.valueChanges.subscribe((value) => {
      this.gc.forDispose(
        this.emailConnectorService
          .get(
            [
              GqlSubField.create('data', [
                GqlField.create('id'),
                GqlField.create('email'),
              ]),
            ],
            value,
          )
          .subscribe((result) => {
            subModelState.sharedContext.entry.set(
              'from',
              () => result?.data?.email,
            );
          }),
      );
    });
  }

  managePrototype(
    form: UntypedFormGroup,
    subModelState: ModelState,
    action: boolean = false,
  ) {
    let field = FormGroupHelpers.formControlByName(form, 'emailPrototypeId');
    this.gc.forDispose(
      field.valueChanges.subscribe((value) => {
        // Langue en cours
        let lang = this.translatedFieldHelperService.getTranslateKey();
        this.emailPrototypeData = undefined;
        if (value != null) {
          this.gc.forDispose(
            this.emailPrototypeCoreService
              .emailPrototypeWithSubsitute(
                [
                  GqlSubField.create('data', [
                    GqlField.create('connectorId'),
                    GqlField.create('to'),
                    GqlField.create('cc'),
                    GqlField.create('cci'),
                    GqlField.create('replyTo'),
                    GqlSubField.create('subject', [GqlField.create(lang)]),
                    GqlSubField.create('body', [GqlField.create(lang)]),
                  ]),
                ],
                this.ticketId,
                value,
              )
              .subscribe((result) => {
                this.emailPrototypeData = result.data;
                if (action) {
                  this.setField(
                    this.subForm.subForm.form,
                    'emailConnectorId',
                    result.data.connectorId,
                    subModelState,
                  );
                  this.setField(
                    this.subForm.subForm.form,
                    'to',
                    result.data.to,
                    subModelState,
                  );
                  this.setField(
                    this.subForm.subForm.form,
                    'cc',
                    result.data.cc,
                    subModelState,
                  );
                  this.setField(
                    this.subForm.subForm.form,
                    'cci',
                    result.data.cci,
                    subModelState,
                  );
                  this.setField(
                    this.subForm.subForm.form,
                    'subject',
                    result.data.subject[lang],
                    subModelState,
                  );
                  this.setField(
                    this.subForm.subForm.form,
                    'body',
                    result.data.body[lang],
                    subModelState,
                  );
                }

                if (result.data?.replyTo?.length > 0) {
                  this.setField(
                    this.subForm.subForm.form,
                    'replyTo',
                    result.data.replyTo,
                    subModelState,
                  );
                } else {
                  this.setField(
                    this.subForm.subForm.form,
                    'replyTo',
                    [],
                    subModelState,
                  );
                }
              }),
          );
        }
      }),
    );
  }

  setField(
    form: UntypedFormGroup,
    fieldName: any,
    value: any,
    subModelState: ModelState,
    isDirty: boolean = false,
  ) {
    let field = FormGroupHelpers.formControlByName(form, fieldName);
    field.setValue(value);
    if (isDirty == true) {
      field.originalValue = '';
      setTimeout(() => field.markAsDirty());
    } else {
      subModelState.sharedContext.entry.set(fieldName, () => value);
    }
  }

  setSharedContext(subModelState) {
    let ticketCategoryId =
      this.subForm.parentModel.sharedContext?.entity?.ticketCategoryId;
    let fieldCategory = FormGroupHelpers.formControlByName(
      this.subForm.parentModel.form,
      'ticketCategoryId',
    );
    if (fieldCategory != undefined) {
      ticketCategoryId = fieldCategory.value;
    }
    subModelState.sharedContext.params.set(
      'ticketCategoryId',
      () => ticketCategoryId,
    );
  }

  newEmail(e) {
    this.gc.forDispose(
      this.subForm.parentModel.subFormReady.subscribe((sub) => {
        this.loadDefaultConnector(this.subForm.subForm.form, sub.subModelState);
        this.resetSub(sub);
        sub.subModelState.sharedContext.entry.set(
          'createdFromTicket',
          () => true,
        );
        sub.subModelState.sharedContext.entry.set(
          'ticketId',
          () => this.ticketId,
        );
        this.managePrototype(
          this.subForm.subForm.form,
          sub.subModelState,
          true,
        );
        this.manageFrom(this.subForm.subForm.form, sub.subModelState);
        this.setSharedContext(sub.subModelState);
      }),
    );
    this.openSubForm();
  }

  /** Initialise le sous formulaire */
  resetSub(sub) {
    // return;
    // sub.subModelState.form.reset();
    this.gc.dispose();
    this.setField(this.subForm.subForm.form, 'to', [], sub.subModelState);
    this.setField(
      this.subForm.subForm.form,
      'subject',
      undefined,
      sub.subModelState,
    );
    this.setField(
      this.subForm.subForm.form,
      'body',
      undefined,
      sub.subModelState,
    );
  }

  reply(e) {
    this.gc.forDispose(
      this.subForm.parentModel.subFormReady.subscribe((sub) => {
        this.loadDefaultConnector(this.subForm.subForm.form, sub.subModelState);
        this.resetSub(sub);
        sub.subModelState.sharedContext.entry.set(
          'createdFromTicket',
          () => true,
        );
        sub.subModelState.sharedContext.entry.set(
          'ticketId',
          () => this.ticketId,
        );
        this.manageFrom(this.subForm.subForm.form, sub.subModelState);

        this.setField(
          this.subForm.subForm.form,
          'to',
          [this.selectedData[0].from],
          sub.subModelState,
        );
        this.setField(
          this.subForm.subForm.form,
          'subject',
          'RE: ' + this.selectedData[0].subject,
          sub.subModelState,
        );
        this.managePrototype(this.subForm.subForm.form, sub.subModelState);
        this.gc.forDispose(
          this.ticketEmailService
            .get(
              [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlField.create('body'),
                ]),
              ],
              this.selectedData[0].id,
            )
            .subscribe((result) => {
              let data = this.selectedData[0];
              data.body = result.data.body;
              this.setField(
                this.subForm.subForm.form,
                'body',
                this.formatBody(data),
                sub.subModelState,
              );
            }),
        );
      }),
    );
    this.openSubForm();
  }

  transfer(e) {
    this.gc.forDispose(
      this.subForm.parentModel.subFormReady.subscribe((sub) => {
        this.loadDefaultConnector(this.subForm.subForm.form, sub.subModelState);

        this.resetSub(sub);
        sub.subModelState.sharedContext.entry.set(
          'createdFromTicket',
          () => true,
        );
        sub.subModelState.sharedContext.entry.set(
          'ticketId',
          () => this.ticketId,
        );
        this.manageFrom(this.subForm.subForm.form, sub.subModelState);

        this.setField(
          this.subForm.subForm.form,
          'subject',
          'RE: ' + this.selectedData[0].subject,
          sub.subModelState,
        );
        this.gc.forDispose(
          this.ticketEmailService
            .get(
              [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlField.create('body'),
                ]),
              ],
              this.selectedData[0].id,
            )
            .subscribe((result) => {
              let data = this.selectedData[0];
              data.body = result.data.body;
              this.setField(
                this.subForm.subForm.form,
                'body',
                this.formatBody(data),
                sub.subModelState,
              );
            }),
        );
      }),
    );
    this.openSubForm();
  }

  sendBack(e) {
    this.subForm.editId = this.selectedData[0].id;
    this.gc.forDispose(
      this.subForm.parentModel.subFormReady.subscribe((sub) => {
        this.loadDefaultConnector(this.subForm.subForm.form, sub.subModelState);

        sub.subModelState.sharedContext.entry.set(
          'createdFromTicket',
          () => true,
        );
        sub.subModelState.sharedContext.entry.set(
          'ticketId',
          () => this.ticketId,
        );
        this.manageFrom(this.subForm.subForm.form, sub.subModelState);

        this.setField(
          this.subForm.subForm.form,
          'subject',
          this.selectedData[0].subject,
          sub.subModelState,
          true,
        );
        this.gc.forDispose(
          this.ticketEmailService
            .get(
              [
                GqlSubField.create('data', [
                  GqlField.create('id'),
                  GqlField.create('body'),
                ]),
              ],
              this.selectedData[0].id,
            )
            .subscribe((result) => {
              this.setField(
                this.subForm.subForm.form,
                'body',
                result.data.body,
                sub.subModelState,
              );
            }),
        );
      }),
    );
    this.openSubForm();
  }

  formatBody(mail) {
    let body = '<br><br>';
    body += "<div style='border:none;border-top:solid #E1E1E1 1.0pt;'>";
    body += '<strong>De:</strong> ' + mail.from + '<br>';
    body +=
      '<strong>Envoyé:</strong> ' +
      new Date(mail.createdAt).toLocaleString(Globalize.locale().locale) +
      '<br>';
    body += '<strong>A:</strong> ' + mail.to + '<br>';
    body += '<strong>Objet:</strong> ' + mail.subject + '<br>';
    body += '</div>';
    body += mail.body;

    return body;
  }

  /** @inheritdoc */
  ngOnDestroy(): void {
    this.gc.dispose();
  }
}
