import { debug } from 'console';
import CustomStore, { CustomStoreOptions } from 'devextreme/data/custom_store';
import DataSource, { DataSourceOptions } from 'devextreme/data/data_source';
import { LoadOptions } from 'devextreme/data/load_options';
import { Subject } from 'rxjs';
import { ServiceListResult } from '.';
import { Pooling } from '../pooling';
import { CriteriaHelpers } from './criteria-helpers';
import {
  GraphQLStoreConfig,
  IQueryableStore,
  WrapperStore,
} from './graphql-store-options';
import {
  QueryFilterInputType,
  QueryOptionsInputType,
  QuerySortInputType,
} from './graphql-types';

/** Class de gestion du datasource */
export class GraphQLDataSource extends DataSource {
  private operationId = null;

  constructor(obj: CustomStore);
  constructor(obj: CustomStoreOptions | DataSourceOptions);
  constructor(obj: string);
  constructor(obj: any) {
    super(obj);
    this.on('changed', this.onLoaded);
  }

  private onLoaded() {
    this.operationId = null;
  }

  /** Permet de stopper l'ecoute de promise si une est déjà en cours */
  public load(loadOption: LoadOptions = null): Promise<any> {
    // if (this.operationId != undefined) {
    //   let cancelFn = super.cancel.bind(this);

    //   cancelFn(this.operationId);
    //   this.operationId = null;
    // }
    let tmp = super.load();
    //this.operationId = (tmp as any).operationId;
    return tmp;
  }
}

/**
 * Permet de générer un DataSource entre GraphQL et DeveExtreme.
 */
export class GraphQLSource {
  /**
   * Permet de construire le dataSource.
   * @param options Les options de configuration.
   */
  static create<TType, TInputType>(
    options: GraphQLStoreConfig<TType, TInputType>,
    pooling: Pooling = null,
    postProcess: any = null,
    onChanged: any = null,
  ): DataSource {
    return new GraphQLDataSource({
      //    key:options.key || "id",
      store: GraphQLStore.create(options, pooling),
      postProcess: postProcess,
      onChanged: onChanged,
    });
  }
  /**
   * Permet de construire le store Devextreme.
   * @param options Les options de configuration.
   */
}
export class GraphQLStore {
  /**
   * Permet de construire le store Devextreme.
   * @param options Les options de configuration.
   */
  public static create<TType, TInputType>(
    options: GraphQLStoreConfig<TType, TInputType>,
    pooling: Pooling,
  ): CustomStore {
    const query = new GraphQLQuery(options, pooling);
    const wrapper = new WrapperStore(query);
    const store = new CustomStore(wrapper);
    // Pour resoudre le problème devextreme sur le context d'execution
    store['context'] = wrapper;
    query.store = store;
    return store;
  }
}
/**
 * Représente la configuration du query du Store DevExtreme.
 */
class GraphQLQuery<TType, TInputType> implements IQueryableStore {
  errors: Subject<any>;
  cache: any;
  tmp: any;
  key: string;
  loadMode: string;
  config: GraphQLStoreConfig<TType, TInputType>;
  pooling: Pooling;
  store: CustomStore;
  hasTimeout: boolean = false;
  timers: NodeJS.Timeout[] = [];
  interval: number = 2000;
  constructor(
    config: GraphQLStoreConfig<TType, TInputType>,
    pooling: Pooling = null,
  ) {
    this.key = config.key || 'id';
    this.loadMode = 'processed';
    this.config = config;
    this.pooling = pooling;
  }
  byKey(key: string): Promise<TType> {
    if (typeof key != 'string' || key === '') return undefined;
    //let queryFilters: QueryFilterInputType[] = CriteriaHelpers.compile(['id', '=', key]);
    let args: any[] = [
      ...(this.config.parameters != undefined &&
      this.config.parameters.length > 0
        ? this.config.parameters
        : []),
      [{ name: 'id', operation: '=', value: key }],
      undefined,
    ];
    let result = this.config.query
      .apply(this.config.query, args)
      .toPromise()
      .then((result) => {
        return result.data && result.data[0];
      });
    return result;
  }

  /**
   * Permet de charger les données
   * @param loadOptions Les options du chargement des données.
   */
  load(loadOptions: LoadOptions): Promise<ServiceListResult<TType>> {
    try {
      // Pour le tri
      let sort: any[] = loadOptions.sort ? (loadOptions.sort as any[]) : [];
      // Pour le filtre
      let filter;

      // Force le search Value si présent
      if (
        this.config != undefined &&
        this.config.options != undefined &&
        this.config.options.searchValue != undefined
      ) {
        if (loadOptions != undefined && loadOptions.searchValue == undefined) {
          loadOptions.searchValue = this.config.options.searchValue;
        }
      }

      if (this.config.filters != undefined && this.config.filters.length > 0) {
        if (loadOptions.filter != undefined) {
          filter = [...this.config.filters, 'and', [...loadOptions.filter]];
        } else {
          filter = [...this.config.filters];
        }
      } else {
        filter = loadOptions.filter;
      }
      if (
        loadOptions.searchValue != undefined &&
        loadOptions.searchExpr != undefined
      ) {
        if (filter != undefined) {
          filter = [
            ...(filter != undefined ? filter : []),
            'and',
            [
              [
                loadOptions.searchExpr,
                loadOptions.searchOperation,
                loadOptions.searchValue,
              ],
            ],
          ];
        } else {
          filter = [
            [
              [
                loadOptions.searchExpr,
                loadOptions.searchOperation,
                loadOptions.searchValue,
              ],
            ],
          ];
        }
      }
      // Sauvegarde des options de requête
      this.config.options = loadOptions;

      let queryFilters: QueryFilterInputType[] = filter
        ? CriteriaHelpers.compile(filter)
        : [];
      // Construction du queryOptions
      let queryOptions: QueryOptionsInputType = {
        sort: sort,
        skip: loadOptions.skip,
        limit: loadOptions.take,
        searchValue: loadOptions.searchValue,
      };

      const args: any[] = [
        ...(this.config.parameters !== undefined &&
        this.config.parameters.length > 0
          ? this.config.parameters
          : []),
        queryFilters,
        queryOptions,
        this.config.manyToManyId,
      ];
      let result = this.config.query.apply(this.config.query, args).toPromise();
      result.then((res) => {
        if (
          this.hasTimeout === true &&
          res.data !== undefined &&
          Array.isArray(res.data)
        ) {
          this.clearTimeout();
          for (let elem of res.data) {
            this.timers.push(
              this.pooling.poolingById(
                this.store,
                elem.id,
                this.config.poolingOptions.query,
                this.interval,
              ),
            );
          }
        }
        return res;
      });
      result.catch((err) => {
        this.errors.next(err);
      });
      return result;
    } catch (e) {
      console.error(e);
    }
  }
  /** @inheritdoc */
  insert(values: any): Promise<any> {
    if (this.config.insert == undefined) {
      throw new Error(
        `Le paramètre 'insert' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [values];

    let result = this.config.insert.apply(this.config.insert, args).toPromise();
    return result;
  }
  /** @inheritdoc */
  remove(key: any): Promise<any> {
    if (this.config.delete == undefined) {
      throw new Error(
        `Le paramètre 'delete' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [key];
    let result = this.config.delete.apply(this.config.delete, args).toPromise();
    return result;
  }
  /** @inheritdoc */
  update(key: any, values: any): Promise<any> {
    if (this.config.update == undefined) {
      throw new Error(
        `Le paramètre 'update' n'est pas définit sur la source de données.`,
      );
    }
    const args: any[] = [key, values];
    let result = this.config.update.apply(this.config.update, args).toPromise();
    return result;
  }

  clearTimeout(): void {
    for (let timer of this.timers) {
      clearTimeout(timer);
    }
  }
  timeout(value: boolean, interval: number = 2000) {
    this.hasTimeout = value;
    this.interval = interval;
  }
}
