<dx-box direction="col" class="aeraItem">
  <dxi-item [baseSize]="40">
    <div class="aeraItemTitle" [ngStyle]="{ background: color }">
      <div class="itemTitle">
        <a
          *ngIf="linkTitle != undefined"
          class="linkIconContent"
          [routerLink]="linkTitle"
          ><span class="iconContent"><i [class]="icon"></i></span>{{ title }}</a
        >
        <span *ngIf="linkTitle == undefined">
          <span class="iconContent"><i [class]="icon"></i></span
          >{{ title }}</span
        >
      </div>
    </div>
  </dxi-item>
  <dxi-item [ratio]="1">
    <div class="aeraItemContent">
      <dx-list
        [dataSource]="source"
        pageLoadMode="scrollBottom"
        itemTemplate="data"
        selectionMode="single"
        height="100%"
        (onItemClick)="listSelectionChanged($event)"
      >
        <div class="templateItem" *dxTemplate="let item of 'data'">
          <div class="contentTemplateItem">
            <span class="iconListContent"><i class="fad fa-circle"></i></span>
            {{ getDisplay(item?.title) }}
          </div>
        </div>
      </dx-list>
    </div>
  </dxi-item>
</dx-box>

<clc-viewer-popup
  [(showViewer)]="showViewer"
  [showEvaluateZone]="showEvaluateZone"
  [serviceName]="sourceContext.serviceName"
  [(id)]="idEntity"
  [icon]="icon"
  [color]="color"
  [secondColor]="secondColor"
  [countService]="countService"
  [countMethodeService]="countMethodeService"
  [titleTranslatable]="titleTranslatable"
  [contentTranslatable]="contentTranslatable"
></clc-viewer-popup>
