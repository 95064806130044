import { LayoutInputType, ServiceListResult, LayoutType, ServiceResult, ServiceSingleResult, QueryFilterInputType, QueryOptionsInputType, QueryListResultTypeLayoutType } from '@clarilog/core';

import { Observable } from 'rxjs';

export interface ILayoutManager {

  /**
   * Permet de sauvegarder l'état du composant constituant la vue personnalisées.
   *
   * @param {LayoutsInputType[]} layouts
   * @returns {Observable<boolean>}
   * @memberof UserService
   */
  addLayout(entry: LayoutInputType): Observable<ServiceSingleResult<boolean>>;

  /** Permet de mettre à jour un layout. */
  updateLayout(id: string, entry: LayoutInputType): Observable<ServiceSingleResult<boolean>>;

  /**
   * Permet de lister les vues personnalisées.
   *
   * @param {string} key
   * @returns {Observable<ServiceSingleResult<LayoutsType>>}
   * @memberof UserService
   */
  findLayouts(key: string, filters?: QueryFilterInputType[], options?: QueryOptionsInputType): Observable<QueryListResultTypeLayoutType>;
  /**
   * Permet de supprimer des vues personnalisées.
   *
   * @param {LayoutsInputType[]} layouts
   * @returns {Observable<boolean>}
   * @memberof UserService
   */
  deleteLayouts(ids: string[]): Observable<ServiceSingleResult<boolean>>;

  /**
   * Permet de sauvegarder la vue personnalisée par défaut.
   *
   * @param {string} id
   * @returns {Observable<ServiceSingleResult<boolean>>}
   * @memberof ILayoutManager
   */
  setDefaultLayout(layouts: LayoutType[]): Observable<ServiceSingleResult<boolean>>;

  /**
   *  Permet de récupèrer la vue personnalisée par défaut.
   *
   * @returns {Observable<string>}
   * @memberof ILayoutManager
   */
  getDefaultLayout(): Observable<LayoutType[]>;
}
