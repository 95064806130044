import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketAffectedRulesBaseVariables, FirstTicketAffectedRulesBaseVariables, CountTicketAffectedRulesBaseVariables, FindTicketAffectedRulesBaseVariables, SearchTicketAffectedRulesBaseVariables, ExportSchemaTicketAffectedRulesBaseVariables, ExportDataTicketAffectedRulesBaseVariables, CustomQueryTicketAffectedRulesBaseVariables, CustomQueryIdTicketAffectedRulesBaseVariables, UsedTicketAffectedRulesBaseVariables, FindOperatorAffectedTicketAffectedRulesBaseVariables, FindOperatorReferentTicketAffectedRulesBaseVariables, FindOperatorLevelsByTeamTicketAffectedRulesBaseVariables, ExistTicketAffectedRulesBaseVariables, FindRecyclesTicketAffectedRulesBaseVariables, CountRecyclesTicketAffectedRulesBaseVariables, ReadOnlyTicketAffectedRulesBaseVariables, FindArchivedTicketAffectedRulesBaseVariables, CountAllTicketAffectedRulesBaseVariables, FindDynamicPropertyFieldsTicketAffectedRulesBaseVariables, FindUnassociatedPopulationsTicketAffectedRulesBaseVariables, InsertTicketAffectedRulesBaseVariables, UpdateTicketAffectedRulesBaseVariables, ImportDataTicketAffectedRulesBaseVariables, UpdateManyTicketAffectedRulesBaseVariables, DeleteTicketAffectedRulesBaseVariables, OrderTicketAffectedRulesBaseVariables, RestoreTicketAffectedRulesBaseVariables, RecycleTicketAffectedRulesBaseVariables, RestoreArchivedTicketAffectedRulesBaseVariables, ArchivedTicketAffectedRulesBaseVariables, AddFileDynamicFieldTicketAffectedRulesBaseVariables, RemoveFileDynamicFieldTicketAffectedRulesBaseVariables, AddPopulationsTicketAffectedRulesBaseVariables, RemovePopulationsTicketAffectedRulesBaseVariables } from '../gqls'
import { GetTicketAffectedRulesDocument, FirstTicketAffectedRulesDocument, CountTicketAffectedRulesDocument, FindTicketAffectedRulesDocument, SearchTicketAffectedRulesDocument, ExportSchemaTicketAffectedRulesDocument, ExportDataTicketAffectedRulesDocument, CustomQueryTicketAffectedRulesDocument, CustomQueryIdTicketAffectedRulesDocument, UsedTicketAffectedRulesDocument, FindOperatorAffectedTicketAffectedRulesDocument, FindOperatorReferentTicketAffectedRulesDocument, FindOperatorLevelsByTeamTicketAffectedRulesDocument, ExistTicketAffectedRulesDocument, FindRecyclesTicketAffectedRulesDocument, CountRecyclesTicketAffectedRulesDocument, ReadOnlyTicketAffectedRulesDocument, FindArchivedTicketAffectedRulesDocument, CountAllTicketAffectedRulesDocument, FindDynamicPropertyFieldsTicketAffectedRulesDocument, FindUnassociatedPopulationsTicketAffectedRulesDocument, InsertTicketAffectedRulesDocument, UpdateTicketAffectedRulesDocument, ImportDataTicketAffectedRulesDocument, UpdateManyTicketAffectedRulesDocument, DeleteTicketAffectedRulesDocument, OrderTicketAffectedRulesDocument, RestoreTicketAffectedRulesDocument, RecycleTicketAffectedRulesDocument, RestoreArchivedTicketAffectedRulesDocument, ArchivedTicketAffectedRulesDocument, AddFileDynamicFieldTicketAffectedRulesDocument, RemoveFileDynamicFieldTicketAffectedRulesDocument, AddPopulationsTicketAffectedRulesDocument, RemovePopulationsTicketAffectedRulesDocument } from '../gqls'
import { ServiceSingleResultOfTicketAffectedRule, QueryContextOfTicketAffectedRule, FilterOfTicketAffectedRule, ServiceSingleResultOfInt64, ServiceListResultOfTicketAffectedRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfUser, QueryContextOfUser, FilterOfUser, ServiceListResultOfSnmpVendor, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTicketAffectedRule, QueryContextOfPopulation, FilterOfPopulation, ServiceListResultOfPopulation, TicketAffectedRuleInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketAffectedRuleBaseService {
    
public modelName = 'ticketAffectedRule';
public modelPluralName = 'ticketAffectedRules';

	private getTicketAffectedRulesQuery: GetTicketAffectedRulesDocument;
	private firstTicketAffectedRulesQuery: FirstTicketAffectedRulesDocument;
	private countTicketAffectedRulesQuery: CountTicketAffectedRulesDocument;
	private findTicketAffectedRulesQuery: FindTicketAffectedRulesDocument;
	private searchTicketAffectedRulesQuery: SearchTicketAffectedRulesDocument;
	private exportSchemaTicketAffectedRulesQuery: ExportSchemaTicketAffectedRulesDocument;
	private exportDataTicketAffectedRulesQuery: ExportDataTicketAffectedRulesDocument;
	private customQueryTicketAffectedRulesQuery: CustomQueryTicketAffectedRulesDocument;
	private customQueryIdTicketAffectedRulesQuery: CustomQueryIdTicketAffectedRulesDocument;
	private usedTicketAffectedRulesQuery: UsedTicketAffectedRulesDocument;
	private findOperatorAffectedTicketAffectedRulesQuery: FindOperatorAffectedTicketAffectedRulesDocument;
	private findOperatorReferentTicketAffectedRulesQuery: FindOperatorReferentTicketAffectedRulesDocument;
	private findOperatorLevelsByTeamTicketAffectedRulesQuery: FindOperatorLevelsByTeamTicketAffectedRulesDocument;
	private existTicketAffectedRulesQuery: ExistTicketAffectedRulesDocument;
	private findRecyclesTicketAffectedRulesQuery: FindRecyclesTicketAffectedRulesDocument;
	private countRecyclesTicketAffectedRulesQuery: CountRecyclesTicketAffectedRulesDocument;
	private readOnlyTicketAffectedRulesQuery: ReadOnlyTicketAffectedRulesDocument;
	private findArchivedTicketAffectedRulesQuery: FindArchivedTicketAffectedRulesDocument;
	private countAllTicketAffectedRulesQuery: CountAllTicketAffectedRulesDocument;
	private findDynamicPropertyFieldsTicketAffectedRulesQuery: FindDynamicPropertyFieldsTicketAffectedRulesDocument;
	private findUnassociatedPopulationsTicketAffectedRulesQuery: FindUnassociatedPopulationsTicketAffectedRulesDocument;
	private insertTicketAffectedRulesMutation: InsertTicketAffectedRulesDocument;
	private updateTicketAffectedRulesMutation: UpdateTicketAffectedRulesDocument;
	private importDataTicketAffectedRulesMutation: ImportDataTicketAffectedRulesDocument;
	private updateManyTicketAffectedRulesMutation: UpdateManyTicketAffectedRulesDocument;
	private deleteTicketAffectedRulesMutation: DeleteTicketAffectedRulesDocument;
	private orderTicketAffectedRulesMutation: OrderTicketAffectedRulesDocument;
	private restoreTicketAffectedRulesMutation: RestoreTicketAffectedRulesDocument;
	private recycleTicketAffectedRulesMutation: RecycleTicketAffectedRulesDocument;
	private restoreArchivedTicketAffectedRulesMutation: RestoreArchivedTicketAffectedRulesDocument;
	private archivedTicketAffectedRulesMutation: ArchivedTicketAffectedRulesDocument;
	private addFileDynamicFieldTicketAffectedRulesMutation: AddFileDynamicFieldTicketAffectedRulesDocument;
	private removeFileDynamicFieldTicketAffectedRulesMutation: RemoveFileDynamicFieldTicketAffectedRulesDocument;
	private addPopulationsTicketAffectedRulesMutation: AddPopulationsTicketAffectedRulesDocument;
	private removePopulationsTicketAffectedRulesMutation: RemovePopulationsTicketAffectedRulesDocument;

	constructor(private injector: Injector) {
		this.getTicketAffectedRulesQuery = this.injector.get(GetTicketAffectedRulesDocument);
		this.firstTicketAffectedRulesQuery = this.injector.get(FirstTicketAffectedRulesDocument);
		this.countTicketAffectedRulesQuery = this.injector.get(CountTicketAffectedRulesDocument);
		this.findTicketAffectedRulesQuery = this.injector.get(FindTicketAffectedRulesDocument);
		this.searchTicketAffectedRulesQuery = this.injector.get(SearchTicketAffectedRulesDocument);
		this.exportSchemaTicketAffectedRulesQuery = this.injector.get(ExportSchemaTicketAffectedRulesDocument);
		this.exportDataTicketAffectedRulesQuery = this.injector.get(ExportDataTicketAffectedRulesDocument);
		this.customQueryTicketAffectedRulesQuery = this.injector.get(CustomQueryTicketAffectedRulesDocument);
		this.customQueryIdTicketAffectedRulesQuery = this.injector.get(CustomQueryIdTicketAffectedRulesDocument);
		this.usedTicketAffectedRulesQuery = this.injector.get(UsedTicketAffectedRulesDocument);
		this.findOperatorAffectedTicketAffectedRulesQuery = this.injector.get(FindOperatorAffectedTicketAffectedRulesDocument);
		this.findOperatorReferentTicketAffectedRulesQuery = this.injector.get(FindOperatorReferentTicketAffectedRulesDocument);
		this.findOperatorLevelsByTeamTicketAffectedRulesQuery = this.injector.get(FindOperatorLevelsByTeamTicketAffectedRulesDocument);
		this.existTicketAffectedRulesQuery = this.injector.get(ExistTicketAffectedRulesDocument);
		this.findRecyclesTicketAffectedRulesQuery = this.injector.get(FindRecyclesTicketAffectedRulesDocument);
		this.countRecyclesTicketAffectedRulesQuery = this.injector.get(CountRecyclesTicketAffectedRulesDocument);
		this.readOnlyTicketAffectedRulesQuery = this.injector.get(ReadOnlyTicketAffectedRulesDocument);
		this.findArchivedTicketAffectedRulesQuery = this.injector.get(FindArchivedTicketAffectedRulesDocument);
		this.countAllTicketAffectedRulesQuery = this.injector.get(CountAllTicketAffectedRulesDocument);
		this.findDynamicPropertyFieldsTicketAffectedRulesQuery = this.injector.get(FindDynamicPropertyFieldsTicketAffectedRulesDocument);
		this.findUnassociatedPopulationsTicketAffectedRulesQuery = this.injector.get(FindUnassociatedPopulationsTicketAffectedRulesDocument);
		this.insertTicketAffectedRulesMutation = this.injector.get(InsertTicketAffectedRulesDocument);
		this.updateTicketAffectedRulesMutation = this.injector.get(UpdateTicketAffectedRulesDocument);
		this.importDataTicketAffectedRulesMutation = this.injector.get(ImportDataTicketAffectedRulesDocument);
		this.updateManyTicketAffectedRulesMutation = this.injector.get(UpdateManyTicketAffectedRulesDocument);
		this.deleteTicketAffectedRulesMutation = this.injector.get(DeleteTicketAffectedRulesDocument);
		this.orderTicketAffectedRulesMutation = this.injector.get(OrderTicketAffectedRulesDocument);
		this.restoreTicketAffectedRulesMutation = this.injector.get(RestoreTicketAffectedRulesDocument);
		this.recycleTicketAffectedRulesMutation = this.injector.get(RecycleTicketAffectedRulesDocument);
		this.restoreArchivedTicketAffectedRulesMutation = this.injector.get(RestoreArchivedTicketAffectedRulesDocument);
		this.archivedTicketAffectedRulesMutation = this.injector.get(ArchivedTicketAffectedRulesDocument);
		this.addFileDynamicFieldTicketAffectedRulesMutation = this.injector.get(AddFileDynamicFieldTicketAffectedRulesDocument);
		this.removeFileDynamicFieldTicketAffectedRulesMutation = this.injector.get(RemoveFileDynamicFieldTicketAffectedRulesDocument);
		this.addPopulationsTicketAffectedRulesMutation = this.injector.get(AddPopulationsTicketAffectedRulesDocument);
		this.removePopulationsTicketAffectedRulesMutation = this.injector.get(RemovePopulationsTicketAffectedRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAffectedRule> {
    
    		let variables: GetTicketAffectedRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.get));
            }
            else{
                let result:ServiceSingleResultOfTicketAffectedRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAffectedRule> {
    
    		let variables: FirstTicketAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: FindTicketAffectedRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: SearchTicketAffectedRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketAffectedRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: CustomQueryTicketAffectedRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: CustomQueryIdTicketAffectedRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.used));
    	}

    	/** Permet de récuperer la liste d'opérateurs affectés. */
    	@InjectArgs
    	public findOperatorAffected(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('supportLevel') supportLevel: number,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('affectedOperatorTeamId?') affectedOperatorTeamId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindOperatorAffectedTicketAffectedRulesBaseVariables = {
    			supportLevel: supportLevel,
			affectedOperatorTeamId: affectedOperatorTeamId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorAffectedTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findOperatorAffected));
    	}

    	/** Permet de récuperer la liste d'opérateurs référents. */
    	@InjectArgs
    	public findOperatorReferent(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('referentOperatorTeamId?') referentOperatorTeamId?: string,
		@Args('options?') options?: QueryContextOfUser,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfUser> {
    
    		let variables: FindOperatorReferentTicketAffectedRulesBaseVariables = {
    			referentOperatorTeamId: referentOperatorTeamId,
			filter: filter,
			options: options
    		}
    				return this.findOperatorReferentTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findOperatorReferent));
    	}

    	/** Permet de récuperer la liste d'opérateurs référents. */
    	@InjectArgs
    	public findOperatorLevelsByTeam(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfUser,
		@Args('operatorTeam?') operatorTeam?: string,
		@Args('operatorAssigned?') operatorAssigned?: string,
		@Args('filter?') filter?: FilterOfUser,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpVendor> {
    
    		let variables: FindOperatorLevelsByTeamTicketAffectedRulesBaseVariables = {
    			operatorTeam: operatorTeam,
			operatorAssigned: operatorAssigned,
			filter: filter,
			options: options
    		}
    				return this.findOperatorLevelsByTeamTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findOperatorLevelsByTeam));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketAffectedRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: FindRecyclesTicketAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTicketAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTicketAffectedRulesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketAffectedRule,
		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketAffectedRule> {
    
    		let variables: FindArchivedTicketAffectedRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTicketAffectedRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTicketAffectedRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedPopulations(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfPopulation> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('populations', fields, null, [
			GqlSubFieldArg.create('filterOfPopulations', 'filter'),
			GqlSubFieldArg.create('optionsOfPopulations', 'options'),
		]),
		])
		extendedVariables['filterOfPopulations'] = filter;
		extendedVariables['optionsOfPopulations'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.populations));
            }
            else{
                let result: ServiceListResultOfPopulation = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPopulation,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfPopulation,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindUnassociatedPopulationsTicketAffectedRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedPopulationsTicketAffectedRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.findUnassociatedPopulations));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TicketAffectedRuleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAffectedRule> {
    
    		let variables: InsertTicketAffectedRulesBaseVariables = {
    			entity: entity
    		}
    				return this.insertTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketAffectedRule> {
    
    		let variables: UpdateTicketAffectedRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTicketAffectedRulesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketAffectedRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketAffectedRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderTicketAffectedRulesBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.order));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTicketAffectedRulesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTicketAffectedRulesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTicketAffectedRulesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationsTicketAffectedRulesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.addPopulationsTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.addPopulations));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationsTicketAffectedRulesBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationsTicketAffectedRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketAffectedRules.removePopulations));
    	}
    
}