import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetBiosBaseVariables, FirstBiosBaseVariables, CountBiosBaseVariables, FindBiosBaseVariables, SearchBiosBaseVariables, ExportSchemaBiosBaseVariables, ExportDataBiosBaseVariables, CustomQueryBiosBaseVariables, CustomQueryIdBiosBaseVariables, UsedBiosBaseVariables, ExistBiosBaseVariables } from '../gqls'
import { GetBiosDocument, FirstBiosDocument, CountBiosDocument, FindBiosDocument, SearchBiosDocument, ExportSchemaBiosDocument, ExportDataBiosDocument, CustomQueryBiosDocument, CustomQueryIdBiosDocument, UsedBiosDocument, ExistBiosDocument } from '../gqls'
import { ServiceSingleResultOfBios, QueryContextOfBios, FilterOfBios, ServiceSingleResultOfInt64, ServiceListResultOfBios, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class BioBaseService {
    
public modelName = 'bio';
public modelPluralName = 'bios';

	private getBiosQuery: GetBiosDocument;
	private firstBiosQuery: FirstBiosDocument;
	private countBiosQuery: CountBiosDocument;
	private findBiosQuery: FindBiosDocument;
	private searchBiosQuery: SearchBiosDocument;
	private exportSchemaBiosQuery: ExportSchemaBiosDocument;
	private exportDataBiosQuery: ExportDataBiosDocument;
	private customQueryBiosQuery: CustomQueryBiosDocument;
	private customQueryIdBiosQuery: CustomQueryIdBiosDocument;
	private usedBiosQuery: UsedBiosDocument;
	private existBiosQuery: ExistBiosDocument;

	constructor(private injector: Injector) {
		this.getBiosQuery = this.injector.get(GetBiosDocument);
		this.firstBiosQuery = this.injector.get(FirstBiosDocument);
		this.countBiosQuery = this.injector.get(CountBiosDocument);
		this.findBiosQuery = this.injector.get(FindBiosDocument);
		this.searchBiosQuery = this.injector.get(SearchBiosDocument);
		this.exportSchemaBiosQuery = this.injector.get(ExportSchemaBiosDocument);
		this.exportDataBiosQuery = this.injector.get(ExportDataBiosDocument);
		this.customQueryBiosQuery = this.injector.get(CustomQueryBiosDocument);
		this.customQueryIdBiosQuery = this.injector.get(CustomQueryIdBiosDocument);
		this.usedBiosQuery = this.injector.get(UsedBiosDocument);
		this.existBiosQuery = this.injector.get(ExistBiosDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBios> {
    
    		let variables: GetBiosBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.get));
            }
            else{
                let result:ServiceSingleResultOfBios={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBios,
		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBios> {
    
    		let variables: FirstBiosBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountBiosBaseVariables = {
    			filter: filter
    		}
    				return this.countBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBios,
		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBios> {
    
    		let variables: FindBiosBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfBios,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBios> {
    
    		let variables: SearchBiosBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaBiosBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataBiosBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfBios,
		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBios> {
    
    		let variables: CustomQueryBiosBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfBios,
		@Args('filter?') filter?: FilterOfBios,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBios> {
    
    		let variables: CustomQueryIdBiosBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedBiosBaseVariables = {
    			ids: ids
    		}
    				return this.usedBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistBiosBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existBiosQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.bios.exist));
    	}
    
    
}