/** Représente une library d'aide à l'iterprétation d'une fonction. */
export class CompilerHelpers {
  /** Récupère le nom des varaibles d'une fonction. */
  public static getParams(func: () => any): string[] {
    let params = [];

    // let tt = func.arguments;
    // console.log(tt);
    const symbolKey = Symbol.for('func');

    let meta = Reflect.getMetadata(symbolKey, func);

    if (meta == undefined) {
      //console.log("PAs implem meta")
      // String representaation of the function code
      let str = func.toString();

      // Remove comments of the form /* ... */
      // Removing comments of the form //
      // Remove body of the function { ... }
      // removing '=>' if func is arrow function
      str = str
        .replace(/\/\*[\s\S]*?\*\//g, '')
        .replace(/\/\/(.)*/g, '')
        .replace(/{[\s\S]*}/, '')
        .replace(/=>/g, '')
        .trim();

      // Start parameter names after first '('
      let start = str.indexOf('(') + 1;

      // End parameter names is just before last ')'
      let end = str.length - 1;

      let result = str.substring(start, end).split(', ');

      result.forEach((element) => {
        // Removing any default value
        element = element.replace(/=[\s\S]*/g, '').trim();

        if (element.length > 0) {
          if (element != '__typename') {
            element = element.replace(/\_/g, '.');
          }
          params.push(element);
        }
      });
    } else {
      params = meta;
    }
    return params;
  }
}
