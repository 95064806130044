import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwarePropertiesBaseVariables, FirstSoftwarePropertiesBaseVariables, CountSoftwarePropertiesBaseVariables, FindSoftwarePropertiesBaseVariables, SearchSoftwarePropertiesBaseVariables, ExportSchemaSoftwarePropertiesBaseVariables, ExportDataSoftwarePropertiesBaseVariables, CustomQuerySoftwarePropertiesBaseVariables, CustomQueryIdSoftwarePropertiesBaseVariables, UsedSoftwarePropertiesBaseVariables, ExistSoftwarePropertiesBaseVariables } from '../gqls'
import { GetSoftwarePropertiesDocument, FirstSoftwarePropertiesDocument, CountSoftwarePropertiesDocument, FindSoftwarePropertiesDocument, SearchSoftwarePropertiesDocument, ExportSchemaSoftwarePropertiesDocument, ExportDataSoftwarePropertiesDocument, CustomQuerySoftwarePropertiesDocument, CustomQueryIdSoftwarePropertiesDocument, UsedSoftwarePropertiesDocument, ExistSoftwarePropertiesDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareProperty, QueryContextOfSoftwareProperty, FilterOfSoftwareProperty, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareProperty, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwarePropertyBaseService {
    
public modelName = 'softwareProperty';
public modelPluralName = 'softwareProperties';

	private getSoftwarePropertiesQuery: GetSoftwarePropertiesDocument;
	private firstSoftwarePropertiesQuery: FirstSoftwarePropertiesDocument;
	private countSoftwarePropertiesQuery: CountSoftwarePropertiesDocument;
	private findSoftwarePropertiesQuery: FindSoftwarePropertiesDocument;
	private searchSoftwarePropertiesQuery: SearchSoftwarePropertiesDocument;
	private exportSchemaSoftwarePropertiesQuery: ExportSchemaSoftwarePropertiesDocument;
	private exportDataSoftwarePropertiesQuery: ExportDataSoftwarePropertiesDocument;
	private customQuerySoftwarePropertiesQuery: CustomQuerySoftwarePropertiesDocument;
	private customQueryIdSoftwarePropertiesQuery: CustomQueryIdSoftwarePropertiesDocument;
	private usedSoftwarePropertiesQuery: UsedSoftwarePropertiesDocument;
	private existSoftwarePropertiesQuery: ExistSoftwarePropertiesDocument;

	constructor(private injector: Injector) {
		this.getSoftwarePropertiesQuery = this.injector.get(GetSoftwarePropertiesDocument);
		this.firstSoftwarePropertiesQuery = this.injector.get(FirstSoftwarePropertiesDocument);
		this.countSoftwarePropertiesQuery = this.injector.get(CountSoftwarePropertiesDocument);
		this.findSoftwarePropertiesQuery = this.injector.get(FindSoftwarePropertiesDocument);
		this.searchSoftwarePropertiesQuery = this.injector.get(SearchSoftwarePropertiesDocument);
		this.exportSchemaSoftwarePropertiesQuery = this.injector.get(ExportSchemaSoftwarePropertiesDocument);
		this.exportDataSoftwarePropertiesQuery = this.injector.get(ExportDataSoftwarePropertiesDocument);
		this.customQuerySoftwarePropertiesQuery = this.injector.get(CustomQuerySoftwarePropertiesDocument);
		this.customQueryIdSoftwarePropertiesQuery = this.injector.get(CustomQueryIdSoftwarePropertiesDocument);
		this.usedSoftwarePropertiesQuery = this.injector.get(UsedSoftwarePropertiesDocument);
		this.existSoftwarePropertiesQuery = this.injector.get(ExistSoftwarePropertiesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareProperty> {
    
    		let variables: GetSoftwarePropertiesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareProperty={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareProperty,
		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareProperty> {
    
    		let variables: FirstSoftwarePropertiesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwarePropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareProperty,
		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareProperty> {
    
    		let variables: FindSoftwarePropertiesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareProperty,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareProperty> {
    
    		let variables: SearchSoftwarePropertiesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwarePropertiesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwarePropertiesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareProperty,
		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareProperty> {
    
    		let variables: CustomQuerySoftwarePropertiesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareProperty,
		@Args('filter?') filter?: FilterOfSoftwareProperty,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareProperty> {
    
    		let variables: CustomQueryIdSoftwarePropertiesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwarePropertiesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwarePropertiesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwarePropertiesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareProperties.exist));
    	}
    
    
}