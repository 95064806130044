import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfOsRecoveryConfiguration, FilterOfOsRecoveryConfiguration, QueryContextOfOsRecoveryConfiguration, ServiceSingleResultOfInt64, ServiceListResultOfOsRecoveryConfiguration, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

export type GetOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        get?: Maybe<ServiceSingleResultOfOsRecoveryConfiguration>
    }
}

export type GetOsRecoveryConfigurationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        first?: Maybe<ServiceSingleResultOfOsRecoveryConfiguration>
    }
}

export type FirstOsRecoveryConfigurationsBaseVariables = {
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOsRecoveryConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOsRecoveryConfiguration = null, $options: QueryContextOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountOsRecoveryConfigurationsBaseVariables = {
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        find?: Maybe<ServiceListResultOfOsRecoveryConfiguration>
    }
}

export type FindOsRecoveryConfigurationsBaseVariables = {
	options: Maybe<QueryContextOfOsRecoveryConfiguration>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfOsRecoveryConfiguration = null, $filter: FilterOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        search?: Maybe<ServiceListResultOfOsRecoveryConfiguration>
    }
}

export type SearchOsRecoveryConfigurationsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfOsRecoveryConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaOsRecoveryConfigurationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataOsRecoveryConfigurationsBaseVariables = {
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        customQuery?: Maybe<ServiceListResultOfOsRecoveryConfiguration>
    }
}

export type CustomQueryOsRecoveryConfigurationsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOsRecoveryConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfOsRecoveryConfiguration = null, $options: QueryContextOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        customQueryId?: Maybe<ServiceListResultOfOsRecoveryConfiguration>
    }
}

export type CustomQueryIdOsRecoveryConfigurationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfOsRecoveryConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfOsRecoveryConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfOsRecoveryConfiguration = null, $options: QueryContextOfOsRecoveryConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfOsRecoveryConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedOsRecoveryConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistOsRecoveryConfigurationsResultType = {
    osRecoveryConfigurations: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistOsRecoveryConfigurationsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistOsRecoveryConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existOsRecoveryConfigurations ${args} {
    osRecoveryConfigurations {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistOsRecoveryConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistOsRecoveryConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

