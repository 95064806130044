import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPortableBatteriesBaseVariables, FirstPortableBatteriesBaseVariables, CountPortableBatteriesBaseVariables, FindPortableBatteriesBaseVariables, SearchPortableBatteriesBaseVariables, ExportSchemaPortableBatteriesBaseVariables, ExportDataPortableBatteriesBaseVariables, CustomQueryPortableBatteriesBaseVariables, CustomQueryIdPortableBatteriesBaseVariables, UsedPortableBatteriesBaseVariables, ExistPortableBatteriesBaseVariables } from '../gqls'
import { GetPortableBatteriesDocument, FirstPortableBatteriesDocument, CountPortableBatteriesDocument, FindPortableBatteriesDocument, SearchPortableBatteriesDocument, ExportSchemaPortableBatteriesDocument, ExportDataPortableBatteriesDocument, CustomQueryPortableBatteriesDocument, CustomQueryIdPortableBatteriesDocument, UsedPortableBatteriesDocument, ExistPortableBatteriesDocument } from '../gqls'
import { ServiceSingleResultOfPortableBattery, QueryContextOfPortableBattery, FilterOfPortableBattery, ServiceSingleResultOfInt64, ServiceListResultOfPortableBattery, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PortableBatteryBaseService {
    
public modelName = 'portableBattery';
public modelPluralName = 'portableBatteries';

	private getPortableBatteriesQuery: GetPortableBatteriesDocument;
	private firstPortableBatteriesQuery: FirstPortableBatteriesDocument;
	private countPortableBatteriesQuery: CountPortableBatteriesDocument;
	private findPortableBatteriesQuery: FindPortableBatteriesDocument;
	private searchPortableBatteriesQuery: SearchPortableBatteriesDocument;
	private exportSchemaPortableBatteriesQuery: ExportSchemaPortableBatteriesDocument;
	private exportDataPortableBatteriesQuery: ExportDataPortableBatteriesDocument;
	private customQueryPortableBatteriesQuery: CustomQueryPortableBatteriesDocument;
	private customQueryIdPortableBatteriesQuery: CustomQueryIdPortableBatteriesDocument;
	private usedPortableBatteriesQuery: UsedPortableBatteriesDocument;
	private existPortableBatteriesQuery: ExistPortableBatteriesDocument;

	constructor(private injector: Injector) {
		this.getPortableBatteriesQuery = this.injector.get(GetPortableBatteriesDocument);
		this.firstPortableBatteriesQuery = this.injector.get(FirstPortableBatteriesDocument);
		this.countPortableBatteriesQuery = this.injector.get(CountPortableBatteriesDocument);
		this.findPortableBatteriesQuery = this.injector.get(FindPortableBatteriesDocument);
		this.searchPortableBatteriesQuery = this.injector.get(SearchPortableBatteriesDocument);
		this.exportSchemaPortableBatteriesQuery = this.injector.get(ExportSchemaPortableBatteriesDocument);
		this.exportDataPortableBatteriesQuery = this.injector.get(ExportDataPortableBatteriesDocument);
		this.customQueryPortableBatteriesQuery = this.injector.get(CustomQueryPortableBatteriesDocument);
		this.customQueryIdPortableBatteriesQuery = this.injector.get(CustomQueryIdPortableBatteriesDocument);
		this.usedPortableBatteriesQuery = this.injector.get(UsedPortableBatteriesDocument);
		this.existPortableBatteriesQuery = this.injector.get(ExistPortableBatteriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortableBattery> {
    
    		let variables: GetPortableBatteriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.get));
            }
            else{
                let result:ServiceSingleResultOfPortableBattery={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortableBattery,
		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortableBattery> {
    
    		let variables: FirstPortableBatteriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPortableBatteriesBaseVariables = {
    			filter: filter
    		}
    				return this.countPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortableBattery,
		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortableBattery> {
    
    		let variables: FindPortableBatteriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPortableBattery,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortableBattery> {
    
    		let variables: SearchPortableBatteriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPortableBatteriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPortableBatteriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPortableBattery,
		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortableBattery> {
    
    		let variables: CustomQueryPortableBatteriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPortableBattery,
		@Args('filter?') filter?: FilterOfPortableBattery,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortableBattery> {
    
    		let variables: CustomQueryIdPortableBatteriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPortableBatteriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPortableBatteriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPortableBatteriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portableBatteries.exist));
    	}
    
    
}