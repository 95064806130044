import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnInit,
  SimpleChanges,
  ViewChild,
} from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import {
  ActivatedRoute,
  NavigationExtras,
  Router,
  UrlTree,
} from '@angular/router';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import DataSource from 'devextreme/data/data_source';
import { ListMode } from '../list/list.component';
import { TreeListService } from '../list/tree-list.service';
import { Filter, GridOptions } from '@clarilog/shared2/models/schema';
import {
  ModelDataSourceContext,
  ModelEventContext,
  ModelFnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import dxDataGrid, { Column } from 'devextreme/ui/data_grid';
import { TemplatesService } from '../../templates/templates.service';
import { CoreSelectListComponent } from '../select-list/select-list.component';
import { v4 as uuidv4 } from 'uuid';
import { confirm } from 'devextreme/ui/dialog';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-grid',
  templateUrl: './grid.component.html',
  styleUrls: ['./grid.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreGridComponent),
      multi: true,
    },
  ],
})
// ATTENTION il y a une erreur sur ce composant
/// ERROR TypeError: Cannot read property 'forEach' of undefined
/// c'est connu et ça vient de devextreme
/// https://supportcenter.devexpress.com/ticket/details/t959818/error-editing-devextreme-data-grid-forked-codesandbox
///
///
///
export class CoreGridComponent
  extends TreeListService
  implements ControlValueAccessor, OnInit
{
  /** Sauvegarde les valeurs. */
  _values: any[] = [];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Obtient ou définit les options (Attention, solution de facilité malheureusement, cette méthode ne permet
   * pas de suivre les modifications. Donc, 'options' à revoir !!!!). */
  @Input() options: GridOptions;
  @Input() mode: ListMode = 'default';
  @Input() readOnly: boolean = false;
  /** Obtient ou définit le modele state */
  @Input() modelState: ModelState;
  /** Obtient ou définit si le composant se refresh automatiqument lorsqu'on revient dessus */
  @Input() autoRefresh: boolean = false;
  @Input() managed: boolean = true;
  /** Obtiens définis les filtres */
  @Input() filters: Filter[];
  /** Obtient le composant */
  @ViewChild(CoreSelectListComponent, { static: true })
  selectList: CoreSelectListComponent;

  /** Obtient ou définit la source de données. */
  source: any[];
  treelistAllSelectKey = 0;
  /** obtient ou definit la valeur du check box du tree */
  checkBoxValue: Boolean = false;
  checkAllComponent: any;
  onSelectChangeAction: Boolean = false;

  /** Récupère le composant en cours. */
  component: dxDataGrid;
  /**Permet de savoir si le dataSource doit etre chargée lors d'un new*/
  @Input() authorize;
  id: string;

  loadPanel: boolean = false;

  /** Affiche le popup d'association */
  associatePopup: boolean = false;

  /** Tableau des index sélectionnées */
  selectedKeys: any[] = [];
  initializeValue: boolean = false;

  /** Liste des colonne pour le pop up de sélection */
  selectColumns: Column[];
  /** Liste des clé sélectionné */
  selectKeys = [];
  gc: GC;

  filterForSelect: Filter[];

  /** Permet d'inhiber le refresh */
  cancelRefresh: boolean = false;

  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this.initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  constructor(
    public templateService: TemplatesService,
    private _router: Router,
    private route: ActivatedRoute,
    private changeDetectorRef: ChangeDetectorRef,
    private gcFactory: GCFactory,
  ) {
    super();
    this.gc = gcFactory.create();
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  /** @inheritdoc */
  writeValue(values: any[]): void {
    // ne gere pas les changement de sélection
    if (this.readOnly != undefined && this.readOnly == true) {
      return;
    }
    this.initializeValue = true;
    this.values = values;
    if (this.values != undefined) {
      this._originalValues = JSON.parse(JSON.stringify(this.values));
    }
    this.initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
    this.readOnly = isDisabled;
  }
  /** @inheritdoc */
  async ngOnInit() {
    this.selectColumns = [];
    if (this.options.selectColumns != undefined) {
      this.selectColumns = JSON.parse(
        JSON.stringify(this.options.selectColumns),
      );
    }

    if (this.filters != undefined) {
      this.filterForSelect = [];
      for (let i = 0; i < this.filters.length; i++) {
        this.filterForSelect.push({
          items: [],
          list: undefined,
          text: this.filters[i].text,
        });

        if (this.filters[i].items != undefined) {
          for (let y = 0; y < this.filters[i].items.length; y++) {
            if (this.filters[i].items[y].list != undefined) {
              this.filterForSelect[i].items.push({
                text: this.filters[i].items[y].text,
                source: this.filters[i].items[y].source,
                inContext: this.filters[i].items[y].inContext,
                filterExpr: this.filters[i].items[y].filterExpr,
                parentIdExpr: this.filters[i].items[y].parentIdExpr,
                description: this.filters[i].items[y].description,
                keyExpr: this.filters[i].items[y].keyExpr,
                displayExpr: this.filters[i].items[y].displayExpr,
                multiple: this.filters[i].items[y].multiple,
                filterOperator: this.filters[i].items[y].filterOperator,
                recursive: this.filters[i].items[y].recursive,
                command: this.filters[i].items[y].command,
                filterLive: this.filters[i].items[y].filterLive,
                list: {
                  source: this.options.selectSource,
                },
                visible: this.filters[i].items[y].visible,
              });
            }
          }
        }

        if (this.filters[i].list != undefined) {
          this.filterForSelect[i].list = {
            source: this.options.selectSource,
          };
        }
      }
    }

    if (this.modelState != undefined) {
      // Après save restaure l'etat de base du cancel
      this.modelState.formComponent.onSubmit.subscribe((f) => {
        if (this.component != undefined && this.cancelRefresh === true) {
          this.cancelRefresh = false;
          this.component.repaint();
        }
      });
    }
  }

  /** Permet de sélectionner les valeurs. */
  private selectDefaultValues() {
    let keyExpr =
      this.options.keyExpr != undefined ? this.options.keyExpr : 'id';
    if (this.values == undefined) {
      this.values = [];
    }
    this.source
      .filter((item) => this.values.some((id) => item[keyExpr] === id))
      .forEach((item) => {
        item.enabled = true;

        if (
          this.options.selection == undefined ||
          this.options.selection.mode != 'none'
        ) {
          this.selectedKeys.push(item[keyExpr]);
        }
      });
  }

  /** Obtient le mode de fonctoinnement */
  hasAsyncSaveMode() {
    if (
      this.modelState?.formComponent != undefined &&
      this.options.saveItems != undefined &&
      this.managed === false
    ) {
      return true;
    }

    return false;
  }

  /** Set la valeur */
  setValueAsyncSaveMode(removeKey: any[]) {
    this.cancelRefresh = true;
    this.component.repaint();
    let items = (<any>this.component.option('dataSource')) as any[];
    if (removeKey.length > 0) {
      items = items.filter((s) => removeKey.indexOf(s.id) < 0);
      this.component.option('dataSource', items);
    }
    this._values = items;
    this.onChange(this._values);
    this.onTouched();
  }

  public onRowUpdating(e) {
    if (this.hasAsyncSaveMode()) {
      this.setValueAsyncSaveMode([]);
    } else {
      let index = this.values.indexOf(e.key);
      if (index === -1) {
        this._values.push(e.key);
      } else {
        this._values.splice(index, 1);
      }
    }
    this.onChange(this._values);
    this.onTouched();
  }
  public onToolbarPreparing(e) {
    e.toolbarOptions.items.unshift({
      widget: 'dxButton',
      location: 'after',
      options: {
        icon: 'refresh',
        hint: TranslateService.get('globals/refresh'),
        onClick: () => {
          this.refresh();
          if (this.modelState != undefined) {
            this.modelState.on.emit({
              eventName: 'reload-tabs',
              eventData: undefined,
            });
          }
        },
      },
    });

    if (this.options.type === 'Tree') {
      e.toolbarOptions.items.unshift(
        {
          widget: 'dxButton',
          location: 'after',
          options: {
            //icon: 'fal fa-minus-square',
            icon: 'minus',
            stylingMode: 'text',
            /*  text:
              this.mode === 'popup'
                ? undefined
                : TranslateService.get('globals/collapseAll'),*/
            hint: TranslateService.get('globals/collapseAll'),
            onClick: () => {
              this.treelistCollapseAll(e);
            },
          },
        },
        {
          widget: 'dxButton',
          location: 'after',

          options: {
            //on: 'fal fa-plus-square',
            icon: 'plus',
            stylingMode: 'text',
            /*    text:
              this.mode === 'popup'
                ? undefined
                : TranslateService.get('globals/expandAll'),*/
            hint: TranslateService.get('globals/expandAll'),
            onClick: () => {
              this.treelistExpandAll(e);
            },
          },
        },
      );
    }

    if (
      this.options.route != undefined &&
      this.options?.selectSource == undefined
    ) {
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'after',
        options: {
          //con: 'fas fa-arrow-right',
          icon: 'arrowright',
          stylingMode: 'text',
          /*text:
            this.mode === 'popup' ? undefined : TranslateService.get('manage'),*/
          hint: TranslateService.get('manage'),
          onClick: () => {
            this.onGoTo();
          },
        },
      });
    }

    if (this.options.toolbarItems != undefined) {
      for (let toolbarItem of this.options.toolbarItems) {
        e.toolbarOptions.items.unshift({
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-button-style',
          options: {
            icon: toolbarItem.icon,
            stylingMode: 'text',
            text: toolbarItem.label,
            hint: toolbarItem.hint,
            onClick: () => {
              ((<any>toolbarItem.click) as ModelEventContext)
                .eventEmitter()
                .emit();
            },
          },
        });
      }
    }

    if (this.options.type === 'Tree') {
      e.toolbarOptions.items.unshift({
        widget: 'dxCheckBox',
        cssClass: 'cl-checkbox-style',
        location: 'before',
        options: {
          visible: !(
            this.readOnly ||
            this.disabled ||
            this.options?.selection?.mode === 'none'
          ),
          // value: this.checkBoxValue,
          onInitialized: (args) => {
            this.checkAllComponent = args.component;
          },
          onValueChanged: (args) => {
            if (!this.onSelectChangeAction) {
              this.selectedKeys = [];
              if (args.value && args.previousValue != undefined) {
                this.selectedKeys = this.treelistCheckAll(e, this.options);
              }
              this.changeDetectorRef.detectChanges();
            }
          },
        },
      });
    }

    if (
      this.options?.selectSource != undefined &&
      !(this.readOnly || this.disabled)
    ) {
      if (this.options?.route != undefined) {
        e.toolbarOptions.items.unshift({
          widget: 'dxButton',
          location: 'before',
          cssClass: 'cl-button-style',
          options: {
            stylingMode: 'text',
            icon: 'fas fa-arrow-right',
            text: TranslateService.get('manage'),
            onClick: () => {
              this.onGoTo();
            },
          },
        });
      }
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'before',
        cssClass: 'cl-button-style',
        options: {
          stylingMode: 'text',
          icon: 'fas fa-ban',
          visible: this.cancelRefresh,
          text: TranslateService.get('cancel'),
          onClick: () => {
            this.cancelRefresh = false;
            this.component.repaint();
            this.refresh();
          },
        },
      });

      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'before',
        cssClass: 'cl-button-style',
        options: {
          icon: 'far fa-unlink',
          stylingMode: 'text',
          disabled: !this.hasSelected,
          text: TranslateService.get('dissociate'),
          onClick: () => {
            let keys = this.component.option('selectedRowKeys');
            if (keys != undefined && keys.length > 0) {
              this.setValueAsyncSaveMode(keys);
            }
          },
        },
      });
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'before',
        cssClass: 'cl-button-style',
        options: {
          stylingMode: 'text',
          icon: 'far fa-link',
          text: TranslateService.get('associate'),
          onClick: () => {
            this.associatePopup = true;
          },
        },
      });
    }

    this.changeDetectorRef.detectChanges();
  }

  initialized(e: { component: dxDataGrid; element: any }) {
    this.component = e.component;
    this.id = this.route.snapshot.paramMap.get('id');

    // Pour le load panel
    setTimeout(() => {
      let dataSource = (<ModelDataSourceContext>(<unknown>this.options.source))
        .datasource;

      if (this.id == null && this.authorize == false) {
        dataSource = new DataSource([]);
      }

      if (this.options.type == 'Grid') {
        if (this.options.paging != undefined) {
          if (
            this.options.paging.enabled != undefined &&
            !this.options.paging.enabled
          ) {
            dataSource.pageSize(500000);
          } else {
            if (this.options.paging.pageIndex != undefined) {
              dataSource.pageIndex(this.options.paging.pageIndex);
            }
            if (this.options.paging.pageSize != undefined) {
              dataSource.pageSize(this.options.paging.pageSize);
            }
          }
        }
      } else {
        dataSource.pageSize(500000);
      }

      this.component.beginCustomLoading('');
      // Pour le load panel
      setTimeout(async () => {
        this.source = await dataSource.reload();
        if (!this.hasAsyncSaveMode()) {
          this.selectDefaultValues();
        }
        this.component.endCustomLoading();
      });
    });
  }

  /** initialisation du composant */
  onContentReady(e) {
    this.treelistContentReadyTreeList(e, this.options);
  }

  onOptionChangedTree(e) {
    // Permet de géré la recursivité au 1er clic
    // Par defaut au chargement de donnée, la récursivité n'est pas activé pour pouvoir sélection des noeud parent unique
    if (
      this.options?.recursive != undefined &&
      !this.options.recursive &&
      e.name == 'focusedRowIndex'
    ) {
      this.setRecusirf();
    }
  }
  totalCount = 0;
  hasSelected = false;
  /** Gestion de la sélection */
  onSelectionChanged(e, selectionChanged: any) {
    // ne gere pas les changement de sélection
    if (this.readOnly != undefined && this.readOnly == true) {
      return;
    }

    this.hasSelected = e.selectedRowKeys.length > 0;
    if (selectionChanged == undefined) {
      if (this.options?.selection?.mode !== 'none' && this.hasAsyncSaveMode()) {
        e.component.repaint();
      }
      return;
    }

    if (
      selectionChanged === 'tree' &&
      this.options?.selection?.mode === 'none'
    ) {
      return;
    }

    if (this.options.type == 'Tree') {
      if (this.totalCount == 0) {
        this.totalCount = e.component.totalCount();
      }
      this.loadPanel = this.totalCount > 1000;
      setTimeout(() => {
        if (!this.options.recursive) {
          this.treelistSelectionChanged(e, this.options);
        }
        if (this._values == undefined) {
          this._values = [];
        }
        if (this._values != undefined) {
          this._values.clear();
        }
        e.component
          .getSelectedRowKeys('all')
          .forEach((f) => this._values.push(f));
        this.treelistAllSelectKey = this._values.length;

        if (this.checkAllComponent != undefined) {
          this.onSelectChangeAction = true;

          let countNodes = 0;
          e.component.forEachNode((node) => {
            countNodes++;
          });
          let checkValue = false;
          if (this._values.length == countNodes) {
            checkValue = true;
          } else if (this._values.length == 0) {
            checkValue = false;
          } else {
            checkValue = undefined;
          }
          this.checkAllComponent.option('value', checkValue);
          this.onSelectChangeAction = false;
        }
        this.onChange(this._values);
        this.onTouched();
        this.loadPanel = false;
      }, 20);
    } else {
      if (selectionChanged != undefined && !this.hasAsyncSaveMode()) {
        this._values = e.selectedRowKeys;
        this.onChange(this._values);
        this.onTouched();
      }
    }
  }

  /** Rafraichi la liste. */
  refresh() {
    if (this.cancelRefresh === true) return;

    // Reset origine
    if (this.id != null && this.authorize != false) {
      (<ModelDataSourceContext>(<unknown>this.options.source)).datasource
        .load()
        .then((results) => {
          this.source = results;
          if (this._originalValues != undefined) {
            this._values = JSON.parse(JSON.stringify(this._originalValues));
          }
          if (!this.hasAsyncSaveMode()) {
            this.onChange(this._values);
            this.onTouched();
            this.selectDefaultValues();
          } else {
            this._values = undefined;
            this.values = this._values;
            this._originalValues = this._values;
            this.onChange(this._values);
            this.onTouched();
          }
        });
    } else {
      (<ModelDataSourceContext>(<unknown>this.options.source)).datasource
        .load()
        .then((results) => {
          this.source = results;
        });
    }
  }
  /** Se déclenche lors du clique sur l'en-tête d'activé (pour tout cocher). */
  onValueChanged(e, column) {
    this._values.clear();
    column.component.beginUpdate();
    let dataSource = JSON.parse(
      JSON.stringify(column.component.option('dataSource')),
    );
    dataSource.forEach((element) => {
      element[column.column.dataField] = e.value;
      if (e.value) {
        this._values.push(element['id']);
      }
    });
    column.component.option('dataSource', dataSource);
    column.component.endUpdate();

    this.onChange(this._values);
    this.onTouched();
  }

  /** Se déclenche sur le click du bouton accéder. */
  public async onGoTo() {
    let url = this._router.createUrlTree([this.options.route], {
      skipLocationChange: true,
    } as NavigationExtras);
    let win = window.open(url.toString(), '_blank');
    win.opener.callback = async () => await this.refresh();
  }

  /** Permet de gérer l'apparence d'une cellule */
  onCellPrepared(e) {
    this.treelistCellPrepared(e, this.options);
  }

  underline(e, data) {
    if (e.button == undefined || e.button == 0) {
      let params = undefined;

      let url: UrlTree;
      let id = data.key;

      let controlColumn = this.options.columns.find(
        (f) => f.field == data.column.dataField,
      );
      if (controlColumn?.linkIdRef != undefined) {
        id = data.data[controlColumn.linkIdRef];
      }

      url = this._router.createUrlTree([this.options.route, 'edit', id], {
        queryParams: params,
        skipLocationChange: true,
      } as NavigationExtras);

      let win = window.open(this._router.serializeUrl(url), '_blank');
      win.opener.callback = async () => await this.refresh();
    }
  }

  /** Valeur par defaut du paging */
  getPaging(e) {
    if (e != undefined) {
      e.enabled = true;

      if (e.pageSize == undefined) {
        e.pageSize = 20;
      }

      return e;
    } else if (e == undefined) {
      return {
        enabled: true,
        pageSize: 20,
      };
    }
  }
  /** Valeur par defaut du pager */
  getPager(e) {
    if (e != undefined) {
      e.visible = true;

      e.showInfo = true;
      e.infoText =
        '{2}' +
        TranslateService.get('items') +
        (this.treelistAllSelectKey != undefined && this.treelistAllSelectKey > 0
          ? ' / ' +
            this.treelistAllSelectKey +
            ' ' +
            TranslateService.get('globals/toolBarSelectedCount')
          : '');
      e.showPageSizeSelector = false;
      return e;
    } else if (e == undefined) {
      return {
        showPageSizeSelector: false,
        visible: true,
        showInfo: true,
      };
    }
  }

  /** Préparation du menu de la grille */
  onContextMenuPreparing(e) {
    this.treelistContextMenuPreparing(e, this.readOnly, this.options);
  }

  onShowingPanel(e) {}

  /** @inheritdoc */
  public ngAfterContentInit(): void {
    if (this.selectList !== undefined) {
      this.selectList.onSelect.subscribe(async (results: any[]) => {
        let fn = (<any>this.options.selectMethodAdd) as ModelFnContext;

        if (fn != undefined) {
          fn.context.params.set('items', () => results);
          fn.context.params.set('id', () => this.id);

          let items = (<any>await fn.fnCall()) as any[];
          let arraySource = (<any>this.component.option('dataSource')) as any[];
          items.forEach((g) => {
            (g.id = uuidv4()), arraySource.push(g);
          });
          this._values = arraySource;
          this.cancelRefresh = true;
          this.component.repaint();
          this.onChange(this._values);
          this.onTouched();
        }

        this.associatePopup = false;
      });
    }
  }

  /** Événement lorsque l'on réduit une branche */
  public onRowCollapsedTreeList(e) {
    this.treelistCollapsed(e, this.options);
  }

  /** Lorsque la fenêtre s'affiche, le dataSource est créé avec un nouveau filtre. */
  public show(e) {
    this.selectList.initModel();
    this.selectList.refresh();
  }
}
