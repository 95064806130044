import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCommentaryModelsBaseVariables, FirstCommentaryModelsBaseVariables, CountCommentaryModelsBaseVariables, FindCommentaryModelsBaseVariables, SearchCommentaryModelsBaseVariables, ExportSchemaCommentaryModelsBaseVariables, ExportDataCommentaryModelsBaseVariables, CustomQueryCommentaryModelsBaseVariables, CustomQueryIdCommentaryModelsBaseVariables, FindUnassociatedSecurityGroupsCommentaryModelsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsBaseVariables, UsedCommentaryModelsBaseVariables, ExistCommentaryModelsBaseVariables, InsertCommentaryModelsBaseVariables, UpdateCommentaryModelsBaseVariables, ImportDataCommentaryModelsBaseVariables, UpdateManyCommentaryModelsBaseVariables, DeleteCommentaryModelsBaseVariables, AddSecurityGroupCommentaryModelsBaseVariables, RemoveSecurityGroupCommentaryModelsBaseVariables, AddOperatorTeamSecurityGroupCommentaryModelsBaseVariables, RemoveOperatorTeamSecurityGroupCommentaryModelsBaseVariables } from '../gqls'
import { GetCommentaryModelsDocument, FirstCommentaryModelsDocument, CountCommentaryModelsDocument, FindCommentaryModelsDocument, SearchCommentaryModelsDocument, ExportSchemaCommentaryModelsDocument, ExportDataCommentaryModelsDocument, CustomQueryCommentaryModelsDocument, CustomQueryIdCommentaryModelsDocument, FindUnassociatedSecurityGroupsCommentaryModelsDocument, FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsDocument, UsedCommentaryModelsDocument, ExistCommentaryModelsDocument, InsertCommentaryModelsDocument, UpdateCommentaryModelsDocument, ImportDataCommentaryModelsDocument, UpdateManyCommentaryModelsDocument, DeleteCommentaryModelsDocument, AddSecurityGroupCommentaryModelsDocument, RemoveSecurityGroupCommentaryModelsDocument, AddOperatorTeamSecurityGroupCommentaryModelsDocument, RemoveOperatorTeamSecurityGroupCommentaryModelsDocument } from '../gqls'
import { ServiceSingleResultOfCommentaryModel, QueryContextOfCommentaryModel, FilterOfCommentaryModel, ServiceSingleResultOfInt64, ServiceListResultOfCommentaryModel, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, CommentaryModelInput, FieldUpdateOperatorOfCommentaryModel } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CommentaryModelBaseService {
    
public modelName = 'commentaryModel';
public modelPluralName = 'commentaryModels';

	private getCommentaryModelsQuery: GetCommentaryModelsDocument;
	private firstCommentaryModelsQuery: FirstCommentaryModelsDocument;
	private countCommentaryModelsQuery: CountCommentaryModelsDocument;
	private findCommentaryModelsQuery: FindCommentaryModelsDocument;
	private searchCommentaryModelsQuery: SearchCommentaryModelsDocument;
	private exportSchemaCommentaryModelsQuery: ExportSchemaCommentaryModelsDocument;
	private exportDataCommentaryModelsQuery: ExportDataCommentaryModelsDocument;
	private customQueryCommentaryModelsQuery: CustomQueryCommentaryModelsDocument;
	private customQueryIdCommentaryModelsQuery: CustomQueryIdCommentaryModelsDocument;
	private findUnassociatedSecurityGroupsCommentaryModelsQuery: FindUnassociatedSecurityGroupsCommentaryModelsDocument;
	private findAssociatedOperatorTeamSecurityGroupsCommentaryModelsQuery: FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsDocument;
	private usedCommentaryModelsQuery: UsedCommentaryModelsDocument;
	private existCommentaryModelsQuery: ExistCommentaryModelsDocument;
	private insertCommentaryModelsMutation: InsertCommentaryModelsDocument;
	private updateCommentaryModelsMutation: UpdateCommentaryModelsDocument;
	private importDataCommentaryModelsMutation: ImportDataCommentaryModelsDocument;
	private updateManyCommentaryModelsMutation: UpdateManyCommentaryModelsDocument;
	private deleteCommentaryModelsMutation: DeleteCommentaryModelsDocument;
	private addSecurityGroupCommentaryModelsMutation: AddSecurityGroupCommentaryModelsDocument;
	private removeSecurityGroupCommentaryModelsMutation: RemoveSecurityGroupCommentaryModelsDocument;
	private addOperatorTeamSecurityGroupCommentaryModelsMutation: AddOperatorTeamSecurityGroupCommentaryModelsDocument;
	private removeOperatorTeamSecurityGroupCommentaryModelsMutation: RemoveOperatorTeamSecurityGroupCommentaryModelsDocument;

	constructor(private injector: Injector) {
		this.getCommentaryModelsQuery = this.injector.get(GetCommentaryModelsDocument);
		this.firstCommentaryModelsQuery = this.injector.get(FirstCommentaryModelsDocument);
		this.countCommentaryModelsQuery = this.injector.get(CountCommentaryModelsDocument);
		this.findCommentaryModelsQuery = this.injector.get(FindCommentaryModelsDocument);
		this.searchCommentaryModelsQuery = this.injector.get(SearchCommentaryModelsDocument);
		this.exportSchemaCommentaryModelsQuery = this.injector.get(ExportSchemaCommentaryModelsDocument);
		this.exportDataCommentaryModelsQuery = this.injector.get(ExportDataCommentaryModelsDocument);
		this.customQueryCommentaryModelsQuery = this.injector.get(CustomQueryCommentaryModelsDocument);
		this.customQueryIdCommentaryModelsQuery = this.injector.get(CustomQueryIdCommentaryModelsDocument);
		this.findUnassociatedSecurityGroupsCommentaryModelsQuery = this.injector.get(FindUnassociatedSecurityGroupsCommentaryModelsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsCommentaryModelsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsDocument);
		this.usedCommentaryModelsQuery = this.injector.get(UsedCommentaryModelsDocument);
		this.existCommentaryModelsQuery = this.injector.get(ExistCommentaryModelsDocument);
		this.insertCommentaryModelsMutation = this.injector.get(InsertCommentaryModelsDocument);
		this.updateCommentaryModelsMutation = this.injector.get(UpdateCommentaryModelsDocument);
		this.importDataCommentaryModelsMutation = this.injector.get(ImportDataCommentaryModelsDocument);
		this.updateManyCommentaryModelsMutation = this.injector.get(UpdateManyCommentaryModelsDocument);
		this.deleteCommentaryModelsMutation = this.injector.get(DeleteCommentaryModelsDocument);
		this.addSecurityGroupCommentaryModelsMutation = this.injector.get(AddSecurityGroupCommentaryModelsDocument);
		this.removeSecurityGroupCommentaryModelsMutation = this.injector.get(RemoveSecurityGroupCommentaryModelsDocument);
		this.addOperatorTeamSecurityGroupCommentaryModelsMutation = this.injector.get(AddOperatorTeamSecurityGroupCommentaryModelsDocument);
		this.removeOperatorTeamSecurityGroupCommentaryModelsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupCommentaryModelsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommentaryModel> {
    
    		let variables: GetCommentaryModelsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.get));
            }
            else{
                let result:ServiceSingleResultOfCommentaryModel={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommentaryModel,
		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommentaryModel> {
    
    		let variables: FirstCommentaryModelsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCommentaryModelsBaseVariables = {
    			filter: filter
    		}
    				return this.countCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCommentaryModel,
		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommentaryModel> {
    
    		let variables: FindCommentaryModelsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCommentaryModel,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommentaryModel> {
    
    		let variables: SearchCommentaryModelsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCommentaryModelsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCommentaryModelsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCommentaryModel,
		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommentaryModel> {
    
    		let variables: CustomQueryCommentaryModelsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCommentaryModel,
		@Args('filter?') filter?: FilterOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCommentaryModel> {
    
    		let variables: CustomQueryIdCommentaryModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsCommentaryModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsCommentaryModelsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCommentaryModelsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCommentaryModelsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCommentaryModelsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CommentaryModelInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommentaryModel> {
    
    		let variables: InsertCommentaryModelsBaseVariables = {
    			entity: entity
    		}
    				return this.insertCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCommentaryModel> {
    
    		let variables: UpdateCommentaryModelsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCommentaryModelsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCommentaryModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCommentaryModelsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCommentaryModelsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupCommentaryModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupCommentaryModelsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupCommentaryModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupCommentaryModelsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupCommentaryModelsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.commentaryModels.removeOperatorTeamSecurityGroup));
    	}
    
}