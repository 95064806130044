import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOneEventLogsBaseVariables, FirstOneEventLogsBaseVariables, CountOneEventLogsBaseVariables, FindOneEventLogsBaseVariables, SearchOneEventLogsBaseVariables, ExportSchemaOneEventLogsBaseVariables, ExportDataOneEventLogsBaseVariables, CustomQueryOneEventLogsBaseVariables, CustomQueryIdOneEventLogsBaseVariables, UsedOneEventLogsBaseVariables, ExistOneEventLogsBaseVariables, InsertOneEventLogsBaseVariables, UpdateOneEventLogsBaseVariables, ImportDataOneEventLogsBaseVariables, UpdateManyOneEventLogsBaseVariables, DeleteOneEventLogsBaseVariables } from '../gqls'
import { GetOneEventLogsDocument, FirstOneEventLogsDocument, CountOneEventLogsDocument, FindOneEventLogsDocument, SearchOneEventLogsDocument, ExportSchemaOneEventLogsDocument, ExportDataOneEventLogsDocument, CustomQueryOneEventLogsDocument, CustomQueryIdOneEventLogsDocument, UsedOneEventLogsDocument, ExistOneEventLogsDocument, InsertOneEventLogsDocument, UpdateOneEventLogsDocument, ImportDataOneEventLogsDocument, UpdateManyOneEventLogsDocument, DeleteOneEventLogsDocument } from '../gqls'
import { ServiceSingleResultOfOneEventLog, QueryContextOfOneEventLog, FilterOfOneEventLog, ServiceSingleResultOfInt64, ServiceListResultOfOneEventLog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, OneEventLogInput, FieldUpdateOperatorOfOneEventLog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OneEventLogBaseService {
    
public modelName = 'oneEventLog';
public modelPluralName = 'oneEventLogs';

	private getOneEventLogsQuery: GetOneEventLogsDocument;
	private firstOneEventLogsQuery: FirstOneEventLogsDocument;
	private countOneEventLogsQuery: CountOneEventLogsDocument;
	private findOneEventLogsQuery: FindOneEventLogsDocument;
	private searchOneEventLogsQuery: SearchOneEventLogsDocument;
	private exportSchemaOneEventLogsQuery: ExportSchemaOneEventLogsDocument;
	private exportDataOneEventLogsQuery: ExportDataOneEventLogsDocument;
	private customQueryOneEventLogsQuery: CustomQueryOneEventLogsDocument;
	private customQueryIdOneEventLogsQuery: CustomQueryIdOneEventLogsDocument;
	private usedOneEventLogsQuery: UsedOneEventLogsDocument;
	private existOneEventLogsQuery: ExistOneEventLogsDocument;
	private insertOneEventLogsMutation: InsertOneEventLogsDocument;
	private updateOneEventLogsMutation: UpdateOneEventLogsDocument;
	private importDataOneEventLogsMutation: ImportDataOneEventLogsDocument;
	private updateManyOneEventLogsMutation: UpdateManyOneEventLogsDocument;
	private deleteOneEventLogsMutation: DeleteOneEventLogsDocument;

	constructor(private injector: Injector) {
		this.getOneEventLogsQuery = this.injector.get(GetOneEventLogsDocument);
		this.firstOneEventLogsQuery = this.injector.get(FirstOneEventLogsDocument);
		this.countOneEventLogsQuery = this.injector.get(CountOneEventLogsDocument);
		this.findOneEventLogsQuery = this.injector.get(FindOneEventLogsDocument);
		this.searchOneEventLogsQuery = this.injector.get(SearchOneEventLogsDocument);
		this.exportSchemaOneEventLogsQuery = this.injector.get(ExportSchemaOneEventLogsDocument);
		this.exportDataOneEventLogsQuery = this.injector.get(ExportDataOneEventLogsDocument);
		this.customQueryOneEventLogsQuery = this.injector.get(CustomQueryOneEventLogsDocument);
		this.customQueryIdOneEventLogsQuery = this.injector.get(CustomQueryIdOneEventLogsDocument);
		this.usedOneEventLogsQuery = this.injector.get(UsedOneEventLogsDocument);
		this.existOneEventLogsQuery = this.injector.get(ExistOneEventLogsDocument);
		this.insertOneEventLogsMutation = this.injector.get(InsertOneEventLogsDocument);
		this.updateOneEventLogsMutation = this.injector.get(UpdateOneEventLogsDocument);
		this.importDataOneEventLogsMutation = this.injector.get(ImportDataOneEventLogsDocument);
		this.updateManyOneEventLogsMutation = this.injector.get(UpdateManyOneEventLogsDocument);
		this.deleteOneEventLogsMutation = this.injector.get(DeleteOneEventLogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneEventLog> {
    
    		let variables: GetOneEventLogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.get));
            }
            else{
                let result:ServiceSingleResultOfOneEventLog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOneEventLog,
		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneEventLog> {
    
    		let variables: FirstOneEventLogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOneEventLogsBaseVariables = {
    			filter: filter
    		}
    				return this.countOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOneEventLog,
		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneEventLog> {
    
    		let variables: FindOneEventLogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOneEventLog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneEventLog> {
    
    		let variables: SearchOneEventLogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOneEventLogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOneEventLogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOneEventLog,
		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneEventLog> {
    
    		let variables: CustomQueryOneEventLogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOneEventLog,
		@Args('filter?') filter?: FilterOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOneEventLog> {
    
    		let variables: CustomQueryIdOneEventLogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOneEventLogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOneEventLogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOneEventLogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OneEventLogInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneEventLog> {
    
    		let variables: InsertOneEventLogsBaseVariables = {
    			entity: entity
    		}
    				return this.insertOneEventLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOneEventLog> {
    
    		let variables: UpdateOneEventLogsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOneEventLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOneEventLogsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOneEventLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOneEventLog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOneEventLogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOneEventLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOneEventLogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOneEventLogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.oneEventLogs.delete));
    	}
    
}