import {
  AfterViewInit,
  Component,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  ViewChildren,
} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  FormGroupDirective,
  NgControl,
  ValidationErrors,
} from '@angular/forms';
import { GC, GCFactory, WorkTimeConfigType } from '@clarilog/core';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { Subscription } from 'rxjs';
import { DxDateBoxComponent } from 'devextreme-angular/ui/date-box';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { FormGroupHelpers } from '../form';
import { InitializationCoreService } from '@clarilog/core/services2';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-work-time',
  templateUrl: './work-time.component.html',
  styleUrls: ['./work-time.component.scss'],
})
export class CoreWorkTimeComponent
  implements ControlValueAccessor, OnInit, AfterViewInit, OnDestroy
{
  /** Represente le modelState du formulaire */
  @Input() modelState: ModelState;
  /** Sauvegarde les valeurs. */
  _values: any;
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;
  /** Represente si on utilise le tick */
  @Input() useTicks: boolean = false;
  _initializeValue: boolean = false;
  _initializeComponent: boolean = false;
  _firstLoad: boolean = false;
  formChangeSubscriber: Subscription;

  days: any[];
  workTimeDayBoutons: any[] = [];
  workTimeDayComponents: any[] = [];
  workTimeConfig: WorkTimeConfigType = {};
  validationComponents: any;
  dateBoxComponents: any[] = [];
  gc: GC;
  @ViewChildren(DxDateBoxComponent) listCompo: QueryList<DxDateBoxComponent>;

  /** Obtient ou définit les valeurs. */
  get values(): any {
    if (this._values != undefined && this._values.length != 0) {
      delete this._values['__typename'];
      this.days.forEach(function (day) {
        let d = this._values[day.id];
        delete d['__typename'];
        if (d.times != null) {
          d.times.forEach(function (time) {
            delete time['__typename'];
          }, this);
        }
      }, this);
    } else {
      this._values = {};
      this.days.forEach(function (day) {
        this._values[day.id] = { enabled: false, times: [] };
      }, this);
    }

    return this._values;
  }

  set values(values: any) {
    this._values = values;
    if (this._initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  constructor(
    private formGroupDirective: FormGroupDirective,
    private _gcFactory: GCFactory,
    private controlDirective: NgControl,
  ) {
    this.gc = _gcFactory.create();
    controlDirective.valueAccessor = this;
    this._initializeComponent == false;
    this.days = [
      {
        id: 'monday',
        value: TranslateService.get('entities/workTime/days/monday'),
      },
      {
        id: 'tuesday',
        value: TranslateService.get('entities/workTime/days/tuesday'),
      },
      {
        id: 'wednesday',
        value: TranslateService.get('entities/workTime/days/wednesday'),
      },
      {
        id: 'thursday',
        value: TranslateService.get('entities/workTime/days/thursday'),
      },
      {
        id: 'friday',
        value: TranslateService.get('entities/workTime/days/friday'),
      },
      {
        id: 'saturday',
        value: TranslateService.get('entities/workTime/days/saturday'),
      },
      {
        id: 'sunday',
        value: TranslateService.get('entities/workTime/days/sunday'),
      },
    ];
    for (var i = 0; i < this.days.length; i++) {
      var tab = this.days[i].value.split('/days/');
    }
  }

  ngAfterViewInit(): void {
    this.listCompo.forEach((el) => {
      this.gc.forRelease(
        el.isValidChange.subscribe((res) => {
          this.removeInvalidBadge();
        }),
      );
    });
  }

  /** @inheritdoc */
  writeValue(values: any): void {
    this._initializeValue = true;
    this.values = values;
    this._values = values;
    if (values != undefined) {
      this._originalValues = JSON.parse(JSON.stringify(values));
    }
    this._initializeValue = false;
  }

  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  removeInvalidBadge() {
    if (this.listCompo != undefined) {
      this.listCompo.forEach((element) => {
        if (
          (element as any).element.nativeElement.classList.contains(
            'dx-show-invalid-badge',
          )
        ) {
          (element as any).element.nativeElement.classList.remove(
            'dx-show-invalid-badge',
          );
        }
      });
    }
  }

  /** @inheritdoc */
  async ngOnInit() {
    this.controlDirective.control.setValidators([this.isValid.bind(this)]);
    //  this.controlDirective.control.updateValueAndValidity();

    this.formChangeSubscriber = FormGroupHelpers.onFormLoaded(
      this.modelState,
    ).subscribe((res) => {
      if (res == true) {
        let configWorkTime = FormGroupHelpers.formControlByName(
          this.modelState.form,
          'config',
        );

        // if (this._firstLoad == false) {
        this.initValue();
        // }

        if (configWorkTime != undefined || configWorkTime != null) {
          configWorkTime.reset(this._originalValues);
          this.removeInvalidBadge();
        }
      }
    });

    this._initializeComponent = true;
  }

  async initialized(e) {
    this._initializeComponent = true;
  }

  initValue() {
    if (this._values == undefined || this._values.length == 0) {
      this._values = {};
      this.days.forEach(function (day) {
        this._values[day.id] = { enabled: false, times: [] };
      }, this);
      this.values = this._values;
    } else {
      this.days.forEach(function (day) {
        let d = this._values[day.id];
        if (d.enabled == true) {
          let btn = this.workTimeDayBoutons.find(
            (x) => x.element().id == day.id,
          );
          if (btn != undefined) {
            btn.option('stylingMode', 'Contained');
          }
          this.changeStateDateBoxByDay(day.id, false);
        }

        if (d.times != null) {
          let index = 1;
          d.times.forEach(function (time) {
            let startCmp = this.dateBoxComponents.find(
              (x) => x.element().id == day.id + '_' + index,
            );
            let indexNext: number = index + 1;
            let endCmp = this.dateBoxComponents.find(
              (x) => x.element().id == day.id + '_' + indexNext,
            );
            if (
              startCmp != undefined &&
              time.start != null &&
              time.start != '0001-01-01T00:00:00Z'
            ) {
              startCmp.option(
                'value',
                this.useTicks
                  ? this.getDateFromTicks(time.startTick)
                  : time.start,
              );
            }
            if (
              endCmp != undefined &&
              time.end != null &&
              time.end != '0001-01-01T00:00:00Z'
            ) {
              endCmp.option(
                'value',
                this.useTicks ? this.getDateFromTicks(time.endTick) : time.end,
              );
            }

            index = Number(index) + 2;
          }, this);
        }
      }, this);
    }
    this._firstLoad = true;
  }

  clickBtn(e) {
    let style = e.component.option('stylingMode');
    let id = e.element.id;
    if (this._values == undefined || this._values.length == 0) {
      this._values = {};
      this.days.forEach(function (day) {
        this._values[day.id] = { enabled: false, times: [] };
      }, this);
    }
    if (style == 'outlined') {
      e.component.option('stylingMode', 'Contained');
      this.changeStateDateBoxByDay(id, false);
      this._values[id].enabled = true;
    } else {
      e.component.option('stylingMode', 'outlined');
      this.changeStateDateBoxByDay(id, true);
      this._values[id].enabled = false;
      this._values[id].times = [];
      this.values = this._values;

      this.dateBoxComponents
        .find((x) => x.element().id == id + '_' + 1)
        .reset();
      this.dateBoxComponents
        .find((x) => x.element().id == id + '_' + 2)
        .reset();
      this.dateBoxComponents
        .find((x) => x.element().id == id + '_' + 3)
        .reset();

      this.dateBoxComponents
        .find((x) => x.element().id == id + '_' + 4)
        .reset();

      // Reforce la valeur a vide
      this._values[id].times = [];
    }

    this.onChange(this._values);
    this.onTouched();
  }

  changeStateDateBoxByDay(id: any, state: boolean) {
    for (let index = 1; index <= 4; index++) {
      let timeCmp = this.dateBoxComponents.find(
        (x) => x.element().id == id + '_' + index,
      );
      if (timeCmp != undefined) {
        timeCmp.option('disabled', state);
      }
    }
  }

  initializeBtn(e) {
    this.workTimeDayBoutons.push(e.component);
    this.initialize();
  }

  initializeBox(e) {
    this.workTimeDayComponents.push(e.component);
    this.initialize();
  }

  initializeDateBox(e) {
    this.dateBoxComponents.push(e.component);
    this.initialize();
  }

  initialize() {
    if (
      this.workTimeDayBoutons.length == 7 &&
      this.workTimeDayComponents.length == 7 &&
      this.dateBoxComponents.length == 28
    ) {
      this._initializeComponent = true;
      if (this._values != undefined) {
        this.initValue();
      } else {
        this._values = {};
        this.days.forEach(function (day) {
          this._values[day.id] = { enabled: false, times: [] };
        }, this);
        this.values = this._values;
        this._firstLoad = true;
      }
    }
  }

  isValid(control: AbstractControl): ValidationErrors | null {
    let isValid =
      this.dateBoxComponents.findIndex((x) => x.valid == false) == -1;
    if (!isValid) {
      return { invalidMsg: 'Valeur invalide' };
    }
    return null;
  }

  validateTimeCallback() {
    return (e) => {
      let elementId = e.validator.element().id;
      let index = elementId.substr(elementId.length - 1);
      let compareIndex = index - 1;
      if (index == 1) {
        compareIndex = Number(index) + 1;
      }

      let component = this.dateBoxComponents.find(
        (x) =>
          (x as any).element().id == elementId.replace(index, compareIndex),
      );
      let valueCompare = component.option('value');
      if (index == 3) {
        let componentNext = this.dateBoxComponents.find(
          (x) => x.element().id == elementId.replace(index, 4),
        );
        let valueNext = componentNext.option('value');
        if (valueNext != null) {
          if (e.value == null || (e.value != null && e.value > valueNext)) {
            e.rule.message = TranslateService.get(
              'entities/workTime/timeInferior',
            );
            component.valid = false;
            return false;
          }

          let componentPrevious = this.dateBoxComponents.find(
            (x) => x.element().id == elementId.replace(index, 2),
          );
          let valuePrevious = componentPrevious.option('value');
          if (valuePrevious != null) {
            if (typeof valuePrevious == 'string') {
              valuePrevious = new Date(valuePrevious);
            }
            let timePrevious = parseFloat(
              valuePrevious.getUTCHours() + '.' + valuePrevious.getUTCMinutes(),
            );
            if (typeof e.value == 'string') {
              e.value = new Date(valuePrevious);
            }
            let time = parseFloat(
              e.value.getUTCHours() + '.' + e.value.getUTCMinutes(),
            );
            if (timePrevious > time) {
              e.rule.message = TranslateService.get(
                'entities/workTime/timePrevious',
              );
              component.valid = false;
              return false;
            }
          }
        } else {
          if (e.value != null) {
            e.rule.message = TranslateService.get(
              'entities/workTime/timeInferior',
            );
            component.valid = false;
            return false;
          }
        }
        component.valid = true;
        return true;
      }
      if (index == 4) {
        let componentPrevious = this.dateBoxComponents.find(
          (x) => x.element().id == elementId.replace(index, 3),
        );
        let valuePrevious = componentPrevious.option('value');
        if (valuePrevious != null) {
          if (e.value == null || (e.value != null && e.value < valuePrevious)) {
            e.rule.message = TranslateService.get(
              'entities/workTime/timeInferior',
            );
            component.valid = false;
            return false;
          }
        }
        component.valid = true;
        return true;
      }

      if (e.value == null && valueCompare == null) {
        component.valid = true;
        return true;
      }

      if (valueCompare == null) {
        component.valid = false;
        return false;
      }
      let isValid;
      let dateValue = new Date(e.value);
      let dateCompare = new Date(valueCompare);
      if (index == 1 || index == 3) {
        isValid = dateValue < dateCompare;
        if (isValid == false) {
          e.rule.message = TranslateService.get(
            'entities/workTime/timeSuperior',
          );
        }
      } else {
        isValid = dateValue > dateCompare;
        if (isValid == false)
          e.rule.message = TranslateService.get(
            'entities/workTime/timeInferior',
          );
      }

      component.valid = isValid;
      return isValid;
    };
  }

  valueTimeChanged(e) {
    if (this._firstLoad == true) {
      let newValue = e.value;
      let index = e.element.id.substr(e.element.id.length - 1);
      var idDay = e.element.id.replace('_' + index, '');
      if (this._values[idDay].times == null) {
        this._values[idDay].times = [];
      }

      let indexTime = index;
      if (index % 2 == 0) {
        indexTime = index / 2;
        indexTime = indexTime - 1;
        if (this._values[idDay].times[indexTime] == null) {
          this._values[idDay].times[indexTime] = {
            start: newValue,
            end: newValue,
          };
          if (this.useTicks) {
            this._values[idDay].times[indexTime].startTick =
              this.getTicks(newValue);
            this._values[idDay].times[indexTime].endTick =
              this.getTicks(newValue);
          }
        } else {
          this._values[idDay].times[indexTime].end = newValue;
          if (this.useTicks) {
            this._values[idDay].times[indexTime].endTick =
              this.getTicks(newValue);
          }
        }
      } else {
        indexTime = Number(index) + Number(1);
        indexTime = indexTime / 2;
        indexTime = indexTime - 1;
        if (this._values[idDay].times[indexTime] == null) {
          this._values[idDay].times[indexTime] = {
            start: newValue,
            end: null,
          };
          if (this.useTicks) {
            this._values[idDay].times[indexTime].startTick =
              this.getTicks(newValue);
          }
        } else {
          this._values[idDay].times[indexTime].start = newValue;
          if (this.useTicks) {
            this._values[idDay].times[indexTime].startTick =
              this.getTicks(newValue);
          }
        }
      }

      if (
        this._values[idDay].times[indexTime].start == null &&
        this._values[idDay].times[indexTime].end == null
      ) {
        this._values[idDay].times.splice(indexTime);
      }

      setTimeout(() => {
        this.onChange(this._values);
        this.onTouched();
      }, 200);
    }
  }

  //** Permet de copier la plage horraire sur les autres jours */
  copyTime(e) {
    this.days.forEach(function (day) {
      if (day.id != 'monday') {
        for (let index = 1; index <= 4; index++) {
          let timeCmp = this.dateBoxComponents.find(
            (x) => x.element().id == day.id + '_' + index,
          );
          let state = timeCmp.option('disabled');
          if (!state && timeCmp.option('value') == null) {
            let mondayTimeCmp = this.dateBoxComponents.find(
              (x) => x.element().id == 'monday_' + index,
            );
            timeCmp.option('value', mondayTimeCmp.option('value'));
          }
        }
      }
    }, this);

    setTimeout(() => {
      this.onChange(this._values);
      this.onTouched();
    }, 200);
  }

  ngOnDestroy() {
    if (this.formChangeSubscriber != undefined) {
      this.formChangeSubscriber.unsubscribe();
    }
  }

  getTicks(value) {
    if (value == null) return null;
    return (value.getHours() * 60 + value.getMinutes()) * 600000000;
  }

  getDateFromTicks(ticks) {
    if (ticks == null) return null;
    let ticksToMicrotime = ticks / 10000;
    //ticks are recorded from 1/1/1; get microtime difference from 1/1/1/ to 1/1/1970
    let epochMicrotimeDiff = 2208988800000;
    //new date is ticks, converted to microtime, minus difference from epoch microtime
    var tickDate = new Date(ticksToMicrotime - epochMicrotimeDiff);
    let hours = tickDate.getUTCHours();
    let minutes = tickDate.getUTCMinutes();
    return new Date('1970-01-01 ' + hours + ':' + minutes);
  }
}
