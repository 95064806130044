import { NgModule } from '@angular/core';
import { InMemoryCache } from '@apollo/client/cache';
import { setContext } from '@apollo/client/link/context';
import { onError } from '@apollo/client/link/error';
import { AuthentificationService, EnvironmentService } from '@clarilog/core';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';
import { APOLLO_NAMED_OPTIONS, ApolloModule } from 'apollo-angular';
import { HttpLink } from 'apollo-angular/http';

/**
 * En cours de développement - Permet de créer la connexion sécurisé à l'api graphql.
 *
 * @export
 * @param {HttpLink} httpLink Le service de lien
 * @param {AuthService} authService Le service d'authentification
 * @returns retourne les options de graphql
 */
const endpointV1 = '/graphql';
const endpointV2 = '/graphql/v2';
export function createApollo(
  endpoint: string,
  httpLink: HttpLink,
  authentificationService: AuthentificationService,
  envService: EnvironmentService,
  translatedFieldHelperService: TranslatedFieldHelperService,
) {
  const uri = envService.apiURL + endpoint;

  const http = httpLink.create({ uri: uri });

  const auth = setContext((_, { headers }) => {
    let additionalHeaders = {
      ...headers,
      Authorization: authentificationService.getAuthorizationHeaderValue(),
    };

    //Gestion des invitation pour les passer dans le header
    if (localStorage.getItem('invite_token') != undefined) {
      additionalHeaders = {
        ...additionalHeaders,
        inviteToken: localStorage.getItem('invite_token'),
      };
      localStorage.removeItem('invite_token');
    }

    // Gestion du fuseaux horaire client
    let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    let timezoneOffset = Math.abs(new Date().getTimezoneOffset());
    let offsetHours = Math.floor(timezoneOffset / 60);
    additionalHeaders = {
      ...additionalHeaders,
      'C-TimeZone': timeZone,
      'C-TimeOffset': offsetHours,
      'C-Url': window.location.href,
      'C-DisplayMode': localStorage.getItem('displayMode'),
      'C-Language': translatedFieldHelperService.getTranslateKey(),
    };

    let useExportMode = localStorage.getItem('UseExportMode');
    if (useExportMode != undefined && useExportMode === 'true') {
      localStorage.removeItem('UseExportMode');
      additionalHeaders = { ...additionalHeaders, 'C-UseExportMode': 'true' };
    }

    return {
      headers: {
        ...additionalHeaders,
      },
    };
  });
  const error = onError(({ graphQLErrors, networkError }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) => {
        // if (message.startsWith("Clarilog.One.GraphQL.Mutations.Internal.LicenseError: ")) {
        //   alert(message.replace("Clarilog.One.GraphQL.Mutations.Internal.LicenseError: ", ""));
        // }
        // else {
        console.error({
          message: message,
          locations: locations,
          path: path,
        });
        // }
      });
    }
    if (networkError) {
      console.error(networkError);
      if (networkError['status'] === 0 || networkError['status'] === 503) {
        console.log(envService.isDev);
        if (
          window.location.search.includes('alreadyReload') === false &&
          envService.isDev === false
        ) {
          const port = window.location.port;

          location.href =
            window.location.protocol +
            '//' +
            window.location.hostname +
            ':' +
            port +
            window.location.pathname +
            '?alreadyReload=true';
        }
      }
    }
  });

  const link = error.concat(auth).concat(http);

  let defaultOptions = {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
  };
  // const fragmentMatcher = new IntrospectionFragmentMatcher({
  //   introspectionQueryResultData: introspectionResult,
  // });
  return {
    link: link,
    defaultOptions: defaultOptions,
    cache: new InMemoryCache(),
  };

  // return {
  //   link: link,
  //   cache: new InMemoryCache({
  //     fragmentMatcher,
  //   }),
  //   defaultOptions: defaultOptions,
  // };
}

export function createNamedApollo(
  httpLink: HttpLink,
  authentificationService: AuthentificationService,
  envService: EnvironmentService,
  translatedFieldHelperService: TranslatedFieldHelperService,
) {
  let result = createApollo(
    endpointV2,
    httpLink,
    authentificationService,
    envService,
    translatedFieldHelperService,
  );

  result['name'] = 'v2';
  return {
    v2: result,
  };
}

/**
 * Permet de gérer les requêtes graphql
 *
 * @export
 * @class GraphQLModule
 */
@NgModule({
  exports: [ApolloModule],
  providers: [
    // {
    //   provide: APOLLO_OPTIONS,
    //   useFactory: (
    //     httpLink: HttpLink,
    //     authentificationService: AuthentificationService,
    //     envService: EnvironmentService,
    //   ) => {
    //     return createApollo(
    //       endpointV1,
    //       httpLink,
    //       authentificationService,
    //       envService,
    //     );
    //   },
    //   deps: [
    //     HttpLink,
    //     AuthentificationService,
    //     EnvironmentService,
    //   ],
    // },
    {
      provide: APOLLO_NAMED_OPTIONS,
      deps: [
        HttpLink,
        AuthentificationService,
        EnvironmentService,
        TranslatedFieldHelperService,
      ],
      useFactory: (
        httpLink: HttpLink,
        authentificationService: AuthentificationService,
        envService: EnvironmentService,
        translatedFieldHelperService: TranslatedFieldHelperService,
      ) => {
        let result = createApollo(
          endpointV2,
          httpLink,
          authentificationService,
          envService,
          translatedFieldHelperService,
        );

        result['name'] = 'v2';
        return {
          v2: result,
        };
      },
    },
  ],
})
export class GraphQLModule {
  constructor() {}
}
