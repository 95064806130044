import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAccountsBaseVariables, FirstAccountsBaseVariables, CountAccountsBaseVariables, FindAccountsBaseVariables, SearchAccountsBaseVariables, ExportSchemaAccountsBaseVariables, ExportDataAccountsBaseVariables, CustomQueryAccountsBaseVariables, CustomQueryIdAccountsBaseVariables, UsedAccountsBaseVariables, FindPermissionScopesAccountsBaseVariables, FindAvailableMembersOfAccountsBaseVariables, FindMembersOfAccountsBaseVariables, ExistAccountsBaseVariables, FindRecyclesAccountsBaseVariables, CountRecyclesAccountsBaseVariables, ReadOnlyAccountsBaseVariables, FindArchivedAccountsBaseVariables, CountAllAccountsBaseVariables, FindDynamicPropertyFieldsAccountsBaseVariables, UpdateManyAccountsBaseVariables, DeleteAccountsBaseVariables, AddMembersOfAccountsBaseVariables, RemoveMembersOfAccountsBaseVariables, RestoreAccountsBaseVariables, RecycleAccountsBaseVariables, RestoreArchivedAccountsBaseVariables, ArchivedAccountsBaseVariables, AddFileDynamicFieldAccountsBaseVariables, RemoveFileDynamicFieldAccountsBaseVariables } from '../gqls'
import { GetAccountsDocument, FirstAccountsDocument, CountAccountsDocument, FindAccountsDocument, SearchAccountsDocument, ExportSchemaAccountsDocument, ExportDataAccountsDocument, CustomQueryAccountsDocument, CustomQueryIdAccountsDocument, UsedAccountsDocument, FindPermissionScopesAccountsDocument, FindAvailableMembersOfAccountsDocument, FindMembersOfAccountsDocument, ExistAccountsDocument, FindRecyclesAccountsDocument, CountRecyclesAccountsDocument, ReadOnlyAccountsDocument, FindArchivedAccountsDocument, CountAllAccountsDocument, FindDynamicPropertyFieldsAccountsDocument, UpdateManyAccountsDocument, DeleteAccountsDocument, AddMembersOfAccountsDocument, RemoveMembersOfAccountsDocument, RestoreAccountsDocument, RecycleAccountsDocument, RestoreArchivedAccountsDocument, ArchivedAccountsDocument, AddFileDynamicFieldAccountsDocument, RemoveFileDynamicFieldAccountsDocument } from '../gqls'
import { ServiceSingleResultOfAccount, QueryContextOfAccount, FilterOfAccount, ServiceSingleResultOfInt64, ServiceListResultOfAccount, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfPermissionScope, ServiceListResultOfRole, QueryContextOfRole, FilterOfRole, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfAccount } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AccountBaseService {
    
public modelName = 'account';
public modelPluralName = 'accounts';

	private getAccountsQuery: GetAccountsDocument;
	private firstAccountsQuery: FirstAccountsDocument;
	private countAccountsQuery: CountAccountsDocument;
	private findAccountsQuery: FindAccountsDocument;
	private searchAccountsQuery: SearchAccountsDocument;
	private exportSchemaAccountsQuery: ExportSchemaAccountsDocument;
	private exportDataAccountsQuery: ExportDataAccountsDocument;
	private customQueryAccountsQuery: CustomQueryAccountsDocument;
	private customQueryIdAccountsQuery: CustomQueryIdAccountsDocument;
	private usedAccountsQuery: UsedAccountsDocument;
	private findPermissionScopesAccountsQuery: FindPermissionScopesAccountsDocument;
	private findAvailableMembersOfAccountsQuery: FindAvailableMembersOfAccountsDocument;
	private findMembersOfAccountsQuery: FindMembersOfAccountsDocument;
	private existAccountsQuery: ExistAccountsDocument;
	private findRecyclesAccountsQuery: FindRecyclesAccountsDocument;
	private countRecyclesAccountsQuery: CountRecyclesAccountsDocument;
	private readOnlyAccountsQuery: ReadOnlyAccountsDocument;
	private findArchivedAccountsQuery: FindArchivedAccountsDocument;
	private countAllAccountsQuery: CountAllAccountsDocument;
	private findDynamicPropertyFieldsAccountsQuery: FindDynamicPropertyFieldsAccountsDocument;
	private updateManyAccountsMutation: UpdateManyAccountsDocument;
	private deleteAccountsMutation: DeleteAccountsDocument;
	private addMembersOfAccountsMutation: AddMembersOfAccountsDocument;
	private removeMembersOfAccountsMutation: RemoveMembersOfAccountsDocument;
	private restoreAccountsMutation: RestoreAccountsDocument;
	private recycleAccountsMutation: RecycleAccountsDocument;
	private restoreArchivedAccountsMutation: RestoreArchivedAccountsDocument;
	private archivedAccountsMutation: ArchivedAccountsDocument;
	private addFileDynamicFieldAccountsMutation: AddFileDynamicFieldAccountsDocument;
	private removeFileDynamicFieldAccountsMutation: RemoveFileDynamicFieldAccountsDocument;

	constructor(private injector: Injector) {
		this.getAccountsQuery = this.injector.get(GetAccountsDocument);
		this.firstAccountsQuery = this.injector.get(FirstAccountsDocument);
		this.countAccountsQuery = this.injector.get(CountAccountsDocument);
		this.findAccountsQuery = this.injector.get(FindAccountsDocument);
		this.searchAccountsQuery = this.injector.get(SearchAccountsDocument);
		this.exportSchemaAccountsQuery = this.injector.get(ExportSchemaAccountsDocument);
		this.exportDataAccountsQuery = this.injector.get(ExportDataAccountsDocument);
		this.customQueryAccountsQuery = this.injector.get(CustomQueryAccountsDocument);
		this.customQueryIdAccountsQuery = this.injector.get(CustomQueryIdAccountsDocument);
		this.usedAccountsQuery = this.injector.get(UsedAccountsDocument);
		this.findPermissionScopesAccountsQuery = this.injector.get(FindPermissionScopesAccountsDocument);
		this.findAvailableMembersOfAccountsQuery = this.injector.get(FindAvailableMembersOfAccountsDocument);
		this.findMembersOfAccountsQuery = this.injector.get(FindMembersOfAccountsDocument);
		this.existAccountsQuery = this.injector.get(ExistAccountsDocument);
		this.findRecyclesAccountsQuery = this.injector.get(FindRecyclesAccountsDocument);
		this.countRecyclesAccountsQuery = this.injector.get(CountRecyclesAccountsDocument);
		this.readOnlyAccountsQuery = this.injector.get(ReadOnlyAccountsDocument);
		this.findArchivedAccountsQuery = this.injector.get(FindArchivedAccountsDocument);
		this.countAllAccountsQuery = this.injector.get(CountAllAccountsDocument);
		this.findDynamicPropertyFieldsAccountsQuery = this.injector.get(FindDynamicPropertyFieldsAccountsDocument);
		this.updateManyAccountsMutation = this.injector.get(UpdateManyAccountsDocument);
		this.deleteAccountsMutation = this.injector.get(DeleteAccountsDocument);
		this.addMembersOfAccountsMutation = this.injector.get(AddMembersOfAccountsDocument);
		this.removeMembersOfAccountsMutation = this.injector.get(RemoveMembersOfAccountsDocument);
		this.restoreAccountsMutation = this.injector.get(RestoreAccountsDocument);
		this.recycleAccountsMutation = this.injector.get(RecycleAccountsDocument);
		this.restoreArchivedAccountsMutation = this.injector.get(RestoreArchivedAccountsDocument);
		this.archivedAccountsMutation = this.injector.get(ArchivedAccountsDocument);
		this.addFileDynamicFieldAccountsMutation = this.injector.get(AddFileDynamicFieldAccountsDocument);
		this.removeFileDynamicFieldAccountsMutation = this.injector.get(RemoveFileDynamicFieldAccountsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAccount> {
    
    		let variables: GetAccountsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.get));
            }
            else{
                let result:ServiceSingleResultOfAccount={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAccount> {
    
    		let variables: FirstAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: FindAccountsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAccount,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: SearchAccountsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAccountsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: CustomQueryAccountsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: CustomQueryIdAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.usedAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.used));
    	}

    	/**  */
    	@InjectArgs
    	public findPermissionScopes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfIds') memberOfIds: Array<string>,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPermissionScope> {
    
    		let variables: FindPermissionScopesAccountsBaseVariables = {
    			id: id,
			memberOfIds: memberOfIds
    		}
    				return this.findPermissionScopesAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findPermissionScopes));
    	}

    	/**  */
    	@InjectArgs
    	public findAvailableMembersOf(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfIds') memberOfIds: Array<string>,
		@Args('options?') options?: QueryContextOfRole,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindAvailableMembersOfAccountsBaseVariables = {
    			id: id,
			memberOfIds: memberOfIds,
			filter: filter,
			options: options
    		}
    				return this.findAvailableMembersOfAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findAvailableMembersOf));
    	}

    	/**  */
    	@InjectArgs
    	public findMembersOf(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfRole,
		@Args('filter?') filter?: FilterOfRole,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfRole> {
    
    		let variables: FindMembersOfAccountsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findMembersOfAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findMembersOf));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAccountsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: FindRecyclesAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyAccountsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAccount,
		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAccount> {
    
    		let variables: FindArchivedAccountsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllAccountsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsAccountsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsAccountsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.findDynamicPropertyFields));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAccount,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAccountsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addMembersOf(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfIds') memberOfIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddMembersOfAccountsBaseVariables = {
    			id: id,
			memberOfIds: memberOfIds
    		}
    				return this.addMembersOfAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.addMembersOf));
    	}

    	/**  */
    	@InjectArgs
    	public removeMembersOf(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('memberOfIds') memberOfIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveMembersOfAccountsBaseVariables = {
    			id: id,
			memberOfIds: memberOfIds
    		}
    				return this.removeMembersOfAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.removeMembersOf));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedAccountsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldAccountsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldAccountsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldAccountsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.accounts.removeFileDynamicField));
    	}
    
}