import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCustomFieldsBaseVariables, FirstCustomFieldsBaseVariables, CountCustomFieldsBaseVariables, FindCustomFieldsBaseVariables, SearchCustomFieldsBaseVariables, ExportSchemaCustomFieldsBaseVariables, ExportDataCustomFieldsBaseVariables, CustomQueryCustomFieldsBaseVariables, CustomQueryIdCustomFieldsBaseVariables, UsedCustomFieldsBaseVariables, ExistCustomFieldsBaseVariables, FindRecyclesCustomFieldsBaseVariables, CountRecyclesCustomFieldsBaseVariables, ReadOnlyCustomFieldsBaseVariables, FindArchivedCustomFieldsBaseVariables, CountAllCustomFieldsBaseVariables, FindDynamicPropertyFieldsCustomFieldsBaseVariables, InsertCustomFieldsBaseVariables, UpdateCustomFieldsBaseVariables, ImportDataCustomFieldsBaseVariables, UpdateManyCustomFieldsBaseVariables, DeleteCustomFieldsBaseVariables, RestoreCustomFieldsBaseVariables, RecycleCustomFieldsBaseVariables, RestoreArchivedCustomFieldsBaseVariables, ArchivedCustomFieldsBaseVariables, AddFileDynamicFieldCustomFieldsBaseVariables, RemoveFileDynamicFieldCustomFieldsBaseVariables } from '../gqls'
import { GetCustomFieldsDocument, FirstCustomFieldsDocument, CountCustomFieldsDocument, FindCustomFieldsDocument, SearchCustomFieldsDocument, ExportSchemaCustomFieldsDocument, ExportDataCustomFieldsDocument, CustomQueryCustomFieldsDocument, CustomQueryIdCustomFieldsDocument, UsedCustomFieldsDocument, ExistCustomFieldsDocument, FindRecyclesCustomFieldsDocument, CountRecyclesCustomFieldsDocument, ReadOnlyCustomFieldsDocument, FindArchivedCustomFieldsDocument, CountAllCustomFieldsDocument, FindDynamicPropertyFieldsCustomFieldsDocument, InsertCustomFieldsDocument, UpdateCustomFieldsDocument, ImportDataCustomFieldsDocument, UpdateManyCustomFieldsDocument, DeleteCustomFieldsDocument, RestoreCustomFieldsDocument, RecycleCustomFieldsDocument, RestoreArchivedCustomFieldsDocument, ArchivedCustomFieldsDocument, AddFileDynamicFieldCustomFieldsDocument, RemoveFileDynamicFieldCustomFieldsDocument } from '../gqls'
import { ServiceSingleResultOfCustomField, QueryContextOfCustomField, FilterOfCustomField, ServiceSingleResultOfInt64, ServiceListResultOfCustomField, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfCustomField, CustomFieldInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CustomFieldBaseService {
    
public modelName = 'customField';
public modelPluralName = 'customFields';

	private getCustomFieldsQuery: GetCustomFieldsDocument;
	private firstCustomFieldsQuery: FirstCustomFieldsDocument;
	private countCustomFieldsQuery: CountCustomFieldsDocument;
	private findCustomFieldsQuery: FindCustomFieldsDocument;
	private searchCustomFieldsQuery: SearchCustomFieldsDocument;
	private exportSchemaCustomFieldsQuery: ExportSchemaCustomFieldsDocument;
	private exportDataCustomFieldsQuery: ExportDataCustomFieldsDocument;
	private customQueryCustomFieldsQuery: CustomQueryCustomFieldsDocument;
	private customQueryIdCustomFieldsQuery: CustomQueryIdCustomFieldsDocument;
	private usedCustomFieldsQuery: UsedCustomFieldsDocument;
	private existCustomFieldsQuery: ExistCustomFieldsDocument;
	private findRecyclesCustomFieldsQuery: FindRecyclesCustomFieldsDocument;
	private countRecyclesCustomFieldsQuery: CountRecyclesCustomFieldsDocument;
	private readOnlyCustomFieldsQuery: ReadOnlyCustomFieldsDocument;
	private findArchivedCustomFieldsQuery: FindArchivedCustomFieldsDocument;
	private countAllCustomFieldsQuery: CountAllCustomFieldsDocument;
	private findDynamicPropertyFieldsCustomFieldsQuery: FindDynamicPropertyFieldsCustomFieldsDocument;
	private insertCustomFieldsMutation: InsertCustomFieldsDocument;
	private updateCustomFieldsMutation: UpdateCustomFieldsDocument;
	private importDataCustomFieldsMutation: ImportDataCustomFieldsDocument;
	private updateManyCustomFieldsMutation: UpdateManyCustomFieldsDocument;
	private deleteCustomFieldsMutation: DeleteCustomFieldsDocument;
	private restoreCustomFieldsMutation: RestoreCustomFieldsDocument;
	private recycleCustomFieldsMutation: RecycleCustomFieldsDocument;
	private restoreArchivedCustomFieldsMutation: RestoreArchivedCustomFieldsDocument;
	private archivedCustomFieldsMutation: ArchivedCustomFieldsDocument;
	private addFileDynamicFieldCustomFieldsMutation: AddFileDynamicFieldCustomFieldsDocument;
	private removeFileDynamicFieldCustomFieldsMutation: RemoveFileDynamicFieldCustomFieldsDocument;

	constructor(private injector: Injector) {
		this.getCustomFieldsQuery = this.injector.get(GetCustomFieldsDocument);
		this.firstCustomFieldsQuery = this.injector.get(FirstCustomFieldsDocument);
		this.countCustomFieldsQuery = this.injector.get(CountCustomFieldsDocument);
		this.findCustomFieldsQuery = this.injector.get(FindCustomFieldsDocument);
		this.searchCustomFieldsQuery = this.injector.get(SearchCustomFieldsDocument);
		this.exportSchemaCustomFieldsQuery = this.injector.get(ExportSchemaCustomFieldsDocument);
		this.exportDataCustomFieldsQuery = this.injector.get(ExportDataCustomFieldsDocument);
		this.customQueryCustomFieldsQuery = this.injector.get(CustomQueryCustomFieldsDocument);
		this.customQueryIdCustomFieldsQuery = this.injector.get(CustomQueryIdCustomFieldsDocument);
		this.usedCustomFieldsQuery = this.injector.get(UsedCustomFieldsDocument);
		this.existCustomFieldsQuery = this.injector.get(ExistCustomFieldsDocument);
		this.findRecyclesCustomFieldsQuery = this.injector.get(FindRecyclesCustomFieldsDocument);
		this.countRecyclesCustomFieldsQuery = this.injector.get(CountRecyclesCustomFieldsDocument);
		this.readOnlyCustomFieldsQuery = this.injector.get(ReadOnlyCustomFieldsDocument);
		this.findArchivedCustomFieldsQuery = this.injector.get(FindArchivedCustomFieldsDocument);
		this.countAllCustomFieldsQuery = this.injector.get(CountAllCustomFieldsDocument);
		this.findDynamicPropertyFieldsCustomFieldsQuery = this.injector.get(FindDynamicPropertyFieldsCustomFieldsDocument);
		this.insertCustomFieldsMutation = this.injector.get(InsertCustomFieldsDocument);
		this.updateCustomFieldsMutation = this.injector.get(UpdateCustomFieldsDocument);
		this.importDataCustomFieldsMutation = this.injector.get(ImportDataCustomFieldsDocument);
		this.updateManyCustomFieldsMutation = this.injector.get(UpdateManyCustomFieldsDocument);
		this.deleteCustomFieldsMutation = this.injector.get(DeleteCustomFieldsDocument);
		this.restoreCustomFieldsMutation = this.injector.get(RestoreCustomFieldsDocument);
		this.recycleCustomFieldsMutation = this.injector.get(RecycleCustomFieldsDocument);
		this.restoreArchivedCustomFieldsMutation = this.injector.get(RestoreArchivedCustomFieldsDocument);
		this.archivedCustomFieldsMutation = this.injector.get(ArchivedCustomFieldsDocument);
		this.addFileDynamicFieldCustomFieldsMutation = this.injector.get(AddFileDynamicFieldCustomFieldsDocument);
		this.removeFileDynamicFieldCustomFieldsMutation = this.injector.get(RemoveFileDynamicFieldCustomFieldsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCustomField> {
    
    		let variables: GetCustomFieldsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.get));
            }
            else{
                let result:ServiceSingleResultOfCustomField={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCustomField> {
    
    		let variables: FirstCustomFieldsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCustomFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.countCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: FindCustomFieldsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCustomField,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: SearchCustomFieldsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCustomFieldsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCustomFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: CustomQueryCustomFieldsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: CustomQueryIdCustomFieldsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCustomFieldsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: FindRecyclesCustomFieldsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesCustomFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCustomFieldsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCustomField,
		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCustomField> {
    
    		let variables: FindArchivedCustomFieldsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllCustomFieldsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsCustomFieldsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsCustomFieldsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CustomFieldInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCustomField> {
    
    		let variables: InsertCustomFieldsBaseVariables = {
    			entity: entity
    		}
    				return this.insertCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCustomField> {
    
    		let variables: UpdateCustomFieldsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCustomFieldsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCustomField,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCustomFieldsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedCustomFieldsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldCustomFieldsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldCustomFieldsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldCustomFieldsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.customFields.removeFileDynamicField));
    	}
    
}