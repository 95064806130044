import { Injectable, Injector } from '@angular/core';
import { AbstractControl } from '@angular/forms';
import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { Observable, of } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { FileManagerBaseService } from '../service-bases';

@Injectable({ providedIn: 'root' })
export class FileManagerCoreService extends FileManagerBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  public static FileModelFields(): Array<GqlField | GqlSubField> {
    return [
      GqlSubField.create('data', [
        GqlField.create('fileId'),
        GqlField.create('id'),
        GqlField.create('name'),
        GqlField.create('objectId'),
        GqlField.create('size'),
        GqlField.create('type'),
        GqlField.create('uploaded'),
        GqlField.create('commentary'),
        GqlSubField.create('metaData', [
          GqlField.create('commentary'),
          GqlField.create('visibleByHelpDeskUser'),
          GqlField.create('outgoingEmails'),
          GqlSubField.create('linkeds', [
            GqlField.create('entityId'),
            GqlField.create('entityType'),
          ]),
        ]),
      ]),
    ];
  }

  /** Retourne le service */
  public getService() {
    return this;
  }
  /** Validation  */
  public oneOrMore(
    state: ModelState,
    control: AbstractControl,
  ): Observable<boolean> {
    if (
      control?.value?.itemsAdded != undefined &&
      control.value.itemsAdded.length > 0
    ) {
      return of(true);
    }

    // si nouveau
    let id = state.sharedContext.params.get('id');
    if (id == undefined) {
      return of(false);
    } else {
      if (
        state?.sharedContext?.entity != undefined &&
        state?.sharedContext?.entity['fileIds'] != undefined
      ) {
        // Vérification nombre de fichier supprimé
        let actualFile = state?.sharedContext?.entity['fileIds'].length;
        let removeFile = control?.value?.itemsRemoved?.length;
        if (removeFile == undefined) {
          removeFile = 0;
        }

        if (actualFile - removeFile <= 0) {
          return of(false);
        }
      }
    }
    return of(true);
  }
}
