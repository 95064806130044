import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetAgentTokensBaseVariables, FirstAgentTokensBaseVariables, CountAgentTokensBaseVariables, FindAgentTokensBaseVariables, SearchAgentTokensBaseVariables, ExportSchemaAgentTokensBaseVariables, ExportDataAgentTokensBaseVariables, CustomQueryAgentTokensBaseVariables, CustomQueryIdAgentTokensBaseVariables, UsedAgentTokensBaseVariables, HasAvailaibleAgentTokensBaseVariables, HasAvailaibleV2AgentTokensBaseVariables, ExistAgentTokensBaseVariables, InsertAgentTokensBaseVariables, UpdateAgentTokensBaseVariables, ImportDataAgentTokensBaseVariables, UpdateManyAgentTokensBaseVariables, DeleteAgentTokensBaseVariables } from '../gqls'
import { GetAgentTokensDocument, FirstAgentTokensDocument, CountAgentTokensDocument, FindAgentTokensDocument, SearchAgentTokensDocument, ExportSchemaAgentTokensDocument, ExportDataAgentTokensDocument, CustomQueryAgentTokensDocument, CustomQueryIdAgentTokensDocument, UsedAgentTokensDocument, HasAvailaibleAgentTokensDocument, HasAvailaibleV2AgentTokensDocument, ExistAgentTokensDocument, InsertAgentTokensDocument, UpdateAgentTokensDocument, ImportDataAgentTokensDocument, UpdateManyAgentTokensDocument, DeleteAgentTokensDocument } from '../gqls'
import { ServiceSingleResultOfAgentToken, QueryContextOfAgentToken, FilterOfAgentToken, ServiceSingleResultOfInt64, ServiceListResultOfAgentToken, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfInt32, AgentTokenInput, FieldUpdateOperatorOfAgentToken } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class AgentTokenBaseService {
    
public modelName = 'agentToken';
public modelPluralName = 'agentTokens';

	private getAgentTokensQuery: GetAgentTokensDocument;
	private firstAgentTokensQuery: FirstAgentTokensDocument;
	private countAgentTokensQuery: CountAgentTokensDocument;
	private findAgentTokensQuery: FindAgentTokensDocument;
	private searchAgentTokensQuery: SearchAgentTokensDocument;
	private exportSchemaAgentTokensQuery: ExportSchemaAgentTokensDocument;
	private exportDataAgentTokensQuery: ExportDataAgentTokensDocument;
	private customQueryAgentTokensQuery: CustomQueryAgentTokensDocument;
	private customQueryIdAgentTokensQuery: CustomQueryIdAgentTokensDocument;
	private usedAgentTokensQuery: UsedAgentTokensDocument;
	private hasAvailaibleAgentTokensQuery: HasAvailaibleAgentTokensDocument;
	private hasAvailaibleV2AgentTokensQuery: HasAvailaibleV2AgentTokensDocument;
	private existAgentTokensQuery: ExistAgentTokensDocument;
	private insertAgentTokensMutation: InsertAgentTokensDocument;
	private updateAgentTokensMutation: UpdateAgentTokensDocument;
	private importDataAgentTokensMutation: ImportDataAgentTokensDocument;
	private updateManyAgentTokensMutation: UpdateManyAgentTokensDocument;
	private deleteAgentTokensMutation: DeleteAgentTokensDocument;

	constructor(private injector: Injector) {
		this.getAgentTokensQuery = this.injector.get(GetAgentTokensDocument);
		this.firstAgentTokensQuery = this.injector.get(FirstAgentTokensDocument);
		this.countAgentTokensQuery = this.injector.get(CountAgentTokensDocument);
		this.findAgentTokensQuery = this.injector.get(FindAgentTokensDocument);
		this.searchAgentTokensQuery = this.injector.get(SearchAgentTokensDocument);
		this.exportSchemaAgentTokensQuery = this.injector.get(ExportSchemaAgentTokensDocument);
		this.exportDataAgentTokensQuery = this.injector.get(ExportDataAgentTokensDocument);
		this.customQueryAgentTokensQuery = this.injector.get(CustomQueryAgentTokensDocument);
		this.customQueryIdAgentTokensQuery = this.injector.get(CustomQueryIdAgentTokensDocument);
		this.usedAgentTokensQuery = this.injector.get(UsedAgentTokensDocument);
		this.hasAvailaibleAgentTokensQuery = this.injector.get(HasAvailaibleAgentTokensDocument);
		this.hasAvailaibleV2AgentTokensQuery = this.injector.get(HasAvailaibleV2AgentTokensDocument);
		this.existAgentTokensQuery = this.injector.get(ExistAgentTokensDocument);
		this.insertAgentTokensMutation = this.injector.get(InsertAgentTokensDocument);
		this.updateAgentTokensMutation = this.injector.get(UpdateAgentTokensDocument);
		this.importDataAgentTokensMutation = this.injector.get(ImportDataAgentTokensDocument);
		this.updateManyAgentTokensMutation = this.injector.get(UpdateManyAgentTokensDocument);
		this.deleteAgentTokensMutation = this.injector.get(DeleteAgentTokensDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentToken> {
    
    		let variables: GetAgentTokensBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.get));
            }
            else{
                let result:ServiceSingleResultOfAgentToken={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentToken,
		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentToken> {
    
    		let variables: FirstAgentTokensBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAgentTokensBaseVariables = {
    			filter: filter
    		}
    				return this.countAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAgentToken,
		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentToken> {
    
    		let variables: FindAgentTokensBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfAgentToken,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentToken> {
    
    		let variables: SearchAgentTokensBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaAgentTokensBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataAgentTokensBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfAgentToken,
		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentToken> {
    
    		let variables: CustomQueryAgentTokensBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfAgentToken,
		@Args('filter?') filter?: FilterOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAgentToken> {
    
    		let variables: CustomQueryIdAgentTokensBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedAgentTokensBaseVariables = {
    			ids: ids
    		}
    				return this.usedAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.used));
    	}

    	/**  */
    	@InjectArgs
    	public hasAvailaible(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('agentTokenId') agentTokenId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt32> {
    
    		let variables: HasAvailaibleAgentTokensBaseVariables = {
    			agentTokenId: agentTokenId
    		}
    				return this.hasAvailaibleAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.hasAvailaible));
    	}

    	/**  */
    	@InjectArgs
    	public hasAvailaibleV2(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('agentToken?') agentToken?: AgentTokenInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt32> {
    
    		let variables: HasAvailaibleV2AgentTokensBaseVariables = {
    			agentToken: agentToken
    		}
    				return this.hasAvailaibleV2AgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.hasAvailaibleV2));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistAgentTokensBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existAgentTokensQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: AgentTokenInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentToken> {
    
    		let variables: InsertAgentTokensBaseVariables = {
    			entity: entity
    		}
    				return this.insertAgentTokensMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAgentToken> {
    
    		let variables: UpdateAgentTokensBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateAgentTokensMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataAgentTokensBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataAgentTokensMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfAgentToken,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyAgentTokensBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyAgentTokensMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteAgentTokensBaseVariables = {
    			ids: ids
    		}
    				return this.deleteAgentTokensMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.agentTokens.delete));
    	}
    
}