import { Injectable } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
import {
  Control,
  DependsOn,
  FormLayout,
  PageControl,
  PageGroup,
  PageSection,
  PageTab,
  SectionGroup,
} from '@clarilog/shared2/models';
import {
  ModelDataSourceContext,
  ModelDependsOnContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { FormDefaultValues } from './form-default-values';
import { FormGroupHelpers } from './form-group-helpers';

@Injectable({ providedIn: 'root' })
export class FormDependsOnService {
  private compileDependsOnFAKE(dep: DependsOn, name: string) {
    if (dep != undefined) {
      console.log(dep);
      console.log(name);
      throw new Error('compileDependsOn');
    }
  }

  private createListeningValue(
    control: AbstractControl,
    modelState: ModelState,
    fnCall: (value) => void,
  ) {
    modelState.gc.forRelease(control.valueChanges.subscribe(fnCall));
  }
  private setControlValue(control: AbstractControl, value: any) {
    control.setValue(value, { emitEvent: false });
  }

  private evaluateOperation(
    config: DependsOn,
    value: any,
    compareValue: any,
    trueCallBack: () => void,
    falseCallBack: () => void,
  ) {
    let result: boolean;

    compareValue =
      Array.isArray(compareValue) === true ? compareValue : [compareValue];

    if (value == undefined) {
      value = '';
    }

    (compareValue as Array<any>).forEach((el) => {
      if (result !== true) {
        switch (config.options.operator) {
          case 'Equal':
            result = result || value === el;
            break;
          case 'NotEqual':
            result = false || value !== el;
            break;
          case 'NotNull':
            result = false || value != undefined;
            break;
          case 'GreaterThanOrEqual':
            result = false || value >= el;
            break;
          case 'NotNullOrEmpty':
            result =
              false ||
              (value != undefined &&
                value.trim != undefined &&
                value.trim().length > 0);
            break;
          case 'Contains':
            result =
              false ||
              (value != undefined &&
                value.includes != undefined &&
                value.includes(el));

            break;
          case 'NotContains':
            result =
              false ||
              (value != undefined &&
                value.includes != undefined &&
                value.includes(el) == false);
            break;
          default:
            //if no operator defined default is equal
            result = result || value === el;
            break;
        }
      }
    });

    if (result === true) {
      trueCallBack();
    } else {
      falseCallBack();
    }
  }

  private compileDisableEffect(
    control: Control,
    formControl: AbstractControl,
    depFormControl: AbstractControl,
    modelState: ModelState,
  ) {
    let depContext: ModelDependsOnContext = new ModelDependsOnContext(
      formControl,
      depFormControl,
      control,
      null,
    );

    if (control.dependsOn.options == undefined) {
      control.dependsOn.options = {
        value: true,
        operator: 'Equal',
      };
    }
    if (control.dependsOn.options.value == undefined) {
      control.dependsOn.options.value = true;
    }

    depContext.fn = (value) => {
      if (control?.dependsOn?.options?.value == undefined) {
        return;
      }
      let ctlr1 = formControl;
      let ctlr2 = depFormControl;

      this.evaluateOperation(
        control.dependsOn,
        value,
        control.dependsOn.options.value,
        () => {
          formControl.disable();
        },
        () => {
          formControl.enable();
        },
      );
    };

    this.createListeningValue(depFormControl, modelState, depContext.fn);

    modelState.dependsOnContexts.push(depContext);
  }

  private compileVisibleEffect(
    dependsOn: DependsOn,
    formControl: AbstractControl,
    depFormControl: AbstractControl,
    modelState: ModelState,
  ) {
    let depContext: ModelDependsOnContext = new ModelDependsOnContext(
      formControl,
      depFormControl,
      null,
      null,
    );

    if (dependsOn.options == undefined) {
      dependsOn.options = {
        value: true,
        operator: 'Equal',
      };
    }
    if (dependsOn.options.value == undefined) {
      dependsOn.options.value = true;
    }

    depContext.fn = (value) => {
      if (dependsOn.options.value == undefined) {
        throw new Error('compileVisibleEffect');
      }

      this.evaluateOperation(
        dependsOn,
        value,
        dependsOn.options.value,
        () => {
          let formGroup = formControl as UntypedFormGroup;
          if (formGroup != undefined && formGroup.controls != undefined) {
            let keys = Object.keys(formGroup.controls);
            for (let key of keys) {
              if (formGroup.controls[key].visible != undefined) {
                formGroup.controls[key].visible();
                formGroup.controls[key].visibled = true;
                formGroup.controls[key].hasParentDependOn = true;
              }
            }
          }
          if (modelState.form['readOnly'] != undefined) {
            formControl.visible(!modelState.form['readOnly']);
          } else formControl.visible();

          formControl.visibled = true;
          if (formControl.parent != undefined) {
            formControl.parent.visibled = true;
          }
        },
        () => {
          let formGroup = formControl as UntypedFormGroup;
          if (formGroup != undefined && formGroup.controls != undefined) {
            let keys = Object.keys(formGroup.controls);
            for (let key of keys) {
              if (formGroup.controls[key].invisible != undefined) {
                formGroup.controls[key].invisible();
                formGroup.controls[key].visibled = false;
                formGroup.controls[key].hasParentDependOn = true;
              }
            }
          }
          formControl.invisible();
        },
      );
    };

    this.createListeningValue(depFormControl, modelState, depContext.fn);

    modelState.dependsOnContexts.push(depContext);
  }

  private compileFilterEffect(
    control: Control,
    formControl: AbstractControl,
    depFormControl: AbstractControl,
    modelState: ModelState,
  ) {
    let depContext: ModelDependsOnContext = new ModelDependsOnContext(
      formControl,
      depFormControl,
      control,
      null,
    );

    if (control.dependsOn.options == undefined) {
      throw new Error('compileVisibleEffect');
    }

    depContext.fn = (value) => {
      if (
        control.options != undefined &&
        control.options['source'] != undefined
      ) {
        let filterValue = value;
        if (filterValue == undefined) {
          filterValue = '00000000-0000-0000-0000-000000000000';
        }

        formControl.reset();
        let parentFilterSource = [
          control.dependsOn.options.propertyName,
          '=',
          filterValue,
        ];
        (control.options['source'] as ModelDataSourceContext).datasource[
          'parentFilterSource'
        ] = parentFilterSource;

        (control.options['source'] as ModelDataSourceContext).datasource.filter(
          [parentFilterSource],
        );
      } else {
        throw new Error('Filter depednsOn has no source');
      }
    };
    this.createListeningValue(depFormControl, modelState, depContext.fn);

    modelState.dependsOnContexts.push(depContext);
  }

  private compileClearErrorsEffect(
    control: Control,
    formControl: AbstractControl,
    depFormControl: AbstractControl,
    modelState: ModelState,
  ) {
    let depContext: ModelDependsOnContext = new ModelDependsOnContext(
      formControl,
      depFormControl,
      control,
      null,
    );

    depContext.fn = (value) => {
      formControl.setErrors(null);
    };

    this.createListeningValue(depFormControl, modelState, depContext.fn);

    modelState.dependsOnContexts.push(depContext);
  }

  private compileEffect(
    control: Control,
    formControl: AbstractControl,
    depFormControl: AbstractControl,
    modelState: ModelState,
  ) {
    switch (control.dependsOn.effect) {
      case 'Disable':
        this.compileDisableEffect(
          control,
          formControl,
          depFormControl,
          modelState,
        );
        break;
      case 'Visible':
        this.compileVisibleEffect(
          control.dependsOn,
          formControl,
          depFormControl,
          modelState,
        );
        break;
      case 'Filter':
        this.compileFilterEffect(
          control,
          formControl,
          depFormControl,
          modelState,
        );
        break;
      case 'ClearErrors':
        this.compileClearErrorsEffect(
          control,
          formControl,
          depFormControl,
          modelState,
        );

        break;
      default:
        console.log('Effect ' + control.dependsOn.effect + ' not implem');
        break;
    }
  }

  private compileDependsOn(
    control: Control,
    name: string,
    modelState: ModelState,
  ) {
    if (control.dependsOn != undefined) {
      let formControl = FormGroupHelpers.formControlByName(
        modelState.form,
        name.split('.').join('_'),
      );
      let depFormControl = FormGroupHelpers.formControlByName(
        modelState.form,
        control.dependsOn.controlName.split('.').join('_'),
      );

      if (formControl == undefined || depFormControl == undefined) {
        console.log(name + ' dep: ' + control.dependsOn.controlName);
        console.log(modelState.form);
        throw new Error('compileDependsOn control not found');
      }
      this.compileEffect(control, formControl, depFormControl, modelState);
    }
  }

  private compileGroupDependsOn(
    group: SectionGroup,
    name: string,
    modelState: ModelState,
  ) {
    if (group.dependsOn != undefined) {
      let formControl = FormGroupHelpers.findFormGroupByName(
        modelState.form,
        group.name.split('.').join('_'),
      );
      let depFormControl: AbstractControl =
        FormGroupHelpers.findFormGroupByName(
          modelState.form,
          group.dependsOn.controlName.split('.').join('_'),
        );

      if (depFormControl == undefined) {
        depFormControl = FormGroupHelpers.formControlByName(
          modelState.form,
          group.dependsOn.controlName.split('.').join('_'),
        );
      }

      if (formControl == undefined) {
        console.log(name + ' dep: ' + group.dependsOn.controlName);
        console.log(modelState.form);
        throw new Error('compileGroupDependsOn control not found');
      }

      if (depFormControl == undefined) {
        formControl.invisible();
        return;
      }
      if (group.dependsOn.effect === 'Visible') {
        this.compileVisibleEffect(
          group.dependsOn,
          formControl,
          depFormControl,
          modelState,
        );
      }
    }
  }

  private compilePageSection(page: PageSection, modelState: ModelState) {
    // page.dependsOn
    //this.compileDependsOnFAKE(page.dependsOn, page.name);
    this.compilePageControlDependsOn(page, page.name, modelState);

    page.sections.forEach((section) => {
      section.groups.forEach((group) => {
        // this.compileDependsOnFAKE(group.dependsOn, group.name);
        this.compileGroupDependsOn(group, group.name, modelState);

        group.controls.forEach((control) => {
          FormDefaultValues.setDefaultValues(control);
          this.compileDependsOn(control, control.name, modelState);
        });
      });
    });
  }

  private compilePageControlDependsOn(
    page: PageControl | PageSection | PageTab,
    name: string,
    modelState: ModelState,
  ) {
    if (page.dependsOn != undefined) {
      let formControl = FormGroupHelpers.findFormGroupByName(
        modelState.form,
        page.name.split('.').join('_'),
      );
      let depFormControl: AbstractControl =
        FormGroupHelpers.findFormGroupByName(
          modelState.form,
          page.dependsOn.controlName.split('.').join('_'),
        );

      if (depFormControl == undefined) {
        depFormControl = FormGroupHelpers.formControlByName(
          modelState.form,
          page.dependsOn.controlName.split('.').join('_'),
        );
      }

      if (formControl == undefined) {
        console.log(name + ' dep: ' + page.dependsOn.controlName);
        console.log(modelState.form);
        throw new Error('compileGroupDependsOn control not found');
      }

      if (depFormControl == undefined) {
        formControl.invisible();
        return;
      }
      if (page.dependsOn.effect === 'Visible') {
        this.compileVisibleEffect(
          page.dependsOn,
          formControl,
          depFormControl,
          modelState,
        );
      }
    }
  }

  private compilePageControl(page: PageControl, modelState: ModelState) {
    this.compilePageControlDependsOn(page, page.name, modelState);
    if (page.control != undefined) {
      this.compileDependsOn(page.control, page.control.name, modelState);
    }
  }

  private compileTabPageControl(
    pages: (PageSection | PageControl)[],
    modelState: ModelState,
  ) {
    pages.forEach((subPage) => {
      if ((subPage as PageSection).sections != undefined) {
        this.compilePageSection(subPage as PageSection, modelState);
      } else if ((subPage as PageControl).control != undefined) {
        this.compilePageControl(subPage as PageControl, modelState);
      } else if ((subPage as any).tabs != undefined) {
        this.compileTabPageControl(
          (subPage as any).tabs as (PageSection | PageControl)[],
          modelState,
        );
      } else {
        console.log(subPage);
        throw new Error('dependon create');
      }
    });
  }

  create(
    formGroup: UntypedFormGroup,
    layout: FormLayout,
    modelState: ModelState,
  ) {
    layout.pages.forEach((page) => {
      this.compilePageControlDependsOn(page, page.name, modelState);
      if ((page as PageSection).sections != undefined) {
        this.compilePageSection(page as PageSection, modelState);
      } else if ((page as PageControl).control != undefined) {
        this.compilePageControl(page as PageControl, modelState);
      } else {
        let pages: (PageSection | PageControl)[] = [];
        if ((page as PageGroup).pages != undefined) {
          pages = (page as PageGroup).pages;
        } else if ((page as PageTab).tabs != undefined) {
          pages = (page as PageTab).tabs;
        } else {
          console.log(page);
          throw new Error('dependon create');
        }
        this.compileTabPageControl(pages, modelState);
      }
    });

    modelState.dependsOnContexts.forEach((dep) => {
      dep.validate();
    });
  }
}
