import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDayOffsBaseVariables, FirstDayOffsBaseVariables, CountDayOffsBaseVariables, FindDayOffsBaseVariables, SearchDayOffsBaseVariables, ExportSchemaDayOffsBaseVariables, ExportDataDayOffsBaseVariables, CustomQueryDayOffsBaseVariables, CustomQueryIdDayOffsBaseVariables, UsedDayOffsBaseVariables, ExistDayOffsBaseVariables, FindUnassociatedServiceLevelAgreementsDayOffsBaseVariables, FindUnassociatedBusinessTimesDayOffsBaseVariables, InsertDayOffsBaseVariables, UpdateDayOffsBaseVariables, ImportDataDayOffsBaseVariables, UpdateManyDayOffsBaseVariables, DeleteDayOffsBaseVariables, DuplicateServiceLevelAgreementsDayOffsBaseVariables, ExportConfigSchemaDayOffsBaseVariables, ImportDayConfigDayOffsBaseVariables, AddBusinessTimesDayOffsBaseVariables, RemoveBusinessTimesDayOffsBaseVariables, AddServiceLevelAgreementsDayOffsBaseVariables, RemoveServiceLevelAgreementsDayOffsBaseVariables } from '../gqls'
import { GetDayOffsDocument, FirstDayOffsDocument, CountDayOffsDocument, FindDayOffsDocument, SearchDayOffsDocument, ExportSchemaDayOffsDocument, ExportDataDayOffsDocument, CustomQueryDayOffsDocument, CustomQueryIdDayOffsDocument, UsedDayOffsDocument, ExistDayOffsDocument, FindUnassociatedServiceLevelAgreementsDayOffsDocument, FindUnassociatedBusinessTimesDayOffsDocument, InsertDayOffsDocument, UpdateDayOffsDocument, ImportDataDayOffsDocument, UpdateManyDayOffsDocument, DeleteDayOffsDocument, DuplicateServiceLevelAgreementsDayOffsDocument, ExportConfigSchemaDayOffsDocument, ImportDayConfigDayOffsDocument, AddBusinessTimesDayOffsDocument, RemoveBusinessTimesDayOffsDocument, AddServiceLevelAgreementsDayOffsDocument, RemoveServiceLevelAgreementsDayOffsDocument } from '../gqls'
import { ServiceSingleResultOfDayOff, QueryContextOfDayOff, FilterOfDayOff, ServiceSingleResultOfInt64, ServiceListResultOfDayOff, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfServiceLevelAgreement, FilterOfServiceLevelAgreement, ServiceListResultOfServiceLevelAgreement, QueryContextOfBusinessTime, FilterOfBusinessTime, ServiceListResultOfBusinessTime, DayOffInput, FieldUpdateOperatorOfDayOff } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DayOffBaseService {
    
public modelName = 'dayOff';
public modelPluralName = 'dayOffs';

	private getDayOffsQuery: GetDayOffsDocument;
	private firstDayOffsQuery: FirstDayOffsDocument;
	private countDayOffsQuery: CountDayOffsDocument;
	private findDayOffsQuery: FindDayOffsDocument;
	private searchDayOffsQuery: SearchDayOffsDocument;
	private exportSchemaDayOffsQuery: ExportSchemaDayOffsDocument;
	private exportDataDayOffsQuery: ExportDataDayOffsDocument;
	private customQueryDayOffsQuery: CustomQueryDayOffsDocument;
	private customQueryIdDayOffsQuery: CustomQueryIdDayOffsDocument;
	private usedDayOffsQuery: UsedDayOffsDocument;
	private existDayOffsQuery: ExistDayOffsDocument;
	private findUnassociatedServiceLevelAgreementsDayOffsQuery: FindUnassociatedServiceLevelAgreementsDayOffsDocument;
	private findUnassociatedBusinessTimesDayOffsQuery: FindUnassociatedBusinessTimesDayOffsDocument;
	private insertDayOffsMutation: InsertDayOffsDocument;
	private updateDayOffsMutation: UpdateDayOffsDocument;
	private importDataDayOffsMutation: ImportDataDayOffsDocument;
	private updateManyDayOffsMutation: UpdateManyDayOffsDocument;
	private deleteDayOffsMutation: DeleteDayOffsDocument;
	private duplicateServiceLevelAgreementsDayOffsMutation: DuplicateServiceLevelAgreementsDayOffsDocument;
	private exportConfigSchemaDayOffsMutation: ExportConfigSchemaDayOffsDocument;
	private importDayConfigDayOffsMutation: ImportDayConfigDayOffsDocument;
	private addBusinessTimesDayOffsMutation: AddBusinessTimesDayOffsDocument;
	private removeBusinessTimesDayOffsMutation: RemoveBusinessTimesDayOffsDocument;
	private addServiceLevelAgreementsDayOffsMutation: AddServiceLevelAgreementsDayOffsDocument;
	private removeServiceLevelAgreementsDayOffsMutation: RemoveServiceLevelAgreementsDayOffsDocument;

	constructor(private injector: Injector) {
		this.getDayOffsQuery = this.injector.get(GetDayOffsDocument);
		this.firstDayOffsQuery = this.injector.get(FirstDayOffsDocument);
		this.countDayOffsQuery = this.injector.get(CountDayOffsDocument);
		this.findDayOffsQuery = this.injector.get(FindDayOffsDocument);
		this.searchDayOffsQuery = this.injector.get(SearchDayOffsDocument);
		this.exportSchemaDayOffsQuery = this.injector.get(ExportSchemaDayOffsDocument);
		this.exportDataDayOffsQuery = this.injector.get(ExportDataDayOffsDocument);
		this.customQueryDayOffsQuery = this.injector.get(CustomQueryDayOffsDocument);
		this.customQueryIdDayOffsQuery = this.injector.get(CustomQueryIdDayOffsDocument);
		this.usedDayOffsQuery = this.injector.get(UsedDayOffsDocument);
		this.existDayOffsQuery = this.injector.get(ExistDayOffsDocument);
		this.findUnassociatedServiceLevelAgreementsDayOffsQuery = this.injector.get(FindUnassociatedServiceLevelAgreementsDayOffsDocument);
		this.findUnassociatedBusinessTimesDayOffsQuery = this.injector.get(FindUnassociatedBusinessTimesDayOffsDocument);
		this.insertDayOffsMutation = this.injector.get(InsertDayOffsDocument);
		this.updateDayOffsMutation = this.injector.get(UpdateDayOffsDocument);
		this.importDataDayOffsMutation = this.injector.get(ImportDataDayOffsDocument);
		this.updateManyDayOffsMutation = this.injector.get(UpdateManyDayOffsDocument);
		this.deleteDayOffsMutation = this.injector.get(DeleteDayOffsDocument);
		this.duplicateServiceLevelAgreementsDayOffsMutation = this.injector.get(DuplicateServiceLevelAgreementsDayOffsDocument);
		this.exportConfigSchemaDayOffsMutation = this.injector.get(ExportConfigSchemaDayOffsDocument);
		this.importDayConfigDayOffsMutation = this.injector.get(ImportDayConfigDayOffsDocument);
		this.addBusinessTimesDayOffsMutation = this.injector.get(AddBusinessTimesDayOffsDocument);
		this.removeBusinessTimesDayOffsMutation = this.injector.get(RemoveBusinessTimesDayOffsDocument);
		this.addServiceLevelAgreementsDayOffsMutation = this.injector.get(AddServiceLevelAgreementsDayOffsDocument);
		this.removeServiceLevelAgreementsDayOffsMutation = this.injector.get(RemoveServiceLevelAgreementsDayOffsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDayOff> {
    
    		let variables: GetDayOffsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.get));
            }
            else{
                let result:ServiceSingleResultOfDayOff={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDayOff,
		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDayOff> {
    
    		let variables: FirstDayOffsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDayOffsBaseVariables = {
    			filter: filter
    		}
    				return this.countDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDayOff,
		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDayOff> {
    
    		let variables: FindDayOffsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDayOff,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDayOff> {
    
    		let variables: SearchDayOffsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDayOffsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDayOffsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDayOff,
		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDayOff> {
    
    		let variables: CustomQueryDayOffsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDayOff,
		@Args('filter?') filter?: FilterOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDayOff> {
    
    		let variables: CustomQueryIdDayOffsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDayOffsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDayOffsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.exist));
    	}

	@InjectArgs
	public findAssociatedServiceLevelAgreements(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfServiceLevelAgreement> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('serviceLevelAgreements', fields, null, [
			GqlSubFieldArg.create('filterOfServiceLevelAgreements', 'filter'),
			GqlSubFieldArg.create('optionsOfServiceLevelAgreements', 'options'),
		]),
		])
		extendedVariables['filterOfServiceLevelAgreements'] = filter;
		extendedVariables['optionsOfServiceLevelAgreements'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.serviceLevelAgreements));
            }
            else{
                let result: ServiceListResultOfServiceLevelAgreement = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfServiceLevelAgreement,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfServiceLevelAgreement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfServiceLevelAgreement> {
    
    		let variables: FindUnassociatedServiceLevelAgreementsDayOffsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedServiceLevelAgreementsDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.findUnassociatedServiceLevelAgreements));
    	}

	@InjectArgs
	public findAssociatedBusinessTimes(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfBusinessTime,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfBusinessTime> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('businessTimes', fields, null, [
			GqlSubFieldArg.create('filterOfBusinessTimes', 'filter'),
			GqlSubFieldArg.create('optionsOfBusinessTimes', 'options'),
		]),
		])
		extendedVariables['filterOfBusinessTimes'] = filter;
		extendedVariables['optionsOfBusinessTimes'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.businessTimes));
            }
            else{
                let result: ServiceListResultOfBusinessTime = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedBusinessTimes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfBusinessTime,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfBusinessTime,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfBusinessTime> {
    
    		let variables: FindUnassociatedBusinessTimesDayOffsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedBusinessTimesDayOffsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.findUnassociatedBusinessTimes));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DayOffInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDayOff> {
    
    		let variables: InsertDayOffsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDayOff> {
    
    		let variables: UpdateDayOffsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDayOffsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDayOff,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDayOffsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDayOffsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.delete));
    	}

    	/**  */
    	@InjectArgs
    	public duplicateServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('nbyear') nbyear: number,
		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DuplicateServiceLevelAgreementsDayOffsBaseVariables = {
    			ids: ids,
			nbyear: nbyear
    		}
    				return this.duplicateServiceLevelAgreementsDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.duplicateServiceLevelAgreements));
    	}

    	/**  */
    	@InjectArgs
    	public exportConfigSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportConfigSchemaDayOffsBaseVariables = {
    
    		}
    				return this.exportConfigSchemaDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.exportConfigSchema));
    	}

    	/**  */
    	@InjectArgs
    	public importDayConfig(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('id') id: string,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDayConfigDayOffsBaseVariables = {
    			id: id,
			file: file,
			type: type
    		}
    				return this.importDayConfigDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.importDayConfig));
    	}

    	/**  */
    	@InjectArgs
    	public addBusinessTimes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('businessTimeIds') businessTimeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddBusinessTimesDayOffsBaseVariables = {
    			id: id,
			businessTimeIds: businessTimeIds
    		}
    				return this.addBusinessTimesDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.addBusinessTimes));
    	}

    	/**  */
    	@InjectArgs
    	public removeBusinessTimes(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('businessTimeIds') businessTimeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveBusinessTimesDayOffsBaseVariables = {
    			id: id,
			businessTimeIds: businessTimeIds
    		}
    				return this.removeBusinessTimesDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.removeBusinessTimes));
    	}

    	/**  */
    	@InjectArgs
    	public addServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddServiceLevelAgreementsDayOffsBaseVariables = {
    			id: id,
			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.addServiceLevelAgreementsDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.addServiceLevelAgreements));
    	}

    	/**  */
    	@InjectArgs
    	public removeServiceLevelAgreements(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('serviceLevelAgreementIds') serviceLevelAgreementIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveServiceLevelAgreementsDayOffsBaseVariables = {
    			id: id,
			serviceLevelAgreementIds: serviceLevelAgreementIds
    		}
    				return this.removeServiceLevelAgreementsDayOffsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dayOffs.removeServiceLevelAgreements));
    	}
    
}