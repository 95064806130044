import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetQuickFixEngineeringsBaseVariables, FirstQuickFixEngineeringsBaseVariables, CountQuickFixEngineeringsBaseVariables, FindQuickFixEngineeringsBaseVariables, SearchQuickFixEngineeringsBaseVariables, ExportSchemaQuickFixEngineeringsBaseVariables, ExportDataQuickFixEngineeringsBaseVariables, CustomQueryQuickFixEngineeringsBaseVariables, CustomQueryIdQuickFixEngineeringsBaseVariables, UsedQuickFixEngineeringsBaseVariables, ExistQuickFixEngineeringsBaseVariables } from '../gqls'
import { GetQuickFixEngineeringsDocument, FirstQuickFixEngineeringsDocument, CountQuickFixEngineeringsDocument, FindQuickFixEngineeringsDocument, SearchQuickFixEngineeringsDocument, ExportSchemaQuickFixEngineeringsDocument, ExportDataQuickFixEngineeringsDocument, CustomQueryQuickFixEngineeringsDocument, CustomQueryIdQuickFixEngineeringsDocument, UsedQuickFixEngineeringsDocument, ExistQuickFixEngineeringsDocument } from '../gqls'
import { ServiceSingleResultOfQuickFixEngineering, QueryContextOfQuickFixEngineering, FilterOfQuickFixEngineering, ServiceSingleResultOfInt64, ServiceListResultOfQuickFixEngineering, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class QuickFixEngineeringBaseService {
    
public modelName = 'quickFixEngineering';
public modelPluralName = 'quickFixEngineerings';

	private getQuickFixEngineeringsQuery: GetQuickFixEngineeringsDocument;
	private firstQuickFixEngineeringsQuery: FirstQuickFixEngineeringsDocument;
	private countQuickFixEngineeringsQuery: CountQuickFixEngineeringsDocument;
	private findQuickFixEngineeringsQuery: FindQuickFixEngineeringsDocument;
	private searchQuickFixEngineeringsQuery: SearchQuickFixEngineeringsDocument;
	private exportSchemaQuickFixEngineeringsQuery: ExportSchemaQuickFixEngineeringsDocument;
	private exportDataQuickFixEngineeringsQuery: ExportDataQuickFixEngineeringsDocument;
	private customQueryQuickFixEngineeringsQuery: CustomQueryQuickFixEngineeringsDocument;
	private customQueryIdQuickFixEngineeringsQuery: CustomQueryIdQuickFixEngineeringsDocument;
	private usedQuickFixEngineeringsQuery: UsedQuickFixEngineeringsDocument;
	private existQuickFixEngineeringsQuery: ExistQuickFixEngineeringsDocument;

	constructor(private injector: Injector) {
		this.getQuickFixEngineeringsQuery = this.injector.get(GetQuickFixEngineeringsDocument);
		this.firstQuickFixEngineeringsQuery = this.injector.get(FirstQuickFixEngineeringsDocument);
		this.countQuickFixEngineeringsQuery = this.injector.get(CountQuickFixEngineeringsDocument);
		this.findQuickFixEngineeringsQuery = this.injector.get(FindQuickFixEngineeringsDocument);
		this.searchQuickFixEngineeringsQuery = this.injector.get(SearchQuickFixEngineeringsDocument);
		this.exportSchemaQuickFixEngineeringsQuery = this.injector.get(ExportSchemaQuickFixEngineeringsDocument);
		this.exportDataQuickFixEngineeringsQuery = this.injector.get(ExportDataQuickFixEngineeringsDocument);
		this.customQueryQuickFixEngineeringsQuery = this.injector.get(CustomQueryQuickFixEngineeringsDocument);
		this.customQueryIdQuickFixEngineeringsQuery = this.injector.get(CustomQueryIdQuickFixEngineeringsDocument);
		this.usedQuickFixEngineeringsQuery = this.injector.get(UsedQuickFixEngineeringsDocument);
		this.existQuickFixEngineeringsQuery = this.injector.get(ExistQuickFixEngineeringsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQuickFixEngineering> {
    
    		let variables: GetQuickFixEngineeringsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.get));
            }
            else{
                let result:ServiceSingleResultOfQuickFixEngineering={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQuickFixEngineering,
		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfQuickFixEngineering> {
    
    		let variables: FirstQuickFixEngineeringsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountQuickFixEngineeringsBaseVariables = {
    			filter: filter
    		}
    				return this.countQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfQuickFixEngineering,
		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQuickFixEngineering> {
    
    		let variables: FindQuickFixEngineeringsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfQuickFixEngineering,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQuickFixEngineering> {
    
    		let variables: SearchQuickFixEngineeringsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaQuickFixEngineeringsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataQuickFixEngineeringsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfQuickFixEngineering,
		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQuickFixEngineering> {
    
    		let variables: CustomQueryQuickFixEngineeringsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfQuickFixEngineering,
		@Args('filter?') filter?: FilterOfQuickFixEngineering,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfQuickFixEngineering> {
    
    		let variables: CustomQueryIdQuickFixEngineeringsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedQuickFixEngineeringsBaseVariables = {
    			ids: ids
    		}
    				return this.usedQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistQuickFixEngineeringsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existQuickFixEngineeringsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.quickFixEngineerings.exist));
    	}
    
    
}