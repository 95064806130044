import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanExcludesBaseVariables, FirstScanExcludesBaseVariables, CountScanExcludesBaseVariables, FindScanExcludesBaseVariables, SearchScanExcludesBaseVariables, ExportSchemaScanExcludesBaseVariables, ExportDataScanExcludesBaseVariables, CustomQueryScanExcludesBaseVariables, CustomQueryIdScanExcludesBaseVariables, UsedScanExcludesBaseVariables, FindNotScanConfigurationsScanExcludesBaseVariables, ScanConfigurationsByTypeScanExcludesBaseVariables, ExistScanExcludesBaseVariables, InsertScanExcludesBaseVariables, UpdateScanExcludesBaseVariables, ImportDataScanExcludesBaseVariables, UpdateManyScanExcludesBaseVariables, DeleteScanExcludesBaseVariables, AddScanConfigurationsScanExcludesBaseVariables, RemoveScanConfigurationsScanExcludesBaseVariables } from '../gqls'
import { GetScanExcludesDocument, FirstScanExcludesDocument, CountScanExcludesDocument, FindScanExcludesDocument, SearchScanExcludesDocument, ExportSchemaScanExcludesDocument, ExportDataScanExcludesDocument, CustomQueryScanExcludesDocument, CustomQueryIdScanExcludesDocument, UsedScanExcludesDocument, FindNotScanConfigurationsScanExcludesDocument, ScanConfigurationsByTypeScanExcludesDocument, ExistScanExcludesDocument, InsertScanExcludesDocument, UpdateScanExcludesDocument, ImportDataScanExcludesDocument, UpdateManyScanExcludesDocument, DeleteScanExcludesDocument, AddScanConfigurationsScanExcludesDocument, RemoveScanConfigurationsScanExcludesDocument } from '../gqls'
import { ServiceSingleResultOfScanExclude, QueryContextOfScanExclude, FilterOfScanExclude, ServiceSingleResultOfInt64, ServiceListResultOfScanExclude, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfiguration, QueryContextOfScanConfiguration, FilterOfScanConfiguration, ScanExcludeInput, FieldUpdateOperatorOfScanExclude } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanExcludeBaseService {
    
public modelName = 'scanExclude';
public modelPluralName = 'scanExcludes';

	private getScanExcludesQuery: GetScanExcludesDocument;
	private firstScanExcludesQuery: FirstScanExcludesDocument;
	private countScanExcludesQuery: CountScanExcludesDocument;
	private findScanExcludesQuery: FindScanExcludesDocument;
	private searchScanExcludesQuery: SearchScanExcludesDocument;
	private exportSchemaScanExcludesQuery: ExportSchemaScanExcludesDocument;
	private exportDataScanExcludesQuery: ExportDataScanExcludesDocument;
	private customQueryScanExcludesQuery: CustomQueryScanExcludesDocument;
	private customQueryIdScanExcludesQuery: CustomQueryIdScanExcludesDocument;
	private usedScanExcludesQuery: UsedScanExcludesDocument;
	private findNotScanConfigurationsScanExcludesQuery: FindNotScanConfigurationsScanExcludesDocument;
	private scanConfigurationsByTypeScanExcludesQuery: ScanConfigurationsByTypeScanExcludesDocument;
	private existScanExcludesQuery: ExistScanExcludesDocument;
	private insertScanExcludesMutation: InsertScanExcludesDocument;
	private updateScanExcludesMutation: UpdateScanExcludesDocument;
	private importDataScanExcludesMutation: ImportDataScanExcludesDocument;
	private updateManyScanExcludesMutation: UpdateManyScanExcludesDocument;
	private deleteScanExcludesMutation: DeleteScanExcludesDocument;
	private addScanConfigurationsScanExcludesMutation: AddScanConfigurationsScanExcludesDocument;
	private removeScanConfigurationsScanExcludesMutation: RemoveScanConfigurationsScanExcludesDocument;

	constructor(private injector: Injector) {
		this.getScanExcludesQuery = this.injector.get(GetScanExcludesDocument);
		this.firstScanExcludesQuery = this.injector.get(FirstScanExcludesDocument);
		this.countScanExcludesQuery = this.injector.get(CountScanExcludesDocument);
		this.findScanExcludesQuery = this.injector.get(FindScanExcludesDocument);
		this.searchScanExcludesQuery = this.injector.get(SearchScanExcludesDocument);
		this.exportSchemaScanExcludesQuery = this.injector.get(ExportSchemaScanExcludesDocument);
		this.exportDataScanExcludesQuery = this.injector.get(ExportDataScanExcludesDocument);
		this.customQueryScanExcludesQuery = this.injector.get(CustomQueryScanExcludesDocument);
		this.customQueryIdScanExcludesQuery = this.injector.get(CustomQueryIdScanExcludesDocument);
		this.usedScanExcludesQuery = this.injector.get(UsedScanExcludesDocument);
		this.findNotScanConfigurationsScanExcludesQuery = this.injector.get(FindNotScanConfigurationsScanExcludesDocument);
		this.scanConfigurationsByTypeScanExcludesQuery = this.injector.get(ScanConfigurationsByTypeScanExcludesDocument);
		this.existScanExcludesQuery = this.injector.get(ExistScanExcludesDocument);
		this.insertScanExcludesMutation = this.injector.get(InsertScanExcludesDocument);
		this.updateScanExcludesMutation = this.injector.get(UpdateScanExcludesDocument);
		this.importDataScanExcludesMutation = this.injector.get(ImportDataScanExcludesDocument);
		this.updateManyScanExcludesMutation = this.injector.get(UpdateManyScanExcludesDocument);
		this.deleteScanExcludesMutation = this.injector.get(DeleteScanExcludesDocument);
		this.addScanConfigurationsScanExcludesMutation = this.injector.get(AddScanConfigurationsScanExcludesDocument);
		this.removeScanConfigurationsScanExcludesMutation = this.injector.get(RemoveScanConfigurationsScanExcludesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanExclude> {
    
    		let variables: GetScanExcludesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.get));
            }
            else{
                let result:ServiceSingleResultOfScanExclude={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanExclude> {
    
    		let variables: FirstScanExcludesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanExcludesBaseVariables = {
    			filter: filter
    		}
    				return this.countScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: FindScanExcludesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: SearchScanExcludesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanExcludesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanExcludesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: CustomQueryScanExcludesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanExclude,
		@Args('filter?') filter?: FilterOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanExclude> {
    
    		let variables: CustomQueryIdScanExcludesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanExcludesBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.used));
    	}

    	/**  */
    	@InjectArgs
    	public findNotScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: FindNotScanConfigurationsScanExcludesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNotScanConfigurationsScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.findNotScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public scanConfigurationsByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanConfiguration,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfScanConfiguration,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanConfiguration> {
    
    		let variables: ScanConfigurationsByTypeScanExcludesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.scanConfigurationsByTypeScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.scanConfigurationsByType));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanExcludesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanExcludesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanExcludeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanExclude> {
    
    		let variables: InsertScanExcludesBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanExclude> {
    
    		let variables: UpdateScanExcludesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanExcludesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanExclude,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanExcludesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanExcludesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddScanConfigurationsScanExcludesBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.addScanConfigurationsScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.addScanConfigurations));
    	}

    	/**  */
    	@InjectArgs
    	public removeScanConfigurations(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('scanConfigurationIds') scanConfigurationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveScanConfigurationsScanExcludesBaseVariables = {
    			id: id,
			scanConfigurationIds: scanConfigurationIds
    		}
    				return this.removeScanConfigurationsScanExcludesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanExcludes.removeScanConfigurations));
    	}
    
}