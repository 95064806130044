/**
 * Classe contenant les options par défaut d'une grille
 */
export class DataGridConfigure {
  readonly options = {
    height: '100%',
    width: '100%',
    remoteOperations: {
      paging: true,
      filtering: true,
      sorting: true,
      grouping: false,
      groupPaging: true,
      summary: false,
    },
    scrolling: {
      showScrollbar: 'onHover',
    },
    columnChooser: {
      enabled: true,
    },

    searchPanel: {
      visible: true,
      width: 200,
      placeholder: 'Rechercher...',
    },
    selection: {
      selectAllMode: 'onClick',
      showCheckBoxesMode: 'onClick',
      mode: 'multiple',
    },
    paging: {
      pageSize: 20,
    },
    allowColumnResizing: true,
    allowColumnReordering: true,
    columnResizingMode: 'widget',
    columnAutoWidth: true,
    columnMinWidth: '150',
    pager: {
      showPageSizeSelector: true,
      allowedPageSizes: [10, 20, 50],
      showInfo: true,
      visible: true,
    },
    groupPanel: {
      visible: true,
    },
    grouping: {
      contextMenuEnabled: true,
    },
    sorting: {
      mode: 'multiple',
    },
    summary: {
      groupItems: [
        {
          column: 'id',
          summaryType: 'count',
          displayFormat: '{0} item(s)',
        },
      ],
    },
    showBorders: true,
    export: {
      enabled: true,
      allowExportSelectedData: true,
      fileName: 'One by ClariLog',
    },
    filterRow: {
      visible: true,
    },
    filterPanel: {
      visible: true,
    },
    onInitialized: (e) => {
      // En cours de test
      // window.addEventListener('resize', () => {
      //   e.component.updateDimensions();
      // });
    },
    onToolbarPreparing: (e) => {
      let dataGrid = e.component;
      e.toolbarOptions.items.unshift({
        widget: 'dxButton',
        location: 'after',
        options: {
          icon: 'refresh',
          onClick: () => {
            let dataSource = dataGrid.option('dataSource');
            if (dataSource && dataSource.reload) {
              dataSource.reload();
            }
          },
        },
      });
    },
  };

  getOptions(): any {
    return this.options;
  }
}
