import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetIisBaseVariables, FirstIisBaseVariables, CountIisBaseVariables, FindIisBaseVariables, SearchIisBaseVariables, ExportSchemaIisBaseVariables, ExportDataIisBaseVariables, CustomQueryIisBaseVariables, CustomQueryIdIisBaseVariables, UsedIisBaseVariables, ExistIisBaseVariables } from '../gqls'
import { GetIisDocument, FirstIisDocument, CountIisDocument, FindIisDocument, SearchIisDocument, ExportSchemaIisDocument, ExportDataIisDocument, CustomQueryIisDocument, CustomQueryIdIisDocument, UsedIisDocument, ExistIisDocument } from '../gqls'
import { ServiceSingleResultOfIis, QueryContextOfIis, FilterOfIis, ServiceSingleResultOfInt64, ServiceListResultOfIis, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class IiBaseService {
    
public modelName = 'ii';
public modelPluralName = 'iis';

	private getIisQuery: GetIisDocument;
	private firstIisQuery: FirstIisDocument;
	private countIisQuery: CountIisDocument;
	private findIisQuery: FindIisDocument;
	private searchIisQuery: SearchIisDocument;
	private exportSchemaIisQuery: ExportSchemaIisDocument;
	private exportDataIisQuery: ExportDataIisDocument;
	private customQueryIisQuery: CustomQueryIisDocument;
	private customQueryIdIisQuery: CustomQueryIdIisDocument;
	private usedIisQuery: UsedIisDocument;
	private existIisQuery: ExistIisDocument;

	constructor(private injector: Injector) {
		this.getIisQuery = this.injector.get(GetIisDocument);
		this.firstIisQuery = this.injector.get(FirstIisDocument);
		this.countIisQuery = this.injector.get(CountIisDocument);
		this.findIisQuery = this.injector.get(FindIisDocument);
		this.searchIisQuery = this.injector.get(SearchIisDocument);
		this.exportSchemaIisQuery = this.injector.get(ExportSchemaIisDocument);
		this.exportDataIisQuery = this.injector.get(ExportDataIisDocument);
		this.customQueryIisQuery = this.injector.get(CustomQueryIisDocument);
		this.customQueryIdIisQuery = this.injector.get(CustomQueryIdIisDocument);
		this.usedIisQuery = this.injector.get(UsedIisDocument);
		this.existIisQuery = this.injector.get(ExistIisDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIis> {
    
    		let variables: GetIisBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.get));
            }
            else{
                let result:ServiceSingleResultOfIis={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIis,
		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfIis> {
    
    		let variables: FirstIisBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountIisBaseVariables = {
    			filter: filter
    		}
    				return this.countIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIis,
		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIis> {
    
    		let variables: FindIisBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfIis,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIis> {
    
    		let variables: SearchIisBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaIisBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataIisBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfIis,
		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIis> {
    
    		let variables: CustomQueryIisBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfIis,
		@Args('filter?') filter?: FilterOfIis,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIis> {
    
    		let variables: CustomQueryIdIisBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedIisBaseVariables = {
    			ids: ids
    		}
    				return this.usedIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistIisBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existIisQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.iis.exist));
    	}
    
    
}