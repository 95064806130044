import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareReferentTypesBaseVariables, FirstSoftwareReferentTypesBaseVariables, CountSoftwareReferentTypesBaseVariables, FindSoftwareReferentTypesBaseVariables, SearchSoftwareReferentTypesBaseVariables, ExportSchemaSoftwareReferentTypesBaseVariables, ExportDataSoftwareReferentTypesBaseVariables, CustomQuerySoftwareReferentTypesBaseVariables, CustomQueryIdSoftwareReferentTypesBaseVariables, UsedSoftwareReferentTypesBaseVariables, ExistSoftwareReferentTypesBaseVariables, InsertSoftwareReferentTypesBaseVariables, UpdateSoftwareReferentTypesBaseVariables, ImportDataSoftwareReferentTypesBaseVariables, UpdateManySoftwareReferentTypesBaseVariables, DeleteSoftwareReferentTypesBaseVariables } from '../gqls'
import { GetSoftwareReferentTypesDocument, FirstSoftwareReferentTypesDocument, CountSoftwareReferentTypesDocument, FindSoftwareReferentTypesDocument, SearchSoftwareReferentTypesDocument, ExportSchemaSoftwareReferentTypesDocument, ExportDataSoftwareReferentTypesDocument, CustomQuerySoftwareReferentTypesDocument, CustomQueryIdSoftwareReferentTypesDocument, UsedSoftwareReferentTypesDocument, ExistSoftwareReferentTypesDocument, InsertSoftwareReferentTypesDocument, UpdateSoftwareReferentTypesDocument, ImportDataSoftwareReferentTypesDocument, UpdateManySoftwareReferentTypesDocument, DeleteSoftwareReferentTypesDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareReferentType, QueryContextOfSoftwareReferentType, FilterOfSoftwareReferentType, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareReferentType, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SoftwareReferentTypeInput, FieldUpdateOperatorOfSoftwareReferentType } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareReferentTypeBaseService {
    
public modelName = 'softwareReferentType';
public modelPluralName = 'softwareReferentTypes';

	private getSoftwareReferentTypesQuery: GetSoftwareReferentTypesDocument;
	private firstSoftwareReferentTypesQuery: FirstSoftwareReferentTypesDocument;
	private countSoftwareReferentTypesQuery: CountSoftwareReferentTypesDocument;
	private findSoftwareReferentTypesQuery: FindSoftwareReferentTypesDocument;
	private searchSoftwareReferentTypesQuery: SearchSoftwareReferentTypesDocument;
	private exportSchemaSoftwareReferentTypesQuery: ExportSchemaSoftwareReferentTypesDocument;
	private exportDataSoftwareReferentTypesQuery: ExportDataSoftwareReferentTypesDocument;
	private customQuerySoftwareReferentTypesQuery: CustomQuerySoftwareReferentTypesDocument;
	private customQueryIdSoftwareReferentTypesQuery: CustomQueryIdSoftwareReferentTypesDocument;
	private usedSoftwareReferentTypesQuery: UsedSoftwareReferentTypesDocument;
	private existSoftwareReferentTypesQuery: ExistSoftwareReferentTypesDocument;
	private insertSoftwareReferentTypesMutation: InsertSoftwareReferentTypesDocument;
	private updateSoftwareReferentTypesMutation: UpdateSoftwareReferentTypesDocument;
	private importDataSoftwareReferentTypesMutation: ImportDataSoftwareReferentTypesDocument;
	private updateManySoftwareReferentTypesMutation: UpdateManySoftwareReferentTypesDocument;
	private deleteSoftwareReferentTypesMutation: DeleteSoftwareReferentTypesDocument;

	constructor(private injector: Injector) {
		this.getSoftwareReferentTypesQuery = this.injector.get(GetSoftwareReferentTypesDocument);
		this.firstSoftwareReferentTypesQuery = this.injector.get(FirstSoftwareReferentTypesDocument);
		this.countSoftwareReferentTypesQuery = this.injector.get(CountSoftwareReferentTypesDocument);
		this.findSoftwareReferentTypesQuery = this.injector.get(FindSoftwareReferentTypesDocument);
		this.searchSoftwareReferentTypesQuery = this.injector.get(SearchSoftwareReferentTypesDocument);
		this.exportSchemaSoftwareReferentTypesQuery = this.injector.get(ExportSchemaSoftwareReferentTypesDocument);
		this.exportDataSoftwareReferentTypesQuery = this.injector.get(ExportDataSoftwareReferentTypesDocument);
		this.customQuerySoftwareReferentTypesQuery = this.injector.get(CustomQuerySoftwareReferentTypesDocument);
		this.customQueryIdSoftwareReferentTypesQuery = this.injector.get(CustomQueryIdSoftwareReferentTypesDocument);
		this.usedSoftwareReferentTypesQuery = this.injector.get(UsedSoftwareReferentTypesDocument);
		this.existSoftwareReferentTypesQuery = this.injector.get(ExistSoftwareReferentTypesDocument);
		this.insertSoftwareReferentTypesMutation = this.injector.get(InsertSoftwareReferentTypesDocument);
		this.updateSoftwareReferentTypesMutation = this.injector.get(UpdateSoftwareReferentTypesDocument);
		this.importDataSoftwareReferentTypesMutation = this.injector.get(ImportDataSoftwareReferentTypesDocument);
		this.updateManySoftwareReferentTypesMutation = this.injector.get(UpdateManySoftwareReferentTypesDocument);
		this.deleteSoftwareReferentTypesMutation = this.injector.get(DeleteSoftwareReferentTypesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentType> {
    
    		let variables: GetSoftwareReferentTypesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareReferentType={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentType,
		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentType> {
    
    		let variables: FirstSoftwareReferentTypesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareReferentTypesBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareReferentType,
		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentType> {
    
    		let variables: FindSoftwareReferentTypesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareReferentType,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentType> {
    
    		let variables: SearchSoftwareReferentTypesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareReferentTypesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareReferentTypesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareReferentType,
		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentType> {
    
    		let variables: CustomQuerySoftwareReferentTypesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareReferentType,
		@Args('filter?') filter?: FilterOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareReferentType> {
    
    		let variables: CustomQueryIdSoftwareReferentTypesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareReferentTypesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareReferentTypesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareReferentTypesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareReferentTypeInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentType> {
    
    		let variables: InsertSoftwareReferentTypesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareReferentTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareReferentType> {
    
    		let variables: UpdateSoftwareReferentTypesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareReferentTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareReferentTypesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareReferentTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareReferentType,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareReferentTypesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareReferentTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareReferentTypesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareReferentTypesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareReferentTypes.delete));
    	}
    
}