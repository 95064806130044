import DataGrid from 'devextreme/ui/data_grid';
import TreeView from 'devextreme/ui/tree_view';
import { Injectable } from '@angular/core';
import { TreeViewConfigure } from '@clarilog/core/configs';


@Injectable({
  providedIn: 'root'
})
export class ConfigureDevExtremeService {

  constructor() { }

  initialize(){

  }
}
