import {
  ChangeDetectorRef,
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import {
  ControlValueAccessor,
  UntypedFormGroup,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { Column } from '@clarilog/shared2/models/schema';
import { TemplatesService } from '../../templates/templates.service';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { TranslatedFieldHelperService } from '../../translate-field';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import { GC, GCFactory } from '@clarilog/core/services/gc/gc';

/** Représente le composent select simple list. */
@Component({
  selector: 'clc-select-simple-list',
  templateUrl: './select-simple-list.component.html',
  styleUrls: ['./select-simple-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreSelectSimpleListComponent),
      multi: true,
    },
  ],
})
export class CoreSelectSimpleListComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  @Input() noForm: boolean = false;
  @Input() type: string;
  @Input() control: any;
  @Input() fg: UntypedFormGroup;

  @Input() generateId: boolean = false;
  @Input() readOnly: boolean = false;
  @Input() columns: Column[];
  @Input() source: ModelDataSourceContext;
  @Input() allowAdding: boolean = true;
  @Input() allowDelete: boolean = true;
  @Input() batchMode: boolean = true;
  @Input() state: ModelState;
  @Input() fieldName: string;
  @Input() showFilterRow: boolean = false;
  namePattern = /^(\d*(\.))*\d+$/;
  /** Sauvegarde les valeurs. */
  _values: any[];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};

  /** Obtient le cellule active */
  component: any;
  /** Indique si le composant est en edition */
  inEditing: boolean = false;

  /** Affiche les titres des colonnes */
  @Input() showColumnHeaders: boolean = false;
  _initializeState: boolean = false;

  selectedLanguage = 'en';
  languageSource: Array<{ key: string; value: string }> = [];
  supportedLanguages: string[];

  /** Obtient ou définit les données sélectionnées. */
  selectedData: any[] = [];
  private _gc: GC;
  data: any;
  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    if (this._values != undefined) {
      this._values.forEach((f) => delete f['__KEY__']);
      this._values.forEach((f) => delete f['__typename']);
    } else {
      this._values = [];
    }
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this._initializeState === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  constructor(
    public templateService: TemplatesService,
    private cd: ChangeDetectorRef,
    private translateFieldHelperService: TranslatedFieldHelperService,
    private localStorageService: LocalStorageService,
    gcFactory: GCFactory,
  ) {
    this._gc = gcFactory.create();
    this.languageSource = translateFieldHelperService.getLanguageDatasource();
    this.selectedLanguage = translateFieldHelperService.getTranslateKey();
    this.supportedLanguages =
      translateFieldHelperService.getAllSupportedLanguage();
    this._gc.forDispose(
      this.localStorageService.ModelState.on.subscribe((f) => {
        if (
          f.eventName == LocalStorageService.EventConst.ReloadSelectSimpleList
        ) {
          this.source.datasource.reload();
        }
      }),
    );
  }

  ngOnInit() {
    if (this.source != undefined) {
      this.source.datasource.load().then((r) => {
        this.data = r;
      });
    }
    this.state.on.subscribe((event) => {
      if (
        event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
      ) {
        this.selectedLanguage = event.eventData;
        // Changement de langue
        let gridColumn = this.component.option('columns');
        gridColumn.forEach((col) => {
          let isTranslatable = this.columns.find(
            (s) => s.label == col.caption && s.translatable === true,
          );
          if (isTranslatable != undefined) {
            let split = col.dataField.split('.');
            split.splice(split.length - 1, 1);
            split.push(this.selectedLanguage);
            col.dataField = split.join('.');
          }
        });
        this.component.option('columns', gridColumn);
      }
    });
  }

  /** @inheritdoc */
  writeValue(values: any[]): void {
    this._initializeState = true;
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
    if (values != undefined) {
      this.selectedData = values;
    }
    this._initializeState = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  getValues(e) {
    setTimeout(() => {
      //this.controlValue();
      this.onChange(this._values);
      this.onTouched();
    });
  }

  onInitialized(e) {
    this.component = e.component;
  }

  onSelectionChanged(e) {
    this.onChange(e.selectedRowKeys);
    this.onTouched();
  }

  ngOnDestroy() {
    this._gc.dispose();
  }
}
