import {
  ChangeDetectorRef,
  Component,
  Injector, Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Router, UrlSerializer } from '@angular/router';
import { Pooling } from '@clarilog/core';
import CustomStore from 'devextreme/data/custom_store';
import { ModelDataSourceContext, ModelState } from '@clarilog/shared2/services/compiler/model-state';
import {
  CoreGraphQLSource,
  GraphQLStore,
} from '@clarilog/core/services2/graphql/graphql-store.service';
import { NotificationCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2';
import {
  Notification,
  NotificationKey,
  Sort,
} from '@clarilog/core/services2/graphql/generated-types/types';

@Component({
  selector: 'clc-notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss'],
})
/** Composant de gestion des alertes */
export class CoreNotificationComponent implements OnInit, OnDestroy {
  source: ModelDataSourceContext;
  store: CustomStore;
  /** Variable des éléments cible de positionnement */
  itemButton: any;
  public showItem: boolean = false;
  private timer: NodeJS.Timeout;
  public alertNumber: number = 0;

  /** Obient si le popup est ouvert */
  isShow: boolean = false;
  @Input() state: ModelState;
  constructor(
    public notificationService: NotificationCoreService,
    private serializer: UrlSerializer,
    private router: Router,
    private changeDetectorRef: ChangeDetectorRef,
    private poolingService: Pooling,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {}

  ngOnInit() {
    this.source = new ModelDataSourceContext({
      serviceName: 'notificationService',
      methodName: 'findMyNotification',
    });

    this.source.datasource = CoreGraphQLSource.create({
      context: this.source,
      query: (filters?: any, options?: any) => {
        let key = this.translatedFieldHelperService.getTranslateKey();
        let sort = {};
        sort[key] = Sort.Asc;
        // options.sort = [{ name: [sort] }];
        return this.notificationService.findMyNotification(
          [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlField.create('urlKey'),
              GqlField.create('urlObjectId'),
              GqlField.create('isRead'),
              GqlSubField.create(
                'title',
                this.translatedFieldHelperService.translatedFields(),
              ),
              GqlSubField.create(
                'text',
                this.translatedFieldHelperService.translatedFields(),
              ),
            ]),
          ],
          options,
          {isRead: {eq: false}}
        );
      },
    });

    this.updateAlertNumber();
    this.store = this.source.datasource.store() as CustomStore;
    this.timer = this.poolingService.poolingByCallback(
      this.updateAlertNumber.bind(this),
      30000,
    );

    this.state.on.subscribe(event => {
      if (event.eventName === 'refresh-notification') {
        this.updateAlertNumber();
      }
    })
  }

  async remove(notification: Notification) {
    this.notificationService
      .delete(ModelFieldCompilerService.createServiceSingleResultScalar(), [
        notification.id,
      ])
      .subscribe((_) => {
        this.updateAlertNumber();
      });
  }
  async toogleReadUnRead(notification: Notification) {
    if (notification.isRead === true) {
      this.notificationService
        .markAsUnRead(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          notification.id,
        )
        .subscribe(() => {
          this.updateAlertNumber();
        });
    }
    if (notification.isRead === false) {
      this.notificationService
        .markAsRead(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          notification.id,
        )
        .subscribe(() => {
          this.updateAlertNumber();
        });
    }
  }

  /** Événement d'initialisation */
  onAlertButtonInitialized(e) {
    this.itemButton = e.element;
    this.changeDetectorRef.detectChanges();
    // this.source.datasource.load();

    //update the number of unseen alert
    this.updateAlertNumber();
  }

  itemClick(e) {
    this.updateAlertNumber();

    this.showItem = true;
  }

  // gestion du click sur la notif pour rediriger vers la bonne page du bon metier
  clickEvent(event, notification: Notification) {
    this.notificationService.manageNotificationOpening(this.router, notification, this.serializer)
  }

  /** Force l'actualisation */
  onShowing(e) {
    this.isShow = true;
    this.source.datasource.reload();
  }
  onHiding(e) {
    this.isShow = false;
  }
  /** Filtre par défaut */
  filterUnread() {
    return {
      isRead: { eq: false },
    };
  }
  /** Fonction callback permettant de mettre a jour en pooling le nombre d'alarmes non vue */
  updateAlertNumber() {
    if (this.isShow) {
      this.source.datasource.reload();
    }

    this.notificationService
      .findMyNotification(
        [GqlField.create('totalCount')],
        undefined,
        this.filterUnread(),
      )
      .subscribe((res) => {
        //let unread = res.data.filter((el) => el.isRead === false);
        this.updateNumber(res.totalCount != undefined ? res.totalCount : 0);
      });
  }

  updateNumber(value: number) {
    this.alertNumber = value;
  }

  ngOnDestroy(): void {
    (
      this.source.datasource.store() as GraphQLStore
    ).context.context.clearTimeout();
    clearTimeout(this.timer);
  }
}
