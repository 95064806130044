<clc-manage-list
  [modelState]="modelState"
  [service]="emailPrototypeService"
  [newCommand]="newCommand"
  [(selectedKeys)]="selectedKeys"
>
  <cl-toolbar-items>
    <cl-toolbar-item-dropdown-button
      *ngIf="dropdownButton === true"
      [text]="'entities/emailPrototype/duplicate' | translate"
      icon="'far fa-random'"
      [visible]="visibleDupicate"
      [disabled]="selectedKeys.length != 1"
      [source]="duplicateDataSource"
      [keyExpr]="'id'"
      [displayExpr]="'name'"
      (onItemClick)="duplicateType($event)"
    >
    </cl-toolbar-item-dropdown-button>
    <cl-toolbar-item-button
      *ngIf="dropdownButton === false"
      [text]="'entities/emailPrototype/duplicate' | translate"
      icon="'far fa-random'"
      [visible]="visibleDupicate"
      [disabled]="selectedKeys.length !== 1"
      (onClick)="duplicateType($event)"
    >
    </cl-toolbar-item-button>
  </cl-toolbar-items>
</clc-manage-list>
