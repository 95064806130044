import { LdapOrganizationalUnitBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { InjectArgs, Args } from '@clarilog/core/modules';
import {
  ServiceListResultOfLdapOrganizationalUnit,
  FilterOfLdapOrganizationalUnit,
  QueryContextOfLdapOrganizationalUnit,
  LdapObjectClassType,
} from '../types';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import { Observable } from 'rxjs';
import { FindTreeLdapLdapOrganizationalUnitsBaseVariables } from '../gqls/ldap-organizational-unit.gqls';

@Injectable({ providedIn: 'root' })
export class LdapOrganizationalUnitCoreService extends LdapOrganizationalUnitBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Récupère la liste LDAP des object de types Computer */
  @InjectArgs
  findLdapAsset(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
    @Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
    extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let newFilter: FilterOfLdapOrganizationalUnit = {
      ldapObjectClassType: { eq: LdapObjectClassType.Asset },
    };

    if (filter != undefined) {
      newFilter.and = [filter];
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  findLdapOrganizationUnit(
    @Args('fields') fields: GqlFields,
    @Args('filter?') filter?: FilterOfLdapOrganizationalUnit,
    @Args('options?') options?: QueryContextOfLdapOrganizationalUnit,
    extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let newFilter: FilterOfLdapOrganizationalUnit = {
      ldapObjectClassType: { eq: LdapObjectClassType.Organization },
    };

    if (filter != undefined) {
      newFilter.and = [filter];
    }

    return this.find(fields, options, newFilter, extendedVariables);
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  public findTreeLdapByRole(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let ldapObjectClassType: LdapObjectClassType = LdapObjectClassType.Role;
    return this.findTreeLdap(fields, ldapObjectClassType, extendedVariables);
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  public findTreeLdapByOU(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let ldapObjectClassType: LdapObjectClassType =
      LdapObjectClassType.Organization;
    return this.findTreeLdap(fields, ldapObjectClassType, extendedVariables);
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  public findTreeLdapByAll(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let ldapObjectClassType: LdapObjectClassType = LdapObjectClassType.All;
    return this.findTreeLdap(fields, ldapObjectClassType, extendedVariables);
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  public findTreeLdapByScanconfigurationByOu(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let ldapObjectClassType: LdapObjectClassType =
      LdapObjectClassType.Organization;
    return this.findTreeLdapByScanconfiguration(
      fields,
      ldapObjectClassType,
      id,
      extendedVariables,
    );
  }

  /** Récupère la liste LDAP des object de types unité organizationelle */
  @InjectArgs
  public findTreeLdapByScanconfigurationAll(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapOrganizationalUnit> {
    let ldapObjectClassType: LdapObjectClassType = LdapObjectClassType.All;
    return this.findTreeLdapByScanconfiguration(
      fields,
      ldapObjectClassType,
      id,
      extendedVariables,
    );
  }
}
