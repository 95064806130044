import {
  AfterContentChecked,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import {
  GCFactory,
  ServicePermission,
  StateManagementService,
} from '@clarilog/core';
import { confirm, custom } from 'devextreme/ui/dialog';
import notify from 'devextreme/ui/notify';
import { of } from 'rxjs';
import { TranslateService, ModelFieldCompilerService } from '../../../services';
import { ModelDataSourceContext } from '@clarilog/shared2/services/compiler/model-state';
import { ActivatedRoute, Router } from '@angular/router';
import { CorePolicyValidator } from '@clarilog/core/services2/authorization/authorization-policy-builder.service';
import { CoreManageListComponent } from '../manage-list/manage-list.component';
import { IncidentCoreService } from '@clarilog/core/services2/graphql/generated-types/services/incident.service';
import DataSource from 'devextreme/data/data_source';
import { TicketCoreService } from '@clarilog/core/services2/graphql/generated-types/services/ticket.service';
import { CoreImportDataPopupComponent } from '../../import-data-popup/import-data-popup.component';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  PrivilegeCoreService,
  ProblemCoreService,
  RequestCoreService,
  UserCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { IncidentHelpDeskCoreService } from '@clarilog/core/services2/graphql/generated-types/services/incident.help-desk.service';
import massiveEditModel from './massive.edit.model.json';
import { CoreMassiveEditComponent } from '../../massive-edit/massive-edit.component';
import { FormGroupHelpers } from '../../form/work-form/form-group-helpers';
import {
  StatusNode,
  Ticket,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { LocalStorageService } from '@clarilog/core/services2/graphql/generated-types/services/local-storage-service/local-storage-service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { UntypedFormControl } from '@angular/forms';
import * as pluralize from 'pluralize';
import { TranslatedFieldHelperService } from '../../translate-field';

import { Column } from '@clarilog/shared2/models/schema';

import ArrayStore from 'devextreme/data/array_store';
import { CoreSelectListComponent } from '../select-list/select-list.component';
@Component({
  selector: 'clc-ticket-list',
  templateUrl: './ticket-list.component.html',
  styleUrls: ['./ticket-list.component.scss'],
})
export class CoreTicketListComponent
  extends CoreManageListComponent
  implements OnChanges, OnDestroy, OnInit, AfterContentChecked
{
  @ViewChild(CoreManageListComponent, { static: true })
  manageList: CoreManageListComponent;
  @Input() isFavorite: boolean = false;
  active: boolean = false;
  newCommandModel = {};
  canAddTicket: boolean = true;
  canEditTicket: boolean;
  canDeleteTicket: boolean = true;
  canDeleteTicketIncident: boolean = true;
  canDeleteTicketRequest: boolean = true;
  canImportTicket: boolean;
  enableEditTicket: boolean;
  enableDeleteTicket: boolean;
  checkLock: boolean;
  policies: any[] = [];
  @Input() showImport: boolean = true;
  @Input() layoutKey: string = undefined;
  massiveEditModel;
  isMassiveEditVisible: boolean = false;
  @ViewChild(CoreMassiveEditComponent, { static: true })
  massiveEditComponent: CoreMassiveEditComponent;
  titleMassiveEdit: string = 'editmassive';
  titleEdit: String = 'edit';
  enableMassiveEdit: boolean = false;
  intervalCheck: any;
  warningMessage: string;
  displayPopUp: boolean;
  isGroupTicketVisible: boolean;
  customTitle: string;
  groupTicketColumns: Column[];
  @Input() hideSomeButton;
  @Input() isQueryBuilder: boolean = false;
  @Input() archiveMode: boolean = false;
  groupTicketSource;
  @ViewChild(CoreSelectListComponent, { static: true })
  selectListComponent: CoreSelectListComponent;
  @Input() displayResearchButton: boolean = false;
  visibleGroupTicket: boolean = false;
  visibleArchiveTicket: boolean = false;
  statusEnd: StatusNode;
  activeChangeTicketTypeButton: boolean = true;
  waitArchive: boolean = false;
  waitPrint: boolean = false;
  actionBtnSource: { id: string; name: string }[];
  @Input() showArchive: boolean = false;
  displayActionBeingPerformed: boolean = false;
  canChangeTicketNature: boolean;

  constructor(
    private routerTicket: Router,
    public route: ActivatedRoute,
    private servicePermissionTicket: ServicePermission,
    private policyValidatorTicket: CorePolicyValidator,
    private gcFactoryTicket: GCFactory,
    private _stateTicket: StateManagementService,
    private incidentService: IncidentCoreService,
    public ticketService: TicketCoreService,
    private requestCoreService: RequestCoreService,
    private problemCoreService: ProblemCoreService,
    private incidentHelpDeskCoreService: IncidentHelpDeskCoreService,
    private changeDetectorRef: ChangeDetectorRef,
    public localStorageService: LocalStorageService,
    private userService: UserCoreService,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(
      routerTicket,
      route,
      servicePermissionTicket,
      policyValidatorTicket,
      gcFactoryTicket,
      _stateTicket,
      localStorageService,
    );
    this.gc = gcFactoryTicket.create();
  }

  ngOnDestroy(): void {
    this.gc.dispose();
  }

  @ViewChild(CoreImportDataPopupComponent, { static: true })
  importComponent: CoreImportDataPopupComponent;
  importDataSource: ModelDataSourceContext = null;
  editMassiveDataSource: ModelDataSourceContext = null;
  // Create the DataSource pour le bouton import
  importSource(): ModelDataSourceContext {
    return new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filters?: any) => {
          let data = [
            {
              name: this.modelState.model.title,
              key: 'ticket',
            },
            {
              name: TranslateService.get('entities/incident/otherUserImpacted'),
              key: 'otherUserImpacted',
            },
            {
              name: TranslateService.get(
                'entities/incident/otherEquipmentImpacted',
              ),
              key: 'otherAssetImpacted',
            },
            {
              name: TranslateService.get('entities/incident/note'),
              key: 'note',
            },
            {
              name: TranslateService.get('entities/intervention/_title/plural'),
              key: 'intervention',
            },
            {
              name: TranslateService.get('entities/task/_title/plural'),
              key: 'ticketTask',
            },
          ];
          let qualification =
            this.modelState?.sharedContext?.params?.get('qualification');
          if (qualification === 'problem') {
            let exludes = ['otherUserImpacted', 'otherAssetImpacted', 'note'];

            data = data.filter((d) => exludes.includes(d.key) === false);
          }

          return of({
            data: data,
            totalCount: 10,
          });
        },
      }),
    });
  }

  editMassiveSource(): ModelDataSourceContext {
    return new ModelDataSourceContext({
      datasource: CoreGraphQLSource.create({
        query: (filters?: any) => {
          return of({
            data: [
              {
                key: 'common',
                name: TranslateService.get('entities/ticket/titleMassiveEdit'),
              },
            ],
            totalCount: 1,
          });
        },
      }),
    });
  }

  onButtonClick(e) {
    this.importComponent.changeTitle(e.itemData.name);
    this.importComponent.open();

    this.importComponent.setService(this.service, false);
    this.importComponent.SetSuffix(e.itemData.key);
    this.importComponent.exportSchemaFn = (fields, format) => {
      return (this.service as TicketCoreService).schemasTicketLinkType(
        fields,
        format,
        e.itemData.key,
      );
    };
    this.importComponent.importDataFn = (fields, format, file) => {
      return (this.service as TicketCoreService).importLinkByType(
        fields,
        format,
        e.itemData.key,
        file,
      );
    };
    this.importComponent.import({
      itemData: '',
    });
  }

  onSuccess(e) {
    this.manageList.refresh();
  }

  ngOnInit(): void {
    this.customTitle = `${TranslateService.get(
      'entities/incident/favorite',
    )}: ${TranslateService.get('entities/ticket/research')} `;
    this.massiveEditModel = massiveEditModel;
    this.titleEdit = TranslateService.get('edit');
    // Vérifie le privilege de l'edition massive
    this.localStorageService.user.getPrivilege().subscribe((res) => {
      if (res.data != null) {
        // Privilege pour new article KB
        this.enableMassiveEdit = PrivilegeCoreService.hasPrivilege(
          res.data,
          'allow-massive-edit',
        );
      }

      if (this.enableMassiveEdit) {
        this.titleMassiveEdit = TranslateService.get('edit');
        let mEditModel = JSON.stringify(massiveEditModel);
        switch (this.service.modelName) {
          case 'incident':
            mEditModel = mEditModel.replace(/"visibleTags"/g, 'true');
            break;
          case 'request':
            mEditModel = mEditModel.replace(
              /IncidentCoreService/g,
              'RequestCoreService',
            );
            mEditModel = mEditModel.replace(/"visibleTags"/g, 'true');
            break;
          case 'problem':
            mEditModel = mEditModel.replace(
              /IncidentCoreService/g,
              'ProblemCoreService',
            );
            mEditModel = mEditModel.replace(/"visibleTags"/g, 'false');
            break;
        }
        this.massiveEditModel = JSON.parse(mEditModel);
      }
    });

    this.initPrivilege();

    this.importDataSource = this.importSource();
    this.editMassiveDataSource = this.editMassiveSource();
    this.policies['incident'] = this.servicePermissionTicket.getAuthorizations(
      this.incidentService as any,
    );
    if (this.policies['incident'].policies != undefined)
      this.policies['incident'] = this.policies['incident'].policies;
    this.policies['request'] = this.servicePermissionTicket.getAuthorizations(
      this.requestCoreService as any,
    );
    if (this.policies['request'].policies != undefined)
      this.policies['request'] = this.policies['request'].policies;
    this.policies['problem'] = this.servicePermissionTicket.getAuthorizations(
      this.problemCoreService as any,
    );
    if (this.policies['problem'].policies != undefined)
      this.policies['problem'] = this.policies['problem'].policies;
    if (this.service.modelName == 'ticket') {
      let type = this.route.routeConfig.data.type;
      this.layoutKey = `ticket_all`;

      if (type != undefined) {
        this.layoutKey = `ticket_${type}`;
      } else {
        this.layoutKey = `ticket_new`;
      }

      if (this.archiveMode == true) {
        this.layoutKey = `ticket_archived`;
      }

      this.active = true;
      let canAddIncident = this.policyValidatorTicket.validate(
        `${this.policies['incident']}.write`,
      );
      let canAddRequest = this.policyValidatorTicket.validate(
        `${this.policies['request']}.write`,
      );
      this.canAddTicket = canAddIncident || canAddRequest;

      this.canEditTicket =
        this.policyValidatorTicket.validate(
          `${this.policies['incident']}.update`,
        ) ||
        this.policyValidatorTicket.validate(
          `${this.policies['request']}.update`,
        );

      this.canDeleteTicket =
        this.policyValidatorTicket.validate(
          `${this.policies['incident']}.delete`,
        ) ||
        this.policyValidatorTicket.validate(
          `${this.policies['request']}.delete`,
        );

      this.canDeleteTicketIncident = this.policyValidatorTicket.validate(
        `${this.policies['incident']}.delete`,
      );
      this.canDeleteTicketRequest = this.policyValidatorTicket.validate(
        `${this.policies['request']}.delete`,
      );
      this.canImportTicket =
        this.policyValidatorTicket.validate(
          `${this.policies['incident']}.execute-import`,
        ) ||
        this.policyValidatorTicket.validate(
          `${this.policies['request']}.execute-import`,
        );

      let dataTicketType = [];
      if (canAddIncident)
        dataTicketType.push({
          id: 'incident',
          name: TranslateService.get('entities/incident/_title/singular'),
        });
      if (canAddRequest)
        dataTicketType.push({
          id: 'request',
          name: TranslateService.get('entities/request/_title/singular'),
        });

      this.newCommandModel = {
        keyExpr: 'id',
        displayExpr: 'name',
        targetIdExpr: 'id',
        source: new ModelDataSourceContext({
          datasource: new DataSource(
            dataTicketType.sort((a, b) => (a.name > b.name ? 1 : -1)),
          ),
        }),
        onItemClick: this.onNewTicketClick.bind(this),
        splitButton: false,
      };
    } else {
      this.canImportTicket = this.policyValidatorTicket.validate(
        `${this.policies[this.service.modelName]}.execute-import`,
      );
      this.canEditTicket = this.policyValidatorTicket.validate(
        `${this.policies[this.service.modelName]}.update`,
      );
      this.canAddTicket = this.policyValidatorTicket.validate(
        `${this.policies[this.service.modelName]}.write`,
      );
      this.canDeleteTicket = this.policyValidatorTicket.validate(
        `${this.policies[this.service.modelName]}.delete`,
      );
    }
    this.activeChangeTicketTypeButton =
      this.ticketService.getCanChangeTicketTypeValue();
    if (this.hideSomeButton) {
      this.canDelete = false;
      this.canAddTicket = false;
      this.active = true;
      this.canImportTicket = false;
      this.canDeleteTicket = false;
    }

    if (this.archiveMode == false) {
      this.manageList.hideDeleteButton = true;
    }

    this.groupTicketSource = new ModelDataSourceContext({
      datasource: new DataSource({
        store: new ArrayStore({
          data: [],
        }),
      }),
    });

    if (this.hideSomeButton && this.displayResearchButton) {
      this.displayPopUp = true;
    }

    if (this.archiveMode) {
      this.canAdd = false;
      this.canAddTicket = false;
      this.canEdit = false;
      this.canEditTicket = false;
      this.active = true;
      this.canImportTicket = false;
    }
    this.manageList.refreshCounter = true;
  }

  onNewTicketClick(e) {
    if (this.service.modelName == 'ticket') {
      let getKey = e.itemData['id'];
      e.event.preventDefault();

      let itemKey = 'incident';

      if (getKey == 'request') {
        itemKey = getKey;
      }

      let ticketUrl = this.policyValidatorTicket.manageUrlBasedOnPermissions(
        `${itemKey}s/new`,
        itemKey,
      );
      //C'est utilisé dans le cadre d'un ticket créé depuis mes tickets favoris
      if (this.routerTicket.url === '/favorite-tickets') {
        this.routerTicket.navigate([ticketUrl], {
          queryParams: {
            isFavorite: false,
            backUrl: this.routerTicket.url,
          },
        });
      } else {
        this.routerTicket.navigate([ticketUrl], {
          queryParams: {
            backUrl: this.routerTicket.url,
          },
        });
      }
    }
  }

  onEditTicketClick(e) {
    if (this.selectedKeys?.length > 1 && this.enableMassiveEdit) {
      return null;
    }
    let params: any = {};
    let selectedId = this.selectedKeys[0];
    let baseUrl = this.routerTicket.url;
    if (baseUrl.includes('research')) {
      baseUrl = pluralize.plural(
        (this.service.modelName as string).toLowerCase(),
      );
    }
    let url = baseUrl + '/edit/' + selectedId;
    if (this.service.modelName == 'ticket') {
      let selectedType =
        this.manageList.selectedData[0].__typename.toLowerCase() + 's';

      url = this.policyValidatorTicket.manageUrlBasedOnPermissions(
        selectedType + '/edit/' + selectedId,
        this.manageList.selectedData[0].__typename.toLowerCase(),
      );

      if (this.hideSomeButton == false) {
        params.backUrl = this.routerTicket.url;
        if (this.isFavorite) {
          params.isFavorite = true;
        }
      }
    }

    this.routerTicket.navigate([`${url}`], {
      queryParams: params,
    });
  }

  onSelectionTicketChanged(e) {
    let ticketsSelected = [];

    this.manageList.selectedData.forEach((el) => {
      this.incidentService
        .checkLockTicket(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          el.id,
        )
        .subscribe((x) => {
          ticketsSelected.push(x.data);
          if (
            ticketsSelected.filter(function (value) {
              return value === true;
            }).length == this.selectedData.length
          ) {
            this.enableEditTicket = false;
          } else {
            if (this.service.modelName == 'ticket') {
              if (this.manageList.selectedData.length > 0) {
                let selectedType = this.manageList.selectedData.map((x) =>
                  x.__typename.toLowerCase(),
                );
                this.enableEditTicket =
                  selectedType.filter(
                    (f) =>
                      !this.policyValidatorTicket.validate(
                        `${this.policies[f]}.update`,
                      ),
                  ).length == 0;
                this.enableDeleteTicket =
                  selectedType.filter(
                    (f) =>
                      !this.policyValidatorTicket.validate(
                        `${this.policies[f]}.delete`,
                      ),
                  ).length == 0;
              }
            } else {
              this.enableEditTicket = true;
              this.enableDeleteTicket = true;
            }
            this.createButtonAction();
          }
        });
    });
  }
  takeCharge() {
    this.service
      .changeAffectedByTicketIds(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        this.selectedKeys,
      )
      .subscribe((res) => {
        if (res?.data?.length > 0) {
          notify(
            TranslateService.get('entities/incident/errors/alreadyMultiple') +
              res.data,
            'error',
            5000,
          );
          this.manageList.refresh();
        } else {
          if (res?.errors?.length > 0) {
            for (let error of res.errors) {
              notify(TranslateService.get(error.messageError), 'error', 5000);
            }
            setTimeout(() => {
              notify(
                TranslateService.get('entities/incident/success/ownAffected'),
                'success',
                5000,
              );
            }, 5000);
          } else {
            notify(
              TranslateService.get('entities/incident/success/ownAffected'),
              'success',
              5000,
            );
          }

          this.manageList.refresh();
        }
      });
  }

  /** Changement de type de nature d'un ticket */
  async transform() {
    let message = this.getConfirmMessage();
    let result = await confirm(
      message,
      TranslateService.get('confirm-title'),
    ).then((result) => {
      return result;
    });

    if (result) {
      var resultTransform = await this.service
        .transformOn(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys,
        )
        .toPromise();

      if (resultTransform?.errors.length == 0) {
        notify(
          TranslateService.get('entities/incident/changeSuccess'),
          'success',
          5000,
        );
        this.manageList.refresh();
      } else {
        notify(resultTransform.errors[0].messageError, 'error', 5000);
      }
    }
  }

  /**Action sur le bouton edit */
  onItemEditButtonClick(e) {
    if (this.massiveEditComponent.modelState != undefined) {
      this.manageEnableField(this.massiveEditComponent.modelState, false, true);
    }
    this.isMassiveEditVisible = true;

    this.gc.forRelease(
      this.ticketService
        .checkLockTickets(
          [
            GqlField.create('data'),
            GqlSubField.create('errors', [GqlField.create('messageError')]),
            GqlField.create('totalCount'),
          ],
          this.selectedKeys,
        )
        .subscribe((x) => {
          let ticketsLocked = x.data;
          let ticketToLock = this.selectedKeys.filter(
            (x) => !ticketsLocked.includes(x),
          );
          if (ticketsLocked?.length > 0) {
            this.selectedKeys = ticketToLock;
            let ticketLocked = this.selectedData.filter((x) =>
              ticketsLocked.includes(x.id),
            );
            if (ticketLocked.length == 1) {
              this.warningMessage = TranslateService.get(
                'entities/ticket/lockedMsgMassiveEditSingular',
                ticketLocked.map((x) => x.ticketNumber).join(','),
              );
            } else {
              this.warningMessage = TranslateService.get(
                'entities/ticket/lockedMsgMassiveEdit',
                ticketLocked.map((x) => x.ticketNumber).join(','),
              );
            }
          } else {
            this.warningMessage = '';
          }

          this.intervalCheck = setInterval(() => {
            this.ticketService
              .checkLockTickets(
                [
                  GqlField.create('data'),
                  GqlSubField.create('errors', [
                    GqlField.create('messageError'),
                  ]),
                  GqlField.create('totalCount'),
                ],
                this.selectedKeys,
              )
              .subscribe();
          }, 30000);
        }),
    );
  }
  massiveEditsaved($event) {
    var addTags = FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'add_tags',
    ).value;
    var removeTags = FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'remove_tags',
    ).value;
    if (addTags != undefined || removeTags != undefined) {
      this.ticketService
        .updateTags(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys,
          removeTags,
          addTags,
        )
        .subscribe(() => {
          this.massiveEditClose();
        });
    } else {
      this.massiveEditClose();
    }
  }
  massiveEditVisibleChange(e) {
    if (e == false && this.selectedKeys?.length > 0) {
      clearInterval(this.intervalCheck);
      this.ticketService
        .removeLockTickets(
          ModelFieldCompilerService.createServiceSingleResultScalar(),
          this.selectedKeys,
        )
        .toPromise();
    }
  }
  massiveEditClose() {
    this.manageList.refresh();
    this.massiveEditComponent?.modelState?.form.reset();
    this.changeDetectorRef?.detectChanges();
  }
  massiveEditFormLoaded() {
    this.manageEnableField(this.massiveEditComponent.modelState, true, false);
    this.changeDetectorRef?.detectChanges();
  }

  manageEnableField(
    modelState,
    eventValueChanged: boolean,
    defaultValue: boolean,
  ) {
    let controls = FormGroupHelpers.formControls(modelState.form);
    controls.forEach((ctrl) => {
      if (
        ctrl.key.includes('enable_') ||
        ctrl.key.includes('assignment_') ||
        ctrl.key.includes('status_')
      ) {
        if (defaultValue == true) {
          ctrl.value.setValue(false);
        }
        if (eventValueChanged == true) {
          if (ctrl.key.includes('enable_')) {
            let controlValueField = controls.find(
              (c) => c.key == ctrl.key.replace('enable_', ''),
            );
            modelState.gc.forRelease(
              ctrl.value.valueChanges.subscribe((value) => {
                this.manageEnableValueChanged(
                  value,
                  controlValueField.key,
                  controlValueField.value,
                );
              }),
            );
          }
          if (ctrl.key.includes('assignment_')) {
            let type = ctrl.key.replace('assignment_', '');
            let controlTeam = controls.find(
              (c) => c.key == 'operatorTeam' + type,
            );
            let controlOperator = controls.find(
              (c) => c.key == 'operator' + type,
            );
            modelState.gc.forRelease(
              controlOperator.value.valueChanges.subscribe((value) => {
                this.loadMainOperatorTeam(value, controlTeam.value);
              }),
            );
            let controlLevel = controls.find((c) => c.key == 'levelSupport');

            modelState.gc.forRelease(
              ctrl.value.valueChanges.subscribe((value) => {
                this.manageEnableValueChanged(
                  value,
                  controlTeam.key,
                  controlTeam.value,
                );
                this.manageEnableValueChanged(
                  value,
                  controlOperator.key,
                  controlOperator.value,
                );
                if (type == 'AffectedId') {
                  this.manageEnableValueChanged(
                    value,
                    controlLevel.key,
                    controlLevel.value,
                  );
                }
              }),
            );
            if (type == 'AffectedId') {
              modelState.gc.forRelease(
                controlLevel.value.valueChanges.subscribe((value) => {
                  controlTeam.value.setValue(null);
                  controlOperator.value.setValue(null);
                }),
              );
            }
          }
          if (ctrl.key.includes('status_')) {
            let controlStatus = controls.find((c) => c.key == 'statusId');
            let controlStatusReason = controls.find(
              (c) => c.key == 'statusReasonId',
            );
            modelState.gc.forRelease(
              controlStatus.value.valueChanges.subscribe((value) => {
                controlStatusReason.value.setValue(null);
                setTimeout(() => {
                  controlStatusReason.value.markAsDirty();
                });
              }),
            );
            modelState.gc.forRelease(
              ctrl.value.valueChanges.subscribe((value) => {
                this.manageEnableValueChanged(
                  value,
                  controlStatus.key,
                  controlStatus.value,
                );
                this.manageEnableValueChanged(
                  value,
                  controlStatusReason.key,
                  controlStatusReason.value,
                );
              }),
            );
          }
        }
      }
    });

    let status = [
      ...new Set(
        (this.manageList.selectedData as Array<Ticket>).map(
          (x) => x.status?.data?.key,
        ),
      ),
    ];
    let enableStatusField = FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'status_statusId',
    );
    if (status.length == 1 && status[0] != undefined) {
      enableStatusField.visible();
      modelState.sharedContext.params.set('id', () => this.selectedKeys[0]);
    } else {
      enableStatusField.invisible();
    }

    let enablePriorityField = FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'enable_priorityId',
    );

    this.localStorageService.ticket
      .matrix(this.service.modelName)
      .toPromise()
      .then((x) => {
        if (x == true) {
          enablePriorityField.invisible();
        } else {
          enablePriorityField.visible();
        }
      });

    FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'add_tags',
    ).reset();
    FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'remove_tags',
    ).reset();
    FormGroupHelpers.formControlByName(
      this.massiveEditComponent.modelState.form,
      'statusId',
    ).disable();
  }

  manageEnableValueChanged(value, key: string, control: UntypedFormControl) {
    if (value == true) {
      control.setValue(null);
      control['readOnly'] = false;
      control['forceSendValue'] = true;
      if (key == 'levelSupport') {
        control.setValue(1);
        control.markAsDirty();
      }
      if (key == 'statusId') {
        control.enable();
      }
    } else {
      control['readOnly'] = true;
      control['forceSendValue'] = false;
      if (key == 'statusId') {
        control.disable();
      }
    }
  }

  loadMainOperatorTeam(value, controlTeam: UntypedFormControl) {
    if (value != null) {
      this.userService
        .get(
          [GqlSubField.create('data', [GqlField.create('mainOperatorTeamId')])],
          value,
        )
        .subscribe((x) => {
          let mainTeamOperatorId = x?.data?.mainOperatorTeamId;
          if (mainTeamOperatorId != undefined || mainTeamOperatorId != null) {
            if (controlTeam != undefined) {
              if (controlTeam.value == undefined) {
                controlTeam.setValue(mainTeamOperatorId);
                controlTeam.markAsDirty();
              }
            }
          }
        });
    }
  }

  ngAfterContentChecked(): void {
    this.changeDetectorRef.detectChanges();
  }

  async onGroupTicketClick() {
    this.groupTicketColumns = this.modelState.model.grid.layout.columns;
    this.selectListComponent.workItems.list.columns = this.groupTicketColumns;
    this.isGroupTicketVisible = true;
    let selectedDataForGroup = this.selectedData.filter(
      (x) => x.parentId == null && x.isParent == false,
    );
    if (selectedDataForGroup.length > 1) {
      if (this.statusEnd == undefined) {
        await this.getDataWorkflow(selectedDataForGroup[0].id, this.service);
      }
      selectedDataForGroup = selectedDataForGroup.filter(
        (x) => x.statusId != this.statusEnd.statusId,
      );
    }
    this.selectListComponent.source.datasource = new DataSource({
      store: new ArrayStore({
        data: selectedDataForGroup,
      }),
    });
    this.selectListComponent.refresh();
    if (selectedDataForGroup.length > 1) {
      this.selectListComponent.selectedKeys = [selectedDataForGroup[0]];
    }
  }

  groupTicketsaved($event) {
    let ticketSource = this.selectListComponent.selectedKeys[0]['id'];
    this.selectListComponent.selectedKeys = [];
    let tickets = this.selectedKeys;

    this.displayActionBeingPerformed = true;
    this.ticketService
      .groupTicket(
        ModelFieldCompilerService.createServiceSingleResultScalar(),
        ticketSource,
        tickets,
      )
      .subscribe((x) => {
        this.displayActionBeingPerformed = false;
        this.isGroupTicketVisible = false;
        this.manageList.refresh();
        this.routerTicket.navigate([
          './' + this.service.modelName + 's/edit/' + x.data,
        ]);
      });
  }

  public makeReseach() {
    this.displayPopUp = true;
  }

  public onSaved(data) {
    (data as Array<any>).map((element) => {
      let textSearch = element['textSearch'];
      let researchIn = element['research'];

      if (textSearch != undefined && (textSearch as string).trim() != '') {
        this.modelState.sharedContext.params.set(
          'textSearch',
          () => textSearch,
        );

        if (researchIn != undefined && researchIn?.length > 0) {
          let type = [];

          (researchIn as Array<any>).map((value) => {
            let getLang = this.translatedFieldHelperService.getTranslateKey();
            let getValue = value['field'];
            if (value['isTranslateField']) {
              getValue = `${getValue}.${getLang}`;
            }
            if (getValue === 'customField1') {
              [2, 3, 4, 5].forEach((element) => {
                type.push([value['type'], `customField${element}`]);
              });
            }
            type.push([value['type'], getValue]);
          });

          this.modelState.sharedContext.params.set('lists', () => type);
        }
      } else {
        this.modelState.sharedContext.params.set('lists', () => null);
        this.modelState.sharedContext.params.set('textSearch', () => null);
      }
      this.manageList.refresh();
    });

    setTimeout(() => {
      this.displayPopUp = false;
    }, 100);
  }

  async getDataWorkflow(id, service) {
    if (id != null) {
      let workflowData = await this.queryDataWorkFlow(id, service);
      if (workflowData == undefined) {
        console.error('Le workflow ne peut pas être null.');
      } else {
        let nodes = workflowData.data.config['nodes'];
        this.statusEnd = nodes.find((element) => element.isEnding == true);
      }
    }
  }

  async queryDataWorkFlow(id, service) {
    return await service
      .statusWorkflowByTicketId(
        [
          GqlSubField.create('data', [
            GqlSubField.create('config', [
              GqlSubField.create('nodes', [
                GqlField.create('statusId'),
                GqlField.create('objId'),
                GqlField.create('isEntry'),
                GqlField.create('isEnding'),
                GqlField.create('isTakingCharge'),
                GqlField.create('isEndTreatment'),
                GqlField.create('isCommentaryRequired'),
              ]),
              GqlSubField.create('edges', [
                GqlField.create('fromId'),
                GqlField.create('toId'),
              ]),
            ]),
          ]),
        ],
        id,
      )
      .toPromise();
  }

  public getConfirmMessage(): string {
    let message: string = '';
    let incident = this.selectedData.filter(
      (element) => element['__typename'].toLowerCase() == 'incident',
    );
    let request = this.selectedData.filter(
      (element) => element['__typename'].toLowerCase() == 'request',
    );
    let requestNumber = request.length;
    let incidentNumber = incident.length;

    message = this.ticketService.confirmMessageTransform(
      'incident',
      incidentNumber,
    );

    if (requestNumber > 0 && incidentNumber > 0) {
      message = this.ticketService.confirmMessageTransform('ticket');
    } else if (requestNumber > 0) {
      message = this.ticketService.confirmMessageTransform(
        'request',
        requestNumber,
      );
    }
    return message;
  }

  private initPrivilege() {
    this.localStorageService.user.getPrivilege().subscribe((res) => {
      if (res.data != null) {
        if (this.service.modelName != 'ticket') {
          let privilgeGroupTicket = PrivilegeCoreService.hasPrivilege(
            res.data,
            'group-ticket',
          );
          this.visibleGroupTicket = privilgeGroupTicket;
        }
        let privilgeArchiveTicket = PrivilegeCoreService.hasPrivilege(
          res.data,
          'archive-ticket',
        );
        this.visibleArchiveTicket =
          this.showArchive &&
          this.archiveMode == false &&
          privilgeArchiveTicket;

        this.canChangeTicketNature = PrivilegeCoreService.hasPrivilege(
          res.data,
          'change-ticket-nature',
        );
      }
    });
  }

  /**Permet de grisé ou de dégrisé le bouton d'édition */
  disabledButtonEdit(): boolean {
    let displaybutton = this.selectedKeys?.length == 0;
    if (this.selectedData.length > 0) {
      let totalTicket = this.selectedData.length;
      let totalRequest: Array<any> = this.selectedData.filter(
        (x) => x['__typename'] == 'Request',
      );
      if (totalRequest.length != 0 && totalRequest.length < totalTicket) {
        displaybutton = true;
      } else {
        let policiesToDisplayButton: string[] =
          this.servicePermissionTicket.getAuthorizations(
            this.incidentService as any,
          );

        if (totalRequest.length != 0) {
          policiesToDisplayButton =
            this.servicePermissionTicket.getAuthorizations(
              this.requestCoreService as any,
            );
        }

        displaybutton = !this.policyValidatorTicket.validate(
          `${policiesToDisplayButton[0]}.update`,
        );
      }
    }
    return displaybutton;
  }

  statusEndIncident;
  statusEndRequest;
  async archive() {
    if (this.layoutKey.includes('_closed')) {
      this.archiveQuery(this.selectedKeys);
      return;
    }

    let requests: Array<any> = this.selectedData.filter(
      (x) => x['__typename'] == 'Request',
    );
    let incidents: Array<any> = this.selectedData.filter(
      (x) => x['__typename'] == 'Incident',
    );
    if (this.statusEndRequest == undefined && requests.length > 0) {
      let workflowData = await this.queryDataWorkFlow(
        requests[0].id,
        this.requestCoreService,
      );
      if (workflowData != undefined) {
        let nodes = workflowData.data.config['nodes'];
        this.statusEndRequest = nodes.find(
          (element) => element.isEnding == true,
        );
      }
    }
    if (this.statusEndIncident == undefined && incidents.length > 0) {
      let workflowData = await this.queryDataWorkFlow(
        incidents[0].id,
        this.requestCoreService,
      );
      if (workflowData != undefined) {
        let nodes = workflowData.data.config['nodes'];
        this.statusEndIncident = nodes.find(
          (element) => element.isEnding == true,
        );
      }
    }
    let ticketIds = [];
    if (requests.length > 0) {
      ticketIds = ticketIds.concat(
        requests
          .filter((x) => x.statusId == this.statusEndRequest.statusId)
          .map((x) => x.id),
      );
    }
    if (incidents.length > 0) {
      ticketIds = ticketIds.concat(
        incidents
          .filter((x) => x.statusId == this.statusEndIncident.statusId)
          .map((x) => x.id),
      );
    }
    if (this.selectedData.length != ticketIds.length) {
      await custom({
        title: TranslateService.get('confirm-title'),
        messageHtml: TranslateService.get('entities/ticket/msgConfirmArchive'),
        buttons: ([] = [
          {
            text: 'Ok',
            onClick: async (e) => {
              if (ticketIds.length > 0) {
                this.archiveQuery(ticketIds);
              }
            },
          },
          {
            text: TranslateService.get('cancel'),
            onClick: (e) => {
              return;
            },
          },
        ]),
      }).show();
    } else {
      this.archiveQuery(ticketIds);
    }
  }

  archiveQuery(ids) {
    this.waitArchive = true;
    this.gc.forRelease(
      this.ticketService
        .archived(
          [
            GqlField.create('data'),
            GqlSubField.create('errors', [GqlField.create('messageError')]),
          ],
          ids,
        )
        .subscribe((x) => {
          this.manageList.refresh();
          this.waitArchive = false;
        }),
    );
  }

  restoreArchive() {
    this.waitArchive = true;
    this.gc.forRelease(
      this.ticketService
        .restoreArchived(
          [
            GqlField.create('data'),
            GqlSubField.create('errors', [GqlField.create('messageError')]),
          ],
          this.selectedKeys,
        )
        .subscribe((x) => {
          this.manageList.refresh();
          this.waitArchive = false;
        }),
    );
  }

  async actionItemBtn(e) {
    switch (e.itemData.id) {
      case 'group':
        this.onGroupTicketClick();
        break;
      case 'changeNature':
        this.transform();
        break;
      case 'printTickets':
        this.printTickets();
        break;
      case 'delete':
        let resultPop = true;
        let incidentNumber = this.selectedData.filter(
          (element) => element['__typename'].toLowerCase() == 'incident',
        );
        let requestNumber = this.selectedData.filter(
          (element) => element['__typename'].toLowerCase() == 'request',
        );
        if (
          this.canDeleteTicketIncident == false &&
          incidentNumber.length > 0
        ) {
          resultPop = false;
          this.selectedData = incidentNumber;

          let message = TranslateService.get(`confirm-recycle-incident`);
          resultPop = await confirm(
            message,
            TranslateService.get('confirm-title'),
          ).then((result) => {
            return result;
          });
        }
        if (this.canDeleteTicketRequest == false && requestNumber.length > 0) {
          resultPop = false;

          this.selectedData = requestNumber;
          let message = TranslateService.get(`confirm-recycle-request`);
          resultPop = await confirm(
            message,
            TranslateService.get('confirm-title'),
          ).then((result) => {
            if (result) this.manageList.delete();
            return result;
          });
        }
        if (resultPop) this.manageList.delete();
        break;
    }
  }

  createButtonAction() {
    this.actionBtnSource = [
      {
        id: 'printTickets',
        name: TranslateService.get('globals/printForm'),
      },
    ];

    if (this.canDeleteTicket) {
      this.actionBtnSource.push({
        id: 'delete',
        name: TranslateService.get('delete'),
      });
    }

    if (
      this.visibleGroupTicket &&
      this.canAddTicket &&
      this.enableEditTicket &&
      this.isQueryBuilder === false &&
      this.selectedKeys.length > 1 &&
      this.service.modelName != 'problem'
    ) {
      this.actionBtnSource.push({
        id: 'group',
        name: TranslateService.get('entities/incident/groupTicket'),
      });
    }

    if (
      this.canEditTicket &&
      this.enableEditTicket &&
      this.activeChangeTicketTypeButton &&
      this.canChangeTicketNature &&
      this.service.modelName != 'problem'
    ) {
      this.actionBtnSource.push({
        id: 'changeNature',
        name: TranslateService.get('entities/incident/changeNature'),
      });
    }

    this.actionBtnSource.sort((a, b) => a.name.localeCompare(b.name));
  }

  printTickets() {
    if (this.selectedKeys?.length > 0) {
      this.selectedData.sort((a, b) => a['ticketNumber'] - b['ticketNumber']);
      let ticketsIdToPrint: string[] = [];
      this.selectedData.map((element) => {
        ticketsIdToPrint.push(element.id);
      });
      let fields = ModelFieldCompilerService.createServiceSingleResultScalar();
      this.waitPrint = true;
      this.gc.forDispose(
        this.ticketService
          .printTicketEvents(fields, ticketsIdToPrint)
          .subscribe((response) => {
            if (response.errors.length > 0) {
              notify(
                TranslateService.get('entities/ticketLogEvent/printError'),
                'error',
                5000,
              );
            } else {
              this.ticketService.createHTMLFile('ticketEvents', response.data);
              notify(TranslateService.get('actionSuccess'), 'success', 5000);
            }
            this.waitPrint = false;
          }),
      );
    }
  }
}
