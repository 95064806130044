import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetInfraredDevicesBaseVariables, FirstInfraredDevicesBaseVariables, CountInfraredDevicesBaseVariables, FindInfraredDevicesBaseVariables, SearchInfraredDevicesBaseVariables, ExportSchemaInfraredDevicesBaseVariables, ExportDataInfraredDevicesBaseVariables, CustomQueryInfraredDevicesBaseVariables, CustomQueryIdInfraredDevicesBaseVariables, UsedInfraredDevicesBaseVariables, ExistInfraredDevicesBaseVariables } from '../gqls'
import { GetInfraredDevicesDocument, FirstInfraredDevicesDocument, CountInfraredDevicesDocument, FindInfraredDevicesDocument, SearchInfraredDevicesDocument, ExportSchemaInfraredDevicesDocument, ExportDataInfraredDevicesDocument, CustomQueryInfraredDevicesDocument, CustomQueryIdInfraredDevicesDocument, UsedInfraredDevicesDocument, ExistInfraredDevicesDocument } from '../gqls'
import { ServiceSingleResultOfInfraredDevice, QueryContextOfInfraredDevice, FilterOfInfraredDevice, ServiceSingleResultOfInt64, ServiceListResultOfInfraredDevice, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class InfraredDeviceBaseService {
    
public modelName = 'infraredDevice';
public modelPluralName = 'infraredDevices';

	private getInfraredDevicesQuery: GetInfraredDevicesDocument;
	private firstInfraredDevicesQuery: FirstInfraredDevicesDocument;
	private countInfraredDevicesQuery: CountInfraredDevicesDocument;
	private findInfraredDevicesQuery: FindInfraredDevicesDocument;
	private searchInfraredDevicesQuery: SearchInfraredDevicesDocument;
	private exportSchemaInfraredDevicesQuery: ExportSchemaInfraredDevicesDocument;
	private exportDataInfraredDevicesQuery: ExportDataInfraredDevicesDocument;
	private customQueryInfraredDevicesQuery: CustomQueryInfraredDevicesDocument;
	private customQueryIdInfraredDevicesQuery: CustomQueryIdInfraredDevicesDocument;
	private usedInfraredDevicesQuery: UsedInfraredDevicesDocument;
	private existInfraredDevicesQuery: ExistInfraredDevicesDocument;

	constructor(private injector: Injector) {
		this.getInfraredDevicesQuery = this.injector.get(GetInfraredDevicesDocument);
		this.firstInfraredDevicesQuery = this.injector.get(FirstInfraredDevicesDocument);
		this.countInfraredDevicesQuery = this.injector.get(CountInfraredDevicesDocument);
		this.findInfraredDevicesQuery = this.injector.get(FindInfraredDevicesDocument);
		this.searchInfraredDevicesQuery = this.injector.get(SearchInfraredDevicesDocument);
		this.exportSchemaInfraredDevicesQuery = this.injector.get(ExportSchemaInfraredDevicesDocument);
		this.exportDataInfraredDevicesQuery = this.injector.get(ExportDataInfraredDevicesDocument);
		this.customQueryInfraredDevicesQuery = this.injector.get(CustomQueryInfraredDevicesDocument);
		this.customQueryIdInfraredDevicesQuery = this.injector.get(CustomQueryIdInfraredDevicesDocument);
		this.usedInfraredDevicesQuery = this.injector.get(UsedInfraredDevicesDocument);
		this.existInfraredDevicesQuery = this.injector.get(ExistInfraredDevicesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInfraredDevice> {
    
    		let variables: GetInfraredDevicesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.get));
            }
            else{
                let result:ServiceSingleResultOfInfraredDevice={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInfraredDevice,
		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInfraredDevice> {
    
    		let variables: FirstInfraredDevicesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountInfraredDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.countInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfInfraredDevice,
		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInfraredDevice> {
    
    		let variables: FindInfraredDevicesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfInfraredDevice,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInfraredDevice> {
    
    		let variables: SearchInfraredDevicesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaInfraredDevicesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataInfraredDevicesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfInfraredDevice,
		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInfraredDevice> {
    
    		let variables: CustomQueryInfraredDevicesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfInfraredDevice,
		@Args('filter?') filter?: FilterOfInfraredDevice,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfInfraredDevice> {
    
    		let variables: CustomQueryIdInfraredDevicesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedInfraredDevicesBaseVariables = {
    			ids: ids
    		}
    				return this.usedInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistInfraredDevicesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existInfraredDevicesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.infraredDevices.exist));
    	}
    
    
}