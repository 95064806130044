import { Injectable } from '@angular/core';
import { AlarmCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import { QueryContextOfAlarm, Sort } from '@clarilog/core/services2/graphql/generated-types/types';

@Injectable({
  providedIn: 'root',
})
export class WidgetDashboardService {
  constructor(
    private _alarmService: AlarmCoreService,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {}

  /**Permets de récupérer les données liée au alertes  */
  async getAlertData() {
    let options: QueryContextOfAlarm = {}
    let key = this.translatedFieldHelperService.getTranslateKey();
    let sort = {};
    sort[key] = Sort.Asc;
    options.sort = [{ name: [sort] }];
    let sourceData: Object[];
    let response = await this._alarmService
      .findAlarmWithNotViewedAlert([
        GqlSubField.create('data', [
          GqlSubField.create(
            'name',
            this.translatedFieldHelperService.translatedFields(),
          ),
          GqlField.create('type'),
          GqlField.create('countNotViewedAlert'),
          GqlField.create('id'),
        ]),
      ],
        options)
      .toPromise();
    sourceData = response.data;
    return sourceData;
  }
}
