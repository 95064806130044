import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPrinterConsumablesBaseVariables, FirstPrinterConsumablesBaseVariables, CountPrinterConsumablesBaseVariables, FindPrinterConsumablesBaseVariables, SearchPrinterConsumablesBaseVariables, ExportSchemaPrinterConsumablesBaseVariables, ExportDataPrinterConsumablesBaseVariables, CustomQueryPrinterConsumablesBaseVariables, CustomQueryIdPrinterConsumablesBaseVariables, UsedPrinterConsumablesBaseVariables, ExistPrinterConsumablesBaseVariables, FindUnassociatedAlertsPrinterConsumablesBaseVariables } from '../gqls'
import { GetPrinterConsumablesDocument, FirstPrinterConsumablesDocument, CountPrinterConsumablesDocument, FindPrinterConsumablesDocument, SearchPrinterConsumablesDocument, ExportSchemaPrinterConsumablesDocument, ExportDataPrinterConsumablesDocument, CustomQueryPrinterConsumablesDocument, CustomQueryIdPrinterConsumablesDocument, UsedPrinterConsumablesDocument, ExistPrinterConsumablesDocument, FindUnassociatedAlertsPrinterConsumablesDocument } from '../gqls'
import { ServiceSingleResultOfPrinterConsumable, QueryContextOfPrinterConsumable, FilterOfPrinterConsumable, ServiceSingleResultOfInt64, ServiceListResultOfPrinterConsumable, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PrinterConsumableBaseService {
    
public modelName = 'printerConsumable';
public modelPluralName = 'printerConsumables';

	private getPrinterConsumablesQuery: GetPrinterConsumablesDocument;
	private firstPrinterConsumablesQuery: FirstPrinterConsumablesDocument;
	private countPrinterConsumablesQuery: CountPrinterConsumablesDocument;
	private findPrinterConsumablesQuery: FindPrinterConsumablesDocument;
	private searchPrinterConsumablesQuery: SearchPrinterConsumablesDocument;
	private exportSchemaPrinterConsumablesQuery: ExportSchemaPrinterConsumablesDocument;
	private exportDataPrinterConsumablesQuery: ExportDataPrinterConsumablesDocument;
	private customQueryPrinterConsumablesQuery: CustomQueryPrinterConsumablesDocument;
	private customQueryIdPrinterConsumablesQuery: CustomQueryIdPrinterConsumablesDocument;
	private usedPrinterConsumablesQuery: UsedPrinterConsumablesDocument;
	private existPrinterConsumablesQuery: ExistPrinterConsumablesDocument;
	private findUnassociatedAlertsPrinterConsumablesQuery: FindUnassociatedAlertsPrinterConsumablesDocument;

	constructor(private injector: Injector) {
		this.getPrinterConsumablesQuery = this.injector.get(GetPrinterConsumablesDocument);
		this.firstPrinterConsumablesQuery = this.injector.get(FirstPrinterConsumablesDocument);
		this.countPrinterConsumablesQuery = this.injector.get(CountPrinterConsumablesDocument);
		this.findPrinterConsumablesQuery = this.injector.get(FindPrinterConsumablesDocument);
		this.searchPrinterConsumablesQuery = this.injector.get(SearchPrinterConsumablesDocument);
		this.exportSchemaPrinterConsumablesQuery = this.injector.get(ExportSchemaPrinterConsumablesDocument);
		this.exportDataPrinterConsumablesQuery = this.injector.get(ExportDataPrinterConsumablesDocument);
		this.customQueryPrinterConsumablesQuery = this.injector.get(CustomQueryPrinterConsumablesDocument);
		this.customQueryIdPrinterConsumablesQuery = this.injector.get(CustomQueryIdPrinterConsumablesDocument);
		this.usedPrinterConsumablesQuery = this.injector.get(UsedPrinterConsumablesDocument);
		this.existPrinterConsumablesQuery = this.injector.get(ExistPrinterConsumablesDocument);
		this.findUnassociatedAlertsPrinterConsumablesQuery = this.injector.get(FindUnassociatedAlertsPrinterConsumablesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrinterConsumable> {
    
    		let variables: GetPrinterConsumablesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.get));
            }
            else{
                let result:ServiceSingleResultOfPrinterConsumable={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrinterConsumable,
		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPrinterConsumable> {
    
    		let variables: FirstPrinterConsumablesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPrinterConsumablesBaseVariables = {
    			filter: filter
    		}
    				return this.countPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPrinterConsumable,
		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterConsumable> {
    
    		let variables: FindPrinterConsumablesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPrinterConsumable,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterConsumable> {
    
    		let variables: SearchPrinterConsumablesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPrinterConsumablesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPrinterConsumablesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPrinterConsumable,
		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterConsumable> {
    
    		let variables: CustomQueryPrinterConsumablesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPrinterConsumable,
		@Args('filter?') filter?: FilterOfPrinterConsumable,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPrinterConsumable> {
    
    		let variables: CustomQueryIdPrinterConsumablesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPrinterConsumablesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPrinterConsumablesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.exist));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsPrinterConsumablesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsPrinterConsumablesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.printerConsumables.findUnassociatedAlerts));
    	}
    
    
}