
import { TicketTaskModelBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';

@Injectable({providedIn: 'root'})
@Authorize('help-desk-operator.manage-task-model')
export class TicketTaskModelCoreService extends TicketTaskModelBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}