import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetArticleKnowledgeCategoriesBaseVariables, FirstArticleKnowledgeCategoriesBaseVariables, CountArticleKnowledgeCategoriesBaseVariables, FindArticleKnowledgeCategoriesBaseVariables, SearchArticleKnowledgeCategoriesBaseVariables, ExportSchemaArticleKnowledgeCategoriesBaseVariables, ExportDataArticleKnowledgeCategoriesBaseVariables, CustomQueryArticleKnowledgeCategoriesBaseVariables, CustomQueryIdArticleKnowledgeCategoriesBaseVariables, UsedArticleKnowledgeCategoriesBaseVariables, ExistArticleKnowledgeCategoriesBaseVariables, FindRecyclesArticleKnowledgeCategoriesBaseVariables, CountRecyclesArticleKnowledgeCategoriesBaseVariables, ReadOnlyArticleKnowledgeCategoriesBaseVariables, FindArchivedArticleKnowledgeCategoriesBaseVariables, CountAllArticleKnowledgeCategoriesBaseVariables, FindDynamicPropertyFieldsArticleKnowledgeCategoriesBaseVariables, FindUnassociatedArticlesArticleKnowledgeCategoriesBaseVariables, InsertArticleKnowledgeCategoriesBaseVariables, UpdateArticleKnowledgeCategoriesBaseVariables, ImportDataArticleKnowledgeCategoriesBaseVariables, UpdateManyArticleKnowledgeCategoriesBaseVariables, DeleteArticleKnowledgeCategoriesBaseVariables, RestoreArticleKnowledgeCategoriesBaseVariables, RecycleArticleKnowledgeCategoriesBaseVariables, RestoreArchivedArticleKnowledgeCategoriesBaseVariables, ArchivedArticleKnowledgeCategoriesBaseVariables, AddFileDynamicFieldArticleKnowledgeCategoriesBaseVariables, RemoveFileDynamicFieldArticleKnowledgeCategoriesBaseVariables, AddArticlesArticleKnowledgeCategoriesBaseVariables, RemoveArticlesArticleKnowledgeCategoriesBaseVariables } from '../gqls'
import { GetArticleKnowledgeCategoriesDocument, FirstArticleKnowledgeCategoriesDocument, CountArticleKnowledgeCategoriesDocument, FindArticleKnowledgeCategoriesDocument, SearchArticleKnowledgeCategoriesDocument, ExportSchemaArticleKnowledgeCategoriesDocument, ExportDataArticleKnowledgeCategoriesDocument, CustomQueryArticleKnowledgeCategoriesDocument, CustomQueryIdArticleKnowledgeCategoriesDocument, UsedArticleKnowledgeCategoriesDocument, ExistArticleKnowledgeCategoriesDocument, FindRecyclesArticleKnowledgeCategoriesDocument, CountRecyclesArticleKnowledgeCategoriesDocument, ReadOnlyArticleKnowledgeCategoriesDocument, FindArchivedArticleKnowledgeCategoriesDocument, CountAllArticleKnowledgeCategoriesDocument, FindDynamicPropertyFieldsArticleKnowledgeCategoriesDocument, FindUnassociatedArticlesArticleKnowledgeCategoriesDocument, InsertArticleKnowledgeCategoriesDocument, UpdateArticleKnowledgeCategoriesDocument, ImportDataArticleKnowledgeCategoriesDocument, UpdateManyArticleKnowledgeCategoriesDocument, DeleteArticleKnowledgeCategoriesDocument, RestoreArticleKnowledgeCategoriesDocument, RecycleArticleKnowledgeCategoriesDocument, RestoreArchivedArticleKnowledgeCategoriesDocument, ArchivedArticleKnowledgeCategoriesDocument, AddFileDynamicFieldArticleKnowledgeCategoriesDocument, RemoveFileDynamicFieldArticleKnowledgeCategoriesDocument, AddArticlesArticleKnowledgeCategoriesDocument, RemoveArticlesArticleKnowledgeCategoriesDocument } from '../gqls'
import { ServiceSingleResultOfArticleKnowledgeCategory, QueryContextOfArticleKnowledgeCategory, FilterOfArticleKnowledgeCategory, ServiceSingleResultOfInt64, ServiceListResultOfArticleKnowledgeCategory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfArticleKnowledgeCategory, QueryContextOfArticleKnowledge, FilterOfArticleKnowledge, ServiceListResultOfArticleKnowledge, ArticleKnowledgeCategoryInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ArticleKnowledgeCategoryBaseService {
    
public modelName = 'articleKnowledgeCategory';
public modelPluralName = 'articleKnowledgeCategories';

	private getArticleKnowledgeCategoriesQuery: GetArticleKnowledgeCategoriesDocument;
	private firstArticleKnowledgeCategoriesQuery: FirstArticleKnowledgeCategoriesDocument;
	private countArticleKnowledgeCategoriesQuery: CountArticleKnowledgeCategoriesDocument;
	private findArticleKnowledgeCategoriesQuery: FindArticleKnowledgeCategoriesDocument;
	private searchArticleKnowledgeCategoriesQuery: SearchArticleKnowledgeCategoriesDocument;
	private exportSchemaArticleKnowledgeCategoriesQuery: ExportSchemaArticleKnowledgeCategoriesDocument;
	private exportDataArticleKnowledgeCategoriesQuery: ExportDataArticleKnowledgeCategoriesDocument;
	private customQueryArticleKnowledgeCategoriesQuery: CustomQueryArticleKnowledgeCategoriesDocument;
	private customQueryIdArticleKnowledgeCategoriesQuery: CustomQueryIdArticleKnowledgeCategoriesDocument;
	private usedArticleKnowledgeCategoriesQuery: UsedArticleKnowledgeCategoriesDocument;
	private existArticleKnowledgeCategoriesQuery: ExistArticleKnowledgeCategoriesDocument;
	private findRecyclesArticleKnowledgeCategoriesQuery: FindRecyclesArticleKnowledgeCategoriesDocument;
	private countRecyclesArticleKnowledgeCategoriesQuery: CountRecyclesArticleKnowledgeCategoriesDocument;
	private readOnlyArticleKnowledgeCategoriesQuery: ReadOnlyArticleKnowledgeCategoriesDocument;
	private findArchivedArticleKnowledgeCategoriesQuery: FindArchivedArticleKnowledgeCategoriesDocument;
	private countAllArticleKnowledgeCategoriesQuery: CountAllArticleKnowledgeCategoriesDocument;
	private findDynamicPropertyFieldsArticleKnowledgeCategoriesQuery: FindDynamicPropertyFieldsArticleKnowledgeCategoriesDocument;
	private findUnassociatedArticlesArticleKnowledgeCategoriesQuery: FindUnassociatedArticlesArticleKnowledgeCategoriesDocument;
	private insertArticleKnowledgeCategoriesMutation: InsertArticleKnowledgeCategoriesDocument;
	private updateArticleKnowledgeCategoriesMutation: UpdateArticleKnowledgeCategoriesDocument;
	private importDataArticleKnowledgeCategoriesMutation: ImportDataArticleKnowledgeCategoriesDocument;
	private updateManyArticleKnowledgeCategoriesMutation: UpdateManyArticleKnowledgeCategoriesDocument;
	private deleteArticleKnowledgeCategoriesMutation: DeleteArticleKnowledgeCategoriesDocument;
	private restoreArticleKnowledgeCategoriesMutation: RestoreArticleKnowledgeCategoriesDocument;
	private recycleArticleKnowledgeCategoriesMutation: RecycleArticleKnowledgeCategoriesDocument;
	private restoreArchivedArticleKnowledgeCategoriesMutation: RestoreArchivedArticleKnowledgeCategoriesDocument;
	private archivedArticleKnowledgeCategoriesMutation: ArchivedArticleKnowledgeCategoriesDocument;
	private addFileDynamicFieldArticleKnowledgeCategoriesMutation: AddFileDynamicFieldArticleKnowledgeCategoriesDocument;
	private removeFileDynamicFieldArticleKnowledgeCategoriesMutation: RemoveFileDynamicFieldArticleKnowledgeCategoriesDocument;
	private addArticlesArticleKnowledgeCategoriesMutation: AddArticlesArticleKnowledgeCategoriesDocument;
	private removeArticlesArticleKnowledgeCategoriesMutation: RemoveArticlesArticleKnowledgeCategoriesDocument;

	constructor(private injector: Injector) {
		this.getArticleKnowledgeCategoriesQuery = this.injector.get(GetArticleKnowledgeCategoriesDocument);
		this.firstArticleKnowledgeCategoriesQuery = this.injector.get(FirstArticleKnowledgeCategoriesDocument);
		this.countArticleKnowledgeCategoriesQuery = this.injector.get(CountArticleKnowledgeCategoriesDocument);
		this.findArticleKnowledgeCategoriesQuery = this.injector.get(FindArticleKnowledgeCategoriesDocument);
		this.searchArticleKnowledgeCategoriesQuery = this.injector.get(SearchArticleKnowledgeCategoriesDocument);
		this.exportSchemaArticleKnowledgeCategoriesQuery = this.injector.get(ExportSchemaArticleKnowledgeCategoriesDocument);
		this.exportDataArticleKnowledgeCategoriesQuery = this.injector.get(ExportDataArticleKnowledgeCategoriesDocument);
		this.customQueryArticleKnowledgeCategoriesQuery = this.injector.get(CustomQueryArticleKnowledgeCategoriesDocument);
		this.customQueryIdArticleKnowledgeCategoriesQuery = this.injector.get(CustomQueryIdArticleKnowledgeCategoriesDocument);
		this.usedArticleKnowledgeCategoriesQuery = this.injector.get(UsedArticleKnowledgeCategoriesDocument);
		this.existArticleKnowledgeCategoriesQuery = this.injector.get(ExistArticleKnowledgeCategoriesDocument);
		this.findRecyclesArticleKnowledgeCategoriesQuery = this.injector.get(FindRecyclesArticleKnowledgeCategoriesDocument);
		this.countRecyclesArticleKnowledgeCategoriesQuery = this.injector.get(CountRecyclesArticleKnowledgeCategoriesDocument);
		this.readOnlyArticleKnowledgeCategoriesQuery = this.injector.get(ReadOnlyArticleKnowledgeCategoriesDocument);
		this.findArchivedArticleKnowledgeCategoriesQuery = this.injector.get(FindArchivedArticleKnowledgeCategoriesDocument);
		this.countAllArticleKnowledgeCategoriesQuery = this.injector.get(CountAllArticleKnowledgeCategoriesDocument);
		this.findDynamicPropertyFieldsArticleKnowledgeCategoriesQuery = this.injector.get(FindDynamicPropertyFieldsArticleKnowledgeCategoriesDocument);
		this.findUnassociatedArticlesArticleKnowledgeCategoriesQuery = this.injector.get(FindUnassociatedArticlesArticleKnowledgeCategoriesDocument);
		this.insertArticleKnowledgeCategoriesMutation = this.injector.get(InsertArticleKnowledgeCategoriesDocument);
		this.updateArticleKnowledgeCategoriesMutation = this.injector.get(UpdateArticleKnowledgeCategoriesDocument);
		this.importDataArticleKnowledgeCategoriesMutation = this.injector.get(ImportDataArticleKnowledgeCategoriesDocument);
		this.updateManyArticleKnowledgeCategoriesMutation = this.injector.get(UpdateManyArticleKnowledgeCategoriesDocument);
		this.deleteArticleKnowledgeCategoriesMutation = this.injector.get(DeleteArticleKnowledgeCategoriesDocument);
		this.restoreArticleKnowledgeCategoriesMutation = this.injector.get(RestoreArticleKnowledgeCategoriesDocument);
		this.recycleArticleKnowledgeCategoriesMutation = this.injector.get(RecycleArticleKnowledgeCategoriesDocument);
		this.restoreArchivedArticleKnowledgeCategoriesMutation = this.injector.get(RestoreArchivedArticleKnowledgeCategoriesDocument);
		this.archivedArticleKnowledgeCategoriesMutation = this.injector.get(ArchivedArticleKnowledgeCategoriesDocument);
		this.addFileDynamicFieldArticleKnowledgeCategoriesMutation = this.injector.get(AddFileDynamicFieldArticleKnowledgeCategoriesDocument);
		this.removeFileDynamicFieldArticleKnowledgeCategoriesMutation = this.injector.get(RemoveFileDynamicFieldArticleKnowledgeCategoriesDocument);
		this.addArticlesArticleKnowledgeCategoriesMutation = this.injector.get(AddArticlesArticleKnowledgeCategoriesDocument);
		this.removeArticlesArticleKnowledgeCategoriesMutation = this.injector.get(RemoveArticlesArticleKnowledgeCategoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeCategory> {
    
    		let variables: GetArticleKnowledgeCategoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.get));
            }
            else{
                let result:ServiceSingleResultOfArticleKnowledgeCategory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeCategory> {
    
    		let variables: FirstArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: FindArticleKnowledgeCategoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: SearchArticleKnowledgeCategoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaArticleKnowledgeCategoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: CustomQueryArticleKnowledgeCategoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: CustomQueryIdArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistArticleKnowledgeCategoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: FindRecyclesArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyArticleKnowledgeCategoriesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledgeCategory,
		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledgeCategory> {
    
    		let variables: FindArchivedArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllArticleKnowledgeCategoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedArticles(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfArticleKnowledge,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfArticleKnowledge,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfArticleKnowledge> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('articles', fields, null, [
			GqlSubFieldArg.create('filterOfArticles', 'filter'),
			GqlSubFieldArg.create('optionsOfArticles', 'options'),
		]),
		])
		extendedVariables['filterOfArticles'] = filter;
		extendedVariables['optionsOfArticles'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.articles));
            }
            else{
                let result: ServiceListResultOfArticleKnowledge = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedArticles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfArticleKnowledge,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfArticleKnowledge,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfArticleKnowledge> {
    
    		let variables: FindUnassociatedArticlesArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedArticlesArticleKnowledgeCategoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.findUnassociatedArticles));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ArticleKnowledgeCategoryInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeCategory> {
    
    		let variables: InsertArticleKnowledgeCategoriesBaseVariables = {
    			entity: entity
    		}
    				return this.insertArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfArticleKnowledgeCategory> {
    
    		let variables: UpdateArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataArticleKnowledgeCategoriesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfArticleKnowledgeCategory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedArticleKnowledgeCategoriesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addArticles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('articleKnowledgeIds') articleKnowledgeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddArticlesArticleKnowledgeCategoriesBaseVariables = {
    			id: id,
			articleKnowledgeIds: articleKnowledgeIds
    		}
    				return this.addArticlesArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.addArticles));
    	}

    	/**  */
    	@InjectArgs
    	public removeArticles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('articleKnowledgeIds') articleKnowledgeIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveArticlesArticleKnowledgeCategoriesBaseVariables = {
    			articleKnowledgeIds: articleKnowledgeIds
    		}
    				return this.removeArticlesArticleKnowledgeCategoriesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.articleKnowledgeCategories.removeArticles));
    	}
    
}