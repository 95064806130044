import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfInventoryHistory, FilterOfInventoryHistory, QueryContextOfInventoryHistory, ServiceListResultOfAuditTime, ServiceSingleResultOfString, ServiceSingleResultOfBoolean, HistoryState } from '../types'

export type FindClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        find?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindClarilogLocalAgentsBaseVariables = {
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        find(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAllInventoryHistoryClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        findAllInventoryHistory?: Maybe<ServiceListResultOfInventoryHistory>
    }
}

export type FindAllInventoryHistoryClarilogLocalAgentsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfInventoryHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfInventoryHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAllInventoryHistoryClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfInventoryHistory = null, $options: QueryContextOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAllInventoryHistoryClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        findAllInventoryHistory(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAllInventoryHistoryClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAllInventoryHistoryClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfInventoryHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLastInventoryHistoryClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        findLastInventoryHistory?: Maybe<ServiceListResultOfAuditTime>
    }
}

export type FindLastInventoryHistoryClarilogLocalAgentsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfInventoryHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfInventoryHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLastInventoryHistoryClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfInventoryHistory = null, $options: QueryContextOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLastInventoryHistoryClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        findLastInventoryHistory(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLastInventoryHistoryClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLastInventoryHistoryClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAuditTime", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type TokenClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        token?: Maybe<ServiceSingleResultOfString>
    }
}

export type TokenClarilogLocalAgentsBaseVariables = {

}

/**  */
@Injectable({providedIn: 'root'})
export class TokenClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query tokenClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        token {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: TokenClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<TokenClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteClarilogLocalAgentsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class DeleteClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CancelAuditClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        cancelAudit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type CancelAuditClarilogLocalAgentsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CancelAuditClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation cancelAuditClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        cancelAudit(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CancelAuditClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CancelAuditClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RunAuditClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        runAudit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RunAuditClarilogLocalAgentsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RunAuditClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation runAuditClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        runAudit(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RunAuditClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RunAuditClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SetEnableClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        setEnable?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type SetEnableClarilogLocalAgentsBaseVariables = {
	ids: Array<string>, /**  */
	state: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class SetEnableClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $state: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation setEnableClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        setEnable(ids: $ids, state: $state) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: SetEnableClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<SetEnableClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateCampaignHistoryClarilogLocalAgentsResultType = {
    clarilogLocalAgents: {
        updateCampaignHistory?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateCampaignHistoryClarilogLocalAgentsBaseVariables = {
	id: string, /**  */
	state: HistoryState, /**  */
	error: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateCampaignHistoryClarilogLocalAgentsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $state: HistoryState!, $error: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateCampaignHistoryClarilogLocalAgents ${args} {
    clarilogLocalAgents {
        updateCampaignHistory(id: $id, state: $state, error: $error) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateCampaignHistoryClarilogLocalAgentsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateCampaignHistoryClarilogLocalAgentsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
