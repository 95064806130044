import { Authorize } from '@clarilog/core/services/graphql';
import { SoftwareAttributeTypeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import {
  FilterOfSoftwareAttributeType,
  QueryContextOfSoftwareAttributeType,
  ServiceListResultOfSoftwareAttributeType,
  Sort,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs/internal/Observable';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('software.manage-software-attribute-type')
export class SoftwareAttributeTypeCoreService extends SoftwareAttributeTypeBaseService {
  constructor(
    injector: Injector,
    public translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  @InjectArgs
  public findSortByName(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfSoftwareAttributeType,
    @Args('filter?') filter?: FilterOfSoftwareAttributeType,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfSoftwareAttributeType> {
    if (options == undefined) {
      options = {};
    }

    let sort = this.translatedFieldHelperService.getSortDefault(Sort.Asc);
    options.sort = [{ name: [sort] }];

    return this.find(fields, options, filter, extendedVariables);
  }
}
