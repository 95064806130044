import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPnpEntityBasesBaseVariables, FirstPnpEntityBasesBaseVariables, CountPnpEntityBasesBaseVariables, FindPnpEntityBasesBaseVariables, SearchPnpEntityBasesBaseVariables, ExportSchemaPnpEntityBasesBaseVariables, ExportDataPnpEntityBasesBaseVariables, CustomQueryPnpEntityBasesBaseVariables, CustomQueryIdPnpEntityBasesBaseVariables, UsedPnpEntityBasesBaseVariables, ExistPnpEntityBasesBaseVariables, InsertPnpEntityBasesBaseVariables, UpdatePnpEntityBasesBaseVariables, ImportDataPnpEntityBasesBaseVariables, UpdateManyPnpEntityBasesBaseVariables, DeletePnpEntityBasesBaseVariables } from '../gqls'
import { GetPnpEntityBasesDocument, FirstPnpEntityBasesDocument, CountPnpEntityBasesDocument, FindPnpEntityBasesDocument, SearchPnpEntityBasesDocument, ExportSchemaPnpEntityBasesDocument, ExportDataPnpEntityBasesDocument, CustomQueryPnpEntityBasesDocument, CustomQueryIdPnpEntityBasesDocument, UsedPnpEntityBasesDocument, ExistPnpEntityBasesDocument, InsertPnpEntityBasesDocument, UpdatePnpEntityBasesDocument, ImportDataPnpEntityBasesDocument, UpdateManyPnpEntityBasesDocument, DeletePnpEntityBasesDocument } from '../gqls'
import { ServiceSingleResultOfPnpEntityBase, QueryContextOfPnpEntityBase, FilterOfPnpEntityBase, ServiceSingleResultOfInt64, ServiceListResultOfPnpEntityBase, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, PnpEntityBaseInput, FieldUpdateOperatorOfPnpEntityBase } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PnpEntityBaseBaseService {
    
public modelName = 'pnpEntityBase';
public modelPluralName = 'pnpEntityBases';

	private getPnpEntityBasesQuery: GetPnpEntityBasesDocument;
	private firstPnpEntityBasesQuery: FirstPnpEntityBasesDocument;
	private countPnpEntityBasesQuery: CountPnpEntityBasesDocument;
	private findPnpEntityBasesQuery: FindPnpEntityBasesDocument;
	private searchPnpEntityBasesQuery: SearchPnpEntityBasesDocument;
	private exportSchemaPnpEntityBasesQuery: ExportSchemaPnpEntityBasesDocument;
	private exportDataPnpEntityBasesQuery: ExportDataPnpEntityBasesDocument;
	private customQueryPnpEntityBasesQuery: CustomQueryPnpEntityBasesDocument;
	private customQueryIdPnpEntityBasesQuery: CustomQueryIdPnpEntityBasesDocument;
	private usedPnpEntityBasesQuery: UsedPnpEntityBasesDocument;
	private existPnpEntityBasesQuery: ExistPnpEntityBasesDocument;
	private insertPnpEntityBasesMutation: InsertPnpEntityBasesDocument;
	private updatePnpEntityBasesMutation: UpdatePnpEntityBasesDocument;
	private importDataPnpEntityBasesMutation: ImportDataPnpEntityBasesDocument;
	private updateManyPnpEntityBasesMutation: UpdateManyPnpEntityBasesDocument;
	private deletePnpEntityBasesMutation: DeletePnpEntityBasesDocument;

	constructor(private injector: Injector) {
		this.getPnpEntityBasesQuery = this.injector.get(GetPnpEntityBasesDocument);
		this.firstPnpEntityBasesQuery = this.injector.get(FirstPnpEntityBasesDocument);
		this.countPnpEntityBasesQuery = this.injector.get(CountPnpEntityBasesDocument);
		this.findPnpEntityBasesQuery = this.injector.get(FindPnpEntityBasesDocument);
		this.searchPnpEntityBasesQuery = this.injector.get(SearchPnpEntityBasesDocument);
		this.exportSchemaPnpEntityBasesQuery = this.injector.get(ExportSchemaPnpEntityBasesDocument);
		this.exportDataPnpEntityBasesQuery = this.injector.get(ExportDataPnpEntityBasesDocument);
		this.customQueryPnpEntityBasesQuery = this.injector.get(CustomQueryPnpEntityBasesDocument);
		this.customQueryIdPnpEntityBasesQuery = this.injector.get(CustomQueryIdPnpEntityBasesDocument);
		this.usedPnpEntityBasesQuery = this.injector.get(UsedPnpEntityBasesDocument);
		this.existPnpEntityBasesQuery = this.injector.get(ExistPnpEntityBasesDocument);
		this.insertPnpEntityBasesMutation = this.injector.get(InsertPnpEntityBasesDocument);
		this.updatePnpEntityBasesMutation = this.injector.get(UpdatePnpEntityBasesDocument);
		this.importDataPnpEntityBasesMutation = this.injector.get(ImportDataPnpEntityBasesDocument);
		this.updateManyPnpEntityBasesMutation = this.injector.get(UpdateManyPnpEntityBasesDocument);
		this.deletePnpEntityBasesMutation = this.injector.get(DeletePnpEntityBasesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntityBase> {
    
    		let variables: GetPnpEntityBasesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.get));
            }
            else{
                let result:ServiceSingleResultOfPnpEntityBase={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpEntityBase,
		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntityBase> {
    
    		let variables: FirstPnpEntityBasesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPnpEntityBasesBaseVariables = {
    			filter: filter
    		}
    				return this.countPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPnpEntityBase,
		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntityBase> {
    
    		let variables: FindPnpEntityBasesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPnpEntityBase,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntityBase> {
    
    		let variables: SearchPnpEntityBasesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPnpEntityBasesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPnpEntityBasesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPnpEntityBase,
		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntityBase> {
    
    		let variables: CustomQueryPnpEntityBasesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPnpEntityBase,
		@Args('filter?') filter?: FilterOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPnpEntityBase> {
    
    		let variables: CustomQueryIdPnpEntityBasesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPnpEntityBasesBaseVariables = {
    			ids: ids
    		}
    				return this.usedPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPnpEntityBasesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPnpEntityBasesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: PnpEntityBaseInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntityBase> {
    
    		let variables: InsertPnpEntityBasesBaseVariables = {
    			entity: entity
    		}
    				return this.insertPnpEntityBasesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPnpEntityBase> {
    
    		let variables: UpdatePnpEntityBasesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updatePnpEntityBasesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataPnpEntityBasesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataPnpEntityBasesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfPnpEntityBase,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyPnpEntityBasesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyPnpEntityBasesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeletePnpEntityBasesBaseVariables = {
    			ids: ids
    		}
    				return this.deletePnpEntityBasesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.pnpEntityBases.delete));
    	}
    
}