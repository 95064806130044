import { OrganizationalUnitBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlSubField, GqlSubFieldArg, GqlField } from '../helpers';
import {
  QueryContextOfLocationCharter,
  FilterOfLocationCharter,
  ServiceListResultOfLocationCharter,
  FilterOfOrganizationalUnit,
  QueryContextOfOrganizationalUnit,
  ServiceListResultOfOrganizationalUnit,
} from '../types';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
@Injectable({ providedIn: 'root' })
@Authorize('administration.organizational-unit')
export class OrganizationalUnitCoreService extends OrganizationalUnitBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findAssociatedLocationCharters(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfLocationCharter,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfLocationCharter,

    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLocationCharter> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('locationCharters', fields, null, [
        GqlSubFieldArg.create('filterOfLocationCharters', 'filter'),
        GqlSubFieldArg.create('optionsOfLocationCharters', 'options'),
      ]),
    ]);
    extendedVariables['filterOfLocationCharters'] = filter;
    extendedVariables['optionsOfLocationCharters'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.locationCharters),
    );
  }

  @InjectArgs
  public findListOrganitionUnits(
    @Args('fields') fields: Array<GqlSubField | GqlField>,
    @Args('filter?') filter: FilterOfOrganizationalUnit,
    @Args('options?') options: QueryContextOfOrganizationalUnit,
    @Args('selectedKeys?') selectedKeys: any[],
    @Args('extendedVariable?') extendedVariables: any = null,
  ): Observable<ServiceListResultOfOrganizationalUnit> {
    let list: string[] = [];
    let createFilter: FilterOfOrganizationalUnit = filter;
    selectedKeys?.map((element) => {
      list.push(element.id);

      let childrenList = element.treeChildrenId;
      if (childrenList?.length > 0) {
        childrenList.map((child) => {
          let indexChild = list.findIndex((x) => x === child);
          if (child && indexChild === -1) {
            list.push(child);
          }
        });
      }
    });

    if (list?.length > 0) {
      createFilter = { id: { nin: list } };
    }
    if (filter != null) {
      createFilter = { and: [filter] };
    }
    return this.findListOrganitionUnit(
      fields,
      options,
      createFilter,
      extendedVariables,
    );
  }
}
