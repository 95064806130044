import { ContactBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlFields, GqlSubField } from '../helpers';
import {
  FilterOfUser,
  QueryContextOfUser,
  ServiceListResultOfUser,
} from '../types';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('supplier')
export class ContactCoreService extends ContactBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('name'),
      ]),
      GqlField.create('totalCount'),
    ];
  }

  //Methode a retravailler plus tard
  @InjectArgs
  public findContactByServiceOffer(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('serviceOfferIds') serviceOfferIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    fields.map((element) => {
      if (element.name == 'data') {
        element['fields'].map((value, index) => {
          if (value.name == 'serviceOfferIds') {
            element['fields'].splice(index, 1);
          }
        });
      }
    });
    if (filter != null) {
      if (filter?.and != undefined) {
        filter.and.map((value, index) => {
          serviceOfferIds = value['serviceOfferIds']?.elemMatch.in;
          if (value['serviceOfferIds'] != undefined) {
            filter.and.splice(1, index);
          }
        });
      } else {
        serviceOfferIds = filter['serviceOfferIds']?.elemMatch.in;
        filter = null;
      }
    }
    return this.findContactByServiceOfferIds(
      fields,
      serviceOfferIds,
      options,
      filter,
      extendedVariables,
    );
  }
}
