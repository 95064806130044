import { Component, Input, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import DataSource from 'devextreme/data/data_source';
import { CoreListComponent } from '../list/list.component';
import { ListComponentBase } from '../list/list.component-base';

import { GCFactory } from '@clarilog/core';
import { TranslateService } from '@clarilog/shared/services';
import { MasterDetail } from '@clarilog/shared2/models';

/** Représente le composent PrintLinkList. */
@Component({
  selector: 'clc-print-link-list',
  templateUrl: './print-link-list.component.html',
  styleUrls: ['./print-link-list.component.scss'],
})
export class CorePrintLinkListComponent extends ListComponentBase {
  /** Obtient ou définit la source de données courante. */
  @Input() currentSource: ModelDataSourceContext = new ModelDataSourceContext({
    datasource: new DataSource([]),
  });

  @Input() modelState: ModelState;
  @Input() hint: string;
  @Input() canSelect: boolean = true;
  @Input() typeMode: string = 'Grid';
  @Input() masterDetail: MasterDetail;

  @ViewChild(CoreListComponent, { static: true }) list: CoreListComponent;

  /**Force l'ecoute sur les queryParams */
  dynamicRoute: boolean = true;

  constructor(
    public _route: ActivatedRoute,
    public gcFactory: GCFactory,
    public router: Router,
  ) {
    super();
  }

  refresh() {
    this.source?.datasource?.reload();
    this.list.refresh();
  }

  onRowClick(e) {
    let getTabId = (e?.data?.entityType as string).toLocaleLowerCase();

    let communicationTabs: string[] = ['messages', 'ticketemails'];
    let route = communicationTabs.includes(getTabId)
      ? 'communication'
      : 'treatment';

    let queryParamsList = {
      navigateTo: route,
      tabs: getTabId,
      eventId: e?.data?.id != undefined ? e?.data?.id : null,
      dynamic: this.dynamicRoute == true ? this.dynamicRoute : null,
    };

    this.router.navigate([], {
      queryParams: queryParamsList,
      queryParamsHandling: 'merge',
      relativeTo: this._route,
      skipLocationChange: true,
    });

    this.modelState.formComponent.navigateToByTitle(
      TranslateService.get(`entities/incident/${route}`),
    );

    this.modelState.on.emit({
      eventName: 'ticket-event',
      eventData: {
        change_tab_index: true,
        tabs: getTabId,
        eventId: e?.data?.id != undefined ? e?.data?.id : null,
      },
    });

    this.dynamicRoute = !this.dynamicRoute;
  }
}
