<clc-work-items
  [showBack]="true"
  [title]="formTilte"
  [modelState]="model"
  [resources]="resources"
  [parameters]="parameters"
  [selectedKeys]="selectedKeys"
  [errors]="errors"
  (onBeforeRefresh)="onBeforeRefresh($event)"
>
  <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
    <cl-toolbar-item-wait-button
      icon="fas fa-times"
      [disabled]="selectedKeys.length === 0"
      [text]="'delete' | translate"
      (onClick)="delete()"
      [wait]="waitDelete"
    ></cl-toolbar-item-wait-button>

    <cl-toolbar-item-wait-button
      [icon]="restoreIcon"
      [disabled]="selectedKeys.length === 0"
      [text]="restoreCaption | translate"
      (onClick)="restore()"
      [wait]="waitRestore"
    ></cl-toolbar-item-wait-button>

    <!-- <cl-toolbar-item-button
      *ngFor="let action of toolbar"
      [text]="action.text"
      [icon]="action.icon"
      [disabled]="action.disabled"
      (onClick)="action.click($event)"
    ></cl-toolbar-item-button> -->
  </cl-toolbar-items>
</clc-work-items>

<dx-load-panel
  shadingColor="rgba(0,0,0,0.1)"
  [visible]="deleteLoadIndicator"
  [showIndicator]="true"
  [showPane]="true"
  [shading]="true"
  [hideOnOutsideClick]="false"
></dx-load-panel>
