import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTaskStatusesBaseVariables, FirstTaskStatusesBaseVariables, CountTaskStatusesBaseVariables, FindTaskStatusesBaseVariables, SearchTaskStatusesBaseVariables, ExportSchemaTaskStatusesBaseVariables, ExportDataTaskStatusesBaseVariables, CustomQueryTaskStatusesBaseVariables, CustomQueryIdTaskStatusesBaseVariables, UsedTaskStatusesBaseVariables, ExistTaskStatusesBaseVariables, FindRecyclesTaskStatusesBaseVariables, CountRecyclesTaskStatusesBaseVariables, ReadOnlyTaskStatusesBaseVariables, FindArchivedTaskStatusesBaseVariables, CountAllTaskStatusesBaseVariables, FindDynamicPropertyFieldsTaskStatusesBaseVariables, FindUnassociatedTicketTasksTaskStatusesBaseVariables, FindUnassociatedPredecessorTicketTasksTaskStatusesBaseVariables, InsertTaskStatusesBaseVariables, UpdateTaskStatusesBaseVariables, ImportDataTaskStatusesBaseVariables, UpdateManyTaskStatusesBaseVariables, DeleteTaskStatusesBaseVariables, RestoreTaskStatusesBaseVariables, RecycleTaskStatusesBaseVariables, RestoreArchivedTaskStatusesBaseVariables, ArchivedTaskStatusesBaseVariables, AddFileDynamicFieldTaskStatusesBaseVariables, RemoveFileDynamicFieldTaskStatusesBaseVariables, AddTicketTasksTaskStatusesBaseVariables, RemoveTicketTasksTaskStatusesBaseVariables, AddPredecessorTicketTasksTaskStatusesBaseVariables, RemovePredecessorTicketTasksTaskStatusesBaseVariables } from '../gqls'
import { GetTaskStatusesDocument, FirstTaskStatusesDocument, CountTaskStatusesDocument, FindTaskStatusesDocument, SearchTaskStatusesDocument, ExportSchemaTaskStatusesDocument, ExportDataTaskStatusesDocument, CustomQueryTaskStatusesDocument, CustomQueryIdTaskStatusesDocument, UsedTaskStatusesDocument, ExistTaskStatusesDocument, FindRecyclesTaskStatusesDocument, CountRecyclesTaskStatusesDocument, ReadOnlyTaskStatusesDocument, FindArchivedTaskStatusesDocument, CountAllTaskStatusesDocument, FindDynamicPropertyFieldsTaskStatusesDocument, FindUnassociatedTicketTasksTaskStatusesDocument, FindUnassociatedPredecessorTicketTasksTaskStatusesDocument, InsertTaskStatusesDocument, UpdateTaskStatusesDocument, ImportDataTaskStatusesDocument, UpdateManyTaskStatusesDocument, DeleteTaskStatusesDocument, RestoreTaskStatusesDocument, RecycleTaskStatusesDocument, RestoreArchivedTaskStatusesDocument, ArchivedTaskStatusesDocument, AddFileDynamicFieldTaskStatusesDocument, RemoveFileDynamicFieldTaskStatusesDocument, AddTicketTasksTaskStatusesDocument, RemoveTicketTasksTaskStatusesDocument, AddPredecessorTicketTasksTaskStatusesDocument, RemovePredecessorTicketTasksTaskStatusesDocument } from '../gqls'
import { ServiceSingleResultOfTaskStatus, QueryContextOfTaskStatus, FilterOfTaskStatus, ServiceSingleResultOfInt64, ServiceListResultOfTaskStatus, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfTaskStatus, QueryContextOfTicketTask, FilterOfTicketTask, ServiceListResultOfTicketTask, TaskStatusInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TaskStatusBaseService {
    
public modelName = 'taskStatus';
public modelPluralName = 'taskStatuses';

	private getTaskStatusesQuery: GetTaskStatusesDocument;
	private firstTaskStatusesQuery: FirstTaskStatusesDocument;
	private countTaskStatusesQuery: CountTaskStatusesDocument;
	private findTaskStatusesQuery: FindTaskStatusesDocument;
	private searchTaskStatusesQuery: SearchTaskStatusesDocument;
	private exportSchemaTaskStatusesQuery: ExportSchemaTaskStatusesDocument;
	private exportDataTaskStatusesQuery: ExportDataTaskStatusesDocument;
	private customQueryTaskStatusesQuery: CustomQueryTaskStatusesDocument;
	private customQueryIdTaskStatusesQuery: CustomQueryIdTaskStatusesDocument;
	private usedTaskStatusesQuery: UsedTaskStatusesDocument;
	private existTaskStatusesQuery: ExistTaskStatusesDocument;
	private findRecyclesTaskStatusesQuery: FindRecyclesTaskStatusesDocument;
	private countRecyclesTaskStatusesQuery: CountRecyclesTaskStatusesDocument;
	private readOnlyTaskStatusesQuery: ReadOnlyTaskStatusesDocument;
	private findArchivedTaskStatusesQuery: FindArchivedTaskStatusesDocument;
	private countAllTaskStatusesQuery: CountAllTaskStatusesDocument;
	private findDynamicPropertyFieldsTaskStatusesQuery: FindDynamicPropertyFieldsTaskStatusesDocument;
	private findUnassociatedTicketTasksTaskStatusesQuery: FindUnassociatedTicketTasksTaskStatusesDocument;
	private findUnassociatedPredecessorTicketTasksTaskStatusesQuery: FindUnassociatedPredecessorTicketTasksTaskStatusesDocument;
	private insertTaskStatusesMutation: InsertTaskStatusesDocument;
	private updateTaskStatusesMutation: UpdateTaskStatusesDocument;
	private importDataTaskStatusesMutation: ImportDataTaskStatusesDocument;
	private updateManyTaskStatusesMutation: UpdateManyTaskStatusesDocument;
	private deleteTaskStatusesMutation: DeleteTaskStatusesDocument;
	private restoreTaskStatusesMutation: RestoreTaskStatusesDocument;
	private recycleTaskStatusesMutation: RecycleTaskStatusesDocument;
	private restoreArchivedTaskStatusesMutation: RestoreArchivedTaskStatusesDocument;
	private archivedTaskStatusesMutation: ArchivedTaskStatusesDocument;
	private addFileDynamicFieldTaskStatusesMutation: AddFileDynamicFieldTaskStatusesDocument;
	private removeFileDynamicFieldTaskStatusesMutation: RemoveFileDynamicFieldTaskStatusesDocument;
	private addTicketTasksTaskStatusesMutation: AddTicketTasksTaskStatusesDocument;
	private removeTicketTasksTaskStatusesMutation: RemoveTicketTasksTaskStatusesDocument;
	private addPredecessorTicketTasksTaskStatusesMutation: AddPredecessorTicketTasksTaskStatusesDocument;
	private removePredecessorTicketTasksTaskStatusesMutation: RemovePredecessorTicketTasksTaskStatusesDocument;

	constructor(private injector: Injector) {
		this.getTaskStatusesQuery = this.injector.get(GetTaskStatusesDocument);
		this.firstTaskStatusesQuery = this.injector.get(FirstTaskStatusesDocument);
		this.countTaskStatusesQuery = this.injector.get(CountTaskStatusesDocument);
		this.findTaskStatusesQuery = this.injector.get(FindTaskStatusesDocument);
		this.searchTaskStatusesQuery = this.injector.get(SearchTaskStatusesDocument);
		this.exportSchemaTaskStatusesQuery = this.injector.get(ExportSchemaTaskStatusesDocument);
		this.exportDataTaskStatusesQuery = this.injector.get(ExportDataTaskStatusesDocument);
		this.customQueryTaskStatusesQuery = this.injector.get(CustomQueryTaskStatusesDocument);
		this.customQueryIdTaskStatusesQuery = this.injector.get(CustomQueryIdTaskStatusesDocument);
		this.usedTaskStatusesQuery = this.injector.get(UsedTaskStatusesDocument);
		this.existTaskStatusesQuery = this.injector.get(ExistTaskStatusesDocument);
		this.findRecyclesTaskStatusesQuery = this.injector.get(FindRecyclesTaskStatusesDocument);
		this.countRecyclesTaskStatusesQuery = this.injector.get(CountRecyclesTaskStatusesDocument);
		this.readOnlyTaskStatusesQuery = this.injector.get(ReadOnlyTaskStatusesDocument);
		this.findArchivedTaskStatusesQuery = this.injector.get(FindArchivedTaskStatusesDocument);
		this.countAllTaskStatusesQuery = this.injector.get(CountAllTaskStatusesDocument);
		this.findDynamicPropertyFieldsTaskStatusesQuery = this.injector.get(FindDynamicPropertyFieldsTaskStatusesDocument);
		this.findUnassociatedTicketTasksTaskStatusesQuery = this.injector.get(FindUnassociatedTicketTasksTaskStatusesDocument);
		this.findUnassociatedPredecessorTicketTasksTaskStatusesQuery = this.injector.get(FindUnassociatedPredecessorTicketTasksTaskStatusesDocument);
		this.insertTaskStatusesMutation = this.injector.get(InsertTaskStatusesDocument);
		this.updateTaskStatusesMutation = this.injector.get(UpdateTaskStatusesDocument);
		this.importDataTaskStatusesMutation = this.injector.get(ImportDataTaskStatusesDocument);
		this.updateManyTaskStatusesMutation = this.injector.get(UpdateManyTaskStatusesDocument);
		this.deleteTaskStatusesMutation = this.injector.get(DeleteTaskStatusesDocument);
		this.restoreTaskStatusesMutation = this.injector.get(RestoreTaskStatusesDocument);
		this.recycleTaskStatusesMutation = this.injector.get(RecycleTaskStatusesDocument);
		this.restoreArchivedTaskStatusesMutation = this.injector.get(RestoreArchivedTaskStatusesDocument);
		this.archivedTaskStatusesMutation = this.injector.get(ArchivedTaskStatusesDocument);
		this.addFileDynamicFieldTaskStatusesMutation = this.injector.get(AddFileDynamicFieldTaskStatusesDocument);
		this.removeFileDynamicFieldTaskStatusesMutation = this.injector.get(RemoveFileDynamicFieldTaskStatusesDocument);
		this.addTicketTasksTaskStatusesMutation = this.injector.get(AddTicketTasksTaskStatusesDocument);
		this.removeTicketTasksTaskStatusesMutation = this.injector.get(RemoveTicketTasksTaskStatusesDocument);
		this.addPredecessorTicketTasksTaskStatusesMutation = this.injector.get(AddPredecessorTicketTasksTaskStatusesDocument);
		this.removePredecessorTicketTasksTaskStatusesMutation = this.injector.get(RemovePredecessorTicketTasksTaskStatusesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatus> {
    
    		let variables: GetTaskStatusesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.get));
            }
            else{
                let result:ServiceSingleResultOfTaskStatus={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatus> {
    
    		let variables: FirstTaskStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTaskStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: FindTaskStatusesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: SearchTaskStatusesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTaskStatusesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTaskStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: CustomQueryTaskStatusesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: CustomQueryIdTaskStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.usedTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTaskStatusesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: FindRecyclesTaskStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesTaskStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyTaskStatusesBaseVariables = {
    			id: id
    		}
    				return this.readOnlyTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTaskStatus,
		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTaskStatus> {
    
    		let variables: FindArchivedTaskStatusesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllTaskStatusesBaseVariables = {
    			filter: filter
    		}
    				return this.countAllTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsTaskStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTicketTasks(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicketTask,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTask,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicketTask> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('ticketTasks', fields, null, [
			GqlSubFieldArg.create('filterOfTicketTasks', 'filter'),
			GqlSubFieldArg.create('optionsOfTicketTasks', 'options'),
		]),
		])
		extendedVariables['filterOfTicketTasks'] = filter;
		extendedVariables['optionsOfTicketTasks'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.ticketTasks));
            }
            else{
                let result: ServiceListResultOfTicketTask = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTask,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTask> {
    
    		let variables: FindUnassociatedTicketTasksTaskStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketTasksTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.findUnassociatedTicketTasks));
    	}

	@InjectArgs
	public findAssociatedPredecessorTicketTasks(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicketTask,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTask,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicketTask> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('predecessorTicketTasks', fields, null, [
			GqlSubFieldArg.create('filterOfPredecessorTicketTasks', 'filter'),
			GqlSubFieldArg.create('optionsOfPredecessorTicketTasks', 'options'),
		]),
		])
		extendedVariables['filterOfPredecessorTicketTasks'] = filter;
		extendedVariables['optionsOfPredecessorTicketTasks'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.predecessorTicketTasks));
            }
            else{
                let result: ServiceListResultOfTicketTask = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedPredecessorTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketTask,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicketTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketTask> {
    
    		let variables: FindUnassociatedPredecessorTicketTasksTaskStatusesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedPredecessorTicketTasksTaskStatusesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.findUnassociatedPredecessorTicketTasks));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: TaskStatusInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatus> {
    
    		let variables: InsertTaskStatusesBaseVariables = {
    			entity: entity
    		}
    				return this.insertTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTaskStatus> {
    
    		let variables: UpdateTaskStatusesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataTaskStatusesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTaskStatus,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTaskStatusesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.recycleTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedTaskStatusesBaseVariables = {
    			ids: ids
    		}
    				return this.archivedTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldTaskStatusesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldTaskStatusesBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskIds') ticketTaskIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketTasksTaskStatusesBaseVariables = {
    			id: id,
			ticketTaskIds: ticketTaskIds
    		}
    				return this.addTicketTasksTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.addTicketTasks));
    	}

    	/**  */
    	@InjectArgs
    	public removeTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskIds') ticketTaskIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketTasksTaskStatusesBaseVariables = {
    			ticketTaskIds: ticketTaskIds
    		}
    				return this.removeTicketTasksTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.removeTicketTasks));
    	}

    	/**  */
    	@InjectArgs
    	public addPredecessorTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskIds') ticketTaskIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPredecessorTicketTasksTaskStatusesBaseVariables = {
    			id: id,
			ticketTaskIds: ticketTaskIds
    		}
    				return this.addPredecessorTicketTasksTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.addPredecessorTicketTasks));
    	}

    	/**  */
    	@InjectArgs
    	public removePredecessorTicketTasks(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketTaskIds') ticketTaskIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePredecessorTicketTasksTaskStatusesBaseVariables = {
    			ticketTaskIds: ticketTaskIds
    		}
    				return this.removePredecessorTicketTasksTaskStatusesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.taskStatuses.removePredecessorTicketTasks));
    	}
    
}