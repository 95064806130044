import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetCorrectionStockMovementsBaseVariables, FirstCorrectionStockMovementsBaseVariables, CountCorrectionStockMovementsBaseVariables, FindCorrectionStockMovementsBaseVariables, SearchCorrectionStockMovementsBaseVariables, ExportSchemaCorrectionStockMovementsBaseVariables, ExportDataCorrectionStockMovementsBaseVariables, CustomQueryCorrectionStockMovementsBaseVariables, CustomQueryIdCorrectionStockMovementsBaseVariables, UsedCorrectionStockMovementsBaseVariables, FindStorageOutConsumableItemsCorrectionStockMovementsBaseVariables, ExistCorrectionStockMovementsBaseVariables, FindRecyclesCorrectionStockMovementsBaseVariables, CountRecyclesCorrectionStockMovementsBaseVariables, ReadOnlyCorrectionStockMovementsBaseVariables, FindArchivedCorrectionStockMovementsBaseVariables, CountAllCorrectionStockMovementsBaseVariables, FindDynamicPropertyFieldsCorrectionStockMovementsBaseVariables, InsertCorrectionStockMovementsBaseVariables, UpdateCorrectionStockMovementsBaseVariables, ImportDataCorrectionStockMovementsBaseVariables, UpdateManyCorrectionStockMovementsBaseVariables, DeleteCorrectionStockMovementsBaseVariables, RestoreCorrectionStockMovementsBaseVariables, RecycleCorrectionStockMovementsBaseVariables, RestoreArchivedCorrectionStockMovementsBaseVariables, ArchivedCorrectionStockMovementsBaseVariables, AddFileDynamicFieldCorrectionStockMovementsBaseVariables, RemoveFileDynamicFieldCorrectionStockMovementsBaseVariables } from '../gqls'
import { GetCorrectionStockMovementsDocument, FirstCorrectionStockMovementsDocument, CountCorrectionStockMovementsDocument, FindCorrectionStockMovementsDocument, SearchCorrectionStockMovementsDocument, ExportSchemaCorrectionStockMovementsDocument, ExportDataCorrectionStockMovementsDocument, CustomQueryCorrectionStockMovementsDocument, CustomQueryIdCorrectionStockMovementsDocument, UsedCorrectionStockMovementsDocument, FindStorageOutConsumableItemsCorrectionStockMovementsDocument, ExistCorrectionStockMovementsDocument, FindRecyclesCorrectionStockMovementsDocument, CountRecyclesCorrectionStockMovementsDocument, ReadOnlyCorrectionStockMovementsDocument, FindArchivedCorrectionStockMovementsDocument, CountAllCorrectionStockMovementsDocument, FindDynamicPropertyFieldsCorrectionStockMovementsDocument, InsertCorrectionStockMovementsDocument, UpdateCorrectionStockMovementsDocument, ImportDataCorrectionStockMovementsDocument, UpdateManyCorrectionStockMovementsDocument, DeleteCorrectionStockMovementsDocument, RestoreCorrectionStockMovementsDocument, RecycleCorrectionStockMovementsDocument, RestoreArchivedCorrectionStockMovementsDocument, ArchivedCorrectionStockMovementsDocument, AddFileDynamicFieldCorrectionStockMovementsDocument, RemoveFileDynamicFieldCorrectionStockMovementsDocument } from '../gqls'
import { ServiceSingleResultOfCorrectionStockMovement, QueryContextOfCorrectionStockMovement, FilterOfCorrectionStockMovement, ServiceSingleResultOfInt64, ServiceListResultOfCorrectionStockMovement, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfOutCart, QueryContextOfOutCart, FilterOfOutCart, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfCorrectionStockMovement, CorrectionStockMovementInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class CorrectionStockMovementBaseService {
    
public modelName = 'correctionStockMovement';
public modelPluralName = 'correctionStockMovements';

	private getCorrectionStockMovementsQuery: GetCorrectionStockMovementsDocument;
	private firstCorrectionStockMovementsQuery: FirstCorrectionStockMovementsDocument;
	private countCorrectionStockMovementsQuery: CountCorrectionStockMovementsDocument;
	private findCorrectionStockMovementsQuery: FindCorrectionStockMovementsDocument;
	private searchCorrectionStockMovementsQuery: SearchCorrectionStockMovementsDocument;
	private exportSchemaCorrectionStockMovementsQuery: ExportSchemaCorrectionStockMovementsDocument;
	private exportDataCorrectionStockMovementsQuery: ExportDataCorrectionStockMovementsDocument;
	private customQueryCorrectionStockMovementsQuery: CustomQueryCorrectionStockMovementsDocument;
	private customQueryIdCorrectionStockMovementsQuery: CustomQueryIdCorrectionStockMovementsDocument;
	private usedCorrectionStockMovementsQuery: UsedCorrectionStockMovementsDocument;
	private findStorageOutConsumableItemsCorrectionStockMovementsQuery: FindStorageOutConsumableItemsCorrectionStockMovementsDocument;
	private existCorrectionStockMovementsQuery: ExistCorrectionStockMovementsDocument;
	private findRecyclesCorrectionStockMovementsQuery: FindRecyclesCorrectionStockMovementsDocument;
	private countRecyclesCorrectionStockMovementsQuery: CountRecyclesCorrectionStockMovementsDocument;
	private readOnlyCorrectionStockMovementsQuery: ReadOnlyCorrectionStockMovementsDocument;
	private findArchivedCorrectionStockMovementsQuery: FindArchivedCorrectionStockMovementsDocument;
	private countAllCorrectionStockMovementsQuery: CountAllCorrectionStockMovementsDocument;
	private findDynamicPropertyFieldsCorrectionStockMovementsQuery: FindDynamicPropertyFieldsCorrectionStockMovementsDocument;
	private insertCorrectionStockMovementsMutation: InsertCorrectionStockMovementsDocument;
	private updateCorrectionStockMovementsMutation: UpdateCorrectionStockMovementsDocument;
	private importDataCorrectionStockMovementsMutation: ImportDataCorrectionStockMovementsDocument;
	private updateManyCorrectionStockMovementsMutation: UpdateManyCorrectionStockMovementsDocument;
	private deleteCorrectionStockMovementsMutation: DeleteCorrectionStockMovementsDocument;
	private restoreCorrectionStockMovementsMutation: RestoreCorrectionStockMovementsDocument;
	private recycleCorrectionStockMovementsMutation: RecycleCorrectionStockMovementsDocument;
	private restoreArchivedCorrectionStockMovementsMutation: RestoreArchivedCorrectionStockMovementsDocument;
	private archivedCorrectionStockMovementsMutation: ArchivedCorrectionStockMovementsDocument;
	private addFileDynamicFieldCorrectionStockMovementsMutation: AddFileDynamicFieldCorrectionStockMovementsDocument;
	private removeFileDynamicFieldCorrectionStockMovementsMutation: RemoveFileDynamicFieldCorrectionStockMovementsDocument;

	constructor(private injector: Injector) {
		this.getCorrectionStockMovementsQuery = this.injector.get(GetCorrectionStockMovementsDocument);
		this.firstCorrectionStockMovementsQuery = this.injector.get(FirstCorrectionStockMovementsDocument);
		this.countCorrectionStockMovementsQuery = this.injector.get(CountCorrectionStockMovementsDocument);
		this.findCorrectionStockMovementsQuery = this.injector.get(FindCorrectionStockMovementsDocument);
		this.searchCorrectionStockMovementsQuery = this.injector.get(SearchCorrectionStockMovementsDocument);
		this.exportSchemaCorrectionStockMovementsQuery = this.injector.get(ExportSchemaCorrectionStockMovementsDocument);
		this.exportDataCorrectionStockMovementsQuery = this.injector.get(ExportDataCorrectionStockMovementsDocument);
		this.customQueryCorrectionStockMovementsQuery = this.injector.get(CustomQueryCorrectionStockMovementsDocument);
		this.customQueryIdCorrectionStockMovementsQuery = this.injector.get(CustomQueryIdCorrectionStockMovementsDocument);
		this.usedCorrectionStockMovementsQuery = this.injector.get(UsedCorrectionStockMovementsDocument);
		this.findStorageOutConsumableItemsCorrectionStockMovementsQuery = this.injector.get(FindStorageOutConsumableItemsCorrectionStockMovementsDocument);
		this.existCorrectionStockMovementsQuery = this.injector.get(ExistCorrectionStockMovementsDocument);
		this.findRecyclesCorrectionStockMovementsQuery = this.injector.get(FindRecyclesCorrectionStockMovementsDocument);
		this.countRecyclesCorrectionStockMovementsQuery = this.injector.get(CountRecyclesCorrectionStockMovementsDocument);
		this.readOnlyCorrectionStockMovementsQuery = this.injector.get(ReadOnlyCorrectionStockMovementsDocument);
		this.findArchivedCorrectionStockMovementsQuery = this.injector.get(FindArchivedCorrectionStockMovementsDocument);
		this.countAllCorrectionStockMovementsQuery = this.injector.get(CountAllCorrectionStockMovementsDocument);
		this.findDynamicPropertyFieldsCorrectionStockMovementsQuery = this.injector.get(FindDynamicPropertyFieldsCorrectionStockMovementsDocument);
		this.insertCorrectionStockMovementsMutation = this.injector.get(InsertCorrectionStockMovementsDocument);
		this.updateCorrectionStockMovementsMutation = this.injector.get(UpdateCorrectionStockMovementsDocument);
		this.importDataCorrectionStockMovementsMutation = this.injector.get(ImportDataCorrectionStockMovementsDocument);
		this.updateManyCorrectionStockMovementsMutation = this.injector.get(UpdateManyCorrectionStockMovementsDocument);
		this.deleteCorrectionStockMovementsMutation = this.injector.get(DeleteCorrectionStockMovementsDocument);
		this.restoreCorrectionStockMovementsMutation = this.injector.get(RestoreCorrectionStockMovementsDocument);
		this.recycleCorrectionStockMovementsMutation = this.injector.get(RecycleCorrectionStockMovementsDocument);
		this.restoreArchivedCorrectionStockMovementsMutation = this.injector.get(RestoreArchivedCorrectionStockMovementsDocument);
		this.archivedCorrectionStockMovementsMutation = this.injector.get(ArchivedCorrectionStockMovementsDocument);
		this.addFileDynamicFieldCorrectionStockMovementsMutation = this.injector.get(AddFileDynamicFieldCorrectionStockMovementsDocument);
		this.removeFileDynamicFieldCorrectionStockMovementsMutation = this.injector.get(RemoveFileDynamicFieldCorrectionStockMovementsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCorrectionStockMovement> {
    
    		let variables: GetCorrectionStockMovementsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.get));
            }
            else{
                let result:ServiceSingleResultOfCorrectionStockMovement={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCorrectionStockMovement> {
    
    		let variables: FirstCorrectionStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountCorrectionStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: FindCorrectionStockMovementsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: SearchCorrectionStockMovementsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaCorrectionStockMovementsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataCorrectionStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: CustomQueryCorrectionStockMovementsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: CustomQueryIdCorrectionStockMovementsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.usedCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.used));
    	}

    	/**  */
    	@InjectArgs
    	public findStorageOutConsumableItems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('storageUnitIds') storageUnitIds: Array<string>,
		@Args('options?') options?: QueryContextOfOutCart,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfOutCart,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOutCart> {
    
    		let variables: FindStorageOutConsumableItemsCorrectionStockMovementsBaseVariables = {
    			id: id,
			storageUnitIds: storageUnitIds,
			filter: filter,
			options: options
    		}
    				return this.findStorageOutConsumableItemsCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.findStorageOutConsumableItems));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistCorrectionStockMovementsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: FindRecyclesCorrectionStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesCorrectionStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyCorrectionStockMovementsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfCorrectionStockMovement,
		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfCorrectionStockMovement> {
    
    		let variables: FindArchivedCorrectionStockMovementsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllCorrectionStockMovementsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsCorrectionStockMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsCorrectionStockMovementsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: CorrectionStockMovementInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCorrectionStockMovement> {
    
    		let variables: InsertCorrectionStockMovementsBaseVariables = {
    			entity: entity
    		}
    				return this.insertCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfCorrectionStockMovement> {
    
    		let variables: UpdateCorrectionStockMovementsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataCorrectionStockMovementsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfCorrectionStockMovement,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyCorrectionStockMovementsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedCorrectionStockMovementsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldCorrectionStockMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldCorrectionStockMovementsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldCorrectionStockMovementsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.correctionStockMovements.removeFileDynamicField));
    	}
    
}