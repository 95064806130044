
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { WarningCustomerBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';

@Injectable({providedIn: 'root'})
@Authorize('clarilog.administrator')
export class WarningCustomerCoreService extends WarningCustomerBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}