import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDynamicPropertyGroupsBaseVariables, FirstDynamicPropertyGroupsBaseVariables, CountDynamicPropertyGroupsBaseVariables, FindDynamicPropertyGroupsBaseVariables, SearchDynamicPropertyGroupsBaseVariables, ExportSchemaDynamicPropertyGroupsBaseVariables, ExportDataDynamicPropertyGroupsBaseVariables, CustomQueryDynamicPropertyGroupsBaseVariables, CustomQueryIdDynamicPropertyGroupsBaseVariables, UsedDynamicPropertyGroupsBaseVariables, FindByTypeDynamicPropertyGroupsBaseVariables, ExistDynamicPropertyGroupsBaseVariables, InsertDynamicPropertyGroupsBaseVariables, UpdateDynamicPropertyGroupsBaseVariables, ImportDataDynamicPropertyGroupsBaseVariables, UpdateManyDynamicPropertyGroupsBaseVariables, DeleteDynamicPropertyGroupsBaseVariables } from '../gqls'
import { GetDynamicPropertyGroupsDocument, FirstDynamicPropertyGroupsDocument, CountDynamicPropertyGroupsDocument, FindDynamicPropertyGroupsDocument, SearchDynamicPropertyGroupsDocument, ExportSchemaDynamicPropertyGroupsDocument, ExportDataDynamicPropertyGroupsDocument, CustomQueryDynamicPropertyGroupsDocument, CustomQueryIdDynamicPropertyGroupsDocument, UsedDynamicPropertyGroupsDocument, FindByTypeDynamicPropertyGroupsDocument, ExistDynamicPropertyGroupsDocument, InsertDynamicPropertyGroupsDocument, UpdateDynamicPropertyGroupsDocument, ImportDataDynamicPropertyGroupsDocument, UpdateManyDynamicPropertyGroupsDocument, DeleteDynamicPropertyGroupsDocument } from '../gqls'
import { ServiceSingleResultOfDynamicPropertyGroup, QueryContextOfDynamicPropertyGroup, FilterOfDynamicPropertyGroup, ServiceSingleResultOfInt64, ServiceListResultOfDynamicPropertyGroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, DynamicPropertyGroupInput, FieldUpdateOperatorOfDynamicPropertyGroup } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DynamicPropertyGroupBaseService {
    
public modelName = 'dynamicPropertyGroup';
public modelPluralName = 'dynamicPropertyGroups';

	private getDynamicPropertyGroupsQuery: GetDynamicPropertyGroupsDocument;
	private firstDynamicPropertyGroupsQuery: FirstDynamicPropertyGroupsDocument;
	private countDynamicPropertyGroupsQuery: CountDynamicPropertyGroupsDocument;
	private findDynamicPropertyGroupsQuery: FindDynamicPropertyGroupsDocument;
	private searchDynamicPropertyGroupsQuery: SearchDynamicPropertyGroupsDocument;
	private exportSchemaDynamicPropertyGroupsQuery: ExportSchemaDynamicPropertyGroupsDocument;
	private exportDataDynamicPropertyGroupsQuery: ExportDataDynamicPropertyGroupsDocument;
	private customQueryDynamicPropertyGroupsQuery: CustomQueryDynamicPropertyGroupsDocument;
	private customQueryIdDynamicPropertyGroupsQuery: CustomQueryIdDynamicPropertyGroupsDocument;
	private usedDynamicPropertyGroupsQuery: UsedDynamicPropertyGroupsDocument;
	private findByTypeDynamicPropertyGroupsQuery: FindByTypeDynamicPropertyGroupsDocument;
	private existDynamicPropertyGroupsQuery: ExistDynamicPropertyGroupsDocument;
	private insertDynamicPropertyGroupsMutation: InsertDynamicPropertyGroupsDocument;
	private updateDynamicPropertyGroupsMutation: UpdateDynamicPropertyGroupsDocument;
	private importDataDynamicPropertyGroupsMutation: ImportDataDynamicPropertyGroupsDocument;
	private updateManyDynamicPropertyGroupsMutation: UpdateManyDynamicPropertyGroupsDocument;
	private deleteDynamicPropertyGroupsMutation: DeleteDynamicPropertyGroupsDocument;

	constructor(private injector: Injector) {
		this.getDynamicPropertyGroupsQuery = this.injector.get(GetDynamicPropertyGroupsDocument);
		this.firstDynamicPropertyGroupsQuery = this.injector.get(FirstDynamicPropertyGroupsDocument);
		this.countDynamicPropertyGroupsQuery = this.injector.get(CountDynamicPropertyGroupsDocument);
		this.findDynamicPropertyGroupsQuery = this.injector.get(FindDynamicPropertyGroupsDocument);
		this.searchDynamicPropertyGroupsQuery = this.injector.get(SearchDynamicPropertyGroupsDocument);
		this.exportSchemaDynamicPropertyGroupsQuery = this.injector.get(ExportSchemaDynamicPropertyGroupsDocument);
		this.exportDataDynamicPropertyGroupsQuery = this.injector.get(ExportDataDynamicPropertyGroupsDocument);
		this.customQueryDynamicPropertyGroupsQuery = this.injector.get(CustomQueryDynamicPropertyGroupsDocument);
		this.customQueryIdDynamicPropertyGroupsQuery = this.injector.get(CustomQueryIdDynamicPropertyGroupsDocument);
		this.usedDynamicPropertyGroupsQuery = this.injector.get(UsedDynamicPropertyGroupsDocument);
		this.findByTypeDynamicPropertyGroupsQuery = this.injector.get(FindByTypeDynamicPropertyGroupsDocument);
		this.existDynamicPropertyGroupsQuery = this.injector.get(ExistDynamicPropertyGroupsDocument);
		this.insertDynamicPropertyGroupsMutation = this.injector.get(InsertDynamicPropertyGroupsDocument);
		this.updateDynamicPropertyGroupsMutation = this.injector.get(UpdateDynamicPropertyGroupsDocument);
		this.importDataDynamicPropertyGroupsMutation = this.injector.get(ImportDataDynamicPropertyGroupsDocument);
		this.updateManyDynamicPropertyGroupsMutation = this.injector.get(UpdateManyDynamicPropertyGroupsDocument);
		this.deleteDynamicPropertyGroupsMutation = this.injector.get(DeleteDynamicPropertyGroupsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyGroup> {
    
    		let variables: GetDynamicPropertyGroupsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.get));
            }
            else{
                let result:ServiceSingleResultOfDynamicPropertyGroup={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyGroup> {
    
    		let variables: FirstDynamicPropertyGroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDynamicPropertyGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyGroup> {
    
    		let variables: FindDynamicPropertyGroupsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyGroup> {
    
    		let variables: SearchDynamicPropertyGroupsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDynamicPropertyGroupsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDynamicPropertyGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyGroup> {
    
    		let variables: CustomQueryDynamicPropertyGroupsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyGroup> {
    
    		let variables: CustomQueryIdDynamicPropertyGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDynamicPropertyGroupsBaseVariables = {
    			ids: ids
    		}
    				return this.usedDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.used));
    	}

    	/**  */
    	@InjectArgs
    	public findByType(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('types?') types?: Array<Maybe<string>>,
		@Args('options?') options?: QueryContextOfDynamicPropertyGroup,
		@Args('filter?') filter?: FilterOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyGroup> {
    
    		let variables: FindByTypeDynamicPropertyGroupsBaseVariables = {
    			types: types,
			filter: filter,
			options: options
    		}
    				return this.findByTypeDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.findByType));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDynamicPropertyGroupsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDynamicPropertyGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: DynamicPropertyGroupInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyGroup> {
    
    		let variables: InsertDynamicPropertyGroupsBaseVariables = {
    			entity: entity
    		}
    				return this.insertDynamicPropertyGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDynamicPropertyGroup> {
    
    		let variables: UpdateDynamicPropertyGroupsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateDynamicPropertyGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataDynamicPropertyGroupsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataDynamicPropertyGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfDynamicPropertyGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyDynamicPropertyGroupsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyDynamicPropertyGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteDynamicPropertyGroupsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteDynamicPropertyGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.dynamicPropertyGroups.delete));
    	}
    
}