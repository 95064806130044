import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfScanConfiguration, FilterOfScanConfiguration, QueryContextOfScanConfiguration, ServiceSingleResultOfInt64, ServiceListResultOfScanConfiguration, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfScanConfigurationAppointment, FilterOfScanConfigurationAppointment, QueryContextOfScanConfigurationAppointment, ServiceListResultOfInventoryHistory, FilterOfInventoryHistory, QueryContextOfInventoryHistory, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfScanConfiguration, ServiceListResultOfNetworkCredential, FilterOfNetworkCredential, QueryContextOfNetworkCredential, ServiceListResultOfScanExclude, FilterOfScanExclude, QueryContextOfScanExclude, ServiceListResultOfScanDataFile, FilterOfScanDataFile, QueryContextOfScanDataFile, ServiceListResultOfScanRegistryKey, FilterOfScanRegistryKey, QueryContextOfScanRegistryKey, ServiceListResultOfScanImportOption, FilterOfScanImportOption, QueryContextOfScanImportOption, ServiceListResultOfUser, FilterOfUser, QueryContextOfUser, ServiceListResultOfAsset, FilterOfAsset, QueryContextOfAsset, ServiceListResultOfAlert, FilterOfAlert, QueryContextOfAlert, ServiceListResultOfLdapHistory, FilterOfLdapHistory, QueryContextOfLdapHistory, ScanConfigurationAppointmentInput, FieldUpdatesOperatorOfScanConfigurationAppointment } from '../types'

export type GetScanConfigurationsResultType = {
    scanConfigurations: {
        get?: Maybe<ServiceSingleResultOfScanConfiguration>
    }
}

export type GetScanConfigurationsBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getScanConfigurations ${args} {
    scanConfigurations {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstScanConfigurationsResultType = {
    scanConfigurations: {
        first?: Maybe<ServiceSingleResultOfScanConfiguration>
    }
}

export type FirstScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstScanConfigurations ${args} {
    scanConfigurations {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountScanConfigurationsResultType = {
    scanConfigurations: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countScanConfigurations ${args} {
    scanConfigurations {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindScanConfigurationsResultType = {
    scanConfigurations: {
        find?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindScanConfigurationsBaseVariables = {
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfScanConfiguration = null, $filter: FilterOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findScanConfigurations ${args} {
    scanConfigurations {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchScanConfigurationsResultType = {
    scanConfigurations: {
        search?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type SearchScanConfigurationsBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchScanConfigurations ${args} {
    scanConfigurations {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaScanConfigurationsResultType = {
    scanConfigurations: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaScanConfigurationsBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaScanConfigurations ${args} {
    scanConfigurations {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataScanConfigurationsResultType = {
    scanConfigurations: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataScanConfigurations ${args} {
    scanConfigurations {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryScanConfigurationsResultType = {
    scanConfigurations: {
        customQuery?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type CustomQueryScanConfigurationsBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryScanConfigurations ${args} {
    scanConfigurations {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdScanConfigurationsResultType = {
    scanConfigurations: {
        customQueryId?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type CustomQueryIdScanConfigurationsBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdScanConfigurations ${args} {
    scanConfigurations {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedScanConfigurationsResultType = {
    scanConfigurations: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedScanConfigurations ${args} {
    scanConfigurations {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindAppointmentsScanConfigurationsResultType = {
    scanConfigurations: {
        findAppointments?: Maybe<ServiceListResultOfScanConfigurationAppointment>
    }
}

export type FindAppointmentsScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfigurationAppointment>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfigurationAppointment>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindAppointmentsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfigurationAppointment = null, $options: QueryContextOfScanConfigurationAppointment = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findAppointmentsScanConfigurations ${args} {
    scanConfigurations {
        findAppointments(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindAppointmentsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindAppointmentsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfigurationAppointment", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindScanConfigurationAppointmentsScanConfigurationsResultType = {
    scanConfigurations: {
        findScanConfigurationAppointments?: Maybe<ServiceListResultOfScanConfigurationAppointment>
    }
}

export type FindScanConfigurationAppointmentsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanConfigurationAppointment>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfigurationAppointment>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindScanConfigurationAppointmentsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanConfigurationAppointment = null, $options: QueryContextOfScanConfigurationAppointment = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findScanConfigurationAppointmentsScanConfigurations ${args} {
    scanConfigurations {
        findScanConfigurationAppointments(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindScanConfigurationAppointmentsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindScanConfigurationAppointmentsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfigurationAppointment", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindLastInErrorScanConfigurationsResultType = {
    scanConfigurations: {
        findLastInError?: Maybe<ServiceListResultOfInventoryHistory>
    }
}

export type FindLastInErrorScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfInventoryHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfInventoryHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindLastInErrorScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfInventoryHistory = null, $options: QueryContextOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findLastInErrorScanConfigurations ${args} {
    scanConfigurations {
        findLastInError(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindLastInErrorScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindLastInErrorScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfInventoryHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ScanConfigurationSchemasLinkTypeScanConfigurationsResultType = {
    scanConfigurations: {
        scanConfigurationSchemasLinkType?: Maybe<ServiceSingleResultOfString>
    }
}

export type ScanConfigurationSchemasLinkTypeScanConfigurationsBaseVariables = {
	type: Maybe<string>, /**  */
	format: ImportFileFormat, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ScanConfigurationSchemasLinkTypeScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: String = null, $format: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query scanConfigurationSchemasLinkTypeScanConfigurations ${args} {
    scanConfigurations {
        scanConfigurationSchemasLinkType(type: $type, format: $format) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ScanConfigurationSchemasLinkTypeScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ScanConfigurationSchemasLinkTypeScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistScanConfigurationsResultType = {
    scanConfigurations: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistScanConfigurationsBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existScanConfigurations ${args} {
    scanConfigurations {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindRecyclesScanConfigurationsResultType = {
    scanConfigurations: {
        findRecycles?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindRecyclesScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindRecyclesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findRecyclesScanConfigurations ${args} {
    scanConfigurations {
        findRecycles(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindRecyclesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindRecyclesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountRecyclesScanConfigurationsResultType = {
    scanConfigurations: {
        countRecycles?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountRecyclesScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountRecyclesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countRecyclesScanConfigurations ${args} {
    scanConfigurations {
        countRecycles(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountRecyclesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountRecyclesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ReadOnlyScanConfigurationsResultType = {
    scanConfigurations: {
        readOnly?: Maybe<ServiceSingleResultOfEntityAttribute>
    }
}

export type ReadOnlyScanConfigurationsBaseVariables = {
	id: string, /** L'Id de l'élément. */
}

/** Vérifie si l'entité est en lecture seule. */
@Injectable({providedIn: 'root'})
export class ReadOnlyScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query readOnlyScanConfigurations ${args} {
    scanConfigurations {
        readOnly(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ReadOnlyScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ReadOnlyScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfEntityAttribute", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindArchivedScanConfigurationsResultType = {
    scanConfigurations: {
        findArchived?: Maybe<ServiceListResultOfScanConfiguration>
    }
}

export type FindArchivedScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanConfiguration>, /** Les options de requêtage à appliquer. */
}

/** Récupère les entités archivées selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindArchivedScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null, $options: QueryContextOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findArchivedScanConfigurations ${args} {
    scanConfigurations {
        findArchived(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindArchivedScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindArchivedScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountAllScanConfigurationsResultType = {
    scanConfigurations: {
        countAll?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountAllScanConfigurationsBaseVariables = {
	filter: Maybe<FilterOfScanConfiguration>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité mis en corbeille selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountAllScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countAllScanConfigurations ${args} {
    scanConfigurations {
        countAll(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountAllScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountAllScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDynamicPropertyFieldsScanConfigurationsResultType = {
    scanConfigurations: {
        findDynamicPropertyFields?: Maybe<ServiceListResultOfDynamicPropertyField>
    }
}

export type FindDynamicPropertyFieldsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	entry: Maybe<FieldUpdateOperatorOfScanConfiguration>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindDynamicPropertyFieldsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $entry: FieldUpdateOperatorOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDynamicPropertyFieldsScanConfigurations ${args} {
    scanConfigurations {
        findDynamicPropertyFields(id: $id, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDynamicPropertyFieldsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDynamicPropertyFieldsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDynamicPropertyField", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedNetworkCredentialsScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedNetworkCredentials?: Maybe<ServiceListResultOfNetworkCredential>
    }
}

export type FindUnassociatedNetworkCredentialsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfNetworkCredential>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfNetworkCredential>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedNetworkCredentialsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfNetworkCredential = null, $options: QueryContextOfNetworkCredential = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedNetworkCredentialsScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedNetworkCredentials(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedNetworkCredentialsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedNetworkCredentialsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfNetworkCredential", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanExcludesScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedScanExcludes?: Maybe<ServiceListResultOfScanExclude>
    }
}

export type FindUnassociatedScanExcludesScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanExclude>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanExclude>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanExcludesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanExclude = null, $options: QueryContextOfScanExclude = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanExcludesScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedScanExcludes(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanExcludesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanExcludesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanExclude", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanDataFilesScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedScanDataFiles?: Maybe<ServiceListResultOfScanDataFile>
    }
}

export type FindUnassociatedScanDataFilesScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanDataFile>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanDataFile>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanDataFilesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanDataFile = null, $options: QueryContextOfScanDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanDataFilesScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedScanDataFiles(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanDataFilesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanDataFilesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanRegistryKeysScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedScanRegistryKeys?: Maybe<ServiceListResultOfScanRegistryKey>
    }
}

export type FindUnassociatedScanRegistryKeysScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanRegistryKey>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanRegistryKey>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanRegistryKeysScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanRegistryKey = null, $options: QueryContextOfScanRegistryKey = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanRegistryKeysScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedScanRegistryKeys(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanRegistryKeysScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanRegistryKeysScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanRegistryKey", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedScanImportOptionsScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedScanImportOptions?: Maybe<ServiceListResultOfScanImportOption>
    }
}

export type FindUnassociatedScanImportOptionsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfScanImportOption>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfScanImportOption>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedScanImportOptionsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfScanImportOption = null, $options: QueryContextOfScanImportOption = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedScanImportOptionsScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedScanImportOptions(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedScanImportOptionsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedScanImportOptionsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfScanImportOption", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedUsersScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedUsers?: Maybe<ServiceListResultOfUser>
    }
}

export type FindUnassociatedUsersScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfUser>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfUser>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedUsersScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfUser = null, $options: QueryContextOfUser = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedUsersScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedUsers(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedUsersScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedUsersScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfUser", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAssetsScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedAssets?: Maybe<ServiceListResultOfAsset>
    }
}

export type FindUnassociatedAssetsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAsset>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAsset>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAssetsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAsset = null, $options: QueryContextOfAsset = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAssetsScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedAssets(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAssetsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAssetsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAsset", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedAlertsScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedAlerts?: Maybe<ServiceListResultOfAlert>
    }
}

export type FindUnassociatedAlertsScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfAlert>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfAlert>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedAlertsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfAlert = null, $options: QueryContextOfAlert = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedAlertsScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedAlerts(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedAlertsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedAlertsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfAlert", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedInventoryHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedInventoryHistories?: Maybe<ServiceListResultOfInventoryHistory>
    }
}

export type FindUnassociatedInventoryHistoriesScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfInventoryHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfInventoryHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedInventoryHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfInventoryHistory = null, $options: QueryContextOfInventoryHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedInventoryHistoriesScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedInventoryHistories(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedInventoryHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedInventoryHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfInventoryHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindUnassociatedLdapHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        findUnassociatedLdapHistories?: Maybe<ServiceListResultOfLdapHistory>
    }
}

export type FindUnassociatedLdapHistoriesScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	filter: Maybe<FilterOfLdapHistory>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfLdapHistory>, /** Les options de requêtage à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class FindUnassociatedLdapHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $filter: FilterOfLdapHistory = null, $options: QueryContextOfLdapHistory = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findUnassociatedLdapHistoriesScanConfigurations ${args} {
    scanConfigurations {
        findUnassociatedLdapHistories(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindUnassociatedLdapHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindUnassociatedLdapHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfLdapHistory", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateManyScanConfigurationsResultType = {
    scanConfigurations: {
        updateMany?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateManyScanConfigurationsBaseVariables = {
	ids: Array<string>, /** L'identifiant des l'entités à mettre à jour. */
	entry: Maybe<FieldUpdateOperatorOfScanConfiguration>, /** Les actions de mise à jour à appliquer. */
}

/** Permet de mette à jour une entité. */
@Injectable({providedIn: 'root'})
export class UpdateManyScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $entry: FieldUpdateOperatorOfScanConfiguration = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateManyScanConfigurations ${args} {
    scanConfigurations {
        updateMany(ids: $ids, entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateManyScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateManyScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type DeleteScanConfigurationsResultType = {
    scanConfigurations: {
        delete?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type DeleteScanConfigurationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer ou à mettre en corbeille. */
}

/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class DeleteScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation deleteScanConfigurations ${args} {
    scanConfigurations {
        delete(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: DeleteScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<DeleteScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RunScanConfigurationsResultType = {
    scanConfigurations: {
        run?: Maybe<ServiceSingleResultOfScanConfiguration>
    }
}

export type RunScanConfigurationsBaseVariables = {
	scanConfigurationId: string, /**  */
	onlyStructLdap: boolean, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RunScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!, $onlyStructLdap: Boolean!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation runScanConfigurations ${args} {
    scanConfigurations {
        run(scanConfigurationId: $scanConfigurationId, onlyStructLdap: $onlyStructLdap) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RunScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RunScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CancelScanConfigurationsResultType = {
    scanConfigurations: {
        cancel?: Maybe<ServiceSingleResultOfScanConfiguration>
    }
}

export type CancelScanConfigurationsBaseVariables = {
	scanConfigurationId: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class CancelScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationId: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation cancelScanConfigurations ${args} {
    scanConfigurations {
        cancel(scanConfigurationId: $scanConfigurationId) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: CancelScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<CancelScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfScanConfiguration", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAppointmentsScanConfigurationsResultType = {
    scanConfigurations: {
        addAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAppointmentsScanConfigurationsBaseVariables = {
	scanConfigurationAppointments: Array<Maybe<ScanConfigurationAppointmentInput>>, /**  */
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAppointmentsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationAppointments: [ScanConfigurationAppointmentInput] = null, $id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAppointmentsScanConfigurations ${args} {
    scanConfigurations {
        addAppointments(scanConfigurationAppointments: $scanConfigurationAppointments, id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAppointmentsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAppointmentsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAppointmentsAuditsScanConfigurationsResultType = {
    scanConfigurations: {
        addAppointmentsAudits?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAppointmentsAuditsScanConfigurationsBaseVariables = {
	scanConfigurationAppointment: Maybe<ScanConfigurationAppointmentInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAppointmentsAuditsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationAppointment: ScanConfigurationAppointmentInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAppointmentsAuditsScanConfigurations ${args} {
    scanConfigurations {
        addAppointmentsAudits(scanConfigurationAppointment: $scanConfigurationAppointment) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAppointmentsAuditsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAppointmentsAuditsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAppointmentsScanConfigurationsResultType = {
    scanConfigurations: {
        removeAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAppointmentsScanConfigurationsBaseVariables = {
	scanConfigurationAppointments: Array<string>, /**  */
	id: string, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAppointmentsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$scanConfigurationAppointments: [Uuid!], $id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAppointmentsScanConfigurations ${args} {
    scanConfigurations {
        removeAppointments(scanConfigurationAppointments: $scanConfigurationAppointments, id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAppointmentsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAppointmentsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAppointmentsScanConfigurationsResultType = {
    scanConfigurations: {
        updateAppointments?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateAppointmentsScanConfigurationsBaseVariables = {
	entry: Array<Maybe<FieldUpdatesOperatorOfScanConfigurationAppointment>>, /** Les actions de mise à jour à appliquer. */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateAppointmentsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$entry: [FieldUpdatesOperatorOfScanConfigurationAppointment] = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAppointmentsScanConfigurations ${args} {
    scanConfigurations {
        updateAppointments(entry: $entry) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAppointmentsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAppointmentsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UpdateAppointmentsAuditScanConfigurationsResultType = {
    scanConfigurations: {
        updateAppointmentsAudit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UpdateAppointmentsAuditScanConfigurationsBaseVariables = {
	id: string, /**  */
	modified: Maybe<ScanConfigurationAppointmentInput>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class UpdateAppointmentsAuditScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $modified: ScanConfigurationAppointmentInput = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation updateAppointmentsAuditScanConfigurations ${args} {
    scanConfigurations {
        updateAppointmentsAudit(id: $id, modified: $modified) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: UpdateAppointmentsAuditScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<UpdateAppointmentsAuditScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddToFilterAuditScanConfigurationsResultType = {
    scanConfigurations: {
        addToFilterAudit?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddToFilterAuditScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddToFilterAuditScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addToFilterAuditScanConfigurations ${args} {
    scanConfigurations {
        addToFilterAudit(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddToFilterAuditScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddToFilterAuditScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveManyNetworkCredentialsScanConfigurationsResultType = {
    scanConfigurations: {
        removeManyNetworkCredentials?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveManyNetworkCredentialsScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	networkCredentialIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveManyNetworkCredentialsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $networkCredentialIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeManyNetworkCredentialsScanConfigurations ${args} {
    scanConfigurations {
        removeManyNetworkCredentials(ids: $ids, networkCredentialIds: $networkCredentialIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveManyNetworkCredentialsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveManyNetworkCredentialsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddManyNetworkCredentialsScanConfigurationsResultType = {
    scanConfigurations: {
        addManyNetworkCredentials?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddManyNetworkCredentialsScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	networkCredentialIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddManyNetworkCredentialsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $networkCredentialIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addManyNetworkCredentialsScanConfigurations ${args} {
    scanConfigurations {
        addManyNetworkCredentials(ids: $ids, networkCredentialIds: $networkCredentialIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddManyNetworkCredentialsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddManyNetworkCredentialsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveManyScanImportOptionsScanConfigurationsResultType = {
    scanConfigurations: {
        removeManyScanImportOptions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveManyScanImportOptionsScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	scanImportOptionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveManyScanImportOptionsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $scanImportOptionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeManyScanImportOptionsScanConfigurations ${args} {
    scanConfigurations {
        removeManyScanImportOptions(ids: $ids, scanImportOptionIds: $scanImportOptionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveManyScanImportOptionsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveManyScanImportOptionsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddManyScanImportOptionsScanConfigurationsResultType = {
    scanConfigurations: {
        addManyScanImportOptions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddManyScanImportOptionsScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	scanImportOptionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddManyScanImportOptionsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $scanImportOptionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addManyScanImportOptionsScanConfigurations ${args} {
    scanConfigurations {
        addManyScanImportOptions(ids: $ids, scanImportOptionIds: $scanImportOptionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddManyScanImportOptionsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddManyScanImportOptionsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveManyScanExcludesScanConfigurationsResultType = {
    scanConfigurations: {
        removeManyScanExcludes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveManyScanExcludesScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	scanExcludeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveManyScanExcludesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $scanExcludeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeManyScanExcludesScanConfigurations ${args} {
    scanConfigurations {
        removeManyScanExcludes(ids: $ids, scanExcludeIds: $scanExcludeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveManyScanExcludesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveManyScanExcludesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddManyScanExcludesScanConfigurationsResultType = {
    scanConfigurations: {
        addManyScanExcludes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddManyScanExcludesScanConfigurationsBaseVariables = {
	ids: Array<string>, /**  */
	scanExcludeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddManyScanExcludesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!], $scanExcludeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addManyScanExcludesScanConfigurations ${args} {
    scanConfigurations {
        addManyScanExcludes(ids: $ids, scanExcludeIds: $scanExcludeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddManyScanExcludesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddManyScanExcludesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ImportScanConfigurationLinkScanConfigurationsResultType = {
    scanConfigurations: {
        importScanConfigurationLink?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ImportScanConfigurationLinkScanConfigurationsBaseVariables = {
	file: Maybe<string>, /**  */
	type: Maybe<string>, /**  */
	format: ImportFileFormat, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class ImportScanConfigurationLinkScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$file: String = null, $type: String = null, $format: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation importScanConfigurationLinkScanConfigurations ${args} {
    scanConfigurations {
        importScanConfigurationLink(file: $file, type: $type, format: $format) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ImportScanConfigurationLinkScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ImportScanConfigurationLinkScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreScanConfigurationsResultType = {
    scanConfigurations: {
        restore?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreScanConfigurationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RestoreScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreScanConfigurations ${args} {
    scanConfigurations {
        restore(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RecycleScanConfigurationsResultType = {
    scanConfigurations: {
        recycle?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RecycleScanConfigurationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
@Injectable({providedIn: 'root'})
export class RecycleScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation recycleScanConfigurations ${args} {
    scanConfigurations {
        recycle(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RecycleScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RecycleScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RestoreArchivedScanConfigurationsResultType = {
    scanConfigurations: {
        restoreArchived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RestoreArchivedScanConfigurationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à supprimer. */
}

/** Permet de restauré une ou plusieurs entités mises en archive. */
@Injectable({providedIn: 'root'})
export class RestoreArchivedScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation restoreArchivedScanConfigurations ${args} {
    scanConfigurations {
        restoreArchived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RestoreArchivedScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RestoreArchivedScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ArchivedScanConfigurationsResultType = {
    scanConfigurations: {
        archived?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ArchivedScanConfigurationsBaseVariables = {
	ids: Array<string>, /** Le ou les identifiants de l'entité à archiver. */
}

/** Permet d'archiver une ou plusieurs entités. */
@Injectable({providedIn: 'root'})
export class ArchivedScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation archivedScanConfigurations ${args} {
    scanConfigurations {
        archived(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: ArchivedScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<ArchivedScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddFileDynamicFieldScanConfigurationsResultType = {
    scanConfigurations: {
        addFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddFileDynamicFieldScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddFileDynamicFieldScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addFileDynamicFieldScanConfigurations ${args} {
    scanConfigurations {
        addFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddFileDynamicFieldScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddFileDynamicFieldScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveFileDynamicFieldScanConfigurationsResultType = {
    scanConfigurations: {
        removeFileDynamicField?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveFileDynamicFieldScanConfigurationsBaseVariables = {
	id: Maybe<string>, /**  */
	fileId: Maybe<string>, /**  */
	propertyName: Maybe<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveFileDynamicFieldScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid = null, $fileId: Uuid = null, $propertyName: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeFileDynamicFieldScanConfigurations ${args} {
    scanConfigurations {
        removeFileDynamicField(id: $id, fileId: $fileId, propertyName: $propertyName) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveFileDynamicFieldScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveFileDynamicFieldScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddNetworkCredentialsScanConfigurationsResultType = {
    scanConfigurations: {
        addNetworkCredentials?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddNetworkCredentialsScanConfigurationsBaseVariables = {
	id: string, /**  */
	networkCredentialIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddNetworkCredentialsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $networkCredentialIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addNetworkCredentialsScanConfigurations ${args} {
    scanConfigurations {
        addNetworkCredentials(id: $id, networkCredentialIds: $networkCredentialIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddNetworkCredentialsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddNetworkCredentialsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveNetworkCredentialsScanConfigurationsResultType = {
    scanConfigurations: {
        removeNetworkCredentials?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveNetworkCredentialsScanConfigurationsBaseVariables = {
	id: string, /**  */
	networkCredentialIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveNetworkCredentialsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $networkCredentialIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeNetworkCredentialsScanConfigurations ${args} {
    scanConfigurations {
        removeNetworkCredentials(id: $id, networkCredentialIds: $networkCredentialIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveNetworkCredentialsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveNetworkCredentialsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanExcludesScanConfigurationsResultType = {
    scanConfigurations: {
        addScanExcludes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanExcludesScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanExcludeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanExcludesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanExcludeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanExcludesScanConfigurations ${args} {
    scanConfigurations {
        addScanExcludes(id: $id, scanExcludeIds: $scanExcludeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanExcludesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanExcludesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanExcludesScanConfigurationsResultType = {
    scanConfigurations: {
        removeScanExcludes?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanExcludesScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanExcludeIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanExcludesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanExcludeIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanExcludesScanConfigurations ${args} {
    scanConfigurations {
        removeScanExcludes(id: $id, scanExcludeIds: $scanExcludeIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanExcludesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanExcludesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanDataFilesScanConfigurationsResultType = {
    scanConfigurations: {
        addScanDataFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanDataFilesScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanDataFileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanDataFilesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanDataFileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanDataFilesScanConfigurations ${args} {
    scanConfigurations {
        addScanDataFiles(id: $id, scanDataFileIds: $scanDataFileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanDataFilesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanDataFilesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanDataFilesScanConfigurationsResultType = {
    scanConfigurations: {
        removeScanDataFiles?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanDataFilesScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanDataFileIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanDataFilesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanDataFileIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanDataFilesScanConfigurations ${args} {
    scanConfigurations {
        removeScanDataFiles(id: $id, scanDataFileIds: $scanDataFileIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanDataFilesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanDataFilesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanRegistryKeysScanConfigurationsResultType = {
    scanConfigurations: {
        addScanRegistryKeys?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanRegistryKeysScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanRegistryKeyIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanRegistryKeysScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanRegistryKeyIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanRegistryKeysScanConfigurations ${args} {
    scanConfigurations {
        addScanRegistryKeys(id: $id, scanRegistryKeyIds: $scanRegistryKeyIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanRegistryKeysScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanRegistryKeysScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanRegistryKeysScanConfigurationsResultType = {
    scanConfigurations: {
        removeScanRegistryKeys?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanRegistryKeysScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanRegistryKeyIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanRegistryKeysScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanRegistryKeyIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanRegistryKeysScanConfigurations ${args} {
    scanConfigurations {
        removeScanRegistryKeys(id: $id, scanRegistryKeyIds: $scanRegistryKeyIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanRegistryKeysScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanRegistryKeysScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddScanImportOptionsScanConfigurationsResultType = {
    scanConfigurations: {
        addScanImportOptions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddScanImportOptionsScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanImportOptionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddScanImportOptionsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanImportOptionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addScanImportOptionsScanConfigurations ${args} {
    scanConfigurations {
        addScanImportOptions(id: $id, scanImportOptionIds: $scanImportOptionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddScanImportOptionsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddScanImportOptionsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveScanImportOptionsScanConfigurationsResultType = {
    scanConfigurations: {
        removeScanImportOptions?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveScanImportOptionsScanConfigurationsBaseVariables = {
	id: string, /**  */
	scanImportOptionIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveScanImportOptionsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $scanImportOptionIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeScanImportOptionsScanConfigurations ${args} {
    scanConfigurations {
        removeScanImportOptions(id: $id, scanImportOptionIds: $scanImportOptionIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveScanImportOptionsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveScanImportOptionsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAlertsScanConfigurationsResultType = {
    scanConfigurations: {
        addAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAlertsScanConfigurationsBaseVariables = {
	id: string, /**  */
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAlertsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAlertsScanConfigurations ${args} {
    scanConfigurations {
        addAlerts(id: $id, alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAlertsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAlertsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAlertsScanConfigurationsResultType = {
    scanConfigurations: {
        removeAlerts?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAlertsScanConfigurationsBaseVariables = {
	alertIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAlertsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$alertIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAlertsScanConfigurations ${args} {
    scanConfigurations {
        removeAlerts(alertIds: $alertIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAlertsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAlertsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddAssetsScanConfigurationsResultType = {
    scanConfigurations: {
        addAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddAssetsScanConfigurationsBaseVariables = {
	id: string, /**  */
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddAssetsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addAssetsScanConfigurations ${args} {
    scanConfigurations {
        addAssets(id: $id, assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddAssetsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddAssetsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveAssetsScanConfigurationsResultType = {
    scanConfigurations: {
        removeAssets?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveAssetsScanConfigurationsBaseVariables = {
	assetIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveAssetsScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$assetIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeAssetsScanConfigurations ${args} {
    scanConfigurations {
        removeAssets(assetIds: $assetIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveAssetsScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveAssetsScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddInventoryHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        addInventoryHistories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddInventoryHistoriesScanConfigurationsBaseVariables = {
	id: string, /**  */
	inventoryHistoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddInventoryHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $inventoryHistoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addInventoryHistoriesScanConfigurations ${args} {
    scanConfigurations {
        addInventoryHistories(id: $id, inventoryHistoryIds: $inventoryHistoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddInventoryHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddInventoryHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveInventoryHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        removeInventoryHistories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveInventoryHistoriesScanConfigurationsBaseVariables = {
	inventoryHistoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveInventoryHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$inventoryHistoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeInventoryHistoriesScanConfigurations ${args} {
    scanConfigurations {
        removeInventoryHistories(inventoryHistoryIds: $inventoryHistoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveInventoryHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveInventoryHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddLdapHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        addLdapHistories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddLdapHistoriesScanConfigurationsBaseVariables = {
	id: string, /**  */
	ldapHistoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddLdapHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $ldapHistoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addLdapHistoriesScanConfigurations ${args} {
    scanConfigurations {
        addLdapHistories(id: $id, ldapHistoryIds: $ldapHistoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddLdapHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddLdapHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveLdapHistoriesScanConfigurationsResultType = {
    scanConfigurations: {
        removeLdapHistories?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveLdapHistoriesScanConfigurationsBaseVariables = {
	ldapHistoryIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveLdapHistoriesScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ldapHistoryIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeLdapHistoriesScanConfigurations ${args} {
    scanConfigurations {
        removeLdapHistories(ldapHistoryIds: $ldapHistoryIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveLdapHistoriesScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveLdapHistoriesScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type AddUsersScanConfigurationsResultType = {
    scanConfigurations: {
        addUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type AddUsersScanConfigurationsBaseVariables = {
	id: string, /**  */
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class AddUsersScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation addUsersScanConfigurations ${args} {
    scanConfigurations {
        addUsers(id: $id, userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: AddUsersScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<AddUsersScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type RemoveUsersScanConfigurationsResultType = {
    scanConfigurations: {
        removeUsers?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type RemoveUsersScanConfigurationsBaseVariables = {
	userIds: Array<string>, /**  */
}

/**  */
@Injectable({providedIn: 'root'})
export class RemoveUsersScanConfigurationsDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$userIds: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
mutation removeUsersScanConfigurations ${args} {
    scanConfigurations {
        removeUsers(userIds: $userIds) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public mutate(variables: RemoveUsersScanConfigurationsBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<FetchResult<RemoveUsersScanConfigurationsResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').mutate({
            mutation: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}
