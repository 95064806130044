import { Injectable } from '@angular/core';
import { MobileWorkItem, TranslateService, WorkItem } from '@clarilog/shared2';

@Injectable({
  providedIn: 'root',
})
export class MobileLayoutNavService {
  constructor() {}

  public static navItemSource(): MobileWorkItem[] {
    return [
      {
        title: TranslateService.get('globals/home-help-desk-user'),
        icon: 'fal fa-home',
        name: 'home',
        data: {
          route: 'home',
          policies: ['request-help-desk-user'],
          feature: 'feature-service-desk',
        },
        globalSearch: true,
      },
      {
        title: TranslateService.get('entities/incident/_title/plural'),
        icon: 'fal fa-file-medical-alt',
        data: {
          route: 'help-desk-incident',
          policies: ['incident-help-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'help-desk-incident',
        globalSearch: true,
        children: [
          {
            title: TranslateService.get('globals/isNew'),
            icon: 'fal fa-file-medical-alt',
            data: {
              route: '',
              policies: ['incident-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'all',
            globalSearch: true,
          },
          {
            title: TranslateService.get('globals/isOpen'),
            icon: 'fal fa-file-medical-alt',
            data: {
              route: 'in-progress',
              policies: ['incident-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'my-team',
            globalSearch: true,
          },
          {
            title: TranslateService.get('globals/processed'),
            icon: 'fal fa-file-medical-alt',
            data: {
              route: 'resolved',
              policies: ['incident-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'my-guy',
            globalSearch: true,
          },
        ],
      },
      {
        title: TranslateService.get('entities/request/_title/plural'),
        icon: 'fal fa-user-headset',
        data: {
          route: 'help-desk-request',
          policies: ['request-help-desk-user'],
          feature: 'feature-service-desk',
        },
        name: 'help-desk-request',
        globalSearch: true,
        children: [
          {
            title: TranslateService.get('globals/isNew'),
            icon: 'fal fa-user-headset',
            data: {
              route: '',
              policies: ['request-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'all',
            globalSearch: true,
          },
          {
            title: TranslateService.get('globals/isOpen'),
            icon: 'fal fa-user-headset',
            data: {
              route: 'in-progress',
              policies: ['request-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'my-team',
            globalSearch: true,
          },
          {
            title: TranslateService.get('globals/processed'),
            icon: 'fal fa-user-headset',
            data: {
              route: 'resolved',
              policies: ['request-help-desk-user'],
              feature: 'feature-service-desk',
            },
            name: 'my-guy',
            globalSearch: true,
          },
        ],
      },
      // {
      //   title: TranslateService.get('globals/disconnect'),
      //   icon: 'fa-light fa-power-off',
      //   name: 'home',
      //   data: {
      //     policies: ['request-help-desk-user'],
      //     feature: 'feature-service-desk',
      //   },
      //   globalSearch: true,
      // },
    ];
  }

  public static setChildId(
    children: MobileWorkItem[],
    parentId: string = '',
    parent: WorkItem = null,
  ) {
    let index = 1;

    children.forEach((el) => {
      el.id = parentId + '_' + index;

      if (parent != undefined) {
        el.expanded = true;
        if (el.data.route === '') {
          el.data.route = parent.data.route;
        } else {
          el.data.route = parent.data.route + '/' + el.data.route;
        }
      }
      if (el.children != undefined && el.children.length > 0) {
        el.disabled = true;
        this.setChildId(el.children, parentId + '_' + index, el);
      }
      index++;
    });
  }

  public static getNavItems(): MobileWorkItem[] {
    let navItems = this.navItemSource();

    this.setChildId(navItems);
    console.log(navItems);
    return navItems;
  }
}
