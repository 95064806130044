import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetVerifaliaTasksBaseVariables, FirstVerifaliaTasksBaseVariables, CountVerifaliaTasksBaseVariables, FindVerifaliaTasksBaseVariables, SearchVerifaliaTasksBaseVariables, ExportSchemaVerifaliaTasksBaseVariables, ExportDataVerifaliaTasksBaseVariables, CustomQueryVerifaliaTasksBaseVariables, CustomQueryIdVerifaliaTasksBaseVariables, UsedVerifaliaTasksBaseVariables, ExistVerifaliaTasksBaseVariables, InsertVerifaliaTasksBaseVariables, UpdateVerifaliaTasksBaseVariables, ImportDataVerifaliaTasksBaseVariables, UpdateManyVerifaliaTasksBaseVariables, DeleteVerifaliaTasksBaseVariables } from '../gqls'
import { GetVerifaliaTasksDocument, FirstVerifaliaTasksDocument, CountVerifaliaTasksDocument, FindVerifaliaTasksDocument, SearchVerifaliaTasksDocument, ExportSchemaVerifaliaTasksDocument, ExportDataVerifaliaTasksDocument, CustomQueryVerifaliaTasksDocument, CustomQueryIdVerifaliaTasksDocument, UsedVerifaliaTasksDocument, ExistVerifaliaTasksDocument, InsertVerifaliaTasksDocument, UpdateVerifaliaTasksDocument, ImportDataVerifaliaTasksDocument, UpdateManyVerifaliaTasksDocument, DeleteVerifaliaTasksDocument } from '../gqls'
import { ServiceSingleResultOfVerifaliaTask, QueryContextOfVerifaliaTask, FilterOfVerifaliaTask, ServiceSingleResultOfInt64, ServiceListResultOfVerifaliaTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, VerifaliaTaskInput, FieldUpdateOperatorOfVerifaliaTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class VerifaliaTaskBaseService {
    
public modelName = 'verifaliaTask';
public modelPluralName = 'verifaliaTasks';

	private getVerifaliaTasksQuery: GetVerifaliaTasksDocument;
	private firstVerifaliaTasksQuery: FirstVerifaliaTasksDocument;
	private countVerifaliaTasksQuery: CountVerifaliaTasksDocument;
	private findVerifaliaTasksQuery: FindVerifaliaTasksDocument;
	private searchVerifaliaTasksQuery: SearchVerifaliaTasksDocument;
	private exportSchemaVerifaliaTasksQuery: ExportSchemaVerifaliaTasksDocument;
	private exportDataVerifaliaTasksQuery: ExportDataVerifaliaTasksDocument;
	private customQueryVerifaliaTasksQuery: CustomQueryVerifaliaTasksDocument;
	private customQueryIdVerifaliaTasksQuery: CustomQueryIdVerifaliaTasksDocument;
	private usedVerifaliaTasksQuery: UsedVerifaliaTasksDocument;
	private existVerifaliaTasksQuery: ExistVerifaliaTasksDocument;
	private insertVerifaliaTasksMutation: InsertVerifaliaTasksDocument;
	private updateVerifaliaTasksMutation: UpdateVerifaliaTasksDocument;
	private importDataVerifaliaTasksMutation: ImportDataVerifaliaTasksDocument;
	private updateManyVerifaliaTasksMutation: UpdateManyVerifaliaTasksDocument;
	private deleteVerifaliaTasksMutation: DeleteVerifaliaTasksDocument;

	constructor(private injector: Injector) {
		this.getVerifaliaTasksQuery = this.injector.get(GetVerifaliaTasksDocument);
		this.firstVerifaliaTasksQuery = this.injector.get(FirstVerifaliaTasksDocument);
		this.countVerifaliaTasksQuery = this.injector.get(CountVerifaliaTasksDocument);
		this.findVerifaliaTasksQuery = this.injector.get(FindVerifaliaTasksDocument);
		this.searchVerifaliaTasksQuery = this.injector.get(SearchVerifaliaTasksDocument);
		this.exportSchemaVerifaliaTasksQuery = this.injector.get(ExportSchemaVerifaliaTasksDocument);
		this.exportDataVerifaliaTasksQuery = this.injector.get(ExportDataVerifaliaTasksDocument);
		this.customQueryVerifaliaTasksQuery = this.injector.get(CustomQueryVerifaliaTasksDocument);
		this.customQueryIdVerifaliaTasksQuery = this.injector.get(CustomQueryIdVerifaliaTasksDocument);
		this.usedVerifaliaTasksQuery = this.injector.get(UsedVerifaliaTasksDocument);
		this.existVerifaliaTasksQuery = this.injector.get(ExistVerifaliaTasksDocument);
		this.insertVerifaliaTasksMutation = this.injector.get(InsertVerifaliaTasksDocument);
		this.updateVerifaliaTasksMutation = this.injector.get(UpdateVerifaliaTasksDocument);
		this.importDataVerifaliaTasksMutation = this.injector.get(ImportDataVerifaliaTasksDocument);
		this.updateManyVerifaliaTasksMutation = this.injector.get(UpdateManyVerifaliaTasksDocument);
		this.deleteVerifaliaTasksMutation = this.injector.get(DeleteVerifaliaTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaTask> {
    
    		let variables: GetVerifaliaTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.get));
            }
            else{
                let result:ServiceSingleResultOfVerifaliaTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaTask,
		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaTask> {
    
    		let variables: FirstVerifaliaTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountVerifaliaTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfVerifaliaTask,
		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaTask> {
    
    		let variables: FindVerifaliaTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfVerifaliaTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaTask> {
    
    		let variables: SearchVerifaliaTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaVerifaliaTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataVerifaliaTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfVerifaliaTask,
		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaTask> {
    
    		let variables: CustomQueryVerifaliaTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfVerifaliaTask,
		@Args('filter?') filter?: FilterOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfVerifaliaTask> {
    
    		let variables: CustomQueryIdVerifaliaTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedVerifaliaTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistVerifaliaTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existVerifaliaTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: VerifaliaTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaTask> {
    
    		let variables: InsertVerifaliaTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertVerifaliaTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfVerifaliaTask> {
    
    		let variables: UpdateVerifaliaTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateVerifaliaTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataVerifaliaTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataVerifaliaTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfVerifaliaTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyVerifaliaTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyVerifaliaTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteVerifaliaTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteVerifaliaTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.verifaliaTasks.delete));
    	}
    
}