import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPortConnectorsBaseVariables, FirstPortConnectorsBaseVariables, CountPortConnectorsBaseVariables, FindPortConnectorsBaseVariables, SearchPortConnectorsBaseVariables, ExportSchemaPortConnectorsBaseVariables, ExportDataPortConnectorsBaseVariables, CustomQueryPortConnectorsBaseVariables, CustomQueryIdPortConnectorsBaseVariables, UsedPortConnectorsBaseVariables, ExistPortConnectorsBaseVariables } from '../gqls'
import { GetPortConnectorsDocument, FirstPortConnectorsDocument, CountPortConnectorsDocument, FindPortConnectorsDocument, SearchPortConnectorsDocument, ExportSchemaPortConnectorsDocument, ExportDataPortConnectorsDocument, CustomQueryPortConnectorsDocument, CustomQueryIdPortConnectorsDocument, UsedPortConnectorsDocument, ExistPortConnectorsDocument } from '../gqls'
import { ServiceSingleResultOfPortConnector, QueryContextOfPortConnector, FilterOfPortConnector, ServiceSingleResultOfInt64, ServiceListResultOfPortConnector, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PortConnectorBaseService {
    
public modelName = 'portConnector';
public modelPluralName = 'portConnectors';

	private getPortConnectorsQuery: GetPortConnectorsDocument;
	private firstPortConnectorsQuery: FirstPortConnectorsDocument;
	private countPortConnectorsQuery: CountPortConnectorsDocument;
	private findPortConnectorsQuery: FindPortConnectorsDocument;
	private searchPortConnectorsQuery: SearchPortConnectorsDocument;
	private exportSchemaPortConnectorsQuery: ExportSchemaPortConnectorsDocument;
	private exportDataPortConnectorsQuery: ExportDataPortConnectorsDocument;
	private customQueryPortConnectorsQuery: CustomQueryPortConnectorsDocument;
	private customQueryIdPortConnectorsQuery: CustomQueryIdPortConnectorsDocument;
	private usedPortConnectorsQuery: UsedPortConnectorsDocument;
	private existPortConnectorsQuery: ExistPortConnectorsDocument;

	constructor(private injector: Injector) {
		this.getPortConnectorsQuery = this.injector.get(GetPortConnectorsDocument);
		this.firstPortConnectorsQuery = this.injector.get(FirstPortConnectorsDocument);
		this.countPortConnectorsQuery = this.injector.get(CountPortConnectorsDocument);
		this.findPortConnectorsQuery = this.injector.get(FindPortConnectorsDocument);
		this.searchPortConnectorsQuery = this.injector.get(SearchPortConnectorsDocument);
		this.exportSchemaPortConnectorsQuery = this.injector.get(ExportSchemaPortConnectorsDocument);
		this.exportDataPortConnectorsQuery = this.injector.get(ExportDataPortConnectorsDocument);
		this.customQueryPortConnectorsQuery = this.injector.get(CustomQueryPortConnectorsDocument);
		this.customQueryIdPortConnectorsQuery = this.injector.get(CustomQueryIdPortConnectorsDocument);
		this.usedPortConnectorsQuery = this.injector.get(UsedPortConnectorsDocument);
		this.existPortConnectorsQuery = this.injector.get(ExistPortConnectorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortConnector> {
    
    		let variables: GetPortConnectorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.get));
            }
            else{
                let result:ServiceSingleResultOfPortConnector={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortConnector,
		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPortConnector> {
    
    		let variables: FirstPortConnectorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPortConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.countPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPortConnector,
		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortConnector> {
    
    		let variables: FindPortConnectorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPortConnector,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortConnector> {
    
    		let variables: SearchPortConnectorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPortConnectorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPortConnectorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPortConnector,
		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortConnector> {
    
    		let variables: CustomQueryPortConnectorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPortConnector,
		@Args('filter?') filter?: FilterOfPortConnector,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPortConnector> {
    
    		let variables: CustomQueryIdPortConnectorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPortConnectorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPortConnectorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPortConnectorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.portConnectors.exist));
    	}
    
    
}