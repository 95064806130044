import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetDiskDrivesBaseVariables, FirstDiskDrivesBaseVariables, CountDiskDrivesBaseVariables, FindDiskDrivesBaseVariables, SearchDiskDrivesBaseVariables, ExportSchemaDiskDrivesBaseVariables, ExportDataDiskDrivesBaseVariables, CustomQueryDiskDrivesBaseVariables, CustomQueryIdDiskDrivesBaseVariables, UsedDiskDrivesBaseVariables, ExistDiskDrivesBaseVariables } from '../gqls'
import { GetDiskDrivesDocument, FirstDiskDrivesDocument, CountDiskDrivesDocument, FindDiskDrivesDocument, SearchDiskDrivesDocument, ExportSchemaDiskDrivesDocument, ExportDataDiskDrivesDocument, CustomQueryDiskDrivesDocument, CustomQueryIdDiskDrivesDocument, UsedDiskDrivesDocument, ExistDiskDrivesDocument } from '../gqls'
import { ServiceSingleResultOfDiskDrive, QueryContextOfDiskDrive, FilterOfDiskDrive, ServiceSingleResultOfInt64, ServiceListResultOfDiskDrive, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class DiskDriveBaseService {
    
public modelName = 'diskDrive';
public modelPluralName = 'diskDrives';

	private getDiskDrivesQuery: GetDiskDrivesDocument;
	private firstDiskDrivesQuery: FirstDiskDrivesDocument;
	private countDiskDrivesQuery: CountDiskDrivesDocument;
	private findDiskDrivesQuery: FindDiskDrivesDocument;
	private searchDiskDrivesQuery: SearchDiskDrivesDocument;
	private exportSchemaDiskDrivesQuery: ExportSchemaDiskDrivesDocument;
	private exportDataDiskDrivesQuery: ExportDataDiskDrivesDocument;
	private customQueryDiskDrivesQuery: CustomQueryDiskDrivesDocument;
	private customQueryIdDiskDrivesQuery: CustomQueryIdDiskDrivesDocument;
	private usedDiskDrivesQuery: UsedDiskDrivesDocument;
	private existDiskDrivesQuery: ExistDiskDrivesDocument;

	constructor(private injector: Injector) {
		this.getDiskDrivesQuery = this.injector.get(GetDiskDrivesDocument);
		this.firstDiskDrivesQuery = this.injector.get(FirstDiskDrivesDocument);
		this.countDiskDrivesQuery = this.injector.get(CountDiskDrivesDocument);
		this.findDiskDrivesQuery = this.injector.get(FindDiskDrivesDocument);
		this.searchDiskDrivesQuery = this.injector.get(SearchDiskDrivesDocument);
		this.exportSchemaDiskDrivesQuery = this.injector.get(ExportSchemaDiskDrivesDocument);
		this.exportDataDiskDrivesQuery = this.injector.get(ExportDataDiskDrivesDocument);
		this.customQueryDiskDrivesQuery = this.injector.get(CustomQueryDiskDrivesDocument);
		this.customQueryIdDiskDrivesQuery = this.injector.get(CustomQueryIdDiskDrivesDocument);
		this.usedDiskDrivesQuery = this.injector.get(UsedDiskDrivesDocument);
		this.existDiskDrivesQuery = this.injector.get(ExistDiskDrivesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDiskDrive> {
    
    		let variables: GetDiskDrivesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.get));
            }
            else{
                let result:ServiceSingleResultOfDiskDrive={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDiskDrive,
		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfDiskDrive> {
    
    		let variables: FirstDiskDrivesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountDiskDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.countDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfDiskDrive,
		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskDrive> {
    
    		let variables: FindDiskDrivesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfDiskDrive,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskDrive> {
    
    		let variables: SearchDiskDrivesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaDiskDrivesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataDiskDrivesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfDiskDrive,
		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskDrive> {
    
    		let variables: CustomQueryDiskDrivesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfDiskDrive,
		@Args('filter?') filter?: FilterOfDiskDrive,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDiskDrive> {
    
    		let variables: CustomQueryIdDiskDrivesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedDiskDrivesBaseVariables = {
    			ids: ids
    		}
    				return this.usedDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistDiskDrivesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existDiskDrivesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.diskDrives.exist));
    	}
    
    
}