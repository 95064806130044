import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { SoftwareReferentLinkBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Maybe } from '@clarilog/core/services/graphql/graphql-types';
import { GqlField, GqlSubField } from '../helpers';
import {
  ServiceSingleResultOfBoolean,
  Software,
  SoftwareReferentLink,
  SoftwareReferentLinkInput,
  User,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({ providedIn: 'root' })
export class SoftwareReferentLinkCoreService extends SoftwareReferentLinkBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public savingReferentComponent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('items?') items?: Array<Maybe<SoftwareReferentLinkInput>>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    // Mise en forme
    items = items.map((d) => {
      return {
        softwareId: d.softwareId == undefined ? id : d.softwareId,
        userId: d.userId,
        softwareReferentTypeId: d.softwareReferentTypeId,
      };
    });

    return this.saveReferentComponent(fields, id, items, extendedVariables);
  }

  @InjectArgs
  public savingSoftwareReferentComponent(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('items?') items?: Array<Maybe<SoftwareReferentLinkInput>>,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceSingleResultOfBoolean> {
    // Mise en forme
    items = items.map((d) => {
      return {
        softwareId: d.softwareId,
        userId: d.userId == undefined ? id : d.userId,
        softwareReferentTypeId: d.softwareReferentTypeId,
      };
    });

    return this.saveSoftwareReferentComponent(
      fields,
      id,
      items,
      extendedVariables,
    );
  }

  /** Mise en forme des données de liason entre porgramme un utilisateur*/
  @InjectArgs
  selectMethodAdd(
    @Args('items') items: User[],
    @Args('id') id: string,
  ): SoftwareReferentLink[] {
    let result = items.map((f) => {
      let item: SoftwareReferentLink = {
        userId: f.id,
        user: {
          data: f,
        },
        softwareId: id,
      };

      return item;
    });

    return result;
  }

  /** Mise en forme des données de liason entre utilisateur un porgramme */
  @InjectArgs
  selectSoftwareMethodAdd(
    @Args('items') items: Software[],
    @Args('id') id: string,
  ): SoftwareReferentLink[] {
    let result = items.map((f) => {
      let item: SoftwareReferentLink = {
        userId: id,
        software: {
          data: f,
        },
        softwareId: f.id,
      };

      return item;
    });

    return result;
  }
}
