
import { WorkflowHttpRequestBaseService } from '../service-bases'
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core';

@Injectable({providedIn: 'root'})
@Authorize('help-desk-operator.manage-workflow')
export class WorkflowHttpRequestCoreService extends WorkflowHttpRequestBaseService {
    constructor(injector: Injector) {
        super(injector)
    }
}
