import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ArticleKnowledgeCategoryBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';

@Injectable({ providedIn: 'root' })
@Authorize('knowledge.manage-category')
export class ArticleKnowledgeCategoryCoreService extends ArticleKnowledgeCategoryBaseService {
  constructor(injector: Injector) {
    super(injector);
  }
}
