import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSecurityGroupRulesBaseVariables, FirstSecurityGroupRulesBaseVariables, CountSecurityGroupRulesBaseVariables, FindSecurityGroupRulesBaseVariables, SearchSecurityGroupRulesBaseVariables, ExportSchemaSecurityGroupRulesBaseVariables, ExportDataSecurityGroupRulesBaseVariables, CustomQuerySecurityGroupRulesBaseVariables, CustomQueryIdSecurityGroupRulesBaseVariables, UsedSecurityGroupRulesBaseVariables, FindNoSecurityGroupsManagerRulesSecurityGroupRulesBaseVariables, ExistSecurityGroupRulesBaseVariables, FindUnassociatedSecurityGroupsSecurityGroupRulesBaseVariables, InsertSecurityGroupRulesBaseVariables, UpdateSecurityGroupRulesBaseVariables, ImportDataSecurityGroupRulesBaseVariables, UpdateManySecurityGroupRulesBaseVariables, DeleteSecurityGroupRulesBaseVariables, AddSecurityGroupsSecurityGroupRulesBaseVariables, RemoveSecurityGroupsSecurityGroupRulesBaseVariables } from '../gqls'
import { GetSecurityGroupRulesDocument, FirstSecurityGroupRulesDocument, CountSecurityGroupRulesDocument, FindSecurityGroupRulesDocument, SearchSecurityGroupRulesDocument, ExportSchemaSecurityGroupRulesDocument, ExportDataSecurityGroupRulesDocument, CustomQuerySecurityGroupRulesDocument, CustomQueryIdSecurityGroupRulesDocument, UsedSecurityGroupRulesDocument, FindNoSecurityGroupsManagerRulesSecurityGroupRulesDocument, ExistSecurityGroupRulesDocument, FindUnassociatedSecurityGroupsSecurityGroupRulesDocument, InsertSecurityGroupRulesDocument, UpdateSecurityGroupRulesDocument, ImportDataSecurityGroupRulesDocument, UpdateManySecurityGroupRulesDocument, DeleteSecurityGroupRulesDocument, AddSecurityGroupsSecurityGroupRulesDocument, RemoveSecurityGroupsSecurityGroupRulesDocument } from '../gqls'
import { ServiceSingleResultOfSecurityGroupRule, QueryContextOfSecurityGroupRule, FilterOfSecurityGroupRule, ServiceSingleResultOfInt64, ServiceListResultOfSecurityGroupRule, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfSecurityGroup, QueryContextOfSecurityGroup, FilterOfSecurityGroup, SecurityGroupRuleInput, FieldUpdateOperatorOfSecurityGroupRule } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SecurityGroupRuleBaseService {
    
public modelName = 'securityGroupRule';
public modelPluralName = 'securityGroupRules';

	private getSecurityGroupRulesQuery: GetSecurityGroupRulesDocument;
	private firstSecurityGroupRulesQuery: FirstSecurityGroupRulesDocument;
	private countSecurityGroupRulesQuery: CountSecurityGroupRulesDocument;
	private findSecurityGroupRulesQuery: FindSecurityGroupRulesDocument;
	private searchSecurityGroupRulesQuery: SearchSecurityGroupRulesDocument;
	private exportSchemaSecurityGroupRulesQuery: ExportSchemaSecurityGroupRulesDocument;
	private exportDataSecurityGroupRulesQuery: ExportDataSecurityGroupRulesDocument;
	private customQuerySecurityGroupRulesQuery: CustomQuerySecurityGroupRulesDocument;
	private customQueryIdSecurityGroupRulesQuery: CustomQueryIdSecurityGroupRulesDocument;
	private usedSecurityGroupRulesQuery: UsedSecurityGroupRulesDocument;
	private findNoSecurityGroupsManagerRulesSecurityGroupRulesQuery: FindNoSecurityGroupsManagerRulesSecurityGroupRulesDocument;
	private existSecurityGroupRulesQuery: ExistSecurityGroupRulesDocument;
	private findUnassociatedSecurityGroupsSecurityGroupRulesQuery: FindUnassociatedSecurityGroupsSecurityGroupRulesDocument;
	private insertSecurityGroupRulesMutation: InsertSecurityGroupRulesDocument;
	private updateSecurityGroupRulesMutation: UpdateSecurityGroupRulesDocument;
	private importDataSecurityGroupRulesMutation: ImportDataSecurityGroupRulesDocument;
	private updateManySecurityGroupRulesMutation: UpdateManySecurityGroupRulesDocument;
	private deleteSecurityGroupRulesMutation: DeleteSecurityGroupRulesDocument;
	private addSecurityGroupsSecurityGroupRulesMutation: AddSecurityGroupsSecurityGroupRulesDocument;
	private removeSecurityGroupsSecurityGroupRulesMutation: RemoveSecurityGroupsSecurityGroupRulesDocument;

	constructor(private injector: Injector) {
		this.getSecurityGroupRulesQuery = this.injector.get(GetSecurityGroupRulesDocument);
		this.firstSecurityGroupRulesQuery = this.injector.get(FirstSecurityGroupRulesDocument);
		this.countSecurityGroupRulesQuery = this.injector.get(CountSecurityGroupRulesDocument);
		this.findSecurityGroupRulesQuery = this.injector.get(FindSecurityGroupRulesDocument);
		this.searchSecurityGroupRulesQuery = this.injector.get(SearchSecurityGroupRulesDocument);
		this.exportSchemaSecurityGroupRulesQuery = this.injector.get(ExportSchemaSecurityGroupRulesDocument);
		this.exportDataSecurityGroupRulesQuery = this.injector.get(ExportDataSecurityGroupRulesDocument);
		this.customQuerySecurityGroupRulesQuery = this.injector.get(CustomQuerySecurityGroupRulesDocument);
		this.customQueryIdSecurityGroupRulesQuery = this.injector.get(CustomQueryIdSecurityGroupRulesDocument);
		this.usedSecurityGroupRulesQuery = this.injector.get(UsedSecurityGroupRulesDocument);
		this.findNoSecurityGroupsManagerRulesSecurityGroupRulesQuery = this.injector.get(FindNoSecurityGroupsManagerRulesSecurityGroupRulesDocument);
		this.existSecurityGroupRulesQuery = this.injector.get(ExistSecurityGroupRulesDocument);
		this.findUnassociatedSecurityGroupsSecurityGroupRulesQuery = this.injector.get(FindUnassociatedSecurityGroupsSecurityGroupRulesDocument);
		this.insertSecurityGroupRulesMutation = this.injector.get(InsertSecurityGroupRulesDocument);
		this.updateSecurityGroupRulesMutation = this.injector.get(UpdateSecurityGroupRulesDocument);
		this.importDataSecurityGroupRulesMutation = this.injector.get(ImportDataSecurityGroupRulesDocument);
		this.updateManySecurityGroupRulesMutation = this.injector.get(UpdateManySecurityGroupRulesDocument);
		this.deleteSecurityGroupRulesMutation = this.injector.get(DeleteSecurityGroupRulesDocument);
		this.addSecurityGroupsSecurityGroupRulesMutation = this.injector.get(AddSecurityGroupsSecurityGroupRulesDocument);
		this.removeSecurityGroupsSecurityGroupRulesMutation = this.injector.get(RemoveSecurityGroupsSecurityGroupRulesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSecurityGroupRule> {
    
    		let variables: GetSecurityGroupRulesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.get));
            }
            else{
                let result:ServiceSingleResultOfSecurityGroupRule={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroupRule,
		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSecurityGroupRule> {
    
    		let variables: FirstSecurityGroupRulesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSecurityGroupRulesBaseVariables = {
    			filter: filter
    		}
    				return this.countSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroupRule,
		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroupRule> {
    
    		let variables: FindSecurityGroupRulesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSecurityGroupRule,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroupRule> {
    
    		let variables: SearchSecurityGroupRulesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSecurityGroupRulesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSecurityGroupRulesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSecurityGroupRule,
		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroupRule> {
    
    		let variables: CustomQuerySecurityGroupRulesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSecurityGroupRule,
		@Args('filter?') filter?: FilterOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroupRule> {
    
    		let variables: CustomQueryIdSecurityGroupRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSecurityGroupRulesBaseVariables = {
    			ids: ids
    		}
    				return this.usedSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.used));
    	}

    	/** Permet de recuperer les profils non associés à la règle d'association. */
    	@InjectArgs
    	public findNoSecurityGroupsManagerRules(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindNoSecurityGroupsManagerRulesSecurityGroupRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findNoSecurityGroupsManagerRulesSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.findNoSecurityGroupsManagerRules));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSecurityGroupRulesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.exist));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsSecurityGroupRulesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsSecurityGroupRulesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.findUnassociatedSecurityGroups));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SecurityGroupRuleInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSecurityGroupRule> {
    
    		let variables: InsertSecurityGroupRulesBaseVariables = {
    			entity: entity
    		}
    				return this.insertSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSecurityGroupRule> {
    
    		let variables: UpdateSecurityGroupRulesBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSecurityGroupRulesBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSecurityGroupRule,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySecurityGroupRulesBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSecurityGroupRulesBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupsSecurityGroupRulesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.addSecurityGroupsSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.addSecurityGroups));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupsSecurityGroupRulesBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupsSecurityGroupRulesMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.securityGroupRules.removeSecurityGroups));
    	}
    
}