import { Component, forwardRef, Input, OnInit } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { MatrixPriorityConfigType } from '@clarilog/core';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ImpactCoreService,
  MatrixPriorityCoreService,
  PriorityCoreService,
  UrgencyCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';

import { ModelState } from '@clarilog/shared2/services/compiler/model-state';
import { Subscription } from 'rxjs';
import { FormGroupHelpers } from '../form';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';

/** Représente la classe du composent cl-list. */
@Component({
  selector: 'clc-matrix',
  templateUrl: './matrix.component.html',
  styleUrls: ['./matrix.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreMatrixComponent),
      multi: true,
    },
  ],
})
export class CoreMatrixComponent implements ControlValueAccessor, OnInit {
  @Input() modelState: ModelState;
  /** Sauvegarde les valeurs. */
  _values: any[] = [];
  /** Sauvegarde les valeurs d'origine. */
  _originalValues: any[] = [];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Obtient ou définit l'état d'activation. */
  @Input() disabled: boolean = false;

  name: string;

  /** Obtient ou définit la source de données. */
  source: any[];
  /** Récupère le composant en cours. */
  component;

  indexUrgencies = [];

  _initializeValue: boolean = false;
  _initializeComponent: boolean = false;

  formChangeSubscriber: Subscription;
  selectedLanguage = 'en';
  qualification: string[];
  impacts: any;
  urgencies: any;
  priorities: any;
  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    if (this._values != undefined) {
      this._values.forEach((f) => delete f['__KEY__']);
      this._values.forEach((f) => delete f['__typename']);
    } else {
      this._values = [];
    }

    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this._initializeValue === false) {
      this.onChange(this._values);
      this.onTouched();
    }
  }
  constructor(
    private impactService: ImpactCoreService,
    private urgencyService: UrgencyCoreService,
    private priorityService: PriorityCoreService,
    private matrixPriorityService: MatrixPriorityCoreService,
    public route: ActivatedRoute,
    private translateHelperService: TranslatedFieldHelperService,
  ) {
    this._initializeComponent == false;
    this.name = this.translateHelperService.setColumnTranslateField('name');
    this.selectedLanguage = translateHelperService.getTranslateKey();
  }
  /** @inheritdoc */
  writeValue(values: any[]): void {
    this._initializeValue = true;
    this.values = values;
    this._originalValues = JSON.parse(JSON.stringify(this.values));
    this._initializeValue = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  /** @inheritdoc */
  setDisabledState?(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  /** @inheritdoc */
  async ngOnInit() {
    this.formChangeSubscriber = this.modelState.form.onLoadedChanged.subscribe(
      (res) => {
        if (res == true && this._initializeComponent == true) {
          let configMatrix = FormGroupHelpers.formControlByName(
            this.modelState.form,
            'config',
          );
          if (configMatrix != undefined || configMatrix != null) {
            configMatrix.reset(this._originalValues);
          }
        }
      },
    );

    this.modelState.on.subscribe((event) => {
      if (
        event.eventName === TranslatedFieldHelperService.ModelStateLanguageKey
      ) {
        this.selectedLanguage = event.eventData;
        this.changeLanguage();
      }
    });
  }

  async initialized(e) {
    this.component = e.component;
    let id = this.route.snapshot.paramMap.get('id');
    this.matrixPriorityService
      .get([GqlSubField.create('data', [GqlField.create('qualification')])], id)
      .subscribe(async (result) => {
        this.qualification = result.data.qualification;
        if (this._values == undefined) {
          this._values = [];
        }
        this.translateHelperService.translatedFields();
        let fields = [
          GqlSubField.create('data', [
            GqlField.create('id'),
            GqlSubField.create(
              'name',
              this.translateHelperService.translatedFields(),
            ),
          ]),
        ];
        this.impacts = await this.impactService
          .findByQualification(fields, this.qualification, null)
          .toPromise();
        this.urgencies = await this.urgencyService
          .findByQualification(fields, this.qualification, null)
          .toPromise();
        this.priorities = await this.priorityService
          .findByQualification(fields, this.qualification, null)
          .toPromise();
        this.indexUrgencies = [];
        let index = 1;
        this.urgencies.data.forEach((u) => {
          var nameIndex = 'urgencyId' + index;
          this.indexUrgencies.push({ nameIndex: nameIndex, urgencyId: u.id });
          this.component.addColumn({
            dataField: nameIndex,
            caption: u.name[this.translateHelperService.getTranslateKey()],
            allowSorting: false,
            lookup: {
              dataSource: this.priorities.data,
              valueExpr: 'id',
              displayExpr:
                this.translateHelperService.setColumnTranslateField('name'),
            },
          });
          index++;
        });

        let data = [];
        this.impacts.data.forEach((i) => {
          var o = { name: i.name, impactId: i.id };
          this.indexUrgencies.forEach((u) => {
            var matrixData = this._values.find(
              (v) => v.impactId == i.id && v.urgencyId == u.urgencyId,
            );
            if (matrixData != undefined) {
              o[u.nameIndex] = matrixData.priorityId;
            }
          });
          data.push(o);
        });
        this.source = data;
        this._initializeComponent = true;
      });
  }

  customRequired(e) {
    return e.value != null;
  }

  public onRowUpdating(e) {
    let c = {} as MatrixPriorityConfigType;
    c.impactId = e.key.impactId;
    this.indexUrgencies.forEach((u) => {
      if (e.newData[u.nameIndex] != undefined) {
        c.urgencyId = u.urgencyId;
        c.priorityId = e.newData[u.nameIndex];
      }
    });

    var matrixData = this._values.find(
      (d) => d.impactId == c.impactId && d.urgencyId == c.urgencyId,
    );
    if (matrixData != undefined) {
      matrixData.priorityId = c.priorityId;
    } else {
      this._values.push(c);
    }

    if (
      this._values.length >=
      this.indexUrgencies.length * this.source.length
    ) {
      this.onChange(this._values);
      this.onTouched();
    }
  }

  async changeLanguage() {
    let rows = this.component.getVisibleRows();
    rows.forEach(function (r) {
      let impactId = r.cells[0].data.impactId;
      r.cells[0].values[0] = this.impacts.data.find(
        (x) => x.id == impactId,
      ).name[this.selectedLanguage];
    }, this);
    let columns = this.component.getVisibleColumns();
    columns.forEach(function (c) {
      let urgency = this.indexUrgencies.find((x) => x.nameIndex == c.dataField);
      if (urgency != undefined) {
        c.caption = this.urgencies.data.find(
          (x) => x.id == urgency.urgencyId,
        ).name[this.selectedLanguage];
        c.lookup.displayExpr = 'name.' + this.selectedLanguage;
        c.lookup.updateValueMap();
      }
    }, this);
    this.component.repaint();
  }

  ngOnDestroy() {
    if (this.formChangeSubscriber != undefined) {
      this.formChangeSubscriber.unsubscribe();
    }
  }
}
