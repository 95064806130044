import { Component, HostBinding, Input, OnInit } from '@angular/core';

@Component({
  selector: 'cl-work-page-right',
  templateUrl: './work-page-right.component.html',
  styleUrls: ['./work-page-right.component.scss']
})
export class WorkPageRightComponent implements OnInit {

  constructor() { }
  @Input()
  @HostBinding('class.hide')
  hide: boolean = false;

  ngOnInit() {
  }

}
