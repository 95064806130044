<div class="grid-list-component">
  <div class="list-component">
    <dx-tree-list
      *ngIf="options.type === 'Tree'"
      class="tree"
      [dataSource]="source"
      [columnAutoWidth]="true"
      [keyExpr]="options.keyExpr"
      [parentIdExpr]="options.parentIdExpr"
      dataStructure="plain"
      (onContentReady)="onContentReady($event)"
      (onInitialized)="initialized($event)"
      [selectedRowKeys]="selectedKeys"
      [rootValue]="null"
      height="100%"
      [hoverStateEnabled]="true"
      (onToolbarPreparing)="onToolbarPreparing($event)"
      [noDataText]="options.noDataText"
      (onCellPrepared)="onCellPrepared($event)"
      (onContextMenuPreparing)="onContextMenuPreparing($event)"
      (onRowUpdating)="onRowUpdating($event)"
      (onRowUpdated)="onRowUpdated($event)"
      (onSaving)="onSaving($event)"
      [remoteOperations]="options.remoteOperations"
      [showRowLines]="false"
      [showBorders]="false"
      [searchPanel]="{
        visible: true,
        width: 200,
        placeholder: 'globals/search' | translate
      }"
      [columnChooser]="{ enabled: true }"
      [paging]="options.paging"
      [pager]="options.pager"
      [filterRow]="{
        visible: true
      }"
      [filterPanel]="{
        visible: true
      }"
      [sorting]="{
        mode: 'multiple'
      }"
    >
      <dxo-editing mode="cell" [allowUpdating]="true"></dxo-editing>
      <dxo-selection
        [mode]="
          readOnly === true ? 'none' : options.multiple ? 'multiple' : 'single'
        "
        [recursive]="options.recursive"
      ></dxo-selection>
      <dxo-scrolling useNative="true" mode="standard"> </dxo-scrolling>
      <dxi-column
        *ngFor="let column of options.columns"
        [dataField]="column.field"
        [caption]="column.label"
        [format]="column.format"
        [type]="column.type"
        [width]="column.width"
        [cellTemplate]="column.template"
        [dataType]="column.dataType"
        [visible]="column.visible != undefined ? column.visible : true"
        [showInColumnChooser]="true"
        [sortIndex]="column.sortIndex"
        [sortOrder]="column.sortOrder"
        [allowEditing]="
          column.allowEditing != undefined ? column.allowEditing : false
        "
      ></dxi-column>

      <ng-container
        *ngFor="
          let column of options.columns | filter : 'template' : undefined : true
        "
      >
        <div *dxTemplate="let data of column.template">
          <ng-template
            *ngTemplateOutlet="
              templateService.get(column.template);
              context: {
                $implicit: data,
                column: column
              }
            "
          >
          </ng-template>
        </div>
      </ng-container>
    </dx-tree-list>
  </div>
  <div class="msg-warning-asset-category">
    {{ 'entities/command/msgWarningAssetCategories' | translate }}
  </div>
</div>
