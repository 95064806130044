import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSnmpVendorsBaseVariables, FirstSnmpVendorsBaseVariables, CountSnmpVendorsBaseVariables, FindSnmpVendorsBaseVariables, SearchSnmpVendorsBaseVariables, ExportSchemaSnmpVendorsBaseVariables, ExportDataSnmpVendorsBaseVariables, CustomQuerySnmpVendorsBaseVariables, CustomQueryIdSnmpVendorsBaseVariables, UsedSnmpVendorsBaseVariables, ExistSnmpVendorsBaseVariables, InsertSnmpVendorsBaseVariables, UpdateSnmpVendorsBaseVariables, ImportDataSnmpVendorsBaseVariables, UpdateManySnmpVendorsBaseVariables, DeleteSnmpVendorsBaseVariables } from '../gqls'
import { GetSnmpVendorsDocument, FirstSnmpVendorsDocument, CountSnmpVendorsDocument, FindSnmpVendorsDocument, SearchSnmpVendorsDocument, ExportSchemaSnmpVendorsDocument, ExportDataSnmpVendorsDocument, CustomQuerySnmpVendorsDocument, CustomQueryIdSnmpVendorsDocument, UsedSnmpVendorsDocument, ExistSnmpVendorsDocument, InsertSnmpVendorsDocument, UpdateSnmpVendorsDocument, ImportDataSnmpVendorsDocument, UpdateManySnmpVendorsDocument, DeleteSnmpVendorsDocument } from '../gqls'
import { ServiceSingleResultOfSnmpVendor, QueryContextOfSnmpVendor, FilterOfSnmpVendor, ServiceSingleResultOfInt64, ServiceListResultOfSnmpVendor, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, SnmpVendorInput, FieldUpdateOperatorOfSnmpVendor } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SnmpVendorBaseService {
    
public modelName = 'snmpVendor';
public modelPluralName = 'snmpVendors';

	private getSnmpVendorsQuery: GetSnmpVendorsDocument;
	private firstSnmpVendorsQuery: FirstSnmpVendorsDocument;
	private countSnmpVendorsQuery: CountSnmpVendorsDocument;
	private findSnmpVendorsQuery: FindSnmpVendorsDocument;
	private searchSnmpVendorsQuery: SearchSnmpVendorsDocument;
	private exportSchemaSnmpVendorsQuery: ExportSchemaSnmpVendorsDocument;
	private exportDataSnmpVendorsQuery: ExportDataSnmpVendorsDocument;
	private customQuerySnmpVendorsQuery: CustomQuerySnmpVendorsDocument;
	private customQueryIdSnmpVendorsQuery: CustomQueryIdSnmpVendorsDocument;
	private usedSnmpVendorsQuery: UsedSnmpVendorsDocument;
	private existSnmpVendorsQuery: ExistSnmpVendorsDocument;
	private insertSnmpVendorsMutation: InsertSnmpVendorsDocument;
	private updateSnmpVendorsMutation: UpdateSnmpVendorsDocument;
	private importDataSnmpVendorsMutation: ImportDataSnmpVendorsDocument;
	private updateManySnmpVendorsMutation: UpdateManySnmpVendorsDocument;
	private deleteSnmpVendorsMutation: DeleteSnmpVendorsDocument;

	constructor(private injector: Injector) {
		this.getSnmpVendorsQuery = this.injector.get(GetSnmpVendorsDocument);
		this.firstSnmpVendorsQuery = this.injector.get(FirstSnmpVendorsDocument);
		this.countSnmpVendorsQuery = this.injector.get(CountSnmpVendorsDocument);
		this.findSnmpVendorsQuery = this.injector.get(FindSnmpVendorsDocument);
		this.searchSnmpVendorsQuery = this.injector.get(SearchSnmpVendorsDocument);
		this.exportSchemaSnmpVendorsQuery = this.injector.get(ExportSchemaSnmpVendorsDocument);
		this.exportDataSnmpVendorsQuery = this.injector.get(ExportDataSnmpVendorsDocument);
		this.customQuerySnmpVendorsQuery = this.injector.get(CustomQuerySnmpVendorsDocument);
		this.customQueryIdSnmpVendorsQuery = this.injector.get(CustomQueryIdSnmpVendorsDocument);
		this.usedSnmpVendorsQuery = this.injector.get(UsedSnmpVendorsDocument);
		this.existSnmpVendorsQuery = this.injector.get(ExistSnmpVendorsDocument);
		this.insertSnmpVendorsMutation = this.injector.get(InsertSnmpVendorsDocument);
		this.updateSnmpVendorsMutation = this.injector.get(UpdateSnmpVendorsDocument);
		this.importDataSnmpVendorsMutation = this.injector.get(ImportDataSnmpVendorsDocument);
		this.updateManySnmpVendorsMutation = this.injector.get(UpdateManySnmpVendorsDocument);
		this.deleteSnmpVendorsMutation = this.injector.get(DeleteSnmpVendorsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpVendor> {
    
    		let variables: GetSnmpVendorsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.get));
            }
            else{
                let result:ServiceSingleResultOfSnmpVendor={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpVendor,
		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpVendor> {
    
    		let variables: FirstSnmpVendorsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSnmpVendorsBaseVariables = {
    			filter: filter
    		}
    				return this.countSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSnmpVendor,
		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpVendor> {
    
    		let variables: FindSnmpVendorsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSnmpVendor,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpVendor> {
    
    		let variables: SearchSnmpVendorsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSnmpVendorsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSnmpVendorsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSnmpVendor,
		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpVendor> {
    
    		let variables: CustomQuerySnmpVendorsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSnmpVendor,
		@Args('filter?') filter?: FilterOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSnmpVendor> {
    
    		let variables: CustomQueryIdSnmpVendorsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSnmpVendorsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSnmpVendorsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSnmpVendorsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SnmpVendorInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpVendor> {
    
    		let variables: InsertSnmpVendorsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSnmpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSnmpVendor> {
    
    		let variables: UpdateSnmpVendorsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSnmpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSnmpVendorsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSnmpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSnmpVendor,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySnmpVendorsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySnmpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSnmpVendorsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSnmpVendorsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.snmpVendors.delete));
    	}
    
}