import { Component, Input, OnInit } from '@angular/core';
import {
  ModelDataSourceContext,
  ModelFnContext,
} from '@clarilog/shared2/services/compiler/model-state';
import { Observable } from 'rxjs';
import { TranslatedFieldHelperService } from '../translate-field/translate-field-helper-service';
import {
  GqlField,
  GqlSubField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import {
  IncidentModelCoreService,
  TicketCategoryCoreService,
} from '@clarilog/core/services2/graphql/generated-types/services';
import { Column } from 'devextreme/ui/data_grid';
import { Router, RouterFeature } from '@angular/router';
import { AuthorizationCoreService } from '@clarilog/core/services2/authorization/authorization.service';
import { map } from 'rxjs/operators';
import { EnvironmentService } from '@clarilog/core/services/environment/environment.service';
import path from 'path';
import { optionName } from 'devexpress-dashboard/viewer-parts/index.internal';
import { Sort } from '@clarilog/core/services2/graphql/generated-types/types';
/** Représente le composant de catalog de service. */
@Component({
  selector: 'cl-service-catalog',
  templateUrl: './service-catalog.component.html',
  styleUrls: ['./service-catalog.component.scss'],
})
export class ServiceCatalogComponent implements OnInit {
  /** Obtient ou définit la source des categories. */
  @Input() ticketCategoriesSourcee: ModelDataSourceContext;
  categories: any;
  lang: any;
  ticketModelSource: any;
  ticketCategoriesSource: any;
  ticketModelColumns: Column;
  selectedTicketCategory: any;
  router: Router;
  myTheme: any;
  titleHeader: any;
  breadCrumb: any = [];
  visibleBack: boolean;
  gridTicketModel: any;
  _envService: any;
  constructor(
    translatedService: TranslatedFieldHelperService,
    private incidentModelCoreService: IncidentModelCoreService,
    ticketCategoriesSource: TicketCategoryCoreService,
    authorizationService: AuthorizationCoreService,
    router: Router,
    _envService: EnvironmentService,
  ) {
    this._envService = _envService;
    this.myTheme = authorizationService.user.getCustomization();
    this.router = router;
    this.lang = translatedService.getTranslateKey();
    this.getTicketCategorySource(ticketCategoriesSource);
    this.getTicketModelSource(incidentModelCoreService);
    this.visibleBack = false;
  }

  /** @inheritdoc */
  ngOnInit() {
    this.ticketCategoriesSource.load();
  }

  getTicketCategorySource(ticketCategoriesSource: TicketCategoryCoreService) {
    let fieldCategories = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('name', [GqlField.create(this.lang)]),
        GqlSubField.create('path', [GqlField.create(this.lang)]),
        GqlField.create('fileId'),
        GqlField.create('parentId'),
        GqlField.create('numberOfChildren'),
      ]),
    ];
    this.ticketCategoriesSource = CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        if (this.selectedTicketCategory != undefined) {
          filter = {
            parentId: { eq: this.selectedTicketCategory.id },
          };
        } else {
          filter = { parentId: { eq: null } };
        }

        let sortName = {};
        sortName[this.lang] = Sort.Asc;
        options.sort = [{ name: [sortName] }];

        return ticketCategoriesSource
          .findTicketCategoryByPopulationWithModel(
            fieldCategories,
            options,
            filter,
          )
          .pipe(
            map((result) => {
              let categories = result.data;
              if (this.selectedTicketCategory != undefined) {
                categories = categories.filter(
                  (x) => x.id != this.selectedTicketCategory.id,
                );
              }
              this.categories = categories;
              return result;
            }),
          );
      },
    });
  }

  getTicketModelSource(incidentModelCoreService: IncidentModelCoreService) {
    let fieldTicketModel = [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlSubField.create('modelName', [GqlField.create(this.lang)]),
      ]),
    ];
    this.ticketModelSource = CoreGraphQLSource.create({
      query: (filter?: any, options?: any) => {
        if (this.selectedTicketCategory != undefined) {
          filter = {
            ticketCategory: {
              path: { fr: { startsWith: this.selectedTicketCategory.path.fr } },
            },
          };
        }
        return incidentModelCoreService.find(fieldTicketModel, options, filter);
      },
    });
  }

  getDisplayTicketModel(field) {
    return field;
  }

  selectCategoryItem(e) {
    if (e.numberOfChildren > 0) {
      this.breadCrumb.push(e);
      this.updateTitleHeader();
      this.selectedTicketCategory = e;
      this.refreshDataSource();
    } else {
      this.updateChildrenTitleHeader(e);
      this.selectedTicketCategory = e;
      this.gridTicketModel.refresh();
    }
  }

  selectTicketModelChanged(e) {
    this.incidentModelCoreService
      .get(
        [
          GqlSubField.create('data', [
            GqlField.create('qualification'),
            GqlField.create('id'),
          ]),
        ],
        e.data.id,
      )
      .subscribe((response) => {
        const qualification = response.data.qualification;
        this.router.navigate(
          [
            qualification.includes('request')
              ? '/help-desk-request/my/new'
              : '/help-desk-incident/my/new',
          ],
          {
            queryParams: {
              incidentModelId: e.data.id,
            },
          },
        );
      });
  }

  async backItem() {
    this.breadCrumb.pop();
    this.updateTitleHeader();
    this.selectedTicketCategory = this.breadCrumb.slice()[0];
    this.refreshDataSource();
  }

  refreshDataSource() {
    this.ticketCategoriesSource.load();
    this.gridTicketModel.refresh();
  }

  updateTitleHeader() {
    if (this.breadCrumb != undefined && this.breadCrumb.length > 0) {
      let names = this.breadCrumb.map((x) => x.name[this.lang]);
      this.titleHeader = names.join(' > ');
      this.visibleBack = true;
    } else {
      this.titleHeader = '';
      this.visibleBack = false;
    }
  }

  updateChildrenTitleHeader(item) {
    let path = '';
    if (this.breadCrumb != undefined && this.breadCrumb.length > 0) {
      path = this.breadCrumb.map((x) => x.name[this.lang]).join(' > ') + ' > ';
    }
    this.titleHeader = path + item.name[this.lang];
    this.visibleBack = true;
  }

  initGridTicketModel(e) {
    this.gridTicketModel = e.component;
  }

  getUrlFile(fileId) {
    return `${this._envService.apiURL}/file/get/${fileId}`;
  }
}
