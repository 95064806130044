import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { ProblemBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { ModelFieldCompilerService } from '@clarilog/shared2/services/compiler/model-field-compiler.service';
import { map } from 'rxjs/internal/operators/map';
import {
  FilterOfCommentaryModel,
  QueryContextOfCommentaryModel,
  ServiceListResultOfCommentaryModel,
} from '../types';
import { GqlField, GqlSubField } from '../helpers';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
@Authorize('problem')
export class ProblemCoreService extends ProblemBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Recupere le premier status */
  @InjectArgs
  public findFirstStatus(@Args('id?') id?: string) {
    let res = this.entryStatus(
      ModelFieldCompilerService.createServiceSingleResultScalar(),
      id,
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      return res;
    });
  }

  public getService(): ProblemCoreService {
    return this;
  }

  @InjectArgs
  public findAffectationCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'affectationUsageType',
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findStatusChangeUsageTypeCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'statusChangeUsageType',
      options,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findRemindAsCommentaryModel(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfCommentaryModel,
    @Args('filter?') filter?: FilterOfCommentaryModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfCommentaryModel> {
    return super.findCommentaryModel(
      fields,
      'remindAsUsageType',
      options,
      filter,
      extendedVariables,
    );
  }
}
