import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNamingConventionsBaseVariables, FirstNamingConventionsBaseVariables, CountNamingConventionsBaseVariables, FindNamingConventionsBaseVariables, SearchNamingConventionsBaseVariables, ExportSchemaNamingConventionsBaseVariables, ExportDataNamingConventionsBaseVariables, CustomQueryNamingConventionsBaseVariables, CustomQueryIdNamingConventionsBaseVariables, UsedNamingConventionsBaseVariables, ByAssetNamingConventionsBaseVariables, ByAssetCategoryNamingConventionsBaseVariables, FindAssetCategoriesNamingConventionsBaseVariables, ExistNamingConventionsBaseVariables, InsertNamingConventionsBaseVariables, UpdateNamingConventionsBaseVariables, ImportDataNamingConventionsBaseVariables, UpdateManyNamingConventionsBaseVariables, DeleteNamingConventionsBaseVariables, ExecuteNamingConventionsBaseVariables } from '../gqls'
import { GetNamingConventionsDocument, FirstNamingConventionsDocument, CountNamingConventionsDocument, FindNamingConventionsDocument, SearchNamingConventionsDocument, ExportSchemaNamingConventionsDocument, ExportDataNamingConventionsDocument, CustomQueryNamingConventionsDocument, CustomQueryIdNamingConventionsDocument, UsedNamingConventionsDocument, ByAssetNamingConventionsDocument, ByAssetCategoryNamingConventionsDocument, FindAssetCategoriesNamingConventionsDocument, ExistNamingConventionsDocument, InsertNamingConventionsDocument, UpdateNamingConventionsDocument, ImportDataNamingConventionsDocument, UpdateManyNamingConventionsDocument, DeleteNamingConventionsDocument, ExecuteNamingConventionsDocument } from '../gqls'
import { ServiceSingleResultOfNamingConvention, QueryContextOfNamingConvention, FilterOfNamingConvention, ServiceSingleResultOfInt64, ServiceListResultOfNamingConvention, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfAssetNamingConvention, ServiceListResultOfAssetCategory, NamingConventionCategory, NamingConventionInput, FieldUpdateOperatorOfNamingConvention } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NamingConventionBaseService {
    
public modelName = 'namingConvention';
public modelPluralName = 'namingConventions';

	private getNamingConventionsQuery: GetNamingConventionsDocument;
	private firstNamingConventionsQuery: FirstNamingConventionsDocument;
	private countNamingConventionsQuery: CountNamingConventionsDocument;
	private findNamingConventionsQuery: FindNamingConventionsDocument;
	private searchNamingConventionsQuery: SearchNamingConventionsDocument;
	private exportSchemaNamingConventionsQuery: ExportSchemaNamingConventionsDocument;
	private exportDataNamingConventionsQuery: ExportDataNamingConventionsDocument;
	private customQueryNamingConventionsQuery: CustomQueryNamingConventionsDocument;
	private customQueryIdNamingConventionsQuery: CustomQueryIdNamingConventionsDocument;
	private usedNamingConventionsQuery: UsedNamingConventionsDocument;
	private byAssetNamingConventionsQuery: ByAssetNamingConventionsDocument;
	private byAssetCategoryNamingConventionsQuery: ByAssetCategoryNamingConventionsDocument;
	private findAssetCategoriesNamingConventionsQuery: FindAssetCategoriesNamingConventionsDocument;
	private existNamingConventionsQuery: ExistNamingConventionsDocument;
	private insertNamingConventionsMutation: InsertNamingConventionsDocument;
	private updateNamingConventionsMutation: UpdateNamingConventionsDocument;
	private importDataNamingConventionsMutation: ImportDataNamingConventionsDocument;
	private updateManyNamingConventionsMutation: UpdateManyNamingConventionsDocument;
	private deleteNamingConventionsMutation: DeleteNamingConventionsDocument;
	private executeNamingConventionsMutation: ExecuteNamingConventionsDocument;

	constructor(private injector: Injector) {
		this.getNamingConventionsQuery = this.injector.get(GetNamingConventionsDocument);
		this.firstNamingConventionsQuery = this.injector.get(FirstNamingConventionsDocument);
		this.countNamingConventionsQuery = this.injector.get(CountNamingConventionsDocument);
		this.findNamingConventionsQuery = this.injector.get(FindNamingConventionsDocument);
		this.searchNamingConventionsQuery = this.injector.get(SearchNamingConventionsDocument);
		this.exportSchemaNamingConventionsQuery = this.injector.get(ExportSchemaNamingConventionsDocument);
		this.exportDataNamingConventionsQuery = this.injector.get(ExportDataNamingConventionsDocument);
		this.customQueryNamingConventionsQuery = this.injector.get(CustomQueryNamingConventionsDocument);
		this.customQueryIdNamingConventionsQuery = this.injector.get(CustomQueryIdNamingConventionsDocument);
		this.usedNamingConventionsQuery = this.injector.get(UsedNamingConventionsDocument);
		this.byAssetNamingConventionsQuery = this.injector.get(ByAssetNamingConventionsDocument);
		this.byAssetCategoryNamingConventionsQuery = this.injector.get(ByAssetCategoryNamingConventionsDocument);
		this.findAssetCategoriesNamingConventionsQuery = this.injector.get(FindAssetCategoriesNamingConventionsDocument);
		this.existNamingConventionsQuery = this.injector.get(ExistNamingConventionsDocument);
		this.insertNamingConventionsMutation = this.injector.get(InsertNamingConventionsDocument);
		this.updateNamingConventionsMutation = this.injector.get(UpdateNamingConventionsDocument);
		this.importDataNamingConventionsMutation = this.injector.get(ImportDataNamingConventionsDocument);
		this.updateManyNamingConventionsMutation = this.injector.get(UpdateManyNamingConventionsDocument);
		this.deleteNamingConventionsMutation = this.injector.get(DeleteNamingConventionsDocument);
		this.executeNamingConventionsMutation = this.injector.get(ExecuteNamingConventionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConvention> {
    
    		let variables: GetNamingConventionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.get));
            }
            else{
                let result:ServiceSingleResultOfNamingConvention={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNamingConvention,
		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConvention> {
    
    		let variables: FirstNamingConventionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNamingConventionsBaseVariables = {
    			filter: filter
    		}
    				return this.countNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNamingConvention,
		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConvention> {
    
    		let variables: FindNamingConventionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNamingConvention,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConvention> {
    
    		let variables: SearchNamingConventionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNamingConventionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNamingConventionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNamingConvention,
		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConvention> {
    
    		let variables: CustomQueryNamingConventionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNamingConvention,
		@Args('filter?') filter?: FilterOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConvention> {
    
    		let variables: CustomQueryIdNamingConventionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNamingConventionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.used));
    	}

    	/**  */
    	@InjectArgs
    	public byAsset(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetId') assetId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetNamingConvention> {
    
    		let variables: ByAssetNamingConventionsBaseVariables = {
    			assetId: assetId
    		}
    				return this.byAssetNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.byAsset));
    	}

    	/**  */
    	@InjectArgs
    	public byAssetCategory(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('assetCategoryId') assetCategoryId: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfAssetNamingConvention> {
    
    		let variables: ByAssetCategoryNamingConventionsBaseVariables = {
    			assetCategoryId: assetCategoryId
    		}
    				return this.byAssetCategoryNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.byAssetCategory));
    	}

    	/**  */
    	@InjectArgs
    	public findAssetCategories(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('namingConventionCategory?') namingConventionCategory?: NamingConventionCategory,
		@Args('id?') id?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAssetCategory> {
    
    		let variables: FindAssetCategoriesNamingConventionsBaseVariables = {
    			id: id,
			namingConventionCategory: namingConventionCategory
    		}
    				return this.findAssetCategoriesNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.findAssetCategories));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNamingConventionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNamingConventionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: NamingConventionInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConvention> {
    
    		let variables: InsertNamingConventionsBaseVariables = {
    			entity: entity
    		}
    				return this.insertNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConvention> {
    
    		let variables: UpdateNamingConventionsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataNamingConventionsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfNamingConvention,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyNamingConventionsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteNamingConventionsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.delete));
    	}

    	/**  */
    	@InjectArgs
    	public execute(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('namingConventionIds') namingConventionIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExecuteNamingConventionsBaseVariables = {
    			namingConventionIds: namingConventionIds
    		}
    				return this.executeNamingConventionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventions.execute));
    	}
    
}