import { Injectable, EventEmitter } from '@angular/core';
import {
  Router,
  ActivatedRouteSnapshot,
  NavigationEnd,
  Event,
  Route,
  ActivatedRoute,
  UrlTree,
  RouterEvent,
} from '@angular/router';
import { Location } from '@angular/common';
import { TranslateService } from '@clarilog/shared2/services';

export class Breadcrumb {
  displayName: string;
  terminal: boolean;
  url: string;
  route: Route | null;
  queryParams: any;
}
/** Représente un service pour la gestion du breadcrumb. */
@Injectable({
  providedIn: 'root',
})
export class BreadcrumbService {
  breadcrumbChanged = new EventEmitter<Breadcrumb[]>(false);
  private breadcrumbs = new Array<Breadcrumb>();
  constructor(
    private router: Router,
    private _activateRoute: ActivatedRoute,
    private _location: Location,
  ) {
    this._location.onUrlChange((url: string, state: unknown) => {
      // En cas de replaceState
      if (state == undefined) {
        let urlTree: UrlTree = this.router.parseUrl(url);
        let segments = urlTree.root.children.primary.segments;
        let configs = this.router.config;
        if (segments.length > 0) {
          var breadCrumbIndex = 0;
          var newCrumbs = [];
          let url = '';
          for (let segment of segments) {
            let currentConfig = undefined;
            url += `/${segment.path}`;
            if (configs != undefined) {
              for (let config of configs) {
                if (config.path.indexOf(segment.path) !== -1) {
                  currentConfig = config;
                  configs = config.children;
                  break;
                }
              }
            }
            if (currentConfig == undefined) continue;

            var newCrumb: Breadcrumb = {
              displayName: currentConfig.data['breadcrumb'],
              terminal: currentConfig.children == undefined,
              url: url,
              route: currentConfig,
              queryParams: undefined,
            };
            if (breadCrumbIndex < this.breadcrumbs.length) {
              var existing = this.breadcrumbs[breadCrumbIndex++];
              if (existing && existing.route == currentConfig) {
                newCrumb.displayName = existing.displayName;
                if (newCrumb.queryParams == undefined) {
                  newCrumb.queryParams = existing.queryParams;
                }
              }
            }
            newCrumbs.push(newCrumb);
          }

          this.breadcrumbs = newCrumbs;
          this.breadcrumbChanged.emit(this.breadcrumbs);
        }
      }
    });

    this.router.events.subscribe((routeEvent) => {
      this.onRouteEvent(routeEvent);
    });
  }
  public changeBreadcrumb(route: ActivatedRouteSnapshot, name: string) {
    const rootUrl = this.createRootUrl(route);
    const breadcrumb = this.breadcrumbs.find(function (bc) {
      return bc.url === rootUrl;
    });
    if (!breadcrumb) {
      return;
    }
    breadcrumb.displayName = name;
    this.breadcrumbChanged.emit(this.breadcrumbs);
  }
  private onRouteEvent(routeEvent: Event | RouterEvent) {
    if (!(routeEvent instanceof NavigationEnd)) {
      return;
    }
    let route = this.router.routerState.root.snapshot;
    let url = '';
    var breadCrumbIndex = 0;
    var newCrumbs = [];
    while (route.firstChild != null) {
      route = route.firstChild;
      if (route.routeConfig === null) {
        continue;
      }
      // if (!route.routeConfig.path ) {
      //   continue;
      // }
      url += `/${this.createUrl(route)}`;
      if (!route.data['breadcrumb']) {
        continue;
      }
      var newCrumb = this.createBreadcrumb(route, url);
      if (breadCrumbIndex < this.breadcrumbs.length) {
        var existing = this.breadcrumbs[breadCrumbIndex++];
        if (existing && existing.route == route.routeConfig) {
          newCrumb.displayName = existing.displayName;
        }
      }
      newCrumbs.push(newCrumb);
    }
    this.breadcrumbs = newCrumbs;
    this.breadcrumbChanged.emit(this.breadcrumbs);
  }
  private createBreadcrumb(
    route: ActivatedRouteSnapshot,
    url: string,
  ): Breadcrumb {
    return {
      displayName: route.data['breadcrumb'],
      terminal: this.isTerminal(route),
      url: url,
      route: route.routeConfig,
      // Concerve uniquement si ce n'est pas un noeud root
      queryParams:
        (<any>route)._lastPathIndex != 0 ? route.queryParams : undefined,
    };
  }
  private isTerminal(route: ActivatedRouteSnapshot) {
    return (
      route.firstChild === null ||
      route.firstChild.routeConfig === null ||
      !route.firstChild.routeConfig.path
    );
  }
  private createUrl(route: ActivatedRouteSnapshot) {
    return route.url
      .map(function (s) {
        return s.toString();
      })
      .join('/');
  }
  private createRootUrl(route: ActivatedRouteSnapshot) {
    let url = '';
    let next = route.root;
    while (next.firstChild !== null) {
      next = next.firstChild;
      if (next.routeConfig === null) {
        continue;
      }
      if (!next.routeConfig.path) {
        continue;
      }
      url += `/${this.createUrl(next)}`;
      if (next === route) {
        break;
      }
    }
    return url;
  }
}
