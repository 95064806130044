import { BusinessTimeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfUser,
  QueryContextOfUser,
  ServiceListResultOfUser,
} from '../types';
import { Observable } from 'rxjs/internal/Observable';
import { map } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-business-time')
export class BusinessTimeCoreService extends BusinessTimeBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  @InjectArgs
  public findAssociatedUsersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        if (filter?.and[index]?.memberOfPopulationIds != undefined) {
          memberOfPopulationIds =
            filter.and[index].memberOfPopulationIds.elemMatch['in'];
          delete filter.and[index].memberOfPopulationIds;
        }
      } else {
        if (filter?.memberOfPopulationIds != undefined) {
          memberOfPopulationIds = filter.memberOfPopulationIds.elemMatch['in'];
          delete filter.memberOfPopulationIds;
        }
      }
    }
    return this.findUsers(
      fields,
      memberOfPopulationIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findNotUsersCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('memberOfPopulationIds') memberOfPopulationIds: Array<string>,
    @Args('options?') options?: QueryContextOfUser,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfUser,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfUser> {
    if (filter != null) {
      if (filter.and) {
        let index = filter.and.findIndex((x) => x.memberOfPopulationIds);
        memberOfPopulationIds =
          filter.and[index].memberOfPopulationIds.elemMatch['in'];
        delete filter.and[index].memberOfPopulationIds;
      } else {
        memberOfPopulationIds = filter.memberOfPopulationIds?.elemMatch['in'];
        delete filter.memberOfPopulationIds;
      }
    }
    return this.findNotUsers(
      fields,
      memberOfPopulationIds,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  public getDefault() {
    let res = this.find(
      [GqlSubField.create('data', [GqlField.create('id')])],
      null,
      { isDefault: { eq: true } },
    )
      .pipe(map((res) => res.data))
      .toPromise();

    return res.then((res) => {
      if (res.length > 0) {
        return res[0].id;
      } else null;
    });
  }
}
