import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetPhysicalMemoryArraysBaseVariables, FirstPhysicalMemoryArraysBaseVariables, CountPhysicalMemoryArraysBaseVariables, FindPhysicalMemoryArraysBaseVariables, SearchPhysicalMemoryArraysBaseVariables, ExportSchemaPhysicalMemoryArraysBaseVariables, ExportDataPhysicalMemoryArraysBaseVariables, CustomQueryPhysicalMemoryArraysBaseVariables, CustomQueryIdPhysicalMemoryArraysBaseVariables, UsedPhysicalMemoryArraysBaseVariables, ExistPhysicalMemoryArraysBaseVariables } from '../gqls'
import { GetPhysicalMemoryArraysDocument, FirstPhysicalMemoryArraysDocument, CountPhysicalMemoryArraysDocument, FindPhysicalMemoryArraysDocument, SearchPhysicalMemoryArraysDocument, ExportSchemaPhysicalMemoryArraysDocument, ExportDataPhysicalMemoryArraysDocument, CustomQueryPhysicalMemoryArraysDocument, CustomQueryIdPhysicalMemoryArraysDocument, UsedPhysicalMemoryArraysDocument, ExistPhysicalMemoryArraysDocument } from '../gqls'
import { ServiceSingleResultOfPhysicalMemoryArray, QueryContextOfPhysicalMemoryArray, FilterOfPhysicalMemoryArray, ServiceSingleResultOfInt64, ServiceListResultOfPhysicalMemoryArray, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class PhysicalMemoryArrayBaseService {
    
public modelName = 'physicalMemoryArray';
public modelPluralName = 'physicalMemoryArrays';

	private getPhysicalMemoryArraysQuery: GetPhysicalMemoryArraysDocument;
	private firstPhysicalMemoryArraysQuery: FirstPhysicalMemoryArraysDocument;
	private countPhysicalMemoryArraysQuery: CountPhysicalMemoryArraysDocument;
	private findPhysicalMemoryArraysQuery: FindPhysicalMemoryArraysDocument;
	private searchPhysicalMemoryArraysQuery: SearchPhysicalMemoryArraysDocument;
	private exportSchemaPhysicalMemoryArraysQuery: ExportSchemaPhysicalMemoryArraysDocument;
	private exportDataPhysicalMemoryArraysQuery: ExportDataPhysicalMemoryArraysDocument;
	private customQueryPhysicalMemoryArraysQuery: CustomQueryPhysicalMemoryArraysDocument;
	private customQueryIdPhysicalMemoryArraysQuery: CustomQueryIdPhysicalMemoryArraysDocument;
	private usedPhysicalMemoryArraysQuery: UsedPhysicalMemoryArraysDocument;
	private existPhysicalMemoryArraysQuery: ExistPhysicalMemoryArraysDocument;

	constructor(private injector: Injector) {
		this.getPhysicalMemoryArraysQuery = this.injector.get(GetPhysicalMemoryArraysDocument);
		this.firstPhysicalMemoryArraysQuery = this.injector.get(FirstPhysicalMemoryArraysDocument);
		this.countPhysicalMemoryArraysQuery = this.injector.get(CountPhysicalMemoryArraysDocument);
		this.findPhysicalMemoryArraysQuery = this.injector.get(FindPhysicalMemoryArraysDocument);
		this.searchPhysicalMemoryArraysQuery = this.injector.get(SearchPhysicalMemoryArraysDocument);
		this.exportSchemaPhysicalMemoryArraysQuery = this.injector.get(ExportSchemaPhysicalMemoryArraysDocument);
		this.exportDataPhysicalMemoryArraysQuery = this.injector.get(ExportDataPhysicalMemoryArraysDocument);
		this.customQueryPhysicalMemoryArraysQuery = this.injector.get(CustomQueryPhysicalMemoryArraysDocument);
		this.customQueryIdPhysicalMemoryArraysQuery = this.injector.get(CustomQueryIdPhysicalMemoryArraysDocument);
		this.usedPhysicalMemoryArraysQuery = this.injector.get(UsedPhysicalMemoryArraysDocument);
		this.existPhysicalMemoryArraysQuery = this.injector.get(ExistPhysicalMemoryArraysDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPhysicalMemoryArray> {
    
    		let variables: GetPhysicalMemoryArraysBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.get));
            }
            else{
                let result:ServiceSingleResultOfPhysicalMemoryArray={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPhysicalMemoryArray,
		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfPhysicalMemoryArray> {
    
    		let variables: FirstPhysicalMemoryArraysBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountPhysicalMemoryArraysBaseVariables = {
    			filter: filter
    		}
    				return this.countPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfPhysicalMemoryArray,
		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemoryArray> {
    
    		let variables: FindPhysicalMemoryArraysBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfPhysicalMemoryArray,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemoryArray> {
    
    		let variables: SearchPhysicalMemoryArraysBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaPhysicalMemoryArraysBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataPhysicalMemoryArraysBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfPhysicalMemoryArray,
		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemoryArray> {
    
    		let variables: CustomQueryPhysicalMemoryArraysBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfPhysicalMemoryArray,
		@Args('filter?') filter?: FilterOfPhysicalMemoryArray,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPhysicalMemoryArray> {
    
    		let variables: CustomQueryIdPhysicalMemoryArraysBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedPhysicalMemoryArraysBaseVariables = {
    			ids: ids
    		}
    				return this.usedPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistPhysicalMemoryArraysBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existPhysicalMemoryArraysQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.physicalMemoryArrays.exist));
    	}
    
    
}