import { Injectable } from '@angular/core';
import { Observable, of } from 'rxjs';
import { first, map } from 'rxjs/operators';
import { AuthorizationCoreService } from '../authorization/authorization.service';
import { LicenseCoreService } from '../graphql/generated-types/services/license.service';
import { LicenseModel } from '../graphql/generated-types/types';

/** Représente la licence courrante. */
@Injectable({
  providedIn: 'root',
})
export class CoreLicenseReader {
  private _currentLicense: LicenseModel;
  constructor(
    private authorizationService: AuthorizationCoreService,
    private licenseService: LicenseCoreService,
  ) {}
  private getCurrentLicense(): Observable<LicenseModel> {
    const id = this.authorizationService.user.getClaim('tenantId');
    if (id != undefined) {
      const variables = { id: id };

      return this.licenseService
        .get(LicenseCoreService.fields(), id)
        .pipe(map((res) => res.data));
    } else {
      return of(undefined);
    }
  }
  /** Test si le nombre max a été atteinte. */
  public max(
    value: number,
    propertyExpr: (license: LicenseModel) => number,
  ): boolean {
    return value >= propertyExpr(this._currentLicense);
  }
  public get currentLicense() {
    return this._currentLicense;
  }

  /** Vérifie si la licence contient la feature */
  public hasFeature(feature: string): boolean {
    if (this._currentLicense != undefined) {
      if (
        this._currentLicense.features[feature] != undefined &&
        this._currentLicense.features[feature] == true
      ) {
        // Vérification si présence d'une dete
        feature += 'ExpireAt';
        if (this._currentLicense.features[feature] != undefined) {
          return this._currentLicense.features[feature] > Date.now();
        }
        return true;
      }
    }

    return false;
  }

  /** Initialise la licence. */
  public async initialize(): Promise<void> {
    this._currentLicense = await this.getCurrentLicense().toPromise();
  }
}
