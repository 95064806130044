import { Component, forwardRef, Input, OnInit, ViewChild } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { TemplatesService } from '../../templates/templates.service';
import {
  ModelDataSourceContext,
  ModelState,
} from '@clarilog/shared2/services/compiler/model-state';
import { DxDataGridComponent } from 'devextreme-angular';
import { DxiColumnComponent } from 'devextreme-angular/ui/nested';
import { CriteriaHelpers } from '@clarilog/core/services2/graphql/criteria-helpers';
import { CoreGraphQLSource } from '@clarilog/core/services2/graphql/graphql-store.service';
import { TicketStatusCoreService } from '@clarilog/core/services2/graphql/generated-types/services';
import {
  GqlSubField,
  GqlField,
} from '@clarilog/core/services2/graphql/generated-types/helpers';
import {
  ModelRule,
  Sort,
} from '@clarilog/core/services2/graphql/generated-types/types';
import { TranslatedFieldHelperService } from '../../translate-field';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';

/** Représente le composent LinkList. Permet de lier des éléments à un élément. */
@Component({
  selector: 'clc-designer-rule-list',
  templateUrl: './desginer-rule-list.component.html',
  styleUrls: ['./designer-rule-list.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => CoreDesignerRuleListComponent),
      multi: true,
    },
  ],
})
export class CoreDesignerRuleListComponent
  implements ControlValueAccessor, OnInit
{
  @Input() readOnly: boolean = false;
  @Input() state: ModelState;
  @Input() fieldName: string;
  @Input() hasStatus: boolean = false;
  namePattern = /^(\d*(\.))*\d+$/;
  /** Sauvegarde les valeurs. */
  _values: any[];
  /** @inheritdoc */
  onChange: any = () => {};
  /** @inheritdoc */
  onTouched: any = () => {};
  /** Message pour le validator. */
  messageValidator: string;

  @Input() defaultValue: any;

  /** Affiche les titres des colonnes */
  @Input() showColumnHeaders: boolean = false;

  @ViewChild(DxDataGridComponent) grid: DxDataGridComponent;
  _initializeState: boolean = false;
  /** Obtient ou définit les valeurs. */
  get values(): any[] {
    if (this._values != undefined) {
      this._values.forEach((f) => delete f['__KEY__']);
      this._values.forEach((f) => {
        delete f['__typename'];
        if (f.option != undefined) {
          delete f.option['__typename'];
        }
      });
    } else {
      this._values = [];
    }
    return this._values;
  }
  set values(values: any[]) {
    this._values = values;
    if (this._initializeState === false) {
      this.onChange(this.values);
      this.onTouched();
    }
  }

  constructor(
    public templateService: TemplatesService,
    private ticketStatusService: TicketStatusCoreService,
    private translateFieldHelperService: TranslatedFieldHelperService,
  ) {}

  tagSource: ModelDataSourceContext;
  defaultValues: ModelRule[];

  ngOnInit() {
    this.tagSource = new ModelDataSourceContext({
      serviceName: 'taskSequenceService',
      methodName: 'find',
    });

    this.tagSource.datasource = CoreGraphQLSource.create({
      context: this.tagSource,
      query: (filter?: any, options?: any) => {
        let myFilter =
          filter != undefined ? CriteriaHelpers.convertDxFilter(filter) : null;
        delete options?.textSearch;
        let sortObj = { name: [{}] };

        sortObj.name[0][this.translateFieldHelperService.getTranslateKey()] =
          Sort.Asc;
        options.sort = [sortObj];
        return this.ticketStatusService.find(
          [
            GqlSubField.create('data', [
              GqlField.create('id'),
              GqlSubField.create(
                'name',
                this.translateFieldHelperService.translatedFields(),
              ),
            ]),
            GqlField.create('totalCount'),
          ],
          options,
          myFilter,
        );
      },
    });
  }

  getDisplayExprTransalted() {
    return this.translateFieldHelperService.setColumnTranslateField('name');
  }

  /** @inheritdoc */
  writeValue(values: any[]): void {
    this._initializeState = true;
    if (values != undefined) {
      values.forEach((el) => {
        // cas champ perso
        if (
          el.fieldName.includes('dynamicProperties') &&
          el.translatedTitle != undefined &&
          el.translatedTitle[
            this.translateFieldHelperService.getTranslateKey()
          ] != undefined
        ) {
          el.title =
            el.translatedTitle[
              this.translateFieldHelperService.getTranslateKey()
            ];
          if (el.title == undefined) {
            el.title = el.fieldName;
          }
        } else {
          if (el.title != undefined) {
            el.title = el.title.replace('/HtmlEditorComponent', '');
          }
        }
      });
      values.forEach((el) => {
        // cas champ perso
        if (
          el.fieldName.includes('dynamicProperties') &&
          el.translatedTitle != undefined &&
          el.translatedTitle[
            this.translateFieldHelperService.getLanguageValue()
          ] != undefined
        ) {
          el.title =
            el.translatedTitle[
              this.translateFieldHelperService.getLanguageValue()
            ];
          if (el.title == undefined) {
            el.title = el.fieldName;
          }
        } else {
          if (el.title != undefined) {
            el.title = el.title.replace('/FileManagerComponent', '');
          }
        }
      });
      this.values = values;
    }
    this._initializeState = false;
  }
  /** @inheritdoc */
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  /** @inheritdoc */
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  public printTitle(cellInfo): string {
    if (this.values != undefined) {
      let fieldName = (cellInfo.data as ModelRule).fieldName;

      // Cas champs perso
      if (fieldName.includes('dynamicProperties')) {
        return (cellInfo.data as ModelRule).title;
      }

      let translatedFieldName = this.values.find(
        (el) => el.fieldName === fieldName,
      );
      if (translatedFieldName == undefined) {
        return (cellInfo.data as ModelRule).title;
      }
      let title = cellInfo.text.replace("'", '').replace("'", '');
      return TranslateService.get(title);
    }
    return '';
  }

  onRowUpdated(e) {
    this.onChange(this.values);
    this.onTouched();
  }
  getValues(e) {
    //this.cd.detectChanges();
    setTimeout(() => {
      this.onChange(this.values);
      this.onTouched();
    });
  }

  onSaved(e) {
    this.getValues(e);
  }

  onCellPrepared(e) {}

  /**
   * Teplate tagbox
   */

  dropDownComponent: any;

  onDropDownInitialized(e) {
    this.dropDownComponent = e.component;
  }

  onDropDownInput(e) {
    e.component.open();
  }

  onTreelistContentReady(e, condition) {
    if (
      condition != undefined &&
      condition.value != undefined &&
      condition.value.length > 0
    ) {
      let isExpand = e.component.option('expandedRowKeys');
      if (isExpand == undefined || isExpand.length == 0) {
        let node = e.component.getNodeByKey(condition.value[0]);
        let expand = [];
        while (node != undefined) {
          if (node.parent == undefined) {
            break;
          }
          node = e.component.getNodeByKey(node.parent.key);
          if (node.level != -1) {
            expand.push(node.key);
          }
        }
        expand.reverse();
        e.component.option('expandedRowKeys', expand);
      }
    }
  }

  setCellValue(newData, value, currentRowData) {
    let that = this as any as DxiColumnComponent;
    // ici ne jamais faire de bind de cette fonction sinon on pers le this
    newData[that.dataField] = value;
    if (that.dataField === 'editable' && value === true) {
      newData.visible = true;
    }
    if (that.dataField === 'editable' && value === false) {
      newData.required = false;
    }
    if (that.dataField === 'visible' && value === false) {
      newData.required = false;
      newData.editable = false;
    }
    if (that.dataField === 'required' && value === true) {
      newData.visible = true;
      newData.editable = true;
    } else if (that.dataField === 'required' && value === false) {
      newData.statusIds = [];
    }
    (that as any).defaultSetCellValue(newData, value);
  }

  selectionTemplateChanged(cellInfo, e) {
    let items = [];

    if (cellInfo.value != undefined) {
      items = JSON.parse(JSON.stringify(cellInfo.value));
    }
    if (e.addedItems.length === 0 && e.removedItems.length === 0) {
      return;
    }

    e.addedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element['id']);
      if (index < 0) {
        items.push(JSON.parse(JSON.stringify(element['id'])));
      }
    });

    e.removedItems.forEach((element) => {
      let index = items.findIndex((x) => x === element['id']);

      if (index >= 0) {
        items.splice(index, 1);
      }
    });

    if (JSON.stringify(items) != JSON.stringify(cellInfo.value)) {
      cellInfo.setValue(items);
    }
  }
  /**
   *  CHECK BOX
   * @param cellInfo
   * @param e
   */

  checkBoxValueChanged(cellInfo, e) {
    cellInfo.setValue(e.value);
  }
}
