import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetScanByAzureAdAppointmentTasksBaseVariables, FirstScanByAzureAdAppointmentTasksBaseVariables, CountScanByAzureAdAppointmentTasksBaseVariables, FindScanByAzureAdAppointmentTasksBaseVariables, SearchScanByAzureAdAppointmentTasksBaseVariables, ExportSchemaScanByAzureAdAppointmentTasksBaseVariables, ExportDataScanByAzureAdAppointmentTasksBaseVariables, CustomQueryScanByAzureAdAppointmentTasksBaseVariables, CustomQueryIdScanByAzureAdAppointmentTasksBaseVariables, UsedScanByAzureAdAppointmentTasksBaseVariables, ExistScanByAzureAdAppointmentTasksBaseVariables, InsertScanByAzureAdAppointmentTasksBaseVariables, UpdateScanByAzureAdAppointmentTasksBaseVariables, ImportDataScanByAzureAdAppointmentTasksBaseVariables, UpdateManyScanByAzureAdAppointmentTasksBaseVariables, DeleteScanByAzureAdAppointmentTasksBaseVariables } from '../gqls'
import { GetScanByAzureAdAppointmentTasksDocument, FirstScanByAzureAdAppointmentTasksDocument, CountScanByAzureAdAppointmentTasksDocument, FindScanByAzureAdAppointmentTasksDocument, SearchScanByAzureAdAppointmentTasksDocument, ExportSchemaScanByAzureAdAppointmentTasksDocument, ExportDataScanByAzureAdAppointmentTasksDocument, CustomQueryScanByAzureAdAppointmentTasksDocument, CustomQueryIdScanByAzureAdAppointmentTasksDocument, UsedScanByAzureAdAppointmentTasksDocument, ExistScanByAzureAdAppointmentTasksDocument, InsertScanByAzureAdAppointmentTasksDocument, UpdateScanByAzureAdAppointmentTasksDocument, ImportDataScanByAzureAdAppointmentTasksDocument, UpdateManyScanByAzureAdAppointmentTasksDocument, DeleteScanByAzureAdAppointmentTasksDocument } from '../gqls'
import { ServiceSingleResultOfScanByAzureAdAppointmentTask, QueryContextOfScanByAzureAdAppointmentTask, FilterOfScanByAzureAdAppointmentTask, ServiceSingleResultOfInt64, ServiceListResultOfScanByAzureAdAppointmentTask, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ScanByAzureAdAppointmentTaskInput, FieldUpdateOperatorOfScanByAzureAdAppointmentTask } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ScanByAzureAdAppointmentTaskBaseService {
    
public modelName = 'scanByAzureAdAppointmentTask';
public modelPluralName = 'scanByAzureAdAppointmentTasks';

	private getScanByAzureAdAppointmentTasksQuery: GetScanByAzureAdAppointmentTasksDocument;
	private firstScanByAzureAdAppointmentTasksQuery: FirstScanByAzureAdAppointmentTasksDocument;
	private countScanByAzureAdAppointmentTasksQuery: CountScanByAzureAdAppointmentTasksDocument;
	private findScanByAzureAdAppointmentTasksQuery: FindScanByAzureAdAppointmentTasksDocument;
	private searchScanByAzureAdAppointmentTasksQuery: SearchScanByAzureAdAppointmentTasksDocument;
	private exportSchemaScanByAzureAdAppointmentTasksQuery: ExportSchemaScanByAzureAdAppointmentTasksDocument;
	private exportDataScanByAzureAdAppointmentTasksQuery: ExportDataScanByAzureAdAppointmentTasksDocument;
	private customQueryScanByAzureAdAppointmentTasksQuery: CustomQueryScanByAzureAdAppointmentTasksDocument;
	private customQueryIdScanByAzureAdAppointmentTasksQuery: CustomQueryIdScanByAzureAdAppointmentTasksDocument;
	private usedScanByAzureAdAppointmentTasksQuery: UsedScanByAzureAdAppointmentTasksDocument;
	private existScanByAzureAdAppointmentTasksQuery: ExistScanByAzureAdAppointmentTasksDocument;
	private insertScanByAzureAdAppointmentTasksMutation: InsertScanByAzureAdAppointmentTasksDocument;
	private updateScanByAzureAdAppointmentTasksMutation: UpdateScanByAzureAdAppointmentTasksDocument;
	private importDataScanByAzureAdAppointmentTasksMutation: ImportDataScanByAzureAdAppointmentTasksDocument;
	private updateManyScanByAzureAdAppointmentTasksMutation: UpdateManyScanByAzureAdAppointmentTasksDocument;
	private deleteScanByAzureAdAppointmentTasksMutation: DeleteScanByAzureAdAppointmentTasksDocument;

	constructor(private injector: Injector) {
		this.getScanByAzureAdAppointmentTasksQuery = this.injector.get(GetScanByAzureAdAppointmentTasksDocument);
		this.firstScanByAzureAdAppointmentTasksQuery = this.injector.get(FirstScanByAzureAdAppointmentTasksDocument);
		this.countScanByAzureAdAppointmentTasksQuery = this.injector.get(CountScanByAzureAdAppointmentTasksDocument);
		this.findScanByAzureAdAppointmentTasksQuery = this.injector.get(FindScanByAzureAdAppointmentTasksDocument);
		this.searchScanByAzureAdAppointmentTasksQuery = this.injector.get(SearchScanByAzureAdAppointmentTasksDocument);
		this.exportSchemaScanByAzureAdAppointmentTasksQuery = this.injector.get(ExportSchemaScanByAzureAdAppointmentTasksDocument);
		this.exportDataScanByAzureAdAppointmentTasksQuery = this.injector.get(ExportDataScanByAzureAdAppointmentTasksDocument);
		this.customQueryScanByAzureAdAppointmentTasksQuery = this.injector.get(CustomQueryScanByAzureAdAppointmentTasksDocument);
		this.customQueryIdScanByAzureAdAppointmentTasksQuery = this.injector.get(CustomQueryIdScanByAzureAdAppointmentTasksDocument);
		this.usedScanByAzureAdAppointmentTasksQuery = this.injector.get(UsedScanByAzureAdAppointmentTasksDocument);
		this.existScanByAzureAdAppointmentTasksQuery = this.injector.get(ExistScanByAzureAdAppointmentTasksDocument);
		this.insertScanByAzureAdAppointmentTasksMutation = this.injector.get(InsertScanByAzureAdAppointmentTasksDocument);
		this.updateScanByAzureAdAppointmentTasksMutation = this.injector.get(UpdateScanByAzureAdAppointmentTasksDocument);
		this.importDataScanByAzureAdAppointmentTasksMutation = this.injector.get(ImportDataScanByAzureAdAppointmentTasksDocument);
		this.updateManyScanByAzureAdAppointmentTasksMutation = this.injector.get(UpdateManyScanByAzureAdAppointmentTasksDocument);
		this.deleteScanByAzureAdAppointmentTasksMutation = this.injector.get(DeleteScanByAzureAdAppointmentTasksDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: GetScanByAzureAdAppointmentTasksBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.get));
            }
            else{
                let result:ServiceSingleResultOfScanByAzureAdAppointmentTask={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdAppointmentTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: FirstScanByAzureAdAppointmentTasksBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountScanByAzureAdAppointmentTasksBaseVariables = {
    			filter: filter
    		}
    				return this.countScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfScanByAzureAdAppointmentTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: FindScanByAzureAdAppointmentTasksBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointmentTask,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: SearchScanByAzureAdAppointmentTasksBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaScanByAzureAdAppointmentTasksBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataScanByAzureAdAppointmentTasksBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointmentTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: CustomQueryScanByAzureAdAppointmentTasksBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfScanByAzureAdAppointmentTask,
		@Args('filter?') filter?: FilterOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: CustomQueryIdScanByAzureAdAppointmentTasksBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedScanByAzureAdAppointmentTasksBaseVariables = {
    			ids: ids
    		}
    				return this.usedScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistScanByAzureAdAppointmentTasksBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existScanByAzureAdAppointmentTasksQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.exist));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ScanByAzureAdAppointmentTaskInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: InsertScanByAzureAdAppointmentTasksBaseVariables = {
    			entity: entity
    		}
    				return this.insertScanByAzureAdAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfScanByAzureAdAppointmentTask> {
    
    		let variables: UpdateScanByAzureAdAppointmentTasksBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateScanByAzureAdAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataScanByAzureAdAppointmentTasksBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataScanByAzureAdAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfScanByAzureAdAppointmentTask,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyScanByAzureAdAppointmentTasksBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyScanByAzureAdAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteScanByAzureAdAppointmentTasksBaseVariables = {
    			ids: ids
    		}
    				return this.deleteScanByAzureAdAppointmentTasksMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.scanByAzureAdAppointmentTasks.delete));
    	}
    
}