import { AssetModelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { InjectArgs, Args, Authorize } from '@clarilog/core';
import {
  AssetModelCategory,
  FilterOfAssetModel,
  QueryContextOfAssetModel,
  ServiceListResultOfAssetModel,
} from '../types';
import { Observable, of } from 'rxjs';
import { GqlField, GqlFields, GqlSubField, GqlSubFieldArg } from '../helpers';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('asset-model')
export class AssetModelCoreService extends AssetModelBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }

  /** Obtient la liste des référence par categorie */
  @InjectArgs
  public findByAsset(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetModel,
    @Args('filter?') filter?: FilterOfAssetModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetModel> {
    return this.findByCategory(
      fields,
      AssetModelCategory.Asset,
      options,
      filter,
      extendedVariables,
    );
  }

  /** Obtient la liste des modèle de type bien */
  @InjectArgs
  public findAssetModels(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfAssetModel,
    @Args('manufacturerId?') manufacturerId?: string,
    @Args('assetCategoryId?') assetCategoryId?: string,
    @Args('modelId?') modelId?: string,
    @Args('filter?') filter?: FilterOfAssetModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetModel> {
    let listFilter = [];
    listFilter.push({ assetCategoryId: { eq: assetCategoryId } });

    if (modelId != undefined) listFilter.push({ modelId: { eq: modelId } });

    if (manufacturerId != undefined)
      listFilter.push({ manufacturerId: { eq: manufacturerId } });

    let listFilter2 = [
      { assetCategoryId: { eq: assetCategoryId } },
      { modelId: { eq: null } },
      { manufacturerId: { eq: null } },
    ];

    if (filter == null) {
      let f1 = {
        and: listFilter,
      };

      let f2 = {
        and: listFilter2,
      };

      filter = {
        or: [f1, f2],
      };
    } else {
      //Laisser afficher la valeur par default même si le classement de la ref a été modifié
      let idIsInclude = filter.id;
      if (idIsInclude == undefined) {
        filter = {
          and: [filter],
        };

        listFilter.map((element) => {
          filter.and.push(element);
        });
      }
    }

    return this.findByCategory(
      fields,
      AssetModelCategory.Asset,
      options,
      filter,
      extendedVariables,
    );
  }

  //Methode en stand-by pour afficher que les biens ayant
  // le classement demandé
  @InjectArgs
  findUnassociatedAssetsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('id') id: string,
    @Args('manufacturerId?') manufacturerId?: string,
    @Args('modelId?') modelId?: string,
    @Args('assetIds?') assetIds?: string,
    @Args('options?') options?: QueryContextOfAssetModel,
    @Args('filter?') filter?: FilterOfAssetModel,
    @Args('assetCategoryId?') assetCategoryId?: string,
    @Args('extendedVariables?') extendedVariables?: any,
  ) {
    let listFilter = [
      { assetCategoryId: { eq: assetCategoryId } },
      { modelId: { eq: modelId } },
      { manufacturerId: { eq: manufacturerId } },
    ];
    if (filter == null) {
      filter = {
        and: listFilter,
      };
    } else {
      filter = {
        and: [filter],
      };

      listFilter.map((element) => {
        filter.and.push(element);
      });
    }
    return this.findUnassociatedAssets(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    );
  }

  @InjectArgs
  public findStorageAssetModelItems(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('storageUnitIds?') storageUnitIds?: string[],
    @Args('options?') options?: QueryContextOfAssetModel,
    @Args('filter?') filter?: FilterOfAssetModel,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfAssetModel> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let entryAmountField = (
      fields.find((x) => x.name === 'data') as GqlSubField
    ).fields.find((x) => x.name === 'entriesAmount');

    if (entryAmountField != undefined) {
      if (storageUnitIds != null) {
        (entryAmountField as GqlSubField).args = [
          GqlSubFieldArg.create('entriesAmountStorageIds', 'storageUnitIds'),
        ];
        extendedVariables['entriesAmountStorageIds'] = storageUnitIds;
      }
    }

    return super.findStorageAssetModelItems(
      fields,
      storageUnitIds,
      options,
      filter,
      extendedVariables,
    );
  }

  /** Permet de choisir le type d'élément à créer  */
  findByKey(): Observable<{ id: string; name: string }[]> {
    let items = [
      {
        id: AssetModelCategory.Asset,
        name: TranslateService.get('entities/asset/_title/singular'),
      },
      {
        id: AssetModelCategory.Consumable,
        name: TranslateService.get('entities/consumable/_title/singular'),
      },
    ];
    items.sort((a, b) => a.name.localeCompare(b.name));
    return of(items);
  }

  @InjectArgs
  /** Obtient les fields de recherche */
  public searchFields(): GqlFields {
    return [
      GqlSubField.create('data', [
        GqlField.create('id'),
        GqlField.create('assetCategoryId'),
        GqlField.create('name'),
        GqlSubField.create('assetCategory', [
          GqlSubField.create('data', [
            GqlSubField.create(
              'name',
              this.translatedFieldHelperService.translatedFields(),
            ),
          ]),
        ]),
      ]),
      GqlField.create('totalCount'),
    ];
  }
}
