import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetOriginsBaseVariables, FirstOriginsBaseVariables, CountOriginsBaseVariables, FindOriginsBaseVariables, SearchOriginsBaseVariables, ExportSchemaOriginsBaseVariables, ExportDataOriginsBaseVariables, CustomQueryOriginsBaseVariables, CustomQueryIdOriginsBaseVariables, FindUnassociatedSecurityGroupsOriginsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsOriginsBaseVariables, UsedOriginsBaseVariables, ExistOriginsBaseVariables, FindRecyclesOriginsBaseVariables, CountRecyclesOriginsBaseVariables, ReadOnlyOriginsBaseVariables, FindArchivedOriginsBaseVariables, CountAllOriginsBaseVariables, FindDynamicPropertyFieldsOriginsBaseVariables, FindUnassociatedProblemsOriginsBaseVariables, InsertOriginsBaseVariables, UpdateOriginsBaseVariables, ImportDataOriginsBaseVariables, UpdateManyOriginsBaseVariables, DeleteOriginsBaseVariables, AddSecurityGroupOriginsBaseVariables, RemoveSecurityGroupOriginsBaseVariables, AddOperatorTeamSecurityGroupOriginsBaseVariables, RemoveOperatorTeamSecurityGroupOriginsBaseVariables, RestoreOriginsBaseVariables, RecycleOriginsBaseVariables, RestoreArchivedOriginsBaseVariables, ArchivedOriginsBaseVariables, AddFileDynamicFieldOriginsBaseVariables, RemoveFileDynamicFieldOriginsBaseVariables, AddProblemsOriginsBaseVariables, RemoveProblemsOriginsBaseVariables } from '../gqls'
import { GetOriginsDocument, FirstOriginsDocument, CountOriginsDocument, FindOriginsDocument, SearchOriginsDocument, ExportSchemaOriginsDocument, ExportDataOriginsDocument, CustomQueryOriginsDocument, CustomQueryIdOriginsDocument, FindUnassociatedSecurityGroupsOriginsDocument, FindAssociatedOperatorTeamSecurityGroupsOriginsDocument, UsedOriginsDocument, ExistOriginsDocument, FindRecyclesOriginsDocument, CountRecyclesOriginsDocument, ReadOnlyOriginsDocument, FindArchivedOriginsDocument, CountAllOriginsDocument, FindDynamicPropertyFieldsOriginsDocument, FindUnassociatedProblemsOriginsDocument, InsertOriginsDocument, UpdateOriginsDocument, ImportDataOriginsDocument, UpdateManyOriginsDocument, DeleteOriginsDocument, AddSecurityGroupOriginsDocument, RemoveSecurityGroupOriginsDocument, AddOperatorTeamSecurityGroupOriginsDocument, RemoveOperatorTeamSecurityGroupOriginsDocument, RestoreOriginsDocument, RecycleOriginsDocument, RestoreArchivedOriginsDocument, ArchivedOriginsDocument, AddFileDynamicFieldOriginsDocument, RemoveFileDynamicFieldOriginsDocument, AddProblemsOriginsDocument, RemoveProblemsOriginsDocument } from '../gqls'
import { ServiceSingleResultOfOrigin, QueryContextOfOrigin, FilterOfOrigin, ServiceSingleResultOfInt64, ServiceListResultOfOrigin, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfOrigin, QueryContextOfProblem, FilterOfProblem, ServiceListResultOfProblem, OriginInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class OriginBaseService {
    
public modelName = 'origin';
public modelPluralName = 'origins';

	private getOriginsQuery: GetOriginsDocument;
	private firstOriginsQuery: FirstOriginsDocument;
	private countOriginsQuery: CountOriginsDocument;
	private findOriginsQuery: FindOriginsDocument;
	private searchOriginsQuery: SearchOriginsDocument;
	private exportSchemaOriginsQuery: ExportSchemaOriginsDocument;
	private exportDataOriginsQuery: ExportDataOriginsDocument;
	private customQueryOriginsQuery: CustomQueryOriginsDocument;
	private customQueryIdOriginsQuery: CustomQueryIdOriginsDocument;
	private findUnassociatedSecurityGroupsOriginsQuery: FindUnassociatedSecurityGroupsOriginsDocument;
	private findAssociatedOperatorTeamSecurityGroupsOriginsQuery: FindAssociatedOperatorTeamSecurityGroupsOriginsDocument;
	private usedOriginsQuery: UsedOriginsDocument;
	private existOriginsQuery: ExistOriginsDocument;
	private findRecyclesOriginsQuery: FindRecyclesOriginsDocument;
	private countRecyclesOriginsQuery: CountRecyclesOriginsDocument;
	private readOnlyOriginsQuery: ReadOnlyOriginsDocument;
	private findArchivedOriginsQuery: FindArchivedOriginsDocument;
	private countAllOriginsQuery: CountAllOriginsDocument;
	private findDynamicPropertyFieldsOriginsQuery: FindDynamicPropertyFieldsOriginsDocument;
	private findUnassociatedProblemsOriginsQuery: FindUnassociatedProblemsOriginsDocument;
	private insertOriginsMutation: InsertOriginsDocument;
	private updateOriginsMutation: UpdateOriginsDocument;
	private importDataOriginsMutation: ImportDataOriginsDocument;
	private updateManyOriginsMutation: UpdateManyOriginsDocument;
	private deleteOriginsMutation: DeleteOriginsDocument;
	private addSecurityGroupOriginsMutation: AddSecurityGroupOriginsDocument;
	private removeSecurityGroupOriginsMutation: RemoveSecurityGroupOriginsDocument;
	private addOperatorTeamSecurityGroupOriginsMutation: AddOperatorTeamSecurityGroupOriginsDocument;
	private removeOperatorTeamSecurityGroupOriginsMutation: RemoveOperatorTeamSecurityGroupOriginsDocument;
	private restoreOriginsMutation: RestoreOriginsDocument;
	private recycleOriginsMutation: RecycleOriginsDocument;
	private restoreArchivedOriginsMutation: RestoreArchivedOriginsDocument;
	private archivedOriginsMutation: ArchivedOriginsDocument;
	private addFileDynamicFieldOriginsMutation: AddFileDynamicFieldOriginsDocument;
	private removeFileDynamicFieldOriginsMutation: RemoveFileDynamicFieldOriginsDocument;
	private addProblemsOriginsMutation: AddProblemsOriginsDocument;
	private removeProblemsOriginsMutation: RemoveProblemsOriginsDocument;

	constructor(private injector: Injector) {
		this.getOriginsQuery = this.injector.get(GetOriginsDocument);
		this.firstOriginsQuery = this.injector.get(FirstOriginsDocument);
		this.countOriginsQuery = this.injector.get(CountOriginsDocument);
		this.findOriginsQuery = this.injector.get(FindOriginsDocument);
		this.searchOriginsQuery = this.injector.get(SearchOriginsDocument);
		this.exportSchemaOriginsQuery = this.injector.get(ExportSchemaOriginsDocument);
		this.exportDataOriginsQuery = this.injector.get(ExportDataOriginsDocument);
		this.customQueryOriginsQuery = this.injector.get(CustomQueryOriginsDocument);
		this.customQueryIdOriginsQuery = this.injector.get(CustomQueryIdOriginsDocument);
		this.findUnassociatedSecurityGroupsOriginsQuery = this.injector.get(FindUnassociatedSecurityGroupsOriginsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsOriginsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsOriginsDocument);
		this.usedOriginsQuery = this.injector.get(UsedOriginsDocument);
		this.existOriginsQuery = this.injector.get(ExistOriginsDocument);
		this.findRecyclesOriginsQuery = this.injector.get(FindRecyclesOriginsDocument);
		this.countRecyclesOriginsQuery = this.injector.get(CountRecyclesOriginsDocument);
		this.readOnlyOriginsQuery = this.injector.get(ReadOnlyOriginsDocument);
		this.findArchivedOriginsQuery = this.injector.get(FindArchivedOriginsDocument);
		this.countAllOriginsQuery = this.injector.get(CountAllOriginsDocument);
		this.findDynamicPropertyFieldsOriginsQuery = this.injector.get(FindDynamicPropertyFieldsOriginsDocument);
		this.findUnassociatedProblemsOriginsQuery = this.injector.get(FindUnassociatedProblemsOriginsDocument);
		this.insertOriginsMutation = this.injector.get(InsertOriginsDocument);
		this.updateOriginsMutation = this.injector.get(UpdateOriginsDocument);
		this.importDataOriginsMutation = this.injector.get(ImportDataOriginsDocument);
		this.updateManyOriginsMutation = this.injector.get(UpdateManyOriginsDocument);
		this.deleteOriginsMutation = this.injector.get(DeleteOriginsDocument);
		this.addSecurityGroupOriginsMutation = this.injector.get(AddSecurityGroupOriginsDocument);
		this.removeSecurityGroupOriginsMutation = this.injector.get(RemoveSecurityGroupOriginsDocument);
		this.addOperatorTeamSecurityGroupOriginsMutation = this.injector.get(AddOperatorTeamSecurityGroupOriginsDocument);
		this.removeOperatorTeamSecurityGroupOriginsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupOriginsDocument);
		this.restoreOriginsMutation = this.injector.get(RestoreOriginsDocument);
		this.recycleOriginsMutation = this.injector.get(RecycleOriginsDocument);
		this.restoreArchivedOriginsMutation = this.injector.get(RestoreArchivedOriginsDocument);
		this.archivedOriginsMutation = this.injector.get(ArchivedOriginsDocument);
		this.addFileDynamicFieldOriginsMutation = this.injector.get(AddFileDynamicFieldOriginsDocument);
		this.removeFileDynamicFieldOriginsMutation = this.injector.get(RemoveFileDynamicFieldOriginsDocument);
		this.addProblemsOriginsMutation = this.injector.get(AddProblemsOriginsDocument);
		this.removeProblemsOriginsMutation = this.injector.get(RemoveProblemsOriginsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrigin> {
    
    		let variables: GetOriginsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.get));
            }
            else{
                let result:ServiceSingleResultOfOrigin={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrigin> {
    
    		let variables: FirstOriginsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountOriginsBaseVariables = {
    			filter: filter
    		}
    				return this.countOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: FindOriginsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfOrigin,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: SearchOriginsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaOriginsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataOriginsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: CustomQueryOriginsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: CustomQueryIdOriginsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsOriginsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsOriginsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.usedOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistOriginsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: FindRecyclesOriginsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesOriginsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyOriginsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfOrigin,
		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOrigin> {
    
    		let variables: FindArchivedOriginsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllOriginsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsOriginsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedProblems(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfProblem,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfProblem,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfProblem> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('problems', fields, null, [
			GqlSubFieldArg.create('filterOfProblems', 'filter'),
			GqlSubFieldArg.create('optionsOfProblems', 'options'),
		]),
		])
		extendedVariables['filterOfProblems'] = filter;
		extendedVariables['optionsOfProblems'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.problems));
            }
            else{
                let result: ServiceListResultOfProblem = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedProblems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfProblem,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfProblem,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfProblem> {
    
    		let variables: FindUnassociatedProblemsOriginsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedProblemsOriginsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.origins.findUnassociatedProblems));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: OriginInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrigin> {
    
    		let variables: InsertOriginsBaseVariables = {
    			entity: entity
    		}
    				return this.insertOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfOrigin> {
    
    		let variables: UpdateOriginsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataOriginsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfOrigin,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyOriginsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.delete));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupOriginsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupOriginsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupOriginsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupOriginsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.removeOperatorTeamSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedOriginsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldOriginsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldOriginsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addProblems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('problemIds') problemIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddProblemsOriginsBaseVariables = {
    			id: id,
			problemIds: problemIds
    		}
    				return this.addProblemsOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.addProblems));
    	}

    	/**  */
    	@InjectArgs
    	public removeProblems(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('problemIds') problemIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveProblemsOriginsBaseVariables = {
    			problemIds: problemIds
    		}
    				return this.removeProblemsOriginsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.origins.removeProblems));
    	}
    
}