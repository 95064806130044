<div class="link-list-component">
  <cl-toolbar class="action">
    <cl-toolbar-items [items]="toolbar?.itemsChildren.toArray()">
      <cl-toolbar-item-button
        [text]="'update' | translate"
        icon="far fa-unlink"
        [disabled]="selectedKeys.length === 0 || disableButton"
        (onClick)="update($event)"
      ></cl-toolbar-item-button>
      <cl-toolbar-item-button
        [text]="'cancel' | translate"
        icon="far fa-unlink"
        [disabled]="selectedKeys.length === 0 || disableButton"
        (onClick)="cancel($event)"
      ></cl-toolbar-item-button>
      <cl-toolbar-item-button
        [text]="'manage' | translate"
        [visible]="route !== undefined"
        (onClick)="onGoTo()"
        icon="fas fa-arrow-right"
      ></cl-toolbar-item-button>
    </cl-toolbar-items>
  </cl-toolbar>
  <clc-list
    class="list"
    [canSelect]="true"
    [source]="currentSource"
    [type]="type == undefined ? 'Grid' : type"
    [columns]="columns"
    [multiple]="multiple"
    [keyExpr]="keyExpr"
    [parentIdExpr]="parentIdExpr"
    [recursive]="recursive"
    [allowUpdate]="!disableEdit"
    [allowDelete]="allowDeleting"
    [(selectedKeys)]="selectedKeys"
    [(selectedData)]="selectedData"
    (onRowClick)="update($event)"
  >
  </clc-list>
</div>
<clc-cart-list-select
  #selectList
  [movementType]="'OUT'"
  [label]="label"
  [inputModel]="outCartModel"
  (onClosed)="associatePopup = false"
  (onAdded)="objectPass($event)"
  [(isVisible)]="associatePopup"
>
</clc-cart-list-select>
