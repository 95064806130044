import { Injectable } from "@angular/core";
import { CoreAuthorizationSettings, PermissionNamespaceRequirement, PermissionRequirement } from "./authorization-policy-builder.service";
import { AuthorizationCoreService } from "./authorization.service";

@Injectable({
  providedIn: "root"
})
export class CoreAuthorizationPolicyConfiguration {
  constructor(
    private authorizationService: AuthorizationCoreService,
    private authorizationSettings: CoreAuthorizationSettings
  ) { }
  initialize() {
    let user = this.authorizationService.user;
    // Groupe les permissions par espace de nom
    let permissionsClaim = user.getClaim("permissions");
    // Test si le compte est un compte connecté.
    this.authorizationSettings.addAuthorization(
      "organization.authorize",
      policy => {
        policy.requireClaim("sub");
      }
    );
    // Test si le compte est un compte administrateur.
    this.authorizationSettings.addAuthorization(
      "clarilog.administrator",
      policy => {
        policy.requireClaim("role", 'administrator');
      }
    );
    if (permissionsClaim != undefined) {
      let permissionScopes = permissionsClaim.reduce(function (r, a: string) {
        let key = a.substring(0, a.indexOf("."));
        r[key] = r[key] || [];
        r[key].push(a);
        return r;
      }, Object.create(null));

      for (let permissionScopeKey in permissionScopes) {
        this.authorizationSettings.addAuthorization(
          permissionScopeKey,
          policy => {
            // Nouveau requirement
            policy.requireClaim("tenantId");
            policy.requireClaim("userId");
            policy.addRequirement(
              new PermissionNamespaceRequirement(permissionScopeKey)
            );
          }
        );
        for (let permission of permissionScopes[permissionScopeKey]) {
          this.authorizationSettings.addAuthorization(permission, policy => {
            policy.requireClaim("tenantId");
            policy.requireClaim("userId");
            policy.addRequirement(new PermissionRequirement(permission));
          });
        }
      }
    }
  }
}
