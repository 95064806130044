import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSatisfactionSettingsBaseVariables, FirstSatisfactionSettingsBaseVariables, CountSatisfactionSettingsBaseVariables, FindSatisfactionSettingsBaseVariables, SearchSatisfactionSettingsBaseVariables, ExportSchemaSatisfactionSettingsBaseVariables, ExportDataSatisfactionSettingsBaseVariables, CustomQuerySatisfactionSettingsBaseVariables, CustomQueryIdSatisfactionSettingsBaseVariables, UsedSatisfactionSettingsBaseVariables, ExistSatisfactionSettingsBaseVariables, FindRecyclesSatisfactionSettingsBaseVariables, CountRecyclesSatisfactionSettingsBaseVariables, ReadOnlySatisfactionSettingsBaseVariables, FindArchivedSatisfactionSettingsBaseVariables, CountAllSatisfactionSettingsBaseVariables, FindDynamicPropertyFieldsSatisfactionSettingsBaseVariables, InsertSatisfactionSettingsBaseVariables, UpdateSatisfactionSettingsBaseVariables, ImportDataSatisfactionSettingsBaseVariables, UpdateManySatisfactionSettingsBaseVariables, DeleteSatisfactionSettingsBaseVariables, RestoreSatisfactionSettingsBaseVariables, RecycleSatisfactionSettingsBaseVariables, RestoreArchivedSatisfactionSettingsBaseVariables, ArchivedSatisfactionSettingsBaseVariables, AddFileDynamicFieldSatisfactionSettingsBaseVariables, RemoveFileDynamicFieldSatisfactionSettingsBaseVariables } from '../gqls'
import { GetSatisfactionSettingsDocument, FirstSatisfactionSettingsDocument, CountSatisfactionSettingsDocument, FindSatisfactionSettingsDocument, SearchSatisfactionSettingsDocument, ExportSchemaSatisfactionSettingsDocument, ExportDataSatisfactionSettingsDocument, CustomQuerySatisfactionSettingsDocument, CustomQueryIdSatisfactionSettingsDocument, UsedSatisfactionSettingsDocument, ExistSatisfactionSettingsDocument, FindRecyclesSatisfactionSettingsDocument, CountRecyclesSatisfactionSettingsDocument, ReadOnlySatisfactionSettingsDocument, FindArchivedSatisfactionSettingsDocument, CountAllSatisfactionSettingsDocument, FindDynamicPropertyFieldsSatisfactionSettingsDocument, InsertSatisfactionSettingsDocument, UpdateSatisfactionSettingsDocument, ImportDataSatisfactionSettingsDocument, UpdateManySatisfactionSettingsDocument, DeleteSatisfactionSettingsDocument, RestoreSatisfactionSettingsDocument, RecycleSatisfactionSettingsDocument, RestoreArchivedSatisfactionSettingsDocument, ArchivedSatisfactionSettingsDocument, AddFileDynamicFieldSatisfactionSettingsDocument, RemoveFileDynamicFieldSatisfactionSettingsDocument } from '../gqls'
import { ServiceSingleResultOfSatisfactionSetting, QueryContextOfSatisfactionSetting, FilterOfSatisfactionSetting, ServiceSingleResultOfInt64, ServiceListResultOfSatisfactionSetting, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfSatisfactionSetting, SatisfactionSettingInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SatisfactionSettingBaseService {
    
public modelName = 'satisfactionSetting';
public modelPluralName = 'satisfactionSettings';

	private getSatisfactionSettingsQuery: GetSatisfactionSettingsDocument;
	private firstSatisfactionSettingsQuery: FirstSatisfactionSettingsDocument;
	private countSatisfactionSettingsQuery: CountSatisfactionSettingsDocument;
	private findSatisfactionSettingsQuery: FindSatisfactionSettingsDocument;
	private searchSatisfactionSettingsQuery: SearchSatisfactionSettingsDocument;
	private exportSchemaSatisfactionSettingsQuery: ExportSchemaSatisfactionSettingsDocument;
	private exportDataSatisfactionSettingsQuery: ExportDataSatisfactionSettingsDocument;
	private customQuerySatisfactionSettingsQuery: CustomQuerySatisfactionSettingsDocument;
	private customQueryIdSatisfactionSettingsQuery: CustomQueryIdSatisfactionSettingsDocument;
	private usedSatisfactionSettingsQuery: UsedSatisfactionSettingsDocument;
	private existSatisfactionSettingsQuery: ExistSatisfactionSettingsDocument;
	private findRecyclesSatisfactionSettingsQuery: FindRecyclesSatisfactionSettingsDocument;
	private countRecyclesSatisfactionSettingsQuery: CountRecyclesSatisfactionSettingsDocument;
	private readOnlySatisfactionSettingsQuery: ReadOnlySatisfactionSettingsDocument;
	private findArchivedSatisfactionSettingsQuery: FindArchivedSatisfactionSettingsDocument;
	private countAllSatisfactionSettingsQuery: CountAllSatisfactionSettingsDocument;
	private findDynamicPropertyFieldsSatisfactionSettingsQuery: FindDynamicPropertyFieldsSatisfactionSettingsDocument;
	private insertSatisfactionSettingsMutation: InsertSatisfactionSettingsDocument;
	private updateSatisfactionSettingsMutation: UpdateSatisfactionSettingsDocument;
	private importDataSatisfactionSettingsMutation: ImportDataSatisfactionSettingsDocument;
	private updateManySatisfactionSettingsMutation: UpdateManySatisfactionSettingsDocument;
	private deleteSatisfactionSettingsMutation: DeleteSatisfactionSettingsDocument;
	private restoreSatisfactionSettingsMutation: RestoreSatisfactionSettingsDocument;
	private recycleSatisfactionSettingsMutation: RecycleSatisfactionSettingsDocument;
	private restoreArchivedSatisfactionSettingsMutation: RestoreArchivedSatisfactionSettingsDocument;
	private archivedSatisfactionSettingsMutation: ArchivedSatisfactionSettingsDocument;
	private addFileDynamicFieldSatisfactionSettingsMutation: AddFileDynamicFieldSatisfactionSettingsDocument;
	private removeFileDynamicFieldSatisfactionSettingsMutation: RemoveFileDynamicFieldSatisfactionSettingsDocument;

	constructor(private injector: Injector) {
		this.getSatisfactionSettingsQuery = this.injector.get(GetSatisfactionSettingsDocument);
		this.firstSatisfactionSettingsQuery = this.injector.get(FirstSatisfactionSettingsDocument);
		this.countSatisfactionSettingsQuery = this.injector.get(CountSatisfactionSettingsDocument);
		this.findSatisfactionSettingsQuery = this.injector.get(FindSatisfactionSettingsDocument);
		this.searchSatisfactionSettingsQuery = this.injector.get(SearchSatisfactionSettingsDocument);
		this.exportSchemaSatisfactionSettingsQuery = this.injector.get(ExportSchemaSatisfactionSettingsDocument);
		this.exportDataSatisfactionSettingsQuery = this.injector.get(ExportDataSatisfactionSettingsDocument);
		this.customQuerySatisfactionSettingsQuery = this.injector.get(CustomQuerySatisfactionSettingsDocument);
		this.customQueryIdSatisfactionSettingsQuery = this.injector.get(CustomQueryIdSatisfactionSettingsDocument);
		this.usedSatisfactionSettingsQuery = this.injector.get(UsedSatisfactionSettingsDocument);
		this.existSatisfactionSettingsQuery = this.injector.get(ExistSatisfactionSettingsDocument);
		this.findRecyclesSatisfactionSettingsQuery = this.injector.get(FindRecyclesSatisfactionSettingsDocument);
		this.countRecyclesSatisfactionSettingsQuery = this.injector.get(CountRecyclesSatisfactionSettingsDocument);
		this.readOnlySatisfactionSettingsQuery = this.injector.get(ReadOnlySatisfactionSettingsDocument);
		this.findArchivedSatisfactionSettingsQuery = this.injector.get(FindArchivedSatisfactionSettingsDocument);
		this.countAllSatisfactionSettingsQuery = this.injector.get(CountAllSatisfactionSettingsDocument);
		this.findDynamicPropertyFieldsSatisfactionSettingsQuery = this.injector.get(FindDynamicPropertyFieldsSatisfactionSettingsDocument);
		this.insertSatisfactionSettingsMutation = this.injector.get(InsertSatisfactionSettingsDocument);
		this.updateSatisfactionSettingsMutation = this.injector.get(UpdateSatisfactionSettingsDocument);
		this.importDataSatisfactionSettingsMutation = this.injector.get(ImportDataSatisfactionSettingsDocument);
		this.updateManySatisfactionSettingsMutation = this.injector.get(UpdateManySatisfactionSettingsDocument);
		this.deleteSatisfactionSettingsMutation = this.injector.get(DeleteSatisfactionSettingsDocument);
		this.restoreSatisfactionSettingsMutation = this.injector.get(RestoreSatisfactionSettingsDocument);
		this.recycleSatisfactionSettingsMutation = this.injector.get(RecycleSatisfactionSettingsDocument);
		this.restoreArchivedSatisfactionSettingsMutation = this.injector.get(RestoreArchivedSatisfactionSettingsDocument);
		this.archivedSatisfactionSettingsMutation = this.injector.get(ArchivedSatisfactionSettingsDocument);
		this.addFileDynamicFieldSatisfactionSettingsMutation = this.injector.get(AddFileDynamicFieldSatisfactionSettingsDocument);
		this.removeFileDynamicFieldSatisfactionSettingsMutation = this.injector.get(RemoveFileDynamicFieldSatisfactionSettingsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSatisfactionSetting> {
    
    		let variables: GetSatisfactionSettingsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.get));
            }
            else{
                let result:ServiceSingleResultOfSatisfactionSetting={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSatisfactionSetting> {
    
    		let variables: FirstSatisfactionSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSatisfactionSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: FindSatisfactionSettingsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: SearchSatisfactionSettingsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSatisfactionSettingsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSatisfactionSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: CustomQuerySatisfactionSettingsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: CustomQueryIdSatisfactionSettingsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSatisfactionSettingsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: FindRecyclesSatisfactionSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesSatisfactionSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlySatisfactionSettingsBaseVariables = {
    			id: id
    		}
    				return this.readOnlySatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSatisfactionSetting,
		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSatisfactionSetting> {
    
    		let variables: FindArchivedSatisfactionSettingsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllSatisfactionSettingsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsSatisfactionSettingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsSatisfactionSettingsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.findDynamicPropertyFields));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SatisfactionSettingInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSatisfactionSetting> {
    
    		let variables: InsertSatisfactionSettingsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSatisfactionSetting> {
    
    		let variables: UpdateSatisfactionSettingsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSatisfactionSettingsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSatisfactionSetting,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySatisfactionSettingsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.delete));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedSatisfactionSettingsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldSatisfactionSettingsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldSatisfactionSettingsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldSatisfactionSettingsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.satisfactionSettings.removeFileDynamicField));
    	}
    
}