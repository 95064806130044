import { Component } from '@angular/core';

/** Représente la page de licence non valide. */
@Component({
  selector: 'cl-no-license',
  templateUrl: './no-license.component.html'
})
export class NoLicenseComponent {

  constructor() { }
}
