<div
  class="element-main"
  [ngStyle]="{ display: display === true ? 'block' : 'none' }"
>
  <div class="element-header">
    <span class="element-header-span-name">{{ fileName }}</span>
    <span class="element-header-span-download" (click)="downloadFile($event)">
      <i
        class="fal fa-file-download"
        [title]="'entities/files/download' | translate"
      ></i>
    </span>
    <span
      *ngIf="closable == true"
      class="fal fa-times element-header-span-close"
      (click)="closeViewer($event)"
    ></span>
  </div>

  <div
    class="element-navigation element-navigation-next"
    [ngStyle]="{ display: displayNext === true ? 'block' : 'none' }"
    (click)="nextFile($event)"
  >
    <span
      class="fal fa-chevron-right element-navigation-span element-navigation-span-next"
    ></span>
  </div>
  <div
    class="element-navigation element-navigation-previous"
    [ngStyle]="{ display: displayPrevious === true ? 'block' : 'none' }"
    (click)="previousFile($event)"
  >
    <span
      class="fal fa-chevron-left element-navigation-span element-navigation-span-previous"
    ></span>
  </div>

  <div class="fileContent"><div></div></div>

  <div class="element-error">
    <div class="element-error-div">
      <i class="fal fa-file-times element-error-icon"></i>
      <p>{{ 'entities/files/msgPreviewError' | translate }}</p>

      <p class="button-download-file">
        <span class="element-download-icon">
          <i class="fal fa-file-download"></i>
          <span class="element-download-label">{{
            'entities/files/download' | translate
          }}</span>
        </span>
      </p>
    </div>
  </div>
</div>
