import { GqlField, GqlSubField } from '../../helpers';
import { UserCoreService } from '../user.service';
import { Observable } from 'rxjs/internal/Observable';
import { of } from 'rxjs/internal/observable/of';
import { map } from 'rxjs/operators';
import { BaseStorageService } from './base-storage-service';
import {
  ListDesignerType,
  ServiceListResultOfModelRulesDesigner,
  ServiceSingleResultOfListDesigner,
  ServiceSingleResultOfPrivilege,
  ServiceSingleResultOfUserPrivilege,
} from '../../types';
import { ModelRulesDesignerCoreService } from '../model-rules-designer.service';
import { Injector } from '@angular/core';
import { ListDesignerCoreService } from '../list-designer.service';

/** Classe de sauvegarde des données de champs perso */
export class UserStorageService extends BaseStorageService {
  private _isOperator: boolean = undefined;
  private _userPrivilege: ServiceSingleResultOfPrivilege = undefined;
  private _helpDeskPrivilege: ServiceSingleResultOfUserPrivilege = undefined;
  private _incidentModelRule: ServiceListResultOfModelRulesDesigner = undefined;
  private _requestModelRule: ServiceListResultOfModelRulesDesigner = undefined;
  private _problemModelRule: ServiceListResultOfModelRulesDesigner = undefined;

  private _incidentListHelpMe: any = undefined;
  private _requestListHelpMe: any = undefined;
  private _incidentOperatorListDesigner: any = undefined;
  private _requestOperatorListDesigner: any = undefined;
  private _problemOperatorListDesigner: any = undefined;

  constructor(
    userService: UserCoreService,
    userId: string,
    private modelRulesDesignerService: ModelRulesDesignerCoreService,
    private listDesignerCoreService: ListDesignerCoreService,
    private serviceInjector: Injector,
  ) {
    super(userService, userId);
  }

  /** Reset */
  reset() {
    this._incidentModelRule = undefined;
    this._requestModelRule = undefined;
  }

  /** Obtient si il s'agit d'un opérateur */
  isOperator(): Observable<boolean> {
    if (this._isOperator == undefined) {
      return this._userService
        .get(
          [GqlSubField.create('data', [GqlField.create('isOperator')])],
          this._userId,
        )
        .pipe(
          map((res) => {
            this._isOperator = res?.data?.isOperator === true;
            return this._isOperator;
          }),
        );
    } else {
      return of(this._isOperator);
    }
  }

  /** Privilige operateur */
  getPrivilege(): Observable<ServiceSingleResultOfPrivilege> {
    if (this._userPrivilege == undefined) {
      return this._userService.getPrivilege().pipe(
        map((res) => {
          this._userPrivilege = res;
          return JSON.parse(JSON.stringify(this._userPrivilege));
        }),
      );
    } else {
      return of(JSON.parse(JSON.stringify(this._userPrivilege)));
    }
  }

  getHelpDeskPrivilege(): Observable<ServiceSingleResultOfUserPrivilege> {
    if (this._helpDeskPrivilege == undefined) {
      return this._userService.getHelpDeskPrivilege().pipe(
        map((res) => {
          this._helpDeskPrivilege = res;
          return JSON.parse(JSON.stringify(this._helpDeskPrivilege));
        }),
      );
    } else {
      return of(JSON.parse(JSON.stringify(this._helpDeskPrivilege)));
    }
  }

  /** Fusionne les champs personnalisé */
  mergeDynamicField(
    data: ServiceListResultOfModelRulesDesigner,
  ): ServiceListResultOfModelRulesDesigner {
    if (data.data != undefined) {
      data.data.forEach((e) => {
        if (
          e.dynamicFieldEditRules != undefined &&
          e.dynamicFieldEditRules.length > 0
        ) {
          e.editRules = e.editRules.concat(e.dynamicFieldEditRules);
        }

        if (
          e.dynamicFieldRules != undefined &&
          e.dynamicFieldRules.length > 0
        ) {
          e.rules = e.rules.concat(e.dynamicFieldRules);
        }
      });
    }

    return data;
  }

  /** Model rule incident */
  getIncidentModelRule(): Observable<ServiceListResultOfModelRulesDesigner> {
    if (this._incidentModelRule == undefined) {
      let service = this.serviceInjector.get(ModelRulesDesignerCoreService);
      return this.modelRulesDesignerService
        .find(service.getFields(), null, {
          and: [
            { operatorIds: { elemMatch: { in: [this._userId] } } },
            { qualification: { elemMatch: { in: ['incident'] } } },
            { type: { eq: 'operator-incident' } },
          ],
        })
        .pipe(
          map((res) => {
            this._incidentModelRule = this.mergeDynamicField(res);
            return JSON.parse(JSON.stringify(this._incidentModelRule));
          }),
        );
    } else {
      return of(JSON.parse(JSON.stringify(this._incidentModelRule)));
    }
  }

  /** Model rule incident */
  getRequestModelRule(): Observable<ServiceListResultOfModelRulesDesigner> {
    if (this._requestModelRule == undefined) {
      let service = this.serviceInjector.get(ModelRulesDesignerCoreService);

      return this.modelRulesDesignerService
        .find(service.getFields(), null, {
          and: [
            { operatorIds: { elemMatch: { in: [this._userId] } } },
            { qualification: { elemMatch: { in: ['request'] } } },
            { type: { eq: 'operator-request' } },
          ],
        })
        .pipe(
          map((res) => {
            this._requestModelRule = this.mergeDynamicField(res);
            return this._requestModelRule;
          }),
        );
    } else {
      return of(this._requestModelRule);
    }
  }

  /** Model rule des problème */
  getProblemModelRule(): Observable<ServiceListResultOfModelRulesDesigner> {
    if (this._problemModelRule == undefined) {
      let service = this.serviceInjector.get(ModelRulesDesignerCoreService);
      return this.modelRulesDesignerService
        .find(service.getFields(), null, {
          and: [
            { operatorIds: { elemMatch: { in: [this._userId] } } },
            { qualification: { elemMatch: { in: ['problem'] } } },
            { type: { eq: 'operator-problem' } },
          ],
        })
        .pipe(
          map((res) => {
            this._problemModelRule = this.mergeDynamicField(res);
            return JSON.parse(JSON.stringify(this._problemModelRule));
          }),
        );
    } else {
      return of(JSON.parse(JSON.stringify(this._problemModelRule)));
    }
  }

  getTicketModelRule(qualification: string) {
    switch (qualification) {
      case 'incident':
        return this.getIncidentModelRule();
      case 'request':
        return this.getRequestModelRule();
      case 'problem':
        return this.getProblemModelRule();
    }
  }

  /** Colonne d'affichage (Operator / Help Me) */
  private async getListDesigner(
    field: string,
  ): Promise<ServiceSingleResultOfListDesigner> {
    let userInfo = await this._userService
      .get([GqlSubField.create('data', [GqlField.create(field)])], this._userId)
      .toPromise();

    if (userInfo?.data != undefined && userInfo?.data[field] != undefined) {
      let model = await this.listDesignerCoreService
        .get(
          [
            GqlSubField.create('data', [
              GqlField.create('columns'),
              GqlField.create('qualification'),
              GqlField.create('listType'),
            ]),
          ],
          userInfo.data[field],
        )
        .toPromise();

      return model;
    }

    return undefined;
  }

  /** Obtient le layout de la liste */
  // async getListLayoput(model: { [k: string]: any }, qualification: string) {
  async getListLayout(
    model: { [k: string]: any },
    qualification: string,
    listType: ListDesignerType,
  ) {
    let col: ServiceSingleResultOfListDesigner;

    // Si deja chargé
    if (qualification == 'incident') {
      if (
        listType == ListDesignerType.HelpDeskUser &&
        this._incidentListHelpMe != undefined
      ) {
        model.grid.layout.columns = this._incidentListHelpMe;
        return model;
      } else if (
        listType == ListDesignerType.HelpDeskOperator &&
        this._incidentOperatorListDesigner != undefined
      ) {
        model.grid.layout.columns = this._incidentOperatorListDesigner;
        return model;
      }
    } else if (qualification == 'request') {
      if (
        listType == ListDesignerType.HelpDeskUser &&
        this._requestListHelpMe != undefined
      ) {
        model.grid.layout.columns = this._requestListHelpMe;
        return model;
      } else if (
        listType == ListDesignerType.HelpDeskOperator &&
        this._requestOperatorListDesigner != undefined
      ) {
        model.grid.layout.columns = this._requestOperatorListDesigner;
        return model;
      }
    } else if (
      qualification == 'problem' &&
      listType == ListDesignerType.HelpDeskOperator &&
      this._problemOperatorListDesigner != undefined
    ) {
      model.grid.layout.columns = this._problemOperatorListDesigner;
      return model;
    }

    //Chargement
    if (qualification == 'incident') {
      col = await this.getListDesigner(
        listType == ListDesignerType.HelpDeskUser
          ? 'userIncidentListDesignerId'
          : 'operatorIncidentListDesignerId',
      );
    } else if (qualification == 'request') {
      col = await this.getListDesigner(
        listType == ListDesignerType.HelpDeskUser
          ? 'userRequestListDesignerId'
          : 'operatorRequestListDesignerId',
      );
    } else if (
      qualification == 'problem' &&
      listType == ListDesignerType.HelpDeskOperator
    ) {
      col = await this.getListDesigner('operatorProblemListDesignerId');
    }

    if (col?.data?.columns != undefined) {
      let myCol = JSON.parse(col.data.columns);
      let modelCol = model?.grid?.layout?.columns;
      if (modelCol != undefined) {
        // Par defaut non visible
        modelCol.forEach((f) => {
          f.visible = false;
          f.sortOrder = undefined;
          f.sortIndex = undefined;
          f.groupIndex = undefined;
        });

        let finalCol = [];
        // Ordre & visibilité
        myCol = myCol.sort(function (a, b) {
          return a.visibleIndex - b.visibleIndex;
        });

        myCol.forEach((element) => {
          let find = modelCol.find(
            (f) =>
              f.field == element.dataField ||
              (element.dataField.startsWith(f.field) && f.translatable),
          );

          if (find != undefined) {
            // Trie
            if (element.sortIndex != undefined) {
              find.sortOrder = element.sortOrder;
              find.sortIndex = element.sortIndex;
            }
            //  Group
            if (element.groupIndex != undefined) {
              find.groupIndex = element.groupIndex;
            }
            find.visible = true;
            finalCol.push(find);
          }
        });

        // Ajout des manquant (pour les avoir dans le choix des colonne)
        modelCol.forEach((f) => {
          let find = finalCol.find((element) => f.field == element.field);
          if (find == undefined) {
            finalCol.push(f);
          }
        });

        model.grid.layout.columns = finalCol;
      }
    }

    // Save
    if (qualification == 'incident') {
      if (listType == ListDesignerType.HelpDeskUser) {
        this._incidentListHelpMe = model?.grid?.layout?.columns;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        this._incidentOperatorListDesigner = model?.grid?.layout?.columns;
      }
    } else if (qualification == 'request') {
      if (listType == ListDesignerType.HelpDeskUser) {
        this._requestListHelpMe = model?.grid?.layout?.columns;
      } else if (listType == ListDesignerType.HelpDeskOperator) {
        this._requestOperatorListDesigner = model?.grid?.layout?.columns;
      }
    } else if (
      qualification == 'problem' &&
      listType == ListDesignerType.HelpDeskOperator
    ) {
      this._problemOperatorListDesigner = model?.grid?.layout?.columns;
    }

    return model;
  }
}
