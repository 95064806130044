import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetTicketBacklogsBaseVariables, FirstTicketBacklogsBaseVariables, CountTicketBacklogsBaseVariables, FindTicketBacklogsBaseVariables, SearchTicketBacklogsBaseVariables, ExportSchemaTicketBacklogsBaseVariables, ExportDataTicketBacklogsBaseVariables, CustomQueryTicketBacklogsBaseVariables, CustomQueryIdTicketBacklogsBaseVariables, UsedTicketBacklogsBaseVariables, ExistTicketBacklogsBaseVariables, UpdateManyTicketBacklogsBaseVariables, DeleteTicketBacklogsBaseVariables } from '../gqls'
import { GetTicketBacklogsDocument, FirstTicketBacklogsDocument, CountTicketBacklogsDocument, FindTicketBacklogsDocument, SearchTicketBacklogsDocument, ExportSchemaTicketBacklogsDocument, ExportDataTicketBacklogsDocument, CustomQueryTicketBacklogsDocument, CustomQueryIdTicketBacklogsDocument, UsedTicketBacklogsDocument, ExistTicketBacklogsDocument, UpdateManyTicketBacklogsDocument, DeleteTicketBacklogsDocument } from '../gqls'
import { ServiceSingleResultOfTicketBacklog, QueryContextOfTicketBacklog, FilterOfTicketBacklog, ServiceSingleResultOfInt64, ServiceListResultOfTicketBacklog, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfTicketBacklog } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class TicketBacklogBaseService {
    
public modelName = 'ticketBacklog';
public modelPluralName = 'ticketBacklogs';

	private getTicketBacklogsQuery: GetTicketBacklogsDocument;
	private firstTicketBacklogsQuery: FirstTicketBacklogsDocument;
	private countTicketBacklogsQuery: CountTicketBacklogsDocument;
	private findTicketBacklogsQuery: FindTicketBacklogsDocument;
	private searchTicketBacklogsQuery: SearchTicketBacklogsDocument;
	private exportSchemaTicketBacklogsQuery: ExportSchemaTicketBacklogsDocument;
	private exportDataTicketBacklogsQuery: ExportDataTicketBacklogsDocument;
	private customQueryTicketBacklogsQuery: CustomQueryTicketBacklogsDocument;
	private customQueryIdTicketBacklogsQuery: CustomQueryIdTicketBacklogsDocument;
	private usedTicketBacklogsQuery: UsedTicketBacklogsDocument;
	private existTicketBacklogsQuery: ExistTicketBacklogsDocument;
	private updateManyTicketBacklogsMutation: UpdateManyTicketBacklogsDocument;
	private deleteTicketBacklogsMutation: DeleteTicketBacklogsDocument;

	constructor(private injector: Injector) {
		this.getTicketBacklogsQuery = this.injector.get(GetTicketBacklogsDocument);
		this.firstTicketBacklogsQuery = this.injector.get(FirstTicketBacklogsDocument);
		this.countTicketBacklogsQuery = this.injector.get(CountTicketBacklogsDocument);
		this.findTicketBacklogsQuery = this.injector.get(FindTicketBacklogsDocument);
		this.searchTicketBacklogsQuery = this.injector.get(SearchTicketBacklogsDocument);
		this.exportSchemaTicketBacklogsQuery = this.injector.get(ExportSchemaTicketBacklogsDocument);
		this.exportDataTicketBacklogsQuery = this.injector.get(ExportDataTicketBacklogsDocument);
		this.customQueryTicketBacklogsQuery = this.injector.get(CustomQueryTicketBacklogsDocument);
		this.customQueryIdTicketBacklogsQuery = this.injector.get(CustomQueryIdTicketBacklogsDocument);
		this.usedTicketBacklogsQuery = this.injector.get(UsedTicketBacklogsDocument);
		this.existTicketBacklogsQuery = this.injector.get(ExistTicketBacklogsDocument);
		this.updateManyTicketBacklogsMutation = this.injector.get(UpdateManyTicketBacklogsDocument);
		this.deleteTicketBacklogsMutation = this.injector.get(DeleteTicketBacklogsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketBacklog> {
    
    		let variables: GetTicketBacklogsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.get));
            }
            else{
                let result:ServiceSingleResultOfTicketBacklog={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfTicketBacklog> {
    
    		let variables: FirstTicketBacklogsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountTicketBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.countTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketBacklog> {
    
    		let variables: FindTicketBacklogsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketBacklog> {
    
    		let variables: SearchTicketBacklogsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaTicketBacklogsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataTicketBacklogsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketBacklog> {
    
    		let variables: CustomQueryTicketBacklogsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfTicketBacklog,
		@Args('filter?') filter?: FilterOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicketBacklog> {
    
    		let variables: CustomQueryIdTicketBacklogsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedTicketBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.usedTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistTicketBacklogsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existTicketBacklogsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfTicketBacklog,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyTicketBacklogsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyTicketBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteTicketBacklogsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteTicketBacklogsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.ticketBacklogs.delete));
    	}
    
}