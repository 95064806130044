import { ScanExcludeBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import { TranslateService } from '@clarilog/shared2/services/translate/translate.service';
import {
  InjectArgs,
  Args,
} from '@clarilog/core/modules/decorators/args-decorator';
import { ScanConfigurationCoreService } from './scan-configuration.service';
import { Observable } from 'rxjs';
import { GqlField, GqlSubField } from '../helpers';
import { map } from 'rxjs/operators';
import {
  FilterOfScanConfiguration,
  QueryContextOfScanConfiguration,
  ServiceListResultOfScanConfiguration,
} from '../types';

@Injectable({ providedIn: 'root' })
@Authorize('scan-configuration.manage-scan-exclude')
export class ScanExcludeCoreService extends ScanExcludeBaseService {
  constructor(
    injector: Injector,
    private scanConfigurationService: ScanConfigurationCoreService,
  ) {
    super(injector);
  }

  scanExcludeOperator(): any[] {
    return [
      {
        id: 'EQUALS',
        value: TranslateService.get(`entities/scanExclude/equals`),
      },
      {
        id: 'NOT_EQUALS',
        value: TranslateService.get(`entities/scanExclude/notEquals`),
      },
    ];
  }

  @InjectArgs
  public findNotScanConfigurationsCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.findNotScanConfigurations(
      fields,
      options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }

  /**  */
  @InjectArgs
  public scanConfigurationsByTypeCustom(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfScanConfiguration,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfScanConfiguration,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfScanConfiguration> {
    let dataOption =
      this.scanConfigurationService.cleanAndRetrieveNewOption(options);
    return this.scanConfigurationsByType(
      fields,
      dataOption.options,
      id,
      filter,
      extendedVariables,
    ).pipe(
      map((result) => {
        return this.scanConfigurationService.orderResultByType(
          result,
          dataOption.sortTypenameValue,
        );
      }),
    );
  }
}
