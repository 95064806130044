import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetImpactsBaseVariables, FirstImpactsBaseVariables, CountImpactsBaseVariables, FindImpactsBaseVariables, SearchImpactsBaseVariables, ExportSchemaImpactsBaseVariables, ExportDataImpactsBaseVariables, CustomQueryImpactsBaseVariables, CustomQueryIdImpactsBaseVariables, FindUnassociatedSecurityGroupsImpactsBaseVariables, FindAssociatedOperatorTeamSecurityGroupsImpactsBaseVariables, FindAssociatedPopulationSecurityGroupsImpactsBaseVariables, UsedImpactsBaseVariables, ExistImpactsBaseVariables, FindRecyclesImpactsBaseVariables, CountRecyclesImpactsBaseVariables, ReadOnlyImpactsBaseVariables, FindArchivedImpactsBaseVariables, CountAllImpactsBaseVariables, FindDynamicPropertyFieldsImpactsBaseVariables, FindUnassociatedTicketsImpactsBaseVariables, FindUnassociatedIncidentModelsImpactsBaseVariables, InsertImpactsBaseVariables, UpdateImpactsBaseVariables, ImportDataImpactsBaseVariables, UpdateManyImpactsBaseVariables, DeleteImpactsBaseVariables, OrderImpactsBaseVariables, AddSecurityGroupImpactsBaseVariables, RemoveSecurityGroupImpactsBaseVariables, AddOperatorTeamSecurityGroupImpactsBaseVariables, RemoveOperatorTeamSecurityGroupImpactsBaseVariables, AddPopulationSecurityGroupImpactsBaseVariables, RemovePopulationSecurityGroupImpactsBaseVariables, RestoreImpactsBaseVariables, RecycleImpactsBaseVariables, RestoreArchivedImpactsBaseVariables, ArchivedImpactsBaseVariables, AddFileDynamicFieldImpactsBaseVariables, RemoveFileDynamicFieldImpactsBaseVariables, AddIncidentModelsImpactsBaseVariables, RemoveIncidentModelsImpactsBaseVariables, AddTicketsImpactsBaseVariables, RemoveTicketsImpactsBaseVariables } from '../gqls'
import { GetImpactsDocument, FirstImpactsDocument, CountImpactsDocument, FindImpactsDocument, SearchImpactsDocument, ExportSchemaImpactsDocument, ExportDataImpactsDocument, CustomQueryImpactsDocument, CustomQueryIdImpactsDocument, FindUnassociatedSecurityGroupsImpactsDocument, FindAssociatedOperatorTeamSecurityGroupsImpactsDocument, FindAssociatedPopulationSecurityGroupsImpactsDocument, UsedImpactsDocument, ExistImpactsDocument, FindRecyclesImpactsDocument, CountRecyclesImpactsDocument, ReadOnlyImpactsDocument, FindArchivedImpactsDocument, CountAllImpactsDocument, FindDynamicPropertyFieldsImpactsDocument, FindUnassociatedTicketsImpactsDocument, FindUnassociatedIncidentModelsImpactsDocument, InsertImpactsDocument, UpdateImpactsDocument, ImportDataImpactsDocument, UpdateManyImpactsDocument, DeleteImpactsDocument, OrderImpactsDocument, AddSecurityGroupImpactsDocument, RemoveSecurityGroupImpactsDocument, AddOperatorTeamSecurityGroupImpactsDocument, RemoveOperatorTeamSecurityGroupImpactsDocument, AddPopulationSecurityGroupImpactsDocument, RemovePopulationSecurityGroupImpactsDocument, RestoreImpactsDocument, RecycleImpactsDocument, RestoreArchivedImpactsDocument, ArchivedImpactsDocument, AddFileDynamicFieldImpactsDocument, RemoveFileDynamicFieldImpactsDocument, AddIncidentModelsImpactsDocument, RemoveIncidentModelsImpactsDocument, AddTicketsImpactsDocument, RemoveTicketsImpactsDocument } from '../gqls'
import { ServiceSingleResultOfImpact, QueryContextOfImpact, FilterOfImpact, ServiceSingleResultOfInt64, ServiceListResultOfImpact, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, QueryContextOfSecurityGroup, FilterOfSecurityGroup, ServiceListResultOfSecurityGroup, ServiceListResultOfOperatorTeam, ServiceListResultOfPopulation, ServiceSingleResultOfBoolean, ServiceSingleResultOfEntityAttribute, ServiceListResultOfDynamicPropertyField, FieldUpdateOperatorOfImpact, QueryContextOfTicket, FilterOfTicket, ServiceListResultOfTicket, QueryContextOfIncidentModel, FilterOfIncidentModel, ServiceListResultOfIncidentModel, ImpactInput } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class ImpactBaseService {
    
public modelName = 'impact';
public modelPluralName = 'impacts';

	private getImpactsQuery: GetImpactsDocument;
	private firstImpactsQuery: FirstImpactsDocument;
	private countImpactsQuery: CountImpactsDocument;
	private findImpactsQuery: FindImpactsDocument;
	private searchImpactsQuery: SearchImpactsDocument;
	private exportSchemaImpactsQuery: ExportSchemaImpactsDocument;
	private exportDataImpactsQuery: ExportDataImpactsDocument;
	private customQueryImpactsQuery: CustomQueryImpactsDocument;
	private customQueryIdImpactsQuery: CustomQueryIdImpactsDocument;
	private findUnassociatedSecurityGroupsImpactsQuery: FindUnassociatedSecurityGroupsImpactsDocument;
	private findAssociatedOperatorTeamSecurityGroupsImpactsQuery: FindAssociatedOperatorTeamSecurityGroupsImpactsDocument;
	private findAssociatedPopulationSecurityGroupsImpactsQuery: FindAssociatedPopulationSecurityGroupsImpactsDocument;
	private usedImpactsQuery: UsedImpactsDocument;
	private existImpactsQuery: ExistImpactsDocument;
	private findRecyclesImpactsQuery: FindRecyclesImpactsDocument;
	private countRecyclesImpactsQuery: CountRecyclesImpactsDocument;
	private readOnlyImpactsQuery: ReadOnlyImpactsDocument;
	private findArchivedImpactsQuery: FindArchivedImpactsDocument;
	private countAllImpactsQuery: CountAllImpactsDocument;
	private findDynamicPropertyFieldsImpactsQuery: FindDynamicPropertyFieldsImpactsDocument;
	private findUnassociatedTicketsImpactsQuery: FindUnassociatedTicketsImpactsDocument;
	private findUnassociatedIncidentModelsImpactsQuery: FindUnassociatedIncidentModelsImpactsDocument;
	private insertImpactsMutation: InsertImpactsDocument;
	private updateImpactsMutation: UpdateImpactsDocument;
	private importDataImpactsMutation: ImportDataImpactsDocument;
	private updateManyImpactsMutation: UpdateManyImpactsDocument;
	private deleteImpactsMutation: DeleteImpactsDocument;
	private orderImpactsMutation: OrderImpactsDocument;
	private addSecurityGroupImpactsMutation: AddSecurityGroupImpactsDocument;
	private removeSecurityGroupImpactsMutation: RemoveSecurityGroupImpactsDocument;
	private addOperatorTeamSecurityGroupImpactsMutation: AddOperatorTeamSecurityGroupImpactsDocument;
	private removeOperatorTeamSecurityGroupImpactsMutation: RemoveOperatorTeamSecurityGroupImpactsDocument;
	private addPopulationSecurityGroupImpactsMutation: AddPopulationSecurityGroupImpactsDocument;
	private removePopulationSecurityGroupImpactsMutation: RemovePopulationSecurityGroupImpactsDocument;
	private restoreImpactsMutation: RestoreImpactsDocument;
	private recycleImpactsMutation: RecycleImpactsDocument;
	private restoreArchivedImpactsMutation: RestoreArchivedImpactsDocument;
	private archivedImpactsMutation: ArchivedImpactsDocument;
	private addFileDynamicFieldImpactsMutation: AddFileDynamicFieldImpactsDocument;
	private removeFileDynamicFieldImpactsMutation: RemoveFileDynamicFieldImpactsDocument;
	private addIncidentModelsImpactsMutation: AddIncidentModelsImpactsDocument;
	private removeIncidentModelsImpactsMutation: RemoveIncidentModelsImpactsDocument;
	private addTicketsImpactsMutation: AddTicketsImpactsDocument;
	private removeTicketsImpactsMutation: RemoveTicketsImpactsDocument;

	constructor(private injector: Injector) {
		this.getImpactsQuery = this.injector.get(GetImpactsDocument);
		this.firstImpactsQuery = this.injector.get(FirstImpactsDocument);
		this.countImpactsQuery = this.injector.get(CountImpactsDocument);
		this.findImpactsQuery = this.injector.get(FindImpactsDocument);
		this.searchImpactsQuery = this.injector.get(SearchImpactsDocument);
		this.exportSchemaImpactsQuery = this.injector.get(ExportSchemaImpactsDocument);
		this.exportDataImpactsQuery = this.injector.get(ExportDataImpactsDocument);
		this.customQueryImpactsQuery = this.injector.get(CustomQueryImpactsDocument);
		this.customQueryIdImpactsQuery = this.injector.get(CustomQueryIdImpactsDocument);
		this.findUnassociatedSecurityGroupsImpactsQuery = this.injector.get(FindUnassociatedSecurityGroupsImpactsDocument);
		this.findAssociatedOperatorTeamSecurityGroupsImpactsQuery = this.injector.get(FindAssociatedOperatorTeamSecurityGroupsImpactsDocument);
		this.findAssociatedPopulationSecurityGroupsImpactsQuery = this.injector.get(FindAssociatedPopulationSecurityGroupsImpactsDocument);
		this.usedImpactsQuery = this.injector.get(UsedImpactsDocument);
		this.existImpactsQuery = this.injector.get(ExistImpactsDocument);
		this.findRecyclesImpactsQuery = this.injector.get(FindRecyclesImpactsDocument);
		this.countRecyclesImpactsQuery = this.injector.get(CountRecyclesImpactsDocument);
		this.readOnlyImpactsQuery = this.injector.get(ReadOnlyImpactsDocument);
		this.findArchivedImpactsQuery = this.injector.get(FindArchivedImpactsDocument);
		this.countAllImpactsQuery = this.injector.get(CountAllImpactsDocument);
		this.findDynamicPropertyFieldsImpactsQuery = this.injector.get(FindDynamicPropertyFieldsImpactsDocument);
		this.findUnassociatedTicketsImpactsQuery = this.injector.get(FindUnassociatedTicketsImpactsDocument);
		this.findUnassociatedIncidentModelsImpactsQuery = this.injector.get(FindUnassociatedIncidentModelsImpactsDocument);
		this.insertImpactsMutation = this.injector.get(InsertImpactsDocument);
		this.updateImpactsMutation = this.injector.get(UpdateImpactsDocument);
		this.importDataImpactsMutation = this.injector.get(ImportDataImpactsDocument);
		this.updateManyImpactsMutation = this.injector.get(UpdateManyImpactsDocument);
		this.deleteImpactsMutation = this.injector.get(DeleteImpactsDocument);
		this.orderImpactsMutation = this.injector.get(OrderImpactsDocument);
		this.addSecurityGroupImpactsMutation = this.injector.get(AddSecurityGroupImpactsDocument);
		this.removeSecurityGroupImpactsMutation = this.injector.get(RemoveSecurityGroupImpactsDocument);
		this.addOperatorTeamSecurityGroupImpactsMutation = this.injector.get(AddOperatorTeamSecurityGroupImpactsDocument);
		this.removeOperatorTeamSecurityGroupImpactsMutation = this.injector.get(RemoveOperatorTeamSecurityGroupImpactsDocument);
		this.addPopulationSecurityGroupImpactsMutation = this.injector.get(AddPopulationSecurityGroupImpactsDocument);
		this.removePopulationSecurityGroupImpactsMutation = this.injector.get(RemovePopulationSecurityGroupImpactsDocument);
		this.restoreImpactsMutation = this.injector.get(RestoreImpactsDocument);
		this.recycleImpactsMutation = this.injector.get(RecycleImpactsDocument);
		this.restoreArchivedImpactsMutation = this.injector.get(RestoreArchivedImpactsDocument);
		this.archivedImpactsMutation = this.injector.get(ArchivedImpactsDocument);
		this.addFileDynamicFieldImpactsMutation = this.injector.get(AddFileDynamicFieldImpactsDocument);
		this.removeFileDynamicFieldImpactsMutation = this.injector.get(RemoveFileDynamicFieldImpactsDocument);
		this.addIncidentModelsImpactsMutation = this.injector.get(AddIncidentModelsImpactsDocument);
		this.removeIncidentModelsImpactsMutation = this.injector.get(RemoveIncidentModelsImpactsDocument);
		this.addTicketsImpactsMutation = this.injector.get(AddTicketsImpactsDocument);
		this.removeTicketsImpactsMutation = this.injector.get(RemoveTicketsImpactsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImpact> {
    
    		let variables: GetImpactsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.get));
            }
            else{
                let result:ServiceSingleResultOfImpact={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImpact> {
    
    		let variables: FirstImpactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountImpactsBaseVariables = {
    			filter: filter
    		}
    				return this.countImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: FindImpactsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfImpact,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: SearchImpactsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaImpactsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataImpactsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: CustomQueryImpactsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: CustomQueryIdImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.customQueryId));
    	}

	@InjectArgs
	public findAssociatedSecurityGroups(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSecurityGroup> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('securityGroups', fields, null, [
			GqlSubFieldArg.create('filterOfSecurityGroups', 'filter'),
			GqlSubFieldArg.create('optionsOfSecurityGroups', 'options'),
		]),
		])
		extendedVariables['filterOfSecurityGroups'] = filter;
		extendedVariables['optionsOfSecurityGroups'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.securityGroups));
            }
            else{
                let result: ServiceListResultOfSecurityGroup = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/** Récupère les profils non liés de l'entité. */
    	@InjectArgs
    	public findUnassociatedSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSecurityGroup> {
    
    		let variables: FindUnassociatedSecurityGroupsImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSecurityGroupsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findUnassociatedSecurityGroups));
    	}

    	/** Récupère les equipes d'operateur via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedOperatorTeamSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfOperatorTeam> {
    
    		let variables: FindAssociatedOperatorTeamSecurityGroupsImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedOperatorTeamSecurityGroupsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findAssociatedOperatorTeamSecurityGroups));
    	}

    	/** Récupère les population via les profils de l'entité. */
    	@InjectArgs
    	public findAssociatedPopulationSecurityGroups(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSecurityGroup,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSecurityGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfPopulation> {
    
    		let variables: FindAssociatedPopulationSecurityGroupsImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findAssociatedPopulationSecurityGroupsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findAssociatedPopulationSecurityGroups));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.usedImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistImpactsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.exist));
    	}

    	/** Récupère les entités mis en corbeille selon le filtre. */
    	@InjectArgs
    	public findRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: FindRecyclesImpactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findRecyclesImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findRecycles));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countRecycles(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountRecyclesImpactsBaseVariables = {
    			filter: filter
    		}
    				return this.countRecyclesImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.countRecycles));
    	}

    	/** Vérifie si l'entité est en lecture seule. */
    	@InjectArgs
    	public readOnly(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfEntityAttribute> {
    
    		let variables: ReadOnlyImpactsBaseVariables = {
    			id: id
    		}
    				return this.readOnlyImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.readOnly));
    	}

    	/** Récupère les entités archivées selon le filtre. */
    	@InjectArgs
    	public findArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfImpact,
		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfImpact> {
    
    		let variables: FindArchivedImpactsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.findArchivedImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findArchived));
    	}

    	/** Compte le nombre d'entité mis en corbeille selon le filtre. */
    	@InjectArgs
    	public countAll(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountAllImpactsBaseVariables = {
    			filter: filter
    		}
    				return this.countAllImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.countAll));
    	}

    	/**  */
    	@InjectArgs
    	public findDynamicPropertyFields(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id?') id?: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfDynamicPropertyField> {
    
    		let variables: FindDynamicPropertyFieldsImpactsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.findDynamicPropertyFieldsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findDynamicPropertyFields));
    	}

	@InjectArgs
	public findAssociatedTickets(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfTicket> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('tickets', fields, null, [
			GqlSubFieldArg.create('filterOfTickets', 'filter'),
			GqlSubFieldArg.create('optionsOfTickets', 'options'),
		]),
		])
		extendedVariables['filterOfTickets'] = filter;
		extendedVariables['optionsOfTickets'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.tickets));
            }
            else{
                let result: ServiceListResultOfTicket = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfTicket,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfTicket,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfTicket> {
    
    		let variables: FindUnassociatedTicketsImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedTicketsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findUnassociatedTickets));
    	}

	@InjectArgs
	public findAssociatedIncidentModels(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfIncidentModel> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('incidentModels', fields, null, [
			GqlSubFieldArg.create('filterOfIncidentModels', 'filter'),
			GqlSubFieldArg.create('optionsOfIncidentModels', 'options'),
		]),
		])
		extendedVariables['filterOfIncidentModels'] = filter;
		extendedVariables['optionsOfIncidentModels'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.incidentModels));
            }
            else{
                let result: ServiceListResultOfIncidentModel = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfIncidentModel,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfIncidentModel,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfIncidentModel> {
    
    		let variables: FindUnassociatedIncidentModelsImpactsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedIncidentModelsImpactsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.findUnassociatedIncidentModels));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: ImpactInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImpact> {
    
    		let variables: InsertImpactsBaseVariables = {
    			entity: entity
    		}
    				return this.insertImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfImpact> {
    
    		let variables: UpdateImpactsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataImpactsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfImpact,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyImpactsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.delete));
    	}

    	/** Permet d'ordonner l'entité. */
    	@InjectArgs
    	public order(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('fromId') fromId: string,
		@Args('toId?') toId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: OrderImpactsBaseVariables = {
    			fromId: fromId,
			toId: toId
    		}
    				return this.orderImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.order));
    	}

    	/**  */
    	@InjectArgs
    	public addSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSecurityGroupImpactsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds,
			isNew: isNew
    		}
    				return this.addSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('securityGroupIds') securityGroupIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSecurityGroupImpactsBaseVariables = {
    			id: id,
			securityGroupIds: securityGroupIds
    		}
    				return this.removeSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removeSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddOperatorTeamSecurityGroupImpactsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds,
			isNew: isNew
    		}
    				return this.addOperatorTeamSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removeOperatorTeamSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('operatorTeamIds') operatorTeamIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveOperatorTeamSecurityGroupImpactsBaseVariables = {
    			id: id,
			operatorTeamIds: operatorTeamIds
    		}
    				return this.removeOperatorTeamSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removeOperatorTeamSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public addPopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('isNew') isNew: boolean,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddPopulationSecurityGroupImpactsBaseVariables = {
    			id: id,
			populationIds: populationIds,
			isNew: isNew
    		}
    				return this.addPopulationSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addPopulationSecurityGroup));
    	}

    	/**  */
    	@InjectArgs
    	public removePopulationSecurityGroup(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('populationIds') populationIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemovePopulationSecurityGroupImpactsBaseVariables = {
    			id: id,
			populationIds: populationIds
    		}
    				return this.removePopulationSecurityGroupImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removePopulationSecurityGroup));
    	}

    	/** Permet de restaurer une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public restore(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.restore));
    	}

    	/** Permet de supprimer définitivement une ou plusieurs entités mises en corbeille. */
    	@InjectArgs
    	public recycle(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RecycleImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.recycleImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.recycle));
    	}

    	/** Permet de restauré une ou plusieurs entités mises en archive. */
    	@InjectArgs
    	public restoreArchived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RestoreArchivedImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.restoreArchivedImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.restoreArchived));
    	}

    	/** Permet d'archiver une ou plusieurs entités. */
    	@InjectArgs
    	public archived(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ArchivedImpactsBaseVariables = {
    			ids: ids
    		}
    				return this.archivedImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.archived));
    	}

    	/**  */
    	@InjectArgs
    	public addFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddFileDynamicFieldImpactsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.addFileDynamicFieldImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public removeFileDynamicField(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('propertyName?') propertyName?: string,
		@Args('id?') id?: string,
		@Args('fileId?') fileId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveFileDynamicFieldImpactsBaseVariables = {
    			id: id,
			fileId: fileId,
			propertyName: propertyName
    		}
    				return this.removeFileDynamicFieldImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removeFileDynamicField));
    	}

    	/**  */
    	@InjectArgs
    	public addIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddIncidentModelsImpactsBaseVariables = {
    			id: id,
			incidentModelIds: incidentModelIds
    		}
    				return this.addIncidentModelsImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public removeIncidentModels(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('incidentModelIds') incidentModelIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveIncidentModelsImpactsBaseVariables = {
    			incidentModelIds: incidentModelIds
    		}
    				return this.removeIncidentModelsImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removeIncidentModels));
    	}

    	/**  */
    	@InjectArgs
    	public addTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddTicketsImpactsBaseVariables = {
    			id: id,
			ticketIds: ticketIds
    		}
    				return this.addTicketsImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.addTickets));
    	}

    	/**  */
    	@InjectArgs
    	public removeTickets(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ticketIds') ticketIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveTicketsImpactsBaseVariables = {
    			ticketIds: ticketIds
    		}
    				return this.removeTicketsImpactsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.impacts.removeTickets));
    	}
    
}