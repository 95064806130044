import { EventEmitter } from '@angular/core';
import { AbstractControl, UntypedFormGroup } from '@angular/forms';
/** @inheritdoc */
AbstractControl.prototype.isDefault = false;
/** @inheritdoc */
AbstractControl.prototype.originalValue = undefined;
/** @inheritdoc */
AbstractControl.prototype.loaded = false;
/** @inheritdoc */
AbstractControl.prototype.hasParentDependOn = false;
/** @inheritdoc */
AbstractControl.prototype.onLoadedChanged = new EventEmitter<boolean>();
/** @inheritdoc */
AbstractControl.prototype.markAsLoaded = function () {
  this.loaded = true;
  let controls = (this as UntypedFormGroup).controls;
  for (let key in controls) {
    controls[key].markAsLoaded();
  }
  // Remet le reset par défaut.
  this.markAsUnreset();
  // une seule fois pour le root
  if (this.root === this) {
    this.onLoadedChanged.emit(this.loaded);
  }
};
/** @inheritdoc */
AbstractControl.prototype.markAsUnloaded = function () {
  let controls = (this as UntypedFormGroup).controls;
  for (let key in controls) {
    controls[key].markAsUnloaded();
  }
  this.loaded = false;
  // JAMAIS AU GRAND JAMAIS
  // this.visibled = true;
  // une seule fois pour le root
  if (this.root === this) {
    this.onLoadedChanged.emit(this.loaded);
  }
};
/** @inheritdoc */
AbstractControl.prototype.visibled = true;
/** @inheritdoc */
AbstractControl.prototype.visible = function (forceEnable: boolean = true) {
  let controls = (this as UntypedFormGroup).controls;
  if (forceEnable) {
    for (let key in controls) {
      controls[key].enable({ emitEvent: false });
    }
  }
  this.visibled = true;
};
/** @inheritdoc */
AbstractControl.prototype.invisible = function () {
  this.visibled = false;
  let controls = (this as UntypedFormGroup).controls;
  for (let key in controls) {
    controls[key].disable({ emitEvent: false });
  }
};
AbstractControl.prototype.isReset = false;
/** @inheritdoc */
AbstractControl.prototype.markAsReset = function () {
  this.isReset = true;
  let controls = (this as UntypedFormGroup).controls;
  for (let key in controls) {
    controls[key].markAsReset();
  }
};
/** @inheritdoc */
AbstractControl.prototype.markAsUnreset = function () {
  this.isReset = false;
  let controls = (this as UntypedFormGroup).controls;
  for (let key in controls) {
    controls[key].markAsUnreset();
  }
};
