<dx-popup
  [width]="width"
  [height]="height"
  [showTitle]="false"
  [resizeEnabled]="true"
  [hideOnOutsideClick]="true"
  [deferRendering]="true"
  [(visible)]="isVisible"
  (onShown)="onShown($event)"
  (onHiding)="close()"
>
  <dx-load-panel
    shadingColor="rgba(0,0,0,0.1)"
    [visible]="isLoading"
    [showIndicator]="true"
    [showPane]="true"
    [shading]="true"
    [hideOnOutsideClick]="false"
  ></dx-load-panel>

  <dx-box direction="col" width="100%" height="100%">
    <dxi-item [baseSize]="95">
      <cl-work-page-header>
        <cl-work-page-header-content>
          <cl-work-page-header-content-title
            >{{ title }}
          </cl-work-page-header-content-title>
          <cl-work-page-header-content-body>
            <cl-toolbar>
              <cl-toolbar-items>
                <cl-toolbar-item-button
                  (onClick)="save($event)"
                  [type]="'default'"
                  [text]="'entities/ticket/research' | translate"
                  location="after"
                  [disabled]="false"
                >
                </cl-toolbar-item-button>
              </cl-toolbar-items>
            </cl-toolbar>
          </cl-work-page-header-content-body>
        </cl-work-page-header-content>
      </cl-work-page-header>
    </dxi-item>
    <dxi-item [ratio]="1" [baseSize]="0">
      <dx-scroll-view width="100%" height="100%">
        <cl-work-page-content>
          <!-- <div
              *ngIf="modelState != undefined"
              class="work-item-container-scroll"
              (mousewheel)="$event.stopPropagation()"
            > -->
          <div class="">
            <div class="work-item-container-scroll">
              <div class="warning-hint" *ngIf="warningMessage != undefined">
                {{ warningMessage }}
              </div>

              <div class="work-item-container">
                <div class="container-fields">
                  <p class="label">
                    {{ 'entities/ticket/researchText' | translate }}
                  </p>
                  <dx-text-box
                    [(value)]="textSearch"
                    [showClearButton]="true"
                    (onEnterKey)="onEnterKey($event)"
                  >
                  </dx-text-box>
                </div>

                <div class="container-fields">
                  <p class="label">
                    {{ 'entities/ticket/researchIn' | translate }}
                  </p>
                  <dx-drop-down-box
                    [(value)]="gridBoxValue"
                    displayExpr="value"
                    [showClearButton]="true"
                    [dataSource]="dataSource"
                  >
                    <div *dxTemplate="let data of 'content'">
                      <dx-data-grid
                        [dataSource]="dataSource"
                        [selection]="{ mode: 'multiple' }"
                        [hoverStateEnabled]="true"
                        [paging]="{ enabled: false, pageSize: 10 }"
                        [filterRow]="{ visible: false }"
                        [scrolling]="{ mode: 'virtual' }"
                        [height]="345"
                        [showColumnHeaders]="true"
                        [showRowLines]="false"
                        (onRowClick)="onRowClick($event)"
                        [(selectedRowKeys)]="gridBoxValue"
                      >
                        <dxi-column
                          *ngFor="let column of columns"
                          [dataField]="column.field"
                          [caption]="column.label"
                        >
                        </dxi-column>
                      </dx-data-grid>
                    </div>
                  </dx-drop-down-box>
                </div>
              </div>
            </div>
          </div>
        </cl-work-page-content>
      </dx-scroll-view>
    </dxi-item>
  </dx-box>

  <div class="close-btn">
    <dx-button icon="fal fa-times" (onClick)="close()"></dx-button>
  </div>
</dx-popup>
