import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetWorkflowActionsBaseVariables, FirstWorkflowActionsBaseVariables, CountWorkflowActionsBaseVariables, FindWorkflowActionsBaseVariables, SearchWorkflowActionsBaseVariables, ExportSchemaWorkflowActionsBaseVariables, ExportDataWorkflowActionsBaseVariables, CustomQueryWorkflowActionsBaseVariables, CustomQueryIdWorkflowActionsBaseVariables, UsedWorkflowActionsBaseVariables, ExistWorkflowActionsBaseVariables, UpdateManyWorkflowActionsBaseVariables, DeleteWorkflowActionsBaseVariables } from '../gqls'
import { GetWorkflowActionsDocument, FirstWorkflowActionsDocument, CountWorkflowActionsDocument, FindWorkflowActionsDocument, SearchWorkflowActionsDocument, ExportSchemaWorkflowActionsDocument, ExportDataWorkflowActionsDocument, CustomQueryWorkflowActionsDocument, CustomQueryIdWorkflowActionsDocument, UsedWorkflowActionsDocument, ExistWorkflowActionsDocument, UpdateManyWorkflowActionsDocument, DeleteWorkflowActionsDocument } from '../gqls'
import { ServiceSingleResultOfWorkflowAction, QueryContextOfWorkflowAction, FilterOfWorkflowAction, ServiceSingleResultOfInt64, ServiceListResultOfWorkflowAction, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, FieldUpdateOperatorOfWorkflowAction } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class WorkflowActionBaseService {
    
public modelName = 'workflowAction';
public modelPluralName = 'workflowActions';

	private getWorkflowActionsQuery: GetWorkflowActionsDocument;
	private firstWorkflowActionsQuery: FirstWorkflowActionsDocument;
	private countWorkflowActionsQuery: CountWorkflowActionsDocument;
	private findWorkflowActionsQuery: FindWorkflowActionsDocument;
	private searchWorkflowActionsQuery: SearchWorkflowActionsDocument;
	private exportSchemaWorkflowActionsQuery: ExportSchemaWorkflowActionsDocument;
	private exportDataWorkflowActionsQuery: ExportDataWorkflowActionsDocument;
	private customQueryWorkflowActionsQuery: CustomQueryWorkflowActionsDocument;
	private customQueryIdWorkflowActionsQuery: CustomQueryIdWorkflowActionsDocument;
	private usedWorkflowActionsQuery: UsedWorkflowActionsDocument;
	private existWorkflowActionsQuery: ExistWorkflowActionsDocument;
	private updateManyWorkflowActionsMutation: UpdateManyWorkflowActionsDocument;
	private deleteWorkflowActionsMutation: DeleteWorkflowActionsDocument;

	constructor(private injector: Injector) {
		this.getWorkflowActionsQuery = this.injector.get(GetWorkflowActionsDocument);
		this.firstWorkflowActionsQuery = this.injector.get(FirstWorkflowActionsDocument);
		this.countWorkflowActionsQuery = this.injector.get(CountWorkflowActionsDocument);
		this.findWorkflowActionsQuery = this.injector.get(FindWorkflowActionsDocument);
		this.searchWorkflowActionsQuery = this.injector.get(SearchWorkflowActionsDocument);
		this.exportSchemaWorkflowActionsQuery = this.injector.get(ExportSchemaWorkflowActionsDocument);
		this.exportDataWorkflowActionsQuery = this.injector.get(ExportDataWorkflowActionsDocument);
		this.customQueryWorkflowActionsQuery = this.injector.get(CustomQueryWorkflowActionsDocument);
		this.customQueryIdWorkflowActionsQuery = this.injector.get(CustomQueryIdWorkflowActionsDocument);
		this.usedWorkflowActionsQuery = this.injector.get(UsedWorkflowActionsDocument);
		this.existWorkflowActionsQuery = this.injector.get(ExistWorkflowActionsDocument);
		this.updateManyWorkflowActionsMutation = this.injector.get(UpdateManyWorkflowActionsDocument);
		this.deleteWorkflowActionsMutation = this.injector.get(DeleteWorkflowActionsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowAction> {
    
    		let variables: GetWorkflowActionsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.get));
            }
            else{
                let result:ServiceSingleResultOfWorkflowAction={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfWorkflowAction> {
    
    		let variables: FirstWorkflowActionsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountWorkflowActionsBaseVariables = {
    			filter: filter
    		}
    				return this.countWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowAction> {
    
    		let variables: FindWorkflowActionsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowAction> {
    
    		let variables: SearchWorkflowActionsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaWorkflowActionsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataWorkflowActionsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowAction> {
    
    		let variables: CustomQueryWorkflowActionsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfWorkflowAction,
		@Args('filter?') filter?: FilterOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfWorkflowAction> {
    
    		let variables: CustomQueryIdWorkflowActionsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedWorkflowActionsBaseVariables = {
    			ids: ids
    		}
    				return this.usedWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistWorkflowActionsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existWorkflowActionsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.exist));
    	}
    
    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfWorkflowAction,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManyWorkflowActionsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManyWorkflowActionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteWorkflowActionsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteWorkflowActionsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.workflowActions.delete));
    	}
    
}