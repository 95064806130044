import { LdapAnalysisBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { GqlField, GqlSubField } from '../helpers';
import {
  FilterOfLdapAnalyse,
  QueryContextOfLdapAnalyse,
  ServiceListResultOfLdapAnalyse,
  ServiceSingleResultOfBoolean,
} from '../types';
import { Observable } from 'rxjs';
import { LdapObjectClassType } from '@clarilog/core/services/graphql/graphql-types';

@Injectable({ providedIn: 'root' })
export class LdapAnalysisCoreService extends LdapAnalysisBaseService {
  constructor(injector: Injector) {
    super(injector);
  }

  /** Analuyse des bien   */
  @InjectArgs
  public assetAnalyse(
    @Args('ldap') ldap: boolean,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfLdapAnalyse,
    @Args('filter?') filter?: FilterOfLdapAnalyse,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapAnalyse> {
    if (ldap) {
      return this.findAssetLdap(fields, options, filter, extendedVariables);
    } else {
      return this.findLdapAsset(fields, options, filter, extendedVariables);
    }
  }

  /** Analuyse des utilisateurs   */
  @InjectArgs
  public userAnalyse(
    @Args('ldap') ldap: boolean,
    @Args('ldapObjectClassType') ldapObjectClassType: LdapObjectClassType,
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfLdapAnalyse,
    @Args('filter?') filter?: FilterOfLdapAnalyse,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfLdapAnalyse> {
    if (ldap) {
      return this.findUserLdap(
        fields,
        ldapObjectClassType,
        options,
        filter,
        extendedVariables,
      );
    } else {
      return this.findLdapUser(
        fields,
        ldapObjectClassType,
        options,
        filter,
        extendedVariables,
      );
    }
  }
}
