import { Maybe } from 'graphql/jsutils/Maybe'
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { Observable } from 'rxjs'
import { ApolloQueryResult } from '@apollo/client/core/types';
import { FetchResult } from '@apollo/client/link/core/types';
import gql from 'graphql-tag';
import { ParseCustomGqlField, GqlField, GqlSubField } from '../helpers';
import { ServiceSingleResultOfDataFile, FilterOfDataFile, QueryContextOfDataFile, ServiceSingleResultOfInt64, ServiceListResultOfDataFile, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

export type GetDataFilesResultType = {
    dataFiles: {
        get?: Maybe<ServiceSingleResultOfDataFile>
    }
}

export type GetDataFilesBaseVariables = {
	id: string, /** L'identifiant de l'entité à récupérer. */
}

/** Récupère une entité selon l'id. */
@Injectable({providedIn: 'root'})
export class GetDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query getDataFiles ${args} {
    dataFiles {
        get(id: $id) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: GetDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<GetDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FirstDataFilesResultType = {
    dataFiles: {
        first?: Maybe<ServiceSingleResultOfDataFile>
    }
}

export type FirstDataFilesBaseVariables = {
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfDataFile>, /** Les options de requêtage à appliquer. */
}

/** Récupère la première entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class FirstDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfDataFile = null, $options: QueryContextOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query firstDataFiles ${args} {
    dataFiles {
        first(filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FirstDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FirstDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CountDataFilesResultType = {
    dataFiles: {
        count?: Maybe<ServiceSingleResultOfInt64>
    }
}

export type CountDataFilesBaseVariables = {
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
}

/** Compte le nombre d'entité selon le filtre. */
@Injectable({providedIn: 'root'})
export class CountDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query countDataFiles ${args} {
    dataFiles {
        count(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CountDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CountDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfInt64", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type FindDataFilesResultType = {
    dataFiles: {
        find?: Maybe<ServiceListResultOfDataFile>
    }
}

export type FindDataFilesBaseVariables = {
	options: Maybe<QueryContextOfDataFile>, /** Les options de requêtage à appliquer. */
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
}

/** Récupère les entités selon le filtre. */
@Injectable({providedIn: 'root'})
export class FindDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$options: QueryContextOfDataFile = null, $filter: FilterOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query findDataFiles ${args} {
    dataFiles {
        find(options: $options, filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: FindDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<FindDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type SearchDataFilesResultType = {
    dataFiles: {
        search?: Maybe<ServiceListResultOfDataFile>
    }
}

export type SearchDataFilesBaseVariables = {
	value: Maybe<string>, /** Le critère de recherche. */
	hasHelpMe: boolean, /**  */
	key: Maybe<string>, /**  */
	options: Maybe<QueryContextOfDataFile>, /** Les options de requêtage à appliquer. */
}

/** Recherche des entités selon 1 critère de recherche. */
@Injectable({providedIn: 'root'})
export class SearchDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$value: String = null, $hasHelpMe: Boolean!, $key: String = null, $options: QueryContextOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query searchDataFiles ${args} {
    dataFiles {
        search(value: $value, hasHelpMe: $hasHelpMe, key: $key, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: SearchDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<SearchDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportSchemaDataFilesResultType = {
    dataFiles: {
        exportSchema?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportSchemaDataFilesBaseVariables = {
	type: ImportFileFormat, /** Format du fichier template. */
}

/** Permet de recupérer le template permettant l'importation de données. */
@Injectable({providedIn: 'root'})
export class ExportSchemaDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$type: ImportFileFormat!" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportSchemaDataFiles ${args} {
    dataFiles {
        exportSchema(type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportSchemaDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportSchemaDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExportDataDataFilesResultType = {
    dataFiles: {
        exportData?: Maybe<ServiceSingleResultOfString>
    }
}

export type ExportDataDataFilesBaseVariables = {
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
}

/** Permet d'obtenir un export en csv. */
@Injectable({providedIn: 'root'})
export class ExportDataDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$filter: FilterOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query exportDataDataFiles ${args} {
    dataFiles {
        exportData(filter: $filter) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExportDataDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExportDataDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfString", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryDataFilesResultType = {
    dataFiles: {
        customQuery?: Maybe<ServiceListResultOfDataFile>
    }
}

export type CustomQueryDataFilesBaseVariables = {
	queryBuilder: Maybe<QueryBuilderInput>, /**  */
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfDataFile>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée. */
@Injectable({providedIn: 'root'})
export class CustomQueryDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$queryBuilder: QueryBuilderInput = null, $filter: FilterOfDataFile = null, $options: QueryContextOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryDataFiles ${args} {
    dataFiles {
        customQuery(queryBuilder: $queryBuilder, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type CustomQueryIdDataFilesResultType = {
    dataFiles: {
        customQueryId?: Maybe<ServiceListResultOfDataFile>
    }
}

export type CustomQueryIdDataFilesBaseVariables = {
	id: string, /**  */
	filter: Maybe<FilterOfDataFile>, /** L'expression du filtre à appliquer. */
	options: Maybe<QueryContextOfDataFile>, /** Les options de requêtage à appliquer. */
}

/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
@Injectable({providedIn: 'root'})
export class CustomQueryIdDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$id: Uuid!, $filter: FilterOfDataFile = null, $options: QueryContextOfDataFile = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query customQueryIdDataFiles ${args} {
    dataFiles {
        customQueryId(id: $id, filter: $filter, options: $options) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: CustomQueryIdDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<CustomQueryIdDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceListResultOfDataFile", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type UsedDataFilesResultType = {
    dataFiles: {
        used?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type UsedDataFilesBaseVariables = {
	ids: Array<string>, /**  */
}

/** Permet de vérifier si l'objet est utilisé dans la base. */
@Injectable({providedIn: 'root'})
export class UsedDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$ids: [Uuid!]" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query usedDataFiles ${args} {
    dataFiles {
        used(ids: $ids) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: UsedDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<UsedDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

export type ExistDataFilesResultType = {
    dataFiles: {
        exist?: Maybe<ServiceSingleResultOfBoolean>
    }
}

export type ExistDataFilesBaseVariables = {
	fieldName: Maybe<string>, /** Le nom du champ. */
	fieldValue: Maybe<string>, /** La valeur du champ à vérifier. */
	excludeId: Maybe<string>, /** L'identitifant de l'entité à exclure lors de la recherche. */
	parentFieldName: Maybe<string>, /** Le nom du champ identifiant du parent. */
	parentId: Maybe<string>, /** L'identifiant du parent. */
	type: Maybe<string>, /** Le type d'entité. */
}

/** Vérifie si la valeur du champ existe sur une entité. */
@Injectable({providedIn: 'root'})
export class ExistDataFilesDocument {
    public document(extendedArgs: string, fields: string) { 
        let args = "$fieldName: String = null, $fieldValue: String = null, $excludeId: Uuid = null, $parentFieldName: String = null, $parentId: Uuid = null, $type: String = null" + extendedArgs
        if (args.length > 0) {
            args = this.fieldGenerator.getUniqueArgs(args);
            args = '(' + args + ')'
        }
        return gql`
query existDataFiles ${args} {
    dataFiles {
        exist(fieldName: $fieldName, fieldValue: $fieldValue, excludeId: $excludeId, parentFieldName: $parentFieldName, parentId: $parentId, type: $type) {
            ${fields}
            
        }
    }
}`
    }

    constructor(private apollo: Apollo, private fieldGenerator: ParseCustomGqlField) {}

    public query(variables: ExistDataFilesBaseVariables, extendedVariables: any, fields: Array<GqlField | GqlSubField>): Observable<ApolloQueryResult<ExistDataFilesResultType>> {
        let customField = this.fieldGenerator.generateGqlQueryFields( "ServiceSingleResultOfBoolean", fields, {...variables, ...extendedVariables})
        return this.apollo.use('v2').query({
            query: this.document(this.fieldGenerator.generateOperationExtendedArgsString(customField.args), customField.queryField),
            variables: {...variables, ...extendedVariables}
        })
    }
}

