export class IncidentHelpers {
    public static loadPage(pages) {
        for (let page in pages) {
            if (pages[page]['$ref'] != undefined) {
                let property = require('./properties/' + pages[page]['$ref']);
                if (property != undefined) {
                    let pagesOrTabs = undefined;
                    if (property.pages != undefined) {
                        pagesOrTabs = property.pages;
                    } else if (property.tabs != undefined) {
                        pagesOrTabs = property.tabs;
                    }

                    if (pagesOrTabs != undefined) {
                        this.loadPage(pagesOrTabs);
                    }

                    pages[page] = property;
                }
            } else if (pages[page].pages != undefined) {
                this.loadPage(pages[page].pages);
            } else if (pages[page].tabs != undefined) {
                this.loadPage(pages[page].tabs);
            }
        }
    }
}