import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'cl-error',
  templateUrl: './error.component.html'
})
export class ErrorComponent {
  public error:string;
  constructor() { }
}
