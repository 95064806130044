import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'clc-default-layout',
  templateUrl: './default-layout.component.html',
  styleUrls: ['./default-layout.component.scss']
})
export class CoreDefaultLayoutComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
