import { IncidentModelBaseService } from '../service-bases';
import { Injectable, Injector } from '@angular/core';
import { Authorize } from '@clarilog/core/services/graphql/graphql.service';
import {
  Args,
  InjectArgs,
} from '@clarilog/core/modules/decorators/args-decorator';
import { Observable, of } from 'rxjs';
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import {
  FilterOfTicket,
  ServiceListResultOfTag,
  FilterOfTicketTask,
  QueryContextOfTicketTask,
  ServiceListResultOfTicketTask,
  Sort,
  QueryContextOfTag,
  FilterOfIncidentModel,
  QueryContextOfIncidentModel,
  ServiceListResultOfIncidentModel,
} from '../types';
import { map } from 'rxjs/operators';
import { TranslatedFieldHelperService } from '@clarilog/shared2/components/translate-field/translate-field-helper-service';

@Injectable({ providedIn: 'root' })
@Authorize('help-desk-operator.manage-incident-model')
export class IncidentModelCoreService extends IncidentModelBaseService {
  constructor(
    injector: Injector,
    private translatedFieldHelperService: TranslatedFieldHelperService,
  ) {
    super(injector);
  }
  @InjectArgs
  public findTags(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification?') qualification?: string,
    @Args('options?') options?: QueryContextOfTag,
    @Args('filter?') filter?: FilterOfTicket,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTag> {
    qualification = 'incident';
    return super.findTags(
      fields,
      qualification,
      options,
      filter,
      extendedVariables,
    );
  }
  @InjectArgs
  public findSortedByDateAssociatedTasks(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('options?') options?: QueryContextOfTicketTask,
    @Args('id?') id?: string,
    @Args('filter?') filter?: FilterOfTicketTask,
    @Args('extendedVariables?') extendedVariables?: any,
  ): Observable<ServiceListResultOfTicketTask> {
    if (extendedVariables == undefined) {
      extendedVariables = {};
    }
    let queryFields = GqlSubField.create('data', [
      GqlSubField.create('tasks', fields, null, [
        GqlSubFieldArg.create('filterOfTasks', 'filter'),
        GqlSubFieldArg.create('optionsOfTasks', 'options'),
      ]),
    ]);
    if (options == undefined) {
      options = {};
    }

    options.sort = [{ order: Sort.Asc }];
    extendedVariables['filterOfTasks'] = filter;
    extendedVariables['optionsOfTasks'] = options;
    return this.get([queryFields], id, extendedVariables).pipe(
      map((result) => result.data.tasks),
    );
  }

  @InjectArgs
  public findByQualification(
    @Args('fields') fields: Array<GqlField | GqlSubField>,
    @Args('qualification') qualification: string[],
    @Args('options?') options?: QueryContextOfIncidentModel,
    @Args('filter?') filter?: FilterOfIncidentModel,
  ): Observable<ServiceListResultOfIncidentModel> {
    let filterByQualification: FilterOfIncidentModel = {
      qualification: { elemMatch: { in: qualification } },
    };
    if (filter != undefined) {
      filterByQualification.and = [filter];
    }

    if (options == undefined) options = {};

    return this.find(fields, options, filterByQualification);
  }

  @InjectArgs
  public customTaskDisplayExpr(@Args('item') item) {
    if (item?.__typename === 'TicketTask') {
      let title = '';
      let status = '';
      if (item?.title != undefined) {
        title =
          item?.title[this.translatedFieldHelperService.getTranslateKey()];
      }
      if (item?.status.data.name != undefined) {
        status =
          item?.status.data.name[
            this.translatedFieldHelperService.getTranslateKey()
          ];
      }

      return of(title + ' | ' + status);
    }

    return;
  }
}
