import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSystemEnclosuresBaseVariables, FirstSystemEnclosuresBaseVariables, CountSystemEnclosuresBaseVariables, FindSystemEnclosuresBaseVariables, SearchSystemEnclosuresBaseVariables, ExportSchemaSystemEnclosuresBaseVariables, ExportDataSystemEnclosuresBaseVariables, CustomQuerySystemEnclosuresBaseVariables, CustomQueryIdSystemEnclosuresBaseVariables, UsedSystemEnclosuresBaseVariables, ExistSystemEnclosuresBaseVariables } from '../gqls'
import { GetSystemEnclosuresDocument, FirstSystemEnclosuresDocument, CountSystemEnclosuresDocument, FindSystemEnclosuresDocument, SearchSystemEnclosuresDocument, ExportSchemaSystemEnclosuresDocument, ExportDataSystemEnclosuresDocument, CustomQuerySystemEnclosuresDocument, CustomQueryIdSystemEnclosuresDocument, UsedSystemEnclosuresDocument, ExistSystemEnclosuresDocument } from '../gqls'
import { ServiceSingleResultOfSystemEnclosure, QueryContextOfSystemEnclosure, FilterOfSystemEnclosure, ServiceSingleResultOfInt64, ServiceListResultOfSystemEnclosure, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SystemEnclosureBaseService {
    
public modelName = 'systemEnclosure';
public modelPluralName = 'systemEnclosures';

	private getSystemEnclosuresQuery: GetSystemEnclosuresDocument;
	private firstSystemEnclosuresQuery: FirstSystemEnclosuresDocument;
	private countSystemEnclosuresQuery: CountSystemEnclosuresDocument;
	private findSystemEnclosuresQuery: FindSystemEnclosuresDocument;
	private searchSystemEnclosuresQuery: SearchSystemEnclosuresDocument;
	private exportSchemaSystemEnclosuresQuery: ExportSchemaSystemEnclosuresDocument;
	private exportDataSystemEnclosuresQuery: ExportDataSystemEnclosuresDocument;
	private customQuerySystemEnclosuresQuery: CustomQuerySystemEnclosuresDocument;
	private customQueryIdSystemEnclosuresQuery: CustomQueryIdSystemEnclosuresDocument;
	private usedSystemEnclosuresQuery: UsedSystemEnclosuresDocument;
	private existSystemEnclosuresQuery: ExistSystemEnclosuresDocument;

	constructor(private injector: Injector) {
		this.getSystemEnclosuresQuery = this.injector.get(GetSystemEnclosuresDocument);
		this.firstSystemEnclosuresQuery = this.injector.get(FirstSystemEnclosuresDocument);
		this.countSystemEnclosuresQuery = this.injector.get(CountSystemEnclosuresDocument);
		this.findSystemEnclosuresQuery = this.injector.get(FindSystemEnclosuresDocument);
		this.searchSystemEnclosuresQuery = this.injector.get(SearchSystemEnclosuresDocument);
		this.exportSchemaSystemEnclosuresQuery = this.injector.get(ExportSchemaSystemEnclosuresDocument);
		this.exportDataSystemEnclosuresQuery = this.injector.get(ExportDataSystemEnclosuresDocument);
		this.customQuerySystemEnclosuresQuery = this.injector.get(CustomQuerySystemEnclosuresDocument);
		this.customQueryIdSystemEnclosuresQuery = this.injector.get(CustomQueryIdSystemEnclosuresDocument);
		this.usedSystemEnclosuresQuery = this.injector.get(UsedSystemEnclosuresDocument);
		this.existSystemEnclosuresQuery = this.injector.get(ExistSystemEnclosuresDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSystemEnclosure> {
    
    		let variables: GetSystemEnclosuresBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.get));
            }
            else{
                let result:ServiceSingleResultOfSystemEnclosure={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSystemEnclosure,
		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSystemEnclosure> {
    
    		let variables: FirstSystemEnclosuresBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSystemEnclosuresBaseVariables = {
    			filter: filter
    		}
    				return this.countSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSystemEnclosure,
		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemEnclosure> {
    
    		let variables: FindSystemEnclosuresBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSystemEnclosure,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemEnclosure> {
    
    		let variables: SearchSystemEnclosuresBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSystemEnclosuresBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSystemEnclosuresBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSystemEnclosure,
		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemEnclosure> {
    
    		let variables: CustomQuerySystemEnclosuresBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSystemEnclosure,
		@Args('filter?') filter?: FilterOfSystemEnclosure,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSystemEnclosure> {
    
    		let variables: CustomQueryIdSystemEnclosuresBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSystemEnclosuresBaseVariables = {
    			ids: ids
    		}
    				return this.usedSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSystemEnclosuresBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSystemEnclosuresQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.systemEnclosures.exist));
    	}
    
    
}