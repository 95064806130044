import {
  AfterContentInit,
  Component,
  ContentChildren,
  EventEmitter,
  Input,
  Output,
  QueryList,
} from '@angular/core';
import {
  CancelableEventArgs,
  EventArgs,
  FilterArgs,
  CoreNavMenuItemComponent,
} from '../nav-menu-item/nav-menu-item.component';

/** Représente les arguments de sélection. */
/** TODO A déplacer dans un dossier event-args */
export type SelectedEventArgs<T, U> = EventArgs<T> & { selected: U };
export type SelectingEventArgs<T, U> = CancelableEventArgs<T> & { selected: U };

/** Représente le mode d'affichage. */
export type NavGroupMode = 'compact' | 'normal';

@Component({
  selector: 'clc-nav-group',
  templateUrl: './nav-group.component.html',
  styleUrls: ['./nav-group.component.scss'],
})
export class CoreNavGroupComponent implements AfterContentInit {
  /** Obtient ou définit le mode. */
  @Input() mode: NavGroupMode = 'normal';
  /** Obtient ou définit si le groupe est ouvert ou non. */
  @Input() expanded: boolean = true;
  /** Obtient ou définit la valeur du text affiché. */
  @Input() text: string = undefined;
  /** Obtient ou définit si le menu est visible. */
  @Input() visibled: boolean = true;
  /** Obtient ou définit les éléments du menu. */
  @ContentChildren(CoreNavMenuItemComponent)
  items: QueryList<CoreNavMenuItemComponent>;
  /** Se produit lorsqu'un item du treeview est sélectionné. */
  @Output() onItemSelectionChanged: EventEmitter<FilterArgs> =
    new EventEmitter<FilterArgs>();
  /** Se produit lors de la sélection. */
  @Output() onSelectionChanged: EventEmitter<
    SelectedEventArgs<CoreNavGroupComponent, CoreNavMenuItemComponent>
  > = new EventEmitter<
    SelectedEventArgs<CoreNavGroupComponent, CoreNavMenuItemComponent>
  >();
  /** Obtient ou définit le message d'aide. */
  @Input() hint: string;

  constructor() {}
  /** Propage la sélection. */
  selectionChanged(e: EventArgs<CoreNavMenuItemComponent>) {
    this.onSelectionChanged.emit({
      component: this,
      selected: e.component,
      event: e.event,
    });
  }
  /** Propage la sélection des items. */
  itemSelectionChanged(e: FilterArgs) {
    this.onItemSelectionChanged.emit(e);
  }
  /** @inheritdoc */
  ngAfterContentInit(): void {
    this.items.forEach((item) => {
      if (!this.visibled) {
        item.visibled = false;
      }
      item.onItemSelectionChanged.subscribe((e) => {
        this.itemSelectionChanged(e);
      });
      item.onClick.subscribe((e) => {
        this.selectionChanged(e);
      });
    });
  }
}
