import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetNamingConventionHistoriesBaseVariables, FirstNamingConventionHistoriesBaseVariables, CountNamingConventionHistoriesBaseVariables, FindNamingConventionHistoriesBaseVariables, SearchNamingConventionHistoriesBaseVariables, ExportSchemaNamingConventionHistoriesBaseVariables, ExportDataNamingConventionHistoriesBaseVariables, CustomQueryNamingConventionHistoriesBaseVariables, CustomQueryIdNamingConventionHistoriesBaseVariables, UsedNamingConventionHistoriesBaseVariables, ExistNamingConventionHistoriesBaseVariables } from '../gqls'
import { GetNamingConventionHistoriesDocument, FirstNamingConventionHistoriesDocument, CountNamingConventionHistoriesDocument, FindNamingConventionHistoriesDocument, SearchNamingConventionHistoriesDocument, ExportSchemaNamingConventionHistoriesDocument, ExportDataNamingConventionHistoriesDocument, CustomQueryNamingConventionHistoriesDocument, CustomQueryIdNamingConventionHistoriesDocument, UsedNamingConventionHistoriesDocument, ExistNamingConventionHistoriesDocument } from '../gqls'
import { ServiceSingleResultOfNamingConventionHistory, QueryContextOfNamingConventionHistory, FilterOfNamingConventionHistory, ServiceSingleResultOfInt64, ServiceListResultOfNamingConventionHistory, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class NamingConventionHistoryBaseService {
    
public modelName = 'namingConventionHistory';
public modelPluralName = 'namingConventionHistories';

	private getNamingConventionHistoriesQuery: GetNamingConventionHistoriesDocument;
	private firstNamingConventionHistoriesQuery: FirstNamingConventionHistoriesDocument;
	private countNamingConventionHistoriesQuery: CountNamingConventionHistoriesDocument;
	private findNamingConventionHistoriesQuery: FindNamingConventionHistoriesDocument;
	private searchNamingConventionHistoriesQuery: SearchNamingConventionHistoriesDocument;
	private exportSchemaNamingConventionHistoriesQuery: ExportSchemaNamingConventionHistoriesDocument;
	private exportDataNamingConventionHistoriesQuery: ExportDataNamingConventionHistoriesDocument;
	private customQueryNamingConventionHistoriesQuery: CustomQueryNamingConventionHistoriesDocument;
	private customQueryIdNamingConventionHistoriesQuery: CustomQueryIdNamingConventionHistoriesDocument;
	private usedNamingConventionHistoriesQuery: UsedNamingConventionHistoriesDocument;
	private existNamingConventionHistoriesQuery: ExistNamingConventionHistoriesDocument;

	constructor(private injector: Injector) {
		this.getNamingConventionHistoriesQuery = this.injector.get(GetNamingConventionHistoriesDocument);
		this.firstNamingConventionHistoriesQuery = this.injector.get(FirstNamingConventionHistoriesDocument);
		this.countNamingConventionHistoriesQuery = this.injector.get(CountNamingConventionHistoriesDocument);
		this.findNamingConventionHistoriesQuery = this.injector.get(FindNamingConventionHistoriesDocument);
		this.searchNamingConventionHistoriesQuery = this.injector.get(SearchNamingConventionHistoriesDocument);
		this.exportSchemaNamingConventionHistoriesQuery = this.injector.get(ExportSchemaNamingConventionHistoriesDocument);
		this.exportDataNamingConventionHistoriesQuery = this.injector.get(ExportDataNamingConventionHistoriesDocument);
		this.customQueryNamingConventionHistoriesQuery = this.injector.get(CustomQueryNamingConventionHistoriesDocument);
		this.customQueryIdNamingConventionHistoriesQuery = this.injector.get(CustomQueryIdNamingConventionHistoriesDocument);
		this.usedNamingConventionHistoriesQuery = this.injector.get(UsedNamingConventionHistoriesDocument);
		this.existNamingConventionHistoriesQuery = this.injector.get(ExistNamingConventionHistoriesDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConventionHistory> {
    
    		let variables: GetNamingConventionHistoriesBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.get));
            }
            else{
                let result:ServiceSingleResultOfNamingConventionHistory={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNamingConventionHistory,
		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfNamingConventionHistory> {
    
    		let variables: FirstNamingConventionHistoriesBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountNamingConventionHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.countNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfNamingConventionHistory,
		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConventionHistory> {
    
    		let variables: FindNamingConventionHistoriesBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfNamingConventionHistory,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConventionHistory> {
    
    		let variables: SearchNamingConventionHistoriesBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaNamingConventionHistoriesBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataNamingConventionHistoriesBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfNamingConventionHistory,
		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConventionHistory> {
    
    		let variables: CustomQueryNamingConventionHistoriesBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQueryNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfNamingConventionHistory,
		@Args('filter?') filter?: FilterOfNamingConventionHistory,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfNamingConventionHistory> {
    
    		let variables: CustomQueryIdNamingConventionHistoriesBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedNamingConventionHistoriesBaseVariables = {
    			ids: ids
    		}
    				return this.usedNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.used));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistNamingConventionHistoriesBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existNamingConventionHistoriesQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.namingConventionHistories.exist));
    	}
    
    
}