import { Maybe } from 'graphql/jsutils/Maybe'
import { GqlField, GqlSubField, GqlSubFieldArg } from '../helpers';
import { Args, InjectArgs } from '@clarilog/core/modules/decorators/args-decorator'
import { Observable, of } from 'rxjs'
import { map } from 'rxjs/operators';
import { Injectable, Injector } from '@angular/core';
import { GetSoftwareGroupsBaseVariables, FirstSoftwareGroupsBaseVariables, CountSoftwareGroupsBaseVariables, FindSoftwareGroupsBaseVariables, SearchSoftwareGroupsBaseVariables, ExportSchemaSoftwareGroupsBaseVariables, ExportDataSoftwareGroupsBaseVariables, CustomQuerySoftwareGroupsBaseVariables, CustomQueryIdSoftwareGroupsBaseVariables, UsedSoftwareGroupsBaseVariables, FindCustomUnassociatedGroupContractsSoftwareGroupsBaseVariables, ExistSoftwareGroupsBaseVariables, FindUnassociatedSoftwareSoftwareGroupsBaseVariables, FindUnassociatedContractsSoftwareGroupsBaseVariables, FindUnassociatedSoftwarePackagesSoftwareGroupsBaseVariables, FindUnassociatedAlertsSoftwareGroupsBaseVariables, InsertSoftwareGroupsBaseVariables, UpdateSoftwareGroupsBaseVariables, ImportDataSoftwareGroupsBaseVariables, UpdateManySoftwareGroupsBaseVariables, DeleteSoftwareGroupsBaseVariables, ForceCharterSoftwareGroupsBaseVariables, AddSoftwareCustomSoftwareGroupsBaseVariables, SoftwareGroupContractAssociateSoftwareGroupsBaseVariables, RemoveSoftwareCustomSoftwareGroupsBaseVariables, AddContractsSoftwareGroupsBaseVariables, RemoveContractsSoftwareGroupsBaseVariables, AddSoftwarePackagesSoftwareGroupsBaseVariables, RemoveSoftwarePackagesSoftwareGroupsBaseVariables, AddAlertsSoftwareGroupsBaseVariables, RemoveAlertsSoftwareGroupsBaseVariables, AddSoftwareSoftwareGroupsBaseVariables, RemoveSoftwareSoftwareGroupsBaseVariables } from '../gqls'
import { GetSoftwareGroupsDocument, FirstSoftwareGroupsDocument, CountSoftwareGroupsDocument, FindSoftwareGroupsDocument, SearchSoftwareGroupsDocument, ExportSchemaSoftwareGroupsDocument, ExportDataSoftwareGroupsDocument, CustomQuerySoftwareGroupsDocument, CustomQueryIdSoftwareGroupsDocument, UsedSoftwareGroupsDocument, FindCustomUnassociatedGroupContractsSoftwareGroupsDocument, ExistSoftwareGroupsDocument, FindUnassociatedSoftwareSoftwareGroupsDocument, FindUnassociatedContractsSoftwareGroupsDocument, FindUnassociatedSoftwarePackagesSoftwareGroupsDocument, FindUnassociatedAlertsSoftwareGroupsDocument, InsertSoftwareGroupsDocument, UpdateSoftwareGroupsDocument, ImportDataSoftwareGroupsDocument, UpdateManySoftwareGroupsDocument, DeleteSoftwareGroupsDocument, ForceCharterSoftwareGroupsDocument, AddSoftwareCustomSoftwareGroupsDocument, SoftwareGroupContractAssociateSoftwareGroupsDocument, RemoveSoftwareCustomSoftwareGroupsDocument, AddContractsSoftwareGroupsDocument, RemoveContractsSoftwareGroupsDocument, AddSoftwarePackagesSoftwareGroupsDocument, RemoveSoftwarePackagesSoftwareGroupsDocument, AddAlertsSoftwareGroupsDocument, RemoveAlertsSoftwareGroupsDocument, AddSoftwareSoftwareGroupsDocument, RemoveSoftwareSoftwareGroupsDocument } from '../gqls'
import { ServiceSingleResultOfSoftwareGroup, QueryContextOfSoftwareGroup, FilterOfSoftwareGroup, ServiceSingleResultOfInt64, ServiceListResultOfSoftwareGroup, ServiceSingleResultOfString, ImportFileFormat, QueryBuilderInput, ServiceSingleResultOfBoolean, ServiceListResultOfContract, QueryContextOfContract, FilterOfContract, QueryContextOfSoftware, FilterOfSoftware, ServiceListResultOfSoftware, QueryContextOfSoftwarePackage, FilterOfSoftwarePackage, ServiceListResultOfSoftwarePackage, QueryContextOfAlert, FilterOfAlert, ServiceListResultOfAlert, SoftwareGroupInput, FieldUpdateOperatorOfSoftwareGroup } from '../types'

/**  */
@Injectable({providedIn: 'root'})
export class SoftwareGroupBaseService {
    
public modelName = 'softwareGroup';
public modelPluralName = 'softwareGroups';

	private getSoftwareGroupsQuery: GetSoftwareGroupsDocument;
	private firstSoftwareGroupsQuery: FirstSoftwareGroupsDocument;
	private countSoftwareGroupsQuery: CountSoftwareGroupsDocument;
	private findSoftwareGroupsQuery: FindSoftwareGroupsDocument;
	private searchSoftwareGroupsQuery: SearchSoftwareGroupsDocument;
	private exportSchemaSoftwareGroupsQuery: ExportSchemaSoftwareGroupsDocument;
	private exportDataSoftwareGroupsQuery: ExportDataSoftwareGroupsDocument;
	private customQuerySoftwareGroupsQuery: CustomQuerySoftwareGroupsDocument;
	private customQueryIdSoftwareGroupsQuery: CustomQueryIdSoftwareGroupsDocument;
	private usedSoftwareGroupsQuery: UsedSoftwareGroupsDocument;
	private findCustomUnassociatedGroupContractsSoftwareGroupsQuery: FindCustomUnassociatedGroupContractsSoftwareGroupsDocument;
	private existSoftwareGroupsQuery: ExistSoftwareGroupsDocument;
	private findUnassociatedSoftwareSoftwareGroupsQuery: FindUnassociatedSoftwareSoftwareGroupsDocument;
	private findUnassociatedContractsSoftwareGroupsQuery: FindUnassociatedContractsSoftwareGroupsDocument;
	private findUnassociatedSoftwarePackagesSoftwareGroupsQuery: FindUnassociatedSoftwarePackagesSoftwareGroupsDocument;
	private findUnassociatedAlertsSoftwareGroupsQuery: FindUnassociatedAlertsSoftwareGroupsDocument;
	private insertSoftwareGroupsMutation: InsertSoftwareGroupsDocument;
	private updateSoftwareGroupsMutation: UpdateSoftwareGroupsDocument;
	private importDataSoftwareGroupsMutation: ImportDataSoftwareGroupsDocument;
	private updateManySoftwareGroupsMutation: UpdateManySoftwareGroupsDocument;
	private deleteSoftwareGroupsMutation: DeleteSoftwareGroupsDocument;
	private forceCharterSoftwareGroupsMutation: ForceCharterSoftwareGroupsDocument;
	private addSoftwareCustomSoftwareGroupsMutation: AddSoftwareCustomSoftwareGroupsDocument;
	private softwareGroupContractAssociateSoftwareGroupsMutation: SoftwareGroupContractAssociateSoftwareGroupsDocument;
	private removeSoftwareCustomSoftwareGroupsMutation: RemoveSoftwareCustomSoftwareGroupsDocument;
	private addContractsSoftwareGroupsMutation: AddContractsSoftwareGroupsDocument;
	private removeContractsSoftwareGroupsMutation: RemoveContractsSoftwareGroupsDocument;
	private addSoftwarePackagesSoftwareGroupsMutation: AddSoftwarePackagesSoftwareGroupsDocument;
	private removeSoftwarePackagesSoftwareGroupsMutation: RemoveSoftwarePackagesSoftwareGroupsDocument;
	private addAlertsSoftwareGroupsMutation: AddAlertsSoftwareGroupsDocument;
	private removeAlertsSoftwareGroupsMutation: RemoveAlertsSoftwareGroupsDocument;
	private addSoftwareSoftwareGroupsMutation: AddSoftwareSoftwareGroupsDocument;
	private removeSoftwareSoftwareGroupsMutation: RemoveSoftwareSoftwareGroupsDocument;

	constructor(private injector: Injector) {
		this.getSoftwareGroupsQuery = this.injector.get(GetSoftwareGroupsDocument);
		this.firstSoftwareGroupsQuery = this.injector.get(FirstSoftwareGroupsDocument);
		this.countSoftwareGroupsQuery = this.injector.get(CountSoftwareGroupsDocument);
		this.findSoftwareGroupsQuery = this.injector.get(FindSoftwareGroupsDocument);
		this.searchSoftwareGroupsQuery = this.injector.get(SearchSoftwareGroupsDocument);
		this.exportSchemaSoftwareGroupsQuery = this.injector.get(ExportSchemaSoftwareGroupsDocument);
		this.exportDataSoftwareGroupsQuery = this.injector.get(ExportDataSoftwareGroupsDocument);
		this.customQuerySoftwareGroupsQuery = this.injector.get(CustomQuerySoftwareGroupsDocument);
		this.customQueryIdSoftwareGroupsQuery = this.injector.get(CustomQueryIdSoftwareGroupsDocument);
		this.usedSoftwareGroupsQuery = this.injector.get(UsedSoftwareGroupsDocument);
		this.findCustomUnassociatedGroupContractsSoftwareGroupsQuery = this.injector.get(FindCustomUnassociatedGroupContractsSoftwareGroupsDocument);
		this.existSoftwareGroupsQuery = this.injector.get(ExistSoftwareGroupsDocument);
		this.findUnassociatedSoftwareSoftwareGroupsQuery = this.injector.get(FindUnassociatedSoftwareSoftwareGroupsDocument);
		this.findUnassociatedContractsSoftwareGroupsQuery = this.injector.get(FindUnassociatedContractsSoftwareGroupsDocument);
		this.findUnassociatedSoftwarePackagesSoftwareGroupsQuery = this.injector.get(FindUnassociatedSoftwarePackagesSoftwareGroupsDocument);
		this.findUnassociatedAlertsSoftwareGroupsQuery = this.injector.get(FindUnassociatedAlertsSoftwareGroupsDocument);
		this.insertSoftwareGroupsMutation = this.injector.get(InsertSoftwareGroupsDocument);
		this.updateSoftwareGroupsMutation = this.injector.get(UpdateSoftwareGroupsDocument);
		this.importDataSoftwareGroupsMutation = this.injector.get(ImportDataSoftwareGroupsDocument);
		this.updateManySoftwareGroupsMutation = this.injector.get(UpdateManySoftwareGroupsDocument);
		this.deleteSoftwareGroupsMutation = this.injector.get(DeleteSoftwareGroupsDocument);
		this.forceCharterSoftwareGroupsMutation = this.injector.get(ForceCharterSoftwareGroupsDocument);
		this.addSoftwareCustomSoftwareGroupsMutation = this.injector.get(AddSoftwareCustomSoftwareGroupsDocument);
		this.softwareGroupContractAssociateSoftwareGroupsMutation = this.injector.get(SoftwareGroupContractAssociateSoftwareGroupsDocument);
		this.removeSoftwareCustomSoftwareGroupsMutation = this.injector.get(RemoveSoftwareCustomSoftwareGroupsDocument);
		this.addContractsSoftwareGroupsMutation = this.injector.get(AddContractsSoftwareGroupsDocument);
		this.removeContractsSoftwareGroupsMutation = this.injector.get(RemoveContractsSoftwareGroupsDocument);
		this.addSoftwarePackagesSoftwareGroupsMutation = this.injector.get(AddSoftwarePackagesSoftwareGroupsDocument);
		this.removeSoftwarePackagesSoftwareGroupsMutation = this.injector.get(RemoveSoftwarePackagesSoftwareGroupsDocument);
		this.addAlertsSoftwareGroupsMutation = this.injector.get(AddAlertsSoftwareGroupsDocument);
		this.removeAlertsSoftwareGroupsMutation = this.injector.get(RemoveAlertsSoftwareGroupsDocument);
		this.addSoftwareSoftwareGroupsMutation = this.injector.get(AddSoftwareSoftwareGroupsDocument);
		this.removeSoftwareSoftwareGroupsMutation = this.injector.get(RemoveSoftwareSoftwareGroupsDocument);
	}

    // /** @inheritdoc */
    // @InjectArgs
    // public defaultName(@Args("currentContext") currentContext: any): Observable<any> {
    //   if (currentContext.attributes != undefined) {
    //     let attributes = currentContext.attributes() as any;
    //     return of(attributes);
    //   }
    //   return null;
    // }

    
    	/** Récupère une entité selon l'id. */
    	@InjectArgs
    	public get(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareGroup> {
    
    		let variables: GetSoftwareGroupsBaseVariables = {
    			id: id
    		}
    		
            if(id != undefined){
                		return this.getSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.get));
            }
            else{
                let result:ServiceSingleResultOfSoftwareGroup={};
			    return of(result)
            }
            
    	}

    	/** Récupère la première entité selon le filtre. */
    	@InjectArgs
    	public first(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareGroup,
		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareGroup> {
    
    		let variables: FirstSoftwareGroupsBaseVariables = {
    			filter: filter,
			options: options
    		}
    				return this.firstSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.first));
    	}

    	/** Compte le nombre d'entité selon le filtre. */
    	@InjectArgs
    	public count(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfInt64> {
    
    		let variables: CountSoftwareGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.countSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.count));
    	}

    	/** Récupère les entités selon le filtre. */
    	@InjectArgs
    	public find(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwareGroup,
		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareGroup> {
    
    		let variables: FindSoftwareGroupsBaseVariables = {
    			options: options,
			filter: filter
    		}
    				return this.findSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.find));
    	}

    	/** Recherche des entités selon 1 critère de recherche. */
    	@InjectArgs
    	public search(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('hasHelpMe') hasHelpMe: boolean,
		@Args('value?') value?: string,
		@Args('options?') options?: QueryContextOfSoftwareGroup,
		@Args('key?') key?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareGroup> {
    
    		let variables: SearchSoftwareGroupsBaseVariables = {
    			value: value,
			hasHelpMe: hasHelpMe,
			key: key,
			options: options
    		}
    				return this.searchSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.search));
    	}

    	/** Permet de recupérer le template permettant l'importation de données. */
    	@InjectArgs
    	public exportSchema(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportSchemaSoftwareGroupsBaseVariables = {
    			type: type
    		}
    				return this.exportSchemaSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.exportSchema));
    	}

    	/** Permet d'obtenir un export en csv. */
    	@InjectArgs
    	public exportData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfString> {
    
    		let variables: ExportDataSoftwareGroupsBaseVariables = {
    			filter: filter
    		}
    				return this.exportDataSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.exportData));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée. */
    	@InjectArgs
    	public customQuery(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('queryBuilder?') queryBuilder?: QueryBuilderInput,
		@Args('options?') options?: QueryContextOfSoftwareGroup,
		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareGroup> {
    
    		let variables: CustomQuerySoftwareGroupsBaseVariables = {
    			queryBuilder: queryBuilder,
			filter: filter,
			options: options
    		}
    				return this.customQuerySoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.customQuery));
    	}

    	/** Permet d'exécuter une requête issue du requêteur personnalisée par son id. */
    	@InjectArgs
    	public customQueryId(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfSoftwareGroup,
		@Args('filter?') filter?: FilterOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwareGroup> {
    
    		let variables: CustomQueryIdSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.customQueryIdSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.customQueryId));
    	}

    	/** Permet de vérifier si l'objet est utilisé dans la base. */
    	@InjectArgs
    	public used(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UsedSoftwareGroupsBaseVariables = {
    			ids: ids
    		}
    				return this.usedSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.used));
    	}

    	/**  */
    	@InjectArgs
    	public findCustomUnassociatedGroupContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('options?') options?: QueryContextOfContract,
		@Args('filter?') filter?: FilterOfContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContract> {
    
    		let variables: FindCustomUnassociatedGroupContractsSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findCustomUnassociatedGroupContractsSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.findCustomUnassociatedGroupContracts));
    	}

    	/** Vérifie si la valeur du champ existe sur une entité. */
    	@InjectArgs
    	public exist(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type?') type?: string,
		@Args('parentId?') parentId?: string,
		@Args('parentFieldName?') parentFieldName?: string,
		@Args('fieldValue?') fieldValue?: string,
		@Args('fieldName?') fieldName?: string,
		@Args('excludeId?') excludeId?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ExistSoftwareGroupsBaseVariables = {
    			fieldName: fieldName,
			fieldValue: fieldValue,
			excludeId: excludeId,
			parentFieldName: parentFieldName,
			parentId: parentId,
			type: type
    		}
    				return this.existSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.exist));
    	}

	@InjectArgs
	public findAssociatedSoftware(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSoftware,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftware,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSoftware> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('software', fields, null, [
			GqlSubFieldArg.create('filterOfSoftware', 'filter'),
			GqlSubFieldArg.create('optionsOfSoftware', 'options'),
		]),
		])
		extendedVariables['filterOfSoftware'] = filter;
		extendedVariables['optionsOfSoftware'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.software));
            }
            else{
                let result: ServiceListResultOfSoftware = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftware,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftware,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftware> {
    
    		let variables: FindUnassociatedSoftwareSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSoftwareSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.findUnassociatedSoftware));
    	}

	@InjectArgs
	public findAssociatedContracts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfContract> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('contracts', fields, null, [
			GqlSubFieldArg.create('filterOfContracts', 'filter'),
			GqlSubFieldArg.create('optionsOfContracts', 'options'),
		]),
		])
		extendedVariables['filterOfContracts'] = filter;
		extendedVariables['optionsOfContracts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.contracts));
            }
            else{
                let result: ServiceListResultOfContract = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfContract,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfContract,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfContract> {
    
    		let variables: FindUnassociatedContractsSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedContractsSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.findUnassociatedContracts));
    	}

	@InjectArgs
	public findAssociatedSoftwarePackages(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfSoftwarePackage,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwarePackage,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfSoftwarePackage> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('softwarePackages', fields, null, [
			GqlSubFieldArg.create('filterOfSoftwarePackages', 'filter'),
			GqlSubFieldArg.create('optionsOfSoftwarePackages', 'options'),
		]),
		])
		extendedVariables['filterOfSoftwarePackages'] = filter;
		extendedVariables['optionsOfSoftwarePackages'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.softwarePackages));
            }
            else{
                let result: ServiceListResultOfSoftwarePackage = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedSoftwarePackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfSoftwarePackage,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfSoftwarePackage,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfSoftwarePackage> {
    
    		let variables: FindUnassociatedSoftwarePackagesSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedSoftwarePackagesSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.findUnassociatedSoftwarePackages));
    	}

	@InjectArgs
	public findAssociatedAlerts(
		@Args('fields') fields: Array<GqlField | GqlSubField>,
		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,

		@Args('extendedVariables?') extendedVariables?: any
	) : Observable<ServiceListResultOfAlert> {
		if (extendedVariables == undefined) {
			extendedVariables = {};
		}
		let queryFields = GqlSubField.create('data', [
		GqlSubField.create('alerts', fields, null, [
			GqlSubFieldArg.create('filterOfAlerts', 'filter'),
			GqlSubFieldArg.create('optionsOfAlerts', 'options'),
		]),
		])
		extendedVariables['filterOfAlerts'] = filter;
		extendedVariables['optionsOfAlerts'] = options;
		
            if(id != undefined){
                		return this.get([queryFields], id, extendedVariables).pipe(map(result => result.data.alerts));
            }
            else{
                let result: ServiceListResultOfAlert = {
                    data: [],
                    totalCount: 0,
                  };
                  return of(result);
            }
            
	}

    	/**  */
    	@InjectArgs
    	public findUnassociatedAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('options?') options?: QueryContextOfAlert,
		@Args('id?') id?: string,
		@Args('filter?') filter?: FilterOfAlert,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceListResultOfAlert> {
    
    		let variables: FindUnassociatedAlertsSoftwareGroupsBaseVariables = {
    			id: id,
			filter: filter,
			options: options
    		}
    				return this.findUnassociatedAlertsSoftwareGroupsQuery.query(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.findUnassociatedAlerts));
    	}
    
    	/** Permet d'ajouter une nouvelle entité. */
    	@InjectArgs
    	public insert(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('entity') entity: SoftwareGroupInput,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareGroup> {
    
    		let variables: InsertSoftwareGroupsBaseVariables = {
    			entity: entity
    		}
    				return this.insertSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.insert));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public update(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfSoftwareGroup> {
    
    		let variables: UpdateSoftwareGroupsBaseVariables = {
    			id: id,
			entry: entry
    		}
    				return this.updateSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.update));
    	}

    	/** Permet d'importer des données via un fichier. */
    	@InjectArgs
    	public importData(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('type') type: ImportFileFormat,
		@Args('file?') file?: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ImportDataSoftwareGroupsBaseVariables = {
    			type: type,
			file: file
    		}
    				return this.importDataSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.importData));
    	}

    	/** Permet de mette à jour une entité. */
    	@InjectArgs
    	public updateMany(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('entry?') entry?: FieldUpdateOperatorOfSoftwareGroup,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: UpdateManySoftwareGroupsBaseVariables = {
    			ids: ids,
			entry: entry
    		}
    				return this.updateManySoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.updateMany));
    	}

    	/** Permet de supprimer ou mettre en corbeille une ou plusieurs entités. */
    	@InjectArgs
    	public delete(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: DeleteSoftwareGroupsBaseVariables = {
    			ids: ids
    		}
    				return this.deleteSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.delete));
    	}

    	/**  */
    	@InjectArgs
    	public forceCharter(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('ids') ids: Array<string>,
		@Args('force') force: boolean,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: ForceCharterSoftwareGroupsBaseVariables = {
    			ids: ids,
			force: force
    		}
    				return this.forceCharterSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.forceCharter));
    	}

    	/**  */
    	@InjectArgs
    	public addSoftwareCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSoftwareCustomSoftwareGroupsBaseVariables = {
    			id: id,
			softwareIds: softwareIds
    		}
    				return this.addSoftwareCustomSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.addSoftwareCustom));
    	}

    	/**  */
    	@InjectArgs
    	public softwareGroupContractAssociate(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareGroupIds') softwareGroupIds: Array<string>,
		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: SoftwareGroupContractAssociateSoftwareGroupsBaseVariables = {
    			softwareGroupIds: softwareGroupIds,
			contractIds: contractIds
    		}
    				return this.softwareGroupContractAssociateSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.softwareGroupContractAssociate));
    	}

    	/**  */
    	@InjectArgs
    	public removeSoftwareCustom(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSoftwareCustomSoftwareGroupsBaseVariables = {
    			softwareIds: softwareIds
    		}
    				return this.removeSoftwareCustomSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.removeSoftwareCustom));
    	}

    	/**  */
    	@InjectArgs
    	public addContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddContractsSoftwareGroupsBaseVariables = {
    			id: id,
			contractIds: contractIds
    		}
    				return this.addContractsSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.addContracts));
    	}

    	/**  */
    	@InjectArgs
    	public removeContracts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('contractIds') contractIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveContractsSoftwareGroupsBaseVariables = {
    			id: id,
			contractIds: contractIds
    		}
    				return this.removeContractsSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.removeContracts));
    	}

    	/**  */
    	@InjectArgs
    	public addSoftwarePackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwarePackageIds') softwarePackageIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSoftwarePackagesSoftwareGroupsBaseVariables = {
    			id: id,
			softwarePackageIds: softwarePackageIds
    		}
    				return this.addSoftwarePackagesSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.addSoftwarePackages));
    	}

    	/**  */
    	@InjectArgs
    	public removeSoftwarePackages(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwarePackageIds') softwarePackageIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSoftwarePackagesSoftwareGroupsBaseVariables = {
    			id: id,
			softwarePackageIds: softwarePackageIds
    		}
    				return this.removeSoftwarePackagesSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.removeSoftwarePackages));
    	}

    	/**  */
    	@InjectArgs
    	public addAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('id') id: string,
		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddAlertsSoftwareGroupsBaseVariables = {
    			id: id,
			alertIds: alertIds
    		}
    				return this.addAlertsSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.addAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public removeAlerts(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('alertIds') alertIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveAlertsSoftwareGroupsBaseVariables = {
    			alertIds: alertIds
    		}
    				return this.removeAlertsSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.removeAlerts));
    	}

    	/**  */
    	@InjectArgs
    	public addSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('id') id: string,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: AddSoftwareSoftwareGroupsBaseVariables = {
    			id: id,
			softwareIds: softwareIds
    		}
    				return this.addSoftwareSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.addSoftware));
    	}

    	/**  */
    	@InjectArgs
    	public removeSoftware(
    		@Args('fields') fields: Array<GqlField | GqlSubField>,
    		@Args('softwareIds') softwareIds: Array<string>,
		@Args('extendedVariables?') extendedVariables?: any
    	) : Observable<ServiceSingleResultOfBoolean> {
    
    		let variables: RemoveSoftwareSoftwareGroupsBaseVariables = {
    			softwareIds: softwareIds
    		}
    				return this.removeSoftwareSoftwareGroupsMutation.mutate(variables, extendedVariables, fields).pipe(map(result => result.data.softwareGroups.removeSoftware));
    	}
    
}